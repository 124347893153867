import { RadioItemInterface } from "@components/atoms/RadioButtons";

/**
 * 定数列挙型enumをもとにラジオボタン用(disabled付き)のオブジェクトを生成する
 */
export function generateRadioItemsWithDisabled(
  types: {
    [s: number]: string;
  },
  disabledValue: string[]
): RadioItemInterface[] {
  const keys = Object.keys(types).filter((k) => typeof types[k] === "number");
  return keys.map((key) => {
    const value = types[key].toString();
    return {
      label: key,
      value,
      disabled: disabledValue.includes(value)
    };
  });
}
