import React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { UsageResult } from "@stores/domain/mgr/SHUROTEICHAKU/report/types";
import { Checkbox } from "@constants/variables";
import { SUPPORT_METHOD } from "@constants/mgr/SHUROTEICHAKU/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";

const styles = (): StyleRules =>
  createStyles({
    cell: {
      boxSizing: "border-box",
      padding: "16px 8px",
      height: 56
    },
    shortCell: {
      width: 64,
      minWidth: 64,
      maxWidth: 64
    },
    regionalCooperationSupport: {
      width: 92,
      minWidth: 92,
      maxWidth: 92
    },
    longCell: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      "&:last-child": {
        paddingRight: 16
      }
    }
  });

type OwnProps = {
  params: UsageResult;
  idx: number;
  isRegionalCooperationSupportFlg: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UsageResultCellCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  // その他ならsupportMethodOtherを、0なら-を、それ以外なら定数のvalueを表示させる
  let supportMethodText = "-";
  if (`${params.supportMethod}` === SUPPORT_METHOD[6].value) {
    supportMethodText = params.supportMethodOther || "";
  } else if (`${params.supportMethod}` !== SUPPORT_METHOD[0].value) {
    supportMethodText = getLabelFromOptions(
      `${params.supportMethod}`,
      SUPPORT_METHOD
    );
  }

  // 地域連携会議実施加算の表示テキスト
  let regionalCooperationSupportText = "-";
  if (
    props.isRegionalCooperationSupportFlg &&
    params.regionalCooperationSupport === 1
  ) {
    regionalCooperationSupportText = "Ⅰ";
  } else if (
    props.isRegionalCooperationSupportFlg &&
    params.regionalCooperationSupport === 2
  ) {
    regionalCooperationSupportText = "Ⅱ";
  }

  return (
    <>
      {/* 支援実施 */}
      <TableCellWrap
        key={`${idx}-status-type`}
        cellClass={`${classes.cell} ${classes.shortCell}`}
      >
        {Checkbox.ON === `${params.statusType}` ? "実施" : "-"}
      </TableCellWrap>

      {/* 特別地域加算 */}
      <TableCellWrap
        key={`${idx}-specialAreaFlg`}
        cellClass={`${classes.cell} ${classes.shortCell}`}
      >
        {Checkbox.ON === `${params.specialAreaFlg}` ? "対象" : "-"}
      </TableCellWrap>

      {/* 地域連携会議実施加算 */}
      <TableCellWrap
        key={`${idx}-regionalCooperationSupport`}
        cellClass={`${classes.cell} ${classes.regionalCooperationSupport}`}
      >
        {regionalCooperationSupportText}
      </TableCellWrap>

      {/* 支援実施方法・場所 */}
      <TableCellWrap
        key={`${idx}-supportMethod`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        {supportMethodText}
      </TableCellWrap>

      {/* 支援期間 */}
      <TableCellWrap
        key={`${idx}-supportTime`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        {params.supportStartTime || params.supportEndTime
          ? `${params.supportStartTime || "--:--"} ~ ${
              params.supportEndTime || "--:--"
            }`
          : "-"}
      </TableCellWrap>

      {/* 備考 */}
      <TableCellWrap
        key={`${idx}-remarks`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        {params.remarks || "-"}
      </TableCellWrap>
    </>
  );
};

export const UsageResultCell = withStyles(styles)(UsageResultCellCore);
