import * as types from "./types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchAssistanceReportStarted = () =>
  ({ type: types.FETCH_ASSISTANCE_REPORT_STARTED } as const);
export const fetchAssistanceReportSuccess = (
  res: types.AssistanceReportRecord
) =>
  ({
    type: types.FETCH_ASSISTANCE_REPORT_SUCCESS,
    payload: res
  } as const);
export const fetchAssistanceReportFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_ASSISTANCE_REPORT_FAILED,
    error: err
  } as const);
// 作成済み計画読み込み
export const copyConsultationStarted = () =>
  ({ type: types.COPY_CONSULTATION_STARTED } as const);
export const copyConsultationSuccess = (res: types.ConsultationRecord) =>
  ({
    type: types.COPY_CONSULTATION_SUCCESS,
    payload: { res }
  } as const);
export const copyConsultationFailed = (err: ErrorResponse) =>
  ({ type: types.COPY_CONSULTATION_FAILED, error: err } as const);
export const resetCopyConsultation = () =>
  ({ type: types.RESET_COPY_CONSULTATION } as const);
// 更新
export const postAssistanceReportStarted = () =>
  ({ type: types.POST_ASSISTANCE_REPORT_STARTED } as const);
export const postAssistanceReportSuccess = () =>
  ({
    type: types.POST_ASSISTANCE_REPORT_SUCCESS
  } as const);
export const postAssistanceReportFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_ASSISTANCE_REPORT_FAILED,
    error: err
  } as const);

// 削除
export const deleteAssistanceReportStarted = () =>
  ({ type: types.DELETE_ASSISTANCE_REPORT_STARTED } as const);
export const deleteAssistanceReportSuccess = () =>
  ({
    type: types.DELETE_ASSISTANCE_REPORT_SUCCESS
  } as const);
export const deleteAssistanceReportFailed = (err: ErrorResponse) =>
  ({
    type: types.DELETE_ASSISTANCE_REPORT_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchAssistanceReportStarted>
  | ReturnType<typeof fetchAssistanceReportSuccess>
  | ReturnType<typeof fetchAssistanceReportFailed>
  | ReturnType<typeof copyConsultationStarted>
  | ReturnType<typeof copyConsultationSuccess>
  | ReturnType<typeof copyConsultationFailed>
  | ReturnType<typeof resetCopyConsultation>
  | ReturnType<typeof postAssistanceReportStarted>
  | ReturnType<typeof postAssistanceReportSuccess>
  | ReturnType<typeof postAssistanceReportFailed>
  | ReturnType<typeof deleteAssistanceReportStarted>
  | ReturnType<typeof deleteAssistanceReportSuccess>
  | ReturnType<typeof deleteAssistanceReportFailed>;
