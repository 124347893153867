import { UsersInFacilityState } from "./types";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";
import { UsersValues } from "@initialize/mgr/KEIKAKUSODAN/users/initialValues";
import { Checkbox } from "@constants/variables";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEmpty from "lodash-es/isEmpty";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import booleanToString0or1 from "@utils/dataNormalizer/booleanToString0or1";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import castStringCheckboxValue from "@utils/dataNormalizer/castStringCheckboxValue";
import castStringOrUndefined from "@utils/dataNormalizer/castStringOrUndefined";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import selectDateValueToDatePaddingZeroWithoutDay from "@utils/date/selectDateValueToDatePaddingZeroWithoutDay";
import { cloneDeep, isArray } from "lodash-es";
import {
  DUMMY_DATE_BEGIN_IN_SERVICE,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityUserTargetIdResponse = UsersInFacilityState["user"];

/**
 * 特定のフラグがOFFの場合、APIに送信する項目を削除する
 */
const resultFormat = (
  target: UserInFacilityResult,
  facilityType: number | null
): UserInFacilityResult => {
  const result = {
    user_in_facility: { ...target.user_in_facility },
    user_in_facility_keikakusodan: {
      ...target.user_in_facility_keikakusodan
    }
  };

  if (!result.user_in_facility.care_support_payment_flg) {
    result.user_in_facility.date_begin_care_support_payment = null;
    result.user_in_facility.date_end_care_support_payment = null;
  }

  const userInFacilityKeikakuShogaiji =
    result.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan;
  if (userInFacilityKeikakuShogaiji) {
    userInFacilityKeikakuShogaiji.forEach((v, index) => {
      if (v.subsidized_unit_flg === "1") {
        userInFacilityKeikakuShogaiji[index].subsidized_yen = null;
      }
      if (v.subsidized_unit_flg === "2") {
        userInFacilityKeikakuShogaiji[index].subsidized_percent = null;
      }
      if (v.income_kind !== "1") {
        userInFacilityKeikakuShogaiji[index].income_classification = 0;
      }
      if (!v.special_area_flg) {
        userInFacilityKeikakuShogaiji[index].date_begin_special_area = null;
        userInFacilityKeikakuShogaiji[index].date_end_special_area = null;
      }
      if (v.subsidized_flg === Checkbox.OFF) {
        userInFacilityKeikakuShogaiji[index].subsidized_percent = null;
        userInFacilityKeikakuShogaiji[index].subsidized_yen = null;
        userInFacilityKeikakuShogaiji[index].subsidized_unit_flg = null;
        userInFacilityKeikakuShogaiji[index].subsidized_city_id = null;
      }
      if (v.uplimit_controlled_by === "2") {
        userInFacilityKeikakuShogaiji[index].uplimit_total_yen = null;
        userInFacilityKeikakuShogaiji[index].uplimit_user_load_yen = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen2 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen3 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen4 = null;
      }
      if (v.result_of_management !== "3") {
        userInFacilityKeikakuShogaiji[index].uplimit_yen = 0;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen2 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen3 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen4 = null;
      }
      if (v.uplimit_facility_flg === Checkbox.OFF) {
        userInFacilityKeikakuShogaiji[index].uplimit_facility_name = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_name2 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_name3 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_name4 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_controlled_by = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_number = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_number2 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_number3 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_facility_number4 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_total_yen = null;
        userInFacilityKeikakuShogaiji[index].uplimit_total_yen2 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_total_yen3 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_total_yen4 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_user_load_yen = null;
        userInFacilityKeikakuShogaiji[index].uplimit_user_load_yen2 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_user_load_yen3 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_user_load_yen4 = null;
        userInFacilityKeikakuShogaiji[index].uplimit_yen = null;
        userInFacilityKeikakuShogaiji[index].result_of_management = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen2 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen3 = null;
        userInFacilityKeikakuShogaiji[
          index
        ].uplimit_user_load_after_adjustment_yen4 = null;
      }
      if (!v.disabled_welfare_service_use_flg) {
        userInFacilityKeikakuShogaiji[
          index
        ].disabled_welfare_service_use_from = null;
        userInFacilityKeikakuShogaiji[
          index
        ].disabled_welfare_service_use_to = null;
      }
      if (!v.before_create_plan_flg) {
        userInFacilityKeikakuShogaiji[index].date_before_create_plan = null;
      }
      if (!v.before_gone_monitoring_flg) {
        userInFacilityKeikakuShogaiji[index].date_before_gone_monitoring = null;
      }
      if (!v.user_charge_limit_flg) {
        userInFacilityKeikakuShogaiji[
          index
        ].date_begin_user_charge_limit = null;
        userInFacilityKeikakuShogaiji[index].date_end_user_charge_limit = null;
      }
      if (!v.care_support_auth_flg) {
        userInFacilityKeikakuShogaiji[
          index
        ].date_begin_care_support_auth = null;
        userInFacilityKeikakuShogaiji[index].date_end_care_support_auth = null;
      }
      if (!v.plan_support_payment_flg) {
        userInFacilityKeikakuShogaiji[
          index
        ].date_begin_plan_support_payment = null;
        userInFacilityKeikakuShogaiji[
          index
        ].date_end_plan_support_payment = null;
      }
      if (!v.plan_support_monitor_flg) {
        userInFacilityKeikakuShogaiji[
          index
        ].date_begin_plan_support_monitor = null;
        userInFacilityKeikakuShogaiji[
          index
        ].date_end_plan_support_monitor = null;
      }
    });
  }

  if (
    facilityType === FACILITY_TYPE_KEIKAKUSODAN &&
    !result.user_in_facility_keikakusodan
      .handicapped_child_consultation_support_flg
  ) {
    result.user_in_facility_keikakusodan.handicapped_child_consultation_support_from = null;
    result.user_in_facility_keikakusodan.handicapped_child_consultation_support_to = null;
  }

  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMapKeikakuShogaiji = {
  date_end_in_service: {
    key: "date_end_in_service",
    childKeys: ["date_begin_in_service"]
  },
  subsidized_flg: {
    key: "subsidized_flg",
    childKeys: [
      "subsidized_unit_flg",
      "subsidized_percent",
      "subsidized_yen",
      "subsidized_city_id"
    ]
  },
  subsidized_unit_flg: {
    key: "subsidized_unit_flg",
    childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_city_id"]
  },
  subsidized_percent: {
    key: "subsidized_percent",
    childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
  },
  subsidized_city_id: {
    key: "subsidized_city_id",
    childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_unit_flg"]
  },
  subsidized_yen: {
    key: "subsidized_yen",
    childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
  },
  uplimit_facility_flg: {
    key: "uplimit_facility_flg",
    childKeys: [
      "uplimit_controlled_by",
      "uplimit_facility_number",
      "uplimit_facility_number2",
      "uplimit_facility_number3",
      "uplimit_facility_number4",
      "uplimit_facility_name",
      "uplimit_facility_name2",
      "uplimit_facility_name3",
      "uplimit_facility_name4",
      "uplimit_total_yen",
      "uplimit_total_yen2",
      "uplimit_total_yen3",
      "uplimit_total_yen4",
      "uplimit_user_load_yen",
      "uplimit_user_load_yen2",
      "uplimit_user_load_yen3",
      "uplimit_user_load_yen4",
      "uplimit_user_load_after_adjustment_yen",
      "uplimit_user_load_after_adjustment_yen2",
      "uplimit_user_load_after_adjustment_yen3",
      "uplimit_user_load_after_adjustment_yen4",
      "uplimit_yen",
      "result_of_management"
    ]
  },
  uplimit_controlled_by: {
    key: "uplimit_controlled_by",
    childKeys: [
      "uplimit_facility_number",
      "uplimit_facility_number2",
      "uplimit_facility_number3",
      "uplimit_facility_number4",
      "uplimit_facility_name",
      "uplimit_facility_name2",
      "uplimit_facility_name3",
      "uplimit_facility_name4",
      "uplimit_total_yen",
      "uplimit_total_yen2",
      "uplimit_total_yen3",
      "uplimit_total_yen4",
      "uplimit_user_load_yen",
      "uplimit_user_load_yen2",
      "uplimit_user_load_yen3",
      "uplimit_user_load_yen4",
      "uplimit_user_load_after_adjustment_yen",
      "uplimit_user_load_after_adjustment_yen2",
      "uplimit_user_load_after_adjustment_yen3",
      "uplimit_user_load_after_adjustment_yen4",
      "uplimit_yen",
      "result_of_management"
    ]
  },
  special_area_flg: {
    key: "special_area_flg",
    childKeys: ["date_begin_special_area", "date_end_special_area"]
  },
  date_begin_special_area: {
    key: "date_begin_special_area",
    childKeys: ["date_end_special_area"]
  },
  date_end_special_area: {
    key: "date_end_special_area",
    childKeys: ["date_begin_special_area"]
  },
  disabled_welfare_service_use_flg: {
    key: "disabled_welfare_service_use_flg",
    childKeys: [
      "disabled_welfare_service_use_from",
      "disabled_welfare_service_use_to"
    ]
  },
  disabled_welfare_service_use_from: {
    key: "disabled_welfare_service_use_from",
    childKeys: ["disabled_welfare_service_use_to"]
  },
  disabled_welfare_service_use_to: {
    key: "disabled_welfare_service_use_to",
    childKeys: ["disabled_welfare_service_use_from"]
  },
  before_create_plan_flg: {
    key: "before_create_plan_flg",
    childKeys: ["date_before_create_plan"]
  },
  before_gone_monitoring_flg: {
    key: "before_gone_monitoring_flg",
    childKeys: ["date_before_gone_monitoring"]
  },
  result_of_management: {
    key: "result_of_management",
    childKeys: ["uplimit_yen"]
  },
  user_charge_limit_flg: {
    key: "user_charge_limit_flg",
    childKeys: ["date_begin_user_charge_limit", "date_end_user_charge_limit"]
  },
  date_begin_user_charge_limit: {
    key: "date_begin_user_charge_limit",
    childKeys: ["date_end_user_charge_limit"]
  },
  date_end_user_charge_limit: {
    key: "date_end_user_charge_limit",
    childKeys: ["date_begin_user_charge_limit"]
  },
  care_support_auth_flg: {
    key: "care_support_auth_flg",
    childKeys: ["date_end_care_support_auth", "date_begin_care_support_auth"]
  },
  date_begin_care_support_auth: {
    key: "date_begin_care_support_auth",
    childKeys: ["date_end_care_support_auth"]
  },
  date_end_care_support_auth: {
    key: "date_end_care_support_auth",
    childKeys: ["date_begin_care_support_auth"]
  },
  plan_support_payment_flg: {
    key: "plan_support_payment_flg",
    childKeys: [
      "date_end_plan_support_payment",
      "date_begin_plan_support_payment"
    ]
  },
  date_begin_plan_support_payment: {
    key: "date_begin_plan_support_payment",
    childKeys: ["date_end_plan_support_payment"]
  },
  date_end_plan_support_payment: {
    key: "date_end_plan_support_payment",
    childKeys: ["date_begin_plan_support_payment"]
  },
  plan_support_monitor_flg: {
    key: "plan_support_monitor_flg",
    childKeys: [
      "date_end_plan_support_monitor",
      "date_begin_plan_support_monitor"
    ]
  },
  date_begin_plan_support_monitor: {
    key: "date_begin_plan_support_monitor",
    childKeys: ["date_end_plan_support_monitor"]
  },
  date_end_plan_support_monitor: {
    key: "date_end_plan_support_monitor",
    childKeys: ["date_begin_plan_support_monitor"]
  }
};
const parentParamsMap = {
  user_in_facility: {
    care_support_payment_flg: {
      key: "care_support_payment_flg",
      childKeys: [
        "date_end_care_support_payment",
        "date_begin_care_support_payment"
      ]
    },
    date_begin_care_support_payment: {
      key: "date_begin_care_support_payment",
      childKeys: ["date_end_care_support_payment"]
    },
    date_end_care_support_payment: {
      key: "date_end_care_support_payment",
      childKeys: ["date_begin_care_support_payment"]
    }
  },
  user_in_facility_keikakusodan: {
    need_nursing_care_class: {
      key: "need_nursing_care_class",
      childKeys: ["support_required_class"]
    },
    support_required_class: {
      key: "support_required_class",
      childKeys: ["need_nursing_care_class"]
    },
    handicapped_child_consultation_support_flg: {
      key: "handicapped_child_consultation_support_flg",
      childKeys: [
        "handicapped_child_consultation_support_from",
        "handicapped_child_consultation_support_to"
      ]
    },
    handicapped_child_consultation_support_from: {
      key: "handicapped_child_consultation_support_from",
      childKeys: ["handicapped_child_consultation_support_to"]
    },
    handicapped_child_consultation_support_to: {
      key: "handicapped_child_consultation_support_to",
      childKeys: ["handicapped_child_consultation_support_from"]
    },
    user_in_facility_keikakusodan_shogaijisodan: [
      { ...parentParamsMapKeikakuShogaiji },
      { ...parentParamsMapKeikakuShogaiji }
    ]
  }
};

const isDifferentChildren = (
  target: UserInFacilityResult,
  uifKey: string,
  paramKey: string,
  childKeys: string[],
  index?: number
): boolean => {
  const targetObj =
    index === undefined ? target[uifKey] : target[uifKey][paramKey][index];
  return childKeys.some((childKey) => targetObj[childKey] !== undefined);
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 */
const addParentValue = (
  target: UserInFacilityResult,
  after: UserInFacilityResult
): UserInFacilityResult => {
  const result = cloneDeep(target);
  const {
    user_in_facility_keikakusodan_shogaijisodan: uifKeikakuShogaiji
  } = after.user_in_facility_keikakusodan;
  const keikakuShogaijiLength = isArray(uifKeikakuShogaiji)
    ? uifKeikakuShogaiji.length
    : 0;

  Object.entries(parentParamsMap).forEach(([uifKey, paramKeysMap]) => {
    Object.entries(paramKeysMap).forEach(([paramKey, parentParams]) => {
      if (isArray(parentParams)) {
        parentParams.forEach((parentParam, index) => {
          // 施設区分が単体か複数かでparentParamsMap > user_in_facility_keikakusodan_shogaijisodanの処理回数が変わる
          if (index === keikakuShogaijiLength) return;
          Object.entries(parentParam).forEach(([, { key, childKeys }]) => {
            if (
              isDifferentChildren(target, uifKey, paramKey, childKeys, index)
            ) {
              result[uifKey][paramKey][index][key] =
                after[uifKey][paramKey][index][key];
            }
          });
        });
      } else {
        const { key, childKeys } = parentParams;
        if (isDifferentChildren(target, uifKey, paramKey, childKeys)) {
          result[uifKey][key] = after[uifKey][key];
        }
      }
    });
  });

  return result;
};

const CheckboxValueToNumber0or1 = (value?: Checkbox): number | undefined => {
  return value === Checkbox.OFF ? 0 : 1;
};

/**
 * 差分比較のために
 * APIのパラメータをフォームの形式に整形
 */
export const normalizeApiValue = (
  params: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const apiValue: UserInFacilityResult = {
    user_in_facility: {
      ...params.user_in_facility,
      care_support_payment_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.care_support_payment_flg
      ),
      none_recipient_number_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.none_recipient_number_flg
      )
    },
    user_in_facility_keikakusodan: params.user_in_facility_keikakusodan
      ? {
          ...params.user_in_facility_keikakusodan,
          user_in_facility_keikakusodan_shogaijisodan: params
            .user_in_facility_keikakusodan
            .user_in_facility_keikakusodan_shogaijisodan
            ? params.user_in_facility_keikakusodan.user_in_facility_keikakusodan_shogaijisodan.map(
                (v) => {
                  return {
                    ...v,
                    none_recipient_number_flg: CheckboxValueToNumber0or1(
                      v.none_recipient_number_flg
                    ),
                    user_charge_limit_flg: CheckboxValueToNumber0or1(
                      v.user_charge_limit_flg
                    ),
                    care_support_auth_flg: CheckboxValueToNumber0or1(
                      v.care_support_auth_flg
                    ),
                    plan_support_payment_flg: CheckboxValueToNumber0or1(
                      v.plan_support_payment_flg
                    ),
                    plan_support_monitor_flg: CheckboxValueToNumber0or1(
                      v.plan_support_monitor_flg
                    )
                  };
                }
              )
            : []
        }
      : {}
  };
  return apiValue;
};

/**
 * apiのparamが画面に表示する際にデフォルト値に変換されてしまっているため
 * 差分比較のために変換前に戻すメソッド
 */
const apiParamFormat = (
  apiParamValue: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const result: UserInFacilityResult = normalizeApiValue(apiParamValue);
  const resultArr =
    result.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan;
  if (resultArr) {
    resultArr.forEach((v, index) => {
      if (v.subsidized_unit_flg === "1") {
        resultArr[index].subsidized_yen = null;
      }
      if (v.subsidized_unit_flg === "2") {
        resultArr[index].subsidized_percent = null;
      }
      if (v.subsidized_flg === Checkbox.OFF) {
        resultArr[index].subsidized_percent = null;
        resultArr[index].subsidized_yen = null;
        resultArr[index].subsidized_unit_flg = null;
        resultArr[index].subsidized_city_id = null;
      }
      if (v.uplimit_facility_flg === Checkbox.OFF) {
        resultArr[index].uplimit_facility_name = null;
        resultArr[index].uplimit_facility_name2 = null;
        resultArr[index].uplimit_facility_name3 = null;
        resultArr[index].uplimit_facility_name4 = null;
        resultArr[index].uplimit_controlled_by = null;
        resultArr[index].uplimit_facility_number = null;
        resultArr[index].uplimit_facility_number2 = null;
        resultArr[index].uplimit_facility_number3 = null;
        resultArr[index].uplimit_facility_number4 = null;
        resultArr[index].uplimit_total_yen = null;
        resultArr[index].uplimit_total_yen2 = null;
        resultArr[index].uplimit_total_yen3 = null;
        resultArr[index].uplimit_total_yen4 = null;
        resultArr[index].uplimit_user_load_yen = null;
        resultArr[index].uplimit_user_load_yen2 = null;
        resultArr[index].uplimit_user_load_yen3 = null;
        resultArr[index].uplimit_user_load_yen4 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen2 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen3 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen4 = null;
        resultArr[index].uplimit_yen = null;
        resultArr[index].result_of_management = null;
      }
      if (v.uplimit_controlled_by === "2") {
        resultArr[index].uplimit_total_yen = null;
        resultArr[index].uplimit_user_load_yen = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen2 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen3 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen4 = null;
      }
      if (v.result_of_management !== "3") {
        resultArr[index].uplimit_user_load_after_adjustment_yen = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen2 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen3 = null;
        resultArr[index].uplimit_user_load_after_adjustment_yen4 = null;
      }
    });
  }
  return result;
};

/**
 * 差分抽出のメソッド
 */
const removeNoChangeData = (
  apiValues: UsersInFacilityState["user"],
  formValues: UserInFacilityResult
): UserInFacilityResult => {
  const target = {
    user_in_facility: {
      id: formValues.user_in_facility.id,
      facility_id: formValues.user_in_facility.facility_id,
      name_sei: undefined,
      name_mei: undefined,
      name_sei_kana: undefined,
      name_mei_kana: undefined,
      recipient_number: undefined,
      none_recipient_number_flg: undefined,
      gender: undefined,
      classify_physical_flg: undefined,
      classify_intelligence_flg: undefined,
      classify_mind_flg: undefined,
      classify_growth_flg: undefined,
      classify_brain_flg: undefined,
      classify_incurable_flg: undefined,
      classify_handicapped_flg: undefined,
      date_birth: formValues.user_in_facility.date_birth,
      postal_code: undefined,
      prefecture_name: undefined,
      city_id: undefined,
      address: undefined,
      tel: undefined,
      email: undefined,
      guardian_name_sei: undefined,
      guardian_name_mei: undefined,
      guardian_name_sei_kana: undefined,
      guardian_name_mei_kana: undefined,
      guardian_relation: undefined,
      guardian_tel: undefined,
      memo: undefined,

      /* 受給者証 */
      care_support_payment_flg: undefined,
      date_begin_care_support_payment: undefined,
      date_end_care_support_payment: undefined
    },
    user_in_facility_keikakusodan: {
      users_in_facility_id: undefined,
      need_nursing_care_class: undefined,
      support_required_class: undefined,
      handicapped_child_consultation_support_flg: undefined,
      handicapped_child_consultation_support_from: undefined,
      handicapped_child_consultation_support_to: undefined,
      family_serial_number: undefined,
      user_in_facility_keikakusodan_shogaijisodan: formValues
        .user_in_facility_keikakusodan
        .user_in_facility_keikakusodan_shogaijisodan
        ? formValues.user_in_facility_keikakusodan.user_in_facility_keikakusodan_shogaijisodan.map(
            (v) => {
              return {
                target_type: v.target_type,
                recipient_number: undefined,
                none_recipient_number_flg: undefined,
                /* サービス詳細 */
                date_begin_in_service: v.date_begin_in_service,
                date_end_in_service: undefined,
                date_pay_begin: v.date_pay_begin,
                date_pay_end: v.date_pay_end,
                disability_class: undefined,
                income_kind: undefined,
                income_classification: undefined,
                subsidized_flg: undefined,
                subsidized_unit_flg: undefined,
                subsidized_percent: undefined,
                subsidized_yen: undefined,
                subsidized_city_id: undefined,
                uplimit_facility_flg: undefined,
                uplimit_controlled_by: undefined,
                uplimit_facility_number: undefined,
                uplimit_facility_name: undefined,
                uplimit_total_yen: undefined,
                uplimit_user_load_yen: undefined,
                uplimit_user_load_after_adjustment_yen: undefined,
                uplimit_facility_number2: undefined,
                uplimit_facility_name2: undefined,
                uplimit_total_yen2: undefined,
                uplimit_user_load_yen2: undefined,
                uplimit_user_load_after_adjustment_yen2: undefined,
                uplimit_facility_number3: undefined,
                uplimit_facility_name3: undefined,
                uplimit_total_yen3: undefined,
                uplimit_user_load_yen3: undefined,
                uplimit_user_load_after_adjustment_yen3: undefined,
                uplimit_facility_number4: undefined,
                uplimit_facility_name4: undefined,
                uplimit_total_yen4: undefined,
                uplimit_user_load_yen4: undefined,
                uplimit_user_load_after_adjustment_yen4: undefined,
                result_of_management: undefined,
                special_area_flg: undefined,
                date_begin_special_area: undefined,
                date_end_special_area: undefined,
                disabled_welfare_service_use_flg: undefined,
                disabled_welfare_service_use_from: undefined,
                disabled_welfare_service_use_to: undefined,
                before_create_plan_flg: undefined,
                date_before_create_plan: undefined,
                before_gone_monitoring_flg: undefined,
                date_before_gone_monitoring: undefined,

                /* 受給者証 */
                user_charge_limit_flg: undefined,
                date_begin_user_charge_limit: undefined,
                date_end_user_charge_limit: undefined,
                care_support_auth_flg: undefined,
                date_begin_care_support_auth: undefined,
                date_end_care_support_auth: undefined,
                plan_support_payment_flg: undefined,
                date_begin_plan_support_payment: undefined,
                date_end_plan_support_payment: undefined,
                plan_support_monitor_flg: undefined,
                date_begin_plan_support_monitor: undefined,
                date_end_plan_support_monitor: undefined
              };
            }
          )
        : []
    }
  };

  const apiValue = apiParamFormat(apiValues);
  const form =
    formValues.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan;
  if (form) {
    form.forEach((v, index) => {
      // サービス提供終了日のみAPIの型と合わないため
      form[index].date_end_in_service =
        form[index].date_end_in_service === ""
          ? null
          : form[index].date_end_in_service;
    });
  }
  // 差分の項目を抽出 必須項目は除く
  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${apiValue[key][param]}` !== `${formValues[key][param]}`) {
          target[key][param] = formValues[key][param];
        } else {
          target[key][param] = undefined;
        }
      } else if (
        key === "user_in_facility_keikakusodan" &&
        param === "user_in_facility_keikakusodan_shogaijisodan"
      ) {
        // user_in_facility_keikakusodan_shogaijisodan配下に対しても同様の処理を行う
        target[key][param].forEach(
          (
            arrayParams: typeof target["user_in_facility_keikakusodan"]["user_in_facility_keikakusodan_shogaijisodan"][number],
            index: number
          ) => {
            const keikakuShogaijiTargetValue = target[key][param][index];
            /** 計画相談/障害児データのtarget.target_typeと同じデータ */
            const keikakuShogaijiApiValue = apiValue
              .user_in_facility_keikakusodan
              .user_in_facility_keikakusodan_shogaijisodan
              ? apiValue.user_in_facility_keikakusodan.user_in_facility_keikakusodan_shogaijisodan.find(
                  (v) =>
                    v.target_type === keikakuShogaijiTargetValue.target_type
                )
              : undefined;
            const formV = formValues[key] && formValues[key][param];
            /** 計画相談/障害児データのtarget.target_typeと同じデータ */
            const keikakuShogaijiFormValue = formV
              ? formV.find(
                  (v) =>
                    v.target_type === keikakuShogaijiTargetValue.target_type
                )
              : undefined;
            Object.keys(arrayParams).forEach((arrayParam) => {
              if (target[key][param][index][arrayParam] === undefined) {
                if (keikakuShogaijiFormValue) {
                  /*  事業所のサービス契約が単体契約から複数契約に変更された場合
                      targetやformValuesではlength 2となるが
                      apiValuesはlength 1 となるため、apiValuesが無い場合には更新する */
                  if (
                    !keikakuShogaijiApiValue ||
                    (keikakuShogaijiFormValue &&
                      `${keikakuShogaijiApiValue[arrayParam]}` !==
                        `${keikakuShogaijiFormValue[arrayParam]}`)
                  ) {
                    target[key][param][index][arrayParam] =
                      keikakuShogaijiFormValue[arrayParam];
                  } else {
                    target[key][param][index][arrayParam] = undefined;
                  }
                } else {
                  // ここがelseに入ることは無い
                  target[key][param][index][arrayParam] = undefined;
                }
              }
            });
          }
        );
      }
    });
  });

  const diffDate = addParentValue(target, formValues);
  const diffDataArr =
    diffDate.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan;
  const formValueArr =
    formValues.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan;
  if (diffDataArr && formValueArr) {
    diffDataArr.forEach((v, index) => {
      // 特殊項目の差分比較
      // 自治体助成金の単位に変更があったら隠れている項目をnullにして送信する
      if (v.subsidized_unit_flg === "1") {
        diffDataArr[index].subsidized_yen = null;
      }
      if (v.subsidized_unit_flg === "2") {
        diffDataArr[index].subsidized_percent = null;
      }
      // 上限管理事業所ありの項目に変更があったら
      // 事業所番号と事業所名は入力値を送信する
      if (v.uplimit_facility_flg === "1") {
        diffDataArr[index].uplimit_facility_number =
          formValueArr[index].uplimit_facility_number;
        diffDataArr[index].uplimit_facility_number2 =
          formValueArr[index].uplimit_facility_number2;
        diffDataArr[index].uplimit_facility_number3 =
          formValueArr[index].uplimit_facility_number3;
        diffDataArr[index].uplimit_facility_number4 =
          formValueArr[index].uplimit_facility_number4;
        diffDataArr[index].uplimit_facility_name =
          formValueArr[index].uplimit_facility_name;
        diffDataArr[index].uplimit_facility_name2 =
          formValueArr[index].uplimit_facility_name2;
        diffDataArr[index].uplimit_facility_name3 =
          formValueArr[index].uplimit_facility_name3;
        diffDataArr[index].uplimit_facility_name4 =
          formValueArr[index].uplimit_facility_name4;
      }
      // 障害支援区分、要介護状態区分、要支援状態区分、障害児相談支援の利用期間を変更した場合、障害児も送信する
      if (
        v.disability_class ||
        diffDate.user_in_facility_keikakusodan.need_nursing_care_class ||
        diffDate.user_in_facility_keikakusodan.support_required_class ||
        diffDate.user_in_facility_keikakusodan
          .handicapped_child_consultation_support_flg
      ) {
        diffDate.user_in_facility.classify_handicapped_flg =
          formValues.user_in_facility.classify_handicapped_flg;
      }

      // 障害児を変更した場合、障害支援区分、要介護状態区分、要支援状態区分、障害児相談支援の利用期間も送信する
      if (diffDate.user_in_facility.classify_handicapped_flg) {
        diffDataArr[index].disability_class =
          formValueArr[index].disability_class;
        diffDate.user_in_facility_keikakusodan.need_nursing_care_class =
          formValues.user_in_facility_keikakusodan.need_nursing_care_class;
        diffDate.user_in_facility_keikakusodan.support_required_class =
          formValues.user_in_facility_keikakusodan.support_required_class;
        diffDate.user_in_facility_keikakusodan.handicapped_child_consultation_support_flg =
          formValues.user_in_facility_keikakusodan.handicapped_child_consultation_support_flg;
      }
    });
  }

  return diffDate;
};

/**
 * パラメータ内のundefinedはパラメータから除外する
 */
const removeNullParam = (data: UserInFacilityResult): UserInFacilityResult => {
  const targetParm = {
    user_in_facility: {},
    user_in_facility_keikakusodan: {}
  };
  // 引数のデータからuser_in_facilityとuser_in_facility_keikakusodanを取得
  Object.keys(data).forEach((key) => {
    // user_in_facilityとuser_in_facility_keikakusodanからparamを取得
    Object.keys(data[key]).forEach((param) => {
      if (data[key][param] !== undefined) {
        targetParm[key][param] = data[key][param];
      }
    });
  });

  return targetParm;
};

export const normalizedGetFacilityUserTargetIdResponse = (
  result: GetFacilityUserTargetIdResponse["data"]
): NormalizedGetFacilityUserTargetIdResponse => {
  const userInFacility = result.user_in_facility;
  const userInFacilityKEIKAKUSODAN = result.user_in_facility_keikakusodan;
  const normalized = {
    user_in_facility: {
      ...userInFacility,
      none_recipient_number_flg: castNumberCheckboxValue(
        userInFacility.none_recipient_number_flg
      ),
      classify_physical_flg: castStringCheckboxValue(
        userInFacility.classify_physical_flg
      ),
      classify_intelligence_flg: castStringCheckboxValue(
        userInFacility.classify_intelligence_flg
      ),
      classify_mind_flg: castStringCheckboxValue(
        userInFacility.classify_mind_flg
      ),
      classify_growth_flg: castStringCheckboxValue(
        userInFacility.classify_growth_flg
      ),
      classify_brain_flg: castStringCheckboxValue(
        userInFacility.classify_brain_flg
      ),
      classify_incurable_flg: castStringCheckboxValue(
        userInFacility.classify_incurable_flg
      ),
      classify_handicapped_flg: castStringCheckboxValue(
        userInFacility.classify_handicapped_flg
      ),
      subsidized_flg: castStringCheckboxValue(userInFacility.subsidized_flg),
      subsidized_percent: castStringOrUndefined(
        userInFacility.subsidized_percent
      ),
      subsidized_yen: castStringOrUndefined(userInFacility.subsidized_yen),
      uplimit_controlled_by: userInFacility.uplimit_controlled_by || "1",
      uplimit_facility_flg: castStringCheckboxValue(
        userInFacility.uplimit_facility_flg
      ),
      uplimit_total_yen: castStringOrUndefined(
        userInFacility.uplimit_total_yen
      ),
      uplimit_total_yen2: castStringOrUndefined(
        userInFacility.uplimit_total_yen2
      ),
      uplimit_total_yen3: castStringOrUndefined(
        userInFacility.uplimit_total_yen3
      ),
      uplimit_total_yen4: castStringOrUndefined(
        userInFacility.uplimit_total_yen4
      ),
      uplimit_user_load_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen
      ),
      uplimit_user_load_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen2
      ),
      uplimit_user_load_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen3
      ),
      uplimit_user_load_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimit_user_load_after_adjustment_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimit_user_load_after_adjustment_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimit_user_load_after_adjustment_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimit_user_load_after_adjustment_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      result_of_management: userInFacility.result_of_management || "1",
      uplimit_yen: castStringOrUndefined(userInFacility.uplimit_yen),
      user_charge_limit_flg: castNumberCheckboxValue(
        userInFacility.user_charge_limit_flg
      ),
      care_support_payment_flg: castNumberCheckboxValue(
        userInFacility.care_support_payment_flg
      ),
      care_support_auth_flg: castNumberCheckboxValue(
        userInFacility.care_support_auth_flg
      ),
      plan_support_payment_flg: castNumberCheckboxValue(
        userInFacility.plan_support_payment_flg
      ),
      plan_support_monitor_flg: castNumberCheckboxValue(
        userInFacility.plan_support_monitor_flg
      )
    },
    user_in_facility_keikakusodan: userInFacilityKEIKAKUSODAN
      ? {
          ...userInFacilityKEIKAKUSODAN,
          user_in_facility_keikakusodan_shogaijisodan: userInFacilityKEIKAKUSODAN.user_in_facility_keikakusodan_shogaijisodan
            ? userInFacilityKEIKAKUSODAN.user_in_facility_keikakusodan_shogaijisodan.map(
                (params) => {
                  return {
                    ...params,
                    none_recipient_number_flg: castNumberCheckboxValue(
                      params.none_recipient_number_flg
                    ),
                    user_charge_limit_flg: castNumberCheckboxValue(
                      params.user_charge_limit_flg
                    ),
                    care_support_auth_flg: castNumberCheckboxValue(
                      params.care_support_auth_flg
                    ),
                    plan_support_payment_flg: castNumberCheckboxValue(
                      params.plan_support_payment_flg
                    ),
                    plan_support_monitor_flg: castNumberCheckboxValue(
                      params.plan_support_monitor_flg
                    )
                  };
                }
              )
            : []
        }
      : {}
  };
  return normalized;
};

/**
 * フォームの結果をAPIに送れる形式に整形
 */
export const normalizeFormValue = (
  values: UsersValues,
  facilityType: number | null,
  params?: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const serviceUseKEIKAKUSODAN = values.serviceUse.find(
    (v) => v.targetType === FACILITY_TYPE_KEIKAKUSODAN
  );
  const userInFacilityKEIKAKUSODANTargetParams =
    (facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
      facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) &&
    serviceUseKEIKAKUSODAN
      ? {
          need_nursing_care_class: values.basic.classifyHandicappedFlag
            ? 0
            : stringToNumber(serviceUseKEIKAKUSODAN.needNursingCareClass),
          support_required_class: values.basic.classifyHandicappedFlag
            ? 0
            : stringToNumber(serviceUseKEIKAKUSODAN.supportRequiredClass),
          handicapped_child_consultation_support_flg: values.basic
            .classifyHandicappedFlag
            ? booleanToNumber0or1(
                serviceUseKEIKAKUSODAN.handicapppedChildConsultationSupportFlag
              )
            : 0,
          handicapped_child_consultation_support_from: serviceUseKEIKAKUSODAN.handicapppedChildConsultationSupportFlag
            ? selectDateValueToDatePaddingZeroWithoutDay(
                serviceUseKEIKAKUSODAN.handicapppedChildConsultationSupportFrom
              )
            : null,
          handicapped_child_consultation_support_to: serviceUseKEIKAKUSODAN.handicapppedChildConsultationSupportFlag
            ? selectDateValueToDatePaddingZeroWithoutDay(
                serviceUseKEIKAKUSODAN.handicapppedChildConsultationSupportTo
              )
            : null
        }
      : {};
  const serviceUseTargetParams = values.serviceUse.map((v) => {
    return {
      target_type: v.targetType,
      date_begin_in_service:
        selectDateValueToDatePaddingZero(v.inServiceStartDate) ||
        DUMMY_DATE_BEGIN_IN_SERVICE,
      date_end_in_service: selectDateValueToDatePaddingZero(v.inServiceEndDate),
      date_pay_begin: selectDateValueToDatePaddingZero(v.payStartDate),
      date_pay_end: selectDateValueToDatePaddingZero(v.payEndDate),
      disability_class: values.basic.classifyHandicappedFlag
        ? 0
        : stringToNumber(v.disabilityClass),
      income_kind: v.incomeKind,
      income_classification: stringToNumber(v.incomeClassification),
      subsidized_flg: booleanToString0or1(v.subsidizedFlag),
      subsidized_percent:
        v.subsidizedUnit === "1"
          ? stringToNumber(v.subsidizedPercent)
          : undefined,
      subsidized_yen:
        v.subsidizedUnit === "2" ? stringToNumber(v.subsidizedYen) : undefined,
      subsidized_unit_flg: v.subsidizedUnit,
      subsidized_city_id: stringToNumber(v.subsidizedCityId),
      uplimit_facility_flg: booleanToString0or1(v.upperLimitFacilityFlag),
      uplimit_controlled_by: v.upperLimitControlledBy,
      uplimit_facility_number: v.upperLimitFacilityNumber,
      uplimit_facility_number2: v.upperLimitFacilityNumber2,
      uplimit_facility_number3: v.upperLimitFacilityNumber3,
      uplimit_facility_number4: v.upperLimitFacilityNumber4,
      uplimit_facility_name: v.upperLimitFacilityName,
      uplimit_facility_name2: v.upperLimitFacilityName2,
      uplimit_facility_name3: v.upperLimitFacilityName3,
      uplimit_facility_name4: v.upperLimitFacilityName4,
      uplimit_total_yen: isEmpty(v.upperLimitTotalYen)
        ? null
        : stringToNumber(v.upperLimitTotalYen),
      uplimit_total_yen2: isEmpty(v.upperLimitTotalYen2)
        ? null
        : stringToNumber(v.upperLimitTotalYen2),
      uplimit_total_yen3: isEmpty(v.upperLimitTotalYen3)
        ? null
        : stringToNumber(v.upperLimitTotalYen3),
      uplimit_total_yen4: isEmpty(v.upperLimitTotalYen4)
        ? null
        : stringToNumber(v.upperLimitTotalYen4),
      uplimit_user_load_yen: isEmpty(v.upperLimitUserLoadYen)
        ? null
        : stringToNumber(v.upperLimitUserLoadYen),
      uplimit_user_load_yen2: isEmpty(v.upperLimitUserLoadYen2)
        ? null
        : stringToNumber(v.upperLimitUserLoadYen2),
      uplimit_user_load_yen3: isEmpty(v.upperLimitUserLoadYen3)
        ? null
        : stringToNumber(v.upperLimitUserLoadYen3),
      uplimit_user_load_yen4: isEmpty(v.upperLimitUserLoadYen4)
        ? null
        : stringToNumber(v.upperLimitUserLoadYen4),
      uplimit_user_load_after_adjustment_yen: isEmpty(
        v.uplimitUserLoadAfterAdjustmentYen
      )
        ? null
        : stringToNumber(v.uplimitUserLoadAfterAdjustmentYen),
      uplimit_user_load_after_adjustment_yen2: isEmpty(
        v.uplimitUserLoadAfterAdjustmentYen2
      )
        ? null
        : stringToNumber(v.uplimitUserLoadAfterAdjustmentYen2),
      uplimit_user_load_after_adjustment_yen3: isEmpty(
        v.uplimitUserLoadAfterAdjustmentYen3
      )
        ? null
        : stringToNumber(v.uplimitUserLoadAfterAdjustmentYen3),
      uplimit_user_load_after_adjustment_yen4: isEmpty(
        v.uplimitUserLoadAfterAdjustmentYen4
      )
        ? null
        : stringToNumber(v.uplimitUserLoadAfterAdjustmentYen4),
      result_of_management: v.resultOfManagement,
      uplimit_yen: stringToNumber(v.upperLimitYen),
      special_area_flg: booleanToNumber0or1(v.specialAreaFlag),
      date_begin_special_area: selectDateValueToDatePaddingZero(
        v.specialAreaStartDate
      ),
      date_end_special_area: selectDateValueToDatePaddingZero(
        v.specialAreaEndDate
      ),
      disabled_welfare_service_use_flg: booleanToNumber0or1(
        v.disabledWelfareServiceFlag
      ),
      disabled_welfare_service_use_from: selectDateValueToDatePaddingZeroWithoutDay(
        v.disabledWelfareServiceFrom
      ),
      disabled_welfare_service_use_to: selectDateValueToDatePaddingZeroWithoutDay(
        v.disabledWelfareServiceTo
      ),
      before_create_plan_flg: booleanToNumber0or1(v.beforeCreatePlanFlag),
      date_before_create_plan: selectDateValueToDatePaddingZero(
        v.dateBeforeCreatePlan
      ),
      before_gone_monitoring_flg: booleanToNumber0or1(
        v.beforeGoneMonitoringFlag
      ),
      date_before_gone_monitoring: selectDateValueToDatePaddingZero(
        v.dateBeforeGoneMonitoring
      )
    };
  });
  const recipientCertificateTargetParams = values.recipientCertificate.map(
    (v) => {
      return {
        target_type: v.targetType,
        user_charge_limit_flg: booleanToNumber0or1(v.userChargeLimitFlag),
        date_begin_user_charge_limit: selectDateValueToDatePaddingZero(
          v.userChargeLimitStartDate
        ),
        date_end_user_charge_limit: selectDateValueToDatePaddingZero(
          v.userChargeLimitEndDate
        ),
        care_support_auth_flg: booleanToNumber0or1(v.careSupportAuthFlag),
        date_begin_care_support_auth: selectDateValueToDatePaddingZero(
          v.careSupportAuthStartDate
        ),
        date_end_care_support_auth: selectDateValueToDatePaddingZero(
          v.careSupportAuthEndDate
        ),
        plan_support_payment_flg: booleanToNumber0or1(v.planSupportPaymentFlag),
        date_begin_plan_support_payment: selectDateValueToDatePaddingZero(
          v.planSupportPaymentStartDate
        ),
        date_end_plan_support_payment: selectDateValueToDatePaddingZero(
          v.planSupportPaymentEndDate
        ),
        plan_support_monitor_flg: booleanToNumber0or1(v.planSupportMonitorFlag),
        date_begin_plan_support_monitor: selectDateValueToDatePaddingZero(
          v.planSupportMonitorStartDate
        ),
        date_end_plan_support_monitor: selectDateValueToDatePaddingZero(
          v.planSupportMonitorEndDate
        )
      };
    }
  );

  const userInFacilityKeikakusodanShogaijisodan: UserInFacilityResult["user_in_facility_keikakusodan"]["user_in_facility_keikakusodan_shogaijisodan"] = [];
  if (
    facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
    facilityType === FACILITY_TYPE_KEIKAKUSODAN
  ) {
    const serviceUseKeikakuParams = serviceUseTargetParams.find(
      (v) => v.target_type === FACILITY_TYPE_KEIKAKUSODAN
    );
    const recipientCertificateKeikakuParams = recipientCertificateTargetParams.find(
      (v) => v.target_type === FACILITY_TYPE_KEIKAKUSODAN
    );

    userInFacilityKeikakusodanShogaijisodan.push({
      target_type: FACILITY_TYPE_KEIKAKUSODAN,
      recipient_number: isEmpty(values.basic.recipientNumberKEIKAKUSODAN)
        ? null
        : values.basic.recipientNumberKEIKAKUSODAN,
      none_recipient_number_flg: booleanToNumber0or1(
        values.basic.noneRecipientNumberFlagKEIKAKUSODAN
      ),
      ...serviceUseKeikakuParams,
      ...recipientCertificateKeikakuParams
    });
  }

  if (
    facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
    facilityType === FACILITY_TYPE_SHOGAIJISODAN
  ) {
    const serviceUseShogaijiParams = serviceUseTargetParams.find(
      (v) => v.target_type === FACILITY_TYPE_SHOGAIJISODAN
    );

    const recipientCertificateShogaijiParams = recipientCertificateTargetParams.find(
      (v) => v.target_type === FACILITY_TYPE_SHOGAIJISODAN
    );
    userInFacilityKeikakusodanShogaijisodan.push({
      target_type: FACILITY_TYPE_SHOGAIJISODAN,
      recipient_number: isEmpty(values.basic.recipientNumberSHOGAIJISODAN)
        ? null
        : values.basic.recipientNumberSHOGAIJISODAN,
      none_recipient_number_flg: booleanToNumber0or1(
        values.basic.noneRecipientNumberFlagSHOGAIJISODAN
      ),
      ...serviceUseShogaijiParams,
      ...recipientCertificateShogaijiParams
    });
  }

  const target: UserInFacilityResult = {
    user_in_facility: {
      id: params ? params.user_in_facility.id : undefined,
      facility_id: params ? params.user_in_facility.facility_id : undefined,

      /* 基本情報 */
      name_sei: values.basic.nameSei,
      name_mei: values.basic.nameMei,
      name_sei_kana: values.basic.nameSeiKana,
      name_mei_kana: values.basic.nameMeiKana,
      recipient_number: null,
      none_recipient_number_flg: undefined,
      gender: values.basic.gender,
      classify_physical_flg: booleanToString0or1(
        values.basic.classifyPhysicalFlag
      ),
      classify_intelligence_flg: booleanToString0or1(
        values.basic.classifyIntelligenceFlag
      ),
      classify_mind_flg: booleanToString0or1(values.basic.classifyMindFlag),
      classify_growth_flg: booleanToString0or1(values.basic.classifyGrowthFlag),
      classify_brain_flg: booleanToString0or1(values.basic.classifyBrainFlag),
      classify_incurable_flg: booleanToString0or1(
        values.basic.classifyIncurableFlag
      ),
      classify_handicapped_flg: booleanToString0or1(
        values.basic.classifyHandicappedFlag
      ),
      date_birth: selectDateValueToDatePaddingZero(values.basic.dateOfBirth),
      postal_code: values.basic.postalCode,
      prefecture_name: values.basic.prefectureId,
      city_id: stringToNumber(values.basic.cityId),
      address: values.basic.restAddress,
      tel: isEmpty(values.basic.tel) ? null : values.basic.tel,
      email: isEmpty(values.basic.email) ? null : values.basic.email,
      guardian_name_sei: isEmpty(values.basic.guardianNameSei)
        ? null
        : values.basic.guardianNameSei,
      guardian_name_mei: isEmpty(values.basic.guardianNameMei)
        ? null
        : values.basic.guardianNameMei,
      guardian_name_sei_kana: isEmpty(values.basic.guardianNameSeiKana)
        ? null
        : values.basic.guardianNameSeiKana,
      guardian_name_mei_kana: isEmpty(values.basic.guardianNameMeiKana)
        ? null
        : values.basic.guardianNameMeiKana,
      guardian_relation: isEmpty(values.basic.guardianRelation)
        ? null
        : values.basic.guardianRelation,
      guardian_tel: isEmpty(values.basic.guardianTel)
        ? null
        : values.basic.guardianTel,
      memo: isEmpty(values.basic.memo) ? null : values.basic.memo,

      /* 受給者証 */
      care_support_payment_flg: booleanToNumber0or1(
        values.recipientCertificate[0].careSupportPaymentFlag
      ),
      date_begin_care_support_payment: values.recipientCertificate[0]
        .careSupportPaymentStartDate
        ? selectDateValueToDatePaddingZero(
            values.recipientCertificate[0].careSupportPaymentStartDate
          )
        : undefined,
      date_end_care_support_payment: values.recipientCertificate[0]
        .careSupportPaymentEndDate
        ? selectDateValueToDatePaddingZero(
            values.recipientCertificate[0].careSupportPaymentEndDate
          )
        : undefined
    },
    user_in_facility_keikakusodan: {
      ...userInFacilityKEIKAKUSODANTargetParams,
      family_serial_number: isEmpty(values.basic.familySerialNumber)
        ? null
        : stringToNumber(values.basic.familySerialNumber),
      user_in_facility_keikakusodan_shogaijisodan: userInFacilityKeikakusodanShogaijisodan
    }
  };

  const normalized = resultFormat(target, facilityType);
  let requestData = normalized;
  // 更新の場合は差分抽出のメソッドへ
  if (params) {
    const targetParm = removeNoChangeData(params, normalized);
    requestData = removeNullParam(targetParm);
  }
  return requestData;
};

/**
 * /mgr/facility/users/{id} の結果
 */
export type UserInFacilityResult = {
  user_in_facility: Partial<{
    id: number | null;
    facility_id: number | null;
    name_sei: string | null;
    name_mei: string | null;
    name_sei_kana: string | null;
    name_mei_kana: string | null;
    recipient_number: string | null;
    none_recipient_number_flg: number | null;
    gender: string | null;
    classify_physical_flg: string | null;
    classify_intelligence_flg: string | null;
    classify_mind_flg: string | null;
    classify_growth_flg: string | null;
    classify_brain_flg: string | null;
    classify_incurable_flg: string | null;
    classify_handicapped_flg: string | null;
    date_birth: string | null;
    postal_code: string | null;
    prefecture_name: string | null;
    city_id: number | null;
    address: string | null;
    tel: string | null;
    email: string | null;
    guardian_name_sei: string | null;
    guardian_name_mei: string | null;
    guardian_name_sei_kana: string | null;
    guardian_name_mei_kana: string | null;
    guardian_relation: string | null;
    guardian_tel: string | null;
    memo: string | null;

    /* 受給者証 */
    care_support_payment_flg: number;
    date_begin_care_support_payment: string | null;
    date_end_care_support_payment: string | null;
  }>;
  user_in_facility_keikakusodan: Partial<{
    need_nursing_care_class: number;
    support_required_class: number;
    handicapped_child_consultation_support_flg: number;
    handicapped_child_consultation_support_from: string | null;
    handicapped_child_consultation_support_to: string | null;
    family_serial_number: number | null;

    user_in_facility_keikakusodan_shogaijisodan: Partial<{
      target_type: number;
      recipient_number: string | null;
      none_recipient_number_flg: number;

      /* サービス詳細 */
      date_begin_in_service: string;
      date_end_in_service: string | null;
      date_pay_begin: string | null;
      date_pay_end: string | null;
      disability_class: number;
      income_kind: string | null;
      income_classification: number;
      subsidized_flg: string | null;
      subsidized_unit_flg: string | null;
      subsidized_percent: number | null;
      subsidized_yen: number | null;
      subsidized_city_id: number | null;
      uplimit_facility_flg: string | null;
      uplimit_yen: number | null;
      uplimit_controlled_by: string | null;
      uplimit_facility_number: string | null;
      uplimit_facility_name: string | null;
      uplimit_total_yen: number | null;
      uplimit_user_load_yen: number | null;
      uplimit_user_load_after_adjustment_yen: number | null;
      uplimit_facility_number2: string | null;
      uplimit_facility_name2: string | null;
      uplimit_total_yen2: number | null;
      uplimit_user_load_yen2: number | null;
      uplimit_user_load_after_adjustment_yen2: number | null;
      uplimit_facility_number3: string | null;
      uplimit_facility_name3: string | null;
      uplimit_total_yen3: number | null;
      uplimit_user_load_yen3: number | null;
      uplimit_user_load_after_adjustment_yen3: number | null;
      uplimit_facility_number4: string | null;
      uplimit_facility_name4: string | null;
      uplimit_total_yen4: number | null;
      uplimit_user_load_yen4: number | null;
      uplimit_user_load_after_adjustment_yen4: number | null;
      result_of_management: string | null;
      special_area_flg: number;
      date_begin_special_area: string | null;
      date_end_special_area: string | null;
      disabled_welfare_service_use_flg: number;
      disabled_welfare_service_use_from: string | null;
      disabled_welfare_service_use_to: string | null;
      before_create_plan_flg: number;
      date_before_create_plan: string | null;
      before_gone_monitoring_flg: number;
      date_before_gone_monitoring: string | null;

      /* 受給者証 */
      user_charge_limit_flg: number;
      date_begin_user_charge_limit: string | null;
      date_end_user_charge_limit: string | null;
      care_support_auth_flg: number;
      date_begin_care_support_auth: string | null;
      date_end_care_support_auth: string | null;
      plan_support_payment_flg: number;
      date_begin_plan_support_payment: string | null;
      date_end_plan_support_payment: string | null;
      plan_support_monitor_flg: number;
      date_begin_plan_support_monitor: string | null;
      date_end_plan_support_monitor: string | null;
    }>[];
  }>;
};
