import React, { useEffect, useState } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import blueGrey from "@material-ui/core/colors/blueGrey";
import KnowbeDialog from "@componentsMobile/molecules/dialog/KnowbeDialog";
import * as isBefore from "date-fns/is_before";
import * as isAfter from "date-fns/is_after";
import * as addDays from "date-fns/add_days";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { BASE_TEXT_COLOR } from "@/constants/styles";
import Calendar, { CalendarTileProperties } from "react-calendar";
// eslint-disable-next-line import/extensions
import "@/styles/react-calendar.css";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    container: {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      margin: "0 24px"
    },
    calendarButton: {
      padding: "8px 5px"
    },
    calendarIcon: {
      height: 23,
      width: 23,
      marginLeft: 5,
      marginRight: 5,
      color: blueGrey[400]
    },
    arrowButton: {
      minWidth: 40,
      padding: 0
    },
    arrowIcon: {
      height: 24,
      width: 24,
      color: "#546e7a",
      margin: 8
    },
    date: {
      fontWeight: "normal",
      lineHeight: 1,
      letterSpacing: 0.2,
      color: BASE_TEXT_COLOR,
      "& > span": {
        margin: "0 5px 0 3px",
        fontSize: 14,
        lineHeight: 1.5
      }
    },
    calendar: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative"
    },
    close: {
      borderTop: "1px solid #e0e0e0",
      width: "100%",
      height: 72,
      position: "sticky",
      bottom: 0,
      margin: 0
    },
    cancelButton: {
      margin: "16px 24px",
      width: "calc(100% - 48px)"
    }
  });

type OwnProps = {
  defaultDate: Date;
  min: Date;
  max: Date;
  onClickSubmit: (date: Date) => void;
  highlightErrors?: {
    errorsDateList: string[];
    fetchLatestInoutErrors: () => Promise<void>;
  };
};

type Props = WithStyles<typeof styles> & OwnProps;

const DateSelectButtonsDailyCore = (props: Props): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(props.defaultDate);
  const [isMinDate, setIsMinDate] = useState(false);
  const [isMaxDate, setIsMaxDate] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const checkIsMinDate = (): boolean => {
    return isBefore(addDays(currentDate, -1), props.min);
  };

  const checkIsMaxDate = (): boolean => {
    return isAfter(addDays(currentDate, 1), props.max);
  };

  useEffect(() => {
    setIsMinDate(checkIsMinDate());
    setIsMaxDate(checkIsMaxDate());
  }, [currentDate]);

  /**
   * 親に選択日付を返した後、現在日付に反映してから閉じる
   */
  const handleOnClickSubmit = (date: Date): void => {
    props.onClickSubmit(date);
    setCurrentDate(date);
    setIsDialogOpen(false);
  };

  const onClickOpenCalendar = async (): Promise<void> => {
    if (props.highlightErrors) {
      await props.highlightErrors.fetchLatestInoutErrors();
    }
    setIsDialogOpen(true);
  };

  /**
   * 選択日付を開いた状態に戻して閉じる
   */
  const closeCalendar = (): void => {
    setIsDialogOpen(false);
  };

  const onClickLeft = (): void => {
    if (checkIsMinDate()) {
      setIsMinDate(true);
    } else {
      const targetDate = addDays(currentDate, -1);
      setCurrentDate(targetDate);
      props.onClickSubmit(targetDate);
    }
    setIsMaxDate(false);
  };

  const onClickRight = (): void => {
    if (checkIsMaxDate()) {
      setIsMaxDate(true);
    } else {
      const targetDate = addDays(currentDate, 1);
      setCurrentDate(targetDate);
      props.onClickSubmit(targetDate);
    }
    setIsMinDate(false);
  };

  const colorStyle = ({
    date,
    view
  }: CalendarTileProperties): string | null => {
    if (view === "month") {
      if (date.getDay() === 6) return "saturday";
      if (date.getDay() === 0) return "sunday";
    }
    return null;
  };

  const { classes, min, max } = props;

  return (
    <>
      <div className={classes.container}>
        <Button
          className={classes.arrowButton}
          onClick={onClickLeft}
          style={isMinDate ? { visibility: "hidden" } : {}}
        >
          <ArrowLeftIcon className={classes.arrowIcon} />
        </Button>
        <Button
          className={classes.calendarButton}
          onClick={onClickOpenCalendar}
        >
          <EventIcon className={classes.calendarIcon} />
          <span className={classes.date}>
            <span>
              {currentDate.getFullYear()}年{currentDate.getMonth() + 1}月
              {currentDate.getDate()}日
            </span>
            <span>({oneLetterWeekdaysJapanese[currentDate.getDay()]})</span>
          </span>
        </Button>
        <Button
          className={classes.arrowButton}
          onClick={onClickRight}
          style={isMaxDate ? { visibility: "hidden" } : {}}
        >
          <ArrowRightIcon className={classes.arrowIcon} />
        </Button>
      </div>
      <KnowbeDialog
        isOpn={isDialogOpen}
        isShowTitle={false}
        isShowFooter
        contentStyle={{ padding: 0 }}
        onCloseDialog={closeCalendar}
        footerContent={
          <div className={classes.close}>
            <KnowbeButton
              kind="outlineWhite"
              onClick={closeCalendar}
              className={classes.cancelButton}
            >
              閉じる
            </KnowbeButton>
          </div>
        }
      >
        <div className={classes.calendar}>
          <Calendar
            calendarType="US"
            view="month"
            maxDate={max}
            minDate={min}
            value={currentDate}
            onClickDay={handleOnClickSubmit}
            prevLabel={<ArrowLeftIcon className={classes.arrowIcon} />}
            nextLabel={<ArrowRightIcon className={classes.arrowIcon} />}
            next2Label={null}
            prev2Label={null}
            formatDay={(locale, date): string => {
              return `${date.getDate()}`;
            }}
            tileClassName={colorStyle}
          />
        </div>
      </KnowbeDialog>
    </>
  );
};

export const DateSelectButtonsDaily = withStyles(styles)(
  DateSelectButtonsDailyCore
);
