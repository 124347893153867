import * as URL from "@constants/url";
import OpenInNew from "@material-ui/icons/OpenInNew";
import HelpOutLine from "@material-ui/icons/HelpOutline";
import { MenuItemList } from "@constants/menu";
import OutlineAssignmentIcon from "@images/icons/OutlineAssignmentIcon";

export const MENU_ITEM_LIST_IDOSHIEN: MenuItemList = [
  {
    title: "記録",
    to: "",
    icon: OutlineAssignmentIcon,
    children: [
      {
        title: "サービス提供記録　日ごと",
        to: URL.RECORD_SERVICE_DELIVERY_DAILY,
        selectedPattern: `^${URL.RECORD_SERVICE_DELIVERY_DAILY}`
      },
      {
        title: "サービス提供記録　月ごと",
        to: `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/0/0`,
        selectedPattern: `^${URL.RECORD_SERVICE_DELIVERY_MONTHLY}`
      }
    ],
    checkPlanGroupOperationSupport: true
  },
  {
    title: "ヘルプ",
    icon: HelpOutLine,
    to: "",
    children: [
      {
        title: "操作マニュアル",
        to: URL.MANUAL_URL,
        target: "_blank",
        rightIcon: OpenInNew
      },
      {
        title: "お問い合わせ",
        to: URL.CONTACT,
        target: "_blank",
        rightIcon: OpenInNew
      }
    ]
  }
];
