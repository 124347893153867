import React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { SettingForm } from "@components/organisms/record/setting/SettingForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

/**
 * 事業所情報
 */
export const RecordSetting = (): JSX.Element => (
  <AdminTemplate pageName="記録の項目設定">
    <SettingForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
