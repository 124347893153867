import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import ReportDaily from "@components/pages/mgr/SEIKATSUKAIGO/report/Daily";
import ReportMonthly from "@components/pages/mgr/SEIKATSUKAIGO/report/Monthly";
import ReportDaily201910 from "@components/v201910/pages/mgr/SEIKATSUKAIGO/report/Daily";
import ReportDaily202104 from "@components/v202104/pages/mgr/SEIKATSUKAIGO/report/Daily";
import ReportMonthly201910 from "@components/v201910/pages/mgr/SEIKATSUKAIGO/report/Monthly";
import ReportMonthly202104 from "@components/v202104/pages/mgr/SEIKATSUKAIGO/report/Monthly";

// 利用者情報
import UsersList from "@components/pages/mgr/SEIKATSUKAIGO/users/UsersList";
import CreateUser from "@components/pages/mgr/SEIKATSUKAIGO/users/CreateUser";
import CreateUser201910 from "@components/v201910/pages/mgr/SEIKATSUKAIGO/users/CreateUser";
import EditUser from "@components/pages/mgr/SEIKATSUKAIGO/users/EditUser";
import EditUser201910 from "@components/v201910/pages/mgr/SEIKATSUKAIGO/users/EditUser";
import { EditUser202104 } from "@components/v202104/pages/mgr/SEIKATSUKAIGO/users/EditUser";

// 事業所情報
import Facility from "@components/pages/mgr/SEIKATSUKAIGO/Facility";
import Facility201910 from "@components/v201910/pages/mgr/SEIKATSUKAIGO/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/SEIKATSUKAIGO/Facility";

// 初期設定情報
import InitialData from "@components/pages/mgr/SEIKATSUKAIGO/InitialData";

// 記録機能
import RecordRoutes from "@app/CommonRoutes/RecordRoutes";
import DailyRecord from "@components/pages/mgr/SEIKATSUKAIGO/record/Daily";
import OperationsRecord from "@components/pages/mgr/SEIKATSUKAIGO/record/Operations";
import UserDetail from "@components/pages/mgr/SEIKATSUKAIGO/record/UserDetail";
import SupportPlan from "@components/pages/mgr/SEIKATSUKAIGO/record/SupportPlan";
import SupportPlanNew from "@components/pages/mgr/SEIKATSUKAIGO/record/SupportPlanNew";
import SupportPlanList from "@components/pages/mgr/SEIKATSUKAIGO/record/SupportPlanList";
import { RecordUsersSummary } from "@components/pages/mgr/SEIKATSUKAIGO/record/UsersSummary";

// モニタリング
import { Evaluation } from "@components/pages/mgr/SEIKATSUKAIGO/record/Evaluation";
import { EvaluationPrint } from "@components/pages/record/print/EvaluationPrint";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/assessment/setting/AssessmentSetting";
import { AssessmentRecordPrint } from "@components/pages/mgr/SEIKATSUKAIGO/record/print/AssessmentPrint";
import { AssessmentList } from "@components/pages/mgr/SEIKATSUKAIGO/record/assessment/AssessmentList";
import { Assessment } from "@components/pages/mgr/SEIKATSUKAIGO/record/assessment/Assessment";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 生活介護施設でログインした時だけ有効になるルーティング
 */
const SEIKATSUKAIGORoutes: React.FunctionComponent<Props> = ({ user }) => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY_201910} component={ReportDaily201910} />
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute
      path={URL.REPORT_USERS_201910}
      component={ReportMonthly201910}
    />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute
      exact
      path={URL.USERS_NEW_201910}
      component={CreateUser201910}
    />
    <AdminRoute exact path={URL.USERS_ID_201910} component={EditUser201910} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    <AdminRoute path={URL.FACILITY_201910} component={Facility201910} />
    <AdminRoute path={URL.FACILITY} component={Facility} />
    {/* 初期設定情報 */}
    <AdminRoute path={URL.INITIAL} component={InitialData} />
    {/* 記録機能 */}
    {RecordRoutes}
    <AdminRoute exact path="/record/daily/:yyyymmdd?" component={DailyRecord} />
    <AdminRoute exact path="/record/operations" component={OperationsRecord} />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />
    {/* アセスメントの設定 */}
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    <AdminRoute
      exact
      path="/record/operations/:year/:month"
      component={OperationsRecord}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/new"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId/copy"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId"
      component={SupportPlan}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/evaluation/:supportPlanId"
      component={Evaluation}
    />
    <AdminRoute
      key="EvaluationPrint"
      exact
      path="/record/print/:uifId/support_plan/evaluation/:supportPlanId"
      component={EvaluationPrint}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support|work|interview)/:year?/:month?"
      component={UserDetail}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support_plan)/:year?/:month?"
      component={SupportPlanList}
    />
    <AdminRoute
      exact
      path="/record/users_summary"
      component={RecordUsersSummary}
    />
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);

export default SEIKATSUKAIGORoutes;
