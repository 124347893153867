import * as React from "react";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";

// ui
import { ButtonProps } from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiCheckbox from "@components/molecules/MuiCheckbox";

// store
import { connect } from "react-redux";

// utils
import cloneDeep from "lodash-es/cloneDeep";

// type
import { PatternListType } from "@constants/mgr/SHISETSUNYUSHO/variables";
import { AppState } from "@stores/type";

const styles = (): StyleRules =>
  createStyles({
    searchWrap: {
      borderRadius: 4,
      backgroundColor: "#fafafa",
      marginTop: 20,
      border: "solid 1px #bdbdbd",
      padding: "20px 16px 0"
    },
    searchTextWrap: {
      fontSize: 14,
      letterSpacing: 0.1,
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 10
    },
    buttonsWrap: { display: "flex", justifyContent: "space-between" },
    searchButtonsWrap: { display: "flex", alignItems: "center" },
    clearButton: {
      display: "flex",
      padding: "3px 8px",
      marginRight: 8,
      width: 168,
      minHeight: 30
    },
    searchButton: {
      width: 168,
      minHeight: 30
    },
    checkBoxWrap: {
      display: "flex"
    }
  });

type StateProps = {
  loading: boolean;
};

type StyledChipProps = ButtonProps & {
  checked: boolean;
};

type Props = {
  className?: string;
  patternList: PatternListType[];
  isRecord: number;
  handleSearchUsers: (
    list: PatternListType[],
    isRecord: number
  ) => (e: React.MouseEvent<HTMLElement>) => void;
} & StateProps &
  WithStyles<typeof styles>;

const StyledChip = (props: StyledChipProps): JSX.Element => {
  const chipStyles = {
    root: {
      backgroundColor: props.checked ? "#455a64" : "#fff",
      color: props.checked ? "#fff" : "#37474f",
      opacity: props.disabled ? 0.5 : 1,
      height: 30,
      borderRadius: 18,
      minWidth: 0,
      minHeight: 30,
      marginRight: 10,
      "&:hover": {
        backgroundColor: props.checked ? "#455a64" : "#fff"
      },
      "& span": {
        fontSize: 12
      },
      "&:disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "#fff"
      }
    }
  };
  const StyledButtonCore = (
    chipProps: StyledChipProps & WithStyles<typeof chipStyles>
  ): JSX.Element => (
    <KnowbeButton {...props} className={chipProps.classes.root} />
  );
  const StyledButton = withStyles(chipStyles)(StyledButtonCore);
  return <StyledButton {...props} />;
};

const SearchUsersFormCore = (props: Props): JSX.Element => {
  const [targetPattern, setTargetPattern] = React.useState(props.patternList);
  // 余計なタイミングで状態がリセットされないよう、useEffect内で前後の差分を確認
  const previousPatternList = React.useRef(props.patternList);

  React.useEffect(() => {
    if (
      JSON.stringify(props.patternList) !==
      JSON.stringify(previousPatternList.current)
    ) {
      setTargetPattern(props.patternList);
      previousPatternList.current = props.patternList;
    }
  }, [props.patternList]);

  const handleClick = (pattern: PatternListType, index: number) => (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    e.preventDefault();
    const newPatternList = cloneDeep(targetPattern);
    newPatternList[index].checked = !pattern.checked;
    setTargetPattern(newPatternList);
  };

  // 0: 全て
  // 1: 未記録
  // 2: 記録済
  const [isRecorded, setIsRecorded] = React.useState(props.isRecord === 2);
  const [isNotRecorded, setIsNotRecorded] = React.useState(
    props.isRecord === 1
  );
  React.useEffect(() => {
    setIsRecorded(props.isRecord === 2);
    setIsNotRecorded(props.isRecord === 1);
    setTargetPattern(props.patternList);
  }, [props.loading, props.isRecord]);

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    switch (name) {
      case "isRecorded":
        setIsRecorded(checked);
        break;
      case "isNotRecorded":
        setIsNotRecorded(checked);
        break;
      default:
    }
  };

  const handleClear = (): void => {
    const resetPattern = targetPattern.map((p) => ({
      ...p,
      checked: false
    }));
    setTargetPattern(resetPattern);
    setIsRecorded(false);
    setIsNotRecorded(false);
  };

  return (
    <div
      className={`${props.classes.searchWrap} ${
        props.className ? props.className : ""
      }`}
    >
      <div className={props.classes.searchTextWrap}>
        表示の絞り込み（複数選択可）
      </div>
      <div>
        {targetPattern.map((item, index) => {
          return (
            <StyledChip
              variant="outlined"
              onClick={handleClick(item, index)}
              checked={item.checked}
              key={item.id}
              disabled={item.disabled}
              disableRipple
            >
              {item.viewKana}
            </StyledChip>
          );
        })}
      </div>
      <div className={props.classes.buttonsWrap}>
        <div className={props.classes.checkBoxWrap}>
          <MuiCheckbox
            label="記録済"
            onChange={handleCheckbox}
            name="isRecorded"
            checked={isRecorded}
            style={{ margin: "0" }}
          />
          <MuiCheckbox
            label="未記録"
            onChange={handleCheckbox}
            name="isNotRecorded"
            checked={isNotRecorded}
            style={{ margin: "0 0 0 8px" }}
          />
        </div>
        <div className={props.classes.searchButtonsWrap}>
          <KnowbeButton
            className={props.classes.clearButton}
            onClick={handleClear}
            kind="iconText"
          >
            <RefreshIcon style={{ marginRight: 3 }} />
            条件をリセット
          </KnowbeButton>
          <KnowbeButton
            kind="outlineWhite"
            onClick={(e): void => {
              const isRecord = ((): number => {
                if (isRecorded && !isNotRecorded) {
                  return 2;
                }
                if (!isRecorded && isNotRecorded) {
                  return 1;
                }
                return 0;
              })();
              props.handleSearchUsers(targetPattern, isRecord)(e);
            }}
            className={props.classes.searchButton}
          >
            この条件で絞り込む
          </KnowbeButton>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return { loading: state.loading };
};

export const SearchUsersForm = connect(mapStateToProps)(
  withStyles(styles)(SearchUsersFormCore)
);
