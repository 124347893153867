import * as React from "react";
import * as H from "history";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import * as URL from "@constants/url";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { RecordHeader } from "@components/organisms/mgr/KEIKAKUSODAN/record/RecordHeader";
import { Formik, Form, FormikActions, FormikProps } from "formik";
import { MonitoringReportFields } from "./MonitoringReportFields";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  MonitoringReportState,
  RESET_COPY_CONSULTATION
} from "@stores/domain/mgr/KEIKAKUSODAN/monitoringReport/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FieldItem } from "@interfaces/ui/form";
import {
  initialValuesNew,
  initialValuesEdit,
  MonitoringReportValues
} from "@initialize/mgr/KEIKAKUSODAN/record/monitoringReport/initialValues";
import { validation } from "@initialize/mgr/KEIKAKUSODAN/record/monitoringReport/validation";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";
import { resetCopyConsultation } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringReport/actions";
import isEqual from "lodash-es/isEqual";
import { CommonPrintModalWithStaffComment } from "@components/organisms/mgr/common/record/CommonPrintModalWithStaffComment";
import { LoadPlanModal } from "@components/organisms/mgr/Cseg/record/LoadPlanModal";
import {
  TARGET_FLG_TYPE_KEIKAKU,
  TARGET_FLG_TYPE_SHOGAIJI,
  TYPE_CONSULTATION_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      margin: "32px auto"
    },
    con: {
      background: "#fff",
      padding: "32px"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteButton: {
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  user: UsersInFacilityState["user"];
  uifId: string;
  history: H.History;
  pageName: string;
  isNew?: boolean;
  monitoringReportId?: string;
  queryParam?: string;
} & WithStyles<typeof styles>;

type StateProps = {
  facility: FacilityState;
  monitoringReport: MonitoringReportState;
  staff: StaffState;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  fetchMonitoringReport: (
    uifId: string,
    monitoringReportId: string
  ) => Promise<void>;
  copyConsultation: (uifId: string, consultationId: string) => Promise<void>;
  postMonitoringReport: (
    uifId: string,
    values: MonitoringReportValues,
    initialValues?: MonitoringReportValues
  ) => Promise<void>;
  resetCopyConsultation: () => void;
  deleteMonitoringReport: (uifId: string, id: string) => void;
};

type MergeProps = {
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
} & OwnProps &
  StateProps &
  DispatchProps;

type Props = MergeProps;

const MonitoringReportFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    monitoringReport,
    isNew,
    staffOptions,
    staffOptionsAddSnapShot,
    authorValue,
    fetchMonitoringReport,
    user,
    uifId,
    monitoringReportId,
    history,
    planMonitoringMeeting,
    queryParam
  } = props;

  const typeConsultation =
    queryParam === "shogaiji"
      ? TYPE_CONSULTATION_SHOGAIJISODAN
      : TYPE_CONSULTATION_KEIKAKUSODAN;

  const { monitoringReportRecord, consultationCopy } = monitoringReport;
  const [formValues, setFormValues] = React.useState(
    isNew
      ? initialValuesNew(user, typeConsultation)
      : initialValuesEdit(monitoringReportRecord, staffOptions)
  );
  const [isEditing, setIsEditing] = React.useState(!!isNew);
  // 計画の詳細読み込みモーダル
  const [isOpenLoadModal, setIsOpenLoadModal] = React.useState<boolean>(false);
  // 計画の詳細上書きフラグ
  const [isDetailUpdate, setIsDetailUpdate] = React.useState<boolean>(false);
  // 削除確認モーダル
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState(false);

  React.useEffect(() => {
    if (isNew) {
      setFormValues(initialValuesNew(user, typeConsultation));
    } else {
      setFormValues(initialValuesEdit(monitoringReportRecord, staffOptions));
    }
  }, [monitoringReport]);

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const confirmDiscardFormChanges = (
    nextValues: MonitoringReportValues
  ): void => {
    const hasChange = !isEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  // バリデーション
  const validate = (values: MonitoringReportValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (isEditing) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  // 編集
  const onClickEdit = (): void => {
    setIsEditing(true);
  };

  // キャンセル
  const onClickCancel = (
    formikProps: FormikProps<MonitoringReportValues>
  ) => (): void => {
    formikProps.resetForm();
    props.stopHistory(false);
    if (isNew) {
      history.goBack();
    } else {
      setIsEditing(false);
    }
  };

  // 読み込みボタン
  const onClickLoad = () => (): void => {
    setIsOpenLoadModal(true);
  };

  // 読み込み確定ボタン
  const onSubmitLoad = async (id: string): Promise<void> => {
    await props.copyConsultation(uifId, id);
    setIsOpenLoadModal(false);
  };

  // 読み込みキャンセルボタン
  const onCloseLoad = () => (): void => {
    setIsOpenLoadModal(false);
  };

  // 印刷モーダル
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState<boolean>(
    false
  );
  const onOpenPrintModal = (): void => {
    setIsOpenPrintModal(true);
  };
  const onClosePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };
  const onConfirmPrintModal = (paramsOption: string): void => {
    history.push(
      `${URL.RECORD_SUPPORT_PLAN}/print/${uifId}/monitoring_report/${monitoringReportId}${paramsOption}`
    );
  };

  // 削除確認モーダル機能
  const onClickDelete = (): void => {
    setIsOpenDeleteModal(true);
  };
  const onClickCancelDelete = (): void => {
    setIsOpenDeleteModal(false);
  };
  const onSubmitDelete = async (): Promise<void> => {
    await props.deleteMonitoringReport(
      uifId,
      String(monitoringReportRecord.id)
    );
    props.stopHistory(false);
    history.push(`/record/${uifId}/plan_monitoring_meeting`);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 更新
  const onSubmit = async (
    values: MonitoringReportValues,
    actions: FormikActions<MonitoringReportValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (isNew) {
      await props.postMonitoringReport(uifId, values);
      actions.setSubmitting(false);
      props.stopHistory(false);
      history.push(`/record/${uifId}/plan_monitoring_meeting`);
    } else {
      await props.postMonitoringReport(uifId, values, formValues);
      actions.setSubmitting(false);
      props.stopHistory(false);
      await fetchMonitoringReport(uifId, String(monitoringReportRecord.id));
      setIsEditing(false);
    }
  };

  const EditButton = (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onOpenPrintModal}>
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickEdit}>
          編集
        </KnowbeButton>
      </div>
    </div>
  );

  const SubmitButton = (
    formikProps: FormikProps<MonitoringReportValues>
  ): JSX.Element => (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="outline" onClick={onClickCancel(formikProps)}>
          キャンセル
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <FormikSubmitButton
          buttonName="保存する"
          formikProps={formikProps}
          errorAction={submitError}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.wrapper}>
        {/* フォーム */}
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const FormButton = isEditing
              ? SubmitButton(formikProps)
              : EditButton;
            return (
              <>
                <RecordHeader
                  uifId={uifId}
                  recordType="plan_monitoring_meeting"
                  isEditing={isEditing}
                  history={history}
                  pageName={props.pageName}
                  userName={userName}
                  button={FormButton}
                />
                <div className={classes.con}>
                  <Form>
                    <MonitoringReportFields
                      formikProps={formikProps}
                      user={user}
                      authorValue={authorValue}
                      staffOptions={staffOptions}
                      staffOptionsAddSnapShot={staffOptionsAddSnapShot}
                      consultationCopy={consultationCopy}
                      isEditing={isNew || isEditing}
                      onClickLoad={onClickLoad}
                      planMonitoringMeeting={planMonitoringMeeting}
                      resetCopyConsultation={props.resetCopyConsultation}
                      setIsDetailUpdate={setIsDetailUpdate}
                      facilityType={props.facility.facilityType}
                      isNew={isNew}
                      queryParam={queryParam}
                    />
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {!isNew && isEditing && (
          <DeleteButton
            text={`${props.pageName}を削除する`}
            onClick={onClickDelete}
          />
        )}
      </div>

      {/* 計画の詳細読み込みモーダル */}
      <LoadPlanModal
        isOpen={isOpenLoadModal}
        onClickSubmit={onSubmitLoad}
        onClose={onCloseLoad()}
        planMonitoringMeeting={planMonitoringMeeting}
        isUpdate={isDetailUpdate}
        title="支援目標の読み込み"
        targetFlagList={[0, 1]}
        targetFlagType={TARGET_FLG_TYPE_KEIKAKU}
        targetFlagTypeShogaiji={TARGET_FLG_TYPE_SHOGAIJI}
      />

      {/* 削除確認モーダル */}
      <MessageDialog
        isOpen={isOpenDeleteModal}
        title={`${props.pageName}を削除します`}
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDelete}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteButton}
            onClick={onSubmitDelete}
          >
            削除する
          </Button>
        }
      />

      {/* 印刷モーダル */}
      <CommonPrintModalWithStaffComment
        title={props.pageName}
        isOpenPrintModal={isOpenPrintModal}
        onClosePrintModal={onClosePrintModal}
        onConfirmPrintModal={onConfirmPrintModal}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.KEIKAKUSODAN.facility,
    monitoringReport: state.KEIKAKUSODAN.monitoringReport,
    staff: state.staff,
    planMonitoringMeeting:
      state.KEIKAKUSODAN.planMonitoringMeeting.planMonitoringMeeting
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, KEIKAKUSODAN } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    fetchMonitoringReport: (
      uifId: string,
      monitoringReportId: string
    ): Promise<void> =>
      KEIKAKUSODAN.monitoringReportDispatcher(dispatch).fetchMonitoringReport(
        uifId,
        monitoringReportId
      ),
    copyConsultation: (uifId: string, consultationId: string): Promise<void> =>
      KEIKAKUSODAN.monitoringReportDispatcher(dispatch).copyConsultation(
        uifId,
        consultationId
      ),
    postMonitoringReport: (
      uifId: string,
      values: MonitoringReportValues,
      initialValues?: MonitoringReportValues
    ): Promise<void> =>
      KEIKAKUSODAN.monitoringReportDispatcher(dispatch).postMonitoringReport(
        uifId,
        values,
        initialValues
      ),
    resetCopyConsultation: (): {
      type: typeof RESET_COPY_CONSULTATION;
    } => dispatch(resetCopyConsultation()),
    deleteMonitoringReport: (uifId: string, id: string): void => {
      KEIKAKUSODAN.monitoringReportDispatcher(dispatch).deleteMonitoringReport(
        uifId,
        id
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, monitoringReport } = stateProps;

  const author =
    monitoringReport.monitoringReportRecord.author !== null
      ? monitoringReport.monitoringReportRecord.author
      : 0;
  // 作成者名
  const authorValue =
    Object.keys(monitoringReport.monitoringReportRecord).length === 0
      ? ""
      : getSnapOrRealName(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // 作成者
  if (monitoringReport.monitoringReportRecord.author !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      monitoringReport.monitoringReportRecord.author
    );
  }

  return {
    authorValue,
    staffOptions,
    staffOptionsAddSnapShot,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const MonitoringReportForm = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(MonitoringReportFormCore)
);
