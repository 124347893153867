import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography
} from "@material-ui/core";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import OfficeField from "@components/v202104/organisms/mgr/common/facility/items/OfficeField";
import ServiceTypeField from "@components/v202104/organisms/mgr/common/facility/items/ServiceTypeField";
import { FacilityValues } from "@initialize/v202104/mgr/SHISETSUNYUSHO/facility/initialValues";
import {
  FOOD_EXPENSES_TYPE_LIST,
  UTILITY_TYPE_LIST,
  foodExpenses,
  utility
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import UnitsFields from "@components/v202104/organisms/mgr/common/facility/items/UnitsFields";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { connect } from "react-redux";
import { StyleRules } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    perFood: {
      marginBottom: 0,
      marginTop: 43,
      marginLeft: 16
    },
    perDay: {
      marginBottom: 0,
      marginTop: 75,
      marginLeft: 16
    },
    utilityCostsOfDay: {
      marginBottom: 0,
      marginTop: 32,
      marginLeft: 32
    },
    utilityCostsOfMonth: {
      marginBottom: 0,
      marginTop: 64,
      marginLeft: 32
    },
    foodExpenses: {
      marginLeft: -25
    },
    utility: {
      marginTop: -10,
      marginBottom: -35
    },
    section: {
      marginBottom: 32
    },
    food: {
      paddingTop: 17,
      paddingLeft: 17
    },
    note: {
      margin: "0 0 32px",
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)"
    },
    noteSection1: {
      marginBottom: "14px"
    }
  });

type OwnProps = {
  isFetchDone: boolean;
  serviceType: string;
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type StateProps = {
  user: UserState;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

type State = {
  shouldFirstSetup: boolean;
  showPerFood: boolean;
  showPerDay: boolean;
  showUtilityCosts: boolean;
  utilityCostsStyle: string;
  masterSubordinateFlg: boolean;
};

class BasicFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldFirstSetup: true,
      showPerFood: false,
      showPerDay: false,
      showUtilityCosts: false,
      utilityCostsStyle: this.props.classes.utilityCostsOfDay,
      masterSubordinateFlg: this.props.formikProps.values.basic
        .masterSubordinateFlg
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): object | null {
    // fetch完了後の初期化処理だけ行う
    if (!prevState.shouldFirstSetup || !nextProps.isFetchDone) return null;

    const { basic } = nextProps.formikProps.values;
    const costsStyle =
      basic.utility === utility.PER_MONTH
        ? nextProps.classes.utilityCostsOfMonth
        : nextProps.classes.utilityCostsOfDay;

    return {
      shouldFirstSetup: false,
      showPerFood: basic.foodExpenses === foodExpenses.PER_FOOD,
      showPerDay: basic.foodExpenses === foodExpenses.PER_DAY,
      showUtilityCosts:
        basic.utility === utility.PER_DAY ||
        basic.utility === utility.PER_MONTH,
      utilityCostsStyle: costsStyle,
      masterSubordinateFlg: basic.masterSubordinateFlg
    };
  }

  /**
   * 食事単価が1の時、朝食・昼食・夕食を表示
   * 食事単価が2の時、1日を表示
   */
  private onChangeFoodExpenses = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({
      showPerFood: value === foodExpenses.PER_FOOD,
      showPerDay: value === foodExpenses.PER_DAY
    });
  };

  /**
   * 光熱水費が1か2の時、単価を表示
   * styleを差し替え
   */
  private onChangeUtility = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    let utilityCostsStyleType = "";

    if (value === utility.PER_DAY) {
      utilityCostsStyleType = this.props.classes.utilityCostsOfDay;
    } else if (value === utility.PER_MONTH) {
      utilityCostsStyleType = this.props.classes.utilityCostsOfMonth;
    }
    this.setState({
      showUtilityCosts:
        value === utility.PER_DAY || value === utility.PER_MONTH,
      utilityCostsStyle: utilityCostsStyleType
    });
  };

  /**
   * 主従たる事業所の値が変更されたら、checkboxの値を更新
   */
  private onChangeMasterSubordinateFlg = (): void => {
    // 主従たる事業所のcheckboxがONからOFFになるときラジオボタンを初期化
    if (
      this.state.masterSubordinateFlg &&
      this.props.formikProps.values.basic.isMasterRadioValue === "0"
    ) {
      this.props.setFormikFieldValue("basic.isMasterRadioValue", "1");
    }

    this.props.setFormikFieldValue(
      "basic.masterSubordinateFlg",
      !this.state.masterSubordinateFlg
    );
    this.setState((prevState) => {
      return { masterSubordinateFlg: !prevState.masterSubordinateFlg };
    });
  };

  public render(): JSX.Element {
    return (
      <FormPaper>
        <div className={this.props.classes.section}>
          <SectionTitle label="基本情報" />
        </div>
        <FormikTextField
          name="basic.corporationName"
          label="法人名"
          placeholder="株式会社ノウビー"
          maxLength={255}
          size="superLong"
        />
        <OfficeField />
        <ServiceTypeField serviceType={this.props.serviceType} />
        <FormikTextField
          name="basic.representativeName"
          label="代表職員氏名"
          placeholder="山田太郎"
          maxLength={20}
          size="superLong"
        />
        <FormikTextField
          name="basic.capacity"
          label="利用定員数"
          placeholder="0"
          maxLength={10}
          endAdornmentLabel="名"
        />
        <FormGroup>
          <FormikCheckbox
            name="basic.masterSubordinateFlg"
            label="主従たる事業所（一体的な運営を行なっている）"
            onChange={this.onChangeMasterSubordinateFlg}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="masterSubordinateFlag" />}
              />
            }
          />
          {this.props.formikProps.values.basic.masterSubordinateFlg && (
            <FormikRadioButtons
              name="basic.isMasterRadioValue"
              label=""
              options={[
                { label: "主たる事業所", value: "1", disabled: false },
                {
                  label: "従たる事業所",
                  value: "0",
                  disabled: this.props.formikProps.values.basic
                    .multiFunctionOfficeFlg
                }
              ]}
              style={{ marginTop: -20, marginLeft: 32, display: "inline" }}
            />
          )}
        </FormGroup>
        <FormGroup>
          <FormikCheckbox
            name="basic.multiFunctionOfficeFlg"
            label="多機能型事業所"
            disabled={
              this.props.formikProps.values.basic.masterSubordinateFlg &&
              this.props.formikProps.values.basic.isMasterRadioValue === "0"
            }
          />
          {this.props.formikProps.values.basic.multiFunctionOfficeFlg && (
            <FormikTextField
              name="basic.allCapacity"
              label="総利用定員数"
              placeholder="0"
              endAdornmentLabel="人"
              style={{ marginTop: -20, marginLeft: 32 }}
            />
          )}
        </FormGroup>
        <FormikSwitch
          name="basic.operatingUnitFlag"
          label="利用者を2つ以上のグループに分けて運営している"
          disabled={!this.props.user.featureGroup.group_operation_support}
          tooltip={
            <HelpToolTip
              title={
                this.props.user.featureGroup.group_operation_support ? (
                  <HelpTipMessages name="operationUnit" />
                ) : (
                  <HelpTipMessages name="operationUnitNoRecordService" />
                )
              }
            />
          }
        >
          {this.props.user.featureGroup.group_operation_support === 1 && (
            <UnitsFields
              formikProps={this.props.formikProps}
              unitLabel="グループ"
            >
              <div className={this.props.classes.note}>
                <div className={this.props.classes.noteSection1}>
                  記録画面にて利用者をグループ単位で管理します。
                  <br />
                </div>
                <div>
                  グループに所属していない利用者は記録画面に表示されませんので、利用者情報画面からグループを選択してください。
                  <br />
                  グループを削除した場合、そのグループに所属する利用者の設定も解除されます。利用者情報画面からグループを再度選択してください。
                </div>
              </div>
            </UnitsFields>
          )}
        </FormikSwitch>
        <FormikPostalCode
          name="basic.postalCode"
          label="郵便番号"
          placeholder="000-0000"
          maxLength={8}
          startAdornmentLabel="〒"
        />
        <FormikAddress
          prefectureIdName="basic.prefectureId"
          cityIdName="basic.cityId"
          formikProps={this.props.formikProps}
          showRegionType={false}
          showRegionType202104
        />
        <FormikTextField
          name="basic.restAddress"
          label="市区町村以降の住所"
          size="superLong"
        />
        <FormikTextField
          name="basic.tel"
          type="tel"
          label="電話番号"
          maxLength={12}
          placeholder="0000000000"
        />
        <Typography>実施オプションサービス</Typography>
        <FormGroup className={this.props.classes.food}>
          <FormikSwitch name="basic.availableFood" label="食事提供サービス">
            <FormGroup row>
              <div className={this.props.classes.foodExpenses}>
                <FormikRadioButtons
                  name="basic.foodExpenses"
                  label="食事単価"
                  options={FOOD_EXPENSES_TYPE_LIST}
                  onChangeHook={this.onChangeFoodExpenses}
                  style={{ marginBottom: 10 }}
                />
              </div>
              {this.state.showPerFood && (
                <FormikTextField
                  name="basic.foodExpensesBreakfast"
                  label="朝食"
                  maxLength={6}
                  size="smallMedium"
                  placeholder="0"
                  className={this.props.classes.perFood}
                  style={{ marginBottom: 0 }}
                />
              )}
              {this.state.showPerFood && (
                <FormikTextField
                  name="basic.foodExpensesLunch"
                  label="昼食"
                  maxLength={6}
                  size="smallMedium"
                  placeholder="0"
                  className={this.props.classes.perFood}
                  style={{ marginBottom: 0 }}
                />
              )}
              {this.state.showPerFood && (
                <FormikTextField
                  name="basic.foodExpensesSupper"
                  label="夕食"
                  maxLength={6}
                  size="smallMedium"
                  placeholder="0"
                  className={this.props.classes.perFood}
                  style={{ marginBottom: 0 }}
                />
              )}
              {this.state.showPerDay && (
                <FormikTextField
                  name="basic.foodExpensesDay"
                  label="１日"
                  maxLength={6}
                  size="smallMedium"
                  placeholder="0"
                  className={this.props.classes.perDay}
                  style={{ marginBottom: 0 }}
                />
              )}
            </FormGroup>
          </FormikSwitch>
        </FormGroup>
        <FormGroup row>
          <div className={this.props.classes.utility}>
            <FormikRadioButtons
              name="basic.utility"
              label="光熱水費"
              options={UTILITY_TYPE_LIST}
              onChangeHook={this.onChangeUtility}
            />
          </div>
          {this.state.showUtilityCosts && (
            <FormikTextField
              name="basic.utilityCosts"
              label="単価"
              maxLength={6}
              size="smallMedium"
              placeholder="0"
              className={this.state.utilityCostsStyle}
              style={{ marginBottom: 0 }}
            />
          )}
        </FormGroup>
      </FormPaper>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user as UserState
});

export default connect(mapStateToProps)(withStyles(styles)(BasicFields));
