import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import UsersListTemplate from "@components/templates/UsersListTemplate";
import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import ErrorsDialog from "@components/organisms/ErrorsDialog";

type DispatchProps = {
  fetchUser: () => void;
  fetchUsersInFacility: () => void;
  fetchUsersError: () => void;
  clear: () => void;
};

type State = {
  user: UserState;
  facilityUsers: GetFacilityUsersResponse["data"];
};

type Props = State & DispatchProps;

/**
 * 利用者情報
 */
const UsersListCore = (props: Props): JSX.Element => {
  React.useEffect(() => {
    if (!props.user.name) props.fetchUser();
    props.fetchUsersInFacility();
    props.fetchUsersError();
  }, []);

  return (
    <AdminTemplate pageName="利用者情報">
      <UsersListTemplate
        facilityUsers={props.facilityUsers}
        clearForm={props.clear}
      />
      <ErrorsDialog errorsKey="users" />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): State => ({
  user: state.user,
  facilityUsers: state.IDOSHIEN.userInFacility.users
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, IDOSHIEN, errorsDispatcher } = dispatches;
  const userInFacilityDispatched = IDOSHIEN.userInFacilityDispatcher(dispatch);

  return {
    fetchUser: userDispatch(dispatch).me,
    fetchUsersInFacility: userInFacilityDispatched.fetch,
    clear: userInFacilityDispatched.clear,
    fetchUsersError: errorsDispatcher(dispatch).users
  };
};

export const UsersList = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersListCore);
