import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { FieldItem } from "@interfaces/ui/form";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTextField from "@components/molecules/FormikTextField";
import { DeleteOutline as DeleteIcon } from "@material-ui/icons";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTime from "@components/molecules/FormikTime";

// store
import { connect } from "react-redux";
import { FormikProps } from "formik";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";

const styles = (): StyleRules =>
  createStyles({
    row: {
      display: "flex",
      borderBottom: "1px solid #e0e0e0",
      padding: "16px",
      gap: "16px"
    },
    inputArea: {
      flexGrow: 1
    },
    scheduleRow: {
      display: "flex",
      padding: "0",
      gap: "16px"
    },
    recordRow: {
      marginTop: "16px",
      display: "flex",
      padding: "16px",
      gap: "16px",
      borderRadius: "4px",
      background: "#f5f5f5"
    },
    deleteIconArea: {
      width: "28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    deleteIcon: {
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      },
      padding: 0
    }
  });

type OwnProps = {
  index: number;
  onClickDelete: (index: number) => void;
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  isRecord: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

type EditableFieldProps = {
  isEditable: boolean;
  style?: React.CSSProperties;
  children: JSX.Element;
} & React.ComponentProps<typeof ReadonlyTextField>;

const EditableField = ({
  style,
  isEditable,
  children,
  ...props
}: EditableFieldProps): JSX.Element => (
  <div style={style}>
    {isEditable ? children : <ReadonlyTextField {...props} />}
  </div>
);

const SupportProcedureFormTableRowCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { index, onClickDelete, formikProps, isRecord } = props;
  const { values: formikValues } = formikProps;

  const checkOptions: FieldItem[] = [
    { label: "-", value: 0 },
    { label: "◯", value: 1 },
    { label: "△", value: 2 },
    { label: "×", value: 3 }
  ];

  return (
    <div className={classes.row}>
      <div className={classes.inputArea}>
        <div className={classes.scheduleRow}>
          {/* 開始時間 */}
          <EditableField
            style={{ maxWidth: "72px" }}
            value={
              formikValues.supportProcedureFormDetails &&
              formikValues.supportProcedureFormDetails[index] &&
              formikValues.supportProcedureFormDetails[index].startTime
            }
            defaultValue=""
            isEditable={!isRecord}
          >
            <FormikTime
              name={`supportProcedureFormDetails[${index}].startTime`}
              placeholder="00:00"
              size="fullSize"
              style={{ marginBottom: 0 }}
            />
          </EditableField>
          {/* 終了時間 */}
          <EditableField
            value={
              formikValues.supportProcedureFormDetails &&
              formikValues.supportProcedureFormDetails[index] &&
              formikValues.supportProcedureFormDetails[index].endTime
            }
            defaultValue=""
            style={{ maxWidth: "72px" }}
            isEditable={!isRecord}
          >
            <FormikTime
              name={`supportProcedureFormDetails[${index}].endTime`}
              placeholder="00:00"
              size="fullSize"
              style={{ marginBottom: 0 }}
            />
          </EditableField>
          {/* 活動 */}
          <EditableField
            style={{ maxWidth: "180px" }}
            value={
              formikValues.supportProcedureFormDetails &&
              formikValues.supportProcedureFormDetails[index] &&
              formikValues.supportProcedureFormDetails[index].activity
            }
            defaultValue=""
            isEditable={!isRecord}
          >
            <FormikTextField
              name={`supportProcedureFormDetails[${index}].activity`}
              size="fullSize"
              style={{ marginBottom: 0 }}
            />
          </EditableField>
          {/* サービス手順・留意事項 */}
          <EditableField
            style={{ flexGrow: 1 }}
            value={
              formikValues.supportProcedureFormDetails &&
              formikValues.supportProcedureFormDetails[index] &&
              formikValues.supportProcedureFormDetails[index].serviceProcedure
            }
            defaultValue=""
            isEditable={!isRecord}
          >
            <FormikTextField
              name={`supportProcedureFormDetails[${index}].serviceProcedure`}
              size="fullSize"
              style={{ marginBottom: 0 }}
              multiline
            />
          </EditableField>
        </div>
        <div className={classes.recordRow}>
          <FormikSelect
            name={`supportProcedureFormDetails[${index}].check`}
            label="チェック"
            options={checkOptions}
            style={{
              maxWidth: "144px",
              margin: 0
            }}
          />
          <FormikTextField
            name={`supportProcedureFormDetails[${index}].appearance`}
            label="様子"
            style={{
              margin: 0
            }}
            size="fullSize"
            multiline
          />
        </div>
      </div>
      {isRecord ? (
        <></>
      ) : (
        <div className={classes.deleteIconArea}>
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={(): void => onClickDelete(index)}
          />
        </div>
      )}
    </div>
  );
};

export const SupportProcedureFormTableRow = withStyles(styles)(
  connect(null)(SupportProcedureFormTableRowCore)
);
