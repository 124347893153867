import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";

const styles = (): StyleRules =>
  createStyles({
    deleteCancelButton: {
      width: 120,
      marginRight: 16
    },
    deleteButton: {
      width: 120,
      color: "#b00020"
    }
  });

type OwnProps = {
  openDeleteConfirm: boolean;
  onClickCancelDelete: () => void;
  onClickDelete: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DeleteSupportPlanDialogCore = (props: Props): JSX.Element => {
  return (
    <MessageDialog
      isOpen={props.openDeleteConfirm}
      title="個別支援計画書とモニタリング記録を削除します"
      message={
        <span>
          計画書とモニタリング記録が同時に削除されます。
          <br />
          削除した場合、データの復元はできません。
          <br />
          よろしいですか？
        </span>
      }
      closeButton={
        <Button
          color="secondary"
          className={props.classes.deleteCancelButton}
          onClick={props.onClickCancelDelete}
        >
          キャンセル
        </Button>
      }
      actionButton={
        <Button
          className={props.classes.deleteButton}
          onClick={props.onClickDelete}
        >
          削除する
        </Button>
      }
    />
  );
};

export const DeleteSupportPlanDialog = withStyles(styles)(
  DeleteSupportPlanDialogCore
);
