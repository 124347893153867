import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * サービス担当者に対する照会（依頼）内容 の削除
 * @param uifId 事業所所属ユーザーのID
 * @param inquiryId サービス担当者に対する照会（依頼）内容 のID
 */
export const deleteInquiry = async (
  uifId: string,
  inquiryId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/inquiry/${inquiryId}`;
  return request.delete(url);
};
