import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";
import * as queryString from "query-string";

export type ServiceCodePaginationParameters = {
  page: number;
  content: number | null;
  physical_care: number | null;
  calculation_hours: number | null;
  daytime_hours: number | null;
  nighttime_hours: number | null;
  late_night_hours: number | null;
  early_morning_hours: number | null;
};

export type GetServiceCodePaginationResponse = BaseResponse<{
  count: number;
  page: number;
  condition: {
    content: number;
    physical_care: number;
    calculation_hours: number;
    daytime_hours: number;
    nighttime_hours: number;
    late_night_hours: number;
    early_morning_hours: number;
  };
  service_codes: {
    service_code_id: number;
    content: number;
    name: string;
    service_code: string | null;
    physical_care: number | null;
    number_of_supporters: number | null;
    calculation_hours: number | null;
    daytime_hours: number | null;
    nighttime_hours: number | null;
    late_night_hours: number | null;
    early_morning_hours: number | null;
    unit_price: number | null;
  }[];
}>;

// 自治体情報に紐づくサービスコードを取得する(条件から絞り込んで登録する用)
const getServiceCodePagination = (
  municipalityId: string,
  params: ServiceCodePaginationParameters
): Promise<AxiosResponse<GetServiceCodePaginationResponse>> => {
  const url = `${VERSION_URL_201910}/service_code/${municipalityId}/pagination`;
  const query = `?${queryString.stringify(params)}`;
  return request.get<GetServiceCodePaginationResponse>(url + query);
};

export default getServiceCodePagination;
