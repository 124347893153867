import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportReportParams = {
  usage_results?: {
    id: number;
    uif_id: number;
    display_form_flg: number;
  }[];
  support_report: {
    id?: number | null;
    facility_id: number;
    uif_id: number;
    creation_date?: string | null;
    working_start_date?: string | null;
    company_name?: string | null;
    responsible_person?: string | null;
    goal_1?: string | null;
    goal_2?: string | null;
    goal_3?: string | null;
    future_support_content?: string | null;
    person_behavior?: string | null;
    facility_behavior?: string | null;
    related_organizations_behavior?: string | null;
    shared_content?: string | null;
    presentation_date?: string | null;
    author?: number | null;
    author_name?: string | null;
    situations?: {
      id: number | null;
      support_reports_id: number | null;
      number: number;
      content: string | null;
      result: string | null;
    }[];
  };
};

/**
 * 支援レポートを更新をする
 * @param uifId 事業所所属ユーザーのID
 * @param year 年
 * @param month 月(MM)
 * @param params 更新データ
 */
const postSupportReport = async (
  uifId: string,
  params: PostSupportReportParams,
  year: string,
  month: string
): Promise<AxiosResponse<unknown>> => {
  const doubleDigitMonth = month.padStart(2, "0");
  const url = `${VERSION_URL_201910}/support_report/users/${uifId}/${year}/${doubleDigitMonth}`;
  return request.post(url, params);
};

export default postSupportReport;
