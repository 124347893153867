import * as React from "react";
import * as H from "history";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import MuiCheckbox from "@components/molecules/MuiCheckbox";
import PrintFormName from "@components/organisms/mgr/common/record/PrintFormName";
import { unsetDailyOperationsAndSupports } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/actions";
import { Dispatch } from "redux";
import { connect } from "react-redux";

const styles = (): StyleRules =>
  createStyles({
    dialogHeader: {
      marginBottom: 4,
      padding: "16px 24px 20px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 64
    },
    dialogContent: {
      minWidth: 550,
      height: "100%",
      padding: 0,
      "&::-webkit-scrollbar": { display: "none" }
    },
    dialogSubTitle: {
      margin: 0,
      padding: "8px 24px 8px",
      color: "#778899",
      lineHeight: 1.6,
      fontSize: 12,
      fontWeight: "normal",
      whiteSpace: "pre-line"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      marginLeft: 0,
      marginRight: 0
    },
    checkboxLi: {
      margin: "8px 0 0 24px"
    },
    checkbox: {
      margin: "-14px 0 0 0"
    },
    operationCheckbox: {
      marginBottom: "12px"
    },
    space: {
      marginLeft: 20
    },
    radioWrapper: {
      borderBottom: "1px solid #cfd8dc",
      marginBottom: 16,
      "& > div": {
        paddingLeft: 10
      },
      "& > div > div": {
        marginTop: 0,
        "& > label > span": {
          fontSize: 16
        }
      }
    },
    formNameLabel: {
      fontSize: 12,
      color: "#778899",
      margin: "24px 24px 6px"
    }
  });

type BaseProps = {
  isModalOpen: boolean;
  onClose: () => void;
  history: H.History;
} & WithStyles<typeof styles>;

type DailyProps = {
  yyyymmdd: string;
  staffCommentLabel: string;
  operationRecordFlag: {
    use_common_operation_record: number;
    use_units_operation_record: number;
  } | null;
  facilityUnit?: { id: number; unit_name: string; user_ids: number[] } | null;
};

type DispatchProps = {
  unsetDailyOperationsAndSupports: () => void;
};

type Props = DailyProps & BaseProps & DispatchProps;

const OperationDailyPrintModal = (
  props: Props /* , state: State */
): JSX.Element => {
  const [workFlag, setWorkFlag] = React.useState(false);
  const [wholeWorkFlag, setWholeWorkFlag] = React.useState(false);
  const [unitWorkFlag, setUnitWorkFlag] = React.useState(false);
  const [operationUnitIds, setOperationUnitIds] = React.useState<number[]>([]);
  const [staffFlag, setStaffFlag] = React.useState(false);
  const [formName, setFormName] = React.useState("support_record");
  const [dispFlgs, setDispState] = React.useState({
    title: "",
    moveUrl: ""
  });

  // mount & update
  React.useEffect(() => {
    setDispState({
      title: "日々の記録",
      moveUrl: `/record/print/daily/${props.yyyymmdd}`
    });
  }, []);

  // event handler
  const onCloseModal = (): void => {
    props.onClose();
  };
  const changeOperationFlag = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    /**
     * facility_unit_idの値となる配列から、unitIdをtoggle
     * @param InitialOperationUnitIds - 業務日誌のquery param配列（初期値）
     * @param flag - true/unitIdを追加 , false/unitIdを除去
     * @param unitId - 追加, 除去するunitId
     * @return - 更新後の業務日誌のquery param配列
     */
    const toggleUnitId = (
      InitialOperationUnitIds: number[],
      flag: boolean,
      unitId: number
    ): number[] => {
      let allowDuplicateOperationUnitIds = InitialOperationUnitIds;
      if (flag) {
        allowDuplicateOperationUnitIds.push(unitId);
      } else {
        allowDuplicateOperationUnitIds = allowDuplicateOperationUnitIds.filter(
          (el: number) => el !== unitId
        );
      }
      return Array.from(new Set(allowDuplicateOperationUnitIds));
    };
    let flag = false;
    const type = e.target.id;
    let unitId = +e.target.value;
    switch (type) {
      case "work_flg":
        setWorkFlag(!workFlag);
        flag = !workFlag;
        if (
          props.operationRecordFlag &&
          props.operationRecordFlag.use_units_operation_record === 1
        ) {
          unitId = props.facilityUnit ? props.facilityUnit.id : 0;
        }
        break;
      case "whole_work_flg":
        setWholeWorkFlag(!wholeWorkFlag);
        flag = !wholeWorkFlag;
        break;
      case "unit_work_flg":
        setUnitWorkFlag(!unitWorkFlag);
        flag = !unitWorkFlag;
        break;
      default:
    }

    setOperationUnitIds(toggleUnitId(operationUnitIds, flag, unitId));
  };

  const changeStaffFlag = (): void => {
    setStaffFlag(!staffFlag);
  };

  const changeFormName = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setFormName(e.target.value);
  };

  const moveToPreview = (): void => {
    // 印刷ページのstateをリセット
    props.unsetDailyOperationsAndSupports();

    const displayParam = `?display_columns=${formName}${
      staffFlag ? ",staff_comment" : ""
    }`;
    const facilityUnitIdParam =
      operationUnitIds.length > 0
        ? `&facility_unit_id=${operationUnitIds.join(",")}`
        : "";
    const supportFacilityUnitIdParam = props.facilityUnit
      ? `&support_facility_unit_id=${props.facilityUnit.id}`
      : "";

    props.history.push(
      `${dispFlgs.moveUrl}${displayParam}${facilityUnitIdParam}${supportFacilityUnitIdParam}`
    );
  };
  return (
    <div>
      <Dialog open={props.isModalOpen} disableBackdropClick>
        <DialogTitle className={props.classes.dialogHeader}>
          <span>{dispFlgs.title}</span>
          <span className={props.classes.space}>印刷項目</span>
        </DialogTitle>
        <DialogContent className={props.classes.dialogContent}>
          <div className={props.classes.radioWrapper}>
            <p className={props.classes.formNameLabel}>帳票名</p>
            <PrintFormName value={formName} changeFormName={changeFormName} />
          </div>
          <div className={props.classes.dialogSubTitle}>
            以下の項目以外で、入力された全ての項目が印刷されます。
            <br />
            追加で印刷したい項目にチェックしてください。
          </div>
          <div className={props.classes.checkboxLi}>
            {(!props.operationRecordFlag ||
              props.operationRecordFlag.use_common_operation_record !==
                props.operationRecordFlag.use_units_operation_record) && (
              <div className={props.classes.operationCheckbox}>
                <MuiCheckbox
                  id="work_flg"
                  label="業務日誌"
                  checked={workFlag}
                  value="0"
                  onChange={changeOperationFlag}
                  style={{ margin: "-14px 0 0" }}
                />
              </div>
            )}
            {!!props.operationRecordFlag &&
              props.operationRecordFlag.use_common_operation_record === 1 &&
              props.operationRecordFlag.use_units_operation_record === 1 && (
                <div className={props.classes.operationCheckbox}>
                  <MuiCheckbox
                    id="whole_work_flg"
                    label="業務日誌（事業所全体）"
                    checked={wholeWorkFlag}
                    value="0"
                    onChange={changeOperationFlag}
                    style={{ margin: "-14px 0 0" }}
                  />
                  <MuiCheckbox
                    id="unit_work_flg"
                    label={`業務日誌（${
                      props.facilityUnit ? props.facilityUnit.unit_name : ""
                    }）`}
                    checked={unitWorkFlag}
                    value={
                      props.facilityUnit
                        ? props.facilityUnit.id.toString()
                        : "0"
                    }
                    onChange={changeOperationFlag}
                    style={{ margin: "-16px 0 0" }}
                  />
                </div>
              )}
            <div className={props.classes.checkbox}>
              <MuiCheckbox
                id="staff_flg"
                label={`${props.staffCommentLabel}（支援記録）`}
                checked={staffFlag}
                value="1"
                onChange={changeStaffFlag}
                style={{ marginBottom: "10px" }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={props.classes.dialogFooter}>
          <KnowbeButton
            kind="outline"
            onClick={onCloseModal}
            minWidth={125}
            style={{ marginRight: 8 }}
          >
            キャンセル
          </KnowbeButton>
          <KnowbeButton
            onClick={moveToPreview}
            minWidth={125}
            style={{ marginRight: 32 }}
          >
            確定する
          </KnowbeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    unsetDailyOperationsAndSupports: (): void => {
      dispatch(unsetDailyOperationsAndSupports());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OperationDailyPrintModal));
