import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { RATE_GET_JOB_ITEMS_202104 } from "@constants/mgr/SHUROTEICHAKU/variables";

import { createStyles, withStyles, StyleRules } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

const BasicRewardItemFields = (): JSX.Element => (
  <FormPaper>
    <div style={{ marginBottom: 32 }}>
      <SectionTitle label="基本報酬" />
    </div>
    <FormikRadioButtons
      name="subtractionItem.rateGetJob"
      label="就労定着率"
      options={RATE_GET_JOB_ITEMS_202104}
    />
  </FormPaper>
);

export default withStyles(styles)(BasicRewardItemFields);
