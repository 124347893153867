import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportRecordUserResponse = {
  data: {
    date_end_in_service: string | null;
    date_pay_end?: string; // 計画相談のみ
    support_records: {
      id: number | null;
      target_date: string;
      status: number;
      type_consultation?: number; // 計画相談のみ
      support_content: string | null;
      staff_comment: string | null;
      staffs:
        | {
            id: number;
            name: string;
            facility_id: number;
            snapshot_name: string;
          }[]
        | null;
    }[];
    created_at: string | null;
    updated_at: string | null;
    type_consultation?: number; // 計画相談のみ
  };
};

/**
 * 利用者の支援記録のデータを取得する
 * @param uifId 事業所所属ユーザーのID
 * @param year 年
 * @param month 月(MM)
 */
export const getSupportRecordUser = async (
  uifId: string,
  year: string,
  month: string
): Promise<AxiosResponse<GetSupportRecordUserResponse>> => {
  const url = `${VERSION_URL_201910}/supports/users/${uifId}/records/${year}/${month}`;
  return request.get<GetSupportRecordUserResponse>(url);
};
