import { dateToSelectDateValue } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";

/**
 * SelectDateValueの項目の不正値チェックと変換/デフォルト値返却を行う
 */
const initializeSelectDate = (value?: string): SelectDateValue => {
  // DBの日付初期値設定で0000-00-00が混じったことがあったので、その対策をしている
  return value && value !== "0000-00-00"
    ? dateToSelectDateValue(value)
    : { year: "NOT_SELECTED", month: "", day: "" };
};

export default initializeSelectDate;
