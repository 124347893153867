import * as React from "react";
import * as URL from "@constants/url";
import { getUrlParams } from "@/utils/url";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { FacilityState } from "@stores/domain/mgr/CHIIKIIKO/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { ConsultationPrintContent } from "@components/organisms/mgr/CHIIKIIKO/record/consultation/ConsultationPrintContent";
import { ConsultationState } from "@stores/domain/mgr/CHIIKIIKO/consultation/types";
import { CONSULTATION_TARGET_TITLE } from "@constants/mgr/CHIIKIIKO/variables";

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  consultationId: string;
  targetFlg: string;
}>;
type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  consultation: ConsultationState["consultationRecord"];
};
type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchConsultation: (uifId: string, consultationId: string) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const ConsultationPrintCore = (props: Props): JSX.Element => {
  const { search } = props.location;
  const { uifId, consultationId, targetFlg } = props.match.params;
  const { facility, user, consultation } = props;
  // ページ名
  const listName = "計画・報告書・会議記録";
  const pageName = CONSULTATION_TARGET_TITLE[`${targetFlg}`];
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // コメント取得
  const [isStaffComment, setIsStaffComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_comment?: string;
    } = getUrlParams(search);
    setIsStaffComment(queryParameter.display_comment === "staff_comment");
  }, [search]);

  // 印鑑マーク取得
  const [isStampIcon, setIsStampIcon] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_icon?: string;
    } = getUrlParams(search);
    setIsStampIcon(queryParameter.display_icon === "stamp_icon");
  }, [search]);

  // fetch
  React.useEffect(() => {
    props.fetchFacility();
    props.fetchOneUser(uifId);
    props.fetchConsultation(uifId, consultationId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <ConsultationPrintContent
          facility={facility}
          user={user}
          consultation={consultation}
          isStaffComment={isStaffComment}
          isStampIcon={isStampIcon}
          title={CONSULTATION_TARGET_TITLE[`${targetFlg}`]}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.CHIIKIIKO.facility,
    user: state.CHIIKIIKO.userInFacility.user,
    consultation: state.CHIIKIIKO.consultation.consultationRecord
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO } = dispatches;
  return {
    fetchFacility: CHIIKIIKO.facilityDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      CHIIKIIKO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchConsultation: (uifId: string, consultationId: string): Promise<void> =>
      CHIIKIIKO.consultationDispatcher(dispatch).fetchConsultation(
        uifId,
        consultationId
      )
  };
};

export const ConsultationPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationPrintCore);
