import { AxiosResponse } from "axios";
import request from "@api/index";
import { BaseResponse } from "@api/types";
import { VERSION_URL_201910 } from "@config";

export type GetSupportLedgerResponse = BaseResponse<{
  created_at: string | null; // 作成時刻
  updated_at: string | null; // 修正時刻
  support_ledger: {
    creation_date: string | null; // 初期作成日
    // 初期作成者
    creation_staff: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
    } | null;
    update_date: string | null; // 最終更新日
    // 最終更新者
    update_staff: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
    } | null;
    support_ledgers_id: number | null; // 支援台帳のID
    date_begin_in_support: string | null; // 支援開始年月
    date_end_in_support: string | null; // 支援終了年月
    is_ignore_print_support_ids: number[] | null; // 印刷しない支援記録の ID 配列
    // 支援台帳の入力内容
    support_ledger_items: {
      custom_records_category_id: number; // カテゴリの ID
      input: {
        id: number; // カスタム項目の入力内容の ID
        custom_record_item_id: number; // カスタム項目の ID
        input_data: string; // 入力テキスト
        choiced_item_id?: number; // 選択したカスタム選択肢の ID
        checked?: number; // choiced_item_id の選択状態
        choiced_staff_id?: number; // 担当者の ID
        choiced_staff_name_snapshot?: string; // 担当者名のスナップショット
        // 生活歴オブジェクト
        life_histories?: {
          id: number; // 生活歴の ID
          yyyymm: string | null; // 年月
          matter: string | null; // 事項
        }[];
        // 障害・疾病歴オブジェクト
        disability_histories?: {
          id: number; // 障害・疾病歴の ID
          yyyymm: string | null; // 年月
          matter: string | null; // 事項
        }[];
        // 家族・親族オブジェクト
        relatives_info?: {
          id: number; // 家族・親族の ID
          relationship: string | null; // 続柄
          name: string | null; // 氏名
          age: string | null; // 年齢
          profession: string | null; // 職業
          address: string | null; // 住所
          contact_address: string | null; // 連絡先
          remarks: string | null; // 備考
        }[];
        // 関係機関オブジェクト
        related_organization?: {
          id: number; // 関係機関の ID
          target_type: number; // 関係機関先
          facility_name: string | null; // 施設・会社・医療機関名
          kinds: string | null; // 種別・診療科・部門
          manager: string | null; // 担当者
          contact_address: string | null; // 連絡先
          remarks: string | null; // 備考
        }[];
      }[];
    }[];
  };
}>;

/**
 * 地域定着支援台帳のデータ取得
 * @param uifId 事業所所属ユーザーのID
 * @param supportLedgerId 地域定着支援台帳のID
 */
const getSupportLedger = async (
  uifId: string,
  supportLedgerId: string
): Promise<AxiosResponse<GetSupportLedgerResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_ledger/${supportLedgerId}`;
  return request.get<GetSupportLedgerResponse>(url);
};

export default getSupportLedger;
