import { GetSupportPlanUifIdSupportPlanSheets } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheets";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";

/**
 * ActionNames
 */
export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_STARTED =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_STARTED";
export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUCCESS =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUCCESS";
export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_FAILED =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_FAILED";

export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED";
export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS";
export const FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED =
  "SUPPORT_PLAN_SHEETS/FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED";

export const SET_EDIT = "SUPPORT_PLAN_SHEETS/SET_EDIT";
export const UNSET_EDIT = "SUPPORT_PLAN_SHEETS/UNSET_EDIT";

export const POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_STARTED =
  "SUPPORT_PLAN_SHEETS/POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_STARTED";
export const POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_SUCCESS =
  "SUPPORT_PLAN_SHEETS/POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_SUCCESS";
export const POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_FAILED =
  "SUPPORT_PLAN_SHEETS/POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_FAILED";

export const DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED =
  "SUPPORT_PLAN_SHEETS/DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED";
export const DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS =
  "SUPPORT_PLAN_SHEETS/DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS";
export const DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED =
  "SUPPORT_PLAN_SHEETS/DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED";

export type SupportPlanSheetsState = {
  supportPlanSheets: GetSupportPlanUifIdSupportPlanSheets["data"];
  supportPlanSheetsSupportPlanSheetId: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"];
  isEditing: boolean;
};
