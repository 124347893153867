import { InitialState } from "./types";
import { GetInitialResponse } from "@api/requests/initial/getInitial";
import { PostInitialParams } from "@api/requests/initial/postInitial";
import { InitialValues } from "@interfaces/mgr/KEIKAKUSODAN/initial/initialData";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEqual from "lodash-es/isEqual";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

/**
 * Normalized Type
 */
export type NormalizedGetInitialResponse = InitialState;

const normalizeApiParams = (
  result: GetInitialResponse["data"]
): InitialState => {
  const obj = result;
  if (
    result.facility.first_time_bill_date === null ||
    result.facility.first_time_bill_date === "0000-00-00"
  ) {
    obj.facility.first_time_bill_date = "";
  }
  return { ...obj };
};

export const normalizeGetInitialResult = (
  response: GetInitialResponse
): NormalizedGetInitialResponse => normalizeApiParams(response.data);

export const normalizePostInitialParams = (
  params: Partial<PostInitialParams>,
  state: InitialState
): InitialState => {
  const returnFacility = { ...state.facility, ...params.facility };
  return { facility: returnFacility };
};

export const normalizeFormValue = (
  values: InitialValues,
  initialState: InitialState
): PostInitialParams => {
  const postFacilityValue: PostInitialParams["facility"] = {};
  const initialDataValues = values;
  // first_time_bill_dateのフォーム値は日にちの情報を持たない為
  // 1日をデフォルトで設定しておく
  initialDataValues.initialData.facility.first_time_bill_date.day = "1";
  if (
    !isEqual(
      undefinedToReturnValue(initialState.facility.first_time_bill_date, ""),
      selectDateValueToDatePaddingZero(
        initialDataValues.initialData.facility.first_time_bill_date
      )
    )
  ) {
    postFacilityValue.first_time_bill_date = selectDateValueToDatePaddingZero(
      initialDataValues.initialData.facility.first_time_bill_date
    );
  }
  if (
    initialState.facility.total_number_of_users_1_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_1_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_1_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_1_month_before
    );
  }
  if (
    initialState.facility.total_number_of_users_2_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_2_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_2_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_2_month_before
    );
  }
  if (
    initialState.facility.total_number_of_users_3_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_3_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_3_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_3_month_before
    );
  }
  if (
    initialState.facility.total_number_of_users_4_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_4_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_4_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_4_month_before
    );
  }
  if (
    initialState.facility.total_number_of_users_5_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_5_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_5_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_5_month_before
    );
  }
  if (
    initialState.facility.total_number_of_users_6_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_6_month_before
    )
  ) {
    postFacilityValue.total_number_of_users_6_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_users_6_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_1_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_1_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_1_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_1_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_2_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_2_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_2_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_2_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_3_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_3_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_3_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_3_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_4_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_4_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_4_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_4_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_5_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_5_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_5_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_5_month_before
    );
  }
  if (
    initialState.facility.total_number_of_staffs_6_month_before !==
    stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_6_month_before
    )
  ) {
    postFacilityValue.total_number_of_staffs_6_month_before = stringToNumber(
      initialDataValues.initialData.facility
        .total_number_of_staffs_6_month_before
    );
  }

  const postUsersValue: PostInitialParams["users"] = [];

  return {
    facility: postFacilityValue,
    users: postUsersValue
  };
};
