import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";
import { ReportResultPostAPI } from "@stores/v202104/domain/mgr/GroupHome/report/type";

export const postSetUsagePerformanceMonthly = async (
  values: ReportResultPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/usage_performance/monthly/set`;
  return request.post(url, values);
};

export default postSetUsagePerformanceMonthly;
