import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as queryString from "query-string";

import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import UserAgencyReceipt from "@components/organisms/download/print/UserAgencyReceipt";

type OwnProps = {
  dataKey: string;
  query: string;
};

type DispatchProps = {
  fetchUserAgencyReceipt: (dataKey: string) => void;
};

type Props = OwnProps & DispatchProps & AppState;

/**
 * 履歴 > 代理受領書 プレビュー
 */
class UserAgencyReceiptPreview extends React.Component<Props> {
  public componentWillMount(): void {
    const { dataKey } = this.props;
    this.props.fetchUserAgencyReceipt(dataKey);
  }

  public render(): JSX.Element | string {
    if (
      this.props.invoice.invoiceUserAgencyReceiptData &&
      this.props.invoice.invoiceUserAgencyReceiptData.length === 0
    ) {
      return "";
    }
    const queryParams = queryString.parse(this.props.query);
    const targetDate = queryParams.target_date;
    const noticeDate = queryParams.notice_date;
    return (
      <PreviewTemplate>
        {this.props.invoice.invoiceUserAgencyReceiptData.map((data, key) => {
          const uniqueKey = `user-agency-receipt-${key}`;
          return (
            <UserAgencyReceipt
              key={uniqueKey}
              data={data}
              targetDate={targetDate}
              noticeDate={noticeDate}
            />
          );
        })}
      </PreviewTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);
  return {
    fetchUserAgencyReceipt: (dataKey: string): void => {
      invoiceDispatched.downloadUserAgencyReceiptJson(dataKey);
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserAgencyReceiptPreview);
