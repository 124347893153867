import * as types from "./types";
import { GetAssessmentResponse } from "@api/requests/assessment/getAssessment";
import { GetAssessmentListResponse } from "@api/requests/assessment/getAssessmentList";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchAssessmentStarted = () =>
  ({ type: types.FETCH_ASSESSMENT_STARTED } as const);
export const fetchAssessmentSuccess = (res: GetAssessmentResponse["data"]) =>
  ({ type: types.FETCH_ASSESSMENT_SUCCESS, payload: res } as const);
export const fetchAssessmentFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_ASSESSMENT_FAILED, error: err } as const);

export const fetchAssessmentListStarted = () =>
  ({ type: types.FETCH_ASSESSMENT_LIST_STARTED } as const);
export const fetchAssessmentListSuccess = (
  res: GetAssessmentListResponse["data"]
) => ({ type: types.FETCH_ASSESSMENT_LIST_SUCCESS, payload: res } as const);
export const fetchAssessmentListFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_ASSESSMENT_LIST_FAILED, error: err } as const);

export const postAssessmentStarted = () =>
  ({ type: types.POST_ASSESSMENT_STARTED } as const);
export const postAssessmentSuccess = () =>
  ({ type: types.POST_ASSESSMENT_SUCCESS } as const);
export const postAssessmentFailed = (err: ErrorResponse) =>
  ({ type: types.POST_ASSESSMENT_FAILED, error: err } as const);
export const unsetAssessment = () =>
  ({ type: types.UNSET_ASSESSMENT } as const);

export const deleteAssessmentStarted = () =>
  ({ type: types.DELETE_ASSESSMENT_STARTED } as const);
export const deleteAssessmentSuccess = () =>
  ({ type: types.DELETE_ASSESSMENT_SUCCESS } as const);
export const deleteAssessmentFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_ASSESSMENT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchAssessmentStarted>
  | ReturnType<typeof fetchAssessmentSuccess>
  | ReturnType<typeof fetchAssessmentFailed>
  | ReturnType<typeof fetchAssessmentListStarted>
  | ReturnType<typeof fetchAssessmentListSuccess>
  | ReturnType<typeof fetchAssessmentListFailed>
  | ReturnType<typeof postAssessmentStarted>
  | ReturnType<typeof postAssessmentSuccess>
  | ReturnType<typeof postAssessmentFailed>
  | ReturnType<typeof unsetAssessment>
  | ReturnType<typeof deleteAssessmentStarted>
  | ReturnType<typeof deleteAssessmentSuccess>
  | ReturnType<typeof deleteAssessmentFailed>;
