import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as navigationTransitionActions from "@stores/ui/navigationTransition/actions";
import * as H from "history";
import * as URL from "@constants/url";
import dispatches from "@stores/dispatches";
import { normalizePostInquiryDataToAPI } from "./normalizer";
import { InquiryApi } from "@api/requests/inquiry";
import { InquiryValues } from "@initialize/mgr/KEIKAKUSODAN/record/inquiry/initialValues";

export const fetchInquiry = (dispatch: Dispatch) => async (
  uifId: string,
  inquiryId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchInquiryStarted());
  await InquiryApi.getInquiry(uifId, inquiryId)
    .then((res) => {
      dispatch(actions.fetchInquirySuccess({ inquiry: res.data.data }));
    })
    .catch((e) => {
      dispatch(actions.fetchInquiryFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postInquiry = (dispatch: Dispatch) => async (
  uifId: string,
  inquiry: InquiryValues,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postInquiryStarted());
  const normalizeParam = normalizePostInquiryDataToAPI(inquiry);
  await InquiryApi.postInquiry(uifId, normalizeParam)
    .then(() => {
      dispatch(actions.postInquirySuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 新規場合（編集ではないとき）
      if (inquiry.support_inquiries_id === null) {
        history.push(`${URL.RECORD_SUPPORT_PLAN}/${uifId}/inquiry`);
      }
    })
    .catch((e) => {
      dispatch(actions.postInquiryFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteInquiry = (dispatch: Dispatch) => async (
  uifId: string,
  InquiryId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteInquiryStarted());
  await InquiryApi.deleteInquiry(uifId, InquiryId)
    .then(() => {
      dispatch(actions.deleteInquirySuccess());
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(actions.deleteInquiryFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchInquiry: (uifId: string, inquiryId: string) => Promise<void>;
  postInquiry: (
    uifId: string,
    inquiry: InquiryValues,
    history: H.History
  ) => Promise<void>;
  deleteInquiry: (uifId: string, inquiryId: string) => Promise<void>;
};

export const KEIKAKUSODANInquiryDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchInquiry: fetchInquiry(dispatch),
  postInquiry: postInquiry(dispatch),
  deleteInquiry: deleteInquiry(dispatch)
});
