import * as types from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import * as actions from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/actions";
import { Checkbox } from "@constants/variables";

// first or reset values
const userDefaultValues: types.UsersInFacilityState["user"] = {
  user_in_facility: {
    none_recipient_number_flg: Checkbox.OFF,
    gender: "1",
    income_kind: "1",
    subsidized_flg: Checkbox.OFF,
    subsidized_unit_flg: "1",
    uplimit_facility_flg: Checkbox.OFF,
    result_of_management: "1",
    create_support_plan_flg: Checkbox.ON,
    uplimit_controlled_by: "1"
  },
  user_in_facility_kyotakukaigo: {
    users_in_facility_id: 0,
    disability_class: 0,
    income_kind: 0,
    facility_unit_id: 0,
    basis_training_supporter_flg: 0,
    special_area_flg: 0,
    date_begin_special_area: "",
    date_end_special_area: "",
    pay_months_agreed_physical_care: 0,
    pay_times_agreed_physical_care: 0,
    pay_months_agreed_outpatient_care_with_physical_care: 0,
    pay_times_agreed_outpatient_care_with_physical_care: 0,
    pay_months_agreed_housework_assistance: 0,
    pay_times_agreed_housework_assistance: 0,
    pay_months_agreed_outpatient_care: 0,
    pay_times_agreed_outpatient_care: 0,
    pay_months_agreed_getting_on_and_off: 0
  }
};

const initialState: types.UsersInFacilityState = {
  users: [],
  usersList: [],
  user: userDefaultValues,
  doneUpsertUser: false,
  userValidation: {}
};

export const KYOTAKUKAIGOUserInFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.UsersInFacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, users: action.payload.data };
    case types.FETCH_FAILED:
      return { ...state };
    case types.FETCH_STARTED_TARGETDAY:
      return { ...state };
    case types.FETCH_SUCCESS_TARGETDAY:
      return { ...state, usersList: action.payload.data };
    case types.FETCH_FAILED_TARGETDAY:
      return { ...state };
    case types.FETCH_ONE_STARTED:
      return { ...state };
    case types.FETCH_ONE_SUCCESS:
      return { ...state, user: action.payload };
    case types.FETCH_ONE_FAILED:
      return { ...state };
    case types.CREATE_STARTED:
      return { ...state };
    case types.CREATE_SUCCESS:
      return { ...state };
    case types.CREATE_FAILED:
      return { ...state };
    case types.UPDATE_STARTED:
      return { ...state };
    case types.UPDATE_SUCCESS:
      return { ...state };
    case types.UPDATE_FAILED:
      return { ...state };
    case types.DELETE_STARTED:
      return { ...state };
    case types.DELETE_SUCCESS:
      return { ...state };
    case types.DELETE_FAILED:
      return { ...state };
    case types.CLEAR:
      return { ...state, user: userDefaultValues };
    default:
      return state;
  }
};
