import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/types";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";

import { ErrorsState } from "@stores/domain/errors/types";
import RecordBatchPrinting from "@components/organisms/mgr/common/record/RecordBatchPrinting";
import { RecordEachUser } from "@components/organisms/mgr/common/record/recordEachUser/RecordEachUser";
import { BASE_PUBLIC_URL } from "@config";
import { RecordEachUserButtonsType } from "@constants/variables";

type StateProps = {
  users: UsersInFacilityState["users"];
  errorState: ErrorsState["records"]["data"];
};
type DispatchProps = {
  fetchUsers: () => Promise<void>;
  fetchErrors: () => Promise<void>;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

/**
 * 記録/利用者ごとで表示させるボタンのオブジェクト
 * @param uifId url作成用のuifIdを返す
 */
const buttonList: React.ComponentProps<typeof RecordEachUser>["buttonList"] = (
  uifId: number
): RecordEachUserButtonsType => {
  return [
    {
      name: "個別支援計画",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support_plan`
    },
    { name: "支援記録", url: `${BASE_PUBLIC_URL}#/record/${uifId}/support` },
    { name: "作業記録", url: `${BASE_PUBLIC_URL}#/record/${uifId}/work` },
    {
      name: "面談記録",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/interview`,
      isLastButton: true
    }
  ];
};

/**
 * 利用者サマリ
 */
const RecordUsersSummaryCore = (props: Props): JSX.Element => {
  const { fetchUsers, fetchErrors, users, errorState, history } = props;

  return (
    <AdminTemplate pageName="利用者ごと">
      {/* 記録の一括印刷 */}
      <RecordBatchPrinting history={history} />
      {/* 利用ごとの記録 */}
      <RecordEachUser
        users={users}
        errorState={errorState}
        buttonList={buttonList}
        fetchUsers={fetchUsers}
        fetchErrors={fetchErrors}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  users: state.JIRITSUKUNRENSEIKATSU.userInFacility.users,
  errorState: state.errors.records.data
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { JIRITSUKUNRENSEIKATSU, errorsDispatcher } = dispatches;
  const userInFacilityDispatcher = JIRITSUKUNRENSEIKATSU.userInFacilityDispatcher(
    dispatch
  );
  const errorsDispatches = errorsDispatcher(dispatch);
  return {
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch(),
    fetchErrors: (): Promise<void> => errorsDispatches.records()
  };
};

export const RecordUsersSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordUsersSummaryCore);
