import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  id: number | null;
  setting_type: number;
  category_type: number;
  name: string;
};

/**
 * カスタム項目を追加、編集する
 */
export const postCustomRecordsCategory = async (
  param: RequestParam
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_records_category`;
  return request.post(url, param);
};

export default postCustomRecordsCategory;
