import * as React from "react";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
// store
import { connect } from "react-redux";
import {
  ServiceDeliveryTypeInterface,
  SERVICE_DELIVERY_DAILY,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
// ui
import { ServiceDeliveryBoxCells } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryBoxCells";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { groupBy } from "lodash-es";
// variables
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    box: {
      marginTop: 8,
      width: "100%",
      backgroundColor: "#fff",
      borderRadius: 4
    },
    index: {
      fontWeight: "bold",
      marginLeft: 16,
      height: 54,
      width: "100%",
      fontSize: 16,
      lineHeight: 1.75,
      padding: "12px 0"
    }
  });

type OwnProps = {
  records:
    | ServiceDeliveryState["monthlyRecord"]
    | ServiceDeliveryState["dailyRecord"];
  type: ServiceDeliveryTypeInterface["type"];
  changeEditMode: (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    supportProcedureFormsId: number
  ) => void;
  facilityType: FacilityType;
  openDeleteModal: (serviceDeliveryRecordsId: number) => void;
};

type ServiceDeliverDictionary = {
  [key: number]: ServiceDeliveryState["dailyRecord"]["service_delivery"];
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryTableCore = (props: Props): JSX.Element => {
  const { records, type, classes } = props;

  const rows = records.service_delivery;

  // データを利用者単位でまとめる(日ごと画面用)
  const groupByUifId = (
    arr: ServiceDeliveryState["dailyRecord"]["service_delivery"]
  ): ServiceDeliverDictionary => {
    const targetObj = {} as ServiceDeliverDictionary;
    arr.forEach((item) => {
      if (
        Object.keys(targetObj).indexOf(`_${item.users_in_facility_id}`) !== -1
      ) {
        targetObj[`_${item.users_in_facility_id}`].push({ ...item });
      } else {
        targetObj[`_${item.users_in_facility_id}`] = [{ ...item }];
      }
    });
    return targetObj;
  };

  const getKey = (
    data: ServiceDeliveryState["dailyRecord"]["service_delivery"][number]
  ): string => {
    return `${data.service_delivery_records_id}-${data.support_procedure_forms_id}-${data.inout_results_id}`;
  };

  const dailyTable = (): JSX.Element[] => {
    const groupedUsers = groupByUifId(rows);
    return Object.values(groupedUsers).map((dataList) => {
      return (
        <div className={`${classes.box}`} key={`box-${getKey(dataList[0])}`}>
          <>
            <div
              key={`${getKey(dataList[0])}-sei-mei`}
              className={`${classes.index}`}
            >
              {dataList[0].name_sei}
              {dataList[0].name_mei}
            </div>
            {dataList.map((params) => {
              return (
                <ServiceDeliveryBoxCells
                  key={`table-cells-${getKey(params)}`}
                  params={params}
                  idx={`${params.service_delivery_records_id}`}
                  changeEditMode={props.changeEditMode}
                  type={type}
                  lastFlg={false}
                  openDeleteModal={props.openDeleteModal}
                  facilityType={props.facilityType}
                />
              );
            })}
          </>
        </div>
      );
    });
  };
  const monthlyTable = (): JSX.Element[] => {
    const groupedUsers = Object.values(
      groupBy(rows, "target_date")
    ) as ServiceDeliveryState["monthlyRecord"]["service_delivery"][];
    return Object.values(groupedUsers).map((dataList) => {
      return (
        <div className={`${classes.box}`} key={`box-${getKey(dataList[0])}`}>
          <>
            <div
              key={`${getKey(dataList[0])}-target-date`}
              className={classes.index}
            >
              <CustomDateLabel
                date={dataList[0].target_date}
                dateFormat="Do（dd）"
                holiday={dataList[0].is_holiday}
              />
            </div>
            {dataList.map((params) => {
              return (
                <ServiceDeliveryBoxCells
                  key={`table-cells-${getKey(params)}`}
                  params={params}
                  idx={`${params.service_delivery_records_id}`}
                  changeEditMode={props.changeEditMode}
                  type={type}
                  lastFlg={false}
                  openDeleteModal={props.openDeleteModal}
                  facilityType={props.facilityType}
                />
              );
            })}
          </>
        </div>
      );
    });
  };
  return <>{type === SERVICE_DELIVERY_DAILY ? dailyTable() : monthlyTable()}</>;
};

export const ServiceDeliveryTable = connect(null)(
  withStyles(styles)(ServiceDeliveryTableCore)
);
