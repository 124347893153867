import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { SupportsState } from "@stores/domain/supports/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import SectionTitle from "@components/atoms/SectionTitle";
import DropDown from "@components/atoms/DropDown";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import UserSummaryModal from "@components/organisms/mgr/common/record/UserSummaryModal";
import {
  RECORD_MODAL_TYPE,
  RECORD_MODAL_TYPE_TARGET,
  CUSTOM_RECORD_TARGET_TYPE
} from "@constants/variables";
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";
import { getStaffCommentLabel } from "@utils/domain/customRecords/getStaffCommentLabel";

const styles = (): StyleRules =>
  createStyles({
    paperContainer: {
      padding: "30px 32px 32px 32px",
      margin: 16
    },
    text: {
      fontSize: 16,
      color: "#37474f",
      margin: "16px 0 28px 0"
    },
    selectRecord: {
      width: 214,
      height: 52,
      marginTop: 0,
      marginRight: 16,
      marginBottom: 0
    },
    printButton: {
      marginTop: 12
    }
  });

type OwnProps = {
  history: H.History;
  options?: { label: string; value: string }[];
  defaultSelected?: string;
  optionalCustomInfo?: GetOptionalCustomInfoResponse["data"];
  isUseCustomRecord?: boolean;
  hasReduceDataNote?: boolean;
  unsetUsersSummary?: () => void;
};

type StateProps = {
  supportsMonths: SupportsState["report"]["months"];
  supportsUsers: SupportsState["report"]["users"];
  customRecords: CustomRecordsState;
  user: UserState;
};

type DispatchProps = {
  fetchReportMonths: (target: string) => Promise<void>;
  fetchReportUsers: (target: string, month: string) => void;
  fetchCustomRecords: (target: number) => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const RecordBatchPrinting = ({
  options = [
    { label: "記録を選択", value: "" },
    { label: "支援記録", value: "support" },
    { label: "作業記録", value: "work" },
    { label: "面談記録", value: "interview" }
  ],
  ...props
}: Props): JSX.Element => {
  const [isOpenPrintModal, setOpenDetailModal] = React.useState(false);
  const [selectedRecord, setSelectedRecord] = React.useState(
    props.defaultSelected || ""
  );
  const [modalType, setModalType] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedRecord(event.target.value);
  };
  const openPrintModal = async (): Promise<void> => {
    const nextModalType = RECORD_MODAL_TYPE[selectedRecord];
    await props.fetchReportMonths(RECORD_MODAL_TYPE_TARGET[nextModalType]);
    if (props.isUseCustomRecord) {
      await props.fetchCustomRecords(CUSTOM_RECORD_TARGET_TYPE.support);
    }
    setModalType(nextModalType);
    setOpenDetailModal(true);
  };
  const closePrintModal = (): void => {
    setOpenDetailModal(false);
  };
  // 職員考察がカスタムされている場合にラベル名を取得
  const staffCommentLabel = getStaffCommentLabel({
    serviceType: props.user.facility_type,
    customRecords: props.customRecords
  });
  return (
    <Paper elevation={0} className={props.classes.paperContainer}>
      <SectionTitle label="記録の一括印刷" isTitleNoMargin />
      <Typography
        component="p"
        variant="caption"
        className={props.classes.text}
      >
        任意の利用者の記録を一括で印刷できます。
      </Typography>
      <DropDown
        id="selectRecord"
        label="対象記録"
        isError={false}
        size="textFieldSmall"
        options={options}
        value={selectedRecord}
        styles={props.classes.selectRecord}
        onChange={handleChange}
      />
      <KnowbeButton
        className={props.classes.printButton}
        onClick={openPrintModal}
        disabled={!selectedRecord}
      >
        印刷
      </KnowbeButton>
      {isOpenPrintModal && (
        <UserSummaryModal
          isModalOpen={isOpenPrintModal}
          onClose={closePrintModal}
          onMonthChange={props.fetchReportUsers}
          modalType={modalType}
          targetMonths={props.supportsMonths}
          targetUsers={props.supportsUsers}
          history={props.history}
          staffCommentLabel={staffCommentLabel}
          optionalCustomInfo={props.optionalCustomInfo}
          hasReduceDataNote={props.hasReduceDataNote}
          unsetUsersSummary={props.unsetUsersSummary}
        />
      )}
    </Paper>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  supportsMonths: state.supports.report.months,
  supportsUsers: state.supports.report.users,
  customRecords: state.customRecords,
  user: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportsDispatcher, customRecords } = dispatches;
  const supportsDispatches = supportsDispatcher(dispatch);
  const customRecordsDispatches = customRecords(dispatch);
  return {
    fetchReportMonths: async (target: string): Promise<void> =>
      supportsDispatches.fetchReportMonths(target),
    fetchReportUsers: (target: string, month: string): void => {
      supportsDispatches.fetchReportUsers(target, month);
    },
    fetchCustomRecords: async (target: number): Promise<void> =>
      customRecordsDispatches.fetchCustomRecords(target)
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(RecordBatchPrinting)
);
