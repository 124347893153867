import * as React from "react";

import * as classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { FormikProps, getIn } from "formik";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      width: "100%",
      margin: "32px 16px",
      minWidth: "328px",
      maxHeight: "calc(100% - 64px)"
    },
    dialogTitle: {
      padding: "16px 24px",
      color: "#37474f",
      fontSize: 20,
      letterSpacing: 0.25,
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc"
    },
    dialogContent: {
      height: "100%",
      padding: 0,
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    dialogActions: {
      borderTop: "1px solid #cfd8dc",
      margin: 0,
      padding: "16px 24px"
    },
    contentSection: {
      padding: "4px 24px 4px 12px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(117, 117, 117, 0.25)"
      }
    },
    contentItemCheckBox: {
      display: "flex",
      alignItems: "center"
    },
    button: {
      width: "50%",
      minWidth: 132,
      height: 40,
      boxShadow: "none",
      margin: 0
    },
    cancel: {
      borderColor: "#ccc",
      marginRight: 16
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  onClose: () => void;
  formikProps: FormikProps<unknown>;
  insertPhraseFieldName: string;
  insertPhraseId: number;
  customRecords?: CustomRecordsState;
  customRecordsWithCategory?: CustomRecordsWithCategoryState[0]["custom_record_items"];
};

type Props = OwnProps & WithStyles<typeof styles>;

type InsertPhraseChoices = {
  id: number;
  custom_record_item_id: number;
  name: string;
  default_choice: number | null;
  hidden: number;
  default_choice_input_type?: number | null;
}[];

const InsertPhraseModalCore = (props: Props): JSX.Element | null => {
  if (
    props.insertPhraseFieldName === "" ||
    props.insertPhraseId === 0 ||
    (!props.customRecords && !props.customRecordsWithCategory)
  )
    return null;
  const { classes } = props;
  const [checkIds, setCheckIds] = React.useState<number[]>([]);

  let recordName = "";
  let choices: InsertPhraseChoices = [];
  if (props.customRecords) {
    const customRecord = props.customRecords.find(
      (record) => record.id === props.insertPhraseId
    );
    recordName = customRecord ? customRecord.name : "";
    choices = customRecord
      ? customRecord.choices.filter((c) => c.hidden === 0)
      : [];
  }

  if (props.customRecordsWithCategory) {
    const customRecordWithCategory = props.customRecordsWithCategory.find(
      (record) => record.id === props.insertPhraseId
    );
    recordName = customRecordWithCategory ? customRecordWithCategory.name : "";
    choices = customRecordWithCategory
      ? customRecordWithCategory.custom_record_item_choices.filter(
          (c) => c.hidden === 0
        )
      : [];
  }

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const id = +event.target.value;
    if (event.target.checked) {
      // 重複削除したIds
      setCheckIds(
        [...checkIds, id].filter((x, i, self) => {
          return self.indexOf(x) === i;
        })
      );
    } else {
      setCheckIds(checkIds.filter((x) => x !== id));
    }
  };

  const handleOnClose = (): void => {
    props.onClose();
    setCheckIds([]);
  };

  const handleOnSubmit = async (): Promise<void> => {
    const insertText: string = checkIds
      .sort((a, b) => {
        return a - b;
      })
      .map((id) => {
        const choice = choices.find((c) => c.id === id);
        return choice ? choice.name : "";
      })
      .join("\n");
    const currentText = getIn(
      props.formikProps.values,
      props.insertPhraseFieldName
    );
    const text = currentText ? `${currentText}\n${insertText}` : insertText;
    props.formikProps.setFieldValue(props.insertPhraseFieldName, text);
    props.onClose();
    setCheckIds([]);
  };

  return (
    <Dialog
      open={props.isModalOpen}
      disableBackdropClick
      maxWidth={false}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle disableTypography className={classes.dialogTitle}>
        {recordName}
        {"　定型文の挿入"}
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {choices.map((choice) => {
          return (
            <div
              className={classes.contentSection}
              key={`content-${choice.id}`}
            >
              <div
                className={classes.contentItemCheckBox}
                key={`checkbox-${choice.id}`}
              >
                <FormControlLabel
                  style={{
                    margin: "0px",
                    display: "flex"
                  }}
                  control={
                    <Checkbox
                      id={`item-checkbox-${choice.id}`}
                      checked={checkIds.includes(choice.id)}
                      onChange={handleChangeCheckbox}
                      value={`${choice.id}`}
                    />
                  }
                  label={
                    <span
                      style={{
                        display: "inline-block",
                        fontSize: 16,
                        whiteSpace: "pre-wrap",
                        overflowWrap: "anywhere"
                      }}
                    >
                      {choice.name}
                    </span>
                  }
                />
              </div>
            </div>
          );
        })}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton
          className={classNames(classes.button, classes.cancel)}
          color="secondary"
          onClick={handleOnClose}
          kind="outline"
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleOnSubmit}
          disabled={!checkIds.length}
        >
          挿入する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const InsertPhraseModal = withStyles(styles)(InsertPhraseModalCore);
