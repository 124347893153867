import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostMeetingRecord = {
  meeting_record: {
    meeting_records_id: number | null;
    creation_date: string;
    creation_staff_id?: number | null;
    meeting_date?: string | null;
    meeting_start_time?: string | null;
    meeting_end_time?: string | null;
    meeting_place?: string | null;
    section?: number | null;
    attendees?:
      | {
          affiliation: string;
          name: string;
        }[]
      | null;
    consideration_item?: string | null;
    consideration_contents?: string | null;
    consideration_results?: string | null;
    others?: string | null;
    type_consultation?: number;
  };
};

/**
 * サービス担当者会議議事録のデータ保存
 * @param uifId 事業所所属ユーザーのID
 */
export const postMeetingRecord = async (
  uifId: number,
  param: PostMeetingRecord
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/meeting_record`;
  return request.post(url, param);
};
