import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  target: string;
  setting: number;
};

/**
 * 事業所全体/ユニットごとの業務日誌を記録するかどうか設定する。
 */
export const postUseOperationRecord = async (
  param: RequestParam
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_records/use_operation_record`;
  return request.post(url, param);
};

export default postUseOperationRecord;
