/**
 * ActionNames
 */
export const FETCH_CHIIKIIKO_MONTHLY_STARTED =
  "CHIIKIIKO/REPORT_202104/FETCH_MONTHLY_STARTED";
export const FETCH_CHIIKIIKO_MONTHLY = "CHIIKIIKO/REPORT_202104/FETCH_MONTHLY";
export const FETCH_CHIIKIIKO_MONTHLY_FAILED =
  "CHIIKIIKO/REPORT_202104/FETCH_MONTHLY_FAILED";
export const POST_CHIIKIIKO_REPORT_STARTED =
  "CHIIKIIKO/REPORT_202104/POST_STARTED";
export const POST_CHIIKIIKO_REPORT_MONTHLY =
  "CHIIKIIKO/REPORT_202104/POST_REPORT_MONTHLY";
export const POST_CHIIKIIKO_REPORT_FAILED =
  "CHIIKIIKO/REPORT_202104/POST_REPORT_FAILED";

export type InoutConsultationResultsState = {
  id: number | null;
  inoutResultDailyId: number | null;
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  targetDate: string;
  status: number | null;
  trialUseType: number | null;
  trialStayType: number | null;
  firstAdditionFlg: boolean;
  housingSupportCooperationFlg: boolean;
  housingSupportPromotionFlg: boolean;
  dischargeMonthAdditionFlg: boolean;
  dateBeginHospital: string | null;
  dateEndHospital: string | null;
  shortTimeForPsychiatricHospitalFlg: boolean;
  memoStatus: string | null;
  memoTrial: string | null;
  memo: string | null;
  isHoliday: boolean;
};

export type ReportSummary = {
  intensiveSupportCount: number | null;
};

export type ReportState = {
  reportMonthly: {
    inoutConsultationResults: InoutConsultationResultsState[];
    summary: ReportSummary;
  };
};
