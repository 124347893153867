/**
 * 未来かどうか
 * @param startDate
 * @param endDate
 * @param options
 */

export const futureDaySelectOnly = (
  startDate: string,
  endDate: string,
  options = { startLabel: "対象開始日", endLabel: "対象終了日" }
): string | undefined => {
  let errorMessage;
  if (startDate && endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    if (Number.isNaN(start.getTime()) || Number.isNaN(end.getTime())) {
      return errorMessage;
    }

    if (end.getTime() <= start.getTime()) {
      errorMessage = `${options.endLabel}は${options.startLabel}よりも後に設定してください`;
    }
  }
  return errorMessage;
};
