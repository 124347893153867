import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate201910 } from "@components/templates/AdminTemplate201910";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  TANKINYUSHOReportTabInfo,
  TANKINYUSHO_REPORT_TABS_INFO
} from "@constants/mgr/TANKINYUSHO/variables";
import * as URL from "@constants/url";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import UsagePerformanceDailyList from "@components/v201910/organisms/mgr/TANKINYUSHO/report/UsagePerformanceDailyList";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { checkAfterOrEqualTo202104 } from "@utils/domain/report/checkAfterOrEqualTo202104";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{}, {}, { targetDate: Date }> &
  WithStyles<typeof styles>;

/**
 * 利用実績
 */
const Report: React.FunctionComponent<Props> = (props: Props) => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();
  const goToV202104 = (targetDate: Date): void => {
    props.history.replace({
      pathname: URL.REPORT_DAILY,
      state: { targetDate }
    });
  };
  // 改定後の日付を参照している時は報酬改定202104版に飛ばす
  if (checkAfterOrEqualTo202104(currentDate)) {
    goToV202104(new Date());
    return null;
  }
  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === TANKINYUSHOReportTabInfo.MONTHLY) {
      props.history.push(URL.REPORT_MONTHLY);
    }
  };
  return (
    <AdminTemplate201910 pageName="利用実績" zIndex>
      <>
        <KnowbeTabs
          key="tab"
          tabInfo={TANKINYUSHO_REPORT_TABS_INFO}
          onChange={onChangeTag}
          value={TANKINYUSHOReportTabInfo.DAILY}
        />
        <UsagePerformanceDailyList
          initialDate={currentDate}
          goToV202104={goToV202104}
          // propsをstateに入れた時の不具合予防にkeyを入れる
          key={currentDate.getTime()}
        />
        <ErrorsDialog errorsKey="inout" />
        <NavigationTransitionPrompt />
      </>
    </AdminTemplate201910>
  );
};

export default withStyles(styles)(Report);
