import * as React from "react";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

// ui
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { ServiceDeliveryMonthlyRecord } from "@components/organisms/record/serviceDelivery/ServiceDeliveryMonthlyRecord";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";

// variables
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import * as format from "date-fns/format";
import * as URL from "@constants/url";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{
  date: string;
  uifId: string;
}> &
  StateProps &
  WithStyles<typeof styles>;

type StateProps = {
  userState: UserState;
};

/**
 * サービス提供記録（月ごと）
 */
const ServiceDeliveryMonthlyCore = (props: Props): JSX.Element => {
  const { date } = props.match.params;
  let currentDate = new Date();
  if (date) {
    const year = +date.slice(0, 4);
    const month = +date.slice(4, 6);
    currentDate = new Date(year, month - 1, 1);
  }
  const userId = +props.match.params.uifId;
  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.DAILY) {
      props.history.push(
        `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${format(new Date(), "YYYYMMDD")}`
      );
    }
  };
  const pageNameValue =
    props.userState.facility_type === FacilityType.KODOENGO
      ? "支援記録"
      : "サービス提供記録";
  return (
    <AdminTemplate pageName={pageNameValue} zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LiST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.USERS}
      />
      <ServiceDeliveryMonthlyRecord
        selectedDate={currentDate}
        userId={userId}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
        history={props.history}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});
export const ServiceDeliveryMonthly = connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ServiceDeliveryMonthlyCore));
