import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    unEditable: {
      opacity: 0.5,
      pointerEvents: "none"
    }
  });

type OwnProps = {
  children: React.ReactNode;
  unEditable: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UnEditableWrapper = ({
  classes,
  unEditable,
  children
}: Props): JSX.Element => (
  <div
    className={ClassNames({
      [classes.unEditable]: unEditable
    })}
  >
    {children}
  </div>
);

export default withStyles(styles)(UnEditableWrapper);
