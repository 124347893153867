import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export interface GetSupportReportUserResponse {
  data: {
    support: {
      usage_results: {
        id: number;
        uif_id: number;
        target_date: string;
        status_type: number;
        support_method: number;
        support_method_other: string | null;
        display_form_flg: number;
      }[];
      report: {
        id: number | null;
        facility_id: number;
        uif_id: number;
        creation_date: string | null;
        working_start_date: string | null;
        company_name: string | null;
        responsible_person: string | null;
        goal_1: string | null;
        goal_2: string | null;
        goal_3: string | null;
        future_support_content: string | null;
        person_behavior: string | null;
        facility_behavior: string | null;
        related_organizations_behavior: string | null;
        shared_content: string | null;
        presentation_date: string | null;
        next_support_plans: string | null;
        author: {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        } | null;
        situations: {
          id: number;
          support_reports_id: number;
          number: number;
          content: string | null;
          result: string | null;
        }[];
      };
    };
    created_at: string | null;
    updated_at: string | null;
  };
}

/**
 * 利用者の支援レポートのデータを取得する
 * @param uifId 事業所所属ユーザーのID
 * @param year 年
 * @param month 月(MM)
 */
const getSupportReportUser = async (
  uifId: string,
  year: string,
  month: string
): Promise<AxiosResponse<GetSupportReportUserResponse>> => {
  const doubleDigitMonth = month.padStart(2, "0");
  const url = `${VERSION_URL_201910}/support_report/users/${uifId}/${year}/${doubleDigitMonth}`;
  return request.get<GetSupportReportUserResponse>(url);
};

export default getSupportReportUser;
