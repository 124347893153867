import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { SupportReportPrint } from "@components/organisms/mgr/SHUROTEICHAKU/record/SupportReportPrint";

/**
 * 支援記録のプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    year: string;
    month: string;
  }>;

const SupportReportRecordPrintCore = (props: Props): JSX.Element => {
  const { uifId, year, month } = props.match.params;
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" }
  ];

  return (
    <AdminTemplate pageName="支援レポート" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <SupportReportPrint uifId={uifId} year={year} month={month} />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const SupportReportRecordPrint = connect(mapStateToProps)(
  SupportReportRecordPrintCore
);
