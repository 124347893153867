import React from "react";
import * as H from "history";
import { InquiryState } from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";

// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import * as inquiryActions from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/actions";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import { toEffectiveObject } from "@utils/object";
import isEqual from "lodash-es/isEqual";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { RecordHeader } from "../RecordHeader";
import {
  InquiryValues as InitialInquiryValues,
  initialValues
} from "@initialize/mgr/KEIKAKUSODAN/record/inquiry/initialValues";

import { validation } from "@initialize/mgr/KEIKAKUSODAN/record/inquiry/validation";
import { SnackbarParams } from "@stores/ui/type";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { InquiryValues } from "@interfaces/mgr/KEIKAKUSODAN/record/inquiry";
import { Button, Theme } from "@material-ui/core";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";

import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import DeleteButton from "@components/atoms/buttons/DeleteButton";

import {
  TYPE_CONSULTATION_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    content: {
      background: "#fff",
      borderRadius: "4px"
    },
    header: {
      backgroundColor: "#fff",
      padding: "60px 0 16px"
    },
    title: {
      lineHeight: 1,
      "& > span": {
        marginLeft: 16
      }
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    form: {
      background: "#fff",
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    postal: {
      "&>div>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>div::before": {
        borderBottomStyle: "dotted"
      },
      "&>div>div>div p": {
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&>div>div>div>input": {
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.87)",
          opacity: 1
        }
      }
    },
    address: {
      display: "flex",
      "&>div": {
        width: "245px"
      },
      "&>div:nth-child(n+2)": {
        marginLeft: "16px"
      }
    },
    age: {
      width: "158px",
      "&>div": {
        width: "100%"
      }
    },
    authorName: {
      width: 240,
      whiteSpace: "nowrap"
    },
    residenceOther: {
      marginBottom: spacing.unit * 4,
      marginLeft: "16px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT8: {
      marginTop: spacing.unit
    },
    ML16: {
      marginLeft: "16px"
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  userName: string;
  uifId: string;
  inquiryId: string;
  history: H.History;
  queryParam?: string;
};

type StateProps = {
  needsStopHistory: boolean;
  inquiry: InquiryState["inquiry"];
  user: UsersInFacilityState["user"];
  staff: StaffState;
};

type DispatchProps = {
  unsetInquiry: () => void;
  fetchStaffs: () => Promise<void>;
  fetchInquiry: (uifId: string, inquiryId: string) => Promise<void>;
  postInquiry: (
    uifId: string,
    inquiry: InitialInquiryValues,
    history: H.History
  ) => Promise<void>;
  deleteInquiry: (uifId: string, inquiryId: string) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => Promise<void>;
};

type MergeProps = {
  authorName: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
} & StateProps &
  DispatchProps;

type Props = OwnProps & MergeProps & WithStyles<typeof styles>;

/**
 * サービス担当者に対する照会（依頼）内容
 */
const InquiryFormCore = (props: Props): JSX.Element | null => {
  const {
    needsStopHistory,
    userName,
    uifId,
    inquiryId,
    history,
    inquiry,
    authorName,
    staffOptions,
    staffOptionsAddSnapShot,
    unsetInquiry,
    fetchStaffs,
    fetchInquiry,
    postInquiry,
    deleteInquiry,
    classes,
    queryParam
  } = props;

  const typeConsultation: number | undefined =
    queryParam === "shogaiji"
      ? TYPE_CONSULTATION_SHOGAIJISODAN
      : TYPE_CONSULTATION_KEIKAKUSODAN;

  const [isLoading, setLoading] = React.useState(true); // 初回fetch終わったらfalse
  const [isNew] = React.useState(inquiryId === "new"); // 新規作成
  const [isView] = React.useState(!isNew); // 表示
  const [isEditing, setEditing] = React.useState(isNew || !isView); // 編集ができる状態かどうか
  const [formValues, setFormValues] = React.useState(
    initialValues(inquiry, staffOptions, { isNew, typeConsultation })
  );

  const [isOpenDeleteInquiryModal, setOpenDeleteInquiryModal] = React.useState(
    false
  );

  //  初回fetch
  React.useEffect(() => {
    setFormValues(
      initialValues(inquiry, staffOptions, { isNew, typeConsultation })
    );
    const fetchFirstData = async (): Promise<void> => {
      unsetInquiry();
      if (isView) {
        await fetchInquiry(uifId, inquiryId);
      }
      await fetchStaffs();
    };
    fetchFirstData().then(() => {
      setLoading(false);
    });
  }, []);

  // fetch終了後
  React.useEffect(() => {
    setFormValues(
      initialValues(inquiry, staffOptions, { isNew, typeConsultation })
    );
  }, [inquiry]);

  const onSubmit = async (
    values: InitialInquiryValues,
    actions: FormikActions<InitialInquiryValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (isNew) {
      await postInquiry(uifId, values, history);
      actions.setSubmitting(false);
      props.stopHistory(false);

      history.push(`/record/${uifId}/plan_monitoring_meeting`);
    } else {
      await props.postInquiry(uifId, values, history);
      actions.setSubmitting(false);
      props.stopHistory(false);
      await fetchInquiry(uifId, String(values.support_inquiries_id));
      setEditing(false);
    }
  };

  const onClickEdit = (): void => {
    setEditing(true);
  };

  const onClickDelete = (): void => {
    setOpenDeleteInquiryModal(true);
  };

  const onSubmitDelete = async (): Promise<void> => {
    await deleteInquiry(uifId, inquiryId);
    props.stopHistory(false);
    history.push(`/record/${uifId}/plan_monitoring_meeting`);
  };

  const onClickCancelDelete = (): void => {
    setOpenDeleteInquiryModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // formik handler
  const confirmDiscardFormChanges = (nextValues: InquiryValues): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  // バリデーション
  const validate = (values: InquiryValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const onClickCancel = async (): Promise<void> => {
              await props.stopHistory(false).then(() => {
                if (isNew) {
                  history.goBack();
                }
                if (isView && isEditing) {
                  formikProps.resetForm();
                  setEditing(false);
                }
              });
            };

            return (
              <Form>
                <RecordHeader
                  pageName="サービス担当者に対する照会（依頼）内容"
                  userName={userName}
                  uifId={uifId}
                  recordType="plan_monitoring_meeting"
                  isEditing={isEditing}
                  history={props.history}
                  button={
                    <div className={classes.titleBtn}>
                      {isEditing ? (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="outline"
                              onClick={onClickCancel}
                            >
                              キャンセル
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <FormikSubmitButton
                              key="submit-button"
                              buttonName="保存する"
                              formikProps={formikProps}
                              errorAction={submitError}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="default"
                              href={`#/record/support_plan/${uifId}/inquiry/${inquiryId}/print`}
                            >
                              印刷
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickEdit}>
                              編集
                            </KnowbeButton>
                          </div>
                        </>
                      )}
                    </div>
                  }
                />
                <div className={classes.form}>
                  {!isNew && (
                    <div className={classes.conCreateDate}>
                      <CreateAndUpdateDate
                        createdAt={formValues.created_at}
                        updatedAt={formValues.updated_at}
                      />
                    </div>
                  )}
                  <RecordSelectDate
                    name="creation_date"
                    label="作成日"
                    required
                    value={formValues.creation_date}
                    isEditable={isEditing}
                    addYearTo={1}
                    overrideYearFrom={1989}
                  />

                  <div className={classes.MT32}>
                    <div className={classes.authorName}>
                      <RecordSelect
                        name="creation_staff"
                        label="サービス計画作成者（照会者）氏名"
                        defaultValue=""
                        placeholder="選択してください"
                        options={staffOptionsAddSnapShot}
                        emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                        value={authorName}
                        isEditable={isEditing}
                        isSelectablePlaceholder
                      />
                    </div>
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="why_not"
                      label="サービス担当者会議を開催しない理由ないし会議に出席できない理由"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.why_not}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="inquiry_purpose"
                      label="照会目的"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.inquiry_purpose}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="inquiry_destination"
                      label="照会（依頼）先"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.inquiry_destination}
                      isEditable={isEditing}
                    />
                  </div>
                  <div className={classes.MT32}>
                    <RecordSelectDate
                      name="inquiry_date"
                      label="照会（依頼）年月日"
                      value={formValues.inquiry_date}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="inquiry_content"
                      label="照会（依頼）内容"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.inquiry_content}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="respondent_name"
                      label="回答者氏名"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.respondent_name}
                      isEditable={isEditing}
                    />
                  </div>
                  <div className={classes.MT32}>
                    <RecordSelectDate
                      name="response_date"
                      label="回答年月日"
                      value={formValues.response_date}
                      isEditable={isEditing}
                      addYearTo={1}
                      overrideYearFrom={1989}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="response_content"
                      label="回答内容"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.response_content}
                      isEditable={isEditing}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.MT32}>
        {isView && isEditing && (
          <DeleteButton
            text="サービス担当者に対する照会（依頼）内容を削除する"
            onClick={onClickDelete}
          />
        )}
      </div>
      <MessageDialog
        isOpen={isOpenDeleteInquiryModal}
        title="サービス担当者に対する照会（依頼）内容を削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDelete}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteSubmitButton}
            onClick={onSubmitDelete}
          >
            削除する
          </Button>
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  inquiry: state.KEIKAKUSODAN.inquiry.inquiry,
  user: state.KEIKAKUSODAN.userInFacility.user,
  staff: state.staff
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { staffDispatcher, KEIKAKUSODAN, uiDispatch } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const inquiryDispatcher = KEIKAKUSODAN.inquiryDispatcher(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    unsetInquiry: (): void => {
      dispatch(inquiryActions.unsetInquiry());
    },
    fetchStaffs: staffDispatches.fetch,
    fetchInquiry: (uifId: string, inquiryId: string): Promise<void> => {
      return inquiryDispatcher.fetchInquiry(uifId, inquiryId);
    },
    postInquiry: (
      uifId: string,
      inquiry: InitialInquiryValues,
      history: H.History
    ): Promise<void> => {
      return inquiryDispatcher.postInquiry(uifId, inquiry, history);
    },
    deleteInquiry: (uifId: string, inquiryId: string): Promise<void> => {
      return inquiryDispatcher.deleteInquiry(uifId, inquiryId);
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),

    stopHistory: uiDispatches.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, inquiry } = stateProps;

  const author = inquiry.creation_staff !== null ? inquiry.creation_staff : 0;

  // 作成者名
  const authorName =
    Object.keys(inquiry).length === 0 ? "" : getSnapOrRealName(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // 作成者
  if (inquiry.creation_staff !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      inquiry.creation_staff
    );
  }

  return {
    authorName,
    staffOptionsAddSnapShot,
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const InquiryForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(InquiryFormCore)
);
