import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * 利用実績一覧(日ごと)APIresponse
 */
export type GetInOutConsultationResultsDailyResponse = {
  data: {
    inout_result_daily: {
      target_date: string;
      number_of_employees: number;
      number_of_handicapped_child: number;
      training_by_chief_flg: number;
      number_of_keikakusodan: number;
      training_by_chief_shogaiji_flg: number;
    };
    inout_consultation_results: InoutConsultationResultsItem[];
    summary: {
      serviceUseCount: number;
      continuousServiceUseCount: number;
      nextMonthUse2LimitCount: number;
      monitoringCount: number;
    };
  };
};

export type InoutConsultationResultsItem = {
  id: number;
  users_in_facility_id: number;
  name_sei: string;
  name_mei: string;
  inout_result_daily_id?: number;
  type_consultation: number;
  status: number;
  first_addition_flg: number;
  first_addition_span: number;
  discharge_addition_flg: number;
  same_inhome_nursing_care_support_flg: number;
  same_preventive_care_support_flg: number;
  inhome_nursing_care_support_office_relation1_flg: number;
  inhome_nursing_care_support_office_relation2_flg: number;
  inhome_nursing_care_support_office_relation3_flg: number;
  inhome_nursing_care_support_office_relation4_flg: number;
  inhome_nursing_care_support_office_relation5_flg: number;
  inhome_nursing_care_support_office_relation6_flg: number;
  intensive_support1_addition_flg: number;
  intensive_support2_addition_flg: number;
  intensive_support3_addition_flg: number;
  hospitalization_relation_addition: number;
  medical_daycare_education_relation_flg: number;
  service_personnel_meeting_held_flg: number;
  service_provision_monitoring_flg: number;
  life_support_promotion_flg: number;
  system_promotion_flg: number;
  childcare_transition_support1_flg: number;
  childcare_transition_support2_flg: number;
  childcare_transition_support3_flg: number;
  memo: string | null;
};

/**
 * 利用実績一覧(日ごと)を取得する
 */
export const getInOutConsultationResultsDaily = async (
  date: string
): Promise<AxiosResponse<GetInOutConsultationResultsDailyResponse>> => {
  const url = `${VERSION_URL_202104}/inout_consultation_results/daily/${date}`;
  return request.get<GetInOutConsultationResultsDailyResponse>(url);
};
