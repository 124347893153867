import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UnitsState } from "@stores/domain/units/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { unsetOperations } from "@stores/domain/mgr/SHISETSUNYUSHO/operations/actions";
import { OperationsState } from "@stores/domain/mgr/SHISETSUNYUSHO/operations/types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordOperationsValues } from "@initialize/mgr/SHISETSUNYUSHO/record/operations/initialValues";
import * as recordMonthlyActions from "@stores/pages/record/monthly/actions";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AdminTemplate from "@components/templates/AdminTemplate";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiSelect from "@components/molecules/MuiSelect";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { OperationRecordList } from "@components/organisms/mgr/SHISETSUNYUSHO/record/OperationRecordList";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { RECORD_OPERATIONS } from "@/constants/url";
import isAfter from "date-fns/is_after";
import { FieldItem } from "@interfaces/ui/form";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  OPERATION_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";
import * as targetUnitActions from "@stores/domain/mgr/SHISETSUNYUSHO/targetUnit/actions";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "12px 16px 20px",
      background: "#eee"
    },
    headerSelect: {
      display: "flex",
      alignItems: "flex-end"
    },
    timestampWrapper: {
      margin: "0 16px 16px"
    },
    noSetting: {
      padding: "80px 0",
      margin: "0 16px"
    },
    noSettingText: {
      textAlign: "center",
      fontSize: 16,
      lineHeight: 1.75
    }
  });

type StateProps = {
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  customRecords: CustomRecordsState;
  operations: OperationsState;
  users: UsersInFacilityState["users"];
  staff: StaffState;
  needsStopHistory: boolean;
  isEditing: boolean;
  editDate: string;
  targetUnit: number;
};

type DispatchProps = {
  fetchCustomRecords: (target: number) => Promise<void>;
  fetchSupportCustomRecords: () => Promise<void>;
  fetchOperations: (
    year: string,
    month: string,
    unitId: number
  ) => Promise<void>;
  unsetOperations: () => void;
  fetchInitialOperations: (
    year: string,
    month: string,
    unitId: number
  ) => Promise<void>;
  postCustomOperation: (
    params: RecordOperationsValues,
    initialValues: RecordOperationsValues,
    targetDate: string,
    unitId: number
  ) => Promise<void>;
  fetchHoliday: (date: string) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
  setOperationsTargetUnit: (targetUnit: number) => void;
};

type OwnProps = RouteComponentProps<{ year?: string; month?: string }> &
  WithStyles<typeof styles>;

type MergeProps = StateProps &
  DispatchProps &
  OwnProps & {
    staffOptions: FieldItem[];
    unitsOptions: FieldItem[];
    mealData: CustomRecordsState[number] | undefined;
  };

/**
 * 業務日誌
 */
const OperationsCore = ({
  classes,
  ...props
}: MergeProps): JSX.Element | null => {
  const { optionalCustomInfo, targetUnit } = props;

  // 表示管理 ※ユニット未設定なら業務日誌表示・ユニット表示なしとして扱う
  const {
    use_common_operation_record,
    use_units_operation_record
  } = !Array.isArray(optionalCustomInfo)
    ? optionalCustomInfo.operation_record_flg
    : {
        use_common_operation_record: 1,
        use_units_operation_record: 0
      };

  // customRecordの取得タイプをunitIdで判断
  const settingType =
    targetUnit === 0
      ? CUSTOM_RECORD_TARGET_TYPE.operation_common
      : CUSTOM_RECORD_TARGET_TYPE.operation_unit;

  // カレンダー設定
  const { year, month } = props.match.params;
  const currentDate = new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = currentDate;
  const selectedDate =
    year && month ? new Date(+year, +month - 1, 1) : currentDate;
  const selectedYear = year || selectedDate.getFullYear().toString();
  const selectedMonth = month || (selectedDate.getMonth() + 1).toString();

  const [calendar, setCalendar] = React.useState({
    year: selectedYear,
    month: selectedMonth,
    date: selectedDate
  });

  const onClickCalendar = async (date: Date): Promise<void> => {
    const clickYear = date.getFullYear().toString();
    const clickMonth = (date.getMonth() + 1).toString();
    setCalendar({
      date,
      year: clickYear,
      month: clickMonth
    });
    await props.fetchHoliday(`${clickYear}${clickMonth.padStart(2, "0")}`);
    await props.fetchOperations(clickYear, clickMonth, targetUnit);
  };

  const onChangeUnit = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    const unitId = +e.target.value;
    const type =
      unitId === 0
        ? CUSTOM_RECORD_TARGET_TYPE.operation_common
        : CUSTOM_RECORD_TARGET_TYPE.operation_unit;
    props.setOperationsTargetUnit(unitId);
    await props.fetchCustomRecords(type);
    await props.fetchSupportCustomRecords();
    await props.fetchOperations(calendar.year, calendar.month, unitId);
  };

  const onPrintPreview = (): void => {
    // 印刷ページのstateリセット
    props.unsetOperations();
    props.history.push(
      `/record/print/operations/${selectedYear}/${selectedMonth}/?facility_unit_id=${targetUnit}`
    );
  };

  // 初回取得完了の判定
  const isFirstRef = React.useRef(false);
  // 初回取得
  React.useEffect(() => {
    props
      .fetchInitialOperations(calendar.year, calendar.month, targetUnit)
      .finally(() => {
        isFirstRef.current = true;
      });
  }, []);

  // カレンダーのURL反映
  React.useEffect(() => {
    props.history.push(
      `${RECORD_OPERATIONS}/${calendar.year}/${calendar.month}`
    );
  }, [calendar]);

  // unitsOptionsが更新されたら一番上のユニットをセット
  const firstUnitsOptionValue =
    props.unitsOptions.length > 0 ? +props.unitsOptions[0].value : 0;

  // 保持しているユニット情報があればセット
  React.useEffect(() => {
    if (!props.targetUnit) {
      props.setOperationsTargetUnit(firstUnitsOptionValue);
    }
  }, [firstUnitsOptionValue]);

  // 直指定の未来日を弾く
  if (isAfter(selectedDate, calendarMaxDate)) {
    return null;
  }

  return (
    <AdminTemplate pageName="業務日誌">
      <div className={classes.stickyWrapper}>
        <div className={classes.headerSelect}>
          <UnEditableWrapper unEditable={props.isEditing}>
            <DateSelectButtonsMonthly
              selectedMonth={calendar.date}
              min={calendarMinDate}
              max={calendarMaxDate}
              onClickSubmit={onClickCalendar}
            />
          </UnEditableWrapper>
          {use_units_operation_record === 1 && (
            <MuiSelect
              name="unit_select"
              label="表示選択"
              value={targetUnit}
              options={props.unitsOptions}
              onChange={onChangeUnit}
              disabled={props.isEditing}
              style={{ width: 520, margin: "0 0 0 24px" }}
            />
          )}
        </div>
        <KnowbeButton
          disabled={
            !props.operations.created_at ||
            props.isEditing ||
            (use_common_operation_record === 0 &&
              use_units_operation_record === 0)
          }
          onClick={onPrintPreview}
        >
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.timestampWrapper}>
        <CreateAndUpdateDate
          createdAt={props.operations.created_at}
          updatedAt={props.operations.updated_at}
        />
      </div>
      {isFirstRef.current &&
        (use_common_operation_record === 1 ||
        use_units_operation_record === 1 ? (
          <OperationRecordList
            year={calendar.year}
            month={calendar.month}
            currentDate={currentDate}
            unitId={targetUnit}
            settingType={settingType}
            {...props}
          />
        ) : (
          <Paper elevation={0} className={classes.noSetting}>
            <Typography color="primary" className={classes.noSettingText}>
              記録設定がオンになっている業務日誌がありません。
              <br />
              設定メニュー内の「記録項目の設定」でオン/オフの切り替えが可能です。
            </Typography>
          </Paper>
        ))}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  optionalCustomInfo: state.units.optionalCustomInfo,
  customRecords: state.customRecords,
  operations: state.SHISETSUNYUSHO.operations,
  targetUnit: state.SHISETSUNYUSHO.targetUnit,
  users: state.SHISETSUNYUSHO.userInFacility.users,
  staff: state.staff,
  needsStopHistory: state.ui.needsStopHistory,
  isEditing: state.pages.recordMonthly.isEditing,
  editDate: state.pages.recordMonthly.targetDate
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    SHISETSUNYUSHO,
    customRecords,
    holiday,
    uiDispatch,
    pages
  } = dispatches;
  const operationsDispatches = SHISETSUNYUSHO.operationsDispatcher(dispatch);
  const customRecordsDispatches = customRecords(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  const recordMonthlyDispatches = pages.recordMonthlyDispatcher(dispatch);
  const holidayDispatcher = holiday(dispatch);

  return {
    fetchInitialOperations: (
      year: string,
      month: string,
      unitId: number
    ): Promise<void> =>
      recordMonthlyDispatches.fetchSHISETSUNYUSHOInitialOperations(
        year,
        month,
        unitId
      ),
    fetchCustomRecords: (target: number): Promise<void> =>
      customRecordsDispatches.fetchCustomRecords(target),
    fetchSupportCustomRecords: (): Promise<void> =>
      customRecordsDispatches.fetchSupportCustomRecords(),
    fetchOperations: (
      year: string,
      month: string,
      unitId: number
    ): Promise<void> =>
      operationsDispatches.fetchOperations(year, month, unitId),
    unsetOperations: (): void => {
      dispatch(unsetOperations());
    },
    postCustomOperation: (
      params: RecordOperationsValues,
      initialValues: RecordOperationsValues,
      targetDate: string,
      unitId: number
    ): Promise<void> =>
      recordMonthlyDispatches.postSHISETSUNYUSHOCustomOperation(
        params,
        initialValues,
        targetDate,
        unitId
      ),
    fetchHoliday: (date: string): Promise<void> =>
      holidayDispatcher.fetchHoliday(date),
    stopHistory: (flag: boolean): void => {
      uiDispatches.stopHistory(flag);
    },
    setEditDate: (targetDate: string): void => {
      dispatch(recordMonthlyActions.setEdit(targetDate));
    },
    unsetEditDate: (): void => {
      dispatch(recordMonthlyActions.unsetEdit());
    },
    setOperationsTargetUnit: (targetUnit: number): void => {
      dispatch(targetUnitActions.setOperationsTargetUnit(targetUnit));
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  const unitsOptions = [];
  if (!Array.isArray(stateProps.optionalCustomInfo)) {
    const { operation_record_flg } = stateProps.optionalCustomInfo;
    if (operation_record_flg.use_common_operation_record === 1) {
      unitsOptions.push({ label: "事業所全体", value: 0 });
    }
    if (operation_record_flg.use_units_operation_record === 1) {
      const units = stateProps.optionalCustomInfo.facility_units.filter(
        (v) => v.id
      );
      unitsOptions.push(...generateSelectFieldItems(units, "unit_name", "id"));
    }
  }

  const operationCustomRecords = stateProps.customRecords.filter(
    (record) => record.setting_type !== CUSTOM_RECORD_TARGET_TYPE.support
  );

  // 出勤者を1番, 欠勤・休暇者を2番, 記録者を最後にして他はorder順に
  const orderCustomRecords: CustomRecordsState = [];
  if (stateProps.customRecords.length) {
    const firstPosition = operationCustomRecords.filter(
      (v) =>
        v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant ||
        v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee
    );
    const lastPosition = operationCustomRecords.filter(
      (v) => v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );
    const orderArray = operationCustomRecords
      .filter(
        (v) =>
          v.default_item !== OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant &&
          v.default_item !== OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee &&
          v.default_item !== OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
      )
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });
    orderCustomRecords.push(...firstPosition, ...orderArray, ...lastPosition);
  }

  const mealData =
    stateProps.customRecords.length > 0
      ? stateProps.customRecords.find(
          (record) =>
            record.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
            record.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.meal
        )
      : undefined;

  return {
    staffOptions,
    unitsOptions,
    mealData,
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    customRecords: orderCustomRecords
  };
};

export const Operations = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(OperationsCore)
);
