/**
 * number なら string に変換して null, undefined なら空文字列にして返す
 */
const castString = (value: string | number | null | undefined): string => {
  if (value === undefined || value === null) {
    return "";
  }
  return `${value}`;
};

export default castString;
