import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportPlanResponse = {
  data: {
    id: number;
    support_begin_date: string | null;
    support_end_date: string | null;
    status_type: number;
    evaluation_status: number;
    user_request_type: number;
    details: string | null;
    user_request_text: string | null;
    income_status: string | null;
    user_issue: string | null;
    physical_condition: string | null;
    risk_factor: string | null;
    current_status: string | null;
    other_comment: string | null;
    staff_comment: string | null;
    minutes: string | null;
    minutes_date: string | null;
    participant:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
        }[]
      | [];
    remarks: string | null;
    author:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        }
      | number;
    authorizer:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        }
      | number;
    creation_date: string | null;
    support_plan_goal: {
      id: number;
      number: number;
      sprint_type: number;
      sprint_goal: string;
      adopt_info: string;
      support_info: string;
      user_comment: string | null;
      improvement_plan: string | null;
      sprint_start_date: string | null;
      sprint_end_date: string | null;
      sprint_result: number | null;
      sprint_review: string | null;
      sprint_user_evaluation: number | null;
      sprint_user_review: string | null;
      sprint_staff_evaluation: number | null;
      sprint_staff_review: string | null;
      achievement: number | null;
      satisfaction: string | null;
      countermeasure: string | null;
    }[];
    support_plan_program: {
      id?: number;
      number: number;
      scheduled_time: string;
      service_content: string;
      delete: boolean;
    }[];
    pickup: number;
    archive: boolean;
    support_plan_shuroteichaku: {
      id: number;
      support_plans_id: number;
      assessment_date: string | null;
      company_name: string | null;
      company_industry: string | null;
      company_address: string | null;
      company_tel: string | null;
      responsible_person: string | null;
      equipment_environment: string | null;
      human_environment: string | null;
      working_status: number;
      wage: string | null;
      working_time: string | null;
      rest_time: string | null;
      holiday: string | null;
      business_description: string | null;
      working_start_date: string | null;
      takeover: string | null;
      disability_class: number;
      disability_certificate_body: number;
      disability_certificate_spirit: number;
      disability_certificate_rehabilitation: number;
      disability_certificate_rehabilitation_other: string | null;
      classify_incurable_flg: number;
      support_group: string | null;
      physical_condition: string | null;
      support_organization: string | null;
    };
  }[];
  response: {
    code: number;
    msg: string;
  };
};

/**
 * 個別支援計画のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
const getSupportPlan = async (
  uifId: string
): Promise<AxiosResponse<GetSupportPlanResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}`;
  return request.get<GetSupportPlanResponse>(url);
};

export default getSupportPlan;
