import * as React from "react";
import { FastField, FieldProps, FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { getLastDay, getWareki } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";
import MuiTextField from "@components/molecules/MuiTextField";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

type Props = {
  isMobile?: boolean;
  name: string;
  label: string;
  addYearTo?: number;
  overrideYearFrom?: number;
  overrideYearTo?: number;
  style?: React.CSSProperties;
};

type CoreProps = Props & {
  value: SelectDateValue;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>; // 使用されるformikPropsがページごとに違うためany
};

type OptionState = { label: string; value: string | number }[];

const FormikSelectDayOnlyCore = (props: CoreProps): JSX.Element => {
  const { label, name, style, isMobile } = props;

  const yearValue = `${props.value.year}年 (${getWareki(props.value.year)})`;
  const monthValue = `${props.value.month}月`;
  const [dayOptions, setDayOptions] = React.useState<OptionState>([]);

  /**
   * 日リスト作成
   */
  const createDayOptions = (year: number, month: number): OptionState => {
    const lastDay = year && month ? getLastDay(year, month) : 0;
    return createOneToNumberOptions(lastDay, "日", false);
  };

  React.useEffect(() => {
    const newOptions = createDayOptions(+props.value.year, +props.value.month);
    setDayOptions(newOptions);
  }, []);

  if (!isMobile) {
    return (
      <FormGroup row style={style || { marginBottom: 32 }}>
        <MuiTextField
          value={yearValue}
          label={label}
          disabled
          disabledStyle
          style={{ marginBottom: 0 }}
        />
        <MuiTextField
          value={monthValue}
          disabled
          disabledStyle
          style={{ marginTop: 16, marginBottom: 0, width: 72 }}
        />
        <FormikSelect
          name={`${name}.day`}
          label="日"
          shrink={false}
          size="superSmall"
          options={dayOptions}
          style={{ marginBottom: 0, width: 75 }}
        />
      </FormGroup>
    );
  }
  return (
    <div style={{ marginBottom: 24, width: "100%", display: "flex" }}>
      <ReadonlyTextField
        label={props.label}
        value={`${yearValue} ${monthValue}`}
        defaultValue=""
        style={{ marginBottom: 0, marginRight: 16, width: "calc(66% - 8px)" }}
      />
      <FormikSelect
        isMobile
        name={`${name}.day`}
        label=""
        options={dayOptions}
        style={{ marginBottom: 0, width: "calc(33% - 8px)" }}
      />
    </div>
  );
};
export const FormikSelectDayOnly = (props: Props): JSX.Element => (
  // tslint:disable:jsx-no-lambda
  <FastField
    name={props.name}
    render={({ field, form }: FieldProps<SelectDateValue>): JSX.Element => (
      <FormikSelectDayOnlyCore value={field.value} form={form} {...props} />
    )}
  />
);
