import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { ReportState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { UserResultFeatureGroup } from "@stores/domain/user/type";

const styles = createStyles({
  contents: {
    display: "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "flex-end" as "flex-end",
    marginBottom: 16
  },
  actionContents: {
    display: "flex",
    justifyContent: "end" as "end",
    width: "100%"
  },
  rectangleListContents: {
    display: "flex",
    justifyContent: "flex-start" as "flex-start",
    "& > :last-child": {
      marginRight: 0
    }
  },
  linkButton: {
    height: 36,
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    color: "#37474f",
    marginLeft: 8
  },
  button: {
    color: "#0277bd",
    width: "100%",
    paddingBottom: 3.5
  },
  alertIconUpper: {
    width: 15,
    height: 15,
    color: "#ff5656",
    position: "absolute",
    top: -7,
    left: 100
  },
  // RectangleBox用のを上書き
  wrapperBase: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 200,
    height: 112,
    backgroundColor: "#eceff1",
    borderRadius: 4,
    lineHeight: 1,
    padding: "10px 12px",
    marginRight: 10
  },
  title: {
    fontSize: 13,
    color: "#37474F",
    lineHeight: "20px",
    letterSpacing: "0.23px"
  },
  content: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    color: "#666"
  },
  num: {
    fontSize: 22,
    flex: 1,
    textAlign: "right"
  },
  unit: {
    fontSize: 14,
    marginRight: 10
  }
});

type OwnProps = {
  rectangleConfigList: {
    title1: string;
    title2: string;
    num: number | null | undefined;
    denom?: string;
    unit: string;
  }[];
  openModal: (report: ReportState) => void;
  selectedDate: Date;
  facility: FacilityState;
  report: ReportState;
  featureGroup: UserResultFeatureGroup;
};
type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportDailyPaperHeaderCore = (props: Props): JSX.Element => {
  const setupError = (data: ReportState, facility: FacilityState): boolean => {
    if (
      data.reportDaily.inoutResultDaily.numberOfEmployees !== null &&
      (!facility.integratedManagementHandicappedChildFlg ||
        (facility.integratedManagementHandicappedChildFlg &&
          data.reportDaily.inoutResultDaily.numberOfHandicappedChild !== null))
    ) {
      return false;
    }
    return true;
  };

  const openSetupModal = (): void => {
    props.openModal(props.report);
  };

  return (
    <div className={props.classes.contents}>
      {props.rectangleConfigList && props.rectangleConfigList.length > 0 && (
        <div className={props.classes.rectangleListContents}>
          {props.rectangleConfigList.map((rectangleConfig, index) => {
            const uniqueKey = `${rectangleConfig.title1}-${index}`;
            return (
              <div className={props.classes.wrapperBase} key={uniqueKey}>
                <div className={props.classes.title}>
                  {rectangleConfig.title1}
                  <br />
                  {rectangleConfig.title2}
                </div>
                <div className={props.classes.content}>
                  <div className={props.classes.num}>
                    {rectangleConfig.num === null ? "-" : rectangleConfig.num}
                  </div>
                  <span className={props.classes.unit}>
                    {rectangleConfig.denom && `/${rectangleConfig.denom}`}
                    {rectangleConfig.unit}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {/* 契約プランが Record Only の場合に非表示 */}
      {!(
        props.featureGroup.group_invoice === 0 &&
        props.featureGroup.group_operation_support === 1 &&
        props.featureGroup.group_labor_charge === 0
      ) && (
        <div className={props.classes.actionContents}>
          <div className={props.classes.linkButton}>
            <Button
              className={props.classes.button}
              color="secondary"
              onClick={openSetupModal}
            >
              {`${props.selectedDate.getMonth() + 1}月の設定項目`}
              {setupError(props.report, props.facility) && (
                <ErrorIcon className={props.classes.alertIconUpper} />
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export const InOutReportDailyPaperHeader = withStyles(styles)(
  InOutReportDailyPaperHeaderCore
);
