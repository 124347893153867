import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCarePlanStarted = () =>
  ({ type: types.FETCH_KODOENGO_CARE_PLAN_STARTED } as const);
export const fetchCarePlan = (res: types.CarePlanStaffState) =>
  ({ type: types.FETCH_KODOENGO_CARE_PLAN, payload: res } as const);
export const fetchCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KODOENGO_CARE_PLAN_FAILED, error: err } as const);

export const postKODOENGOCarePlanStarted = () =>
  ({ type: types.POST_KODOENGO_CARE_PLAN_STARTED } as const);
export const postKODOENGOCarePlan = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KODOENGO_CARE_PLAN, payload: req } as const);
export const postKODOENGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KODOENGO_CARE_PLAN_FAILED, error: err } as const);

export const deleteKODOENGOCarePlanStarted = () =>
  ({ type: types.DELETE_KODOENGO_CARE_PLAN_STARTED } as const);
export const deleteKODOENGOCarePlan = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_KODOENGO_CARE_PLAN, payload: req } as const);
export const deleteKODOENGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_KODOENGO_CARE_PLAN_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCarePlanStarted>
  | ReturnType<typeof fetchCarePlan>
  | ReturnType<typeof fetchCarePlanFailed>
  | ReturnType<typeof postKODOENGOCarePlanStarted>
  | ReturnType<typeof postKODOENGOCarePlan>
  | ReturnType<typeof postKODOENGOCarePlanFailed>
  | ReturnType<typeof deleteKODOENGOCarePlanStarted>
  | ReturnType<typeof deleteKODOENGOCarePlan>
  | ReturnType<typeof deleteKODOENGOCarePlanFailed>;
