import * as React from "react";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Button, createStyles, WithStyles } from "@material-ui/core";
// store
import { connect } from "react-redux";
// ui
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/CHIIKIIKO/planMonitoringMeeting/types";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import {
  MANAGER_MEETING_NUM,
  CHIIKIIKO_SUPPORT_REPORT_NUM,
  TARGET_FLG_TYPE,
  CHIIKIIKO_CONTINUATION_PLAN_NUM,
  CHIIKIIKO_DRAFT_PLAN_NUM,
  CHIIKIIKO_PLAN_NUM
} from "@constants/mgr/CHIIKIIKO/variables";
import classNames from "classnames";
import CustomDateLabel from "@components/atoms/CustomDateLabel";

const styles = (): StyleRules =>
  createStyles({
    rowYearly: {
      height: 56
    },
    rowMonthly: {
      height: 36,
      backgroundColor: "#f5f5f5"
    },
    rowDaily: {
      height: 60
    },
    createAtHeader: {
      minWidth: 156,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingLeft: 16
    },
    reportHeader: {
      minWidth: 848,
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    createAtCell: {
      minWidth: 142,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingLeft: 16
    },
    createAtDailyCell: {
      minWidth: 126,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingLeft: 32
    },
    reportCellExpand: {
      minWidth: 848,
      height: 56,
      textAlign: "right",
      verticalAlign: "center",
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    reportCell: {
      minWidth: 832,
      textAlign: "left",
      verticalAlign: "center",
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    reportButtonCell: {
      paddingTop: 8,
      paddingBottom: 8
    },
    expandIconWrapper: {
      paddingRight: 4
    },
    expandIcon: {
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    noRecord: {
      paddingTop: "80px",
      paddingBottom: "48px",
      textAlign: "center",
      lineHeight: "28px"
    },
    dottedBorderBottom: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 2px,transparent 2px, transparent 4px)",
      backgroundSize: "4px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left bottom"
    },
    solidBorderBottom: {
      borderBottom: "1px solid #9e9e9e"
    },
    whiteBorderBottom: {
      borderBottom: "1px solid #ffffff"
    },
    reportContainer: {
      width: 720
    },
    reportButton: {
      fontSize: 14,
      width: 172,
      height: 36,
      color: "#0277bd",
      backgroundColor: "#ffffff",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      margin: "4px 8px 4px 0",
      whiteSpace: "nowrap"
    }
  });

type OwnProps = {
  record: PlanMonitoringMeetingState["planMonitoringMeeting"];
  uifId: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PlanMonitoringMeetingTableCore = (props: Props): JSX.Element => {
  const { record, uifId, classes } = props;
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  React.useEffect(() => {
    // 当年以降の年は開いた状態(∧)、当年より過去の年は閉じた状態(∨)
    const currentYear = new Date().getFullYear();

    const indexList: number[] = [];
    record.yearly
      .sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        }
        if (a.year > b.year) {
          return -1;
        }
        return 0;
      })
      .forEach((y, yidx) => {
        if (y.year >= currentYear) {
          indexList.push(yidx);
        }
      });
    setOpenIndexes(indexList);
  }, [record.yearly]);

  const table = (
    yearly: PlanMonitoringMeetingState["planMonitoringMeeting"]["yearly"]
  ): JSX.Element[] => {
    const getPath = (id: number, targetFlg: number): string => {
      switch (targetFlg) {
        case CHIIKIIKO_DRAFT_PLAN_NUM:
          return `#/record/consultation/${uifId}/${id}/${targetFlg}`;
        case CHIIKIIKO_PLAN_NUM:
          return `#/record/consultation/${uifId}/${id}/${targetFlg}`;
        case CHIIKIIKO_CONTINUATION_PLAN_NUM:
          return `#/record/consultation/${uifId}/${id}/${targetFlg}`;
        case CHIIKIIKO_SUPPORT_REPORT_NUM:
          return `#/record/support_plan/${uifId}/assistance_report/${id}`;
        case MANAGER_MEETING_NUM:
          return `#/record/meeting_record/${uifId}/${id}`;
        default:
          return "#";
      }
    };

    const reportsButton = (
      reports: {
        id: number;
        target_flg: number;
      }[]
    ): JSX.Element => {
      const sortedReports = reports.sort((a, b) => {
        if (a.target_flg > b.target_flg) {
          return 1;
        }
        if (a.target_flg < b.target_flg) {
          return -1;
        }
        return 0;
      });
      return (
        <span>
          <div className={classes.reportContainer}>
            {sortedReports.map((data, idx) => {
              const key = idx;
              return (
                <Button
                  key={key}
                  className={classes.reportButton}
                  href={getPath(data.id, data.target_flg)}
                >
                  {TARGET_FLG_TYPE[data.target_flg]}
                </Button>
              );
            })}
          </div>
        </span>
      );
    };

    const onClickOpen = (yearIndex: number): void => {
      setOpenIndexes((indexes) => [...indexes, yearIndex]);
    };

    const onClickClose = (yearIndex: number): void => {
      setOpenIndexes((indexes) => indexes.filter((v) => v !== yearIndex));
    };

    return yearly
      .sort((a, b) => {
        if (a.year < b.year) {
          return 1;
        }
        if (a.year > b.year) {
          return -1;
        }
        return 0;
      })
      .map((y, yidx) => {
        const ykey = yidx;
        return (
          <React.Fragment key={ykey}>
            <TableRow
              key={`table-row-${ykey}`}
              className={props.classes.rowYearly}
            >
              <>
                <TableCellWrap
                  key={`${ykey}-create-at`}
                  cellClass={classNames(
                    classes.createAtCell,
                    openIndexes.some((v) => v === yidx)
                      ? ""
                      : classes.solidBorderBottom
                  )}
                >
                  {/* 作成日（年） */ `${y.year}年`}
                </TableCellWrap>
                <TableCellWrap
                  key={`${ykey}-report`}
                  cellClass={classNames(
                    classes.reportCellExpand,
                    openIndexes.some((v) => v === yidx)
                      ? ""
                      : classes.solidBorderBottom
                  )}
                  padding="checkbox"
                >
                  {/* 帳票 */}
                  {openIndexes.some((v) => v === yidx) ? (
                    <div className={classes.expandIconWrapper}>
                      <ExpandMoreIcon
                        className={classes.expandIcon}
                        onClick={(): void => {
                          onClickClose(yidx);
                        }}
                      />
                    </div>
                  ) : (
                    <div className={classes.expandIconWrapper}>
                      <ExpandLessIcon
                        className={classes.expandIcon}
                        onClick={(): void => {
                          onClickOpen(yidx);
                        }}
                      />
                    </div>
                  )}
                </TableCellWrap>
              </>
            </TableRow>
            {openIndexes.some((v) => v === yidx) &&
              y.monthly &&
              y.monthly
                .sort((a, b) => {
                  if (a.month < b.month) {
                    return 1;
                  }
                  if (a.month > b.month) {
                    return -1;
                  }
                  return 0;
                })
                .map((m, midx, mArray) => {
                  const mkey = midx;
                  const mLastIdx = mArray.length - 1;
                  return (
                    <React.Fragment key={mkey}>
                      <TableRow
                        key={`table-row-${ykey}-${mkey}`}
                        className={classes.rowMonthly}
                      >
                        <TableCellWrap
                          key={`${ykey}-${mkey}-create-at`}
                          cellClass={classNames(
                            classes.createAtCell,
                            midx === mLastIdx && m.daily === undefined
                              ? classes.solidBorderBottom
                              : classes.whiteBorderBottom
                          )}
                          colSpan={2}
                        >
                          {/* 作成日（月） */ `${m.month}月`}
                        </TableCellWrap>
                      </TableRow>
                      {m.daily &&
                        m.daily
                          .sort((a, b) => {
                            if (a.creation_date < b.creation_date) {
                              return 1;
                            }
                            if (a.creation_date > b.creation_date) {
                              return -1;
                            }
                            return 0;
                          })
                          .map((d, didx, dArray) => {
                            const dkey = didx;
                            const dLastIdx = dArray.length - 1;
                            let borderBottomClass = classes.dottedBorderBottom;
                            if (midx !== mLastIdx && didx === dLastIdx) {
                              borderBottomClass = "";
                            } else if (midx === mLastIdx && didx === dLastIdx) {
                              borderBottomClass = classes.solidBorderBottom;
                            }
                            return (
                              <TableRow
                                key={`table-row-${ykey}-${mkey}-${dkey}`}
                                className={classes.rowDaily}
                              >
                                <>
                                  <TableCellWrap
                                    key={`${ykey}-${mkey}-${dkey}-create-at`}
                                    cellClass={classNames(
                                      classes.createAtDailyCell,
                                      borderBottomClass
                                    )}
                                  >
                                    {/* 作成日（日） */}
                                    <CustomDateLabel
                                      date={d.creation_date}
                                      dateFormat="Do（dd）"
                                      holiday={!!d.is_holiday}
                                    />
                                  </TableCellWrap>
                                  <TableCellWrap
                                    key={`${ykey}-${mkey}-${dkey}-report`}
                                    cellClass={classNames(
                                      classes.reportCell,
                                      classes.reportButtonCell,
                                      borderBottomClass
                                    )}
                                  >
                                    {/* 帳票 */}
                                    {reportsButton(d.reports)}
                                  </TableCellWrap>
                                </>
                              </TableRow>
                            );
                          })}
                    </React.Fragment>
                  );
                })}
          </React.Fragment>
        );
      });
  };

  const headData = [
    {
      label: "作成日",
      className: `${classes.createAtHeader} `
    },
    {
      label: "帳票",
      className: `${classes.reportHeader}`
    }
  ];

  return (
    <>
      <Table key="plan-monitoring-meeting-table-head">
        <KnowbeTableHead
          uniqueKey="plan-monitoring-meeting"
          height={48}
          items={headData}
        />
      </Table>
      {(record.yearly && record.yearly.length) > 0 ? (
        <Table key="plan-monitoring-meeting-table">
          <TableBody key="plan-monitoring-meeting-table-body">
            {table(record.yearly)}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noRecord}>
          作成済みの帳票がありません。新規作成ボタンから作成してください。
        </div>
      )}
    </>
  );
};

export const PlanMonitoringMeetingTable = connect(null)(
  withStyles(styles)(PlanMonitoringMeetingTableCore)
);
