import { AxiosResponse } from "axios";
import request from "@api/index";
import { BaseResponse } from "@api/types";
import { VERSION_URL_201910 } from "@config";

export type GetSupportSimpleRecordsResponse = BaseResponse<{
  support_record_consultations: {
    id: number;
    target_date: string;
    support_content: string | null;
    staffs:
      | {
          staff_id: number;
          staff_name: string;
        }[]
      | null;
  }[];
}>;

/**
 * 利用者の支援記録のデータを取得する
 * @param uifId 事業所所属ユーザーのID
 * @param fromMonth 開始年月(yyyymm)
 * @param toMonth 月(MM)
 */
const getSupportSimpleRecords = async (
  uifId: string,
  fromMonth: string,
  toMonth: string
): Promise<AxiosResponse<GetSupportSimpleRecordsResponse>> => {
  const url = `${VERSION_URL_201910}/supports/users/${uifId}/simple_records?from=${fromMonth}&to=${toMonth}`;
  return request.get<GetSupportSimpleRecordsResponse>(url);
};

export default getSupportSimpleRecords;
