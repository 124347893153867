import * as React from "react";

import { convertWareki } from "@utils/date";

import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/domain/invoice/type";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  KYOTAKUKAIGO_DISPLAY_STATUS_LIST,
  KYOTAKUKAIGO_DISPATCH_NO_LIST
} from "@constants/mgr/KYOTAKUKAIGO/variables";

const PRINT_PAGE_MAX_HEIGHT = 900;
const PRINT_END_PAGE_MAX_HEIGHT = 700;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    container: {
      marginBottom: 12
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.thin": {
        border: "1px solid"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 20,
        wordBreak: "break-all",
        overflow: "hidden",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.miniLabel": {
          width: 40,
          textAlign: "center",
          "& > div": {
            width: 40,
            transform: "scale(0.8) translate(0px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.miniLabelStatus": {
          width: 44,
          textAlign: "center",
          "& > div": {
            width: 44,
            transform: "scale(0.8) translate(0px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.miniLabelPer": {
          maxWidth: 30,
          textAlign: "center",
          "& > div": {
            width: 30,
            transform: "scale(0.8) translate(-2px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.memo": {
          maxWidth: 105,
          textAlign: "center",
          "& > div": {
            width: 169,
            transform: "scale(0.6) translate(-56px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.ssmall": {
          height: 20
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssssize": {
          width: 22
        },
        "&.timesize": {
          width: 30
        },
        "&.ssssize": {
          width: 32
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 80
        },
        "&.msize": {
          width: 120
        },
        "&.lsize": {
          width: 150
        },
        "&.llsize": {
          width: 200
        },
        "&.vertical": {
          height: 50,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderBottomBold": {
          borderBottom: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDoubleTop": {
          borderTopStyle: "double",
          borderTop: "4px"
        },
        "&.borderDashed": {
          borderBottom: "1px dashed"
        }
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    empty: {
      width: "120%",
      transform: "matrix(-1, 0.6, 0.5, 0.8, -1.7, 0.3)",
      borderTop: "solid black 1px"
    },
    empty2: {
      width: "120%",
      transform: "matrix(-0.9, 0.5, 0.5, 1, -3, 0.3)",
      borderTop: "solid black 1px"
    },
    empty3: {
      width: "110%",
      transform: "matrix(-1, 4, 0.7, 1, -1, 0.3)",
      borderTop: "solid black 1px"
    },
    empty4: {
      width: "156%",
      transform: "matrix(-1, 7, 1, -1, -4.5, 0.4)",
      borderTop: "solid black 1px"
    },
    empty5: {
      width: "95%",
      transform: "matrix(-1.1, 0.57, 1, 1, 2, 1)",
      borderTop: "solid black 1px"
    },
    empty6: {
      width: "100%",
      transform: "matrix(-1.1, 1, 1, 1, -1, 1)",
      borderTop: "solid black 1px"
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 居宅介護の実績記録票
 */
const InoutRecordTableCore = (props: Props): JSX.Element => {
  const { key, invoiceData } = props;
  const { date, facility, users } = invoiceData;
  const { classes } = props;
  let pageSum = 1;
  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;
        // デフォルトの高さ定義
        const defaultHeight = 37;
        const emptyHeight = 23;
        const indexArray: number[] = [0];
        const data = user.inout_results_kyotakukaigo
          ? user.inout_results_kyotakukaigo.reduce((sum, record, i) => {
              const len = record.memo ? record.memo.length : 0;
              const numberOfLines = Math.ceil(len / 10);
              const isLastData =
                user.inout_results_kyotakukaigo &&
                user.inout_results_kyotakukaigo.length - 1 === i;
              let result = defaultHeight;
              // サービス内容が「通院(伴う)(基礎等)」「通院(伴う)(重訪)」「通院(伴ず)(基礎等)」「通院(伴ず)(重訪)」の場合は備考を考慮せずに高さを設定する(備考3行と同じ高さ)
              if (
                record.status.toString() === "21" ||
                record.status.toString() === "22" ||
                record.status.toString() === "31" ||
                record.status.toString() === "32"
              ) {
                result += 18.5;
              }
              // サービス内容が「身体」「家事」「乗降」かつ、備考が1行以下の場合は備考を考慮せずに高さを設定する(空行と同じ高さ)
              if (
                (record.status.toString() === "10" ||
                  record.status.toString() === "40" ||
                  record.status.toString() === "50") &&
                numberOfLines <= 1
              ) {
                result = emptyHeight;
              }
              // 備考が2行より多い場合、備考の文字数から高さを導出
              if (numberOfLines > 2) {
                result = defaultHeight + (numberOfLines - 2) * 18.5;
              }

              // 該当行が次のページかどうかを判定
              if (
                sum + result > PRINT_PAGE_MAX_HEIGHT ||
                (isLastData && sum + result > PRINT_END_PAGE_MAX_HEIGHT)
              ) {
                indexArray.push(i);
              } else {
                result = sum + result;
              }
              return result;
            }, 0)
          : 0;

        // 空行の行数を導出
        const marginLength = Math.floor(
          (PRINT_END_PAGE_MAX_HEIGHT - data) / emptyHeight
        );
        // ページ数
        pageSum = indexArray.length;
        const inoutResultsKyotakukaigoSize = user.inout_results_kyotakukaigo
          ? user.inout_results_kyotakukaigo.length
          : 0;

        return (
          <React.Fragment key={keyIndex}>
            {indexArray.map((firstNumberOfPage, pageIndex) => {
              const keyPageIndex = pageIndex;
              return (
                <section
                  key={`InoutRecordTable-${keyIndex}-${keyPageIndex}`}
                  className={classes.page}
                >
                  <div className={`${classes.flexContainer} center relative`}>
                    <div className={classes.date}>
                      <span>{`${convertWareki(date.year, date.month).era}${
                        convertWareki(date.year, date.month).year
                      }年${date.month}月分`}</span>
                    </div>
                    <span>居宅介護サービス提供実績記録票</span>
                  </div>
                  <div className={classes.flexContainer}>
                    <table className={`${classes.table} fullWidth`}>
                      <tbody>
                        <tr>
                          <td className="label ssize">
                            受給者証
                            <br />
                            番号
                          </td>
                          <td className="label ssize">{user.recipientNo}</td>
                          <td className="label msize">
                            支給決定障害者等氏名
                            <br />
                            (障害児氏名)
                          </td>
                          <td className="label llsize">
                            {user.guardianName
                              ? user.guardianName
                              : user.recipientName}
                            <br />
                            {user.guardianName ? `(${user.recipientName})` : ""}
                          </td>
                          <td className="label" colSpan={2}>
                            事業所番号
                          </td>
                          <td className="label ssize">{facility.officeNo}</td>
                        </tr>
                        <tr>
                          <td className="label">契約支給量</td>
                          <td colSpan={3} className="ssize">
                            {user.payment}
                          </td>
                          <td className="label ssize">
                            事業者及び
                            <br />
                            その事業所
                          </td>
                          <td colSpan={2} className="label">
                            {facility.officeName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.flexContainer}>
                    {pageSum === pageIndex + 1 ? (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsKyotakukaigoIndex={firstNumberOfPage}
                        inoutResultsKyotakukaigoSize={
                          inoutResultsKyotakukaigoSize - firstNumberOfPage
                        }
                        marginLength={marginLength}
                        isEndRow
                      />
                    ) : (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsKyotakukaigoIndex={firstNumberOfPage}
                        inoutResultsKyotakukaigoSize={
                          indexArray[pageIndex + 1] - firstNumberOfPage
                        }
                        marginLength={0}
                        isEndRow={false}
                      />
                    )}
                  </div>
                  <div className={`${classes.flexContainer} end`}>
                    <table className={`${classes.table} thin`}>
                      <tbody>
                        <tr>
                          <td className="label">{pageSum}</td>
                          <td className="label">枚中</td>
                          <td className="label">{pageIndex + 1}</td>
                          <td className="label">枚</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
  inoutResultsKyotakukaigoIndex: number;
  inoutResultsKyotakukaigoSize: number;
  marginLength: number;
  isEndRow: boolean;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const {
    user,
    classes,
    inoutResultsKyotakukaigoIndex,
    inoutResultsKyotakukaigoSize,
    isEndRow,
    marginLength
  } = props;
  const inoutResultsKyotakukaigo = user.inout_results_kyotakukaigo
    ? user.inout_results_kyotakukaigo
    : [];
  const emptyList = new Array<undefined>(marginLength).fill(undefined);
  const records = [
    ...inoutResultsKyotakukaigo.slice(
      inoutResultsKyotakukaigoIndex,
      inoutResultsKyotakukaigoIndex + inoutResultsKyotakukaigoSize
    ),
    ...emptyList
  ];

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={3} className="label vertical">
            <span>日付</span>
          </td>
          <td rowSpan={3} className="label vertical">
            <span>曜日</span>
          </td>
          <td rowSpan={3} className="label sssssize vertical borderBold" />
          <td rowSpan={3} className="miniLabelStatus borderBold">
            <div>
              サービス
              <br />
              内容
            </div>
          </td>
          <td colSpan={4} className="label borderBold">
            居宅介護計画
          </td>
          <td colSpan={4} className="label ">
            サービス提供時間
          </td>
          <td colSpan={2} className="label borderBold">
            算定
            <br />
            時間数
          </td>
          <td rowSpan={3} className="label sssssize">
            派<br />遣<br />人<br />数
          </td>
          <td rowSpan={3} className="label sssssize">
            初<br />回<br />加<br />算
          </td>
          <td rowSpan={3} className="label sssssize">
            緊<br />急<br />時
            <br />
            対<br />応
            <br />
            加<br />算
          </td>
          <td rowSpan={3} className="label ssssize borderBold">
            福祉
            <br />
            専門
            <br />
            職員
            <br />
            等
            <br />
            連携
            <br />
            加算
          </td>
          <td rowSpan={3} className="label miniLabel borderBold">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={3} className="label msize">
            備考
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="label ">
            開始
            <br />
            時間
          </td>
          <td rowSpan={2} className="label ">
            終了
            <br />
            時間
          </td>
          <td colSpan={2} className="label borderBold">
            計画
            <br />
            時間数
          </td>
          <td colSpan={2} rowSpan={2} className="label ">
            開始時間
          </td>
          <td colSpan={2} rowSpan={2} className="label ">
            終了時間
          </td>
          <td rowSpan={2} className="label timesize">
            時間
          </td>
          <td rowSpan={2} className="label sssssize borderBold">
            乗<br />降
          </td>
        </tr>
        <tr>
          <td className="label timesize">時間</td>
          <td className="label sssssize borderBold">
            乗<br />降
          </td>
        </tr>
        {records.map((inout_results_kyotakukaigo, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";
          const statusValue =
            inout_results_kyotakukaigo && inout_results_kyotakukaigo.status
              ? inout_results_kyotakukaigo.status.toString()
              : "0";
          const serialNumberValue =
            inout_results_kyotakukaigo &&
            inout_results_kyotakukaigo.serialNumber
              ? inout_results_kyotakukaigo.serialNumber.toString()
              : "";
          const getStatus = (status: string): JSX.Element => {
            switch (status) {
              case "0":
                return <td className="label borderBold ssmall" />;
              case "11":
                return (
                  <td className="label borderBold ssmall">
                    身体
                    <br />
                    (基礎等)
                  </td>
                );
              case "12":
                return (
                  <td className="label borderBold ssmall">
                    身体
                    <br />
                    (重訪)
                  </td>
                );
              case "20":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴う)
                  </td>
                );
              case "21":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴う)
                    <br />
                    (基礎等)
                  </td>
                );
              case "22":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴う)
                    <br />
                    (重訪)
                  </td>
                );
              case "30":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴ず)
                  </td>
                );
              case "31":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴ず)
                    <br />
                    (基礎等)
                  </td>
                );
              case "32":
                return (
                  <td className="label borderBold ssmall">
                    通院
                    <br />
                    (伴ず)
                    <br />
                    (重訪)
                  </td>
                );
              case "41":
                return (
                  <td className="label borderBold ssmall">
                    家事
                    <br />
                    (基礎等)
                  </td>
                );
              case "42":
                return (
                  <td className="label borderBold ssmall">
                    家事
                    <br />
                    (重訪)
                  </td>
                );
              case "51":
                return (
                  <td className="label borderBold ssmall">
                    乗降
                    <br />
                    (基礎等)
                  </td>
                );
              case "52":
                return (
                  <td className="label borderBold ssmall">
                    乗降
                    <br />
                    (重訪)
                  </td>
                );
              default:
                return (
                  <td className="label borderBold ssmall">
                    {getLabelFromOptions(
                      statusValue,
                      KYOTAKUKAIGO_DISPLAY_STATUS_LIST
                    )}
                  </td>
                );
            }
          };

          return (
            <tr
              key={`Records-${inoutResultsKyotakukaigoIndex}-${keyIndex}`}
              className={rowClass}
            >
              <td className="label ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.day
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.dayOfWeek
                  : ""}
              </td>
              <td className="label borderBold ssmall">
                {inout_results_kyotakukaigo && serialNumberValue.length
                  ? getLabelFromOptions(
                      serialNumberValue,
                      KYOTAKUKAIGO_DISPATCH_NO_LIST
                    )
                  : ""}
              </td>
              {getStatus(statusValue)}
              <td className="label sssize ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.planInTime
                  : ""}
              </td>
              <td className="label sssize ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.planOutTime
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.planHours
                  : ""}
              </td>
              <td className="label borderBold ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.planGettingOnAndOffTimes
                  : ""}
              </td>
              <td colSpan={2} className="label sssize ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.inTime
                  : ""}
              </td>
              <td colSpan={2} className="label sssize ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.outTime
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.calculatedHours
                  : ""}
              </td>
              <td className="label borderBold ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.gettingOnAndOffTimes
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo
                  ? inout_results_kyotakukaigo.dispatchedPeople
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo &&
                inout_results_kyotakukaigo.isFirstAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kyotakukaigo &&
                inout_results_kyotakukaigo.isEmergencySupport
                  ? "1"
                  : ""}
              </td>
              <td className="label borderBold ssmall">
                {inout_results_kyotakukaigo &&
                inout_results_kyotakukaigo.isWelfareCooperation
                  ? "1"
                  : ""}
              </td>
              <td className="label borderBold ssmall" />
              <td className="ssmall">
                {inout_results_kyotakukaigo && inout_results_kyotakukaigo.memo
                  ? inout_results_kyotakukaigo.memo
                  : ""}
              </td>
            </tr>
          );
        })}
        {isEndRow && (
          <>
            <tr className="borderDoubleTop ssmall">
              <td rowSpan={2} className="label borderBold" />
              <td rowSpan={2} colSpan={5} className="label" />
              <td rowSpan={2} colSpan={2} className="label borderBold">
                計画
                <br />
                時間数計
              </td>
              <td rowSpan={1} colSpan={4} className="label">
                内訳（適用単価別）
              </td>
              <td rowSpan={2} colSpan={2} className="label borderBold">
                算定
                <br />
                時間数計
              </td>
              <td rowSpan={8} className="label">
                <div className={classes.empty4}> </div>
              </td>
              <td rowSpan={2} colSpan={3} className="label borderBold">
                <div className={classes.empty5}> </div>
              </td>
              <td rowSpan={8} colSpan={2} className="label borderBold">
                <div className={classes.empty6}> </div>
              </td>
            </tr>
            <tr>
              <td className="miniLabelPer sssssize">
                <div>100%</div>
              </td>
              <td className="miniLabelPer sssssize">
                <div>90%</div>
              </td>
              <td className="miniLabelPer sssssize">
                <div>70%</div>
              </td>
              <td className="miniLabelPer sssssize">
                <div>重訪</div>
              </td>
            </tr>
            <tr className="borderBoldTop">
              <td rowSpan={6} className="label borderBottomBold borderBold">
                合<br />計
              </td>
            </tr>
            <tr className="borderBoldTop">
              <td colSpan={5} className="label ssmall">
                居宅における身体介護
              </td>
              <td className="label"> {`${user.cntPlanPhysicalCare}`} </td>
              <td rowSpan={4} className="label borderBold">
                <div className={classes.empty3}> </div>
              </td>
              <td className="label">{`${user.cntPhysicalCareOf100}`}</td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">{`${user.cntPhysicalCareOf70}`}</td>
              <td className="label">{`${user.cntPhysicalCareOfSevere}`}</td>
              <td className="label">{`${user.cntPhysicalCare}`}</td>
              <td rowSpan={4} className="label borderBold">
                <div className={classes.empty3}> </div>
              </td>
              <td rowSpan={5} className="label">
                {`${user.cntFirstAddition}回`}
              </td>
              <td rowSpan={5} className="label">
                {`${user.cntEmergencySupport}回`}
              </td>
              <td rowSpan={5} className="label borderBold">
                {`${user.cntWelfareCooperation}回`}
              </td>
            </tr>
            <tr>
              <td colSpan={5} className="label">
                通院介護(身体介護を伴う)
              </td>
              <td className="label">
                {`${user.cntPlanOutpatientCareWithPhysicalCare}`}
              </td>
              <td className="label">
                {`${user.cntOutpatientCareWithPhysicalCareOf100}`}
              </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                {`${user.cntOutpatientCareWithPhysicalCareOf70}`}
              </td>
              <td className="label">{`${user.cntOutpatientCareWithPhysicalCareOfSevere}`}</td>
              <td className="label">{`${user.cntOutpatientCareWithPhysicalCare}`}</td>
            </tr>
            <tr>
              <td colSpan={5} className="label">
                家事援助
              </td>
              <td className="label">{`${user.cntPlanHouseworkAssistance}`}</td>
              <td className="label">{`${user.cntHouseworkAssistanceOf100}`}</td>
              <td className="label">{`${user.cntHouseworkAssistanceOf90}`} </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">{`${user.cntHouseworkAssistance}`}</td>
            </tr>
            <tr>
              <td colSpan={5} className="label">
                通院介護(身体介護を伴わない)
              </td>
              <td className="label">{`${user.cntPlanOutpatientCare}`}</td>
              <td className="label">{`${user.cntOutpatientCareOf100}`} </td>
              <td className="label">{`${user.cntOutpatientCareOf90}`} </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">{`${user.cntOutpatientCare}`}</td>
            </tr>
            <tr>
              <td colSpan={5} className="label borderBottomBold">
                通院等乗降介助
              </td>
              <td className="label borderBottomBold">
                <div className={classes.empty}> </div>
              </td>
              <td className="label borderBottomBold borderBold">
                {`${user.cntPlanGettingOnAndOff}`}
              </td>
              <td className="label borderBottomBold">{`${user.cntGettingOnAndOffOf100}`}</td>
              <td className="label borderBottomBold">{`${user.cntGettingOnAndOffOf90}`}</td>
              <td className="label borderBottomBold">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label borderBottomBold">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label borderBottomBold">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label borderBottomBold borderBold">{`${user.cntGettingOnAndOff}`}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export const KYOTAKUKAIGOJisseki = withStyles(styles)(InoutRecordTableCore);
