import { AxiosResponse } from "axios";
import request from "@api/index";
import { BaseResponse } from "@api/types";
import { VERSION_URL_201910 } from "@config";

export type KEIKAKUSODANGetConsultationResponse = BaseResponse<{
  id: number;
  created_at: string;
  updated_at: string;
  target_flg: number;
  creation_date: string;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  monitoring_period: number | null;
  monitoring_start_ym: string | null;
  plan_start_ym: string;
  classify_disability_support: number;
  income_kind: string | null;
  disabled_welfare_service_recipient_number: string;
  regular_place_recipient_number: string;
  consultation_support_recipient_number: string;
  desired_life: string | null;
  support_policy: string | null;
  long_term_goal: string | null;
  short_term_goal: string | null;
  support_plan_consultation_details?: {
    support_plan_consultation_details_id: number;
    needs: string;
    support_goal: string;
    achievement_time: string;
    welfare_service_type: string;
    provider_name: string | null;
    role_in_problem_solving: string;
    evaluation_time: string;
    other_considerations: string;
  }[];
  support_plan_consultation_schedule: {
    support_plan_consultation_schedule_id: number;
    day_of_week: number[];
    start_time: string;
    next_day_flg: number;
    end_time: string;
    needs: string;
    content: string;
  }[];
  main_activities: string;
  non_weekly_service: string;
  overall_life: string;
  staff_comment: string;
  type_consultation: number;
}>;

export type CHIIKIIKOGetConsultationResponse = BaseResponse<{
  id: number;
  created_at: string;
  updated_at: string;
  target_flg: number;
  creation_date: string;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  attainment_goal: string | null;
  long_term_goal: string | null;
  short_term_goal: string | null;
  start_ym: string | null;
  end_ym: string | null;
  support_plan_consultation_details?: {
    support_plan_consultation_details_id: number;
    support_goal: string;
    collaborator: string;
    contents_cooperation: string;
    considerations: string;
    start_ym: string;
    end_ym: string;
    users_do: string;
  }[];
  staff_comment: string;
}>;

/**
 * 指定ユーザーのサービス等利用計画、地域移行計画の情報を取得する
 * @param uifId ユーザーID
 * @param consultationId 計画ID
 */
export const getConsultation = async <
  T extends
    | KEIKAKUSODANGetConsultationResponse
    | CHIIKIIKOGetConsultationResponse
>(
  uifId: string,
  consultationId: string
): Promise<AxiosResponse<T>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/consultation/${consultationId}`;
  return request.get<T>(url);
};
