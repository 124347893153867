import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { UserState } from "@stores/domain/user/type";
// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryFormDaily } from "@components/organisms/record/serviceDelivery/ServiceDeliveryFormDaily";
import { OptionInterface } from "@components/atoms/DropDown";
// utils
import { dateTodayInFormat } from "@utils/date";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { FacilityType } from "@constants/variables";

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  fetchStaffs: () => void;
  fetchDetailsRecord: (
    serviceDeliveryRecordsId: number | null,
    inoutResultsId: number | null,
    supportProcedureFormsId: number | null
  ) => Promise<void>;
  fetchUser: (id: string, facilityType: FacilityType) => void;
};

type StateProps = {
  userState: UserState;
  detailRecords: ServiceDeliveryState["detailsRecord"];
};

type OwnProps = RouteComponentProps<{
  date: string;
  serviceDeliveryRecordsId: string;
  inoutResultsId: string;
  uifId: string;
  supportProcedureFormsId: string;
}>;

type Props = OwnProps &
  StateProps &
  DispatchProps & {
    staffOptions: FieldItem[];
    userListOption: OptionInterface[];
  };

/**
 * サービス提供記録（編集）
 */
const ServiceDeliveryEditCore = (props: Props): JSX.Element => {
  const {
    date,
    serviceDeliveryRecordsId,
    inoutResultsId,
    uifId,
    supportProcedureFormsId
  } = props.match.params;
  const pageName = `サービス提供記録${"　"}${dateTodayInFormat(date, true)}`;

  // fetch
  React.useEffect(() => {
    props.fetchCustomRecords();
    props.fetchStaffs();
    props.fetchDetailsRecord(
      Number(serviceDeliveryRecordsId),
      Number(inoutResultsId),
      Number(supportProcedureFormsId)
    );
    props.fetchUser(uifId, props.userState.facility_type);
  }, []);

  return (
    <AdminTemplate pageName={pageName}>
      <ServiceDeliveryFormDaily
        date={new Date(fixDateAndTimeFormat(date))}
        targetDate={date}
        isEdit
        history={props.history}
        facilityType={props.userState.facility_type}
        detailRecords={props.detailRecords}
        params={props.match.params}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  detailRecords: state.serviceDelivery.detailsRecord,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    customRecordsWithCategory,
    serviceDelivery,
    staffDispatcher
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchDetailsRecord: (
      serviceDeliveryRecordsId: number | null,
      inoutResultsId: number | null,
      supportProcedureFormsId: number | null
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchDetailsRecord(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      ),
    fetchUser: (id: string, facilityType: FacilityType): Promise<void> =>
      serviceDeliveryDispatches.fetchUser(id, facilityType)
  };
};

export const ServiceDeliveryEditDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryEditCore);
