import * as URL from "@constants/url";
import OutlineReceiptIcon from "@images/icons/OutlineReceiptIcon";
import OutlineRestoreIcon from "@images/icons/OutlineRestoreIcon";
import OutlineSettingsIcon from "@images/icons/OutlineSettingsIcon";
import OpenInNew from "@material-ui/icons/OpenInNew";
import HelpOutLine from "@material-ui/icons/HelpOutline";
import { MenuItemList } from "@constants/menu";
import OutlineAssignmentIcon from "@images/icons/OutlineAssignmentIcon";

export const MENU_ITEM_LIST_DOKOENGO: MenuItemList = [
  {
    title: "利用実績",
    to: URL.REPORT,
    icon: OutlineRestoreIcon,
    selectedPattern: `(${URL.REPORT}).*$`
  },
  {
    title: "記録",
    to: "",
    icon: OutlineAssignmentIcon,
    children: [
      {
        title: "サービス提供記録",
        to: URL.RECORD_SERVICE_DELIVERY_DAILY,
        selectedPattern: `^${URL.RECORD_SERVICE_DELIVERY}`
      },
      {
        title: "支援計画",
        to: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY,
        selectedPattern: `^${URL.RECORD_SUPPORT_PLAN}`
      }
    ],
    checkPlanGroupOperationSupport: true
  },
  {
    title: "請求",
    to: URL.DOWNLOAD,
    icon: OutlineReceiptIcon,
    checkPlanGroupInvoice: true
  },
  {
    title: "設定",
    icon: OutlineSettingsIcon,
    to: "",
    children: [
      {
        title: "事業所情報",
        to: URL.FACILITY,
        key: "facility",
        selectedPattern: `^(${URL.FACILITY}|${URL.FACILITY_202104}).*$`
      },
      {
        title: "利用者情報",
        to: URL.USERS,
        key: "users",
        selectedPattern: `^(${URL.USERS}|${URL.USERS_202104}).*$`
      },
      {
        title: "職員情報",
        to: URL.STAFFS
      },
      {
        title: "提供記録の設定",
        to: URL.RECORD_SETTING,
        checkPlanGroupOperationSupport: true
      },
      {
        title: "アセスメントの設定",
        to: URL.SETTING_ASSESSMENT,
        checkPlanGroupOperationSupport: true
      }
    ]
  },
  {
    title: "ヘルプ",
    icon: HelpOutLine,
    to: "",
    children: [
      {
        title: "操作マニュアル",
        to: URL.MANUAL_URL,
        target: "_blank",
        rightIcon: OpenInNew
      },
      {
        title: "お問い合わせ",
        to: URL.CONTACT,
        target: "_blank",
        rightIcon: OpenInNew
      }
    ]
  }
];
