import React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/JIRITSUKUNRENSEIKATSU/report/initial";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrap: {
      padding: "8px 28px 4px 0"
    },
    cancel: {
      marginRight: spacing.unit
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  onCancel: () => void;
  disabled: boolean;
};

type Props = StateProps & WithStyles<typeof styles>;

const InOutReportDialogSubmitCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.wrap}>
      <KnowbeButton
        className={classes.cancel}
        minWidth={125}
        kind="outline"
        onClick={props.onCancel}
      >
        キャンセル
      </KnowbeButton>
      <FormikSubmitButton
        minWidth={125}
        buttonName="保存する"
        formikProps={props.formikPropsValues}
        disabled={props.disabled}
      />
    </div>
  );
};

export const InOutReportDialogSubmit = withStyles(styles)(
  InOutReportDialogSubmitCore
);
