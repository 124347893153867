import * as types from "./types";
import * as actions from "./actions";

const initialState: types.SupportReportState = {
  support: {
    usage_results: [],
    report: {
      id: 0,
      facility_id: 0,
      uif_id: 0,
      creation_date: "",
      working_start_date: "",
      company_name: "",
      responsible_person: "",
      goal_1: "",
      goal_2: "",
      goal_3: "",
      future_support_content: "",
      person_behavior: "",
      facility_behavior: "",
      related_organizations_behavior: "",
      shared_content: "",
      presentation_date: "",
      next_support_plans: "",
      author: {
        id: 0,
        name: "",
        role: "",
        facility_id: 0,
        snapshot_name: "",
        snapshot_role: ""
      },
      situations: []
    }
  },
  created_at: null,
  updated_at: null
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportReportState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_REPORT_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_REPORT_SUCCESS:
      return { ...action.payload };
    case types.FETCH_SUPPORT_REPORT_FAILED:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
