import * as React from "react";

import { dateToLocalisedString, convertWareki } from "@utils/date";

import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/domain/invoice/type";
import { isEmpty } from "lodash-es";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";

const RECORD_MAX_ROW = 31;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    empty: {
      width: "100%",
      transform: "matrix(1.03, -0.13, 0, 1, 1, 0)",
      borderTop: "solid black 1px"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.thin": {
        border: "1px solid"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        lineHeight: 1.4,
        height: 20,
        wordBreak: "break-all",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.nowrap": {
          whiteSpace: "nowrap"
        },
        "&.ssmall": {
          height: 25
        },
        "&.small": {
          height: 100
        },
        "&.w20": {
          width: 20
        },
        "&.w24": {
          width: 24
        },
        "&.w36": {
          width: 36
        },
        "&.w40": {
          width: 40
        },
        "&.w50": {
          width: 50
        },
        "&.w60": {
          width: 60
        },
        "&.ssize": {
          width: 75
        },
        "&.w78": {
          width: 78
        },
        "&.w110": {
          width: 110
        },
        "&.msize": {
          width: 120
        },
        "&.vertical": {
          height: 50,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        // フォントサイズ調整用クラス
        // フォントサイズのscaleを行うと、親クラスのサイズに合わせた幅調整が必要
        "&.fontSize6": {
          "& > div": {
            whiteSpace: "nowrap"
          },
          "&.w60": {
            maxWidth: 60,
            "& > div": {
              transform: "scale(0.7) translate(-13px, 0px)"
            }
          }
        },
        "&.fontSize7": {
          "& > div": {
            whiteSpace: "nowrap"
          },
          "&.w20": {
            maxWidth: 20,
            "& > div": {
              width: 21,
              transform: "scale(0.7) translate(-2px, 0px)"
            }
          },
          "&.w36": {
            maxWidth: 36,
            "& > div": {
              // 幅を取らないと文字が中央に寄らないため幅を設定
              width: 41,
              transform: "scale(0.7) translate(-6px, 0px)"
            }
          },
          "&.w40": {
            maxWidth: 40,
            "& > div": {
              transform: "scale(0.7) translate(-2px, 0px)"
            }
          },
          "&.w50": {
            maxWidth: 50,
            "& > div": {
              width: 60,
              transform: "scale(0.7) translate(-10px, 0px)"
            }
          }
        },
        "&.fontSize8": {
          "& > div": {
            whiteSpace: "nowrap"
          },
          "&.w40": {
            maxWidth: 40,
            "& > div": {
              transform: "scale(0.8) translate(-2px, 0px)"
            }
          }
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDoubleTop": {
          borderTopStyle: "double"
        },
        "&.borderDashed": {
          borderBottom: "1px dashed"
        }
      }
    },
    dateTable: {
      borderCollapse: "collapse",
      width: "100%",
      fontSize: 10,
      textAlign: "center",
      "& td": {
        borderRight: "1px solid",
        "&.borderBoldRight": {
          borderRight: "2px solid"
        },
        "&.w74": {
          width: 74
        }
      },
      // テーブル全体のボーダールール設定
      "& tr:first-child > td": {
        borderTop: "2px solid"
      },
      "& tr > td": {
        borderBottom: "2px solid"
      },
      "& tr > td:first-child": {
        borderLeft: "2px solid"
      },
      "& tr > td:last-child": {
        borderRight: "2px solid"
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 生活介護の実績記録票
 */
const InoutRecordTable = (props: Props): JSX.Element => {
  const { key, invoiceData } = props;
  const { date, facility, users } = invoiceData;
  const { classes } = props;

  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;
        return (
          <section
            key={`InoutRecordTable-${keyIndex}`}
            className={classes.page}
          >
            <div className={`${classes.flexContainer} center relative`}>
              <div className={classes.date}>
                <span>{`${convertWareki(date.year, date.month).era}${
                  convertWareki(date.year, date.month).year
                }年${date.month}月分`}</span>
              </div>
              <span>生活介護サービス提供実績記録票</span>
            </div>
            <div className={classes.flexContainer}>
              <table className={`${classes.table} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="label">
                      受給者証
                      <br />
                      番号
                    </td>
                    <td className="ssize">{user.recipientNo}</td>
                    <td className="label">支給決定障害者氏名</td>
                    <td className="label msize">{user.recipientName}</td>
                    <td className="label">事業所番号</td>
                    <td className="label nowrap w78">{facility.officeNo}</td>
                  </tr>
                  <tr>
                    <td className="label">契約支給量</td>
                    <td colSpan={2} className="label">
                      {user.payment}
                    </td>
                    <td className="label">
                      事業者及び
                      <br />
                      その事業所
                    </td>
                    <td colSpan={2} className="label">
                      {facility.officeName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.flexContainer}>
              <Records
                classes={classes}
                user={user}
                facilityType={facility.kindService}
              />
            </div>
            <div className={classes.flexContainer}>
              <table className={`${classes.dateTable} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="borderBoldRight w74">初期加算</td>
                    <td>利用開始日</td>
                    <td>
                      {isEmpty(user.dateBeginService)
                        ? ""
                        : dateToLocalisedString(
                            new Date(user.dateBeginService),
                            "YYYY年MM月DD日"
                          )}
                    </td>
                    <td className="borderBoldRight">30日目</td>
                    <td>
                      {isEmpty(user.dateAfter30Days)
                        ? ""
                        : dateToLocalisedString(
                            new Date(user.dateAfter30Days),
                            "YYYY年MM月DD日"
                          )}
                    </td>
                    <td>当月算定日数</td>
                    <td>{`${user.cntDaysInitApply}日`}</td>
                  </tr>
                  <tr>
                    <td className="borderBoldRight w74">集中的支援加算</td>
                    <td>支援開始日</td>
                    <td>
                      {user.dateIntensiveSupportStart
                        ? dateToLocalisedString(
                            new Date(user.dateIntensiveSupportStart),
                            "YYYY年MM月DD日"
                          )
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={`${classes.flexContainer} end`}>
              <table className={`${classes.table} thin`}>
                <tbody>
                  <tr>
                    <td className="label">1</td>
                    <td className="label">枚中</td>
                    <td className="label">1</td>
                    <td className="label">枚</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        );
      })}
    </React.Fragment>
  );
};
type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const { user, classes } = props;
  const emptyRow =
    user.records.length >= RECORD_MAX_ROW
      ? 0
      : RECORD_MAX_ROW - user.records.length;
  // tslint:disable-next-line:prefer-array-literal
  const emptyList = new Array<undefined>(emptyRow).fill(undefined);
  const records = [...user.records, ...emptyList];

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={3} className="label vertical w24">
            <span>日付</span>
          </td>
          <td rowSpan={3} className="label vertical borderBold w24">
            <span>曜日</span>
          </td>
          <td colSpan={13} className="label borderBold">
            サービス提供実績
          </td>
          <td rowSpan={3} className="label w36 borderBold nowrap">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={3} className="label w110">
            備考
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="label w50 fontSize7">
            <div>
              サービス提供
              <br />
              の状況
            </div>
          </td>
          <td rowSpan={2} className="label w40 fontSize7">
            <div>開始時間</div>
          </td>
          <td rowSpan={2} className="label w40 fontSize7">
            <div>終了時間</div>
          </td>
          <td rowSpan={2} className="label w20 fontSize7">
            <div>
              算定
              <br />
              時間
              <br />数
            </div>
          </td>
          <td colSpan={2} className="label w40 fontSize8">
            <div>送迎加算</div>
          </td>
          <td className="w60 fontSize6">
            <div>訪問支援特別加算</div>
          </td>
          <td rowSpan={2} className="label w36 fontSize7">
            <div>
              食事提供
              <br />
              加算
            </div>
          </td>
          <td rowSpan={2} className="label w36 fontSize7">
            <div>
              体験利用
              <br />
              支援加算
            </div>
          </td>
          <td rowSpan={2} className="label w36 fontSize7">
            <div>
              入浴支援
              <br />
              加算
            </div>
          </td>
          <td rowSpan={2} className="label w36 fontSize7">
            <div>
              喀痰吸引
              <br />
              等実施加
              <br />算
            </div>
          </td>
          <td rowSpan={2} className="label w36 fontSize7">
            <div>
              緊急時受
              <br />
              入加算
            </div>
          </td>
          <td rowSpan={2} className="label w36 borderBold fontSize7">
            <div>
              集中的支
              <br />
              援加算
            </div>
          </td>
        </tr>
        <tr>
          <td className="label borderDashed w20">往</td>
          <td className="label w20">復</td>
          <td className="label">時間数</td>
        </tr>
        {records.map((record, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";

          return (
            <tr key={`Records-${keyIndex}`} className={rowClass}>
              <td className="label ssmall">{record ? record.day : ""}</td>
              <td className="label borderBold ssmall">
                {record ? record.dayOfWeek : ""}
              </td>
              <td className="label sssize ssmall">
                {record ? record.serviceStatus : ""}
              </td>
              <td className="label sssize ssmall">
                {record ? record.startTime : ""}
              </td>
              <td className="label sssize ssmall">
                {record ? record.endTime : ""}
              </td>
              <td className="label ssmall">
                {record ? record.additions.calculatedHours : ""}
              </td>
              <td className="label borderDashed ssmall">
                {record && (record.additions.isInbound ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record && (record.additions.isOutbound ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record && record.additions.supportDiffTime}
              </td>
              <td className="label ssmall">
                {record && (record.additions.isSuppliedMeal ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record ? record.additions.trialUsage : ""}
              </td>
              <td className="label ssmall">
                {record && (record.additions.isBathSupport ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record && (record.additions.isSputumImplementation ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record && record.additions.isEmergencyAcceptanceAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall borderBold">
                {record && record.additions.isIntensiveSupport ? "1" : ""}
              </td>
              <td className="label ssmall borderBold" />
              <td className="ssmall">{record ? record.memo : ""}</td>
            </tr>
          );
        })}
        <tr className="borderDoubleTop borderDashed">
          <td colSpan={5} className="label">
            合計
          </td>
          <td className="prise">{user.cntCalculatedHours}</td>
          <td colSpan={2} className="prise">{`${user.cntPickup}回`}</td>
          <td className="prise">{`${user.cntVisit}回`}</td>
          <td className="prise">{`${user.cntFood}回`}</td>
          <td className="prise">{`${user.cntTrialUsage}回`}</td>
          <td className="prise">{`${user.cntBathSupport}回`}</td>
          <td className="prise">{`${user.cntSputumImplementation}回`}</td>
          <td className="prise">{`${user.cntEmergencyAcceptanceAddition}回`}</td>
          <td className="prise borderBold">{`${user.cntIntensiveSupport}回`}</td>
          <td rowSpan={2} colSpan={2}>
            {/* 斜線 */}
            <div className={classes.empty} />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default withStyles(styles)(InoutRecordTable);
