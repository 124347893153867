import React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordHeaderAllEditType from "@components/organisms/mgr/common/record/RecordHeaderAllEditType";
import { RecordSupportPlanValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportPlan/initialValues";
import { FormikProps, getIn } from "formik";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import { ErrorsState } from "@stores/domain/errors/types";
import ClassNames from "classnames";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { BasicFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/BasicFields";
import { GoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/GoalFields";
import { SupportPlanGoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/SupportPlanGoalFields";
import { SupportPlanGoalEvaluationFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/SupportPlanGoalEvaluationFields";
import { OtherFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/OtherFields";
import { WarningAlert } from "@components/organisms/mgr/common/record/WarningAlert";
import { dateToLocalisedString } from "@/utils/date";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: "16px 32px 32px"
    },
    paper2: {
      padding: "20px 32px 32px",
      marginTop: 16
    },
    title: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      paddingBottom: 32
    },
    title2: {
      borderBottom: "1px solid #757575",
      paddingBottom: 8,
      marginBottom: 30
    },
    section: {
      margin: "-16px 0 0"
    },
    secondaryWrapper: {
      backgroundColor: "#fafafa",
      borderRadius: "4px",
      border: "1px solid #bdbdbd",
      fontSize: 14,
      padding: 24,
      margin: "30px 0 0"
    },
    field: {
      marginBottom: 32,
      "& textarea::-webkit-scrollbar": {
        display: "none"
      }
    },
    dateField: {
      width: 245,
      marginBottom: 32
    },
    supportDateField: {
      width: 277,
      marginLeft: 16,
      marginBottom: 32
    },
    authorizerField: {
      width: 245,
      marginLeft: 24,
      marginBottom: 32
    },
    reviewer: {
      width: 245,
      marginRight: 16
    },
    userInfo: {
      margin: "8px 0 48px"
    },
    hidden: {
      visibility: "hidden",
      margin: 0,
      height: 0
    },
    hideIcon: {
      "&::before": {
        content: "''",
        display: "inline-block",
        borderTop: "1px solid #0277bd",
        borderLeft: "1px solid #0277bd",
        transform: "rotate(45deg)",
        width: 6,
        height: 6,
        marginRight: 10
      }
    },
    showIcon: {
      "&::before": {
        content: "''",
        display: "inline-block",
        borderBottom: "1px solid #0277bd",
        borderRight: "1px solid #0277bd",
        transform: "rotate(45deg)",
        width: 6,
        height: 6,
        marginRight: 10,
        marginBottom: 2
      }
    },
    goalField: {
      margin: "32px 0 48px"
    },
    divider2: {
      marginTop: 24
    },
    divider: {
      marginTop: 48
    },
    assessmentDateField: {
      marginBottom: 32
    },
    warningAlertWrap: {
      marginBottom: 16,
      marginTop: -8
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  supportPlanId?: string;
  assessmentDate: string | null;
  creationDate: string | null;
  supportBeginDate: string | null;
  supportEndDate: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  dateBirth: string;
  isEditing: boolean;
  staff: StaffState;
  staffOptions: FieldItem[];
  authorValue: string;
  authorizerValue: string;
  evaluationAuthorizerValue: string;
  evaluationAuthorizerRole: string;
  history: H.History;
  errorsState: ErrorsState["goal"]["data"];
  onClickEdit: (e: React.MouseEvent) => void;
  onClickEditCancel: () => void;
  formikProps: FormikProps<RecordSupportPlanValues>;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 評価振り返り閲覧編集
 */
const EvaluationFieldsCore = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  const currentEvaluationStatus = getIn(formikValues, "evaluation_status");
  const currentEvaluationAuthorizer = getIn(
    formikValues,
    "evaluation_authorizer"
  );

  // state
  const [showReviewer, setShowReviewer] = React.useState(false);
  const [evaluationStaffRole, setEvaluationStaffRole] = React.useState("-");
  const [showUserInfo, setShowUserInfo] = React.useState(false);

  // 評価ステータス変更時
  React.useEffect(() => {
    setShowReviewer(currentEvaluationStatus === "1");
  }, [currentEvaluationStatus]);

  // 評価者変更時
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態の場合
      // 変更後の evaluation_authorizer 値にマッチした役職名を evaluationStaffRole に流し込む
      setEvaluationStaffRole(
        getStaffRole(props.staff, currentEvaluationAuthorizer) || "-"
      );
    } else {
      // 閲覧状態の場合 ( 初期表示時 )
      // evaluationStaffRole に snapshot_role を流し込む ( スナップがない場合は role 値が入る )
      setEvaluationStaffRole(props.evaluationAuthorizerRole || "-");
    }
  }, [currentEvaluationAuthorizer]);

  // 編集・更新完了時に
  // 「必須ではない」かつ「入力状態が不完全」
  // な日付項目をリセットさせるための施策
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態開始時
      // スナップ情報ではなく現行の値に表示を切り替えるため evaluationStaffRole を更新させる
      setEvaluationStaffRole(
        getStaffRole(props.staff, currentEvaluationAuthorizer) || "-"
      );
    } else {
      props.formikProps.resetForm();
      // 編集状態終了時 ( 編集「キャンセル」時 )
      // スナップ情報を復元させるため evaluationStaffRole を更新させる
      // ( 編集キャンセル前に値を変更された状態が残るケースのフォロー施策 )
      setEvaluationStaffRole(props.evaluationAuthorizerRole || "-");
    }
  }, [props.isEditing]);

  const { errorsState, supportPlanId } = props;
  const relationId = Number(supportPlanId);
  const hasError = errorsState && errorsState.length > 0;
  // エラー表示条件は「エラー種別が警告」「自身(supportPlanId)が等価」であること
  const hasWarningData =
    hasError &&
    errorsState[0].errors.findIndex(
      (e) => e.type === "warn" && e.relation.value === relationId
    ) > -1;

  const toggleUserInfo = (): void => {
    setShowUserInfo((isShowUserInfo) => !isShowUserInfo);
  };

  const supportBeginDate = props.supportBeginDate
    ? dateToLocalisedString(props.supportBeginDate, "YYYY年M月D日")
    : "";
  const supportEndDate = props.supportEndDate
    ? dateToLocalisedString(props.supportEndDate, "YYYY年M月D日")
    : "";

  return (
    <div>
      <RecordHeaderAllEditType
        pageName={props.pageName}
        userName={props.userName}
        hasRecord // 新規及びリスト経由なので常にtrue
        uifId={props.uifId}
        supportPlanId={supportPlanId}
        recordType="support_plan"
        isEditing={props.isEditing}
        onClickEdit={props.onClickEdit}
        onClickEditCancel={props.onClickEditCancel}
        formikProps={props.formikProps}
        history={props.history}
        isEvaluation
        isExistSupportReport
      />
      <Paper className={props.classes.paper} elevation={0}>
        {!props.isEditing && errorsState && hasWarningData && (
          <div className={props.classes.warningAlertWrap}>
            <WarningAlert
              supportPlanId={supportPlanId}
              errorsState={errorsState}
            />
          </div>
        )}
        <CreateAndUpdateDate
          createdAt={props.createdAt}
          updatedAt={props.updatedAt}
        />
        <div className={props.classes.section}>
          {props.isEditing ? (
            <div className={props.classes.assessmentDateField}>
              <RecordSelectDate
                name="support_plan_shuroteichaku.assessment_date"
                label="評価日"
                value={formikValues.previous_creation_date}
                isEditable
                addYearTo={1}
                overrideYearFrom={1989}
              />
            </div>
          ) : (
            <div className={props.classes.dateField}>
              <ReadonlyTextField
                label="評価日"
                value={
                  props.assessmentDate
                    ? dateToLocalisedString(
                        props.assessmentDate,
                        "YYYY年M月D日"
                      )
                    : ""
                }
                defaultValue="-"
              />
            </div>
          )}
          <FormGroup row>
            <div className={props.classes.dateField}>
              <ReadonlyTextField
                label="計画作成日"
                value={
                  props.creationDate
                    ? dateToLocalisedString(props.creationDate, "YYYY年M月D日")
                    : ""
                }
                defaultValue="-"
              />
            </div>
            <div className={props.classes.supportDateField}>
              <ReadonlyTextField
                label="支援期間"
                value={
                  supportBeginDate || supportEndDate
                    ? `${supportBeginDate}〜${supportEndDate}`
                    : ""
                }
                defaultValue="-"
              />
            </div>
          </FormGroup>
          <FormGroup row>
            <div className={props.classes.dateField}>
              <ReadonlyTextField
                label="計画作成者"
                value={props.authorValue}
                defaultValue="-"
              />
            </div>
            <div className={props.classes.authorizerField}>
              <ReadonlyTextField
                label="承認者"
                value={props.authorizerValue}
                defaultValue="-"
              />
            </div>
          </FormGroup>
          <KnowbeButton kind="text" onClick={toggleUserInfo}>
            <span
              className={
                showUserInfo ? props.classes.hideIcon : props.classes.showIcon
              }
            >
              就職先情報・本人情報を
              <span>{showUserInfo ? "非表示" : "表示"}</span>
            </span>
          </KnowbeButton>
        </div>
        <div
          className={ClassNames(
            !showUserInfo && props.classes.hidden,
            props.classes.userInfo
          )}
        >
          <BasicFields
            isEditing={false}
            dateBirth={props.dateBirth}
            values={formikValues}
          />
        </div>

        <div className={props.classes.divider2} />
        <GoalFields
          isEditing={false}
          sprintType={1}
          values={formikValues}
          isDateSplitOff
        />
        <div className={props.classes.goalField}>
          <SupportPlanGoalEvaluationFields
            isEditing={props.isEditing}
            index={0}
            values={formikValues}
          />
        </div>

        <GoalFields
          isEditing={false}
          sprintType={2}
          values={formikValues}
          isDateSplitOff
        />
        <div className={props.classes.goalField}>
          <SupportPlanGoalEvaluationFields
            isEditing={props.isEditing}
            index={1}
            values={formikValues}
          />
        </div>

        <SupportPlanGoalFields
          isEditing={props.isEditing}
          values={formikValues}
          hasEvaluation
        />
        <div className={props.classes.divider} />

        <OtherFields isEditing={false} values={formikValues} />
        <div className={props.classes.secondaryWrapper}>
          <RecordTextField
            name="after_summary"
            value={formikValues.after_summary}
            defaultValue="-"
            label="総括評価"
            labelType="default"
            placeholder="入力してください"
            isEditable={props.isEditing}
          />
        </div>
      </Paper>
      <Paper className={props.classes.paper2} elevation={0}>
        <div className={props.classes.title2}>
          <Typography variant="h6" color="primary">
            モニタリング会議 議事録
          </Typography>
        </div>
        <div className={props.classes.field}>
          <RecordSelectDate
            name="evaluation_date"
            label="実施日"
            value={formikValues.evaluation_date}
            isEditable={props.isEditing}
            addYearTo={10}
            overrideYearFrom={1989}
          />
        </div>
        <div className={props.classes.field}>
          <RecordTextField
            name="evaluation_minutes"
            value={formikValues.evaluation_minutes}
            defaultValue="-"
            label="議事録"
            labelType="default"
            placeholder="入力してください"
            isEditable={props.isEditing}
          />
        </div>
        <FormGroup row>
          <div className={props.classes.reviewer}>
            <RecordSelect
              name="evaluation_status"
              value={
                formikValues.evaluation_status === "1" ? "実施済" : "未実施"
              }
              defaultValue="-"
              label="モニタリング実施状況"
              placeholder="入力してください"
              options={[
                { label: "未実施", value: "0" },
                { label: "実施済", value: "1" }
              ]}
              isEditable={props.isEditing}
            />
          </div>
          {showReviewer && (
            <>
              <div className={props.classes.reviewer}>
                <RecordSelect
                  name="evaluation_authorizer"
                  value={props.evaluationAuthorizerValue}
                  defaultValue="未設定"
                  label="評価者"
                  placeholder="選択してください"
                  options={props.staffOptions}
                  isEditable={props.isEditing}
                  isSelectablePlaceholder
                  emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                />
              </div>
              <div className={props.classes.reviewer}>
                <ReadonlyTextField
                  value={evaluationStaffRole}
                  defaultValue="-"
                  label="役職"
                />
              </div>
            </>
          )}
        </FormGroup>
      </Paper>
    </div>
  );
};

export const EvaluationFields = withStyles(styles)(EvaluationFieldsCore);
