import {
  AttendanceListResultAPI,
  AttendanceResultAPI,
  AttendanceList,
  Attendance
} from "@stores/domain/attendance/type";
import { dateToLocalisedString } from "@utils/date";

import { SERVICE_STATUS, STRING_TRUE_FROM_API } from "@constants/variables";
import castStringCheckboxValue from "@utils/dataNormalizer/castStringCheckboxValue";

const getAttendanceStatus = (
  status: number,
  inTime: string,
  outTime: string
): "before" | "working" | "after" => {
  const targetServiceStatus = SERVICE_STATUS.find((target) => {
    return target.value === status;
  });
  if (!targetServiceStatus || targetServiceStatus.notAttended || !inTime) {
    return "before";
  }
  return !outTime ? "working" : "after";
};

export const normalizeAttendanceListFromAPI = (
  result: AttendanceListResultAPI
): AttendanceList => {
  return result.data.map((target) => {
    const inTime = target.inTime
      ? dateToLocalisedString(`${target.inTime}:00`, "HH:mm")
      : undefined;
    const outTime = target.outTime
      ? dateToLocalisedString(`${target.outTime}:00`, "HH:mm")
      : undefined;
    return {
      ...target,
      inTime,
      outTime,
      attendanceStatus: getAttendanceStatus(
        target.status || 1,
        target.inTime || "",
        target.outTime || ""
      )
    };
  });
};

export const normalizeAttendanceFromAPI = (
  result: AttendanceResultAPI,
  id: number
): Attendance => {
  const { inout, previous, facility, user } = result;

  const inTime =
    inout && inout.inTime
      ? dateToLocalisedString(`${inout.inTime}:00`, "HH:mm")
      : undefined;
  const outTime =
    inout && inout.outTime
      ? dateToLocalisedString(`${inout.outTime}:00`, "HH:mm")
      : undefined;
  const previousOutTime =
    previous && previous.outTime
      ? dateToLocalisedString(`${previous.outTime}:00`, "HH:mm")
      : undefined;
  const normalizeFacility = !Array.isArray(facility)
    ? {
        workBreakTimes: facility.workBreakTimes,
        workBreakTimeItems: facility.workBreakTimeItems,
        workTimeItems: facility.workTimeItems,
        mondaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        tuesdaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        wednesdaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        thursdaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        fridaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        saturdaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        sundaySchedule:
          facility.facility.mon_active_flg === STRING_TRUE_FROM_API,
        unitEngrave: facility.facility.work_truncate_minutes
          ? `${facility.facility.work_truncate_minutes}`
          : "0"
      }
    : [];
  const normalizeUser = user
    ? {
        user_in_facility: {
          mon_scheduled_flg: castStringCheckboxValue(user.mon_scheduled_flg),
          tue_scheduled_flg: castStringCheckboxValue(user.tue_scheduled_flg),
          wed_scheduled_flg: castStringCheckboxValue(user.wed_scheduled_flg),
          thu_scheduled_flg: castStringCheckboxValue(user.thu_scheduled_flg),
          fri_scheduled_flg: castStringCheckboxValue(user.fri_scheduled_flg),
          sat_scheduled_flg: castStringCheckboxValue(user.sat_scheduled_flg),
          sun_scheduled_flg: castStringCheckboxValue(user.sun_scheduled_flg)
        },
        work_details: user.work_details,
        work_break_times: user.work_break_times,
        work_break_time_items: user.work_break_time_items,
        work_time_items: user.work_time_items
      }
    : undefined;

  return {
    inTime,
    outTime,
    previousOutTime,
    uif_id: id,
    displayName: "",
    kanaName: "",
    attendanceStatus: getAttendanceStatus(
      inout && inout.status ? inout.status : 1,
      inout && inout.inTime ? inout.inTime : "",
      inout && inout.outTime ? inout.outTime : ""
    ),
    status: inout && inout.status ? inout.status : undefined,
    previousInTime: previous && previous.inTime ? previous.inTime : undefined,
    previousStatus: previous && previous.status ? previous.status : undefined,
    defaultStatus: result.default ? result.default.status : undefined,
    facility: normalizeFacility,
    user: normalizeUser
  };
};
