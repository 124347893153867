import getSupportsDateList from "./getSupportsDateList";
import getSupportsRecord from "./getSupportsRecord";
import getSupportRecordUser from "./getSupportRecordUser";
import getSupportSimpleRecords from "./getSupportSimpleRecords";
import getSupportRecordUserGroupHome from "./GroupHome/getSupportRecordUser";
import getSupportRecordUserSHISETSUNYUSHO from "./SHISETSUNYUSHO/getSupportRecordUser";
import getSupportRecordUserTANKINYUSHO from "./TANKINYUSHO/getSupportRecordUser";
import getUsersSummaryCustomRecords from "./getUsersSummaryCustomRecords";
import postCustomSupport from "./postCustomSupport";
import postSupports from "./postSupports";
import postSupportRecordUser from "./postSupportRecordUser";

export default {
  getSupportsDateList,
  getSupportsRecord,
  getSupportRecordUser,
  getSupportSimpleRecords,
  postSupports,
  postSupportRecordUser,
  getSupportRecordUserGroupHome,
  getSupportRecordUserSHISETSUNYUSHO,
  getSupportRecordUserTANKINYUSHO,
  getUsersSummaryCustomRecords,
  postCustomSupport
};
