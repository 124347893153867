import * as types from "./types";
import * as actions from "./actions";

const initialState: types.RecordDailyState = {
  isEditing: false,
  uifId: null,
  unitId: null
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.RecordDailyState => {
  switch (action.type) {
    case types.SET_EDIT:
      return { ...state, isEditing: true };
    case types.SET_EDIT_WITH_UIF_ID:
      return { ...state, isEditing: true, uifId: action.payload, unitId: null };
    case types.SET_EDIT_WITH_UNIT_ID:
      return { ...state, isEditing: true, uifId: null, unitId: action.payload };
    case types.UNSET_EDIT:
      return { ...state, isEditing: false, uifId: null, unitId: null };
    default:
      return state;
  }
};

export default reducer;
