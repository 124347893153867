import * as React from "react";

import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

const styles = (): StyleRules =>
  createStyles({
    button: {
      borderRadius: 4,
      width: 245
    },
    buttonSide: {
      display: "flex",
      flexWrap: "wrap",
      gap: "16px"
    }
  });

export type DownloadAction = {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
};

type OwnProps = {
  downloadCsvActions: DownloadAction[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadInvoiceSection = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.buttonSide}>
      {props.downloadCsvActions.map((action) => (
        <Button
          key={`download_${action.label}`}
          className={classes.button}
          color="secondary"
          onClick={action.onClick}
          variant="contained"
          disabled={action.isDisabled}
        >
          {action.label}
        </Button>
      ))}
    </div>
  );
};

export default withStyles(styles)(DownloadInvoiceSection);
