import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import MuiTextField from "@components/molecules/MuiTextField";
import validator from "@validator";
import { Link } from "react-router-dom";
import * as H from "history";

import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import Button from "@material-ui/core/Button";
import { LOGIN } from "@constants/url";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      "@media only screen and (min-width: 640px)": {
        margin: "48px 0 0 7px"
      },
      "@media only screen and (max-width: 639px)": {
        marginTop: 32
      }
    },
    addressSp: {
      "&>div": {
        "@media only screen and (min-width: 640px)": {
          width: 328
        },
        "@media only screen and (max-width: 639px)": {
          width: "100%"
        }
      }
    },
    submitButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      letterSpacing: 1.25,
      "@media only screen and (min-width: 640px)": {
        marginTop: 32
      }
    },
    submitButton: {
      boxShadow: "none",
      textTransform: "none",
      "@media only screen and (min-width: 640px)": {
        width: 320
      },
      "@media only screen and (max-width: 639px)": {
        width: "100%"
      }
    },
    disableButton: {
      fontSize: 14,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
      borderRadius: 4,
      "@media only screen and (min-width: 640px)": {
        width: 320
      },
      "@media only screen and (max-width: 639px)": {
        width: "100%"
      }
    },
    linkWrapper: {
      textAlign: "center",
      marginTop: 16
    },
    link: {
      textDecoration: "none",
      marginLeft: "auto",
      marginRight: "auto",
      display: "block",
      "@media only screen and (min-width: 640px)": {},
      "@media only screen and (max-width: 639px)": {
        width: "100%"
      }
    },
    linkButton: {
      color: "#0277bd",
      fontSize: 14,
      letterSpacing: 1.25,
      width: 160
    }
  });

type DispatchProps = {
  postReminder: (value: string, history: H.History) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type OwnProps = {
  history: H.History;
};

type Props = DispatchProps & OwnProps & WithStyles<typeof styles>;

const ReminderFormCore = (props: Props): JSX.Element => {
  const [accountId, setAccountId] = useState("");
  const [accountIdError, setAccountIdError] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.currentTarget;
    const error = validator(value, "email");

    setAccountIdError(error || "");
    setAccountId(error ? "" : value);
  };

  const handleSubmit = (): void => {
    props.postReminder(accountId, props.history);
  };

  return (
    <div className={props.classes.wrapper}>
      <div className={props.classes.addressSp}>
        <MuiTextField
          label="メールアドレス"
          placeholder="メールアドレス"
          type="email"
          onChange={handleChange}
          error={Boolean(accountIdError.length)}
          helperText={accountIdError}
          size="long"
        />
      </div>
      <div className={props.classes.submitButtonWrapper}>
        {accountId ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
            className={props.classes.submitButton}
          >
            パスワード再設定メールを送信する
          </Button>
        ) : (
          <div className={props.classes.disableButton}>
            パスワード再設定メールを送信する
          </div>
        )}
      </div>
      <div className={props.classes.linkWrapper}>
        <Link to={LOGIN} className={props.classes.link}>
          <Button className={props.classes.linkButton}>
            ログイン画面に戻る
          </Button>
        </Link>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, authDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const authDispatches = authDispatch(dispatch);
  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    postReminder: (value: string, history: H.History): Promise<void> =>
      authDispatches.postReminder(value, history)
  };
};

export const ReminderForm = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ReminderFormCore));
