import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  FormLabel
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikTime from "@components/molecules/FormikTime";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v202104/mgr/KODOENGO/Users/initial";
import { FacilityState } from "@stores/v202104/domain/mgr/KODOENGO/facility/types";
import { UnitsFields } from "@components/v202104/organisms/mgr/KODOENGO/Users/dialog/UnitsFields";
import {
  MAX_STANDARD_TIME_VALUE,
  MAX_TIME_VALUE,
  KODOENGO_MEMBER_LIST,
  KODOENGO_MEMBER_SELECT_LIST,
  STANDARD_TIME_VALUE,
  IN_TIME_VALIDATION_LIST,
  OUT_TIME_VALIDATION_LIST
} from "@constants/mgr/KODOENGO/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";
import { calculateHours } from "@utils/domain/mgr/calculateHours";
import { calculateOverlapTime } from "@utils/domain/mgr/calculateOverlapTime";
import { calculateTotalTime } from "@utils/domain/mgr/calculateTotalTime";
import { formatTime } from "@utils/date/formatTime";
import { isWithinRange } from "@utils/domain/mgr/isWithinRange";

const styles = (): StyleRules =>
  createStyles({
    categoryGroup: {
      paddingLeft: 32
    },
    categoryGroupBorder: {
      borderTop: "solid 1px #cfd8dc"
    },
    categoryLabel: {
      paddingTop: 24,
      paddingBottom: 24
    },
    calculatedHours: {
      "& > label": {
        width: 180
      }
    },
    disabledColor: {
      // disabledの色を上書き
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      },
      "& > div": {
        "& > input": {
          color: "rgba(0, 0, 0, 0.87)!important"
        },
        "& > div": {
          "& > p": {
            color: "rgba(0, 0, 0, 0.87)!important"
          }
        }
      }
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  isPractitioner2: boolean;
  practitioner1InTime: string;
  practitioner1OutTime: string;
  practitioner1DetailTimeList: DetailTimeInfo[];
  isAddButton1: boolean;
  practitioner2InTime: string;
  practitioner2OutTime: string;
  practitioner2DetailTimeList: DetailTimeInfo[];
  isAddButton2: boolean;
  standardValue: number;
  licenseSameFlg: boolean;
};

type DetailTimeInfo = { time: number; inTime: string; outTime: string };

type Props = StateProps & WithStyles<typeof styles>;

class CarePlanDialogFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { initial } = this.props.formikPropsValues.initialValues;

    this.state = {
      isPractitioner2:
        initial.numberOfParticipants === KODOENGO_MEMBER_LIST.TWO.value,
      practitioner1InTime: initial.practitioner1InTime,
      practitioner1OutTime: initial.practitioner1OutTime,
      practitioner1DetailTimeList: initial.carePlanDetails1.map((row) => {
        return { time: row.time, inTime: row.inTime, outTime: row.outTime };
      }),
      isAddButton1: initial.carePlanDetails1.length < 10,
      practitioner2InTime: initial.practitioner2InTime,
      practitioner2OutTime: initial.practitioner2OutTime,
      practitioner2DetailTimeList: initial.carePlanDetails2.map((row) => {
        return { time: row.time, inTime: row.inTime, outTime: row.outTime };
      }),
      isAddButton2: initial.carePlanDetails2.length < 10,
      standardValue: STANDARD_TIME_VALUE,
      licenseSameFlg: initial.licenseSameFlg
    };
  }

  private getInitDetailTime = (): DetailTimeInfo => {
    return {
      time: 0,
      inTime: "",
      outTime: ""
    };
  };

  private onAddDetailRecord1 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner1DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner1DetailTimeList: list,
        isAddButton1: list.length < 10
      };
    });
  };

  private onAddDetailRecord2 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner2DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner2DetailTimeList: list,
        isAddButton2: list.length < 10
      };
    });
  };

  private onDeleteDetailRecord1 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner1DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner1DetailTimeList: list, isAddButton1: true };
      },
      () => {
        this.setPersonalCalculatedHours1();
        this.setAllCalculatedHours();
      }
    );
  };

  private onDeleteDetailRecord2 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner2DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner2DetailTimeList: list, isAddButton2: true };
      },
      () => {
        this.setPersonalCalculatedHours2();
        this.setAllCalculatedHours();
      }
    );
  };

  private onChangeDetailRecord1 = (
    index: number,
    start: string,
    end: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner1DetailTimeList: prevState.practitioner1DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(startTime, "0", endTime, "0"),
              inTime: startTime,
              outTime: endTime
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours1(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1DetailTimeList
        );
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1DetailTimeList
        );
      }
    );
  };

  private onChangeDetailRecord2 = (
    index: number,
    start: string,
    end: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner2DetailTimeList: prevState.practitioner2DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(startTime, "0", endTime, "0"),
              inTime: startTime,
              outTime: endTime
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours2(
          this.state.practitioner2InTime,
          this.state.practitioner2OutTime,
          this.state.practitioner2DetailTimeList
        );
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1DetailTimeList,
          this.state.practitioner2InTime,
          this.state.practitioner2OutTime,
          this.state.practitioner2DetailTimeList
        );
      }
    );
  };

  private setPersonalCalculatedHours1 = (
    inTime: string = this.state.practitioner1InTime,
    outTime: string = this.state.practitioner1OutTime,
    list: DetailTimeInfo[] = this.state.practitioner1DetailTimeList,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    this.setCalculatedHours(
      "initial.practitioner1CalculatedHours",
      inTime,
      outTime,
      list,
      nextStandardValue
    );
  };

  private setPersonalCalculatedHours2 = (
    inTime: string = this.state.practitioner2InTime,
    outTime: string = this.state.practitioner2OutTime,
    list: DetailTimeInfo[] = this.state.practitioner2DetailTimeList,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    this.setCalculatedHours(
      "initial.practitioner2CalculatedHours",
      inTime,
      outTime,
      list,
      nextStandardValue
    );
  };

  private setCalculatedHours = (
    target: string,
    inTime: string,
    outTime: string,
    otherTimeList: DetailTimeInfo[],
    nextStandardValue: number = this.state.standardValue
  ): void => {
    const baseTime = calculateForRequiredTime(inTime, "0", outTime, "0");
    const otherTime = otherTimeList.reduce((sum, value) => {
      return sum + value.time;
    }, 0);
    let result = calculateHours(
      baseTime,
      nextStandardValue,
      otherTime,
      STANDARD_TIME_VALUE
    );
    if (result > MAX_STANDARD_TIME_VALUE) {
      result = MAX_TIME_VALUE;
    }
    this.props.setFormikFieldValue(target, Number.isNaN(result) ? "" : result);
  };

  private handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    let start1 = this.state.practitioner1InTime;
    let end1 = this.state.practitioner1OutTime;
    let start2 = this.state.practitioner2InTime;
    let end2 = this.state.practitioner2OutTime;
    switch (event.target.name) {
      case "initial.practitioner1InTime":
        start1 = formatTime(event.target.value);
        this.setState({ practitioner1InTime: start1 });
        this.setPersonalCalculatedHours1(start1, end1);
        break;
      case "initial.practitioner1OutTime":
        end1 = formatTime(event.target.value);
        this.setState({ practitioner1OutTime: end1 });
        this.setPersonalCalculatedHours1(start1, end1);
        break;
      case "initial.practitioner2InTime":
        start2 = formatTime(event.target.value);
        this.setState({ practitioner2InTime: start2 });
        this.setPersonalCalculatedHours2(start2, end2);
        break;
      case "initial.practitioner2OutTime":
        end2 = formatTime(event.target.value);
        this.setState({ practitioner2OutTime: end2 });
        this.setPersonalCalculatedHours2(start2, end2);
        break;
      default:
    }

    // 合算算定（全体/重複）
    this.setAllCalculatedHours(
      start1,
      end1,
      this.state.practitioner1DetailTimeList,
      start2,
      end2,
      this.state.practitioner2DetailTimeList
    );
  };

  private setAllCalculatedHours = (
    baseInTime1 = this.state.practitioner1InTime,
    baseOutTime1 = this.state.practitioner1OutTime,
    baseDetail1 = this.state.practitioner1DetailTimeList,
    baseInTime2 = this.state.practitioner2InTime,
    baseOutTime2 = this.state.practitioner2OutTime,
    baseDetail2 = this.state.practitioner2DetailTimeList,
    sameFlg = this.state.licenseSameFlg,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    const detail1 = baseDetail1.map((record) => {
      return { inTime: record.inTime, outTime: record.outTime };
    });

    // 同時実行ONの場合、提供者②は提供者①のコピーとする
    const inTime2 = !sameFlg ? baseInTime2 : baseInTime1;
    const outTime2 = !sameFlg ? baseOutTime2 : baseOutTime1;
    const detail2 = !sameFlg ? baseDetail2 : baseDetail1;

    // 提供時間の計算
    const baseData = this.calcAllCalculateTime([
      { inTime: baseInTime1, outTime: baseOutTime1 },
      { inTime: inTime2, outTime: outTime2 }
    ]);
    // 空き時間の計算
    // （・全体 = 全体の提供時間 - 明細1 - 明細3）
    // （・重複 = 重複の提供時間 - 明細2）
    // 1.提供時間を重複範囲とそれ以外に分割
    const data = calculateOverlapTime(
      { inTime: baseInTime1, outTime: baseOutTime1 },
      [{ inTime: inTime2, outTime: outTime2 }]
    );

    // 2.空き時間を、重複範囲内か外かでグループ分け（重複の境を跨ぐ場合は、分割）
    const detail =
      data && data.length > 0
        ? this.calcDetailCalculateTime(data[0], detail1.concat(detail2))
        : {
            total: [{ inTime: "", outTime: "" }],
            duplicate: [{ inTime: "", outTime: "" }]
          };
    // 3.重複範囲外の空き時間を計算（→明細1)
    const subtractedTotalData = this.calcAllCalculateTime(
      detail.total.slice(1)
    );
    // 4.重複範囲内の空き時間（全体・重複）を計算（→明細2・明細3)
    const subtractedDuplicateData = this.calcAllCalculateTime(
      detail.duplicate.slice(1)
    );

    let wholeData = calculateHours(
      baseData.whole,
      nextStandardValue,
      subtractedTotalData.whole + subtractedDuplicateData.duplicate,
      STANDARD_TIME_VALUE
    );
    if (wholeData > MAX_STANDARD_TIME_VALUE) {
      wholeData = MAX_TIME_VALUE;
    }

    let duplicateData = sameFlg
      ? wholeData
      : calculateHours(
          baseData.duplicate,
          nextStandardValue,
          subtractedDuplicateData.whole,
          STANDARD_TIME_VALUE
        );
    if (duplicateData > MAX_STANDARD_TIME_VALUE) {
      duplicateData = MAX_TIME_VALUE;
    }

    // 算定時間（全体）のセット
    this.props.setFormikFieldValue(
      "initial.wholeCalculatedHours",
      Number.isNaN(wholeData) ? "" : wholeData
    );
    // 算定時間（重複）のセット
    this.props.setFormikFieldValue(
      "initial.duplicateCalculatedHours",
      Number.isNaN(duplicateData) ? "" : duplicateData
    );
  };

  private calcAllCalculateTime = (
    list: { inTime: string; outTime: string }[]
  ): { whole: number; duplicate: number } => {
    let totalList = list.slice();
    let target = totalList.shift();
    if (!target) {
      return { whole: 0, duplicate: 0 };
    }

    // [全体]の時間を算出
    let whole = 0;
    while (target) {
      const data = calculateTotalTime(target, totalList);
      whole += calculateForRequiredTime(
        data.result.inTime,
        "0",
        data.result.outTime,
        "0"
      );

      target = data.targetList.shift();
      totalList = data.targetList;
    }

    // [重複]の時間を算出
    // 1. 重複対象のデータ取得
    // 2. 1次元配列に変換
    // 3. 重複削除
    // 4. 算定用の時間計算
    let duplicate = 0;
    Array.from(
      new Map(
        list
          .map((data, idx) =>
            calculateOverlapTime(
              data,
              list.filter((record, i) => i !== idx)
            )
          )
          .reduce((pre, current) => pre.concat(current), [])
          .map((data) => [`${data.inTime}${data.outTime}`, data])
      ).values()
    ).forEach((record) => {
      duplicate += calculateForRequiredTime(
        record.inTime,
        "0",
        record.outTime,
        "0"
      );
    });

    return { whole, duplicate };
  };

  private calcDetailCalculateTime = (
    range: { inTime: string; outTime: string },
    list: { inTime: string; outTime: string }[]
  ): {
    total: { inTime: string; outTime: string }[];
    duplicate: { inTime: string; outTime: string }[];
  } => {
    return list.reduce(
      (prev, record) => {
        const start1 = Number(range.inTime.split(":").join(""));
        const start2 = Number(record.inTime.split(":").join(""));
        const end1 = Number(range.outTime.split(":").join(""));
        const end2 = Number(record.outTime.split(":").join(""));

        const start =
          isWithinRange(range.inTime, range.outTime, record.inTime) ||
          start1 === start2;
        const end =
          isWithinRange(range.inTime, range.outTime, record.outTime) ||
          end1 === end2;
        const outRangeFlg = !isWithinRange(
          record.inTime,
          record.outTime,
          range.inTime
        );

        // ケース1（重複範囲内）
        if (start && end) {
          return {
            total: prev.total,
            duplicate: prev.duplicate.concat(record)
          };
        }

        // ケース2（重複範囲Fromをまたぐ）
        if (!start && end) {
          return {
            total: prev.total.concat({
              inTime: record.inTime,
              outTime: range.inTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: range.inTime,
              outTime: record.outTime
            })
          };
        }

        // ケース3（重複範囲toをまたぐ）
        if (start && !end) {
          return {
            total: prev.total.concat({
              inTime: range.outTime,
              outTime: record.outTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: record.inTime,
              outTime: range.outTime
            })
          };
        }

        // ケース4（重複範囲外）
        if (!start && !end && outRangeFlg) {
          return {
            total: prev.total.concat(record),
            duplicate: prev.duplicate
          };
        }

        // ケース5（重複範囲全体をまたぐ）
        return {
          total: prev.total.concat([
            { inTime: record.inTime, outTime: range.inTime },
            { inTime: range.outTime, outTime: record.outTime }
          ]),
          duplicate: prev.duplicate.concat(range)
        };
      },
      {
        total: [{ inTime: "", outTime: "" }],
        duplicate: [{ inTime: "", outTime: "" }]
      }
    );
  };

  private onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.setFormikFieldValue(e.target.name, e.target.checked);
  };

  private onChangeLicenseSameFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ licenseSameFlg: e.target.checked });

    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2OutTime,
      this.state.practitioner2DetailTimeList,
      e.target.checked
    );
  };

  private handleChangeNumberOfParticipants = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      isPractitioner2: e.target.value === KODOENGO_MEMBER_LIST.TWO.value
    });
    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours();
  };

  public render(): JSX.Element {
    return (
      <FormGroup>
        <FormGroup className={this.props.classes.categoryGroup}>
          <FormikSelect
            name="initial.numberOfParticipants"
            label="提供人数"
            size="smallMedium"
            options={KODOENGO_MEMBER_SELECT_LIST}
            onChangeHook={this.handleChangeNumberOfParticipants}
          />
          <FormikCheckbox
            name="initial.licenseSameFlg"
            label="2人同時にサービスを提供する"
            style={{ marginBottom: 0 }}
            disabled={!this.state.isPractitioner2}
            onChange={this.onChangeLicenseSameFlg}
          />
          <FormikCheckbox
            name="initial.secondPersonFlg"
            label="他事業所とともにサービスを提供する"
            style={{ marginBottom: 0 }}
            disabled={this.state.isPractitioner2}
          />
        </FormGroup>

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            {this.state.isPractitioner2 && !this.state.licenseSameFlg && "①"}
            サービス提供時間
          </FormLabel>
          <FormGroup row>
            <FormikTime
              name="initial.practitioner1InTime"
              label="開始時間"
              required
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={this.handleChangeTime}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1InTime
                  ? IN_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1InTime
                    )
                  : false
              }
            />
            <FormikTime
              name="initial.practitioner1OutTime"
              label="終了時間"
              required
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={this.handleChangeTime}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1OutTime
                    )
                  : false
              }
            />
          </FormGroup>
          <FormGroup style={{ marginBottom: 20 }}>
            <UnitsFields
              formikProps={this.props.formikPropsValues}
              unitKey="carePlanDetails1"
              onAddRecord={this.onAddDetailRecord1}
              onDeleteRecord={this.onDeleteDetailRecord1}
              onChangeTime={this.onChangeDetailRecord1}
              isAddButton={this.state.isAddButton1}
              list={this.state.practitioner1DetailTimeList}
            />
          </FormGroup>
          <FormikTextField
            name="initial.practitioner1Memo"
            label="備考"
            size="quarterSuperLong"
          />
        </FormGroup>

        {this.state.isPractitioner2 && !this.state.licenseSameFlg && (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              ②サービス提供時間
            </FormLabel>
            <FormGroup row>
              {!this.state.licenseSameFlg && (
                <FormikTime
                  name="initial.practitioner2InTime"
                  label="開始時間"
                  required
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  onChangeHookTime={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2InTime
                      ? IN_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2InTime
                        )
                      : false
                  }
                />
              )}
              {!this.state.licenseSameFlg && (
                <FormikTime
                  name="initial.practitioner2OutTime"
                  label="終了時間"
                  required
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  onChangeHookTime={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2OutTime
                      ? OUT_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2OutTime
                        )
                      : false
                  }
                />
              )}
            </FormGroup>
            {!this.state.licenseSameFlg && (
              <FormGroup style={{ marginBottom: 20 }}>
                <UnitsFields
                  formikProps={this.props.formikPropsValues}
                  unitKey="carePlanDetails2"
                  onAddRecord={this.onAddDetailRecord2}
                  onDeleteRecord={this.onDeleteDetailRecord2}
                  onChangeTime={this.onChangeDetailRecord2}
                  isAddButton={this.state.isAddButton2}
                  list={this.state.practitioner2DetailTimeList}
                />
              </FormGroup>
            )}
            {!this.state.licenseSameFlg && (
              <FormikTextField
                name="initial.practitioner2Memo"
                label="備考"
                size="quarterSuperLong"
              />
            )}
          </FormGroup>
        )}

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            算定時間
          </FormLabel>
          <FormGroup row>
            {!this.state.isPractitioner2 && (
              <FormikTextField
                className={this.props.classes.disabledColor}
                name="initial.practitioner1CalculatedHours"
                label="算定時間"
                size="smallMedium"
                endAdornmentLabel="時間"
                disabled
              />
            )}
          </FormGroup>
          <FormGroup row>
            {this.state.isPractitioner2 && (
              <FormikTextField
                className={this.props.classes.disabledColor}
                name="initial.wholeCalculatedHours"
                label="全体の算定時間"
                size="smallMedium"
                endAdornmentLabel="時間"
                disabled
              />
            )}
            {this.state.isPractitioner2 && (
              <FormikTextField
                className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                name="initial.duplicateCalculatedHours"
                label="2人が重複する算定時間"
                size="smallMedium"
                endAdornmentLabel="時間"
                disabled
              />
            )}
          </FormGroup>
        </FormGroup>

        {this.props.facility.specificFacilitiesAddition !== "1" && (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              その他
            </FormLabel>

            <FormikCheckbox
              name="initial.sputumImplementationFlg"
              label="喀痰吸引等実施"
              style={{ marginBottom: 0 }}
            />
          </FormGroup>
        )}
      </FormGroup>
    );
  }
}

export const CarePlanDialogFields = withStyles(styles)(
  CarePlanDialogFieldsCore
);
