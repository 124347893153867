import * as React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  STICKY_BOX_SHADOW,
  STICKY_NONE_BOX_SHADOW,
  DIVIDER_UNSET_BG,
  DIVIDER_BG_COLOR
} from "@constants/styles";
import { CHIIKITEICHAKU_PLAN_LIST_TAB_PATH } from "@constants/mgr/CHIIKITEICHAKU/variables";
import * as URL from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    tabWrapper: {
      position: "sticky",
      backgroundColor: "#fff"
    },
    stickyWrapper: {
      position: "sticky",
      zIndex: 10,
      top: 16
    },
    header: {
      backgroundColor: "#fff",
      padding: "32px 32px 8px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between"
    },
    title: {
      lineHeight: 1,
      "& > span": {
        marginLeft: 16
      }
    },
    divider: {
      position: "relative",
      margin: "0 32px -2px"
    }
  });
type PlanListTabPath = typeof CHIIKITEICHAKU_PLAN_LIST_TAB_PATH.SUPPORT_LEDGER;

type OwnProps = {
  uifId: string;
  recordType: PlanListTabPath;
  isEditing: boolean;
  history: H.History;
  pageName: string;
  userName: string;
  button: JSX.Element | JSX.Element[];
  displayDate?: string;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * タブとタイトルヘッダーを表示する役割
 */
const SupportLedgerListHeaderCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const scrollYValue = 335;
  const [stickyFlg, setStickyFlg] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    const listenScrollEvent = (): void => {
      if (unmounted) return;
      if (window.scrollY > scrollYValue) {
        setStickyFlg(true);
      } else {
        setStickyFlg(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      unmounted = true;
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleTabChange = (e: React.ChangeEvent<{}>, value: string): void => {
    if (value === "support_report" || value === "support") {
      props.history.push(`${URL.RECORD}/${props.uifId}/${value}`);
      return;
    }
    props.history.push(`${URL.RECORD_SUPPORT_PLAN}/${props.uifId}/${value}`);
  };

  const recordInfo = [
    { label: "アセスメント", value: "assessment" },
    {
      label: "地域定着支援台帳",
      value: "support_ledger"
    },
    { label: "支援記録", value: "support" }
  ];

  return (
    <div
      className={classes.stickyWrapper}
      style={
        stickyFlg
          ? { boxShadow: STICKY_BOX_SHADOW }
          : { boxShadow: STICKY_NONE_BOX_SHADOW }
      }
    >
      <div className={classes.tabWrapper}>
        <KnowbeTabs
          key="recordTabs"
          tabInfo={recordInfo}
          value={props.recordType}
          disabled={props.isEditing}
          onChange={handleTabChange}
        />
      </div>
      <>
        <div className={classes.header}>
          <Typography variant="h6" color="primary" className={classes.title}>
            {props.pageName}
            <span>{props.userName}</span>
            {props.displayDate && <span>{props.displayDate}</span>}
          </Typography>
          <div>{props.button}</div>
        </div>
        <Divider
          className={classes.divider}
          style={
            stickyFlg
              ? { backgroundColor: DIVIDER_UNSET_BG }
              : { backgroundColor: DIVIDER_BG_COLOR }
          }
        />
      </>
    </div>
  );
};
export const SupportLedgerListHeader = withStyles(styles)(
  SupportLedgerListHeaderCore
);
