import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import * as format from "date-fns/format";

const styles = (): StyleRules =>
  createStyles({
    dateLabel: {
      display: "flex",
      justifyContent: "space-between",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 12,
      width: 185
    },
    dateLabelWithOutTime: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 12,
      "&>span:last-child": {
        marginLeft: "8px"
      }
    }
  });

type OwnProps = {
  createdAt?: string | number | Date | null;
  updatedAt?: string | number | Date | null;
  withOutTime?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 作成日と更新日を表示する
 */
const CreateAndUpdateDateCore = (props: Props): JSX.Element => {
  const formatData = props.withOutTime
    ? "YYYY年 MM月 DD日"
    : "YYYY年MM月DD日 HH:mm";
  const emptyData = props.withOutTime ? "-年 -月 -日" : "-年 -月 -日 --:--";
  return (
    <div>
      <div
        className={
          props.withOutTime
            ? props.classes.dateLabelWithOutTime
            : props.classes.dateLabel
        }
      >
        <span>作成日</span>
        <span>
          {props.createdAt ? format(props.createdAt, formatData) : emptyData}
        </span>
      </div>
      <div
        className={
          props.withOutTime
            ? props.classes.dateLabelWithOutTime
            : props.classes.dateLabel
        }
      >
        <span>更新日</span>
        <span>
          {props.updatedAt ? format(props.updatedAt, formatData) : emptyData}
        </span>
      </div>
    </div>
  );
};

export const CreateAndUpdateDate = withStyles(styles)(CreateAndUpdateDateCore);
