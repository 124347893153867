import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

const styles: StyleRules = createStyles({
  wrapper: {
    padding: 8,
    backgroundColor: "#eceff1",
    borderRadius: 4,
    lineHeight: 1
  },
  title: {
    fontSize: 13,
    color: "#37474F"
  },
  content: {
    marginTop: 12,
    display: "flex",
    justifyContent: "space-between",
    gap: "8px",
    color: "#666"
  },
  item: {
    display: "flex",
    alignItems: "baseline"
  },
  num: {
    fontSize: 24
  },
  hasError: {
    color: "#f44336"
  },
  unit: {
    fontSize: 14
  }
});

type RectangleBoxProportion = {
  num: number;
  denom: number;
  unit: string;
};

type OwnProps = {
  title: string;
  main?: RectangleBoxProportion;
  // 2つ目が必要な場合に
  sub?: RectangleBoxProportion;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 主に累計などの表示に使うボックス
 * denom(分母)が渡された時、numがdenomを超えていたらエラーの色で表示する
 */
const RectangleBoxCore = ({
  classes,
  title,
  main,
  sub
}: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div className={classes.title}>{title}</div>
    <div className={classes.content}>
      {main ? (
        <div className={classes.item}>
          <div
            className={ClassNames(classes.num, {
              [classes.hasError]: main.denom && main.num > main.denom
            })}
          >
            {main.num}
          </div>
          <span className={classes.unit}>
            {main.denom && `/${main.denom}`}
            {main.unit}
          </span>
        </div>
      ) : null}

      {sub ? (
        <div className={classes.item}>
          <div
            className={ClassNames(classes.num, {
              [classes.hasError]: sub.denom && sub.num > sub.denom
            })}
          >
            {sub.num}
          </div>
          <span className={classes.unit}>
            {sub.denom && `/${sub.denom}`}
            {sub.unit}
          </span>
        </div>
      ) : null}
    </div>
  </div>
);

export const RectangleBox = withStyles(styles)(RectangleBoxCore);
