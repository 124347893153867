import * as React from "react";
import ReactPaginate from "react-paginate";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px"
    },
    label: {
      color: "rgba(0,0,0,0.6)"
    },
    pageContainer: {
      display: "flex",
      paddingLeft: 0,
      listStyle: "none"
    },
    activeLink: {
      zIndex: 3,
      color: "#333 !important",
      cursor: "default!important"
    },
    page: {
      textAlign: "center"
    },
    pageLink: {
      position: "relative",
      display: "block",
      color: "#0277bd",
      textDecoration: "none",
      padding: "6px 6px",
      cursor: "pointer",
      width: "32px"
    },
    disabledPageLink: {
      cursor: "default!important",
      "&::before": {
        borderColor: "rgba(0,0,0,0.38)!important"
      }
    },
    previousLink: {
      position: "relative",
      paddingLeft: "18px",
      userSelect: "none",
      width: "auto",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "10px",
        height: "10px",
        transform: "rotate(-45deg)",
        borderTop: "solid 3px #0277bd",
        borderLeft: "solid 3px #0277bd",
        left: 0,
        top: "32%"
      }
    },
    nextLink: {
      position: "relative",
      paddingRight: "18px",
      width: "auto",
      userSelect: "none",
      "&::before": {
        content: "''",
        position: "absolute",
        width: "10px",
        height: "10px",
        transform: "rotate(45deg)",
        borderTop: "solid 3px #0277bd",
        borderRight: "solid 3px #0277bd",
        right: 0,
        top: "32%"
      }
    },
    dottedATag: {
      pointerEvents: "none",
      textDecoration: "none",
      width: "32px",
      padding: "6px 6px"
    },
    dottedLiTag: {
      textAlign: "center",
      display: "flex",
      alignItems: "center"
    },
    disabled: {
      "& *": {
        color: "rgba(0,0,0,0.38)"
      }
    }
  });

type PageChangeProps = {
  selected: number;
};

type OwnProps = {
  dataLength: number;
  page: number;
  hideCountDisplay?: boolean;
  // クリック時のfunction
  handlePageChange: (params: PageChangeProps) => void;
  perPage?: number;
  className?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PaginationCore = ({
  dataLength,
  page,
  hideCountDisplay = false,
  handlePageChange,
  perPage = 20,
  className = "",
  classes
}: Props): JSX.Element | null => {
  const [pageRangeDisplayed, setPageRangeDisplayed] = React.useState(7);
  const [pageCount, setPageCount] = React.useState(0);

  React.useEffect(() => {
    setPageCount(Math.ceil(dataLength / perPage));
  }, [perPage, dataLength]);

  // ページネーションパーツは常に9セル表示する形を取っているが
  // useEffectにすると、途中で9セル前後になる瞬間がある（ちらついてしまう）
  React.useLayoutEffect(() => {
    if (
      page === 1 ||
      pageCount < 10 ||
      (pageCount > 9 && page >= pageCount - 3)
    ) {
      setPageRangeDisplayed(7);
    } else if (page < 5) {
      setPageRangeDisplayed(6);
    } else {
      setPageRangeDisplayed(5);
    }
  }, [page, pageCount]);

  // warning回避：初期状態では、pageがpageCountを上回る
  return page <= pageCount ? (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.label}>
        {hideCountDisplay
          ? ""
          : `
          ${(page - 1) * perPage + 1} 〜 ${
              pageCount === page ? dataLength : page * perPage
            }
          人目を表示中 / ${dataLength}人
          `}
      </div>
      {/* // ページネーションを置きたい箇所に以下のコンポーネントを配置 */}
      <ReactPaginate
        previousLabel="前へ"
        nextLabel="次へ"
        breakLabel="..."
        pageCount={pageCount} // 全部のページ数。端数の場合も考えて切り上げに。
        marginPagesDisplayed={1} // 一番最初と最後を基準にして、そこからいくつページ数を表示するか
        pageRangeDisplayed={pageRangeDisplayed} // アクティブなページを基準にして、そこからいくつページ数を表示するか
        onPageChange={handlePageChange} // クリック時のfunction
        containerClassName={classes.pageContainer} // ページネーションであるulに着くクラス名
        pageClassName={classes.page}
        pageLinkClassName={classes.pageLink}
        disabledLinkClassName={classes.disabledPageLink}
        activeClassName={classes.active} // アクティブなページのliに着くクラス名
        activeLinkClassName={classes.activeLink}
        previousClassName="" // 「<」のliに着けるクラス名
        previousLinkClassName={`${classes.pageLink} ${classes.previousLink}`}
        nextClassName="" // 「>」のliに着けるクラス名
        nextLinkClassName={`${classes.pageLink} ${classes.nextLink}`}
        disabledClassName={classes.disabled}
        breakClassName={classes.dottedLiTag}
        breakLinkClassName={classes.dottedATag}
        forcePage={page - 1}
      />
    </div>
  ) : null;
};

export const Pagination = withStyles(styles)(PaginationCore);
