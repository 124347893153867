import * as types from "./types";
import * as actions from "./actions";

type targetUnitState = number;

const initialState: targetUnitState = 0;

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): targetUnitState => {
  switch (action.type) {
    case types.SET_TARGET_UNIT:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
