import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";

const styles = (): StyleRules =>
  createStyles({
    calendar: {
      display: "flex",
      justifyContent: "flex-start",
      overflow: "hidden",
      position: "relative",
      "&::before": {
        content: "'　'",
        display: "block",
        width: "100%",
        height: "100%",
        border: "2px solid #212121",
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 10
      },
      "& .fc": {
        width: "calc(100% - 212px)"
      },
      "& .fc-scrollgrid>thead": {
        "@media print": {
          display: "table-caption",
          transform: "translateY(1px) translateX(1px)"
        }
      },
      "& .fc-timegrid-body": {
        overflow: "hidden"
      },
      "& table.fc-scrollgrid": {
        pointerEvents: "none",
        borderColor: "#212121"
      },
      "& .fc-col-header-cell-cushion": {
        display: "block",
        padding: 0
      },
      "& th.fc-col-header-cell": {
        fontSize: 10,
        lineHeight: 1.4,
        letterSpacing: "0.5px",
        padding: "3px 0",
        color: " #212121",
        fontWeight: "normal"
      },
      "& .fc-theme-standard th , & .fc-theme-standard td": {
        borderColor: "#212121"
      },
      "& td.fc-timegrid-slot-lane": {
        height: 24,
        borderTopStyle: "dotted",
        borderTopColor: "#979797"
      },
      "& .fc-timegrid-slot-label": { border: "none" },
      "& .fc-timegrid-slot-label-frame": {
        position: "relative",
        height: "100%"
      },
      "& .fc-timegrid-slot-label-frame::before": {
        content: "' '",
        display: "block",
        position: "absolute",
        width: 4,
        height: 2,
        background: "#212121",
        top: -1,
        right: 0
      },
      "& .fc-timegrid-slot-label-cushion": {
        fontSize: 10,
        lineHeight: 1,
        letterSpacing: "0.5px",
        transform: "scale(0.8)",
        transformOrigin: "right",
        position: "absolute",
        top: 2,
        right: 9,
        padding: 0
      },
      "& .fc-timegrid-event": {
        borderWidth: "1px",
        "& .fc-event-main": {
          padding: "2px 1px"
        }
      },
      "& .fc-event-title-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },
      "& .fc-event-title": {
        fontSize: 10,
        lineHeight: 1,
        fontWeight: "bold",
        transform: "scale(0.7)",
        transformOrigin: "left",
        width: "calc(100% / 0.7)",
        overflow: "hidden",
        textAlign: "center",
        wordBreak: "break-all",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical"
      },
      "& .line-clamp--1 .fc-event-title": {
        "-webkit-line-clamp": 1
      },
      "& .line-clamp--2 .fc-event-title": {
        "-webkit-line-clamp": 2
      },
      "& .line-clamp--3 .fc-event-title": {
        "-webkit-line-clamp": 3
      },
      "& .line-clamp--4 .fc-event-title": {
        "-webkit-line-clamp": 4
      },
      "& .fc-timegrid-event.fc-event-start": {
        borderBottom: "none",
        height: "calc(100% + 1px)",
        position: "relative",
        "&::after": {
          display: "block",
          content: "' '",
          height: "1890px",
          background: "rgb(245, 245, 245)",
          width: "calc(100% + 2px)",
          transform: "translateX(-1px)",
          borderColor: "#212121",
          borderStyle: "solid",
          borderWidth: "0 1px 0 1px",
          boxShadow: "0px 1px 0px 1px #fff",
          "@media print": {
            height: "357px",
            borderColor: "#212121"
          }
        }
      },
      "& .fc-timegrid-event.fc-event-end": {
        borderTop: "none"
      },
      "& .fc-timegrid-event.fc-event-start.fc-event-end": {
        border: "1px solid #212121",
        "&::after": {
          display: "none"
        }
      },
      "& colgroup col": {
        width: "42px!important"
      }
    },
    calendarText: {
      position: "absolute",
      top: 4,
      left: 10,
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "#212121"
    },
    comment: {
      width: "calc(100% - 835px)",
      border: "1px solid #212121",
      borderLeft: "none",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: " #212121",
      fontWeight: "normal"
    },
    mainActivityTitle: {
      padding: "3px 0",
      display: "block",
      textAlign: "center",
      borderBottom: "1px solid #212121"
    },
    nonWeeklyServiceTitle: {
      padding: "3px 0",
      display: "block",
      textAlign: "center",
      borderTop: "1px solid #212121",
      borderBottom: "1px solid #212121"
    },
    commentCon: {
      padding: 4,
      wordBreak: "break-word"
    },
    title: {
      fontFamily: ["HiraginoSans-W6", "sans-serif"].join(","),
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      paddingBottom: 4,
      borderBottom: "1px solid #000",
      marginBottom: 0
    },
    MT16: {
      marginTop: 16
    },
    MT32: {
      marginTop: 32
    }
  });

type PrintSchedulesItem = {
  title: string;
  start: string;
  end: string;
};

type Schedule = BasicInfoState["basicInfo"]["basic_info"]["schedule"];

type OwnProps = {
  basicInfo: BasicInfoState["basicInfo"]["basic_info"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

const PRINT_CALENDAR_DATE = "2018-01-0";

export const printSchedules = (defaultVale: Schedule): PrintSchedulesItem[] => {
  const baseDay = PRINT_CALENDAR_DATE;
  const res: PrintSchedulesItem[] = [];

  defaultVale.forEach((item) => {
    item.day_of_week.forEach((w) => {
      if (item.start_time !== "" && item.end_time !== "") {
        const resItem = { title: "", start: "", end: "" };
        const name = item.content;
        const start = item.start_time;
        const end = item.end_time;
        let dayStart;
        let dayEnd;

        if (item.next_day_flg === 1) {
          dayStart = baseDay + w;
          dayEnd = baseDay + Number(Number(w) + 1);
        } else {
          dayStart = baseDay + w;
          dayEnd = baseDay + w;
        }

        resItem.title = name;
        resItem.start = `${dayStart}T${start}`;
        resItem.end = `${dayEnd}T${end}`;

        res.push(resItem);
      }
    });
  });

  return res;
};

const BasicInfoPrintSchedulesCore = (props: Props): JSX.Element => {
  const { classes, basicInfo } = props;

  // canlendar用データ整理
  const printSchedulesData = printSchedules(basicInfo.schedule);

  // canlendarの時間軸用
  const [minTime, setMinTime] = React.useState<string>("00:00:00");
  const [maxTime, setMaxTime] = React.useState<string>("24:00:00");
  // canlendarの高さ用
  const [remarksWrapHeight, setRemarksWrapHeight] = React.useState<number>(0);
  const [canlendarHeight, setCanlendarHeight] = React.useState<number>(0);

  React.useEffect(() => {
    // 備考の高さ
    const remarksWrapper = document.getElementById("remarksWrapper");
    const remarksHeader = 23;
    if (remarksWrapper) {
      setRemarksWrapHeight(
        Math.floor(remarksWrapper.clientHeight + remarksHeader)
      );
    }

    const canlendar = { border: 1, head: 20, item: 24 };
    let minTimeValue;
    let maxTimeValue;

    if (printSchedulesData.length === 0) {
      minTimeValue = 0;
      maxTimeValue =
        Math.ceil(remarksWrapHeight / canlendar.item) > 24
          ? 24
          : Math.ceil(remarksWrapHeight / canlendar.item);
    } else {
      const timeData: number[] = [];
      basicInfo.schedule.forEach((item) => {
        if (item.start_time) {
          timeData.push(Number(item.start_time.split(":")[0]));
        }
        if (item.end_time) {
          timeData.push(Number(item.end_time.split(":")[0]));
        }
      });
      minTimeValue = Math.min(...timeData) - 2;
      maxTimeValue = Math.max(...timeData) + 2;
    }

    if (minTimeValue !== Infinity && maxTimeValue !== Infinity) {
      if (minTimeValue < 0) {
        setMinTime("00:00:00");
      } else if (minTimeValue > 0 && minTimeValue < 10) {
        setMinTime(`0${String(minTimeValue)}:00:00`);
      } else {
        setMinTime(`${String(minTimeValue)}:00:00`);
      }

      if (maxTimeValue > 24) {
        setMaxTime("24:00:00");
      } else if (maxTimeValue < 10) {
        setMaxTime(`0${String(maxTimeValue)}:00:00`);
      } else {
        setMaxTime(`${String(maxTimeValue)}:00:00`);
      }
    }

    setCanlendarHeight(
      Math.floor(
        canlendar.head +
          (maxTimeValue - minTimeValue) * (canlendar.item + canlendar.border) +
          canlendar.border * 2
      )
    );
  }, [basicInfo.schedule]);

  return (
    <div className={ClassNames(classes.calendar, classes.MT16)}>
      <div className={classes.calendarText}>時刻</div>
      <FullCalendar
        locale="ja"
        plugins={[timeGridPlugin]}
        contentHeight={
          remarksWrapHeight > canlendarHeight ? remarksWrapHeight : "auto"
        }
        headerToolbar={false}
        slotDuration="01:00:00"
        slotLabelInterval="02:00"
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        slotEventOverlap={false}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
          hour12: false
        }}
        dayHeaderFormat={(date): string => {
          const days = ["日", "月", "火", "水", "木", "金", "土"];
          return days[date.date.marker.getDay()];
        }}
        displayEventTime={false}
        eventBackgroundColor="#f5f5f5"
        eventColor="#212121"
        eventTextColor="#212121"
        eventBorderColor="#212121"
        firstDay={1}
        allDaySlot={false}
        initialDate={`${PRINT_CALENDAR_DATE}1`}
        navLinks={false}
        editable
        dayMaxEvents
        events={printSchedulesData}
        eventDidMount={(item): void => {
          // タイトルの３点リーダー
          const height = item.el.clientHeight;
          if (height < 24) {
            item.el.classList.add("line-clamp--1");
          } else if (height >= 24 && height < 34) {
            item.el.classList.add("line-clamp--2");
          } else if (height >= 34 && height < 74) {
            item.el.classList.add("line-clamp--3");
          } else {
            item.el.classList.add("line-clamp--4");
          }
        }}
      />

      <div className={classes.comment}>
        <span className={classes.mainActivityTitle}>主な日常生活上の活動</span>
        <div className={classes.commentCon} id="remarksWrapper">
          {basicInfo.main_activity}
        </div>
        <span className={classes.nonWeeklyServiceTitle}>
          週単位以外のサービス
        </span>
        <div className={classes.commentCon} id="remarksWrapper">
          {basicInfo.non_weekly_service}
        </div>
      </div>
    </div>
  );
};

export const BasicInfoPrintSchedules = withStyles(styles)(
  BasicInfoPrintSchedulesCore
);
