import { SelectDateValue } from "@interfaces/ui/form";

/**
 * SelectDateValue => string (YYYY-MM-dd)
 */
export const selectDateValueToDate = (value: SelectDateValue): string => {
  return value.year && value.month && value.day
    ? `${value.year}-${value.month}-${value.day}`
    : "";
};
