import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { FormikProps } from "formik";
import classNames from "classnames";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  FacilityType,
  CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME
} from "@constants/variables";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import { SupportLedgerCheckBox } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerCheckBox";
import { SupportLedgerRadio } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerRadio";
import { SupportLedgerHistory } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerHistory";
import { SupportLedgerRelativesInfo } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerRelativesInfo";
import { SupportLedgerRelatedOrganization } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerRelatedOrganization";

import {
  getIndexes,
  getName,
  getInput
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";
import { normalizeDisplayCustomRecord } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordNormalize";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    categoryType: {
      color: "#37474f",
      padding: "48px 0 5px 0",
      borderBottom: "1px solid #37474f"
    },
    itemInputs: {
      alignItems: "center",
      width: "100%",
      minHeight: 50,
      "&.-tel": {
        width: "240px"
      },
      "&.-email": {
        width: "800px"
      },
      "&.-body": {
        width: "114px"
      },
      "& textarea::-webkit-scrollbar": {
        display: "none"
      }
    },
    category: {
      width: "100%",
      height: 34,
      backgroundColor: "#f5f5f5",
      marginTop: 32,
      paddingLeft: 8,
      lineHeight: "34px"
    },
    noItem: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      margin: "8px 6px 24px 17px"
    }
  });

type OwnProps = {
  categoryType: number;
  formValues: SupportLedger;
  formikProps: FormikProps<SupportLedger>;
  isEditing: boolean;
  customRecords: CustomRecordsWithCategoryState;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerSheetFormCore = (props: Props): JSX.Element => {
  const {
    categoryType,
    formValues,
    formikProps,
    isEditing,
    customRecords,
    classes
  } = props;

  const hasCategoryName =
    categoryType ===
      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.user_condition ||
    categoryType === CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.family ||
    categoryType ===
      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.related_organizations ||
    categoryType === CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.notices;

  const sortedCustomRecords = normalizeDisplayCustomRecord(
    customRecords,
    formValues,
    categoryType
  );

  if (!sortedCustomRecords.length) {
    return <></>;
  }
  /**
   * カーソル外したとき、全角の数字及びピリオドを半角に変換する
   * 数値が1以上でかつ先頭が0のとき、最初の0以外の数字が表示されるまで先頭から0を省略する
   * ドットなしの形式の場合、ドットを自動で付ける（「身長」「体重」項目のみ）
   */
  const handleBlurDecimal = (defaultItem: number | null) => (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    const halfWidthValue = event.currentTarget.value.replace(
      /[０-９．]/g,
      (s) => String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );

    if (
      defaultItem === CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.height ||
      defaultItem === CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.weight
    ) {
      if (parseFloat(halfWidthValue) > 0 && !halfWidthValue.includes(".")) {
        return `${halfWidthValue.replace(/^(0+)([0-9]+)/g, "$2")}`;
      }
    }
    return halfWidthValue;
  };

  const itemsBodyInputs = (
    items: CustomRecordsWithCategoryState[number]["custom_record_items"]
  ): JSX.Element[] => {
    if (!items.length) {
      return [
        <div className={classes.noItem} key="noItem">
          このカテゴリー内の項目は全て非表示に設定されています。地域定着支援台帳の設定画面を確認してください。
        </div>
      ];
    }
    return items.map((item) => {
      let wrapperClass = "";
      let endAdornmentLabel: string | undefined;
      let maxLength: number | undefined;
      let placeholder = "";
      // 通常テキスト以外の特殊表示となる項目
      switch (item.default_item) {
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.address:
          // 住所
          maxLength = 255;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.tel:
          // 電話
          wrapperClass = "-tel";
          maxLength = 12;
          placeholder = "00000000000";
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.email:
          // メール
          wrapperClass = "-email";
          maxLength = 255;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityType:
          // 障害種別
          return (
            <SupportLedgerCheckBox
              key={`item-${item.id}`}
              item={item}
              formValues={formValues}
              isEditing={isEditing}
            />
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilitySupportCategory:
          // 障害支援区分
          return (
            <SupportLedgerRadio
              key={`item-${item.id}`}
              item={item}
              formValues={formValues}
              isEditing={isEditing}
            />
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityHistories: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "disability_histories");

          if (!input.disability_histories) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerHistory
              key={`item-${item.id}`}
              historyData={input.disability_histories}
              formikProps={formikProps}
              name={name}
              label="障害・病歴"
              isEditing={isEditing}
              isShowItemLabel
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.height:
          // 身長
          wrapperClass = "-body";
          endAdornmentLabel = "cm";
          maxLength = 10;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.weight:
          // 体重
          wrapperClass = "-body";
          endAdornmentLabel = "kg";
          maxLength = 10;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.lifeHistories: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "life_histories");

          if (!input.life_histories) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerHistory
              key={`item-${item.id}`}
              historyData={input.life_histories}
              formikProps={formikProps}
              name={name}
              label="生活歴"
              isEditing={isEditing}
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relativesInfo: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "relatives_info");

          if (!input.relatives_info) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerRelativesInfo
              key={`item-${item.id}`}
              relativesInfoData={input.relatives_info}
              formikProps={formikProps}
              name={name}
              label="家族・親族"
              isEditing={isEditing}
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedWelfareService: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "related_organization");

          if (!input.related_organization) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerRelatedOrganization
              key={`item-${item.id}`}
              relatedOrganizationData={input.related_organization}
              name={name}
              label="福祉サービス"
              defaultItem={item.default_item}
              formikProps={formikProps}
              isEditing={isEditing}
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedMedical: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "related_organization");

          if (!input.related_organization) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerRelatedOrganization
              key={`item-${item.id}`}
              relatedOrganizationData={input.related_organization}
              name={name}
              label="医療機関"
              defaultItem={item.default_item}
              formikProps={formikProps}
              isEditing={isEditing}
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedLifeLine: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "related_organization");

          if (!input.related_organization) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerRelatedOrganization
              key={`item-${item.id}`}
              relatedOrganizationData={input.related_organization}
              name={name}
              label="ライフライン"
              defaultItem={item.default_item}
              formikProps={formikProps}
              isEditing={isEditing}
            />
          );
        }
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedOthers: {
          const idxs = getIndexes(formValues, item);
          const input = getInput(formValues, idxs);
          const name = getName(idxs, "related_organization");

          if (!input.related_organization) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <SupportLedgerRelatedOrganization
              key={`item-${item.id}`}
              relatedOrganizationData={input.related_organization}
              name={name}
              label="その他"
              defaultItem={item.default_item}
              formikProps={formikProps}
              isEditing={isEditing}
            />
          );
        }
        default:
      }

      const idxs = getIndexes(formValues, item);
      const input = getInput(formValues, idxs);

      // 通常テキスト
      return (
        <div
          className={classNames(classes.itemInputs, wrapperClass)}
          key={`item-${item.id}`}
        >
          <RecordTextField
            name={getName(idxs, "input_data")}
            label={item.name}
            labelType="default"
            endAdornmentLabel={endAdornmentLabel}
            defaultValue=""
            placeholder={placeholder}
            value={input.input_data}
            isEditable={isEditing}
            style={{ marginTop: 32, marginBottom: 0, width: "100%" }}
            onBlurHook={
              item.default_item ===
              CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.tel
                ? undefined
                : handleBlurDecimal(item.default_item)
            }
            maxLength={maxLength}
          />
        </div>
      );
    });
  };

  return (
    <>
      <div className={classes.categoryType}>
        {CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME[categoryType]}
      </div>
      {sortedCustomRecords.map((category) => (
        <div key={`categoryContainer-${category.id}`}>
          {hasCategoryName && (
            <div
              className={classes.category}
              key={`categoryName-${category.id}`}
            >
              {category.name}
            </div>
          )}
          {itemsBodyInputs(category.custom_record_items)}
        </div>
      ))}
    </>
  );
};

export const SupportLedgerSheetForm = withStyles(styles)(
  SupportLedgerSheetFormCore
);
