import { SelectDateValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * stringの年月日を年、月、日(1固定)に変換する
 */
export const dateToSelectDateValueYYYYM = (date: string): SelectDateValue => {
  const year = date ? dateToLocalisedString(date, "YYYY") : "";
  const month = date ? dateToLocalisedString(date, "M") : "";
  const day = "1";
  return { year, month, day };
};
