/**
 * ActionNames
 */
export const FETCH_CHIIKITEICHAKU_MONTHLY_STARTED =
  "CHIIKITEICHAKU/FETCH_MONTHLY_STARTED";
export const FETCH_CHIIKITEICHAKU_MONTHLY = "CHIIKITEICHAKU/FETCH_MONTHLY";
export const FETCH_CHIIKITEICHAKU_MONTHLY_FAILED =
  "CHIIKITEICHAKU/FETCH_MONTHLY_FAILED";
export const POST_CHIIKITEICHAKU_REPORT_STARTED = "CHIIKITEICHAKU/POST_STARTED";
export const POST_CHIIKITEICHAKU_REPORT_MONTHLY =
  "CHIIKITEICHAKU/POST_REPORT_MONTHLY";
export const POST_CHIIKITEICHAKU_REPORT_FAILED =
  "CHIIKITEICHAKU/POST_REPORT_FAILED";

export type ReportState = {
  inoutConsultationResults: InoutConsultationResultsState[];
};

export type NoConstantContactState = {
  inoutResultsMonthly: NoConstantContactSystemFlgState;
};

export type InoutConsultationResultsState = {
  inout_consultation_results_id: number | null;
  inoutResultDailyId: number | null;
  targetDate: string;
  status: number | null;
  housingSupportCooperationFlg: boolean;
  housingSupportPromotionFlg: boolean;
  dailySupportProvisionOfInfoFlg: boolean;
  noConstantContactSystemFlg: boolean;
  memo: string | null;
  isHoliday: boolean;
};

export type NoConstantContactSystemFlgState = {
  noConstantContactSystemFlg: boolean;
};
