import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 指定ユーザーの地域移行支援報告書情報のデータ削除を行う
 * @param uifId ユーザーID
 * @param assistanceReportId 報告書ID
 */
export const deleteAssistanceReport = async (
  uifId: string,
  assistanceReportId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assistance_report/${assistanceReportId}`;
  return request.delete(url);
};
