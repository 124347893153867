import React, {
  useState,
  useEffect,
  Dispatch as reactDispatch,
  SetStateAction
} from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { UserState } from "@stores/domain/user/type";

// ui
import { CustomRecordsTable } from "@components/organisms/record/setting/CustomRecordsTable";
import { CustomRecordsDialog } from "@components/organisms/record/setting/dialolg/CustomRecordsDialog";
import { SettingFormButtons } from "@components/organisms/record/setting/SettingFormButtons";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";

// formik
import { Formik, FormikActions } from "formik";
import {
  CustomRecordsTableBodyValues,
  initialValues,
  tableBodyInitialValues
} from "@initialize/record/customRecord/initialValues";

// others
import { CUSTOM_RECORD_TARGET_TYPE } from "@constants/variables";
import deepEqual from "fast-deep-equal";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    settingTopSection: {
      margin: "24px 0 8px",
      textAlign: "right"
    },
    changeOrderButton: {
      marginLeft: spacing.unit
    }
  });

type OwnProps = {
  customRecords: CustomRecordsState;
  setSortingSettingType: reactDispatch<SetStateAction<number | null>>;
  sortingSettingType: number | null;
  openHideConfirmModal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openHiddenCustomRecordsList: (type: number) => void;
  fetchCustomRecords: () => Promise<void>;
};

type StateProps = {
  needsStopHistory: boolean;
  userState: UserState;
};

type DispatchProps = {
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsTableBodyValues
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const SupportSettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    customRecords,
    fetchCustomRecords,
    sortingSettingType,
    setSortingSettingType,
    openHideConfirmModal,
    openHiddenCustomRecordsList,
    postCustomRecordsOrder,
    needsStopHistory,
    stopHistory,
    userState
  } = props;

  // state
  const [formValues, setFormValues] = useState(
    initialValues(userState.facility_type)
  );
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [customRecordModalItemId, setCustomRecordModalItemId] = useState(0);

  // 選択肢削除時に画面を更新するための処理
  useEffect(() => {
    const activeRecord = customRecords.find(
      (record) => record.id === customRecordModalItemId
    );

    setFormValues(initialValues(userState.facility_type, activeRecord));
  }, [customRecords]);

  // 項目の追加
  const openAddCustomRecordModal = (
    e: React.MouseEvent<HTMLInputElement>
  ): void => {
    // setting_typeの設定
    const { value } = e.currentTarget;
    setFormValues(
      initialValues(userState.facility_type, undefined, null, +value)
    );
    openCustomRecordModal(true);
  };

  // 項目の編集
  const openEditCustomRecordModal = (
    params: CustomRecordsTableBodyValues[number]
  ): void => {
    setFormValues(initialValues(userState.facility_type, params));
    setCustomRecordModalItemId(params.id);
    openCustomRecordModal(true);
  };

  // 項目の追加、編集モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    openCustomRecordModal(false);
  };

  // 並べ替え用関数（並び順固定項目無し、orderの昇順に並べる）
  const sortRecords = (records: CustomRecordsState): CustomRecordsState =>
    records.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

  // 記録項目データ
  const SupportCustomRecords = customRecords.length
    ? customRecords.filter(
        (record) => record.setting_type === CUSTOM_RECORD_TARGET_TYPE.support
      )
    : [];

  // 記録項目データ（表示用並べ替え済み）
  const visibleSupportCustomRecords = SupportCustomRecords.length
    ? sortRecords(
        SupportCustomRecords.filter(
          (record) =>
            record.visibility === 1 && record.setting_page_visibility === 1
        )
      )
    : [];

  // 非表示リスト用データ
  const nonVisibleSupportCustomRecords = SupportCustomRecords.length
    ? SupportCustomRecords.filter((record) => record.visibility === 0)
    : [];

  // state
  const [tableValues, setTableValues] = React.useState(
    tableBodyInitialValues()
  );

  React.useEffect(() => {
    setTableValues(tableBodyInitialValues(visibleSupportCustomRecords));
  }, [customRecords]);

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordsTableBodyValues
  ): void => {
    const hasChange = !deepEqual(nextValues, tableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordsTableBodyValues): void => {
    if (!needsStopHistory) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordsTableBodyValues,
    actions: FormikActions<CustomRecordsTableBodyValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postCustomRecordsOrder(values)
      .then(() => {
        fetchCustomRecords();
        setSortingSettingType(null);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <FormPaper>
        <div
          className={
            !sortingSettingType ||
            sortingSettingType === CUSTOM_RECORD_TARGET_TYPE.support
              ? classes.editable
              : classes.unEditable
          }
        >
          <SectionTitle label="支援記録の項目設定" />
          <Formik
            initialValues={tableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                <div className={classes.settingTopSection}>
                  <SettingFormButtons
                    settingType={CUSTOM_RECORD_TARGET_TYPE.support}
                    sortingSettingType={sortingSettingType}
                    setSortingSettingType={setSortingSettingType}
                    openAddCustomRecordModal={openAddCustomRecordModal}
                    formikProps={formikProps}
                    formValues={tableValues}
                    stopHistory={stopHistory}
                  />
                </div>
                <CustomRecordsTable
                  hiddenCustomRecords={nonVisibleSupportCustomRecords}
                  openItemEditModal={openEditCustomRecordModal}
                  openHideConfirmModal={openHideConfirmModal}
                  openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                  settingType={CUSTOM_RECORD_TARGET_TYPE.support}
                  isSorting={
                    sortingSettingType === CUSTOM_RECORD_TARGET_TYPE.support
                  }
                  formikProps={formikProps}
                  formValues={tableValues}
                />
              </>
            )}
          </Formik>
        </div>
      </FormPaper>
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecords, uiDispatch } = dispatches;
  const customRecordsDispatches = customRecords(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordsTableBodyValues
    ): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsOrder(
        visibleCustomRecords
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const SupportSettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportSettingFormCore)
);
