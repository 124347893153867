import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { ServiceDeliveryPrint as ServiceDeliveryPrintOrganism } from "@components/organisms/record/print/ServiceDeliveryPrint";
import { ServiceDeliveryPrint as ServiceDeliveryPrintIdoshien } from "@components/organisms/mgr/IDOSHIEN/record/print/ServiceDeliveryPrint";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { UserState } from "@stores/domain/user/type";
import {
  clearRecordPrintState,
  ActionTypes
} from "@stores/domain/serviceDelivery/actions";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  PrintRecordParams,
  targetDateType,
  TargetType
} from "@api/requests/serviceDelivery/getServiceDeliveryPrint";
import { getUrlParams } from "@/utils/url";
import { FacilityType } from "@constants/variables";

type StateProps = {
  printState: ServiceDeliveryState["print"];
  customRecords: CustomRecordsWithCategoryState;
  userState: UserState;
};
type DispatchProps = {
  fetchPrintRecord: (params: PrintRecordParams) => void;
  fetchCustomRecords: () => Promise<void>;
  clearRecordPrintState: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

type PrintRecordQuery = {
  display_columns?: string;
  target_date?: string;
  excluded_user_ids?: string;
  from?: string;
  to?: string;
  user_ids?: string;
};

// /**
//  * サービス提供記録のプリント画面
//  */
const ServiceDeliveryPrintCore = (props: Props): JSX.Element | null => {
  const { pathname, search } = props.location;
  const [displayColumns, setDisplayColumns] = React.useState([] as string[]);

  // 印刷情報取得
  React.useEffect(() => {
    const queryParameters = getUrlParams(search) as PrintRecordQuery;
    let target: TargetType = targetDateType.daily;
    let targetDate;
    let excludedUserIds;
    let from;
    let to;
    let userIds;
    if (pathname.indexOf(targetDateType.monthly) !== -1) {
      target = targetDateType.monthly;
    }
    if (queryParameters.target_date) {
      targetDate = queryParameters.target_date;
    }
    if (queryParameters.excluded_user_ids) {
      excludedUserIds = queryParameters.excluded_user_ids;
    }
    if (queryParameters.from) {
      from = queryParameters.from;
    }
    if (queryParameters.to) {
      to = queryParameters.to;
    }
    if (queryParameters.user_ids) {
      userIds = queryParameters.user_ids;
    }
    if (queryParameters.display_columns) {
      setDisplayColumns(queryParameters.display_columns.split(","));
    }
    props.fetchPrintRecord({
      target,
      targetDate,
      excludedUserIds,
      from,
      to,
      userIds
    });
    props.fetchCustomRecords();
    return (): void => {
      // state初期化
      props.clearRecordPrintState();
    };
  }, []);
  if (!props.printState.length) {
    return null;
  }
  return (
    <AdminTemplate pageName="サービス提供記録">
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        {props.userState.facility_type === FacilityType.IDOSHIEN ? (
          <ServiceDeliveryPrintIdoshien
            records={props.printState}
            customRecords={props.customRecords}
            displayColumns={displayColumns}
          />
        ) : (
          <ServiceDeliveryPrintOrganism
            records={props.printState}
            customRecords={props.customRecords}
            displayColumns={displayColumns}
            facilityType={props.userState.facility_type}
          />
        )}
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  printState: state.serviceDelivery.print,
  customRecords: state.customRecordsWithCategory.serviceDelivery,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery, customRecordsWithCategory } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    fetchPrintRecord: (params: PrintRecordParams): Promise<void> =>
      serviceDelivery(dispatch).fetchPrintRecord(params),
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    clearRecordPrintState: (): ActionTypes => dispatch(clearRecordPrintState())
  };
};

export const ServiceDeliveryPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryPrintCore);
