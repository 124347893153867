import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import dispatches from "@stores/dispatches";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { DownloadFileType } from "@stores/ui/download/type";
import {
  HIDE_ERRORS_DIALOG,
  SHOW_ERRORS_DIALOG
} from "@stores/ui/errorsDialog/types";
import {
  DownloadCsvTarget,
  DownloadableUser
} from "@stores/domain/invoice/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import InvoiceExcludedUserDialog from "@components/organisms/mgr/InvoiceExcludedUserDialog";
import { DownloadTargetSelect } from "@components/organisms/mgr/IDOSHIEN/download/DownloadTargetSelect";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import {
  DOWNLOAD_CSV_JISSEKI,
  DOWNLOAD_CSV_MEISAI,
  DOWNLOAD_CSV_SEIKYU,
  FacilityType,
  INT_FALSE_FROM_API
} from "@constants/variables";

type DispatchProps = {
  downloadable: () => void;
  downloadCsv: (
    year: string,
    month: string,
    excluded_user_ids: number[],
    target: DownloadCsvTarget
  ) => Promise<void>;
  errorInvoice: (
    year: string,
    month: string,
    excluded_user_ids: number[]
  ) => void;
  appDownloadDispatchTargetYearMonth: (yearMonth: string) => void;
  appDownloadIsDisableDownloadButton: (isDisabled: boolean) => void;
  appDownloadIsDisableExcludedUsersButton: (isDisabled: boolean) => void;
  appDownloadReadyFileType: (fileType: DownloadFileType) => void;
  appDownloadIsOpenUserModal: (open: boolean) => void;
  appDownloadExcludedUserIds: (userIds: number[]) => void;
  appDownloadTmpExcludedUserIds: (userIds: number[]) => void;
  appDownloadClearExcludedUserIds: () => void;
  openErrorsDialog: () => void;
  closeErrorsDialog: () => void;
};

type State = {
  actionButtonType: "csv" | "print" | null;
  targetYear: string;
  targetMonth: string;
  downloadReadyFileType: DownloadFileType;
  readonly isDownload: boolean;
};

type DownloadCsvAction = {
  isDisabled: boolean;
  label: string;
  onClick: () => void;
};

type Props = DispatchProps & RouteComponentProps & AppState;

const initialState: State = {
  actionButtonType: null,
  targetYear: "",
  targetMonth: "",
  downloadReadyFileType: DownloadFileType.NONE,
  isDownload: false
};

/**
 * 請求画面
 */
class Download extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public async componentDidMount(): Promise<void> {
    await this.props.downloadable();

    // 選択済みの対象請求月が編集で消えることがありえるのでボタンの非活性を行う
    if (
      this.props.appDownload.targetYearMonth &&
      !this.hasTargetUser(this.props.appDownload.targetYearMonth)
    ) {
      this.props.appDownloadIsDisableDownloadButton(true);
      this.props.appDownloadIsDisableExcludedUsersButton(true);
    }
  }

  public componentDidUpdate(nextProps: Props): void {
    this.preCheckDownload(nextProps);
  }

  // 利用者選択を保存した時
  private onUserModalSubmitClose = (): void => {
    this.props.appDownloadExcludedUserIds(
      this.props.appDownload.tmpExcludedUserIds
    );
    this.props.appDownloadIsOpenUserModal(false);
  };

  // 利用者選択をキャンセルした時
  private onUserModalClose = (): void => {
    this.props.appDownloadIsOpenUserModal(false);
  };

  /**
   * 請求対象月の変更
   */
  private onChangeMonthSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.props.appDownloadDispatchTargetYearMonth(event.target.value);
    if (
      event.target.value !== undefined &&
      event.target.value.length > 0 &&
      this.hasTargetUser(event.target.value)
    ) {
      this.props.appDownloadIsDisableDownloadButton(false);
      this.props.appDownloadIsDisableExcludedUsersButton(false);
    } else {
      this.props.appDownloadIsDisableDownloadButton(true);
      this.props.appDownloadIsDisableExcludedUsersButton(true);
    }
    this.props.appDownloadClearExcludedUserIds();
  };

  /**
   * 対象外のユーザー選択モーダルを開く
   */
  private onClickButton = (): void => {
    this.props.appDownloadTmpExcludedUserIds(
      this.props.appDownload.excludedUserIds
    );
    this.props.appDownloadIsOpenUserModal(true);
  };

  /**
   * 対象外のユーザーの全て選択
   */
  private onChangeAllExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const monthUsers = this.targetMonthUser();
    const serviceType = event.target.value as FacilityType;
    const users = monthUsers[serviceType];
    const userIds = users.map((user) => user.id);

    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter(
          (m) => !userIds.includes(m)
        )
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userIds
      );
      const res = Array.from(new Set(userIdsData));
      this.props.appDownloadTmpExcludedUserIds(res);
    }
  };

  /**
   * 対象外のユーザーを全て選択(多機能)
   */
  private onChangeAllExcludedUserMultiple = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const monthUsers = this.targetMonthUser();
    let userIds: number[] = [];
    Object.keys(monthUsers).forEach((key) => {
      const users: DownloadableUser[] = monthUsers[key];
      userIds = userIds.concat(users.map((user) => user.id));
    });
    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter(
          (m) => !userIds.includes(m)
        )
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userIds
      );
      const res = Array.from(new Set(userIdsData));
      this.props.appDownloadTmpExcludedUserIds(res);
    }
  };

  /**
   * 対象外のユーザーのチェックボックスを押した時のボタン
   */
  private onChangeExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const userId: number = +event.target.value;

    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter((m) => m !== userId)
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userId
      );
      const userIds = Array.from(new Set(userIdsData));
      this.props.appDownloadTmpExcludedUserIds(userIds);
    }
  };

  private checkDownload = (
    year: string,
    month: string,
    fileType: DownloadFileType
  ): void => {
    const downloadReadyFileType = fileType;
    const targetYear = year;
    const targetMonth = month;
    if (this.state.isDownload) {
      this.setState({ isDownload: false });
      switch (downloadReadyFileType) {
        case DownloadFileType.JISSEKI_CSV:
          this.props.downloadCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds,
            DOWNLOAD_CSV_JISSEKI
          );
          break;
        case DownloadFileType.MEISAI_CSV:
          this.props.downloadCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds,
            DOWNLOAD_CSV_MEISAI
          );
          break;
        case DownloadFileType.SEIKYU_CSV:
          this.props.downloadCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds,
            DOWNLOAD_CSV_SEIKYU
          );
          break;
        default:
      }
    }
  };

  private handleClickErrorsDialog = (): void => {
    this.checkDownload(
      this.state.targetYear,
      this.state.targetMonth,
      this.state.downloadReadyFileType
    );
    this.props.closeErrorsDialog();
  };

  /**
   * 指定の月に対象利用者が1人以上存在するかを返す
   */
  private hasTargetUser = (selectMonth: string): boolean => {
    const currentMonthData = this.props.invoice.downloadable.months.find(
      (month) => month.date === selectMonth
    );
    if (!currentMonthData || currentMonthData.results.length === 0) {
      return false;
    }

    // 受給者証未発行/見学中の利用者を除外
    const users = currentMonthData.results.map((result) =>
      result.users.filter(
        (user) => user.none_recipient_number_flg === INT_FALSE_FROM_API
      )
    );
    const userCounts = users.reduce((acc, val) => acc.concat(val), []).length;
    return userCounts > 0;
  };

  /**
   * エラーを確認して大丈夫ならば、ファイルをダウンロードする
   * @param nextProps
   */
  private preCheckDownload(nextProps: Props): void {
    const { downloadReadyFileType } = nextProps.appDownload;
    if (
      downloadReadyFileType !== DownloadFileType.NONE &&
      !nextProps.errors.invoice.loading
    ) {
      // ユーザー任意で実行することがあるためキープしておく
      this.setState({
        targetYear: nextProps.appDownload.targetYear,
        targetMonth: nextProps.appDownload.targetMonth,
        downloadReadyFileType: nextProps.appDownload.downloadReadyFileType
      });

      if (nextProps.errors.invoice.hasError) {
        // エラーが混じっている場合実行出来なくする
        const hasTypeError = nextProps.errors.invoice.data.some((data) =>
          data.errors.some((error) => error.type === "error")
        );
        // CSVの実行できるアクションを切り替える
        switch (downloadReadyFileType) {
          case DownloadFileType.JISSEKI_CSV:
          case DownloadFileType.MEISAI_CSV:
          case DownloadFileType.SEIKYU_CSV:
            this.setState({ actionButtonType: !hasTypeError ? "csv" : null });
            break;
          default:
            this.setState({ actionButtonType: null });
        }
        this.props.openErrorsDialog();
      } else {
        this.checkDownload(
          nextProps.appDownload.targetYear,
          nextProps.appDownload.targetMonth,
          nextProps.appDownload.downloadReadyFileType
        );
      }
      this.props.appDownloadReadyFileType(DownloadFileType.NONE);
    }
  }

  /**
   * 特定の月の、対象施設名を事業所種別ごとに取得
   * {[key in FacilityType]: }
   */
  private targetMonthFacilityName(): { [key in FacilityType]: string } {
    const { months } = this.props.invoice.downloadable;
    const { targetYearMonth } = this.props.appDownload;
    const targetMonths = months.filter((m) => m.date === targetYearMonth);
    const newState = {};
    let index = 0;

    const res = targetMonths.reduce((acc, month) => {
      const data = month.results.reduce((prev, result) => {
        const { name } = result.facility;
        index += 1;
        newState[index] = name;
        return {
          ...acc,
          ...newState
        };
      }, {});
      return {
        ...acc,
        ...data
      };
    }, {} as { [key in FacilityType]: string });
    return res;
  }

  /**
   * 特定の月の、対象ユーザーを事業所種別ごとに取得
   * {[key in FacilityType]: user}
   */
  private targetMonthUser(): { [key in FacilityType]: DownloadableUser[] } {
    const { months } = this.props.invoice.downloadable;
    const { targetYearMonth } = this.props.appDownload;
    const targetMonths = months.filter((m) => m.date === targetYearMonth);
    const newState = {};
    let index = 0;
    const res = targetMonths.reduce((acc, month) => {
      const data = month.results.reduce((prev, result) => {
        index += 1;
        newState[index] = result.users;
        return {
          ...acc,
          ...newState
        };
      }, {});
      return {
        ...acc,
        ...data
      };
    }, {} as { [key in FacilityType]: DownloadableUser[] });
    return res;
  }

  private downloadCsvAction(): DownloadCsvAction[] {
    const isDisabled =
      this.props.appDownload.isDisableDownloadButton ||
      this.props.errors.invoice.loading;

    const onClickDownload = (type: number): void => {
      const { targetYear, targetMonth } = this.props.appDownload;
      if (!!targetYear && !!targetMonth) {
        this.props.errorInvoice(
          targetYear,
          targetMonth,
          this.props.appDownload.excludedUserIds
        );
        this.setState({ isDownload: true });
        this.props.appDownloadReadyFileType(type);
      }
    };

    return [
      {
        isDisabled,
        label: "サービス提供実績記録票",
        onClick: (): void => onClickDownload(DownloadFileType.JISSEKI_CSV)
      },
      {
        isDisabled,
        label: "給付費明細書",
        onClick: (): void => onClickDownload(DownloadFileType.MEISAI_CSV)
      },
      {
        isDisabled,
        label: "請求書",
        onClick: (): void => onClickDownload(DownloadFileType.SEIKYU_CSV)
      }
    ];
  }

  public render(): JSX.Element {
    return (
      <AdminTemplate pageName="請求">
        <DownloadTargetSelect
          months={this.props.invoice.downloadable.months}
          isDisabledButton={this.props.appDownload.isDisableExcludedUsersButton}
          onChangeSelect={this.onChangeMonthSelect}
          onClickButton={this.onClickButton}
          excludedUserIds={this.props.appDownload.excludedUserIds}
          value={this.props.appDownload.targetYearMonth}
          downloadCsvActions={this.downloadCsvAction()}
        />
        <InvoiceExcludedUserDialog
          open={this.props.appDownload.isOpenUserModal}
          onClose={this.onUserModalClose}
          onSubmit={this.onUserModalSubmitClose}
          labelId="excluded-dialog-title"
          users={this.targetMonthUser()}
          facilityNames={this.targetMonthFacilityName()}
          describeId="excluded-dialog-description"
          excludedUserIds={this.props.appDownload.tmpExcludedUserIds}
          onChangeExcludedUser={this.onChangeExcludedUser}
          onChangeAllExcludedUser={this.onChangeAllExcludedUser}
          onChangeAllExcludedUserMultiple={this.onChangeAllExcludedUserMultiple}
          isMultipleFacility={this.props.user.isMultipleFacility}
          isMasterSubordinate={this.props.user.isMasterSubordinate}
          facilityType={this.props.user.facility_type}
        />
        <ErrorsDialog
          errorsKey="invoice"
          actionButton={
            this.state.actionButtonType === "csv"
              ? {
                  text: "そのままダウンロード",
                  clickHandler: this.handleClickErrorsDialog
                }
              : undefined
          }
        />
      </AdminTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch, errorsDispatcher, appDownloadDispatch } = dispatches;

  const invoiceDispatched = invoiceDispatch(dispatch);
  const appDownloadDispatched = appDownloadDispatch(dispatch);

  return {
    downloadable: invoiceDispatched.downloadable,
    downloadCsv: invoiceDispatched.downloadCsv,
    errorInvoice: errorsDispatcher(dispatch).invoice,
    appDownloadDispatchTargetYearMonth: appDownloadDispatched.targetYearMonth,
    appDownloadIsDisableDownloadButton:
      appDownloadDispatched.isDisableDownloadButton,
    appDownloadIsDisableExcludedUsersButton:
      appDownloadDispatched.isDisableExcludedUsersButton,
    appDownloadReadyFileType: appDownloadDispatched.downloadReadyFileType,
    appDownloadIsOpenUserModal: appDownloadDispatched.isOpenUserModal,
    // 対象外ユーザー選択
    appDownloadExcludedUserIds: appDownloadDispatched.excludedUserIds,
    appDownloadTmpExcludedUserIds: appDownloadDispatched.tmpExcludedUserIds,
    appDownloadClearExcludedUserIds: appDownloadDispatched.clearExcludedUserIds,
    openErrorsDialog: (): { readonly type: typeof SHOW_ERRORS_DIALOG } =>
      dispatch(errorsDialogActions.showErrorsDialog()),
    closeErrorsDialog: (): { readonly type: typeof HIDE_ERRORS_DIALOG } =>
      dispatch(errorsDialogActions.hideErrorsDialog())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
