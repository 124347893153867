import { BasicErrors } from "@interfaces/mgr/SEIKATSUKAIGO/facility/basic";
import { SubtractionItemErrors } from "@interfaces/mgr/SEIKATSUKAIGO/facility/subtractionItem";
import { AdministrationErrors } from "@interfaces/mgr/SEIKATSUKAIGO/facility/administration";
import { AdditionalItemErrors } from "@interfaces/mgr/SEIKATSUKAIGO/facility/additionItem";
import { FacilityValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  SELECTED_CONDITION_TYPE_2024_V,
  STRING_TRUE_FROM_API
} from "@constants/variables";

type FacilityErrors = BasicErrors &
  AdministrationErrors &
  SubtractionItemErrors &
  AdditionalItemErrors;

const basicValidation = (values: FacilityValues): BasicErrors => {
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      officeNumber: validator(
        values.basic.officeNumber,
        "required",
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      officeName: validator(values.basic.officeName, "required"),
      representativeName: validator(
        values.basic.representativeName,
        "required"
      ),
      capacity: validator(
        values.basic.capacity,
        "required",
        "naturalNumberNonZero",
        {
          type: "upperLimit",
          upperLimit: 999
        }
      ),
      allCapacity: validateSwitcher(
        values.basic.multiFunctionOfficeFlag,
        validator(
          values.basic.allCapacity,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 999
          }
        )
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "required", "naturalNumber"),

      transferServiceType: validateSwitcher(
        values.basic.transferServiceFlag,
        validator(values.basic.transferServiceType, {
          type: "selectRequired",
          value: DEFAULT_SELECT_VALUE
        })
      )
    }
  };
};

const administrationItemValidation = (
  values: FacilityValues
): AdministrationErrors => {
  return {
    administration: {
      mondayStartTime: validateSwitcher(
        values.administration.mondaySchedule,
        validator(
          values.administration.mondayStartTime,
          "required",
          "checkTime"
        )
      ),
      mondayEndTime: validateSwitcher(
        values.administration.mondaySchedule,
        validator(
          values.administration.mondayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.mondayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      tuesdayStartTime: validateSwitcher(
        values.administration.tuesdaySchedule,
        validator(
          values.administration.tuesdayStartTime,
          "required",
          "checkTime"
        )
      ),
      tuesdayEndTime: validateSwitcher(
        values.administration.tuesdaySchedule,
        validator(
          values.administration.tuesdayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.tuesdayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      wednesdayStartTime: validateSwitcher(
        values.administration.wednesdaySchedule,
        validator(
          values.administration.wednesdayStartTime,
          "required",
          "checkTime"
        )
      ),
      wednesdayEndTime: validateSwitcher(
        values.administration.wednesdaySchedule,
        validator(
          values.administration.wednesdayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.wednesdayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      thursdayStartTime: validateSwitcher(
        values.administration.thursdaySchedule,
        validator(
          values.administration.thursdayStartTime,
          "required",
          "checkTime"
        )
      ),
      thursdayEndTime: validateSwitcher(
        values.administration.thursdaySchedule,
        validator(
          values.administration.thursdayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.thursdayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      fridayStartTime: validateSwitcher(
        values.administration.fridaySchedule,
        validator(
          values.administration.fridayStartTime,
          "required",
          "checkTime"
        )
      ),
      fridayEndTime: validateSwitcher(
        values.administration.fridaySchedule,
        validator(
          values.administration.fridayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.fridayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      saturdayStartTime: validateSwitcher(
        values.administration.saturdaySchedule,
        validator(
          values.administration.saturdayStartTime,
          "required",
          "checkTime"
        )
      ),
      saturdayEndTime: validateSwitcher(
        values.administration.saturdaySchedule,
        validator(
          values.administration.saturdayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.saturdayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      ),
      sundayStartTime: validateSwitcher(
        values.administration.sundaySchedule,
        validator(
          values.administration.sundayStartTime,
          "required",
          "checkTime"
        )
      ),
      sundayEndTime: validateSwitcher(
        values.administration.sundaySchedule,
        validator(
          values.administration.sundayEndTime,
          "required",
          "checkTime",
          {
            type: "checkTimeFuture",
            startTime: values.administration.sundayStartTime,
            options: { firstLabel: "終業時間", secondLabel: "始業時間" }
          }
        )
      )
    }
  };
};

const subtractionItemValidation = (
  values: FacilityValues
): SubtractionItemErrors => {
  // lackOfLifeSupportMemberStartDate,lackOfResponsiblePersonStartDateの
  // フォーム値は「年月」であるが、入力チェックを行うために「年月日」であることが必要なので、
  // 日にちとして「1日」をdefaultで設定する
  const {
    lackOfLifeSupportMemberStartDate,
    lackOfResponsiblePersonStartDate
  } = values.subtractionItem;
  const memberDate = { ...lackOfLifeSupportMemberStartDate, day: "1" };
  const personDate = { ...lackOfResponsiblePersonStartDate, day: "1" };
  const selectDateRules: { type: "selectRequired"; value: string } = {
    type: "selectRequired",
    value: DEFAULT_SELECT_VALUE
  };
  return {
    subtractionItem: {
      lackOfLifeSupportMemberStartDate: {
        year: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(memberDate.year, selectDateRules)
        ),
        // 日付の初期値が空文字のため、validationの初期値に合わせる
        month: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(
            memberDate.month === "" ? DEFAULT_SELECT_VALUE : memberDate.month,
            selectDateRules
          )
        ),
        day: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(memberDate.day, selectDateRules)
        )
      },
      lackOfResponsiblePersonStartDate: {
        year: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(personDate.year, selectDateRules)
        ),
        month: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(
            personDate.month === "" ? DEFAULT_SELECT_VALUE : personDate.month,
            selectDateRules
          )
        ),
        day: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(personDate.day, selectDateRules)
        )
      },
      bodyRestrictedStillFrom: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillFrom),
          "required"
        )
      ),
      bodyRestrictedStillTo: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillTo),
          {
            type: "future",
            startDate: values.subtractionItem.bodyRestrictedStillFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      workContinuationNotPlanningFrom: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningFrom
          ),
          "required"
        )
      ),
      workContinuationNotPlanningTo: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningTo
          ),
          {
            type: "future",
            startDate: values.subtractionItem.workContinuationNotPlanningFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};
const AdditionalValidation = (values: FacilityValues): AdditionalItemErrors => {
  return {
    additionalItem: {
      numberOfContinuations: validateSwitcher(
        values.additionalItem.employmentTransitionSupportFlag,
        validator(
          values.additionalItem.numberOfContinuations,
          "required",
          "naturalNumberNonZero",
          {
            type: "lowerLimit",
            lowerLimit: 1
          }
        )
      ),
      welfareConditionEndDate: validateSwitcher(
        values.additionalItem.welfareSpecialistPlacementType !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.welfareConditionEndDate),
          {
            type: "future",
            startDate: values.additionalItem.welfareConditionStartDate,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      placementEndDate: validateSwitcher(
        values.additionalItem.placementOfServiceAdminFlag === true,
        dateValidator(
          notSelectedToEmpty(values.additionalItem.placementEndDate),
          {
            type: "futureNextDayAfter",
            startDate: values.additionalItem.placementStartDate,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      nursingSupporter: validateSwitcher(
        values.additionalItem.nursingSupporterFlg,
        validator(
          values.additionalItem.nursingSupporter,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 999
          }
        )
      ),
      specificBetterSupporterCondition202404:
        values.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        values.additionalItem.specificBetterSupporterCondition202404Sub ===
          `${NO_SELECTED_CONDITION_TYPE_2024_SUB}`
          ? "福祉・介護職員等処遇改善（Ⅴ）以下の項目を選択してください"
          : undefined,
      seeHearTeam202404From: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== STRING_TRUE_FROM_API,
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404From),
          "required"
        )
      ),
      seeHearTeam202404To: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== STRING_TRUE_FROM_API,
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404To),
          {
            type: "future",
            startDate: values.additionalItem.seeHearTeam202404From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      higherBrainDysfunctionPersonsSupportFrom: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportFrom
          ),
          "required"
        )
      ),
      higherBrainDysfunctionPersonsSupportTo: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportTo
          ),
          {
            type: "future",
            startDate:
              values.additionalItem.higherBrainDysfunctionPersonsSupportFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};

const validation = (values: FacilityValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const administrationErrors = administrationItemValidation(values);
  const subtractionItemErrors = subtractionItemValidation(values);
  const additionalItemErrors = AdditionalValidation(values);
  return {
    ...basicErrors,
    ...administrationErrors,
    ...subtractionItemErrors,
    ...additionalItemErrors
  };
};

export default validation;
