import {
  HospitalizationDays,
  StatusType
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import {
  ReportState,
  ReportType
} from "@stores/domain/mgr/SHISETSUNYUSHO/report/types";
import {
  ReportState as ReportStateV202104,
  ReportType as ReportTypeV202104
} from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/report/types";
import {
  ReportState as ReportStateV201910,
  ReportType as ReportTypeV201910
} from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/types";

/** 入院・外泊日数の最大値 */
const MAX_HOSPITALIZATION_DAYS_PREV = 999;

/** 「入院・外泊日数」のデフォルト値を取得(実績一覧) */
export const getDefaultHospitalizationDays = (
  statusType: string,
  hospitalizationDaysPrev: string | null
): string | null => {
  // 前日＋1の場合
  let previousDayPlusOne = 1;

  if (
    hospitalizationDaysPrev !== null &&
    !Number.isNaN(Number(hospitalizationDaysPrev))
  ) {
    previousDayPlusOne += Number(hospitalizationDaysPrev);
  }

  switch (statusType) {
    case StatusType.NONE:
    case StatusType.LODGING:
    case StatusType.EXPERIENCE_LODGING_FIRST:
    case StatusType.EXPERIENCE_LODGING:
    case StatusType.EXPERIENCE_LODGING_LAST:
    case StatusType.REGIONAL_MIGRATION_SUPPORT:
      return null;
    case StatusType.HOSPITALIZATION_FIRST:
    case StatusType.OVERNIGHT_FIRST:
    case StatusType.HOSPITALIZATION_FROM_OVERNIGHT:
    case StatusType.OVERNIGHT_FROM_HOSPITALIZATION:
    case StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT:
    case StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION:
      return HospitalizationDays.ZERO;
    case StatusType.HOSPITALIZATION:
    case StatusType.HOSPITALIZATION_LAST:
    case StatusType.HOSPITALIZATION_TEMPORARY_STAY_INSIDE:
    case StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE:
    case StatusType.OVERNIGHT:
    case StatusType.OVERNIGHT_LAST:
      return Math.min(
        MAX_HOSPITALIZATION_DAYS_PREV,
        previousDayPlusOne
      ).toString();
    default:
      return null;
  }
};

/** 「入院・外泊日数」のデフォルト値を取得(実績編集モーダル) */
export const getDefaultHospitalizationDaysDialogFields = (
  statusType: string,
  hospitalizationDaysPrev: number | null
): string => {
  // 前日＋1の場合
  let previousDayPlusOne = 1;

  if (
    hospitalizationDaysPrev !== null &&
    !Number.isNaN(hospitalizationDaysPrev)
  ) {
    previousDayPlusOne += hospitalizationDaysPrev;
  }
  switch (statusType) {
    case StatusType.NONE:
    case StatusType.LODGING:
    case StatusType.EXPERIENCE_LODGING_FIRST:
    case StatusType.EXPERIENCE_LODGING:
    case StatusType.EXPERIENCE_LODGING_LAST:
    case StatusType.REGIONAL_MIGRATION_SUPPORT:
      return HospitalizationDays.NONE;
    case StatusType.HOSPITALIZATION_FIRST:
    case StatusType.OVERNIGHT_FIRST:
    case StatusType.HOSPITALIZATION_FROM_OVERNIGHT:
    case StatusType.OVERNIGHT_FROM_HOSPITALIZATION:
    case StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT:
    case StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION:
      return HospitalizationDays.ZERO;
    case StatusType.HOSPITALIZATION:
    case StatusType.HOSPITALIZATION_LAST:
    case StatusType.HOSPITALIZATION_TEMPORARY_STAY_INSIDE:
    case StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE:
    case StatusType.OVERNIGHT:
    case StatusType.OVERNIGHT_LAST:
      return Math.min(
        MAX_HOSPITALIZATION_DAYS_PREV,
        previousDayPlusOne
      ).toString();
    default:
      return HospitalizationDays.NONE;
  }
};

/**
 *月ごとの利用実績で前日+1の値取得
 * @param keyValue 入院・外泊日数
 * @param usagePerformanceSHISETSUNYUSHO 前日の入院・外泊日数
 */
export const getUsersHospitalizationDaysPrev = (
  keyValue: string,
  usagePerformanceSHISETSUNYUSHO:
    | ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]
    | ReportStateV202104[ReportTypeV202104]["usagePerformanceSHISETSUNYUSHO"]
    | ReportStateV201910[ReportTypeV201910]["usagePerformanceSHISETSUNYUSHO"]
): string | null => {
  let previousDay = (Number(keyValue.slice(-2)) - 1).toString();
  previousDay = `00${Number(previousDay)}`.slice(-2);
  if (previousDay === "00") {
    return usagePerformanceSHISETSUNYUSHO.after[`${keyValue}`]
      .hospitalizationDaysPrev;
  }
  // 前日のkeyValue
  const keyValuePrev = keyValue.slice(0, -2) + previousDay;
  const hospitalizationDaysPrev =
    usagePerformanceSHISETSUNYUSHO.after[`${keyValuePrev}`].hospitalizationDays;
  return hospitalizationDaysPrev;
};

/**
 * 「入院・外泊日数」をPOST用に変換
 * @param hospitalizationDays 入院・外泊日数
 * @param hospitalizationDaysPrev 前日の入院・外泊日数
 * @param serviceStatus サービス提供の状況
 * @param isDialogFields true: 実績編集モーダル/ false: 実績一覧
 */
export const normalizeHospitalizationDays = (
  hospitalizationDays: string | null,
  hospitalizationDaysPrev: string | null,
  serviceStatus: number,
  isDialogFields: boolean
): string | null => {
  if (
    hospitalizationDays === null ||
    hospitalizationDays === HospitalizationDays.NONE
  ) {
    return null;
  }
  let normalizedHospitalizationDays:
    | string
    | number
    | null = hospitalizationDays;
  // 空白の場合はデフォルト値で登録
  if (hospitalizationDays === "" && isDialogFields) {
    normalizedHospitalizationDays = getDefaultHospitalizationDaysDialogFields(
      serviceStatus.toString(),
      Number(hospitalizationDaysPrev)
    );
  } else if (hospitalizationDays === "" && !isDialogFields) {
    normalizedHospitalizationDays = getDefaultHospitalizationDays(
      serviceStatus.toString(),
      hospitalizationDaysPrev === null ? null : hospitalizationDaysPrev
    );
  }

  // 先頭に0がある場合削除して返す
  return Number(normalizedHospitalizationDays).toString();
};
