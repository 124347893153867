import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import getOperationsAndSupports from "@api/requests/operations/TANKINYUSHO/getOperationsAndSupports";

export const fetchDailyOperationsAndSupports = (dispatch: Dispatch) => async (
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchDailyOperationsAndSupportsStarted());
  await getOperationsAndSupports(yyyymmdd)
    .then((res) => {
      dispatch(actions.fetchDailyOperationsAndSupportsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(
        actions.fetchDailyOperationsAndSupportsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchDailyOperationsAndSupports: (yyyymmdd: string) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchDailyOperationsAndSupports: fetchDailyOperationsAndSupports(dispatch)
});
