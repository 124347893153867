import * as H from "history";
import * as URL from "@constants/url";
import * as actions from "./actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as loadingActions from "@stores/loading/actions";
import * as staffActions from "@stores/domain/staff/actions";
import * as navigationTransitionActions from "@stores/ui/navigationTransition/actions";
import cityApi from "@api/requests/city";
import staffApi from "@api/requests/staff";
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { CityParams } from "@stores/domain/city/type";
import { PLAN_LIST_TAB_PATH } from "@constants/variables";
import { carePlanApi } from "@api/requests/carePlan";
import {
  RecordCarePlan,
  SupportCarePlanSchedules
} from "@interfaces/record/carePlan/carePlan";
import {
  normalizeFormValuesToPostSupportPlanUifIdCarePlanNew,
  normalizeFormValuesToPostSupportPlanUifIdCarePlanCarePlanId,
  normalizeFormValuesTopostSupportPlanUifIdCarePlanCarePlanIdDetails
} from "./normalizer";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

const fetchSupportPlanUifIdCarePlan = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportPlanUifIdCarePlanStarted());
  await carePlanApi
    .getSupportPlanUifIdCarePlan(uifId)
    .then((res) => {
      dispatch(actions.fetchSupportPlanUifIdCarePlanSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(
        actions.fetchSupportPlanUifIdCarePlanFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchSupportPlanUifIdCarePlanCarePlanId = (dispatch: Dispatch) => async (
  uifId: string,
  carePlanId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportPlanUifIdCarePlanCarePlanIdStarted());
  dispatch(staffActions.fetchStarted());

  Promise.all([
    staffApi.getStaff(),
    carePlanApi.getSupportPlanUifIdCarePlanCarePlanId(uifId, carePlanId)
  ])
    .then((res) => {
      dispatch(staffActions.fetchSuccess(res[0].data));
      dispatch(
        actions.fetchSupportPlanUifIdCarePlanCarePlanIdSuccess(res[1].data.data)
      );
    })
    .catch((e) => {
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(
        actions.fetchSupportPlanUifIdCarePlanCarePlanIdFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const updateSchedules = (dispatch: Dispatch) => async (
  uifId: string,
  carePlanId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.updateSchedulesStarted());

  await carePlanApi
    .getSupportPlanUifIdCarePlanCarePlanId(uifId, carePlanId)
    .then((res) => {
      dispatch(actions.updateSchedulesSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(
        actions.updateSchedulesFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchCity = (dispatch: Dispatch) => async (
  params: CityParams
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCityStarted());

  await cityApi
    .getCityList(params.prefectureName)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.fetchCitySuccess(data));
    })
    .catch((e) => {
      dispatch(actions.fetchCityFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchStaff = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(staffActions.fetchStarted());
  await staffApi
    .getStaff()
    .then((res) => {
      dispatch(staffActions.fetchSuccess(res.data));
    })
    .catch((e) => {
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postSupportPlanUifIdCarePlanNew = (dispatch: Dispatch) => async (
  uifId: string,
  values: RecordCarePlan,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportPlanUifIdCarePlanNewStarted());
  const normalizedParams = normalizeFormValuesToPostSupportPlanUifIdCarePlanNew(
    values
  );
  await carePlanApi
    .postSupportPlanUifIdCarePlanNew(uifId, normalizedParams)
    .then(() => {
      dispatch(actions.postSupportPlanUifIdCarePlanNewSuccess());
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
      );
    })
    .catch((e) => {
      dispatch(
        actions.postSupportPlanUifIdCarePlanNewFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postSupportPlanUifIdCarePlanCarePlanId = (dispatch: Dispatch) => async (
  uifId: string,
  carePlanId: string,
  values: RecordCarePlan,
  history: H.History,
  gotValue: GetSupportPlanUifIdCarePlanCarePlanId["data"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportPlanUifIdCarePlanCarePlanIdStarted());
  const normalizedParams = normalizeFormValuesToPostSupportPlanUifIdCarePlanCarePlanId(
    values,
    gotValue
  );
  await carePlanApi
    .postSupportPlanUifIdCarePlanCarePlanId(uifId, carePlanId, normalizedParams)
    .then(() => {
      dispatch(actions.postSupportPlanUifIdCarePlanCarePlanIdSuccess());
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
      );
    })
    .catch((e) => {
      dispatch(
        actions.postSupportPlanUifIdCarePlanCarePlanIdFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteSupportPlanUifIdCarePlanCarePlanId = (
  dispatch: Dispatch
) => async (
  uifId: string,
  carePlanId: string,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteSupportPlanUifIdCarePlanCarePlanIdStarted());
  await carePlanApi
    .deleteSupportPlanUifIdCarePlanCarePlanId(uifId, carePlanId)
    .then(() => {
      dispatch(
        actions.deleteSupportPlanUifIdCarePlanCarePlanIdSuccess(carePlanId)
      );
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
      history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
      );
    })
    .catch((e) => {
      dispatch(
        actions.deleteSupportPlanUifIdCarePlanCarePlanIdFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postSupportPlanUifIdCarePlanCarePlanIdDetails = (
  dispatch: Dispatch
) => async (
  uifId: string,
  carePlanId: string,
  values: SupportCarePlanSchedules[],
  isNew: boolean
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportPlanUifIdCarePlanCarePlanIdDetailsStarted());
  const normalizedParams = normalizeFormValuesTopostSupportPlanUifIdCarePlanCarePlanIdDetails(
    values,
    isNew
  );
  await carePlanApi
    .postSupportPlanUifIdCarePlanCarePlanIdDetails(
      uifId,
      carePlanId,
      normalizedParams
    )
    .then(() => {
      dispatch(actions.postSupportPlanUifIdCarePlanCarePlanIdDetailsSuccess());
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.postSupportPlanUifIdCarePlanCarePlanIdDetailsFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId = (
  dispatch: Dispatch
) => async (uifId: string, carePlanScheduleId: string): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(
    actions.deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdStarted()
  );
  await carePlanApi
    .deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId(
      uifId,
      carePlanScheduleId
    )
    .then(() => {
      dispatch(
        actions.deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdSuccess()
      );
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchSupportPlanUifIdCarePlan: (uifId: string) => Promise<void>;
  fetchSupportPlanUifIdCarePlanCarePlanId: (
    uifId: string,
    carePlanId: string
  ) => Promise<void>;
  updateSchedules: (uifId: string, carePlanId: string) => Promise<void>;
  fetchCity: (params: CityParams) => Promise<void>;
  fetchStaff: () => Promise<void>;
  postSupportPlanUifIdCarePlanNew: (
    uifId: string,
    values: RecordCarePlan,
    history: H.History
  ) => Promise<void>;
  postSupportPlanUifIdCarePlanCarePlanId: (
    uifId: string,
    carePlanId: string,
    values: RecordCarePlan,
    history: H.History,
    gotValue: GetSupportPlanUifIdCarePlanCarePlanId["data"]
  ) => Promise<void>;
  deleteSupportPlanUifIdCarePlanCarePlanId: (
    uifId: string,
    carePlanId: string,
    history: H.History
  ) => Promise<void>;
  postSupportPlanUifIdCarePlanCarePlanIdDetails: (
    uifId: string,
    carePlanId: string,
    values: SupportCarePlanSchedules[],
    isNew: boolean
  ) => Promise<void>;
  deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId: (
    uifId: string,
    carePlanScheduleId: string
  ) => Promise<void>;
};

export const carePlanDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchSupportPlanUifIdCarePlan: fetchSupportPlanUifIdCarePlan(dispatch),
  fetchSupportPlanUifIdCarePlanCarePlanId: fetchSupportPlanUifIdCarePlanCarePlanId(
    dispatch
  ),
  updateSchedules: updateSchedules(dispatch),
  fetchCity: fetchCity(dispatch),
  fetchStaff: fetchStaff(dispatch),
  postSupportPlanUifIdCarePlanNew: postSupportPlanUifIdCarePlanNew(dispatch),
  postSupportPlanUifIdCarePlanCarePlanId: postSupportPlanUifIdCarePlanCarePlanId(
    dispatch
  ),
  deleteSupportPlanUifIdCarePlanCarePlanId: deleteSupportPlanUifIdCarePlanCarePlanId(
    dispatch
  ),
  postSupportPlanUifIdCarePlanCarePlanIdDetails: postSupportPlanUifIdCarePlanCarePlanIdDetails(
    dispatch
  ),
  deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId: deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId(
    dispatch
  )
});
