import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { SupportPlanGoalEvaluationFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/SupportPlanGoalEvaluationFields";
import { SelectDateValue } from "@interfaces/ui/form";
import { dateToLocalisedString } from "@/utils/date";
import { selectDateValueToDate } from "@utils/date";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

const styles = (): StyleRules =>
  createStyles({
    wrap1: {
      marginTop: 24
    },
    wrap2: {
      marginTop: 48
    },
    label: {
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 12
    },
    block: {
      paddingLeft: 12
    },
    field: {
      marginTop: 30
    },
    goalField: {
      margin: "32px 0 48px"
    },
    dateField: {
      margin: "30px 24px 0 0",
      width: 245
    }
  });

type OwnProps = {
  isEditing: boolean;
  values: {
    support_plan_goal: {
      id?: number;
      number: number;
      sprint_goal: string;
      adopt_info: string;
      support_info: string;
      sprint_start_date: SelectDateValue;
      sprint_end_date: SelectDateValue;
      sprint_result: string;
      achievement: string;
      satisfaction: string;
      countermeasure: string;
    }[];
  };
  isPlanned?: boolean;
  hasEvaluation?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 目標達成に向けた支援方針・内容・期間・頻度
 */
const SupportPlanGoalFieldsCore = (props: Props): JSX.Element => {
  // 評価・振り返りの時は編集しない
  const isEditable = !props.hasEvaluation && props.isEditing;
  // 評価・振り返り・計画目標モーダルでは適応日をまとめる
  const isDateSplitOff = props.isPlanned || props.hasEvaluation;

  return (
    <>
      <GrayMiddleHeading text="目標達成に向けた支援方針・内容・期間・頻度" />
      {[2, 3, 4].map((num, index) => {
        const value = props.values.support_plan_goal[num];
        const sprintStartDate = isDateSplitOff
          ? selectDateValueToDate(value.sprint_start_date)
          : "";
        const sprintEndDate = isDateSplitOff
          ? selectDateValueToDate(value.sprint_end_date)
          : "";
        return (
          <div
            className={index === 0 ? props.classes.wrap1 : props.classes.wrap2}
            key={value.id || value.number}
          >
            <div className={props.classes.label}>
              支援方針 {circleNumbersList[index]}
            </div>
            <div className={props.classes.block}>
              <RecordTextField
                name={`support_plan_goal[${num}]["sprint_goal"]`}
                value={value.sprint_goal}
                defaultValue="-"
                label="支援方針"
                labelType="default"
                placeholder=""
                isEditable={isEditable}
              />
              <div className={props.classes.field}>
                <RecordTextField
                  name={`support_plan_goal[${num}]["adopt_info"]`}
                  value={value.adopt_info}
                  defaultValue="-"
                  label="本人の取組内容"
                  labelType="default"
                  placeholder=""
                  isEditable={isEditable}
                />
              </div>
              <div className={props.classes.field}>
                <RecordTextField
                  name={`support_plan_goal[${num}]["support_info"]`}
                  value={value.support_info}
                  defaultValue="-"
                  label="職員の支援内容"
                  labelType="default"
                  placeholder=""
                  isEditable={isEditable}
                />
              </div>
              <FormGroup row>
                {isDateSplitOff ? (
                  <>
                    <div className={props.classes.dateField}>
                      <ReadonlyTextField
                        label="適用開始日"
                        value={
                          sprintStartDate
                            ? dateToLocalisedString(
                                sprintStartDate,
                                "YYYY年M月D日"
                              )
                            : ""
                        }
                        defaultValue="-"
                      />
                    </div>
                    <div className={props.classes.dateField}>
                      <ReadonlyTextField
                        label="適用終了日"
                        value={
                          sprintEndDate
                            ? dateToLocalisedString(
                                sprintEndDate,
                                "YYYY年M月D日"
                              )
                            : ""
                        }
                        defaultValue="-"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className={props.classes.field}>
                      <RecordSelectDate
                        name={`support_plan_goal[${num}]["sprint_start_date"]`}
                        label="適用開始日"
                        value={value.sprint_start_date}
                        isEditable={isEditable}
                        addYearTo={1}
                        overrideYearFrom={1989}
                      />
                    </div>
                    <div className={props.classes.field}>
                      <RecordSelectDate
                        name={`support_plan_goal[${num}]["sprint_end_date"]`}
                        label="適用終了日"
                        value={value.sprint_end_date}
                        isEditable={isEditable}
                        addYearTo={10}
                        overrideYearFrom={1989}
                      />
                    </div>
                  </>
                )}
              </FormGroup>
            </div>
            {props.hasEvaluation && (
              <div className={props.classes.goalField}>
                <SupportPlanGoalEvaluationFields
                  isEditing={props.isEditing}
                  index={num}
                  values={props.values}
                />
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export const SupportPlanGoalFields = withStyles(styles)(
  SupportPlanGoalFieldsCore
);
