import { getFacilityMunicipalitiesTargetId } from "./getFacilityMunicipalitiesTargetId";
import deleteFacilityMunicipality from "./deleteFacilityMunicipality";
import getFacility from "./getFacility";
import postFacility from "./postFacility";
import getFacilityUsers from "./getFacilityUsers";
import { getFacilityUsersList } from "./getFacilityUsersList";
import getFacilityUserTargetId from "./getFacilityUserTargetId";
import postNewFacilityUser from "./postNewFacilityUser";
import postUpdateFacilityUser from "./postUpdateFacilityUser";
import getTargetFacilityUser from "./getTargetFacilityUser";
import deleteFacilityUser from "./deleteFacilityUser";
import deleteFacilityUserTargetId from "./deleteFacilityUserTargetId";
import getFacilityMultiFunctional from "./getFacilityMultiFunctional";
import getFacilityMunicipalities from "./getFacilityMunicipalities";
import { getCarePlan } from "./getCarePlan";
import { postCarePlan } from "./postCarePlan";
import { deleteCarePlan } from "./deleteCarePlan";
import { postFacilityMunicipality } from "./postFacilityMunicipality";

export default {
  getFacility,
  postFacility,
  getFacilityUsers,
  getFacilityUsersList,
  getFacilityUserTargetId,
  postNewFacilityUser,
  postUpdateFacilityUser,
  getTargetFacilityUser,
  deleteFacilityUser,
  deleteFacilityUserTargetId,
  getFacilityMultiFunctional,
  getFacilityMunicipalities,
  getFacilityMunicipalitiesTargetId,
  deleteFacilityMunicipality,
  postFacilityMunicipality,
  getCarePlan,
  postCarePlan,
  deleteCarePlan
};
