import * as React from "react";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { OperationsAndSupportsState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";

// ui
import { DailyPrintOperationCsegRecords } from "@components/organisms/record/print/DailyPrintOperationCsegRecords";

// utils
import { getUrlParams } from "@utils/url";
import { DailyPrintSupportCseg } from "./DailyPrintSupportCseg";
import { FacilityType } from "@constants/variables";

/**
 * interface
 */
type StateProps = {
  facilityType: FacilityType;
  dailyOperationsAndSupports: OperationsAndSupportsState;
};

type DispatchProps = {
  fetchOperationsAndSupports: (
    yyyymmdd: string,
    page?: number,
    keyword?: string,
    isRecord?: number
  ) => void;
  fetchUsers: (facilityType: FacilityType) => void;
  fetchKEIKAKUSODANFacility: () => void;
};

type OwnProps = {
  selectedDate: string;
  query: string;
};

type Props = StateProps & OwnProps & DispatchProps;

const DailyPrintCsegRecordsCore = (props: Props): JSX.Element | null => {
  const {
    selectedDate,
    dailyOperationsAndSupports,
    query,
    facilityType
  } = props;

  const queryParameters: {
    display_columns?: string;
  } = getUrlParams(query);

  const displayColumnsValues = queryParameters.display_columns
    ? queryParameters.display_columns.split(",")
    : [];

  React.useEffect(() => {
    props.fetchOperationsAndSupports(selectedDate);
    props.fetchUsers(facilityType);
    if (props.facilityType === FacilityType.KEIKAKUSODAN) {
      props.fetchKEIKAKUSODANFacility();
    }
  }, []);

  return (
    <>
      {displayColumnsValues.includes("operation_record") && (
        // 業務日誌
        <DailyPrintOperationCsegRecords
          date={selectedDate}
          operation={dailyOperationsAndSupports.operation_records}
        />
      )}
      {/* 支援記録 */}
      <DailyPrintSupportCseg
        displayColumns={displayColumnsValues}
        date={selectedDate}
        supportRecords={dailyOperationsAndSupports.support_records}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityType: state.user.facility_type,
    dailyOperationsAndSupports: state.Cseg.csegOperationsAndSupports
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, KEIKAKUSODAN } = dispatches;

  return {
    fetchOperationsAndSupports: (
      yyyymmdd: string,
      page?: number,
      keyword?: string,
      isRecord?: number
    ): void => {
      Cseg.operationsAndSupportsDispatcher(dispatch).fetchOperationsAndSupports(
        yyyymmdd,
        page,
        keyword,
        isRecord
      );
    },
    fetchUsers: (facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetch();
    },
    fetchKEIKAKUSODANFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

export const DailyPrintCsegRecords = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyPrintCsegRecordsCore);
