import * as types from "./types";
import * as actions from "./actions";

const initialState: types.InitialState = {
  facility: {
    first_time_bill_date: "",
    total_number_of_users_1_month_before: 0,
    total_number_of_users_2_month_before: 0,
    total_number_of_users_3_month_before: 0,
    total_number_of_users_4_month_before: 0,
    total_number_of_users_5_month_before: 0,
    total_number_of_users_6_month_before: 0,
    total_number_of_staffs_1_month_before: 0,
    total_number_of_staffs_2_month_before: 0,
    total_number_of_staffs_3_month_before: 0,
    total_number_of_staffs_4_month_before: 0,
    total_number_of_staffs_5_month_before: 0,
    total_number_of_staffs_6_month_before: 0
  }
};

export const KEIKAKUSODANInitial = (
  state = initialState,
  action: actions.ActionTypes
): types.InitialState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_FAILED:
      return { ...state };
    case types.POST_STARTED:
      return { ...state };
    case types.POST_SUCCESS:
      return { ...state, ...action.payload };
    case types.POST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
