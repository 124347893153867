import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { isUndefined, uniq } from "lodash-es";

// api
import {
  GetFacilityUsersResponse,
  GetFacilityUsersResponseElement
} from "@api/requests/facility/getFacilityUsers";
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

// stores
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";

// mui
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

// constants
import {
  DEFAULT_UNIT_SELECT_BOX,
  PatternListType,
  patternListFormat
} from "@constants/variables";

// components
import { SearchUsersForm } from "@components/organisms/mgr/common/record/recordEachUser/SearchUsersForm";
import { RecordUserRow } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserRow";
import { RecordUserEmptyMessage } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserEmptyMessage";
import { RecordUserGroup } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserGroup";
import SectionTitle from "@components/atoms/SectionTitle";

// utils
import { filterUsersByKana } from "@utils/domain/record/filterUsersByKana";
import { filterActiveUsers } from "@utils/domain/record/filterActiveUsers";

const styles = (): StyleRules =>
  createStyles({
    paperContainer: {
      padding: "30px 32px 32px 32px",
      margin: 16
    },
    ul: {
      listStyle: "none",
      padding: 0,
      marginTop: 8,
      marginBottom: 0
    },
    unitUserList: {
      listStyle: "none",
      padding: 0,
      marginTop: 0,
      marginBottom: 1,
      borderTop: "1px solid #e5e5e5"
    },
    unitName: {
      backgroundColor: "#eceff1",
      padding: "8px 16px",
      fontSize: 16,
      "& + ul": {
        borderTop: "none"
      }
    }
  });

type OwnProps = {
  users: GetFacilityUsersResponse["data"];
  errorState: ErrorsState["records"]["data"];
  buttonList: React.ComponentProps<typeof RecordUserRow>["buttonList"];
  optionalCustomInfo: GetOptionalCustomInfoResponse["data"];
  userState?: UserState;
  fetchOptionalCustomInfo: () => Promise<void>;
  fetchUsers: () => Promise<void>;
  fetchErrors: () => Promise<void>;
};

type Props = OwnProps & WithStyles<typeof styles>;

// フックの返却型定義
type UseRecordEachUserGroupReturnType = {
  currentUsers: GetFacilityUsersResponse["data"];
  targetPattern: PatternListType[];
  setTargetPattern: React.Dispatch<React.SetStateAction<PatternListType[]>>;
  expiredVisibility: boolean;
  setExpiredVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearchUsers: (e: React.MouseEvent<HTMLElement>) => void;
  handleClear: () => void;
  unitGroup: number;
  setUnitGroup: React.Dispatch<React.SetStateAction<number>>;
  isZeroMatch: boolean;
  facilityUnits: GetOptionalCustomInfoResponse["data"]["facility_units"];
  filteredUnitGroups: number;
};

/**
 * @param users ユーザー
 * @param setCurrentUsers 表示するユーザー
 * @param optionalCustomInfo ユニット情報
 * @param fetchOptionalCustomInfo ユニット情報をfetchする処理
 * @param fetchUsers usersをfetchする処理
 * @param fetchErrors errorsをfetchする処理
 * @returns
 */
const useRecordEachUserGroup = (
  users: GetFacilityUsersResponse["data"],
  optionalCustomInfo: GetOptionalCustomInfoResponse["data"],
  fetchOptionalCustomInfo: () => Promise<void>,
  fetchUsers: () => Promise<void>,
  fetchErrors: () => Promise<void>
): UseRecordEachUserGroupReturnType => {
  const [currentUsers, setCurrentUsers] = useState<
    GetFacilityUsersResponseElement[]
  >([]);
  const [targetPattern, setTargetPattern] = useState<PatternListType[]>(
    patternListFormat
  );
  const [expiredVisibility, setExpiredVisibility] = useState<boolean>(true);
  const [unitGroup, setUnitGroup] = useState<number>(
    Number(DEFAULT_UNIT_SELECT_BOX.value)
  );
  const [isZeroMatch, setIsZeroMatch] = useState<boolean>(false);
  const [facilityUnits, setFacilityUnits] = useState<
    GetOptionalCustomInfoResponse["data"]["facility_units"]
  >([]);
  const [filteredUnitGroups, setFilteredUnitGroups] = useState<number>(
    Number(DEFAULT_UNIT_SELECT_BOX.value)
  );
  const [searchConditionsIds, setSearchConditionsIds] = useState<number[]>([]);

  useEffect(() => {
    fetchOptionalCustomInfo();
    fetchUsers();
    fetchErrors();
  }, []);

  /**
   * userがfetchされたらセットする
   * 退所者の非表示：ONが初期値であるため、絞り込みも行う
   */
  useEffect(() => {
    if (!users.length) {
      setIsZeroMatch(true);
      return;
    }
    if (expiredVisibility) {
      let filteredUsers = users;
      // 退所者の非表示による絞り込み
      filteredUsers = filterActiveUsers(filteredUsers, expiredVisibility);
      setCurrentUsers(filteredUsers);
      setIsZeroMatch(filteredUsers.length === 0);
    }
  }, [users]);

  /**
   * unit情報がfetchされたらセットする
   */
  useEffect(() => {
    if (optionalCustomInfo.facility_units) {
      const filteredUnits = optionalCustomInfo.facility_units.filter(
        (v) => v.id
      );
      setFacilityUnits(filteredUnits);
    }
  }, [optionalCustomInfo.facility_units]);

  /**
   * ふりがな絞り込み処理
   * @param usersResElm ユーザー
   * return 絞り込み後のユーザーのID配列
   */
  const extractClickablePatternIds = useCallback(
    (usersResElm: GetFacilityUsersResponseElement[]) => {
      const arr: number[] = [];
      [...usersResElm].forEach((item) => {
        const existingPattern = patternListFormat.find((pattern) => {
          const checkKanaReg = new RegExp(`^${pattern.targetKana}.*$`);
          return checkKanaReg.test(item.kanaName);
        });
        if (existingPattern) {
          arr.push(existingPattern.id);
        }
      });
      return uniq(arr);
    },
    [users, patternListFormat, uniq]
  );

  /**
   * ふりがなの選択状態を変更する
   */
  useEffect(() => {
    const patternList = patternListFormat.map((item) => {
      let { disabled, checked } = item;
      const clickablePatternID = extractClickablePatternIds(users);
      if (!clickablePatternID.includes(item.id)) {
        disabled = true;
      } else if (searchConditionsIds.includes(item.id)) {
        checked = true;
      }
      return {
        ...item,
        disabled,
        checked
      };
    });
    setTargetPattern(patternList);
  }, [
    extractClickablePatternIds,
    users,
    patternListFormat,
    searchConditionsIds
  ]);

  /**
   * 絞り込みボタン押下時の処理
   * @param e イベント
   */
  const handleSearchUsers = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    const checkedPatterns = targetPattern.filter((item) => item.checked);
    const checkedPatternsIds = checkedPatterns.map((item) => item.id);
    setSearchConditionsIds(checkedPatternsIds);

    // フリガナでの絞り込み
    let filteredUsers = filterUsersByKana<GetFacilityUsersResponseElement>(
      users,
      checkedPatterns
    );

    // 退所者の非表示による絞り込み
    filteredUsers = filterActiveUsers(filteredUsers, expiredVisibility);

    // ユニット/グループによる絞り込み
    if (unitGroup !== Number(DEFAULT_UNIT_SELECT_BOX.value)) {
      const selectedUnit = optionalCustomInfo.facility_units.find(
        (v) => v.id === unitGroup
      );
      filteredUsers = selectedUnit
        ? selectedUnit.user_ids
            .map((uid) => {
              const index = filteredUsers.findIndex((v) => v.uif_id === uid);
              return filteredUsers[index];
            })
            .filter((user) => !isUndefined(user))
        : [];
    }

    if (optionalCustomInfo.facility_units) {
      setFacilityUnits(
        unitGroup !== Number(DEFAULT_UNIT_SELECT_BOX.value)
          ? optionalCustomInfo.facility_units.filter((v) => v.id === unitGroup)
          : optionalCustomInfo.facility_units.filter((v) => v.id)
      );
    }
    setCurrentUsers(filteredUsers);
    setIsZeroMatch(filteredUsers.length === 0);
    setFilteredUnitGroups(unitGroup);
  };

  /**
   * 絞り込みリセット用の関数
   */
  const handleClear = (): void => {
    const resetPattern = targetPattern.map((p) => ({
      ...p,
      checked: false
    }));
    setTargetPattern(resetPattern);
    setExpiredVisibility(true);
    setUnitGroup(Number(DEFAULT_UNIT_SELECT_BOX.value));
  };

  return {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    unitGroup,
    setUnitGroup,
    isZeroMatch,
    facilityUnits,
    filteredUnitGroups
  };
};

/**
 * 絞り込みFormと、利用者情報一覧をまとめる
 */
const RecordEachUserGroupCore = (props: Props): JSX.Element => {
  const {
    classes,
    users,
    errorState,
    buttonList,
    optionalCustomInfo,
    userState,
    fetchOptionalCustomInfo,
    fetchUsers,
    fetchErrors
  } = props;

  const {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    unitGroup,
    setUnitGroup,
    isZeroMatch,
    facilityUnits,
    filteredUnitGroups
  } = useRecordEachUserGroup(
    users,
    optionalCustomInfo,
    fetchOptionalCustomInfo,
    fetchUsers,
    fetchErrors
  );

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <SectionTitle label="利用者ごとの記録" isTitleNoMargin />
      <SearchUsersForm
        targetPattern={targetPattern}
        setTargetPattern={setTargetPattern}
        expiredVisibility={expiredVisibility}
        setExpiredVisibility={setExpiredVisibility}
        handleSearchUsers={handleSearchUsers}
        handleClear={handleClear}
        unitGroup={unitGroup}
        setUnitGroup={setUnitGroup}
        optionalCustomInfo={optionalCustomInfo}
        userState={userState}
      />

      {/* ゼロマッチ時・登録ユーザーなしの時のメッセージ */}
      {(isZeroMatch || (!users.length && !facilityUnits.length)) && (
        <RecordUserEmptyMessage />
      )}

      {/* ユニット/グループあり */}
      {!isZeroMatch &&
        facilityUnits.map((unit) => {
          return (
            <div key={unit.id}>
              {filteredUnitGroups === Number(DEFAULT_UNIT_SELECT_BOX.value) && (
                <RecordUserGroup
                  unitLabel={unit.unit_name}
                  userIdsNum={unit.user_ids.length}
                />
              )}
              <ul className={classes.unitUserList}>
                {unit.user_ids.map((id) => {
                  const user = currentUsers.find((v) => v && v.uif_id === id);
                  return (
                    user && (
                      <RecordUserRow
                        key={`row-unit-${user.uif_id}`}
                        errorState={errorState}
                        buttonList={buttonList}
                        user={user}
                      />
                    )
                  );
                })}
              </ul>
            </div>
          );
        })}

      {/* ユニット/グループなし */}
      {!isZeroMatch && !facilityUnits.length && currentUsers.length && (
        <ul className={classes.ul}>
          {currentUsers.map((user) => (
            <RecordUserRow
              key={`row-${user.uif_id}`}
              errorState={errorState}
              buttonList={buttonList}
              user={user}
            />
          ))}
        </ul>
      )}
    </Paper>
  );
};

export const RecordEachUserGroup = withStyles(styles)(RecordEachUserGroupCore);
