import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import operationsApi from "@api/requests/operations/Cseg";
import { csegSupportApi } from "@api/requests/supports/Cseg";
import {
  normalizePostSupportFormToApi,
  normalizePostOperationFormToApi
} from "@stores/domain/mgr/Cseg/operationAndSupports/normalizer";
import {
  SupportRecordFormValues,
  OperationRecordFormValues
} from "@initialize/mgr/Cseg/record/dailyRecord/initialValues";

export const fetchOperationsAndSupports = (dispatch: Dispatch) => async (
  yyyymmdd: string,
  page?: number,
  keyword?: string,
  isRecord?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchOperationsAndSupportsStarted());
  await operationsApi
    .getOperationsAndSupports(yyyymmdd, page, keyword, isRecord)
    .then((res) => {
      dispatch(actions.fetchOperationsAndSupportsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchOperationsAndSupportsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchOperation = (dispatch: Dispatch) => async (
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchOperationStarted());
  await operationsApi
    .getOperationsAndSupports(yyyymmdd)
    .then((res) => {
      dispatch(actions.fetchOperationSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchOperationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postSupport = (dispatch: Dispatch) => async (
  uidId: number,
  targetDate: string,
  formValues: SupportRecordFormValues,
  initialValues: SupportRecordFormValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportsStarted());

  const normalizeParam = normalizePostSupportFormToApi(
    uidId,
    targetDate,
    formValues,
    initialValues
  );
  await csegSupportApi
    .postSupportRecordUser(normalizeParam, uidId.toString())
    .then(() => {
      dispatch(actions.postSupportsSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postSupportsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.data.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postOperation = (dispatch: Dispatch) => async (
  targetDate: string,
  formValues: OperationRecordFormValues,
  initialValues: OperationRecordFormValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postOperationStarted());

  const normalizedParam = normalizePostOperationFormToApi(
    formValues,
    initialValues
  );
  await operationsApi
    .postOperations(normalizedParam, targetDate)
    .then(() => {
      dispatch(actions.postOperationSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postOperationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.data.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchOperationsAndSupports: ReturnType<typeof fetchOperationsAndSupports>;
  fetchOperation: ReturnType<typeof fetchOperation>;
  postSupport: ReturnType<typeof postSupport>;
  postOperation: ReturnType<typeof postOperation>;
};

export const CsegOperationsAndSupportsDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchOperationsAndSupports: fetchOperationsAndSupports(dispatch),
  fetchOperation: fetchOperation(dispatch),
  postSupport: postSupport(dispatch),
  postOperation: postOperation(dispatch)
});
