import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryFormDaily } from "@components/organisms/record/serviceDelivery/ServiceDeliveryFormDaily";
import { OptionInterface } from "@components/atoms/DropDown";
// utils
import { dateTodayInFormat } from "@utils/date";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { FacilityType } from "@constants/variables";

type StateProps = {
  userState: UserState;
};

type DispatchProps = {
  fetchFacilityUserListKYOTAKUKAIGO: (date: Date) => void;
  fetchFacilityUserListJUDOHOMONKAIGO: (date: Date) => void;
  fetchFacilityUserListDOKOENGO: (date: Date) => void;
  fetchFacilityUserListKODOENGO: (date: Date) => void;
  fetchFacilityUserListIDOSHIEN: (date: Date) => void;
  fetchCustomRecords: () => Promise<void>;
  fetchStaffs: () => void;
};

type OwnProps = RouteComponentProps<{
  date: string;
}>;

type Props = OwnProps &
  StateProps &
  DispatchProps & {
    staffOptions: FieldItem[];
    userListOption: OptionInterface[];
  };

/**
 * サービス提供記録（新規作成）
 */
export const ServiceDeliveryNewCore = (props: Props): JSX.Element => {
  const { date } = props.match.params;
  const pageName = `サービス提供記録${"　"}${dateTodayInFormat(date, true)}`;

  // fetch
  React.useEffect(() => {
    if (props.userState.facility_type === FacilityType.KYOTAKUKAIGO) {
      props.fetchFacilityUserListKYOTAKUKAIGO(
        new Date(fixDateAndTimeFormat(date))
      );
    } else if (props.userState.facility_type === FacilityType.DOKOENGO) {
      props.fetchFacilityUserListDOKOENGO(new Date(fixDateAndTimeFormat(date)));
    } else if (props.userState.facility_type === FacilityType.KODOENGO) {
      props.fetchFacilityUserListKODOENGO(new Date(fixDateAndTimeFormat(date)));
    } else if (props.userState.facility_type === FacilityType.IDOSHIEN) {
      props.fetchFacilityUserListIDOSHIEN(new Date(fixDateAndTimeFormat(date)));
    } else {
      props.fetchFacilityUserListJUDOHOMONKAIGO(
        new Date(fixDateAndTimeFormat(date))
      );
    }
    props.fetchCustomRecords();
    props.fetchStaffs();
  }, []);

  return (
    <AdminTemplate pageName={pageName}>
      <ServiceDeliveryFormDaily
        date={new Date(fixDateAndTimeFormat(date))}
        targetDate={date}
        isEdit={false}
        history={props.history}
        facilityType={props.userState.facility_type}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userState: state.user as UserState
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    KYOTAKUKAIGO,
    JUDOHOMONKAIGO,
    DOKOENGO,
    KODOENGO,
    IDOSHIEN,
    customRecordsWithCategory,
    staffDispatcher
  } = dispatches;
  const userInFacilityDispatcherKYOTAKUKAIGO = KYOTAKUKAIGO.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatcherJUDOHOMONKAIGO = JUDOHOMONKAIGO.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatcherDOKOENGO = DOKOENGO.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatcherKODOENGO = KODOENGO.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatcherIDOSHIEN = IDOSHIEN.userInFacilityDispatcher(
    dispatch
  );
  const staffDispatches = staffDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  return {
    fetchFacilityUserListKYOTAKUKAIGO: (date: Date): Promise<void> =>
      userInFacilityDispatcherKYOTAKUKAIGO.fetchTargetDay(date),
    fetchFacilityUserListJUDOHOMONKAIGO: (date: Date): Promise<void> =>
      userInFacilityDispatcherJUDOHOMONKAIGO.fetchTargetDay(date),
    fetchFacilityUserListDOKOENGO: (date: Date): Promise<void> =>
      userInFacilityDispatcherDOKOENGO.fetchTargetDay(date),
    fetchFacilityUserListKODOENGO: (date: Date): Promise<void> =>
      userInFacilityDispatcherKODOENGO.fetchTargetDay(date),
    fetchFacilityUserListIDOSHIEN: (date: Date): Promise<void> =>
      userInFacilityDispatcherIDOSHIEN.fetchTargetDay(date),
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    }
  };
};

export const ServiceDeliveryNewDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryNewCore);
