import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { HashRouter, Switch, Route, Redirect } from "react-router-dom";
import AuthRoutes from "@app/Routes/AuthRoutes";
import GuestRoutes from "@app/Routes/GuestRoutes";
import TemporaryRoutes from "@app/Routes/TemporaryRoutes";
import ScrollToTop from "@app/ScrollToTop";
import ResetReminder from "@components/pages/auth/password/ResetReminder";
import ResetReminderComplete from "@components/pages/auth/password/ResetReminderComplete";
import { NewUser } from "@components/pages/account/NewUser";
import AccountNewUserComplete from "@components/pages/account/NewUserComplete";
import { AppState } from "@stores/type";
import { ResponseError } from "@stores/ui/type";

type StateProps = {
  isLoggedIn: boolean;
  isCheckedLoggedIn: boolean;
  isTemporaryLoggedIn: boolean;
  isDoneMe: boolean;
  responseError: ResponseError;
};

type DispatchProps = {
  checkLogin(): Promise<void>;
  fetchUser(): void;
  logout(): void;
  responseErrorClear(): void;
};

type MergeProps = StateProps &
  DispatchProps & {
    isLogin: boolean;
    isLogout: boolean;
  };

const getUserConfirmation = (
  dialogKey: string,
  callback: (isTransPage: boolean) => void
): void => {
  const dialogTrigger = window[Symbol.for(dialogKey)];
  return dialogTrigger ? dialogTrigger(callback) : callback(true);
};

const NavigationGuard = (props: MergeProps): JSX.Element | null => {
  React.useEffect((): void => {
    // 初回はcheckLoginが動くがスコープ内propsに反映されない為null描画
    // すぐに2周目の描画が開始, ここでfetchUserが呼ばれる
    // fetchUser完了後(3回目以降)はチェック抜けるだけ
    if (!props.isCheckedLoggedIn) {
      props.checkLogin();
      return;
    }
    if (props.isCheckedLoggedIn && props.isLoggedIn && !props.isDoneMe) {
      props.fetchUser();
    }
  }, [props.isCheckedLoggedIn]);

  React.useEffect((): void => {
    if (!props.responseError) return;
    switch (props.responseError.status) {
      case 400:
      case 401:
        // 仮ログイン中userが必ずエラーを返す影響で例外処理を入れている
        if (window.location.hash !== "#/login" && props.isLogin) {
          // メニューからログアウトした時とstateが同じになるよう注意
          props.responseErrorClear();
          props.logout();
        }
        break;
      default:
    }
  }, [props.responseError]);

  if (!props.isCheckedLoggedIn) return null;
  return (
    <HashRouter getUserConfirmation={getUserConfirmation}>
      <ScrollToTop>
        <Switch>
          <Route
            path="/password/resetreminder/complete"
            component={ResetReminderComplete}
          />
          <Route path="/password/resetreminder" component={ResetReminder} />
          <Route
            path="/account/user/new/complete"
            component={AccountNewUserComplete}
            exact
          />
          <Route path="/account/user/new" component={NewUser} exact />
          {props.isLogout && <GuestRoutes />}
          {props.isLogin && <AuthRoutes />}
          {props.isTemporaryLoggedIn && <TemporaryRoutes />}
          {/* 切り替え時用に一時的なリダイレクトループをさせる */}
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </ScrollToTop>
    </HashRouter>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  isLoggedIn: state.auth.isLoggedIn,
  isCheckedLoggedIn: state.auth.isChecked,
  isTemporaryLoggedIn: state.auth.isTemporary,
  isDoneMe: state.user.done,
  responseError: state.ui.responseError
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch, userDispatch, uiDispatch } = dispatches;
  return {
    checkLogin: async (): Promise<void> => authDispatch(dispatch).checkLogin(),
    fetchUser: (): Promise<void> => userDispatch(dispatch).me(),
    logout: authDispatch(dispatch).logout,
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps
): MergeProps => {
  const isLogin = !stateProps.isTemporaryLoggedIn && stateProps.isLoggedIn;
  const isLogout = !stateProps.isTemporaryLoggedIn && !stateProps.isLoggedIn;
  return {
    isLogin,
    isLogout,
    ...stateProps,
    ...dispatchProps
  };
};

export const NavigationRoot = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(NavigationGuard);
