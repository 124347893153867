import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import { Formik, Form, FormikActions } from "formik";
import isEqual from "lodash-es/isEqual";
import { initialValues } from "@initialize/mgr/KEIKAKUSODAN/initial/initialValues";
import { InitialValues } from "@interfaces/mgr/KEIKAKUSODAN/initial/initialData";
import { validation } from "@initialize/mgr/KEIKAKUSODAN/initial/validation";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import FormPaper from "@components/atoms/FormPaper";
import { FirstInvoiceDataFields } from "@components/organisms/mgr/KEIKAKUSODAN/initial/FirstInvoiceDataFields";
import { PastUsageFields } from "@components/organisms/mgr/KEIKAKUSODAN/initial/PastUsageFields";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { InitialState } from "@stores/domain/mgr/KEIKAKUSODAN/initial/types";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import { StyleRules } from "@material-ui/core/styles";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      height: spacing.unit * 8,
      top: 0
    },
    errorIcon: {
      color: "#0277bd"
    },
    description: {
      marginTop: 6,
      marginLeft: 16,
      marginBottom: 0,
      color: "#666666"
    },
    descriptionErrorIcon: {
      float: "left",
      height: 24
    },
    descriptionTitle: {
      marginLeft: 32,
      verticalAlign: "top",
      color: "#37474f"
    },
    caution: {
      color: "#f44336"
    }
  });

type DispatchProps = {
  fetchInitialData: () => void;
  postInitialData: (values: InitialValues, initialState: InitialState) => void;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  initialData: InitialState;
  needsStopHistory: boolean;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const EditInitialFormCore = (props: Props): JSX.Element => {
  const [initialDataValues, setInitialDataValues] = React.useState<
    InitialValues
  >(initialValues());

  React.useEffect(() => {
    (async (): Promise<void> => {
      await props.fetchInitialData();
    })();
  }, []);

  React.useEffect(() => {
    setInitialDataValues(initialValues(props.initialData));
  }, [props.initialData]);

  const confirmDiscardFormChanges = (nextValues: InitialValues): void => {
    const hasChange = !isEqual(nextValues, initialDataValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: InitialValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const onSubmit = async (
    values: InitialValues,
    actions: FormikActions<InitialValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postInitialData(values, props.initialData);
    actions.setSubmitting(false);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  return (
    <Formik
      initialValues={initialDataValues}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <ContentHeader
            position="sticky"
            classes={{ wrapper: props.classes.wrapper }}
          >
            <ContentHeaderRight>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                errorAction={submitError}
              />
            </ContentHeaderRight>
          </ContentHeader>
          <FormPaper>
            <div>
              <div className={props.classes.descriptionErrorIcon}>
                <ErrorIcon className={props.classes.errorIcon} />
              </div>
              <div className={props.classes.descriptionTitle}>
                初期データについて
              </div>
            </div>
            <p className={props.classes.description}>
              月初の給付費請求作業を正しく行うには、請求額算出の前提となる以下のデータを登録する必要があります。
              <br />
              必ず
              <span className={props.classes.caution}>
                初回の請求作業の実施前に登録を完了
              </span>
              させてください。
            </p>
          </FormPaper>
          {/* 初回請求月の指定 */}
          <FirstInvoiceDataFields />
          {/* 過去６カ月間の実績 */}
          <PastUsageFields />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  initialData: state.KEIKAKUSODAN.initial,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, KEIKAKUSODAN } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const initialDataDispatcher = KEIKAKUSODAN.initialDataDispatcher(dispatch);

  return {
    fetchInitialData: initialDataDispatcher.fetch,
    postInitialData: (
      values: InitialValues,
      initialState: InitialState
    ): Promise<void> => initialDataDispatcher.post(values, initialState),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const EditInitialForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(EditInitialFormCore));
