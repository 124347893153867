import { BasicValues } from "@interfaces/v202104/mgr/JUDOHOMONKAIGO/facility/basic";
import { AdditionalItemValues } from "@interfaces/v202104/mgr/JUDOHOMONKAIGO/facility/additionItem";
import { FacilityState } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/facility/types";

export type FacilityValues = BasicValues & AdditionalItemValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : ""
    },
    additionalItem: {
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      specificFacilitiesAddition: state
        ? state.specificFacilitiesAddition
        : "0",
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false,
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false
    }
  };
};
