import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";
import * as URL from "@constants/url";

// 利用実績
import ReportDaily from "@components/pages/mgr/IAB/report/Daily";
import ReportMonthly from "@components/pages/mgr/IAB/report/Monthly";
import ReportDaily201910 from "@components/v201910/pages/mgr/IAB/report/Daily";
import ReportMonthly201910 from "@components/v201910/pages/mgr/IAB/report/Monthly";
import { ReportDaily202104 } from "@components/v202104/pages/mgr/IAB/report/Daily";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/IAB/report/Monthly";

// 利用者情報
import UsersList from "@components/pages/mgr/IAB/users/UsersList";
import { CreateUser } from "@components/pages/mgr/IAB/users/CreateUser";
import { EditUser } from "@components/pages/mgr/IAB/users/EditUser";
import CreateUser201910 from "@components/v201910/pages/mgr/IAB/users/CreateUser";
import EditUser201910 from "@components/v201910/pages/mgr/IAB/users/EditUser";
import { EditUser202104 } from "@components/v202104/pages/mgr/IAB/users/EditUser";

// 事業所情報
import Facility from "@components/pages/mgr/IAB/Facility";
import Facility201910 from "@components/v201910/pages/mgr/IAB/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/IAB/Facility";

// 初期設定情報
import InitialData from "@components/pages/mgr/IAB/InitialData";

// 記録機能
import RecordRoutes from "@app/CommonRoutes/RecordRoutes";
import DailyRecord from "@components/pages/mgr/IAB/record/Daily";
import OperationsRecord from "@components/pages/mgr/IAB/record/Operations";
import UserDetail from "@components/pages/mgr/IAB/record/UserDetail";
import { UserDetailA } from "@components/pages/mgr/A/record/UserDetailA";
import SupportPlan from "@components/pages/mgr/IAB/record/SupportPlan";
import SupportPlanNew from "@components/pages/mgr/IAB/record/SupportPlanNew";
import SupportPlanList from "@components/pages/mgr/IAB/record/SupportPlanList";
import SupportPlanA from "@components/pages/mgr/A/record/SupportPlanA";
import SupportPlanANew from "@components/pages/mgr/A/record/SupportPlanANew";
import { SupportPlanAList } from "@components/pages/mgr/A/record/SupportPlanAList";
import { RecordUsersSummary } from "@components/pages/mgr/IAB/record/UsersSummary";
import { AssessmentList } from "@components/pages/mgr/IAB/record/assessment/AssessmentList";
import { Assessment } from "@components/pages/mgr/IAB/record/assessment/Assessment";
import { AssessmentRecordPrint } from "@components/pages/mgr/IAB/record/print/AssessmentPrint";
import OffsiteWork from "@components/pages/mgr/IAB/record/OffsiteWork";
import WorkplaceCompanyEdit from "@components/pages/mgr/IAB/record/WorkplaceCompanyEdit";
import OffsiteWorkPrint from "@components/pages/record/print/OffsiteWorkPrint";

// 作業時間
import WorkRecords from "@components/pages/mgr/IAB/WorkRecords";
import { Evaluation } from "@components/pages/mgr/IAB/record/Evaluation";
import { EvaluationA } from "@components/pages/mgr/A/record/EvaluationA";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/assessment/setting/AssessmentSetting";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 移行/A型/B型施設でログインした時だけ有効になるルーティング
 */
const IABRoutes: React.FunctionComponent<Props> = ({ user }) => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute
      path={URL.REPORT_MONTHLY_202104}
      component={ReportMonthly202104}
    />
    <AdminRoute path={URL.REPORT_DAILY_201910} component={ReportDaily201910} />
    <AdminRoute
      path={URL.REPORT_MONTHLY_201910}
      component={ReportMonthly201910}
    />
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_MONTHLY} component={ReportMonthly} />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path="/v201910/users/new" component={CreateUser201910} />
    <AdminRoute exact path="/v201910/users/:id" component={EditUser201910} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    <AdminRoute exact path="/users/new" component={CreateUser} />
    <AdminRoute exact path="/users/:id" component={EditUser} />
    {/* 事業所情報 */}
    <AdminRoute path="/v201910/facility" component={Facility201910} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    <AdminRoute path="/facility" component={Facility} />
    {/* 初期設定情報 */}
    <AdminRoute path="/initial" component={InitialData} />
    {/* 作業時間 */}
    <AdminRoute path="/work-records" component={WorkRecords} />
    {/* 記録機能 */}
    {RecordRoutes}
    <AdminRoute exact path="/record/daily/:yyyymmdd?" component={DailyRecord} />
    <AdminRoute exact path="/record/operations" component={OperationsRecord} />
    <AdminRoute
      exact
      path="/record/operations/:year/:month"
      component={OperationsRecord}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/new"
      component={
        user.facility_type !== FacilityType.A ? SupportPlanNew : SupportPlanANew
      }
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId/copy"
      component={
        user.facility_type !== FacilityType.A ? SupportPlanNew : SupportPlanANew
      }
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId"
      component={
        user.facility_type !== FacilityType.A ? SupportPlan : SupportPlanA
      }
    />
    {user.facility_type === FacilityType.A && (
      <AdminRoute
        exact
        path="/record/:uifId/support_plan/evaluation/:supportPlanId"
        component={EvaluationA}
      />
    )}
    {(user.facility_type === FacilityType.B ||
      user.facility_type === FacilityType.IKOU) && (
      <AdminRoute
        exact
        path="/record/:uifId/support_plan/evaluation/:supportPlanId"
        component={Evaluation}
      />
    )}
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support|work|interview)/:year?/:month?"
      component={
        user.facility_type !== FacilityType.A ? UserDetail : UserDetailA
      }
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support_plan)/:year?/:month?"
      component={
        user.facility_type !== FacilityType.A
          ? SupportPlanList
          : SupportPlanAList
      }
    />
    <AdminRoute
      exact
      path="/record/users_summary"
      component={RecordUsersSummary}
    />
    {/* アセスメントシート */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute exact path="/record/offsite-work" component={OffsiteWork} />
    <AdminRoute
      exact
      path="/record/offsite-work/workplace_company/new"
      component={WorkplaceCompanyEdit}
    />
    <AdminRoute
      exact
      path="/record/offsite-work/workplace_company/:id"
      component={WorkplaceCompanyEdit}
    />
    <AdminRoute
      exact
      path="/record/print/offsite-work/:year/:month"
      component={OffsiteWorkPrint}
    />
    {/* アセスメントの設定 */}
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? "/attendance" : "/report/daily"}
      />
    </Route>
  </Switch>
);

export default IABRoutes;
