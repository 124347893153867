import React, { useEffect, useState } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { connect } from "react-redux";

// ui
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CustomRecordsDialogSubmit } from "@components/organisms/assessment/setting/dialog/CustomRecordsDialogSubmit";
import { CustomRecordsDialogContent } from "@components/organisms/assessment/setting/dialog/CutomRecordsDialogContent";

// formik
import { Formik, FormikActions } from "formik";
import { CustomRecordsValues } from "@initialize/record/customRecordWithCategory/initialValues";

const styles = (): StyleRules =>
  createStyles({
    modal: {
      maxHeight: "80%",
      width: 776,
      maxWidth: 776
    },
    form: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    modalHeaderArea: {
      padding: "16px 32px 18px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: "20px",
      color: "#37474f",
      lineHeight: "24px",
      backgroundColor: "#f5f5f5"
    },
    modalHeader: {
      display: "flex"
    },
    modalContents: {
      padding: "24px 0 12px 0",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    action: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      margin: 0,
      paddingRight: 4,
      paddingBottom: 4
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  modalType: "" | "category" | "item";
  closeModal: () => void;
  formValues: CustomRecordsValues;
  fetchCustomRecords: () => Promise<void>;
};

type DispatchProps = {
  postCustomRecords: (formValue: CustomRecordsValues) => Promise<void>;
  postCustomRecordsCategory: (formValue: CustomRecordsValues) => Promise<void>;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

const CustomRecordsDialogCore = (props: Props): JSX.Element => {
  const {
    classes,
    modalType,
    isModalOpen,
    closeModal,
    formValues,
    fetchCustomRecords,
    postCustomRecords,
    postCustomRecordsCategory
  } = props;

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {
    setSubmitButtonDisabled(formValues.name === "");
  }, []);

  const onSubmit = async (
    values: CustomRecordsValues,
    actions: FormikActions<CustomRecordsValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    const postAPI =
      modalType === "category" ? postCustomRecordsCategory : postCustomRecords;
    await postAPI(values)
      .then(() => {
        fetchCustomRecords();
      })
      .finally(() => {
        closeModal();
        actions.setSubmitting(false);
      });
  };
  const typeLabel = modalType === "category" ? "カテゴリー" : "項目";

  return (
    <Dialog fullWidth open={isModalOpen} classes={{ paper: classes.modal }}>
      <Formik initialValues={formValues} onSubmit={onSubmit}>
        {(formikProps): JSX.Element => (
          <div className={classes.form}>
            <DialogTitle
              classes={{ root: classes.modalHeaderArea }}
              disableTypography
            >
              <div className={classes.modalHeader}>
                {`新規${typeLabel}の作成`}
              </div>
            </DialogTitle>
            <DialogContent className={classes.modalContents}>
              <CustomRecordsDialogContent
                typeLabel={typeLabel}
                setSubmitButtonDisabled={setSubmitButtonDisabled}
              />
            </DialogContent>
            <DialogActions className={classes.action}>
              <CustomRecordsDialogSubmit
                formikProps={formikProps}
                onCancel={closeModal}
                disabled={submitButtonDisabled}
              />
            </DialogActions>
          </div>
        )}
      </Formik>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecords(formValue);
    },
    postCustomRecordsCategory: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsCategory(formValue);
    }
  };
};

export const CustomRecordsDialog = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CustomRecordsDialogCore));
