import * as React from "react";

import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    arrow: {
      height: "0px",
      position: "relative",
      boxSizing: "border-box"
    },
    after: {
      width: 0,
      height: 0,
      borderStyle: "solid",
      position: "absolute"
    }
  });

type OwnProps = {
  height?: number;
  width?: number;
  color?: string;
  direction?: "right" | "left" | "top" | "bottom";
};

type Props = OwnProps & WithStyles<typeof styles>;

// 印刷プレビュー用右向き矢印
const ArrowIcon = (props: Props): JSX.Element => {
  const {
    classes,
    height = 10,
    width = 20,
    color = "#212121",
    direction = "right"
  } = props;

  const directionValue = (d: "right" | "left" | "top" | "bottom"): number => {
    switch (d) {
      case "right":
        return 0;
      case "left":
        return 180;
      case "top":
        return 270;
      case "bottom":
        return 90;
      default:
        return 0;
    }
  };

  const arrowStyle = {
    width: `${width - height / 2}px`,
    border: `${height / 4}px solid ${color}`,
    transform: `rotate(-${directionValue(direction)}deg)`
  };
  const afterStyle = {
    borderWidth: `${height / 2}px 0 ${height / 2}px ${height / 2}px`,
    borderColor: `transparent transparent transparent ${color}`,
    top: `calc(-50% - ${height / 2}px)`,
    left: `calc(100% + ${height / 4}px)`
  };

  return (
    <div className={classes.arrow} style={arrowStyle}>
      <div className={classes.after} style={afterStyle} />
    </div>
  );
};

export default withStyles(styles)(ArrowIcon);
