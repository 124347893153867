import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

// components
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";

// utils
import { Assessment } from "@initialize/record/assessment/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

import {
  FacilityType,
  IKOU_ASSESSMENT_CATEGORY_TYPE,
  IKOU_ASSESSMENT_DEFAULT_ITEM
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    area: {
      display: "flex",
      marginTop: "32px",
      gap: "32px",
      flexDirection: "column"
    }
  });

type OwnProps = {
  formValues: Assessment;
  customRecords: CustomRecordsWithCategoryState;
  facilityType: FacilityType;
  isEditable: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentSheetFormEvaluationPeriodCore = (props: Props): JSX.Element => {
  const categoryType = IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period;
  const categoryIndex = props.customRecords.findIndex((v) => {
    return v.category_type === categoryType;
  });

  const itemId = IKOU_ASSESSMENT_DEFAULT_ITEM.evaluationPeriod;
  const itemIndex =
    categoryIndex !== -1
      ? props.customRecords[categoryIndex].custom_record_items.findIndex(
          (v) => v.default_item === itemId
        )
      : -1;

  const evaluationPeriod =
    props.formValues.assessment_records[categoryIndex].input[itemIndex]
      .evaluation_period;

  const startValue = React.useMemo(() => {
    if (!evaluationPeriod) return { year: "", month: "", day: "" };
    return evaluationPeriod.start.date || { year: "", month: "", day: "" };
  }, [evaluationPeriod]);

  const endValue = React.useMemo(() => {
    if (!evaluationPeriod) return { year: "", month: "", day: "" };
    return evaluationPeriod.end.date || { year: "", month: "", day: "" };
  }, [evaluationPeriod]);

  return (
    <div className={props.classes.area}>
      <RecordSelectDate
        name={`assessment_records[${categoryIndex}].input[${itemIndex}].evaluation_period.start.date`}
        label="開始年月日"
        value={startValue}
        isEditable={props.isEditable}
        addYearTo={1}
        overrideYearFrom={1989}
      />
      <RecordSelectDate
        name={`assessment_records[${categoryIndex}].input[${itemIndex}].evaluation_period.end.date`}
        label="終了年月日"
        value={endValue}
        isEditable={props.isEditable}
        addYearTo={1}
        overrideYearFrom={1989}
      />
    </div>
  );
};

export const AssessmentSheetFormEvaluationPeriod = withStyles(styles)(
  AssessmentSheetFormEvaluationPeriodCore
);
