import { RequestParamToPostAPI } from "@api/requests/inOutResults/postInOutResults";
import { RequestParamToDeleteAPI } from "@api/requests/inOutResults/deleteInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/inOutResults/postInOutResultsCopy";
import { RequestParamToPostCopyServiceDeliveryAPI } from "@api/requests/inOutResults/postInOutResultsCopyServiceDelivery";
import * as types from "@stores/domain/mgr/IDOSHIEN/report/types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchIDOSHIENDailyStarted = () =>
  ({ type: types.FETCH_IDOSHIEN_DAILY_STARTED } as const);
export const fetchIDOSHIENDaily = (res: types.ReportState["reportDaily"]) =>
  ({ type: types.FETCH_IDOSHIEN_DAILY, payload: res } as const);
export const fetchIDOSHIENDailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_IDOSHIEN_DAILY_FAILED, error: err } as const);
export const fetchIDOSHIENUserStarted = () =>
  ({ type: types.FETCH_IDOSHIEN_MONTHLY_STARTED } as const);
export const fetchIDOSHIENUser = (res: types.ReportState["reportMonthly"]) =>
  ({ type: types.FETCH_IDOSHIEN_MONTHLY, payload: res } as const);
export const fetchIDOSHIENUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_IDOSHIEN_MONTHLY_FAILED, error: err } as const);

export const postIDOSHIENReportStarted = () =>
  ({ type: types.POST_IDOSHIEN_REPORT_STARTED } as const);
export const postIDOSHIENReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_IDOSHIEN_REPORT_DAILY, payload: req } as const);
export const postIDOSHIENReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_IDOSHIEN_REPORT_MONTHLY, payload: req } as const);
export const postIDOSHIENReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_IDOSHIEN_REPORT_FAILED, error: err } as const);

export const postIDOSHIENReportCopyStarted = () =>
  ({ type: types.POST_IDOSHIEN_REPORT_COPY_STARTED } as const);
export const postIDOSHIENReportCopy = (req: RequestParamToPostCopyAPI) =>
  ({ type: types.POST_IDOSHIEN_REPORT_COPY, payload: req } as const);
export const postIDOSHIENReportCopyFailed = (err: ErrorResponse) =>
  ({ type: types.POST_IDOSHIEN_REPORT_COPY_FAILED, error: err } as const);

export const postIDOSHIENReportCopyServiceDeliveryStarted = () =>
  ({ type: types.POST_IDOSHIEN_REPORT_COPY_CARE_PLAN_STARTED } as const);
export const postIDOSHIENReportCopyServiceDelivery = (
  req: RequestParamToPostCopyServiceDeliveryAPI
) =>
  ({
    type: types.POST_IDOSHIEN_REPORT_COPY_CARE_PLAN,
    payload: req
  } as const);
export const postIDOSHIENReportCopyServiceDeliveryFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_IDOSHIEN_REPORT_COPY_CARE_PLAN_FAILED,
    error: err
  } as const);

export const deleteIDOSHIENReportStarted = () =>
  ({ type: types.DELETE_IDOSHIEN_REPORT_STARTED } as const);
export const deleteIDOSHIENReport = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_IDOSHIEN_REPORT, payload: req } as const);
export const deleteIDOSHIENReportFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_IDOSHIEN_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchIDOSHIENDailyStarted>
  | ReturnType<typeof fetchIDOSHIENDaily>
  | ReturnType<typeof fetchIDOSHIENDailyFailed>
  | ReturnType<typeof fetchIDOSHIENUserStarted>
  | ReturnType<typeof fetchIDOSHIENUser>
  | ReturnType<typeof fetchIDOSHIENUserFailed>
  | ReturnType<typeof postIDOSHIENReportStarted>
  | ReturnType<typeof postIDOSHIENReportDaily>
  | ReturnType<typeof postIDOSHIENReportUser>
  | ReturnType<typeof postIDOSHIENReportFailed>
  | ReturnType<typeof postIDOSHIENReportCopyStarted>
  | ReturnType<typeof postIDOSHIENReportCopy>
  | ReturnType<typeof postIDOSHIENReportCopyFailed>
  | ReturnType<typeof postIDOSHIENReportCopyServiceDeliveryStarted>
  | ReturnType<typeof postIDOSHIENReportCopyServiceDelivery>
  | ReturnType<typeof postIDOSHIENReportCopyServiceDeliveryFailed>
  | ReturnType<typeof deleteIDOSHIENReportStarted>
  | ReturnType<typeof deleteIDOSHIENReport>
  | ReturnType<typeof deleteIDOSHIENReportFailed>;
