import * as React from "react";
import { BASE_PUBLIC_URL } from "@config";
import { Route, Switch, Redirect } from "react-router-dom";
import { Login } from "@components/pages/auth/Login";
import { Reminder } from "@components/pages/auth/password/Reminder";
import ReminderComplete from "@components/pages/auth/password/ReminderComplete";

const GuestRoutes: React.FunctionComponent = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <Route path="/password/reminder/complete" component={ReminderComplete} />
    <Route path="/password/reminder" component={Reminder} />
    {/* default path */}
    <Route path="/" exact>
      <Redirect to="/login" />
    </Route>
    <Route
      // 未ログインにて404発生時に一度ブラウザのリロードにて root へ遷移させる
      // root遷移後に `path="/" exact` にて `/login` へ遷移する
      // ※最新のjs(bundle)を取得するため
      path="/"
      // eslint-disable-next-line react/no-children-prop
      children={(): JSX.Element => {
        window.location.assign(BASE_PUBLIC_URL);
        return <></>;
      }}
    />
  </Switch>
);

export default GuestRoutes;
