const castNumberOrNull = (
  value: string | number | null | undefined
): number | null => {
  if (value === undefined || value === null) {
    return null;
  }
  return Number(value);
};

export default castNumberOrNull;
