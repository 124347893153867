/**
 * YYYYMMDD or YYYYMM の日付文字列をDateに変換する
 * @param dateString 対象の日付
 * @returns 有効な日付であればDateオブジェクト、無効な場合はnull
 */
export const parseDate = (dateString: string | undefined): Date | null => {
  let year: string;
  let month: string;
  let day: string;

  if (dateString === undefined) {
    return null;
  }

  if (dateString.length === 8) {
    year = dateString.slice(0, 4);
    month = dateString.slice(4, 6);
    day = dateString.slice(6, 8);
  } else if (dateString.length === 6) {
    year = dateString.slice(0, 4);
    month = dateString.slice(4, 6);
    day = "01"; // 6桁の場合は日を1日として扱う
  } else {
    return null;
  }

  const date = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    parseInt(day, 10)
  );
  return Number.isNaN(date.getTime()) ? null : date;
};
