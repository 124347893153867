import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { inOutResults } from "@api/requests/inOutResults/";

import * as loadingActions from "@stores/loading/actions";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import * as actions from "@stores/domain/mgr/IDOSHIEN/report/actions";
import {
  normalizeIDOSHIENDailyReportDataFromAPI,
  normalizeRequestParamToPostCopyAPI,
  normalizeRequestParamToDeleteAPI,
  normalizeIDOSHIENUserReportDataFromAPI,
  normalizeRequestParamToPostCopyServiceDeliveryAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI
} from "@stores/domain/mgr/IDOSHIEN/report/normalizer";
import {
  CopyReportState,
  DeleteReportState,
  IDOSHIENReportTypeInterface,
  REPEAT_DAILY,
  MunicipalityData,
  DisplayStaffsInFacilityState
} from "@stores/domain/mgr/IDOSHIEN/report/types";

import {
  INOUT_RESULTS_COPY_ERROR_STATUS,
  INOUT_RESULTS_ERROR_DUPLICATED
} from "@constants/mgr/IDOSHIEN/variables";
import { InitialValues } from "@interfaces/mgr/IDOSHIEN/report/initial";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 * @param dispatch
 */
const fetchIDOSHIENDaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchIDOSHIENDailyStarted());
  await inOutResults
    .getInOutResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchIDOSHIENDaily(
          normalizeIDOSHIENDailyReportDataFromAPI(
            response.data,
            dateInYYYYMMDDFormat(date)
          )
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchIDOSHIENDailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(ユーザごと)のデータ取得及びstore格納
 * @param dispatch
 */
const fetchIDOSHIENUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchIDOSHIENUserStarted());
  await inOutResults
    .getInOutResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchIDOSHIENUser(
          normalizeIDOSHIENUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchIDOSHIENUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 * @param dispatch
 */
const postIDOSHIENReport = (dispatch: Dispatch) => async (
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: IDOSHIENReportTypeInterface["type"],
  municipality: MunicipalityData,
  staffList: DisplayStaffsInFacilityState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postIDOSHIENReportStarted());
  const { usersInFacilityId } = beforeFormValue.initial;
  const { targetDate } = beforeFormValue.initial;

  const before = normalizeRequestParamToPostAPI(
    beforeFormValue,
    targetDate,
    usersInFacilityId,
    municipality,
    staffList
  );
  const after = normalizeRequestParamToPostAPI(
    afterFormValue,
    targetDate,
    usersInFacilityId,
    municipality,
    staffList
  );

  const requestParam = normalizeDiffParamToPostAPI(
    afterFormValue.initial.status,
    before,
    after,
    municipality
  );
  await inOutResults
    .postInOutResults(requestParam)
    .then(() => {
      const actionType =
        type === REPEAT_DAILY
          ? actions.postIDOSHIENReportDaily(requestParam)
          : actions.postIDOSHIENReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postIDOSHIENReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // 3人同時エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.error !== INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用計画→実績へのコピー
 * @param dispatch
 */
const postIDOSHIENReportCopy = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postIDOSHIENReportCopyStarted());

  const requestParam = normalizeRequestParamToPostCopyAPI(processType, target);

  await inOutResults
    .postInOutResultsCopy(requestParam)
    .then(() => {
      dispatch(actions.postIDOSHIENReportCopy(requestParam));
    })
    .catch((e) => {
      dispatch(actions.postIDOSHIENReportCopyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で実績登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * サービス提供記録→利用計画へのコピー
 * @param dispatch
 */
const postIDOSHIENReportCopyServiceDelivery = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postIDOSHIENReportCopyServiceDeliveryStarted());

  const requestParam = normalizeRequestParamToPostCopyServiceDeliveryAPI(
    processType,
    target
  );

  await inOutResults
    .postInOutResultsCopyServiceDelivery(requestParam)
    .then(() => {
      dispatch(actions.postIDOSHIENReportCopyServiceDelivery(requestParam));
    })
    .catch((e) => {
      dispatch(
        actions.postIDOSHIENReportCopyServiceDeliveryFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で計画登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績削除
 * @param dispatch
 */
const deleteIDOSHIENReport = (dispatch: Dispatch) => async (
  target: DeleteReportState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteIDOSHIENReportStarted());

  const requestParam = normalizeRequestParamToDeleteAPI(target);

  await inOutResults
    .deleteInOutResults(requestParam)
    .then(() => {
      dispatch(actions.deleteIDOSHIENReport(requestParam));
    })
    .catch((e) => {
      dispatch(actions.deleteIDOSHIENReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchIDOSHIENDaily: (date: Date) => Promise<void>;
  fetchIDOSHIENUsers: (uifId: number, date: Date) => Promise<void>;
  postIDOSHIENReport: ReturnType<typeof postIDOSHIENReport>;
  postIDOSHIENReportCopy: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  postIDOSHIENReportCopyServiceDelivery: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  deleteIDOSHIENReport: (target: DeleteReportState) => Promise<void>;
};

export const IDOSHIENReportDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchIDOSHIENDaily: fetchIDOSHIENDaily(dispatch),
  fetchIDOSHIENUsers: fetchIDOSHIENUsers(dispatch),
  postIDOSHIENReport: postIDOSHIENReport(dispatch),
  postIDOSHIENReportCopy: postIDOSHIENReportCopy(dispatch),
  postIDOSHIENReportCopyServiceDelivery: postIDOSHIENReportCopyServiceDelivery(
    dispatch
  ),
  deleteIDOSHIENReport: deleteIDOSHIENReport(dispatch)
});
