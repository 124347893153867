import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 地域定着支援台帳の削除
 * @param uifId 事業所所属ユーザーのID
 * @param supportLedgerId 地域定着支援台帳のID
 */
const deleteSupportLedger = async (
  uifId: string,
  supportLedgerId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_ledger/${supportLedgerId}`;
  return request.delete(url);
};

export default deleteSupportLedger;
