import React from "react";
import { format } from "date-fns";
import { defaultTimeLabel } from "@utils/date";
import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";

const formatTime = (...timeList: (string | null)[]): string[] => {
  return timeList.map((time) =>
    time ? format(time, "HH:mm") : defaultTimeLabel
  );
};

const TimeCellCore = (props: {
  rowData: SupportProcedureState["supportProcedureList"]["support_procedure_forms"][0];
}): JSX.Element => {
  const { rowData } = props;
  const [startTime1, endTime1, startTime2, endTime2] = formatTime(
    rowData.start_time_1,
    rowData.end_time_1,
    rowData.start_time_2,
    rowData.end_time_2
  );
  switch (rowData.number_of_practitioner) {
    case 1:
      return <>{`${startTime1} 〜 ${endTime1}`}</>;
    case 2:
      return startTime1 && endTime1 ? (
        <>
          {`${startTime1} 〜 ${endTime1}`}
          <br />
          {`${startTime2} 〜 ${endTime2}`}
        </>
      ) : (
        <>{`${startTime2} 〜 ${endTime2}`}</>
      );
    default:
      return <></>;
  }
};

export const TimeCell = TimeCellCore;
