import { Checkbox } from "@constants/variables";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";

const castNumberCheckboxValueOrNull = (
  value: string | number | null | undefined
): Checkbox | null => {
  return !value && `${value}` !== "0" ? null : castNumberCheckboxValue(value);
};

export default castNumberCheckboxValueOrNull;
