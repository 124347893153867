import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import FormikRadioButtons from "@components/molecules/FormikRadioButtons";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";

import {
  getIndexes,
  getName,
  getOptions
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    wrapper: {
      marginTop: 32
    }
  });

type OwnProps = {
  item: CustomRecordsWithCategoryState[number]["custom_record_items"][number];
  formValues: SupportLedger;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerRadioCore = ({ classes, ...props }: Props): JSX.Element => {
  const indexes = getIndexes(props.formValues, props.item);
  const name = getName(indexes, "choiced_item_id");
  const options = getOptions(props.item);

  const tipsDisabilityClass = (
    <HelpToolTip title={<HelpTipMessages name="disabilityClass" />} />
  );

  return (
    <div className={classes.wrapper}>
      <FormikRadioButtons
        label={props.item.name}
        options={options}
        name={name}
        disabled={!props.isEditing}
        style={{ marginBottom: 0 }}
        tooltip={tipsDisabilityClass}
      />
    </div>
  );
};

export const SupportLedgerRadio = withStyles(styles)(SupportLedgerRadioCore);
