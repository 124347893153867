import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { InitialDataValues } from "@initialize/v201910/mgr/SHISETSUNYUSHO/report/initialValues";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import validation, {
  submitValidation
} from "@initialize/v201910/mgr/SHISETSUNYUSHO/report/validation";
import { UsagePerformanceReportDialogSubmit } from "@components/v201910/organisms/mgr/SHISETSUNYUSHO/report/dialog/UsagePerformanceReportDialogSubmit";
import UsagePerformanceReportDialogFields from "@components/v201910/organisms/mgr/SHISETSUNYUSHO/report/dialog/UsagePerformanceReportDialogFields";
import { FacilityState } from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/facility/types";
import { AppState } from "@stores/type";
import { InitialValues } from "@interfaces/v201910/mgr/SHISETSUNYUSHO/report/initial";
import {
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType,
  ReportType,
  REPEAT_DAILY
} from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/types";
import { UsersInFacilityState } from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { INT_TRUE_FROM_API } from "@constants/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    action: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      // MuiDialog-paperの上書き
      margin: 0,
      paddingRight: 4,
      paddingBottom: 4
    },
    modalAction: {
      display: "flex"
    },
    button: {
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4,
      textTransform: "none",
      width: 110
    },
    submit: {
      marginLeft: spacing.unit,
      width: 110,
      marginRight: spacing.unit
    },
    modalHeader: {
      display: "flex"
    },
    modal: {
      maxHeight: "80%",
      width: 600,
      // MuiDialog-paperScrollPaperの上書き
      maxWidth: 600,
      margin: 0
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 32,
      paddingBottom: 0,
      paddingLeft: 32
    },
    modalHeaderArea: {
      padding: "16px 32px 18px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: "20px",
      color: "#37474f",
      backgroundColor: "#f5f5f5"
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    UsageRecordDate: {
      textAlign: "right",
      flex: "auto",
      fontSize: 16
    }
  });

type DispatchProps = {
  postSHISETSUNYUSHOReportDaily: (
    report: UsagePerformanceType,
    reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
    formValue: InitialValues,
    usersInFacilityState: UsersInFacilityState,
    facilityState: FacilityState
  ) => Promise<void>;
  postSHISETSUNYUSHOReportUsers: (
    report: UsagePerformanceType,
    reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
    formValue: InitialValues,
    usersInFacilityState: UsersInFacilityState,
    facilityState: FacilityState
  ) => Promise<void>;
};

type StateProps = {
  facilityState: FacilityState;
  userInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  report: UsagePerformanceType;
  reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType;
  data: InitialDataValues;
  selectedDate: Date;
  type: ReportType;
  onCancel: () => void;
  onSubmit: () => void;
};

type State = {
  isSubmitDisabled: boolean;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class UsagePerformanceReportDialogCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitDisabled: true
    };
  }

  private validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values);
    this.setState({
      isSubmitDisabled: submitValidation(validationResult)
    });
    const error = toEffectiveObject(validationResult);
    return error;
  };

  private onCancel = (): void => {
    this.setState({ isSubmitDisabled: true });
    this.props.onCancel();
  };

  private onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    this.onCancel();
    actions.setSubmitting(true);
    await (this.props.type === REPEAT_DAILY
      ? this.props.postSHISETSUNYUSHOReportDaily(
          this.props.report,
          this.props.reportSHISETSUNYUSHO,
          values,
          this.props.userInFacilityState,
          this.props.facilityState
        )
      : this.props.postSHISETSUNYUSHOReportUsers(
          this.props.report,
          this.props.reportSHISETSUNYUSHO,
          values,
          this.props.userInFacilityState,
          this.props.facilityState
        )
    )
      .then(() => {
        this.props.onSubmit();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        classes={{ paper: classes.modal }}
      >
        <Formik
          initialValues={this.props.data}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.formArea}>
              <DialogTitle
                id={this.props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={this.props.classes.modalHeader}>
                  利用実績
                  <div className={classes.UsageRecordDate}>
                    <span>
                      {this.props.selectedDate.getFullYear()}
                      <span>年</span>
                      {this.props.selectedDate.getMonth() + 1}
                      <span>月</span>
                      {this.props.selectedDate.getDate()}
                      <span>日</span>
                      <span>
                        {`（${
                          oneLetterWeekdaysJapanese[
                            this.props.selectedDate.getDay()
                          ]
                        }）`}
                      </span>
                    </span>
                  </div>
                </div>
                <div>{this.props.data.initial.name}</div>
              </DialogTitle>

              <DialogContent className={classes.modalContents}>
                <UsagePerformanceReportDialogFields
                  formikPropsValues={formikProps}
                  isSevereDisabilitySupport={
                    this.props.userInFacilityState.user
                      .user_in_facility_shisetsunyusho
                      ? this.props.userInFacilityState.user
                          .user_in_facility_shisetsunyusho
                          .severe_disability_support === INT_TRUE_FROM_API
                      : false
                  }
                  facilityState={this.props.facilityState}
                  setFormikFieldValue={formikProps.setFieldValue}
                  serviceStatus={
                    this.props.report && this.props.report.isServiceEnd
                  }
                />
              </DialogContent>
              <DialogActions className={classes.action}>
                <UsagePerformanceReportDialogSubmit
                  formikPropsValues={formikProps}
                  onCancel={this.onCancel}
                  disabled={this.state.isSubmitDisabled}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.v201910.SHISETSUNYUSHO.facility,
    userInFacilityState: state.v201910.SHISETSUNYUSHO.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v201910 } = dispatches;
  const SHISETSUNYUSHODispatcher = v201910.SHISETSUNYUSHO.reportDispatcher(
    dispatch
  );
  return {
    postSHISETSUNYUSHOReportDaily: (
      report: UsagePerformanceType,
      reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
      formValue: InitialValues,
      usersInFacilityState: UsersInFacilityState,
      facilityState: FacilityState
    ): Promise<void> =>
      SHISETSUNYUSHODispatcher.postSHISETSUNYUSHOReportDaily(
        report,
        reportSHISETSUNYUSHO,
        formValue,
        usersInFacilityState,
        facilityState
      ),
    postSHISETSUNYUSHOReportUsers: (
      report: UsagePerformanceType,
      reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
      formValue: InitialValues,
      usersInFacilityState: UsersInFacilityState,
      facilityState: FacilityState
    ): Promise<void> =>
      SHISETSUNYUSHODispatcher.postSHISETSUNYUSHOReportUsers(
        report,
        reportSHISETSUNYUSHO,
        formValue,
        usersInFacilityState,
        facilityState
      )
  };
};

export const UsagePerformanceReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsagePerformanceReportDialogCore));
