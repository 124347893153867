import { GetUsagePerformanceParamsSummary } from "@api/requests/usagePerformance/getUsagePerformanceSummary";

/**
 * ActionNames
 */
export const FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED =
  "REPORT/FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED";
export const FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS =
  "REPORT/FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS";
export const FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED =
  "REPORT/FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED";
export const UNSET_GROUP_HOME_USAGE_PERFORMANCE_SUMMARY =
  "REPORT/UNSET_GROUP_HOME_USAGE_PERFORMANCE_SUMMARY";
export const UNSET_GroupHome_REPORT = "REPORT/UNSET_GroupHome_REPORT";

/**
 * State
 */
export type ReportUsagePerformanceState = GetUsagePerformanceParamsSummary;
