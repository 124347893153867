import get from "lodash-es/get";
import {
  dateToObject,
  dateToSelectDateValue,
  dateToLocalisedString
} from "@utils/date";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import {
  FacilityType,
  ENCIRCLED_NUMBER,
  DISABILITY_CLASS_LIST,
  SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_TEXT,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM
} from "@constants/variables";
import { SUPPORT_CARE_PLAN_SCHEDULES_STATUS_PRINT } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { UsersInFacilityState as UsersInFacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import {
  Id,
  Status,
  RecordCarePlan,
  PrintSchedulesItem,
  SupportCarePlanBasicInfo,
  SupportCarePlanDetails,
  SupportCarePlanSchedules,
  SupportCarePlanBasicInfoWithOutId,
  SupportCarePlanDetailsWithOutId
} from "@interfaces/record/carePlan/carePlan";
import { FieldItem } from "@interfaces/ui/form";

// 初期値
export const schedulesBasicInfoValue: SupportCarePlanBasicInfoWithOutId = {
  day_of_week: [],
  start_time: "",
  end_time: "",
  end_time_next_day_flg: "0",
  number_of_time: "",
  number_of_rides: "",
  number_of_participants: "1",
  practitioner1: 0,
  practitioner2: 0,
  is_delete: false
};
export const schedulesDetailsValue: SupportCarePlanDetailsWithOutId = {
  time_required: "",
  service_details: "",
  point: "",
  to_do: "",
  is_delete: false
};
export const schedulesValue = (
  id: Id,
  facilityType: FacilityType
): SupportCarePlanSchedules => {
  return {
    support_care_plan_schedule_id: id,
    status: "1",
    status_text: SUPPORT_CARE_PLAN_SCHEDULES_STATUS_TEXT[facilityType],
    support_care_plan_basic_info: [
      {
        support_care_plan_basic_info_id: id,
        day_of_week: [],
        start_time: "",
        end_time: "",
        end_time_next_day_flg: "0",
        number_of_time: "",
        number_of_rides: "",
        number_of_participants: "1",
        practitioner1: 0,
        practitioner2: 0,
        is_delete: false
      }
    ],
    support_care_plan_details: [
      {
        support_care_plan_detail_id: id,
        time_required: "",
        service_details: "",
        point: "",
        to_do: "",
        is_delete: false
      }
    ]
  };
};

// Schedules
const initialSchedules = (
  values: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"]
): SupportCarePlanSchedules[] => {
  const result =
    values &&
    Object.keys(values).map((i) => {
      const id: Id = values[i].support_care_plan_schedule_id;
      const statusValue: Status =
        values[i].status !== undefined ? `${values[i].status}` : "1";
      const statusText: Status =
        values[i].status_text !== undefined
          ? `${values[i].status_text}`
          : values[i].status_text;
      const infoValue: SupportCarePlanBasicInfo[] = values[
        i
      ].support_care_plan_basic_info.map(
        (
          m: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"][0]["support_care_plan_basic_info"][0]
        ) => {
          return {
            support_care_plan_basic_info_id: m.support_care_plan_basic_info_id,
            day_of_week: m.day_of_week,
            start_time:
              m.start_time !== null
                ? dateToLocalisedString(m.start_time, "HH:mm")
                : "",
            end_time:
              m.end_time !== null
                ? dateToLocalisedString(m.end_time, "HH:mm")
                : "",
            end_time_next_day_flg: undefinedToReturnValue(
              m.end_time_next_day_flg
            ),
            number_of_time: undefinedToReturnValue(m.number_of_time),
            number_of_rides: undefinedToReturnValue(m.number_of_rides),
            number_of_participants: undefinedToReturnValue(
              m.number_of_participants
            ),
            practitioner1: m.practitioner1 ? m.practitioner1.id : 0,
            practitioner2: m.practitioner2 ? m.practitioner2.id : 0
          };
        }
      );
      const detailsValue: SupportCarePlanDetails[] = values[
        i
      ].support_care_plan_details.map(
        (
          m: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"][0]["support_care_plan_details"][0]
        ) => {
          return {
            support_care_plan_detail_id: m.support_care_plan_detail_id,
            time_required: undefinedToReturnValue(m.time_required),
            service_details: undefinedToReturnValue(m.service_details),
            point: undefinedToReturnValue(m.point),
            to_do: undefinedToReturnValue(m.to_do)
          };
        }
      );

      return {
        support_care_plan_schedule_id: id,
        status: statusValue,
        status_text: statusText,
        support_care_plan_basic_info: infoValue,
        support_care_plan_details: detailsValue
      };
    });
  return result;
};
export const initialValuesSchedulesData = (
  values: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"]
): SupportCarePlanSchedules[] => {
  return initialSchedules(values);
};
export const initialValuesSchedules = (
  values: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"],
  id: number
): SupportCarePlanSchedules => {
  return initialSchedules(values)[id];
};

// 閲覧・編集
export const initialValues = (
  values?: GetSupportPlanUifIdCarePlanCarePlanId["data"],
  staffOptions?: FieldItem[]
): RecordCarePlan => {
  const value = values;

  let authorId = null;
  if (staffOptions && value && value.author) {
    const authorIsDelete = staffOptions.every(
      (option) => value.author && option.value !== String(value.author.id)
    );
    authorId =
      value.author.name !== value.author.snapshot_name && !authorIsDelete
        ? 0
        : value.author.id;
  }

  return {
    created_at: undefinedToReturnValue(get(value, "created_at")),
    updated_at: undefinedToReturnValue(get(value, "updated_at")),
    target_date: dateToSelectDateValue(
      undefinedToReturnValue(get(value, "target_date"))
    ),
    postal_code: undefinedToReturnValue(get(value, "postal_code")),
    read_only_postal_code: undefinedToReturnValue(get(value, "postal_code")),
    city_id: undefinedToReturnValue(get(value, "city_id")),
    prefecture_name: undefinedToReturnValue(get(value, "prefecture_name")),
    address: undefinedToReturnValue(get(value, "address")),
    tel: undefinedToReturnValue(get(value, "tel")),
    classify_disability_support: get(value, "classify_disability_support")
      ? `${get(value, "classify_disability_support")}`
      : DISABILITY_CLASS_LIST[0].value,
    classify_handicapped_flg: undefinedToReturnValue(
      get(value, "classify_handicapped_flg")
    ),
    date_pay_begin: dateToSelectDateValue(
      undefinedToReturnValue(get(value, "date_pay_begin"))
    ),
    date_pay_end: dateToSelectDateValue(
      undefinedToReturnValue(get(value, "date_pay_end"))
    ),
    date_begin_in_service: dateToSelectDateValue(
      undefinedToReturnValue(get(value, "date_begin_in_service"))
    ),
    pay_months_agreed_physical_care: undefinedToReturnValue(
      get(value, "pay_months_agreed_physical_care")
    ),
    pay_months_agreed_outpatient_care_with_physical_care: undefinedToReturnValue(
      get(value, "pay_months_agreed_outpatient_care_with_physical_care")
    ),
    pay_months_agreed_housework_assistance: undefinedToReturnValue(
      get(value, "pay_months_agreed_housework_assistance")
    ),
    pay_months_agreed_outpatient_care: undefinedToReturnValue(
      get(value, "pay_months_agreed_outpatient_care")
    ),
    pay_months_agreed_getting_on_and_off: undefinedToReturnValue(
      get(value, "pay_months_agreed_getting_on_and_off")
    ),
    housework_assistance_base_type: determineTrueOrFalse(
      get(value, "housework_assistance_base_type")
    ),

    housework_assistance_base_type_reason: undefinedToReturnValue(
      get(value, "housework_assistance_base_type_reason"),
      0
    ),
    housework_assistance_base_type_comment:
      undefinedToReturnValue(
        get(value, "housework_assistance_base_type_reason")
      ) === HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value
        ? undefinedToReturnValue(
            get(value, "housework_assistance_base_type_comment")
          )
        : "",
    usage_other_facility: undefinedToReturnValue(
      get(value, "usage_other_facility")
    ),
    user_request: undefinedToReturnValue(get(value, "user_request")),
    user_issue: undefinedToReturnValue(get(value, "user_issue")),
    goals_direction_assistance: undefinedToReturnValue(
      get(value, "goals_direction_assistance")
    ),
    remarks: undefinedToReturnValue(get(value, "remarks")),
    staff_comment: undefinedToReturnValue(get(value, "staff_comment")),
    author: authorId !== null ? `${authorId}` : authorId,
    support_care_plan_schedules: [],
    support_care_plans_judohomonkaigo: {
      support_care_plans_judohomonkaigo_id: undefinedToReturnValue(
        get(
          value && value.support_care_plans_judohomonkaigo,
          "support_care_plans_judohomonkaigo_id"
        )
      ),
      pay_months_agreed_severe_home_visit_care: undefinedToReturnValue(
        get(
          value && value.support_care_plans_judohomonkaigo,
          "pay_months_agreed_severe_home_visit_care"
        )
      ),
      pay_months_agreed_guide_severe_home_visit_care: undefinedToReturnValue(
        get(
          value && value.support_care_plans_judohomonkaigo,
          "pay_months_agreed_guide_severe_home_visit_care"
        )
      )
    },
    support_care_plans_dokoengo: {
      support_care_plans_dokoengo_id: undefinedToReturnValue(
        get(
          value && value.support_care_plans_dokoengo,
          "support_care_plans_dokoengo_id"
        )
      ),
      pay_months_agreed_accompanying_support: undefinedToReturnValue(
        get(
          value && value.support_care_plans_dokoengo,
          "pay_months_agreed_accompanying_support"
        )
      )
    },
    support_care_plans_kodoengo: {
      support_care_plans_kodoengo_id: undefinedToReturnValue(
        get(
          value && value.support_care_plans_kodoengo,
          "support_care_plans_kodoengo_id"
        )
      ),
      pay_months_agreed_accompanying_support: undefinedToReturnValue(
        get(
          value && value.support_care_plans_kodoengo,
          "pay_months_agreed_accompanying_support"
        )
      )
    }
  };
};
// 新規・コピー
export const initialValuesCopy = (
  facilityType: FacilityType,
  userValues?: UsersInFacilityStateKYOTAKUKAIGO["user"] &
    UsersInFacilityStateJUDOHOMONKAIGO["user"] &
    UsersInFacilityStateDOKOENGO["user"] &
    UsersInFacilityStateKODOENGO["user"],
  carePlanValues?: GetSupportPlanUifIdCarePlanCarePlanId["data"]
): RecordCarePlan => {
  const supportCarePlan = carePlanValues;
  const userInFacility = userValues && userValues.user_in_facility;
  const userInFacilityKYOTAKUKAIGO =
    userValues && userValues.user_in_facility_kyotakukaigo;
  const userInFacilityJUDOHOMONKAIGO =
    userValues && userValues.user_in_facility_judohomonkaigo;
  const userInFacilityDOKOENGO =
    userValues && userValues.user_in_facility_dokoengo;
  const userInFacilityKODOENGO =
    userValues && userValues.user_in_facility_kodoengo;

  const defaultItem = {
    housework_assistance_base_type: false,
    housework_assistance_base_type_reason: "0",
    housework_assistance_base_type_comment: "",
    usage_other_facility: "",
    user_request: "",
    user_issue: "",
    goals_direction_assistance: "",
    remarks: "",
    staff_comment: "",
    support_care_plan_schedules: []
  };
  const copyItem = {
    housework_assistance_base_type: determineTrueOrFalse(
      get(supportCarePlan, "housework_assistance_base_type")
    ),

    housework_assistance_base_type_reason: undefinedToReturnValue(
      get(supportCarePlan, "housework_assistance_base_type_reason"),
      0
    ),
    housework_assistance_base_type_comment:
      undefinedToReturnValue(
        get(supportCarePlan, "housework_assistance_base_type_reason")
      ) === HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value
        ? undefinedToReturnValue(
            get(supportCarePlan, "housework_assistance_base_type_comment")
          )
        : "",
    usage_other_facility: undefinedToReturnValue(
      get(supportCarePlan, "usage_other_facility")
    ),
    user_request: undefinedToReturnValue(get(supportCarePlan, "user_request")),
    user_issue: undefinedToReturnValue(get(supportCarePlan, "user_issue")),
    goals_direction_assistance: undefinedToReturnValue(
      get(supportCarePlan, "goals_direction_assistance")
    ),
    remarks: undefinedToReturnValue(get(supportCarePlan, "remarks")),
    staff_comment: undefinedToReturnValue(
      get(supportCarePlan, "staff_comment")
    ),
    support_care_plan_schedules: supportCarePlan
      ? initialValuesSchedulesData(supportCarePlan.support_care_plan_schedules)
      : []
  };
  const itemList = supportCarePlan ? copyItem : defaultItem;

  const classifyDisabilitySupport = ():
    | UsersInFacilityStateKYOTAKUKAIGO["user"]["user_in_facility_kyotakukaigo"]
    | UsersInFacilityStateJUDOHOMONKAIGO["user"]["user_in_facility_judohomonkaigo"]
    | UsersInFacilityStateDOKOENGO["user"]["user_in_facility_dokoengo"]
    | UsersInFacilityStateKODOENGO["user"]["user_in_facility_kodoengo"] => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return userInFacilityKYOTAKUKAIGO;
      case FacilityType.JUDOHOMONKAIGO:
        return userInFacilityJUDOHOMONKAIGO;
      case FacilityType.DOKOENGO:
        return userInFacilityDOKOENGO;
      case FacilityType.KODOENGO:
        return userInFacilityKODOENGO;
      default:
        return userInFacilityKYOTAKUKAIGO;
    }
  };

  return {
    created_at: undefinedToReturnValue(get(userInFacility, "created_at")),
    updated_at: undefinedToReturnValue(get(userInFacility, "updated_at")),
    target_date: dateToObject(new Date()),
    postal_code: undefinedToReturnValue(get(userInFacility, "postal_code")),
    city_id: undefinedToReturnValue(get(userInFacility, "city_id")),
    prefecture_name: undefinedToReturnValue(
      get(userInFacility, "prefecture_name")
    ),
    address: undefinedToReturnValue(get(userInFacility, "address")),
    tel: undefinedToReturnValue(get(userInFacility, "tel")),
    classify_disability_support: get(
      classifyDisabilitySupport(),
      "disability_class"
    )
      ? `${get(classifyDisabilitySupport(), "disability_class")}`
      : DISABILITY_CLASS_LIST[0].value,
    classify_handicapped_flg: undefinedToReturnValue(
      get(userInFacility, "classify_handicapped_flg")
    ),
    date_pay_begin: dateToSelectDateValue(
      undefinedToReturnValue(get(userInFacility, "date_pay_begin"))
    ),
    date_pay_end: dateToSelectDateValue(
      undefinedToReturnValue(get(userInFacility, "date_pay_end"))
    ),
    date_begin_in_service: dateToSelectDateValue(
      undefinedToReturnValue(get(userInFacility, "date_begin_in_service"))
    ),
    pay_months_agreed_physical_care: undefinedToReturnValue(
      get(userInFacilityKYOTAKUKAIGO, "pay_months_agreed_physical_care")
    ),
    pay_months_agreed_outpatient_care_with_physical_care: undefinedToReturnValue(
      get(
        userInFacilityKYOTAKUKAIGO,
        "pay_months_agreed_outpatient_care_with_physical_care"
      )
    ),
    pay_months_agreed_housework_assistance: undefinedToReturnValue(
      get(userInFacilityKYOTAKUKAIGO, "pay_months_agreed_housework_assistance")
    ),
    pay_months_agreed_outpatient_care: undefinedToReturnValue(
      get(userInFacilityKYOTAKUKAIGO, "pay_months_agreed_outpatient_care")
    ),
    pay_months_agreed_getting_on_and_off: undefinedToReturnValue(
      get(userInFacilityKYOTAKUKAIGO, "pay_months_agreed_getting_on_and_off")
    ),
    author: null,
    ...itemList,
    support_care_plans_judohomonkaigo: {
      support_care_plans_judohomonkaigo_id: undefinedToReturnValue(
        get(
          userInFacilityJUDOHOMONKAIGO,
          "support_care_plans_judohomonkaigo_id"
        )
      ),
      pay_months_agreed_severe_home_visit_care: undefinedToReturnValue(
        get(
          userInFacilityJUDOHOMONKAIGO,
          "pay_months_agreed_severe_home_visit_care"
        )
      ),
      pay_months_agreed_guide_severe_home_visit_care: undefinedToReturnValue(
        get(
          userInFacilityJUDOHOMONKAIGO,
          "pay_months_agreed_guide_severe_home_visit_care"
        )
      )
    },
    support_care_plans_dokoengo: {
      support_care_plans_dokoengo_id: undefinedToReturnValue(
        get(userInFacilityDOKOENGO, "support_care_plans_dokoengo_id")
      ),
      pay_months_agreed_accompanying_support: undefinedToReturnValue(
        get(userInFacilityDOKOENGO, "pay_months_agreed_accompanying_support")
      )
    },
    support_care_plans_kodoengo: {
      support_care_plans_kodoengo_id: undefinedToReturnValue(
        get(userInFacilityKODOENGO, "support_care_plans_kodoengo_id")
      ),
      pay_months_agreed_accompanying_support: undefinedToReturnValue(
        get(userInFacilityKODOENGO, "pay_months_agreed_accompanying_support")
      )
    }
  };
};

// 印刷プレビュー用
export const printSchedules = (
  defaultVale: SupportCarePlanSchedules[],
  facilityType: FacilityType
): PrintSchedulesItem[] => {
  const baseDay = SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE;
  const res: PrintSchedulesItem[] = [];

  defaultVale.forEach((item: SupportCarePlanSchedules, index) => {
    const name = (): string => {
      switch (facilityType) {
        case FacilityType.KYOTAKUKAIGO:
          return SUPPORT_CARE_PLAN_SCHEDULES_STATUS_PRINT.filter(
            (f) => f.value === item.status
          ).length !== 0
            ? SUPPORT_CARE_PLAN_SCHEDULES_STATUS_PRINT.filter(
                (f) => f.value === item.status
              )[0].label
            : "";
        case FacilityType.JUDOHOMONKAIGO:
        case FacilityType.DOKOENGO:
        case FacilityType.KODOENGO:
          return item.status_text;
        default:
          return item.status_text;
      }
    };
    const number = ENCIRCLED_NUMBER[index + 1];
    item.support_care_plan_basic_info.forEach(
      (info: SupportCarePlanBasicInfo) => {
        info.day_of_week.forEach((w) => {
          if (info.start_time !== "" && info.end_time !== "") {
            const resItem = { title: "", start: "", end: "" };
            const start = info.start_time;
            const end = info.end_time;
            let dayStart;
            let dayEnd;

            if (info.end_time_next_day_flg === "1") {
              dayStart = baseDay + w;
              dayEnd = baseDay + Number(Number(w) + 1);
            } else {
              dayStart = baseDay + w;
              dayEnd = baseDay + w;
            }

            resItem.title = `${number}${name()}`;
            resItem.start = `${dayStart}T${start}`;
            resItem.end = `${dayEnd}T${end}`;

            res.push(resItem);
          }
        });
      }
    );
  });

  return res;
};
