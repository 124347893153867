import { deleteSupportProcedureDetail } from "./deleteSupportProcedureDetail";
import { getSupportProcedureDetail } from "./getSupportProcedureDetail";
import { getSupportProcedureList } from "./getSupportProcedureList";
import { postSupportProcedureDetail } from "./postSupportProcedureDetail";
import { postSupportProcedureDetailForm } from "./postSupportProcedureDetailForm";
import { getSupportProcedurePrint } from "./getSupportProcedurePrint";

export const supportProcedureApi = {
  getSupportProcedureList,
  getSupportProcedureDetail,
  postSupportProcedureDetail,
  postSupportProcedureDetailForm,
  deleteSupportProcedureDetail,
  getSupportProcedurePrint
};
