import React, { CSSProperties } from "react";
import Switch, { SwitchProps } from "@material-ui/core/Switch";
import FieldWrapper from "@components/atoms/FieldWrapper";
import FormLabel from "@components/molecules/FormLabel";

type OwnProps = {
  label: string;
  tooltip?: React.ReactElement;
  labelStyle?: CSSProperties;
};

export type MuiSwitchProps = OwnProps & SwitchProps;

const MuiSwitch = ({
  label,
  tooltip,
  children,
  style,
  labelStyle,
  ...props
}: MuiSwitchProps): JSX.Element => {
  return (
    <FieldWrapper type="switch" size="auto" style={style}>
      <FormLabel
        control={<Switch {...props} />}
        label={label}
        tooltip={tooltip}
        labelStyle={labelStyle}
      />
      {props.checked && <div style={{ marginLeft: 48 }}>{children}</div>}
    </FieldWrapper>
  );
};

MuiSwitch.defaultProps = {
  color: "secondary"
};

export default MuiSwitch;
