import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

/**
 * 実績登録API用のリクエストパラメータ
 */
export type RequestParamToPostAPI = {
  yyyymmdd: string | null;
  uif_id: string | null;
  params: {
    inout_consultation_results_id: number | null;
    inout_result_daily_id: number | null;
    target_date: string | null;
    type_consultation?: number; // 種別「計画相談/障害児相談」の時のみ出現
    status?: number;
    trial_use_type?: number | null;
    trial_stay_type?: number | null;
    first_addition_flg?: number | null;
    first_addition_span?: number;
    housing_support_cooperation_flg?: number | null;
    housing_support_promotion_flg?: number | null;
    discharge_month_addition_flg?: number | null;
    date_begin_hospital?: string | null;
    date_end_hospital?: string | null;
    discharge_addition_flg?: number;
    short_time_for_psychiatric_hospital_flg?: number | null;
    same_inhome_nursing_care_support_flg?: number;
    same_preventive_care_support_flg?: number;
    inhome_nursing_care_support_office_relation1_flg?: number;
    inhome_nursing_care_support_office_relation2_flg?: number;
    inhome_nursing_care_support_office_relation3_flg?: number;
    inhome_nursing_care_support_office_relation4_flg?: number;
    inhome_nursing_care_support_office_relation5_flg?: number;
    inhome_nursing_care_support_office_relation6_flg?: number;
    intensive_support1_addition_flg?: number;
    intensive_support2_addition_flg?: number;
    intensive_support3_addition_flg?: number;
    intensive_support4_addition_flg?: number;
    intensive_support5_addition_flg?: number;
    hospitalization_relation_addition?: number;
    medical_daycare_education_relation1_flg?: number;
    medical_daycare_education_relation2_flg?: number;
    medical_daycare_education_relation3_flg?: number;
    service_personnel_meeting_held_flg?: number;
    service_provision_monitoring_flg?: number;
    life_support_promotion_flg?: number;
    system_promotion_flg?: number;
    memo_status?: string | null;
    memo_trial?: string | null;
    memo?: string | null;
    childcare_transition_support1_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    childcare_transition_support2_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    childcare_transition_support3_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    mental_disorder_support?: number;
    behavioral_disorder_support?: number;
    higher_brain_dysfunction_support?: number;
    remote_location_first_addition_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_hospitalization_relation_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_discharge_addition_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_inhome_nursing2_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_inhome_nursing5_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_childcare_transition_support2_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_medical_daycare_education1_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_medical_daycare_education2_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_intensive_support1_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
    remote_location_intensive_support4_flg?: number; // 種別「計画相談/障害児相談」の時のみ出現
  };
};

/**
 * 利用実績の登録（更新）
 */
export const postInOutConsultationResults = async (
  data: RequestParamToPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202404}/inout_consultation_results/${data.uif_id}/${data.yyyymmdd}`;
  return request.post(url, data.params);
};
