import * as types from "./types";

export const setEdit = () => ({ type: types.SET_EDIT } as const);
export const setEditWithUifId = (uifId: number) =>
  ({ type: types.SET_EDIT_WITH_UIF_ID, payload: uifId } as const);
export const setEditWithUnitId = (unitId: number) =>
  ({ type: types.SET_EDIT_WITH_UNIT_ID, payload: unitId } as const);
export const unsetEdit = () => ({ type: types.UNSET_EDIT } as const);

export type ActionTypes =
  | ReturnType<typeof setEdit>
  | ReturnType<typeof setEditWithUifId>
  | ReturnType<typeof setEditWithUnitId>
  | ReturnType<typeof unsetEdit>;
