import * as React from "react";
import * as URL from "@constants/url";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { AssistanceReportForm } from "@components/organisms/mgr/CHIIKIIKO/record/assistanceReport/AssistanceReportForm";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  assistanceReportsId: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UsersInFacilityState["user"];
};

type DispatchProps = {
  fetchOneUser: (id: string) => void;
  fetchStaffs: () => Promise<void>;
  fetchPlanMonitoringMeeting: (uifId: string) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const AssistanceReportCore = (props: Props): JSX.Element => {
  const { history, classes, user } = props;
  const { uifId, assistanceReportsId } = props.match.params;
  const userInFacilityId = user.user_in_facility.id;

  // ページ名
  const listName = "計画・報告書・会議記録";
  const pageName = "地域移行支援報告書";
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(uifId);
    props.fetchStaffs();
    props.fetchPlanMonitoringMeeting(uifId);
  }, []);

  if (!userInFacilityId) {
    return <></>;
  }

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <UserInfoRecord user={user} />
        {/* フォーム */}
        <div className={classes.con}>
          <AssistanceReportForm
            user={user}
            userInFacilityId={userInFacilityId}
            assistanceReportsId={assistanceReportsId}
            history={history}
            pageName={pageName}
          />
        </div>
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.CHIIKIIKO.userInFacility.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO, staffDispatcher } = dispatches;
  const planMonitoringMeetingDispatcher = CHIIKIIKO.planMonitoringMeetingDispatcher(
    dispatch
  );
  return {
    fetchOneUser: (id: string): void => {
      CHIIKIIKO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchStaffs: staffDispatcher(dispatch).fetch,
    fetchPlanMonitoringMeeting: (uifId: string): void => {
      planMonitoringMeetingDispatcher.fetchPlanMonitoringMeeting(uifId);
    }
  };
};

export const AssistanceReport = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AssistanceReportCore)
);
