import { getBasicInfo } from "./getBasicInfo";
import { getBasicInfoList } from "./getBasicInfoList";
import { postBasicInfo } from "./postBasicInfo";
import { deleteBasicInfo } from "./deleteBasicInfo";

export const basicInfoApi = {
  getBasicInfoList,
  getBasicInfo,
  postBasicInfo,
  deleteBasicInfo
};
