import * as React from "react";

import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import MuiCheckbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { textFieldStyle } from "@styles/base";

const styles = (): StyleRules =>
  createStyles({
    ...textFieldStyle,
    root: {
      display: "flex"
    }
  });

type OwnProps = {
  label: string | JSX.Element;
  value: string;
  checked: boolean;
  labelStyle?: object;
  inputStyle?: object;
  disabled?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const Checkbox = (props: Props): JSX.Element => (
  <FormControlLabel
    control={
      <MuiCheckbox
        value={props.value}
        checked={props.checked}
        color="secondary"
        onChange={props.onChange}
        style={props.inputStyle || {}}
        disabled={props.disabled}
      />
    }
    style={props.labelStyle}
    label={props.label}
  />
);

export default withStyles(styles)(Checkbox);
