import { BasicValues } from "@interfaces/mgr/CHIIKITEICHAKU/facility/basic";
import { AdditionalItemValues } from "@interfaces/mgr/CHIIKITEICHAKU/facility/additionItem";
import { FacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/types";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import { SubtractionItemValues } from "@interfaces/mgr/CHIIKITEICHAKU/facility/subtractionItem";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : ""
    },
    subtractionItem: {
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      )
    },
    additionalItem: {
      peerSupportFlg: state ? state.peerSupportFlg : false,
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false,
      lifeSupportFunctionPromotionFlg: state
        ? state.lifeSupportFunctionPromotionFlg
        : false,
      lifeSupportFunctionPromotionTimes: state
        ? state.lifeSupportFunctionPromotionTimes
        : ""
    }
  };
};
