import * as React from "react";
import { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import WelfareSpecialistPlacementTypeFields from "@components/organisms/mgr/TANKINYUSHO/facility/items/WelfareSpecialistPlacementTypeFields";
import {
  StaffTreatmentSystemTypes,
  StaffTreatmentSpecificSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DEFAULT_RADIO_VALUE,
  SpecificBetterSupporterConditionTypes2024,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  DISABLED_RADIO_ITEMS_VALUE
} from "@constants/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/TANKINYUSHO/facility/initialValues";
import {
  DIETICIAN_ITEMS,
  FULLTIME_NURSING_STAFF_ITEMS
} from "@constants/mgr/TANKINYUSHO/variables";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";

const styles = (): StyleRules =>
  createStyles({
    innerItem: {
      marginLeft: -20
    },
    section: {
      marginBottom: 32
    }
  });

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);
const staffTreatmentSpecificSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSpecificSystemTypes
);

// なしと福祉・介護職員等処遇改善（Ⅰ）-（Ⅴ）の選択肢
const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);
// なしと、福祉・介護職員等処遇改善（Ⅴ）1 - 14の選択肢
const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AdditionalItemFields = (props: Props): JSX.Element => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = useState(false);
  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );

  const isSelectedConditionType2024 =
    props.formikProps.values.additionalItem
      .specificBetterSupporterCondition202404 ===
    `${SELECTED_CONDITION_TYPE_2024_V}`;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (!isSelectedConditionType2024) {
      props.formikProps.setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
  }, [staffTreatmentSystemType]);
  // 常勤看護職員配置加算のFlgと配置人数のstate管理
  const fulltimeNursingStaffFlg: boolean = getIn(
    props.formikProps.values,
    "additionalItem.fulltimeNursingStaffFlg"
  );
  useEffect(() => {
    const fulltimeNursingStaff: string = getIn(
      props.formikProps.values,
      "additionalItem.fulltimeNursingStaff"
    );
    if (fulltimeNursingStaffFlg && fulltimeNursingStaff === "0") {
      props.setFormikFieldValue("additionalItem.fulltimeNursingStaff", "1");
    }
    if (!fulltimeNursingStaffFlg) {
      props.setFormikFieldValue("additionalItem.fulltimeNursingStaff", "0");
    }
  }, [fulltimeNursingStaffFlg]);
  // 栄養士配置加算のFlgと栄養士配置のstate管理
  const dieticianFlg: boolean = getIn(
    props.formikProps.values,
    "additionalItem.dieticianFlg"
  );
  useEffect(() => {
    const dietician: string = getIn(
      props.formikProps.values,
      "additionalItem.dietician"
    );
    if (dieticianFlg && dietician === "0") {
      props.setFormikFieldValue("additionalItem.dietician", "1");
    }
    if (!dieticianFlg) {
      props.setFormikFieldValue("additionalItem.dietician", "0");
    }
  }, [dieticianFlg]);

  useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.formikProps.setFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.formikProps.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        {/* 福祉専門職員配置等加算 */}
        <WelfareSpecialistPlacementTypeFields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="welfareSpecialistPlacementType2" />}
            />
          }
        />
        <FormikSwitch
          name="additionalItem.fulltimeNursingStaffFlg"
          label="常勤看護職員配置加算"
        >
          <div className={props.classes.innerItem}>
            <FormikRadioButtons
              name="additionalItem.fulltimeNursingStaff"
              label="配置人数"
              options={FULLTIME_NURSING_STAFF_ITEMS}
              style={{ marginBottom: 0 }}
            />
          </div>
        </FormikSwitch>
      </FormGroup>
      <FormGroup style={{ marginBottom: 28 }}>
        <FormikRadioButtons
          name="additionalItem.specificBetterSupporterCondition202404"
          style={{ marginBottom: 0 }}
          label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
          options={SpecificBetterSupporterConditionType2024RadioItems}
          onClick={onChangeSpecificBetterSupporterConditionType2024}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
              }
            />
          }
        />
        {isSelectedConditionType2024 && (
          <FormikRadioButtons
            style={{ marginLeft: 48, marginTop: -2, marginBottom: 0 }}
            name="additionalItem.specificBetterSupporterCondition202404Sub"
            label=""
            options={SpecificBetterSupporterConditionTypeSub2024RadioItems}
          />
        )}
      </FormGroup>
      <FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="staffTreatmentSystem" />}
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.medicalSupportFlg"
          label="医療連携体制加算"
        />
      </FormGroup>
      <FormGroup>
        <FormikSwitch name="additionalItem.dieticianFlg" label="栄養士配置加算">
          <div className={props.classes.innerItem}>
            <FormikRadioButtons
              name="additionalItem.dietician"
              label="栄養士配置"
              options={DIETICIAN_ITEMS}
              style={{ marginBottom: 0 }}
            />
          </div>
        </FormikSwitch>
      </FormGroup>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.seriousDisabilityFlg"
          label="重度障害児・障害者対応支援加算"
        />
        <FormikCheckbox
          name="additionalItem.lifeSupportHubInDistrictFlg"
          label="地域生活支援拠点等"
          style={{ marginBottom: 0 }}
        />
      </FormGroup>
    </FormPaper>
  );
};

export default withStyles(styles)(AdditionalItemFields);
