import ValidationErrors from "@interfaces/ui/validationErrors";
import {
  SupportProcedureDetailFormValues,
  SupportProcedureFormPractitioners
} from "./initialValues";
import validator, { validateRuleSwitcher } from "@validator";
import { DAY_SELECT_TYPE } from "@constants/variables";
import { KODOENGO_MEMBER_LIST } from "@constants/mgr/KODOENGO/variables";

type FieldErrors = ValidationErrors<SupportProcedureDetailFormValues>;

/** 時間指定をDateに変換する */
const normalizeTime = (timeString: string, startDateType?: string): Date => {
  const baseDate = new Date();
  const [hours, minutes] = timeString.split(":").map(Number);
  baseDate.setHours(hours);
  baseDate.setMinutes(minutes);
  // 翌日判定の場合は日付を翌日にする
  if (startDateType === DAY_SELECT_TYPE.nextDay) {
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return baseDate;
};

/** サービス提供時間のバリデーション */
const validateServiceDelivery = (
  values: SupportProcedureDetailFormValues
): FieldErrors => {
  /** サービス提供者単体のバリデーションチェック */
  const validatePractitioners = (
    practitioners: Exclude<SupportProcedureFormPractitioners, []>
  ): ValidationErrors<Exclude<SupportProcedureFormPractitioners, []>> => {
    // 入力形式 逆転チェック
    return {
      startTime: validator(practitioners.startTime, "checkTime"),
      endTime: validator(practitioners.endTime, "checkTime", {
        type: "futureTime",
        startTime: normalizeTime(
          practitioners.startTime,
          practitioners.startTimeDate
        ),
        endTime: normalizeTime(practitioners.endTime, practitioners.endTimeDate)
      })
    };
  };

  // サービス提供者1
  let supportProcedureFormPractitioners1Errors = {};
  if (!Array.isArray(values.supportProcedureFormPractitioners1)) {
    supportProcedureFormPractitioners1Errors = validatePractitioners(
      values.supportProcedureFormPractitioners1
    );
  }

  // サービス提供者2
  let supportProcedureFormPractitioners2Errors = {};
  if (
    !Array.isArray(values.supportProcedureFormPractitioners2) &&
    values.numberOfPractitioner === KODOENGO_MEMBER_LIST.TWO.value
  ) {
    supportProcedureFormPractitioners2Errors = validatePractitioners(
      values.supportProcedureFormPractitioners2
    );
  }

  // サービス提供者の時間の被りをチェック
  if (
    !Array.isArray(values.supportProcedureFormPractitioners1) &&
    !Array.isArray(values.supportProcedureFormPractitioners2)
  ) {
    const startTime1 = normalizeTime(
      values.supportProcedureFormPractitioners1.startTime,
      values.supportProcedureFormPractitioners1.startTimeDate
    );
    const endTime1 = normalizeTime(
      values.supportProcedureFormPractitioners1.endTime,
      values.supportProcedureFormPractitioners1.endTimeDate
    );
    const startTime2 = normalizeTime(
      values.supportProcedureFormPractitioners2.startTime,
      values.supportProcedureFormPractitioners2.startTimeDate
    );
    const endTime2 = normalizeTime(
      values.supportProcedureFormPractitioners2.endTime,
      values.supportProcedureFormPractitioners2.endTimeDate
    );
    const errorMessage = validator(
      values.supportProcedureFormPractitioners2.endTime,
      validateRuleSwitcher(
        values.numberOfPractitioner === KODOENGO_MEMBER_LIST.TWO.value,
        {
          type: "checkRangeDateTime",
          startTimeFirst: startTime1,
          endTimeFirst: endTime1,
          startTimeSecond: startTime2,
          endTimeSecond: endTime2,
          message: "2人の提供者の開始/終了時間"
        }
      )
    );
    if (errorMessage) {
      supportProcedureFormPractitioners1Errors = {
        ...supportProcedureFormPractitioners1Errors,
        startTime: errorMessage,
        startTimeDate: errorMessage,
        endTime: errorMessage,
        endTimeDate: errorMessage
      };
      supportProcedureFormPractitioners2Errors = {
        ...supportProcedureFormPractitioners2Errors,
        startTime: errorMessage,
        startTimeDate: errorMessage,
        endTime: errorMessage,
        endTimeDate: errorMessage
      };
    }
  }

  // 他事業所
  const supportProcedureFormOtherPractitionerErrors = values.supportProcedureFormOtherPractitioners.map<
    NonNullable<FieldErrors["supportProcedureFormOtherPractitioners"]>[number]
  >((otherPractitioner) => {
    return {
      facilityName: validator(otherPractitioner.facilityName, {
        type: "checkCharacterLength",
        length: 100
      }),
      practitionerName: validator(otherPractitioner.practitionerName, {
        type: "checkCharacterLength",
        length: 100
      }),
      startTime: validator(otherPractitioner.startTime, "checkTime"),
      endTime: validator(otherPractitioner.endTime, "checkTime", {
        type: "futureTime",
        startTime: normalizeTime(
          otherPractitioner.startTime,
          otherPractitioner.startTimeDate
        ),
        endTime: normalizeTime(
          otherPractitioner.endTime,
          otherPractitioner.endTimeDate
        )
      })
    };
  });

  return {
    supportProcedureFormPractitioners1: {
      ...supportProcedureFormPractitioners1Errors
    },
    supportProcedureFormPractitioners2: {
      ...supportProcedureFormPractitioners2Errors
    },
    supportProcedureFormOtherPractitioners: supportProcedureFormOtherPractitionerErrors
  };
};

/** 「手順書と記録」のバリデーションチェック */
const validateSupportProcedureFormDetails = (
  values: SupportProcedureDetailFormValues
): FieldErrors => {
  const supportProcedureFormDetailsErrors = values.supportProcedureFormDetails.map<
    NonNullable<FieldErrors["supportProcedureFormDetails"]>[number]
  >((supportProcedureFormDetail) => {
    return {
      startTime: validator(supportProcedureFormDetail.startTime, "checkTime"),
      endTime: validator(supportProcedureFormDetail.endTime, "checkTime"),
      activity: validator(supportProcedureFormDetail.activity, {
        type: "checkCharacterLength",
        length: 20
      }),
      serviceProcedure: validator(supportProcedureFormDetail.serviceProcedure, {
        type: "checkCharacterLength",
        length: 2500
      }),
      appearance: validator(supportProcedureFormDetail.appearance, {
        type: "checkCharacterLength",
        length: 2500
      })
    };
  });
  return {
    supportProcedureFormDetails: supportProcedureFormDetailsErrors
  };
};
export const validate = (
  values: SupportProcedureDetailFormValues
): FieldErrors => {
  return {
    title: validator(values.title, {
      type: "checkCharacterLength",
      length: 20
    }),
    ...validateServiceDelivery(values),
    ...validateSupportProcedureFormDetails(values),
    contactInformationRecord: validator(values.contactInformationRecord, {
      type: "checkCharacterLength",
      length: 2500
    }),
    contactInformationPlan: validator(values.contactInformationPlan, {
      type: "checkCharacterLength",
      length: 2500
    }),
    inquiryItemsRecord: validator(values.inquiryItemsRecord, {
      type: "checkCharacterLength",
      length: 2500
    }),
    inquiryItemsPlan: validator(values.inquiryItemsPlan, {
      type: "checkCharacterLength",
      length: 2500
    })
  };
};
