import React from "react";

// UI
import {
  StyleRules,
  WithStyles,
  createStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { SupportProcedureTable } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureTable";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import { SupportProcedureCreateModalContent } from "@components/organisms/mgr/KODOENGO/dialog/SupportProcedureCreateModalContent";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import {
  SelectedSupportProcedureIds,
  SupportProcedureState
} from "@stores/domain/mgr/KODOENGO/supportProcedure/types";

import * as URL from "@constants/url";
import { dateInYYYYMMFormat } from "@utils/date";
import { RouteComponentProps } from "react-router";
import { FacilityType } from "@constants/variables";
import {
  KODOENGO_PLAN_LIST_TAB_PATH,
  SUPPORT_PROCEDURE_DATE_SETTING_MODAL_RADIO_BUTTONS,
  TARGET_PATH
} from "@constants/mgr/KODOENGO/variables";
import { isDateWithin } from "@utils/date/isDateWithin";
import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { SupportProcedurePrintModal } from "@components/organisms/mgr/KODOENGO/dialog/SupportProcedurePrintModal";
import { useLocalStorage } from "@hooks/record/supportProcedure/useLocalStorage";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: palette.background.default,
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    list: {
      marginTop: "32px",
      background: "#fff",
      borderRadius: "4px"
    },
    header: {
      backgroundColor: "#fff",
      padding: "32px 32px 8px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between"
    },
    tableWrapper: {
      backgroundColor: "#fff"
    },
    outsidePeriod: {
      fontSize: "16px",
      paddingTop: "80px",
      paddingBottom: "80px",
      textAlign: "center",
      lineHeight: "28px",
      backgroundColor: "#fff"
    }
  });

type OwnProps = WithStyles<typeof styles> &
  RouteComponentProps<{ uifId: string; date: string }>;

type StateProps = {
  user: UsersInFacilityState["user"];
  facilityType: FacilityType;
  supportProcedureList: SupportProcedureState["supportProcedureList"];
};

type DispatchProps = {
  fetchOneUser: (id: string) => void;
  fetchSupportProcedureList: (uifId: string, date: string) => void;
  deleteSupportProcedureDetail: (
    supportProcedureFormsId: string
  ) => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps;

const SupportProcedureListCore = (props: Props): JSX.Element => {
  const {
    user,
    match,
    classes,
    history,
    facilityType,
    supportProcedureList,
    fetchOneUser,
    fetchSupportProcedureList,
    deleteSupportProcedureDetail
  } = props;
  const {
    support_procedure_forms = [],
    number_of_procedures
  } = supportProcedureList;
  const { user_in_facility } = user;
  const { uifId, date } = match.params;

  const pathList = [
    { pathName: "支援計画", path: `${URL.RECORD_SUPPORT_PLAN}/users_summary` }
  ];

  const userName = `${user_in_facility.name_sei} ${user_in_facility.name_mei}`;

  const today = new Date();

  // 日付の最大値の設定 (30年後の12月31日)
  const maxDate = new Date(today.getFullYear() + 30, 11, 31);
  const minDate = new Date(2021, 3, 1);

  const [currentDate, setCurrentDate] = React.useState<Date>(today);
  const isWithinInterval = isDateWithin(
    currentDate,
    user_in_facility.date_begin_in_service || "",
    user_in_facility.date_end_in_service
  );

  const [deleteTarget, setDeleteTarget] = React.useState<string>();
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
  const [isOpenModal, setIsOpenModal] = React.useState<boolean>(false);
  const [isOpenDateSettingModal, setIsOpenDateSettingModal] = React.useState<
    boolean
  >(false);
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState<boolean>(
    false
  );
  const [isCopy, setIsCopy] = React.useState<boolean>(false);
  const [
    selectedSupportProcedureIds,
    setSelectedSupportProcedureIds
  ] = React.useState<SelectedSupportProcedureIds>({
    serviceDeliveryRecordsId: null,
    inoutResultsId: null,
    supportProcedureFormsId: null
  });
  const [
    originSupportProcedureFormsId,
    setOriginSupportProcedureFormsId
  ] = React.useState("");

  // 初期表示・日付変更時・URL変更時の処理
  React.useEffect(() => {
    const year = Number(date.slice(0, 4));
    const month = Number(date.slice(4, 6));
    setCurrentDate(new Date(year, month - 1, 1));

    fetchOneUser(uifId);
    fetchSupportProcedureList(uifId, date);
  }, [date]);

  const options = React.useMemo((): RadioItemInterface[] => {
    const action = isCopy ? "コピー" : "作成";
    return [
      { label: `一覧から日時を選択して${action}する`, value: "1" },
      { label: `日時を設定して${action}する`, value: "2" },
      { label: `日時を設定せずに${action}する`, value: "3" }
    ];
  }, [isCopy]);

  const printButton = React.useMemo((): JSX.Element => {
    return (
      <KnowbeButton
        disabled={number_of_procedures === 0}
        onClick={(): void => setIsOpenPrintModal(true)}
      >
        印刷
      </KnowbeButton>
    );
  }, [number_of_procedures]);

  const onChangeDate = (targetDate: Date): void => {
    const formattedDate = dateInYYYYMMFormat(targetDate);
    history.replace(
      `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE}/list/${formattedDate}`
    );
    setCurrentDate(targetDate);
  };

  const handleOnClickDeleteIcon = (target: string): void => {
    setDeleteTarget(target);
    setIsOpenDeleteDialog(true);
  };

  const deleteSupportProcedureList = React.useCallback(() => {
    if (!deleteTarget) return;
    deleteSupportProcedureDetail(deleteTarget).then(() => {
      setDeleteTarget(undefined);
      setIsOpenDeleteDialog(false);
      fetchSupportProcedureList(uifId, date);
    });
  }, [deleteTarget, date]);

  const { setTargetObject } = useLocalStorage();
  const navigateToEdit = (ids: SelectedSupportProcedureIds): void => {
    const {
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId,
      targetDate
    } = ids;

    const params: string[] = [];
    if (serviceDeliveryRecordsId) {
      params.push(`serviceDeliveryRecordsId=${serviceDeliveryRecordsId}`);
    }
    if (inoutResultsId) {
      params.push(`inoutResultsId=${inoutResultsId}`);
    }
    if (supportProcedureFormsId) {
      params.push(`supportProcedureFormsId=${supportProcedureFormsId}`);
    }
    if (targetDate) {
      params.push(`targetDate=${targetDate}`);
    }

    setTargetObject([
      {
        targetDate: targetDate || null,
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      }
    ]);

    const search = params.length > 0 ? params.join("&") : "";
    const pathname = `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE}/${TARGET_PATH.edit}`;
    history.push({ pathname, search });
  };

  return (
    <AdminTemplate pageName="支援手順書 兼 記録用紙一覧" pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <CarePlanUserInfo user={user} />
        <div className={classes.list}>
          <PlanListHeader
            pageName="支援手順書 兼 記録用紙 一覧"
            userName={userName}
            uifId={uifId}
            recordType={KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE}
            isEditing={false}
            history={props.history}
            button={printButton}
            facilityType={facilityType}
          />
        </div>
        <div className={classes.header}>
          <DateSelectButtonsMonthly
            selectedMonth={currentDate}
            min={minDate}
            max={maxDate}
            onClickSubmit={onChangeDate}
          />
          {isWithinInterval && (
            <KnowbeButton
              onClick={(): void => {
                setIsCopy(false);
                setIsOpenModal(true);
              }}
            >
              新規作成
            </KnowbeButton>
          )}
        </div>
        {isWithinInterval ? (
          <div className={classes.tableWrapper}>
            <SupportProcedureTable
              records={support_procedure_forms}
              handleOnClickDelete={handleOnClickDeleteIcon}
              handleOnClickEdit={navigateToEdit}
              handleOnClickCopy={(ids: SelectedSupportProcedureIds): void => {
                setIsCopy(true);
                setIsOpenModal(true);
                setSelectedSupportProcedureIds(ids);
              }}
              handleOnClickDateSetting={(
                ids: SelectedSupportProcedureIds,
                originId: string
              ): void => {
                setIsOpenDateSettingModal(true);
                setOriginSupportProcedureFormsId(originId);
                setSelectedSupportProcedureIds(ids);
              }}
            />
          </div>
        ) : (
          <div className={classes.outsidePeriod}>サービス提供期間外です</div>
        )}
      </div>
      <ConfirmDialog
        title="該当する支援手順書を削除します"
        message={
          <>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </>
        }
        isOpen={isOpenDeleteDialog}
        onDelete={deleteSupportProcedureList}
        onCancel={(): void => setIsOpenDeleteDialog(false)}
        dialogWidth={600}
      />
      {/* 新規作成・コピーモーダル */}
      {isOpenModal && (
        <SupportProcedureCreateModalContent
          uifId={uifId}
          title={isCopy ? "コピー" : "新規作成"}
          isOpen={isOpenModal}
          radioBtnOptions={options}
          dateBeginInService={user_in_facility.date_begin_in_service || ""}
          dateEndInService={user_in_facility.date_end_in_service}
          history={props.history}
          target={isCopy ? TARGET_PATH.copy : TARGET_PATH.new}
          onClose={(): void => setIsOpenModal(false)}
          selectedIds={selectedSupportProcedureIds}
        />
      )}
      {/* 日付設定モーダル */}
      {isOpenDateSettingModal && (
        <SupportProcedureCreateModalContent
          uifId={uifId}
          title="日時の設定"
          isOpen={isOpenDateSettingModal}
          radioBtnOptions={SUPPORT_PROCEDURE_DATE_SETTING_MODAL_RADIO_BUTTONS}
          dateBeginInService={user_in_facility.date_begin_in_service || ""}
          dateEndInService={user_in_facility.date_end_in_service}
          history={props.history}
          target={TARGET_PATH.setDate}
          onClose={(): void => setIsOpenDateSettingModal(false)}
          selectedIds={selectedSupportProcedureIds}
          originSupportProcedureId={originSupportProcedureFormsId}
        />
      )}
      {/* 印刷モーダル */}
      {isOpenPrintModal && (
        <SupportProcedurePrintModal
          isOpen={isOpenPrintModal}
          uifId={uifId}
          dateBeginInService={user_in_facility.date_begin_in_service || ""}
          dateEndInService={user_in_facility.date_end_in_service}
          history={props.history}
          onClose={(): void => setIsOpenPrintModal(false)}
        />
      )}
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.KODOENGO.userInFacility.user,
  facilityType: state.user.facility_type,
  supportProcedureList: state.KODOENGO.supportProcedure.supportProcedureList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  return {
    fetchOneUser: (id: string): void => {
      KODOENGO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportProcedureList: (uifId: string, date: string): void => {
      KODOENGO.supportProcedureDispatcher(dispatch).fetchSupportProcedureList(
        uifId,
        date
      );
    },
    deleteSupportProcedureDetail: (
      supportProcedureFormsId: string
    ): Promise<void> => {
      return KODOENGO.supportProcedureDispatcher(
        dispatch
      ).deleteSupportProcedureDetail(supportProcedureFormsId);
    }
  };
};

export const SupportProcedureList = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportProcedureListCore)
);
