import React, { useState, useEffect } from "react";
import * as H from "history";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import debounce from "lodash-es/debounce";
import { DARK_BLUE } from "@constants/styles";
import { FACILITY_TYPE_NAME_LIST } from "@constants/variables";
import ClassNames from "classnames";
import { getFacilityId } from "@utils/localStorage";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

const styles = (): StyleRules =>
  createStyles({
    modal: {
      width: 1080,
      // スクロールバーの非表示（Google Chromeのみの適用）
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    modalArea: {
      width: 1080,
      letterSpacing: 0.5
    },
    dialogTitleRoot: {
      position: "sticky",
      top: 0,
      zIndex: 1,
      padding: "0 32px",
      backgroundColor: "#fff"
    },
    dialogTitleWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: 52
    },
    dialogTitleTextWrapper: {
      display: "flex",
      height: 24,
      alignItems: "baseline"
    },
    dialogTitle: {
      fontSize: 20,
      lineHeight: 1.2,
      color: DARK_BLUE
    },
    dialogTitleSub: {
      color: "#212121",
      fontSize: 12,
      lineHeight: 1.25,
      marginLeft: 4
    },
    closeButton: {
      width: 120,
      height: 36,
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      backgroundColor: "rgba(98, 2, 238, 0)",
      color: "#0277bd"
    },
    doneIcon: {
      position: "relative",
      top: 3,
      color: "rgba(0, 0, 0, 0.54)",
      width: 18,
      height: 18
    },
    dialogContent: {
      padding: 0,
      overflowY: "visible"
    },
    headerTable: {
      borderBottom: "none"
    },
    bodyTable: {
      width: 1080
    },
    tableHeader: {
      "& th": {
        position: "sticky",
        top: 52,
        backgroundColor: "#90a4ae",
        zIndex: 1
      },
      "& th:not(last-of-type)": {
        paddingRight: 24
      }
    },
    facilityNumberTableHeader: {
      paddingLeft: 32,
      width: 168
    },
    facilityNameTableHeader: {
      width: 280
    },
    facilityTypeTableHeader: {
      width: 160
    },
    isMasterTableHeader: {
      width: 54
    },
    isMultipleTableHeader: {
      width: 70
    },
    responsibleNameTableHeader: {
      width: 144
    },
    businessOwnerTableHeader: {
      width: 204,
      "&:last-of-type": {
        paddingRight: 32
      }
    },
    tableBody: {
      overflowY: "auto",
      overflowX: "hidden",
      height: "100%",
      // スクロールバーのデザイン対応のため（Google Chromeのみの適用）
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    tableRow: {
      "& td + td": {
        padding: "14px 24px 14px 0"
      }
    },
    selectedFacility: {
      backgroundColor: "#d6d6d6" // rgba(0, 0, 0, 0.08)
    },
    facilityNumberTableBody: {
      padding: "14px 24px 14px 32px",
      width: 168
    },
    facilityNameTableBody: {
      width: 280
    },
    facilityTypeTableBody: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: 160,
      maxWidth: 160
    },
    isMasterTableBody: {
      width: 54,
      textAlign: "center"
    },
    isMultipleTableBody: {
      width: 70,
      textAlign: "center"
    },
    responsibleNameTableBody: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: 144,
      maxWidth: 144
    },
    businessOwnerTableBody: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: 204,
      maxWidth: 204,
      "&:last-child": {
        padding: "14px 32px 14px 0"
      }
    }
  });

type StateProps = {
  user: UserState;
};

type DispatchProps = {
  switchUserFacility: (facility_id: number, history: H.History) => void;
};

type OwnProps = {
  isModalOpen: boolean;
  closeFacilityDialog: () => void;
  history: H.History;
};

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

/**
 * 事業所切り替えモーダル
 */
const FacilitySwitchDialogComponent = (props: Props): JSX.Element => {
  const {
    user,
    switchUserFacility,
    isModalOpen,
    closeFacilityDialog,
    history,
    classes
  } = props;

  const tableList = {
    header: "facility_switch_table_header",
    body: "facility_switch_table_body"
  };

  // 選択中の事業所Idを取得
  // ログイン後に事業所切り替えを行っていない場合はUserAPIのデータを使用し、
  // 一度でも事業所切り替えを行っている場合はLocalStorageの値を使用する
  const getSelectedFacilityId = (): string => {
    const storageFacilityId = getFacilityId();

    if (!storageFacilityId) return "";

    // 選択中事業所が管理画面から解除されていた場合の対応（初期事業所が選択状態になる）
    if (
      user.facilityGroups.some(
        (facility_group) =>
          String(facility_group.facility_id) === storageFacilityId
      )
    ) {
      return storageFacilityId;
    }

    return "";
  };

  const selectedFacilityId = getSelectedFacilityId() || user.origin_facility_id;

  // モーダルのヘッダーデータ
  const headerData: KnowbeTableCellParam[] = [
    {
      label: "事業所番号",
      className: classes.facilityNumberTableHeader
    },
    {
      label: "事業所名",
      className: classes.facilityNameTableHeader
    },
    {
      label: "種別",
      className: classes.facilityTypeTableHeader
    },
    {
      label: "主従",
      className: classes.isMasterTableHeader
    },
    {
      label: "多機能",
      className: classes.isMultipleTableHeader
    },
    {
      label: "代表者名",
      className: classes.responsibleNameTableHeader
    },
    {
      label: "法人名",
      className: classes.businessOwnerTableHeader
    }
  ];

  // モーダルのボディデータ
  const bodyData: KnowbeTableCellParam[][] = user.facilityGroups.map(
    (facility_group) => {
      // 種別
      const facilityTypeName = FACILITY_TYPE_NAME_LIST.find(
        (facility_type_name) => {
          return facility_type_name.value === facility_group.type_service;
        }
      );

      // 主従
      let masterFlg;
      if (facility_group.master_flg === "1") {
        masterFlg = "主";
      } else if (facility_group.master_flg === "0") {
        masterFlg = "従";
      } else {
        masterFlg = "-";
      }

      // 多機能
      const multipleFlg =
        facility_group.multiple_facility_flg === "1" ? (
          <DoneIcon className={classes.doneIcon} />
        ) : (
          "-"
        );

      // 現在選択中の事業所かどうか
      const isSelectedFacility =
        +selectedFacilityId === facility_group.facility_id;

      return [
        // 配列の１つ目に事業所ごとのユニーク値を入れておく（facility_id）
        // モーダルには表示しない
        {
          label: String(facility_group.facility_id),
          hidden: true
        },
        {
          label: facility_group.gov_facility_number,
          className: ClassNames(
            classes.facilityNumberTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: facility_group.name,
          className: ClassNames(
            classes.facilityNameTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: facilityTypeName ? facilityTypeName.label : "-",
          className: ClassNames(
            classes.facilityTypeTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: masterFlg,
          className: ClassNames(
            classes.isMasterTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: multipleFlg,
          className: ClassNames(
            classes.isMultipleTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: facility_group.responsible_person,
          className: ClassNames(
            classes.responsibleNameTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        },
        {
          label: facility_group.gov_business_owner,
          className: ClassNames(
            classes.businessOwnerTableBody,
            isSelectedFacility && classes.selectedFacility
          )
        }
      ];
    }
  );

  const [pressedRow, setPressedRow] = useState<KnowbeTableCellParam[]>([
    { label: "" }
  ]);

  // 引数の事業所が、押下した事業所か判断する関数（pressed color設定用）
  const isPressed = (items: KnowbeTableCellParam[]): boolean => {
    return items[0].label === pressedRow[0].label;
  };

  // クリック操作を列ごとに制御する
  const onClickRow = async (items: KnowbeTableCellParam[]): Promise<void> => {
    setPressedRow(items);

    // 選択した（押下された）事業所のfacility_idを一覧と比較し、切り替え先の事業所を特定する
    const pressedFacility = user.facilityGroups.find((data) => {
      return String(data.facility_id) === items[0].label;
    });

    if (!pressedFacility) return;

    // 押下した事業所が、現在選択している事業所のときはモーダルを閉じる
    if (pressedFacility.facility_id === +selectedFacilityId) {
      closeFacilityDialog();
      return;
    }

    // 事業所切り替えの処理を行う
    await switchUserFacility(pressedFacility.facility_id, history);
  };

  // スクロールバーの表示範囲を調整。
  // IABリリース前からの事象でスクロールバーは表示されているが画面を小さくするとスクロールバーの表示が見切れてしまう事象があり、
  // 画面の高さに変更があった時、固定(640px)ではなく動的に高さを変動させてスクロールバーが正しい範囲内で収まるように調整。

  // ウインドウの高さを動的に算出する
  const [innerHeight, setHeight] = useState(0);

  const setHeightFunc = (): void => {
    const height = window.innerHeight;
    setHeight(height);
  };

  // resize後、0.1sマウスが止まったときに関数を呼び出す（負荷対策）
  const handleResize = debounce(setHeightFunc, 100);

  useEffect(() => {
    setHeightFunc();
    window.addEventListener("resize", handleResize);
    return (): void => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // MuiDialogのcssではモーダルの高さをwindow高さ - 96pxで計算している
  const modalHeight = innerHeight - 96;
  // モーダルのmaxHeightを640pxに制限
  const modalAreaStyle = {
    height: modalHeight > 640 ? 640 : modalHeight
  };
  // tableBody部の高さ計算
  // スクロール時の固定表示部(100px)：モーダルヘッダーの高さ(52px) + テーブルヘッダー(48px)
  const tempTableBodyHeight = modalHeight - 100;
  // tableBody部のmaxHeightは640-100=540pxに制限
  const modalContentsStyle = {
    height: tempTableBodyHeight > 540 ? 540 : tempTableBodyHeight
  };

  // 切替可能合計施設数
  const FacilityTotal = user.facilityGroups ? user.facilityGroups.length : 0;

  return (
    <Dialog
      open={isModalOpen}
      onClose={closeFacilityDialog}
      maxWidth="lg"
      classes={{ paper: classes.modal }}
      disableBackdropClick
    >
      <div className={classes.modalArea} style={modalAreaStyle}>
        <DialogTitle classes={{ root: classes.dialogTitleRoot }}>
          <div className={classes.dialogTitleWrapper}>
            <div className={classes.dialogTitleTextWrapper}>
              <div className={classes.dialogTitle}>事業所選択</div>
              <div className={classes.dialogTitleSub}>
                {`（全 ${FacilityTotal} 施設）`}
              </div>
            </div>
            <Button
              onClick={closeFacilityDialog}
              className={classes.closeButton}
              color="secondary"
            >
              閉じる
            </Button>
          </div>
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={modalContentsStyle}
        >
          <KnowbeTable className={classes.headerTable}>
            <KnowbeTableHead
              headerClassName={classes.tableHeader}
              uniqueKey={tableList.header}
              items={headerData}
              height={48}
            />
          </KnowbeTable>
          <div className={classes.tableBody}>
            <KnowbeTable className={classes.bodyTable}>
              <KnowbeTableBody
                rowClassName={classes.tableRow}
                uniqueKey={tableList.body}
                itemsContainer={bodyData}
                stripe={false}
                hover
                isSelected={isPressed}
                onClickRow={onClickRow}
              />
            </KnowbeTable>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch } = dispatches;
  const userDispatches = userDispatch(dispatch);
  return {
    switchUserFacility: (
      facility_id: number,
      history: H.History
    ): Promise<void> => {
      return userDispatches.switchFacility(facility_id, history);
    }
  };
};

export const FacilitySwitchDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FacilitySwitchDialogComponent));
