import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import * as classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    fab: {
      position: "sticky",
      height: "40px",
      display: "flex"
    },
    fabExpand: {
      width: "40px!important"
    },
    label: {
      paddingLeft: "6px",
      opacity: 1
    },
    labelDisplay: {
      paddingLeft: 0,
      opacity: 0,
      fontSize: 0
    }
  });

type OwnProps = {
  onClick: () => void;
  label: string;
  transition?: boolean; // スクロールによるlabel非表示制御を実行するか
  style?: React.CSSProperties;
};

type Props = OwnProps & WithStyles<typeof styles>;

const MuiFloatingActionButtonCore = ({
  onClick,
  label,
  transition = true,
  style,
  classes
}: Props): JSX.Element => {
  const [scrollDown, isScrollDown] = React.useState(false);
  const fabStyle = style || { bottom: "32px", margin: "0px 20px 32px auto" };
  // 下スクロールでlabelを非表示にし、上スクロールでlabelを表示する
  React.useEffect(() => {
    let set_position = 0;
    const scrollAction = (): void => {
      if (set_position < document.documentElement.scrollTop) {
        isScrollDown(true);
      } else {
        isScrollDown(false);
      }
      set_position = document.documentElement.scrollTop;
    };

    window.addEventListener("scroll", scrollAction, {
      capture: false,
      passive: true
    });
    scrollAction();

    return (): void => {
      window.removeEventListener("scroll", scrollAction);
    };
  }, []);

  return (
    <Fab
      variant={transition && scrollDown ? "round" : "extended"}
      color="secondary"
      aria-label="Add"
      className={classNames(
        classes.fab,
        transition && scrollDown ? classes.fabExpand : ""
      )}
      style={fabStyle} // bottomやmarginなどを指定することで、位置を調整できる
      onClick={onClick}
    >
      <AddIcon />
      <span
        className={classNames(
          classes.label,
          transition && scrollDown ? classes.labelDisplay : ""
        )}
      >
        {label}
      </span>
    </Fab>
  );
};

export const MuiFloatingActionButton = withStyles(styles)(
  MuiFloatingActionButtonCore
);
