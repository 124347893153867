import * as React from "react";
import ClassNames from "classnames";
import {
  createStyles,
  WithStyles,
  withStyles,
  FormLabel
} from "@material-ui/core";
import {
  FacilityType,
  TRAIL_USAGE_KIND,
  MEDICAL_COOPERATION_202104,
  SUPPLY_FOOD_SERVICE_LIST,
  SUPPLY_PICKUP_SERVICE_LIST,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST0,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST1,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST2,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST3
} from "@constants/variables";
import {
  IKOU_IN_OUT_RECORDS_STATUS_MODAL_202104,
  AB_IN_OUT_RECORDS_STATUS_MODAL,
  ENABLE_MEDICAL_COOPERATION_VALUES,
  REWARD_TYPE_ITEMS
} from "@constants/mgr/IAB/variables";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikTime from "@components/molecules/FormikTime";
import { InitialDataValues } from "@initialize/v202104/mgr/IAB/report/initialValues";
import { StyleRules } from "@material-ui/core/styles";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/IAB/userInFacility/types";

const styles = (): StyleRules =>
  createStyles({
    disabled: {
      color: "rgba(0, 0, 0, 0.38)"
    },
    checkbox: {
      "& > div > label > span": {
        fontSize: "16px"
      }
    },
    fullWidth: {
      "& > div": {
        width: "100%"
      }
    },
    marginTop: {
      marginTop: 12
    }
  });

interface OwnState {
  fieldsStatus: {
    isTrialUsageKindDisplay: boolean;
    isTimeInputRequired: boolean;
    isTimeInputDisabled: boolean;
    isDidGetFoodDisabled: boolean;
    isMedicalCooperationDisabled: boolean;
    isSputumGuidanceDisabled: boolean;
    isTravelTimeDisabled: boolean;
    isPickupPremisesDisabled: boolean;
    isOtherDisabled: boolean;
    isWorkRecordDisplay: boolean;
    isCaseMeetingFlgDisabled: boolean;
  };
  serviceType: string;
  formikPropsValues: FormikProps<InitialDataValues>;
  isPickupAvailable: boolean;
  isFoodAvailable: boolean;
  rewardType: string;
  isCaseMeetingFlg: boolean;
  onChangeInOutTime: (
    autoCorrectValue: string,
    fieldName: string
  ) => { startTime: string; endTime: string; breakTime: string };
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  usersInFacilityState: UsersInFacilityState;
}

type Props = OwnState & WithStyles<typeof styles>;

interface State {
  readonly pickupPremisesList: FieldItem[];
  readonly pickupValue: string;
  readonly pickupPremisesValue: string;
  enableSputumGuidanceFlg: boolean;
}

/**
 * 「サービス提供の状況」が「作業時間」の表示と関係あるステータスか判定
 * @param status サービス提供の状況
 */
export const isRelatedToWorkHours = (status: string): boolean => {
  switch (status) {
    case "2": // 通所
    case "3": // 施設外就労
    case "4": // 施設外支援
    case "8": // 移行準備支援(I)
    case "9": // 旧 移行準備支援(II)
      return true;
    case "1": // -
    case "5": // 欠席時対応
    case "6": // 訪問
    case "7": // 体験利用支援
    case "10": // 欠席
    default:
      return false;
  }
};

class InOutReportDialogFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { formikPropsValues } = this.props;
    this.state = {
      pickupValue: formikPropsValues.values.initial.travelTime,
      pickupPremisesValue: formikPropsValues.values.initial.pickupPremises,
      pickupPremisesList: SUPPLY_PICKUP_PREMISES_SERVICE_LIST0,
      enableSputumGuidanceFlg: false
    };
  }

  public componentDidMount(): void {
    const { formikPropsValues } = this.props;
    this.changePickupPremisesState(formikPropsValues.values.initial.travelTime);
    const enableSputumGuidanceFlgValue = ENABLE_MEDICAL_COOPERATION_VALUES.includes(
      this.props.formikPropsValues.values.initial.medicalCooperation
    );
    this.setState({
      enableSputumGuidanceFlg: enableSputumGuidanceFlgValue
    });
  }

  /**
   * フィールド操作以外で変更があった時の調整
   */
  public componentDidUpdate(prevProps: Props): void {
    const { travelTime } = this.props.formikPropsValues.values.initial;
    const preTravelTime = prevProps.formikPropsValues.values.initial.travelTime;
    if (travelTime !== preTravelTime) {
      this.changePickupPremisesState(travelTime);
    }
  }

  private handleChangeStatusHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { setFormikFieldValue, formikPropsValues } = this.props;
    setFormikFieldValue("initial.extended", "0");
    setFormikFieldValue("initial.trialUsageKind", "1");
    this.setState({
      enableSputumGuidanceFlg: ENABLE_MEDICAL_COOPERATION_VALUES.includes(
        formikPropsValues.values.initial.medicalCooperation
      )
    });
    this.changeState(e.target.value);
  };

  private handleChangeMedicalCooperationHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.props.setFormikFieldValue("initial.sputumGuidanceFlg", false);
    this.setState({
      enableSputumGuidanceFlg: ENABLE_MEDICAL_COOPERATION_VALUES.includes(
        e.target.value
      )
    });
  };

  /**
   * propsからの追加処理とリアルタイムにバリデーションを行う
   */
  private handleChangeInitialTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string
  ): string | void => {
    this.props.onChangeInOutTime(autoCorrectValue, event.target.name);
    const { setFormikFieldValue } = this.props;
    setFormikFieldValue(event.target.name, autoCorrectValue);
  };

  private handleChangePickupHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { setFormikFieldValue } = this.props;
    const { pickupValue, pickupPremisesValue } = this.state;
    setFormikFieldValue(
      "initial.pickupPremises",
      e.target.value === pickupValue ? pickupPremisesValue : "0"
    );

    this.changePickupPremisesState(e.target.value);
  };

  private changeState = (status: string): void => {
    const {
      setFormikFieldValue,
      formikPropsValues,
      usersInFacilityState
    } = this.props;
    const memo = formikPropsValues.values.initial.memo.replace(
      /施設外就労\(実施報告書等添付\)|日報有り\(職場体験実習\)/,
      ""
    );
    // 3,4は施設外就労と施設外支援
    if (status === "3") {
      setFormikFieldValue(
        "initial.memo",
        `${memo}施設外就労(実施報告書等添付)`
      );
    } else if (status === "4" || status === "8") {
      setFormikFieldValue("initial.memo", `${memo}日報有り(職場体験実習)`);
    } else {
      setFormikFieldValue("initial.memo", memo);
    }

    // 「作業を実施した」チェックボックスを自動チェックするかどうか判定し、適宜自動チェックする
    if (isRelatedToWorkHours(status)) {
      setFormikFieldValue(
        "workRecord.worked",
        usersInFacilityState.user.user_in_facility.def_record_work === "1"
      );
    } else {
      setFormikFieldValue("workRecord.worked", false);
    }
  };

  private changePickupPremisesState = (pickupValue: string): void => {
    let pickupPremisesList;
    switch (pickupValue) {
      case "1":
        pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST1;
        break;
      case "2":
        pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST2;
        break;
      case "3":
        pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST3;
        break;
      default:
        pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST0;
    }
    this.setState({ pickupPremisesList });
  };

  public render(): JSX.Element {
    const {
      serviceType,
      classes,
      isFoodAvailable,
      isPickupAvailable,
      rewardType,
      isCaseMeetingFlg
    } = this.props;
    const {
      isTrialUsageKindDisplay,
      isTimeInputRequired,
      isTimeInputDisabled,
      isDidGetFoodDisabled,
      isMedicalCooperationDisabled,
      isSputumGuidanceDisabled,
      isTravelTimeDisabled,
      isPickupPremisesDisabled,
      isOtherDisabled,
      isCaseMeetingFlgDisabled
    } = this.props.fieldsStatus;
    const { pickupPremisesList }: State = this.state;
    const checkBoxStyle = { marginTop: -4, marginBottom: 0 };
    return (
      <>
        <FormGroup row>
          <FormikSelect
            name="initial.status"
            label="サービス提供の状況"
            required
            options={
              serviceType === FacilityType.IKOU
                ? IKOU_IN_OUT_RECORDS_STATUS_MODAL_202104
                : AB_IN_OUT_RECORDS_STATUS_MODAL
            }
            size="smallMedium"
            onChangeHook={this.handleChangeStatusHook}
            style={{ width: serviceType === FacilityType.IKOU ? 328 : "" }}
          />
          {isTrialUsageKindDisplay && (
            <div>
              <FormikSelect
                name="initial.trialUsageKind"
                label="体験利用支援種別"
                required
                options={TRAIL_USAGE_KIND}
                size="medium"
                style={{ marginBottom: 12, width: "335px" }}
              />
              <div className={classes.checkbox}>
                <FormikCheckbox
                  name="initial.lifeSupportHubInDistrictFlg"
                  label="地域生活支援拠点"
                />
              </div>
            </div>
          )}
        </FormGroup>
        <FormGroup row>
          <FormikTime
            name="initial.inTime"
            label="開始時間"
            required={isTimeInputRequired}
            placeholder="00:00"
            size="smallMedium"
            maxLength={5}
            disabled={isTimeInputDisabled}
            onChangeHookTime={this.handleChangeInitialTime}
          />
          <FormikTime
            name="initial.outTime"
            label="終了時間"
            placeholder="00:00"
            size="smallMedium"
            maxLength={5}
            disabled={isTimeInputDisabled}
            onChangeHookTime={this.handleChangeInitialTime}
          />
        </FormGroup>
        {isPickupAvailable && (
          <FormGroup row>
            <FormikSelect
              name="initial.travelTime"
              label="送迎"
              size="smallMedium"
              disabled={isTravelTimeDisabled}
              options={SUPPLY_PICKUP_SERVICE_LIST}
              onChangeHook={this.handleChangePickupHook}
              FormLabelClasses={
                isTravelTimeDisabled ? { root: classes.disabled } : undefined
              }
            />
            <FormikSelect
              name="initial.pickupPremises"
              label="同一敷地内送迎"
              size="smallMedium"
              options={pickupPremisesList}
              disabled={isPickupPremisesDisabled}
              FormLabelClasses={
                isPickupPremisesDisabled
                  ? { root: classes.disabled }
                  : undefined
              }
            />
          </FormGroup>
        )}
        {isFoodAvailable && (
          <FormikSelect
            name="initial.didGetFood"
            label="食事提供"
            size="smallMedium"
            disabled={isDidGetFoodDisabled}
            options={SUPPLY_FOOD_SERVICE_LIST}
            FormLabelClasses={
              isDidGetFoodDisabled ? { root: classes.disabled } : undefined
            }
          />
        )}
        <FormGroup row>
          <FormikSelect
            name="initial.medicalCooperation"
            label="医療連携体制"
            size="smallMedium"
            disabled={isMedicalCooperationDisabled}
            options={MEDICAL_COOPERATION_202104}
            onChangeHook={this.handleChangeMedicalCooperationHook}
            FormLabelClasses={
              isMedicalCooperationDisabled
                ? { root: classes.disabled }
                : undefined
            }
          />
          <FormikCheckbox
            name="initial.sputumGuidanceFlg"
            label="喀痰吸引等に係る指導実施"
            disabled={
              isSputumGuidanceDisabled || !this.state.enableSputumGuidanceFlg
            }
            style={{ marginTop: 12 }}
          />
        </FormGroup>
        <div className={classes.checkbox}>
          <FormLabel style={{ fontSize: "12px" }}>その他</FormLabel>
          <FormikCheckbox
            name="initial.helpInhouseLifeFlg"
            label="在宅時生活支援"
            disabled={isOtherDisabled}
            style={checkBoxStyle}
          />
          <FormikCheckbox
            name="initial.helpSocialLifeFlg"
            label="社会生活支援"
            disabled={isOtherDisabled}
            style={checkBoxStyle}
          />
          {serviceType === FacilityType.IKOU && (
            <div className={classes.checkbox}>
              <FormikCheckbox
                name="initial.trainCommuteFlg"
                label="通勤訓練"
                disabled={isOtherDisabled}
                style={checkBoxStyle}
              />
              <FormikCheckbox
                name="initial.caseMeetingFlg"
                label="支援計画会議実施加算"
                disabled={isCaseMeetingFlgDisabled || !isCaseMeetingFlg}
                style={checkBoxStyle}
              />
            </div>
          )}
          {serviceType === FacilityType.B && (
            <div className={classes.checkbox}>
              <FormikCheckbox
                name="initial.regionalCooperationFlg"
                label="地域協働加算"
                disabled={
                  isOtherDisabled || rewardType !== REWARD_TYPE_ITEMS[1].value
                }
                style={checkBoxStyle}
              />
              <FormikCheckbox
                name="initial.peerSupportFlg"
                label="ピアサポート実施加算"
                disabled={
                  isOtherDisabled || rewardType !== REWARD_TYPE_ITEMS[1].value
                }
                style={checkBoxStyle}
              />
            </div>
          )}
          <div className={ClassNames(classes.fullWidth, classes.marginTop)}>
            <FormikTextField
              name="initial.memo"
              label="備考"
              size="quarterSuperLong"
              style={{ marginBottom: 32 }}
              multiline
            />
          </div>
        </div>
      </>
    );
  }
}
export default withStyles(styles)(InOutReportDialogFields);
