import * as React from "react";
import { FormikProps, FieldArray } from "formik";
// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import IconAdd from "@material-ui/icons/Add";
import { DestinationTable } from "@components/organisms/record/serviceDelivery/destination/DestinationTable";

// formik
import {
  ServiceDeliveryDetailValues,
  practitionerDestinationInitialValue
} from "@initialize/record/serviceDelivery/initialValues";

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  isAddButton: boolean;
  practitionerNum: number;
  destination: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDestinations"];
};

type Props = OwnProps;

export const DestinationFields = (props: Props): JSX.Element => {
  const { destination } = props;
  const unitKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.serviceDeliveryRecordPractitionerDestinations`;
  return (
    <FieldArray name={unitKey}>
      {(arrayHelpers): JSX.Element => {
        const onClickAddUnit = (): void => {
          arrayHelpers.push(practitionerDestinationInitialValue);
        };
        const onDeleteRecord = (index: number): void => {
          props.formikProps.setFieldValue(`${unitKey}[${index}].isDelete`, 1);
        };
        return (
          <div>
            <div style={{ marginTop: 24 }}>
              {destination && destination.length > 0 && (
                <DestinationTable
                  formikProps={props.formikProps}
                  unitKey={unitKey}
                  onDeleteRecord={onDeleteRecord}
                  isAddButton={props.isAddButton}
                  practitionerNum={props.practitionerNum}
                  destination={props.destination}
                />
              )}
              <KnowbeButton
                kind="iconText"
                onClick={onClickAddUnit}
                disabled={!props.isAddButton}
              >
                <IconAdd />
                行き先を追加する
              </KnowbeButton>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
