import * as React from "react";
import classNames, * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import format from "date-fns/format";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import {
  RESIDENCE_LIST,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleTextS: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "12px",
      fontWeight: "normal",
      letterSpacing: "0.6px",
      margin: 0
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4,
      display: "flex",
      "&:nth-child(n+2)": {
        marginTop: 4
      },
      "&>span": {
        display: "block",
        minWidth: 88,
        marginLeft: 4
      }
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start"
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      minHeight: 19,
      fontSize: 10,
      lineHeight: 1.4,
      color: "#212121",
      paddingBottom: 5,
      borderBottom: "solid 1px #000"
    },
    tableArea: {
      border: "solid 2px #212121",
      lineHeight: 1.4,
      fontSize: 10,
      color: "#212121",
      overflowWrap: "anywhere"
    },
    supportProgress: {
      padding: "4px 6px"
    },
    userStatusGridContainer: {
      flexGrow: 1,
      display: "grid",
      width: "100%",
      gridTemplateColumns: "100px 1fr 76px 1fr 55px 120px",
      "&> div": {
        padding: "4px 6px",
        "&:not(.lastRow)": {
          borderBottom: "1px solid #000"
        },
        "&:not(.lastColumn)": {
          borderRight: "1px solid #000"
        }
      }
    },
    table2row: {
      gridRow: "2 / 4"
    },
    table3col: {
      gridColumn: "2 / 5"
    },
    table4col: {
      gridColumn: "1 / 5"
    },
    table2col: {
      gridColumn: "5 / 7"
    },
    table3colS: {
      gridColumn: "1 / 4"
    },
    table3colE: {
      gridColumn: "4 / 7"
    },
    tableLabel: {
      backgroundColor: "#f5f5f5",
      display: "flex"
    },
    textCenter: {
      display: "flex",
      justifyContent: "center"
    },
    underline: {
      textDecoration: "underline"
    },
    supportStatusGridContainer: {
      flexGrow: 1,
      display: "grid",
      width: "100%",
      gridTemplateColumns: "100px 120px 120px 1fr 55px 140px",
      "&> div": {
        padding: "4px 6px",
        "&:not(.lastRow)": {
          borderBottom: "1px solid #000"
        },
        "&:not(.lastColumn)": {
          borderRight: "1px solid #000"
        }
      }
    },
    W158: {
      width: 158
    },
    W254: {
      width: 254
    },
    W65: {
      width: 65
    },
    MT4: {
      marginTop: 4
    },
    ML16: {
      marginLeft: 16
    },
    MT13: {
      marginTop: 13
    },
    MT16: {
      marginTop: 16
    },
    MT24: {
      marginTop: 24
    },
    flex: {
      display: "flex"
    },
    paddingRight: {
      paddingRight: 16
    }
  });

type UserStatusProp = {
  value: {
    name_sei: string | undefined;
    name_mei: string | undefined;
    support_progress: string | null;
    date_birth: string | null;
    age: number | null;
    postal_code: string | null;
    city_id: number | null;
    prefecture_name: string | null;
    address: string | null;
    residence: number | null;
    tel: string | null;
    fax: string | null;
    disability_name: string | null;
    classify_disability_support: number | null;
    gender: string | null;
    family_structure: string | null;
    social_relationship: string | null;
    life_history: string | null;
    medical_situation: string | null;
    self_complaint: string | null;
    family_complaint: string | null;
  };
  cityLabel: string;
  classes: Record<string, string>;
};

type SupportStatusProp = {
  public_support: BasicInfoState["basicInfo"]["basic_info"]["public_support"];
  other_support: BasicInfoState["basicInfo"]["basic_info"]["other_support"];
  classes: Record<string, string>;
};

type OwnProps = {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  cityLabel: string;
  basicInfo: BasicInfoState["basicInfo"]["basic_info"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

// 利用者の状況
const UserStatus = (props: UserStatusProp): JSX.Element => {
  const { classes, value, cityLabel } = props;

  if (Object.keys(value).every((x) => !x)) {
    return <div />;
  }

  // 住所
  const postalCodeAndRestAddress = (): JSX.Element => {
    if (value.postal_code === null && value.address === null) {
      return <span>-</span>;
    }

    const postalCode =
      value.postal_code === null ? "" : `〒${value.postal_code}`;
    const restAddress = value.address === null ? "" : `${value.address}`;
    const prefectureAndCityName = [
      value.prefecture_name,
      cityLabel,
      restAddress
    ]
      .filter(Boolean)
      .join("");

    if (postalCode === "" || prefectureAndCityName === "") {
      return (
        <span>
          {postalCode}
          {prefectureAndCityName}
        </span>
      );
    }

    return (
      <span>
        {postalCode}&emsp;{prefectureAndCityName}
      </span>
    );
  };

  // 障害支援区分
  const classifyDisabilitySupport = (): string => {
    if (value.classify_disability_support === 0) {
      return "なし";
    }
    return DISABILITY_CLASS_LIST.filter(
      (f) => f.value === `${value.classify_disability_support}`
    ).length !== 0
      ? DISABILITY_CLASS_LIST.filter(
          (f) => f.value === `${value.classify_disability_support}`
        )[0].label
      : "";
  };

  // 住まい
  const residence = (): string => {
    return RESIDENCE_LIST.filter((f) => f.value === `${value.residence}`)
      .length !== 0
      ? RESIDENCE_LIST.filter((f) => f.value === `${value.residence}`)[0].label
      : "";
  };

  return (
    <div>
      <div className={classNames(classes.pageTitleTextS, classes.MT16)}>
        2.利用者の状況
      </div>
      <div
        className={ClassNames(
          classes.tableArea,
          classes.MT4,
          classes.userStatusGridContainer
        )}
      >
        <div className={classes.tableLabel}>氏名</div>
        <div>{`${value.name_sei} ${value.name_mei}`}</div>
        <div className={classes.tableLabel}>生年月日</div>
        <div>
          {value.date_birth ? format(value.date_birth, "YYYY年MM月DD日") : ""}
        </div>
        <div className={classes.tableLabel}>年齢</div>
        <div className="lastColumn">
          {value.age !== null ? `${value.age}歳` : ""}
        </div>
        <div className={ClassNames(classes.tableLabel, classes.table2row)}>
          住所
        </div>
        <div className={classes.table3col}>{postalCodeAndRestAddress()}</div>
        <div className={classes.tableLabel}>電話番号</div>
        <div className="lastColumn">{value.tel ? value.tel : ""}</div>
        <div className={classes.table3col}>{residence()}</div>
        <div className={classes.tableLabel}>FAX番号</div>
        <div className="lastColumn">{value.fax ? value.fax : ""}</div>
        <div className={classes.tableLabel}>障害または疾患名</div>
        <div>{value.disability_name ? value.disability_name : ""}</div>
        <div className={classes.tableLabel}>障害支援区分</div>
        <div>{classifyDisabilitySupport()}</div>
        <div className={classes.tableLabel}>性別</div>
        <div className="lastColumn">
          {value.gender === "1" ? "男性" : "女性"}
        </div>
        <div className={classes.table3colS}>
          <div className={classes.underline}>家族構成</div>
          <div className={classes.MT4}>
            {value.family_structure ? value.family_structure : ""}
          </div>
        </div>
        <div className={`lastColumn ${classes.table3colE}`}>
          <div className={classes.underline}>社会関係図</div>
          <div className={classes.MT4}>
            {value.social_relationship ? value.social_relationship : ""}
          </div>
        </div>
        <div className={classes.table4col}>
          <div className={classes.underline}>生活歴</div>
          <div className={classes.MT4}>
            {value.life_history ? value.life_history : ""}
          </div>
        </div>
        <div className={`lastColumn ${classes.table2col}`}>
          <div className={classes.underline}>医療の状況</div>
          <div className={classes.MT4}>
            {value.medical_situation ? value.medical_situation : ""}
          </div>
        </div>
        <div className={`lastRow ${classes.table3colS}`}>
          <div className={classes.underline}>本人の主訴</div>
          <div className={classes.MT4}>
            {value.self_complaint ? value.self_complaint : ""}
          </div>
        </div>
        <div className={`lastColumn lastRow ${classes.table3colE}`}>
          <div className={classes.underline}>家族の主訴</div>
          <div className={classes.MT4}>
            {value.family_complaint ? value.family_complaint : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

// 利用者の状況
const SupportStatus = (props: SupportStatusProp): JSX.Element => {
  const { public_support, other_support, classes } = props;

  const havePublicSupport = !Object.keys(public_support)
    .filter((x) => x !== "basic_info_details_id")
    .every((x) => !public_support[x]);
  const haveOtherSupport = !Object.keys(other_support)
    .filter((x) => x !== "basic_info_details_id")
    .every((x) => !other_support[x]);

  if (!havePublicSupport && !haveOtherSupport) {
    return <></>;
  }

  return (
    <div>
      <div className={classNames(classes.pageTitleTextS, classes.MT16)}>
        3.支援の状況
      </div>
      <div
        className={classNames(
          classes.tableArea,
          classes.MT4,
          classes.supportStatusGridContainer
        )}
      >
        <div className={classes.tableLabel}>{/* 空欄 */}</div>
        <div className={classNames(classes.tableLabel, classes.textCenter)}>
          名称
        </div>
        <div className={classNames(classes.tableLabel, classes.textCenter)}>
          提供期間・提供者
        </div>
        <div className={classNames(classes.tableLabel, classes.textCenter)}>
          支援内容
        </div>
        <div className={classNames(classes.tableLabel, classes.textCenter)}>
          頻度
        </div>
        <div
          className={`lastColumn ${classes.tableLabel} ${classes.textCenter}`}
        >
          備考
        </div>
        {havePublicSupport && (
          <>
            <div
              className={`${haveOtherSupport ? "" : "lastRow"} ${
                classes.tableLabel
              }`}
            >
              公的支援（障害福祉サービス、介護保険等）
            </div>
            <div className={haveOtherSupport ? "" : "lastRow"}>
              {public_support.name ? public_support.name : ""}
            </div>
            <div className={haveOtherSupport ? "" : "lastRow"}>
              {public_support.offer_name ? public_support.offer_name : ""}
            </div>
            <div className={haveOtherSupport ? "" : "lastRow"}>
              {public_support.support_contents
                ? public_support.support_contents
                : ""}
            </div>
            <div className={haveOtherSupport ? "" : "lastRow"}>
              {public_support.frequency ? public_support.frequency : ""}
            </div>
            <div className={`lastColumn ${haveOtherSupport ? "" : "lastRow"}`}>
              {public_support.memo ? public_support.memo : ""}
            </div>
          </>
        )}
        {haveOtherSupport && (
          <>
            <div className={`lastRow ${classes.tableLabel}`}>その他の支援</div>
            <div className="lastRow">
              {other_support.name ? other_support.name : ""}
            </div>
            <div className="lastRow">
              {other_support.offer_name ? other_support.offer_name : ""}
            </div>
            <div className="lastRow">
              {other_support.support_contents
                ? other_support.support_contents
                : ""}
            </div>
            <div className="lastRow">
              {other_support.frequency ? other_support.frequency : ""}
            </div>
            <div className="lastRow lastColumn">
              {other_support.memo ? other_support.memo : ""}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const BasicInfoPrintContentCore = (props: Props): JSX.Element => {
  const { classes, user, facility, cityLabel, basicInfo } = props;

  // 計画作成日
  const targetDate = format(basicInfo.creation_date, "YYYY年MM月DD日");

  // 作成者名
  const authorName = basicInfo.author
    ? getSnapOrRealName(basicInfo.author, "")
    : "";

  const userInFacility = user.user_in_facility;

  let officeName = "";
  const facilityKS = findFacilityKeikakuSodan(facility);
  const facilitySS = findFacilityShogaijisodan(facility);
  if (
    facilityKS &&
    basicInfo.type_consultation === TYPE_CONSULTATION_KEIKAKUSODAN
  ) {
    officeName = facilityKS.name;
  } else if (
    facilitySS &&
    basicInfo.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN
  ) {
    officeName = facilitySS.name;
  }

  if (user.user_in_facility.name_sei === undefined) {
    return <></>;
  }

  return (
    <>
      {/* 申請者の現状（基本情報） */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>申請者の現状（基本情報）</h2>
          <div>
            <div className={classes.pageTitleTool}>
              作成日: <span>{`${targetDate}`}</span>
            </div>
          </div>
        </div>
        {/* 基本情報 */}
        <div>
          <div className={classes.userInfo}>
            <div className={ClassNames(classes.W254, classes.MT24)}>
              <span className={classes.userInfoItem}>相談支援事業者名</span>
              <div className={classes.userInfoValue}>
                <span>{officeName}</span>
              </div>
            </div>
            <div
              className={ClassNames(classes.W158, classes.ML16, classes.MT24)}
            >
              <span className={classes.userInfoItem}>計画作成担当者</span>
              <div className={classes.userInfoValue}>
                <span>{authorName}</span>
              </div>
            </div>
          </div>
        </div>
        {/* 概要 */}
        {!!basicInfo.support_progress && (
          <div>
            <div className={classNames(classes.pageTitleTextS, classes.MT16)}>
              1.概要&emsp;支援経過・現状と課題等
            </div>
            <div className={ClassNames(classes.tableArea, classes.MT4)}>
              <div className={classes.supportProgress}>
                {basicInfo.support_progress}
              </div>
            </div>
          </div>
        )}
        {/* 利用者の状況 */}
        <UserStatus
          value={{
            ...basicInfo,
            name_sei: userInFacility.name_sei,
            name_mei: userInFacility.name_mei
          }}
          cityLabel={cityLabel}
          classes={classes}
        />
        {/* 支援の状況 */}
        <SupportStatus
          public_support={basicInfo.public_support}
          other_support={basicInfo.other_support}
          classes={classes}
        />
      </div>
    </>
  );
};

export const BasicInfoPrintContent = withStyles(styles)(
  BasicInfoPrintContentCore
);
