import React, {
  Dispatch as reactDispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
// ui
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { CustomRecordsTable } from "@components/organisms/record/settingWithCategory/CustomRecordsTable";
import { CustomRecordsDialog } from "@components/organisms/record/settingWithCategory/dialolg/CustomRecordsDialog";
import { SettingFormButtons } from "@components/organisms/record/settingWithCategory/SettingFormButtons";
// formik
import { Formik, FormikActions } from "formik";
import {
  initialValuesForService,
  tableBodyInitialValuesForService,
  CustomRecordItemValues
} from "@initialize/record/customRecordWithCategory/initialValues";
// variables
import {
  CUSTOM_RECORD_TARGET_TYPE,
  FacilityType,
  SERVICE_HEAD_FIXED_ITEM,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO,
  INT_TRUE_FROM_API
} from "@constants/variables";
import { SERVICE_DELIVERY_FIX_ORDER_DEFAULT_ITEM } from "@constants/mgr/IDOSHIEN/variables";

// utils
import deepEqual from "fast-deep-equal";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    pureSettingTopSection: {
      marginTop: spacing.unit * 3,
      marginBottom: spacing.unit,
      textAlign: "right"
    },
    description: {
      color: "#37474f"
    }
  });

type OwnProps = {
  categoryData: CustomRecordsWithCategoryState[0];
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  openHideConfirmModal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openHiddenCustomRecordsList: (type: number) => void;
  sortingCategoryType: number | null;
  fetchCustomRecords: () => Promise<void>;
  title: string;
  description: string;
  facilityType: FacilityType;
};

type StateProps = {
  needsStopHistory: boolean;
};

type DispatchProps = {
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordItemValues,
    customRecordCategoryId: number
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const ServiceDeliverySettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    categoryData,
    fetchCustomRecords,
    sortingCategoryType,
    setSortingCategoryType,
    openHideConfirmModal,
    openHiddenCustomRecordsList,
    postCustomRecordsOrder,
    needsStopHistory,
    stopHistory,
    title,
    description
  } = props;

  // state
  const [commonTableValues, setCommonTableValues] = React.useState(
    tableBodyInitialValuesForService()
  );
  const [formValues, setFormValues] = useState(
    initialValuesForService(
      categoryData.id,
      CUSTOM_RECORD_TARGET_TYPE.service_delivery
    )
  );
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [customRecordModalItemId, setCustomRecordModalItemId] = useState(0);

  const getHeadFixedItems = (): number[] => {
    if (SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(props.facilityType)) {
      return SERVICE_HEAD_FIXED_ITEM;
    }
    if (props.facilityType === FacilityType.IDOSHIEN) {
      return (
        SERVICE_DELIVERY_FIX_ORDER_DEFAULT_ITEM[categoryData.category_type] ||
        []
      );
    }
    return [];
  };
  const headFixedItems = getHeadFixedItems();

  // 並べ替え用関数
  const sortRecords = (
    recordItems: CustomRecordItemValues
  ): CustomRecordItemValues => {
    return recordItems.sort((a, b) => {
      // 固定項目を先頭に持ってくる
      const aIndex = headFixedItems.findIndex((i) => i === a.default_item);
      const bIndex = headFixedItems.findIndex((i) => i === b.default_item);
      if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;
      if (aIndex >= 0) return -1;
      if (bIndex >= 0) return 1;
      // 固定項目以外のソート
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });
  };

  // 記録項目データ（表示用並べ替え済み）
  const visibleCustomRecords = categoryData.custom_record_items.length
    ? sortRecords(
        categoryData.custom_record_items.filter(
          (record) =>
            record.visibility === 1 && record.setting_page_visibility === 1
        )
      )
    : [];

  const canSortItems =
    visibleCustomRecords.filter(
      (v) => v.allow_change_order === INT_TRUE_FROM_API
    ).length > 1;

  // 非表示リスト用データ
  const nonVisibleCustomRecords = categoryData.custom_record_items.length
    ? categoryData.custom_record_items.filter(
        (record) =>
          record.visibility === 0 && record.setting_page_visibility === 1
      )
    : [];

  React.useEffect(() => {
    setCommonTableValues(
      tableBodyInitialValuesForService(visibleCustomRecords)
    );
  }, [categoryData.custom_record_items]);

  // 選択肢削除時に画面を更新するための処理
  useEffect(() => {
    const activeRecord = categoryData.custom_record_items.find(
      (record) => record.id === customRecordModalItemId
    );

    setFormValues(
      initialValuesForService(
        categoryData.id,
        CUSTOM_RECORD_TARGET_TYPE.service_delivery,
        activeRecord
      )
    );
  }, [categoryData]);

  // 項目の追加
  const openAddCustomRecordModal = (): void => {
    setFormValues(
      initialValuesForService(
        categoryData.id,
        CUSTOM_RECORD_TARGET_TYPE.service_delivery
      )
    );
    openCustomRecordModal(true);
  };

  // 項目の編集
  const openEditCustomRecordModal = (
    params: CustomRecordItemValues[0]
  ): void => {
    setFormValues(
      initialValuesForService(
        categoryData.id,
        CUSTOM_RECORD_TARGET_TYPE.service_delivery,
        params
      )
    );
    setCustomRecordModalItemId(params.id);
    openCustomRecordModal(true);
  };

  // 項目の追加、編集モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    openCustomRecordModal(false);
  };

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordItemValues
  ): void => {
    const hasChange = !deepEqual(nextValues, commonTableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordItemValues): void => {
    if (!needsStopHistory && sortingCategoryType) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordItemValues,
    actions: FormikActions<CustomRecordItemValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postCustomRecordsOrder(values, categoryData.id)
      .then(() => {
        fetchCustomRecords();
        setSortingCategoryType(null);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  return (
    <>
      <div
        className={
          !sortingCategoryType ||
          sortingCategoryType === categoryData.category_type
            ? classes.editable
            : classes.unEditable
        }
      >
        <FormPaper>
          <SectionTitle label={`【${title}】の項目設定`} />
          {!!description && (
            <div className={classes.description}>{description}</div>
          )}
          <Formik
            initialValues={commonTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                <div className={classes.pureSettingTopSection}>
                  <SettingFormButtons
                    categoryType={categoryData.category_type}
                    sortingCategoryType={sortingCategoryType}
                    setSortingCategoryType={setSortingCategoryType}
                    openAddCustomRecordModal={openAddCustomRecordModal}
                    formikProps={formikProps}
                    formValues={commonTableValues}
                    stopHistory={stopHistory}
                    isSortButtonDisabled={!canSortItems}
                  />
                </div>
                <CustomRecordsTable
                  formValues={commonTableValues}
                  hiddenCustomRecords={nonVisibleCustomRecords}
                  openItemEditModal={openEditCustomRecordModal}
                  openHideConfirmModal={openHideConfirmModal}
                  openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                  categoryType={categoryData.category_type}
                  isSorting={sortingCategoryType === categoryData.category_type}
                  formikProps={formikProps}
                  facilityType={props.facilityType}
                />
              </>
            )}
          </Formik>
        </FormPaper>
      </div>
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory, uiDispatch } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );
  const uiDispatches = uiDispatch(dispatch);

  return {
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordItemValues,
      customRecordCategoryId: number
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsOrder(
        visibleCustomRecords,
        customRecordCategoryId
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const ServiceDeliverySettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ServiceDeliverySettingFormCore)
);
