import { getSupportPlanUifIdCarePlan } from "./getSupportPlanUifIdCarePlan";
import { getSupportPlanUifIdCarePlanCarePlanId } from "./getSupportPlanUifIdCarePlanCarePlanId";
import { postSupportPlanUifIdCarePlanNew } from "./postSupportPlanUifIdCarePlanNew";
import { getAssessment } from "./getAssessment";
import { postSupportPlanUifIdCarePlanCarePlanId } from "./postSupportPlanUifIdCarePlanCarePlanId";
import { deleteSupportPlanUifIdCarePlanCarePlanId } from "./deleteSupportPlanUifIdCarePlanCarePlanId";
import { postSupportPlanUifIdCarePlanCarePlanIdDetails } from "./postSupportPlanUifIdCarePlanCarePlanIdDetails";
import { deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId } from "./deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId";

export const carePlanApi = {
  getSupportPlanUifIdCarePlan,
  getSupportPlanUifIdCarePlanCarePlanId,
  postSupportPlanUifIdCarePlanNew,
  getAssessment,
  postSupportPlanUifIdCarePlanCarePlanId,
  deleteSupportPlanUifIdCarePlanCarePlanId,
  postSupportPlanUifIdCarePlanCarePlanIdDetails,
  deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId
};
