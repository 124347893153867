import { dateValidator } from "@validator";
import {
  SupportPlanSheets,
  BasicErrors
} from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import { checkCharacterLength } from "@validator/rules";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

export const validation = (values: SupportPlanSheets): BasicErrors => {
  return {
    creation_date: dateValidator(
      notSelectedToEmpty(values.creation_date),
      "required"
    ),
    intake: checkCharacterLength(values.intake, 2500),
    assessment_biological: checkCharacterLength(
      values.assessment_biological,
      2500
    ),
    assessment_psychological: checkCharacterLength(
      values.assessment_psychological,
      2500
    ),
    assessment_social: checkCharacterLength(values.assessment_social, 2500),
    support_plan_sheet_details: values.support_plan_sheet_details.map((i) => {
      return {
        support_issue: checkCharacterLength(i.support_issue, 2500),
        response_policy: checkCharacterLength(i.response_policy, 2500)
      };
    })
  };
};
