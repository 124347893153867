/**
 * 指定数値xから指定数値yまでのOptionsを生成 (月のOptions)
 */
const createOptionsBetweenNumbers = (
  from: number,
  to: number,
  format: string,
  hasDefault = true
): { label: string; value: string }[] => {
  const options = hasDefault ? [{ label: "選択してください", value: "" }] : [];
  if (to) {
    for (let i = from; i <= to; i += 1) {
      options.push({ label: `${i} ${format}`, value: `${i}` });
    }
  }
  return options;
};

export default createOptionsBetweenNumbers;
