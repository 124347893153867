export const SET_EDIT = "PAGES/RECORD/DAILY/SET_EDIT";
export const SET_EDIT_WITH_UIF_ID = "PAGES/RECORD/DAILY/SET_EDIT_WITH_UIF_ID";
export const SET_EDIT_WITH_UNIT_ID = "PAGES/RECORD/DAILY/SET_EDIT_WITH_UNIT_ID";
export const UNSET_EDIT = "PAGES/RECORD/DAILY/UNSET_EDIT";

export interface RecordDailyState {
  isEditing: boolean;
  uifId: number | null;
  unitId: number | null;
}
