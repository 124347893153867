import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import GrayLabel from "@components/atoms/GrayLabel";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTextField from "@components/molecules/FormikTextField";
import { InputLabelProps } from "@material-ui/core/InputLabel";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      width: "100%"
    },
    fieldWrapper: {
      padding: "0 16px"
    }
  });

type ChangeEventAlias = React.ChangeEvent<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
>;

type BlurHookEvent = (
  event: React.FormEvent<HTMLInputElement>,
  beforeValue?: string
) => string | void;

// onChange時に実行できるcallback
type ChangeHookEvent = (
  event: ChangeEventAlias,
  beforeValue?: string
) => string | void;

interface OwnProps {
  isEditable: boolean;
  name: string; // formikと紐づけるname
  label?: string;
  labelType?: "gray" | "default"; // ラベル表記方法
  value: string; // 表示用、編集には使わない
  defaultValue: string; // 表示モード時の初期値
  placeholder: string; // 編集モード時のplaceholder
  maxLength?: number;
  style?: React.CSSProperties;
  endAdornmentLabel?: string;
  endAdornment?: React.ReactNode;
  nonMultiline?: boolean;
  onBlurHook?: BlurHookEvent;
  onChangeHook?: ChangeHookEvent;
  InputLabelProps?: Partial<InputLabelProps>;
}

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 記録系ページで使う専用のTextField
 * 表示と編集モードを切り替える
 */
const RecordTextField = ({
  labelType = "gray",
  ...props
}: Props): JSX.Element => {
  const grayLabel =
    props.label && labelType === "gray" ? props.label : undefined;
  const defaultLabel =
    props.label && labelType === "default" ? props.label : undefined;
  return (
    <div style={props.style ? props.style : { width: "100%" }}>
      {grayLabel && <GrayLabel label={grayLabel} />}
      <div className={grayLabel ? props.classes.fieldWrapper : undefined}>
        {props.isEditable ? (
          <FormikTextField
            name={props.name}
            label={defaultLabel}
            placeholder={props.placeholder}
            size="fullSize"
            style={{ marginBottom: 0 }}
            multiline={!props.nonMultiline}
            maxLength={props.maxLength}
            endAdornmentLabel={props.endAdornmentLabel}
            onBlurHook={props.onBlurHook}
            onChangeHook={props.onChangeHook}
            InputLabelProps={props.InputLabelProps}
          />
        ) : (
          <ReadonlyTextField
            value={props.value}
            defaultValue={props.defaultValue}
            label={defaultLabel}
            multiline={!props.nonMultiline}
            endAdornment={props.endAdornmentLabel}
          />
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(RecordTextField);
