import { BasicValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/basic";
import { ServiceUseValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/recipientCertificate";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { DEFAULT_SELECT_VALUE, Checkbox } from "@constants/variables";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues;

// FlgがCheckbox.ONの場合はtrueを返す
const determineTureOrFalse = (value: Checkbox | undefined): boolean => {
  if (value === Checkbox.ON) {
    return true;
  }
  return false;
};

export const initialValues = (
  facilityType: number | null,
  state?: UsersInFacilityState["user"]
): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const userInFacilityKeikakuShogaiji =
    state && state.user_in_facility_keikakusodan
      ? state.user_in_facility_keikakusodan
      : {};
  const KEIKAKUSODAN: Partial<NonNullable<
    ReturnType<typeof findUserInFacilityKeikakusodan>
  >> = (state && findUserInFacilityKeikakusodan(state)) || {};
  const SHOGAIJISODAN: Partial<NonNullable<
    ReturnType<typeof findUserInFacilityShogaijisodan>
  >> = (state && findUserInFacilityShogaijisodan(state)) || {};
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumberKEIKAKUSODAN: undefinedToReturnValue(
        KEIKAKUSODAN.recipient_number
      ),
      noneRecipientNumberFlagKEIKAKUSODAN: determineTureOrFalse(
        KEIKAKUSODAN.none_recipient_number_flg
      ),
      recipientNumberSHOGAIJISODAN: undefinedToReturnValue(
        SHOGAIJISODAN.recipient_number
      ),
      noneRecipientNumberFlagSHOGAIJISODAN: determineTureOrFalse(
        SHOGAIJISODAN.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTureOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTureOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTureOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTureOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTureOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTureOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTureOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianNameSei: undefinedToReturnValue(userInFacility.guardian_name_sei),
      guardianNameMei: undefinedToReturnValue(userInFacility.guardian_name_mei),
      guardianNameSeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_sei_kana
      ),
      guardianNameMeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_mei_kana
      ),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      familySerialNumber: undefinedToReturnValue(
        userInFacilityKeikakuShogaiji.family_serial_number
      ),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: [
      {
        targetType:
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_KEIKAKUSODAN
            ? FACILITY_TYPE_KEIKAKUSODAN
            : undefined,
        inServiceStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_in_service)
          )
        ),
        inServiceEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_in_service)
          )
        ),
        payStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_pay_begin)
          )
        ),
        payEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_pay_end)
          )
        ),
        disabilityClass: undefinedToReturnValue(
          KEIKAKUSODAN.disability_class,
          0
        ),
        needNursingCareClass: undefinedToReturnValue(
          userInFacilityKeikakuShogaiji.need_nursing_care_class,
          0
        ),
        supportRequiredClass: undefinedToReturnValue(
          userInFacilityKeikakuShogaiji.support_required_class,
          0
        ),
        specialAreaFlag: numberToBoolean(KEIKAKUSODAN.special_area_flg),
        specialAreaStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_special_area)
          )
        ),
        specialAreaEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_special_area)
          )
        ),
        disabledWelfareServiceFlag: numberToBoolean(
          KEIKAKUSODAN.disabled_welfare_service_use_flg
        ),
        disabledWelfareServiceFrom: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              KEIKAKUSODAN.disabled_welfare_service_use_from
            )
          )
        ),
        disabledWelfareServiceTo: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.disabled_welfare_service_use_to)
          )
        ),
        handicapppedChildConsultationSupportFlag: numberToBoolean(
          userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_flg
        ),
        handicapppedChildConsultationSupportFrom: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_from
            )
          )
        ),
        handicapppedChildConsultationSupportTo: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_to
            )
          )
        ),
        beforeCreatePlanFlag: numberToBoolean(
          KEIKAKUSODAN.before_create_plan_flg
        ),
        dateBeforeCreatePlan: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_before_create_plan)
          )
        ),
        beforeGoneMonitoringFlag: numberToBoolean(
          KEIKAKUSODAN.before_gone_monitoring_flg
        ),
        dateBeforeGoneMonitoring: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_before_gone_monitoring)
          )
        ),
        incomeKind: undefinedToReturnValue(KEIKAKUSODAN.income_kind, 1),
        incomeClassification: undefinedToReturnValue(
          KEIKAKUSODAN.income_classification,
          0
        ),
        subsidizedFlag: determineTureOrFalse(KEIKAKUSODAN.subsidized_flg),
        subsidizedPercent: undefinedToReturnValue(
          KEIKAKUSODAN.subsidized_percent
        ),
        subsidizedYen: undefinedToReturnValue(KEIKAKUSODAN.subsidized_yen),
        subsidizedUnit: undefinedToReturnValue(
          KEIKAKUSODAN.subsidized_unit_flg,
          1
        ),
        subsidizedCityId: KEIKAKUSODAN.subsidized_city_id
          ? `${KEIKAKUSODAN.subsidized_city_id}`
          : DEFAULT_SELECT_VALUE,
        upperLimitFacilityFlag: determineTureOrFalse(
          KEIKAKUSODAN.uplimit_facility_flg
        ),
        upperLimitControlledBy: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_controlled_by,
          1
        ),
        upperLimitFacilityNumber: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_number
        ),
        upperLimitFacilityNumber2: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_number2
        ),
        upperLimitFacilityNumber3: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_number3
        ),
        upperLimitFacilityNumber4: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_number4
        ),
        upperLimitFacilityName: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_name
        ),
        upperLimitFacilityName2: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_name2
        ),
        upperLimitFacilityName3: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_name3
        ),
        upperLimitFacilityName4: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_facility_name4
        ),
        upperLimitTotalYen: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_total_yen
        ),
        upperLimitTotalYen2: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_total_yen2
        ),
        upperLimitTotalYen3: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_total_yen3
        ),
        upperLimitTotalYen4: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_total_yen4
        ),
        upperLimitUserLoadYen: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_yen
        ),
        upperLimitUserLoadYen2: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_yen2
        ),
        upperLimitUserLoadYen3: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_yen3
        ),
        upperLimitUserLoadYen4: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_yen4
        ),
        uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_after_adjustment_yen
        ),
        uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_after_adjustment_yen2
        ),
        uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_after_adjustment_yen3
        ),
        uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
          KEIKAKUSODAN.uplimit_user_load_after_adjustment_yen4
        ),
        resultOfManagement: undefinedToReturnValue(
          KEIKAKUSODAN.result_of_management,
          2
        ),
        upperLimitYen: undefinedToReturnValue(KEIKAKUSODAN.uplimit_yen)
      },
      {
        targetType:
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_SHOGAIJISODAN
            ? FACILITY_TYPE_SHOGAIJISODAN
            : undefined,
        inServiceStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_begin_in_service)
          )
        ),
        inServiceEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_in_service)
          )
        ),
        payStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_pay_begin)
          )
        ),
        payEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_pay_end)
          )
        ),
        disabilityClass: undefinedToReturnValue(
          SHOGAIJISODAN.disability_class,
          0
        ),
        needNursingCareClass: undefinedToReturnValue(
          userInFacilityKeikakuShogaiji.need_nursing_care_class,
          0
        ),
        supportRequiredClass: undefinedToReturnValue(
          userInFacilityKeikakuShogaiji.support_required_class,
          0
        ),
        specialAreaFlag: numberToBoolean(SHOGAIJISODAN.special_area_flg),
        specialAreaStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_begin_special_area)
          )
        ),
        specialAreaEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_special_area)
          )
        ),
        disabledWelfareServiceFlag: numberToBoolean(
          SHOGAIJISODAN.disabled_welfare_service_use_flg
        ),
        disabledWelfareServiceFrom: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              SHOGAIJISODAN.disabled_welfare_service_use_from
            )
          )
        ),
        disabledWelfareServiceTo: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              SHOGAIJISODAN.disabled_welfare_service_use_to
            )
          )
        ),
        handicapppedChildConsultationSupportFlag: numberToBoolean(
          userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_flg
        ),
        handicapppedChildConsultationSupportFrom: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_from
            )
          )
        ),
        handicapppedChildConsultationSupportTo: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacilityKeikakuShogaiji.handicapped_child_consultation_support_to
            )
          )
        ),
        beforeCreatePlanFlag: numberToBoolean(
          SHOGAIJISODAN.before_create_plan_flg
        ),
        dateBeforeCreatePlan: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_before_create_plan)
          )
        ),
        beforeGoneMonitoringFlag: numberToBoolean(
          SHOGAIJISODAN.before_gone_monitoring_flg
        ),
        dateBeforeGoneMonitoring: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_before_gone_monitoring)
          )
        ),
        incomeKind: undefinedToReturnValue(SHOGAIJISODAN.income_kind, 1),
        incomeClassification: undefinedToReturnValue(
          SHOGAIJISODAN.income_classification,
          0
        ),
        subsidizedFlag: determineTureOrFalse(SHOGAIJISODAN.subsidized_flg),
        subsidizedPercent: undefinedToReturnValue(
          SHOGAIJISODAN.subsidized_percent
        ),
        subsidizedYen: undefinedToReturnValue(SHOGAIJISODAN.subsidized_yen),
        subsidizedUnit: undefinedToReturnValue(
          SHOGAIJISODAN.subsidized_unit_flg,
          1
        ),
        subsidizedCityId: SHOGAIJISODAN.subsidized_city_id
          ? `${SHOGAIJISODAN.subsidized_city_id}`
          : DEFAULT_SELECT_VALUE,
        upperLimitFacilityFlag: determineTureOrFalse(
          SHOGAIJISODAN.uplimit_facility_flg
        ),
        upperLimitControlledBy: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_controlled_by,
          1
        ),
        upperLimitFacilityNumber: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_number
        ),
        upperLimitFacilityNumber2: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_number2
        ),
        upperLimitFacilityNumber3: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_number3
        ),
        upperLimitFacilityNumber4: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_number4
        ),
        upperLimitFacilityName: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_name
        ),
        upperLimitFacilityName2: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_name2
        ),
        upperLimitFacilityName3: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_name3
        ),
        upperLimitFacilityName4: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_facility_name4
        ),
        upperLimitTotalYen: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_total_yen
        ),
        upperLimitTotalYen2: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_total_yen2
        ),
        upperLimitTotalYen3: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_total_yen3
        ),
        upperLimitTotalYen4: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_total_yen4
        ),
        upperLimitUserLoadYen: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_yen
        ),
        upperLimitUserLoadYen2: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_yen2
        ),
        upperLimitUserLoadYen3: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_yen3
        ),
        upperLimitUserLoadYen4: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_yen4
        ),
        uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_after_adjustment_yen
        ),
        uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_after_adjustment_yen2
        ),
        uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_after_adjustment_yen3
        ),
        uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
          SHOGAIJISODAN.uplimit_user_load_after_adjustment_yen4
        ),
        resultOfManagement: undefinedToReturnValue(
          SHOGAIJISODAN.result_of_management,
          2
        ),
        upperLimitYen: undefinedToReturnValue(SHOGAIJISODAN.uplimit_yen)
      }
    ],
    recipientCertificate: [
      {
        targetType:
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_KEIKAKUSODAN
            ? FACILITY_TYPE_KEIKAKUSODAN
            : undefined,
        userChargeLimitFlag: determineTureOrFalse(
          KEIKAKUSODAN.user_charge_limit_flg
        ),
        userChargeLimitStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_user_charge_limit)
          )
        ),
        userChargeLimitEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_user_charge_limit)
          )
        ),
        careSupportAuthFlag: determineTureOrFalse(
          KEIKAKUSODAN.care_support_auth_flg
        ),
        careSupportAuthStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_care_support_auth)
          )
        ),
        careSupportAuthEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_care_support_auth)
          )
        ),
        careSupportPaymentFlag: determineTureOrFalse(
          userInFacility.care_support_payment_flg
        ),
        careSupportPaymentStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacility.date_begin_care_support_payment
            )
          )
        ),
        careSupportPaymentEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(userInFacility.date_end_care_support_payment)
          )
        ),
        planSupportPaymentFlag: determineTureOrFalse(
          KEIKAKUSODAN.plan_support_payment_flg
        ),
        planSupportPaymentStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_plan_support_payment)
          )
        ),
        planSupportPaymentEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_plan_support_payment)
          )
        ),
        planSupportMonitorFlag: determineTureOrFalse(
          KEIKAKUSODAN.plan_support_monitor_flg
        ),
        planSupportMonitorStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_begin_plan_support_monitor)
          )
        ),
        planSupportMonitorEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(KEIKAKUSODAN.date_end_plan_support_monitor)
          )
        )
      },
      {
        targetType:
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_SHOGAIJISODAN
            ? FACILITY_TYPE_SHOGAIJISODAN
            : undefined,
        userChargeLimitFlag: determineTureOrFalse(
          SHOGAIJISODAN.user_charge_limit_flg
        ),
        userChargeLimitStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_begin_user_charge_limit)
          )
        ),
        userChargeLimitEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_user_charge_limit)
          )
        ),
        careSupportAuthFlag: determineTureOrFalse(
          SHOGAIJISODAN.care_support_auth_flg
        ),
        careSupportAuthStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_begin_care_support_auth)
          )
        ),
        careSupportAuthEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_care_support_auth)
          )
        ),
        careSupportPaymentFlag: determineTureOrFalse(
          userInFacility.care_support_payment_flg
        ),
        careSupportPaymentStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              userInFacility.date_begin_care_support_payment
            )
          )
        ),
        careSupportPaymentEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(userInFacility.date_end_care_support_payment)
          )
        ),
        planSupportPaymentFlag: determineTureOrFalse(
          SHOGAIJISODAN.plan_support_payment_flg
        ),
        planSupportPaymentStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              SHOGAIJISODAN.date_begin_plan_support_payment
            )
          )
        ),
        planSupportPaymentEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_plan_support_payment)
          )
        ),
        planSupportMonitorFlag: determineTureOrFalse(
          SHOGAIJISODAN.plan_support_monitor_flg
        ),
        planSupportMonitorStartDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              SHOGAIJISODAN.date_begin_plan_support_monitor
            )
          )
        ),
        planSupportMonitorEndDate: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(SHOGAIJISODAN.date_end_plan_support_monitor)
          )
        )
      }
    ]
  };
};
