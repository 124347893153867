import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { InOutReportMonthly } from "@components/organisms/mgr/CHIIKIIKO/report/InOutReportMonthly";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { FacilityType, INVOICE_VERSION_CURRENT } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { INVOICE_PATHS } from "@constants/mgr/CHIIKIIKO/variables";

type Props = RouteComponentProps<{}, {}, { targetDate: Date }>;

/**
 * 利用実績
 */
export const ReportMonthly = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();

  const currentPageVersion = INVOICE_VERSION_CURRENT;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.CHIIKIIKO,
    currentPageVersion,
    INVOICE_PATHS.reportUsers,
    props.history
  );

  if (transitionDone) {
    return null;
  }

  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <InOutReportMonthly
        initialDate={currentDate}
        history={props.history}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate>
  );
};
