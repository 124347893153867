import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
// ui
import TableCellWrap from "@components/atoms/TableCellWrap";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import DoneIcon from "@material-ui/icons/Done";
// utils
import * as format from "date-fns/format";
import { getStatusLabel } from "@/utils/domain/serviceDelivery/getStatusLabel";
// variables
import { FacilityType } from "@constants/variables";
import {
  DISPLAY_STATUS_OTHER,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";

const styles = (): StyleRules =>
  createStyles({
    shortCell: {
      minWidth: 32,
      width: 32,
      boxSizing: "content-box",
      textAlign: "center",
      "&:last-child": {
        paddingRight: 16
      }
    },
    statusCell: {
      minWidth: 176,
      width: 176,
      boxSizing: "content-box",
      lineHeight: "24px",
      whiteSpace: "pre-line"
    },
    timeCell: {
      minWidth: 140,
      width: 140,
      boxSizing: "content-box",
      lineHeight: "24px",
      whiteSpace: "pre-line"
    },
    unPlannedFlgCell: {
      minWidth: 140,
      width: 140,
      boxSizing: "content-box",
      lineHeight: "24px",
      textAlign: "center"
    },
    numberOfPractitionerCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    icon: {
      textAlign: "center"
    },
    baseCell: {
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 8
    },
    recordedFlg: {
      color: "#2db45a",
      height: "18px",
      width: "18px"
    },
    statusTextBox: {
      display: "flex",
      flexDirection: "column",
      padding: "4px"
    },
    unPlannedIcon: {
      margin: "0 12px 0 auto",
      height: "24px",
      width: "64px",
      backgroundColor: "#757575",
      borderRadius: "12px",
      color: "#fff",
      textAlign: "center",
      fontSize: "12px"
    },
    deleteIcon: {
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    editIcon: {
      color: "#0277bd",
      cursor: "pointer"
    }
  });

type OwnProps = {
  params:
    | ServiceDeliveryState["dailyRecord"]["service_delivery"][0]
    | ServiceDeliveryState["monthlyRecord"]["service_delivery"][0];
  idx: string;
  lastFlg: boolean;
  changeEditMode: (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    uifId: number
  ) => void;
  openDeleteModal: (serviceDeliveryRecordsId: number) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  const statusLabel = getStatusLabel(params.status, FacilityType.IDOSHIEN);

  const changeEditMode = (): void => {
    props.changeEditMode(
      params.service_delivery_records_id || 0,
      params.inout_results_id || 0,
      params.target_date,
      params.users_in_facility_id
    );
  };

  const openDeleteModal = (): void => {
    // 記録済みでない計画はアイコンが表示されないので「params.service_delivery_records_id」は存在する想定
    if (params.service_delivery_records_id) {
      props.openDeleteModal(params.service_delivery_records_id);
    }
  };

  // 「時間・時間数」表示パターン
  const startTime1 = params.start_time_1
    ? format(params.start_time_1, "HH:mm")
    : "--:--";
  const endTime1 = params.end_time_1
    ? format(params.end_time_1, "HH:mm")
    : "--:--";
  const startTime2 = params.start_time_2
    ? format(params.start_time_2, "HH:mm")
    : "--:--";
  const endTime2 = params.end_time_2
    ? format(params.end_time_2, "HH:mm")
    : "--:--";
  const timeDisplayPattern = ((): number => {
    if (
      params.number_of_practitioner === 1 ||
      params.status === stringToNumber(IDOSHIEN_STATUS_LIST.GROUP.value) ||
      params.status ===
        stringToNumber(IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value)
    ) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_2 &&
      !params.end_time_2
    ) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_1 &&
      !params.end_time_1
    ) {
      return 2;
    }
    return 3;
  })();

  // 時間表示用処理
  const TimeCellCore = (): JSX.Element => {
    switch (timeDisplayPattern) {
      case 1:
        return <>{`${startTime1} 〜 ${endTime1}`}</>;
      case 2:
        return <>{`${startTime2} 〜 ${endTime2}`}</>;
      case 3:
        return (
          <>
            {`${startTime1} 〜 ${endTime1}`}
            <br />
            {`${startTime2} 〜 ${endTime2}`}
          </>
        );
      default:
        return <></>;
    }
  };

  // 時間数表示用処理
  const NumberOftimeCellCore = (): JSX.Element => {
    switch (timeDisplayPattern) {
      case 1:
        return <>{params.number_of_time_1 ? params.number_of_time_1 : "-"}</>;
      case 2:
        return <>{params.number_of_time_2 ? params.number_of_time_2 : "-"}</>;
      case 3:
        return (
          <>
            {params.number_of_time_1 ? params.number_of_time_1 : "-"}
            <br />
            {params.number_of_time_2 ? params.number_of_time_2 : "-"}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {/* 記録済み */}
      <TableCellWrap
        key={`${idx}-recorded-flg`}
        cellClass={`${props.classes.shortCell} ${classes.baseCell} ${props.classes.recordedFlg}`}
      >
        {params.recorded_flg === 1 && (
          <DoneIcon className={classes.recordedFlg} />
        )}
      </TableCellWrap>

      {/* サービス内容 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.statusCell} ${classes.baseCell}`}
      >
        <div className={classes.statusTextBox}>
          <div>{statusLabel}</div>
          <div>
            {params.status === DISPLAY_STATUS_OTHER ? params.status_text : ""}
          </div>
        </div>
      </TableCellWrap>

      {/* 人数 */}
      <TableCellWrap
        key={`${idx}-practitioner-number`}
        cellClass={`${classes.numberOfPractitionerCell} ${classes.baseCell}`}
      >
        {params.number_of_practitioner ? params.number_of_practitioner : "-"}
      </TableCellWrap>

      {/* 開始・終了時間 */}
      <TableCellWrap
        key={`${idx}-time`}
        cellClass={`${classes.timeCell} ${classes.baseCell}`}
      >
        <TimeCellCore />
      </TableCellWrap>

      {/* 時間 */}
      <TableCellWrap
        key={`${idx}-numberーofーtime`}
        cellClass={`${classes.timeCell} ${classes.baseCell}`}
      >
        <NumberOftimeCellCore />
      </TableCellWrap>

      {/* 計画外 */}
      <TableCellWrap
        key={`${idx}-unplanned-flg`}
        cellClass={`${classes.unPlannedFlgCell} ${classes.baseCell}`}
      >
        {params.unplanned_flg ? (
          <div className={classes.unPlannedIcon}>計画外</div>
        ) : null}
      </TableCellWrap>

      {/* 削除 */}
      <TableCellWrap
        key={`${idx}-delete-icon`}
        cellClass={`${classes.shortCell} ${classes.baseCell} ${classes.icon}`}
        align="right"
      >
        {params.recorded_flg ? (
          <DeleteIcon
            className={classes.deleteIcon}
            onClick={openDeleteModal}
          />
        ) : null}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-edit-icon`}
        cellClass={`${classes.shortCell} ${classes.baseCell} ${classes.icon}`}
        align="right"
      >
        <Edit className={classes.editIcon} onClick={changeEditMode} />
      </TableCellWrap>
    </>
  );
};

export const ServiceDeliveryTableCells = withStyles(styles)(
  ServiceDeliveryTableCellsCore
);
