import {
  CarePlanStaffState,
  CarePlanState,
  CarePlanDetailState,
  DisplayListState,
  DataListState,
  DisplayStaffsInFacilityState,
  DeleteCarePlanState
} from "@stores/domain/mgr/KYOTAKUKAIGO/carePlan/types";
import {
  GetCarePlanResponse,
  CarePlanItem,
  CarePlanDetailsItem,
  CarePlanList,
  CarePlanStaffsInFacilityItem
} from "@api/requests/facility/getCarePlan";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/mgr/KYOTAKUKAIGO/Users/initial";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  DEFAULT_CHECKBOX_VALUE
} from "@constants/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import {
  KYOTAKUKAIGO_LICENSE_MAPPING_LIST,
  KYOTAKUKAIGO_STATUS_LIST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { FacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/types";
import { cloneDeep } from "lodash-es";
import {
  convertTimeHHMM,
  dateToLocalisedString,
  parseDateString,
  createTime
} from "@utils/date";

type InitBaseInfo = {
  uifId: number;
  sei: string;
  mei: string;
};

// 規定値
const SPECIFIED_DATE = "1900/01/01";

// statusによる不要なデータのstoreKey一覧
const nullDataByStatus = (statusValue: string): string[] => {
  switch (statusValue) {
    case KYOTAKUKAIGO_STATUS_LIST.PHYSICAL.value:
    case KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL.value:
      return ["number_of_boarding_and_alighting"];
    case KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK.value:
    case KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT.value:
      return ["number_of_boarding_and_alighting", "sputum_implementation_flg"];
    case KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value:
      return [
        "sputum_implementation_flg",
        "users_in_facility_care_plan_details1",
        "users_in_facility_care_plan_details2",
        "practitioner1_calculated_hours",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_calculated_hours",
        "whole_calculated_hours",
        "duplicate_calculated_hours",
        "license_same_flg"
      ];
    default:
      return [];
  }
};

const nullDataByParticipants = (
  participants: number,
  license_same_flg: number | null | undefined,
  practitioner1_license: number | null | undefined,
  practitioner2_license: number | null | undefined
): string[] => {
  switch (participants) {
    case 1:
      return [
        "license_same_flg",
        "practitioner2",
        "practitioner2_license",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_calculated_hours",
        "practitioner2_memo",
        "users_in_facility_care_plan_details2",
        "whole_calculated_hours",
        "duplicate_calculated_hours"
      ];
    case 2:
      if (license_same_flg) {
        return [
          "second_person_flg",
          "practitioner2_in_time",
          "practitioner2_out_time",
          "practitioner2_calculated_hours",
          "practitioner2_memo",
          "users_in_facility_care_plan_details2",
          "practitioner1_calculated_hours"
        ];
      }
      if (practitioner1_license === practitioner2_license) {
        return [
          "second_person_flg",
          "practitioner1_calculated_hours",
          "practitioner2_calculated_hours"
        ];
      }
      return [
        "second_person_flg",
        "whole_calculated_hours",
        "duplicate_calculated_hours"
      ];
    default:
      return [];
  }
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  number_of_participants: {
    requestKey: "number_of_participants",
    cooperationKeys: [
      "status",
      "practitioner1",
      "practitioner1_license",
      "practitioner2",
      "practitioner2_license",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "users_in_facility_care_plan_details1",
      "users_in_facility_care_plan_details2",
      "sputum_implementation_flg"
    ]
  },
  practitioner1: {
    requestKey: "practitioner1",
    cooperationKeys: ["number_of_participants"]
  },
  practitioner2: {
    requestKey: "practitioner2",
    cooperationKeys: ["number_of_participants"]
  },
  practitioner2_license: {
    requestKey: "practitioner2_license",
    cooperationKeys: ["practitioner2_memo", "number_of_participants"]
  },
  practitioner1_license: {
    requestKey: "practitioner1_license",
    cooperationKeys: ["practitioner2_memo", "practitioner2_license"]
  },
  practitioner1_in_time: {
    requestKey: "practitioner1_in_time",
    cooperationKeys: [
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "users_in_facility_care_plan_details1",
      "number_of_participants"
    ]
  },
  practitioner1_out_time: {
    requestKey: "practitioner1_out_time",
    cooperationKeys: [
      "practitioner1_in_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "users_in_facility_care_plan_details1",
      "number_of_participants"
    ]
  },
  practitioner2_in_time: {
    requestKey: "practitioner2_in_time",
    cooperationKeys: [
      "practitioner2_out_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "users_in_facility_care_plan_details2",
      "number_of_participants"
    ]
  },
  practitioner2_out_time: {
    requestKey: "practitioner2_out_time",
    cooperationKeys: [
      "practitioner2_in_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "users_in_facility_care_plan_details2",
      "number_of_participants"
    ]
  },
  practitioner1_calculated_hours: {
    requestKey: "practitioner1_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  practitioner2_calculated_hours: {
    requestKey: "practitioner2_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  whole_calculated_hours: {
    requestKey: "whole_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  duplicate_calculated_hours: {
    requestKey: "duplicate_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  time_duplication_judgment_flg: {
    requestKey: "time_duplication_judgment_flg",
    cooperationKeys: [
      "practitioner1_calculated_hours",
      "practitioner2_calculated_hours",
      "whole_calculated_hours",
      "duplicate_calculated_hours"
    ]
  },
  number_of_boarding_and_alighting: {
    requestKey: "number_of_boarding_and_alighting",
    cooperationKeys: ["status"]
  },
  sputum_implementation_flg: {
    requestKey: "sputum_implementation_flg",
    cooperationKeys: ["number_of_participants"]
  }
};

/**
 * 当日/翌日の取得
 */
const getTimeClass = (date?: string, dateTime?: string | null): string => {
  let timeClass = "";
  if (date && dateTime) {
    timeClass =
      dateToLocalisedString(date, "YYYYMMDD") ===
      dateToLocalisedString(dateTime, "YYYYMMDD")
        ? "0"
        : "1";
  }

  return timeClass;
};

const normalizeCarePlanDetail = (
  list: CarePlanDetailsItem[]
): CarePlanDetailState[] => {
  return list
    ? list.map((record) => {
        return {
          id: record.id,
          actionClass: record.action_class,
          inTimeClass: getTimeClass(SPECIFIED_DATE, record.in_time),
          inTime: convertTimeHHMM(parseDateString(record.in_time)),
          outTimeClass: getTimeClass(SPECIFIED_DATE, record.out_time),
          outTime: convertTimeHHMM(parseDateString(record.out_time))
        };
      })
    : [];
};

const nullDataByFacility = (facility: FacilityState): string[] => {
  const target = [];
  // 「特定事業所加算＝（Ⅰ）」を選択しているときは、「喀痰吸引等実施」は非活性
  if (facility.specificFacilitiesAddition === "1") {
    target.push("sputum_implementation_flg");
  }

  return target;
};

/**
 * 明細の差分有無確認
 */
const normalizeDiffDetailParamToPostAPI = (
  before: CarePlanDetailsItem[] | undefined,
  after: CarePlanDetailsItem[] | undefined
): boolean => {
  if (before === undefined || after === undefined) {
    return false;
  }
  if (before.length === 0 && after.length === 0) {
    return false;
  }

  return true;
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

export const normalizeDetailData = (
  recordNo: number | null,
  carePlanItemList: CarePlanItem[],
  baseInfo: InitBaseInfo,
  planRegistered = false
): CarePlanState => {
  const targetRecord = carePlanItemList.filter((row) => {
    return row.id === recordNo && row.users_in_facility_id === baseInfo.uifId;
  });

  const record = recordNo && targetRecord.length === 1 ? targetRecord[0] : null;
  return {
    id: record ? record.id : 0,
    usersInFacilityId: record ? record.users_in_facility_id : baseInfo.uifId,
    dayOfWeek: record ? record.day_of_week : null,
    nameSei: record ? record.name_sei : baseInfo.sei,
    nameMei: record ? record.name_mei : baseInfo.mei,
    status: record && record.status ? `${record.status}` : "",
    numberOfBoardingAndAlighting: record
      ? record.number_of_boarding_and_alighting
      : null,
    numberOfParticipants: record ? record.number_of_participants : null,
    practitioner1: record ? record.practitioner1 : null,
    practitioner1License: record ? record.practitioner1_license : null,
    practitioner1InTime:
      record && record.practitioner1_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_in_time))
        : "",
    practitioner1OutTimeClass: record
      ? getTimeClass(SPECIFIED_DATE, record.practitioner1_out_time)
      : "",
    practitioner1OutTime:
      record && record.practitioner1_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_out_time))
        : "",
    practitioner1CalculatedHours: record
      ? record.practitioner1_calculated_hours
      : null,
    practitioner1Memo: record ? record.practitioner1_memo : "",
    carePlanDetailState1: record
      ? normalizeCarePlanDetail(record.users_in_facility_care_plan_details1)
      : [],
    practitioner2: record ? record.practitioner2 : null,
    practitioner2License: record ? record.practitioner2_license : null,
    practitioner2InTimeClass: record
      ? getTimeClass(SPECIFIED_DATE, record.practitioner2_in_time)
      : "",
    practitioner2InTime:
      record && record.practitioner2_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_in_time))
        : "",
    practitioner2OutTimeClass: record
      ? getTimeClass(SPECIFIED_DATE, record.practitioner2_out_time)
      : "",
    practitioner2OutTime:
      record && record.practitioner2_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_out_time))
        : "",
    practitioner2CalculatedHours: record
      ? record.practitioner2_calculated_hours
      : null,
    practitioner2Memo: record ? record.practitioner2_memo : "",
    carePlanDetailState2: record
      ? normalizeCarePlanDetail(record.users_in_facility_care_plan_details2)
      : [],
    timeDuplicationJudgmentFlg: record
      ? record.time_duplication_judgment_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    wholeCalculatedHours: record ? record.whole_calculated_hours : null,
    duplicateCalculatedHours: record ? record.duplicate_calculated_hours : null,
    licenseSameFlg: record
      ? record.license_same_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    secondPersonFlg: record
      ? record.second_person_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    sputumImplementationFlg: record
      ? record.sputum_implementation_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    planRegisteredFlg: planRegistered
  };
};

export const normalizeDisplayStaffsInFacilityStateFromAPI = (
  data: CarePlanStaffsInFacilityItem[]
): DisplayStaffsInFacilityState[] => {
  // 資格が「-：0」や想定外の場合は選択対象外として除外
  return data && data.length > 0
    ? data
        .filter((record) => {
          return !!KYOTAKUKAIGO_LICENSE_MAPPING_LIST[record.license.toString()];
        })
        .map((record) => {
          return {
            label: record.name,
            value: record.id.toString(),
            license:
              KYOTAKUKAIGO_LICENSE_MAPPING_LIST[record.license.toString()]
          };
        })
    : [];
};

export const initialDataList = (
  record: CarePlanList | null,
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  baseInfo: InitBaseInfo
): DataListState => {
  return {
    no: record ? record.no : null,
    id: record ? record.id : null,
    plan: {
      dispatchNo: record && record.plan ? record.plan.dispatch_no : null,
      status: record && record.plan ? record.plan.status : null,
      inTime: record && record.plan ? record.plan.in_time : null,
      outTime: record && record.plan ? record.plan.out_time : null,
      calculatedHours:
        record && record.plan ? record.plan.calculated_hours : null,
      gettingOnAndOff:
        record && record.plan ? record.plan.getting_on_and_off : null,
      numberOfParticipants:
        record && record.plan ? record.plan.number_of_participants : null
    },
    planDetail: normalizeDetailData(
      record ? record.id : null,
      carePlanList,
      baseInfo
    )
  };
};

export const normalizeDataListFromAPI = (
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  displayList: CarePlanList[],
  baseInfo: InitBaseInfo
): DataListState[] => {
  return displayList
    ? displayList.map((record) => {
        return initialDataList(record, carePlanList, baseInfo);
      })
    : [initialDataList(null, carePlanList, baseInfo)];
};

/**
 * サービス予定データ一覧の整形
 */
export const normalizeDisplayListFromAPI = (
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  displayList: GetCarePlanResponse["data"]["display_list"]
): DisplayListState[] => {
  return displayList.map((record) => {
    const baseInfo: InitBaseInfo = {
      uifId: record.users_in_facility_id,
      sei: record.name_sei,
      mei: record.name_mei
    };

    return {
      usersInFacilityId: record.users_in_facility_id,
      nameSei: record.name_sei,
      nameMei: record.name_mei,
      dayOfWeek: record.day_of_week,
      dataList: normalizeDataListFromAPI(
        carePlanList,
        record.data_list,
        baseInfo
      )
    };
  });
};

/**
 * サービス予定情報
 */
export const normalizeCarePlanDataFromAPI = (
  result: GetCarePlanResponse
): CarePlanStaffState => {
  const normalizeReportList: CarePlanStaffState = {
    staffsInFacility: result.data.staffs_in_facility,
    displayStaffsInFacility: normalizeDisplayStaffsInFacilityStateFromAPI(
      result.data.staffs_in_facility
    ),
    displayList: normalizeDisplayListFromAPI(
      result.data.users_in_facility_care_plan,
      result.data.display_list
    ),
    carePlanCount: result.data.users_in_facility_care_plan
      ? result.data.users_in_facility_care_plan.length
      : 0
  };

  return normalizeReportList;
};

const normalizeCarePlanDetailToAPI = (
  detailList: CarePlanDetailsFields[]
): CarePlanDetailsItem[] => {
  if (!detailList || detailList.length === 0) {
    return [];
  }

  const result = detailList.map((record) => {
    return {
      id: record.id,
      action_class:
        record.inTime && record.outTime ? castNumber(record.actionClass) : -1,
      in_time: createTime(SPECIFIED_DATE, record.inTime, record.inTimeClass),
      out_time: createTime(SPECIFIED_DATE, record.outTime, record.outTimeClass)
    };
  });

  return result.filter((record) => {
    return record.action_class !== -1;
  });
};

/**
 * サービス予定登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    uif_id: formValue.initial.usersInFacilityId,
    params: {
      users_in_facility_care_plan_id: formValue.initial.id,
      day_of_week: formValue.initial.dayOfWeek,
      status: castNumber(formValue.initial.status),
      number_of_boarding_and_alighting:
        formValue.initial.status === "6"
          ? castNumber(formValue.initial.numberOfBoardingAndAlighting)
          : null,
      number_of_participants: castNumber(
        formValue.initial.numberOfParticipants
      ),
      practitioner1: castNumber(formValue.initial.practitioner1),
      practitioner1_license: castNumber(formValue.initial.practitioner1License),
      practitioner1_in_time: createTime(
        SPECIFIED_DATE,
        formValue.initial.practitioner1InTime,
        formValue.initial.practitioner1InTimeClass
      ),
      practitioner1_out_time: createTime(
        SPECIFIED_DATE,
        formValue.initial.practitioner1OutTime,
        formValue.initial.practitioner1OutTimeClass
      ),
      practitioner1_calculated_hours:
        (formValue.initial.numberOfParticipants === "1" &&
          formValue.initial.status !== "6") ||
        (formValue.initial.numberOfParticipants === "2" &&
          formValue.initial.practitioner1License !==
            formValue.initial.practitioner2License &&
          formValue.initial.status !== "6")
          ? castNumber(formValue.initial.practitioner1CalculatedHours)
          : null,
      practitioner1_memo: formValue.initial.practitioner1Memo,
      users_in_facility_care_plan_details1:
        formValue.initial.status === "6"
          ? []
          : normalizeCarePlanDetailToAPI(formValue.initial.carePlanDetails1),
      practitioner2:
        formValue.initial.numberOfParticipants === "1"
          ? null
          : castNumber(formValue.initial.practitioner2),
      practitioner2_license:
        formValue.initial.numberOfParticipants === "1"
          ? null
          : castNumber(formValue.initial.practitioner2License),
      practitioner2_in_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg &&
        formValue.initial.status !== "6"
          ? createTime(
              SPECIFIED_DATE,
              formValue.initial.practitioner2InTime,
              formValue.initial.practitioner2InTimeClass
            )
          : null,
      practitioner2_out_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg &&
        formValue.initial.status !== "6"
          ? createTime(
              SPECIFIED_DATE,
              formValue.initial.practitioner2OutTime,
              formValue.initial.practitioner2OutTimeClass
            )
          : null,
      practitioner2_calculated_hours:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.practitioner1License !==
          formValue.initial.practitioner2License &&
        formValue.initial.status !== "6"
          ? castNumber(formValue.initial.practitioner2CalculatedHours)
          : null,
      practitioner2_memo:
        (formValue.initial.numberOfParticipants === "2" &&
          !formValue.initial.licenseSameFlg &&
          formValue.initial.status !== "6") ||
        (formValue.initial.status === "6" &&
          formValue.initial.practitioner1License !==
            formValue.initial.practitioner2License)
          ? formValue.initial.practitioner2Memo
          : null,
      users_in_facility_care_plan_details2:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg &&
        formValue.initial.status !== "6"
          ? normalizeCarePlanDetailToAPI(formValue.initial.carePlanDetails2)
          : [],
      time_duplication_judgment_flg:
        formValue.initial.numberOfParticipants === "2" &&
        (formValue.initial.practitioner1License ===
          formValue.initial.practitioner2License ||
          formValue.initial.licenseSameFlg) &&
        formValue.initial.status !== "6"
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
      whole_calculated_hours:
        formValue.initial.numberOfParticipants === "2" &&
        (formValue.initial.practitioner1License ===
          formValue.initial.practitioner2License ||
          formValue.initial.licenseSameFlg) &&
        formValue.initial.status !== "6"
          ? castNumber(formValue.initial.wholeCalculatedHours)
          : null,
      duplicate_calculated_hours:
        formValue.initial.numberOfParticipants === "2" &&
        (formValue.initial.practitioner1License ===
          formValue.initial.practitioner2License ||
          formValue.initial.licenseSameFlg) &&
        formValue.initial.status !== "6"
          ? castNumber(formValue.initial.duplicateCalculatedHours)
          : null,
      license_same_flg: formValue.initial.licenseSameFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      second_person_flg: formValue.initial.secondPersonFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      sputum_implementation_flg: formValue.initial.sputumImplementationFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  };

  if (
    formValue.initial.status === "6" ||
    formValue.initial.numberOfParticipants === "1"
  ) {
    result.params.license_same_flg = null;
  }
  if (formValue.initial.numberOfParticipants === "2") {
    result.params.second_person_flg = null;
  }
  if (
    formValue.initial.status === "6" ||
    formValue.initial.status === "5" ||
    formValue.initial.status === "4"
  ) {
    result.params.sputum_implementation_flg = null;
  }

  return result;
};

export const normalizeDiffParamToPostAPI = (
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI,
  facility: FacilityState
): RequestParamToPostAPI => {
  const nullTargetList = nullDataByStatus(
    after.params.status ? after.params.status.toString() : "1"
  )
    .concat(
      nullDataByParticipants(
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg,
        after.params.practitioner1_license,
        after.params.practitioner2_license
      )
    )
    .concat(nullDataByFacility(facility));

  const result: RequestParamToPostAPI = {
    uif_id: after.uif_id,
    params: {
      users_in_facility_care_plan_id:
        after.params.users_in_facility_care_plan_id,
      day_of_week: after.params.day_of_week,
      status: after.params.status,
      time_duplication_judgment_flg: after.params.time_duplication_judgment_flg
    }
  };

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    const nullFlg = nullTargetList.includes(key);
    const detailFlg =
      key === "users_in_facility_care_plan_details1" ||
      key === "users_in_facility_care_plan_details2";
    const nullValue = detailFlg ? [] : null;
    const diffFlg = !detailFlg
      ? before.params[key] !== after.params[key]
      : normalizeDiffDetailParamToPostAPI(
          before.params[key],
          after.params[key]
        );
    if (after.params.users_in_facility_care_plan_id === null) {
      // 初回の場合、null対象はnull、それ以外は入力値をセット
      result.params[key] = nullFlg ? nullValue : after.params[key];
    } else if (diffFlg) {
      result.params[key] = after.params[key];
    }
  });

  const diffDate = addRelationValue(result, after);

  let nullTarget = nullDataByStatus(
    after.params.status ? after.params.status.toString() : "1"
  ).concat(nullDataByFacility(facility));

  if (diffDate.params.number_of_participants) {
    nullTarget = nullTarget.concat(
      nullDataByParticipants(
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg,
        after.params.practitioner1_license,
        after.params.practitioner2_license
      )
    );
  }

  nullTarget.forEach((key) => {
    const detailFlg =
      key === "users_in_facility_care_plan_details1" ||
      key === "users_in_facility_care_plan_details2";
    const nullValue = detailFlg ? [] : null;
    diffDate.params[key] = nullValue;
  });

  return diffDate;
};

/**
 * サービス予定削除
 */
export const normalizeRequestParamToDeleteAPI = (
  target: DeleteCarePlanState
): RequestParamToDeleteAPI => {
  return {
    data: {
      target: [
        {
          users_in_facility_care_plan_id: target.users_in_facility_care_plan_id
        }
      ]
    }
  };
};
