import { UsersInFacilityState } from "./types";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";
import { UsersValues } from "@initialize/mgr/SHUROTEICHAKU/users/initialValues";
import {
  Checkbox,
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API
} from "@constants/variables";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEmpty from "lodash-es/isEmpty";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import booleanToString0or1 from "@utils/dataNormalizer/booleanToString0or1";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import castStringCheckboxValue from "@utils/dataNormalizer/castStringCheckboxValue";
import castStringOrUndefined from "@utils/dataNormalizer/castStringOrUndefined";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityUserTargetIdResponse = UsersInFacilityState["user"];

// パラメータの親子関係マッピング表
const parentParamsMap = {
  user_in_facility: {
    date_end_in_service: {
      key: "date_end_in_service",
      childKeys: ["date_begin_in_service"]
    },
    subsidized_flg: {
      key: "subsidized_flg",
      childKeys: [
        "subsidized_unit_flg",
        "subsidized_percent",
        "subsidized_yen",
        "subsidized_city_id"
      ]
    },
    subsidized_unit_flg: {
      key: "subsidized_unit_flg",
      childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_city_id"]
    },
    subsidized_percent: {
      key: "subsidized_percent",
      childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
    },
    subsidized_yen: {
      key: "subsidized_yen",
      childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
    },
    subsidized_city_id: {
      key: "subsidized_city_id",
      childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_unit_flg"]
    },
    uplimit_facility_flg: {
      key: "uplimit_facility_flg",
      childKeys: [
        "uplimit_controlled_by",
        "uplimit_facility_number",
        "uplimit_facility_number2",
        "uplimit_facility_number3",
        "uplimit_facility_number4",
        "uplimit_facility_name",
        "uplimit_facility_name2",
        "uplimit_facility_name3",
        "uplimit_facility_name4",
        "uplimit_total_yen",
        "uplimit_total_yen2",
        "uplimit_total_yen3",
        "uplimit_total_yen4",
        "uplimit_user_load_yen",
        "uplimit_user_load_yen2",
        "uplimit_user_load_yen3",
        "uplimit_user_load_yen4",
        "uplimit_user_load_after_adjustment_yen",
        "uplimit_user_load_after_adjustment_yen2",
        "uplimit_user_load_after_adjustment_yen3",
        "uplimit_user_load_after_adjustment_yen4",
        "uplimit_yen",
        "result_of_management"
      ]
    },
    uplimit_controlled_by: {
      key: "uplimit_controlled_by",
      childKeys: [
        "uplimit_facility_number",
        "uplimit_facility_number2",
        "uplimit_facility_number3",
        "uplimit_facility_number4",
        "uplimit_facility_name",
        "uplimit_facility_name2",
        "uplimit_facility_name3",
        "uplimit_facility_name4",
        "uplimit_total_yen",
        "uplimit_total_yen2",
        "uplimit_total_yen3",
        "uplimit_total_yen4",
        "uplimit_user_load_yen",
        "uplimit_user_load_yen2",
        "uplimit_user_load_yen3",
        "uplimit_user_load_yen4",
        "uplimit_user_load_after_adjustment_yen",
        "uplimit_user_load_after_adjustment_yen2",
        "uplimit_user_load_after_adjustment_yen3",
        "uplimit_user_load_after_adjustment_yen4",
        "uplimit_yen",
        "result_of_management"
      ]
    },
    result_of_management: {
      key: "result_of_management",
      childKeys: ["uplimit_yen"]
    },
    create_support_plan_flg: {
      key: "create_support_plan_flg",
      childKeys: ["date_start_not_create_support_plan"]
    },
    user_charge_limit_flg: {
      key: "user_charge_limit_flg",
      childKeys: ["date_begin_user_charge_limit", "date_end_user_charge_limit"]
    },
    date_begin_user_charge_limit: {
      key: "date_begin_user_charge_limit",
      childKeys: ["date_end_user_charge_limit"]
    },
    date_end_user_charge_limit: {
      key: "date_end_user_charge_limit",
      childKeys: ["date_begin_user_charge_limit"]
    },
    food_serve_addition_flg: {
      key: "food_serve_addition_flg",
      childKeys: [
        "date_end_food_serve_addition",
        "date_begin_food_serve_addition"
      ]
    },
    date_begin_food_serve_addition: {
      key: "date_begin_food_serve_addition",
      childKeys: ["date_end_food_serve_addition"]
    },
    date_end_food_serve_addition: {
      key: "date_end_food_serve_addition",
      childKeys: ["date_begin_food_serve_addition"]
    },
    care_support_auth_flg: {
      key: "care_support_auth_flg",
      childKeys: ["date_end_care_support_auth", "date_begin_care_support_auth"]
    },
    date_begin_care_support_auth: {
      key: "date_begin_care_support_auth",
      childKeys: ["date_end_care_support_auth"]
    },
    date_end_care_support_auth: {
      key: "date_end_care_support_auth",
      childKeys: ["date_begin_care_support_auth"]
    },
    care_support_payment_flg: {
      key: "care_support_payment_flg",
      childKeys: [
        "date_end_care_support_payment",
        "date_begin_care_support_payment"
      ]
    },
    date_begin_care_support_payment: {
      key: "date_begin_care_support_payment",
      childKeys: ["date_end_care_support_payment"]
    },
    date_end_care_support_payment: {
      key: "date_end_care_support_payment",
      childKeys: ["date_begin_care_support_payment"]
    },
    plan_support_payment_flg: {
      key: "plan_support_payment_flg",
      childKeys: [
        "date_end_plan_support_payment",
        "date_begin_plan_support_payment"
      ]
    },
    date_begin_plan_support_payment: {
      key: "date_begin_plan_support_payment",
      childKeys: ["date_end_plan_support_payment"]
    },
    date_end_plan_support_payment: {
      key: "date_end_plan_support_payment",
      childKeys: ["date_begin_plan_support_payment"]
    },
    plan_support_monitor_flg: {
      key: "plan_support_monitor_flg",
      childKeys: [
        "date_end_plan_support_monitor",
        "date_begin_plan_support_monitor"
      ]
    },
    date_begin_plan_support_monitor: {
      key: "date_begin_plan_support_monitor",
      childKeys: ["date_end_plan_support_monitor"]
    },
    date_end_plan_support_monitor: {
      key: "date_end_plan_support_monitor",
      childKeys: ["date_begin_plan_support_monitor"]
    }
  },
  user_in_facility_shuroteichaku: {
    support_system_not_implemented_flg: {
      key: "support_system_not_implemented_flg",
      childKeys: [
        "support_system_not_implemented_start_date",
        "support_system_not_implemented_end_date"
      ]
    },
    support_system_not_implemented_start_date: {
      key: "support_system_not_implemented_start_date",
      childKeys: ["support_system_not_implemented_end_date"]
    },
    support_system_not_implemented_end_date: {
      key: "support_system_not_implemented_end_date",
      childKeys: ["support_system_not_implemented_start_date"]
    }
  }
};

const normalizeCompanyPersons = (
  companyPersons: GetFacilityUserTargetIdResponse["data"]["company_persons"]
): UsersInFacilityState["user"]["company_persons"] => {
  const companyPersonsValue: UsersInFacilityState["user"]["company_persons"] = [];
  if (companyPersons) {
    companyPersons.forEach((staff) => {
      companyPersonsValue.push({
        ...staff
      });
    });
  }
  return companyPersonsValue;
};

const createCompanyPerson = (
  companyPersons: UsersValues["companies"]["companyPersons"],
  params?: UsersInFacilityState["user"]
): UserInFacilityResult["company_persons"] => {
  const companyPersonsValue: UserInFacilityResult["company_persons"] = [];
  companyPersons.forEach((staff, index) => {
    companyPersonsValue.push({
      id:
        params && params.company_persons && params.company_persons[index]
          ? params.company_persons[index].id
          : undefined,
      users_in_facility_companies_id:
        params && params.company_persons && params.company_persons[index]
          ? params.company_persons[index].users_in_facility_companies_id
          : undefined,
      name: staff.flg && !isEmpty(staff.name) ? staff.name : null,
      position: staff.flg && !isEmpty(staff.position) ? staff.position : null,
      department:
        staff.flg && !isEmpty(staff.department) ? staff.department : null,
      relationship:
        staff.flg && !isEmpty(staff.relationship)
          ? stringToNumber(staff.relationship)
          : null,
      tel: staff.flg && !isEmpty(staff.tel) ? staff.tel : null,
      email: staff.flg && !isEmpty(staff.email) ? staff.email : null
    });
  });
  return companyPersonsValue;
};

/**
 * APIのデータをフォームの形式に成形
 * @param result resultの型がanyである理由は、APIの型がCheckboxのため
 */
export const normalizedGetFacilityUserTargetIdResponse = (
  result: GetFacilityUserTargetIdResponse["data"]
): NormalizedGetFacilityUserTargetIdResponse => {
  const userInFacility = result.user_in_facility;
  const userInFacilitSHUROTEICHAKU = result.user_in_facility_shuroteichaku;
  const { companies } = result;
  const companyPersons = result.company_persons;
  return {
    user_in_facility: {
      ...userInFacility,
      none_recipient_number_flg: castNumberCheckboxValue(
        userInFacility.none_recipient_number_flg
      ),
      classify_physical_flg: castStringCheckboxValue(
        userInFacility.classify_physical_flg
      ),
      classify_intelligence_flg: castStringCheckboxValue(
        userInFacility.classify_intelligence_flg
      ),
      classify_mind_flg: castStringCheckboxValue(
        userInFacility.classify_mind_flg
      ),
      classify_growth_flg: castStringCheckboxValue(
        userInFacility.classify_growth_flg
      ),
      classify_brain_flg: castStringCheckboxValue(
        userInFacility.classify_brain_flg
      ),
      classify_incurable_flg: castStringCheckboxValue(
        userInFacility.classify_incurable_flg
      ),
      classify_handicapped_flg: castStringCheckboxValue(
        userInFacility.classify_handicapped_flg
      ),
      subsidized_flg: castStringCheckboxValue(userInFacility.subsidized_flg),
      subsidized_percent: castStringOrUndefined(
        userInFacility.subsidized_percent
      ),
      subsidized_yen: castStringOrUndefined(userInFacility.subsidized_yen),
      uplimit_controlled_by: userInFacility.uplimit_controlled_by || "1",
      uplimit_facility_flg: castStringCheckboxValue(
        userInFacility.uplimit_facility_flg
      ),
      uplimit_total_yen: castStringOrUndefined(
        userInFacility.uplimit_total_yen
      ),
      uplimit_total_yen2: castStringOrUndefined(
        userInFacility.uplimit_total_yen2
      ),
      uplimit_total_yen3: castStringOrUndefined(
        userInFacility.uplimit_total_yen3
      ),
      uplimit_total_yen4: castStringOrUndefined(
        userInFacility.uplimit_total_yen4
      ),
      uplimit_user_load_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen
      ),
      uplimit_user_load_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen2
      ),
      uplimit_user_load_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen3
      ),
      uplimit_user_load_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimit_user_load_after_adjustment_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimit_user_load_after_adjustment_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimit_user_load_after_adjustment_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimit_user_load_after_adjustment_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      result_of_management: userInFacility.result_of_management || "1",
      uplimit_yen: castStringOrUndefined(userInFacility.uplimit_yen),
      create_support_plan_flg: castStringCheckboxValue(
        userInFacility.create_support_plan_flg
      ),
      user_charge_limit_flg: castNumberCheckboxValue(
        userInFacility.user_charge_limit_flg
      ),
      food_serve_addition_flg: castNumberCheckboxValue(
        userInFacility.food_serve_addition_flg
      ),
      care_support_payment_flg: castNumberCheckboxValue(
        userInFacility.care_support_payment_flg
      ),
      care_support_auth_flg: castNumberCheckboxValue(
        userInFacility.care_support_auth_flg
      ),
      plan_support_payment_flg: castNumberCheckboxValue(
        userInFacility.plan_support_payment_flg
      ),
      plan_support_monitor_flg: castNumberCheckboxValue(
        userInFacility.plan_support_monitor_flg
      )
    },
    user_in_facility_shuroteichaku: {
      ...userInFacilitSHUROTEICHAKU,
      income_kind:
        userInFacilitSHUROTEICHAKU && userInFacilitSHUROTEICHAKU.income_kind
    },
    companies: {
      ...companies
    },
    company_persons: normalizeCompanyPersons(companyPersons)
  };
};

/**
 * 特定のフラグがOFFの場合、APIに送信する項目を削除する
 * @param target
 */
const resultFormat = (target: UserInFacilityResult): UserInFacilityResult => {
  const result = {
    user_in_facility: { ...target.user_in_facility },
    user_in_facility_shuroteichaku: {
      ...target.user_in_facility_shuroteichaku
    },
    companies: { ...target.companies },
    company_persons: target.company_persons
  };

  if (result.user_in_facility.subsidized_unit_flg === "1") {
    result.user_in_facility.subsidized_yen = null;
  }
  if (result.user_in_facility.subsidized_unit_flg === "2") {
    result.user_in_facility.subsidized_percent = null;
  }
  if (result.user_in_facility.income_kind !== "1") {
    result.user_in_facility_shuroteichaku.income_kind = 0;
  }
  if (result.user_in_facility.subsidized_flg === Checkbox.OFF) {
    result.user_in_facility.subsidized_percent = null;
    result.user_in_facility.subsidized_yen = null;
    result.user_in_facility.subsidized_unit_flg = null;
    result.user_in_facility.subsidized_city_id = null;
  }
  if (result.user_in_facility.uplimit_controlled_by === "2") {
    result.user_in_facility.uplimit_total_yen = null;
    result.user_in_facility.uplimit_user_load_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
  }
  if (result.user_in_facility.result_of_management !== "3") {
    result.user_in_facility.uplimit_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
  }
  if (result.user_in_facility.uplimit_facility_flg === Checkbox.OFF) {
    result.user_in_facility.uplimit_facility_name = null;
    result.user_in_facility.uplimit_facility_name2 = null;
    result.user_in_facility.uplimit_facility_name3 = null;
    result.user_in_facility.uplimit_facility_name4 = null;
    result.user_in_facility.uplimit_controlled_by = null;
    result.user_in_facility.uplimit_facility_number = null;
    result.user_in_facility.uplimit_facility_number2 = null;
    result.user_in_facility.uplimit_facility_number3 = null;
    result.user_in_facility.uplimit_facility_number4 = null;
    result.user_in_facility.uplimit_total_yen = null;
    result.user_in_facility.uplimit_total_yen2 = null;
    result.user_in_facility.uplimit_total_yen3 = null;
    result.user_in_facility.uplimit_total_yen4 = null;
    result.user_in_facility.uplimit_user_load_yen = null;
    result.user_in_facility.uplimit_user_load_yen2 = null;
    result.user_in_facility.uplimit_user_load_yen3 = null;
    result.user_in_facility.uplimit_user_load_yen4 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
    result.user_in_facility.uplimit_yen = null;
    result.user_in_facility.result_of_management = null;
  }
  if (result.user_in_facility.create_support_plan_flg === Checkbox.ON) {
    result.user_in_facility.date_start_not_create_support_plan = null;
  }
  if (!result.user_in_facility.user_charge_limit_flg) {
    result.user_in_facility.date_begin_user_charge_limit = null;
    result.user_in_facility.date_end_user_charge_limit = null;
  }
  if (!result.user_in_facility.food_serve_addition_flg) {
    result.user_in_facility.date_begin_food_serve_addition = null;
    result.user_in_facility.date_end_food_serve_addition = null;
  }
  if (!result.user_in_facility.care_support_auth_flg) {
    result.user_in_facility.date_begin_care_support_auth = null;
    result.user_in_facility.date_end_care_support_auth = null;
  }
  if (!result.user_in_facility.care_support_payment_flg) {
    result.user_in_facility.date_begin_care_support_payment = null;
    result.user_in_facility.date_end_care_support_payment = null;
  }
  if (!result.user_in_facility.plan_support_payment_flg) {
    result.user_in_facility.date_begin_plan_support_payment = null;
    result.user_in_facility.date_end_plan_support_payment = null;
  }
  if (!result.user_in_facility.plan_support_monitor_flg) {
    result.user_in_facility.date_begin_plan_support_monitor = null;
    result.user_in_facility.date_end_plan_support_monitor = null;
  }
  if (
    !result.user_in_facility_shuroteichaku.disability_certificate_rehabilitation
  ) {
    result.user_in_facility_shuroteichaku.disability_certificate_rehabilitation_other = null;
  }
  if (
    result.user_in_facility_shuroteichaku.support_system_not_implemented_flg ===
    INT_FALSE_FROM_API
  ) {
    result.user_in_facility_shuroteichaku.support_system_not_implemented_start_date = null;
    result.user_in_facility_shuroteichaku.support_system_not_implemented_end_date = null;
  }

  return result;
};

/**
 * stringの時だけnumberに変換
 */
const stringToNumberOrNull = (value?: string): number | null => {
  return !isEmpty(value) ? Number(value) : null;
};

const CheckboxValueToNumber0or1 = (value?: Checkbox): number => {
  return value === Checkbox.ON ? 1 : 0;
};

/**
 * 差分比較のために
 * APIのパラメータをフォームの形式に整形
 */
export const normalizeApiValue = (
  params: UsersInFacilityState["user"]
): UserInFacilityResult => {
  return {
    user_in_facility: {
      ...params.user_in_facility,
      none_recipient_number_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.none_recipient_number_flg
      ),
      same_corporation_movement_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.same_corporation_movement_flg
      ),
      subsidized_percent: stringToNumber(
        params.user_in_facility.subsidized_percent
      ),
      subsidized_yen: stringToNumber(params.user_in_facility.subsidized_yen),
      uplimit_total_yen: stringToNumberOrNull(
        params.user_in_facility.uplimit_total_yen
      ),
      uplimit_total_yen2: stringToNumberOrNull(
        params.user_in_facility.uplimit_total_yen2
      ),
      uplimit_total_yen3: stringToNumberOrNull(
        params.user_in_facility.uplimit_total_yen3
      ),
      uplimit_total_yen4: stringToNumberOrNull(
        params.user_in_facility.uplimit_total_yen4
      ),
      uplimit_yen: stringToNumberOrNull(params.user_in_facility.uplimit_yen),
      uplimit_user_load_yen: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_yen
      ),
      uplimit_user_load_yen2: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_yen2
      ),
      uplimit_user_load_yen3: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_yen3
      ),
      uplimit_user_load_yen4: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_yen4
      ),
      uplimit_user_load_after_adjustment_yen: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_after_adjustment_yen
      ),
      uplimit_user_load_after_adjustment_yen2: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimit_user_load_after_adjustment_yen3: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimit_user_load_after_adjustment_yen4: stringToNumberOrNull(
        params.user_in_facility.uplimit_user_load_after_adjustment_yen4
      ),
      user_charge_limit_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.user_charge_limit_flg
      ),
      food_serve_addition_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.food_serve_addition_flg
      ),
      care_support_payment_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.care_support_payment_flg
      ),
      care_support_auth_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.care_support_auth_flg
      ),
      plan_support_payment_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.plan_support_payment_flg
      ),
      plan_support_monitor_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.plan_support_monitor_flg
      )
    },
    user_in_facility_shuroteichaku: {
      ...params.user_in_facility_shuroteichaku
    },
    companies: { ...params.companies },
    company_persons: params.company_persons ? params.company_persons : []
  };
};

/**
 * apiのparamが画面に表示する際にデフォルト値に変換されてしまっているため
 * 差分比較のために変換前に戻すメソッド
 * @param apiParamValue
 */
const apiParamFormat = (
  apiParamValue: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const result: UserInFacilityResult = normalizeApiValue(apiParamValue);
  if (result.user_in_facility.subsidized_unit_flg === "1") {
    result.user_in_facility.subsidized_yen = null;
  }
  if (result.user_in_facility.subsidized_unit_flg === "2") {
    result.user_in_facility.subsidized_percent = null;
  }
  if (result.user_in_facility.subsidized_flg === Checkbox.OFF) {
    result.user_in_facility.subsidized_percent = null;
    result.user_in_facility.subsidized_yen = null;
    result.user_in_facility.subsidized_unit_flg = null;
    result.user_in_facility.subsidized_city_id = null;
  }
  if (result.user_in_facility.uplimit_facility_flg === Checkbox.OFF) {
    result.user_in_facility.uplimit_facility_name = null;
    result.user_in_facility.uplimit_facility_name2 = null;
    result.user_in_facility.uplimit_facility_name3 = null;
    result.user_in_facility.uplimit_facility_name4 = null;
    result.user_in_facility.uplimit_controlled_by = null;
    result.user_in_facility.uplimit_facility_number = null;
    result.user_in_facility.uplimit_facility_number2 = null;
    result.user_in_facility.uplimit_facility_number3 = null;
    result.user_in_facility.uplimit_facility_number4 = null;
    result.user_in_facility.uplimit_total_yen = null;
    result.user_in_facility.uplimit_total_yen2 = null;
    result.user_in_facility.uplimit_total_yen3 = null;
    result.user_in_facility.uplimit_total_yen4 = null;
    result.user_in_facility.uplimit_user_load_yen = null;
    result.user_in_facility.uplimit_user_load_yen2 = null;
    result.user_in_facility.uplimit_user_load_yen3 = null;
    result.user_in_facility.uplimit_user_load_yen4 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
    result.user_in_facility.uplimit_yen = null;
    result.user_in_facility.result_of_management = null;
  }
  if (result.user_in_facility.uplimit_controlled_by === "2") {
    result.user_in_facility.uplimit_total_yen = null;
    result.user_in_facility.uplimit_user_load_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
  }
  if (result.user_in_facility.result_of_management !== "3") {
    result.user_in_facility.uplimit_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen2 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen3 = null;
    result.user_in_facility.uplimit_user_load_after_adjustment_yen4 = null;
  }
  if (result.user_in_facility.create_support_plan_flg === Checkbox.ON) {
    result.user_in_facility.date_start_not_create_support_plan = null;
  }
  if (
    result.user_in_facility_shuroteichaku.regional_cooperation_support_flg ===
    INT_FALSE_FROM_API
  ) {
    result.user_in_facility_shuroteichaku.regional_cooperation_support_start_date = null;
  }
  return result;
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param targetParm
 * @param after
 */
const addParentValue = (
  targetParm: UserInFacilityResult,
  after: UserInFacilityResult
): UserInFacilityResult => {
  const target = targetParm;
  Object.keys(parentParamsMap).forEach((uifKey) => {
    Object.keys(parentParamsMap[uifKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[uifKey][paramKey].childKeys
        ? parentParamsMap[uifKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return target[uifKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        target[uifKey][parentParamsMap[uifKey][paramKey].key] =
          after[uifKey][parentParamsMap[uifKey][paramKey].key];
      }
    });
  });
  return target;
};

/**
 * 差分抽出のメソッド
 */
const removeNoChangeData = (
  apiValues: UsersInFacilityState["user"],
  formValuesParam: UserInFacilityResult
): UserInFacilityResult => {
  const formValues = formValuesParam;
  const target: UserInFacilityResult = {
    user_in_facility: {
      id: formValues.user_in_facility.id,
      facility_id: formValues.user_in_facility.facility_id,
      name_sei: undefined,
      name_mei: undefined,
      name_sei_kana: undefined,
      name_mei_kana: undefined,
      recipient_number: undefined,
      none_recipient_number_flg: undefined,
      gender: undefined,
      classify_physical_flg: undefined,
      classify_intelligence_flg: undefined,
      classify_mind_flg: undefined,
      classify_growth_flg: undefined,
      classify_brain_flg: undefined,
      classify_incurable_flg: undefined,
      classify_handicapped_flg: undefined,
      date_birth: formValues.user_in_facility.date_birth,
      postal_code: undefined,
      prefecture_name: undefined,
      city_id: undefined,
      address: undefined,
      tel: undefined,
      email: undefined,
      guardian_name: undefined,
      guardian_relation: undefined,
      guardian_tel: undefined,
      memo: undefined,

      /* サービス詳細 */
      date_begin_in_service: formValues.user_in_facility.date_begin_in_service,
      date_end_in_service: undefined,
      same_corporation_movement_flg: undefined,
      date_pay_begin: formValues.user_in_facility.date_pay_begin,
      date_pay_end: formValues.user_in_facility.date_pay_end,
      income_kind: formValues.user_in_facility.income_kind,
      subsidized_flg: undefined,
      subsidized_percent: undefined,
      subsidized_yen: undefined,
      subsidized_unit_flg: undefined,
      subsidized_city_id: undefined,
      uplimit_facility_flg: undefined,
      uplimit_controlled_by: undefined,
      uplimit_facility_number: undefined,
      uplimit_facility_number2: undefined,
      uplimit_facility_number3: undefined,
      uplimit_facility_number4: undefined,
      uplimit_facility_name: undefined,
      uplimit_facility_name2: undefined,
      uplimit_facility_name3: undefined,
      uplimit_facility_name4: undefined,
      uplimit_total_yen: undefined,
      uplimit_total_yen2: undefined,
      uplimit_total_yen3: undefined,
      uplimit_total_yen4: undefined,
      uplimit_user_load_yen: undefined,
      uplimit_user_load_yen2: undefined,
      uplimit_user_load_yen3: undefined,
      uplimit_user_load_yen4: undefined,
      uplimit_user_load_after_adjustment_yen: undefined,
      uplimit_user_load_after_adjustment_yen2: undefined,
      uplimit_user_load_after_adjustment_yen3: undefined,
      uplimit_user_load_after_adjustment_yen4: undefined,
      result_of_management: undefined,
      uplimit_yen: undefined,
      create_support_plan_flg: undefined,
      date_start_not_create_support_plan:
        formValues.user_in_facility.create_support_plan_flg === "0"
          ? formValues.user_in_facility.date_start_not_create_support_plan
          : undefined,

      /* 受給者証 */
      user_charge_limit_flg: undefined,
      date_begin_user_charge_limit: undefined,
      date_end_user_charge_limit: undefined,
      food_serve_addition_flg: undefined,
      date_begin_food_serve_addition: undefined,
      date_end_food_serve_addition: undefined,
      care_support_payment_flg: undefined,
      date_begin_care_support_payment: undefined,
      date_end_care_support_payment: undefined,
      care_support_auth_flg: undefined,
      date_begin_care_support_auth: undefined,
      date_end_care_support_auth: undefined,
      plan_support_payment_flg: undefined,
      date_begin_plan_support_payment: undefined,
      date_end_plan_support_payment: undefined,
      plan_support_monitor_flg: undefined,
      date_begin_plan_support_monitor: undefined,
      date_end_plan_support_monitor: undefined
    },
    user_in_facility_shuroteichaku: {
      users_in_facility_id:
        formValues.user_in_facility_shuroteichaku.users_in_facility_id,
      income_kind: formValues.user_in_facility_shuroteichaku.income_kind,
      cooperation_promotion_flg: undefined,
      cooperation_promotion_start_date: undefined,
      disability_class: undefined,
      disability_certificate_body: undefined,
      disability_certificate_spirit: undefined,
      disability_certificate_rehabilitation: undefined,
      disability_certificate_rehabilitation_other: undefined,
      classify_incurable_flg: undefined,
      regional_cooperation_support_flg: undefined,
      regional_cooperation_support_start_date: undefined,
      support_system_not_implemented_flg: undefined,
      support_system_not_implemented_start_date: undefined,
      support_system_not_implemented_end_date: undefined
    },
    companies: {
      id: undefined,
      users_in_facility_id: undefined,
      name: undefined,
      industry: undefined,
      overview: undefined,
      address: undefined,
      department: undefined,
      working_start_date: undefined,
      remarks: undefined
    },
    company_persons: [{}, {}]
  };

  const apiValue = apiParamFormat(apiValues);
  if (formValues.companies) {
    // 就労開始年月日のみAPIの型と合わないため
    formValues.companies.working_start_date =
      formValues.companies.working_start_date === ""
        ? null
        : formValues.companies.working_start_date;
  }
  // 差分の項目を抽出 必須項目は除く
  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${apiValue[key][param]}` !== `${formValues[key][param]}`) {
          target[key][param] = formValues[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });

  if (formValues.company_persons) {
    // company_personsの差分更新
    formValues.company_persons.forEach((staff, index) => {
      const diffCompanyPersons = {};
      Object.keys(staff).forEach((key) => {
        if (apiValue.company_persons) {
          if (
            !apiValue.company_persons[index] ||
            (apiValue.company_persons[index] &&
              apiValue.company_persons[index][key] !== staff[key])
          ) {
            diffCompanyPersons[key] = staff[key];
          }
        }
      });
      if (target.company_persons) {
        target.company_persons.splice(index, 1, diffCompanyPersons);
      }
    });
  }

  const diffDate = addParentValue(target, formValues);
  // 特殊項目の差分比較
  // 自治体助成金の単位に変更があったら隠れている項目をnullにして送信する
  if (diffDate.user_in_facility.subsidized_unit_flg === "1") {
    diffDate.user_in_facility.subsidized_yen = null;
  }
  if (diffDate.user_in_facility.subsidized_unit_flg === "2") {
    diffDate.user_in_facility.subsidized_percent = null;
  }
  // 定着支援連携促進加算をOFFに変更した場合、算定開始日をnullにして送信する
  if (
    diffDate.user_in_facility_shuroteichaku.cooperation_promotion_flg ===
    INT_FALSE_FROM_API
  ) {
    diffDate.user_in_facility_shuroteichaku.cooperation_promotion_start_date = null;
  }
  // 上限管理事業所ありの項目に変更があったら
  // 事業所番号と事業所名は入力値を送信する
  if (diffDate.user_in_facility.uplimit_facility_flg === "1") {
    diffDate.user_in_facility.uplimit_facility_number =
      formValues.user_in_facility.uplimit_facility_number;
    diffDate.user_in_facility.uplimit_facility_name =
      formValues.user_in_facility.uplimit_facility_name;
  }
  // 地域連携会議実施加算をOFFに変更した場合、算定開始日をnullにして送信する
  if (
    diffDate.user_in_facility_shuroteichaku.regional_cooperation_support_flg ===
    INT_FALSE_FROM_API
  ) {
    diffDate.user_in_facility_shuroteichaku.regional_cooperation_support_start_date = null;
  }
  return diffDate;
};

/**
 * パラメータ内のundefinedはパラメータから除外する
 * @param data
 * @param formData
 */
const removeNullParam = (
  data: UserInFacilityResult,
  formData: UserInFacilityResult
): UserInFacilityResult => {
  const targetParm: UserInFacilityResult = {
    user_in_facility: {},
    user_in_facility_shuroteichaku: {},
    companies: {},
    company_persons: []
  };
  // 引数のデータからuser_in_facility,user_in_facility_shuroteichaku
  // companies,company_personsを取得
  Object.keys(data).forEach((key) => {
    // user_in_facility,user_in_facility_shuroteichaku
    // companies,company_personsからparamを取得
    Object.keys(data[key]).forEach((param) => {
      if (data[key][param] !== undefined) {
        targetParm[key][param] = data[key][param];
      }
    });
  });

  // companiesオブジェクトに差分がある場合idとusers_in_facility_idを追加
  if (
    targetParm.companies &&
    formData.companies &&
    !isEmpty(targetParm.companies)
  ) {
    targetParm.companies.id = formData.companies.id;
    targetParm.companies.users_in_facility_id =
      formData.companies.users_in_facility_id;
  }

  const resultCompanyPersons: UserInFacilityResult["company_persons"] = [];
  // company_personsオブジェクトに差分がある場合idとusers_in_facility_companies_idを追加
  if (!targetParm.company_persons) {
    targetParm.company_persons = resultCompanyPersons;
  } else {
    targetParm.company_persons.forEach((companyPersons, index) => {
      if (
        targetParm.company_persons &&
        formData.company_persons &&
        !isEmpty(companyPersons)
      ) {
        targetParm.company_persons[index].id =
          formData.company_persons[index].id;
        targetParm.company_persons[index].users_in_facility_companies_id =
          formData.company_persons[index].users_in_facility_companies_id;
        resultCompanyPersons.push(targetParm.company_persons[index]);
      }
    });
    targetParm.company_persons = resultCompanyPersons;
  }

  return targetParm;
};

/**
 * フォームの結果をAPIに送れる形式に整形
 */
export const normalizeFormValue = (
  values: UsersValues,
  params?: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const target: UserInFacilityResult = {
    user_in_facility: {
      id: params ? params.user_in_facility.id : undefined,
      facility_id: params ? params.user_in_facility.facility_id : undefined,

      /* 基本情報 */
      name_sei: values.basic.nameSei,
      name_mei: values.basic.nameMei,
      name_sei_kana: values.basic.nameSeiKana,
      name_mei_kana: values.basic.nameMeiKana,
      recipient_number: isEmpty(values.basic.recipientNumber)
        ? null
        : values.basic.recipientNumber,
      none_recipient_number_flg: booleanToNumber0or1(
        values.basic.noneRecipientNumberFlag
      ),
      gender: values.basic.gender,
      classify_physical_flg: booleanToString0or1(
        values.basic.classifyPhysicalFlag
      ),
      classify_intelligence_flg: booleanToString0or1(
        values.basic.classifyIntelligenceFlag
      ),
      classify_mind_flg: booleanToString0or1(values.basic.classifyMindFlag),
      classify_growth_flg: booleanToString0or1(values.basic.classifyGrowthFlag),
      classify_brain_flg: booleanToString0or1(values.basic.classifyBrainFlag),
      classify_incurable_flg: booleanToString0or1(
        values.basic.classifyIncurableFlag
      ),
      classify_handicapped_flg: booleanToString0or1(
        values.basic.classifyHandicappedFlag
      ),
      date_birth: selectDateValueToDatePaddingZero(values.basic.dateOfBirth),
      postal_code: values.basic.postalCode,
      prefecture_name: values.basic.prefectureId,
      city_id: stringToNumber(values.basic.cityId),
      address: values.basic.restAddress,
      tel: isEmpty(values.basic.tel) ? null : values.basic.tel,
      email: isEmpty(values.basic.email) ? null : values.basic.email,
      guardian_name: isEmpty(values.basic.guardianName)
        ? null
        : values.basic.guardianName,
      guardian_relation: isEmpty(values.basic.guardianRelation)
        ? null
        : values.basic.guardianRelation,
      guardian_tel: isEmpty(values.basic.guardianTel)
        ? null
        : values.basic.guardianTel,
      memo: isEmpty(values.basic.memo) ? null : values.basic.memo,

      /* サービス詳細 */
      date_begin_in_service: selectDateValueToDatePaddingZero(
        values.serviceUse.inServiceStartDate
      ),
      date_end_in_service: selectDateValueToDatePaddingZero(
        values.serviceUse.inServiceEndDate
      ),
      same_corporation_movement_flg: booleanToNumber0or1(
        values.serviceUse.sameCorporationMovementFlg
      ),
      date_pay_begin: selectDateValueToDatePaddingZero(
        values.serviceUse.payStartDate
      ),
      date_pay_end: selectDateValueToDatePaddingZero(
        values.serviceUse.payEndDate
      ),
      income_kind: values.serviceUse.incomeKind,
      subsidized_flg: booleanToString0or1(values.serviceUse.subsidizedFlag),
      subsidized_percent:
        values.serviceUse.subsidizedUnit === "1"
          ? stringToNumber(values.serviceUse.subsidizedPercent)
          : undefined,
      subsidized_yen:
        values.serviceUse.subsidizedUnit === "2"
          ? stringToNumber(values.serviceUse.subsidizedYen)
          : undefined,
      subsidized_unit_flg: values.serviceUse.subsidizedUnit,
      subsidized_city_id: stringToNumber(values.serviceUse.subsidizedCityId),
      uplimit_facility_flg: booleanToString0or1(
        values.serviceUse.upperLimitFacilityFlag
      ),
      uplimit_controlled_by: values.serviceUse.upperLimitControlledBy,
      uplimit_facility_number: values.serviceUse.upperLimitFacilityNumber,
      uplimit_facility_number2: values.serviceUse.upperLimitFacilityNumber2,
      uplimit_facility_number3: values.serviceUse.upperLimitFacilityNumber3,
      uplimit_facility_number4: values.serviceUse.upperLimitFacilityNumber4,
      uplimit_facility_name: values.serviceUse.upperLimitFacilityName,
      uplimit_facility_name2: values.serviceUse.upperLimitFacilityName2,
      uplimit_facility_name3: values.serviceUse.upperLimitFacilityName3,
      uplimit_facility_name4: values.serviceUse.upperLimitFacilityName4,
      uplimit_total_yen: isEmpty(values.serviceUse.upperLimitTotalYen)
        ? null
        : stringToNumber(values.serviceUse.upperLimitTotalYen),
      uplimit_total_yen2: isEmpty(values.serviceUse.upperLimitTotalYen2)
        ? null
        : stringToNumber(values.serviceUse.upperLimitTotalYen2),
      uplimit_total_yen3: isEmpty(values.serviceUse.upperLimitTotalYen3)
        ? null
        : stringToNumber(values.serviceUse.upperLimitTotalYen3),
      uplimit_total_yen4: isEmpty(values.serviceUse.upperLimitTotalYen4)
        ? null
        : stringToNumber(values.serviceUse.upperLimitTotalYen4),
      uplimit_user_load_yen: isEmpty(values.serviceUse.upperLimitUserLoadYen)
        ? null
        : stringToNumber(values.serviceUse.upperLimitUserLoadYen),
      uplimit_user_load_yen2: isEmpty(values.serviceUse.upperLimitUserLoadYen2)
        ? null
        : stringToNumber(values.serviceUse.upperLimitUserLoadYen2),
      uplimit_user_load_yen3: isEmpty(values.serviceUse.upperLimitUserLoadYen3)
        ? null
        : stringToNumber(values.serviceUse.upperLimitUserLoadYen3),
      uplimit_user_load_yen4: isEmpty(values.serviceUse.upperLimitUserLoadYen4)
        ? null
        : stringToNumber(values.serviceUse.upperLimitUserLoadYen4),
      uplimit_user_load_after_adjustment_yen: isEmpty(
        values.serviceUse.uplimitUserLoadAfterAdjustmentYen
      )
        ? null
        : stringToNumber(values.serviceUse.uplimitUserLoadAfterAdjustmentYen),
      uplimit_user_load_after_adjustment_yen2: isEmpty(
        values.serviceUse.uplimitUserLoadAfterAdjustmentYen2
      )
        ? null
        : stringToNumber(values.serviceUse.uplimitUserLoadAfterAdjustmentYen2),
      uplimit_user_load_after_adjustment_yen3: isEmpty(
        values.serviceUse.uplimitUserLoadAfterAdjustmentYen3
      )
        ? null
        : stringToNumber(values.serviceUse.uplimitUserLoadAfterAdjustmentYen3),
      uplimit_user_load_after_adjustment_yen4: isEmpty(
        values.serviceUse.uplimitUserLoadAfterAdjustmentYen4
      )
        ? null
        : stringToNumber(values.serviceUse.uplimitUserLoadAfterAdjustmentYen4),
      result_of_management: values.serviceUse.resultOfManagement,
      uplimit_yen: stringToNumber(values.serviceUse.upperLimitYen),
      create_support_plan_flg: values.serviceUse.createSupportPlanFlag
        ? Checkbox.OFF
        : Checkbox.ON,
      date_start_not_create_support_plan: selectDateValueToDatePaddingZero(
        values.serviceUse.notCreateSupportPlanStartDate
      ),

      /* 受給者証 */
      user_charge_limit_flg: booleanToNumber0or1(
        values.recipientCertificate.userChargeLimitFlag
      ),
      date_begin_user_charge_limit: selectDateValueToDatePaddingZero(
        values.recipientCertificate.userChargeLimitStartDate
      ),
      date_end_user_charge_limit: selectDateValueToDatePaddingZero(
        values.recipientCertificate.userChargeLimitEndDate
      ),
      food_serve_addition_flg: booleanToNumber0or1(
        values.recipientCertificate.foodServeAdditionFlg
      ),
      date_begin_food_serve_addition: selectDateValueToDatePaddingZero(
        values.recipientCertificate.foodServeAdditionStartDate
      ),
      date_end_food_serve_addition: selectDateValueToDatePaddingZero(
        values.recipientCertificate.foodServeAdditionEndDate
      ),
      care_support_payment_flg: booleanToNumber0or1(
        values.recipientCertificate.careSupportPaymentFlag
      ),
      date_begin_care_support_payment: selectDateValueToDatePaddingZero(
        values.recipientCertificate.careSupportPaymentStartDate
      ),
      date_end_care_support_payment: selectDateValueToDatePaddingZero(
        values.recipientCertificate.careSupportPaymentEndDate
      ),
      care_support_auth_flg: booleanToNumber0or1(
        values.recipientCertificate.careSupportAuthFlag
      ),
      date_begin_care_support_auth: selectDateValueToDatePaddingZero(
        values.recipientCertificate.careSupportAuthStartDate
      ),
      date_end_care_support_auth: selectDateValueToDatePaddingZero(
        values.recipientCertificate.careSupportAuthEndDate
      ),
      plan_support_payment_flg: booleanToNumber0or1(
        values.recipientCertificate.planSupportPaymentFlag
      ),
      date_begin_plan_support_payment: selectDateValueToDatePaddingZero(
        values.recipientCertificate.planSupportPaymentStartDate
      ),
      date_end_plan_support_payment: selectDateValueToDatePaddingZero(
        values.recipientCertificate.planSupportPaymentEndDate
      ),
      plan_support_monitor_flg: booleanToNumber0or1(
        values.recipientCertificate.planSupportMonitorFlag
      ),
      date_begin_plan_support_monitor: selectDateValueToDatePaddingZero(
        values.recipientCertificate.planSupportMonitorStartDate
      ),
      date_end_plan_support_monitor: selectDateValueToDatePaddingZero(
        values.recipientCertificate.planSupportMonitorEndDate
      )
    },
    user_in_facility_shuroteichaku: {
      users_in_facility_id:
        params && params.user_in_facility_shuroteichaku
          ? params.user_in_facility_shuroteichaku.users_in_facility_id
          : undefined,
      income_kind: stringToNumber(values.serviceUse.incomeKindType),
      support_system_not_implemented_flg: values.serviceUse
        .supportSystemNotImplementedFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      support_system_not_implemented_start_date: selectDateValueToDatePaddingZero(
        values.serviceUse.supportSystemNotImplementedStartDate
      ),
      support_system_not_implemented_end_date: selectDateValueToDatePaddingZero(
        values.serviceUse.supportSystemNotImplementedEndDate
      ),
      disability_class: stringToNumber(values.serviceUse.disabilityClass),
      disability_certificate_body: stringToNumber(
        values.basic.disabilityClassBody
      ),
      disability_certificate_spirit: stringToNumber(
        values.basic.disabilityClassSpirit
      ),
      disability_certificate_rehabilitation: stringToNumber(
        values.basic.disabilityClassRehabilitation
      ),
      disability_certificate_rehabilitation_other:
        values.basic.disabilityClassRehabilitationOther,
      classify_incurable_flg: values.basic.classifyIncurableFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      regional_cooperation_support_flg: values.serviceUse
        .regionalCooperationSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      regional_cooperation_support_start_date: selectDateValueToDatePaddingZero(
        values.serviceUse.regionalCooperationSupportStartDate
      )
    },
    companies: {
      id: params && params.companies ? params.companies.id : undefined,
      users_in_facility_id:
        params && params.companies
          ? params.companies.users_in_facility_id
          : undefined,
      name: isEmpty(values.companies.name) ? null : values.companies.name,
      industry: isEmpty(values.companies.industry)
        ? null
        : values.companies.industry,
      overview: isEmpty(values.companies.overview)
        ? null
        : values.companies.overview,
      address: isEmpty(values.companies.address)
        ? null
        : values.companies.address,
      department: isEmpty(values.companies.department)
        ? null
        : values.companies.department,
      working_start_date: selectDateValueToDatePaddingZero(
        values.companies.workingStartDate
      ),
      remarks: isEmpty(values.companies.remarks)
        ? null
        : values.companies.remarks
    },
    company_persons: createCompanyPerson(
      values.companies.companyPersons,
      params
    )
  };

  const normalized: UserInFacilityResult = resultFormat(target);
  let postValue;
  // 更新の場合は差分抽出のメソッドへ
  if (params) {
    const targetParm = removeNoChangeData(params, normalized);
    postValue = removeNullParam(targetParm, normalized);
  } else {
    postValue = normalized;
  }

  return postValue;
};

/**
 * /mgr/facility/users/{id} の結果
 */
export type UserInFacilityResult = {
  user_in_facility: {
    id?: number | null;
    facility_id?: number | null;
    name_sei?: string | null;
    name_mei?: string | null;
    name_sei_kana?: string | null;
    name_mei_kana?: string | null;
    recipient_number?: string | null;
    none_recipient_number_flg?: number | null;
    gender?: string | null;
    classify_physical_flg?: string | null;
    classify_intelligence_flg?: string | null;
    classify_mind_flg?: string | null;
    classify_growth_flg?: string | null;
    classify_brain_flg?: string | null;
    classify_incurable_flg?: string | null;
    classify_handicapped_flg?: string | null;
    date_birth?: string | null;
    postal_code?: string | null;
    prefecture_name?: string | null;
    city_id?: number | null;
    address?: string | null;
    tel?: string | null;
    email?: string | null;
    guardian_name?: string | null;
    guardian_relation?: string | null;
    guardian_tel?: string | null;
    memo?: string | null;

    /* サービス詳細 */

    date_begin_in_service?: string | null;
    date_end_in_service?: string | null;
    same_corporation_movement_flg?: number | null;
    date_pay_begin?: string | null;
    date_pay_end?: string | null;
    income_kind?: string | null;
    subsidized_flg?: string | null;
    subsidized_percent?: number | null;
    subsidized_yen?: number | null;
    subsidized_unit_flg?: string | null;
    subsidized_city_id?: number | null;
    uplimit_facility_flg?: string | null;
    uplimit_controlled_by?: string | null;
    uplimit_facility_number?: string | null;
    uplimit_facility_number2?: string | null;
    uplimit_facility_number3?: string | null;
    uplimit_facility_number4?: string | null;
    uplimit_facility_name?: string | null;
    uplimit_facility_name2?: string | null;
    uplimit_facility_name3?: string | null;
    uplimit_facility_name4?: string | null;
    uplimit_total_yen?: number | null;
    uplimit_total_yen2?: number | null;
    uplimit_total_yen3?: number | null;
    uplimit_total_yen4?: number | null;
    uplimit_user_load_yen?: number | null;
    uplimit_user_load_yen2?: number | null;
    uplimit_user_load_yen3?: number | null;
    uplimit_user_load_yen4?: number | null;
    uplimit_user_load_after_adjustment_yen?: number | null;
    uplimit_user_load_after_adjustment_yen2?: number | null;
    uplimit_user_load_after_adjustment_yen3?: number | null;
    uplimit_user_load_after_adjustment_yen4?: number | null;
    result_of_management?: string | null;
    uplimit_yen?: number | null;
    create_support_plan_flg?: string | null;
    date_start_not_create_support_plan?: string | null;

    /* 受給者証 */

    user_charge_limit_flg?: number | null;
    date_begin_user_charge_limit?: string | null;
    date_end_user_charge_limit?: string | null;
    food_serve_addition_flg?: number | null;
    date_begin_food_serve_addition?: string | null;
    date_end_food_serve_addition?: string | null;
    care_support_payment_flg?: number | null;
    date_begin_care_support_payment?: string | null;
    date_end_care_support_payment?: string | null;
    care_support_auth_flg?: number | null;
    date_begin_care_support_auth?: string | null;
    date_end_care_support_auth?: string | null;
    plan_support_payment_flg?: number | null;
    date_begin_plan_support_payment?: string | null;
    date_end_plan_support_payment?: string | null;
    plan_support_monitor_flg?: number | null;
    date_begin_plan_support_monitor?: string | null;
    date_end_plan_support_monitor?: string | null;
  };
  user_in_facility_shuroteichaku: Partial<{
    users_in_facility_id: number | null;
    income_kind: number | null;
    cooperation_promotion_flg: number;
    cooperation_promotion_start_date: string | null;
    disability_class?: number | null;
    disability_certificate_body?: number | null;
    disability_certificate_spirit?: number | null;
    disability_certificate_rehabilitation?: number | null;
    disability_certificate_rehabilitation_other?: string | null;
    classify_incurable_flg?: number;
    regional_cooperation_support_flg?: number;
    regional_cooperation_support_start_date?: string | null;
    support_system_not_implemented_flg: number;
    support_system_not_implemented_start_date: string | null;
    support_system_not_implemented_end_date: string | null;
  }>;
  companies?: Partial<{
    id: number | null;
    users_in_facility_id: number | null;
    name: string | null;
    industry: string | null;
    overview: string | null;
    address: string | null;
    department: string | null;
    working_start_date: string | null;
    remarks: string | null;
  }>;
  company_persons?: Partial<{
    id: number | null;
    users_in_facility_companies_id: number | null;
    name: string | null;
    position: string | null;
    department: string | null;
    relationship: number | null;
    tel: string | null;
    email: string | null;
  }>[];
};
