import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: ""
    },
    staffsInFacility: [],
    displayStaffsInFacility: [],
    displayList: [],
    planCount: 0
  },
  reportMonthly: {
    summary: {
      payTimesIndividualSupport: 0,
      payMonthsIndividualSupport: 0,
      payTimesGroupSupport: 0,
      payMonthsGroupSupport: 0,
      payTimesVehicleTransferSupport: 0,
      payMonthsVehicleTransferSupport: 0,
      payTimesSchoolAndHospitalCommutingSupport: 0,
      payMonthsSchoolAndHospitalCommutingSupport: 0,
      payTimesOtherSupport: 0,
      payMonthsOtherSupport: 0
    },
    staffsInFacility: [],
    displayStaffsInFacility: [],
    numberOfRecords: 0,
    planCount: 0,
    displayList: []
  }
};

export const IDOSHIENReport = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_IDOSHIEN_DAILY_STARTED:
      return { ...state };
    case types.FETCH_IDOSHIEN_DAILY:
      return {
        ...state,
        reportDaily: action.payload
      };
    case types.FETCH_IDOSHIEN_DAILY_FAILED:
      return { ...state };
    case types.FETCH_IDOSHIEN_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_IDOSHIEN_MONTHLY:
      return {
        ...state,
        reportMonthly: action.payload
      };
    case types.FETCH_IDOSHIEN_MONTHLY_FAILED:
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
};
