import * as React from "react";

import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FieldArray, FormikProps } from "formik";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import {
  generateRelativeInfoInitialValues,
  SupportLedger,
  RelativesInfo
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: "32px"
    },
    card: {
      margin: "4px 0 8px",
      border: "1px solid #bdbdbd",
      borderRadius: "4px",
      padding: "24px 32px"
    },
    cardLabel: {
      fontSize: "14px",
      lineHeight: 1.71,
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0
    },
    row: {
      display: "flex",
      gap: "16px",
      marginTop: "8px"
    },
    cell: {
      width: "240px"
    }
  });

type OwnProps = {
  relativesInfoData: RelativesInfo[];
  name: string;
  label: string;
  formikProps: FormikProps<SupportLedger>;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerRelativesInfoCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  return (
    <div className={classes.wrapper}>
      <FieldArray name={props.name}>
        {(arrayHelper): JSX.Element => {
          let displayDataCount = 0;
          const handleClickAddRelativeInfo = (): void => {
            arrayHelper.push(generateRelativeInfoInitialValues());
          };
          const handleClickDeleteRelativeInfo = (index: number): void => {
            const newData = Array.from(props.relativesInfoData);
            const targetData = newData[index];
            if (targetData.id) {
              newData[index].isDelete = true;
              newData[index].address = "";
              newData[index].age = "";
              newData[index].contact_address = "";
              newData[index].name = "";
              newData[index].profession = "";
              newData[index].relationship = "";
              newData[index].remarks = "";
            } else {
              newData.splice(index, 1);
            }
            props.formikProps.setFieldValue(props.name, newData);
          };
          return (
            <>
              {props.relativesInfoData.map((data, index) => {
                if (data.isDelete) {
                  return null;
                }
                displayDataCount += 1;
                const key = `${props.name}_${index}`;
                return (
                  <div key={key} className={classes.card}>
                    <p className={classes.cardLabel}>
                      {props.label}
                      {displayDataCount}
                    </p>
                    <div className={classes.row}>
                      <div className={classes.cell}>
                        <RecordTextField
                          label="続柄"
                          value={data.relationship || ""}
                          labelType="default"
                          defaultValue=""
                          name={`${props.name}[${index}].relationship`}
                          isEditable={props.isEditing}
                          placeholder=""
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                      <div className={classes.cell}>
                        <RecordTextField
                          label="氏名"
                          value={data.name || ""}
                          labelType="default"
                          defaultValue=""
                          name={`${props.name}[${index}].name`}
                          isEditable={props.isEditing}
                          placeholder=""
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                      <div className={classes.cell}>
                        <RecordTextField
                          label="年齢"
                          value={data.age || ""}
                          labelType="default"
                          defaultValue=""
                          name={`${props.name}[${index}].age`}
                          isEditable={props.isEditing}
                          placeholder=""
                          style={{ marginBottom: 0 }}
                        />
                      </div>
                    </div>
                    <RecordTextField
                      label="職業"
                      value={data.profession || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].profession`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="住所"
                      value={data.address || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].address`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="連絡先"
                      value={data.contact_address || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].contact_address`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="備考"
                      value={data.remarks || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].remarks`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    {props.isEditing ? (
                      <KnowbeButton
                        kind="iconText"
                        onClick={(): void =>
                          handleClickDeleteRelativeInfo(index)
                        }
                        style={{ marginTop: "24px" }}
                      >
                        <DeleteOutlinedIcon />
                        削除する
                      </KnowbeButton>
                    ) : null}
                  </div>
                );
              })}
              {props.isEditing ? (
                <KnowbeButton
                  kind="iconText"
                  onClick={handleClickAddRelativeInfo}
                  style={{ marginTop: "24px" }}
                  disabled={displayDataCount >= 20}
                >
                  <AddIcon />
                  {props.label}を追加する
                </KnowbeButton>
              ) : null}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const SupportLedgerRelativesInfo = withStyles(styles)(
  SupportLedgerRelativesInfoCore
);
