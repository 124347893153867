import React from "react";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";

// components
import { CreateAndUpdateDate } from "@componentsMobile/atoms/CreateAndUpdateDate";

// utils
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { dateTodayForAttendanceHeaderForDetailDaily } from "@utils/date/dateTodayForAttendanceHeaderForDetailDaily";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";

const styles = (): StyleRules =>
  createStyles({
    area: {
      marginTop: 16,
      padding: "0 16px"
    },
    date: {
      fontSize: 16,
      fontWeight: "bold",
      margin: 0
    },
    name: {
      fontSize: 16,
      fontWeight: "bold",
      margin: "4px 0 0"
    },
    unPlannedIcon: {
      width: 44,
      height: 20,
      color: "#fff",
      fontSize: 12,
      backgroundColor: "#757575",
      padding: "1px 4px",
      borderRadius: 2,
      marginBottom: 16,
      marginTop: 16
    },
    createArea: {
      marginTop: 16
    }
  });

type OwnProps = {
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  userName: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureRecordInfoCore = (props: Props): JSX.Element => {
  const targetDate = props.supportProcedureDetail.target_date;
  const { classes } = props;

  return (
    <div className={classes.area}>
      <p className={classes.date}>
        {targetDate
          ? dateTodayForAttendanceHeaderForDetailDaily(
              new Date(fixDateAndTimeFormat(targetDate))
            )
          : ""}
      </p>
      <p className={classes.name}>{props.userName}</p>
      {props.supportProcedureDetail.inout_results_id === null ? (
        <span className={classes.unPlannedIcon}>計画外</span>
      ) : null}
      <div className={classes.createArea}>
        <CreateAndUpdateDate
          createdAt={props.supportProcedureDetail.created_at}
          updatedAt={props.supportProcedureDetail.updated_at}
        />
      </div>
    </div>
  );
};

export const SupportProcedureRecordInfo = withStyles(styles)(
  SupportProcedureRecordInfoCore
);
