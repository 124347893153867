import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostCustomOperationParams = {
  setting_type: number;
  operation_record_id: number | null;
  custom_record: {
    custom_record_input_id: number | null;
    custom_record_item_id: number;
    input_data?: string; // input_type = 1, 4
    choiced_item_id?: number; // input_type = 2, 4
    choiced_staff_id?: number; // input_type = 3
    choiced_staff_name_snapshot?: string; // input_type = 3
    checked?: number; // input_type = 2, 3
  }[];
};

/**
 * 指定日の業務記録を更新する
 */
const postCustomOperation = async (
  params: PostCustomOperationParams,
  yyyymmdd: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_operation/${yyyymmdd}`;
  return request.post(url, params);
};

export default postCustomOperation;
