import React from "react";
import { OperationsState } from "@stores/domain/mgr/Cseg/operations/types";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKITEICHAKUUsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  OperationsValues
} from "@initialize/mgr/Cseg/record/operations/initialValues";
import { validation } from "@initialize/mgr/Cseg/record/operations/validation";
import { toEffectiveObject } from "@utils/object";
import deepEqual from "fast-deep-equal";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { OperationRecord } from "@components/organisms/mgr/Cseg/record/operation/OperationRecord";
import { FieldItem } from "@interfaces/ui/form";
import { AppState } from "@stores/type";
import { connect } from "react-redux";

const styles = (): StyleRules =>
  createStyles({
    operationRecordWrapper: {
      margin: "0 16px 16px"
    }
  });

type OwnProps = {
  year: string;
  month: string;
  currentDate: Date;
  isEditing: boolean;
  editDate: string;
  operations: OperationsState;
  staffOptions: FieldItem[];
  users:
    | KEIKAKUSODANUsersInFacilityState["users"]
    | CHIIKITEICHAKUUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
  needsStopHistory: boolean;
  postOperation: (
    params: OperationsValues,
    initialValues: OperationsValues,
    targetDate: string
  ) => Promise<void>;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  holidays: string[];
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const OperationRecordListCore = (props: Props): JSX.Element => {
  const { year, month, currentDate } = props;

  // 選択月の末日を取り出す、現在月と同じなら現在日まで
  const lastDayOfMonth = new Date(+year, +month, 0);
  const lastDay =
    +year === currentDate.getFullYear() && +month === currentDate.getMonth() + 1
      ? currentDate.getDate()
      : lastDayOfMonth.getDate();

  const dateList = Array.from(
    {
      length: lastDay
    },
    (_, index) => {
      const day = `${index + 1}`.padStart(2, "0");
      return `${year}-${month.padStart(2, "0")}-${day}`;
    }
  );

  const [formValues, setFormValues] = React.useState(
    initialValues(props.operations, dateList)
  );

  React.useEffect(() => {
    setFormValues(initialValues(props.operations, dateList));
  }, [props.operations]);

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditDate(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);

  const confirmDiscardFormChanges = (nextValues: OperationsValues): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: OperationsValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const onSubmit = async (
    values: OperationsValues,
    actions: FormikActions<OperationsValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postOperation(values, formValues, props.editDate);
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => {
          const onClickEditCancel = (): void => {
            formikProps.resetForm();
            props.unsetEditDate();
            props.stopHistory(false);
          };
          return (
            <Form>
              {formikProps.values.operations.length === dateList.length &&
                dateList.map((date, index) => {
                  const isHoliday = props.holidays.includes(date);
                  const operation = props.operations.operations.find(
                    (v) => v.target_date === date
                  );
                  // 現在の編集対象
                  const isEditTarget = props.editDate === date;
                  // 編集中だが編集対象でない
                  const isEditNotTarget =
                    props.isEditing && props.editDate !== date;
                  // 指定日付を編集状態に
                  const onClickEdit = (): void => {
                    props.setEditDate(date);
                  };
                  return (
                    <div
                      className={props.classes.operationRecordWrapper}
                      key={date}
                    >
                      <OperationRecord
                        handleClickEdit={onClickEdit}
                        handleClickCancel={onClickEditCancel}
                        formikProps={formikProps}
                        formikFieldNamePrefix={`operations[${index}]`}
                        isEditing={isEditTarget}
                        isEditNotTarget={isEditNotTarget}
                        operation={operation}
                        staffOptions={props.staffOptions}
                        date={date}
                        isHoliday={isHoliday}
                      />
                    </div>
                  );
                })}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  holidays: state.holiday
});

export const OperationRecordList = withStyles(styles)(
  connect(mapStateToProps)(OperationRecordListCore)
);
