import { BasicValues } from "@interfaces/mgr/KEIKAKUSODAN/facility/basic";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  DEFAULT_FUNCTION_ENHANCED_SYSTEM,
  DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";
import { FacilityInfoShogaijiValues } from "@interfaces/mgr/KEIKAKUSODAN/facility/facilityInfoShogaijiValues";
import { FacilityInfoKeikakuValues } from "@interfaces/mgr/KEIKAKUSODAN/facility/facilityInfoKeikakuValues";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import { DEFAULT_SELECT_DATE_VALUE } from "@constants/variables";

export type FacilityValues = BasicValues &
  FacilityInfoKeikakuValues &
  FacilityInfoShogaijiValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  if (state) {
    const facilityKS = findFacilityKeikakuSodan<FacilityState>(state);
    const facilitySS = findFacilityShogaijisodan<FacilityState>(state);

    return {
      basic: {
        corporationName: state.corporationName,
        serviceType: state.serviceType,
        capacity: state.capacity,
        integratedManagementHandicappedChildFlg:
          state.integratedManagementHandicappedChildFlg,
        postalCode: state.postalCode,
        prefectureId: state.selectedPrefectureName,
        cityId: state.selectedCityCode,
        restAddress: state.restAddress
      },
      facilityInfoKeikaku: {
        officeNumber:
          facilityKS && facilityKS.govFacilityNumber
            ? facilityKS.govFacilityNumber
            : "",
        officeName: facilityKS && facilityKS.name ? facilityKS.name : "",
        representativeName:
          facilityKS && facilityKS.responsiblePerson
            ? facilityKS.responsiblePerson
            : "",
        tel: facilityKS && facilityKS.tel ? facilityKS.tel : "",
        functionEnhancedSystem:
          facilityKS && facilityKS.functionEnhancedSystem
            ? facilityKS.functionEnhancedSystem
            : DEFAULT_FUNCTION_ENHANCED_SYSTEM,
        needMedicalCareHandicappedChildSupport202404:
          !!facilityKS &&
          facilityKS.needMedicalCareHandicappedChildSupport202404
            ? facilityKS.needMedicalCareHandicappedChildSupport202404
            : DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT,
        peerSupportFlg: !!facilityKS && facilityKS.peerSupportFlg,
        lifeSupportHubInDistrictFlg:
          !!facilityKS && facilityKS.lifeSupportHubInDistrictFlg,
        lifeSupportFunctionPromotionFlg:
          !!facilityKS && facilityKS.lifeSupportFunctionPromotionFlg,
        lifeSupportFunctionPromotionTimes:
          facilityKS && facilityKS.lifeSupportFunctionPromotionTimes
            ? facilityKS.lifeSupportFunctionPromotionTimes
            : "",
        informationDisclosureNotReportedFlg: facilityKS
          ? facilityKS.informationDisclosureNotReportedFlg
          : false,
        informationDisclosureNotReportedFrom: initializeSelectDate(
          facilityKS && facilityKS.informationDisclosureNotReportedFrom
        ),
        informationDisclosureNotReportedTo: initializeSelectDate(
          facilityKS && facilityKS.informationDisclosureNotReportedTo
        ),
        abusePreventionMeasuresNotImplementedFlg:
          !!facilityKS && facilityKS.abusePreventionMeasuresNotImplementedFlg,
        abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
          facilityKS && facilityKS.abusePreventionMeasuresNotImplementedFrom
        ),
        abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
          facilityKS && facilityKS.abusePreventionMeasuresNotImplementedTo
        ),
        workContinuationNotPlanningFlg:
          !!facilityKS && facilityKS.workContinuationNotPlanningFlg,
        workContinuationNotPlanningFrom: initializeSelectDate(
          facilityKS && facilityKS.workContinuationNotPlanningFrom
        ),
        workContinuationNotPlanningTo: initializeSelectDate(
          facilityKS && facilityKS.workContinuationNotPlanningTo
        )
      },
      facilityInfoShogaiji: {
        officeNumber:
          facilitySS && facilitySS.govFacilityNumber
            ? facilitySS.govFacilityNumber
            : "",
        officeName: facilitySS && facilitySS.name ? facilitySS.name : "",
        representativeName:
          facilitySS && facilitySS.responsiblePerson
            ? facilitySS.responsiblePerson
            : "",
        tel: facilitySS && facilitySS.tel ? facilitySS.tel : "",
        functionEnhancedSystem:
          facilitySS && facilitySS.functionEnhancedSystem
            ? facilitySS.functionEnhancedSystem
            : DEFAULT_FUNCTION_ENHANCED_SYSTEM,
        needMedicalCareHandicappedChildSupport202404:
          !!facilitySS &&
          facilitySS.needMedicalCareHandicappedChildSupport202404
            ? facilitySS.needMedicalCareHandicappedChildSupport202404
            : DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT,
        peerSupportFlg: !!facilitySS && facilitySS.peerSupportFlg,
        lifeSupportHubInDistrictFlg:
          !!facilitySS && facilitySS.lifeSupportHubInDistrictFlg,
        lifeSupportFunctionPromotionFlg:
          !!facilitySS && facilitySS.lifeSupportFunctionPromotionFlg,
        lifeSupportFunctionPromotionTimes:
          facilitySS && facilitySS.lifeSupportFunctionPromotionTimes
            ? facilitySS.lifeSupportFunctionPromotionTimes
            : "",
        informationDisclosureNotReportedFlg: facilitySS
          ? facilitySS.informationDisclosureNotReportedFlg
          : false,
        informationDisclosureNotReportedFrom: initializeSelectDate(
          facilitySS && facilitySS.informationDisclosureNotReportedFrom
        ),
        informationDisclosureNotReportedTo: initializeSelectDate(
          facilitySS && facilitySS.informationDisclosureNotReportedTo
        ),
        abusePreventionMeasuresNotImplementedFlg:
          !!facilitySS && facilitySS.abusePreventionMeasuresNotImplementedFlg,
        abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
          facilitySS && facilitySS.abusePreventionMeasuresNotImplementedFrom
        ),
        abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
          facilitySS && facilitySS.abusePreventionMeasuresNotImplementedTo
        ),
        workContinuationNotPlanningFlg:
          !!facilitySS && facilitySS.workContinuationNotPlanningFlg,
        workContinuationNotPlanningFrom: initializeSelectDate(
          facilitySS && facilitySS.workContinuationNotPlanningFrom
        ),
        workContinuationNotPlanningTo: initializeSelectDate(
          facilitySS && facilitySS.workContinuationNotPlanningTo
        )
      }
    };
  }
  return {
    basic: {
      corporationName: "",
      serviceType: "",
      capacity: "",
      integratedManagementHandicappedChildFlg: false,
      postalCode: "",
      prefectureId: "",
      cityId: "",
      restAddress: ""
    },
    facilityInfoKeikaku: {
      officeNumber: "",
      officeName: "",
      representativeName: "",
      tel: "",
      functionEnhancedSystem: DEFAULT_FUNCTION_ENHANCED_SYSTEM,
      needMedicalCareHandicappedChildSupport202404: DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT,
      peerSupportFlg: false,
      lifeSupportHubInDistrictFlg: false,
      lifeSupportFunctionPromotionFlg: false,
      lifeSupportFunctionPromotionTimes: "",
      informationDisclosureNotReportedFlg: false,
      informationDisclosureNotReportedFrom: DEFAULT_SELECT_DATE_VALUE,
      informationDisclosureNotReportedTo: DEFAULT_SELECT_DATE_VALUE,
      abusePreventionMeasuresNotImplementedFlg: false,
      abusePreventionMeasuresNotImplementedFrom: DEFAULT_SELECT_DATE_VALUE,
      abusePreventionMeasuresNotImplementedTo: DEFAULT_SELECT_DATE_VALUE,
      workContinuationNotPlanningFlg: false,
      workContinuationNotPlanningFrom: DEFAULT_SELECT_DATE_VALUE,
      workContinuationNotPlanningTo: DEFAULT_SELECT_DATE_VALUE
    },
    facilityInfoShogaiji: {
      officeNumber: "",
      officeName: "",
      representativeName: "",
      tel: "",
      functionEnhancedSystem: DEFAULT_FUNCTION_ENHANCED_SYSTEM,
      needMedicalCareHandicappedChildSupport202404: DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT,
      peerSupportFlg: false,
      lifeSupportHubInDistrictFlg: false,
      lifeSupportFunctionPromotionFlg: false,
      lifeSupportFunctionPromotionTimes: "",
      informationDisclosureNotReportedFlg: false,
      informationDisclosureNotReportedFrom: DEFAULT_SELECT_DATE_VALUE,
      informationDisclosureNotReportedTo: DEFAULT_SELECT_DATE_VALUE,
      abusePreventionMeasuresNotImplementedFlg: false,
      abusePreventionMeasuresNotImplementedFrom: DEFAULT_SELECT_DATE_VALUE,
      abusePreventionMeasuresNotImplementedTo: DEFAULT_SELECT_DATE_VALUE,
      workContinuationNotPlanningFlg: false,
      workContinuationNotPlanningFrom: DEFAULT_SELECT_DATE_VALUE,
      workContinuationNotPlanningTo: DEFAULT_SELECT_DATE_VALUE
    }
  };
};
