import React, { useEffect, useState } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import EventIcon from "@material-ui/icons/Event";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import blueGrey from "@material-ui/core/colors/blueGrey";
import { CalendarDaily } from "@components/molecules/CalendarDaily";
import KnowbeDialog from "@components/molecules/dialog/KnowbeDialog";
import * as isBefore from "date-fns/is_before";
import * as isAfter from "date-fns/is_after";
import * as addDays from "date-fns/add_days";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { BASE_TEXT_COLOR, SECONDARY_LINE_COLOR } from "@/constants/styles";

const styles = (): StyleRules =>
  createStyles({
    root: {
      display: "inline-flex",
      borderBottom: "1px solid #ccc"
    },
    calendarButton: {
      padding: "8px 5px"
    },
    calendarIcon: {
      height: 23,
      width: 23,
      marginLeft: 5,
      color: blueGrey[400]
    },
    arrowButton: {
      minWidth: 20,
      "&.left": {
        padding: "0 4px 0 5px"
      },
      "&.right": {
        padding: "0 5px 0 4px"
      }
    },
    arrowIcon: {
      height: 24,
      width: 24,
      color: SECONDARY_LINE_COLOR
    },
    date: {
      fontSize: 18,
      fontWeight: "bold",
      lineHeight: 1,
      letterSpacing: 0.2,
      color: BASE_TEXT_COLOR,
      "& > span": {
        margin: "0 5px 0 3px",
        fontSize: 16,
        lineHeight: 1.5
      }
    },
    underLine: {
      width: 306,
      color: SECONDARY_LINE_COLOR
    }
  });

type OwnProps = {
  defaultDate: Date;
  min: Date;
  max: Date;
  onClickSubmit: (date: Date) => void;
  highlightErrors?: {
    errorsDateList: string[];
    fetchLatestInoutErrors: () => Promise<void>;
  };
};

type Props = WithStyles<typeof styles> & OwnProps;

const DateSelectButtonsDaily = (props: Props): JSX.Element => {
  const [currentDate, setCurrentDate] = useState(props.defaultDate);
  const [selectedDate, setSelectedDate] = useState(props.defaultDate);
  const [isMinDate, setIsMinDate] = useState(false);
  const [isMaxDate, setIsMaxDate] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const checkIsMinDate = (): boolean => {
    return isBefore(addDays(selectedDate, -1), props.min);
  };

  const checkIsMaxDate = (): boolean => {
    return isAfter(addDays(selectedDate, 1), props.max);
  };

  useEffect(() => {
    setIsMinDate(checkIsMinDate());
    setIsMaxDate(checkIsMaxDate());
  }, [selectedDate]);

  /**
   * 親に選択日付を返した後、現在日付に反映してから閉じる
   */
  const handleOnClickSubmit = (): void => {
    // カレンダーのDOMから選択日付を取得する
    const selectedDateElm = document.querySelector(".Cal__Day__selected");
    if (!selectedDateElm) return;
    const selectedDateData = selectedDateElm.getAttribute("data-date");
    if (!selectedDateData) return;
    const date = new Date(selectedDateData);

    props.onClickSubmit(date);
    setCurrentDate(date);
    setSelectedDate(date);
    setIsDialogOpen(false);
  };

  const onClickOpenCalendar = async (): Promise<void> => {
    if (props.highlightErrors) {
      await props.highlightErrors.fetchLatestInoutErrors();
    }
    setIsDialogOpen(true);
  };

  /**
   * 選択日付を開いた状態に戻して閉じる
   */
  const closeCalendar = (): void => {
    setSelectedDate(currentDate);
    setIsDialogOpen(false);
  };

  const onClickLeft = (): void => {
    if (checkIsMinDate()) {
      setIsMinDate(true);
    } else {
      const targetDate = addDays(selectedDate, -1);
      setSelectedDate(targetDate);
      setCurrentDate(targetDate);
      props.onClickSubmit(targetDate);
    }
    setIsMaxDate(false);
  };

  const onClickRight = (): void => {
    if (checkIsMaxDate()) {
      setIsMaxDate(true);
    } else {
      const targetDate = addDays(selectedDate, 1);
      setSelectedDate(targetDate);
      setCurrentDate(targetDate);
      props.onClickSubmit(targetDate);
    }
    setIsMinDate(false);
  };

  const { classes, min, max } = props;

  return (
    <>
      <div>
        <Button
          className={`${classes.arrowButton} left`}
          onClick={onClickLeft}
          style={isMinDate ? { visibility: "hidden" } : {}}
        >
          <ArrowLeftIcon className={classes.arrowIcon} />
        </Button>
        <Button
          className={classes.calendarButton}
          onClick={onClickOpenCalendar}
        >
          <span className={classes.date}>
            {currentDate.getFullYear()}
            <span>年</span>
            {currentDate.getMonth() + 1}
            <span>月</span>
            {currentDate.getDate()}
            <span>日</span>
            <span>({oneLetterWeekdaysJapanese[currentDate.getDay()]})</span>
          </span>
          <EventIcon className={classes.calendarIcon} />
        </Button>
        <Button
          className={`${classes.arrowButton} right`}
          onClick={onClickRight}
          style={isMaxDate ? { visibility: "hidden" } : {}}
        >
          <ArrowRightIcon className={classes.arrowIcon} />
        </Button>
        <Divider className={classes.underLine} />
      </div>
      <KnowbeDialog
        isOpn={isDialogOpen}
        isShowTitle={false}
        isShowFooter={false}
        contentStyle={{ padding: 0 }}
        onCloseDialog={closeCalendar}
      >
        <CalendarDaily
          max={max}
          min={min}
          today={selectedDate}
          cancelLabel="キャンセル"
          submitLabel="確定する"
          onClickCancel={closeCalendar}
          onClickSubmit={handleOnClickSubmit}
          errorDates={
            props.highlightErrors && props.highlightErrors.errorsDateList
          }
        />
      </KnowbeDialog>
    </>
  );
};

// 影響範囲が大きいので後ほどnamed exportにリファクタする
export default withStyles(styles)(DateSelectButtonsDaily);
