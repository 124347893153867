import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    label: {
      backgroundColor: "#f5f5f5",
      boxSizing: "border-box",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 14,
      padding: "4px 16px",
      marginBottom: 8
    }
  });

type OwnProps = {
  label: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 文字装飾
 */
const GrayLabel = (props: Props): JSX.Element => (
  <div className={props.classes.label}>{props.label}</div>
);

export default withStyles(styles)(GrayLabel);
