import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostMonitoringReport = {
  id: number | null;
  /** 保護者指名と続柄 障害児相談のみ */
  guardian_name?: string | null;
  guardian_relation?: string | null;
  /** モニタリング実施日 新規の場合必須 */
  date_of_monitoring?: string;
  creation_date?: string | null;
  author?: number | null;
  classify_disability_support?: number | null;
  income_kind?: string | null;
  income_category?: number | null;
  disabled_welfare_service_recipient_number?: string | null;
  consultation_support_recipient_number?: string | null;
  regular_place_recipient_number?: string | null;
  support_policy?: string | null;
  whole_situation?: string | null;
  staff_comment?: string | null;
  /** 相談種別 新規の場合必須 */
  type_consultation?: number;
  details?: {
    id: number | null;
    support_goal?: string | null;
    achievement_time?: string | null;
    service_provision_status?: string | null;
    personal_satisfaction?: string | null;
    achievement_support_goals?: string | null;
    future_issues?: string | null;
    change_plan_service_type?: number | null;
    change_plan_service_volume?: number | null;
    change_plan_weekly?: number | null;
    other_considerations?: string | null;
    is_delete?: number;
  }[];
};

/**
 * モニタリング報告書 のデータ更新
 * @param uifId 事業所所属ユーザーのID
 */
export const postMonitoringReport = async (
  uifId: string,
  param: PostMonitoringReport
): Promise<AxiosResponse<PostMonitoringReport>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/monitoring_report`;
  return request.post(url, param);
};
