import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate202104 } from "@components/templates/AdminTemplate202104";
import InOutReportDaily from "@components/v202104/organisms/mgr/IAB/report/InOutReportDaily";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  ReportTabInfo,
  REPORT_TABS_INFO,
  FacilityType,
  INVOICE_VERSION_V202104
} from "@constants/variables";
import * as URL from "@constants/url";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { INVOICE_PATHS } from "@constants/mgr/IAB/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

type Props = RouteComponentProps<{}, {}, { targetDate: Date }>;

/**
 * 利用実績
 */
export const ReportDaily202104 = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();

  const currentPageVersion = INVOICE_VERSION_V202104;
  const transitionDone = checkReportVersion(
    currentDate,
    // IABそれぞれを渡す必要があるが、ページ遷移の分岐処理は共通のためIKOUのみ渡している
    FacilityType.IKOU,
    currentPageVersion,
    INVOICE_PATHS.reportDaily,
    props.history
  );

  if (transitionDone) {
    return null;
  }

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === ReportTabInfo.MONTHLY) {
      props.history.push(URL.REPORT_MONTHLY);
    }
  };
  return (
    <AdminTemplate202104 pageName="利用実績" zIndex>
      <>
        <KnowbeTabs
          key="tab"
          tabInfo={REPORT_TABS_INFO}
          onChange={onChangeTag}
          value={ReportTabInfo.DAILY}
        />
        <InOutReportDaily
          initialDate={currentDate}
          history={props.history}
          currentPageVersion={currentPageVersion}
          // propsをstateに入れた時の不具合予防にkeyを入れる
          key={currentDate.getTime()}
        />
        <ErrorsDialog errorsKey="inout" />
      </>
    </AdminTemplate202104>
  );
};
