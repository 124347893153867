import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetServiceDeliveryMonthly = {
  data: {
    service_delivery: {
      service_delivery_records_id: number | null;
      inout_results_id: number | null;
      support_procedure_forms_id: number | null; // 行動援護のみ
      users_in_facility_id: number;
      name_sei: string;
      name_mei: string;
      target_date: string;
      is_holiday: boolean;
      status: number;
      status_text: string | null; // 移動支援のみ
      start_time_1: string;
      end_time_1: string;
      number_of_time_1: number;
      number_of_rides_1: number;
      start_time_2: string;
      end_time_2: string;
      number_of_time_2: number;
      number_of_rides_2: number;
      number_of_practitioner: number;
      recorded_flg: number;
      unplanned_flg: number;
      procedure_status_flg: number; // 行動援護のみ
    }[];
    number_of_plans: number;
    number_of_records: number;
    number_of_procedures: number; // 行動援護のみ
  };
};

/**
 * 指定月のサービス提供記録を取得する
 * @param usersInFacilityId number
 * @param yyyymm YYYYMM
 */
export const getServiceDeliveryMonthly = async (
  usersInFacilityId: number,
  yyyymm: string
): Promise<AxiosResponse<GetServiceDeliveryMonthly>> => {
  const url = `${VERSION_URL_201910}/service_delivery/monthly/${usersInFacilityId}/${yyyymm}`;
  return request.get<GetServiceDeliveryMonthly>(url);
};
