import * as types from "./types";
import * as actions from "./actions";

const initialState: types.DailyOperationsAndSupportsState = {
  summary: [],
  operation: [],
  support: [],
  holidays: [],
  created_at: null,
  updated_at: null
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.DailyOperationsAndSupportsState => {
  switch (action.type) {
    case types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_STARTED:
      return state;
    case types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_SUCCESS:
      return { ...action.payload };
    case types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_FAILED:
      return state;
    case types.UNSET_DAILY_OPERATIONS_AND_SUPPORTS:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
