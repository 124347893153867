import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";
import { Checkbox } from "@constants/variables";

export type GetFacilityUserTargetIdResponse = {
  data: {
    /* 基本情報 */

    // 施設利用者id
    id?: number;
    // 姓
    name_sei?: string;
    // 名
    name_mei?: string;
    // 姓(カナ)
    name_sei_kana?: string;
    // 名(カナ)
    name_mei_kana?: string;
    // 受給者証番号
    recipient_number?: string;
    // 受給者証未発行、または見学中の利用者である（請求対象外）
    none_recipient_number_flg?: Checkbox;
    // 性別
    gender?: string;
    // 施設id
    facility_id?: number;
    // 身体障害
    classify_physical_flg?: Checkbox;
    // 知的障害
    classify_intelligence_flg?: Checkbox;
    // 精神障害
    classify_mind_flg?: Checkbox;
    // 発達障害
    classify_growth_flg?: Checkbox;
    // 高次脳機能障害
    classify_brain_flg?: Checkbox;
    // 難病等対象者
    classify_incurable_flg?: Checkbox;
    // 障害児
    classify_handicapped_flg?: Checkbox;
    // 生年月日
    date_birth?: string;
    // 郵便番号
    postal_code?: string;
    // 都道府県
    prefecture_name?: string;
    // 市区町村id
    city_id?: number;
    // 住所
    address?: string;
    // 電話番号
    tel?: string;
    // メールアドレス
    email?: string;
    // 保護者名
    guardian_name?: string;
    // 保護者、続柄
    guardian_relation?: string;
    // 保護者 電話番号
    guardian_tel?: string;
    // 備考
    memo?: string;

    /* サービス詳細 */

    // 入居日 yyyy-mm-dd
    date_begin_in_service?: string;
    // 退居日 yyyy-mm-dd
    date_end_in_service?: string;
    // 支給決定開始日
    date_pay_begin?: string;
    // 支給決定終了日
    date_pay_end?: string;
    // 障害支援区分
    classify_disability_support?: string;
    // 負担上限額
    income_kind?: string;
    // 所得区分
    income_kind_type?: string;
    // Checkbox?: 自治体助成金対応
    subsidized_flg?: Checkbox;
    // 金額?: %
    subsidized_percent?: string;
    // 金額 ¥
    subsidized_yen?: string;
    // Select?: 自治体助成金対応(単位 1?: %, 2?: ¥)
    subsidized_unit_flg?: string;
    // 市区町村番号
    subsidized_city_id?: number;
    // 上限額管理事業所有り
    uplimit_facility_flg?: Checkbox;
    // 上限管理事業所コード null, 1?: 上限額管理事業所あり 2?: 他事業所が上限を管理
    uplimit_controlled_by?: string;
    // サービス提供した事業者番号
    uplimit_facility_number?: string;
    // サービス提供した事業者番号2
    uplimit_facility_number2?: string;
    // サービス提供した事業者番号3
    uplimit_facility_number3?: string;
    // サービス提供した事業者番号4
    uplimit_facility_number4?: string;
    // 上限額管理事業所 事業所名
    uplimit_facility_name?: string;
    // 上限額管理事業所 事業所名2
    uplimit_facility_name2?: string;
    // 上限額管理事業所 事業所名3
    uplimit_facility_name3?: string;
    // 上限額管理事業所 事業所名4
    uplimit_facility_name4?: string;
    // 上限管理・総費用額(金額)
    uplimit_total_yen?: string | null;
    // 上限管理・総費用額(金額)2
    uplimit_total_yen2?: string | null;
    // 上限管理・総費用額(金額)3
    uplimit_total_yen3?: string | null;
    // 上限管理・総費用額(金額)4
    uplimit_total_yen4?: string | null;
    // 上限管理・利用者負担額(yen)
    uplimit_user_load_yen?: string | null;
    // 上限管理・利用者負担額(yen)2
    uplimit_user_load_yen2?: string | null;
    // 上限管理・利用者負担額(yen)3
    uplimit_user_load_yen3?: string | null;
    // 上限管理・利用者負担額(yen)4
    uplimit_user_load_yen4?: string | null;
    // 利用者負担上限額管理結果
    result_of_management?: string;
    // 上限管理・管理結果後利用者負担額(yen)
    uplimit_user_load_after_adjustment_yen?: number;
    // 上限管理・管理結果後利用者負担額(yen)2
    uplimit_user_load_after_adjustment_yen2?: number;
    // 上限管理・管理結果後利用者負担額(yen)3
    uplimit_user_load_after_adjustment_yen3?: number;
    // 上限管理・管理結果後利用者負担額(yen)4
    uplimit_user_load_after_adjustment_yen4?: number;
    // 施設外支援日数累計データ（サービス前）
    total_days_in_fiscal_year?: number;
    // 自事業所調整上限額
    uplimit_yen?: string;
    // 個別支援計画作成済み
    create_support_plan_flg?: Checkbox;
    // 個別支援計画作成期間開始日
    date_start_not_create_support_plan?: string;
    // 同一法人の事業所から受け入れ(初期加算対象外)
    same_corporation_movement_flg?: Checkbox;
    // 平均利用期間 計算除外（標準利用期間超過減算の特例対象）
    average_use_period_exception_flg?: Checkbox;
    // 契約支給量
    agreed_by_contract_flg?: string;
    // 食事提供サービス
    def_food?: string;
    // 送迎サービス
    def_pickup?: string;
    // 同一敷地内送迎
    pickup_premises?: string;
    // 日数
    pay_days_agreed?: string;
    // 事業所記入欄番号
    business_number_contract?: string;
    // 契約開始年月日
    contract_begin_date?: string;
    // 契約終了年月日
    contract_end_date?: string;

    /* 受給者証 */

    // 負担上限月額の適用期間 1: ON, 0: OFF
    user_charge_limit_flg?: Checkbox;
    // 負担上限月額の適用開始日 yyyy-mm-dd
    date_begin_user_charge_limit?: string;
    // 負担上限月額の適用終了日 yyyy-mm-dd
    date_end_user_charge_limit?: string;
    // 食事提供体制加算の適用 1: ON, 0: OFF
    food_serve_addition_flg?: Checkbox;
    // 食事提供体制加算の適用開始日 yyyy-mm-dd
    date_begin_food_serve_addition?: string;
    // 食事提供体制加算の適用終了日 yyyy-mm-dd
    date_end_food_serve_addition?: string;
    // 介護給付費の支給決定フラグ 1?: ON 0?: OFF
    care_support_payment_flg?: Checkbox;
    // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
    date_begin_care_support_payment?: string;
    // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
    date_end_care_support_payment?: string;
    // 障害支援区分の認定有効  1: ON, 0: OFF
    care_support_auth_flg?: Checkbox;
    // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
    date_begin_care_support_auth?: string;
    // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
    date_end_care_support_auth?: string;
    // 計画相談支援給付費の支給決定フラグ 1?: ON 0?: OFF
    plan_support_payment_flg?: Checkbox;
    // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
    date_begin_plan_support_payment?: string;
    // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
    date_end_plan_support_payment?: string;
    // 計画相談支援給付費のモニタリング期間 フラグ 1?: ON 0?: OFF
    plan_support_monitor_flg?: Checkbox;
    // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
    date_begin_plan_support_monitor?: string;
    // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
    date_end_plan_support_monitor?: string;

    // 通所予定日（月）
    mon_scheduled_flg?: Checkbox;
    // 通所予定日（火）
    tue_scheduled_flg?: Checkbox;
    // 通所予定日（水）
    wed_scheduled_flg?: Checkbox;
    // 通所予定日（木）
    thu_scheduled_flg?: Checkbox;
    // 通所予定日（金）
    fri_scheduled_flg?: Checkbox;
    // 通所予定日（土）
    sat_scheduled_flg?: Checkbox;
    // 通所予定日（日）
    sun_scheduled_flg?: Checkbox;
    // A型減免対象
    a_target_for_reduction_flg?: Checkbox;
    // 作業時間を管理する
    def_record_work?: Checkbox;
    // 利用日数に関わる特例あり
    day_use_special_case_flg?: Checkbox;
    // 利用日数に関わる特例あり開始日
    day_use_special_case_from?: string;
    // 利用日数に関わる特例あり終了日
    day_use_special_case_to?: string;
    user_in_facility: {
      /* 基本情報 */

      // 施設利用者id
      id?: number;
      // 姓
      name_sei?: string;
      // 名
      name_mei?: string;
      // 姓(カナ)
      name_sei_kana?: string;
      // 名(カナ)
      name_mei_kana?: string;
      // 受給者証番号
      recipient_number?: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg?: Checkbox;
      // 性別
      gender?: string;
      // 施設id
      facility_id?: number;
      // 身体障害
      classify_physical_flg?: Checkbox;
      // 知的障害
      classify_intelligence_flg?: Checkbox;
      // 精神障害
      classify_mind_flg?: Checkbox;
      // 発達障害
      classify_growth_flg?: Checkbox;
      // 高次脳機能障害
      classify_brain_flg?: Checkbox;
      // 難病等対象者
      classify_incurable_flg?: Checkbox;
      // 障害児
      classify_handicapped_flg?: Checkbox;
      // 生年月日
      date_birth?: string;
      // 郵便番号
      postal_code?: string;
      // 都道府県
      prefecture_name?: string;
      // 市区町村id
      city_id?: number;
      // 住所
      address?: string;
      // 電話番号
      tel?: string;
      // メールアドレス
      email?: string;
      // 保護者名
      guardian_name?: string;
      // 保護者_名前（姓）
      guardian_name_sei?: string;
      // 保護者_名前（名）
      guardian_name_mei?: string;
      // 保護者_名前（姓カナ）
      guardian_name_sei_kana?: string;
      // 保護者_名前（名カナ）
      guardian_name_mei_kana?: string;
      // 保護者、続柄
      guardian_relation?: string;
      // 保護者 電話番号
      guardian_tel?: string;
      // 備考
      memo?: string;

      /* サービス詳細 */

      // 入居日 yyyy-mm-dd
      date_begin_in_service?: string;
      // 退居日 yyyy-mm-dd
      date_end_in_service?: string;
      // 支給決定開始日
      date_pay_begin?: string;
      // 支給決定終了日
      date_pay_end?: string;
      // 障害支援区分
      classify_disability_support?: string;
      // 負担上限額
      income_kind?: string;
      // 所得区分
      income_kind_type?: string;
      // Checkbox?: 自治体助成金対応
      subsidized_flg?: Checkbox;
      // 金額?: %
      subsidized_percent?: string;
      // 金額 ¥
      subsidized_yen?: string;
      // Select?: 自治体助成金対応(単位 1?: %, 2?: ¥)
      subsidized_unit_flg?: string;
      // 市区町村番号
      subsidized_city_id?: number;
      // 上限額管理事業所有り
      uplimit_facility_flg?: Checkbox;
      // 上限管理事業所コード null, 1?: 上限額管理事業所あり 2?: 他事業所が上限を管理
      uplimit_controlled_by?: string;
      // サービス提供した事業者番号
      uplimit_facility_number?: string;
      // サービス提供した事業者番号2
      uplimit_facility_number2?: string;
      // サービス提供した事業者番号3
      uplimit_facility_number3?: string;
      // サービス提供した事業者番号4
      uplimit_facility_number4?: string;
      // 上限額管理事業所 事業所名
      uplimit_facility_name?: string;
      // 上限額管理事業所 事業所名2
      uplimit_facility_name2?: string;
      // 上限額管理事業所 事業所名3
      uplimit_facility_name3?: string;
      // 上限額管理事業所 事業所名4
      uplimit_facility_name4?: string;
      // 上限管理・総費用額(金額)
      uplimit_total_yen?: string;
      // 上限管理・総費用額(金額)2
      uplimit_total_yen2?: string;
      // 上限管理・総費用額(金額)3
      uplimit_total_yen3?: string;
      // 上限管理・総費用額(金額)4
      uplimit_total_yen4?: string;
      // 上限管理・利用者負担額(yen)
      uplimit_user_load_yen?: string;
      // 上限管理・利用者負担額(yen)2
      uplimit_user_load_yen2?: string;
      // 上限管理・利用者負担額(yen)3
      uplimit_user_load_yen3?: string;
      // 上限管理・利用者負担額(yen)4
      uplimit_user_load_yen4?: string;
      // 上限管理・管理結果後利用者負担額(yen)
      uplimit_user_load_after_adjustment_yen?: number;
      // 上限管理・管理結果後利用者負担額(yen)2
      uplimit_user_load_after_adjustment_yen2?: number;
      // 上限管理・管理結果後利用者負担額(yen)3
      uplimit_user_load_after_adjustment_yen3?: number;
      // 上限管理・管理結果後利用者負担額(yen)4
      uplimit_user_load_after_adjustment_yen4?: number;
      // 利用者負担上限額管理結果
      result_of_management?: string;
      // 自事業所調整上限額
      uplimit_yen?: string;
      // 個別支援計画作成済み
      create_support_plan_flg?: Checkbox;
      // 個別支援計画作成期間開始日
      date_start_not_create_support_plan?: string;
      // 同一法人の事業所から受け入れ(初期加算対象外)
      same_corporation_movement_flg?: Checkbox;
      // 平均利用期間 計算除外（標準利用期間超過減算の特例対象）
      average_use_period_exception_flg?: Checkbox;
      // 契約支給量
      agreed_by_contract_flg?: string;
      // 食事提供サービス
      def_food?: string;
      // 送迎サービス
      def_pickup?: string;
      // 同一敷地内送迎
      pickup_premises?: string;
      // 日数
      pay_days_agreed?: string;
      // 事業所記入欄番号
      business_number_contract?: string;
      // 就労移行連携加算
      ikou_cooperation_flg?: number;
      // 就労移行連携加算実施日
      ikou_cooperation_date?: string | null;
      // 支援計画会議実施加算
      case_meeting_flg?: number;
      // 支援計画会議実施加算 算定開始日
      case_meeting_start_date?: string | null;

      /* 受給者証 */

      // 負担上限月額の適用期間 1: ON, 0: OFF
      user_charge_limit_flg?: Checkbox;
      // 負担上限月額の適用開始日 yyyy-mm-dd
      date_begin_user_charge_limit?: string;
      // 負担上限月額の適用終了日 yyyy-mm-dd
      date_end_user_charge_limit?: string;
      // 食事提供体制加算の適用 1: ON, 0: OFF
      food_serve_addition_flg?: Checkbox;
      // 食事提供体制加算の適用開始日 yyyy-mm-dd
      date_begin_food_serve_addition?: string;
      // 食事提供体制加算の適用終了日 yyyy-mm-dd
      date_end_food_serve_addition?: string;
      // 介護給付費の支給決定フラグ 1?: ON 0?: OFF
      care_support_payment_flg?: Checkbox;
      // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
      date_begin_care_support_payment?: string;
      // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
      date_end_care_support_payment?: string;
      // 障害支援区分の認定有効  1: ON, 0: OFF
      care_support_auth_flg?: Checkbox;
      // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
      date_begin_care_support_auth?: string;
      // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
      date_end_care_support_auth?: string;
      // 計画相談支援給付費の支給決定フラグ 1?: ON 0?: OFF
      plan_support_payment_flg?: Checkbox;
      // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
      date_begin_plan_support_payment?: string;
      // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
      date_end_plan_support_payment?: string;
      // 計画相談支援給付費のモニタリング期間 フラグ 1?: ON 0?: OFF
      plan_support_monitor_flg?: Checkbox;
      // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
      date_begin_plan_support_monitor?: string;
      // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
      date_end_plan_support_monitor?: string;

      // 通所予定日（月）
      mon_scheduled_flg?: Checkbox;
      // 通所予定日（火）
      tue_scheduled_flg?: Checkbox;
      // 通所予定日（水）
      wed_scheduled_flg?: Checkbox;
      // 通所予定日（木）
      thu_scheduled_flg?: Checkbox;
      // 通所予定日（金）
      fri_scheduled_flg?: Checkbox;
      // 通所予定日（土）
      sat_scheduled_flg?: Checkbox;
      // 通所予定日（日）
      sun_scheduled_flg?: Checkbox;
      // A型減免対象
      a_target_for_reduction_flg?: Checkbox;
      // 作業時間を管理する
      def_record_work?: Checkbox;
      // 利用日数に関わる特例あり
      day_use_special_case_flg?: Checkbox;
      // 利用日数に関わる特例あり開始日
      day_use_special_case_from?: string;
      // 利用日数に関わる特例あり終了日
      day_use_special_case_to?: string;
    };
    user_in_facility_group_home?: {
      // 施設利用者id
      users_in_facility_id?: number;
      // 障害区分
      disability_class?: string;
      // 強度行動障害者地域移行体制・重度障害支援体制加算
      regional_transfer_for_strong_behavior_type_202104?: number;
      // 地域生活移行個別支援特別加算・精神障害者地域移行体制加算
      mental_disorder_support_type?: string;
      // 所得区分
      income_kind_type?: string;
      // 特定障害者特別給付費
      specified_persons_disabilities_benefits?: string;
      // 運営ユニットid
      facility_unit_id?: string;
      // 医療的ケア対応支援加算
      medical_care_flg?: number;
      // 月途中変更フラグ
      mid_month_change_flg?: number;
      // 月途中変更区分
      mid_month_change_disability_class?: number | null;
      // 月途中変更日付
      mid_month_change_date?: string | null;
      // 日中活動先事業所：指定事業所番号
      daytime_facility_number?: string | null;
      // 日中活動先事業所：事業所名
      daytime_facility_name?: string | null;
      // 日中活動先事業所：当該事業所への通所日数
      daytime_support_count?: number | null;
    };
    user_in_facility_seikatsukaigo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 所得区分
      income_kind: string;
      // 障害区分
      disability_class: string;
      // 重度障害者支援
      severe_disability_support: number;
      // 加算算定開始日
      severe_disability_support_start_date: string;
      // リハビリテーション加算
      rehabilitation: string;
      // リハビリテーション実施計画作成日
      rehabilitation_start_date: string;
      // 月途中変更フラグ
      mid_month_change_flg: number;
      // 月途中変更区分
      mid_month_change_disability_class: number | null;
      // 月途中変更日付
      mid_month_change_date: string | null;
    }>;
    user_in_facility_shuroteichaku?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 所得区分
      income_kind: number;
      // 定着支援連携促進加算
      cooperation_promotion_flg: number;
      // 定着支援連携促進加算 算定開始日
      cooperation_promotion_start_date: string | null;
      // 障害区分
      disability_class?: number;
      // 障害者手帳等級 身体
      disability_certificate_body?: number;
      // 精神障害者手帳等級 精神
      disability_certificate_spirit?: number;
      // 障害者手帳等級 療育
      disability_certificate_rehabilitation?: number;
      // 障害者手帳等級 療育 等級
      disability_certificate_rehabilitation_other?: string;
      // 障害者手帳等級 難病
      classify_incurable_flg?: number;
    }>;
    companies?: Partial<{
      id: number;
      // 施設利用者id
      users_in_facility_id: number;
      // 企業名
      name: string;
      // 業種
      industry: string;
      // 企業概要
      overview: string;
      // 住所
      address: string;
      // 所属部署
      department: string;
      // 就労開始年月日
      working_start_date: string;
      // 備考
      remarks: string;
    }>;
    company_persons?: Partial<{
      id: number;
      // 企業情報id
      users_in_facility_companies_id: number;
      // 担当者名
      name: string;
      // 役職
      position: string;
      // 所属部署
      department: string;
      // 関係性
      relationship: number;
      // 電話番号
      tel: string;
      // メールアドレス
      email: string;
    }>[];
    user_in_facility_tankinyusho?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 支援の種類
      support_type: number;
      // 障害区分
      disability_class: number;
      // 障害児区分
      disability_child_class: number;
      // 重症心身障害フラグ（医療型のみ）
      severely_disabled_flg: number;
      // 利用タイプ（医療型のみ）
      use_type: number;
      // 負担上限月額0円の場合の所得区分
      income_kind: number;
      // 医療的ケア対象支援加算
      medical_care_flg: number;
      // 特別重度支援加算
      special_severe_disability_support_202104: number;
      // 重度障害者支援加算（区分6のみ）
      severe_disability_support: number;
      // 同一敷地内への移動による退所フラグ
      end_in_service_same_corporation_movement_flg: number;
    }>;
    user_in_facility_jiritsukunren_seikatsu?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 視覚障害
      blindness_flg: number;
      // 個別訓練支援計画作成済み
      individual_training_implementation_plan_flg: number;
      // 社会生活支援特別加算
      social_life_support_flg: number;
    }>;
    user_in_facility_shisetsunyusho?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 補足給付
      supplementary_benefit_flg: number;
      // 補足給付金額
      supplementary_benefit_yen: number | null;
      // 障害区分
      disability_class: number;
      // 負担上限月額0円の場合の所得区分
      income_kind: number;
      // 重度障害者支援加算（区分６のみ）
      severe_disability_support: number;
      // 重度障害者支援加算算定開始日
      severe_disability_support2_start_date: string;
      // 地域生活移行個別支援特別加算（Ⅱ）
      regional_life_transition2: number;
      // 食事提供（朝）デフォルト
      food_breakfast_flg: number | null;
      // 食事提供（昼）デフォルト
      food_lunch_flg: number | null;
      // 食事提供（夜）デフォルト
      food_supper_flg: number | null;
      // 経口維持加算(I)
      oral_maintenance_i_flg: number;
      // 経口維持加算(I)開始日
      oral_maintenance_i_start_date: string | null;
      // 経口維持加算(I)終了日
      oral_maintenance_i_end_date: string | null;
      // 経口維持加算(II)
      oral_maintenance_ii_flg: number;
      // 経口維持加算(II)開始日
      oral_maintenance_ii_start_date: string | null;
      // 経口維持加算(II)終了日
      oral_maintenance_ii_end_date: string | null;
      // 運営ユニットid
      facility_unit_id?: number;
    }>;
    user_in_facility_kyotakukaigo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 居住する建物ID
      facility_unit_id: number | null;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 初任者研修課程修了者が作成した居宅介護計画に基づき提供する場合
      basis_training_supporter_flg: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 開始日
      date_begin_special_area: string | null;
      // 特別地域加算 終了日
      date_end_special_area: string | null;
      // 契約支給量_身体介護_月単位
      pay_months_agreed_physical_care: number;
      // 契約支給量_身体介護_回単位
      pay_times_agreed_physical_care: number;
      // 契約支給量_通院介助（伴う）_月単位
      pay_months_agreed_outpatient_care_with_physical_care: number;
      // 契約支給量_通院介助（伴う）_回単位
      pay_times_agreed_outpatient_care_with_physical_care: number;
      // 契約支給量_家事援助_月単位
      pay_months_agreed_housework_assistance: number;
      // 契約支給量_家事援助_回単位
      pay_times_agreed_housework_assistance: number;
      // 契約支給量_通院介助（伴ず）_月単位
      pay_months_agreed_outpatient_care: number;
      // 契約支給量_通院介助（伴ず）_回単位
      pay_times_agreed_outpatient_care: number;
      // 契約支給量_乗降介助_月単位
      pay_months_agreed_getting_on_and_off: number;
    }>;
    user_in_facility_judohomonkaigo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 重度障害者等包括支援
      severely_disabled_support_flg: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 開始日
      date_begin_special_area: string | null;
      // 特別地域加算 終了日
      date_end_special_area: string | null;
      // 契約支給量_重度訪問介護_月単位
      pay_months_agreed_severe_home_visit_care: number;
      // 契約支給量_重度訪問介護_移動介護_月単位
      pay_months_agreed_guide_severe_home_visit_care: number | null;
      // 同行支援の承認
      approval_of_accompany_support_flg: number;
      // 新任従業者の総数
      total_newly_appointed: number;
      // 同行支援の総時間数
      total_accompany_support_hours: number;
      // 同行支援開始日
      date_accompany_support_begin: string | null;
      // 同行支援終了日
      date_accompany_support_end: string | null;
    }>;
    user_in_facility_dokoengo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 盲ろう者
      classify_deafblind_flg: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 開始日
      date_begin_special_area: string | null;
      // 特別地域加算 終了日
      date_end_special_area: string | null;
      // 契約支給量_同行援護_月単位
      pay_months_agreed_accompanying_support: number;
    }>;
    user_in_facility_kodoengo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 開始日
      date_begin_special_area: string | null;
      // 特別地域加算 終了日
      date_end_special_area: string | null;
      // 契約支給量_行動援護_月単位
      pay_months_agreed_accompanying_support: number;
    }>;
    user_in_facility_chiikiiko?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 適用開始日
      date_begin_special_area: string | null;
      // 特別地域加算 適用終了日
      date_end_special_area: string | null;
      // 地域移行支援計画
      create_support_plan_flg: number;
      // 地域移行支援計画未作成期間終了日
      date_end_not_create_support_plan: string | null;
    }>;
    user_in_facility_chiikiteichaku?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 適用開始日
      date_begin_special_area: string | null;
      // 特別地域加算 適用終了日
      date_end_special_area: string | null;
    }>;
    user_in_facility_keikakusodan?: Partial<{
      /** 施設利用者id */
      users_in_facility_id: number;
      /** 要介護区分 */
      need_nursing_care_class: number;
      /** 要支援区分 */
      support_required_class: number;
      /** 障害児相談支援の利用 */
      handicapped_child_consultation_support_flg: number;
      /** 障害児相談支援の利用開始年月 */
      handicapped_child_consultation_support_from: string | null;
      /** 障害児相談支援の利用終了年月 */
      handicapped_child_consultation_support_to: string | null;
      /** 施設利用者（計画相談）：世帯通番 「相談種別：障害児相談」が存在する場合時のみ存在 */
      family_serial_number?: number;
      /** 利用者情報の相談種別 1：計画相談、2：障害児相談 */
      type_daily_consultation: 1 | 2;
      /** 施設利用者（計画相談/障害児相談）オブジェクト */
      user_in_facility_keikakusodan_shogaijisodan: {
        /** 相談種別 1:計画相談 2:障害児相談 */
        target_type: 1 | 2;
        /** 受給者証番号 */
        recipient_number: string;
        /** 受給者証未発行もしくは見学中の利用者 */
        none_recipient_number_flg: number;
        /** サービス開始日 */
        date_begin_in_service: string;
        /** サービス終了日 */
        date_end_in_service: string | null;
        /** 計画相談支援給付費開始日 */
        date_pay_begin: string;
        /** 計画相談支援給付費終了日 */
        date_pay_end: string;
        /** 障害区分 */
        disability_class: number;
        /** 所得区分 */
        income_kind: string;
        /** 負担上限月額0円の場合の所得区分 */
        income_classification: number;
        /** 自治体助成金対象 */
        subsidized_flg: string;
        /** 助成金フラグ（円かパーセントか） 1：%、2：¥ */
        subsidized_unit_flg: string | null;
        /** 助成金（パーセント） */
        subsidized_percent: number | null;
        /** 助成金（円） */
        subsidized_yen: number | null;
        /** 助成金支給市区町村番号 */
        subsidized_city_id: number | null;
        /** 上限管理事業所フラグ 0：上限管理なし、1：上限管理あり */
        uplimit_facility_flg: string;
        /** 他事業所調整上限額 */
        uplimit_yen: number | null;
        /** 上限管理事業所 1：自事業所が上限管理、2：他事業所が上限管理 */
        uplimit_controlled_by: string | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number2: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name2: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen2: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen2: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen2: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number3: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name3: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen3: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen3: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen3: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number4: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name4: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen4: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen4: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen4: number | null;
        /** 上限額管理結果 */
        result_of_management: string;
        /** 特別地域加算 */
        special_area_flg: number;
        /** 特別地域加算 適用開始日 */
        date_begin_special_area: string | null;
        /** 特別地域加算 適用終了日 */
        date_end_special_area: string | null;
        /** 障害福祉サービス等利用フラグ */
        disabled_welfare_service_use_flg: number;
        /** 障害福祉サービス等利用開始年月 */
        disabled_welfare_service_use_from: string | null;
        /** 障害福祉サービス等利用終了年月 */
        disabled_welfare_service_use_to: string | null;
        /** Knowbe開始前に計画を作成 */
        before_create_plan_flg: number;
        /** Knowbe開始前の計画作成日 */
        date_before_create_plan: string | null;
        /** Knowbe開始前にモニタリングを実施している */
        before_gone_monitoring_flg: number;
        /** Knowbe開始前のモニタリング実施 */
        date_before_gone_monitoring: string | null;
        /** 負担上限月額の適応期間 */
        user_charge_limit_flg: number;
        /** 負担上限月額の適応期間開始日 */
        date_begin_user_charge_limit: string | null;
        /** 負担上限月額の適応期間終了日 */
        date_end_user_charge_limit: string | null;
        /** 障害支援区分の認定有効期間 0：適用期間なし、1：適用期間あり */
        care_support_auth_flg: number;
        /** 障害支援区分の認定有効期間開始日 */
        date_begin_care_support_auth: string | null;
        /** 障害支援区分の認定有効期間終了日 */
        date_end_care_support_auth: string | null;
        /** 訓練給付費の支給期間 */
        plan_support_payment_flg: number;
        /** 訓練給付費の支給期間開始日 */
        date_begin_plan_support_payment: string | null;
        /** 訓練給付費の支給期間終了日 */
        date_end_plan_support_payment: string | null;
        /** 計画相談支援給付費モニタリング期間 */
        plan_support_monitor_flg: number;
        /** 計画相談支援給付費モニタリング期間開始日 */
        date_begin_plan_support_monitor: string | null;
        /** 計画相談支援給付費モニタリング期間終了日 */
        date_end_plan_support_monitor: string | null;
      }[];
    }>;
    user_in_facility_idoshien?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 自治体情報ID
      municipality_id: number;
      // 障害区分
      disability_class: number;
      // 契約支給量：個別支援：時間/月
      pay_times_individual_support: number;
      // 契約支給量：個別支援：回/月
      pay_months_individual_support: number;
      // 契約支給量：グループ支援：時間/月
      pay_times_group_support: number;
      // 契約支給量：グループ支援：回/月
      pay_months_group_support: number;
      // 契約支給量：車両移送支援：時間/月
      pay_times_vehicle_transfer_support: number;
      // 契約支給量：車両移送支援：回/月
      pay_months_vehicle_transfer_support: number;
      // 契約支給量：通学・通院支援：時間/月
      pay_times_school_and_hospital_commuting_support: number;
      // 契約支給量：通学・通院支援：回/月
      pay_months_school_and_hospital_commuting_support: number;
      // 契約支給量：その他支援：時間/月
      pay_times_other_support: number;
      // 契約支給量：その他支援：回/月
      pay_months_other_support: number;
      // 利用者負担（%）
      user_charge_rate: number;
      // 負担上限月額
      monthly_user_charge_limit: number;
    }>;
    default_status_settings?: {
      id: number;
      status: number;
      // デフォルト 開始時間
      start_time: string | null;
      // デフォルト 終了時間
      end_time: string | null;
      // 食事提供サービス
      def_food: string;
      // 送迎サービス
      def_pickup: string;
      // 同一敷地内送迎
      pickup_premises: number;
      // タイムカード表示
      time_card_display_flg: number;
    }[];
    // 「種別：I/A/B」のみ
    work_details?:
      | {
          id: number;
          work_time_use_flg: number;
          work_time_use_flg_of_week: number;
          work_truncate_minutes: number | null;
          work_start_time: string | null;
          work_end_time: string | null;
        }
      | [];
    // 「種別：I/A/B」のみ
    work_break_times?: {
      id: number;
      start_time: string;
      end_time: string;
    }[];
    // 「種別：I/A/B」のみ
    work_break_time_items?: {
      id: number;
      users_in_facility_work_time_items_id: number;
      users_in_facility_work_break_times_id: number;
      start_time: string;
      end_time: string;
      applied: number;
    }[];
    // 「種別：I/A/B」のみ
    work_time_items?: {
      id: number;
      start_time: string | null;
      end_time: string | null;
      day_of_the_week: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
    }[];
  };
};

/**
 * 事業所に紐づくユーザーをID指定で取得する。
 * @param id ユーザーID
 */
export const getFacilityUserTargetId = async (
  id: string
): Promise<AxiosResponse<GetFacilityUserTargetIdResponse>> => {
  const url = `${VERSION_URL_202104}/facility/users/${id}`;
  return request.get<GetFacilityUserTargetIdResponse>(url);
};

export default getFacilityUserTargetId;
