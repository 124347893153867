import * as React from "react";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import UsagePerformanceReportRow from "@components/organisms/mgr/SHISETSUNYUSHO/report/cells/UsagePerformanceReportRow";
import {
  ReportState,
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType,
  ReportType,
  REPEAT_DAILY
} from "@stores/domain/mgr/SHISETSUNYUSHO/report/types";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import UsagePerformanceTableHeader from "./UsagePerformanceTableHeader";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row: {
      height: 56,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    sticky: {
      zIndex: 10,
      position: "sticky",
      backgroundColor: "#fff"
    },
    stickyNotEditing: {
      height: 73,
      paddingTop: 16
    },
    stickyIsEditing: {
      height: 57
    }
  });

type StateProps = {
  report: ReportState;
};

type OwnProps = {
  isEditing: boolean;
  headerHeight: number;
  openModal: (
    usagePerformance: UsagePerformanceType,
    usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType
  ) => void;
  isDisabledFood: boolean;
  reportType: ReportType;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const UsagePerformanceTable = (props: Props): JSX.Element => {
  const isDaily = props.reportType === REPEAT_DAILY;
  const report = isDaily ? props.report.reportDaily : props.report.reportUsers;
  const usagePerformance = props.isEditing
    ? report.usagePerformance.after
    : report.usagePerformance.before;
  const usagePerformanceSHISETSUNYUSHO = props.isEditing
    ? report.usagePerformanceSHISETSUNYUSHO.after
    : report.usagePerformanceSHISETSUNYUSHO.before;
  const TableList = Object.keys(usagePerformance).map(
    (keyValue: string, index: number) => {
      return (
        <TableRow
          key={`table-row-${usagePerformance[keyValue].usersInFacilityId}-${usagePerformance[keyValue].targetDate}`}
          className={props.classes.row}
        >
          <UsagePerformanceReportRow
            usagePerformance={usagePerformance[keyValue]}
            usagePerformanceSHISETSUNYUSHO={
              usagePerformanceSHISETSUNYUSHO[keyValue]
            }
            idx={index}
            keyValue={keyValue}
            isEditing={props.isEditing}
            openModal={props.openModal}
            isDisabledFood={props.isDisabledFood}
            reportType={props.reportType}
          />
        </TableRow>
      );
    }
  );

  return (
    <>
      <div
        className={
          props.isEditing
            ? `${props.classes.sticky} ${props.classes.stickyIsEditing}`
            : `${props.classes.sticky} ${props.classes.stickyNotEditing}`
        }
        style={{ top: `${props.headerHeight}px` }}
      >
        <Table key="users-report-table">
          <UsagePerformanceTableHeader
            isEditing={props.isEditing}
            isDisabledFood={props.isDisabledFood}
            reportType={props.reportType}
          />
        </Table>
      </div>
      <Table key="users-report-table">
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者が登録されていません。
          <br />
          利用者情報画面で登録後、ご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    report: state.SHISETSUNYUSHO.report
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(UsagePerformanceTable)
);
