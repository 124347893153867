export const FETCH_LATEST_INOUT_ERRORS_STARTED =
  "PAGES/REPORT/DAILY/FETCH_LATEST_INOUT_ERRORS_STARTED ";
export const FETCH_LATEST_INOUT_ERRORS_SUCCESS =
  "PAGES/REPORT/DAILY/FETCH_LATEST_INOUT_ERRORS_SUCCESS";
export const FETCH_LATEST_INOUT_ERRORS_FAILED =
  "PAGES/REPORT/DAILY/FETCH_LATEST_INOUT_ERRORS_FAILED";

export interface ReportDailyState {
  errorsDateList: string[];
}
