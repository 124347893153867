import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import RectangleBox from "@components/atoms/RectangleBox";
import {
  DOKOENGOReportTypeInterface,
  REPEAT_MONTHLY
} from "@stores/v202104/domain/mgr/DOKOENGO/report/types";

const styles = createStyles({
  contents: {
    display: "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "flex-end" as "flex-end",
    marginBottom: 16
  },
  actionContents: {
    display: "flex",
    justifyContent: "end" as "end",
    width: "100%"
  },
  rectangleListContents: {
    display: "flex",
    justifyContent: "flex-start" as "flex-start",
    "& > :last-child": {
      marginRight: 0
    }
  },
  linkButton: {
    height: 36,
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    color: "#37474f",
    marginLeft: 8
  },
  button: {
    color: "#0277bd",
    width: "100%",
    paddingBottom: 4
  },
  // RectangleBox用のを上書き
  wrapper: {
    width: 203
  }
});

type OwnProps = {
  rectangleConfigList: {
    title: string;
    num: number;
    denom?: number;
    unit: string;
  }[];
  type: DOKOENGOReportTypeInterface["type"];
  onClickCopyReportListIcon: () => void;
  onClickCopyCarePlanIcon?: () => void;
  disabledCopyCarePlan?: boolean;
  disabledCopy: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportPaperHeaderCore = ({
  classes,
  rectangleConfigList,
  type,
  disabledCopyCarePlan = false,
  disabledCopy,
  onClickCopyReportListIcon,
  onClickCopyCarePlanIcon
}: Props): JSX.Element => (
  <div>
    <div className={classes.contents}>
      {rectangleConfigList && rectangleConfigList.length > 0 && (
        <div className={classes.rectangleListContents}>
          {rectangleConfigList.map((rectangleConfig, index) => {
            const uniqueKey = `${rectangleConfig.title}-${index}`;
            return (
              <RectangleBox
                classes={{ wrapper: classes.wrapper }}
                key={uniqueKey}
                {...rectangleConfig}
              />
            );
          })}
        </div>
      )}
      <div className={classes.actionContents}>
        {type === REPEAT_MONTHLY ? (
          <div className={classes.linkButton}>
            <Button
              className={classes.button}
              color="secondary"
              onClick={onClickCopyCarePlanIcon}
              disabled={disabledCopyCarePlan}
            >
              サービス予定を読み込む
            </Button>
          </div>
        ) : null}
        <div className={classes.linkButton}>
          <Button
            className={classes.button}
            color="secondary"
            onClick={onClickCopyReportListIcon}
            disabled={disabledCopy}
          >
            計画から実績へ一括コピー
          </Button>
        </div>
      </div>
    </div>
  </div>
);

export const InOutReportPaperHeader = withStyles(styles)(
  InOutReportPaperHeaderCore
);
