import { BasicErrors } from "@interfaces/mgr/IDOSHIEN/Users/basic";
import { ServiceUseErrors } from "@interfaces/mgr/IDOSHIEN/Users/serviceUse";
import { UsersValues } from "@initialize/mgr/IDOSHIEN/users/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type FacilityErrors = BasicErrors & ServiceUseErrors;

const basicValidation = (values: UsersValues): BasicErrors => {
  return {
    basic: {
      nameSei: validator(values.basic.nameSei, "required"),
      nameMei: validator(values.basic.nameMei, "required"),
      nameSeiKana: validator(values.basic.nameSeiKana, "required", "kana"),
      nameMeiKana: validator(values.basic.nameMeiKana, "required", "kana"),
      recipientNumber: validator(
        values.basic.recipientNumber,
        {
          type: "required",
          shouldValidate: !values.basic.noneRecipientNumberFlag
        },
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      dateOfBirth: dateValidator(
        notSelectedToEmpty(values.basic.dateOfBirth),
        "required"
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      municipalityId: validator(values.basic.municipalityId),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "naturalNumber"),
      email: validator(values.basic.email, "email"),
      guardianNameSei: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianNameSei, "required")
      ),
      guardianNameMei: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianNameMei, "required")
      ),
      guardianNameSeiKana: values.basic.classifyHandicappedFlag
        ? validator(values.basic.guardianNameSeiKana, "required", "kana")
        : validator(values.basic.guardianNameSeiKana, "kana"),
      guardianNameMeiKana: values.basic.classifyHandicappedFlag
        ? validator(values.basic.guardianNameMeiKana, "required", "kana")
        : validator(values.basic.guardianNameMeiKana, "kana"),
      guardianRelation: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianRelation, "required")
      ),
      guardianTel: validator(values.basic.guardianTel, "naturalNumber")
    }
  };
};

const serviceUseValidation = (values: UsersValues): ServiceUseErrors => {
  return {
    serviceUse: {
      inServiceStartDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.inServiceStartDate),
        "required"
      ),
      inServiceEndDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.inServiceEndDate),
        {
          type: "future",
          startDate: values.serviceUse.inServiceStartDate,
          options: {
            startLabel: "サービス提供開始日",
            endLabel: "サービス提供終了日"
          }
        }
      ),
      payStartDate: validateSwitcher(
        !values.basic.noneRecipientNumberFlag,
        dateValidator(
          notSelectedToEmpty(values.serviceUse.payStartDate),
          "required"
        )
      ),
      payEndDate: values.basic.noneRecipientNumberFlag
        ? dateValidator(notSelectedToEmpty(values.serviceUse.payEndDate), {
            type: "future",
            startDate: values.serviceUse.payStartDate,
            options: {
              startLabel: "支給決定開始日",
              endLabel: "支給決定終了日"
            }
          })
        : dateValidator(
            notSelectedToEmpty(values.serviceUse.payEndDate),
            "required",
            {
              type: "future",
              startDate: values.serviceUse.payStartDate,
              options: {
                startLabel: "支給決定開始日",
                endLabel: "支給決定終了日"
              }
            }
          ),
      payTimesIndividualSupport: validator(
        values.serviceUse.payTimesIndividualSupport,
        "decimalPoint",
        "decimalPointByEveryHalfMinutes"
      ),
      payMonthsIndividualSupport: validator(
        values.serviceUse.payMonthsIndividualSupport,
        "naturalNumberNonZero"
      ),
      payTimesGroupSupport: validator(
        values.serviceUse.payTimesGroupSupport,
        "decimalPoint",
        "decimalPointByEveryHalfMinutes"
      ),
      payMonthsGroupSupport: validator(
        values.serviceUse.payMonthsGroupSupport,
        "naturalNumberNonZero"
      ),
      payTimesVehicleTransferSupport: validator(
        values.serviceUse.payTimesVehicleTransferSupport,
        "decimalPoint",
        "decimalPointByEveryHalfMinutes"
      ),
      payMonthsVehicleTransferSupport: validator(
        values.serviceUse.payMonthsVehicleTransferSupport,
        "naturalNumberNonZero"
      ),
      payTimesSchoolAndHospitalCommutingSupport: validator(
        values.serviceUse.payTimesSchoolAndHospitalCommutingSupport,
        "decimalPoint",
        "decimalPointByEveryQuarterMinutesAfter30Minutes"
      ),
      payMonthsSchoolAndHospitalCommutingSupport: validator(
        values.serviceUse.payMonthsSchoolAndHospitalCommutingSupport,
        "naturalNumberNonZero"
      ),
      payTimesOtherSupport: validator(
        values.serviceUse.payTimesOtherSupport,
        "decimalPoint",
        "decimalPointByEveryQuarterMinutesAfter30Minutes"
      ),
      payMonthsOtherSupport: validator(
        values.serviceUse.payMonthsOtherSupport,
        "naturalNumberNonZero"
      ),
      userChargeRate: validator(
        values.serviceUse.userChargeRate,
        "naturalNumberNonZero",
        { type: "upperLimit", upperLimit: 100 }
      ),
      monthlyUserChargeLimit: validator(
        values.serviceUse.monthlyUserChargeLimit,
        "naturalNumberNonZero"
      )
    }
  };
};

export const validation = (values: UsersValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const serviceUseErrors = serviceUseValidation(values);
  return { ...basicErrors, ...serviceUseErrors };
};
