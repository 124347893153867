/**
 * セレクトボックス形式日付の必須チェック（任意のエラーメッセージ）
 * @param value
 */

import requiredAnyMessage from "@validator/rules/requiredAnyMessage";
import { SelectDateValue } from "@interfaces/ui/form";

const requiredDateAnyMessage = (
  date: SelectDateValue,
  message: string
): {
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
} => {
  return {
    year: requiredAnyMessage(date.year, message),
    month: requiredAnyMessage(date.month, message),
    day: requiredAnyMessage(date.day, message)
  };
};

export default requiredDateAnyMessage;
