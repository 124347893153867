import React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Formik, FormikActions } from "formik";
import deepEqual from "fast-deep-equal";
import { format } from "date-fns";

// components
import AdminTemplate from "@componentsMobile/templates/AdminTemplate";
import { SupportProcedureForm } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordForm";
import { SupportProcedureRecordInfo } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordInfo";
import { SupportProcedureRecordActions } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordActions";

// stores
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import dispatches from "@stores/dispatches";

// utils
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import {
  checkErrorIsValidation,
  createPostValidationError
} from "@utils/domain/mgr/KODOENGO/supportProcedureErrorValidation";
import { FieldItem } from "@interfaces/ui/form";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { validate } from "@initialize/mgr/KODOENGO/record/supportProcedure/validation";
import {
  initialFormValues,
  initialValuesEdit,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { getUrlParams } from "@utils/url";
import { toEffectiveObject } from "@utils/object";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";

const styles = (): StyleRules => createStyles({});

type OwnProps = RouteComponentProps<{
  uifId: string;
  supportProcedureId: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UsersInFacilityState["user"];
  staff: StaffState;
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  needsStopHistory: boolean;
};

type DispatchProps = {
  fetchStaff: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchSupportProcedureDetail: (
    serviceDeliveryRecordsId?: string,
    inoutResultsId?: string,
    supportProcedureFormsId?: string
  ) => void;
  postSupportProcedureDetailForm: (
    formValues: SupportProcedureDetailFormValues,
    staff: StaffState,
    apiResponse: GetSupportProcedureDetailResponse["data"]
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type MergeProps = {
  authorName: string;
  authorizerRole: string;
  staffOptionsAddSnapShot: FieldItem[];
  staffWithSnap: StaffState;
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

type SupportProcedureDetailQuery = {
  serviceDeliveryRecordsId?: string;
  inoutResultsId?: string;
  supportProcedureFormsId?: string;
  originSupportProcedureFormsId?: string;
};

export const SupportProcedureRecordFormMobileCore = (
  props: Props
): JSX.Element => {
  const { uifId } = props.match.params;

  const pageTitle = "支援記録 > 支援手順書 兼 記録用紙";

  const [targetObject, setTargetObject] = React.useState<TargetObject[]>([]);
  const [isEdit, setIsEdit] = React.useState(false);

  const [formValues, setFormValues] = React.useState(initialFormValues);
  const rest = getUrlParams(
    props.location.search
  ) as SupportProcedureDetailQuery;
  React.useEffect(() => {
    props.fetchOneUser(uifId);
    props.fetchStaff();
  }, []);

  React.useEffect(() => {
    setFormValues(
      initialValuesEdit(
        props.supportProcedureDetail,
        props.staffOptionsAddSnapShot
      )
    );
    // targetObjectに分かれている値を設定する
    setTargetObject([
      {
        targetDate: props.supportProcedureDetail.target_date,
        serviceDeliveryRecordsId: null,
        inoutResultsId: null,
        supportProcedureFormsId: null
      }
    ]);
  }, [props.supportProcedureDetail]);

  React.useEffect(() => {
    const {
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    } = rest;
    props.fetchSupportProcedureDetail(
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    );
  }, [props.location.search]);

  const confirmDiscardFormChanges = (
    nextValues: SupportProcedureDetailFormValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  const validateForm = (
    value: SupportProcedureDetailFormValues
  ): void | object => {
    const validationResult = validate(value);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(value);
    }
    return error;
  };

  const onSubmit = async (
    values: SupportProcedureDetailFormValues,
    actions: FormikActions<SupportProcedureDetailFormValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postSupportProcedureDetailForm(
        values,
        props.staff,
        props.supportProcedureDetail
      )
      .then(() => {
        // 更新後に情報を取得
        const {
          serviceDeliveryRecordsId,
          inoutResultsId,
          supportProcedureFormsId
        } = rest;
        props.fetchSupportProcedureDetail(
          serviceDeliveryRecordsId,
          inoutResultsId,
          supportProcedureFormsId
        );
        setIsEdit(false);
      })
      .catch((e) => {
        if (checkErrorIsValidation(e)) {
          const error = createPostValidationError(e);
          actions.setErrors(error);
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  // 編集モードへ移動
  const handleClickStatusToEdit = (): void => {
    setIsEdit(true);
  };

  // 一覧へ戻る
  const handleClickReturnList = (): void => {
    const isDaily = props.location.pathname.includes("daily");
    const datePath = props.supportProcedureDetail.target_date
      ? format(
          fixDateAndTimeFormat(props.supportProcedureDetail.target_date),
          isDaily ? "YYYYMMDD" : "YYYYMM"
        )
      : "";
    const returnUrl = isDaily
      ? `/record/service_delivery/daily/list/${datePath}`
      : `/record/service_delivery/monthly/list/${uifId}/${datePath}`;
    props.history.push(returnUrl);
  };

  return (
    <AdminTemplate pageName={pageTitle}>
      <div>
        <SupportProcedureRecordInfo
          supportProcedureDetail={props.supportProcedureDetail}
          userName={props.userName}
        />
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validateForm}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            // 編集キャンセル
            const handleClickCancel = (): void => {
              formikProps.resetForm();
              setIsEdit(false);
            };

            return (
              <>
                <SupportProcedureForm
                  staffOptions={props.staffOptionsAddSnapShot}
                  supportProcedureDetail={props.supportProcedureDetail}
                  formikProps={formikProps}
                  targetObject={targetObject}
                  staff={props.staff}
                  isEdit={isEdit}
                />
                <SupportProcedureRecordActions
                  isEdit={isEdit}
                  formikProps={formikProps}
                  onClickReturnList={handleClickReturnList}
                  onClickStatusToEdit={handleClickStatusToEdit}
                  onClickCancel={handleClickCancel}
                />
              </>
            );
          }}
        </Formik>
      </div>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.KODOENGO.userInFacility.user,
  staff: state.staff,
  supportProcedureDetail:
    state.KODOENGO.supportProcedure.supportProcedureDetail,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  const uiDispatcher = dispatches.uiDispatch(dispatch);

  return {
    fetchStaff: KODOENGO.staffDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      KODOENGO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportProcedureDetail: (
      serviceDeliveryRecordsId?: string,
      inoutResultsId?: string,
      supportProcedureFormsId?: string
    ): void => {
      KODOENGO.supportProcedureDispatcher(dispatch).fetchSupportProcedureDetail(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      );
    },
    postSupportProcedureDetailForm: (
      formValues: SupportProcedureDetailFormValues,
      staff: StaffState,
      apiResponse: GetSupportProcedureDetailResponse["data"]
    ): Promise<void> => {
      return KODOENGO.supportProcedureDispatcher(
        dispatch
      ).postSupportProcedureDetailForm(formValues, staff, apiResponse);
    },
    stopHistory: uiDispatcher.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, user } = stateProps;

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  // 作成者名
  const authorName = "";
  // 承認者役職
  const authorizerRole = "";

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  const staffOptionsAddSnapShot = staffOptions;

  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptionsAddSnapShot.length > staff.staffItems.length) {
    const snapOption = staffOptionsAddSnapShot.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorName,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  return {
    authorName,
    authorizerRole,
    staffOptionsAddSnapShot,
    staffWithSnap,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportProcedureRecordFormMobile = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SupportProcedureRecordFormMobileCore)
);
