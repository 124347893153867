import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN,
  UIFACILITY_TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

/**
 * 利用者詳細から計画相談の利用者情報を取得
 * ない場合は undefined
 */
export const findUserInFacilityKeikakusodan = (
  state: UsersInFacilityState["user"]
):
  | NonNullable<
      NonNullable<
        UsersInFacilityState["user"]["user_in_facility_keikakusodan"]
      >["user_in_facility_keikakusodan_shogaijisodan"]
    >[number]
  | undefined => {
  return state.user_in_facility_keikakusodan &&
    state.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan
    ? state.user_in_facility_keikakusodan.user_in_facility_keikakusodan_shogaijisodan.find(
        (v) => v.target_type === FACILITY_TARGET_TYPE_KEIKAKUSODAN
      )
    : undefined;
};

/**
 * 利用者詳細から障害児相談の利用者情報を取得
 * ない場合は undefined
 */
export const findUserInFacilityShogaijisodan = (
  state: UsersInFacilityState["user"]
):
  | NonNullable<
      NonNullable<
        UsersInFacilityState["user"]["user_in_facility_keikakusodan"]
      >["user_in_facility_keikakusodan_shogaijisodan"]
    >[number]
  | undefined => {
  return state.user_in_facility_keikakusodan &&
    state.user_in_facility_keikakusodan
      .user_in_facility_keikakusodan_shogaijisodan
    ? state.user_in_facility_keikakusodan.user_in_facility_keikakusodan_shogaijisodan.find(
        (v) => v.target_type === FACILITY_TARGET_TYPE_SHOGAIJISODAN
      )
    : undefined;
};

/**
 * 事業所情報と利用者情報のフラグを掛け合わせて利用者の今時点の利用種別判定を行う
 * @param facilityState 事業所情報
 * @param uifState 利用者詳細
 * @returns 利用者が計画相談の場合 true
 */
export const isUserTypeKeikakusodan = (
  facilityState: FacilityState,
  uifState: UsersInFacilityState["user"]
): boolean => {
  // 計画相談の情報が取得できていない
  if (!uifState.user_in_facility_keikakusodan) return false;
  const hasFacilityTypeKeikakusodan =
    facilityState.facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
    facilityState.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  // 計画相談を事業所で契約していない
  if (!hasFacilityTypeKeikakusodan) return false;
  // 事業所が計画相談のみの契約だった場合は利用者は表示上計画相談となる
  if (facilityState.facilityType === FACILITY_TYPE_KEIKAKUSODAN) return true;
  return (
    uifState.user_in_facility_keikakusodan.type_daily_consultation ===
    UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN
  );
};

/**
 * 事業所情報と利用者情報のフラグを掛け合わせて、利用者の居間時点の利用種別判定を行う
 * @param facilityState 事業所情報
 * @param uifState 利用者詳細
 * @returns 利用者が障害児相談支援の場合 true
 */
export const isUserTypeShogaijisodan = (
  facilityState: FacilityState,
  uifState: UsersInFacilityState["user"]
): boolean => {
  // 計画相談の情報が取得できていない
  if (!uifState.user_in_facility_keikakusodan) return false;
  const hasFacilityTypeShogaijisodan =
    facilityState.facilityType === FACILITY_TYPE_SHOGAIJISODAN ||
    facilityState.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  // 障害児相談を事業所で契約していない
  if (!hasFacilityTypeShogaijisodan) return false;
  // 事業所が障害児相談支援のみの施設区分だった場合は表示上障害児相談支援
  if (facilityState.facilityType === FACILITY_TYPE_SHOGAIJISODAN) {
    return true;
  }
  return (
    uifState.user_in_facility_keikakusodan.type_daily_consultation ===
    UIFACILITY_TYPE_CONSULTATION_SHOGAIJISODAN
  );
};
