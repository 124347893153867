import get from "lodash-es/get";
import { dateToObject, dateToSelectDateValue } from "@utils/date";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import {
  SupportPlanSheetDetails,
  SupportPlanSheets
} from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import { FieldItem } from "@interfaces/ui/form";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";

// 支援課題と対応・方針
export const supportPlanSheetDetailsValue: SupportPlanSheetDetails = {
  support_plan_sheet_detail_id: null,
  support_issue: "",
  response_policy: "",
  is_delete: false
};

// 閲覧・編集
export const initialValues = (
  supportPlanSheets: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"],
  staffOptions?: FieldItem[]
): SupportPlanSheets => {
  const value = supportPlanSheets;
  const supportPlanSheetDetails = value.support_plan_sheet_details;

  let authorId = null;
  if (staffOptions && value && value.author) {
    const authorIsDelete = staffOptions.every(
      (option) => value.author && option.value !== String(value.author.id)
    );
    authorId =
      value.author.name !== value.author.snapshot_name && !authorIsDelete
        ? 0
        : value.author.id;
  }

  return {
    support_plan_sheet_id: get(value, "id"),
    created_at: undefinedToReturnValue(get(value, "created_at")),
    updated_at: undefinedToReturnValue(get(value, "updated_at")),
    author: authorId !== null ? `${authorId}` : authorId,
    creation_date: dateToSelectDateValue(
      undefinedToReturnValue(get(value, "creation_date"))
    ),
    intake: undefinedToReturnValue(get(value, "intake")),
    assessment_biological: undefinedToReturnValue(
      get(value, "assessment_biological")
    ),
    assessment_psychological: undefinedToReturnValue(
      get(value, "assessment_psychological")
    ),
    assessment_social: undefinedToReturnValue(get(value, "assessment_social")),
    support_plan_sheet_details:
      supportPlanSheetDetails.length !== 0
        ? supportPlanSheetDetails.map((item) => {
            return {
              support_plan_sheet_detail_id: item.support_plan_sheet_detail_id,
              support_issue: undefinedToReturnValue(get(item, "support_issue")),
              response_policy: undefinedToReturnValue(
                get(item, "response_policy")
              ),
              is_delete: false
            };
          })
        : []
  };
};

// 新規・コピー
export const initialValuesCopy = (
  supportPlanSheets?: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"]
): SupportPlanSheets => {
  const value = supportPlanSheets;

  let sheet;
  // コピーの場合
  if (value) {
    sheet = {
      intake: undefinedToReturnValue(get(value, "intake")),
      assessment_biological: undefinedToReturnValue(
        get(value, "assessment_biological")
      ),
      assessment_psychological: undefinedToReturnValue(
        get(value, "assessment_psychological")
      ),
      assessment_social: undefinedToReturnValue(
        get(value, "assessment_social")
      ),
      support_plan_sheet_details:
        value.support_plan_sheet_details.length !== 0
          ? value.support_plan_sheet_details.map((item) => {
              return {
                support_plan_sheet_detail_id: null,
                support_issue: undefinedToReturnValue(
                  get(item, "support_issue")
                ),
                response_policy: undefinedToReturnValue(
                  get(item, "response_policy")
                ),
                is_delete: false
              };
            })
          : []
    };
    // 新規の場合
  } else {
    sheet = {
      intake: "",
      assessment_biological: "",
      assessment_psychological: "",
      assessment_social: "",
      support_plan_sheet_details: [supportPlanSheetDetailsValue]
    };
  }

  return {
    support_plan_sheet_id: null,
    creation_date: dateToObject(new Date()),
    author: null,
    ...sheet
  };
};
