import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g id="Outline">
        <g id="ui_x5F_spec_x5F_header" />
        <g>
          <path d="M17.2888763,3.81818182 L18.1979672,3.81818182 C19.1979672,3.81818182 20.016149,4.63636364 20.016149,5.63636364 L20.016149,10 L18,10 L18,9 L4,9 L4,20.1818182 L10.1818182,20.1818182 L10.1818182,22 L3.81818182,22 C2.81818182,22 2,21.1818182 2,20.1818182 L2,5.63636364 C2,4.63636364 2.81818182,3.81818182 3.81818182,3.81818182 L4.72727273,3.81818182 L4.72727273,2 L6.54545455,2 L6.54545455,3.81818182 L15.4706945,3.81818182 L15.4706945,2 L17.2888763,2 L17.2888763,3.81818182 Z M16.5,11 C19.536,11 22,13.465 22,16.501 C22,19.537 19.536,22.001 16.5,22.001 C13.464,22.001 11,19.537 11,16.501 C11,13.465 13.464,11 16.5,11 Z M18.9729334,18.9984013 C20.3434185,17.6279162 20.3434185,15.4023422 18.9665442,14.0318571 C18.2818341,13.3460821 17.3862802,13 16.4832721,13 L16.4832721,16.5151292 L14,18.9984013 C15.3704851,20.3688864 17.5960592,20.3688864 18.9729334,18.9984013 Z" />
        </g>
      </g>
    </SvgIcon>
  );
};
