import * as React from "react";

import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const styles = (): StyleRules =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    circleSVG: {
      "@media only screen and (min-width: 640px)": {
        color: "orange"
      },
      "@media only screen and (max-width: 639px)": {
        color: "#546E7A"
      }
    },
    root: {
      "@media only screen and (min-width: 640px)": {
        width: "80px!important",
        height: "80px!important"
      },
      "@media only screen and (max-width: 639px)": {
        width: "40px!important",
        height: "40px!important"
      }
    }
  });

type OwnProps = {
  isShown: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const BackdropComponent = (): JSX.Element => <Backdrop open invisible />;
const Loading = ({ isShown, classes }: Props): JSX.Element | null => {
  React.useEffect(() => {
    // DialogとModalの利用が重なるとoverflowがhiddenのままになるバグがあるため、明示的に元に戻す
    if (!isShown) {
      document.body.style.overflow = "";
    }
  }, [isShown]);
  return isShown ? (
    <Modal
      open
      className={classes.modal}
      disableAutoFocus
      BackdropComponent={BackdropComponent}
    >
      <CircularProgress
        classes={{ svg: classes.circleSVG, root: classes.root }}
      />
    </Modal>
  ) : null;
};

export default withStyles(styles)(Loading);
