import { CustomRecordsState } from "@stores/domain/customRecords/types";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  FacilityType
} from "@constants/variables";
import { SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM as SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO } from "@constants/mgr/TANKINYUSHO/variables";

/**
 * "職員考察"ラベルの取得
 * 記録の項目設定 > 支援記録の項目設定の職員考察は名称の変更が可能なため、customRecordsを渡されたら現在の名称を取得する
 */

type getStaffCommentParam = {
  serviceType?: string;
  customRecords?: CustomRecordsState;
};
export const getStaffCommentLabel = ({
  serviceType,
  customRecords
}: getStaffCommentParam): string => {
  let staffCommentDefaultItem: number | null = null;
  switch (serviceType) {
    case FacilityType.GROUP_HOME:
    case FacilityType.SHISETSUNYUSHO:
      staffCommentDefaultItem =
        SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment;
      break;
    case FacilityType.TANKINYUSHO:
      staffCommentDefaultItem =
        SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO.staff_comment;
      break;
    default:
  }
  const staffComment =
    Array.isArray(customRecords) && staffCommentDefaultItem !== null
      ? customRecords.find(
          (custom) =>
            custom.default_item === staffCommentDefaultItem &&
            custom.setting_type === CUSTOM_RECORD_TARGET_TYPE.support
        )
      : undefined;
  return staffComment ? staffComment.name : "職員考察";
};
