import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { getHoliday } from "@api/requests/holiday/getHoliday";

export const fetchHoliday = (dispatch: Dispatch) => async (
  date: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchHolidayStarted());
  await getHoliday(date)
    .then((res) => {
      dispatch(actions.fetchHolidaySuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchHolidayFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchHoliday: (date: string) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchHoliday: fetchHoliday(dispatch)
});
