import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  DOKOENGO_DISPLAY_STATUS_FULL_WIDTH_LIST,
  DOKOENGO_DISPATCH_NO_LIST
} from "@constants/mgr/DOKOENGO/variables";
import {
  DataListState,
  CarePlanState,
  DeleteCarePlanState
} from "@stores/domain/mgr/DOKOENGO/carePlan/types";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";

const styles = (): StyleRules =>
  createStyles({
    superShortNumberCell: {
      width: "2%",
      minWidth: 16,
      maxWidth: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      textAlign: "center",
      "&:last-child": {
        paddingRight: 16
      }
    },
    superShortCell: {
      width: "6.3%",
      minWidth: 16,
      maxWidth: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    shortCell: {
      width: "10.5%",
      minWidth: 44,
      maxWidth: 44,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    middleCell: {
      width: "16.4%",
      minWidth: 150,
      maxWidth: 150,
      boxSizing: "content-box",
      lineHeight: "28px"
    },
    icon: {
      paddingLeft: 15,
      textAlign: "center"
    },
    iconDiv: {
      height: 26
    }
  });

type OwnProps = {
  dayOfWeek: number;
  params: DataListState;
  idx: string;
  classOption: string;
  openModal: (param: CarePlanState) => void;
  onClickDeleteIcon: (target: DeleteCarePlanState) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  const planDispatchNo = params.plan.dispatchNo
    ? params.plan.dispatchNo.toString()
    : null;

  const planStatusValue = params.plan.status
    ? params.plan.status.toString()
    : "0";

  const openEditPlanModal = (): void => {
    props.openModal(params.planDetail);
  };

  const onClickPlanDeleteIcon = (): void => {
    const target: DeleteCarePlanState = {
      uifId: `${params.planDetail.usersInFacilityId}`,
      users_in_facility_care_plan_id: params.planDetail.id
    };
    props.onClickDeleteIcon(target);
  };

  return (
    <>
      {/* 計画ヘルパー番号 */}
      <TableCellWrap
        key={`${idx}-care-worker-license`}
        cellClass={`${classes.superShortNumberCell} ${props.classOption}`}
      >
        {planDispatchNo
          ? getLabelFromOptions(planDispatchNo, DOKOENGO_DISPATCH_NO_LIST)
          : ""}
      </TableCellWrap>

      {/* サービス内容 */}
      <TableCellWrap
        key={`${idx}-plan-status`}
        cellClass={`${classes.middleCell} ${props.classOption}`}
      >
        {getLabelFromOptions(
          planStatusValue,
          DOKOENGO_DISPLAY_STATUS_FULL_WIDTH_LIST
        )}
      </TableCellWrap>

      {/* 開始時間 */}
      <TableCellWrap
        key={`${idx}-plan-in-time`}
        cellClass={`${classes.shortCell} ${props.classOption}`}
      >
        {params.plan.inTime ? params.plan.inTime : "-"}
      </TableCellWrap>

      {/* 終了時間 */}
      <TableCellWrap
        key={`${idx}-plan-out-time`}
        cellClass={`${classes.shortCell} ${props.classOption}`}
      >
        {params.plan.outTime ? params.plan.outTime : "-"}
      </TableCellWrap>

      {/* 時間 */}
      <TableCellWrap
        key={`${idx}-plan-calculated-hours`}
        cellClass={`${classes.shortCell} ${props.classOption}`}
      >
        {params.plan.calculatedHours ? params.plan.calculatedHours : "-"}
      </TableCellWrap>

      {/* 人数 */}
      <TableCellWrap
        key={`${idx}-plan-participants-number`}
        cellClass={`${classes.shortCell} ${props.classOption}`}
      >
        {params.plan.numberOfParticipants
          ? params.plan.numberOfParticipants
          : "-"}
      </TableCellWrap>

      <TableCellWrap
        key={`${idx}-plan-delete-icon`}
        cellClass={`${props.classes.superShortCell} ${props.classes.icon} ${props.classes.tableCell}`}
        align="center"
      >
        {params.plan.status ? (
          <div className={classes.iconDiv}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickPlanDeleteIcon}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-plan-edit-icon`}
        cellClass={`${props.classes.superShortCell} ${props.classes.icon} ${props.classes.tableCell}`}
        align="center"
      >
        {params.planDetail.usersInFacilityId ? (
          <div className={classes.iconDiv}>
            <Edit
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={openEditPlanModal}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>
    </>
  );
};

export const CarePlanTableCells = withStyles(styles)(CarePlanTableCellsCore);
