import * as React from "react";
import { WARNING_YELLOW_COLOR } from "@constants/styles";

import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    label: {
      display: "inline-block",
      padding: "4px 12px",
      borderRadius: 12,
      backgroundColor: WARNING_YELLOW_COLOR,
      color: "#fff",
      fontSize: 12,
      lineHeight: 16 / 12
    }
  });

type OwnProps = {
  text: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 *  202104報酬改定対応で追加されたラベル。
 */
export const ChipsLabel = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes, text } = props;
    return <span className={classes.label}>{text}</span>;
  }
);
