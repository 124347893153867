import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";

import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { BasicInfoItem } from "@components/organisms/mgr/common/record/carePlan/Dialog/Edit/BasicInfoItem";

import { StaffData } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/types";
import {
  SupportCarePlanBasicInfo,
  SupportCarePlanSchedules
} from "@interfaces/record/carePlan/carePlan";
import { schedulesBasicInfoValue } from "@initialize/record/carePlan/initialValues";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    buttonCon: {
      alignItems: "center",
      "&>span>span": {
        lineHeight: 1,
        minHeight: "auto",
        display: "block",
        marginLeft: "13px"
      }
    },
    noMessage: {
      fontSize: "12px",
      lineHeight: 1.3,
      letterSpacing: "0.4px",
      color: "rgba(0, 0, 0, 0.6)",
      padding: "16px",
      borderBottom: "1px solid #e0e0e0"
    },
    MT16: {
      marginTop: "16px"
    }
  });

type OwnProps = {
  isRides: boolean;
  isEditing: boolean;
  staffItems: StaffData[];
  formikProps: FormikProps<SupportCarePlanSchedules>;
  facilityType: FacilityType;
  isDisabledSaveButton: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const BasicInfoCore = (props: Props): JSX.Element => {
  const {
    classes,
    isRides,
    isEditing,
    staffItems,
    formikProps,
    facilityType,
    isDisabledSaveButton
  } = props;

  const maxLength = 50;
  const [isMaxLength, setIsMaxLength] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsMaxLength(
      formikProps.values.support_care_plan_basic_info.length >= maxLength
    );
  }, [formikProps.values.support_care_plan_basic_info]);

  return (
    <div>
      <FieldArray name="support_care_plan_basic_info">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          const onClickAddSchedules = (): void => {
            arrayHelpers.push(schedulesBasicInfoValue);
          };
          return (
            <div>
              {formikProps.values.support_care_plan_basic_info.length > 0 ? (
                formikProps.values.support_care_plan_basic_info.map(
                  (item: SupportCarePlanBasicInfo, index) => {
                    const nameNumber = index;
                    const unitKey = `support_care_plan_basic_info_${index}`;
                    return (
                      <BasicInfoItem
                        key={unitKey}
                        item={item}
                        isRides={isRides}
                        isEditing={isEditing}
                        staffItems={staffItems}
                        nameNumber={nameNumber}
                        formikProps={formikProps}
                        facilityType={facilityType}
                      />
                    );
                  }
                )
              ) : (
                <div className={classes.noMessage}>
                  基本情報が登録されていません
                </div>
              )}
              {isEditing && (
                <KnowbeButton
                  kind="iconText"
                  onClick={onClickAddSchedules}
                  disabled={isDisabledSaveButton || isMaxLength}
                  className={ClassNames(classes.buttonCon, classes.MT16)}
                >
                  <AddIcon />
                  <span>追加</span>
                </KnowbeButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const BasicInfo = withStyles(styles)(BasicInfoCore);
