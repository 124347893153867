import { BasicValues } from "@interfaces/mgr/IDOSHIEN/Users/basic";
import { ServiceUseValues } from "@interfaces/mgr/IDOSHIEN/Users/serviceUse";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";

export type UsersValues = BasicValues & ServiceUseValues;

export const initialValues = (
  state?: UsersInFacilityState["user"]
): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const IDOSHIEN =
    state && state.user_in_facility_idoshien
      ? state.user_in_facility_idoshien
      : {};
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTrueOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTrueOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTrueOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTrueOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTrueOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTrueOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTrueOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTrueOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: undefinedToReturnValue(
        userInFacility.city_id,
        DEFAULT_SELECT_VALUE
      ),
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianNameSei: undefinedToReturnValue(userInFacility.guardian_name_sei),
      guardianNameMei: undefinedToReturnValue(userInFacility.guardian_name_mei),
      guardianNameSeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_sei_kana
      ),
      guardianNameMeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_mei_kana
      ),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo),
      municipalityId: undefinedToReturnValue(
        IDOSHIEN.municipality_id,
        DEFAULT_SELECT_VALUE
      )
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      disabilityClass: undefinedToReturnValue(IDOSHIEN.disability_class, 0),
      payTimesIndividualSupport: undefinedToReturnValue(
        IDOSHIEN.pay_times_individual_support
      ),
      payMonthsIndividualSupport: undefinedToReturnValue(
        IDOSHIEN.pay_months_individual_support
      ),
      payTimesGroupSupport: undefinedToReturnValue(
        IDOSHIEN.pay_times_group_support
      ),
      payMonthsGroupSupport: undefinedToReturnValue(
        IDOSHIEN.pay_months_group_support
      ),
      payTimesVehicleTransferSupport: undefinedToReturnValue(
        IDOSHIEN.pay_times_vehicle_transfer_support
      ),
      payMonthsVehicleTransferSupport: undefinedToReturnValue(
        IDOSHIEN.pay_months_vehicle_transfer_support
      ),
      payTimesSchoolAndHospitalCommutingSupport: undefinedToReturnValue(
        IDOSHIEN.pay_times_school_and_hospital_commuting_support
      ),
      payMonthsSchoolAndHospitalCommutingSupport: undefinedToReturnValue(
        IDOSHIEN.pay_months_school_and_hospital_commuting_support
      ),
      payTimesOtherSupport: undefinedToReturnValue(
        IDOSHIEN.pay_times_other_support
      ),
      payMonthsOtherSupport: undefinedToReturnValue(
        IDOSHIEN.pay_months_other_support
      ),
      userChargeRate: undefinedToReturnValue(IDOSHIEN.user_charge_rate),
      monthlyUserChargeLimit: undefinedToReturnValue(
        IDOSHIEN.monthly_user_charge_limit
      )
    }
  };
};
