/**
 * 特定の期間に対象の日付が含まれているかチェック
 */
export const checkDatesRange = (
  targetDates: string[],
  start: string,
  end: string
): boolean => {
  let result = false;
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (Number.isNaN(startDate.getTime()) || Number.isNaN(endDate.getTime())) {
    return result;
  }
  targetDates.forEach((item) => {
    const dateItem = new Date(item);
    if (
      !Number.isNaN(startDate.getTime()) &&
      dateItem >= startDate &&
      dateItem <= endDate
    ) {
      result = true;
    }
  });
  return result;
};
