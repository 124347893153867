import { PostSupportProcedureDetailValidationErrorResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";

/** バリデーションエラーのthrowなのか判定 */
export const checkErrorIsValidation = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  targetError: any
): targetError is PostSupportProcedureDetailValidationErrorResponse => {
  return (
    targetError.response &&
    targetError.response.code === 17 &&
    targetError.response.validation
  );
};

/** Post実施後のフォームに使用するエラーメッセージ作成 */
export const createPostValidationError = (
  err: PostSupportProcedureDetailValidationErrorResponse
): {
  supportProcedureFormPractitioners1: {
    startTime?: string;
    endTime?: string;
  };
  supportProcedureFormPractitioners2: {
    startTime?: string;
    endTime?: string;
  };
} => {
  const error: ReturnType<typeof createPostValidationError> = {
    supportProcedureFormPractitioners1: {},
    supportProcedureFormPractitioners2: {}
  };
  const startTimeErrorMessage =
    "サービス提供記録で設定されている空き時間が開始時間の範囲を超えています。サービス提供記録をご確認ください。";
  const endTimeErrorMessage =
    "サービス提供記録で設定されている空き時間が終了時間の範囲を超えています。サービス提供記録をご確認ください。";
  if (
    err.response.validation.start_time_1 &&
    err.response.validation.start_time_1.length
  ) {
    error.supportProcedureFormPractitioners1.startTime = startTimeErrorMessage;
  }
  if (
    err.response.validation.end_time_1 &&
    err.response.validation.end_time_1.length
  ) {
    error.supportProcedureFormPractitioners1.endTime = endTimeErrorMessage;
  }
  if (
    err.response.validation.start_time_2 &&
    err.response.validation.start_time_2.length
  ) {
    error.supportProcedureFormPractitioners2.startTime = startTimeErrorMessage;
  }
  if (
    err.response.validation.end_time_2 &&
    err.response.validation.end_time_2.length
  ) {
    error.supportProcedureFormPractitioners2.endTime = endTimeErrorMessage;
  }
  return error;
};
