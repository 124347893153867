import { BasicValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/facility/basic";
import { SubtractionItemValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/facility/additionItem";
import { FacilityState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/facility/types";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues;

const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      numberOfUsers: state ? state.numberOfUsers : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : ""
    },
    subtractionItem: {
      rateGetJob: state ? state.rateGetJob : "1",
      lackFlag: state
        ? state.lackOfLifeSupportMemberFlag || state.lackOfResponsiblePersonFlag
        : false,
      lackOfLifeSupportMemberFlag: state
        ? state.lackOfLifeSupportMemberFlag
        : false,
      lackOfLifeSupportMemberStartDate:
        state && state.lackOfLifeSupportMemberStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfLifeSupportMemberStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }, // APIの仕様上dayは1固定
      lackOfResponsiblePersonFlag: state
        ? state.lackOfResponsiblePersonFlag
        : false,
      lackOfResponsiblePersonStartDate:
        state && state.lackOfResponsiblePersonStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfResponsiblePersonStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" } // APIの仕様上dayは1固定
    },
    additionalItem: {
      workHardenesResultFlag: state ? state.workHardenesResultFlag : false,
      workPlaceAdaptationAssistantFlag: state
        ? state.workPlaceAdaptationAssistantFlag
        : false
    }
  };
};

export default initialValues;
