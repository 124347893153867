import {
  FacilityType,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME,
  KEIKAKUSODAN_ASSESSMENT_DEFAULT_ITEM,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE_NAME,
  CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE_NAME,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE,
  CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM
} from "@constants/variables";
/*
 * 事業所によって切り替わるパラメータを取得する共通処理
 */

/** カテゴリ名を取得 */
export const getCategoryName = (
  targetCategoryType: number,
  targetFacilityType: FacilityType
): string => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN:
      return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME[targetCategoryType];
    case FacilityType.CHIIKIIKO:
      return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE_NAME[targetCategoryType];
    case FacilityType.CHIIKITEICHAKU:
      return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE_NAME[targetCategoryType];
    default:
      return "";
  }
};

/** 本人の状況カテゴリか判定 */
export const checkCategoryIsSituation = (
  targetFacilityType: FacilityType,
  categoryType: number
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN:
      return categoryType === KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation;
    case FacilityType.CHIIKIIKO:
      return categoryType === CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation;
    case FacilityType.CHIIKITEICHAKU:
      return categoryType === CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation;
    default:
      return false;
  }
};

/** 生活歴のitemか判定 */
export const checkItemIsLifeHistories = (
  facilityType: FacilityType,
  defaultItem: number | null
): boolean => {
  switch (facilityType) {
    case FacilityType.KEIKAKUSODAN:
      return defaultItem === KEIKAKUSODAN_ASSESSMENT_DEFAULT_ITEM.lifeHistory;
    case FacilityType.CHIIKIIKO:
      return defaultItem === CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.lifeHistory;
    case FacilityType.CHIIKITEICHAKU:
      return defaultItem === CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM.lifeHistory;
    default:
      return false;
  }
};

/** 障害・疾病歴のitemか判定 */
export const checkItemIsDisabilityHistories = (
  targetFacilityType: FacilityType,
  defaultItem: number | null
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN:
      return (
        defaultItem === KEIKAKUSODAN_ASSESSMENT_DEFAULT_ITEM.disabilityHistory
      );
    case FacilityType.CHIIKIIKO:
      return (
        defaultItem === CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.disabilityHistory
      );
    case FacilityType.CHIIKITEICHAKU:
      return (
        defaultItem === CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM.disabilityHistory
      );
    default:
      return false;
  }
};

/** １日の流れのitemか判定 */
export const checkItemIsFlowDays = (
  targetFacilityType: FacilityType,
  defaultItem: number | null
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN:
      return defaultItem === KEIKAKUSODAN_ASSESSMENT_DEFAULT_ITEM.flowDays;
    case FacilityType.CHIIKIIKO:
      return defaultItem === CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.flowDays;
    case FacilityType.CHIIKITEICHAKU:
      return defaultItem === CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM.flowDays;
    default:
      return false;
  }
};

/** 地域移行に対する意思・意見のitemか判定 */
export const checkItemIsChiikiikoOpinions = (
  targetFacilityType: FacilityType,
  defaultItem: number | null
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.CHIIKIIKO:
      return (
        defaultItem ===
          CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouSelf ||
        defaultItem ===
          CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouFamily ||
        defaultItem ===
          CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouCarer ||
        defaultItem ===
          CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouOrganization
      );
    default:
      return false;
  }
};

/**
 * facilityTypeに基づいてCategoryTypeを取得する
 * 対象のfacilityに存在しないCategoryTypeの場合は0を返却
 * @param targetFacilityType
 * @param targetCategory
 * @returns
 */
export const findCategoryType = (
  targetFacilityType: FacilityType,
  targetCategory:
    | "basic_information"
    | "summary"
    | "living_situation"
    | "situation"
    | "request"
    | "opinions_on_chiikiikou"
    | "others"
): number => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN: {
      switch (targetCategory) {
        case "summary":
          return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary;
        case "living_situation":
          return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation;
        case "situation":
          return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation;
        case "request":
          return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request;
        case "others":
          return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others;
        default:
          return 0;
      }
    }
    case FacilityType.CHIIKIIKO: {
      switch (targetCategory) {
        case "basic_information":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information;
        case "summary":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.summary;
        case "living_situation":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation;
        case "situation":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation;
        case "opinions_on_chiikiikou":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou;
        case "others":
          return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.others;
        default:
          return 0;
      }
    }
    case FacilityType.CHIIKITEICHAKU: {
      switch (targetCategory) {
        case "basic_information":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information;
        case "summary":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.summary;
        case "living_situation":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation;
        case "situation":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation;
        case "request":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.request;
        case "others":
          return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.others;
        default:
          return 0;
      }
    }
    default:
      return 0;
  }
};

export const findDefaultItem = (
  targetFacilityType: FacilityType,
  defaultItem:
    | "keikakusodanOffice"
    | "keikakusodanSupporter"
    | "opinionsOnChiikiikouSelf"
    | "opinionsOnChiikiikouFamily"
    | "opinionsOnChiikiikouCarer"
    | "opinionsOnChiikiikouOrganization"
): number => {
  switch (targetFacilityType) {
    case FacilityType.CHIIKIIKO: {
      switch (defaultItem) {
        case "keikakusodanOffice":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.keikakusodanOffice;
        case "keikakusodanSupporter":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.keikakusodanSupporter;
        case "opinionsOnChiikiikouSelf":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouSelf;
        case "opinionsOnChiikiikouFamily":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouFamily;
        case "opinionsOnChiikiikouCarer":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouCarer;
        case "opinionsOnChiikiikouOrganization":
          return CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM.opinionsOnChiikiikouOrganization;
        default:
          return 0;
      }
    }
    case FacilityType.CHIIKITEICHAKU: {
      switch (defaultItem) {
        case "keikakusodanOffice":
          return CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM.keikakusodanOfficeName;
        case "keikakusodanSupporter":
          return CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM.keikakusodanCarer;
        default:
          return 0;
      }
    }
    default:
      return 0;
  }
};

/** 選択中のラジオボタンのラベルを取得する */
export const findChooseRadioLabel = (
  items: {
    id: number;
    name: string;
  }[],
  chooseId: number | null | undefined
): string => {
  if (chooseId === null || chooseId === undefined) return "";
  const choosingChoice = items.find((item) => item.id === chooseId);
  return choosingChoice ? choosingChoice.name : "";
};
