import * as React from "react";
import {
  createStyles,
  StyleRules,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import ClassNames from "classnames";

const styles = ({ typography, spacing }: Theme): StyleRules =>
  createStyles({
    title: {
      fontSize: typography.h6.fontSize,
      fontWeight: "bold"
    },
    action: {
      paddingTop: spacing.unit,
      paddingRight: spacing.unit
    },
    deleteAction: {
      color: "#b00020"
    },
    cancelButton: {
      width: 120
    },
    button: {
      width: 120,
      marginRight: 12,
      marginBottom: 8
    }
  });

type OwnProps = {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
  title: string;
  message: string | JSX.Element;
  submitLabel?: string;
  dialogWidth?: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ConfirmDialog = (props: Props): JSX.Element => {
  const {
    classes,
    isOpen,
    message,
    onCancel,
    onDelete,
    submitLabel,
    title,
    dialogWidth
  } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={dialogWidth ? false : "sm"}
    >
      <div style={{ width: dialogWidth }}>
        <DialogTitle className={classes.title} disableTypography>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            onClick={onCancel}
            color="secondary"
            autoFocus
            className={ClassNames(classes.cancelButton, classes.button)}
          >
            キャンセル
          </Button>
          <Button
            onClick={onDelete}
            className={ClassNames(classes.deleteAction, classes.button)}
          >
            {submitLabel || "削除する"}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmDialog);
