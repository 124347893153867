import React from "react";
import * as H from "history";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions, getIn } from "formik";

// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import * as basicInfoActions from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/actions";
import { toEffectiveObject } from "@utils/object";
import isEqual from "lodash-es/isEqual";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { RecordHeader } from "./RecordHeader";
import {
  BasicInfoValues,
  initialValues
} from "@initialize/mgr/KEIKAKUSODAN/record/basicInfo/initialValues";
import { validation } from "@initialize/mgr/KEIKAKUSODAN/record/basicInfo/validation";
import { SnackbarParams } from "@stores/ui/type";
import * as URL from "@constants/url";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { BasicValues } from "@interfaces/mgr/KEIKAKUSODAN/record/basicInfo";
import { Button, Divider, Theme, Typography } from "@material-ui/core";
import * as ClassNames from "classnames";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import { CityParams, CityState } from "@stores/domain/city/type";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import { BasicInfoPrintDialog } from "@components/organisms/mgr/KEIKAKUSODAN/record/BasicInfoPrintDialog";
import {
  DEFAULT_SELECT_VALUE,
  DISABILITY_CLASS_LIST
} from "@constants/variables";
import { BasicInfoSchedule } from "./BasicInfoSchedule";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import {
  GENDER_LIST,
  RESIDENCE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    content: {
      background: "#fff",
      borderRadius: "4px"
    },
    header: {
      backgroundColor: "#fff",
      padding: "60px 0 16px"
    },
    title: {
      lineHeight: 1,
      "& > span": {
        marginLeft: 16
      }
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    form: {
      background: "#fff",
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    postal: {
      "&>div>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>div::before": {
        borderBottomStyle: "dotted"
      },
      "&>div>div>div p": {
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&>div>div>div>input": {
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.87)",
          opacity: 1
        }
      }
    },
    address: {
      display: "flex",
      "&>div": {
        width: "245px"
      },
      "&>div:nth-child(n+2)": {
        marginLeft: "16px"
      }
    },
    age: {
      width: "158px",
      "&>div": {
        width: "100%"
      }
    },
    authorName: {
      width: 240
    },
    residenceOther: {
      marginBottom: spacing.unit * 4,
      marginLeft: "16px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT8: {
      marginTop: spacing.unit
    },
    ML16: {
      marginLeft: "16px"
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  uifId: string;
  supportPlanBasicInfoId: string;
  consultationType: number;
  copyId: string;
  history: H.History;
  facilityType: number | null;
};

type StateProps = {
  user: UsersInFacilityState["user"];
  needsStopHistory: boolean;
  basicInfo: BasicInfoState["basicInfo"];
  staff: StaffState;
  cityListView: CityState[] | null;
};

type DispatchProps = {
  fetchStaffs: () => Promise<void>;
  fetchFacilityCity: (params: CityParams) => Promise<void>;
  fetchBasicInfo: (
    uifId: string,
    supportPlanBasicInfoId: string
  ) => Promise<void>;
  postBasicInfo: (
    uifId: string,
    basicInfo: BasicInfoValues,
    history: H.History,
    isCopy: boolean,
    isNew: boolean
  ) => Promise<void>;
  deleteBasicInfo: (
    uifId: string,
    supportPlanBasicInfoId: string
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  unsetBasicInfo: () => void;
  stopHistory: (flag: boolean) => Promise<void>;
};

type MergeProps = {
  userName: string;
  authorName: string;
  authorizerRole: string;
  staffOptions: FieldItem[];
  staffWithSnap: StaffState;
  cityLabelView: string;
} & StateProps &
  DispatchProps;

type Props = OwnProps & MergeProps & WithStyles<typeof styles>;

/**
 * 申請者の現状（基本情報）
 */
const BasicInfoFormCore = (props: Props): JSX.Element | null => {
  const {
    needsStopHistory,
    userName,
    uifId,
    supportPlanBasicInfoId,
    copyId,
    history,
    facilityType,
    basicInfo,
    user,
    authorName,
    staffOptions,
    cityLabelView,
    consultationType,
    fetchStaffs,
    fetchFacilityCity,
    fetchBasicInfo,
    unsetBasicInfo,
    postBasicInfo,
    deleteBasicInfo,
    classes
  } = props;
  const [isLoading, setLoading] = React.useState(true); // 初回fetch終わったらfalse
  const [isNew] = React.useState(supportPlanBasicInfoId === "new"); // 新規作成
  const [isView] = React.useState(!isNew && !copyId); // 表示
  const [isCopy] = React.useState(!!copyId); // コピー中
  const [isEditing, setEditing] = React.useState(isNew || !isView || isCopy); // 編集ができる状態かどうか
  const [formValues, setFormValues] = React.useState(
    initialValues(facilityType, basicInfo, user, staffOptions, isCopy, isNew)
  );
  const [isOpenPrintDialog, setIsOpenPrintDialog] = React.useState(false);

  const [
    isOpenDeleteBasicInfoModal,
    setOpenDeleteBasicInfoModal
  ] = React.useState(false);

  //  初回fetch
  React.useEffect(() => {
    unsetBasicInfo();
    setFormValues(
      initialValues(
        facilityType,
        basicInfo,
        user,
        staffOptions,
        isCopy,
        isNew,
        consultationType
      )
    );
    const fetchFirstData = async (): Promise<void> => {
      if (isView || isCopy) {
        await fetchBasicInfo(uifId, supportPlanBasicInfoId || copyId);
      }
      await fetchStaffs();
    };
    fetchFirstData().then(() => {
      setLoading(false);
    });
  }, []);

  // fetch終了後
  React.useEffect(() => {
    setFormValues(
      initialValues(
        facilityType,
        basicInfo,
        user,
        staffOptions,
        isCopy,
        isNew,
        consultationType
      )
    );
  }, [basicInfo, user]);

  // 事業所市区町村取得
  React.useEffect(() => {
    if (
      isNew &&
      user.user_in_facility.prefecture_name !== undefined &&
      user.user_in_facility.prefecture_name !== "" &&
      user.user_in_facility.prefecture_name !== DEFAULT_SELECT_VALUE
    ) {
      fetchFacilityCity({
        prefectureName: user.user_in_facility.prefecture_name
      });
    } else if (
      basicInfo.basic_info.prefecture_name !== null &&
      basicInfo.basic_info.prefecture_name !== "" &&
      basicInfo.basic_info.prefecture_name !== DEFAULT_SELECT_VALUE
    ) {
      fetchFacilityCity({
        prefectureName: basicInfo.basic_info.prefecture_name
      });
    }
  }, [basicInfo, user]);

  const onSubmit = async (
    values: BasicInfoValues,
    actions: FormikActions<BasicInfoValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postBasicInfo(uifId, values, history, isCopy, isNew);
    if (isView) {
      await setFormValues(values);
      await fetchBasicInfo(uifId, supportPlanBasicInfoId);
      setEditing(false);
    }
    actions.setSubmitting(false);
  };

  const onClickEdit = (): void => {
    setEditing(true);
  };

  const onClickDelete = (): void => {
    setOpenDeleteBasicInfoModal(true);
  };

  const onSubmitDelete = async (): Promise<void> => {
    await deleteBasicInfo(uifId, supportPlanBasicInfoId);
    props.stopHistory(false);
    history.push(`/record/support_plan/${uifId}/basic_info`);
  };

  const onClickCancelDelete = (): void => {
    setOpenDeleteBasicInfoModal(false);
  };

  const onClickPrintButton = (): void => {
    setIsOpenPrintDialog(true);
  };

  const onClickPrintDialogCancel = (): void => {
    setIsOpenPrintDialog(false);
  };

  const onClickPrintDialogSubmit: React.ComponentProps<
    typeof BasicInfoPrintDialog
  >["onClickSubmit"] = (type) => {
    history.push(
      `/record/support_plan/${uifId}/basic_info/${supportPlanBasicInfoId}/${type}/print`
    );
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // formik handler
  const confirmDiscardFormChanges = (nextValues: BasicValues): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  // バリデーション
  const validate = (values: BasicValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const currentResidence = getIn(formikProps.values, "residence");

            const onClickCancel = async (): Promise<void> => {
              await props.stopHistory(false).then(() => {
                if (isNew || isCopy) {
                  history.push(
                    `${URL.RECORD_SUPPORT_PLAN}/${uifId}/basic_info`
                  );
                }
                if (isView && isEditing) {
                  formikProps.resetForm();
                  setEditing(false);
                }
              });
            };
            return (
              <Form>
                <RecordHeader
                  pageName="申請者の状況"
                  userName={userName}
                  uifId={uifId}
                  recordType="basic_info"
                  isEditing={isEditing}
                  history={props.history}
                  button={
                    <div className={classes.titleBtn}>
                      {isEditing ? (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="outline"
                              onClick={onClickCancel}
                            >
                              キャンセル
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <FormikSubmitButton
                              key="submit-button"
                              buttonName="保存する"
                              formikProps={formikProps}
                              errorAction={submitError}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="default"
                              onClick={onClickPrintButton}
                            >
                              印刷
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickEdit}>
                              編集
                            </KnowbeButton>
                          </div>
                        </>
                      )}
                    </div>
                  }
                />
                <div className={classes.form}>
                  {!isNew && !isCopy && (
                    <div className={classes.conCreateDate}>
                      <CreateAndUpdateDate
                        createdAt={formValues.created_at}
                        updatedAt={formValues.updated_at}
                      />
                    </div>
                  )}
                  <RecordSelectDate
                    name="creation_date"
                    label="作成日"
                    required
                    value={formValues.creation_date}
                    isEditable={isEditing}
                    addYearTo={1}
                    overrideYearFrom={1989}
                  />

                  <div className={classes.MT32}>
                    <div className={classes.authorName}>
                      <RecordSelect
                        name="author"
                        label="計画作成担当者"
                        defaultValue="-"
                        placeholder="選択してください"
                        options={staffOptions}
                        emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                        value={authorName}
                        isEditable={isEditing}
                        isSelectablePlaceholder
                      />
                    </div>
                  </div>
                  <Typography
                    variant="subtitle1"
                    className={ClassNames(classes.titleBg, classes.MT48)}
                  >
                    1.概要
                  </Typography>
                  <div className={classes.MT32}>
                    <RecordTextField
                      name="support_progress"
                      label="支援経過・現状と課題等"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.support_progress}
                      isEditable={isEditing}
                    />
                  </div>
                  <Typography
                    variant="subtitle1"
                    className={ClassNames(classes.titleBg, classes.MT48)}
                  >
                    2.利用者の状況
                  </Typography>
                  <div className={classes.MT32}>
                    <RecordSelectDate
                      name="date_birth"
                      label="生年月日"
                      overrideYearFrom={1926}
                      value={formValues.date_birth}
                      isEditable={isEditing}
                    />
                  </div>
                  <div className={ClassNames(classes.MT32)}>
                    {isEditing ? (
                      <FormikTextField
                        name="age"
                        type="number"
                        label="年齢"
                        endAdornmentLabel="歳"
                      />
                    ) : (
                      <div className={classes.age}>
                        <ReadonlyTextField
                          label="年齢"
                          defaultValue=""
                          endAdornment="歳"
                          value={formValues.age}
                        />
                      </div>
                    )}
                  </div>
                  <Typography className={classes.MT24}>住所</Typography>
                  <div className={ClassNames(classes.MT8, classes.ML16)}>
                    {isEditing ? (
                      <FormikPostalCode
                        name="postal_code"
                        label="郵便番号"
                        maxLength={8}
                        startAdornmentLabel="〒"
                        style={{ maxWidth: "158px" }}
                      />
                    ) : (
                      <div className={classes.postal}>
                        <FormikTextField
                          name="postal_code"
                          label="郵便番号"
                          startAdornmentLabel="〒"
                          style={{ maxWidth: "158px" }}
                        />
                      </div>
                    )}
                    {isEditing ? (
                      <div className={classes.addressEdit}>
                        <FormikAddress
                          prefectureIdName="prefecture_name"
                          cityIdName="city_id"
                          formikProps={formikProps}
                          showRegionType={false}
                          showCityCode={false}
                          prefectureName={formValues.prefecture_name}
                        />
                      </div>
                    ) : (
                      <div className={classes.address}>
                        <ReadonlyTextField
                          label="都道府県"
                          defaultValue=""
                          value={formValues.prefecture_name}
                        />
                        <ReadonlyTextField
                          label="市区町村"
                          defaultValue=""
                          value={cityLabelView}
                        />
                      </div>
                    )}

                    <div className={!isEditing ? classes.MT32 : ""}>
                      <RecordTextField
                        name="address"
                        label="市区町村以降の住所"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.address}
                        isEditable={isEditing}
                        style={{ maxWidth: "766px" }}
                        nonMultiline
                      />
                    </div>
                  </div>
                  <div>
                    <div className={classes.MT32}>
                      {isEditing ? (
                        <FormikRadioButtons
                          name="residence"
                          label="住まい"
                          style={{ marginBottom: 0 }}
                          options={RESIDENCE_LIST}
                        />
                      ) : (
                        <div className={classes.radio}>
                          <MuiRadioButtons
                            name="residence"
                            label="住まい"
                            options={RESIDENCE_LIST}
                            value={formValues.residence}
                          />
                        </div>
                      )}
                    </div>
                    {currentResidence === "6" && (
                      <div
                        className={ClassNames(
                          classes.residenceOther,
                          classes.MT16
                        )}
                      >
                        <RecordTextField
                          name="residence_other"
                          defaultValue=""
                          placeholder=""
                          value={formValues.residence_other}
                          isEditable={isEditing}
                          maxLength={100}
                        />
                      </div>
                    )}
                  </div>
                  <div className={ClassNames(classes.MT32)}>
                    {isEditing ? (
                      <FormikTextField
                        name="tel"
                        type="tel"
                        label="電話番号"
                        placeholder="0000000000"
                        maxLength={12}
                      />
                    ) : (
                      <ReadonlyTextField
                        label="電話番号"
                        defaultValue=""
                        value={formValues.tel}
                      />
                    )}
                  </div>

                  <div className={ClassNames(classes.MT32)}>
                    {isEditing ? (
                      <FormikTextField
                        name="fax"
                        type="tel"
                        label="FAX番号"
                        placeholder="0000000000"
                        maxLength={12}
                      />
                    ) : (
                      <ReadonlyTextField
                        label="FAX番号"
                        defaultValue=""
                        value={formValues.fax}
                      />
                    )}
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="disability_name"
                      label="障害または疾患名"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.disability_name}
                      isEditable={isEditing}
                      maxLength={100}
                    />
                  </div>

                  <div className={classes.MT32}>
                    {isEditing ? (
                      <FormikRadioButtons
                        name="classify_disability_support"
                        label="障害支援区分"
                        style={{ marginBottom: 0 }}
                        options={DISABILITY_CLASS_LIST}
                      />
                    ) : (
                      <div className={classes.radio}>
                        <MuiRadioButtons
                          name="read_only_classify_disability_support"
                          label="障害支援区分"
                          options={DISABILITY_CLASS_LIST}
                          value={formValues.classify_disability_support}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.MT32}>
                    {isEditing ? (
                      <FormikRadioButtons
                        name="gender"
                        label="性別"
                        style={{ marginBottom: 0 }}
                        options={GENDER_LIST}
                      />
                    ) : (
                      <div className={classes.radio}>
                        <MuiRadioButtons
                          name="gender"
                          label="性別"
                          options={GENDER_LIST}
                          value={formValues.gender}
                        />
                      </div>
                    )}
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="family_structure"
                      label="家族構成"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.family_structure}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="social_relationship"
                      label="社会関係図"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.social_relationship}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="life_history"
                      label="生活歴"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.life_history}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="medical_situation"
                      label="医療の状況"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.medical_situation}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="self_complaint"
                      label="本人の主訴"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.self_complaint}
                      isEditable={isEditing}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="family_complaint"
                      label="家族の主訴"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.family_complaint}
                      isEditable={isEditing}
                    />
                  </div>

                  <Typography
                    variant="subtitle1"
                    className={ClassNames(classes.titleBg, classes.MT48)}
                  >
                    3.支援の状況
                  </Typography>

                  <Typography className={classes.MT32}>
                    公的支援（障害福祉サービス、介護保険等）
                  </Typography>
                  <div className={classes.ML16}>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="public_support.name"
                        label="名称"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.public_support.name}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="public_support.offer_name"
                        label="提供機関・提供者"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.public_support.offer_name}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="public_support.support_contents"
                        label="支援内容"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.public_support.support_contents}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="public_support.frequency"
                        label="頻度"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.public_support.frequency}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="public_support.memo"
                        label="備考"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.public_support.memo}
                        isEditable={isEditing}
                      />
                    </div>
                  </div>

                  <Typography className={classes.MT32}>その他の支援</Typography>
                  <div className={classes.ML16}>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="other_support.name"
                        label="名称"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.other_support.name}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="other_support.offer_name"
                        label="提供機関・提供者"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.other_support.offer_name}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="other_support.support_contents"
                        label="支援内容"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.other_support.support_contents}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="other_support.frequency"
                        label="頻度"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.other_support.frequency}
                        isEditable={isEditing}
                      />
                    </div>
                    <div className={classes.MT32}>
                      <RecordTextField
                        name="other_support.memo"
                        label="備考"
                        labelType="default"
                        defaultValue=""
                        placeholder=""
                        value={formValues.other_support.memo}
                        isEditable={isEditing}
                      />
                    </div>
                  </div>

                  <div className={props.classes.header}>
                    <Typography
                      variant="h6"
                      color="primary"
                      className={props.classes.title}
                    >
                      現在の生活<span>{userName}</span>
                    </Typography>
                  </div>
                  <Divider variant="middle" className={props.classes.divider} />

                  <div className={classes.MT32}>
                    <BasicInfoSchedule
                      isEditing={isEditing}
                      formikProps={formikProps}
                    />
                  </div>

                  <div className={classes.MT32}>
                    <RecordTextField
                      name="main_activity"
                      label="主な日常生活上の活動"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.main_activity}
                      isEditable={isEditing}
                    />
                  </div>
                  <div className={classes.MT32}>
                    <RecordTextField
                      name="non_weekly_service"
                      label="週単位以外のサービス"
                      labelType="default"
                      defaultValue=""
                      placeholder=""
                      value={formValues.non_weekly_service}
                      isEditable={isEditing}
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.MT32}>
        {isView && isEditing && (
          <DeleteButton
            text="申請者の現状(基本情報)を削除する"
            onClick={onClickDelete}
          />
        )}
      </div>
      <MessageDialog
        isOpen={isOpenDeleteBasicInfoModal}
        title="申請者の現状(基本情報)を削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDelete}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteSubmitButton}
            onClick={onSubmitDelete}
          >
            削除する
          </Button>
        }
      />
      <BasicInfoPrintDialog
        isOpen={isOpenPrintDialog}
        onClickCancel={onClickPrintDialogCancel}
        onClickSubmit={onClickPrintDialogSubmit}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.KEIKAKUSODAN.userInFacility.user,
  needsStopHistory: state.ui.needsStopHistory,
  basicInfo: state.KEIKAKUSODAN.basicInfo.basicInfo,
  staff: state.staff,
  cityListView: state.city
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    staffDispatcher,
    KEIKAKUSODAN,
    uiDispatch,
    cityDispatch
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const basicInfoDispatcher = KEIKAKUSODAN.basicInfoDispatcher(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchFacilityCity: (params: CityParams): Promise<void> =>
      cityDispatch(dispatch).fetch(params),
    fetchBasicInfo: (
      uifId: string,
      supportPlanBasicInfoId: string
    ): Promise<void> => {
      return basicInfoDispatcher.fetchBasicInfo(uifId, supportPlanBasicInfoId);
    },
    postBasicInfo: (
      uifId: string,
      basicInfo: BasicInfoValues,
      history: H.History,
      isCopy: boolean,
      isNew: boolean
    ): Promise<void> => {
      return basicInfoDispatcher.postBasicInfo(
        uifId,
        basicInfo,
        history,
        isCopy,
        isNew
      );
    },
    deleteBasicInfo: (
      uifId: string,
      supportPlanBasicInfoId: string
    ): Promise<void> => {
      return basicInfoDispatcher.deleteBasicInfo(uifId, supportPlanBasicInfoId);
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    unsetBasicInfo: (): void => {
      dispatch(basicInfoActions.unsetBasicInfo());
    },
    stopHistory: uiDispatches.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, basicInfo, cityListView, user } = stateProps;

  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;

  const author =
    basicInfo.basic_info.author !== null ? basicInfo.basic_info.author : 0;

  // 作成者名
  const authorName = getSnapOrRealName(author, "-");
  // 役職
  const authorizerRole = getSnapOrRealRole(author, "-");

  // 記録者フィールドに渡すoptions
  let staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  // 作成者選択肢作成（スナップショット）
  if (basicInfo.basic_info.author !== null) {
    staffOptions = createSnapshotOptions(
      staffOptions,
      basicInfo.basic_info.author
    );
  }
  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptions.length > staff.staffItems.length) {
    const snapOption = staffOptions.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorName,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  const id =
    ownProps.supportPlanBasicInfoId === "new"
      ? user.user_in_facility.city_id
      : basicInfo.basic_info.city_id;
  let cityLabelViewData = "";
  if (cityListView !== null) {
    Object.keys(cityListView).forEach((key) => {
      if (cityListView[key].value === id) {
        cityLabelViewData = cityListView[key].label;
      }
    });
  }

  return {
    userName,
    authorName,
    authorizerRole,
    staffOptions,
    staffWithSnap,
    cityLabelView: cityLabelViewData,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const BasicInfoForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(BasicInfoFormCore)
);
