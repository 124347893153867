import * as React from "react";

import {
  createStyles,
  StyleRules,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { green, red, lightBlue } from "@material-ui/core/colors";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    success: {
      backgroundColor: green[800]
    },
    error: {
      backgroundColor: red[700]
    },
    info: {
      backgroundColor: lightBlue[700]
    },
    warning: {
      backgroundColor: "#ed6c02"
    },
    iconVariant: {
      opacity: 0.9,
      marginRight: spacing.unit,
      fontSize: 14
    },
    message: {
      display: "flex",
      alignItems: "center",
      fontSize: 14
    },
    anchorOriginBottomRight: {
      left: "auto",
      right: 16,
      bottom: 16,
      width: "calc(100% - 32px)"
    },
    root: {
      maxWidth: "100%",
      minWidth: "100%",
      borderRadius: 4,
      padding: "6px 16px"
    }
  });

type OwnProps = {
  message: string;
  open: boolean;
  onClose: () => void;
  autoHideDuration?: number;
  variant: "success" | "warning" | "error" | "info";
};

type Props = OwnProps & WithStyles<typeof styles>;

const MySnackbarCore = ({
  classes,
  message,
  onClose,
  open,
  autoHideDuration = 10000,
  variant = "info"
}: Props): JSX.Element => {
  const Icon = variantIcon[variant];
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      classes={{
        anchorOriginBottomRight: classes.anchorOriginBottomRight
      }}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="ui-snackbar"
        message={
          <span id="ui-snackbar" className={classes.message}>
            <Icon className={classes.iconVariant} />
            {message}
          </span>
        }
        classes={{
          root: classes.root
        }}
      />
    </Snackbar>
  );
};

export const MySnackbar = withStyles(styles)(MySnackbarCore);
