/**
 * 明細テーブル
 */
import * as React from "react";
import { FormikProps, FieldArray } from "formik";
import { inoutResultsDetailsInitialValue } from "@initialize/mgr/KODOENGO/report/initialValues";
import { InitialValues } from "@interfaces/mgr/KODOENGO/report/initial";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import { UnitsTable } from "@components/organisms/mgr/KODOENGO/report/dialog/UnitsTable";

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  unitKey: string;
  children?: React.ReactNode;
  onAddRecord: () => void;
  onDeleteRecord: (index: number) => void;
  onChangeTime: (index: number, start: string, end: string) => void;
  isAddButton: boolean;
  list: { time: number; inTime: string; outTime: string }[];
};

export const UnitsFields = (props: OwnProps): JSX.Element => {
  return (
    <FieldArray name={`initial.${props.unitKey}`}>
      {(arrayHelpers): JSX.Element => {
        const onClickAddUnit = (): void => {
          arrayHelpers.push(inoutResultsDetailsInitialValue);
          props.onAddRecord();
        };
        const onClickDelete = (index: number): void => {
          arrayHelpers.remove(index);
          props.onDeleteRecord(index);
        };
        return (
          <div>
            <div>
              {props.list.length > 0 && (
                <UnitsTable
                  formikProps={props.formikProps}
                  unitKey={props.unitKey}
                  onClickDelete={onClickDelete}
                  onChangeTime={props.onChangeTime}
                />
              )}
              <Button
                color="secondary"
                onClick={onClickAddUnit}
                disabled={!props.isAddButton}
              >
                <IconAdd />
                空き時間を追加する
              </Button>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};
