import {
  NO_SELECTED_CONDITION_TYPE_2024,
  SELECTED_CONDITION_TYPE_2024_V,
  SpecificBetterSupporterConditionSubTypes2024Range
} from "@constants/variables";

// specificBetterSupporterCondition202404の値によってラジオボタンのvalueを返す関数"
/**
 * @param value specificBetterSupporterCondition202404の値
 */
export const initializeSpecificBetterSupporterCondition202404V = (
  value: string | null
): string => {
  // valueがfalsyな値の場合は初期値"1"を返す
  if (!value) {
    return `${NO_SELECTED_CONDITION_TYPE_2024}`;
  }
  // 子のラジオボタンが選択されている値の場合、親のラジオボタン点灯用の値"99"を返す
  if (SpecificBetterSupporterConditionSubTypes2024Range.includes(value)) {
    return `${SELECTED_CONDITION_TYPE_2024_V}`;
  }
  // 上記以外の場合、値をそのまま返す
  return value;
};
