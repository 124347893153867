import {
  TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST,
  TRAINING_BY_CHIEF_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: "",
      numberOfEmployees: 0,
      numberOfHandicappedChild: 0,
      trainingByChief202404: TRAINING_BY_CHIEF_TYPE_LIST[0].value,
      numberOfKeikakusodan: 0,
      trainingByChiefShogaiji202404:
        TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST[0].value
    },
    inoutConsultationResults: [],
    summary: {
      serviceUseCount: 0,
      continuousServiceUseCount: 0,
      nextMonthUse2LimitCount: 0,
      monitoringCount: 0
    }
  },
  reportMonthly: {
    summary: {
      inhomeNursingCareSupportOfficeRelation1Count: 0,
      inhomeNursingCareSupportOfficeRelation2Count: 0,
      inhomeNursingCareSupportOfficeRelation3Count: 0,
      inhomeNursingCareSupportOfficeRelation4Count: 0,
      inhomeNursingCareSupportOfficeRelation5Count: 0,
      inhomeNursingCareSupportOfficeRelation6Count: 0,
      inhomeNursingCareSupportOfficeRelationLimit: 0,
      lifeSupportPromotionCount: 0,
      dischargeAdditionCount: 0,
      childcareTransitionSupport1Count: 0,
      childcareTransitionSupport2Count: 0,
      childcareTransitionSupport3Count: 0
    },
    inoutConsultationResults: []
  }
};

export const KEIKAKUSODANReport = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_KEIKAKUSODAN_DAILY_STARTED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_DAILY:
      return {
        ...state,
        reportDaily: action.payload
      };
    case types.FETCH_KEIKAKUSODAN_DAILY_FAILED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_MONTHLY:
      return {
        ...state,
        reportMonthly: action.payload
      };
    case types.FETCH_KEIKAKUSODAN_MONTHLY_FAILED:
      return { ...state };
    case types.POST_KEIKAKUSODAN_FACILITY_MONTHLY_STARTED:
      return { ...state };
    case types.POST_KEIKAKUSODAN_FACILITY_MONTHLY:
      return {
        ...state,
        reportDaily: {
          ...state.reportDaily,
          inoutResultDaily: action.payload
        }
      };
    case types.POST_KEIKAKUSODAN_FACILITY_MONTHLY_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};
