import * as React from "react";
import { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import WelfareSpecialistPlacementTypeFields from "@components/organisms/mgr/SEIKATSUKAIGO/facility/WelfareSpecialistPlacementTypeFields";
import {
  StaffTreatmentSystemTypes,
  StaffTreatmentSpecificSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DEFAULT_SELECT_DATE_VALUE,
  DEFAULT_RADIO_VALUE,
  DISABLED_RADIO_ITEMS_VALUE,
  SpecificBetterSupporterConditionSubTypes2024,
  SpecificBetterSupporterConditionTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";

import {
  StaffPlacementTypes202404,
  SERIOUS_DISABILITY_TYPE_LIST,
  ENABLE_FACILITY_TYPE
} from "@constants/mgr/SEIKATSUKAIGO/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import FormikTextField from "@components/molecules/FormikTextField";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";
import { PlacementOfServiceAdminFields } from "@components/organisms/mgr/common/facility/items/PlacementOfServiceAdminFields";
import SeeHearTeam202404Fields from "@components/organisms/mgr/common/facility/items/SeeHeareTeamFields";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);

const StaffPlacementTypesRadioItems = generateRadioItems(
  StaffPlacementTypes202404
);

const staffTreatmentSpecificSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSpecificSystemTypes
);

const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionTypes2024
);

const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionSubTypes2024
);

const SpecificBetterSupporterConditionType2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

const SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

type Props = {
  formikProps: FormikProps<FacilityValues>;
  facilityType: number;
};
const AdditionalItemFields = (props: Props): JSX.Element => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = useState(false);
  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );
  useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
  }, [staffTreatmentSystemType]);

  // 就労移行支援体制加算 Switch ON/OFF
  const employmentTransitionSupportFlag: string = getIn(
    props.formikProps.values,
    "additionalItem.employmentTransitionSupportFlag"
  );
  useEffect(() => {
    if (!employmentTransitionSupportFlag) {
      props.formikProps.setFieldValue(
        "additionalItem.numberOfContinuations",
        ""
      );
    }
  }, [employmentTransitionSupportFlag]);

  // 常勤看護職員等配置加算 Switch ON/OFF
  const nursingSupporterFlg: string = getIn(
    props.formikProps.values,
    "additionalItem.nursingSupporterFlg"
  );
  useEffect(() => {
    if (!nursingSupporterFlg) {
      props.formikProps.setFieldValue("additionalItem.nursingSupporter", "");
    }
  }, [nursingSupporterFlg]);

  // 高次脳機能障害者支援体制加算：デフォルト値に戻す
  const onChangeHigherBrainDysfunction = (): void => {
    const { setFieldValue } = props.formikProps;
    if (
      getIn(
        props.formikProps.values,
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg"
      )
    ) {
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        false
      );
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFrom",
        DEFAULT_SELECT_DATE_VALUE
      );
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportTo",
        DEFAULT_SELECT_DATE_VALUE
      );
    } else {
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        true
      );
    }
  };
  useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.formikProps.setFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.formikProps.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  const isSelectedConditionType2024 =
    props.formikProps.values.additionalItem
      .specificBetterSupporterCondition202404 ===
    `${SELECTED_CONDITION_TYPE_2024_V}`;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (!isSelectedConditionType2024) {
      props.formikProps.setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        {/* サービス管理責任者配置等加算 */}
        {ENABLE_FACILITY_TYPE.includes(props.facilityType) ? (
          <PlacementOfServiceAdminFields formikProps={props.formikProps} />
        ) : null}
        <FormikRadioButtons
          name="additionalItem.staffPlacementType"
          label="人員配置体制加算"
          options={StaffPlacementTypesRadioItems}
        />

        {/* 福祉専門職員配置等加算 */}
        <WelfareSpecialistPlacementTypeFields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="welfareSpecialistPlacementType2" />}
            />
          }
        />
        <FormikSwitch
          name="additionalItem.nursingSupporterFlg"
          label="常勤看護職員等配置加算"
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="fullTimeNursePlacementType_202404" />
              }
            />
          }
        >
          <FormGroup row style={{ marginBottom: 0 }}>
            <FormikTextField
              name="additionalItem.nursingSupporter"
              label="看護職員人数"
              endAdornmentLabel="人"
              placeholder="0"
              maxLength={3}
              style={{ width: 280, marginBottom: 12 }}
            />
          </FormGroup>
        </FormikSwitch>
        <FormGroup style={{ marginBottom: 28 }}>
          <FormGroup>
            <FormikRadioButtons
              name="additionalItem.specificBetterSupporterCondition202404"
              style={{ marginBottom: 0 }}
              label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
              options={
                props.formikProps.values.basic.commuterLifeSupportFlag
                  ? SpecificBetterSupporterConditionType2024RadioItemsDisabled
                  : SpecificBetterSupporterConditionType2024RadioItems
              }
              onClick={onChangeSpecificBetterSupporterConditionType2024}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                  }
                />
              }
            />
            {isSelectedConditionType2024 && (
              <FormikRadioButtons
                style={{ marginLeft: 48, marginTop: -2 }}
                name="additionalItem.specificBetterSupporterCondition202404Sub"
                label=""
                options={
                  props.formikProps.values.basic.commuterLifeSupportFlag
                    ? SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled
                    : SpecificBetterSupporterConditionTypeSub2024RadioItems
                }
              />
            )}
          </FormGroup>
        </FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.seriousDisability"
          label="重度障害者支援加算"
          options={SERIOUS_DISABILITY_TYPE_LIST}
        />
        {/* 視覚・聴覚言語障害者支援体制加算 */}
        <SeeHearTeam202404Fields formikProps={props.formikProps} />
      </FormGroup>
      <FormikSwitch
        name="additionalItem.higherBrainDysfunctionPersonsSupportFlg"
        label="高次脳機能障害者支援体制加算"
        onChange={onChangeHigherBrainDysfunction}
      >
        <FormikSelectDateNotSelectedDefault
          name="additionalItem.higherBrainDysfunctionPersonsSupportFrom"
          label="開始年月日"
          addYearTo={1}
          overrideYearFrom={2024}
          setFormikFieldValue={props.formikProps.setFieldValue}
          monthOptionsStartFrom={4}
        />
        <FormikSelectDateNotSelectedDefault
          name="additionalItem.higherBrainDysfunctionPersonsSupportTo"
          label="終了年月日（任意）"
          addYearTo={5}
          overrideYearFrom={2024}
          setFormikFieldValue={props.formikProps.setFieldValue}
          monthOptionsStartFrom={4}
        />
      </FormikSwitch>
      <FormikSwitch
        name="additionalItem.employmentTransitionSupportFlag"
        label="就労移行支援体制加算"
        style={{ marginBottom: 0 }}
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="employmentTransitionSupportType" />}
          />
        }
      >
        <FormGroup row style={{ marginBottom: 0 }}>
          <FormikTextField
            name="additionalItem.numberOfContinuations"
            label="前年度実績の6ヶ月以上就労継続者数"
            endAdornmentLabel="名"
            placeholder="0"
            maxLength={3}
            style={{ width: 280 }}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};

export default AdditionalItemFields;
