import * as React from "react";
import * as classNames from "classnames";
import { convertWareki } from "@utils/date";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import {
  SummaryByCity,
  SeikyuDetail,
  SeikyuDetailUser
} from "@stores/domain/invoice/type";
import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

const SERVICE_CONTENTS_MAX_ROW = 10;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: PRINT_PAGE_PADDING,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 12,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.space-between": {
        justifyContent: "space-between"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "& td": {
        padding: "3px 7px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 20,
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.middle": {
          height: 200
        },
        "&.ssize": {
          width: 80
        },
        "&.lsize": {
          width: 150
        },
        "&.llsize": {
          width: 250
        },
        "&.vertical": {
          width: 18,
          letterSpacing: 0,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    title: {
      margin: "0 0 10px 0",
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.84)"
    },
    date: {
      fontSize: 11
    },
    city: {
      fontSize: 14,
      marginTop: 80
    },
    note: {
      fontSize: 14
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type OwnProps = {
  data: SummaryByCity;
  typeConsultation?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 請求書・明細書
 * 計画相談支援給付費請求書
 */
const InvoiceSummaryCityPlanningConsultationCore = (
  props: Props
): JSX.Element => {
  const { data, classes } = props;
  const { bill, date, demand, facility, detail } = data;
  const billDate = convertWareki(date.bill.r01, date.bill.r02);
  // 各ページの最初のデータindexが入った配列
  const indexArray: number[] = [0];
  let dataIndex = 0;
  detail.users.forEach((item, index) => {
    if (dataIndex <= SERVICE_CONTENTS_MAX_ROW - 1) {
      dataIndex += 1;
    } else {
      dataIndex = 0;
      indexArray.push(index);
      dataIndex += 1;
    }
  });

  const isInvoiceConsultationShogaiji =
    Number(props.typeConsultation) === TYPE_CONSULTATION_SHOGAIJISODAN;

  const invoiceTitle = isInvoiceConsultationShogaiji
    ? "障害児相談支援給付費請求書"
    : "計画相談支援給付費請求書";

  return (
    <>
      {indexArray.map((firstNumberOfPage, index) => {
        const uniqueKey = `table-row-${index}`;
        const lastNumberOfPage =
          index + 1 === indexArray.length
            ? detail.users.length - 1
            : indexArray[index + 1] - 1;
        const pageRecords = detail.users.slice(
          firstNumberOfPage,
          lastNumberOfPage + 1
        );
        const subtotal = pageRecords.reduce((prev, current): number => {
          const numberD02 = !Number.isNaN(
            Number(current.summaries[0].d02.replace(/,/g, ""))
          )
            ? Number(current.summaries[0].d02.replace(/,/g, ""))
            : 0;
          return prev + numberD02;
        }, 0);
        return (
          <React.Fragment key={uniqueKey}>
            <section className={classes.page}>
              <header>
                <h1 className={classes.title}>{invoiceTitle}</h1>
              </header>
              <div
                className={classNames(
                  classes.flexContainer,
                  "end",
                  classes.date
                )}
              >
                <span>
                  {
                    convertWareki(date.createdAt.h01, date.createdAt.h02)
                      .warekiYear
                  }
                  {date.createdAt.h02}月{date.createdAt.h03}日
                </span>
              </div>
              <div className={classes.flexContainer}>
                <span>（&emsp;請&emsp;求&emsp;先&emsp;）</span>
              </div>
              <div
                className={classNames(classes.flexContainer, "space-between")}
              >
                <div className={classes.city}>
                  <span>{demand} 殿</span>
                </div>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td
                        rowSpan={5}
                        className={classNames(
                          "vertical",
                          "middle",
                          "borderBold"
                        )}
                      >
                        <span>請求事業者</span>
                      </td>
                      <td className="label">指定事業所番号</td>
                      <td className="llsize">{facility.c01}</td>
                    </tr>
                    <tr>
                      <td className="label">
                        住所
                        <br />
                        （所在地）
                      </td>
                      <td className="llsize">
                        〒{facility.c02}
                        <br />
                        {facility.c03}
                      </td>
                    </tr>
                    <tr>
                      <td className="label">電話番号</td>
                      <td className="llsize">{facility.c04}</td>
                    </tr>
                    <tr>
                      <td className="label">名称</td>
                      <td className="llsize">{facility.c05}</td>
                    </tr>
                    <tr>
                      <td className="label">職・氏名</td>
                      <td className="llsize">{facility.c06}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={classNames(classes.flexContainer, classes.note)}>
                <span>下記のとおり請求します。</span>
              </div>
              <div className={classes.flexContainer}>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>{billDate.era}</td>
                      <td>{billDate.year}</td>
                      <td>年</td>
                      <td>{date.bill.r02}</td>
                      <td>月分</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={classNames(classes.flexContainer, "space-between")}
              >
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td className="label">請求金額</td>
                      <td className="lsize prise">
                        {firstNumberOfPage === 0 ? bill.r03 : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table className={classes.table} style={{ marginLeft: 68 }}>
                  <tbody>
                    <tr>
                      <td className="label borderBold">区分</td>
                      <td className="label ssize borderBold">件数</td>
                      <td className="label" style={{ padding: 0, width: 50 }}>
                        地域区分
                      </td>
                      <td className="lsize label" colSpan={2}>
                        {firstNumberOfPage === 0 ? detail.facility.m10 : ""}
                      </td>
                    </tr>
                    <tr>
                      <td className="label borderBold">
                        {bill.typeServices[0].t01}
                      </td>
                      <td className="label borderBold">
                        {firstNumberOfPage === 0
                          ? bill.typeServices[0].t02
                          : ""}
                      </td>
                      <td className="label" colSpan={2} style={{ width: 100 }}>
                        単位数単価
                      </td>
                      <td className="prise" style={{ width: 100 }}>
                        {firstNumberOfPage === 0
                          ? bill.typeServices[0].t04
                          : ""}
                        円/単位
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={classes.flexContainer}
                style={{ marginBottom: 0 }}
              >
                <UsersContents
                  classes={classes}
                  detail={detail}
                  firstNumberOfPage={firstNumberOfPage}
                  lastNumberOfPage={lastNumberOfPage}
                  isInvoiceConsultationShogaiji={isInvoiceConsultationShogaiji}
                />
              </div>
              <div
                className={classNames(classes.flexContainer, "space-between")}
              >
                <table className={classes.table}>
                  <tbody />
                </table>
                <table className={classes.table} style={{ borderTop: 0 }}>
                  <tbody>
                    <tr>
                      <td className="label ssize">小計</td>
                      <td className="prise lsize">
                        {subtotal.toLocaleString()}円
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={classNames(classes.flexContainer, "space-between")}
              >
                <table className={classes.table}>
                  <tbody />
                </table>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>{indexArray.length}枚中</td>
                      <td>{index + 1}枚目</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </React.Fragment>
        );
      })}
    </>
  );
};

type ServiceContentsProps = WithStyles<typeof styles> & {
  detail: SeikyuDetail;
  firstNumberOfPage: number;
  lastNumberOfPage: number;
  isInvoiceConsultationShogaiji: boolean;
};

/**
 * 利用者詳細
 */
const UsersContents = (props: ServiceContentsProps): JSX.Element => {
  const { detail, classes, firstNumberOfPage, lastNumberOfPage } = props;
  const emptyList = [
    ...Array(
      lastNumberOfPage - firstNumberOfPage + 1 >= SERVICE_CONTENTS_MAX_ROW
        ? 0
        : SERVICE_CONTENTS_MAX_ROW - (lastNumberOfPage - firstNumberOfPage + 1)
    )
  ].fill(undefined);
  const records: SeikyuDetailUser[] = [
    ...detail.users.slice(firstNumberOfPage, lastNumberOfPage + 1),
    ...emptyList
  ];

  const grantDecisionHeaderLabel = props.isInvoiceConsultationShogaiji
    ? "給付決定保護者"
    : "支給決定障害者等";

  return (
    <>
      <table className={`${classes.table}`}>
        <tbody>
          <tr className="borderBold">
            <td className="label" style={{ padding: 0, width: 30 }}>
              項番
            </td>
            <td className="label borderBold" colSpan={4} style={{ width: 400 }}>
              {grantDecisionHeaderLabel}
            </td>
            <td className="label borderBold" colSpan={2} style={{ width: 200 }}>
              請求額計算欄
            </td>
          </tr>
          {records.map((item, index) => {
            const uniqueKey = `table-row-${index}`;
            return (
              <React.Fragment key={uniqueKey}>
                <tr key={`${uniqueKey}-row1`}>
                  <td rowSpan={2}>{firstNumberOfPage + index + 1}</td>
                  <td className="label" style={{ width: 80, padding: 0 }}>
                    受給者証番号
                  </td>
                  <td className="label" style={{ width: 140 }}>
                    {item ? item.m05 : ""}
                  </td>
                  <td className="label" style={{ width: 50, padding: 0 }}>
                    フリガナ
                  </td>
                  <td className="label borderBold" style={{ width: 150 }}>
                    {item ? item.m11 : ""}
                  </td>
                  <td className="label ssize">単位数</td>
                  <td className="label">請求額</td>
                </tr>
                <tr key={`${uniqueKey}-row2`}>
                  <td className="label" style={{ padding: 0 }}>
                    モニタリング日
                  </td>
                  <td className="label">
                    {item && item.m13
                      ? `${
                          convertWareki(
                            item.m13.substr(0, 4),
                            item.m13.substr(4, 2)
                          ).warekiYear
                        }
                     ${item.m13.substr(4, 2)}月${item.m13.substr(6, 2)}日`
                      : ""}
                  </td>
                  <td className="label" style={{ padding: 0 }}>
                    氏名
                  </td>
                  <td className="label borderBold">{item ? item.m06 : ""}</td>
                  <td className="prise">{item ? item.summaries[0].d01 : ""}</td>
                  <td className="prise">
                    {item ? item.summaries[0].d02 : ""}円
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const InvoiceSummaryCityPlanningConsultation = withStyles(styles)(
  InvoiceSummaryCityPlanningConsultationCore
);
