import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import OperationsPrint from "@components/organisms/record/print/OperationsPrint";
import { OperationsPrintCustomRecord } from "@components/organisms/record/print/OperationsPrintCustomRecord";
import { OperationsPrintTANKINYUSHO } from "@components/organisms/record/print/OperationsPrintTANKINYUSHO";
import { CsegOperationsRecordPrint } from "@components/organisms/mgr/Cseg/record/operation/OperationRecordPrint";
import { FacilityType } from "@constants/variables";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

type StateProps = {
  userState: UserState;
};

type Props = StateProps & RouteComponentProps<{ year: string; month: string }>;

// /**
//  * 業務日誌のプリント画面
//  */
const RecordOperationsPrint = (props: Props): JSX.Element => {
  const { year } = props.match.params;
  const { month } = props.match.params;
  const query = props.location.search;

  const RenderPrint = (): JSX.Element => {
    switch (props.userState.facility_type) {
      case FacilityType.GROUP_HOME:
      case FacilityType.SHISETSUNYUSHO:
        return (
          <OperationsPrintCustomRecord
            query={query}
            year={year}
            month={month}
          />
        );
      case FacilityType.TANKINYUSHO:
        return <OperationsPrintTANKINYUSHO year={year} month={month} />;
      case FacilityType.KEIKAKUSODAN:
      case FacilityType.CHIIKITEICHAKU:
      case FacilityType.CHIIKIIKO:
        return <CsegOperationsRecordPrint year={year} month={month} />;
      default:
        return <OperationsPrint year={year} month={month} />;
    }
  };

  return (
    <AdminTemplate pageName="業務日誌">
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const OperationsRecordPrint = connect(mapStateToProps)(
  RecordOperationsPrint
);
