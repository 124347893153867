import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import UsersListTemplate from "@components/templates/UsersListTemplate";

import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import ErrorsDialog from "@components/organisms/ErrorsDialog";

type DispatchProps = {
  fetchUser: () => void;
  fetchUsersInFacility: () => void;
  fetchUsersError: () => void;
};

type State = {
  user: UserState;
  facilityUsers: GetFacilityUsersResponse["data"];
};

type Props = State & DispatchProps; // WithStyles<typeof styles>,

/**
 * 利用者情報
 */
class UsersList extends React.Component<Props> {
  public componentDidMount(): void {
    if (!this.props.user.name) this.props.fetchUser();
    this.props.fetchUsersInFacility();
    this.props.fetchUsersError();
  }

  public render(): JSX.Element {
    return (
      <AdminTemplate pageName="利用者情報">
        <UsersListTemplate
          user={this.props.user}
          facilityUsers={this.props.facilityUsers}
        />
        <ErrorsDialog errorsKey="users" />
      </AdminTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): State => ({
  user: state.user,
  facilityUsers: state.IAB.userInFacility.users
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, IAB, errorsDispatcher } = dispatches;
  const userInFacilityDispatched = IAB.userInFacilityDispatcher(dispatch);

  return {
    fetchUser: userDispatch(dispatch).me,
    fetchUsersInFacility: userInFacilityDispatched.fetch,
    fetchUsersError: errorsDispatcher(dispatch).users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
