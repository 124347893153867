import * as React from "react";

// ui
import * as classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { CUSTOM_RECORD_INPUT_TYPE } from "@constants/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      minHeight: 330,
      maxHeight: "80%"
    },
    dialogTitle: {
      padding: "16px 24px 20px 32px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 58
    },
    dialogContent: {
      width: 776,
      height: "100%",
      minHeight: 148,
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    dialogActions: {
      borderTop: "1px solid #cfd8dc",
      margin: 0,
      padding: 8
    },
    contentSection: {
      padding: "24px 32px 48px",
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.38)"
      }
    },
    formNameWrap: {
      "& > div > div": {
        margin: 0,
        "& > label > span": {
          fontSize: 16
        }
      }
    },
    formNameLabel: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 6px"
    },
    note: {
      fontSize: 12,
      marginBottom: 24,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 16px"
    },
    button: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 24
      }
    },
    cancel: {
      borderColor: "#ccc"
    },
    contentHeader: {
      display: "flex",
      color: "#37474f",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "12px 12px 12px 16px",
      backgroundColor: "#eceff1"
    },
    contentHeaderName: {
      display: "flex",
      alignItems: "center",
      width: 340
    },
    contentHeaderType: {
      display: "flex",
      alignItems: "center",
      width: 240,
      marginLeft: 24
    },
    contentHeaderCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 40
    },
    contentItem: {
      display: "flex",
      borderBottom: "solid 1px #e5e5e5",
      fontSize: 16,
      width: 712,
      height: 64,
      padding: "12px 12px 12px 16px"
    },
    contentItemName: {
      display: "flex",
      alignItems: "center",
      minWidth: 340
    },
    contentItemType: {
      display: "flex",
      alignItems: "center",
      minWidth: 240,
      marginLeft: 24
    },
    contentItemCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 42
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onVisible: (recordIds: number[]) => void;
  HiddenCustomRecordListType: number;
  customRecords: CustomRecordsState;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 記録の項目設定 > 非表示項目リスト
 */
const HiddenItemsModalCore = (props: Props): JSX.Element | null => {
  const { classes } = props;
  const [checkIds, setCheckIds] = React.useState<number[]>([]);

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const id = +event.target.value;
    if (event.target.checked) {
      // 重複削除したIds
      setCheckIds(
        [...checkIds, id].filter((x, i, self) => {
          return self.indexOf(x) === i;
        })
      );
    } else {
      setCheckIds(checkIds.filter((x) => x !== id));
    }
  };

  const handleOnClose = (): void => {
    props.onClose();
    setCheckIds([]);
  };

  const handleOnVisible = async (): Promise<void> => {
    await props.onVisible(checkIds);
    props.onClose();
    setCheckIds([]);
  };

  const hiddenCustomRecords = props.customRecords.filter(
    (record) =>
      record.visibility === 0 &&
      record.setting_type === props.HiddenCustomRecordListType
  );

  return (
    <Dialog
      open={props.isModalOpen}
      disableBackdropClick
      maxWidth={false}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        非表示項目リスト
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentSection}>
          <p className={classes.note}>
            表示のチェックをオンにすると、再び記録画面に表示されるようになります。
          </p>
          <div className={classes.contentHeader}>
            <div className={classes.contentHeaderName}>項目名</div>
            <div className={classes.contentHeaderType}>入力形式</div>
            <div className={classes.contentHeaderCheckBox}>表示</div>
          </div>
          {hiddenCustomRecords.map((record) => {
            return (
              <div
                key={`content-item-${record.id}`}
                className={classes.contentItem}
              >
                <div className={classes.contentItemName}>{record.name}</div>
                <div className={classes.contentItemType}>
                  {CUSTOM_RECORD_INPUT_TYPE[record.input_type]}
                </div>
                <div className={classes.contentItemCheckBox}>
                  <MuiCheckbox
                    id={`item-checkbox-${record.id}`}
                    label=""
                    checked={checkIds.includes(record.id)}
                    value={`${record.id}`}
                    onChange={handleChangeCheckbox}
                    style={{ margin: "0 0 -2px" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton
          className={classNames(classes.button, classes.cancel)}
          color="secondary"
          onClick={handleOnClose}
          kind="outline"
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleOnVisible}
          disabled={!checkIds.length}
        >
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const HiddenItemsModal = withStyles(styles)(HiddenItemsModalCore);
