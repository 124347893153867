import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetUsersSummaryTANKINYUSHOUsers = {
  uif_id: number;
  name: string;
  recipient_number: string;
  counts: {
    status_type: {
      implement: number;
      denominator: number;
    };
    meal: {
      custom_record_item_id: number;
      choices: {
        id: number;
        name: string;
        count: number;
      }[];
    };
  };
  support: {
    support_records_id: number;
    target_date: string;
    status_type: number;
    pickup: number;
    support_record_input: {
      id: number;
      custom_record_item_id: number;
      input_data?: string;
      choiced_item_id?: number;
      choiced_staff_id?: number;
      checked?: number;
      choiced_staff_name_snapshot?: string;
    }[];
  }[];
};

export type GetUsersSummaryTANKINYUSHO = {
  data: {
    users: string[]; // json_encodeされたGetUsersSummaryTANKINYUSHOUsersのgzip配列（負荷対策）
  };
};

/**
 * 指定された利用者分の支援記録のデータを取得する
 * @param year 年
 * @param month 月(MM)
 * @param excludedUserIds 指定された事業所所属ユーザーのID
 */
const getUsersSummaryTANKINYUSHO = async (
  year: string,
  month: string,
  excludedUserIds?: string
): Promise<AxiosResponse<GetUsersSummaryTANKINYUSHO>> => {
  const url = `${VERSION_URL_201910}/supports/users/records/${year}/${month}`;
  const query = excludedUserIds ? `?excluded_user_ids=${excludedUserIds}` : "";
  return request.get<GetUsersSummaryTANKINYUSHO>(url + query);
};

export default getUsersSummaryTANKINYUSHO;
