import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import {
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DISABLED_RADIO_ITEMS_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  SELECTED_CONDITION_TYPE_2024_V,
  SpecificBetterSupporterConditionSubTypes2024,
  SpecificBetterSupporterConditionTypes2024,
  StaffTreatmentSystemTypesSHUROTEICHAKU
} from "@constants/variables";
import { FormGroup } from "@material-ui/core";
import { FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/SHUROTEICHAKU/facility/initialValues";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";
import { generateRadioItems } from "@utils/dataNormalizer";

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
  setFieldValue: (fieldName: string, value: number | string | boolean) => void;
};

type Props = OwnProps;

// なしと福祉・介護職員等処遇改善（Ⅰ）-（Ⅴ）の選択肢
const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);
// なしと、福祉・介護職員等処遇改善（Ⅴ）1 - 14の選択肢
const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypesSHUROTEICHAKU
);

const AdditionalItemFields = (props: Props): JSX.Element => {
  const { values, setFieldValue } = props.formikProps;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (
      ![`${SELECTED_CONDITION_TYPE_2024_V}`].includes(
        values.additionalItem.specificBetterSupporterCondition202404
      )
    ) {
      setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  React.useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .specificBetterSupporterCondition ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.setFieldValue(
        "additionalItem.specificBetterSupporterCondition",
        false
      );

      props.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  return (
    <div style={{ marginBottom: 103 }}>
      <FormPaper>
        <div style={{ marginBottom: 32 }}>
          <SectionTitle label="加算対象項目" />
        </div>
        <FormGroup>
          <FormikCheckbox
            name="additionalItem.workHardenesResultFlag"
            label="就労定着実績体制加算"
          />
          <FormikCheckbox
            name="additionalItem.workPlaceAdaptationAssistantFlag"
            label="職場適応援助者養成研修修了者体制加算"
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="workPlaceAdaptationAssistantFlag" />
                }
              />
            }
          />
          <FormGroup style={{ marginBottom: 28 }}>
            <FormikRadioButtons
              name="additionalItem.specificBetterSupporterCondition202404"
              style={{ marginBottom: 0 }}
              label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
              options={SpecificBetterSupporterConditionType2024RadioItems}
              onClick={onChangeSpecificBetterSupporterConditionType2024}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                  }
                />
              }
            />
            {props.formikProps.values.additionalItem
              .specificBetterSupporterCondition202404 ===
              `${SELECTED_CONDITION_TYPE_2024_V}` && (
              <FormikRadioButtons
                style={{ marginLeft: 48, marginTop: -2, marginBottom: 0 }}
                name="additionalItem.specificBetterSupporterCondition202404Sub"
                label=""
                options={SpecificBetterSupporterConditionTypeSub2024RadioItems}
              />
            )}
          </FormGroup>
          <FormikRadioButtons
            name="additionalItem.staffTreatmentSystemType"
            label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
            options={staffTreatmentSystemTypeRadioItems}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
                }
              />
            }
          />

          <FormikCheckbox
            name="additionalItem.specificBetterSupporterCondition"
            label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
            disabled={
              !["2", "3", "4"].includes(
                props.formikProps.values.additionalItem.staffTreatmentSystemType
              )
            }
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
                }
              />
            }
          />
          <FormikCheckbox
            name="additionalItem.betterSupporterConditionBaseUpFlg"
            label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
            disabled={
              !["2", "3", "4"].includes(
                props.formikProps.values.additionalItem.staffTreatmentSystemType
              )
            }
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
                }
              />
            }
          />
        </FormGroup>
      </FormPaper>
    </div>
  );
};

export default AdditionalItemFields;
