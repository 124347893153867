import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { supportProcedureApi } from "@api/requests/supportProcedure";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import {
  normalizePostSupportProcedureDetailToAPI,
  normalizePostSupportProcedureDetailFormToApi,
  normalizePostSupportProcedureDetailFormRecordDeleteToApi
} from "./normalizer";
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import { StaffState } from "@stores/domain/staff/types";
import { TARGET_FLG } from "@constants/mgr/KODOENGO/variables";
import { PrintRecordParams } from "@api/requests/supportProcedure/getSupportProcedurePrint";

const fetchSupportProcedureList = (dispatch: Dispatch) => async (
  uifId: string,
  date: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportProcedureStarted());
  await supportProcedureApi
    .getSupportProcedureList(uifId, date)
    .then((res) => {
      dispatch(actions.fetchSupportProcedureSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportProcedureFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const deleteSupportProcedureDetail = (dispatch: Dispatch) => async (
  supportProcedureFormsId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteSupportProcedureDetailStarted());
  await supportProcedureApi
    .deleteSupportProcedureDetail(supportProcedureFormsId)
    .then(() => {
      dispatch(actions.deleteSupportProcedureDetailSuccess());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.deleteSupportProcedureDetailFailed({ error: e.response })
      );
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchModalSupportProcedureList = (dispatch: Dispatch) => async (
  uifId: string,
  date: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchModalSupportProcedureStarted());
  await supportProcedureApi
    .getSupportProcedureList(uifId, date)
    .then((res) => {
      dispatch(actions.fetchModalSupportProcedureSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchModalSupportProcedureFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchSupportProcedureDetail = (dispatch: Dispatch) => async (
  serviceDeliveryRecordsId?: string,
  inoutResultsId?: string,
  supportProcedureFormsId?: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportProcedureDetailStarted());
  await supportProcedureApi
    .getSupportProcedureDetail(
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    )
    .then((res) => {
      dispatch(actions.fetchSupportProcedureDetailSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(
        actions.fetchSupportProcedureDetailFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postSupportProcedureDetail = (dispatch: Dispatch) => async (
  formValues: SupportProcedureDetailFormValues,
  staff: StaffState,
  apiResponse: GetSupportProcedureDetailResponse["data"],
  targetPath: keyof typeof TARGET_FLG,
  targetObject: TargetObject[],
  uifId: number,
  queryTargetDate: string | undefined,
  originSupportProcedureFormsId?: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportProcedureDetailStarted());

  const normalizeParam = normalizePostSupportProcedureDetailToAPI(
    formValues,
    staff,
    apiResponse,
    targetPath,
    targetObject,
    uifId,
    queryTargetDate,
    originSupportProcedureFormsId
  );

  await supportProcedureApi
    .postSupportProcedureDetail(normalizeParam)
    .then(() => {
      dispatch(actions.postSupportProcedureDetailSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postSupportProcedureDetailFailed({ error: e.response }));
      if (
        e.response &&
        e.response.data &&
        e.response.data.response &&
        e.response.data.response.code === 17
      ) {
        // バリデーションエラーの場合にエラーをスローする
        throw e.response.data;
      }
      dispatch(responseErrorActions.setResponseError(e.data.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
      // 画面側でエラーを拾うために例外を投げる
      throw e;
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/** 支援手順書兼記録用紙の更新 */
const postSupportProcedureDetailForm = (dispatch: Dispatch) => async (
  formValues: SupportProcedureDetailFormValues,
  staff: StaffState,
  apiResponse: GetSupportProcedureDetailResponse["data"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportProcedureDetailFormStarted());

  await supportProcedureApi
    .postSupportProcedureDetailForm(
      Number(apiResponse.support_procedure_forms_id),
      normalizePostSupportProcedureDetailFormToApi(formValues, apiResponse)
    )
    .then(() => {
      dispatch(actions.postSupportProcedureDetailFormSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      if (
        e.response &&
        e.response.data &&
        e.response.data.response &&
        e.response.data.response.code === 17
      ) {
        // バリデーションエラーの場合にエラーをスローする
        throw e.response.data;
      }
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/** 支援記録 支援手順書 兼 記録用紙の記録部分削除(通信としてはPOSTを実行する) */
const postSupportProcedureDetailFormRecordDelete = (
  dispatch: Dispatch
) => async (
  apiResponse: GetSupportProcedureDetailResponse["data"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportProcedureDetailFormRecordDeleteStarted());

  await supportProcedureApi
    .postSupportProcedureDetailForm(
      Number(apiResponse.support_procedure_forms_id),
      normalizePostSupportProcedureDetailFormRecordDeleteToApi(apiResponse)
    )
    .then(() => {
      dispatch(actions.postSupportProcedureDetailFormSuccess());
      dispatch(snackbarActions.showSnackbar("削除が完了しました。", "success"));
    })
    .catch(() => {
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchPrintRecord = (dispatch: Dispatch) => async (
  params: PrintRecordParams
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchPrintRecordStarted());
  await supportProcedureApi
    .getSupportProcedurePrint({
      targetDate: params.targetDate,
      excludedUserIds: params.excludedUserIds,
      from: params.from,
      to: params.to,
      userIds: params.userIds,
      targetId: params.targetId
    })
    .then((res) => {
      dispatch(actions.fetchPrintRecordSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchPrintRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchSupportProcedureList: (uifId: string, date: string) => Promise<void>;
  deleteSupportProcedureDetail: (
    supportProcedureFormsId: string
  ) => Promise<void>;
  fetchModalSupportProcedureList: (
    uifId: string,
    date: string
  ) => Promise<void>;
  fetchSupportProcedureDetail: (
    serviceDeliveryRecordsId?: string,
    inoutResultsId?: string,
    supportProcedureFormsId?: string
  ) => Promise<void>;
  postSupportProcedureDetail: (
    formValues: SupportProcedureDetailFormValues,
    staff: StaffState,
    apiResponse: GetSupportProcedureDetailResponse["data"],
    targetPath: keyof typeof TARGET_FLG,
    targetObject: TargetObject[],
    uifId: number,
    queryTargetDate: string | undefined,
    originSupportProcedureFormsId?: string
  ) => Promise<void>;
  postSupportProcedureDetailForm: ReturnType<
    typeof postSupportProcedureDetailForm
  >;
  postSupportProcedureDetailFormRecordDelete: ReturnType<
    typeof postSupportProcedureDetailFormRecordDelete
  >;
  fetchPrintRecord: (params: PrintRecordParams) => Promise<void>;
};

export const KODOENGOSupportProcedureDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchSupportProcedureList: fetchSupportProcedureList(dispatch),
  fetchModalSupportProcedureList: fetchModalSupportProcedureList(dispatch),
  fetchSupportProcedureDetail: fetchSupportProcedureDetail(dispatch),
  postSupportProcedureDetail: postSupportProcedureDetail(dispatch),
  postSupportProcedureDetailForm: postSupportProcedureDetailForm(dispatch),
  postSupportProcedureDetailFormRecordDelete: postSupportProcedureDetailFormRecordDelete(
    dispatch
  ),
  deleteSupportProcedureDetail: deleteSupportProcedureDetail(dispatch),
  fetchPrintRecord: fetchPrintRecord(dispatch)
});
