import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDate from "@components/molecules/FormikSelectDate";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { UpperLimitTable } from "@components/organisms/mgr/common/Users/items/UpperLimitTable";

// formik
import { FormikProps, getIn } from "formik";
import { UsersValues } from "@initialize/mgr/SEIKATSUKAIGO/users/initialValues";

// store
import { FacilityState } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/types";

// constants
import {
  REHABILITATION_TYPE_LIST,
  SERIOUS_DISABILITY_TYPE_LIST
} from "@constants/mgr/SEIKATSUKAIGO/variables";
import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_LIST,
  INCOME_KIND_TYPE_LIST,
  SUBSIDIZED_UNIT_LIST,
  UPLIMIT_CONT_ROLLED_BY_LIST,
  RESULT_OF_MANAGEMENT_LIST,
  SUPPLY_FOOD_SERVICE,
  SUPPLY_PICKUP_SERVICE,
  PICKUP_PREMISES_CASE_0,
  PICKUP_PREMISES_CASE_1,
  PICKUP_PREMISES_CASE_2,
  PICKUP_PREMISES_CASE_3,
  AGREED_BY_CONTRACT_LIST,
  DEFAULT_SELECT_VALUE
} from "@constants/variables";

// others
import { OptionInterface } from "@components/atoms/DropDown";
import TableHead, { HeaderProps } from "@components/molecules/TableHead";
import { TableBody, TableCell, TableRow, Theme } from "@material-ui/core";
import FormLabel from "@components/molecules/FormLabel";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    groupDate: {
      margin: "6px 0 0 16px"
    },
    fieldWrapper: {
      position: "relative"
    },
    fieldWrapperFoods: {
      position: "relative",
      marginTop: 20
    },
    irregularTooltip1: {
      position: "absolute",
      top: "50%",
      marginTop: -9,
      left: 276
    },
    irregularTooltip2: {
      position: "absolute",
      top: "50%",
      marginTop: -9,
      left: 260
    },
    incomeKindType: {
      position: "absolute",
      top: 22,
      left: 140
    },
    supplyPickupInSameSiteService: {
      position: "absolute",
      top: 89,
      left: 293
    },
    SeriousSupporterFlag: {
      margin: "-24px 0 -16px 16px"
    },
    severeDisabilitySupport: {
      marginTop: 25
    },
    checkOption: {
      color: "#666666",
      fontSize: "14px"
    },
    payDaysAgreed: {
      width: 213,
      marginLeft: 30
    },
    businessNumberContract: {
      width: 245
    },
    rehabilitationStartDate: {
      marginLeft: 15
    },
    def: {
      marginBottom: -18
    },
    date: {
      marginBottom: 12
    },
    agreedByContractFlg: {
      marginTop: 2,
      marginBottom: 8
    },
    fieldWrapperForm: {
      marginBottom: 16,
      "& > div": {
        marginBottom: 0
      }
    },
    row: {
      "&:nth-child(even)": {
        backgroundColor: palette.background.default
      }
    },
    cellStyle: {
      padding: "10px 20px 7px 0px",
      borderBottom: "none",
      verticalAlign: "top"
    },
    checkboxCellStyle: {
      padding: "4px 0px 4px 18px",
      borderBottom: "none",
      verticalAlign: "top"
    },
    weekDayCellStyle: {
      padding: "16px 0px 4px 0px",
      borderBottom: "none",
      verticalAlign: "top"
    },
    calculatedHoursCellStyle: {
      padding: "8px 10px 4px 10px",
      borderBottom: "none",
      verticalAlign: "top"
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  isFetchDone?: boolean;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

type State = {
  shouldFirstSetup: boolean;
  showIncomeKindType: boolean;
  showSubsidizedPercent: boolean;
  showOfficeInfoTable: boolean;
  showUpperLimitYen: boolean;
  showAgreedByContractFlg: boolean;
  showRehabilitationType: boolean;
  pickupPremisesCase: OptionInterface[];
  showPickupPremises: boolean;
};

const currentDate = new Date();
class ServiceUseFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldFirstSetup: true,
      showIncomeKindType: true,
      showSubsidizedPercent: true,
      showOfficeInfoTable: true,
      showUpperLimitYen: false,
      showAgreedByContractFlg: false,
      showRehabilitationType: false,
      pickupPremisesCase: PICKUP_PREMISES_CASE_0,
      showPickupPremises: true
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): State | null {
    if (!prevState.shouldFirstSetup || !nextProps.isFetchDone) {
      return null;
    }

    const { serviceUse } = nextProps.formikProps.values;
    let pickupPremisesList = PICKUP_PREMISES_CASE_0;
    switch (serviceUse.defPickup) {
      case "1":
        pickupPremisesList = PICKUP_PREMISES_CASE_1;
        break;
      case "2":
        pickupPremisesList = PICKUP_PREMISES_CASE_2;
        break;
      case "3":
        pickupPremisesList = PICKUP_PREMISES_CASE_3;
        break;
      default:
    }
    return {
      shouldFirstSetup: false,
      showIncomeKindType: serviceUse.incomeKind === "1",
      showSubsidizedPercent: serviceUse.subsidizedUnit === "1",
      showOfficeInfoTable: serviceUse.upperLimitControlledBy === "1",
      showUpperLimitYen: serviceUse.resultOfManagement === "3",
      showAgreedByContractFlg: serviceUse.agreedByContractFlg === "2",
      showRehabilitationType:
        serviceUse.rehabilitation === "1" || serviceUse.rehabilitation === "2",
      showPickupPremises: serviceUse.defPickup === "0",
      pickupPremisesCase: pickupPremisesList
    };
  }

  /**
   * 負担上限額が0の時、所得区分を表示
   */
  private onChangeIncomeKindHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showIncomeKindType: value === "1" });
  };

  /**
   * 契約支給量が選択されたら、日数と事業者記入欄番号を表示
   */
  private onChangeAgreedByContractHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showAgreedByContractFlg: value === "2" });
  };

  /**
   * 送迎サービスデフォルトの値によって、同一敷地内のセレクトリストを変更
   */
  private onChangepickupPremisesHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    let pickupPremisesList = PICKUP_PREMISES_CASE_0;
    switch (value) {
      case "1":
        pickupPremisesList = PICKUP_PREMISES_CASE_1;
        break;
      case "2":
        pickupPremisesList = PICKUP_PREMISES_CASE_2;
        break;
      case "3":
        pickupPremisesList = PICKUP_PREMISES_CASE_3;
        break;
      default:
    }
    this.setState({
      pickupPremisesCase: pickupPremisesList,
      showPickupPremises: value === "0"
    });
    this.props.setFormikFieldValue("serviceUse.pickupPremises", "0");
  };

  /**
   * リハビリテーション加算が1か2の時、リハビリテーション実施計画作成を表示
   */
  private onChangeRehabilitationHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showRehabilitationType: value === "2" || value === "1" });
  };

  /**
   * 自治体助成金対象: 助成金 <=> 金額：円
   */
  private onChangeSubsidizedUnitHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showSubsidizedPercent: value === "1" });
  };

  /**
   * 月途中の区分変更あり：ONOFF切り替え時に値をリセット
   */
  private onChangeMidMonthChangeFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { setFieldValue } = this.props.formikProps;
    setFieldValue("serviceUse.midMonthChangeFlg", e.currentTarget.checked);
    if (!e.currentTarget.checked) {
      setFieldValue("serviceUse.midMonthChangeFlg", false);
      setFieldValue(
        "serviceUse.midMonthChangeDisabilityClass",
        DISABILITY_CLASS_LIST[0].value
      );
      setFieldValue("serviceUse.midMonthChangeDate", {
        year: "",
        month: "",
        day: ""
      });
    }
  };

  /**
   * 上限管理事業所あり：ONOFF切り替え時に値をリセット
   */
  private onChangeUpperLimitFacilityFlag = (): void => {
    if (
      getIn(this.props.formikProps.values, "serviceUse.upperLimitFacilityFlag")
    ) {
      this.props.setFormikFieldValue(
        "serviceUse.upperLimitFacilityFlag",
        false
      );
      this.props.setFormikFieldValue("serviceUse.upperLimitControlledBy", "1");
      this.props.setFormikFieldValue("serviceUse.resultOfManagement", "1");
      this.props.setFormikFieldValue("serviceUse.upperLimitYen", "");
      this.setState({
        showUpperLimitYen: false,
        showOfficeInfoTable: true
      });
      this.resetUpperLimitFacilityYenForm();
    } else {
      this.props.setFormikFieldValue("serviceUse.upperLimitFacilityFlag", true);
    }
  };

  private resetUpperLimitFacilityYenForm = (): void => {
    for (let i = 0; i < 4; i += 1) {
      const dataNumber = i !== 0 ? String(i + 1) : "";
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityNumber${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityName${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitTotalYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitUserLoadYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.uplimitUserLoadAfterAdjustmentYen${dataNumber}`,
        ""
      );
    }
  };

  /**
   * 管理事業所が1の時、総費用額と利用者負担額を表示
   */
  private onChangeUpperLimitControlledBy = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showOfficeInfoTable: value === "1" });
    this.resetUpperLimitFacilityYenForm();
  };

  /**
   * 管理結果が3の時、自事業所調整上限額を表示及び管理結果後利用者負担額の値のリセット
   */
  private onChangeResultOfManagement = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showUpperLimitYen: value === "3" });
    if (value !== "3")
      ["", "2", "3", "4"].forEach((item) => {
        this.props.setFormikFieldValue(
          `serviceUse.uplimitUserLoadAfterAdjustmentYen${item}`,
          ""
        );
      });
  };

  /**
   * 利用日数に関わる特例あり：ONOFF切り替え時に値をリセット
   */
  private onChangeDayUseSpecialCaseFlg = (): void => {
    if (
      getIn(this.props.formikProps.values, "serviceUse.dayUseSpecialCaseFlag")
    ) {
      this.props.setFormikFieldValue("serviceUse.dayUseSpecialCaseFlag", false);
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseStartDate.year",
        DEFAULT_SELECT_VALUE
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseStartDate.month",
        ""
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseEndDate.year",
        DEFAULT_SELECT_VALUE
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseEndDate.month",
        ""
      );
    } else {
      this.props.setFormikFieldValue("serviceUse.dayUseSpecialCaseFlag", true);
    }
  };

  /**
   * 集中的支援加算：ONOFF切り替え時に値をリセット
   * ４月スコープアウトのためコメントアウト
   * 実装時チケット https://gogopmpr.backlog.jp/view/KNOWBE_DEV-2072
   */
  // private onChangeIntensiveSupportFlag = (): void => {
  //   if (
  //     getIn(this.props.formikProps.values, "serviceUse.intensiveSupportFlg")
  //   ) {
  //     this.props.setFormikFieldValue("serviceUse.intensiveSupportFlg", false);
  //     this.props.setFormikFieldValue(
  //       "serviceUse.intensiveSupportStartDate.year",
  //       DEFAULT_SELECT_VALUE
  //     );
  //     this.props.setFormikFieldValue(
  //       "serviceUse.intensiveSupportStartDate.month",
  //       ""
  //     );
  //     this.props.setFormikFieldValue(
  //       "serviceUse.intensiveSupportStartDate.day",
  //       ""
  //     );
  //   } else {
  //     this.props.setFormikFieldValue("serviceUse.intensiveSupportFlg", true);
  //   }
  // };

  public render(): JSX.Element {
    const startAddYearTo = 1;
    const endAddYearTo = 5;
    const header: HeaderProps = {
      tabIndex: 0,
      key: 0,
      selected: false,
      items: [
        {
          align: "left",
          label: ""
        },
        {
          align: "left",
          label: "曜日"
        },
        {
          align: "left",
          label: "標準算定時間"
        }
      ]
    };
    const weekdayItems = [
      {
        label: "月",
        scheduleFlgName: "serviceUse.monScheduledFlg",
        calculatedHoursName: "serviceUse.monCalculatedHours"
      },
      {
        label: "火",
        scheduleFlgName: "serviceUse.tueScheduledFlg",
        calculatedHoursName: "serviceUse.tueCalculatedHours"
      },
      {
        label: "水",
        scheduleFlgName: "serviceUse.wedScheduledFlg",
        calculatedHoursName: "serviceUse.wedCalculatedHours"
      },
      {
        label: "木",
        scheduleFlgName: "serviceUse.thuScheduledFlg",
        calculatedHoursName: "serviceUse.thuCalculatedHours"
      },
      {
        label: "金",
        scheduleFlgName: "serviceUse.friScheduledFlg",
        calculatedHoursName: "serviceUse.friCalculatedHours"
      },
      {
        label: "土",
        scheduleFlgName: "serviceUse.satScheduledFlg",
        calculatedHoursName: "serviceUse.satCalculatedHours"
      },
      {
        label: "日",
        scheduleFlgName: "serviceUse.sunScheduledFlg",
        calculatedHoursName: "serviceUse.sunCalculatedHours"
      }
    ];

    return (
      <FormPaper>
        <div style={{ marginBottom: 32 }}>
          <SectionTitle label="サービス利用詳細" />
        </div>
        <Typography className={this.props.classes.date}>
          サービス提供期間
        </Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceStartDate"
            label="サービス提供開始日"
            style={{ marginBottom: 12 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceEndDate"
            label="サービス提供終了日"
            addYearTo={endAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <FormikCheckbox
          name="serviceUse.sameCorporationMovementFlg"
          label="同一法人の事業所からの受け入れ利用者（初期加算対象外）"
        />
        <Typography className={this.props.classes.date}>受給者証</Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payStartDate"
            label="支給決定開始日 "
            style={{ marginBottom: 12 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payEndDate"
            label="支給決定終了日"
            addYearTo={endAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <FormGroup>
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.disabilityClass"
              label="障害支援区分"
              style={{ margin: "12px 0 18px 16px" }}
              options={DISABILITY_CLASS_LIST}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="disability_class_SEIKATSUKAIGO" />
                  }
                />
              }
            />
            <FormikSwitch
              name="serviceUse.midMonthChangeFlg"
              label="月途中の区分変更あり"
              onChange={this.onChangeMidMonthChangeFlg}
              style={{ marginLeft: 20 }}
            >
              <FormikRadioButtons
                name="serviceUse.midMonthChangeDisabilityClass"
                label=""
                style={{ margin: "0px 0 18px 0px" }}
                options={DISABILITY_CLASS_LIST}
              />
              <FormikSelectDate
                name="serviceUse.midMonthChangeDate"
                label="変更日"
                style={{ marginBottom: 32 }}
                overrideYearFrom={currentDate.getFullYear() - 1}
                overrideYearTo={currentDate.getFullYear() + 1}
              />
            </FormikSwitch>
          </div>
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.agreedByContractFlg"
              label="契約支給量"
              options={AGREED_BY_CONTRACT_LIST}
              onChangeHook={this.onChangeAgreedByContractHook}
              className={this.props.classes.agreedByContractFlg}
            />
            <FormGroup row>
              {this.state.showAgreedByContractFlg && (
                <FormikTextField
                  className={this.props.classes.payDaysAgreed}
                  name="serviceUse.payDaysAgreed"
                  label="日数"
                  placeholder="00"
                  maxLength={2}
                  endAdornmentLabel="日"
                />
              )}
              {this.state.showAgreedByContractFlg && (
                <FormikTextField
                  className={this.props.classes.businessNumberContract}
                  name="serviceUse.businessNumberContract"
                  label="事業者記入欄番号"
                  placeholder="00"
                  maxLength={2}
                />
              )}
            </FormGroup>
          </div>
          <div className={this.props.classes.fieldWrapper}>
            <FormikSwitch
              name="serviceUse.dayUseSpecialCaseFlag"
              label="利用日数に関わる特例あり"
              tooltip={
                <HelpToolTip
                  title={<HelpTipMessages name="special_case_flg" />}
                />
              }
              onChange={this.onChangeDayUseSpecialCaseFlg}
              className={this.props.classes.formikActionLabel}
              style={{ marginLeft: 16 }}
            >
              <FormGroup>
                <FormikSelectYearMonth
                  name="serviceUse.dayUseSpecialCaseStartDate"
                  label="対象期間（開始）"
                  overrideYearFrom={2016}
                  addYearTo={1}
                  style={{ marginBottom: 16 }}
                />
                <FormikSelectYearMonth
                  name="serviceUse.dayUseSpecialCaseEndDate"
                  label="対象期間（終了）"
                  overrideYearFrom={2016}
                  addYearTo={1}
                  style={{ marginBottom: 16 }}
                />
              </FormGroup>
            </FormikSwitch>
          </div>
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.incomeKind"
              label="負担上限額"
              options={INCOME_KIND_LIST}
              onChangeHook={this.onChangeIncomeKindHook}
            />
            <div className={this.props.classes.incomeKindType}>
              {this.state.showIncomeKindType && (
                <FormikSelect
                  name="serviceUse.incomeKindType"
                  label="所得区分"
                  options={INCOME_KIND_TYPE_LIST}
                />
              )}
            </div>
          </div>
        </FormGroup>
        <FormikSwitch
          name="serviceUse.subsidizedFlag"
          label="自治体助成金対象"
          tooltip={
            <HelpToolTip title={<HelpTipMessages name="subsidized_flg" />} />
          }
        >
          <FormGroup row>
            {this.state.showSubsidizedPercent && (
              <FormikTextField
                name="serviceUse.subsidizedPercent"
                label="助成金"
                maxLength={9}
              />
            )}
            {!this.state.showSubsidizedPercent && (
              <FormikTextField
                name="serviceUse.subsidizedYen"
                label="金額：円"
                maxLength={11}
              />
            )}
            <FormikSelect
              name="serviceUse.subsidizedUnit"
              label="単位"
              options={SUBSIDIZED_UNIT_LIST}
              onChangeHook={this.onChangeSubsidizedUnitHook}
            />
          </FormGroup>
          <FormikAddress
            prefectureIdName="basic.prefectureId"
            cityIdName="serviceUse.subsidizedCityId"
            formikProps={this.props.formikProps}
            required={false}
            disabledPrefectureId
            showRegionType={false}
          />
        </FormikSwitch>
        <FormikSwitch
          name="serviceUse.upperLimitFacilityFlag"
          label="上限管理事業所あり"
          onChange={this.onChangeUpperLimitFacilityFlag}
        >
          <div className={this.props.classes.fieldWrapperForm}>
            <FormikSelect
              name="serviceUse.upperLimitControlledBy"
              label="管理事業所"
              options={UPLIMIT_CONT_ROLLED_BY_LIST}
              onChangeHook={this.onChangeUpperLimitControlledBy}
            />
          </div>
          {!this.state.showOfficeInfoTable && (
            <FormGroup row className={this.props.classes.fieldWrapperForm}>
              <FormikTextField
                name="serviceUse.upperLimitFacilityNumber"
                label="事業所番号"
                maxLength={10}
                placeholder="0000000000"
              />
              <FormikTextField
                name="serviceUse.upperLimitFacilityName"
                label="事業所名"
              />
            </FormGroup>
          )}
          <FormikRadioButtons
            name="serviceUse.resultOfManagement"
            label="管理結果"
            options={RESULT_OF_MANAGEMENT_LIST}
            onChangeHook={this.onChangeResultOfManagement}
            style={{ marginBottom: 0 }}
          />
          {this.state.showUpperLimitYen && (
            <FormikTextField
              name="serviceUse.upperLimitYen"
              label="自事業所　管理結果後利用者負担額"
              endAdornmentLabel="円"
              style={{
                margin: this.state.showOfficeInfoTable
                  ? "16px 0px 16px 52px"
                  : "16px 0px 32px 52px"
              }}
              maxLength={11}
              InputLabelProps={{
                style: {
                  width: "120%",
                  transform: "scale(0.75) translate(0, 1.5px)"
                }
              }}
            />
          )}
          {this.state.showOfficeInfoTable && (
            <UpperLimitTable formikProps={this.props.formikProps} />
          )}
        </FormikSwitch>
        <FormikSwitch
          name="serviceUse.createSupportPlanFlag"
          label="個別支援計画未作成"
          style={{ marginBottom: 0 }}
        >
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.notCreateSupportPlanStartDate"
            label="未作成期間開始日"
            style={{ marginBottom: 0 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </FormikSwitch>

        <div style={{ marginTop: 24, marginBottom: 3 }}>
          <FormLabel
            label="通所予定日・標準算定時間"
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="calculatedHoursTips" />}
              />
            }
          />
        </div>
        <TableHead
          role={undefined}
          ariaChecked={undefined}
          tabIndex={0}
          key={0}
          selected={false}
          items={header.items}
          rowStyle={undefined}
        />
        <TableBody>
          {weekdayItems.map((item) => (
            <TableRow className={this.props.classes.row} key={item.label}>
              <TableCell className={this.props.classes.checkboxCellStyle}>
                <FormikCheckbox
                  label=""
                  name={item.scheduleFlgName}
                  style={{ margin: 0 }}
                />
              </TableCell>
              <TableCell className={this.props.classes.weekDayCellStyle}>
                {item.label}
              </TableCell>
              <TableCell
                className={this.props.classes.calculatedHoursCellStyle}
              >
                <FormikTextField
                  name={item.calculatedHoursName}
                  endAdornmentLabel="時間"
                  placeholder="0.5"
                  maxLength={3}
                  disabled={
                    !getIn(this.props.formikProps.values, item.scheduleFlgName)
                  }
                  style={{ width: 120, marginBottom: 4, marginRight: 10 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

        <div className={this.props.classes.fieldWrapperFoods}>
          <Typography className={this.props.classes.def}>
            食事提供サービス デフォルト
          </Typography>
          <div className={this.props.classes.groupDate}>
            <FormikSelect
              name="serviceUse.defFood"
              label=""
              options={SUPPLY_FOOD_SERVICE}
              disabled={!this.props.facility.mealSaservedServiceFlag}
            />
          </div>
          <Typography className={this.props.classes.def}>
            送迎サービス デフォルト
          </Typography>
          <div className={this.props.classes.groupDate}>
            <FormikSelect
              name="serviceUse.defPickup"
              label=""
              options={SUPPLY_PICKUP_SERVICE}
              disabled={!this.props.facility.transferServiceFlag}
              onChangeHook={this.onChangepickupPremisesHook}
            />
          </div>
          <div className={this.props.classes.supplyPickupInSameSiteService}>
            <Typography className={this.props.classes.def}>
              同一敷地内送迎 デフォルト
            </Typography>
            <div className={this.props.classes.groupDate}>
              <FormikSelect
                name="serviceUse.pickupPremises"
                label=""
                options={this.state.pickupPremisesCase}
                disabled={
                  !this.props.facility.transferServiceFlag ||
                  this.state.showPickupPremises
                }
              />
            </div>
          </div>
        </div>
        <Typography className={this.props.classes.checkOption}>
          *
          事業所設定の「実施オプションサービス」にチェックを入れると設定ができます。
        </Typography>
        <div className={this.props.classes.fieldWrapper}>
          <div className={this.props.classes.severeDisabilitySupport}>
            {this.props.facility.seriousDisability !==
              SERIOUS_DISABILITY_TYPE_LIST[0].value && (
              <FormikSwitch
                name="serviceUse.severeDisabilitySupport"
                label="重度障害者支援の個別支援対象者である"
                tooltip={
                  <HelpToolTip
                    title={
                      <HelpTipMessages name="severeDisabilitySupportFlag202104" />
                    }
                  />
                }
              >
                <FormikSelectDateNotSelectedDefault
                  name="serviceUse.severeDisabilitySupportStartData"
                  label="加算算定開始日"
                  addYearTo={startAddYearTo}
                  setFormikFieldValue={this.props.setFormikFieldValue}
                />
              </FormikSwitch>
            )}
          </div>
        </div>
        <div className={this.props.classes.fieldWrapper}>
          <FormikRadioButtons
            name="serviceUse.rehabilitation"
            label="リハビリテーション加算"
            options={REHABILITATION_TYPE_LIST}
            onChangeHook={this.onChangeRehabilitationHook}
          />
          <div className={this.props.classes.groupDate}>
            {this.state.showRehabilitationType && (
              <div className={this.props.classes.rehabilitationStartDate}>
                <FormikSelectDateNotSelectedDefault
                  name="serviceUse.rehabilitationStartDate"
                  label="リハビリ実施計画作成日"
                  addYearTo={startAddYearTo}
                  setFormikFieldValue={this.props.setFormikFieldValue}
                />
              </div>
            )}
          </div>
        </div>
        {/* 
       * ４月スコープアウトのためコメントアウト
       * 実装時チケット https://gogopmpr.backlog.jp/view/KNOWBE_DEV-2072
        <FormikSwitch
          name="serviceUse.intensiveSupportFlg"
          label="集中的支援加算"
          onChange={this.onChangeIntensiveSupportFlag}
        >
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.intensiveSupportStartDate"
            label="支援開始日"
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </FormikSwitch> */}
      </FormPaper>
    );
  }
}

export const ServiceUseFields = withStyles(styles)(ServiceUseFieldsCore);
