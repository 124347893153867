import { compareDateFuture } from "@utils/date";
import { isNaN } from "lodash-es";

export type TargetUser = {
  uif_id: number;
  // kanaName: string;
  date_end_in_service?: string | null;
};

/**
 * サービス提供日がすぎているユーザーを返す
 * @param targetUser サービス提供日がすぎているかチェックされるユーザーデータ
 * @return サービス提供日がすぎているユーザーidの配列
 */
export const filterExpiredUser = <T extends TargetUser>(
  targetUser: T[]
): string[] => {
  if (!targetUser.length) return [];
  const expiredArr: string[] = [];
  targetUser.forEach((u) => {
    if (u.date_end_in_service) {
      const targetDate = new Date(u.date_end_in_service);
      if (isNaN(targetDate.getTime())) {
        return;
      }
      if (compareDateFuture(new Date(), targetDate)) {
        return;
      }
      expiredArr.push(`${u.uif_id}`);
    }
  });
  return expiredArr;
};
