import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * モニタリング報告書 のデータ削除
 * @param uifId 事業所所属ユーザーのID
 * @param monitoringReportId モニタリング報告書 のID
 */
export const deleteMonitoringReport = async (
  uifId: string,
  monitoringReportId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/monitoring_report/${monitoringReportId}`;
  return request.delete(url);
};
