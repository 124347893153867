/**
 * 業務支援管理者ページのドロワー(Menu)
 */
import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { createStyles, WithStyles } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Cancel from "@material-ui/icons/Cancel";
import { MOBILE_MIN_WIDTH, DARK_BLUE } from "@constants/styles";
import { FacilityType } from "@constants/variables";
import { MENU_ITEM_LIST_KYOTAKUKAIGO } from "@constantsMobile/mgr/KYOTAKUKAIGO/menu";
import { MENU_ITEM_LIST_JUDOHOMONKAIGO } from "@constantsMobile/mgr/JUDOHOMONKAIGO/menu";
import { MENU_ITEM_LIST_DOKOENGO } from "@constantsMobile/mgr/DOKOENGO/menu";
import { MENU_ITEM_LIST_KODOENGO } from "@constantsMobile/mgr/KODOENGO/menu";
import { MENU_ITEM_LIST_IDOSHIEN } from "@constantsMobile/mgr/IDOSHIEN/menu";
import { MenuItemList } from "@constants/menu";
import DrawerList from "@components/molecules/DrawerList";
import KnowbeMgrLogo from "@components/atoms/KnowbeMgrLogo";
import IconButton from "@material-ui/core/IconButton";

const styles = (): StyleRules =>
  createStyles({
    contentWrapper: {
      padding: "14px 16px"
    },
    drawerPaper: {
      position: "relative",
      width: MOBILE_MIN_WIDTH,
      minHeight: "100svh",
      backgroundColor: DARK_BLUE,
      display: "flex",
      justifyContent: "space-between"
    },
    header: {
      display: "flex",
      justifyContent: "space-between"
    },
    close: {
      marginLeft: 8,
      display: "flex",
      alignItems: "center",
      fontColor: "#fff",
      fontSize: 14
    },
    companyName: {
      marginTop: 30,
      color: "rgba(255, 255, 255, 0.5)",
      fontSize: 12,
      lineHeight: 1.67,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    },
    officeName: {
      marginTop: 4,
      fontSize: 16,
      lineHeight: 1.5,
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: "rgba(255, 255, 255, 0.87)"
    },
    cancel: {
      padding: 0,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      borderRadius: 0,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0)"
      }
    },
    cancelIcon: {
      fontSize: 20,
      fontColor: "#eceff1"
    },
    logout: {
      marginTop: 26,
      height: 48,
      width: MOBILE_MIN_WIDTH,
      backgroundColor: DARK_BLUE,
      padding: 0,
      color: "#fff",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      borderTop: "1px solid #546E7A",
      borderRadius: 0
    },
    logoutIcon: {
      marginLeft: 16,
      fontSize: 24
    },
    logoutText: {
      marginLeft: 24,
      fontSize: 16
    },
    list: {
      marginLeft: -16 - 2,
      width: MOBILE_MIN_WIDTH + 2
    },
    backDrop: {
      backgroundColor: "rgba(0, 0, 0, 0.38)"
    }
  });

type StateProps = {
  featureGroup: UserState["featureGroup"];
  facility_name: string;
  facility_type: string;
  business_owner: string;
  needsStopHistory: boolean;
};

type OwnProps = {
  isOpen: boolean;
  toggleDrawer: reactDispatch<SetStateAction<boolean>>;
};

type DispatchProps = {
  handleLogout: () => void;
};

type MergeProps = StateProps &
  DispatchProps &
  OwnProps & {
    itemList: MenuItemList;
  };

type Props = RouteComponentProps & MergeProps & WithStyles<typeof styles>;

const MgrDrawerCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const onClose = (): void => {
    props.toggleDrawer(false);
  };

  const onLogout = (): void => {
    props.handleLogout();
  };

  return (
    <nav>
      <Drawer
        variant="temporary"
        anchor="left"
        open={props.isOpen}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{
          keepMounted: true,
          BackdropProps: { classes: { root: classes.backDrop } }
        }}
        onClose={onClose}
      >
        <div className={classes.contentWrapper}>
          <div className={classes.header}>
            <KnowbeMgrLogo />
            <IconButton classes={{ root: classes.cancel }} onClick={onClose}>
              <Cancel className={classes.cancelIcon} />
              <span className={classes.close}>閉じる</span>
            </IconButton>
          </div>
          <div className={classes.companyName}>{props.business_owner}</div>
          <div className={classes.officeName}>{props.facility_name}</div>
          <div className={classes.list}>
            <DrawerList
              list={props.itemList}
              featureGroup={props.featureGroup}
              needsStopHistory={props.needsStopHistory}
            />
          </div>
        </div>
        <IconButton classes={{ root: classes.logout }} onClick={onLogout}>
          <ExitToApp className={classes.logoutIcon} />
          <span className={classes.logoutText}>ログアウト</span>
        </IconButton>
      </Drawer>
    </nav>
  );
};

/**
 * 事業所タイプによるメニューの出しわけとエラーの埋め込みを行う
 */
const getMenuList = (props: StateProps): MenuItemList => {
  return ((): MenuItemList => {
    switch (props.facility_type) {
      case FacilityType.KYOTAKUKAIGO:
        return MENU_ITEM_LIST_KYOTAKUKAIGO;
      case FacilityType.JUDOHOMONKAIGO:
        return MENU_ITEM_LIST_JUDOHOMONKAIGO;
      case FacilityType.DOKOENGO:
        return MENU_ITEM_LIST_DOKOENGO;
      case FacilityType.KODOENGO:
        return MENU_ITEM_LIST_KODOENGO;
      case FacilityType.IDOSHIEN:
        return MENU_ITEM_LIST_IDOSHIEN;
      default:
        return [];
    }
  })();
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch } = dispatches;
  return {
    handleLogout: authDispatch(dispatch).logout
  };
};

const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  return {
    featureGroup: user.featureGroup,
    facility_name: user.facility_name || "",
    facility_type: user.facility_type || "",
    business_owner: user.business_owner || "",
    needsStopHistory: state.ui.needsStopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  return {
    itemList: getMenuList(stateProps),
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const MgrDrawer = withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(withStyles(styles)(MgrDrawerCore))
);
