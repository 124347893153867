import { InitialDataValues } from "./initialValues";
import validator, { dateValidator } from "@validator";
import { InitialErrors } from "@interfaces/mgr/KEIKAKUSODAN/initial/initialData";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type InitialDataErrors = InitialErrors;

const initialDataValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initialData: {
      facility: {
        first_time_bill_date: dateValidator(
          // first_time_bill_dateのフォーム値は日にちの情報を持たない為
          // 1日をdefaultで設定する
          notSelectedToEmpty({
            ...values.initialData.facility.first_time_bill_date,
            day: "1"
          }),
          "required"
        ),
        total_number_of_users_1_month_before: validator(
          values.initialData.facility.total_number_of_users_1_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_users_2_month_before: validator(
          values.initialData.facility.total_number_of_users_2_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_users_3_month_before: validator(
          values.initialData.facility.total_number_of_users_3_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_users_4_month_before: validator(
          values.initialData.facility.total_number_of_users_4_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_users_5_month_before: validator(
          values.initialData.facility.total_number_of_users_5_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_users_6_month_before: validator(
          values.initialData.facility.total_number_of_users_6_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_1_month_before: validator(
          values.initialData.facility.total_number_of_staffs_1_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_2_month_before: validator(
          values.initialData.facility.total_number_of_staffs_2_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_3_month_before: validator(
          values.initialData.facility.total_number_of_staffs_3_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_4_month_before: validator(
          values.initialData.facility.total_number_of_staffs_4_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_5_month_before: validator(
          values.initialData.facility.total_number_of_staffs_5_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        ),
        total_number_of_staffs_6_month_before: validator(
          values.initialData.facility.total_number_of_staffs_6_month_before,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 999 }
        )
      }
    }
  };
};

export const validation = (values: InitialDataValues): InitialDataErrors => {
  const initialErrors = initialDataValidation(values);

  return {
    initialData: {
      ...initialErrors.initialData
    }
  };
};
