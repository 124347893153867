import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import { Checkbox } from "@constants/variables";

/**
 * ActionNames
 */
export const FETCH_STARTED = "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_FAILED";
export const FETCH_ONE_STARTED =
  "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS =
  "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED =
  "KEIKAKUSODAN/USER_IN_FACILITY/FETCH_ONE_FAILED";
export const CREATE_STARTED = "KEIKAKUSODAN/USER_IN_FACILITY/CREATE_STARTED";
export const CREATE_SUCCESS = "KEIKAKUSODAN/USER_IN_FACILITY/CREATE_SUCCESS";
export const CREATE_FAILED = "KEIKAKUSODAN/USER_IN_FACILITY/CREATE_FAILED";
export const UPDATE_STARTED = "KEIKAKUSODAN/USER_IN_FACILITY/UPDATE_STARTED";
export const UPDATE_SUCCESS = "KEIKAKUSODAN/USER_IN_FACILITY/UPDATE_SUCCESS";
export const UPDATE_FAILED = "KEIKAKUSODAN/USER_IN_FACILITY/UPDATE_FAILED";
export const DELETE_STARTED = "KEIKAKUSODAN/USER_IN_FACILITY/DELETE_STARTED";
export const DELETE_SUCCESS = "KEIKAKUSODAN/USER_IN_FACILITY/DELETE_SUCCESS";
export const DELETE_FAILED = "KEIKAKUSODAN/USER_IN_FACILITY/DELETE_FAILED";
export const CLEAR = "KEIKAKUSODAN/USER_IN_FACILITY/CLEAR";

export type UsersInFacilityState = {
  users: GetFacilityUsersResponse["data"];
  user: {
    user_in_facility: Partial<{
      /* 基本情報 */
      // 施設利用者id
      id: number;
      // 姓
      name_sei: string;
      // 名
      name_mei: string;
      // 姓(カナ)
      name_sei_kana: string;
      // 名(カナ)
      name_mei_kana: string;
      // 受給者証番号
      recipient_number: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg: Checkbox;
      // 性別
      gender: string;
      // 施設id
      facility_id: number;
      // 身体障害
      classify_physical_flg: Checkbox;
      // 知的障害
      classify_intelligence_flg: Checkbox;
      // 精神障害
      classify_mind_flg: Checkbox;
      // 発達障害
      classify_growth_flg: Checkbox;
      // 高次脳機能障害
      classify_brain_flg: Checkbox;
      // 難病等対象者
      classify_incurable_flg: Checkbox;
      // 障害児
      classify_handicapped_flg: Checkbox;
      // 生年月日
      date_birth: string;
      // 郵便番号
      postal_code: string;
      // 都道府県
      prefecture_name: string;
      // 市区町村id
      city_id: number;
      // 住所
      address: string;
      // 電話番号
      tel: string;
      // メールアドレス
      email: string;
      // 保護者姓
      guardian_name_sei: string;
      // 保護者名
      guardian_name_mei: string;
      // 保護者姓(カナ)
      guardian_name_sei_kana: string;
      // 保護者名(カナ)
      guardian_name_mei_kana: string;
      // 保護者、続柄
      guardian_relation: string;
      // 保護者 電話番号
      guardian_tel: string;
      // 備考
      memo: string;

      /* サービス詳細 */
      // 入居日 yyyy-mm-dd
      date_begin_in_service: string;
      // 退居日 yyyy-mm-dd
      date_end_in_service: string;
      // 支給決定開始日
      date_pay_begin: string;
      // 支給決定終了日
      date_pay_end: string;
      // 障害支援区分
      classify_disability_support: string;
      // 負担上限額
      income_kind: string;
      // 所得区分
      income_kind_type: string;
      // Checkbox: 自治体助成金対応
      subsidized_flg: Checkbox;
      // 金額: %
      subsidized_percent: string;
      // 金額 ¥
      subsidized_yen: string;
      // Select: 自治体助成金対応(単位 1: %, 2: ¥)
      subsidized_unit_flg: string;
      // 市区町村番号
      subsidized_city_id: number;
      // 上限額管理事業所有り
      uplimit_facility_flg: Checkbox;
      // 上限管理事業所コード null, 1: 上限額管理事業所あり 2: 他事業所が上限を管理
      uplimit_controlled_by: string;
      // サービス提供した事業者番号
      uplimit_facility_number: string;
      // サービス提供した事業者番号2
      uplimit_facility_number2: string;
      // サービス提供した事業者番号3
      uplimit_facility_number3: string;
      // サービス提供した事業者番号4
      uplimit_facility_number4: string;
      // 上限額管理事業所 事業所名
      uplimit_facility_name: string;
      // 上限額管理事業所 事業所名2
      uplimit_facility_name2: string;
      // 上限額管理事業所 事業所名3
      uplimit_facility_name3: string;
      // 上限額管理事業所 事業所名4
      uplimit_facility_name4: string;
      // 上限管理・総費用額(金額)
      uplimit_total_yen: string;
      // 上限管理・総費用額(金額)2
      uplimit_total_yen2: string;
      // 上限管理・総費用額(金額)3
      uplimit_total_yen3: string;
      // 上限管理・総費用額(金額)4
      uplimit_total_yen4: string;
      // 上限管理・利用者負担額(yen)
      uplimit_user_load_yen: string;
      // 上限管理・利用者負担額(yen)2
      uplimit_user_load_yen2: string;
      // 上限管理・利用者負担額(yen)3
      uplimit_user_load_yen3: string;
      // 上限管理・利用者負担額(yen)4
      uplimit_user_load_yen4: string;
      // 上限管理・管理結果後利用者負担額(yen)
      uplimit_user_load_after_adjustment_yen: string;
      // 上限管理・管理結果後利用者負担額(yen)2
      uplimit_user_load_after_adjustment_yen2: string;
      // 上限管理・管理結果後利用者負担額(yen)3
      uplimit_user_load_after_adjustment_yen3: string;
      // 上限管理・管理結果後利用者負担額(yen)4
      uplimit_user_load_after_adjustment_yen4: string;
      // 利用者負担上限額管理結果
      result_of_management: string;
      // 自事業所調整上限額
      uplimit_yen: string;

      /* 受給者証 */
      // 負担上限月額の適用期間 1: ON, 0: OFF
      user_charge_limit_flg: Checkbox;
      // 負担上限月額の適用開始日 yyyy-mm-dd
      date_begin_user_charge_limit: string;
      // 負担上限月額の適用終了日 yyyy-mm-dd
      date_end_user_charge_limit: string;
      // 介護給付費の支給決定フラグ 1: ON 0: OFF
      care_support_payment_flg: Checkbox;
      // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
      date_begin_care_support_payment: string;
      // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
      date_end_care_support_payment: string;
      // 障害支援区分の認定有効  1: ON, 0: OFF
      care_support_auth_flg: Checkbox;
      // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
      date_begin_care_support_auth: string;
      // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
      date_end_care_support_auth: string;
      // 計画相談支援給付費の支給決定フラグ 1: ON 0: OFF
      plan_support_payment_flg: Checkbox;
      // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
      date_begin_plan_support_payment: string;
      // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
      date_end_plan_support_payment: string;
      // 計画相談支援給付費のモニタリング期間 フラグ 1: ON 0: OFF
      plan_support_monitor_flg: Checkbox;
      // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
      date_begin_plan_support_monitor: string;
      // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
      date_end_plan_support_monitor: string;
    }>;
    user_in_facility_keikakusodan?: Partial<{
      /** 施設利用者id */
      users_in_facility_id: number;
      /** 要介護区分 */
      need_nursing_care_class: number;
      /** 要支援区分 */
      support_required_class: number;
      /** 障害児相談支援の利用 */
      handicapped_child_consultation_support_flg: number;
      /** 障害児相談支援の利用開始年月 */
      handicapped_child_consultation_support_from: string | null;
      /** 障害児相談支援の利用終了年月 */
      handicapped_child_consultation_support_to: string | null;
      /** 世帯通番 */
      family_serial_number: number;
      /** 利用者情報の相談種別 1：計画相談、2：障害児相談 */
      type_daily_consultation: 1 | 2;
      /** 施設利用者（計画相談/障害児相談）オブジェクト */
      user_in_facility_keikakusodan_shogaijisodan: {
        /** 相談種別 1:計画相談 2:障害児相談 */
        target_type: 1 | 2;
        /** 受給者証番号 */
        recipient_number: string;
        /** 0:受給者証未発行もしくは見学中の利用者ではない 1：受給者証未発行もしくは見学中の利用者である */
        none_recipient_number_flg: Checkbox;
        /** サービス開始日 */
        date_begin_in_service: string;
        /** サービス終了日 */
        date_end_in_service: string | null;
        /** 計画相談支援給付費開始日 */
        date_pay_begin: string;
        /** 計画相談支援給付費終了日 */
        date_pay_end: string;
        /** 所得区分 */
        income_kind: string;
        /** 負担上限月額0円の場合の所得区分 */
        income_classification: number;
        /** 自治体助成金対象 */
        subsidized_flg: Checkbox;
        /** 助成金（パーセント） */
        subsidized_percent: number | null;
        /** 助成金（円） */
        subsidized_yen: number | null;
        /** 助成金フラグ 1：%、2：¥ */
        subsidized_unit_flg: string | null;
        /** 助成金支給市区町村番号 */
        subsidized_city_id: number | null;
        /** 上限管理事業所フラグ */
        uplimit_facility_flg: Checkbox;
        /** 上限管理事業所 */
        uplimit_controlled_by: string | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number2: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name2: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen2: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen2: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen2: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number3: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name3: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen3: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen3: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen3: number | null;
        /** 上限管理事業所番号 */
        uplimit_facility_number4: string | null;
        /** 上限管理事業所名 */
        uplimit_facility_name4: string | null;
        /** 上限管理総費用額 */
        uplimit_total_yen4: number | null;
        /** 上限管理利用者負担額 */
        uplimit_user_load_yen4: number | null;
        /** 管理結果後利用者負担額 */
        uplimit_user_load_after_adjustment_yen4: number | null;
        /** 上限額管理結果 */
        result_of_management: string;
        /** 自事業所調整上限額 */
        uplimit_yen: number | null;
        /** 障害区分 */
        disability_class: number;
        /** 特別地域加算 */
        special_area_flg: number;
        /** 特別地域加算 適用開始日 */
        date_begin_special_area: string | null;
        /** 特別地域加算 適用終了日 */
        date_end_special_area: string | null;
        /** 障害福祉サービス等利用フラグ */
        disabled_welfare_service_use_flg: number;
        /** 障害福祉サービス等利用開始年月 */
        disabled_welfare_service_use_from: string | null;
        /** 障害福祉サービス等利用終了年月 */
        disabled_welfare_service_use_to: string | null;
        /** Knowbe開始前に計画を作成 */
        before_create_plan_flg: number;
        /** Knowbe開始前の計画作成日 */
        date_before_create_plan: string | null;
        /** Knowbe開始前にモニタリングを実施している */
        before_gone_monitoring_flg: number;
        /** Knowbe開始前のモニタリング実施 */
        date_before_gone_monitoring: string | null;
        /** 負担上限月額の適応期間 */
        user_charge_limit_flg: Checkbox;
        /** 負担上限月額の適応期間開始日 */
        date_begin_user_charge_limit: string | null;
        /** 負担上限月額の適応期間終了日 */
        date_end_user_charge_limit: string | null;
        /** 障害支援区分の認定有効期間 */
        care_support_auth_flg: Checkbox;
        /** 障害支援区分の認定有効期間開始日 */
        date_begin_care_support_auth: string | null;
        /** 障害支援区分の認定有効期間終了日 */
        date_end_care_support_auth: string | null;
        /** 訓練給付費の支給期間 */
        plan_support_payment_flg: Checkbox;
        /** 訓練給付費の支給期間開始日 */
        date_begin_plan_support_payment: string | null;
        /** 訓練給付費の支給期間終了日 */
        date_end_plan_support_payment: string | null;
        /** 計画相談支援給付費モニタリング期間 */
        plan_support_monitor_flg: Checkbox;
        /** 計画相談支援給付費モニタリング期間開始日 */
        date_begin_plan_support_monitor: string | null;
        /** 計画相談支援給付費モニタリング期間終了日 */
        date_end_plan_support_monitor: string | null;
      }[];
    }>;
  };
  doneUpsertUser: boolean;
  userValidation: Partial<{}>;
};
