import * as actions from "./actions";
import * as types from "./types";

const initialState: types.CarePlanStaffState = {
  staffsInFacility: [],
  displayStaffsInFacility: [],
  displayList: [],
  carePlanCount: 0
};

export const KYOTAKUKAIGOCarePlan = (
  state = initialState,
  action: actions.ActionTypes
): types.CarePlanStaffState => {
  switch (action.type) {
    case types.FETCH_KYOTAKUKAIGO_CARE_PLAN_STARTED:
      return { ...state };
    case types.FETCH_KYOTAKUKAIGO_CARE_PLAN:
      return { ...state, ...action.payload };
    case types.FETCH_KYOTAKUKAIGO_CARE_PLAN_FAILED:
      return { ...state };
    case types.POST_KYOTAKUKAIGO_CARE_PLAN_STARTED:
      return { ...state };
    case types.POST_KYOTAKUKAIGO_CARE_PLAN:
      return { ...state };
    case types.POST_KYOTAKUKAIGO_CARE_PLAN_FAILED:
      return { ...state };
    case types.DELETE_KYOTAKUKAIGO_CARE_PLAN_STARTED:
      return { ...state };
    case types.DELETE_KYOTAKUKAIGO_CARE_PLAN:
      return { ...state };
    case types.DELETE_KYOTAKUKAIGO_CARE_PLAN_FAILED:
      return { ...state };
    default:
      return state;
  }
};
