import React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { SettingFormWithCategory } from "@components/organisms/record/settingWithCategory/SettingFormWithCategory";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

/**
 * 提供記録の設定画面
 */
export const RecordSettingWithCategory = (): JSX.Element => (
  <AdminTemplate pageName="提供記録の設定">
    <SettingFormWithCategory />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
