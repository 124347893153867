import * as React from "react";

import * as ClassNames from "classnames";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

const primaryPaddingRight = 16;
const primaryFontSize = 16;
const primaryLineHeight = 28 / primaryFontSize;

const styles = (): StyleRules =>
  createStyles({
    row: {
      // MuiTableRow-rootのheight 48pxをクリアする。
      height: 40
    },
    stripeRow: {
      "&:nth-child(2n)": {
        backgroundColor: "#f5f5f5"
      }
    },
    // tableRowのhover、selectedのCSS上書き
    // https://stackoverflow.com/questions/55852415/how-to-change-the-text-color-of-the-selected-row-in-material-ui-table
    hoverRow: {
      cursor: "pointer",
      "&$hover:hover": {
        backgroundColor: "#eaeaea" // rgba(0, 0, 0, 0.08)
      },
      "&$selected": {
        backgroundColor: "#d6d6d6" // rgba(0, 0, 0, 0.16)
      }
    },
    hover: {},
    selected: {},
    planeRow: {
      borderBottom: "1px solid #e5e5e5",
      "&:last-child": {
        borderBottom: "none"
      }
    },
    cell: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight,
      borderBottom: "none",
      verticalAlign: "initial",
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: primaryPaddingRight
      }
    },
    dashedBorderCell: {
      borderRight: "1px dashed #9e9e9e",
      "&:last-child": {
        borderRight: "none"
      }
    }
  });

export type BasicTableBodyProps = {
  uniqueKey: string;
};

export type MutualProps = {
  rowClassName?: string;
  stripe?: boolean;
  dashedBorder?: boolean;
  hover?: boolean;
  isSelected?: (items: KnowbeTableCellParam[]) => boolean;
  onClickRow?: (items: KnowbeTableCellParam[]) => void;
  // 以下はアクセシビリティ対応
  role?: string;
  ariaChecked?: boolean;
  tabIndex?: number;
};

export type KnowbeTableCellParam = {
  label: string | React.ReactElement;
  align?: "left" | "center" | "right";
  hidden?: boolean;
  className?: string;
};

type KnowbeTableRowOwnProps = {
  items: KnowbeTableCellParam[];
} & BasicTableBodyProps &
  WithStyles<typeof styles>;

type KnowbeTableCellOwnProps = {
  idx: number;
  item: KnowbeTableCellParam;
} & WithStyles<typeof styles>;

type KnowbeTableRowProps = MutualProps & KnowbeTableRowOwnProps;
type KnowbeTableCellProps = MutualProps & KnowbeTableCellOwnProps;

const KnowbeTableCell = React.memo((props: KnowbeTableCellProps) => {
  const { classes, dashedBorder, item } = props;
  return (
    <TableCell
      className={ClassNames(
        classes.cell,
        dashedBorder && classes.dashedBorderCell,
        item.className
      )}
      align={item.align || "left"}
      padding="none"
    >
      {item.label}
    </TableCell>
  );
});

/**
 * KnowbeTableBody用のTableRow。KnowbeTableBodyのchildren以外で使用するように作られていない。
 */
export const KnowbeTableRow = React.memo(
  withStyles(styles)(
    (props: KnowbeTableRowProps): JSX.Element => {
      const {
        classes,
        uniqueKey,
        rowClassName,
        stripe,
        dashedBorder,
        items,
        hover,
        isSelected,
        onClickRow,
        // 以下はアクセシビリティ対応用props
        role,
        ariaChecked,
        tabIndex = -1
      } = props;

      // 選択中の列かどうか
      const isItemSelected = isSelected ? isSelected(items) : false;

      const rowClickFnc = onClickRow
        ? (): void => onClickRow(items)
        : undefined;

      return (
        <TableRow
          className={ClassNames(
            classes.row,
            rowClassName,
            stripe ? classes.stripeRow : classes.planeRow,
            hover && classes.hoverRow
          )}
          classes={{ hover: classes.hover, selected: classes.selected }}
          hover={hover}
          role={role}
          tabIndex={tabIndex}
          aria-checked={ariaChecked}
          selected={isItemSelected}
          onClick={rowClickFnc}
        >
          {items.map((item, idx) => {
            const keyNum = idx;

            // hiddenプロパティを持つデータは非表示
            if (item.hidden) {
              return null;
            }

            return (
              <KnowbeTableCell
                key={`table-cell-${uniqueKey}-${keyNum}`}
                idx={idx}
                item={item}
                dashedBorder={dashedBorder}
                classes={classes}
              />
            );
          })}
        </TableRow>
      );
    }
  )
);
