/**
 * 自然数チェック（0含む）、空文字は判定しない、30分刻みのみ許容
 * @param value
 */
export const decimalPointByEveryHalfMinutes = (
  value: string
): string | undefined => {
  let errorMessage;
  if (value !== "" && !/^([1-9]\d*|0)(\.(5|0)0?)?$/.test(value)) {
    errorMessage = "30分(0.5)刻みで入力してください";
  }
  return errorMessage;
};
