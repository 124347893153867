/**
 * 未来かどうかチェック（日跨ぎ用）
 */
export const checkTimeFutureStraddlingTheDay = (
  startTime: string,
  startTimeClass: string,
  outTime: string,
  outTimeClass: string,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  // 日跨ぎしている場合、時間に+24する
  const startHour =
    startTimeClass === "1"
      ? parseInt(startTime.replace(":", ""), 10) + 2400
      : parseInt(startTime.replace(":", ""), 10);
  const endHour =
    outTimeClass === "1"
      ? parseInt(outTime.replace(":", ""), 10) + 2400
      : parseInt(outTime.replace(":", ""), 10);

  // 開始/終了が一致する場合,、逆転する場合は、エラーとする
  if (endHour === startHour || endHour < startHour) {
    return `${option.firstLabel}は${option.secondLabel}より後の時間を入力してください`;
  }

  return undefined;
};
