import validator, { dateValidator } from "@validator";
import { checkCharacterLength } from "@validator/rules";
import { MeetingRecordValues } from "./initialValues";
import { InitialMeetingRecordErrors } from "@interfaces/mgr/Cseg/record/meetingRecord/initial";

export const validation = (
  values: MeetingRecordValues
): InitialMeetingRecordErrors => {
  return {
    creation_date: dateValidator(values.creation_date, "required"),
    meeting_start_time: validator(values.meeting_start_time || "", "checkTime"),
    meeting_end_time: validator(values.meeting_end_time || "", "checkTime", {
      type: "checkTimeFuture",
      startTime: values.meeting_start_time || ""
    }),
    consideration_item: checkCharacterLength(
      values.consideration_item || "",
      2500
    ),
    consideration_contents: checkCharacterLength(
      values.consideration_contents || "",
      2500
    ),
    consideration_results: checkCharacterLength(
      values.consideration_results || "",
      2500
    ),
    others: checkCharacterLength(values.others || "", 2500)
  };
};
