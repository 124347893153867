import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  FormLabel,
  Button
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikTime from "@components/molecules/FormikTime";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import {
  InitialValues,
  InoutResultsDetailsFields
} from "@interfaces/mgr/IDOSHIEN/report/initial";
import {
  DetailTimeInfo,
  DisplayStaffsInFacilityState,
  MunicipalityData,
  InoutResultsState,
  Timeframe,
  DetailTime,
  TimeRange
} from "@stores/domain/mgr/IDOSHIEN/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import {
  IDOSHIEN_STATUS_LIST,
  IDOSHIEN_STATUS_SELECT_LIST,
  IDOSHIEN_BASE_LIST,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_MEMBER_SELECT_LIST,
  IDOSHIEN_INPUT_CLASS_SELECT_LIST,
  OUT_TIME_VALIDATION_LIST,
  IN_TIME_VALIDATION_LIST,
  TIME_CLASS_LIST,
  IDOSHIEN_GROUP_MEMBER_SELECT_LIST,
  TIME_CLASS
} from "@constants/mgr/IDOSHIEN/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";
import { calculateHours } from "@utils/domain/mgr/IDOSHIEN/calculateHours";
import { calculateOverlapTime } from "@utils/domain/mgr/calculateOverlapTime";
import { formatTime } from "@utils/date/formatTime";
import { UnitsFields } from "@components/organisms/mgr/IDOSHIEN/report/dialog/UnitsFields";
import { ServiceCodesFields } from "@components/organisms/mgr/IDOSHIEN/report/dialog/ServiceCodesFields";
import castNumber from "@utils/dataNormalizer/castNumber";
import { calculateTheRequiredTimeOfEachTimeframe } from "@utils/domain/mgr/IDOSHIEN/calculateTheRequiredTimeOfEachTimeframe";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  convertTimeHHMM,
  dateToLocalisedString,
  parseDateString
} from "@utils/date";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { calculateTotalTime } from "@utils/domain/mgr/IDOSHIEN/calculateTotalTime";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";

const styles = (): StyleRules =>
  createStyles({
    worker_license: {
      marginBottom: 0
    },
    categoryGroup: {
      paddingLeft: 32
    },
    categoryGroupBorder: {
      borderTop: "solid 1px #cfd8dc"
    },
    categoryLabel: {
      paddingTop: 24,
      paddingBottom: 24
    },
    addButton: {
      justifyContent: "left"
    },
    supportRatio: {
      "& > label": {
        width: 190
      }
    },
    checkboxHelperText: {
      marginTop: 0
    },
    facilityUnit: {
      paddingTop: 20
    },
    calculatedHours: {
      "& > label": {
        width: 180
      }
    },
    disabledColor: {
      // disabledの色を上書き
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      },
      "& > div": {
        "& > input": {
          color: "rgba(0, 0, 0, 0.87)!important"
        },
        "& > div": {
          "& > p": {
            color: "rgba(0, 0, 0, 0.87)!important"
          }
        }
      }
    },
    disabledColorLabel: {
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      }
    },
    error: {
      "& > label": {
        color: "#f44336!important"
      }
    },
    linkButton: {
      height: 36,
      width: 248,
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      borderRadius: 5,
      color: "#37474f",
      marginLeft: "auto",
      marginRight: 20
    },
    button: {
      color: "#0277bd",
      width: "100%",
      paddingBottom: 4
    },
    serviceCode: {
      display: "flex",
      justifyContent: "space-between"
    },
    serviceCodeInfo: {
      transform: "translate(20px) scale(0.75)",
      color: "rgba(0, 0, 0, 0.6)"
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  staffList: DisplayStaffsInFacilityState[];
  usersInFacility: UsersInFacilityState;
  serviceDelivery: ServiceDeliveryState["detailsRecord"];
  municipality: MunicipalityData;
  municipalityId: number | null;
  apiData: InoutResultsState | null;
  copyServiceDelivery: () => Promise<void>;
  openServiceCodesDialog: (type: "search" | "pagination") => void;
  onDeleteServiceCodes: (index: number) => void;
  groupOperationSupportFlg: boolean;
};

type State = {
  staffList: FieldItem[];
  isStatusNone: boolean;
  status: string;
  inputClass: string;
  numberOfParticipants: number;
  numberOfSupporters: number;
  practitioner1InTime: string;
  practitioner1OutTime: string;
  practitioner1OutTimeClass: string;
  practitioner1DetailTimeList: DetailTimeInfo[];
  isAddButton1: boolean;
  practitioner2InTime: string;
  practitioner2OutTime: string;
  practitioner2OutTimeClass: string;
  practitioner2DetailTimeList: DetailTimeInfo[];
  isAddButton2: boolean;
  licenseSameFlg: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const staffStateToApiObject = (
  staff:
    | InoutResultsState[
        | "practitioner1Staff"
        | "practitioner2Staff"
        | "practitioner3Staff"
        | "practitioner4Staff"
        | "practitioner5Staff"]
    | null
): Parameters<typeof createSnapshotOptions>[1] => {
  if (!staff) return null;
  return {
    id: staff.id,
    snapshot_name: staff.snapshotName
  };
};

/**
 * 対象番号のスタッフ情報を取得する
 * @param apiData 記録APIで取得した情報
 * @param staffNumber 対象番号(1 ~ 5)
 * @returns スタッフ情報 | null
 */
const getPractitioner: (
  apiData: InoutResultsState | null,
  num: number
) => Parameters<typeof createSnapshotOptions>[1] = (apiData, staffNumber) => {
  if (!apiData) return null;
  // グループ支援とそれ以外で支援者の持ち方が違う
  if (apiData.status === IDOSHIEN_STATUS_LIST.GROUP.value) {
    // グループ支援の場合
    switch (staffNumber) {
      case 1:
        return staffStateToApiObject(apiData.practitioner1Staff);
      case 2:
        return staffStateToApiObject(apiData.practitioner2Staff);
      case 3:
        return staffStateToApiObject(apiData.practitioner3Staff);
      case 4:
        return staffStateToApiObject(apiData.practitioner4Staff);
      case 5:
        return staffStateToApiObject(apiData.practitioner5Staff);
      default:
        return null;
    }
  } else {
    // グループ支援以外の場合
    switch (staffNumber) {
      case 1:
        return staffStateToApiObject(apiData.practitioner1Staff);
      case 2:
        return staffStateToApiObject(apiData.practitioner2Staff);
      default:
        return null;
    }
  }
};

class InOutReportDialogFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { initial } = this.props.formikProps.initialValues;
    this.state = {
      staffList: this.props.staffList
        ? [IDOSHIEN_BASE_LIST.NONE].concat(this.props.staffList)
        : [IDOSHIEN_BASE_LIST.NONE],
      status: initial.status,
      isStatusNone: initial.status === IDOSHIEN_STATUS_LIST.NONE.value,
      inputClass: initial.inputClass,
      numberOfParticipants: castNumber(initial.numberOfParticipants),
      numberOfSupporters: castNumber(initial.numberOfSupporters),
      practitioner1InTime: initial.practitioner1InTime,
      practitioner1OutTime: initial.practitioner1OutTime,
      practitioner1OutTimeClass: initial.practitioner1OutTimeClass,
      practitioner1DetailTimeList: initial.inoutResultsDetails1.map((row) => {
        return {
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton1: initial.inoutResultsDetails1.length < 10,
      practitioner2InTime: initial.practitioner2InTime,
      practitioner2OutTime: initial.practitioner2OutTime,
      practitioner2OutTimeClass: initial.practitioner2OutTimeClass,
      practitioner2DetailTimeList: initial.inoutResultsDetails2.map((row) => {
        return {
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton2: initial.inoutResultsDetails2.length < 10,
      licenseSameFlg: initial.licenseSameFlg
    };
  }

  private getInitDetailTime = (): DetailTimeInfo => {
    return {
      time: 0,
      inTime: "",
      inTimeClass: "",
      outTime: "",
      outTimeClass: ""
    };
  };

  private onAddDetailRecord1 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner1DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner1DetailTimeList: list,
        isAddButton1: list.length < 10
      };
    });
  };

  private onAddDetailRecord2 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner2DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner2DetailTimeList: list,
        isAddButton2: list.length < 10
      };
    });
  };

  private onDeleteDetailRecord1 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner1DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner1DetailTimeList: list, isAddButton1: true };
      },
      () => {
        this.setPersonalCalculatedHours1();

        if (
          this.state.numberOfParticipants <= 1 ||
          this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
          this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
        )
          return;
        this.setAllCalculatedHours();
      }
    );
  };

  private onDeleteDetailRecord2 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner2DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner2DetailTimeList: list, isAddButton2: true };
      },
      () => {
        this.setAllCalculatedHours();
      }
    );
  };

  private onOpenFilterDialog = (): void => {
    this.props.openServiceCodesDialog("pagination");
  };

  private onOpenDialog = (): void => {
    this.props.openServiceCodesDialog("search");
  };

  private onChangeDetailRecord1 = (
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner1DetailTimeList: prevState.practitioner1DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours1(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList
        );

        if (
          this.state.numberOfParticipants <= 1 ||
          this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
          this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
        )
          return;
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList
        );
      }
    );
  };

  private onChangeDetailRecord2 = (
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner2DetailTimeList: prevState.practitioner2DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList,
          this.state.practitioner2InTime,
          this.state.practitioner2OutTime,
          this.state.practitioner2OutTimeClass,
          this.state.practitioner2DetailTimeList
        );
      }
    );
  };

  private setPersonalCalculatedHours1 = (
    inTime: string = this.state.practitioner1InTime,
    outTime: string = this.state.practitioner1OutTime,
    outTimeClass: string = this.state.practitioner1OutTimeClass,
    list: DetailTimeInfo[] = this.state.practitioner1DetailTimeList
  ): void => {
    this.setPersonalCalculatedHours(
      "initial.practitioner1CalculatedHours",
      inTime,
      outTime,
      outTimeClass,
      list
    );
  };

  private setPersonalCalculatedHours = (
    target: string,
    inTime: string,
    outTime: string,
    outTimeClass: string,
    otherTimeList: DetailTimeInfo[]
  ): void => {
    const baseTime = calculateForRequiredTime(
      inTime,
      TIME_CLASS.TODAY,
      outTime,
      outTimeClass
    );
    const otherTime = otherTimeList.reduce((sum, value) => {
      return sum + value.time;
    }, 0);

    const totalTime = baseTime - otherTime;
    const hour = Math.floor(totalTime / 60);
    const minute = totalTime % 60;
    this.props.formikProps.setFieldValue(
      "initial.vehicleCalculatedHours",
      Number.isNaN(hour) ? "" : hour
    );
    this.props.formikProps.setFieldValue(
      "initial.vehicleCalculatedMinute",
      Number.isNaN(minute) ? "" : minute
    );

    if (!this.props.municipality.timeDivisionFlg) {
      const result = calculateHours(
        baseTime,
        this.props.municipality.roundUpMinute,
        otherTime
      );
      this.props.formikProps.setFieldValue(
        target,
        Number.isNaN(result) ? "" : result
      );
    } else {
      // 時間区分あり
      const baseTimeOfEachTimeframe = calculateTheRequiredTimeOfEachTimeframe(
        inTime,
        outTime,
        outTimeClass,
        otherTimeList.map((x) => ({
          inTime: x.inTime,
          inTimeClass: x.inTimeClass,
          outTime: x.outTime,
          outTimeClass: x.outTimeClass
        }))
      );
      const daytime = calculateHours(
        baseTimeOfEachTimeframe.daytime,
        this.props.municipality.roundUpMinute
      );
      const nighttime = calculateHours(
        baseTimeOfEachTimeframe.nighttime,
        this.props.municipality.roundUpMinute
      );
      const lateNight = calculateHours(
        baseTimeOfEachTimeframe.lateNight,
        this.props.municipality.roundUpMinute
      );
      const earlyMorning = calculateHours(
        baseTimeOfEachTimeframe.earlyMorning,
        this.props.municipality.roundUpMinute
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursDaytime",
        Number.isNaN(daytime) ? "" : daytime
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursNighttime",
        Number.isNaN(nighttime) ? "" : nighttime
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursLateNight",
        Number.isNaN(lateNight) ? "" : lateNight
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursEarlyMorning",
        Number.isNaN(earlyMorning) ? "" : earlyMorning
      );
    }
  };

  private handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    let start1 = this.state.practitioner1InTime;
    let end1 = this.state.practitioner1OutTime;
    let end1Class = this.state.practitioner1OutTimeClass;
    let start2 = this.state.practitioner2InTime;
    let end2 = this.state.practitioner2OutTime;
    let end2Class = this.state.practitioner2OutTimeClass;
    switch (event.target.name) {
      case "initial.practitioner1InTime":
        start1 = formatTime(event.target.value);
        this.setState({
          practitioner1InTime: start1
        });
        this.setPersonalCalculatedHours1(start1, end1, end1Class);
        break;
      case "initial.practitioner1OutTime":
        end1 = formatTime(event.target.value);
        this.setState({
          practitioner1OutTime: end1
        });
        this.setPersonalCalculatedHours1(start1, end1, end1Class);
        break;
      case "initial.practitioner1OutTimeClass":
        end1Class = event.target.value;
        this.setState({
          practitioner1OutTimeClass: end1Class
        });
        this.setPersonalCalculatedHours1(start1, end1, end1Class);
        break;
      case "initial.practitioner2InTime":
        start2 = formatTime(event.target.value);
        this.setState({
          practitioner2InTime: start2
        });
        break;
      case "initial.practitioner2OutTime":
        end2 = formatTime(event.target.value);
        this.setState({
          practitioner2OutTime: end2
        });
        break;
      case "initial.practitioner2OutTimeClass":
        end2Class = event.target.value;
        this.setState({
          practitioner2OutTimeClass: end2Class
        });
        break;
      default:
    }

    if (
      this.state.numberOfParticipants <= 1 ||
      this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
      this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    )
      return;
    // 合算算定（全体/重複）
    this.setAllCalculatedHours(
      start1,
      end1,
      end1Class,
      this.state.practitioner1DetailTimeList,
      start2,
      end2,
      end2Class,
      this.state.practitioner2DetailTimeList
    );
  };

  private setAllCalculatedHours = (
    baseInTime1 = this.state.practitioner1InTime,
    baseOutTime1 = this.state.practitioner1OutTime,
    baseOutTime1Class = this.state.practitioner1OutTimeClass,
    baseDetail1 = this.state.practitioner1DetailTimeList,
    baseInTime2 = this.state.practitioner2InTime,
    baseOutTime2 = this.state.practitioner2OutTime,
    baseOutTime2Class = this.state.practitioner2OutTimeClass,
    baseDetail2 = this.state.practitioner2DetailTimeList,
    sameFlg = this.state.licenseSameFlg
  ): void => {
    if (
      this.state.numberOfSupporters === 1 ||
      this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
      this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    )
      return;
    const outTime1 = this.getTime(baseOutTime1, baseOutTime1Class);
    const detail1 = baseDetail1.map((record) => this.getDetailTime(record));

    // 同時実行ONの場合、提供者②は提供者①のコピーとする
    const inTime2 = !sameFlg
      ? this.getTime(baseInTime2, TIME_CLASS.TODAY)
      : baseInTime1;
    const outTime2 = !sameFlg
      ? this.getTime(baseOutTime2, baseOutTime2Class)
      : outTime1;
    const detail2 = !sameFlg
      ? baseDetail2.map((record) => this.getDetailTime(record))
      : detail1.slice();

    // 運転/空き時間の計算
    // （・全体 = 全体の提供時間 - 明細1 - 明細3）
    // （・重複 = 重複の提供時間 - 明細2）
    // 1.提供時間を重複範囲とそれ以外に分割
    const data = calculateOverlapTime(
      { inTime: baseInTime1, outTime: outTime1 },
      [{ inTime: inTime2, outTime: outTime2 }]
    );

    // 2.運転空き時間を、重複範囲内か外かでグループ分け（重複の境を跨ぐ場合は、分割）
    const detail =
      data && data.length > 0
        ? this.calcDetailCalculateTime(data[0], detail1.concat(detail2))
        : {
            total: detail1.concat(detail2),
            duplicate: [{ inTime: "", outTime: "" }]
          };

    if (!this.props.municipality.timeDivisionFlg) {
      // 提供時間の計算
      const baseData = this.calcAllCalculateTime([
        { inTime: baseInTime1, outTime: outTime1 },
        { inTime: inTime2, outTime: outTime2 }
      ]);

      // 重複範囲外の運転空き時間を計算（→明細1)
      const subtractedTotalData = this.calcAllCalculateTime(detail.total);
      // 重複範囲内の運転空き時間（全体・重複）を計算（→明細2・明細3)
      const subtractedDuplicateData = this.calcAllCalculateTime(
        detail.duplicate
      );

      const wholeData = calculateHours(
        baseData.whole,
        this.props.municipality.roundUpMinute,
        subtractedTotalData.whole + subtractedDuplicateData.duplicate
      );

      const duplicateData = sameFlg
        ? wholeData
        : calculateHours(
            baseData.duplicate,
            this.props.municipality.roundUpMinute,
            subtractedDuplicateData.whole
          );

      // 算定時間（全体）のセット
      this.props.formikProps.setFieldValue(
        "initial.wholeCalculatedHours",
        Number.isNaN(wholeData) ? "" : wholeData
      );
      // 算定時間（重複）のセット
      this.props.formikProps.setFieldValue(
        "initial.duplicateCalculatedHours",
        Number.isNaN(duplicateData) ? "" : duplicateData
      );
    } else {
      // 時間区分あり

      // 提供時間の計算
      const baseData = this.calcCalculateAllTimeOfEachTimeFrame(
        [
          { inTime: baseInTime1, outTime: outTime1 },
          { inTime: inTime2, outTime: outTime2 }
        ],
        {
          total: detail.total.map((x) => ({
            ...x,
            inTimeClass: TIME_CLASS.TODAY,
            outTimeClass: TIME_CLASS.TODAY
          })),
          duplicate: detail.duplicate.map((x) => ({
            ...x,
            inTimeClass: TIME_CLASS.TODAY,
            outTimeClass: TIME_CLASS.TODAY
          }))
        }
      );

      const wholeData = {
        daytime: 0,
        nighttime: 0,
        lateNight: 0,
        earlyMorning: 0
      };
      const duplicateData = {
        daytime: 0,
        nighttime: 0,
        lateNight: 0,
        earlyMorning: 0
      };

      Object.keys(wholeData).forEach((timeFrame) => {
        wholeData[timeFrame] = calculateHours(
          baseData.whole[timeFrame],
          this.props.municipality.roundUpMinute
        );
        duplicateData[timeFrame] = sameFlg
          ? wholeData[timeFrame]
          : calculateHours(
              baseData.duplicate[timeFrame],
              this.props.municipality.roundUpMinute
            );
      });

      // 算定時間（全体）のセット
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursDaytime",
        Number.isNaN(wholeData.daytime) ? "" : wholeData.daytime
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursNighttime",
        Number.isNaN(wholeData.nighttime) ? "" : wholeData.nighttime
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursLateNight",
        Number.isNaN(wholeData.lateNight) ? "" : wholeData.lateNight
      );
      this.props.formikProps.setFieldValue(
        "initial.calculatedHoursEarlyMorning",
        Number.isNaN(wholeData.earlyMorning) ? "" : wholeData.earlyMorning
      );
      // 算定時間（重複）のセット
      this.props.formikProps.setFieldValue(
        "initial.duplicateCalculatedHoursDaytime",
        Number.isNaN(duplicateData.daytime) ? "" : duplicateData.daytime
      );
      this.props.formikProps.setFieldValue(
        "initial.duplicateCalculatedHoursNighttime",
        Number.isNaN(duplicateData.nighttime) ? "" : duplicateData.nighttime
      );
      this.props.formikProps.setFieldValue(
        "initial.duplicateCalculatedHoursLateNight",
        Number.isNaN(duplicateData.lateNight) ? "" : duplicateData.lateNight
      );
      this.props.formikProps.setFieldValue(
        "initial.duplicateCalculatedHoursEarlyMorning",
        Number.isNaN(duplicateData.earlyMorning)
          ? ""
          : duplicateData.earlyMorning
      );
    }
  };

  // 提供時間の計算
  private calcAllCalculateTime = (
    list: TimeRange[]
  ): { whole: number; duplicate: number } => {
    if (list.length <= 0) return { whole: 0, duplicate: 0 };

    // [全体]の時間を算出
    const { result } = calculateTotalTime(list[0], list);
    const whole = calculateForRequiredTime(
      result.inTime,
      TIME_CLASS.TODAY,
      result.outTime,
      TIME_CLASS.TODAY
    );

    // [重複]の時間を算出
    const data = list
      .map((x, idx) =>
        calculateOverlapTime(
          x,
          list.filter((record, i) => i !== idx)
        )
      )
      .reduce((pre, current) => pre.concat(current), []);

    const duplicate =
      data.length > 0
        ? calculateForRequiredTime(
            data[0].inTime,
            TIME_CLASS.TODAY,
            data[0].outTime,
            TIME_CLASS.TODAY
          )
        : 0;

    return { whole, duplicate };
  };

  // 提供時間の計算(時間区分あり)
  private calcCalculateAllTimeOfEachTimeFrame = (
    list: TimeRange[],
    detail: {
      total: DetailTime[];
      duplicate: DetailTime[];
    }
  ): { whole: Timeframe; duplicate: Timeframe } => {
    const init = {
      daytime: 0,
      nighttime: 0,
      lateNight: 0,
      earlyMorning: 0
    };
    if (list.length <= 0) return { whole: init, duplicate: init };

    // [全体]の時間を算出
    const { result } = calculateTotalTime(list[0], list);
    const whole = calculateTheRequiredTimeOfEachTimeframe(
      result.inTime,
      result.outTime,
      TIME_CLASS.TODAY,
      detail.total
    );

    // [重複]の時間を算出
    const data = list
      .map((x, idx) =>
        calculateOverlapTime(
          x,
          list.filter((record, i) => i !== idx)
        )
      )
      .reduce((pre, current) => pre.concat(current), []);

    const duplicate =
      data.length > 0
        ? calculateTheRequiredTimeOfEachTimeframe(
            data[0].inTime,
            data[0].outTime,
            TIME_CLASS.TODAY,
            detail.duplicate
          )
        : init;

    return { whole, duplicate };
  };

  private calcDetailCalculateTime = (
    range: TimeRange,
    list: TimeRange[]
  ): {
    total: TimeRange[];
    duplicate: TimeRange[];
  } => {
    const total: TimeRange[] = [];
    const duplicate: TimeRange[] = [];

    const getHours = (time: string, timeClass = TIME_CLASS.TODAY): number => {
      const hours = castNumber(time.split(":")[0]);
      const minutes = castNumber(time.split(":")[1]);
      return (
        (timeClass === TIME_CLASS.NEXT_DAY ? hours + 24 : hours) * 60 + minutes
      );
    };

    const rit = getHours(range.inTime);
    const rot = getHours(range.outTime);

    list.forEach((x) => {
      const xit = getHours(x.inTime);
      const xot = getHours(x.outTime);
      if (rit <= xit && range.outTime >= x.outTime) {
        duplicate.push(x);
      } else if (rit <= xit && rot >= xit) {
        duplicate.push({ inTime: x.inTime, outTime: range.outTime });
        total.push({ inTime: range.outTime, outTime: x.outTime });
      } else if (rit <= xot && rot >= xot) {
        duplicate.push({ inTime: range.inTime, outTime: x.outTime });
        total.push({ inTime: x.inTime, outTime: range.inTime });
      } else if (rit >= xit && rot <= xot) {
        duplicate.push({ inTime: range.inTime, outTime: range.outTime });
        total.push({ inTime: x.inTime, outTime: range.inTime });
        total.push({ inTime: range.outTime, outTime: x.outTime });
      } else {
        total.push(x);
      }
    });

    // 重複空き時間の重複は重複している時間とは言えないので重複を消し、消した分を全体の空き時間に入れる
    const newDuplicate: TimeRange[] = [];
    duplicate.forEach((x, i) => {
      const restList = duplicate.splice(i + 1);
      const xit = getHours(x.inTime);
      const xot = getHours(x.outTime);
      restList.forEach((y) => {
        const yit = getHours(y.inTime);
        const yot = getHours(y.outTime);
        if (yit <= xit && yot >= xot) {
          total.push(x);
          newDuplicate.push(y);
        } else if (yit <= xit && yot >= xit) {
          total.push({ inTime: x.inTime, outTime: y.outTime });
          newDuplicate.push({ inTime: y.inTime, outTime: x.outTime });
        } else if (yit <= xot && yot >= xot) {
          total.push({ inTime: y.inTime, outTime: x.outTime });
          newDuplicate.push({ inTime: x.inTime, outTime: y.outTime });
        }
      });
    });

    return {
      total,
      duplicate: newDuplicate.length > 0 ? newDuplicate : duplicate
    };
  };

  private getTime = (time: string, timeClass: string): string => {
    // 日跨ぎしている場合、時間に+24する
    const times = time.split(":");

    return timeClass === TIME_CLASS.NEXT_DAY
      ? `${Number(times[0]) + 24}:${times[1]}`
      : time;
  };

  private getDetailTime = (detail: {
    inTime: string;
    inTimeClass: string;
    outTime: string;
    outTimeClass: string;
  }): TimeRange => {
    const inTime = this.getTime(detail.inTime, detail.inTimeClass);
    const outTime = this.getTime(detail.outTime, detail.outTimeClass);

    return { inTime, outTime };
  };

  private handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.changeStatus(e.target.value);
  };

  private changeStatus = (status: string): void => {
    const nextStatus = status;
    this.setState({ status: nextStatus });

    if (nextStatus === IDOSHIEN_STATUS_LIST.NONE.value) {
      this.setState({ isStatusNone: true });
    } else {
      this.setState({ isStatusNone: false });
    }

    this.setPersonalCalculatedHours1(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList
    );

    if (
      this.state.numberOfParticipants <= 1 ||
      this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
      this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    )
      return;
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2OutTime,
      this.state.practitioner2OutTimeClass,
      this.state.practitioner2DetailTimeList,
      this.state.licenseSameFlg
    );
  };

  private onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.formikProps.setFieldValue(e.target.name, e.target.checked);
  };

  private onChangeLicenseSameFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ licenseSameFlg: e.target.checked });

    this.setPersonalCalculatedHours1();

    if (
      this.state.numberOfParticipants <= 1 ||
      this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
      this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    )
      return;
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2OutTime,
      this.state.practitioner2OutTimeClass,
      this.state.practitioner2DetailTimeList,
      e.target.checked
    );
  };

  private handleChangeNumberOfParticipants = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      numberOfParticipants: castNumber(e.target.value)
    });
    if (e.target.value === IDOSHIEN_MEMBER_LIST.TWO.value) {
      this.setAllCalculatedHours();
    } else {
      this.setPersonalCalculatedHours1();
    }

    // 職員1人あたりの支援比率を更新
    if (this.state.numberOfSupporters && this.state.numberOfSupporters) {
      this.props.formikProps.setFieldValue(
        "initial.supportRatio",
        `1:${
          // 小数点第3位を切り捨てして小数点第2位まで表示
          Math.floor(
            (this.state.numberOfSupporters / castNumber(e.target.value)) * 100
          ) / 100
        }`
      );
    }
  };

  private handleChangeNumberOfSupporters = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    this.setState({
      numberOfSupporters: castNumber(event.target.value)
    });

    // 職員1人あたりの支援比率を更新
    if (Number.isNaN(castNumber(event.target.value))) {
      this.props.formikProps.setFieldValue("initial.supportRatio", "-");
    } else {
      this.props.formikProps.setFieldValue(
        "initial.supportRatio",
        `1:${
          // 小数点第3位を切り捨てして小数点第2位まで表示
          Math.floor(
            (castNumber(event.target.value) / this.state.numberOfParticipants) *
              100
          ) / 100
        }`
      );
    }
  };

  private onChangePractitioner = (): void => {
    this.setPersonalCalculatedHours1();

    if (
      this.state.numberOfParticipants <= 1 ||
      this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
      this.state.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    )
      return;
    this.setAllCalculatedHours();
  };

  // サービス提供記録から読み込む
  private onClickCopyServiceDelivery = async (): Promise<void> => {
    await this.props.copyServiceDelivery();
    this.setServiceDeliveryData(this.props.serviceDelivery);
  };

  // 読み込んだサービス提供記録を項目にセットする（入力済みでも上書きする）
  private setServiceDeliveryData = (
    data: ServiceDeliveryState["detailsRecord"]
  ): void => {
    const practitioner1 = data.serviceDeliveryRecordPractitioners[0];
    const targetDate = dateToLocalisedString(data.targetDate, "YYYYMMDD");
    const classFlg = practitioner1.endTime
      ? targetDate === dateToLocalisedString(practitioner1.endTime, "YYYYMMDD")
      : true;
    const result = {
      nameSei: data.nameSei,
      nameMei: data.nameMei,
      name: `${data.nameSei} ${data.nameMei}`,
      targetDate,
      status: data.status,
      statusText: data.statusText,
      numberOfParticipants: String(data.numberOfPractitioner),
      numberOfSupporters: data.numberOfSupporters
        ? String(data.numberOfSupporters)
        : "",
      licenseSameFlg: data.licenseSameFlg,
      practitioner1: practitioner1.practitioner
        ? String(practitioner1.practitioner.id)
        : "",
      practitioner1InTime: practitioner1.startTime
        ? convertTimeHHMM(parseDateString(practitioner1.startTime))
        : "",
      practitioner1OutTime: practitioner1.endTime
        ? convertTimeHHMM(parseDateString(practitioner1.endTime))
        : "",
      inoutResultsDetails1: practitioner1.serviceDeliveryRecordsPractitionerDetails.map(
        (detail) => this.convertInoutResultsDetails(detail, targetDate)
      ),
      practitioner2: practitioner1.practitioner2
        ? String(practitioner1.practitioner2.id)
        : "",
      practitioner3: practitioner1.practitioner3
        ? String(practitioner1.practitioner3.id)
        : "",
      practitioner4: practitioner1.practitioner4
        ? String(practitioner1.practitioner4.id)
        : "",
      practitioner5: practitioner1.practitioner5
        ? String(practitioner1.practitioner5.id)
        : "",
      physicalCareFlg: data.physicalCareFlg
    };

    Object.keys(result).forEach((key) => {
      this.props.formikProps.setFieldValue(`initial.${key}`, result[key]);
    });
    this.setState({
      numberOfParticipants: castNumber(result.numberOfParticipants),
      numberOfSupporters: castNumber(result.numberOfSupporters),
      practitioner1InTime: result.practitioner1InTime,
      practitioner1OutTime: result.practitioner1OutTime,
      practitioner1OutTimeClass: classFlg ? "0" : "1",
      practitioner1DetailTimeList: result.inoutResultsDetails1.map((row) => {
        return {
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      licenseSameFlg: result.licenseSameFlg
    });

    if (data.serviceDeliveryRecordPractitioners.length >= 2) {
      const practitioner2 = data.serviceDeliveryRecordPractitioners[1];
      const classFlg2 = practitioner2.endTime
        ? targetDate ===
          dateToLocalisedString(practitioner2.endTime, "YYYYMMDD")
        : true;
      const result2 = {
        practitioner2: practitioner2.practitioner
          ? String(practitioner2.practitioner.id)
          : "",
        practitioner2InTime: practitioner2.startTime
          ? convertTimeHHMM(parseDateString(practitioner2.startTime))
          : "",
        practitioner2OutTime: practitioner2.endTime
          ? convertTimeHHMM(parseDateString(practitioner2.endTime))
          : "",
        practitioner2OutTimeClass: classFlg2 ? "0" : "1",
        inoutResultsDetails2: practitioner2.serviceDeliveryRecordsPractitionerDetails.map(
          (detail) => this.convertInoutResultsDetails(detail, targetDate)
        )
      };
      Object.keys(result2).forEach((key) => {
        this.props.formikProps.setFieldValue(`initial.${key}`, result2[key]);
      });

      this.setState({
        practitioner2InTime: result2.practitioner2InTime,
        practitioner2OutTime: result2.practitioner2OutTime,
        practitioner2OutTimeClass: result2.practitioner2OutTimeClass,
        practitioner2DetailTimeList: result2.inoutResultsDetails2.map((row) => {
          return {
            time: row.time,
            inTime: row.inTime,
            inTimeClass: row.inTimeClass,
            outTime: row.outTime,
            outTimeClass: row.outTimeClass
          };
        })
      });
    }

    this.changeStatus(result.status);
  };

  private convertInoutResultsDetails = (
    data: {
      serviceDeliveryRecordPractitionerDetailsId: number | null;
      actionClass: number;
      startTime: string | null;
      endTime: string | null;
      isDelete: number;
    },
    targetDate: string
  ): InoutResultsDetailsFields => {
    const inTimeClassFlg = data.startTime
      ? targetDate === dateToLocalisedString(data.startTime, "YYYYMMDD")
      : true;
    const outTimeClassFlg = data.endTime
      ? targetDate === dateToLocalisedString(data.endTime, "YYYYMMDD")
      : true;
    return {
      id: data.serviceDeliveryRecordPractitionerDetailsId,
      actionClass: String(data.actionClass),
      inTimeClass: inTimeClassFlg ? "0" : "1",
      inTime: data.startTime
        ? convertTimeHHMM(parseDateString(data.startTime))
        : "",
      outTimeClass: outTimeClassFlg ? "0" : "1",
      outTime: data.endTime
        ? convertTimeHHMM(parseDateString(data.endTime))
        : "",
      time: calculateForRequiredTime(
        data.startTime ? convertTimeHHMM(parseDateString(data.startTime)) : "",
        inTimeClassFlg ? "0" : "1",
        data.endTime ? convertTimeHHMM(parseDateString(data.endTime)) : "",
        outTimeClassFlg ? "0" : "1"
      )
    };
  };

  private checkRequiredItem = (): boolean => {
    const practitioner1 =
      this.state.practitioner1InTime !== "" &&
      this.state.practitioner1OutTime !== "" &&
      this.state.practitioner1DetailTimeList.every(
        (x) => x.inTime !== "" && x.outTime !== ""
      );
    /*
     * 提供人数に関わらず一人分のみ時間を入力するものに関しては
     * 一人目の時間を入力していれば活性化
     */
    if (
      [
        IDOSHIEN_STATUS_LIST.GROUP.value,
        IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
      ].includes(this.state.status) ||
      this.state.licenseSameFlg
    ) {
      return practitioner1;
    }
    const practitioner2 =
      this.state.numberOfParticipants === 2
        ? this.state.practitioner2InTime !== "" &&
          this.state.practitioner2OutTime !== "" &&
          this.state.practitioner2DetailTimeList.every(
            (x) => x.inTime !== "" && x.outTime !== ""
          )
        : true;
    return (
      this.state.status !== IDOSHIEN_STATUS_LIST.NONE.value &&
      practitioner1 &&
      practitioner2
    );
  };

  public render(): JSX.Element {
    const onChangeTargetFlg = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      this.props.formikProps.setFieldValue(
        "initial.status",
        IDOSHIEN_STATUS_LIST.NONE.value
      );
      this.changeStatus(IDOSHIEN_STATUS_LIST.NONE.value);
      this.setState({ inputClass: e.target.value });
    };

    return (
      <FormGroup>
        <FormGroup className={this.props.classes.categoryGroup}>
          {this.props.formikProps.initialValues.initial.createFlg && (
            <FormikRadioButtons
              name="initial.inputClass"
              label=""
              options={IDOSHIEN_INPUT_CLASS_SELECT_LIST}
              style={{ marginTop: 0, marginLeft: 0 }}
              onChangeHook={onChangeTargetFlg}
            />
          )}
          <FormGroup row>
            <FormikSelect
              name="initial.status"
              label="サービス内容"
              required
              options={IDOSHIEN_STATUS_SELECT_LIST}
              size="smallMedium"
              onChangeHook={this.handleChangeStatus}
            />
            {this.props.groupOperationSupportFlg &&
              !this.props.formikProps.initialValues.initial.createFlg &&
              this.state.inputClass === "2" && (
                <div className={this.props.classes.linkButton}>
                  <Button
                    className={this.props.classes.button}
                    color="secondary"
                    onClick={this.onClickCopyServiceDelivery}
                    disabled={
                      this.props.formikProps.initialValues.initial
                        .serviceDeliveryRecordsId === null
                    }
                  >
                    サービス提供記録から読み込む
                  </Button>
                </div>
              )}
          </FormGroup>
          {this.state.status === IDOSHIEN_STATUS_LIST.OTHER.value && (
            <FormikTextField name="initial.statusText" size="largeMedium" />
          )}
          <FormikCheckbox
            name="initial.physicalCareFlg"
            label="身体介護"
            disabled={this.state.isStatusNone}
            style={{ marginBottom: 20 }}
          />
          {this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value ? (
            <>
              <FormikSelect
                name="initial.numberOfParticipants"
                label="提供人数"
                size="smallMedium"
                options={IDOSHIEN_MEMBER_SELECT_LIST}
                disabled={this.state.isStatusNone}
                onChangeHook={this.handleChangeNumberOfParticipants}
              />
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner1"
                  label="提供者 1人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 1)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={this.state.isStatusNone}
                  onChangeHook={this.onChangePractitioner}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner2"
                  label="提供者 2人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 2)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone ||
                    this.state.numberOfParticipants !== 2
                  }
                  onChangeHook={this.onChangePractitioner}
                />
              </FormGroup>
            </>
          ) : (
            <>
              <FormGroup row>
                <FormikSelect
                  name="initial.numberOfParticipants"
                  label="提供人数"
                  size="smallMedium"
                  options={
                    this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value
                      ? IDOSHIEN_MEMBER_SELECT_LIST
                      : IDOSHIEN_GROUP_MEMBER_SELECT_LIST
                  }
                  onChangeHook={this.handleChangeNumberOfParticipants}
                />
                <FormikTextField
                  name="initial.numberOfSupporters"
                  label="支援人数"
                  size="smallMedium"
                  maxLength={3}
                  endAdornmentLabel="人"
                  onChangeHook={this.handleChangeNumberOfSupporters}
                />
                <FormikTextField
                  name="initial.supportRatio"
                  label="職員1人あたりの支援比率"
                  size="smallMedium"
                  disabled
                  className={`${this.props.classes.supportRatio} ${this.props.classes.disabledColor}`}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner1"
                  label="提供者 1人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 1)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={this.state.isStatusNone}
                  onChangeHook={this.onChangePractitioner}
                />
                <FormikSelect
                  name="initial.practitioner2"
                  label="提供者 2人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 2)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone ||
                    this.state.numberOfParticipants < 2
                  }
                  onChangeHook={this.onChangePractitioner}
                />
                <FormikSelect
                  name="initial.practitioner3"
                  label="提供者 3人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 3)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone ||
                    this.state.numberOfParticipants < 3
                  }
                  onChangeHook={this.onChangePractitioner}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner4"
                  label="提供者 4人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 4)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone ||
                    this.state.numberOfParticipants < 4
                  }
                  onChangeHook={this.onChangePractitioner}
                />
                <FormikSelect
                  name="initial.practitioner5"
                  label="提供者 5人目"
                  size="smallMedium"
                  options={createSnapshotOptions(
                    this.state.staffList,
                    getPractitioner(this.props.apiData, 5)
                  )}
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone ||
                    this.state.numberOfParticipants < 5
                  }
                  onChangeHook={this.onChangePractitioner}
                />
              </FormGroup>
            </>
          )}
          {this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value &&
            this.state.status !==
              IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value && (
              <FormikCheckbox
                name="initial.licenseSameFlg"
                label="2人が同時にサービスを提供する"
                style={{ marginBottom: 20 }}
                disabled={
                  this.state.isStatusNone ||
                  this.state.numberOfParticipants !== 2
                }
                onChange={this.onChangeLicenseSameFlg}
              />
            )}
        </FormGroup>

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            サービス提供時間
            {this.state.numberOfParticipants === 2 &&
              !this.state.licenseSameFlg &&
              this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value &&
              this.state.status !==
                IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value &&
              " 1人目"}
          </FormLabel>
          <FormGroup row>
            <FormikTextField
              name="initial.practitioner1InTimeClass"
              label="開始時間"
              required
              style={{ width: 80 }}
              disabled
              disabledStyle={!this.state.isStatusNone}
              className={
                this.props.formikProps.errors &&
                this.props.formikProps.errors.initial &&
                this.props.formikProps.errors.initial.practitioner1InTime &&
                IN_TIME_VALIDATION_LIST.includes(
                  this.props.formikProps.errors.initial.practitioner1InTime
                )
                  ? this.props.classes.error
                  : this.props.classes.disabledColorLabel
              }
            />
            <FormikTime
              name="initial.practitioner1InTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              disabled={this.state.isStatusNone}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16, marginRight: 32 }}
              error={
                this.props.formikProps.errors &&
                this.props.formikProps.errors.initial &&
                this.props.formikProps.errors.initial.practitioner1InTime
                  ? IN_TIME_VALIDATION_LIST.includes(
                      this.props.formikProps.errors.initial.practitioner1InTime
                    )
                  : false
              }
            />
            <FormikSelect
              name="initial.practitioner1OutTimeClass"
              label="終了時間"
              required
              style={{ width: 80 }}
              options={TIME_CLASS_LIST}
              disabled={this.state.isStatusNone}
              onChangeHook={this.handleChangeTime}
              error={
                this.props.formikProps.errors &&
                this.props.formikProps.errors.initial &&
                this.props.formikProps.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikProps.errors.initial.practitioner1OutTime
                    )
                  : false
              }
            />
            <FormikTime
              name="initial.practitioner1OutTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              disabled={this.state.isStatusNone}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16 }}
              error={
                this.props.formikProps.errors &&
                this.props.formikProps.errors.initial &&
                this.props.formikProps.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikProps.errors.initial.practitioner1OutTime
                    )
                  : false
              }
            />
          </FormGroup>
          {!this.state.isStatusNone && (
            <FormGroup style={{ marginBottom: 20 }}>
              <UnitsFields
                formikProps={this.props.formikProps}
                status={this.state.status}
                unitKey="inoutResultsDetails1"
                onAddRecord={this.onAddDetailRecord1}
                onDeleteRecord={this.onDeleteDetailRecord1}
                onChangeTime={this.onChangeDetailRecord1}
                isAddButton={this.state.isAddButton1}
                list={this.state.practitioner1DetailTimeList}
              />
            </FormGroup>
          )}
          <FormikTextField
            name="initial.practitioner1Memo"
            label="備考"
            size="quarterSuperLong"
            disabled={this.state.isStatusNone}
          />
        </FormGroup>

        {this.state.numberOfParticipants === 2 &&
          !this.state.licenseSameFlg &&
          this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value &&
          this.state.status !==
            IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value && (
            <FormGroup
              className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
            >
              <FormLabel className={this.props.classes.categoryLabel}>
                サービス提供時間 2人目
              </FormLabel>
              {!this.state.licenseSameFlg && (
                <FormGroup row>
                  <FormikTextField
                    name="initial.practitioner2InTimeClass"
                    label="開始時間"
                    required
                    style={{ width: 80 }}
                    disabled
                    disabledStyle={!this.state.isStatusNone}
                    className={
                      this.props.formikProps.errors &&
                      this.props.formikProps.errors.initial &&
                      this.props.formikProps.errors.initial
                        .practitioner2InTime &&
                      IN_TIME_VALIDATION_LIST.includes(
                        this.props.formikProps.errors.initial
                          .practitioner2InTime
                      )
                        ? this.props.classes.error
                        : this.props.classes.disabledColorLabel
                    }
                  />
                  <FormikTime
                    name="initial.practitioner2InTime"
                    placeholder="00:00"
                    size="smallMedium"
                    maxLength={5}
                    disabled={this.state.isStatusNone}
                    onChangeHookTime={this.handleChangeTime}
                    style={{ marginTop: 16, marginRight: 32 }}
                    error={
                      this.props.formikProps.errors &&
                      this.props.formikProps.errors.initial &&
                      this.props.formikProps.errors.initial.practitioner2InTime
                        ? IN_TIME_VALIDATION_LIST.includes(
                            this.props.formikProps.errors.initial
                              .practitioner2InTime
                          )
                        : false
                    }
                  />
                  <FormikSelect
                    name="initial.practitioner2OutTimeClass"
                    label="終了時間"
                    required
                    style={{ width: 80 }}
                    options={TIME_CLASS_LIST}
                    disabled={this.state.isStatusNone}
                    onChangeHook={this.handleChangeTime}
                    error={
                      this.props.formikProps.errors &&
                      this.props.formikProps.errors.initial &&
                      this.props.formikProps.errors.initial.practitioner2OutTime
                        ? OUT_TIME_VALIDATION_LIST.includes(
                            this.props.formikProps.errors.initial
                              .practitioner2OutTime
                          )
                        : false
                    }
                  />
                  <FormikTime
                    name="initial.practitioner2OutTime"
                    placeholder="00:00"
                    size="smallMedium"
                    maxLength={5}
                    disabled={this.state.isStatusNone}
                    onChangeHookTime={this.handleChangeTime}
                    style={{ marginTop: 16 }}
                    error={
                      this.props.formikProps.errors &&
                      this.props.formikProps.errors.initial &&
                      this.props.formikProps.errors.initial.practitioner2OutTime
                        ? OUT_TIME_VALIDATION_LIST.includes(
                            this.props.formikProps.errors.initial
                              .practitioner2OutTime
                          )
                        : false
                    }
                  />
                </FormGroup>
              )}
              {!this.state.isStatusNone && !this.state.licenseSameFlg && (
                <FormGroup style={{ marginBottom: 20 }}>
                  <UnitsFields
                    formikProps={this.props.formikProps}
                    status={this.state.status}
                    unitKey="inoutResultsDetails2"
                    onAddRecord={this.onAddDetailRecord2}
                    onDeleteRecord={this.onDeleteDetailRecord2}
                    onChangeTime={this.onChangeDetailRecord2}
                    isAddButton={this.state.isAddButton2}
                    list={this.state.practitioner2DetailTimeList}
                  />
                </FormGroup>
              )}

              <FormikTextField
                name="initial.practitioner2Memo"
                label="備考"
                size="quarterSuperLong"
                disabled={this.state.isStatusNone}
              />
            </FormGroup>
          )}
        {this.props.municipality.timeDivisionFlg &&
        this.state.status !== IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ? (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              {this.state.numberOfParticipants !== 2
                ? "算定時間"
                : "全体の算定時間"}
            </FormLabel>
            <FormGroup row>
              <FormikTextField
                name="initial.calculatedHoursDaytime"
                label="日中"
                size="small"
                endAdornmentLabel="時間"
                style={{ width: 122 }}
                disabled
                className={this.props.classes.disabledColor}
              />
              <FormikTextField
                name="initial.calculatedHoursNighttime"
                label="夜間"
                size="small"
                endAdornmentLabel="時間"
                style={{ width: 122 }}
                disabled
                className={this.props.classes.disabledColor}
              />
              <FormikTextField
                name="initial.calculatedHoursLateNight"
                label="深夜"
                size="small"
                endAdornmentLabel="時間"
                style={{ width: 122 }}
                disabled
                className={this.props.classes.disabledColor}
              />
              <FormikTextField
                name="initial.calculatedHoursEarlyMorning"
                label="早朝"
                size="small"
                endAdornmentLabel="時間"
                style={{ width: 122 }}
                disabled
                className={this.props.classes.disabledColor}
              />
            </FormGroup>
            {this.state.numberOfParticipants === 2 &&
              this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value && (
                <>
                  <FormLabel className={this.props.classes.categoryLabel}>
                    2人が重複する算定時間
                  </FormLabel>
                  <FormGroup row>
                    <FormikTextField
                      name="initial.duplicateCalculatedHoursDaytime"
                      label="日中"
                      size="small"
                      endAdornmentLabel="時間"
                      style={{ width: 122 }}
                      disabled
                      className={this.props.classes.disabledColor}
                    />
                    <FormikTextField
                      name="initial.duplicateCalculatedHoursNighttime"
                      label="夜間"
                      size="small"
                      endAdornmentLabel="時間"
                      style={{ width: 122 }}
                      disabled
                      className={this.props.classes.disabledColor}
                    />
                    <FormikTextField
                      name="initial.duplicateCalculatedHoursLateNight"
                      label="深夜"
                      size="small"
                      endAdornmentLabel="時間"
                      style={{ width: 122 }}
                      disabled
                      className={this.props.classes.disabledColor}
                    />
                    <FormikTextField
                      name="initial.duplicateCalculatedHoursEarlyMorning"
                      label="早朝"
                      size="small"
                      endAdornmentLabel="時間"
                      style={{ width: 122 }}
                      disabled
                      className={this.props.classes.disabledColor}
                    />
                  </FormGroup>
                </>
              )}
          </FormGroup>
        ) : (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            {this.state.status ===
            IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ? (
              <>
                <FormLabel className={this.props.classes.categoryLabel}>
                  時間
                </FormLabel>
                <FormGroup row>
                  <FormikTextField
                    name="initial.vehicleCalculatedHours"
                    size="smallMedium"
                    endAdornmentLabel="時間"
                    disabled
                    className={this.props.classes.disabledColor}
                  />
                  <FormikTextField
                    name="initial.vehicleCalculatedMinute"
                    size="smallMedium"
                    endAdornmentLabel="分"
                    disabled
                    className={this.props.classes.disabledColor}
                  />
                </FormGroup>
              </>
            ) : (
              <>
                <FormLabel className={this.props.classes.categoryLabel}>
                  算定時間
                </FormLabel>
                <FormGroup row>
                  {(this.state.numberOfParticipants <= 1 ||
                    this.state.status === IDOSHIEN_STATUS_LIST.GROUP.value) && (
                    <FormikTextField
                      name="initial.practitioner1CalculatedHours"
                      label="算定時間"
                      size="smallMedium"
                      endAdornmentLabel="時間"
                      disabled
                      className={this.props.classes.disabledColor}
                    />
                  )}
                </FormGroup>
                <FormGroup row>
                  {this.state.numberOfParticipants === 2 &&
                    this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value && (
                      <FormikTextField
                        name="initial.wholeCalculatedHours"
                        label="全体の算定時間"
                        size="smallMedium"
                        endAdornmentLabel="時間"
                        disabled
                        className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                      />
                    )}
                  {this.state.numberOfParticipants === 2 &&
                    this.state.status !== IDOSHIEN_STATUS_LIST.GROUP.value && (
                      <FormikTextField
                        className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                        name="initial.duplicateCalculatedHours"
                        label="2人が重複する算定時間"
                        size="smallMedium"
                        endAdornmentLabel="時間"
                        disabled
                      />
                    )}
                </FormGroup>
              </>
            )}
          </FormGroup>
        )}
        {!this.props.formikProps.initialValues.initial.createFlg &&
          this.state.inputClass === "2" && (
            <FormGroup
              className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
            >
              <div
                className={`${this.props.classes.categoryLabel} ${this.props.classes.serviceCode}`}
              >
                <FormLabel>サービスコード</FormLabel>
                {this.props.municipalityId !== null ? (
                  <KnowbeButton
                    variant="contained"
                    color="secondary"
                    disabled={!this.checkRequiredItem()}
                    style={{ marginRight: 20 }}
                    onClick={this.onOpenDialog}
                  >
                    対象サービスコードを表示する
                  </KnowbeButton>
                ) : (
                  <div className={this.props.classes.serviceCodeInfo}>
                    利用者情報で自治体情報を選択してください
                  </div>
                )}
              </div>

              <FormGroup style={{ marginBottom: 20 }}>
                <ServiceCodesFields
                  onOpenDialog={this.onOpenFilterDialog}
                  onDeleteRecord={this.props.onDeleteServiceCodes}
                  disabledButton={!this.checkRequiredItem()}
                  municipalityId={this.props.municipalityId}
                  formikProps={this.props.formikProps}
                />
              </FormGroup>
            </FormGroup>
          )}
      </FormGroup>
    );
  }
}

export const InOutReportDialogFields = withStyles(styles)(
  InOutReportDialogFieldsCore
);
