import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";

// ui
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

// stores
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { initialValues } from "@initialize/mgr/KODOENGO/staffs/initialValues";
import { StaffValues } from "@interfaces/mgr/common/staff/staffValues";
import { StaffDataWithLicense } from "@stores/domain/mgr/common/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { validation } from "@initialize/mgr/KODOENGO/staffs/validation";
import { toEffectiveObject } from "@utils/object";

// formik
import {
  Formik,
  Form,
  FormikActions,
  FieldArray,
  ArrayHelpers,
  FormikProps
} from "formik";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";

import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import FormPaper from "@components/atoms/FormPaper";

const styles = createStyles({
  tableHeader: {
    backgroundColor: "#90a4ae",
    "& tr th": {
      fontSize: 14,
      lineHeight: "24px",
      color: "#fff",
      padding: "12px 0 12px 16px"
    },
    "& tr th:last-child": {
      paddingRight: 0
    }
  },
  tableRow: {
    "&:first-child td.gridcell": {
      paddingTop: 24
    }
  },
  cell: {
    fontSize: 16,
    padding: "0 0 4px 16px",
    borderBottom: "none",
    "&:last-child": {
      paddingRight: 0
    },
    "& div": {
      margin: 0
    },
    "& > div": {
      height: 52,
      width: "100%"
    }
  },
  roleCol: {
    width: 160,
    minWidth: 160,
    paddingLeft: 16
  },
  nameCol: {
    width: 256,
    minWidth: 256,
    paddingLeft: 16
  },
  deleteCol: {
    width: 32
  },
  deleteIcon: {
    minWidth: 24,
    padding: 0,
    color: "#0277bd",
    cursor: "pointer",
    "&:hover": {
      color: "rgb(1, 83, 132)",
      backgroundColor: "transparent"
    }
  },
  disabledIcon: {
    color: "rgba(0, 0, 0, 0.12)",
    pointerEvents: "none"
  },
  paperWrapper: {
    "& > section": {
      paddingBottom: 8,
      marginTop: 8
    }
  },
  wrapper: {
    height: 60,
    "& > div": {
      paddingRight: 16,
      paddingLeft: 16,
      minHeight: 60
    }
  },
  button: { width: 120 }
});

type DispatchProps = {
  fetchStaffs: () => void;
  postStaffs: (values: StaffValues["staffs"]) => void;
  deleteStaff: (id: number) => void;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  staffItems: StaffDataWithLicense[];
  isLoggedInSupport: boolean;
  needsStopHistory: boolean;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const StaffsFormCore = (props: Props): JSX.Element => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [deleteTargetId, setDeleteTargetId] = React.useState<number>(0);
  const [initialValuesState, setInitialValuesState] = React.useState<
    StaffValues
  >(initialValues());
  const [version, setVersion] = React.useState<number>(new Date().getTime());

  const unmount = React.useRef<boolean>(false);
  const [isFetchDone, setIsFetchDone] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async (): Promise<() => void> => {
      await props.fetchStaffs();
      if (!unmount.current) {
        setInitialValuesState(initialValues(props.staffItems));
        setVersion(new Date().getTime());
        setIsFetchDone(true);
      }
      return (): void => {
        unmount.current = true;
      };
    })();
  }, []);

  React.useEffect(() => {
    // 再取得時、formを更新
    if (isFetchDone) {
      setInitialValuesState(initialValues(props.staffItems));
    }
  }, [props.staffItems, isFetchDone]);

  const confirmDiscardFormChanges = (nextValues: StaffValues): void => {
    const hasChange = nextValues.staffs
      .filter((staff) => !staff.delete)
      .some((staff) => staff.dirty);
    if (props.needsStopHistory !== hasChange) {
      props.stopHistory(hasChange);
    }
  };

  const handleChangeField = (
    arrayHelpers: ArrayHelpers,
    form: FormikProps<StaffValues>,
    currentKey: number,
    fieldName: "roleName" | "staffName" | "staffLicenseName"
  ) => (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    const { value } = e.target;
    const {
      values: { staffs }
    } = form;

    const tmpStaffs = [...staffs];
    const currentStaff = { ...staffs[currentKey], [fieldName]: value };
    tmpStaffs[currentKey] = currentStaff;

    // 変更管理
    currentStaff.dirty = true;
    if (currentStaff.staffItemId) {
      currentStaff.dirty =
        currentStaff.roleName !==
          initialValuesState.staffs[currentKey].roleName ||
        currentStaff.staffName !==
          initialValuesState.staffs[currentKey].staffName ||
        currentStaff.staffLicenseName !==
          initialValuesState.staffs[currentKey].staffLicenseName;
    } else {
      currentStaff.dirty =
        currentStaff.roleName !== "" ||
        currentStaff.staffName !== "" ||
        currentStaff.staffLicenseName !== "";
    }
    if (currentStaff.dirty !== staffs[currentKey].dirty) {
      form.setFieldValue(`staffs[${currentKey}].dirty`, currentStaff.dirty);
    }

    if (currentKey === staffs.length - 1) {
      // 最終行がすべて入力済みなら行追加
      if (!!currentStaff.roleName && !!currentStaff.staffName) {
        const newStaff = initialValues().staffs[0];
        newStaff.key = currentStaff.key + 1;
        arrayHelpers.push(newStaff);
        tmpStaffs.push(newStaff);
      }
    } else if (
      !currentStaff.staffItemId &&
      !currentStaff.roleName &&
      !currentStaff.staffName &&
      !currentStaff.staffLicenseName
    ) {
      // 未入力になった行は削除
      form.setFieldValue(`staffs[${currentKey}].delete`, true);
      currentStaff.delete = true;
    }

    // 変更管理
    confirmDiscardFormChanges({ staffs: tmpStaffs });
  };

  const handleClickDelete = (e: React.MouseEvent<SVGElement>): void => {
    if (!e.currentTarget.dataset.id) return;

    setOpenModal(true);
    setDeleteTargetId(parseInt(e.currentTarget.dataset.id, 10));
  };

  const handleCancel = (): void => {
    setOpenModal(false);
    setDeleteTargetId(0);
  };

  const validate = (values: StaffValues): object | undefined => {
    const { staffs = [] } = validation(values);
    const errors = staffs.map((res = {}) => toEffectiveObject(res));
    return errors.filter((e) => e).length === 0 ? {} : { staffs: errors };
  };

  const onSubmit = async (
    values: StaffValues,
    actions: FormikActions<StaffValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    // 未削除、変更のある行のみを更新
    await props.postStaffs(
      values.staffs.filter(
        (staff) => !staff.delete && !!staff.roleName && staff.dirty
      )
    );
    await props.fetchStaffs();
    setVersion(new Date().getTime());
    actions.setSubmitting(false);
  };

  const onDelete = async (): Promise<void> => {
    if (deleteTargetId === 0) return;
    setOpenModal(false);
    setDeleteTargetId(0);
    await props.deleteStaff(deleteTargetId);
    await props.fetchStaffs();
    setVersion(new Date().getTime());
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const dialogMessage = (
    <span>
      この操作を実行するとデータが完全に削除され、復元できません。
      <br />
      よろしいですか？
    </span>
  );

  return (
    <Formik
      initialValues={initialValuesState}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <ContentHeader
            position="sticky"
            classes={{ wrapper: props.classes.wrapper }}
          >
            <ContentHeaderRight>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                errorAction={submitError}
                className={props.classes.button}
              />
            </ContentHeaderRight>
          </ContentHeader>
          <div className={props.classes.paperWrapper}>
            <FormPaper>
              <Table>
                <TableHead className={props.classes.tableHeader}>
                  <TableRow>
                    <TableCell className={props.classes.roleCol}>
                      役職
                    </TableCell>
                    <TableCell className={props.classes.nameCol}>
                      職員名
                    </TableCell>
                    <TableCell>資格名</TableCell>
                    <TableCell className={props.classes.deleteCol} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <FieldArray name="staffs" validateOnChange={false}>
                    {(arrayHelpers): JSX.Element => (
                      <>
                        {formikProps.values.staffs
                          .filter((staff) => !staff.delete)
                          .map((staff) => (
                            <TableRow
                              key={`${version}_${staff.key}`}
                              className={props.classes.tableRow}
                            >
                              <TableCell
                                className={`gridcell ${props.classes.cell}`}
                              >
                                <FormikTextField
                                  name={`staffs[${staff.key}].roleName`}
                                  maxLength={15}
                                  onChangeHook={handleChangeField(
                                    arrayHelpers,
                                    formikProps,
                                    staff.key,
                                    "roleName"
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                className={`gridcell ${props.classes.cell}`}
                              >
                                <FormikTextField
                                  name={`staffs[${staff.key}].staffName`}
                                  maxLength={20}
                                  onChangeHook={handleChangeField(
                                    arrayHelpers,
                                    formikProps,
                                    staff.key,
                                    "staffName"
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                className={`gridcell ${props.classes.cell}`}
                              >
                                <FormikTextField
                                  name={`staffs[${staff.key}].staffLicenseName`}
                                  maxLength={30}
                                  onChangeHook={handleChangeField(
                                    arrayHelpers,
                                    formikProps,
                                    staff.key,
                                    "staffLicenseName"
                                  )}
                                />
                              </TableCell>
                              <TableCell
                                className={`gridcell ${props.classes.cell}`}
                              >
                                {staff.staffItemId && (
                                  <DeleteOutlineIcon
                                    data-id={staff.staffItemId}
                                    onClick={handleClickDelete}
                                    className={`${props.classes.deleteIcon} ${
                                      props.needsStopHistory ||
                                      props.isLoggedInSupport
                                        ? props.classes.disabledIcon
                                        : ""
                                    }`}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </FieldArray>
                </TableBody>
              </Table>
            </FormPaper>
          </div>
          <ConfirmDialog
            isOpen={openModal}
            onDelete={onDelete}
            onCancel={handleCancel}
            title="職員情報を削除します"
            message={dialogMessage}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const staffDispatcher = dispatches.KODOENGO.staffDispatcher(dispatch);
  const uiDispatcher = dispatches.uiDispatch(dispatch);
  return {
    fetchStaffs: staffDispatcher.fetch,
    postStaffs: staffDispatcher.post,
    deleteStaff: staffDispatcher.deleteStaff,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatcher.snackbar(params),
    stopHistory: uiDispatcher.stopHistory
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  staffItems: state.KODOENGO.staff.staffItems,
  isLoggedInSupport: state.auth.isSupport,
  needsStopHistory: state.ui.needsStopHistory
});

export const StaffsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(StaffsFormCore));
