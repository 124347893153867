import { RecordUserDetailValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator from "@validator";

type RecordUserDetailErrors = {
  support: ValidationErrors<RecordUserDetailValues["support"]>;
};

const validation = (values: RecordUserDetailValues): RecordUserDetailErrors => {
  return {
    support: values.support
      ? values.support.map((sup) => ({
          company_name: validator(sup.company_name, {
            type: "checkCharacterLength",
            length: 255
          }),
          todays_purpose: validator(sup.todays_purpose, {
            type: "checkCharacterLength",
            length: 500
          }),
          life_current_issues: validator(sup.life_current_issues, {
            type: "checkCharacterLength",
            length: 2000
          }),
          life_support_content: validator(sup.life_support_content, {
            type: "checkCharacterLength",
            length: 2000
          }),
          office_current_issues: validator(sup.office_current_issues, {
            type: "checkCharacterLength",
            length: 2000
          }),
          office_support_content: validator(sup.office_support_content, {
            type: "checkCharacterLength",
            length: 2000
          }),
          usage_status_of_related_organizations: validator(
            sup.usage_status_of_related_organizations,
            {
              type: "checkCharacterLength",
              length: 2000
            }
          ),
          notices: validator(sup.notices, {
            type: "checkCharacterLength",
            length: 2000
          }),
          next_support_date: validator(sup.next_support_date, {
            type: "checkCharacterLength",
            length: 500
          }),
          next_support_method: validator(sup.next_support_method, {
            type: "checkCharacterLength",
            length: 500
          }),
          next_support_plans: validator(sup.next_support_plans, {
            type: "checkCharacterLength",
            length: 500
          }),
          staff_comment: validator(sup.staff_comment, {
            type: "checkCharacterLength",
            length: 2000
          })
        }))
      : []
  };
};

export default validation;
