import React, { useState } from "react";
import MuiTextField from "@components/molecules/MuiTextField";
import FormButtons from "@components/organisms/account/FormButtons";
import { PasswordCaution } from "@components/organisms/account/PasswordCaution";
import validator from "@validator";
import { UpdatePasswordParams } from "@stores/domain/account/type";

type OwnProps = {
  email: string;
  adminId?: string;
  errorType: string;
  needsStopHistory: boolean;
  currentPasswordLabel: string;
  currentPasswordPlaceholder: string;
  newPasswordLabel: string;
  newPasswordConfirmLabel: string;
  onClose: () => void;
  updatePassword: (params: UpdatePasswordParams) => void;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps;

export const PasswordForm = (props: Props): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordConfirmError, setNewPasswordConfirmError] = useState("");

  const confirmDiscardFormChanges = (): void => {
    if (!props.needsStopHistory) {
      props.stopHistory(true);
    }
  };

  const handleChangeCurrentPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setCurrentPassword(value);
    confirmDiscardFormChanges();
  };

  const handleChangeNewPassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setNewPassword(value);
    confirmDiscardFormChanges();
  };

  const handleBlurNewPassword = (): void => {
    const error =
      validator(newPassword, "password") ||
      //  モバイルパスワードの変更時のみ重複チェックのバリデーションを入れる
      (props.adminId &&
        validator(newPassword, {
          type: "passwordDuplication",
          adminId: props.adminId,
          mobileId: props.email
        }));
    setNewPasswordError(error || "");
    confirmDiscardFormChanges();
  };

  const handleChangeNewPasswordConfirm = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setNewPasswordConfirm(value);
  };

  const handleBlurNewPasswordConfirm = (): void => {
    const error = validator(newPasswordConfirm, {
      type: "passwordMatch",
      value: newPassword
    });
    setNewPasswordConfirmError(error || "");
  };

  const handleSubmit = async (): Promise<void> => {
    await props.updatePassword({
      email: props.email,
      password: currentPassword,
      new_password: newPassword,
      com_new_password: newPasswordConfirm
    });
    if (!props.errorType) {
      setCurrentPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
      props.stopHistory(false);
      props.onClose();
    }
  };

  return (
    <form>
      <MuiTextField
        label={props.currentPasswordLabel}
        placeholder={props.currentPasswordPlaceholder}
        type="password"
        autoComplete="new-password"
        required
        size="long"
        value={currentPassword}
        onChange={handleChangeCurrentPassword}
      />
      <MuiTextField
        label={props.newPasswordLabel}
        type="password"
        autoComplete="new-password"
        required
        value={newPassword}
        onChange={handleChangeNewPassword}
        onBlur={handleBlurNewPassword}
        error={newPasswordError.length !== 0}
        size="long"
        helperText={
          newPasswordError || "半角英字・数字・記号を組み合わせた8文字以上"
        }
        maxLength={100}
      />
      <MuiTextField
        label={props.newPasswordConfirmLabel}
        type="password"
        autoComplete="new-password"
        required
        size="long"
        value={newPasswordConfirm}
        onChange={handleChangeNewPasswordConfirm}
        onBlur={handleBlurNewPasswordConfirm}
        error={newPasswordConfirmError.length !== 0}
        helperText={newPasswordConfirmError || "新しいパスワードをもう一度入力"}
        maxLength={100}
      />
      <PasswordCaution />
      <FormButtons
        confirmButtonLabel="変更を確定する"
        flexCenter={false}
        disabled={
          !currentPassword ||
          !newPassword ||
          !newPasswordConfirm ||
          !!newPasswordError ||
          !!newPasswordConfirmError
        }
        handleSubmit={handleSubmit}
        handleCancel={props.onClose}
      />
    </form>
  );
};
