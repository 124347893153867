import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps, getIn } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import SubsidizedFields from "@components/v201910/organisms/mgr/common/users/items/SubsidizedFields";
import { FacilityState } from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { OptionInterface } from "@components/atoms/DropDown";
import { UpperLimitTable } from "@components/v201910/organisms/mgr/common/users/items/UpperLimitTable";
import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_TYPE_LIST,
  INCOME_KIND_LIST,
  UPLIMIT_CONT_ROLLED_BY_LIST,
  RESULT_OF_MANAGEMENT_LIST,
  SUPPLY_FOOD_SERVICE,
  SUPPLY_PICKUP_SERVICE,
  PICKUP_PREMISES_CASE_0,
  PICKUP_PREMISES_CASE_1,
  PICKUP_PREMISES_CASE_2,
  PICKUP_PREMISES_CASE_3,
  AGREED_BY_CONTRACT_LIST,
  DEFAULT_SELECT_VALUE
} from "@constants/variables";
import { UsersValues } from "@initialize/v201910/mgr/JIRITSUKUNRENSEIKATSU/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    groupDate: {
      marginLeft: 16
    },
    fieldWrapper: {
      position: "relative"
    },
    fieldWrapperFoods: {
      position: "relative",
      marginTop: 20
    },
    incomeKindType: {
      position: "absolute",
      top: 22,
      left: 140
    },
    supplyPickupInSameSiteService: {
      position: "absolute",
      top: 83,
      left: 293
    },
    checkOption: {
      color: "#666666",
      fontSize: "1.1rem"
    },
    payDaysAgreed: {
      width: 213,
      marginLeft: 30
    },
    businessNumberContract: {
      width: 245
    },
    def: {
      marginBottom: -18
    },
    date: {
      marginBottom: 12
    },
    formikActionLabel: {
      "&+span": {
        fontSize: 16
      }
    },
    agreedByContractFlg: {
      marginTop: 2,
      marginBottom: 8
    },
    fieldWrapperForm: {
      marginBottom: 16,
      "& > div": {
        marginBottom: 0
      }
    }
  });

interface OwnProps {
  formikProps: FormikProps<UsersValues>;
  isFetchDone?: boolean;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
}
type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  shouldFirstSetup: boolean;
  showIncomeKindType: boolean;
  showOfficeInfoTable: boolean;
  showUpperLimitYen: boolean;
  showAgreedByContractFlg: boolean;
  pickupPremisesCase: OptionInterface[];
  showPickupPremises: boolean;
}

class ServiceUseFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldFirstSetup: true,
      showIncomeKindType: true,
      showOfficeInfoTable: true,
      showUpperLimitYen: false,
      showAgreedByContractFlg: false,
      pickupPremisesCase: PICKUP_PREMISES_CASE_0,
      showPickupPremises: true
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): State | null {
    if (!prevState.shouldFirstSetup || !nextProps.isFetchDone) {
      return null;
    }

    const { serviceUse } = nextProps.formikProps.values;
    let pickupPremisesList = PICKUP_PREMISES_CASE_0;
    switch (serviceUse.defPickup) {
      case "1":
        pickupPremisesList = PICKUP_PREMISES_CASE_1;
        break;
      case "2":
        pickupPremisesList = PICKUP_PREMISES_CASE_2;
        break;
      case "3":
        pickupPremisesList = PICKUP_PREMISES_CASE_3;
        break;
      default:
    }
    return {
      shouldFirstSetup: false,
      showIncomeKindType: serviceUse.incomeKind === "1",
      showOfficeInfoTable: serviceUse.upperLimitControlledBy === "1",
      showUpperLimitYen: serviceUse.resultOfManagement === "3",
      showAgreedByContractFlg: serviceUse.agreedByContractFlg === "2",
      showPickupPremises: serviceUse.defPickup === "0",
      pickupPremisesCase: pickupPremisesList
    };
  }

  /**
   * 負担上限額が1の時、所得区分を表示
   */
  private onChangeIncomeKindHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showIncomeKindType: value === "1" });
  };

  /**
   * 契約支給量が選択されたら、日数と事業者記入欄番号を表示
   */
  private onChangeAgreedByContractHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showAgreedByContractFlg: value === "2" });
  };

  /**
   * 送迎サービスデフォルトの値によって、同一敷地内のセレクトリストを変更
   */
  private onChangePickupPremisesHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    let pickupPremisesList = PICKUP_PREMISES_CASE_0;
    switch (value) {
      case "1":
        pickupPremisesList = PICKUP_PREMISES_CASE_1;
        break;
      case "2":
        pickupPremisesList = PICKUP_PREMISES_CASE_2;
        break;
      case "3":
        pickupPremisesList = PICKUP_PREMISES_CASE_3;
        break;
      default:
    }
    this.setState({
      pickupPremisesCase: pickupPremisesList,
      showPickupPremises: value === "0"
    });
    this.props.setFormikFieldValue("serviceUse.pickupPremises", "0");
  };

  /**
   * 上限管理事業所あり：ONOFF切り替え時に値をリセット
   */
  private onChangeUpperLimitFacilityFlag = (): void => {
    if (
      getIn(this.props.formikProps.values, "serviceUse.upperLimitFacilityFlag")
    ) {
      this.props.setFormikFieldValue(
        "serviceUse.upperLimitFacilityFlag",
        false
      );
      this.props.setFormikFieldValue("serviceUse.upperLimitControlledBy", "1");
      this.props.setFormikFieldValue("serviceUse.resultOfManagement", "1");
      this.props.setFormikFieldValue("serviceUse.upperLimitYen", "");
      this.setState({
        showUpperLimitYen: false,
        showOfficeInfoTable: true
      });
      this.resetUpperLimitFacilityYenForm();
    } else {
      this.props.setFormikFieldValue("serviceUse.upperLimitFacilityFlag", true);
    }
  };

  private resetUpperLimitFacilityYenForm = (): void => {
    for (let i = 0; i < 4; i += 1) {
      const dataNumber = i !== 0 ? String(i + 1) : "";
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityNumber${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityName${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitTotalYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitUserLoadYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.uplimitUserLoadAfterAdjustmentYen${dataNumber}`,
        ""
      );
    }
  };

  /**
   * 管理事業所が1の時、総費用額と利用者負担額を表示
   */
  private onChangeUpperLimitControlledBy = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showOfficeInfoTable: value === "1" });
    this.resetUpperLimitFacilityYenForm();
  };

  /**
   * 管理結果が3の時、自事業所調整上限額を表示及び管理結果後利用者負担額の値のリセット
   */
  private onChangeResultOfManagement = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showUpperLimitYen: value === "3" });
    if (value !== "3")
      ["", "2", "3", "4"].forEach((item) => {
        this.props.setFormikFieldValue(
          `serviceUse.uplimitUserLoadAfterAdjustmentYen${item}`,
          ""
        );
      });
  };

  /**
   * 利用日数に関わる特例あり：ONOFF切り替え時に値をリセット
   */
  private onChangeDayUseSpecialCaseFlg = (): void => {
    if (
      getIn(this.props.formikProps.values, "serviceUse.dayUseSpecialCaseFlag")
    ) {
      this.props.setFormikFieldValue("serviceUse.dayUseSpecialCaseFlag", false);
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseStartDate.year",
        DEFAULT_SELECT_VALUE
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseStartDate.month",
        ""
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseEndDate.year",
        DEFAULT_SELECT_VALUE
      );
      this.props.setFormikFieldValue(
        "serviceUse.dayUseSpecialCaseEndDate.month",
        ""
      );
    } else {
      this.props.setFormikFieldValue("serviceUse.dayUseSpecialCaseFlag", true);
    }
  };

  public render(): JSX.Element {
    const startAddYearTo = 1;
    const endAddYearTo = 5;
    return (
      <FormPaper>
        <div style={{ marginBottom: 32 }}>
          <SectionTitle label="サービス利用詳細" />
        </div>
        <Typography className={this.props.classes.date}>
          サービス提供期間
        </Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceStartDate"
            label="サービス提供開始日"
            style={{ marginBottom: 12 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceEndDate"
            label="サービス提供終了日"
            addYearTo={endAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <FormikCheckbox
          name="serviceUse.sameCorporationMovementFlg"
          label="同一法人の事業所からの受け入れ利用者（初期加算対象外）"
          className={this.props.classes.formikActionLabel}
        />
        <Typography className={this.props.classes.date}>受給者証</Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payStartDate"
            label="支給決定開始日 "
            style={{ marginBottom: 12 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payEndDate"
            label="支給決定終了日"
            addYearTo={endAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <FormGroup>
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.disabilityClass"
              label="障害区分"
              options={DISABILITY_CLASS_LIST}
            />
          </div>
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.agreedByContractFlg"
              label="契約支給量"
              options={AGREED_BY_CONTRACT_LIST}
              onChangeHook={this.onChangeAgreedByContractHook}
              className={this.props.classes.agreedByContractFlg}
            />
            <FormGroup row>
              {this.state.showAgreedByContractFlg && (
                <FormikTextField
                  className={this.props.classes.payDaysAgreed}
                  name="serviceUse.payDaysAgreed"
                  label="日数"
                  placeholder="00"
                  maxLength={2}
                  endAdornmentLabel="日"
                />
              )}
              {this.state.showAgreedByContractFlg && (
                <FormikTextField
                  className={this.props.classes.businessNumberContract}
                  name="serviceUse.businessNumberContract"
                  label="事業者記入欄番号"
                  placeholder="00"
                  maxLength={2}
                />
              )}
            </FormGroup>
          </div>
          <div className={this.props.classes.fieldWrapper}>
            <FormikSwitch
              name="serviceUse.dayUseSpecialCaseFlag"
              label="利用日数に関わる特例あり"
              tooltip={
                <HelpToolTip
                  title={<HelpTipMessages name="special_case_flg" />}
                />
              }
              onChange={this.onChangeDayUseSpecialCaseFlg}
              className={this.props.classes.formikActionLabel}
              style={{ marginLeft: 16 }}
            >
              <FormGroup>
                <FormikSelectYearMonth
                  name="serviceUse.dayUseSpecialCaseStartDate"
                  label="対象期間（開始）"
                  overrideYearFrom={2016}
                  addYearTo={1}
                  style={{ marginBottom: 16 }}
                />
                <FormikSelectYearMonth
                  name="serviceUse.dayUseSpecialCaseEndDate"
                  label="対象期間（終了）"
                  overrideYearFrom={2016}
                  addYearTo={1}
                  style={{ marginBottom: 16 }}
                />
              </FormGroup>
            </FormikSwitch>
          </div>
          <FormikCheckbox name="serviceUse.blindnessFlg" label="視覚障害" />
          <div className={this.props.classes.fieldWrapper}>
            <FormikRadioButtons
              name="serviceUse.incomeKind"
              label="負担上限額"
              options={INCOME_KIND_LIST}
              onChangeHook={this.onChangeIncomeKindHook}
            />
            <div className={this.props.classes.incomeKindType}>
              {this.state.showIncomeKindType && (
                <FormikSelect
                  name="serviceUse.incomeKindType"
                  label="所得区分"
                  options={INCOME_KIND_TYPE_LIST}
                />
              )}
            </div>
          </div>
        </FormGroup>
        <SubsidizedFields formikProps={this.props.formikProps} />
        <FormikSwitch
          name="serviceUse.upperLimitFacilityFlag"
          label="上限管理事業所あり"
          onChange={this.onChangeUpperLimitFacilityFlag}
        >
          <div className={this.props.classes.fieldWrapperForm}>
            <FormikSelect
              name="serviceUse.upperLimitControlledBy"
              label="管理事業所"
              options={UPLIMIT_CONT_ROLLED_BY_LIST}
              onChangeHook={this.onChangeUpperLimitControlledBy}
            />
          </div>
          {!this.state.showOfficeInfoTable && (
            <FormGroup row className={this.props.classes.fieldWrapperForm}>
              <FormikTextField
                name="serviceUse.upperLimitFacilityNumber"
                label="事業所番号"
                maxLength={10}
                placeholder="0000000000"
              />
              <FormikTextField
                name="serviceUse.upperLimitFacilityName"
                label="事業所名"
              />
            </FormGroup>
          )}
          <FormikRadioButtons
            name="serviceUse.resultOfManagement"
            label="管理結果"
            options={RESULT_OF_MANAGEMENT_LIST}
            onChangeHook={this.onChangeResultOfManagement}
            style={{ marginBottom: 0 }}
          />
          {this.state.showUpperLimitYen && (
            <FormikTextField
              name="serviceUse.upperLimitYen"
              label="自事業所　管理結果後利用者負担額"
              endAdornmentLabel="円"
              style={{
                margin: this.state.showOfficeInfoTable
                  ? "16px 0px 16px 52px"
                  : "16px 0px 32px 52px"
              }}
              maxLength={11}
              InputLabelProps={{
                style: {
                  width: "120%",
                  transform: "scale(0.75) translate(0, 1.5px)"
                }
              }}
            />
          )}
          {this.state.showOfficeInfoTable && (
            <UpperLimitTable formikProps={this.props.formikProps} />
          )}
        </FormikSwitch>
        <FormikSwitch
          name="serviceUse.createSupportPlanFlag"
          label="個別支援計画未作成"
        >
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.notCreateSupportPlanStartDate"
            label="未作成期間開始日"
            style={{ marginBottom: 0 }}
            addYearTo={startAddYearTo}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </FormikSwitch>
        <FormikCheckbox
          name="serviceUse.individualTrainingImplementationPlanFlg"
          label="個別訓練支援計画作成済み"
        />
        <FormikCheckbox
          name="serviceUse.socialLifeSupportFlg"
          label="社会生活支援特別加算"
        />

        <div className={this.props.classes.fieldWrapperFoods}>
          <Typography className={this.props.classes.def}>
            食事提供サービス デフォルト*
          </Typography>
          <div className={this.props.classes.groupDate}>
            <FormikSelect
              name="serviceUse.defFood"
              label=""
              options={SUPPLY_FOOD_SERVICE}
              disabled={!this.props.facility.mealSaservedServiceFlag}
            />
          </div>
          <Typography className={this.props.classes.def}>
            送迎サービス デフォルト*
          </Typography>
          <div className={this.props.classes.groupDate}>
            <FormikSelect
              name="serviceUse.defPickup"
              label=""
              options={SUPPLY_PICKUP_SERVICE}
              disabled={!this.props.facility.transferServiceFlag}
              onChangeHook={this.onChangePickupPremisesHook}
            />
          </div>
          <div className={this.props.classes.supplyPickupInSameSiteService}>
            <Typography className={this.props.classes.def}>
              同一敷地内送迎 デフォルト*
            </Typography>
            <div className={this.props.classes.groupDate}>
              <FormikSelect
                name="serviceUse.pickupPremises"
                label=""
                options={this.state.pickupPremisesCase}
                disabled={
                  !this.props.facility.transferServiceFlag ||
                  this.state.showPickupPremises
                }
              />
            </div>
          </div>
        </div>
        <Typography className={this.props.classes.checkOption}>
          *
          事業所設定の「実施オプションサービス」にチェックを入れると設定ができます。
        </Typography>
      </FormPaper>
    );
  }
}

export default withStyles(styles)(ServiceUseFields);
