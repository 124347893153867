import * as React from "react";
// ui
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryTimeField } from "@components/organisms/record/serviceDelivery/ServiceDeliveryTimeField";
import { ServiceDeliveryTimeField as ServiceDeliveryTimeFieldMobile } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryTimeField";
// formik
import { FormikProps } from "formik";
import {
  ServiceDeliveryDetailValues,
  DetailsInitialValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import {
  STANDARD_TIME_VALUE,
  PRACTITIONER_SNAPSHOT_OPTION_VALUE,
  KYOTAKUKAIGO_STATUS_LIST,
  KYOTAKUKAIGO_MEMBER_LIST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import {
  DAY_SELECT_OPTIONS,
  DAY_SELECT_TYPE,
  DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER,
  FacilityType
} from "@constants/variables";
import { JUDOHOMONKAIGO_ACTION_CLASS_LIST_NUMBER } from "@constants/mgr/JUDOHOMONKAIGO/variables";
// utils
import { calculateForRequiredTimeNoReverse } from "@utils/domain/mgr/calculateForRequiredTimeNoReverse";
import { calculateHours } from "@utils/domain/mgr/calculateHours";
import { isNaN } from "lodash-es";
import checkTime from "@validator/rules/checkTime";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { checkTimeFutureHHMM } from "@validator/rules/checkTimeFutureHHMM";
import FormikSelect from "@components/molecules/FormikSelect";
import castNumber from "@utils/dataNormalizer/castNumber";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import {
  CALCULATION_TIME_UPPER_LIMIT,
  DEFAULT_ROUND_UP_MINUTE
} from "@constants/mgr/IDOSHIEN/variables";

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerNum: number;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  staffOptions: FieldItem[];
  facilityType: FacilityType;
  isMobile: boolean;
  isEdit?: boolean;
};

type StateProps = {
  municipality: MunicipalitiesInFacilityState;
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchMunicipality: (id: string) => void;
  fetchUserInFacility: (uifId: string) => void;
};

type MergeProps = OwnProps & DispatchProps & StateProps;

export const ServiceDeliveryTimeContainerCore = (
  props: MergeProps
): JSX.Element => {
  const {
    practitionerNum,
    practitionerValues,
    formikProps,
    facilityType,
    fetchMunicipality,
    fetchUserInFacility
  } = props;
  const fieldName = `serviceDeliveryRecordPractitioners${practitionerNum}`;
  const detailsFieldName = `serviceDeliveryRecordPractitioners${practitionerNum}.serviceDeliveryRecordPractitionerDetails`;
  const formikValues = formikProps.values;

  const isEditable = !!formikValues.serviceDeliveryRecordsId;
  React.useEffect(() => {
    if (!formikValues.usersInFacilityId || isEditable) return;
    fetchUserInFacility(String(formikValues.usersInFacilityId));
  }, [formikValues.usersInFacilityId]);

  React.useEffect(() => {
    const municipalityId =
      props.userInFacility.user.user_in_facility_idoshien &&
      props.userInFacility.user.user_in_facility_idoshien.municipality_id;
    if (!municipalityId) return;
    fetchMunicipality(String(municipalityId));
  }, [props.userInFacility]);

  React.useEffect(() => {
    if (isEditable) return;
    const calculationTimeFlg =
      props.municipality.municipality.calculation_time_flg;
    const roundUpMinute =
      props.municipality.municipality.round_up_minute ||
      DEFAULT_ROUND_UP_MINUTE;
    props.setFormikFieldValue(
      "municipality.calculationTimeFlg",
      calculationTimeFlg
    );
    props.setFormikFieldValue("municipality.roundUpMinute", roundUpMinute);
  }, [props.municipality]);

  const getIdoshienTotalTime = (base: number, other: number): number => {
    const totalTime = base - other;
    if (facilityType !== FacilityType.IDOSHIEN || !formikValues.municipality)
      return totalTime;
    const { calculationTimeFlg, roundUpMinute } = formikValues.municipality;
    const roundUpMinuteValue =
      calculationTimeFlg && roundUpMinute !== null
        ? roundUpMinute
        : DEFAULT_ROUND_UP_MINUTE;
    const timeOfOver = totalTime % CALCULATION_TIME_UPPER_LIMIT;
    if (timeOfOver < roundUpMinuteValue) {
      return totalTime - timeOfOver;
    }
    return totalTime;
  };

  // 同行支援時間フィールド表示フラグ（重度のみ）
  const accompanyFieldDisplayFlg =
    props.facilityType === FacilityType.JUDOHOMONKAIGO &&
    formikValues.accompanySupportFlg &&
    formikValues.numberOfPractitioner === KYOTAKUKAIGO_MEMBER_LIST.ONE.value;

  // 当日翌日を考慮し、HH:MM形式の時間を00:01~47:59で表示する
  const formatTimeTodayOrNextDay = (time: string, dateNum: string): string => {
    // 00:00の形式でないものはそのまま返す
    if (dateNum === DAY_SELECT_TYPE.today || checkTime(time) || !time) {
      return time;
    }
    const times = time.split(":");
    const hour = Number(times[0]) + 24;
    return `${hour.toString()}:${times[1]}`;
  };

  // 移動時間数計算（重度のみ）
  const calculatedMovingHours = (
    detailsTimeList: DetailsInitialValues,
    baseInTime: string,
    baseOutTime: string,
    movingHoursFieldName: string
  ): void => {
    const movingHours = detailsTimeList.reduce((sum, value) => {
      if (
        value.isDelete ||
        // 移動時間のみ計算
        value.actionClass === JUDOHOMONKAIGO_ACTION_CLASS_LIST_NUMBER[1].value
      ) {
        return sum;
      }
      const inTimeDetails = formatTimeTodayOrNextDay(
        value.startTime,
        value.startTimeDate
      );
      // 基準となる時間未満の場合は基準の時間に設定する
      const inTimeDetailsValues = checkTimeFutureHHMM(inTimeDetails, baseInTime)
        ? baseInTime
        : inTimeDetails;
      const outTimeDetails = formatTimeTodayOrNextDay(
        value.endTime,
        value.endTimeDate
      );
      const outTimeDetailsValues = checkTimeFutureHHMM(
        outTimeDetails,
        baseOutTime
      )
        ? outTimeDetails
        : baseOutTime;
      const time = calculateForRequiredTimeNoReverse(
        inTimeDetailsValues,
        outTimeDetailsValues
      );
      return time ? sum + time : sum;
    }, 0);
    const result = calculateHours(
      movingHours,
      STANDARD_TIME_VALUE.HALF,
      0,
      STANDARD_TIME_VALUE.HALF
    );
    const resultText =
      result > 0 && !String(result).includes(".")
        ? `${result}.0`
        : String(result);
    props.setFormikFieldValue(
      `${fieldName}.${movingHoursFieldName}`,
      !result || isNaN(result) ? "" : resultText
    );
  };

  // 「開始時間・終了時間・運転/空き時間」から時間数計算
  const setPersonalCalculatedHours = (
    inTimeDate: string,
    inTime: string,
    outTimeDate: string,
    outTime: string,
    otherTimeList: DetailsInitialValues,
    facilityTypeParam: FacilityType,
    isAccompany = false // 自動計算される項目のフィールド名
  ): void => {
    // 自動計算される項目のフィールド名
    const numberOfTimeFieldName = isAccompany
      ? "calculatedAccompanySupportHours"
      : "numberOfTime";
    const nextStandardValue =
      props.facilityType === FacilityType.KYOTAKUKAIGO &&
      formikValues.status === KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK.value
        ? STANDARD_TIME_VALUE.QUARTER
        : STANDARD_TIME_VALUE.HALF;
    const inTimeValue = formatTimeTodayOrNextDay(inTime, inTimeDate);
    const outTimeValue = formatTimeTodayOrNextDay(outTime, outTimeDate);
    const baseTime = calculateForRequiredTimeNoReverse(
      inTimeValue,
      outTimeValue
    );
    if (facilityTypeParam === FacilityType.JUDOHOMONKAIGO) {
      // 自動計算される移動時間数のフィールド名
      const movingHoursFieldName = isAccompany
        ? "calculatedAccompanySupportMovingHours"
        : "calculatedMovingHours";
      calculatedMovingHours(
        otherTimeList,
        inTimeValue,
        outTimeValue,
        movingHoursFieldName
      );
    }
    if (baseTime) {
      const otherTime = otherTimeList.reduce((sum, value) => {
        if (
          value.isDelete ||
          // 重度の場合は「空き時間」のみ考慮して計算
          (facilityTypeParam === FacilityType.JUDOHOMONKAIGO &&
            value.actionClass ===
              JUDOHOMONKAIGO_ACTION_CLASS_LIST_NUMBER[0].value)
        ) {
          return sum;
        }
        // 開始時間または終了時間が存在しない場合は計算しない
        if (value.startTime.length === 0 || value.endTime.length === 0) {
          return sum;
        }
        const inTimeDetails = formatTimeTodayOrNextDay(
          value.startTime,
          value.startTimeDate
        );
        // 基準となる時間未満の場合は基準の時間に設定する
        const inTimeDetailsValues = checkTimeFutureHHMM(
          inTimeDetails,
          inTimeValue
        )
          ? inTimeValue
          : inTimeDetails;

        const outTimeDetails = formatTimeTodayOrNextDay(
          value.endTime,
          value.endTimeDate
        );
        // 基準となる時間を超える場合は基準の時間に設定する
        const outTimeDetailsValues = checkTimeFutureHHMM(
          outTimeDetails,
          outTimeValue
        )
          ? outTimeDetails
          : outTimeValue;
        const time = calculateForRequiredTimeNoReverse(
          inTimeDetailsValues,
          outTimeDetailsValues
        );
        return time ? sum + time : sum;
      }, 0);
      const result = calculateHours(
        baseTime,
        nextStandardValue,
        otherTime,
        STANDARD_TIME_VALUE.HALF,
        undefined,
        undefined,
        (base, other): number => getIdoshienTotalTime(base, other)
      );
      const resultText =
        result > 0 && !String(result).includes(".")
          ? `${result}.0`
          : String(result);

      props.setFormikFieldValue(
        `${fieldName}.${numberOfTimeFieldName}`,
        !result || isNaN(result) ? "" : resultText
      );
    } else {
      props.setFormikFieldValue(`${fieldName}.${numberOfTimeFieldName}`, "");
    }
  };

  // 「開始時間・終了時間・運転/空き時間」から同行支援の時間数
  const setAccompanySupportHours = (otherTime: DetailsInitialValues): void => {
    // 同行支援時間設定
    setPersonalCalculatedHours(
      practitionerValues.accompanySupportInTimeDate,
      practitionerValues.accompanySupportInTime,
      practitionerValues.accompanySupportOutTimeDate,
      practitionerValues.accompanySupportOutTime,
      otherTime,
      facilityType,
      true
    );
  };

  // 「当日・翌日」セレクトボックス変更時
  const handleChangeDateSelect = (
    event: React.ChangeEvent<HTMLSelectElement>,
    isAccompanyField = false
  ): void => {
    // 「同行支援時間」の場合はformikのフィールド名変更
    const start = isAccompanyField
      ? practitionerValues.accompanySupportInTime
      : practitionerValues.startTime;
    const end = isAccompanyField
      ? practitionerValues.accompanySupportOutTime
      : practitionerValues.endTime;
    const startTimeFieldName = isAccompanyField
      ? "accompanySupportInTime"
      : "startTime";
    const endTimeFieldName = isAccompanyField
      ? "accompanySupportOutTime"
      : "endTime";
    const otherTime =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;
    formikProps.setFieldTouched(`${fieldName}.${startTimeFieldName}`, true);
    formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}`, true);
    if (event.target.name === `${fieldName}.${startTimeFieldName}Date`) {
      formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}Date`, true);
      setPersonalCalculatedHours(
        event.target.value,
        start,
        isAccompanyField
          ? practitionerValues.accompanySupportOutTimeDate
          : practitionerValues.endTimeDate,
        end,
        otherTime,
        facilityType,
        isAccompanyField
      );
    } else {
      formikProps.setFieldTouched(`${fieldName}.startTimeDate`, true);
      setPersonalCalculatedHours(
        isAccompanyField
          ? practitionerValues.accompanySupportInTimeDate
          : practitionerValues.startTimeDate,
        start,
        event.target.value,
        end,
        otherTime,
        facilityType,
        isAccompanyField
      );
    }
  };

  // 「開始時間・終了時間」時間変更時
  const handleChangeInitialTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string,
    isAccompanyField = false
  ): void => {
    // 「同行支援時間」の場合はformikのフィールド名変更
    const startDate = isAccompanyField
      ? practitionerValues.accompanySupportInTimeDate
      : practitionerValues.startTimeDate;
    const endDate = isAccompanyField
      ? practitionerValues.accompanySupportOutTimeDate
      : practitionerValues.endTimeDate;
    const startTimeFieldName = isAccompanyField
      ? "accompanySupportInTime"
      : "startTime";
    const endTimeFieldName = isAccompanyField
      ? "accompanySupportOutTime"
      : "endTime";

    const otherTime =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;
    formikProps.setFieldTouched(`${fieldName}.${startTimeFieldName}Date`, true);
    formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}Date`, true);
    if (event.target.name === `${fieldName}.${startTimeFieldName}`) {
      formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}`, true);
      const end = isAccompanyField
        ? practitionerValues.accompanySupportOutTime
        : practitionerValues.endTime;
      setPersonalCalculatedHours(
        startDate,
        autoCorrectValue,
        endDate,
        end,
        otherTime,
        facilityType,
        isAccompanyField
      );
    } else {
      formikProps.setFieldTouched(`${fieldName}.startTime`, true);
      const start = isAccompanyField
        ? practitionerValues.accompanySupportInTime
        : practitionerValues.startTime;

      setPersonalCalculatedHours(
        startDate,
        start,
        endDate,
        autoCorrectValue,
        otherTime,
        facilityType,
        isAccompanyField
      );
    }
  };

  // 「運転・空き」「当日・翌日」セレクトボックス変更時(重度のみ)
  const onChangeActionClass = (index: number, actionClass: number): void => {
    if (facilityType === FacilityType.JUDOHOMONKAIGO) {
      const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
        (item, i) => {
          if (i === index) {
            return {
              serviceDeliveryRecordPractitionerDetailsId:
                item.serviceDeliveryRecordPractitionerDetailsId,
              actionClass,
              startTimeDate: item.startTimeDate,
              startTime: item.startTime,
              endTimeDate: item.endTimeDate,
              endTime: item.endTime,
              isDelete: item.isDelete
            };
          }
          return item;
        }
      );
      setPersonalCalculatedHours(
        practitionerValues.startTimeDate,
        practitionerValues.startTime,
        practitionerValues.endTimeDate,
        practitionerValues.endTime,
        otherTime,
        facilityType
      );
      if (accompanyFieldDisplayFlg) {
        // 同行支援時間設定
        setAccompanySupportHours(otherTime);
      }
    }
  };
  // 「運転・空き」「当日・翌日」セレクトボックス変更時
  const onChangeDetailDateSelect = (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ): void => {
    const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
      (item, i) => {
        if (i === index) {
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].startTime`,
            true
          );
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].endTime`,
            true
          );
          if (isStart) {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].endTimeDate`,
              true
            );
          } else {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].startTimeDate`,
              true
            );
          }
          return {
            serviceDeliveryRecordPractitionerDetailsId:
              item.serviceDeliveryRecordPractitionerDetailsId,
            actionClass: item.actionClass,
            startTimeDate: startDate,
            startTime: item.startTime,
            endTimeDate: endDate,
            endTime: item.endTime,
            isDelete: item.isDelete
          };
        }
        return item;
      }
    );
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      otherTime,
      facilityType
    );
    if (accompanyFieldDisplayFlg) {
      // 同行支援時間設定
      setAccompanySupportHours(otherTime);
    }
  };

  // 「運転・空き」時間変更時
  const onChangeDetailRecord = (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ): void => {
    const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
      (item, i) => {
        if (i === index) {
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].startTimeDate`,
            true
          );
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].endTimeDate`,
            true
          );
          if (isStart) {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].endTime`,
              true
            );
          } else {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].startTime`,
              true
            );
          }
          return {
            serviceDeliveryRecordPractitionerDetailsId:
              item.serviceDeliveryRecordPractitionerDetailsId,
            actionClass: item.actionClass,
            startTimeDate: item.startTimeDate,
            startTime: start,
            endTimeDate: item.endTimeDate,
            endTime: end,
            isDelete: item.isDelete
          };
        }
        return item;
      }
    );
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      otherTime,
      facilityType
    );
    if (accompanyFieldDisplayFlg) {
      // 同行支援時間設定
      setAccompanySupportHours(otherTime);
    }
  };

  // 「運転/空き時間」削除時
  const deleteRecord = (index: number): void => {
    const removeDetailsValues = [
      ...practitionerValues.serviceDeliveryRecordPractitionerDetails
    ];
    removeDetailsValues.splice(index, 1);
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      removeDetailsValues,
      facilityType
    );
    if (accompanyFieldDisplayFlg) {
      // 同行支援時間設定
      setAccompanySupportHours(removeDetailsValues);
    }
  };

  // サービス提供者選択肢作成（スナップショット）
  const practitionerOptions = createSnapshotOptions(
    props.staffOptions,
    practitionerValues.practitionerSnapshot
  );

  // サービス提供者選択肢変更確認（スナップショット）
  const changePractitioner = (
    e: React.ChangeEvent<HTMLSelectElement>,
    numberOfIsSnapshot?: string
  ): void => {
    const practitionerOption = practitionerOptions.find(
      (item) => item.value === e.target.value
    );
    props.setFormikFieldValue(
      `${fieldName}.practitionerName${numberOfIsSnapshot || ""}`,
      practitionerOption ? practitionerOption.label : ""
    );
    // post時に変更があった値のみ送る処理の確認用
    if (e.target.value === PRACTITIONER_SNAPSHOT_OPTION_VALUE) {
      props.setFormikFieldValue(
        `${fieldName}.isSnapshot${numberOfIsSnapshot || ""}`,
        true
      );
    } else {
      props.setFormikFieldValue(
        `${fieldName}.isSnapshot${numberOfIsSnapshot || ""}`,
        false
      );
    }
  };

  React.useEffect(() => {
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      practitionerValues.serviceDeliveryRecordPractitionerDetails,
      facilityType
    );
  }, [formikValues.status]);

  // 提供人数「２」→「１」の時の対応（選択肢が””になるのを防ぐ）
  React.useEffect(() => {
    if (!practitionerValues.startTimeDate) {
      props.setFormikFieldValue(
        `${fieldName}.startTimeDate`,
        DAY_SELECT_OPTIONS[0].value
      );
    } else if (!practitionerValues.endTimeDate) {
      props.setFormikFieldValue(
        `${fieldName}.endTimeDate`,
        DAY_SELECT_OPTIONS[0].value
      );
    }
  }, [practitionerValues.startTimeDate, practitionerValues.endTimeDate]);

  // 同行支援フィールド非表示にエラーがある項目は初期値にする
  React.useEffect(() => {
    if (
      props.facilityType === FacilityType.JUDOHOMONKAIGO &&
      !accompanyFieldDisplayFlg &&
      props.formikProps.errors.serviceDeliveryRecordPractitioners1
    ) {
      const practitionersErrors =
        props.formikProps.errors.serviceDeliveryRecordPractitioners1;
      let errorFlg = false;
      if (practitionersErrors.accompanySupportInTime) {
        props.setFormikFieldValue(`${fieldName}.accompanySupportInTime`, "");
        errorFlg = true;
      }
      if (practitionersErrors.accompanySupportInTimeDate) {
        props.setFormikFieldValue(
          `${fieldName}.accompanySupportInTimeDate`,
          DAY_SELECT_OPTIONS[0].value
        );
        errorFlg = true;
      }
      if (practitionersErrors.accompanySupportOutTime) {
        props.setFormikFieldValue(`${fieldName}.accompanySupportOutTime`, "");
        errorFlg = true;
      }
      if (practitionersErrors.accompanySupportOutTimeDate) {
        props.setFormikFieldValue(
          `${fieldName}.accompanySupportOutTimeDate`,
          DAY_SELECT_OPTIONS[0].value
        );
        errorFlg = true;
      }
      if (errorFlg) {
        props.setFormikFieldValue(
          `${fieldName}.calculatedAccompanySupportHours`,
          ""
        );
        props.setFormikFieldValue(
          `${fieldName}.calculatedAccompanySupportMovingHours`,
          ""
        );
      }
    }
  }, [accompanyFieldDisplayFlg]);

  const isDriveLabel = [
    FacilityType.KYOTAKUKAIGO,
    FacilityType.DOKOENGO,
    FacilityType.IDOSHIEN
  ].includes(facilityType);

  // actionClassのラベル
  const actionClassLabel = isDriveLabel ? "運転" : "移動";

  // actionClassの選択肢
  const actionClassOptions = isDriveLabel
    ? DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER
    : JUDOHOMONKAIGO_ACTION_CLASS_LIST_NUMBER;

  const getPractitionerSelectElement = (
    numberOfIsSnapshot: string
  ): JSX.Element => {
    const isDisabled =
      castNumber(formikValues.numberOfPractitioner) <
      castNumber(numberOfIsSnapshot);
    return (
      <FormikSelect
        name={`serviceDeliveryRecordPractitioners1.practitioner${numberOfIsSnapshot}`}
        label={`サービス提供者${numberOfIsSnapshot}`}
        options={practitionerOptions}
        isSelectablePlaceholder
        placeholder="選択してください"
        emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
        onChangeHook={(e): void => changePractitioner(e, numberOfIsSnapshot)}
        disabled={isDisabled}
      />
    );
  };

  return props.isMobile ? (
    <ServiceDeliveryTimeFieldMobile
      isEdit={!!props.isEdit}
      formikProps={formikProps}
      facilityType={facilityType}
      practitionerNum={practitionerNum}
      practitionerValues={practitionerValues}
      accompanyFieldDisplayFlg={accompanyFieldDisplayFlg}
      fieldName={fieldName}
      handleChangeDateSelect={handleChangeDateSelect}
      handleChangeInitialTime={handleChangeInitialTime}
      onChangeActionClass={onChangeActionClass}
      onChangeDetailDateSelect={onChangeDetailDateSelect}
      onChangeDetailRecord={onChangeDetailRecord}
      deleteRecord={deleteRecord}
      practitionerOptions={practitionerOptions}
      changePractitioner={changePractitioner}
      actionClassLabel={actionClassLabel}
      actionClassOptions={actionClassOptions}
    />
  ) : (
    <ServiceDeliveryTimeField
      formikProps={formikProps}
      facilityType={facilityType}
      practitionerNum={practitionerNum}
      practitionerValues={practitionerValues}
      accompanyFieldDisplayFlg={accompanyFieldDisplayFlg}
      fieldName={fieldName}
      handleChangeDateSelect={handleChangeDateSelect}
      handleChangeInitialTime={handleChangeInitialTime}
      onChangeActionClass={onChangeActionClass}
      onChangeDetailDateSelect={onChangeDetailDateSelect}
      onChangeDetailRecord={onChangeDetailRecord}
      deleteRecord={deleteRecord}
      practitionerOptions={practitionerOptions}
      changePractitioner={changePractitioner}
      actionClassLabel={actionClassLabel}
      actionClassOptions={actionClassOptions}
      practitioner2SelectElement={getPractitionerSelectElement("2")}
      practitioner3SelectElement={getPractitionerSelectElement("3")}
      practitioner4SelectElement={getPractitionerSelectElement("4")}
      practitioner5SelectElement={getPractitionerSelectElement("5")}
    />
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  municipality: state.IDOSHIEN.municipalitiesInFacility,
  userInFacility: state.IDOSHIEN.userInFacility
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN } = dispatches;
  const municipalitiesInFacilityDispatches = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatches = IDOSHIEN.userInFacilityDispatcher(dispatch);
  return {
    fetchMunicipality: municipalitiesInFacilityDispatches.fetchOne,
    fetchUserInFacility: userInFacilityDispatches.fetchOne
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryTimeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ServiceDeliveryTimeContainerCore);
