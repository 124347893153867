import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 申請者の現状 (基本情報) の削除
 * @param uifId 事業所所属ユーザーのID
 * @param supportPlanBasicInfoId 申請者の現状 (基本情報)のID
 */
export const deleteBasicInfo = async (
  uifId: string,
  supportPlanBasicInfoId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/basic_info/${supportPlanBasicInfoId}`;
  return request.delete(url);
};
