import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetOperations = {
  data: {
    operations: {
      operation_records_id: number | null;
      target_date: string; // yyyy-mm-dd
      operation_in_the_morning: string;
      operation_in_the_afternoon: string;
      other_comment: string;
      staffs: {
        id: number;
        name: string;
        facility_id: number;
        snapshot_name: string;
      }[];
    }[];
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 指定年月の業務記録を取得する
 * @param year 年
 * @param month 月
 */
export const getOperations = async (
  year: string,
  month: string
): Promise<AxiosResponse<GetOperations>> => {
  const url = `${VERSION_URL_201910}/operations/${year}/${month}`;
  return request.get<GetOperations>(url);
};
