import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ConsultationState = {
  consultationRecord: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    targetFlg: 0,
    creationDate: "",
    author: null,
    monitoringPeriod: "",
    monitoringStartYm: "",
    planStartYm: "",
    classifyDisabilitySupport: 0,
    incomeKind: "",
    disabledWelfareServiceRecipientNumber: "",
    regularPlaceRecipientNumber: "",
    consultationSupportRecipientNumber: "",
    desiredLife: "",
    supportPolicy: "",
    longTermGoal: "",
    shortTermGoal: "",
    supportPlanConsultationDetails: [],
    supportPlanConsultationSchedule: [],
    mainActivities: "",
    nonWeeklyService: "",
    overallLife: "",
    staffComment: "",
    type_consultation: 1
  },
  copyType: "",
  consultationCopy: null
};

export const KEIKAKUSODANConsultation = (
  state = initialState,
  action: actions.ActionTypes
): types.ConsultationState => {
  switch (action.type) {
    case types.FETCH_CONSULTATION_STARTED:
      return { ...state };
    case types.FETCH_CONSULTATION_SUCCESS:
      return { ...state, consultationRecord: action.payload };
    case types.FETCH_CONSULTATION_FAILED:
      return { ...state };
    case types.COPY_CONSULTATION_STARTED:
      return { ...state };
    case types.COPY_CONSULTATION_SUCCESS:
      return {
        ...state,
        copyType: action.payload.copyType,
        consultationCopy: action.payload.res
      };
    case types.COPY_CONSULTATION_FAILED:
      return { ...state };
    case types.RESET_COPY_CONSULTATION:
      return {
        ...state,
        copyType: "",
        consultationCopy: null
      };
    case types.POST_CONSULTATION_STARTED:
      return { ...state };
    case types.POST_CONSULTATION_SUCCESS:
      return { ...state };
    case types.POST_CONSULTATION_FAILED:
      return { ...state };
    case types.DELETE_CONSULTATION_STARTED:
      return { ...state };
    case types.DELETE_CONSULTATION_SUCCESS:
      return { ...state };
    case types.DELETE_CONSULTATION_FAILED:
      return { ...state };
    case types.UNSET_CONSULTATION:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
