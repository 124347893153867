import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Header } from "@componentsMobile/organisms/mgr/Header";
import {
  Footer,
  MAX_FOOTER_HEIGHT,
  MIN_FOOTER_HEIGHT
} from "@componentsMobile/organisms/mgr/Footer";
import Typography from "@material-ui/core/Typography";
import { MgrDrawer } from "@componentsMobile/organisms/mgr/Drawer";
import { MOBILE_MIN_WIDTH } from "@constants/styles";
// eslint-disable-next-line import/extensions
import "@styles/mobileFont.css";
import * as classNames from "classnames";

export const HEADER_HEIGHT = "64px";

const styles = (): StyleRules =>
  createStyles({
    root: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      minHeight: "100vh",
      width: "100%",
      minWidth: MOBILE_MIN_WIDTH
    },
    contentsWrap: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: `calc(100vh - ${MIN_FOOTER_HEIGHT})`,
      "@media (max-width: 442px)": {
        minHeight: `calc(100vh - ${MAX_FOOTER_HEIGHT})`
      }
    },
    contents: {
      marginTop: HEADER_HEIGHT
    },
    pageName: {
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)",
      height: 48,
      padding: "8px 16px",
      backgroundColor: "#fff"
    },
    headerHide: {
      top: `-${HEADER_HEIGHT}`,
      boxShadow: "none"
    },
    headerShow: {
      top: 0
    },
    sticky: {
      position: "sticky"
    }
  });

type OwnProps = {
  nav?: { key: number; sticky?: boolean; top?: number; elm: JSX.Element }[];
  footerButton?: JSX.Element;
  children: React.ReactNode;
  pageName: string;
};
type Props = OwnProps & WithStyles<typeof styles>;

const AdminTemplate = (props: Props): JSX.Element => {
  const headerHeight = 64;
  const { classes, pageName, children, nav, footerButton } = props;
  const [isOpenDrawer, toggleDrawer] = React.useState(false);
  const [headerMove, setHeaderMove] = React.useState<string | undefined>(
    undefined
  );
  const [headerShowFlg, setHeaderShowFlg] = React.useState<boolean>(true);

  const getScrollBottom = (): number => {
    const documentBody = window.document.body;
    const html = window.document.documentElement;
    const scrollTop = documentBody.scrollTop || html.scrollTop;
    return html.scrollHeight - html.clientHeight - scrollTop;
  };

  React.useEffect(() => {
    let offset = 0;
    const listenScrollEvent = (): void => {
      if (offset < window.pageYOffset && window.pageYOffset > headerHeight) {
        setHeaderShowFlg(false);
        setHeaderMove(classes.headerHide);
      } else if (offset > window.pageYOffset && getScrollBottom() > 0) {
        setHeaderMove(classes.headerShow);
        setHeaderShowFlg(true);
      }
      offset = window.pageYOffset;
    };
    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  return (
    <div className={classes.root}>
      <div>
        <MgrDrawer isOpen={isOpenDrawer} toggleDrawer={toggleDrawer} />
        <div className={classes.contentsWrap}>
          <div className={classes.contents}>
            <Header toggleDrawer={toggleDrawer} move={headerMove} />
            <Typography variant="h6" className={classes.pageName}>
              <span>{pageName}</span>
            </Typography>
            {nav &&
              nav.map((n) => {
                return (
                  <div
                    key={`nav-${n.key}`}
                    className={
                      n.sticky ? classNames(classes.sticky) : undefined
                    }
                    style={{
                      top: headerShowFlg
                        ? headerHeight + (n.top || 0)
                        : n.top || 0,
                      zIndex: 10 - n.key
                    }}
                  >
                    {n.elm}
                  </div>
                );
              })}
            {children}
          </div>
          {footerButton}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withStyles(styles)(AdminTemplate);
