import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Button, Theme } from "@material-ui/core";
import { SupportProcedureFormTableRow } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureFormTableRow";
import { Add as AddIcon } from "@material-ui/icons";
// store
import { connect } from "react-redux";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import {
  supportProcedureFormDetailInitialValue,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    header: {
      display: "flex",
      height: "40px",
      padding: "0 16px",
      backgroundColor: "#90a4ae",
      color: "#fff",
      fontSize: "12px",
      alignItems: "center",
      gap: "16px"
    },
    dateCell: {
      width: 72
    },
    activityCell: {
      width: 180
    },
    serviceProcedureCell: {
      flexGrow: 1
    },
    iconButtonCell: {
      width: 28,
      textAlign: "center",
      padding: 0
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT24: {
      marginTop: spacing.unit * 3
    }
  });

type OwnProps = {
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  isRecord: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureFormTableCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { formikProps } = props;
  const { supportProcedureFormDetails } = formikProps.values;

  const maxLength = 20;
  const [isMaxLength, setIsMaxLength] = React.useState(false);

  const headData = [
    { label: "開始時間", className: `${classes.dateCell}` },
    { label: "終了時間", className: `${classes.dateCell}` },
    { label: "活動", className: `${classes.activityCell}` },
    {
      label: "サービス手順・留意事項",
      className: `${classes.serviceProcedureCell}`
    }
  ];
  if (!props.isRecord) {
    headData.push({ label: "削除", className: `${classes.iconButtonCell}` });
  }

  React.useEffect(() => {
    setIsMaxLength(supportProcedureFormDetails.length >= maxLength);
  }, [supportProcedureFormDetails]);

  return (
    <div className={classes.MT24}>
      <div className={classes.header}>
        {headData.map((data) => (
          <div key={data.label} className={data.className}>
            {data.label}
          </div>
        ))}
      </div>

      <FieldArray name="supportProcedureFormDetails">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          const onClickAdd = (): void => {
            arrayHelpers.push(supportProcedureFormDetailInitialValue);
          };
          const onClickDelete = (index: number): void => {
            // arrayHelpers.remove(index) を使用していたが、バリデーションエラーがある際に落ちるためsetFieldValueで代替
            // formik issue: https://github.com/jaredpalmer/formik/issues/1158
            formikProps.setFieldValue(
              "supportProcedureFormDetails",
              supportProcedureFormDetails.filter((_, i) => i !== index)
            );
          };
          return (
            <>
              {supportProcedureFormDetails.map((_, i) => {
                const index = `supportProcedureFormDetailsTableRow${i}`;
                return (
                  <SupportProcedureFormTableRow
                    key={index}
                    index={i}
                    onClickDelete={onClickDelete}
                    formikProps={formikProps}
                    isRecord={props.isRecord}
                  />
                );
              })}
              {props.isRecord ? (
                <></>
              ) : (
                <Button
                  className={classes.MT16}
                  color="secondary"
                  onClick={onClickAdd}
                  disabled={isMaxLength}
                >
                  <AddIcon />
                  手順を追加
                </Button>
              )}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const SupportProcedureFormTable = withStyles(styles)(
  connect(null)(SupportProcedureFormTableCore)
);
