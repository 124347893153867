import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as H from "history";
import { AssessmentState } from "@stores/domain/assessment/types";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

// ui
import { FacilityType, PLAN_LIST_TAB_PATH } from "@constants/variables";
import {
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN,
  UIFACILITY_TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AddToPhotosOutlined from "@material-ui/icons/AddToPhotosOutlined";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { ConsultationSwitchDialog } from "@components/organisms/ConsultationSwitchDialog";
// utils
import { dateToLocalisedString } from "@/utils/date";

const styles = (): StyleRules =>
  createStyles({
    list: {
      background: "#fff",
      borderRadius: "4px"
    },
    listCon: {
      marginTop: "32px",
      padding: "0 40px"
    },
    item: {
      borderTop: "1px solid #b2b2b2",
      "&:nth-child(n+2)": {
        marginTop: "24px"
      }
    },
    itemTitle: {
      borderBottom: "1px solid #d9d9d9",
      background: " rgba(245, 245, 245, 0.38)",
      padding: "10px 8px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    itemTitleText: {
      display: "flex",
      alignItems: "center",
      "&>p": {
        fontFamily: "HiraginoSans-W4",
        fontSize: "16px",
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.87)",
        margin: 0,
        "&:nth-child(n+2)": {
          marginLeft: "40px"
        }
      }
    },
    itemBtn: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 8px 16px"
    },
    itemBtnIcon: {
      color: "#0277bd"
    },
    itemBtnText: {
      display: "block",
      marginLeft: "4px",
      color: "#0277bd",
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "1.25px"
    },
    listMessage: {
      padding: "80px 32px",
      textAlign: "center",
      fontFamily: "HiraginoSans-W4",
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: " rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  assessmentList: AssessmentState["assessmentList"];
  history: H.History;
  facilityType: FacilityType;
};

type StateProps = {
  facilityTypeKeikakusodan: number | null;
  uifKeikakusodan: UsersInFacilityState["user"]["user_in_facility_keikakusodan"];
};

type DispatchProps = {
  fetchKEIKAKUSODANFacility: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

/**
 * アセスメント一覧
 *
 */
const AssessmentRecordListCore = (props: Props): JSX.Element | null => {
  const {
    assessmentList,
    classes,
    uifId,
    facilityType,
    facilityTypeKeikakusodan,
    fetchKEIKAKUSODANFacility,
    uifKeikakusodan
  } = props;

  const [isModalOpen, openModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [transitionUrl, setTransitionUrl] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<string>(
    `${UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN}`
  );

  const isKeikakuAndShogaijiFacility = (): boolean =>
    facilityTypeKeikakusodan === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  const isShogaiji = (type: number): boolean =>
    type === UIFACILITY_TYPE_CONSULTATION_SHOGAIJISODAN;

  const shouldAddLabel = (type: number): boolean => isShogaiji(type);

  React.useEffect(() => {
    if (facilityType === FacilityType.KEIKAKUSODAN) {
      fetchKEIKAKUSODANFacility();
    }
  }, []);

  const onClickNew = (): void => {
    if (isKeikakuAndShogaijiFacility()) {
      setIsNew(true);
      setDefaultValue(
        uifKeikakusodan && uifKeikakusodan.type_daily_consultation
          ? `${uifKeikakusodan.type_daily_consultation}`
          : `${UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN}`
      );
      setTransitionUrl(`#/record/support_plan/${uifId}/assessment/new`);
      openModal(true);
    } else {
      props.history.push(`/record/support_plan/${uifId}/assessment/new`);
    }
  };

  const onClickCopy = ({
    id,
    type_consultation
  }: AssessmentState["assessmentList"][number]): void => {
    if (isKeikakuAndShogaijiFacility()) {
      setIsNew(false);
      setDefaultValue(`${type_consultation}`);
      setTransitionUrl(`#/record/support_plan/${uifId}/assessment/${id}/copy`);
      openModal(true);
    } else {
      props.history.push(`/record/support_plan/${uifId}/assessment/${id}/copy`);
    }
  };

  const createButton = (
    <KnowbeButton kind="default" onClick={onClickNew}>
      新規作成
    </KnowbeButton>
  );

  return (
    <>
      <ConsultationSwitchDialog
        isModalOpen={isModalOpen}
        onClose={(): void => openModal(false)}
        isNew={isNew}
        copyTitle={isNew ? "新規作成" : "アセスメントシートをコピー"}
        transitionUrl={transitionUrl}
        defaultValue={defaultValue}
      />
      <div className={classes.list}>
        <PlanListHeader
          pageName="アセスメント一覧"
          userName={props.userName}
          uifId={props.uifId}
          recordType={PLAN_LIST_TAB_PATH.ASSESSMENT}
          isEditing={false}
          history={props.history}
          button={createButton}
          facilityType={facilityType}
        />
        {assessmentList.length !== 0 ? (
          <div className={classes.listCon}>
            {assessmentList.map((item, index) => {
              const key = `key_${index}`;
              return (
                <div className={classes.item} key={key}>
                  <div className={classes.itemTitle}>
                    <div className={classes.itemTitleText}>
                      <p>
                        作成日：
                        {dateToLocalisedString(
                          item.target_date,
                          "YYYY年M月D日"
                        )}
                      </p>
                      <p>作成者：{item.author_name || "-"}</p>
                    </div>
                    <div className={classes.itemTitleBtn}>
                      <KnowbeButton
                        kind="outlineWhite"
                        minWidth={158}
                        href={`#/record/support_plan/${uifId}/assessment/${item.id}`}
                      >
                        アセスメントシート
                        {shouldAddLabel(item.type_consultation) && "（障害児）"}
                      </KnowbeButton>
                    </div>
                  </div>
                  <div className={classes.itemBtn}>
                    <KnowbeButton
                      kind="iconText"
                      onClick={(): void => onClickCopy(item)}
                    >
                      <AddToPhotosOutlined className={classes.itemBtnIcon} />
                      <span className={classes.itemBtnText}>
                        このアセスメントシートをコピー
                      </span>
                    </KnowbeButton>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.listMessage}>
            アセスメントシートがありません。新規作成ボタンから作成してください。
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { facility, userInFacility } = state.KEIKAKUSODAN;
  return {
    facilityTypeKeikakusodan: facility.facilityType,
    uifKeikakusodan: userInFacility.user.user_in_facility_keikakusodan
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN } = dispatches;

  return {
    fetchKEIKAKUSODANFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

export const AssessmentRecordList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssessmentRecordListCore));
