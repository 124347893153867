import { ServiceDeliveryDetailValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, {
  ErrorMessage,
  validateSwitcher,
  validateRuleSwitcher
} from "@validator";
import {
  SERVICE_CUSTOM_RECORD_DEFAULT_CHOICE_INPUT_TYPE,
  SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO,
  KYOTAKUKAIGO_STATUS_LIST,
  SERVICE_CUSTOM_RECORD_CATEGORY_TYPE,
  KYOTAKUKAIGO_MEMBER_LIST,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import {
  DAY_SELECT_TYPE,
  FacilityType,
  MEMBER_LIST_SERVICE_DETAIL
} from "@constants/variables";
import { SelectDateValue } from "@interfaces/ui/form";
import { selectDateValueToDate } from "@utils/date";
import {
  isCheckCharacterLengthRecord,
  isValidateNaturalNumberWithCommaRecord,
  isValidateNumberWithCommaRecord
} from "@utils/domain/serviceDelivery/extractDefaultItems";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN } from "@constants/mgr/IDOSHIEN/variables";

export type ServiceDeliveryDetailErrors = ValidationErrors<
  ServiceDeliveryDetailValues
>;

type PractitionersValuesErrors = ValidationErrors<
  ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]
>;

type PractitionersDetailsValuesErrors = ValidationErrors<
  ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDetails"]
>;

type PractitionersDestinationsValuesErrors = ValidationErrors<
  ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDestinations"]
>;

type PractitionersMoneyManagementsValuesErrors = ValidationErrors<
  ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerMoneyManagements"]
>;

type CustomRecordsValuesErrors = ValidationErrors<
  ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"]
>;

type CustomRecordTextErrorMessage = {
  [custom_record_id: string]: { input_data: ErrorMessage };
};

// サービス内容が居宅「通院等乗降介助」か判断

export const isGettingOnAndOff = (
  status: string,
  facilityType: FacilityType
): boolean => {
  return (
    facilityType === FacilityType.KYOTAKUKAIGO &&
    status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
  );
};
// 事業種別により，食事項目の番号を取得する
const extractMealNum = (facilityType: FacilityType): number => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.meal;
    case FacilityType.JUDOHOMONKAIGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.meal;
    case FacilityType.IDOSHIEN:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.meal;
    default:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.meal;
  }
};

// 事業種別により，メニュー項目の番号を取得する
const extractMenuNum = (facilityType: FacilityType): number => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.menu;
    case FacilityType.JUDOHOMONKAIGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.menu;
    default:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.menu;
  }
};

// 「サービス内容」により，バリデートする項目を変更する(居宅のみ)
export const checkStatus = (
  categoryType: number,
  currentStatus: string,
  facilityTypeParam?: FacilityType
): boolean => {
  if (facilityTypeParam !== FacilityType.KYOTAKUKAIGO) {
    return true;
  }
  if (currentStatus === KYOTAKUKAIGO_STATUS_LIST.PHYSICAL.value) {
    return [
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.BASIC,
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.PHYSICAL,
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.SPECIAL
    ].includes(categoryType);
  }
  if (currentStatus === KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK.value) {
    return [
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.BASIC,
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.HOUSE_WORK,
      SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.SPECIAL
    ].includes(categoryType);
  }
  return [
    SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.BASIC,
    SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.HOSPITAL,
    SERVICE_CUSTOM_RECORD_CATEGORY_TYPE.SPECIAL
  ].includes(categoryType);
};

// HH:MM形式をDate型に変換する
const formatDate = (
  time: string,
  dateSelect: string,
  targetDate: SelectDateValue
): Date => {
  const baseDate = new Date(
    fixDateAndTimeFormat(selectDateValueToDate(targetDate))
  );
  const timeArr = time.split(":");
  const hour = Number(timeArr[0]);
  const minute = Number(timeArr[1]);
  if (dateSelect === DAY_SELECT_TYPE.nextDay) {
    baseDate.setDate(baseDate.getDate() + 1);
  }
  baseDate.setHours(hour, minute);
  return baseDate;
};

// カスタムレコードバリデーション（テキストタイプ）
const validateCustomRecordText = (
  customRecords: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"]["input_type_text"],
  status: string,
  facilityType: FacilityType
): CustomRecordTextErrorMessage => {
  const obj = {};

  Object.entries(customRecords).forEach(([customRecordKey, items]) => {
    // 項目が「食事量」・「メニュー」の場合
    if (
      items.default_item === extractMealNum(facilityType) ||
      items.default_item === extractMenuNum(facilityType)
    ) {
      Object.assign(obj, {
        [customRecordKey]: {
          input_data: validateSwitcher(
            checkStatus(items.category_type, status, facilityType),
            validator(customRecords[`${customRecordKey}`].input_data, {
              type: "checkCharacterLength",
              length: 200
            })
          )
        }
      });
    } else {
      Object.assign(obj, {
        [customRecordKey]: {
          input_data: validateSwitcher(
            checkStatus(items.category_type, status),
            validator(customRecords[`${customRecordKey}`].input_data, {
              type: "checkCharacterLength",
              length: 2500
            })
          )
        }
      });
    }
  });
  return obj;
};

// カスタムレコードバリデーション（複数テキストタイプ）
const validateCustomRecordMultiText = (
  customRecords: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"]["input_type_multi_text"],
  status: string,
  facilityType: FacilityType
): CustomRecordTextErrorMessage => {
  const obj = {};
  Object.entries(customRecords).forEach(([customRecordKey, items]) => {
    const choicedObj = {};
    Object.entries(items).forEach(([choicedItemKey, item]) => {
      if (
        isValidateNaturalNumberWithCommaRecord(
          item.default_item,
          facilityType,
          item.default_choice
        )
      ) {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validateSwitcher(
              checkStatus(item.category_type, status, facilityType),
              validator(item.input_data, "naturalNumberWithComma")
            )
          }
        });
      } else if (
        isValidateNumberWithCommaRecord(
          item.default_item,
          facilityType,
          item.default_choice
        )
      ) {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validateSwitcher(
              checkStatus(item.category_type, status, facilityType),
              validator(item.input_data, "numberWithComma")
            )
          }
        });
      } else if (
        // 項目が「費用負担」・「預かり金」の場合(重度もdefault_itemは同じ)
        // TODO: FacilityTypeの指定をする
        item.default_item ===
          SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.cost ||
        item.default_item ===
          SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.deposit
      ) {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validateSwitcher(
              checkStatus(item.category_type, status, facilityType),
              validator(item.input_data, "naturalNumberWithComma")
            )
          }
        });
        // 項目が「排泄管理（排便）」（重度のみ）
      } else if (
        facilityType === FacilityType.JUDOHOMONKAIGO &&
        item.default_item ===
          SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.excretion_management
      ) {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validator(
              item.input_data,
              item.default_choice === 2
                ? {
                    type: "checkCharacterLength",
                    length: 200
                  }
                : "decimalPoint"
            )
          }
        });
      } else {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validateSwitcher(
              checkStatus(item.category_type, status, facilityType),
              validator(item.input_data, "decimalPoint")
            )
          }
        });
      }
    });
    Object.assign(obj, { [customRecordKey]: choicedObj });
  });
  return obj;
};

// カスタムレコードバリデーション（選択式（入力項目））
const validateCustomRecordMixture = (
  customRecords: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"]["input_type_mixture"],
  status: string,
  targetDate: SelectDateValue,
  facilityType: FacilityType
): CustomRecordTextErrorMessage => {
  const obj = {};
  Object.entries(customRecords).forEach(([customRecordKey, items]) => {
    const choicedObj = {};
    Object.entries(items).forEach(([choicedItemKey, item]) => {
      const startTimeDateRecord = Object.values(items).find((startTimeItem) => {
        return (
          startTimeItem.default_choice_input_type ===
            SERVICE_CUSTOM_RECORD_DEFAULT_CHOICE_INPUT_TYPE.time &&
          startTimeItem.default_choice ===
            MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.start
        );
      });
      const startTimeDate = startTimeDateRecord
        ? formatDate(
            startTimeDateRecord.input_data,
            startTimeDateRecord.timeDate,
            targetDate
          )
        : new Date("");
      // 項目が「開始時間」・「終了時間」の場合のみチェック
      if (
        item.default_choice_input_type ===
        SERVICE_CUSTOM_RECORD_DEFAULT_CHOICE_INPUT_TYPE.time
      ) {
        // 開始時間バリデーション
        if (item.default_choice === MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.start) {
          Object.assign(choicedObj, {
            [choicedItemKey]: {
              input_data: validateSwitcher(
                checkStatus(item.category_type, status, facilityType),
                validator(item.input_data, "checkTime")
              )
            }
          });
        } else if (
          item.default_choice === MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.end
        ) {
          const endTimeDate = formatDate(
            item.input_data,
            item.timeDate,
            targetDate
          );
          // 終了時間バリデーション
          Object.assign(choicedObj, {
            [choicedItemKey]: {
              input_data: validateSwitcher(
                checkStatus(item.category_type, status, facilityType),
                validator(item.input_data, "checkTime", {
                  type: "futureTime",
                  startTime: startTimeDate,
                  endTime: endTimeDate
                })
              ),
              timeDate: validateSwitcher(
                checkStatus(item.category_type, status, facilityType),
                validator(item.timeDate, {
                  type: "futureTime",
                  startTime: startTimeDate,
                  endTime: endTimeDate
                })
              )
            }
          });
        } else if (
          // 目的地・交通手段など
          item.default_choice === MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
        ) {
          Object.assign(choicedObj, {
            [choicedItemKey]: {
              input_data: validator(item.input_data, {
                type: "checkCharacterLength",
                length: 200
              })
            }
          });
        }
      }
    });
    Object.assign(obj, { [customRecordKey]: choicedObj });
  });
  return obj;
};

// カスタムレコードバリデーション（チェックボックス（カスタム可）＋テキスト））
const validateCustomRecordCheckboxAndText = (
  customRecords: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"]["input_type_checkbox_and_text"],
  facilityType: FacilityType
): CustomRecordTextErrorMessage => {
  const obj = {};
  Object.entries(customRecords).forEach(([customRecordKey, items]) => {
    const choicedObj = {};
    Object.entries(items).forEach(([choicedItemKey, item]) => {
      // 項目がテキストの場合のみチェック
      if (
        isCheckCharacterLengthRecord(
          item.default_item,
          item.default_choice,
          facilityType
        )
      ) {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validator(item.input_data, {
              type: "checkCharacterLength",
              length: 200
            })
          }
        });
      }
    });
    Object.assign(obj, { [customRecordKey]: choicedObj });
  });
  return obj;
};

// カスタムレコードバリデーション
const customRecordsValidation = (
  values: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["custom_record"],
  status: string,
  targetDate: SelectDateValue,
  facilityType: FacilityType
): CustomRecordsValuesErrors => {
  return {
    input_type_text: validateCustomRecordText(
      values.input_type_text,
      status,
      facilityType
    ),
    input_type_multi_text: validateCustomRecordMultiText(
      values.input_type_multi_text,
      status,
      facilityType
    ),
    input_type_mixture: validateCustomRecordMixture(
      values.input_type_mixture,
      status,
      targetDate,
      facilityType
    ),
    input_type_checkbox_and_text: validateCustomRecordCheckboxAndText(
      values.input_type_checkbox_and_text,
      facilityType
    )
  };
};

// 運転/空き時間バリデーション
const practitionersDetailsValuesValidation = (
  values: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDetails"],
  state: ServiceDeliveryDetailValues,
  baseStartTime: Date,
  baseEndTime: Date,
  facilityType: FacilityType
): PractitionersDetailsValuesErrors => {
  const errorLabel = (): string => {
    if (facilityType === FacilityType.JUDOHOMONKAIGO) {
      return "移動/空き時間";
    }
    if (facilityType === FacilityType.KODOENGO) {
      return "空き";
    }
    return "運転/空き時間";
  };

  // 有効な運転/空き時間のみ取り出し
  const detailsTimes: { startTime: Date; endTime: Date; key: number }[] = [];
  values.forEach((value, index) => {
    if (value.startTime && value.endTime && !value.isDelete) {
      const startTimeDate = formatDate(
        value.startTime,
        value.startTimeDate,
        state.targetDate
      );
      const endTimeDate = formatDate(
        value.endTime,
        value.endTimeDate,
        state.targetDate
      );
      if (
        !Number.isNaN(startTimeDate.getTime()) &&
        !Number.isNaN(endTimeDate.getTime()) &&
        startTimeDate.getTime() < endTimeDate.getTime()
      ) {
        detailsTimes.push({
          startTime: startTimeDate,
          endTime: endTimeDate,
          key: index
        });
      }
    }
  });

  return values.map((value, index) => {
    const startTimeDate = formatDate(
      value.startTime,
      value.startTimeDate,
      state.targetDate
    );
    const endTimeDate = formatDate(
      value.endTime,
      value.endTimeDate,
      state.targetDate
    );
    // 自分の値を除く時間配列を作成
    const removedDetailsTimes = detailsTimes
      .filter((time) => time.key !== index)
      .map((time) => {
        return {
          startTime: time.startTime,
          endTime: time.endTime
        };
      });

    const equivalent =
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? value.actionClass === 1
        : false;

    return {
      startTimeDate: validateSwitcher(
        !isGettingOnAndOff(state.status, facilityType) && !value.isDelete,
        validator(
          value.startTimeDate,
          {
            type: "checkWithinRangeTime",
            baseStartTime,
            baseEndTime,
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            option: {
              baseLabel: "提供時間",
              targetLabel: errorLabel()
            },
            equivalent
          },
          {
            type: "checkTimeConflict",
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            baseTimes: removedDetailsTimes,
            label: errorLabel()
          }
        )
      ),
      startTime: validateSwitcher(
        !isGettingOnAndOff(state.status, facilityType) && !value.isDelete,
        validator(
          value.startTime,
          "checkTime",
          {
            type: "checkWithinRangeTime",
            baseStartTime,
            baseEndTime,
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            option: {
              baseLabel: "提供時間",
              targetLabel: errorLabel()
            },
            equivalent
          },
          {
            type: "checkTimeConflict",
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            baseTimes: removedDetailsTimes,
            label: errorLabel()
          }
        )
      ),
      endTimeDate: validateSwitcher(
        !isGettingOnAndOff(state.status, facilityType) && !value.isDelete,
        validator(
          value.endTimeDate,
          {
            type: "futureTime",
            startTime: startTimeDate,
            endTime: endTimeDate
          },
          ![FacilityType.JUDOHOMONKAIGO, FacilityType.IDOSHIEN].includes(
            facilityType
          )
            ? {
                type: "checkTimeRangeLimitDateType",
                startTime: startTimeDate,
                outTime: endTimeDate,
                limit: 2,
                option: errorLabel()
              }
            : undefined,
          {
            type: "checkWithinRangeTime",
            baseStartTime,
            baseEndTime,
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            option: {
              baseLabel: "提供時間",
              targetLabel: errorLabel()
            },
            equivalent
          },
          {
            type: "checkTimeConflict",
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            baseTimes: removedDetailsTimes,
            label: errorLabel()
          }
        )
      ),
      endTime: validateSwitcher(
        !isGettingOnAndOff(state.status, facilityType) && !value.isDelete,
        validator(
          value.endTime,
          "checkTime",
          {
            type: "futureTime",
            startTime: startTimeDate,
            endTime: endTimeDate
          },
          ![FacilityType.JUDOHOMONKAIGO, FacilityType.IDOSHIEN].includes(
            facilityType
          )
            ? {
                type: "checkTimeRangeLimitDateType",
                startTime: startTimeDate,
                outTime: endTimeDate,
                limit: 2,
                option: errorLabel()
              }
            : undefined,
          {
            type: "checkWithinRangeTime",
            baseStartTime,
            baseEndTime,
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            option: {
              baseLabel: "提供時間",
              targetLabel: errorLabel()
            },
            equivalent
          },
          {
            type: "checkTimeConflict",
            targetStartTime: startTimeDate,
            targetEndTime: endTimeDate,
            baseTimes: removedDetailsTimes,
            label: errorLabel()
          }
        )
      )
    };
  });
};

// 行き先バリデーション
const practitionersDestinationsValuesValidation = (
  values: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDestinations"]
): PractitionersDestinationsValuesErrors => {
  return values.map((value) => {
    return {
      when_time: validateSwitcher(
        !value.isDelete,
        validator(value.when_time, "checkTime")
      ),
      place: validateSwitcher(
        !value.isDelete,
        validator(value.place, {
          type: "checkCharacterLength",
          length: 20
        })
      ),
      support: validateSwitcher(
        !value.isDelete,
        validator(value.support, {
          type: "checkCharacterLength",
          length: 200
        })
      )
    };
  });
};

// 金銭管理
const practitionersMoneyManagementsValuesValidation = (
  values: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerMoneyManagements"]
): PractitionersMoneyManagementsValuesErrors => {
  const getError = (
    expenses: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerMoneyManagements"]["traveling_expenses"]
  ): PractitionersMoneyManagementsValuesErrors["traveling_expenses"] => {
    return expenses.map((value) => {
      return {
        free_text: validateSwitcher(
          !value.isDelete,
          validator(value.free_text, {
            type: "checkCharacterLength",
            length: 20
          })
        ),
        amount_money: validateSwitcher(
          !value.isDelete,
          validator(value.amount_money, "naturalNumberWithComma")
        )
      };
    });
  };
  return {
    traveling_expenses: getError(values.traveling_expenses),
    other_expenses: getError(values.other_expenses)
  };
};

// サービス提供記録者バリデーション
const practitionersValuesValidation = (
  value: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"],
  anotherPractitionersValue: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"],
  state: ServiceDeliveryDetailValues,
  facilityType: FacilityType
): PractitionersValuesErrors => {
  const startTimeDate = formatDate(
    value.startTime,
    value.startTimeDate,
    state.targetDate
  );
  const endTimeDate = formatDate(
    value.endTime,
    value.endTimeDate,
    state.targetDate
  );
  const anotherStartTimeDate = formatDate(
    anotherPractitionersValue.startTime,
    anotherPractitionersValue.startTimeDate,
    state.targetDate
  );
  const anotherendTimeDate = formatDate(
    anotherPractitionersValue.endTime,
    anotherPractitionersValue.endTimeDate,
    state.targetDate
  );

  // 重度のみ
  const accompanySupportInTimeValues = formatDate(
    value.accompanySupportInTime,
    value.accompanySupportInTimeDate,
    state.targetDate
  );

  const accompanySupportOutTimeValues = formatDate(
    value.accompanySupportOutTime,
    value.accompanySupportOutTimeDate,
    state.targetDate
  );

  return {
    startTimeDate: validator(
      value.startTime,
      validateRuleSwitcher(
        state.numberOfPractitioner === KYOTAKUKAIGO_MEMBER_LIST.TWO.value,
        {
          type: "checkRangeDateTime",
          startTimeFirst: startTimeDate,
          endTimeFirst: endTimeDate,
          startTimeSecond: anotherStartTimeDate,
          endTimeSecond: anotherendTimeDate,
          message: "2人の提供者の開始/終了時間",
          allowContinuing: facilityType === FacilityType.IDOSHIEN
        }
      )
    ),
    startTime: validator(
      value.startTime,
      "checkTime",
      validateRuleSwitcher(
        state.numberOfPractitioner === KYOTAKUKAIGO_MEMBER_LIST.TWO.value,
        {
          type: "checkRangeDateTime",
          startTimeFirst: startTimeDate,
          endTimeFirst: endTimeDate,
          startTimeSecond: anotherStartTimeDate,
          endTimeSecond: anotherendTimeDate,
          message: "2人の提供者の開始/終了時間",
          allowContinuing: facilityType === FacilityType.IDOSHIEN
        }
      )
    ),
    endTimeDate: validator(
      value.endTimeDate,
      {
        type: "futureTime",
        startTime: startTimeDate,
        endTime: endTimeDate
      },
      validateRuleSwitcher(
        state.numberOfPractitioner === KYOTAKUKAIGO_MEMBER_LIST.TWO.value,
        {
          type: "checkRangeDateTime",
          startTimeFirst: startTimeDate,
          endTimeFirst: endTimeDate,
          startTimeSecond: anotherStartTimeDate,
          endTimeSecond: anotherendTimeDate,
          message: "2人の提供者の開始/終了時間",
          allowContinuing: facilityType === FacilityType.IDOSHIEN
        }
      ),
      validateRuleSwitcher(
        facilityType === FacilityType.KYOTAKUKAIGO &&
          state.status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value,
        {
          type: "checkTimeZoneCrossing",
          startTime: startTimeDate,
          endTime: endTimeDate,
          targetDate: selectDateValueToDate(state.targetDate)
        }
      )
    ),
    endTime: validator(
      value.endTime,
      "checkTime",
      {
        type: "futureTime",
        startTime: startTimeDate,
        endTime: endTimeDate
      },
      validateRuleSwitcher(
        state.numberOfPractitioner === KYOTAKUKAIGO_MEMBER_LIST.TWO.value,
        {
          type: "checkRangeDateTime",
          startTimeFirst: startTimeDate,
          endTimeFirst: endTimeDate,
          startTimeSecond: anotherStartTimeDate,
          endTimeSecond: anotherendTimeDate,
          message: "2人の提供者の開始/終了時間",
          allowContinuing: facilityType === FacilityType.IDOSHIEN
        }
      ),
      validateRuleSwitcher(
        facilityType === FacilityType.KYOTAKUKAIGO &&
          state.status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value,
        {
          type: "checkTimeZoneCrossing",
          startTime: startTimeDate,
          endTime: endTimeDate,
          targetDate: selectDateValueToDate(state.targetDate)
        }
      )
    ),
    accompanySupportInTimeDate: validator(value.accompanySupportInTimeDate, {
      type: "checkWithinRangeTime",
      baseStartTime: startTimeDate,
      baseEndTime: endTimeDate,
      targetStartTime: accompanySupportInTimeValues,
      targetEndTime: accompanySupportOutTimeValues,
      option: {
        baseLabel: "提供時間",
        targetLabel: "同行支援開始時間/同行支援終了時間"
      },
      equivalent: true
    }),
    accompanySupportInTime: validator(
      value.accompanySupportInTime,
      "checkTime",
      {
        type: "checkWithinRangeTime",
        baseStartTime: startTimeDate,
        baseEndTime: endTimeDate,
        targetStartTime: accompanySupportInTimeValues,
        targetEndTime: accompanySupportOutTimeValues,
        option: {
          baseLabel: "提供時間",
          targetLabel: "同行支援開始時間/同行支援終了時間"
        },
        equivalent: true
      }
    ),
    accompanySupportOutTimeDate: validator(
      value.accompanySupportOutTime,
      {
        type: "futureTime",
        startTime: accompanySupportInTimeValues,
        endTime: accompanySupportOutTimeValues
      },
      {
        type: "checkWithinRangeTime",
        baseStartTime: startTimeDate,
        baseEndTime: endTimeDate,
        targetStartTime: accompanySupportInTimeValues,
        targetEndTime: accompanySupportOutTimeValues,
        option: {
          baseLabel: "提供時間",
          targetLabel: "同行支援開始時間/同行支援終了時間"
        },
        equivalent: true
      }
    ),
    accompanySupportOutTime: validator(
      value.accompanySupportOutTime,
      "checkTime",
      {
        type: "futureTime",
        startTime: accompanySupportInTimeValues,
        endTime: accompanySupportOutTimeValues
      },
      {
        type: "checkWithinRangeTime",
        baseStartTime: startTimeDate,
        baseEndTime: endTimeDate,
        targetStartTime: accompanySupportInTimeValues,
        targetEndTime: accompanySupportOutTimeValues,
        option: {
          baseLabel: "提供時間",
          targetLabel: "同行支援開始時間/同行支援終了時間"
        },
        equivalent: true
      }
    ),

    numberOfRides: validateSwitcher(
      facilityType === FacilityType.KYOTAKUKAIGO &&
        state.status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value &&
        !!value.numberOfRides,
      validator(String(value.numberOfRides), "decimal", {
        type: "checkMaximumNumber",
        value: Number(value.numberOfRides),
        maximumNum: 9
      })
    ),
    serviceDeliveryRecordPractitionerDetails: practitionersDetailsValuesValidation(
      value.serviceDeliveryRecordPractitionerDetails,
      state,
      startTimeDate,
      endTimeDate,
      facilityType
    ),
    custom_record: customRecordsValidation(
      value.custom_record,
      state.status,
      state.targetDate,
      facilityType
    ),
    serviceDeliveryRecordPractitionerDestinations: practitionersDestinationsValuesValidation(
      value.serviceDeliveryRecordPractitionerDestinations
    ),
    serviceDeliveryRecordPractitionerMoneyManagements: practitionersMoneyManagementsValuesValidation(
      value.serviceDeliveryRecordPractitionerMoneyManagements
    )
  };
};

export const validation = (
  values: ServiceDeliveryDetailValues,
  facilityType: FacilityType
): ServiceDeliveryDetailErrors => {
  return {
    usersInFacilityId: validator(String(values.usersInFacilityId), {
      type: "selectRequired",
      value: "0"
    }),
    status: validator(values.status, {
      type: "selectRequired",
      value: MEMBER_LIST_SERVICE_DETAIL[0].value
    }),
    numberOfSupporters: validator(values.numberOfSupporters, "naturalNumber", {
      type: "lowerLimit",
      lowerLimit: 1
    }),
    serviceDeliveryRecordPractitioners1: practitionersValuesValidation(
      values.serviceDeliveryRecordPractitioners1,
      values.serviceDeliveryRecordPractitioners2,
      values,
      facilityType
    ),
    serviceDeliveryRecordPractitioners2: practitionersValuesValidation(
      values.serviceDeliveryRecordPractitioners2,
      values.serviceDeliveryRecordPractitioners1,
      values,
      facilityType
    )
  };
};
