import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * サービス予定取得 API Response
 */
export type GetCarePlanResponse = {
  data: {
    users_in_facility_care_plan: CarePlanItem[];
    staffs_in_facility: CarePlanStaffsInFacilityItem[];
    display_list: CarePlanDisplayList[];
  };
};

export type CarePlanItem = {
  id: number;
  users_in_facility_id: number;
  day_of_week: number;
  name_sei: string;
  name_mei: string;
  status: number;
  number_of_boarding_and_alighting: number | null;
  number_of_participants: number | null;
  practitioner1: number | null;
  practitioner1_license: number | null;
  practitioner1_in_time: string | null;
  practitioner1_out_time: string | null;
  practitioner1_calculated_hours: number | null;
  practitioner1_calculated_moving_hours: number | null;
  practitioner1_memo: string | null;
  users_in_facility_care_plan_details1: CarePlanDetailsItem[];
  practitioner2: number | null;
  practitioner2_license: number | null;
  practitioner2_in_time: string | null;
  practitioner2_out_time: string | null;
  practitioner2_calculated_hours: number | null;
  practitioner2_memo: string | null;
  users_in_facility_care_plan_details2: CarePlanDetailsItem[];
  time_duplication_judgment_flg: number | null;
  whole_calculated_hours: number | null;
  duplicate_calculated_hours: number | null;
  license_same_flg: number | null;
  accompany_support_flg: number | null;
  second_person_flg: number | null;
  sputum_implementation_flg: number | null;
  moving_time_duplication_judgment_flg: number | null;
  whole_calculated_moving_hours: number | null;
  duplicate_calculated_moving_hours: number | null;
  accompany_support_in_time: string;
  accompany_support_out_time: string;
  whole_calculated_accompany_support_hours: number | null;
  whole_calculated_accompany_support_moving_hours: number | null;
};

export type CarePlanStaffsInFacilityItem = {
  id: number;
  name: string;
  role: string;
  license: number;
};

export type CarePlanDetailsItem = {
  id: number | null;
  action_class: number;
  in_time: string;
  out_time: string;
};

export type CarePlanDisplayList = {
  users_in_facility_id: number;
  name_sei: string;
  name_mei: string;
  day_of_week: number;
  data_list: CarePlanList[];
};

export type CarePlanList = {
  no: number;
  id: number;
  plan: {
    dispatch_no: number | null;
    status: number;
    in_time: string | null;
    out_time: string | null;
    calculated_hours: number | null;
    calculated_moving_hours: number | null;
    getting_on_and_off: number | null;
    number_of_participants: number | null;
  };
};

/**
 * サービス予定を取得する
 * @param id ユーザーID
 */
export const getCarePlan = async (
  id: string
): Promise<AxiosResponse<GetCarePlanResponse>> => {
  const url = `${VERSION_URL_202104}/care_plan/${id}`;
  return request.get<GetCarePlanResponse>(url);
};
