import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { ReportResultAPI } from "@stores/domain/report/type";

export type GetUsagePerformanceSummaryParams = {
  data: {
    summary: {
      status: number;
      count: number;
    }[];
    usage_performance: {
      users_in_facility_id: number;
      user_name: string;
      recipient_number: string;
      status: number;
    }[];
  };
};

export type GetUsagePerformanceParamsSummary = GetUsagePerformanceSummaryParams["data"];

type ForNormalizeReportDataFromAPI = {
  data: ReportResultAPI;
};

type GetUsagePerformanceSummaryResponse = ForNormalizeReportDataFromAPI &
  GetUsagePerformanceSummaryParams;

/**
 * 利用実績サマリーを取得
 * @param yyyymmdd 対象日 YYYYMMDD
 */
export const getUsagePerformanceSummary = async (
  yyyymmdd: string
): Promise<AxiosResponse<GetUsagePerformanceSummaryResponse>> => {
  const url = `${VERSION_URL_201910}/usage_performance/summary/date/${yyyymmdd}`;
  return request.get<GetUsagePerformanceSummaryResponse>(url);
};

export default getUsagePerformanceSummary;
