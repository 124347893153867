import React from "react";

import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { Formik, Form, FormikProps } from "formik";

import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import RadioButtons from "@components/atoms/RadioButtons";

const styles = (): StyleRules =>
  createStyles({
    dialogPaper: {
      width: "600px"
    },
    dialogTitle: {
      padding: "16px 32px",
      backgroundColor: "#F5F5F5",
      borderBottom: "1px solid #CFD8DC"
    },
    dialogTitleLabel: {
      margin: 0,
      fontSize: "20px",
      lineHeight: "24px",
      color: "37474F"
    },
    dialogContent: {
      padding: "24px 32px"
    },
    dialogContentLabel: {
      margin: 0,
      fontSize: "16px",
      lineHeight: "28px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #CFD8DC"
    }
  });

type PrintType = "user" | "life";

type OwnProps = {
  isOpen: boolean;
  onClickCancel: () => void;
  onClickSubmit: (printType: PrintType) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

type DialogFormType = {
  basicInfoPrintTarget: PrintType;
};

const DialogFormInitial: DialogFormType = {
  basicInfoPrintTarget: "user"
};

const BasicInfoPrintDialogCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const handleChangeSelectedPrintItem = (
    formikProps: FormikProps<DialogFormType>,
    value: string
  ): void => {
    // 型判定のために分岐 実装上これ以外の値は入ってこない
    if (value === "user" || value === "life") {
      formikProps.setFieldValue("basicInfoPrintTarget", value);
    }
  };

  const onClickSubmit = (values: DialogFormType): void => {
    props.onClickSubmit(values.basicInfoPrintTarget);
  };

  return (
    <Dialog open={props.isOpen} PaperProps={{ className: classes.dialogPaper }}>
      <DialogTitle className={classes.dialogTitle}>
        <p className={classes.dialogTitleLabel}>
          申請者の現状（基本情報） 印刷項目
        </p>
      </DialogTitle>
      <Formik initialValues={DialogFormInitial} onSubmit={onClickSubmit}>
        {(formikProps): JSX.Element => {
          return (
            <Form>
              <DialogContent className={classes.dialogContent}>
                <p className={classes.dialogContentLabel}>
                  印刷する帳票を選択してください。
                </p>
                <RadioButtons
                  name="basicInfoPrintTarget"
                  value={formikProps.values.basicInfoPrintTarget}
                  onChange={(_, value): void =>
                    handleChangeSelectedPrintItem(formikProps, value)
                  }
                  radioItems={[
                    {
                      value: "user",
                      label: "申請者の現状"
                    },
                    {
                      value: "life",
                      label: "現在の生活"
                    }
                  ]}
                />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <KnowbeButton kind="outline" onClick={props.onClickCancel}>
                  キャンセル
                </KnowbeButton>
                <FormikSubmitButton
                  buttonName="確定する"
                  formikProps={formikProps}
                />
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export const BasicInfoPrintDialog = withStyles(styles)(
  BasicInfoPrintDialogCore
);
