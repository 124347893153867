import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * 利用計画から実績へのコピーAPI用のリクエストパラメータ
 */
export type RequestParamToPostCopyAPI = {
  process_type: number;
  target: {
    uif_id: number;
    yyyymmdd: string;
    inout_results_id?: number;
  }[];
};

/**
 * 利用計画から実績へのコピー
 * @param data リクエスト情報
 */
export const postInOutResultsCopy = async (
  data: RequestParamToPostCopyAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/inout_results/copy`;
  return request.post(url, data);
};
