import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { InOutReportMonthly } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportMonthly";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST
} from "@constants/mgr/IDOSHIEN/variables";
import * as URL from "@constants/url";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import ErrorsDialog from "@components/organisms/ErrorsDialog";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{}, {}, { targetDate: Date }> &
  WithStyles<typeof styles>;

/**
 * 利用実績
 */
const ReportCore = (props: Props): JSX.Element => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.DAILY) {
      props.history.push(URL.REPORT_DAILY);
    }
  };
  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LiST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.USERS}
      />
      <InOutReportMonthly
        initialDate={currentDate}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate>
  );
};

export const ReportMonthly = withStyles(styles)(ReportCore);
