import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
// ui
import { FieldItem } from "@interfaces/ui/form";
import MuiTextField from "@components/molecules/MuiTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import FormikTextField from "@components/molecules/FormikTextField";
import { UnitsFields } from "@components/organisms/record/serviceDelivery/units/UnitsField";
import { ServiceDeliveryAccompanySupportField } from "@components/organisms/record/serviceDelivery/ServiceDeliveryAccompanySupportField";
// formik
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { DAY_SELECT_OPTIONS, FacilityType } from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      border: "1px solid #bdbdbd",
      padding: "30px 30px",
      borderRadius: "4px",
      marginBottom: "48px",
      backgroundColor: "#fafafa"
    },
    flex: {
      display: "flex"
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  practitionerNum: number;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  facilityType: FacilityType;
  accompanyFieldDisplayFlg: boolean;
  fieldName: string;
  handleChangeDateSelect: (
    event: React.ChangeEvent<HTMLSelectElement>,
    isAccompanyField?: boolean
  ) => void;
  handleChangeInitialTime(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string,
    isAccompanyField?: boolean
  ): void;
  onChangeActionClass(index: number, actionClass: number): void;
  onChangeDetailDateSelect(
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ): void;
  onChangeDetailRecord(
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ): void;
  deleteRecord(index: number): void;
  practitionerOptions: FieldItem[];
  changePractitioner: (
    e: React.ChangeEvent<HTMLSelectElement>,
    numberOfIsSnapshot?: string
  ) => void;
  actionClassLabel: string;
  actionClassOptions: {
    label: string;
    value: number;
  }[];
  practitioner2SelectElement?: JSX.Element;
  practitioner3SelectElement?: JSX.Element;
  practitioner4SelectElement?: JSX.Element;
  practitioner5SelectElement?: JSX.Element;
} & WithStyles<typeof styles>;

export const ServiceDeliveryTimeFieldCore = (props: Props): JSX.Element => {
  const {
    classes,
    practitionerNum,
    practitionerValues,
    formikProps,
    accompanyFieldDisplayFlg,
    fieldName,
    facilityType,
    handleChangeDateSelect,
    handleChangeInitialTime,
    onChangeActionClass,
    onChangeDetailRecord,
    onChangeDetailDateSelect,
    deleteRecord,
    changePractitioner,
    practitionerOptions,
    actionClassLabel,
    actionClassOptions,
    practitioner2SelectElement,
    practitioner3SelectElement,
    practitioner4SelectElement,
    practitioner5SelectElement
  } = props;
  const startTimeValue = DAY_SELECT_OPTIONS.find(
    (option) => option.value === practitionerValues.startTimeDate
  );
  const formikValues = formikProps.values;

  const isIdoshienVehicleStatusOrSameFlgChecked =
    facilityType === FacilityType.IDOSHIEN &&
    (formikValues.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ||
      formikValues.licenseSameFlg);

  const isIdoshienGroupStatus =
    facilityType === FacilityType.IDOSHIEN &&
    formikProps.values.status === IDOSHIEN_STATUS_LIST.GROUP.value;

  return (
    <div
      className={
        !isIdoshienVehicleStatusOrSameFlgChecked || isIdoshienGroupStatus
          ? classes.wrapper
          : ""
      }
    >
      <div className={props.classes.flex}>
        <FormikSelect
          name={`${fieldName}.practitioner`}
          label={`サービス提供者${isIdoshienGroupStatus ? "1" : ""}`}
          options={practitionerOptions}
          isSelectablePlaceholder
          placeholder="選択してください"
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          onChangeHook={(e): void => changePractitioner(e, "")}
        />
        {isIdoshienGroupStatus && (
          <>
            {practitioner2SelectElement}
            {practitioner3SelectElement}
          </>
        )}
      </div>
      {isIdoshienGroupStatus && (
        <div className={props.classes.flex}>
          {practitioner4SelectElement}
          {practitioner5SelectElement}
        </div>
      )}

      {(!isIdoshienVehicleStatusOrSameFlgChecked || isIdoshienGroupStatus) && (
        <>
          <FormGroup row style={{ display: "flex", alignItems: "flex-start" }}>
            <FormGroup row>
              {practitionerNum === 2 ? (
                <FormikSelect
                  name={`${fieldName}.startTimeDate`}
                  label="開始時間"
                  options={DAY_SELECT_OPTIONS}
                  style={{ width: 80 }}
                  noerrortext
                  onChangeHook={(e): void => handleChangeDateSelect(e)}
                />
              ) : (
                <MuiTextField
                  value={startTimeValue ? startTimeValue.label : "当日"}
                  label="開始時間"
                  disabled
                  disabledStyle
                  style={{ width: 80 }}
                />
              )}
              <FormikTime
                name={`${fieldName}.startTime`}
                label=""
                placeholder="00:00"
                size="smallMedium"
                maxLength={5}
                onChangeHookTime={handleChangeInitialTime}
                style={{ marginTop: 16, marginRight: 24 }}
              />
            </FormGroup>
            <FormGroup row>
              <FormikSelect
                name={`${fieldName}.endTimeDate`}
                label="終了時間"
                options={DAY_SELECT_OPTIONS}
                style={{ width: 80 }}
                noerrortext
                onChangeHook={(e): void => handleChangeDateSelect(e)}
              />
              <FormikTime
                name={`${fieldName}.endTime`}
                placeholder="00:00"
                size="smallMedium"
                maxLength={5}
                onChangeHookTime={handleChangeInitialTime}
                style={{ marginTop: 16, marginRight: 24 }}
              />
            </FormGroup>
            {facilityType === FacilityType.KYOTAKUKAIGO &&
            formikValues.status ===
              KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value ? (
              <FormikTextField
                name={`${fieldName}.numberOfRides`}
                label="乗降回数"
                size="smallMedium"
                endAdornmentLabel="回"
              />
            ) : (
              <MuiTextField
                value={practitionerValues.numberOfTime}
                label="時間"
                size="smallMedium"
                disabled
                disabledStyle
              />
            )}
            {facilityType === FacilityType.JUDOHOMONKAIGO && (
              <MuiTextField
                value={practitionerValues.calculatedMovingHours}
                label="移動時間数"
                size="smallMedium"
                disabled
                disabledStyle
                style={{ marginLeft: 8 }}
                endAdornmentLabel="時間"
              />
            )}
          </FormGroup>

          {/* 同行支援時間フィールド */}
          {accompanyFieldDisplayFlg && (
            <ServiceDeliveryAccompanySupportField
              formikProps={props.formikProps}
              setFormikFieldValue={props.formikProps.setFieldValue}
              practitionerNum={props.practitionerNum}
              practitionerValues={props.practitionerValues}
              handleChangeDateSelect={handleChangeDateSelect}
              handleChangeInitialTime={handleChangeInitialTime}
            />
          )}

          {(facilityType !== FacilityType.KYOTAKUKAIGO ||
            formikValues.status !==
              KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value) && (
            <UnitsFields
              formikProps={props.formikProps}
              unitKey={`${fieldName}`}
              onDeleteRecord={deleteRecord}
              onChangeTime={onChangeDetailRecord}
              onChangeSelect={onChangeDetailDateSelect}
              onChangeActionClass={onChangeActionClass}
              setFormikFieldValue={props.formikProps.setFieldValue}
              isAddButton={
                practitionerValues.serviceDeliveryRecordPractitionerDetails.filter(
                  (item) => !item.isDelete
                ).length < 10
              }
              list={practitionerValues.serviceDeliveryRecordPractitionerDetails}
              practitionerNum={practitionerNum}
              practitionerValues={practitionerValues}
              actionClassLabel={actionClassLabel}
              actionClassOptions={actionClassOptions}
              facilityType={facilityType}
            />
          )}
        </>
      )}
    </div>
  );
};

export const ServiceDeliveryTimeField = withStyles(styles)(
  ServiceDeliveryTimeFieldCore
);
