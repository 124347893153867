import * as React from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      margin: "32px 16px",
      padding: "0 24px",
      maxWidth: "328px",
      minHeight: "256px"
    },
    paperScrollPaper: {
      maxHeight: "calc(100% - 64px)"
    },
    title: {
      padding: "16px 0 24px 0"
    },
    content: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0px",
      marginBottom: "16px"
    },
    button: {
      marginBottom: "16px"
    },
    footer: {
      margin: "0px",
      justifyContent: "center",
      flexDirection: "column"
    }
  });

type OwnProps = {
  isOpen: boolean;
  title: string;
  message: string | React.ReactNode;
  onDelete: () => void;
  onCancel: () => void;
  classes?: ClassNameMap;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DeleteDialogCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <Dialog
      open={props.isOpen}
      classes={{
        paper: classes.paper,
        paperScrollPaper: classes.paperScrollPaper
      }}
    >
      <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      <DialogContent className={classes.content}>{props.message}</DialogContent>
      <DialogActions className={classes.footer}>
        <KnowbeButton
          kind="textDelete"
          style={{ marginBottom: "16px" }}
          onClick={props.onDelete}
          className={classes.button}
        >
          削除する
        </KnowbeButton>
        <KnowbeButton
          kind="text"
          onClick={props.onCancel}
          className={classes.button}
        >
          キャンセル
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const DeleteDialog = withStyles(styles)(DeleteDialogCore);
