import React from "react";
import { UnitsState } from "@stores/domain/units/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { OperationsState } from "@stores/domain/mgr/GroupHome/operations/types";
import { UsersInFacilityState } from "@stores/domain/mgr/GroupHome/userInFacility/types";
import { Formik, Form, FormikActions } from "formik";
import initialValues, {
  RecordOperationsValues
} from "@initialize/mgr/GroupHome/record/operations/initialValues";
import validation from "@initialize/mgr/GroupHome/record/operations/validation";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { OperationRecord } from "@components/organisms/mgr/GroupHome/record/OperationRecord";
import { StatusTypeSummary } from "@components/organisms/mgr/GroupHome/record/StatusTypeSummary";
import { MealSummary } from "@components/organisms/mgr/common/record/MealSummary";
import { toEffectiveObject } from "@utils/object";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import deepEqual from "fast-deep-equal";
import { FieldItem } from "@interfaces/ui/form";

import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { unsetGroupHomeUsagePerformanceSummary } from "@stores/domain/mgr/GroupHome/report/actions";
import { ReportUsagePerformanceState } from "@stores/domain/mgr/GroupHome/report/types";
import { connect } from "react-redux";
import { UsagePerformanceReportModal } from "@components/organisms/mgr/common/report/UsagePerformanceReportModal";

const styles = (): StyleRules =>
  createStyles({
    operationRecordWrapper: {
      margin: "0 16px 16px"
    },
    summary: {
      marginBottom: 24,
      "& > div": {
        marginBottom: 8
      }
    }
  });

type OwnProps = {
  year: string;
  month: string;
  currentDate: Date;
  unitId: number;
  settingType: number;
  isEditing: boolean;
  editDate: string;
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  customRecords: CustomRecordsState;
  mealData?: CustomRecordsState[number];
  operations: OperationsState;
  users: UsersInFacilityState["users"];
  staffOptions: FieldItem[];
  needsStopHistory: boolean;
  postCustomOperation: (
    params: RecordOperationsValues,
    initialValues: RecordOperationsValues,
    targetDate: string,
    unitId: number
  ) => Promise<void>;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  report: ReportUsagePerformanceState;
  holidays: string[];
};

type DispatchProps = {
  fetchSummary: (yyyymmdd: string) => void;
  unsetSummary: () => void;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

const OperationRecordListCore = (props: Props): JSX.Element => {
  const { year, month, currentDate, report } = props;

  // 選択月の末日を取り出す、現在月と同じなら現在日まで
  const lastDayOfMonth = new Date(+year, +month, 0);
  const lastDay =
    +year === currentDate.getFullYear() && +month === currentDate.getMonth() + 1
      ? currentDate.getDate()
      : lastDayOfMonth.getDate();

  const dateList = Array.from({ length: lastDay }, (_, index) => {
    const day = `${index + 1}`.padStart(2, "0");
    return `${year}-${month.padStart(2, "0")}-${day}`;
  });

  // 表示選択状態に対応する利用者データ管理
  const [selectedUsersState, setSelectedUsersState] = React.useState<
    UsersInFacilityState["users"]
  >([]);

  const selectedUsers = (): UsersInFacilityState["users"] => {
    let selectedUsersValue;
    if (props.unitId === 0) {
      selectedUsersValue = [...props.users];
      return selectedUsersValue;
    }

    const units = !Array.isArray(props.optionalCustomInfo)
      ? props.optionalCustomInfo.facility_units
      : [];

    const selectedUnit = units.find((unit) => {
      return unit.id === props.unitId;
    });

    selectedUsersValue =
      selectedUnit !== undefined && props.users.length > 0
        ? selectedUnit.user_ids.map((unitUserId) => {
            return props.users.find(
              (user) => user.uif_id === unitUserId
            ) as UsersInFacilityState["users"][number];
          })
        : [];

    return selectedUsersValue;
  };

  React.useEffect(() => {
    setSelectedUsersState(selectedUsers());
  }, [props.users, props.optionalCustomInfo, props.unitId]);

  // 選択中の月までに退去日を迎える利用者データ管理
  const [
    invalidUsersByMonthState,
    setInValidUsersByMonthState
  ] = React.useState<UsersInFacilityState["users"]>([]);

  // 選択中の月までに退去日を迎える利用者を絞り込む
  const invalidUsersByMonth = (): UsersInFacilityState["users"] => {
    return selectedUsersState.filter((user) => {
      // 退去日, 表示日
      const endDate =
        user.date_end_in_service && new Date(user.date_end_in_service);
      if (endDate) return endDate <= lastDayOfMonth;
      return false;
    });
  };

  React.useEffect(() => {
    setInValidUsersByMonthState(invalidUsersByMonth());
  }, [year, month, selectedUsersState]);

  const [formValues, setFormValues] = React.useState(
    initialValues(props.operations, props.customRecords, props.unitId, dateList)
  );

  React.useEffect(() => {
    setFormValues(
      initialValues(
        props.operations,
        props.customRecords,
        props.unitId,
        dateList
      )
    );
  }, [props.customRecords, props.operations, props.unitId]);

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditDate(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);

  const confirmDiscardFormChanges = (
    nextValues: RecordOperationsValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: RecordOperationsValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const onSubmit = async (
    values: RecordOperationsValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postCustomOperation(
      values,
      formValues,
      props.editDate,
      props.unitId
    );
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  // 利用状況の詳細
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [DialogDate, setDialogDate] = React.useState("");
  const closeDialog = (): void => {
    setIsOpenDialog(false);
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => {
          const onClickEditCancel = (): void => {
            formikProps.resetForm();
            props.unsetEditDate();
            props.stopHistory(false);
          };
          return (
            <Form>
              {formikProps.values.record.length === dateList.length &&
                dateList.map((date, index) => {
                  const yyyymmdd = date.replace(/-/g, "");
                  const isHoliday = props.holidays.includes(date);
                  const operation = props.operations.operation.find(
                    (v) => v.target_date === date
                  );
                  // 現在の編集対象
                  const isEditTarget = props.editDate === date;
                  // 編集中だが編集対象でない
                  const isEditNotTarget =
                    props.isEditing && props.editDate !== date;
                  // 利用状況サマリ（食事）
                  const mealItem = operation
                    ? operation.counts.meal
                    : undefined;
                  const isExistMealItem = mealItem
                    ? mealItem.choices.some((choice) => choice.count !== 0)
                    : false;
                  // 退去していない利用者の数の算出
                  const displayDate = convertBlankSeparatorFormatToDate(
                    date.replace(/-/g, "")
                  );
                  const invalidUsersOnDateTotal =
                    invalidUsersByMonthState.length &&
                    invalidUsersByMonthState.filter((user) => {
                      // 退去日, 表示日
                      const endDate =
                        user.date_end_in_service &&
                        new Date(user.date_end_in_service);

                      if (endDate) return endDate < displayDate;

                      return false;
                    }).length;
                  const validUsersTotal =
                    selectedUsersState.length - invalidUsersOnDateTotal;
                  // 指定日付を編集状態に
                  const onClickEdit = (): void => {
                    props.setEditDate(date);
                  };
                  // 利用状況の詳細
                  const onClickDetail = (): void => {
                    setDialogDate(date);
                    props.unsetSummary(); // 他の画面や日時で取得した値が残っているためこのタイミングでリセット
                    props.fetchSummary(yyyymmdd);
                    setIsOpenDialog(true);
                  };
                  return (
                    <div
                      className={props.classes.operationRecordWrapper}
                      key={date}
                    >
                      <OperationRecord
                        index={index}
                        handleClickEdit={onClickEdit}
                        handleClickCancel={onClickEditCancel}
                        formikProps={formikProps}
                        formikFieldNamePrefix={`record[${index}]`}
                        isEditing={isEditTarget}
                        isEditNotTarget={isEditNotTarget}
                        settingType={props.settingType}
                        customRecords={props.customRecords}
                        operation={operation}
                        staffOptions={props.staffOptions}
                        date={date}
                        isHoliday={isHoliday}
                      >
                        <div className={props.classes.summary}>
                          <StatusTypeSummary
                            status_type={
                              operation
                                ? operation.counts.status_type
                                : undefined
                            }
                            disabledDetail={isEditTarget}
                            handleClickDetail={onClickDetail}
                            validUsersTotal={validUsersTotal}
                          />
                          {props.mealData &&
                            (props.mealData.visibility === 1 ||
                              isExistMealItem) && (
                              <MealSummary
                                mealData={props.mealData}
                                mealItem={mealItem}
                                date={date}
                              />
                            )}
                        </div>
                      </OperationRecord>
                    </div>
                  );
                })}
            </Form>
          );
        }}
      </Formik>
      {isOpenDialog && (
        <UsagePerformanceReportModal
          summary={report.summary}
          usagePerformance={report.usage_performance}
          isOpen={isOpenDialog}
          onClose={closeDialog}
          date={new Date(DialogDate)}
          units={props.optionalCustomInfo}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  report: state.GroupHome.report,
  holidays: state.holiday
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { GroupHome } = dispatches;
  const reportDispatcher = GroupHome.reportDispatcher(dispatch);
  return {
    fetchSummary: (yyyymmdd: string): Promise<void> =>
      reportDispatcher.fetchUsagePerformanceSummary(yyyymmdd),
    unsetSummary: (): void => {
      dispatch(unsetGroupHomeUsagePerformanceSummary());
    }
  };
};

export const OperationRecordList = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(OperationRecordListCore)
);
