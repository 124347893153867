import { generateRadioItems } from "@utils/dataNormalizer/generateRadioItems";
import { generateDropDownOptions } from "@utils/dataNormalizer/generateDropDownOptions";
import { getLabelFromOptions } from "@utils/dataNormalizer/getLabelFromOptions";
import { removeHyphenFromPostalCode } from "@utils/dataNormalizer/removeHyphenFromPostalCode";
import { getSelectedCityCode } from "@utils/dataNormalizer/getSelectedCityCode";
import { getSelectedCityGrade } from "@utils/dataNormalizer/getSelectedCityGrade";
import { getSelectedCityGrade202104 } from "@utils/dataNormalizer/getSelectedCityGrade202104";
import { getSelectedCityGrade202404 } from "@utils/dataNormalizer/getSelectedCityGrade202404";
import { getSelectedCityGradeChildrenDisability202104 } from "@utils/dataNormalizer/getSelectedCityGradeChildrenDisability202104";
import { getSelectedCityGradeChildrenDisability202404 } from "@utils/dataNormalizer/getSelectedCityGradeChildrenDisability202404";

export const DEFAULT_SELECT_VALUE = "NOT_SELECTED";

export {
  generateRadioItems,
  generateDropDownOptions,
  getLabelFromOptions,
  getSelectedCityCode,
  getSelectedCityGrade,
  getSelectedCityGrade202104,
  getSelectedCityGrade202404,
  getSelectedCityGradeChildrenDisability202104,
  getSelectedCityGradeChildrenDisability202404,
  removeHyphenFromPostalCode
};
