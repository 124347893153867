import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  inoutConsultationResults: []
};

const initialMothlyState: types.NoConstantContactSystemFlgState = {
  noConstantContactSystemFlg: false
};

export const CHIIKITEICHAKUReport202104 = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_CHIIKITEICHAKU_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_CHIIKITEICHAKU_MONTHLY:
      return { ...action.payload };
    case types.FETCH_CHIIKITEICHAKU_MONTHLY_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};

export const CHIIKITEICHAKUReportMonthly202104 = (
  state = initialMothlyState,
  action: actions.ActionTypes
): types.NoConstantContactSystemFlgState => {
  switch (action.type) {
    case types.FETCH_CHIIKITEICHAKU_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_CHIIKITEICHAKU_MONTHLY_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};
