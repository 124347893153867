import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as queryString from "query-string";

import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import UserReceipt from "@components/organisms/download/print/UserReceipt";

import { dateToLocalisedString } from "@utils/date";

type OwnProps = {
  dataKey: string;
  query: string;
};

type DispatchProps = {
  fetchUserReceipt: (data: string) => void;
};

type Props = OwnProps & DispatchProps & AppState;

/**
 * 履歴 > 領収書プレビュー
 */
class UserReceiptPreview extends React.Component<Props> {
  public componentWillMount(): void {
    const { dataKey } = this.props;
    this.props.fetchUserReceipt(dataKey);
  }

  public render(): JSX.Element | string {
    const queryParam = queryString.parse(this.props.query);
    const targetDate =
      queryParam.target_date ||
      dateToLocalisedString(new Date(), "YYYY年M月D日");
    if (
      this.props.invoice.invoiceUserReceiptData &&
      this.props.invoice.invoiceUserReceiptData.length === 0
    ) {
      return "";
    }
    return (
      <PreviewTemplate>
        {this.props.invoice.invoiceUserReceiptData.map((data, key) => {
          const uniqueKey = `user-receipt-${key}`;
          return (
            <UserReceipt key={uniqueKey} data={data} targetDate={targetDate} />
          );
        })}
      </PreviewTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);
  return {
    fetchUserReceipt: (dataKey: string): void => {
      invoiceDispatched.downloadUserReceiptJson(dataKey);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserReceiptPreview);
