import * as React from "react";

import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import {
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { CHIIKIIKO_MODAL_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";
import { FieldItem } from "@interfaces/ui/form";
import { Typography } from "@material-ui/core";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";
import { SupportState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { FormikSubmitButtonWrapper } from "@components/molecules/FormikSubmitButton";
import { FormikProps } from "formik";
import { SupportRecordFormValues } from "@initialize/mgr/Cseg/record/dailyRecord/initialValues";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    default: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 1
    },
    editDefault: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    label_name: {
      fontSize: 16,
      lineHeight: 1.75
    },
    label_shogaiji: {
      fontSize: 14,
      lineHeight: 1.43,
      color: "#3daa8d"
    },
    label_status: {
      fontSize: 12,
      lineHeight: 1.33
    },
    buttons: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    },
    contentField: {
      paddingLeft: "200px"
    },
    helpIcon: {
      position: "relative",
      top: 3,
      marginRight: 0
    },
    sectionTitle: {
      display: "flex",
      justifyContent: "space-between"
    }
  });

type OwnProps = {
  staffOptions: FieldItem[];
  formikNamePrefix: string;
  supportRecord: SupportState[number];
  displayName: string;
  isEditing: boolean;
  isTarget: boolean;
  onClickEdit: (e: React.MouseEvent, uifId: number) => void;
  onClickEditCancel: () => void;
  formikProps: FormikProps<SupportRecordFormValues>;
  submitError: () => void;
  facilityType: FacilityType;
  facilitySubType: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DailySupportTableRowCore = (props: Props): JSX.Element => {
  const {
    classes,
    staffOptions,
    formikNamePrefix,
    supportRecord,
    displayName,
    isEditing,
    isTarget,
    formikProps,
    submitError,
    onClickEdit,
    onClickEditCancel,
    facilityType,
    facilitySubType
  } = props;

  const displayStaffs = supportRecord.staffs
    ? supportRecord.staffs.map((v) => v.snapshot_name).join("、")
    : "";

  const isShogaijiSodan =
    facilityType === FacilityType.KEIKAKUSODAN &&
    (facilitySubType === FACILITY_TYPE_SHOGAIJISODAN ||
      facilitySubType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) &&
    supportRecord.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN;

  const statusList = (): {
    label: string;
    value: string;
  }[] => {
    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
        if (isShogaijiSodan) {
          return STATUS_LIST_SHOGAIJISODAN;
        }
        return STATUS_LIST;
      case FacilityType.CHIIKITEICHAKU:
        return CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST;
      case FacilityType.CHIIKIIKO:
        return CHIIKIIKO_MODAL_STATUS_LIST;
      default:
        return STATUS_LIST;
    }
  };

  const status = statusList().find(
    ({ value }) => value === `${supportRecord.status}`
  );
  const displayStatus = status ? status.label : STATUS_LIST[0].label;

  const choseStaffs = supportRecord.staffs
    ? supportRecord.staffs
        .filter((v) => !!v.snapshot_name)
        .map((v) => {
          return { id: Number(v.id), name: v.snapshot_name };
        })
    : [];

  const mergedStaffOptions = generateMergedStaffOptions(
    staffOptions,
    choseStaffs
  );

  return (
    <div
      className={isEditing && !isTarget ? classes.editDefault : classes.default}
    >
      <div className={classes.sectionTitle}>
        <div>
          <Typography
            gutterBottom
            variant="subtitle1"
            color="primary"
            className={classes.label_name}
          >
            {displayName}
          </Typography>
          {isShogaijiSodan && (
            <Typography
              gutterBottom
              variant="subtitle1"
              className={classes.label_shogaiji}
            >
              障害児
            </Typography>
          )}
          <Typography
            gutterBottom
            variant="subtitle2"
            className={classes.label_status}
          >
            {displayStatus}
          </Typography>
        </div>
        {isEditing && isTarget ? (
          <div className={classes.buttons}>
            <KnowbeButton kind="outlineWhite" onClick={onClickEditCancel}>
              キャンセル
            </KnowbeButton>
            <FormikSubmitButtonWrapper
              buttonName="保存する"
              formikProps={formikProps}
              errorAction={submitError}
            />
          </div>
        ) : (
          <KnowbeButton
            key="edit-start-button"
            style={{ width: 120, marginLeft: 8, height: 36 }}
            onClick={(e): void =>
              onClickEdit(e, supportRecord.users_in_facility_id)
            }
          >
            編集
          </KnowbeButton>
        )}
      </div>
      <div className={classes.contentField}>
        <RecordSupportTableField
          type="text"
          label="支援内容"
          name={`${formikNamePrefix}.support_content`}
          value={supportRecord.support_content || ""}
          defaultValue=""
          placeholder=""
          isEditing={isEditing && isTarget}
        />
        <RecordSupportTableField
          type="text"
          label="職員考察"
          name={`${formikNamePrefix}.staff_comment`}
          value={supportRecord.staff_comment || ""}
          defaultValue=""
          placeholder=""
          isEditing={isEditing && isTarget}
          tooltip={
            <HelpToolTip
              classes={{ icon: classes.helpIcon }}
              placement="right-end"
              title={<HelpTipMessages name="csegStaffComment" />}
            />
          }
        />
        <RecordSupportTableField
          type="multiple"
          label="記録者"
          name={`${formikNamePrefix}.displayStaffIds`}
          value={displayStaffs}
          defaultValue=""
          placeholder="選択してください"
          isEditing={isEditing && isTarget}
          options={[{ categoryName: "", items: mergedStaffOptions }]}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
        />
      </div>
    </div>
  );
};

export const DailySupportTableRow = withStyles(styles)(
  DailySupportTableRowCore
);
