import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

import red from "@material-ui/core/colors/red";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    root: {
      color: palette.error.main,
      backgroundColor: red[50],
      cursor: "pointer",
      marginTop: "2px"
    },
    grow: {
      flexGrow: 1,
      textAlign: "center"
    }
  });

type Props = WithStyles<typeof styles> & {
  message: string;
  onClick: () => void;
  dialog?: React.ReactNode;
};

/**
 * 請求エラーを表示するAppBar
 */
const InvoiceErrorBar = ({
  message,
  classes,
  onClick,
  dialog
}: Props): JSX.Element => {
  return (
    <AppBar
      position="sticky"
      className={classes.root}
      elevation={0}
      onClick={onClick}
    >
      <Toolbar>
        <Typography variant="body1" color="inherit" className={classes.grow}>
          {message}
        </Typography>
      </Toolbar>
      {dialog || null}
    </AppBar>
  );
};

export default withStyles(styles)(InvoiceErrorBar);
