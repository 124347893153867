import deepEqual from "fast-deep-equal";
import {
  SEIKATSUKAIGOReport,
  SEIKATSUKAIGOSummary,
  DailyInOutRecords,
  UserInOutRecords,
  SEIKATSUKAIGOReportState,
  SEIKATSUKAIGOReportTypeInterface,
  REPEAT_DAILY,
  REPEAT_USER
} from "./types";

import {
  dateInHyphenYYYYMMDDFormat,
  dateInYYYYMMDDFormat,
  selectDateValueToDate
} from "@utils/date";
import { GetInOutDailySummaryResponse } from "@api/requests/inOut/getInOutSummary";
import { GetInOutUserSummaryResponse } from "@api/requests/inOut/getInOutUserSummary";
import { GetInOutDailyReportResponse } from "@api/requests/inOut/getInOut";
import { GetInOutUserReportResponse } from "@api/requests/inOut/getInOutUser";
import { FacilityState } from "../facility/types";
import { RequestParam } from "@api/requests/inOut/putInOutRecords";
import { isEmpty } from "lodash-es";
import { InitialDataValues } from "@initialize/mgr/SEIKATSUKAIGO/report/initialValues";
import {
  Checkbox,
  SEIKATSUKAIGO_SUMMARY_SERVICE_STATUS
} from "@constants/variables";
import castNumberOrNull from "@utils/dataNormalizer/castNumberOrNull";
import castString from "@utils/dataNormalizer/castString";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import castNumber from "@utils/dataNormalizer/castNumber";

const notNullCastNumber = (
  value?: string | number | null | undefined
): number => {
  if (value === undefined || value === null) {
    return 0;
  }
  return Number(value);
};

// storeのkeyからpiaRequestのkeyに変換するmap表 Request必須値は除く
// "StoreToRequestParam"で定義すること
// + "removeNoChangeData"内の"differenceObject"で初期定義「しない」こと
// = 値に変更ない場合はパラメータを送らなくなる
const StoreToRequestParam = {
  inTime: {
    storeKey: "inTime",
    requestKey: "inTime"
  },
  outTime: {
    storeKey: "outTime",
    requestKey: "outTime"
  },
  lifeSupportHubInDistrictFlg: {
    storeKey: "lifeSupportHubInDistrictFlg",
    requestKey: "lifeSupportHubInDistrictFlg"
  },
  extended: {
    storeKey: "extended",
    requestKey: "extended"
  },
  memo: {
    storeKey: "memo",
    requestKey: "memo"
  },
  nutritionScreeningFlg: {
    storeKey: "nutritionScreeningFlg",
    requestKey: "nutritionScreeningFlg"
  },
  sputumImplementationFlg: {
    storeKey: "sputumImplementationFlg",
    requestKey: "sputumImplementationFlg"
  },
  extended_202404: {
    storeKey: "extended_202404",
    requestKey: "extended_202404"
  },
  emergencyAcceptanceAdditionFlg: {
    storeKey: "emergencyAcceptanceAdditionFlg",
    requestKey: "emergencyAcceptanceAdditionFlg"
  },
  intensiveSupportFlg: {
    storeKey: "intensiveSupportFlg",
    requestKey: "intensiveSupportFlg"
  },
  intensiveSupportStartDate: {
    storeKey: "intensiveSupportStartDate",
    requestKey: "intensiveSupportStartDate"
  },
  nutritionImprovementServiceFlg: {
    storeKey: "nutritionImprovementServiceFlg",
    requestKey: "nutritionImprovementServiceFlg"
  },
  calculatedHours: {
    storeKey: "calculatedHours",
    requestKey: "calculatedHours"
  }
};

// statusによる不要なデータのstoreKey一覧
const nullDataByStatus = {
  status1: [
    "inTime",
    "outTime",
    "didGetFood",
    "lifeSupportHubInDistrictFlg",
    "extended",
    "nutritionScreeningFlg",
    "extended_202404",
    "emergencyAcceptanceAdditionFlg",
    "intensiveSupportFlg",
    "intensiveSupportStartDate",
    "nutritionImprovementServiceFlg",
    "calculatedHours"
  ],
  status2: ["lifeSupportHubInDistrictFlg"],
  status5: [
    "inTime",
    "outTime",
    "didGetFood",
    "lifeSupportHubInDistrictFlg",
    "extended",
    "nutritionScreeningFlg",
    "extended_202404",
    "emergencyAcceptanceAdditionFlg",
    "intensiveSupportFlg",
    "intensiveSupportStartDate",
    "calculatedHours"
  ],
  status6: [
    "didGetFood",
    "lifeSupportHubInDistrictFlg",
    "extended",
    "extended_202404"
  ],
  status7: [
    "inTime",
    "outTime",
    "didGetFood",
    "extended",
    "emergencyAcceptanceAdditionFlg",
    "nutritionScreeningFlg",
    "extended_202404",
    "emergencyAcceptanceAdditionFlg",
    "intensiveSupportFlg",
    "intensiveSupportStartDate",
    "calculatedHours"
  ],
  status10: [
    "inTime",
    "outTime",
    "didGetFood",
    "lifeSupportHubInDistrictFlg",
    "extended",
    "nutritionScreeningFlg",
    "extended_202404",
    "emergencyAcceptanceAdditionFlg",
    "intensiveSupportFlg",
    "intensiveSupportStartDate",
    "nutritionImprovementServiceFlg",
    "calculatedHours"
  ]
};

/**
 * 訪問支援項目用の時間差分計算
 * @param inTime 開始時間
 * @param outTime 終了時間
 */
const defTime = (inTime: string, outTime: string): number => {
  const startTime = new Date(inTime);
  const endTime = new Date(outTime);
  const diffTime =
    Math.floor(endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
  if (diffTime >= 1) {
    return 2;
  }
  return 1;
};
/**
 * yyyy-mm-dd HH:MM:SSの作成
 * @param date yyyy-mm-dd
 * @param time HH:MM
 */
const createTime = (date?: string, time?: string): string => {
  return date && time ? date.concat(" ") + time.concat(":00") : "";
};
/**
 * パラメータの生成
 * @param data
 */
const removeUndefinedParam = (
  data: RequestParam["otherParam"]
): RequestParam["otherParam"] => {
  const param = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) {
      param[key] = data[key];
    }
  });

  return param as RequestParam["otherParam"];
};
/**
 * 差分チェック uif_id・targetDate・facirityType・travelTime・pickupPremisesは除く
 */
const isDiff = (before: string | number, after: string | number): boolean => {
  return before !== after;
};

/**
 * statusによって不要な値を変更する
 * @param value
 * @param facilityState
 */
const convertParamByStatus = (
  value: SEIKATSUKAIGOReport,
  facilityState: FacilityState
): SEIKATSUKAIGOReport => {
  // 初期化データの作成
  const initialValue = {
    trialUsageKind: "0",
    lifeSupportHubInDistrictFlg: "0",
    inTime: "",
    outTime: "",
    extended: "0",
    didGetFood: "0" as string | null,
    travelTime: "0" as string | null,
    pickupPremises: "0",
    memo: "",
    severeDisabilitySupportType: 0 as number | null,
    nutritionScreeningFlg: 0 as number | null,
    extended_202404: "0",
    emergencyAcceptanceAdditionFlg: Checkbox.OFF as string,
    intensiveSupportFlg: false,
    intensiveSupportStartDate: null as string | null,
    sputumImplementationFlg: 0 as number | null,
    bathSupportFlg: 0 as number | null,
    nutritionImprovementServiceFlg: 0 as number | null,
    calculatedHours: 0 as number | null
  };

  // サービス提供の状況確認、不要項目の初期化
  switch (value.status) {
    case 1:
      // 食事・送迎にnullを設定するとAPIから利用者情報のデフォルト値が返ってくる
      initialValue.didGetFood = null;
      initialValue.travelTime = null;
      break;
    case 2:
      initialValue.inTime = value.inTime ? value.inTime : initialValue.inTime;
      initialValue.outTime = value.outTime
        ? value.outTime
        : initialValue.outTime;
      initialValue.extended = value.extended
        ? value.extended
        : initialValue.extended;
      if (facilityState.mealSaservedServiceFlag) {
        initialValue.didGetFood = value.didGetFood
          ? value.didGetFood
          : initialValue.didGetFood;
      }
      if (facilityState.transferServiceFlag) {
        initialValue.travelTime = value.travelTime
          ? value.travelTime
          : initialValue.travelTime;
        initialValue.pickupPremises = value.pickupPremises
          ? value.pickupPremises
          : initialValue.pickupPremises;
      }
      initialValue.severeDisabilitySupportType = value.severeDisabilitySupportType
        ? castNumberOrNull(value.severeDisabilitySupportType)
        : initialValue.severeDisabilitySupportType;
      initialValue.nutritionScreeningFlg = value.nutritionScreeningFlg
        ? value.nutritionScreeningFlg
        : initialValue.nutritionScreeningFlg;
      initialValue.sputumImplementationFlg = value.sputumImplementationFlg
        ? castNumberOrNull(value.sputumImplementationFlg)
        : initialValue.sputumImplementationFlg;
      initialValue.extended_202404 = value.extended_202404
        ? value.extended_202404
        : initialValue.extended_202404;
      initialValue.bathSupportFlg = value.bathSupportFlg
        ? castNumberOrNull(value.bathSupportFlg)
        : initialValue.bathSupportFlg;
      initialValue.emergencyAcceptanceAdditionFlg = value.emergencyAcceptanceAdditionFlg
        ? value.emergencyAcceptanceAdditionFlg
        : initialValue.emergencyAcceptanceAdditionFlg;
      initialValue.intensiveSupportFlg = !!value.intensiveSupportFlg;
      initialValue.intensiveSupportStartDate = value.intensiveSupportStartDate
        ? value.intensiveSupportStartDate
        : initialValue.intensiveSupportStartDate;
      initialValue.calculatedHours = value.calculatedHours;
      initialValue.nutritionImprovementServiceFlg = value.nutritionImprovementServiceFlg
        ? castNumberOrNull(value.nutritionImprovementServiceFlg)
        : initialValue.nutritionImprovementServiceFlg;
      break;
    case 5:
      initialValue.nutritionScreeningFlg = value.nutritionScreeningFlg
        ? value.nutritionScreeningFlg
        : initialValue.nutritionScreeningFlg;
      initialValue.nutritionImprovementServiceFlg = value.nutritionImprovementServiceFlg
        ? castNumber(value.nutritionImprovementServiceFlg)
        : initialValue.nutritionImprovementServiceFlg;
      break;
    case 6:
      initialValue.inTime = value.inTime ? value.inTime : initialValue.inTime;
      initialValue.outTime = value.outTime
        ? value.outTime
        : initialValue.outTime;
      initialValue.sputumImplementationFlg = value.sputumImplementationFlg
        ? castNumberOrNull(value.sputumImplementationFlg)
        : initialValue.sputumImplementationFlg;
      initialValue.nutritionScreeningFlg = value.nutritionScreeningFlg
        ? value.nutritionScreeningFlg
        : initialValue.nutritionScreeningFlg;
      initialValue.bathSupportFlg = value.bathSupportFlg
        ? castNumberOrNull(value.bathSupportFlg)
        : initialValue.bathSupportFlg;
      initialValue.emergencyAcceptanceAdditionFlg = value.emergencyAcceptanceAdditionFlg
        ? value.emergencyAcceptanceAdditionFlg
        : initialValue.emergencyAcceptanceAdditionFlg;
      initialValue.intensiveSupportFlg = !!value.intensiveSupportFlg;
      initialValue.intensiveSupportStartDate = value.intensiveSupportStartDate
        ? value.intensiveSupportStartDate
        : initialValue.intensiveSupportStartDate;
      initialValue.calculatedHours = value.calculatedHours;
      initialValue.nutritionImprovementServiceFlg = value.nutritionImprovementServiceFlg
        ? castNumberOrNull(value.nutritionImprovementServiceFlg)
        : initialValue.nutritionImprovementServiceFlg;
      break;
    case 7:
      initialValue.lifeSupportHubInDistrictFlg = value.lifeSupportHubInDistrictFlg
        ? value.lifeSupportHubInDistrictFlg
        : initialValue.lifeSupportHubInDistrictFlg;
      initialValue.trialUsageKind = value.trialUsageKind
        ? value.trialUsageKind
        : initialValue.trialUsageKind;
      break;
    case 10:
      initialValue.nutritionScreeningFlg = value.nutritionScreeningFlg
        ? value.nutritionScreeningFlg
        : initialValue.nutritionScreeningFlg;
      initialValue.nutritionImprovementServiceFlg = value.nutritionImprovementServiceFlg
        ? castNumberOrNull(value.nutritionImprovementServiceFlg)
        : initialValue.nutritionImprovementServiceFlg;
      break;
    default:
  }

  return {
    ...value,
    inTime: initialValue.inTime,
    outTime: initialValue.outTime,
    extended: initialValue.extended,
    didGetFood: initialValue.didGetFood,
    travelTime: initialValue.travelTime,
    pickupPremises: initialValue.pickupPremises,
    trialUsageKind: initialValue.trialUsageKind,
    lifeSupportHubInDistrictFlg: initialValue.lifeSupportHubInDistrictFlg,
    severeDisabilitySupportType: initialValue.severeDisabilitySupportType,
    bathSupportFlg: initialValue.bathSupportFlg,
    // 備考は全サービス提供で必須送信項目の為、switch外で処理
    memo: value.memo && !isEmpty(value.memo) ? value.memo : initialValue.memo,
    nutritionScreeningFlg: initialValue.nutritionScreeningFlg,
    emergencyAcceptanceAdditionFlg: initialValue.emergencyAcceptanceAdditionFlg,
    calculatedHours: initialValue.calculatedHours,
    sputumImplementationFlg: initialValue.sputumImplementationFlg,
    extended_202404: initialValue.extended_202404,
    intensiveSupportFlg: booleanToNumber0or1(initialValue.intensiveSupportFlg),
    intensiveSupportStartDate: initialValue.intensiveSupportStartDate,
    nutritionImprovementServiceFlg: initialValue.nutritionImprovementServiceFlg
  };
};

const createResultInOutRecord = (
  target: SEIKATSUKAIGOReport
): SEIKATSUKAIGOReport => {
  return {
    uif_id: castNumberOrNull(target.uif_id),
    inoutRecordsId: castNumberOrNull(target.inoutRecordsId),
    name: castString(target.name),
    target_date: castString(target.target_date),
    status: castNumberOrNull(target.status),
    inTime: castString(target.inTime),
    outTime: castString(target.outTime),
    travelTime: isEmpty(castString(target.travelTime))
      ? "0"
      : castString(target.travelTime),
    pickupPremises: isEmpty(castString(target.pickupPremises))
      ? "0"
      : castString(target.pickupPremises),
    visitSupport: castNumberOrNull(target.visitSupport),
    didGetFood: castString(target.didGetFood),
    trialUsageKind: castString(target.trialUsageKind),
    lifeSupportHubInDistrictFlg: castString(target.lifeSupportHubInDistrictFlg),
    extended: castString(target.extended),
    is_holiday: target.is_holiday,
    memo: target.memo,
    facilityType: "SEIKATSUKAIGO",
    initialFlg: castNumberOrNull(target.inoutRecordsId) === null,
    severeDisabilitySupportType: castNumberOrNull(
      target.severeDisabilitySupportType
    ),
    is_severe_disability_support: target.is_severe_disability_support,
    nutritionScreeningFlg: target.nutritionScreeningFlg,
    sputumImplementationFlg: target.sputumImplementationFlg,
    extended_202404: castString(target.extended_202404),
    bathSupportFlg: target.bathSupportFlg,
    emergencyAcceptanceAdditionFlg: castString(
      target.emergencyAcceptanceAdditionFlg
    ),
    intensiveSupportFlg: target.intensiveSupportFlg,
    intensiveSupportStartDate: target.intensiveSupportStartDate,
    calculatedHours: castNumberOrNull(target.calculatedHours),
    nutritionImprovementServiceFlg: castNumberOrNull(
      target.nutritionImprovementServiceFlg
    )
  };
};

/**
 * putで送る用の型に変形
 * @param requestReport
 * @param otherParams
 */
const createPutRequestPamram = (
  requestReport: SEIKATSUKAIGOReport,
  otherParams: RequestParam["otherParam"]
): RequestParam => {
  const date = requestReport.target_date
    ? dateInYYYYMMDDFormat(new Date(requestReport.target_date))
    : null;
  return {
    yyyymmdd: castNumberOrNull(Number(date)),
    uif_id: castNumberOrNull(requestReport.uif_id),
    otherParam: otherParams
  };
};

/**
 * 差分抽出 差分以外はundefinedで対応
 * @param beforeList
 * @param afterList
 * @param facilityState
 */
const removeNoChangeData = (
  beforeList: SEIKATSUKAIGOReport[],
  afterList: SEIKATSUKAIGOReport[],
  facilityState: FacilityState
): RequestParam => {
  let resultAfter = {} as SEIKATSUKAIGOReport;
  let resultBefore = {} as SEIKATSUKAIGOReport;
  // 差分の行を抽出
  afterList.map((after, idx) => {
    if (!deepEqual(after, beforeList[idx])) {
      resultAfter = after;
      resultBefore = beforeList[idx];
    }
    return undefined;
  });
  resultAfter = convertParamByStatus(
    resultAfter as SEIKATSUKAIGOReport,
    facilityState
  );
  // 必須分初期化
  const differenceObject: RequestParam["otherParam"] = {
    status: resultAfter.status,
    restTime: 60,
    didGetFood: resultAfter.didGetFood ? resultAfter.didGetFood : null,
    travelTime: resultAfter.travelTime ? resultAfter.travelTime : null,
    pickupPremises: resultAfter.pickupPremises
      ? resultAfter.pickupPremises
      : "0",
    // 体験利用支援種別は全サービス利用状況で必須扱い。
    trialUsageKind: resultAfter.trialUsageKind,
    facilityType: "SEIKATSUKAIGO",
    severeDisabilitySupportType: resultAfter.severeDisabilitySupportType
      ? resultAfter.severeDisabilitySupportType
      : 0,
    bathSupportFlg: resultAfter.bathSupportFlg ? resultAfter.bathSupportFlg : 0,
    nutritionImprovementServiceFlg: resultAfter.nutritionImprovementServiceFlg
      ? resultBefore.nutritionImprovementServiceFlg
      : 0
  };
  // nullにすべきデータのkeyの取得
  const nullDataList = nullDataByStatus[`status${resultAfter.status}`]
    ? nullDataByStatus[`status${resultAfter.status}`]
    : [];

  // 差分の項目を抽出
  Object.keys(StoreToRequestParam).forEach((key) => {
    const { requestKey } = StoreToRequestParam[key];
    const { storeKey } = StoreToRequestParam[key];
    differenceObject[requestKey] =
      isDiff(resultAfter[storeKey], resultBefore[storeKey]) ||
      nullDataList.includes(storeKey)
        ? resultAfter[storeKey]
        : undefined;
  });
  // 初回時のみ且つstatusが【通所】の時、食事提供は必ず入れる。default設定の為
  if (
    resultBefore.initialFlg &&
    resultAfter.status ===
      SEIKATSUKAIGO_SUMMARY_SERVICE_STATUS.USUAL_PLACE.value
  ) {
    differenceObject.didGetFood = resultAfter.didGetFood;
  }
  // 開始時間と終了時間は兄弟関係であるため、どちらか片方に差分が存在した場合どっちも差分とみなす
  if (
    differenceObject.inTime !== undefined ||
    differenceObject.outTime !== undefined
  ) {
    differenceObject.inTime = resultAfter.inTime;
    differenceObject.outTime = resultAfter.outTime;
  }
  // フラグと算定開始日のどちらかに差分があればどちらも差分とみなす
  if (
    differenceObject.intensiveSupportFlg !== undefined ||
    differenceObject.intensiveSupportStartDate !== undefined
  ) {
    differenceObject.intensiveSupportFlg = resultAfter.intensiveSupportFlg;
    differenceObject.intensiveSupportStartDate =
      resultAfter.intensiveSupportStartDate;
  }
  const otherParam = removeUndefinedParam(differenceObject);
  return createPutRequestPamram(resultAfter, otherParam);
};

const nomalizeReportList = (
  usersList:
    | GetInOutDailyReportResponse["data"]["users"]
    | GetInOutUserReportResponse["data"]["records"]
): SEIKATSUKAIGOReport[] => {
  const reportList: SEIKATSUKAIGOReport[] = [];
  Object.keys(usersList).forEach((key) => {
    const target = usersList[key];
    const report = createResultInOutRecord(target);
    reportList.push(report);
  });
  return reportList;
};

const nomalizeCountPerStatus = (
  result: GetInOutDailySummaryResponse | GetInOutUserSummaryResponse
): SEIKATSUKAIGOSummary["countsPerStatus"] => {
  const resultList: SEIKATSUKAIGOSummary["countsPerStatus"] = [];
  Object.keys(result.data.summary.countsPerStatus).forEach((key) => {
    const target = result.data.summary.countsPerStatus[key];
    resultList.push({
      status: castNumberOrNull(target.status),
      count: castNumberOrNull(target.count)
    });
  });
  return resultList;
};

const nomalizeBaseSummary = (
  result: GetInOutDailySummaryResponse | GetInOutUserSummaryResponse
): SEIKATSUKAIGOSummary => {
  return {
    serviceCounts: {
      oneWayCount: castNumberOrNull(
        result.data.summary.serviceCounts.oneWayCount
      ),
      pickupCount: castNumberOrNull(
        result.data.summary.serviceCounts.pickupCount
      ),
      foodCount: castNumberOrNull(result.data.summary.serviceCounts.foodCount)
    },
    countsPerStatus: nomalizeCountPerStatus(result),
    inoutRecords: []
  };
};

const nomalizeDailyInOutRecords = (
  result: GetInOutDailySummaryResponse
): DailyInOutRecords[] => {
  const resultList: DailyInOutRecords[] = [];
  Object.keys(result.data.inoutRecords).forEach((key) => {
    const target = result.data.inoutRecords[key];
    resultList.push({
      userName: castString(target.userName),
      recipientNumber: castString(target.recipientNumber),
      status: castNumberOrNull(target.status)
    });
  });
  return resultList;
};

const nomalizeUserInOutRecords = (
  result: GetInOutUserSummaryResponse
): UserInOutRecords[] => {
  const resultList: UserInOutRecords[] = [];
  Object.keys(result.data.inoutRecords).forEach((key) => {
    const target = result.data.inoutRecords[key];
    resultList.push({
      date: castString(target.date),
      status: castNumberOrNull(target.status)
    });
  });
  return resultList;
};

export const normalizeSEIKATSUKAIGODailyReportDataFromAPI = (
  result: GetInOutDailyReportResponse,
  targetDate?: Date
): SEIKATSUKAIGOReportState => {
  const formatedDate = !targetDate
    ? ""
    : dateInHyphenYYYYMMDDFormat(targetDate);
  const normalizeReportList = nomalizeReportList(result.data.users);
  return {
    additionsDaily: {
      openShortTime: notNullCastNumber(result.data.additions.openShortTime),
      targetDate: formatedDate
    },
    reportDaily: {
      reportList: JSON.parse(JSON.stringify(normalizeReportList))
    },
    reportUser: {
      reportList: [],
      numberOfAbsence: 0
    }
  };
};

export const normalizeSEIKATSUKAIGOUserReportDataFromAPI = (
  result: GetInOutUserReportResponse
): SEIKATSUKAIGOReportState["reportUser"] => {
  const normalizeReportList = nomalizeReportList(result.data.records);
  return {
    reportList: JSON.parse(JSON.stringify(normalizeReportList)),
    numberOfAbsence: castNumberOrNull(result.data.counts.numberOfAbsence)
  };
};

export const nomalizeSEIKATSUKAIGODailySummaryDataFromAPI = (
  result: GetInOutDailySummaryResponse
): SEIKATSUKAIGOSummary => {
  const inoutRecords = nomalizeDailyInOutRecords(result);
  const baseSummary = nomalizeBaseSummary(result);
  baseSummary.inoutRecords = inoutRecords;
  return baseSummary;
};

export const nomalizeSEIKATSUKAIGOUserSummaryDataFromAPI = (
  result: GetInOutUserSummaryResponse
): SEIKATSUKAIGOSummary => {
  const inoutRecords = nomalizeUserInOutRecords(result);
  const baseSummary = nomalizeBaseSummary(result);
  baseSummary.inoutRecords = inoutRecords;
  return baseSummary;
};

export const normalizeSEIKATSUKAIGOReportDataToAPI = (
  reportListBefore: SEIKATSUKAIGOReport[],
  reportListAfter: SEIKATSUKAIGOReport[],
  facilityState: FacilityState
): RequestParam => {
  return removeNoChangeData(reportListBefore, reportListAfter, facilityState);
};

/**
 * formの値をstoreに反映
 * @param index
 * @param values
 * @param reportList
 * @param type
 */
export const addChangedDataToReportList = (
  index: number,
  values: InitialDataValues,
  reportList: SEIKATSUKAIGOReport[],
  type: SEIKATSUKAIGOReportTypeInterface["type"]
): SEIKATSUKAIGOReport[] => {
  const result = JSON.parse(JSON.stringify(reportList));
  Object.keys(reportList).forEach((key) => {
    if (
      (reportList[key].uif_id === index && type === REPEAT_DAILY) ||
      (reportList[key].target_date === values.initial.targetDate &&
        type === REPEAT_USER)
    ) {
      result[key].status = parseInt(values.initial.status, 10);

      const date = values.initial.targetDate;
      result[key].target_date = date;
      result[key].memo = values.initial.memo;

      result[key].inTime = createTime(date, values.initial.inTime);
      result[key].outTime = createTime(date, values.initial.outTime);
      result[key].extended = values.initial.extended;
      result[key].didGetFood = values.initial.didGetFood;
      result[key].travelTime = values.initial.travelTime;
      result[key].visitSupport =
        values.initial.status === "6"
          ? defTime(result[key].inTime, result[key].outTime)
          : "0";
      result[key].pickupPremises = values.initial.pickupPremises;
      result[key].trialUsageKind = values.initial.trialUsageKind;
      result[key].lifeSupportHubInDistrictFlg = values.initial
        .lifeSupportHubInDistrictFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[
        key
      ].severeDisabilitySupportType = values.initial.severeDisabilitySupportType.toString();
      if (result[key].initialFlg === true) result[key].initialFlg = false;
      result[key].nutritionScreeningFlg = values.initial.nutritionScreeningFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[key].sputumImplementationFlg = values.initial
        .sputumImplementationFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[key].extended_202404 = values.initial.extended_202404;
      result[key].bathSupportFlg = values.initial.bathSupportFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[key].emergencyAcceptanceAdditionFlg = values.initial
        .emergencyAcceptanceAdditionFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[key].intensiveSupportFlg = booleanToNumber0or1(
        values.initial.intensiveSupportFlg
      );
      result[key].intensiveSupportStartDate = booleanToNumber0or1(
        values.initial.intensiveSupportFlg
      )
        ? selectDateValueToDate(values.initial.intensiveSupportStartDate)
        : null;
      result[key].nutritionImprovementServiceFlg = values.initial
        .nutritionImprovementServiceFlg
        ? Checkbox.ON
        : Checkbox.OFF;
      result[key].calculatedHours =
        values.initial.calculatedHours === ""
          ? null
          : castNumberOrNull(values.initial.calculatedHours);
    }
  });
  return result;
};
