import * as React from "react";
import {
  createStyles,
  Theme,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    titleContainer: {
      marginBottom: spacing.unit * 2
    },
    noMarginTitleContainer: {
      marginBottom: 0
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      minHeight: 32,
      marginBottom: 1
    }
  });

type OwnProps = {
  label: string;
  subLabel?: string;
  subClassName?: string;
  isTitleNoMargin?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SectionTitle = (props: Props): JSX.Element => (
  <div
    className={
      props.isTitleNoMargin
        ? props.classes.noMarginTitleContainer
        : props.classes.titleContainer
    }
  >
    <Typography
      gutterBottom
      variant="h6"
      color="primary"
      className={props.classes.flex}
    >
      <div>{props.label}</div>
      {props.subClassName && props.subLabel && (
        <div className={props.subClassName}>{props.subLabel}</div>
      )}
    </Typography>
    <Divider variant="middle" className={props.classes.divider} />
  </div>
);

export default withStyles(styles)(SectionTitle);
