import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/SHISETSUNYUSHO/report/initial";
import {
  STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT,
  STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT,
  STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT,
  StatusType,
  STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY,
  HospitalizationOvernightstay,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS,
  STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS,
  HospitalizationDays,
  STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL,
  INTENSIVE_SUPPORT_FLG
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import Paper from "@material-ui/core/Paper";
import { FacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import {
  DEFAULT_SELECT_DATE_VALUE,
  INT_FALSE_FROM_API
} from "@constants/variables";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import { getDefaultHospitalizationDaysDialogFields } from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { convertYYYYMMToYYYY_MM } from "@utils/date";
import { createOptionsHospitalizationOvernightStayDialog } from "@utils/domain/mgr/SHISETSUNYUSHO/report/createOptionsHospitalizationOvernightStay";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { SEVERE_DISABILITY_SUPPORT_TYPE } from "@constants/mgr/GroupHome/variables";

const styles = (): StyleRules =>
  createStyles({
    checkbox: {
      paddingLeft: 12,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      height: 22.8
    },
    typographyMargin: { marginBottom: 11 },
    disabled: {
      color: "rgba(0, 0, 0, 0.38)"
    },
    foodGroup: {
      marginBottom: 30,
      marginTop: 0,
      minHeight: 24
    },
    hospitalizationDaysReadOnly: {
      marginBottom: 48,
      width: 128,
      minHeight: 52
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  facilityState: FacilityState;
  userInFacilityState: UsersInFacilityState["user"]["user_in_facility_shisetsunyusho"];
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  serviceStatus?: number;
};

type State = {
  isStatusTypeNone: boolean;
  isStatusDisabledHospitalization: boolean;
  isStatusDisabledHospitalizationDays: boolean;
  isStatusReadOnlyHospitalizationDays: boolean;
  isStatusDisabledRegionalMigration: boolean;
  isStatusTypeRegionalMigrationSupport: boolean;
};

type Props = StateProps & WithStyles<typeof styles>;

class UsagePerformanceReportDialogFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStatusTypeNone: true,
      isStatusDisabledHospitalization: false,
      isStatusDisabledHospitalizationDays: false,
      isStatusReadOnlyHospitalizationDays: false,
      isStatusDisabledRegionalMigration: false,
      isStatusTypeRegionalMigrationSupport: false
    };
  }

  public componentDidMount(): void {
    this.setIsCommuteByStatus(
      this.props.formikPropsValues.values.initial.statusType
    );
  }

  // サービス提供実施の値が変更されたら、他項目のdisableの変更を行う
  private handleChangeStatusHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setIsCommuteByStatus(e.target.value);
  };

  // サービス提供の状況の判定
  private setIsCommuteByStatus = (status: string): void => {
    // 【-】時に他項目をdisableにする
    const isStatusTypeNone = status === StatusType.NONE.toString();
    // 特定のサービス提供の状況で「入院・外泊時加算/入院時支援特別加算」項目をdisableにし、値を「-」にする
    const isStatusDisabledHospitalization = STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY.some(
      (item) => item === status
    );
    // 特定のサービス提供の状況で「入院・外泊日数」項目をdisableにし、値を「-」にする
    const isStatusDisabledHospitalizationDays = STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS.some(
      (item) => item === status
    );
    // 特定のサービス提供の状況で「入院・外泊日数」項目を読み取り専用にし、値を「0」にする
    const isStatusReadOnlyHospitalizationDays = STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS.some(
      (item) => item === status
    );
    // サービス提供状況が「入院（初日・中日・最終日）」「外泊（初日・中日・最終日）」かどうかの判定
    const isStatusDisabledRegionalMigration = STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT.some(
      (value) => {
        return status === value.toString();
      }
    );

    // サービス提供の状況が地域移行加算(退所後)かどうかの判定
    const isStatusTypeRegionalMigrationSupport =
      status === StatusType.REGIONAL_MIGRATION_SUPPORT.toString();

    this.setState({
      isStatusTypeNone,
      isStatusDisabledHospitalization,
      isStatusDisabledHospitalizationDays,
      isStatusReadOnlyHospitalizationDays,
      isStatusDisabledRegionalMigration,
      isStatusTypeRegionalMigrationSupport
    });

    // 「入院・外泊日数」の更新
    this.props.setFormikFieldValue(
      "initial.hospitalizationDays",
      getDefaultHospitalizationDaysDialogFields(
        status,
        this.props.formikPropsValues.initialValues.initial
          .hospitalizationDaysPrev
      )
    );

    // サービス提供の状況選択肢制御 地域移行加算（退所後）リセット
    if (isStatusDisabledRegionalMigration) {
      this.props.setFormikFieldValue("initial.regionalTransitionFlg", false);
    }

    // サービス提供の状況によって栄養マネジメント加算と経口移行加算の値をデフォルト値に戻す
    if (STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(status)) {
      this.props.setFormikFieldValue("initial.nutritionManagementFlg", false);
      this.props.setFormikFieldValue("initial.oralTransition", false);
    }

    // 入院（最終日）・入院(最終日)→外泊(初日)・入院(最終日)→共同生活住居に戻る→外泊(初日)選択時は「入院・外泊時加算/入院時支援特別加算」項目の値を変更
    const {
      hospitalizationOvernightStay
    } = this.props.formikPropsValues.values.initial;
    if (
      STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL.includes(
        status
      ) &&
      (hospitalizationOvernightStay === HospitalizationOvernightstay.ONE ||
        hospitalizationOvernightStay === HospitalizationOvernightstay.TWO)
    ) {
      this.props.setFormikFieldValue(
        "initial.hospitalizationOvernightStay",
        HospitalizationOvernightstay.NONE
      );
    }
    // 外泊（中日）選択時は「入院・外泊時加算/入院時支援特別加算」項目の値を変更
    if (
      status === StatusType.OVERNIGHT &&
      hospitalizationOvernightStay === HospitalizationOvernightstay.SPECIAL
    ) {
      this.props.setFormikFieldValue(
        "initial.hospitalizationOvernightStay",
        HospitalizationOvernightstay.NONE
      );
    }
  };

  // リアルタイムにバリデーションを行いたい為、onChangeごとにFormikの更新を行う
  private onChangeText = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    this.props.setFormikFieldValue(event.target.name, event.target.value);
  };

  // 地域移行加算ONのとき他の加算をリセットして選択出来ないようにする
  private onChangeCheck = (): void => {
    this.props.setFormikFieldValue(
      "initial.hospitalizationOvernightStay",
      false
    );
    this.props.setFormikFieldValue("initial.collectionOfUtilityFeeFlg", false);
    this.props.setFormikFieldValue("initial.nutritionManagementFlg", false);
    this.props.setFormikFieldValue("initial.foodBreakfastFlg", false);
    this.props.setFormikFieldValue("initial.foodLunchFlg", false);
    this.props.setFormikFieldValue("initial.foodSupperFlg", false);
    this.props.setFormikFieldValue("initial.oralTransition", false);
    this.props.setFormikFieldValue("initial.oralCareFlg", false);
    this.props.setFormikFieldValue("initial.medicalFoodsFlg", false);
    this.props.setFormikFieldValue("initial.remarks", "");
  };

  // 栄養マネジメント加算OFFのとき経口移行加算をリセットする
  private onChangeCheckNutrition = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.props.setFormikFieldValue(e.target.name, e.target.checked);
    if (!e.target.checked) {
      this.props.setFormikFieldValue("initial.oralTransition", false);
    }
  };

  // 利用者情報の経口維持加算（Ⅰ）の範囲内か
  private isWithinOralMaintenanceDate = (
    startDateParam: string | null | undefined,
    endDateParam: string | null | undefined,
    targetDate: string
  ): boolean => {
    if (!startDateParam || !endDateParam) {
      return false;
    }
    const start = convertYYYYMMToYYYY_MM(startDateParam);
    const end = convertYYYYMMToYYYY_MM(endDateParam);
    if (!start || !end) {
      return false;
    }

    const startDate = new Date(start);
    const endDate = new Date(end);
    endDate.setMonth(endDate.getMonth() + 1);
    const target = new Date(targetDate).getTime();
    if (startDate.getTime() <= target && target < endDate.getTime()) {
      return true;
    }
    return false;
  };

  private handleChangeIntensiveSupportFlgHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (e.target.value === "0") {
      this.props.formikPropsValues.setFieldValue(
        "initial.intensiveSupportStartDate",
        DEFAULT_SELECT_DATE_VALUE
      );
    }
  };

  public render(): JSX.Element {
    const checkBoxStyle = { marginBottom: 22, marginTop: 0, minHeight: 24 };
    const selectBoxStyle = { marginBottom: 30, minHeight: 52, minWidth: 330 };
    const foodCheckBoxStyle = { marginBottom: 0, marginTop: 0 };
    const disabledWithRegionalTransitionFlg = this.props.formikPropsValues
      .values.initial.regionalTransitionFlg;
    const { values } = this.props.formikPropsValues;
    const { userInFacilityState } = this.props;
    // 経口移行加算表示判定
    const oralTransitionDisplayFlg = !(
      userInFacilityState &&
      userInFacilityState.oral_maintenance_i_flg &&
      this.isWithinOralMaintenanceDate(
        userInFacilityState.oral_maintenance_i_start_date,
        userInFacilityState.oral_maintenance_i_end_date,
        values.initial.targetDate
      )
    );
    return (
      <Paper component="section" elevation={0}>
        <FormikSelect
          name="initial.statusType"
          label="サービス提供の状況"
          options={
            this.props.serviceStatus === 1 || this.props.serviceStatus === 3
              ? STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT
              : STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT
          }
          onChangeHook={this.handleChangeStatusHook}
          style={{ ...selectBoxStyle, marginBottom: 22, width: "536px" }}
          required
        />
        <FormikSelect
          name="initial.hospitalizationOvernightStay"
          label="入院・外泊時加算/入院時支援特別加算"
          size="smallMedium"
          disabled={
            this.state.isStatusDisabledHospitalization ||
            disabledWithRegionalTransitionFlg
          }
          options={createOptionsHospitalizationOvernightStayDialog(
            `${this.props.formikPropsValues.values.initial.statusType}`
          )}
          style={selectBoxStyle}
          FormLabelClasses={
            this.state.isStatusTypeNone
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        {this.state.isStatusReadOnlyHospitalizationDays ? (
          <div className={this.props.classes.hospitalizationDaysReadOnly}>
            <ReadonlyTextField
              label="入院・外泊日数"
              value={HospitalizationDays.ZERO}
              defaultValue={HospitalizationDays.ZERO}
            />
          </div>
        ) : (
          <FormikTextField
            name="initial.hospitalizationDays"
            label="入院・外泊日数"
            size="quarterSuperLong"
            style={{ marginBottom: 48, width: 128, minHeight: 52 }}
            error={
              this.props.formikPropsValues.errors &&
              this.props.formikPropsValues.errors.initial
                ? this.props.formikPropsValues.errors.initial
                    .hospitalizationDays !== undefined
                : false
            }
            placeholder="-"
            onChangeHook={this.onChangeText}
            disabled={this.state.isStatusDisabledHospitalizationDays}
            maxLength={3}
          />
        )}
        <FormikCheckbox
          name="initial.regionalTransitionFlg"
          label="地域移行加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            this.state.isStatusDisabledRegionalMigration ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
          onChangeHook={this.onChangeCheck}
        />
        <FormikCheckbox
          name="initial.collectionOfUtilityFeeFlg"
          label="光熱水費提供"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
        />
        <FormikCheckbox
          name="initial.nutritionManagementFlg"
          label="栄養マネジメント加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(
              values.initial.statusType
            )
          }
          style={checkBoxStyle}
          onChange={this.onChangeCheckNutrition}
        />
        {this.props.facilityState.availableFood && (
          <>
            <Typography
              classes={{
                root:
                  !this.state.isStatusTypeNone &&
                  !disabledWithRegionalTransitionFlg &&
                  !this.state.isStatusTypeRegionalMigrationSupport
                    ? this.props.classes.typographyMargin
                    : `${this.props.classes.typographyMargin} ${this.props.classes.disabled}`
              }}
            >
              食事提供
            </Typography>
            <FormGroup row className={this.props.classes.foodGroup}>
              <FormikCheckbox
                name="initial.foodBreakfastFlg"
                label="朝"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
              <FormikCheckbox
                name="initial.foodLunchFlg"
                label="昼"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
              <FormikCheckbox
                name="initial.foodSupperFlg"
                label="夜"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
            </FormGroup>
          </>
        )}
        {oralTransitionDisplayFlg && (
          <FormikCheckbox
            name="initial.oralTransition"
            label="経口移行加算"
            classes={{ root: this.props.classes.checkbox }}
            disabled={
              this.state.isStatusTypeNone ||
              disabledWithRegionalTransitionFlg ||
              STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(
                values.initial.statusType
              ) ||
              !values.initial.nutritionManagementFlg
            }
            style={checkBoxStyle}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="shisetsunyushoOralTransfer" />}
              />
            }
          />
        )}

        <FormikCheckbox
          name="initial.oralCareFlg"
          label="口腔ケア"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            this.props.facilityState.oralHygieneManagementSystemFlg ===
              INT_FALSE_FROM_API ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
          tooltip={
            <HelpToolTip title={<HelpTipMessages name="oralCareFlag" />} />
          }
        />
        <FormikCheckbox
          name="initial.medicalFoodsFlg"
          label="療養食"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
        />
        <FormikCheckbox
          name="initial.regionalTransitionPromotion2Flg"
          label="︎地域移行促進加算（Ⅱ）"
          classes={{ root: this.props.classes.checkbox }}
          disabled={this.state.isStatusTypeNone}
          style={checkBoxStyle}
        />
        <FormikCheckbox
          name="initial.hospitalVisitSupportFlg"
          label="︎通院支援加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={this.state.isStatusTypeNone}
          style={checkBoxStyle}
        />
        <FormikSelect
          name="initial.intensiveSupportFlg"
          label="集中的支援加算"
          size="smallMedium"
          options={INTENSIVE_SUPPORT_FLG}
          disabled={this.state.isStatusTypeNone}
          style={{
            marginBottom:
              this.props.formikPropsValues.values.initial
                .intensiveSupportFlg !== "0"
                ? 16
                : 24,
            width: 330,
            minHeight: 52
          }}
          onChangeHook={this.handleChangeIntensiveSupportFlgHook}
          FormLabelClasses={
            this.state.isStatusTypeNone
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        {this.props.formikPropsValues.values.initial.intensiveSupportFlg !==
          INTENSIVE_SUPPORT_FLG[0].value &&
          !this.state.isStatusTypeNone && (
            <FormikSelectDateNotSelectedDefault
              name="initial.intensiveSupportStartDate"
              label="算定開始日"
              required
              style={{ marginBottom: 24, marginLeft: 36 }}
              addYearTo={1}
              setFormikFieldValue={this.props.setFormikFieldValue}
            />
          )}
        <FormikCheckbox
          name="initial.infectiousDiseasesFacilityFlg"
          label="新興感染症等施設療養加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={this.state.isStatusTypeNone}
          style={checkBoxStyle}
        />
        <FormikSelect
          name="initial.severeDisabilitySupportType"
          label="重度障害者支援加算"
          disabled={
            this.state.isStatusTypeNone ||
            (this.props.userInFacilityState &&
              this.props.userInFacilityState.severe_disability_support ===
                INT_FALSE_FROM_API)
          }
          options={SEVERE_DISABILITY_SUPPORT_TYPE}
          style={{ ...selectBoxStyle, marginBottom: 22, width: "536px" }}
        />
        <FormikTextField
          name="initial.remarks"
          label="備考"
          size="quarterSuperLong"
          style={{ marginBottom: 48, width: 536, minHeight: 52 }}
          error={
            this.props.formikPropsValues.errors &&
            this.props.formikPropsValues.errors.initial
              ? this.props.formikPropsValues.errors.initial.remarks !==
                undefined
              : false
          }
          onChangeHook={this.onChangeText}
          disabled={
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
        />
      </Paper>
    );
  }
}
export default withStyles(styles)(UsagePerformanceReportDialogFields);
