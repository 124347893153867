/**
 * ActionNames
 */
export const FETCH_STARTED = "KYOTAKUKAIGO/FACILITY_202104/FETCH_STARTED";
export const FETCH_SUCCESS = "KYOTAKUKAIGO/FACILITY_202104/FETCH_SUCCESS";
export const FETCH_FAILED = "KYOTAKUKAIGO/FACILITY_202104/FETCH_FAILED";
export const POST_STARTED = "KYOTAKUKAIGO/FACILITY_202104/POST_STARTED";
export const POST_SUCCESS = "KYOTAKUKAIGO/FACILITY_202104/POST_SUCCESS";
export const POST_FAILED = "KYOTAKUKAIGO/FACILITY_202104/POST_FAILED";

/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  capacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
  multiFunctionOfficeFlag: boolean;
  masterSubordinateFlg: boolean;
  masterFlg: string;
  allCapacity: string;
  mealSaservedServiceFlag: boolean;
  transferServiceFlag: boolean;
  transferServiceType: string;
  seriousSupporterFlg: boolean;

  // FacilityAdditionItemSection
  staffTreatmentSystemType: string;
  staffTreatmentSpecificSystemType: string;
  commuterLifeSupportFlag: boolean;
  specificFacilitiesAddition: string;
  betterSupporterConditionBaseUpFlg: boolean;
  lifeSupportHubInDistrictFlg: boolean;
  buildingManagementFlg: boolean;

  // units
  units: {
    id: number | null;
    unit_name: string;
    same_building_subtraction_flg: boolean;
    is_deleted: boolean;
    results_use_flg: boolean;
  }[];
};
