import { UsageResult, UsageResultsState } from "./types";
import { GetUsageResultsMonthlyResponce } from "@api/requests/v201910/usageResults/getUsageResultsMonthly";
import { InitialDataValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/report/initialData";
import { PostUsageResultsParam } from "@api/requests/v201910/usageResults/postUsageResultsMonthly";
import deepEqual from "fast-deep-equal";
import { cloneDeep, isEmpty } from "lodash-es";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import castNumber from "@utils/dataNormalizer/castNumber";
import castString from "@utils/dataNormalizer/castString";
import castStringOrNull from "@utils/dataNormalizer/castStringOrNull";

const normalizeUsageResultList = (
  usageResults: GetUsageResultsMonthlyResponce["data"]["usage_results"]
): UsageResult[] => {
  const normalizedUsageResults: UsageResult[] = usageResults.map(
    (usageResult) => {
      return {
        uifId: castNumber(usageResult.users_in_facility_id),
        nameSei: castString(usageResult.name_sei),
        // 名
        nameMei: castString(usageResult.name_mei),
        // 対象年月日
        targetDate: castString(usageResult.target_date),
        // サービス提供の状況
        statusType: castNumber(usageResult.status_type),
        // 特別地域加算
        specialAreaFlg: castNumber(usageResult.special_area_flg),
        // 備考
        remarks: castStringOrNull(usageResult.remarks),
        // 休日判定
        isHoliday: usageResult.isHoliday
      };
    }
  );
  return normalizedUsageResults;
};

/**
 * APIのデータをstoreに変更
 * @param result APIのresponce
 */
export const normalizeUsageResultFromAPI = (
  result: GetUsageResultsMonthlyResponce["data"]
): UsageResult[] => {
  const normalizeReportList = Array.isArray(result.usage_results)
    ? normalizeUsageResultList(result.usage_results)
    : [];
  return normalizeReportList;
};

// storeのkeyからpiaRequestのkeyに変換するmap表 Request必須値は除く
const StoreToRequestParam = {
  status_type: {
    storeKey: "statusType",
    requestKey: "status_type"
  },
  special_area_flg: {
    storeKey: "specialAreaFlg",
    requestKey: "special_area_flg"
  },
  remarks: {
    storeKey: "remarks",
    requestKey: "remarks"
  }
};

/**
 * 差分チェック uif_id・targetDate・facirityType・travelTime・pickupPremisesは除く
 */
const isDiff = (before: string | number, after: string | number): boolean => {
  return before !== after;
};

/**
 * 差分抽出 差分以外はundefindで対応
 * @param formValue formのデータ
 * @param storeValues storeのデータ
 */
const getDiffData = (
  formValues: UsageResultsState["usageResults"],
  stateValues: UsageResultsState["usageResults"]
): PostUsageResultsParam["usage_results"] => {
  const diffPostParamList: PostUsageResultsParam["usage_results"] = [];
  stateValues.forEach((stateValue, index) => {
    if (
      index < formValues.length &&
      !deepEqual(stateValue, formValues[index])
    ) {
      // 差分一時保管用のデータの初期化
      const diffValue: PostUsageResultsParam["usage_results"][0] = {
        users_in_facility_id: stateValue.uifId ? `${stateValue.uifId}` : "",
        target_date: stateValue.targetDate ? stateValue.targetDate : "",
        status_type: undefined,
        special_area_flg: undefined,
        remarks: undefined
      };

      // 差分の項目を抽出
      Object.keys(StoreToRequestParam).forEach((paramKey) => {
        const { requestKey } = StoreToRequestParam[paramKey];
        const { storeKey } = StoreToRequestParam[paramKey];
        if (isDiff(stateValue[storeKey], formValues[index][storeKey])) {
          diffValue[requestKey] = formValues[index][storeKey];
        } else {
          delete diffValue[requestKey];
        }
      });
      diffPostParamList.push(diffValue);
    }
  });
  return diffPostParamList;
};

/**
 * formデータをAPIのrequestに変換
 * @param formValue formのデータ
 * @param storeValues storeのデータ
 */
export const normalizeFormValueToAPI = (
  formValue: UsageResultsState["usageResults"],
  storeValues: UsageResultsState["usageResults"]
): PostUsageResultsParam => {
  const diffData: PostUsageResultsParam = {
    usage_results: getDiffData(formValue, storeValues)
  };
  return diffData;
};

/**
 * formデータをstoreデータに変換
 * @param formValues formのデータ
 * @param storeValues
 */
export const normalizeUsageResultFromForm = (
  formValues: InitialDataValues,
  storeValues: UsageResultsState["usageResults"]
): UsageResultsState["usageResults"] => {
  const usageResultList: UsageResultsState["usageResults"] = storeValues.map(
    (storeValue, index) => {
      const convertReportValue: UsageResult = cloneDeep(storeValue);
      if (
        index < formValues.ReportData.length &&
        storeValue.targetDate === formValues.ReportData[index].targetDate
      ) {
        convertReportValue.statusType =
          booleanToNumber0or1(formValues.ReportData[index].statusType) || 0;
        convertReportValue.specialAreaFlg =
          booleanToNumber0or1(formValues.ReportData[index].specialAreaFlg) || 0;
        convertReportValue.remarks = isEmpty(
          formValues.ReportData[index].remarks
        )
          ? null
          : formValues.ReportData[index].remarks;
        // 祝日情報がundefの場合deepCopyされずに後続の処理で不適切な動きをするため
        convertReportValue.isHoliday = storeValue.isHoliday;
      }
      return convertReportValue;
    }
  );
  return usageResultList;
};
