import React from "react";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import {
  SERVICE_STATUS_202104,
  SUPPLY_FOOD_SERVICE,
  SUPPLY_PICKUP_SERVICE,
  PICKUP_PREMISES_CASE_1,
  PICKUP_PREMISES_CASE_2,
  PICKUP_PREMISES_CASE_3
} from "@constants/variables";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTextField from "@components/molecules/FormikTextField";
import Typography from "@material-ui/core/Typography";
import { FacilityState } from "@stores/domain/mgr/IAB/facility/types";

// formik
import { FormikProps } from "formik";
import { UsersValues } from "@initialize/mgr/IAB/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: "auto",
      tableLayout: "fixed",
      marginTop: 24
    },
    cell: {
      width: 120,
      "&:first-child": {
        width: 232,
        paddingLeft: 16,
        paddingRight: 16
      },
      "&:last-child": {
        width: 118,
        paddingLeft: 14,
        marginRight: 16
      }
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
};

type StateProps = {
  facility: FacilityState;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

type ChangeEventAlias = React.ChangeEvent<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
>;

const ServiceProvideStatusFieldsCore = ({
  classes,
  formikProps,
  facility
}: Props): JSX.Element => {
  const { serviceProvideStatusItems } = formikProps.values.serviceProvideStatus;

  /**
   * 開始時間/終了時間でコロンなしで4桁の数字を入力時、コロンを自動で付ける
   */
  const handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    const currentValue = event.currentTarget.value;
    return /^\d{4}$/.test(currentValue)
      ? `${currentValue.slice(0, 2)}:${currentValue.slice(2)}`
      : undefined;
  };
  /**
   * 送迎サービスデフォルトの値によって、同一敷地内のセレクトリストを変更
   */
  const onChangePickupPremisesHook = React.useCallback(
    (key: number) => {
      return (e: ChangeEventAlias): void => {
        const { name, value } = e.target;
        if (
          name !==
          `serviceProvideStatus.serviceProvideStatusItems[${key}].pickup`
        ) {
          return;
        }
        serviceProvideStatusItems[key].pickupPremise = "0";
        switch (value) {
          case "1":
            serviceProvideStatusItems[
              key
            ].pickupPremiseList = PICKUP_PREMISES_CASE_1;
            break;
          case "2":
            serviceProvideStatusItems[
              key
            ].pickupPremiseList = PICKUP_PREMISES_CASE_2;
            break;
          case "3":
            serviceProvideStatusItems[
              key
            ].pickupPremiseList = PICKUP_PREMISES_CASE_3;
            break;
          default:
        }
      };
    },
    [formikProps.values.serviceProvideStatus.serviceProvideStatusItems]
  );

  const headData = [
    {
      label: "サービス提供の状況",
      className: classes.cell
    },
    {
      label: "開始時間",
      className: classes.cell
    },
    {
      label: "終了時間",
      className: classes.cell
    }
  ];

  if (facility.mealSaservedServiceFlag) {
    headData.push({
      label: "食事提供",
      className: classes.cell
    });
  }

  if (facility.transferServiceFlag) {
    headData.push(
      {
        label: "送迎",
        className: classes.cell
      },
      {
        label: "同一敷地内送迎",
        className: classes.cell
      }
    );
  }

  headData.push({
    label: "タイムカード表示",
    className: classes.cell
  });

  const bodyData = serviceProvideStatusItems.map((item, idx) => {
    const items = [
      {
        label: (
          <Typography style={{ transform: "translateY(17px)", fontSize: 16 }}>
            {
              SERVICE_STATUS_202104.filter(
                (serviceStatus) => serviceStatus.value === item.status
              )[0].label
            }
          </Typography>
        ),
        className: classes.cell
      },
      {
        label: (
          <FormikTextField
            name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].startTime`}
            label=""
            style={{ width: 120, transform: "translateY(15px)" }}
            placeholder="00:00"
            maxLength={5}
            onChangeHook={handleChangeTime}
          />
        ),
        className: classes.cell
      },
      {
        label: (
          <FormikTextField
            name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].endTime`}
            label=""
            style={{ width: 120, transform: "translateY(15px)" }}
            placeholder="00:00"
            maxLength={5}
            onChangeHook={handleChangeTime}
          />
        ),
        className: classes.cell
      }
    ];
    if (facility.mealSaservedServiceFlag) {
      items.push({
        label: (
          <FormikSelect
            name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].supplyFood`}
            label=""
            size="auto"
            style={{ width: 120, marginBottom: 15 }}
            options={SUPPLY_FOOD_SERVICE}
          />
        ),
        className: classes.cell
      });
    }
    if (facility.transferServiceFlag) {
      items.push(
        {
          label: (
            <FormikSelect
              name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].pickup`}
              label=""
              size="auto"
              style={{ width: 120, marginBottom: 15 }}
              options={SUPPLY_PICKUP_SERVICE}
              onChangeHook={onChangePickupPremisesHook(idx)}
            />
          ),
          className: classes.cell
        },
        {
          label: (
            <FormikSelect
              name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].pickupPremise`}
              label=""
              size="auto"
              style={{ width: 120, marginBottom: 15 }}
              options={item.pickupPremiseList}
              disabled={item.pickup === "0"}
            />
          ),
          className: classes.cell
        }
      );
    }
    items.push({
      label: (
        <FormikCheckbox
          label=""
          name={`serviceProvideStatus.serviceProvideStatusItems[${idx}].timeCardShow`}
          style={{ transform: "translate(15px, 17px)" }}
        />
      ),
      className: classes.cell
    });

    return items;
  });
  return (
    <KnowbeTable className={classes.table}>
      <KnowbeTableHead
        uniqueKey="サービス提供状況の自動入力"
        height={48}
        items={headData}
      />
      <KnowbeTableBody
        uniqueKey="サービス提供状況の自動入力"
        itemsContainer={bodyData}
      />
    </KnowbeTable>
  );
};

export const ServiceProvideStatusFields = withStyles(styles)(
  ServiceProvideStatusFieldsCore
);
