import { Dispatch } from "redux";
import * as actions from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/actions";
import dispatches from "@stores/dispatches";
import { inOutResults } from "@api/requests/v202104/inOutResults";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeKYOTAKUKAIGODailyReportDataFromAPI,
  normalizeKYOTAKUKAIGOUserReportDataFromAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToDeleteAPI,
  normalizeRequestParamToPostCopyAPI,
  normalizeRequestParamToPostCopyCarePlanAPI
} from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/report/initial";
import {
  KYOTAKUKAIGOReportTypeInterface,
  REPEAT_DAILY,
  DeleteReportState,
  CopyReportState
} from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/types";
import {
  INOUT_RESULTS_COPY_ERROR_STATUS,
  INOUT_RESULTS_ERROR_DUPLICATED
} from "@constants/mgr/KYOTAKUKAIGO/variables";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 * @param dispatch
 */
const fetchKYOTAKUKAIGODaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKYOTAKUKAIGODailyStarted());
  await inOutResults
    .getInOutResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKYOTAKUKAIGODaily(
          normalizeKYOTAKUKAIGODailyReportDataFromAPI(
            response.data,
            dateInYYYYMMDDFormat(date)
          )
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKYOTAKUKAIGODailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(ユーザごと)のデータ取得及びstore格納
 * @param dispatch
 */
const fetchKYOTAKUKAIGOUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKYOTAKUKAIGOUserStarted());
  await inOutResults
    .getInOutResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKYOTAKUKAIGOUser(
          normalizeKYOTAKUKAIGOUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKYOTAKUKAIGOUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 * @param dispatch
 */
const postKYOTAKUKAIGOReport = (dispatch: Dispatch) => async (
  facility: FacilityState,
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: KYOTAKUKAIGOReportTypeInterface["type"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKYOTAKUKAIGOReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(before, after, facility);
  await inOutResults
    .postInOutResults(requestParam)
    .then(() => {
      const actionType =
        type === REPEAT_DAILY
          ? actions.postKYOTAKUKAIGOReportDaily(requestParam)
          : actions.postKYOTAKUKAIGOReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postKYOTAKUKAIGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // 3人同時エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.error !== INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用計画→実績へのコピー
 * @param dispatch
 */
const postKYOTAKUKAIGOReportCopy = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKYOTAKUKAIGOReportCopyStarted());

  const requestParam = normalizeRequestParamToPostCopyAPI(processType, target);

  await inOutResults
    .postInOutResultsCopy(requestParam)
    .then(() => {
      dispatch(actions.postKYOTAKUKAIGOReportCopy(requestParam));
    })
    .catch((e) => {
      dispatch(actions.postKYOTAKUKAIGOReportCopyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で実績登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 介護計画→利用計画へのコピー
 * @param dispatch
 */
const postKYOTAKUKAIGOReportCopyCarePlan = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKYOTAKUKAIGOReportCopyCarePlanStarted());

  const requestParam = normalizeRequestParamToPostCopyCarePlanAPI(
    processType,
    target
  );

  await inOutResults
    .postInOutResultsCopyCarePlan(requestParam)
    .then(() => {
      dispatch(actions.postKYOTAKUKAIGOReportCopyCarePlan(requestParam));
    })
    .catch((e) => {
      dispatch(
        actions.postKYOTAKUKAIGOReportCopyCarePlanFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で計画登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績削除
 * @param dispatch
 */
const deleteKYOTAKUKAIGOReport = (dispatch: Dispatch) => async (
  target: DeleteReportState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteKYOTAKUKAIGOReportStarted());

  const requestParam = normalizeRequestParamToDeleteAPI(target);

  await inOutResults
    .deleteInOutResults(requestParam)
    .then(() => {
      dispatch(actions.deleteKYOTAKUKAIGOReport(requestParam));
    })
    .catch((e) => {
      dispatch(actions.deleteKYOTAKUKAIGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchKYOTAKUKAIGODaily: (date: Date) => Promise<void>;
  fetchKYOTAKUKAIGOUsers: (uifId: number, date: Date) => Promise<void>;
  postKYOTAKUKAIGOReport: (
    facility: FacilityState,
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KYOTAKUKAIGOReportTypeInterface["type"]
  ) => Promise<void>;
  postKYOTAKUKAIGOReportCopy: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  postKYOTAKUKAIGOReportCopyCarePlan: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  deleteKYOTAKUKAIGOReport: (target: DeleteReportState) => Promise<void>;
};

export const KYOTAKUKAIGOReportDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchKYOTAKUKAIGODaily: fetchKYOTAKUKAIGODaily(dispatch),
  fetchKYOTAKUKAIGOUsers: fetchKYOTAKUKAIGOUsers(dispatch),
  postKYOTAKUKAIGOReport: postKYOTAKUKAIGOReport(dispatch),
  postKYOTAKUKAIGOReportCopy: postKYOTAKUKAIGOReportCopy(dispatch),
  postKYOTAKUKAIGOReportCopyCarePlan: postKYOTAKUKAIGOReportCopyCarePlan(
    dispatch
  ),
  deleteKYOTAKUKAIGOReport: deleteKYOTAKUKAIGOReport(dispatch)
});
