import {
  InoutConsultationResultsState,
  ReportState
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { InitialValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/report/initial";
import {
  STATUS_LIST,
  FIRST_ADDITION_SPAN_LIST,
  HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST,
  FACILITY_TYPE_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

export type InitialDataValues = InitialValues;

// デフォルト値設定
export const initialValues = (
  state: InoutConsultationResultsState | null = null,
  report: ReportState | null = null,
  date = ""
): InitialDataValues => {
  const targetDateState = state && state.targetDate ? state.targetDate : date;
  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      // inout_result_daily:
      targetDate: targetDateState,
      numberOfEmployees:
        report && report.reportDaily.inoutResultDaily.numberOfEmployees !== null
          ? report.reportDaily.inoutResultDaily.numberOfEmployees.toString()
          : "",
      numberOfHandicappedChild:
        report &&
        report.reportDaily.inoutResultDaily.numberOfHandicappedChild !== null
          ? report.reportDaily.inoutResultDaily.numberOfHandicappedChild.toString()
          : "",
      trainingByChiefFlg:
        report && report.reportDaily.inoutResultDaily.trainingByChiefFlg
          ? report.reportDaily.inoutResultDaily.trainingByChiefFlg
          : false,
      trainingByChiefShogaijiFlg:
        report && report.reportDaily.inoutResultDaily.trainingByChiefShogaijiFlg
          ? report.reportDaily.inoutResultDaily.trainingByChiefShogaijiFlg
          : false,
      numberOfKeikakusodan:
        report &&
        report.reportDaily.inoutResultDaily.numberOfKeikakusodan !== null
          ? report.reportDaily.inoutResultDaily.numberOfKeikakusodan.toString()
          : "",

      // inout_consultation_results:
      id: state && state.id ? state.id : null,
      inoutResultDailyId: state ? state.inoutResultDailyId : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      typeConsultation:
        state && state.typeConsultation
          ? state.typeConsultation
          : FACILITY_TYPE_KEIKAKUSODAN,
      status:
        state && state.status ? state.status.toString() : STATUS_LIST[0].value,
      firstAdditionFlg:
        state && state.firstAdditionFlg ? state.firstAdditionFlg : false,
      firstAdditionSpan:
        state && state.firstAdditionSpan
          ? state.firstAdditionSpan.toString()
          : FIRST_ADDITION_SPAN_LIST[0].value,
      intensiveSupport1AdditionFlg:
        state && state.intensiveSupport1AdditionFlg
          ? state.intensiveSupport1AdditionFlg
          : false,
      intensiveSupport2AdditionFlg:
        state && state.intensiveSupport2AdditionFlg
          ? state.intensiveSupport2AdditionFlg
          : false,
      intensiveSupport3AdditionFlg:
        state && state.intensiveSupport3AdditionFlg
          ? state.intensiveSupport3AdditionFlg
          : false,
      inhomeNursingCareSupportOfficeRelation1Flg:
        state && state.inhomeNursingCareSupportOfficeRelation1Flg
          ? state.inhomeNursingCareSupportOfficeRelation1Flg
          : false,
      inhomeNursingCareSupportOfficeRelation2Flg:
        state && state.inhomeNursingCareSupportOfficeRelation2Flg
          ? state.inhomeNursingCareSupportOfficeRelation2Flg
          : false,
      inhomeNursingCareSupportOfficeRelation3Flg:
        state && state.inhomeNursingCareSupportOfficeRelation3Flg
          ? state.inhomeNursingCareSupportOfficeRelation3Flg
          : false,
      inhomeNursingCareSupportOfficeRelation4Flg:
        state && state.inhomeNursingCareSupportOfficeRelation4Flg
          ? state.inhomeNursingCareSupportOfficeRelation4Flg
          : false,
      inhomeNursingCareSupportOfficeRelation5Flg:
        state && state.inhomeNursingCareSupportOfficeRelation5Flg
          ? state.inhomeNursingCareSupportOfficeRelation5Flg
          : false,
      inhomeNursingCareSupportOfficeRelation6Flg:
        state && state.inhomeNursingCareSupportOfficeRelation6Flg
          ? state.inhomeNursingCareSupportOfficeRelation6Flg
          : false,
      dischargeAdditionFlg:
        state && state.dischargeAdditionFlg
          ? state.dischargeAdditionFlg
          : false,
      sameInhomeNursingCareSupportFlg:
        state && state.sameInhomeNursingCareSupportFlg
          ? state.sameInhomeNursingCareSupportFlg
          : false,
      samePreventiveCareSupportFlg:
        state && state.samePreventiveCareSupportFlg
          ? state.samePreventiveCareSupportFlg
          : false,
      hospitalizationRelationAddition:
        state && state.hospitalizationRelationAddition
          ? state.hospitalizationRelationAddition.toString()
          : HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[0].value,
      medicalDaycareEducationRelationFlg:
        state && state.medicalDaycareEducationRelationFlg
          ? state.medicalDaycareEducationRelationFlg
          : false,
      servicePersonnelMeetingHeldFlg:
        state && state.servicePersonnelMeetingHeldFlg
          ? state.servicePersonnelMeetingHeldFlg
          : false,
      serviceProvisionMonitoringFlg:
        state && state.serviceProvisionMonitoringFlg
          ? state.serviceProvisionMonitoringFlg
          : false,
      lifeSupportPromotionFlg:
        state && state.lifeSupportPromotionFlg
          ? state.lifeSupportPromotionFlg
          : false,
      systemPromotionFlg:
        state && state.systemPromotionFlg ? state.systemPromotionFlg : false,
      childcareTransitionSupport1Flg:
        state && state.childcareTransitionSupport1Flg
          ? state.childcareTransitionSupport1Flg
          : false,
      childcareTransitionSupport2Flg:
        state && state.childcareTransitionSupport2Flg
          ? state.childcareTransitionSupport2Flg
          : false,
      childcareTransitionSupport3Flg:
        state && state.childcareTransitionSupport3Flg
          ? state.childcareTransitionSupport3Flg
          : false,
      memo: state && state.memo ? state.memo : ""
    }
  };
};
