import { GetSupportReportUserResponse } from "@api/requests/supportReport/getSupportReportUser";

/**
 * ActionNames
 */
export const FETCH_SUPPORT_REPORT_STARTED =
  "SUPPORTS/FETCH_SUPPORT_REPORT_STARTED";
export const FETCH_SUPPORT_REPORT_SUCCESS =
  "SUPPORTS/FETCH_SUPPORT_REPORT_SUCCESS";
export const FETCH_SUPPORT_REPORT_FAILED =
  "SUPPORTS/FETCH_SUPPORT_REPORT_FAILED";
export const POST_SUPPORT_REPORT_STARTED =
  "SUPPORTS/POST_SUPPORT_REPORT_STARTED";
export const POST_SUPPORT_REPORT_SUCCESS =
  "SUPPORTS/POST_SUPPORT_REPORT_SUCCESS";
export const POST_SUPPORT_REPORT_FAILED = "SUPPORTS/POST_SUPPORT_REPORT_FAILED";

/**
 * State
 */
export type SupportReportState = GetSupportReportUserResponse["data"];
