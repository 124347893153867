import { getCustomRecordsResponse } from "@api/requests/customRecords/getCustomRecords";
import { RequestParam as CustomRecordsRequestParam } from "@api/requests/customRecords/postHiddenChoice";
import { RequestParam as OrderRequestParam } from "@api/requests/customRecords/postCustomRecordsOrder";

/**
 * ActionNames
 */
export const FETCH_CUSTOM_RECORDS_STARTED = "CUSTOM_RECORDS/FETCH_STARTED";
export const FETCH_CUSTOM_RECORDS_SUCCESS = "CUSTOM_RECORDS/FETCH_SUCCESS";
export const FETCH_CUSTOM_RECORDS_FAILED = "CUSTOM_RECORDS/FETCH_FAILED";

export const FETCH_SUPPORT_CUSTOM_RECORDS_STARTED =
  "SUPPORTS/FETCH_CUSTOM_RECORDS_STARTED";
export const FETCH_SUPPORT_CUSTOM_RECORDS_SUCCESS =
  "SUPPORTS/FETCH_CUSTOM_RECORDS_SUCCESS";
export const FETCH_SUPPORT_CUSTOM_RECORDS_FAILED =
  "SUPPORTS/FETCH_CUSTOM_RECORDS_FAILED";

export const POST_CUSTOM_RECORDS_STARTED = "CUSTOM_RECORDS/POST_STARTED";
export const POST_CUSTOM_RECORDS_SUCCESS = "CUSTOM_RECORDS/POST_SUCCESS";
export const POST_CUSTOM_RECORDS_FAILED = "CUSTOM_RECORDS/POST_FAILED";
export const POST_CUSTOM_RECORDS_ORDER_STARTED =
  "CUSTOM_RECORDS/POST_ORDER_STARTED";
export const POST_CUSTOM_RECORDS_ORDER_SUCCESS =
  "CUSTOM_RECORDS/POST_ORDER_SUCCESS";
export const POST_CUSTOM_RECORDS_ORDER_FAILED =
  "CUSTOM_RECORDS/POST_ORDER_FAILED";
export const POST_HIDDEN_CHOICE_STARTED =
  "CUSTOM_RECORDS/POST_HIDDEN_CHOICE_STARTED";
export const POST_HIDDEN_CHOICE_SUCCESS =
  "CUSTOM_RECORDS/POST_HIDDEN_CHOICE_SUCCESS";
export const POST_HIDDEN_CHOICE_FAILED =
  "CUSTOM_RECORDS/POST_HIDDEN_CHOICE_FAILED";

export const HIDE_CUSTOM_RECORD_STARTED = "CUSTOM_RECORD/HIDE_STARTED";
export const HIDE_CUSTOM_RECORD_SUCCESS = "CUSTOM_RECORD/HIDE_SUCCESS";
export const HIDE_CUSTOM_RECORD_FAILED = "CUSTOM_RECORD/HIDE_FAILED";

export const SHOW_CUSTOM_RECORD_STARTED = "CUSTOM_RECORD/SHOW_STARTED";
export const SHOW_CUSTOM_RECORD_SUCCESS = "CUSTOM_RECORD/SHOW_SUCCESS";
export const SHOW_CUSTOM_RECORD_FAILED = "CUSTOM_RECORD/SHOW_FAILED";
/**
 * State
 */
export type CustomRecordsState = getCustomRecordsResponse["data"];
export type HiddenChoiceState = CustomRecordsRequestParam;
export type CustomRecordsOrderState = OrderRequestParam;
