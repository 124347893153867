import { ShotMultipleSelectValue } from "@interfaces/ui/form";
import { OperationsState } from "@stores/domain/mgr/Cseg/operations/types";

export type OperationValue =
  | {
      operation_records_id: number | null;
      target_date: string; // yyyy-mm-dd
      operation_in_the_morning: string;
      operation_in_the_afternoon: string;
      other_comment: string;
      staffs: {
        id: number;
        name: string;
        facility_id: number;
        snapshot_name: string;
      }[];
    }
  | undefined;

export type OperationsValues = {
  operations: {
    operation_records_id: number | null;
    target_date: string; // yyyy-mm-dd
    operation_in_the_morning: string;
    operation_in_the_afternoon: string;
    other_comment: string;
    staffs: {
      readonly beforeValues: {
        id: number;
        name: string;
        facility_id: number;
        snapshot_name: string;
      }[];
      itemIdList: ShotMultipleSelectValue;
    };
  }[];
};

export const initialValues = (
  values: OperationsState,
  dateList: string[]
): OperationsValues => {
  return {
    operations: dateList.map((date) => {
      const operation = values.operations.find(
        (value) => value.target_date === date
      );
      return {
        operation_records_id: operation ? operation.operation_records_id : null,
        target_date: date,
        operation_in_the_morning: operation
          ? operation.operation_in_the_morning
          : "",
        operation_in_the_afternoon: operation
          ? operation.operation_in_the_afternoon
          : "",
        other_comment: operation ? operation.other_comment : "",
        staffs: {
          beforeValues: operation ? operation.staffs : [],
          itemIdList: operation
            ? operation.staffs.map((staff) => {
                return {
                  category_id: 0,
                  id: staff.id,
                  label: staff.snapshot_name,
                  is_delete: null
                };
              })
            : []
        }
      };
    })
  };
};
