import accountDispatch from "@stores/domain/account/dispatch";
import authDispatch from "@stores/auth/dispatch";
import userDispatch from "@stores/domain/user/dispatch";
import invoiceDispatch from "@stores/domain/invoice/dispatch";
import offsiteWorkDispatch from "@stores/domain/offsiteWork/dispatcher";
import errorsDispatcher from "@stores/domain/errors/dispatcher";
import operationsDispatcher from "@stores/domain/operations/dispatcher";

// state
import downloadDispatch from "@stores/ui/download/dispatch";
import cityDispatch from "@stores/domain/city/dispatch";
import uiDispatch from "@stores/ui/dispatch";
import reportDispatch from "@stores/domain/report/dispatch";
import contactDispatch from "@stores/domain/contact/dispatch";
import attendanceDispatch from "@stores/domain/attendance/dispatch";
import workDispatcher from "@stores/domain/work/dispatcher";
import staffDispatcher from "@stores/domain/staff/dispatcher";
import supportPlanDispatcher from "@stores/domain/supportPlan/dispatcher";
import supportsDispatcher from "@stores/domain/supports/dispatcher";
import userSummaryDispatcher from "@stores/domain/userSummary/dispatcher";
import workRecordsDispatcher from "@stores/domain/workRecords/dispatcher";
import supportRecordUserDispatcher from "@stores/domain/supportRecordUser/dispatcher";
import supportReportDispatcher from "@stores/domain/supportReport/dispatcher";
import unitsDispatcher from "@stores/domain/units/dispatcher";
import customRecordsDispatcher from "@stores/domain/customRecords/dispatcher";
import { customRecordsWithCategoryDispatcher } from "@stores/domain/customRecordsWithCategory/dispatcher";
import holidayDispatcher from "@stores/domain/holiday/dispatcher";
import { serviceDeliveryDispatcher } from "@stores/domain/serviceDelivery/dispatcher";
import { assessmentDispatcher } from "@stores/domain/assessment/dispatcher";
import { carePlanDispatcher } from "@stores/domain/carePlan/dispatcher";
import { supportPlanSheetsDispatcher } from "@stores/domain/supportPlanSheets/dispatcher";

// pages
import pagesRecordDailyDispatcher from "@stores/pages/record/daily/dispatcher";
import pagesRecordDailyGroupHomeDispatcher from "@stores/pages/record/dailyGroupHome/dispatcher";
import pagesRecordDailyTANKINYUSHODispatcher from "@stores/pages/record/dailyTANKINYUSHO/dispatcher";
import pagesRecordDailySHISETSUNYUSHODispatcher from "@stores/pages/record/dailySHISETSUNYUSHO/dispatcher";
import pagesRecordMonthlyDispatcher from "@stores/pages/record/monthly/dispatcher";
import pagesRecordSupportPlanDispatcher from "@stores/pages/record/supportPlan/dispatcher";
import pagesRecordSupportReportDispatcher from "@stores/pages/record/supportReport/dispatcher";
import pagesRecordSupportPlanADispatcher from "@stores/pages/record/supportPlanA/dispatcher";
import pagesRecordSupportPlanSHUROTEICHAKUDispatcher from "@stores/pages/record/supportPlanSHUROTEICHAKU/dispatcher";
import pagesRecordUserDetailDispatcher from "@stores/pages/record/userDetail/dispatcher";
import pagesReportDailyDispatcher from "@stores/pages/report/daily/dispatcher";
import pagesOffsiteWorkDispatcher from "@stores/pages/record/offsiteWork/dispatcher";

// mgr
import FacilityDispatcher from "@stores/domain/facilities/dispatcher";
import GroupHomeFacilityDispatcher from "@stores/domain/mgr/GroupHome/facility/dispatcher";
import GroupHomeUserInFacilityDispatcher from "@stores/domain/mgr/GroupHome/userInFacility/dispatcher";
import GroupHomeSupportRecordUserDispatcher from "@stores/domain/mgr/GroupHome/supportRecordUser/dispatcher";
import GroupHomeDailyOperationsAndSupportsDispatcher from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/dispatcher";
import SHISETSUNYUSHODailyOperationsAndSupportsDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/dispatcher";
import SHISETSUNYUSHODailySupportsDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/paginatedDailySupports/dispatcher";
import GroupHomeOperationsDispatcher from "@stores/domain/mgr/GroupHome/operations/dispatcher";
import GroupHomeUsersSummaryDispatcher from "@stores/domain/mgr/GroupHome/usersSummary/dispatcher";
import GroupHomeReportDispatcher from "@stores/domain/mgr/GroupHome/report/dispatcher";
import SEIKATSUKAIGOFacilityDispatcher from "@stores/domain/mgr/SEIKATSUKAIGO/facility/dispatcher";
import SEIKATSUKAIGOReportDispatcher from "@stores/domain/mgr/SEIKATSUKAIGO/report/dispatcher";
import SEIKATSUKAIGOUserInFacilityDispatcher from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/dispatcher";
import SEIKATSUKAIGOInitialDispatcher from "@stores/domain/mgr/SEIKATSUKAIGO/initial/dispatcher";
import SHUROTEICHAKUReportDispatcher from "@stores/domain/mgr/SHUROTEICHAKU/report/dispatcher";
import SHUROTEICHAKUUserInFacilityDispatcher from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/dispatcher";
import SHUROTEICHAKUFacilityDispatcher from "@stores/domain/mgr/SHUROTEICHAKU/facility/dispatcher";
import SHUROTEICHAKUSupportPlanDispatcher from "@stores/domain/mgr/SHUROTEICHAKU/supportPlan/dispatcher";
import GroupHomeSupportPlanDispatcher from "@stores/domain/mgr/GroupHome/supportPlan/dispatcher";
import JIRITSUKUNRENSEIKATSUUserInFacilityDispatcher from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/dispatcher";
import JIRITSUKUNRENSEIKATSUReportDispatcher from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/report/dispatcher";
import JIRITSUKUNRENSEIKATSUInitialDispatcher from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/initial/dispatcher";
import JIRITSUKUNRENSEIKATSUFacilityDispatcher from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/dispatcher";
import IABFacilityDispatcher from "@stores/domain/mgr/IAB/facility/dispatcher";
import IABReportDispatcher from "@stores/domain/mgr/IAB/report/dispatcher";
import IABUserInFacilityDispatcher from "@stores/domain/mgr/IAB/userInFacility/dispatcher";
import IABInitialDispatcher from "@stores/domain/mgr/IAB/initial/dispatcher";
import { IDOSHIENFacilityDispatcher } from "@stores/domain/mgr/IDOSHIEN/facility/dispatcher";
import TANKINYUSHOFacilityDispatcher from "@stores/domain/mgr/TANKINYUSHO/facility/dispatcher";
import TANKINYUSHOUserInFacilityDispatcher from "@stores/domain/mgr/TANKINYUSHO/userInFacility/dispatcher";
import TANKINYUSHODailyOperationsAndSupportsDispatcher from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/dispatcher";
import TANKINYUSHOSupportRecordUserDispatcher from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/dispatcher";
import TANKINYUSHOReportDispatcher from "@stores/domain/mgr/TANKINYUSHO/report/dispatcher";
import TANKINYUSHOInitialDispatcher from "@stores/domain/mgr/TANKINYUSHO/initial/dispatcher";
import TANKINYUSHOOperationsDispatcher from "@stores/domain/mgr/TANKINYUSHO/operations/dispatcher";
import TANKINYUSHOUsersSummaryDispatcher from "@stores/domain/mgr/TANKINYUSHO/usersSummary/dispatcher";
import SHISETSUNYUSHOFacilityDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/facility/dispatcher";
import SHISETSUNYUSHOUserInFacilityDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/dispatcher";
import SHISETSUNYUSHOReportDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/report/dispatcher";
import SHISETSUNYUSHOInitialDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/initial/dispatcher";
import SHISETSUNYUSHOOperationsDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/operations/dispatcher";
import SHISETSUNYUSHOUsagePerformanceDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/usagePerformance/dispatcher";
import SHISETSUNYUSHOSupportRecordUserDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/dispatcher";
import SHISETSUNYUSHOUsersSummaryDispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/usersSummary/dispatcher";
import { KYOTAKUKAIGOUserInFacilityDispatcher } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/dispatcher";
import { KYOTAKUKAIGOFacilityDispatcher } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/dispatcher";
import { KYOTAKUKAIGOReportDispatcher } from "@stores/domain/mgr/KYOTAKUKAIGO/report/dispatcher";
import { KYOTAKUKAIGOStaffDispatcher } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/dispatcher";
import { KYOTAKUKAIGOCarePlanDispatcher } from "@stores/domain/mgr/KYOTAKUKAIGO/carePlan/dispatcher";
import { JUDOHOMONKAIGOFacilityDispatcher } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/dispatcher";
import { JUDOHOMONKAIGOUserInFacilityDispatcher } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/dispatcher";
import { JUDOHOMONKAIGOStaffDispatcher } from "@stores/domain/mgr/JUDOHOMONKAIGO/staff/dispatcher";
import { JUDOHOMONKAIGOReportDispatcher } from "@stores/domain/mgr/JUDOHOMONKAIGO/report/dispatcher";
import { JUDOHOMONKAIGOCarePlanDispatcher } from "@stores/domain/mgr/JUDOHOMONKAIGO/carePlan/dispatcher";
import { DOKOENGOReportDispatcher } from "@stores/domain/mgr/DOKOENGO/report/dispatcher";
import { DOKOENGOUserInFacilityDispatcher } from "@stores/domain/mgr/DOKOENGO/userInFacility/dispatcher";
import { DOKOENGOFacilityDispatcher } from "@stores/domain/mgr/DOKOENGO/facility/dispatcher";
import { DOKOENGOCarePlanDispatcher } from "@stores/domain/mgr/DOKOENGO/carePlan/dispatcher";
import { DOKOENGOStaffDispatcher } from "@stores/domain/mgr/DOKOENGO/staff/dispatcher";
import { KODOENGOUserInFacilityDispatcher } from "@stores/domain/mgr/KODOENGO/userInFacility/dispatcher";
import { KODOENGOReportDispatcher } from "@stores/domain/mgr/KODOENGO/report/dispatcher";
import { KODOENGOFacilityDispatcher } from "@stores/domain/mgr/KODOENGO/facility/dispatcher";
import { KODOENGOCarePlanDispatcher } from "@stores/domain/mgr/KODOENGO/carePlan/dispatcher";
import { KODOENGOSupportProcedureDispatcher } from "./domain/mgr/KODOENGO/supportProcedure/dispatcher";
import { KODOENGOStaffDispatcher } from "@stores/domain/mgr/KODOENGO/staff/dispatcher";
import { IDOSHIENMunicipalitiesInFacilityDispatcher } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/dispatcher";
import { CHIIKIIKOUserInFacilityDispatcher } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/dispatcher";
import { CHIIKIIKOFacilityDispatcher } from "@stores/domain/mgr/CHIIKIIKO/facility/dispatcher";
import { CHIIKIIKOReportDispatcher } from "@stores/domain/mgr/CHIIKIIKO/report/dispatcher";
import { CHIIKIIKOPlanMonitoringMeetingDispatcher } from "./domain/mgr/CHIIKIIKO/planMonitoringMeeting/dispatcher";
import { CHIIKIIKOConsultationDispatcher } from "./domain/mgr/CHIIKIIKO/consultation/dispatcher";
import { CHIIKIIKOAssistanceReportDispatcher } from "./domain/mgr/CHIIKIIKO/assistanceReport/dispatcher";
import { CHIIKITEICHAKUUserInFacilityDispatcher } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/dispatcher";
import { CHIIKITEICHAKUFacilityDispatcher } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/dispatcher";
import { CHIIKITEICHAKUReportDispatcher } from "@stores/domain/mgr/CHIIKITEICHAKU/report/dispatcher";
import { CHIIKITEICHAKUSupportLedgerDispatcher } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/dispatcher";
import { KEIKAKUSODANUserInFacilityDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/dispatcher";
import { KEIKAKUSODANInitialDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/initial/dispatcher";
import { KEIKAKUSODANFacilityDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/facility/dispatcher";
import { KEIKAKUSODANReportDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/report/dispatcher";
import { KEIKAKUSODANInquiryDispatcher } from "./domain/mgr/KEIKAKUSODAN/inquiry/dispatcher";
import { KEIKAKUSODANConsultationDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/dispatcher";
import { CsegSupportRecordUserDispatcher } from "@stores/domain/mgr/Cseg/support/dispatcher";
import { CsegOperationsDispatcher } from "@stores/domain/mgr/Cseg/operations/dispatcher";
import { CsegUsersSummaryDispatcher } from "@stores/domain/mgr/Cseg/usersSummary/dispatcher";
import { CsegOperationsAndSupportsDispatcher } from "@stores/domain/mgr/Cseg/operationAndSupports/dispatcher";
import { CsegMeetingRecordDispatcher } from "@stores/domain/mgr/Cseg/meetingRecord/dispatcher";
import { KEIKAKUSODANBasicInfoDispatcher } from "./domain/mgr/KEIKAKUSODAN/basicInfo/dispatcher";
import { KEIKAKUSODANPlanMonitoringMeetingDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/dispatcher";
import { KEIKAKUSODANMonitoringReportDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringReport/dispatcher";
import { KEIKAKUSODANMonitoringMonthDispatcher } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringMonth/dispatcher";
import { IDOSHIENReportDispatcher } from "@stores/domain/mgr/IDOSHIEN/report/dispatcher";
import { IDOSHIENUserInFacilityDispatcher } from "@stores/domain/mgr/IDOSHIEN/userInFacility/dispatcher";

// v201910仕様
import invoice201910Dispatch from "@stores/v201910/domain/invoice/dispatch";

import PagesReportDaily201910Dispatcher from "@stores/v201910/pages/report/daily/dispatcher";

import IABFacility201910Dispatcher from "@stores/v201910/domain/mgr/IAB/facility/dispatcher";
import IABReport201910Dispatcher from "@stores/v201910/domain/mgr/IAB/report/dispatcher";
import IABUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/IAB/userInFacility/dispatcher";
import JIRITSUKUNRENSEIKATSUFacility201910Dispatcher from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/dispatcher";
import JIRITSUKUNRENSEIKATSUReport201910Dispatcher from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/report/dispatcher";
import JIRITSUKUNRENSEIKATSUUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/dispatcher";
import GroupHomeFacility201910Dispatcher from "@stores/v201910/domain/mgr/GroupHome/facility/dispatcher";
import GroupHomeReport201910Dispatcher from "@stores/v201910/domain/mgr/GroupHome/report/dispatch";
import GroupHomeUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/GroupHome/userInFacility/dispatcher";
import SEIKATSUKAIGOFacility201910Dispatcher from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/facility/dispatcher";
import SEIKATSUKAIGOReport201910Dispatcher from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/report/dispatcher";
import SEIKATSUKAIGOUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/userInFacility/dispatcher";
import SHUROTEICHAKUFacility201910Dispatcher from "@stores/v201910/domain/mgr/SHUROTEICHAKU/facility/dispatcher";
import SHUROTEICHAKUUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/SHUROTEICHAKU/userInFacility/dispatcher";
import TANKINYUSHOFacility201910Dispatcher from "@stores/v201910/domain/mgr/TANKINYUSHO/facility/dispatcher";
import SHUROTEICHAKUReport201910Dispatcher from "@stores/v201910/domain/mgr/SHUROTEICHAKU/report/dispatcher";
import TANKINYUSHOUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/TANKINYUSHO/userInFacility/dispatcher";
import TANKINYUSHOReport201910Dispatcher from "@stores/v201910/domain/mgr/TANKINYUSHO/report/dispatcher";
import SHISETSUNYUSHOFacility201910Dispatcher from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/facility/dispatcher";
import SHISETSUNYUSHOUserInFacility201910Dispatcher from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/userInFacility/dispatcher";
import SHISETSUNYUSHOReport201910Dispatcher from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/dispatcher";

// v202104仕様
import invoice202104Dispatch from "@stores/v202104/domain/invoice/dispatch";

import IABFacility202104Dispatcher from "@stores/v202104/domain/mgr/IAB/facility/dispatcher";
import IABReport202104Dispatcher from "@stores/v202104/domain/mgr/IAB/report/dispatcher";
import IABUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/IAB/userInFacility/dispatcher";
import JIRITSUKUNRENSEIKATSUFacility202104Dispatcher from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/dispatcher";
import JIRITSUKUNRENSEIKATSUUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/dispatcher";
import JIRITSUKUNRENSEIKATSUReport202104Dispatcher from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/report/dispatcher";
import { KODOENGOReport202104Dispatcher } from "@stores/v202104/domain/mgr/KODOENGO/report/dispatcher";
import { KODOENGOUserInFacility202104Dispatcher } from "./v202104/domain/mgr/KODOENGO/userInFacility/dispatcher";
import { KODOENGOFacilityDispatcher as KODOENGOFacility202104Dispatcher } from "@stores/v202104/domain/mgr/KODOENGO/facility/dispatcher";
import { KODOENGOCarePlanDispatcher as KODOENGOCarePlan202104Dispatcher } from "@stores/v202104/domain/mgr/KODOENGO/carePlan/dispatcher";
import SHUROTEICHAKUReport202104Dispatcher from "@stores/v202104/domain/mgr/SHUROTEICHAKU/report/dispatcher";
import SHUROTEICHAKUUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/SHUROTEICHAKU/userInFacility/dispatcher";
import SHUROTEICHAKUFacility202104Dispatcher from "@stores/v202104/domain/mgr/SHUROTEICHAKU/facility/dispatcher";
import GroupHomeReport202104Dispatcher from "@stores/v202104/domain/mgr/GroupHome/report/dispatch";
import GroupHomeUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/GroupHome/userInFacility/dispatcher";
import { KEIKAKUSODANUserInFacilityDispatcher as KEIKAKUSODANUserInFacility202104Dispatcher } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/dispatcher";
import { KEIKAKUSODANFacilityDispatcher as KEIKAKUSODANFacility202104Dispatcher } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/dispatcher";
import { KEIKAKUSODANReportDispatcher as KEIKAKUSODANReport202104Dispatcher } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/dispatcher";
import SEIKATSUKAIGOReport202104Dispatcher from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/report/dispatcher";
import SEIKATSUKAIGOUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/userInFacility/dispatcher";
import SEIKATSUKAIGOFacility202104Dispatcher from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/facility/dispatcher";
import GroupHomeFacility202104Dispatcher from "@stores/v202104/domain/mgr/GroupHome/facility/dispatcher";
import TANKINYUSHOFacility202104Dispatcher from "@stores/v202104/domain/mgr/TANKINYUSHO/facility/dispatcher";
import TANKINYUSHOReport202104Dispatcher from "@stores/v202104/domain/mgr/TANKINYUSHO/report/dispatcher";
import TANKINYUSHOUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/TANKINYUSHO/userInFacility/dispatcher";
import SHISETSUNYUSHOFacility202104Dispatcher from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/facility/dispatcher";
import SHISETSUNYUSHOUserInFacility202104Dispatcher from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/userInFacility/dispatcher";
import SHISETSUNYUSHOReport202104Dispatcher from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/report/dispatcher";
import { CHIIKITEICHAKUUserInFacility202104Dispatcher } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/userInFacility/dispatcher";
import { CHIIKITEICHAKUFacility202104Dispatcher } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/facility/dispatcher";
import { CHIIKITEICHAKUReport202104Dispatcher } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/report/dispatcher";
import { KYOTAKUKAIGOFacilityDispatcher as KYOTAKUKAIGOFacility202104Dispatcher } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/dispatcher";
import { KYOTAKUKAIGOUserInFacilityDispatcher as KYOTAKUKAIGOUserInFacility202104Dispatcher } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/dispatcher";
import { KYOTAKUKAIGOCarePlanDispatcher as KYOTAKUKAIGOCarePlan202104Dispatcher } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/carePlan/dispatcher";
import { KYOTAKUKAIGOReportDispatcher as KYOTAKUKAIGOReport202104Dispatcher } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/dispatcher";
import { CHIIKIIKOFacility202104Dispatcher } from "@stores/v202104/domain/mgr/CHIIKIIKO/facility/dispatcher";
import { CHIIKIIKOUserInFacility202104Dispatcher } from "./v202104/domain/mgr/CHIIKIIKO/userInFacility/dispatcher";
import { CHIIKIIKOReport202104Dispatcher } from "@stores/v202104/domain/mgr/CHIIKIIKO/report/dispatcher";
import { DOKOENGOUserInFacilityDispatcher as DOKOENGOUserInFacility202104Dispatcher } from "@stores/v202104/domain/mgr/DOKOENGO/userInFacility/dispatcher";
import { DOKOENGOFacilityDispatcher as DOKOENGOFacility202104Dispatcher } from "@stores/v202104/domain/mgr/DOKOENGO/facility/dispatcher";
import { DOKOENGOCarePlanDispatcher as DOKOENGOCarePlan202104Dispatcher } from "@stores/v202104/domain/mgr/DOKOENGO/carePlan/dispatcher";
import { DOKOENGOReportDispatcher as DOKOENGOReport202104Dispatcher } from "@stores/v202104/domain/mgr/DOKOENGO/report/dispatcher";
import { JUDOHOMONKAIGOFacility202104Dispatcher } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/facility/dispatcher";
import { JUDOHOMONKAIGOUserInFacility202104Dispatcher } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/userInFacility/dispatcher";
import JUDOHOMONKAIGOReport202104Dispatcher from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/report/dispatcher";
import { JUDOHOMONKAIGOCarePlan202104Dispatcher } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/carePlan/dispatcher";

export default {
  accountDispatch,
  authDispatch,
  userDispatch,
  invoiceDispatch,
  errorsDispatcher,
  operationsDispatcher,
  FacilityDispatcher,
  cityDispatch,
  uiDispatch,
  reportDispatch,
  contactDispatch,
  attendanceDispatch,
  offsiteWorkDispatch,
  workDispatcher,
  staffDispatcher,
  supportPlanDispatcher,
  supportsDispatcher,
  userSummaryDispatcher,
  workRecordsDispatcher,
  supportRecordUserDispatcher,
  supportReportDispatcher,
  unitsDispatcher,
  appDownloadDispatch: downloadDispatch,
  customRecords: customRecordsDispatcher,
  customRecordsWithCategory: customRecordsWithCategoryDispatcher,
  holiday: holidayDispatcher,
  serviceDelivery: serviceDeliveryDispatcher,
  assessmentDispatcher,
  carePlanDispatcher,
  supportPlanSheetsDispatcher,
  pages: {
    recordDailyDispatcher: pagesRecordDailyDispatcher,
    recordDailyGroupHomeDispatcher: pagesRecordDailyGroupHomeDispatcher,
    recordDailyTANKINYUSHODispatcher: pagesRecordDailyTANKINYUSHODispatcher,
    recordDailySHISETSUNYUSHODispatcher: pagesRecordDailySHISETSUNYUSHODispatcher,
    recordMonthlyDispatcher: pagesRecordMonthlyDispatcher,
    recordSupportPlanDispatcher: pagesRecordSupportPlanDispatcher,
    recordSupportPlanADispatcher: pagesRecordSupportPlanADispatcher,
    recordSupportPlanSHUROTEICHAKUDispatcher: pagesRecordSupportPlanSHUROTEICHAKUDispatcher,
    recordUserDetailDispatcher: pagesRecordUserDetailDispatcher,
    reportDailyDispatcher: pagesReportDailyDispatcher,
    recordOffsiteWorkDispatcher: pagesOffsiteWorkDispatcher,
    recordSupportReportDispatcher: pagesRecordSupportReportDispatcher
  },
  GroupHome: {
    facilityDispatcher: GroupHomeFacilityDispatcher,
    userInFacilityDispatcher: GroupHomeUserInFacilityDispatcher,
    supportRecordUserDispatcher: GroupHomeSupportRecordUserDispatcher,
    operationsDispatcher: GroupHomeOperationsDispatcher,
    reportDispatcher: GroupHomeReportDispatcher,
    dailyOperationsAndSupportsDispatcher: GroupHomeDailyOperationsAndSupportsDispatcher,
    usersSummaryDispatcher: GroupHomeUsersSummaryDispatcher,
    supportPlanDispatcher: GroupHomeSupportPlanDispatcher
  },
  SEIKATSUKAIGO: {
    facilityDispatcher: SEIKATSUKAIGOFacilityDispatcher,
    userInFacilityDispatcher: SEIKATSUKAIGOUserInFacilityDispatcher,
    initialDataDispatcher: SEIKATSUKAIGOInitialDispatcher,
    reportDispacher: SEIKATSUKAIGOReportDispatcher
  },
  SHUROTEICHAKU: {
    reportDispacher: SHUROTEICHAKUReportDispatcher,
    userInFacilityDispatcher: SHUROTEICHAKUUserInFacilityDispatcher,
    facilityDispatcher: SHUROTEICHAKUFacilityDispatcher,
    supportPlanDispatcher: SHUROTEICHAKUSupportPlanDispatcher
  },
  JIRITSUKUNRENSEIKATSU: {
    facilityDispatcher: JIRITSUKUNRENSEIKATSUFacilityDispatcher,
    userInFacilityDispatcher: JIRITSUKUNRENSEIKATSUUserInFacilityDispatcher,
    initialDataDispatcher: JIRITSUKUNRENSEIKATSUInitialDispatcher,
    reportDispacher: JIRITSUKUNRENSEIKATSUReportDispatcher
  },
  IAB: {
    facilityDispatcher: IABFacilityDispatcher,
    userInFacilityDispatcher: IABUserInFacilityDispatcher,
    initialDataDispatcher: IABInitialDispatcher,
    reportDispatcher: IABReportDispatcher
  },
  TANKINYUSHO: {
    facilityDispatcher: TANKINYUSHOFacilityDispatcher,
    userInFacilityDispatcher: TANKINYUSHOUserInFacilityDispatcher,
    supportRecordUserDispatcher: TANKINYUSHOSupportRecordUserDispatcher,
    initialDataDispatcher: TANKINYUSHOInitialDispatcher,
    reportDispatcher: TANKINYUSHOReportDispatcher,
    operationsDispatcher: TANKINYUSHOOperationsDispatcher,
    dailyOperationsAndSupportsDispatcher: TANKINYUSHODailyOperationsAndSupportsDispatcher,
    usersSummaryDispatcher: TANKINYUSHOUsersSummaryDispatcher
  },
  SHISETSUNYUSHO: {
    facilityDispatcher: SHISETSUNYUSHOFacilityDispatcher,
    userInFacilityDispatcher: SHISETSUNYUSHOUserInFacilityDispatcher,
    supportRecordUserDispatcher: SHISETSUNYUSHOSupportRecordUserDispatcher,
    initialDataDispatcher: SHISETSUNYUSHOInitialDispatcher,
    reportDispatcher: SHISETSUNYUSHOReportDispatcher,
    operationsDispatcher: SHISETSUNYUSHOOperationsDispatcher,
    usagePerformanceDispatcher: SHISETSUNYUSHOUsagePerformanceDispatcher,
    usersSummaryDispatcher: SHISETSUNYUSHOUsersSummaryDispatcher,
    dailyOperationsAndSupportsDispatcher: SHISETSUNYUSHODailyOperationsAndSupportsDispatcher,
    paginateDailySupportsDispatcher: SHISETSUNYUSHODailySupportsDispatcher
  },
  KYOTAKUKAIGO: {
    userInFacilityDispatcher: KYOTAKUKAIGOUserInFacilityDispatcher,
    facilityDispatcher: KYOTAKUKAIGOFacilityDispatcher,
    reportDispatcher: KYOTAKUKAIGOReportDispatcher,
    staffDispatcher: KYOTAKUKAIGOStaffDispatcher,
    carePlanDispatcher: KYOTAKUKAIGOCarePlanDispatcher
  },
  JUDOHOMONKAIGO: {
    userInFacilityDispatcher: JUDOHOMONKAIGOUserInFacilityDispatcher,
    reportDispatcher: JUDOHOMONKAIGOReportDispatcher,
    facilityDispatcher: JUDOHOMONKAIGOFacilityDispatcher,
    staffDispatcher: JUDOHOMONKAIGOStaffDispatcher,
    carePlanDispatcher: JUDOHOMONKAIGOCarePlanDispatcher
  },
  DOKOENGO: {
    userInFacilityDispatcher: DOKOENGOUserInFacilityDispatcher,
    facilityDispatcher: DOKOENGOFacilityDispatcher,
    staffDispatcher: DOKOENGOStaffDispatcher,
    reportDispatcher: DOKOENGOReportDispatcher,
    carePlanDispatcher: DOKOENGOCarePlanDispatcher
  },
  KODOENGO: {
    userInFacilityDispatcher: KODOENGOUserInFacilityDispatcher,
    facilityDispatcher: KODOENGOFacilityDispatcher,
    reportDispatcher: KODOENGOReportDispatcher,
    carePlanDispatcher: KODOENGOCarePlanDispatcher,
    supportProcedureDispatcher: KODOENGOSupportProcedureDispatcher,
    staffDispatcher: KODOENGOStaffDispatcher
  },
  CHIIKIIKO: {
    userInFacilityDispatcher: CHIIKIIKOUserInFacilityDispatcher,
    facilityDispatcher: CHIIKIIKOFacilityDispatcher,
    reportDispatcher: CHIIKIIKOReportDispatcher,
    planMonitoringMeetingDispatcher: CHIIKIIKOPlanMonitoringMeetingDispatcher,
    consultationDispatcher: CHIIKIIKOConsultationDispatcher,
    assistanceReportDispatcher: CHIIKIIKOAssistanceReportDispatcher
  },
  CHIIKITEICHAKU: {
    userInFacilityDispatcher: CHIIKITEICHAKUUserInFacilityDispatcher,
    facilityDispatcher: CHIIKITEICHAKUFacilityDispatcher,
    reportDispatcher: CHIIKITEICHAKUReportDispatcher,
    supportLedgerDispatcher: CHIIKITEICHAKUSupportLedgerDispatcher
  },
  KEIKAKUSODAN: {
    initialDataDispatcher: KEIKAKUSODANInitialDispatcher,
    userInFacilityDispatcher: KEIKAKUSODANUserInFacilityDispatcher,
    facilityDispatcher: KEIKAKUSODANFacilityDispatcher,
    reportDispatcher: KEIKAKUSODANReportDispatcher,
    inquiryDispatcher: KEIKAKUSODANInquiryDispatcher,
    consultationDispatcher: KEIKAKUSODANConsultationDispatcher,
    basicInfoDispatcher: KEIKAKUSODANBasicInfoDispatcher,
    planMonitoringMeetingDispatcher: KEIKAKUSODANPlanMonitoringMeetingDispatcher,
    monitoringReportDispatcher: KEIKAKUSODANMonitoringReportDispatcher,
    monitoringMonthDispatcher: KEIKAKUSODANMonitoringMonthDispatcher
  },
  IDOSHIEN: {
    reportDispatcher: IDOSHIENReportDispatcher,
    userInFacilityDispatcher: IDOSHIENUserInFacilityDispatcher,
    facilityDispatcher: IDOSHIENFacilityDispatcher,
    municipalitiesInFacilityDispatcher: IDOSHIENMunicipalitiesInFacilityDispatcher
  },
  Cseg: {
    operationsDispatcher: CsegOperationsDispatcher,
    supportRecordUserDispatcher: CsegSupportRecordUserDispatcher,
    usersSummaryDispatcher: CsegUsersSummaryDispatcher,
    operationsAndSupportsDispatcher: CsegOperationsAndSupportsDispatcher,
    meetingRecordDispatcher: CsegMeetingRecordDispatcher
  },
  v201910: {
    invoiceDispatch: invoice201910Dispatch,
    pages: {
      reportDailyDispatcher: PagesReportDaily201910Dispatcher
    },
    GroupHome: {
      facilityDispatcher: GroupHomeFacility201910Dispatcher,
      userInFacilityDispatcher: GroupHomeUserInFacility201910Dispatcher,
      reportDispatcher: GroupHomeReport201910Dispatcher
    },
    IAB: {
      facilityDispatcher: IABFacility201910Dispatcher,
      userInFacilityDispatcher: IABUserInFacility201910Dispatcher,
      reportDispatcher: IABReport201910Dispatcher
    },
    JIRITSUKUNRENSEIKATSU: {
      facilityDispatcher: JIRITSUKUNRENSEIKATSUFacility201910Dispatcher,
      userInFacilityDispatcher: JIRITSUKUNRENSEIKATSUUserInFacility201910Dispatcher,
      reportDispatcher: JIRITSUKUNRENSEIKATSUReport201910Dispatcher
    },
    SEIKATSUKAIGO: {
      facilityDispatcher: SEIKATSUKAIGOFacility201910Dispatcher,
      userInFacilityDispatcher: SEIKATSUKAIGOUserInFacility201910Dispatcher,
      reportDispacher: SEIKATSUKAIGOReport201910Dispatcher
    },
    SHUROTEICHAKU: {
      reportDispacher: SHUROTEICHAKUReport201910Dispatcher,
      facilityDispatcher: SHUROTEICHAKUFacility201910Dispatcher,
      userInFacilityDispatcher: SHUROTEICHAKUUserInFacility201910Dispatcher
    },
    TANKINYUSHO: {
      facilityDispatcher: TANKINYUSHOFacility201910Dispatcher,
      userInFacilityDispatcher: TANKINYUSHOUserInFacility201910Dispatcher,
      reportDispatcher: TANKINYUSHOReport201910Dispatcher
    },
    SHISETSUNYUSHO: {
      facilityDispatcher: SHISETSUNYUSHOFacility201910Dispatcher,
      userInFacilityDispatcher: SHISETSUNYUSHOUserInFacility201910Dispatcher,
      reportDispatcher: SHISETSUNYUSHOReport201910Dispatcher
    }
  },
  v202104: {
    invoiceDispatch: invoice202104Dispatch,
    IAB: {
      facilityDispatcher: IABFacility202104Dispatcher,
      userInFacilityDispatcher: IABUserInFacility202104Dispatcher,
      reportDispatcher: IABReport202104Dispatcher
    },
    JIRITSUKUNRENSEIKATSU: {
      facilityDispatcher: JIRITSUKUNRENSEIKATSUFacility202104Dispatcher,
      userInFacilityDispatcher: JIRITSUKUNRENSEIKATSUUserInFacility202104Dispatcher,
      reportDispatcher: JIRITSUKUNRENSEIKATSUReport202104Dispatcher
    },
    KODOENGO: {
      facilityDispatcher: KODOENGOFacility202104Dispatcher,
      reportDispatcher: KODOENGOReport202104Dispatcher,
      userInFacilityDispatcher: KODOENGOUserInFacility202104Dispatcher,
      carePlanDispatcher: KODOENGOCarePlan202104Dispatcher
    },
    SHUROTEICHAKU: {
      reportDispacher: SHUROTEICHAKUReport202104Dispatcher,
      userInFacilityDispatcher: SHUROTEICHAKUUserInFacility202104Dispatcher,
      facilityDispatcher: SHUROTEICHAKUFacility202104Dispatcher
    },
    GroupHome: {
      reportDispatcher: GroupHomeReport202104Dispatcher,
      userInFacilityDispatcher: GroupHomeUserInFacility202104Dispatcher,
      facilityDispatcher: GroupHomeFacility202104Dispatcher
    },
    DOKOENGO: {
      userInFacilityDispatcher: DOKOENGOUserInFacility202104Dispatcher,
      facilityDispatcher: DOKOENGOFacility202104Dispatcher,
      carePlanDispatcher: DOKOENGOCarePlan202104Dispatcher,
      reportDispatcher: DOKOENGOReport202104Dispatcher
    },
    KEIKAKUSODAN: {
      userInFacilityDispatcher: KEIKAKUSODANUserInFacility202104Dispatcher,
      facilityDispatcher: KEIKAKUSODANFacility202104Dispatcher,
      reportDispatcher: KEIKAKUSODANReport202104Dispatcher
    },
    SEIKATSUKAIGO: {
      reportDispacher: SEIKATSUKAIGOReport202104Dispatcher,
      userInFacilityDispatcher: SEIKATSUKAIGOUserInFacility202104Dispatcher,
      facilityDispatcher: SEIKATSUKAIGOFacility202104Dispatcher
    },
    TANKINYUSHO: {
      facilityDispatcher: TANKINYUSHOFacility202104Dispatcher,
      userInFacilityDispatcher: TANKINYUSHOUserInFacility202104Dispatcher,
      reportDispatcher: TANKINYUSHOReport202104Dispatcher
    },
    SHISETSUNYUSHO: {
      facilityDispatcher: SHISETSUNYUSHOFacility202104Dispatcher,
      userInFacilityDispatcher: SHISETSUNYUSHOUserInFacility202104Dispatcher,
      reportDispatcher: SHISETSUNYUSHOReport202104Dispatcher
    },
    CHIIKITEICHAKU: {
      userInFacilityDispatcher: CHIIKITEICHAKUUserInFacility202104Dispatcher,
      facilityDispatcher: CHIIKITEICHAKUFacility202104Dispatcher,
      reportDispatcher: CHIIKITEICHAKUReport202104Dispatcher
    },
    KYOTAKUKAIGO: {
      facilityDispatcher: KYOTAKUKAIGOFacility202104Dispatcher,
      userInFacilityDispatcher: KYOTAKUKAIGOUserInFacility202104Dispatcher,
      carePlanDispatcher: KYOTAKUKAIGOCarePlan202104Dispatcher,
      reportDispatcher: KYOTAKUKAIGOReport202104Dispatcher
    },
    CHIIKIIKO: {
      facilityDispatcher: CHIIKIIKOFacility202104Dispatcher,
      userInFacilityDispatcher: CHIIKIIKOUserInFacility202104Dispatcher,
      reportDispatcher: CHIIKIIKOReport202104Dispatcher
    },
    JUDOHOMONKAIGO: {
      facilityDispatcher: JUDOHOMONKAIGOFacility202104Dispatcher,
      userInFacilityDispatcher: JUDOHOMONKAIGOUserInFacility202104Dispatcher,
      reportDispatcher: JUDOHOMONKAIGOReport202104Dispatcher,
      carePlanDispatcher: JUDOHOMONKAIGOCarePlan202104Dispatcher
    }
  }
};
