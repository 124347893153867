import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import Edit from "@material-ui/icons/Edit";
import {
  InoutConsultationResultsState,
  REPORT_MONTHLY
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  FACILITY_TARGET_TYPE_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";

const styles = (): StyleRules =>
  createStyles({
    superShortIconCell: {
      minWidth: 54,
      width: 54,
      boxSizing: "content-box",
      lineHeight: "10px",
      "&:last-child": {
        paddingRight: 4
      },
      paddingLeft: 4,
      paddingRight: 4
    },
    middleCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      lineHeight: "20px"
    },
    icon: {
      textAlign: "center"
    },
    baseCell: {
      fontSize: 14,
      paddingLeft: 4,
      paddingRight: 4,
      lineHeight: "20px"
    },
    iconDiv: {
      height: 26
    }
  });

type OwnProps = {
  params: InoutConsultationResultsState;
  idx: string;
  classOption: string;
  openModal: (param: InoutConsultationResultsState) => void;
  usersInFacilityState: UsersInFacilityState;
  facility?: FacilityState;
  reportType: "reportDaily" | "reportMonthly";
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  const payEndDate =
    props.usersInFacilityState &&
    props.usersInFacilityState.user.user_in_facility.date_end_in_service
      ? props.usersInFacilityState.user.user_in_facility.date_end_in_service
      : "";
  const serviceEndAdd6Months = new Date(payEndDate);
  serviceEndAdd6Months.setMonth(serviceEndAdd6Months.getMonth() + 6);

  const openEditResultModal = (): void => {
    props.openModal(params);
  };

  const needNursingCareClass = props.usersInFacilityState.user
    .user_in_facility_keikakusodan
    ? props.usersInFacilityState.user.user_in_facility_keikakusodan
        .need_nursing_care_class
    : 0;

  const supportRequiredClass = props.usersInFacilityState.user
    .user_in_facility_keikakusodan
    ? props.usersInFacilityState.user.user_in_facility_keikakusodan
        .support_required_class
    : 0;

  const getOther = (): JSX.Element => {
    const flgList = [];
    if (
      params.sameInhomeNursingCareSupportFlg &&
      (needNursingCareClass === 1 || needNursingCareClass === 2)
    )
      flgList.push("居宅介護支援費重複減算Ⅰ");
    if (
      params.sameInhomeNursingCareSupportFlg &&
      (needNursingCareClass === 3 ||
        needNursingCareClass === 4 ||
        needNursingCareClass === 5)
    )
      flgList.push("居宅介護支援費重複減算Ⅱ");
    if (
      params.samePreventiveCareSupportFlg &&
      (supportRequiredClass === 1 || supportRequiredClass === 2)
    )
      flgList.push("介護予防支援費重複減算");
    if (params.dischargeAdditionFlg) flgList.push("退院・退所加算");
    if (params.medicalDaycareEducationRelationFlg)
      flgList.push("医療・保育・教育機関等連携加算");
    if (params.servicePersonnelMeetingHeldFlg)
      flgList.push("サービス担当者会議実施加算");
    if (params.serviceProvisionMonitoringFlg)
      flgList.push("サービス提供時モニタリング加算");
    if (params.hospitalizationRelationAddition !== 0)
      flgList.push("入院時情報連携加算");
    if (params.lifeSupportPromotionFlg)
      flgList.push("地域生活支援拠点等相談強化加算");
    if (params.systemPromotionFlg) flgList.push("地域体制強化共同支援加算");
    if (flgList.length === 0) flgList.push("-");

    const texts = flgList.map((item) => {
      return (
        <div key={`other-${item}`}>
          {item}
          <br />
        </div>
      );
    });
    return <div>{texts}</div>;
  };

  const getStatusLabel = (): string => {
    let statusList: { label: string; value: string }[] = [];
    if (params.typeConsultation === 1) {
      statusList = STATUS_LIST;
    } else if (params.typeConsultation === 2) {
      statusList = STATUS_LIST_SHOGAIJISODAN;
    }

    const status = statusList.find(({ value }) => value === `${params.status}`);
    return status ? status.label : "-";
  };

  const isMonthly: boolean = props.reportType === REPORT_MONTHLY;

  return (
    <>
      {/* サービス種類 */}
      {!isMonthly && (
        <TableCellWrap
          key={`${idx}-type-consultation`}
          cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
        >
          {params.typeConsultation === 1 && "計画相談支援"}
          {params.typeConsultation === 2 && "障害児相談支援"}
        </TableCellWrap>
      )}

      {/* サービス内容 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {getStatusLabel()}
      </TableCellWrap>

      {/* 初回加算 */}
      <TableCellWrap
        key={`${idx}-first-addition-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.firstAdditionFlg ? "あり" : "-"}
        <br />
        {params.firstAdditionSpan === 1 ? "4ヶ月目" : ""}
        {params.firstAdditionSpan === 2 ? "5ヶ月目" : ""}
        {params.firstAdditionSpan === 3 ? "6ヶ月目以上" : ""}
      </TableCellWrap>

      {/* 集中支援加算 */}
      <TableCellWrap
        key={`${idx}-intensive-support1-addition-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.intensiveSupport1AdditionFlg ? "①" : ""}
        {params.intensiveSupport2AdditionFlg ? "②" : ""}
        {params.intensiveSupport3AdditionFlg ? "③" : ""}
        {!params.intensiveSupport1AdditionFlg &&
        !params.intensiveSupport2AdditionFlg &&
        !params.intensiveSupport3AdditionFlg
          ? "-"
          : ""}
      </TableCellWrap>

      {/* 居宅介護支援事業所等連携加算/保育・教育等移行支援加算 */}
      {params.typeConsultation === FACILITY_TARGET_TYPE_KEIKAKUSODAN ? (
        <TableCellWrap
          key={`${idx}-inhome-nursing-care-support-office-relation-flg`}
          cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
        >
          {params.inhomeNursingCareSupportOfficeRelation1Flg ? "①" : ""}
          {params.inhomeNursingCareSupportOfficeRelation2Flg ? "②" : ""}
          {params.inhomeNursingCareSupportOfficeRelation3Flg ? "③" : ""}
          {params.inhomeNursingCareSupportOfficeRelation4Flg ? "④" : ""}
          {params.inhomeNursingCareSupportOfficeRelation5Flg ? "⑤" : ""}
          {params.inhomeNursingCareSupportOfficeRelation6Flg ? "⑥" : ""}
          {!params.inhomeNursingCareSupportOfficeRelation1Flg &&
          !params.inhomeNursingCareSupportOfficeRelation2Flg &&
          !params.inhomeNursingCareSupportOfficeRelation3Flg &&
          !params.inhomeNursingCareSupportOfficeRelation4Flg &&
          !params.inhomeNursingCareSupportOfficeRelation5Flg &&
          !params.inhomeNursingCareSupportOfficeRelation6Flg
            ? "-"
            : ""}
        </TableCellWrap>
      ) : (
        <TableCellWrap
          key={`${idx}-childcareTransitionSupportFlg`}
          cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
        >
          {params.childcareTransitionSupport1Flg ? "①" : ""}
          {params.childcareTransitionSupport2Flg ? "②" : ""}
          {params.childcareTransitionSupport3Flg ? "③" : ""}
          {!params.childcareTransitionSupport1Flg &&
          !params.childcareTransitionSupport2Flg &&
          !params.childcareTransitionSupport3Flg
            ? "-"
            : ""}
        </TableCellWrap>
      )}

      {/* その他加算・減算 */}
      <TableCellWrap
        key={`${idx}-other`}
        cellClass={`${classes.middleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {getOther()}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-edit-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        <div className={props.classes.iconDiv}>
          <Edit
            style={{
              width: 24,
              height: 24,
              color: "#0277bd",
              cursor: "pointer"
            }}
            onClick={openEditResultModal}
          />
        </div>
      </TableCellWrap>
    </>
  );
};

export const InOutReportTableCells = withStyles(styles)(
  InOutReportTableCellsCore
);
