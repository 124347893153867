import { isWithinRange } from "@utils/domain/mgr/isWithinRange";

/**
 * 重複時間の算出
 */
export const calculateOverlapTime = (
  item: { inTime: string; outTime: string },
  list: { inTime: string; outTime: string }[]
): { inTime: string; outTime: string }[] => {
  const result: { inTime: string; outTime: string }[] = [];

  list.forEach((record) => {
    // 時間かぶっているかチェック
    const start1 = isWithinRange(item.inTime, item.outTime, record.inTime);
    const end1 = isWithinRange(item.inTime, item.outTime, record.outTime);
    const start2 = isWithinRange(record.inTime, record.outTime, item.inTime);
    const end2 = isWithinRange(record.inTime, record.outTime, item.outTime);
    const equal =
      item.inTime === record.inTime && item.outTime === record.outTime;

    if (start1 || end1 || start2 || end2 || equal) {
      result.push({
        inTime: start1 ? record.inTime : item.inTime,
        outTime: end1 ? record.outTime : item.outTime
      });
    }
  });

  return result;
};
