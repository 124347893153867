import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

// api docs: https://rhc-bd.atlassian.net/wiki/spaces/KNOW/pages/2571141121/GET+host+version+mgr+support+plan+usersInFacilityId+plan+monitoring+meeting
export type GetPlanMonitoringMeetingResponse = {
  data: {
    yearly: {
      year: number;
      monthly: {
        month: number;
        monitoring_flg: number;
        monitoring_not_create: number;
        daily: {
          creation_date: string;
          is_holiday: number;
          reports: {
            id: number;
            target_flg: number;
            type_consultation?: number;
          }[];
        }[];
      }[];
    }[];
  };
};

/**
 * 計画・モニタリング・会議記録一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const getPlanMonitoringMeeting = async (
  uifId: string
): Promise<AxiosResponse<GetPlanMonitoringMeetingResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/plan_monitoring_meeting`;
  return request.get<GetPlanMonitoringMeetingResponse>(url);
};
