/**
 * 終了日が設定されている場合に開始日も設定されているかチェック
 * @param value
 */

import { SelectDateValue } from "@interfaces/ui/form";

export const withStartDate = (
  date: SelectDateValue,
  startDate: SelectDateValue,
  options = { startLabel: "開始日" }
): {
  year: string | undefined;
  month: undefined;
  day: undefined;
} => {
  let errorMessage;
  if (date.year && date.month && date.day) {
    if (
      startDate.year === "" ||
      startDate.month === "" ||
      startDate.day === ""
    ) {
      errorMessage = `${options.startLabel}も入力してください`;
    }
  }
  return {
    year: errorMessage,
    month: undefined,
    day: undefined
  };
};
