import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { generateRadioItems } from "@utils/dataNormalizer";
import {
  DEFAULT_SELECT_DATE_VALUE,
  seeHearTeam202404Types
} from "@constants/variables";
import { FormikProps, getIn } from "formik";
import { FacilityValues as IABValues } from "@initialize/mgr/IAB/facility/initialValues";
import { FacilityValues as GHValues } from "@initialize/mgr/GroupHome/facility/initialValues";
import { FacilityValues as JIRITSUValues } from "@initialize/mgr/JIRITSUKUNRENSEIKATSU/facility/initialValues";
import { FacilityValues as SEIKATSUValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";
import { FacilityValues as SHISETSUValues } from "@initialize/mgr/SHISETSUNYUSHO/facility/initialValues";

const seeHeaderTeam202404TypeRadioItems = generateRadioItems(
  seeHearTeam202404Types
);

const styles = (): StyleRules =>
  createStyles({
    dateWrap: {
      margin: "-20px 0 30px 32px"
    }
  });

type OwnProps = {
  formikProps:
    | FormikProps<IABValues>
    | FormikProps<GHValues>
    | FormikProps<JIRITSUValues>
    | FormikProps<SEIKATSUValues>
    | FormikProps<SHISETSUValues>;
  tooltip?: React.ReactElement;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 視覚・聴覚言語障害者支援体制加算
 */
const SeeHearTeam202404Fields: React.FC<Props> = (props) => {
  const value = getIn(
    props.formikProps.values,
    "additionalItem.seeHearTeam202404"
  );
  const { setFieldValue } = props.formikProps;

  // 変更時、開始・終了年月日は初期値に戻す
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value === "1") {
      setFieldValue(
        "additionalItem.seeHearTeam202404From",
        DEFAULT_SELECT_DATE_VALUE
      );
      setFieldValue(
        "additionalItem.seeHearTeam202404To",
        DEFAULT_SELECT_DATE_VALUE
      );
    }
  };

  return (
    <div>
      <FormikRadioButtons
        name="additionalItem.seeHearTeam202404"
        label="視覚・聴覚言語障害者支援体制加算"
        options={seeHeaderTeam202404TypeRadioItems}
        onChangeHook={onChangeRadio}
        tooltip={props.tooltip}
      />
      {value !== seeHeaderTeam202404TypeRadioItems[0].value && (
        <div className={props.classes.dateWrap}>
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.seeHearTeam202404From"
            label="開始年月日"
            setFormikFieldValue={setFieldValue}
            addYearTo={1}
            overrideYearFrom={2024}
            style={{ marginBottom: 22 }}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.seeHearTeam202404To"
            label="終了年月日（任意）"
            setFormikFieldValue={setFieldValue}
            addYearTo={5}
            overrideYearFrom={2024}
            style={{ marginBottom: 0 }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(withStyles(styles)(SeeHearTeam202404Fields));
