import React, {
  Dispatch as reactDispatch,
  SetStateAction,
  useEffect
} from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { FieldItem } from "@interfaces/ui/form";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FormikProps, ArrayHelpers, FieldArray } from "formik";
import {
  initialMonitoringReportDetails,
  MonitoringReportValues,
  loadValuesDetails,
  isDetailUpdate
} from "@initialize/mgr/KEIKAKUSODAN/record/monitoringReport/initialValues";
import {
  Checkbox,
  DISABILITY_CLASS_LIST,
  INCOME_KIND_LIST,
  INCOME_KIND_TYPE_LIST,
  PLAN_CHANGE
} from "@constants/variables";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";
import { ConsultationRecordState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import {
  CONSULTATION_TARGET_FRG,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  PARAMETER_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      background: "#fff",
      position: "relative"
    },
    createAndUpdateDate: {
      height: "16px",
      marginTop: "-16px"
    },
    field: { marginTop: "32px" },
    fieldFirst: { marginTop: "12px" },
    radio: {
      "&>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>label>span:first-child": {
        color: "#757575"
      },
      "&>div>div>label>span:last-child": {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "24px"
    },
    deleteButton: {
      marginTop: "32px",
      marginLeft: "-8px"
    },
    btnTxt: {
      marginLeft: "8px"
    },
    priorityCards: {
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd",
      marginBottom: "8px"
    },
    priorityCardsTitle: {
      fontSize: "14px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    planNeediness: {
      margin: "32px 0 15px 0",
      color: "rgba(0, 0, 0, 0.87)"
    },
    loadPlanButtonWrapper: { display: "flex", justifyContent: "flex-end" },
    loadPlanButton: {
      width: "240px",
      marginBottom: "15px"
    },
    W240: {
      width: "240px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT32: {
      marginTop: "32px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    income: {
      display: "flex",
      alignItems: "row"
    },
    incomeKind: {
      width: 138
    },
    incomeKindType: {
      width: 276,
      marginTop: 22
    },
    header: {
      display: "flex",
      alignItems: "row",
      width: "100%",
      height: 48,
      backgroundColor: "#eceff1",
      color: "#37474f",
      fontSize: 12
    },
    content: {
      width: 300,
      margin: "15px 0 0 15px"
    },
    headerRadio: {
      marginTop: 15,
      width: 48
    },
    bodyRow: {
      display: "flex",
      alignItems: "row",
      width: "100%",
      minHeight: 56,
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
    },
    unCheck: {
      width: 100,
      margin: "12px 0 0 12px"
    },
    guardianWrapper: {
      display: "flex"
    },
    guardianInfos: {
      marginRight: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<MonitoringReportValues>;
  user: UsersInFacilityState["user"];
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  consultationCopy: ConsultationRecordState | null;
  isEditing: boolean;
  onClickLoad: (isSchedule?: boolean) => () => void;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  resetCopyConsultation: () => void;
  setIsDetailUpdate: reactDispatch<SetStateAction<boolean>>;
  facilityType: number | null;
  isNew?: boolean;
  queryParam?: string;
} & WithStyles<typeof styles>;

type Props = OwnProps;

const MonitoringReportFieldsCore = (props: Props): JSX.Element => {
  const {
    formikProps,
    classes,
    staffOptionsAddSnapShot,
    consultationCopy,
    isEditing,
    authorValue,
    planMonitoringMeeting,
    onClickLoad,
    facilityType,
    isNew,
    user,
    queryParam
  } = props;

  const { values } = formikProps;

  // 所得区分表示フラグ（負担上限月額0円(=1)の場合表示）
  const [showIncomeKindType, setShowIncomeKindType] = React.useState(
    values.income_kind === "1"
  );

  useEffect(() => {
    setShowIncomeKindType(values.income_kind === "1");
  }, [values.income_kind]);

  // 上書きフラグ
  React.useEffect(() => {
    props.setIsDetailUpdate(isDetailUpdate(values));
  }, [values]);

  // 計画読み込み
  React.useEffect(() => {
    if (consultationCopy) {
      formikProps.setValues(loadValuesDetails(values, consultationCopy));

      props.resetCopyConsultation();
    }
  }, [consultationCopy]);

  // 作成済みの計画が存在するか
  const consultationExists = planMonitoringMeeting.yearly.some((yearly) => {
    return yearly.monthly.some((monthly) => {
      return (
        monthly.daily && // モニタリング設定月だけ設定されており記録がない場合はdaily以下は返ってこない
        monthly.daily.some((daily) => {
          return daily.reports.some((report) => {
            return (
              report.target_flg === CONSULTATION_TARGET_FRG.CONSULTATION_PLAN ||
              report.target_flg === CONSULTATION_TARGET_FRG.CONSULTATION
            );
          });
        })
      );
    });
  });

  const isDisabledAddButton =
    values.details.filter((detail) => !detail.is_delete).length >= 10;

  // 計画変更の必要性選択解除
  const onClickUnCheck = (e: React.MouseEvent<HTMLInputElement>): void => {
    const { name } = e.currentTarget;
    formikProps.setFieldValue(name, "");
  };

  // 所得区分の表示名
  const getLabel = (value: string): string => {
    const selected = INCOME_KIND_TYPE_LIST.find(
      (option) => option.value === value
    );
    if (selected) {
      return selected.value === "0" ? "-" : selected.label;
    }
    return "-";
  };

  const classifyHandicappedFlg =
    user.user_in_facility.classify_handicapped_flg === Checkbox.ON;
  const isShogaiji = isNew
    ? (facilityType === FACILITY_TYPE_SHOGAIJISODAN &&
        classifyHandicappedFlg) ||
      (facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        queryParam === PARAMETER_TYPE_SHOGAIJISODAN &&
        classifyHandicappedFlg)
    : values.type_consultation === FACILITY_TARGET_TYPE_SHOGAIJISODAN;

  return (
    <div className={classes.wrapper}>
      {!isNew && (
        <div className={classes.createAndUpdateDate}>
          <CreateAndUpdateDate
            createdAt={values.created_at}
            updatedAt={values.updated_at}
          />
        </div>
      )}
      {isShogaiji && (
        <div className={classes.guardianWrapper}>
          <div className={classes.guardianInfos}>
            <RecordTextField
              name="guardian_name"
              label="保護者氏名"
              labelType="default"
              defaultValue=""
              placeholder=""
              value={values.guardian_name || ""}
              isEditable={isEditing}
              nonMultiline
              maxLength={48}
            />
          </div>
          <div className={classes.guardianInfos}>
            <RecordTextField
              name="guardian_relation"
              label="本人との続柄"
              labelType="default"
              defaultValue=""
              placeholder=""
              value={values.guardian_relation || ""}
              isEditable={isEditing}
              nonMultiline
              maxLength={12}
            />
          </div>
        </div>
      )}
      <div className={`${isShogaiji && classes.MT32}`}>
        <RecordSelectDate
          name="date_of_monitoring"
          label="モニタリング実施日"
          value={values.date_of_monitoring}
          isEditable={isEditing}
          overrideYearFrom={1989}
          required
        />
      </div>
      <div className={classes.field}>
        <RecordSelectDate
          name="creation_date"
          label="計画作成日"
          value={values.creation_date}
          isEditable={isEditing}
          overrideYearFrom={1989}
        />
      </div>
      <div className={ClassNames(classes.field, classes.W240)}>
        <RecordSelect
          name="author"
          label="計画作成者"
          defaultValue=""
          placeholder="選択してください"
          options={staffOptionsAddSnapShot}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          value={authorValue}
          isEditable={isEditing}
          isSelectablePlaceholder
        />
      </div>
      <div className={classes.field}>
        {isEditing ? (
          <FormikRadioButtons
            name="classify_disability_support"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
          />
        ) : (
          <div className={classes.radio}>
            <MuiRadioButtons
              name="read_only_classify_disability_support"
              label="障害支援区分"
              options={DISABILITY_CLASS_LIST}
              value={values.classify_disability_support}
            />
          </div>
        )}
      </div>
      <div className={classes.income}>
        <div className={classes.incomeKind}>
          {isEditing ? (
            <FormikRadioButtons
              name="income_kind"
              label="利用者負担上限額"
              options={INCOME_KIND_LIST}
            />
          ) : (
            <div className={classes.radio}>
              <MuiRadioButtons
                name="read_only_income_kind"
                label="利用者負担上限額"
                options={INCOME_KIND_LIST}
                value={values.income_kind}
              />
            </div>
          )}
        </div>
        <div className={classes.incomeKindType}>
          {showIncomeKindType && (
            <RecordSelect
              name="income_category"
              label="所得区分"
              defaultValue=""
              placeholder="選択してください"
              options={INCOME_KIND_TYPE_LIST}
              isEditable={isEditing}
              value={getLabel(values.income_category)}
            />
          )}
        </div>
      </div>
      <div className={classes.W240}>
        <RecordTextField
          name="disabled_welfare_service_recipient_number"
          label="障害福祉サービス受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.disabled_welfare_service_recipient_number}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="regular_place_recipient_number"
          label="通所受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.regular_place_recipient_number}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="consultation_support_recipient_number"
          label="地域相談支援受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.consultation_support_recipient_number}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      <div className={classes.field}>
        <RecordTextField
          name="support_policy"
          label="総合的な援助の方針"
          labelType="default"
          defaultValue=""
          value={values.support_policy}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      <div className={classes.field}>
        <RecordTextField
          name="whole_situation"
          label="全体の状況"
          labelType="default"
          defaultValue=""
          value={values.whole_situation}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        モニタリング詳細
      </Typography>
      {isEditing && (
        <div className={classes.loadPlanButtonWrapper}>
          <KnowbeButton
            className={classes.loadPlanButton}
            kind="default"
            onClick={onClickLoad()}
            disabled={!consultationExists}
          >
            計画から支援目標を読みこむ
          </KnowbeButton>
        </div>
      )}
      {/* 優先順位 */}
      <FieldArray name="details">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          return (
            <>
              {values.details
                .map((detail, index) => {
                  return {
                    detail,
                    baseIndex: index
                  };
                })
                .filter((item) => !item.detail.is_delete)
                .map((item, index) => {
                  const { detail, baseIndex } = item;
                  return (
                    <div
                      className={classes.priorityCards}
                      key={`details_${baseIndex}_${detail.id}`}
                    >
                      <div className={classes.priorityCardsTitle}>{`優先順位${
                        index + 1
                      }`}</div>
                      <div className={classes.fieldFirst}>
                        <RecordTextField
                          name={`details[${baseIndex}].support_goal`}
                          label="支援目標"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].support_goal}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].achievement_time`}
                          label="達成時期"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].achievement_time}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].service_provision_status`}
                          label="サービス提供状況（事業者からの聞き取り）"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].service_provision_status
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].personal_satisfaction`}
                          label="本人の感想・満足度"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].personal_satisfaction
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].achievement_support_goals`}
                          label="支援目標の達成度（ニーズの充足度）"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].achievement_support_goals
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].future_issues`}
                          label="今後の課題・解決方法"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].future_issues}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      <div className={classes.planNeediness}>
                        計画変更の必要性
                      </div>
                      {/* 計画変更の必要性TBL-HEADER */}
                      <div className={classes.header}>
                        <div className={classes.content}>変更内容</div>
                        <div className={classes.headerRadio}> 無し</div>
                        <div className={classes.headerRadio}> 有り</div>
                      </div>
                      {/* 計画変更の必要性TBL-BODY */}
                      <div>
                        <div className={classes.bodyRow}>
                          <div className={classes.content}>
                            サービス種類の変更
                          </div>
                          {isEditing ? (
                            <FormikRadioButtons
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "12px 0 0 0"
                              }}
                              name={`details[${baseIndex}].change_plan_service_type`}
                              label=""
                              options={PLAN_CHANGE}
                            />
                          ) : (
                            <div className={classes.radio}>
                              <MuiRadioButtons
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  margin: "12px 0 0 0"
                                }}
                                name={`read_only_details[${baseIndex}].change_plan_service_type`}
                                label=""
                                options={PLAN_CHANGE}
                                value={
                                  values.details[baseIndex]
                                    .change_plan_service_type
                                }
                              />
                            </div>
                          )}

                          <div className={classes.unCheck}>
                            {isEditing && (
                              <KnowbeButton
                                kind="outlineMini"
                                name={`details[${baseIndex}].change_plan_service_type`}
                                onClick={onClickUnCheck}
                                disabled={
                                  !values.details[baseIndex]
                                    .change_plan_service_type
                                }
                              >
                                選択解除
                              </KnowbeButton>
                            )}
                          </div>
                        </div>
                        <div className={classes.bodyRow}>
                          <div className={classes.content}>
                            サービス量の変更
                          </div>
                          {isEditing ? (
                            <FormikRadioButtons
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "12px 0 0 0"
                              }}
                              name={`details[${baseIndex}].change_plan_service_volume`}
                              label=""
                              options={PLAN_CHANGE}
                            />
                          ) : (
                            <div className={classes.radio}>
                              <MuiRadioButtons
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  margin: "12px 0 0 0"
                                }}
                                name={`read_only_details[${baseIndex}].change_plan_service_volume`}
                                label=""
                                options={PLAN_CHANGE}
                                value={
                                  values.details[baseIndex]
                                    .change_plan_service_volume
                                }
                              />
                            </div>
                          )}

                          <div className={classes.unCheck}>
                            {isEditing && (
                              <KnowbeButton
                                kind="outlineMini"
                                name={`details[${baseIndex}].change_plan_service_volume`}
                                onClick={onClickUnCheck}
                                disabled={
                                  !values.details[baseIndex]
                                    .change_plan_service_volume
                                }
                              >
                                選択解除
                              </KnowbeButton>
                            )}
                          </div>
                        </div>
                        <div className={classes.bodyRow}>
                          <div className={classes.content}>週間計画の変更</div>
                          {isEditing ? (
                            <FormikRadioButtons
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                margin: "12px 0 0 0"
                              }}
                              name={`details[${baseIndex}].change_plan_weekly`}
                              label=""
                              options={PLAN_CHANGE}
                            />
                          ) : (
                            <div className={classes.radio}>
                              <MuiRadioButtons
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  margin: "12px 0 0 0"
                                }}
                                name={`read_only_details[${baseIndex}].change_plan_weekly`}
                                label=""
                                options={PLAN_CHANGE}
                                value={
                                  values.details[baseIndex].change_plan_weekly
                                }
                              />
                            </div>
                          )}

                          <div className={classes.unCheck}>
                            {isEditing && (
                              <KnowbeButton
                                kind="outlineMini"
                                name={`details[${baseIndex}].change_plan_weekly`}
                                onClick={onClickUnCheck}
                                disabled={
                                  !values.details[baseIndex].change_plan_weekly
                                }
                              >
                                選択解除
                              </KnowbeButton>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].other_considerations`}
                          label="その他留意事項"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].other_considerations}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {isEditing && (
                        <div className={props.classes.deleteButton}>
                          <DeleteButton
                            text="削除する"
                            onClick={(): void => {
                              formikProps.setFieldValue(
                                `details[${baseIndex}].is_delete`,
                                1
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              {isEditing && (
                <KnowbeButton
                  kind="iconText"
                  className={classes.MT16}
                  onClick={(): void =>
                    arrayHelpers.push(initialMonitoringReportDetails)
                  }
                  disabled={isDisabledAddButton}
                >
                  <AddIcon />
                  <span className={classes.btnTxt}>
                    モニタリング詳細を追加する
                  </span>
                </KnowbeButton>
              )}
            </>
          );
        }}
      </FieldArray>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      {/* 職員コメント */}
      <div className={classes.field}>
        <RecordTextField
          name="staff_comment"
          label="職員コメント"
          labelType="default"
          defaultValue=""
          value={values.staff_comment}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
    </div>
  );
};

export const MonitoringReportFields = withStyles(styles)(
  MonitoringReportFieldsCore
);
