import { BasicErrors } from "@interfaces/mgr/DOKOENGO/facility/basic";
import { FacilityValues } from "@initialize/mgr/DOKOENGO/facility/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import { SubtractionItemErrors } from "@interfaces/mgr/DOKOENGO/facility/subtractionItem";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  SELECTED_CONDITION_TYPE_2024_V
} from "@constants/variables";
import { AdditionalItemErrors } from "@interfaces/mgr/DOKOENGO/facility/additionItem";

type FacilityErrors = BasicErrors;

const basicValidation = (values: FacilityValues): BasicErrors => {
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      officeNumber: validator(
        values.basic.officeNumber,
        "required",
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      officeName: validator(values.basic.officeName, "required"),
      representativeName: validator(
        values.basic.representativeName,
        "required"
      ),
      allCapacity: validateSwitcher(
        values.basic.multiFunctionOfficeFlag,
        validator(
          values.basic.allCapacity,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 999
          }
        )
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "required", "naturalNumber")
    }
  };
};

const subtractionItemValidation = (
  values: FacilityValues
): SubtractionItemErrors => {
  return {
    subtractionItem: {
      bodyRestrictedStillFrom: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillFrom),
          "required"
        )
      ),
      bodyRestrictedStillTo: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillTo),
          {
            type: "future",
            startDate: values.subtractionItem.bodyRestrictedStillFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};

/**
 * 加算対象項目
 */
const AdditionalValidation = (values: FacilityValues): AdditionalItemErrors => {
  return {
    additionalItem: {
      specificBetterSupporterCondition202404:
        values.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        values.additionalItem.specificBetterSupporterCondition202404Sub ===
          `${NO_SELECTED_CONDITION_TYPE_2024_SUB}`
          ? "福祉・介護職員等処遇改善（Ⅴ）以下の項目を選択してください"
          : undefined
    }
  };
};

export const validation = (values: FacilityValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const subtractionItemErrors = subtractionItemValidation(values);
  const additionalItemErrors = AdditionalValidation(values);
  return {
    ...basicErrors,
    ...subtractionItemErrors,
    ...additionalItemErrors
  };
};
