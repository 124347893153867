import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetOperationsAndSupports = {
  data: {
    summary: {
      facility_unit_id: number;
      counts: {
        status_type: {
          stay: number;
          hospitalization: number;
          stay_away: number;
          denominator: number;
        };
        meal: {
          custom_record_item_id: number;
          choices: {
            id: number;
            name: string;
            count: number;
          }[];
        };
      };
    }[];
    operation: {
      facility_unit_id: number;
      operation_records_id: number;
      operation_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    support: {
      user_in_facility_id: number;
      support_records_id: number;
      status_type: number;
      support_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    support_count: number;
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 指定日の業務記録と支援記録を取得する
 * @param yyyymmdd 日付
 * @param facility_unit_id グループID 0は事業所全体
 * @param first_page 先頭ページ分のみ表示するフラグ 0は全件
 */
const getOperationsAndSupports = async (
  yyyymmdd: string,
  facility_unit_id = 0,
  first_page?: number
): Promise<AxiosResponse<GetOperationsAndSupports>> => {
  const url = `${VERSION_URL_201910}/operations/${yyyymmdd}`;
  const query = `?facility_unit_id=${facility_unit_id}${
    first_page ? `&first_page=${first_page}` : ""
  }`;
  return request.get<GetOperationsAndSupports>(url + query);
};

export default getOperationsAndSupports;
