import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { AttendanceTime } from "@components/organisms/attendance/AttendanceTime";
import { Attendance } from "@stores/domain/attendance/type";
import { SERVICE_STATUS_202104 } from "@constants/variables";
import { ATTENDANCE_BOX_SHADOW } from "@constants/styles";
import attendanceInScreen from "@images/attendance_in_screen.gif";
import attendanceOutScreen from "@images/attendance_out_screen.gif";
import { dateToLocalisedString } from "@utils/date";
import classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    loot: {
      width: "100%",
      backgroundColor: "#eee",
      position: "fixed",
      zIndex: 1200,
      left: 0,
      top: "100vh",
      transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
      overflow: "scroll",
      fontFamily:
        "'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif"
    },
    contentWrapper: {
      height: "100vh"
    },
    content: {
      display: "flex",
      margin: "0 auto 0",
      padding: "56px 12px 0",
      maxWidth: 960,
      "@media only screen and (max-width: 875px)": {
        display: "block",
        margin: "40px 16px 0",
        padding: "0 12px"
      }
    },
    header: {
      padding: "10px 16px",
      backgroundColor: "#50aab4",
      height: 60
    },
    closeButton: {
      padding: "12px 16px",
      backgroundColor: "#50aab4",
      boxShadow: "none",
      lineHeight: 1,
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#73bbc3"
      },
      "&:active": {
        boxShadow: "none"
      }
    },
    closeButtonLabel: {
      color: "#fff",
      fontSize: 16,
      width: 64
    },
    buttonWrapper: {
      display: "flex",
      flexDirection: "column",
      marginTop: 12
    },
    workingButton: {
      width: 180,
      height: 56,
      marginLeft: "auto",
      marginRight: "auto",
      color: "#fff",
      boxShadow: ATTENDANCE_BOX_SHADOW,
      "& + $workingButton": {
        marginTop: 24
      },
      "&:only-of-type": {
        marginTop: 14,
        marginBottom: 16
      },
      "&:hover": {
        backgroundColor: "#50aab4"
      }
    },
    primary: {
      backgroundColor: "#50aab4",
      "&:hover": {
        backgroundColor: "#50aab4"
      }
    },
    secondary: {
      backgroundColor: "#7167b9",
      "&:hover": {
        backgroundColor: "#7167b9"
      }
    },
    third: {
      backgroundColor: "#d06774",
      "&:hover": {
        backgroundColor: "#d06774"
      }
    },
    long: {
      width: 308
    },
    semiSpace: {
      paddingLeft: 20
    },
    space: {
      paddingLeft: 12
    },
    afterButton: {
      backgroundColor: "#f5cd00",
      width: 180,
      height: 56,
      margin: "32px auto 16px",
      color: "#fff",
      boxShadow: ATTENDANCE_BOX_SHADOW,
      "&:hover": {
        backgroundColor: "#f5cd00"
      }
    },
    attendanceButtonLabel: {
      display: "block",
      textAlign: "center",
      fontSize: 24
    },
    select: {
      flex: "3 1",
      display: "flex",
      minWidth: 516,
      boxSizing: "border-box",
      textAlign: "center",
      flexDirection: "column",
      justifyContent: "center",
      borderRadius: 2,
      boxShadow:
        "0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)",
      backgroundColor: "#ffffff",
      paddingTop: 57,
      paddingBottom: 64
    },
    selectMainText: {
      fontSize: 30,
      color: "#333333",
      "& + $selectMainText": {
        marginTop: 9
      }
    },
    footerImgWrapper: {
      display: "none",
      "@media only screen and (max-width: 875px)": {
        display: "block"
      }
    },
    footerImg: {
      width: "345px",
      margin: "auto",
      display: "block"
    },
    timeWrapper: {
      marginTop: 28,
      minHeight: 20,
      fontSize: 20,
      fontWeight: "bold"
    },
    inTime: {
      lineHeight: 1,
      color: "#50aab4"
    },
    outTime: {
      marginTop: 16,
      marginBottom: 64,
      lineHeight: 1,
      color: "#f5cd00"
    },
    prevUseWrapper: {
      marginTop: 9
    },
    prevUse: {
      color: "rgba(0, 0, 0, 0.54)",
      fontSize: 14
    },
    colorGray: {
      color: "rgba(0, 0, 0, 0.54)"
    }
  });

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  onInTime: (id: number, name: string, status: number) => Promise<void>;
  onOutTime: (id: number, name: string, status: number) => Promise<void>;
  targetAttendance?: Attendance;
  punchInButtonLabel: string;
  punchOutButtonLabel: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AttendanceModalCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const getPreviousInTimeLabel = (): string => {
    if (!props.targetAttendance || !props.targetAttendance.previousInTime) {
      return "--:--";
    }
    return dateToLocalisedString(
      props.targetAttendance.previousInTime,
      "YYYY年MM月DD日 HH:mm"
    );
  };

  const getStatusLabel = (): string => {
    const result = SERVICE_STATUS_202104.find((target) => {
      if (!props.targetAttendance) return false;
      return target.value === props.targetAttendance.status;
    });
    return result ? result.label : "";
  };

  const getPreviousStatusLabel = (): string => {
    const result = SERVICE_STATUS_202104.find((target) => {
      if (!props.targetAttendance) return false;
      return target.value === props.targetAttendance.previousStatus;
    });
    return result ? result.label : "なし";
  };

  const isPreviousStatusView = (): boolean => {
    const result = SERVICE_STATUS_202104.find((target) => {
      if (!props.targetAttendance) return false;
      return target.value === props.targetAttendance.previousStatus;
    });
    return result ? result.isView : false;
  };

  const isNotInOut = (): boolean => {
    const result = SERVICE_STATUS_202104.find((target) => {
      if (!props.targetAttendance || !props.targetAttendance.status) {
        return false;
      }

      return target.value === props.targetAttendance.status;
    });
    return result ? result.notInOut : false;
  };

  const onClose = (): void => {
    props.onClose();
  };

  const onInTime = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (!props.targetAttendance) return;
    props.onInTime(
      props.targetAttendance.uif_id,
      props.targetAttendance.displayName,
      +e.currentTarget.value
    );
  };

  const onOutTime = (): void => {
    if (!props.targetAttendance) return;
    props.onOutTime(
      props.targetAttendance.uif_id,
      props.targetAttendance.displayName,
      props.targetAttendance.status || 2
    );
  };

  // 文字数が多いステータスが存在する場合、ボタンの幅を大きくする
  const isLong =
    props.targetAttendance &&
    props.targetAttendance.defaultStatus &&
    (props.targetAttendance.defaultStatus.includes(
      SERVICE_STATUS_202104[7].value
    ) ||
      props.targetAttendance.defaultStatus.includes(
        SERVICE_STATUS_202104[8].value
      ));

  const buttonInfo = [
    {
      value: SERVICE_STATUS_202104[1].value,
      class: classes.primary,
      label: props.punchInButtonLabel || "通所する"
    },
    {
      value: SERVICE_STATUS_202104[3].value,
      class: classes.third,
      label: SERVICE_STATUS_202104[3].label
    },
    {
      value: SERVICE_STATUS_202104[2].value,
      class: classes.secondary,
      label: SERVICE_STATUS_202104[2].label
    },
    {
      value: SERVICE_STATUS_202104[7].value,
      class: classes.third,
      label: SERVICE_STATUS_202104[7].label
    },
    {
      value: SERVICE_STATUS_202104[8].value,
      class: classes.secondary,
      label: SERVICE_STATUS_202104[8].label
    }
  ];

  // 開始時のボタン表示Component
  const StartButtonList = (): JSX.Element | null => {
    if (!props.targetAttendance) return null;

    // IABのみボタンが複数個表示になる可能性がある
    return props.targetAttendance.attendanceStatus === "before" &&
      props.targetAttendance.defaultStatus &&
      props.targetAttendance.defaultStatus.length > 0 ? (
      <div className={classes.buttonWrapper}>
        {buttonInfo.map((item) => {
          return props.targetAttendance &&
            props.targetAttendance.defaultStatus &&
            props.targetAttendance.defaultStatus.includes(item.value) ? (
            <Button
              key={item.value}
              variant="contained"
              onClick={onInTime}
              color="secondary"
              value={item.value}
              classes={{
                root: classNames(
                  classes.workingButton,
                  item.class,
                  isLong && classes.long
                ),
                label: classNames(
                  classes.attendanceButtonLabel,
                  item.value === SERVICE_STATUS_202104[7].value
                    ? classes.semiSpace
                    : "",
                  item.value === SERVICE_STATUS_202104[8].value
                    ? classes.space
                    : ""
                )
              }}
            >
              {item.label}
            </Button>
          ) : null;
        })}
      </div>
    ) : (
      <>
        {props.targetAttendance.attendanceStatus === "before" && !isNotInOut() && (
          <div className={classes.timeWrapper}>
            <Button
              variant="contained"
              onClick={onInTime}
              color="secondary"
              value={SERVICE_STATUS_202104[1].value}
              classes={{
                root: classNames(classes.workingButton, classes.primary),
                label: classes.attendanceButtonLabel
              }}
            >
              {props.punchInButtonLabel || "通所する"}
            </Button>
          </div>
        )}
      </>
    );
  };

  return (
    <div
      className={classes.loot}
      style={
        props.isOpen
          ? { transform: "translateY(-100vh)" }
          : { transform: "translateY(0vh)" }
      }
    >
      <div className={classes.contentWrapper}>
        <div className={classes.header}>
          <Button
            variant="contained"
            onClick={onClose}
            color="secondary"
            classes={{
              root: classes.closeButton,
              label: classes.closeButtonLabel
            }}
          >
            閉じる
          </Button>
        </div>
        {props.isOpen && props.targetAttendance ? (
          <>
            <div className={classes.content}>
              <AttendanceTime
                attendanceStatus={props.targetAttendance.attendanceStatus}
              />
              <div className={classes.select}>
                <div className={classes.selectMainText}>
                  {props.targetAttendance.displayName}
                  さん
                </div>
                <div className={classes.selectMainText}>
                  {props.targetAttendance.attendanceStatus === "before"
                    ? "おはようございます"
                    : "お疲れ様でした"}
                </div>
                {isPreviousStatusView() && (
                  <div className={classes.prevUseWrapper}>
                    <div className={classes.prevUse}>
                      {`前回の利用：${getPreviousStatusLabel()}`}
                    </div>
                    {(props.targetAttendance.previousInTime ||
                      props.targetAttendance.previousOutTime) && (
                      <div className={classes.prevUse}>
                        {getPreviousInTimeLabel()}
                        {" 〜 "}
                        {props.targetAttendance.previousOutTime || "--:--"}
                      </div>
                    )}
                  </div>
                )}
                <div className={classes.timeWrapper}>
                  {props.targetAttendance.inTime && (
                    <div className={classes.inTime}>
                      {`本日の開始時間 ${props.targetAttendance.inTime}`}
                    </div>
                  )}
                  {props.targetAttendance.outTime && (
                    <div className={classes.outTime}>
                      {`本日の終了時間 ${props.targetAttendance.outTime}`}
                    </div>
                  )}
                </div>
                {isNotInOut() && (
                  <div className={classes.colorGray}>
                    {`本日は${getStatusLabel()}です`}
                  </div>
                )}
                <StartButtonList />
                {props.targetAttendance.attendanceStatus === "working" &&
                  !isNotInOut() && (
                    <Button
                      variant="contained"
                      onClick={onOutTime}
                      color="secondary"
                      classes={{
                        root: classes.afterButton,
                        label: classes.attendanceButtonLabel
                      }}
                    >
                      {props.punchOutButtonLabel || "終了する"}
                    </Button>
                  )}
              </div>
            </div>
            <div className={classes.footerImgWrapper}>
              <img
                src={
                  props.targetAttendance.attendanceStatus === "before"
                    ? attendanceInScreen
                    : attendanceOutScreen
                }
                className={classes.footerImg}
                alt=""
              />
            </div>
          </>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};

export const AttendanceModal = withStyles(styles)(AttendanceModalCore);
