import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { WorkHistory } from "@initialize/record/assessment/initialValues";
import { RecordSelectMonth } from "../../common/record/RecordSelectMonth";
import RecordTextField from "../../common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      border: "1px solid #bdbdbd",
      padding: "24px 32px",
      borderRadius: "4px",
      marginTop: "8px"
    },
    categoryGroupTitle: {
      marginTop: 0,
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 14
    },
    item: {
      display: "flex"
    },
    itemName: {
      marginTop: 32,
      fontSize: 12,
      color: "#37474f"
    },
    deleteButton: {
      marginTop: 24
    }
  });

interface OwnProps {
  titleIndex: number;
  index: number;
  name: string;
  isEditable: boolean;
  value: WorkHistory; // 表示用、編集には使わない
  onClickDeleteWorkHistory: (index: number) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * アセスメントシートで使う専用の表組みUI
 * 表示と編集モードを切り替える
 */
const RecordWorkHistoryCore = ({ ...props }: Props): JSX.Element => {
  const {
    titleIndex,
    index,
    name,
    isEditable,
    value,
    classes,
    onClickDeleteWorkHistory
  } = props;
  const onClickDelete = (): void => {
    onClickDeleteWorkHistory(index);
  };
  return (
    <div className={classes.wrapper}>
      <p className={classes.categoryGroupTitle}>{`就労歴・訓練歴等${
        titleIndex + 1
      }`}</p>
      <div className={classes.item}>
        <RecordSelectMonth
          name={`${name}[${index}].begin_date`}
          label="開始年月"
          value={value.begin_date}
          isEditable={isEditable}
          addYearTo={1}
          overrideYearFrom={1937}
        />
        <RecordSelectMonth
          name={`${name}[${index}].end_date`}
          label="終了年月"
          value={value.end_date}
          isEditable={isEditable}
          addYearTo={1}
          overrideYearFrom={1937}
        />
      </div>
      <div className={classes.itemName}>企業名・施設名</div>
      <RecordTextField
        name={`${name}[${index}].facility_name`}
        labelType="default"
        defaultValue=""
        placeholder=""
        value={value.facility_name}
        isEditable={isEditable}
        style={{ marginBottom: 0, width: "100%" }}
      />
      <div className={classes.itemName}>詳細</div>
      <RecordTextField
        name={`${name}[${index}].details`}
        labelType="default"
        defaultValue=""
        placeholder=""
        value={value.details}
        isEditable={isEditable}
        style={{ marginBottom: 0, width: "100%" }}
      />
      {isEditable && (
        <KnowbeButton
          className={classes.deleteButton}
          onClick={onClickDelete}
          kind="iconText"
        >
          <DeleteOutlinedIcon color="secondary" />
          削除する
        </KnowbeButton>
      )}
    </div>
  );
};

export const RecordWorkHistory = withStyles(styles)(RecordWorkHistoryCore);
