import * as React from "react";
import { FormikProps, FieldArray } from "formik";
// ui
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import { UnitsTable } from "@componentsMobile/organisms/record/serviceDelivery/units/UnitsTable";
import { FieldItem } from "@interfaces/ui/form";
// formik
import {
  ServiceDeliveryDetailValues,
  practitionerDetailsInitialValue
} from "@initialize/record/serviceDelivery/initialValues";

import { FacilityType } from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

type OwnProps = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  unitKey: string;
  onDeleteRecord: (index: number) => void;
  onChangeTime: (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ) => void;
  onChangeSelect: (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ) => void;
  onChangeActionClass: (index: number, actionClass: number) => void;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  isAddButton: boolean;
  list: {
    serviceDeliveryRecordPractitionerDetailsId: number | null;
    actionClass: number;
    startTime: string;
    endTime: string;
    isDelete: number;
  }[];
  practitionerNum: number;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  actionClassLabel: string;
  actionClassOptions: FieldItem[];
  facilityType: FacilityType;
};

type Props = OwnProps;

export const UnitsFields = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  return (
    <FieldArray
      name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails`}
    >
      {(arrayHelpers): JSX.Element => {
        const onClickAddUnit = (): void => {
          arrayHelpers.push(practitionerDetailsInitialValue);
        };
        const onClickDelete = (index: number): void => {
          props.setFormikFieldValue(
            `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${index}].isDelete`,
            1
          );
          props.onDeleteRecord(index);
        };
        return (
          <>
            {(props.isEdit || props.list.length > 0) && (
              <div
                style={{
                  color: "rgba(0, 0, 0, 0.6)",
                  margin: "24px 0",
                  fontSize: 14
                }}
              >
                {props.facilityType === FacilityType.KODOENGO ||
                (props.facilityType === FacilityType.IDOSHIEN &&
                  formikValues.status ===
                    IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value)
                  ? "空き時間"
                  : `${props.actionClassLabel}時間または空き時間`}
              </div>
            )}
            {props.list.length > 0 && (
              <UnitsTable
                isEdit={props.isEdit}
                formikProps={props.formikProps}
                unitKey={props.unitKey}
                onClickDelete={onClickDelete}
                onChangeTime={props.onChangeTime}
                onChangeSelect={props.onChangeSelect}
                onChangeActionClass={props.onChangeActionClass}
                setFormikFieldValue={props.setFormikFieldValue}
                practitionerNum={props.practitionerNum}
                actionClassLabel={props.actionClassLabel}
                actionClassOptions={props.actionClassOptions}
                facilityType={props.facilityType}
              />
            )}
            {props.isEdit && (
              <Button
                color="secondary"
                onClick={onClickAddUnit}
                style={{ paddingLeft: 16 }}
                disabled={!props.isAddButton}
              >
                <IconAdd style={{ marginRight: 7 }} />
                追加する
              </Button>
            )}
          </>
        );
      }}
    </FieldArray>
  );
};
