import * as React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { FacilityForm } from "@components/organisms/mgr/IDOSHIEN/facility/FacilityForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

/**
 * 事業所情報
 */
export const Facility = (): JSX.Element => (
  <AdminTemplate pageName="事業所情報">
    <FacilityForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
