import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import MuiTextField from "@components/molecules/MuiTextField";
import { UnitsFields } from "@components/organisms/record/serviceDelivery/units/UnitsField";
import {
  DAY_SELECT_OPTIONS,
  DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER,
  FacilityType,
  TIME_CLASS_LIST
} from "@constants/variables";
import { FormGroup } from "@material-ui/core";
import React from "react";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import { FormikProps } from "formik";
import { useServiceDeliveryTimeContainer } from "@hooks/record/serviceDelivery/useServiceDeliveryTimeContainer";
import {
  IDOSHIEN_STATUS_LIST,
  MAX_PRACTITIONER_DETAILS
} from "@constants/mgr/IDOSHIEN/variables";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  serviceDeliveryRecordPractitioners1: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  facilityType: FacilityType;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  isEdit: boolean;
};

type StateProps = {
  municipality: MunicipalitiesInFacilityState;
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchMunicipality: (id: string) => void;
  fetchUserInFacility: (uifId: string) => void;
};

type MergeProps = OwnProps & DispatchProps & StateProps;

const ServiceDeliveryOnlyTimeContainerCore = ({
  formikProps,
  serviceDeliveryRecordPractitioners1,
  facilityType,
  setFormikFieldValue,
  fetchMunicipality,
  fetchUserInFacility,
  municipality,
  userInFacility
}: MergeProps): JSX.Element => {
  const { values } = formikProps;
  const fieldName = "serviceDeliveryRecordPractitioners1";
  const detailsFieldName =
    "serviceDeliveryRecordPractitioners1.serviceDeliveryRecordPractitionerDetails";
  const {
    onChangeDateSelect,
    onChangeInitialTime,
    onChangeActionClass,
    onChangeDetailDateSelect,
    onChangeDetailRecord,
    onDeleteRecord
  } = useServiceDeliveryTimeContainer(
    fieldName,
    detailsFieldName,
    setFormikFieldValue,
    facilityType,
    serviceDeliveryRecordPractitioners1,
    formikProps,
    fetchMunicipality,
    fetchUserInFacility,
    municipality,
    userInFacility
  );

  const isPractitionerDetailsLessThan10 =
    serviceDeliveryRecordPractitioners1.serviceDeliveryRecordPractitionerDetails.filter(
      (item) => !item.isDelete
    ).length < MAX_PRACTITIONER_DETAILS;

  const isVehicleTransportStatus =
    values.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value;

  return (
    <>
      <FormGroup row>
        <MuiTextField
          value={TIME_CLASS_LIST[0].label}
          label="開始時間"
          disabled
          disabledStyle
          style={{ width: 80 }}
        />
        <FormikTime
          name="serviceDeliveryRecordPractitioners1.startTime"
          label=""
          placeholder="00:00"
          size="smallMedium"
          maxLength={5}
          onChangeHookTime={onChangeInitialTime}
          style={{ marginTop: 16, marginRight: 24 }}
        />
        <FormikSelect
          name="serviceDeliveryRecordPractitioners1.endTimeDate"
          label="終了時間"
          options={DAY_SELECT_OPTIONS}
          style={{ width: 80 }}
          onChangeHook={(e): void => onChangeDateSelect(e)}
        />
        <FormikTime
          name="serviceDeliveryRecordPractitioners1.endTime"
          placeholder="00:00"
          size="smallMedium"
          maxLength={5}
          onChangeHookTime={onChangeInitialTime}
          style={{ marginTop: 16, marginRight: 24 }}
        />
        {/* ２人同時に提供するときの時間入力 */}
        {values.licenseSameFlg && !isVehicleTransportStatus && (
          <MuiTextField
            value={serviceDeliveryRecordPractitioners1.numberOfTime}
            label="時間"
            size="smallMedium"
            disabled
            disabledStyle
          />
        )}
        {/* 車両移送支援のときのの時間入力 */}
        {isVehicleTransportStatus && (
          <FormGroup row>
            <MuiTextField
              value={serviceDeliveryRecordPractitioners1.numberOfServiceHours}
              label="時間"
              size="smallMedium"
              disabled
              disabledStyle
              endAdornmentLabel="時間"
            />
            <MuiTextField
              value={serviceDeliveryRecordPractitioners1.numberOfServiceMinutes}
              size="smallMedium"
              disabled
              disabledStyle
              endAdornmentLabel="分"
              style={{ marginTop: "16px" }}
            />
          </FormGroup>
        )}
      </FormGroup>
      <div>
        <UnitsFields
          formikProps={formikProps}
          unitKey="serviceDeliveryRecordPractitioners1"
          onDeleteRecord={onDeleteRecord}
          onChangeTime={onChangeDetailRecord}
          onChangeSelect={onChangeDetailDateSelect}
          onChangeActionClass={onChangeActionClass}
          setFormikFieldValue={formikProps.setFieldValue}
          isAddButton={isPractitionerDetailsLessThan10}
          list={
            serviceDeliveryRecordPractitioners1.serviceDeliveryRecordPractitionerDetails
          }
          practitionerNum={serviceDeliveryRecordPractitioners1.practitionerNum}
          practitionerValues={serviceDeliveryRecordPractitioners1}
          actionClassLabel={DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER[0].label}
          actionClassOptions={DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER}
          facilityType={facilityType}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  municipality: state.IDOSHIEN.municipalitiesInFacility,
  userInFacility: state.IDOSHIEN.userInFacility
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN } = dispatches;
  const municipalitiesInFacilityDispatches = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatches = IDOSHIEN.userInFacilityDispatcher(dispatch);
  return {
    fetchMunicipality: municipalitiesInFacilityDispatches.fetchOne,
    fetchUserInFacility: userInFacilityDispatches.fetchOne
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryOnlyTimeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ServiceDeliveryOnlyTimeContainerCore);
