import { OptionInterface } from "@components/atoms/DropDown";

/**
 * ドロップダウンで使用する形式に整形する
 */
export function generateDropDownOptions(data: {
  label: string;
  value: string | number;
}): OptionInterface {
  return {
    label: data.label,
    value: data.value
  };
}
