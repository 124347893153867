import { BasicValues } from "@interfaces/mgr/SHISETSUNYUSHO/facility/basic";
import { SubtractionItemValues } from "@interfaces/mgr/SHISETSUNYUSHO/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/mgr/SHISETSUNYUSHO/facility/additionItem";
import { FacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import { UnitsValues } from "@interfaces/mgr/SHISETSUNYUSHO/facility/units";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import { initializeSpecificBetterSupporterCondition202404V } from "@utils/domain/facility/initializeSpecificBetterSupporterCondition202404V";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues &
  UnitsValues;

// ユニットの初期値
export const unitInitialValue = {
  id: null,
  unit_name: "",
  night_support_type: "1",
  ave_users_last_fiscal_year: "0",
  is_deleted: false
};

const initialValues = (state?: FacilityState): FacilityValues => {
  // ユニットは複数 ≒ 必ず2件以上
  const unitsValue =
    state && state.units.length > 0
      ? state.units
      : [unitInitialValue, unitInitialValue];

  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      isMasterRadioValue: state ? state.isMasterRadioValue : "1",
      multiFunctionOfficeFlg: state ? state.multiFunctionOfficeFlg : false,
      operatingUnitFlag: state ? state.operatingUnitFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : DEFAULT_SELECT_VALUE,
      cityId: state ? state.selectedCityCode : DEFAULT_SELECT_VALUE,
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      availableFood: state ? state.availableFood : false,
      foodExpenses: state ? `${state.foodExpenses}` : "0",
      foodExpensesBreakfast: state ? `${state.foodExpensesBreakfast}` : "",
      foodExpensesLunch: state ? `${state.foodExpensesLunch}` : "",
      foodExpensesSupper: state ? `${state.foodExpensesSupper}` : "",
      foodExpensesDay: state ? `${state.foodExpensesDay}` : "",
      utility: state ? `${state.utility}` : "0",
      utilityCosts: state ? `${state.utilityCosts}` : ""
    },
    subtractionItem: {
      originLocalGovFlg: state ? state.originLocalGovFlg : false,
      nutritionistPlacement: state ? `${state.nutritionistPlacement}` : "1",
      lackOfSupporterFlg: state ? state.lackOfSupporterFlg : false,
      dateStartLackOfSupporter:
        state && state.dateStartLackOfSupporter
          ? dateToSelectDateValueYYYYM(state.dateStartLackOfSupporter)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" },
      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      ),
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      bodyRestrictedStillFlg: state ? state.bodyRestrictedStillFlg : false,
      bodyRestrictedStillFrom: initializeSelectDate(
        state && state.bodyRestrictedStillFrom
      ),
      bodyRestrictedStillTo: initializeSelectDate(
        state && state.bodyRestrictedStillTo
      ),
      workContinuationNotPlanningFlg: state
        ? state.workContinuationNotPlanningFlg
        : false,
      workContinuationNotPlanningFrom: initializeSelectDate(
        state && state.workContinuationNotPlanningFrom
      ),
      workContinuationNotPlanningTo: initializeSelectDate(
        state && state.workContinuationNotPlanningTo
      )
    },
    additionalItem: {
      specificBetterSupporterCondition202404: state
        ? initializeSpecificBetterSupporterCondition202404V(
            state.specificBetterSupporterCondition202404
          )
        : NO_SELECTED_CONDITION_TYPE_2024,
      specificBetterSupporterCondition202404Sub:
        state && state.specificBetterSupporterCondition202404
          ? state.specificBetterSupporterCondition202404
          : NO_SELECTED_CONDITION_TYPE_2024_SUB,
      nighttimePlacement: state ? `${state.nighttimePlacement}` : "0",
      nighttimeNurseCount:
        state && state.nighttimeNurseCount !== ""
          ? state.nighttimeNurseCount
          : "1",
      seeHearTeam202404: state ? state.seeHearTeam202404 : "1",
      seeHearTeam202404From: initializeSelectDate(
        state && state.seeHearTeam202404From
      ),
      seeHearTeam202404To: initializeSelectDate(
        state && state.seeHearTeam202404To
      ),
      regionalLifeTransition: state ? state.regionalLifeTransition : false,
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      seriousDisability: state ? `${state.seriousDisability}` : "0",
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false,
      oralHygieneManagementSystemFlg: state
        ? `${state.oralHygieneManagementSystemFlg}`
        : "0",
      higherBrainDysfunctionPersonsSupportFlg: state
        ? state.higherBrainDysfunctionPersonsSupportFlg
        : false,
      higherBrainDysfunctionPersonsSupportFrom: initializeSelectDate(
        state && state.higherBrainDysfunctionPersonsSupportFrom
      ),
      higherBrainDysfunctionPersonsSupportTo: initializeSelectDate(
        state && state.higherBrainDysfunctionPersonsSupportTo
      ),
      improvingInfectionControl1Flg: state
        ? state.improvingInfectionControl1Flg
        : false,
      improvingInfectionControl1From: initializeSelectDate(
        state && state.improvingInfectionControl1From
      ),
      improvingInfectionControl1To: initializeSelectDate(
        state && state.improvingInfectionControl1To
      ),
      improvingInfectionControl2Flg: state
        ? state.improvingInfectionControl2Flg
        : false,
      improvingInfectionControl2From: initializeSelectDate(
        state && state.improvingInfectionControl2From
      ),
      improvingInfectionControl2To: initializeSelectDate(
        state && state.improvingInfectionControl2To
      ),
      regionalTransitionSupportFlg: state
        ? state.regionalTransitionSupportFlg
        : false,
      decreaseUsersCount: state ? state.decreaseUsersCount : "",
      regionalTransitionSupportFrom: initializeSelectDate(
        state && state.regionalTransitionSupportFrom
      ),
      regionalTransitionSupportTo: initializeSelectDate(
        state && state.regionalTransitionSupportTo
      )
    },
    units: unitsValue
  };
};

export default initialValues;
