import React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { UsageResultCell } from "@components/organisms/mgr/SHUROTEICHAKU/report/cell/UsageResultCell";
import { UsageResult } from "@stores/domain/mgr/SHUROTEICHAKU/report/types";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { UsageResultEditCell } from "@components/organisms/mgr/SHUROTEICHAKU/report/cell/UsageResultEditCell";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { INT_TRUE_FROM_API } from "@constants/variables";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { FormikProps } from "formik";
import { InitialDataValues } from "@interfaces/mgr/SHUROTEICHAKU/report/initialData";

const styles = (): StyleRules =>
  createStyles({
    dateCell: {
      boxSizing: "border-box",
      width: 142,
      minWidth: 142,
      maxWidth: 142,
      padding: "16px 8px 16px 16px"
    }
  });

type StateProps = {
  userInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  params: UsageResult;
  formikProps: FormikProps<InitialDataValues>;
  idx: number;
  isEditing: boolean;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const UsageResultListCellCore = (props: Props): JSX.Element => {
  const { params, formikProps, idx, isEditing, userInFacilityState } = props;

  const isRegionalCooperationSupportFlg = userInFacilityState.user
    .user_in_facility_shuroteichaku
    ? userInFacilityState.user.user_in_facility_shuroteichaku
        .regional_cooperation_support_flg === INT_TRUE_FROM_API
    : false;

  return (
    <>
      <TableCellWrap
        key={`${idx}-target-date`}
        cellClass={props.classes.dateCell}
      >
        <CustomDateLabel
          date={params.targetDate ? params.targetDate : ""}
          dateFormat="Do（dd）"
          holiday={params.isHoliday ? params.isHoliday : false}
        />
      </TableCellWrap>
      {isEditing ? (
        <UsageResultEditCell
          formikProps={formikProps}
          idx={idx}
          isRegionalCooperationSupportFlg={isRegionalCooperationSupportFlg}
        />
      ) : (
        <UsageResultCell
          isRegionalCooperationSupportFlg={isRegionalCooperationSupportFlg}
          params={params}
          idx={idx}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userInFacilityState: state.SHUROTEICHAKU.userInFacility
  };
};

export const UsageResultListCell = connect(mapStateToProps)(
  withStyles(styles)(UsageResultListCellCore)
);
