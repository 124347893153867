import * as types from "./types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};
// 取得
export const fetchInquiryStarted = () =>
  ({ type: types.FETCH_INQUIRY_STARTED } as const);
export const fetchInquirySuccess = (res: types.InquiryState) =>
  ({ type: types.FETCH_INQUIRY_SUCCESS, payload: res } as const);
export const fetchInquiryFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_INQUIRY_FAILED, error: err } as const);
// 更新
export const postInquiryStarted = () =>
  ({ type: types.POST_INQUIRY_STARTED } as const);
export const postInquirySuccess = () =>
  ({
    type: types.POST_INQUIRY_SUCCESS
  } as const);
export const postInquiryFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_INQUIRY_FAILED,
    error: err
  } as const);

// 削除
export const deleteInquiryStarted = () =>
  ({ type: types.DELETE_INQUIRY_STARTED } as const);
export const deleteInquirySuccess = () =>
  ({ type: types.DELETE_INQUIRY_SUCCESS } as const);
export const deleteInquiryFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_INQUIRY_FAILED, error: err } as const);

// 初期化
export const unsetInquiry = () => ({ type: types.UNSET_INQUIRY } as const);

export type ActionTypes =
  | ReturnType<typeof fetchInquiryStarted>
  | ReturnType<typeof fetchInquirySuccess>
  | ReturnType<typeof fetchInquiryFailed>
  | ReturnType<typeof postInquiryStarted>
  | ReturnType<typeof postInquirySuccess>
  | ReturnType<typeof postInquiryFailed>
  | ReturnType<typeof deleteInquiryStarted>
  | ReturnType<typeof deleteInquirySuccess>
  | ReturnType<typeof deleteInquiryFailed>
  | ReturnType<typeof unsetInquiry>;
