import { BasicValues } from "@interfaces/mgr/SEIKATSUKAIGO/Users/basic";
import { ServiceUseValues } from "@interfaces/mgr/SEIKATSUKAIGO/Users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/mgr/SEIKATSUKAIGO/Users/recipientCertificate";
import { UsersInFacilityState } from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import {
  DEFAULT_SELECT_VALUE,
  Checkbox,
  DISABILITY_CLASS_LIST
} from "@constants/variables";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import castStringOrUndefined from "@utils/dataNormalizer/castStringOrUndefined";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues;

// FlgがCheckbox.ONの場合はtrueを返す
const determineTureOrFalse = (value: Checkbox | undefined): boolean => {
  if (value === Checkbox.ON) {
    return true;
  }
  return false;
};

const initialValues = (state?: UsersInFacilityState["user"]): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const SEIKATSUKAIGO =
    state && state.user_in_facility_seikatsukaigo
      ? state.user_in_facility_seikatsukaigo
      : {};
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTureOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTureOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTureOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTureOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTureOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTureOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTureOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTureOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianName: undefinedToReturnValue(userInFacility.guardian_name),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      sameCorporationMovementFlg: determineTureOrFalse(
        userInFacility.same_corporation_movement_flg
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      disabilityClass: undefinedToReturnValue(
        SEIKATSUKAIGO.disability_class,
        0
      ),
      agreedByContractFlg: undefinedToReturnValue(
        userInFacility.agreed_by_contract_flg,
        1
      ),
      severeDisabilitySupport: SEIKATSUKAIGO.severe_disability_support === 1,
      severeDisabilitySupportStartData: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(
            SEIKATSUKAIGO.severe_disability_support_start_date
          )
        )
      ),
      rehabilitation: undefinedToReturnValue(SEIKATSUKAIGO.rehabilitation, 0),
      rehabilitationStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(SEIKATSUKAIGO.rehabilitation_start_date)
        )
      ),
      incomeKind: undefinedToReturnValue(userInFacility.income_kind, 1),
      incomeKindType: undefinedToReturnValue(SEIKATSUKAIGO.income_kind, 0),
      subsidizedFlag: determineTureOrFalse(userInFacility.subsidized_flg),
      subsidizedPercent: undefinedToReturnValue(
        userInFacility.subsidized_percent
      ),
      subsidizedYen: undefinedToReturnValue(userInFacility.subsidized_yen),
      subsidizedUnit: undefinedToReturnValue(
        userInFacility.subsidized_unit_flg,
        1
      ),
      subsidizedCityId: userInFacility.subsidized_city_id
        ? `${userInFacility.subsidized_city_id}`
        : DEFAULT_SELECT_VALUE,
      upperLimitFacilityFlag: determineTureOrFalse(
        userInFacility.uplimit_facility_flg
      ),
      upperLimitControlledBy: undefinedToReturnValue(
        userInFacility.uplimit_controlled_by,
        1
      ),
      upperLimitFacilityNumber: undefinedToReturnValue(
        userInFacility.uplimit_facility_number
      ),
      upperLimitFacilityNumber2: undefinedToReturnValue(
        userInFacility.uplimit_facility_number2
      ),
      upperLimitFacilityNumber3: undefinedToReturnValue(
        userInFacility.uplimit_facility_number3
      ),
      upperLimitFacilityNumber4: undefinedToReturnValue(
        userInFacility.uplimit_facility_number4
      ),
      upperLimitFacilityName: undefinedToReturnValue(
        userInFacility.uplimit_facility_name
      ),
      upperLimitFacilityName2: undefinedToReturnValue(
        userInFacility.uplimit_facility_name2
      ),
      upperLimitFacilityName3: undefinedToReturnValue(
        userInFacility.uplimit_facility_name3
      ),
      upperLimitFacilityName4: undefinedToReturnValue(
        userInFacility.uplimit_facility_name4
      ),
      upperLimitTotalYen: undefinedToReturnValue(
        userInFacility.uplimit_total_yen
      ),
      upperLimitTotalYen2: undefinedToReturnValue(
        userInFacility.uplimit_total_yen2
      ),
      upperLimitTotalYen3: undefinedToReturnValue(
        userInFacility.uplimit_total_yen3
      ),
      upperLimitTotalYen4: undefinedToReturnValue(
        userInFacility.uplimit_total_yen4
      ),
      upperLimitUserLoadYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen
      ),
      upperLimitUserLoadYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen2
      ),
      upperLimitUserLoadYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen3
      ),
      upperLimitUserLoadYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      resultOfManagement: undefinedToReturnValue(
        userInFacility.result_of_management,
        1
      ),
      upperLimitYen: undefinedToReturnValue(userInFacility.uplimit_yen),
      createSupportPlanFlag:
        userInFacility.create_support_plan_flg === Checkbox.OFF || false,
      notCreateSupportPlanStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(
            userInFacility.date_start_not_create_support_plan
          )
        )
      ),
      /**
       * ４月スコープアウトのためコメントアウト
       * 実装時チケット https://gogopmpr.backlog.jp/view/KNOWBE_DEV-2072
       */
      // intensiveSupportFlg:
      //   userInFacility.intensive_support_flg === Checkbox.ON || false,
      // intensiveSupportStartDate: emptyToNotSelected(
      //   dateToSelectDateValue(
      //     undefinedToReturnValue(userInFacility.intensive_support_start_date)
      //   )
      // ),
      defFood: undefinedToReturnValue(userInFacility.def_food, 1),
      defPickup: undefinedToReturnValue(userInFacility.def_pickup, 0),
      pickupPremises: undefinedToReturnValue(userInFacility.pickup_premises, 0),
      payDaysAgreed: undefinedToReturnValue(userInFacility.pay_days_agreed),
      businessNumberContract: undefinedToReturnValue(
        userInFacility.business_number_contract
      ),
      monScheduledFlg: determineTureOrFalse(userInFacility.mon_scheduled_flg),
      tueScheduledFlg: determineTureOrFalse(userInFacility.tue_scheduled_flg),
      wedScheduledFlg: determineTureOrFalse(userInFacility.wed_scheduled_flg),
      thuScheduledFlg: determineTureOrFalse(userInFacility.thu_scheduled_flg),
      friScheduledFlg: determineTureOrFalse(userInFacility.fri_scheduled_flg),
      satScheduledFlg: determineTureOrFalse(userInFacility.sat_scheduled_flg),
      sunScheduledFlg: determineTureOrFalse(userInFacility.sun_scheduled_flg),
      monCalculatedHours: determineTureOrFalse(userInFacility.mon_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.mon_calculated_hours)
        : "",
      tueCalculatedHours: determineTureOrFalse(userInFacility.tue_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.tue_calculated_hours)
        : "",
      wedCalculatedHours: determineTureOrFalse(userInFacility.wed_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.wed_calculated_hours)
        : "",
      thuCalculatedHours: determineTureOrFalse(userInFacility.thu_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.thu_calculated_hours)
        : "",
      friCalculatedHours: determineTureOrFalse(userInFacility.fri_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.fri_calculated_hours)
        : "",
      satCalculatedHours: determineTureOrFalse(userInFacility.sat_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.sat_calculated_hours)
        : "",
      sunCalculatedHours: determineTureOrFalse(userInFacility.sun_scheduled_flg)
        ? undefinedToReturnValue(SEIKATSUKAIGO.sun_calculated_hours)
        : "",
      midMonthChangeFlg: !!SEIKATSUKAIGO.mid_month_change_flg,
      midMonthChangeDisabilityClass:
        castStringOrUndefined(
          SEIKATSUKAIGO.mid_month_change_disability_class
        ) || DISABILITY_CLASS_LIST[0].value,
      midMonthChangeDate: dateToSelectDateValue(
        SEIKATSUKAIGO.mid_month_change_date || ""
      ),
      dayUseSpecialCaseFlag: determineTrueOrFalse(
        userInFacility.day_use_special_case_flg
      ),
      dayUseSpecialCaseStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.day_use_special_case_from)
        )
      ),
      dayUseSpecialCaseEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.day_use_special_case_to)
        )
      )
    },

    recipientCertificate: {
      userChargeLimitFlag: determineTureOrFalse(
        userInFacility.user_charge_limit_flg
      ),
      userChargeLimitStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_user_charge_limit)
        )
      ),
      userChargeLimitEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_user_charge_limit)
        )
      ),
      foodServeAdditionFlg: determineTureOrFalse(
        userInFacility.food_serve_addition_flg
      ),
      foodServeAdditionStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_food_serve_addition)
        )
      ),
      foodServeAdditionEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_food_serve_addition)
        )
      ),
      careSupportAuthFlag: determineTureOrFalse(
        userInFacility.care_support_auth_flg
      ),
      careSupportAuthStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_auth)
        )
      ),
      careSupportAuthEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_auth)
        )
      ),
      careSupportPaymentFlag: determineTureOrFalse(
        userInFacility.care_support_payment_flg
      ),
      careSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_payment)
        )
      ),
      careSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_payment)
        )
      ),
      planSupportPaymentFlag: determineTureOrFalse(
        userInFacility.plan_support_payment_flg
      ),
      planSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_payment)
        )
      ),
      planSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_payment)
        )
      ),
      planSupportMonitorFlag: determineTureOrFalse(
        userInFacility.plan_support_monitor_flg
      ),
      planSupportMonitorStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_monitor)
        )
      ),
      planSupportMonitorEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_monitor)
        )
      )
    }
  };
};

export default initialValues;
