import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { monitoringMonthApi } from "@api/requests/monitoringMonth";
import { InitialMonitoringMonthValues } from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";
import { normalizePostMonitoringMonthDataToAPI } from "./normalizer";
import dispatches from "@stores/dispatches";

export const fetchMonitoringMonth = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchMonitoringMonthStarted());
  await monitoringMonthApi
    .getMonitoringMonth(uifId)
    .then((res) => {
      dispatch(actions.fetchMonitoringMonthSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchMonitoringMonthFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postMonitoringMonth = (dispatch: Dispatch) => async (
  uifId: string,
  param: InitialMonitoringMonthValues,
  initialState: InitialMonitoringMonthValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postMonitoringMonthStarted());
  const normalizedParam = normalizePostMonitoringMonthDataToAPI(
    param,
    initialState
  );

  await monitoringMonthApi
    .postMonitoringMonth(uifId, normalizedParam)
    .then(() => {
      dispatch(actions.postMonitoringMonthSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postMonitoringMonthFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchMonitoringMonth: ReturnType<typeof fetchMonitoringMonth>;
  postMonitoringMonth: ReturnType<typeof postMonitoringMonth>;
};

export const KEIKAKUSODANMonitoringMonthDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchMonitoringMonth: fetchMonitoringMonth(dispatch),
  postMonitoringMonth: postMonitoringMonth(dispatch)
});
