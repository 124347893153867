import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import {
  StaffTreatmentSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES
} from "@constants/variables";
import {
  SpecificOfficeType,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST
} from "@constants/mgr/KODOENGO/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/v202104/mgr/KODOENGO/facility/initialValues";

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);

const specificOfficeTypeRadioItems = generateRadioItems(SpecificOfficeType);

type Props = {
  formikProps: FormikProps<FacilityValues>;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = React.useState(false);
  // 福祉・介護職員等ベースアップ等支援加算のdisabled-state
  const [
    disableBetterSupporterConditionBaseUpFlg,
    setDisableBetterSupporterConditionBaseUpFlg
  ] = React.useState(false);
  const [
    staffTreatmentSpecificSystemTypeRadioItems,
    setStaffTreatmentSpecificSystemTypeRadioItems
  ] = React.useState(STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST);

  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );
  const specificFacilitiesAddition: string = getIn(
    props.formikProps.values,
    "additionalItem.specificFacilitiesAddition"
  );

  React.useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
    setDisableBetterSupporterConditionBaseUpFlg(disabled);
    const specificDisabled = specificFacilitiesAddition === "0";
    const list = [...staffTreatmentSpecificSystemTypeRadioItems];
    list[1].disabled = specificDisabled;
    setStaffTreatmentSpecificSystemTypeRadioItems(list);
  }, [staffTreatmentSystemType, specificFacilitiesAddition]);

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        <FormikRadioButtons
          name="additionalItem.specificFacilitiesAddition"
          label="特定事業所加算"
          options={specificOfficeTypeRadioItems}
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="staffTreatmentSystem" />}
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
        />
      </FormGroup>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算"
          disabled={disableBetterSupporterConditionBaseUpFlg}
        />
        <FormikCheckbox
          name="additionalItem.lifeSupportHubInDistrictFlg"
          label="地域生活支援拠点等"
        />
      </FormGroup>
    </FormPaper>
  );
};
