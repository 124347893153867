import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import { SelectMonthValue } from "@interfaces/ui/form";
import { getWareki } from "@utils/date";
import { FormikSelectMonthNotSelectedDefault } from "@components/molecules/FormikSelectMonthNotSelectedDefault";
import { FormikSelectMonth } from "@components/molecules/FormikSelectMonth";

const styles = (): StyleRules =>
  createStyles({
    readonlyWrapper: {
      display: "flex",
      alignItems: "flex-end",
      "& > div": {
        width: 128,
        marginRight: 16
      },
      "& > div:first-child": {
        width: 240
      }
    },
    noLabel: {
      marginTop: -16
    }
  });

type ChangeHookEvent = (
  event: React.ChangeEvent<HTMLSelectElement>,
  beforeValue: string
) => void;

type OwnProps = {
  isEditable: boolean;
  name: string; // formikと紐づけるname
  label?: string;
  value: SelectMonthValue;
  required?: boolean;
  addYearTo?: number;
  overrideYearFrom?: number;
  setFormikFieldValue?: (
    fieldName: string,
    value: number | boolean | string
  ) => void;
  onChangeHook?: ChangeHookEvent;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 記録系ページで使う専用のSelectMonth
 * 表示と編集モードを切り替える
 */
const RecordSelectMonthCore = (props: Props): JSX.Element => {
  let Component: JSX.Element;
  if (props.isEditable) {
    Component = (
      <div className={!props.label ? props.classes.noLabel : undefined}>
        {props.setFormikFieldValue ? (
          <FormikSelectMonthNotSelectedDefault
            name={props.name}
            label={props.label || ""}
            addYearTo={props.addYearTo}
            setFormikFieldValue={props.setFormikFieldValue}
            overrideYearFrom={props.overrideYearFrom}
          />
        ) : (
          <FormikSelectMonth
            name={props.name}
            label={props.label || ""}
            required={props.required || false}
            addYearTo={props.addYearTo}
            style={{ marginBottom: 0 }}
            overrideYearFrom={props.overrideYearFrom}
            onChangeHook={props.onChangeHook}
          />
        )}
      </div>
    );
  } else {
    const year =
      props.value && props.value.year
        ? `${props.value.year}年 (${getWareki(props.value.year)})`
        : "-年";
    const month =
      props.value && props.value.month ? `${props.value.month}` : "-";
    Component = (
      <div className={props.classes.readonlyWrapper}>
        <ReadonlyTextField value={year} defaultValue="" label={props.label} />
        <ReadonlyTextField value={`${month}月`} defaultValue="" />
      </div>
    );
  }
  return Component;
};

export const RecordSelectMonth = withStyles(styles)(RecordSelectMonthCore);
