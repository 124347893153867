import * as types from "./types";
import * as actions from "./actions";

const initialState: types.OperationsState = {
  created_at: "",
  updated_at: "",
  operations: []
};

export const CsegOperations = (
  state = initialState,
  action: actions.ActionTypes
): types.OperationsState => {
  switch (action.type) {
    case types.FETCH_OPERATIONS_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_OPERATIONS_FAILED:
      return { ...state, ...action.error };
    case types.UNSET_OPERATIONS:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
