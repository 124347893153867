import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";
import { CarePlanDetailsItem } from "./getCarePlan";

/**
 * サービス予定登録API用のリクエストパラメータ
 */
export type RequestParamToPostAPI = {
  uif_id: string | null;
  params: {
    users_in_facility_care_plan_id: number | null;
    day_of_week: number;
    status?: number;
    number_of_boarding_and_alighting?: number | null;
    number_of_participants?: number | null;
    practitioner1?: number | null;
    practitioner1_license?: number | null;
    practitioner1_in_time?: string | null;
    practitioner1_out_time?: string | null;
    practitioner1_calculated_hours?: number | null;
    practitioner1_calculated_moving_hours?: number | null;
    practitioner1_memo?: string | null;
    users_in_facility_care_plan_details1?: CarePlanDetailsItem[];
    practitioner2?: number | null;
    practitioner2_license?: number | null;
    practitioner2_in_time?: string | null;
    practitioner2_out_time?: string | null;
    practitioner2_calculated_hours?: number | null;
    practitioner2_memo?: string | null;
    users_in_facility_care_plan_details2?: CarePlanDetailsItem[];
    time_duplication_judgment_flg?: number | null;
    whole_calculated_hours?: number | null;
    duplicate_calculated_hours?: number | null;
    moving_time_duplication_judgment_flg?: number | null;
    whole_calculated_moving_hours?: number | null;
    duplicate_calculated_moving_hours?: number | null;
    accompany_support_in_time?: string | null;
    accompany_support_out_time?: string | null;
    whole_calculated_accompany_support_hours?: number | null;
    whole_calculated_accompany_support_moving_hours?: number | null;
    license_same_flg?: number | null;
    second_person_flg?: number | null;
    accompany_support_flg?: number | null;
    sputum_implementation_flg?: number | null;
  };
};

/**
 * サービス予定の登録（更新）
 * @param data リクエスト情報
 */
export const postCarePlan = async (
  data: RequestParamToPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/care_plan/${data.uif_id}`;
  return request.post(url, data.params);
};
