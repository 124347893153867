import {
  InitialDataValues,
  InitialDataErrors
} from "@interfaces/mgr/SHUROTEICHAKU/report/initialData";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import validator, { validateSwitcher, dateValidator } from "@validator";
import { SUPPORT_METHOD } from "@constants/mgr/SHUROTEICHAKU/variables";

export const reportValidation = (
  values: InitialDataValues
): InitialDataErrors => {
  const validatedShareSupportReportDate = dateValidator(
    notSelectedToEmpty(values.ReportData.shareSupportReportDate),
    "validDate"
  );

  const validatedUsers = values.ReportData.usageResults.map((report) => {
    return {
      supportMethodOther: validateSwitcher(
        report.supportMethod === SUPPORT_METHOD[6].value,
        validator(report.supportMethodOther, "required")
      ),
      supportStartTime: validator(report.supportStartTime, "checkTime"),
      supportEndTime: validator(report.supportEndTime, "checkTime", {
        type: "checkTimeFuture",
        startTime: report.supportStartTime
      }),
      remarks: validator(report.remarks ? report.remarks : "", {
        type: "checkCharacterLength",
        length: 50
      })
    };
  });

  return {
    ReportData: {
      shareSupportReportDate: validatedShareSupportReportDate,
      usageResults: validatedUsers
    }
  };
};
