import * as types from "./types";
import { GetSupportReportUserResponse } from "@api/requests/supportReport/getSupportReportUser";
import { ResponseErrorState } from "@stores/ui/responseError/types";

export const fetchSupportReportStarted = () =>
  ({ type: types.FETCH_SUPPORT_REPORT_STARTED } as const);
export const fetchSupportReportSuccess = (
  res: GetSupportReportUserResponse["data"]
) => ({ type: types.FETCH_SUPPORT_REPORT_SUCCESS, payload: res } as const);
export const fetchSupportReportFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.FETCH_SUPPORT_REPORT_FAILED, error: err } as const);
export const postSupportReportStarted = () =>
  ({ type: types.POST_SUPPORT_REPORT_STARTED } as const);
export const postSupportReportSuccess = () =>
  ({ type: types.POST_SUPPORT_REPORT_SUCCESS } as const);
export const postSupportReportFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.POST_SUPPORT_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportReportStarted>
  | ReturnType<typeof fetchSupportReportSuccess>
  | ReturnType<typeof fetchSupportReportFailed>
  | ReturnType<typeof postSupportReportStarted>
  | ReturnType<typeof postSupportReportSuccess>
  | ReturnType<typeof postSupportReportFailed>;
