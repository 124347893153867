import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordMultipleSelect from "@components/organisms/mgr/common/record/RecordMultipleSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { FieldItem, CategorizedFieldItem } from "@interfaces/ui/form";
import { dateToSelectDateValue } from "@utils/date";

const styles = (): StyleRules =>
  createStyles({
    listRow: {
      display: "flex",
      marginBottom: 12,
      "& > :last-child": {
        paddingLeft: 16
      },
      "& > :only-child": {
        padding: 0
      }
    },
    listRowExtendsMarginBottom: {
      display: "flex",
      marginBottom: 24,
      "& > :last-child": {
        paddingLeft: 16
      },
      "& > :only-child": {
        padding: 0
      }
    },
    listLabel: {
      width: 136,
      minWidth: 136,
      marginTop: 4,
      fontSize: 14,
      whiteSpace: "pre-line",
      color: "rgba(0, 0, 0, 0.6)",
      "&.withTooltip": {
        display: "flex"
      },
      "&.multiTextLabel": {
        marginTop: 20
      }
    },
    listField: {
      flex: 1
    },
    tooltip: {
      marginLeft: 16,
      marginTop: -3
    }
  });

interface BaseProps extends WithStyles<typeof styles> {
  label: string;
  name: string;
  value: string;
  defaultValue: string;
  placeholder: string;
  isEditing: boolean;
  hiddenLabel?: boolean;
  emptyText?: string;
  isExtendsMarginBottom?: boolean;
  tooltip?: JSX.Element | null;
  multiTextLabel?: boolean;
  tag?: keyof JSX.IntrinsicElements;
}
interface TextProps extends BaseProps {
  type: "text";
  maxLength?: number;
}
interface SelectProps extends BaseProps {
  type: "select";
  options: FieldItem[];
  onChangeHook?: (
    event: React.ChangeEvent<HTMLSelectElement>,
    beforeValue: string
  ) => void;
  isSelectablePlaceholder?: boolean; // placeholderを選択した際に表示
}
interface MultipleProps extends BaseProps {
  type: "multiple";
  options: CategorizedFieldItem[];
  maxWidth?: number;
}
interface CustomProps extends WithStyles<typeof styles>, React.Props<{}> {
  type: "custom";
  label: string;
  hiddenLabel?: boolean;
  isExtendsMarginBottom?: boolean;
  tooltip?: JSX.Element | null;
  multiTextLabel?: boolean;
  tag?: keyof JSX.IntrinsicElements;
}
type DateProps = {
  type: "date";
  required: boolean;
  tag?: keyof JSX.IntrinsicElements;
} & BaseProps;

type Props = TextProps | SelectProps | MultipleProps | CustomProps | DateProps;

/**
 * 支援記録のtypeに応じたフィールド
 */
const RecordSupportTableField = (props: Props): JSX.Element => {
  const WrapTag = props.tag || "li";
  return (
    <WrapTag
      className={
        props.isExtendsMarginBottom
          ? props.classes.listRowExtendsMarginBottom
          : props.classes.listRow
      }
    >
      {!props.hiddenLabel && !props.tooltip && (
        <div
          className={
            props.multiTextLabel
              ? `${props.classes.listLabel} multiTextLabel`
              : props.classes.listLabel
          }
        >
          {props.label}
        </div>
      )}
      {props.tooltip && (
        <div className={`${props.classes.listLabel} withTooltip`}>
          <span>{props.label}</span>
          <span className={props.classes.tooltip}>{props.tooltip}</span>
        </div>
      )}
      <div className={props.classes.listField}>
        {props.type === "text" && (
          <RecordTextField
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            isEditable={props.isEditing}
            maxLength={props.maxLength}
          />
        )}
        {props.type === "select" && (
          <RecordSelect
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            isEditable={props.isEditing}
            options={props.options}
            onChangeHook={props.onChangeHook}
            emptyText={props.emptyText}
            isSelectablePlaceholder={props.isSelectablePlaceholder}
          />
        )}
        {props.type === "multiple" && (
          <RecordMultipleSelect
            name={props.name}
            value={props.value}
            defaultValue={props.defaultValue}
            placeholder={props.placeholder}
            isEditable={props.isEditing}
            options={props.options}
            maxWidth={props.maxWidth}
            emptyText={props.emptyText}
          />
        )}
        {props.type === "date" && (
          <RecordSelectDate
            name={props.name}
            required={props.required}
            value={dateToSelectDateValue(props.value || "")}
            isEditable={props.isEditing}
            addYearTo={1}
            overrideYearFrom={1989}
          />
        )}
        {props.type === "custom" && props.children}
      </div>
    </WrapTag>
  );
};

export default withStyles(styles)(RecordSupportTableField);
