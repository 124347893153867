import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportPlanUifIdSupportPlanSheets = {
  support_plan_sheet_id: number | null;
  author: number | null;
  creation_date: string;
  intake: string | null;
  assessment_biological: string | null;
  assessment_psychological: string | null;
  assessment_social: string | null;
  support_plan_sheet_details: {
    support_plan_sheet_detail_id: number | null;
    support_issue: string | null;
    response_policy: string | null;
    is_delete: number | null;
  }[];
};

/**
 * 対象ユーザーの支援計画シート一覧を更新する。
 * @param uifId ユーザーID
 * @param params 更新データ
 */
export const postSupportPlanUifIdSupportPlanSheets = async (
  uifId: string,
  params: PostSupportPlanUifIdSupportPlanSheets
): Promise<AxiosResponse<PostSupportPlanUifIdSupportPlanSheets>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_plan_sheets`;
  return request.post(url, params);
};
