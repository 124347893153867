import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import {
  ServiceDeliveryState,
  SERVICE_DELIVERY_MONTHLY
} from "@stores/domain/serviceDelivery/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
// ui
import { ServiceDeliveryTable } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryTable";
// utils
import { compareDateFuture } from "@utils/date";
import { OptionInterface } from "@components/atoms/DropDown";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { RouteComponentProps } from "react-router-dom";
import format from "date-fns/format";
// variables
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    tableContainer: {
      margin: "16px 16px"
    },
    noPlanAndNoRecordFuture: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  });

type OwnProps = {
  currentDate: Date;
  history: RouteComponentProps["history"];
  selectedDate: Date;
  selectedUser: OptionInterface;
  facilityType: FacilityType;
};

type StateProps = {
  serviceDeliveryMonthlyState: ServiceDeliveryState["monthlyRecord"];
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchMonthlyRecord: (
    usersInFacilityId: number,
    yyyymm: string
  ) => Promise<void>;
  fetchFacilityUserList: (date: Date) => void;
};

type MergeProps = OwnProps &
  StateProps &
  DispatchProps & {
    userListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

const ServiceDeliveryMonthlyRecordCore = (props: Props): JSX.Element | null => {
  const { selectedDate, currentDate, selectedUser } = props;

  // 未来日かつ計画・実績がない場合
  let emptyPlanMessage;
  if (props.serviceDeliveryMonthlyState.service_delivery.length === 0) {
    if (compareDateFuture(currentDate, selectedDate)) {
      // 未来日の場合
      if (props.facilityType === FacilityType.KODOENGO) {
        emptyPlanMessage =
          "計画または支援手順書 兼 記録用紙がありません。利用実績画面から計画を入力するか、支援計画画面から支援手順書兼記録用紙を作成後、ご利用ください。";
      } else {
        emptyPlanMessage =
          "計画がありません。利用実績画面から入力後、ご利用ください。";
      }
      // 当日または過去日の場合
    } else if (props.facilityType === FacilityType.KODOENGO) {
      emptyPlanMessage =
        "計画・支援手順書 兼 記録用紙・サービス提供記録がありません。利用実績画面にて計画を入力する、または支援計画画面にて支援手順書 兼 記録用紙を作成後にご利用いただくか、この画面の「計画外の記録の追加」ボタンからサービス提供記録を作成してください。";
    } else {
      emptyPlanMessage =
        "計画または記録がありません。利用実績画面にて計画を入力後にご利用いただくか、「計画外の記録の追加」ボタンから記録を作成してください。";
    }
  } else if (props.userListOption.length === 0) {
    // 利用者がいない場合
    emptyPlanMessage =
      "利用者が登録されていません。利用者情報画面で登録後、ご利用ください。";
  }

  const changeEditMode = (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    supportProcedureFormsId: number
  ): void => {
    const paramDate = format(targetDate, "YYYYMMDD");
    const targetUserId = String(selectedUser.value);
    if (props.facilityType === FacilityType.KODOENGO) {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${supportProcedureFormsId}/${targetUserId}/monthly`
      );
    } else {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${targetUserId}/monthly`
      );
    }
  };

  const { classes } = props;
  return (
    <>
      <div className={classes.tableContainer}>
        {emptyPlanMessage ? (
          <div className={classes.noPlanAndNoRecordFuture}>
            {emptyPlanMessage}
          </div>
        ) : (
          <ServiceDeliveryTable
            records={props.serviceDeliveryMonthlyState}
            type={SERVICE_DELIVERY_MONTHLY}
            changeEditMode={changeEditMode}
            facilityType={props.facilityType}
            openDeleteModal={(): void => {
              /* 未実装 */
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    serviceDeliveryMonthlyState: state.serviceDelivery.monthlyRecord,
    userInFacility: state.KYOTAKUKAIGO.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery } = dispatches;
  const { KYOTAKUKAIGO } = dispatches;
  const userInFacilityDispatcher = KYOTAKUKAIGO.userInFacilityDispatcher(
    dispatch
  );
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchMonthlyRecord: (
      usersInFacilityId: number,
      yyyymm: string
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchMonthlyRecord(usersInFacilityId, yyyymm),
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const userListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    userListOption,
    ...stateProps,
    ...ownProps,
    ...dispatchProps
  };
};

export const ServiceDeliveryMonthlyRecord = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryMonthlyRecordCore));
