/**
 * YYYYMM形式をYYYY-MMに変換
 */
export const convertYYYYMMToYYYY_MM = (date: string): string | null => {
  const year = date.slice(0, 4);
  const month = date.slice(4, 6);
  const convertedDate = `${year}-${month}`;
  if (
    date.length !== 6 ||
    Number.isNaN(Number(date)) ||
    Number.isNaN(new Date(convertedDate).getTime())
  ) {
    return null;
  }

  return convertedDate;
};
