import {
  FACILITY,
  FACILITY_202104,
  USERS_ID,
  USERS_ID_202104,
  REPORT_DAILY,
  REPORT_DAILY_202104,
  REPORT_USERS,
  REPORT_USERS_202104
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V202104
} from "@constants/variables";

// 特定事業所
export enum SpecificOfficeType {
  "なし" = 0,
  "特定事業所加算（Ⅰ）", // 1
  "特定事業所加算（Ⅱ）", // 2
  "特定事業所加算（Ⅲ）" // 3
}

// 福祉・介護職員等特定処遇改善加算
export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE = {
  NONE: { label: "なし", value: "1", disabled: false },
  LEVEL1: { label: "特定処遇改善加算（Ⅰ）", value: "2", disabled: false },
  LEVEL2: { label: "特定処遇改善加算（Ⅱ）", value: "3", disabled: false }
};

export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST = [
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.NONE,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL2
];

// サービス内容(実績記録表用)
export const JUDOHOMONKAIGO_JISSEKI_STATUS_LIST = [
  { label: "", value: "1" },
  { label: "", value: "2" },
  { label: "入院", value: "3" },
  { label: "入院（長期）", value: "4" }
];

export const JUDOHOMONKAIGO_DISPATCH_NO_LIST = [
  { label: "①", value: "1" },
  { label: "②", value: "2" }
];

export const JUDOHOMONKAIGO_INPUT_CLASS_LIST = {
  PLAN: { label: "重度訪問介護計画", value: "1" },
  RESULT: {
    label: "サービス提供実績",
    value: "2"
  },
  CREATE: {
    label: "重度訪問介護計画/サービス提供実績",
    value: "0"
  }
};

export const JUDOHOMONKAIGO_INPUT_CLASS_SELECT_LIST = [
  JUDOHOMONKAIGO_INPUT_CLASS_LIST.PLAN,
  JUDOHOMONKAIGO_INPUT_CLASS_LIST.RESULT
];

export const REPORT_FILTER_LIST = {
  NONE: { label: "すべての利用者", value: "1" },
  FILTER: { label: "計画または実績あり", value: "2" }
};

export const REPORT_FILTER_SELECT_LIST = [
  REPORT_FILTER_LIST.NONE,
  REPORT_FILTER_LIST.FILTER
];

export const PROCESS_TYPE = {
  ALL: 1,
  ALL_ERROR: 2,
  NEW_ONLY: 3
};

export const INOUT_RESULTS_COPY_ERROR_STATUS = 17;

// サービス内容(表示用)
export const JUDOHOMONKAIGO_DISPLAY_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "居宅", value: "10" },
  { label: "入院", value: "20" },
  { label: "入院（長期）", value: "30" }
];

export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LiST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

// サービス内容
export const JUDOHOMONKAIGO_STATUS_LIST = {
  NONE: { label: "選択してください", value: "1" },
  HOME_VISIT: { label: "居宅", value: "2" },
  HOSPITALIZATION: { label: "入院", value: "3" },
  HOSPITALIZATION_LONG_TERM: { label: "入院（長期）", value: "4" }
};

export const JUDOHOMONKAIGO_STATUS_SELECT_LIST = [
  JUDOHOMONKAIGO_STATUS_LIST.NONE,
  JUDOHOMONKAIGO_STATUS_LIST.HOME_VISIT,
  JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION,
  JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION_LONG_TERM
];

export const JUDOHOMONKAIGO_MEMBER_LIST = {
  ONE: { label: "1人", value: "1" },
  TWO: { label: "2人", value: "2" }
};

export const JUDOHOMONKAIGO_MEMBER_SELECT_LIST = [
  JUDOHOMONKAIGO_MEMBER_LIST.ONE,
  JUDOHOMONKAIGO_MEMBER_LIST.TWO
];

export const JUDOHOMONKAIGO_BASE_LIST = {
  NONE: { label: "-", value: "0" },
  SELECT: { label: "選択してください", value: "0" }
};

export const JUDOHOMONKAIGO_ACTION_CLASS_LIST = {
  GUIDE: { label: "移動", value: "1" },
  NONE: { label: "空き", value: "2" }
};

export const JUDOHOMONKAIGO_ACTION_CLASS_LIST_NUMBER = [
  { label: "移動", value: 1 },
  { label: "空き", value: 2 }
];

export const JUDOHOMONKAIGO_ACTION_CLASS_SELECT_LIST = [
  JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE,
  JUDOHOMONKAIGO_ACTION_CLASS_LIST.NONE
];

export const JUDOHOMONKAIGO_LICENSE_LIST = {
  NONE: { label: "-", value: "1" },
  BEGINNER: { label: "新任", value: "2" },
  EXPERT: { label: "熟練", value: "3" }
};

export const JUDOHOMONKAIGO_LICENSE_SELECT_LIST = [
  JUDOHOMONKAIGO_LICENSE_LIST.NONE,
  JUDOHOMONKAIGO_LICENSE_LIST.BEGINNER,
  JUDOHOMONKAIGO_LICENSE_LIST.EXPERT
];

export const SERVICE_TIME_ZONE = {
  MIDNIGHT: { start: "22:00", startClass: "0", end: "06:00", endClass: "1" },
  MORNING: { start: "06:00", startClass: "0", end: "08:00", endClass: "0" },
  DAYTIME: { start: "08:00", startClass: "0", end: "18:00", endClass: "0" },
  NIGHT: { start: "18:00", startClass: "0", end: "22:00", endClass: "0" }
};

export const SERVICE_TIME_ZONE_LIST = [
  SERVICE_TIME_ZONE.MIDNIGHT,
  SERVICE_TIME_ZONE.MORNING,
  SERVICE_TIME_ZONE.DAYTIME,
  SERVICE_TIME_ZONE.NIGHT
];

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

export const JUDOHOMONKAIGO_DAY_OF_WEEK_LIST = [
  { label: "月", value: "1" },
  { label: "火", value: "2" },
  { label: "水", value: "3" },
  { label: "木", value: "4" },
  { label: "金", value: "5" },
  { label: "土", value: "6" },
  { label: "日", value: "7" }
];

export const STANDARD_TIME_VALUE = 0.5;
export const MAX_TIME_VALUE = 3;
export const TIME_MIN_VALUE = 40;

export const JUDHOMONKAIGO_PLAN_CORE_WORKER_LICENSE_LIST = [
  JUDOHOMONKAIGO_LICENSE_LIST.NONE,
  JUDOHOMONKAIGO_LICENSE_LIST.BEGINNER,
  JUDOHOMONKAIGO_LICENSE_LIST.EXPERT
];

export const MAX_MOVING_TIME = 4;

// custom_recordのdefault_itemの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO = {
  vital: 1,
  meal: 5,
  hydration: 7,
  urination_management: 11,
  excretion_management: 12,
  menu: 18,
  cost: 29,
  deposit: 30,
  staff_comment: 33
};

// 排泄管理（排便）「状態」default_choicd
export const EXCRETION_MAGAGEMENT_STATE = 2;

// サービス内容(記録画面表示用)
export const JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD = [
  { label: "-", value: "1" },
  { label: "重度訪問介護（居宅）", value: "2" },
  { label: "重度訪問介護（入院）", value: "3" },
  { label: "重度訪問介護（入院）", value: "4" }
];

// サービス内容(記録画面表示用)
export const JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD_PRINT = [
  { label: "-", value: "1" },
  { label: "重度訪問介護", value: "2" },
  { label: "重度訪問介護（入院時支援）", value: "3" }
];

// サービス内容(記録画面詳細用)
export const JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_DETAIL = [
  { label: "選択してください", value: "1" },
  { label: "重度訪問介護（居宅）", value: "2" },
  { label: "重度訪問介護（入院）", value: "3" }
];

export const TIME_CLASS_LIST = [
  { label: "当日", value: "0" },
  { label: "翌日", value: "1" }
];

export const IN_TIME_VALIDATION_LIST = [
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の重度訪問介護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の重度訪問介護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const DETAIL_IN_TIME_VALIDATION_LIST = [
  "移動/空き時間は提供時間の範囲内で入力してください",
  "移動/空き時間は同一時間帯で重複しないように入力してください"
];

export const DETAIL_OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "移動/空き時間は提供時間の範囲内で入力してください",
  "移動/空き時間は同一時間帯で重複しないように入力してください"
];

export const ACCOMPANY_SUPPORT_IN_TIME_VALIDATION_LIST = [
  "同行支援開始時間、同行支援終了時間は提供時間の範囲内で入力してください"
];

export const ACCOMPANY_SUPPORT_OUT_TIME_VALIDATION_LIST = [
  "同行支援終了時間は同行支援開始時間より後の時間を入力してください",
  "同行支援開始時間、同行支援終了時間は提供時間の範囲内で入力してください"
];

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

/** 重度訪問介護の報酬改定バージョン管理パスリスト */
type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";

export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
