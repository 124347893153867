import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";

// ui
import AdminTemplate from "@componentsMobile/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryFormDaily } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryFormDaily";
import { OptionInterface } from "@components/atoms/DropDown";
// utils
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { dateTodayInFormat } from "@utils/date";
import { DeleteDialog } from "@componentsMobile/molecules/dialog/DeleteDialog";
import * as URL from "@constants/url";
// variables
import { FacilityType } from "@constants/variables";

type DispatchProps = {
  fetchFacilityUserList: (date: Date, facilityType: FacilityType) => void;
  fetchCustomRecords: () => Promise<void>;
  fetchStaffs: () => void;
  fetchDetailsRecord: (
    serviceDeliveryRecordsId: number | null,
    inoutResultsId: number | null,
    supportProcedureFormsId: number | null
  ) => Promise<void>;
  deleteServiceDelivery: (serviceDeliveryRecordsId: number) => Promise<void>;
};

type StateProps = {
  facilityType: FacilityType;
  detailRecords: ServiceDeliveryState["detailsRecord"];
};

type OwnProps = RouteComponentProps<{
  date: string;
  serviceDeliveryRecordsId: string | undefined;
  inoutResultsId: string;
  supportProcedureFormsId: string | undefined;
}>;

type MergeProps = {
  userName: string;
};

type Props = MergeProps &
  OwnProps &
  StateProps &
  DispatchProps & {
    staffOptions: FieldItem[];
    userListOption: OptionInterface[];
  };

export const SUB_HEADER_HEIGHT = "48px";

/**
 * サービス提供記録（編集・閲覧）
 */
const ServiceDeliveryEditCore = (props: Props): JSX.Element => {
  const {
    date,
    serviceDeliveryRecordsId,
    inoutResultsId,
    supportProcedureFormsId
  } = props.match.params;
  const { facilityType } = props;
  const pageName =
    facilityType === FacilityType.KODOENGO
      ? "支援記録 > サービス提供記録"
      : "サービス提供記録";
  const [loadEnd, setLoadEnd] = React.useState<boolean>(false);
  // 計画外の記録の追加フラグ
  const isNew = serviceDeliveryRecordsId === undefined && !inoutResultsId;
  const isNoView = serviceDeliveryRecordsId === "0"; // 記録がない場合閲覧画面がない
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState<boolean>(
    false
  );

  // fetch
  React.useEffect(() => {
    const promise = [
      props.fetchFacilityUserList(
        new Date(fixDateAndTimeFormat(date)),
        facilityType
      ),
      props.fetchCustomRecords(),
      props.fetchStaffs()
    ];
    if (!isNew) {
      promise.push(
        props.fetchDetailsRecord(
          Number(serviceDeliveryRecordsId),
          Number(inoutResultsId),
          Number(supportProcedureFormsId)
        )
      );
    }
    Promise.all(promise).then(() => {
      setLoadEnd(true);
    });
  }, []);

  const onClickDeleteIconButton = (): void => {
    setIsOpenDeleteDialog(true);
  };

  const onCancel = (): void => {
    setIsOpenDeleteDialog(false);
  };

  const onDelete = async (): Promise<void> => {
    if (serviceDeliveryRecordsId) {
      await props.deleteServiceDelivery(+serviceDeliveryRecordsId);
      setIsOpenDeleteDialog(false);
      props.history.push(`${URL.RECORD_SERVICE_DELIVERY_DAILY}/${date}`);
    }
  };

  return (
    <AdminTemplate pageName={pageName}>
      <div
        style={{
          width: "100%",
          padding: "12px 16px",
          display: "flex",
          justifyContent: "space-between",
          fontSize: 16,
          fontWeight: "bold",
          height: SUB_HEADER_HEIGHT,
          position: "fixed",
          top: 0,
          backgroundColor: "#fff",
          boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
          zIndex: 100
        }}
      >
        <div>{dateTodayInFormat(date, true)}</div>
        <div>{props.userName}</div>
      </div>
      {loadEnd && (
        <ServiceDeliveryFormDaily
          isNew={isNew}
          isNoView={isNoView}
          date={new Date(fixDateAndTimeFormat(date))}
          targetDate={date}
          history={props.history}
          detailRecords={props.detailRecords}
          onClickDeleteIconButton={onClickDeleteIconButton}
          facilityType={facilityType}
          params={props.match.params}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteDialog
          isOpen={isOpenDeleteDialog}
          title="この記録を削除します"
          message="削除した場合、データの復元はできません。よろしいですか？"
          onDelete={onDelete}
          onCancel={onCancel}
        />
      )}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    detailRecords: state.serviceDelivery.detailsRecord
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    customRecordsWithCategory,
    serviceDelivery,
    staffDispatcher
  } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  const staffDispatches = staffDispatcher(dispatch);
  return {
    fetchFacilityUserList: (date: Date, facilityType): Promise<void> =>
      dispatches[facilityType]
        .userInFacilityDispatcher(dispatch)
        .fetchTargetDay(date),
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchDetailsRecord: (
      serviceDeliveryRecordsId: number | null,
      inoutResultsId: number | null,
      supportProcedureFormsId: number | null
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchDetailsRecord(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      ),
    deleteServiceDelivery: (serviceDeliveryRecordsId: number): Promise<void> =>
      serviceDeliveryDispatches.deleteServiceDelivery(serviceDeliveryRecordsId)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const nameSei = stateProps.detailRecords
    ? stateProps.detailRecords.nameSei
    : "";
  const nameMei = stateProps.detailRecords
    ? stateProps.detailRecords.nameMei
    : "";
  return {
    userName: `${nameSei} ${nameMei}`,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryEditDailyMobile = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ServiceDeliveryEditCore);
