import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * 実績登録API用のリクエストパラメータ
 */
export type RequestParamToPostAPI = {
  inout_consultation_results: {
    inout_consultation_results_id: number | null;
    inout_result_daily_id: number | null;
    target_date: string | null;
    status?: number;
    housing_support_cooperation_flg?: number | null;
    housing_support_promotion_flg?: number | null;
    daily_support_provision_of_info_flg?: number | null;
    memo?: string | null;
  }[];
};

/**
 * 常時連絡体制確保の未実施登録API用のリクエストパラメータ
 */
export type RequestParamMonthlyToPostAPI = {
  inout_results_monthly: {
    no_constant_contact_system_flg?: number | null;
  };
};

/**
 * 利用実績の登録（更新）
 */
export const postInOutConsultationResultsMonthly = async (
  data: RequestParamToPostAPI,
  uifId: number,
  date: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/inout_consultation_results/${uifId}/${date}`;
  return request.post(url, data);
};

/**
 * 常時連絡体制確保の未実施登録（更新）
 */
export const postInOutResultsMonthly = async (
  data: RequestParamMonthlyToPostAPI,
  uifId: number,
  date: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/inout_consultation_results/${uifId}/${date}`;
  return request.post(url, data);
};
