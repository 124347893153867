import * as React from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      width: 652,
      maxWidth: 652
    },
    title: {
      padding: "24px 32px 16px"
    },
    content: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 32px 40px"
    },
    footer: {
      margin: "0 32px 16px"
    }
  });

type OwnProps = {
  isOpen: boolean;
  title: string;
  message: string | React.ReactNode;
  closeButton: React.ReactNode;
  actionButton?: React.ReactNode;
  classes?: ClassNameMap;
};

type Props = OwnProps & WithStyles<typeof styles>;

const MessageDialog = (props: Props): JSX.Element => {
  return (
    <Dialog open={props.isOpen} classes={{ paper: props.classes.paper }}>
      <DialogTitle className={props.classes.title}>{props.title}</DialogTitle>
      <DialogContent className={props.classes.content}>
        {props.message}
      </DialogContent>
      <DialogActions className={props.classes.footer}>
        {props.closeButton}
        {props.actionButton}
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(MessageDialog);
