import * as types from "./types";
import {
  RequestParamToPostAPI,
  RequestParamMonthlyToPostAPI
} from "@api/requests/v202104/inOutConsultationResults/postInOutConsultationResultsMonthly";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCHIIKITEICHAKUMonthlyStarted = () =>
  ({ type: types.FETCH_CHIIKITEICHAKU_MONTHLY_STARTED } as const);
export const fetchCHIIKITEICHAKUMonthly = (res: types.ReportState) =>
  ({ type: types.FETCH_CHIIKITEICHAKU_MONTHLY, payload: res } as const);
export const fetchCHIIKITEICHAKUMonthlyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_CHIIKITEICHAKU_MONTHLY_FAILED, error: err } as const);
export const postCHIIKITEICHAKUReportStarted = () =>
  ({ type: types.POST_CHIIKITEICHAKU_REPORT_STARTED } as const);
export const postCHIIKITEICHAKUReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_CHIIKITEICHAKU_REPORT_MONTHLY, payload: req } as const);
export const postCHIIKITEICHAKUReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_CHIIKITEICHAKU_REPORT_FAILED, error: err } as const);
export const postCHIIKITEICHAKUReportMonthly = (
  req: RequestParamMonthlyToPostAPI
) =>
  ({ type: types.POST_CHIIKITEICHAKU_REPORT_MONTHLY, payload: req } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCHIIKITEICHAKUMonthlyStarted>
  | ReturnType<typeof fetchCHIIKITEICHAKUMonthly>
  | ReturnType<typeof fetchCHIIKITEICHAKUMonthlyFailed>
  | ReturnType<typeof postCHIIKITEICHAKUReportStarted>
  | ReturnType<typeof postCHIIKITEICHAKUReportUser>
  | ReturnType<typeof postCHIIKITEICHAKUReportFailed>
  | ReturnType<typeof postCHIIKITEICHAKUReportMonthly>;
