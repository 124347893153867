import * as React from "react";
import { SummaryByMonth } from "@stores/domain/invoice/type";
import { SupportsState } from "@stores/domain/supports/types";

type Options = {
  printPageHeight: number;
  tableHeadHeight: number;
};

export type ExternalProps = {
  data: SummaryByMonth;
  supportsRecord: SupportsState["supportsRecord"];
};

export type InjectProps = {
  setHeaderRef: (headerElement: HTMLElement | null) => void;
  setTableRowRef: (tableRowElement: HTMLElement | null, index: number) => void;
  tableRowStartEndIndicesInSheet: TableRowStartEndIndices[];
};

export type TableRowStartEndIndices = {
  startIndex: number;
  endIndex: number;
};

type State = {
  tableRowStartEndIndicesInSheet: TableRowStartEndIndices[];
};

const initialState = {
  tableRowStartEndIndicesInSheet: []
};

/**
 * 印刷プレビュー画面で、1つのテーブルが複数ページにまたがる場合に
 * 分割をする
 */
export const previewHOC = (params: Options = {} as Options) => <
  OriginalProps extends {}
>(
  Component: React.ComponentType<OriginalProps & InjectProps>
  // HOCの適切な型指定が不明なため暫定的にany
  /* eslint-disable @typescript-eslint/no-explicit-any */
): any => {
  type ResultProps = OriginalProps & ExternalProps;
  type arrayItem = HTMLElement | null;

  return class PreviewHOC extends React.Component<
    ResultProps,
    State,
    arrayItem
  > {
    public headerElement?: HTMLElement | null;

    public tableRowElements: arrayItem[];

    public constructor(props: ResultProps) {
      super(props);
      this.state = initialState;
      this.headerElement = undefined;
      this.tableRowElements = [];
    }

    public componentDidMount(): void {
      this.separatePage();
    }

    public componentDidUpdate(): void {
      this.separatePage();
    }

    public setHeaderRef = (headerElement: HTMLElement | null): void => {
      this.headerElement = headerElement;
    };

    public setTableRowRef = (
      tableRowElement: HTMLElement | null,
      index: number
    ): void => {
      this.tableRowElements = this.tableRowElements.filter((v) => v);
      this.tableRowElements[index] = tableRowElement;
    };

    /**
     * 1ページに入りきる量に列を分割する(1度だけ)
     */
    public separatePage(): void {
      const { printPageHeight, tableHeadHeight } = params;

      // separatePage()の循環を防ぐ処理
      // テーブルヘッダーを除いた列の数（endIndex）と、データの数（dataLength）が等しいときに、ページ分割が完了したとして処理を止める
      const dataLength = this.props.data
        ? this.props.data.detail.length + 1
        : this.props.supportsRecord.work_summary.length;

      const isSeparatePageComplete =
        this.state.tableRowStartEndIndicesInSheet.length > 0 &&
        this.state.tableRowStartEndIndicesInSheet[
          this.state.tableRowStartEndIndicesInSheet.length - 1
        ].endIndex === dataLength;

      if (isSeparatePageComplete || this.tableRowElements.length === 0) {
        return;
      }

      const tableRowStartEndIndicesInSheet = [];
      const headerHeight = this.headerElement
        ? this.headerElement.offsetHeight
        : 0;

      let total = 0;
      let currentIndex = 0;
      let lastIndex = 0;

      this.tableRowElements.forEach((tableRowElement) => {
        // 【恒久対応したら消す】面談が無いステータスだとtableRowElementsにnullが入るため飛ばす
        if (tableRowElement !== null) {
          total += tableRowElement.offsetHeight;
        }

        if (
          total >
          (tableRowStartEndIndicesInSheet.length === 0
            ? printPageHeight - (headerHeight + tableHeadHeight)
            : printPageHeight - tableHeadHeight)
        ) {
          tableRowStartEndIndicesInSheet.push({
            startIndex: lastIndex,
            endIndex: currentIndex - 1
          });
          lastIndex = currentIndex;
          if (tableRowElement !== null) {
            total = tableRowElement.offsetHeight;
          }
        }
        currentIndex += 1;
      });
      tableRowStartEndIndicesInSheet.push({
        startIndex: lastIndex,
        endIndex: currentIndex
      });
      this.setState({ tableRowStartEndIndicesInSheet });
    }

    public render(): JSX.Element {
      return (
        <Component
          tableRowStartEndIndicesInSheet={
            this.state.tableRowStartEndIndicesInSheet
          }
          setHeaderRef={this.setHeaderRef}
          setTableRowRef={this.setTableRowRef}
          {...this.props}
        />
      );
    }
  };
};
