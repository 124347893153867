import { RecordUserDetailValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { ErrorMessage } from "@validator";

type RecordUserDetailErrors = {
  record: ValidationErrors<RecordUserDetailValues["record"]>;
};

type CustomRecordFirstErrorMessage = {
  [custom_record_id: string]: { input_data: ErrorMessage };
};

type CustomRecordFourthErrorMessage = {
  [custom_record_id: string]: {
    [choiced_item_id: string]: { input_data: ErrorMessage };
  };
};

const validateCustomRecordFirst = (
  customRecords: RecordUserDetailValues["record"][0]["custom_record"]["input_type_first"]
): CustomRecordFirstErrorMessage => {
  const obj = {};
  Object.keys(customRecords).forEach((item) => {
    Object.assign(obj, {
      [item]: {
        input_data: validator(customRecords[`${item}`].input_data, {
          type: "checkCharacterLength",
          length: 2500
        })
      }
    });
  });
  return obj;
};

const validateCustomRecordFourth = (
  customRecords: RecordUserDetailValues["record"][0]["custom_record"]["input_type_fourth"]
): CustomRecordFourthErrorMessage => {
  const obj = {};
  Object.entries(customRecords).forEach(([customRecordKey, item]) => {
    const choicedObj = {};
    Object.keys(item).forEach((choicedItemKey) => {
      Object.assign(choicedObj, {
        [choicedItemKey]: {
          input_data: validator(
            customRecords[`${customRecordKey}`][`${choicedItemKey}`].input_data,
            "decimalPoint"
          )
        }
      });
    });
    Object.assign(obj, { [customRecordKey]: choicedObj });
  });
  return obj;
};

const validation = (values: RecordUserDetailValues): RecordUserDetailErrors => {
  return {
    record: values.record
      ? values.record.map((item) => ({
          custom_record: {
            input_type_first: validateCustomRecordFirst(
              item.custom_record.input_type_first
            ),
            input_type_fourth: validateCustomRecordFourth(
              item.custom_record.input_type_fourth
            )
          }
        }))
      : []
  };
};

export default validation;
