import { GetUsersSummary } from "@api/requests/supports/Cseg/getUsersSummary";

/**
 * ActionNames
 */
export const FETCH_USERS_SUMMARY_CSEG_STARTED =
  "SUPPORTS/FETCH_USERS_SUMMARY_CSEG_STARTED";
export const FETCH_USERS_SUMMARY_CSEG_SUCCESS =
  "SUPPORTS/FETCH_USERS_SUMMARY_CSEG_SUCCESS";
export const FETCH_USERS_SUMMARY_CSEG_FAILED =
  "SUPPORTS/FETCH_USERS_SUMMARY_CSEG_FAILED";
export const UNSET_USERS_SUMMARY_CSEG = "SUPPORTS/UNSET_USERS_SUMMARY_CSEG";

/**
 * State
 */
export type UsersSummaryState = GetUsersSummary["data"];
