import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as supportReportActions from "@stores/domain/supportReport/actions";
import supportReportDispatcher from "@stores/domain/supportReport/dispatcher";
import { normalizeFormValuesToPostSupportReportParams } from "./normalizer";
import supportReportApi from "@api/requests/supportReport";
import { SupportReportValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportReport/initialValues";
import { StaffState } from "@stores/domain/staff/types";

/**
 * 利用者ごと更新
 */
export const postSupportReport = (dispatch: Dispatch) => async (
  uifId: string,
  params: SupportReportValues,
  initialValues: SupportReportValues,
  staff: StaffState,
  fetchOptions: {
    year: string;
    month: string;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(supportReportActions.postSupportReportStarted());
  const normalizeParams = normalizeFormValuesToPostSupportReportParams(
    params,
    initialValues,
    staff
  );
  await supportReportApi
    .postSupportReport(
      uifId,
      normalizeParams,
      fetchOptions.year,
      fetchOptions.month
    )
    .then(async () => {
      dispatch(supportReportActions.postSupportReportSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportReportDispatcher(dispatch).fetchSupportReport(
        uifId,
        fetchOptions.year,
        fetchOptions.month
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        supportReportActions.postSupportReportFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  postSupportReport: (
    uifId: string,
    values: SupportReportValues,
    initialValues: SupportReportValues,
    staff: StaffState,
    fetchOptions: {
      year: string;
      month: string;
    }
  ) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  postSupportReport: postSupportReport(dispatch)
});
