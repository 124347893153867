import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCarePlanStarted = () =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_CARE_PLAN_STARTED } as const);
export const fetchCarePlan = (res: types.CarePlanStaffState) =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_CARE_PLAN, payload: res } as const);
export const fetchCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_CARE_PLAN_FAILED, error: err } as const);

export const postJUDOHOMONKAIGOCarePlanStarted = () =>
  ({ type: types.POST_JUDOHOMONKAIGO_CARE_PLAN_STARTED } as const);
export const postJUDOHOMONKAIGOCarePlan = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_JUDOHOMONKAIGO_CARE_PLAN, payload: req } as const);
export const postJUDOHOMONKAIGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.POST_JUDOHOMONKAIGO_CARE_PLAN_FAILED, error: err } as const);

export const deleteJUDOHOMONKAIGOCarePlanStarted = () =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_CARE_PLAN_STARTED } as const);
export const deleteJUDOHOMONKAIGOCarePlan = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_CARE_PLAN, payload: req } as const);
export const deleteJUDOHOMONKAIGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_CARE_PLAN_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCarePlanStarted>
  | ReturnType<typeof fetchCarePlan>
  | ReturnType<typeof fetchCarePlanFailed>
  | ReturnType<typeof postJUDOHOMONKAIGOCarePlanStarted>
  | ReturnType<typeof postJUDOHOMONKAIGOCarePlan>
  | ReturnType<typeof postJUDOHOMONKAIGOCarePlanFailed>
  | ReturnType<typeof deleteJUDOHOMONKAIGOCarePlanStarted>
  | ReturnType<typeof deleteJUDOHOMONKAIGOCarePlan>
  | ReturnType<typeof deleteJUDOHOMONKAIGOCarePlanFailed>;
