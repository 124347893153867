import { CityState } from "@stores/domain/city/type";

/**
 * 選択した市区町村のcityCodeを取得する
 */
export function getSelectedCityCode(
  cityList: CityState[],
  selectedCityId?: number | string
): string {
  let result = "";
  const id = Number(selectedCityId);
  Object.keys(cityList).forEach((key) => {
    if (cityList[key].value === id) {
      result = cityList[key].cityCode;
    }
  });

  return result;
}
