import { BasicErrors } from "@interfaces/mgr/KEIKAKUSODAN/facility/basic";
import { FacilityValues } from "@initialize/mgr/KEIKAKUSODAN/facility/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import { FacilityInfoKeikakuErrors } from "@interfaces/mgr/KEIKAKUSODAN/facility/facilityInfoKeikakuValues";
import { FacilityInfoShogaijiErrors } from "@interfaces/mgr/KEIKAKUSODAN/facility/facilityInfoShogaijiValues";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type FacilityErrors = BasicErrors &
  FacilityInfoKeikakuErrors &
  FacilityInfoShogaijiErrors;

const basicValidation = (
  values: FacilityValues,
  facility: FacilityState
): FacilityErrors => {
  const isKeikaku = facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    facility.facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required")
    },
    facilityInfoKeikaku: {
      officeNumber: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(
          values.facilityInfoKeikaku.officeNumber,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      officeName: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.officeName, "required")
      ),
      representativeName: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.representativeName, "required")
      ),
      tel: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.tel, "required", "naturalNumber")
      )
    },
    facilityInfoShogaiji: {
      officeNumber: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(
          values.facilityInfoShogaiji.officeNumber,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      officeName: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.officeName, "required")
      ),
      representativeName: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.representativeName, "required")
      ),
      tel: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.tel, "required", "naturalNumber")
      )
    }
  };
};

const AdditionalValidation = (
  values: FacilityValues
): FacilityInfoKeikakuErrors & FacilityInfoShogaijiErrors => {
  return {
    facilityInfoKeikaku: {
      lifeSupportFunctionPromotionTimes: validateSwitcher(
        values.facilityInfoKeikaku.lifeSupportFunctionPromotionFlg,
        validator(
          values.facilityInfoKeikaku.lifeSupportFunctionPromotionTimes,
          "required",
          "naturalNumber",
          {
            type: "lowerLimit",
            lowerLimit: 1
          }
        )
      )
    },
    facilityInfoShogaiji: {
      lifeSupportFunctionPromotionTimes: validateSwitcher(
        values.facilityInfoShogaiji.lifeSupportFunctionPromotionFlg,
        validator(
          values.facilityInfoShogaiji.lifeSupportFunctionPromotionTimes,
          "required",
          "naturalNumber",
          {
            type: "lowerLimit",
            lowerLimit: 1
          }
        )
      )
    }
  };
};

const subtractionItemValidation = (
  values: FacilityValues
): FacilityInfoKeikakuErrors & FacilityInfoShogaijiErrors => {
  return {
    facilityInfoKeikaku: {
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.facilityInfoKeikaku.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.facilityInfoKeikaku.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoKeikaku.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoKeikaku
                .abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      workContinuationNotPlanningFrom: validateSwitcher(
        values.facilityInfoKeikaku.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.workContinuationNotPlanningFrom
          ),
          "required"
        )
      ),
      workContinuationNotPlanningTo: validateSwitcher(
        values.facilityInfoKeikaku.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoKeikaku.workContinuationNotPlanningTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoKeikaku.workContinuationNotPlanningFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    },
    facilityInfoShogaiji: {
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.facilityInfoShogaiji.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.facilityInfoShogaiji.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoShogaiji.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji
              .abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoShogaiji
                .abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      workContinuationNotPlanningFrom: validateSwitcher(
        values.facilityInfoShogaiji.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji.workContinuationNotPlanningFrom
          ),
          "required"
        )
      ),
      workContinuationNotPlanningTo: validateSwitcher(
        values.facilityInfoShogaiji.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.facilityInfoShogaiji.workContinuationNotPlanningTo
          ),
          {
            type: "future",
            startDate:
              values.facilityInfoShogaiji.workContinuationNotPlanningFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};
export const validation = (
  values: FacilityValues,
  facility: FacilityState
): FacilityErrors => {
  const basicErrors = basicValidation(values, facility);
  const additionalItemErrors = AdditionalValidation(values);
  const subtractionItemErrors = subtractionItemValidation(values);
  return {
    basic: {
      ...basicErrors.basic
    },
    facilityInfoKeikaku: {
      ...basicErrors.facilityInfoKeikaku,
      ...additionalItemErrors.facilityInfoKeikaku,
      ...subtractionItemErrors.facilityInfoKeikaku
    },
    facilityInfoShogaiji: {
      ...basicErrors.facilityInfoShogaiji,
      ...additionalItemErrors.facilityInfoShogaiji,
      ...subtractionItemErrors.facilityInfoShogaiji
    }
  };
};
