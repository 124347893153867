import * as types from "./types";
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

export const fetchOptionalCustomInfoStarted = () =>
  ({ type: types.FETCH_OPTIONAL_CUSTOM_INFO_STARTED } as const);
export const fetchOptionalCustomInfoSuccess = (
  res: GetOptionalCustomInfoResponse
) =>
  ({ type: types.FETCH_OPTIONAL_CUSTOM_INFO_SUCCESS, payload: res } as const);
export const fetchOptionalCustomInfoFailed = (err: unknown) =>
  ({ type: types.FETCH_OPTIONAL_CUSTOM_INFO_FAILED, error: err } as const);
export const postUseCommonOperationRecordStarted = () =>
  ({
    type: types.POST_USE_COMMON_OPERATION_RECORD_STARTED
  } as const);
export const postUseCommonOperationRecordSuccess = (setting: number) =>
  ({
    type: types.POST_USE_COMMON_OPERATION_RECORD_SUCCESS,
    payload: setting
  } as const);
export const postUseCommonOperationRecordFailed = (err: unknown) =>
  ({
    type: types.POST_USE_COMMON_OPERATION_RECORD_FAILED,
    error: err
  } as const);
export const postUseUnitsOperationRecordStarted = () =>
  ({
    type: types.POST_USE_UNITS_OPERATION_RECORD_STARTED
  } as const);
export const postUseUnitsOperationRecordSuccess = (setting: number) =>
  ({
    type: types.POST_USE_UNITS_OPERATION_RECORD_SUCCESS,
    payload: setting
  } as const);
export const postUseUnitsOperationRecordFailed = (err: unknown) =>
  ({
    type: types.POST_USE_UNITS_OPERATION_RECORD_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchOptionalCustomInfoStarted>
  | ReturnType<typeof fetchOptionalCustomInfoSuccess>
  | ReturnType<typeof fetchOptionalCustomInfoFailed>
  | ReturnType<typeof postUseCommonOperationRecordStarted>
  | ReturnType<typeof postUseCommonOperationRecordSuccess>
  | ReturnType<typeof postUseCommonOperationRecordFailed>
  | ReturnType<typeof postUseUnitsOperationRecordStarted>
  | ReturnType<typeof postUseUnitsOperationRecordSuccess>
  | ReturnType<typeof postUseUnitsOperationRecordFailed>;
