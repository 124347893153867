/**
 * サービスコードテーブル
 */

import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import { StyleRules } from "@material-ui/core/styles";
import { ServiceCodesTable } from "@components/organisms/mgr/IDOSHIEN/report/dialog/ServiceCodesTable";
import { FieldArray, FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/IDOSHIEN/report/initial";

const styles = (): StyleRules => createStyles({});

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  onOpenDialog: () => void;
  onDeleteRecord: (index: number) => void;
  disabledButton: boolean;
  municipalityId: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceCodesFieldsCore = (props: Props): JSX.Element => {
  return (
    <FieldArray name="initial.serviceCodes">
      {(): JSX.Element => {
        const onClickAdd = (): void => {
          props.onOpenDialog();
        };
        const onClickDelete = (index: number): void => {
          props.onDeleteRecord(index);
        };
        return (
          <div>
            <div>
              <ServiceCodesTable
                formikProps={props.formikProps}
                onClickDelete={onClickDelete}
              />
              {props.municipalityId !== null && (
                <Button
                  color="secondary"
                  onClick={onClickAdd}
                  disabled={props.disabledButton}
                >
                  <IconAdd />
                  条件から絞り込んで登録する
                </Button>
              )}
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};

export const ServiceCodesFields = withStyles(styles)(ServiceCodesFieldsCore);
