import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type postInquiry = {
  support_inquiry: {
    support_inquiries_id: number | null;
    creation_date: string | null;
    creation_staff_id: number | null;
    why_not: string | null;
    inquiry_purpose: string | null;
    inquiry_destination: string | null;
    inquiry_date: string | null;
    inquiry_content: string | null;
    respondent_name: string | null;
    response_date: string | null;
    response_content: string | null;
    type_consultation?: number;
  };
};

/**
 * 指定ユーザーのサービス等利用計画、地域移行計画を保存する
 * @param uifId ユーザーID
 * @param params 更新データ
 */

export const postInquiry = async (
  uifId: string,
  params: postInquiry
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/inquiry`;
  return request.post(url, params);
};
