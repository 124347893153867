import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { dateToLocalisedString } from "@utils/date";
import * as ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    saturday: {
      color: "#06a6e9"
    },
    holiday: {
      color: "#ff5656"
    },
    default: {}
  });

type OwnProps = {
  date: string;
  dateFormat: string;
  holiday: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CustomDateFormat = (props: Props): JSX.Element => {
  let targetStyle = props.classes.default;
  const week = dateToLocalisedString(props.date, "d");
  if (week === "0" || props.holiday) {
    targetStyle = props.classes.holiday;
  } else if (week === "6") {
    targetStyle = props.classes.saturday;
  }
  return (
    <span className={ClassNames(targetStyle)}>
      {dateToLocalisedString(props.date, props.dateFormat)}
    </span>
  );
};

export default withStyles(styles)(CustomDateFormat);
