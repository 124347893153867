import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { OptionInterface } from "@components/atoms/DropDown";
import {
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_CURRENT
} from "@constants/variables";
import {
  FACILITY_201910,
  FACILITY_202104,
  FACILITY,
  USERS_ID,
  USERS_ID_201910,
  USERS_ID_202104,
  REPORT_DAILY,
  REPORT_DAILY_201910,
  REPORT_DAILY_202104,
  REPORT_MONTHLY,
  REPORT_MONTHLY_201910,
  REPORT_MONTHLY_202104
} from "@constants/url";

// 常勤看護職員等配置加算
export enum FullTimeNursePlacementTypes {
  "なし" = 0,
  "常勤看護職員等配置（Ⅰ）", // 1
  "常勤看護職員等配置（Ⅱ）" // 2
}

// 就職後６ヶ月以上の定着率 // Iのみ
export enum PostEmploymentRetentionRateTypes {
  "なし" = 7,
  "0割超1割未満" = 6,
  "1割以上2割未満" = 5,
  "2割以上3割未満" = 4,
  "3割以上4割未満" = 3,
  "4割以上5割未満" = 2,
  "5割以上" = 1
}

// 職員配置
export enum StaffPlacementTypes {
  "7.5：1" = 1,
  "10：1" // 2
}
// 職員配置 B型 2024年4月報酬改定以降
export enum StaffPlacementTypesBAfter2404 {
  "6：1" = 3,
  "7.5：1" = 1,
  "10：1" = 2
}

// 送迎サービス
export const SUPPLY_PICKUP_SERVICE_ITEMS = [
  { label: "送迎サービス（Ⅰ）", value: "1" },
  { label: "送迎サービス（Ⅱ）", value: "2" }
];

// 報酬体系 // Bのみ
export const REWARD_TYPE_ITEMS = [
  { label: "平均工賃月額", value: "0" },
  {
    label: "利用者の生産活動等への参加等を支援したことをもって一律の評価",
    value: "1"
  }
];

// 就労移行支援体制加算 前年度実績就労継続者
export const SUPPORT_IKOU_RESULT_ITEMS = [
  { label: "6ヶ月以上の就労継続者なし", value: "1" },
  { label: "6ヶ月以上の就労継続者あり", value: "2" }
];

export enum DischargeSupportFacilityTypes {
  "なし" = 1,
  "精神障害者退院支援施設加算（Ⅰ）",
  "精神障害者退院支援施設加算（Ⅱ）"
}

// 重度障害者支援
export const SEVERE_DISABILITY_SUPPORT_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "あり", value: "1" },
  { label: "あり（基礎研修修了者対応）", value: "2" }
];

// リハビリテーション加算
export const REHABILITATION_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "リハビリテーション加算（Ⅰ）", value: "1" },
  { label: "リハビリテーション加算（Ⅱ）", value: "2" }
];

// 負担_単位
export const BEAR_UNIT_ITEMS = [
  { label: "%", value: "1" },
  { label: "円", value: "2" }
];

// 利用者の1日平均労働時間 // Aのみ
export enum AverageDailyWorkingHoursOfUsersType {
  "2時間未満" = 7,
  "2時間以上3時間未満" = 6,
  "3時間以上4時間未満" = 5,
  "4時間以上5時間未満" = 4,
  "5時間以上6時間未満" = 3,
  "6時間以上7時間未満" = 2,
  "7時間以上" = 1
}

// 利用者の1日平均労働時間 // Aのみ
// 令和3年度報酬改定で追加
export enum AverageWorkDurationsType {
  "80点：7時間以上" = 1,
  "70点：6時間以上7時間未満" = 2,
  "55点：5時間以上6時間未満" = 3,
  "45点：4時間30分以上5時間未満" = 4,
  "40点：4時間以上4時間30分未満" = 5,
  "30点：3時間以上4時間未満" = 6,
  "20点：2時間以上3時間未満" = 7,
  "5点：2時間未満" = 8
}

// 利用者の1日平均労働時間 // Aのみ
// 令和6年度報酬改定で追加
export enum AverageWorkDurationsType202404 {
  "90点：7時間以上" = 1,
  "80点：6時間以上7時間未満" = 2,
  "65点：5時間以上6時間未満" = 3,
  "55点：4時間30分以上5時間未満" = 4,
  "40点：4時間以上4時間30分未満" = 5,
  "30点：3時間以上4時間未満" = 6,
  "20点：2時間以上3時間未満" = 7,
  "5点：2時間未満" = 8
}

// 生産活動 // Aのみ
export enum ProductionActivitiesType {
  "40点：前年度及び前々年度の生産活動収支が、いずれも当該各年度に利用者に支払う賃金の総額以上" = 1,
  "25点：前年度及び前々年度のうち前年度のみ、生産活動収支が当該年度に利用者に支払う賃金の総額以上" = 2,
  "20点：前年度及び前々年度のうち前々年度のみ、生産活動収支が当該年度に利用者に支払う賃金の総額以上" = 3,
  "5点：前年度及び前々年度の生産活動収支が、いずれも当該各年度に利用者に支払う賃金の総額以上ではない" = 4
}

// 生産活動 // Aのみ
// 令和6年度報酬改定で追加
export enum ProductionActivitiesType202404 {
  "60点：前年度、前々年度及び前々々年度における生産活動収支がそれぞれ当該年度に利用者に支払う賃金の総額以上" = 1,
  "50点：前年度及び前々年度の生産活動収支が、いずれも当該各年度に利用者に支払う賃金の総額以上" = 2,
  "40点：前年度の生産活動収支が、利用者に支払う賃金の総額以上" = 3,
  "20点：前年度の生産活動収支が、利用者に支払う賃金の総額未満" = 4,
  "-10点：前年度及び前々年度、生産活動収支が、いずれも当該各年度に利用者に支払う賃金の総額未満" = 5,
  "-20点：前年度、前々年度及び前々々年度における生産活動収支がいずれも当該各年度に利用者に支払う賃金の総額未満" = 6
}

// 多様な働き方 // Aのみ
export const DIVERSIFIED_WORK_STYLES_TYPE = [
  { label: "35点：8以上", value: 3 },
  { label: "25点：6又は7", value: 2 },
  { label: "15点：1以上5以下", value: 1 },
  { label: "0点：0", value: 0 }
];

// 多様な働き方 // Aのみ
// 令和6年度報酬改定で追加
export const DIVERSIFIED_WORK_STYLES_TYPE_202404 = [
  { label: "15点：5点以上", value: 3 },
  { label: "5点：3点又は4点", value: 2 },
  { label: "0点：2点以下", value: 1 }
];

// 支援力向上 // Aのみ
export const IMPROVEMENT_OF_SUPPORTS_TYPE = [
  { label: "35点：8以上", value: 3 },
  { label: "25点：6又は7", value: 2 },
  { label: "15点：1以上5以下", value: 1 },
  { label: "0点：0", value: 0 }
];

// 支援力向上 // Aのみ
// 令和6年度報酬改定で追加
export const IMPROVEMENT_OF_SUPPORTS_TYPE_202404 = [
  { label: "15点：5点以上", value: 3 },
  { label: "5点：3点又は4点", value: 2 },
  { label: "0点：2点以下", value: 1 }
];

// 地域連携活動 // Aのみ
export enum RegionalCooperationActivitiesType {
  "10点：1事例以上あり" = 1,
  "0点：事例なし" = 0
}

// 経営改善計画 // Aのみ
export const MANAGEMENT_IMPROVEMENT_PLAN_TYPE = [
  { label: "0点：経営改善計画を提出期限までに提出", value: 1 },
  { label: "-50点：経営改善計画を提出期限までに未提出", value: 0 }
];

// 利用者の知識・能力の向上 // Aのみ
export const INITIATIVES_TO_IMPROVE_ABILITIES_TYPE = [
  { label: "10点：取組が1以上", value: 1 },
  { label: "0点：取組なし", value: 0 }
];

// 平均工賃の月額 // Bのみ
export enum MonthlyAverageWageType {
  "5千円未満" = 7,
  "5千円以上1万円未満" = 6,
  "1万円以上2万円未満" = 5,
  "2万円以上2万5千円未満" = 4,
  "2万5千円以上3万円未満" = 3,
  "3万円以上4万5千円未満" = 2,
  "4万5千円以上" = 1
}

// 平均工賃の月額 // Bのみ
// 令和3年度報酬改定で追加
export enum AverageMonthlyWageType {
  "1万円未満" = 8,
  "1万円以上1万5千円未満" = 7,
  "1万5千円以上2万円未満" = 6,
  "2万円以上2万5千円未満" = 5,
  "2万5千円以上3万円未満" = 4,
  "3万円以上3万5千円未満" = 3,
  "3万5千円以上4万5千円未満" = 2,
  "4万5千円以上" = 1
}

// 重度者支援体制加算
export enum SevereSupportType {
  "なし" = 1,
  "重度者支援体制加算（Ⅰ）",
  "重度者支援体制加算（Ⅱ）"
}

// 作業時間_刻む単位
export const UNIT_ENGRAVE = [
  { label: "選択してください", value: "0" },
  { label: "1分", value: "1" },
  { label: "5分", value: "5" },
  { label: "10分", value: "10" },
  { label: "15分", value: "15" },
  { label: "30分", value: "30" },
  { label: "60分", value: "60" }
];
// サービス提供の状況 (inout_statusと紐付けて表示に使う想定)
export const IAB_IN_OUT_RECORDS_STATUS_LIST: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "施設外就労", value: "3" }, // ABのみ
  { label: "施設外支援", value: "4" }, // ABのみ
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用支援", value: "7" },
  { label: "移行準備支援体制加算(I)", value: "8" }, // Iのみ
  { label: "移行準備支援体制加算(II)", value: "9" }, // Iのみ
  { label: "欠席", value: "10" }
];

// サービス提供の状況 (inout_statusと紐付けて表示に使う想定)
// 令和3年度報酬改定で追加
export const IAB_IN_OUT_RECORDS_STATUS_LIST_202104: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "施設外就労", value: "3" }, // ABのみ
  { label: "施設外支援", value: "4" }, // ABのみ
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用支援", value: "7" },
  { label: "移行準備支援(I)", value: "8" }, // Iのみ
  { label: "旧 移行準備支援(II)", value: "9" }, // Iのみ
  { label: "欠席", value: "10" }
];

// 移行型サービス提供の状況 (SELECTフィールド利用想定)
export const IKOU_IN_OUT_RECORDS_STATUS_MODAL: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用支援", value: "7" },
  { label: "移行準備支援I", value: "8" },
  { label: "移行準備支援II", value: "9" },
  { label: "欠席（加算なし）", value: "10" }
];

// 移行型サービス提供の状況 (SELECTフィールド利用想定)
// 令和3年度報酬改定で追加
export const IKOU_IN_OUT_RECORDS_STATUS_MODAL_202104: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用支援", value: "7" },
  { label: "移行準備支援（I）", value: "8" },
  { label: "旧 移行準備支援（II）（加算なし）", value: "9" },
  { label: "欠席（加算なし）", value: "10" }
];

// AB型サービス提供の状況 (SELECTフィールド利用想定)
export const AB_IN_OUT_RECORDS_STATUS_MODAL: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用支援", value: "7" },
  { label: "施設外就労", value: "3" },
  { label: "施設外支援", value: "4" },
  { label: "欠席（加算なし）", value: "10" }
];

// 利用状況の詳細モーダルで使用
export const IAB_SUMMARY_SERVICE_STATUS = {
  USUAL_PLACE: { label: "通所", value: 2 },
  OFFSITE_WORK: { label: "施設外就労", value: 3 },
  OFFSITE_SUPPORT: { label: "施設外支援", value: 4 },
  WHEN_ABSENT: { label: "欠席時対応", value: 5 },
  VISIT: { label: "訪問", value: 6 },
  TRIAL_USE_SUPPORT: { label: "体験利用支援", value: 7 },
  SUPPORT_IKOU_1: { label: "移行準備支援体制加算(I)", value: 8 },
  SUPPORT_IKOU_2: { label: "移行準備支援体制加算(II)", value: 9 },
  ABSENT: { label: "欠席", value: 10 }
};

// 利用状況の詳細モーダルで使用
// 令和3年度報酬改定で追加
export const IAB_SUMMARY_SERVICE_STATUS_202104 = {
  USUAL_PLACE: { label: "通所", value: 2 },
  OFFSITE_WORK: { label: "施設外就労", value: 3 },
  OFFSITE_SUPPORT: { label: "施設外支援", value: 4 },
  WHEN_ABSENT: { label: "欠席時対応", value: 5 },
  VISIT: { label: "訪問", value: 6 },
  TRIAL_USE_SUPPORT: { label: "体験利用支援", value: 7 },
  SUPPORT_IKOU_1: { label: "移行準備支援(I)", value: 8 },
  SUPPORT_IKOU_2: { label: "旧 移行準備支援(II)", value: 9 },
  ABSENT: { label: "欠席", value: 10 }
};

// 各種提供サービスや作業時間を設定できるサービス提供の状況
export const IAB_OPTION_SERVICE_STATUS = [
  { label: "通所", value: 2 },
  { label: "施設外就労", value: 3 },
  { label: "施設外支援", value: 4 },
  { label: "移行準備支援Ⅰ", value: 8 },
  { label: "移行準備支援Ⅱ", value: 9 }
];

// 利用実績編集モーダル 地域連携会議実施加算の選択肢
// 令和6年度報酬改定で追加、移行のみ使用
export const REGIONAL_COLLABORATION_MEETING_ITEMS = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

// 目標タイプ ( A 型専用 )
export enum SprintType {
  LONG = 1, // 長期
  SHORT = 2, // 短期
  INDIVIDUAL = 3 // 個別目標
}

// 振り返り目標達成度合い
export const ACHIEVEMENT_STATE = ["未達成", "達成", "一部達成"];

// 振り返りステータス
export const DONE_STATE = ["未実施", "実施", "一部実施"];

// 喀痰吸引等に係る指導実施のチェックが出来るようになる、医療連携体制加算の値
export const ENABLE_MEDICAL_COOPERATION_VALUES = ["1", "2", "3", "4", "6"];

// custom_recordのdefault_itemの対応表（アセスメント）
export const ASSESSMENT_CUSTOM_RECORD_DEFAULT_ITEM_IKOU = {
  evaluation_period: 22
};

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportMonthly";
/** IABの報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V201910,
      url: FACILITY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_DAILY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportMonthly: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_MONTHLY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_MONTHLY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_MONTHLY
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V201910,
      url: USERS_ID_201910.replace(":id", userId)
    }
  ]
};
