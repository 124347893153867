import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/inOutConsultationResults/postInOutConsultationResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCHIIKIIKOUserStarted = () =>
  ({ type: types.FETCH_CHIIKIIKO_MONTHLY_STARTED } as const);
export const fetchCHIIKIIKOUser = (res: types.ReportState["reportMonthly"]) =>
  ({ type: types.FETCH_CHIIKIIKO_MONTHLY, payload: res } as const);
export const fetchCHIIKIIKOUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_CHIIKIIKO_MONTHLY_FAILED, error: err } as const);
export const postCHIIKIIKOReportStarted = () =>
  ({ type: types.POST_CHIIKIIKO_REPORT_STARTED } as const);
export const postCHIIKIIKOReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_CHIIKIIKO_REPORT_MONTHLY, payload: req } as const);
export const postCHIIKIIKOReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_CHIIKIIKO_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCHIIKIIKOUserStarted>
  | ReturnType<typeof fetchCHIIKIIKOUser>
  | ReturnType<typeof fetchCHIIKIIKOUserFailed>
  | ReturnType<typeof postCHIIKIIKOReportStarted>
  | ReturnType<typeof postCHIIKIIKOReportUser>
  | ReturnType<typeof postCHIIKIIKOReportFailed>;
