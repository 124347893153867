export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LiST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

// サービス内容
export const IDOSHIEN_STATUS_LIST = {
  NONE: { label: "-", value: "1" },
  INDIVIDUAL: { label: "個別支援", value: "2" },
  GROUP: { label: "グループ支援", value: "3" },
  VEHICLE_TRANSPORT: { label: "車両移送支援", value: "4" },
  COMMUTE: { label: "通学・通所支援", value: "5" },
  OTHER: { label: "その他", value: "6" }
};

// サービス内容(記録画面詳細用)
export const IDOSHIEN_DISPLAY_STATUS_LIST_DETAIL = [
  { label: "選択してください", value: "1" },
  { label: "個別支援", value: "2" },
  { label: "グループ支援", value: "3" },
  { label: "車両移送支援", value: "4" },
  { label: "通学・通所支援", value: "5" },
  { label: "その他", value: "6" }
];

export const IDOSHIEN_STATUS_SELECT_LIST = [
  IDOSHIEN_STATUS_LIST.NONE,
  IDOSHIEN_STATUS_LIST.INDIVIDUAL,
  IDOSHIEN_STATUS_LIST.GROUP,
  IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT,
  IDOSHIEN_STATUS_LIST.COMMUTE,
  IDOSHIEN_STATUS_LIST.OTHER
];

// モバイル版 サービス提供記録一覧用
export const IDOSHIEN_DISPLAY_STATUS_LIST_RECORD = IDOSHIEN_STATUS_SELECT_LIST;

// サービス内容(表示用)
export const IDOSHIEN_DISPLAY_STATUS_LIST = IDOSHIEN_STATUS_SELECT_LIST;

// サービス内容(サービスコード検索用)
export const IDOSHIEN_DISPLAY_STATUS_LIST_SEARCH = [
  { label: "-", value: "" },
  { label: "個別支援", value: "1" },
  { label: "グループ支援", value: "2" },
  { label: "車両移送支援", value: "3" },
  { label: "通学・通所支援", value: "4" },
  { label: "その他", value: "5" },
  { label: "加算", value: "6" }
];

// 身体介護(サービスコード検索用)
export const PHYSICAL_CARE_LIST = [
  { label: "-", value: "" },
  { label: "なし", value: "0" },
  { label: "あり", value: "1" }
];

// 算定時間(サービスコード検索用)
export const CALCULATION_HOURS_LIST = [
  { label: "-", value: "" },
  { label: "0.5", value: "1" },
  { label: "1", value: "2" },
  { label: "1.5", value: "3" },
  { label: "2", value: "4" },
  { label: "2.5", value: "5" },
  { label: "3", value: "6" },
  { label: "3.5", value: "7" },
  { label: "4", value: "8" },
  { label: "4.5", value: "9" },
  { label: "5", value: "10" },
  { label: "5.5", value: "11" },
  { label: "6", value: "12" },
  { label: "6.5", value: "13" },
  { label: "7", value: "14" },
  { label: "7.5", value: "15" },
  { label: "8", value: "16" },
  { label: "8.5", value: "17" },
  { label: "9", value: "18" },
  { label: "9.5", value: "19" },
  { label: "10", value: "20" },
  { label: "10.5", value: "21" },
  { label: "11", value: "22" },
  { label: "11.5", value: "23" },
  { label: "12", value: "24" },
  { label: "12.5", value: "25" },
  { label: "13", value: "26" },
  { label: "13.5", value: "27" },
  { label: "14", value: "28" },
  { label: "14.5", value: "29" },
  { label: "15", value: "30" },
  { label: "15.5", value: "31" },
  { label: "16", value: "32" },
  { label: "16.5", value: "33" },
  { label: "17", value: "34" },
  { label: "17.5", value: "35" },
  { label: "18", value: "36" },
  { label: "18.5", value: "37" },
  { label: "19", value: "38" },
  { label: "19.5", value: "39" },
  { label: "20", value: "40" },
  { label: "20.5", value: "41" },
  { label: "21", value: "42" },
  { label: "21.5", value: "43" },
  { label: "22", value: "44" },
  { label: "22.5", value: "45" },
  { label: "23", value: "46" },
  { label: "23.5", value: "47" },
  { label: "24時間以上", value: "48" }
];

// 算定時間_日中(サービスコード検索用)
export const DAYTIME_HOURS_LIST = [
  { label: "-", value: "" },
  { label: "0.5", value: "1" },
  { label: "1", value: "2" },
  { label: "1.5", value: "3" },
  { label: "2", value: "4" },
  { label: "2.5", value: "5" },
  { label: "3", value: "6" },
  { label: "3.5", value: "7" },
  { label: "4", value: "8" },
  { label: "4.5", value: "9" },
  { label: "5", value: "10" },
  { label: "5.5", value: "11" },
  { label: "6", value: "12" },
  { label: "6.5", value: "13" },
  { label: "7", value: "14" },
  { label: "7.5", value: "15" },
  { label: "8", value: "16" },
  { label: "8.5", value: "17" },
  { label: "9", value: "18" },
  { label: "9.5", value: "19" },
  { label: "10時間以上", value: "20" }
];

// 算定時間_夜間(サービスコード検索用)
export const NIGHTTIME_HOURS_LIST = [
  { label: "-", value: "" },
  { label: "0.5", value: "1" },
  { label: "1", value: "2" },
  { label: "1.5", value: "3" },
  { label: "2", value: "4" },
  { label: "2.5", value: "5" },
  { label: "3", value: "6" },
  { label: "3.5", value: "7" },
  { label: "4時間以上", value: "8" }
];

// 算定時間_深夜(サービスコード検索用)
export const LATE_NIGHT_HOURS_LIST = [
  { label: "-", value: "" },
  { label: "0.5", value: "1" },
  { label: "1", value: "2" },
  { label: "1.5", value: "3" },
  { label: "2", value: "4" },
  { label: "2.5", value: "5" },
  { label: "3", value: "6" },
  { label: "3.5", value: "7" },
  { label: "4", value: "8" },
  { label: "4.5", value: "9" },
  { label: "5", value: "10" },
  { label: "5.5", value: "11" },
  { label: "6時間以上", value: "12" }
];

// 算定時間_早朝(サービスコード検索用)
export const EARLY_MORNING_HOURS_LIST = [
  { label: "-", value: "" },
  { label: "0.5", value: "1" },
  { label: "1", value: "2" },
  { label: "1.5", value: "3" },
  { label: "2時間以上", value: "4" }
];

export const IDOSHIEN_BASE_LIST = {
  NONE: { label: "-", value: "" },
  SELECT: { label: "選択してください", value: "0" }
};

export const IDOSHIEN_ACTION_CLASS_LIST = {
  DRIVE: { label: "運転", value: "1" },
  NONE: { label: "空き", value: "2" }
};

export const IDOSHIEN_ACTION_CLASS_SELECT_LIST = [
  IDOSHIEN_ACTION_CLASS_LIST.DRIVE,
  IDOSHIEN_ACTION_CLASS_LIST.NONE
];

export const IDOSHIEN_MEMBER_LIST = {
  ONE: { label: "1人", value: "1" },
  TWO: { label: "2人", value: "2" },
  THREE: { label: "3人", value: "3" },
  FOUR: { label: "4人", value: "4" },
  FIVE: { label: "5人", value: "5" }
};

export const IDOSHIEN_MEMBER_SELECT_LIST = [
  IDOSHIEN_MEMBER_LIST.ONE,
  IDOSHIEN_MEMBER_LIST.TWO
];

export const IDOSHIEN_GROUP_MEMBER_SELECT_LIST = [
  IDOSHIEN_MEMBER_LIST.ONE,
  IDOSHIEN_MEMBER_LIST.TWO,
  IDOSHIEN_MEMBER_LIST.THREE,
  IDOSHIEN_MEMBER_LIST.FOUR,
  IDOSHIEN_MEMBER_LIST.FIVE
];

export const IDOSHIEN_INPUT_CLASS_LIST = {
  PLAN: { label: "移動支援計画", value: "1" },
  RESULT: {
    label: "サービス提供実績",
    value: "2"
  },
  CREATE: {
    label: "移動支援計画/サービス提供実績",
    value: "0"
  }
};

export const IDOSHIEN_INPUT_CLASS_SELECT_LIST = [
  IDOSHIEN_INPUT_CLASS_LIST.PLAN,
  IDOSHIEN_INPUT_CLASS_LIST.RESULT
];

export const REPORT_FILTER_LIST = {
  NONE: { label: "すべての利用者", value: "1" },
  FILTER: { label: "計画または実績あり", value: "2" }
};

export const REPORT_FILTER_SELECT_LIST = [
  REPORT_FILTER_LIST.NONE,
  REPORT_FILTER_LIST.FILTER
];

export const SERVICE_TIME_ZONE = {
  MIDNIGHT: { start: "22:00", startClass: "0", end: "06:00", endClass: "1" },
  MORNING: { start: "06:00", startClass: "0", end: "08:00", endClass: "0" },
  DAYTIME: { start: "08:00", startClass: "0", end: "18:00", endClass: "0" },
  NIGHT: { start: "18:00", startClass: "0", end: "22:00", endClass: "0" }
};

export const SERVICE_TIME_ZONE_LIST = [
  SERVICE_TIME_ZONE.MIDNIGHT,
  SERVICE_TIME_ZONE.MORNING,
  SERVICE_TIME_ZONE.DAYTIME,
  SERVICE_TIME_ZONE.NIGHT
];

export const TIME_CLASS = {
  TODAY: "0",
  NEXT_DAY: "1"
};

export const TIME_CLASS_LIST = [
  { label: "当日", value: TIME_CLASS.TODAY },
  { label: "翌日", value: TIME_CLASS.NEXT_DAY }
];

export const IDOSHIEN_DISPATCH_NO_LIST = [
  { label: "①", value: "1" },
  { label: "②", value: "2" }
];

export const ERROR_MESSAGE = {
  TIME_AFTER_START: "終了時間は開始時間より後の時間を入力してください",
  OVER_SERVICE_END_DATE:
    "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください",
  NOT_OVERLAP_TIME_NEW:
    "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  NOT_OVERLAP_TIME_EDIT_1:
    "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の移動支援計画として入力してください。",
  NOT_OVERLAP_TIME_EDIT_2:
    "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。",
  OUT_OF_TIME_RANGE: "空き/運転時間は提供時間の範囲内で入力してください",
  OVERLAP_TIME: "空き/運転時間は同一時間帯で重複しないように入力してください",
  NO_RECORD_MESSAGE_SERVICE_DELIVERY:
    "計画がありません。利用実績画面から入力後、ご利用ください。",
  NO_USER_MESSAGE_SERVICE_DELIVERY:
    "利用者が登録されていません。利用者情報画面で登録後、ご利用ください。"
};

export const IN_TIME_VALIDATION_LIST = [
  ERROR_MESSAGE.NOT_OVERLAP_TIME_NEW,
  ERROR_MESSAGE.NOT_OVERLAP_TIME_EDIT_1,
  ERROR_MESSAGE.NOT_OVERLAP_TIME_EDIT_2
];

export const OUT_TIME_VALIDATION_LIST = [
  ERROR_MESSAGE.TIME_AFTER_START,
  ERROR_MESSAGE.OVER_SERVICE_END_DATE,
  ERROR_MESSAGE.NOT_OVERLAP_TIME_NEW,
  ERROR_MESSAGE.NOT_OVERLAP_TIME_EDIT_1,
  ERROR_MESSAGE.NOT_OVERLAP_TIME_EDIT_2
];

export const DETAIL_IN_TIME_VALIDATION_LIST = [
  ERROR_MESSAGE.OUT_OF_TIME_RANGE,
  ERROR_MESSAGE.OVERLAP_TIME
];

export const DETAIL_OUT_TIME_VALIDATION_LIST = [
  ERROR_MESSAGE.TIME_AFTER_START,
  ERROR_MESSAGE.OUT_OF_TIME_RANGE,
  ERROR_MESSAGE.OVERLAP_TIME
];

export const PROCESS_TYPE = {
  ALL: 1,
  ALL_ERROR: 2,
  NEW_ONLY: 3
};

export const INOUT_RESULTS_COPY_ERROR_STATUS = 17;

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

// custom_recordのdefault_itemの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN = {
  cost: 1,
  deposit: 2,
  vital: 3,
  user_state: 4,
  eat_assistant: 5,
  meal: 6,
  hydration: 7,
  mediator: 8,
  change_position: 9,
  medicine_assistant: 10,
  other_assistant: 11,
  staff_comment: 12,
  room_exit: 13,
  other: 14
};

/** サービス提供記録の並び替え固定のためのdefault_item番号 */
export const SERVICE_DELIVERY_FIX_ORDER_DEFAULT_ITEM = {
  // 金銭管理
  1: [
    SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.cost,
    SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit
  ],
  // 利用者の様子・状態
  2: [],
  // サービス内容
  3: [],
  // 特記事項
  4: [],
  // その他・備考
  5: []
};

export const START_ADD_YEAR_TO = 1;
export const END_ADD_YEAR_TO = 5;

/** 自治体情報 請求単位 単価 */
export const MUNICIPALITY_BILLING_UNIT_PRICE = "0";
/** 自治体情報 請求単位 単位 */
export const MUNICIPALITY_BILLING_UNIT_UNIT = "1";
/** 自治体情報 請求単位 選択肢 */
export const MUNICIPALITY_BILLING_UNIT_OPTIONS = [
  {
    label: "単価",
    value: MUNICIPALITY_BILLING_UNIT_PRICE
  },
  {
    label: "単位",
    value: MUNICIPALITY_BILLING_UNIT_UNIT
  }
];

// サービスコードモーダルの１ページに表示するデータ数
export const SERVICE_CODE_DIALOG_MAX_COUNT = 20;

export const DISPLAY_STATUS_OTHER = 6;

export const MAX_PRACTITIONER_DETAILS = 10;

export const DEFAULT_ROUND_UP_MINUTE = 30;

export const CALCULATION_TIME_UPPER_LIMIT = 30;

/** 自治体情報の更新でエラーが発生した際のAPIから返却されるエラーコード */
export const MUNICIPALITY_POST_ERROR_CODE = {
  fileError: 18
};
