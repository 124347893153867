import * as React from "react";

import _has from "lodash-es/has";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import { UserDetailRecord } from "@components/organisms/mgr/Cseg/record/UserDetailRecord";
import { RecordHeader as KEIKAKUSODANRecordHeader } from "@components/organisms/mgr/KEIKAKUSODAN/record/RecordHeader";
import { RecordHeader as CHIIKIIKORecordHeader } from "@components/organisms/mgr/CHIIKIIKO/record/RecordHeader";
import { RecordHeader as CHIIKITEICHAKURecordHeader } from "@components/organisms/mgr/CHIIKITEICHAKU/record/RecordHeader";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AdminTemplate from "@components/templates/AdminTemplate";
import { RECORD_USERS_SUMMARY } from "@constants/url";
import { SupportRecordFormValues } from "@initialize/mgr/Cseg/record/support/initialValues";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import dispatches from "@stores/dispatches";
import { SupportRecordUserState } from "@stores/domain/mgr/Cseg/support/types";
// 種別ごとのtype
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { AppState } from "@stores/type";
import { dateToObject } from "@utils/date";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { FacilityType } from "@constants/variables";
import { CommonPrintModalWithStaffComment } from "@components/organisms/mgr/common/record/CommonPrintModalWithStaffComment";
import { SnackbarParams } from "@stores/ui/type";
import {
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";

const styles = (): StyleRules =>
  createStyles({
    pageWrapper: {
      padding: 16
    },
    stickyWrapper: {
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    planListWrapper: {
      marginTop: "32px",
      background: "#fff",
      borderRadius: "4px"
    },
    headerInfoWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "31px 32px 20px 32px",
      marginTop: "2px",
      background: "#fff"
    },
    printButton: {
      width: 120,
      height: 36,
      marginLeft: 8
    }
  });

type UsersInFacilityStateType =
  | UsersInFacilityStateKEIKAKUSODAN["user"]
  | UsersInFacilityStateCHIIKIIKO["user"]
  | UsersInFacilityStateCHIIKITEICHAKU["user"];

type OwnProps = RouteComponentProps<{
  uifId: string;
  yyyy?: string;
  mm?: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  facilityType: FacilityType;
  user: UsersInFacilityStateType;
  supportRecord: SupportRecordUserState;
};

const checkIsUserKeikakusodan = (
  user: UsersInFacilityStateType
): user is UsersInFacilityStateKEIKAKUSODAN["user"] => {
  return _has(user, "user_in_facility_keikakusodan");
};

type DispatchProps = {
  fetchOneUser: (uifId: string, facilityType: FacilityType) => void;
  fetchHoliday: (date: string) => void;
  fetchStaff: () => void;
  fetchSupportRecordUser: (uifId: string, year: string, month: string) => void;
  postSupportRecordUser: (
    uifId: string,
    editingRecordDate: string,
    params: SupportRecordFormValues[],
    initialValues: SupportRecordFormValues[]
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const UserDetailCore = (props: Props): JSX.Element => {
  const { classes, facilityType, supportRecord, user } = props;
  const { uifId, yyyy, mm } = props.match.params;

  const { name_sei = "", name_mei = "" } = user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;

  const pageName = "支援記録";
  const pathList = [{ pathName: "利用者ごと", path: RECORD_USERS_SUMMARY }];

  let dateBeginInService = "";
  if (checkIsUserKeikakusodan(user)) {
    // 計画相談と障害児相談判定
    const typeDailyConsultation =
      user.user_in_facility_keikakusodan &&
      user.user_in_facility_keikakusodan.type_daily_consultation;
    if (typeDailyConsultation === TYPE_CONSULTATION_KEIKAKUSODAN) {
      const keikakusodanFacility = findUserInFacilityKeikakusodan(user);
      if (keikakusodanFacility) {
        dateBeginInService = keikakusodanFacility.date_begin_in_service;
      }
    }
    if (typeDailyConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
      const shogaijiFacility = findUserInFacilityShogaijisodan(user);
      if (shogaijiFacility) {
        dateBeginInService = shogaijiFacility.date_begin_in_service;
      }
    }
  } else if (user.user_in_facility.date_begin_in_service) {
    dateBeginInService = user.user_in_facility.date_begin_in_service;
  }

  const today = new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const [calendarSelectedDate, setCalendarSelectedDate] = React.useState(
    !!yyyy && !!mm ? new Date(Number(yyyy), Number(mm) - 1, 1) : today
  );

  const [isDisabledPrintButton, setIsDisabledPrintButton] = React.useState(
    false
  );

  const [editTargetDate, setEditTargetDate] = React.useState<
    string | undefined
  >();

  const onClickCalendar = React.useCallback((targetDate: Date): void => {
    const { year, month } = dateToObject(targetDate);
    setCalendarSelectedDate(targetDate);
    props.history.replace(
      `/record/${uifId}/support/${year}/${month.padStart(2, "0")}`
    );
  }, []);

  // 印刷モーダル
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState<boolean>(
    false
  );
  const onOpenPrintModal = (): void => {
    setIsOpenPrintModal(true);
  };
  const onClosePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };
  const onConfirmPrintModal = (paramsOption: string): void => {
    const { year, month } = dateToObject(calendarSelectedDate);
    props.history.push(
      `/record/print/${uifId}/support/${year}/${month}${paramsOption}`
    );
  };

  React.useEffect(() => {
    props.fetchOneUser(uifId, facilityType);
    props.fetchStaff();
  }, [uifId, facilityType]);

  React.useEffect(() => {
    const { year, month } = dateToObject(calendarSelectedDate);
    const [yearParam, monthParam] =
      !!yyyy && !!mm ? [yyyy, mm] : [year, month.padStart(2, "0")];
    props.fetchSupportRecordUser(uifId, yearParam, monthParam);
    props.fetchHoliday(`${yearParam}${monthParam}`);
  }, [calendarSelectedDate, yyyy, mm]);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.pageWrapper}>
        <UserInfoRecord user={props.user} />
        <div className={classes.stickyWrapper}>
          <div className={classes.planListWrapper}>
            {facilityType === FacilityType.KEIKAKUSODAN && (
              <KEIKAKUSODANRecordHeader
                uifId={uifId}
                recordType="support"
                isEditing={!!editTargetDate}
                history={props.history}
                pageName={pageName}
                userName={userName}
                button={<></>}
              />
            )}
            {facilityType === FacilityType.CHIIKIIKO && (
              <CHIIKIIKORecordHeader
                uifId={uifId}
                recordType="support"
                isEditing={!!editTargetDate}
                history={props.history}
                pageName={pageName}
                userName={userName}
                button={<></>}
              />
            )}
            {facilityType === FacilityType.CHIIKITEICHAKU && (
              <CHIIKITEICHAKURecordHeader
                uifId={uifId}
                recordType="support"
                isEditing={!!editTargetDate}
                history={props.history}
                pageName={pageName}
                userName={userName}
                button={<></>}
              />
            )}
          </div>
          <div className={classes.headerInfoWrapper}>
            <UnEditableWrapper unEditable={!!editTargetDate}>
              <DateSelectButtonsMonthly
                selectedMonth={calendarSelectedDate}
                min={calendarMinDate}
                max={today}
                onClickSubmit={onClickCalendar}
              />
            </UnEditableWrapper>
            <KnowbeButton
              className={classes.printButton}
              disabled={isDisabledPrintButton || !!editTargetDate}
              onClick={onOpenPrintModal}
            >
              印刷
            </KnowbeButton>
          </div>
        </div>
        <UserDetailRecord
          uifId={uifId}
          facilityType={facilityType}
          supportRecord={supportRecord}
          calendarSelectedDate={calendarSelectedDate}
          dateBeginInService={dateBeginInService}
          setIsDisabledPrintButton={setIsDisabledPrintButton}
          editTargetDate={editTargetDate}
          setEditTargetDate={setEditTargetDate}
          stopHistory={props.stopHistory}
          postSupportRecordUser={props.postSupportRecordUser}
          fetchSupportRecordUser={props.fetchSupportRecordUser}
          showSnackbar={props.showSnackbar}
        />
      </div>
      {/* 印刷モーダル */}
      <CommonPrintModalWithStaffComment
        title="支援記録"
        isOpenPrintModal={isOpenPrintModal}
        onClosePrintModal={onClosePrintModal}
        onConfirmPrintModal={onConfirmPrintModal}
        staffLabel="職員考察"
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    user: state[facilityType].userInFacility.user,
    supportRecord: state.Cseg.supportRecordUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, holiday, staffDispatcher } = dispatches;
  const uiDispatcher = dispatches.uiDispatch(dispatch);

  return {
    fetchOneUser: (uifId: string, facilityType: FacilityType): void => {
      dispatches[facilityType]
        .userInFacilityDispatcher(dispatch)
        .fetchOne(uifId);
    },
    fetchHoliday: (date: string): void => {
      holiday(dispatch).fetchHoliday(date);
    },
    fetchStaff: (): void => {
      staffDispatcher(dispatch).fetch();
    },
    fetchSupportRecordUser: (
      uifId: string,
      year: string,
      month: string
    ): void => {
      Cseg.supportRecordUserDispatcher(dispatch).fetchSupportRecordUser(
        uifId,
        year,
        month
      );
    },
    postSupportRecordUser: (
      uifId,
      editingRecordDate,
      params,
      initialValues
    ): Promise<void> => {
      return Cseg.supportRecordUserDispatcher(dispatch).postSupportRecordUser(
        uifId,
        editingRecordDate,
        params,
        initialValues
      );
    },
    stopHistory: uiDispatcher.stopHistory,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatcher.snackbar(params)
  };
};

export const UserDetail = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UserDetailCore)
);
