import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 指定ユーザーのサービス等利用計画、地域移行計画の情報を削除する
 * @param uifId ユーザーID
 * @param consultationId 計画ID
 */
export const deleteConsultation = async (
  uifId: string,
  consultationId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/consultation/${consultationId}`;
  return request.delete(url);
};
