import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";

import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { AppDownloadState } from "@stores/ui/download/type";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import JissekiPrintPreview from "@components/organisms/download/print/JissekiPrintPreview";
import JissekiPrintPreview201910 from "@components/v201910/organisms/download/print/JissekiPrintPreview";
import { JissekiPrintPreview202104 } from "@components/v202104/organisms/download/print/JissekiPrintPreview";
import {
  FacilityType,
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104
} from "@constants/variables";
import { findVersionFromDate } from "@utils/invoiceVersion/findVersionFromDate";

type StateProps = {
  appDownload: AppDownloadState;
  facilityType: FacilityType;
};

type DispatchProps = {
  fetchInvoice: (year: string, month: string, excludeUserIds: number[]) => void;
  fetchInvoiceV201910: (
    year: string,
    month: string,
    excludeUserIds: number[]
  ) => void;
  fetchInvoiceV202104: (
    year: string,
    month: string,
    excludeUserIds: number[]
  ) => void;
};

type Props = StateProps &
  DispatchProps &
  RouteComponentProps<{ year: string; month: string }>;

/**
 * 実績記録票のプリント画面
 */
const JissekiPrint = (props: Props): JSX.Element => {
  const {
    match,
    history,
    location,
    facilityType,
    fetchInvoiceV201910,
    fetchInvoiceV202104,
    fetchInvoice
  } = props;
  const { year, month } = match.params;
  const { excludedUserIds } = props.appDownload;
  const previousMonthOfSelectedMonth = new Date(+year, +month - 1);

  const version = findVersionFromDate(
    previousMonthOfSelectedMonth,
    facilityType
  );

  const shouldGoToV201910 = version === INVOICE_VERSION_V201910;
  const shouldGoToV202104 = version === INVOICE_VERSION_V202104;
  const shouldGoToLatestVersion = version === INVOICE_VERSION_CURRENT;

  React.useEffect(() => {
    if (shouldGoToV201910) {
      fetchInvoiceV201910(year, month, excludedUserIds);
    } else if (shouldGoToV202104) {
      fetchInvoiceV202104(year, month, excludedUserIds);
    } else {
      fetchInvoice(year, month, excludedUserIds);
    }
  }, []);

  return (
    <AdminTemplate pageName="請求 > 実績記録票">
      <PrintPreviewTemplate history={history} location={location} match={match}>
        {shouldGoToV201910 && <JissekiPrintPreview201910 />}
        {shouldGoToV202104 && <JissekiPrintPreview202104 />}
        {shouldGoToLatestVersion && <JissekiPrintPreview />}
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  appDownload: state.appDownload,
  facilityType: state.user.facility_type
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch, v201910, v202104 } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);
  const invoiceDispatchedV201910 = v201910.invoiceDispatch(dispatch);
  const invoiceDispatchedV202104 = v202104.invoiceDispatch(dispatch);
  return {
    fetchInvoice: (
      year: string,
      month: string,
      excludeUserIds: number[]
    ): void => {
      invoiceDispatched.downloadJissekiJson(year, month, excludeUserIds);
    },
    fetchInvoiceV201910: (
      year: string,
      month: string,
      excludeUserIds: number[]
    ): void => {
      invoiceDispatchedV201910.downloadJissekiJson(year, month, excludeUserIds);
    },
    fetchInvoiceV202104: (
      year: string,
      month: string,
      excludeUserIds: number[]
    ): void => {
      invoiceDispatchedV202104.downloadJissekiJson(year, month, excludeUserIds);
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JissekiPrint);
