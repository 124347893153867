/**
 * 必須チェック（任意のエラーメッセージ）
 * @param value
 */
const requiredAnyMessage = (
  value: string,
  message: string
): string | undefined => {
  let errorMessage;
  if (value === "") {
    errorMessage = message;
  }
  return errorMessage;
};

export default requiredAnyMessage;
