import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    message: {
      letterSpacing: 0.4,
      lineHeight: "18px",
      fontWeight: "bold"
    },
    textIndent: {
      paddingLeft: "1em",
      textIndent: "-1em"
    },
    list: {
      paddingLeft: 20,
      listStyle: "none"
    },
    listStyleNone: {
      paddingLeft: 0,
      listStyle: "none"
    },
    listOrder: {
      display: "inline-block",
      width: "10%",
      verticalAlign: "top"
    },
    listContent: {
      display: "inline-block",
      width: "80%"
    },
    textIndentMarginTop: {
      marginBottom: 0,
      paddingLeft: "1em",
      textIndent: "-1em"
    },
    textIndentNonMargin: {
      margin: 0,
      paddingLeft: "1em",
      textIndent: "-1em"
    },
    notMarginTop: {
      marginTop: 0
    },
    notFontWeight: {
      fontWeight: "normal"
    }
  });

type Props = WithStyles<typeof styles> & {
  name: string;
};

// 事業所情報-[事業者番号]のtips
const officeNumber = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      事業所番号を変更する場合、knowbe業務支援サポートデスクにお問い合わせください。
    </p>
    <p>
      問い合わせ方法はメニューの「お問い合わせ」をクリックし、問い合わせフォームに変更したい事業所番号を入力して送信してください。
    </p>
  </div>
);

// 事業所情報-[一体的な運営を行なっている]のtips
const satellite = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      各都道府県で使用している介護給付費等の算定に係る届出書類において、
      同一敷地内（近接地を含む）にある共同生活住居のこと。
    </p>
  </div>
);

// 事業所情報(生活介護)-[一体的な運営を行なっている]のtips
const satelliteSEIKATSUKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>下記要件を満たした場合、一体的な運営とみなされる</p>
    <ul className={classes.listStyleNone}>
      <li>【人員配置】</li>
      <li>
        一つの事業所としての人員配置のほか、直接サービス提供職員はそれぞれ事業所ごとに専従常勤職員を１以上配置していること
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【事業運営】</li>
      <li>
        <ul className={classes.listStyleNone}>
          <li>
            <span className={classes.listOrder}>①</span>
            <span className={classes.listContent}>
              利用申込みに係る調整、職員に対する技術指導等が一体的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>②</span>
            <span className={classes.listContent}>
              事業所間で相互支援の体制があること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>③</span>
            <span className={classes.listContent}>
              事業の目的や運営方針、営業日・営業時間、利用料等の運営規程が一本化されていること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>④</span>
            <span className={classes.listContent}>
              職員の勤務体制、勤務内容等の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑤</span>
            <span className={classes.listContent}>
              人事、給与・福利厚生、勤務条件等に関する職員の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑥</span>
            <span className={classes.listContent}>
              事業所間の会計管理が一元化されていること
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【地域的範囲】</li>
      <li>
        主たる事業所と従たる事業所が同一の日常生活圏域にあって、サービス管理責任者の業務遂行に支障の無い範囲（通常の移動手段により概ね30分以内で移動可能な範囲を目安とする）であること
      </li>
    </ul>
  </div>
);
// 事業所情報(就労定着支援)-[一体的な運営を行なっている]のtips
const satelliteSHUROTEICHAKU = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>下記要件を満たした場合、一体的な運営とみなされる</p>
    <ul className={classes.listStyleNone}>
      <li>【人員配置】</li>
      <li>
        一つの事業所としての人員配置のほか、直接サービス提供職員はそれぞれ事業所ごとに専従常勤職員を１以上配置していること
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【事業運営】</li>
      <li>
        <ul className={classes.listStyleNone}>
          <li>
            <span className={classes.listOrder}>①</span>
            <span className={classes.listContent}>
              利用申込みに係る調整、職員に対する技術指導等が一体的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>②</span>
            <span className={classes.listContent}>
              事業所間で相互支援の体制があること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>③</span>
            <span className={classes.listContent}>
              事業の目的や運営方針、営業日・営業時間、利用料等の運営規程が一本化されていること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>④</span>
            <span className={classes.listContent}>
              職員の勤務体制、勤務内容等の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑤</span>
            <span className={classes.listContent}>
              人事、給与・福利厚生、勤務条件等に関する職員の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑥</span>
            <span className={classes.listContent}>
              事業所間の会計管理が一元化されていること
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【地域的範囲】</li>
      <li>
        主たる事業所と従たる事業所が同一の日常生活圏域にあって、サービス管理責任者の業務遂行に支障の無い範囲（通常の移動手段により概ね30分以内で移動可能な範囲を目安とする）であること
      </li>
    </ul>
  </div>
);

// 事業所情報-[主従たる事業所]のtips
const masterSubordinate = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>下記要件を満たした場合、一体的な運営とみなされる</p>
    <ul className={classes.listStyleNone}>
      <li>【人員配置】</li>
      <li>
        一つの事業所としての人員配置のほか、直接サービス提供職員はそれぞれ事業所ごとに専従常勤職員を１以上配置していること
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【事業運営】</li>
      <li>
        <ul className={classes.listStyleNone}>
          <li>
            <span className={classes.listOrder}>①</span>
            <span className={classes.listContent}>
              利用申込みに係る調整、職員に対する技術指導等が一体的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>②</span>
            <span className={classes.listContent}>
              事業所間で相互支援の体制があること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>③</span>
            <span className={classes.listContent}>
              事業の目的や運営方針、営業日・営業時間、利用料等の運営規程が一本化されていること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>④</span>
            <span className={classes.listContent}>
              職員の勤務体制、勤務内容等の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑤</span>
            <span className={classes.listContent}>
              人事、給与・福利厚生、勤務条件等に関する職員の管理方法が一元的であること
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>⑥</span>
            <span className={classes.listContent}>
              事業所間の会計管理が一元化されていること
            </span>
          </li>
        </ul>
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【地域的範囲】</li>
      <li>
        主たる事業所と従たる事業所が同一の日常生活圏域にあって、サービス管理責任者の業務遂行に支障の無い範囲（通常の移動手段により概ね30分以内で移動可能な範囲を目安とする）であること
      </li>
    </ul>
  </div>
);

// 事業所情報-[夜勤職員の欠員]のtips
const lackOfNightShiftStaff = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>夜勤職員欠員の減算について</p>
    <ul className={classes.listStyleNone}>
      <li>【減算の適応月と単位】</li>
      <p className={classes.notMarginTop}>
        次のいずれかの場合に、その翌月に利用者全員につき本体報酬を95%（5%減算）に減算する。
      </p>
      <li className={classes.textIndentNonMargin}>
        ・夜勤時間帯において、基準を満たさない状態が連続2日以上発生
      </li>
      <li className={classes.textIndentNonMargin}>
        ・夜勤時間帯において、基準を満たさない状態が4日以上発生（夜勤時間帯：22時から翌5時までを含む連続16時間をいい、施設ごとに設定する。）
      </li>
    </ul>
  </div>
);

// 事業所情報-[従業員の欠員]のtips
const lackOfSupporter = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>従業員の欠員の減算について</p>
    <p className={classes.textIndentMarginTop}>【減算の適応月】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えている場合は翌月から
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えていない場合は翌々月から
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>【減算単位】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応1ヶ月〜2ヶ月目は所定単位の70%(30%減算)
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応3ヶ月目以降は所定単位の50%(50%減算)
        </p>
      </li>
    </ul>
  </div>
);

// R6報酬改定 事業所情報-[業務継続計画未策定減算]のtips
const workContinuationNotPlanningFlg = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>令和7年3月31日までは減算は適用されません。</p>
  </div>
);

// 事業所情報-[就労定着支援員等の欠員]のtips
const lackOfLifeSupportMemberSHUROTEICHAKU = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>就労定着支援員等の欠員の減算について</p>
    <p className={classes.textIndentMarginTop}>【減算の適応月】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えている場合は翌月から
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えていない場合は翌々月から
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>【減算単位】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応1ヶ月〜2ヶ月目は所定単位の70%(30%減算)
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応3ヶ月目以降は所定単位の50%(50%減算)
        </p>
      </li>
    </ul>
  </div>
);

// 事業所情報-[刻む単位]のtips
const unitEngrave = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>作業時間を記録する際の打刻単位を設定します。</p>
    <p className={classes.textIndent}>
      例）刻む単位を15分に設定した場合：10:03に通所した場合、作業開始時間が10:15に設定されます。
    </p>
  </div>
);

// 事業所情報-[利用者数]のtips
const numberOfUsers = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>利用者数について</p>
    <p>
      自治体への申請時に申告した利用者数。
      <br />
      算出の方法は以下の通り。
    </p>
    <p className={classes.textIndentMarginTop}>【新設等から6か月未満】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・便宜上、過去3年間で一体的に運営する就労移行支援事業所等を受けた後に就労し、就労を継続している期間が6ヶ月に達した者の数の70％を利用者数とする。
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>
      【新設等から6か月以上1年未満】
    </p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・新設等から6か月間における延べ利用者数を6で割った数とする。
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>【新設等から1年以上経過】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・前年1年間における延べ利用者数を12で割った数とする。
        </p>
      </li>
    </ul>
  </div>
);

// 事業所情報-[作業時間を自動的に入力する利用者]のtips
const userAutomaticallyWorkTime = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      ここで設定した作業時間を、利用実績の編集画面で自動的に入力させたい利用者を選択してください。&nbsp;
    </p>
  </div>
);

// 事業所情報-[生活支援員等欠員]のtips
const lackOfLifeSupportMember = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>生活支援員等の欠員の減算について</p>
    <p className={classes.textIndentMarginTop}>【減算の適応月】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えている場合は翌月から
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・欠員が1割を超えていない場合は翌々月から
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>【減算単位】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応1ヶ月〜2ヶ月目は所定単位の70%(30%減算)
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応3ヶ月目以降は所定単位の50%(50%減算)
        </p>
      </li>
    </ul>
  </div>
);

// 事業所情報-[サービス管理責任者の欠員]のtips
const lackOfResponsiblePerson = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>サービス管理責任者の欠員の減算について</p>
    <p className={classes.textIndentMarginTop}>【減算の適応月】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・人員基準を満たしていない場合は翌々月から
        </p>
      </li>
    </ul>
    <p className={classes.textIndentMarginTop}>【減算単位】</p>
    <ul className={`${classes.listStyleNone} ${classes.notMarginTop}`}>
      <li className={`${classes.list} ${classes.notMarginTop}`}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応1ヶ月〜4ヶ月目は所定単位の70%(30%減算)
        </p>
      </li>
      <li className={classes.list}>
        <p className={classes.textIndentNonMargin}>
          ・減算適応5ヶ月目以降は所定単位の50%(50%減算)
        </p>
      </li>
    </ul>
  </div>
);

// 事業所情報-[職場適応援助者養成研修修了者配置体制加算]のtips
const workPlaceAdaptationAssistant = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>職場適応援助者とは、</p>
    <p className={classes.notMarginTop}>
      障害者が職場に適応するために、障害者本人の他、家族や職場の事業主、上司、同僚に対して必要な援助を行う援助者のこと。
    </p>
    <p className={classes.notMarginTop}>
      この業務に必要なスキルを習得するために、（独）高齢・障害・求職者雇用支援機構と厚生労働大臣が指定する民間の養成研修機関において実施している「職場適応援助者養成研修」を修了した者が加算の対象となる。
    </p>
  </div>
);

// 事業所情報-[福祉専門職員配置等加算]のtips (移行AB/GH)
const welfareCondition = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>福祉専門職員とは、</p>
    <p>
      社会福祉士、介護福祉士、精神保健福祉士または公認心理師の資格を保有し、常勤で配置される従業員のこと。
    </p>
    <p className={classes.textIndent}>
      ※ 就労移行支援施設の場合は、作業療法士も含まれる。
    </p>
    <ul className={classes.list}>
      <li>(Ⅰ)：福祉専門職員の割合が35％以上</li>
      <li>(Ⅱ)：福祉専門職員の割合が25％以上</li>
      <li>
        (Ⅲ)：次の(1)又は(2)のいずれかに該当する場合に加算する。
        <ul className={classes.list}>
          <li>
            <span className={classes.listOrder}>(1)</span>
            <span className={classes.listContent}>
              職業指導員等として配置されている従業者のうち、常勤で配置されている従業者の割合が75%以上であること。
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>(2)</span>
            <span className={classes.listContent}>
              職業指導員等として常勤で配置されている従業者のうち、3年以上従事している従業者の割合が30%以上であること。
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

// 事業所情報-[福祉専門職員配置等加算]のtips (生活介護/自立訓練/短期入所)
const welfareCondition2 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>福祉専門職員とは、</p>
    <p>
      社会福祉士、介護福祉士、精神保健福祉士または公認心理師の資格を保有し、生活支援等として常勤で配置される従業員のこと。
    </p>
    <ul className={classes.list}>
      <li>(Ⅰ)：福祉専門職員の割合が35％以上</li>
      <li>(Ⅱ)：福祉専門職員の割合が25％以上</li>
      <li>
        (Ⅲ)：次の(1)又は(2)のいずれかに該当する場合に加算する。
        <ul className={classes.list}>
          <li>
            <span className={classes.listOrder}>(1)</span>
            <span className={classes.listContent}>
              職業指導員等として配置されている従業者のうち、常勤で配置されている従業者の割合が75%以上であること。
            </span>
          </li>
          <li>
            <span className={classes.listOrder}>(2)</span>
            <span className={classes.listContent}>
              職業指導員等として常勤で配置されている従業者のうち、3年以上従事している従業者の割合が30%以上であること。
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
);

// 事業所情報-[就労移行支援体制加算]のtips (移行AB/GH/生活介護/自立訓練)
const employmentTransitionSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    6ヶ月以上就労継続している利用者がいる場合、基本報酬の区分および定員規模に応じた所定単位数に当該利用者の数に乗じた単位数を加算。
  </div>
);

// 事業所情報-[就労移行支援体制加算]のtips (A)
const employmentTransitionSupportA = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    前年度において、就労継続支援Ａ型等を受けた後就労し、6月以上就労継続している者が１名以上いる場合、評価点に応じた所定単位数にその前年度実績の人数を乗じた単位数を加算。
    <br />
    ただし、前年度実績には就労継続支援A型事業所への就職は除く。
  </div>
);

// 事業所情報-[就労移行支援体制加算]のtips (B)
const employmentTransitionSupportB = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    前年度において、就労継続支援Ｂ型等を受けた後就労し、6月以上就労継続している者が１名以上いる場合、平均工賃月額に応じた所定単位数にその前年度実績の人数を乗じた単位数を加算。
    <br />
    ただし、前年度実績には就労継続支援A型事業所への移行は除く。
  </div>
);

// 事業所情報(生活介護)-[常勤看護職員等配置加算]のtips
const fullTimeNursePlace = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p className={classes.textIndent}>
      (Ⅰ)：看護職員を常勤換算方法で１人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、利用者に対して指定のサービスを行った場合に算定される加算。
    </p>
    <p className={classes.textIndent}>
      (Ⅱ)：看護職員を常勤換算方法で２人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、別に厚生労働大臣が定める利用者に対して指定のサービスを行った場合に算定される加算。
    </p>
  </div>
);

// 事業所情報(生活介護)-[常勤看護職員等配置加算]のtips
// 令和3年度報酬改定で追加
const fullTimeNursePlace_202104 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p className={classes.textIndent}>
      (Ⅰ)：看護職員を常勤換算方法で１人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、利用者に対して指定のサービスを行った場合に算定される加算。
    </p>
    <p className={classes.textIndent}>
      (Ⅱ)：看護職員を常勤換算方法で２人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、判定スコアの各項目に規定する状態のいずれかに該当する利用者を１名以上受け入れ、指定のサービスを行った場合に算定される加算。
    </p>
    <p className={classes.textIndent}>
      (Ⅲ)：看護職員を常勤換算方法で３人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、判定スコアの各項目に規定する状態のいずれかに該当する利用者を２名以上受け入れ、指定のサービスを行った場合に算定される加算。
    </p>
  </div>
);

// 事業所情報(生活介護)-[常勤看護職員等配置加算]のtips
// 令和6年度報酬改定で追加
const fullTimeNursePlace_202404 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      看護職員を常勤換算方法で１人以上配置しているものとして都道府県知事又は市町村長に届け出た事業所が、利用者に対して指定のサービスを行った場合に算定される加算。利用定員及び常勤換算方法で算定した看護職員の数により算定単位数が決まる。
    </p>
  </div>
);

// 事業所情報-[福祉・介護職員処遇改善加算]のtips
const staffTreatment = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      厚生労働大臣が定める基準に適合している福祉・介護職員の賃金の改善等を実施しているものとして、都道府県知事に届け出た事業所が、利用者に対して、サービスを行った場合に算定される加算。
    </p>
  </div>
);

// 事業所情報(生活介護)-[福祉・介護職員処遇改善加算]のtips
const staffTreatmentSEIKATSUKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      キャリアパス要件・職場環境等要件について厚生労働大臣が定める基準に適合している福祉・介護職員の賃金の改善等を実施しているものとして、都道府県知事に届け出た事業所が、利用者に対してサービスを行った場合に算定される加算。
    </p>
  </div>
);

// 事業所情報(A/B)-[重度者支援体制加算]のtips
const severeSupportType = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度の障害基礎年金1級を受給する利用者の数が、当該年度における指定就労継続支援A型等の利用者数が規定の割合以上の場合に加算対象となる。
    </p>
    <ul className={classes.list}>
      <li>(Ⅰ)：前年度において当該年度における利用者数が50%以上</li>
      <li> (Ⅱ)：前年度において当該年度における利用者数が25%以上</li>
    </ul>
  </div>
);

// 事業所情報(A/B)-[精神障害者退院支援施設加算]のtips
const dischargeSupportFacilityType = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      精神障害者退院支援施設である指定就労移行支援事業所において、精神病床におおむね1年以上入院していた精神障害者その他これに準ずる精神障害者に対して、居住の場を提供した場合に算定する。
    </p>
    <ul className={classes.list}>
      <li>(Ⅰ)：夜勤体制を確保している</li>
      <li>(Ⅱ)：宿直体制を確保している</li>
    </ul>
  </div>
);

// 事業所情報-[福祉・介護職員処遇改善加算]のtips
const staffTreatmentSystemType = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      キャリアパス要件・職場環境等要件について厚生労働大臣が定める基準に適合している福祉・介護職員の賃金の改善等を実施しているものとして、都道府県知事に届け出た事業所が、利用者に対してサービスを行った場合に算定される加算。
    </p>
  </div>
);

// 事業所情報-[看護職員・医療連携に関する加算]のtips
const ursingStaffPlacement = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p className={classes.textIndent}>
      ※看護職員：保健師、看護師、准看護師など看護資格を有する人を指す。
    </p>
    <p className={classes.textIndent}>
      ・看護職員配置加算は上記看護職員を常勤換算方法で1以上かつ利用者の数を20で割った数以上配置する必要がある。また日常的な利用者の健康管理、看護の提供、喀痰吸引等に係る指導、医療機関との連絡等を行う。
    </p>
    <p className={classes.textIndent}>
      ・医療連携体制加算(Ⅴ)は看護師を配置、または訪問看護スタッフとの契約により看護師を確保されている必要がある。看護師により24時間連絡できる体制を確保し、日常的な健康管理、医療ニーズが必要となった場合に適切な対応がとれる体制を指す。
    </p>
  </div>
);

// 事業所情報-[看護職員・医療連携に関する加算]のtips
// 令和3年度報酬改定で追加
const nursingStaffPlacement_202104 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p className={classes.textIndent}>
      ※看護職員：保健師、看護師、准看護師など看護資格を有する人を指す。
    </p>
    <p className={classes.textIndent}>
      ・看護職員配置加算は上記看護職員を常勤換算方法で1以上かつ利用者の数を20で割った数以上配置する必要がある。また日常的な利用者の健康管理、看護の提供、喀痰吸引等に係る指導、医療機関との連絡等を行う。
    </p>
    <p className={classes.textIndent}>
      ・医療連携体制加算(Ⅶ)は看護師を配置、または訪問看護スタッフとの契約により看護師を確保されている必要がある。看護師により24時間連絡できる体制を確保し、日常的な健康管理、医療ニーズが必要となった場合に適切な対応がとれる体制を指す。
    </p>
  </div>
);

// 事業所情報-[夜間支援体制加算]のtips
const nightSupport = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>夜間支援等体制加算毎の条件は</p>
    <ul className={classes.list}>
      <li>
        (Ⅰ)：夜間支援従事者を配置し、就寝準備の確認、寝返りや排泄などの支援を行う。
      </li>
      <li>(Ⅱ)：夜間支援従事者を配置する。</li>
      <li>(Ⅲ)：夜間防災体制、または常時の連絡体制を確保している。</li>
    </ul>
  </div>
);

// 事業所情報-[夜間支援体制加算]のtips
// 令和3年度報酬改定で追加
const nightSupport_202104 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>夜間支援等体制加算毎の条件は</p>
    <ul className={classes.list}>
      <li>
        (Ⅰ)：夜間支援従事者を配置し、就寝準備の確認、寝返りや排泄などの支援を行う。
      </li>
      <li>(Ⅱ)：夜間支援従事者を配置</li>
      <li>(Ⅲ)：夜間防災体制、または常時の連絡体制を確保</li>
      <li>(Ⅳ)：事業所単位で夜勤職員を追加配置</li>
      <li>(Ⅴ)：事業所単位で夜勤職員（夜間の一部時間）を追加配置</li>
      <li>(Ⅵ)：事業所単位で宿直職員を追加配置</li>
    </ul>
  </div>
);

// 事業所情報-[看護職員・医療連携に関する加算]のtips
const commuterLifeSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      利用者の自活に向けた支援の質の向上を図るため、主に日中において職場での対人関係の調整や相談・援助、金銭管理の指導等、日常生活上の支援を行うこと。
    </p>
    <p>
      一般就労している利用者が50％以上を占める場合に、利用者全員につき日ごとに算定可能。
    </p>
  </div>
);

// 事業所情報(生活介護)-[上記処遇改善を指定障害者支援施設において実施]のtips
const specifiedDisabilitySupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>指定障害者支援施設とは、</li>
      <li>
        障害者の日常生活及び社会生活を総合的に支援するための法律により「障害者につき、施設入所支援を行うとともに、施設入所支援以外の施設障害福祉サービスを行う施設」と規定されている施設である。具体的には、障害者に対し、夜間から早朝にかけては「施設入所支援」を提供するとともに、昼間は「生活介護」などの「日中活動系サービス（昼間実施サービス）」を行う、社会福祉施設である。
      </li>
    </ul>
  </div>
);

// 事業所情報-[上記処遇改善を指定障害者支援施設において実施,障害者支援施設(R6報酬改定後)]のtips
const specifiedDisabedSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>指定障害者支援施設とは、</li>
      <li>
        障害者の日常生活及び社会生活を総合的に支援するための法律により「障害者につき、施設入所支援を行うとともに、施設入所支援以外の施設障害福祉サービスを行う施設」と規定されている施設である。
      </li>
      <li>
        具体的には、障害者に対し、夜間から早朝にかけては「施設入所支援」を提供するとともに、昼間は「生活介護」などの「日中活動系サービス（昼間実施サービス）」を行う、社会福祉施設である。
      </li>
    </ul>
  </div>
);

// 事業所情報-[福祉・介護職員等処遇改善加算]のtips
const specificBetterSupporterCondition202404Tips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>2024（令和6）年6月1日より算定可能</p>
  </div>
);
// 事業所情報-[旧処遇改善加算]のtips
const oldTreatmentImprovementAdditionTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>2024（令和6）年5月31日まで算定可能</p>
  </div>
);
// 事業所情報-[視覚・聴覚言語障害者支援体制加算]のtips
const visualAuditoryLanguageDisabledPeopleSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>視覚障害者等との意思疎通に専門性を有する職員を一定数加配すること。 </p>
    <p>
      視覚、聴覚、言語障害のある利用者が全利用者の30％以上を占めている場合に、利用者全員につき日ごとに算定可能。&nbsp;
    </p>
  </div>
);

// 事業所情報-[視覚・聴覚言語障害者支援体制加算]のtips
const visualAuditoryLanguageDisabledPeopleSupportSEIKATSUKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      視覚、聴覚、言語障害のある者が全利用者の原則30％以上を占めており、それらの利用者との意思疎通に関する生活支援員を一定数配置した場合、利用者全員につき日ごとに算定される加算。
    </p>
  </div>
);

// 事業所情報-[視覚・聴覚言語障害者支援体制加算]のtips
const seeHearTeam = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      視覚、聴覚、言語障害のある者が全利用者の原則30％以上を占めており、それらの利用者との意思疎通に関する生活支援員を一定数配置した場合、利用者全員につき日ごとに算定される加算。
    </p>
  </div>
);

// 利用者情報-[障害区分]のtips
const disability = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      障害の多様な特性その他の心身の状態に応じて必要とされる標準的な支援の度合いを総合的に示すもので、受給者証に記載されている。
    </p>
  </div>
);

// 生活介護用_利用者情報-[障害区分]のtips
const disabilitySEIKATSUKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      市町村により認定された、障害の多様な特性その他の心身の状態に応じて必要とされる標準的な支援の度合いを総合的に示すもので、受給者証に記載されている。
    </p>
  </div>
);

// 利用者情報-[障害区分]のtips
const disabilityClass = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      市町村により認定された、障害の多様な特性その他の心身の状態に応じて必要とされる標準的な支援の度合いを総合的に示すもので、受給者証に記載されている。
    </p>
  </div>
);

// 利用者情報-[要介護区分]のtips
const needNursingCareClass = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      身体上又は精神上の障害があるために、厚生労働省令で定める期間にわたり継続して、常時介護を要すると見込まれる状態であって、その介護の必要の程度に応じて厚生労働省令で定める区分。
    </p>
    <p className={classes.textIndent}>
      居宅介護支援費重複減算の設定に用います。
    </p>
  </div>
);

// 利用者情報-[要支援区分]のtips
const supportRequiredClass = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      身体上又は精神上の障害があるために、厚生労働省令で定める期間にわたり継続して常時介護を要する状態の軽減若しくは悪化の防止に特に資する支援を要すると見込まれ、又は身体上若しくは精神上の障害があるために厚生労働省令で定める期間にわたり継続して日常生活を営むのに支障があると見込まれる状態であって、支援の必要の程度に応じて厚生労働省令で定める区分。
    </p>
    <p className={classes.textIndent}>
      介護予防支援費重複減算の設定に用います。
    </p>
  </div>
);

// 利用者情報-[自治体助成金対象]のtips
const subsidized = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      請求給付金の「総費用額の１割」と「上限額」のどちらか低い金額が利用者負担額となるところを、「総費用額の〇〇%」、又は「総費用額の〇〇円」と「上限額」のどちらか低い金額の負担で済むように、本来の利用者負担額と軽減後の負担額の差額を、市区町村が助成する。
    </p>
    <p className={classes.textIndent}>
      ※自治体助成金制度があるかどうかは各市区町村にお問い合わせください。
    </p>
  </div>
);

// 利用者情報-[地域生活移行個別支援特別加算]のtips
const localLifeSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      医療観察法に基づく通院決定を受けてから3年を経過していない者、または矯正施設等を退所等してから3年を経過していない者等で、保護観察所等との調整により利用を開始した者が算定対象。
    </p>
    <p>
      事業所として、社会福祉士、精神保健福祉士、または公認心理師を配置し、該当有資格者による指導体制を整える必要がある。
    </p>
  </div>
);

// 利用者情報-[強度行動障害者地域移行特別加算]のtips
const intensitySpecialAddition = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>強度行動障害を有する者に対し算定可能な加算。</p>
    <p>
      事業所として、強度行動障害支援者養成研修、または行動援護従業者養成研修を修了したサービス管理責任者、または生活支援員を1名以上配置必要。
    </p>
  </div>
);

// 利用者情報-[サービス提供開始日]のtips
const inServiceStartDate = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>利用者が施設に通所することが決定し、正式に契約を結んだ日のこと。</p>
  </div>
);

// 利用者情報-[支給決定開始日]のtips
const payStartDate = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      障害者に対し、各市区町村が決定した支援費を支給する期間のこと。利用者の受給者証に記載されているため、参照してください。
    </p>
  </div>
);

// 利用者情報-[作業時間を自動的に入力する]のtips
const defRecordWork = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      事業所情報画面で設定された作業時間が、利用実績の編集画面で自動的に入力されます。
    </p>
  </div>
);

// 利用者情報-[療育]のtips
const disabilityClassRehabilitation = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>各自治体において定められた等級を入力してください</li>
      <li>例）東京都：4度 / 神奈川県：B2</li>
    </ul>
  </div>
);

// 利用者情報-[重度障害者支援の個別支援対象者である]のtips[報酬改定前]
const severeDisabilitySupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>・対象者とは：</li>
      <li>
        行動関連項目10点以上の利用者（強度行動障害を有する者）で、支援計画シートなどに基づき、指定研修（※）を修了した職員より個別支援を受ける利用者のことを指す。
      </li>
    </ul>
    <p>
      （※）指定研修とは、強度行動障害支援者養成研修（基礎研修）、重度訪問介護従業者養成研修行動障害支援課程、行動援護従業者養成研修
    </p>
    <ul className={classes.listStyleNone}>
      <li>・加算算定開始日とは：</li>
      <li>
        「個別の支援の評価」の算定を開始した日で、入所開始日ではない。加算算定開始日から90日以内の期間について、さらに加算される。
      </li>
    </ul>
  </div>
);

// 利用者情報-[盲ろう者(聴覚障害6級)に該当する利用者]のtips
const classifyDeafblind = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      盲ろう者（同行援護の対象要件を満たし、かつ聴覚障害6級に該当する者）に対して盲ろう者向け通訳・介助員が支援した場合は25/100が加算される。
    </p>
  </div>
);

// 利用者情報-[重度障害者支援の個別支援対象者である]のtips[報酬改定後]
const severeDisabilitySupport202104 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>・対象者とは：</li>
      <li>
        行動関連項目10点以上の利用者（強度行動障害を有する者）で、支援計画シートなどに基づき、指定研修（※）を修了した職員より個別支援を受ける利用者のことを指す。
      </li>
    </ul>
    <p>
      （※）指定研修とは、強度行動障害支援者養成研修（基礎研修）、重度訪問介護従業者養成研修行動障害支援課程、行動援護従業者養成研修
    </p>
    <ul className={classes.listStyleNone}>
      <li>・加算算定開始日とは：</li>
      <li>
        「個別の支援の評価」の算定を開始した日で、入所開始日ではない。加算算定開始日から180日以内の期間について、さらに加算される。
      </li>
    </ul>
  </div>
);

// 事業所情報-[重度障害者支援加算]のtips
const seriousDisability = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>重度障害者支援加算（Ⅰ）</li>
      <li className={classes.notFontWeight}>
        医師意見書により特別な治療が必要であるとされる者又はこれに準ずる者の割合が100分の20以上であって、人員基準に加え看護職員又は生活支援員を常勤換算１以上配置している場合
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>重度障害者支援加算（Ⅰ）（重度）</li>
      <li className={classes.notFontWeight}>
        （Ⅰ）を算定している事業所で、区分6かつ気管切開を伴う人工呼吸器による呼吸管理が必要な者、又は重症心身障がい者が2人以上利用している場合
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>重度障害者支援加算（Ⅱ）</li>
      <li className={classes.notFontWeight}>
        所定の研修修了者が、強度行動障害を有する者に対して（障害者支援施設においては夜間に）個別の支援を行った場合
      </li>
    </ul>
  </div>
);

// 初期設定情報(短期入所)-[短期利用加算の算定開始日]のtips
const calculationStartDate = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      年間上限３０日の短期利用加算の算定回数が最後にリセットされた（ゼロになった）日。算定回数を含む本施設の利用が一度もない場合は空欄。また初回請求月以降の場合も空欄。
    </p>
  </div>
);

// 初期設定情報(短期入所)-[短期利用加算の算定開始日]のtips
const calculationCounts = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      左記の算定開始日から、初回請求月の前月末までに短期利用加算を算定した回数（日数）を30以下で入力。他施設での算定は含めない。
    </p>
  </div>
);

// 利用実績編集モーダル(短期入所)-[地域生活支援拠点等として指定短期入所等利用開始 > 一定の条件を満たす場合]のtips
const lifeSupportHubInDistrictCondition = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      市町村及び基幹相談支援センター等との連携及び
      調整に従事する者を一以上配置し、医療的ケアが必要な児者、重症心身障害児
      者又は強度行動障害を有する児者に対し、指定短期入所等を行った場合
    </p>
  </div>
);

// 事業所情報(生活介護)-[送迎加算(重度)]のtips
const severeTransportationAdditionSEIKATSUKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      送迎を利用する利用者のうち、以下を準ずる者が60%以上いる場合、送迎を利用する全員に加算が適応される。
    </p>
    <p className={classes.textIndent}>・障害支援区分が5と6</p>
    <p className={classes.textIndent}>・一定以上の行動障害を有する者</p>
    <p className={classes.textIndent}>・たんの吸引などを必要とする者</p>
  </div>
);

// 振り返り-本人の評価のtips
const selfEvaluation = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      利用者本人の自分自身に対する主観的な評価・振り返りを入力してください。職員からの客観的な評価と比較して、より分かりやすく振り返ることができます。
    </p>
  </div>
);

// 振り返り-職員からの評価のtips
const staffEvaluation = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      職員から見た利用者に対する客観的な評価・振り返りを入力してください。利用者本人の主観的な評価と比較して、次の支援に活かすことができます。
    </p>
  </div>
);

// 事業所情報-[基本報酬(利用者の1日平均労働時間)]のtips
const averageWorkDuration = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度において、雇用契約を締結していた利用者の労働時間の合計数を当該利用者の合計数で除して算出した事業所における
      1 日当たりの平均労働時間数によって８段階の評価。
    </p>
  </div>
);

// 事業所情報-[基本報酬(生産活動)]のtips
const productionActivity = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度及び前々年度の各年度において生産活動に係る事業の収入から生産活動に係る事業に必要な経費を控除した額に相当する金額（以下、生産活動収支という。）が、利用者に支払う賃金の総額以上であるかによって４段階評価の評価。
    </p>
  </div>
);

// 事業所情報-[基本報酬(生産活動)]のtips
// 令和6年度報酬改定で追加
const productionActivity202404 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度及び前々年度の各年度において生産活動に係る事業の収入から生産活動に係る事業に必要な経費を控除した額に相当する金額（以下、生産活動収支という。）が、利用者に支払う賃金の総額以上であるかによって６段階評価の評価。
    </p>
  </div>
);

// 事業所情報-[基本報酬(多様な働き方)]のtips
const diversifiedWorkStyle = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      任意の５項目について規程等（就業規則その他これに準ずるものに限る。）で定めており、前年度において雇用契約を締結していた利用者の希望により当該５項目に係る制度を活用した実績があった場合に、各項目ごとに評価値を２（実績がない場合は１）として評価（最大10）した上で、その合計に応じて３段階の評価を行う。
    </p>
    <ul className={classes.listStyleNone}>
      <li>（評価要素）</li>
      <li>①免許及び資格の取得の促進並びに検定の受験の勧奨に関する事項</li>
      <li>
        ②当該就労継続支援Ａ型事業所の利用者を、職員（利用者を除く）としての登用する制度に係る試験等の手続、対象者の要件及び採用時期に関する事項
      </li>
      <li>③在宅勤務に係る労働条件及び服務規律に関する事項</li>
      <li>④フレックスタイム制に係る労働条件に関する事項</li>
      <li>⑤1 日の所定労働時間を短縮するに当たり必要な労働条件に関する事項</li>
      <li>⑥早出遅出勤務に係る労働条件に関する事項</li>
      <li>⑦時間を単位として有給休暇を付与又は計画付与制度の取得に関する事項</li>
      <li>
        ⑧従業者が私的に負傷し、又は疾病にかかった場合の療養のための休暇の取得に関する事項
      </li>
    </ul>
  </div>
);

// 事業所情報-[基本報酬(多様な働き方)]のtips
// 令和6年度報酬改定で追加
const diversifiedWorkStyle202404 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      評価項目について規程等（就業規則その他これに準ずるものに限る。）で定めている場合、１項目につき１点の評価とした上で、その合計に応じて以下３段階の評価。
    </p>
    <ul className={classes.listStyleNone}>
      <li>（評価要素）</li>
      <li>①免許及び資格の取得の促進並びに検定の受験の勧奨に関する事項</li>
      <li>
        ②当該就労継続支援Ａ型事業所の利用者を、職員（利用者を除く）としての登用する制度に係る試験等の手続、対象者の要件及び採用時期に関する事項
      </li>
      <li>③在宅勤務に係る労働条件及び服務規律に関する事項</li>
      <li>④フレックスタイム制に係る労働条件に関する事項</li>
      <li>⑤1 日の所定労働時間を短縮するに当たり必要な労働条件に関する事項</li>
      <li>⑥早出遅出勤務に係る労働条件に関する事項</li>
      <li>⑦時間を単位として有給休暇を付与又は計画付与制度の取得に関する事項</li>
      <li>
        ⑧従業者が私的に負傷し、又は疾病にかかった場合の療養のための休暇の取得に関する事項
      </li>
    </ul>
  </div>
);

// 事業所情報-[基本報酬(支援力向上)]のtips
const improvementOfSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      任意の５項目について、各項目の取組実績に応じて別に定める算定方法に従い評価値として各０～２として評価（最大10）した上で、その合計に応じて以下３段階の評価。
    </p>
    <ul className={classes.listStyleNone}>
      <li>（評価要素）</li>
      <li>
        ①職員の研修に関する計画に基づく障害者雇用、障害者福祉その他障害者就労に関する外部研修会等の参加又は外部講師による内部研修会の開催状況
      </li>
      <li>
        ②外部研修会等への講師派遣、学会等での研究発表又は実践報告の実施状況
      </li>
      <li>
        ③障害者就労に係る先進的な取組を行う他の事業所等への視察若しくは実習への参加又は他の事業所等からの視察等の受入状況
      </li>
      <li>
        ④販路拡大、事業拡大等に向けた展示会への出展、商談会への参加その他生産活動収益の増加に資するビジネスマッチングに係る取組の実施状況
      </li>
      <li> ⑤昇給、昇格と連動した人事評価制度の整備状況</li>
      <li>
        ⑥障害者ピアサポート研修における基礎研修及び専門研修の修了し、利用者の就労又は生産活動等の支援を実施するピアサポートの配置状況
      </li>
      <li> ⑦前年度末日から過去３年以内の福祉サービス第三者評価の受審状況</li>
      <li>
        ⑧国際標準化機構が制定したマネジメントシステム規格等の認証取得又は更新審査等の受審状況
      </li>
    </ul>
  </div>
);

// 事業所情報-[基本報酬(支援力向上)]のtips
// 令和6年度報酬改定で追加
const improvementOfSupport202404 = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      各項目の取組実績に応じて１項目につき１点の評価とした上で、その合計に応じて以下３段階の評価。
    </p>
    <ul className={classes.listStyleNone}>
      <li>（評価要素）</li>
      <li>
        ①職員の研修に関する計画に基づく障害者雇用、障害者福祉その他障害者就労に関する外部研修会等の参加又は外部講師による内部研修会の開催状況
      </li>
      <li>
        ②外部研修会等への講師派遣、学会等での研究発表又は実践報告の実施状況
      </li>
      <li>
        ③障害者就労に係る先進的な取組を行う他の事業所等への視察若しくは実習への参加又は他の事業所等からの視察等の受入状況
      </li>
      <li>
        ④販路拡大、事業拡大等に向けた展示会への出展、商談会への参加その他生産活動収益の増加に資するビジネスマッチングに係る取組の実施状況
      </li>
      <li> ⑤昇給、昇格と連動した人事評価制度の整備状況</li>
      <li>
        ⑥障害者ピアサポート研修における基礎研修及び専門研修の修了し、利用者の就労又は生産活動等の支援を実施するピアサポートの配置状況
      </li>
      <li> ⑦前年度末日から過去３年以内の福祉サービス第三者評価の受審状況</li>
      <li>
        ⑧国際標準化機構が制定したマネジメントシステム規格等の認証取得又は更新審査等の受審状況
      </li>
    </ul>
  </div>
);

// 事業所情報-[基本報酬(地域連携活動)]のtips
const regionalCooperationActivity = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度に実施した地元企業と連携した高付加価値の商品開発、施設外就労による地域での働く場の確保等地域と連携した取組について、当該取組をまとめた報告書を作成し、インターネットの利用その他の方法により公表するとともに、当該報告書において連携先である地元企業等から当該取組が地域連携活動である旨の意見又は評価が付されていることをもって評価する。
    </p>
  </div>
);

// 事業所情報-[基本報酬(経営改善計画)]のtips
const managementImprovementPlan = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>経営改善計画の作成状況に基づき評価</p>
  </div>
);

// 事業所情報-[基本報酬(利用者の知識・能力の向上)]のtips
const initiativesToImproveAbilities = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      前年度において、就労継続支援Ａ型事業所等が利用者の知識及び能力の向上に向けた支援を行い、当該支援の具体的な内容を記載した報告書を作成し、インターネットの利用その他の方法により公表していることをもって評価する。
    </p>
  </div>
);

// 利用者情報-[特別重度支援加算]のtips
const specialSevereDisabilitySupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>特別重度支援加算（Ⅰ）：判定スコアを合算し25点以上</li>
      <li>特別重度支援加算（Ⅱ）：判定スコアを合算し10点以上</li>
      <li>特別重度支援加算（Ⅲ）</li>
      <li style={{ marginTop: 20 }}>【判定スコア】</li>
      <li>(1) レスピレーター管理 ＝ 10</li>
      <li>(2) 気管内挿管、気管切開 ＝ 8</li>
      <li>(3) 鼻咽頭エアウェイ ＝ 5</li>
      <li>(4) 酸素吸入 ＝ 5</li>
      <li>(5) 1回／時間以上の頻回の吸引 ＝ 8</li>
      <li style={{ marginLeft: 25 }}>6回／日以上の頻回の吸引 ＝ 3</li>
      <li>(6) ネブライザー6回／日以上または継続使用 ＝ 3</li>
      <li>(7) ＩＶＨ ＝ 10</li>
      <li>(8) 経口摂取（全介助） ＝ 3</li>
      <li>(9) 経管（経鼻・胃ろう含む） ＝ 5</li>
      <li>(10) 腸ろう・腸管栄養 ＝ 8</li>
      <li>(11) 持続注入ポンプ使用（腸ろう･腸管栄養時） ＝ 3</li>
      <li>
        (12)
        手術・服薬でも改善しない過緊張で、発汗による更衣と姿勢修正（3回／日以上）＝
        3
      </li>
      <li>(13) 継続する透析(腹膜灌流を含む） ＝ 10</li>
      <li>(14) 定期導尿（3回／日以上） ＝ 5</li>
      <li>(15) 人工肛門 ＝ 5</li>
      <li>(16) 体位交換（6回／日以上） ＝ 3</li>
    </ul>
  </div>
);

// 利用実績-[口腔ケア]のtips
const oralCareFlag = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    歯科医師の指示を受けた歯科衛生士が、入所者に対し口腔ケア（本項目）を月２回以上行い、かつ以下の要件を満たしている場合に、１月につき所定単位数が加算される
    <p className={classes.listStyleNone}>
      ・口腔衛生管理体制加算を算定していること。
      <br />
      ・歯科衛生士が入所者に係る口腔ケアについて、施設従業者に対し、具体的な技術的助言及び指導を行うこと。
      <br />
      ・歯科衛生士が入所者の口腔に関する施設従業者からの相談等に必要に応じ対応すること。
    </p>
  </div>
);

// 支援レポート-[帳簿に表示]のtips
const supportMethotdFlag = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      ここにチェックの入っている実施日が
      <br />
      帳票に表示されます。
    </p>
  </div>
);

// GH-支援記録-[職員考察]のtips
const supportStaffComment = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      印刷時、この項目のみ「印刷する/しない」
      <br />
      を選択することができます。
    </p>
  </div>
);

// 記録の項目設定-[職員考察]のtips
const staffComment = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>帳票印刷時、この項目のみ「印刷する/しない」を選択することができます。</p>
    <p>※記録画面への「表示/非表示」の切り替えはできません</p>
  </div>
);

// 事業所情報-ユニット（グループ設定）
const operationUnit = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>記録画面にて利用者をグループ単位で管理することが可能となります。</p>
  </div>
);

// 事業所情報-ユニット（グループ設定）記録機能がない場合
const operationUnitNoRecordService = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>記録機能利用時に設定いただける機能です。</p>
    <p>記録画面にて利用者をグループ単位で管理することが可能となります。</p>
  </div>
);

// 事業所情報-建物管理
const buildingManagement = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      利用者情報画面で居住建物を割り当てると、同一建物に居住する利用者20人以上、もしくは事業所と同一敷地内建物に居住する利用者にサービスを行った場合の減算の要件に合致した際、自動で算定されます。
    </p>
  </div>
);

// 利用者情報-[サービス提供開始日]のtips(訪問系サービス)
const inServiceStartDateOfVisit = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      利用者が事業所からサービスを提供されることが決定し、正式に契約を結んだ日のこと。
    </p>
  </div>
);

// 利用者情報-[利用日数に関わる特例あり]のtips
const dayUseSpecialCase = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>＜利用日数に係る特例の適用について＞</p>
    <p>
      日中活動サービスについて、一人の利用者がひと月に利用できる日数(支給量)は、原則として各月の日数から8日を控除した日数(原則の日数)を上限とされています。
      <br />
      ただし、事業運営上の理由から「原則の日数」を超える支援が必要となる場合は、当該事業所が特定する3か月以上1年以内の期間において、利用日数の合計が「原則の日数」の総和の範囲内であれば、自治体に届出を行うことにより、「原則の日数」を超えてサービスを利用することができます。
    </p>
  </div>
);

// 利用者情報-同行支援の承認のtips（重度訪問介護）
const approvalOfAccompanySupportJUDOHOMONKAIGO = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      当該利用者の支援に熟練した従業者が同行する「同行支援」について承認および支給決定がなされている場合に設定します。
      <br />
      受給者証の特記事項欄に記載された同行支援の時間、同行期間を併せて入力してください。
    </p>
  </div>
);

// 支援手順書兼記録用紙-[タイトル]のtips(行動援護)
const supportProcedureTitle = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      タイトルを入力しておくと、一覧上で各手順書の概要を把握したり、コピーする際にどの手順書を元にするか等を見分けやすくなります。帳票印刷時は表示されません。必要に応じてお使いください。
      <br />
      入力例：「通院付き添い」「買い物と散歩」など
    </p>
  </div>
);

// 事業所情報-[地域移行支援サービス費]のtips（地域移行支援）
const chiikiikoServiceType = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <ul className={classes.listStyleNone}>
      <li>【地域移行支援サービス費（Ⅰ）】</li>
      <li>
        地域移行の実績（3人以上）がある事業所において、社会福祉士や精神保健福祉士の配置等について一定の基準をみたしている
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【地域移行支援サービス費（Ⅱ）】</li>
      <li>
        地域移行の実績（1人以上）がある事業所において、社会福祉士や精神保健福祉士の配置等について一定の基準をみたしている
      </li>
    </ul>
    <ul className={classes.listStyleNone}>
      <li>【地域移行支援サービス費（Ⅲ）】</li>
      <li>上記以外</li>
    </ul>
    <p>
      事業者が、地域移行支援計画を作成しない場合や、利用者との対面による支援を1月に2日以上行わない場合には、所定単位を算定しない。
    </p>
    <p>
      ※地域移行支援計画を作成しない場合については、利用者情報の地域移行支援計画未作成にて設定をおこなってください。
    </p>
  </div>
);

// 利用者情報-[地域移行支援計画未作成]のtips（地域移行支援）
const notCreateSupportPlanEndDate = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>下記の条件に当てはまるとき、地域移行支援サービス費が算定されない</p>
    <ul className={classes.listStyleNone}>
      <li>
        <span className={classes.listOrder}>①</span>
        <span className={classes.listContent}>
          地域移行支援計画未作成のスイッチがONで未作成期間終了日が未入力であるとき
        </span>
      </li>
      <li>
        <span className={classes.listOrder}>②</span>
        <span className={classes.listContent}>
          地域移行支援計画未作成のスイッチがONで未作成期間終了日までの期間内であるとき
        </span>
      </li>
      <li>
        <span className={classes.listOrder}>③</span>
        <span className={classes.listContent}>
          地域移行支援計画未作成のスイッチがOFFで未作成期間終了日までの期間内であるとき
        </span>
      </li>
    </ul>
  </div>
);

// 利用実績-常時連絡体制確保の未実施のtips（地域定着）
const noConstantContactSystemFlgCHIIKITEICHAKU = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      常時連絡体制確保ができない場合にチェックしてください。
      <br />
      チェックをした場合は、当月の地域定着支援サービス費（体制確保費）が算定されません。
    </p>
  </div>
);

// 利用者情報-[項目設定モーダル]のtips（計画相談）
const keikakusodanNumberOfEmployees = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      今月のサービス提供時モニタリング加算の実施上限数を定める為、及び翌月以降のサービス利用支援費（Ⅱ）（障害児支援利用援助費（Ⅱ））/継続サービス利用支援費（Ⅱ）（継続障害児支援利用援助費（Ⅱ））を算定する件数に用います。
    </p>
  </div>
);

const keikakusodanNumberOfHandicappedChild = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      翌月以降のサービス利用支援費（Ⅱ）/継続サービス利用支援費（Ⅱ）を算定する件数に用います。
    </p>
  </div>
);

// 事業所情報-[指定障害児相談支援事業所と一体的に運営]のtips（計画相談支援）
const keikakusodanIntegratedManagementHandicappedChild = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      指定特定相談支援事業者と指定障害児相談支援事業所を一体的に運営している場合は設定してください。
      <br />
      利用実績画面の「今月の設定項目」で「障害児相談支援の対応件数」を入力することが出来ます。
      <br />
      サービス利用支援費Ⅱもしくは継続サービス利用支援費Ⅱの算定に用います。
    </p>
  </div>
);

// 利用者情報-[障害福祉サービス等の利用期間]のtips（計画相談支援）
const keikakusodanDisabledWelfareService = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      障害福祉サービス等の利用期間で居宅介護支援事業所等連携加算の算定の可否、上限数を決定します。
    </p>
  </div>
);

// 利用者情報-[障害児相談支援の利用期間]のtips（計画相談支援）
const keikakusodanHandicapppedChildConsultationSupport = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      障害児の場合に設定できる項目です。
      <br />
      計画相談支援と障害児相談支援両方を利用している場合、障害児相談支援のみが請求対象となります。
      <br />
      障害児相談支援の利用期間中は計画相談支援の算定、支援人数としてのカウントを行いません。
    </p>
  </div>
);

// 利用者情報-[世帯通番]のtips（計画相談）
const keikakusodanFamilySerialNumberTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      事業所内に同一世帯の利用者がいる場合、世帯ごとに番号が一意になるように、設定してください
    </p>
  </div>
);

// 利用者情報-[経口維持加算]のtips（施設入所）
const shisetsunyushoOralMaintenance = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      ・経口維持加算（Ⅰ）を設定している期間内であっても、栄養マネジメント加算が1日も設定されていない月は算定されません。
      <br />
      <br />
      ・経口維持加算（Ⅰ）を設定している期間内においては、経口移行加算を設定しても算定されません。
      <br />
      <br />
      ・経口維持加算（Ⅰ）を設定していない期間に、経口維持加算（Ⅱ）を設定することはできません。
    </p>
  </div>
);

// 利用者実績モーダル-[経口移行加算]のtips（施設入所）
const shisetsunyushoOralTransfer = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      ・経口移行加算を算定するには、「利用実績」画面にて同日に栄養マネジメント加算を設定してください。
      <br />
      <br />
      ・経口移行加算を設定していても、「利用者情報」画面にて経口維持加算（Ⅰ）を設定している期間内は算定されません。
    </p>
  </div>
);

// 利用者ごと-支援記録-[職員考察]のtips（相談系）
const csegStaffComment = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>印刷時、この項目のみ「印刷する/しない」を選択することができます。</p>
  </div>
);

// 利用者設定-[自治体選択]のtips（移動支援）
const municipalitySelectTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      自治体を選択してください。選択肢にない場合は、自治体情報にて登録してください。設定した自治体情報をもとに、算定時間の計算やサービスコードの登録（記録プランの場合は除く）ができます。
      <br />
      <br />
      自治体を設定しない場合は以下の設定となります。
      <br />
      ・算定時間は30分区切り
      <br />
      （30分以下の端数は切り捨て）
      <br />
      ・時間区分なし
      <br />
      ・サービスコードの表示なし
    </p>
  </div>
);

// 自治体情報の算定時間のtips(移動支援)
const municipalityCalculationTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      30分に満たない算定時間の切り上げ・切り捨て処理を設定します。
      <br />
      OFFの場合は30分に満たない算定時間は切り捨てられます。
      <br />
      ONにした場合は何分以上で切り上げるかを設定してください。
    </p>
  </div>
);

// 自治体情報の時間区分のtips(移動支援)
const municipalityTimeDivisionTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      サービス提供時間に「日中」「夜間」「深夜」「早朝」の区分がある場合にチェックしてください。
      <br />
      利用実績で入力したサービス提供時間に応じて時間区分ごとに算定時間が算出されます。
      <br />
      時間区分は以下の時間帯で設定されます。
      <br />
      <br />
      日中：08:00~18:00
      <br />
      夜間：18:00~22:00
      <br />
      深夜：22:00~06:00
      <br />
      早朝：06:00~08:00
    </p>
  </div>
);

// 事業所情報の視覚・聴覚言語障害者支援体制加算のtips(GH)
const seeHearTeam202404Tips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>
      視覚障害者等との意思疎通に専門性を有する職員を一定数加配すると、利用者全員につき日ごとに算定可能な加算。
    </p>
    <p>
      視覚、聴覚、言語障害のある利用者が全利用者の50％以上を占めている場合にⅠを、30%以上を占めている場合にⅡを算定可能。
    </p>
  </div>
);

const calculationPossibleUntil20240531Tips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>2024（令和6）年5月31日まで算定可能</p>
  </div>
);

// 利用者情報-[通所予定日・標準算定時間]のtips（生活介護）
const calculatedHoursTips = ({
  classes
}: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.message}>
    <p>個別支援計画に定めた標準的な支援時間を入力してください</p>
  </div>
);

const HelpMessages = {
  officeNumber,
  numberOfUsers,
  satelliteTypeFlag: satellite,
  satelliteTypeFlagSEIKATSUKAIGO: satelliteSEIKATSUKAIGO,
  satelliteTypeFlagSHUROTEICHAKU: satelliteSHUROTEICHAKU,
  masterSubordinateFlag: masterSubordinate,
  welfareSpecialistPlacementType: welfareCondition,
  welfareSpecialistPlacementType2: welfareCondition2,
  employmentTransitionSupportType: employmentTransitionSupport,
  employmentTransitionSupportTypeA: employmentTransitionSupportA,
  employmentTransitionSupportTypeB: employmentTransitionSupportB,
  fullTimeNursePlacementType: fullTimeNursePlace,
  fullTimeNursePlacementType_202104: fullTimeNursePlace_202104,
  fullTimeNursePlacementType_202404: fullTimeNursePlace_202404,
  staffTreatmentSystemType: staffTreatment,
  staffTreatmentSystemTypeSEIKATSUKAIGO: staffTreatmentSEIKATSUKAIGO,
  severeSupportTypeFlag: severeSupportType,
  dischargeSupportFacilityTypeFlag: dischargeSupportFacilityType,
  staffTreatmentSystem: staffTreatmentSystemType,
  nursingStaffPlacementSystemFlag: ursingStaffPlacement,
  nursingStaffPlacementSystemFlag_202104: nursingStaffPlacement_202104,
  nightSupportFlag: nightSupport,
  nightSupportFlag_202104: nightSupport_202104,
  commuterLifeSupportFlag: commuterLifeSupport,
  commuterLifeSupportFlagSEIKATSUKAIGO: specifiedDisabilitySupport,
  specifiedDisabedSupportFlag: specifiedDisabedSupport,
  user_automatically: userAutomaticallyWorkTime,
  visualAuditoryLanguageDisabledPeopleSupportSystemFlag: visualAuditoryLanguageDisabledPeopleSupport,
  visualAuditoryLanguageDisabledPeopleSupportSystemFlagSEIKATSUKAIGO: visualAuditoryLanguageDisabledPeopleSupportSEIKATSUKAIGO,
  seeHearTeamFlag: seeHearTeam,
  disability_class: disability,
  disability_class_SEIKATSUKAIGO: disabilitySEIKATSUKAIGO,
  subsidized_flg: subsidized,
  localLifeSpecialAddition: localLifeSupport,
  intensityBehavioralSpecialAddition: intensitySpecialAddition,
  lackOfSupporterFlag: lackOfSupporter,
  lackOfNightShiftStaffFlag: lackOfNightShiftStaff,
  lackOfLifeSupportMemberFlagSHUROTEICHAKU: lackOfLifeSupportMemberSHUROTEICHAKU,
  unitEngraveFlag: unitEngrave,
  lackOfLifeSupportMemberFlag: lackOfLifeSupportMember,
  lackOfResponsiblePersonFlag: lackOfResponsiblePerson,
  workPlaceAdaptationAssistantFlag: workPlaceAdaptationAssistant,
  in_service_start_date: inServiceStartDate,
  pay_start_date: payStartDate,
  def_record_work: defRecordWork,
  severeDisabilitySupportFlag: severeDisabilitySupport,
  classifyDeafblindFlg: classifyDeafblind,
  severeDisabilitySupportFlag202104: severeDisabilitySupport202104,
  seriousDisabilityFlag: seriousDisability,
  calculationStartDateFlg: calculationStartDate,
  calculationCountsFlg: calculationCounts,
  lifeSupportHubInDistrictCondition,
  seriousSupporterFlg: severeTransportationAdditionSEIKATSUKAIGO,
  selfEvaluation,
  staffEvaluation,
  averageWorkDuration,
  productionActivity,
  productionActivity202404,
  diversifiedWorkStyle,
  diversifiedWorkStyle202404,
  improvementOfSupport,
  improvementOfSupport202404,
  regionalCooperationActivity,
  managementImprovementPlan,
  initiativesToImproveAbilities,
  specialSevereDisabilitySupport,
  oralCareFlag,
  disabilityClassRehabilitation,
  supportMethotdFlag,
  supportStaffComment,
  staffComment,
  operationUnit,
  operationUnitNoRecordService,
  buildingManagement,
  inServiceStartDateOfVisit,
  special_case_flg: dayUseSpecialCase,
  approvalOfAccompanySupportJUDOHOMONKAIGO,
  disabilityClass,
  supportProcedureTitle,
  needNursingCareClass,
  supportRequiredClass,
  chiikiikoServiceType,
  notCreateSupportPlanEndDate,
  noConstantContactSystemFlgCHIIKITEICHAKU,
  keikakusodanIntegratedManagementHandicappedChild,
  keikakusodanDisabledWelfareService,
  keikakusodanHandicapppedChildConsultationSupport,
  keikakusodanNumberOfEmployees,
  keikakusodanNumberOfHandicappedChild,
  keikakusodanFamilySerialNumberTips,
  shisetsunyushoOralMaintenance,
  shisetsunyushoOralTransfer,
  csegStaffComment,
  municipalitySelectTips,
  municipalityCalculationTips,
  municipalityTimeDivisionTips,
  specificBetterSupporterCondition202404Tips,
  oldTreatmentImprovementAdditionTips,
  workContinuationNotPlanningFlg,
  seeHearTeam202404Tips,
  calculationPossibleUntil20240531Tips,
  calculatedHoursTips
};

const HelpTipMessages = ({ name, classes }: Props): JSX.Element => {
  const HelpComponent = HelpMessages[name];
  return <HelpComponent classes={classes} />;
};

export default withStyles(styles)(HelpTipMessages);
