import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { Lockout, UnLock } from "@stores/auth/type";

const styles = createStyles({
  errorText: {
    fontSize: 12,
    color: "#f44336",
    lineHeight: "16px",
    width: 384,
    margin: "20px auto 32px"
  },
  onlyPc: {
    "@media only screen and (min-width: 640px)": {
      display: "block"
    },
    "@media only screen and (max-width: 639px)": {
      display: "none"
    }
  }
});

type OwnProps = {
  lockout: Lockout | null;
  unlock: UnLock | null;
};
type Props = OwnProps & WithStyles<typeof styles>;

const LoginErrorMessage = ({
  lockout,
  unlock,
  classes
}: Props): JSX.Element => {
  let message;
  if (lockout && lockout.count === lockout.limit && unlock) {
    message = (
      <>
        ログインに{lockout.count}回失敗したため、現在はログインできません。
        <br className={classes.onlyPc} />
        {unlock.time}分後に再度お試しください。
      </>
    );
  } else if (lockout && lockout.count >= 5) {
    message = (
      <>
        ログインに {lockout.count}回 失敗しました。
        <br className={classes.onlyPc} />
        {lockout.limit}
        回ログインに失敗すると、10分間ログインできなくなりますのでご注意ください。
      </>
    );
  } else {
    message = <>ログインに失敗しました。</>;
  }
  return <p className={classes.errorText}>{message}</p>;
};

export default withStyles(styles)(LoginErrorMessage);
