import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";

import FormikCheckbox from "@components/molecules/FormikCheckbox";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";

import {
  SupportCarePlanDetails,
  SupportCarePlanSchedules
} from "@interfaces/record/carePlan/carePlan";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    item: {
      padding: "16px",
      display: "flex",
      alignItems: "flex-start",
      borderBottom: "1px solid #e0e0e0"
    },
    ML24: {
      marginLeft: "24px"
    },
    ML16: {
      marginLeft: "16px"
    },
    W236: {
      width: "236px",
      "&>div": {
        width: "100%"
      }
    },
    W72: {
      maxWidth: "72px",
      "&>div": {
        width: "100%"
      }
    },
    W172: {
      width: "172px",
      "&>div": {
        width: "100%"
      }
    },
    W56: {
      width: "56px",
      "&>div": {
        width: "100%"
      }
    },
    W262: {
      width: "262px",
      "&>div": {
        width: "100%"
      }
    },
    W144: {
      width: "144px"
    },
    W406: {
      width: "406px"
    },
    W280: {
      width: "280px"
    },
    checkBox: {
      "&>div": {
        margin: 0
      },
      "&>div>div": {
        margin: 0,
        padding: 0,
        "&>label": {
          margin: 0,
          "&>span": {
            padding: 0
          }
        }
      }
    }
  });

type OwnProps = {
  item: SupportCarePlanDetails;
  isEditing: boolean;
  nameNumber: number;
  formikProps: SupportCarePlanSchedules;
  facilityType: FacilityType;
};
type Props = OwnProps & WithStyles<typeof styles>;

const DetailsItemCore = (props: Props): JSX.Element => {
  const {
    classes,
    item,
    isEditing,
    nameNumber,
    formikProps,
    facilityType
  } = props;

  // サービス内容が未選択の時disabled
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const setDisabled = (): void => {
    if (formikProps && formikProps.status === "1") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };
  React.useEffect(() => {
    if (facilityType === FacilityType.KYOTAKUKAIGO) {
      setDisabled();
    } else if (
      facilityType === FacilityType.JUDOHOMONKAIGO ||
      facilityType === FacilityType.DOKOENGO ||
      facilityType === FacilityType.KODOENGO
    ) {
      setIsDisabled(false);
    }
  }, [formikProps, facilityType]);

  // 削除を選択した時disabled
  const [isDelete, setIsDelete] = React.useState<boolean>(true);
  React.useEffect(() => {
    setIsDelete(formikProps.support_care_plan_details[nameNumber].is_delete);
  }, [formikProps.support_care_plan_details[nameNumber].is_delete]);

  return (
    <div className={classes.item}>
      <div className={classes.W72}>
        {isDisabled ? (
          <ReadonlyTextField value="" label="" defaultValue="" />
        ) : (
          <RecordTextField
            name={`support_care_plan_details[${nameNumber}].time_required`}
            value={item.time_required}
            defaultValue=""
            placeholder=""
            maxLength={10}
            isEditable={isEditing && !isDelete}
          />
        )}
      </div>
      <div className={ClassNames(classes.W144, classes.ML16)}>
        {isDisabled ? (
          <ReadonlyTextField value="" label="" defaultValue="" />
        ) : (
          <RecordTextField
            name={`support_care_plan_details[${nameNumber}].service_details`}
            value={item.service_details}
            defaultValue=""
            placeholder=""
            maxLength={20}
            isEditable={isEditing && !isDelete}
          />
        )}
      </div>
      <div className={ClassNames(classes.W406, classes.ML16)}>
        {isDisabled ? (
          <ReadonlyTextField value="" label="" defaultValue="" />
        ) : (
          <RecordTextField
            name={`support_care_plan_details[${nameNumber}].point`}
            value={item.point}
            defaultValue=""
            placeholder=""
            isEditable={isEditing && !isDelete}
          />
        )}
      </div>
      <div className={ClassNames(classes.W280, classes.ML16)}>
        {isDisabled ? (
          <ReadonlyTextField value="" label="" defaultValue="" />
        ) : (
          <RecordTextField
            name={`support_care_plan_details[${nameNumber}].to_do`}
            value={item.to_do}
            defaultValue=""
            placeholder=""
            isEditable={isEditing && !isDelete}
          />
        )}
      </div>

      <div className={ClassNames(classes.checkBox, classes.ML24)}>
        {!isDisabled && isEditing && (
          <FormikCheckbox
            name={`support_care_plan_details[${nameNumber}].is_delete`}
            label=""
          />
        )}
      </div>
    </div>
  );
};

export const DetailsItem = withStyles(styles)(DetailsItemCore);
