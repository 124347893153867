import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { inOutConsultationResults } from "@api/requests/v202104/inOutConsultationResults";
import {
  RequestParamToPostAPI,
  RequestParamMonthlyToPostAPI
} from "@api/requests/v202104/inOutConsultationResults/postInOutConsultationResultsMonthly";
import { dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeCHIIKITEICHAKUMonthlyReportDataFromAPI,
  normalizeUsageResultFromForm,
  normalizeFormValueToAPI,
  normalizeFormValueMonthlyToAPI
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/report/initial";
import { ReportState } from "./types";

/**
 * 利用実績一覧(月ごと)のデータ取得及びstore格納
 */
const fetchCHIIKITEICHAKUMonthly = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCHIIKITEICHAKUMonthlyStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchCHIIKITEICHAKUMonthly(
          normalizeCHIIKITEICHAKUMonthlyReportDataFromAPI(response.data, date)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchCHIIKITEICHAKUMonthlyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postCHIIKITEICHAKUReport = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date,
  formValue: InitialValues,
  storeValue: ReportState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCHIIKITEICHAKUReportStarted());
  const normalizedFormValue = normalizeUsageResultFromForm(
    formValue,
    storeValue
  );
  const requestParam: RequestParamToPostAPI = normalizeFormValueToAPI(
    normalizedFormValue,
    storeValue
  );
  await inOutConsultationResults
    .postInOutConsultationResultsMonthly(
      requestParam,
      uifId,
      dateInYYYYMMFormat(date)
    )
    .then(() => {
      const actionType = actions.postCHIIKITEICHAKUReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postCHIIKITEICHAKUReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績(常時連絡体制確保の未実施)更新
 */
const postCHIIKITEICHAKUReportMonthly = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date,
  formValue: boolean
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCHIIKITEICHAKUReportStarted());
  const requestParam: RequestParamMonthlyToPostAPI = normalizeFormValueMonthlyToAPI(
    formValue
  );
  await inOutConsultationResults
    .postInOutResultsMonthly(requestParam, uifId, dateInYYYYMMFormat(date))
    .then(() => {
      const actionType = actions.postCHIIKITEICHAKUReportMonthly(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postCHIIKITEICHAKUReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchCHIIKITEICHAKUMonthly: (uifId: number, date: Date) => Promise<void>;
  postCHIIKITEICHAKUReport: (
    uifId: number,
    date: Date,
    formValue: InitialValues,
    storeValue: ReportState
  ) => Promise<void>;
  postCHIIKITEICHAKUReportMonthly: (
    uifId: number,
    date: Date,
    formValue: boolean
  ) => Promise<void>;
};

export const CHIIKITEICHAKUReport202104Dispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchCHIIKITEICHAKUMonthly: fetchCHIIKITEICHAKUMonthly(dispatch),
  postCHIIKITEICHAKUReport: postCHIIKITEICHAKUReport(dispatch),
  postCHIIKITEICHAKUReportMonthly: postCHIIKITEICHAKUReportMonthly(dispatch)
});
