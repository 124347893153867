import * as React from "react";
import * as URL from "@constants/url";
import { getUrlParams } from "@/utils/url";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { FacilityState } from "@stores/domain/mgr/CHIIKIIKO/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { AssistanceReportState } from "@stores/domain/mgr/CHIIKIIKO/assistanceReport/types";
import { AssistanceReportPrintContent } from "@components/organisms/mgr/CHIIKIIKO/record/assistanceReport/AssistanceReportPrintContent";

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  assistanceReportsId: string;
}>;
type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  assistanceReport: AssistanceReportState["assistanceReport"];
};
type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchAssistanceReport: (
    uifId: string,
    assistanceReportsId: string
  ) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const AssistanceReportPrintCore = (props: Props): JSX.Element => {
  const { search } = props.location;
  const { uifId, assistanceReportsId } = props.match.params;
  const { facility, user, assistanceReport } = props;
  // ページ名
  const listName = "計画・報告書・会議記録";
  const pageName = "地域移行支援報告書";
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // コメント取得
  const [isStaffComment, setIsStaffComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_comment?: string;
    } = getUrlParams(search);
    setIsStaffComment(queryParameter.display_comment === "staff_comment");
  }, [search]);

  // fetch
  React.useEffect(() => {
    props.fetchFacility();
    props.fetchOneUser(uifId);
    props.fetchAssistanceReport(uifId, assistanceReportsId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <AssistanceReportPrintContent
          facility={facility}
          user={user}
          assistanceReport={assistanceReport}
          isStaffComment={isStaffComment}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.CHIIKIIKO.facility,
    user: state.CHIIKIIKO.userInFacility.user,
    assistanceReport: state.CHIIKIIKO.assistanceReport.assistanceReport
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO } = dispatches;
  return {
    fetchFacility: CHIIKIIKO.facilityDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      CHIIKIIKO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchAssistanceReport: (
      uifId: string,
      assistanceReportsId: string
    ): Promise<void> =>
      CHIIKIIKO.assistanceReportDispatcher(dispatch).fetchAssistanceReport(
        uifId,
        assistanceReportsId
      )
  };
};

export const AssistanceReportPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(AssistanceReportPrintCore);
