import { SelectDateValue, SelectMonthValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * SelectDateValue => string (YYYYMM)
 * 月を0埋めする
 */

const selectDateValueToDatePaddingZeroWithoutDay = (
  value: SelectDateValue | SelectMonthValue
): string | null => {
  return value.year && value.month
    ? dateToLocalisedString(`${value.year}-${value.month}`, "YYYYMM")
    : null;
};

export default selectDateValueToDatePaddingZeroWithoutDay;
