import {
  assessmentInput,
  AssessmentState
} from "@stores/domain/assessment/types";
import get from "lodash-es/get";
import {
  FieldItem,
  InputValue,
  SelectDateValue,
  SelectMonthValue
} from "@interfaces/ui/form";
import { dateToObject, dateToSelectDateValue } from "@utils/date";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  IKOU_ASSESSMENT_DEFAULT_ITEM,
  FacilityType
} from "@constants/variables";
import {
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  checkItemIsLifeHistories,
  checkItemIsDisabilityHistories,
  checkItemIsFlowDays
} from "@utils/domain/mgr/Cseg/assessment/variableFromFacility";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import { dateToSelectMonthValue } from "@utils/date/dateToSelectMonthValue";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

type AssessmentInput = {
  id: number | null;
  custom_record_item_id: number;
  input_data: string;
  /** keyがchoiceのidとなる */
  multiTexts?: {
    [key: string]: {
      id: number | null;
      value: string;
    };
  };
  evaluation_period?: EvaluationPeriod | null;
  work_histories?: WorkHistory[] | null;
  life_histories?: LifeHistory[] | null;
  disability_histories?: DisabilityHistory[] | null;
  flow_days?: FlowDays[] | null;
  choiced_item_id?: string;
  checked?: boolean;
};

/** 評価期間の情報 */
export type EvaluationPeriodRecord = {
  id: number | null;
  custom_record_item_id: number;
  choiced_item_id: number;
  date: SelectDateValue;
};

/** 評価期間 */
export type EvaluationPeriod = {
  start: EvaluationPeriodRecord;
  end: EvaluationPeriodRecord;
};

export type WorkHistory = {
  id: number | null;
  begin_date: SelectMonthValue;
  end_date: SelectMonthValue;
  facility_name: string;
  details: string;
  is_delete: number;
};

/** 生活歴 */
export type LifeHistory = {
  id: number | null;
  yyyymm: string;
  matter: string;
  isDelete: number;
};

/** 障害・疾病歴 */
export type DisabilityHistory = {
  id: number | null;
  yyyymm: string;
  matter: string;
  isDelete: number;
};

/** １日の流れ */
export type FlowDays = {
  id: number | null;
  title: string;
  flow_day_details_user: FlowDayDetail[];
  flow_day_details_caregiver: FlowDayDetail[];
  isDelete: number;
};

export type FlowDayDetail = {
  id: number | null;
  start_time: string;
  end_time: string;
  matter: string;
  isDelete: number;
};

export type Assessment = {
  created_at: InputValue;
  updated_at: InputValue;
  assessment_records_id: number | null;
  target_date: SelectDateValue;
  author: number | null;
  assessment_records: {
    custom_records_category_id: number;
    input: AssessmentInput[];
  }[];
};

export const assessmentInputInitialValue = (): WorkHistory => {
  return {
    id: null,
    begin_date: { year: "", month: "" },
    end_date: { year: "", month: "" },
    facility_name: "",
    details: "",
    is_delete: 0
  };
};

export const lifeHistoryInitialValue = (): LifeHistory => ({
  id: null,
  yyyymm: "",
  matter: "",
  isDelete: 0
});

export const disabilityHistoryInitialValue = (): DisabilityHistory => ({
  id: null,
  yyyymm: "",
  matter: "",
  isDelete: 0
});

export const flowDaysInitialValue = (): FlowDays => ({
  id: null,
  title: "1日の流れ",
  flow_day_details_user: [
    {
      id: null,
      start_time: "",
      end_time: "",
      matter: "",
      isDelete: 0
    }
  ],
  flow_day_details_caregiver: [
    {
      id: null,
      start_time: "",
      end_time: "",
      matter: "",
      isDelete: 0
    }
  ],
  isDelete: 0
});

export const flowDayDetailInitialValue = (): FlowDayDetail => ({
  id: null,
  start_time: "",
  end_time: "",
  matter: "",
  isDelete: 0
});

/** 対象項目のカテゴリ・項目ともに表示状態となっているかチェック */
const checkItemVisible = (
  targetCategory: CustomRecordsWithCategoryState[number],
  targetItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number],
  facilityType?: FacilityType,
  typeConsultation?: number
): boolean => {
  if (
    facilityType === FacilityType.KEIKAKUSODAN &&
    typeConsultation &&
    targetCategory.custom_records_keikakusodan_visibility &&
    targetItem.custom_records_keikakusodan_visibility
  ) {
    if (typeConsultation === TYPE_CONSULTATION_KEIKAKUSODAN) {
      return (
        numberToBoolean(
          targetCategory.custom_records_keikakusodan_visibility.plan_visibility
        ) &&
        numberToBoolean(
          targetItem.custom_records_keikakusodan_visibility.plan_visibility
        )
      );
    }
    if (typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
      return (
        numberToBoolean(
          targetCategory.custom_records_keikakusodan_visibility.child_visibility
        ) &&
        numberToBoolean(
          targetItem.custom_records_keikakusodan_visibility.child_visibility
        )
      );
    }
  }
  return (
    numberToBoolean(targetCategory.visibility) &&
    numberToBoolean(targetItem.visibility)
  );
};

export const initialValues = (
  assessmentState?: AssessmentState["assessment"],
  customRecords?: CustomRecordsWithCategoryState,
  staffOptions?: FieldItem[],
  isCopy?: boolean,
  opt?: {
    facilityType?: FacilityType;
    typeConsultation?: number;
  }
): Assessment => {
  if (!assessmentState || !customRecords) {
    return {
      created_at: "",
      updated_at: "",
      assessment_records_id: null,
      target_date: { year: "NOT_SELECTED", month: "", day: "" },
      author: null,
      assessment_records: []
    };
  }
  const getInput = (
    recordId: number,
    itemId: number
  ): assessmentInput | undefined => {
    const assessmentRecord = assessmentState.assessment.assessment_records.find(
      (r) => r.custom_records_category_id === recordId
    );
    if (!assessmentRecord) {
      return undefined;
    }
    return assessmentRecord.input.find((item) => {
      return item.custom_record_item_id === itemId;
    });
  };

  const getInputs = (recordId: number, itemId: number): assessmentInput[] => {
    const assessmentRecord = assessmentState.assessment.assessment_records.find(
      (r) => r.custom_records_category_id === recordId
    );
    if (!assessmentRecord) {
      return [];
    }
    return assessmentRecord.input.filter((item) => {
      return item.custom_record_item_id === itemId;
    });
  };

  const assessment_records: Assessment["assessment_records"] = customRecords.map(
    (category) => {
      return {
        custom_records_category_id: category.id,
        input: category.custom_record_items.map((item) => {
          const input = getInput(category.id, item.id);
          let obj: AssessmentInput;
          if (
            input &&
            !input.work_histories &&
            !input.life_histories &&
            !input.disability_histories &&
            !input.flow_days
          ) {
            obj = {
              id: isCopy ? null : input.id,
              custom_record_item_id: item.id,
              input_data: input.input_data || "",
              choiced_item_id: `${input.choiced_item_id || ""}`,
              checked: determineTrueOrFalse(
                castNumberCheckboxValue(input.checked)
              )
            };
            // Aセグの「就労歴・訓練歴等」の項目のみwork_historiesが返却される
          } else if (input && input.work_histories) {
            obj = {
              id: isCopy ? null : input.id,
              custom_record_item_id: item.id,
              input_data: input.input_data || "",
              work_histories: input.work_histories.map((v) => {
                return {
                  ...v,
                  id: isCopy ? null : v.id,
                  begin_date: dateToSelectMonthValue(v.begin_date),
                  end_date: dateToSelectMonthValue(v.end_date),
                  is_delete: 0
                };
              }),
              choiced_item_id: `${input.choiced_item_id || ""}`,
              checked: determineTrueOrFalse(
                castNumberCheckboxValue(input.checked)
              )
            };
            // 計画相談、地域移行、地域定着の「生活歴」項目のみlife_historiesが返却される
          } else if (
            input &&
            input.life_histories &&
            input.life_histories.length
          ) {
            obj = {
              id: isCopy ? null : input.id,
              custom_record_item_id: item.id,
              input_data: input.input_data || "",
              life_histories: input.life_histories.map((v) => ({
                yyyymm: v.yyyymm || "",
                matter: v.matter || "",
                id: isCopy ? null : v.id,
                isDelete: 0
              })),
              choiced_item_id: "",
              checked: determineTrueOrFalse(castNumberCheckboxValue(0))
            };
            // 計画相談、地域移行、地域定着の「障害・疾病歴」項目のみdisability_historiesが返却される
          } else if (
            input &&
            input.disability_histories &&
            input.disability_histories.length
          ) {
            obj = {
              id: isCopy ? null : input.id,
              custom_record_item_id: item.id,
              input_data: input.input_data || "",
              disability_histories: input.disability_histories.map((v) => ({
                yyyymm: v.yyyymm || "",
                matter: v.matter || "",
                id: isCopy ? null : v.id,
                isDelete: 0
              })),
              choiced_item_id: "",
              checked: determineTrueOrFalse(castNumberCheckboxValue(0))
            };
            // 計画相談、地域移行、地域定着の「生活状況」項目のみflow_daysが返却される
          } else if (input && input.flow_days && input.flow_days.length) {
            obj = {
              id: isCopy ? null : input.id,
              custom_record_item_id: item.id,
              input_data: input.input_data || "",
              flow_days: input.flow_days.map((flowDay) => ({
                id: isCopy ? null : flowDay.id,
                title: flowDay.title || "",
                flow_day_details_user: flowDay.flow_day_details_user.map(
                  (v) => ({
                    start_time: v.start_time ? v.start_time.slice(0, 5) : "",
                    end_time: v.end_time ? v.end_time.slice(0, 5) : "",
                    matter: v.matter || "",
                    id: isCopy ? null : v.id,
                    isDelete: 0
                  })
                ),
                flow_day_details_caregiver: flowDay.flow_day_details_caregiver.map(
                  (v) => ({
                    start_time: v.start_time ? v.start_time.slice(0, 5) : "",
                    end_time: v.end_time ? v.end_time.slice(0, 5) : "",
                    matter: v.matter || "",
                    id: isCopy ? null : v.id,
                    isDelete: 0
                  })
                ),
                isDelete: 0
              })),
              choiced_item_id: "",
              checked: determineTrueOrFalse(castNumberCheckboxValue(0))
            };
          } else {
            obj = {
              id: null,
              custom_record_item_id: item.id,
              input_data: "",
              work_histories: null,
              choiced_item_id: "",
              checked: determineTrueOrFalse(castNumberCheckboxValue(0))
            };
          }
          if (item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text) {
            delete obj.checked;
            delete obj.choiced_item_id;
          }
          if (item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text) {
            delete obj.checked;
            delete obj.choiced_item_id;
            const isVisible = category.visibility && item.visibility;
            const inputs = getInputs(category.id, item.id);
            const hasValue = inputs.some((v) => !!v.input_data);
            if (isVisible || hasValue || isCopy) {
              obj.multiTexts = item.custom_record_item_choices.reduce<
                AssessmentInput["multiTexts"]
              >((prev, current) => {
                const value = inputs.find(
                  (v) => v.choiced_item_id === current.id
                );
                return {
                  ...prev,
                  [current.id]: {
                    id: !isCopy && value ? value.id : null,
                    value: value ? value.input_data : ""
                  }
                };
              }, {});
            }
          }
          if (item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio) {
            delete obj.checked;
          }
          if (item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox) {
            // 表示OFFの場合
            if (!category.visibility || !item.visibility) {
              delete obj.checked;
              delete obj.choiced_item_id;
              // 表示ONかつ新規作成の場合
            } else if (!input) {
              obj.choiced_item_id = `${item.custom_record_item_choices[0].id}`;
            }
          }
          if (item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.others) {
            delete obj.checked;
            delete obj.choiced_item_id;
            /**
             * カテゴリも項目も表示状態となっている
             * 用途としては、データがない場合は初期値の登録が必要
             */
            const isVisible = checkItemVisible(
              category,
              item,
              opt && opt.facilityType,
              opt && opt.typeConsultation
            );
            const isLifeHistories =
              opt &&
              opt.facilityType &&
              checkItemIsLifeHistories(opt.facilityType, item.default_item);
            const isDisabilityHistories =
              opt &&
              opt.facilityType &&
              checkItemIsDisabilityHistories(
                opt.facilityType,
                item.default_item
              );
            const isFlowDays =
              opt &&
              opt.facilityType &&
              checkItemIsFlowDays(opt.facilityType, item.default_item);

            // 生活歴初期値設定
            if (isLifeHistories && isVisible && !obj.life_histories) {
              if (input) {
                obj.life_histories = [];
              } else {
                obj.life_histories = [
                  {
                    id: null,
                    yyyymm: "",
                    matter: "",
                    isDelete: 0
                  }
                ];
              }
            }

            // 障害・疾病歴初期値設定
            if (
              isDisabilityHistories &&
              isVisible &&
              !obj.disability_histories
            ) {
              if (input) {
                obj.disability_histories = [];
              } else {
                obj.disability_histories = [
                  {
                    id: null,
                    yyyymm: "",
                    matter: "",
                    isDelete: 0
                  }
                ];
              }
            }

            // 1日の流れ
            if (isFlowDays && isVisible && !obj.flow_days) {
              obj.flow_days = input ? [] : [flowDaysInitialValue()];
            }

            if (
              !isLifeHistories &&
              !isDisabilityHistories &&
              !isFlowDays &&
              obj.work_histories === null
            ) {
              obj.work_histories = [
                {
                  id: null,
                  begin_date: { year: "", month: "" },
                  end_date: { year: "", month: "" },
                  facility_name: "",
                  details: "",
                  is_delete: 0
                }
              ];
            }
          }
          // 評価期間の場合
          if (
            opt &&
            opt.facilityType === FacilityType.IKOU &&
            item.default_item === IKOU_ASSESSMENT_DEFAULT_ITEM.evaluationPeriod
          ) {
            delete obj.checked;
            delete obj.choiced_item_id;
            const findEvaluationPeriodInput = (
              assessment: AssessmentState["assessment"]["assessment"]["assessment_records"],
              choiceId: number
            ):
              | AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]
              | null => {
              const evaluationCategory = assessment.find(
                (v) => v.custom_records_category_id === category.id
              );
              if (!evaluationCategory) return null;
              const evaluationItem = evaluationCategory.input.find(
                (v) =>
                  v.custom_record_item_id === item.id &&
                  Number(v.choiced_item_id) === choiceId
              );
              return evaluationItem || null;
            };
            const evaluationPeriod = item.custom_record_item_choices.reduce<
              EvaluationPeriod
            >(
              (evaluationBase, choice) => {
                // 開始時間 or 終了時間のitem
                const evaluationPeriodItem = findEvaluationPeriodInput(
                  assessmentState.assessment.assessment_records,
                  choice.id
                );
                const evaluationPeriodData: EvaluationPeriodRecord = {
                  id:
                    evaluationPeriodItem && !isCopy
                      ? evaluationPeriodItem.id
                      : null,
                  custom_record_item_id: item.id,
                  choiced_item_id: choice.id,
                  date:
                    evaluationPeriodItem && evaluationPeriodItem.input_data
                      ? dateToSelectDateValue(evaluationPeriodItem.input_data)
                      : {
                          year: "",
                          month: "",
                          day: ""
                        }
                };
                if (choice.default_choice === 1) {
                  return {
                    ...evaluationBase,
                    start: evaluationPeriodData
                  };
                }
                if (choice.default_choice === 2) {
                  return {
                    ...evaluationBase,
                    end: evaluationPeriodData
                  };
                }
                return evaluationBase;
              },
              {
                start: {
                  id: null,
                  custom_record_item_id: 0,
                  choiced_item_id: 0,
                  date: {
                    year: "",
                    month: "",
                    day: ""
                  }
                },
                end: {
                  id: null,
                  custom_record_item_id: 0,
                  choiced_item_id: 0,
                  date: {
                    year: "",
                    month: "",
                    day: ""
                  }
                }
              }
            );
            obj.evaluation_period = evaluationPeriod;
          }
          return obj;
        })
      };
    }
  );
  const target_date =
    get(assessmentState.assessment, "target_date") && !isCopy
      ? dateToSelectDateValue(
          undefinedToReturnValue(get(assessmentState.assessment, "target_date"))
        )
      : dateToObject(new Date());
  const assessment_records_id = isCopy
    ? null
    : assessmentState.assessment.assessment_records_id;

  // スナップショットを選択している場合、選択している値を0にする
  let authorId = null;
  if (staffOptions && assessmentState.author) {
    const authorIsDelete = staffOptions.every(
      (option) =>
        assessmentState.author &&
        option.value !== String(assessmentState.author.id)
    );
    authorId =
      assessmentState.author.name !== assessmentState.author.snapshot_name &&
      !authorIsDelete
        ? 0
        : assessmentState.author.id;
  }

  return {
    created_at:
      assessmentState && assessmentState.created_at
        ? assessmentState.created_at
        : "",
    updated_at:
      assessmentState && assessmentState.updated_at
        ? assessmentState.updated_at
        : "",
    assessment_records_id,
    target_date,
    author: !isCopy ? authorId : null,
    assessment_records
  };
};
