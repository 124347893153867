import { combineReducers } from "redux";
import { isType } from "typescript-fsa";
import { userCacheClear } from "@utils/localStorage";
import { logout } from "@stores/auth/action";

// app
import loading from "@stores/loading/reducers";
import auth from "@stores/auth/reducer";

// domain
import account from "@stores/domain/account/reducer";
import attendance from "@stores/domain/attendance/reducer";
import city from "@stores/domain/city/reducer";
import errors from "@stores/domain/errors/reducers";
import facilities from "@stores/domain/facilities/reducers";
import invoice from "@stores/domain/invoice/reducer";
import operations from "@stores/domain/operations/reducers";
import report from "@stores/domain/report/reducer";
import user from "@stores/domain/user/reducer";
import offsiteWork from "@stores/domain/offsiteWork/reducers";
import work from "@stores/domain/work/reducers";
import staff from "@stores/domain/staff/reducers";
import supportPlan from "@stores/domain/supportPlan/reducers";
import supports from "@stores/domain/supports/reducers";
import userSummary from "@stores/domain/userSummary/reducers";
import workRecords from "@stores/domain/workRecords/reducers";
import supportRecordUser from "@stores/domain/supportRecordUser/reducers";
import supportReport from "@stores/domain/supportReport/reducers";
import units from "@stores/domain/units/reducers";
import customRecords from "@stores/domain/customRecords/reducers";
import { customRecordsWithCategory } from "@stores/domain/customRecordsWithCategory/reducers";
import holiday from "@stores/domain/holiday/reducers";
import { serviceDelivery } from "@stores/domain/serviceDelivery/reducers";
import { assessment } from "@stores/domain/assessment/reducers";
import { carePlan } from "@stores/domain/carePlan/reducers";
import { supportPlanSheets } from "@stores/domain/supportPlanSheets/reducers";

// ui
import alertDialog from "@stores/ui/alertDialog/reducers";
import errorsDialog from "@stores/ui/errorsDialog/reducers";
import navigationTransition from "@stores/ui/navigationTransition/reducers";
import responseError from "@stores/ui/responseError/reducers";
import snackbar from "@stores/ui/snackbar/reducers";

// pages
import appDownload from "@stores/ui/download/reducer";
import recordDaily from "@stores/pages/record/daily/reducers";
import recordMonthly from "@stores/pages/record/monthly/reducers";
import recordSupportPlan from "@stores/pages/record/supportPlan/reducers";
import recordSupportReport from "@stores/pages/record/supportReport/reducers";
import recordSupportPlanA from "@stores/pages/record/supportPlanA/reducers";
import recordUserDetail from "@stores/pages/record/userDetail/reducers";
import reportDaily from "@stores/pages/report/daily/reducers";
import recordOffsiteWork from "@stores/pages/record/offsiteWork/reducers";
import recordUsersDaily from "@stores/pages/record/usersDaily/reducers";

// GroupHome
import GroupHomeFacility from "@stores/domain/mgr/GroupHome/facility/reducers";
import GroupHomeUserInFacility from "@stores/domain/mgr/GroupHome/userInFacility/reducers";
import GroupHomeSupportRecordUser from "@stores/domain/mgr/GroupHome/supportRecordUser/reducers";
import GroupHomeDailyOperationsAndSupports from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/reducers";
import GroupHomeOperations from "@stores/domain/mgr/GroupHome/operations/reducers";
import GroupHomeUsersSummary from "@stores/domain/mgr/GroupHome/usersSummary/reducers";
import GroupHomeReport from "@stores/domain/mgr/GroupHome/report/reducers";
import GroupHomeTargetUnit from "@stores/domain/mgr/GroupHome/targetUnit/reducers";

// 生活介護
import SEIKATSUKAIGOFacility from "@stores/domain/mgr/SEIKATSUKAIGO/facility/reducers";
import SEIKATSUKAIGOUserInFacility from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/reducers";
import SEIKATSUKAIGOReport from "@stores/domain/mgr/SEIKATSUKAIGO/report/reducers";
import SEIKATSUKAIGOInitial from "@stores/domain/mgr/SEIKATSUKAIGO/initial/reducers";

// 就労定着支援
import SHUROTEICHAKUUserInFacility from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/reducers";
import SHUROTEICHAKUFacility from "@stores/domain/mgr/SHUROTEICHAKU/facility/reducers";
import SHUROTEICHAKUReport from "@stores/domain/mgr/SHUROTEICHAKU/report/reducers";
import SHUROTEICHAKUSupportPlan from "@stores/domain/mgr/SHUROTEICHAKU/supportPlan/reducers";

// 自立訓練（生活訓練）
import JIRITSUKUNRENSEIKATSUFacility from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/reducers";
import JIRITSUKUNRENSEIKATSUUserInFacility from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/reducers";
import JIRITSUKUNRENSEIKATSUReport from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/report/reducers";
import JIRITSUKUNRENSEIKATSUInitial from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/initial/reducers";

// 移行AB
import IABFacility from "@stores/domain/mgr/IAB/facility/reducers";
import IABUserInFacility from "@stores/domain/mgr/IAB/userInFacility/reducers";
import IABReport from "@stores/domain/mgr/IAB/report/reducers";
import IABInitial from "@stores/domain/mgr/IAB/initial/reducers";
import IABUsersSummary from "@stores/domain/mgr/IAB/usersSummary/reducers";
import ASupportPlan from "@stores/domain/mgr/A/supportPlan/reducers";

import GroupHomeSupportPlan from "@stores/domain/mgr/GroupHome/supportPlan/reducers";

// 短期入所
import TANKINYUSHOFacility from "@stores/domain/mgr/TANKINYUSHO/facility/reducers";
import TANKINYUSHOUserInFacility from "@stores/domain/mgr/TANKINYUSHO/userInFacility/reducers";
import TANKINYUSHODailyOperationsAndSupports from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/reducers";
import TANKINYUSHOSupportRecordUser from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/reducers";
import TANKINYUSHOReport from "@stores/domain/mgr/TANKINYUSHO/report/reducers";
import TANKINYUSHOInitial from "@stores/domain/mgr/TANKINYUSHO/initial/reducers";
import TANKINYUSHOOperations from "@stores/domain/mgr/TANKINYUSHO/operations/reducers";
import TANKINYUSHOUsersSummary from "@stores/domain/mgr/TANKINYUSHO/usersSummary/reducers";

// 施設入所支援
import SHISETSUNYUSHOFacility from "@stores/domain/mgr/SHISETSUNYUSHO/facility/reducers";
import SHISETSUNYUSHOUserInFacility from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/reducers";
import SHISETSUNYUSHODailyOperationsAndSupports from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/reducers";
import SHISETSUNYUSHOPaginatedDailySupports from "@stores/domain/mgr/SHISETSUNYUSHO/paginatedDailySupports/reducers";
import SHISETSUNYUSHOReport from "@stores/domain/mgr/SHISETSUNYUSHO/report/reducers";
import SHISETSUNYUSHOInitial from "@stores/domain/mgr/SHISETSUNYUSHO/initial/reducers";
import SHISETSUNYUSHOTargetUnit from "@stores/domain/mgr/SHISETSUNYUSHO/targetUnit/reducers";
import SHISETSUNYUSHOOperations from "@stores/domain/mgr/SHISETSUNYUSHO/operations/reducers";
import SHISETSUNYUSHOUsagePerformance from "@stores/domain/mgr/SHISETSUNYUSHO/usagePerformance/reducers";
import SHISETSUNYUSHOSupportRecordUser from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/reducers";
import SHISETSUNYUSHOUsersSummary from "@stores/domain/mgr/SHISETSUNYUSHO/usersSummary/reducers";

// 居宅介護
import { KYOTAKUKAIGOUserInFacility } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/reducers";
import { KYOTAKUKAIGOFacility } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/reducers";
import { KYOTAKUKAIGOReport } from "@stores/domain/mgr/KYOTAKUKAIGO/report/reducers";
import { KYOTAKUKAIGOStaff } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/reducers";
import { KYOTAKUKAIGOCarePlan } from "@stores/domain/mgr/KYOTAKUKAIGO/carePlan/reducers";

// 重度訪問介護
import { JUDOHOMONKAIGOUserInFacility } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/reducers";
import { JUDOHOMONKAIGOFacility } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/reducers";
import { JUDOHOMONKAIGOStaff } from "@stores/domain/mgr/JUDOHOMONKAIGO/staff/reducers";
import { JUDOHOMONKAIGOReport } from "@stores/domain/mgr/JUDOHOMONKAIGO/report/reducers";
import { JUDOHOMONKAIGOCarePlan } from "@stores/domain/mgr/JUDOHOMONKAIGO/carePlan/reducers";

// 同行援護
import { DOKOENGOReport } from "@stores/domain/mgr/DOKOENGO/report/reducers";
import { DOKOENGOUserInFacility } from "@stores/domain/mgr/DOKOENGO/userInFacility/reducers";
import { DOKOENGOFacility } from "@stores/domain/mgr/DOKOENGO/facility/reducers";
import { DOKOENGOCarePlan } from "@stores/domain/mgr/DOKOENGO/carePlan/reducers";
import { DOKOENGOStaff } from "@stores/domain/mgr/DOKOENGO/staff/reducers";

// 行動援護
import { KODOENGOReport } from "@stores/domain/mgr/KODOENGO/report/reducers";
import { KODOENGOFacility } from "@stores/domain/mgr/KODOENGO/facility/reducers";
import { KODOENGOUserInFacility } from "@stores/domain/mgr/KODOENGO/userInFacility/reducers";
import { KODOENGOCarePlan } from "@stores/domain/mgr/KODOENGO/carePlan/reducers";
import { KODOENGOStaff } from "@stores/domain/mgr/KODOENGO/staff/reducers";
import {
  KODOENGOSupportProcedure,
  KODOENGOSupportProcedureModal
} from "./domain/mgr/KODOENGO/supportProcedure/reducers";

// 地域移行
import { CHIIKIIKOUserInFacility } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/reducers";
import { CHIIKIIKOFacility } from "@stores/domain/mgr/CHIIKIIKO/facility/reducers";
import { CHIIKIIKOReport } from "@stores/domain/mgr/CHIIKIIKO/report/reducers";
import { CHIIKIIKOPlanMonitoringMeeting } from "./domain/mgr/CHIIKIIKO/planMonitoringMeeting/reducers";
import { CHIIKIIKOConsultation } from "./domain/mgr/CHIIKIIKO/consultation/reducers";
import { CHIIKIIKOAssistanceReport } from "./domain/mgr/CHIIKIIKO/assistanceReport/reducers";

// 地域定着
import { CHIIKITEICHAKUUserInFacility } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/reducers";
import { CHIIKITEICHAKUFacility } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/reducers";
import { CHIIKITEICHAKUReport } from "@stores/domain/mgr/CHIIKITEICHAKU/report/reducers";
import { CHIIKITEICHAKUSupportLedger } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/reducers";

// 計画相談
import { KEIKAKUSODANUserInFacility } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/reducers";
import { KEIKAKUSODANInitial } from "@stores/domain/mgr/KEIKAKUSODAN/initial/reducers";
import { KEIKAKUSODANFacility } from "@stores/domain/mgr/KEIKAKUSODAN/facility/reducers";
import { KEIKAKUSODANReport } from "@stores/domain/mgr/KEIKAKUSODAN/report/reducers";
import { KEIKAKUSODANInquiry } from "./domain/mgr/KEIKAKUSODAN/inquiry/reducers";
import { KEIKAKUSODANConsultation } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/reducers";
import { KEIKAKUSODANBasicInfo } from "./domain/mgr/KEIKAKUSODAN/basicInfo/reducers";
import { KEIKAKUSODANPlanMonitoringMeeting } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/reducers";
import { KEIKAKUSODANMonitoringReport } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringReport/reducers";
import { KEIKAKUSODANMonitoringMonth } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringMonth/reducers";

// 移動支援
import { IDOSHIENReport } from "@stores/domain/mgr/IDOSHIEN/report/reducers";
import { IDOSHIENFacility } from "./domain/mgr/IDOSHIEN/facility/reducers";
import { IDOSHIENUserInFacility } from "@stores/domain/mgr/IDOSHIEN/userInFacility/reducers";
import { IDOSHIENMunicipalitiesInFacility } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/reducers";

// Cseg
import { CsegOperations } from "@stores/domain/mgr/Cseg/operations/reducers";
import { CsegSupportRecordUser } from "@stores/domain/mgr/Cseg/support/reducers";
import { CsegUsersSummary } from "@stores/domain/mgr/Cseg/usersSummary/reducers";
import { CsegOperationsAndSupports } from "@stores/domain/mgr/Cseg/operationAndSupports/reducers";
import { CsegMeetingRecord } from "@stores/domain/mgr/Cseg/meetingRecord/reducers";

// v201910
import invoice201910 from "@stores/v201910/domain/invoice/reducer";

import ReportDaily201910 from "@stores/v201910/pages/report/daily/reducers";

import IABFacility201910 from "@stores/v201910/domain/mgr/IAB/facility/reducers";
import IABUserInFacility201910 from "@stores/v201910/domain/mgr/IAB/userInFacility/reducers";
import IABReport201910 from "@stores/v201910/domain/mgr/IAB/report/reducers";
import JIRITSUKUNRENSEIKATSUFacility201910 from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/reducers";
import JIRITSUKUNRENSEIKATSUUserInFacility201910 from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/reducers";
import JIRITSUKUNRENSEIKATSUReport201910 from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/report/reducers";
import GroupHomeFacility201910 from "@stores/v201910/domain/mgr/GroupHome/facility/reducers";
import GroupHomeUserInFacility201910 from "@stores/v201910/domain/mgr/GroupHome/userInFacility/reducers";
import GroupHomeReport201910 from "@stores/v201910/domain/mgr/GroupHome/report/reducer";
import SEIKATSUKAIGOFacility201910 from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/facility/reducers";
import SEIKATSUKAIGOUserInFacility201910 from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/userInFacility/reducers";
import SEIKATSUKAIGOReport201910 from "@stores/v201910/domain/mgr/SEIKATSUKAIGO/report/reducers";
import SHUROTEICHAKUFacility201910 from "@stores/v201910/domain/mgr/SHUROTEICHAKU/facility/reducers";
import SHUROTEICHAKUReport201910 from "@stores/v201910/domain/mgr/SHUROTEICHAKU/report/reducers";
import SHUROTEICHAKUUserInFacility201910 from "@stores/v201910/domain/mgr/SHUROTEICHAKU/userInFacility/reducers";
import TANKINYUSHOFacility201910 from "@stores/v201910/domain/mgr/TANKINYUSHO/facility/reducers";
import TANKINYUSHOUserInFacility201910 from "@stores/v201910/domain/mgr/TANKINYUSHO/userInFacility/reducers";
import TANKINYUSHOReport201910 from "@stores/v201910/domain/mgr/TANKINYUSHO/report/reducers";
import SHISETSUNYUSHOFacility201910 from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/facility/reducers";
import SHISETSUNYUSHOUserInFacility201910 from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/userInFacility/reducers";
import SHISETSUNYUSHOReport201910 from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/reducers";

// v202104
import invoice202104 from "@stores/v202104/domain/invoice/reducer";

import IABFacility202104 from "@stores/v202104/domain/mgr/IAB/facility/reducers";
import IABUserInFacility202104 from "@stores/v202104/domain/mgr/IAB/userInFacility/reducers";
import IABReport202104 from "@stores/v202104/domain/mgr/IAB/report/reducers";
import JIRITSUKUNRENSEIKATSUFacility202404 from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/reducers";
import JIRITSUKUNRENSEIKATSUUserInFacility202404 from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/reducers";
import JIRITSUKUNRENSEIKATSUReport202104 from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/report/reducers";
import SHUROTEICHAKUReport202104 from "@stores/v202104/domain/mgr/SHUROTEICHAKU/report/reducers";
import SHUROTEICHAKUUserInFacility202104 from "@stores/v202104/domain/mgr/SHUROTEICHAKU/userInFacility/reducers";
import SHUROTEICHAKUFacility202104 from "@stores/v202104/domain/mgr/SHUROTEICHAKU/facility/reducers";
import GroupHomeReport202104 from "@stores/v202104/domain/mgr/GroupHome/report/reducer";
import GroupHomeUserInFacility202104 from "@stores/v202104/domain/mgr/GroupHome/userInFacility/reducers";
import { KEIKAKUSODANUserInFacility as KEIKAKUSODANUserInFacility202104 } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/reducers";
import { KEIKAKUSODANFacility as KEIKAKUSODANFacility202104 } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/reducers";
import { KEIKAKUSODANReport as KEIKAKUSODANReport202104 } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/reducers";
import { KODOENGOFacility as KODOENGOFacility202104 } from "@stores/v202104/domain/mgr/KODOENGO/facility/reducers";
import GroupHomeFacility202104 from "@stores/v202104/domain/mgr/GroupHome/facility/reducers";
import SEIKATSUKAIGOReport202104 from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/report/reducers";
import SEIKATSUKAIGOUserInFacility202104 from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/userInFacility/reducers";
import SEIKATSUKAIGOFacility202104 from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/facility/reducers";
import TANKINYUSHOFacility202104 from "@stores/v202104/domain/mgr/TANKINYUSHO/facility/reducers";
import TANKINYUSHOReport202104 from "@stores/v202104/domain/mgr/TANKINYUSHO/report/reducers";
import TANKINYUSHOUserInFacility202104 from "@stores/v202104/domain/mgr/TANKINYUSHO/userInFacility/reducers";
import SHISETSUNYUSHOFacility202104 from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/facility/reducers";
import SHISETSUNYUSHOUserInFacility202104 from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/userInFacility/reducers";
import SHISETSUNYUSHOReport202104 from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/report/reducers";
import { CHIIKITEICHAKUUserInFacility202104 } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/userInFacility/reducers";
import { CHIIKITEICHAKUFacility202104 } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/facility/reducers";
import { CHIIKITEICHAKUReport202104 } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/report/reducers";
import { KYOTAKUKAIGOFacility as KYOTAKUKAIGOFacility202104 } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/reducers";
import { KYOTAKUKAIGOUserInFacility as KYOTAKUKAIGOUserInFacility202104 } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/reducers";
import { KYOTAKUKAIGOCarePlan as KYOTAKUKAIGOCarePlan202104 } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/carePlan/reducers";
import { KYOTAKUKAIGOReport as KYOTAKUKAIGOReport202104 } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/reducers";
import { CHIIKIIKOFacility202104 } from "@stores/v202104/domain/mgr/CHIIKIIKO/facility/reducers";
import { CHIIKIIKOUserInFacility202104 } from "@stores/v202104/domain/mgr/CHIIKIIKO/userInFacility/reducers";
import { CHIIKIIKOReport202104 } from "@stores/v202104/domain/mgr/CHIIKIIKO/report/reducers";
import { DOKOENGOUserInFacility as DOKOENGOUserInFacility202104 } from "@stores/v202104/domain/mgr/DOKOENGO/userInFacility/reducers";
import { DOKOENGOFacility as DOKOENGOFacility202104 } from "@stores/v202104/domain/mgr/DOKOENGO/facility/reducers";
import { DOKOENGOCarePlan as DOKOENGOCarePlan202104 } from "@stores/v202104/domain/mgr/DOKOENGO/carePlan/reducers";
import { DOKOENGOReport as DOKOENGOReport202104 } from "@stores/v202104/domain/mgr/DOKOENGO/report/reducers";
import { JUDOHOMONKAIGOFacility202104 } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/facility/reducers";
import { JUDOHOMONKAIGOUserInFacility202104 } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/userInFacility/reducers";
import { JUDOHOMONKAIGOReport202104 } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/report/reducers";
import { JUDOHOMONKAIGOCarePlan202104 } from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/carePlan/reducers";
import { KODOENGOReport202104 } from "@stores/v202104/domain/mgr/KODOENGO/report/reducers";
import { KODOENGOUserInFacility202104 } from "@stores/v202104/domain/mgr/KODOENGO/userInFacility/reducers";
import { KODOENGOCarePlan202104 } from "@stores/v202104/domain/mgr/KODOENGO/carePlan/reducers";

const appReducer = combineReducers({
  // app
  loading,
  // domain
  account,
  attendance,
  auth,
  city,
  errors,
  facilities,
  invoice,
  operations,
  report,
  user,
  offsiteWork,
  work,
  staff,
  supportPlan,
  supports,
  userSummary,
  workRecords,
  supportRecordUser,
  supportReport,
  units,
  customRecords,
  customRecordsWithCategory,
  holiday,
  serviceDelivery,
  assessment,
  carePlan,
  supportPlanSheets,
  // ui
  appDownload,
  ui: combineReducers({
    alertDialog,
    errorsDialog,
    responseError,
    snackbar,
    needsStopHistory: navigationTransition
  }),
  // pages
  pages: combineReducers({
    recordDaily,
    recordMonthly,
    recordSupportPlan,
    recordSupportPlanA,
    recordUserDetail,
    reportDaily,
    recordOffsiteWork,
    recordSupportReport,
    recordUsersDaily
  }),
  // domain (mgr)
  GroupHome: combineReducers({
    facility: GroupHomeFacility,
    userInFacility: GroupHomeUserInFacility,
    supportRecordUser: GroupHomeSupportRecordUser,
    dailyOperationsAndSupports: GroupHomeDailyOperationsAndSupports,
    operations: GroupHomeOperations,
    usersSummary: GroupHomeUsersSummary,
    report: GroupHomeReport,
    targetUnit: GroupHomeTargetUnit,
    supportPlan: GroupHomeSupportPlan
  }),
  SEIKATSUKAIGO: combineReducers({
    facility: SEIKATSUKAIGOFacility,
    userInFacility: SEIKATSUKAIGOUserInFacility,
    report: SEIKATSUKAIGOReport,
    initial: SEIKATSUKAIGOInitial
  }),
  SHUROTEICHAKU: combineReducers({
    report: SHUROTEICHAKUReport,
    userInFacility: SHUROTEICHAKUUserInFacility,
    facility: SHUROTEICHAKUFacility,
    supportPlan: SHUROTEICHAKUSupportPlan
  }),
  JIRITSUKUNRENSEIKATSU: combineReducers({
    facility: JIRITSUKUNRENSEIKATSUFacility,
    userInFacility: JIRITSUKUNRENSEIKATSUUserInFacility,
    report: JIRITSUKUNRENSEIKATSUReport,
    initial: JIRITSUKUNRENSEIKATSUInitial
  }),
  IAB: combineReducers({
    facility: IABFacility,
    userInFacility: IABUserInFacility,
    report: IABReport,
    initial: IABInitial,
    usersSummary: IABUsersSummary
  }),
  TANKINYUSHO: combineReducers({
    facility: TANKINYUSHOFacility,
    userInFacility: TANKINYUSHOUserInFacility,
    dailyOperationsAndSupports: TANKINYUSHODailyOperationsAndSupports,
    supportRecordUser: TANKINYUSHOSupportRecordUser,
    initial: TANKINYUSHOInitial,
    report: TANKINYUSHOReport,
    operations: TANKINYUSHOOperations,
    usersSummary: TANKINYUSHOUsersSummary
  }),
  SHISETSUNYUSHO: combineReducers({
    facility: SHISETSUNYUSHOFacility,
    userInFacility: SHISETSUNYUSHOUserInFacility,
    dailyOperationsAndSupports: SHISETSUNYUSHODailyOperationsAndSupports,
    paginatedDailySupports: SHISETSUNYUSHOPaginatedDailySupports,
    supportRecordUser: SHISETSUNYUSHOSupportRecordUser,
    initial: SHISETSUNYUSHOInitial,
    report: SHISETSUNYUSHOReport,
    usersSummary: SHISETSUNYUSHOUsersSummary,
    targetUnit: SHISETSUNYUSHOTargetUnit,
    operations: SHISETSUNYUSHOOperations,
    usagePerformance: SHISETSUNYUSHOUsagePerformance
  }),
  KYOTAKUKAIGO: combineReducers({
    userInFacility: KYOTAKUKAIGOUserInFacility,
    facility: KYOTAKUKAIGOFacility,
    report: KYOTAKUKAIGOReport,
    staff: KYOTAKUKAIGOStaff,
    carePlan: KYOTAKUKAIGOCarePlan
  }),
  JUDOHOMONKAIGO: combineReducers({
    userInFacility: JUDOHOMONKAIGOUserInFacility,
    facility: JUDOHOMONKAIGOFacility,
    report: JUDOHOMONKAIGOReport,
    staff: JUDOHOMONKAIGOStaff,
    carePlan: JUDOHOMONKAIGOCarePlan
  }),
  DOKOENGO: combineReducers({
    userInFacility: DOKOENGOUserInFacility,
    facility: DOKOENGOFacility,
    staff: DOKOENGOStaff,
    report: DOKOENGOReport,
    carePlan: DOKOENGOCarePlan
  }),
  KODOENGO: combineReducers({
    userInFacility: KODOENGOUserInFacility,
    facility: KODOENGOFacility,
    report: KODOENGOReport,
    staff: KODOENGOStaff,
    carePlan: KODOENGOCarePlan,
    supportProcedure: KODOENGOSupportProcedure,
    supportProcedureModal: KODOENGOSupportProcedureModal
  }),
  CHIIKIIKO: combineReducers({
    userInFacility: CHIIKIIKOUserInFacility,
    facility: CHIIKIIKOFacility,
    report: CHIIKIIKOReport,
    consultation: CHIIKIIKOConsultation,
    planMonitoringMeeting: CHIIKIIKOPlanMonitoringMeeting,
    assistanceReport: CHIIKIIKOAssistanceReport
  }),
  CHIIKITEICHAKU: combineReducers({
    userInFacility: CHIIKITEICHAKUUserInFacility,
    facility: CHIIKITEICHAKUFacility,
    report: CHIIKITEICHAKUReport,
    supportLedger: CHIIKITEICHAKUSupportLedger
  }),
  KEIKAKUSODAN: combineReducers({
    userInFacility: KEIKAKUSODANUserInFacility,
    initial: KEIKAKUSODANInitial,
    facility: KEIKAKUSODANFacility,
    report: KEIKAKUSODANReport,
    inquiry: KEIKAKUSODANInquiry,
    consultation: KEIKAKUSODANConsultation,
    basicInfo: KEIKAKUSODANBasicInfo,
    planMonitoringMeeting: KEIKAKUSODANPlanMonitoringMeeting,
    monitoringReport: KEIKAKUSODANMonitoringReport,
    monitoringMonth: KEIKAKUSODANMonitoringMonth
  }),
  IDOSHIEN: combineReducers({
    report: IDOSHIENReport,
    userInFacility: IDOSHIENUserInFacility,
    facility: IDOSHIENFacility,
    municipalitiesInFacility: IDOSHIENMunicipalitiesInFacility
  }),
  A: combineReducers({
    supportPlan: ASupportPlan
    //   facility: AFacility,
    //   userInFacility: AUserInFacility
  }),
  Cseg: combineReducers({
    operations: CsegOperations,
    supportRecordUser: CsegSupportRecordUser,
    usersSummary: CsegUsersSummary,
    csegOperationsAndSupports: CsegOperationsAndSupports,
    meetingRecord: CsegMeetingRecord
  }),
  v201910: combineReducers({
    invoice: invoice201910,
    pages: combineReducers({
      reportDaily: ReportDaily201910
    }),
    IAB: combineReducers({
      facility: IABFacility201910,
      userInFacility: IABUserInFacility201910,
      report: IABReport201910
    }),
    JIRITSUKUNRENSEIKATSU: combineReducers({
      facility: JIRITSUKUNRENSEIKATSUFacility201910,
      userInFacility: JIRITSUKUNRENSEIKATSUUserInFacility201910,
      report: JIRITSUKUNRENSEIKATSUReport201910
    }),
    GroupHome: combineReducers({
      facility: GroupHomeFacility201910,
      userInFacility: GroupHomeUserInFacility201910,
      report: GroupHomeReport201910
    }),
    SEIKATSUKAIGO: combineReducers({
      facility: SEIKATSUKAIGOFacility201910,
      userInFacility: SEIKATSUKAIGOUserInFacility201910,
      report: SEIKATSUKAIGOReport201910
    }),
    SHUROTEICHAKU: combineReducers({
      report: SHUROTEICHAKUReport201910,
      facility: SHUROTEICHAKUFacility201910,
      userInFacility: SHUROTEICHAKUUserInFacility201910
    }),
    TANKINYUSHO: combineReducers({
      facility: TANKINYUSHOFacility201910,
      userInFacility: TANKINYUSHOUserInFacility201910,
      report: TANKINYUSHOReport201910
    }),
    SHISETSUNYUSHO: combineReducers({
      facility: SHISETSUNYUSHOFacility201910,
      userInFacility: SHISETSUNYUSHOUserInFacility201910,
      report: SHISETSUNYUSHOReport201910
    })
  }),
  v202104: combineReducers({
    invoice: invoice202104,
    IAB: combineReducers({
      facility: IABFacility202104,
      userInFacility: IABUserInFacility202104,
      report: IABReport202104
    }),
    JIRITSUKUNRENSEIKATSU: combineReducers({
      facility: JIRITSUKUNRENSEIKATSUFacility202404,
      userInFacility: JIRITSUKUNRENSEIKATSUUserInFacility202404,
      report: JIRITSUKUNRENSEIKATSUReport202104
    }),
    SHUROTEICHAKU: combineReducers({
      report: SHUROTEICHAKUReport202104,
      userInFacility: SHUROTEICHAKUUserInFacility202104,
      facility: SHUROTEICHAKUFacility202104
    }),
    GroupHome: combineReducers({
      userInFacility: GroupHomeUserInFacility202104,
      facility: GroupHomeFacility202104,
      report: GroupHomeReport202104
    }),
    DOKOENGO: combineReducers({
      userInFacility: DOKOENGOUserInFacility202104,
      facility: DOKOENGOFacility202104,
      carePlan: DOKOENGOCarePlan202104,
      report: DOKOENGOReport202104
    }),
    KEIKAKUSODAN: combineReducers({
      userInFacility: KEIKAKUSODANUserInFacility202104,
      facility: KEIKAKUSODANFacility202104,
      report: KEIKAKUSODANReport202104
    }),
    KODOENGO: combineReducers({
      facility: KODOENGOFacility202104,
      report: KODOENGOReport202104,
      userInFacility: KODOENGOUserInFacility202104,
      carePlan: KODOENGOCarePlan202104
    }),
    SEIKATSUKAIGO: combineReducers({
      facility: SEIKATSUKAIGOFacility202104,
      userInFacility: SEIKATSUKAIGOUserInFacility202104,
      report: SEIKATSUKAIGOReport202104
    }),
    TANKINYUSHO: combineReducers({
      facility: TANKINYUSHOFacility202104,
      report: TANKINYUSHOReport202104,
      userInFacility: TANKINYUSHOUserInFacility202104
    }),
    SHISETSUNYUSHO: combineReducers({
      facility: SHISETSUNYUSHOFacility202104,
      userInFacility: SHISETSUNYUSHOUserInFacility202104,
      report: SHISETSUNYUSHOReport202104
    }),
    CHIIKITEICHAKU: combineReducers({
      userInFacility: CHIIKITEICHAKUUserInFacility202104,
      facility: CHIIKITEICHAKUFacility202104,
      report: CHIIKITEICHAKUReport202104
    }),
    KYOTAKUKAIGO: combineReducers({
      facility: KYOTAKUKAIGOFacility202104,
      userInFacility: KYOTAKUKAIGOUserInFacility202104,
      carePlan: KYOTAKUKAIGOCarePlan202104,
      report: KYOTAKUKAIGOReport202104
    }),
    CHIIKIIKO: combineReducers({
      facility: CHIIKIIKOFacility202104,
      userInFacility: CHIIKIIKOUserInFacility202104,
      report: CHIIKIIKOReport202104
    }),
    JUDOHOMONKAIGO: combineReducers({
      facility: JUDOHOMONKAIGOFacility202104,
      userInFacility: JUDOHOMONKAIGOUserInFacility202104,
      report: JUDOHOMONKAIGOReport202104,
      carePlan: JUDOHOMONKAIGOCarePlan202104
    })
  })
});

type AppState = ReturnType<typeof appReducer>;
type AnyAction = { type: string; payload?: object };

// action引数の型はstateと違い、型定義がまとまっている箇所がないためanyとしている
const rootReducer = (
  state: AppState | undefined,
  action: AnyAction
): AppState => {
  if (isType(action, logout)) {
    userCacheClear();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
