import { Attendee } from "@api/requests/meetingRecord/getMeetingRecord";
import { MeetingRecordFields } from "@interfaces/mgr/Cseg/record/meetingRecord/initial";
import { FieldItem } from "@interfaces/ui/form";
import { MeetingRecordState } from "@stores/domain/mgr/Cseg/meetingRecord/types";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { dateToLocalisedString, dateToSelectDateValue } from "@utils/date";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";

export type MeetingRecordValues = MeetingRecordFields;

const meetingRecordInitialValue = (
  typeConsultation?: number
): MeetingRecordValues => {
  const currentDate = dateToSelectDateValue(
    dateToLocalisedString(new Date(), "YYYY-MM-DD")
  );
  return {
    meeting_records_id: 0,
    creation_date: currentDate,
    creation_staff: "",
    meeting_date: currentDate,
    meeting_start_time: "",
    meeting_end_time: "",
    meeting_place: "",
    section: "1",
    attendees: [],
    consideration_item: "",
    consideration_contents: "",
    consideration_results: "",
    others: "",
    type_consultation: typeConsultation,
    created_at: "",
    updated_at: ""
  };
};

export const attendeeInitialValue = (): Attendee => ({
  affiliation: "",
  name: ""
});

export const initialValues = (
  state: MeetingRecordState,
  staffOptions: FieldItem[],
  option?: {
    isNew?: boolean;
    typeConsultation?: number;
  }
): MeetingRecordValues => {
  if (!state || (option && option.isNew)) {
    return meetingRecordInitialValue(option && option.typeConsultation);
  }
  const creationStaffId = getStaffWithSnap(state.creation_staff, staffOptions);

  return {
    meeting_records_id: state.meeting_records_id,
    creation_date: dateToSelectDateValue(
      undefinedToReturnValue(state.creation_date)
    ),
    creation_staff: creationStaffId || "",
    meeting_date: dateToSelectDateValue(
      undefinedToReturnValue(state.meeting_date)
    ),
    meeting_start_time: state.meeting_start_time
      ? dateToLocalisedString(state.meeting_start_time, "HH:mm")
      : "",
    meeting_end_time: state.meeting_end_time
      ? dateToLocalisedString(state.meeting_end_time, "HH:mm")
      : "",
    meeting_place: state.meeting_place ? state.meeting_place : "",
    section: state.section.toString(),
    attendees:
      state.attendees && state.attendees.length > 0 ? state.attendees : [],
    consideration_item: state.consideration_item
      ? state.consideration_item
      : "",
    consideration_contents: state.consideration_contents
      ? state.consideration_contents
      : "",
    consideration_results: state.consideration_results
      ? state.consideration_results
      : "",
    others: state.others ? state.others : "",
    type_consultation:
      (option && option.typeConsultation) || state.type_consultation,
    created_at: state.created_at,
    updated_at: state.updated_at
  };
};
