import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import reportApi from "@api/requests/usagePerformance";

export const fetchUsagePerformanceSummary = (dispatch: Dispatch) => async (
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchUsagePerformanceSummaryStarted());
  await reportApi
    .getUsagePerformanceSummary(yyyymmdd)
    .then((res) => {
      dispatch(actions.fetchUsagePerformanceSummarySuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(
        actions.fetchUsagePerformanceSummaryFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchUsagePerformanceSummary: (yyyymmdd: string) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchUsagePerformanceSummary: fetchUsagePerformanceSummary(dispatch)
});
