import * as H from "history";
import { FacilityType, InvoiceVersionUrl } from "@constants/variables";
import { getVersionUrl } from "@utils/invoiceVersion/getVersionUrl";
import { findVersionFromDate } from "@utils/invoiceVersion/findVersionFromDate";

/** 渡された日付のバージョンが現在のページのバージョンと異なる場合、渡された日付のバージョンに対応するページへ遷移させる
 * @param date チェック対象の日付
 * @param facilityType 施設区分
 * @param currentPageVersion 現在のページのバージョン
 * @param invoicePaths 報酬改定のパスリスト
 * @param history ヒストリー
 */
export const checkReportVersion = (
  date: Date,
  facilityType: FacilityType,
  currentPageVersion: number,
  invoiceVersionUrls: InvoiceVersionUrl[],
  history: H.History
): boolean => {
  const targetVersion = findVersionFromDate(date, facilityType);
  // 現在バージョンと変更後のバージョンが異なる場合、変更後のURLに遷移
  if (targetVersion !== currentPageVersion) {
    const targetUrl = getVersionUrl(targetVersion, invoiceVersionUrls);

    if (targetUrl) {
      history.replace({
        pathname: targetUrl,
        state: { targetDate: date }
      });
      return true;
    }
  }

  return false;
};
