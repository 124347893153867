import { PostAssistanceReport } from "@api/requests/assistanceReport/postAssistanceReport";

import * as types from "./types";
import { AssistanceReportValues } from "@initialize/mgr/CHIIKIIKO/record/assistanceReport/initialValues";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import { CHIIKIIKOGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";
import { isUndefined } from "lodash-es";
import { GetAssistanceReportResponse } from "@api/requests/assistanceReport/getAssistanceReport";

const removeNullParam = <T extends {}>(data: T, requiredKey?: string[]): T => {
  const targetParam = {} as T;

  // 必須キーを除くundefinedをのparamを除去
  Object.keys(data).forEach((param) => {
    if (
      (requiredKey && requiredKey.includes(param)) ||
      data[param] !== undefined
    ) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

// Get用のnormalizer
export const normalizeValueFromAPI = (
  res: GetAssistanceReportResponse["data"]
): types.AssistanceReportState["assistanceReport"] => {
  return {
    id: res.id,
    created_at: res.created_at || "",
    updated_at: res.updated_at || "",
    guardian_name: res.guardian_name || "",
    guardian_relation: res.guardian_relation || "",
    date_of_monitoring: res.date_of_monitoring,
    creation_date: res.creation_date,
    author: res.author,
    classify_disability_support: res.classify_disability_support || 0,
    classify_handicapped_flg: res.classify_handicapped_flg || 0,
    disabled_welfare_service_recipient_number:
      res.disabled_welfare_service_recipient_number || "",
    regular_place_recipient_number: res.regular_place_recipient_number || "",
    consultation_support_recipient_number:
      res.consultation_support_recipient_number || "",
    attainment_goal: res.attainment_goal || "",
    whole_situation: res.whole_situation || "",
    details: res.details || [],
    staff_comment: res.staff_comment || ""
  };
};

// 詳細読み込み用のnormalizer
export const normalizeCopyValueFromAPI = (
  res: GetConsultationResponse["data"]
): types.ConsultationRecord => {
  return {
    id: res.id,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    targetFlg: res.target_flg,
    creationDate: res.creation_date,
    author: res.author,
    attainmentGoal: res.attainment_goal || "",
    longTermGoal: res.long_term_goal || "",
    shortTermGoal: res.short_term_goal || "",
    startYm: res.start_ym || "",
    endYm: res.end_ym || "",
    supportPlanConsultationDetails: res.support_plan_consultation_details || [],
    staffComment: res.staff_comment || ""
  };
};

// Post用の計画の詳細normalizer
export const normalizeDetails = (
  detailValues: AssistanceReportValues["details"],
  initialDetailValues?: AssistanceReportValues["details"]
): PostAssistanceReport["details"] => {
  return detailValues
    .filter((value) => !(!value.id && value.is_delete))
    .map((value) => {
      const initialvalue = initialDetailValues
        ? initialDetailValues.find((initial) => initial.id === value.id)
        : undefined;

      // 存在する詳細データを編集する場合
      if (initialvalue) {
        const res = omitByNoChanges(value, initialvalue);
        const param = {
          id: value.id,
          support_goal: isUndefined(res.support_goal)
            ? undefined
            : value.support_goal,
          criteria_for_cooperation: isUndefined(res.criteria_for_cooperation)
            ? undefined
            : value.criteria_for_cooperation,
          service_provision_status: isUndefined(res.service_provision_status)
            ? undefined
            : value.service_provision_status,
          personal_satisfaction: isUndefined(res.personal_satisfaction)
            ? undefined
            : value.personal_satisfaction,
          achievement_of_expectations: isUndefined(
            res.achievement_of_expectations
          )
            ? undefined
            : value.achievement_of_expectations,
          future_issues: isUndefined(res.future_issues)
            ? undefined
            : value.future_issues,
          other_considerations: isUndefined(res.other_considerations)
            ? undefined
            : value.other_considerations,
          is_delete: res.is_delete ? value.is_delete : 0
        };
        return removeNullParam(param, ["id"]);
      }

      // 新規追加
      const param = {
        id: null,
        support_goal: value.support_goal,
        criteria_for_cooperation: value.criteria_for_cooperation,
        service_provision_status: value.service_provision_status,
        personal_satisfaction: value.personal_satisfaction,
        achievement_of_expectations: value.achievement_of_expectations,
        future_issues: value.future_issues,
        other_considerations: value.other_considerations,
        is_delete: value.is_delete
      };
      return removeNullParam(param);
    });
};

// Post用のnormalizer
export const normalizeFormValuesToPostAssistanceReport = (
  values: AssistanceReportValues,
  initialValues?: AssistanceReportValues
): PostAssistanceReport => {
  let normalizedValue: PostAssistanceReport;

  // 新規作成
  if (!initialValues) {
    normalizedValue = {
      id: null,
      guardian_name: values.guardianName,
      guardian_relation: values.guardianRelation,
      date_of_monitoring:
        selectDateValueToDatePaddingZero(values.dateOfMonitoring) || "",
      creation_date: selectDateValueToDatePaddingZero(values.creationDate),
      author: Number(values.author) || null,

      classify_disability_support: Number(values.classifyDisabilitySupport),

      disabled_welfare_service_recipient_number:
        values.disabledWelfareServiceRecipientNumber,
      regular_place_recipient_number: values.regularPlaceRecipientNumber,
      consultation_support_recipient_number:
        values.consultationSupportRecipientNumber,

      attainment_goal: values.attainmentGoal,
      whole_situation: values.wholeSituation,

      details: normalizeDetails(values.details),
      staff_comment: values.staffComment
    };
  } else {
    // 編集
    const res = omitByNoChanges(values, initialValues);
    normalizedValue = {
      id: values.id,
      guardian_name: isUndefined(res.guardianName)
        ? undefined
        : values.guardianName,
      guardian_relation: isUndefined(res.guardianRelation)
        ? undefined
        : values.guardianRelation,
      date_of_monitoring:
        selectDateValueToDatePaddingZero(values.dateOfMonitoring) || "",
      creation_date: selectDateValueToDatePaddingZero(values.creationDate),
      author: !res.author ? null : Number(values.author),
      classify_disability_support: isUndefined(res.classifyDisabilitySupport)
        ? undefined
        : Number(values.classifyDisabilitySupport),
      disabled_welfare_service_recipient_number: isUndefined(
        res.disabledWelfareServiceRecipientNumber
      )
        ? undefined
        : values.disabledWelfareServiceRecipientNumber,
      regular_place_recipient_number: isUndefined(
        res.regularPlaceRecipientNumber
      )
        ? undefined
        : values.regularPlaceRecipientNumber,
      consultation_support_recipient_number: isUndefined(
        res.consultationSupportRecipientNumber
      )
        ? undefined
        : values.consultationSupportRecipientNumber,
      attainment_goal: isUndefined(res.attainmentGoal)
        ? undefined
        : values.attainmentGoal,
      whole_situation: isUndefined(res.wholeSituation)
        ? undefined
        : values.wholeSituation,
      details: normalizeDetails(values.details, initialValues.details),
      staff_comment: isUndefined(res.staffComment)
        ? undefined
        : values.staffComment
    };
  }
  return removeNullParam(normalizedValue, ["is", "date_of_monitoring"]);
};
