import * as types from "./types";
import * as actions from "./actions";

const initialState: types.SupportRecordUserState = {
  date_end_in_service: "",
  support_records: [],
  created_at: null,
  updated_at: null
};

export const CsegSupportRecordUser = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportRecordUserState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_RECORD_USER_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_RECORD_USER_SUCCESS:
      return { ...action.payload };
    case types.FETCH_SUPPORT_RECORD_USER_FAILED:
      return { ...state };
    default:
      return state;
  }
};
