import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { SnackbarParams } from "@stores/ui/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { StaffState } from "@stores/domain/staff/types";
import {
  loadDone,
  ActionTypes as LoadingActionTypes
} from "@stores/loading/actions";
import {
  clearRecordDetailState,
  ActionTypes
} from "@stores/domain/serviceDelivery/actions";
// ui
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryBasicFieldMonthly } from "@componentsMobile/organisms/record/serviceDelivery/serviceDeliveryBasicFieldMonthly";
import { ServiceDeliveryDetailField } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryDetailField";
import { CreateAndUpdateDate } from "@componentsMobile/atoms/CreateAndUpdateDate";
// formik
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  ServiceDeliveryDetailValues
} from "@initialize/record/serviceDelivery/initialValues";
import { validation } from "@initialize/record/serviceDelivery/validation";
// utils
import { toEffectiveObject } from "@utils/object";
import * as URL from "@constants/url";
import * as H from "history";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { dateTodayInFormat } from "@utils/date";
import { MobileKnowbeButton } from "@componentsMobile/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { IconButton } from "@componentsMobile/atoms/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import deepEqual from "fast-deep-equal";
import { HEADER_HEIGHT } from "@componentsMobile/templates/AdminTemplate";
import { SUB_HEADER_HEIGHT } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import {
  MAX_FOOTER_HEIGHT,
  MIN_FOOTER_HEIGHT
} from "@componentsMobile/organisms/mgr/Footer";
import { FacilityType } from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${SUB_HEADER_HEIGHT} - ${MIN_FOOTER_HEIGHT})`,
      "@media (max-width: 442px)": {
        minHeight: `calc(100vh - ${HEADER_HEIGHT} - ${SUB_HEADER_HEIGHT} - ${MAX_FOOTER_HEIGHT})`
      }
    },
    wrapper: {
      height: spacing.unit * 8,
      top: 0
    },
    cancelButton: {
      marginRight: "8px"
    },
    header: {
      margin: 16,
      display: "flex",
      justifyContent: "space-between"
    },
    name: {
      fontSize: 16,
      fontWeight: "bold"
    },
    date: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 4
    },
    unplannedAndDate: {
      marginTop: 16
    },
    footer: {
      zIndex: 10,
      boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.2)",
      padding: 16,
      backgroundColor: "#fff",
      width: "100%",
      height: 72,
      position: "sticky",
      bottom: 0,
      display: "flex"
    },
    leftButton: {
      padding: 0,
      width: "100%"
    },
    rightButton: {
      padding: 0,
      width: "100%",
      marginLeft: 16
    },
    unPlannedIcon: {
      width: 44,
      height: 20,
      color: "#fff",
      fontSize: 12,
      backgroundColor: "#757575",
      padding: "1px 4px",
      borderRadius: 2,
      marginTop: 16
    },
    createdAt: {
      marginTop: 16
    }
  });

type OwnProps = {
  isNew: boolean;
  isNoView: boolean;
  targetDate: string;
  history: RouteComponentProps["history"];
  userInFacility: ServiceDeliveryState["user"];
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  onClickDeleteIconButton: () => void;
  facilityType: FacilityType;
  params?: {
    supportProcedureFormsId: string | undefined;
  };
};

type DispatchProps = {
  postRecordDetail: (
    params: ServiceDeliveryDetailValues,
    initialValues: ServiceDeliveryDetailValues,
    history: H.History,
    path: string | null,
    facilityType: FacilityType,
    supportProcedureFormsId?: string
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => Promise<void>;
  loadDone: () => void;
  clearRecordDetailState: () => void;
};

type StateProps = {
  customRecords: CustomRecordsWithCategoryState;
  needsStopHistory: boolean;
  staff: StaffState;
};

type MergeProps = OwnProps &
  DispatchProps &
  StateProps & {
    staffOptions: FieldItem[];
    userName: string;
  };
type Props = MergeProps & WithStyles<typeof styles>;

const ServiceDeliveryFormMonthlyCore = (props: Props): JSX.Element => {
  const { facilityType } = props;
  const user = props.userInFacility.user_in_facility;
  const [initialValuesState, setInitialValuesState] = React.useState<
    ServiceDeliveryDetailValues
  >(
    !props.isNew && props.detailRecords
      ? initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          state: props.detailRecords,
          facilityType
        })
      : initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          userId: user.id,
          facilityType
        })
  );
  const [numberOfPractitioner, setNumberOfPractitioner] = React.useState<
    number
  >(1);
  const [isEdit, setIsEdit] = React.useState(props.isNew || props.isNoView);
  const [isInfoOpen, setIsInfoOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    return (): void => {
      // state初期化
      props.clearRecordDetailState();
    };
  }, []);

  const onClickEdit = (): void => {
    setIsEdit(true);
  };

  React.useEffect(() => {
    if (!props.isNew && props.detailRecords) {
      setInitialValuesState(
        initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          state: props.detailRecords,
          facilityType
        })
      );
      setNumberOfPractitioner(props.detailRecords.numberOfPractitioner);
    } else {
      setInitialValuesState(
        initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          userId: user.id,
          facilityType
        })
      );
    }
  }, [props.customRecords, props.detailRecords]);

  React.useEffect(() => {
    if (
      initialValuesState.status !== KYOTAKUKAIGO_STATUS_LIST.NONE.value &&
      initialValuesState.usersInFacilityId
    ) {
      setIsInfoOpen(true);
    }
  }, [initialValuesState, isEdit]);

  const onSubmit = async (
    values: ServiceDeliveryDetailValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    let path = null;
    // 閲覧モードがないケースは一覧に戻す
    const isReturnList = props.isNoView || props.isNew;
    if (isReturnList) {
      path = `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/${user.id}/${props.targetDate}`;
    }
    await props
      .postRecordDetail(
        values,
        initialValuesState,
        props.history,
        path,
        facilityType,
        props.params ? props.params.supportProcedureFormsId : undefined
      )
      .then(() => {
        if (!isReturnList) {
          setInitialValuesState(values);
          setIsEdit(false);
        }
      })
      .catch(() => {
        props.showSnackbar({
          open: true,
          message: "入力内容に誤りがあります",
          variant: "warning"
        });
        props.loadDone();
      });
    actions.setSubmitting(false);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onClickCancelButton = async (): Promise<void> => {
    await props
      .stopHistory(false)
      .then(() =>
        props.history.push(
          `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/${user.id}/${props.targetDate}`
        )
      );
  };

  const confirmDiscardFormChanges = (
    nextValues: ServiceDeliveryDetailValues
  ): void => {
    const hasChange = !deepEqual(nextValues, initialValuesState);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: ServiceDeliveryDetailValues): void | object => {
    const validationResult = validation(values, facilityType);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  return (
    <Formik
      initialValues={initialValuesState}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const isGroup =
          facilityType === FacilityType.IDOSHIEN &&
          formikProps.values.status === IDOSHIEN_STATUS_LIST.GROUP.value;

        const onClickCancel = (): void => {
          formikProps.resetForm();
          setNumberOfPractitioner(
            +formikProps.initialValues.numberOfPractitioner
          );
          setIsEdit(false);
          props.stopHistory(false);
        };
        return (
          <Form className={props.classes.form}>
            <div>
              <div className={props.classes.header}>
                <div>
                  {!props.isNew && (
                    <div className={props.classes.date}>
                      {dateTodayInFormat(props.targetDate, true)}
                    </div>
                  )}
                  <div className={props.classes.name}>{props.userName}</div>
                  {!props.isNew && (
                    <>
                      {props.detailRecords &&
                        props.detailRecords.inoutResultsId === null && (
                          <div className={props.classes.unPlannedIcon}>
                            計画外
                          </div>
                        )}
                      {formikProps.values.createdAt && (
                        <div className={props.classes.createdAt}>
                          <CreateAndUpdateDate
                            createdAt={formikProps.values.createdAt}
                            updatedAt={formikProps.values.updatedAt}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div>
                  <div>
                    {props.detailRecords &&
                      props.detailRecords.inoutResultsId === null &&
                      props.detailRecords.serviceDeliveryRecordsId &&
                      !isEdit && (
                        <IconButton
                          color="inherit"
                          onClick={props.onClickDeleteIconButton}
                          aria-label="Menu"
                        >
                          <DeleteIcon style={{ color: "#0277bd" }} />
                        </IconButton>
                      )}
                  </div>
                </div>
              </div>
              {/* 基本情報 */}
              <ServiceDeliveryBasicFieldMonthly
                isNew={props.isNew}
                isEdit={isEdit}
                formikProps={formikProps}
                setFormikFieldValue={formikProps.setFieldValue}
                setNumberOfPractitioner={setNumberOfPractitioner}
                isInfoOpen={isInfoOpen}
                setIsInfoOpen={setIsInfoOpen}
                targetDate={props.targetDate}
                detailRecords={props.detailRecords}
                facilityType={facilityType}
                serviceDeliveryUser={props.userInFacility}
              />
              {/* サービス提供記録 */}
              {isInfoOpen && (
                <>
                  <ServiceDeliveryDetailField
                    submitError={submitError}
                    date={dateTodayInFormat(props.targetDate, true)}
                    userName={props.userName}
                    isEdit={isEdit}
                    formikProps={formikProps}
                    setFormikFieldValue={formikProps.setFieldValue}
                    customRecords={props.customRecords}
                    practitionerNum={1}
                    numberOfPractitioner={numberOfPractitioner}
                    detailRecords={props.detailRecords}
                    staffOptions={props.staffOptions}
                    facilityType={facilityType}
                  />
                  {numberOfPractitioner === 2 && !isGroup && (
                    <ServiceDeliveryDetailField
                      submitError={submitError}
                      date={dateTodayInFormat(props.targetDate, true)}
                      userName={props.userName}
                      isEdit={isEdit}
                      formikProps={formikProps}
                      setFormikFieldValue={formikProps.setFieldValue}
                      customRecords={props.customRecords}
                      practitionerNum={2}
                      detailRecords={props.detailRecords}
                      numberOfPractitioner={numberOfPractitioner}
                      staffOptions={props.staffOptions}
                      facilityType={facilityType}
                    />
                  )}
                </>
              )}
            </div>
            <div className={props.classes.footer}>
              {!isEdit ? (
                <>
                  <MobileKnowbeButton
                    className={props.classes.leftButton}
                    kind="outline"
                    onClick={onClickCancelButton}
                  >
                    一覧に戻る
                  </MobileKnowbeButton>
                  <MobileKnowbeButton
                    className={props.classes.rightButton}
                    kind="default"
                    onClick={onClickEdit}
                  >
                    編集する
                  </MobileKnowbeButton>
                </>
              ) : (
                <>
                  <MobileKnowbeButton
                    className={props.classes.leftButton}
                    kind="outline"
                    onClick={
                      props.isNew || props.isNoView
                        ? onClickCancelButton
                        : onClickCancel
                    }
                  >
                    キャンセル
                  </MobileKnowbeButton>
                  <FormikSubmitButton
                    className={props.classes.rightButton}
                    buttonName="保存する"
                    formikProps={formikProps}
                    errorAction={submitError}
                  />
                </>
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, serviceDelivery } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    postRecordDetail: (
      params: ServiceDeliveryDetailValues,
      initialValuesParam: ServiceDeliveryDetailValues,
      history: H.History,
      path: string | null,
      facilityType: FacilityType,
      supportProcedureFormsId
    ): Promise<void> =>
      serviceDeliveryDispatches.postRecordDetail(
        params,
        initialValuesParam,
        history,
        path,
        facilityType,
        true,
        supportProcedureFormsId || ""
      ),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory,
    loadDone: (): LoadingActionTypes => dispatch(loadDone()),
    clearRecordDetailState: (): ActionTypes =>
      dispatch(clearRecordDetailState())
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecordsWithCategory.serviceDelivery,
  needsStopHistory: state.ui.needsStopHistory,
  staff: state.staff
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff } = stateProps;
  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );
  const nameSei = ownProps.userInFacility.user_in_facility
    ? ownProps.userInFacility.user_in_facility.name_sei
    : "";
  const nameMei = ownProps.userInFacility.user_in_facility
    ? ownProps.userInFacility.user_in_facility.name_mei
    : "";

  return {
    userName: `${nameSei} ${nameMei}`,
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryFormMonthly = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryFormMonthlyCore));
