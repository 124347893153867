import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

/**
 * 利用実績一覧(月ごと)APIresponse
 */
export type GetInOutConsultationResultsUsersResponse = {
  data: {
    inout_consultation_results: InoutConsultationResultsItem[];
    summary: {
      intensiveSupportCount: number;
      inhomeNursingCareSupportOfficeRelation1Count?: number;
      inhomeNursingCareSupportOfficeRelation2Count?: number;
      inhomeNursingCareSupportOfficeRelation3Count?: number;
      inhomeNursingCareSupportOfficeRelation4Count?: number;
      inhomeNursingCareSupportOfficeRelation5Count?: number;
      inhomeNursingCareSupportOfficeRelation6Count?: number;
      inhomeNursingCareSupportOfficeRelationLimit?: number;
      lifeSupportPromotionCount?: number;
      dischargeAdditionCount?: number;
      childcareTransitionSupport1Count?: number;
      childcareTransitionSupport2Count?: number;
      childcareTransitionSupport3Count?: number;
    };
  };
};

export type InoutConsultationResultsItem = {
  id: number;
  inout_result_daily_id?: number;
  users_in_facility_id: number;
  name_sei: string;
  name_mei: string;
  target_date?: string;
  type_consultation: number;
  status: number;
  trial_use_type: number;
  trial_stay_type: number;
  first_addition_flg: number;
  first_addition_span?: number;
  housing_support_cooperation_flg: number;
  housing_support_promotion_flg: number;
  discharge_month_addition_flg: number;
  date_begin_hospital: string | null;
  date_end_hospital: string | null;
  short_time_for_psychiatric_hospital_flg: number;
  daily_support_provision_of_info_flg?: number;
  no_constant_contact_system_flg?: number;
  memo_status: string | null;
  memo_trial: string | null;
  memo: string | null;
  isHoliday?: boolean;
  intensive_support1_addition_flg?: number;
  intensive_support2_addition_flg?: number;
  intensive_support3_addition_flg?: number;
  intensive_support4_addition_flg?: number;
  intensive_support5_addition_flg?: number;
  inhome_nursing_care_support_office_relation1_flg?: number;
  inhome_nursing_care_support_office_relation2_flg?: number;
  inhome_nursing_care_support_office_relation3_flg?: number;
  inhome_nursing_care_support_office_relation4_flg?: number;
  inhome_nursing_care_support_office_relation5_flg?: number;
  inhome_nursing_care_support_office_relation6_flg?: number;
  discharge_addition_flg?: number;
  same_inhome_nursing_care_support_flg?: number;
  same_preventive_care_support_flg?: number;
  hospitalization_relation_addition?: number;
  medical_daycare_education_relation1_flg?: number;
  medical_daycare_education_relation2_flg?: number;
  medical_daycare_education_relation3_flg?: number;
  service_personnel_meeting_held_flg?: number;
  service_provision_monitoring_flg?: number;
  life_support_promotion_flg?: number;
  system_promotion_flg?: number;
  childcare_transition_support1_flg: number;
  childcare_transition_support2_flg: number;
  childcare_transition_support3_flg: number;
  mental_disorder_support: number;
  behavioral_disorder_support: number;
  higher_brain_dysfunction_support: number;
  remote_location_first_addition_flg: number;
  remote_location_hospitalization_relation_flg: number;
  remote_location_discharge_addition_flg: number;
  remote_location_inhome_nursing2_flg: number;
  remote_location_inhome_nursing5_flg: number;
  remote_location_childcare_transition_support2_flg: number;
  remote_location_medical_daycare_education1_flg: number;
  remote_location_medical_daycare_education2_flg: number;
  remote_location_intensive_support1_flg: number;
  remote_location_intensive_support4_flg: number;
};

/**
 * 利用実績一覧(月ごと)を取得する
 */
export const getInOutConsultationResultsMonthly = async (
  uifId: number,
  date: string
): Promise<AxiosResponse<GetInOutConsultationResultsUsersResponse>> => {
  const url = `${VERSION_URL_202404}/inout_consultation_results/monthly/${uifId}/${date}`;
  return request.get<GetInOutConsultationResultsUsersResponse>(url);
};
