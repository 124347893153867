import React from "react";
import { RouteComponentProps } from "react-router-dom";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { SupportLedgerPrint } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerPrint";
import * as URL from "@constants/url";

/**
 * 地域定着支援台帳のプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    supportLedgerId: string;
  }>;

const SupportLedgerRecordPrintCore = (props: Props): JSX.Element => {
  const query = props.location.search;

  const { uifId, supportLedgerId } = props.match.params;

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "地域定着支援台帳",
      path: `/record/support_plan/${uifId}/support_ledger`
    }
  ];
  const RenderPrint = (): JSX.Element => {
    return (
      <SupportLedgerPrint
        uifId={uifId}
        supportLedgerId={supportLedgerId}
        query={query}
      />
    );
  };

  return (
    <AdminTemplate pageName="地域定着支援台帳" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const SupportLedgerRecordPrint = connect(mapStateToProps)(
  SupportLedgerRecordPrintCore
);
