import React, { useState } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import {
  FacilityUser,
  UpdatePasswordParams
} from "@stores/domain/account/type";
import Collapse from "@material-ui/core/Collapse";
import { CurrentPassword } from "@components/organisms/account/CurrentPassword";
import { CurrentAccountName } from "@components/organisms/account/CurrentAccountName";
import { PasswordForm } from "@components/organisms/account/PasswordForm";

const styles = (): StyleRules =>
  createStyles({
    form: {
      marginTop: 32
    }
  });

type StateProps = {
  admin: FacilityUser;
  errorType: string;
  needsStopHistory: boolean;
};

type DispatchProps = {
  updatePassword: (params: UpdatePasswordParams) => void;
  stopHistory: (flag: boolean) => void;
};

type Props = StateProps & DispatchProps & WithStyles<typeof styles>;

const AccountAdminPasswordCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const [isEdit, toggleIsEdit] = useState(false);

  const closeForm = (): void => {
    toggleIsEdit(false);
  };

  const openForm = (): void => {
    toggleIsEdit(true);
  };

  return (
    <>
      <CurrentAccountName label="アカウント名" account={props.admin.email} />
      <div className={classes.form}>
        <Collapse in={isEdit} collapsedHeight="44px">
          {/* height => Formの高さ */}
          <div style={{ height: 598 }}>
            {!isEdit && <CurrentPassword onClick={openForm} />}
            {/* 閉じても値を保持する */}
            <div style={{ display: isEdit ? "block" : "none" }}>
              <PasswordForm
                email={props.admin.email}
                errorType={props.errorType}
                needsStopHistory={props.needsStopHistory}
                currentPasswordLabel="現在のパスワードを入力してください"
                currentPasswordPlaceholder=""
                newPasswordLabel="新しいパスワード"
                newPasswordConfirmLabel="新しいパスワード（確認）"
                onClose={closeForm}
                updatePassword={props.updatePassword}
                stopHistory={props.stopHistory}
              />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  admin: state.account.admin,
  errorType: state.account.errorType,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { accountDispatch, uiDispatch } = dispatches;
  const accountDispatches = accountDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    updatePassword: (params: UpdatePasswordParams): Promise<void> =>
      accountDispatches.updatePassword(params, "admin"),
    stopHistory: uiDispatches.stopHistory
  };
};

export const AccountAdminPassword = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccountAdminPasswordCore)
);
