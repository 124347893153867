import { FieldItem } from "@interfaces/ui/form";
import { PRACTITIONER_SNAPSHOT_OPTION_VALUE } from "@constants/mgr/KYOTAKUKAIGO/variables";

type SnapshotValueType = {
  id: number;
  snapshot_name: string;
  name?: string;
  facility_id?: number;
  role?: string;
  license?: number;
  license_name?: string | null;
  snapshot_license_name?: string | null;
  snapshot_role?: string | null;
};

// スナップショットを考慮してシンプルセレクトのoptionsを作成
export const createSnapshotOptions = (
  staffOptions: FieldItem[],
  snapshotValue: SnapshotValueType | null
): FieldItem[] => {
  let options = staffOptions;
  if (snapshotValue) {
    const isDelete = staffOptions.every(
      (option) => option.value !== String(snapshotValue.id)
    );
    if (
      (snapshotValue &&
        snapshotValue.snapshot_name &&
        staffOptions.some(
          (option) =>
            option.value === String(snapshotValue.id) &&
            option.label !== snapshotValue.snapshot_name
        )) ||
      isDelete
    ) {
      options = [
        {
          label: snapshotValue.snapshot_name,
          value: isDelete
            ? String(snapshotValue.id)
            : PRACTITIONER_SNAPSHOT_OPTION_VALUE
        },
        ...staffOptions
      ];
    }
  }

  return options;
};
