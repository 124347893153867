import {
  CarePlanState,
  CarePlanDetailState
} from "@stores/domain/mgr/JUDOHOMONKAIGO/carePlan/types";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/mgr/JUDOHOMONKAIGO/Users/initial";
import {
  JUDOHOMONKAIGO_STATUS_LIST,
  JUDOHOMONKAIGO_MEMBER_LIST,
  JUDOHOMONKAIGO_BASE_LIST,
  JUDOHOMONKAIGO_ACTION_CLASS_LIST,
  JUDOHOMONKAIGO_LICENSE_LIST
} from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";

export type InitialDataValues = InitialValues;

export const carePlanDetailsInitialValue = {
  id: null,
  actionClass: JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value,
  inTime: "",
  inTimeClass: "0",
  outTime: "",
  outTimeClass: "0",
  time: 0
};

const carePlanDetailsFormatValue = (
  detail: CarePlanDetailState[]
): CarePlanDetailsFields[] => {
  return detail.map((record) => {
    return {
      id: record.id,
      actionClass: record.actionClass
        ? record.actionClass.toString()
        : JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value,
      inTimeClass: record.inTimeClass ? record.inTimeClass : "0",
      inTime: record.inTime ? record.inTime : "",
      outTimeClass: record.outTimeClass ? record.outTimeClass : "0",
      outTime: record.outTime ? record.outTime : "",
      time: calculateForRequiredTime(
        record.inTime,
        record.inTimeClass,
        record.outTime,
        record.outTimeClass
      )
    };
  });
};

// デフォルト値設定
export const initialValues = (
  state: CarePlanState | null = null
): InitialDataValues => {
  const details1 =
    state && state.carePlanDetailState1.length
      ? carePlanDetailsFormatValue(state.carePlanDetailState1)
      : [];

  const details2 =
    state && state.carePlanDetailState2.length
      ? carePlanDetailsFormatValue(state.carePlanDetailState2)
      : [];

  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      id: state ? state.id : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      dayOfWeek: state && state.dayOfWeek ? state.dayOfWeek : 0,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      status:
        state && state.status
          ? state.status
          : JUDOHOMONKAIGO_STATUS_LIST.NONE.value,
      numberOfParticipants:
        state && state.numberOfParticipants
          ? state.numberOfParticipants.toString()
          : JUDOHOMONKAIGO_MEMBER_LIST.ONE.value,
      practitioner1:
        state && state.practitioner1
          ? state.practitioner1.toString()
          : JUDOHOMONKAIGO_BASE_LIST.NONE.value,
      practitioner1License:
        state && state.practitioner1License
          ? state.practitioner1License.toString()
          : JUDOHOMONKAIGO_LICENSE_LIST.NONE.value,
      practitioner1InTimeClass: "当日",
      practitioner1InTime:
        state && state.practitioner1InTime ? state.practitioner1InTime : "",
      practitioner1OutTimeClass:
        state && state.practitioner1OutTimeClass
          ? state.practitioner1OutTimeClass
          : "0",
      practitioner1OutTime:
        state && state.practitioner1OutTime ? state.practitioner1OutTime : "",
      practitioner1CalculatedHours:
        state && state.practitioner1CalculatedHours !== null
          ? state.practitioner1CalculatedHours.toString()
          : "0",
      practitioner1CalculatedMovingHours:
        state && state.practitioner1CalculatedMovingHours !== null
          ? state.practitioner1CalculatedMovingHours.toString()
          : "0",
      practitioner1Memo:
        state && state.practitioner1Memo ? state.practitioner1Memo : "",
      carePlanDetails1: details1,
      practitioner2:
        state && state.practitioner2
          ? state.practitioner2.toString()
          : JUDOHOMONKAIGO_BASE_LIST.NONE.value,
      practitioner2License:
        state && state.practitioner2License
          ? state.practitioner2License.toString()
          : JUDOHOMONKAIGO_LICENSE_LIST.NONE.value,
      practitioner2InTimeClass:
        state && state.practitioner2InTimeClass
          ? state.practitioner2InTimeClass
          : "0",
      practitioner2InTime:
        state && state.practitioner2InTime ? state.practitioner2InTime : "",
      practitioner2OutTimeClass:
        state && state.practitioner2OutTimeClass
          ? state.practitioner2OutTimeClass
          : "0",
      practitioner2OutTime:
        state && state.practitioner2OutTime ? state.practitioner2OutTime : "",
      practitioner2Memo:
        state && state.practitioner2Memo ? state.practitioner2Memo : "",
      timeDuplicationJudgmentFlg:
        state && state.timeDuplicationJudgmentFlg
          ? state.timeDuplicationJudgmentFlg
          : false,
      wholeCalculatedHours:
        state && state.wholeCalculatedHours !== null
          ? state.wholeCalculatedHours.toString()
          : "",
      duplicateCalculatedHours:
        state && state.duplicateCalculatedHours !== null
          ? state.duplicateCalculatedHours.toString()
          : "",
      carePlanDetails2: details2,
      licenseSameFlg:
        state && state.licenseSameFlg ? state.licenseSameFlg : false,
      movingTimeDuplicationJudgmentFlg:
        state && state.movingTimeDuplicationJudgmentFlg
          ? state.movingTimeDuplicationJudgmentFlg
          : false,
      wholeCalculatedMovingHours:
        state && state.wholeCalculatedMovingHours !== null
          ? state.wholeCalculatedMovingHours.toString()
          : "",
      duplicateCalculatedMovingHours:
        state &&
        state.duplicateCalculatedMovingHours !== undefined &&
        state.duplicateCalculatedMovingHours !== null
          ? state.duplicateCalculatedMovingHours.toString()
          : "",
      accompanySupportInTimeClass:
        state && state.accompanySupportInTimeClass
          ? state.accompanySupportInTimeClass
          : "0",
      accompanySupportInTime:
        state && state.accompanySupportInTime
          ? state.accompanySupportInTime
          : "",
      accompanySupportOutTimeClass:
        state && state.accompanySupportOutTimeClass
          ? state.accompanySupportOutTimeClass
          : "0",
      accompanySupportOutTime:
        state && state.accompanySupportOutTime
          ? state.accompanySupportOutTime
          : "",
      wholeCalculatedAccompanySupportHours:
        state && state.wholeCalculatedAccompanySupportHours !== null
          ? state.wholeCalculatedAccompanySupportHours.toString()
          : "",
      wholeCalculatedAccompanySupportMovingHours:
        state && state.wholeCalculatedAccompanySupportMovingHours !== null
          ? state.wholeCalculatedAccompanySupportMovingHours.toString()
          : "0",
      secondPersonFlg:
        state && state.secondPersonFlg ? state.secondPersonFlg : false,
      secondPersonFlgError: "",
      accompanySupportFlg:
        state && state.accompanySupportFlg ? state.accompanySupportFlg : false,
      sputumImplementationFlg:
        state && state.sputumImplementationFlg
          ? state.sputumImplementationFlg
          : false
    }
  };
};
