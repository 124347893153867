import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";
import AdminRoute from "@app/Auth/AdminRoute";

// 利用実績
import { ReportDaily } from "@components/pages/mgr/IDOSHIEN/report/Daily";
import { ReportMonthly } from "@components/pages/mgr/IDOSHIEN/report/Monthly";

// サービス提供記録
import { ServiceDeliveryDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryDaily";
import { ServiceDeliveryMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryMonthly";
import { ServiceDeliveryNewDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewDaily";
import { ServiceDeliveryEditDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import { ServiceDeliveryNewMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewMonthly";
import { ServiceDeliveryEditMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditMonthly";
import { ServiceDeliveryPrint } from "@components/pages/record/print/ServiceDeliveryPrint";

// 自治体情報
import MunicipalitiesList from "@components/pages/mgr/IDOSHIEN/Municipalities/MunicipalitiesList";
import { MunicipalityNew } from "@components/pages/mgr/IDOSHIEN/Municipalities/MunicipalityNew";
import { MunicipalityEdit } from "@components/pages/mgr/IDOSHIEN/Municipalities/MunicipalityEdit";

// 利用者一覧
import { UsersList } from "@components/pages/mgr/IDOSHIEN/Users/UsersList";

// 事業所情報
import { Facility } from "@components/pages/mgr/IDOSHIEN/Facility";
import { CreateUser } from "@components/pages/mgr/IDOSHIEN/Users/CreateUser";
import { EditUser } from "@components/pages/mgr/IDOSHIEN/Users/EditUser";

// 職員情報
import Staffs from "@components/pages/staffs/Staffs";

// 記録の項目設定
import { RecordSettingWithCategory } from "@components/pages/record/setting/RecordSettingWithCategory";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 移動支援でログインした時だけ有効になるルーティング
 */
export const IDOSHIENRoutes = ({ user }: Props): JSX.Element => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />

    {/* サービス提供記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/list/:date?"
      component={ServiceDeliveryDaily}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/list/:uifId/:date"
      component={ServiceDeliveryMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/new"
      component={ServiceDeliveryNewDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:uifId"
      component={ServiceDeliveryEditDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/:uifId/service_delivery/new"
      component={ServiceDeliveryNewMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:uifId/monthly"
      component={ServiceDeliveryEditMonthly}
    />

    {/* 印刷・サービス提供記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/print"
      component={ServiceDeliveryPrint}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/print"
      component={ServiceDeliveryPrint}
    />

    {/* 請求 */}

    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />

    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />

    {/* 職員情報 */}
    <AdminRoute path={URL.STAFFS} component={Staffs} />

    {/* 自治体情報 */}
    <AdminRoute exact path={URL.MUNICIPALITY} component={MunicipalitiesList} />
    <AdminRoute
      exact
      path={`${URL.MUNICIPALITY}/new`}
      component={MunicipalityNew}
    />
    <AdminRoute
      exact
      path={`${URL.MUNICIPALITY}/:municipalityId`}
      component={MunicipalityEdit}
    />

    {/* 提供記録の設定 */}
    <AdminRoute
      key="RecordSetting"
      path={URL.RECORD_SETTING}
      exact
      component={RecordSettingWithCategory}
    />

    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);
