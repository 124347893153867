import React, { useState } from "react";
import validator from "@validator";
import MuiTextField from "@components/molecules/MuiTextField";

type OwnProps = {
  passwordName: string;
  errorMessage?: string;
  confirmHelperText?: string;
  onChange: (password: string) => void;
};

type Props = OwnProps;

const PasswordField = (props: Props): JSX.Element => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");

  const setPasswordFnc = (
    passwordValue: string,
    passwordConfirmValue: string
  ): void => {
    const error = validator(passwordValue, "password");
    const confirmError = validator(passwordConfirmValue, {
      type: "passwordMatch",
      value: passwordValue
    });

    setPassword(passwordValue);
    setPasswordConfirm(passwordConfirmValue);
    setPasswordError(error || "");
    setPasswordConfirmError(confirmError || "");

    if (!error && !confirmError) {
      props.onChange(passwordValue);
    } else {
      props.onChange("");
    }
  };

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.currentTarget;
    setPasswordFnc(value, passwordConfirm);
  };
  const handleChangePasswordConfirm = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.currentTarget;
    setPasswordFnc(password, value);
  };

  const viewPasswordErrorMessage = props.errorMessage || passwordError;

  return (
    <>
      <MuiTextField
        label={props.passwordName}
        type="password"
        autoComplete="new-password"
        required
        onChange={handleChangePassword}
        error={Boolean(viewPasswordErrorMessage.length)}
        helperText={
          viewPasswordErrorMessage ||
          "半角英字・数字・記号の組み合わせで8文字以上"
        }
        maxLength={100}
        size="long"
      />
      <MuiTextField
        label={`${props.passwordName}（確認）`}
        type="password"
        autoComplete="new-password"
        required
        onChange={handleChangePasswordConfirm}
        error={Boolean(passwordConfirmError.length)}
        helperText={
          passwordConfirmError ||
          props.confirmHelperText ||
          "新しいパスワードをもう一度入力"
        }
        maxLength={100}
        size="long"
      />
    </>
  );
};

// 影響範囲が大きいためdefault exportままにしておく（全体リファクタ時にnamed exportに変える）
export default PasswordField;
