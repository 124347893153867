import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g id="Outline">
        <g id="ui_x5F_spec_x5F_header" />
        <g>
          <path d="M8,5 L8,8 L4,8 L4,19 C4,19.5522847 4.44771525,20 5,20 L15,20 L15,22 L5,22 C3.34314575,22 2,20.6568542 2,19 L2,7.04623177 L6.62426488,3 L17,3 C18.6568542,3 20,4.34314575 20,6 L20,12 L18,12 L18,6 C18,5.44771525 17.5522847,5 17,5 L8,5 Z M20.5,14 L22.5,14 L20.5,17 L21.5,17 L21.5,18 L19.9,18 L19.9,19 L21.5,19 L21.5,20 L19.9,20 L19.9,22 L18.3,22 L18.3,20 L16.5,20 L16.5,19 L18.3,19 L18.3,18 L16.5,18 L16.5,17 L17.5,17 L15.5,14 L17.5,14 L19,16.5 L20.5,14 Z M7,12 L7,10 L15,10 L15,12 L7,12 Z M7,16.001 L7,14 L13.501,14 L13.501,16.001 L7,16.001 Z" />
        </g>
      </g>
    </SvgIcon>
  );
};
