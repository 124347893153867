import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Header from "@components/organisms/auth/Header";
import Footer from "@components/organisms/mgr/Footer";

const styles = createStyles({
  root: {
    display: "block"
  },
  container: {
    "@media only screen and (min-width: 640px)": {
      width: 640,
      margin: "128px auto 0",
      minHeight: "calc(100vh - 128px - 54px - 80px)"
    },
    "@media only screen and (max-width: 639px)": {
      "&>div": {
        borderRadius: 0,
        boxShadow: "none"
      },
      "& + footer": {
        marginTop: 0
      }
    },
    "@media only screen and (min-width: 576px) and (max-width: 639px)": {
      marginTop: "64px",
      minHeight: "calc(100vh - 64px - 54px)"
    },
    "@media only screen and (max-width: 575px)": {
      marginTop: "56px"
    },
    "@media only screen and (min-width: 443px) and (max-width: 575px)": {
      minHeight: "calc(100vh - 56px - 54px)"
    },
    "@media only screen and (max-width: 442px)": {
      minHeight: "calc(100vh - 56px - 80px)"
    }
  }
});

type OwnProps = {
  children: React.ReactNode;
};
type Props = OwnProps & WithStyles<typeof styles>;

const AuthTemplate = ({ classes, children }: Props): JSX.Element => (
  <div className={classes.root}>
    <Header />
    <div className={classes.container}>{children}</div>
    <Footer />
  </div>
);

export default withStyles(styles)(AuthTemplate);
