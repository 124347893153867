import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  INVOICE_PATHS,
  TANKINYUSHOReportTabInfo,
  TANKINYUSHO_REPORT_TABS_INFO
} from "@constants/mgr/TANKINYUSHO/variables";
import * as URL from "@constants/url";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import UsagePerformanceMonthlyList from "@components/organisms/mgr/TANKINYUSHO/report/UsagePerformanceMonthlyList";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FacilityType, INVOICE_VERSION_CURRENT } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{}, {}, { targetDate: Date }> &
  WithStyles<typeof styles>;

/**
 * 利用実績
 */
const Report = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();
  // 報酬改定のバージョン変更が必要だったら変更後のページを表示
  const currentPageVersion = INVOICE_VERSION_CURRENT;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.TANKINYUSHO,
    currentPageVersion,
    INVOICE_PATHS.reportMonthly,
    props.history
  );

  if (transitionDone) {
    return null;
  }
  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === TANKINYUSHOReportTabInfo.DAILY) {
      props.history.push(URL.REPORT_DAILY);
    }
  };
  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <>
        <KnowbeTabs
          key="tab"
          tabInfo={TANKINYUSHO_REPORT_TABS_INFO}
          onChange={onChangeTag}
          value={TANKINYUSHOReportTabInfo.MONTHLY}
        />
        <UsagePerformanceMonthlyList
          initialDate={currentDate}
          history={props.history}
          currentPageVersion={currentPageVersion}
          // propsをstateに入れた時の不具合予防にkeyを入れる
          key={currentDate.getTime()}
        />
        <ErrorsDialog errorsKey="inout" />
        <NavigationTransitionPrompt />
      </>
    </AdminTemplate>
  );
};

export default withStyles(styles)(Report);
