import * as React from "react";
import classNames from "classnames";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { AssessmentState } from "@stores/domain/assessment/types";

import dispatches from "@stores/dispatches";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { UsersInFacilityState as KEIKAKUSOUDANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UsersInFacilityState as CHIIKITEICHAKUUsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE,
  DEFAULT_SELECT_VALUE,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";
import {
  checkItemIsLifeHistories,
  checkItemIsDisabilityHistories,
  checkItemIsFlowDays,
  checkItemIsChiikiikoOpinions,
  getCategoryName,
  findCategoryType,
  findChooseRadioLabel,
  findDefaultItem
} from "@utils/domain/mgr/Cseg/assessment/variableFromFacility";

// utils
import { dateToLocalisedString } from "@utils/date";
import getAge from "@utils/date/getAge";
import { AssessmentPrintFlowDay } from "./AssessmentPrintFlowDay";
import { CityParams, CityState } from "@stores/domain/city/type";
import { findUserInFacilityKeikakusodan } from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        boxShadow: "none"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 14,
      marginBottom: 20
    },
    headerTitleContainer: {
      display: "flex"
    },
    headerTitle: {
      fontSize: 18
    },
    headerLabel: {
      display: "flex",
      fontSize: 10,
      marginLeft: 8,
      alignItems: "center"
    },
    headerCreate: {
      fontSize: 10
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start",
      overflowWrap: "anywhere"
    },
    userInfoNameContainer: {
      width: 216,
      borderBottom: "1px solid #000"
    },
    userInfoBirthContainer: {
      width: 136,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoGenderContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyContainer: {
      width: 448,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyDisabilitySupportContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoAddressContainer: {
      width: 448,
      borderBottom: "1px solid #000"
    },
    userInfoTelContainer: {
      width: 216,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoItem: {
      display: "block",
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      fontSize: 10,
      color: "#212121",
      paddingBottom: 5
    },
    table: {
      display: "grid",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid #000",
      textAlign: "left",
      width: "100%",
      overflowWrap: "anywhere",
      gridTemplateColumns: "73px 128px 1fr",
      "&.living_situation": {
        gridTemplateColumns: "73px 1fr"
      },
      "&.situation": {
        gridTemplateColumns: "73px 128px repeat(3, 1fr)"
      }
    },
    tableSummary2col: {
      gridColumn: "2 / 4"
    },
    cell: {
      display: "flex",
      alignItems: "center",
      padding: "4px 6px",
      borderRight: "1px solid #000",
      borderBottom: "1px solid #000",
      fontSize: 10,
      letterSpacing: "normal",
      minHeight: 24,
      height: "100%",
      color: "rgba(0, 0, 0, 0.84)",
      "&.label": {
        justifyContent: "center",
        borderBottom: "2px solid #000"
      },
      "&.text": {
        paddingLeft: 8
      },
      "&.multi": {
        alignItems: "flex-start"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.lastRow": {
        borderBottom: "none"
      },
      "&.lastColumn": {
        borderRight: "none"
      },
      "&.small": {
        padding: 0
      },
      "&.borderDashed": {
        borderRight: "1px dotted #000"
      },
      // 生活歴 / 障害歴セル
      "&.historyCell": {
        padding: 0,
        flexDirection: "column"
      }
    },
    // 生活歴 / 障害歴関連のスタイル
    tableHistoryRow: {
      display: "flex",
      width: "100%"
    },
    tableHistoryIndex: {
      display: "flex",
      alignItems: "center",
      padding: "0.5px 2px",
      width: 20,
      minWidth: 20,
      height: "100%",
      borderRight: "1px solid #000",
      justifyContent: "center",
      "&:not(.lastRow)": {
        borderBottom: "1px solid #000"
      }
    },
    tableHistoryData: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "108px 1fr",
      "&> div": {
        padding: "4px 6px",
        "&:not(.lastRow)": {
          borderBottom: "1px solid #000"
        },
        "&:not(.lastColumn)": {
          borderRight: "1px solid #000"
        }
      }
    },
    tableHeaderContainer: {
      maxHeight: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: "14px"
    },
    tableHeader: {
      whiteSpace: "nowrap"
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    categoryName: {
      marginTop: 36,
      width: "100%",
      fontFamily: "HiraginoSans-W6",
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginBottom: 10,
      paddingBottom: 5,
      whiteSpace: "initial",
      "&.evaluationPeriod": {
        display: "flex",
        justifyContent: "space-between"
      }
    },
    evaluationPeriodDate: {
      color: "#212121",
      fontFamily: "HiraginoSans-W3"
    },
    MT16: {
      marginTop: 16
    },
    MT24: {
      marginTop: 24
    },
    MT8: {
      marginTop: 8
    }
  });

/**
 * interface
 */

type StateProps = {
  user: UserState;
  assessmentRecord: AssessmentState["assessment"];
  userInFacility:
    | KEIKAKUSOUDANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"]
    | CHIIKITEICHAKUUsersInFacilityState["user"];
  customRecords: CustomRecordsWithCategoryState;
  cityListView: CityState[] | null;
};

type OwnProps = {
  uifId: string;
  assessmentId: string;
  query?: string;
};

type DispatchProps = {
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  getUserDetailInFacilityData: (
    uifId: string,
    facilityType: FacilityType
  ) => void;
  fetchFacilityCity: (params: CityParams) => Promise<void>;
};

type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: StateProps["userInFacility"];
  assessmentRecord: AssessmentState["assessment"];
  customRecords: CustomRecordsWithCategoryState;
  classes: Record<string, string>;
  facilityType: FacilityType;
  cityListView: CityState[] | null;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type ContentsProps = {
  customRecords: CustomRecordsWithCategoryState;
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"];
  categoryType: number;
  classes: Record<string, string>;
  facilityType: FacilityType;
};

const checkHasBasicInformationCategory = (
  facilityType: FacilityType
): boolean => {
  return (
    facilityType === FacilityType.CHIIKIIKO ||
    facilityType === FacilityType.CHIIKITEICHAKU
  );
};

const findInputFromDefaultItem = (
  categoryType: number,
  defaultItem: number,
  customRecords: CustomRecordsWithCategoryState,
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"]
):
  | AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]
  | null => {
  const customCategory = customRecords.find(
    (category) => category.default_category === categoryType
  );
  if (!customCategory) return null;
  const customItem = customCategory.custom_record_items.find(
    (item) => item.default_item === defaultItem
  );
  if (!customItem) return null;
  const categoryRecord = assessmentRecords.find(
    (category) => category.custom_records_category_id === customCategory.id
  );
  if (!categoryRecord) return null;
  const itemRecord = categoryRecord.input.find((item) => {
    return item.custom_record_item_id === customItem.id;
  });
  if (!itemRecord) return null;
  return itemRecord;
};

const getFacilityClass = (
  userInFacility: StateProps["userInFacility"]
): string => {
  const uifKeikakusodan = findUserInFacilityKeikakusodan(userInFacility);

  const isKeikausodan = (
    uif: StateProps["userInFacility"]
  ): uif is KEIKAKUSOUDANUsersInFacilityState["user"] => {
    return "user_in_facility_keikakusodan" in uif;
  };

  const isChiikiiko = (
    uif: StateProps["userInFacility"]
  ): uif is CHIIKIIKOUsersInFacilityState["user"] => {
    return "user_in_facility_chiikiiko" in uif;
  };

  const isChiikiteichaku = (
    uif: StateProps["userInFacility"]
  ): uif is CHIIKITEICHAKUUsersInFacilityState["user"] => {
    return "user_in_facility_chiikiteichaku" in uif;
  };

  if (
    isKeikausodan(userInFacility) &&
    userInFacility.user_in_facility_keikakusodan
  ) {
    return uifKeikakusodan && uifKeikakusodan.disability_class !== 0
      ? `区分${uifKeikakusodan.disability_class}`
      : "なし";
  }

  if (
    isChiikiiko(userInFacility) &&
    userInFacility.user_in_facility_chiikiiko
  ) {
    return userInFacility.user_in_facility_chiikiiko.disability_class !== 0
      ? `区分${userInFacility.user_in_facility_chiikiiko.disability_class}`
      : "なし";
  }

  if (
    isChiikiteichaku(userInFacility) &&
    userInFacility.user_in_facility_chiikiteichaku
  ) {
    return userInFacility.user_in_facility_chiikiteichaku.disability_class !== 0
      ? `区分${userInFacility.user_in_facility_chiikiteichaku.disability_class}`
      : "なし";
  }

  return "なし";
};

/**
 * 表示するカテゴリ（項目）が「１日の流れ」のみか判定
 */
const checkCategoryIsOnlyFlowDaysData = (
  categories: CustomRecordsWithCategoryState,
  facilityType: FacilityType
): boolean => {
  return categories.every((category) =>
    category.custom_record_items.every((item) =>
      checkItemIsFlowDays(facilityType, item.default_item)
    )
  );
};

const HistoryCell = (props: {
  classes: Record<string, string>;
  histories: NonNullable<
    | AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]["life_histories"]
    | AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]["disability_histories"]
  >;
  isLastItem: boolean;
}): JSX.Element => {
  const { classes, histories, isLastItem } = props;

  return (
    <div
      className={`${classes.cell} ${
        classes.tableSummary2col
      } historyCell lastColumn${isLastItem ? " lastRow" : ""}`}
    >
      {histories.map((history, hIdx) => {
        const isLastRow = histories.length === hIdx + 1;
        return (
          <div
            className={classes.tableHistoryRow}
            key={`history-${history.id}`}
          >
            <div
              className={`${classes.tableHistoryIndex}${
                isLastRow ? " lastRow" : ""
              }`}
            >
              {hIdx + 1}
            </div>
            <div className={classes.tableHistoryData}>
              {/* 年月 */}
              <div>年月</div>
              <div className="lastColumn">{history.yyyymm}</div>
              {/* 事項 */}
              <div className={isLastRow ? "lastRow" : ""}>事項</div>
              <div className={`lastColumn ${isLastRow ? " lastRow" : ""}`}>
                {history.matter}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Contents = (props: ContentsProps): JSX.Element => {
  const {
    customRecords,
    assessmentRecords,
    categoryType,
    classes,
    facilityType
  } = props;

  const facilityCategoryType = {
    summary: findCategoryType(facilityType, "summary"),
    living_situation: findCategoryType(facilityType, "living_situation"),
    situation: findCategoryType(facilityType, "situation"),
    request: findCategoryType(facilityType, "request"),
    opinions_on_chiikiikou: findCategoryType(
      facilityType,
      "opinions_on_chiikiikou"
    ),
    others: findCategoryType(facilityType, "others")
  };

  // 選択肢情報からテーブルヘッダーを作成
  let header: JSX.Element;
  const getHeader = (
    choices: { id: number; string: string[] }[]
  ): JSX.Element => {
    return (
      <>
        <div className={`${classes.cell} label`}>カテゴリー</div>
        <div className={`${classes.cell} label`}>項目</div>
        {choices.map((c, ci) => (
          <div
            key={`td-choice-${c.id}`}
            className={`${classes.cell} small label ${
              // 最後の右ボーダは無し
              ci !== choices.length - 1 ? "borderDashed" : "lastColumn"
            }`}
          >
            <div className={`${classes.tableHeaderContainer}`}>
              {c.string.map((string, idx) =>
                idx === 0 ? (
                  <div key={`d1-${c.id}`} className={classes.tableHeader}>
                    {string}
                  </div>
                ) : (
                  <div key={`d2-${c.id}`} className={classes.tableHeader}>
                    {string}
                  </div>
                )
              )}
            </div>
          </div>
        ))}
      </>
    );
  };

  // カテゴリータイプに対応する選択肢項目
  switch (categoryType) {
    case facilityCategoryType.situation:
      header = getHeader([
        { id: 1, string: ["現状"] },
        { id: 2, string: ["本人・家族の希望や困りごと"] },
        { id: 3, string: ["支援者の気付きや気になりごと"] }
      ]);
      break;
    default:
      header = <></>;
      break;
  }

  // 記録項目に対応する保存内容を取得
  const getInput = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number] => {
    const categoryIdx = assessmentRecords.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx !== -1) {
      const inputIdx = assessmentRecords[categoryIdx].input.findIndex(
        (i) => i.custom_record_item_id === item.id
      );
      if (inputIdx !== -1) {
        return assessmentRecords[categoryIdx].input[inputIdx];
      }
    }
    return {
      id: 0,
      custom_record_item_id: 0,
      input_data: ""
    };
  };

  // custom_record_item_idが同値となっている複数入力内容を取得（複数テキスト形式用）
  const getInputs = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"] => {
    const categoryIdx = assessmentRecords.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx === -1) {
      return [];
    }
    return assessmentRecords[categoryIdx].input.filter(
      (i) => i.custom_record_item_id === item.id
    );
  };

  const getOptions = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): { label: string; value: string }[] => {
    return item.custom_record_item_choices.map((choice) => {
      return { label: choice.name, value: `${choice.id}` };
    });
  };

  // 入力がある記録項目のみ取得してソート
  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .sort((a, b) => {
      // 並べ替え不可項目は先頭に移動させ、default category で並び替えを行う
      if (!a.allow_change_order && b.allow_change_order) return -1;
      if (!b.allow_change_order && a.allow_change_order) return 1;
      if (
        !a.allow_change_order &&
        !b.allow_change_order &&
        a.default_category &&
        b.default_category
      ) {
        return a.default_category < b.default_category ? -1 : 1;
      }
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    })
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          const input = getInput(i);
          const exist_life_histories =
            input.life_histories && input.life_histories.length > 0;
          const exist_disability_histories =
            input.disability_histories && input.disability_histories.length > 0;
          const exist_flow_days = input.flow_days && input.flow_days.length > 0;
          const isMultiText =
            i.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text;
          let multiTextHasText = false;
          if (isMultiText) {
            const inputs = getInputs(i);
            multiTextHasText = inputs.some(
              (v) => v.input_data && v.input_data !== ""
            );
          }
          return (
            input.input_data ||
            (!isMultiText && !!input.choiced_item_id) ||
            (isMultiText && multiTextHasText) ||
            exist_life_histories ||
            exist_disability_histories ||
            exist_flow_days
          );
        })
        .sort((a, b) => {
          // 並べ替え不可項目は先頭に移動させ、default item で並び替えを行う
          if (!a.allow_change_order && b.allow_change_order) return -1;
          if (!b.allow_change_order && a.allow_change_order) return 1;
          if (
            !a.allow_change_order &&
            !b.allow_change_order &&
            a.default_item &&
            b.default_item
          ) {
            return a.default_item < b.default_item ? -1 : 1;
          }
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter((r) => {
      return r.custom_record_items.length > 0;
    });

  const getTimeTable = (): (JSX.Element[] | null)[][] => {
    // カテゴリごと
    return sortedCustomRecords.map((category) => {
      // タイムテーブル形式の項目を抽出
      const filteredItem = category.custom_record_items.filter((v) =>
        checkItemIsFlowDays(facilityType, v.default_item)
      );
      // 項目ごと
      return filteredItem.map((item) => {
        const input = getInput(item);
        return input.flow_days
          ? input.flow_days.map((flowDay) => {
              return (
                <AssessmentPrintFlowDay key={flowDay.id} flowDay={flowDay} />
              );
            })
          : null;
      });
    });
  };

  const getTableRows = (): JSX.Element[][] => {
    /** このテーブルで描画された行数 */
    let displayRowCount = 0;
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // テキスト形式とタイムテーブル形式は別テーブルになるため、タイムテーブル形式の項目を除外
      const filteredItem = category.custom_record_items.filter(
        (v) => !checkItemIsFlowDays(facilityType, v.default_item)
      );
      // 項目ごと
      return filteredItem.map((item, iIdx) => {
        const input = getInput(item);
        const lastItem = lastCategory && filteredItem.length === iIdx + 1;
        displayRowCount += 1;
        const isHideCategoryName =
          iIdx !== 0 ||
          (iIdx === 0 &&
            categoryType === facilityCategoryType.living_situation) ||
          checkItemIsLifeHistories(facilityType, item.default_item) ||
          checkItemIsDisabilityHistories(facilityType, item.default_item);
        return (
          <React.Fragment key={item.id}>
            {isHideCategoryName ? null : (
              <div
                className={`${classes.cell} text${
                  lastCategory ? " lastRow" : ""
                }`}
                style={{
                  gridRowStart: displayRowCount,
                  gridRowEnd: displayRowCount + filteredItem.length
                }}
              >
                {category.name}
              </div>
            )}
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {item.name}
            </div>
            {input.life_histories && (
              <HistoryCell
                classes={classes}
                histories={input.life_histories}
                isLastItem={lastItem}
              />
            )}
            {input.disability_histories && (
              <HistoryCell
                classes={classes}
                histories={input.disability_histories}
                isLastItem={lastItem}
              />
            )}
            {input.input_data && (
              <div
                className={`${classes.cell} text lastColumn${
                  lastItem ? " lastRow" : ""
                }`}
              >
                {input.input_data}
              </div>
            )}
          </React.Fragment>
        );
      });
    });
  };

  const getTableRowsMultiText = (): JSX.Element[][] => {
    /** このテーブルで描画された行数(初期値でヘッダー行) */
    let displayRowCount = 1;
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // 項目ごと
      return category.custom_record_items.map((customRecordItem, iIdx) => {
        const inputs = getInputs(customRecordItem);
        const options = getOptions(customRecordItem);
        const multiTexts = customRecordItem.custom_record_item_choices.reduce(
          (prev, current) => {
            const value = inputs.find((v) => v.choiced_item_id === current.id);
            return {
              ...prev,
              [current.id]: {
                id: value ? value.id : null,
                value: value ? value.input_data : ""
              }
            };
          },
          {}
        );

        const lastItem =
          lastCategory && category.custom_record_items.length === iIdx + 1;
        displayRowCount += 1;
        return (
          <React.Fragment key={customRecordItem.id}>
            {iIdx === 0 && (
              <div
                className={`${classes.cell} text${
                  lastCategory ? " lastRow" : ""
                }`}
                style={{
                  gridRowStart: displayRowCount,
                  gridRowEnd:
                    displayRowCount + category.custom_record_items.length
                }}
              >
                {category.name}
              </div>
            )}
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {customRecordItem.name}
            </div>
            {options.map((option, optionIndex) => {
              const keyNum = optionIndex;
              const lastDot =
                customRecordItem.custom_record_item_choices.length !==
                optionIndex + 1;
              return (
                <div
                  key={`multiText-${option.value}-${keyNum}`}
                  className={`${classes.cell} multi ${
                    lastDot ? " borderDashed" : " lastColumn"
                  }${lastItem ? " lastRow" : ""}`}
                >
                  {multiTexts ? multiTexts[option.value].value : ""}
                </div>
              );
            })}
          </React.Fragment>
        );
      });
    });
  };

  const getTableOpinionsOnChiikiiko = (): JSX.Element[][] => {
    /** このテーブルで描画された行数 */
    let displayRowCount = 0;
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // テキスト形式とタイムテーブル形式は別テーブルになるため、タイムテーブル形式の項目を除外
      const filteredItem = category.custom_record_items.filter(
        (v) => !checkItemIsFlowDays(facilityType, v.default_item)
      );
      // 項目ごと
      return filteredItem.map((item, iIdx) => {
        const input = getInput(item);
        const lastItem = lastCategory && filteredItem.length === iIdx + 1;
        const isOpinionsItem = checkItemIsChiikiikoOpinions(
          facilityType,
          item.default_item
        );
        displayRowCount += isOpinionsItem ? 2 : 1;
        return (
          <React.Fragment key={item.id}>
            {iIdx === 0 ? (
              <div
                className={`${classes.cell} text${
                  lastCategory ? " lastRow" : ""
                }`}
                style={{
                  gridRowStart: isOpinionsItem
                    ? displayRowCount - 1
                    : displayRowCount,
                  gridRowEnd: displayRowCount + filteredItem.length
                }}
              >
                {category.name}
              </div>
            ) : null}
            {isOpinionsItem ? (
              <>
                <div className={`${classes.cell} text`}>意思</div>
                <div className={`${classes.cell} text lastColumn`}>
                  {findChooseRadioLabel(
                    item.custom_record_item_choices,
                    input.choiced_item_id
                  )}
                </div>
                <div
                  className={`${classes.cell} text${
                    lastItem ? " lastRow" : ""
                  }`}
                >
                  理由・詳細
                </div>
                <div
                  className={`${classes.cell} text lastColumn${
                    lastItem ? " lastRow" : ""
                  }`}
                >
                  {input.input_data}
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${classes.cell} text${
                    lastItem ? " lastRow" : ""
                  }`}
                >
                  {item.name}
                </div>
                <div
                  className={`${classes.cell} text lastColumn${
                    lastItem ? " lastRow" : ""
                  }`}
                >
                  {input.input_data}
                </div>
              </>
            )}
          </React.Fragment>
        );
      });
    });
  };

  if (sortedCustomRecords.length === 0) {
    return <></>;
  }

  const addClasses = {
    [facilityCategoryType.summary]: "",
    [facilityCategoryType.living_situation]: "living_situation",
    [facilityCategoryType.situation]: "situation",
    [facilityCategoryType.request]: "",
    [facilityCategoryType.others]: ""
  };

  const CategoryHeader = (categoryHeaderProps: {
    targetCategoryType: number;
    targetFacilityType: FacilityType;
  }): JSX.Element => {
    return (
      <div className={classes.categoryName}>
        {getCategoryName(
          categoryHeaderProps.targetCategoryType,
          categoryHeaderProps.targetFacilityType
        )}
      </div>
    );
  };

  const getTableRowsSwitcher = (): JSX.Element => {
    // カテゴリータイプに対応するテーブルボディ
    switch (categoryType) {
      case facilityCategoryType.living_situation: {
        const isFlowDaysOnly = checkCategoryIsOnlyFlowDaysData(
          sortedCustomRecords,
          facilityType
        );
        return (
          <>
            {getTimeTable()}
            {isFlowDaysOnly ? null : (
              <div
                className={`${classes.table} ${addClasses[categoryType]} ${classes.MT16}`}
              >
                {getTableRows()}
              </div>
            )}
          </>
        );
      }
      case facilityCategoryType.situation:
        return (
          <div className={`${classes.table} ${addClasses[categoryType]}`}>
            {header}
            {getTableRowsMultiText()}
          </div>
        );
      case facilityCategoryType.opinions_on_chiikiikou:
        return (
          <div className={`${classes.table} ${addClasses[categoryType]}`}>
            {header}
            {getTableOpinionsOnChiikiiko()}
          </div>
        );
      default:
        return (
          <div className={`${classes.table} ${addClasses[categoryType]}`}>
            {getTableRows()}
          </div>
        );
    }
  };

  return (
    <div>
      <CategoryHeader
        targetCategoryType={categoryType}
        targetFacilityType={facilityType}
      />
      {sortedCustomRecords.length !== 0 && getTableRowsSwitcher()}
    </div>
  );
};

const AssessmentPrintCore = (props: Props): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);

  const {
    uifId,
    assessmentId,
    classes,
    userInFacility,
    assessmentRecord,
    customRecords,
    user,
    cityListView,
    getUserDetailInFacilityData,
    fetchAssessment,
    fetchCustomRecords,
    fetchFacilityCity
  } = props;

  React.useEffect(() => {
    getUserDetailInFacilityData(uifId, user.facility_type);
    fetchAssessment(uifId, assessmentId);
    fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  // 事業所市区町村取得
  React.useEffect(() => {
    if (
      userInFacility.user_in_facility.prefecture_name !== undefined &&
      userInFacility.user_in_facility.prefecture_name !== "" &&
      userInFacility.user_in_facility.prefecture_name !== DEFAULT_SELECT_VALUE
    ) {
      fetchFacilityCity({
        prefectureName: userInFacility.user_in_facility.prefecture_name
      });
    }
  }, [userInFacility]);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  if (customRecords.length === 0 || !assessmentRecord.created_at) {
    return null;
  }

  return (
    <Sheet
      classes={classes}
      userInFacility={userInFacility}
      assessmentRecord={assessmentRecord}
      customRecords={customRecords}
      facilityType={user.facility_type}
      cityListView={cityListView}
    />
  );
};

const BasicInformation = ({
  classes,
  ...props
}: {
  facilityType: FacilityType;
  classes: Record<string, string>;
  customRecords: CustomRecordsWithCategoryState;
  assessmentRecord: AssessmentState["assessment"];
}): JSX.Element => {
  const keikakusodanOfficeNameInput = findInputFromDefaultItem(
    findCategoryType(props.facilityType, "basic_information"),
    findDefaultItem(props.facilityType, "keikakusodanOffice"),
    props.customRecords,
    props.assessmentRecord.assessment.assessment_records
  );

  const keikakusodanCarerInput = findInputFromDefaultItem(
    findCategoryType(props.facilityType, "basic_information"),
    findDefaultItem(props.facilityType, "keikakusodanSupporter"),
    props.customRecords,
    props.assessmentRecord.assessment.assessment_records
  );

  return (
    <div className={classNames(classes.userInfo, classes.MT24)}>
      <div className={classes.userInfoAddressContainer}>
        <span className={classes.userInfoItem}>計画相談事業所</span>
        <div className={classes.userInfoValue}>
          <span>
            {keikakusodanOfficeNameInput
              ? keikakusodanOfficeNameInput.input_data
              : "-"}
          </span>
        </div>
      </div>
      <div className={classes.userInfoTelContainer}>
        <span className={classes.userInfoItem}>計画相談担当者</span>
        <div className={classes.userInfoValue}>
          <span>
            {keikakusodanCarerInput ? keikakusodanCarerInput.input_data : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    userInFacility,
    assessmentRecord,
    customRecords,
    facilityType,
    cityListView
  } = props;

  const userDetail = userInFacility.user_in_facility;

  const getAuthorString = (): string => {
    const { author } = assessmentRecord;
    if (!author) {
      return "-";
    }
    const authorName = author.snapshot_name || author.name;
    const authorRole = author.snapshot_role || author.role;
    return `${authorName}（${authorRole}）`;
  };

  let printCategory: number[] = [];
  switch (props.facilityType) {
    case FacilityType.KEIKAKUSODAN:
      printCategory = [
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary,
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation,
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation,
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request,
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others
      ];
      break;
    case FacilityType.CHIIKIIKO:
      printCategory = [
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.summary,
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation,
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation,
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou,
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.others
      ];
      break;
    case FacilityType.CHIIKITEICHAKU:
      printCategory = [
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.summary,
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation,
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation,
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.request,
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.others
      ];
      break;
    default:
      break;
  }

  // 障害種別
  const flags = {
    classify_physical_flg: "身体障害",
    classify_intelligence_flg: "知的障害",
    classify_mind_flg: "精神障害",
    classify_growth_flg: "発達障害",
    classify_brain_flg: "高次脳機能障害",
    classify_handicapped_flg: "障害児",
    classify_incurable_flg: "難病等対象者"
  };

  const classifies = Object.entries(flags)
    .filter(([flag]) => userDetail[flag] === "1")
    .map(([, value]) => value);

  const id = userDetail.city_id;
  let cityLabelView = "";
  if (cityListView !== null) {
    Object.keys(cityListView).forEach((key) => {
      if (cityListView[key].value === id) {
        cityLabelView = cityListView[key].label;
      }
    });
  }
  const postalCode = userDetail.postal_code
    ? `〒${userDetail.postal_code} `
    : "";
  const address =
    userDetail.prefecture_name && cityListView && userDetail.address
      ? `${postalCode}${userDetail.prefecture_name}${cityLabelView}${userDetail.address}`
      : "";

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTitleContainer}>
          <div className={classes.headerTitle}>アセスメントシート</div>
          {assessmentRecord.assessment.type_consultation ===
            TYPE_CONSULTATION_SHOGAIJISODAN && (
            <div className={classes.headerLabel}>障害児相談支援</div>
          )}
        </div>
        <div>
          <div className={classes.headerCreate}>
            作成日：
            {assessmentRecord.assessment.target_date
              ? dateToLocalisedString(
                  assessmentRecord.assessment.target_date,
                  "YYYY年M月D日"
                )
              : "-"}
          </div>
          <div className={classes.headerCreate}>
            作成者：{getAuthorString()}
          </div>
        </div>
      </div>
      <div>
        <div className={classNames(classes.userInfo, classes.MT24)}>
          <div className={classes.userInfoNameContainer}>
            <span className={classes.userInfoItem}>氏名（フリガナ）</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.name_sei} ${userDetail.name_mei}（${userDetail.name_sei_kana} ${userDetail.name_mei_kana}）`}</span>
            </div>
          </div>
          <div className={classes.userInfoBirthContainer}>
            <span className={classes.userInfoItem}>生年月日(年齢)</span>
            <div className={classes.userInfoValue}>
              <span>
                {userDetail.date_birth !== undefined
                  ? dateToLocalisedString(userDetail.date_birth, "YYYY年M月D日")
                  : ""}
                {`(${getAge(
                  userDetail.date_birth,
                  assessmentRecord.created_at
                )}歳)`}
              </span>
            </div>
          </div>
          <div className={classes.userInfoGenderContainer}>
            <span className={classes.userInfoItem}>性別</span>
            <div className={classes.userInfoValue}>
              <span>{userDetail.gender === "1" ? "男性" : "女性"}</span>
            </div>
          </div>
        </div>
        <div className={classNames(classes.userInfo, classes.MT16)}>
          <div className={classes.userInfoClassifyContainer}>
            <span className={classes.userInfoItem}>障害種別</span>
            <div className={classes.userInfoValue}>
              <span>{classifies.length > 0 ? classifies.join("、") : "-"}</span>
            </div>
          </div>
          <div className={classes.userInfoClassifyDisabilitySupportContainer}>
            <span className={classes.userInfoItem}>障害支援区分</span>
            <div className={classes.userInfoValue}>
              <span>{getFacilityClass(userInFacility)}</span>
            </div>
          </div>
        </div>
        <div className={classNames(classes.userInfo, classes.MT16)}>
          <div className={classes.userInfoAddressContainer}>
            <span className={classes.userInfoItem}>住所</span>
            <div className={classes.userInfoValue}>
              <span>{address}</span>
            </div>
          </div>
          <div className={classes.userInfoTelContainer}>
            <span className={classes.userInfoItem}>連絡先</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.tel ? userDetail.tel : "-"}`}</span>
            </div>
          </div>
        </div>
        {checkHasBasicInformationCategory(facilityType) ? (
          <BasicInformation
            classes={classes}
            facilityType={facilityType}
            customRecords={customRecords}
            assessmentRecord={assessmentRecord}
          />
        ) : null}
      </div>
      {printCategory.map((type) => (
        <Contents
          key={type}
          customRecords={customRecords}
          assessmentRecords={assessmentRecord.assessment.assessment_records}
          categoryType={type}
          classes={classes}
          facilityType={props.facilityType}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const assessmentRecord = state.assessment.assessment;
  const facilityType = state.user.facility_type;

  const getUserInFacility = (): StateProps["userInFacility"] => {
    return state[facilityType].userInFacility.user;
  };
  const customRecords = state.customRecordsWithCategory.assesment;
  return {
    user,
    assessmentRecord,
    userInFacility: getUserInFacility(),
    customRecords,
    cityListView: state.city
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    assessmentDispatcher,
    customRecordsWithCategory,
    cityDispatch
  } = dispatches;

  const fetchAssessment = (
    uifId: string,
    assessmentId: string
  ): Promise<void> =>
    assessmentDispatcher(dispatch).fetchAssessment(uifId, assessmentId);
  const getUserDetailInFacilityData = (
    uifId: string,
    facilityType: FacilityType
  ): Promise<void> => {
    return dispatches[facilityType]
      .userInFacilityDispatcher(dispatch)
      .fetchOne(uifId);
  };
  const fetchCustomRecords = (): Promise<void> =>
    customRecordsWithCategory(dispatch).fetchCustomRecords(
      CUSTOM_RECORD_TARGET_TYPE.assessment
    );

  const fetchFacilityCity = (params: CityParams): Promise<void> =>
    cityDispatch(dispatch).fetch(params);

  return {
    fetchAssessment,
    fetchCustomRecords,
    getUserDetailInFacilityData,
    fetchFacilityCity
  };
};

export const AssessmentPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssessmentPrintCore));
