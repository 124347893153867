import * as React from "react";

import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import LackFields from "@components/v202104/organisms/mgr/common/facility/items/LackFields";

import { FormikProps, getIn } from "formik";

import { SUBTRACTION_OF_LARGE_SCALE_HOUSING } from "@constants/variables";
import { UnitsLargeScaleHousingFields } from "@components/v202104/organisms/mgr/GroupHome/facility/items/UnitsLargeScaleHousingFields";
import { FacilityValues } from "@initialize/v202104/mgr/GroupHome/facility/initialValues";

type Props = {
  formikProps: FormikProps<FacilityValues>;
};

export const SubtractionItemFields = ({ formikProps }: Props): JSX.Element => {
  // 複数ユニットに応じて大規模住居等減算のフィールドが変わる
  const { operatingUnitFlag } = formikProps.values.basic;
  const { units } = formikProps.values;

  const onChangeSubtractionOfLargeScaleHousingFlag = (): void => {
    const subtractionOfLargeScaleHousingFlag = getIn(
      formikProps.values,
      "subtractionItem.subtractionOfLargeScaleHousingFlag"
    );
    formikProps.setFieldValue(
      "subtractionItem.subtractionOfLargeScaleHousingFlag",
      !subtractionOfLargeScaleHousingFlag
    );
    if (operatingUnitFlag && !subtractionOfLargeScaleHousingFlag) {
      units.forEach((unit, index) => {
        formikProps.setFieldValue(
          `units[${index}].subtraction_of_large_scale_housing_type`,
          0
        );
      });
    }
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.establishedByLocalGovernmentsFlag"
        label="地方公共団体が設置"
      />
      <LackFields formikProps={formikProps} name="subtractionItem" />
      <FormikSwitch
        name="subtractionItem.subtractionOfLargeScaleHousingFlag"
        label="大規模住居等減算"
        onChange={onChangeSubtractionOfLargeScaleHousingFlag}
        style={{ marginBottom: 0 }}
      >
        {operatingUnitFlag ? (
          <UnitsLargeScaleHousingFields formikProps={formikProps} />
        ) : (
          <FormikRadioButtons
            name="subtractionItem.subtractionOfLargeScaleHousing"
            label=""
            options={SUBTRACTION_OF_LARGE_SCALE_HOUSING}
            style={{ marginTop: 0, marginLeft: 0 }}
          />
        )}
      </FormikSwitch>
    </FormPaper>
  );
};
