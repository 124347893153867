import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import icon_footer_knowbePng from "@images/icon_footer_knowbe.png";

const styles = (): StyleRules =>
  createStyles({
    knowbeLogoFooter: {
      position: "relative",
      padding: 0,
      width: 185,
      height: 24
    }
  });

type Props = WithStyles<typeof styles>;

const RecruitIcon = (props: Props): JSX.Element => (
  <img
    src={icon_footer_knowbePng}
    className={props.classes.knowbeLogoFooter}
    alt="https://www.recruit.co.jp/company/recruitcoltd/"
  />
);

export default withStyles(styles)(RecruitIcon);
