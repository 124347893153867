import * as XLSX from "xlsx";
import { WorkRecordsState } from "@stores/domain/workRecords/types";

import { FacilityType } from "@constants/variables";
import { summaryData } from "@utils/domain/workRecords/summaryData";
import { formatDate } from "@utils/domain/workRecords/formatDate";
import { serviceDeliveryData } from "@utils/domain/workRecords/serviceDeliveryData";

export const createExcelFile = (
  workRecordState: WorkRecordsState,
  facilityType: FacilityType,
  facilityUserName: string,
  targetFrom: string,
  targetTo: string,
  selectedUserIds: number[]
): void => {
  const targetDate = `${formatDate(targetFrom)}〜${formatDate(targetTo)}`;
  const workBook = XLSX.utils.book_new();

  const sheet1 = [];
  sheet1.push(
    ...summaryData(
      workRecordState,
      facilityType,
      targetDate,
      facilityUserName,
      selectedUserIds
    )
  );

  const workSheet = XLSX.utils.aoa_to_sheet(sheet1, {
    dateNF: "H:mm"
  });
  workSheet["!cols"] = [
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 160 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 400 }
  ];
  XLSX.utils.book_append_sheet(workBook, workSheet, "利用者サマリ");

  // ステータスごとの集計
  const sheet2 = [];
  sheet2.push(
    ...serviceDeliveryData(
      workRecordState,
      facilityType,
      targetDate,
      facilityUserName,
      selectedUserIds
    )
  );
  const workSheet2 = XLSX.utils.aoa_to_sheet(sheet2, {
    dateNF: "H:mm"
  });
  workSheet2["!cols"] = [
    { wpx: 150 },
    { wpx: 100 },
    { wpx: 160 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 110 },
    { wpx: 100 },
    { wpx: 100 },
    { wpx: 400 }
  ];
  XLSX.utils.book_append_sheet(workBook, workSheet2, "サービス提供状況サマリ");

  XLSX.writeFile(
    workBook,
    `作業時間_${formatDate(targetFrom)}-${formatDate(targetTo)}.xlsx`
  );
};
