import React from "react";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import { MobileKnowbeButton } from "@componentsMobile/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";

import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";

const styles = (): StyleRules =>
  createStyles({
    actionArea: {
      zIndex: 10,
      boxShadow: "0 -2px 4px 0 rgba(0, 0, 0, 0.2)",
      padding: 16,
      backgroundColor: "#fff",
      width: "100%",
      height: 72,
      position: "sticky",
      bottom: 0,
      display: "flex",
      gap: "16px",
      marginTop: "16px"
    },
    button: {
      padding: 0,
      width: "100%"
    }
  });

type OwnProps = {
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  isEdit: boolean;
  onClickReturnList: () => void;
  onClickStatusToEdit: () => void;
  onClickCancel: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureRecordActionsCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.actionArea}>
      {props.isEdit ? (
        <>
          <MobileKnowbeButton
            kind="outline"
            className={classes.button}
            onClick={props.onClickCancel}
            minWidth={120}
          >
            キャンセル
          </MobileKnowbeButton>
          <FormikSubmitButton
            key="submit-button"
            buttonName="保存する"
            className={classes.button}
            minWidth={120}
            formikProps={props.formikProps}
          />
        </>
      ) : (
        <>
          <MobileKnowbeButton
            kind="outline"
            className={classes.button}
            onClick={props.onClickReturnList}
            minWidth={120}
          >
            一覧に戻る
          </MobileKnowbeButton>
          <MobileKnowbeButton
            kind="default"
            className={classes.button}
            onClick={props.onClickStatusToEdit}
            minWidth={120}
          >
            編集する
          </MobileKnowbeButton>
        </>
      )}
    </div>
  );
};

export const SupportProcedureRecordActions = withStyles(styles)(
  SupportProcedureRecordActionsCore
);
