import * as React from "react";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";
import { isUndefined } from "lodash-es";

// type
import { FacilityType, PatternListType } from "@constants/variables";
import { UserState } from "@stores/domain/user/type";
import { SelectMonthValue } from "@interfaces/ui/form";

// api
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

// components
import { FieldKana } from "@components/organisms/mgr/common/record/recordEachUser/search/FieldKana";
import { FieldExpiration } from "@components/organisms/mgr/common/record/recordEachUser/search/FieldExpiration";
import { SearchUsersFormAction } from "@components/organisms/mgr/common/record/recordEachUser/search/SearchUsersFormAction";
import { FieldUnit } from "@components/organisms/mgr/common/record/recordEachUser/search/FieldUnit";
import { FieldMonitoring } from "@components/organisms/mgr/common/record/recordEachUser/search/FieldMonitoring";

const styles = (): StyleRules =>
  createStyles({
    searchWrap: {
      borderRadius: 4,
      backgroundColor: "#fafafa",
      marginTop: 20,
      border: "solid 1px #bdbdbd",
      padding: "20px 16px 0",
      position: "relative",
      marginBottom: "30px"
    },
    searchTextWrap: {
      fontSize: 14,
      letterSpacing: 0.1,
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 10
    }
  });

type Props = {
  className?: string;
  handleSearchUsers: (e: React.MouseEvent<HTMLElement>) => void;
  handleClear: (e: React.MouseEvent<HTMLElement>) => void;
  targetPattern: PatternListType[];
  setTargetPattern: React.Dispatch<React.SetStateAction<PatternListType[]>>;
  expiredVisibility: boolean;
  setExpiredVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  unitGroup?: number;
  setUnitGroup?: React.Dispatch<React.SetStateAction<number>>;
  optionalCustomInfo?: GetOptionalCustomInfoResponse["data"];
  userState?: UserState;
  handleChangeMonthSelect?: (selectDate: SelectMonthValue) => void;
  isReset?: boolean;
  setIsReset?: React.Dispatch<React.SetStateAction<boolean>>;
} & WithStyles<typeof styles>;

// ユニット/グループ選択ボックスを表示する種別
const isViewFieldUnitFacilityTypes = [
  FacilityType.GROUP_HOME,
  FacilityType.SHISETSUNYUSHO
];

/**
 * 絞り込みFormをまとめる
 */
const SearchUsersFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    handleSearchUsers,
    handleClear,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    unitGroup,
    setUnitGroup,
    optionalCustomInfo,
    userState,
    handleChangeMonthSelect,
    isReset,
    setIsReset
  } = props;

  return (
    <div className={classes.searchWrap}>
      <div className={classes.searchTextWrap}>表示の絞り込み</div>
      {/* 利用者かな絞り込みフォーム */}
      <FieldKana
        setTargetPattern={setTargetPattern}
        targetPattern={targetPattern}
      />

      {/* 退所者非表示チェックボックス */}
      <FieldExpiration
        expiredVisibility={expiredVisibility}
        setExpiredVisibility={setExpiredVisibility}
      />

      {/* ユニット一覧（GH, 施設入所） */}
      {userState &&
        !isUndefined(unitGroup) &&
        setUnitGroup &&
        isViewFieldUnitFacilityTypes.includes(userState.facility_type) && (
          <FieldUnit
            unitGroup={unitGroup}
            setUnitGroup={setUnitGroup}
            optionalCustomInfo={optionalCustomInfo}
          />
        )}

      {/* モニタリング実施年月（計画相談） */}
      {userState &&
        handleChangeMonthSelect &&
        userState.facility_type === FacilityType.KEIKAKUSODAN && (
          <FieldMonitoring
            handleChangeMonthSelect={handleChangeMonthSelect}
            isReset={isReset}
            setIsReset={setIsReset}
          />
        )}

      {/* リセット・絞り込みボタン */}
      <SearchUsersFormAction
        onClickSearch={handleSearchUsers}
        onClickReset={handleClear}
      />
    </div>
  );
};

export const SearchUsersForm = withStyles(styles)(SearchUsersFormCore);
