import * as React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { EditInitialForm } from "@components/organisms/mgr/KEIKAKUSODAN/initial/EditInitialForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

/**
 * 初期設定情報
 */
export const InitialData = (): JSX.Element => (
  <AdminTemplate pageName="初期設定情報">
    <EditInitialForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
