/**
 * ActionNames
 */

import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";
import { ConsultationRecordState } from "../consultation/types";

// 取得
export const FETCH_MONITORINGREPORT_STARTED =
  "MONITORINGREPORT/FETCH_MONITORINGREPORT_STARTED";
export const FETCH_MONITORINGREPORT_SUCCESS =
  "MONITORINGREPORT/FETCH_MONITORINGREPORT_SUCCESS";
export const FETCH_MONITORINGREPORT_FAILED =
  "MONITORINGREPORT/FETCH_MONITORINGREPORT_FAILED";
// 作成済み計画読み込み
export const COPY_CONSULTATION_STARTED =
  "MONITORINGREPORT/COPY_CONSULTATION_STARTED";
export const COPY_CONSULTATION_SUCCESS =
  "MONITORINGREPORT/COPY_CONSULTATION_SUCCESS";
export const COPY_CONSULTATION_FAILED =
  "MONITORINGREPORT/COPY_CONSULTATION_FAILED";
// 作成済み計画初期化
export const RESET_COPY_CONSULTATION =
  "MONITORINGREPORT/RESET_COPY_CONSULTATION";
// 更新
export const POST_MONITORINGREPORT_STARTED =
  "MONITORINGREPORT/POST_MONITORINGREPORT_STARTED";
export const POST_MONITORINGREPORT_SUCCESS =
  "MONITORINGREPORT/UPDATE_MONITORINGREPORT_SUCCESS";
export const POST_MONITORINGREPORT_FAILED =
  "MONITORINGREPORT/POST_MONITORINGREPORT_FAILED";
// 削除
export const DELETE_MONITORINGREPORT_STARTED =
  "MONITORINGREPORT/DELETE_MONITORINGREPORT_STARTED";
export const DELETE_MONITORINGREPORT_SUCCESS =
  "MONITORINGREPORT/DELETE_MONITORINGREPORT_SUCCESS";
export const DELETE_MONITORINGREPORT_FAILED =
  "MONITORINGREPORT/DELETE_MONITORINGREPORT_FAILED";
export const UNSET_MONITORINGREPORT = "MONITORINGREPORT/UNSET_MONITORINGREPORT";

export type MonitoringReportState = {
  monitoringReportRecord: GetMonitoringReport["data"];
  consultationCopy: ConsultationRecordState | null;
};
