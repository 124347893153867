import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportProcedureListResponse = {
  data: {
    support_procedure_forms: {
      service_delivery_records_id: number | null;
      inout_results_id: number | null;
      support_procedure_forms_id: number | null;
      users_in_facility_id: number;
      target_date: string | null;
      is_holiday: boolean;
      start_time_1: string;
      end_time_1: string;
      start_time_2: string;
      end_time_2: string;
      number_of_practitioner: number;
      title: string;
      recorded_flg: number;
      unplanned_flg: number;
      procedure_status_flg: number;
    }[];
    number_of_plans: number;
    number_of_records: number;
    number_of_procedures: number;
  };
};

export const getSupportProcedureList = async (
  uifId: string,
  date: string
): Promise<AxiosResponse<GetSupportProcedureListResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_procedure/list/${date}`;
  return request.get<GetSupportProcedureListResponse>(url);
};
