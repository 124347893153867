import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import SectionTitle from "@components/atoms/SectionTitle";
import { dateToLocalisedString } from "@/utils/date";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as DOKOENGOUsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import getAge from "@utils/date/getAge";

const styles = (): StyleRules =>
  createStyles({
    user: {
      background: "#fff",
      borderRadius: "4px",
      padding: "30px 32px"
    },
    userTitle: {
      fontWeight: 500
    },
    userCon: {
      marginTop: "32px",
      display: "flex",
      flexWrap: "wrap"
    },
    userConItem: {
      margin: "0 48px 0 0",
      display: "flex",
      alignItems: "center",
      "&:first-child,&:last-child": {
        "&>dt": {
          minWidth: "130px"
        }
      },
      "&:last-child": {
        width: "100%",
        marginTop: "16px"
      }
    },
    userConItemLabel: {
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.25px",
      color: "#37474f",
      fontWeight: "normal"
    },
    userConItemData: {
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginLeft: "16px",
      fontWeight: 500
    }
  });

type OwnProps = {
  user:
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"]
    | DOKOENGOUsersInFacilityState["user"]
    | KODOENGOUsersInFacilityState["user"];
};
type MergeProps = OwnProps & WithStyles<typeof styles>;

const CarePlanUserInfoCore = (props: MergeProps): JSX.Element => {
  const { user } = props;

  const [classifyList, setClassifyList] = React.useState<string[]>([]);

  React.useEffect(() => {
    const classify = [];
    if (user.user_in_facility.classify_physical_flg === "1") {
      classify.push("身体障害");
    }
    if (user.user_in_facility.classify_intelligence_flg === "1") {
      classify.push("知的障害");
    }
    if (user.user_in_facility.classify_mind_flg === "1") {
      classify.push("精神障害");
    }
    if (user.user_in_facility.classify_growth_flg === "1") {
      classify.push("発達障害");
    }
    if (user.user_in_facility.classify_brain_flg === "1") {
      classify.push("高次脳機能障害");
    }
    if (user.user_in_facility.classify_handicapped_flg === "1") {
      classify.push("障害児");
    }
    if (user.user_in_facility.classify_incurable_flg === "1") {
      classify.push("難病等対象者");
    }
    setClassifyList(classify);
  }, [user.user_in_facility]);

  const userInfo = [
    {
      label: "サービス提供開始日",
      data:
        user.user_in_facility.date_begin_in_service &&
        dateToLocalisedString(
          user.user_in_facility.date_begin_in_service,
          "YYYY年M月D日"
        )
    },
    {
      label: "性別",
      data: user.user_in_facility.gender === "1" ? "男性" : "女性"
    },
    {
      label: "生年月日",
      data:
        user.user_in_facility.date_birth !== null
          ? user.user_in_facility.date_birth &&
            `${dateToLocalisedString(
              user.user_in_facility.date_birth,
              "YYYY年M月D日"
            )} （${getAge(user.user_in_facility.date_birth)}歳）`
          : "-"
    },
    {
      label: "障害種別",
      data: classifyList.length > 0 ? classifyList.join("、") : "-"
    }
  ];

  return (
    <div className={props.classes.user}>
      <div className={props.classes.userTitle}>
        <SectionTitle
          label={`${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`}
          isTitleNoMargin
        />
      </div>
      <div className={props.classes.userCon}>
        {userInfo.map((item, index) => {
          const key = `key_${index}`;
          return (
            <dl className={props.classes.userConItem} key={key}>
              <dt className={props.classes.userConItemLabel}>{item.label}</dt>
              <dd className={props.classes.userConItemData}>{item.data}</dd>
            </dl>
          );
        })}
      </div>
    </div>
  );
};

export const CarePlanUserInfo = withStyles(styles)(CarePlanUserInfoCore);
