import * as types from "./types";
import * as actions from "./actions";
import {
  DEFAULT_CHECKBOX_VALUE,
  DEFAULT_RADIO_VALUE
} from "@constants/variables";

const initialState: types.FacilityState = {
  corporationName: "",
  officeNumber: "",
  officeName: "",
  serviceType: "",
  representativeName: "",
  capacity: "",
  postalCode: "",
  selectedPrefectureName: "",
  selectedCityCode: "",
  restAddress: "",
  tel: "",
  multiFunctionOfficeFlag: DEFAULT_CHECKBOX_VALUE,
  masterSubordinateFlg: DEFAULT_CHECKBOX_VALUE,
  masterFlg: "1",
  cityId: "",
  allCapacity: "",
  specificBetterSupporterCondition202404: DEFAULT_RADIO_VALUE,
  staffTreatmentSystemType: "",
  staffTreatmentSpecificSystemType: "",
  specificFacilitiesAddition: "",
  betterSupporterConditionBaseUpFlg: DEFAULT_CHECKBOX_VALUE,
  lifeSupportHubInDistrictFlg: DEFAULT_CHECKBOX_VALUE,
  bodyRestrictedStillFlg: DEFAULT_CHECKBOX_VALUE,
  bodyRestrictedStillFrom: "",
  bodyRestrictedStillTo: "",
  abusePreventionMeasuresNotImplementedFlg: DEFAULT_CHECKBOX_VALUE,
  abusePreventionMeasuresNotImplementedFrom: "",
  abusePreventionMeasuresNotImplementedTo: "",
  informationDisclosureNotReportedFlg: DEFAULT_CHECKBOX_VALUE,
  informationDisclosureNotReportedFrom: "",
  informationDisclosureNotReportedTo: ""
};

export const JUDOHOMONKAIGOFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.FacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_FAILED:
      return { ...state };
    case types.POST_STARTED:
      return { ...state };
    case types.POST_SUCCESS:
      return { ...state, ...action.payload };
    case types.POST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
