import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import * as classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    buttons: {
      display: "flex"
    },
    center: {
      justifyContent: "center"
    },
    right: {
      justifyContent: "flex-end"
    }
  });

type OwnProps = {
  confirmButtonLabel: string;
  flexCenter: boolean;
  disabled: boolean;
  handleSubmit: () => void;
  handleCancel: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const FormButtons: React.FunctionComponent<Props> = ({
  classes,
  confirmButtonLabel,
  flexCenter,
  disabled,
  handleSubmit,
  handleCancel
}) => (
  <div
    className={classNames(
      classes.buttons,
      flexCenter ? classes.center : classes.right
    )}
  >
    <KnowbeButton kind="outline" onClick={handleCancel} minWidth={160}>
      キャンセル
    </KnowbeButton>
    <KnowbeButton
      onClick={handleSubmit}
      disabled={disabled}
      minWidth={160}
      style={{ marginLeft: 16, padding: "6px 16px" }}
    >
      {confirmButtonLabel}
    </KnowbeButton>
  </div>
);

export default withStyles(styles)(FormButtons);
