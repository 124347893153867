import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetBasicInfoListResponse = {
  data: {
    id: number;
    target_date: string;
    type_consultation: number;
    author_name: string;
  }[];
};

/**
 * 申請者の現状 (基本情報) 一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const getBasicInfoList = async (
  uifId: string
): Promise<AxiosResponse<GetBasicInfoListResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/basic_info`;
  return request.get<GetBasicInfoListResponse>(url);
};
