import * as React from "react";

import { convertWareki } from "@utils/date";

import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";

import { SummaryByMonth } from "@stores/domain/invoice/type";
import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";

const TABLE_HEAD_HEIGHT = 20;

// 給付費請求サマリーの表示数
const INVIOCE_SUMMARY_TOTAL_FIRST_PAGE_DISPLAY_NUM = 32;
const INVIOCE_SUMMARY_TOTAL_PAGE_DISPLAY_NUM = 38;

const styles = (): StyleRules =>
  createStyles({
    root: {},
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: PRINT_PAGE_PADDING,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 12,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.space-between": {
        justifyContent: "space-between"
      }
    },
    invoiceContainer: {
      marginBottom: 32
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 20,
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 80
        },
        "&.msize": {
          width: 120
        },
        "&.lsize": {
          width: 150
        },
        "&.llsize": {
          width: 250
        },
        "&.vertical": {
          width: 18,
          letterSpacing: 0,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderBoldLeft": {
          borderLeft: "2px solid"
        },
        "&.totalAmountLabel": {
          fontSize: 14
        },
        "&.totalAmount": {
          fontSize: 18
        },
        "&.pageNumber": {
          padding: "0.5px 7px"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderTop: "2px solid"
        }
      }
    },
    title: {
      margin: "0 0 10px 0",
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.84)"
    },
    city: {
      fontSize: 14,
      marginTop: 80
    },
    note: {
      fontSize: 14
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type OwnProps = {
  data: SummaryByMonth;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InvoiceSummaryTotal = (props: Props): JSX.Element => {
  const { data, classes } = props;
  const { detail } = data;

  // 1ページ目は32、2ページ以降は38で改ページする
  const tableRowStartEndIndicesInSheet: TableRowStartEndIndices[] = [];
  const totalRow = detail.length + 1; // 1 => 合計行
  if (detail.length < INVIOCE_SUMMARY_TOTAL_FIRST_PAGE_DISPLAY_NUM) {
    tableRowStartEndIndicesInSheet.push({
      startIndex: 0,
      endIndex: totalRow
    });
  } else {
    let endIndex = INVIOCE_SUMMARY_TOTAL_FIRST_PAGE_DISPLAY_NUM - 1;
    detail.forEach((v, i) => {
      if (i === 0) {
        tableRowStartEndIndicesInSheet.push({
          startIndex: 0,
          endIndex
        });
      } else if (i > endIndex) {
        endIndex += INVIOCE_SUMMARY_TOTAL_PAGE_DISPLAY_NUM;
        tableRowStartEndIndicesInSheet.push({
          startIndex: i,
          endIndex: totalRow < endIndex ? totalRow : endIndex
        });
      }
    });
  }

  return (
    <>
      {tableRowStartEndIndicesInSheet.map((tableRowStartEndIndexInSheet, i) => (
        <Sheet
          key={tableRowStartEndIndexInSheet.startIndex}
          classes={classes}
          page={i + 1}
          totalPage={tableRowStartEndIndicesInSheet.length}
          summary={data}
          tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
        />
      ))}
    </>
  );
};

type SheetProps = {
  summary: SummaryByMonth;
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  page: number;
  totalPage: number;
} & WithStyles<typeof styles>;

const Sheet: React.SFC<SheetProps> = (props) => {
  const {
    classes,
    page,
    totalPage,
    summary,
    tableRowStartEndIndexInSheet
  } = props;
  const { totalInvoiceAmount, date, facility, detail, total } = summary;
  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;
  const totalRowIndex = detail.length;

  return (
    <section className={classes.page}>
      <div>
        <header>
          <h1 className={classes.title}>給付費請求サマリ</h1>
        </header>
        <div className={`${classes.flexContainer} ${classes.note}`}>
          <span>
            対象請求月：
            {convertWareki(date.year, date.month).warekiYear}
            {date.month}月分
          </span>
        </div>
        {startIndex === 0 ? (
          <div>
            <div className={classes.note}>
              <span>事業所情報</span>
            </div>
            <div className={classes.flexContainer}>
              <table className={`${classes.table} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="label ssize">事業所番号</td>
                    <td>{facility.c01}</td>
                  </tr>
                  <tr>
                    <td className="label ssize">事業所名</td>
                    <td>{facility.c02}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className={`${classes.flexContainer} ${classes.invoiceContainer}`}
            >
              <table className={`${classes.table} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="label totalAmountLabel msize">
                      請求合計金額
                    </td>
                    <td className="totalAmount">{totalInvoiceAmount}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        <div className={classes.note}>
          <span>請求詳細情報</span>
        </div>
      </div>
      <div className={classes.flexContainer}>
        <table className={`${classes.table} fullWidth`}>
          <tbody>
            <tr style={{ height: TABLE_HEAD_HEIGHT }}>
              <td className="sssize label">No.</td>
              <td className="lsize label">請求先</td>
              <td className="sssize label">件数</td>
              <td className="ssize label">費用合計</td>
              <td className="ssize label">給付費請求額</td>
              <td className="ssize label">利用者負担額</td>
              <td className="ssize label">自治体助成額</td>
            </tr>
            {detail.map((d, index) => {
              if (index < startIndex || index > endIndex) return undefined;
              const keyNumber = index;
              return (
                <tr id={`i${index}`} key={keyNumber}>
                  <td className="label">{index + 1}</td>
                  <td>{d.municipalityName}</td>
                  <td className="prise">{d.count}</td>
                  <td className="prise">{d.amount}</td>
                  <td className="prise">{d.benefitCostsClaim}</td>
                  <td className="prise">{d.userBurden}</td>
                  <td className="prise">{d.municipalitySubsidy}</td>
                </tr>
              );
            })}
            {totalRowIndex < startIndex || detail.length > endIndex ? null : (
              <tr className="borderBold">
                <td className="label" colSpan={2}>
                  合計
                </td>
                <td className="prise">{total.count}</td>
                <td className="prise">{total.amount}</td>
                <td className="prise">{total.benefitCostsClaim}</td>
                <td className="prise">{total.userBurden}</td>
                <td className="prise">{total.municipalitySubsidy}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className={`${classes.flexContainer} end`}>
        <table className={`${classes.table}`}>
          <tbody>
            <tr>
              <td className="pageNumber">{totalPage}枚中</td>
              <td className="pageNumber">{page}枚目</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default withStyles(styles)(InvoiceSummaryTotal);
