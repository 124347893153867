import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate202104 } from "@components/templates/AdminTemplate202104";
import { CreateUserForm } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/users/CreateUserForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

type Props = RouteComponentProps;

/**
 * 利用者情報 > 新規作成
 */
export const CreateUser202104 = (props: Props): JSX.Element => {
  return (
    <AdminTemplate202104 pageName="利用者情報">
      <CreateUserForm {...props} />
      <NavigationTransitionPrompt />
    </AdminTemplate202104>
  );
};
