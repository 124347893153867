/**
 * 年月日すべて入力または全て初期値かどうか
 */

import { SelectDateValue } from "@interfaces/ui/form";

const check = (value?: string): string | undefined => {
  return value ? undefined : "入力してください";
};

export const validDate = (
  date: SelectDateValue
): {
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
} => {
  if (date.year || date.month || date.day) {
    return {
      year: check(date.year),
      month: check(date.month),
      day: check(date.day)
    };
  }
  return { year: undefined, month: undefined, day: undefined };
};
