import { BasicValues } from "@interfaces/mgr/TANKINYUSHO/facility/basic";
import { SubtractionItemValues } from "@interfaces/mgr/TANKINYUSHO/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/mgr/TANKINYUSHO/facility/additionItem";
import { FacilityState } from "@stores/domain/mgr/TANKINYUSHO/facility/types";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import { initializeSpecificBetterSupporterCondition202404V } from "@utils/domain/facility/initializeSpecificBetterSupporterCondition202404V";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues;

const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      facilityType: state ? `${state.facilityType}` : "0",
      medicalType: state ? `${state.medicalType}` : "0",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      isMasterRadioValue: state ? state.isMasterRadioValue : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : DEFAULT_SELECT_VALUE,
      cityId: state ? state.selectedCityCode : DEFAULT_SELECT_VALUE,
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : ""
    },
    subtractionItem: {
      facilityCombiStatus: state ? `${state.facilityCombiStatus}` : "0",
      largeScaleFlg: state ? state.largeScaleFlg : false,
      lackOfLifeSupportMemberFlag: state
        ? state.lackOfLifeSupportMemberFlag
        : false,
      lackOfLifeSupportMemberStartDate:
        state && state.lackOfLifeSupportMemberStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfLifeSupportMemberStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" },
      bodyRestrictedStillFlg: state ? state.bodyRestrictedStillFlg : false,
      bodyRestrictedStillFrom: initializeSelectDate(
        state && state.bodyRestrictedStillFrom
      ),
      bodyRestrictedStillTo: initializeSelectDate(
        state && state.bodyRestrictedStillTo
      ),
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      workContinuationNotPlanningFlg: state
        ? state.workContinuationNotPlanningFlg
        : false,
      workContinuationNotPlanningFrom: initializeSelectDate(
        state && state.workContinuationNotPlanningFrom
      ),
      workContinuationNotPlanningTo: initializeSelectDate(
        state && state.workContinuationNotPlanningTo
      ),

      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      )
    },
    additionalItem: {
      specificBetterSupporterCondition202404: state
        ? initializeSpecificBetterSupporterCondition202404V(
            state.specificBetterSupporterCondition202404
          )
        : NO_SELECTED_CONDITION_TYPE_2024,
      specificBetterSupporterCondition202404Sub:
        state && state.specificBetterSupporterCondition202404
          ? state.specificBetterSupporterCondition202404
          : NO_SELECTED_CONDITION_TYPE_2024_SUB,
      welfareSpecialistPlacementType: state
        ? state.welfareSpecialistPlacementType
        : "1",
      welfareConditionStartDate: initializeSelectDate(
        state && state.welfareConditionStartDate
      ),
      welfareConditionEndDate: initializeSelectDate(
        state && state.welfareConditionEndDate
      ),
      fulltimeNursingStaffFlg: state ? state.fulltimeNursingStaff !== 0 : false,
      fulltimeNursingStaff: state ? `${state.fulltimeNursingStaff}` : "0",
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false,
      medicalSupportFlg: state ? state.medicalSupportFlg : false,
      dieticianFlg: state ? state.dietician !== 0 : false,
      dietician: state ? `${state.dietician}` : "0",
      seriousDisabilityFlg: state ? state.seriousDisabilityFlg : false,
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false
    }
  };
};

export default initialValues;
