import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSwitch from "@components/molecules/FormikSwitch";

import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { FacilityValues } from "@initialize/mgr/CHIIKIIKO/facility/initialValues";
import { FormikProps } from "formik";
import { DEFAULT_SELECT_DATE_VALUE } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = WithStyles<typeof styles> & OwnProps;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };
  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            style={{ marginBottom: 0 }}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
