import * as React from "react";

import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

import { GetSupportPlanResponse } from "@api/requests/supportPlan/getSupportPlan";
import { StyleRules } from "@material-ui/core/styles";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";

import { dateToLocalisedString } from "@/utils/date";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { SprintType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    dialogTitle: {
      color: "rgba(0, 0, 0, 0.87)",
      backgroundColor: "#f5f5f5",
      fontSize: 20,
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      minHeight: 53,
      maxWidth: 1070,
      padding: "11px 31px",
      "& > :first-child": {
        marginLeft: 8
      }
    },
    titleWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    section: {
      display: "flex",
      fontSize: 20,
      marginTop: 24,
      borderBottom: "solid 1px rgba(0, 0, 0, 0.54)",
      "& > span": {
        lineHeight: "45px"
      }
    },
    createData: {
      justifyContent: "space-between",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      marginLeft: "auto",
      marginBottom: 9
    },
    dialogContent: {
      width: 1070,
      padding: "0 32px"
    },
    textField: {
      margin: "24px 8px 16px",
      " & > div": {
        marginBottom: 16
      }
    },
    space: {
      marginLeft: 20
    }
  });

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  supportPlan: GetSupportPlanResponse["data"];
  userName: string;
  shortTermsNum?: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PlannedTargetDialogHasMonitoringCore = (
  props: Props
): JSX.Element | null => {
  const filteredPlan = props.supportPlan.filter((plan) => {
    return !plan.archive;
  });
  const latestPlan =
    filteredPlan.length > 0 &&
    filteredPlan.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
  if (!latestPlan) {
    return null;
  }

  // 作成者名
  const { author } = latestPlan;
  const authorValue = getSnapOrRealName(author, "-");

  /** 長期目標が存在するか */
  const longTermExists = latestPlan.support_plan_goal.some(
    (v) => v.sprint_type === SprintType.LONG
  );

  /** 短期目標のレコード数 */
  const shortTermsLength = latestPlan.support_plan_goal.filter(
    (v) => v.sprint_type === SprintType.SHORT
  ).length;

  // 短期目標の数(GHなら6、その他は3)
  const shortTermsNum = props.shortTermsNum || 3;

  if (!longTermExists) {
    latestPlan.support_plan_goal.push({
      id: 0,
      sprint_type: 1,
      number: 1,
      sprint_goal: "",
      adopt_info: null,
      support_info: null,
      user_comment: null,
      improvement_plan: null,
      sprint_result: null,
      sprint_review: null,
      sprint_user_evaluation: null,
      sprint_user_review: null,
      sprint_staff_evaluation: null,
      sprint_staff_review: null
    });
  }
  if (shortTermsLength < shortTermsNum) {
    const supportPlanNums = latestPlan.support_plan_goal
      .filter((v) => v.sprint_type === SprintType.SHORT)
      .map((v) => v.number);
    Array.from(new Array(shortTermsNum))
      .map((v, i) => i + 1)
      .filter((num) => supportPlanNums.indexOf(num) === -1)
      .forEach((emptyNum) => {
        latestPlan.support_plan_goal.push({
          id: 0,
          sprint_type: 2,
          number: emptyNum,
          sprint_goal: "",
          adopt_info: null,
          support_info: null,
          user_comment: null,
          improvement_plan: null,
          sprint_result: null,
          sprint_review: null,
          sprint_user_evaluation: null,
          sprint_user_review: null,
          sprint_staff_evaluation: null,
          sprint_staff_review: null
        });
      });
  }
  /** 長期目標のインデックス */
  const longTermIndex = latestPlan.support_plan_goal.findIndex(
    (v) => v.sprint_type === SprintType.LONG
  );

  /** 短期目標のインデックスリスト */
  const shortTermIndexes = latestPlan.support_plan_goal
    .map((supportPlan, index) => ({
      ...supportPlan,
      baseIndex: index
    }))
    .filter((v) => v.sprint_type === SprintType.SHORT)
    .sort((a, b) => a.number - b.number)
    .map((supportPlan) => supportPlan.baseIndex);

  return (
    <Dialog open={props.isOpen} disableBackdropClick maxWidth={false}>
      <DialogTitle className={props.classes.dialogTitle}>
        <div className={props.classes.titleWrap}>
          <div>
            <span>{props.userName}</span>
            <span className={props.classes.space}>計画目標</span>
          </div>
          <KnowbeButton kind="outline" minWidth={120} onClick={props.onClose}>
            閉じる
          </KnowbeButton>
        </div>
      </DialogTitle>
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.section}>
          <span>支援期間</span>
          <span className={props.classes.space}>
            {latestPlan.support_begin_date
              ? dateToLocalisedString(
                  latestPlan.support_begin_date,
                  "YYYY年M月D日"
                )
              : null}
          </span>
          <span className={props.classes.space}>〜</span>
          <span className={props.classes.space}>
            {latestPlan.support_end_date
              ? dateToLocalisedString(
                  latestPlan.support_end_date,
                  "YYYY年M月D日"
                )
              : null}
          </span>
          <div className={props.classes.createData}>
            <div>
              <span>計画作成日：</span>
              <span>
                {latestPlan.creation_date
                  ? dateToLocalisedString(
                      latestPlan.creation_date,
                      "YYYY年 MM月 DD日"
                    )
                  : "-"}
              </span>
            </div>
            <div>
              <span>計画作成者：</span>
              <span>{authorValue}</span>
            </div>
          </div>
        </div>
        <div className={props.classes.textField}>
          <ReadonlyTextField
            label="本人・家族の意向"
            value={latestPlan.user_request_text || ""}
            defaultValue=""
            multiline
          />
          <ReadonlyTextField
            label="本人の現状"
            value={latestPlan.current_status || ""}
            defaultValue=""
            multiline
          />
        </div>

        <GrayMiddleHeading text="長期目標" />
        <div className={props.classes.textField}>
          <ReadonlyTextField
            value={
              latestPlan.support_plan_goal[longTermIndex].sprint_goal || ""
            }
            defaultValue=""
            multiline
          />
        </div>
        {shortTermIndexes.map((index) => (
          <div key={`index_${index}`}>
            <GrayMiddleHeading
              text={`短期目標 ${latestPlan.support_plan_goal[index].number}`}
            />
            <div className={props.classes.textField}>
              <ReadonlyTextField
                label="目標"
                value={latestPlan.support_plan_goal[index].sprint_goal || ""}
                defaultValue=""
                multiline
              />
              <ReadonlyTextField
                label="本人の取組内容"
                value={latestPlan.support_plan_goal[index].adopt_info || ""}
                defaultValue=""
                multiline
              />
              <ReadonlyTextField
                label="職員の支援内容"
                value={latestPlan.support_plan_goal[index].support_info || ""}
                defaultValue=""
                multiline
              />
            </div>
          </div>
        ))}
        <GrayMiddleHeading text="その他" />
        <div className={props.classes.textField}>
          <ReadonlyTextField
            label="備考欄"
            value={latestPlan.remarks || ""}
            defaultValue=""
            multiline
          />
          <ReadonlyTextField
            label="職員コメント"
            value={latestPlan.staff_comment || ""}
            defaultValue=""
            multiline
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const PlannedTargetDialogHasMonitoring = withStyles(styles)(
  PlannedTargetDialogHasMonitoringCore
);
