import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between"
    },
    label: {
      margin: 0,
      fontSize: 12,
      lineHeight: 20 / 12,
      letterSpacing: 0.4,
      color: "#37474f"
    },
    currentPassword: {
      margin: "8px 0 0",
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0.5,
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 600
    }
  });

type OwnProps = {
  onClick: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CurrentPasswordCore = ({ onClick, classes }: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <div>
      <p className={classes.label}>
        現在のパスワード（セキュリティ保護のため表示していません）
      </p>
      <p className={classes.currentPassword}>･･････････</p>
    </div>
    <KnowbeButton minWidth={160} onClick={onClick}>
      変更する
    </KnowbeButton>
  </div>
);

export const CurrentPassword = withStyles(styles)(CurrentPasswordCore);
