import { AxiosRequestConfig, AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 利用者負担上限額管理結果票のCSVを取得
 * @param year
 * @param month
 * @param user_ids
 * @param modify_user_ids
 * @param cancel_user_ids
 */
export const getInvoicesUpLimitCsv = async (
  year: string,
  month: string,
  user_ids: string,
  modify_user_ids: number[],
  cancel_user_ids: number[],
  type_consultation?: 1 | 2
): Promise<AxiosResponse<string>> => {
  const url = `${VERSION_URL_201910}/invoices/uplimit/${year}/${month}?type=csv&excluded_user_ids=${user_ids}`;
  let query = "";
  if (type_consultation) {
    query += `&type_consultation=${type_consultation}`;
  }
  if (modify_user_ids.length !== 0) {
    query += `&modify_user_ids=${modify_user_ids.join(",")}`;
  }
  if (cancel_user_ids.length !== 0) {
    query += `&cancel_user_ids=${cancel_user_ids.join(",")}`;
  }

  const requestParams: AxiosRequestConfig = {
    responseType: "blob"
  };
  return request.get<string>(`${url}${query}`, requestParams);
};

export default getInvoicesUpLimitCsv;
