import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import { UnitsFields } from "@componentsMobile/organisms/record/serviceDelivery/units/UnitsField";
import {
  DAY_SELECT_OPTIONS,
  DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER,
  FacilityType
} from "@constants/variables";
import React from "react";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import { FormikProps } from "formik";
import { useServiceDeliveryTimeContainer } from "@hooks/record/serviceDelivery/useServiceDeliveryTimeContainer";
import {
  IDOSHIEN_STATUS_LIST,
  MAX_PRACTITIONER_DETAILS
} from "@constants/mgr/IDOSHIEN/variables";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: 24
    },
    flex: {
      display: "flex",
      justifyContent: "space-between"
    }
  });

type StateProps = {
  municipality: MunicipalitiesInFacilityState;
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchMunicipality: (id: string) => void;
  fetchUserInFacility: (uifId: string) => void;
};

type OwnProps = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  facilityType: FacilityType;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type MergeProps = OwnProps & DispatchProps & StateProps;

type Props = MergeProps & WithStyles<typeof styles>;

const ServiceDeliveryOnlyTimeContainerCore = ({
  classes,
  isEdit,
  formikProps,
  practitionerValues,
  facilityType,
  fetchMunicipality,
  fetchUserInFacility,
  municipality,
  userInFacility
}: Props): JSX.Element => {
  const { values: formikValues } = formikProps;
  const fieldName = "serviceDeliveryRecordPractitioners1";
  const detailsFieldName =
    "serviceDeliveryRecordPractitioners1.serviceDeliveryRecordPractitionerDetails";
  const {
    onChangeDateSelect,
    onChangeInitialTime,
    onChangeActionClass,
    onChangeDetailDateSelect,
    onChangeDetailRecord,
    onDeleteRecord
  } = useServiceDeliveryTimeContainer(
    fieldName,
    detailsFieldName,
    formikProps.setFieldValue,
    facilityType,
    practitionerValues,
    formikProps,
    fetchMunicipality,
    fetchUserInFacility,
    municipality,
    userInFacility
  );
  const startTimeValue = DAY_SELECT_OPTIONS.find(
    (option) => option.value === practitionerValues.startTimeDate
  );

  const isPractitionerDetailsLessThan10 =
    practitionerValues.serviceDeliveryRecordPractitionerDetails.filter(
      (item) => !item.isDelete
    ).length < MAX_PRACTITIONER_DETAILS;

  return (
    <div className={classes.wrapper}>
      <div className={classes.flex}>
        <ReadonlyTextField
          label="開始時間"
          value={startTimeValue ? startTimeValue.label : "当日"}
          size="mobileHalf"
          defaultValue="当日"
        />
        <FormikTime
          isMobile
          readOnly={!isEdit}
          name="serviceDeliveryRecordPractitioners1.startTime"
          label=""
          placeholder="00:00"
          size="mobileHalf"
          maxLength={5}
          onChangeHookTime={onChangeInitialTime}
          style={{ marginLeft: 16 }}
        />
      </div>
      <div className={classes.flex}>
        <FormikSelect
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.endTimeDate`}
          label="終了時間"
          options={DAY_SELECT_OPTIONS}
          size="mobileHalf"
          noerrortext
          onChangeHook={(e): void => onChangeDateSelect(e)}
        />
        <FormikTime
          isMobile
          readOnly={!isEdit}
          name="serviceDeliveryRecordPractitioners1.endTime"
          placeholder="00:00"
          size="mobileHalf"
          maxLength={5}
          onChangeHookTime={onChangeInitialTime}
          style={{ marginLeft: 16 }}
        />
      </div>
      {formikValues.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ? (
        <div className={classes.flex}>
          <ReadonlyTextField
            label="時間"
            value={practitionerValues.numberOfServiceHours || "-"}
            defaultValue=""
            endAdornment="時間"
            size="mobileHalf"
          />
          <ReadonlyTextField
            value={practitionerValues.numberOfServiceMinutes || "-"}
            defaultValue=""
            endAdornment="分"
            size="mobileHalf"
          />
        </div>
      ) : (
        <ReadonlyTextField
          label="時間"
          value={practitionerValues.numberOfTime || "-"}
          defaultValue=""
          endAdornment="時間"
          size="mobileHalf"
        />
      )}
      <div>
        <UnitsFields
          isEdit={isEdit}
          formikProps={formikProps}
          unitKey="serviceDeliveryRecordPractitioners1"
          onDeleteRecord={onDeleteRecord}
          onChangeTime={onChangeDetailRecord}
          onChangeSelect={onChangeDetailDateSelect}
          onChangeActionClass={onChangeActionClass}
          setFormikFieldValue={formikProps.setFieldValue}
          isAddButton={isPractitionerDetailsLessThan10}
          list={practitionerValues.serviceDeliveryRecordPractitionerDetails}
          practitionerNum={practitionerValues.practitionerNum}
          practitionerValues={practitionerValues}
          actionClassLabel={DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER[0].label}
          actionClassOptions={DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER}
          facilityType={facilityType}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  municipality: state.IDOSHIEN.municipalitiesInFacility,
  userInFacility: state.IDOSHIEN.userInFacility
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN } = dispatches;
  const municipalitiesInFacilityDispatches = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatches = IDOSHIEN.userInFacilityDispatcher(dispatch);
  return {
    fetchMunicipality: municipalitiesInFacilityDispatches.fetchOne,
    fetchUserInFacility: userInFacilityDispatches.fetchOne
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryOnlyTimeContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryOnlyTimeContainerCore));
