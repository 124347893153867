import { InitialState } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/initial/types";
import { dateToSelectDateValue } from "@utils/date";
import { InitialDataValues } from "@interfaces/mgr/JIRITSUKUNRENSEIKATSU/initial/initialData";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

const initialValues = (state?: InitialState): InitialDataValues => {
  const facility = state && state.facility ? state.facility : {};
  const users = state && state.users ? state.users : [{}];
  const initializedUsers = users.map((user) => {
    const uifJIRITSUKUNRENSEIKATSU = user.users_in_facility_jiritsukunren_seikatsu
      ? user.users_in_facility_jiritsukunren_seikatsu
      : {};
    return {
      users_in_facility_jiritsukunren_seikatsu: {
        social_life_support_start_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              uifJIRITSUKUNRENSEIKATSU.social_life_support_start_date,
              ""
            )
          )
        ),
        visit_start_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              uifJIRITSUKUNRENSEIKATSU.visit_start_date,
              ""
            )
          )
        )
      }
    };
  });

  return {
    initialData: {
      facility: {
        first_time_bill_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(facility.first_time_bill_date, "")
          )
        ),
        total_number_of_users_1_month_before: undefinedToReturnValue(
          facility.total_number_of_users_1_month_before,
          ""
        ),
        total_number_of_users_2_month_before: undefinedToReturnValue(
          facility.total_number_of_users_2_month_before,
          ""
        ),
        total_number_of_users_3_month_before: undefinedToReturnValue(
          facility.total_number_of_users_3_month_before,
          ""
        )
      },
      users: initializedUsers
    }
  };
};

export default initialValues;
