import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/inOutResults/postInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/inOutResults/postInOutResultsCopy";
import { RequestParamToPostCopyCarePlanAPI } from "@api/requests/inOutResults/postInOutResultsCopyCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/inOutResults/deleteInOutResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchJUDOHOMONKAIGODailyStarted = () =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_DAILY_STARTED } as const);
export const fetchJUDOHOMONKAIGODaily = (
  res: types.ReportState["reportDaily"]
) => ({ type: types.FETCH_JUDOHOMONKAIGO_DAILY, payload: res } as const);
export const fetchJUDOHOMONKAIGODailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_DAILY_FAILED, error: err } as const);
export const fetchJUDOHOMONKAIGOUserStarted = () =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_MONTHLY_STARTED } as const);
export const fetchJUDOHOMONKAIGOUser = (
  res: types.ReportState["reportMonthly"]
) => ({ type: types.FETCH_JUDOHOMONKAIGO_MONTHLY, payload: res } as const);
export const fetchJUDOHOMONKAIGOUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_JUDOHOMONKAIGO_MONTHLY_FAILED, error: err } as const);

export const postJUDOHOMONKAIGOReportStarted = () =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_STARTED } as const);
export const postJUDOHOMONKAIGOReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_DAILY, payload: req } as const);
export const postJUDOHOMONKAIGOReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_MONTHLY, payload: req } as const);
export const postJUDOHOMONKAIGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_FAILED, error: err } as const);

export const postJUDOHOMONKAIGOReportCopyStarted = () =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_COPY_STARTED } as const);
export const postJUDOHOMONKAIGOReportCopy = (req: RequestParamToPostCopyAPI) =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_COPY, payload: req } as const);
export const postJUDOHOMONKAIGOReportCopyFailed = (err: ErrorResponse) =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_COPY_FAILED, error: err } as const);

export const postJUDOHOMONKAIGOReportCopyCarePlanStarted = () =>
  ({ type: types.POST_JUDOHOMONKAIGO_REPORT_COPY_CARE_PLAN_STARTED } as const);
export const postJUDOHOMONKAIGOReportCopyCarePlan = (
  req: RequestParamToPostCopyCarePlanAPI
) =>
  ({
    type: types.POST_JUDOHOMONKAIGO_REPORT_COPY_CARE_PLAN,
    payload: req
  } as const);
export const postJUDOHOMONKAIGOReportCopyCarePlanFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_JUDOHOMONKAIGO_REPORT_COPY_CARE_PLAN_FAILED,
    error: err
  } as const);

export const deleteJUDOHOMONKAIGOReportStarted = () =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_REPORT_STARTED } as const);
export const deleteJUDOHOMONKAIGOReport = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_REPORT, payload: req } as const);
export const deleteJUDOHOMONKAIGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_JUDOHOMONKAIGO_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchJUDOHOMONKAIGODailyStarted>
  | ReturnType<typeof fetchJUDOHOMONKAIGODaily>
  | ReturnType<typeof fetchJUDOHOMONKAIGODailyFailed>
  | ReturnType<typeof fetchJUDOHOMONKAIGOUserStarted>
  | ReturnType<typeof fetchJUDOHOMONKAIGOUser>
  | ReturnType<typeof fetchJUDOHOMONKAIGOUserFailed>
  | ReturnType<typeof postJUDOHOMONKAIGOReportStarted>
  | ReturnType<typeof postJUDOHOMONKAIGOReportDaily>
  | ReturnType<typeof postJUDOHOMONKAIGOReportUser>
  | ReturnType<typeof postJUDOHOMONKAIGOReportFailed>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopyStarted>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopy>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopyFailed>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopyCarePlanStarted>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopyCarePlan>
  | ReturnType<typeof postJUDOHOMONKAIGOReportCopyCarePlanFailed>
  | ReturnType<typeof deleteJUDOHOMONKAIGOReportStarted>
  | ReturnType<typeof deleteJUDOHOMONKAIGOReport>
  | ReturnType<typeof deleteJUDOHOMONKAIGOReportFailed>;
