import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { SupportReportState } from "@stores/domain/supportReport/types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import ClassNames from "classnames";
import { SUPPORT_METHOD_ITEMS } from "@constants/mgr/SHUROTEICHAKU/variables";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

// utils
import { dateToLocalisedString } from "@/utils/date";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20
    },
    headerInfo: {
      width: 430
    },
    title: {
      margin: 0,
      marginTop: 14,
      fontSize: 18,
      fontWeight: "normal",
      letterSpacing: 1.2,
      color: "#212121"
    },
    userBasicInfo: {
      marginTop: 28
    },
    footer: {
      marginTop: 24
    },
    basicInfo: {
      letterSpacing: "0.1em",
      fontSize: 0,
      "&:last-child": {
        marginTop: 4
      }
    },
    basicInfoText: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      "&:first-child": {
        marginRight: 20
      },
      "&:nth-child(2)": {
        marginRight: 24
      }
    },
    tableContainer: {
      fontSize: 10,
      marginBottom: 20,
      whiteSpace: "pre-line"
    },
    tableTitle: {
      fontWeight: "bold",
      borderBottom: "1px solid #000",
      paddingBottom: 5,
      marginBottom: 8,
      display: "flex",
      color: "rgba 0 0 0, 0.87",
      justifyContent: "space-between"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      tableLayout: "fixed",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      color: "#212121",
      "&.fullWidth": {
        width: "100%"
      },
      "& th": {
        fontWeight: "normal",
        borderRight: "1px solid",
        padding: "3px 8px",
        verticalAlign: "top"
      },
      "& td": {
        padding: "4px 12px",
        height: 24,

        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    primaryTableHead: {
      width: 120
    },
    headerDate: {
      marginTop: 19
    },
    headerDateText: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      textAlign: "right"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * type
 */

type StateProps = {
  supportReport: SupportReportState;
  userInFacility: UsersInFacilityState["user"];
  userState: UserState;
};
type OwnProps = {
  uifId: string;
  year: string;
  month: string;
};

type DispatchProps = {
  fetchSupportReport: (uifId: string, year: string, month: string) => void;
  fetchOne: (id: string) => void;
};
type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: UsersInFacilityState["user"];
  support: SupportReportState["support"];
  year: string;
  month: string;
  user: UserState;
};
type SectionTableOwnProps = {
  sectionTitle?: string;
  sectionData: {
    itemTitle?: string;
    content: string | null;
    borderBottomBold?: boolean;
  }[];
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type SectionTableProps = SectionTableOwnProps & WithStyles<typeof styles>;

const SupportReportPrintCore = (props: Props): JSX.Element => {
  React.useEffect(() => {
    const { uifId, year, month } = props;
    props.fetchOne(uifId);
    props.fetchSupportReport(uifId, year, month);
  }, []);

  const { classes, userInFacility, supportReport } = props;

  return (
    <>
      <Sheet
        classes={classes}
        userInFacility={userInFacility}
        support={supportReport.support}
        year={props.year}
        month={props.month}
        user={props.userState}
      />
    </>
  );
};

const SectionTable = (props: SectionTableProps): JSX.Element => {
  const { classes, sectionTitle, sectionData } = props;

  return (
    <section>
      {sectionTitle && (
        <div className={classes.tableTitle}>
          <span>{sectionTitle}</span>
        </div>
      )}

      <table className={`${classes.table} fullWidth`}>
        <tbody>
          {sectionData.map((data, index) => {
            const keyNumber = index;
            return (
              <tr
                key={keyNumber}
                className={data.borderBottomBold ? "borderBold" : ""}
              >
                {sectionTitle !== "共有事項" && (
                  <th className={ClassNames(classes.primaryTableHead)}>
                    {data.itemTitle}
                  </th>
                )}
                <td>{data.content}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, userInFacility, support, year, month, user } = props;

  // 担当者名
  const author = support.report.author ? support.report.author : 0;
  const authorValue = getSnapOrRealName(author, "-");

  // 雇用事業主
  const companyName = support.report.company_name
    ? support.report.company_name
    : "-";

  // ご担当者
  const companyPerson = support.report.responsible_person
    ? support.report.responsible_person
    : "-";

  const usageResultsDisplayOn = support.usage_results.filter(
    (result) => result.display_form_flg === 1
  );

  // 支援実績
  let supportData = usageResultsDisplayOn.map((result) => {
    const supportDateTime = `${dateToLocalisedString(
      result.target_date,
      "M月D日"
    )}`;
    const supportMethod =
      result.support_method === 6
        ? result.support_method_other
        : SUPPORT_METHOD_ITEMS[result.support_method].label.replace(
            /\r?\n/g,
            ""
          );

    return {
      itemTitle: supportDateTime,
      content: result.support_method === 0 ? "" : supportMethod
    };
  });

  if (supportData.length === 0) {
    supportData = [
      {
        itemTitle: "",
        content: ""
      }
    ];
  }

  // 当月の主な支援目標
  const goalData = [
    {
      itemTitle: "①",
      content: support.report.goal_1
    },
    {
      itemTitle: "②",
      content: support.report.goal_2
    },
    {
      itemTitle: "③",
      content: support.report.goal_3
    }
  ];

  // 当月の支援状況
  const situationsData = ((): {
    itemTitle: string;
    content: string | null;
    borderBottomBold?: boolean | undefined;
  }[] => {
    let array = [] as {
      itemTitle: string;
      content: string | null;
      borderBottomBold?: boolean;
    }[];
    [1, 2, 3].forEach((item) => {
      const reportSituation = support.report.situations.find(
        (situation) => situation.number === item
      );
      const data = [
        {
          itemTitle: `支援実施内容 ${circleNumbersList[item - 1]}`,
          content: reportSituation ? reportSituation.content : ""
        },
        {
          itemTitle: "支援結果",
          content: reportSituation ? reportSituation.result : "",
          borderBottomBold: true
        }
      ];
      array = array.concat(data);
    });
    return array;
  })();

  // 今後の方向性
  const nextSupportData = [
    {
      itemTitle: "今後の支援内容",
      content: support.report.future_support_content,
      borderBottomBold: true
    },
    {
      itemTitle: "対象者の取組",
      content: support.report.person_behavior
    },
    {
      itemTitle: "事業主の取組",
      content: support.report.facility_behavior
    },
    {
      itemTitle: "関係機関等の取組",
      content: support.report.related_organizations_behavior
    }
  ];

  // 共有事項
  const sharedContentData = [
    {
      content: support.report.shared_content
    }
  ];

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <h1 className={classes.title}>就労定着支援 支援レポート</h1>
          <div className={classes.userBasicInfo}>
            <div className={classes.basicInfo}>
              <span className={classes.basicInfoText}>
                {`利用者名: ${userInFacility.user_in_facility.name_sei} ${userInFacility.user_in_facility.name_mei}`}
              </span>
            </div>
            <div className={classes.basicInfo}>
              <span className={classes.basicInfoText}>
                {`雇用事業主（勤務先） : ${companyName}`}
              </span>
              <span
                className={classes.basicInfoText}
              >{`ご担当者 : ${companyPerson}`}</span>
            </div>
          </div>
        </div>
        <div className={classes.headerDate}>
          <div className={classes.headerDateText}>
            {`作成日 : ${
              support.report.creation_date
                ? dateToLocalisedString(
                    support.report.creation_date,
                    "YYYY年M月D日"
                  )
                : "-"
            }`}
          </div>
          <div className={classes.headerDateText}>
            {`雇用開始日 : ${
              support.report.working_start_date
                ? dateToLocalisedString(
                    support.report.working_start_date,
                    "YYYY年M月D日"
                  )
                : "-"
            }`}
          </div>
        </div>
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle={`${year}年${month}月の支援実績`}
          sectionData={supportData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="当月の主な支援目標"
          sectionData={goalData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="当月の支援状況"
          sectionData={situationsData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="今後の方向性"
          sectionData={nextSupportData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="共有事項"
          sectionData={sharedContentData}
        />
      </div>

      <div className={classes.footer}>
        <div className={classes.basicInfo}>
          <span className={classes.basicInfoText}>
            {`事業所名: ${user.facility_name}`}
          </span>
          <span className={classes.basicInfoText}>
            {`担当者 : ${authorValue}`}
          </span>
        </div>
        <div className={classes.basicInfo}>
          <span className={classes.basicInfoText}>{`対象者提示日 : ${
            support.report.presentation_date
              ? dateToLocalisedString(
                  support.report.presentation_date,
                  "YYYY年M月D日"
                )
              : "-"
          }`}</span>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => ({
  supportReport: state.supportReport,
  userInFacility: state.SHUROTEICHAKU.userInFacility.user,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHUROTEICHAKU, supportReportDispatcher } = dispatches;
  const userInFacilityDispatcher = SHUROTEICHAKU.userInFacilityDispatcher(
    dispatch
  );
  const supportReportDispatches = supportReportDispatcher(dispatch);

  return {
    fetchSupportReport: (uifId: string, year: string, month: string): void => {
      supportReportDispatches.fetchSupportReport(uifId, year, month);
    },
    fetchOne: userInFacilityDispatcher.fetchOne
  };
};

export const SupportReportPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SupportReportPrintCore));
