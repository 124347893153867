import castNumber from "@utils/dataNormalizer/castNumber";

/**
 * 算定時間の計算
 * @param base
 * @param standardValue
 * @param other
 * @param threshold
 * @param minValue
 * @param judgeFlg
 * @param getIdoshienTotalTime
 * 移動支援でのみ使用する。自治体情報の算定時間切り上げ値（分）を利用する。
 * @returns
 */
export const calculateHours = (
  base: number,
  standardValue = 0.25,
  other = 0,
  threshold = 0,
  minValue = 0,
  judgeFlg = false,
  getIdoshienTotalTime?: (base: number, other: number) => number
): number => {
  const totalTime = getIdoshienTotalTime
    ? getIdoshienTotalTime(base, other)
    : base - other;
  if (judgeFlg && totalTime < minValue) return threshold;
  const result = totalTime / 60;

  if (result <= 0) return 0;
  // 最小単位以下の場合は最小単位を返却
  if (result <= threshold) return threshold;

  const integralNumber = castNumber(String(result).split(".")[0]);
  let decimalPart = result - integralNumber;
  if (standardValue === 0.25) {
    if (decimalPart <= 0) {
      decimalPart = 0.0;
    } else if (decimalPart > 0 && decimalPart <= 0.25) {
      decimalPart = 0.25;
    } else if (decimalPart > 0.25 && decimalPart <= 0.5) {
      decimalPart = 0.5;
    } else if (decimalPart > 0.5 && decimalPart <= 0.75) {
      decimalPart = 0.75;
    } else if (decimalPart > 0.75) {
      decimalPart = 1.0;
    }
  } else if (decimalPart <= 0) {
    decimalPart = 0.0;
  } else if (decimalPart > 0 && decimalPart <= 0.5) {
    decimalPart = 0.5;
  } else if (decimalPart > 0.5) {
    decimalPart = 1.0;
  }

  return integralNumber + decimalPart;
};
