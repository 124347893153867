import * as action from "@stores/ui/download/action";
import { Dispatch } from "redux";
import { DownloadFileType, UplimitCreateType } from "./type";

const targetYearMonth = (dispatch: Dispatch) => (yearMonth: string): void => {
  dispatch(action.targetYearMonth(yearMonth));
};

const isDisableDownloadButton = (dispatch: Dispatch) => (
  isDisabled: boolean
): void => {
  dispatch(action.isDisableDownloadButton(isDisabled));
};

const isDisableExcludedUsersButton = (dispatch: Dispatch) => (
  isDisabled: boolean
): void => {
  dispatch(action.isDisableExcludedUsersButton(isDisabled));
};

const downloadReadyFileType = (dispatch: Dispatch) => (
  fileType: DownloadFileType
): void => {
  dispatch(action.downloadReadyFileType(fileType));
};

const isOpenUserModal = (dispatch: Dispatch) => (open: boolean): void => {
  dispatch(action.isOpenUserModal(open));
};

const excludedUserIds = (dispatch: Dispatch) => (userIds: number[]): void => {
  dispatch(action.excludedUserIds(userIds));
};

const tmpExcludedUserIds = (dispatch: Dispatch) => (
  userIds: number[]
): void => {
  dispatch(action.tmpExcludedUserIds(userIds));
};

// 請求対象除外ユーザーを初期化する
const clearExcludedUserIds = (dispatch: Dispatch) => (): void => {
  dispatch(action.clearExcludedUserIds());
};

const downloadReadyTypeConsultation = (dispatch: Dispatch) => (
  typeConsultation?: 1 | 2
): void => {
  dispatch(action.downloadReadyTypeConsultation(typeConsultation));
};

const isOpenUplimitCreateTypeSelectModal = (dispatch: Dispatch) => (
  open: boolean
): void => {
  dispatch(action.isOpenUplimitCreateTypeSelectModal(open));
};

const updateUserIdToUplimitCreateType = (dispatch: Dispatch) => (
  userIdToUplimitCreateType: Map<number, UplimitCreateType>
): void => {
  dispatch(action.userIdToUplimitCreateType(userIdToUplimitCreateType));
};

type Dispatcher = {
  targetYearMonth: (yearMonth: string) => void;
  isDisableDownloadButton: (isDisabled: boolean) => void;
  isDisableExcludedUsersButton: (isDisabled: boolean) => void;
  downloadReadyFileType: (fileType: DownloadFileType) => void;
  isOpenUserModal: (open: boolean) => void;
  excludedUserIds: (userIds: number[]) => void;
  tmpExcludedUserIds: (userIds: number[]) => void;
  clearExcludedUserIds: () => void;
  downloadReadyTypeConsultation: (typeConsultation?: 1 | 2) => void;
  isOpenUplimitCreateTypeSelectModal: (open: boolean) => void;
  updateUserIdToUplimitCreateType: (
    userIdToUplimitType: Map<number, UplimitCreateType>
  ) => void;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    targetYearMonth: targetYearMonth(dispatch),
    isDisableDownloadButton: isDisableDownloadButton(dispatch),
    isDisableExcludedUsersButton: isDisableExcludedUsersButton(dispatch),
    downloadReadyFileType: downloadReadyFileType(dispatch),
    isOpenUserModal: isOpenUserModal(dispatch),
    excludedUserIds: excludedUserIds(dispatch),
    tmpExcludedUserIds: tmpExcludedUserIds(dispatch),
    clearExcludedUserIds: clearExcludedUserIds(dispatch),
    downloadReadyTypeConsultation: downloadReadyTypeConsultation(dispatch),
    isOpenUplimitCreateTypeSelectModal: isOpenUplimitCreateTypeSelectModal(
      dispatch
    ),
    updateUserIdToUplimitCreateType: updateUserIdToUplimitCreateType(dispatch)
  };
}
