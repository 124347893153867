import { RecordSupportPlanValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { dateValidator, validateSwitcher, Rule } from "@validator";
import { DISABILITY_CERTIFICATE_REHABILITATION } from "@constants/mgr/SHUROTEICHAKU/variables";

const validation = (
  values: RecordSupportPlanValues
): ValidationErrors<RecordSupportPlanValues> => {
  const lengthRule = (length: number): Rule => ({
    type: "checkCharacterLength",
    length
  });
  return {
    creation_date: dateValidator(values.creation_date, "required"),
    support_begin_date: dateValidator(values.support_begin_date, "required"),
    support_end_date: dateValidator(values.support_end_date, "required", {
      type: "future",
      startDate: values.support_begin_date
    }),
    authorizer: validateSwitcher(
      values.status_type === "1",
      validator(values.authorizer, "required")
    ),
    remarks: validator(values.remarks, lengthRule(1000)),
    staff_comment: validator(values.staff_comment, lengthRule(1000)),
    minutes: validator(values.minutes, lengthRule(1000)),
    support_plan_shuroteichaku: {
      company_name: validator(
        values.support_plan_shuroteichaku.company_name,
        lengthRule(255)
      ),
      company_industry: validator(
        values.support_plan_shuroteichaku.company_industry,
        lengthRule(255)
      ),
      company_address: validator(
        values.support_plan_shuroteichaku.company_address,
        lengthRule(1000)
      ),
      company_tel: validator(
        values.support_plan_shuroteichaku.company_tel,
        lengthRule(255)
      ),
      responsible_person: validator(
        values.support_plan_shuroteichaku.responsible_person,
        lengthRule(255)
      ),
      equipment_environment: validator(
        values.support_plan_shuroteichaku.equipment_environment,
        lengthRule(1000)
      ),
      human_environment: validator(
        values.support_plan_shuroteichaku.human_environment,
        lengthRule(1000)
      ),
      wage: validator(values.support_plan_shuroteichaku.wage, lengthRule(1000)),
      working_time: validator(
        values.support_plan_shuroteichaku.working_time,
        lengthRule(1000)
      ),
      rest_time: validator(
        values.support_plan_shuroteichaku.rest_time,
        lengthRule(1000)
      ),
      holiday: validator(
        values.support_plan_shuroteichaku.holiday,
        lengthRule(1000)
      ),
      business_description: validator(
        values.support_plan_shuroteichaku.business_description,
        lengthRule(1000)
      ),
      takeover: validator(
        values.support_plan_shuroteichaku.takeover,
        lengthRule(1000)
      ),
      support_group: validator(
        values.support_plan_shuroteichaku.support_group,
        lengthRule(1000)
      ),
      physical_condition: validator(
        values.support_plan_shuroteichaku.physical_condition,
        lengthRule(1000)
      ),
      support_organization: validator(
        values.support_plan_shuroteichaku.support_organization,
        lengthRule(1000)
      ),
      disability_certificate_rehabilitation_other: validateSwitcher(
        values.support_plan_shuroteichaku
          .disability_certificate_rehabilitation ===
          DISABILITY_CERTIFICATE_REHABILITATION[1].value,
        validator(
          values.support_plan_shuroteichaku
            .disability_certificate_rehabilitation_other,
          "required",
          lengthRule(10)
        )
      )
    },
    support_plan_goal: values.support_plan_goal.map((g) => ({
      sprint_goal: validator(g.sprint_goal, lengthRule(1000)),
      adopt_info: validator(g.adopt_info, lengthRule(1000)),
      support_info: validator(g.support_info, lengthRule(1000))
    }))
  };
};

export default validation;
