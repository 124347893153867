import React from "react";
import * as H from "history";
import * as URL from "@constants/url";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { SupportProcedureModalTable } from "./SupportProcedureModalTable";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiSelect from "@components/molecules/MuiSelect";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import ConfirmDialog from "@components/atoms/ConfirmDialog";

import {
  KODOENGO_PLAN_LIST_TAB_PATH,
  SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE,
  TARGET_PATH
} from "@constants/mgr/KODOENGO/variables";
import { AppState } from "@stores/type";
import {
  SelectedSupportProcedureIds,
  SupportProcedureState
} from "@stores/domain/mgr/KODOENGO/supportProcedure/types";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { orderBy } from "lodash-es";
import {
  dateInHyphenYYYYMMDDFormat,
  dateToSelectDateValue,
  getLastDay,
  getWarekiList
} from "@utils/date";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { isDateWithin } from "@utils/date/isDateWithin";
import {
  TargetObject,
  TargetObjectInitialValue,
  useLocalStorage
} from "@hooks/record/supportProcedure/useLocalStorage";

export type CheckedIds = { id: string; statusFlg: string };

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    modal: {
      "&>div:nth-child(2)>div": {
        maxWidth: "820px",
        width: "100%"
      }
    },
    title: {
      padding: "16px 32px",
      background: "#f5f5f5",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    content: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 24
    },
    actions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    tableRowBase: {
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    radioButtonWrapper: {
      paddingTop: 24,
      paddingLeft: 16
    },
    targetDateWrapper: {
      display: "flex",
      paddingTop: 24,
      paddingLeft: 32
    },
    checkboxWrapper: {
      paddingTop: 24,
      paddingLeft: 32
    },
    borderBottom: {
      borderBottom: "1px solid #cfd8dc"
    },
    noRecord: {
      fontSize: "16px",
      paddingTop: "80px",
      paddingBottom: "49px",
      textAlign: "center",
      lineHeight: "28px"
    }
  });

type OwnProps = {
  uifId: string;
  title: string;
  isOpen: boolean;
  radioBtnOptions: RadioItemInterface[];
  dateBeginInService: string;
  dateEndInService: string | undefined;
  history: H.History;
  target: keyof typeof TARGET_PATH;
  onClose: () => void;
  selectedIds: SelectedSupportProcedureIds;
  originSupportProcedureId?: string;
} & WithStyles<typeof styles>;

type StateProps = {
  supportProcedureList: SupportProcedureState["supportProcedureList"];
};

type DispatchProps = {
  fetchModalSupportProcedureList: (uifId: string, date: string) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const SupportProcedureCreateModalContentCore = (props: Props): JSX.Element => {
  const {
    classes,
    uifId,
    title,
    isOpen,
    radioBtnOptions,
    dateBeginInService,
    dateEndInService,
    history,
    target,
    onClose,
    supportProcedureList,
    fetchModalSupportProcedureList,
    selectedIds,
    originSupportProcedureId
  } = props;

  const today = React.useMemo(() => {
    const date = dateInHyphenYYYYMMDDFormat(new Date());
    return dateToSelectDateValue(date);
  }, []);

  const [hasErrorDate, setHasErrorDate] = React.useState(false);
  const [hasErrorMonth, setHasErrorMonth] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<string>("1");
  const [checkedIds, setCheckedIds] = React.useState<CheckedIds[]>([]);
  const [targetDate, setTargetDate] = React.useState({ ...today });
  const [targetMonth, setTargetMonth] = React.useState<{
    year: string;
    month: string;
  }>({ year: today.year, month: today.month });
  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false);

  const monthErrorMessage = React.useMemo(() => {
    return hasErrorMonth ? SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE : "";
  }, [hasErrorMonth]);

  const dateErrorMessage = React.useMemo(() => {
    return hasErrorDate ? SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE : "";
  }, [hasErrorDate]);

  const supportProcedureRows = React.useMemo(() => {
    const sortedSupportProcedure = orderBy(
      supportProcedureList.support_procedure_forms,
      "target_date",
      "asc"
    );
    return target === TARGET_PATH.setDate
      ? sortedSupportProcedure.filter((v) => v.target_date !== null)
      : sortedSupportProcedure;
  }, [supportProcedureList, target]);

  const yearOptions = React.useMemo(() => {
    return getWarekiList(1989, Number(today.year) + 10).map(
      ({ label, value }) => ({
        label,
        value: `${value}`
      })
    );
  }, []);

  const monthOptions = React.useMemo(() => {
    return createOneToNumberOptions(12, "月", false);
  }, []);

  const dayOptions = React.useMemo(() => {
    const { year, month } = targetDate;
    const lastDay = getLastDay(Number(year), Number(month));
    return createOneToNumberOptions(lastDay, "日", false);
  }, [targetDate]);

  // 日付が設定されている手順書のIndexと手順書作成済みフラグ
  const baseIds = React.useMemo((): CheckedIds[] => {
    return supportProcedureRows.reduce(
      (acc, { target_date, procedure_status_flg }, index) => {
        return target_date !== null
          ? [
              ...acc,
              {
                id: `${index}`,
                statusFlg: `${procedure_status_flg}`
              } as CheckedIds
            ]
          : [...acc];
      },
      [] as CheckedIds[]
    );
  }, [supportProcedureRows]);

  const isCheckedAll = React.useMemo(() => {
    if (baseIds.length === 0) return false;
    const checkedIdsSet = new Set(checkedIds.map((v) => v.id));
    return baseIds.every((v) => checkedIdsSet.has(v.id));
  }, [baseIds, checkedIds]);

  const isNotSelected = React.useMemo(() => {
    return (
      selectedOption === radioBtnOptions[0].value && checkedIds.length === 0
    );
  }, [selectedOption, checkedIds]);

  const isNoRecord = React.useMemo(() => {
    return (
      selectedOption === radioBtnOptions[0].value &&
      supportProcedureRows.length === 0
    );
  }, [selectedOption, supportProcedureRows]);

  // 一覧のチェックボックス変更時処理
  const handleChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, value: statusFlg, checked } = e.target;
      if (checked) {
        setCheckedIds((prev) => [...prev, { id, statusFlg }]);
      } else {
        setCheckedIds((prev) => prev.filter((v) => v.id !== id));
      }
    },
    []
  );

  // チェックボックス「すべての日時を選択」の変更時処理
  const handleCheckAllChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // 日時が設定されていない手順書のチェックボックスには影響を与えないようにする
    const noDateCheckedIds = checkedIds.filter(
      (v) => !baseIds.some((ids) => ids.id === v.id)
    );
    if (e.target.checked) {
      setCheckedIds([...baseIds, ...noDateCheckedIds]);
    } else {
      setCheckedIds([...noDateCheckedIds]);
    }
  };

  // 対象年月日のバリデーション処理
  const handleOnBlurTargetDate = React.useCallback((): void => {
    const { year, month, day } = targetDate;
    const dateString = `${year}-${month.padStart(2, "0")}-${day}`;
    const isError = !isDateWithin(
      new Date(dateString),
      dateBeginInService,
      dateEndInService
    );
    setHasErrorDate(isError);
  }, [targetDate]);

  // 対象年月日の変更時処理
  React.useEffect(() => {
    const { year, month, day } = targetDate;
    const lastDay = getLastDay(Number(year), Number(month));
    // 選択している日付が更新後の月末よりも大きい場合、更新後の月末で上書き
    if (Number(day) > lastDay) {
      setTargetDate((prev) => ({ ...prev, day: `${lastDay}` }));
    }
  }, [targetDate]);

  // 対象年月のバリデーション処理
  const handleOnBlurTargeMonth = React.useCallback((): void => {
    const { year, month } = targetMonth;
    const dateString = `${year}-${month.padStart(2, "0")}-1`;
    const isError = !isDateWithin(
      new Date(dateString),
      dateBeginInService,
      dateEndInService
    );
    setHasErrorMonth(isError);
  }, [targetMonth]);

  // 対象年月の変更時処理
  React.useEffect(() => {
    // 一覧のチェックボックスを初期化
    setCheckedIds([]);
    // SelectBoxはYYYYMだがAPIはYYYYMMを受け取るため、月が2桁以下の場合には先頭に0を追加
    const date = `${targetMonth.year}${targetMonth.month.padStart(2, "0")}`;
    fetchModalSupportProcedureList(uifId, date);
  }, [targetMonth]);

  const createTargetDateQuery = (
    queryTargetDate: typeof targetDate
  ): string => {
    const selectedDate = Object.values(queryTargetDate)
      .map((v) => v.padStart(2, "0"))
      .join("-");
    return `&targetDate=${selectedDate}`;
  };

  const createIdsQuery = (queryIds: SelectedSupportProcedureIds): string => {
    const {
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    } = queryIds;

    let idsQuery = "";
    if (serviceDeliveryRecordsId) {
      idsQuery += `&serviceDeliveryRecordsId=${serviceDeliveryRecordsId}`;
    }
    if (inoutResultsId) {
      idsQuery += `&inoutResultsId=${inoutResultsId}`;
    }
    if (supportProcedureFormsId) {
      idsQuery += `&supportProcedureFormsId=${supportProcedureFormsId}`;
    }

    return idsQuery;
  };

  const navigateToEdit = (): void => {
    let query = "";

    if (target === TARGET_PATH.new) {
      switch (selectedOption) {
        case "1": {
          // 単数選択の場合のみqueryに値を設定
          if (isNotSelected || checkedIds.length !== 1) break;
          const index = Number(checkedIds[0].id);
          const {
            service_delivery_records_id,
            inout_results_id,
            support_procedure_forms_id,
            target_date
          } = supportProcedureRows[index];
          if (service_delivery_records_id) {
            query += `&serviceDeliveryRecordsId=${service_delivery_records_id}`;
          }
          if (inout_results_id) {
            query += `&inoutResultsId=${inout_results_id}`;
          }
          if (support_procedure_forms_id) {
            query += `&supportProcedureFormsId=${support_procedure_forms_id}`;
          }
          if (target_date) {
            query += `&targetDate=${target_date}`;
          }
          break;
        }
        case "2": {
          query += createTargetDateQuery(targetDate);
          break;
        }
        case "3":
          query += "&";
          break;

        default:
      }
    } else if (target === TARGET_PATH.copy) {
      // コピーの場合はPropsで受け取った値をqueryに指定
      query += createIdsQuery(selectedIds);

      if (selectedOption === "2") {
        query += createTargetDateQuery(targetDate);
      }
    } else if (target === TARGET_PATH.setDate) {
      // 日時設定の場合はPropsで受け取った値をqueryに指定
      query += createIdsQuery(selectedIds);

      switch (selectedOption) {
        case "1": {
          // 単数選択の場合のみqueryにtargetDateを設定
          if (checkedIds.length !== 1) break;
          const index = Number(checkedIds[0].id);
          const { target_date } = supportProcedureRows[index];
          if (target_date) {
            query += `&targetDate=${target_date}`;
          }
          break;
        }
        case "2": {
          query += createTargetDateQuery(targetDate);
          break;
        }
        default:
      }
    }

    // 日時設定の場合のみ一覧で選択した手順書のIDを設定する
    if (target === TARGET_PATH.setDate) {
      query += `&originSupportProcedureFormsId=${originSupportProcedureId}`;
    }

    query = query && `?${query.slice(1)}`;
    const url = `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE}/${target}`;
    history.push(`${url}${query}`);
  };

  // 編集画面に渡すオブジェクトの設定
  const { setTargetObject } = useLocalStorage();
  const setSelectedTarget = (): void => {
    switch (selectedOption) {
      case "1": {
        const selectedSupportProcedure = supportProcedureRows.filter(
          (_, index) => checkedIds.some(({ id }) => Number(id) === index)
        );
        const targetObject: TargetObject[] = selectedSupportProcedure.map(
          (v) => {
            return {
              targetDate: v.target_date,
              serviceDeliveryRecordsId: v.service_delivery_records_id,
              inoutResultsId: v.inout_results_id,
              supportProcedureFormsId: v.support_procedure_forms_id
            };
          }
        );
        setTargetObject(targetObject);
        break;
      }
      case "2": {
        const selectedDate = Object.values(targetDate)
          .map((v) => v.padStart(2, "0"))
          .join("-");
        const targetObject: TargetObject[] = [
          { ...TargetObjectInitialValue, targetDate: selectedDate }
        ];
        setTargetObject(targetObject);
        break;
      }
      case "3": {
        const targetObject: TargetObject[] = [{ ...TargetObjectInitialValue }];
        setTargetObject(targetObject);
        break;
      }
      default:
    }
  };

  // 日時を確定して編集画面に進む
  const onClickEdit = (): void => {
    // 選択済みの手順書に作成済みの手順書が含まれている場合、上書き確認ダイアログを表示
    if (checkedIds.some(({ statusFlg }) => statusFlg !== "0")) {
      setIsOpenUpdateModal(true);
    } else {
      setSelectedTarget();
      navigateToEdit();
    }
  };

  const onClickFix = (): void => {
    setSelectedTarget();
    navigateToEdit();
  };

  const onCloseDialog = (): void => {
    setIsOpenUpdateModal(false);
  };

  return (
    <Dialog className={classes.modal} maxWidth="md" open={isOpen}>
      <DialogTitle
        className={classes.title}
      >{`支援手順書 ${title}`}</DialogTitle>
      <DialogContent className={classes.content}>
        <div
          className={`${classes.radioButtonWrapper} ${
            (selectedOption === radioBtnOptions[0].value ||
              selectedOption === radioBtnOptions[1].value) &&
            classes.borderBottom
          }`}
        >
          <MuiRadioButtons
            label=""
            options={radioBtnOptions}
            value={selectedOption}
            onChange={(_, value): void => setSelectedOption(value)}
          />
        </div>
        {selectedOption === radioBtnOptions[0].value && (
          <>
            <div
              className={`${classes.targetDateWrapper} ${classes.borderBottom}`}
            >
              <MuiSelect
                name="targetYear"
                label="対象年月"
                size="medium"
                value={targetMonth.year}
                error={hasErrorMonth}
                helperText={monthErrorMessage}
                options={yearOptions}
                onChange={(e): void => {
                  setTargetMonth((prev) => ({
                    ...prev,
                    year: e.target.value
                  }));
                }}
                onBlur={handleOnBlurTargeMonth}
                style={{ width: "256px", minWidth: "256px" }}
              />
              <MuiSelect
                name="targetMonth"
                label=""
                size="superSmall"
                value={targetMonth.month}
                error={hasErrorMonth}
                options={monthOptions}
                onChange={(e): void => {
                  setTargetMonth((prev) => ({
                    ...prev,
                    month: e.target.value
                  }));
                }}
                onBlur={handleOnBlurTargeMonth}
              />
            </div>

            {!isNoRecord ? (
              <>
                <div className={classes.checkboxWrapper}>
                  <MuiCheckbox
                    label="すべての日時を選択"
                    checked={isCheckedAll}
                    onChange={handleCheckAllChange}
                    style={{ margin: 0 }}
                    disabled={baseIds.length === 0}
                  />
                </div>
                <SupportProcedureModalTable
                  checkedIds={checkedIds}
                  handleChangeCheckbox={handleChangeCheckbox}
                  records={supportProcedureRows}
                />
              </>
            ) : (
              <div className={classes.noRecord}>
                計画または支援手順書がありません。
              </div>
            )}
          </>
        )}
        {selectedOption === radioBtnOptions[1].value && (
          <div className={classes.targetDateWrapper}>
            <MuiSelect
              name="targetYear"
              label="対象年月日"
              size="medium"
              value={targetDate.year}
              options={yearOptions}
              error={hasErrorDate}
              helperText={dateErrorMessage}
              onChange={(e): void => {
                setTargetDate((prev) => ({
                  ...prev,
                  year: e.target.value
                }));
              }}
              onBlur={handleOnBlurTargetDate}
              style={{ width: "256px", minWidth: "256px" }}
            />
            <MuiSelect
              name="targetMonth"
              label=""
              size="superSmall"
              value={targetDate.month}
              options={monthOptions}
              error={hasErrorDate}
              onChange={(e): void => {
                setTargetDate((prev) => ({
                  ...prev,
                  month: e.target.value
                }));
              }}
              onBlur={handleOnBlurTargetDate}
            />
            <MuiSelect
              name="targetDay"
              label=""
              size="superSmall"
              value={targetDate.day}
              options={dayOptions}
              error={hasErrorDate}
              onChange={(e): void => {
                setTargetDate((prev) => ({
                  ...prev,
                  day: e.target.value
                }));
              }}
              onBlur={handleOnBlurTargetDate}
            />
          </div>
        )}
        <ConfirmDialog
          isOpen={isOpenUpdateModal}
          onDelete={onClickFix}
          onCancel={onCloseDialog}
          title="作成済みの手順書は上書きされます"
          message={
            <>
              上書きされる前のデータは復元できません。
              <br />
              よろしいですか？
            </>
          }
          submitLabel="確定する"
          dialogWidth={600}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <KnowbeButton kind="outline" onClick={onClose}>
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          disabled={
            isNoRecord ||
            (hasErrorMonth && selectedOption === radioBtnOptions[0].value) ||
            (hasErrorDate && selectedOption === radioBtnOptions[1].value) ||
            isNotSelected
          }
          onClick={onClickEdit}
        >
          {selectedOption === "3"
            ? "確定して編集画面に進む" // 日時を確定せずに作成する
            : "日時を確定して編集画面に進む"}
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  supportProcedureList:
    state.KODOENGO.supportProcedureModal.supportProcedureList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  return {
    fetchModalSupportProcedureList: (uifId: string, date: string): void => {
      KODOENGO.supportProcedureDispatcher(
        dispatch
      ).fetchModalSupportProcedureList(uifId, date);
    }
  };
};

export const SupportProcedureCreateModalContent = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SupportProcedureCreateModalContentCore)
);
