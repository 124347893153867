import { RecordMonthlyValues as SEIKATSUKAIGOFormValues } from "@initialize/mgr/SEIKATSUKAIGO/record/monthly/initialValues";
import { RecordMonthlyValues as IABFormValues } from "@initialize/mgr/IAB/record/monthly/initialValues";
import { RecordOperationsValues as GroupHomeFormValues } from "@initialize/mgr/GroupHome/record/operations/initialValues";
import { RecordOperationsValues as TANKINYUSHOFormValues } from "@initialize/mgr/TANKINYUSHO/record/operations/initialValues";
import { PostOperationsParams } from "@api/requests/operations/postOperations";
import { PostOperationsParams as PostCsegOperationsParams } from "@api/requests/operations/Cseg/postOperations";
import { PostCustomOperationParams } from "@api/requests/operations/postCustomOperation";
import { WorkState } from "@stores/domain/work/types";
import { StaffState } from "@stores/domain/staff/types";
import getStaffName from "@utils/domain/staffs/getStaffName";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import omit from "lodash-es/omit";
import deepEqual from "fast-deep-equal";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";
import { OperationsValues } from "@initialize/mgr/Cseg/record/operations/initialValues";
import { normalizeMultiSelectStaffs } from "@utils/domain/staffs/normalizeMultiSelectStaffs";

type RecordMonthlyValues = SEIKATSUKAIGOFormValues | IABFormValues;
type FormOperation = RecordMonthlyValues["operation"][0];
type PostOperation = Exclude<PostOperationsParams["operation"], null>[0];
type StaffsOperation = IABFormValues["operation"][0]["workplace_company"][0]["staffs"];

/**
 * 差分だけピックしたフォームの値をPostOperationが受け付ける値に変換する
 */
const operationMapping = (
  value: Partial<FormOperation>,
  work: WorkState,
  staff: StaffState,
  initialValues: FormOperation
): PostOperation => {
  const operation = {} as PostOperation;

  // 入力はstaffIdのみなので復元する
  if (value.staff_id === "") {
    operation.staff_id = null;
    operation.staff_name = null;
  } else if (value.staff_id) {
    operation.staff_id = value.staff_id;
    operation.staff_name = getStaffName(staff, value.staff_id);
  }

  // 入力はitemIdのリストなので復元する
  if (value.operation_work_history) {
    const { beforeValues, itemIdList } = value.operation_work_history;
    operation.operation_work_history = itemIdList.map((item, index) => {
      const beforeValue = beforeValues[index];
      // is_deleteがnull以外＆同じ位置に同じitemIdがあれば変更なしとみなして前回値を渡す
      if (!item.is_delete && beforeValue && beforeValue.item_id === item.id) {
        beforeValue.is_delete = null;
        return beforeValue;
      }
      return {
        operation_record_id: null,
        category_id: item.category_id || 0,
        item_id: +item.id,
        item_name: item.label || "",
        is_delete: item.is_delete
      };
    });
  }

  // 移行AB用: 施設外就労同行職員の変換
  if ("workplace_company" in value && value.workplace_company) {
    const { workplace_company } = value; // map配下までは存在チェックが通らない対策
    operation.workplace_company = workplace_company.map((company, i) => {
      const workplaceCompanyStaffs: StaffsOperation = [];

      const initialOperation = initialValues as IABFormValues["operation"][0];
      const hasDifferentStaffs = !deepEqual(
        company.staffs,
        initialOperation.workplace_company
      );
      if (company.staffs && hasDifferentStaffs) {
        company.staffs.forEach((staffValue, j) => {
          if (!staffValue) return;
          const {
            staffs_in_facility_id,
            workplace_company_operation_id
          } = staffValue;
          if (
            (staffs_in_facility_id === null || staffs_in_facility_id < 1) &&
            !workplace_company_operation_id
          )
            return;

          // 同行職員あり -> なしの場合、ほかのプロパティはそのままだが、staffs_in_facility_idは空になるので
          // 同行職員なし時のデータ構造を作ってAPIに渡す。
          if (!staffs_in_facility_id) {
            workplaceCompanyStaffs.push({
              workplace_company_operation_id:
                staffValue.workplace_company_operation_id,
              staffs_in_facility_id: null,
              staff_name: null,
              working_hours: "",
              display_order: staffValue.display_order
            });
            return;
          }
          const operationId = !staffValue.workplace_company_operation_id
            ? undefined
            : staffValue.workplace_company_operation_id;
          let staffId: number | null = !staffValue.staffs_in_facility_id
            ? null
            : staffValue.staffs_in_facility_id;
          let staffName = staffValue.staff_name;
          let workingHours: string | number | null = staffValue.working_hours;

          const initialStaffValue =
            initialOperation.workplace_company[i].staffs[j];
          if (initialStaffValue.staffs_in_facility_id === staffId) {
            workplaceCompanyStaffs.push({
              workplace_company_operation_id:
                initialStaffValue.workplace_company_operation_id,
              staffs_in_facility_id: initialStaffValue.staffs_in_facility_id,
              staff_name: initialStaffValue.staff_name,
              working_hours: workingHours,
              display_order: j
            });
            return;
          }

          if (staffId) {
            staffName = getStaffName(staff, staffId);
          } else {
            staffId = null;
            staffName = null;
            workingHours = null;
          }
          workplaceCompanyStaffs.push({
            workplace_company_operation_id: operationId,
            staffs_in_facility_id: staffId,
            staff_name: staffName,
            working_hours: workingHours,
            display_order: j
          });
        });
      }

      return {
        operation_record_id: company.operation_record_id,
        workplace_company_id: company.workplace_company_id,
        staffs: workplaceCompanyStaffs
      };
    });
  }

  // 対応の不要なパラメーターを取り出す
  const restValue = omit(value, [
    "staff_id",
    "staff_name",
    "operation_work_history",
    "workplace_company"
  ]);
  // merge
  return { ...restValue, ...operation };
};

/**
 * Form => Post
 * 入力前との比較をして差分のあるデータだけ送る
 */
const normalizeFormValuesToPostOperationsParams = (
  values: RecordMonthlyValues,
  initialValues: RecordMonthlyValues,
  work: WorkState,
  staff: StaffState
): PostOperationsParams => {
  const params: PostOperationsParams = {
    operation: null
  };
  // paramsで[]をセットしても型が通らない
  const operation: PostOperationsParams["operation"] = [];

  // 変更のないプロパティを除外する
  values.operation.forEach((v, i) => {
    const res = omitByNoChanges(v, initialValues.operation[i]);
    // どれか一つでもis_deleteが1だった場合operation_work_historyをPOST
    if (v.operation_work_history.itemIdList.some((item) => item.is_delete)) {
      res.operation_work_history = v.operation_work_history;
    }
    if (res && Object.keys(res).length !== 0) {
      // 必要なデータの復元
      res.target_date = v.target_date;
      if (res.operation_work_history) {
        res.operation_work_history = v.operation_work_history;
      }
      const res2 = operationMapping(
        res,
        work,
        staff,
        initialValues.operation[i]
      );
      operation.push(res2);
    }
  });

  params.operation = operation;
  return params;
};

const normalizeCustomRecords = (
  postCustomRecord: GroupHomeFormValues["record"][0]["custom_record"],
  initialDataCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]
): PostCustomOperationParams["custom_record"] => {
  const resCustom = [] as PostCustomOperationParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);
  const diffDataKeys = Object.keys(diffData);

  // 入力タイプ「テキスト入力」
  if (diffDataKeys.some((item) => item === "input_type_first")) {
    const diffDataFirst = omitByNoChanges(
      postCustomRecord.input_type_first,
      initialDataCustomRecord.input_type_first
    );
    const record = Object.values(
      diffDataFirst
    ) as PostCustomOperationParams["custom_record"];
    resCustom.push(...record);
  }

  // 入力タイプ「チェックボックス」
  if (diffDataKeys.some((item) => item === "input_type_second")) {
    const diffDataSecond = omitByNoChanges(
      postCustomRecord.input_type_second,
      initialDataCustomRecord.input_type_second
    );
    const records = Object.values(
      diffDataSecond
    ) as PostCustomOperationParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => {
        return {
          custom_record_input_id: item[key].custom_record_input_id,
          custom_record_item_id: item[key].custom_record_item_id,
          choiced_item_id: item[key].choiced_item_id,
          checked: castCheckBoxToNumber(item[key].checked)
        };
      });
      resCustom.push(...postRecord);
    });
  }
  return resCustom;
};

const normalizeCustomRecordInputTypeThird = (
  postCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]["input_type_third"],
  initialDataCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]["input_type_third"]
): PostCustomOperationParams["custom_record"] => {
  const resCustom = [] as PostCustomOperationParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDataKeys = Object.keys(diffData);

  Object.entries(postCustomRecord).forEach(([key, value]) => {
    const isDeleteItem = value.itemIdList.some(
      (item) => item.is_delete !== null
    );

    const isDiffCustomRecord = diffDataKeys.some((item) => item === key);

    if (value && (isDiffCustomRecord || isDeleteItem)) {
      // 新規追加またはchecked「OFF」変更（「is_delete=1」の場合）
      const newRecord = [] as PostCustomOperationParams["custom_record"];
      value.itemIdList.forEach((item) => {
        const beforeValue = value.beforeValues.find(
          (beforeValueItem) => beforeValueItem.choiced_staff_id === item.id
        );

        const record = {
          custom_record_input_id: beforeValue
            ? beforeValue.custom_record_input_id
            : null,
          custom_record_item_id: Number(key),
          choiced_staff_id: beforeValue
            ? beforeValue.choiced_staff_id
            : Number(item.id),
          choiced_staff_name_snapshot: item.label,
          checked: item.is_delete === 1 ? 0 : 1
        };
        newRecord.push(record);
      });

      // checked「OFF」変更処理
      const unCheckRecord = value.beforeValues
        .filter((beforeValueItem) => {
          return !value.itemIdList.find(
            (itemIdListItem) =>
              beforeValueItem.choiced_staff_id === itemIdListItem.id
          );
        })
        .map((unCheckBeforeValue) => {
          return {
            custom_record_input_id: unCheckBeforeValue.custom_record_input_id,
            custom_record_item_id: Number(key),
            choiced_staff_id: unCheckBeforeValue.choiced_staff_id,
            choiced_staff_name_snapshot:
              unCheckBeforeValue.choiced_staff_name_snapshot,
            checked: 0
          };
        });

      resCustom.push(...newRecord, ...unCheckRecord);
    }
  });

  return resCustom;
};

/**
 * postCustomSupportに渡すparamsの変換
 */
export const normalizeFormValuesToPostCustomOperationParams = (
  values: GroupHomeFormValues,
  initialValues: GroupHomeFormValues,
  targetDate: string
): PostCustomOperationParams => {
  const params = {} as PostCustomOperationParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_date === targetDate
  );
  const postData = values.record.find(
    (item) => item.target_date === targetDate
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDataKeys = Object.keys(res);

    if (diffDataKeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = postData.setting_type;
    params.operation_record_id = postData.operation_record_id || null;
    if (postData.setting_type === 2) {
      params.facility_unit_id = postData.facility_unit_id;
    }
  }

  return params;
};

/**
 * postCustomSupportに渡すparamsの変換
 */
export const normalizeFormValuesToPostCustomOperationParamsTANKINYUSHO = (
  values: TANKINYUSHOFormValues,
  initialValues: TANKINYUSHOFormValues,
  targetDate: string
): PostCustomOperationParams => {
  const params = {} as PostCustomOperationParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_date === targetDate
  );
  const postData = values.record.find(
    (item) => item.target_date === targetDate
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDataKeys = Object.keys(res);

    if (diffDataKeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = postData.setting_type;
    params.operation_record_id = postData.operation_record_id || null;
  }

  return params;
};

/**
 * postOperationに渡すparamsの変換
 */
export const normalizeFormValuesToPostCsegOperationParams = (
  values: OperationsValues,
  initialValues: OperationsValues,
  targetDate: string
): PostCsegOperationsParams => {
  const postData = values.operations.find(
    (item) => item.target_date === targetDate
  );

  const operation: PostCsegOperationsParams["operation"] = {
    operation_records_id: postData ? postData.operation_records_id : null,
    operation_in_the_morning: postData
      ? postData.operation_in_the_morning
      : null,
    operation_in_the_afternoon: postData
      ? postData.operation_in_the_afternoon
      : null,
    other_comment: postData ? postData.other_comment : null,
    staffs: postData
      ? normalizeMultiSelectStaffs(
          postData.staffs.itemIdList,
          postData.staffs.beforeValues
        )
      : null
  };

  const beforeValue = initialValues.operations.find(
    (item) => item.target_date === targetDate
  );

  // 変更を行なっていないデータはkeyを削除する
  if (postData && beforeValue) {
    const diffValues = omitByNoChanges(postData, beforeValue);
    const diffDataKeys = Object.keys(diffValues);
    if (!diffDataKeys.includes("operation_in_the_morning")) {
      delete operation.operation_in_the_morning;
    }
    if (!diffDataKeys.includes("operation_in_the_afternoon")) {
      delete operation.operation_in_the_afternoon;
    }
    if (!diffDataKeys.includes("other_comment")) {
      delete operation.other_comment;
    }
    if (!diffDataKeys.includes("staffs")) {
      delete operation.staffs;
    }
  }

  return { operation };
};

export default normalizeFormValuesToPostOperationsParams;
