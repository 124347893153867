import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetAssistanceReportResponse = {
  data: {
    id: number;
    created_at: string;
    updated_at: string;
    guardian_name: string | null;
    guardian_relation: string | null;
    date_of_monitoring: string;
    creation_date: string | null;
    author: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string;
      facility_id: number;
      snapshot_name: string;
    } | null;
    classify_disability_support: number | null;
    classify_handicapped_flg: number | null;
    disabled_welfare_service_recipient_number: string | null;
    consultation_support_recipient_number: string | null;
    regular_place_recipient_number: string | null;
    attainment_goal: string | null;
    whole_situation: string | null;
    staff_comment: string | null;
    details: {
      id: number;
      support_goal: string;
      criteria_for_cooperation: string;
      service_provision_status: string;
      personal_satisfaction: string;
      achievement_of_expectations: string;
      future_issues: string;
      other_considerations: string;
    }[];
  };
  response: {
    code: number;
    msg: string;
  };
};

/**
 * 指定ユーザーの地域移行支援報告書情報を取得する
 * @param uifId ユーザーID
 * @param assistanceReportsId 支援報告書ID
 */
export const getAssistanceReport = async (
  uifId: string,
  assistanceReportsId: string
): Promise<AxiosResponse<GetAssistanceReportResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assistance_report/${assistanceReportsId}`;
  return request.get<GetAssistanceReportResponse>(url);
};
