import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { CellParam } from "@components/molecules/Table";
import * as React from "react";
import Table from "@material-ui/core/Table";
import classNames from "classnames";

import TableHead, { HeaderProps } from "@components/molecules/TableHead";
import { ReportUsagePerformanceState } from "@stores/domain/mgr/GroupHome/report/types";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME } from "@constants/mgr/GroupHome/variables";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO } from "@constants/mgr/SHISETSUNYUSHO/variables";
import { FacilityType } from "@constants/variables";

type OwnProps = {
  summary: ReportUsagePerformanceState["summary"];
  labels:
    | typeof USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME
    | typeof USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO;
  facilityType: FacilityType;
};

const styles = (): StyleRules =>
  createStyles({
    tableHeader: {
      tableLayout: "fixed",
      height: 114
    },
    headerFirstWidth: {
      width: 158
    },
    headMiddle96: {
      width: 96,
      height: "88px",
      backgroundColor: "#90a4ae",
      padding: "0px",
      "&:nth-of-type(even)": {
        backgroundColor: "#9badb6"
      },
      "&:last-child": {
        padding: "0px"
      }
    },
    headMiddle90: {
      width: 90,
      height: "88px",
      backgroundColor: "#90a4ae",
      padding: "0px",
      "&:nth-of-type(even)": {
        backgroundColor: "#9badb6"
      },
      "&:last-child": {
        padding: "0px"
      }
    },
    headMiddleLong116: {
      width: 116,
      height: "88px",
      backgroundColor: "#90a4ae",
      padding: "0px",
      "&:nth-of-type(even)": {
        backgroundColor: "#9badb6"
      },
      "&:last-child": {
        padding: "0px"
      }
    },
    headMiddleLong120: {
      width: 120,
      height: "88px",
      backgroundColor: "#90a4ae",
      padding: "0px",
      "&:nth-of-type(even)": {
        backgroundColor: "#9badb6"
      },
      "&:last-child": {
        padding: "0px"
      }
    },
    headFirst: {
      boxSizing: "content-box",
      height: "88px",
      lineHeight: 3,
      letterSpacing: "0.1px",
      verticalAlign: "bottom",
      padding: "0px",
      backgroundColor: "#90a4ae"
    },
    headerWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%"
    },
    headerLabel: {
      height: "100%",
      fontSize: 14,
      lineHeight: "1.2",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      padding: "0 6px",
      margin: "8px auto 0",
      whiteSpace: "pre-wrap",
      "&.-shisetsunyusho": {
        fontSize: 12
      }
    },
    headerMainTextContent: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#78909c",
      borderRadius: 16,
      height: 32,
      width: 72,
      textAlign: "center",
      margin: "0 auto 12px",
      "&.-shisetsunyusho": {
        width: 58,
        height: "20px"
      }
    },
    headerCount: {
      fontSize: 20,
      "&.-shisetsunyusho": {
        fontSize: 16
      }
    },
    headerUnit: {
      fontSize: 14,
      marginTop: 6,
      marginLeft: 8,
      "&.-shisetsunyusho": {
        fontSize: 12,
        marginTop: 2,
        marginLeft: 6
      }
    },
    headerMultiContentWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 8
    },
    headerLabelContentWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "0 10px"
    },
    headerSubLabel: {
      width: 38,
      textAlign: "left",
      fontSize: 12
    },
    headerMainTextContentMulti: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#78909c",
      borderRadius: 10,
      height: 20,
      width: 58,
      textAlign: "center",
      margin: "0 0 4px 4px"
    },
    headerCountMulti: {
      fontSize: 16
    },
    headerUnitMulti: {
      fontSize: 12,
      marginTop: 2,
      marginLeft: 6
    }
  });

type Props = OwnProps & WithStyles<typeof styles>;

const UsagePerformanceDetailHeader = ({
  classes,
  summary,
  labels,
  facilityType
}: Props): JSX.Element | null => {
  const getCount = (status: number): number => {
    const statusCount = summary.find((target) => target.status === status);
    return statusCount === undefined ? 0 : statusCount.count;
  };
  const getClassValue = (length: number): string | undefined => {
    if (facilityType === FacilityType.SHISETSUNYUSHO) {
      return length > 1 ? classes.headMiddleLong116 : classes.headMiddle90;
    }
    return length > 1 ? classes.headMiddleLong120 : classes.headMiddle96;
  };
  const HeaderItemList: CellParam[] = labels.map((label) => {
    let classValue;
    if (label.key === 0) {
      classValue = classes.headFirst;
    } else {
      classValue = getClassValue(label.status.length);
    }
    const element = (
      <div
        className={classes.headerWrapper}
        key={`${label.key}-header-wrapper`}
      >
        <div
          className={classNames(classes.headerLabel, {
            "-shisetsunyusho": facilityType === FacilityType.SHISETSUNYUSHO
          })}
          key={`${label.key}-header-label`}
        >
          {label.text}
        </div>
        {label.key !== 0 &&
          (label.status.length === 1 ? (
            <div
              className={classNames(classes.headerMainTextContent, {
                "-shisetsunyusho": facilityType === FacilityType.SHISETSUNYUSHO
              })}
              key={`${label.key}-header-Main`}
            >
              <span
                className={classNames(classes.headerCount, {
                  "-shisetsunyusho":
                    facilityType === FacilityType.SHISETSUNYUSHO
                })}
              >
                {getCount(label.status[0])}
              </span>
              <span
                className={classNames(classes.headerUnit, {
                  "-shisetsunyusho":
                    facilityType === FacilityType.SHISETSUNYUSHO
                })}
              >
                人
              </span>
            </div>
          ) : (
            <div
              className={classes.headerMultiContentWrapper}
              key={`${label.key}-header-multi-content`}
            >
              {label.status.map((status, i) => {
                return (
                  <div
                    className={classes.headerLabelContentWrapper}
                    key={`${label.key}-${status}-header-wrapper`}
                  >
                    <span className={classes.headerSubLabel}>
                      {label.subLabel ? label.subLabel[i] : ""}
                    </span>
                    <div
                      className={classes.headerMainTextContentMulti}
                      key={`${label.key}-${status}-header-main-multi`}
                    >
                      <span className={classes.headerCountMulti}>
                        {getCount(status)}
                      </span>
                      <span className={classes.headerUnitMulti}>人</span>
                    </div>
                  </div>
                );
              })}
            </div>
          ))}
      </div>
    );
    return {
      align: "left",
      label: element,
      className: classValue
    };
  });

  const tableHead: HeaderProps = {
    tabIndex: -1,
    key: 0,
    selected: false,
    items: HeaderItemList
  };

  return (
    <Table key="monthly-report-table" className={classes.tableHeader}>
      <TableHead
        role={tableHead.role}
        ariaChecked={tableHead.ariaChecked}
        tabIndex={tableHead.tabIndex}
        key={tableHead.key}
        selected={tableHead.selected}
        items={tableHead.items}
        rowStyle={tableHead.rowStyle}
        headerFirstWidth={classes.headerFirstWidth}
      />
    </Table>
  );
};

export default withStyles(styles)(UsagePerformanceDetailHeader);
