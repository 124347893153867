import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";
import { InoutResultsDetailsItem } from "./getInOutResultsDaily";
/**
 * 実績登録API用のリクエストパラメータ
 */
export type RequestParamToPostAPI = {
  yyyymmdd: string | null;
  uif_id: string | null;
  params: {
    inout_results_id: number | null;
    inout_result_daily_id: number | null;
    target_date: string | null;
    input_class: number | null;
    inout_results_plan_id?: number | null;
    inout_results_result_id?: number | null;
    service_delivery_records_id?: number | null;
    status?: number;
    status_text?: string | null;
    number_of_boarding_and_alighting?: number | null;
    number_of_participants?: number | null;
    practitioner1?: number | null;
    practitioner1_name?: string | null;
    practitioner1_license?: number | null;
    practitioner1_in_time?: string | null;
    practitioner1_out_time?: string | null;
    practitioner1_calculated_hours?: number | null;
    practitioner1_calculated_moving_hours?: number | null;
    practitioner1_memo?: string | null;
    inout_results_details1?: InoutResultsDetailsItem[];
    practitioner2?: number | null;
    practitioner2_name?: string | null;
    practitioner2_license?: number | null;
    practitioner2_in_time?: string | null;
    practitioner2_out_time?: string | null;
    practitioner2_calculated_hours?: number | null;
    practitioner2_memo?: string | null;
    inout_results_details2?: InoutResultsDetailsItem[];
    practitioner3?: number | null;
    practitioner3_name?: string | null;
    practitioner4?: number | null;
    practitioner4_name?: string | null;
    practitioner5?: number | null;
    practitioner5_name?: string | null;
    time_duplication_judgment_flg?: number | null;
    whole_calculated_hours?: number | null;
    calculated_hours_daytime?: number | null;
    calculated_hours_nighttime?: number | null;
    calculated_hours_late_night?: number | null;
    calculated_hours_early_morning?: number | null;
    duplicate_calculated_hours?: number | null;
    duplication_calculated_hours_daytime?: number | null;
    duplication_calculated_hours_nighttime?: number | null;
    duplication_calculated_hours_late_night?: number | null;
    duplication_calculated_hours_early_morning?: number | null;
    moving_time_duplication_judgment_flg?: number | null;
    whole_calculated_moving_hours?: number | null;
    duplicate_calculated_moving_hours?: number | null;
    accompany_support_in_time?: string | null;
    accompany_support_out_time?: string | null;
    whole_calculated_accompany_support_hours?: number | null;
    whole_calculated_accompany_support_moving_hours?: number | null;
    vehicle_calculated_hours?: string | null;
    license_same_flg?: number | null;
    second_person_flg?: number | null;
    accompany_support_flg?: number | null;
    emergency_support_flg?: number | null;
    first_addition_flg?: number | null;
    sputum_implementation_flg?: number | null;
    behavior_disorder_flg?: number | null;
    emergency_guide_support_flg?: number | null;
    welfare_cooperation_flg?: number | null;
    behavior_disorder_coaching_flg?: number | null;
    body_restricted_still_flg?: number | null;
    facility_unit_id?: number | null;
    physical_care_flg?: number | null;
    number_of_supporters?: number | null;
    service_codes?: ServiceCodesItem[];
  };
};

export type ServiceCodesItem = {
  service_code_inout_result_id: number | null;
  service_code_municipality_id: number;
  is_delete: number;
};

/**
 * 利用実績の登録（更新）
 * @param data リクエスト情報
 */
export const postInOutResults = async (
  data: RequestParamToPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/inout_results/${data.uif_id}/${data.yyyymmdd}`;
  return request.post(url, data.params);
};
