import * as React from "react";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { SnackbarParams } from "@stores/ui/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { StaffState } from "@stores/domain/staff/types";
import {
  loadDone,
  ActionTypes as LoadingActionTypes
} from "@stores/loading/actions";
import {
  clearRecordDetailState,
  ActionTypes
} from "@stores/domain/serviceDelivery/actions";
// ui
import { FieldItem } from "@interfaces/ui/form";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { ServiceDeliveryBasicField } from "@components/organisms/record/serviceDelivery/ServiceDeliveryBasicField";
import { ServiceDeliveryDetailField } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailField";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
// formik
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  ServiceDeliveryDetailValues
} from "@initialize/record/serviceDelivery/initialValues";
import {
  validation,
  ServiceDeliveryDetailErrors
} from "@initialize/record/serviceDelivery/validation";
// utils
import { toEffectiveObject } from "@utils/object";
import deepEqual from "fast-deep-equal";
import * as URL from "@constants/url";
import * as H from "history";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import {
  FacilityType,
  NUMBER_OF_PRACTITIONER_STR,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      height: spacing.unit * 8,
      top: 0
    },
    cancelButton: {
      marginRight: "8px"
    },
    createdAt: {
      paddingRight: "16px"
    },
    deleteIcon: {
      marginRight: "8px",
      color: "#0277bd"
    },
    dialogContent: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 32px 16px"
    }
  });

type OwnProps = {
  targetDate: string;
  date: Date;
  isEdit: boolean;
  history: RouteComponentProps["history"];
  facilityType: FacilityType;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  params?: {
    supportProcedureFormsId: string;
  };
};

type DispatchProps = {
  postRecordDetail: (
    params: ServiceDeliveryDetailValues,
    initialValues: ServiceDeliveryDetailValues,
    history: H.History,
    path: string,
    facilityType: FacilityType,
    supportProcedureFormsId?: string
  ) => Promise<void>;
  clearRecordDetailState: () => void;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  loadDone: () => void;
  deleteServiceDelivery: (serviceDeliveryRecordsId: number) => Promise<void>;
};

type StateProps = {
  customRecords: CustomRecordsWithCategoryState;
  needsStopHistory: boolean;
  staff: StaffState;
};

type MergeProps = OwnProps &
  DispatchProps &
  StateProps & {
    staffOptions: FieldItem[];
  };
type Props = MergeProps & WithStyles<typeof styles>;

const ServiceDeliveryFormCore = (props: Props): JSX.Element => {
  const { classes, facilityType } = props;
  const [initialValuesState, setInitialValuesState] = React.useState<
    ServiceDeliveryDetailValues
  >(
    initialValues({
      customRecords: props.customRecords,
      targetDate: props.targetDate,
      staffOptions: props.staffOptions,
      facilityType
    })
  );
  const [numberOfPractitioner, setNumberOfPractitioner] = React.useState<
    number
  >(2);
  const alwaysInfoOpenFlg = !!SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
    facilityType
  );
  const [isInfoOpen, setIsInfoOpenHandle] = React.useState<boolean>(
    alwaysInfoOpenFlg
  );
  const setIsInfoOpen = (state: boolean): void => {
    if (alwaysInfoOpenFlg) return;
    setIsInfoOpenHandle(state);
  };
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);
  const [deleteTargetId, setDeleteTargetId] = React.useState<number>(0);

  React.useEffect(() => {
    return (): void => {
      // state初期化
      props.clearRecordDetailState();
    };
  }, []);

  React.useEffect(() => {
    if (
      props.isEdit &&
      props.detailRecords &&
      props.detailRecords.usersInFacilityId !== 0
    ) {
      setInitialValuesState(
        initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          state: props.detailRecords,
          facilityType
        })
      );
      setNumberOfPractitioner(props.detailRecords.numberOfPractitioner);
    } else {
      setInitialValuesState(
        initialValues({
          customRecords: props.customRecords,
          targetDate: props.targetDate,
          staffOptions: props.staffOptions,
          facilityType
        })
      );
      // 編集の際には最終的にAPIから取得した値が設定されるため、レンダリング時の計算（サービス提供時間数）を行うため2を設定している
      setNumberOfPractitioner(props.isEdit ? 2 : 1);
    }
  }, [props.customRecords, props.detailRecords]);

  React.useEffect(() => {
    if (
      initialValuesState.status !== KYOTAKUKAIGO_STATUS_LIST.NONE.value &&
      initialValuesState.usersInFacilityId
    ) {
      setIsInfoOpen(true);
    }
  }, [initialValuesState]);

  const onSubmit = async (
    values: ServiceDeliveryDetailValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postRecordDetail(
        values,
        initialValuesState,
        props.history,
        `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${props.targetDate}`,
        facilityType,
        props.params ? props.params.supportProcedureFormsId : undefined
      )
      .catch(() => {
        props.showSnackbar({
          open: true,
          message: "入力内容に誤りがあります",
          variant: "warning"
        });
        props.loadDone();
      });
    actions.setSubmitting(false);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onClickCancelButton = async (): Promise<void> => {
    if (!props.isEdit) {
      await props.stopHistory(false);
    }
    props.history.push(
      `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${props.targetDate}`
    );
  };

  const confirmDiscardFormChanges = (
    nextValues: ServiceDeliveryDetailValues
  ): void => {
    // 自治体情報の変更によって離脱モーダルが表示されないようにnullを挿入
    const value = { ...nextValues, municipality: null };
    const init = { ...initialValuesState, municipality: null };

    const hasChange = !deepEqual(value, init);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: ServiceDeliveryDetailValues): void | object => {
    const validationResult = validation(values, facilityType);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const openDeleteModal = (): void => {
    if (props.detailRecords && props.detailRecords.serviceDeliveryRecordsId) {
      setDeleteTargetId(props.detailRecords.serviceDeliveryRecordsId);
    }
    setIsOpenDeleteDialog(true);
  };

  const handleCancel = (): void => {
    setIsOpenDeleteDialog(false);
  };

  const dialogMessage = (
    <span>
      データが完全に削除され、復元できません。
      <br />
      よろしいですか？
    </span>
  );

  const onDelete = async (): Promise<void> => {
    await props.deleteServiceDelivery(deleteTargetId);
    await props.stopHistory(false);
    props.history.push(
      `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${props.targetDate}`
    );
    setIsOpenDeleteDialog(false);
  };

  return (
    <Formik
      initialValues={initialValuesState}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        // 非表示になっている項目はここで表示
        const errorAction = (): void => {
          const validationResult = validation(formikProps.values, facilityType);
          const error = toEffectiveObject(
            validationResult
          ) as ServiceDeliveryDetailErrors;
          if (
            error.serviceDeliveryRecordPractitioners1 &&
            error.serviceDeliveryRecordPractitioners1.custom_record &&
            !formikProps.values.serviceDeliveryRecordPractitioners1.displayFlg
          ) {
            formikProps.setFieldValue(
              "serviceDeliveryRecordPractitioners1.displayFlg",
              true
            );
          }
          if (
            error.serviceDeliveryRecordPractitioners2 &&
            error.serviceDeliveryRecordPractitioners2.custom_record &&
            !formikProps.values.serviceDeliveryRecordPractitioners2.displayFlg
          ) {
            formikProps.setFieldValue(
              "serviceDeliveryRecordPractitioners2.displayFlg",
              true
            );
          }
          submitError();
        };

        const isIdoshien = facilityType === FacilityType.IDOSHIEN;
        const isGroupStatus =
          formikProps.values.status === IDOSHIEN_STATUS_LIST.GROUP.value;
        return (
          <Form>
            <ContentHeader
              position="sticky"
              classes={{ wrapper: props.classes.wrapper }}
            >
              <ContentHeaderRight mediaOff>
                {props.isEdit ? (
                  <KnowbeButton
                    kind="outline"
                    style={{ position: "absolute" }}
                    onClick={onClickCancelButton}
                  >
                    一覧に戻る
                  </KnowbeButton>
                ) : (
                  <KnowbeButton
                    kind="outline"
                    onClick={onClickCancelButton}
                    className={classes.cancelButton}
                  >
                    キャンセル
                  </KnowbeButton>
                )}
                <FormikSubmitButton
                  buttonName="保存する"
                  formikProps={formikProps}
                  errorAction={errorAction}
                />
              </ContentHeaderRight>
            </ContentHeader>
            {formikProps.values.createdAt && (
              <div className={props.classes.createdAt}>
                <CreateAndUpdateDate
                  createdAt={formikProps.values.createdAt}
                  updatedAt={formikProps.values.updatedAt}
                />
              </div>
            )}
            {/* 基本情報 */}
            <ServiceDeliveryBasicField
              formikProps={formikProps}
              setFormikFieldValue={formikProps.setFieldValue}
              setNumberOfPractitioner={setNumberOfPractitioner}
              isInfoOpen={isInfoOpen}
              setIsInfoOpen={setIsInfoOpen}
              targetDate={props.targetDate}
              facilityType={facilityType}
              detailRecords={props.detailRecords}
              isDaily
              isEdit={props.isEdit}
            />
            {/* サービス提供記録 */}
            {isInfoOpen && (
              <>
                <ServiceDeliveryDetailField
                  formikProps={formikProps}
                  setFormikFieldValue={formikProps.setFieldValue}
                  customRecords={props.customRecords}
                  practitionerNum={1}
                  numberOfPractitioner={numberOfPractitioner}
                  detailRecords={props.detailRecords}
                  staffOptions={props.staffOptions}
                  facilityType={facilityType}
                />
                {((isIdoshien && !isGroupStatus) || !isIdoshien) &&
                  formikProps.values.numberOfPractitioner ===
                    NUMBER_OF_PRACTITIONER_STR.two && (
                    <ServiceDeliveryDetailField
                      formikProps={formikProps}
                      setFormikFieldValue={formikProps.setFieldValue}
                      customRecords={props.customRecords}
                      practitionerNum={2}
                      detailRecords={props.detailRecords}
                      numberOfPractitioner={numberOfPractitioner}
                      staffOptions={props.staffOptions}
                      facilityType={facilityType}
                    />
                  )}
              </>
            )}
            {props.detailRecords &&
            props.detailRecords.serviceDeliveryRecordsId ? (
              <KnowbeButton kind="text" onClick={openDeleteModal}>
                <DeleteIcon className={classes.deleteIcon} />
                サービス提供記録を削除する
              </KnowbeButton>
            ) : null}
            {isOpenDeleteDialog && (
              <MessageDialog
                classes={{ content: props.classes.dialogContent }}
                isOpen={isOpenDeleteDialog}
                title="このサービス提供記録を削除します"
                message={dialogMessage}
                closeButton={
                  <KnowbeButton kind="text" onClick={handleCancel}>
                    キャンセル
                  </KnowbeButton>
                }
                actionButton={
                  <KnowbeButton kind="textDelete" onClick={onDelete}>
                    削除する
                  </KnowbeButton>
                }
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, serviceDelivery } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);

  return {
    postRecordDetail: (
      params: ServiceDeliveryDetailValues,
      initialValuesParam: ServiceDeliveryDetailValues,
      history: H.History,
      path: string,
      facilityType: FacilityType,
      supportProcedureFormsId
    ): Promise<void> =>
      serviceDeliveryDispatches.postRecordDetail(
        params,
        initialValuesParam,
        history,
        path,
        facilityType,
        false,
        supportProcedureFormsId || ""
      ),
    clearRecordDetailState: (): ActionTypes =>
      dispatch(clearRecordDetailState()),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory,
    loadDone: (): LoadingActionTypes => dispatch(loadDone()),
    deleteServiceDelivery: (serviceDeliveryRecordsId: number): Promise<void> =>
      serviceDeliveryDispatches.deleteServiceDelivery(serviceDeliveryRecordsId)
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecordsWithCategory.serviceDelivery,
  needsStopHistory: state.ui.needsStopHistory,
  staff: state.staff
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff } = stateProps;
  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  return {
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryFormDaily = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryFormCore));
