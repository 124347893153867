import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import FormikSwitch from "@components/molecules/FormikSwitch";
import { FacilityValues } from "@initialize/mgr/KEIKAKUSODAN/facility/initialValues";
import { FormikProps } from "formik";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { DEFAULT_SELECT_DATE_VALUE } from "@constants/variables";

type Props = {
  facility: FacilityState;
  formikProps: FormikProps<FacilityValues>;
};

export const SubtractionItemFields = (props: Props): JSX.Element => {
  const isKeikaku = props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    props.facility.facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "facilityInfoKeikaku.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "facilityInfoShogaiji.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "facilityInfoKeikaku.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.workContinuationNotPlanningFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.workContinuationNotPlanningTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "facilityInfoShogaiji.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.workContinuationNotPlanningFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.workContinuationNotPlanningTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };
  return (
    <>
      {(isKeikaku || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="減算対象項目（計画相談支援）" />
          </div>
          <FormGroup>
            <FormikSwitch
              name="facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFlg"
              label="虐待防止措置未実施減算"
              onChange={onChangeSwitch}
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.abusePreventionMeasuresNotImplementedFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.abusePreventionMeasuresNotImplementedTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>
          <FormGroup>
            <FormikSwitch
              name="facilityInfoKeikaku.workContinuationNotPlanningFlg"
              label="業務継続計画未策定減算"
              onChange={onChangeSwitch}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="workContinuationNotPlanningFlg" />
                  }
                />
              }
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.workContinuationNotPlanningFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.workContinuationNotPlanningTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>
          <FormGroup>
            <FormikSwitch
              name="facilityInfoKeikaku.informationDisclosureNotReportedFlg"
              label="情報公表未報告減算"
              onChange={onChangeSwitch}
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.informationDisclosureNotReportedFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoKeikaku.informationDisclosureNotReportedTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>
        </FormPaper>
      )}
      {(isShogaiji || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="減算対象項目（障害児相談支援）" />
          </div>
          <FormGroup>
            <FormikSwitch
              name="facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFlg"
              label="虐待防止措置未実施減算"
              onChange={onChangeSwitch}
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.abusePreventionMeasuresNotImplementedFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.abusePreventionMeasuresNotImplementedTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>

          <FormGroup>
            <FormikSwitch
              name="facilityInfoShogaiji.workContinuationNotPlanningFlg"
              label="業務継続計画未策定減算"
              onChange={onChangeSwitch}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="workContinuationNotPlanningFlg" />
                  }
                />
              }
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.workContinuationNotPlanningFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.workContinuationNotPlanningTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>

          <FormGroup>
            <FormikSwitch
              name="facilityInfoShogaiji.informationDisclosureNotReportedFlg"
              label="情報公表未報告減算"
              onChange={onChangeSwitch}
            >
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.informationDisclosureNotReportedFrom"
                  label="開始年月日"
                  addYearTo={1}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
              <FormGroup row>
                <FormikSelectDateNotSelectedDefault
                  name="facilityInfoShogaiji.informationDisclosureNotReportedTo"
                  label="終了年月日（任意）"
                  addYearTo={5}
                  overrideYearFrom={2024}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  monthOptionsStartFrom={4}
                />
              </FormGroup>
            </FormikSwitch>
          </FormGroup>
        </FormPaper>
      )}
    </>
  );
};
