import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { FacilityState } from "@stores/domain/mgr/TANKINYUSHO/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import AdminTemplate from "@components/templates/AdminTemplate";
import DailySupportsRecord from "@components/organisms/mgr/TANKINYUSHO/record/DailySupportsRecord";
import { FieldItem } from "@interfaces/ui/form";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";

// utils
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { getStaffCommentLabel } from "@utils/domain/customRecords/getStaffCommentLabel";

// variables
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import format from "date-fns/format";
import * as URL from "@constants/url";
import { STICKY_BOX_SHADOW, STICKY_NONE_BOX_SHADOW } from "@constants/styles";
import { DailyOperationRecord } from "@components/organisms/mgr/TANKINYUSHO/record/DailyOperationRecord";
import { DailyPrintModal } from "@components/organisms/mgr/TANKINYUSHO/record/DailyPrintModal";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: "16px 16px 0"
    },
    stickyWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 16px",
      zIndex: 1000,
      position: "sticky",
      top: 0,
      background: "#eeeeee"
    },
    CreateAndUpdateDateWrap: {
      padding: "16px 16px 0"
    },
    buttons: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    },
    buttonShadowNone: {
      boxShadow: "none"
    },
    noDataRoot: {
      // padding: "32px 32px 0px",
      margin: "16px"
    },
    noData: {
      padding: "80px",
      textAlign: "center"
    }
  });

type OwnProps = WithStyles<typeof styles> &
  RouteComponentProps<{ yyyymmdd?: string }>;
type StateProps = {
  facility: FacilityState;
  users: UsersInFacilityState["users"];
  dailyOperationsAndSupports: DailyOperationsAndSupportsState;
  staff: StaffState;
  recordDaily: RecordDailyState;
  customRecords: CustomRecordsState;
};
type DispatchProps = {
  fetchDailyOperationsAndSupports: (yyyymmdd: string) => Promise<void>;
  fetchInitialDailyRecordTANKINYUSHO: (yyyymmdd: string) => void;
};
type MergeProps = {
  staffOptions: FieldItem[];
} & OwnProps &
  StateProps &
  DispatchProps;

/**
 * 日々の記録
 */
const RecordDaily = (props: MergeProps): JSX.Element => {
  const noCreatedAt = !props.dailyOperationsAndSupports.created_at;

  const scrollYValue = 48;
  const [stickyFlg, setStickyFlg] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    const listenScrollEvent = (): void => {
      if (unmounted) return;
      if (window.scrollY > scrollYValue) {
        setStickyFlg(true);
      } else {
        setStickyFlg(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      unmounted = true;
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  // カレンダー周り
  const currentDate = props.match.params.yyyymmdd
    ? convertBlankSeparatorFormatToDate(props.match.params.yyyymmdd)
    : new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = new Date(2049, 11, 31);

  // state
  const [targetDay, setTargetDay] = React.useState(
    format(currentDate, "YYYYMMDD")
  );
  const isFirstRef = React.useRef(false);
  const [isOpenPrintModal, setOpenDetailModal] = React.useState(false);

  // fetch
  React.useEffect(() => {
    // URL変更
    const locationState = {
      pathname: `${URL.RECORD_DAILY}/${targetDay}`
    };
    if (props.match.params.yyyymmdd) {
      // TODO: ブラウザバックのバグが取れるまで履歴を持たなくする
      props.history.replace(locationState);
    } else {
      props.history.replace(locationState);
    }

    // API実行: 初期取得 or 再取得
    if (isFirstRef.current) {
      props.fetchDailyOperationsAndSupports(targetDay);
    } else {
      props.fetchInitialDailyRecordTANKINYUSHO(targetDay);
      isFirstRef.current = true;
    }
  }, [targetDay]);

  // 職員考察の名称(印刷確認モーダルで使用)
  const staffCommentLabel = getStaffCommentLabel({
    serviceType: props.facility.serviceType,
    customRecords: props.customRecords
  });

  // handler
  const onClickCalendar = (date: Date): void => {
    setTargetDay(format(date, "YYYYMMDD"));
  };

  const onPrintPreview = (): void => {
    setOpenDetailModal(true);
  };

  const onClosePrintModal = (): void => {
    setOpenDetailModal(false);
  };

  return (
    <AdminTemplate pageName="日々の記録">
      <div
        className={props.classes.stickyWrapper}
        style={
          stickyFlg
            ? { boxShadow: STICKY_BOX_SHADOW }
            : { boxShadow: STICKY_NONE_BOX_SHADOW }
        }
      >
        <UnEditableWrapper unEditable={props.recordDaily.isEditing}>
          <DateSelectButtonsDaily
            defaultDate={currentDate}
            min={calendarMinDate}
            max={calendarMaxDate}
            onClickSubmit={onClickCalendar}
          />
        </UnEditableWrapper>
        <div className={props.classes.buttons}>
          <Button
            className={props.classes.buttonShadowNone}
            variant="contained"
            color="secondary"
            disabled={noCreatedAt || props.recordDaily.isEditing}
            onClick={onPrintPreview}
          >
            印刷
          </Button>
        </div>
      </div>
      <div className={props.classes.CreateAndUpdateDateWrap}>
        <CreateAndUpdateDate
          createdAt={props.dailyOperationsAndSupports.created_at}
          updatedAt={props.dailyOperationsAndSupports.updated_at}
        />
      </div>
      {props.dailyOperationsAndSupports.support.length > 0 ? (
        <>
          <DailyOperationRecord
            operations={props.dailyOperationsAndSupports.operation}
            title="業務日誌"
            staffOptions={props.staffOptions}
            yyyymmdd={targetDay}
            summaries={props.dailyOperationsAndSupports.summary}
            customRecords={props.customRecords}
          />

          <div className={props.classes.wrapper}>
            <DailySupportsRecord
              pageName="支援記録"
              supportsRecord={props.dailyOperationsAndSupports.support}
              staff={props.staff}
              staffOptions={props.staffOptions}
              recordDaily={props.recordDaily}
              history={props.history}
              users={props.users}
              yyyymmdd={targetDay}
              customRecords={props.customRecords}
            />
          </div>
          {isOpenPrintModal && (
            <DailyPrintModal
              isModalOpen={isOpenPrintModal}
              onClose={onClosePrintModal}
              history={props.history}
              staffCommentLabel={staffCommentLabel}
              yyyymmdd={targetDay}
            />
          )}
          <NavigationTransitionPrompt />
        </>
      ) : (
        <Paper className={props.classes.noDataRoot} elevation={0}>
          <div className={props.classes.noData}>
            利用実績がありません。利用実績を入力後、ご利用ください。
          </div>
        </Paper>
      )}
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.TANKINYUSHO.facility,
  users: state.TANKINYUSHO.userInFacility.users,
  dailyOperationsAndSupports: state.TANKINYUSHO.dailyOperationsAndSupports,
  staff: state.staff,
  recordDaily: state.pages.recordDaily,
  customRecords: state.customRecords
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { pages, TANKINYUSHO } = dispatches;
  const recordDailyDispatcher = pages.recordDailyTANKINYUSHODispatcher(
    dispatch
  );
  const dailyOperationsAndSupportsDispatches = TANKINYUSHO.dailyOperationsAndSupportsDispatcher(
    dispatch
  );
  return {
    fetchDailyOperationsAndSupports: (yyyymmdd: string): Promise<void> => {
      return dailyOperationsAndSupportsDispatches.fetchDailyOperationsAndSupports(
        yyyymmdd
      );
    },
    fetchInitialDailyRecordTANKINYUSHO: (yyyymmdd: string): Promise<void> => {
      return recordDailyDispatcher.fetchInitialDailyRecord(yyyymmdd);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  return {
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(RecordDaily));
