import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate202104 } from "@components/templates/AdminTemplate202104";
import { EditUserForm } from "@components/v202104/organisms/mgr/DOKOENGO/Users/EditUserForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import {
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_OPTIONS_23
} from "@constants/variables";
import { INVOICE_PATHS_FUNCTIONS } from "@constants/mgr/DOKOENGO/variables";

type Props = RouteComponentProps<{ id: string }>;

/**
 * 報酬改定 v202104バージョン
 * 利用者情報 > 編集
 */
export const EditUserV202104 = (props: Props): JSX.Element => {
  return (
    <AdminTemplate202104
      pageName="利用者情報"
      invoiceVersion={{
        currentVersion: INVOICE_VERSION_V202104,
        versionPaths: INVOICE_PATHS_FUNCTIONS.user(props.match.params.id),
        versionOptions: INVOICE_VERSION_OPTIONS_23
      }}
    >
      <EditUserForm {...props} />
      <NavigationTransitionPrompt />
    </AdminTemplate202104>
  );
};
