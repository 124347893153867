import * as React from "react";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {
  ReportState,
  UsagePerformanceType,
  UsagePerformanceTANKINYUSHOType
} from "@stores/v202104/domain/mgr/TANKINYUSHO/report/types";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import UsagePerformanceTableMonthlyHeader from "@components/v202104/organisms/mgr/TANKINYUSHO/report/UsagePerformanceTableMonthlyHeader";
import UsagePerformanceReportMonthlyCell from "@components/v202104/organisms/mgr/TANKINYUSHO/report/cells/UsagePerformanceReportMonthlyCell";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row: {
      height: 48,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    sticky: {
      zIndex: 10,
      position: "sticky",
      height: 81,
      backgroundColor: "#fff",
      paddingTop: 24
    }
  });

type StateProps = {
  report: ReportState;
};

type OwnProps = {
  isEditing: boolean;
  headerHeight: number;
  openModal: (
    usagePerformance: UsagePerformanceType,
    usagePerformanceTANKINYUSHO: UsagePerformanceTANKINYUSHOType
  ) => void;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const UsagePerformanceMonthlyTable = (props: Props): JSX.Element => {
  const usagePerformance = props.isEditing
    ? props.report.reportMonthly.usagePerformance.after
    : props.report.reportMonthly.usagePerformance.before;
  const usagePerformanceTANKINYUSHO = props.isEditing
    ? props.report.reportMonthly.usagePerformanceTANKINYUSHO.after
    : props.report.reportMonthly.usagePerformanceTANKINYUSHO.before;
  const idList: string[] = Object.keys(usagePerformance);

  const TableList = idList.map((keyValue: string, index) => {
    return (
      <TableRow key={`table-row-${keyValue}`} className={props.classes.row}>
        <UsagePerformanceReportMonthlyCell
          usagePerformance={usagePerformance[keyValue]}
          usagePerformanceTANKINYUSHO={usagePerformanceTANKINYUSHO[keyValue]}
          keyValue={keyValue}
          idx={index}
          isEditing={props.isEditing}
          openModal={props.openModal}
        />
      </TableRow>
    );
  });

  const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

  return (
    <>
      <div className={props.classes.sticky} style={{ top: `${topHeight}px` }}>
        <Table key="monthly-report-table">
          <UsagePerformanceTableMonthlyHeader isEditing={props.isEditing} />
        </Table>
      </div>
      <Table key="monthly-report-table">
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者が登録されていません。
          <br />
          利用者情報画面で登録後、ご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    report: state.v202104.TANKINYUSHO.report
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(UsagePerformanceMonthlyTable)
);
