import { PostMonitoringMonth } from "@api/requests/monitoringMonth/postMonitoringMonth";
import { InitialMonitoringMonthValues } from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";
import { format } from "date-fns";
import { selectDateValueToDate } from "@utils/date";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";

export const normalizePostMonitoringMonthDataToAPI = (
  param: InitialMonitoringMonthValues,
  initialState: InitialMonitoringMonthValues
): PostMonitoringMonth => {
  const request: PostMonitoringMonth = {
    monitoring_month: []
  };
  param.monitoring_month
    .filter((v) => v.year_month.year !== "" && v.year_month.month !== "")
    .forEach((v) => {
      // 新規
      if (v.id === null && !v.is_delete) {
        request.monitoring_month.push({
          id: v.id,
          year_month: format(
            selectDateValueToDate({ ...v.year_month, day: "1" }),
            "YYYYMM"
          ),
          is_delete: booleanToNumber0or1(v.is_delete) || 0
        });
        // 更新
      } else if (
        initialState.monitoring_month.some((initial) => {
          return (
            v.id === initial.id &&
            selectDateValueToDate({ ...v.year_month, day: "1" }) !==
              selectDateValueToDate({ ...initial.year_month, day: "1" })
          );
        })
      ) {
        request.monitoring_month.push({
          id: v.id,
          year_month: format(
            selectDateValueToDate({ ...v.year_month, day: "1" }),
            "YYYYMM"
          ),
          is_delete: booleanToNumber0or1(v.is_delete) || 0
        });
        // 削除
      } else if (v.id !== null && v.is_delete) {
        request.monitoring_month.push({
          id: v.id,
          is_delete: booleanToNumber0or1(v.is_delete) || 0
        });
      }
    });
  return request;
};
