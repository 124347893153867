import {
  SupportLedger,
  CheckedItem
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import {
  RequestParam,
  RequestHistory,
  RequestRelativesInfo,
  RequestRelatedOrganization
} from "@api/requests/supportLedger/postSupportLedger";
import { selectDateValueToDate } from "@utils/date";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import { format } from "date-fns";
import isEqual from "lodash-es/isEqual";
import { FieldItem } from "@interfaces/ui/form";

type HistoryFormType =
  | NonNullable<
      SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["life_histories"]
    >[number]
  | NonNullable<
      SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["disability_histories"]
    >[number];

type RelativesInfoFormType = NonNullable<
  SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["relatives_info"]
>[number];

type RelatedOrganizationFormType = NonNullable<
  SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["related_organization"]
>[number];

/** 生活歴、障害・疾病歴のnormalize関数 */
const normalizeHistory = (
  currentValue: HistoryFormType,
  beforeValue?: HistoryFormType | null
): RequestHistory => {
  const history: RequestHistory = {
    id: currentValue.id,
    yyyymm: currentValue.yyyymm,
    matter: currentValue.matter,
    is_delete: booleanToNumber0or1(currentValue.isDelete)
      ? booleanToNumber0or1(currentValue.isDelete) || null
      : null
  };
  if (beforeValue) {
    if (history.yyyymm === beforeValue.yyyymm) delete history.yyyymm;
    if (history.matter === beforeValue.matter) delete history.matter;
  }
  return history;
};

/** 生活歴、障害・疾病歴のフィルター */
const filterHistory = (history: RequestHistory): boolean => {
  return (
    {}.hasOwnProperty.call(history, "yyyymm") ||
    {}.hasOwnProperty.call(history, "matter") ||
    history.is_delete === 1
  );
};

/** 家族・親族のnormalize関数 */
const normalizeRelativesInfo = (
  currentValue: RelativesInfoFormType,
  beforeValue?: RelativesInfoFormType | null
): RequestRelativesInfo => {
  const relativesInfo: RequestRelativesInfo = {
    id: currentValue.id,
    relationship: currentValue.relationship,
    name: currentValue.name,
    age: currentValue.age,
    profession: currentValue.profession,
    address: currentValue.address,
    contact_address: currentValue.contact_address,
    remarks: currentValue.remarks,
    is_delete: booleanToNumber0or1(currentValue.isDelete)
      ? booleanToNumber0or1(currentValue.isDelete) || null
      : null
  };
  if (beforeValue) {
    if (relativesInfo.relationship === beforeValue.relationship)
      delete relativesInfo.relationship;
    if (relativesInfo.name === beforeValue.name) delete relativesInfo.name;
    if (relativesInfo.age === beforeValue.age) delete relativesInfo.age;
    if (relativesInfo.profession === beforeValue.profession)
      delete relativesInfo.profession;
    if (relativesInfo.address === beforeValue.address)
      delete relativesInfo.address;
    if (relativesInfo.contact_address === beforeValue.contact_address)
      delete relativesInfo.contact_address;
    if (relativesInfo.remarks === beforeValue.remarks)
      delete relativesInfo.remarks;
  }
  return relativesInfo;
};

/** 家族・親族のフィルター */
const filterRelativesInfo = (relativesInfo: RequestRelativesInfo): boolean => {
  return (
    {}.hasOwnProperty.call(relativesInfo, "relationship") ||
    {}.hasOwnProperty.call(relativesInfo, "name") ||
    {}.hasOwnProperty.call(relativesInfo, "age") ||
    {}.hasOwnProperty.call(relativesInfo, "profession") ||
    {}.hasOwnProperty.call(relativesInfo, "address") ||
    {}.hasOwnProperty.call(relativesInfo, "contact_address") ||
    {}.hasOwnProperty.call(relativesInfo, "remarks") ||
    relativesInfo.is_delete === 1
  );
};

/** 関係機関のnormalize関数 */
const normalizeRelatedOrganization = (
  currentValue: RelatedOrganizationFormType,
  beforeValue?: RelatedOrganizationFormType | null
): RequestRelatedOrganization => {
  const relatedOrganization: RequestRelatedOrganization = {
    id: currentValue.id,
    target_type: currentValue.target_type,
    facility_name: currentValue.facility_name,
    kinds: currentValue.kinds,
    manager: currentValue.manager,
    contact_address: currentValue.contact_address,
    remarks: currentValue.remarks,
    is_delete: booleanToNumber0or1(currentValue.isDelete)
      ? booleanToNumber0or1(currentValue.isDelete) || null
      : null
  };
  if (beforeValue) {
    if (relatedOrganization.target_type === beforeValue.target_type)
      delete relatedOrganization.target_type;
    if (relatedOrganization.facility_name === beforeValue.facility_name)
      delete relatedOrganization.facility_name;
    if (relatedOrganization.kinds === beforeValue.kinds)
      delete relatedOrganization.kinds;
    if (relatedOrganization.manager === beforeValue.manager)
      delete relatedOrganization.manager;
    if (relatedOrganization.contact_address === beforeValue.contact_address)
      delete relatedOrganization.contact_address;
    if (relatedOrganization.remarks === beforeValue.remarks)
      delete relatedOrganization.remarks;
  }
  return relatedOrganization;
};

/** 関係機関のフィルター */
const filterRelatedOrganization = (
  relatedOrganization: RequestRelatedOrganization
): boolean => {
  return (
    {}.hasOwnProperty.call(relatedOrganization, "target_type") ||
    {}.hasOwnProperty.call(relatedOrganization, "facility_name") ||
    {}.hasOwnProperty.call(relatedOrganization, "kinds") ||
    {}.hasOwnProperty.call(relatedOrganization, "manager") ||
    {}.hasOwnProperty.call(relatedOrganization, "contact_address") ||
    {}.hasOwnProperty.call(relatedOrganization, "remarks") ||
    relatedOrganization.is_delete === 1
  );
};
export const normalizePostSupportLedgerDataToAPI = (
  initialSupportLedger: SupportLedger,
  supportLedger: SupportLedger,
  staffOptions: FieldItem[],
  isNew?: boolean,
  isCopy?: boolean
): RequestParam => {
  const custom_record: RequestParam["custom_record"] = [];

  const getIsIgnorePrintSupportIds = (isPrintSupportIds: {
    [supportId: number]: boolean;
  }): number[] => {
    const array = Object.keys(isPrintSupportIds)
      .filter((key) => !isPrintSupportIds[key])
      .map(Number);
    return array;
  };

  supportLedger.support_ledger.support_ledger_items.forEach((record) => {
    record.input.forEach((afterInput) => {
      const beforeRecord = initialSupportLedger.support_ledger.support_ledger_items.find(
        (a) =>
          a.custom_records_category_id === record.custom_records_category_id
      );
      const beforeInput = beforeRecord
        ? beforeRecord.input.find(
            (i) => i.custom_record_item_id === afterInput.custom_record_item_id
          )
        : null;
      if (!isEqual(afterInput, beforeInput)) {
        const reqInput: RequestParam["custom_record"][number] = {
          custom_records_category_id: record.custom_records_category_id,
          custom_record_item_id: afterInput.custom_record_item_id,
          custom_record_input_id: afterInput.id,
          input_data: afterInput.input_data
        };
        // input_type=5(radio)の場合
        if (
          "choiced_item_id" in afterInput &&
          afterInput.choiced_item_id !== undefined
        ) {
          if (afterInput.choiced_item_id === "") {
            reqInput.choiced_item_id = +afterInput.choiced_item_id;
            reqInput.checked = 0;
          } else {
            reqInput.choiced_item_id = +afterInput.choiced_item_id;
            reqInput.checked = 1;
          }
        }
        // input_type=3(職員のセレクトボックス)の場合
        if (afterInput.staff) {
          const staffId = Number(afterInput.staff.id);
          const targetStaff = staffOptions
            ? staffOptions.find(
                (staff) =>
                  staff.value === (afterInput.staff ? afterInput.staff.id : "")
              )
            : null;
          if (staffId === 0 && !targetStaff && afterInput.initialStaff) {
            // 未選択はchecked=0 choiced_staff_id=今まで設定していたID choiced_staff_name_snapshot=今まで設定していたスナップショット
            reqInput.checked = 0;
            reqInput.choiced_staff_id = Number(afterInput.initialStaff.id);
            reqInput.choiced_staff_name_snapshot = afterInput.initialStaff.name;
          } else {
            reqInput.checked = 1;
            reqInput.choiced_staff_id = staffId;
            reqInput.choiced_staff_name_snapshot = targetStaff
              ? targetStaff.label
              : "";
          }
          delete reqInput.input_data;
        }
        // input_type=2(checkbox)の場合
        if (
          "checkedItems" in afterInput &&
          afterInput.checkedItems !== undefined
        ) {
          const { checkedItems } = afterInput;
          const checkList = Object.keys(afterInput.checkedItems).map<
            RequestParam["custom_record"][number]
          >((key): RequestParam["custom_record"][number] => {
            // チェックボックス用の型が推論できないので、キャストを実装
            const checkedItem = checkedItems[key] as CheckedItem;
            return {
              custom_records_category_id: reqInput.custom_records_category_id,
              custom_record_item_id: reqInput.custom_record_item_id,
              custom_record_input_id: checkedItem.inputId,
              choiced_item_id: Number(key),
              checked: checkedItem.checked ? 1 : 0
            };
          });
          const lastChecked = checkList.shift();
          // 基本的に一つは存在するので、このif文は通る前提
          if (lastChecked) {
            delete reqInput.input_data;
            reqInput.custom_record_input_id =
              lastChecked.custom_record_input_id;
            reqInput.choiced_item_id = lastChecked.choiced_item_id;
            reqInput.checked = lastChecked.checked;
          }
          checkList.forEach((input) => {
            custom_record.push(input);
          });
        }
        // input_type=9(カスタム入力形式外)の場合
        if ("life_histories" in afterInput && afterInput.life_histories) {
          reqInput.input_data = "";
          const lifeHistories = afterInput.life_histories
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["life_histories"]
              >[number]
            >((lifeHistory) => {
              const beforeHistory =
                beforeInput &&
                beforeInput.life_histories &&
                beforeInput.life_histories.find((v) => v.id === lifeHistory.id);
              return normalizeHistory(lifeHistory, beforeHistory);
            })
            .filter(filterHistory);
          reqInput.life_histories = lifeHistories;
        }
        if (
          "disability_histories" in afterInput &&
          afterInput.disability_histories != null
        ) {
          reqInput.input_data = "";
          const disabilityHistories = afterInput.disability_histories
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["disability_histories"]
              >[number]
            >((disabilityHistory) => {
              const beforeHistory =
                beforeInput &&
                beforeInput.disability_histories &&
                beforeInput.disability_histories.find(
                  (v) => v.id === disabilityHistory.id
                );
              return normalizeHistory(disabilityHistory, beforeHistory);
            })
            .filter(filterHistory);
          reqInput.disability_histories = disabilityHistories;
        }
        if (
          "relatives_info" in afterInput &&
          afterInput.relatives_info != null
        ) {
          reqInput.input_data = "";
          const relativesInfo = afterInput.relatives_info
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["relatives_info"]
              >[number]
            >((info) => {
              const beforeInfo =
                beforeInput &&
                beforeInput.relatives_info &&
                beforeInput.relatives_info.find((v) => v.id === info.id);
              return normalizeRelativesInfo(info, beforeInfo);
            })
            .filter(filterRelativesInfo);
          reqInput.relatives_info = relativesInfo;
        }
        if (
          "related_organization" in afterInput &&
          afterInput.related_organization != null
        ) {
          reqInput.input_data = "";
          const relatedOrganization = afterInput.related_organization
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["related_organization"]
              >[number]
            >((organization) => {
              const beforeOrganization =
                beforeInput &&
                beforeInput.related_organization &&
                beforeInput.related_organization.find(
                  (v) => v.id === organization.id
                );
              return normalizeRelatedOrganization(
                organization,
                beforeOrganization
              );
            })
            .filter(filterRelatedOrganization);
          reqInput.related_organization = relatedOrganization;
        }
        custom_record.push(reqInput);
      }
      return false;
    });
  });
  // POST時にいつでも送るもの
  const request: RequestParam = {
    setting_type: 6,
    support_ledgers_id: supportLedger.support_ledger.support_ledgers_id,
    date_begin_in_support:
      selectDateValueToDate(
        supportLedger.support_ledger.date_begin_in_support
      ) !== ""
        ? format(
            selectDateValueToDate(
              supportLedger.support_ledger.date_begin_in_support
            ),
            "YYYYMMDD"
          ).slice(0, 6)
        : null,
    date_end_in_support:
      selectDateValueToDate(
        supportLedger.support_ledger.date_end_in_support
      ) !== ""
        ? format(
            selectDateValueToDate(
              supportLedger.support_ledger.date_end_in_support
            ),
            "YYYYMMDD"
          ).slice(0, 6)
        : null,
    is_ignore_print_support_ids: getIsIgnorePrintSupportIds(
      supportLedger.support_ledger.isPrintSupportIds
    ),
    custom_record
  };
  // POST時に変更があった時にだけ送るもの
  if (
    isNew ||
    isCopy ||
    !supportLedger.support_ledger.creation_date ||
    String(supportLedger.support_ledger.creation_date.year) !==
      String(initialSupportLedger.support_ledger.creation_date.year) ||
    String(supportLedger.support_ledger.creation_date.month) !==
      String(initialSupportLedger.support_ledger.creation_date.month) ||
    String(supportLedger.support_ledger.creation_date.day) !==
      String(initialSupportLedger.support_ledger.creation_date.day) ||
    !supportLedger.support_ledger.update_date ||
    String(supportLedger.support_ledger.update_date.year) !==
      String(initialSupportLedger.support_ledger.update_date.year) ||
    String(supportLedger.support_ledger.update_date.month) !==
      String(initialSupportLedger.support_ledger.update_date.month) ||
    String(supportLedger.support_ledger.update_date.day) !==
      String(initialSupportLedger.support_ledger.update_date.day)
  ) {
    // 初回作成日と最終更新日はセット
    request.creation_date = format(
      selectDateValueToDate(supportLedger.support_ledger.creation_date),
      "YYYY-MM-DD"
    );
    request.update_date = format(
      selectDateValueToDate(supportLedger.support_ledger.update_date),
      "YYYY-MM-DD"
    );
  }
  if (
    String(supportLedger.support_ledger.creation_staff) !==
    String(initialSupportLedger.support_ledger.creation_staff)
  ) {
    request.creation_staff = supportLedger.support_ledger.creation_staff
      ? Number(supportLedger.support_ledger.creation_staff)
      : null;
  }
  if (
    String(supportLedger.support_ledger.update_staff) !==
    String(initialSupportLedger.support_ledger.update_staff)
  ) {
    request.update_staff = supportLedger.support_ledger.update_staff
      ? Number(supportLedger.support_ledger.update_staff)
      : null;
  }
  if (
    String(supportLedger.support_ledger.is_ignore_print_support_ids) !==
    String(initialSupportLedger.support_ledger.is_ignore_print_support_ids)
  ) {
    request.is_ignore_print_support_ids =
      supportLedger.support_ledger.is_ignore_print_support_ids;
  }
  return request;
};
