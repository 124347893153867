import {
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_CURRENT
} from "@constants/variables";
import {
  FACILITY,
  FACILITY_202104,
  USERS_ID,
  USERS_ID_202104,
  REPORT_DAILY,
  REPORT_DAILY_202104,
  REPORT_USERS,
  REPORT_USERS_202104
} from "@constants/url";

export const KYOTAKUKAIGO_TYPE_LIST = [
  { label: "生活介護", value: "0" },
  { label: "共生型生活介護サービス（Ⅰ）", value: "1" },
  { label: "共生型生活介護サービス（Ⅱ）", value: "2" },
  { label: "基準該当生活介護サービス（Ⅰ）", value: "5" },
  { label: "基準該当生活介護サービス（Ⅱ）", value: "6" },
  { label: "経過的生活介護", value: "9" },
  { label: "居宅介護", value: "10" }
];

// 特定事業所
export enum SpecificOfficeType {
  "なし" = 0,
  "特定事業所加算（Ⅰ）", // 1
  "特定事業所加算（Ⅱ）", // 2
  "特定事業所加算（Ⅲ）", // 3
  "特定事業所加算（Ⅳ）" // 4
}

// 福祉・介護職員等特定処遇改善加算
export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE = {
  NONE: { label: "なし", value: "1", disabled: false },
  LEVEL1: { label: "特定処遇改善加算（Ⅰ）", value: "2", disabled: false },
  LEVEL2: { label: "特定処遇改善加算（Ⅱ）", value: "3", disabled: false }
};

export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST = [
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.NONE,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL2
];

export const LICENSE_LIST = {
  SELECT: { label: "選択してください", value: " " },
  NONE: { label: "減算なし", value: "0" },
  BASE: { label: "減算: 基礎研修課程修了者等", value: "1" },
  SEVERE: { label: "減算: 重度訪問介護研修修了者", value: "2" }
};

export const LICENSE_SELECT_LIST = [
  LICENSE_LIST.SELECT,
  LICENSE_LIST.NONE,
  LICENSE_LIST.BASE,
  LICENSE_LIST.SEVERE
];

export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LiST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

// サービス内容
export const KYOTAKUKAIGO_STATUS_LIST = {
  NONE: { label: "-", value: "1" },
  PHYSICAL: { label: "身体", value: "2" },
  OUTPATIENT_WITH_PHYSICAL: { label: "通院（伴う）", value: "3" },
  HOUSE_WORK: { label: "家事", value: "4" },
  OUTPATIENT: { label: "通院（伴ず）", value: "5" },
  GETTING_ON_AND_OFF: { label: "乗降", value: "6" }
};

export const KYOTAKUKAIGO_STATUS_SELECT_LIST = [
  KYOTAKUKAIGO_STATUS_LIST.NONE,
  KYOTAKUKAIGO_STATUS_LIST.PHYSICAL,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL,
  KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT,
  KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF
];

// 新規作成時の実績用の選択値
// ※緊急時対応加算対象のみ
export const KYOTAKUKAIGO_STATUS_SELECT_LIST_NEW = [
  KYOTAKUKAIGO_STATUS_LIST.NONE,
  KYOTAKUKAIGO_STATUS_LIST.PHYSICAL,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL
];

export const STANDARD_TIME_VALUE = {
  QUARTER: 0.25,
  HALF: 0.5
};

export const ENABLE_GETTING_ON_AND_OFF_TIMES_LIST = [
  KYOTAKUKAIGO_STATUS_LIST.PHYSICAL.value,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL.value,
  KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK.value,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT.value
];

// サービス内容(表示用)
export const KYOTAKUKAIGO_DISPLAY_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "身体", value: "10" },
  { label: "身体(基礎等)", value: "11" },
  { label: "身体(重訪)", value: "12" },
  { label: "通院(伴う)", value: "20" },
  { label: "通院(伴う)(基礎等)", value: "21" },
  { label: "通院(伴う)(重訪)", value: "22" },
  { label: "通院(伴ず)", value: "30" },
  { label: "通院(伴ず)(基礎等)", value: "31" },
  { label: "通院(伴ず)(重訪)", value: "32" },
  { label: "家事", value: "40" },
  { label: "家事(基礎等)", value: "41" },
  { label: "家事(重訪)", value: "42" },
  { label: "乗降", value: "50" },
  { label: "乗降(基礎等)", value: "51" },
  { label: "乗降(重訪)", value: "52" }
];

// サービス内容(記録画面表示用)
export const KYOTAKUKAIGO_DISPLAY_STATUS_LIST_RECORD = [
  { label: "-", value: "1" },
  { label: "身体介護", value: "2" },
  { label: "通院等介助（身体介護伴う）", value: "3" },
  { label: "家事援助", value: "4" },
  { label: "通院等介助（身体介護伴わず）", value: "5" },
  { label: "通院等乗降介助", value: "6" }
];

// サービス内容(記録画面詳細用)
export const KYOTAKUKAIGO_DISPLAY_STATUS_LIST_DETAIL = [
  { label: "選択してください", value: "1" },
  { label: "身体介護", value: "2" },
  { label: "通院等介助（身体介護伴う）", value: "3" },
  { label: "家事援助", value: "4" },
  { label: "通院等介助（身体介護伴わず）", value: "5" },
  { label: "通院等乗降介助", value: "6" }
];

export const KYOTAKUKAIGO_BASE_LIST = {
  NONE: { label: "-", value: "0" },
  SELECT: { label: "選択してください", value: "0" }
};

export const KYOTAKUKAIGO_LICENSE_LIST = {
  NONE: { label: "-", value: "1" },
  BASIC: { label: "基礎", value: "2" },
  SEVERE: { label: "重度", value: "3" }
};

export const KYOTAKUKAIGO_PLAN_CORE_WORKER_LICENSE_LIST = [
  KYOTAKUKAIGO_LICENSE_LIST.NONE,
  KYOTAKUKAIGO_LICENSE_LIST.BASIC,
  KYOTAKUKAIGO_LICENSE_LIST.SEVERE
];

// 資格項目のマッピングリスト（職員情報と実績のマッピング）
export const KYOTAKUKAIGO_LICENSE_MAPPING_LIST = {
  "0": KYOTAKUKAIGO_LICENSE_LIST.NONE.value,
  "1": KYOTAKUKAIGO_LICENSE_LIST.BASIC.value,
  "2": KYOTAKUKAIGO_LICENSE_LIST.SEVERE.value
};

export const KYOTAKUKAIGO_ACTION_CLASS_LIST = {
  DRIVE: { label: "運転", value: "1" },
  NONE: { label: "空き", value: "2" }
};

export const KYOTAKUKAIGO_ACTION_CLASS_SELECT_LIST = [
  KYOTAKUKAIGO_ACTION_CLASS_LIST.DRIVE,
  KYOTAKUKAIGO_ACTION_CLASS_LIST.NONE
];

export const KYOTAKUKAIGO_MEMBER_LIST = {
  ONE: { label: "1人", value: "1" },
  TWO: { label: "2人", value: "2" }
};

export const KYOTAKUKAIGO_MEMBER_SELECT_LIST = [
  KYOTAKUKAIGO_MEMBER_LIST.ONE,
  KYOTAKUKAIGO_MEMBER_LIST.TWO
];

export const KYOTAKUKAIGO_INPUT_CLASS_LIST = {
  PLAN: { label: "居宅介護計画", value: "1" },
  RESULT: {
    label: "サービス提供実績",
    value: "2"
  },
  CREATE: {
    label: "居宅介護計画/サービス提供実績",
    value: "0"
  }
};

export const KYOTAKUKAIGO_INPUT_CLASS_SELECT_LIST = [
  KYOTAKUKAIGO_INPUT_CLASS_LIST.PLAN,
  KYOTAKUKAIGO_INPUT_CLASS_LIST.RESULT
];

export const REPORT_FILTER_LIST = {
  NONE: { label: "すべての利用者", value: "1" },
  FILTER: { label: "計画または実績あり", value: "2" }
};

export const REPORT_FILTER_SELECT_LIST = [
  REPORT_FILTER_LIST.NONE,
  REPORT_FILTER_LIST.FILTER
];

export const SERVICE_TIME_ZONE = {
  MIDNIGHT: { start: "22:00", startClass: "0", end: "06:00", endClass: "1" },
  MORNING: { start: "06:00", startClass: "0", end: "08:00", endClass: "0" },
  DAYTIME: { start: "08:00", startClass: "0", end: "18:00", endClass: "0" },
  NIGHT: { start: "18:00", startClass: "0", end: "22:00", endClass: "0" }
};

export const SERVICE_TIME_ZONE_LIST = [
  SERVICE_TIME_ZONE.MIDNIGHT,
  SERVICE_TIME_ZONE.MORNING,
  SERVICE_TIME_ZONE.DAYTIME,
  SERVICE_TIME_ZONE.NIGHT
];

export const SERVICE_TIME_ZONE_LIST_SEPARATOR_TIME = [
  SERVICE_TIME_ZONE.MORNING.start,
  SERVICE_TIME_ZONE.DAYTIME.start,
  SERVICE_TIME_ZONE.NIGHT.start,
  SERVICE_TIME_ZONE.MIDNIGHT.start
];

export const PROCESS_TYPE = {
  ALL: 1,
  ALL_ERROR: 2,
  NEW_ONLY: 3
};

export const INOUT_RESULTS_COPY_ERROR_STATUS = 17;

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

export const KYOTAKUKAIGO_DAY_OF_WEEK_LIST = [
  { label: "月", value: "1" },
  { label: "火", value: "2" },
  { label: "水", value: "3" },
  { label: "木", value: "4" },
  { label: "金", value: "5" },
  { label: "土", value: "6" },
  { label: "日", value: "7" }
];

export const KYOTAKUKAIGO_DISPATCH_NO_LIST = [
  { label: "①", value: "1" },
  { label: "②", value: "2" }
];

// custom_recordのカテゴリータイプの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_CATEGORY_TYPE = {
  BASIC: 1,
  PHYSICAL: 2,
  HOSPITAL: 3,
  HOUSE_WORK: 4,
  SPECIAL: 5
};

// custom_recordのdefault_itemの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO = {
  vital: 1,
  meal: 3,
  body_care: 12,
  in_hospital_assistance: 16,
  menu: 22,
  cost: 29,
  deposit: 30,
  basic_info: 1,
  staff_comment: 33
};

// カスタムレコード（選択式（入力項目））のインプットタイプ
export const MIXUTER_INPUT_TYPE = {
  checkbox: 2,
  text: 4
};

// カスタムレコード（選択式（入力項目））の開始時間・終了時間
export const MIXUTER_INPUT_TYPE_DEFAULT_CHOICE = {
  start: 1,
  end: 2,
  destination: 3
};

// サービス提供記録のデフォルト選択タイプ
export const SERVICE_CUSTOM_RECORD_DEFAULT_CHOICE_INPUT_TYPE = {
  time: 4
};

export const PRACTITIONER_SNAPSHOT_OPTION_VALUE = "0";

export const HOSPITAL_STATUS_VALUES = [
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT.value,
  KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL.value,
  KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
];

export const SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM = {
  SELECT: { label: "選択してください", value: "1" },
  PHYSICAL_CARE: { label: "身体介護", value: "2" },
  WITH_PHYSICAL_CARE: {
    label: "通院等介助\n(身体介護伴う)",
    value: "3"
  },
  HOUSEWORK_ASSISTANCE: { label: "家事援助", value: "4" },
  WITHOUT_PHYSICAL_CARE: { label: "通院等介助\n(身体介護伴わず)", value: "5" },
  GETTING_ON_AND_OFF_ASSISTANCE: { label: "通院等乗降介助", value: "6" }
};
export const SUPPORT_CARE_PLAN_SCHEDULES_STATUS = [
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.SELECT,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.WITH_PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.HOUSEWORK_ASSISTANCE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.WITHOUT_PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.GETTING_ON_AND_OFF_ASSISTANCE
];

export const SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT = {
  PHYSICAL_CARE: SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.PHYSICAL_CARE,
  WITH_PHYSICAL_CARE: {
    label: "通院(伴う)",
    value: SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.WITH_PHYSICAL_CARE.value
  },
  HOUSEWORK_ASSISTANCE:
    SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.HOUSEWORK_ASSISTANCE,
  WITHOUT_PHYSICAL_CARE: {
    label: "通院(伴わず)",
    value: SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.WITHOUT_PHYSICAL_CARE.value
  },
  GETTING_ON_AND_OFF_ASSISTANCE: {
    label: "乗降介助",
    value:
      SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.GETTING_ON_AND_OFF_ASSISTANCE
        .value
  }
};
export const SUPPORT_CARE_PLAN_SCHEDULES_STATUS_PRINT = [
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT.PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT.WITH_PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT.HOUSEWORK_ASSISTANCE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT.WITHOUT_PHYSICAL_CARE,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_PRINT.GETTING_ON_AND_OFF_ASSISTANCE
];

export const TIME_CLASS_LIST = [
  { label: "当日", value: "0" },
  { label: "翌日", value: "1" }
];

export const IN_TIME_VALIDATION_LIST = [
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の居宅介護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください",
  "複数の乗降回数を含む実績が、早朝/日中/夜間/深夜の時間帯をまたいで登録されています。時間帯による加算を算定したい場合は、実績を分けて登録してください",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の居宅介護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const DETAIL_IN_TIME_VALIDATION_LIST = [
  "空き/運転時間は提供時間の範囲内で入力してください",
  "空き/運転時間は同一時間帯で重複しないように入力してください"
];

export const DETAIL_OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "空き/運転時間は提供時間の範囲内で入力してください",
  "空き/運転時間は同一時間帯で重複しないように入力してください",
  "空き/運転時間が2時間を超えています"
];

export const NO_RECORD_MESSAGE_SERVICE_DELIVERY =
  "計画がありません。利用実績画面から入力後、ご利用ください。";

export const NO_USER_MESSAGE_SERVICE_DELIVERY =
  "利用者が登録されていません。利用者情報画面で登録後、ご利用ください。";

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";

/** 居宅介護の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
