import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikTextField from "@components/molecules/FormikTextField";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";

const styles = (): StyleRules =>
  createStyles({
    root: {
      position: "relative"
    },
    tooltip: {
      position: "absolute",
      left: 70,
      top: -5
    }
  });

type OwnProps = {
  type: "keikaku" | "shogaiji";
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 事業者番号 & 事業所名
 * - 事業者番号は常に非活性
 * - basic.officeNumberとbasic.officeNameが定義されたformik配下の利用が前提
 */
const OfficeField = (props: Props): JSX.Element => {
  return props.type === "keikaku" ? (
    <FormGroup row className={props.classes.root}>
      <FormikTextField
        name="facilityInfoKeikaku.officeNumber"
        label="事業所番号"
        placeholder="1234567890"
        maxLength={10}
        disabled
        disabledStyle
      />
      <span className={props.classes.tooltip}>
        <HelpToolTip title={<HelpTipMessages name="officeNumber" />} />
      </span>
      <FormikTextField
        name="facilityInfoKeikaku.officeName"
        label="事業所名"
        placeholder="ノウビー"
        maxLength={255}
        size="halfSuperLong"
      />
    </FormGroup>
  ) : (
    <FormGroup row className={props.classes.root}>
      <FormikTextField
        name="facilityInfoShogaiji.officeNumber"
        label="事業所番号"
        placeholder="1234567890"
        maxLength={10}
        disabled
        disabledStyle
      />
      <span className={props.classes.tooltip}>
        <HelpToolTip title={<HelpTipMessages name="officeNumber" />} />
      </span>
      <FormikTextField
        name="facilityInfoShogaiji.officeName"
        label="事業所名"
        placeholder="ノウビー"
        maxLength={255}
        size="halfSuperLong"
      />
    </FormGroup>
  );
};

export default withStyles(styles)(OfficeField);
