import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InOutReportUserHeader } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/report/InOutReportMonthlyHeader";
import { InOutReportTable } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import { InOutReportPaperHeader } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/report/InOutReportPaperHeader";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  REPEAT_MONTHLY,
  ReportState,
  ReportSummary,
  InoutResultsState,
  DeleteReportState,
  CopyReportState,
  CopyReportErrorState
} from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { OptionInterface } from "@components/atoms/DropDown";
import { InOutReportDialog } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/report/InOutReportDialog";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import {
  InitialDataValues,
  initialValues
} from "@initialize/v202104/mgr/KYOTAKUKAIGO/report/initialValues";
import {
  KYOTAKUKAIGO_INPUT_CLASS_LIST,
  PROCESS_TYPE,
  INOUT_RESULTS_COPY_ERROR_STATUS
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import Button from "@material-ui/core/Button";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { ResponseError } from "@stores/ui/type";
import { CarePlanStaffState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/carePlan/types";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    clear: {
      clear: "both"
    },
    headerWrapper: {
      position: "sticky",
      top: NOTICE_HEADER_HEIGHT,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    button: {
      marginLeft: 10,
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4
    },
    abolitionChip: {
      paddingLeft: 20,
      width: 160
    },
    flex: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between"
    },
    bodyRestraint: {
      paddingBottom: spacing.unit * 2
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
  carePlanStaffState: CarePlanStaffState;
};

type DispatchProps = {
  fetchUser: () => Promise<void>;
  fetchFacility: () => void;
  fetchReportUser: (uifId: number, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  deleteInoutResults: (target: DeleteReportState) => Promise<void>;
  copyInoutResults: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  copyCarePlan: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  fetchCarePlan: (uifId: string) => Promise<void>;
  responseErrorClear(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  selectedMonth: Date;
  selectedUser: OptionInterface;
  headerHeight: number;
  isOpenDetailModal: boolean;
  data: InitialDataValues;
  selectedDate: Date;
  modalTarget: string;
  isOpenDeleteDialog: boolean;
  isOpenNonDeleteDialog: boolean;
  deleteTarget: DeleteReportState | null;
  isOpenCopyConfirmDialog: boolean;
  copyReportListTarget: CopyReportState[] | null;
  isOpenCopyCarePlanConfirmDialog: boolean;
  isOpenAlreadyResultDialog: boolean;
  copyCarePlanTarget: CopyReportState[] | null;
};

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績(ユーザごと）
 */
class InOutReportMonthlyCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMonth: this.props.initialDate,
      selectedUser: { label: "", value: "" },
      headerHeight: 0,
      isOpenDetailModal: false,
      data: initialValues(null, this.props.usersInFacilityState),
      selectedDate: this.props.initialDate,
      modalTarget: KYOTAKUKAIGO_INPUT_CLASS_LIST.CREATE.label,
      isOpenDeleteDialog: false,
      isOpenNonDeleteDialog: false,
      deleteTarget: null,
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null,
      isOpenCopyCarePlanConfirmDialog: false,
      isOpenAlreadyResultDialog: false,
      copyCarePlanTarget: null
    };
  }

  public componentDidMount(): void {
    Promise.all([
      this.props.fetchFacility(),
      this.props.fetchInoutError(this.state.selectedMonth)
    ]);
  }

  public componentDidUpdate(): void {
    const top = document.getElementById("reportDailyHeader");
    if (top && top.clientHeight !== this.state.headerHeight) {
      const setClientHeight = (): void => {
        this.setState({
          headerHeight: top.clientHeight
        });
      };
      setClientHeight();
    }
  }

  private onChangeMonth = (date: Date, user: OptionInterface): void => {
    this.setState({ selectedMonth: date, selectedUser: user });
    this.props.fetchReportUser(+user.value, date);
    this.props.fetchInoutError(date);
    if (user.value !== "") {
      this.props.fetchUserInFacility(user.value.toString());
    }
  };

  private onChangeUser = (user: OptionInterface): void => {
    this.setState({ selectedUser: user });
    this.props.fetchReportUser(+user.value, this.state.selectedMonth);
    this.props.fetchUserInFacility(user.value.toString());
    this.props.fetchInoutError(this.state.selectedMonth);
    this.props.fetchCarePlan(user.value.toString());
  };

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  private openModal = (param: InoutResultsState, inputClass: string): void => {
    this.setState({
      isOpenDetailModal: true,
      data: initialValues(param, this.props.usersInFacilityState),
      selectedDate: convertBlankSeparatorFormatToDate(param.targetDate)
    });
    if (inputClass === KYOTAKUKAIGO_INPUT_CLASS_LIST.PLAN.value) {
      this.setState({ modalTarget: KYOTAKUKAIGO_INPUT_CLASS_LIST.PLAN.label });
    } else {
      this.setState({
        modalTarget: KYOTAKUKAIGO_INPUT_CLASS_LIST.RESULT.label
      });
    }
    this.props.fetchUserInFacility(param.usersInFacilityId.toString());
  };

  private openCreateModal = (date: string): void => {
    this.setState({ isOpenDetailModal: true });

    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues(null, this.props.usersInFacilityState);
    init.initial.targetDate = date;
    init.initial.name = this.state.selectedUser.label;
    init.initial.usersInFacilityId = this.state.selectedUser.value.toString();

    this.setState({
      data: init,
      selectedDate: convertBlankSeparatorFormatToDate(date),
      modalTarget: KYOTAKUKAIGO_INPUT_CLASS_LIST.CREATE.label
    });
    this.props.fetchUserInFacility(this.state.selectedUser.value.toString());
  };

  private onCancel = (): void => {
    this.setState({ isOpenDetailModal: false });
  };

  private onSubmit = (): void => {
    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);
    this.props.fetchCarePlan(this.state.selectedUser.value.toString());
  };

  private onClickDeleteIcon = (target: DeleteReportState): void => {
    this.setState({ deleteTarget: target, isOpenDeleteDialog: true });
  };

  private openNonDeleteDialog = (): void => {
    this.setState({ isOpenNonDeleteDialog: true });
  };

  private onDelete = async (): Promise<void> => {
    if (this.state.deleteTarget !== null) {
      await this.props.deleteInoutResults(this.state.deleteTarget);
      this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
    }

    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);
    this.props.fetchCarePlan(this.state.selectedUser.value.toString());
  };

  private onCancelDelete = (): void => {
    this.setState({
      deleteTarget: null,
      isOpenDeleteDialog: false,
      isOpenNonDeleteDialog: false
    });
  };

  private onClickCopyCarePlanIcon = async (): Promise<void> => {
    const copyList = this.props.reportState.reportMonthly.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyCarePlan(PROCESS_TYPE.ALL_ERROR, copyList);
    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyCarePlanTarget: copyList,
        isOpenCopyCarePlanConfirmDialog: true
      });
    } else {
      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
      this.props.fetchCarePlan(this.state.selectedUser.value.toString());
    }
  };

  private onCopyCarePlan = async (): Promise<void> => {
    if (this.state.copyCarePlanTarget !== null) {
      await this.props.copyCarePlan(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyCarePlanTarget
      );

      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
      this.props.fetchCarePlan(this.state.selectedUser.value.toString());
    }

    this.setState({
      isOpenCopyCarePlanConfirmDialog: false,
      copyCarePlanTarget: null
    });
  };

  private onClickCopyReportListIcon = async (): Promise<void> => {
    const copyList = this.props.reportState.reportMonthly.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, copyList);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyReportListTarget: copyList,
        isOpenCopyConfirmDialog: true
      });
    } else {
      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
    }
  };

  private onCopyReportList = async (): Promise<void> => {
    if (this.state.copyReportListTarget !== null) {
      await this.props.copyInoutResults(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyReportListTarget
      );

      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
      this.props.fetchCarePlan(this.state.selectedUser.value.toString());
    }

    this.setState({
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null
    });
  };

  private onCopyRecord = async (target: CopyReportState): Promise<void> => {
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, [target]);
    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({ isOpenAlreadyResultDialog: true });
    }
    this.props.fetchCarePlan(this.state.selectedUser.value.toString());
  };

  private onCloseCopyDialog = (): void => {
    this.setState({
      isOpenCopyConfirmDialog: false,
      isOpenCopyCarePlanConfirmDialog: false,
      isOpenAlreadyResultDialog: false
    });
  };

  /**
   * RectangleBoxが受け付けるconfigを生成する
   */
  private genRectangleBoxConfig = (
    title: string,
    num: number | null | undefined,
    denom?: number,
    unit = "時間"
  ): {
    title: string;
    denom: number | undefined;
    num: number;
    unit: string;
  } => {
    return { title, denom, num: num || 0, unit };
  };

  private createRectangleList = (
    summary: ReportSummary,
    usersInFacilityState: UsersInFacilityState
  ): {
    title: string;
    denom: number | undefined;
    num: number;
    unit: string;
  }[] => {
    const userInFacility =
      usersInFacilityState.user.user_in_facility_kyotakukaigo;
    return [
      this.genRectangleBoxConfig(
        "身体",
        summary.physicalCareCount,
        userInFacility && userInFacility.pay_months_agreed_physical_care
          ? userInFacility.pay_months_agreed_physical_care
          : undefined
      ),
      this.genRectangleBoxConfig(
        "通院（伴う）",
        summary.outpatientCareWithPhysicalCareCount,
        userInFacility &&
          userInFacility.pay_months_agreed_outpatient_care_with_physical_care
          ? userInFacility.pay_months_agreed_outpatient_care_with_physical_care
          : undefined
      ),
      this.genRectangleBoxConfig(
        "家事",
        summary.houseworkAssistanceCount,
        userInFacility && userInFacility.pay_months_agreed_housework_assistance
          ? userInFacility.pay_months_agreed_housework_assistance
          : undefined
      ),
      this.genRectangleBoxConfig(
        "通院（伴ず）",
        summary.outpatientCareCount,
        userInFacility && userInFacility.pay_months_agreed_outpatient_care
          ? userInFacility.pay_months_agreed_outpatient_care
          : undefined
      ),
      this.genRectangleBoxConfig(
        "乗降",
        summary.gettingOnAndOffCount,
        userInFacility && userInFacility.pay_months_agreed_getting_on_and_off
          ? userInFacility.pay_months_agreed_getting_on_and_off
          : undefined,
        "回"
      )
    ];
  };

  public render(): JSX.Element {
    const { classes, inoutErrors } = this.props;
    const { selectedMonth, selectedUser, headerHeight } = this.state;
    return (
      <>
        <div id="reportDailyHeader" className={classes.headerWrapper}>
          {inoutErrors.hasError && (
            <InvoiceErrorBar
              message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
              onClick={this.onClickErrorDialog}
            />
          )}
          <div className={classes.headerInfoContainer}>
            <InOutReportUserHeader
              minDate={minDate}
              maxDate={maxDate}
              selectedMonth={selectedMonth}
              selectedUserId={selectedUser.value}
              history={this.props.history}
              currentPageVersion={this.props.currentPageVersion}
              onChangeMonth={this.onChangeMonth}
              onChangeUser={this.onChangeUser}
            />
          </div>
        </div>
        <Paper elevation={0} className={classes.tableContainer}>
          <InOutReportPaperHeader
            rectangleConfigList={this.createRectangleList(
              this.props.reportState.reportMonthly.summary,
              this.props.usersInFacilityState
            )}
            type={REPEAT_MONTHLY}
            onClickCopyReportListIcon={this.onClickCopyReportListIcon}
            onClickCopyCarePlanIcon={this.onClickCopyCarePlanIcon}
            disabledCopyCarePlan={
              this.props.carePlanStaffState.carePlanCount === 0
            }
            disabledCopy={this.props.reportState.reportMonthly.planCount === 0}
          />
          <InOutReportTable
            headerHeight={headerHeight}
            openModal={this.openModal}
            openCreateModal={this.openCreateModal}
            onClickDeleteIcon={this.onClickDeleteIcon}
            openNonDeleteDialog={this.openNonDeleteDialog}
            onCopyRecord={this.onCopyRecord}
            type={REPEAT_MONTHLY}
            filterFlg={false}
            usersInFacilityState={this.props.usersInFacilityState}
          />
        </Paper>
        <InOutReportDialog
          open={this.state.isOpenDetailModal}
          facilityList={
            this.props.reportState.reportMonthly.displayFacilityUnits
          }
          staffList={
            this.props.reportState.reportMonthly.displayStaffsInFacility
          }
          usersInFacilityState={this.props.usersInFacilityState}
          data={this.state.data}
          selectedDate={this.state.selectedDate}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          type={REPEAT_MONTHLY}
          modalTarget={this.state.modalTarget}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDeleteDialog}
          onDelete={this.onDelete}
          onCancel={this.onCancelDelete}
          title=""
          message="実績を削除しますか?"
        />
        <MessageDialog
          isOpen={this.state.isOpenNonDeleteDialog}
          title=""
          message="実績があるため削除できません"
          closeButton={
            <Button
              onClick={this.onCancelDelete}
              className={classes.dialogActionButton}
            >
              OK
            </Button>
          }
        />
        <MessageDialog
          isOpen={this.state.isOpenAlreadyResultDialog}
          title=""
          message="実績がすでに登録されています"
          closeButton={
            <Button
              onClick={this.onCloseCopyDialog}
              className={classes.dialogActionButton}
            >
              OK
            </Button>
          }
        />
        <ConfirmDialog
          isOpen={this.state.isOpenCopyCarePlanConfirmDialog}
          onDelete={this.onCopyCarePlan}
          onCancel={this.onCloseCopyDialog}
          submitLabel="コピーする"
          title=""
          message="当月にすでに実績が登録されているデータが存在します。登録されていない日のみ計画をコピーしますか？"
        />
        <ConfirmDialog
          isOpen={this.state.isOpenCopyConfirmDialog}
          onDelete={this.onCopyReportList}
          onCancel={this.onCloseCopyDialog}
          submitLabel="コピーする"
          title=""
          message="当月にすでに実績が登録されているデータが存在します。登録されていない日のみ計画をコピーしますか？"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.v202104.KYOTAKUKAIGO.report,
    usersInFacilityState: state.v202104.KYOTAKUKAIGO.userInFacility,
    inoutErrors: state.errors.inout,
    responseError: state.ui.responseError,
    carePlanStaffState: state.v202104.KYOTAKUKAIGO.carePlan
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, v202104, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = v202104.KYOTAKUKAIGO.reportDispatcher(dispatch);
  const uifDispatcher = v202104.KYOTAKUKAIGO.userInFacilityDispatcher(dispatch);
  return {
    fetchUser: userDispatch(dispatch).me,
    fetchFacility: v202104.KYOTAKUKAIGO.facilityDispatcher(dispatch).fetch,
    fetchReportUser: (uifId: number, date: Date): Promise<void> =>
      reportDispatcher.fetchKYOTAKUKAIGOUsers(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    deleteInoutResults: (target: DeleteReportState): Promise<void> =>
      reportDispatcher.deleteKYOTAKUKAIGOReport(target),
    copyInoutResults: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postKYOTAKUKAIGOReportCopy(processType, target),
    copyCarePlan: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postKYOTAKUKAIGOReportCopyCarePlan(processType, target),
    fetchCarePlan: (uifId: string): Promise<void> =>
      v202104.KYOTAKUKAIGO.carePlanDispatcher(dispatch).fetchCarePlan(uifId),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportMonthlyCore));
