import * as React from "react";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import {
  TableMultiHead,
  HeaderProps
} from "@components/molecules/TableMultiHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { InOutReportTableCells } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportTableCells";
import { AppState } from "@stores/type";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import {
  ReportState,
  InoutConsultationResultsState,
  KEIKAKUSODANReportTypeInterface,
  REPORT_DAILY,
  REPORT_MONTHLY
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import classNames from "classnames";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableLayout: {
      width: "100%",
      minWidth: 1030
    },
    rowDaily: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    rowUser: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    middleShortCell: {
      minWidth: 60,
      width: 60,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 8,
      paddingRight: 8
    },
    middleCell: {
      minWidth: 120,
      width: 120,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 4,
      paddingRight: 4
    },
    middleLongCell: {
      minWidth: 190,
      width: 190,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 4,
      paddingRight: 4
    },
    longCell: {
      minWidth: 260,
      width: 260,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 4,
      paddingRight: 4
    },
    longCellFirst: {
      minWidth: 100,
      width: 100,
      boxSizing: "content-box",
      lineHeight: "28px",
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 8,
      paddingLeft: 16
    },
    daily: {
      paddingTop: 4,
      paddingBottom: 4
    },
    user: {
      paddingTop: 4,
      paddingBottom: 4
    },
    headerFontSize: {
      fontSize: 14
    }
  });

type StateProps = {
  reportState: ReportState;
};
type OwnProps = {
  headerHeight: number;
  openModal: (param: InoutConsultationResultsState) => void;
  openCreateModal: (uifName: string, uifId: number) => void;
  type: KEIKAKUSODANReportTypeInterface["type"];
  filterFlg: boolean;
  usersInFacilityState: UsersInFacilityState;
  facility?: FacilityState;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const reportDailyHeader = (
  classes: Props["classes"],
  type: KEIKAKUSODANReportTypeInterface["type"]
): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    rowStyle: [
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 32
      },
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 56
      }
    ],
    items: [
      [
        {
          align: "left",
          label: type === REPORT_DAILY ? "利用者名" : "日付",
          className: `${classes.longCellFirst} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "サービス種類",
          className: `${classes.middleLongCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "サービス内容",
          className: `${classes.middleLongCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "初回加算",
          className: `${classes.middleCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              集中支援
              <br />
              加算
            </>
          ),
          className: `${classes.middleShortCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "居宅介護支援事業所等連携加算/保育・教育等移行支援加算",
          className: `${classes.middleCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "その他加算・減算",
          className: `${classes.longCell} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "",
          className: `${classes.superShortIconCell} ${classes.headerFontSize} ${classes.headerFontSize}`
        }
      ]
    ]
  };
};

const InOutReportTableCore = (props: Props): JSX.Element => {
  const { reportState, type } = props;
  const tableHead = reportDailyHeader(props.classes, props.type);

  // 月表示する際はサービス種類を非表示（テーブルヘッダー）
  if (props.type === REPORT_MONTHLY) {
    tableHead.items[0].splice(1, 1);
  }

  const rows =
    type === REPORT_DAILY
      ? reportState.reportDaily.inoutConsultationResults
      : reportState.reportMonthly.inoutConsultationResults;
  const TableList = rows
    .filter((row) => {
      return !props.filterFlg || !(row.status === null || row.status === 0);
    })
    .map((row, index) => {
      const idx = `${index}`;
      const border = index !== 0 ? props.classes.border : "";
      return (
        <TableRow
          key={`table-row-${idx}`}
          className={classNames(
            props.type === REPORT_DAILY
              ? props.classes.rowDaily
              : props.classes.rowUser,
            border
          )}
        >
          <>
            {props.type === REPORT_DAILY && (
              /* 利用者名 */
              <TableCellWrap
                key={`${idx}-sei-mei`}
                cellClass={props.classes.longCellFirst}
              >
                {row.nameSei}
                <> </>
                {row.nameMei}
              </TableCellWrap>
            )}
            {props.type === REPORT_MONTHLY && (
              /* 日付 */
              <TableCellWrap
                key={`${idx}-target-date`}
                cellClass={props.classes.longCellFirst}
              >
                <CustomDateLabel
                  date={row.targetDate ? row.targetDate : ""}
                  dateFormat="Do（dd）"
                  holiday={row.isHoliday}
                />
              </TableCellWrap>
            )}
            <InOutReportTableCells
              params={row}
              idx={`${index}`}
              openModal={props.openModal}
              classOption={
                type === REPORT_DAILY ? props.classes.daily : props.classes.user
              }
              usersInFacilityState={props.usersInFacilityState}
              facility={props.facility}
              reportType={props.type}
            />
          </>
        </TableRow>
      );
    });

  const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

  return (
    <>
      <Table key="monthly-report-table" className={props.classes.tableLayout}>
        <TableMultiHead
          role={tableHead.role}
          ariaChecked={tableHead.ariaChecked}
          tabIndex={tableHead.tabIndex}
          key={tableHead.key}
          selected={tableHead.selected}
          items={tableHead.items}
          rowStyle={tableHead.rowStyle}
          headerStyle={{
            zIndex: 10,
            position: "sticky",
            top: `${topHeight}px`
          }}
        />
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者の情報が登録されていません。
          <br />
          利用者情報ページで利用者を登録した後にご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    reportState: state.v202104.KEIKAKUSODAN.report
  };
};

export const InOutReportTable = connect(mapStateToProps)(
  withStyles(styles)(InOutReportTableCore)
);
