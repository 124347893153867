import { ConsultationValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { TIME_CLASS_LIST } from "@constants/variables";

type ConsultationErrors = ValidationErrors<ConsultationValues>;

// 削除済みでなければバリデーションをかける
const isDeleteValidateSwitcher = (
  detail: ConsultationValues["supportPlanConsultationDetails"][0],
  value: string
): Exclude<
  Exclude<ConsultationErrors["supportPlanConsultationDetails"], undefined>[0],
  undefined
>["is_delete"] => {
  return validateSwitcher(
    !detail.is_delete,
    validator(value, {
      type: "checkCharacterLength",
      length: 2500
    })
  );
};

// 計画の詳細バリデーション
export const supportPlanConsultationDetailsValidation = (
  detailValues: ConsultationValues["supportPlanConsultationDetails"]
): ConsultationErrors["supportPlanConsultationDetails"] => {
  return detailValues.map((detail) => {
    return {
      needs: isDeleteValidateSwitcher(detail, detail.needs),
      support_goal: isDeleteValidateSwitcher(detail, detail.support_goal),
      achievement_time: isDeleteValidateSwitcher(
        detail,
        detail.achievement_time
      ),
      welfare_service_type: isDeleteValidateSwitcher(
        detail,
        detail.welfare_service_type
      ),
      provider_name: isDeleteValidateSwitcher(detail, detail.provider_name),
      role_in_problem_solving: isDeleteValidateSwitcher(
        detail,
        detail.role_in_problem_solving
      ),
      evaluation_time: isDeleteValidateSwitcher(detail, detail.evaluation_time),
      other_considerations: isDeleteValidateSwitcher(
        detail,
        detail.other_considerations
      )
    };
  });
};

// 週間計画表バリデーション
export const supportPlanConsultationScheduleValidation = (
  scheduleValues: ConsultationValues["supportPlanConsultationSchedule"]
): ConsultationErrors["supportPlanConsultationSchedule"] => {
  return scheduleValues.map((detail) => {
    return {
      start_time: validateSwitcher(
        !detail.is_delete,
        validator(detail.start_time, "checkTime")
      ),
      end_time:
        detail.next_day_flg === TIME_CLASS_LIST[0].value
          ? validateSwitcher(
              !detail.is_delete,
              validator(detail.end_time, "checkTime", {
                type: "checkTimeFuture",
                startTime: detail.start_time
              })
            )
          : validateSwitcher(
              !detail.is_delete,
              validator(detail.end_time, "checkTime")
            ),
      content: validateSwitcher(
        !detail.is_delete,
        validator(detail.content, {
          type: "checkCharacterLength",
          length: 2500
        })
      )
    };
  });
};

export const validation = (values: ConsultationValues): ConsultationErrors => {
  return {
    creationDate: dateValidator(values.creationDate, "required"),
    disabledWelfareServiceRecipientNumber: validator(
      values.disabledWelfareServiceRecipientNumber,
      "naturalNumber"
    ),
    regularPlaceRecipientNumber: validator(
      values.regularPlaceRecipientNumber,
      "naturalNumber"
    ),
    consultationSupportRecipientNumber: validator(
      values.consultationSupportRecipientNumber,
      "naturalNumber"
    ),
    desiredLife: validator(values.desiredLife, {
      type: "checkCharacterLength",
      length: 2500
    }),
    supportPolicy: validator(values.supportPolicy, {
      type: "checkCharacterLength",
      length: 2500
    }),
    longTermGoal: validator(values.longTermGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    shortTermGoal: validator(values.shortTermGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    supportPlanConsultationDetails: supportPlanConsultationDetailsValidation(
      values.supportPlanConsultationDetails
    ),
    supportPlanConsultationSchedule: supportPlanConsultationScheduleValidation(
      values.supportPlanConsultationSchedule
    ),
    mainActivities: validator(values.mainActivities, {
      type: "checkCharacterLength",
      length: 2500
    }),
    nonWeeklyService: validator(values.nonWeeklyService, {
      type: "checkCharacterLength",
      length: 2500
    }),
    overallLife: validator(values.overallLife, {
      type: "checkCharacterLength",
      length: 2500
    }),
    staffComment: validator(values.staffComment, {
      type: "checkCharacterLength",
      length: 2500
    })
  };
};
