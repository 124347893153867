import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { SupportPlanState } from "@stores/domain/mgr/SHUROTEICHAKU/supportPlan/types";
import { UsersInFacilityState as SHUROTEICHAKUUsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import dispatches from "@stores/dispatches";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  WORKING_STRATUS,
  DISABILITY_CERTIFICATE_BODY,
  DISABILITY_CERTIFICATE_SPIRIT,
  DISABILITY_CERTIFICATE_REHABILITATION
} from "@constants/mgr/SHUROTEICHAKU/variables";
import ClassNames from "classnames";
import { FacilityType, DISABILITY_CLASS_LIST } from "@constants/variables";
import { SprintType } from "@constants/mgr/IAB/variables";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

// utils
import { getUrlParams } from "@/utils/url";
import { dateToLocalisedString } from "@/utils/date";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import getAge from "@utils/date/getAge";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    createdDate: {
      letterSpacing: "0.1em",
      marginBottom: 4
    },
    planningPeriodDate: {
      letterSpacing: "0.1em",
      fontSize: 10
    },
    entryField: {
      marginBottom: 9
    },
    entryFieldAlignment: {
      display: "flex"
    },
    entryFieldColumn: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 8
    },
    entryFieldContainer: {
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginBottom: 15,
      marginRight: 16,
      width: 64,
      "&.disabilityClass": {
        width: 145
      }
    },
    entryFieldLabel: {
      fontSize: 8,
      marginBottom: 12,
      color: "#424242"
    },
    entryFieldLabelAuthor: {
      fontSize: 8,
      color: "#424242"
    },
    entryFieldContentContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
      marginTop: 4
    },
    role: {
      fontSize: 8
    },
    entryFieldMark: {
      color: "#9E9E9E",
      fontSize: 12,
      lineHeight: 1.23
    },
    tableContainer: {
      fontSize: 10,
      marginBottom: 20,
      whiteSpace: "pre-line"
    },
    tableTitle: {
      fontWeight: "bold",
      borderBottom: "1px solid #000",
      paddingBottom: 5,
      marginBottom: 8,
      display: "flex",
      justifyContent: "space-between"
    },
    tableSubtitle: {
      fontWeight: 500
    },
    dateContainer: {
      marginRight: 10,
      "&:last-child": {
        marginRight: 0
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      tableLayout: "fixed",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      "&.sprintGoal": {
        borderBottom: "none",
        "&:last-child": {
          borderBottom: "2px solid"
        }
      },
      "&.fullWidth": {
        width: "100%"
      },
      "& th": {
        fontWeight: "normal",
        borderRight: "1px solid",
        padding: "3px 8px",
        verticalAlign: "middle",
        "&.employmentHeader": {
          width: 68,
          borderRight: "1px solid"
        }
      },
      "& td": {
        padding: "4px 8px",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 24,
        color: "#424242",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.width229": {
          width: 229
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.height40": {
          height: 40
        }
      }
    },
    employmentHeader: {
      width: 68,
      borderRight: "1px solid"
    },
    primaryTableHeadSHUROTEITYAKUSub: {
      padding: "4px 7.5px",
      width: 91,
      borderRight: "1px solid"
    },
    primaryTableHead: {
      width: 100
    },
    secondaryTableHead: {
      height: 40,
      width: 160
    },
    sprintTableItem: {
      "&:last-child": {
        borderBottom: "none"
      }
    },
    implementDate: {
      fontWeight: "normal",
      letterSpacing: "0.1em",
      fontSize: 8
    },
    title: {
      margin: 0,
      marginTop: 14,
      fontSize: 18,
      fontWeight: "normal",
      letterSpacing: 1.2,
      color: "rgba(0, 0, 0, 0.84)"
    },
    otherInfo: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 8,
      fontSize: 10
    },
    period: {
      margin: "0 2px"
    },
    authorContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 10
    },
    authorizerContainer: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: 16
    },
    author: {
      marginBottom: 10
    },
    name: {
      marginLeft: 2,
      marginRight: 20
    },
    userBasicInfo: {
      display: "flex",
      justifyContent: "space-between"
    },
    userName: {
      width: 368,
      marginBottom: 12
    },
    dateOfBirth: {
      width: 128
    },
    facilityStaff: {
      display: "flex",
      padding: "12px 0"
    },
    staffBox: {
      width: 72,
      height: 80,
      paddingTop: 4,
      border: "1px solid #212121",
      borderRight: "none",
      fontSize: 10,
      color: "#424242",
      textAlign: "center",
      "&:last-child": {
        borderRight: "1px solid #212121"
      }
    },
    servicePerson: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: "left",
      lineHeight: 1.25
    },
    counts: {
      fontSize: 10,
      letterSpacing: "0.1em",
      "&:first-child": {
        marginRight: 20
      }
    },
    signatureArea: {
      fontSize: 10
    },
    signatureAreaTopText: {
      paddingTop: 20,
      paddingBottom: 6
    },
    signature: {
      display: "flex",
      alignItems: "center"
    },
    signatureDate: {
      display: "flex",
      marginLeft: 48,
      marginRight: 30,
      "& :nth-of-type(n+2)": {
        marginLeft: 48
      }
    },
    userNameContainer: {
      width: 200,
      borderBottomColor: "#000",
      paddingBottom: 5
    },
    authorityNameContainer: {
      width: 416,
      marginTop: 10,
      borderBottomColor: "#000",
      paddingBottom: 5
    },
    authorityTitle: {
      color: "#424242",
      fontSize: 10
    },
    userEntryFieldContentContainer: {
      textAlign: "right",
      fontSize: 16,
      height: "25px"
    },
    userEntryFieldWithMark: {
      borderBottom: "1px solid",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      minWidth: 200,
      marginLeft: 8,
      paddingBottom: 5,
      lineHeight: 1.4,
      minHeight: "20px"
    },
    footer: {
      fontSize: 10,
      borderTop: "1px solid"
    },
    footerContentWrapper: {
      marginTop: 7,
      display: "flex",
      justifyContent: "space-between"
    },
    footerContentOfficeWrapper: {
      marginTop: 4
    },
    extendableContent: {
      width: 480,
      wordWrap: "break-word"
    },
    rightSpacedItem: {
      marginRight: 7
    },
    smallSignature: {
      width: 160,
      color: "#424242"
    },
    hideStaffComment: {
      display: "none"
    },
    signatureAreaSubText: { marginTop: 17, marginBottom: 25 },
    "@media print": {
      page: {
        width: "200mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */
type SprintData = {
  id?: number; // 登録済みの場合
  number: number; // 短期目標番号
  sprint_type: SprintType; // 目標種別
  sprint_goal: string; // 目標
  adopt_info: string; // 本人の取り組み内容
  support_info: string; // 職員の支援内容
  sprint_start_date: string; // 設定日
  sprint_end_date: string; // 達成予定日
};

type DisplayOptions = {
  display_info?: string;
  display_comment?: string;
  display_minutes?: string;
  display_icon?: string;
};
type StateProps = {
  user: UserState;
  userInFacility: SHUROTEICHAKUUsersInFacilityState["user"];
  facility: FacilityState;
  privateSupportPlan: SupportPlanState["privateSupportPlan"];
};
type OwnProps = {
  uifId: string;
  supportPlanId: string;
  query: string;
};
type DateData = {
  title: string | null;
  date: string | null;
};
type DispatchProps = {
  getPrivateSupportPlanRecordData: (
    uifId: string,
    supportPlanId: string
  ) => void;
  getUserDetailInFacilityData: (
    uifId: string,
    facility_type: FacilityType
  ) => void;
  getFacilitySHUROTEICHAKU: () => void;
};
type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userDetail: {
    nameSei?: string;
    nameMei?: string;
    nameSeiKana?: string;
    nameMeiKana?: string;
    dateBirth?: string;
    gender?: string;
    disabilitySupportClass?: string;
    facilityName: string;
  };
  fixPlanData: SupportPlanState["privateSupportPlan"];
  displayOptions: DisplayOptions;
  facilityState: FacilityState;
};
type SectionTableOwnProps = {
  sectionTitle: string;
  sectionPlanData: {
    itemTitle: string;
    content: string | null;
    isContentEmpty?: boolean;
  }[];
  sprintPlanData?: SprintData[];
  implementDate?: string | null;
  displayOptions?: {
    display_info?: string;
    display_comment?: string;
    display_minutes?: string;
  };
  secondaryStyle?: boolean;
  subHeading?: JSX.Element;
};

type SectionCompanyTableOwnProps = {
  sectionTitle: string;
  supportPlanData: SupportPlanState["privateSupportPlan"]["support_plan_shuroteichaku"];
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type SectionTableProps = SectionTableOwnProps & WithStyles<typeof styles>;

type SectionCompanyTableProps = SectionCompanyTableOwnProps &
  WithStyles<typeof styles>;

const SupportPlanSHUROTEICHAKUPrintCore = (
  props: Props
): JSX.Element | null => {
  const {
    uifId,
    supportPlanId,
    query,
    user,
    classes,
    privateSupportPlan,
    facility,
    userInFacility
  } = props;

  const queryParameters: {
    display_info?: string;
    display_comment?: string;
    display_minutes?: string;
  } = getUrlParams(query);

  const displayOptions = queryParameters;

  React.useEffect(() => {
    props.getUserDetailInFacilityData(uifId, user.facility_type);
    props.getPrivateSupportPlanRecordData(uifId, supportPlanId);
    props.getFacilitySHUROTEICHAKU();
  }, []);

  const userDetail = {
    nameSei: userInFacility.user_in_facility.name_sei,
    nameMei: userInFacility.user_in_facility.name_mei,
    nameSeiKana: userInFacility.user_in_facility.name_sei_kana,
    nameMeiKana: userInFacility.user_in_facility.name_mei_kana,
    dateBirth: userInFacility.user_in_facility.date_birth,
    gender: userInFacility.user_in_facility.gender,
    disabilitySupportClass:
      userInFacility.user_in_facility.classify_disability_support,
    facilityName: user.facility_name
  };

  if (
    !privateSupportPlan ||
    Object.keys(privateSupportPlan).length === 0 ||
    Object.keys(userInFacility.user_in_facility).length === 0 ||
    !userInFacility.user_in_facility.id ||
    !user
  ) {
    return null;
  }
  return (
    <Sheet
      classes={classes}
      userDetail={userDetail}
      fixPlanData={privateSupportPlan}
      displayOptions={displayOptions}
      facilityState={facility}
    />
  );
};

const SectionTable = (props: SectionTableProps): JSX.Element => {
  const {
    classes,
    sectionTitle,
    sectionPlanData,
    sprintPlanData,
    implementDate,
    displayOptions,
    secondaryStyle,
    subHeading
  } = props;

  const toCircled = (num: number): string => {
    return circleNumbersList[num - 1];
  };

  const returnApplicablePeriod = (
    startDate: string,
    endDate: string
  ): JSX.Element => {
    if (startDate === "" && endDate === "") {
      return <></>;
    }

    const startDateElement =
      startDate !== "" ? dateToLocalisedString(startDate, "YYYY年M月D日") : "-";
    const endDateElement =
      endDate !== "" ? dateToLocalisedString(endDate, "YYYY年M月D日") : "-";

    return <span>{`${startDateElement} 〜 ${endDateElement}`}</span>;
  };

  return (
    <section>
      <div className={classes.tableTitle}>
        <span>{sectionTitle}</span>
        {implementDate && (
          <span className={classes.implementDate}>
            実施日:
            {implementDate === "-"
              ? implementDate
              : dateToLocalisedString(implementDate, "YYYY年M月D日")}
          </span>
        )}
        {subHeading && (
          <span className={classes.tableSubtitle}>{subHeading}</span>
        )}
      </div>
      {sprintPlanData ? (
        // 長期目標・短期目標・目標達成に向けた個別方針
        // sprintPlanDataの0,1は長期目標と短期目標なので除外し、個別目標は2から
        [2, 3, 4].map(
          (index): JSX.Element => {
            const planKey = sprintPlanData[index];
            const planNumber = toCircled(index - 1);
            return (
              <React.Fragment key={index}>
                <table className={`${classes.table} fullWidth sprintGoal`}>
                  <tbody>
                    <tr>
                      <th className={classes.primaryTableHead}>
                        {planNumber} 支援方針
                      </th>
                      <td>{planKey.sprint_goal}</td>
                    </tr>
                    <tr className={classes.sprintTableItem}>
                      <th className={classes.primaryTableHead}>期間</th>
                      <td>
                        {returnApplicablePeriod(
                          planKey.sprint_start_date,
                          planKey.sprint_end_date
                        )}
                      </td>
                    </tr>
                    <tr className={classes.sprintTableItem}>
                      <th className={classes.primaryTableHead}>
                        本人の取組内容
                      </th>
                      <td>{planKey.adopt_info}</td>
                    </tr>
                    {displayOptions && displayOptions.display_info && (
                      <tr className={classes.sprintTableItem}>
                        <th className={classes.primaryTableHead}>
                          職員の支援内容
                        </th>
                        <td>{planKey.support_info}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </React.Fragment>
            );
          }
        )
      ) : (
        <table className={`${classes.table} fullWidth`}>
          <tbody>
            {sectionPlanData.map((planKey, index) => {
              const keyNumber = index;
              if (planKey.isContentEmpty)
                return <React.Fragment key={keyNumber} />;
              return (
                <tr
                  className={
                    displayOptions &&
                    !displayOptions.display_comment &&
                    planKey.itemTitle === "職員コメント"
                      ? classes.hideStaffComment
                      : ""
                  }
                  key={keyNumber}
                >
                  {secondaryStyle ? (
                    <th className={classes.secondaryTableHead}>
                      {planKey.itemTitle}
                    </th>
                  ) : (
                    <th className={classes.primaryTableHead}>
                      {planKey.itemTitle}
                    </th>
                  )}
                  <td>{planKey.content}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </section>
  );
};

const SectionCompanyTable = (props: SectionCompanyTableProps): JSX.Element => {
  const { classes, sectionTitle, supportPlanData } = props;

  return (
    <section>
      <div className={classes.tableTitle}>
        <span>{sectionTitle}</span>
      </div>
      {/* 就職先情報 */}
      <table className={`${classes.table} fullWidth`}>
        {/* 行ごとによって異なるセルの幅に対応 */}
        <colgroup>
          <col span={1} style={{ width: 68 }} />
          <col span={1} style={{ width: 91 }} />
          <col span={1} style={{ width: 229 }} />
          <col span={1} style={{ width: 48 }} />
        </colgroup>
        <tbody>
          <tr>
            <th className={classes.employmentHeader} rowSpan={4}>
              雇用事業主
            </th>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>企業名</td>
            <td colSpan={3}>{supportPlanData.company_name}</td>
            <td style={{ width: 48, borderLeft: "1px solid" }}>業種</td>
            <td style={{ borderLeft: "1px solid" }}>
              {supportPlanData.company_industry}
            </td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>所在地</td>
            <td colSpan={5}>{supportPlanData.company_address}</td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>連絡先</td>
            <td colSpan={5}>{supportPlanData.company_tel}</td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>担当者</td>
            <td colSpan={5}>{supportPlanData.responsible_person}</td>
          </tr>
          <tr>
            <th className={classes.employmentHeader} rowSpan={2}>
              職場環境
            </th>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>
              物理的環境
            </td>
            <td colSpan={5}>{supportPlanData.equipment_environment}</td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>
              人的環境
            </td>
            <td colSpan={5}>{supportPlanData.human_environment}</td>
          </tr>

          <tr>
            <th className={classes.employmentHeader} rowSpan={3}>
              労働条件
            </th>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>
              雇用形態
            </td>
            <td colSpan={5}>
              {WORKING_STRATUS[supportPlanData.working_status].label}
            </td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>賃金</td>
            <td colSpan={5}>{supportPlanData.wage}</td>
          </tr>
          <tr>
            <td className={classes.primaryTableHeadSHUROTEITYAKUSub}>
              1日の勤務時間
            </td>
            <td>
              {supportPlanData.working_time}（休み時間：
              {supportPlanData.rest_time}）
            </td>
            <td style={{ borderLeft: "1px solid" }}>休日</td>
            <td style={{ borderLeft: "1px solid" }} colSpan={3}>
              {supportPlanData.holiday}
            </td>
          </tr>

          <tr className="height40">
            <th className={classes.employmentHeader} colSpan={2}>
              業務内容
            </th>
            <td colSpan={5}>{supportPlanData.business_description}</td>
          </tr>

          <tr>
            <th className={classes.employmentHeader} colSpan={2}>
              就職まで利用していた
              <br />
              事業所からの引き継ぎ事項
            </th>
            <td colSpan={5}>
              （就職日：
              {supportPlanData.working_start_date !== null
                ? dateToLocalisedString(
                    supportPlanData.working_start_date,
                    "YYYY年M月D日"
                  )
                : "-"}
              ）<br />
              {supportPlanData.takeover}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    userDetail,
    fixPlanData,
    displayOptions,
    facilityState
  } = props;
  // 事業所Noと電話番号改行用の処理
  const officeName = React.useRef() as React.MutableRefObject<HTMLSpanElement>;
  const officeAddress = React.useRef() as React.MutableRefObject<
    HTMLSpanElement
  >;
  const [isOfficeNameLong, setIsOfficeNameLong] = React.useState(false);
  const [isAddressLong, setIsAddressLong] = React.useState(false);
  const breakPoint = 365;
  React.useEffect(() => {
    setIsOfficeNameLong(officeName.current.offsetWidth > breakPoint);
  }, [officeName.current]);
  React.useEffect(() => {
    setIsAddressLong(officeAddress.current.offsetWidth > breakPoint);
  }, [officeAddress.current]);

  const { author, authorizer } = fixPlanData;
  // 作成者名
  const authorValue = getSnapOrRealName(author, "");
  // 承認者名
  const authorizerName = getSnapOrRealName(authorizer, "");
  // 承認者役職
  const authorizerRole = getSnapOrRealRole(authorizer, "");

  // 障害者手帳
  const disabilityList: string[] = [];
  if (
    fixPlanData.support_plan_shuroteichaku.disability_certificate_body !==
    Number(DISABILITY_CERTIFICATE_BODY[0].value)
  ) {
    disabilityList.push(
      `身体 ${fixPlanData.support_plan_shuroteichaku.disability_certificate_body}級`
    );
  }
  if (
    fixPlanData.support_plan_shuroteichaku.disability_certificate_spirit !==
    Number(DISABILITY_CERTIFICATE_SPIRIT[0].value)
  ) {
    disabilityList.push(
      `精神 ${fixPlanData.support_plan_shuroteichaku.disability_certificate_spirit}級`
    );
  }
  if (
    fixPlanData.support_plan_shuroteichaku
      .disability_certificate_rehabilitation !==
    Number(DISABILITY_CERTIFICATE_REHABILITATION[0].value)
  ) {
    disabilityList.push(
      `療育 ${fixPlanData.support_plan_shuroteichaku.disability_certificate_rehabilitation_other}`
    );
  }
  let disabilityCertificateText = "";
  if (disabilityList.length > 0) {
    disabilityCertificateText = disabilityList.join("、");
  } else if (fixPlanData.support_plan_shuroteichaku.classify_incurable_flg) {
    disabilityCertificateText = "難病";
  }

  /**
   * support_plan_goalは入力していないnumberは返って来ないので、ない場合は初期値でセットする
   */
  const supplementSupportPlanGoal = (
    supportPlanGoal: SupportPlanState["privateSupportPlan"]["support_plan_goal"]
  ): SprintData[] => {
    const numberOfLongOrShort = 1;
    const convertPrivateToRecord = (
      res:
        | SupportPlanState["privateSupportPlan"]["support_plan_goal"][0]
        | undefined,
      defaultNumber: number,
      defaultSprintType: SprintType
    ): SprintData => {
      return res
        ? {
            id: res.id,
            number: res.number,
            sprint_type: res.sprint_type,
            sprint_goal: res.sprint_goal || "",
            adopt_info: res.adopt_info || "",
            support_info: res.support_info || "",
            sprint_start_date: res.sprint_start_date || "",
            sprint_end_date: res.sprint_end_date || ""
          }
        : {
            number: defaultNumber,
            sprint_type: defaultSprintType,
            sprint_goal: "",
            adopt_info: "",
            support_info: "",
            sprint_start_date: "",
            sprint_end_date: ""
          };
    };

    const supportPlanGoalLong = convertPrivateToRecord(
      supportPlanGoal.find((g) => g.sprint_type === SprintType.LONG),
      numberOfLongOrShort,
      SprintType.LONG
    );
    const supportPlanGoalShort = convertPrivateToRecord(
      supportPlanGoal.find((g) => g.sprint_type === SprintType.SHORT),
      numberOfLongOrShort,
      SprintType.SHORT
    );
    const tmpSupportPlanGoalIndividuals = supportPlanGoal.filter(
      (g) => g.sprint_type === SprintType.INDIVIDUAL
    );
    const individualNumbers = [3, 4, 5]; // 個別目標 1~3
    const supportPlanGoalIndividuals = individualNumbers.map((num) => {
      const res = tmpSupportPlanGoalIndividuals.find((s) => s.number === num);
      return convertPrivateToRecord(res, num, SprintType.INDIVIDUAL);
    });
    return [
      supportPlanGoalLong,
      supportPlanGoalShort,
      ...supportPlanGoalIndividuals
    ];
  };

  const [supportPlanGoal] = React.useState(
    supplementSupportPlanGoal(fixPlanData.support_plan_goal)
  );

  const filteredName: string[] = [];
  if (fixPlanData.participant) {
    fixPlanData.participant
      .filter((result) => result.name)
      .map((participant) => filteredName.push(participant.name));
  }

  const hopeData = [
    {
      itemTitle: "関わっている支援機関",
      content: fixPlanData.support_plan_shuroteichaku.support_group
    },
    {
      itemTitle: "健康状態\n（診断名・服薬状況等）",
      content: fixPlanData.support_plan_shuroteichaku.physical_condition
    },
    {
      itemTitle: "生活環境及び\n生活面のサポート体制",
      content: fixPlanData.support_plan_shuroteichaku.support_organization
    }
  ];
  const longTermGoalData = [
    {
      itemTitle: "目標",
      content: supportPlanGoal[0] ? supportPlanGoal[0].sprint_goal : ""
    }
  ];
  const shortTermGoalData = [
    {
      itemTitle: "目標",
      content: supportPlanGoal[1] ? supportPlanGoal[1].sprint_goal : ""
    }
  ];
  const longTermGoalSubHeading = [
    {
      title: "設定日",
      date: supportPlanGoal[0] ? supportPlanGoal[0].sprint_start_date : ""
    },
    {
      title: "達成予定日",
      date: supportPlanGoal[0] ? supportPlanGoal[0].sprint_end_date : ""
    }
  ];
  const shortTermGoalSubHeading = [
    {
      title: "設定日",
      date: supportPlanGoal[1] ? supportPlanGoal[1].sprint_start_date : ""
    },
    {
      title: "達成予定日",
      date: supportPlanGoal[1] ? supportPlanGoal[1].sprint_end_date : ""
    }
  ];
  const otherData = [
    {
      itemTitle: "特記事項",
      content: fixPlanData.remarks
    },
    {
      itemTitle: "職員コメント",
      content: fixPlanData.staff_comment
    }
  ];
  const mtgData = [
    {
      itemTitle: "会議議事録",
      content: fixPlanData.minutes
    },
    {
      itemTitle: "参加者",
      content: filteredName.join("、")
    }
  ];

  const dateForSubHeading = (data: Array<DateData>): JSX.Element => {
    return (
      <div>
        {data.map((item: DateData, index: number) => {
          return (
            <span
              className={classes.dateContainer}
              key={`${item.title || index} ${item.date || index}`}
            >
              <span>{`${item.title}:`}</span>
              <span>
                {item.date === "" || item.date === null
                  ? "-"
                  : dateToLocalisedString(item.date, "YYYY年M月D日")}
              </span>
            </span>
          );
        })}
      </div>
    );
  };
  return (
    <div className={classes.page}>
      <header>
        <h1 className={classes.title}>就労定着支援 個別支援計画書</h1>
      </header>
      <div className={classes.otherInfo}>
        <div>
          <div className={classes.createdDate}>
            <span className={classes.counts}>
              計画作成日:{" "}
              {dateToLocalisedString(
                fixPlanData.creation_date !== null
                  ? fixPlanData.creation_date
                  : "",
                "YYYY年M月D日"
              )}
            </span>
          </div>
          <div>
            <span>支援期間: </span>
            <span className={classes.planningPeriodDate}>
              {dateToLocalisedString(
                fixPlanData.support_begin_date !== null
                  ? fixPlanData.support_begin_date
                  : "",
                "YYYY年M月D日"
              )}
              <span className={classes.period}>~</span>
              {dateToLocalisedString(
                fixPlanData.support_end_date !== null
                  ? fixPlanData.support_end_date
                  : "",
                "YYYY年M月D日"
              )}
            </span>
          </div>
        </div>
        <div>
          <div className={classes.authorContainer}>
            <span className={classes.entryFieldLabelAuthor}>計画作成者</span>
            <div className={classes.userEntryFieldWithMark}>
              <span>{authorValue}</span>
              {displayOptions.display_icon && (
                <span className={classes.entryFieldMark}>印</span>
              )}
            </div>
          </div>
          <div className={classes.authorizerContainer}>
            <span className={classes.entryFieldLabelAuthor}>承認者</span>
            <div className={classes.userEntryFieldWithMark}>
              <div className={classes.name}>
                <div>{authorizerRole}</div>
                <div>{authorizerName}</div>
              </div>
              {displayOptions.display_icon && (
                <span className={classes.entryFieldMark}>印</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.userBasicInfo}>
        <div className={classes.entryField}>
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.userName
            )}
          >
            <span className={classes.entryFieldLabel}>氏名（フリガナ）</span>
            <div className={classes.entryFieldContentContainer}>
              <span>{`${userDetail.nameSei} ${userDetail.nameMei}（${userDetail.nameSeiKana} ${userDetail.nameMeiKana}）`}</span>
            </div>
          </div>
          <div className={classes.entryFieldAlignment}>
            <div
              className={ClassNames(
                classes.entryFieldContainer,
                classes.dateOfBirth
              )}
            >
              <span className={classes.entryFieldLabel}>生年月日(年齢)</span>
              <div className={classes.entryFieldContentContainer}>
                <span>
                  {dateToLocalisedString(
                    userDetail.dateBirth !== undefined
                      ? userDetail.dateBirth
                      : "",
                    "YYYY年M月D日"
                  )}
                  {`（${getAge(
                    userDetail.dateBirth,
                    fixPlanData.creation_date
                  )}歳）`}
                </span>
              </div>
            </div>
            <div className={classes.entryFieldContainer}>
              <span className={classes.entryFieldLabel}>性別</span>
              <div className={classes.entryFieldContentContainer}>
                <span>{userDetail.gender === "1" ? "男性" : "女性"}</span>
              </div>
            </div>
            <div className={classes.entryFieldContainer}>
              <span className={classes.entryFieldLabel}>障害支援区分</span>
              <div className={classes.entryFieldContentContainer}>
                <span>
                  {
                    DISABILITY_CLASS_LIST[
                      fixPlanData.support_plan_shuroteichaku.disability_class
                        ? Number(
                            fixPlanData.support_plan_shuroteichaku
                              .disability_class
                          )
                        : 0
                    ].label
                  }
                </span>
              </div>
            </div>
            <div className={`${classes.entryFieldContainer} disabilityClass`}>
              <span className={classes.entryFieldLabel}>障害者手帳</span>
              <div className={classes.entryFieldContentContainer}>
                <span>{disabilityCertificateText}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.facilityStaff}>
          <div className={classes.staffBox}>
            <span>管理者</span>
          </div>
          <div className={classes.staffBox}>
            <div className={classes.servicePerson}>
              <div>サービス管理</div>
              <div>責任者</div>
            </div>
          </div>
          <div className={classes.staffBox}>
            <span>就労支援員</span>
          </div>
        </div>
      </div>
      <div className={classes.tableContainer}>
        <SectionCompanyTable
          classes={classes}
          sectionTitle="就職先情報"
          supportPlanData={fixPlanData.support_plan_shuroteichaku}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="本人情報"
          sectionPlanData={hopeData}
          secondaryStyle
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="長期目標"
          sectionPlanData={longTermGoalData}
          subHeading={dateForSubHeading(longTermGoalSubHeading)}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="短期目標"
          sectionPlanData={shortTermGoalData}
          subHeading={dateForSubHeading(shortTermGoalSubHeading)}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="目標達成に向けた支援方針・内容・期間・頻度"
          sectionPlanData={[{ itemTitle: "", content: "" }]}
          sprintPlanData={supportPlanGoal}
          displayOptions={displayOptions}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="その他"
          sectionPlanData={otherData}
          displayOptions={displayOptions}
        />
      </div>
      {displayOptions.display_minutes && (
        <div className={classes.tableContainer}>
          <SectionTable
            classes={classes}
            sectionTitle="個別支援会議 議事録"
            implementDate={fixPlanData.minutes_date || "-"}
            sectionPlanData={mtgData}
          />
        </div>
      )}
      <div className={classes.signatureArea}>
        <div className={classes.signatureAreaTopText}>
          <p>
            上記計画書の内容についてサービス管理責任者より説明を受け、同意の上受領しました。
          </p>
        </div>
        <div className={classes.signature}>
          <div className={classes.signatureDate}>
            <span>年</span>
            <span>月</span>
            <span>日</span>
          </div>
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.userNameContainer
            )}
          >
            <span className={classes.entryFieldLabel}>ご本人氏名</span>
            <div className={classes.userEntryFieldContentContainer}>
              {displayOptions.display_icon && (
                <span className={classes.entryFieldMark}>印</span>
              )}
            </div>
          </div>
          <div
            className={`${classes.entryFieldContainer} ${classes.userNameContainer}`}
          >
            <span className={classes.entryFieldLabel}>ご家族氏名</span>
            <div className={classes.userEntryFieldContentContainer}>
              {displayOptions.display_icon && (
                <span className={classes.entryFieldMark}>印</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={classes.signatureArea}>
        <p className={classes.signatureAreaSubText}>
          上記計画書に基づきサービスの説明を行い、内容に同意頂きましたので、ご報告申し上げます。
        </p>
        <div className={classes.signature}>
          <div className={classes.signatureDate}>
            <span>年</span>
            <span>月</span>
            <span>日</span>
          </div>
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.authorityNameContainer
            )}
          >
            <div className={classes.userEntryFieldContentContainer}>
              <span className={classes.authorityTitle}>
                相談支援専門員様/事業所様
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerContentWrapper}>
          <div className={classes.footerContentOfficeWrapper}>
            <div className={classes.extendableContent}>
              <span ref={officeName} className={classes.rightSpacedItem}>
                {`就労定着支援 ${userDetail.facilityName}`}
              </span>
              {isOfficeNameLong && <br />}
              <span>{`事業所No. ${facilityState.officeNumber}`}</span>
            </div>
            <div className={classes.extendableContent}>
              <span ref={officeAddress}>
                <span>{`〒${facilityState.postalCode} `}</span>
                <span>
                  {facilityState.restAddress}
                  &nbsp;
                </span>
              </span>
              {isAddressLong && <br />}
              <span>{`tel: ${facilityState.tel}`}</span>
            </div>
          </div>
          <div>
            <div className={classes.smallSignature}>管理者:</div>
            <div className={classes.smallSignature}>説明者:</div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const userInFacility = state.SHUROTEICHAKU.userInFacility.user;
  const { facility } = state.SHUROTEICHAKU;
  return {
    user,
    userInFacility,
    facility,
    privateSupportPlan: state.SHUROTEICHAKU.supportPlan.privateSupportPlan
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHUROTEICHAKU } = dispatches;
  const getPrivateSupportPlanRecordData = (
    uifId: string,
    supportPlanId: string
  ): Promise<void> =>
    SHUROTEICHAKU.supportPlanDispatcher(dispatch).fetchPrivateSupportPlan(
      uifId,
      supportPlanId
    );
  const getUserDetailInFacilityData = (uifId: string): Promise<void> =>
    SHUROTEICHAKU.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  const getFacilitySHUROTEICHAKU = (): Promise<void> =>
    SHUROTEICHAKU.facilityDispatcher(dispatch).fetch();
  return {
    getPrivateSupportPlanRecordData,
    getUserDetailInFacilityData,
    getFacilitySHUROTEICHAKU
  };
};

export const SupportPlanSHUROTEICHAKUPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SupportPlanSHUROTEICHAKUPrintCore));
