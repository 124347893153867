/**
 * 00時〜23時の時間配列を作る
 */
const createHoursArray = (): { label: string; value: string }[] => {
  return Array(24)
    .fill(0)
    .map((e, index) => index)
    .map((e) => {
      const padding = e.toString().padStart(2, "0");
      return { label: `${padding}時`, value: padding };
    });
};

export default createHoursArray;
