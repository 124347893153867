import * as React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  STICKY_BOX_SHADOW,
  STICKY_NONE_BOX_SHADOW,
  DIVIDER_UNSET_BG,
  DIVIDER_BG_COLOR
} from "@constants/styles";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  SUPPORT_CARE_PLAN_NAME
} from "@constants/variables";
import { KODOENGO_PLAN_LIST_TAB_PATH } from "@constants/mgr/KODOENGO/variables";
import { dateInYYYYMMFormat } from "@utils/date";
import * as URL from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    tabWrapper: {
      position: "sticky",
      backgroundColor: "#fff"
    },
    stickyWrapper: {
      position: "sticky",
      zIndex: 10,
      top: 16
    },
    header: {
      backgroundColor: "#fff",
      padding: "32px 32px 8px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between"
    },
    title: {
      lineHeight: 1,
      "& > span": {
        marginLeft: 16
      }
    },
    divider: {
      position: "relative",
      margin: "0 32px -2px"
    }
  });
type PlanListTabPath =
  | typeof PLAN_LIST_TAB_PATH.ASSESSMENT
  | typeof PLAN_LIST_TAB_PATH.CAREPLAN
  | typeof KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS
  | typeof KODOENGO_PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE;

type OwnProps = {
  uifId: string;
  recordType: PlanListTabPath;
  isEditing: boolean;
  history: H.History;
  pageName: string;
  userName: string;
  button: JSX.Element | JSX.Element[];
  facilityType: FacilityType;
  displayDate?: string;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * タブとタイトルヘッダーを表示する役割
 */
const PlanListHeaderCore = (props: Props): JSX.Element => {
  const { classes, facilityType } = props;
  const scrollYValue = 335;
  const [stickyFlg, setStickyFlg] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    const listenScrollEvent = (): void => {
      if (unmounted) return;
      if (window.scrollY > scrollYValue) {
        setStickyFlg(true);
      } else {
        setStickyFlg(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      unmounted = true;
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleTabChange = (e: React.ChangeEvent<{}>, value: string): void => {
    if (
      (facilityType === FacilityType.A ||
        facilityType === FacilityType.B ||
        facilityType === FacilityType.IKOU ||
        facilityType === FacilityType.GROUP_HOME ||
        facilityType === FacilityType.SEIKATSUKAIGO) &&
      value !== "assessment"
    ) {
      props.history.push(`${URL.RECORD}/${props.uifId}/${value}`);
      return;
    }
    if (
      facilityType === FacilityType.KODOENGO &&
      value === "support_procedure"
    ) {
      // 行動援護の支援手順書 兼 記録用紙の場合のみ、URLに日付を追加(recordInfoに書くとMUIのWarningがでるためこちらに記載)
      props.history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${
          props.uifId
        }/${value}/list/${dateInYYYYMMFormat(new Date())}`
      );
      return;
    }
    if (
      (facilityType === FacilityType.KEIKAKUSODAN ||
        facilityType === FacilityType.CHIIKIIKO ||
        facilityType === FacilityType.CHIIKITEICHAKU) &&
      (value === "plan_monitoring_meeting" || value === "support")
    ) {
      props.history.push(`${URL.RECORD}/${props.uifId}/${value}`);
      return;
    }

    props.history.push(`${URL.RECORD_SUPPORT_PLAN}/${props.uifId}/${value}`);
  };

  let recordInfo = [];
  switch (facilityType) {
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
    case FacilityType.SEIKATSUKAIGO:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        { label: "個別支援計画", value: "support_plan" },
        { label: "支援記録", value: "support" },
        { label: "作業記録", value: "work" },
        { label: "面談記録", value: "interview" }
      ];
      break;
    case FacilityType.KODOENGO:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        {
          label: SUPPORT_CARE_PLAN_NAME[facilityType],
          value: "care_plan"
        },
        { label: "支援計画シート", value: "support_plan_sheets" },
        { label: "支援手順書 兼 記録用紙", value: "support_procedure" }
      ];
      break;
    case FacilityType.KEIKAKUSODAN:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        {
          label: "申請者の現状（基本情報）",
          value: "basic_info"
        },
        {
          label: "計画・モニタリング・会議記録",
          value: "plan_monitoring_meeting"
        },
        { label: "支援記録", value: "support" }
      ];
      break;
    case FacilityType.CHIIKIIKO:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        { label: "計画・報告書・会議記録", value: "plan_monitoring_meeting" },
        { label: "支援記録", value: "support" }
      ];
      break;
    case FacilityType.CHIIKITEICHAKU:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        { label: "地域定着支援台帳", value: "support_ledger" },
        { label: "支援記録", value: "support" }
      ];
      break;
    case FacilityType.GROUP_HOME:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        { label: "個別支援計画", value: "support_plan" },
        { label: "支援記録", value: "support" }
      ];
      break;
    default:
      recordInfo = [
        { label: "アセスメント", value: "assessment" },
        {
          label: SUPPORT_CARE_PLAN_NAME[facilityType],
          value: "care_plan"
        }
      ];
  }

  return (
    <div
      className={classes.stickyWrapper}
      style={
        stickyFlg
          ? { boxShadow: STICKY_BOX_SHADOW }
          : { boxShadow: STICKY_NONE_BOX_SHADOW }
      }
    >
      <div className={classes.tabWrapper}>
        <KnowbeTabs
          key="recordTabs"
          tabInfo={recordInfo}
          value={props.recordType}
          disabled={props.isEditing}
          onChange={handleTabChange}
        />
      </div>
      <>
        <div className={classes.header}>
          <Typography variant="h6" color="primary" className={classes.title}>
            {props.pageName}
            <span>{props.userName}</span>
            {props.displayDate && <span>{props.displayDate}</span>}
          </Typography>
          <div>{props.button}</div>
        </div>
        <Divider
          className={classes.divider}
          style={
            stickyFlg
              ? { backgroundColor: DIVIDER_UNSET_BG }
              : { backgroundColor: DIVIDER_BG_COLOR }
          }
        />
      </>
    </div>
  );
};
export const PlanListHeader = withStyles(styles)(PlanListHeaderCore);
