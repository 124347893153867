import React, { useEffect, useState } from "react";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

// ui
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@componentsMobile/templates/AdminTemplate";
import { ServiceDeliveryMonthlyRecord } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryMonthlyRecord";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";

// variables
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import * as URL from "@constants/url";
import {
  compareDateFuture,
  dateInYYYYMMFormat,
  dateToLocalisedString
} from "@utils/date";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { connect } from "react-redux";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { OptionInterface } from "@components/atoms/DropDown";
import { MuiFloatingActionButton } from "@componentsMobile/molecules/MuiFloatingActionButton";
import { MuiSelect } from "@componentsMobile/molecules/MuiSelect";
import { DateSelectButtonsMonthly } from "@componentsMobile/molecules/DateSelectButtonsMonthly";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    tab: {
      position: "relative",
      zIndex: 10,
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)"
    },
    selectWrapper: {
      width: "100%",
      padding: "24px 16px 8px 16px",
      backgroundColor: "#fff"
    },
    monthlyHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 16,
      paddingBottom: 16,
      backgroundColor: "#fff",
      zIndex: 1,
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)"
    }
  });

type StateProps = {
  serviceDeliveryMonthlyState: ServiceDeliveryState["monthlyRecord"];
  userInFacility: UsersInFacilityState;
  facilityType: FacilityType;
};

type OwnProps = RouteComponentProps<{
  date: string;
  uifId: string;
}> &
  WithStyles<typeof styles>;

type DispatchProps = {
  fetchMonthlyRecord: (
    usersInFacilityId: number,
    yyyymm: string
  ) => Promise<void>;
  fetchFacilityUserList: (date: Date) => void;
};

type MergeProps = OwnProps &
  StateProps &
  DispatchProps & {
    userListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * サービス提供記録（月ごと）
 */
const currentDate = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDate.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);
const ServiceDeliveryMonthlyMobileCore = (props: Props): JSX.Element => {
  const uifId = props.match.params.uifId ? +props.match.params.uifId : 0;
  const { date } = props.match.params;
  const dateInstance = new Date(+date.slice(0, 4), +date.slice(4, 6) - 1, 1);
  let initialDate = date ? dateInstance : new Date();
  if (initialDate < minDate || initialDate > maxDate) {
    initialDate = new Date();
  }
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedUser, setSelectedUser] = useState<OptionInterface>({
    label: "",
    value: uifId
  });
  const [doneFetch, setDoneFetch] = useState(false);

  const getUrl = (params: { id?: number; date?: Date }): string => {
    const userId = params.id || selectedUser.value;
    const urlDate = params.date || selectedDate;
    const yyyymm = dateToLocalisedString(urlDate, "YYYYMM");
    return `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/${userId}/${yyyymm}`;
  };

  // 不正な日付がURLにある場合に変更する
  useEffect(() => {
    if (dateInstance < minDate || dateInstance > maxDate) {
      props.history.replace(getUrl({ date: selectedDate }));
    }
  }, []);

  useEffect(() => {
    props.fetchFacilityUserList(selectedDate);
  }, []);

  // リスト取得完了後に初回取得、URLに不正なuserIdが指定されている場合一番上を選ぶ
  useEffect(() => {
    if (props.userListOption && props.userListOption[0]) {
      if (!props.userListOption.find((u) => u.value === selectedUser.value)) {
        const user = props.userListOption[0];
        setSelectedUser(user);
        props.history.replace(getUrl({ id: +user.value }));
      } else if (!doneFetch) {
        props.fetchMonthlyRecord(
          +selectedUser.value,
          dateInYYYYMMFormat(selectedDate)
        );
        setDoneFetch(true);
      }
    }
  }, [props.userListOption]);

  const onChangeUser = (user: OptionInterface): void => {
    props.history.replace(getUrl({ id: +user.value }));
    setSelectedUser(user);
    props.fetchMonthlyRecord(+user.value, dateInYYYYMMFormat(selectedDate));
  };

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.DAILY) {
      props.history.push(URL.RECORD_SERVICE_DELIVERY_DAILY);
    }
  };

  const onChangeDate = async (paramDate: Date): Promise<void> => {
    props.history.replace(getUrl({ date: paramDate }));
    await props.fetchFacilityUserList(paramDate);
    setSelectedDate(paramDate);
    props.fetchMonthlyRecord(
      +selectedUser.value,
      dateInYYYYMMFormat(paramDate)
    );
  };

  // 計画外の記録作成画面
  const changeCreateMode = (): void => {
    const targatYYYYMM = dateInYYYYMMFormat(selectedDate);
    const targetUserId = String(selectedUser.value);
    if (targatYYYYMM && targetUserId) {
      props.history.push(
        `/record/${targatYYYYMM}/${targetUserId}/service_delivery/new`
      );
    }
  };

  /**
   * 利用者の変更
   */
  const onChangeUserList = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const user = props.userListOption.find(
      (x) => x.value === event.target.value
    );
    if (user) {
      onChangeUser(user);
    }
  };

  const pageNameValue =
    props.facilityType === FacilityType.KODOENGO
      ? "支援記録"
      : "サービス提供記録";

  return (
    <AdminTemplate
      pageName={pageNameValue}
      nav={[
        {
          key: 1,
          sticky: true,
          top: -1,
          elm: (
            <>
              <div className={props.classes.tab}>
                <KnowbeTabs
                  key="tab"
                  tabInfo={REPORT_TABS_INFO_LiST}
                  onChange={onChangeTag}
                  value={REPORT_TABS_INFO_PARAM.USERS}
                />
              </div>
              {props.userListOption.length > 0 && (
                <div className={props.classes.selectWrapper}>
                  <MuiSelect
                    id="users-in-facility-list"
                    style={{ width: "100%", marginBottom: 0 }}
                    label="利用者選択"
                    name=""
                    value={`${selectedUser.value}`}
                    options={props.userListOption}
                    onChange={(
                      e: React.ChangeEvent<HTMLSelectElement>
                    ): void => {
                      onChangeUserList(e);
                    }}
                  />
                </div>
              )}
              <div
                id="serviceDeliveryRecordHeader"
                className={props.classes.monthlyHeader}
              >
                <DateSelectButtonsMonthly
                  selectedMonth={selectedDate}
                  min={minDate}
                  max={maxDate}
                  onClickSubmit={onChangeDate}
                />
              </div>
            </>
          )
        }
      ]}
      footerButton={
        // 計画のない未来日の場合、計画外の記録の追加はできない
        !(
          props.serviceDeliveryMonthlyState.service_delivery.length === 0 &&
          compareDateFuture(currentDate, selectedDate)
        ) ? (
          <MuiFloatingActionButton
            onClick={changeCreateMode}
            label="計画外の記録の追加"
          />
        ) : undefined
      }
    >
      <ServiceDeliveryMonthlyRecord
        currentDate={currentDate}
        selectedDate={selectedDate}
        selectedUser={selectedUser}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
        history={props.history}
        facilityType={props.facilityType}
      />
    </AdminTemplate>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery } = dispatches;
  const { KYOTAKUKAIGO } = dispatches;
  const userInFacilityDispatcher = KYOTAKUKAIGO.userInFacilityDispatcher(
    dispatch
  );
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchMonthlyRecord: (
      usersInFacilityId: number,
      yyyymm: string
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchMonthlyRecord(usersInFacilityId, yyyymm),
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date)
  };
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    serviceDeliveryMonthlyState: state.serviceDelivery.monthlyRecord,
    userInFacility: state.KYOTAKUKAIGO.userInFacility,
    facilityType: state.user.facility_type
  };
};
const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const userListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    userListOption,
    ...stateProps,
    ...ownProps,
    ...dispatchProps
  };
};

export const ServiceDeliveryMonthlyMobile = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryMonthlyMobileCore));
