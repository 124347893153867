import * as React from "react";
import * as ClassNames from "classnames";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import blueGrey from "@material-ui/core/colors/blueGrey";

const styles = ({ palette, spacing }: Theme): StyleRules =>
  createStyles({
    head: {
      backgroundColor: blueGrey[300],
      color: palette.common.white
    },
    cell: {
      padding: `0 ${spacing.unit}px`,
      color: palette.common.white,
      fontSize: 13
    }
  });

export type HeaderItem = {
  label: string | React.ReactElement;
  hidden?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  className?: string;
  colSpan?: number;
  rowSpan?: number;
};

export type HeaderProps = {
  role?: string;
  ariaChecked?: boolean;
  tabIndex: number;
  key: number;
  selected: boolean;
  items: HeaderItem[][];
  headerFirstWidth?: string;
  rowStyle?: React.CSSProperties[];
  headerStyle?: React.CSSProperties;
};

type Props = HeaderProps & WithStyles<typeof styles>;

const tableHead = (props: Props): JSX.Element => {
  const { classes, headerFirstWidth } = props;
  return (
    <TableHead className={classes.head} style={props.headerStyle}>
      {props.items.map((itemRow, id) => {
        const rowIndex = id;
        return (
          <TableRow
            role={props.role}
            aria-checked={props.ariaChecked}
            tabIndex={props.tabIndex}
            key={`table-header-row-${rowIndex}`}
            selected={props.selected}
            style={props.rowStyle ? props.rowStyle[id] : {}}
          >
            {itemRow.map((item, idx) => {
              const index = idx;
              return (
                <TableCell
                  key={`table-cell-row-${rowIndex}-${index}`}
                  className={ClassNames(classes.cell, item.className, {
                    [`${headerFirstWidth || ""}`]: idx === 0
                  })}
                  align={item.align}
                  colSpan={item.colSpan}
                  rowSpan={item.rowSpan}
                >
                  {item.label}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
    </TableHead>
  );
};

export const TableMultiHead = withStyles(styles)(tableHead);
