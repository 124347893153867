import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import MgrDrawer from "@components/organisms/mgr/Drawer";
import Header from "@components/organisms/mgr/Header";
import Footer from "@components/organisms/mgr/Footer";
import ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    root: {
      display: "block"
    },
    container: {
      display: "flex"
    },
    wrapperContents: {
      width: "100%",
      zIndex: 1
    },
    mainContents: {
      flexGrow: 1,
      flexBasis: "auto",
      minHeight: "calc(100vh - 136px)" // 100vh - Footer-height（marginTop:80を含む）
    },
    noPrint: {
      position: "relative"
    },
    noPrintIndex0: {
      zIndex: 0
    },
    noPrintIndex2: {
      zIndex: 2
    },
    "@media print": {
      noPrint: {
        display: "none"
      }
    },
    children: {
      zIndex: 1,
      position: "relative"
    }
  });

type OwnProps = {
  pageName: string;
  pathList?: { pathName: string; path: string }[];
  versionChangePath?: string;
  invoiceVersion?: React.ComponentProps<typeof Header>["invoiceVersion"];
  zIndex?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

type State = {
  isOpenDrawer: boolean;
};

class AdminTemplate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenDrawer: true
    };
  }

  public toggleDrawer = (): void => {
    this.setState((prevState) => ({
      isOpenDrawer: !prevState.isOpenDrawer
    }));
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    const version = this.props.versionChangePath
      ? ({ current: "2", changePath: this.props.versionChangePath } as const)
      : undefined;

    return (
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.noPrint}>
            <MgrDrawer
              container={this}
              isOpen={this.state.isOpenDrawer}
              toggleDrawer={this.toggleDrawer}
            />
          </div>
          <div className={classes.wrapperContents}>
            <div className={classes.mainContents}>
              <div
                className={ClassNames(
                  classes.noPrint,
                  this.props.zIndex
                    ? classes.noPrintIndex0
                    : classes.noPrintIndex2
                )}
              >
                <Header
                  toggleDrawer={this.toggleDrawer}
                  pageName={this.props.pageName}
                  pathList={this.props.pathList}
                  // versionは全ての実装が変更できたら削除する（propsのversionChangePath削除と合わせて）
                  version={version}
                  invoiceVersion={this.props.invoiceVersion}
                />
              </div>
              <div className={classes.children}>{this.props.children}</div>
            </div>
            <div className={classes.noPrint}>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(AdminTemplate);
