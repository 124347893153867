import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import facilityApi from "@api/requests/facility/";
import {
  normalizeCarePlanDataFromAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToDeleteAPI
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/mgr/KYOTAKUKAIGO/Users/initial";
import { DeleteCarePlanState } from "@stores/domain/mgr/KYOTAKUKAIGO/carePlan/types";
import { FacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/types";
import { INOUT_RESULTS_ERROR_DUPLICATED } from "@constants/mgr/KYOTAKUKAIGO/variables";

/**
 * サービス予定情報取得及びstore格納
 * @param dispatch
 */
const fetchCarePlan = (dispatch: Dispatch) => async (
  id: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCarePlanStarted());
  await facilityApi
    .getCarePlan(id)
    .then((response) => {
      dispatch(
        actions.fetchCarePlan(normalizeCarePlanDataFromAPI(response.data))
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchCarePlanFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * サービス予定登録/更新
 * @param dispatch
 */
const postKYOTAKUKAIGOCarePlan = (dispatch: Dispatch) => async (
  facility: FacilityState,
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKYOTAKUKAIGOCarePlanStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(before, after, facility);
  await facilityApi
    .postCarePlan(requestParam)
    .then(() => {
      const actionType = actions.postKYOTAKUKAIGOCarePlan(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postKYOTAKUKAIGOCarePlanFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // 3人同時エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.error !== INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * サービス予定削除
 * @param dispatch
 */
const deleteKYOTAKUKAIGOCarePlan = (dispatch: Dispatch) => async (
  target: DeleteCarePlanState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteKYOTAKUKAIGOCarePlanStarted());

  const requestParam = normalizeRequestParamToDeleteAPI(target);

  await facilityApi
    .deleteCarePlan(target.uifId, requestParam)
    .then(() => {
      dispatch(actions.deleteKYOTAKUKAIGOCarePlan(requestParam));
    })
    .catch((e) => {
      dispatch(actions.deleteKYOTAKUKAIGOCarePlanFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchCarePlan: (id: string) => Promise<void>;
  postKYOTAKUKAIGOCarePlan: (
    facility: FacilityState,
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues
  ) => Promise<void>;
  deleteKYOTAKUKAIGOCarePlan: (target: DeleteCarePlanState) => Promise<void>;
};

export const KYOTAKUKAIGOCarePlanDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchCarePlan: fetchCarePlan(dispatch),
  postKYOTAKUKAIGOCarePlan: postKYOTAKUKAIGOCarePlan(dispatch),
  deleteKYOTAKUKAIGOCarePlan: deleteKYOTAKUKAIGOCarePlan(dispatch)
});
