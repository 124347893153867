import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import get from "lodash-es/get";
// ui
// store
import {
  GetServiceDeliveryPrint,
  GetServiceDeliveryPrintExpenses
} from "@api/requests/serviceDelivery/getServiceDeliveryPrint";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH
} from "@/constants/styles";
import * as format from "date-fns/format";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO } from "@constants/mgr/DOKOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO } from "@constants/mgr/KODOENGO/variables";
import {
  SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE,
  SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE,
  SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO,
  FacilityType
} from "@constants/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      },
      position: "relative"
    },
    confirmationColumn: {
      position: "absolute",
      top: "25px",
      right: `${PRINT_PAGE_PADDING / 2}px`
    },
    title: {
      margin: 0,
      fontSize: "18px",
      fontWeight: "normal",
      textAlign: "left",
      color: "#212121"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",

      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "5px 4px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        borderBottom: "1px solid",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sizeCategory": {
          width: "14%"
        },
        "&.sizeTime": {
          width: "6%"
        },
        "&.sizePlace": {
          width: "20%"
        },
        "&.sizeSupport": {
          width: "64%"
        },
        "&.lsize": {
          width: "74%"
        },
        "&.sizeItem": {
          width: "14%"
        },
        "&.sizeExpense": {
          width: "36%"
        },
        "&.titleLabel": {
          width: 71,
          height: 0,
          textAlign: "center",
          lineHeight: 1
        },
        "&.titleContents": {
          width: 71,
          height: 56
        },
        "&.vertical": {
          width: 18,
          letterSpacing: 0,
          height: 80,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.verticalTop": {
          verticalAlign: "top"
        },
        "&.noBorder": { borderBottom: "none" },
        "&.topBorder": { borderTop: "1px solid" },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.borderDashedBottom": {
          borderBottom: "1px dashed"
        },
        "&.borderTop": {
          borderTop: "1px solid"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        },
        "&.bgColor": {
          backgroundColor: "#f5f5f5"
        }
      },
      "& tr": {
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    entryFieldContainer: {
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginTop: 16,
      "&.disabilityClass": {
        width: 145
      }
    },
    userName: {
      width: 120
    },
    status: {
      width: 162
    },
    serviceDate: {
      width: 280,
      marginRight: 16
    },
    entryFieldLabel: {
      fontSize: 8,
      marginBottom: 12,
      color: "#424242"
    },
    entryFieldContentContainer: {
      fontFamily: "HiraginoSans-W6",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
      marginTop: 4,
      color: "#212121",
      fontSize: 10
    },
    spaceBetween: { display: "flex", justifyContent: "space-between" },
    "@media print": {
      page: {
        width: "200mm",
        height: "auto",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type OwnProps = {
  practitionerNumber: number;
  serviceDeliveryPrint: GetServiceDeliveryPrint["data"][0];
  filteredCustomRecords: CustomRecordsWithCategoryState;
  serviceDeliveryRecordsPractitioner: GetServiceDeliveryPrint["data"][0]["service_delivery_record_practitioners"][0]["service_delivery_records"];
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

// サービス内容の詳細テーブル作成
const ServiceDeliveryPrintMoneyManagementsTableBodyCore = (
  props: Props
): JSX.Element => {
  const {
    practitionerNumber,
    serviceDeliveryPrint,
    classes,
    filteredCustomRecords,
    serviceDeliveryRecordsPractitioner
  } = props;
  const moneyManagementsRecord = filteredCustomRecords.find((r) => {
    return (
      r.category_type ===
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.money_management
    );
  });

  // 指定のデフォルト項目のデフォルト選択肢の値を取得
  const getRecordInput = (
    defaultItem: number,
    defaultChoice: number
  ): string => {
    if (!moneyManagementsRecord) return "0";
    if (!serviceDeliveryRecordsPractitioner) return "0";
    const item = moneyManagementsRecord.custom_record_items.find(
      (i) => i.default_item === defaultItem
    );
    if (!item) return "0";
    const choice = item.custom_record_item_choices.find(
      (c) => c.default_choice === defaultChoice
    );
    if (!choice) return "0";
    const choiceId = choice.id;
    const categoryId = item.custom_records_category_id;
    const category = serviceDeliveryRecordsPractitioner.find(
      (p) => p.custom_records_category_id === categoryId
    );
    const input =
      category && category.input.find((i) => i.choiced_item_id === choiceId);
    const inputData = input && input.input_data;
    return inputData || "0";
  };

  const getDefaultChoiceCost = (): number => {
    switch (props.facilityType) {
      case FacilityType.DOKOENGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.cost;
      case FacilityType.IDOSHIEN:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.cost;
      default:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.cost;
    }
  };

  const serviceCustomRecordDefaultChoiceCost = getDefaultChoiceCost();

  const getDefaultChoiceDeposit = (): number => {
    switch (props.facilityType) {
      case FacilityType.DOKOENGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.deposit;
      case FacilityType.IDOSHIEN:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit;
      default:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.deposit;
    }
  };

  const serviceCustomRecordDefaultChoiceDeposit = getDefaultChoiceDeposit();
  // 費用負担：（交通費）
  const travelingExpensesInput = getRecordInput(
    serviceCustomRecordDefaultChoiceCost,
    SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.traveling_expenses
  );
  // 費用負担：（その他費用）
  const otherExpenseInput = getRecordInput(
    serviceCustomRecordDefaultChoiceCost,
    SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.other_expense
  );
  // 費用負担：費用合計
  const totalInput = getRecordInput(
    serviceCustomRecordDefaultChoiceCost,
    SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.total
  );
  // 費用負担：うち利用者負担額
  const userBurdenInput = getRecordInput(
    serviceCustomRecordDefaultChoiceCost,
    SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.user_burden
  );
  // 所持金・預かり金：所持金・預かり金
  const moneyAndDepositInput = getRecordInput(
    serviceCustomRecordDefaultChoiceDeposit,
    SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit
  );
  // 所持金・預かり金：使用額
  const usedMoneyInput = getRecordInput(
    serviceCustomRecordDefaultChoiceDeposit,
    SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
  );
  // 所持金・預かり金：残金
  const balanceInput = getRecordInput(
    serviceCustomRecordDefaultChoiceDeposit,
    SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.balance
  );

  const destinations: GetServiceDeliveryPrint["data"][0]["service_delivery_record_practitioners"][0]["service_delivery_record_practitioner_destinations"] = get(
    serviceDeliveryPrint,
    `service_delivery_record_practitioners[${
      practitionerNumber - 1
    }].service_delivery_record_practitioner_destinations`
  );
  const moneyManagements: GetServiceDeliveryPrint["data"][0]["service_delivery_record_practitioners"][0]["service_delivery_record_practitioner_money_managements"] = get(
    serviceDeliveryPrint,
    `service_delivery_record_practitioners[${
      practitionerNumber - 1
    }].service_delivery_record_practitioner_money_managements`
  );
  const travelingExpenses = moneyManagements
    ? moneyManagements.traveling_expenses
    : [];
  const otherExpenses = moneyManagements ? moneyManagements.other_expenses : [];

  // 長い方分の行を生成する
  const expensesLength =
    travelingExpenses.length > otherExpenses.length
      ? travelingExpenses.length
      : otherExpenses.length;

  // 交通費の行を作成
  const getExpenseRows = (): JSX.Element[] => {
    const row = [];
    let traveling: GetServiceDeliveryPrintExpenses | undefined;
    let other: GetServiceDeliveryPrintExpenses | undefined;
    let travelingRow: JSX.Element;
    let otherRow: JSX.Element;
    let key: number;
    const getTransportationLabel = (number: number): string => {
      const select = SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT.find(
        (s) => +s.value === number
      );
      return select ? select.label : "";
    };
    for (let i = 0; i < expensesLength; i += 1) {
      traveling = travelingExpenses[i];
      other = otherExpenses[i];
      travelingRow = traveling ? (
        <div className={classes.spaceBetween}>
          {traveling.free_text ? (
            <div>{traveling.free_text}</div>
          ) : (
            <div>{getTransportationLabel(traveling.transportation) || "-"}</div>
          )}
          <div>{traveling.amount_money || "0"}円</div>
        </div>
      ) : (
        <></>
      );
      otherRow = other ? (
        <div className={classes.spaceBetween}>
          <div>{other.free_text || "-"}</div>
          <div>{other.amount_money || "0"}円</div>
        </div>
      ) : (
        <></>
      );
      key = traveling
        ? traveling.service_delivery_record_practitioner_money_managements_id
        : other.service_delivery_record_practitioner_money_managements_id;
      row.push(
        <tr key={`moneyManagements-${practitionerNumber}-${key}`}>
          <td className="sizeCategory noBorder bgColor" />
          <td className="sizeItem noBorder verticalTop bgColor" />
          <td className="sizeExpense borderDashedBottom">{travelingRow}</td>
          <td className="sizeExpense borderDashedBottom">{otherRow}</td>
        </tr>
      );
    }
    // 合計
    row.push(
      <tr key={`moneyManagements-${practitionerNumber}-total`}>
        <td className="sizeCategory noBorder bgColor" />
        <td className="sizeItem noBorder verticalTop bgColor" />
        <td className="sizeExpense borderTop">
          <div className={classes.spaceBetween}>
            <div>合計</div>
            <div>{travelingExpensesInput}円</div>
          </div>
        </td>
        <td className="sizeExpense borderTop">
          <div className={classes.spaceBetween}>
            <div>合計</div>
            <div>{otherExpenseInput}円</div>
          </div>
        </td>
      </tr>
    );
    // 費用合計
    row.push(
      <tr key={`moneyManagements-${practitionerNumber}-total2`}>
        <td className="sizeCategory noBorder bgColor" />
        <td className="sizeItem noBorder bgColor" />
        <td className="borderTop" colSpan={2}>
          費用合計：{totalInput}円 （うち利用者負担額：{userBurdenInput}円）
        </td>
      </tr>
    );
    // 所持金・預かり金
    row.push(
      <tr key={`moneyManagements-${practitionerNumber}-deposit`}>
        <td className="sizeCategory noBorder bgColor" />
        <td className="sizeItem borderTop bgColor">所持金・預かり金</td>
        <td className="borderTop" colSpan={2}>
          所持金・預かり金：{moneyAndDepositInput}円&nbsp;使用額：
          {usedMoneyInput}円&nbsp;残金：{balanceInput}円
        </td>
      </tr>
    );
    return row;
  };
  return (
    <>
      {destinations && destinations.length > 0 && (
        <div style={{ marginTop: 16 }}>
          <table className={`${classes.table}`} style={{ width: "100%" }}>
            <tbody>
              <tr key={`destination-${practitionerNumber}-0`}>
                <td className="sizeCategory noBorder topBorder verticalTop bgColor">
                  行き先
                </td>
                <td className="sizeTime verticalTop borderDashed bgColor">
                  時間
                </td>
                <td className="sizePlace verticalTop borderDashed bgColor">
                  場所
                </td>
                <td className="sizeSupport verticalTop bgColor">支援内容</td>
              </tr>
              {destinations.map((d) => (
                <tr
                  key={`destination-${practitionerNumber}-${d.service_delivery_record_practitioner_destinations_id}`}
                >
                  <td className="sizeCategory noBorder verticalTop bgColor" />
                  <td className="sizeTime verticalTop borderDashed">
                    {d.when_time ? format(d.when_time, "HH:mm") : "--:--"}
                  </td>
                  <td className="sizePlace verticalTop borderDashed">
                    {d.place || "-"}
                  </td>
                  <td className="sizeSupport verticalTop">
                    {d.support || "-"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {expensesLength > 0 && (
        <div style={{ marginTop: 16 }}>
          <table className={`${classes.table}`} style={{ width: "100%" }}>
            <tbody>
              <tr key={`moneyManagements-${practitionerNumber}-0`}>
                <td className="sizeCategory noBorder topBorder verticalTop bgColor">
                  金銭管理
                </td>
                <td className="sizeItem noBorder verticalTop bgColor">
                  費用負担
                </td>
                <td className="sizeExpense verticalTop bgColor">交通費</td>
                <td className="sizeExpense verticalTop bgColor">その他費用</td>
              </tr>
              {getExpenseRows()}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export const ServiceDeliveryPrintMoneyManagementsTableBody = withStyles(styles)(
  ServiceDeliveryPrintMoneyManagementsTableBodyCore
);
