/**
 * 時間範囲が指定内か(Date型で判定)
 */

export const checkTimeRangeLimitDateType = (
  startTime: Date,
  outTime: Date,
  limit: number,
  option = "時間"
): string | undefined => {
  let errorMessage;

  const diff = outTime.getTime() - startTime.getTime();
  if (diff / (60 * 1000) > limit * 60) {
    errorMessage = `${option}は${limit}時間以内で入力してください`;
  }

  return errorMessage;
};
