import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { meetingRecordApi } from "@api/requests/meetingRecord";
import { normalizePostMeetingRecordDataToAPI } from "./normalizer";
import dispatches from "@stores/dispatches";
import { MeetingRecordValues } from "@initialize/mgr/Cseg/record/meetingRecord/initialValues";

export const fetchMeetingRecord = (dispatch: Dispatch) => async (
  uifId: string,
  meetingRecordId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchMeetingRecordStarted());
  await meetingRecordApi
    .getMeetingRecord(uifId, meetingRecordId)
    .then((res) => {
      dispatch(actions.fetchMeetingRecordSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchMeetingRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postMeetingRecord = (dispatch: Dispatch) => async (
  uifId: number,
  param: MeetingRecordValues,
  initialValues?: MeetingRecordValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postMeetingRecordStarted());
  const normalizedParam = normalizePostMeetingRecordDataToAPI(
    param,
    initialValues
  );

  await meetingRecordApi
    .postMeetingRecord(uifId, normalizedParam)
    .then(() => {
      dispatch(actions.postMeetingRecordSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postMeetingRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteMeetingRecord = (dispatch: Dispatch) => async (
  uifId: number,
  meetingRecordId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteMeetingRecordStarted());
  await meetingRecordApi
    .deleteMeetingRecord(uifId, meetingRecordId)
    .then(() => {
      dispatch(actions.deleteMeetingRecordSuccess());
    })
    .catch((e) => {
      dispatch(actions.deleteMeetingRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchMeetingRecord: ReturnType<typeof fetchMeetingRecord>;
  postMeetingRecord: ReturnType<typeof postMeetingRecord>;
  deleteMeetingRecord: ReturnType<typeof deleteMeetingRecord>;
};

export const CsegMeetingRecordDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchMeetingRecord: fetchMeetingRecord(dispatch),
  postMeetingRecord: postMeetingRecord(dispatch),
  deleteMeetingRecord: deleteMeetingRecord(dispatch)
});
