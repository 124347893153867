import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { SupportRecordUserState } from "@stores/domain/supportRecordUser/types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import dispatches from "@stores/dispatches";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import ClassNames from "classnames";
import { SUPPORT_METHOD_ITEMS } from "@constants/mgr/SHUROTEICHAKU/variables";

// utils
import { getUrlParams } from "@/utils/url";
import { dateToLocalisedString } from "@/utils/date";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 24
    },
    title: {
      margin: 0,
      marginTop: 14,
      fontSize: 18,
      fontWeight: "normal",
      letterSpacing: 1.2,
      color: "rgba(0, 0, 0, 0.84)"
    },
    userBasicInfo: {
      alignItems: "end",
      marginTop: 28
    },
    createdDate: {
      letterSpacing: "0.1em",
      fontSize: 0,
      "&:last-child": {
        marginTop: 8
      }
    },
    tableContainer: {
      fontSize: 10,
      marginBottom: 20,
      whiteSpace: "pre-line"
    },
    tableContainerBottom16: {
      fontSize: 10,
      marginBottom: 16,
      whiteSpace: "pre-line"
    },
    tableTitle: {
      fontWeight: "bold",
      borderBottom: "1px solid #000",
      paddingBottom: 5,
      marginBottom: 8,
      display: "flex",
      justifyContent: "space-between"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      tableLayout: "fixed",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      "&.fullWidth": {
        width: "100%"
      },
      "& th": {
        fontWeight: "normal",
        borderRight: "1px solid",
        padding: "3px 8px",
        verticalAlign: "top"
      },
      "& td": {
        padding: "4px 12px",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 24,
        color: "#424242",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    primaryTableHead: {
      width: 120
    },
    facilityStaff: {
      display: "flex",
      marginTop: 22
    },
    staffBox: {
      width: 72,
      height: 80,
      paddingTop: 4,
      margin: 0,
      lineHeight: 1,
      border: "1px solid #212121",
      borderRight: "none",
      fontSize: 0,
      color: "#424242",
      textAlign: "center",
      "&:last-child": {
        borderRight: "1px solid #212121"
      }
    },
    staffName: {
      fontSize: 10,
      transform: "scale(0.8)",
      margin: 0,
      display: "inline-block"
    },
    staffName2: {
      fontSize: 10,
      transform: "scale(0.8)",
      margin: 0
    },
    servicePerson: {
      paddingRight: 5,
      paddingLeft: 5,
      textAlign: "left"
    },
    basicInfoText: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      "&:first-child": {
        marginRight: 20
      },
      "&:nth-child(2)": {
        marginRight: 24
      }
    },
    signatureArea: {
      fontSize: 10
    },
    signature: {
      display: "flex",
      alignItems: "center"
    },
    entryFieldMark: {
      color: "#9E9E9E",
      fontSize: 10,
      lineHeight: 1.4
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  supportsRecord: SupportRecordUserState;
  userInFacility: UsersInFacilityState["user"];
};
type OwnProps = {
  uifId: string;
  year: string;
  month: string;
  query: string;
};

type DispatchProps = {
  fetchSupportRecordUser: (uifId: string, year: string, month: string) => void;
  fetchOne: (id: string) => void;
};
type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  displayColumns: string[];
  userInFacility: UsersInFacilityState["user"];
  support: SupportRecordUserState["support"][0];
};
type SectionTableOwnProps = {
  sectionTitle?: string;
  sectionData: {
    itemTitle: string;
    content: string | null;
  }[];
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type SectionTableProps = SectionTableOwnProps & WithStyles<typeof styles>;

const SupportSHUROTEICHAKUPrintCore = (props: Props): JSX.Element => {
  const [displayColumns, setDisplayColumns] = React.useState([] as string[]);

  React.useEffect(() => {
    const { uifId, year, month, query } = props;
    props.fetchOne(uifId);
    props.fetchSupportRecordUser(uifId, year, month);
    const queryParameters: { display_columns?: string } = getUrlParams(query);
    if (queryParameters.display_columns) {
      setDisplayColumns(queryParameters.display_columns.split(","));
    }
  }, []);

  const { classes, userInFacility, supportsRecord } = props;

  return (
    <>
      {supportsRecord.support
        .filter((support) => support.record.id)
        .map((support) => {
          return (
            <Sheet
              classes={classes}
              displayColumns={displayColumns}
              userInFacility={userInFacility}
              support={support}
              key={`support_print_${support.record.id}`}
            />
          );
        })}
    </>
  );
};

const SectionTable = (props: SectionTableProps): JSX.Element => {
  const { classes, sectionTitle, sectionData } = props;

  return (
    <section>
      {sectionTitle && (
        <div className={classes.tableTitle}>
          <span>{sectionTitle}</span>
        </div>
      )}

      <table className={`${classes.table} fullWidth`}>
        <tbody>
          {sectionData.map((data, index) => {
            const keyNumber = index;
            return (
              <tr key={keyNumber}>
                <th className={ClassNames(classes.primaryTableHead)}>
                  {data.itemTitle}
                </th>
                <td>{data.content}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, userInFacility, displayColumns, support } = props;

  // 作成者名
  const author = support.record.author ? support.record.author : 0;
  const authorValue = getSnapOrRealName(author, "-");

  // 雇用事業主
  const companyName = support.record.company_name
    ? support.record.company_name
    : "-";

  // 支援の開始/終了時間表示のロジック
  let supportInTime = "--:--";
  if (support.usage_results.support_start_time) {
    supportInTime = convertHHMMSSToHHMM(
      support.usage_results.support_start_time
    );
  }
  let supportOutTime = "--:--";
  if (support.usage_results.support_end_time) {
    supportOutTime = convertHHMMSSToHHMM(
      support.usage_results.support_end_time
    );
  }
  const supportInOutTime = `${supportInTime}〜${supportOutTime}`;

  // 支援日時
  const supportDateTime = `${dateToLocalisedString(
    support.usage_results.target_date,
    "YYYY年M月D日"
  )} ${
    !support.usage_results.support_start_time &&
    !support.usage_results.support_end_time
      ? "-"
      : supportInOutTime
  }`;

  // 支援実施場所・方法
  // 改行「\n」除去
  const supportMethod =
    support.usage_results.support_method === 6
      ? support.usage_results.support_method_other
      : SUPPORT_METHOD_ITEMS[
          support.usage_results.support_method
        ].label.replace(/\r?\n/g, "");

  // 支援実績
  const supportData = [
    {
      itemTitle: "支援日時",
      content: supportDateTime
    },
    {
      itemTitle: "支援実施場所・方法",
      content: support.usage_results.support_method === 0 ? "" : supportMethod
    },
    {
      itemTitle: "当日の目的",
      content: support.record.todays_purpose
    }
  ];

  // 生活習慣や日常生活の状況
  const lifeData = [
    {
      itemTitle: "現状と課題",
      content: support.record.life_current_issues
    },
    {
      itemTitle: "支援内容",
      content: support.record.life_support_content
    }
  ];

  // 職場での状況
  const officeData = [
    {
      itemTitle: "現状と課題",
      content: support.record.office_current_issues
    },
    {
      itemTitle: "支援内容",
      content: support.record.office_support_content
    }
  ];

  // 関係機関の利用状況
  const relatedOrganizationsData = [
    {
      itemTitle: "関係機関の利用状況",
      content: support.record.usage_status_of_related_organizations
    }
  ];

  // その他特記事項
  const otherData = [
    {
      itemTitle: "その他特記事項",
      content: support.record.notices
    }
  ];

  // 次回の支援予定
  const nextSupportData = [
    {
      itemTitle: "時期",
      content: support.record.next_support_date
    },
    {
      itemTitle: "場所・方法",
      content: support.record.next_support_method
    },
    {
      itemTitle: "目的",
      content: support.record.next_support_plans
    }
  ];

  // 職員考察
  const staffCommentData = [
    {
      itemTitle: "職員考察",
      content: support.record.staff_comment
    }
  ];

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div>
          <h1 className={classes.title}>
            {displayColumns.includes("support_record")
              ? "就労定着支援記録票"
              : "就労定着サービス提供記録票"}
          </h1>
          <div className={classes.userBasicInfo}>
            <div className={classes.createdDate}>
              <span className={classes.basicInfoText}>{`作成日: ${
                support.record.creation_date
                  ? dateToLocalisedString(
                      support.record.creation_date,
                      "YYYY年M月D日"
                    )
                  : "-"
              }`}</span>
              <span
                className={classes.basicInfoText}
              >{`作成者 : ${authorValue}`}</span>
              <span className={classes.entryFieldMark}>印</span>
            </div>
            <div className={classes.createdDate}>
              <span
                className={classes.basicInfoText}
              >{`利用者名: ${userInFacility.user_in_facility.name_sei} ${userInFacility.user_in_facility.name_mei}`}</span>
              <span className={classes.basicInfoText}>
                {`雇用事業主 : ${companyName}`}
              </span>
            </div>
          </div>
        </div>
        <div className={classes.facilityStaff}>
          <div className={classes.staffBox}>
            <div className={classes.staffName}>管理者</div>
          </div>
          <div className={classes.staffBox}>
            <div className={classes.servicePerson}>
              <div className={classes.staffName2}>サービス管理</div>
              <div className={classes.staffName2}>責任者</div>
            </div>
          </div>
          <div className={classes.staffBox}>
            <div className={classes.staffName}>就労定着支援員</div>
          </div>
        </div>
      </div>

      <div className={classes.tableContainer}>
        <SectionTable classes={classes} sectionData={supportData} />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="生活習慣や日常生活の状況"
          sectionData={lifeData}
        />
      </div>

      <div className={classes.tableContainerBottom16}>
        <SectionTable
          classes={classes}
          sectionTitle="職場での状況"
          sectionData={officeData}
        />
      </div>

      <div className={classes.tableContainerBottom16}>
        <SectionTable
          classes={classes}
          sectionData={relatedOrganizationsData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable classes={classes} sectionData={otherData} />
      </div>

      <div className={classes.tableContainerBottom16}>
        <SectionTable
          classes={classes}
          sectionTitle="次回の支援予定"
          sectionData={nextSupportData}
        />
      </div>
      {displayColumns.includes("staff_comment") && (
        <div className={classes.tableContainer}>
          <SectionTable classes={classes} sectionData={staffCommentData} />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const userInFacility = state.SHUROTEICHAKU.userInFacility.user;
  const supportsRecord = state.supportRecordUser;
  return {
    supportsRecord,
    userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHUROTEICHAKU, supportRecordUserDispatcher } = dispatches;
  const userInFacilityDispatcher = SHUROTEICHAKU.userInFacilityDispatcher(
    dispatch
  );
  const supportRecordUserDispatches = supportRecordUserDispatcher(dispatch);

  return {
    fetchSupportRecordUser: (
      uifId: string,
      year: string,
      month: string
    ): void => {
      supportRecordUserDispatches.fetchSupportRecordUser(uifId, year, month);
    },
    fetchOne: userInFacilityDispatcher.fetchOne
  };
};

export const SupportSHUROTEICHAKUPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SupportSHUROTEICHAKUPrintCore));
