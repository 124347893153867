import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import format from "date-fns/format";
import getAge from "@utils/date/getAge";

import {
  FacilityType,
  DISABILITY_CLASS_LIST,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON
} from "@constants/variables";
import { UsersInFacilityState as UsersInFacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

const styles = (): StyleRules =>
  createStyles({
    MT14: {
      marginTop: 14
    },
    MT16: {
      marginTop: 16
    },
    MT24: {
      marginTop: 24
    },
    ML16: {
      marginLeft: 16
    },
    flex: {
      display: "flex",
      flexWrap: "wrap"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 2,
      minHeight: 20
    },
    conItem: {
      display: "block",
      "&:nth-child(3)": {
        minWidth: 270
      },
      "&:not(:nth-child(3n+1))": {
        marginLeft: 16
      }
    },
    tableArea: {
      border: "solid 2px #212121"
    },
    dl: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      display: "flex",
      margin: 0,
      "&+&": {
        borderTopStyle: "solid",
        borderTopColor: "#212121"
      }
    },
    dt: {
      width: 110,
      padding: "4px 6px",
      margin: 0,
      borderRight: "1px solid #212121"
    },
    dd: {
      width: "calc(100% - 110px)",
      padding: "4px 6px",
      margin: 0,
      wordBreak: "break-word"
    },
    normalBorder: {
      borderTopWidth: "1px"
    },
    boldBorder: {
      borderTopWidth: "2px"
    },
    W243: {
      width: 243
    },
    W274: {
      width: 274
    },
    W163: {
      width: 163
    },
    W64: {
      width: 64
    },
    W194: {
      width: 194
    },
    WResidue: {
      width: "calc(100% - 243px - 274px - 16px - 16px)"
    },
    W100: {
      width: "100%"
    }
  });
type OwnProps = {
  cityLabel: string;
  facilityType: FacilityType;
  userInFacility:
    | UsersInFacilityStateKYOTAKUKAIGO["user"]["user_in_facility"]
    | UsersInFacilityStateJUDOHOMONKAIGO["user"]["user_in_facility"]
    | UsersInFacilityStateDOKOENGO["user"]["user_in_facility"]
    | UsersInFacilityStateKODOENGO["user"]["user_in_facility"];
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
};
type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanPrintContentBaseCore = (props: Props): JSX.Element => {
  const {
    classes,
    cityLabel,
    facilityType,
    userInFacility,
    supportCarePlan
  } = props;

  // 契約支給量
  const [payMonths, setPayMonths] = React.useState<string[]>([]);
  const payMonthsListKYOTAKUKAIGO = [
    {
      value: supportCarePlan.pay_months_agreed_physical_care,
      label: "身体介護",
      unit: "時間/月"
    },
    {
      value:
        supportCarePlan.pay_months_agreed_outpatient_care_with_physical_care,
      label: "通院等介助（身体介護伴う）",
      unit: "時間/月"
    },
    {
      value: supportCarePlan.pay_months_agreed_housework_assistance,
      label: "通院等乗降介助",
      unit: "時間/月"
    },
    {
      value: supportCarePlan.pay_months_agreed_outpatient_care,
      label: "家事援助",
      unit: "時間/月"
    },
    {
      value: supportCarePlan.pay_months_agreed_getting_on_and_off,
      label: "通院等介助（身体介護伴わず）",
      unit: "時間/月"
    }
  ];
  const payMonthsListJUDOHOMONKAIGO = [
    {
      value:
        supportCarePlan.support_care_plans_judohomonkaigo &&
        supportCarePlan.support_care_plans_judohomonkaigo
          .pay_months_agreed_severe_home_visit_care,
      label: "重度訪問介護",
      unit: "時間/月"
    },
    {
      value:
        supportCarePlan.support_care_plans_judohomonkaigo &&
        supportCarePlan.support_care_plans_judohomonkaigo
          .pay_months_agreed_guide_severe_home_visit_care,
      label: "移動介護",
      unit: "時間/月"
    }
  ];
  const payMonthsListDOKOENGO = [
    {
      value:
        supportCarePlan.support_care_plans_dokoengo &&
        supportCarePlan.support_care_plans_dokoengo
          .pay_months_agreed_accompanying_support,
      label: "同行援護",
      unit: "時間/月"
    }
  ];
  const payMonthsListKODOENGO = [
    {
      value:
        supportCarePlan.support_care_plans_kodoengo &&
        supportCarePlan.support_care_plans_kodoengo
          .pay_months_agreed_accompanying_support,
      label: "行動援護",
      unit: "時間/月"
    }
  ];
  React.useEffect(() => {
    const payMonthsArray: string[] = [];
    let payMonthsList = [];
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        payMonthsList = payMonthsListKYOTAKUKAIGO;
        break;
      case FacilityType.JUDOHOMONKAIGO:
        payMonthsList = payMonthsListJUDOHOMONKAIGO;
        break;
      case FacilityType.DOKOENGO:
        payMonthsList = payMonthsListDOKOENGO;
        break;
      case FacilityType.KODOENGO:
        payMonthsList = payMonthsListKODOENGO;
        break;
      default:
        payMonthsList = payMonthsListKYOTAKUKAIGO;
    }
    payMonthsList.forEach((item) => {
      if (item.value !== null) {
        payMonthsArray.push(
          [item.label, `${item.value}${item.unit}`].join("　")
        );
      }
    });
    setPayMonths(payMonthsArray);
  }, [supportCarePlan]);

  // 障害支援区分
  const classifyDisabilitySupport = (): string => {
    if (supportCarePlan.classify_handicapped_flg === 1) {
      return "-";
    }
    return DISABILITY_CLASS_LIST.filter(
      (f) => f.value === `${supportCarePlan.classify_disability_support}`
    ).length !== 0
      ? DISABILITY_CLASS_LIST.filter(
          (f) => f.value === `${supportCarePlan.classify_disability_support}`
        )[0].label
      : "";
  };

  // 家事援助中心型を算定する理由
  const houseworkAssistanceBaseValue = (): null | string => {
    if (
      supportCarePlan.housework_assistance_base_type_reason === undefined ||
      supportCarePlan.housework_assistance_base_type === 0 ||
      supportCarePlan.housework_assistance_base_type_reason === null ||
      supportCarePlan.housework_assistance_base_type_reason === 0
    ) {
      return null;
    }

    const reason =
      HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON[
        supportCarePlan.housework_assistance_base_type_reason
      ].label;

    const comment =
      supportCarePlan.housework_assistance_base_type_comment === null
        ? ""
        : `（${supportCarePlan.housework_assistance_base_type_comment}）`;

    if (supportCarePlan.housework_assistance_base_type_reason === 3) {
      return `${reason}${comment}`;
    }
    return reason;
  };

  // 住所
  const postalCodeAndRestAddress = (): string => {
    if (
      supportCarePlan.postal_code === null &&
      supportCarePlan.address === null
    ) {
      return "-";
    }

    const postalCode =
      supportCarePlan.postal_code === null
        ? ""
        : `〒${supportCarePlan.postal_code}`;
    const restAddress =
      supportCarePlan.address === null ? "" : `${supportCarePlan.address}`;
    const prefectureAndCityName = [
      supportCarePlan.prefecture_name,
      cityLabel,
      restAddress
    ]
      .filter(Boolean)
      .join("");

    return [postalCode, prefectureAndCityName].filter(Boolean).join(" ");
  };

  // 受給者証認定有効期間
  const datePayPeriod = (): string => {
    if (
      supportCarePlan.date_pay_begin === null &&
      supportCarePlan.date_pay_end === null
    ) {
      return "-";
    }

    return `${
      supportCarePlan.date_pay_begin === null
        ? "-"
        : format(supportCarePlan.date_pay_begin, "YYYY年M月D日")
    }〜${
      supportCarePlan.date_pay_end === null
        ? "-"
        : format(supportCarePlan.date_pay_end, "YYYY年M月D日")
    }`;
  };

  // ベース項目
  const baseList = [
    {
      label: "氏名（フリガナ）",
      className: ClassNames(classes.W243),
      data: `${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`
    },
    {
      label: "住所",
      className: ClassNames(classes.W274, classes.ML16),
      data: postalCodeAndRestAddress()
    },
    {
      label: "連絡先",
      className: ClassNames(classes.WResidue, classes.ML16),
      data: supportCarePlan.tel === null ? "-" : supportCarePlan.tel
    },
    {
      label: "生年月日（年齢）",
      className: ClassNames(classes.W163, classes.MT14),
      data: userInFacility.date_birth
        ? `${format(userInFacility.date_birth, "YYYY年M月D日")}（${getAge(
            userInFacility.date_birth
          )}歳）`
        : "-"
    },
    {
      label: "性別",
      className: ClassNames(classes.W64, classes.MT14, classes.ML16),
      data: userInFacility.gender === "1" ? "男性" : "女性"
    },
    {
      label: "障害支援区分",
      className: ClassNames(classes.W64, classes.MT14, classes.ML16),
      disabilityClassFlg: true,
      data: classifyDisabilitySupport()
    },
    {
      label: "受給者証認定有効期間",
      className: ClassNames(classes.W194, classes.MT14, classes.ML16),
      data: datePayPeriod()
    },
    {
      label: "契約開始日",
      className: ClassNames(classes.WResidue, classes.MT14, classes.ML16),
      data: supportCarePlan.date_begin_in_service
        ? `${format(supportCarePlan.date_begin_in_service, "YYYY年M月D日")}`
        : "-"
    },
    {
      label: "契約支給量",
      className: ClassNames(classes.W100, classes.MT14),
      data: payMonths,
      dataFlg: true
    }
  ];
  const infoListDefault = [
    {
      classes: ClassNames(classes.tableArea, classes.MT16),
      data: [
        {
          label: (
            <span>
              家事援助中心型を
              <br />
              算定する理由
            </span>
          ),
          className: ClassNames(classes.dl, classes.normalBorder),
          data: houseworkAssistanceBaseValue()
        },
        {
          label: <span>他事業所利用状況</span>,
          className: ClassNames(classes.dl, classes.normalBorder),
          data: supportCarePlan.usage_other_facility
        }
      ]
    },
    {
      classes: ClassNames(classes.tableArea, classes.MT16),
      data: [
        {
          label: <span>本人・家族の意向</span>,
          className: ClassNames(classes.dl, classes.normalBorder),
          data: supportCarePlan.user_request
        },
        {
          label: <span>本人の現状と課題</span>,
          className: ClassNames(classes.dl, classes.normalBorder),
          data: supportCarePlan.user_issue
        },
        {
          label: <span>援助の目標・方向性</span>,
          className: ClassNames(classes.dl, classes.boldBorder),
          data: supportCarePlan.goals_direction_assistance
        }
      ]
    }
  ];
  const infoList = infoListDefault.filter((m) => {
    const res = m;
    res.data = m.data.filter((f) => {
      return f.data !== null;
    });
    return res.data.length !== 0;
  });

  return (
    <>
      {/* 項目 */}
      <div className={ClassNames(classes.flex, classes.MT24)}>
        {baseList.map((m, index) => {
          const uniqueKey = `uniqueBaseListKey-${index}`;
          return (
            <div className={m.className && m.className} key={uniqueKey}>
              <div className={classes.label}>{m.label}</div>
              <div className={classes.con}>
                {m.dataFlg ? (
                  <div className={classes.flex}>
                    {m.data.map((i, idx) => {
                      const dataUniqueKey = `uniqueBaseListDataKey-${idx}`;
                      return (
                        <span className={classes.conItem} key={dataUniqueKey}>
                          {i}
                        </span>
                      );
                    })}
                  </div>
                ) : (
                  m.data
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* デーブル */}
      {infoList.map((m, index) => {
        const uniqueKey = `uniqueInfoListKey-${index}`;
        return (
          <div className={m.classes} key={uniqueKey}>
            {m.data.map((i, idx) => {
              const dataUniqueKey = `uniqueInfoListDataKey-${idx}`;
              return (
                <dl className={i.className} key={dataUniqueKey}>
                  <dt className={classes.dt}>{i.label}</dt>
                  <dd className={classes.dd}>{i.data}</dd>
                </dl>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export const CarePlanPrintContentBase = withStyles(styles)(
  CarePlanPrintContentBaseCore
);
