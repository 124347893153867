/**
 * ActionNames
 */

export const FETCH_STARTED = "CHIIKIIKO/FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "CHIIKIIKO/FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "CHIIKIIKO/FACILITY/FETCH_FAILED";
export const POST_STARTED = "CHIIKIIKO/FACILITY/POST_STARTED";
export const POST_SUCCESS = "CHIIKIIKO/FACILITY/POST_SUCCESS";
export const POST_FAILED = "CHIIKIIKO/FACILITY/POST_FAILED";

/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  capacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
  multiFunctionOfficeFlag: boolean;
  masterSubordinateFlg: boolean;
  masterFlg: string;
  allCapacity: string;

  // BasicRemuneration
  chiikiikoServiceType: string;

  // FacilityAdditionItemSection
  peerSupportFlg: boolean;
  lifeSupportHubInDistrictFlg: boolean;
  lifeSupportFunctionPromotionFlg: boolean;
  lifeSupportFunctionPromotionTimes: string;

  // SubtractionItemSection
  abusePreventionMeasuresNotImplementedFlg: boolean;
  abusePreventionMeasuresNotImplementedFrom: string;
  abusePreventionMeasuresNotImplementedTo: string;
  informationDisclosureNotReportedFlg: boolean;
  informationDisclosureNotReportedFrom: string;
  informationDisclosureNotReportedTo: string;
};
