import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
// ui
import { FormGroup } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import IconAdd from "@material-ui/icons/Add";
// formik
import { FormikProps, FieldArray } from "formik";
import {
  practitionerExpensesInitialValue,
  ServiceDeliveryDetailValues,
  ExpensesValues
} from "@initialize/record/serviceDelivery/initialValues";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
// variables
import {
  FacilityType,
  SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT,
  SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT_VALUE
} from "@constants/variables";
import get from "lodash-es/get";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    title: {
      marginTop: 16,
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)"
    },
    table: {
      width: "100%",
      marginTop: 8
    },
    head1: {
      width: 232,
      paddingLeft: 16
    },
    head2: {
      width: 156
    },
    head3: {
      textAlign: "right",
      paddingRight: "18px !important"
    },
    iconCell: {
      textAlign: "right",
      verticalAlign: "top",
      display: "inline-flex",
      alignContent: "flex-end",
      width: "100%",
      marginRight: 16
    },
    addButton: {
      marginTop: 24
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  practitionerNum: number;
  facilityType: FacilityType;
  handleBlurMoney: (event: React.FormEvent<HTMLInputElement>) => string | void;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  target: string;
  handleBlurExpenses: (
    target: string,
    i: number
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
  changeExpenses: (
    target: string,
    i: number,
    event?: React.FormEvent<HTMLInputElement>
  ) => void;
} & WithStyles<typeof styles>;

const ServiceDeliveryDetailExpensesCore = (props: Props): JSX.Element => {
  const { values } = props.formikProps;
  const { classes } = props;
  const content =
    props.target === "other_expenses"
      ? { title: "その他費用", head: "費用内訳" }
      : { title: "交通費", head: "交通手段" };
  const headData = [
    {
      label: content.head,
      className: `${classes.head1}`
    },
    {
      label: "金額",
      className: `${classes.head2}`
    },
    {
      label: "削除",
      className: `${classes.head3}`
    }
  ];
  const expensesKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.serviceDeliveryRecordPractitionerMoneyManagements.${props.target}`;
  const expenses: ExpensesValues = get(values, expensesKey);
  const isAddButton = expenses.filter((item) => !item.isDelete).length < 50;
  const bodyData = (
    i: number,
    onDeleteRecord: () => void
  ): KnowbeTableCellParam[] => {
    const field1 =
      props.target === "other_expenses" ? (
        <FormikTextField
          name={`${expensesKey}[${i}].free_text`}
          label=""
          style={{ width: 200, margin: "16px" }}
        />
      ) : (
        <>
          <FormikSelect
            name={`${expensesKey}[${i}].transportation`}
            label=""
            style={{ width: 200, margin: "16px" }}
            options={SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT}
            placeholder="選択してください"
            isSelectablePlaceholder
            negativeMarginLabel
          />
          {+get(values, `${expensesKey}[${i}].transportation`) ===
            SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT_VALUE.other && (
            <FormikTextField
              name={`${expensesKey}[${i}].free_text`}
              label=""
              style={{ width: 200, margin: "16px" }}
            />
          )}
        </>
      );
    return [
      {
        label: field1
      },
      {
        label: (
          <FormikTextField
            name={`${expensesKey}[${i}].amount_money`}
            size="smallMedium"
            style={{ width: 140, marginBottom: 0, marginRight: 16 }}
            endAdornmentLabel="円"
            onBlurHook={props.handleBlurExpenses(props.target, i)}
          />
        )
      },
      {
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 28,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onDeleteRecord}
            />
          </FormGroup>
        )
      }
    ];
  };

  // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
  const getBodyValues = (
    onDeleteExpenses: (i: number) => void
  ): KnowbeTableCellParam[][] => {
    return expenses
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((item) => {
        const onDeleteRecord = (): void => {
          onDeleteExpenses(item.baseIndex);
          props.changeExpenses(props.target, item.baseIndex);
        };
        return bodyData(item.baseIndex, onDeleteRecord);
      });
  };

  const onDeleteExpenses = (index: number): void => {
    props.formikProps.setFieldValue(`${expensesKey}[${index}].isDelete`, 1);
  };

  return (
    <FieldArray name={expensesKey}>
      {(arrayHelpers): JSX.Element => {
        const onClickAddExpenses = (): void => {
          arrayHelpers.push(practitionerExpensesInitialValue);
        };
        return (
          <div>
            <div className={classes.title}>{content.title}</div>
            {expenses.length && (
              <KnowbeTable className={classes.table}>
                <KnowbeTableHead
                  uniqueKey={content.title}
                  height={40}
                  items={headData}
                  backgroundColor="secondary"
                />
                <KnowbeTableBody
                  uniqueKey={content.title}
                  itemsContainer={getBodyValues(onDeleteExpenses)}
                  stripe={false}
                />
              </KnowbeTable>
            )}
            <KnowbeButton
              kind="iconText"
              className={classes.addButton}
              onClick={onClickAddExpenses}
              disabled={!isAddButton}
            >
              <IconAdd />
              {content.title}を追加する
            </KnowbeButton>
          </div>
        );
      }}
    </FieldArray>
  );
};

export const ServiceDeliveryDetailExpenses = withStyles(styles)(
  ServiceDeliveryDetailExpensesCore
);
