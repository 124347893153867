import {
  ReportState,
  InoutConsultationResultsState
} from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";
import { GetInOutConsultationResultsUsersResponse } from "@api/requests/inOutConsultationResults/getInOutConsultationResultsMonthly";
import {
  RequestParamToPostAPI,
  RequestParamMonthlyToPostAPI
} from "@api/requests/inOutConsultationResults/postInOutConsultationResultsMonthly";
import { InitialValues } from "@interfaces/mgr/CHIIKITEICHAKU/report/initial";
import deepEqual from "fast-deep-equal";
import { cloneDeep, isEmpty } from "lodash-es";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import {
  INT_TRUE_FROM_API,
  INT_FALSE_FROM_API,
  DEFAULT_CHECKBOX_VALUE
} from "@constants/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import { dateInYYYYMMFormat } from "@utils/date";

/**
 * 実績データ一覧の整形
 */
export const normalizeListFromAPI = (
  inoutConsultationResults: GetInOutConsultationResultsUsersResponse["data"]["inout_consultation_results"],
  date = ""
): InoutConsultationResultsState[] => {
  return inoutConsultationResults
    ? inoutConsultationResults.map((record) => {
        const targetDateState = record.target_date ? record.target_date : date;
        return {
          inout_consultation_results_id: record ? record.id : null,
          inoutResultDailyId:
            record && record.inout_result_daily_id
              ? record.inout_result_daily_id
              : null,
          usersInFacilityId: record.users_in_facility_id,
          targetDate: targetDateState,
          status: record ? record.status : null,
          housingSupportCooperationFlg: record
            ? record.housing_support_cooperation_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          housingSupportPromotionFlg: record
            ? record.housing_support_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          dailySupportProvisionOfInfoFlg: record
            ? record.daily_support_provision_of_info_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          noConstantContactSystemFlg: record
            ? record.no_constant_contact_system_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          memo: record ? record.memo : null,
          isHoliday: record.isHoliday ? record.isHoliday : false
        };
      })
    : [];
};

/**
 * 利用実績一覧(月ごと)
 */
export const normalizeCHIIKITEICHAKUMonthlyReportDataFromAPI = (
  result: GetInOutConsultationResultsUsersResponse,
  date: Date
): ReportState => {
  const normalizeReportList: ReportState = {
    inoutConsultationResults: normalizeListFromAPI(
      result.data.inout_consultation_results,
      dateInYYYYMMFormat(date)
    )
  };

  return normalizeReportList;
};

/**
 * formデータをAPIのrequestに変換
 */
export const normalizeFormValueToAPI = (
  formValues: ReportState,
  storeValues: ReportState
): RequestParamToPostAPI => {
  const inOutResults: RequestParamToPostAPI["inout_consultation_results"] = [];
  storeValues.inoutConsultationResults.forEach((stateValue, index) => {
    const formValue = formValues.inoutConsultationResults[index];

    if (formValue === undefined || deepEqual(stateValue, formValue)) {
      return;
    }

    const diffValue: RequestParamToPostAPI["inout_consultation_results"][0] = {
      inout_consultation_results_id: stateValue.inout_consultation_results_id
        ? stateValue.inout_consultation_results_id
        : null,
      inout_result_daily_id: stateValue.inoutResultDailyId
        ? stateValue.inoutResultDailyId
        : null,
      target_date: stateValue.targetDate || ""
    };

    if (formValue.status !== null && stateValue.status !== formValue.status) {
      diffValue.status = formValue.status;
    }
    if (
      formValue.housingSupportCooperationFlg !== null &&
      stateValue.housingSupportCooperationFlg !==
        formValue.housingSupportCooperationFlg
    ) {
      diffValue.housing_support_cooperation_flg =
        booleanToNumber0or1(formValue.housingSupportCooperationFlg) || 0;
    }
    if (
      formValue.housingSupportPromotionFlg !== null &&
      stateValue.housingSupportPromotionFlg !==
        formValue.housingSupportPromotionFlg
    ) {
      diffValue.housing_support_promotion_flg =
        booleanToNumber0or1(formValue.housingSupportPromotionFlg) || 0;
    }
    if (
      formValue.dailySupportProvisionOfInfoFlg !== null &&
      stateValue.dailySupportProvisionOfInfoFlg !==
        formValue.dailySupportProvisionOfInfoFlg
    ) {
      diffValue.daily_support_provision_of_info_flg =
        booleanToNumber0or1(formValue.dailySupportProvisionOfInfoFlg) || 0;
    }
    if (stateValue.memo !== formValue.memo) {
      diffValue.memo = formValue.memo || "";
    }

    inOutResults.push(diffValue);
  });

  return {
    inout_consultation_results: inOutResults
  };
};

/**
 * formデータをstoreデータに変換
 */
export const normalizeUsageResultFromForm = (
  formValues: InitialValues,
  storeValues: ReportState
): ReportState => {
  const inoutConsultationResults = storeValues.inoutConsultationResults.map(
    (storeValue, index) => {
      const convertReportValue: InoutConsultationResultsState = cloneDeep(
        storeValue
      );
      if (
        index < formValues.initial.inoutConsultationResults.length &&
        storeValue.targetDate ===
          formValues.initial.inoutConsultationResults[index].targetDate
      ) {
        convertReportValue.status = castNumber(
          formValues.initial.inoutConsultationResults[index].status
        );
        convertReportValue.housingSupportCooperationFlg =
          formValues.initial.inoutConsultationResults[
            index
          ].housingSupportCooperationFlg;
        convertReportValue.housingSupportPromotionFlg =
          formValues.initial.inoutConsultationResults[
            index
          ].housingSupportPromotionFlg;
        convertReportValue.dailySupportProvisionOfInfoFlg =
          formValues.initial.inoutConsultationResults[
            index
          ].dailySupportProvisionOfInfoFlg;
        convertReportValue.memo = isEmpty(
          formValues.initial.inoutConsultationResults[index].memo
        )
          ? null
          : formValues.initial.inoutConsultationResults[index].memo;
        convertReportValue.isHoliday = storeValue.isHoliday;
      }
      return convertReportValue;
    }
  );

  return { inoutConsultationResults };
};

/**
 * formデータをAPIのrequestに変換
 */
export const normalizeFormValueMonthlyToAPI = (
  formValues: boolean
): RequestParamMonthlyToPostAPI => {
  const inOutResultsMonthly: RequestParamMonthlyToPostAPI["inout_results_monthly"] = {
    no_constant_contact_system_flg: formValues
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API
  };
  return {
    inout_results_monthly: inOutResultsMonthly
  };
};
