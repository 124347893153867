import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UnitsState } from "@stores/domain/units/types";
import { UserState } from "@stores/domain/user/type";
import { FacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { unsetSHISETSUNYUSHOUsagePerformanceSummary } from "@stores/domain/mgr/SHISETSUNYUSHO/usagePerformance/actions";
import * as targetUnitActions from "@stores/domain/mgr/SHISETSUNYUSHO/targetUnit/actions";
import * as usersDailyActions from "@stores/pages/record/usersDaily/actions";
import { PaginatedDailySupportsState } from "@stores/domain/mgr/SHISETSUNYUSHO/paginatedDailySupports/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import AdminTemplate from "@components/templates/AdminTemplate";
import { DailySupportsRecord } from "@components/organisms/mgr/SHISETSUNYUSHO/record/DailySupportsRecord";
import { FieldItem } from "@interfaces/ui/form";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import MuiSelect from "@components/molecules/MuiSelect";
import { UsagePerformanceReportModal } from "@components/organisms/mgr/common/report/UsagePerformanceReportModal";

// utils
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { getStaffCommentLabel } from "@utils/domain/customRecords/getStaffCommentLabel";

// variables
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import format from "date-fns/format";
import * as URL from "@constants/url";
import { STICKY_BOX_SHADOW, STICKY_NONE_BOX_SHADOW } from "@constants/styles";
import DailyRecordSummary from "@components/organisms/mgr/common/record/DailyRecordSummary";
import { DailyOperationRecord } from "@components/organisms/mgr/SHISETSUNYUSHO/record/DailyOperationRecord";
import { ReportUsagePerformanceState } from "@stores/domain/mgr/SHISETSUNYUSHO/usagePerformance/types";
import { DailyPrintModal } from "@components/organisms/mgr/SHISETSUNYUSHO/record/DailyPrintModal";
import { patternListFormat } from "@constants/mgr/SHISETSUNYUSHO/variables";
import { RecordUsersDailyState } from "@stores/pages/record/usersDaily/types";
import addDays from "date-fns/add_days";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: "16px 16px 0"
    },
    stickyWrapper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 16px",
      zIndex: 1000,
      position: "sticky",
      top: 0,
      background: "#eeeeee"
    },
    CreateAndUpdateDateWrap: {
      padding: "16px 16px 0"
    },
    buttons: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    }
  });

type OwnProps = WithStyles<typeof styles> &
  RouteComponentProps<{ yyyymmdd?: string }>;
type StateProps = {
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  facility: FacilityState;
  userInFacility: UsersInFacilityState;
  dailyOperationsAndSupports: DailyOperationsAndSupportsState;
  paginatedDailySupports: PaginatedDailySupportsState;
  staff: StaffState;
  recordDaily: RecordDailyState;
  userState: UserState;
  report: ReportUsagePerformanceState;
  customRecords: CustomRecordsState;
  operationTargetUnit: number;
  recordUsersDaily: RecordUsersDailyState;
};
type DispatchProps = {
  fetchUsers: () => Promise<void>;
  fetchDailyOperationsAndSupports: (
    yyyymmdd: string,
    unitId: number
  ) => Promise<void>;
  fetchInitialDailyRecord: (yyyymmdd: string, unitId: number) => void;
  fetchPaginatedDailySupports: (
    yyyymmdd: string,
    unitId?: number,
    page?: number,
    keyword?: string,
    is_record?: number
  ) => void;
  unsetSearchConditions: () => void;
  fetchSummary: (yyyymmdd: string) => void;
  unsetSummary: () => void;
  setOperationsTargetUnit: (targetUnit: number) => void;
};
type MergeProps = {
  userName: string;
  staffOptions: FieldItem[];
  unitsOptions: FieldItem[];
} & OwnProps &
  StateProps &
  DispatchProps;

/**
 * 日々の記録
 */
const RecordDaily = (props: MergeProps): JSX.Element => {
  const noCreatedAt = !props.dailyOperationsAndSupports.created_at;

  const scrollYValue = 48;
  const [stickyFlg, setStickyFlg] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    const listenScrollEvent = (): void => {
      if (unmounted) return;
      if (window.scrollY > scrollYValue) {
        setStickyFlg(true);
      } else {
        setStickyFlg(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      unmounted = true;
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const { optionalCustomInfo, operationTargetUnit } = props;
  // カレンダー周り
  const currentDate = props.match.params.yyyymmdd
    ? convertBlankSeparatorFormatToDate(props.match.params.yyyymmdd)
    : new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = new Date();

  // 表示管理 ※グループ未設定なら業務日誌表示・グループ表示なしとして扱う
  const {
    use_common_operation_record,
    use_units_operation_record
  } = !Array.isArray(optionalCustomInfo)
    ? optionalCustomInfo.operation_record_flg
    : {
        use_common_operation_record: 1,
        use_units_operation_record: 0
      };
  // グループ変更周り
  const [initialUnitSetFlag, setInitialUnitSetFlag] = React.useState(false);

  const [unitValidUsers, setUnitValidUsers] = React.useState<
    UsersInFacilityState["users"]
  >([]);

  React.useEffect(() => {
    // グループ選択の初期値に、選択肢の1つ目を設定
    if (!initialUnitSetFlag && props.unitsOptions.length > 0) {
      const initialUnitValue = Number(props.unitsOptions[0].value);
      // 保持しているグループ情報があればセット
      if (!operationTargetUnit) {
        props.setOperationsTargetUnit(initialUnitValue);
      }
      setInitialUnitSetFlag(true);
    }
  }, [props.unitsOptions]);

  const choicedUnitOption = props.unitsOptions.find(
    (option) => Number(option.value) === operationTargetUnit
  ) || { label: "" };

  const onChangeUnit = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    props.setOperationsTargetUnit(+e.target.value);
    props.unsetSearchConditions();
  };

  // 職員考察の名称(印刷確認モーダルで使用)
  const staffCommentLabel = getStaffCommentLabel({
    serviceType: props.facility.serviceType,
    customRecords: props.customRecords
  });

  // state
  const [targetDay, setTargetDay] = React.useState(
    format(currentDate, "YYYYMMDD")
  );
  const isFirstRef = React.useRef(false);
  const [isOpenPrintModal, setOpenDetailModal] = React.useState(false);
  const [isOpenDetailDialog, setOpenDetailDialog] = React.useState(false);
  const [targetUnit, setTargetUnit] = React.useState<
    { id: number; unit_name: string; user_ids: number[] } | null | undefined
  >(null);

  // fetch: 業務日誌と初期fetch
  React.useEffect(() => {
    // URL変更
    const locationState = {
      pathname: `${URL.RECORD_DAILY}/${targetDay}`
    };
    if (props.match.params.yyyymmdd) {
      // TODO: ブラウザバックのバグが取れるまで履歴を持たなくする
      props.history.replace(locationState);
    } else {
      props.history.replace(locationState);
    }

    // API実行: 初期取得 or 再取得
    if (isFirstRef.current) {
      props.fetchDailyOperationsAndSupports(targetDay, operationTargetUnit);
    } else {
      props.fetchInitialDailyRecord(targetDay, operationTargetUnit);
      isFirstRef.current = true;
    }
  }, [targetDay, operationTargetUnit]);

  // fetch: 支援記録
  React.useEffect(() => {
    // id → alphabet変換
    const searchTargetAlphabetList = patternListFormat
      .filter((pattern) => {
        return props.recordUsersDaily.searchConditionsIds.includes(pattern.id);
      })
      .map((pattern) => pattern.alphabet);
    props.fetchPaginatedDailySupports(
      targetDay,
      operationTargetUnit,
      props.recordUsersDaily.currentPage,
      searchTargetAlphabetList.join(","),
      props.recordUsersDaily.isRecord
    );
  }, [targetDay, operationTargetUnit, props.recordUsersDaily]);

  React.useEffect(() => {
    setTargetUnit(
      !Array.isArray(props.optionalCustomInfo)
        ? props.optionalCustomInfo.facility_units.find(
            (unit) => unit.id === operationTargetUnit
          )
        : null
    );
  }, [props.optionalCustomInfo, props.dailyOperationsAndSupports]);

  React.useEffect(() => {
    // チェック: 設定されているグループに所属している
    const isUnitUsers = (
      unit: typeof targetUnit,
      user: UsersInFacilityState["users"][number]
    ): boolean => {
      return !!unit && unit.user_ids.includes(user.uif_id);
    };

    // チェック: 退居日が、表示している日にち以降
    const isValidUsers = (
      date: string,
      user: UsersInFacilityState["users"][number]
    ): boolean => {
      // 退去日, 表示日
      const endDate = user.date_end_in_service
        ? new Date(user.date_end_in_service)
        : new Date();
      const displayDate = convertBlankSeparatorFormatToDate(date);
      return !user.date_end_in_service || displayDate <= addDays(endDate, 29);
    };

    setUnitValidUsers(
      props.userInFacility.users.filter(
        (user) =>
          (isUnitUsers(targetUnit, user) ||
            !props.facility.operatingUnitFlag) &&
          isValidUsers(targetDay, user)
      )
    );
  }, [targetUnit, props.userInFacility.users, targetDay]);

  // handler
  const onClickCalendar = (date: Date): void => {
    setTargetDay(format(date, "YYYYMMDD"));
  };

  const onPrintPreview = (): void => {
    setOpenDetailModal(true);
  };

  const onClosePrintModal = (): void => {
    setOpenDetailModal(false);
  };

  const onClickDetail = (): void => {
    props.unsetSummary();
    props.fetchSummary(targetDay);
    setOpenDetailDialog(true);
  };

  const closeDialog = (): void => {
    setOpenDetailDialog(false);
  };

  return (
    <AdminTemplate pageName="日々の記録">
      <div
        className={props.classes.stickyWrapper}
        style={
          stickyFlg
            ? { boxShadow: STICKY_BOX_SHADOW }
            : { boxShadow: STICKY_NONE_BOX_SHADOW }
        }
      >
        <UnEditableWrapper unEditable={props.recordDaily.isEditing}>
          <DateSelectButtonsDaily
            defaultDate={currentDate}
            min={calendarMinDate}
            max={calendarMaxDate}
            onClickSubmit={onClickCalendar}
          />
        </UnEditableWrapper>
        <div className={props.classes.buttons}>
          <KnowbeButton
            disabled={noCreatedAt || props.recordDaily.isEditing}
            onClick={onPrintPreview}
          >
            印刷
          </KnowbeButton>
        </div>
      </div>
      <div className={props.classes.CreateAndUpdateDateWrap}>
        <CreateAndUpdateDate
          createdAt={props.dailyOperationsAndSupports.created_at}
          updatedAt={props.dailyOperationsAndSupports.updated_at}
        />
      </div>
      <div className={props.classes.wrapper}>
        <DailyRecordSummary
          summaries={props.dailyOperationsAndSupports.summary}
          title="日ごとの合計（事業所全体）"
          recordDaily={props.recordDaily}
          handleClickDetail={onClickDetail}
        />
      </div>
      {use_common_operation_record === 1 && (
        <DailyOperationRecord
          operations={props.dailyOperationsAndSupports.operation}
          title="業務日誌（事業所全体）"
          staffOptions={props.staffOptions}
          selectedUnitId={operationTargetUnit}
          unitId={0}
          yyyymmdd={targetDay}
        />
      )}
      {props.facility.operatingUnitFlag && (
        <>
          <MuiSelect
            name="unit_select"
            label="グループ選択"
            value={operationTargetUnit}
            options={props.unitsOptions}
            onChange={onChangeUnit}
            style={{ width: 520, margin: "48px 0 0 24px" }}
            disabled={props.recordDaily.isEditing}
          />
          {props.paginatedDailySupports.support_count > 0 && (
            <div className={props.classes.wrapper}>
              <DailyRecordSummary
                summaries={props.dailyOperationsAndSupports.summary}
                title={`日ごとの合計（${choicedUnitOption.label}）`}
                isUnit
              />
            </div>
          )}
        </>
      )}
      {use_units_operation_record === 1 && (
        <DailyOperationRecord
          operations={props.dailyOperationsAndSupports.operation}
          title={`業務日誌（${choicedUnitOption.label}）`}
          staffOptions={props.staffOptions}
          selectedUnitId={operationTargetUnit}
          unitId={operationTargetUnit}
          yyyymmdd={targetDay}
        />
      )}

      <div className={props.classes.wrapper}>
        <DailySupportsRecord
          pageName="支援記録"
          supportsRecord={props.paginatedDailySupports.support}
          supportCount={props.paginatedDailySupports.support_count}
          staff={props.staff}
          staffOptions={props.staffOptions}
          recordDaily={props.recordDaily}
          history={props.history}
          users={unitValidUsers}
          unitId={operationTargetUnit}
          yyyymmdd={targetDay}
          recordUsersDaily={props.recordUsersDaily}
        />
      </div>
      {isOpenPrintModal && (
        <DailyPrintModal
          isModalOpen={isOpenPrintModal}
          onClose={onClosePrintModal}
          history={props.history}
          yyyymmdd={targetDay}
          operationRecordFlag={
            Array.isArray(props.optionalCustomInfo)
              ? null
              : props.optionalCustomInfo.operation_record_flg
          }
          facilityUnit={targetUnit}
          staffCommentLabel={staffCommentLabel}
        />
      )}
      {isOpenDetailDialog && (
        <UsagePerformanceReportModal
          summary={props.report.summary}
          usagePerformance={props.report.usage_performance}
          isOpen={isOpenDetailDialog}
          onClose={closeDialog}
          date={convertBlankSeparatorFormatToDate(targetDay)}
          units={props.optionalCustomInfo}
        />
      )}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  optionalCustomInfo: state.units.optionalCustomInfo,
  facility: state.SHISETSUNYUSHO.facility,
  userInFacility: state.SHISETSUNYUSHO.userInFacility,
  dailyOperationsAndSupports: state.SHISETSUNYUSHO.dailyOperationsAndSupports,
  paginatedDailySupports: state.SHISETSUNYUSHO.paginatedDailySupports,
  staff: state.staff,
  recordDaily: state.pages.recordDaily,
  userState: state.user,
  report: state.SHISETSUNYUSHO.usagePerformance,
  customRecords: state.customRecords,
  operationTargetUnit: state.SHISETSUNYUSHO.targetUnit,
  recordUsersDaily: state.pages.recordUsersDaily
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { pages, SHISETSUNYUSHO } = dispatches;
  const recordDailyDispatcher = pages.recordDailySHISETSUNYUSHODispatcher(
    dispatch
  );
  const dailyOperationsAndSupportsDispatches = SHISETSUNYUSHO.dailyOperationsAndSupportsDispatcher(
    dispatch
  );
  const paginateDailySupportsDispatches = SHISETSUNYUSHO.paginateDailySupportsDispatcher(
    dispatch
  );
  const userInFacilityDispatcher = SHISETSUNYUSHO.userInFacilityDispatcher(
    dispatch
  );
  const reportDispatcher = SHISETSUNYUSHO.usagePerformanceDispatcher(dispatch);
  return {
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch(),
    fetchDailyOperationsAndSupports: (
      yyyymmdd: string,
      unitId: number
    ): Promise<void> => {
      return dailyOperationsAndSupportsDispatches.fetchDailyOperationsAndSupports(
        yyyymmdd,
        unitId
      );
    },
    fetchPaginatedDailySupports: (
      yyyymmdd: string,
      unitId?: number,
      page?: number,
      keyword?: string,
      is_record?: number
    ): Promise<void> => {
      return paginateDailySupportsDispatches.fetchPaginatedDailySupports(
        yyyymmdd,
        unitId,
        page,
        keyword,
        is_record
      );
    },
    fetchInitialDailyRecord: (
      yyyymmdd: string,
      unitId: number
    ): Promise<void> => {
      return recordDailyDispatcher.fetchInitialDailyRecord(yyyymmdd, unitId);
    },
    fetchSummary: (yyyymmdd: string): Promise<void> =>
      reportDispatcher.fetchUsagePerformanceSummary(yyyymmdd),
    unsetSummary: (): void => {
      dispatch(unsetSHISETSUNYUSHOUsagePerformanceSummary());
    },
    setOperationsTargetUnit: (targetUnit: number): void => {
      dispatch(targetUnitActions.setOperationsTargetUnit(targetUnit));
    },
    unsetSearchConditions: (): void => {
      dispatch(usersDailyActions.unsetSearchConditions());
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const {
    name_sei = "",
    name_mei = ""
  } = stateProps.userInFacility.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  // グループ選択の選択肢作成
  const unitsOptions = [];
  if (!Array.isArray(stateProps.optionalCustomInfo)) {
    const units = stateProps.optionalCustomInfo.facility_units.filter(
      (v) => v.id
    );
    unitsOptions.push(...generateSelectFieldItems(units, "unit_name", "id"));
  }

  return {
    userName,
    staffOptions,
    unitsOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const DailyRecord = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(RecordDaily));
