import { OptionInterface } from "@components/atoms/DropDown";
import { RadioItemInterface } from "@components/atoms/RadioButtons";

import {
  FACILITY,
  FACILITY_201910,
  FACILITY_202104,
  USERS_ID,
  USERS_ID_201910,
  USERS_ID_202104,
  REPORT_USERS_202104,
  REPORT_USERS_201910,
  REPORT_USERS
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104
} from "@constants/variables";

// 担当者_関係性
export const STAFF_RELATIONSHIP_ITEMS: OptionInterface[] = [
  { label: "上司", value: "0" },
  { label: "先輩", value: "1" },
  { label: "同僚", value: "2" },
  { label: "後輩", value: "3" },
  { label: "部下", value: "4" },
  { label: "担当窓口", value: "5" },
  { label: "その他", value: "9" }
];

export const RATE_GET_JOB_ITEMS: RadioItemInterface[] = [
  { label: "9割以上", value: "1" },
  { label: "8割以上9割未満", value: "2" },
  { label: "7割以上8割未満", value: "3" },
  { label: "5割以上7割未満", value: "4" },
  { label: "3割以上5割未満", value: "5" },
  { label: "1割以上3割未満", value: "6" },
  { label: "1割未満", value: "7" }
];

export const RATE_GET_JOB_ITEMS_202104: RadioItemInterface[] = [
  { label: "9割5分以上", value: "1" },
  { label: "9割以上9割5分未満", value: "2" },
  { label: "8割以上9割未満", value: "3" },
  { label: "7割以上8割未満", value: "4" },
  { label: "5割以上7割未満", value: "5" },
  { label: "3割以上5割未満", value: "6" },
  { label: "3割未満", value: "7" }
];

export const SUPPORT_METHOD: OptionInterface[] = [
  { label: "選択してください", value: "0" },
  { label: "面接：雇用事業主内", value: "1" },
  { label: "面接：事業所内", value: "2" },
  { label: "面接：オンライン", value: "3" },
  { label: "電話", value: "4" },
  { label: "メール", value: "5" },
  { label: "その他", value: "6" }
];

export const REGIONAL_COOPERATION_SUPPORT: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

// 支援方法・場所
export const SUPPORT_METHOD_ITEMS: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "面接：\n雇用事業主内", value: "1" },
  { label: "面接：\n事業所内", value: "2" },
  { label: "面接：\nオンライン", value: "3" },
  { label: "電話", value: "4" },
  { label: "メール", value: "5" },
  { label: "その他", value: "6" }
];

// 雇用形態
export const WORKING_STRATUS: OptionInterface[] = [
  { label: "正規", value: "0" },
  { label: "非正規：パート・アルバイト", value: "1" },
  { label: "非正規：契約社員・嘱託", value: "2" },
  { label: "非正規：派遣", value: "3" }
];

// 障害者手帳等級 身体
export const DISABILITY_CERTIFICATE_BODY: OptionInterface[] = [
  { label: "交付なし", value: "0" },
  { label: "1級", value: "1" },
  { label: "2級", value: "2" },
  { label: "3級", value: "3" },
  { label: "4級", value: "4" },
  { label: "5級", value: "5" },
  { label: "6級", value: "6" },
  { label: "7級", value: "7" }
];

// 障害者手帳等級 精神
export const DISABILITY_CERTIFICATE_SPIRIT: OptionInterface[] = [
  { label: "交付なし", value: "0" },
  { label: "1級", value: "1" },
  { label: "2級", value: "2" },
  { label: "3級", value: "3" }
];

// 障害者手帳等級 療育
export const DISABILITY_CERTIFICATE_REHABILITATION: OptionInterface[] = [
  { label: "交付なし", value: "0" },
  { label: "交付あり", value: "1" }
];

// 取得する記録内容を選択
export const RECORD_TYPE_SHUROTEICHAKU = {
  SUPPORT: "support",
  REPORT: "report"
};

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportUsers";
/** 就労定着の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V201910,
      url: FACILITY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_USERS_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V201910,
      url: USERS_ID_201910.replace(":id", userId)
    }
  ]
};
