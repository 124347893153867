import React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { UsageResultsState } from "@stores/domain/mgr/SHUROTEICHAKU/report/types";
import { ErrorsState } from "@stores/domain/errors/types";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { OptionInterface } from "@components/atoms/DropDown";
import { UsageResultListHeader } from "@components/organisms/mgr/SHUROTEICHAKU/report/UsageResultListHeader";
import { UsageResultListTable } from "@components/organisms/mgr/SHUROTEICHAKU/report/UsageResultListTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { UsageResultListHeaderEdit } from "./UsageResultListHeaderEdit";
import { Formik, Form, FormikActions } from "formik";
import { InitialDataValues } from "@interfaces/mgr/SHUROTEICHAKU/report/initialData";
import { initialValues } from "@initialize/mgr/SHUROTEICHAKU/report/initialValues";
import { reportValidation } from "@initialize/mgr/SHUROTEICHAKU/report/validation";
import { toEffectiveObject } from "@utils/object";
import { dateInYYYYMMFormat } from "@utils/date";
import isEqual from "lodash-es/isEqual";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    clear: {
      clear: "both"
    },
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 10
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 16
    },
    table: {
      tableLayout: "fixed"
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    button: {
      marginLeft: 10,
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4
    },
    allStatusType: {
      marginBottom: spacing.unit * 2
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  usageResultList: UsageResultsState;
  inoutErrors: ErrorsState["inout"];
  needsStopHistory: boolean;
};

type DispatchProps = {
  fetch: (uifId: string, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  openErrorsDialog: () => void;
  post: (
    formValue: InitialDataValues,
    reportValue: UsageResultsState,
    id: string,
    date: string
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  unsetReport: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）
 */
const UsageResultListCore = (props: Props): JSX.Element => {
  const { classes, usageResultList, history, currentPageVersion } = props;

  const [isEditing, setIsEditing] = React.useState(false);
  const [selectedMonth, setSelectedMonth] = React.useState(props.initialDate);
  const [selectedUser, setSelectedUser] = React.useState<OptionInterface>({
    label: "",
    value: ""
  });
  const [headerHeight, setHeaderHeight] = React.useState(0);

  React.useEffect((): void => {
    const top = document.getElementById("reportMonthlyHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  });

  const onChangeMonth = (date: Date, user: OptionInterface): void => {
    setSelectedMonth(date);
    setSelectedUser(user);
    if (user.value) {
      props.fetch(`${user.value}`, date);
    } else {
      props.unsetReport();
    }
    props.fetchInoutError(date);
  };

  const onChangeUser = (user: OptionInterface): void => {
    setSelectedUser(user);
    props.fetch(`${user.value}`, selectedMonth);
    props.fetchInoutError(selectedMonth);
  };

  const onClickErrorDialog = (): void => props.openErrorsDialog();

  const onChangeEditMode = (): void => {
    if (isEditing) props.stopHistory(false);
    setIsEditing((prev) => !prev);
  };

  const onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    props
      .post(
        values,
        usageResultList,
        `${selectedUser.value}`,
        dateInYYYYMMFormat(selectedMonth)
      )
      .finally(() => {
        props.fetchInoutError(selectedMonth);
        onChangeEditMode();
        actions.resetForm(initialValues(usageResultList));
        actions.setSubmitting(false);
      });
  };

  const validate = (values: InitialDataValues): void | object => {
    // 差分があれば離脱モーダルフラグをONに変更する。
    if (
      !props.needsStopHistory &&
      !isEqual(values, initialValues(usageResultList))
    ) {
      props.stopHistory(true);
    }
    return toEffectiveObject(reportValidation(values));
  };

  // 日付の最大値の設定 (30年後の12月31日)
  const maxDate = new Date(new Date().getFullYear() + 30, 11, 31);
  const minDate = new Date(2001, 0, 1);

  return (
    <Formik
      initialValues={initialValues(usageResultList)}
      onSubmit={onSubmit}
      validate={validate}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <div id="reportMonthlyHeader" className={classes.headerWrapper}>
            {props.inoutErrors.hasError && (
              <InvoiceErrorBar
                message={`${props.inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
                onClick={onClickErrorDialog}
              />
            )}

            <div className={classes.headerInfoContainer}>
              {isEditing ? (
                <UsageResultListHeaderEdit
                  selectedMonth={selectedMonth}
                  selectedUserName={selectedUser.label}
                  onChangeEditMode={onChangeEditMode}
                  formikProps={formikProps}
                  resetForm={formikProps.resetForm}
                />
              ) : (
                <UsageResultListHeader
                  minDate={minDate}
                  maxDate={maxDate}
                  selectedMonth={selectedMonth}
                  isSubmitDisabled={!usageResultList.usageResults.length}
                  selectedUserId={selectedUser.value}
                  onChangeMonth={onChangeMonth}
                  onChangeUser={onChangeUser}
                  onChangeEditMode={onChangeEditMode}
                  resetForm={formikProps.resetForm}
                  usageResultList={initialValues(usageResultList)}
                  history={history}
                  currentPageVersion={currentPageVersion}
                />
              )}
            </div>
          </div>

          <Paper
            elevation={0}
            className={ClassNames(classes.clear, classes.tableContainer)}
          >
            <UsageResultListTable
              isEditing={isEditing}
              headerHeight={headerHeight}
              formikProps={formikProps}
            />
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  usageResultList: state.SHUROTEICHAKU.report,
  inoutErrors: state.errors.inout,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHUROTEICHAKU, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatches = SHUROTEICHAKU.reportDispacher(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    fetch: (uifId: string, date: Date): Promise<void> =>
      reportDispatches.fetch(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    post: (
      formValue: InitialDataValues,
      reportValue: UsageResultsState,
      id: string,
      date: string
    ): Promise<void> => reportDispatches.post(formValue, reportValue, id, date),
    stopHistory: uiDispatches.stopHistory,
    unsetReport: (): void => reportDispatches.unsetReport()
  };
};

export const UsageResultList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsageResultListCore));
