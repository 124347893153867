import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

/**
 * 対象月の利用実績情報
 */
export type GetUsageResultsMonthlyResponse = {
  data: {
    share_support_report_date: string | null;
    usage_results: {
      users_in_facility_id: number;
      name_sei: string;
      name_mei: string;
      target_date: string;
      status_type: number;
      special_area_flg: number;
      remarks: string;
      isHoliday: boolean;
      cooperation_promotion_flg: number;
      support_method: number;
      support_method_other: string;
      support_start_time: string;
      support_end_time: string;
      regional_cooperation_support: number;
    }[];
  };
};

/**
 * 対象利用者の対象月の利用実績情報（usage_results）を取得する。
 * @param uifId 施設利用者id string
 * @param date 対象日 string YYYYMM
 */
export const getUsageResultsMonthly = async (
  uifId: string,
  date: string
): Promise<AxiosResponse<GetUsageResultsMonthlyResponse>> => {
  const url = `${VERSION_URL_202404}/usage_results/monthly/${uifId}/${date}`;
  return request.get<GetUsageResultsMonthlyResponse>(url);
};
