import * as React from "react";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

// ui
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { ServiceDeliveryDailyRecord } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDailyRecord";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";

// variables
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import * as format from "date-fns/format";
import * as URL from "@constants/url";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{
  date: string;
}> &
  StateProps &
  WithStyles<typeof styles>;

type StateProps = {
  userState: UserState;
};

/**
 * サービス提供記録（日ごと）
 */
const ServiceDeliveryDailyCore = (props: Props): JSX.Element => {
  const { date } = props.match.params;
  let currentDate = new Date();
  if (date) {
    const year = +date.slice(0, 4);
    const month = +date.slice(4, 6);
    const day = +date.slice(6, 8);
    currentDate = new Date(year, month - 1, day);
  }
  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.USERS) {
      props.history.push(
        `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/0/${format(
          new Date(),
          "YYYYMM"
        )}`
      );
    }
  };

  const pageNameValue =
    props.userState.facility_type === FacilityType.KODOENGO
      ? "支援記録"
      : "サービス提供記録";

  return (
    <AdminTemplate pageName={pageNameValue} zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LiST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.DAILY}
      />
      <ServiceDeliveryDailyRecord
        selectedDate={currentDate}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
        history={props.history}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const ServiceDeliveryDaily = connect(mapStateToProps)(
  withStyles(styles)(ServiceDeliveryDailyCore)
);
