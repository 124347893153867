import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";
import { dateToLocalisedString } from "@utils/date";
import { BaseResponse } from "@api/types";

export type GetFacilityUsersResponseElement = {
  uif_id: number;
  displayName: string;
  kanaName: string;
  date_end_in_service: string | null;
  status: number;
  in_time: string;
  out_time: string;
  monitoring_not_create?: boolean; // 計画相談のみ
  monitoring_month?: string[]; // 計画相談のみ
  type_consultation?: number; // 計画相談のみ
};

export type GetFacilityUsersResponse = BaseResponse<
  GetFacilityUsersResponseElement[]
>;

/**
 * 事業所に紐づくユーザー一覧を取得する。年月を指定した場合、指定した年月で絞り込む
 * @param date Date
 */
export const getFacilityUsers = async (
  date?: Date
): Promise<AxiosResponse<GetFacilityUsersResponse>> => {
  const targetDate = date
    ? `?date=${dateToLocalisedString(date, "YYYY-MM")}`
    : "";
  const url = `${VERSION_URL_202404}/facility/users${targetDate}`;
  return request.get<GetFacilityUsersResponse>(url);
};

export default getFacilityUsers;
