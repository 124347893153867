import { getWareki } from "@utils/date/getWareki";

export const getWarekiList = (
  from: number,
  to: number,
  desc = true
): {
  label: string;
  value: number;
}[] => {
  const result = [];

  for (let num = 0; num <= to - from; num += 1) {
    const year = desc ? to - num : from + num;
    const wareki = getWareki(year);
    result.push({ label: `${year}年 (${wareki})`, value: year });
  }

  return result;
};
