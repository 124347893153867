import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Link } from "react-router-dom";

import { createStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Paper from "@material-ui/core/Paper";

import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { ErrorsState } from "@stores/domain/errors/types";
import { GetFacilityMunicipalitiesResponse } from "@api/requests/facility/getFacilityMunicipalities";
import SectionTitle from "@components/atoms/SectionTitle";
import MunicipalitiesList from "@components/organisms/mgr/MunicipalitiesList";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    container: {
      margin: spacing.unit * 2,
      marginTop: 12
    },
    newButtonLink: {
      marginLeft: "auto"
    },
    newButton: {
      width: 120,
      letterSpacing: "1.25px"
    },
    toolBar: {
      display: "flex"
    },
    countText: {
      fontSize: 12,
      letterSpacing: "2px"
    },
    paperContainer: {
      padding: "32px 32px 31px",
      marginTop: 20
    }
  });

type DispatchProps = {
  openErrorsDialog: () => void;
};

type State = {
  error: ErrorsState;
};

type OwnProps = {
  facilityMunicipalities: GetFacilityMunicipalitiesResponse["data"];
  clearForm?: () => void;
};

type Props = WithStyles<typeof styles> & State & OwnProps & DispatchProps & {};

/**
 * 自治体情報
 */
const Municipalities: React.FC<Props> = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.toolBar}>
        <Link to="/municipality/new" className={classes.newButtonLink}>
          <Button
            color="secondary"
            variant="contained"
            className={classes.newButton}
          >
            新規登録
          </Button>
        </Link>
      </div>
      <Paper elevation={0} className={classes.paperContainer}>
        <SectionTitle
          label="自治体リスト"
          subLabel={`全${props.facilityMunicipalities.municipalities.length}件`}
          subClassName={classes.countText}
          isTitleNoMargin
        />
        <MunicipalitiesList
          municipalities={props.facilityMunicipalities.municipalities}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state: AppState): State => ({
  error: state.errors
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    openErrorsDialog: (): {
      readonly type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Municipalities));
