import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";

const styles = (): StyleRules =>
  createStyles({
    table: {
      minWidth: 700
    }
  });

export type CellParam = {
  role?: string;
  align: "left" | "center" | "right";
  label: string | React.ReactElement;
  className?: string;
};

type Props = WithStyles<typeof styles> & {
  key: string;
  className?: string;
  children: React.ReactNode;
};

const table = (props: Props): JSX.Element => {
  const { classes, children, key, className } = props;
  return (
    <Table key={key} className={ClassNames(classes.table, className)}>
      {children}
    </Table>
  );
};

export default withStyles(styles)(table);
