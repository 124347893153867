import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import { FormikProps, getIn } from "formik";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { FacilityValues as FacilityValuesV201910 } from "@initialize/v201910/mgr/SEIKATSUKAIGO/facility/initialValues";
import { FacilityValues as FacilityValuesV202104 } from "@initialize/v202104/mgr/SEIKATSUKAIGO/facility/initialValues";
import { FacilityValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";

import { DEFAULT_SELECT_VALUE } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    dateWrap: {
      margin: "0 0 12px -20px"
    },
    helperText: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)"
    },
    switchLabel: {
      "&+span": {
        fontSize: 16
      }
    }
  });

type OwnProps = {
  formikProps:
    | FormikProps<FacilityValuesV201910>
    | FormikProps<FacilityValuesV202104>
    | FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * サービス管理責任者配置等加算
 */
const PlacementOfServiceAdminFieldsCore = (props: Props): JSX.Element => {
  const { setFieldValue } = props.formikProps;
  const name = "additionalItem";

  const onLackFlagChange = (): void => {
    if (
      getIn(props.formikProps.values, `${name}.placementOfServiceAdminFlag`)
    ) {
      setFieldValue(`${name}.placementStartDate.year`, DEFAULT_SELECT_VALUE);
      setFieldValue(`${name}.placementStartDate.month`, "");
      setFieldValue(`${name}.placementStartDate.day`, "");
      setFieldValue(`${name}.placementEndDate.year`, DEFAULT_SELECT_VALUE);
      setFieldValue(`${name}.placementEndDate.month`, "");
      setFieldValue(`${name}.placementEndDate.day`, "");
      setFieldValue(`${name}.placementOfServiceAdminFlag`, false);
    } else {
      setFieldValue(`${name}.placementOfServiceAdminFlag`, true);
    }
  };

  return (
    <FormikSwitch
      name={`${name}.placementOfServiceAdminFlag`}
      label="サービス管理責任者配置等加算"
      onChange={onLackFlagChange}
      className={props.classes.switchLabel}
    >
      <div className={props.classes.dateWrap}>
        <FormikSelectDateNotSelectedDefault
          name={`${name}.placementStartDate`}
          label="開始年月日"
          setFormikFieldValue={setFieldValue}
          addYearTo={1}
          style={{ marginBottom: 22 }}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${name}.placementEndDate`}
          label="終了年月日"
          setFormikFieldValue={setFieldValue}
          addYearTo={5}
          style={{ marginBottom: 0 }}
        />
        <span className={props.classes.helperText}>
          開始年月日または終了年月日は、決まっている場合のみ入力してください
        </span>
      </div>
    </FormikSwitch>
  );
};

export const PlacementOfServiceAdminFields = withStyles(styles)(
  PlacementOfServiceAdminFieldsCore
);
