import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { FormikProps } from "formik";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormikSelect from "@components/molecules/FormikSelect";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikTextField from "@components/molecules/FormikTextField";

import { StaffData } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/types";
import { RecordCarePlan } from "@interfaces/record/carePlan/carePlan";
import {
  FacilityType,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_TEXT
} from "@constants/variables";
import {
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS as SUPPORT_CARE_PLAN_SCHEDULES_STATUS_KYOTAKUKAIGO,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM as SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_KYOTAKUKAIGO
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { BasicInfo } from "@components/organisms/mgr/common/record/carePlan/Dialog/Create/BasicInfo";
import { Details } from "@components/organisms/mgr/common/record/carePlan/Dialog/Create/Details";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      "&>div:nth-child(2)>div": {
        maxWidth: "1094px",
        width: "100%"
      }
    },
    dialogTitle: {
      padding: "16px 32px",
      background: "#f5f5f5",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    dialogCon: {
      padding: "32px 32px 50px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    MT48: {
      marginTop: "48px"
    },
    MT32: {
      marginTop: "32px"
    },
    header: {
      display: "flex",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      color: "#37474f",
      background: "#eceff1",
      padding: "14px 16px",
      marginTop: "8px"
    },
    week: {
      width: "236px"
    },
    start: {
      width: "calc(72px + 56px + 8px)",
      marginLeft: "16px"
    },
    end: {
      width: "calc(72px + 56px + 8px)",
      marginLeft: "16px"
    },
    times: {
      width: "106px",
      marginLeft: "16px"
    },
    number: {
      width: "56px",
      marginLeft: "16px"
    },
    charge: {
      width: "200px",
      marginLeft: "16px"
    },
    delete: {
      width: "25px",
      marginLeft: "24px"
    },
    time: {
      width: "72px"
    },
    service: {
      width: "144px",
      marginLeft: "16px"
    },
    point: {
      width: "406px",
      marginLeft: "16px"
    },
    toDo: {
      width: "280px",
      marginLeft: "16px"
    }
  });

type OwnProps = {
  staffItems: StaffData[];
  formikProps: FormikProps<RecordCarePlan>;
  facilityType: FacilityType;
  schedulesLength: number;
  serviceNumberCreate: number;
  isOpenModelOnCreating: boolean;
  onClickSaveButtonOnCreating: (th: number) => void;
  onClickCancelButtonOnCreating: (th: number) => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanSchedulesDialogCreateCore = (props: Props): JSX.Element => {
  const {
    classes,
    staffItems,
    formikProps,
    facilityType,
    schedulesLength,
    serviceNumberCreate,
    isOpenModelOnCreating,
    onClickSaveButtonOnCreating,
    onClickCancelButtonOnCreating
  } = props;

  // サービス内容が通院等乗降介助・選択してくださいの時切り替え用
  const [isRides, setIsRides] = React.useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = React.useState<
    boolean
  >(true);
  const setRides = (): void => {
    if (
      formikProps.values.support_care_plan_schedules[schedulesLength] &&
      formikProps.values.support_care_plan_schedules[schedulesLength].status ===
        SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_KYOTAKUKAIGO
          .GETTING_ON_AND_OFF_ASSISTANCE.value
    ) {
      setIsRides(true);
    } else {
      setIsRides(false);
    }
  };
  const setDisabledSaveButton = (): void => {
    if (
      formikProps.values.support_care_plan_schedules[schedulesLength] &&
      formikProps.values.support_care_plan_schedules[schedulesLength].status ===
        SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM_KYOTAKUKAIGO.SELECT.value
    ) {
      setIsDisabledSaveButton(true);
    } else {
      setIsDisabledSaveButton(false);
    }
  };
  React.useEffect(() => {
    if (facilityType === FacilityType.KYOTAKUKAIGO) {
      setRides();
      setDisabledSaveButton();
    } else if (
      facilityType === FacilityType.JUDOHOMONKAIGO ||
      facilityType === FacilityType.DOKOENGO ||
      facilityType === FacilityType.KODOENGO
    ) {
      setIsRides(false);
      setIsDisabledSaveButton(false);
    } else {
      setIsRides(false);
      setIsDisabledSaveButton(false);
    }
  }, [
    formikProps.values.support_care_plan_schedules[schedulesLength],
    facilityType
  ]);

  // 種別による分岐・サービス内容
  const schedulesStatus = (
    <FormikSelect
      name={`support_care_plan_schedules[${schedulesLength}].status`}
      label="サービス内容"
      placeholder="選択してください"
      options={SUPPORT_CARE_PLAN_SCHEDULES_STATUS_KYOTAKUKAIGO}
      style={{ width: "254px" }}
    />
  );
  const schedulesStatusText = (
    <FormikTextField
      name={`support_care_plan_schedules[${schedulesLength}].status_text`}
      label="サービス内容"
      defaultValue={SUPPORT_CARE_PLAN_SCHEDULES_STATUS_TEXT[facilityType]}
      maxLength={20}
      style={{ margin: 0, width: "348px" }}
    />
  );
  const getSchedulesStatus = (): JSX.Element => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return schedulesStatus;
      case FacilityType.JUDOHOMONKAIGO:
      case FacilityType.DOKOENGO:
      case FacilityType.KODOENGO:
        return schedulesStatusText;
      default:
        return schedulesStatus;
    }
  };

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={isOpenModelOnCreating}
    >
      <DialogTitle className={classes.dialogTitle}>
        サービス{circleNumbersList[serviceNumberCreate]}
        {"　"}サービス内容の詳細
      </DialogTitle>
      <DialogContent className={classes.dialogCon}>
        {getSchedulesStatus()}
        {/* 基本情報 */}
        <Typography variant="subtitle2" className={classes.MT32}>
          基本情報
        </Typography>
        <div className={classes.header}>
          <span className={classes.week}>サービス提供日</span>
          <span className={classes.start}>開始時間</span>
          <span className={classes.end}>終了時間</span>
          <span className={classes.times}>
            {isRides ? "乗降回数" : "時間数"}
          </span>
          <span className={classes.number}>提供人数</span>
          <span className={classes.charge}>提供担当者（資格）</span>
          <span className={classes.delete}>削除</span>
        </div>
        <BasicInfo
          isRides={isRides}
          staffItems={staffItems}
          formikProps={formikProps}
          facilityType={facilityType}
          schedulesLength={schedulesLength}
          isDisabledSaveButton={isDisabledSaveButton}
        />
        {/* 詳細内容 */}
        <Typography variant="subtitle2" className={classes.MT48}>
          詳細内容
        </Typography>
        <div className={classes.header}>
          <span className={classes.time}>所要時間</span>
          <span className={classes.service}>サービスの詳細内容</span>
          <span className={classes.point}>手順・留意事項・観察ポイント</span>
          <span className={classes.toDo}>本人・家族にやっていただくこと</span>
          <span className={classes.delete}>削除</span>
        </div>
        <Details
          formikProps={formikProps}
          facilityType={facilityType}
          schedulesLength={schedulesLength}
          isDisabledSaveButton={isDisabledSaveButton}
        />
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton
          kind="outline"
          onClick={(): void => onClickCancelButtonOnCreating(schedulesLength)}
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          kind="default"
          disabled={isDisabledSaveButton}
          onClick={(): void => onClickSaveButtonOnCreating(schedulesLength)}
        >
          保存する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const CarePlanSchedulesDialogCreate = withStyles(styles)(
  CarePlanSchedulesDialogCreateCore
);
