import * as types from "./types";
import { getCustomRecordsResponse } from "@api/requests/customRecords/getCustomRecords";

export const fetchCustomRecordsStarted = () =>
  ({ type: types.FETCH_CUSTOM_RECORDS_STARTED } as const);
export const fetchCustomRecordsSuccess = (
  res: getCustomRecordsResponse["data"]
) => ({ type: types.FETCH_CUSTOM_RECORDS_SUCCESS, payload: res } as const);
export const fetchCustomRecordsFailed = (err: unknown) =>
  ({ type: types.FETCH_CUSTOM_RECORDS_FAILED, error: err } as const);

export const fetchSupportCustomRecordsStarted = () =>
  ({ type: types.FETCH_SUPPORT_CUSTOM_RECORDS_STARTED } as const);
export const fetchSupportCustomRecordsSuccess = (
  res: getCustomRecordsResponse["data"]
) =>
  ({ type: types.FETCH_SUPPORT_CUSTOM_RECORDS_SUCCESS, payload: res } as const);
export const fetchSupportCustomRecordsFailed = (err: unknown) =>
  ({ type: types.FETCH_SUPPORT_CUSTOM_RECORDS_FAILED, error: err } as const);

export const postCustomRecordsStarted = () =>
  ({ type: types.POST_CUSTOM_RECORDS_STARTED } as const);
export const postCustomRecordsSuccess = () =>
  ({ type: types.POST_CUSTOM_RECORDS_SUCCESS } as const);
export const postCustomRecordsFailed = (err: unknown) =>
  ({ type: types.POST_CUSTOM_RECORDS_FAILED, error: err } as const);

export const postCustomRecordsOrderStarted = () =>
  ({ type: types.POST_CUSTOM_RECORDS_ORDER_STARTED } as const);
export const postCustomRecordsOrderSuccess = () =>
  ({ type: types.POST_CUSTOM_RECORDS_ORDER_SUCCESS } as const);
export const postCustomRecordsOrderFailed = (err: unknown) =>
  ({ type: types.POST_CUSTOM_RECORDS_ORDER_FAILED, error: err } as const);

export const postHiddenChoiceStarted = () =>
  ({ type: types.POST_HIDDEN_CHOICE_STARTED } as const);
export const postHiddenChoiceSuccess = () =>
  ({ type: types.POST_HIDDEN_CHOICE_SUCCESS } as const);
export const postHiddenChoiceFailed = (err: unknown) =>
  ({ type: types.POST_HIDDEN_CHOICE_FAILED, error: err } as const);

export const HideCustomRecordStarted = () =>
  ({ type: types.HIDE_CUSTOM_RECORD_STARTED } as const);
export const HideCustomRecordSuccess = () =>
  ({ type: types.HIDE_CUSTOM_RECORD_SUCCESS } as const);
export const HideCustomRecordFailed = (err: unknown) =>
  ({ type: types.HIDE_CUSTOM_RECORD_FAILED, error: err } as const);

export const ShowCustomRecordStarted = () =>
  ({ type: types.SHOW_CUSTOM_RECORD_STARTED } as const);
export const ShowCustomRecordSuccess = () =>
  ({ type: types.SHOW_CUSTOM_RECORD_SUCCESS } as const);
export const ShowCustomRecordFailed = (err: unknown) =>
  ({ type: types.SHOW_CUSTOM_RECORD_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCustomRecordsStarted>
  | ReturnType<typeof fetchCustomRecordsSuccess>
  | ReturnType<typeof fetchCustomRecordsFailed>
  | ReturnType<typeof fetchSupportCustomRecordsStarted>
  | ReturnType<typeof fetchSupportCustomRecordsSuccess>
  | ReturnType<typeof fetchSupportCustomRecordsFailed>
  | ReturnType<typeof postCustomRecordsStarted>
  | ReturnType<typeof postCustomRecordsSuccess>
  | ReturnType<typeof postCustomRecordsFailed>
  | ReturnType<typeof postCustomRecordsOrderStarted>
  | ReturnType<typeof postCustomRecordsOrderSuccess>
  | ReturnType<typeof postCustomRecordsOrderFailed>
  | ReturnType<typeof postHiddenChoiceStarted>
  | ReturnType<typeof postHiddenChoiceSuccess>
  | ReturnType<typeof postHiddenChoiceFailed>
  | ReturnType<typeof HideCustomRecordStarted>
  | ReturnType<typeof HideCustomRecordSuccess>
  | ReturnType<typeof HideCustomRecordFailed>
  | ReturnType<typeof ShowCustomRecordStarted>
  | ReturnType<typeof ShowCustomRecordSuccess>
  | ReturnType<typeof ShowCustomRecordFailed>;
