import * as React from "react";
import { FastField, FieldProps, FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { dateInYYYYFormat, getWarekiList } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";

// FormikSelectDateではここで定義しているためここに配置
const defaultSelect = { label: "選択してください", value: "NOT_SELECTED" };

type Props = {
  name: string;
  label: string;
  required?: boolean;
  addYearTo?: number;
  overrideYearFrom?: number;
  overrideYearTo?: number;
  style?: React.CSSProperties;
  setFormikFieldValue: (
    fieldName: string,
    value: number | boolean | string
  ) => void;
  tooltip?: React.ReactElement;
};

type ClassProps = Props & {
  value: SelectDateValue;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>; // 使用されるformikPropsがページごとに違うためany
};

type ClassState = {
  yearOptions: { label: string; value: string | number }[];
  monthOptions: { label: string; value: string }[];
};

class FormikSelectMonthNotSelectedDefaultClass extends React.Component<
  ClassProps,
  ClassState
> {
  constructor(props: ClassProps) {
    super(props);
    this.state = {
      yearOptions: [],
      monthOptions: []
    };
  }

  public componentDidMount(): void {
    const currentYear = +dateInYYYYFormat(new Date());
    const yearFrom = currentYear - 86;
    const from = this.props.overrideYearFrom || yearFrom;
    const to =
      this.props.overrideYearTo || currentYear + (this.props.addYearTo || 0);
    const yearOptions = [defaultSelect, ...getWarekiList(from, to)];
    const monthOptions = createOneToNumberOptions(12, "月");
    this.setState({ yearOptions, monthOptions });
  }

  public shouldComponentUpdate(
    nextProps: ClassProps,
    nextState: ClassState
  ): boolean {
    return (
      nextProps.value.year !== this.props.value.year ||
      nextProps.value.month !== this.props.value.month ||
      nextState.yearOptions.length !== this.state.yearOptions.length ||
      nextState.monthOptions.length !== this.state.monthOptions.length
    );
  }

  public render(): JSX.Element {
    const { label, name, required, style } = this.props;
    return (
      <FormGroup row style={style || { marginBottom: 32 }}>
        <FormikSelect
          name={`${name}.year`}
          label={label}
          required={required}
          options={this.state.yearOptions}
          style={{ marginBottom: 0 }}
          tooltip={this.props.tooltip}
        />
        <FormikSelect
          name={`${name}.month`}
          label="月"
          shrink={false}
          size="small"
          options={this.state.monthOptions}
          style={{ marginBottom: 0 }}
        />
      </FormGroup>
    );
  }
}

export const FormikSelectMonthNotSelectedDefault = (
  props: Props
): JSX.Element => (
  // tslint:disable:jsx-no-lambda
  // FieldProps<any> 使用されるformikPropsがページごとに違うためany
  <FastField
    name={props.name}
    /* eslint-disable @typescript-eslint/no-explicit-any */
    render={({ field, form }: FieldProps<any>): JSX.Element => (
      <FormikSelectMonthNotSelectedDefaultClass
        value={field.value}
        form={form}
        {...props}
      />
    )}
  />
);
