import React from "react";
import FieldWrapper from "@components/atoms/FieldWrapper";
import { SECONDARY_LINE_COLOR } from "@constants/styles";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import { SelectDateValue } from "@interfaces/ui/form";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";
import { getWareki } from "@utils/date/getWareki";

const styles = (): StyleRules =>
  createStyles({
    label: {
      position: "absolute",
      top: 0,
      left: 0,
      display: "block",
      lineHeight: "0.9rem",
      fontSize: "75%",
      color: "#37474F",
      fontFamily:
        "Hiragino Kaku Gothic ProN,ヒラギノ角ゴ ProN W3,Meiryo,メイリオ,Osaka,MS PGothic,arial,helvetica,sans-serif"
    },
    value: {
      display: "block",
      marginTop: 16,
      padding: "3px 0 4px"
    }
  });

type OwnProps = {
  label: string;
  value: SelectDateValue;
  size?: "short";
};

type Props = OwnProps & WithStyles<typeof styles>;

const size2width = (size?: string): [number, number, number] => {
  switch (size) {
    case "short":
      return [184, 72, 72];
    default:
      return [240, 128, 128];
  }
};

/**
 * FormikSelectDate*の表示用
 */
const DummySelectDateCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { year, month, day } = props.value;
  const [yearWidth, monthWidth, dayWidth] = size2width(props.size);
  const style = {
    position: "relative" as const,
    display: "inline-block",
    marginBottom: 16,
    boxSizing: "border-box" as const,
    borderBottom: `1px dotted ${SECONDARY_LINE_COLOR}`,
    verticalAlign: "baseline"
  };
  return (
    <div>
      <FieldWrapper style={{ ...style, width: yearWidth }}>
        <span className={classes.label}>{props.label}</span>
        <span className={classes.value}>
          {year === DEFAULT_SELECT_VALUE
            ? "- 年"
            : `${year}年 (${getWareki(year)})`}
        </span>
      </FieldWrapper>
      <FieldWrapper style={{ ...style, width: monthWidth }}>
        <span className={classes.value}>{month || "-"} 月</span>
      </FieldWrapper>
      <FieldWrapper style={{ ...style, width: dayWidth }}>
        <span className={classes.value}>{day || "-"} 日</span>
      </FieldWrapper>
    </div>
  );
};

export const DummySelectDate = withStyles(styles)(DummySelectDateCore);
