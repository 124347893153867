import * as React from "react";

import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import attendanceInScreen from "@images/attendance_in_screen.gif";
import attendanceOutScreen from "@images/attendance_out_screen.gif";
import { dateToObject } from "@utils/date";

const styles = (): StyleRules =>
  createStyles({
    timeWrapper: {
      flex: "2 1",
      padding: "38px 38px 0 0",
      textAlign: "center",
      "@media only screen and (max-width: 875px)": {
        padding: "0",
        margin: "20px 0 10px",
        minWidth: 512,
        boxSizing: "border-box",
        textAlign: "left",
        display: "flex"
      }
    },
    time: {
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(","),
      marginBottom: 40,
      fontSize: 44,
      lineHeight: 1,
      color: "#495c6d",
      "@media only screen and (max-width: 875px)": {
        marginBottom: 0,
        flex: "2 1"
      }
    },
    timeImgWrapper: {
      "@media only screen and (max-width: 875px)": {
        display: "none"
      }
    },
    timeImg: {
      width: "345px"
    },
    day: {
      marginLeft: 8,
      "@media only screen and (max-width: 875px)": {
        marginLeft: 0
      }
    },
    dayWrapper: {
      marginBottom: 40,
      fontSize: 26,
      color: "#495c6d",
      "@media only screen and (max-width: 875px)": {
        marginBottom: 0,
        flex: "2 1"
      }
    },
    hourMonth: {
      fontSize: 88,
      marginRight: 26
    },
    ml8: {
      marginLeft: 8
    },
    mediaBr: {
      display: "none",
      "@media only screen and (max-width: 875px)": {
        display: "block"
      }
    }
  });

type OwnProps = {
  attendanceStatus: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AttendanceTimeCore = ({
  attendanceStatus,
  classes
}: Props): JSX.Element => {
  const [time, setTime] = React.useState(new Date());

  const formatDate = (value: number): string => {
    return `0${value}`.slice(-2);
  };

  React.useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return (): void => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.timeWrapper}>
      <div className={classes.dayWrapper}>
        {time.getFullYear()}年
        <br className={classes.mediaBr} />
        <span className={classes.day}>
          {time.getMonth() + 1}月{time.getDate()}日
        </span>
        <span className={classes.ml8}>
          {dateToObject(time).day_of_week}曜日
        </span>
      </div>
      <div className={classes.time}>
        <span className={classes.hourMonth}>
          {formatDate(time.getHours())}:{formatDate(time.getMinutes())}
        </span>
        {formatDate(time.getSeconds())}
      </div>
      <div className={classes.timeImgWrapper}>
        <img
          src={
            attendanceStatus === "before"
              ? attendanceInScreen
              : attendanceOutScreen
          }
          className={classes.timeImg}
          alt=""
        />
      </div>
    </div>
  );
};

export const AttendanceTime = withStyles(styles)(AttendanceTimeCore);
