import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { FieldItem } from "@interfaces/ui/form";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { FormikProps, ArrayHelpers, FieldArray } from "formik";
import {
  AssistanceReportValues,
  initialDetails,
  isDetailUpdate,
  loadValuesDetails
} from "@initialize/mgr/CHIIKIIKO/record/assistanceReport/initialValues";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/CHIIKIIKO/planMonitoringMeeting/types";
import { ConsultationRecord } from "@stores/domain/mgr/CHIIKIIKO/assistanceReport/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      background: "#fff",
      paddingTop: "16px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "0px",
      top: "0px"
    },
    field: { marginTop: "32px" },
    fieldClassifyAfter: {
      // ラジオグループの内包しているmargin bottomが 28px なので 28px + 4px = 32px
      marginTop: "4px"
    },
    radio: {
      "&>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>label>span:first-child": {
        color: "#757575"
      },
      "&>div>div>label>span:last-child": {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "24px"
    },
    deleteButton: {
      marginTop: "32px",
      marginLeft: "-8px"
    },
    btnTxt: {
      marginLeft: "8px"
    },
    priorityCards: {
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd",
      marginBottom: "8px"
    },
    loadPlanButtonWrapper: { display: "flex", justifyContent: "flex-end" },
    loadPlanButton: {
      width: "240px"
    },
    guardianWrapper: {
      display: "flex",
      columnGap: "16px"
    },
    W240: {
      width: "240px"
    },
    W245: {
      width: "245px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT32: {
      marginTop: "32px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MB16: {
      marginBottom: "16px"
    },
    MB32: {
      marginBottom: "32px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<AssistanceReportValues>;
  user: UsersInFacilityState["user"];
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  consultationCopy: ConsultationRecord | null;
  isEditing: boolean;
  onClickLoad: () => () => void;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  resetCopyConsultation: () => void;
  setIsDetailUpdate: reactDispatch<SetStateAction<boolean>>;
  isNew?: boolean;
} & WithStyles<typeof styles>;

type Props = OwnProps;

const AssistanceReportFieldsCore = (props: Props): JSX.Element => {
  const {
    formikProps,
    classes,
    staffOptionsAddSnapShot,
    consultationCopy,
    isEditing,
    authorValue,
    onClickLoad,
    isNew
  } = props;
  const { values } = formikProps;

  // 上書きフラグ
  React.useEffect(() => {
    props.setIsDetailUpdate(isDetailUpdate(values));
  }, [values]);

  // 計画読み込み
  React.useEffect(() => {
    if (consultationCopy) {
      formikProps.setValues(loadValuesDetails(values, consultationCopy));
      props.resetCopyConsultation();
    }
  }, [consultationCopy]);

  const isDisabledAddButton =
    values.details.filter((detail) => !detail.is_delete).length >= 10;

  return (
    <div className={classes.wrapper}>
      {!isNew && (
        <div className={classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={values.createdAt}
            updatedAt={values.updatedAt}
          />
        </div>
      )}
      {values.classifyHandicappedFlg === 1 && (
        <div className={ClassNames(classes.MB32, classes.guardianWrapper)}>
          {/* 保護者氏名 */}
          <div className={classes.W245}>
            <RecordTextField
              name="guardianName"
              label="保護者氏名"
              labelType="default"
              defaultValue=""
              value={values.guardianName}
              isEditable={isEditing}
              placeholder=""
              maxLength={48}
            />
          </div>
          {/* 本人との続柄 */}
          <div className={classes.W245}>
            <RecordTextField
              name="guardianRelation"
              label="本人との続柄"
              labelType="default"
              defaultValue=""
              value={values.guardianRelation}
              isEditable={isEditing}
              placeholder=""
              maxLength={12}
            />
          </div>
        </div>
      )}
      {/* モニタリング実施日 */}
      <div className={classes.MT16}>
        <RecordSelectDate
          name="dateOfMonitoring"
          label="モニタリング実施日"
          required
          value={values.dateOfMonitoring}
          isEditable={isEditing}
          overrideYearFrom={1989}
        />
      </div>
      {/* 計画作成日 */}
      <div className={classes.MT32}>
        <RecordSelectDate
          name="creationDate"
          label="計画作成日"
          value={values.creationDate}
          isEditable={isEditing}
          overrideYearFrom={1989}
        />
      </div>
      {/* 計画作成者 */}
      <div className={ClassNames(classes.field, classes.W240)}>
        <RecordSelect
          name="author"
          label="計画作成者"
          defaultValue="-"
          placeholder="選択してください"
          options={staffOptionsAddSnapShot}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          value={authorValue}
          isEditable={isEditing}
          isSelectablePlaceholder
        />
      </div>

      {/* 障害支援区分 */}
      <div className={classes.field}>
        {isEditing ? (
          <FormikRadioButtons
            name="classifyDisabilitySupport"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
          />
        ) : (
          <div className={classes.radio}>
            <MuiRadioButtons
              name="read_only_classify_disability_support"
              label="障害支援区分"
              options={DISABILITY_CLASS_LIST}
              value={values.classifyDisabilitySupport}
            />
          </div>
        )}
      </div>
      {/* 障害福祉サービス受給者証番号 */}
      <div className={ClassNames(classes.W240, classes.fieldClassifyAfter)}>
        <RecordTextField
          name="disabledWelfareServiceRecipientNumber"
          label="障害福祉サービス受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.disabledWelfareServiceRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      {/* 通所受給者証番号 */}
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="regularPlaceRecipientNumber"
          label="通所受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.regularPlaceRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      {/* 地域相談支援受給者証番号 */}
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="consultationSupportRecipientNumber"
          label="地域相談支援受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.consultationSupportRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        地域移行支援報告書詳細
      </Typography>
      {isEditing && (
        <div className={classes.loadPlanButtonWrapper}>
          <KnowbeButton
            className={classes.loadPlanButton}
            kind="default"
            onClick={onClickLoad()}
            disabled={props.planMonitoringMeeting.yearly.length === 0}
          >
            計画から支援目標を読みこむ
          </KnowbeButton>
        </div>
      )}
      {/* サービス等利用計画の到達目標 */}
      <div className={ClassNames(classes.field)}>
        <RecordTextField
          name="attainmentGoal"
          label="サービス等利用計画の到達目標"
          labelType="default"
          defaultValue=""
          value={values.attainmentGoal}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      {/* 全体の状況 */}
      <div className={ClassNames(classes.field, classes.MB32)}>
        <RecordTextField
          name="wholeSituation"
          label="全体の状況"
          labelType="default"
          defaultValue=""
          value={values.wholeSituation}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      {/* 地域移行支援報告書詳細 */}
      <FieldArray name="details">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          return (
            <>
              {values.details
                .map((detail, index) => {
                  return { detail, baseIndex: index };
                })
                .filter((item) => !item.detail.is_delete)
                .map((item) => {
                  const { detail, baseIndex } = item;
                  return (
                    <div
                      className={classes.priorityCards}
                      key={`details_${baseIndex}_${detail.id}`}
                    >
                      {/* 利用者の期待や不安 */}
                      <div>
                        <RecordTextField
                          name={`details[${baseIndex}].support_goal`}
                          label="利用者の期待や不安"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].support_goal}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* 協力（支援）の目安 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].criteria_for_cooperation`}
                          label="協力（支援）の目安"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].criteria_for_cooperation
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* サービス提供状況（事業者からの聞き取り） */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].service_provision_status`}
                          label="サービス提供状況（事業者からの聞き取り）"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].service_provision_status
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {/* 本人の感想・満足度 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].personal_satisfaction`}
                          label="本人の感想・満足度"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex].personal_satisfaction
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* 期待への達成度・不安の解消度 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].achievement_of_expectations`}
                          label="期待への達成度・不安の解消度"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.details[baseIndex]
                              .achievement_of_expectations
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {/* 今後の課題・解決方法 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].future_issues`}
                          label="今後の課題・解決方法"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].future_issues}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {/* その他留意事項 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`details[${baseIndex}].other_considerations`}
                          label="その他留意事項"
                          labelType="default"
                          defaultValue=""
                          value={values.details[baseIndex].other_considerations}
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {isEditing && (
                        <div className={props.classes.deleteButton}>
                          <DeleteButton
                            text="削除する"
                            onClick={(): void => {
                              formikProps.setFieldValue(
                                `details[${baseIndex}].is_delete`,
                                1
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              {isEditing && (
                <KnowbeButton
                  kind="iconText"
                  className={classes.MT16}
                  onClick={(): void => arrayHelpers.push(initialDetails)}
                  disabled={isDisabledAddButton}
                >
                  <AddIcon />
                  <span className={classes.btnTxt}>
                    地域移行支援報告書詳細を追加する
                  </span>
                </KnowbeButton>
              )}
            </>
          );
        }}
      </FieldArray>
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      {/* 職員コメント */}
      <div className={classes.field}>
        <RecordTextField
          name="staffComment"
          label="職員コメント"
          labelType="default"
          defaultValue=""
          value={values.staffComment}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
    </div>
  );
};

export const AssistanceReportFields = withStyles(styles)(
  AssistanceReportFieldsCore
);
