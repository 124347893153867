import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import SectionTitle from "@components/atoms/SectionTitle";
import LackFields from "@components/organisms/mgr/common/facility/items/LackFields";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import {
  WithStyles,
  createStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FacilityValues } from "@initialize/mgr/SHUROTEICHAKU/facility/initialValues";

import { FormikProps } from "formik";
import FormikSwitch from "@components/molecules/FormikSwitch";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    },
    section: {
      marginBottom: 32
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      const init = { year: "NOT_SELECTED", month: "", day: "" };
      switch (event.target.name) {
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            init
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            init
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            init
          );
          break;
        default:
      }
    }
  };

  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle label="減算対象項目" />
      </div>
      <LackFields formikProps={props.formikProps} name="subtractionItem" />

      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.workContinuationNotPlanningFlg"
        label="業務継続計画未策定減算"
        onChange={onChangeSwitch}
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="workContinuationNotPlanningFlg" />}
          />
        }
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
