import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { dateToLocalisedString } from "@/utils/date";
import { selectDateValueToDate } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    field1: {
      marginTop: 24
    },
    field2: {
      marginTop: 30
    },
    dateField: {
      margin: "30px 24px 0 0",
      width: 245
    }
  });

type OwnProps = {
  isEditing: boolean;
  sprintType: 1 | 2;
  values: {
    support_plan_goal: {
      id?: number;
      number: number;
      sprint_goal: string;
      adopt_info: string;
      support_info: string;
      sprint_start_date: SelectDateValue;
      sprint_end_date: SelectDateValue;
    }[];
  };
  isDateSplitOff?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 長期目標 or 短期目標
 */
const GoalFieldsCore = (props: Props): JSX.Element => {
  const text = props.sprintType === 1 ? "長期目標" : "短期目標";
  const index = props.sprintType === 1 ? 0 : 1;
  const value = props.values.support_plan_goal[index];

  const sprintStartDate = props.isDateSplitOff
    ? selectDateValueToDate(value.sprint_start_date)
    : "";
  const sprintEndDate = props.isDateSplitOff
    ? selectDateValueToDate(value.sprint_end_date)
    : "";

  return (
    <>
      <GrayMiddleHeading text={text} />
      <div className={props.classes.field1}>
        <RecordTextField
          name={`support_plan_goal[${index}]["sprint_goal"]`}
          value={value.sprint_goal}
          defaultValue="-"
          label="目標"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>
      <FormGroup row>
        {props.isDateSplitOff ? (
          <>
            <div className={props.classes.dateField}>
              <ReadonlyTextField
                label="設定日"
                value={
                  sprintStartDate
                    ? dateToLocalisedString(sprintStartDate, "YYYY年M月D日")
                    : ""
                }
                defaultValue="-"
              />
            </div>
            <div className={props.classes.dateField}>
              <ReadonlyTextField
                label="達成予定日"
                value={
                  sprintEndDate
                    ? dateToLocalisedString(sprintEndDate, "YYYY年M月D日")
                    : ""
                }
                defaultValue="-"
              />
            </div>
          </>
        ) : (
          <>
            <div className={props.classes.field2}>
              <RecordSelectDate
                name={`support_plan_goal[${index}]["sprint_start_date"]`}
                label="設定日"
                value={value.sprint_start_date}
                isEditable={props.isEditing}
                addYearTo={1}
                overrideYearFrom={1989}
              />
            </div>
            <div className={props.classes.field2}>
              <RecordSelectDate
                name={`support_plan_goal[${index}]["sprint_end_date"]`}
                label="達成予定日"
                value={value.sprint_end_date}
                isEditable={props.isEditing}
                addYearTo={10}
                overrideYearFrom={1989}
              />
            </div>
          </>
        )}
      </FormGroup>
    </>
  );
};

export const GoalFields = withStyles(styles)(GoalFieldsCore);
