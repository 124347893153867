import {
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_CURRENT
} from "@constants/variables";
import {
  USERS_ID,
  USERS_ID_202104,
  REPORT_DAILY_202104,
  REPORT_DAILY,
  REPORT_USERS_202104,
  REPORT_USERS,
  FACILITY_202104,
  FACILITY
} from "@constants/url";

// サービス内容(表示用/半角括弧バージョン)
export const DOKOENGO_DISPLAY_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "同行(初任者等)", value: "10" },
  { label: "同行(基礎等)", value: "11" },
  { label: "同行(初任・通訳)", value: "12" },
  { label: "同行(基礎・通訳)", value: "13" },
  { label: "同行(通訳)", value: "14" }
];

// サービス内容(表示用/全角括弧バージョン)
export const DOKOENGO_DISPLAY_STATUS_FULL_WIDTH_LIST = [
  { label: "-", value: "0" },
  { label: "同行（初任者等）", value: "10" },
  { label: "同行（基礎等）", value: "11" },
  { label: "同行（初任・通訳）", value: "12" },
  { label: "同行（基礎・通訳）", value: "13" },
  { label: "同行（通訳）", value: "14" }
];

// 特定事業所
export enum SpecificOfficeType {
  "なし" = 0,
  "特定事業所加算（Ⅰ）", // 1
  "特定事業所加算（Ⅱ）", // 2
  "特定事業所加算（Ⅲ）", // 3
  "特定事業所加算（Ⅳ）" // 4
}

// 福祉・介護職員等特定処遇改善加算
export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE = {
  NONE: { label: "なし", value: "1", disabled: false },
  LEVEL1: { label: "特定処遇改善加算（Ⅰ）", value: "2", disabled: false },
  LEVEL2: { label: "特定処遇改善加算（Ⅱ）", value: "3", disabled: false }
};

export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST = [
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.NONE,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL2
];

// 資格区分
export const LICENSE_LIST = {
  SELECT: { label: "選択してください", value: " " },
  NEWLY: { label: "初任者等", value: "1" },
  BASE: { label: "基礎等", value: "2" },
  NEWLY_INTERPRETER: { label: "初任者等（通訳）", value: "3" },
  BASE_INTERPRETER: { label: "基礎等（通訳）", value: "4" },
  INTERPRETER: { label: "通訳", value: "5" }
};

export const LICENSE_SELECT_LIST = [
  LICENSE_LIST.SELECT,
  LICENSE_LIST.NEWLY,
  LICENSE_LIST.BASE,
  LICENSE_LIST.NEWLY_INTERPRETER,
  LICENSE_LIST.BASE_INTERPRETER,
  LICENSE_LIST.INTERPRETER
];

export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LiST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

export const STANDARD_TIME_VALUE = 0.5;

export const DOKOENGO_BASE_LIST = {
  NONE: { label: "-", value: "0" },
  SELECT: { label: "選択してください", value: "0" }
};

export const DOKOENGO_LICENSE_LIST = {
  NEWCOMER: { label: "初任者等", value: "1" },
  BASIC: { label: "基礎等", value: "2" },
  INTERPRETATION_NEWCOMER: { label: "初任者等（通訳）", value: "3" },
  INTERPRETATION_BASIC: { label: "基礎等（通訳）", value: "4" },
  INTERPRETATION: { label: "通訳", value: "5" }
};

export const DOKOENGO_PLAN_CORE_WORKER_LICENSE_LIST = [
  DOKOENGO_LICENSE_LIST.NEWCOMER,
  DOKOENGO_LICENSE_LIST.BASIC,
  DOKOENGO_LICENSE_LIST.INTERPRETATION_NEWCOMER,
  DOKOENGO_LICENSE_LIST.INTERPRETATION_BASIC,
  DOKOENGO_LICENSE_LIST.INTERPRETATION
];

export const DOKOENGO_ACTION_CLASS_LIST = {
  DRIVE: { label: "運転", value: "1" },
  NONE: { label: "空き", value: "2" }
};

export const DOKOENGO_ACTION_CLASS_SELECT_LIST = [
  DOKOENGO_ACTION_CLASS_LIST.DRIVE,
  DOKOENGO_ACTION_CLASS_LIST.NONE
];

export const DOKOENGO_MEMBER_LIST = {
  ONE: { label: "1人", value: "1" },
  TWO: { label: "2人", value: "2" }
};

export const DOKOENGO_MEMBER_SELECT_LIST = [
  DOKOENGO_MEMBER_LIST.ONE,
  DOKOENGO_MEMBER_LIST.TWO
];

export const DOKOENGO_INPUT_CLASS_LIST = {
  PLAN: { label: "同行援護計画", value: "1" },
  RESULT: {
    label: "サービス提供実績",
    value: "2"
  },
  CREATE: {
    label: "同行援護計画/サービス提供実績",
    value: "0"
  }
};

export const DOKOENGO_INPUT_CLASS_SELECT_LIST = [
  DOKOENGO_INPUT_CLASS_LIST.PLAN,
  DOKOENGO_INPUT_CLASS_LIST.RESULT
];

export const REPORT_FILTER_LIST = {
  NONE: { label: "すべての利用者", value: "1" },
  FILTER: { label: "計画または実績あり", value: "2" }
};

export const REPORT_FILTER_SELECT_LIST = [
  REPORT_FILTER_LIST.NONE,
  REPORT_FILTER_LIST.FILTER
];

export const SERVICE_TIME_ZONE = {
  MIDNIGHT: { start: "22:00", startClass: "0", end: "06:00", endClass: "1" },
  MORNING: { start: "06:00", startClass: "0", end: "08:00", endClass: "0" },
  DAYTIME: { start: "08:00", startClass: "0", end: "18:00", endClass: "0" },
  NIGHT: { start: "18:00", startClass: "0", end: "22:00", endClass: "0" }
};

export const SERVICE_TIME_ZONE_LIST = [
  SERVICE_TIME_ZONE.MIDNIGHT,
  SERVICE_TIME_ZONE.MORNING,
  SERVICE_TIME_ZONE.DAYTIME,
  SERVICE_TIME_ZONE.NIGHT
];

export const PROCESS_TYPE = {
  ALL: 1,
  ALL_ERROR: 2,
  NEW_ONLY: 3
};

export const INOUT_RESULTS_COPY_ERROR_STATUS = 17;

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

export const DOKOENGO_DAY_OF_WEEK_LIST = [
  { label: "月", value: "1" },
  { label: "火", value: "2" },
  { label: "水", value: "3" },
  { label: "木", value: "4" },
  { label: "金", value: "5" },
  { label: "土", value: "6" },
  { label: "日", value: "7" }
];

export const DOKOENGO_DISPATCH_NO_LIST = [
  { label: "①", value: "1" },
  { label: "②", value: "2" }
];

export const TIME_CLASS_LIST = [
  { label: "当日", value: "0" },
  { label: "翌日", value: "1" }
];

export const IN_TIME_VALIDATION_LIST = [
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の同行援護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の同行援護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const DETAIL_IN_TIME_VALIDATION_LIST = [
  "運転/空き時間は提供時間の範囲内で入力してください",
  "運転/空き時間は同一時間帯で重複しないように入力してください"
];

export const DETAIL_OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "運転/空き時間は提供時間の範囲内で入力してください",
  "運転/空き時間は同一時間帯で重複しないように入力してください",
  "運転/空き時間が2時間を超えています"
];

// サービス内容(記録画面表示用)
export const DOKOENGO_DISPLAY_STATUS_LIST_RECORD = [
  { label: "-", value: "1" },
  { label: "同行援護", value: "2" }
];

// サービス内容(記録画面詳細用)
export const DOKOENGO_DISPLAY_STATUS_LIST_DETAIL = [];

// custom_recordのdefault_itemの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO = {
  cost: 1,
  deposit: 2,
  vital: 3,
  user_state: 4,
  service_overview: 5,
  visual_information_aid: 6,
  other_service: 7,
  staff_comment: 8,
  exit_confirmation: 9,
  other: 10
};

// カスタムレコード（チェックボックス（カスタム可）＋テキスト）のインプットタイプ
export const CHECKBOX_AND_TEXT_INPUT_TYPE = {
  text: 1,
  checkbox: 2
};

// カスタムレコード（チェックボックス（カスタム可）＋テキスト）のテキストのdefault_choice
// 1,2はデフォルト選択肢
export const CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE = {
  other: 3
};

// サービス内容(記録画面表示用)
export const DOKOENGO_DISPLAY_STATUS_LIST_RECORD_PRINT = [
  { label: "-", value: "1" },
  { label: "同行援護", value: "2" }
];

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

/** 同行援護の報酬改定バージョン管理パスリスト */
type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
