/**
 * 自然数チェック（0含む）、空文字は判定しない、小数点第二位以降も許容
 * @param value
 */
const decimalPoint = (value: string, message?: string): string | undefined => {
  let errorMessage;
  if (value !== "" && !/^([1-9]\d*|0)(\.\d+)?$/.test(value)) {
    errorMessage = message !== undefined ? message : "数字を入力してください";
  }
  return errorMessage;
};

export default decimalPoint;
