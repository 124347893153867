import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

export type postFacilitiesMonthlyParams = {
  yyyymm: string | null;
  params: {
    facility: {
      number_of_employees: number | null;
      number_of_handicapped_child: number | null;
      number_of_keikakusodan: number | null;
      training_by_chief_202404: number | null;
      training_by_chief_shogaiji_202404: number | null;
    };
  };
};

/**
 * 施設の月ごと情報の更新
 */
export const postFacilitiesMonthly = async (
  data: postFacilitiesMonthlyParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202404}/facility/monthly/${data.yyyymm}`;
  return request.post(url, data.params);
};
