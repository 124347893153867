import {
  FACILITY,
  FACILITY_202104,
  REPORT_USERS,
  REPORT_USERS_202104,
  USERS_ID,
  USERS_ID_202104
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V202104
} from "@constants/variables";

// サービス内容(表示用)
export const CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "緊急時支援Ⅰ", value: "1" },
  { label: "緊急時支援Ⅱ", value: "2" }
];

export const CHIIKITEICHAKU_STATUS_LIST = {
  NONE: { label: "-", value: "0" },
  ONE: { label: "緊急時支援Ⅰ", value: "1" },
  TWO: { label: "緊急時支援Ⅱ", value: "2" }
};

export const CHIIKITEICHAKU_DISPLAY_STATUS_LIST = [
  CHIIKITEICHAKU_STATUS_LIST.NONE,
  CHIIKITEICHAKU_STATUS_LIST.ONE,
  CHIIKITEICHAKU_STATUS_LIST.TWO
];

export const CHIIKITEICHAKU_PLAN_LIST_TAB_PATH = {
  SUPPORT_LEDGER: "support_ledger"
} as const;

/** 地域定着台帳の関係機関先type */
export const RELATED_ORGANIZATION_TARGET_TYPE = {
  /** 福祉サービス */
  welfareService: 0,
  /** 医療機関 */
  medicalInstitution: 1,
  /** ライフライン */
  lifeline: 2,
  /** その他 */
  other: 3
} as const;

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportUsers";
/** 地域定着の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
