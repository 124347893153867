import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  PRINT_PAGE_PADDING,
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_HORIZONTAL_PAGE_HEIGHT,
  PRINT_HORIZONTAL_PAGE_WIDTH
} from "@/constants/styles";
import { DISABILITY_CLASS_LIST, INCOME_KIND_LIST } from "@constants/variables";
import {
  MONITORING_PERIOD_LIST,
  CONSULTATION_TARGET_FRG,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { ConsultationState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import format from "date-fns/format";
import { dateToLocalisedString } from "@utils/date";
import { PriorityPrintContent } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/PriorityPrintContent";
import { SchedulePrintContent } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/SchedulePrintContent";
import { findOfficeName } from "@utils/domain/mgr/KEIKAKUSODAN/facility";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "nowrap"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)",
      whiteSpace: "nowrap"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 2,
      minHeight: 20
    },
    signatureWrapper: {
      display: "flex",
      width: "288px",
      height: "38px",
      marginLeft: "auto",
      border: "solid 2px #212121"
    },
    signatureWrapperShogaiji: {
      marginLeft: "auto"
    },
    signature: {
      width: "100px",
      borderRight: "solid 1px #212121",
      fontSize: "10px",
      letterSpacing: "0.5px",
      textAlign: "center",
      lineHeight: "34px"
    },
    page: {
      minHeight: PRINT_HORIZONTAL_PAGE_HEIGHT,
      width: PRINT_HORIZONTAL_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING + 3}px 10px ${PRINT_PAGE_PADDING}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "276mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      "& td": {
        verticalAlign: "initial",
        padding: "3px 7px",
        borderRight: "1px solid",
        borderTop: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 20,
        "&:last-of-type": {
          overflowWrap: "anywhere"
        },
        "&.label": {
          fontSize: "10px",
          lineHeight: 1.4,
          letterSpacing: "0.5px",
          color: "#212121",
          width: "118px",
          backgroundColor: "#f5f5f5",
          whiteSpace: "nowrap"
        },
        "&.termLabel": {
          fontSize: "10px",
          lineHeight: 1.4,
          letterSpacing: "0.5px",
          color: "#212121",
          width: "84px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle"
        },
        "&.blankLabel": {
          borderTop: "none",
          width: "33px",
          backgroundColor: "#f5f5f5"
        }
      }
    },
    W130: {
      width: "130px"
    },
    W136: {
      width: "136px"
    },
    W158: {
      width: "158px"
    },
    W288: {
      width: "288px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT20: {
      marginTop: "20px"
    },
    ML16: {
      marginLeft: "16px"
    },
    otherTable: {
      display: "grid",
      gridTemplateColumns: "80px 1fr",
      border: "solid 2px #212121"
    },
    overallLifeWrap: {
      minWidth: "80px",
      minHeight: "68px",
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      textAlign: "center",
      borderRight: "solid 1px #212121"
    },
    overallLife: {
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      overflowWrap: "anywhere"
    },
    staffCommentWrap: {
      minWidth: "80px",
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      textAlign: "center",
      borderRight: "solid 1px #212121",
      minHeight: "26px"
    },
    staffComment: {
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      overflowWrap: "anywhere"
    }
  });

type OwnProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  consultation: ConsultationState["consultationRecord"];
  isStaffComment: boolean;
  title: string;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const ConsultationPrintContentCore = (props: Props): JSX.Element => {
  const {
    classes,
    facility,
    user,
    title,
    consultation,
    isStaffComment
  } = props;
  const userInFacility = user.user_in_facility;

  if (userInFacility.name_sei === undefined) {
    return <></>;
  }

  const isDisplayDetails =
    consultation.desiredLife ||
    consultation.supportPolicy ||
    consultation.longTermGoal ||
    consultation.shortTermGoal;

  // 利用者負担上限額
  const incomeKind = INCOME_KIND_LIST.find(
    (item) => item.value === consultation.incomeKind
  );

  // モニタリング期間（開始年月）
  const createMonitoringPeriod = (
    monitoringPeriod: string,
    monitoringStartYm: string
  ): string => {
    const period = monitoringPeriod
      ? MONITORING_PERIOD_LIST[Number(monitoringPeriod) - 1].label
      : "";
    const startYm = monitoringStartYm
      ? `（${dateToLocalisedString(monitoringStartYm, "YYYY年M月")}）`
      : "";

    return period + startYm;
  };

  const officeName = findOfficeName(facility, consultation.type_consultation);
  // 基本情報項目
  const baseList1 = [
    {
      label: "利用者氏名（フリガナ）",
      className: ClassNames(classes.W288),
      data: `${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`
    },
    {
      label: "障害支援区分",
      className: ClassNames(classes.W136, classes.ML16),
      data:
        DISABILITY_CLASS_LIST[
          consultation.classifyDisabilitySupport
            ? Number(consultation.classifyDisabilitySupport)
            : 0
        ].label
    },
    {
      label: "相談支援事業者名",
      className: ClassNames(classes.W288, classes.ML16),
      data: officeName
    },
    {
      label: "計画作成担当者",
      className: ClassNames(classes.W288, classes.ML16),
      data: getSnapOrRealName(consultation.author || 0, "")
    }
  ];
  const baseList2 = [
    {
      label: "障害福祉サービス受給者証番号",
      className: ClassNames(classes.W136),
      data: consultation.disabledWelfareServiceRecipientNumber
    },
    consultation.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_PLAN
      ? {
          label: "利用者負担上限額",
          className: ClassNames(classes.W136, classes.ML16),
          data: incomeKind ? incomeKind.label : ""
        }
      : {},
    {
      label: "地域相談支援受給者番号",
      className: ClassNames(classes.W136, classes.ML16),
      data: consultation.consultationSupportRecipientNumber
    },
    {
      label: "通所受給者証番号",
      className: ClassNames(classes.W136, classes.ML16),
      data: consultation.regularPlaceRecipientNumber
    },
    consultation.targetFlg === CONSULTATION_TARGET_FRG.CONSULTATION_PLAN
      ? {
          isBlank: true
        }
      : {}
  ];
  const baseList3 = [
    {
      label:
        consultation.targetFlg === CONSULTATION_TARGET_FRG.CONSULTATION_PLAN
          ? "計画案作成日"
          : "計画作成日",
      className: ClassNames(classes.W136),
      data: `${format(consultation.creationDate, "YYYY年M月D日")}`
    },
    {
      label: "モニタリング期間（開始年月）",
      className: ClassNames(classes.W136, classes.ML16),
      data: createMonitoringPeriod(
        consultation.monitoringPeriod,
        consultation.monitoringStartYm
      )
    }
  ];

  // 値がある優先順位のみ取り出す
  const filteredDetails = consultation.supportPlanConsultationDetails
    .map((detail, index) => ({ record: detail, number: index + 1 }))
    .filter((detail) => {
      return !Object.entries(detail.record).every(([key, value]) => {
        if (key === "support_plan_consultation_details_id") {
          return true;
        }
        return !value;
      });
    });

  return (
    <>
      {/* 利用計画1 */}
      {consultation.targetFlg !==
        CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION && (
        <div className={classes.page}>
          {/* タイトル */}
          <div className={classes.pageTitle}>
            <h2 className={classes.pageTitleTextB}>{title}</h2>
          </div>
          {/* 基本情報 */}
          <div className={ClassNames(classes.flex, classes.MT20)}>
            {baseList1.map((m, index) => {
              const uniqueKey = `uniqueBaseList1Key-${index}`;
              return (
                <div className={m.className && m.className} key={uniqueKey}>
                  <div className={classes.label}>{m.label}</div>
                  <div className={classes.con}>{m.data}</div>
                </div>
              );
            })}
          </div>
          <div className={ClassNames(classes.flex, classes.MT16)}>
            {baseList2.map((m, index) => {
              const uniqueKey = `uniqueBaseList2Key-${index}`;
              return m.isBlank ? (
                <div className={classes.W158} key={uniqueKey} />
              ) : (
                <div className={m.className && m.className} key={uniqueKey}>
                  <div className={classes.label}>{m.label}</div>
                  <div className={classes.con}>{m.data}</div>
                </div>
              );
            })}
          </div>
          <div className={ClassNames(classes.flex, classes.MT16)}>
            {baseList3.map((m, index) => {
              const uniqueKey = `uniqueBaseList3Key-${index}`;
              return (
                <div className={m.className && m.className} key={uniqueKey}>
                  <div className={classes.label}>{m.label}</div>
                  <div className={classes.con}>{m.data}</div>
                </div>
              );
            })}
            {consultation.type_consultation ===
              TYPE_CONSULTATION_KEIKAKUSODAN && (
              <div className={classes.signatureWrapper}>
                <div className={classes.signature}>利用者同意署名欄</div>
                <></>
              </div>
            )}

            {consultation.type_consultation ===
              TYPE_CONSULTATION_SHOGAIJISODAN && (
              <div
                className={ClassNames(
                  classes.flex,
                  classes.signatureWrapperShogaiji
                )}
              >
                <div className={classes.signatureWrapper}>
                  <div className={classes.signature}>利用者同意署名欄</div>
                  <></>
                </div>
                <div
                  className={ClassNames(classes.ML16, classes.signatureWrapper)}
                >
                  <div className={classes.signature}>保護者署名欄</div>
                  <></>
                </div>
              </div>
            )}
          </div>

          {/* 計画の詳細 */}
          {isDisplayDetails && (
            <table className={ClassNames(classes.table, classes.MT16)}>
              <tbody>
                <tr>
                  <td className="label" colSpan={2}>
                    利用者及びその家族の
                    <br />
                    生活に対する意向
                    <br />
                    （希望する生活）
                  </td>
                  <td>{consultation.desiredLife}</td>
                </tr>
                <tr>
                  <td className="label" colSpan={2}>
                    総合的な援助の方針
                  </td>
                  <td>{consultation.supportPolicy}</td>
                </tr>
                <tr>
                  <td className="blankLabel" />
                  <td className="termLabel">長期目標</td>
                  <td>{consultation.longTermGoal}</td>
                </tr>
                <tr>
                  <td className="blankLabel" />
                  <td className="termLabel">短期目標</td>
                  <td>{consultation.shortTermGoal}</td>
                </tr>
              </tbody>
            </table>
          )}

          {/* 優先順位 */}
          {filteredDetails.length > 0 && (
            <div className={classes.MT16}>
              <PriorityPrintContent
                targetFlg={consultation.targetFlg}
                details={filteredDetails}
              />
            </div>
          )}
        </div>
      )}

      {/* 利用計画2 */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>{title}</h2>
        </div>
        {/* 基本情報 */}
        <div className={ClassNames(classes.flex, classes.MT20)}>
          {baseList1.map((m, index) => {
            const uniqueKey = `uniqueBaseList4Key-${index}`;
            return (
              <div className={m.className && m.className} key={uniqueKey}>
                <div className={classes.label}>{m.label}</div>
                <div className={classes.con}>{m.data}</div>
              </div>
            );
          })}
        </div>
        <div className={ClassNames(classes.flex, classes.MT16)}>
          {baseList2.map((m, index) => {
            const uniqueKey = `uniqueBaseList5Key-${index}`;
            return m.isBlank ? (
              <div className={classes.W158} key={uniqueKey} />
            ) : (
              <div className={m.className && m.className} key={uniqueKey}>
                <div className={classes.label}>{m.label}</div>
                <div className={classes.con}>{m.data}</div>
              </div>
            );
          })}
        </div>
        <div className={classes.MT16}>
          <div className={classes.W130}>
            <div className={classes.label}>計画開始年月</div>
            <div className={classes.con}>
              {consultation.planStartYm
                ? dateToLocalisedString(consultation.planStartYm, "YYYY年M月")
                : ""}
            </div>
          </div>
        </div>
        {/* スケジュール */}
        {(consultation.supportPlanConsultationSchedule.length > 0 ||
          consultation.mainActivities ||
          consultation.nonWeeklyService) && (
          <div className={classes.MT16}>
            <SchedulePrintContent consultation={consultation} />
          </div>
        )}
        {consultation.overallLife && (
          <div
            className={ClassNames(
              classes.flex,
              classes.MT16,
              classes.otherTable
            )}
          >
            <div className={classes.overallLifeWrap}>
              サービス提供
              <br />
              によって実現
              <br />
              する生活の
              <br />
              全体像
            </div>
            <div className={classes.overallLife}>
              {consultation.overallLife}
            </div>
          </div>
        )}
        {isStaffComment && (
          <div
            className={ClassNames(
              classes.flex,
              classes.MT16,
              classes.otherTable
            )}
          >
            <div className={classes.staffCommentWrap}>職員コメント</div>
            <div className={classes.staffComment}>
              {consultation.staffComment}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const ConsultationPrintContent = withStyles(styles)(
  ConsultationPrintContentCore
);
