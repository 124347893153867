import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import * as queryString from "query-string";
import { isEmpty } from "lodash-es";
// store
import {
  ServiceDeliveryTypeInterface,
  SERVICE_DELIVERY_DAILY,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
// ui
import Edit from "@material-ui/icons/Edit";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { CheckCircle } from "@material-ui/icons/";
// utils
import * as format from "date-fns/format";
import { getStatusLabel } from "@/utils/domain/serviceDelivery/getStatusLabel";
// variables
import { FacilityType } from "@constants/variables";
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { IconButton } from "@componentsMobile/atoms/IconButton";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: "12px 16px",
      borderTop: "1px solid #e0e0e0"
    },
    flex: {
      display: "flex",
      justifyContent: "space-between"
    },
    width: {
      width: "100%"
    },
    contentRight: {
      display: "flex",
      alignItems: "center"
    },
    status: {
      fontSize: 16,
      marginBottom: 8,
      whiteSpace: "pre-line"
    },
    statusText: {
      fontSize: 16,
      marginTop: -6,
      paddingRight: 16,
      whiteSpace: "pre-line"
    },
    practitionerNumber: {
      fontSize: 14,
      marginBottom: 4
    },
    time: {
      fontSize: 14
    },
    recordedFlg: {
      color: "#2db45a",
      height: "18px",
      width: "18px"
    },
    recordedIcon: {
      width: 56,
      height: 20,
      color: "#fff",
      fontSize: 12,
      backgroundColor: "#4caf50",
      borderRadius: 2,
      padding: "1px 4px",
      marginRight: 8
    },
    unPlannedIcon: {
      width: 44,
      height: 20,
      color: "#fff",
      fontSize: 12,
      backgroundColor: "#757575",
      padding: "1px 4px",
      borderRadius: 2
    },
    iconWrapper: {
      marginTop: 12,
      display: "flex"
    },
    editIconWrapper: {
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "solid 1px rgba(0, 0, 0, 0.23)",
      borderRadius: 40
    },
    editIcon: {
      color: "#0277bd",
      cursor: "pointer"
    },
    widthButton: {
      display: "flex",
      width: "100%"
    },
    recordedButton: {
      width: "50%",
      minWidth: "140px",
      position: "relative",
      marginTop: "12px",
      marginRight: "16px",
      "&:last-child": {
        marginRight: "0px"
      }
    },
    unPlannedIconKODOENGO: {
      width: 44,
      height: 20,
      color: "#fff",
      fontSize: 12,
      backgroundColor: "#757575",
      padding: "1px 4px",
      borderRadius: 2,
      margin: "0 0 0 auto"
    },
    recordedCheck: {
      position: "absolute",
      color: "#2db45a",
      right: "-8px",
      bottom: "26px",
      height: "16.8px",
      width: "16.8px"
    }
  });

type OwnProps = {
  params: ServiceDeliveryState["dailyRecord"]["service_delivery"][0];
  idx: string;
  lastFlg: boolean;
  type: ServiceDeliveryTypeInterface["type"];
  changeEditMode: (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    supportProcedureFormsId: number
  ) => void;
  openDeleteModal: (serviceDeliveryRecordsId: number) => void;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryBoxCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes, facilityType } = props;

  const statusLabel = getStatusLabel(params.status, props.facilityType, true);
  const userId = `${params.users_in_facility_id}`;

  const changeEditMode = (): void => {
    props.changeEditMode(
      params.service_delivery_records_id || 0,
      params.inout_results_id || 0,
      params.target_date,
      params.support_procedure_forms_id || 0
    );
  };

  const supportProcedureQueryObject: Record<string, number> = {};
  if (params.service_delivery_records_id) {
    supportProcedureQueryObject.serviceDeliveryRecordsId =
      params.service_delivery_records_id;
  }
  if (params.inout_results_id) {
    supportProcedureQueryObject.inoutResultsId = params.inout_results_id;
  }
  if (params.support_procedure_forms_id) {
    supportProcedureQueryObject.supportProcedureFormsId =
      params.support_procedure_forms_id;
  }
  const supportProcedureQuery = !isEmpty(supportProcedureQueryObject)
    ? `?${queryString.stringify(supportProcedureQueryObject)}`
    : "";

  // 移動支援:車両移送の場合true
  const isVehicleTransport =
    facilityType === FacilityType.IDOSHIEN &&
    IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value === params.status.toString();

  // 移動支援:グループ支援の場合true
  const isGroup =
    facilityType === FacilityType.IDOSHIEN &&
    IDOSHIEN_STATUS_LIST.GROUP.value === params.status.toString();

  // 「時間・時間数」表示パターン
  const startTime1 = params.start_time_1
    ? format(params.start_time_1, "HH:mm")
    : "--:--";
  const endTime1 = params.end_time_1
    ? format(params.end_time_1, "HH:mm")
    : "--:--";
  const startTime2 = params.start_time_2
    ? format(params.start_time_2, "HH:mm")
    : "--:--";
  const endTime2 = params.end_time_2
    ? format(params.end_time_2, "HH:mm")
    : "--:--";
  const timeDisplayPattern = ((): number => {
    if (params.number_of_practitioner === 1 || isVehicleTransport || isGroup) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_2 &&
      !params.end_time_2
    ) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_1 &&
      !params.end_time_1
    ) {
      return 2;
    }
    return 3;
  })();

  // 時間数表示用処理
  const NumberOftimeCellCore = (): string[] => {
    return [
      params.number_of_time_1 ? `（${params.number_of_time_1}時間）` : "",
      params.number_of_time_2 ? `（${params.number_of_time_2}時間）` : ""
    ];
  };

  // 乗降回数数表示用処理
  const GettingOnAndOffCellCore = (): string[] => {
    const numberOfRides1 = params.number_of_rides_1
      ? `（${params.number_of_rides_1}回）`
      : "";
    const numberOfRides2 = params.number_of_rides_2
      ? `（${params.number_of_rides_2}回）`
      : "";
    return [numberOfRides1, numberOfRides2];
  };

  // 移動時間表示用処理(重度のみ)
  const CalculatedMovingHoursCellCore = ({
    practitionerNum
  }: {
    practitionerNum: number;
  }): JSX.Element | null => {
    if (facilityType === FacilityType.JUDOHOMONKAIGO) {
      const calculatedMovingHours =
        practitionerNum === 1
          ? params.calculated_moving_hours_1
          : params.calculated_moving_hours_2;
      if (calculatedMovingHours) {
        return (
          <>
            <br />
            {`移動${calculatedMovingHours}時間`}
          </>
        );
      }
    }
    return null;
  };

  // 時間表示用処理
  const TimeCellCore = (): JSX.Element => {
    const parentheses: string[] =
      params.status.toString() ===
        KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value &&
      facilityType === FacilityType.KYOTAKUKAIGO
        ? GettingOnAndOffCellCore()
        : NumberOftimeCellCore();

    switch (timeDisplayPattern) {
      case 1:
        return (
          <>
            {`${startTime1} 〜 ${endTime1}${parentheses[0]}`}
            <CalculatedMovingHoursCellCore practitionerNum={1} />
          </>
        );
      case 2:
        return (
          <>
            {`${startTime2} 〜 ${endTime2}${parentheses[0]}`}
            <CalculatedMovingHoursCellCore practitionerNum={2} />
          </>
        );
      case 3:
        return (
          <>
            {`${startTime1} 〜 ${endTime1}${parentheses[0]}`}
            <CalculatedMovingHoursCellCore practitionerNum={1} />
            <br />
            {`${startTime2} 〜 ${endTime2}${parentheses[1]}`}
            <CalculatedMovingHoursCellCore practitionerNum={2} />
          </>
        );
      default:
        return <></>;
    }
  };

  const StatusLabel = (): JSX.Element => (
    <div key={`${idx}-status`} className={`${classes.status}`}>
      {statusLabel}
    </div>
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.flex}>
        <div className={classes.width}>
          {/* サービス内容 */}
          {facilityType === FacilityType.KODOENGO ? (
            <div className={classes.flex}>
              <StatusLabel />
              {params.unplanned_flg === 1 && (
                <div className={classes.unPlannedIconKODOENGO}>計画外</div>
              )}
            </div>
          ) : (
            <div key={`${idx}-status`} className={`${classes.status}`}>
              <StatusLabel />
              {/* 移動支援 サービス内容 「その他」自由記述 */}
              {facilityType === FacilityType.IDOSHIEN &&
              params.status === 6 &&
              params.status_text ? (
                <div className={classes.statusText}>{params.status_text}</div>
              ) : null}
            </div>
          )}
          {/* 人数 */}
          <div
            key={`${idx}-practitioner-number`}
            className={`${classes.practitionerNumber}`}
          >
            {params.number_of_practitioner
              ? params.number_of_practitioner
              : "-"}
            人
          </div>
          {/* 時間 */}
          <div key={`${idx}-time`} className={`${classes.time}`}>
            <TimeCellCore />
          </div>
          {facilityType === FacilityType.KODOENGO ? (
            <div className={classes.widthButton}>
              {/* 手順書兼記録用紙 */}
              <KnowbeButton
                className={classes.recordedButton}
                kind="outlineWhite"
                disabled={params.procedure_status_flg === 0}
                href={
                  props.type === SERVICE_DELIVERY_DAILY
                    ? `#/record/service_delivery/daily/${userId}/support_procedure${supportProcedureQuery}`
                    : `#/record/service_delivery/monthly/${userId}/support_procedure${supportProcedureQuery}`
                }
              >
                {params.procedure_status_flg === 2 ? (
                  <CheckCircle className={classes.recordedCheck} />
                ) : null}
                {params.procedure_status_flg === 0
                  ? "手順書未作成"
                  : "手順書兼記録用紙"}
              </KnowbeButton>

              {/* サービス提供記録 */}
              <KnowbeButton
                className={classes.recordedButton}
                kind="outlineWhite"
                onClick={changeEditMode}
              >
                {params.recorded_flg === 1 ? (
                  <CheckCircle className={classes.recordedCheck} />
                ) : null}
                サービス提供記録
              </KnowbeButton>
            </div>
          ) : (
            (params.recorded_flg === 1 || params.unplanned_flg === 1) && (
              <div key={`${idx}-iconWrapper`} className={classes.iconWrapper}>
                {/* 記録済み */}
                {params.recorded_flg === 1 && (
                  <div className={classes.recordedIcon}>記録済み</div>
                )}
                {/* 計画外 */}
                {params.unplanned_flg === 1 && (
                  <div className={classes.unPlannedIcon}>計画外</div>
                )}
              </div>
            )
          )}
        </div>
        {facilityType !== FacilityType.KODOENGO ? (
          <div className={classes.contentRight}>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={changeEditMode}
            >
              <Edit key={`${idx}-edit-icon`} className={classes.editIcon} />
            </IconButton>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const ServiceDeliveryBoxCells = withStyles(styles)(
  ServiceDeliveryBoxCellsCore
);
