import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * サービス予定削除APIのリクエストパラメータ
 */
export type RequestParamToDeleteAPI = {
  data: {
    target: Record[];
  };
};

type Record = {
  users_in_facility_care_plan_id: number;
};

/**
 * サービス予定の削除
 * @param data 削除するサービス予定情報
 */
export const deleteCarePlan = async (
  id: string,
  data: RequestParamToDeleteAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/care_plan/${id}`;
  return request.deleteAll(url, data);
};
