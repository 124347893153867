import * as React from "react";
import * as classNames from "classnames";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Toolbar from "@material-ui/core/Toolbar";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    flex: {
      flexGrow: 1
    },
    submit: {
      marginLeft: spacing.unit
    },
    button: {
      boxShadow: "none",
      textTransform: "none"
    }
  });

type Props = WithStyles<typeof styles> & {
  buttonName: string;
  handleSubmit: (event: React.MouseEvent<HTMLElement>) => void;
  submitStyle?: string;
  cancelButtonName?: string;
  handleCancel?: (event: React.MouseEvent<HTMLElement>) => void;
  cancelStyle?: string;
  toolbarStyle?: string;
  isSubmitDisabled?: boolean;
  isPrintPage?: boolean;
};

class ContentHeaderSubmit extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { classes } = this.props;

    return (
      <Toolbar disableGutters={false} className={this.props.toolbarStyle}>
        <div className={classes.flex} />
        {this.props.handleCancel ? (
          <Button
            variant={this.props.isPrintPage ? "text" : "contained"}
            color="secondary"
            onClick={this.props.handleCancel}
            className={classNames(classes.button, this.props.cancelStyle)}
          >
            {this.props.cancelButtonName}
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="secondary"
          onClick={this.props.handleSubmit}
          className={classNames(
            classes.button,
            classes.submit,
            this.props.submitStyle
          )}
          disabled={
            this.props.isSubmitDisabled !== undefined
              ? this.props.isSubmitDisabled
              : false
          }
        >
          {this.props.buttonName}
        </Button>
      </Toolbar>
    );
  }
}

export default withStyles(styles)(ContentHeaderSubmit);
