import {
  FACILITY,
  FACILITY_202104,
  USERS_ID,
  USERS_ID_202104,
  REPORT_USERS,
  REPORT_USERS_202104
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V202104
} from "@constants/variables";

// 支援内容(モーダル用)
export const CHIIKIIKO_MODAL_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "訪問相談", value: "1" },
  { label: "同行支援", value: "2" },
  { label: "その他支援（基本報酬の算定）", value: "3" }
];

export const CHIIKIIKO_STATUS_LIST = {
  NONE: { label: "-", value: "0" },
  ONE: { label: "訪問相談", value: "1" },
  TWO: { label: "同行支援", value: "2" },
  THREE: { label: "その他支援", value: "3" }
};

export const CHIIKIIKO_DISPLAY_STATUS_LIST = [
  CHIIKIIKO_STATUS_LIST.NONE,
  CHIIKIIKO_STATUS_LIST.ONE,
  CHIIKIIKO_STATUS_LIST.TWO,
  CHIIKIIKO_STATUS_LIST.THREE
];

export const CHIIKIIKO_TRIAL_USE_TYPE_LIST = {
  NONE: { label: "-", value: "0" },
  ONE: { label: "体験利用Ⅰ", value: "1" },
  TWO: { label: "体験利用Ⅱ", value: "4" }
};

export const CHIIKIIKO_TRIAL_USE_TYPE_SELECT_LIST = [
  CHIIKIIKO_TRIAL_USE_TYPE_LIST.NONE,
  CHIIKIIKO_TRIAL_USE_TYPE_LIST.ONE,
  CHIIKIIKO_TRIAL_USE_TYPE_LIST.TWO
];

export const CHIIKIIKO_DISPLAY_TRIAL_USE_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "4" }
];

export const CHIIKIIKO_TRIAL_STAY_TYPE_LIST = {
  NONE: { label: "-", value: "0" },
  ONE: { label: "体験宿泊Ⅰ", value: "2" },
  TWO: { label: "体験宿泊Ⅱ", value: "3" }
};

export const CHIIKIIKO_TRIAL_STAY_TYPE_SELECT_LIST = [
  CHIIKIIKO_TRIAL_STAY_TYPE_LIST.NONE,
  CHIIKIIKO_TRIAL_STAY_TYPE_LIST.ONE,
  CHIIKIIKO_TRIAL_STAY_TYPE_LIST.TWO
];

export const CHIIKIIKO_DISPLAY_TRIAL_STAY_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "2" },
  { label: "Ⅱ", value: "3" }
];

// サービス内容(表示用)
export const CHIIKIIKO_DISPLAY_SERVICE_STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "体験利用Ⅰ", value: "1" },
  { label: "体験宿泊Ⅰ", value: "2" },
  { label: "体験宿泊Ⅱ", value: "3" },
  { label: "体験利用Ⅱ", value: "4" }
];

// 地域移行支援サービス費
export const CHIIKIIKO_SERVICE_TYPE_LIST = [
  { label: "地域移行支援サービス費（Ⅰ）", value: "1" },
  { label: "地域移行支援サービス費（Ⅱ）", value: "2" },
  { label: "地域移行支援サービス費（Ⅲ）", value: "3" }
];

export const DEFAULT_CHIIKIIKO_SERVICE_TYPE = "3";

export const MONTHLY_INTENSIVE_SUPPORT_VALUE = 6;

export const CHIIKIIKO_DRAFT_PLAN_NUM = 0;
export const CHIIKIIKO_PLAN_NUM = 1;
export const CHIIKIIKO_CONTINUATION_PLAN_NUM = 2;
export const CHIIKIIKO_SUPPORT_REPORT_NUM = 101;
export const MANAGER_MEETING_NUM = 102;

export const TARGET_FLG_TYPE = {
  [CHIIKIIKO_DRAFT_PLAN_NUM]: "地域移行計画案",
  [CHIIKIIKO_PLAN_NUM]: "地域移行計画",
  [CHIIKIIKO_CONTINUATION_PLAN_NUM]: "地域移行計画（継続）",
  [CHIIKIIKO_SUPPORT_REPORT_NUM]: "地域移行支援報告書",
  [MANAGER_MEETING_NUM]: "担当者会議記録"
};

export const CHIIKIIKO_CREATE_MODAL_OPTIONS = [
  { label: "地域移行計画案", value: "0" },
  { label: "地域移行計画", value: "1" },
  { label: "地域移行計画（継続）", value: "2" },
  { label: "地域移行支援報告書", value: "101" },
  { label: "サービス担当者会議記録", value: "102" }
];

// 支援計画タイプ
export const CONSULTATION_TARGET_FRG = {
  CONSULTATION_PLAN: 0, // 地域移行支援計画案
  CONSULTATION: 1, // 地域移行支援計画
  CONSULTATION_CONTINUATION: 2 // 地域移行支援計画書（継続）
};

// 支援計画タイトル
export const CONSULTATION_TARGET_TITLE = {
  0: "地域移行支援計画案",
  1: "地域移行支援計画",
  2: "地域移行支援計画（継続）"
};

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportUsers";

/** 地域移行の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
