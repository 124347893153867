import {
  StaffValues,
  StaffFields
} from "@interfaces/mgr/common/staff/staffValues";
import validator, { validateSwitcher } from "@validator";
import ValidationErrors from "@interfaces/ui/validationErrors";

export const validation = (
  values: StaffValues
): ValidationErrors<StaffFields> => {
  return {
    staffs: values.staffs.map((staff) => {
      return {
        roleName: validateSwitcher(
          !staff.delete &&
            (!!staff.staffItemId ||
              !!staff.staffName ||
              !!staff.staffLicenseName),
          validator(staff.roleName, "required")
        ),
        staffName: validateSwitcher(
          !staff.delete && (!!staff.roleName || !!staff.staffLicenseName),
          validator(staff.staffName, "required")
        )
      };
    })
  };
};
