import {
  ReportState,
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType,
  ReportType,
  REPEAT_DAILY,
  UsagePerformanceDailyType
} from "./types";

import { dateInHyphenYYYYMMDDFormat, selectDateValueToDate } from "@utils/date";
import {
  GetUsagePerformanceDailyParams,
  GetUsagePerformanceParamsDaily
} from "@api/requests/usagePerformance/getUsagePerformanceDaily";
import { INT_TRUE_FROM_API, INT_FALSE_FROM_API } from "@constants/variables";
import cloneDeep from "lodash-es/cloneDeep";
import {
  StatusType,
  UsagePerformanceStoreParamKeyToSelectBoxName,
  UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName,
  HospitalizationOvernightstay
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import {
  GetUsagePerformanceMonthlyParams,
  GetUsagePerformanceParamsMonthly
} from "@api/requests/usagePerformance/getUsagePerformanceMonthly";
import {
  PostUsagePerformanceDailyParams,
  PostUsagePerformanceDailyParam,
  PostUsagePerformanceSHISETSUNYUSHODailyParam
} from "@api/requests/usagePerformance/postUsagePerformanceDaily";
import deepEqual from "fast-deep-equal";
import isEmpty from "lodash-es/isEmpty";
import { InitialValues } from "@interfaces/mgr/SHISETSUNYUSHO/report/initial";
import {
  PostUsagePerformanceMonthlyParams,
  PostUsagePerformanceMonthlyParam,
  PostUsagePerformanceSHISETSUNYUSHOMonthlyParam
} from "@api/requests/usagePerformance/postUsagePerformanceMonthly";
import { FacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import castNumber from "@utils/dataNormalizer/castNumber";
import castStringToNumber from "@utils/dataNormalizer/castStringToNumber";
import {
  normalizeHospitalizationDays,
  getUsersHospitalizationDaysPrev
} from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";

// usagePerformanceSHISETSUNYUSHO store to postParam list
const usagePerformanceSHISETSUNYUSHOConvertParamList = {
  hospitalizationDays: {
    storeKey: "hospitalizationDays",
    paramKey: "hospitalization_days"
  },
  hospitalizationOvernightStay: {
    storeKey: "hospitalizationOvernightStay",
    paramKey: "hospitalization_overnightstay"
  },
  regionalTransition: {
    storeKey: "regionalTransition",
    paramKey: "regional_transition_flg"
  },
  oralTransition: {
    storeKey: "oralTransition",
    paramKey: "oral_transition_flg"
  },
  oralCareFlg: {
    storeKey: "oralCareFlg",
    paramKey: "oral_care_flg"
  },
  medicalFoods: {
    storeKey: "medicalFoods",
    paramKey: "medical_foods_flg"
  },
  nutritionManagement: {
    storeKey: "nutritionManagement",
    paramKey: "nutrition_management_flg"
  },
  collectionOfUtilityFee: {
    storeKey: "collectionOfUtilityFee",
    paramKey: "collection_of_utility_fee_flg"
  },
  foodBreakfast: {
    storeKey: "foodBreakfast",
    paramKey: "food_breakfast_flg"
  },
  foodLunch: {
    storeKey: "foodLunch",
    paramKey: "food_lunch_flg"
  },
  foodSupper: {
    storeKey: "foodSupper",
    paramKey: "food_supper_flg"
  },
  hospitalVisitSupportFlg: {
    storeKey: "hospitalVisitSupportFlg",
    paramKey: "hospital_visit_support_flg"
  },
  regionalTransitionPromotion2Flg: {
    storeKey: "regionalTransitionPromotion2Flg",
    paramKey: "regional_transition_promotion2_flg"
  },
  infectiousDiseasesFacilityFlg: {
    storeKey: "infectiousDiseasesFacilityFlg",
    paramKey: "infectious_diseases_facility_flg"
  }
};

/**
 * 差分チェック uif_id・targetDateは除く
 */
const isDiff = (before: string | number, after: string | number): boolean => {
  return before !== after;
};

/**
 * 差分更新ロジック
 */
const removeNoChangeData = <T>(beforeObject: T, afterObject: T): Partial<T> => {
  const diffObject = {} as Partial<T>;
  Object.keys(beforeObject).forEach((key: string) => {
    if (isDiff(beforeObject[key], afterObject[key])) {
      diffObject[key] = afterObject[key];
    }
  });
  return diffObject;
};

/**
 * store/UsagePerformanceDailyをPostUsagePerformanceParams["usage_performance_daily"]に変換(日ごと)
 * @param usagePerformanceDaily storeのusagePerformanceDaily
 */
const convertUsagePerformanceDaily = (
  usagePerformanceDaily: UsagePerformanceDailyType
): PostUsagePerformanceDailyParams["usage_performance_daily"] => {
  return {
    target_date: usagePerformanceDaily.targetDate
  };
};

/**
 * 一括登録用のusagePerformanceDailyの作成(日ごと)
 * @param usagePerformanceDaily
 */
const createUsagePerformanceDaily = (
  usagePerformanceDaily: ReportState["reportDaily"]["usagePerformanceDaily"]
): PostUsagePerformanceDailyParams["usage_performance_daily"] => {
  // 差分がなければ処理しない
  if (!deepEqual(usagePerformanceDaily.before, usagePerformanceDaily.after)) {
    return convertUsagePerformanceDaily(usagePerformanceDaily.after);
  }
  return {};
};

/**
 * usagePerformanceSHISETSUNYUSHOの初期化
 * @param usagePerformanceSHISETSUNYUSHO
 */
const initializeUsagePerformanceSHISETSUNYUSHO = (
  usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType
): UsagePerformanceSHISETSUNYUSHOType => {
  return cloneDeep({
    ...usagePerformanceSHISETSUNYUSHO,
    hospitalizationDays: null,
    hospitalizationOvernightStay: 0,
    regionalTransition: 0,
    oralTransition: 0,
    oralCareFlg: 0,
    medicalFoods: 0,
    collectionOfUtilityFee: 0,
    foodBreakfast: 0,
    foodLunch: 0,
    foodSupper: 0,
    nutritionManagement: 0,
    hospitalVisitSupportFlg: 0,
    regionalTransitionPromotion2Flg: 0,
    infectiousDiseasesFacilityFlg: 0
  });
};

/**
 * storeのusagePerformanceSHISETSUNYUSHOをpostのusagePerformanceSHISETSUNYUSHOに変換
 * @param usagePerformanceSHISETSUNYUSHO
 * @param diffUsagePerformance
 */
const convertUsagePerformanceSHISETSUNYUSHO = (
  usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
  diffUsagePerformance: Partial<UsagePerformanceSHISETSUNYUSHOType>
):
  | PostUsagePerformanceSHISETSUNYUSHODailyParam
  | PostUsagePerformanceSHISETSUNYUSHOMonthlyParam => {
  const postUsagePerformance:
    | PostUsagePerformanceSHISETSUNYUSHODailyParam
    | PostUsagePerformanceSHISETSUNYUSHOMonthlyParam = {
    target_date: usagePerformanceSHISETSUNYUSHO.targetDate,
    users_in_facility_id: usagePerformanceSHISETSUNYUSHO.usersInFacilityId
  };
  Object.keys(usagePerformanceSHISETSUNYUSHOConvertParamList).forEach(
    (key: string) => {
      if (
        diffUsagePerformance[
          usagePerformanceSHISETSUNYUSHOConvertParamList[key].storeKey
        ] !== undefined
      ) {
        postUsagePerformance[
          usagePerformanceSHISETSUNYUSHOConvertParamList[key].paramKey
        ] =
          diffUsagePerformance[
            usagePerformanceSHISETSUNYUSHOConvertParamList[key].storeKey
          ];
      }
    }
  );
  return postUsagePerformance;
};

/**
 * 一括登録用のusagePerformanceSHISETSUNYUSHOの作成
 * @param usagePerformanceSHISETSUNYUSHO
 * @param usagePerformance
 * @param isDaily true→日ごとのデータ/false→月ごとのデータ
 */
const createUsagePerformanceSHISETSUNYUSHOParam = (
  usagePerformanceSHISETSUNYUSHO: ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"],
  usagePerformance: ReportState[ReportType]["usagePerformance"],
  isDaily: boolean
):
  | PostUsagePerformanceDailyParams["usage_performance_shisetsunyusho"]
  | PostUsagePerformanceMonthlyParams["usage_performance_shisetsunyusho"] => {
  const postUsagePerformanceSHISETSUNYUSHO:
    | PostUsagePerformanceDailyParams["usage_performance_shisetsunyusho"]
    | PostUsagePerformanceMonthlyParams["usage_performance_shisetsunyusho"] = [];
  const usagePerformanceSHISETSUNYUSHOValue = usagePerformanceSHISETSUNYUSHO;
  Object.keys(usagePerformanceSHISETSUNYUSHOValue.before).forEach(
    (keyValue: string) => {
      // 初回登録時のみデフォルト値の考慮
      if (
        usagePerformance.before[keyValue].initialFlg &&
        `${usagePerformance.after[keyValue].statusType}` !== StatusType.NONE
      ) {
        usagePerformanceSHISETSUNYUSHOValue.before[keyValue].foodBreakfast = 0;
        usagePerformanceSHISETSUNYUSHOValue.before[keyValue].foodLunch = 0;
        usagePerformanceSHISETSUNYUSHOValue.before[keyValue].foodSupper = 0;
      }
      // サービス提供状況が【-】に更新しているかどうか
      const isInitializedUsagePerformance =
        usagePerformance.after[keyValue].statusType !==
          usagePerformance.before[keyValue].statusType &&
        `${usagePerformance.after[keyValue].statusType}` === StatusType.NONE;
      // hospitalizationDaysの値をPOST用に変換
      // 日ごと
      if (isDaily) {
        usagePerformanceSHISETSUNYUSHOValue.after[
          keyValue
        ].hospitalizationDays = normalizeHospitalizationDays(
          usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
            .hospitalizationDays,
          usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
            .hospitalizationDaysPrev,
          usagePerformance.after[keyValue].statusType,
          false
        );
        // 月ごと
      } else {
        usagePerformanceSHISETSUNYUSHOValue.after[
          keyValue
        ].hospitalizationDays = normalizeHospitalizationDays(
          usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
            .hospitalizationDays,
          getUsersHospitalizationDaysPrev(
            keyValue,
            usagePerformanceSHISETSUNYUSHOValue
          ),
          usagePerformance.after[keyValue].statusType,
          false
        );
      }
      // 全体を見て違いがなければまたはサービス提供状況が【-】で更新していなければ処理をしない
      if (
        !deepEqual(
          usagePerformanceSHISETSUNYUSHOValue.before[keyValue],
          usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
        ) ||
        isInitializedUsagePerformance
      ) {
        // サービス提供状況が【-】で更新している場合、差分更新せずに各項目の初期値に設定する
        const diffObject: Partial<UsagePerformanceSHISETSUNYUSHOType> = isInitializedUsagePerformance
          ? initializeUsagePerformanceSHISETSUNYUSHO(
              usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
            )
          : removeNoChangeData<UsagePerformanceSHISETSUNYUSHOType>(
              usagePerformanceSHISETSUNYUSHOValue.before[keyValue],
              usagePerformanceSHISETSUNYUSHOValue.after[keyValue]
            );
        postUsagePerformanceSHISETSUNYUSHO.push(
          convertUsagePerformanceSHISETSUNYUSHO(
            usagePerformanceSHISETSUNYUSHOValue.before[keyValue],
            diffObject
          )
        );
      }
    }
  );
  return postUsagePerformanceSHISETSUNYUSHO;
};

/**
 * 対象データ内に対象日と対象ユーザが合致するものがあればtrue,それ以外falseを返す
 * @param targetDate 対象日
 * @param uifId 対象ユーザId
 * @param postUsagePerformanceSHISETSUNYUSHOList 対象データ
 */
const existPostUsagePerformance = (
  targetDate: string,
  uifId: number,
  postUsagePerformanceSHISETSUNYUSHOList:
    | PostUsagePerformanceDailyParams["usage_performance_shisetsunyusho"]
    | PostUsagePerformanceMonthlyParams["usage_performance_shisetsunyusho"]
): boolean => {
  return postUsagePerformanceSHISETSUNYUSHOList
    ? postUsagePerformanceSHISETSUNYUSHOList.some(
        (
          postUsagePerformanceSHISETSUNYUSHO: Partial<
            | PostUsagePerformanceSHISETSUNYUSHODailyParam
            | PostUsagePerformanceSHISETSUNYUSHOMonthlyParam
          >
        ) => {
          return (
            postUsagePerformanceSHISETSUNYUSHO.users_in_facility_id === uifId &&
            postUsagePerformanceSHISETSUNYUSHO.target_date === targetDate
          );
        }
      )
    : false;
};

/**
 * storeのusagePerformanceをpostのusagePerformanceに変換
 * @param usagePerformance
 */
const convertUsagePerformanceParams = (
  usagePerformance: UsagePerformanceType
): PostUsagePerformanceDailyParam | PostUsagePerformanceMonthlyParam => {
  // 既存のsetUsagePerformanceが差分更新に対応していないため、1つでも差分が存在すれば全て送る。
  return {
    target_date: usagePerformance.targetDate,
    users_in_facility_id: usagePerformance.usersInFacilityId,
    status_type: usagePerformance.statusType,
    remarks: usagePerformance.remarks ? usagePerformance.remarks : null,
    intensive_support_flg: usagePerformance.intensiveSupportFlg,
    intensive_support_start_date: usagePerformance.intensiveSupportStartDate,
    severe_disability_support_type:
      usagePerformance.severeDisabilitySupportType,
    // 以下の項目は入れないとAPIでエラーを起こすため設定する。
    daytime_support_type: null,
    get_home_support_type: null,
    hospitalization_support_type: null,
    life_support_flg: null,
    night_support_type: null,
    medical_support_type: null
  };
};

/**
 * 一括登録用のusagePerformanceの作成
 * @param usagePerformance storeのusagePerformance
 * @param postUsagePerformanceSHISETSUNYUSHO usagePerformanceSHISETSUNYUSHOのpostデータ
 */
const createUsagePerformanceParam = (
  usagePerformance: ReportState[ReportType]["usagePerformance"],
  postUsagePerformanceSHISETSUNYUSHO:
    | PostUsagePerformanceDailyParams["usage_performance_shisetsunyusho"]
    | PostUsagePerformanceMonthlyParams["usage_performance_shisetsunyusho"]
):
  | PostUsagePerformanceDailyParams["usage_performance"]
  | PostUsagePerformanceMonthlyParams["usage_performance"] => {
  const postUsagePerformance:
    | PostUsagePerformanceDailyParams["usage_performance"]
    | PostUsagePerformanceMonthlyParams["usage_performance"] = [];
  const usagePerformanceValue = usagePerformance;
  Object.keys(usagePerformanceValue.before).forEach((keyValue: string) => {
    // 差分更新前に初回フラグをマージする。
    usagePerformanceValue.before[keyValue].initialFlg =
      usagePerformanceValue.after[keyValue].initialFlg;

    // 差分がないorPostUsagePerformanceが存在しないときは処理をしない
    if (
      !deepEqual(
        usagePerformanceValue.before[keyValue],
        usagePerformanceValue.after[keyValue]
      ) ||
      existPostUsagePerformance(
        usagePerformanceValue.after[keyValue].targetDate,
        usagePerformanceValue.after[keyValue].usersInFacilityId,
        postUsagePerformanceSHISETSUNYUSHO
      )
    ) {
      postUsagePerformance.push(
        convertUsagePerformanceParams(usagePerformanceValue.after[keyValue])
      );
    }
  });
  return postUsagePerformance;
};

// 利用者ごと用のnormalizer
/**
 * usagePerformanceのPOSTデータ作成(差分更新)(利用者ごと)
 * @param reportUsers
 */
export const normalizePostUsagePerformanceParamUsers = (
  reportUsers: ReportState["reportUsers"]
): PostUsagePerformanceMonthlyParams => {
  const postData: PostUsagePerformanceMonthlyParams = {
    usage_performance: [],
    usage_performance_shisetsunyusho: createUsagePerformanceSHISETSUNYUSHOParam(
      reportUsers.usagePerformanceSHISETSUNYUSHO,
      reportUsers.usagePerformance,
      false
    )
  };
  postData.usage_performance = createUsagePerformanceParam(
    reportUsers.usagePerformance,
    postData.usage_performance_shisetsunyusho
  );
  return postData;
};

// 共通処理
/**
 * APIから施設利用者の実績記録オブジェクト配列の変換
 * @param getUsagePerformanceList	施設利用者の実績記録オブジェクト配列
 */
const normalizeGetUsagePerformanceParams = (
  getUsagePerformanceList:
    | GetUsagePerformanceDailyParams["data"]["usage_performance"]
    | GetUsagePerformanceMonthlyParams["data"]["usage_performance"]
): ReportState[ReportType]["usagePerformance"] => {
  const storeUsagePerformance: ReportState[ReportType]["usagePerformance"]["before"] = {};
  // getAPIのパラメータを連想配列に変換
  getUsagePerformanceList.forEach(
    (
      getUsagePerformance:
        | GetUsagePerformanceParamsDaily
        | GetUsagePerformanceParamsMonthly
    ) => {
      storeUsagePerformance[
        `${getUsagePerformance.users_in_facility_id}_${getUsagePerformance.target_date}`
      ] = {
        id: getUsagePerformance.id,
        usersInFacilityId: getUsagePerformance.users_in_facility_id,
        nameSei: getUsagePerformance.name_sei,
        nameMei: getUsagePerformance.name_mei,
        targetDate: getUsagePerformance.target_date,
        statusType: getUsagePerformance.status_type
          ? getUsagePerformance.status_type
          : 0,
        remarks: getUsagePerformance.remarks,
        isHoliday: getUsagePerformance.is_holiday,
        isServiceEnd: getUsagePerformance.is_service_end,
        initialFlg: getUsagePerformance.id === null,
        intensiveSupportFlg: getUsagePerformance.intensive_support_flg,
        intensiveSupportStartDate:
          getUsagePerformance.intensive_support_start_date,
        severeDisabilitySupportType:
          getUsagePerformance.severe_disability_support_type
      };
    }
  );

  return {
    before: cloneDeep(storeUsagePerformance),
    after: cloneDeep(storeUsagePerformance)
  };
};

/**
 * APIから施設利用者の実績記録（短期入所）オブジェクト配列の変換
 * objectのkeyは【(user_in_facility_id)-(target_date)】
 * @param getUsagePerformanceSHISETSUNYUSHOList	施設利用者の実績記録（短期入所）オブジェクト
 */
const normalizeGetUsagePerformanceSHISETSUNYUSHO = (
  getUsagePerformanceSHISETSUNYUSHOList:
    | GetUsagePerformanceDailyParams["data"]["usage_performance_shisetsunyusho"]
    | GetUsagePerformanceMonthlyParams["data"]["usage_performance_shisetsunyusho"]
): ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"] => {
  const storeUsagePerformanceSHISETSUNYUSHO: ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]["before"] = {};
  // getAPIのパラメータを連想配列に変換
  if (getUsagePerformanceSHISETSUNYUSHOList) {
    getUsagePerformanceSHISETSUNYUSHOList.forEach(
      (getUsagePerformanceSHISETSUNYUSHO) => {
        storeUsagePerformanceSHISETSUNYUSHO[
          `${getUsagePerformanceSHISETSUNYUSHO.users_in_facility_id}_${getUsagePerformanceSHISETSUNYUSHO.target_date}`
        ] = {
          id: getUsagePerformanceSHISETSUNYUSHO.id,
          usersInFacilityId:
            getUsagePerformanceSHISETSUNYUSHO.users_in_facility_id,
          targetDate: getUsagePerformanceSHISETSUNYUSHO.target_date,
          hospitalizationDays:
            getUsagePerformanceSHISETSUNYUSHO.hospitalization_days !== null
              ? getUsagePerformanceSHISETSUNYUSHO.hospitalization_days.toString()
              : null,
          hospitalizationDaysPrev:
            getUsagePerformanceSHISETSUNYUSHO.hospitalization_days_prev !== null
              ? getUsagePerformanceSHISETSUNYUSHO.hospitalization_days_prev.toString()
              : null,
          hospitalizationOvernightStay: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.hospitalization_overnightstay
          ),
          regionalTransition: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.regional_transition_flg
          ),
          oralTransition: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.oral_transition_flg
          ),
          oralCareFlg: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.oral_care_flg
          ),
          medicalFoods: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.medical_foods_flg
          ),
          nutritionManagement: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.nutrition_management_flg
          ),
          collectionOfUtilityFee: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.collection_of_utility_fee_flg
          ),
          foodBreakfast: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.food_breakfast_flg
          ),
          foodLunch: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.food_lunch_flg
          ),
          foodSupper: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.food_supper_flg
          ),
          hospitalVisitSupportFlg: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.hospital_visit_support_flg
          ),
          regionalTransitionPromotion2Flg: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.regional_transition_promotion2_flg
          ),
          infectiousDiseasesFacilityFlg: castNumber(
            getUsagePerformanceSHISETSUNYUSHO.infectious_diseases_facility_flg
          )
        };
      }
    );
  }
  return {
    before: cloneDeep(storeUsagePerformanceSHISETSUNYUSHO),
    after: cloneDeep(storeUsagePerformanceSHISETSUNYUSHO)
  };
};

/**
 * APIパラメータからstoreに変換(利用者ごと)
 * @param result
 */
export const normalizeSHISETSUNYUSHOReportUsersDataFromAPI = (
  result: GetUsagePerformanceMonthlyParams
): ReportState["reportUsers"] => {
  return {
    usagePerformance: normalizeGetUsagePerformanceParams(
      result.data.usage_performance
    ),
    usagePerformanceSHISETSUNYUSHO: normalizeGetUsagePerformanceSHISETSUNYUSHO(
      result.data.usage_performance_shisetsunyusho
    )
  };
};

// 日ごと用のnormalizer
/**
 * usagePerformanceのPOSTデータ作成(差分更新)(日ごと)
 * @param reportDaily
 */
export const normalizePostUsagePerformanceParamDaily = (
  reportDaily: ReportState["reportDaily"]
): PostUsagePerformanceDailyParams => {
  const postData: PostUsagePerformanceDailyParams = {
    usage_performance_daily: createUsagePerformanceDaily(
      reportDaily.usagePerformanceDaily
    ),
    usage_performance: [],
    usage_performance_shisetsunyusho: createUsagePerformanceSHISETSUNYUSHOParam(
      reportDaily.usagePerformanceSHISETSUNYUSHO,
      reportDaily.usagePerformance,
      true
    )
  };
  postData.usage_performance = createUsagePerformanceParam(
    reportDaily.usagePerformance,
    postData.usage_performance_shisetsunyusho
  );
  return postData;
};

/**
 * APIから施設利用者全体の実績記録オブジェクトの変換(日ごと)
 * @param targetDate 対象日付
 * @param getUsagePerformanceDaily  施設利用者全体の実績記録オブジェクト(APIデータ)
 */
const normalizeGetUsagePerformanceDaily = (
  targetDate: string,
  getUsagePerformanceDaily: GetUsagePerformanceDailyParams["data"]["usage_performance_daily"]
): ReportState["reportDaily"]["usagePerformanceDaily"] => {
  return {
    before: {
      id: getUsagePerformanceDaily ? getUsagePerformanceDaily.id : undefined,
      targetDate: targetDate || undefined
    },
    after: {
      id: getUsagePerformanceDaily ? getUsagePerformanceDaily.id : undefined,
      targetDate: targetDate || undefined
    }
  };
};

/**
 * APIパラメータからstoreに変換(日ごと)
 * @param result
 * @param targetDate
 */
export const normalizeSHISETSUNYUSHOReportDailyDataFromAPI = (
  result: GetUsagePerformanceDailyParams,
  targetDate?: Date
): ReportState["reportDaily"] => {
  const formatedDate = !targetDate
    ? ""
    : dateInHyphenYYYYMMDDFormat(targetDate);

  return {
    usagePerformanceDaily: normalizeGetUsagePerformanceDaily(
      formatedDate,
      result.data.usage_performance_daily
    ),
    usagePerformance: normalizeGetUsagePerformanceParams(
      result.data.usage_performance
    ),
    usagePerformanceSHISETSUNYUSHO: normalizeGetUsagePerformanceSHISETSUNYUSHO(
      result.data.usage_performance_shisetsunyusho
    )
  };
};

/**
 * StatusTypeの一括更新(それに伴う入院・外泊時加算入院時支援特別加算、「入院・外泊日数」項目の値の初期化)
 * @param usagePerformanceList storeデータ
 */
export const getSetAllStatusType = (
  usagePerformanceList: ReportState[ReportType]["usagePerformance"]["after"],
  usagePerformanceSHISETSUNYUSHO: ReportState["reportDaily"]["usagePerformanceSHISETSUNYUSHO"]["after"]
): {
  setAllStatusTypeList: ReportState[ReportType]["usagePerformance"]["after"];
  setAllHospitalizationOvernightStayList: ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]["after"];
} => {
  const setAllStatusTypeList: ReportState[ReportType]["usagePerformance"]["after"] = {};
  Object.keys(usagePerformanceList).forEach((keyValue: string) => {
    const setAllServiceType: UsagePerformanceType = cloneDeep(
      usagePerformanceList[keyValue]
    );
    setAllServiceType.statusType = Number(StatusType.LODGING);
    // 実績入力時に初回フラグをfalseにする。
    setAllServiceType.initialFlg = false;
    setAllStatusTypeList[keyValue] = setAllServiceType;
  });
  const setAllHospitalizationOvernightStayList: ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]["after"] = {};
  Object.keys(usagePerformanceSHISETSUNYUSHO).forEach((keyValue: string) => {
    const setAllHospitalizationOvernightStay: UsagePerformanceSHISETSUNYUSHOType = cloneDeep(
      usagePerformanceSHISETSUNYUSHO[keyValue]
    );
    setAllHospitalizationOvernightStay.hospitalizationOvernightStay = Number(
      HospitalizationOvernightstay.NONE
    );
    setAllHospitalizationOvernightStay.hospitalizationDays = null;
    setAllHospitalizationOvernightStayList[
      keyValue
    ] = setAllHospitalizationOvernightStay;
  });
  return {
    setAllStatusTypeList,
    setAllHospitalizationOvernightStayList
  };
};

/**
 * UsagePerformanceの一項目更新
 * @param name 項目名
 * @param value 変更値
 * @param usagePerformance storeのusagePerformance
 */
const setUsagePerformance = (
  name: string,
  value: string | number | null,
  usagePerformance: UsagePerformanceType
): UsagePerformanceType => {
  // 登録すべきものがなかった場合return
  if (usagePerformance === undefined) {
    return usagePerformance;
  }
  const usagePerformanceValue = usagePerformance;
  usagePerformanceValue[
    UsagePerformanceStoreParamKeyToSelectBoxName[name].storeKey
  ] = value === null ? null : castStringToNumber(value);
  // 実績入力時に初回フラグをfalseにする。
  usagePerformanceValue.initialFlg = false;
  return usagePerformanceValue;
};

/**
 * 一項目更新後のUsagePerformanceの取得
 * @param name 項目の名前
 * @param value 更新後の値
 * @param keyValue 更新したusagePerformanceのkey
 * @param usagePerformance storeのusagePerformance
 */
export const getSetUsagePerformanceItems = (
  name: string,
  value: string | number | null,
  keyValue: string,
  usagePerformance: ReportState[ReportType]["usagePerformance"]["after"]
): ReportState[ReportType]["usagePerformance"]["after"] => {
  const clonedUsagePerformance = cloneDeep(usagePerformance);
  clonedUsagePerformance[keyValue] = setUsagePerformance(
    name,
    value,
    clonedUsagePerformance[keyValue]
  );
  return clonedUsagePerformance;
};

/**
 * UsagePerformanceSHISETSUNYUSHOの一項目更新
 * @param name 項目名
 * @param value 変更値
 * @param usagePerformanceSHISETSUNYUSHO storeのusagePerformanceSHISETSUNYUSHO
 */
const setUsagePerformanceSHISETSUNYUSHO = (
  name: string,
  value: string | number | null,
  usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType
): UsagePerformanceSHISETSUNYUSHOType => {
  // 登録すべきものがなかった場合return
  if (usagePerformanceSHISETSUNYUSHO === undefined) {
    return usagePerformanceSHISETSUNYUSHO;
  }
  const usagePerformanceSHISETSUNYUSHOValue = usagePerformanceSHISETSUNYUSHO;
  if (name === "hospitalizationDays" || value === null) {
    usagePerformanceSHISETSUNYUSHOValue[
      UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName[name].storeKey
    ] = value;
  } else {
    usagePerformanceSHISETSUNYUSHOValue[
      UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName[name].storeKey
    ] = value === null ? null : castStringToNumber(value);
  }

  return usagePerformanceSHISETSUNYUSHOValue;
};

/**
 * 一項目更新後のUsagePerformanceSHISETSUNYUSHOの取得
 * @param name 項目の名前
 * @param value 更新後の値
 * @param keyValue 更新したいusagePerformanceSHISETSUNYUSHOのkey
 * @param usagePerformanceSHISETSUNYUSHO storeのusagePerformanceSHISETSUNYUSHO
 */
export const getSetUsagePerfomanceSHISETSUNYUSHOItems = (
  name: string,
  value: string | number | null,
  keyValue: string,
  usagePerformanceSHISETSUNYUSHO: ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]["after"]
): ReportState[ReportType]["usagePerformanceSHISETSUNYUSHO"]["after"] => {
  const clonedUsagePerformanceSHISETSUNYUSHO = cloneDeep(
    usagePerformanceSHISETSUNYUSHO
  );
  clonedUsagePerformanceSHISETSUNYUSHO[
    keyValue
  ] = setUsagePerformanceSHISETSUNYUSHO(
    name,
    value,
    clonedUsagePerformanceSHISETSUNYUSHO[keyValue]
  );

  return clonedUsagePerformanceSHISETSUNYUSHO;
};

/**
 * サービス提供状況が[-]時の全項目の初期化
 * @param report 実績データ
 * @param notFood 事業所設定(食事提供しているかどうか)
 */
export const normalizeServiceTypeNone = (
  report: ReportState[ReportType],
  notFood: boolean
): ReportState[ReportType] => {
  const usagePerformanceAfter = report.usagePerformance.after;
  const reportState = report;
  Object.keys(usagePerformanceAfter).forEach((keyValue: string) => {
    // サービス提供状況を[-]に更新している場合のみ
    if (
      usagePerformanceAfter[keyValue].statusType !==
        reportState.usagePerformance.before[keyValue].statusType &&
      `${usagePerformanceAfter[keyValue].statusType}` === StatusType.NONE
    ) {
      reportState.usagePerformanceSHISETSUNYUSHO.after[
        keyValue
      ] = initializeUsagePerformanceSHISETSUNYUSHO(
        reportState.usagePerformanceSHISETSUNYUSHO.after[keyValue]
      );
    }
    // 事業所設定の食事提供がOFFの場合、食事提供を初期化する。
    if (notFood) {
      reportState.usagePerformanceSHISETSUNYUSHO.after[
        keyValue
      ].foodBreakfast = 0;
      reportState.usagePerformanceSHISETSUNYUSHO.after[keyValue].foodLunch = 0;
      reportState.usagePerformanceSHISETSUNYUSHO.after[keyValue].foodSupper = 0;
    }
  });
  return reportState;
};

/**
 * 実績編集postデータ作成
 */
const normalizePostUsagePerformanceData = (
  report: UsagePerformanceType,
  reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
  formValue: InitialValues,
  facilityState: FacilityState,
  reportType: ReportType
): {
  store: UsagePerformanceType;
  storeSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType;
  post: PostUsagePerformanceDailyParams | PostUsagePerformanceMonthlyParams;
} => {
  // formikをstoreの値に変換
  const usagePerformanceFormValue: UsagePerformanceType = {
    ...report,
    statusType: Number(formValue.initial.statusType),
    remarks: formValue.initial.remarks,
    intensiveSupportFlg:
      Number(formValue.initial.statusType) !== 0 &&
      formValue.initial.intensiveSupportFlg
        ? Number(formValue.initial.intensiveSupportFlg)
        : INT_FALSE_FROM_API,
    intensiveSupportStartDate:
      Number(formValue.initial.statusType) !== 0 &&
      formValue.initial.intensiveSupportStartDate &&
      selectDateValueToDate(formValue.initial.intensiveSupportStartDate) !== ""
        ? selectDateValueToDate(formValue.initial.intensiveSupportStartDate)
        : null,
    severeDisabilitySupportType: Number(
      formValue.initial.severeDisabilitySupportType
    ),
    initialFlg: false
  };
  let usagePerformanceSHISETSUNYUSHOFormValue: UsagePerformanceSHISETSUNYUSHOType = {
    ...reportSHISETSUNYUSHO,
    hospitalizationDays: normalizeHospitalizationDays(
      formValue.initial.hospitalizationDays === null
        ? null
        : formValue.initial.hospitalizationDays.toString(),
      formValue.initial.hospitalizationDaysPrev === null
        ? null
        : formValue.initial.hospitalizationDaysPrev.toString(),
      Number(formValue.initial.statusType),
      true
    ),
    hospitalizationOvernightStay: Number(
      formValue.initial.hospitalizationOvernightStay
    ),
    regionalTransition: formValue.initial.regionalTransitionFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    oralTransition: formValue.initial.oralTransition
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    oralCareFlg:
      facilityState.oralHygieneManagementSystemFlg === INT_TRUE_FROM_API &&
      formValue.initial.oralCareFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
    medicalFoods: formValue.initial.medicalFoodsFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    nutritionManagement: formValue.initial.nutritionManagementFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    collectionOfUtilityFee: formValue.initial.collectionOfUtilityFeeFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    foodBreakfast:
      facilityState.availableFood && formValue.initial.foodBreakfastFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
    foodLunch:
      facilityState.availableFood && formValue.initial.foodLunchFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
    foodSupper:
      facilityState.availableFood && formValue.initial.foodSupperFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
    hospitalVisitSupportFlg: formValue.initial.hospitalVisitSupportFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    regionalTransitionPromotion2Flg: formValue.initial
      .regionalTransitionPromotion2Flg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API,
    infectiousDiseasesFacilityFlg: formValue.initial
      .infectiousDiseasesFacilityFlg
      ? INT_TRUE_FROM_API
      : INT_FALSE_FROM_API
  };

  // disabledの項目を初期化する処理
  let diffUsagePerformanceSHISETSUNYUSHO: Partial<UsagePerformanceSHISETSUNYUSHOType> = usagePerformanceSHISETSUNYUSHOFormValue;
  const reportSHISETSUNYUSHOValue = reportSHISETSUNYUSHO;
  if (
    `${usagePerformanceFormValue.statusType}` === StatusType.NONE.toString()
  ) {
    usagePerformanceSHISETSUNYUSHOFormValue = initializeUsagePerformanceSHISETSUNYUSHO(
      usagePerformanceSHISETSUNYUSHOFormValue
    );
    diffUsagePerformanceSHISETSUNYUSHO = usagePerformanceSHISETSUNYUSHOFormValue;

    usagePerformanceFormValue.severeDisabilitySupportType = 0;
  } else {
    // 初回登録時のみデフォルト値の考慮
    if (report.initialFlg) {
      reportSHISETSUNYUSHOValue.foodBreakfast = 0;
      reportSHISETSUNYUSHOValue.foodLunch = 0;
      reportSHISETSUNYUSHOValue.foodSupper = 0;
    }
    // 差分抽出
    diffUsagePerformanceSHISETSUNYUSHO = removeNoChangeData(
      reportSHISETSUNYUSHOValue,
      usagePerformanceSHISETSUNYUSHOFormValue
    );
  }

  const postUsagePerformanceSHISETSUNYUSHO = [];
  // 差分チェック
  if (!isEmpty(diffUsagePerformanceSHISETSUNYUSHO)) {
    // 特定のフラグがOFFの場合、APIに送信する項目を削除する
    // 子要素が更新されていたら、親要素も更新したことにする
    postUsagePerformanceSHISETSUNYUSHO.push(
      convertUsagePerformanceSHISETSUNYUSHO(
        reportSHISETSUNYUSHOValue,
        diffUsagePerformanceSHISETSUNYUSHO
      )
    );
  }

  const postUsagePerformance = [];
  // 差分チェック
  if (
    !deepEqual(report, usagePerformanceFormValue) ||
    postUsagePerformanceSHISETSUNYUSHO.length !== 0
  ) {
    postUsagePerformance.push(
      convertUsagePerformanceParams(usagePerformanceFormValue)
    );
  }
  const postValue =
    reportType === REPEAT_DAILY
      ? {
          usage_performance_daily: {},
          usage_performance: postUsagePerformance,
          usage_performance_shisetsunyusho: postUsagePerformanceSHISETSUNYUSHO
        }
      : {
          usage_performance: postUsagePerformance,
          usage_performance_shisetsunyusho: postUsagePerformanceSHISETSUNYUSHO
        };
  return {
    store: usagePerformanceFormValue,
    storeSHISETSUNYUSHO: usagePerformanceSHISETSUNYUSHOFormValue,
    post: postValue
  };
};

/**
 * ダイアログからの実績登録データの変換
 * @param report
 * @param reportSHISETSUNYUSHO
 * @param formValue
 * @param usersInFacilityState
 * @param facilityState
 * @param reportType
 */
export const normalizeSHISETSUNYUSHOReportDataToAPI = (
  report: UsagePerformanceType,
  reportSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType,
  formValue: InitialValues,
  facilityState: FacilityState,
  reportType: ReportType
): {
  store: UsagePerformanceType;
  storeSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType;
  post: PostUsagePerformanceDailyParams | PostUsagePerformanceMonthlyParams;
} => {
  return normalizePostUsagePerformanceData(
    report,
    reportSHISETSUNYUSHO,
    formValue,
    facilityState,
    reportType
  );
};
