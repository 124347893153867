import * as React from "react";
import { getIn, Field, FormikProps, FieldProps } from "formik";
import MuiRadioButtons, {
  MuiRadioButtonsProps
} from "@components/molecules/MuiRadioButtons";

type ChangeHookEvent = (
  event: React.ChangeEvent<HTMLInputElement>,
  beforeValue: string
) => void;

type OwnProps = {
  name: string;
  disabled?: boolean;
  onChangeHook?: ChangeHookEvent;
  labelTextClass?: string;
  formControlLabelClass?: React.CSSProperties;
};

type NeverSelectProps = {
  value?: never;
  onChange?: never;
};

type Props = OwnProps & NeverSelectProps & MuiRadioButtonsProps;

const handleChange = (
  event: React.ChangeEvent<HTMLInputElement>,
  beforeValue: string,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>, // 使用されるformikPropsがページごとに違うためany
  onChangeHook?: ChangeHookEvent
): void => {
  if (onChangeHook) onChangeHook(event, beforeValue);
  form.setFieldValue(event.target.name, event.target.value);
  form.setFieldTouched(event.target.name, true);
};

const FormikCheckbox = ({
  onChangeHook,
  style,
  ...props
}: Props): JSX.Element => (
  <Field
    name={props.name}
    render={({ field, form }: FieldProps): JSX.Element => {
      const formikError = getIn(form.errors, props.name);
      const formikTouch =
        getIn(form.touched, props.name) || form.submitCount !== 0;
      const hasError = !!(formikTouch && formikError);
      return (
        <MuiRadioButtons
          {...props}
          value={field.value}
          style={style}
          onChange={(e): void =>
            handleChange(
              e as React.ChangeEvent<HTMLInputElement>,
              field.value,
              form,
              onChangeHook
            )
          }
          onBlur={field.onBlur}
          hasError={hasError}
          errorMessage={formikError}
        />
      );
    }}
  />
);

export default FormikCheckbox;
