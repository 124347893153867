import * as React from "react";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import ContentHeaderSubmit from "@components/molecules/ContentHeaderSubmit";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { ReportState } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/report/types";
import { InitialValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/report/initial";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    floatLeft: {
      float: "left"
    },
    floatRight: {
      float: "right"
    },
    dateButtonsContainer: {
      paddingTop: spacing.unit
    },
    usersDropDown: {
      marginTop: "0px !important",
      marginLeft: spacing.unit * 2
    },
    headerInfoContainer: {
      minHeight: 56,
      marginTop: 16,
      marginLeft: 16,
      width: "100%"
    },
    noConstant: {
      marginRight: 116
    },
    buttonContainer: {
      paddingRight: 16
    },
    buttonWidth: {
      width: 120
    }
  });

type StateProps = {
  InOutResultList: ReportState;
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchFacilityUserList: (date: Date) => Promise<void>;
  postCHIIKITEICHAKUReportMonthly: (
    uifId: number,
    date: Date,
    formValue: boolean
  ) => Promise<void>;
};

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedMonth: Date;
  selectedUserId: string | number;
  isSubmitDisabled: boolean;
  onChangeMonth: (date: Date, user: OptionInterface) => Promise<void>;
  onChangeUser: (user: OptionInterface) => Promise<void>;
  onChangeEditMode: (isEditing: boolean) => void;
  resetForm: (nextValues?: InitialValues) => void;
  InOutResultList: InitialValues;
  onDefaultCheck: () => boolean;
  onCheckBoxFlg: (checkFlg: boolean) => void;
};

type MergeProps = OwnProps &
  DispatchProps & {
    facilityListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）- 月変更 & 利用者変更（通常時）
 */
const InOutReportMonthlyHeaderCore = (props: Props): JSX.Element => {
  const {
    classes,
    minDate,
    maxDate,
    selectedMonth,
    selectedUserId,
    facilityListOption
  } = props;
  const [isCheck, setIsCheck] = React.useState<boolean>(props.onDefaultCheck());
  const [isUpdated, setUpdated] = React.useState<boolean>(true);

  React.useEffect((): void => {
    if (!props.selectedUserId) {
      props.fetchFacilityUserList(props.selectedMonth);
    }
  }, [props.selectedUserId]);

  React.useEffect((): void => {
    if (
      !props.selectedUserId &&
      props.facilityListOption &&
      props.facilityListOption[0]
    ) {
      props.onChangeUser(props.facilityListOption[0]).then(() => {
        setUpdated(true);
      });
    }
  }, [props.facilityListOption]);

  React.useEffect((): void => {
    if (isUpdated) {
      setIsCheck(props.onDefaultCheck());
      setUpdated(false);
    }
  }, [isUpdated]);

  const onChangeMonth = (date: Date): void => {
    props.fetchFacilityUserList(date).then(() => {
      const facility = props.facilityListOption.filter(
        (x) => x.value === props.selectedUserId
      );
      let selectedUser;
      if (facility.length > 0) {
        [selectedUser] = facility;
      } else if (props.facilityListOption.length > 0) {
        [selectedUser] = props.facilityListOption;
      } else {
        selectedUser = { label: "", value: props.selectedUserId };
      }
      props.onChangeMonth(date, selectedUser).then(() => {
        setUpdated(true);
      });
    });
  };

  // 月変更時
  React.useEffect((): void => {
    onChangeMonth(selectedMonth);
  }, [selectedMonth]);

  const onChangeUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const facility = props.facilityListOption.filter(
      (x) => x.value === event.target.value
    );
    if (facility.length > 0) {
      props.onChangeUser(facility[0]).then(() => {
        setUpdated(true);
      });
    }
  };

  const onChange = (): void => {
    props.resetForm(props.InOutResultList);
    props.onChangeEditMode(true);
  };

  const onCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.onCheckBoxFlg(e.target.checked);
    setIsCheck(e.target.checked);
  };

  const checkBoxStyle = {
    position: "absolute" as const,
    margin: "0px 0 0"
  };
  return (
    <>
      <div className={classes.headerInfoContainer}>
        <div
          className={ClassNames(
            classes.floatLeft,
            classes.dateButtonsContainer
          )}
        >
          <DateSelectButtonsMonthly
            selectedMonth={selectedMonth}
            min={minDate}
            max={maxDate}
            onClickSubmit={onChangeMonth}
          />
        </div>
        <div className={classes.floatLeft}>
          <DropDown
            id="users-in-facility-list"
            label="利用者"
            isError={false}
            options={facilityListOption}
            value={`${selectedUserId}`}
            styles={classes.usersDropDown}
            onChange={onChangeUser}
          />
        </div>
        <div className={classes.floatRight}>
          <ContentHeaderSubmit
            buttonName="編集"
            handleSubmit={onChange}
            submitStyle={classes.buttonWidth}
            toolbarStyle={classes.buttonContainer}
            isSubmitDisabled={props.isSubmitDisabled}
          />
        </div>
        <div className={ClassNames(classes.floatLeft, classes.noConstant)}>
          <MuiCheckbox
            name="initial.noConstantContactSystemFlg"
            label="常時連絡体制確保の未実施"
            focusVisibleClassName={classes.root}
            style={checkBoxStyle}
            onChange={onCheck}
            checked={isCheck}
            disabled={props.isSubmitDisabled}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="noConstantContactSystemFlgCHIIKITEICHAKU" />
                }
              />
            }
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    InOutResultList: state.v202104.CHIIKITEICHAKU.report,
    userInFacility: state.v202104.CHIIKITEICHAKU.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const userInFacilityDispatcher = v202104.CHIIKITEICHAKU.userInFacilityDispatcher(
    dispatch
  );
  const reportDispatcher = v202104.CHIIKITEICHAKU.reportDispatcher(dispatch);
  return {
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date),
    postCHIIKITEICHAKUReportMonthly: (
      uifId: number,
      date: Date,
      formValue: boolean
    ): Promise<void> =>
      reportDispatcher.postCHIIKITEICHAKUReportMonthly(uifId, date, formValue)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const facilityListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    facilityListOption,
    ...ownProps,
    ...dispatchProps
  };
};

export const InOutReportMonthlyHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(InOutReportMonthlyHeaderCore));
