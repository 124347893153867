import * as React from "react";
import { FastField, FieldProps, FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { dateInYYYYFormat, getWarekiList } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";

type ChangeHookEvent = (
  event: React.ChangeEvent<HTMLSelectElement>,
  beforeValue: string
) => void;

const defaultSelect = { label: "選択してください", value: "" };

type Props = {
  name: string;
  label: string;
  required?: boolean;
  addYearTo?: number;
  overrideYearFrom?: number;
  overrideYearTo?: number;
  style?: React.CSSProperties;
  yearError?: boolean;
  monthError?: boolean;
  onChangeHook?: ChangeHookEvent;
};

type ClassProps = Props & {
  value: SelectDateValue;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>; // 使用されるformikPropsがページごとに違うためany
};

type ClassState = {
  yearOptions: { label: string; value: string | number }[];
  monthOptions: { label: string; value: string }[];
};

class FormikSelectMonthClass extends React.Component<ClassProps, ClassState> {
  constructor(props: ClassProps) {
    super(props);
    this.state = {
      yearOptions: [],
      monthOptions: []
    };
  }

  public componentDidMount(): void {
    const currentYear = +dateInYYYYFormat(new Date());
    const yearFrom = currentYear - 86;
    const from = this.props.overrideYearFrom || yearFrom;
    const to =
      this.props.overrideYearTo || currentYear + (this.props.addYearTo || 0);
    const yearOptions = [defaultSelect, ...getWarekiList(from, to)];
    const monthOptions = createOneToNumberOptions(12, "月");
    this.setState({ yearOptions, monthOptions });
  }

  public shouldComponentUpdate(
    nextProps: ClassProps,
    nextState: ClassState
  ): boolean {
    return (
      nextProps.value.year !== this.props.value.year ||
      nextProps.value.month !== this.props.value.month ||
      nextState.yearOptions.length !== this.state.yearOptions.length ||
      nextState.monthOptions.length !== this.state.monthOptions.length ||
      nextProps.yearError !== this.props.yearError ||
      nextProps.monthError !== this.props.monthError
    );
  }

  public render(): JSX.Element {
    const { label, name, required, style } = this.props;
    return (
      <FormGroup row style={style || { marginBottom: 32 }}>
        <FormikSelect
          name={`${name}.year`}
          label={label}
          required={required}
          options={this.state.yearOptions}
          style={{ marginBottom: 0 }}
          isSelectablePlaceholder
          error={this.props.yearError}
          onChangeHook={this.props.onChangeHook}
        />
        <FormikSelect
          name={`${name}.month`}
          label="月"
          shrink={false}
          size="small"
          options={this.state.monthOptions}
          style={{ marginBottom: 0 }}
          error={this.props.monthError}
          onChangeHook={this.props.onChangeHook}
        />
      </FormGroup>
    );
  }
}

export const FormikSelectMonth = (props: Props): JSX.Element => (
  // tslint:disable:jsx-no-lambda
  <FastField
    name={props.name}
    render={({ field, form }: FieldProps<SelectDateValue>): JSX.Element => (
      <FormikSelectMonthClass value={field.value} form={form} {...props} />
    )}
  />
);
