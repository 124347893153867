// react
import * as React from "react";
// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// stores
import { AssociatedFacilityListState } from "@stores/domain/facilities/types";
// constants
import { FACILITY_TYPE_NAME_LIST, FacilityType } from "@constants/variables";
// components
import { OptionInterface } from "@components/atoms/DropDown";

const styles = (): StyleRules =>
  createStyles({
    listItemRoot: {
      width: "auto",
      boxSizing: "content-box",
      margin: "0 32px"
    },
    listItem: {
      minHeight: 32,
      paddingTop: 12,
      paddingBottom: 12,
      "&:hover": {
        backgroundColor: "#eceff1"
      },
      paddingRight: "0px"
    },
    facilityTypeName: {
      width: "280px",
      minWidth: "280px",
      maxWidth: "280px",
      boxSizing: "content-box"
    },
    facilityName: {
      fontSize: 16,
      paddingLeft: "0px"
    }
  });

/**
 * 施設切替画面用の種別名リスト
 * （計画相談と障害児相談は”計画相談/障害児相談”表記とするためリスト作り直す）
 */
const FACILITY_TYPE_NAME_FOR_SWITCH_ASSOCIATES_FACILITY_LIST: OptionInterface[] = FACILITY_TYPE_NAME_LIST.map(
  (item) =>
    item.value === FacilityType.KEIKAKUSODAN
      ? { label: "計画相談/障害児相談", value: FacilityType.KEIKAKUSODAN }
      : item
);

type OwnProps = {
  facility: AssociatedFacilityListState["data"][0];
  handleClick: (facility: { id: number }) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssociatedFacilityListItemCore = (props: Props): JSX.Element => {
  const { classes, facility, handleClick } = props;
  const changeFacility = (): void => handleClick({ id: facility.id });

  const facilityTypeName = FACILITY_TYPE_NAME_FOR_SWITCH_ASSOCIATES_FACILITY_LIST.find(
    (fTypeName) => fTypeName.value === facility.type_service
  );

  return (
    <ListItem
      button
      component="li"
      classes={{ root: classes.listItemRoot }}
      className={classes.listItem}
      onClick={changeFacility}
      disabled={facility.selected}
    >
      <ListItemText
        className={classes.facilityTypeName}
        primary={facilityTypeName ? facilityTypeName.label : ""}
      />
      <ListItemText className={classes.facilityName} primary={facility.name} />
    </ListItem>
  );
};

export const AssociatedFacilityListItem = withStyles(styles)(
  AssociatedFacilityListItemCore
);
