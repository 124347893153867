import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Assessment } from "@initialize/record/assessment/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  ASSESSMENT_CATEGORY_TYPE_NAME,
  ASSESSMENT_CATEGORY_TYPE
} from "@constants/variables";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { FormikProps } from "formik";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    categoryType: {
      color: "#37474f",
      padding: "48px 0 5px 0",
      borderBottom: "1px solid #37474f"
    },
    header: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: 48,
      marginTop: 24,
      backgroundColor: "#eceff1",
      color: "#37474f",
      fontSize: 12
    },
    item: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      minHeight: 56,
      borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
    },
    itemRemarks: {
      alignItems: "center",
      width: "100%",
      minHeight: 50
    },
    headerName: {
      width: 276
    },
    headerRadio: {
      marginLeft: 12,
      width: 48
    },
    headerText: {
      marginLeft: 100,
      marginRight: "auto"
    },
    itemName: {
      paddingLeft: 16,
      width: 276
    },
    itemNameRemarks: {
      marginTop: 32,
      fontSize: 12,
      color: "#37474f"
    },
    itemRadio: {
      marginLeft: 12,
      width: 48
    },
    itemText5: {
      width: "calc(100% - 60px * 5 - 376px)",
      margin: "12px auto 12px 0px"
    },
    itemText3: {
      width: "calc(100% - 60px * 3 - 376px)",
      margin: "12px auto 12px 0px"
    },
    itemText2: {
      width: "calc(100% - 60px * 2 - 376px)",
      margin: "12px auto 12px 0px"
    },
    itemText0: {
      width: "calc(100% - 60px * 0 - 376px)",
      margin: "12px auto 12px 0px"
    },
    unCheck: {
      width: 100,
      marginLeft: -5
    },
    category: {
      width: "100%",
      height: 34,
      backgroundColor: "#f5f5f5",
      marginTop: 1,
      paddingLeft: 8,
      lineHeight: "34px"
    },
    radioText: {
      marginLeft: 8
    },
    noItem: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      margin: "8px 6px 24px 17px"
    }
  });

type OwnProps = {
  categoryType: number;
  formValues: Assessment;
  formikProps: FormikProps<Assessment>;
  isEditing: boolean;
  customRecords: CustomRecordsWithCategoryState;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentSheetFormCore = (props: Props): JSX.Element => {
  const {
    categoryType,
    formValues,
    formikProps,
    isEditing,
    customRecords,
    classes
  } = props;

  const getName = (
    idxs: { categoryIdx: number; inputIdx: number },
    name: string
  ): string => {
    const { categoryIdx, inputIdx } = idxs;
    return `assessment_records[${categoryIdx}]['input'][${inputIdx}]['${name}']`;
  };

  const getInput = (idxs: {
    categoryIdx: number;
    inputIdx: number;
  }): Assessment["assessment_records"][number]["input"][number] => {
    const { categoryIdx, inputIdx } = idxs;
    return formValues.assessment_records[categoryIdx].input[inputIdx];
  };

  const getIdx = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): { categoryIdx: number; inputIdx: number } => {
    const categoryIdx = formValues.assessment_records.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx === -1) {
      return { categoryIdx, inputIdx: -1 };
    }
    const inputIdx = formValues.assessment_records[categoryIdx].input.findIndex(
      (i) => i.custom_record_item_id === item.id
    );
    return { categoryIdx, inputIdx };
  };

  const getOptions = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): { label: string; value: string }[] => {
    return item.custom_record_item_choices.map((i) => {
      return { label: "", value: `${i.id}` };
    });
  };

  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    })
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          const input = getInput(getIdx(i));
          return (
            (r.visibility && i.visibility) ||
            input.input_data ||
            !!input.choiced_item_id
          );
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter((r) => r.visibility || r.custom_record_items.length);

  const onClickUnCheck = (e: React.MouseEvent<HTMLInputElement>): void => {
    const { name } = e.currentTarget;
    formikProps.setFieldValue(name, "");
  };

  let header: JSX.Element = <></>;
  switch (categoryType) {
    case ASSESSMENT_CATEGORY_TYPE.everyday_life:
      header = (
        <>
          <div className={classes.headerRadio}>自立</div>
          <div className={classes.headerRadio}>
            ほぼ
            <br />
            自立
          </div>
          <div className={classes.headerRadio}>
            一部
            <br />
            介助
          </div>
          <div className={classes.headerRadio}>
            多くを
            <br />
            介助
          </div>
          <div className={classes.headerRadio}>
            全て
            <br />
            介助
          </div>
        </>
      );
      break;
    case ASSESSMENT_CATEGORY_TYPE.psychosomatic_state:
      header = (
        <>
          <div className={classes.headerRadio}>普通</div>
          <div className={classes.headerRadio}>
            やや
            <br />
            困難
          </div>
          <div className={classes.headerRadio}>困難</div>
        </>
      );
      break;
    case ASSESSMENT_CATEGORY_TYPE.fault_characteristics:
      header = (
        <>
          <div className={classes.headerRadio}>あり</div>
          <div className={classes.headerRadio}>なし</div>
        </>
      );
      break;
    default:
  }

  const itemsBody = (
    items: CustomRecordsWithCategoryState[number]["custom_record_items"]
  ): JSX.Element[] => {
    if (!items.length) {
      return [
        <div className={classes.noItem} key="noItem">
          このカテゴリー内の項目は全て非表示に設定されています。アセスメントの設定画面を確認してください。
        </div>
      ];
    }
    return items.map((i) => {
      const idxs = getIdx(i);
      const options = getOptions(i);
      const input = getInput(idxs);
      return (
        <div className={classes.item} key={`item-${i.id}`}>
          <div className={classes.itemName} key={`itemName-${i.id}`}>
            {i.name}
          </div>
          {isEditing ? (
            <FormikRadioButtons
              key={`itemRadio-${i.id}`}
              style={{ display: "flex", flexDirection: "row", margin: 0 }}
              labelTextClass={classes.radioText}
              formControlLabelClass={{ width: 60, margin: 0 }}
              name={getName(idxs, "choiced_item_id")}
              label=""
              options={options}
            />
          ) : (
            <MuiRadioButtons
              key={`itemRadio-${i.id}`}
              style={{ display: "flex", flexDirection: "row", margin: 0 }}
              labelTextClass={classes.radioText}
              formControlLabelClass={{ width: 60, margin: 0 }}
              name={getName(idxs, "choiced_item_id")}
              label=""
              options={options}
              value={input.choiced_item_id}
              disabled
            />
          )}
          <div className={classes.unCheck} key={`itemUnCheck-${i.id}`}>
            {isEditing && (
              <KnowbeButton
                kind="outlineMini"
                name={getName(idxs, "choiced_item_id")}
                onClick={onClickUnCheck}
                disabled={!input.choiced_item_id}
              >
                選択解除
              </KnowbeButton>
            )}
          </div>
          <div
            className={classes[`itemText${options.length}`]}
            key={`itemText-${i.id}`}
          >
            <RecordTextField
              key={`field-${i.id}`}
              name={getName(idxs, "input_data")}
              labelType="default"
              defaultValue="-"
              placeholder=""
              value={input.input_data}
              isEditable={isEditing}
              style={{ marginBottom: 0, width: "100%", paddingRight: 16 }}
            />
          </div>
        </div>
      );
    });
  };

  const itemsBodyRemarks = (
    items: CustomRecordsWithCategoryState[number]["custom_record_items"]
  ): JSX.Element[] => {
    return items.map((i) => {
      const idxs = getIdx(i);
      const input = getInput(idxs);
      return (
        <div className={classes.itemRemarks} key={`item-${i.id}`}>
          <div className={classes.itemNameRemarks} key={`itemName-${i.id}`}>
            {i.name}
          </div>
          <RecordTextField
            key={`field-${i.id}`}
            name={getName(idxs, "input_data")}
            labelType="default"
            defaultValue=""
            placeholder=""
            value={input.input_data}
            isEditable={isEditing}
            style={{ marginBottom: 0, width: "100%" }}
          />
        </div>
      );
    });
  };

  if (
    !sortedCustomRecords.length ||
    (categoryType === ASSESSMENT_CATEGORY_TYPE.remarks &&
      !sortedCustomRecords[0].custom_record_items.length)
  ) {
    return <></>;
  }

  return (
    <>
      <div className={classes.categoryType}>
        {ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}
      </div>
      {categoryType !== ASSESSMENT_CATEGORY_TYPE.remarks && (
        <div className={classes.header}>
          <div className={classes.headerName}> </div>
          {header}
          <div className={classes.headerText}>備考</div>
        </div>
      )}
      {sortedCustomRecords.map((category) => (
        <div key={`categoryContainer-${category.id}`}>
          {categoryType !== ASSESSMENT_CATEGORY_TYPE.remarks ? (
            <div key={`categoryName-${category.id}`}>
              <div
                className={classes.category}
                key={`categoryName-${category.id}`}
              >
                {category.name}
              </div>
              {itemsBody(category.custom_record_items)}
            </div>
          ) : (
            <div key={`categoryContainer-${category.id}`}>
              {itemsBodyRemarks(category.custom_record_items)}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export const AssessmentSheetForm = withStyles(styles)(AssessmentSheetFormCore);
