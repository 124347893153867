import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import { FormGroup } from "@material-ui/core";
import {
  InitialValues,
  ServiceCodesFields as ServiceCodes
} from "@interfaces/mgr/IDOSHIEN/report/initial";
import { FormikProps } from "formik";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 551,
      marginBottom: 18
    },
    firstCellLabel: {
      paddingLeft: 16
    },
    firstCell: {
      "&:first-child": {
        padding: "16px 0px 16px 16px"
      },
      "& > div": {
        width: 80,
        marginBottom: 0
      }
    },
    firstItemCell: {
      "&:last-child": {
        margin: 0
      }
    },
    iconCell: {
      display: "inline-flex",
      verticalAlign: "text-top"
    },
    price: {
      textAlign: "end",
      marginRight: 22
    },
    serviceCodeInfo: {
      transform: "translate(-55px) scale(0.75)",
      color: "rgba(0, 0, 0, 0.6)",
      marginBottom: 12
    },
    wordWrap: {
      wordWrap: "break-word"
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  onClickDelete: (index: number) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const ServiceCodesTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const serviceCodesList: ({
      index: number;
    } & ServiceCodes)[] = props.formikProps.values.initial.serviceCodes
      .map((v, i) => ({
        ...v,
        index: i
      }))
      .filter((x) => !x.isDelete);

    const headData = [
      {
        label: "コード",
        className: classes.firstCellLabel
      },
      {
        label: "サービス名称"
      },
      {
        label: "単位/単価"
      },
      {
        label: "削除"
      }
    ];

    const bodyData = (
      data: ServiceCodes,
      onClickDelete: () => void
    ): KnowbeTableCellParam[] => [
      {
        label: <div>{data.serviceCode}</div>,
        className: `${classes.firstCell} ${classes.wordWrap}`
      },
      {
        label: <div>{data.name}</div>,
        className: classes.wordWrap
      },
      {
        label: <div className={classes.price}>{data.unitPrice}</div>,
        className: classes.wordWrap
      },
      {
        // iconCell
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickDelete}
            />
          </FormGroup>
        )
      }
    ];

    const bodyValues = serviceCodesList.map((x) => {
      const onClickDelete = (): void => {
        props.onClickDelete(x.index);
      };
      return bodyData(x, onClickDelete);
    });

    return (
      <>
        <KnowbeTable className={classes.table}>
          <KnowbeTableHead
            uniqueKey="サービスコード"
            height={40}
            items={headData}
          />
          {serviceCodesList.length > 0 && (
            <KnowbeTableBody
              uniqueKey="サービスコード情報"
              itemsContainer={bodyValues}
            />
          )}
        </KnowbeTable>
        {serviceCodesList.length <= 0 && (
          <div className={props.classes.serviceCodeInfo}>
            対象サービスを表示してサービスコードを登録してください。
          </div>
        )}
      </>
    );
  }
);
