import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

const styles = (): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    title: {
      minHeight: 32,
      marginBottom: 1
    },
    buttons: {
      marginBottom: 8
    }
  });

type OwnProps = {
  label: string;
};
type Props = OwnProps & React.Props<{}> & WithStyles<typeof styles>;

/**
 * SectionTitle内にボタンが持てるタイプ(children経由・labelの文字数が多くてもボタンに被らないように修正)
 */
const SectionTitleWithButtonFlexCore = (props: Props): JSX.Element => (
  <>
    <div className={props.classes.root}>
      <Typography
        gutterBottom
        variant="h6"
        color="primary"
        className={props.classes.title}
      >
        {props.label}
      </Typography>
      <div className={props.classes.buttons}>{props.children}</div>
    </div>
    <Divider variant="middle" className={props.classes.divider} />
  </>
);

export const SectionTitleWithButtonFlex = withStyles(styles)(
  SectionTitleWithButtonFlexCore
);
