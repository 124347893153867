import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import { FieldArray, FormikProps } from "formik";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { BasicInfoScheduleItem } from "./BasicInfoScheduleItem";
import {
  BasicInfoFields,
  Schedule
} from "@interfaces/mgr/KEIKAKUSODAN/record/basicInfo";
import { basicInfoSchedulesValue } from "@initialize/mgr/KEIKAKUSODAN/record/basicInfo/validation";

const styles = (): StyleRules =>
  createStyles({
    buttonCon: {
      alignItems: "center",
      "&>span>span": {
        lineHeight: 1,
        minHeight: "auto",
        display: "block",
        marginLeft: "13px"
      }
    },
    noMessage: {
      fontSize: "12px",
      lineHeight: 1.3,
      letterSpacing: "0.4px",
      color: "rgba(0, 0, 0, 0.6)",
      padding: "16px"
    },
    tableHeader: {
      display: "flex",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      color: "#37474f",
      background: "#eceff1",
      padding: "14px 16px",
      marginTop: "8px"
    },
    tableBody: {
      borderBottom: "1px solid #e0e0e0"
    },
    bgGray: {
      background: "#f5f5f5"
    },
    week: {
      width: "236px"
    },
    start: {
      width: "72px",
      marginLeft: "16px"
    },
    end: {
      width: "calc(72px + 64px + 16px)",
      marginLeft: "16px"
    },
    content: {
      width: "450px",
      marginLeft: "16px"
    },
    delete: {
      width: "25px",
      marginLeft: "24px"
    },
    displayNone: {
      display: "none"
    },
    MT16: {
      marginTop: "16px"
    }
  });

type OwnProps = {
  isEditing: boolean;
  formikProps: FormikProps<BasicInfoFields>;
};
type Props = OwnProps & WithStyles<typeof styles>;

const BasicInfoScheduleCore = (props: Props): JSX.Element => {
  const { classes, isEditing, formikProps } = props;

  const noDataMessage = isEditing
    ? "生活の内容が登録されていません。「追加する」ボタンから登録してください。"
    : "生活の内容が登録されていません";

  const maxLength = 50;
  const [isMaxLength, setIsMaxLength] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsMaxLength(formikProps.values.schedule.length >= maxLength);
  }, [formikProps.values.schedule]);

  return (
    <div>
      <div className={classes.tableHeader}>
        <span className={classes.week}>曜日</span>
        <span className={classes.start}>開始時間</span>
        <span className={classes.end}>終了時間</span>
        <span className={classes.content}>内容</span>
        <span className={classes.delete}>{isEditing ? "削除" : ""}</span>
      </div>
      <FieldArray name="basic_info_schedule">
        {(): JSX.Element => {
          const onClickAddSchedules = (): void => {
            formikProps.setFieldValue(
              "schedule",
              formikProps.values.schedule.concat(basicInfoSchedulesValue)
            );
          };
          const onClickDeleteSchedules = (index: number): void => {
            const item = formikProps.values.schedule[index];
            if (item.basic_info_schedules_id === null) {
              formikProps.setFieldValue(
                "schedule",
                formikProps.values.schedule.filter((_, i) => i !== index)
              );
            } else {
              formikProps.setFieldValue(
                "schedule",
                formikProps.values.schedule.map((x, i) =>
                  i !== index
                    ? x
                    : {
                        ...x,
                        is_delete: true
                      }
                )
              );
            }
          };
          return (
            <div>
              <div className={classes.tableBody}>
                {formikProps.values.schedule.filter(
                  (x) => x.is_delete === false
                ).length > 0 ? (
                  formikProps.values.schedule.map((item: Schedule, index) => {
                    const nameNumber = index;
                    const unitKey = `basic_info_schedule_${nameNumber}`;
                    const onDelete = (): void => {
                      onClickDeleteSchedules(nameNumber);
                    };
                    return item.is_delete === false ? (
                      <div
                        className={ClassNames(
                          (nameNumber + 1) % 2 === 0 ? classes.bgGray : ""
                        )}
                        key={unitKey}
                      >
                        <BasicInfoScheduleItem
                          item={item}
                          isEditing={isEditing}
                          nameNumber={nameNumber}
                          formikProps={formikProps}
                          onDelete={onDelete}
                        />
                      </div>
                    ) : (
                      <div className={classes.displayNone} key={unitKey} />
                    );
                  })
                ) : (
                  <div className={classes.noMessage}>{noDataMessage}</div>
                )}
              </div>
              {isEditing && (
                <KnowbeButton
                  kind="iconText"
                  onClick={onClickAddSchedules}
                  disabled={isMaxLength}
                  className={ClassNames(classes.buttonCon, classes.MT16)}
                >
                  <AddIcon />
                  <span>追加する</span>
                </KnowbeButton>
              )}
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const BasicInfoSchedule = withStyles(styles)(BasicInfoScheduleCore);
