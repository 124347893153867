export const REPEAT_DAILY = "reportDaily";
export const REPEAT_MONTHLY = "reportMonthly";

/**
 * ActionNames
 */
export const FETCH_KODOENGO_DAILY_STARTED =
  "KODOENGO/REPORT_202104/FETCH_DAILY_STARTED";
export const FETCH_KODOENGO_DAILY = "KODOENGO/REPORT_202104/FETCH_DAILY";
export const FETCH_KODOENGO_DAILY_FAILED =
  "KODOENGO/REPORT_202104/FETCH_FAILED";
export const FETCH_KODOENGO_MONTHLY_STARTED =
  "KODOENGO/REPORT_202104/FETCH_MONTHLY_STARTED";
export const FETCH_KODOENGO_MONTHLY = "KODOENGO/REPORT_202104/FETCH_MONTHLY";
export const FETCH_KODOENGO_MONTHLY_FAILED =
  "KODOENGO/REPORT_202104/FETCH_MONTHLY_FAILED";
export const POST_KODOENGO_REPORT_STARTED =
  "KODOENGO/REPORT_202104/POST_STARTED";
export const POST_KODOENGO_REPORT_DAILY =
  "KODOENGO/REPORT_202104/POST_REPORT_DAILY";
export const POST_KODOENGO_REPORT_MONTHLY =
  "KODOENGO/REPORT_202104/POST_REPORT_MONTHLY";
export const POST_KODOENGO_REPORT_FAILED =
  "KODOENGO/REPORT_202104/POST_REPORT_FAILED";
export const DELETE_KODOENGO_REPORT_STARTED =
  "KODOENGO/REPORT_202104/DELETE_REPORT_STARTED";
export const DELETE_KODOENGO_REPORT = "KODOENGO/REPORT_202104/DELETE_REPORT";
export const DELETE_KODOENGO_REPORT_FAILED =
  "KODOENGO/REPORT_202104/DELETE_REPORT_FAILED";
export const POST_KODOENGO_REPORT_COPY_STARTED =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY_STARTED";
export const POST_KODOENGO_REPORT_COPY =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY";
export const POST_KODOENGO_REPORT_COPY_FAILED =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY_FAILED";
export const POST_KODOENGO_REPORT_COPY_CARE_PLAN_STARTED =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY_CARE_PLAN_STARTED";
export const POST_KODOENGO_REPORT_COPY_CARE_PLAN =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY_CARE_PLAN";
export const POST_KODOENGO_REPORT_COPY_CARE_PLAN_FAILED =
  "KODOENGO/REPORT_202104/POST_REPORT_COPY_CARE_PLAN_FAILED";

export type KODOENGOReportTypeInterface = {
  type: "reportDaily" | "reportMonthly";
};

export type InoutResultsDetailsState = {
  id: number | null;
  actionClass: number | null;
  inTime: string | null;
  outTime: string | null;
};

export type InoutResultsState = {
  id: number | null;
  inoutResultDailyId: number | null;
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  targetDate: string;
  inputClass: number;
  inoutResultsPlanId: number | null;
  numberOfParticipants: number | null;
  practitioner1InTime: string;
  practitioner1OutTime: string;
  practitioner1CalculatedHours: number | null;
  practitioner1Memo: string | null;
  inoutResultsDetails1: InoutResultsDetailsState[];
  practitioner2InTime: string;
  practitioner2OutTime: string;
  practitioner2Memo: string | null;
  timeDuplicationJudgmentFlg: boolean;
  wholeCalculatedHours: number | null;
  duplicateCalculatedHours: number | null;
  inoutResultsDetails2: InoutResultsDetailsState[];
  licenseSameFlg: boolean;
  secondPersonFlg: boolean;
  emergencySupportFlg: boolean;
  firstAdditionFlg: boolean;
  sputumImplementationFlg: boolean;
  behaviorDisorderCoachingFlg: boolean;
  bodyRestrictedStillFlg: boolean;
  resultExistFlg: boolean;
};

export type DisplayListState = {
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  isHoliday: boolean;
  targetDate: string;
  dataList: DataListState[];
};

export type DataListState = {
  no: number | null;
  resultExistFlg: boolean;
  plan: DisplayState;
  result: DisplayState;
  planDetail: InoutResultsState;
  resultDetail: InoutResultsState;
};

export type DisplayState = {
  inoutResultsId: number | null;
  dispatchNo: number | null;
  inTime: string | null;
  outTime: string | null;
  calculatedHours: number | null;
  numberOfParticipants: number | null;
  emergencySupportFlg?: number | null;
};

export type ReportSummary = {
  serviceHoursCount: number | null;
};

export type ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: string | null;
    };
    displayList: DisplayListState[];
    planCount: number;
  };
  reportMonthly: {
    summary: ReportSummary;
    displayList: DisplayListState[];
    planCount: number;
  };
};

export type DeleteReportState = {
  uifId: number;
  targetDate: string;
  inoutResultsId: number | null;
  inputClass: number;
};

export type CopyReportState = {
  uifId: number;
  targetDate: string;
  inoutResultsId?: number | null;
};

export type CopyReportErrorState = {
  response: {
    code: number;
    msg: string;
  };
};
