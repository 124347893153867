import { UsersInFacilityState } from "./types";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";
import { Checkbox } from "@constants/variables";
import { UsersValues } from "@initialize/mgr/IDOSHIEN/users/initialValues";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import booleanToString0or1 from "@utils/dataNormalizer/booleanToString0or1";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import castStringCheckboxValue from "@utils/dataNormalizer/castStringCheckboxValue";
import castStringOrUndefined from "@utils/dataNormalizer/castStringOrUndefined";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEmpty from "lodash-es/isEmpty";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityUserTargetIdResponse = UsersInFacilityState["user"];

/**
 * 特定のフラグがOFFの場合、APIに送信する項目を削除する
 * @param target
 */
const resultFormat = (target: UserInFacilityResult): UserInFacilityResult => {
  const result = {
    user_in_facility: { ...target.user_in_facility },
    user_in_facility_idoshien: {
      ...target.user_in_facility_idoshien
    }
  };
  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMap = {
  user_in_facility: {
    date_end_in_service: {
      key: "date_end_in_service",
      childKeys: ["date_begin_in_service"]
    },
    subsidized_flg: {
      key: "subsidized_flg",
      childKeys: [
        "subsidized_unit_flg",
        "subsidized_percent",
        "subsidized_yen",
        "subsidized_city_id"
      ]
    },
    subsidized_unit_flg: {
      key: "subsidized_unit_flg",
      childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_city_id"]
    },
    subsidized_percent: {
      key: "subsidized_percent",
      childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
    },
    subsidized_yen: {
      key: "subsidized_yen",
      childKeys: ["subsidized_unit_flg", "subsidized_city_id"]
    },
    subsidized_city_id: {
      key: "subsidized_city_id",
      childKeys: ["subsidized_percent", "subsidized_yen", "subsidized_unit_flg"]
    },
    uplimit_facility_flg: {
      key: "uplimit_facility_flg",
      childKeys: [
        "uplimit_controlled_by",
        "uplimit_facility_number",
        "uplimit_facility_number2",
        "uplimit_facility_number3",
        "uplimit_facility_number4",
        "uplimit_facility_name",
        "uplimit_facility_name2",
        "uplimit_facility_name3",
        "uplimit_facility_name4",
        "uplimit_total_yen",
        "uplimit_total_yen2",
        "uplimit_total_yen3",
        "uplimit_total_yen4",
        "uplimit_user_load_yen",
        "uplimit_user_load_yen2",
        "uplimit_user_load_yen3",
        "uplimit_user_load_yen4",
        "uplimit_user_load_after_adjustment_yen",
        "uplimit_user_load_after_adjustment_yen2",
        "uplimit_user_load_after_adjustment_yen3",
        "uplimit_user_load_after_adjustment_yen4",
        "uplimit_yen",
        "result_of_management"
      ]
    },
    uplimit_controlled_by: {
      key: "uplimit_controlled_by",
      childKeys: [
        "uplimit_facility_number",
        "uplimit_facility_number2",
        "uplimit_facility_number3",
        "uplimit_facility_number4",
        "uplimit_facility_name",
        "uplimit_facility_name2",
        "uplimit_facility_name3",
        "uplimit_facility_name4",
        "uplimit_total_yen",
        "uplimit_total_yen2",
        "uplimit_total_yen3",
        "uplimit_total_yen4",
        "uplimit_user_load_yen",
        "uplimit_user_load_yen2",
        "uplimit_user_load_yen3",
        "uplimit_user_load_yen4",
        "uplimit_user_load_after_adjustment_yen",
        "uplimit_user_load_after_adjustment_yen2",
        "uplimit_user_load_after_adjustment_yen3",
        "uplimit_user_load_after_adjustment_yen4",
        "uplimit_yen",
        "result_of_management"
      ]
    },
    result_of_management: {
      key: "result_of_management",
      childKeys: ["uplimit_yen"]
    },
    user_charge_limit_flg: {
      key: "user_charge_limit_flg",
      childKeys: ["date_begin_user_charge_limit", "date_end_user_charge_limit"]
    },
    date_begin_user_charge_limit: {
      key: "date_begin_user_charge_limit",
      childKeys: ["date_end_user_charge_limit"]
    },
    date_end_user_charge_limit: {
      key: "date_end_user_charge_limit",
      childKeys: ["date_begin_user_charge_limit"]
    },
    care_support_auth_flg: {
      key: "care_support_auth_flg",
      childKeys: ["date_end_care_support_auth", "date_begin_care_support_auth"]
    },
    date_begin_care_support_auth: {
      key: "date_begin_care_support_auth",
      childKeys: ["date_end_care_support_auth"]
    },
    date_end_care_support_auth: {
      key: "date_end_care_support_auth",
      childKeys: ["date_begin_care_support_auth"]
    },
    care_support_payment_flg: {
      key: "care_support_payment_flg",
      childKeys: [
        "date_end_care_support_payment",
        "date_begin_care_support_payment"
      ]
    },
    date_begin_care_support_payment: {
      key: "date_begin_care_support_payment",
      childKeys: ["date_end_care_support_payment"]
    },
    date_end_care_support_payment: {
      key: "date_end_care_support_payment",
      childKeys: ["date_begin_care_support_payment"]
    },
    plan_support_payment_flg: {
      key: "plan_support_payment_flg",
      childKeys: [
        "date_end_plan_support_payment",
        "date_begin_plan_support_payment"
      ]
    },
    date_begin_plan_support_payment: {
      key: "date_begin_plan_support_payment",
      childKeys: ["date_end_plan_support_payment"]
    },
    date_end_plan_support_payment: {
      key: "date_end_plan_support_payment",
      childKeys: ["date_begin_plan_support_payment"]
    },
    plan_support_monitor_flg: {
      key: "plan_support_monitor_flg",
      childKeys: [
        "date_end_plan_support_monitor",
        "date_begin_plan_support_monitor"
      ]
    },
    date_begin_plan_support_monitor: {
      key: "date_begin_plan_support_monitor",
      childKeys: ["date_end_plan_support_monitor"]
    },
    date_end_plan_support_monitor: {
      key: "date_end_plan_support_monitor",
      childKeys: ["date_begin_plan_support_monitor"]
    }
  },
  user_in_facility_idoshien: {}
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param target
 * @param after
 */
const addParentValue = (
  target: UserInFacilityResult,
  after: UserInFacilityResult
): UserInFacilityResult => {
  const result = target;
  Object.keys(parentParamsMap).forEach((uifKey) => {
    Object.keys(parentParamsMap[uifKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[uifKey][paramKey].childKeys
        ? parentParamsMap[uifKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return result[uifKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        result[uifKey][parentParamsMap[uifKey][paramKey].key] =
          after[uifKey][parentParamsMap[uifKey][paramKey].key];
      }
    });
  });
  return result;
};

const CheckboxValueToNumber0or1 = (value?: Checkbox): number | undefined => {
  return value === Checkbox.OFF ? 0 : 1;
};

/**
 * 差分比較のために
 * APIのパラメータをフォームの形式に整形
 */
export const normalizeApiValue = (
  params: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const apiValue: UserInFacilityResult = {
    user_in_facility: {
      ...params.user_in_facility,
      none_recipient_number_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.none_recipient_number_flg
      ),
      care_support_payment_flg: CheckboxValueToNumber0or1(
        params.user_in_facility.care_support_payment_flg
      )
    },
    user_in_facility_idoshien: params.user_in_facility_idoshien
      ? {
          ...params.user_in_facility_idoshien
        }
      : {}
  };
  return apiValue;
};

/**
 * apiのparamが画面に表示する際にデフォルト値に変換されてしまっているため
 * 差分比較のために変換前に戻すメソッド
 */
const apiParamFormat = (
  apiParamValue: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const result: UserInFacilityResult = normalizeApiValue(apiParamValue);
  return result;
};

/**
 * 差分抽出のメソッド
 */
const removeNoChangeData = (
  apiValues: UsersInFacilityState["user"],
  formValues: UserInFacilityResult
): UserInFacilityResult => {
  const target = {
    user_in_facility: {
      id: formValues.user_in_facility.id,
      facility_id: formValues.user_in_facility.facility_id,
      name_sei: undefined,
      name_mei: undefined,
      name_sei_kana: undefined,
      name_mei_kana: undefined,
      recipient_number: undefined,
      none_recipient_number_flg: undefined,
      gender: undefined,
      classify_physical_flg: undefined,
      classify_intelligence_flg: undefined,
      classify_mind_flg: undefined,
      classify_growth_flg: undefined,
      classify_brain_flg: undefined,
      classify_incurable_flg: undefined,
      classify_handicapped_flg: undefined,
      date_birth: formValues.user_in_facility.date_birth,
      postal_code: undefined,
      prefecture_name: undefined,
      city_id: undefined,
      address: undefined,
      tel: undefined,
      email: undefined,
      guardian_name_sei: undefined,
      guardian_name_mei: undefined,
      guardian_name_sei_kana: undefined,
      guardian_name_mei_kana: undefined,
      guardian_relation: undefined,
      guardian_tel: undefined,
      memo: undefined,

      /* サービス詳細 */
      date_begin_in_service: formValues.user_in_facility.date_begin_in_service,
      date_end_in_service: undefined,
      date_pay_begin: formValues.user_in_facility.date_pay_begin,
      date_pay_end: formValues.user_in_facility.date_pay_end,
      care_support_payment_flg: undefined
    },
    user_in_facility_idoshien: {
      users_in_facility_id: undefined,
      municipality_id: undefined,
      disability_class: undefined,
      pay_times_individual_support: undefined,
      pay_months_individual_support: undefined,
      pay_times_group_support: undefined,
      pay_months_group_support: undefined,
      pay_times_vehicle_transfer_support: undefined,
      pay_months_vehicle_transfer_support: undefined,
      pay_times_school_and_hospital_commuting_support: undefined,
      pay_months_school_and_hospital_commuting_support: undefined,
      pay_times_other_support: undefined,
      pay_months_other_support: undefined,
      user_charge_rate: undefined,
      monthly_user_charge_limit: undefined
    }
  };

  const apiValue = apiParamFormat(apiValues);
  const form = formValues.user_in_facility;
  // サービス提供終了日のみAPIの型と合わないため
  form.date_end_in_service =
    form.date_end_in_service === "" ? null : form.date_end_in_service;
  // 差分の項目を抽出 必須項目は除く
  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${apiValue[key][param]}` !== `${formValues[key][param]}`) {
          target[key][param] = formValues[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });
  const diffDate = addParentValue(target, formValues);

  return diffDate;
};

/**
 * パラメータ内のundefindはパラメータから除外する
 * @param data
 */
const removeNullParam = (data: UserInFacilityResult): UserInFacilityResult => {
  const targetParm = {
    user_in_facility: {},
    user_in_facility_idoshien: {}
  };
  // 引数のデータからuser_in_facilityとuser_in_facility_idoshienを取得
  Object.keys(data).forEach((key) => {
    // user_in_facilityとuser_in_facility_idoshienからparamを取得
    Object.keys(data[key]).forEach((param) => {
      if (data[key][param] !== undefined) {
        targetParm[key][param] = data[key][param];
      }
    });
  });

  return targetParm;
};

export const normalizedGetFacilityUserTargetIdResponse = (
  result: GetFacilityUserTargetIdResponse["data"]
): NormalizedGetFacilityUserTargetIdResponse => {
  const userInFacility = result.user_in_facility;
  const userInFacilityIDOSHIEN = result.user_in_facility_idoshien;
  const normalized = {
    user_in_facility: {
      ...userInFacility,
      none_recipient_number_flg: castNumberCheckboxValue(
        userInFacility.none_recipient_number_flg
      ),
      classify_physical_flg: castStringCheckboxValue(
        userInFacility.classify_physical_flg
      ),
      classify_intelligence_flg: castStringCheckboxValue(
        userInFacility.classify_intelligence_flg
      ),
      classify_mind_flg: castStringCheckboxValue(
        userInFacility.classify_mind_flg
      ),
      classify_growth_flg: castStringCheckboxValue(
        userInFacility.classify_growth_flg
      ),
      classify_brain_flg: castStringCheckboxValue(
        userInFacility.classify_brain_flg
      ),
      classify_incurable_flg: castStringCheckboxValue(
        userInFacility.classify_incurable_flg
      ),
      classify_handicapped_flg: castStringCheckboxValue(
        userInFacility.classify_handicapped_flg
      ),
      subsidized_flg: castStringCheckboxValue(userInFacility.subsidized_flg),
      subsidized_percent: castStringOrUndefined(
        userInFacility.subsidized_percent
      ),
      subsidized_yen: castStringOrUndefined(userInFacility.subsidized_yen),
      uplimit_controlled_by: userInFacility.uplimit_controlled_by || "1",
      uplimit_facility_flg: castStringCheckboxValue(
        userInFacility.uplimit_facility_flg
      ),
      uplimit_total_yen: castStringOrUndefined(
        userInFacility.uplimit_total_yen
      ),
      uplimit_total_yen2: castStringOrUndefined(
        userInFacility.uplimit_total_yen2
      ),
      uplimit_total_yen3: castStringOrUndefined(
        userInFacility.uplimit_total_yen3
      ),
      uplimit_total_yen4: castStringOrUndefined(
        userInFacility.uplimit_total_yen4
      ),
      uplimit_user_load_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen
      ),
      uplimit_user_load_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen2
      ),
      uplimit_user_load_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen3
      ),
      uplimit_user_load_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimit_user_load_after_adjustment_yen: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimit_user_load_after_adjustment_yen2: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimit_user_load_after_adjustment_yen3: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimit_user_load_after_adjustment_yen4: castStringOrUndefined(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      result_of_management: userInFacility.result_of_management || "1",
      uplimit_yen: castStringOrUndefined(userInFacility.uplimit_yen),
      user_charge_limit_flg: castNumberCheckboxValue(
        userInFacility.user_charge_limit_flg
      ),
      care_support_payment_flg: castNumberCheckboxValue(
        userInFacility.care_support_payment_flg
      ),
      care_support_auth_flg: castNumberCheckboxValue(
        userInFacility.care_support_auth_flg
      ),
      plan_support_payment_flg: castNumberCheckboxValue(
        userInFacility.plan_support_payment_flg
      ),
      plan_support_monitor_flg: castNumberCheckboxValue(
        userInFacility.plan_support_monitor_flg
      )
    },
    user_in_facility_idoshien: userInFacilityIDOSHIEN
      ? {
          ...userInFacilityIDOSHIEN
        }
      : {}
  };
  return normalized;
};

/**
 * フォームの結果をAPIに送れる形式に整形
 */
export const normalizeFormValue = (
  values: UsersValues,
  params?: UsersInFacilityState["user"]
): UserInFacilityResult => {
  const target: UserInFacilityResult = {
    user_in_facility: {
      id: params ? params.user_in_facility.id : undefined,
      facility_id: params ? params.user_in_facility.facility_id : undefined,

      /* 基本情報 */
      name_sei: values.basic.nameSei,
      name_mei: values.basic.nameMei,
      name_sei_kana: values.basic.nameSeiKana,
      name_mei_kana: values.basic.nameMeiKana,
      recipient_number: isEmpty(values.basic.recipientNumber)
        ? null
        : values.basic.recipientNumber,
      none_recipient_number_flg: booleanToNumber0or1(
        values.basic.noneRecipientNumberFlag
      ),
      gender: values.basic.gender,
      classify_physical_flg: booleanToString0or1(
        values.basic.classifyPhysicalFlag
      ),
      classify_intelligence_flg: booleanToString0or1(
        values.basic.classifyIntelligenceFlag
      ),
      classify_mind_flg: booleanToString0or1(values.basic.classifyMindFlag),
      classify_growth_flg: booleanToString0or1(values.basic.classifyGrowthFlag),
      classify_brain_flg: booleanToString0or1(values.basic.classifyBrainFlag),
      classify_incurable_flg: booleanToString0or1(
        values.basic.classifyIncurableFlag
      ),
      classify_handicapped_flg: booleanToString0or1(
        values.basic.classifyHandicappedFlag
      ),
      date_birth: selectDateValueToDatePaddingZero(values.basic.dateOfBirth),
      postal_code: values.basic.postalCode,
      prefecture_name: values.basic.prefectureId,
      city_id: stringToNumber(values.basic.cityId),
      address: values.basic.restAddress,
      tel: isEmpty(values.basic.tel) ? null : values.basic.tel,
      email: isEmpty(values.basic.email) ? null : values.basic.email,
      guardian_name_sei: isEmpty(values.basic.guardianNameSei)
        ? null
        : values.basic.guardianNameSei,
      guardian_name_mei: isEmpty(values.basic.guardianNameMei)
        ? null
        : values.basic.guardianNameMei,
      guardian_name_sei_kana: isEmpty(values.basic.guardianNameSeiKana)
        ? null
        : values.basic.guardianNameSeiKana,
      guardian_name_mei_kana: isEmpty(values.basic.guardianNameMeiKana)
        ? null
        : values.basic.guardianNameMeiKana,
      guardian_relation: isEmpty(values.basic.guardianRelation)
        ? null
        : values.basic.guardianRelation,
      guardian_tel: isEmpty(values.basic.guardianTel)
        ? null
        : values.basic.guardianTel,
      memo: isEmpty(values.basic.memo) ? null : values.basic.memo,

      /* サービス詳細 */

      date_begin_in_service: selectDateValueToDatePaddingZero(
        values.serviceUse.inServiceStartDate
      ),
      date_end_in_service: selectDateValueToDatePaddingZero(
        values.serviceUse.inServiceEndDate
      ),
      date_pay_begin: selectDateValueToDatePaddingZero(
        values.serviceUse.payStartDate
      ),
      date_pay_end: selectDateValueToDatePaddingZero(
        values.serviceUse.payEndDate
      )
    },
    user_in_facility_idoshien: {
      users_in_facility_id:
        params && params.user_in_facility_idoshien
          ? params.user_in_facility_idoshien.users_in_facility_id
          : undefined,
      municipality_id: stringToNumber(values.basic.municipalityId),
      disability_class: values.basic.classifyHandicappedFlag
        ? 0
        : stringToNumber(values.serviceUse.disabilityClass),
      pay_times_individual_support:
        values.serviceUse.payTimesIndividualSupport !== ""
          ? stringToNumber(values.serviceUse.payTimesIndividualSupport)
          : null,

      pay_months_individual_support:
        values.serviceUse.payMonthsIndividualSupport !== ""
          ? stringToNumber(values.serviceUse.payMonthsIndividualSupport)
          : null,
      pay_times_group_support:
        values.serviceUse.payTimesGroupSupport !== ""
          ? stringToNumber(values.serviceUse.payTimesGroupSupport)
          : null,
      pay_months_group_support:
        values.serviceUse.payMonthsGroupSupport !== ""
          ? stringToNumber(values.serviceUse.payMonthsGroupSupport)
          : null,
      pay_times_vehicle_transfer_support:
        values.serviceUse.payTimesVehicleTransferSupport !== ""
          ? stringToNumber(values.serviceUse.payTimesVehicleTransferSupport)
          : null,
      pay_months_vehicle_transfer_support:
        values.serviceUse.payMonthsVehicleTransferSupport !== ""
          ? stringToNumber(values.serviceUse.payMonthsVehicleTransferSupport)
          : null,
      pay_times_school_and_hospital_commuting_support:
        values.serviceUse.payTimesSchoolAndHospitalCommutingSupport !== ""
          ? stringToNumber(
              values.serviceUse.payTimesSchoolAndHospitalCommutingSupport
            )
          : null,
      pay_months_school_and_hospital_commuting_support:
        values.serviceUse.payMonthsSchoolAndHospitalCommutingSupport !== ""
          ? stringToNumber(
              values.serviceUse.payMonthsSchoolAndHospitalCommutingSupport
            )
          : null,
      pay_times_other_support:
        values.serviceUse.payTimesOtherSupport !== ""
          ? stringToNumber(values.serviceUse.payTimesOtherSupport)
          : null,
      pay_months_other_support:
        values.serviceUse.payMonthsOtherSupport !== ""
          ? stringToNumber(values.serviceUse.payMonthsOtherSupport)
          : null,
      user_charge_rate:
        values.serviceUse.userChargeRate !== ""
          ? stringToNumber(values.serviceUse.userChargeRate)
          : null,
      monthly_user_charge_limit:
        values.serviceUse.monthlyUserChargeLimit !== ""
          ? stringToNumber(values.serviceUse.monthlyUserChargeLimit)
          : null
    }
  };
  const normalized: UserInFacilityResult = resultFormat(target);
  let requestData = normalized;
  // 更新の場合は差分抽出のメソッドへ
  if (params) {
    const targetParm = removeNoChangeData(params, normalized);
    requestData = removeNullParam(targetParm);
  }
  return requestData;
};

/**
 * /mgr/facility/users/{id} の結果
 */
export type UserInFacilityResult = {
  user_in_facility: Partial<{
    id: number;
    facility_id: number;
    name_sei: string;
    name_mei: string;
    name_sei_kana: string;
    name_mei_kana: string;
    recipient_number: string | null;
    none_recipient_number_flg: number;
    gender: string;
    classify_physical_flg: string;
    classify_intelligence_flg: string;
    classify_mind_flg: string;
    classify_growth_flg: string;
    classify_brain_flg: string;
    classify_incurable_flg: string;
    classify_handicapped_flg: string;
    date_birth: string | null;
    postal_code: string;
    prefecture_name: string;
    city_id: number;
    address: string;
    tel: string | null;
    email: string | null;
    guardian_name_sei: string | null;
    guardian_name_mei: string | null;
    guardian_name_sei_kana: string | null;
    guardian_name_mei_kana: string | null;
    guardian_relation: string | null;
    guardian_tel: string | null;
    memo: string | null;

    /* サービス詳細 */
    date_begin_in_service: string | null;
    date_end_in_service: string | null;
    date_pay_begin: string | null;
    date_pay_end: string | null;
    care_support_payment_flg: number | null;
  }>;
  user_in_facility_idoshien: Partial<{
    users_in_facility_id: number;
    municipality_id: number;
    disability_class: number;
    pay_times_individual_support: number | null;
    pay_months_individual_support: number | null;
    pay_times_group_support: number | null;
    pay_months_group_support: number | null;
    pay_times_vehicle_transfer_support: number | null;
    pay_months_vehicle_transfer_support: number | null;
    pay_times_school_and_hospital_commuting_support: number | null;
    pay_months_school_and_hospital_commuting_support: number | null;
    pay_times_other_support: number | null;
    pay_months_other_support: number | null;
    user_charge_rate: number | null;
    monthly_user_charge_limit: number | null;
  }>;
};
