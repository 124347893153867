import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import knowbemgr_logoPng from "@images/logo_fix_knowbe_logo-wh.png";

const styles = (): StyleRules =>
  createStyles({
    knowbeMgrLogo: {
      width: 85,
      height: 36
    }
  });

type Props = WithStyles<typeof styles>;

const KnowbeMgrIcon = (props: Props): JSX.Element => (
  <img
    src={knowbemgr_logoPng}
    alt="https://knowbe.jp/"
    className={props.classes.knowbeMgrLogo}
  />
);

export default withStyles(styles)(KnowbeMgrIcon);
