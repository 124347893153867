import { normalizeGetFacilityResult as normalizeGetFacilityResultSEIKATSUKAIGO } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultJIRITSUKUNRENSEIKATSU } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultIAB } from "@stores/domain/mgr/IAB/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultSHUROTEICHAKU } from "@stores/domain/mgr/SHUROTEICHAKU/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultGroupHome } from "@stores/domain/mgr/GroupHome/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultSHISETSUNYUSHO } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/normalizer";
import { normalizeGetFacilityResult as normalizeGetFacilityResultTANKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/facility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseSEIKATSUKAIGO,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseSEIKATSUKAIGO
} from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseJIRITSUKUNRENSEIKATSU,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseJIRITSUKUNRENSEIKATSU
} from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseIAB,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseIAB
} from "@stores/domain/mgr/IAB/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseSHUROTEICHAKU,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseSHUROTEICHAKU
} from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseGroupHome,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseGroupHome
} from "@stores/domain/mgr/GroupHome/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseSHISETSUNYUSHO,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseSHISETSUNYUSHO
} from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/normalizer";
import {
  normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseTANKINYUSHO,
  NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseTANKINYUSHO
} from "@stores/domain/mgr/TANKINYUSHO/userInFacility/normalizer";

import { nomalizeSEIKATSUKAIGODailySummaryDataFromAPI } from "@stores/domain/mgr/SEIKATSUKAIGO/report/normalizer";
import { nomalizeJIRITSUKUNRENSEIKATSUDailySummaryDataFromAPI } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/report/normalizer";
import { normalizeIABDailySummaryDataFromAPI } from "@stores/domain/mgr/IAB/report/normalizer";
import { SEIKATSUKAIGOSummary } from "@stores/domain/mgr/SEIKATSUKAIGO/report/types";
import { InOutReportState } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/report/types";
import { IABSummary } from "@stores/domain/mgr/IAB/report/types";
import { GetInOutDailySummaryResponse } from "@api/requests/inOut/getInOutSummary";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { FacilityState as FacilityStateIAB } from "@stores/domain/mgr/IAB/facility/types";
import { FacilityState as FacilityStateSEIKATSUKAIGO } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/types";
import { FacilityState as FacilityStateSHUROTEICHAKU } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { FacilityState as FacilityStateJIRITSUKUNRENSEIKATSU } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { FacilityState as FacilityStateGroupHome } from "@stores/domain/mgr/GroupHome/facility/types";
import { FacilityState as FacilityStateSHISETSUNYUSHO } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import { FacilityState as FacilityStateTANKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/facility/types";

import { FacilityType } from "@constants/variables";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";

/**
 * Get Facilityのnormalizeを渡す
 * @param facilityType
 */
export const getNormalizeGetFacilityResult = (
  facilityType: FacilityType
): ((
  response: GetFacilityResponse
) =>
  | FacilityStateSEIKATSUKAIGO
  | FacilityStateJIRITSUKUNRENSEIKATSU
  | FacilityStateIAB
  | FacilityStateSHUROTEICHAKU
  | FacilityStateGroupHome
  | FacilityStateTANKINYUSHO
  | FacilityStateSHISETSUNYUSHO) => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return normalizeGetFacilityResultSEIKATSUKAIGO;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return normalizeGetFacilityResultJIRITSUKUNRENSEIKATSU;
    case FacilityType.SHUROTEICHAKU:
      return normalizeGetFacilityResultSHUROTEICHAKU;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return normalizeGetFacilityResultIAB;
    case FacilityType.GROUP_HOME:
      return normalizeGetFacilityResultGroupHome;
    case FacilityType.TANKINYUSHO:
      return normalizeGetFacilityResultTANKINYUSHO;
    case FacilityType.SHISETSUNYUSHO:
      return normalizeGetFacilityResultSHISETSUNYUSHO;
    default:
  }
  return normalizeGetFacilityResultSEIKATSUKAIGO;
};

export const getNormalizedGetFacilityUserTargetIdResponse = (
  facilityType: FacilityType
): ((
  result: GetFacilityUserTargetIdResponse["data"]
) =>
  | NormalizedGetFacilityUserTargetIdResponseSEIKATSUKAIGO
  | NormalizedGetFacilityUserTargetIdResponseIAB
  | NormalizedGetFacilityUserTargetIdResponseSHUROTEICHAKU
  | NormalizedGetFacilityUserTargetIdResponseJIRITSUKUNRENSEIKATSU
  | NormalizedGetFacilityUserTargetIdResponseGroupHome
  | NormalizedGetFacilityUserTargetIdResponseTANKINYUSHO
  | NormalizedGetFacilityUserTargetIdResponseSHISETSUNYUSHO) => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return normalizedGetFacilityUserTargetIdResponseSEIKATSUKAIGO;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return normalizedGetFacilityUserTargetIdResponseJIRITSUKUNRENSEIKATSU;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return normalizedGetFacilityUserTargetIdResponseIAB;
    case FacilityType.SHUROTEICHAKU:
      return normalizedGetFacilityUserTargetIdResponseSHUROTEICHAKU;
    case FacilityType.GROUP_HOME:
      return normalizedGetFacilityUserTargetIdResponseGroupHome;
    case FacilityType.TANKINYUSHO:
      return normalizedGetFacilityUserTargetIdResponseTANKINYUSHO;
    case FacilityType.SHISETSUNYUSHO:
      return normalizedGetFacilityUserTargetIdResponseSHISETSUNYUSHO;
    default:
  }
  return normalizedGetFacilityUserTargetIdResponseSEIKATSUKAIGO;
};

/**
 * Get DailySummaryDataのnormalizeを渡す
 * @param facilityType
 */
export const getNormalizeGetDailySummaryData = (
  facilityType: FacilityType
): ((
  result: GetInOutDailySummaryResponse
) => SEIKATSUKAIGOSummary | IABSummary | InOutReportState["summary"]) => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return nomalizeSEIKATSUKAIGODailySummaryDataFromAPI;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return nomalizeJIRITSUKUNRENSEIKATSUDailySummaryDataFromAPI;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return normalizeIABDailySummaryDataFromAPI;
    default:
  }
  return nomalizeSEIKATSUKAIGODailySummaryDataFromAPI;
};
