import * as React from "react";
import { FormikProps } from "formik";
import {
  createStyles,
  withStyles,
  Theme,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InOutReportDialogFields, {
  isRelatedToWorkHours
} from "@components/v202104/organisms/mgr/IAB/report/dialog/InOutReportDialogFields";
import InOutReportDialogFieldsWorkRecord from "@/components/v202104/organisms/mgr/IAB/report/dialog/InOutReportDialogFieldsWorkRecord";
import { InitialDataValues } from "@initialize/v202104/mgr/IAB/report/initialValues";
import { UserState } from "@stores/domain/user/type";
import { FacilityState } from "@stores/v202104/domain/mgr/IAB/facility/types";
import checkTime from "@validator/rules/checkTime";
import roundingMinutes from "@utils/dataNormalizer/roundingMinutes";
import { getUserCorrectWorkRecordTimes } from "@utils/domain/facility/getUserCorrectWorkRecordTimes";
import makeTotalBreakTimeMinutes from "@utils/domain/report/makeTotalBreakTimeMinutes";
import { INT_TRUE_FROM_API } from "@constants/variables";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/IAB/userInFacility/types";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({ paper: { margin: spacing.unit * 2 } });

type OwnProps = {
  selectedDate: Date;
  formikProps: FormikProps<InitialDataValues>;
  user: UserState;
  facility: FacilityState;
  usersInFacilityState: UsersInFacilityState;
};
type Props = OwnProps & WithStyles<typeof styles>;

// サービス提供の状況（initial.status）に応じてフィールドの編集許可の管理を行う、その初期状態
const initialFieldsStatus = {
  isTrialUsageKindDisplay: false, // 体験利用フィールドの表示
  isTimeInputRequired: true, // 開始時間のrequired属性
  isTimeInputDisabled: true, // 開始時間のdisable属性
  isDidGetFoodDisabled: true, // 食事提供のdisable属性
  isMedicalCooperationDisabled: true, // 医療連携体制のdisable属性
  isSputumGuidanceDisabled: true, // 喀痰吸引等に係る指導実施
  isTravelTimeDisabled: true, // 送迎のdisable属性
  isPickupPremisesDisabled: true, // 同一敷地内送迎のdisable属性
  isOtherDisabled: true, // その他（在宅時生活支援・社会生活支援・通勤訓練）のdisable属性
  isWorkRecordDisplay: false, // 作業時間関連のフィールドの表示
  isCaseMeetingFlgDisabled: true // 支援計画会議実施加算のdisabled属性
};
type FieldsStatus = typeof initialFieldsStatus;

type DefaultStatusSetting =
  | undefined
  | {
      id: number;
      status: number;
      start_time: string | null;
      end_time: string | null;
      def_food: string;
      def_pickup: string;
      pickup_premises: number;
      time_card_display_flg: number;
    };

/**
 * 分割したフィールド全体の状態管理及びブリッジを担当する
 */
const InOutReportDialogContent = (props: Props): JSX.Element => {
  const [fieldsStatus, setFieldsStatus] = React.useState(initialFieldsStatus);
  const [workRecord, setWorkRecord] = React.useState(() =>
    getUserCorrectWorkRecordTimes(
      props.usersInFacilityState.user,
      props.facility,
      props.selectedDate
    )
  );
  const { initialValues, values } = props.formikProps;

  const { group_labor_charge } = props.user.featureGroup;
  const { startTime, endTime } = props.formikProps.values.workRecord;

  const default_value = React.useRef<DefaultStatusSetting>(undefined);

  // 利用者単位で作業時間周りのデータがあれば、それを優先
  const isUserWorkTime =
    props.usersInFacilityState.user.work_details &&
    "work_time_use_flg" in props.usersInFacilityState.user.work_details &&
    props.usersInFacilityState.user.work_details.work_time_use_flg;

  const unitEngrave =
    isUserWorkTime &&
    props.usersInFacilityState.user.work_details &&
    "work_truncate_minutes" in props.usersInFacilityState.user.work_details &&
    props.usersInFacilityState.user.work_details.work_truncate_minutes
      ? props.usersInFacilityState.user.work_details.work_truncate_minutes
      : props.facility.unitEngrave;

  /**
   * In,OutTimeの変更を受け取る
   * @param autoCorrectValue 変更した値（開始or終了時間）
   * @param fieldName 変更した値のフィールド名（initial.inTime,initial.outTime）
   * @return 更新後の作業時間（未更新の値は空文字）
   */
  const onChangeInOutTime = React.useCallback(
    (
      autoCorrectValue: string,
      fieldName: string
    ): { startTime: string; endTime: string; breakTime: string } => {
      const workRecordValues = {
        startTime: "",
        endTime: "",
        breakTime: ""
      };
      if (group_labor_charge !== 1 || !!checkTime(autoCorrectValue)) {
        return { startTime: "", endTime: "", breakTime: "" };
      }
      const { setFieldValue } = props.formikProps;

      // 作業への自動反映処理、一旦ここで処理する
      const isBusinessDay = !!(workRecord.start_time && workRecord.end_time);
      let updateValue = autoCorrectValue;
      let currentStartTime =
        startTime ||
        (default_value.current && default_value.current.start_time) ||
        "";
      let currentEndTime = endTime;
      const format = fieldName === "initial.inTime" ? "start" : "end";
      if (isBusinessDay) {
        updateValue = roundingMinutes(autoCorrectValue, unitEngrave, format);
      }
      const preCorrectValue = updateValue.replace(/:/, "");
      if (fieldName === "initial.inTime") {
        if (isBusinessDay) {
          // 設定した基準開始時刻より前なら基準時刻で上書きする
          const st = workRecord.start_time.replace(/:/, "");
          if (preCorrectValue < st) {
            updateValue = workRecord.start_time;
          }
        }
        currentStartTime = updateValue;
        setFieldValue("workRecord.startTime", updateValue);
        workRecordValues.startTime = updateValue;
      } else {
        if (isBusinessDay) {
          // 設定した基準終了時刻より後なら基準時刻で上書きする
          const et = workRecord.end_time.replace(/:/, "");
          if (preCorrectValue > et) {
            updateValue = workRecord.end_time;
          }
        }
        currentEndTime = updateValue;
        setFieldValue("workRecord.endTime", updateValue);
        workRecordValues.endTime = updateValue;
      }

      if (isBusinessDay) {
        // 時刻に含まれる休憩時間を算出してセット
        const minutes = makeTotalBreakTimeMinutes(
          currentStartTime,
          currentEndTime,
          workRecord.break_times,
          unitEngrave.toString()
        );
        setFieldValue("workRecord.breakTime", minutes);
        workRecordValues.breakTime = minutes.toString();
      }
      return workRecordValues;
    },
    [group_labor_charge, startTime, endTime, unitEngrave, workRecord]
  );

  /**
   * disabledになったフィールドを初期設定（新規登録時の状態）に戻す
   * 活性化を継続しているものは維持すること
   * initial.memoはdisabled状態がないのとやや特殊なので子で修正している
   * TODO: 現状だとマスター参照をしないと初期設定には戻せないので、時間以外は開いた時の状態に戻している
   */
  const resetDisabledFields = React.useCallback(
    (next: FieldsStatus, status, prev_status) => {
      const prev = fieldsStatus;
      const initial = {} as InitialDataValues["initial"];
      const workRecordUpdate = {} as InitialDataValues["workRecord"];
      initial.status = values.initial.status;

      if (prev.isTrialUsageKindDisplay && !next.isTrialUsageKindDisplay) {
        initial.trialUsageKind = initialValues.initial.trialUsageKind;
        initial.lifeSupportHubInDistrictFlg =
          initialValues.initial.lifeSupportHubInDistrictFlg;
      }
      if (!prev.isTimeInputDisabled && next.isTimeInputDisabled) {
        initial.inTime = "";
        initial.outTime = "";
      }
      if (!prev.isDidGetFoodDisabled && next.isDidGetFoodDisabled) {
        initial.didGetFood = initialValues.initial.didGetFood;
      }
      if (
        !prev.isMedicalCooperationDisabled &&
        next.isMedicalCooperationDisabled
      ) {
        initial.medicalCooperation = initialValues.initial.medicalCooperation;
      }
      if (!prev.isSputumGuidanceDisabled && next.isSputumGuidanceDisabled) {
        initial.sputumGuidanceFlg = initialValues.initial.sputumGuidanceFlg;
      }
      if (!prev.isTravelTimeDisabled && next.isTravelTimeDisabled) {
        initial.travelTime = initialValues.initial.travelTime;
      }
      if (!prev.isPickupPremisesDisabled && next.isPickupPremisesDisabled) {
        initial.pickupPremises =
          values.initial.travelTime === "0"
            ? "0"
            : initialValues.initial.pickupPremises;
      }
      if (!prev.isOtherDisabled && next.isOtherDisabled) {
        initial.helpInhouseLifeFlg = initialValues.initial.helpInhouseLifeFlg;
        initial.helpSocialLifeFlg = initialValues.initial.helpSocialLifeFlg;
        initial.trainCommuteFlg = initialValues.initial.trainCommuteFlg;
        initial.regionalCooperationFlg =
          initialValues.initial.regionalCooperationFlg;
        initial.peerSupportFlg = initialValues.initial.peerSupportFlg;
      }
      if (!prev.isWorkRecordDisplay && next.isWorkRecordDisplay) {
        // workRecord.workedはInOutReportDialogFieldsのchangeState内で変更する。それ以外はここで変更。
        workRecordUpdate.startTime = initialValues.workRecord.startTime;
        workRecordUpdate.endTime = initialValues.workRecord.endTime;
        workRecordUpdate.breakTime = initialValues.workRecord.breakTime;
        workRecordUpdate.memo = initialValues.workRecord.memo;
      }
      if (!prev.isCaseMeetingFlgDisabled && next.isCaseMeetingFlgDisabled) {
        initial.caseMeetingFlg = initialValues.initial.caseMeetingFlg;
      }

      // デフォルトステータス
      if (props.usersInFacilityState.user.default_status_settings) {
        if (
          !isRelatedToWorkHours(prev_status) &&
          isRelatedToWorkHours(status) &&
          default_value.current &&
          // 通所で保存後に表示 → prev_statusは初期表示時に""なので、上記trueでデフォルトステータスが入ってしまう
          prev_status !== ""
        ) {
          initial.inTime = convertHHMMSSToHHMM(
            default_value.current.start_time || ""
          );
          initial.outTime = convertHHMMSSToHHMM(
            default_value.current.end_time || ""
          );
          initial.travelTime = default_value.current.def_pickup;
          initial.pickupPremises = default_value.current.pickup_premises.toString();
          initial.didGetFood = default_value.current.def_food;
          // 作業時間の更新
          if (default_value.current.start_time) {
            const { startTime: startTimeUpdate } = onChangeInOutTime(
              convertHHMMSSToHHMM(default_value.current.start_time),
              "initial.inTime"
            ) || { startTime: "" };
            workRecordUpdate.startTime = startTimeUpdate;
          }
          if (default_value.current.end_time) {
            const {
              endTime: endTimeUpdate,
              breakTime: breakTimeUpdate
            } = onChangeInOutTime(
              convertHHMMSSToHHMM(default_value.current.end_time),
              "initial.outTime"
            ) || { endTime: "", breakTime: "" };
            workRecordUpdate.endTime = endTimeUpdate;
            workRecordUpdate.breakTime = breakTimeUpdate;
          }
        }
      }

      // コストが高いので更新は余計な実行を避ける
      if (
        Object.keys(initial).length > 1 ||
        Object.keys(workRecordUpdate).length > 0
      ) {
        props.formikProps.setValues({
          initial: { ...values.initial, ...initial },
          workRecord: { ...values.workRecord, ...workRecordUpdate }
        });
      }
    },
    [fieldsStatus, initialValues, values]
  );

  const { status, travelTime } = props.formikProps.values.initial;

  const prev_status = React.useRef("");

  /**
   * 現在のinitial.statusに応じたfieldsStatusを決定する
   */
  React.useEffect(() => {
    // 未指定（1）の状態をセットして各種差分を後から指定する
    const nextFieldsStatus = { ...initialFieldsStatus };

    // 通所・施設外就労
    if (status === "2" || status === "3") {
      nextFieldsStatus.isTimeInputDisabled = false;
      nextFieldsStatus.isDidGetFoodDisabled = false;
      nextFieldsStatus.isMedicalCooperationDisabled = false;
      nextFieldsStatus.isTravelTimeDisabled = false;
      nextFieldsStatus.isPickupPremisesDisabled = travelTime === "0";
      nextFieldsStatus.isOtherDisabled = false;
      nextFieldsStatus.isWorkRecordDisplay = true;
      nextFieldsStatus.isSputumGuidanceDisabled = false;
      nextFieldsStatus.isCaseMeetingFlgDisabled = false;
    }
    // 施設外支援・移行準備支援Ⅰ・旧 移行準備支援Ⅱ
    else if (status === "4" || status === "8" || status === "9") {
      nextFieldsStatus.isTimeInputRequired = false;
      nextFieldsStatus.isTimeInputDisabled = false;
      nextFieldsStatus.isDidGetFoodDisabled = false;
      nextFieldsStatus.isMedicalCooperationDisabled = false;
      nextFieldsStatus.isTravelTimeDisabled = false;
      nextFieldsStatus.isPickupPremisesDisabled = travelTime === "0";
      nextFieldsStatus.isOtherDisabled = false;
      nextFieldsStatus.isWorkRecordDisplay = true;
      nextFieldsStatus.isSputumGuidanceDisabled = false;
      nextFieldsStatus.isCaseMeetingFlgDisabled = false;
    }
    // 欠席時対応・欠席（加算なし）
    else if (status === "5" || status === "10") {
      nextFieldsStatus.isTimeInputRequired = false;
      nextFieldsStatus.isCaseMeetingFlgDisabled = false;
    }
    // 訪問
    else if (status === "6") {
      nextFieldsStatus.isTimeInputDisabled = false;
      nextFieldsStatus.isCaseMeetingFlgDisabled = false;
    }
    // 体験利用
    else if (status === "7") {
      nextFieldsStatus.isTrialUsageKindDisplay = true;
      nextFieldsStatus.isTimeInputRequired = false;
      nextFieldsStatus.isCaseMeetingFlgDisabled = false;
    }
    default_value.current = (
      props.usersInFacilityState.user.default_status_settings || []
    ).find((value) => value.status.toString() === status);
    resetDisabledFields(nextFieldsStatus, status, prev_status.current);
    setFieldsStatus(nextFieldsStatus);
    prev_status.current = status;
  }, [status, travelTime]);

  /**
   * 基準となる作業時間を取得する
   */
  React.useEffect(() => {
    const res = getUserCorrectWorkRecordTimes(
      props.usersInFacilityState.user,
      props.facility,
      props.selectedDate
    );
    setWorkRecord(res);
  }, [props.selectedDate, props.facility]);

  return (
    <Paper
      classes={{ root: props.classes.paper }}
      component="section"
      elevation={0}
    >
      <InOutReportDialogFields
        fieldsStatus={fieldsStatus}
        formikPropsValues={props.formikProps}
        setFormikFieldValue={props.formikProps.setFieldValue}
        serviceType={props.facility.serviceType}
        isPickupAvailable={props.facility.transferServiceFlag}
        isFoodAvailable={props.facility.mealSaservedServiceFlag}
        rewardType={props.facility.rewardType}
        isCaseMeetingFlg={
          props.usersInFacilityState.user.user_in_facility
            ? props.usersInFacilityState.user.user_in_facility
                .case_meeting_flg === INT_TRUE_FROM_API
            : false
        }
        onChangeInOutTime={onChangeInOutTime}
        usersInFacilityState={props.usersInFacilityState}
      />
      {group_labor_charge === 1 && (
        <InOutReportDialogFieldsWorkRecord
          formikProps={props.formikProps}
          isWorkRecordDisplay={fieldsStatus.isWorkRecordDisplay}
          isTimeInputRequired={fieldsStatus.isTimeInputRequired}
          facility={props.facility}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(InOutReportDialogContent);
