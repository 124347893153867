import * as React from "react";

// mui
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
// components
import { DateSelectYearMonth } from "@components/molecules/DateSelectYearMonth";
// constants
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
// interfaces
import { SelectMonthValue } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    searchSection: {
      marginTop: "16px",
      paddingBottom: "16px"
    }
  });

type OwnProps = {
  handleChangeMonthSelect: (value: SelectMonthValue) => void;
  isReset?: boolean;
  setIsReset?: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const FieldMonitoringCore = (props: Props): JSX.Element | null => {
  const { classes, handleChangeMonthSelect, isReset, setIsReset } = props;
  return (
    <div className={classes.searchSection}>
      <DateSelectYearMonth
        label="モニタリング実施年月"
        overrideYearFrom={1989}
        addYearTo={1}
        onChangeSelect={handleChangeMonthSelect}
        defaultDate={{ year: DEFAULT_SELECT_VALUE, month: "" }}
        isReset={isReset}
        setIsReset={setIsReset}
      />
    </div>
  );
};

export const FieldMonitoring = withStyles(styles)(FieldMonitoringCore);
