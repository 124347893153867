import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type postCustomRecordsVisibilityParams = {
  custom_record_items?: {
    id: number;
    visibility?: number;
    custom_records_keikakusodan_visibility?: {
      id: number;
      plan_visibility?: number;
      child_visibility?: number;
    };
  }[];
  custom_records_category?: {
    id: number;
    visibility?: number;
    custom_records_keikakusodan_visibility?: {
      id: number;
      plan_visibility?: number;
      child_visibility?: number;
    };
  }[];
};

/**
 * カスタム項目の表示/非表示を切り替える
 */
export const postCustomRecordsVisibility = async (
  params: postCustomRecordsVisibilityParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_records/visibility`;
  return request.post(url, params);
};

export default postCustomRecordsVisibility;
