import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikTextField from "@components/molecules/FormikTextField";
import { FacilityValues } from "@initialize/mgr/CHIIKIIKO/facility/initialValues";
import { FormikProps } from "formik";

type Props = {
  formikProps: FormikProps<FacilityValues>;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "additionalItem.lifeSupportHubInDistrictFlg":
          props.formikProps.setFieldValue(
            "additionalItem.lifeSupportFunctionPromotionFlg",
            false
          );
          props.formikProps.setFieldValue(
            "additionalItem.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        case "additionalItem.lifeSupportFunctionPromotionFlg":
          props.formikProps.setFieldValue(
            "additionalItem.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        default:
      }
    }
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.peerSupportFlg"
          label="ピアサポート体制"
        />
        <FormikSwitch
          name="additionalItem.lifeSupportHubInDistrictFlg"
          label="地域生活支援拠点等"
          onChange={onChangeSwitch}
          style={{ marginBottom: 0 }}
        >
          <FormikSwitch
            name="additionalItem.lifeSupportFunctionPromotionFlg"
            label="地域生活支援拠点等機能強化加算"
            onChange={onChangeSwitch}
          >
            <FormikTextField
              name="additionalItem.lifeSupportFunctionPromotionTimes"
              label="算定回数"
              placeholder="1"
              endAdornmentLabel="回"
            />
          </FormikSwitch>
        </FormikSwitch>
      </FormGroup>
    </FormPaper>
  );
};
