import * as React from "react";
import {
  withStyles,
  createStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/report/initial";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrap: {
      padding: "8px 28px 4px 0"
    },
    cancel: {
      marginRight: spacing.unit
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  onCancel: () => void;
  disabled: boolean;
};

type Props = StateProps & WithStyles<typeof styles>;

const UsagePerformanceReportDialogSubmitCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.wrap}>
      <KnowbeButton
        className={props.classes.cancel}
        kind="outline"
        onClick={props.onCancel}
        minWidth={125}
      >
        キャンセル
      </KnowbeButton>
      <FormikSubmitButton
        buttonName="保存する"
        formikProps={props.formikPropsValues}
        disabled={props.disabled}
        minWidth={125}
      />
    </div>
  );
};

export const UsagePerformanceReportDialogSubmit = withStyles(styles)(
  UsagePerformanceReportDialogSubmitCore
);
