/**
 * ActionNames
 */

export const FETCH_STARTED = "SHISETSUNYUSHO/FACILITY_202104/FETCH_STARTED";
export const FETCH_SUCCESS = "SHISETSUNYUSHO/FACILITY_202104/FETCH_SUCCESS";
export const FETCH_FAILED = "SHISETSUNYUSHO/FACILITY_202104/FETCH_FAILED";
export const POST_STARTED = "SHISETSUNYUSHO/FACILITY_202104/POST_STARTED";
export const POST_SUCCESS = "SHISETSUNYUSHO/FACILITY_202104/POST_SUCCESS";
export const POST_FAILED = "SHISETSUNYUSHO/FACILITY_202104/POST_FAILED";

/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  capacity: string;
  multiFunctionOfficeFlg: boolean;
  masterSubordinateFlg: boolean;
  isMasterRadioValue: string;
  allCapacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
  availableFood: boolean;
  foodExpenses: number;
  foodExpensesBreakfast: number;
  foodExpensesLunch: number;
  foodExpensesSupper: number;
  foodExpensesDay: number;
  utility: number;
  utilityCosts: number;
  operatingUnitFlag: boolean;

  // SubtractionItemSection
  originLocalGovFlg: boolean;
  nutritionistPlacement: number;
  lackOfSupporterFlg: boolean;
  dateStartLackOfSupporter: string;

  // AdditionItemSection
  nighttimePlacement: number;
  seeHearTeamFlg: boolean;
  regionalLifeTransition: boolean;
  staffTreatmentSystemType: string;
  staffTreatmentSpecificSystemType: string;
  seriousDisability: number;
  betterSupporterConditionBaseUpFlg: boolean;
  oralHygieneManagementSystemFlg: number;

  // units
  units: {
    id: number | null;
    unit_name: string;
    night_support_type: string;
    ave_users_last_fiscal_year: string;
    is_deleted: boolean;
  }[];
};
