import React from "react";
import * as H from "history";
import classNames from "classnames";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { FacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/types";
import Button from "@material-ui/core/Button";
import { Formik, Form, FormikActions } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import deepEqual from "fast-deep-equal";

// components
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
// import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { SupportLedgerListHeader } from "@components/organisms/mgr/CHIIKITEICHAKU/record/SupportLedgerListHeader";
import { SupportLedgerSheetForm } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerSheetForm";
import { SupportLedgerCreateAndUpdate } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerCreateAndUpdate";
import { SupportLedgerSupportProgress } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerSupportProgress";
import { SupportLedgerFacility } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerFacility";
// import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
// import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";

// utils
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { StaffState } from "@stores/domain/staff/types";
import * as supportLedgerActions from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/actions";
import {
  SupportLedger,
  initialValues
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { validation } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/validation";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { toEffectiveObject } from "@utils/object";
import { getStateValueFromDefaultItem } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { CityState, CityParams } from "@stores/domain/city/type";

import * as URL from "@constants/url";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM
} from "@constants/variables";

import { CHIIKITEICHAKU_PLAN_LIST_TAB_PATH } from "@constants/mgr/CHIIKITEICHAKU/variables";

import { FieldItem } from "@interfaces/ui/form";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
// import { convertCompilerOptionsFromJson } from "typescript";
// import { required } from "@validator/rules";

const styles = (): StyleRules =>
  createStyles({
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    formArea: {
      background: "#fff",
      padding: "32px",
      position: "relative",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px"
    },
    author: {
      display: "flex",
      padding: "16px 32px 32px 32px"
    },
    authorName: {
      width: 240
    },
    selectField: {
      marginTop: "32px",
      width: 245,
      "&:not(:last-child)": {
        marginBottom: "48px"
      }
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    },
    deleteButton: {
      marginTop: 24,
      height: 0
    }
  });
type UsersInFacilityStateType = UsersInFacilityState["user"];

type OwnProps = {
  user: UsersInFacilityStateType;
  userName: string;
  uifId: string;
  supportLedgerId: string;
  copyId: string;
  history: H.History;
  isNew: boolean;
  isView: boolean;
  isCopy: boolean;
  isEditing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  facilityType: FacilityType;
  facility: FacilityState;
};

type StateProps = {
  needsStopHistory: boolean;
  cityList: CityState[];
  customRecords: CustomRecordsWithCategoryState;
  supportLedger: SupportLedgerState["supportLedger"];
  staff: StaffState;
};

type DispatchProps = {
  fetchStaffs: () => Promise<void>;
  fetchCity: (params: CityParams) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchSupportLedger: (uifId: string, supportLedgerId: string) => Promise<void>;
  postSupportLedger: (
    uifId: string,
    initialSupportLedger: SupportLedger,
    supportLedger: SupportLedger,
    history: H.History,
    staffOptions: FieldItem[],
    isNew?: boolean,
    isCopy?: boolean
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  unsetSupportLedger: () => void;
  deleteSupportLedger: (uifId: string, supportLedger: string) => Promise<void>;
  stopHistory: (flag: boolean) => Promise<void>;
};

type MergeProps = {
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
} & StateProps &
  DispatchProps;

type Props = OwnProps & MergeProps & WithStyles<typeof styles>;

/**
 * 地域定着支援台帳
 *
 */
const SupportLedgerSheetCore = (props: Props): JSX.Element | null => {
  const {
    user,
    cityList,
    userName,
    uifId,
    supportLedgerId,
    copyId,
    history,
    isNew,
    isView,
    isCopy,
    isEditing,
    setEditing,
    customRecords,
    supportLedger,
    staffOptions,
    staffOptionsAddSnapShot,
    fetchStaffs,
    fetchCustomRecords,
    fetchSupportLedger,
    postSupportLedger,
    unsetSupportLedger,
    deleteSupportLedger,
    classes,
    facilityType,
    facility
  } = props;

  const [isLoading, setLoading] = React.useState(true); // 初回fetch終わったらfalse
  const getInitialValues = (): SupportLedger => {
    return initialValues({
      user,
      facility,
      cityList,
      supportLedgerState: supportLedger,
      customRecords,
      staffOptions: props.staffOptions,
      isNew,
      isCopy
    });
  };

  const prefectureName = user.user_in_facility.prefecture_name
    ? user.user_in_facility.prefecture_name
    : "";

  const [formValues, setFormValues] = React.useState(getInitialValues());
  const [
    isOpenDeleteSupportLedgerModal,
    setOpenDeleteSupportLedgerModal
  ] = React.useState(false);

  // fetch終了後
  React.useEffect(() => {
    if (prefectureName) {
      props.fetchCity({ prefectureName }).then(() => {
        setFormValues(getInitialValues());
      });
    } else {
      setFormValues(getInitialValues());
    }
  }, [prefectureName]);

  //  初回fetch
  React.useEffect(() => {
    unsetSupportLedger();

    if (prefectureName) {
      props.fetchCity({ prefectureName }).then(() => {
        setFormValues(getInitialValues());
      });
    } else {
      setFormValues(getInitialValues());
    }

    const fetchFirstData = async (): Promise<void> => {
      if (isView || isCopy) {
        await fetchSupportLedger(uifId, supportLedgerId || copyId);
      }
      await fetchStaffs();
      await fetchCustomRecords();
    };
    fetchFirstData().then(() => {
      setLoading(false);
    });
  }, []);

  // fetch終了後
  React.useEffect(() => {
    setFormValues(getInitialValues());
  }, [supportLedger, customRecords]);

  const onSubmit = async (
    values: SupportLedger,
    actions: FormikActions<SupportLedger>
  ): Promise<void> => {
    actions.setSubmitting(true);
    const isInitial = true;
    let initial: SupportLedger;
    if (isCopy) {
      initial = initialValues({
        user,
        facility,
        cityList
      });
    } else {
      initial = initialValues({
        user,
        facility,
        cityList,
        supportLedgerState: supportLedger,
        staffOptions,
        customRecords,
        isNew,
        isCopy,
        isInitial
      });
    }
    await postSupportLedger(
      uifId,
      initial,
      values,
      history,
      staffOptionsAddSnapShot,
      isNew,
      isCopy
    );
    if (isView) {
      await setFormValues(values);
      await fetchSupportLedger(uifId, supportLedgerId);
      setEditing(false);
    }
    actions.setSubmitting(false);
  };

  const onClickPrint = (): void => {
    const url = `/record/support_plan/${uifId}/support_ledger/${supportLedgerId}/print`;
    history.push(url);
  };

  const onClickEdit = (): void => {
    setEditing(true);
  };

  const onClickDeleteSupportLedger = (): void => {
    setOpenDeleteSupportLedgerModal(true);
  };

  const onSubmitDeleteSupportLedger = async (): Promise<void> => {
    await deleteSupportLedger(uifId, supportLedgerId);
    props.stopHistory(false);
    props.showSnackbar({
      open: true,
      message: "削除が完了しました",
      variant: "success"
    });
    history.push(`/record/support_plan/${uifId}/support_ledger`);
  };

  const onClickCancelDeleteSupportLedger = (): void => {
    setOpenDeleteSupportLedgerModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const confirmDiscardFormChanges = (nextValues: SupportLedger): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  // バリデーション
  const validate = (values: SupportLedger): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const onClickCancel = async (): Promise<void> => {
              await props.stopHistory(false).then(() => {
                if (isNew || isCopy) {
                  history.push(
                    `${URL.RECORD_SUPPORT_PLAN}/${uifId}/support_ledger`
                  );
                }
                if (isView && isEditing) {
                  formikProps.resetForm();
                  setEditing(false);
                }
              });
            };
            return (
              <Form>
                <SupportLedgerListHeader
                  pageName="地域定着支援台帳"
                  userName={userName}
                  uifId={uifId}
                  recordType={CHIIKITEICHAKU_PLAN_LIST_TAB_PATH.SUPPORT_LEDGER}
                  isEditing={isEditing}
                  history={props.history}
                  button={
                    <div className={classes.titleBtn}>
                      {isEditing ? (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="outline"
                              onClick={onClickCancel}
                            >
                              キャンセル
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <FormikSubmitButton
                              key="submit-button"
                              buttonName="保存する"
                              formikProps={formikProps}
                              errorAction={submitError}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickPrint}>
                              印刷
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickEdit}>
                              編集
                            </KnowbeButton>
                          </div>
                        </>
                      )}
                    </div>
                  }
                />
                <div className={classNames(classes.formArea, "-first")}>
                  <SupportLedgerCreateAndUpdate
                    isCopy={isCopy}
                    isNew={isNew}
                    isEditing={isEditing}
                    supportLedgerFieldValues={formValues}
                    supportLedgerData={supportLedger}
                    staffs={staffOptions}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.user_condition
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.family
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.related_organizations
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <SupportLedgerSheetForm
                    categoryType={
                      CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.notices
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                </div>

                <SupportLedgerSupportProgress
                  isEditing={isEditing}
                  formValues={isEditing ? formikProps.values : formValues}
                  uifId={uifId}
                  formikProps={formikProps}
                />

                <SupportLedgerFacility
                  categoryType={
                    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.office_information
                  }
                  formValues={isEditing ? formikProps.values : formValues}
                  isEditing={isEditing}
                  customRecords={customRecords}
                  supportLedgerState={supportLedger}
                  staffs={staffOptionsAddSnapShot}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {isView && isEditing && (
          <DeleteButton
            text="地域定着支援台帳を削除する"
            onClick={onClickDeleteSupportLedger}
          />
        )}
      </div>
      <MessageDialog
        isOpen={isOpenDeleteSupportLedgerModal}
        title="地域定着支援台帳を削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDeleteSupportLedger}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteSubmitButton}
            onClick={onSubmitDeleteSupportLedger}
          >
            削除する
          </Button>
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  customRecords: state.customRecordsWithCategory.assesment,
  supportLedger: state.CHIIKITEICHAKU.supportLedger.supportLedger,
  staff: state.staff,
  cityList: state.city
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    staffDispatcher,
    cityDispatch,
    CHIIKITEICHAKU,
    customRecordsWithCategory,
    uiDispatch
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const cityDispatches = cityDispatch(dispatch);
  const supportLedgerDispatches = CHIIKITEICHAKU.supportLedgerDispatcher(
    dispatch
  );

  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.support_ledger
      );
    },
    fetchSupportLedger: (
      uifId: string,
      supportLedgerId: string
    ): Promise<void> => {
      return supportLedgerDispatches.fetchSupportLedger(uifId, supportLedgerId);
    },
    postSupportLedger: (
      uifId: string,
      initialSupportLedger: SupportLedger,
      supportLedger: SupportLedger,
      history: H.History,
      staffOptions: FieldItem[],
      isNew?: boolean,
      isCopy?: boolean
    ): Promise<void> => {
      return supportLedgerDispatches.postSupportLedger(
        uifId,
        initialSupportLedger,
        supportLedger,
        history,
        staffOptions,
        isNew,
        isCopy
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    unsetSupportLedger: (): void => {
      dispatch(supportLedgerActions.unsetSupportLedger());
    },
    deleteSupportLedger: (
      uifId: string,
      supportLedgerId: string
    ): Promise<void> => {
      return supportLedgerDispatches.deleteSupportLedger(
        uifId,
        supportLedgerId
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, customRecords, supportLedger } = stateProps;

  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  const getStaffOptionsAddSnapShot = (): FieldItem[] => {
    if (staffOptions.length === 0) return [];
    const categoryItems = customRecords.find(
      (category) =>
        category.default_category ===
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.office_information
    );
    // ここの条件に入ることは基本的にはない
    if (!categoryItems) return staffOptions;
    const staffData = getStateValueFromDefaultItem(
      supportLedger,
      categoryItems.custom_record_items,
      CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdStaff
    );
    // 新規など、スタッフ情報が設定されていない場合
    if (
      !staffData ||
      !staffData.choiced_staff_id ||
      !staffData.choiced_staff_name_snapshot
    ) {
      return staffOptions;
    }

    let staffOptionsAddSnapShot = staffOptions;
    // 作成者
    if (staffData.choiced_staff_id !== undefined && staffData.checked !== 0) {
      staffOptionsAddSnapShot = createSnapshotOptions(staffOptions, {
        id: staffData.choiced_staff_id,
        snapshot_name: staffData.choiced_staff_name_snapshot
      });
    }
    return staffOptionsAddSnapShot;
  };

  return {
    staffOptions,
    staffOptionsAddSnapShot: getStaffOptionsAddSnapShot(),
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportLedgerSheet = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SupportLedgerSheetCore)
);
