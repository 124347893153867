/**
 * Date型で未来かどうか判定する
 * @param value
 */
export const futureTime = (
  startTime: Date,
  endTime: Date,
  options = { startLabel: "開始時間", endLabel: "終了時間" }
): string | undefined => {
  let errorMessage;

  if (
    !Number.isNaN(startTime.getTime()) &&
    !Number.isNaN(endTime.getTime()) &&
    endTime.getTime() <= startTime.getTime()
  ) {
    errorMessage = `${options.endLabel}は${options.startLabel}より後の時間を入力してください`;
  }

  return errorMessage;
};
