import * as React from "react";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import OfficeField from "@components/organisms/mgr/common/facility/items/OfficeField";
import ServiceTypeField from "@components/organisms/mgr/common/facility/items/ServiceTypeField";
import { FacilityValues } from "@initialize/mgr/IDOSHIEN/facility/initialValues";

type Props = {
  isFetchDone: boolean;
  serviceType: string;
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

export const BasicFields = (props: Props): JSX.Element => {
  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="基本情報" />
      </div>
      <FormikTextField
        name="basic.corporationName"
        label="法人名"
        placeholder="株式会社ノウビー"
        maxLength={255}
        size="superLong"
      />
      <OfficeField />
      <FormGroup row>
        <ServiceTypeField serviceType={props.serviceType} />
      </FormGroup>
      <FormikTextField
        name="basic.representativeName"
        label="代表職員氏名"
        placeholder="山田太郎"
        maxLength={20}
        size="superLong"
      />
      <FormikPostalCode
        name="basic.postalCode"
        label="郵便番号"
        placeholder="000-0000"
        maxLength={8}
        startAdornmentLabel="〒"
      />
      <FormikAddress
        prefectureIdName="basic.prefectureId"
        cityIdName="basic.cityId"
        formikProps={props.formikProps}
        showRegionType={false}
        // 地域区分(grade)は移動支援では最新に連動していなくても問題は起きないが他種別と揃えるために最新にしている
        showRegionType202404
      />
      <FormikTextField
        name="basic.restAddress"
        label="市区町村以降の住所"
        size="superLong"
      />
      <FormikTextField
        name="basic.tel"
        type="tel"
        label="電話番号"
        maxLength={12}
        placeholder="0000000000"
        helperText="ハイフンなしで入力"
      />
    </FormPaper>
  );
};
