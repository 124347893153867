import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikTime from "@components/molecules/FormikTime";
import {
  TRAIL_USAGE_KIND,
  SUPPLY_EXTENDED_SERVICE_LIST_202404,
  SUPPLY_FOOD_SERVICE_LIST,
  SUPPLY_PICKUP_SERVICE_LIST,
  SEIKATSUKAIGO_IN_OUT_RECORDS_STATUS,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST0,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST1,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST2,
  SUPPLY_PICKUP_PREMISES_SERVICE_LIST3,
  DEFAULT_SELECT_DATE_VALUE
} from "@constants/variables";
import InOutReportFormPaper from "@components/organisms/mgr/SEIKATSUKAIGO/report/dialog/InOutReportFormPaper";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps, getIn } from "formik";
import { InitialValues } from "@interfaces/mgr/SEIKATSUKAIGO/report/initial";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import convertHHMMToMinutes from "@utils/date/convertHHMMToMinutes";
import { SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT } from "@constants/mgr/SEIKATSUKAIGO/variables";
import { UsersInFacilityState } from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/types";

const styles = (): StyleRules =>
  createStyles({
    disabled: {
      color: "rgba(0, 0, 0, 0.38)"
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  isPickupAvailable: boolean;
  isFoodAvailable: boolean;
  usersInFacilityState: UsersInFacilityState;
  isSevereFailureSupport: boolean;
  selectedDate: Date;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  isTrialUsageKindDisplay: boolean;
  isTimeInputDisabled: boolean;
  isExtendedDisabled: boolean;
  isDidGetFoodDisablead: boolean;
  isTravelTimeDisabled: boolean;
  isPickupPremisesDisabled: boolean;
  pickupPremisesList: FieldItem[];
  pickupValue: string;
  pickupPremisesValue: string;
  isSevereDisabilitySupportDisabled: boolean;
  isNutritionScreeningFlgDisabled: boolean;
  isSputumImplementationFlgDisabled: boolean;
  isBathSupportFlgDisabled: boolean;
  isEmergencyAcceptanceAdditionFlgDisabled: boolean;
  isIntensiveSupportFlgDisabled: boolean;
  isNutritionImprovementServiceFlgDisabled: boolean;
};

type Props = StateProps & WithStyles<typeof styles>;

class InOutReportDialogFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { formikPropsValues } = this.props;
    this.state = {
      isTrialUsageKindDisplay: false,
      isTimeInputDisabled: true,
      isExtendedDisabled: true,
      isDidGetFoodDisablead: true,
      isTravelTimeDisabled: true,
      isPickupPremisesDisabled: true,
      pickupValue: formikPropsValues.values.initial.travelTime,
      pickupPremisesValue: formikPropsValues.values.initial.pickupPremises,
      pickupPremisesList: SUPPLY_PICKUP_PREMISES_SERVICE_LIST0,
      isSevereDisabilitySupportDisabled: true,
      isNutritionScreeningFlgDisabled: true,
      isEmergencyAcceptanceAdditionFlgDisabled: true,
      isSputumImplementationFlgDisabled: true,
      isBathSupportFlgDisabled: formikPropsValues.values.initial.bathSupportFlg,
      isIntensiveSupportFlgDisabled: true,
      isNutritionImprovementServiceFlgDisabled:
        formikPropsValues.values.initial.nutritionImprovementServiceFlg
    };
  }

  public componentDidMount(): void {
    this.changeState(this.props.formikPropsValues.values.initial.status);
    this.changePickupPremisesState(
      this.props.formikPropsValues.values.initial.travelTime
    );
    if (
      this.props.formikPropsValues.values.initial.status === "2" &&
      this.props.formikPropsValues.values.initial.travelTime !== "0"
    ) {
      this.setState({ isPickupPremisesDisabled: false });
    } else {
      this.setState({ isPickupPremisesDisabled: true });
    }
  }

  /**
   * 集中的支援加算：ONOFF切り替え時に値をリセット
   */
  private onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    this.props.setFormikFieldValue(event.target.name, checked);
    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "initial.intensiveSupportFlg":
          this.props.formikPropsValues.setFieldValue(
            "initial.intensiveSupportStartDate",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };

  /**
   * 集中的支援加算：非活性時の値のリセット
   */
  private resetIntensiveSupport = (): void => {
    this.props.setFormikFieldValue("initial.intensiveSupportFlg", false);
    this.props.formikPropsValues.setFieldValue(
      "initial.intensiveSupportStartDate",
      DEFAULT_SELECT_DATE_VALUE
    );
  };

  /**
   * 重度障害支援加算：非活性時の値のリセット
   */
  private resetSevereDisabilitySupportType = (): void => {
    this.props.setFormikFieldValue(
      "initial.severeDisabilitySupportType",
      SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT[0].value
    );
  };

  private handleChangeStatusHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.props.setFormikFieldValue("initial.trialUsageKind", "1");
    this.props.setFormikFieldValue(
      "initial.lifeSupportHubInDistrictFlg",
      false
    );
    this.props.setFormikFieldValue("initial.extended_202404", "0");
    // サービス提供の状況が通所の時のデフォルト設定
    if (e.target.value === "2") {
      this.changePickupPremisesState(
        this.props.formikPropsValues.values.initial.travelTime
      );
      this.props.setFormikFieldValue("initial.severeDisabilitySupport", true);
    } else {
      this.setState({ isPickupPremisesDisabled: true });
    }
    this.changeState(e.target.value);
  };

  // リアルタイムにバリデーションを行いたい為、onChangeごとにFormikの更新を行う
  private handleChangeInitialTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string
  ): string | void => {
    this.onChangeInOutTime(event, autoCorrectValue);
    this.props.setFormikFieldValue(event.target.name, autoCorrectValue);
  };

  private onChangeInOutTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    autoCorrectValue: string
  ): void => {
    const target = event.target.name;
    const allowTarget = ["initial.inTime", "initial.outTime"];
    if (!allowTarget.includes(target)) {
      // inTime, outTime以外では実行しない
      return;
    }

    const inTimeValue =
      target === "initial.inTime"
        ? autoCorrectValue
        : getIn(this.props.formikPropsValues.values, "initial.inTime");
    const outTimeValue =
      target === "initial.outTime"
        ? autoCorrectValue
        : getIn(this.props.formikPropsValues.values, "initial.outTime");

    const inTime = convertHHMMToMinutes(inTimeValue);
    const outTime = convertHHMMToMinutes(outTimeValue);

    if (typeof inTime === "string" || typeof outTime === "string") {
      return;
    }

    const calculatedHours = this.getCalculatedHoursWithSettingDayOfWeek();
    if (calculatedHours !== null) {
      this.props.setFormikFieldValue(
        "initial.calculatedHours",
        calculatedHours
      );
    }
  };

  // 利用者情報で標準算定時間が設定されている曜日の場合のみ標準算定時間を出力する
  // 設定されていない曜日の場合は空欄
  private getCalculatedHoursWithSettingDayOfWeek = (): number | null => {
    const { targetDate } = this.props.formikPropsValues.values.initial;
    const dayOfWeek = new Date(targetDate).getDay();
    const usersInFacilitySeikai = this.props.usersInFacilityState.user
      .user_in_facility_seikatsukaigo;
    if (usersInFacilitySeikai === null || usersInFacilitySeikai === undefined) {
      return null;
    }

    const {
      sun_calculated_hours,
      mon_calculated_hours,
      tue_calculated_hours,
      wed_calculated_hours,
      thu_calculated_hours,
      fri_calculated_hours,
      sat_calculated_hours
    } = usersInFacilitySeikai;

    let calculatedHours = null;
    if (
      dayOfWeek === 0 &&
      sun_calculated_hours !== null &&
      sun_calculated_hours !== undefined
    ) {
      calculatedHours = sun_calculated_hours;
    } else if (
      dayOfWeek === 1 &&
      mon_calculated_hours !== null &&
      mon_calculated_hours !== undefined
    ) {
      calculatedHours = mon_calculated_hours;
    } else if (
      dayOfWeek === 2 &&
      tue_calculated_hours !== null &&
      tue_calculated_hours !== undefined
    ) {
      calculatedHours = tue_calculated_hours;
    } else if (
      dayOfWeek === 3 &&
      wed_calculated_hours !== null &&
      wed_calculated_hours !== undefined
    ) {
      calculatedHours = wed_calculated_hours;
    } else if (
      dayOfWeek === 4 &&
      thu_calculated_hours !== null &&
      thu_calculated_hours !== undefined
    ) {
      calculatedHours = thu_calculated_hours;
    } else if (
      dayOfWeek === 5 &&
      fri_calculated_hours !== null &&
      fri_calculated_hours !== undefined
    ) {
      calculatedHours = fri_calculated_hours;
    } else if (
      dayOfWeek === 6 &&
      sat_calculated_hours !== null &&
      sat_calculated_hours !== undefined
    ) {
      calculatedHours = sat_calculated_hours;
    }

    return calculatedHours;
  };

  // リアルタイムにバリデーションを行いたい為、onChangeごとにFormikの更新を行う
  private onChangeText = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    this.props.setFormikFieldValue(event.target.name, event.target.value);
  };

  private handleChangePickupHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.props.setFormikFieldValue(
      "initial.pickupPremises",
      e.target.value === this.state.pickupValue
        ? this.state.pickupPremisesValue
        : "0"
    );

    this.changePickupPremisesState(e.target.value);
  };

  private changeState = (status: string): void => {
    // 一度表示の初期化
    this.setState({
      isTimeInputDisabled: true,
      isExtendedDisabled: true,
      isDidGetFoodDisablead: true,
      isTravelTimeDisabled: true,
      isTrialUsageKindDisplay: false,
      isSevereDisabilitySupportDisabled: true,
      isNutritionScreeningFlgDisabled: true,
      isEmergencyAcceptanceAdditionFlgDisabled: true,
      isSputumImplementationFlgDisabled: true,
      isBathSupportFlgDisabled: true,
      isIntensiveSupportFlgDisabled: true,
      isNutritionImprovementServiceFlgDisabled: true
    });

    // -の場合
    if (status === "1") {
      this.resetIntensiveSupport();
    }
    // 通所の場合
    if (status === "2") {
      this.setState({
        isTimeInputDisabled: false,
        isExtendedDisabled: false,
        isDidGetFoodDisablead: false,
        isTravelTimeDisabled: false,
        isSevereDisabilitySupportDisabled: false,
        isNutritionScreeningFlgDisabled: false,
        isEmergencyAcceptanceAdditionFlgDisabled: false,
        isSputumImplementationFlgDisabled: false,
        isBathSupportFlgDisabled: false,
        isIntensiveSupportFlgDisabled: false,
        isNutritionImprovementServiceFlgDisabled: false
      });
    }
    // 欠席時対応の場合
    if (status === "5") {
      this.setState({
        isNutritionScreeningFlgDisabled: false,
        isBathSupportFlgDisabled: true,
        isEmergencyAcceptanceAdditionFlgDisabled: true,
        isIntensiveSupportFlgDisabled: true,
        isNutritionImprovementServiceFlgDisabled: false
      });
      this.resetIntensiveSupport();
      this.resetSevereDisabilitySupportType();
    }
    // 訪問の場合
    if (status === "6") {
      this.setState({
        isTimeInputDisabled: false,
        isNutritionScreeningFlgDisabled: false,
        isBathSupportFlgDisabled: false,
        isEmergencyAcceptanceAdditionFlgDisabled: false,
        isIntensiveSupportFlgDisabled: false,
        isSputumImplementationFlgDisabled: false,
        isNutritionImprovementServiceFlgDisabled: false,
        isSevereDisabilitySupportDisabled: false
      });
    }
    // 体験利用の場合
    if (status === "7") {
      this.setState({
        isTrialUsageKindDisplay: true,
        isBathSupportFlgDisabled: true,
        isEmergencyAcceptanceAdditionFlgDisabled: true,
        isIntensiveSupportFlgDisabled: true,
        isNutritionImprovementServiceFlgDisabled: true
      });
      this.resetIntensiveSupport();
      this.resetSevereDisabilitySupportType();
    }
    // 欠席の場合
    if (status === "10") {
      this.setState({
        isNutritionScreeningFlgDisabled: false,
        isBathSupportFlgDisabled: true,
        isEmergencyAcceptanceAdditionFlgDisabled: true,
        isIntensiveSupportFlgDisabled: true,
        isNutritionImprovementServiceFlgDisabled: false
      });
      this.resetIntensiveSupport();
      this.resetSevereDisabilitySupportType();
    }
  };

  private changePickupPremisesState = (pickupValue: string): void => {
    if (pickupValue !== "0") {
      let pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST0;
      switch (pickupValue) {
        case "1":
          pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST1;
          break;
        case "2":
          pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST2;
          break;
        case "3":
          pickupPremisesList = SUPPLY_PICKUP_PREMISES_SERVICE_LIST3;
          break;
        default:
      }
      this.setState({ pickupPremisesList, isPickupPremisesDisabled: false });
    } else {
      this.setState({ isPickupPremisesDisabled: true });
    }
  };

  public render(): JSX.Element {
    // 集中的支援加算
    // 利用者設定の集中的支援加算フラグがOFFの場合は、利用実績の集中的支援加算項目を非活性にする
    // const isUserInFacilityIntensiveSupportFlgDisabled = !numberToBoolean(
    //   stringToNumber(
    //     this.props.usersInFacilityState.user.user_in_facility
    //       .intensive_support_flg
    //   )
    // );

    // 集中的支援加算
    // 算定開始日から3ヶ月以内の場合非活性にする
    // const intensiveSupportStartDateString = this.props.usersInFacilityState.user
    //   .user_in_facility.intensive_support_start_date;
    // let isUserInFacilityIntensiveSupportStartDateFlgDisabled = false;

    // if (intensiveSupportStartDateString) {
    //   const intensiveSupportStartDate = new Date(
    //     intensiveSupportStartDateString
    //   );
    //   const before3MonthDate = new Date(this.props.selectedDate);
    //   before3MonthDate.setMonth(before3MonthDate.getMonth() - 3);

    //   // 活性条件: モーダルの日付3ヶ月前 <= 集中的支援算定開始日 <= モーダルの日付
    //   const isDateWithInIntensive = isDateWithin(
    //     intensiveSupportStartDate,
    //     before3MonthDate,
    //     this.props.selectedDate
    //   );
    //   isUserInFacilityIntensiveSupportStartDateFlgDisabled = !isDateWithInIntensive;
    // }

    const startAddYearTo = 1;
    return (
      <InOutReportFormPaper>
        <FormGroup row>
          <FormikSelect
            name="initial.status"
            label="サービス提供の状況"
            required
            options={SEIKATSUKAIGO_IN_OUT_RECORDS_STATUS}
            size="smallMedium"
            onChangeHook={this.handleChangeStatusHook}
          />
          {this.state.isTrialUsageKindDisplay && (
            <div>
              <FormikSelect
                name="initial.trialUsageKind"
                label="体験利用支援種別"
                required
                options={TRAIL_USAGE_KIND}
                size="medium"
                style={{ marginBottom: 12, width: "335px" }}
              />
              <FormikCheckbox
                name="initial.lifeSupportHubInDistrictFlg"
                label="地域生活支援拠点"
              />
            </div>
          )}
        </FormGroup>
        <FormGroup row>
          <FormikTime
            name="initial.inTime"
            label="開始時間"
            required={!this.state.isTimeInputDisabled}
            placeholder="00:00"
            size="smallMedium"
            maxLength={5}
            disabled={this.state.isTimeInputDisabled}
            error={
              this.props.formikPropsValues.errors &&
              this.props.formikPropsValues.errors.initial
                ? this.props.formikPropsValues.errors.initial.inTime !==
                  undefined
                : false
            }
            onChangeHookTime={this.handleChangeInitialTime}
          />
          <FormikTime
            name="initial.outTime"
            label="終了時間"
            placeholder="00:00"
            size="smallMedium"
            maxLength={5}
            disabled={this.state.isTimeInputDisabled}
            error={
              this.props.formikPropsValues.errors &&
              this.props.formikPropsValues.errors.initial
                ? this.props.formikPropsValues.errors.initial.outTime !==
                  undefined
                : false
            }
            onChangeHookTime={this.handleChangeInitialTime}
          />
          <FormikTextField
            name="initial.calculatedHours"
            label="算定時間"
            placeholder="0.5"
            size="smallMedium"
            maxLength={4}
            disabled={this.state.isTimeInputDisabled}
            endAdornmentLabel="時間"
          />
        </FormGroup>
        <FormikSelect
          name="initial.extended_202404"
          label="延長支援"
          size="smallMedium"
          disabled={this.state.isExtendedDisabled}
          options={SUPPLY_EXTENDED_SERVICE_LIST_202404}
          FormLabelClasses={
            this.state.isExtendedDisabled
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        {this.props.isFoodAvailable && (
          <FormikSelect
            name="initial.didGetFood"
            label="食事提供"
            size="smallMedium"
            disabled={this.state.isDidGetFoodDisablead}
            options={SUPPLY_FOOD_SERVICE_LIST}
            FormLabelClasses={
              this.state.isDidGetFoodDisablead
                ? { root: this.props.classes.disabled }
                : undefined
            }
          />
        )}
        {this.props.isPickupAvailable && (
          <FormGroup row>
            <FormikSelect
              name="initial.travelTime"
              label="送迎"
              size="smallMedium"
              disabled={this.state.isTravelTimeDisabled}
              options={SUPPLY_PICKUP_SERVICE_LIST}
              onChangeHook={this.handleChangePickupHook}
              FormLabelClasses={
                this.state.isTravelTimeDisabled
                  ? { root: this.props.classes.disabled }
                  : undefined
              }
            />
            <FormikSelect
              name="initial.pickupPremises"
              label="同一敷地内送迎"
              size="smallMedium"
              options={this.state.pickupPremisesList}
              disabled={this.state.isPickupPremisesDisabled}
              FormLabelClasses={
                this.state.isPickupPremisesDisabled
                  ? { root: this.props.classes.disabled }
                  : undefined
              }
            />
          </FormGroup>
        )}
        {this.props.isSevereFailureSupport &&
          this.props.formikPropsValues.values.initial
            .isSevereDisabilitySupport && (
            <FormikSelect
              size="quarterSuperLong"
              name="initial.severeDisabilitySupportType"
              label="重度障害者支援実施"
              disabled={this.state.isSevereDisabilitySupportDisabled}
              options={SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT}
            />
          )}
        <FormikCheckbox
          name="initial.nutritionScreeningFlg"
          label="栄養スクリーニング加算"
          disabled={this.state.isNutritionScreeningFlgDisabled}
        />
        <FormikCheckbox
          name="initial.nutritionImprovementServiceFlg"
          label="栄養改善サービス実施"
          disabled={this.state.isNutritionImprovementServiceFlgDisabled}
        />
        <FormikCheckbox
          name="initial.sputumImplementationFlg"
          label="喀痰吸引等実施"
          disabled={this.state.isSputumImplementationFlgDisabled}
        />
        <FormikCheckbox
          name="initial.bathSupportFlg"
          label="入浴支援実施"
          disabled={this.state.isBathSupportFlgDisabled}
        />
        <FormikSwitch
          name="initial.intensiveSupportFlg"
          label="集中的支援加算"
          onChange={this.onChangeSwitch}
          disabled={this.state.isIntensiveSupportFlgDisabled}
        >
          {!this.state.isIntensiveSupportFlgDisabled && (
            <FormikSelectDateNotSelectedDefault
              required
              name="initial.intensiveSupportStartDate"
              label="支援開始日"
              addYearTo={startAddYearTo}
              setFormikFieldValue={this.props.setFormikFieldValue}
            />
          )}
        </FormikSwitch>
        <FormikCheckbox
          name="initial.emergencyAcceptanceAdditionFlg"
          label="緊急時受入加算"
          disabled={this.state.isEmergencyAcceptanceAdditionFlgDisabled}
        />
        <FormikTextField
          name="initial.memo"
          label="備考"
          size="quarterSuperLong"
          style={{ marginBottom: 48 }}
          error={
            this.props.formikPropsValues.errors &&
            this.props.formikPropsValues.errors.initial
              ? this.props.formikPropsValues.errors.initial.memo !== undefined
              : false
          }
          multiline
          onChangeHook={this.onChangeText}
        />
      </InOutReportFormPaper>
    );
  }
}

export default withStyles(styles)(InOutReportDialogFields);
