import * as React from "react";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
// store
import { connect } from "react-redux";
import {
  ServiceDeliveryTypeInterface,
  SERVICE_DELIVERY_DAILY,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
// ui
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { ServiceDeliveryTableCells } from "@components/organisms/mgr/KODOENGO/record/ServiceDeliveryTableCells";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { groupBy } from "lodash-es";
import { FacilityType } from "@constants/variables";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    rowDaily: {
      height: 56,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      },
      position: "relative"
    },
    longCellFirst: {
      minWidth: 160,
      width: 160,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingTop: 14,
      paddingBottom: 14,
      paddingRight: 8,
      paddingLeft: 16,
      backgroundColor: "#fff"
    },
    longCellFirstLabel: {
      minWidth: 160,
      width: 160,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingTop: 14,
      paddingBottom: 14,
      paddingRight: 8,
      paddingLeft: 16
    },
    marginCell: {
      height: "18px",
      width: "18px"
    },
    statusCell: {
      minWidth: 104,
      width: 104,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8
    },
    numberOfPractitionerCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8
    },
    timeCell: {
      minWidth: 132,
      width: 132,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8
    },
    middleCell: {
      minWidth: 50,
      width: 50,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8
    },
    unPlannedFlgCell: {
      minWidth: 72,
      width: 72,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8
    },
    recordedButtonCell: {
      minWidth: 160,
      width: 160,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingRight: 8,
      textAlign: "center"
    },
    tableHeader: {
      zIndex: 10,
      position: "sticky"
    },
    noPlanAndNoRecord: {
      paddingTop: "80px",
      paddingBottom: "48px",
      textAlign: "center",
      lineHeight: "28px"
    },
    dottedBorderBottom: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left bottom"
    },
    solidBorderBottom: {
      borderBottom: "1px solid #e0e0e0"
    }
  });

type OwnProps = {
  records:
    | ServiceDeliveryState["monthlyRecord"]
    | ServiceDeliveryState["dailyRecord"];
  type: ServiceDeliveryTypeInterface["type"];
  headerHeight: number;
  changeEditMode: (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    uifId: number,
    supportProcedureFormsId: number
  ) => void;
  facilityType: FacilityType;
};

type ServiceDeliverDictionary = {
  [key: string]: ServiceDeliveryState["dailyRecord"]["service_delivery"];
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryTableCore = (props: Props): JSX.Element => {
  const { records, classes } = props;

  const rows = records.service_delivery;

  // データを利用者単位でまとめる(日ごと画面用)
  const groupByUifId = (
    arr: ServiceDeliveryState["dailyRecord"]["service_delivery"]
  ): ServiceDeliverDictionary => {
    const targetObj = {} as ServiceDeliverDictionary;
    arr.forEach((item) => {
      if (
        Object.keys(targetObj).indexOf(`_${item.users_in_facility_id}`) !== -1
      ) {
        targetObj[`_${item.users_in_facility_id}`].push({ ...item });
      } else {
        targetObj[`_${item.users_in_facility_id}`] = [{ ...item }];
      }
    });
    return targetObj;
  };

  const table = (
    row:
      | ServiceDeliveryState["dailyRecord"]["service_delivery"]
      | ServiceDeliveryState["monthlyRecord"]["service_delivery"],
    dataList:
      | ServiceDeliveryState["dailyRecord"]["service_delivery"][0]
      | ServiceDeliveryState["monthlyRecord"]["service_delivery"][0],
    index: number,
    subIndex: number,
    lastIndex: number,
    lastSubIndex: number
  ): JSX.Element => {
    const idx = `${index}-${subIndex}`;
    const firstCellStyle =
      index === lastIndex
        ? `${classes.longCellFirst}`
        : `${classes.longCellFirst} ${classes.dottedBorderBottom}`;

    const borderBottomStyle =
      index === lastIndex
        ? classes.solidBorderBottom
        : classes.dottedBorderBottom;

    const borderBottom = subIndex === lastSubIndex ? borderBottomStyle : "";

    return (
      <TableRow
        key={`table-row-${idx}`}
        className={`${classes.rowDaily} ${borderBottom}`}
      >
        <>
          {subIndex === 0 && props.type === SERVICE_DELIVERY_DAILY ? (
            /* 利用者名 */
            <TableCellWrap
              key={`${idx}-sei-mei`}
              cellClass={firstCellStyle}
              rowSpan={row.length}
            >
              {dataList.name_sei}
              {dataList.name_mei}
            </TableCellWrap>
          ) : null}

          {/* 日付 */}
          {subIndex === 0 && "is_holiday" in dataList ? (
            <TableCellWrap
              key={`${idx}-target-date`}
              cellClass={firstCellStyle}
              rowSpan={row.length}
            >
              <CustomDateLabel
                date={row ? dataList.target_date : ""}
                dateFormat="Do（dd）"
                // 月ごとの提供記録で「is_holiday」がない場合は存在しないが型定義のため
                holiday={dataList.is_holiday}
              />
            </TableCellWrap>
          ) : null}
          <ServiceDeliveryTableCells
            key={`table-cells-${idx}`}
            params={dataList}
            idx={`${index}${subIndex}`}
            type={props.type}
            changeEditMode={props.changeEditMode}
            lastFlg={subIndex === lastSubIndex}
            facilityType={props.facilityType}
          />
        </>
      </TableRow>
    );
  };

  const dailyTable = (): JSX.Element[][] => {
    const groupedUsers = groupByUifId(rows);
    return Object.values(groupedUsers).map((row, index, arr) => {
      const lastIndex = arr.length - 1;
      const lastSubIndex = row.length - 1;
      return row.map((dataList, subIndex) => {
        return table(row, dataList, index, subIndex, lastIndex, lastSubIndex);
      });
    });
  };

  const monthlyTable = (): JSX.Element[][] => {
    const groupedUsers = Object.values(
      groupBy(rows, "target_date")
    ) as ServiceDeliveryState["monthlyRecord"]["service_delivery"][];

    return Object.values(groupedUsers).map((row, index, arr) => {
      const lastIndex = arr.length - 1;
      const lastSubIndex = row.length - 1;
      return row.map((dataList, subIndex) => {
        return table(row, dataList, index, subIndex, lastIndex, lastSubIndex);
      });
    });
  };

  const headData = [
    {
      label: "利用者名",
      className: `${classes.longCellFirstLabel}`
    },
    // 余白ヘッダー
    {
      label: "",
      className: `${classes.marginCell}`
    },
    {
      label: "サービス内容",
      className: `${classes.statusCell}`
    },
    {
      label: "人数",
      className: `${classes.numberOfPractitionerCell}`
    },
    {
      label: "時間",
      className: `${classes.timeCell}`
    },
    {
      label: "時間数",
      className: `${classes.middleCell}`
    },
    // 計画外ヘッダー
    {
      label: "",
      className: `${classes.unPlannedFlgCell}`
    },
    // 手順書 兼 記録用紙ヘッダー
    {
      label: "",
      className: `${classes.recordedButtonCell}`
    },
    // サービス提供記録
    {
      label: "",
      className: `${classes.recordedButtonCell}`
    }
  ];

  return (
    <>
      <div
        className={classes.tableHeader}
        style={{
          top: `${props.headerHeight}px`
        }}
      >
        <Table key="record-table-head">
          <KnowbeTableHead
            uniqueKey="customRecordsTable"
            height={48}
            items={headData}
          />
        </Table>
      </div>
      {records.service_delivery.length > 0 ? (
        <Table key="record-table">
          <TableBody key="record-table-body">
            {props.type === SERVICE_DELIVERY_DAILY
              ? dailyTable()
              : monthlyTable()}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noPlanAndNoRecord}>
          計画・支援手順書 兼 記録用紙・サービス提供記録がありません。
          <br />
          利用実績画面にて計画を入力する、または支援計画画面にて支援手順書 兼
          記録用紙を作成後にご利用いただくか、
          <br />
          この画面の「計画外の記録の追加」ボタンからサービス提供記録を作成してください。
        </div>
      )}
    </>
  );
};

export const ServiceDeliveryTable = connect(null)(
  withStyles(styles)(ServiceDeliveryTableCore)
);
