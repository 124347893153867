import React from "react";

// ui
import { RecordSupportTableRow } from "@components/organisms/mgr/SHISETSUNYUSHO/record/RecordSupportTableRow";
import { FieldItem } from "@interfaces/ui/form";

// formik
import { Formik, Form, FormikActions } from "formik";
import initialValues, {
  RecordUserDetailValues
} from "@initialize/mgr/SHISETSUNYUSHO/record/userDetail/initialValues";
import validation from "@initialize/mgr/SHISETSUNYUSHO/record/userDetail/validation";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import * as recordUserDetailActions from "@stores/pages/record/userDetail/actions";

import { SupportRecordUserState } from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { RecordUserDetailState } from "@stores/pages/record/userDetail/types";
import { StaffState } from "@stores/domain/staff/types";

// utils
import { toEffectiveObject } from "@utils/object";
import isEqual from "lodash-es/isEqual";
import { SnackbarParams } from "@stores/ui/type";

type OwnProps = {
  supportsRecord: SupportRecordUserState;
  staffOptions: FieldItem[];
  isEditing: boolean;
  targetDate: string | null;
  serviceType?: string;
  customRecords: CustomRecordsState;
  year: string;
  month: string;
  currentDate: Date;
  supportLastDay: number;
  newCustomRecords: CustomRecordsState | null;
  recordUserDetail: RecordUserDetailState;
  uifId: string;
  staff: StaffState;
};
type StateProps = {
  needsStopHistory: boolean;
  holidays: string[];
};
type DispatchProps = {
  setEditing: (targetDate: string) => void;
  unsetEditing: () => void;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
  postCustomSupport: (
    uifId: string,
    params: RecordUserDetailValues,
    initialValue: RecordUserDetailValues,
    targetDate: string,
    year: string,
    month: string
  ) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const UserDetailRecordSupportTableCore = (props: Props): JSX.Element | null => {
  // state
  const [formValues, setFormValues] = React.useState(
    initialValues(
      props.supportsRecord.support,
      props.customRecords,
      props.supportLastDay,
      props.year,
      props.month
    )
  );

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditing(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);
  React.useEffect(() => {
    setFormValues(
      initialValues(
        props.supportsRecord.support,
        props.customRecords,
        props.supportLastDay,
        props.year,
        props.month
      )
    );
  }, [props.customRecords, props.supportsRecord.support]);

  // handler
  const onClickEdit = (e: React.MouseEvent, date: string): void => {
    props.setEditing(date);
    e.preventDefault();
  };

  // formik handler
  const confirmDiscardFormChanges = (
    nextValues: RecordUserDetailValues
  ): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };
  const validate = (values: RecordUserDetailValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };
  const onSubmit = async (
    values: RecordUserDetailValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (props.recordUserDetail.targetDate) {
      await props.postCustomSupport(
        props.uifId,
        values,
        formValues,
        props.recordUserDetail.targetDate,
        props.year,
        props.month
      );
    }
    actions.setSubmitting(false);
    props.stopHistory(false);
  };
  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const onClickEditCancel = (): void => {
          formikProps.resetForm();
          props.unsetEditing();
          setFormValues(
            initialValues(
              props.supportsRecord.support,
              props.customRecords,
              props.supportLastDay,
              props.year,
              props.month
            )
          );
          props.stopHistory(false);
        };
        return (
          <Form>
            {props.supportLastDay &&
            props.newCustomRecords &&
            formikProps.values.record.length === props.supportLastDay
              ? Array.from(
                  { length: props.supportLastDay },
                  (_, index) => index
                ).map((item) => {
                  const date = `${props.year}-${props.month.padStart(
                    2,
                    "0"
                  )}-${String(item + 1).padStart(2, "0")}`;
                  const isHoliday = props.holidays.includes(date);
                  return (
                    <RecordSupportTableRow
                      key={date}
                      editType="individual"
                      displayType="date"
                      support={props.supportsRecord.support}
                      staffOptions={props.staffOptions}
                      formikProps={formikProps}
                      formikFieldNamePrefix={`record[${item}]`}
                      isEditing={
                        date === props.targetDate ? props.isEditing : false
                      }
                      isEditMode={
                        !props.targetDate || date === props.targetDate
                      }
                      initialValues={formValues.record[item]}
                      date={date}
                      customRecords={props.newCustomRecords || []}
                      onClickEdit={onClickEdit}
                      onClickEditCancel={onClickEditCancel}
                      isHoliday={isHoliday}
                      showSnackbar={props.showSnackbar}
                      serviceEndDate={props.supportsRecord.date_end_in_service}
                    />
                  );
                })
              : null}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  holidays: state.holiday
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, pages } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const recordUserDetailDispatcher = pages.recordUserDetailDispatcher(dispatch);
  return {
    postCustomSupport: (
      uifId: string,
      params: RecordUserDetailValues,
      initialValue: RecordUserDetailValues,
      targetDate: string,
      year: string,
      month: string
    ): void => {
      recordUserDetailDispatcher.postCustomSupportSHISETSUNYUSHO(
        uifId,
        params,
        initialValue,
        targetDate,
        { year, month }
      );
    },
    setEditing: (targetDate: string): void => {
      dispatch(recordUserDetailActions.setEditWithTargetDate(targetDate));
    },
    unsetEditing: (): void => {
      dispatch(recordUserDetailActions.unsetEditWithTargetDate());
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const UserDetailRecordSupportTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailRecordSupportTableCore);
