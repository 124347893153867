import { BasicErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/basic";
import { FacilityValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/facility/initialValues";
import validator, { validateSwitcher } from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import { FacilityInfoKeikakuErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/facilityInfoKeikakuValues";
import { FacilityInfoShogaijiErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/facilityInfoShogaijiValues";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

type FacilityErrors = BasicErrors &
  FacilityInfoKeikakuErrors &
  FacilityInfoShogaijiErrors;

const basicValidation = (
  values: FacilityValues,
  facility: FacilityState
): FacilityErrors => {
  const isKeikaku = facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    facility.facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required")
    },
    facilityInfoKeikaku: {
      officeNumber: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(
          values.facilityInfoKeikaku.officeNumber,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      officeName: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.officeName, "required")
      ),
      representativeName: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.representativeName, "required")
      ),
      tel: validateSwitcher(
        isKeikaku || isKeikakuShogaiji,
        validator(values.facilityInfoKeikaku.tel, "required", "naturalNumber")
      )
    },
    facilityInfoShogaiji: {
      officeNumber: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(
          values.facilityInfoShogaiji.officeNumber,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      officeName: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.officeName, "required")
      ),
      representativeName: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.representativeName, "required")
      ),
      tel: validateSwitcher(
        isShogaiji || isKeikakuShogaiji,
        validator(values.facilityInfoShogaiji.tel, "required", "naturalNumber")
      )
    }
  };
};

export const validation = (
  values: FacilityValues,
  facility: FacilityState
): FacilityErrors => {
  const basicErrors = basicValidation(values, facility);
  return {
    ...basicErrors
  };
};
