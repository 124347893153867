import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { RATE_GET_JOB_ITEMS_202104 } from "@constants/mgr/SHUROTEICHAKU/variables";
import LackFields from "@components/v202104/organisms/mgr/common/facility/items/LackFields";
import {
  WithStyles,
  createStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FacilityValues } from "@initialize/v202104/mgr/SHUROTEICHAKU/facility/initialValues";

import { FormikProps } from "formik";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => (
  <FormPaper>
    <div style={{ marginBottom: 32 }}>
      <SectionTitle label="減算対象" />
    </div>
    <FormikRadioButtons
      name="subtractionItem.rateGetJob"
      label="就労定着率"
      options={RATE_GET_JOB_ITEMS_202104}
    />
    <LackFields formikProps={props.formikProps} name="subtractionItem" />
  </FormPaper>
);

export default withStyles(styles)(SubtractionItemFields);
