import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import { AssessmentSettingForm } from "@components/organisms/mgr/KEIKAKUSODAN/assessment/setting/AssessmentSettingForm";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { AppState } from "@stores/type";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  ASSESSMENT_CATEGORY_TYPE,
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  INT_TRUE_FROM_API,
  INT_FALSE_FROM_API
} from "@constants/variables";
import FormPaper from "@components/atoms/FormPaper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  CustomRecordsValues,
  initialValues
} from "@initialize/record/customRecordWithCategory/initialValues";
import { CustomRecordsDialog } from "@components/organisms/assessment/setting/dialog/CustomRecordsDialog";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";

const styles = (): StyleRules =>
  createStyles({
    topSection: {
      marginTop: 32
    },
    infoIcon: {
      color: "#0277bd",
      width: 20,
      height: 20,
      marginRight: 6
    },
    caption: {
      color: "#37474f",
      display: "flex",
      alignItems: "center"
    },
    description: {
      lineHeight: 1.75,
      margin: "4px 26px",
      color: "#666666"
    }
  });

type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  postCustomRecords: (formValue: CustomRecordsValues) => Promise<void>;
  postCustomRecordsCategory: (formValue: CustomRecordsValues) => Promise<void>;
  changeVisibilityCustomRecord: (
    customRecordItems?: postCustomRecordsVisibilityParams["custom_record_items"],
    customRecordsCategory?: postCustomRecordsVisibilityParams["custom_records_category"]
  ) => Promise<void>;
};

type StateProps = {
  facility: FacilityState;
  customRecords: CustomRecordsWithCategoryState;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const SettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    facility,
    customRecords,
    fetchFacility,
    fetchCustomRecords,
    postCustomRecords,
    postCustomRecordsCategory,
    changeVisibilityCustomRecord
  } = props;
  const [isOpenHideConfirm, setOpenHideConfirm] = React.useState(false);
  const [hideConfirmTargetId, setHideConfirmTargetId] = React.useState(0);
  const [customRecordItems, setCustomRecordItems] = React.useState<
    postCustomRecordsVisibilityParams["custom_record_items"] | null
  >(null);
  const [customRecordCategory, setCustomRecordCategory] = React.useState<
    postCustomRecordsVisibilityParams["custom_records_category"] | null
  >(null);
  const [formValues, setFormValues] = useState(initialValues());
  const [editedType, setEditedType] = useState<"" | "category" | "item">("");
  const [editedId, setEditedId] = useState(0);
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [sortingCategoryType, setSortingCategoryType] = useState<number | null>(
    null
  );
  const [sortingItemType, setSortingItemType] = useState<number | null>(null);

  let hideConfirmTargetName = "";
  if (hideConfirmTargetId > 0 && customRecords.length > 0) {
    if (editedType === "category") {
      const hideConfirmTarget = customRecords.find(
        (record) => record.id === hideConfirmTargetId
      );
      hideConfirmTargetName = hideConfirmTarget ? hideConfirmTarget.name : "";
    } else if (editedType === "item") {
      const hideConfirmTargetCategory = customRecords.find((record) =>
        record.custom_record_items.find((r) => r.id === hideConfirmTargetId)
      );
      const hideConfirmTargetItem = hideConfirmTargetCategory
        ? hideConfirmTargetCategory.custom_record_items.find(
            (r) => r.id === hideConfirmTargetId
          )
        : null;
      hideConfirmTargetName = hideConfirmTargetItem
        ? hideConfirmTargetItem.name
        : "";
    }
  }

  // 項目カテゴリー名の追加
  const openAddModal = (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null,
    custom_record_item_choices?: {
      id: number;
      custom_record_item_id: number;
      name: string;
      default_choice: number | null;
      hidden: number;
      default_choice_input_type: number | null;
    }[]
  ): void => {
    let isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text;
    let choices;
    if (custom_record_item_choices && custom_record_item_choices.length > 0) {
      isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text;
      choices = custom_record_item_choices;
    }

    setFormValues(
      initialValues(
        category_type,
        custom_records_category_id,
        choices,
        isInputType,
        CUSTOM_RECORD_TARGET_TYPE.assessment
      )
    );
    openCustomRecordModal(true);
    setEditedType(type);
  };

  // 項目カテゴリー名の編集
  const changeEditMode = (
    id: number,
    type: "" | "category" | "item",
    params: CustomRecordsWithCategoryState[number]
  ): void => {
    const isInputTypeRadio =
      params.category_type !== ASSESSMENT_CATEGORY_TYPE.remarks;
    setFormValues(
      initialValues(
        params.category_type,
        params.id,
        [],
        isInputTypeRadio
          ? SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio
          : SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text,
        CUSTOM_RECORD_TARGET_TYPE.assessment
      )
    );
    setEditedId(id);
    setEditedType(type);
  };

  // 項目カテゴリー名編集終了
  const endEdit = (): void => {
    setFormValues(initialValues());
    setEditedId(0);
    setEditedType("");
  };

  // カテゴリー名編集保存
  const submitCategoryEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((v) => v.id === editedId);
    const params = {
      id: editedId,
      name: category ? category.name : "",
      input_type: "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.assessment,
      custom_records_category_id: null,
      category_type: category ? category.category_type : 0
    };
    await postCustomRecordsCategory(params);
  };

  // 項目名編集保存
  const submitItemEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((value) =>
      value.custom_record_items.find((v) => v.id === editedId)
    );
    const item = category
      ? category.custom_record_items.find((v) => v.id === editedId)
      : null;
    const params = {
      id: editedId,
      name: item ? item.name : "",
      input_type: item ? `${item.input_type}` : "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.assessment,
      custom_records_category_id: item ? item.custom_records_category_id : 0,
      category_type: 0
    };
    await postCustomRecords(params);
  };

  // 項目カテゴリー名のうち編集中の保存関数を渡す
  const submitEdit =
    editedType === "category" ? submitCategoryEdit : submitItemEdit;

  // 項目の追加モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    setFormValues(initialValues());
    openCustomRecordModal(false);
    setEditedType("");
  };

  const categorizeCustomRecord = (
    categoryType: number
  ): CustomRecordsWithCategoryState => {
    return customRecords.filter((r) => r.category_type === categoryType);
  };

  const openHideConfirmModal = (
    id: number,
    type: "" | "category" | "item"
  ): void => {
    setOpenHideConfirm(true);
    setEditedType(type);
    setHideConfirmTargetId(id);
  };
  const closeHideConfirmModal = (): void => {
    setOpenHideConfirm(false);
  };

  const onClickHide = async (): Promise<void> => {
    setOpenHideConfirm(false);
    if (hideConfirmTargetId === 0) return;
    if (editedType === "") return;
    await changeVisibilityCustomRecord(
      customRecordItems && customRecordItems.length !== 0
        ? customRecordItems
        : undefined,
      customRecordCategory && customRecordCategory.length !== 0
        ? customRecordCategory
        : undefined
    );
    await fetchCustomRecords();
    setCustomRecordItems(null);
    setCustomRecordCategory(null);
  };

  // 計画or障害児(単体)のチェックボックス押下時
  const onClickHideCheckbox = async (
    type: "category" | "item",
    target: "keikaku" | "shogaiji",
    record?: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): Promise<void> => {
    if (
      type === "category" &&
      record &&
      record.custom_records_keikakusodan_visibility
    ) {
      // カテゴリーの場合
      setCustomRecordCategory([
        {
          id: record.id,
          custom_records_keikakusodan_visibility: {
            id: record.custom_records_keikakusodan_visibility.id,
            plan_visibility: target === "keikaku" ? 0 : undefined,
            child_visibility: target === "shogaiji" ? 0 : undefined
          }
        }
      ]);
      // モーダル表示
      openHideConfirmModal(record.id, "category");
    } else if (
      type === "item" &&
      item &&
      item.custom_records_keikakusodan_visibility
    ) {
      // 項目の場合
      setCustomRecordItems([
        {
          id: item.id,
          custom_records_keikakusodan_visibility: {
            id: item.custom_records_keikakusodan_visibility.id,
            plan_visibility: target === "keikaku" ? 0 : undefined,
            child_visibility: target === "shogaiji" ? 0 : undefined
          }
        }
      ]);
      // モーダル表示
      openHideConfirmModal(item.id, "item");
    }
  };

  // 計画×障害児(種別複合)のチェックボックス押下時
  const onClickVisibilityCheckbox = async (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "category" | "item",
    target: "keikaku" | "shogaiji",
    record: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): Promise<void> => {
    const { checked } = event.target;

    if (type === "category" && record.custom_records_keikakusodan_visibility) {
      // カテゴリーの場合
      const items: postCustomRecordsVisibilityParams["custom_record_items"] = [];
      record.custom_record_items.forEach((v) => {
        if (
          v.allow_change_visibility &&
          v.custom_records_keikakusodan_visibility
        ) {
          items.push({
            id: v.id,
            custom_records_keikakusodan_visibility: {
              id: v.custom_records_keikakusodan_visibility.id,
              plan_visibility:
                target === "keikaku" ? booleanToNumber0or1(checked) : undefined,
              child_visibility:
                target === "shogaiji" ? booleanToNumber0or1(checked) : undefined
            }
          });
        }
      });
      const category: postCustomRecordsVisibilityParams["custom_records_category"] = [
        {
          id: record.id,
          custom_records_keikakusodan_visibility: {
            id: record.custom_records_keikakusodan_visibility.id,
            plan_visibility:
              target === "keikaku" ? booleanToNumber0or1(checked) : undefined,
            child_visibility:
              target === "shogaiji" ? booleanToNumber0or1(checked) : undefined
          }
        }
      ];
      if (
        checked ||
        (!checked &&
          record.custom_records_keikakusodan_visibility.plan_visibility ===
            INT_TRUE_FROM_API &&
          record.custom_records_keikakusodan_visibility.child_visibility ===
            INT_TRUE_FROM_API)
      ) {
        // モーダル表示無しで送信
        await changeVisibilityCustomRecord(
          items.length !== 0 ? items : undefined,
          category
        );
        await fetchCustomRecords();
      } else {
        // モーダル表示
        setCustomRecordItems(items);
        setCustomRecordCategory(category);
        openHideConfirmModal(record.id, "category");
      }
    } else if (
      type === "item" &&
      item &&
      item.custom_records_keikakusodan_visibility
    ) {
      // 項目の場合
      const items: postCustomRecordsVisibilityParams["custom_record_items"] = [
        {
          id: item.id,
          custom_records_keikakusodan_visibility: {
            id: item.custom_records_keikakusodan_visibility.id,
            plan_visibility:
              target === "keikaku" ? booleanToNumber0or1(checked) : undefined,
            child_visibility:
              target === "shogaiji" ? booleanToNumber0or1(checked) : undefined
          }
        }
      ];
      if (checked) {
        let category: postCustomRecordsVisibilityParams["custom_records_category"];
        // カテゴリーのチェックボックスがOFFの場合に配下の項目いずれかをONにした場合は、カテゴリーのチェックボックスもONになる
        if (
          target === "keikaku" &&
          record.custom_records_keikakusodan_visibility &&
          record.custom_records_keikakusodan_visibility.plan_visibility ===
            INT_FALSE_FROM_API
        ) {
          category = [
            {
              id: record.id,
              custom_records_keikakusodan_visibility: {
                id: record.custom_records_keikakusodan_visibility.id,
                plan_visibility: 1
              }
            }
          ];
        } else if (
          target === "shogaiji" &&
          record.custom_records_keikakusodan_visibility &&
          record.custom_records_keikakusodan_visibility.child_visibility ===
            INT_FALSE_FROM_API
        ) {
          category = [
            {
              id: record.id,
              custom_records_keikakusodan_visibility: {
                id: record.custom_records_keikakusodan_visibility.id,
                child_visibility: 1
              }
            }
          ];
        }
        // モーダル表示無しで送信
        await changeVisibilityCustomRecord(items, category);
        await fetchCustomRecords();
      } else if (
        !checked &&
        item.custom_records_keikakusodan_visibility.plan_visibility ===
          INT_TRUE_FROM_API &&
        item.custom_records_keikakusodan_visibility.child_visibility ===
          INT_TRUE_FROM_API
      ) {
        // モーダル表示無しで送信
        await changeVisibilityCustomRecord(items);
        await fetchCustomRecords();
      } else {
        // モーダル表示
        setCustomRecordItems(items);
        openHideConfirmModal(item.id, "item");
      }
    }
  };

  useEffect(() => {
    fetchFacility();
    fetchCustomRecords();
  }, []);

  const hideItemConfirmModalMessage = (
    <span>
      この項目は記録画面に表示されなくなり、「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
    </span>
  );
  const hideCategoryConfirmModalMessage = (
    <span>
      このカテゴリーに属する全ての項目が記録画面に表示されなくなり、
      <br />
      「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
      <br />
    </span>
  );
  const hideConfirmModalMessage =
    editedType === "category"
      ? hideCategoryConfirmModalMessage
      : hideItemConfirmModalMessage;
  const hideConfirmModalTitle =
    editedType === "category"
      ? `カテゴリー「${hideConfirmTargetName}」を非表示にしますか？`
      : `「${hideConfirmTargetName}」を非表示にしますか？`;

  let assessmentSettingInfo: {
    isCategorized: boolean;
    categoryType: number;
    label: string;
    customRecords: CustomRecordsWithCategoryState;
    isHiddenButton?: boolean;
  }[] = [];

  assessmentSettingInfo = [
    {
      isCategorized: true,
      categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary
      )
    },
    {
      isCategorized: false,
      categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation,
      label: "入力画面に文字入力形式の追加が可能です。",
      customRecords: categorizeCustomRecord(
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation
      )
    },
    {
      isCategorized: true,
      categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation,
      label:
        "入力画面では「現状」「本人・家族の希望や困りごと」「支援者の気付きや気になりごと」の3項目への文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation
      )
    },
    {
      isCategorized: true,
      categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request
      )
    },
    {
      isCategorized: true,
      categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others
      )
    }
  ];

  return (
    <section className={classes.topSection}>
      <FormPaper>
        <div className={classes.caption}>
          <InfoOutlinedIcon className={classes.infoIcon} />
          この画面の設定について
        </div>
        <p className={classes.description}>
          ここで設定した内容は、作成済みのものも含めて全てのアセスメントシートに反映されます。
          <br />
          カテゴリー名や項目名を変更する際に、作成済みのシートに反映させたくない場合は、該当箇所の「編集」は行わず、
          <br />
          「追加」で新しいカテゴリー/項目を作成の上、不要なカテゴリー/項目を「非表示」にしてください。
          <br />
          「非表示」にした場合でも、作成済みのシートで内容が入力されている箇所は消えずに残ります。
        </p>
      </FormPaper>
      {assessmentSettingInfo.map((v) => (
        <AssessmentSettingForm
          key={`assessmentSettingForm-${v.categoryType}`}
          isCategorized={v.isCategorized}
          categoryType={v.categoryType}
          label={v.label}
          facility={facility}
          customRecords={v.customRecords}
          isHiddenButton={v.isHiddenButton}
          sortingCategoryType={sortingCategoryType}
          setSortingCategoryType={setSortingCategoryType}
          sortingItemType={sortingItemType}
          setSortingItemType={setSortingItemType}
          fetchCustomRecords={fetchCustomRecords}
          openAddModal={openAddModal}
          editedId={editedId}
          editedType={editedType}
          changeEditMode={changeEditMode}
          endEdit={endEdit}
          submitEdit={submitEdit}
          onClickHideCheckbox={onClickHideCheckbox}
          onClickVisibilityCheckbox={onClickVisibilityCheckbox}
          changeVisibilityCustomRecord={changeVisibilityCustomRecord}
        />
      ))}
      <MessageDialog
        isOpen={isOpenHideConfirm}
        title={hideConfirmModalTitle}
        message={hideConfirmModalMessage}
        closeButton={
          <KnowbeButton
            kind="text"
            style={{ margin: "0 8px 0 0" }}
            onClick={closeHideConfirmModal}
          >
            キャンセル
          </KnowbeButton>
        }
        actionButton={
          <KnowbeButton
            kind="textDelete"
            style={{ margin: 0 }}
            onClick={onClickHide}
          >
            非表示にする
          </KnowbeButton>
        }
      />
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          modalType={editedType}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.KEIKAKUSODAN.facility,
  customRecords: state.customRecordsWithCategory.assesment
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory, KEIKAKUSODAN } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    fetchFacility: KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.assessment
      );
    },
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecords(formValue);
    },
    postCustomRecordsCategory: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsCategory(formValue);
    },
    changeVisibilityCustomRecord: (
      customRecordItems?: postCustomRecordsVisibilityParams["custom_record_items"],
      customRecordsCategory?: postCustomRecordsVisibilityParams["custom_records_category"]
    ): Promise<void> => {
      return customRecordsDispatches.changeVisibilityCustomRecord(
        customRecordItems,
        customRecordsCategory
      );
    }
  };
};

export const SettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingFormCore)
);
