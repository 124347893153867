import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { AssessmentPrint } from "@components/organisms/record/print/AssessmentPrint";
import * as URL from "@constants/url";

/**
 * 個別支援計画のプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    assessmentId: string;
  }>;

const AssessmentRecordPrintCore = (props: Props): JSX.Element => {
  const { uifId, assessmentId } = props.match.params;

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "支援計画", path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY },
    {
      pathName: "アセスメント",
      path: `/record/support_plan/${uifId}/assessment`
    }
  ];
  const RenderPrint = (): JSX.Element => {
    return <AssessmentPrint uifId={uifId} assessmentId={assessmentId} />;
  };

  return (
    <AdminTemplate pageName="アセスメントシート" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const AssessmentRecordPrint = connect(mapStateToProps)(
  AssessmentRecordPrintCore
);
