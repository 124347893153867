import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  StyleRules,
  Theme,
  withStyles
} from "@material-ui/core/styles";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      margin: `${spacing.unit * 2}px  0`,
      padding: "24px  16px",
      borderRadius: 0
    }
  });

const StyledPaper = withStyles(styles)(Paper);

type OwnProps = {
  children: React.ReactNode;
};

type Props = OwnProps;

const FormPaperCore = ({ children }: Props): JSX.Element => (
  <StyledPaper component="section" elevation={0}>
    {children}
  </StyledPaper>
);

export const FormPaper = withStyles(styles)(FormPaperCore);
