import { createStore } from "redux";
import reducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";

const isDevelopment = process.env.REACT_APP_ENV !== "production";

const store = isDevelopment
  ? createStore(reducer, composeWithDevTools())
  : createStore(reducer);

export default store;
