import _isNil from "lodash-es/isNil";
import {
  InputValue,
  SelectValue,
  SelectDateValue,
  RadioButtonValue,
  FieldItem
} from "@interfaces/ui/form";
import { dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_LIST,
  INCOME_KIND_TYPE_LIST
} from "@constants/variables";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";
import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { ConsultationRecordState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

export type MonitoringReportValues = {
  id: number | null;
  created_at: string | null;
  updated_at: string | null;
  guardian_name?: string | null;
  guardian_relation?: string | null;
  date_of_monitoring: SelectDateValue;
  creation_date: SelectDateValue;
  author: SelectValue;
  classify_disability_support: RadioButtonValue;
  income_kind: RadioButtonValue;
  income_category: SelectValue;
  disabled_welfare_service_recipient_number: InputValue;
  consultation_support_recipient_number: InputValue;
  regular_place_recipient_number: InputValue;
  support_policy: InputValue;
  whole_situation: InputValue;
  staff_comment: InputValue;
  type_consultation: number;
  details: {
    id: number | null;
    support_goal: InputValue;
    achievement_time: InputValue;
    service_provision_status: InputValue;
    personal_satisfaction: InputValue;
    achievement_support_goals: InputValue;
    future_issues: InputValue;
    change_plan_service_type: RadioButtonValue;
    change_plan_service_volume: RadioButtonValue;
    change_plan_weekly: RadioButtonValue;
    other_considerations: InputValue;
    is_delete: number;
  }[];
};

// 優先順位カード、初期値
export const initialMonitoringReportDetails: MonitoringReportValues["details"][0] = {
  id: null,
  support_goal: "",
  achievement_time: "",
  service_provision_status: "",
  personal_satisfaction: "",
  achievement_support_goals: "",
  future_issues: "",
  change_plan_service_type: "",
  change_plan_service_volume: "",
  change_plan_weekly: "",
  other_considerations: "",
  is_delete: 0
};

// 優先順位カード、values
const MonitoringReportDetailsValues = (
  detailsValues: GetMonitoringReport["data"]["details"]
): MonitoringReportValues["details"] => {
  return detailsValues.map((detail) => ({
    id: detail.id,
    support_goal: undefinedToReturnValue(detail.support_goal),
    achievement_time: undefinedToReturnValue(detail.achievement_time),
    service_provision_status: undefinedToReturnValue(
      detail.service_provision_status
    ),
    personal_satisfaction: undefinedToReturnValue(detail.personal_satisfaction),
    achievement_support_goals: undefinedToReturnValue(
      detail.achievement_support_goals
    ),
    future_issues: undefinedToReturnValue(detail.future_issues),
    change_plan_service_type: undefinedToReturnValue(
      detail.change_plan_service_type
    ),
    change_plan_service_volume: undefinedToReturnValue(
      detail.change_plan_service_volume
    ),
    change_plan_weekly: undefinedToReturnValue(detail.change_plan_weekly),
    other_considerations: undefinedToReturnValue(detail.other_considerations),
    is_delete: 0
  }));
};

// 新規作成時、初期値
export const initialValuesNew = (
  user: UsersInFacilityState["user"],
  typeConsultation: number
): MonitoringReportValues => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");

  const isShogaiji =
    typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN &&
    user.user_in_facility.classify_handicapped_flg === "1";

  // 計画相談or障害児で参照するパラメータが変わる値
  const targetConsultationUserInFacility =
    typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN
      ? findUserInFacilityShogaijisodan(user)
      : findUserInFacilityKeikakusodan(user);

  const fieldInitial: MonitoringReportValues = {
    id: null,
    created_at: null,
    updated_at: null,
    date_of_monitoring: targetConsultationUserInFacility
      ? dateToSelectDateValue(
          targetConsultationUserInFacility.date_begin_plan_support_monitor || ""
        )
      : dateToSelectDateValue(currentDate),
    creation_date: {
      year: "",
      month: "",
      day: ""
    },
    author: "",
    classify_disability_support: targetConsultationUserInFacility
      ? targetConsultationUserInFacility.disability_class.toString()
      : DISABILITY_CLASS_LIST[0].value,
    income_kind: targetConsultationUserInFacility
      ? targetConsultationUserInFacility.income_kind
      : INCOME_KIND_LIST[0].value,
    income_category: targetConsultationUserInFacility
      ? targetConsultationUserInFacility.income_classification.toString()
      : String(INCOME_KIND_TYPE_LIST[0].value),
    disabled_welfare_service_recipient_number:
      targetConsultationUserInFacility &&
      targetConsultationUserInFacility.recipient_number != null
        ? targetConsultationUserInFacility.recipient_number
        : "",
    consultation_support_recipient_number: "",
    regular_place_recipient_number: "",
    support_policy: "",
    whole_situation: "",
    staff_comment: "",
    type_consultation: typeConsultation,
    details: [initialMonitoringReportDetails]
  };

  // 保護者情報が必要な場合だけkeyを設定する
  if (isShogaiji) {
    fieldInitial.guardian_name = `${user.user_in_facility.guardian_name_sei} ${user.user_in_facility.guardian_name_mei}`;

    fieldInitial.guardian_relation = _isNil(
      user.user_in_facility.guardian_relation
    )
      ? ""
      : user.user_in_facility.guardian_relation;
  }

  return fieldInitial;
};

// 編集
export const initialValuesEdit = (
  values: GetMonitoringReport["data"],
  staffOptions: FieldItem[]
): MonitoringReportValues => {
  return {
    id: values.id,
    created_at: values.created_at,
    updated_at: values.updated_at,
    guardian_name: values.guardian_name,
    guardian_relation: values.guardian_relation,
    date_of_monitoring: dateToSelectDateValue(
      undefinedToReturnValue(values.date_of_monitoring)
    ),
    creation_date: dateToSelectDateValue(
      undefinedToReturnValue(values.creation_date)
    ),
    author: undefinedToReturnValue(
      getStaffWithSnap(values.author, staffOptions)
    ),
    classify_disability_support: String(values.classify_disability_support),
    income_kind: String(values.income_kind),
    income_category: String(values.income_category),
    disabled_welfare_service_recipient_number: undefinedToReturnValue(
      values.disabled_welfare_service_recipient_number
    ),
    consultation_support_recipient_number: undefinedToReturnValue(
      values.consultation_support_recipient_number
    ),
    regular_place_recipient_number: undefinedToReturnValue(
      values.regular_place_recipient_number
    ),
    support_policy: undefinedToReturnValue(values.support_policy),
    whole_situation: undefinedToReturnValue(values.whole_situation),
    staff_comment: undefinedToReturnValue(values.staff_comment),
    type_consultation: values.type_consultation,
    details: MonitoringReportDetailsValues(values.details)
  };
};

// 読み込み元に値があれば使用する
const createLoadedValue = (
  copy:
    | ConsultationRecordState["supportPlanConsultationDetails"][0]
    | null
    | undefined,
  value: MonitoringReportValues["details"][0] | undefined,
  key: string
): string => {
  if (copy) {
    return copy[key] || "";
  }
  if (value) {
    return value[key] || "";
  }
  return "";
};

// 優先順位カードの読み込み
const loadDetails = (
  detailsValues: MonitoringReportValues["details"],
  copyDetailsValues: ConsultationRecordState["supportPlanConsultationDetails"]
): MonitoringReportValues["details"] => {
  const filteredDetailsValues = detailsValues.filter(
    (value) => !value.is_delete
  );
  // 最終的に生成される配列の要素数
  const loadedLength = Math.max(
    filteredDetailsValues.length,
    copyDetailsValues.length
  );

  // 値が入っている読み込み先の優先順位のみ抽出
  const copyValues = copyDetailsValues.map((copy) => {
    // id以外空値か
    const isEmptyValue = Object.values(copy)
      .slice(1)
      .every((item) => !item);
    return isEmptyValue ? null : copy;
  });

  const details = Array.from(new Array(loadedLength)).map((_, i) => {
    const value = filteredDetailsValues[i];
    const copy:
      | ConsultationRecordState["supportPlanConsultationDetails"][0]
      | null = copyValues[i];
    return {
      id: value ? value.id : null,
      support_goal: createLoadedValue(copy, value, "support_goal"),
      achievement_time: createLoadedValue(copy, value, "achievement_time"),
      service_provision_status: value ? value.service_provision_status : "",
      personal_satisfaction: value ? value.personal_satisfaction : "",
      achievement_support_goals: value ? value.achievement_support_goals : "",
      future_issues: value ? value.future_issues : "",
      change_plan_service_type: value ? value.change_plan_service_type : "",
      change_plan_service_volume: value ? value.change_plan_service_volume : "",
      change_plan_weekly: value ? value.change_plan_weekly : "",
      other_considerations: value ? value.other_considerations : "",
      is_delete: 0
    };
  });
  return details;
};

// 計画の詳細読み込み
export const loadValuesDetails = (
  values: MonitoringReportValues,
  copyValues: ConsultationRecordState
): MonitoringReportValues => {
  return {
    id: values.id,
    created_at: values.created_at,
    updated_at: values.updated_at,
    guardian_name: values.guardian_name,
    guardian_relation: values.guardian_relation,
    date_of_monitoring: values.date_of_monitoring,
    creation_date: values.creation_date,
    author: values.author,
    classify_disability_support: values.classify_disability_support,
    income_kind: values.income_kind,
    income_category: values.income_category,
    disabled_welfare_service_recipient_number:
      values.disabled_welfare_service_recipient_number,
    consultation_support_recipient_number:
      values.consultation_support_recipient_number,
    regular_place_recipient_number: values.regular_place_recipient_number,
    support_policy: copyValues.supportPolicy,
    whole_situation: values.whole_situation,
    staff_comment: values.staff_comment,
    type_consultation: values.type_consultation,
    details: loadDetails(
      values.details,
      copyValues.supportPlanConsultationDetails
    )
  };
};

// 支援目標・達成時期でどちらか一つでも入力されている場合,true
export const isDetailUpdate = (values: MonitoringReportValues): boolean => {
  let result = false;
  if (values.support_policy !== "") {
    result = true;
  }

  const filteredDetailsValues = values.details.filter(
    (value) => !value.is_delete
  );

  // 支援目標・達成時期が入力されているか確認
  const isPriorityUpdate =
    filteredDetailsValues.length !== 0 &&
    filteredDetailsValues.some((detail) => {
      return (
        detail.support_goal.trim() !== "" ||
        detail.achievement_time.trim() !== ""
      );
    });

  if (isPriorityUpdate) {
    result = true;
  }

  return result;
};
