import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import {
  OperationsAndSupportsState,
  SupportState
} from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import { SimplePaper } from "@components/organisms/mgr/common/record/SimplePaper";
import { SearchUsersForm } from "@components/organisms/mgr/Cseg/record/operationAndSupports/SearchUsersForm";
import { dateInYYYYMMDDFormat } from "@utils/date";
import { Pagination } from "@components/molecules/Pagination";
import { DailySupportTable } from "@components/organisms/mgr/Cseg/record/operationAndSupports/DailySupportTable";
import { EditType } from "@components/pages/mgr/Cseg/record/operationAndSupports/DailyRecord";
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";

const styles = (): StyleRules =>
  createStyles({
    searchUsersFormWrapper: {
      marginTop: 20
    },
    editSearch: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    resultLabel: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "rgba(0, 0, 0, 0.6)",
      letterSpacing: "0.4px",
      padding: "18px 0",
      paddingTop: "32px"
    },
    noResultBorder: {
      borderBottom: "1px solid #75757580"
    },
    noResultBody: {
      fontSize: "16px",
      lineHeight: "28px",
      alignItems: "center",
      letterSpacing: "0.5px",
      wordWrap: "break-word",
      padding: "80px 0",
      textAlign: "center"
    }
  });

type UserInFacility =
  | UsersInFacilityStateKEIKAKUSODAN
  | UsersInFacilityStateCHIIKITEICHAKU
  | UsersInFacilityStateCHIIKIIKO;

type OwnProps = {
  supportCount: OperationsAndSupportsState["support_count"];
  support: SupportState;
  users: UserInFacility["users"];
  yyyymmdd: string | undefined;
  fetchOperationsAndSupports: (
    yyyymmdd: string,
    page?: number,
    keyword?: string,
    isRecord?: number
  ) => void;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editType: string;
  setEditType: React.Dispatch<React.SetStateAction<EditType>>;
};

type Props = OwnProps & WithStyles<typeof styles>;

type fetchApiParams = {
  page: number;
  keyword?: string;
  isRecord?: number;
};

const DailySupportsRecordCore = (props: Props): JSX.Element => {
  const { classes, supportCount, support, users, yyyymmdd } = props;

  const [apiParams, setApiParams] = React.useState<fetchApiParams>({ page: 1 });

  const fetchApiCall = React.useCallback(
    (params: fetchApiParams): void => {
      const { page, keyword, isRecord } = params;
      const dateParam = yyyymmdd || dateInYYYYMMDDFormat();
      props.fetchOperationsAndSupports(dateParam, page, keyword, isRecord);
    },
    [yyyymmdd]
  );

  const handleSearchUsers = React.useCallback(
    (keyword: string | undefined, isRecord: number): void => {
      setApiParams({ page: 1, keyword, isRecord });
      fetchApiCall({ page: 1, keyword, isRecord });
    },
    [yyyymmdd]
  );

  const handlePageChange = React.useCallback(
    (data: { selected: number }): void => {
      const page = data.selected + 1;
      setApiParams((prev) => ({ ...prev, page }));
      fetchApiCall({ ...apiParams, page });
    },
    [apiParams]
  );

  const update = (): void => {
    fetchApiCall({ ...apiParams });
  };

  React.useEffect(() => {
    setApiParams({ page: 1 });
  }, [yyyymmdd]);

  return (
    <SimplePaper title="支援記録">
      <div className={props.isEditing ? classes.editSearch : ""}>
        <div className={classes.searchUsersFormWrapper}>
          <SearchUsersForm
            users={users}
            handleSearchUsers={handleSearchUsers}
          />
        </div>
        {supportCount > 20 && (
          <Pagination
            page={apiParams.page}
            dataLength={supportCount}
            handlePageChange={handlePageChange}
          />
        )}
      </div>
      {supportCount === 0 ? (
        <div>
          <div className={`${classes.resultLabel} ${classes.noResultBorder}`}>
            0人
          </div>
          <div className={classes.noResultBody}>
            条件に当てはまる利用者が見つかりませんでした。
            <br />
            条件を変更して再度絞り込みをしてください。
          </div>
        </div>
      ) : (
        <>
          {supportCount <= 20 && (
            <div className={classes.resultLabel}>{supportCount}人</div>
          )}
          <DailySupportTable
            supportList={support}
            users={users}
            targetDate={yyyymmdd || dateInYYYYMMDDFormat()}
            isEditing={props.isEditing}
            editType={props.editType}
            setIsEditing={props.setIsEditing}
            setEditType={props.setEditType}
            update={update}
          />
          {supportCount > 20 && (
            <div className={props.isEditing ? props.classes.editSearch : ""}>
              <Pagination
                page={apiParams.page}
                dataLength={supportCount}
                handlePageChange={handlePageChange}
                hideCountDisplay
              />
            </div>
          )}
        </>
      )}
    </SimplePaper>
  );
};

export const DailySupportsRecord = withStyles(styles)(DailySupportsRecordCore);
