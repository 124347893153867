import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import customRecordsApi from "@api/requests/customRecords";
import {
  CustomRecordsTableBodyValues,
  customRecordsValues
} from "@initialize/record/customRecord/initialValues";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";
import {
  normalizeCustomRecordsDataToAPI,
  normalizeCustomRecordsOrderDataToAPI
} from "@stores/domain/customRecords/normalizer";
import { HiddenChoiceState } from "@stores/domain/customRecords/types";
import dispatches from "@stores/dispatches";

export const fetchCustomRecords = (dispatch: Dispatch) => async (
  target?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCustomRecordsStarted());
  await customRecordsApi
    .getCustomRecords(target)
    .then((res) => {
      dispatch(actions.fetchCustomRecordsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 項目追加、編集
export const postCustomRecords = (dispatch: Dispatch) => async (
  param: customRecordsValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsStarted());
  const normalizedParam = normalizeCustomRecordsDataToAPI(param);
  await customRecordsApi
    .postCustomRecords(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 選択肢削除
export const postHiddenChoice = (dispatch: Dispatch) => async (
  param: HiddenChoiceState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postHiddenChoiceStarted());
  await customRecordsApi
    .postHiddenChoice(param)
    .then(() => {
      dispatch(actions.postHiddenChoiceSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postHiddenChoiceFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 並び順変更
export const postCustomRecordsOrder = (dispatch: Dispatch) => async (
  visibleCustomRecords: CustomRecordsTableBodyValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsOrderStarted());
  const normalizedParam = normalizeCustomRecordsOrderDataToAPI(
    visibleCustomRecords
  );
  await customRecordsApi
    .postCustomRecordsOrder(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsOrderSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsOrderFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const hideCustomRecord = (dispatch: Dispatch) => async (
  recordId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.HideCustomRecordStarted());
  const params: postCustomRecordsVisibilityParams = {
    custom_record_items: [
      {
        id: recordId,
        visibility: 0
      }
    ]
  };
  await customRecordsApi
    .postCustomRecordsVisibility(params)
    .then(() => {
      dispatch(actions.HideCustomRecordSuccess());
    })
    .catch((e) => {
      dispatch(actions.HideCustomRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const showCustomRecord = (dispatch: Dispatch) => async (
  recordIds: number[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.ShowCustomRecordStarted());
  const custom_record_items = recordIds.map((record) => {
    return {
      id: record,
      visibility: 1
    };
  });
  const params: postCustomRecordsVisibilityParams = {
    custom_record_items
  };
  await customRecordsApi
    .postCustomRecordsVisibility(params)
    .then(() => {
      dispatch(actions.ShowCustomRecordSuccess());
    })
    .catch((e) => {
      dispatch(actions.ShowCustomRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchSupportCustomRecords = (
  dispatch: Dispatch
) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportCustomRecordsStarted());
  await customRecordsApi
    .getCustomRecords(3)
    .then((res) => {
      dispatch(actions.fetchSupportCustomRecordsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchCustomRecords: (target?: number) => Promise<void>;
  fetchSupportCustomRecords: () => Promise<void>;
  postCustomRecords: (param: customRecordsValues) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsTableBodyValues
  ) => Promise<void>;
  postHiddenChoice: (param: HiddenChoiceState) => Promise<void>;
  hideCustomRecord: (recordId: number) => Promise<void>;
  showCustomRecord: (recordIds: number[]) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchCustomRecords: fetchCustomRecords(dispatch),
  fetchSupportCustomRecords: fetchSupportCustomRecords(dispatch),
  postCustomRecords: postCustomRecords(dispatch),
  postCustomRecordsOrder: postCustomRecordsOrder(dispatch),
  postHiddenChoice: postHiddenChoice(dispatch),
  hideCustomRecord: hideCustomRecord(dispatch),
  showCustomRecord: showCustomRecord(dispatch)
});
