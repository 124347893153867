export const REPEAT_DAILY = "reportDaily";
export const REPEAT_MONTHLY = "reportMonthly";

/**
 * ActionNames
 */
export const FETCH_IDOSHIEN_DAILY_STARTED = "IDOSHIEN/FETCH_DAILY_STARTED";
export const FETCH_IDOSHIEN_DAILY = "IDOSHIEN/FETCH_DAILY";
export const FETCH_IDOSHIEN_DAILY_FAILED = "IDOSHIEN/FETCH_FAILED";
export const FETCH_IDOSHIEN_MONTHLY_STARTED = "IDOSHIEN/FETCH_MONTHLY_STARTED";
export const FETCH_IDOSHIEN_MONTHLY = "IDOSHIEN/FETCH_MONTHLY";
export const FETCH_IDOSHIEN_MONTHLY_FAILED = "IDOSHIEN/FETCH_MONTHLY_FAILED";
export const POST_IDOSHIEN_REPORT_STARTED = "IDOSHIEN/POST_STARTED";
export const POST_IDOSHIEN_REPORT_DAILY = "IDOSHIEN/POST_REPORT_DAILY";
export const POST_IDOSHIEN_REPORT_MONTHLY = "IDOSHIEN/POST_REPORT_MONTHLY";
export const POST_IDOSHIEN_REPORT_FAILED = "IDOSHIEN/POST_REPORT_FAILED";
export const POST_IDOSHIEN_REPORT_COPY_STARTED =
  "IDOSHIEN/POST_REPORT_COPY_STARTED";
export const POST_IDOSHIEN_REPORT_COPY = "IDOSHIEN/POST_REPORT_COPY";
export const POST_IDOSHIEN_REPORT_COPY_FAILED =
  "IDOSHIEN/POST_REPORT_COPY_FAILED";
export const DELETE_IDOSHIEN_REPORT_STARTED = "IDOSHIEN/DELETE_REPORT_STARTED";
export const DELETE_IDOSHIEN_REPORT = "IDOSHIEN/DELETE_REPORT";
export const DELETE_IDOSHIEN_REPORT_FAILED = "IDOSHIEN/DELETE_REPORT_FAILED";
export const POST_IDOSHIEN_REPORT_COPY_CARE_PLAN_STARTED =
  "IDOSHIEN/POST_REPORT_COPY_CARE_PLAN_STARTED";
export const POST_IDOSHIEN_REPORT_COPY_CARE_PLAN =
  "IDOSHIEN/POST_REPORT_COPY_CARE_PLAN";
export const POST_IDOSHIEN_REPORT_COPY_CARE_PLAN_FAILED =
  "IDOSHIEN/POST_REPORT_COPY_CARE_PLAN_FAILED";

export type IDOSHIENReportTypeInterface = {
  type: "reportDaily" | "reportMonthly";
};

export type InoutResultsDetailsState = {
  id: number | null;
  actionClass: number | null;
  inTimeClass: string;
  inTime: string | null;
  outTimeClass: string;
  outTime: string | null;
};

export type InoutResultsState = {
  id: number | null;
  inoutResultDailyId: number | null;
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  targetDate: string;
  inputClass: number;
  inoutResultsPlanId: number | null;
  inoutResultsResultId: number | null;
  serviceDeliveryRecordsId: number | null;
  status: string;
  statusText: string;
  numberOfParticipants: number | null;
  practitioner1: number | null;
  practitioner1Staff: PractitionerStaffState | null;
  practitioner1InTime: string;
  practitioner1OutTimeClass: string;
  practitioner1OutTime: string;
  practitioner1CalculatedHours: number | null;
  practitioner1Memo: string | null;
  inoutResultsDetails1: InoutResultsDetailsState[];
  practitioner2: number | null;
  practitioner2Staff: PractitionerStaffState | null;
  practitioner2InTimeClass: string;
  practitioner2InTime: string;
  practitioner2OutTimeClass: string;
  practitioner2OutTime: string;
  practitioner2Memo: string | null;
  inoutResultsDetails2: InoutResultsDetailsState[];
  practitioner3Staff: PractitionerStaffState | null;
  practitioner4Staff: PractitionerStaffState | null;
  practitioner5Staff: PractitionerStaffState | null;
  timeDuplicationJudgmentFlg: boolean;
  wholeCalculatedHours: number | null;
  calculatedHoursDaytime: number | null;
  calculatedHoursNighttime: number | null;
  calculatedHoursLateNight: number | null;
  calculatedHoursEarlyMorning: number | null;
  duplicateCalculatedHours: number | null;
  duplicateCalculatedHoursDaytime: number | null;
  duplicateCalculatedHoursNighttime: number | null;
  duplicateCalculatedHoursLateNight: number | null;
  duplicateCalculatedHoursEarlyMorning: number | null;
  vehicleCalculatedHours: string;
  licenseSameFlg: boolean;
  secondPersonFlg: boolean;
  physicalCareFlg: boolean;
  numberOfSupporters: number | null;
  municipality: MunicipalityData;
  serviceCodes: ServiceCodesState[];
  resultExistFlg: boolean;
};

export type PractitionerStaffState = {
  id: number;
  name: string;
  facilityId: number;
  snapshotName: string;
};

export type MunicipalityData = {
  calculationTimeFlg: boolean;
  roundUpMinute: number | null;
  timeDivisionFlg: boolean;
};

export type ServiceCodesState = {
  serviceCodeInoutResultId: number;
  serviceCodeMunicipalityId: number;
  name: string;
  serviceCode: string;
  unitPrice: number;
};

export type DisplayListState = {
  usersInFacilityId: number;
  addIconFlg: boolean;
  nameSei: string;
  nameMei: string;
  isHoliday: boolean;
  targetDate: string;
  dataList: DataListState[];
};

export type DataListState = {
  no: number | null;
  resultExistFlg: boolean;
  plan: DisplayState;
  result: DisplayState;
  planDetail: InoutResultsState;
  resultDetail: InoutResultsState;
};

export type DisplayState = {
  inoutResultsId: number | null;
  dispatchNo: number | null;
  status: number | null;
  statusText: string | null;
  inTime: string | null;
  outTime: string | null;
  calculatedHours: number | null;
  gettingOnAndOff: number | null;
  numberOfParticipants: number | null;
  emergencySupportFlg?: number | null;
  serviceCodeFlg?: number | null;
  differenceFlg?: number | null;
};

export type StaffsInFacilityState = {
  id: number;
  name: string;
  role: string | null;
};

export type DisplayStaffsInFacilityState = {
  label: string;
  value: string;
};

export type ReportSummary = {
  payTimesIndividualSupport: number | null;
  payMonthsIndividualSupport: number | null;
  payTimesGroupSupport: number | null;
  payMonthsGroupSupport: number | null;
  payTimesVehicleTransferSupport: number | null;
  payMonthsVehicleTransferSupport: number | null;
  payTimesSchoolAndHospitalCommutingSupport: number | null;
  payMonthsSchoolAndHospitalCommutingSupport: number | null;
  payTimesOtherSupport: number | null;
  payMonthsOtherSupport: number | null;
};

export type ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: string | null;
    };
    staffsInFacility: StaffsInFacilityState[];
    displayStaffsInFacility: DisplayStaffsInFacilityState[];
    displayList: DisplayListState[];
    planCount: number;
  };
  reportMonthly: {
    summary: ReportSummary;
    staffsInFacility: StaffsInFacilityState[];
    displayStaffsInFacility: DisplayStaffsInFacilityState[];
    numberOfRecords: number;
    displayList: DisplayListState[];
    planCount: number;
  };
};

export type DeleteReportState = {
  uifId: number;
  targetDate: string;
  inoutResultsId: number | null;
  inputClass: number;
};

export type CopyReportState = {
  uifId: number;
  targetDate: string;
  inoutResultsId?: number | null;
};

export type CopyReportErrorState = {
  response: {
    code: number;
    msg: string;
  };
};

export type DetailTimeInfo = {
  time: number;
  inTime: string;
  inTimeClass: string;
  outTime: string;
  outTimeClass: string;
};

export type DetailTime = {
  inTime: string;
  inTimeClass: string;
  outTime: string;
  outTimeClass: string;
};

export type TimeRange = {
  inTime: string;
  outTime: string;
};

export type serviceCodesInfo = {
  serviceCodeInoutResultId: number | null;
  serviceCodeMunicipalityId: number | null;
  name: string;
  serviceCode: string;
  unitPrice: number | null;
  isDelete: boolean;
};

export type Timeframe = {
  // 日中：8:00~18:00
  daytime: number;
  // 夜間：18:00~22:00
  nighttime: number;
  // 深夜：22:00~6:00
  lateNight: number;
  // 早朝：6:00~8:00
  earlyMorning: number;
};

export type ServiceCodesFilter = {
  content: string;
  physicalCare: string;
  calculationHours: string;
  daytimeHours: string;
  nighttimeHours: string;
  lateNightHours: string;
  earlyMorningHours: string;
};
