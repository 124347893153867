import * as React from "react";
import Paper from "@material-ui/core/Paper";

type Props = React.Props<{}>;

export const CarePlanFormPaper = ({ children }: Props): JSX.Element => (
  <Paper component="section" elevation={0}>
    {children}
  </Paper>
);
