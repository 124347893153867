import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsGHState } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsSNState } from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/types";
import { AppState } from "@stores/type";

// store
import { connect } from "react-redux";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// ui
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { RecordDailyState } from "@stores/pages/record/daily/types";

type DailyOperationsAndSupportsState =
  | DailyOperationsAndSupportsGHState
  | DailyOperationsAndSupportsSNState;

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "16px 32px"
    },
    body: {
      marginTop: "16px"
    },
    summaryWrap: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    summarySpacing: {
      borderRadius: 4,
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "8px"
    },
    button: {
      minWidth: 142,
      minHeight: 30
    }
  });

type OwnProps = {
  summaries: DailyOperationsAndSupportsState["summary"];
  title: string;
  recordDaily?: RecordDailyState;
  isUnit?: boolean;
  handleClickDetail?: () => void;
};
type StateProps = {
  customRecords: CustomRecordsState;
};
type Props = OwnProps & StateProps & WithStyles<typeof styles>;

/**
 * 日ごとのサマリーを扱う
 */
const DailyRecordSummary = (props: Props): JSX.Element => {
  let summary: DailyOperationsAndSupportsState["summary"][number] | null = null;
  if (props.summaries.length > 0) {
    // facility_unit_idを元に、データを事業所全体とユニットとで分類する
    const summaries = props.summaries.reduce(
      (
        acc: {
          common: DailyOperationsAndSupportsState["summary"];
          unit: DailyOperationsAndSupportsState["summary"];
        },
        cur: DailyOperationsAndSupportsState["summary"][number]
      ) => {
        if (cur.facility_unit_id === 0) {
          return { ...acc, common: [...acc.common, cur] };
        }
        return { ...acc, unit: [...acc.unit, cur] };
      },
      { common: [], unit: [] }
    );
    summary = props.isUnit ? summaries.unit[0] : summaries.common[0];
  }

  // 「食事」項目
  const mealData =
    props.customRecords.length > 0 &&
    props.customRecords.find((record) => {
      return record.setting_type === 3 && record.default_item === 3;
    });

  const initialMeal = (mealData && {
    choices: mealData.choices.map((choice) => {
      return { id: choice.id, name: choice.name, count: 0 };
    })
  }) || { choices: [] };
  const initialCounts = {
    status_type: {
      stay: 0,
      hospitalization: 0,
      stay_away: 0,
      denominator: 0
    },
    meal: initialMeal
  };
  const { status_type, meal } = summary ? summary.counts : initialCounts;

  const isExistMealItem = meal.choices.some((choice) => choice.count !== 0);

  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <Typography
          variant="subtitle1"
          color="primary"
          className={props.classes.title}
        >
          {props.title}
        </Typography>
        <Divider variant="middle" className={props.classes.divider} />
        <div className={props.classes.body}>
          <div className={props.classes.summarySpacing}>
            <div className={props.classes.summaryWrap}>
              <div
                className={props.classes.summaryContent}
              >{`宿泊：${status_type.stay} 人`}</div>
              <div
                className={props.classes.summaryContent}
              >{`入院：${status_type.hospitalization} 人`}</div>
              <div
                className={props.classes.summaryContent}
              >{`外泊：${status_type.stay_away} 人`}</div>
              <div
                className={props.classes.summaryContent}
              >{`/ ${status_type.denominator} 人`}</div>
            </div>
            {props.handleClickDetail && (
              <KnowbeButton
                kind="outlineWhite"
                className={props.classes.button}
                onClick={props.handleClickDetail}
                disabled={props.recordDaily && props.recordDaily.isEditing}
              >
                利用状況の詳細
              </KnowbeButton>
            )}
          </div>

          {mealData && (mealData.visibility === 1 || isExistMealItem) ? (
            <div className={props.classes.summarySpacing}>
              <div className={props.classes.summaryWrap}>
                {mealData.choices &&
                  mealData.choices.map((item) => {
                    const choiceInputData =
                      meal.choices.length > 0 &&
                      meal.choices.find(
                        (choiceData) => choiceData.id === item.id
                      );
                    if (
                      !choiceInputData ||
                      (mealData.visibility === 0 &&
                        choiceInputData.count === 0) ||
                      (item.hidden === 1 && choiceInputData.count === 0)
                    ) {
                      return null;
                    }
                    return (
                      <div
                        className={props.classes.summaryContent}
                        key={choiceInputData.id}
                      >{`${item.name}：${choiceInputData.count} 人`}</div>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords
});

export default connect(mapStateToProps)(withStyles(styles)(DailyRecordSummary));
