import validator, {
  dateValidator,
  validateSwitcher,
  validateRuleSwitcher
} from "@validator";
import { Assessment } from "@initialize/record/assessment/initialValues";
import { BasicErrors } from "@interfaces/record/assessment/assessment";

export type AssessmentMaxLengthConfig = Partial<{
  lifeHistoriesMaxLength: number;
  disabilityHistoriesMaxLength: number;
}>;

type Option = {
  max200itemIds?: number[];
  max2500itemIds?: number[];
  maxLengthConfig?: AssessmentMaxLengthConfig;
};

const validateCustomRecordInput = (
  values: Assessment["assessment_records"],
  option?: Option
): BasicErrors["assessment_records"] => {
  const { lifeHistoriesMaxLength = 200, disabilityHistoriesMaxLength = 200 } =
    (option && option.maxLengthConfig) || {};
  return values.map((v) => {
    return {
      input: v.input.map((i) => {
        let inputDataMaxLength = 2500;
        // 特記事項はchoiced_item_idがない
        if (i.choiced_item_id !== undefined) {
          inputDataMaxLength = 200;
        }
        if (option && option.max200itemIds && option.max200itemIds.length) {
          if (option.max200itemIds.includes(i.custom_record_item_id)) {
            inputDataMaxLength = 200;
          }
        }
        // choiced_item_idとinput_dataがあり、且つmax2500文字のパターン
        if (option && option.max2500itemIds && option.max2500itemIds.length) {
          if (option.max2500itemIds.includes(i.custom_record_item_id)) {
            inputDataMaxLength = 2500;
          }
        }
        return {
          input_data: validator(i.input_data, {
            type: "checkCharacterLength",
            length: inputDataMaxLength
          }),
          multiTexts: i.multiTexts
            ? Object.keys(i.multiTexts).reduce((prev, key) => {
                if (!i.multiTexts) return prev;
                const targetValue = i.multiTexts[key];
                return {
                  ...prev,
                  [key]: {
                    value: validator(targetValue.value, {
                      type: "checkCharacterLength",
                      length: 200
                    })
                  }
                };
              }, {})
            : undefined,
          work_histories: i.work_histories
            ? i.work_histories.map((history) => {
                return {
                  begin_date: validateSwitcher(
                    history.begin_date.year !== "" ||
                      history.begin_date.month !== "",
                    dateValidator(
                      { ...history.begin_date, day: "1" },
                      {
                        type: "requiredDateAnyMessage",
                        message: "年月どちらも入力してください"
                      }
                    )
                  ),
                  end_date: validateSwitcher(
                    history.end_date.year !== "" ||
                      history.end_date.month !== "",
                    dateValidator(
                      { ...history.end_date, day: "2" },
                      {
                        type: "requiredDateAnyMessage",
                        message: "年月どちらも入力してください"
                      },
                      {
                        type: "withStartDate",
                        startDate: { ...history.begin_date, day: "1" },
                        options: {
                          startLabel: "開始年月"
                        }
                      },
                      {
                        type: "future",
                        startDate: { ...history.begin_date, day: "1" },
                        options: {
                          startLabel: "開始年月",
                          endLabel: "終了年月"
                        }
                      }
                    )
                  ),
                  facility_name: validator(history.facility_name, {
                    type: "checkCharacterLength",
                    length: 20
                  }),
                  details: validator(history.details, {
                    type: "checkCharacterLength",
                    length: 2500
                  })
                };
              })
            : undefined,
          evaluation_period: i.evaluation_period
            ? {
                start: {
                  date: validateSwitcher(
                    i.evaluation_period.start.date.year !== "" ||
                      i.evaluation_period.start.date.month !== "" ||
                      i.evaluation_period.start.date.day !== "",
                    dateValidator(i.evaluation_period.start.date, {
                      type: "requiredDateAnyMessage",
                      message: "必須です"
                    })
                  )
                },
                end: {
                  date: validateSwitcher(
                    i.evaluation_period.end.date.year !== "" ||
                      i.evaluation_period.end.date.month !== "" ||
                      i.evaluation_period.end.date.day !== "",
                    dateValidator(
                      i.evaluation_period.end.date,
                      {
                        type: "requiredDateAnyMessage",
                        message: "必須です"
                      },
                      {
                        type: "future",
                        startDate: i.evaluation_period.start.date,
                        options: {
                          startLabel: "開始年月日",
                          endLabel: "終了年月日"
                        }
                      }
                    )
                  )
                }
              }
            : undefined,
          // 本人の概要 > 生活歴
          life_histories: i.life_histories
            ? i.life_histories.map((lifeHistory) => {
                return {
                  yyyymm: validator(lifeHistory.yyyymm, {
                    type: "checkCharacterLength",
                    length: 20
                  }),
                  matter: validator(lifeHistory.matter, {
                    type: "checkCharacterLength",
                    length: lifeHistoriesMaxLength
                  })
                };
              })
            : undefined,
          // 本人の概要 > 障害・疾病歴
          disability_histories: i.disability_histories
            ? i.disability_histories.map((disabilityHistory) => {
                return {
                  yyyymm: validator(disabilityHistory.yyyymm, {
                    type: "checkCharacterLength",
                    length: 20
                  }),
                  matter: validator(disabilityHistory.matter, {
                    type: "checkCharacterLength",
                    length: disabilityHistoriesMaxLength
                  })
                };
              })
            : undefined,
          // 生活状況 > １日の流れ
          flow_days: i.flow_days
            ? i.flow_days.map((flowDay) => {
                const flowDayDetailsUser = flowDay.flow_day_details_user.map(
                  (detailUser) => {
                    return {
                      start_time: validator(
                        detailUser.start_time,
                        "checkTime",
                        validateRuleSwitcher(detailUser.end_time !== "", {
                          type: "requiredAnyMessage",
                          message: "開始終了どちらも入力してください"
                        })
                      ),
                      end_time: validator(
                        detailUser.end_time,
                        "checkTime",
                        validateRuleSwitcher(detailUser.start_time !== "", {
                          type: "requiredAnyMessage",
                          message: "開始終了どちらも入力してください"
                        }),
                        {
                          type: "checkTimeFuture",
                          startTime: detailUser.start_time
                        }
                      ),
                      matter: validator(detailUser.matter, {
                        type: "checkCharacterLength",
                        length: 200
                      })
                    };
                  }
                );
                const flowDayDetailsCaregiver = flowDay.flow_day_details_caregiver.map(
                  (detailCaregiver) => {
                    return {
                      start_time: validator(
                        detailCaregiver.start_time,
                        "checkTime",
                        validateRuleSwitcher(detailCaregiver.end_time !== "", {
                          type: "requiredAnyMessage",
                          message: "開始終了どちらも入力してください"
                        })
                      ),
                      end_time: validator(
                        detailCaregiver.end_time,
                        "checkTime",
                        validateRuleSwitcher(
                          detailCaregiver.start_time !== "",
                          {
                            type: "requiredAnyMessage",
                            message: "開始終了どちらも入力してください"
                          }
                        ),
                        {
                          type: "checkTimeFuture",
                          startTime: detailCaregiver.start_time
                        }
                      ),
                      matter: validator(detailCaregiver.matter, {
                        type: "checkCharacterLength",
                        length: 200
                      })
                    };
                  }
                );
                return {
                  title: validator(flowDay.title, {
                    type: "checkCharacterLength",
                    length: 20
                  }),
                  flow_day_details_user: flowDayDetailsUser,
                  flow_day_details_caregiver: flowDayDetailsCaregiver
                };
              })
            : undefined
        };
      })
    };
  });
};

export const validation = (
  values: Assessment,
  option?: Option
): BasicErrors => {
  return {
    target_date: dateValidator(values.target_date, "required"),
    assessment_records: validateCustomRecordInput(
      values.assessment_records,
      option
    )
  };
};
