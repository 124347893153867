import * as types from "./types";
import { GetFacilityMunicipalitiesResponse } from "@api/requests/facility/getFacilityMunicipalities";
import { GetFacilityMunicipalitiesTargetIdResponse } from "@api/requests/facility/getFacilityMunicipalitiesTargetId";
import { ResponseErrorState } from "@stores/ui/responseError/types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchStarted = () => ({ type: types.FETCH_STARTED } as const);
export const fetchSuccess = (res: GetFacilityMunicipalitiesResponse) =>
  ({ type: types.FETCH_SUCCESS, payload: res } as const);
export const fetchFailed = (err: { error: { response: ResponseErrorState } }) =>
  ({ type: types.FETCH_FAILED, error: err } as const);

export const fetchOneStarted = () =>
  ({ type: types.FETCH_ONE_STARTED } as const);
export const fetchOneSuccess = (
  res: GetFacilityMunicipalitiesTargetIdResponse
) => ({ type: types.FETCH_ONE_SUCCESS, payload: res } as const);
export const fetchOneFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.FETCH_ONE_FAILED, error: err } as const);

export const clearOne = () => ({ type: types.CLEAR_ONE } as const);

export const clear = () => ({ type: types.CLEAR } as const);

export const postMunicipalityStarted = () =>
  ({
    type: types.POST_MUNICIPALITY_STARTED
  } as const);
export const postMunicipalitySuccess = () =>
  ({
    type: types.POST_MUNICIPALITY_SUCCESS
  } as const);
export const postMunicipalityFailed = () =>
  ({
    type: types.POST_MUNICIPALITY_FAILED
  } as const);

export const fetchIDOSHIENServiceCodesSearchStarted = () =>
  ({ type: types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_STARTED } as const);
export const fetchIDOSHIENServiceCodesSearchSuccess = (
  res: types.ServiceCodesState
) =>
  ({
    type: types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_SUCCESS,
    payload: res
  } as const);
export const fetchIDOSHIENServiceCodesSearchFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_FAILED,
    error: err
  } as const);
export const fetchIDOSHIENServiceCodesPaginationStarted = () =>
  ({ type: types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_STARTED } as const);
export const fetchIDOSHIENServiceCodesPaginationSuccess = (
  res: types.ServiceCodesState
) =>
  ({
    type: types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_SUCCESS,
    payload: res
  } as const);
export const fetchIDOSHIENServiceCodesPaginationFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_FAILED,
    error: err
  } as const);

export const deleteMunicipalityStarted = () =>
  ({
    type: types.DELETE_MUNICIPALITY_STARTED
  } as const);
export const deleteMunicipalitySuccess = () =>
  ({
    type: types.DELETE_MUNICIPALITY_SUCCESS
  } as const);
export const deleteMunicipalityFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.DELETE_MUNICIPALITY_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchStarted>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof fetchFailed>
  | ReturnType<typeof fetchOneStarted>
  | ReturnType<typeof fetchOneSuccess>
  | ReturnType<typeof fetchOneFailed>
  | ReturnType<typeof clearOne>
  | ReturnType<typeof clear>
  | ReturnType<typeof postMunicipalityStarted>
  | ReturnType<typeof postMunicipalitySuccess>
  | ReturnType<typeof postMunicipalityFailed>
  | ReturnType<typeof fetchIDOSHIENServiceCodesSearchStarted>
  | ReturnType<typeof fetchIDOSHIENServiceCodesSearchSuccess>
  | ReturnType<typeof fetchIDOSHIENServiceCodesSearchFailed>
  | ReturnType<typeof fetchIDOSHIENServiceCodesPaginationStarted>
  | ReturnType<typeof fetchIDOSHIENServiceCodesPaginationSuccess>
  | ReturnType<typeof fetchIDOSHIENServiceCodesPaginationFailed>
  | ReturnType<typeof deleteMunicipalityStarted>
  | ReturnType<typeof deleteMunicipalitySuccess>
  | ReturnType<typeof deleteMunicipalityFailed>;
