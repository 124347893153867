import { SERVICE_STATUS } from "@constants/variables";

/**
 * サービス提供の状況のステータスコードをもとに、入所退所時刻を表示するか否かを判断する
 * @param status
 */
export const isDispInOutTime = (status: number): boolean => {
  return ![
    SERVICE_STATUS[0].value,
    SERVICE_STATUS[4].value,
    SERVICE_STATUS[6].value,
    SERVICE_STATUS[9].value
  ].includes(status);
};
