import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostMonitoringMonth = {
  monitoring_month: {
    id: number | null;
    year_month?: string;
    is_delete: number;
  }[];
};

/**
 * モニタリング実施月一覧のデータ保存
 * @param uifId 事業所所属ユーザーのID
 */
export const postMonitoringMonth = async (
  uifId: string,
  param: PostMonitoringMonth
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/monitoring_month`;
  return request.post(url, param);
};
