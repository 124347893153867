import * as types from "./types";
import * as actions from "./actions";
import { Checkbox, INT_FALSE_FROM_API } from "@constants/variables";

// first or reset values
const userDefaultValues: types.UsersInFacilityState["user"] = {
  user_in_facility: {
    none_recipient_number_flg: Checkbox.OFF,
    gender: "1",
    income_kind: "1",
    subsidized_flg: Checkbox.OFF,
    day_use_special_case_flg: Checkbox.OFF,
    subsidized_unit_flg: "1",
    uplimit_facility_flg: Checkbox.OFF,
    result_of_management: "1",
    create_support_plan_flg: Checkbox.ON,
    uplimit_controlled_by: "1",
    a_target_for_reduction_flg: Checkbox.OFF,
    def_record_work: Checkbox.OFF,
    ikou_cooperation_flg: INT_FALSE_FROM_API,
    ikou_cooperation_date: "",
    regional_collaboration_meeting_flg: INT_FALSE_FROM_API,
    regional_collaboration_meeting_start_date: ""
  },
  default_status_settings: [],
  work_details: {
    id: 0,
    work_time_use_flg: 0,
    work_time_use_flg_of_week: 0,
    work_truncate_minutes: null,
    work_start_time: "",
    work_end_time: ""
  },
  work_break_times: [],
  work_break_time_items: [],
  work_time_items: []
};

const initialState: types.UsersInFacilityState = {
  users: [],
  user: userDefaultValues,
  doneUpsertUser: false,
  userValidation: {}
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.UsersInFacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, users: action.payload.data };
    case types.FETCH_FAILED:
      return { ...state };
    case types.FETCH_ONE_STARTED:
      return { ...state };
    case types.FETCH_ONE_SUCCESS:
      return { ...state, user: action.payload };
    case types.FETCH_ONE_FAILED:
      return { ...state };
    case types.CREATE_STARTED:
      return { ...state };
    case types.CREATE_SUCCESS:
      return { ...state };
    case types.CREATE_FAILED:
      return { ...state };
    case types.UPDATE_STARTED:
      return { ...state };
    case types.UPDATE_SUCCESS:
      return { ...state };
    case types.UPDATE_FAILED:
      return { ...state };
    case types.CLEAR_STARTED:
      return { ...state };
    case types.CLEAR_SUCCESS:
      return { ...state };
    case types.CLEAR_FAILED:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
