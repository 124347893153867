import { getInOutConsultationResultsDaily } from "./getInOutConsultationResultsDaily";
import { getInOutConsultationResultsMonthly } from "./getInOutConsultationResultsMonthly";
import { postInOutConsultationResults } from "./postInOutConsultationResults";
import {
  postInOutConsultationResultsMonthly,
  postInOutResultsMonthly
} from "./postInOutConsultationResultsMonthly";
import { postFacilitiesMonthly } from "./postFacilityMonthly";

export const inOutConsultationResults = {
  getInOutConsultationResultsDaily,
  getInOutConsultationResultsMonthly,
  postInOutConsultationResults,
  postInOutConsultationResultsMonthly,
  postInOutResultsMonthly,
  postFacilitiesMonthly
};
