import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  FormLabel
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikTime from "@components/molecules/FormikTime";
import MuiTextField from "@components/molecules/MuiTextField";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/JUDOHOMONKAIGO/Users/initial";
import { FacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/types";
import { UnitsFields } from "@components/organisms/mgr/JUDOHOMONKAIGO/Users/dialog/UnitsFields";
import { DisplayStaffsInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/carePlan/types";
import { UsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import {
  JUDOHOMONKAIGO_STATUS_LIST,
  JUDOHOMONKAIGO_STATUS_SELECT_LIST,
  JUDOHOMONKAIGO_BASE_LIST,
  JUDOHOMONKAIGO_LICENSE_SELECT_LIST,
  JUDOHOMONKAIGO_MEMBER_LIST,
  JUDOHOMONKAIGO_MEMBER_SELECT_LIST,
  JUDOHOMONKAIGO_LICENSE_LIST,
  JUDOHOMONKAIGO_ACTION_CLASS_LIST,
  STANDARD_TIME_VALUE,
  MAX_TIME_VALUE,
  MAX_MOVING_TIME,
  TIME_CLASS_LIST,
  OUT_TIME_VALIDATION_LIST,
  IN_TIME_VALIDATION_LIST,
  ACCOMPANY_SUPPORT_IN_TIME_VALIDATION_LIST,
  ACCOMPANY_SUPPORT_OUT_TIME_VALIDATION_LIST,
  TIME_MIN_VALUE
} from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";
import { calculateHours } from "@utils/domain/mgr/calculateHours";
import { calculateOverlapTime } from "@utils/domain/mgr/calculateOverlapTime";
import { calculateTotalTime } from "@utils/domain/mgr/calculateTotalTime";
import { isWithinRange } from "@utils/domain/mgr/isWithinRange";
import { formatTime } from "@utils/date/formatTime";
import FormHelperText from "@material-ui/core/FormHelperText";

const styles = (): StyleRules =>
  createStyles({
    worker_license: {
      marginBottom: 0
    },
    categoryGroup: {
      paddingLeft: 32
    },
    categoryGroupBorder: {
      borderTop: "solid 1px #cfd8dc"
    },
    categoryLabel: {
      paddingTop: 24,
      paddingBottom: 24
    },
    addButton: {
      justifyContent: "left"
    },
    displayHidden: {
      "& > :first-child": {
        height: 0,
        "&:before": {
          content: "''",
          borderBottom: "none"
        },
        "&:after": {
          content: "''"
        }
      },
      "& > p": {
        paddingBottom: 16
      }
    },
    calculatedHours: {
      "& > label": {
        width: 180
      }
    },
    accompanySupport: {
      width: 142
    },
    disabledColor: {
      // disabledの色を上書き
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      },
      "& > div": {
        "& > input": {
          color: "rgba(0, 0, 0, 0.87)!important"
        },
        "& > div": {
          "& > p": {
            color: "rgba(0, 0, 0, 0.87)!important"
          }
        }
      }
    },
    disabledColorLabel: {
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      }
    },
    error: {
      "& > label": {
        color: "#f44336!important"
      }
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  facility: FacilityState;
  staffList: DisplayStaffsInFacilityState[];
  usersInFacility: UsersInFacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  staffList: FieldItem[];
  licenseList: FieldItem[];
  isPractitioner2: boolean;
  isStatusNone: boolean;
  isCheckboxDisplayNoneFlg: boolean;
  practitioner1InTime: string;
  practitioner1InTimeClass: string;
  practitioner1OutTime: string;
  practitioner1OutTimeClass: string;
  practitioner1DetailTimeList: DetailTimeInfo[];
  isAddButton1: boolean;
  practitioner2InTime: string;
  practitioner2InTimeClass: string;
  practitioner2OutTime: string;
  practitioner2OutTimeClass: string;
  practitioner2DetailTimeList: DetailTimeInfo[];
  isAddButton2: boolean;
  licenseSameFlg: boolean;
  statusList: FieldItem[];
  accompanySupportFlg: boolean;
  isDisableAccompanySupportFlg: boolean;
  secondPersonFlg: boolean;
  isDisplayAccompanySupportTime: boolean;
  accompanySupportInTime: string;
  accompanySupportInTimeClass: string;
  accompanySupportOutTime: string;
  accompanySupportOutTimeClass: string;
};

type DetailTimeInfo = {
  action: string;
  time: number;
  inTime: string;
  inTimeClass: string;
  outTime: string;
  outTimeClass: string;
};

type Props = StateProps & WithStyles<typeof styles>;

class CarePlanDialogFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { initial } = this.props.formikPropsValues.initialValues;
    this.state = {
      licenseList: JUDOHOMONKAIGO_LICENSE_SELECT_LIST,
      staffList: this.props.staffList
        ? [JUDOHOMONKAIGO_BASE_LIST.NONE].concat(this.props.staffList)
        : [JUDOHOMONKAIGO_BASE_LIST.NONE],
      isStatusNone: initial.status === JUDOHOMONKAIGO_STATUS_LIST.NONE.value,
      isCheckboxDisplayNoneFlg:
        initial.status === JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION.value ||
        initial.status ===
          JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION_LONG_TERM.value,
      isPractitioner2:
        initial.numberOfParticipants === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value,
      practitioner1InTime: initial.practitioner1InTime,
      practitioner1InTimeClass: initial.practitioner1InTimeClass,
      practitioner1OutTime: initial.practitioner1OutTime,
      practitioner1OutTimeClass: initial.practitioner1OutTimeClass,
      practitioner1DetailTimeList: initial.carePlanDetails1.map((row) => {
        return {
          action: row.actionClass,
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton1: initial.carePlanDetails1.length < 10,
      practitioner2InTime: initial.practitioner2InTime,
      practitioner2InTimeClass: initial.practitioner2InTimeClass,
      practitioner2OutTime: initial.practitioner2OutTime,
      practitioner2OutTimeClass: initial.practitioner2OutTimeClass,
      practitioner2DetailTimeList: initial.carePlanDetails2.map((row) => {
        return {
          action: row.actionClass,
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton2: initial.carePlanDetails2.length < 10,
      licenseSameFlg: initial.licenseSameFlg,
      statusList: JUDOHOMONKAIGO_STATUS_SELECT_LIST,
      accompanySupportFlg: initial.accompanySupportFlg,
      isDisableAccompanySupportFlg:
        !this.props.usersInFacility.user.user_in_facility_judohomonkaigo ||
        this.props.usersInFacility.user.user_in_facility_judohomonkaigo
          .approval_of_accompany_support_flg !== 1,
      secondPersonFlg: initial.secondPersonFlg,
      isDisplayAccompanySupportTime:
        initial.accompanySupportFlg && initial.secondPersonFlg,
      accompanySupportInTime: initial.accompanySupportInTime,
      accompanySupportInTimeClass: initial.accompanySupportInTimeClass,
      accompanySupportOutTime: initial.accompanySupportOutTime,
      accompanySupportOutTimeClass: initial.accompanySupportOutTimeClass
    };
  }

  private getInitDetailTime = (): DetailTimeInfo => {
    return {
      action: "1",
      time: 0,
      inTime: "",
      inTimeClass: "",
      outTime: "",
      outTimeClass: ""
    };
  };

  private onAddDetailRecord1 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner1DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner1DetailTimeList: list,
        isAddButton1: list.length < 10
      };
    });
  };

  private onAddDetailRecord2 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner2DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner2DetailTimeList: list,
        isAddButton2: list.length < 10
      };
    });
  };

  private onDeleteDetailRecord1 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner1DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner1DetailTimeList: list, isAddButton1: true };
      },
      () => {
        this.setPersonalCalculatedHours1();
        this.setCalculatedAccompanySupportHours();
        this.setAllCalculatedHours();
      }
    );
  };

  private onDeleteDetailRecord2 = (index: number): void => {
    this.state.practitioner2DetailTimeList.splice(index, 1);
    this.setAllCalculatedHours();
    this.setState({ isAddButton2: true });
  };

  private onChangeDetailRecord1 = (
    action: string,
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner1DetailTimeList: prevState.practitioner1DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              action,
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours1(
          this.state.practitioner1InTime,
          this.state.practitioner1InTimeClass,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass
        );
        this.setCalculatedAccompanySupportHours(
          this.state.accompanySupportInTime,
          this.state.accompanySupportInTimeClass,
          this.state.accompanySupportOutTime,
          this.state.accompanySupportOutTimeClass
        );
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList
        );
      }
    );
  };

  private onChangeDetailRecord2 = (
    action: string,
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner2DetailTimeList: prevState.practitioner2DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              action,
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList,
          this.state.practitioner2InTime,
          this.state.practitioner2InTimeClass,
          this.state.practitioner2OutTime,
          this.state.practitioner2OutTimeClass,
          this.state.practitioner2DetailTimeList
        );
      }
    );
  };

  private setPersonalCalculatedHours1 = (
    startTime: string = this.state.practitioner1InTime,
    inTimeClass: string = this.state.practitioner1InTimeClass,
    endTime: string = this.state.practitioner1OutTime,
    outTimeClass: string = this.state.practitioner1OutTimeClass
  ): void => {
    const inTime = this.getTime(startTime, inTimeClass);
    const outTime = this.getTime(endTime, outTimeClass);
    // 明細（空き）のレコードを取得
    const detail = this.state.practitioner1DetailTimeList
      .filter(
        (record) =>
          record.action !== JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value &&
          (isWithinRange(
            inTime,
            outTime,
            this.getTime(record.inTime, record.inTimeClass)
          ) ||
            isWithinRange(
              inTime,
              outTime,
              this.getTime(record.outTime, record.outTimeClass)
            ))
      )
      .map((record) => {
        // 一部分収まってる（片方の時間のみ）場合の対応
        const data = calculateOverlapTime(
          {
            inTime: this.getTime(record.inTime, record.inTimeClass),
            outTime: this.getTime(record.outTime, record.outTimeClass)
          },
          [{ inTime, outTime }]
        );
        return {
          action: record.action,
          inTime: data[0].inTime,
          inTimeClass: "0",
          outTime: data[0].outTime,
          outTimeClass: "0",
          time: calculateForRequiredTime(
            data[0].inTime,
            "0",
            data[0].outTime,
            "0"
          )
        };
      });

    // 算定時間
    this.setPersonalCalculatedHours(
      "initial.practitioner1CalculatedHours",
      startTime,
      inTimeClass,
      endTime,
      outTimeClass,
      detail
    );

    // 移動時間(明細リストから移動時間のみ選択して計算)
    const movingData = this.state.practitioner1DetailTimeList
      .filter(
        (record) =>
          record.action === JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value
      )
      .reduce(
        (sum, record) =>
          sum +
          calculateForRequiredTime(
            record.inTime,
            record.inTimeClass,
            record.outTime,
            record.outTimeClass
          ),
        0
      );

    let movingHour = calculateHours(movingData, STANDARD_TIME_VALUE, 0);
    if (movingHour > MAX_TIME_VALUE) {
      movingHour = MAX_MOVING_TIME;
    }

    this.props.setFormikFieldValue(
      "initial.practitioner1CalculatedMovingHours",
      Number.isNaN(movingHour) ? "" : Math.min(movingHour, MAX_MOVING_TIME)
    );
  };

  private isWithinEqualityOperatorRange = (
    inTime: string,
    inTimeClass: string,
    outTime: string,
    outTimeClass: string,
    targetTime: string,
    targetTimeClass: string
  ): boolean => {
    const start = Number(this.getTime(inTime, inTimeClass).split(":").join(""));
    const end = Number(this.getTime(outTime, outTimeClass).split(":").join(""));
    const target = Number(
      this.getTime(targetTime, targetTimeClass).split(":").join("")
    );

    return start <= target && target <= end;
  };

  private calculateEqualityOperatorOverlapTime = (
    item: {
      inTime: string;
      inTimeClass: string;
      outTime: string;
      outTimeClass: string;
    },
    list: {
      inTime: string;
      inTimeClass: string;
      outTime: string;
      outTimeClass: string;
    }[]
  ): { inTime: string; outTime: string }[] => {
    const result: { inTime: string; outTime: string }[] = [];

    list.forEach((record) => {
      // 時間かぶっているかチェック
      const start1 = this.isWithinEqualityOperatorRange(
        item.inTime,
        item.inTimeClass,
        item.outTime,
        item.outTimeClass,
        record.inTime,
        record.inTimeClass
      );
      const end1 = this.isWithinEqualityOperatorRange(
        item.inTime,
        item.inTimeClass,
        item.outTime,
        item.outTimeClass,
        record.outTime,
        record.outTimeClass
      );
      const start2 = this.isWithinEqualityOperatorRange(
        record.inTime,
        record.inTimeClass,
        record.outTime,
        record.outTimeClass,
        item.inTime,
        item.inTimeClass
      );
      const end2 = this.isWithinEqualityOperatorRange(
        record.inTime,
        record.inTimeClass,
        record.outTime,
        record.outTimeClass,
        item.outTime,
        item.outTimeClass
      );
      const itemIntime = this.getTime(item.inTime, item.inTimeClass);
      const itemOuttime = this.getTime(item.outTime, item.outTimeClass);
      const recordIntime = this.getTime(record.inTime, record.inTimeClass);
      const recordOuttime = this.getTime(record.outTime, record.outTimeClass);
      const equal =
        itemIntime === recordIntime && itemOuttime === recordOuttime;

      if (start1 || end1 || start2 || end2 || equal) {
        result.push({
          inTime: start1 ? recordIntime : itemIntime,
          outTime: end1 ? recordOuttime : itemOuttime
        });
      }
    });

    return result;
  };

  private setCalculatedAccompanySupportHours = (
    accompanySupportInTime: string = this.state.accompanySupportInTime,
    inTimeClass: string = this.state.accompanySupportInTimeClass,
    accompanySupportOutTime: string = this.state.accompanySupportOutTime,
    outTimeClass: string = this.state.accompanySupportOutTimeClass
  ): void => {
    const inTime = this.getTime(accompanySupportInTime, inTimeClass);
    const outTime = this.getTime(accompanySupportOutTime, outTimeClass);
    // 同行支援時間に収まっている、明細（空き）のレコードを取得
    const detail = this.state.practitioner1DetailTimeList
      .filter(
        (record) =>
          record.action !== JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value &&
          (isWithinRange(
            inTime,
            outTime,
            this.getTime(record.inTime, record.inTimeClass)
          ) ||
            isWithinRange(
              inTime,
              outTime,
              this.getTime(record.outTime, record.outTimeClass)
            ))
      )
      .map((record) => {
        // 一部分収まってる（片方の時間のみ）場合の対応
        const data = calculateOverlapTime(
          {
            inTime: this.getTime(record.inTime, record.inTimeClass),
            outTime: this.getTime(record.outTime, record.outTimeClass)
          },
          [{ inTime, outTime }]
        );
        return {
          action: record.action,
          inTime: data[0].inTime,
          inTimeClass: "0",
          outTime: data[0].outTime,
          outTimeClass: "0",
          time: calculateForRequiredTime(
            data[0].inTime,
            "0",
            data[0].outTime,
            "0"
          )
        };
      });
    // 同行支援時間に収まっている、明細（移動）のレコードを取得
    const movingData = this.calcAllCalculateTime(
      this.state.practitioner1DetailTimeList
        .filter(
          (record) =>
            record.action === JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value &&
            (this.isWithinEqualityOperatorRange(
              inTime,
              inTimeClass,
              outTime,
              outTimeClass,
              record.inTime,
              record.inTimeClass
            ) ||
              this.isWithinEqualityOperatorRange(
                inTime,
                inTimeClass,
                outTime,
                outTimeClass,
                record.outTime,
                record.outTimeClass
              ))
        )
        .map((record) => {
          // 一部分収まってる（片方の時間のみ）場合の対応
          const data = this.calculateEqualityOperatorOverlapTime(
            {
              inTime: record.inTime,
              inTimeClass: record.inTimeClass,
              outTime: record.outTime,
              outTimeClass: record.outTimeClass
            },
            [{ inTime, inTimeClass, outTime, outTimeClass }]
          );
          return {
            action: record.action,
            inTime: data[0].inTime,
            outTime: data[0].outTime,
            time: calculateForRequiredTime(
              data[0].inTime,
              "0",
              data[0].outTime,
              "0"
            )
          };
        })
    );
    // 移動時間内に同行支援時間がおさまっている、明細（移動）のレコードを取得
    const AccompanyMovingData = this.calcAllCalculateTime(
      this.state.practitioner1DetailTimeList
        .filter(
          (record) =>
            record.action === JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value &&
            isWithinRange(
              this.getTime(record.inTime, record.inTimeClass),
              this.getTime(record.outTime, record.outTimeClass),
              inTime
            ) &&
            isWithinRange(
              this.getTime(record.inTime, record.inTimeClass),
              this.getTime(record.outTime, record.outTimeClass),
              outTime
            )
        )
        .map((record) => {
          return {
            action: record.action,
            inTime: this.getTime(record.inTime, record.inTimeClass),
            outTime: this.getTime(record.outTime, record.outTimeClass),
            time: calculateForRequiredTime(
              record.inTime,
              record.inTimeClass,
              record.outTime,
              record.outTimeClass
            )
          };
        })
    );
    // 同行支援時間の設定
    this.setPersonalCalculatedHours(
      "initial.wholeCalculatedAccompanySupportHours",
      accompanySupportInTime,
      inTimeClass,
      accompanySupportOutTime,
      outTimeClass,
      detail
    );
    // 同行支援移動時間の設定
    if (AccompanyMovingData && AccompanyMovingData.whole === 0) {
      let wholeCalculatedAccompanySupportMovingHours = calculateHours(
        movingData.whole,
        STANDARD_TIME_VALUE,
        0
      );
      if (wholeCalculatedAccompanySupportMovingHours > MAX_TIME_VALUE) {
        wholeCalculatedAccompanySupportMovingHours = MAX_MOVING_TIME;
      }

      this.props.setFormikFieldValue(
        "initial.wholeCalculatedAccompanySupportMovingHours",
        Number.isNaN(movingData.whole)
          ? ""
          : Math.min(
              wholeCalculatedAccompanySupportMovingHours,
              MAX_MOVING_TIME
            )
      );
    } else {
      let wholeCalculatedAccompanySupportMovingHours = calculateHours(
        AccompanyMovingData.whole,
        STANDARD_TIME_VALUE,
        0
      );
      if (wholeCalculatedAccompanySupportMovingHours > MAX_TIME_VALUE) {
        wholeCalculatedAccompanySupportMovingHours = MAX_MOVING_TIME;
      }

      this.props.setFormikFieldValue(
        "initial.wholeCalculatedAccompanySupportMovingHours",
        Number.isNaN(AccompanyMovingData.whole)
          ? ""
          : Math.min(
              wholeCalculatedAccompanySupportMovingHours,
              MAX_MOVING_TIME
            )
      );
    }
  };

  private setPersonalCalculatedHours = (
    target: string,
    inTime: string,
    inTimeClass: string,
    outTime: string,
    outTimeClass: string,
    otherTimeList: DetailTimeInfo[]
  ): void => {
    const baseTime = calculateForRequiredTime(
      inTime,
      inTimeClass,
      outTime,
      outTimeClass
    );
    const otherTime = otherTimeList.reduce((sum, value) => {
      return sum + value.time;
    }, 0);
    let result = calculateHours(
      baseTime,
      STANDARD_TIME_VALUE,
      otherTime,
      STANDARD_TIME_VALUE,
      TIME_MIN_VALUE,
      true
    );
    if (result === STANDARD_TIME_VALUE) {
      result = 0;
    }
    this.props.setFormikFieldValue(target, Number.isNaN(result) ? "" : result);
  };

  private handleChangeAccompanySupportTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    let start = this.state.accompanySupportInTime;
    let startClass = this.state.accompanySupportInTimeClass;
    let end = this.state.accompanySupportOutTime;
    let endClass = this.state.accompanySupportOutTimeClass;
    switch (event.target.name) {
      case "initial.accompanySupportInTime":
        start = formatTime(event.target.value);
        this.setState({ accompanySupportInTime: start });
        break;
      case "initial.accompanySupportInTimeClass":
        startClass = event.target.value;
        this.setState({ accompanySupportInTimeClass: startClass });
        break;
      case "initial.accompanySupportOutTime":
        end = formatTime(event.target.value);
        this.setState({ accompanySupportOutTime: end });
        break;
      case "initial.accompanySupportOutTimeClass":
        endClass = event.target.value;
        this.setState({ accompanySupportOutTimeClass: endClass });
        break;
      default:
    }
    this.setCalculatedAccompanySupportHours(start, startClass, end, endClass);
  };

  private handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    let start1 = this.state.practitioner1InTime;
    const start1Class = this.state.practitioner1InTimeClass;
    let end1 = this.state.practitioner1OutTime;
    let end1Class = this.state.practitioner1OutTimeClass;
    let start2 = this.state.practitioner2InTime;
    let start2Class = this.state.practitioner2InTimeClass;
    let end2 = this.state.practitioner2OutTime;
    let end2Class = this.state.practitioner2OutTimeClass;
    switch (event.target.name) {
      case "initial.practitioner1InTime":
        start1 = formatTime(event.target.value);
        this.setState({ practitioner1InTime: start1 });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner1OutTime":
        end1 = formatTime(event.target.value);
        this.setState({ practitioner1OutTime: end1 });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner1OutTimeClass":
        end1Class = event.target.value;
        this.setState({
          practitioner1OutTimeClass: end1Class
        });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner2InTime":
        start2 = formatTime(event.target.value);
        this.setState({ practitioner2InTime: start2 });
        break;
      case "initial.practitioner2InTimeClass":
        start2Class = event.target.value;
        this.setState({
          practitioner2InTimeClass: start2Class
        });
        break;
      case "initial.practitioner2OutTime":
        end2 = formatTime(event.target.value);
        this.setState({ practitioner2OutTime: end2 });
        break;
      case "initial.practitioner2OutTimeClass":
        end2Class = event.target.value;
        this.setState({
          practitioner2OutTimeClass: end2Class
        });
        break;
      default:
    }

    // 合算算定（全体/重複）
    this.setAllCalculatedHours(
      start1,
      end1,
      end1Class,
      this.state.practitioner1DetailTimeList,
      start2,
      start2Class,
      end2,
      end2Class,
      this.state.practitioner2DetailTimeList
    );
  };

  private setAllCalculatedHours = (
    baseInTime1 = this.state.practitioner1InTime,
    baseOutTime1 = this.state.practitioner1OutTime,
    baseOutTime1Class = this.state.practitioner1OutTimeClass,
    baseDetail1 = this.state.practitioner1DetailTimeList,
    baseInTime2 = this.state.practitioner2InTime,
    baseInTime2Class = this.state.practitioner2InTimeClass,
    baseOutTime2 = this.state.practitioner2OutTime,
    baseOutTime2Class = this.state.practitioner2OutTimeClass,
    baseDetail2 = this.state.practitioner2DetailTimeList,
    sameFlg = this.state.licenseSameFlg
  ): void => {
    const outTime1 = this.getTime(baseOutTime1, baseOutTime1Class);
    const detail1 = baseDetail1.map((record) => this.getDetailTime(record));

    // 同時実行ONの場合、提供者②は提供者①のコピーとする
    const inTime2 = !sameFlg
      ? this.getTime(baseInTime2, baseInTime2Class)
      : baseInTime1;
    const outTime2 = !sameFlg
      ? this.getTime(baseOutTime2, baseOutTime2Class)
      : outTime1;
    const detail2 = !sameFlg
      ? baseDetail2.map((record) => this.getDetailTime(record))
      : detail1.slice();

    // 提供時間の計算
    const baseData = this.calcAllCalculateTime([
      { inTime: baseInTime1, outTime: outTime1 },
      { inTime: inTime2, outTime: outTime2 }
    ]);
    // 空き時間の計算(明細リストから移動時間を除外して計算)
    // （・全体 = 全体の提供時間 - 明細1 - 明細3）
    // （・重複 = 重複の提供時間 - 明細2）
    // 1.提供時間を重複範囲とそれ以外に分割
    const data = calculateOverlapTime(
      { inTime: baseInTime1, outTime: outTime1 },
      [{ inTime: inTime2, outTime: outTime2 }]
    );

    // 2.空き時間を、重複範囲内か外かでグループ分け（重複の境を跨ぐ場合は、分割）
    const detail =
      data && data.length > 0
        ? this.calcDetailCalculateTime(
            data[0],
            detail1
              .concat(detail2)
              .filter(
                (record) =>
                  record.actionClass !==
                  JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value
              )
          )
        : {
            total: [{ inTime: "", outTime: "" }],
            duplicate: [{ inTime: "", outTime: "" }]
          };
    // 3.重複範囲外の空き時間を計算（→明細1)
    const subtractedTotalData = this.calcAllCalculateTime(
      detail.total.slice(1)
    );
    // 4.重複範囲内の空き時間（全体・重複）を計算（→明細2・明細3)
    const subtractedDuplicateData = this.calcAllCalculateTime(
      detail.duplicate.slice(1)
    );
    // 移動時間の計算(明細リストから移動時間のみ選択して計算)
    const movingData = this.calcAllCalculateTime(
      detail1
        .concat(detail2)
        .filter(
          (record) =>
            record.actionClass === JUDOHOMONKAIGO_ACTION_CLASS_LIST.GUIDE.value
        )
    );

    let wholeData = calculateHours(
      baseData.whole,
      STANDARD_TIME_VALUE,
      subtractedTotalData.whole + subtractedDuplicateData.duplicate,
      STANDARD_TIME_VALUE,
      TIME_MIN_VALUE,
      true
    );
    if (wholeData === STANDARD_TIME_VALUE) {
      wholeData = 0;
    }

    let duplicateData = sameFlg
      ? wholeData
      : calculateHours(
          baseData.duplicate,
          STANDARD_TIME_VALUE,
          subtractedDuplicateData.whole,
          STANDARD_TIME_VALUE,
          TIME_MIN_VALUE,
          true
        );
    if (duplicateData === STANDARD_TIME_VALUE) {
      duplicateData = 0;
    }

    let wholeMovingData = calculateHours(
      movingData.whole,
      STANDARD_TIME_VALUE,
      0
    );
    if (wholeMovingData > MAX_TIME_VALUE) {
      wholeMovingData = MAX_MOVING_TIME;
    }

    let duplicateMovingData = sameFlg
      ? movingData.duplicate
      : calculateHours(movingData.duplicate, STANDARD_TIME_VALUE, 0);
    if (duplicateMovingData > MAX_TIME_VALUE) {
      duplicateMovingData = MAX_MOVING_TIME;
    }

    // 算定時間（全体）のセット
    this.props.setFormikFieldValue(
      "initial.wholeCalculatedHours",
      Number.isNaN(wholeData) ? "" : wholeData
    );
    // 算定時間（重複）のセット
    this.props.setFormikFieldValue(
      "initial.duplicateCalculatedHours",
      Number.isNaN(duplicateData) ? "" : duplicateData
    );
    // 移動時間（全体）のセット(最大4時間)
    this.props.setFormikFieldValue(
      "initial.wholeCalculatedMovingHours",
      Number.isNaN(wholeMovingData)
        ? ""
        : Math.min(wholeMovingData, MAX_MOVING_TIME)
    );
    // 移動時間（重複）のセット(最大4時間)
    this.props.setFormikFieldValue(
      "initial.duplicateCalculatedMovingHours",
      Number.isNaN(duplicateMovingData)
        ? ""
        : Math.min(duplicateMovingData, MAX_MOVING_TIME)
    );
  };

  private calcAllCalculateTime = (
    list: { inTime: string; outTime: string }[]
  ): { whole: number; duplicate: number } => {
    let totalList = list.slice();
    let target = totalList.shift();
    if (!target) {
      return { whole: 0, duplicate: 0 };
    }

    // [全体]の時間を算出
    let whole = 0;
    while (target) {
      const data = calculateTotalTime(target, totalList);
      whole += calculateForRequiredTime(
        data.result.inTime,
        "0",
        data.result.outTime,
        "0"
      );

      target = data.targetList.shift();
      totalList = data.targetList;
    }

    // [重複]の時間を算出
    // 1. 重複対象のデータ取得
    // 2. 1次元配列に変換
    // 3. 重複削除
    // 4. 算定用の時間計算
    let duplicate = 0;
    Array.from(
      new Map(
        list
          .map((data, idx) =>
            calculateOverlapTime(
              data,
              list.filter((record, i) => i !== idx)
            )
          )
          .reduce((pre, current) => pre.concat(current), [])
          .map((data) => [`${data.inTime}${data.outTime}`, data])
      ).values()
    ).forEach((record) => {
      duplicate += calculateForRequiredTime(
        record.inTime,
        "0",
        record.outTime,
        "0"
      );
    });

    return { whole, duplicate };
  };

  private calcDetailCalculateTime = (
    range: { inTime: string; outTime: string },
    list: { inTime: string; outTime: string }[]
  ): {
    total: { inTime: string; outTime: string }[];
    duplicate: { inTime: string; outTime: string }[];
  } => {
    return list.reduce(
      (prev, record) => {
        const start1 = Number(range.inTime.split(":").join(""));
        const start2 = Number(record.inTime.split(":").join(""));
        const end1 = Number(range.outTime.split(":").join(""));
        const end2 = Number(record.outTime.split(":").join(""));

        const start =
          isWithinRange(range.inTime, range.outTime, record.inTime) ||
          start1 === start2;
        const end =
          isWithinRange(range.inTime, range.outTime, record.outTime) ||
          end1 === end2;
        const outRangeFlg = !isWithinRange(
          record.inTime,
          record.outTime,
          range.inTime
        );

        // ケース1（重複範囲内）
        if (start && end) {
          return {
            total: prev.total,
            duplicate: prev.duplicate.concat(record)
          };
        }

        // ケース2（重複範囲Fromをまたぐ）
        if (!start && end) {
          return {
            total: prev.total.concat({
              inTime: record.inTime,
              outTime: range.inTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: range.inTime,
              outTime: record.outTime
            })
          };
        }

        // ケース3（重複範囲toをまたぐ）
        if (start && !end) {
          return {
            total: prev.total.concat({
              inTime: range.outTime,
              outTime: record.outTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: record.inTime,
              outTime: range.outTime
            })
          };
        }

        // ケース4（重複範囲外）
        if (!start && !end && outRangeFlg) {
          return {
            total: prev.total.concat(record),
            duplicate: prev.duplicate
          };
        }

        // ケース5（重複範囲全体をまたぐ）
        return {
          total: prev.total.concat([
            { inTime: record.inTime, outTime: range.inTime },
            { inTime: range.outTime, outTime: record.outTime }
          ]),
          duplicate: prev.duplicate.concat(range)
        };
      },
      {
        total: [{ inTime: "", outTime: "" }],
        duplicate: [{ inTime: "", outTime: "" }]
      }
    );
  };

  private getTime = (time: string, timeClass: string): string => {
    // 日跨ぎしている場合、時間に+24する
    const times = time.split(":");

    return timeClass === "1" ? `${Number(times[0]) + 24}:${times[1]}` : time;
  };

  private getDetailTime = (detail: {
    inTime: string;
    inTimeClass: string;
    outTime: string;
    outTimeClass: string;
    action: string;
  }): { inTime: string; outTime: string; actionClass: string } => {
    const inTime = this.getTime(detail.inTime, detail.inTimeClass);
    const outTime = this.getTime(detail.outTime, detail.outTimeClass);
    const actionClass = detail.action;

    return { inTime, outTime, actionClass };
  };

  private handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.changeStatus(e.target.value);
  };

  private changeStatus = (status: string): void => {
    const nextStatus = status;
    if (nextStatus === JUDOHOMONKAIGO_STATUS_LIST.NONE.value) {
      this.setState({ isStatusNone: true });
    } else {
      this.setState({ isStatusNone: false });
    }
    this.setState({
      isCheckboxDisplayNoneFlg:
        nextStatus === JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION.value ||
        nextStatus ===
          JUDOHOMONKAIGO_STATUS_LIST.HOSPITALIZATION_LONG_TERM.value
    });
    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2InTimeClass,
      this.state.practitioner2OutTime,
      this.state.practitioner2OutTimeClass,
      this.state.practitioner2DetailTimeList
    );
  };

  private onChangeLicense1 = (): void => {
    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours();
  };

  private onChangeLicense2 = (): void => {
    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours();
  };

  private onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.setFormikFieldValue(e.target.name, e.target.checked);
  };

  private onChangeAccompanySupportFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ accompanySupportFlg: e.target.checked });
    this.changeDisplayAccompanySupportTimeFlg(
      e.target.checked,
      this.state.secondPersonFlg
    );
    this.setPersonalCalculatedHours1();
    if (e.target.checked && this.state.isPractitioner2) {
      this.props.setFormikFieldValue(
        "initial.practitioner1License",
        JUDOHOMONKAIGO_LICENSE_LIST.EXPERT.value
      );
      this.props.setFormikFieldValue(
        "initial.practitioner2License",
        JUDOHOMONKAIGO_LICENSE_LIST.BEGINNER.value
      );
    } else {
      this.props.setFormikFieldValue(
        "initial.practitioner1License",
        JUDOHOMONKAIGO_LICENSE_LIST.NONE.value
      );
      this.props.setFormikFieldValue(
        "initial.practitioner2License",
        JUDOHOMONKAIGO_LICENSE_LIST.NONE.value
      );
    }
  };

  private onChangeSecondPersonFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ secondPersonFlg: e.target.checked });
    this.changeDisplayAccompanySupportTimeFlg(
      this.state.accompanySupportFlg,
      e.target.checked
    );
  };

  private changeDisplayAccompanySupportTimeFlg = (
    supportFlg: boolean,
    otherFlg: boolean
  ): void => {
    this.setState({ isDisplayAccompanySupportTime: supportFlg && otherFlg });
  };

  private onChangeLicenseSameFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ licenseSameFlg: e.target.checked });

    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2InTimeClass,
      this.state.practitioner2OutTime,
      this.state.practitioner2OutTimeClass,
      this.state.practitioner2DetailTimeList,
      e.target.checked
    );
  };

  private handleChangeNumberOfParticipants = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (
      this.state.accompanySupportFlg &&
      e.target.value === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value
    ) {
      this.props.setFormikFieldValue(
        "initial.practitioner1License",
        JUDOHOMONKAIGO_LICENSE_LIST.EXPERT.value
      );
      this.props.setFormikFieldValue(
        "initial.practitioner2License",
        JUDOHOMONKAIGO_LICENSE_LIST.BEGINNER.value
      );
    } else if (this.state.isPractitioner2) {
      this.props.setFormikFieldValue(
        "initial.practitioner1License",
        JUDOHOMONKAIGO_LICENSE_LIST.NONE.value
      );
      this.props.setFormikFieldValue(
        "initial.practitioner2License",
        JUDOHOMONKAIGO_LICENSE_LIST.NONE.value
      );
    }
    this.setState({
      isPractitioner2: e.target.value === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value
    });
    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours();
  };

  private onChangePractitioner = (): void => {
    this.setPersonalCalculatedHours1();
    this.setCalculatedAccompanySupportHours();
    this.setAllCalculatedHours();
  };

  public render(): JSX.Element {
    return (
      <FormGroup>
        <FormGroup className={this.props.classes.categoryGroup}>
          <FormikSelect
            name="initial.status"
            label="サービス提供の状況"
            required
            options={this.state.statusList}
            size="smallMedium"
            onChangeHook={this.handleChangeStatus}
          />
          <FormikCheckbox
            name="initial.accompanySupportFlg"
            label="同行支援"
            disabled={
              this.state.isStatusNone || this.state.isDisableAccompanySupportFlg
            }
            onChange={this.onChangeAccompanySupportFlg}
          />
          <FormikSelect
            name="initial.numberOfParticipants"
            label="提供人数"
            size="smallMedium"
            options={JUDOHOMONKAIGO_MEMBER_SELECT_LIST}
            disabled={this.state.isStatusNone}
            onChangeHook={this.handleChangeNumberOfParticipants}
          />
          <FormGroup row>
            <FormikSelect
              name="initial.practitioner1"
              label="提供者 1人目"
              size="smallMedium"
              options={this.state.staffList}
              className={this.props.classes.worker_license}
              disabled={this.state.isStatusNone}
              onChangeHook={this.onChangePractitioner}
            />
            {this.state.accompanySupportFlg && (
              <>
                <FormikSelect
                  name="initial.practitioner1License"
                  label="経験"
                  options={this.state.licenseList}
                  size="smallMedium"
                  className={this.props.classes.worker_license}
                  disabled={
                    this.state.isStatusNone || this.state.isPractitioner2
                  }
                  style={{
                    display: this.state.isPractitioner2 ? "none" : "flex"
                  }}
                  onChangeHook={this.onChangeLicense1}
                />
                <MuiTextField
                  name="initial.practitioner1LicenseText"
                  label="経験"
                  value="熟練"
                  size="smallMedium"
                  disabled
                  className={this.props.classes.disabledColor}
                  style={{
                    display: this.state.isPractitioner2 ? "flex" : "none"
                  }}
                />
              </>
            )}
          </FormGroup>
          <FormGroup row>
            <FormikSelect
              name="initial.practitioner2"
              label="提供者 2人目"
              size="smallMedium"
              options={this.state.staffList}
              className={this.props.classes.worker_license}
              disabled={this.state.isStatusNone || !this.state.isPractitioner2}
              onChangeHook={this.onChangePractitioner}
            />
            {this.state.accompanySupportFlg && (
              <>
                <FormikSelect
                  name="initial.practitioner2License"
                  label="経験"
                  options={this.state.licenseList}
                  size="smallMedium"
                  className={this.props.classes.worker_license}
                  disabled
                  style={{
                    display: this.state.isPractitioner2 ? "none" : "flex"
                  }}
                  onChangeHook={this.onChangeLicense2}
                />
                <MuiTextField
                  name="initial.practitioner2LicenseText"
                  label="経験"
                  value="新任"
                  size="smallMedium"
                  disabled
                  className={this.props.classes.disabledColor}
                  style={{
                    display: this.state.isPractitioner2 ? "flex" : "none"
                  }}
                />
              </>
            )}
          </FormGroup>
          <FormikCheckbox
            name="initial.licenseSameFlg"
            label="2人同時にサービスを提供する"
            style={{ marginBottom: 0 }}
            disabled={this.state.isStatusNone || !this.state.isPractitioner2}
            onChange={this.onChangeLicenseSameFlg}
          />
          <FormGroup>
            <FormikCheckbox
              name="initial.secondPersonFlg"
              label={
                this.state.accompanySupportFlg && !this.state.isPractitioner2
                  ? "他事業所とともにサービスを提供する*"
                  : "他事業所とともにサービスを提供する"
              }
              style={{ marginBottom: 0 }}
              disabled={this.state.isStatusNone || this.state.isPractitioner2}
              onChange={this.onChangeSecondPersonFlg}
            />
            {this.state.accompanySupportFlg && !this.state.isPractitioner2 && (
              <FormHelperText
                style={{
                  marginTop: -2,
                  marginBottom: 25,
                  color:
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .secondPersonFlgError !== undefined
                      ? "#ff5656"
                      : ""
                }}
              >
                同行支援で提供人数1人の場合は他事業所とともにサービスを提供するが必須です
              </FormHelperText>
            )}
          </FormGroup>
        </FormGroup>

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            サービス提供時間
            {this.state.isPractitioner2 &&
              !this.state.licenseSameFlg &&
              " 1人目"}
          </FormLabel>
          <FormGroup row>
            <FormikTextField
              name="initial.practitioner1InTimeClass"
              label="開始時間"
              required
              style={{ width: 80 }}
              disabled
              disabledStyle={!this.state.isStatusNone}
              className={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial
                  .practitioner1InTime &&
                IN_TIME_VALIDATION_LIST.includes(
                  this.props.formikPropsValues.errors.initial
                    .practitioner1InTime
                )
                  ? this.props.classes.error
                  : this.props.classes.disabledColorLabel
              }
            />
            <FormikTime
              name="initial.practitioner1InTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              disabled={this.state.isStatusNone}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16, marginRight: 32 }}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1InTime
                  ? IN_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1InTime
                    )
                  : false
              }
            />
            <FormikSelect
              name="initial.practitioner1OutTimeClass"
              label="終了時間"
              required
              style={{ width: 80 }}
              options={TIME_CLASS_LIST}
              disabled={this.state.isStatusNone}
              onChangeHook={this.handleChangeTime}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1OutTime
                    )
                  : false
              }
            />
            <FormikTime
              name="initial.practitioner1OutTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              disabled={this.state.isStatusNone}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16 }}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1OutTime
                    )
                  : false
              }
            />
          </FormGroup>
          {!this.state.isPractitioner2 && this.state.accompanySupportFlg && (
            <FormGroup row>
              <FormikSelect
                FormLabelClasses={{ root: this.props.classes.accompanySupport }}
                name="initial.accompanySupportInTimeClass"
                label="同行支援開始時間"
                required
                style={{ width: 80 }}
                options={TIME_CLASS_LIST}
                disabled={this.state.isStatusNone}
                onChangeHook={this.handleChangeAccompanySupportTime}
                error={
                  this.props.formikPropsValues.errors &&
                  this.props.formikPropsValues.errors.initial &&
                  this.props.formikPropsValues.errors.initial
                    .accompanySupportInTime
                    ? ACCOMPANY_SUPPORT_IN_TIME_VALIDATION_LIST.includes(
                        this.props.formikPropsValues.errors.initial
                          .accompanySupportInTime
                      )
                    : false
                }
              />
              <FormikTime
                name="initial.accompanySupportInTime"
                placeholder="00:00"
                size="smallMedium"
                maxLength={5}
                disabled={this.state.isStatusNone}
                onChangeHookTime={this.handleChangeAccompanySupportTime}
                style={{ marginTop: 16, marginRight: 32 }}
                error={
                  this.props.formikPropsValues.errors &&
                  this.props.formikPropsValues.errors.initial &&
                  this.props.formikPropsValues.errors.initial
                    .accompanySupportInTime
                    ? ACCOMPANY_SUPPORT_IN_TIME_VALIDATION_LIST.includes(
                        this.props.formikPropsValues.errors.initial
                          .accompanySupportInTime
                      )
                    : false
                }
              />
              <FormikSelect
                FormLabelClasses={{ root: this.props.classes.accompanySupport }}
                name="initial.accompanySupportOutTimeClass"
                label="同行支援終了時間"
                required
                style={{ width: 80 }}
                options={TIME_CLASS_LIST}
                disabled={this.state.isStatusNone}
                onChangeHook={this.handleChangeAccompanySupportTime}
                error={
                  this.props.formikPropsValues.errors &&
                  this.props.formikPropsValues.errors.initial &&
                  this.props.formikPropsValues.errors.initial
                    .accompanySupportOutTime
                    ? ACCOMPANY_SUPPORT_OUT_TIME_VALIDATION_LIST.includes(
                        this.props.formikPropsValues.errors.initial
                          .accompanySupportOutTime
                      )
                    : false
                }
              />
              <FormikTime
                name="initial.accompanySupportOutTime"
                placeholder="00:00"
                size="smallMedium"
                maxLength={5}
                disabled={this.state.isStatusNone}
                onChangeHookTime={this.handleChangeAccompanySupportTime}
                style={{ marginTop: 16 }}
                error={
                  this.props.formikPropsValues.errors &&
                  this.props.formikPropsValues.errors.initial &&
                  this.props.formikPropsValues.errors.initial
                    .accompanySupportOutTime
                    ? ACCOMPANY_SUPPORT_OUT_TIME_VALIDATION_LIST.includes(
                        this.props.formikPropsValues.errors.initial
                          .accompanySupportOutTime
                      )
                    : false
                }
              />
            </FormGroup>
          )}
          {!this.state.isStatusNone && (
            <FormGroup style={{ marginBottom: 20 }}>
              <UnitsFields
                formikProps={this.props.formikPropsValues}
                unitKey="carePlanDetails1"
                onAddRecord={this.onAddDetailRecord1}
                onDeleteRecord={this.onDeleteDetailRecord1}
                onChangeTime={this.onChangeDetailRecord1}
                isAddButton={this.state.isAddButton1}
                list={this.state.practitioner1DetailTimeList}
              />
            </FormGroup>
          )}
          <FormikTextField
            name="initial.practitioner1Memo"
            label="備考"
            size="quarterSuperLong"
            disabled={this.state.isStatusNone}
          />
        </FormGroup>

        {this.state.isPractitioner2 && !this.state.licenseSameFlg && (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              サービス提供時間 2人目
            </FormLabel>
            {!this.state.licenseSameFlg && (
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner2InTimeClass"
                  label="開始時間"
                  required
                  style={{ width: 80 }}
                  options={TIME_CLASS_LIST}
                  disabled={this.state.isStatusNone}
                  onChangeHook={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2InTime
                      ? IN_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2InTime
                        )
                      : false
                  }
                />
                <FormikTime
                  name="initial.practitioner2InTime"
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  disabled={this.state.isStatusNone}
                  onChangeHookTime={this.handleChangeTime}
                  style={{ marginTop: 16, marginRight: 32 }}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2InTime
                      ? IN_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2InTime
                        )
                      : false
                  }
                />
                <FormikSelect
                  name="initial.practitioner2OutTimeClass"
                  label="終了時間"
                  required
                  style={{ width: 80 }}
                  options={TIME_CLASS_LIST}
                  disabled={this.state.isStatusNone}
                  onChangeHook={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2OutTime
                      ? OUT_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2OutTime
                        )
                      : false
                  }
                />
                <FormikTime
                  name="initial.practitioner2OutTime"
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  disabled={this.state.isStatusNone}
                  onChangeHookTime={this.handleChangeTime}
                  style={{ marginTop: 16 }}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2OutTime
                      ? OUT_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2OutTime
                        )
                      : false
                  }
                />
              </FormGroup>
            )}
            {!this.state.isStatusNone && !this.state.licenseSameFlg && (
              <FormGroup style={{ marginBottom: 20 }}>
                <UnitsFields
                  formikProps={this.props.formikPropsValues}
                  unitKey="carePlanDetails2"
                  onAddRecord={this.onAddDetailRecord2}
                  onDeleteRecord={this.onDeleteDetailRecord2}
                  onChangeTime={this.onChangeDetailRecord2}
                  isAddButton={this.state.isAddButton2}
                  list={this.state.practitioner2DetailTimeList}
                />
              </FormGroup>
            )}

            {!this.state.licenseSameFlg && (
              <FormikTextField
                name="initial.practitioner2Memo"
                label="備考"
                size="quarterSuperLong"
                disabled={this.state.isStatusNone}
              />
            )}
          </FormGroup>
        )}

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            算定時間
          </FormLabel>
          {!this.state.isPractitioner2 && (
            <FormGroup>
              <FormGroup row>
                <FormikTextField
                  name="initial.practitioner1CalculatedHours"
                  label="算定時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
                {this.state.isDisplayAccompanySupportTime && (
                  <FormikTextField
                    name="initial.wholeCalculatedAccompanySupportHours"
                    label="同行支援時間"
                    size="smallMedium"
                    endAdornmentLabel="時間"
                    disabled
                    className={this.props.classes.disabledColor}
                  />
                )}
              </FormGroup>
              <FormGroup row>
                <FormikTextField
                  name="initial.practitioner1CalculatedMovingHours"
                  label="移動時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
                {this.state.isDisplayAccompanySupportTime && (
                  <FormikTextField
                    name="initial.wholeCalculatedAccompanySupportMovingHours"
                    label="同行支援の移動時間"
                    size="smallMedium"
                    endAdornmentLabel="時間"
                    disabled
                    className={this.props.classes.disabledColor}
                  />
                )}
              </FormGroup>
            </FormGroup>
          )}
          {this.state.isPractitioner2 && (
            <FormGroup>
              <FormGroup row>
                <FormikTextField
                  name="initial.wholeCalculatedHours"
                  label="全体の算定時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
                <FormikTextField
                  className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                  name="initial.duplicateCalculatedHours"
                  label="2人が重複する算定時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                />
              </FormGroup>
              <FormGroup row>
                <FormikTextField
                  name="initial.wholeCalculatedMovingHours"
                  label="全体の移動時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
                <FormikTextField
                  className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                  name="initial.duplicateCalculatedMovingHours"
                  label="2人が重複する移動時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                />
              </FormGroup>
            </FormGroup>
          )}
        </FormGroup>
        {this.props.facility.specificFacilitiesAddition !== "1" && (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              その他
            </FormLabel>

            <FormikCheckbox
              name="initial.sputumImplementationFlg"
              label="喀痰吸引等実施"
              style={{ marginBottom: 0 }}
              disabled={
                this.state.isStatusNone || this.state.isCheckboxDisplayNoneFlg
              }
            />
          </FormGroup>
        )}
      </FormGroup>
    );
  }
}

export const CarePlanDialogFields = withStyles(styles)(
  CarePlanDialogFieldsCore
);
