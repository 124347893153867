import { BasicValues } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/facility/basic";
import { AdditionalItemValues } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/facility/additionItem";
import { FacilityState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/types";
import { UnitsValues } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/facility/units";

export type FacilityValues = BasicValues & AdditionalItemValues & UnitsValues;

// ユニットの初期値
export const unitInitialValue = {
  id: null,
  unit_name: "",
  same_building_subtraction_flg: false,
  is_deleted: false,
  results_use_flg: false
};

export const initialValues = (state?: FacilityState): FacilityValues => {
  const unitsValue =
    state && state.units && state.units.length > 0
      ? state.units
      : [unitInitialValue];

  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      buildingManagementFlg: state ? state.buildingManagementFlg : false
    },
    additionalItem: {
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      specificFacilitiesAddition: state
        ? state.specificFacilitiesAddition
        : "1",
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false,
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false
    },
    units: unitsValue
  };
};
