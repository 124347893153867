import * as types from "./types";
import { GetUsersSummaryCustomRecords } from "@api/requests/supports/getUsersSummaryCustomRecords";
import { ResponseErrorState } from "@stores/ui/responseError/types";

export const fetchUsersSummaryGroupHomeStarted = () =>
  ({ type: types.FETCH_USERS_SUMMARY_GROUP_HOME_STARTED } as const);
export const fetchUsersSummaryGroupHomeSuccess = (
  res: GetUsersSummaryCustomRecords["data"]
) =>
  ({
    type: types.FETCH_USERS_SUMMARY_GROUP_HOME_SUCCESS,
    payload: res
  } as const);
export const fetchUsersSummaryGroupHomeFailed = (err: {
  error: { response: ResponseErrorState };
}) =>
  ({
    type: types.FETCH_USERS_SUMMARY_GROUP_HOME_FAILED,
    error: err
  } as const);
export const unsetUsersSummaryGroupHome = () =>
  ({ type: types.UNSET_USERS_SUMMARY_GROUP_HOME } as const);

export type ActionTypes =
  | ReturnType<typeof fetchUsersSummaryGroupHomeStarted>
  | ReturnType<typeof fetchUsersSummaryGroupHomeSuccess>
  | ReturnType<typeof fetchUsersSummaryGroupHomeFailed>
  | ReturnType<typeof unsetUsersSummaryGroupHome>;
