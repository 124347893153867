import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetServiceDeliveryDetails = BaseResponse<{
  service_delivery_records_id: number | null;
  inout_results_id: number | null;
  support_procedure_forms_id: number | null; // 行動のみ設定
  created_at: number | null;
  updated_at: number | null;
  users_in_facility_id: number;
  name_sei: string;
  name_mei: string;
  target_date: string;
  status: number;
  status_text?: string; // 移動支援のみ
  emergency_support_flg: number | null;
  sputum_implementation_flg: number | null;
  emergency_guide_support_flg?: number | null; // 重度のみ設定
  body_restricted_still_flg: number | null;
  accompany_support_flg?: number | null; // 重度のみ設定
  accompany_support_display_flg?: number | null; // 重度のみ設定
  number_of_practitioner: number;
  number_of_supporters?: number; // 移動支援のみ
  support_ratio_per_practitioner?: number; // 移動支援のみ
  physical_care_flg?: number; // 移動支援のみ
  license_same_flg?: number; // 移動支援のみ
  service_delivery_record_practitioners: {
    service_delivery_record_practitioners_id: number | null;
    start_time: string | null;
    end_time: string | null;
    number_of_time: string | null;
    number_of_rides?: number; // 居宅のみ設定
    calculated_moving_hours?: number; // 重度のみ設定
    accompany_support_in_time?: string | null; // 重度のみ設定
    accompany_support_out_time?: string | null; // 重度のみ設定
    calculated_accompany_support_hours?: number; // 重度のみ設定
    calculated_accompany_support_moving_hours?: number; // 重度のみ設定
    practitioner: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_license_name: string | null;
    } | null;
    practitioner2?: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_license_name: string | null;
    }; // 移動支援のみ
    practitioner3?: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_license_name: string | null;
    }; // 移動支援のみ
    practitioner4?: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_license_name: string | null;
    }; // 移動支援のみ
    practitioner5?: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string | null;
      facility_id: number;
      snapshot_name: string;
      snapshot_license_name: string | null;
    }; // 移動支援のみ
    practitioner_num: number;
    display_flg: number | null;
    service_delivery_record_practitioner_details: {
      service_delivery_record_practitioner_details_id: number | null;
      action_class: number;
      start_time: string | null;
      end_time: string | null;
    }[];
    service_delivery_records?: {
      custom_records_category_id: number;
      input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string;
        choiced_item_id?: number;
        checked?: number;
      }[];
    }[];
    // 同行援護のみ
    service_delivery_record_practitioner_destinations?: {
      service_delivery_record_practitioner_destinations_id: number;
      when_time: string;
      place: string;
      support: string;
    }[];
    // 同行援護のみ
    service_delivery_record_practitioner_money_managements?: {
      traveling_expenses: {
        service_delivery_record_practitioner_money_managements_id: number;
        transportation: number;
        free_text: string;
        amount_money: string;
      }[];
      other_expenses: {
        service_delivery_record_practitioner_money_managements_id: number;
        transportation: number;
        free_text: string;
        amount_money: string;
      }[];
    };
  }[];
  municipality?: {
    calculation_time_flg: number;
    round_up_minute: number;
  }; // 移動支援のみ
}>;

/**
 * サービス提供記録詳細を取得する
 * @param serviceDeliveryRecordsId サービス提供記録のID
 * @param inoutResultsId 施設利用実績のID
 */
export const getServiceDeliveryDetails = async (
  serviceDeliveryRecordsId: number | null,
  inoutResultsId: number | null,
  supportProcedureFormsId: number | null
): Promise<AxiosResponse<GetServiceDeliveryDetails>> => {
  const url = `${VERSION_URL_201910}/service_delivery/details?`;
  let query = "";
  // それぞれ存在確認を行い、クエリパラメータを付与
  if (serviceDeliveryRecordsId) {
    query += `&service_delivery_records_id=${serviceDeliveryRecordsId}`;
  }
  if (inoutResultsId) {
    query += `&inout_results_id=${inoutResultsId}`;
  }
  if (supportProcedureFormsId) {
    query += `&support_procedure_forms_id=${supportProcedureFormsId}`;
  }
  query = query && `${query.slice(1)}`;

  return request.get<GetServiceDeliveryDetails>(url + query);
};
