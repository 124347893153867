import { SupportLedger } from "./initialValues";
import validator, {
  validateSwitcher,
  dateValidator,
  ErrorMessage,
  Rule
} from "@validator";
import { CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM } from "@constants/variables";
import { BasicErrors } from "@interfaces/mgr/CHIIKITEICHAKU/record/supportLedger";
import { SelectDateValue } from "@interfaces/ui/form";

type HistoriesErrorMessage = {
  yyyymm: ErrorMessage;
  matter: ErrorMessage;
};

type RelativesInfoErrorMessage = {
  relationship: ErrorMessage;
  name: ErrorMessage;
  age: ErrorMessage;
  profession: ErrorMessage;
  address: ErrorMessage;
  contact_address: ErrorMessage;
  remarks: ErrorMessage;
};

type RelatedOrganizationErrorMessage = {
  facility_name: ErrorMessage;
  kinds: ErrorMessage;
  manager: ErrorMessage;
  contact_address: ErrorMessage;
  remarks: ErrorMessage;
};

type InputItemsErrorMessage = {
  input_data: ErrorMessage | undefined;
  life_histories: HistoriesErrorMessage[];
  disability_histories: HistoriesErrorMessage[];
  relatives_info: RelativesInfoErrorMessage[];
  related_organization: RelatedOrganizationErrorMessage[];
};

type SupportLedgerItemsErrorMessage = {
  input: InputItemsErrorMessage[];
};

const lengthRule = (length: number): Rule => ({
  type: "checkCharacterLength",
  length
});

const validateLifeHistory = (
  lifeHistories: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["life_histories"]
): HistoriesErrorMessage[] => {
  if (!lifeHistories) {
    return [];
  }
  return lifeHistories.map((i) => {
    return {
      yyyymm: validator(i.yyyymm ? i.yyyymm : "", lengthRule(20)),
      matter: validator(i.matter ? i.matter : "", lengthRule(200))
    };
  });
};

const validateDisabilityHistory = (
  disabilityHistories: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["disability_histories"]
): HistoriesErrorMessage[] => {
  if (!disabilityHistories) {
    return [];
  }
  return disabilityHistories.map((i) => {
    return {
      yyyymm: validator(i.yyyymm ? i.yyyymm : "", lengthRule(20)),
      matter: validator(i.matter ? i.matter : "", lengthRule(200))
    };
  });
};

const validateRelativesInfo = (
  relativesInfo: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["relatives_info"]
): RelativesInfoErrorMessage[] => {
  if (!relativesInfo) {
    return [];
  }
  return relativesInfo.map((i) => {
    return {
      relationship: validator(
        i.relationship ? i.relationship : "",
        lengthRule(20)
      ),
      name: validator(i.name ? i.name : "", lengthRule(20)),
      age: validator(i.age ? i.age : "", lengthRule(20)),
      profession: validator(i.profession ? i.profession : "", lengthRule(20)),
      address: validator(i.address ? i.address : "", lengthRule(200)),
      contact_address: validator(
        i.contact_address ? i.contact_address : "",
        lengthRule(200)
      ),
      remarks: validator(i.remarks ? i.remarks : "", lengthRule(200))
    };
  });
};

const validateRelatedOrganization = (
  relatedOrganization: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["related_organization"]
): RelatedOrganizationErrorMessage[] => {
  if (!relatedOrganization) {
    return [];
  }
  return relatedOrganization.map((i) => {
    return {
      facility_name: validator(
        i.facility_name ? i.facility_name : "",
        lengthRule(200)
      ),
      kinds: validator(i.kinds ? i.kinds : "", lengthRule(20)),
      manager: validator(i.manager ? i.manager : "", lengthRule(200)),
      contact_address: validator(
        i.contact_address ? i.contact_address : "",
        lengthRule(200)
      ),
      remarks: validator(i.remarks ? i.remarks : "", lengthRule(200))
    };
  });
};
const validateByDefaultItem = (
  input: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]
): InputItemsErrorMessage["input_data"] | undefined => {
  switch (input.default_item) {
    // 電話、台帳作成事業所連絡先
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.tel:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdTel:
      return validator(input.input_data, "naturalNumber", lengthRule(12));
    // 身長 体重
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.height:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.weight:
      return validator(input.input_data, "decimalPoint");
    // 日常生活能力 生活保護 障害年金 住居の状態 周辺環境 留意点
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.lifeAbility:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.welfare:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityPension:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.housingStatus:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.surroundingEnvironment:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.pointsToRemember:
      return validator(input.input_data, lengthRule(2500));
    // バリデーションなし
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdOffice:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdStaff:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.address:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.email:
      return undefined;
    default:
      return validator(input.input_data, lengthRule(200));
  }
};

const validateInput = (
  supportLedgerInput: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"]
): InputItemsErrorMessage[] => {
  return supportLedgerInput.map((inputItem) => {
    return {
      input_data: validateByDefaultItem(inputItem),
      life_histories: validateLifeHistory(inputItem.life_histories),
      disability_histories: validateDisabilityHistory(
        inputItem.disability_histories
      ),
      relatives_info: validateRelativesInfo(inputItem.relatives_info),
      related_organization: validateRelatedOrganization(
        inputItem.related_organization
      )
    };
  });
};

const validateSupportLedgerItems = (
  supportLedgerItems: SupportLedger["support_ledger"]["support_ledger_items"]
): SupportLedgerItemsErrorMessage[] => {
  return supportLedgerItems.map((item) => {
    return {
      input: validateInput(item.input)
    };
  });
};

// 初期作成日と最終更新日が同じだったらtrue
const validDateEqual = (
  creationDate: SelectDateValue,
  updateDate: SelectDateValue
): boolean => {
  if (
    creationDate.year !== updateDate.year ||
    creationDate.month !== updateDate.month ||
    creationDate.day !== updateDate.day
  ) {
    return false;
  }
  return true;
};

export const validation = (values: SupportLedger): BasicErrors => {
  return {
    support_ledger: {
      creation_date: dateValidator(
        values.support_ledger.creation_date,
        "required"
      ),
      update_date: !validDateEqual(
        values.support_ledger.creation_date,
        values.support_ledger.update_date
      )
        ? dateValidator(values.support_ledger.update_date, "required", {
            type: "future",
            startDate: values.support_ledger.creation_date,
            options: {
              startLabel: "初期作成日",
              endLabel: "最終更新日"
            }
          })
        : dateValidator(values.support_ledger.update_date, "required"),
      date_begin_in_support:
        validateSwitcher(
          values.support_ledger.date_begin_in_support.year !== "" ||
            values.support_ledger.date_begin_in_support.month !== "",
          dateValidator(
            { ...values.support_ledger.date_begin_in_support, day: "1" },
            {
              type: "requiredDateAnyMessage",
              message: "年月どちらも入力してください"
            }
          )
        ) ||
        validateSwitcher(
          values.support_ledger.date_end_in_support.year !== "" &&
            values.support_ledger.date_end_in_support.month !== "",
          dateValidator(
            { ...values.support_ledger.date_begin_in_support, day: "1" },
            {
              type: "requiredDateAnyMessage",
              message: "開始年月も入力してください"
            }
          )
        ),
      date_end_in_support:
        validateSwitcher(
          values.support_ledger.date_end_in_support.year !== "" ||
            values.support_ledger.date_end_in_support.month !== "",
          dateValidator(
            { ...values.support_ledger.date_end_in_support, day: "2" },
            {
              type: "requiredDateAnyMessage",
              message: "年月どちらも入力してください"
            },
            {
              type: "future",
              startDate: {
                ...values.support_ledger.date_begin_in_support,
                day: "1"
              },
              options: {
                startLabel: "支援開始年月",
                endLabel: "支援終了年月"
              }
            }
          )
        ) ||
        validateSwitcher(
          values.support_ledger.date_begin_in_support.year !== "" &&
            values.support_ledger.date_begin_in_support.month !== "",
          dateValidator(
            { ...values.support_ledger.date_end_in_support, day: "1" },
            {
              type: "requiredDateAnyMessage",
              message: "終了年月も入力してください"
            }
          )
        ),
      support_ledger_items: validateSupportLedgerItems(
        values.support_ledger.support_ledger_items
      )
    }
  };
};

export default validation;
