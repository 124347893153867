import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import LineBreak from "@components/atoms/LineBreak";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import dispatches from "@stores/dispatches";
import { GetUsersSummaryCustomRecordsUsers } from "@api/requests/supports/getUsersSummaryCustomRecords";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  USAGE_PERFORMANCE_STATUS_TYPE_FULL,
  CUSTOM_RECORD_DEFAULT_CHOICE_UNIT,
  FacilityType
} from "@constants/variables";
import { STATUS_TYPE } from "@constants/mgr/SHISETSUNYUSHO/variables";

// utils
import { getUrlParams } from "@/utils/url";
import dateToObject from "@utils/date/dateToObject";
import { getLabelFromOptions } from "@/utils/dataNormalizer";

import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";
import pako from "pako";
import { OptionInterface } from "@components/atoms/DropDown";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.84)"
    },
    userBasicInfo: {
      marginBottom: 16
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      marginBottom: 8
    },
    recipientNumber: {
      fontSize: 10,
      color: "rgba(0, 0, 0, 0.87)",
      marginLeft: 8
    },
    summaryContent: {
      fontSize: 10,
      marginRight: 10,
      color: "rgba(0, 0, 0, 0.87)"
    },
    summaryInfo: { fontSize: 0 },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  usersSummary: string[]; // gzip圧縮されたusers配列
  customRecords: CustomRecordsState;
  userState: UserState;
};
type OwnProps = {
  year: string;
  month: string;
  query: string;
};

type DispatchProps = {
  fetchUsersSummary: (
    facility_type: FacilityType,
    year: string,
    month: string,
    excludedUserIds: string
  ) => void;
  fetchCustomRecords: () => void;
};
type MergeProps = StateProps &
  OwnProps &
  DispatchProps & {
    usagePerformanceStatusType: OptionInterface[];
  };
type Props = MergeProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  year: string;
  month: string;
  displayColumns: string[];
  user: GetUsersSummaryCustomRecordsUsers;
  customRecords: CustomRecordsState;
  staffCustomRecordID: number;
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  usagePerformanceStatusType: OptionInterface[];
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

const getSupportContents = (
  customRecords: CustomRecordsState,
  displayColumns: string[],
  status_type: number,
  support_record_input: GetUsersSummaryCustomRecordsUsers["support"][number]["support_record_input"],
  usagePerformanceStatusType: OptionInterface[],
  classes: Record<string, string>
): JSX.Element[] => {
  const supportContents: JSX.Element[] = [];

  if (status_type) {
    const statusType = getLabelFromOptions(
      `${status_type}`,
      usagePerformanceStatusType
    );

    const statusTypeContent = (
      <p key={`status_type_${status_type}`} style={{ margin: 0 }}>
        {`[サービス提供の状況] ${statusType}`}
      </p>
    );

    supportContents.push(statusTypeContent);
  }

  [...customRecords].forEach((record) => {
    switch (record.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const textContent = support_record_input.find(
          (item) => item.custom_record_item_id === record.id
        );

        const textInputData =
          textContent && textContent.input_data ? textContent.input_data : null;

        // 「職員考察」項目
        if (
          record.default_item ===
            SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment &&
          displayColumns.includes("staff_comment") &&
          textInputData
        ) {
          supportContents.push(
            <p
              key={`input_type_first_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }

        if (
          record.default_item !==
            SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment &&
          textInputData
        ) {
          supportContents.push(
            <p
              key={`input_type_first_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItemsId = support_record_input
          .filter(
            (item) =>
              item.custom_record_item_id === record.id && item.checked === 1
          )
          .map((filteredItem) => filteredItem.choiced_item_id);

        const checkedChoiceItems = record.choices
          .filter((item) => checkedItemsId.includes(item.id))
          .map((filteredItem) => filteredItem.name);

        const checkedName = checkedChoiceItems
          ? checkedChoiceItems.join("、")
          : "";

        if (checkedItemsId.length !== 0) {
          supportContents.push(
            <p
              key={`input_type_second_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}] ${checkedName}`}
            </p>
          );
        }
        break;
      }
      // 複数テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
        const inputDataItems = support_record_input.filter(
          (item) => item.custom_record_item_id === record.id && item.input_data
        );

        const multiTextContents: JSX.Element[] = [];

        // 小項目の途中で改行されないようspanタグを使用
        [1, 2, 3, 4, 5].forEach((defaultChoiceNum) => {
          const choiced = record.choices.find(
            (recordItem) => recordItem.default_choice === defaultChoiceNum
          );
          const choicedInputData =
            choiced &&
            inputDataItems.find(
              (inputDataItem) => inputDataItem.choiced_item_id === choiced.id
            );

          if (choiced && choicedInputData) {
            multiTextContents.push(
              <span key={choiced.id} style={{ display: "inline-block" }}>
                {choiced.name} : {choicedInputData.input_data}
                {CUSTOM_RECORD_DEFAULT_CHOICE_UNIT[`${defaultChoiceNum}`]}&emsp;
              </span>
            );
          }
        });

        // 血圧の最低と最高を並び替え
        const sortedMultiTextContents = multiTextContents.slice();
        const index2 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最低）"
        );
        const index3 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最高）"
        );

        if (index2 !== -1 && index3 !== -1) {
          const lowerChoice = sortedMultiTextContents[index2];
          sortedMultiTextContents[index2] = sortedMultiTextContents[index3];
          sortedMultiTextContents[index3] = lowerChoice;
        }

        if (inputDataItems.length !== 0) {
          supportContents.push(
            <p
              key={`input_type_fourth_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <span>{sortedMultiTextContents}</span>
            </p>
          );
        }
        break;
      }
      default:
        break;
    }
  });

  return supportContents;
};

const UsersSummarySupportPrintCustomRecordCore = (
  props: Props
): JSX.Element | null => {
  const {
    classes,
    year,
    month,
    usersSummary,
    customRecords,
    query,
    userState,
    usagePerformanceStatusType
  } = props;

  const [displayColumns, setDisplayColumns] = React.useState([] as string[]);
  const [renderFlg, setRenderFlg] = React.useState(false);

  React.useEffect(() => {
    const queryParameters: {
      display_columns?: string;
      excluded_user_ids?: string;
    } = getUrlParams(query);
    if (queryParameters.display_columns) {
      setDisplayColumns(queryParameters.display_columns.split(","));
    }
    let excludedUserIds = "";
    if (queryParameters.excluded_user_ids) {
      excludedUserIds = queryParameters.excluded_user_ids;
    }
    props.fetchUsersSummary(
      userState.facility_type,
      year,
      month,
      excludedUserIds
    );
    props.fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  if (!renderFlg || Object.keys(props.customRecords).length === 0) {
    return null;
  }

  // 「order」順、「記録者」項目は除外
  const sortCustomRecords = (
    array: CustomRecordsState
  ): {
    newCustomRecords: CustomRecordsState;
    staffCustomRecord: CustomRecordsState;
  } => {
    const orderArray = array
      .filter((item) => item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);

    return {
      newCustomRecords: orderArray,
      staffCustomRecord: recorderItem
    };
  };

  const orderedCustomRecords =
    customRecords.length > 0 && sortCustomRecords(customRecords);

  if (!orderedCustomRecords) {
    return null;
  }

  // 文字列をUint8Arrayに変換
  function gunZip(str: string): GetUsersSummaryCustomRecordsUsers {
    const charList = atob(str).split("");
    const uintArray = [];
    for (let i = 0; i < charList.length; i += 1) {
      uintArray.push(charList[i].charCodeAt(0));
    }
    const uint = new Uint8Array(uintArray);
    return JSON.parse(pako.ungzip(uint, { to: "string" }));
  }

  return (
    <>
      {usersSummary.map((gzipUser: string) => {
        const user: GetUsersSummaryCustomRecordsUsers = gunZip(gzipUser);
        if (user === undefined || !user.support) {
          return null;
        }
        const tableRowStartEndIndexInSheet = {
          startIndex: 0,
          endIndex: user.support.length
        };
        return (
          <Sheet
            year={year}
            month={month}
            classes={classes}
            displayColumns={displayColumns}
            user={user}
            customRecords={orderedCustomRecords.newCustomRecords}
            staffCustomRecordID={orderedCustomRecords.staffCustomRecord[0].id}
            tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
            usagePerformanceStatusType={usagePerformanceStatusType}
            key={`page-${user.uif_id}`}
          />
        );
      })}
    </>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    year,
    month,
    classes,
    displayColumns,
    user,
    customRecords,
    staffCustomRecordID,
    tableRowStartEndIndexInSheet,
    usagePerformanceStatusType
  } = props;

  const { counts } = user;
  const isMealItem = counts.meal.choices.some((choice) => choice.count !== 0);
  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;

  return (
    <div className={classes.page} key={`page-${user.uif_id}`}>
      <header key={`sheet-header-${user.uif_id}`}>
        <h1 className={classes.title}>
          {displayColumns.includes("support_record")
            ? "支援記録"
            : "サービス提供記録"}
        </h1>
      </header>
      <div className={classes.userBasicInfo} key={`sheet-info-${user.uif_id}`}>
        <div className={classes.subTitle} key={`sheet-subtitle-${user.uif_id}`}>
          <span>
            {`${year}年${month}月分`}&emsp;{`${user.name}`}
          </span>
          <span className={classes.recipientNumber}>
            {`（受給者証番号：${user.recipient_number}）`}
          </span>
        </div>
        <div
          className={classes.summaryInfo}
          key={`sheet-summary-${user.uif_id}`}
        >
          <span
            className={props.classes.summaryContent}
          >{`宿泊：${counts.status_type.stay}日`}</span>
          <span
            className={props.classes.summaryContent}
          >{`入院：${counts.status_type.hospitalization}日`}</span>
          <span
            className={props.classes.summaryContent}
          >{`外泊：${counts.status_type.stay_away}日`}</span>
        </div>
        {isMealItem && (
          <div
            className={classes.summaryInfo}
            key={`sheet-meal-${user.uif_id}`}
          >
            {counts.meal.choices
              .filter((item) => item.count > 0)
              .map((mealItem) => {
                return (
                  <span
                    className={props.classes.summaryContent}
                    key={mealItem.id}
                  >{`${mealItem.name}：${mealItem.count}回`}</span>
                );
              })}
          </div>
        )}
      </div>
      {user.support.length > 0 && (
        <div
          className={classes.flexContainer}
          key={`sheet-container-${user.uif_id}`}
        >
          <table
            className={`${classes.table} fullWidth`}
            key={`sheet-table-${user.uif_id}`}
          >
            <tbody>
              <tr style={{ height: 25 }}>
                <td className="label ssize">日付</td>
                <td className="label">支援内容</td>
                <td className="label ssize">記録者</td>
                <td className="label sssize">捺印</td>
              </tr>
              {user.support.map(
                ({ target_date, status_type, support_record_input }, index) => {
                  if (index < startIndex || index > endIndex) return undefined;
                  const targetDate = dateToObject(new Date(target_date));

                  const supportContents = getSupportContents(
                    customRecords,
                    displayColumns,
                    status_type,
                    support_record_input,
                    usagePerformanceStatusType,
                    classes
                  );

                  // 記録者
                  const staffRecord = support_record_input
                    .filter(
                      (item) =>
                        item.custom_record_item_id === staffCustomRecordID &&
                        item.checked
                    )
                    .map((item) => item.choiced_staff_name_snapshot);

                  const staffName = staffRecord ? staffRecord.join("、") : "";

                  if (!supportContents.length && !staffName) {
                    return undefined;
                  }
                  return (
                    <tr
                      key={`tr-${user.uif_id}-${targetDate.month}_${targetDate.day}`}
                    >
                      <td className="label topCenterAlign">
                        {`${targetDate.month}/${targetDate.day}(${targetDate.day_of_week})`}
                      </td>
                      <td className="topAlign">{supportContents}</td>
                      <td className="topCenterAlign">{staffName}</td>
                      <td className="label" />
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const userState = state.user as UserState;
  const getUsersSummary = (): StateProps["usersSummary"] => {
    switch (userState.facility_type) {
      case FacilityType.GROUP_HOME:
        return state.GroupHome.usersSummary.users;
      case FacilityType.SHISETSUNYUSHO:
        return state.SHISETSUNYUSHO.usersSummary.users;
      default:
        return [];
    }
  };
  return {
    usersSummary: getUsersSummary(),
    customRecords: state.customRecords,
    userState
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecords } = dispatches;
  const customRecordsDispatches = customRecords(dispatch);

  const fetchUsersSummary = (
    facility_type: FacilityType,
    year: string,
    month: string,
    excludedUserIds: string
  ): Promise<void> | null => {
    switch (facility_type) {
      case FacilityType.GROUP_HOME:
        return dispatches.GroupHome.usersSummaryDispatcher(
          dispatch
        ).fetchUsersSummaryGroupHome(year, month, excludedUserIds);
      case FacilityType.SHISETSUNYUSHO:
        return dispatches.SHISETSUNYUSHO.usersSummaryDispatcher(
          dispatch
        ).fetchUsersSummarySHISETSUNYUSHO(year, month, excludedUserIds);
      default:
        return null;
    }
  };

  return {
    fetchUsersSummary,
    fetchCustomRecords: (): void => {
      customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.support
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const getStatusType = (facility_type: FacilityType): OptionInterface[] => {
    switch (facility_type) {
      case FacilityType.GROUP_HOME:
        return USAGE_PERFORMANCE_STATUS_TYPE_FULL;
      case FacilityType.SHISETSUNYUSHO:
        return STATUS_TYPE;
      default:
        return USAGE_PERFORMANCE_STATUS_TYPE_FULL;
    }
  };
  const usagePerformanceStatusType = getStatusType(
    stateProps.userState.facility_type
  );
  return {
    usagePerformanceStatusType,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const UsersSummarySupportPrintCustomRecord = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(UsersSummarySupportPrintCustomRecordCore));
