import * as React from "react";
import * as URL from "@constants/url";
import * as H from "history";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as DOKOENGOUsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { CityParams } from "@stores/domain/city/type";

import AdminTemplate from "@components/templates/AdminTemplate";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { CarePlanFormCreate } from "@components/organisms/mgr/common/record/carePlan/CarePlanFormCreate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { newValidation } from "@initialize/record/carePlan/validation";
import { FieldItem } from "@interfaces/ui/form";
import {
  RecordCarePlan,
  BasicValues
} from "@interfaces/record/carePlan/carePlan";
import { initialValuesCopy } from "@initialize/record/carePlan/initialValues";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  SUPPORT_CARE_PLAN_NAME,
  SUPPORT_CARE_PLAN_PAGE_NAME
} from "@constants/variables";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { toEffectiveObject } from "@utils/object";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    form: {
      marginTop: "32px"
    },
    con: {
      background: "#fff"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    }
  });

type OwnProps = RouteComponentProps<{ id: string; carePlanId?: string }> &
  WithStyles<typeof styles>;

type StateProps = {
  needsStopHistory: boolean;
  user:
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"]
    | DOKOENGOUsersInFacilityState["user"]
    | KODOENGOUsersInFacilityState["user"];
  staff: StaffState;
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
  facilityType: FacilityType;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => Promise<void>;
  fetchOneUser: (id: string, facilityType: FacilityType) => void;
  fetchSupportPlanUifIdCarePlanCarePlanId: (
    id: string,
    carePlanId: string
  ) => Promise<void>;
  fetchCity: (params: CityParams) => Promise<void>;
  fetchStaff: () => Promise<void>;
  postSupportPlanUifIdCarePlanNew: (
    uifId: string,
    values: RecordCarePlan,
    history: H.History
  ) => Promise<void>;
};

type MergeProps = {
  staffOptions: FieldItem[];
  userId: number | undefined;
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

const CarePlanCreateCore = (props: Props): JSX.Element => {
  const {
    needsStopHistory,
    history,
    classes,
    user,
    staff,
    supportCarePlan,
    staffOptions,
    userId,
    userName,
    facilityType
  } = props;
  const { id, carePlanId } = props.match.params;

  // ページ名
  const listName = SUPPORT_CARE_PLAN_NAME[facilityType];
  const pageName = SUPPORT_CARE_PLAN_PAGE_NAME[facilityType];
  const pathList = [
    {
      pathName: "支援計画",
      path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `${URL.RECORD_SUPPORT_PLAN}/${userId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
    }
  ];

  // schedules
  const [schedules, setSchedules] = React.useState<
    GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"]
  >([]);
  React.useEffect(() => {
    setSchedules(supportCarePlan.support_care_plan_schedules);
  }, [supportCarePlan.support_care_plan_schedules]);

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchStaff();
    // コピーの場合
    if (carePlanId) {
      props.fetchSupportPlanUifIdCarePlanCarePlanId(id, carePlanId);
    }
  }, []);

  // fetchCity
  React.useEffect(() => {
    const prefectureNameData = user.user_in_facility.prefecture_name;
    if (prefectureNameData !== undefined) {
      props.fetchCity({
        prefectureName: prefectureNameData
      });
    }
  }, [user]);

  // reInitialize
  const [formValues, setFormValues] = React.useState(
    initialValuesCopy(facilityType, user)
  );
  // 新規
  React.useEffect(() => {
    setFormValues(initialValuesCopy(facilityType, user));
  }, [user]);
  // コピー
  React.useEffect(() => {
    if (carePlanId) {
      setFormValues(initialValuesCopy(facilityType, user, supportCarePlan));
    }
  }, [supportCarePlan]);

  // バリデーション
  const validate = (values: BasicValues): void | object => {
    const validationResult = newValidation(values, facilityType);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      props.stopHistory(true);
    }
    return error;
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 保存
  const onSubmit = async (
    values: RecordCarePlan,
    actions: FormikActions<RecordCarePlan>
  ): Promise<void> => {
    actions.setSubmitting(true);
    props.postSupportPlanUifIdCarePlanNew(id, values, history);
    actions.setSubmitting(false);
  };

  // キャンセル
  const onClickCancel = async (): Promise<void> => {
    await props.stopHistory(false).then(() => {
      const url = `${URL.RECORD_SUPPORT_PLAN}/${userId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`;
      props.history.push(url);
    });
  };

  if (user.user_in_facility.id === undefined) {
    return <></>;
  }

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <CarePlanUserInfo user={user} />
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.form}>
              {/* タブヘッダー */}
              <PlanListHeader
                pageName={pageName}
                userName={userName}
                uifId={id}
                recordType={PLAN_LIST_TAB_PATH.CAREPLAN}
                isEditing
                history={props.history}
                button={
                  <div className={classes.titleBtn}>
                    <div className={classes.titleBtnItem}>
                      <KnowbeButton kind="outline" onClick={onClickCancel}>
                        キャンセル
                      </KnowbeButton>
                    </div>
                    <div className={classes.titleBtnItem}>
                      <FormikSubmitButton
                        key="submit-button"
                        buttonName="保存する"
                        formikProps={formikProps}
                        errorAction={submitError}
                      />
                    </div>
                  </div>
                }
                facilityType={facilityType}
              />

              {/* フォーム */}
              <div className={classes.con}>
                <CarePlanFormCreate
                  formikProps={formikProps}
                  staff={staff}
                  staffOptions={staffOptions}
                  schedules={schedules}
                  paramsId={id}
                  paramsCarePlanId={carePlanId || ""}
                  facilityType={facilityType}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    needsStopHistory: state.ui.needsStopHistory,
    user: state[facilityType].userInFacility.user,
    staff: state.staff,
    supportCarePlan: state.carePlan.supportCarePlan,
    facilityType
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, cityDispatch, carePlanDispatcher } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    fetchOneUser: (id: string, facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportPlanUifIdCarePlanCarePlanId: carePlanDispatcher(dispatch)
      .fetchSupportPlanUifIdCarePlanCarePlanId,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatch(dispatch).fetch({
        prefectureName: params.prefectureName
      });
    },
    fetchStaff: carePlanDispatcher(dispatch).fetchStaff,
    postSupportPlanUifIdCarePlanNew: async (
      uifId: string,
      values: RecordCarePlan,
      history: H.History
    ): Promise<void> => {
      await carePlanDispatcher(dispatch).postSupportPlanUifIdCarePlanNew(
        uifId,
        values,
        history
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, user } = stateProps;

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  // ユーザID
  const userId = user.user_in_facility.id;
  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  return {
    staffOptions,
    userId,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const CarePlanCreate = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CarePlanCreateCore)
);
