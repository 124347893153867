import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import {
  SEIKATSUKAIGOReport,
  SEIKATSUKAIGOReportTypeInterface
} from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/report/types";
import { InitialDataValues } from "@initialize/v202104/mgr/SEIKATSUKAIGO/report/initialValues";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import validation, {
  submitValidation
} from "@initialize/v202104/mgr/SEIKATSUKAIGO/report/validation";
import { InOutReportDialogSubmit } from "@components/v202104/organisms/mgr/SEIKATSUKAIGO/report/dialog/InOutReportDialogSubmit";
import InOutReportDialogFields from "@components/v202104/organisms/mgr/SEIKATSUKAIGO/report/dialog/InOutReportDialogFields";
import { FacilityState } from "@stores/v202104/domain/mgr/SEIKATSUKAIGO/facility/types";
import { AppState } from "@stores/type";
import { InitialValues } from "@interfaces/v202104/mgr/SEIKATSUKAIGO/report/initial";
import { SERIOUS_DISABILITY_TYPE_LIST } from "@constants/mgr/SEIKATSUKAIGO/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    action: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      // MuiDialog-paperの上書き
      margin: 0,
      paddingRight: 4,
      paddingBottom: 4
    },
    modalAction: {
      display: "flex"
    },
    button: {
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4,
      textTransform: "none",
      width: 110
    },
    submit: {
      marginLeft: spacing.unit,
      width: 110,
      marginRight: spacing.unit
    },
    modalHeader: {
      display: "flex"
    },
    modal: {
      maxHeight: "80%",
      width: 600,
      // MuiDialog-paperScrollPaperの上書き
      maxWidth: 600,
      margin: 0
    },
    form: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    modalHeaderArea: {
      padding: "16px 32px 18px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: "20px",
      color: "#37474f",
      backgroundColor: "#f5f5f5"
    },
    UsageRecordDate: {
      textAlign: "right",
      flex: "auto",
      fontSize: 16
    },
    modalContents: {
      paddingTop: 8,
      paddingRight: 16,
      paddingBottom: 0,
      paddingLeft: 16
    }
  });

type DispatchProps = {
  putSEIKATSUKAIGOReport: (
    reportList: SEIKATSUKAIGOReport[],
    formValue: InitialValues,
    type: SEIKATSUKAIGOReportTypeInterface["type"],
    facilityState: FacilityState
  ) => Promise<void>;
};

type StateProps = {
  facilityState: FacilityState;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  reportList: SEIKATSUKAIGOReport[];
  data: InitialDataValues;
  selectedDate: Date;
  type: SEIKATSUKAIGOReportTypeInterface["type"];
  onCancel: () => void;
  onSubmit: () => void;
  initialFlg: boolean;
};

type State = {
  isSubmitDisabled: boolean;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class InOutReportDialogCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitDisabled: true
    };
  }

  private validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values);
    this.setState({
      isSubmitDisabled: submitValidation(
        values.initial.status,
        validationResult
      )
    });
    return toEffectiveObject(validationResult);
  };

  private onCancel = (): void => {
    this.setState({ isSubmitDisabled: true });
    this.props.onCancel();
  };

  private onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    this.onCancel();
    actions.setSubmitting(true);
    await this.props
      .putSEIKATSUKAIGOReport(
        this.props.reportList,
        values,
        this.props.type,
        this.props.facilityState
      )
      .then(() => {
        this.props.onSubmit();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        classes={{ paper: classes.modal }}
      >
        <Formik
          initialValues={this.props.data}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.form}>
              <DialogTitle
                id={this.props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={this.props.classes.modalHeader}>
                  <div>利用実績</div>
                  <div className={classes.UsageRecordDate}>
                    <span>
                      {this.props.selectedDate.getFullYear()}
                      <span>年</span>
                      {this.props.selectedDate.getMonth() + 1}
                      <span>月</span>
                      {this.props.selectedDate.getDate()}
                      <span>日</span>
                      <span>
                        （
                        {
                          oneLetterWeekdaysJapanese[
                            this.props.selectedDate.getDay()
                          ]
                        }
                        ）
                      </span>
                    </span>
                  </div>
                </div>
                <div>{this.props.data.initial.name}</div>
              </DialogTitle>

              <DialogContent className={classes.modalContents}>
                <InOutReportDialogFields
                  formikPropsValues={formikProps}
                  isPickupAvailable={
                    this.props.facilityState.transferServiceFlag
                  }
                  isFoodAvailable={
                    this.props.facilityState.mealSaservedServiceFlag
                  }
                  setFormikFieldValue={formikProps.setFieldValue}
                  isSevereFailureSupport={
                    this.props.facilityState.seriousDisability !==
                    SERIOUS_DISABILITY_TYPE_LIST[0].value
                  }
                />
              </DialogContent>
              <DialogActions className={classes.action}>
                <InOutReportDialogSubmit
                  formikPropsValues={formikProps}
                  onCancel={this.onCancel}
                  disabled={this.state.isSubmitDisabled}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.v202104.SEIKATSUKAIGO.facility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const seikatsukaigoDispatcher = v202104.SEIKATSUKAIGO.reportDispacher(
    dispatch
  );
  return {
    putSEIKATSUKAIGOReport: (
      reportList: SEIKATSUKAIGOReport[],
      formValue: InitialValues,
      type: SEIKATSUKAIGOReportTypeInterface["type"],
      facilityState: FacilityState
    ): Promise<void> =>
      seikatsukaigoDispatcher.putSEIKATSUKAIGOReport(
        reportList,
        formValue,
        facilityState,
        type
      )
  };
};

export const InOutReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDialogCore));
