import { GetInquiryResponse } from "@api/requests/inquiry/getInquiry";

/**
 * ActionNames
 */
// 取得
export const FETCH_INQUIRY_STARTED = "INQUIRY/FETCH_INQUIRY_STARTED";
export const FETCH_INQUIRY_SUCCESS = "INQUIRY/FETCH_INQUIRY_SUCCESS";
export const FETCH_INQUIRY_FAILED = "INQUIRY/FETCH_INQUIRY_FAILED";
// 更新
export const POST_INQUIRY_STARTED = "INQUIRY/POST_INQUIRY_STARTED";
export const POST_INQUIRY_SUCCESS = "INQUIRY/UPDATE_INQUIRY_SUCCESS";
export const POST_INQUIRY_FAILED = "INQUIRY/POST_INQUIRY_FAILED";
// 削除
export const DELETE_INQUIRY_STARTED = "INQUIRY/DELETE_INQUIRY_STARTED";
export const DELETE_INQUIRY_SUCCESS = "INQUIRY/DELETE_INQUIRY_SUCCESS";
export const DELETE_INQUIRY_FAILED = "INQUIRY/DELETE_INQUIRY_FAILED";
// 初期化
export const UNSET_INQUIRY = "INQUIRY/UNSET_INQUIRY";

/**
 * State
 */
export type InquiryState = {
  inquiry: GetInquiryResponse["data"];
};
