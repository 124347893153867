import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import MuiTextField from "@components/molecules/MuiTextField";

import DropDown from "@components/atoms/DropDown";

import {
  DownloadableMonth,
  DownloadableUser,
  DownloadableResult
} from "@stores/domain/invoice/type";

import { dateToLocalisedString } from "@utils/date";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      marginTop: spacing.unit * 2,
      paddingTop: spacing.unit * 2,
      paddingBottom: spacing.unit * 2
    },
    dropDown: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      width: 213
    },
    button: {
      marginTop: spacing.unit,
      border: "1px solid #cccccc",
      borderRadius: 4,
      width: 122
    }
  });

type OwnProps = {
  months: DownloadableMonth[];
  isDisabledButton: boolean;
  excludedUserIdCount: number;
  value: string;
  onChangeSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickButton: (event: React.MouseEvent<HTMLSelectElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const getUsers = (month: DownloadableMonth): DownloadableUser[] => {
  if (!month) return [];
  const users = month.results.map(
    (result: DownloadableResult): DownloadableUser[] => {
      return result.users;
    }
  );
  return users.reduce((acc, val) => acc.concat(val), []);
};

const TargetSelectSection = (props: Props): JSX.Element => {
  const { classes } = props;

  const months = props.months.map((month) => {
    return {
      label: dateToLocalisedString(month.date, "YYYY年MM月"),
      value: month.date
    };
  });
  const monthItem = [{ label: "月を選択", value: "" }, ...months];

  const [textFieldValue, setTextFieldValue] = useState("-");

  useEffect(() => {
    if (props.months) {
      const targetMonth = props.months.find((m) => m.date === props.value);
      if (targetMonth) {
        const userList = getUsers(targetMonth);
        const userCount = userList.length;
        const targetUserCount = userCount - props.excludedUserIdCount;
        setTextFieldValue(`${targetUserCount} 名 / ${userCount} 名`);
      } else {
        setTextFieldValue("-");
      }
    }
  }, [props.months, props.value, props.excludedUserIdCount]);

  return (
    <div className={classes.root}>
      <DropDown
        id="downloadTargetSelectPaperSelectMonth"
        label="対象請求月"
        size="textFieldSmall"
        options={monthItem}
        value={props.value}
        styles={classes.dropDown}
        onChange={props.onChangeSelect}
      />
      <MuiTextField
        label="対象利用者数"
        className={classes.textField}
        value={textFieldValue}
        InputLabelProps={{
          shrink: true
        }}
        disabled
        disabledStyle={props.value !== ""}
        style={{
          width: "154px",
          marginRight: "16px",
          display: "inline-flex"
        }}
      />
      <Button
        className={classes.button}
        color="secondary"
        disabled={props.isDisabledButton}
        onClick={props.onClickButton}
        variant="text"
      >
        利用者選択
      </Button>
    </div>
  );
};

export default withStyles(styles)(TargetSelectSection);
