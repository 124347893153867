import { SupportReportState } from "@stores/domain/supportReport/types";
import get from "lodash-es/get";
import {
  SelectDateValue,
  SelectValue,
  CheckBoxValue
} from "@interfaces/ui/form";
import { dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";

export type SupportReportValues = {
  usage_results: {
    id: number;
    uif_id: number;
    display_form_flg: CheckBoxValue;
  }[];
  support_report: {
    id: number;
    facility_id: number;
    uif_id: number;
    creation_date: SelectDateValue;
    working_start_date: SelectDateValue;
    company_name: string;
    responsible_person: string;
    goal_1: string;
    goal_2: string;
    goal_3: string;
    future_support_content: string;
    person_behavior: string;
    facility_behavior: string;
    related_organizations_behavior: string;
    shared_content: string;
    presentation_date: SelectDateValue;
    author: SelectValue; // 担当者
    author_name: string;
    situations: {
      id: number;
      support_reports_id: number;
      number: number;
      content: string;
      result: string;
    }[];
  };
};

// 配列内の初期値を設定し、「number」順に配列を揃える
const supplementUsageResults = (
  usageResults: SupportReportState["support"]["usage_results"]
): SupportReportValues["usage_results"] => {
  return usageResults.map((item) => {
    return {
      id: item.id,
      uif_id: item.uif_id,
      display_form_flg: determineTrueOrFalse(item.display_form_flg)
    };
  });
};

const supplementSituations = (
  situations: SupportReportState["support"]["report"]["situations"]
): SupportReportValues["support_report"]["situations"] => {
  const initialSituations = Array.from(new Array(3)).map((e, i) => {
    const situation = situations.find((element) => element.number === i + 1);
    if (situation) {
      return {
        ...situation,
        content: situation.content || "",
        result: situation.result || ""
      };
    }
    return {
      id: 0,
      support_reports_id: 0,
      number: i + 1,
      content: "",
      result: ""
    };
  });
  return initialSituations;
};

const initialValues = (
  value: SupportReportState["support"],
  user: UsersInFacilityState["user"]
): SupportReportValues => {
  const companies = user.companies ? user.companies : null;
  const companyPersons = user.company_persons ? user.company_persons : [];
  let creationDate = get(value.report, "creation_date") || "";
  let workingStartDate = get(value.report, "working_start_date") || "";
  const presentationDate = get(value.report, "presentation_date") || "";
  let companyName = get(value.report, "company_name") || "";
  let responsiblePerson = get(value.report, "responsible_person") || "";

  // 新規作成
  if (!value.report.id) {
    creationDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
    if (companies) {
      workingStartDate = companies.working_start_date
        ? companies.working_start_date
        : "";
      companyName = companies.name || "";
    }
    if (companyPersons.length > 0) {
      responsiblePerson = companyPersons[0].name || "";
    }
  }

  // author,authorizerは入力ありなしで型が変わる
  const authorId =
    value && value.report.author !== null ? `${value.report.author.id}` : "";
  return {
    usage_results: supplementUsageResults(value.usage_results),
    support_report: {
      id: get(value.report, "id") || 0,
      facility_id: get(value.report, "facility_id") || 0,
      uif_id: get(value.report, "uif_id") || 0,
      creation_date: dateToSelectDateValue(creationDate),
      working_start_date: dateToSelectDateValue(workingStartDate),
      company_name: companyName,
      responsible_person: responsiblePerson,
      goal_1: get(value.report, "goal_1") || "",
      goal_2: get(value.report, "goal_2") || "",
      goal_3: get(value.report, "goal_3") || "",
      future_support_content: get(value.report, "future_support_content") || "",
      person_behavior: get(value.report, "person_behavior") || "",
      facility_behavior: get(value.report, "facility_behavior") || "",
      related_organizations_behavior:
        get(value.report, "related_organizations_behavior") || "",
      shared_content: get(value.report, "shared_content") || "",
      presentation_date: dateToSelectDateValue(presentationDate),
      author: authorId,
      author_name: get(value.report, "author_name") || "",
      situations: supplementSituations(value.report.situations)
    }
  };
};

export default initialValues;
