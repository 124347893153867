import * as React from "react";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { ReportUsagePerformanceState } from "@stores/domain/mgr/GroupHome/report/types";
import { UnitsState } from "@stores/domain/units/types";
import TableRow from "@material-ui/core/TableRow";
import TableCellWrap from "@components/atoms/TableCellWrap";
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME } from "@constants/mgr/GroupHome/variables";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO } from "@constants/mgr/SHISETSUNYUSHO/variables";
import DoneIcon from "@material-ui/icons/Done";
import { FacilityType } from "@constants/variables";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableBody: {
      overflowY: "auto",
      overflowX: "hidden",
      height: "100%",
      // スクロールバーのデザイン対応のため（Googole Chromeのみの適用）
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    table: {
      tableLayout: "fixed",
      width: 1070
    },
    row: {
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    longCell: {
      width: 158,
      boxSizing: "content-box",
      padding: 0
    },
    nameCell: {
      fontSize: "16px"
    },
    firstCell: {
      margin: "12px 0px 12px 32px"
    },
    idCell: {
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: "12px"
    },
    middleCell95: {
      boxSizing: "content-box",
      textAlign: "center",
      borderLeft: "dashed 1px #979797",
      padding: 0,
      width: 95
    },
    middleCell89: {
      boxSizing: "content-box",
      textAlign: "center",
      borderLeft: "dashed 1px #979797",
      padding: 0,
      width: 89
    },
    middleCellLong119: {
      boxSizing: "content-box",
      textAlign: "center",
      borderLeft: "dashed 1px #979797",
      padding: 0,
      width: 119
    },
    middleCellLong115: {
      boxSizing: "content-box",
      textAlign: "center",
      borderLeft: "dashed 1px #979797",
      padding: 0,
      width: 115
    },
    doneIcon: {
      width: 18,
      height: 18,
      color: "rgba(0, 0, 0, 0.54)"
    },
    subLabel: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: "14px"
    },
    unitName: {
      display: "flex",
      alignItems: "center",
      height: 40,
      backgroundColor: "#eceff1",
      paddingLeft: 22,
      borderTop: "1px solid #ffffff"
    }
  });

type OwnProps = {
  usagePerformance: ReportUsagePerformanceState["usage_performance"];
  units: UnitsState["optionalCustomInfo"];
  labels:
    | typeof USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME
    | typeof USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UsagePerformanceDetailModalContents: React.FunctionComponent<Props> = ({
  classes,
  usagePerformance,
  units,
  labels,
  facilityType
}) => {
  const getCellClass = (length: number): string | undefined => {
    if (facilityType === FacilityType.SHISETSUNYUSHO) {
      return length > 1 ? classes.middleCellLong115 : classes.middleCell89;
    }

    return length > 1 ? classes.middleCellLong119 : classes.middleCell95;
  };

  const makeTr = (up: typeof usagePerformance[number]): JSX.Element => {
    return (
      <TableRow
        key={`table-row-${up.users_in_facility_id}`}
        className={classes.row}
      >
        {/* 利用者名 */}
        <TableCellWrap
          key={`${up.users_in_facility_id}-sei-mei`}
          cellClass={classes.longCell}
        >
          <div className={classes.firstCell}>
            <div className={classes.nameCell}>{up.user_name}</div>
            <div className={classes.idCell}>{up.recipient_number}</div>
          </div>
        </TableCellWrap>
        {labels
          .filter((label) => label.key !== 0)
          .map((label) => {
            return (
              <TableCellWrap
                key={`${label.key}-status-type`}
                cellClass={getCellClass(label.status.length)}
              >
                {label.status.includes(up.status) && (
                  <>
                    <DoneIcon className={classes.doneIcon} />
                    {label.subLabel && (
                      <div className={classes.subLabel}>
                        （
                        {
                          label.subLabel[
                            label.status.findIndex(
                              (status) => status === up.status
                            )
                          ]
                        }
                        ）
                      </div>
                    )}
                  </>
                )}
              </TableCellWrap>
            );
          })}
      </TableRow>
    );
  };

  if (
    units === undefined ||
    Array.isArray(units) ||
    units.facility_units.length === 0
  ) {
    // ユニットなし
    return (
      <div className={classes.tableBody}>
        <Table key="monthly-report-table" className={classes.table}>
          <TableBody>
            {usagePerformance.map((up) => {
              return makeTr(up);
            })}
          </TableBody>
        </Table>
      </div>
    );
  }

  // ユニットあり
  return (
    <div key="table-body" className={classes.tableBody}>
      {units.facility_units
        .filter((unit) => unit.id !== null)
        .map((unit) => {
          return (
            <div key={`unit-wrapper-${unit.id}`}>
              <div key={`unit-name-${unit.id}`} className={classes.unitName}>
                {unit.unit_name}
              </div>
              <Table key={`report-table-${unit.id}`} className={classes.table}>
                <TableBody>
                  {unit.user_ids
                    .map((id) => {
                      const targetUp = usagePerformance.find(
                        (up) => up.users_in_facility_id === id
                      );
                      return targetUp !== undefined
                        ? makeTr(targetUp)
                        : undefined;
                    })
                    .filter((e) => e !== undefined)}
                </TableBody>
              </Table>
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(UsagePerformanceDetailModalContents);
