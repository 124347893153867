import _has from "lodash-es/has";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { FacilityState as FacilityStateV202104 } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

type FacilityStateAllVersion = FacilityState | FacilityStateV202104;

const isFacilityV202404 = (
  data: FacilityStateAllVersion
): data is FacilityState => {
  const keikakuShogaiji = data.facilities_keikakusodan_shogaijisodan;
  for (let i = 0; i < keikakuShogaiji.length; i += 1) {
    if (
      _has(keikakuShogaiji[i], "needMedicalCareHandicappedChildSupport202404")
    ) {
      return true;
    }
  }
  return false;
};

const isFacilityV202104 = (
  data: FacilityStateAllVersion
): data is FacilityStateV202104 => {
  const keikakuShogaiji = data.facilities_keikakusodan_shogaijisodan;
  for (let i = 0; i < keikakuShogaiji.length; i += 1) {
    if (
      _has(keikakuShogaiji[i], "needMedicalCareHandicappedChildSupport202404")
    ) {
      return false;
    }
  }
  return true;
};

/**
 * 事業所情報の「計画相談/障害児相談」データから計画相談のデータを探す
 * 存在しない場合は事業所で未契約であり、undefinedを返す
 * @param facility 事業所情報
 * @returns 計画相談のデータ or undefined
 */
export const findFacilityKeikakuSodan = <
  T extends FacilityStateAllVersion = FacilityStateV202104
>(
  facility: T
): T["facilities_keikakusodan_shogaijisodan"][number] | undefined => {
  if (isFacilityV202404(facility)) {
    return facility.facilities_keikakusodan_shogaijisodan.find(
      (f) => f.target_type === FACILITY_TARGET_TYPE_KEIKAKUSODAN
    );
  }

  if (isFacilityV202104(facility)) {
    return facility.facilities_keikakusodan_shogaijisodan.find(
      (f) => f.target_type === FACILITY_TARGET_TYPE_KEIKAKUSODAN
    );
  }

  return undefined;
};

/**
 * 事業所情報の「計画相談/障害児相談」データから障害児相談のデータを探す
 * 存在しない場合は事業所で未契約であり、undefinedを返す
 * @param facility 事業所情報
 * @returns 障害児相談のデータ or undefined
 */
export const findFacilityShogaijisodan = <
  T extends FacilityStateAllVersion = FacilityStateV202104
>(
  facility: T
): T["facilities_keikakusodan_shogaijisodan"][number] | undefined => {
  if (isFacilityV202404(facility)) {
    return facility.facilities_keikakusodan_shogaijisodan.find(
      (f) => f.target_type === FACILITY_TARGET_TYPE_SHOGAIJISODAN
    );
  }

  if (isFacilityV202104(facility)) {
    return facility.facilities_keikakusodan_shogaijisodan.find(
      (f) => f.target_type === FACILITY_TARGET_TYPE_SHOGAIJISODAN
    );
  }

  return undefined;
};

/**
 * typeConsultationの値に応じて、計画相談か障害児相談に対応した事業所名を取得する
 * @param facility 事業所情報
 * @returns 事業所名
 */
export const findOfficeName = (
  facility: FacilityState,
  typeConsultation?: number
): string => {
  let officeName = "";
  if (typeConsultation === TYPE_CONSULTATION_KEIKAKUSODAN) {
    const facilityKS = findFacilityKeikakuSodan(facility);
    if (facilityKS !== undefined) {
      officeName = facilityKS.name;
    }
  } else if (typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
    const facilitySS = findFacilityShogaijisodan(facility);
    if (facilitySS !== undefined) {
      officeName = facilitySS.name;
    }
  }
  return officeName;
};
