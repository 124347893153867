import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParamToPostSupportProcedureDetailAPI = {
  support_procedure_forms: {
    service_delivery_records_id: number | null;
    inout_results_id: number | null;
    support_procedure_forms_id: number | null;
    users_in_facility_id: number;
    target_date: string | null;
    title: string;
    number_of_practitioner: number;
    author?: number | null;
    author_name?: string;
    role: string;
    support_procedure_form_practitioners: {
      support_procedure_form_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner?: number | null;
      practitioner_name?: string;
      practitioner_num: number;
    }[];
    support_procedure_form_other_practitioners: {
      support_procedure_form_other_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner_name: string;
      facility_name: string;
      is_delete?: number;
    }[];
    support_procedure_form_details: {
      support_procedure_form_details_id: number | null;
      start_time: string | null;
      end_time: string | null;
      activity: string;
      service_procedure: string;
      check: number;
      appearance: string;
      is_delete?: number;
    }[];
    contact_information_plan: string;
    inquiry_items_plan: string;
    contact_information_record: string;
    inquiry_items_record: string;
    origin_support_procedure_forms_id?: number;
  };
  target_flg: number;
  target_object: {
    service_delivery_records_id: number | null;
    inout_results_id: number | null;
    support_procedure_forms_id: number | null;
    target_date: string | null;
  }[];
};

/**
 * 対象IDの支援手順書兼記録用紙を保存する
 */
export const postSupportProcedureDetail = async (
  data: RequestParamToPostSupportProcedureDetailAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_procedure/detail`;
  return request.post(url, data);
};
