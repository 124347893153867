import * as types from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/types";
import { RequestParamToPostAPI } from "@api/requests/v202104/inOutResults/postInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopy";
import { RequestParamToPostCopyCarePlanAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopyCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/v202104/inOutResults/deleteInOutResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchKYOTAKUKAIGODailyStarted = () =>
  ({ type: types.FETCH_KYOTAKUKAIGO_DAILY_STARTED } as const);
export const fetchKYOTAKUKAIGODaily = (res: types.ReportState["reportDaily"]) =>
  ({ type: types.FETCH_KYOTAKUKAIGO_DAILY, payload: res } as const);
export const fetchKYOTAKUKAIGODailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KYOTAKUKAIGO_DAILY_FAILED, error: err } as const);
export const fetchKYOTAKUKAIGOUserStarted = () =>
  ({ type: types.FETCH_KYOTAKUKAIGO_MONTHLY_STARTED } as const);
export const fetchKYOTAKUKAIGOUser = (
  res: types.ReportState["reportMonthly"]
) => ({ type: types.FETCH_KYOTAKUKAIGO_MONTHLY, payload: res } as const);
export const fetchKYOTAKUKAIGOUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KYOTAKUKAIGO_MONTHLY_FAILED, error: err } as const);

export const postKYOTAKUKAIGOReportStarted = () =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_STARTED } as const);
export const postKYOTAKUKAIGOReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_DAILY, payload: req } as const);
export const postKYOTAKUKAIGOReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_MONTHLY, payload: req } as const);
export const postKYOTAKUKAIGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_FAILED, error: err } as const);

export const postKYOTAKUKAIGOReportCopyStarted = () =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_COPY_STARTED } as const);
export const postKYOTAKUKAIGOReportCopy = (req: RequestParamToPostCopyAPI) =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_COPY, payload: req } as const);
export const postKYOTAKUKAIGOReportCopyFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_COPY_FAILED, error: err } as const);

export const postKYOTAKUKAIGOReportCopyCarePlanStarted = () =>
  ({ type: types.POST_KYOTAKUKAIGO_REPORT_COPY_CARE_PLAN_STARTED } as const);
export const postKYOTAKUKAIGOReportCopyCarePlan = (
  req: RequestParamToPostCopyCarePlanAPI
) =>
  ({
    type: types.POST_KYOTAKUKAIGO_REPORT_COPY_CARE_PLAN,
    payload: req
  } as const);
export const postKYOTAKUKAIGOReportCopyCarePlanFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_KYOTAKUKAIGO_REPORT_COPY_CARE_PLAN_FAILED,
    error: err
  } as const);

export const deleteKYOTAKUKAIGOReportStarted = () =>
  ({ type: types.DELETE_KYOTAKUKAIGO_REPORT_STARTED } as const);
export const deleteKYOTAKUKAIGOReport = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_KYOTAKUKAIGO_REPORT, payload: req } as const);
export const deleteKYOTAKUKAIGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_KYOTAKUKAIGO_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchKYOTAKUKAIGODailyStarted>
  | ReturnType<typeof fetchKYOTAKUKAIGODaily>
  | ReturnType<typeof fetchKYOTAKUKAIGODailyFailed>
  | ReturnType<typeof fetchKYOTAKUKAIGOUserStarted>
  | ReturnType<typeof fetchKYOTAKUKAIGOUser>
  | ReturnType<typeof fetchKYOTAKUKAIGOUserFailed>
  | ReturnType<typeof postKYOTAKUKAIGOReportStarted>
  | ReturnType<typeof postKYOTAKUKAIGOReportDaily>
  | ReturnType<typeof postKYOTAKUKAIGOReportUser>
  | ReturnType<typeof postKYOTAKUKAIGOReportFailed>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopyStarted>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopy>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopyFailed>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopyCarePlanStarted>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopyCarePlan>
  | ReturnType<typeof postKYOTAKUKAIGOReportCopyCarePlanFailed>
  | ReturnType<typeof deleteKYOTAKUKAIGOReportStarted>
  | ReturnType<typeof deleteKYOTAKUKAIGOReport>
  | ReturnType<typeof deleteKYOTAKUKAIGOReportFailed>;
