import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FormikProps } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikLinkAddress from "@components/molecules/FormikLinkAddress";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { DEFAULT_CITY_STATE } from "@constants/variables";
import {
  DISABILITY_CERTIFICATE_BODY,
  DISABILITY_CERTIFICATE_SPIRIT,
  DISABILITY_CERTIFICATE_REHABILITATION
} from "@constants/mgr/SHUROTEICHAKU/variables";
import { CityParams } from "@stores/domain/city/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { UsersValues } from "@initialize/mgr/SHUROTEICHAKU/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    checkboxContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: 480,
      marginTop: 6,
      marginBottom: 20,
      marginLeft: 16,
      "& > div": {
        width: 160,
        marginBottom: 0
      }
    },
    selectboxContainer: {
      marginTop: 8,
      marginLeft: 16
    },
    helpMark: {
      verticalAlign: "sub"
    },
    labelWrap: {
      position: "relative"
    },
    label: {
      position: "absolute",
      top: -5,
      left: 32
    },
    address: {
      marginBottom: "12px"
    },
    groupAddress: {
      marginLeft: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type DispatchProps = {
  fetchCity: (params: CityParams) => void;
  clearCity: () => void;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

// selectBoxのlink情報マップ
const linkSelectBoxMap = {
  "basic.cityId": ["serviceUse.subsidizedCityId"]
};

class BasicFields extends React.Component<Props> {
  private handleChangeClassRehabilitationOther = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    if (value === "0") {
      this.props.setFormikFieldValue(
        "basic.disabilityClassRehabilitationOther",
        ""
      );
    }
  };

  /**
   * 市区町村及び関連する地域区分と市区町村番号を初期化
   */
  private resetSelectCity = (cityIdName: string): void => {
    this.props.setFormikFieldValue(cityIdName, DEFAULT_CITY_STATE.value);
    const linkList = linkSelectBoxMap[cityIdName];
    if (Array.isArray(linkList)) {
      linkList.forEach((key) => {
        this.props.setFormikFieldValue(key, DEFAULT_CITY_STATE.value);
      });
    }
  };

  /**
   * prefectureIdに更新があった時、cityListの再取得を行う
   */
  private ifNeededFetchCity = (
    nextPrefectureId: string,
    prevPrefectureId: string
  ): void => {
    if (nextPrefectureId === DEFAULT_CITY_STATE.value) {
      this.props.clearCity();
      return;
    }
    if (
      nextPrefectureId !== DEFAULT_CITY_STATE.value &&
      nextPrefectureId !== prevPrefectureId
    ) {
      this.props.fetchCity({ prefectureName: nextPrefectureId });
    }
  };

  // selectBoxのLink
  private onChangeLinkAddress = (
    linkKey: string,
    nextValue: string,
    beforeValue: string
  ): void => {
    this.resetSelectCity(linkKey);
    this.ifNeededFetchCity(nextValue, beforeValue);
  };

  public render(): JSX.Element {
    return (
      <FormPaper>
        <div style={{ marginBottom: 32 }}>
          <SectionTitle label="基本情報" />
        </div>
        <FormGroup row>
          <FormikTextField
            name="basic.nameSei"
            label="名前"
            placeholder="山田"
            maxLength={255}
            size="medium"
          />
          <FormikTextField
            name="basic.nameMei"
            placeholder="太郎"
            maxLength={255}
            size="medium"
            style={{ paddingTop: 16 }}
          />
        </FormGroup>
        <FormGroup row>
          <FormikTextField
            name="basic.nameSeiKana"
            label="フリガナ"
            placeholder="ヤマダ"
            maxLength={255}
            size="medium"
          />
          <FormikTextField
            name="basic.nameMeiKana"
            placeholder="タロウ"
            maxLength={255}
            size="medium"
            style={{ paddingTop: 16 }}
          />
        </FormGroup>
        <FormikTextField
          name="basic.recipientNumber"
          label="受給者証番号"
          placeholder="0000000000"
          maxLength={10}
          size="medium"
          style={{ marginBottom: 12 }}
        />
        <FormikCheckbox
          name="basic.noneRecipientNumberFlag"
          label="受給者証未発行もしくは見学中の利用者"
        />
        <Typography>障害種別（複数選択可）</Typography>
        <div className={this.props.classes.checkboxContainer}>
          <FormikCheckbox name="basic.classifyPhysicalFlag" label="身体障害" />
          <FormikCheckbox
            name="basic.classifyIntelligenceFlag"
            label="知的障害"
          />
          <FormikCheckbox name="basic.classifyMindFlag" label="精神障害" />
          <FormikCheckbox name="basic.classifyGrowthFlag" label="発達障害" />
          <FormikCheckbox
            name="basic.classifyBrainFlag"
            label="高次脳機能障害"
          />
          <FormikCheckbox
            name="basic.classifyIncurableFlag"
            label="難病等対象者"
          />
          <FormikCheckbox name="basic.classifyHandicappedFlag" label="障害児" />
        </div>
        <Typography>障害者手帳 等級</Typography>
        <div className={this.props.classes.selectboxContainer}>
          <FormikSelect
            name="basic.disabilityClassBody"
            label="身体"
            placeholder="交付なし"
            options={DISABILITY_CERTIFICATE_BODY}
          />
          <FormikSelect
            name="basic.disabilityClassSpirit"
            label="精神"
            placeholder="交付なし"
            options={DISABILITY_CERTIFICATE_SPIRIT}
          />
          <FormGroup row>
            <FormikSelect
              name="basic.disabilityClassRehabilitation"
              label="療育"
              placeholder="交付なし"
              options={DISABILITY_CERTIFICATE_REHABILITATION}
              onChangeHook={this.handleChangeClassRehabilitationOther}
              style={{ marginRight: 16, marginBottom: 24 }}
            />
            <div className={this.props.classes.labelWrap}>
              <FormikTextField
                name="basic.disabilityClassRehabilitationOther"
                label="等級"
                maxLength={10}
                size="medium"
                disabled={
                  this.props.formikProps.values.basic
                    .disabilityClassRehabilitation === "0"
                }
                style={{ marginRight: 16, marginBottom: 24 }}
              />
              <div className={this.props.classes.label}>
                <span className={this.props.classes.helpMark}>
                  <HelpToolTip
                    placement="right-end"
                    title={
                      <HelpTipMessages name="disabilityClassRehabilitation" />
                    }
                  />
                </span>
              </div>
            </div>
          </FormGroup>
          <FormikCheckbox
            name="basic.classifyIncurableFlg"
            label="難病（手帳なし）"
          />
        </div>
        <FormikRadioButtons
          name="basic.gender"
          label="性別"
          options={[
            {
              label: "男性",
              value: "1"
            },
            {
              label: "女性",
              value: "2"
            }
          ]}
        />
        <FormikSelectDateNotSelectedDefault
          name="basic.dateOfBirth"
          label="生年月日"
          overrideYearFrom={1926}
          setFormikFieldValue={this.props.setFormikFieldValue}
        />
        <Typography className={this.props.classes.address}>
          受給者証に記載の住所（給付費請求先の自治体）
        </Typography>
        <div className={this.props.classes.groupAddress}>
          <FormikPostalCode
            name="basic.postalCode"
            label="郵便番号"
            placeholder="000-0000"
            maxLength={8}
            startAdornmentLabel="〒"
          />
          <FormikLinkAddress
            prefectureIdName="basic.prefectureId"
            cityIdName="basic.cityId"
            formikProps={this.props.formikProps}
            showRegionType={false}
            onChangePrefecture={this.onChangeLinkAddress}
            billingLabel
          />
          <FormikTextField
            name="basic.restAddress"
            label="市区町村以降の住所"
            size="superLong"
            helperText="請求先と居住地が異なる場合は、ここに居住地の住所を全て入力してください"
          />
        </div>
        <FormikTextField
          name="basic.tel"
          type="tel"
          label="電話番号"
          placeholder="0000000000"
          helperText="ハイフンなしで入力"
          maxLength={12}
        />
        <FormikTextField
          name="basic.email"
          label="メールアドレス"
          size="superLong"
          helperText="半角英数字で入力"
          maxLength={255}
        />
        <FormGroup row>
          <FormikTextField
            name="basic.guardianName"
            label="保護者氏名"
            maxLength={48}
          />
          <FormikTextField
            name="basic.guardianRelation"
            label="続柄"
            maxLength={12}
          />
        </FormGroup>
        <FormikTextField
          name="basic.guardianTel"
          label="緊急連絡先"
          placeholder="0000000000"
          helperText="ハイフンなしで入力"
          maxLength={12}
        />
        <FormikTextField
          name="basic.memo"
          label="備考"
          size="superLong"
          style={{ marginBottom: 0 }}
          multiline
        />
      </FormPaper>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { cityDispatch } = dispatches;
  const cityDispatches = cityDispatch(dispatch);
  return {
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    clearCity: cityDispatches.clearCity
  };
};

export default connect<void, DispatchProps>(
  null,
  mapDispatchToProps
)(withStyles(styles)(BasicFields));
