/**
 * 時間範囲が指定内か
 */

export const checkTimeRangeLimit = (
  startTime: string,
  startTimeClass: string,
  outTime: string,
  outTimeClass: string,
  limit: number,
  option = "時間"
): string | undefined => {
  let errorMessage;
  // 日跨ぎしている場合、時間に+24する
  const start =
    startTimeClass === "1"
      ? parseInt(startTime.replace(":", ""), 10) + 2400
      : parseInt(startTime.replace(":", ""), 10);
  const end =
    outTimeClass === "1"
      ? parseInt(outTime.replace(":", ""), 10) + 2400
      : parseInt(outTime.replace(":", ""), 10);

  if (end - start > limit * 100) {
    errorMessage = `${option}が${limit}時間を超えています`;
  }

  return errorMessage;
};
