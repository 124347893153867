import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import dispatches from "@stores/dispatches";

import { EvaluationAPrint } from "@components/organisms/record/print/EvaluationAPrint";
import { EvaluationSHUROTEICHAKUPrint } from "@components/organisms/record/print/EvaluationSHUROTEICHAKUPrint";
import { EvaluationSEIKATSUKAIGOPrint } from "@components/organisms/record/print/EvaluationSEIKATSUKAIGOPrint";
import { FacilityType } from "@constants/variables";
import { EvaluationIBPrint } from "@components/organisms/record/print/EvaluationIBPrint";
import { EvaluationGroupHomePrint } from "@components/organisms/record/print/EvaluationGroupHomePrint";
/**
 * 評価振り返りのプリント画面
 */

type StateProps = {
  userState: UserState;
};

type DispatchProps = {
  getPrivateSupportPlanRecordData: (
    uifId: string,
    supportPlanId: string
  ) => void;
  getPrivateSupportPlanRecordDataSHUROTEICHAKU: (
    uifId: string,
    supportPlanId: string
  ) => void;
  getUserDetailInFacilityDataIAB: (
    uifId: string,
    facility_type: FacilityType
  ) => void;
  getUserDetailInFacilityDataSHUROTEICHAKU: (uifId: string) => void;
  getUserDetailInFacilityDataSEIKATSUKAIGO: (uifId: string) => void;
  getUserDetailInFacilityDataGroupHome: (uifId: string) => void;
  getFacilityIAB: () => void;
  getFacilitySHUROTEICHAKU: () => void;
  getFacilitySEIKATSUKAIGO: () => void;
  getFacilityGroupHome: () => void;
};

type Props = StateProps &
  DispatchProps &
  RouteComponentProps<{
    uifId: string;
    supportPlanId: string;
  }>;

const EvaluationPrintCore = (props: Props): JSX.Element => {
  const { uifId } = props.match.params;
  const { supportPlanId } = props.match.params;
  const query = props.location.search;
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";
  const isFacilityTypeA = props.userState.facility_type === FacilityType.A;
  const isFacilityTypeB = props.userState.facility_type === FacilityType.B;
  const isFacilityTypeIKOU =
    props.userState.facility_type === FacilityType.IKOU;
  const isFacilityTypeSHUROTEICHAKU =
    props.userState.facility_type === FacilityType.SHUROTEICHAKU;
  const isFacilityTypeSEIKATSUKAIGO =
    props.userState.facility_type === FacilityType.SEIKATSUKAIGO;
  const isFacilityTypeGROUP_HOME =
    props.userState.facility_type === FacilityType.GROUP_HOME;

  // 表記統一までの一時的対応
  const secondPageName =
    props.userState.facility_type === FacilityType.SHUROTEICHAKU ||
    props.userState.facility_type === FacilityType.SEIKATSUKAIGO ||
    props.userState.facility_type === FacilityType.GROUP_HOME
      ? "個別支援計画"
      : "個別支援計画一覧";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" },
    { pathName: secondPageName, path: `/record/${uifId}/support_plan` }
  ];

  React.useEffect(() => {
    if (isFacilityTypeA || isFacilityTypeB || isFacilityTypeIKOU) {
      props.getUserDetailInFacilityDataIAB(
        uifId,
        props.userState.facility_type
      );
      props.getPrivateSupportPlanRecordData(uifId, supportPlanId);
      props.getFacilityIAB();
    } else if (isFacilityTypeSHUROTEICHAKU) {
      props.getUserDetailInFacilityDataSHUROTEICHAKU(uifId);
      props.getPrivateSupportPlanRecordDataSHUROTEICHAKU(uifId, supportPlanId);
      props.getFacilitySHUROTEICHAKU();
    } else if (isFacilityTypeSEIKATSUKAIGO) {
      props.getPrivateSupportPlanRecordData(uifId, supportPlanId);
      props.getUserDetailInFacilityDataSEIKATSUKAIGO(uifId);
      props.getFacilitySEIKATSUKAIGO();
    } else if (isFacilityTypeGROUP_HOME) {
      props.getUserDetailInFacilityDataGroupHome(uifId);
      props.getPrivateSupportPlanRecordData(uifId, supportPlanId);
      props.getFacilityGroupHome();
    }
  }, []);

  return (
    <AdminTemplate pageName="モニタリング記録" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        {isFacilityTypeA && (
          <EvaluationAPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        )}
        {(isFacilityTypeB || isFacilityTypeIKOU) && (
          <EvaluationIBPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        )}
        {isFacilityTypeSHUROTEICHAKU && (
          <EvaluationSHUROTEICHAKUPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        )}
        {isFacilityTypeSEIKATSUKAIGO && (
          <EvaluationSEIKATSUKAIGOPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        )}
        {isFacilityTypeGROUP_HOME && (
          <EvaluationGroupHomePrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        )}
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    IAB,
    SHUROTEICHAKU,
    SEIKATSUKAIGO,
    GroupHome,
    supportPlanDispatcher
  } = dispatches;
  const getPrivateSupportPlanRecordData = (
    uifId: string,
    supportPlanId: string
  ): Promise<void> =>
    supportPlanDispatcher(dispatch).fetchPrivateSupportPlan(
      uifId,
      supportPlanId
    );
  const getPrivateSupportPlanRecordDataSHUROTEICHAKU = (
    uifId: string,
    supportPlanId: string
  ): Promise<void> =>
    SHUROTEICHAKU.supportPlanDispatcher(dispatch).fetchPrivateSupportPlan(
      uifId,
      supportPlanId
    );
  const getUserDetailInFacilityDataIAB = (uifId: string): Promise<void> => {
    return IAB.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  };
  const getUserDetailInFacilityDataSHUROTEICHAKU = (
    uifId: string
  ): Promise<void> => {
    return SHUROTEICHAKU.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  };
  const getUserDetailInFacilityDataGroupHome = (
    uifId: string
  ): Promise<void> => {
    return GroupHome.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  };
  const getUserDetailInFacilityDataSEIKATSUKAIGO = (
    uifId: string
  ): Promise<void> => {
    return SEIKATSUKAIGO.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  };
  const getFacilityIAB = (): Promise<void> => {
    return IAB.facilityDispatcher(dispatch).fetch();
  };
  const getFacilitySHUROTEICHAKU = (): Promise<void> => {
    return SHUROTEICHAKU.facilityDispatcher(dispatch).fetch();
  };
  const getFacilityGroupHome = (): Promise<void> => {
    return GroupHome.facilityDispatcher(dispatch).fetch();
  };
  const getFacilitySEIKATSUKAIGO = (): Promise<void> => {
    return SEIKATSUKAIGO.facilityDispatcher(dispatch).fetch();
  };

  return {
    getPrivateSupportPlanRecordData,
    getPrivateSupportPlanRecordDataSHUROTEICHAKU,
    getUserDetailInFacilityDataIAB,
    getUserDetailInFacilityDataSHUROTEICHAKU,
    getUserDetailInFacilityDataGroupHome,
    getUserDetailInFacilityDataSEIKATSUKAIGO,
    getFacilityIAB,
    getFacilitySHUROTEICHAKU,
    getFacilityGroupHome,
    getFacilitySEIKATSUKAIGO
  };
};

export const EvaluationPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(EvaluationPrintCore);
