import * as actions from "./actions";
import * as types from "./types";

const initialState: types.UsageResultsState = {
  shareSupportReportDate: "",
  usageResults: []
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.UsageResultsState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH:
      return { ...action.payload };
    case types.FETCH_FAILED:
      return { ...state };
    case types.POST_STARTED:
      return { ...state };
    case types.POST:
      return { ...action.payload };
    case types.POST_FAILED:
      return { ...state };
    case types.UNSET_REPORT:
      return { ...initialState };
    default:
      return state;
  }
};

export default reducer;
