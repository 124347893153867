import { OptionInterface } from "@components/atoms/DropDown";
import {
  FACILITY,
  FACILITY_201910,
  FACILITY_202104,
  REPORT_DAILY,
  REPORT_DAILY_201910,
  REPORT_DAILY_202104,
  REPORT_USERS,
  REPORT_USERS_201910,
  REPORT_USERS_202104,
  USERS_ID,
  USERS_ID_201910,
  USERS_ID_202104
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104
} from "@constants/variables";

export const ReportTabInfo = {
  DAILY: "0",
  MONTHLY: "1"
};

export const REPORT_TABS_INFO = [
  { label: "日ごと", value: ReportTabInfo.DAILY },
  { label: "月ごと", value: ReportTabInfo.MONTHLY }
];

// サービス提供状況
export enum StatusType {
  NONE = "0",
  LODGING = "1",
  HOSPITALIZATION_FIRST = "2",
  HOSPITALIZATION = "3",
  HOSPITALIZATION_LAST = "4",
  HOSPITALIZATION_TEMPORARY_STAY_INSIDE = "17",
  HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE = "18",
  OVERNIGHT_FIRST = "5",
  OVERNIGHT = "6",
  OVERNIGHT_LAST = "7",
  HOSPITALIZATION_FROM_OVERNIGHT = "8",
  OVERNIGHT_FROM_HOSPITALIZATION = "9",
  HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT = "10",
  OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION = "11",
  EXPERIENCE_LODGING_FIRST = "13",
  EXPERIENCE_LODGING = "14",
  EXPERIENCE_LODGING_LAST = "15",
  REGIONAL_MIGRATION_SUPPORT = "90"
}

export const STATUS_TYPE: OptionInterface[] = [
  { label: "-", value: StatusType.NONE },
  { label: "宿泊", value: StatusType.LODGING },
  { label: "入院（初日）", value: StatusType.HOSPITALIZATION_FIRST },
  { label: "入院（中日）", value: StatusType.HOSPITALIZATION },
  { label: "入院（最終日）", value: StatusType.HOSPITALIZATION_LAST },
  {
    label: "入院（施設に一時外泊）",
    value: StatusType.HOSPITALIZATION_TEMPORARY_STAY_INSIDE
  },
  {
    label: "入院（施設以外に一時外泊）",
    value: StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE
  },
  { label: "外泊（初日）", value: StatusType.OVERNIGHT_FIRST },
  { label: "外泊（中日）", value: StatusType.OVERNIGHT },
  { label: "外泊（最終日）", value: StatusType.OVERNIGHT_LAST },
  {
    label: "入院(最終日)→外泊(初日)",
    value: StatusType.HOSPITALIZATION_FROM_OVERNIGHT
  },
  {
    label: "外泊(最終日)→入院(初日)",
    value: StatusType.OVERNIGHT_FROM_HOSPITALIZATION
  },
  {
    label: "入院(最終日)→共同生活住居に戻る→外泊(初日)",
    value: StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT
  },
  {
    label: "外泊(最終日)→共同生活住居に戻る→入院(初日)",
    value: StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION
  },
  { label: "体験宿泊（初日）", value: StatusType.EXPERIENCE_LODGING_FIRST },
  { label: "体験宿泊（中日）", value: StatusType.EXPERIENCE_LODGING },
  { label: "体験宿泊（最終日）", value: StatusType.EXPERIENCE_LODGING_LAST },
  {
    label: "地域移行加算（退所後）",
    value: StatusType.REGIONAL_MIGRATION_SUPPORT
  }
];

// 地域移行加算（退所後）なしのデータ
export const STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT: OptionInterface[] = STATUS_TYPE.filter(
  (i) => i.value !== StatusType.REGIONAL_MIGRATION_SUPPORT
);

// 地域移行加算（退所後）だけのデータ
export const STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT: OptionInterface[] = STATUS_TYPE.filter(
  (i) =>
    i.value === StatusType.REGIONAL_MIGRATION_SUPPORT ||
    i.value === StatusType.NONE
);

export const STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT: string[] = [
  StatusType.HOSPITALIZATION_FIRST,
  StatusType.HOSPITALIZATION,
  StatusType.HOSPITALIZATION_LAST,
  StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE,
  StatusType.OVERNIGHT_FIRST,
  StatusType.OVERNIGHT,
  StatusType.OVERNIGHT_LAST
];

// 実績一覧画面用
export const STATUS_TYPE_JISSEKI: OptionInterface[] = [
  { label: "-", value: StatusType.NONE },
  { label: "宿泊", value: StatusType.LODGING },
  { label: "入院（初日）", value: StatusType.HOSPITALIZATION_FIRST },
  { label: "入院（中日）", value: StatusType.HOSPITALIZATION },
  { label: "入院（最終日）", value: StatusType.HOSPITALIZATION_LAST },
  {
    label: "入院（施設に一時外泊）",
    value: StatusType.HOSPITALIZATION_TEMPORARY_STAY_INSIDE
  },
  {
    label: "入院（施設以外に一時外泊）",
    value: StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE
  },
  { label: "外泊（初日）", value: StatusType.OVERNIGHT_FIRST },
  { label: "外泊（中日）", value: StatusType.OVERNIGHT },
  { label: "外泊（最終日）", value: StatusType.OVERNIGHT_LAST },
  {
    label: "入院(終)→外泊(初)",
    value: StatusType.HOSPITALIZATION_FROM_OVERNIGHT
  },
  {
    label: "外泊(終)→入院(初)",
    value: StatusType.OVERNIGHT_FROM_HOSPITALIZATION
  },
  {
    label: "入院(終)→住居→外泊(初)",
    value: StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT
  },
  {
    label: "外泊(終)→住居→入院(初)",
    value: StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION
  },
  { label: "体験宿泊（初日）", value: StatusType.EXPERIENCE_LODGING_FIRST },
  { label: "体験宿泊（中日）", value: StatusType.EXPERIENCE_LODGING },
  { label: "体験宿泊（最終日）", value: StatusType.EXPERIENCE_LODGING_LAST },
  {
    label: "地域移行加算（退所後）",
    value: StatusType.REGIONAL_MIGRATION_SUPPORT
  }
];

// 地域移行加算（退所後）なしのデータ(実績一覧画面用)
export const STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT_JISSEKI: OptionInterface[] = STATUS_TYPE_JISSEKI.filter(
  (i) => i.value !== StatusType.REGIONAL_MIGRATION_SUPPORT
);

// 地域移行加算（退所後）だけのデータ(実績一覧画面用)
export const STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT_JISSEKI: OptionInterface[] = STATUS_TYPE_JISSEKI.filter(
  (i) =>
    i.value === StatusType.REGIONAL_MIGRATION_SUPPORT ||
    i.value === StatusType.NONE
);

// 入院・外泊
export enum HospitalizationOvernightstay {
  NONE = "0",
  ONE = "1",
  TWO = "2",
  SPECIAL = "3"
}

export const STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY: string[] = [
  StatusType.NONE,
  StatusType.LODGING,
  StatusType.HOSPITALIZATION_FIRST,
  StatusType.HOSPITALIZATION_TEMPORARY_STAY_INSIDE,
  StatusType.OVERNIGHT_FIRST,
  StatusType.OVERNIGHT_LAST,
  StatusType.OVERNIGHT_FROM_HOSPITALIZATION,
  StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION,
  StatusType.EXPERIENCE_LODGING_FIRST,
  StatusType.EXPERIENCE_LODGING,
  StatusType.EXPERIENCE_LODGING_LAST,
  StatusType.REGIONAL_MIGRATION_SUPPORT
];

// 入院・外泊
export enum HospitalizationDays {
  NONE = "-",
  ZERO = "0"
}

export const STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS: string[] = [
  StatusType.NONE,
  StatusType.LODGING,
  StatusType.EXPERIENCE_LODGING_FIRST,
  StatusType.EXPERIENCE_LODGING,
  StatusType.EXPERIENCE_LODGING_LAST,
  StatusType.REGIONAL_MIGRATION_SUPPORT
];

export const STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS: string[] = [
  StatusType.HOSPITALIZATION_FIRST,
  StatusType.OVERNIGHT_FIRST,
  StatusType.HOSPITALIZATION_FROM_OVERNIGHT,
  StatusType.OVERNIGHT_FROM_HOSPITALIZATION,
  StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT,
  StatusType.OVERNIGHT_COMMUNITY_LIFE_HOSPITALIZATION
];

// 栄養マネジメント加算と経口移行加算の非活性ステータス
export const STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER: string[] = [
  StatusType.HOSPITALIZATION,
  StatusType.HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE,
  StatusType.OVERNIGHT,
  StatusType.HOSPITALIZATION_FROM_OVERNIGHT,
  StatusType.OVERNIGHT_FROM_HOSPITALIZATION,
  StatusType.EXPERIENCE_LODGING,
  StatusType.REGIONAL_MIGRATION_SUPPORT
];
// 入院・外泊時加算入院時支援特別加算で「特別加算」のみ選択できるサービス提供の状況
export const STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL: string[] = [
  StatusType.HOSPITALIZATION_LAST,
  StatusType.HOSPITALIZATION_FROM_OVERNIGHT,
  StatusType.HOSPITALIZATION_COMMUNITY_LIFE_OVERNIGHT
];

export const HOSPITALIZATION_OVERNIGHTSTAY: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  { label: "Ⅰ", value: HospitalizationOvernightstay.ONE },
  { label: "Ⅱ", value: HospitalizationOvernightstay.TWO },
  { label: "特別加算(訪問)", value: HospitalizationOvernightstay.SPECIAL }
];

export const HOSPITALIZATION_OVERNIGHTSTAY_ONLY_SPECIAL: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  { label: "特別加算(訪問)", value: HospitalizationOvernightstay.SPECIAL }
];

export const HOSPITALIZATION_OVERNIGHTSTAY_WITHOUT_SPECIAL: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  { label: "Ⅰ", value: HospitalizationOvernightstay.ONE },
  { label: "Ⅱ", value: HospitalizationOvernightstay.TWO }
];

export const HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  { label: "入院・外泊時加算(Ⅰ)", value: HospitalizationOvernightstay.ONE },
  { label: "入院・外泊時加算(Ⅱ)", value: HospitalizationOvernightstay.TWO },
  {
    label: "入院時支援特別加算(訪問)",
    value: HospitalizationOvernightstay.SPECIAL
  }
];

export const HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_ONLY_SPECIAL: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  {
    label: "入院時支援特別加算(訪問)",
    value: HospitalizationOvernightstay.SPECIAL
  }
];

export const HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_WITHOUT_SPECIAL: OptionInterface[] = [
  { label: "-", value: HospitalizationOvernightstay.NONE },
  { label: "入院・外泊時加算(Ⅰ)", value: HospitalizationOvernightstay.ONE },
  { label: "入院・外泊時加算(Ⅱ)", value: HospitalizationOvernightstay.TWO }
];

// 地域移行支援
export enum RegionalTransition {
  NONE = "0",
  IMPLEMENTATION = "1"
}

export const REGIONAL_TRANSITION: OptionInterface[] = [
  { label: "-", value: RegionalTransition.NONE },
  { label: "実施", value: RegionalTransition.IMPLEMENTATION }
];

// 光熱水費
export enum CollectionOfUtilityFee {
  NONE = "0",
  IMPLEMENTATION = "1"
}

export const COLLECTION_OF_UTILITY_FEE: OptionInterface[] = [
  { label: "-", value: CollectionOfUtilityFee.NONE },
  { label: "あり", value: CollectionOfUtilityFee.IMPLEMENTATION }
];

// 食事提供(朝)
export enum FoodBreakfast {
  NONE = "0",
  IMPLEMENTATION = "1"
}

export const FOOD_BREAKFAST: OptionInterface[] = [
  { label: "-", value: FoodBreakfast.NONE },
  { label: "あり", value: FoodBreakfast.IMPLEMENTATION }
];

// 食事提供(昼)
export enum FoodLunch {
  NONE = "0",
  IMPLEMENTATION = "1"
}

export const FOOD_LUNCH: OptionInterface[] = [
  { label: "-", value: FoodLunch.NONE },
  { label: "あり", value: FoodLunch.IMPLEMENTATION }
];

// 食事提供(夜)
export enum FoodSupper {
  NONE = "0",
  IMPLEMENTATION = "1"
}

export const FOOD_SUPPER: OptionInterface[] = [
  { label: "-", value: FoodSupper.NONE },
  { label: "あり", value: FoodSupper.IMPLEMENTATION }
];

// 食事単価
export enum foodExpenses {
  NONE = "0",
  PER_FOOD = "1",
  PER_DAY = "2"
}

export const FOOD_EXPENSES_TYPE_LIST: OptionInterface[] = [
  { label: "負担なし", value: foodExpenses.NONE },
  { label: "食事ごと", value: foodExpenses.PER_FOOD },
  { label: "１日ごと", value: foodExpenses.PER_DAY }
];

// 経口移行加算
export enum OralTransition {
  NONE = "0",
  ADDITION = "1"
}

export const ORAL_TRANSITION: OptionInterface[] = [
  { label: "-", value: OralTransition.NONE },
  { label: "経口移行加算対象", value: OralTransition.ADDITION }
];

// 経口維持加算
export enum OralPreservation {
  NONE = "0",
  ADDITION_ONE = "1",
  ADDITION_TWO = "2"
}

export const ORAL_PRESERVATION: OptionInterface[] = [
  { label: "-", value: OralPreservation.NONE },
  { label: "経口維持加算Ⅰ", value: OralPreservation.ADDITION_ONE },
  { label: "経口維持加算Ⅱ", value: OralPreservation.ADDITION_TWO }
];

// 光熱水費
export enum utility {
  NONE = "0",
  PER_DAY = "1",
  PER_MONTH = "2"
}

export const UTILITY_TYPE_LIST: OptionInterface[] = [
  { label: "負担なし", value: utility.NONE },
  { label: "日ごと", value: utility.PER_DAY },
  { label: "月ごと", value: utility.PER_MONTH }
];

export const NIGHTTIME_PLACEMENT_LIST: OptionInterface[] = [
  { label: "配置なし", value: "0" },
  { label: "配置あり（生活支援員）", value: "1" },
  { label: "配置あり（看護職員）", value: "2" }
];

export const SERIOUS_DISABILITY_LIST: OptionInterface[] = [
  { label: "なし", value: "0" },
  { label: "重度障害者支援加算（Ⅰ）", value: "1" },
  { label: "重度障害者支援加算（Ⅰ）（重度）", value: "2" },
  { label: "重度障害者支援加算（Ⅱ）", value: "3" }
];

// 重度障害者支援加算
// 令和6年度報酬改定で追加
export const SERIOUS_DISABILITY_LIST_202404: OptionInterface[] = [
  { label: "なし", value: "0" },
  { label: "重度障害者支援加算（Ⅰ）", value: "1" },
  { label: "重度障害者支援加算（Ⅰ）（重度）", value: "2" },
  { label: "重度障害者支援加算（Ⅱ）または（Ⅲ）", value: "3" }
];

export const NUTRITIONIST_PLACEMENT_LIST: OptionInterface[] = [
  { label: "配置あり（常勤）", value: "1" },
  { label: "配置あり（非常勤）", value: "2" },
  { label: "配置なし", value: "0" }
];

export const STAFF_TREATMENT_SPECIFIC_SYSTEM_LIST: OptionInterface[] = [
  { label: "なし", value: "1" },
  { label: "特定処遇改善加算", value: "2" }
];

export const ORAL_HYGIENE_MANAGEMENT_SYSTEM_LIST: OptionInterface[] = [
  { label: "体制なし", value: "0" },
  { label: "体制あり", value: "1" }
];

export const INTENSIVE_SUPPORT_FLG: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅰ+Ⅱ", value: "3" }
];

// 登録できる項目一覧
export const BulkRegistrationItemList = {
  STATUS_TYPE: "statusType",
  HOSPITALIZATION_OVERNIGHTSTAY: "hospitalizationOvernightstay",
  HOSPITALIZATION_DAYS: "hospitalizationDays",
  REGIONAL_TRANSITION: "regionalTransition",
  COLLECTION_OF_UTILITY_FEE: "collectionOfUtilityFee",
  FOOD_BREAKFAST: "foodBreakfast",
  FOOD_LUNCH: "foodLunch",
  FOOD_SUPPER: "foodSupper",
  NUTRITION_MANAGEMENT: "nutritionManagement",
  ORAL_TRANSITION: "oralTransition"
} as const;
export type BulkRegistrationItemList = typeof BulkRegistrationItemList[keyof typeof BulkRegistrationItemList];

// usagePerformance項目一覧
export const USAGE_PERFORMANCE_ITEM_LIST: BulkRegistrationItemList[] = [
  BulkRegistrationItemList.STATUS_TYPE
];

// usagePerformanceSHISETSUNYUSHO項目一覧
export const USAGE_PERFORMANCE_SHISETSUNYUSHO_ITEM_LIST: BulkRegistrationItemList[] = [
  BulkRegistrationItemList.HOSPITALIZATION_OVERNIGHTSTAY,
  BulkRegistrationItemList.HOSPITALIZATION_DAYS,
  BulkRegistrationItemList.REGIONAL_TRANSITION,
  BulkRegistrationItemList.COLLECTION_OF_UTILITY_FEE,
  BulkRegistrationItemList.FOOD_BREAKFAST,
  BulkRegistrationItemList.FOOD_LUNCH,
  BulkRegistrationItemList.FOOD_SUPPER,
  BulkRegistrationItemList.NUTRITION_MANAGEMENT,
  BulkRegistrationItemList.ORAL_TRANSITION
];

// storeのkey名とselectBoxのkey名の関連付け
export const UsagePerformanceStoreParamKeyToSelectBoxName = {
  statusType: {
    storeKey: "statusType",
    cast: "number"
  }
};

// storeのkey名とselectBoxのkey名の関連付け
export const UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName = {
  hospitalizationOvernightstay: {
    storeKey: "hospitalizationOvernightStay",
    cast: "number"
  },
  hospitalizationDays: {
    storeKey: "hospitalizationDays",
    cast: "number"
  },
  regionalTransition: { storeKey: "regionalTransition", cast: "number" },
  collectionOfUtilityFee: {
    storeKey: "collectionOfUtilityFee",
    cast: "number"
  },
  foodBreakfast: { storeKey: "foodBreakfast", cast: "number" },
  foodLunch: { storeKey: "foodLunch", cast: "number" },
  foodSupper: { storeKey: "foodSupper", cast: "number" },
  nutritionManagement: {
    storeKey: "nutritionManagement",
    cast: "number"
  },
  oralTransition: {
    storeKey: "oralTransition",
    cast: "number"
  }
};

// 利用状況の詳細用ヘッダー
export const USAGE_PERFORMANCE_SUMMARY_LABEL = [
  { key: 0, text: "", status: [] },
  { key: 1, text: "宿泊", status: [1] },
  {
    key: 2,
    text: "入院",
    status: [2, 3, 4],
    subLabel: ["初日", "中日", "最終日"]
  },
  { key: 11, text: "入院\n(施設に\n一時外泊)", status: [17] },
  { key: 12, text: "入院\n(施設以外に\n一時外泊)", status: [18] },
  { key: 3, text: "入院→外泊", status: [8] },
  { key: 4, text: "入院→戻る\n→外泊", status: [10] },
  {
    key: 5,
    text: "外泊",
    status: [5, 6, 7],
    subLabel: ["初日", "中日", "最終日"]
  },
  { key: 6, text: "外泊→入院", status: [9] },
  { key: 7, text: "外泊→戻る\n→入院", status: [11] },
  {
    key: 8,
    text: "体験宿泊",
    status: [13, 14, 15],
    subLabel: ["初日", "中日", "最終日"]
  },
  { key: 9, text: "地域移行加算（退所後）", status: [90] },
  { key: 10, text: "利用なし", status: [0] }
];

// 絞り込みのパターンリスト
export type PatternListType = {
  id: number;
  targetKana: string;
  viewKana: string;
  alphabet: string;
  checked: boolean;
  disabled: boolean;
};
export const patternListFormat: PatternListType[] = [
  {
    id: 1,
    viewKana: "あ",
    alphabet: "a",
    targetKana: "[ァ-オ]|[ヴ]",
    checked: false,
    disabled: false
  },
  {
    id: 2,
    viewKana: "か",
    alphabet: "k",
    targetKana: "[カ-ゴ]|[ヵ-ヶ]",
    checked: false,
    disabled: false
  },
  {
    id: 3,
    viewKana: "さ",
    alphabet: "s",
    targetKana: "[サ-ゾ]",
    checked: false,
    disabled: false
  },
  {
    id: 4,
    viewKana: "た",
    alphabet: "t",
    targetKana: "[タ-ド]",
    checked: false,
    disabled: false
  },
  {
    id: 5,
    viewKana: "な",
    alphabet: "n",
    targetKana: "[ナ-ノ]",
    checked: false,
    disabled: false
  },
  {
    id: 6,
    viewKana: "は",
    alphabet: "h",
    targetKana: "[ハ-ポ]",
    checked: false,
    disabled: false
  },
  {
    id: 7,
    viewKana: "ま",
    alphabet: "m",
    targetKana: "[マ-モ]",
    checked: false,
    disabled: false
  },
  {
    id: 8,
    viewKana: "や",
    alphabet: "y",
    targetKana: "[ャ-ヨ]",
    checked: false,
    disabled: false
  },
  {
    id: 9,
    viewKana: "ら",
    alphabet: "r",
    targetKana: "[ラ-ロ]",
    checked: false,
    disabled: false
  },
  {
    id: 10,
    viewKana: "わ",
    alphabet: "w",
    targetKana: "[ヮ-ン]|[ヷ-ヺ]",
    checked: false,
    disabled: false
  }
];

// 利用者情報画面 「所得区分」で以下を選択した場合、「重度障害者支援の個別支援対象者である」が非活性となる
export const SEVERE_DISABILITY_SUPPORT_DISABLE_VALUES = ["0", "1", "2", "3"];

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";

/** 施設入所の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V201910,
      url: FACILITY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_DAILY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_USERS_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V201910,
      url: USERS_ID_201910.replace(":id", userId)
    }
  ]
};
