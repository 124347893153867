import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import workRecordsApi from "@api/requests/workRecords";
import dispatches from "@stores/dispatches";

export const fetchWorkRecords = (dispatch: Dispatch) => async (
  startDate: string,
  endDate: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchWorkRecordsStarted());
  await workRecordsApi
    .getWorkRecords(startDate, endDate)
    .then((res) => {
      dispatch(actions.fetchWorkRecordsSuccess(res.data));
    })
    .catch((e) => {
      dispatch(actions.fetchWorkRecordsFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchWorkRecordsMonthList = (
  dispatch: Dispatch
) => async (): Promise<void> => {
  dispatch(actions.fetchWorkRecordsMonthListStarted());
  await workRecordsApi
    .getWortRecordsMonthList()
    .then((res) => {
      dispatch(actions.fetchWorkRecordsMonthListSuccess(res.data));
    })
    .catch((e) => {
      dispatch(actions.fetchWorkRecordsMonthListFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    });
};

type DispatchProps = {
  fetchWorkRecords: (startDate: string, endDate: string) => Promise<void>;
  fetchWorkRecordsMonthList: () => Promise<void>;
};

export default (dispatch: Dispatch): DispatchProps => ({
  fetchWorkRecords: fetchWorkRecords(dispatch),
  fetchWorkRecordsMonthList: fetchWorkRecordsMonthList(dispatch)
});
