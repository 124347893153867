import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type getHolidayResponse = {
  data: string[];
};

/**
 * 祝日情報を取得する
 * @param date 対象日 YYYYMM
 */
export const getHoliday = async (
  date: string
): Promise<AxiosResponse<getHolidayResponse>> => {
  const url = `${VERSION_URL_201910}/holidays/${date}`;
  return request.get<getHolidayResponse>(url);
};

export default getHoliday;
