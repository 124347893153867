import * as types from "./types";
import { GetSupportLedgerResponse } from "@api/requests/supportLedger/getSupportLedger";
import { GetSupportLedgerListResponse } from "@api/requests/supportLedger/getSupportLedgerList";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchSupportLedgerStarted = () =>
  ({ type: types.FETCH_SUPPORT_LEDGER_STARTED } as const);
export const fetchSupportLedgerSuccess = (
  res: GetSupportLedgerResponse["data"]
) => ({ type: types.FETCH_SUPPORT_LEDGER_SUCCESS, payload: res } as const);
export const fetchSupportLedgerFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_SUPPORT_LEDGER_FAILED, error: err } as const);

export const fetchSupportLedgerListStarted = () =>
  ({ type: types.FETCH_SUPPORT_LEDGER_LIST_STARTED } as const);
export const fetchSupportLedgerListSuccess = (
  res: GetSupportLedgerListResponse["data"]
) => ({ type: types.FETCH_SUPPORT_LEDGER_LIST_SUCCESS, payload: res } as const);
export const fetchSupportLedgerListFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_SUPPORT_LEDGER_LIST_FAILED, error: err } as const);

export const postSupportLedgerStarted = () =>
  ({ type: types.POST_SUPPORT_LEDGER_STARTED } as const);
export const postSupportLedgerSuccess = () =>
  ({ type: types.POST_SUPPORT_LEDGER_SUCCESS } as const);
export const postSupportLedgerFailed = (err: ErrorResponse) =>
  ({ type: types.POST_SUPPORT_LEDGER_FAILED, error: err } as const);
export const unsetSupportLedger = () =>
  ({ type: types.UNSET_SUPPORT_LEDGER } as const);

export const deleteSupportLedgerStarted = () =>
  ({ type: types.DELETE_SUPPORT_LEDGER_STARTED } as const);
export const deleteSupportLedgerSuccess = () =>
  ({ type: types.DELETE_SUPPORT_LEDGER_SUCCESS } as const);
export const deleteSupportLedgerFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_SUPPORT_LEDGER_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportLedgerStarted>
  | ReturnType<typeof fetchSupportLedgerSuccess>
  | ReturnType<typeof fetchSupportLedgerFailed>
  | ReturnType<typeof fetchSupportLedgerListStarted>
  | ReturnType<typeof fetchSupportLedgerListSuccess>
  | ReturnType<typeof fetchSupportLedgerListFailed>
  | ReturnType<typeof postSupportLedgerStarted>
  | ReturnType<typeof postSupportLedgerSuccess>
  | ReturnType<typeof postSupportLedgerFailed>
  | ReturnType<typeof unsetSupportLedger>
  | ReturnType<typeof deleteSupportLedgerStarted>
  | ReturnType<typeof deleteSupportLedgerSuccess>
  | ReturnType<typeof deleteSupportLedgerFailed>;
