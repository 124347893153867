import get from "lodash-es/get";

import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { CityState } from "@stores/domain/city/type";
import { FacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/types";

import {
  FieldItem,
  InputValue,
  SelectValue,
  SelectDateValue,
  CheckBoxValue
} from "@interfaces/ui/form";
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM,
  Checkbox
} from "@constants/variables";
import { RELATED_ORGANIZATION_TARGET_TYPE } from "@constants/mgr/CHIIKITEICHAKU/variables";

import { dateToObject, dateToSelectDateValue } from "@utils/date";
import { getRelatedOrganizationTargetType } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";

export type History = {
  id: number | null; // ID
  yyyymm: InputValue | null; // 年月
  matter: InputValue | null; // 事項
  isDelete: boolean;
};

export type RelativesInfo = {
  id: number | null; // 家族・親族の ID
  relationship: InputValue | null; // 続柄
  name: InputValue | null; // 氏名
  age: InputValue | null; // 年齢
  profession: InputValue | null; // 職業
  address: InputValue | null; // 住所
  contact_address: InputValue | null; // 連絡先
  remarks: InputValue | null; // 備考
  isDelete: boolean;
};

export type RelatedOrganization = {
  id: number | null; // 関係機関の ID
  target_type: number; // 関係機関先
  facility_name: InputValue | null; // 施設・会社・医療機関名
  kinds: InputValue | null; // 種別・診療科・部門
  manager: InputValue | null; // 担当者
  contact_address: InputValue | null; // 連絡先
  remarks: InputValue | null; // 備考
  isDelete: boolean;
};

export type SupportLedgerInput = {
  custom_records_category_id: number; // カテゴリの ID
  input: {
    id: number | null; // カスタム項目の入力内容の ID
    custom_record_item_id: number; // カスタム項目の ID
    input_data: InputValue; // 入力テキスト
    default_item: number | null;
    choiced_item_id?: string; // 選択したカスタム選択肢の ID
    checkedItems?: {
      [choiceId: number]: CheckedItem;
    }; // チェックボックス { 選択肢ID: チェック状態 }
    staff?: {
      id: string;
      name: string;
      checked: number;
    };
    initialStaff?: {
      id: string;
      name: string;
    };
    /** 障害・疾病歴オブジェクト */
    disability_histories?: History[];
    /** 生活歴オブジェクト */
    life_histories?: History[];
    /** 家族・親族オブジェクト */
    relatives_info?: RelativesInfo[];
    /** 関係機関オブジェクト */
    related_organization?: RelatedOrganization[];
  }[];
};

export type CheckedItem = {
  inputId: number | null; // 更新時inputId
  checked: CheckBoxValue; // チェック状態
};

export type SupportLedger = {
  created_at: InputValue;
  updated_at: InputValue;
  support_ledger: {
    creation_date: SelectDateValue; // 初期作成日
    creation_staff: SelectValue; // 初期作成者
    update_date: SelectDateValue; // 最終更新日
    update_staff: SelectValue; // 最終更新者
    support_ledgers_id: number | null; // 支援台帳のID 登録済みの場合
    date_begin_in_support: SelectDateValue; // 支援開始年月
    date_end_in_support: SelectDateValue; // 支援終了年月
    is_ignore_print_support_ids: number[]; // 印刷しない支援記録の ID 配列
    isPrintSupportIds: {
      [supportId: number]: boolean;
    }; // 印刷する支援記録のチェックリスト(APIのデータと反転するためフォームはこちらの値で管理)
    support_ledger_items: SupportLedgerInput[]; // 支援台帳の入力内容
  };
};

/** 履歴系データの初期値作成 */
export const generateHistoryInitialValue = (): History => {
  return {
    id: null,
    yyyymm: "",
    matter: "",
    isDelete: false
  };
};

/** 家族・親族データの初期値作成 */
export const generateRelativeInfoInitialValues = (): RelativesInfo => {
  return {
    id: null,
    relationship: "",
    name: "",
    age: "",
    profession: "",
    address: "",
    contact_address: "",
    remarks: "",
    isDelete: false
  };
};

export const generateRelatedOrganizationInitialValues = (
  targetType: typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]
): RelatedOrganization => {
  return {
    id: null,
    target_type: targetType,
    facility_name: "",
    kinds: "",
    manager: "",
    contact_address: "",
    remarks: "",
    isDelete: false
  };
};

// 生活歴データの取得
const getLifeHistories = (
  supportLedger: SupportLedgerState["supportLedger"]["support_ledger"],
  customRecordId: CustomRecordsWithCategoryState[number]["id"],
  customRecordItemId: SupportLedgerInput["input"][number]["id"],
  isCopy?: boolean
): SupportLedgerInput["input"][number]["life_histories"] => {
  const lifeHistories: SupportLedgerInput["input"][number]["life_histories"] = [];
  const initialLifeHistories = [
    {
      id: null,
      yyyymm: "",
      matter: "",
      isDelete: false
    }
  ];
  if (!supportLedger) {
    // 新規作成時
    return initialLifeHistories;
  }

  const inputRecord = supportLedger.support_ledger_items.find(
    (i) => i.custom_records_category_id === customRecordId
  );

  if (!inputRecord) {
    return initialLifeHistories;
  }
  const input = inputRecord.input.find(
    (i) => i.custom_record_item_id === customRecordItemId
  );
  if (!input || !input.life_histories) {
    return initialLifeHistories;
  }
  input.life_histories.map((item) => {
    const lifeHistory = {
      id: isCopy ? null : item.id, // 生活歴の ID
      yyyymm: item.yyyymm, // 年月
      matter: item.matter, // 事項
      isDelete: false
    };
    return lifeHistories.push(lifeHistory);
  });
  return lifeHistories;
};

// 障害・疾病歴データの取得
const getDisabilityHistories = (
  supportLedger: SupportLedgerState["supportLedger"]["support_ledger"],
  customRecordId: CustomRecordsWithCategoryState[number]["id"],
  customRecordItemId: SupportLedgerInput["input"][number]["id"],
  isCopy?: boolean
): SupportLedgerInput["input"][number]["disability_histories"] => {
  const disabilityHistories: SupportLedgerInput["input"][number]["disability_histories"] = [];
  const initialDisabilityHistories = [
    {
      id: null,
      yyyymm: "",
      matter: "",
      isDelete: false
    }
  ];
  if (!supportLedger) {
    return initialDisabilityHistories;
  }

  const inputRecord = supportLedger.support_ledger_items.find(
    (i) => i.custom_records_category_id === customRecordId
  );

  if (!inputRecord) {
    return initialDisabilityHistories;
  }
  const input = inputRecord.input.find(
    (i) => i.custom_record_item_id === customRecordItemId
  );
  if (!input || !input.disability_histories) {
    return initialDisabilityHistories;
  }
  input.disability_histories.map((item) => {
    const disabilityHistory = {
      id: isCopy ? null : item.id, // 障害・疾病歴の ID
      yyyymm: item.yyyymm, // 年月
      matter: item.matter, // 事項
      isDelete: false
    };
    return disabilityHistories.push(disabilityHistory);
  });

  return disabilityHistories;
};

// 家族・親族データの取得
const getRelativesInfo = (
  supportLedger: SupportLedgerState["supportLedger"]["support_ledger"],
  customRecordId: CustomRecordsWithCategoryState[number]["id"],
  customRecordItemId: SupportLedgerInput["input"][number]["id"],
  isCopy?: boolean
): SupportLedgerInput["input"][number]["relatives_info"] => {
  const relativesInfoList: SupportLedgerInput["input"][number]["relatives_info"] = [];
  const initialRelativesInfo = [
    {
      id: null,
      relationship: "",
      name: "",
      age: "",
      profession: "",
      address: "",
      contact_address: "",
      remarks: "",
      isDelete: false
    }
  ];
  if (!supportLedger) {
    return initialRelativesInfo;
  }
  const inputRecord = supportLedger.support_ledger_items.find(
    (i) => i.custom_records_category_id === customRecordId
  );

  if (!inputRecord) {
    return initialRelativesInfo;
  }
  const input = inputRecord.input.find(
    (i) => i.custom_record_item_id === customRecordItemId
  );
  if (!input || !input.relatives_info) {
    return initialRelativesInfo;
  }
  input.relatives_info.map((item) => {
    const relativesInfo = {
      id: isCopy ? null : item.id, // 家族・親族の ID
      relationship: item.relationship, // 続柄
      name: item.name, // 氏名
      age: item.age, // 年齢
      profession: item.profession, // 職業
      address: item.address, // 住所
      contact_address: item.contact_address, // 連絡先
      remarks: item.remarks, // 備考
      isDelete: false
    };
    return relativesInfoList.push(relativesInfo);
  });

  return relativesInfoList;
};

// 関係機関データの取得
const getRelatedOrganization = (
  supportLedger: SupportLedgerState["supportLedger"]["support_ledger"],
  customRecord: CustomRecordsWithCategoryState[number],
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number],
  isCopy?: boolean
): SupportLedgerInput["input"][number]["related_organization"] => {
  const relatedOrganizationList: SupportLedgerInput["input"][number]["related_organization"] = [];
  const initialRelatedOrganization = [
    {
      id: null,
      target_type: getRelatedOrganizationTargetType(
        customRecordItem.default_item
      ),
      facility_name: "",
      kinds: "",
      manager: "",
      contact_address: "",
      remarks: "",
      isDelete: false
    }
  ];
  if (!supportLedger) {
    return initialRelatedOrganization;
  }
  if (!supportLedger) {
    return initialRelatedOrganization;
  }
  const inputRecord = supportLedger.support_ledger_items.find(
    (i) => i.custom_records_category_id === customRecord.id
  );

  if (!inputRecord) {
    return initialRelatedOrganization;
  }
  const input = inputRecord.input.find(
    (i) => i.custom_record_item_id === customRecordItem.id
  );
  if (!input || !input.related_organization) {
    return initialRelatedOrganization;
  }
  input.related_organization.map((item) => {
    const relatedOrganization = {
      id: isCopy ? null : item.id, // 関係機関の ID
      target_type: item.target_type, // 関係機関先
      facility_name: item.facility_name, // 施設・会社・医療機関名
      kinds: item.kinds, // 種別・診療科・部門
      manager: item.manager, // 担当者
      contact_address: item.contact_address, // 連絡先
      remarks: item.remarks, // 備考
      isDelete: false
    };
    return relatedOrganizationList.push(relatedOrganization);
  });

  return relatedOrganizationList;
};

const pickInput = (
  supportLedgerItem: SupportLedgerState["supportLedger"],
  customRecordId: CustomRecordsWithCategoryState[number]["id"],
  customRecordItemId: SupportLedgerInput["input"][number]["id"]
):
  | SupportLedgerState["supportLedger"]["support_ledger"]["support_ledger_items"][number]["input"][number]
  | null => {
  const inputRecord = supportLedgerItem.support_ledger.support_ledger_items.find(
    (item) => item.custom_records_category_id === customRecordId
  );
  if (!inputRecord) {
    return null;
  }
  const input = inputRecord.input.find(
    (i) => i.custom_record_item_id === customRecordItemId
  );
  if (!input) {
    return null;
  }
  return input;
};

/**
 * 複数に分かれているinputを全て取得する処理
 * チェックボックスのグループなどでcustom recordのitem一つに対して複数のinputが用意される場合がある
 * @param supportLedgerState 地域定着台帳情報
 * @param customRecordItem カスタムレコード
 */
const getInputsWithChoose = (
  supportLedgerState: SupportLedgerState["supportLedger"],
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
):
  | SupportLedgerState["supportLedger"]["support_ledger"]["support_ledger_items"][number]["input"]
  | null => {
  const targetCategoryData = supportLedgerState.support_ledger.support_ledger_items.find(
    (s) => {
      return (
        s.custom_records_category_id ===
        customRecordItem.custom_records_category_id
      );
    }
  );
  if (!targetCategoryData) {
    return [];
  }
  // 条件に合うデータを「全て」取得
  const inputs = targetCategoryData.input.filter((v) => {
    return v.custom_record_item_id === customRecordItem.id;
  });
  return inputs;
};

const getCreatedStaff = (
  inputData: ReturnType<typeof pickInput>,
  staffOptions?: FieldItem[]
): SupportLedgerInput["input"][number]["staff"] => {
  if (
    !inputData ||
    !inputData.choiced_staff_id ||
    !inputData.choiced_staff_name_snapshot ||
    inputData.checked === 0
  ) {
    return {
      id: "",
      name: "",
      checked: 0
    };
  }
  const targetStaffId = inputData.choiced_staff_id.toString();
  const targetStaff = staffOptions
    ? staffOptions.find((staff) => staff.value === targetStaffId)
    : undefined;
  const isSnapShot = targetStaff
    ? targetStaff.label !== inputData.choiced_staff_name_snapshot
    : false;
  return {
    id: isSnapShot ? "0" : inputData.choiced_staff_id.toString(),
    name: inputData.choiced_staff_name_snapshot,
    checked: 1
  };
};

const getCityName = (cityId: number, cityList: CityState[]): string => {
  let targetCity = "";

  if (cityList) {
    Object.keys(cityList).forEach((key) => {
      if (cityList[key].value === cityId) {
        targetCity = cityList[key].label;
      }
    });
  }
  if (!targetCity) {
    return "";
  }
  return targetCity;
};

/**
 * 利用者情報をもとに障害支援区分の選択肢IDを返却する
 * @param user 利用者情報
 * @param customRecordItem 障害支援区分のカスタムレコードitem
 * @returns せんたくされている値
 */
const getDisabilitySupportCategory = (
  user: UsersInFacilityState["user"],
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
): string => {
  let disabilityClassLabel = "";
  if (
    user.user_in_facility_chiikiteichaku &&
    user.user_in_facility_chiikiteichaku.disability_class
  ) {
    disabilityClassLabel = `区分${user.user_in_facility_chiikiteichaku.disability_class}`;
  } else {
    disabilityClassLabel = "なし";
  }
  const choice = customRecordItem.custom_record_item_choices.find(
    (v) => v.name === disabilityClassLabel
  );
  if (!choice) {
    // ここに入ることはないと思われる
    const data = customRecordItem.custom_record_item_choices[0];
    return data.id.toString();
  }
  return choice.id.toString();
};

/**
 * defaultItemに対応したユーザー情報を取得して返却
 * defaultItemが非対応の場合は初期値である空文字を返す
 * @param user ユーザー情報
 * @param cityList 市区町村データ
 * @param defaultItem デフォルトアイテムnumber
 * @returns 文字列
 */
const findUserInfoText = (
  user: UsersInFacilityState["user"],
  facility: FacilityState,
  cityList: CityState[],
  defaultItem: number | null
): string => {
  switch (defaultItem) {
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.address:
      if (
        user.user_in_facility.prefecture_name &&
        user.user_in_facility.city_id &&
        user.user_in_facility.address
      ) {
        const address =
          user.user_in_facility.prefecture_name +
          getCityName(user.user_in_facility.city_id, cityList) +
          user.user_in_facility.address;
        return address;
      }
      return "";
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.tel:
      return user.user_in_facility.tel ? user.user_in_facility.tel : "";
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.email:
      return user.user_in_facility.email ? user.user_in_facility.email : "";
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdOffice:
      return facility.corporationName ? facility.corporationName : "";
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdTel:
      return facility.tel ? facility.tel : "";
    default:
      return "";
  }
};

/**
 * 対象の障害種別が利用者情報でチェックされているか確認する
 * IDなどは固定ではないため、選択肢のnameで判定する様にしている
 * @param user 利用者情報
 * @param choiceInfo 選択肢の情報
 * @returns チェックボックスの状態
 */
const isCheckedDisabilityType = (
  user: UsersInFacilityState["user"],
  choiceInfo: CustomRecordsWithCategoryState[number]["custom_record_items"][number]["custom_record_item_choices"][number]
): CheckBoxValue => {
  switch (choiceInfo.name) {
    case "身体障害":
      return user.user_in_facility.classify_physical_flg === Checkbox.ON;
    case "知的障害":
      return user.user_in_facility.classify_intelligence_flg === Checkbox.ON;
    case "精神障害":
      return user.user_in_facility.classify_mind_flg === Checkbox.ON;
    case "発達障害":
      return user.user_in_facility.classify_growth_flg === Checkbox.ON;
    case "高次脳機能障害":
      return user.user_in_facility.classify_brain_flg === Checkbox.ON;
    case "難病等対象者":
      return user.user_in_facility.classify_incurable_flg === Checkbox.ON;
    case "障害児":
      return user.user_in_facility.classify_handicapped_flg === Checkbox.ON;
    default:
      return false;
  }
};

const generateInput = ({
  user,
  facility,
  supportLedgerState,
  customRecord,
  cityList,
  isNew,
  isCopy,
  isInitial,
  staffOptions
}: {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  supportLedgerState: SupportLedgerState["supportLedger"];
  customRecord: CustomRecordsWithCategoryState[number];
  cityList: CityState[];
  isNew?: boolean;
  isCopy?: boolean;
  isInitial?: boolean;
  staffOptions?: FieldItem[];
}): SupportLedgerInput["input"] => {
  const input = customRecord.custom_record_items.map<
    SupportLedgerInput["input"][number]
  >((customRecordItem): SupportLedgerInput["input"][number] => {
    // supportLedgerItemから値を取得
    const inputData = pickInput(
      supportLedgerState,
      customRecord.id,
      customRecordItem.id
    );
    let inputDataText = "";
    if (inputData && inputData.input_data) {
      inputDataText = inputData.input_data;
    } else if (isNew && !isInitial) {
      inputDataText = findUserInfoText(
        user,
        facility,
        cityList,
        customRecordItem.default_item
      );
    }
    // ミニマムな値の状態
    const item: SupportLedgerInput["input"][number] = {
      id: !isCopy && inputData && inputData.id ? inputData.id : null, // カスタム項目の入力内容の ID
      custom_record_item_id: customRecordItem.id, // カスタム項目の ID
      input_data: inputDataText,
      default_item: customRecordItem.default_item
    };

    // テキスト以外の各値の判定を行う
    let isCheckBoxes = false;
    let isRadioButton = false;
    switch (customRecordItem.input_type) {
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox:
        isCheckBoxes = true;
        break;
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio:
        isRadioButton = true;
        break;
      default:
    }

    let isDisabilityHistories = false;
    let isLifeHistories = false;
    let isRelativesInfo = false;
    let isRelatedOrganization = false;
    let isStaff = false;
    switch (customRecordItem.default_item) {
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityHistories:
        isDisabilityHistories = true;
        break;
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.lifeHistories:
        isLifeHistories = true;
        break;
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relativesInfo:
        isRelativesInfo = true;
        break;
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedWelfareService:
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedMedical:
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedLifeLine:
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedOthers:
        isRelatedOrganization = true;
        break;
      case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdStaff:
        isStaff = true;
        break;
      default:
    }

    if (isCheckBoxes && !isInitial) {
      const checkBoxesData = getInputsWithChoose(
        supportLedgerState,
        customRecordItem
      );

      // チェックボックスのデータ（障害種別）
      const options = customRecordItem.custom_record_item_choices.reduce<
        SupportLedgerInput["input"][number]["checkedItems"]
      >((prev, current) => {
        const newObj = prev || {};
        const targetChooseInput =
          checkBoxesData &&
          checkBoxesData.find((v) => v.choiced_item_id === current.id);

        let checked = false;
        if (targetChooseInput) {
          checked = !!targetChooseInput.checked;
        } else if (isNew) {
          checked = isCheckedDisabilityType(user, current);
        }

        newObj[current.id] = {
          checked,
          inputId: !isCopy && targetChooseInput ? targetChooseInput.id : null
        };
        return newObj;
      }, {});

      item.checkedItems = options;
    }
    // ラジオボタンのデータ（障害支援区分）
    if (isRadioButton) {
      if (inputData && inputData.choiced_item_id) {
        // すでにデータが存在する時
        item.choiced_item_id = inputData.choiced_item_id.toString();
      } else {
        // 新規の時
        const chooseId = getDisabilitySupportCategory(user, customRecordItem);
        item.choiced_item_id = chooseId;
      }
      if (isCopy) {
        item.id = null;
      }
    }

    if (isDisabilityHistories) {
      item.disability_histories = getDisabilityHistories(
        supportLedgerState.support_ledger,
        customRecord.id,
        customRecordItem.id,
        isCopy
      );
    }
    if (isLifeHistories) {
      item.life_histories = getLifeHistories(
        supportLedgerState.support_ledger,
        customRecord.id,
        customRecordItem.id,
        isCopy
      );
    }
    if (isRelativesInfo) {
      item.relatives_info = getRelativesInfo(
        supportLedgerState.support_ledger,
        customRecord.id,
        customRecordItem.id,
        isCopy
      );
    }
    if (isRelatedOrganization) {
      item.related_organization = getRelatedOrganization(
        supportLedgerState.support_ledger,
        customRecord,
        customRecordItem,
        isCopy
      );
    }
    if (isInitial && (isNew || isCopy)) {
      delete item.life_histories;
      delete item.disability_histories;
      delete item.relatives_info;
      delete item.related_organization;
    }
    if (isStaff) {
      item.staff = getCreatedStaff(inputData, staffOptions);
      item.initialStaff = {
        id:
          inputData && inputData.choiced_staff_id
            ? inputData.choiced_staff_id.toString()
            : "",
        name:
          inputData && inputData.choiced_staff_name_snapshot
            ? inputData.choiced_staff_name_snapshot
            : ""
      };
    }
    return item;
  });
  return input;
};

const generateSupportLedgerItems = (
  user: UsersInFacilityState["user"],
  facility: FacilityState,
  cityList: CityState[],
  supportLedgerState: SupportLedgerState["supportLedger"],
  customRecords: CustomRecordsWithCategoryState,
  staffOptions?: FieldItem[],
  isNew?: boolean,
  isCopy?: boolean,
  isInitial?: boolean
): SupportLedgerInput[] => {
  return customRecords.map<SupportLedgerInput>((category) => {
    return {
      custom_records_category_id: category.id,
      input: generateInput({
        user,
        facility,
        cityList,
        supportLedgerState,
        customRecord: category,
        staffOptions,
        isNew,
        isCopy,
        isInitial
      })
    };
  });
};

const getStaffId = (
  supportLedgerState: SupportLedgerState["supportLedger"]["support_ledger"],
  staffOptions: FieldItem[] | undefined,
  staffType: string
): SelectValue => {
  // スナップショットを選択している場合、選択している値を0にする
  let staffId: SelectValue = "";

  switch (staffType) {
    // 初期作成者creation_staff
    case "creation_staff":
      if (staffOptions && supportLedgerState.creation_staff) {
        const staffIsDelete = staffOptions.every(
          (option) =>
            supportLedgerState.creation_staff &&
            option.value !== String(supportLedgerState.creation_staff.id)
        );
        staffId =
          supportLedgerState.creation_staff.name !==
            supportLedgerState.creation_staff.snapshot_name && !staffIsDelete
            ? "0"
            : String(supportLedgerState.creation_staff.id);
      }
      return staffId;
    // 最終更新者update_staff
    case "update_staff":
      if (staffOptions && supportLedgerState.update_staff) {
        const staffIsDelete = staffOptions.every(
          (option) =>
            supportLedgerState.update_staff &&
            option.value !== String(supportLedgerState.update_staff.id)
        );
        staffId =
          supportLedgerState.update_staff.name !==
            supportLedgerState.update_staff.snapshot_name && !staffIsDelete
            ? "0"
            : String(supportLedgerState.update_staff.id);
      }
      return staffId;
    default:
      return staffId;
  }
};

const generateSupportLedger = (
  user: UsersInFacilityState["user"],
  facility: FacilityState,
  cityList: CityState[],
  supportLedgerState: SupportLedgerState["supportLedger"],
  customRecords: CustomRecordsWithCategoryState,
  staffOptions?: FieldItem[],
  isNew?: boolean,
  isCopy?: boolean,
  isInitial?: boolean
): SupportLedger["support_ledger"] => {
  let creationDate = dateToSelectDateValue(
    get(supportLedgerState.support_ledger, "creation_date") || ""
  );
  let updateDate = dateToSelectDateValue(
    get(supportLedgerState.support_ledger, "update_date") || ""
  );
  let dateBeginInSupport = dateToSelectDateValue(
    get(supportLedgerState.support_ledger, "date_begin_in_support") || ""
  );
  let dateEndInSupport = dateToSelectDateValue(
    get(supportLedgerState.support_ledger, "date_end_in_support") || ""
  );
  if (!supportLedgerState || isNew || isCopy) {
    // 新規作成 or コピーの場合は現在日付を入れる
    const currentDate = new Date();
    creationDate = dateToObject(currentDate);
    updateDate = dateToObject(currentDate);
    // 当月
    dateBeginInSupport = dateToObject(currentDate);
    // 台帳の初期作成月の翌年同月
    dateEndInSupport = {
      year: (currentDate.getFullYear() + 1).toString(),
      month: (currentDate.getMonth() + 1).toString(),
      day: currentDate.getDate().toString()
    };
  }
  return {
    creation_date: creationDate,
    creation_staff:
      isNew || isCopy
        ? ""
        : getStaffId(
            supportLedgerState.support_ledger,
            staffOptions,
            "creation_staff"
          ),
    update_date: updateDate,
    update_staff:
      isNew || isCopy
        ? ""
        : getStaffId(
            supportLedgerState.support_ledger,
            staffOptions,
            "update_staff"
          ),
    support_ledgers_id:
      isNew || isCopy
        ? null
        : get(supportLedgerState.support_ledger, "support_ledgers_id"),
    date_begin_in_support: dateBeginInSupport,
    date_end_in_support: dateEndInSupport,
    is_ignore_print_support_ids:
      get(supportLedgerState.support_ledger, "is_ignore_print_support_ids") ||
      [],
    isPrintSupportIds: {},
    support_ledger_items: generateSupportLedgerItems(
      user,
      facility,
      cityList,
      supportLedgerState,
      customRecords,
      staffOptions,
      isNew,
      isCopy,
      isInitial
    )
  };
};

export const initialValues = (props: {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  cityList: CityState[];
  supportLedgerState?: SupportLedgerState["supportLedger"];
  customRecords?: CustomRecordsWithCategoryState;
  staffOptions?: FieldItem[];
  isNew?: boolean;
  isCopy?: boolean;
  isInitial?: boolean;
}): SupportLedger => {
  if (!props.supportLedgerState || !props.customRecords) {
    return {
      created_at: "",
      updated_at: "",
      support_ledger: {
        creation_date: { year: "NOT_SELECTED", month: "", day: "" },
        creation_staff: "",
        update_date: { year: "NOT_SELECTED", month: "", day: "" },
        update_staff: "",
        support_ledgers_id: null,
        date_begin_in_support: { year: "NOT_SELECTED", month: "", day: "" },
        date_end_in_support: { year: "NOT_SELECTED", month: "", day: "" },
        is_ignore_print_support_ids: [],
        isPrintSupportIds: {},
        support_ledger_items: []
      }
    };
  }
  return {
    created_at: get(props.supportLedgerState, "created_at") || "",
    updated_at: get(props.supportLedgerState, "updated_at") || "",
    support_ledger: generateSupportLedger(
      props.user,
      props.facility,
      props.cityList,
      props.supportLedgerState,
      props.customRecords,
      props.staffOptions,
      props.isNew,
      props.isCopy,
      props.isInitial
    )
  };
};
