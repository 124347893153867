import * as types from "./types";
import { GetOperationsAndSupports } from "@api/requests/operations/SHISETSUNYUSHO/getOperationsAndSupports";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchDailyOperationsAndSupportsStarted = () =>
  ({ type: types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_STARTED } as const);
export const fetchDailyOperationsAndSupportsSuccess = (
  res: GetOperationsAndSupports["data"]
) =>
  ({
    type: types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_SUCCESS,
    payload: res
  } as const);
export const fetchDailyOperationsAndSupportsFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_DAILY_OPERATIONS_AND_SUPPORTS_FAILED,
    error: err
  } as const);
export const unsetDailyOperationsAndSupports = () =>
  ({
    type: types.UNSET_DAILY_OPERATIONS_AND_SUPPORTS
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchDailyOperationsAndSupportsStarted>
  | ReturnType<typeof fetchDailyOperationsAndSupportsSuccess>
  | ReturnType<typeof fetchDailyOperationsAndSupportsFailed>
  | ReturnType<typeof unsetDailyOperationsAndSupports>;
