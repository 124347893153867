/**
 * セレクトボックス形式日付の必須チェック
 * @param value
 */

import required from "@validator/rules/required";
import { SelectDateValue } from "@interfaces/ui/form";

const requiredDate = (
  date: SelectDateValue
): {
  year: string | undefined;
  month: string | undefined;
  day: string | undefined;
} => {
  return {
    year: required(date.year),
    month: required(date.month),
    day: required(date.day)
  };
};

export default requiredDate;
