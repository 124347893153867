import React, { useState, useEffect } from "react";
import ClassNames from "classnames";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import {
  KnowbeTableHead,
  KnowbeTableHeaderProps
} from "@components/presentational/molecules/KnowbeTableHead";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = (): StyleRules =>
  createStyles({
    sticky: {
      zIndex: 10,
      position: "sticky"
    },
    disableCell: {
      display: "none"
    },
    baseCell: {
      padding: "0 8px",
      boxSizing: "content-box",
      "&:last-child": {
        paddingLeft: 8,
        paddingRight: 24
      }
    },
    firstCell: {
      width: 112,
      maxWidth: 112,
      minWidth: 112
    },
    secondCell: {
      width: 128,
      maxWidth: 128,
      minWidth: 128
    },
    nightSupportCell: {
      width: 144,
      maxWidth: 144,
      minWidth: 144
    },
    secondLongCell: {
      width: 226,
      maxWidth: 226,
      minWidth: 226
    },
    veryShortCell: {
      width: 60,
      maxWidth: 60,
      minWidth: 60
    },
    shortCell: {
      width: 64,
      maxWidth: 64,
      minWidth: 64
    },
    lastCell: {
      width: 122,
      maxWidth: 122,
      minWidth: 122
    },
    semiMiddleCell: {
      width: 72,
      maxWidth: 72,
      minWidth: 72
    }
  });

type OwnProps = {
  firstLabel: string; // 日ごと,月ごとの最初のラベル
  hasNightSupportType: boolean; // 夜間支援の有効状態
  headerHeight: number; // sticky用の高さ
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * TableHeadに渡すitemsを生成
 */
const createHeaderItems = (props: Props): KnowbeTableHeaderProps["items"] => {
  const { classes } = props;

  // 夜間支援,夜間支援対象者数の表示管理
  const nightSupportTypeClass = ClassNames(
    classes.baseCell,
    props.hasNightSupportType ? classes.nightSupportCell : classes.disableCell
  );
  // 夜間支援の表示に応じてサービス提供の状況の長さを可変させる
  const statusTypeClass = ClassNames(
    classes.baseCell,
    props.hasNightSupportType ? classes.secondCell : classes.secondLongCell
  );
  // 夜間支援の表示に応じて一番小さいセルをさらに縮める
  const shortCellClass = ClassNames(
    classes.baseCell,
    props.hasNightSupportType ? classes.veryShortCell : classes.shortCell
  );

  return [
    {
      label: props.firstLabel,
      className: ClassNames(classes.baseCell, classes.firstCell)
    },
    {
      label: (
        <span>
          サービス提供の
          <br />
          状況
        </span>
      ),
      className: statusTypeClass
    },
    {
      label: "夜間支援 / 対象者数",
      className: nightSupportTypeClass
    },
    {
      label: (
        <span>
          入院時
          <br />
          支援
        </span>
      ),
      className: shortCellClass
    },
    {
      label: (
        <span>
          帰宅時
          <br />
          支援
        </span>
      ),
      className: shortCellClass
    },
    {
      label: (
        <span>
          日中
          <br />
          支援
        </span>
      ),
      className: shortCellClass
    },
    {
      label: (
        <span>
          医療
          <br />
          連携
        </span>
      ),
      className: shortCellClass
    },
    {
      label: (
        <span>
          喀痰吸引等
          <br />
          に係る指導
        </span>
      ),
      className: ClassNames(classes.baseCell, classes.semiMiddleCell)
    },
    {
      label: (
        <span>
          自立
          <br />
          支援
        </span>
      ),
      className: shortCellClass
    },
    {
      label: (
        <span>
          居宅
          <br />
          介護
        </span>
      ),
      className: shortCellClass
    },
    {
      label: "備考",
      className: ClassNames(classes.baseCell, classes.lastCell)
    }
  ];
};

/**
 * 利用実績一覧のテーブルヘッダー（日ごと/月ごと共用）
 */
export const UsagePerformanceTableHeader = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;
    const [headerItems, setHeaderItems] = useState(createHeaderItems(props));

    useEffect(() => {
      setHeaderItems(createHeaderItems(props));
    }, [props.hasNightSupportType]);

    const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

    return (
      <div className={classes.sticky} style={{ top: `${topHeight}px` }}>
        <KnowbeTable>
          <KnowbeTableHead uniqueKey="report" height={56} items={headerItems} />
        </KnowbeTable>
      </div>
    );
  }
);
