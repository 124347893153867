import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import { UserCostAmountList } from "@components/organisms/download/print/UserCostAmountList";

type OwnProps = {
  year: string;
  month: string;
  idList: string;
  typeConsultation?: string;
};
type DispatchProps = {
  fetchCostAmountList: (
    year: string,
    month: string,
    excludeUserIds: number[],
    typeConsultation?: string
  ) => void;
};

type Props = OwnProps & DispatchProps & AppState;

/**
 * 利用者負担額一覧表のプリント画面
 */
class CostAmountList extends React.Component<Props> {
  public componentWillMount(): void {
    const { year, month, idList, typeConsultation } = this.props;
    const excludedUserIds = idList
      ? (idList.split(",").map((str) => parseInt(str, 10)) as number[])
      : ([] as number[]);

    this.props.fetchCostAmountList(
      year,
      month,
      excludedUserIds,
      typeConsultation
    );
  }

  public render(): JSX.Element {
    const { invoiceCostAmountListData } = this.props.invoice;
    return (
      <PreviewTemplate>
        <UserCostAmountList userCostAmount={invoiceCostAmountListData} />
      </PreviewTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);

  return {
    fetchCostAmountList: (
      year: string,
      month: string,
      excludeUserIds: number[],
      typeConsultation?: string
    ): void => {
      invoiceDispatched.downloadCostAmountListJson(
        year,
        month,
        excludeUserIds,
        typeConsultation
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CostAmountList);
