import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import TextField from "@components/atoms/TextField";
import { FacilityState } from "@stores/domain/mgr/GroupHome/facility/types";
import { Report } from "@stores/domain/report/type";
import {
  USAGE_PERFORMANCE_STATUS_TYPE,
  USAGE_PERFORMANCE_HOSPITALIZATION_SUPPORT_TYPE,
  USAGE_PERFORMANCE_GET_HOME_SUPPORT_TYPE,
  USAGE_PERFORMANCE_DAYTIME_SUPPORT_TYPE,
  USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104,
  LIFE_SUPPORT_FLG_202404,
  SERVICE_END_STATUS,
  USAGE_PERFORMANCE_STATUS_TYPE_END_IN_30_DAY,
  USAGE_PERFORMANCE_STATUS_TYPE_FULL,
  SPUTUM_GUIDANCE_FLG,
  ENABLE_MEDICAL_COOPERATION_VALUES
} from "@constants/variables";
import {
  USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE_202104,
  NIGHT_SUPPORT_TARGET_USERS,
  NIGHT_SUPPORT_TARGET_USERS_2,
  HOME_CARE_FLG,
  PEER_SUPPORT_FLG,
  RETIREMENT_SERVICE_FLG,
  AFT_LEAVING_PEER_SUPPORT_FLG,
  MORE_THAN_8HOURS_FLG,
  INTENSIVE_SUPPORT_FLG,
  INFECTIOUS_DISEASES_FACILITY_FLG,
  SEVERE_DISABILITY_SUPPORT_TYPE
} from "@constants/mgr/GroupHome/variables";
import { dateInYYYYFormat } from "@utils/date";
import DateSelectFields from "@components/molecules/DateSelectFields";

const styles = (): StyleRules =>
  createStyles({
    dropDown: {
      width: "100%",
      marginRight: 0
    },
    veryShortCell: {
      width: 60,
      maxWidth: 60,
      minWidth: 60,
      boxSizing: "content-box"
    },
    shortCell: {
      width: 64,
      maxWidth: 64,
      minWidth: 64,
      boxSizing: "content-box"
    },
    semiMiddleCell: {
      width: 72,
      maxWidth: 72,
      minWidth: 72,
      boxSizing: "content-box"
    },
    shortMiddleCell: {
      width: 80,
      maxWidth: 80,
      minWidth: 80,
      boxSizing: "content-box"
    },
    semiLongCell: {
      width: 128,
      maxWidth: 128,
      minWidth: 128,
      boxSizing: "content-box"
    },
    longCell: {
      width: 226,
      maxWidth: 226,
      minWidth: 226,
      boxSizing: "content-box"
    },
    veryLongCell: {
      width: 368,
      maxWidth: 368,
      minWidth: 368,
      boxSizing: "content-box"
    },
    nightSupportCell: {
      width: 144,
      maxWidth: 144,
      minWidth: 144,
      boxSizing: "content-box"
    },
    nightSupportWrap: {
      display: "flex",
      justifyContent: "space-around"
    },
    nightSupportBlock: {
      width: "100%",
      overflow: "hidden",
      "&:first-child": {
        marginRight: 16
      }
    },
    lastCell: {
      width: 122,
      maxWidth: 122,
      minWidth: 122,
      boxSizing: "content-box",
      wordBreak: "break-all"
    },
    middleCell: {
      width: 108,
      minWidth: 108,
      boxSizing: "content-box"
    },
    // UsagePerformanceReportDailyCellのclassとマージされる問題があり、回避のために定義だけする
    firstCell: {}
  });

interface OwnProps {
  params: Report;
  facility: FacilityState;
  idx: number;
  hasNightSupportType: boolean;
  setStatusType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setNightSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setNightSupportTargetUsersType: (value: string, key: number) => void;
  setHospitalizationSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setGetHomeSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setDaytimeSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setMedicalSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setSputumGuidanceFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setLifeSupportFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setHomeCareFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setRetirementServiceFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setPeerSupportFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setAftLeavingPeerSupportFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setMoreThan8hoursFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setIntensiveSupportFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setIntensiveSupportStartDate: (value: string, key: number) => void;
  setInfectiousDiseasesFacilityFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setSevereDisabilitySupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setRemarks: (remarks: string, key: number) => void;
  onChangeDisabled: (errorMessage: string, key: number) => void;
}

type Props = OwnProps & WithStyles<typeof styles>;

interface State {
  currentRemarks: string;
  errorMessage: {
    remarks: string;
  };
  intensiveSupportStartDateErrorMessage: string;
}

/**
 * サービス提供の状態に応じて各項目の入力禁止が決まる。
 * サービス提供の状況のvalue値を各項目の配列に含めると、対象の項目が非活性になる
 */
const DISABLED_STATUS_TYPE_PATTERN = {
  NIGHT_SUPPORT_TYPE: ["0", "2", "3", "5", "6", "8", "9", "10", "11"],
  HOSPITALIZATION_SUPPORT_TYPE: ["0", "1", "5", "6", "7", "8", "9", "10", "11", "12"], // prettier-ignore
  HOME_SUPPORT_TYPE: ["0", "1", "2", "3", "4", "8", "9", "10", "11", "12"],
  DAYTIME_SUPPORT_TYPE: ["0", "3", "6", "8", "9"],
  MEDICAL_SUPPORT_TYPE: ["0", "3", "6", "8", "9"],
  SPUTUM_GUIDANCE_FLG: ["0", "3", "6", "8", "9"],
  LIFE_SUPPORT_FLG: ["0", "3", "6", "8", "9", "12"],
  LIFE_SUPPORT_FLG_202404: ["0", "12"],
  HOME_CARE_FLG: ["0"],
  PEER_SUPPORT_FLG: ["0"],
  AFT_LEAVING_PEER_SUPPORT_FLG: ["12"],
  // 退居後共同生活援助サービス費はサービス提供の状況が「退居後加算」時以外は入力禁止
  RETIREMENT_SERVICE_FLG: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"], // prettier-ignore
  INFECTIOUS_DISEASES_FACILITY_FLG: ["0"]
};

class UsagePerformanceReportCellEdit extends React.Component<Props, State> {
  public medicalSupportTypeRadioItems: OptionInterface[];

  constructor(props: Props) {
    super(props);
    this.state = {
      currentRemarks: this.props.params.remarks,
      errorMessage: {
        remarks: ""
      },
      intensiveSupportStartDateErrorMessage: ""
    };
    this.medicalSupportTypeRadioItems = this.createMedicalSupportTypeRadioItems();
  }

  /**
   * 医療連携のラジオボタンを事業所情報および、利用実績の登録値を元に生成
   */
  private createMedicalSupportTypeRadioItems = (): OptionInterface[] => {
    const items =
      this.props.facility.nursingStaffPlacementSystemFlag &&
      this.props.facility.nursingStaffPlacementSystemFlag === "2"
        ? this.filterMedicalSupportTypeRadioItems()
        : USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104;
    return items;
  };

  private filterMedicalSupportTypeRadioItems = (): OptionInterface[] => {
    const medicalSupportValue = this.props.params.medicalSupportType || "0";
    return USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104.filter((option) => {
      return (
        option.value === "0" ||
        option.value === "6" ||
        option.value === medicalSupportValue
      );
    });
  };

  private setStatusType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.statusType || "0";
    if (currentValue !== event.target.value) {
      this.props.setStatusType(event, this.props.idx);
      let { currentRemarks } = this.state;

      // サービス提供の状況が「-」or 「体験利用」に変更された場合、
      // 備考欄の入力は居宅介護「あり」->「-」と同様の挙動にする。
      if (
        DISABLED_STATUS_TYPE_PATTERN.HOME_CARE_FLG.includes(event.target.value)
      ) {
        currentRemarks = this.replaceRemarks(currentRemarks, "居宅介護等利用");
      }
      // 入院時支援の場合
      if (
        DISABLED_STATUS_TYPE_PATTERN.HOSPITALIZATION_SUPPORT_TYPE.includes(
          event.target.value
        )
      ) {
        currentRemarks = this.replaceRemarks(currentRemarks, "長期入院時支援");
      }
      // 帰宅支援の場合
      if (
        DISABLED_STATUS_TYPE_PATTERN.HOME_SUPPORT_TYPE.includes(
          event.target.value
        )
      ) {
        currentRemarks = this.replaceRemarks(currentRemarks, "長期帰宅時支援");
      }
      // ピアサポートの場合
      if (
        DISABLED_STATUS_TYPE_PATTERN.PEER_SUPPORT_FLG.includes(
          event.target.value
        )
      ) {
        currentRemarks = this.replaceRemarks(currentRemarks, "ピアサポート");
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
  };

  private setNightSupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.nightSupportType || "1";
    if (currentValue !== event.target.value) {
      this.props.setNightSupportType(event, this.props.idx);
      // 夜間支援対象者数の初期値連動
      const { nightSupportTargetUsers } = this.props.params;
      // 夜間支援対象者数が非活性になる時と、夜間支援Ⅱに変更時に対象者数の2人以下と3人が選ばれていたら値をリセットする
      if (
        event.target.value === "1" ||
        event.target.value === "4" ||
        (event.target.value === "3" &&
          (nightSupportTargetUsers === "2" || nightSupportTargetUsers === "3"))
      ) {
        this.props.setNightSupportTargetUsersType("0", this.props.idx);
      }
      // 活性かつ夜間支援体制加算がない時デフォ値をセットする
      else if (nightSupportTargetUsers === "0") {
        let defUsers = this.props.params.defNightSupportTargetUsers;
        // 夜間支援Ⅱで2人と3人をセットしようとする場合、"0"に変更
        if (
          event.target.value === "3" &&
          (defUsers === "2" || defUsers === "3")
        ) {
          defUsers = "0";
        }
        this.props.setNightSupportTargetUsersType(defUsers, this.props.idx);
      }
    }
  };

  private setNightSupportTargetUsersType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.nightSupportTargetUsers || "0";
    if (currentValue !== event.target.value) {
      this.props.setNightSupportTargetUsersType(
        event.target.value,
        this.props.idx
      );
    }
  };

  private setHospitalizationSupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.hospitalizationSupportType || "1";
    const targetValue = event.target.value;
    if (currentValue !== targetValue) {
      this.props.setHospitalizationSupportType(event, this.props.idx);
      let { currentRemarks } = this.state;
      currentRemarks = this.replaceRemarks(currentRemarks, "長期入院時支援");
      if (targetValue === "3") {
        currentRemarks = `${currentRemarks}${
          currentRemarks ? " " : ""
        }長期入院時支援`;
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
  };

  private setGetHomeSupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.getHomeSupportType || "1";
    const targetValue = event.target.value;
    if (currentValue !== targetValue) {
      this.props.setGetHomeSupportType(event, this.props.idx);
      let { currentRemarks } = this.state;
      currentRemarks = this.replaceRemarks(currentRemarks, "長期帰宅時支援");
      if (targetValue === "3") {
        currentRemarks = `${currentRemarks}${
          currentRemarks ? " " : ""
        }長期帰宅時支援`;
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
  };

  private setDaytimeSupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.daytimeSupportType || "1";
    if (currentValue !== event.target.value) {
      this.props.setDaytimeSupportType(event, this.props.idx);
    }
  };

  private setMedicalSupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.medicalSupportType || "0";
    if (currentValue !== event.target.value) {
      this.props.setMedicalSupportType(event, this.props.idx);
    }
  };

  private setSputumGuidanceFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.sputumGuidanceFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setSputumGuidanceFlg(event, this.props.idx);
    }
  };

  private setLifeSupportFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.lifeSupportFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setLifeSupportFlg(event, this.props.idx);
    }
  };

  private setHomeCareFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.homeCareFlg || "0";
    const { value } = event.target;
    if (currentValue !== value) {
      this.props.setHomeCareFlg(event, this.props.idx);
      let { currentRemarks } = this.state;
      // 「居宅介護等利用」の前に半角スペースがあった場合は一緒に削除
      currentRemarks = this.replaceRemarks(currentRemarks, "居宅介護等利用");
      if (value === "1") {
        currentRemarks = `${currentRemarks}${
          currentRemarks ? " " : ""
        }居宅介護等利用`;
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
    // 「居宅介護」を"-"にした場合は「8時間以上の利用」も"-"に変更
    if (value === "0") {
      this.props.setMoreThan8hoursFlg(
        { target: { value: "0" } } as React.ChangeEvent<HTMLInputElement>,
        this.props.idx
      );
    }
  };

  private setMoreThan8hoursFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.moreThan8hoursFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setMoreThan8hoursFlg(event, this.props.idx);
    }
  };

  private setRetirementServiceFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.retirementServiceFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setRetirementServiceFlg(event, this.props.idx);
    }
  };

  private setAftLeavingPeerSupportFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.aftLeavingPeerSupportFlg || "0";
    const { value } = event.target;
    if (currentValue !== event.target.value) {
      this.props.setAftLeavingPeerSupportFlg(event, this.props.idx);
      let { currentRemarks } = this.state;
      // 「ピアサポート」の前に半角スペースがあった場合は一緒に削除
      currentRemarks = this.replaceRemarks(currentRemarks, "ピアサポート");
      if (value === "1" || this.props.params.peerSupportFlg === "1") {
        currentRemarks = `${currentRemarks}${
          currentRemarks ? " " : ""
        }ピアサポート`;
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
  };

  private setIntensiveSupportFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.intensiveSupportFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setIntensiveSupportFlg(event, this.props.idx);
    }
    // OFFなら支援開始日をデフォルト値にリセット
    if (event.target.value === "0") {
      this.props.setIntensiveSupportStartDate("", this.props.idx);
    }
    this.intensiveSupportStartDateValidation(
      event.target.value,
      this.props.params.intensiveSupportStartDate
    );
  };

  private setIntensiveSupportStartDate = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.intensiveSupportStartDate || "";
    if (currentValue !== event.target.value) {
      this.props.setIntensiveSupportStartDate(
        event.target.value,
        this.props.idx
      );
    }
    this.intensiveSupportStartDateValidation(
      this.props.params.aftLeavingPeerSupportFlg,
      event.target.value
    );
  };

  private setPeerSupportFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.peerSupportFlg || "0";
    const { value } = event.target;
    if (currentValue !== value) {
      this.props.setPeerSupportFlg(event, this.props.idx);
      let { currentRemarks } = this.state;
      // 「ピアサポート」の前に半角スペースがあった場合は一緒に削除
      currentRemarks = this.replaceRemarks(currentRemarks, "ピアサポート");
      if (value === "1" || this.props.params.aftLeavingPeerSupportFlg === "1") {
        currentRemarks = `${currentRemarks}${
          currentRemarks ? " " : ""
        }ピアサポート`;
      }
      this.setState({ currentRemarks });
      this.props.setRemarks(currentRemarks, this.props.idx);
    }
  };

  private setInfectiousDiseasesFacilityFlg = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.infectiousDiseasesFacilityFlg || "0";
    if (currentValue !== event.target.value) {
      this.props.setInfectiousDiseasesFacilityFlg(event, this.props.idx);
    }
  };

  private setSevereDisabilitySupportType = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const currentValue = this.props.params.severeDisabilitySupportType || "0";
    if (currentValue !== event.target.value) {
      this.props.setSevereDisabilitySupportType(event, this.props.idx);
    }
  };

  private replaceRemarks = (currentRemarks: string, word: string): string => {
    // 直前に半角スペースがあった場合は一緒に削除
    const reg = new RegExp(`( )*${word}`, "g");
    return currentRemarks.replace(reg, "");
  };

  private onChangeRemarks = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ currentRemarks: event.target.value });
  };

  private intensiveSupportStartDateValidation = (
    flg: string,
    value: string
  ): void => {
    if (flg !== "0" && value === "") {
      this.setState({
        intensiveSupportStartDateErrorMessage: "必須です"
      });
      this.props.onChangeDisabled("必須です", this.props.idx);
    } else {
      this.setState({
        intensiveSupportStartDateErrorMessage: ""
      });
      this.props.onChangeDisabled("", this.props.idx);
    }
  };

  private onBlurRemarks = (): void => {
    if (this.props.params.remarks !== this.state.currentRemarks) {
      this.props.setRemarks(this.state.currentRemarks, this.props.idx);
    }
  };

  private isDisabledNightSupportType = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.NIGHT_SUPPORT_TYPE
      ) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledNightSupportTargetUsersType = (): boolean => {
    const { params } = this.props;
    return (
      !params.nightSupportType ||
      params.nightSupportType === "1" ||
      params.nightSupportType === "4"
    );
  };

  private isDisabledHospitalizationSupportType = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.HOSPITALIZATION_SUPPORT_TYPE
      ) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledHomeSupportType = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.HOME_SUPPORT_TYPE
      ) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledDaytimeSupportType = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.DAYTIME_SUPPORT_TYPE
      ) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledMedicalSupportType = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.MEDICAL_SUPPORT_TYPE
      ) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledSputumGuidanceFlg = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.SPUTUM_GUIDANCE_FLG
      ) ||
      !ENABLE_MEDICAL_COOPERATION_VALUES.includes(params.medicalSupportType) ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END ||
      params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
    );
  };

  private isDisabledLifeSupportFlg = (): boolean => {
    const { params } = this.props;
    return (
      this.hasDisabledStatusType(
        DISABLED_STATUS_TYPE_PATTERN.LIFE_SUPPORT_FLG_202404
      ) || params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END
    );
  };

  private isDisabledHomeCareFlg = (): boolean => {
    return this.hasDisabledStatusType(
      DISABLED_STATUS_TYPE_PATTERN.HOME_CARE_FLG
    );
  };

  private isDisabledPeerSupportFlg = (): boolean => {
    return this.hasDisabledStatusType(
      DISABLED_STATUS_TYPE_PATTERN.PEER_SUPPORT_FLG
    );
  };

  private isDisabledRetirementServiceFlg = (): boolean => {
    return this.hasDisabledStatusType(
      DISABLED_STATUS_TYPE_PATTERN.RETIREMENT_SERVICE_FLG
    );
  };

  private isDisabledAftLeavingPeerSupportFlg = (): boolean => {
    return this.hasDisabledStatusType(
      DISABLED_STATUS_TYPE_PATTERN.AFT_LEAVING_PEER_SUPPORT_FLG
    );
  };

  private isDisabledMoreThan8hoursFlg = (): boolean => {
    return this.props.params.homeCareFlg !== "1";
  };

  private isDisabledInfectiousDiseasesFacilityFlg = (): boolean => {
    return this.hasDisabledStatusType(
      DISABLED_STATUS_TYPE_PATTERN.INFECTIOUS_DISEASES_FACILITY_FLG
    );
  };

  private hasDisabledStatusType = (statusTypeList: string[]): boolean => {
    return (
      !this.props.params.statusType ||
      statusTypeList.some((type) => type === this.props.params.statusType)
    );
  };

  public render(): JSX.Element {
    const { idx, params, hasNightSupportType, classes } = this.props;
    const shortCellClassName = hasNightSupportType
      ? classes.veryShortCell
      : classes.shortCell;
    const middleCellClassName = classes.middleCell;

    // 夜間支援の値に応じて夜間支援対象者数のOptionを変更する
    const nightSupportTargetUsersOptions =
      params.nightSupportType === "3"
        ? NIGHT_SUPPORT_TARGET_USERS_2
        : NIGHT_SUPPORT_TARGET_USERS;

    // 集中的支援加算 算定開始日の入力項目に必要な値を宣言
    const currentYear = +dateInYYYYFormat(new Date());
    const from = currentYear - 86;
    const to = currentYear + 1;

    return (
      <>
        {/* サービス提供の状況 */}
        <TableCellWrap
          key={`${idx}-status-type`}
          cellClass={
            hasNightSupportType ? classes.semiLongCell : classes.longCell
          }
        >
          <DropDown
            id={`${idx}-status-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={
              params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_IN_30_DAY
                ? USAGE_PERFORMANCE_STATUS_TYPE_END_IN_30_DAY
                : USAGE_PERFORMANCE_STATUS_TYPE
            }
            onChange={this.setStatusType}
            value={params.statusType || "0"}
            names={USAGE_PERFORMANCE_STATUS_TYPE_FULL}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END}
          />
        </TableCellWrap>

        {/* 夜間支援 / 対象者数 */}
        <TableCellWrap
          key={`${idx}-night-support-type`}
          hidden={!hasNightSupportType}
          cellClass={classes.nightSupportCell}
        >
          <div className={classes.nightSupportWrap}>
            <div className={classes.nightSupportBlock}>
              <DropDown
                id={`${idx}-night-support-type-dropdown`}
                label=""
                size="textFieldFullSize"
                options={USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE_202104}
                onChange={this.setNightSupportType}
                value={params.nightSupportType || "1"}
                helperText=""
                isError={false}
                styles={classes.dropDown}
                isDisabled={this.isDisabledNightSupportType()}
              />
            </div>
            <div className={classes.nightSupportBlock}>
              <DropDown
                id={`${idx}-night-support-target-users-dropdown`}
                label=""
                size="textFieldFullSize"
                options={nightSupportTargetUsersOptions}
                onChange={this.setNightSupportTargetUsersType}
                value={params.nightSupportTargetUsers || "0"}
                styles={classes.dropDown}
                isDisabled={this.isDisabledNightSupportTargetUsersType()}
              />
            </div>
          </div>
        </TableCellWrap>

        {/* 入院時支援 */}
        <TableCellWrap
          key={`${idx}-hospitalization-support-type`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-hospitalization-support-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={USAGE_PERFORMANCE_HOSPITALIZATION_SUPPORT_TYPE}
            onChange={this.setHospitalizationSupportType}
            value={params.hospitalizationSupportType || "1"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledHospitalizationSupportType()}
          />
        </TableCellWrap>

        {/* 帰宅時支援 */}
        <TableCellWrap
          key={`${idx}-get-home-support-type`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-get-home-support-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={USAGE_PERFORMANCE_GET_HOME_SUPPORT_TYPE}
            onChange={this.setGetHomeSupportType}
            value={params.getHomeSupportType || "1"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledHomeSupportType()}
          />
        </TableCellWrap>

        {/* 日中支援 */}
        <TableCellWrap
          key={`${idx}-daytime-support-type`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-daytime-support-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={USAGE_PERFORMANCE_DAYTIME_SUPPORT_TYPE}
            onChange={this.setDaytimeSupportType}
            value={params.daytimeSupportType || "1"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledDaytimeSupportType()}
          />
        </TableCellWrap>

        {/* 医療連携 */}
        <TableCellWrap
          key={`${idx}-medical-support-type`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-medical-support-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={this.medicalSupportTypeRadioItems}
            onChange={this.setMedicalSupportType}
            value={params.medicalSupportType || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledMedicalSupportType()}
          />
        </TableCellWrap>

        {/* 喀痰吸引等に係る指導 */}
        <TableCellWrap
          key={`${idx}-sputum-guidance-flg`}
          cellClass={classes.semiMiddleCell}
        >
          <DropDown
            id={`${idx}-sputum-guidance-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={SPUTUM_GUIDANCE_FLG}
            onChange={this.setSputumGuidanceFlg}
            value={params.sputumGuidanceFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.sputumGuidanceDropDownStyle}
            isDisabled={this.isDisabledSputumGuidanceFlg()}
          />
        </TableCellWrap>

        {/* 自立支援 */}
        <TableCellWrap
          key={`${idx}-life-support-flg`}
          cellClass={middleCellClassName}
        >
          <DropDown
            id={`${idx}-life-support-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={LIFE_SUPPORT_FLG_202404}
            onChange={this.setLifeSupportFlg}
            value={params.lifeSupportFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledLifeSupportFlg()}
          />
        </TableCellWrap>

        {/* 居宅介護 */}
        <TableCellWrap
          key={`${idx}-home-care-flg`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-home-care-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={HOME_CARE_FLG}
            onChange={this.setHomeCareFlg}
            value={params.homeCareFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledHomeCareFlg()}
          />
        </TableCellWrap>

        {/* 退居後共同生活援助サービス費 */}
        <TableCellWrap
          key={`${idx}-retirement-service-flg`}
          cellClass={middleCellClassName}
        >
          <DropDown
            id={`${idx}-retirement-service-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={RETIREMENT_SERVICE_FLG}
            onChange={this.setRetirementServiceFlg}
            value={params.retirementServiceFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledRetirementServiceFlg()}
          />
        </TableCellWrap>
        {/* ピアサポート実施 */}
        <TableCellWrap
          key={`${idx}-peer-support-flg`}
          cellClass={middleCellClassName}
        >
          <DropDown
            id={`${idx}-peer-support-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={PEER_SUPPORT_FLG}
            onChange={this.setPeerSupportFlg}
            value={params.peerSupportFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledPeerSupportFlg()}
          />
        </TableCellWrap>
        {/* 退居後ピアサポート実施 */}
        <TableCellWrap
          key={`${idx}-aft-leaving-peer-support-flg`}
          cellClass={middleCellClassName}
        >
          <DropDown
            id={`${idx}-aft-leaving-peer-support-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={AFT_LEAVING_PEER_SUPPORT_FLG}
            onChange={this.setAftLeavingPeerSupportFlg}
            value={params.aftLeavingPeerSupportFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledAftLeavingPeerSupportFlg()}
          />
        </TableCellWrap>

        {/* 8時間以上の利用 */}
        <TableCellWrap
          key={`${idx}-more-than-8hours-flg`}
          cellClass={shortCellClassName}
        >
          <DropDown
            id={`${idx}-more-than-8hours-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={MORE_THAN_8HOURS_FLG}
            onChange={this.setMoreThan8hoursFlg}
            value={params.moreThan8hoursFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledMoreThan8hoursFlg()}
          />
        </TableCellWrap>

        {/* 集中的支援加算 */}
        <TableCellWrap
          key={`${idx}-intensive-support-flg`}
          cellClass={classes.shortCell}
        >
          <DropDown
            id={`${idx}-intensive-support-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={INTENSIVE_SUPPORT_FLG}
            onChange={this.setIntensiveSupportFlg}
            value={params.intensiveSupportFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
          />
        </TableCellWrap>

        {/* 集中的支援加算 算定開始日 */}
        <TableCellWrap
          key={`${idx}-intensive-support-start-date`}
          cellClass={classes.veryLongCell}
        >
          <DateSelectFields
            id="start"
            label=" "
            from={from}
            to={to}
            value={params.intensiveSupportStartDate || ""}
            onChange={this.setIntensiveSupportStartDate}
            isDisabled={
              params.intensiveSupportFlg === "0" ||
              params.intensiveSupportFlg === ""
            }
            helperText={this.state.intensiveSupportStartDateErrorMessage}
            isError={!!this.state.intensiveSupportStartDateErrorMessage}
            isDefaultValue={false}
            isTableCell
          />
        </TableCellWrap>

        {/* 新興感染症等施設療養加算 */}
        <TableCellWrap
          key={`${idx}-infectious-diseases-facility-flg`}
          cellClass={classes.shortMiddleCell}
        >
          <DropDown
            id={`${idx}-infectious-diseases-facility-flg-dropdown`}
            label=""
            size="textFieldFullSize"
            options={INFECTIOUS_DISEASES_FACILITY_FLG}
            onChange={this.setInfectiousDiseasesFacilityFlg}
            value={params.infectiousDiseasesFacilityFlg || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={this.isDisabledInfectiousDiseasesFacilityFlg()}
          />
        </TableCellWrap>

        {/* 重度障害者支援加算 */}
        <TableCellWrap
          key={`${idx}-severe-disability-support-type`}
          cellClass={classes.shortMiddleCell}
        >
          <DropDown
            id={`${idx}-severe-disability-support-type-dropdown`}
            label=""
            size="textFieldFullSize"
            options={SEVERE_DISABILITY_SUPPORT_TYPE}
            onChange={this.setSevereDisabilitySupportType}
            value={params.severeDisabilitySupportType || "0"}
            helperText=""
            isError={false}
            styles={classes.dropDown}
            isDisabled={!params.severeDisabilitySupportFlg}
          />
        </TableCellWrap>

        {/* 備考 */}
        <TableCellWrap key={`${idx}-remarks`} cellClass={classes.lastCell}>
          <TextField
            id={`${idx}-remarks-text-field`}
            value={this.state.currentRemarks}
            size="textFieldFullSize"
            type="text"
            helperText={this.state.errorMessage.remarks}
            onChange={this.onChangeRemarks}
            onBlur={this.onBlurRemarks}
            isDisabled={params.isServiceEnd === SERVICE_END_STATUS.SERVICE_END}
            isError={!!this.state.errorMessage.remarks}
          />
        </TableCellWrap>
      </>
    );
  }
}

export default withStyles(styles)(UsagePerformanceReportCellEdit);
