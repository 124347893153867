import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
// ui
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { FieldItem } from "@interfaces/ui/form";
// formik
import { FormikProps } from "formik";
import {
  ServiceDeliveryDetailValues,
  DetailsInitialValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import { DAY_SELECT_OPTIONS, FacilityType } from "@constants/variables";
import Button from "@material-ui/core/Button";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      justifyContent: "space-between"
    },
    detail: {
      marginBottom: 24,
      borderBottom: "1px solid #ccc",
      "&:last-child": {
        marginBottom: 0
      }
    },
    delete: {
      paddingLeft: 16,
      marginBottom: 16
    }
  });

type OwnProps = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  unitKey: string;
  onClickDelete: (index: number, unit: DetailsInitialValues[0]) => void;
  onChangeTime: (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ) => void;
  onChangeSelect: (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ) => void;
  onChangeActionClass: (index: number, actionClass: number) => void;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerNum: number;
  actionClassOptions: FieldItem[];
  actionClassLabel: string;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const UnitsTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const formikValues = props.isEdit
      ? props.formikProps.values
      : props.formikProps.initialValues;
    const practitionerValues =
      props.practitionerNum === 1
        ? formikValues.serviceDeliveryRecordPractitioners1
        : formikValues.serviceDeliveryRecordPractitioners2;
    const { classes } = props;

    const units: DetailsInitialValues =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;

    const bodyData = (
      i: number,
      onClickDelete: () => void,
      onChangeInTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void,
      onChangeInTimeDate: (event: React.ChangeEvent<HTMLSelectElement>) => void,
      onChangeOutTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void,
      onChangeOutTimeDate: (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => void,
      onChangeClass: (event: React.ChangeEvent<HTMLSelectElement>) => void
    ): JSX.Element => {
      const isVehicleTransport =
        props.facilityType === FacilityType.IDOSHIEN &&
        formikValues.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value;
      return (
        <div className={classes.detail} key={`detail-${i}`}>
          {props.facilityType !== FacilityType.KODOENGO &&
          !isVehicleTransport ? (
            <FormikSelect
              key={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].actionClass`}
              isMobile
              readOnly={!props.isEdit}
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].actionClass`}
              label={`${props.actionClassLabel}/空き`}
              size="mobileHalf"
              options={props.actionClassOptions}
              className={`${props.classes.smallCellItemSelect} ${props.classes.firstItemCell}`}
              onChangeHook={onChangeClass}
            />
          ) : null}
          <div className={classes.flex}>
            <FormikSelect
              key={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTimeDate`}
              isMobile
              size="mobileHalf"
              readOnly={!props.isEdit}
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTimeDate`}
              label="開始時間"
              options={DAY_SELECT_OPTIONS}
              onChangeHook={onChangeInTimeDate}
              noerrortext
            />
            <FormikTime
              key={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTime`}
              isMobile
              size="mobileHalf"
              readOnly={!props.isEdit}
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTime`}
              placeholder="00:00"
              maxLength={5}
              onChangeHookTime={onChangeInTime}
            />
          </div>
          <div className={classes.flex}>
            <FormikSelect
              key={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTimeDate`}
              isMobile
              size="mobileHalf"
              readOnly={!props.isEdit}
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTimeDate`}
              label="終了時間"
              options={DAY_SELECT_OPTIONS}
              noerrortext
              onChangeHook={onChangeOutTimeDate}
            />
            <FormikTime
              key={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTime`}
              isMobile
              size="mobileHalf"
              readOnly={!props.isEdit}
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTime`}
              placeholder="00:00"
              maxLength={5}
              onChangeHookTime={onChangeOutTime}
            />
          </div>
          {props.isEdit && (
            <Button
              key={`delete-${i}`}
              className={classes.delete}
              variant="text"
              onClick={onClickDelete}
            >
              <DeleteIcon
                style={{
                  width: 24,
                  height: 28,
                  color: "#0277bd",
                  cursor: "pointer",
                  marginRight: 7
                }}
              />
              <div style={{ color: "#0277bd" }}>削除する</div>
            </Button>
          )}
        </div>
      );
    };

    const bodyValues = units
      // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((unit: { value: DetailsInitialValues[0]; baseIndex: number }) => {
        // 提供人数「２」→「１」の時の対応（選択肢が””になるのを防ぐ）
        if (!unit.value.startTimeDate) {
          props.setFormikFieldValue(
            `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${unit.baseIndex}].startTimeDate`,
            DAY_SELECT_OPTIONS[0].value
          );
        }
        if (!unit.value.endTimeDate) {
          props.setFormikFieldValue(
            `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${unit.baseIndex}].endTimeDate`,
            DAY_SELECT_OPTIONS[0].value
          );
        }

        const onClickDelete = (): void => {
          props.onClickDelete(unit.baseIndex, unit.value);
        };
        const onChangeInTime = (
          event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >,
          beforeValue: string,
          autoCorrectValue: string
        ): void => {
          props.onChangeTime(
            unit.baseIndex,
            autoCorrectValue,
            unit.value.endTime,
            true
          );
        };
        const onChangeInTimeDate = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeSelect(
            unit.baseIndex,
            event.target.value,
            unit.value.endTimeDate,
            true
          );
        };
        const onChangeOutTime = (
          event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >,
          beforeValue: string,
          autoCorrectValue: string
        ): void => {
          props.onChangeTime(
            unit.baseIndex,
            unit.value.startTime,
            autoCorrectValue,
            false
          );
        };
        const onChangeOutTimeDate = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeSelect(
            unit.baseIndex,
            unit.value.startTimeDate,
            event.target.value,
            false
          );
        };

        const onChangeClass = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeActionClass(unit.baseIndex, Number(event.target.value));
        };

        return bodyData(
          unit.baseIndex,
          onClickDelete,
          onChangeInTime,
          onChangeInTimeDate,
          onChangeOutTime,
          onChangeOutTimeDate,
          onChangeClass
        );
      });

    return <>{bodyValues}</>;
  }
);
