/**
 * boolean => 0 or 1
 */
const booleanToNumber0or1 = (
  value: boolean | undefined
): number | undefined => {
  return value !== undefined ? +value : value;
};

export default booleanToNumber0or1;
