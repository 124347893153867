import * as React from "react";
import { Link } from "react-router-dom";

import List from "@material-ui/core/List";
import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import Business from "@material-ui/icons/Business";

import ListItem from "@components/molecules/ListItem";

const styles = (): StyleRules =>
  createStyles({
    row: {
      textDecoration: "none"
    },
    businessIcon: {
      color: "#90a4ae"
    },
    list: {
      paddingTop: 0
    }
  });

type MunicipalitiesParam = {
  id: number;
  name: string;
};

type Props = WithStyles<typeof styles> & {
  municipalities: MunicipalitiesParam[];
};

const userList = ({ municipalities = [], classes }: Props): JSX.Element => {
  return (
    <List className={classes.list}>
      {municipalities.length > 0 &&
        municipalities.map((municipality) => (
          <Link
            to={`/municipality/${municipality.id}`}
            key={municipality.id}
            className={classes.row}
          >
            <ListItem id={municipality.id} theme="userList">
              <Business className={classes.businessIcon} />
              <ListItemText primary={municipality.name} />
            </ListItem>
          </Link>
        ))}
    </List>
  );
};

export default withStyles(styles)(userList);
