import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrap: {
      borderRadius: 4,
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      display: "flex",
      justifyContent: "space-between"
    },
    summaryWrap: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    button: {
      minWidth: 142,
      minHeight: 30
    }
  });

type OwnProps = {
  status_type?: {
    stay: number;
    hospitalization: number;
    stay_away: number;
    denominator: number;
  };
  disabledDetail?: boolean;
  handleClickDetail?: () => void;
  validUsersTotal: number;
};
type Props = OwnProps & WithStyles<typeof styles>;

const StatusTypeSummaryCore = (props: Props): JSX.Element => {
  const {
    stay,
    hospitalization,
    stay_away,
    denominator
  } = props.status_type || {
    stay: 0,
    hospitalization: 0,
    stay_away: 0,
    denominator: props.validUsersTotal
  };
  return (
    <div className={props.classes.wrap}>
      <div className={props.classes.summaryWrap}>
        <div className={props.classes.summaryContent}>{`宿泊：${stay} 人`}</div>
        <div
          className={props.classes.summaryContent}
        >{`入院：${hospitalization} 人`}</div>
        <div
          className={props.classes.summaryContent}
        >{`外泊：${stay_away} 人`}</div>
        <div
          className={props.classes.summaryContent}
        >{`/ ${denominator} 人`}</div>
      </div>
      {props.handleClickDetail && (
        <KnowbeButton
          kind="outlineWhite"
          disabled={props.disabledDetail}
          className={props.classes.button}
          onClick={props.handleClickDetail}
        >
          利用状況の詳細
        </KnowbeButton>
      )}
    </div>
  );
};

export const StatusTypeSummary = withStyles(styles)(StatusTypeSummaryCore);
