import React, { useState } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import {
  AccountState,
  NewMobileAccountParams,
  UpdatePasswordParams
} from "@stores/domain/account/type";
import Collapse from "@material-ui/core/Collapse";
import { CurrentPassword } from "@components/organisms/account/CurrentPassword";
import { CurrentAccountName } from "@components/organisms/account/CurrentAccountName";
import { PasswordForm } from "@components/organisms/account/PasswordForm";
import { CreateAccountForm } from "@components/organisms/account/CreateAccountForm";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import * as H from "history";

const styles = (): StyleRules =>
  createStyles({
    form: {
      marginTop: 32
    },
    caution: {
      margin: "8px 0 32px 0",
      color: "#666",
      fontSize: 16,
      lineHeight: 1.75
    },
    button: {
      display: "block",
      margin: "0 0 0 auto",
      padding: "6px 16px"
    }
  });

type OwnProps = {
  history: H.History;
};

type StateProps = {
  account: AccountState;
  errorType: string;
  needsStopHistory: boolean;
};

type DispatchProps = {
  activateMobileAccount: (
    params: NewMobileAccountParams,
    history: H.History
  ) => void;
  updatePassword: (params: UpdatePasswordParams) => void;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const AccountMobilePasswordCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const [isEdit, toggleIsEdit] = useState(false);

  const closeForm = (): void => {
    toggleIsEdit(false);
  };

  const openForm = (): void => {
    toggleIsEdit(true);
  };

  return (
    <>
      {props.account.mobile.email ? (
        <>
          <CurrentAccountName
            label="アカウント名"
            account={props.account.mobile.email}
          />
          <div className={classes.form}>
            <Collapse in={isEdit} collapsedHeight="44px">
              {/* height => Formの高さ */}
              <div style={{ height: 598 }}>
                {!isEdit && <CurrentPassword onClick={openForm} />}
                {/* 閉じても値を保持する */}
                <div style={{ display: isEdit ? "block" : "none" }}>
                  <PasswordForm
                    email={props.account.mobile.email}
                    adminId={props.account.admin.email}
                    errorType={props.errorType}
                    needsStopHistory={props.needsStopHistory}
                    currentPasswordLabel="現在の管理画面パスワードを入力してください"
                    currentPasswordPlaceholder=""
                    newPasswordLabel="新しいモバイル画面パスワード"
                    newPasswordConfirmLabel="新しいモバイル画面パスワード（確認）"
                    onClose={closeForm}
                    updatePassword={props.updatePassword}
                    stopHistory={props.stopHistory}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        </>
      ) : (
        <>
          <p className={classes.caution}>
            モバイル端末で記録機能を利用する場合は、こちらからアカウントを作成してください
          </p>
          <div className={classes.form}>
            <Collapse in={isEdit} collapsedHeight="44px">
              {/* height => Formの高さ */}
              <div style={{ height: 350 }}>
                {!isEdit && (
                  <KnowbeButton
                    className={classes.button}
                    minWidth={160}
                    onClick={openForm}
                  >
                    モバイル画面アカウントを作成する
                  </KnowbeButton>
                )}
                {/* 閉じても値を保持する */}
                <div style={{ display: isEdit ? "block" : "none" }}>
                  <CreateAccountForm
                    adminId={props.account.admin.email}
                    errorType={props.errorType}
                    errors={props.account.errors}
                    needsStopHistory={props.needsStopHistory}
                    mobileAccountLabel="モバイル画面アカウント"
                    mobileAccountPlaceholder="例）knowbe123!"
                    passwordLabel="モバイル画面パスワード"
                    passwordConfirmLabel="モバイル画面パスワード（確認）"
                    confirmButtonLabel="上記の内容に同意し、作成する"
                    onClose={closeForm}
                    activateMobileAccount={props.activateMobileAccount}
                    history={props.history}
                    stopHistory={props.stopHistory}
                  />
                </div>
              </div>
            </Collapse>
          </div>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  account: state.account,
  errorType: state.account.errorType,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { accountDispatch, uiDispatch } = dispatches;
  const accountDispatches = accountDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    activateMobileAccount: (
      params: NewMobileAccountParams,
      history: H.History
    ): Promise<void> =>
      accountDispatches.activateMobileAccount(params, history),
    updatePassword: (params: UpdatePasswordParams): Promise<void> =>
      accountDispatches.updatePassword(params, "mobile"),
    stopHistory: uiDispatches.stopHistory
  };
};

export const AccountMobilePassword = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccountMobilePasswordCore)
);
