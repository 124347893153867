import * as React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { StaffsForm } from "@components/organisms/mgr/DOKOENGO/staffs/StaffsForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import AlertDialog from "@components/organisms/AlertDialog";

/**
 * 職員情報
 */
export const Staffs = (): JSX.Element => {
  return (
    <AdminTemplate pageName="職員情報">
      <StaffsForm />
      <NavigationTransitionPrompt />
      <AlertDialog />
    </AdminTemplate>
  );
};
