import checkTime from "@validator/rules/checkTime";

/**
 * 未来時刻かどうかをチェック
 *
 * @param endTime   // hh:mm
 * @param startTime // hh:mm
 * @param option
 */
const checkTimeFuture = (
  endTime: string,
  startTime: string,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  let errorMessage;
  if (!!checkTime(startTime) || !!checkTime(endTime)) {
    /* eslint-disable no-console */
    console.error("only allow hh:mm string");
    return undefined;
  }
  const start = startTime.replace(":", "");
  const end = endTime.replace(":", "");

  if (parseInt(end, 10) - parseInt(start, 10) <= 0) {
    errorMessage = `${option.firstLabel}は${option.secondLabel}より後の時間を入力してください`;
  }
  return errorMessage;
};

export default checkTimeFuture;
