import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import { OperationSettingForm } from "@components/organisms/record/setting/OperationSettingForm";
import { SupportSettingForm } from "@components/organisms/record/setting/SupportSettingForm";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { AppState } from "@stores/type";
import { HiddenItemsModal } from "@components/organisms/mgr/common/record/HiddenItemsModal";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    topSection: {
      marginTop: 32
    }
  });

type DispatchProps = {
  fetchOptionalCustomInfo: () => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  hideCustomRecord: (recordId: number) => void;
  showCustomRecord: (recordIds: number[]) => void;
};

type StateProps = {
  customRecords: CustomRecordsState;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const SettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    customRecords,
    fetchOptionalCustomInfo,
    fetchCustomRecords,
    hideCustomRecord,
    showCustomRecord
  } = props;
  const [isOpenHideConfirm, setOpenHideConfirm] = React.useState(false);
  const [hideConfirmTargetId, setHideConfirmTargetId] = React.useState(0);
  const hideConfirmTarget =
    hideConfirmTargetId > 0 && customRecords.length > 0
      ? customRecords.find((record) => record.id === hideConfirmTargetId)
      : "";
  const hideConfirmTargetName = hideConfirmTarget ? hideConfirmTarget.name : "";
  const [
    isOpenHiddenCustomRecordList,
    setOpenHiddenCustomRecordList
  ] = React.useState(false);
  const [
    HiddenCustomRecordListType,
    setHiddenCustomRecordListType
  ] = React.useState(0);

  // 並べ替えモード用 state
  const [sortingSettingType, setSortingSettingType] = React.useState<
    null | number
  >(null);

  const openHideConfirmModal = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setHideConfirmTargetId(+event.target.value);
    setOpenHideConfirm(true);
  };
  const closeHideConfirmModal = (): void => {
    setOpenHideConfirm(false);
  };

  const onClickHide = async (): Promise<void> => {
    setOpenHideConfirm(false);
    if (hideConfirmTargetId === 0) return;
    await hideCustomRecord(hideConfirmTargetId);
    fetchCustomRecords();
  };

  const openHiddenCustomRecordsList = (type: number): void => {
    setHiddenCustomRecordListType(type);
    setOpenHiddenCustomRecordList(true);
  };
  const closeHiddenCustomRecordsList = (): void => {
    setOpenHiddenCustomRecordList(false);
  };
  const onClickVisible = async (recordIds: number[]): Promise<void> => {
    await showCustomRecord(recordIds);
    await setOpenHiddenCustomRecordList(false);
    await fetchCustomRecords();
  };

  useEffect(() => {
    fetchCustomRecords();
    fetchOptionalCustomInfo();
  }, []);

  const hideConfirmModalMessage = (
    <span>
      この項目は記録画面に表示されなくなり、「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
    </span>
  );

  return (
    <section className={classes.topSection}>
      <OperationSettingForm
        {...{
          customRecords,
          sortingSettingType,
          setSortingSettingType,
          openHideConfirmModal,
          openHiddenCustomRecordsList,
          fetchCustomRecords
        }}
      />
      <SupportSettingForm
        {...{
          customRecords,
          sortingSettingType,
          setSortingSettingType,
          openHideConfirmModal,
          openHiddenCustomRecordsList,
          fetchCustomRecords
        }}
      />
      <MessageDialog
        isOpen={isOpenHideConfirm}
        title={`「${hideConfirmTargetName}」を非表示にしますか？`}
        message={hideConfirmModalMessage}
        closeButton={
          <KnowbeButton
            kind="text"
            style={{ margin: "0 8px 0 0" }}
            onClick={closeHideConfirmModal}
          >
            キャンセル
          </KnowbeButton>
        }
        actionButton={
          <KnowbeButton
            kind="textDelete"
            style={{ margin: 0 }}
            onClick={onClickHide}
          >
            非表示にする
          </KnowbeButton>
        }
      />
      <HiddenItemsModal
        isModalOpen={isOpenHiddenCustomRecordList}
        onClose={closeHiddenCustomRecordsList}
        onVisible={onClickVisible}
        HiddenCustomRecordListType={HiddenCustomRecordListType}
        customRecords={props.customRecords}
      />
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { unitsDispatcher, customRecords } = dispatches;
  const unitsDispatches = unitsDispatcher(dispatch);
  const customRecordsDispatches = customRecords(dispatch);

  return {
    fetchOptionalCustomInfo: (): Promise<void> =>
      unitsDispatches.fetchOptionalCustomInfo(),
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(0);
    },
    hideCustomRecord: (recordId: number): Promise<void> => {
      return customRecordsDispatches.hideCustomRecord(recordId);
    },
    showCustomRecord: (recordIds: number[]): Promise<void> => {
      return customRecordsDispatches.showCustomRecord(recordIds);
    }
  };
};

export const SettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingFormCore)
);
