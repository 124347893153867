import * as types from "./types";
import { GetMeetingRecordResponse } from "@api/requests/meetingRecord/getMeetingRecord";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchMeetingRecordStarted = () =>
  ({ type: types.FETCH_CSEG_MEETING_RECORD_STARTED } as const);
export const fetchMeetingRecordSuccess = (
  res: GetMeetingRecordResponse["data"]
) =>
  ({
    type: types.FETCH_CSEG_MEETING_RECORD_SUCCESS,
    payload: res
  } as const);
export const fetchMeetingRecordFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_CSEG_MEETING_RECORD_FAILED,
    error: err
  } as const);
export const postMeetingRecordStarted = () =>
  ({ type: types.POST_CSEG_MEETING_RECORD_STARTED } as const);
export const postMeetingRecordSuccess = () =>
  ({
    type: types.POST_CSEG_MEETING_RECORD_SUCCESS
  } as const);
export const postMeetingRecordFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_CSEG_MEETING_RECORD_FAILED,
    error: err
  } as const);
export const deleteMeetingRecordStarted = () =>
  ({ type: types.DELETE_CSEG_MEETING_RECORD_STARTED } as const);
export const deleteMeetingRecordSuccess = () =>
  ({ type: types.DELETE_CSEG_MEETING_RECORD_SUCCESS } as const);
export const deleteMeetingRecordFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_CSEG_MEETING_RECORD_FAILED, error: err } as const);
export const unsetMeetingRecord = () =>
  ({ type: types.UNSET_CSEG_MEETING_RECORD } as const);

export type ActionTypes =
  | ReturnType<typeof fetchMeetingRecordStarted>
  | ReturnType<typeof fetchMeetingRecordSuccess>
  | ReturnType<typeof fetchMeetingRecordFailed>
  | ReturnType<typeof postMeetingRecordStarted>
  | ReturnType<typeof postMeetingRecordSuccess>
  | ReturnType<typeof postMeetingRecordFailed>
  | ReturnType<typeof deleteMeetingRecordStarted>
  | ReturnType<typeof deleteMeetingRecordSuccess>
  | ReturnType<typeof deleteMeetingRecordFailed>
  | ReturnType<typeof unsetMeetingRecord>;
