import * as React from "react";

// ui
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import { WorkingTimeFields } from "@components/v202104/organisms/mgr/IAB/users/WorkingTimeFields";
import { StatusFields } from "@components/v202104/organisms/mgr/IAB/users/StatusFields";

// state
import { connect } from "react-redux";
import { FacilityState } from "@stores/v202104/domain/mgr/IAB/facility/types";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

// formik
import { UsersValues } from "@initialize/v202104/mgr/IAB/users/initialValues";
import { FormikProps } from "formik";

const styles = (): StyleRules =>
  createStyles({
    sectionTitle: { marginBottom: 32 }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  isFetchDone?: boolean;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type StateProps = {
  user: UserState;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const DefaultSettingsFieldsCore = (props: Props): JSX.Element => {
  return (
    <FormPaper>
      <div className={props.classes.sectionTitle}>
        <SectionTitle label="デフォルト設定" />
      </div>
      {/* status ※ */}
      <StatusFields formikProps={props.formikProps} facility={props.facility} />
      {/* 作業時間 ※ */}
      {props.user.featureGroup.group_labor_charge === 1 && (
        <>
          <GrayMiddleHeading text="作業時間" />
          <WorkingTimeFields formikProps={props.formikProps} />
        </>
      )}
    </FormPaper>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user
});

export const DefaultSettingsFields = connect(mapStateToProps)(
  withStyles(styles)(DefaultSettingsFieldsCore)
);
