import * as React from "react";
import * as classNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import {
  SUCCESS_COLOR,
  WARNING_COLOR,
  SECONDARY_BLUE_COLOR,
  SECONDARY_BACKGROUND_COLOR
} from "@constants/styles";
import Error from "@material-ui/icons/Error";
import CheckCircle from "@material-ui/icons/CheckCircle";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { RecommendationName } from "@utils/recommendation";

const styles = (): StyleRules =>
  createStyles({
    browser: {
      background: SECONDARY_BACKGROUND_COLOR,
      padding: "24px 32px",
      marginBottom: 32
    },
    browserTitle: {
      display: "flex",
      alignItems: "center"
    },
    browserTitleIcon: {
      width: 24,
      height: 24
    },
    browserTitleCon: {
      marginLeft: 4
    },
    wrapper: {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      minHeight: "auto!important",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: "normal"
    },
    content: {
      "_::-webkit-full-page-media, _:future, :root &": {
        fontWeight: 300
      },
      "@media only screen and (min-width: 640px)": {
        padding: "32px 32px 0"
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px 0"
      }
    },
    contentTitle: {
      margin: 0,
      fontSize: 24,
      lineHeight: "normal",
      fontWeight: "normal",
      letterSpacing: "normal",
      "_::-webkit-full-page-media, _:future, :root &": {
        fontWeight: 300
      }
    },
    linkBtn: {
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      appearance: "none",
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "@media only screen and (min-width: 640px)": {
        padding: 32
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px"
      }
    },
    linkBtnCon: {
      margin: 0,
      color: "rgba(2, 119, 189, 0.87)"
    },
    accordion: {
      "@media only screen and (min-width: 640px)": {
        padding: "0 32px 32px"
      },
      "@media only screen and (max-width: 639px)": {
        padding: "0 16px 32px"
      }
    },
    accordionItem: {
      marginTop: 24,
      "&+&": {
        paddingTop: 24,
        borderTop: "1px solid #cfd8dc"
      }
    },
    MT8: {
      marginTop: 8
    },
    MT16: {
      marginTop: 16
    },
    MT24: {
      marginTop: 24
    },
    MT26: {
      marginTop: 26
    },
    icon: {
      width: 24,
      height: 24,
      color: SECONDARY_BLUE_COLOR
    },
    colorGreen: {
      color: SUCCESS_COLOR
    },
    colorRed: {
      color: WARNING_COLOR
    }
  });

type OwnProps = { res: RecommendationName } & WithStyles<typeof styles>;
type Props = OwnProps;

const LoginCautionCore = (props: Props): JSX.Element => {
  const { classes, res } = props;

  // アコーディオン用
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const onClickArrow = (): void => {
    setIsOpen(!isOpen);
  };

  // 機器ブラウザブロック
  const accordionBrowser = (
    <div className={classes.browser}>
      {res.recommendation ? (
        <>
          <div className={classes.browserTitle}>
            <CheckCircle
              className={classNames(
                classes.browserTitleIcon,
                classes.colorGreen
              )}
            />
            <span className={classes.browserTitleCon}>
              現在の機器 / ブラウザ
            </span>
          </div>
          <div className={classes.MT8}>
            {`${res.terminalName} / ${res.browserName}`}
          </div>
        </>
      ) : (
        <>
          <div className={classes.browserTitle}>
            <Error
              className={classNames(classes.browserTitleIcon, classes.colorRed)}
            />
            <span className={classes.browserTitleCon}>
              現在の機器 / ブラウザ
            </span>
          </div>
          <div className={classes.MT8}>
            {`${res.terminalName} / ${res.browserName}`}
          </div>
          <div className={classNames(classes.browserTitle, classes.MT16)}>
            <CheckCircle
              className={classNames(
                classes.browserTitleIcon,
                classes.colorGreen
              )}
            />
            <span className={classes.browserTitleCon}>
              推奨の機器 / ブラウザ
            </span>
          </div>
          <div className={classes.MT8}>{res.recommendationName}</div>
        </>
      )}
    </div>
  );

  return (
    <Paper className={classes.wrapper}>
      {/* 必ずお読みください */}
      {!res.recommendation && (
        <div className={classes.content}>
          <h2 className={classes.contentTitle}>必ずお読みください</h2>
          <div className={classes.MT24}>
            現在ご利用中の機器およびブラウザは当社が動作を確認したものではない為、正しく動作しない可能性があります。
          </div>
          <div className={classes.MT26}>
            推奨の機器、ブラウザをお使いください。
          </div>
        </div>
      )}

      {/* 推奨環境について・ボタン */}
      <button type="button" className={classes.linkBtn} onClick={onClickArrow}>
        <p className={classes.linkBtnCon}>推奨環境について</p>
        {isOpen ? (
          <KeyboardArrowUp className={classes.icon} />
        ) : (
          <KeyboardArrowDown className={classes.icon} />
        )}
      </button>

      {/* 推奨環境について・内容 */}
      {isOpen && (
        <div className={classes.accordion}>
          {res.browserName &&
            res.terminalName !== "unknown" &&
            accordionBrowser}
          <>
            以下の機器およびブラウザでのご利用を推奨いたします。
            <div className={classes.accordionItem}>
              パソコン:
              <br />
              Windows: Google Chrome
              <br />
              Mac: Google Chrome
            </div>
            <div className={classes.accordionItem}>
              スマートフォン:
              <br />
              ※訪問系サービス用モバイル画面のみ対応
              <br />
              iPhone: Safari
              <br />
              Android: Google Chrome
            </div>
            <div className={classes.accordionItem}>
              タブレット:
              <br />
              ※タイムカードおよび共同生活援助(GH)記録機能の基本動作のみ対応
              <br />
              iPad: Google Chrome
              <br />
              Android: Google Chrome
            </div>
          </>
        </div>
      )}
    </Paper>
  );
};

export const LoginCaution = withStyles(styles)(LoginCautionCore);
