import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type getCustomRecordsWithCategoryResponse = {
  data: {
    id: number;
    category_type: number;
    name: string;
    order: number | null;
    visibility: number;
    custom_records_keikakusodan_visibility?: {
      id: number;
      plan_visibility?: number;
      child_visibility?: number;
    };
    allow_edit_name?: number;
    allow_change_order?: number;
    allow_add_items?: number;
    change_items_order_button_display_flg?: number;
    default_category?: number | null;
    custom_record_items: {
      id: number;
      default_item: number | null;
      name: string;
      input_type: number;
      setting_type: number;
      order: number | null;
      visibility: number;
      custom_records_keikakusodan_visibility?: {
        id: number;
        plan_visibility?: number;
        child_visibility?: number;
      };
      custom_records_category_id: number;
      custom_record_item_choices: {
        id: number;
        custom_record_item_id: number;
        name: string;
        default_choice: number | null;
        hidden: number;
        default_choice_input_type: number | null;
      }[];
      allow_edit_name: number;
      allow_edit_choiced: number;
      allow_change_order: number;
      allow_change_visibility: number;
      setting_page_visibility: number;
    }[];
  }[];
};

/**
 * カスタム項目を取得する
 */
export const getCustomRecordsWithCategory = async (
  target?: number
): Promise<AxiosResponse<getCustomRecordsWithCategoryResponse>> => {
  const url = `${VERSION_URL_201910}/custom_records`;
  const query = target ? `?target=${target}` : "";
  return request.get<getCustomRecordsWithCategoryResponse>(url + query);
};
