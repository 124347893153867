import * as action from "./action";
import { Dispatch } from "redux";
import { dateInYYYYMMDDFormat, dateToObject } from "@utils/date";
import dispatches from "@stores/dispatches";
import {
  normalizeAttendanceListFromAPI,
  normalizeAttendanceFromAPI
} from "@stores/domain/attendance/normalizer";
import * as loadingActions from "@stores/loading/actions";
import inOutApi from "@api/requests/inOut";
import * as snackbarActions from "@stores/ui/snackbar/actions";

const isLogout = (status: number): boolean => {
  return status === 400 || status === 401;
};

const fetchAttendanceList = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatches.uiDispatch(dispatch).loading(true);
  await inOutApi
    .getInOutList(dateInYYYYMMDDFormat(new Date()))
    .then((res) => {
      dispatch(
        action.fetchAttendanceList(normalizeAttendanceListFromAPI(res.data))
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatches.uiDispatch(dispatch).loading(false);
      if (e.response && e.response.status && isLogout(e.response.status)) {
        dispatches.authDispatch(dispatch).logout();
      }
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    });
};

const fetchAttendance = (dispatch: Dispatch) => async (
  id: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  await inOutApi
    .getUserInOut(id, dateInYYYYMMDDFormat(new Date()))
    .then((res) => {
      if (res.data.data) {
        dispatch(
          action.fetchAttendance(normalizeAttendanceFromAPI(res.data.data, id))
        );
      }
    })
    .catch((e) => {
      if (e.response && e.response.status && isLogout(e.response.status)) {
        dispatches.authDispatch(dispatch).logout();
      }
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const inTime = (dispatch: Dispatch) => async (
  id: number,
  name: string,
  status: number
): Promise<void> => {
  const requestParams = {
    status,
    in_time: new Date().getTime()
  };
  dispatches.uiDispatch(dispatch).loading(true);
  await inOutApi
    .postInTime(id, requestParams)
    .then(() => {
      inOutApi
        .getInOutList(dateInYYYYMMDDFormat(new Date()))
        .then((list) => {
          dispatch(
            action.fetchAttendanceList(
              normalizeAttendanceListFromAPI(list.data)
            )
          );
          dispatches.uiDispatch(dispatch).snackbar({
            open: true,
            // テキスト内のため全角スペースを許容
            // eslint-disable-next-line no-irregular-whitespace
            message: `${name}さん　${dateToObject(new Date()).hour}:${
              dateToObject(new Date()).minute
            }に開始しました`,
            variant: "success"
          });
          dispatches.uiDispatch(dispatch).loading(false);
        })
        .catch((e) => {
          if (e.response && e.response.status && isLogout(e.response.status)) {
            dispatches.authDispatch(dispatch).logout();
          }
          dispatches.uiDispatch(dispatch).loading(false);
        });
    })
    .catch((e) => {
      if (e.response && e.response.status && isLogout(e.response.status)) {
        dispatches.authDispatch(dispatch).logout();
      } else {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "開始処理に失敗しました。",
          variant: "error"
        });
      }
      dispatches.uiDispatch(dispatch).loading(false);
    });
};

const outTime = (dispatch: Dispatch) => async (
  id: number,
  name: string,
  status: number,
  date: Date,
  breakTime: string | undefined
): Promise<void> => {
  const requestParams = {
    status,
    out_time: date.getTime(),
    break_time: breakTime
  };
  dispatches.uiDispatch(dispatch).loading(true);
  await inOutApi
    .postOutTime(id, requestParams)
    .then(() => {
      inOutApi
        .getInOutList(dateInYYYYMMDDFormat(new Date()))
        .then((list) => {
          dispatch(
            action.fetchAttendanceList(
              normalizeAttendanceListFromAPI(list.data)
            )
          );
          dispatches.uiDispatch(dispatch).snackbar({
            open: true,
            // テキスト内のため全角スペースを許容
            // eslint-disable-next-line no-irregular-whitespace
            message: `${name}さん　${dateToObject(new Date()).hour}:${
              dateToObject(new Date()).minute
            }に終了しました`,
            variant: "success"
          });
          dispatches.uiDispatch(dispatch).loading(false);
        })
        .catch((e) => {
          if (e.response && e.response.status && isLogout(e.response.status)) {
            dispatches.authDispatch(dispatch).logout();
          }
          dispatches.uiDispatch(dispatch).loading(false);
        });
    })
    .catch((e) => {
      if (e.response && e.response.status && isLogout(e.response.status)) {
        dispatches.authDispatch(dispatch).logout();
      } else {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "終了処理に失敗しました。",
          variant: "error"
        });
      }
      dispatches.uiDispatch(dispatch).loading(false);
    });
};

type Dispatcher = {
  fetchAttendanceList: () => Promise<void>;
  fetchAttendance: (id: number) => Promise<void>;
  inTime: (id: number, name: string, status: number) => Promise<void>;
  outTime: (
    id: number,
    name: string,
    status: number,
    date: Date,
    breakTime: string | undefined
  ) => Promise<void>;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    fetchAttendanceList: fetchAttendanceList(dispatch),
    fetchAttendance: fetchAttendance(dispatch),
    inTime: inTime(dispatch),
    outTime: outTime(dispatch)
  };
}
