import { InitialDataValues } from "./initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { InitialErrors } from "@interfaces/mgr/SHISETSUNYUSHO/report/initial";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import {
  HospitalizationOvernightstay,
  INTENSIVE_SUPPORT_FLG
} from "@constants/mgr/SHISETSUNYUSHO/variables";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.remarks === undefined ||
      validationResult.initial.hospitalizationDays === undefined ||
      validationResult.initial.intensiveSupportStartDate === undefined
    );
  }
  return false;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      remarks: validator(values.initial.remarks, {
        type: "checkCharacterLength",
        length: 50
      }),
      hospitalizationDays:
        values.initial.hospitalizationDays !== null &&
        values.initial.hospitalizationDays.toString() !== "-"
          ? validator(
              values.initial.hospitalizationDays.toString(),
              "naturalNumber"
            )
          : undefined,
      intensiveSupportStartDate: validateSwitcher(
        values.initial.intensiveSupportFlg !== INTENSIVE_SUPPORT_FLG[0].value &&
          values.initial.statusType !== HospitalizationOvernightstay.NONE,
        dateValidator(
          notSelectedToEmpty(values.initial.intensiveSupportStartDate),
          "required"
        )
      )
    }
  };
};

const validation = (values: InitialDataValues): InitialDataErrors => {
  return { ...initialValidation(values) };
};

export default validation;
