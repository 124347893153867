import {
  InquiryErrors,
  InquiryFields
} from "@interfaces/mgr/KEIKAKUSODAN/record/inquiry";
import { dateValidator } from "@validator";
import { checkCharacterLength } from "@validator/rules";

export const validation = (values: InquiryFields): InquiryErrors => {
  return {
    creation_date: dateValidator(values.creation_date, "required"),
    why_not: checkCharacterLength(values.why_not, 2500),
    inquiry_purpose: checkCharacterLength(values.inquiry_purpose, 2500),
    inquiry_destination: checkCharacterLength(values.inquiry_destination, 2500),
    inquiry_content: checkCharacterLength(values.inquiry_content, 2500),
    respondent_name: checkCharacterLength(values.respondent_name, 2500),
    response_content: checkCharacterLength(values.response_content, 2500)
  };
};
