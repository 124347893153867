import React from "react";

export type TargetObject = {
  targetDate: string | null;
  serviceDeliveryRecordsId: number | null;
  inoutResultsId: number | null;
  supportProcedureFormsId: number | null;
};

export const TargetObjectInitialValue: TargetObject = {
  targetDate: null,
  serviceDeliveryRecordsId: null,
  inoutResultsId: null,
  supportProcedureFormsId: null
};

export const useLocalStorage = (): {
  getTargetObject: () => TargetObject[];
  setTargetObject: (val: TargetObject[]) => void;
  resetTargetObject: () => void;
} => {
  const key = "selectedTargetObject";
  const [state, setState] = React.useState<TargetObject[]>(() => {
    try {
      const val = JSON.parse(`${localStorage.getItem(key)}`);
      if (val !== null) return val;
      return [{ ...TargetObjectInitialValue }];
    } catch {
      return [{ ...TargetObjectInitialValue }];
    }
  });

  const getTargetObject = React.useCallback((): TargetObject[] => {
    return state;
  }, [state]);

  const setTargetObject = React.useCallback((val: TargetObject[]) => {
    setState(val);
    localStorage.setItem(key, JSON.stringify(val));
  }, []);

  const resetTargetObject = React.useCallback(() => {
    setState([{ ...TargetObjectInitialValue }]);
    localStorage.setItem(
      key,
      JSON.stringify([{ ...TargetObjectInitialValue }])
    );
  }, []);

  return { getTargetObject, setTargetObject, resetTargetObject };
};
