import React from "react";
import { connect } from "react-redux";
import { CurrentAccountName } from "@components/organisms/account/CurrentAccountName";
import { FacilityUser } from "@stores/domain/account/type";
import { AppState } from "@stores/type";

type StateProps = {
  admin: FacilityUser;
};

type Props = StateProps;

const AccountMailCore = (props: Props): JSX.Element => {
  return (
    <CurrentAccountName
      label="現在のメールアドレス"
      account={props.admin.email}
    />
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  admin: state.account.admin
});

export const AccountMail = connect(mapStateToProps)(AccountMailCore);
