import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { FormikProps } from "formik";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/v202104/mgr/JUDOHOMONKAIGO/Users/initial";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import {
  DETAIL_IN_TIME_VALIDATION_LIST,
  DETAIL_OUT_TIME_VALIDATION_LIST,
  JUDOHOMONKAIGO_ACTION_CLASS_SELECT_LIST,
  TIME_CLASS_LIST
} from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { FormGroup } from "@material-ui/core";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 551,
      marginBottom: 18
    },
    firstCellLabel: {
      paddingLeft: 16
    },
    firstCell: {
      "&:first-child": {
        padding: "16px 0px 16px 16px"
      },
      "& > div": {
        width: 80,
        marginBottom: 0
      }
    },
    firstItemCell: {
      "&:last-child": {
        margin: 0
      }
    },
    timeClassCell: {
      "&:first-child": {
        padding: "16px 0px 16px 16px"
      },
      "& > div": {
        width: 80,
        marginBottom: 0,
        marginRight: 8
      }
    },
    timeClassItemCell: {
      "&:last-child": {
        margin: 0
      }
    },
    timeCell: {
      "&:first-child": {
        padding: "16px 0px 16px 16px"
      },
      "& > div": {
        width: 80,
        marginBottom: 0
      }
    },
    iconCell: {
      display: "inline-flex",
      verticalAlign: "top"
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  unitKey: string;
  onClickDelete: (index: number) => void;
  onChangeTime: (
    action: string,
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const UnitsTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const units: CarePlanDetailsFields[] =
      props.formikProps.values.initial[props.unitKey];

    const state = {
      detailActionClassList: JUDOHOMONKAIGO_ACTION_CLASS_SELECT_LIST
    };

    const headData = [
      {
        label: "移動/空き",
        className: classes.firstCellLabel
      },
      {
        label: "開始時間*"
      },
      {
        label: ""
      },
      {
        label: "終了時間*"
      },
      {
        label: ""
      },
      {
        label: "削除"
      }
    ];

    const bodyData = (
      i: number,
      onClickDelete: () => void,
      onChangeAction: (
        event: React.ChangeEvent<HTMLSelectElement>,
        beforeValue: string
      ) => void,
      onChangeInTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string
      ) => void,
      onChangeInTimeClass: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string
      ) => void,
      onChangeOutTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string
      ) => void,
      onChangeOutTimeClass: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string
      ) => void
    ): KnowbeTableCellParam[] => [
      {
        label: (
          <FormikSelect
            name={`initial.${props.unitKey}[${i}].actionClass`}
            label=""
            options={state.detailActionClassList}
            className={props.classes.firstItemCell}
            onChangeHook={onChangeAction}
          />
        ),
        className: classes.firstCell
      },
      {
        label: (
          <FormikSelect
            name={`initial.${props.unitKey}[${i}].inTimeClass`}
            label=""
            options={TIME_CLASS_LIST}
            className={props.classes.timeClassItemCell}
            onChangeHook={onChangeInTimeClass}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].inTime
                ? DETAIL_IN_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].inTime
                  )
                : false
            }
          />
        ),
        className: classes.timeClassCell
      },
      {
        label: (
          <FormikTime
            name={`initial.${props.unitKey}[${i}].inTime`}
            label=""
            placeholder="00:00"
            maxLength={5}
            onChangeHookTime={onChangeInTime}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].inTime
                ? DETAIL_IN_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].inTime
                  )
                : false
            }
          />
        ),
        className: classes.timeCell
      },
      {
        label: (
          <FormikSelect
            name={`initial.${props.unitKey}[${i}].outTimeClass`}
            label=""
            options={TIME_CLASS_LIST}
            className={props.classes.timeClassItemCell}
            onChangeHook={onChangeOutTimeClass}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].outTime
                ? DETAIL_OUT_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].outTime
                  )
                : false
            }
          />
        ),
        className: classes.timeClassCell
      },
      {
        label: (
          <FormikTime
            name={`initial.${props.unitKey}[${i}].outTime`}
            label=""
            placeholder="00:00"
            maxLength={5}
            onChangeHookTime={onChangeOutTime}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].outTime
                ? DETAIL_OUT_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].outTime
                  )
                : false
            }
          />
        ),
        className: classes.timeCell
      },
      {
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickDelete}
            />
          </FormGroup>
        )
      }
    ];

    const bodyValues = units.map((unit: CarePlanDetailsFields, i) => {
      const onClickDelete = (): void => {
        props.onClickDelete(i);
      };
      const onChangeAction = (
        event: React.ChangeEvent<HTMLSelectElement>
      ): void => {
        props.onChangeTime(
          event.target.value,
          i,
          unit.inTime,
          unit.inTimeClass,
          unit.outTime,
          unit.outTimeClass
        );
      };
      const onChangeInTime = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(
          unit.actionClass,
          i,
          event.target.value,
          unit.inTimeClass,
          unit.outTime,
          unit.outTimeClass
        );
      };
      const onChangeInTimeClass = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(
          unit.actionClass,
          i,
          unit.inTime,
          event.target.value,
          unit.outTime,
          unit.outTimeClass
        );
      };
      const onChangeOutTime = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(
          unit.actionClass,
          i,
          unit.inTime,
          unit.inTimeClass,
          event.target.value,
          unit.outTimeClass
        );
      };
      const onChangeOutTimeClass = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(
          unit.actionClass,
          i,
          unit.inTime,
          unit.inTimeClass,
          unit.outTime,
          event.target.value
        );
      };

      return bodyData(
        i,
        onClickDelete,
        onChangeAction,
        onChangeInTime,
        onChangeInTimeClass,
        onChangeOutTime,
        onChangeOutTimeClass
      );
    });

    return (
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead uniqueKey="明細" height={40} items={headData} />
        <KnowbeTableBody uniqueKey="明細情報" itemsContainer={bodyValues} />
      </KnowbeTable>
    );
  }
);
