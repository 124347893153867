export const BROWSER = {
  EDGE: "edge",
  FIREFOX: "firefox",
  OPERA: "opera",
  CHROME: "chrome",
  SAFARI: "safari",
  UNKNOWN: "unknown"
} as const;
export type BROWSER = typeof BROWSER[keyof typeof BROWSER];

const userAgentCheck = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const getBrowserFromUA = (): BROWSER => {
  const ua = userAgentCheck();
  if (ua.indexOf("edge") !== -1 || ua.indexOf("edg") !== -1)
    return BROWSER.EDGE;
  if (ua.indexOf("firefox") !== -1 || ua.indexOf("fxios") !== -1)
    return BROWSER.FIREFOX;
  if (
    ua.indexOf("opr") !== -1 ||
    ua.indexOf("opera") !== -1 ||
    ua.indexOf("opt") !== -1
  )
    return BROWSER.OPERA;
  if (ua.indexOf("chrome") !== -1 || ua.indexOf("crios") !== -1)
    return BROWSER.CHROME;
  if (ua.indexOf("safari") !== -1) return BROWSER.SAFARI;

  return BROWSER.UNKNOWN;
};

export const currentBrowserName = (): string => {
  const currentUA = getBrowserFromUA();
  switch (currentUA) {
    case BROWSER.EDGE:
      return "Microsoft Edge";
    case BROWSER.FIREFOX:
      return "Firefox";
    case BROWSER.OPERA:
      return "Opera";
    case BROWSER.CHROME:
      return "Google Chrome";
    case BROWSER.SAFARI:
      return "Safari";
    default:
      return "unknown";
  }
};
