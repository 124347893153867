import React, { useState } from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { MonitoringMonthState } from "@stores/domain/mgr/KEIKAKUSODAN/monitoringMonth/types";
import { InitialMonitoringMonthValues } from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";

// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

// utils
import { RecordHeader } from "../RecordHeader";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";
import { PlanMonitoringMeetingTable } from "./PlanMonitoringMeetingTable";
import { initialMonitoringMonthValues } from "@initialize/mgr/KEIKAKUSODAN/monitoringMonth/initialValues";
import Paper from "@material-ui/core/Paper";
import spacing from "@material-ui/core/styles/spacing";
import { MonitoringMonthDialog } from "./MonitoringMonthDialog";
import { PlanMonitoringMeetingCreateDialog } from "./PlanMonitoringMeetingCreateDialog";
import { SnackbarParams } from "@stores/ui/type";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

const styles = (): StyleRules =>
  createStyles({
    list: {
      background: "#fff",
      borderRadius: "4px"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`
    },
    createButtonWrapper: {
      display: "flex",
      justifyContent: "flex-end"
    },
    createButton: {
      marginBottom: 8,
      width: "120px"
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  history: H.History;
  typeConsultation?: number;
};

type StateProps = {
  monitoringMonth: MonitoringMonthState["monitoringMonth"];
  facility: FacilityState;
};

type DispatchProps = {
  fetchPlanMonitoringMeeting: (uifId: string) => Promise<void>;
  fetchMonitoringMonth: (uifId: string) => Promise<void>;
  fetchFacility: () => void;
  postMonitoringMonth: (
    uifId: string,
    param: InitialMonitoringMonthValues,
    initialState: InitialMonitoringMonthValues
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => Promise<void>;
};

type MergeProps = StateProps & DispatchProps;

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;

/**
 * 計画・モニタリング・会議記録 一覧
 *
 */
const PlanMonitoringMeetingRecordListCore = (
  props: Props
): JSX.Element | null => {
  const { classes, uifId, monitoringMonth, facility } = props;

  const [
    isOpenMonitoringMonthDialog,
    setIsOpenMonitoringMonthDialog
  ] = useState(false);

  const [isOpenCreateDialog, setIsOpenCreateDialog] = useState(false);

  const initialMonitoringMonth = React.useMemo(
    () => initialMonitoringMonthValues(monitoringMonth || undefined),
    [monitoringMonth]
  );

  React.useEffect(() => {
    props.fetchMonitoringMonth(uifId);
    props.fetchFacility();
  }, []);

  const onClickMonitoringMonth = (): void => {
    setIsOpenMonitoringMonthDialog(true);
  };

  const onClickNew = (): void => {
    setIsOpenCreateDialog(true);
  };

  const monitoringMonthButton = (
    <KnowbeButton kind="outline" onClick={onClickMonitoringMonth}>
      モニタリング実施月の設定
    </KnowbeButton>
  );

  const onClickCancelMonitoringMonth = (): void => {
    setIsOpenMonitoringMonthDialog(false);
  };

  const onSubmitMonitoringMonth = async (
    values: InitialMonitoringMonthValues
  ): Promise<void> => {
    await props.postMonitoringMonth(uifId, values, initialMonitoringMonth);
    await props.fetchMonitoringMonth(uifId);
    await props.fetchPlanMonitoringMeeting(uifId);
  };

  const onClickCancelCreate = (): void => {
    setIsOpenCreateDialog(false);
  };

  return (
    <>
      <div className={classes.list}>
        <RecordHeader
          pageName="計画・モニタリング・会議記録"
          userName={props.userName}
          uifId={uifId}
          recordType="plan_monitoring_meeting"
          isEditing={false}
          history={props.history}
          button={monitoringMonthButton}
        />
        <Paper elevation={0} className={classes.tableContainer}>
          <div className={classes.createButtonWrapper}>
            <KnowbeButton
              kind="default"
              className={classes.createButton}
              onClick={onClickNew}
            >
              新規作成
            </KnowbeButton>
          </div>
          <PlanMonitoringMeetingTable
            record={props.planMonitoringMeeting}
            facility={facility}
            uifId={uifId}
          />
        </Paper>
      </div>
      {isOpenMonitoringMonthDialog && (
        <MonitoringMonthDialog
          isOpen={isOpenMonitoringMonthDialog}
          data={initialMonitoringMonth}
          onCancel={onClickCancelMonitoringMonth}
          onSubmit={onSubmitMonitoringMonth}
          showSnackbar={props.showSnackbar}
        />
      )}
      {isOpenCreateDialog && (
        <PlanMonitoringMeetingCreateDialog
          isOpen={isOpenCreateDialog}
          uifId={uifId}
          onCancel={onClickCancelCreate}
          history={props.history}
          facility={facility}
          typeConsultation={props.typeConsultation}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    monitoringMonth: state.KEIKAKUSODAN.monitoringMonth.monitoringMonth,
    facility: state.KEIKAKUSODAN.facility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN, uiDispatch } = dispatches;
  const planMonitoringMeetingDispatcher = KEIKAKUSODAN.planMonitoringMeetingDispatcher(
    dispatch
  );
  const monitoringMonthDispatcher = KEIKAKUSODAN.monitoringMonthDispatcher(
    dispatch
  );
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchFacility: KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchPlanMonitoringMeeting: (uifId: string): Promise<void> => {
      return planMonitoringMeetingDispatcher.fetchPlanMonitoringMeeting(uifId);
    },
    fetchMonitoringMonth: (uifId: string): Promise<void> => {
      return monitoringMonthDispatcher.fetchMonitoringMonth(uifId);
    },
    postMonitoringMonth: (
      uifId: string,
      param: InitialMonitoringMonthValues,
      initialState: InitialMonitoringMonthValues
    ): Promise<void> => {
      return monitoringMonthDispatcher.postMonitoringMonth(
        uifId,
        param,
        initialState
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const PlanMonitoringMeetingRecordList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PlanMonitoringMeetingRecordListCore));
