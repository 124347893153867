import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { inOutResults } from "@api/requests/inOutResults/";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeDOKOENGODailyReportDataFromAPI,
  normalizeDOKOENGOUserReportDataFromAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToDeleteAPI,
  normalizeRequestParamToPostCopyAPI,
  normalizeRequestParamToPostCopyCarePlanAPI
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/mgr/DOKOENGO/report/initial";
import {
  DOKOENGOReportTypeInterface,
  REPEAT_DAILY,
  DeleteReportState,
  CopyReportState
} from "@stores/domain/mgr/DOKOENGO/report/types";
import { FacilityState } from "@stores/domain/mgr/DOKOENGO/facility/types";
import {
  INOUT_RESULTS_COPY_ERROR_STATUS,
  INOUT_RESULTS_ERROR_DUPLICATED
} from "@constants/mgr/DOKOENGO/variables";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 */
const fetchDOKOENGODaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchDOKOENGODailyStarted());
  await inOutResults
    .getInOutResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchDOKOENGODaily(
          normalizeDOKOENGODailyReportDataFromAPI(
            response.data,
            dateInYYYYMMDDFormat(date)
          )
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchDOKOENGODailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(ユーザごと)のデータ取得及びstore格納
 */
const fetchDOKOENGOUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchDOKOENGOUserStarted());
  await inOutResults
    .getInOutResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchDOKOENGOUser(
          normalizeDOKOENGOUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchDOKOENGOUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postDOKOENGOReport = (dispatch: Dispatch) => async (
  facility: FacilityState,
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: DOKOENGOReportTypeInterface["type"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postDOKOENGOReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(before, after, facility);
  await inOutResults
    .postInOutResults(requestParam)
    .then(() => {
      const actionType =
        type === REPEAT_DAILY
          ? actions.postDOKOENGOReportDaily(requestParam)
          : actions.postDOKOENGOReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postDOKOENGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // 3人同時エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.error !== INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用計画→実績へのコピー
 */
const postDOKOENGOReportCopy = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postDOKOENGOReportCopyStarted());

  const requestParam = normalizeRequestParamToPostCopyAPI(processType, target);

  await inOutResults
    .postInOutResultsCopy(requestParam)
    .then(() => {
      dispatch(actions.postDOKOENGOReportCopy(requestParam));
    })
    .catch((e) => {
      dispatch(actions.postDOKOENGOReportCopyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で実績登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 介護計画→利用計画へのコピー
 */
const postDOKOENGOReportCopyCarePlan = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postDOKOENGOReportCopyCarePlanStarted());

  const requestParam = normalizeRequestParamToPostCopyCarePlanAPI(
    processType,
    target
  );

  await inOutResults
    .postInOutResultsCopyCarePlan(requestParam)
    .then(() => {
      dispatch(actions.postDOKOENGOReportCopyCarePlan(requestParam));
    })
    .catch((e) => {
      dispatch(
        actions.postDOKOENGOReportCopyCarePlanFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で計画登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績削除
 */
const deleteDOKOENGOReport = (dispatch: Dispatch) => async (
  target: DeleteReportState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteDOKOENGOReportStarted());

  const requestParam = normalizeRequestParamToDeleteAPI(target);

  await inOutResults
    .deleteInOutResults(requestParam)
    .then(() => {
      dispatch(actions.deleteDOKOENGOReport(requestParam));
    })
    .catch((e) => {
      dispatch(actions.deleteDOKOENGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchDOKOENGODaily: (date: Date) => Promise<void>;
  fetchDOKOENGOUsers: (uifId: number, date: Date) => Promise<void>;
  postDOKOENGOReport: (
    facility: FacilityState,
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: DOKOENGOReportTypeInterface["type"]
  ) => Promise<void>;
  postDOKOENGOReportCopy: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  postDOKOENGOReportCopyCarePlan: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  deleteDOKOENGOReport: (target: DeleteReportState) => Promise<void>;
};

export const DOKOENGOReportDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchDOKOENGODaily: fetchDOKOENGODaily(dispatch),
  fetchDOKOENGOUsers: fetchDOKOENGOUsers(dispatch),
  postDOKOENGOReport: postDOKOENGOReport(dispatch),
  postDOKOENGOReportCopy: postDOKOENGOReportCopy(dispatch),
  postDOKOENGOReportCopyCarePlan: postDOKOENGOReportCopyCarePlan(dispatch),
  deleteDOKOENGOReport: deleteDOKOENGOReport(dispatch)
});
