import React, { useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Redirect } from "react-router-dom";
import {
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import * as H from "history";

import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { ChangePassword, AuthState } from "@stores/auth/type";
import Button from "@material-ui/core/Button";
import PasswordField from "@components/molecules/PasswordField";
import { AppState } from "@stores/type";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    userType: {
      marginTop: 48,
      marginBottom: 36,
      fontSize: 20,
      lineHeight: 1.71,
      letterSpacing: 0.1,
      color: "#37474f",
      borderBottom: "solid 1px"
    },
    submitButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: spacing.unit * 3
    },
    submitButton: {
      width: 320,
      boxShadow: "none",
      textTransform: "none"
    },
    disableButton: {
      width: 320,
      fontSize: 14,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
      borderRadius: 4
    }
  });

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  postChangePassword: (params: ChangePassword) => void;
  refreshPasswordChangeErrorMessage: (type: "mgradmin" | "mgruser") => void;
};

type OwnProps = {
  history: H.History;
};

type StateProps = {
  auth: AuthState;
};

type AfterPassword = {
  afterPassword: string;
};

type Props = DispatchProps & OwnProps & StateProps & WithStyles<typeof styles>;

const MGR_ADMIN = 0;
const MGR_USER = 1;

const ResetReminderForm = (props: Props): JSX.Element => {
  const [admin, setAdmin] = useState<AfterPassword>({
    afterPassword: ""
  });
  const [user, setUser] = useState<AfterPassword>({
    afterPassword: ""
  });

  const getAdminErrorMessage = (): string => {
    const adminError = props.auth.changePasswordRes.filter((target) => {
      return target.role === "mgradmin";
    });
    return adminError.length ? adminError[0].error.message : "";
  };

  const getUserErrorMessage = (): string => {
    const userError = props.auth.changePasswordRes.filter((target) => {
      return target.role === "mgruser";
    });
    return userError.length ? userError[0].error.message : "";
  };

  // パスワードを変更するユーザーの数によって送信ボタンの活性化条件の変更
  const isActive = (): string | boolean => {
    if (props.auth.wishChangePasswordUsers[MGR_USER]) {
      return admin.afterPassword && user.afterPassword;
    }
    return admin.afterPassword;
  };

  const handleChangeAdminAfterPassword = (value: string): void => {
    if (
      props.auth.changePasswordRes.filter((target) => {
        return target.role === "mgradmin";
      }).length
    ) {
      props.refreshPasswordChangeErrorMessage("mgradmin");
    }
    setAdmin({ afterPassword: value });
  };

  const handleChangeUserAfterPassword = (value: string): void => {
    if (
      props.auth.changePasswordRes.filter((target) => {
        return target.role === "mgruser";
      }).length
    ) {
      props.refreshPasswordChangeErrorMessage("mgruser");
    }
    setUser({ afterPassword: value });
  };

  const handleSubmit = (): void => {
    const param = {
      email: props.auth.wishChangePasswordUsers[MGR_ADMIN].email,
      password: admin.afterPassword,
      mgruser_password: user.afterPassword || undefined,
      remember_token: props.auth.wishChangePasswordUsers[MGR_ADMIN].token
    };
    props.postChangePassword(param);
  };

  useLayoutEffect(() => {
    // 変更するユーザーが存在しない場合はログイン画面にリダイレクト
    if (
      !props.auth.wishChangePasswordUsers ||
      props.auth.wishChangePasswordUsers.length === 0
    ) {
      props.history.replace("/login");
    }
  }, []);

  // 完了時にcompleteに飛ばす（本来はredux側の責務）
  if (props.auth.isLoggedIn) {
    return <Redirect to="/password/resetreminder/complete" />;
  }
  const viewAdminError = getAdminErrorMessage();
  const viewUserError = getUserErrorMessage();

  return (
    <div>
      <div className={props.classes.userType}>
        業務支援用アカウントのパスワード変更
      </div>
      <PasswordField
        passwordName="新しいパスワード"
        onChange={handleChangeAdminAfterPassword}
        errorMessage={viewAdminError}
      />
      {props.auth.wishChangePasswordUsers[MGR_USER] && (
        <>
          <div className={props.classes.userType}>
            利用者様用タイムカードアカウントのパスワード変更
          </div>
          <PasswordField
            passwordName="新しいパスワード"
            onChange={handleChangeUserAfterPassword}
            errorMessage={viewUserError}
          />
        </>
      )}

      <div className={props.classes.submitButtonWrapper}>
        {isActive() ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
            className={props.classes.submitButton}
          >
            パスワードを送信する
          </Button>
        ) : (
          <div className={props.classes.disableButton}>
            パスワードを送信する
          </div>
        )}
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, authDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const authDispatches = authDispatch(dispatch);

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    postChangePassword: (params: ChangePassword): void =>
      authDispatches.postChangePassword(params),
    refreshPasswordChangeErrorMessage: (type): void =>
      authDispatches.refreshPasswordChangeErrorMessage(type)
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetReminderForm));
