import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import * as H from "history";
import { SupportRecordUserState } from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/types";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { RecordUserDetailState } from "@stores/pages/record/userDetail/types";

// ui
import { RecordHeader } from "@components/organisms/mgr/TANKINYUSHO/record/RecordHeader";
import { UserDetailRecordSupportTable } from "@components/organisms/mgr/TANKINYUSHO/record/UserDetailRecordSupportTable";
import Paper from "@material-ui/core/Paper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";

import NoRecord from "@components/organisms/mgr/common/record/NoRecord";

// variables
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/mgr/TANKINYUSHO/variables";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "16px 32px 0px"
    },
    summaryWrap: {
      borderRadius: 4,
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "8px",
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    CreateAndUpdateDateWrap: {
      marginTop: 20,
      marginBottom: 20
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  year: string;
  month: string;
  supportsRecord: SupportRecordUserState;
  staff: StaffState;
  staffOptions: FieldItem[];
  recordUserDetail: RecordUserDetailState;
  history: H.History;
  user: UsersInFacilityState["user"];
  customRecords: CustomRecordsState;
};
type StateProps = {
  customRecords: CustomRecordsState;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

/**
 * 利用者ごとの支援記録の管理を扱う
 */
const UserDetailRecord = (props: Props): JSX.Element => {
  const hasRecord = props.supportsRecord.support.length !== 0;

  const { status_type, meal } = props.supportsRecord.counts;

  const isExistMealItem = meal.choices.some((choice) => choice.count !== 0);
  // 「食事」項目
  const mealData =
    props.customRecords.length > 0 &&
    props.customRecords.find((record) => {
      return (
        record.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
        record.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.meal
      );
    });

  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === 3)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);
    return [...orderArray, ...recorderItem];
  };

  const newCustomRecords =
    props.customRecords.length > 0 && sortCustomRecords(props.customRecords);

  return (
    <>
      <RecordHeader
        pageName={props.pageName}
        userName={props.userName}
        uifId={props.uifId}
        isEditing={props.recordUserDetail.isEditing}
        history={props.history}
        showTitle={hasRecord}
      />
      {hasRecord ? (
        <Paper className={props.classes.root} elevation={0}>
          <div>
            <div className={props.classes.summaryWrap}>
              <div className={props.classes.summaryContent}>
                サービス提供実施：{`${status_type.implement} 日`}
              </div>
              <div
                className={props.classes.summaryContent}
              >{`/ ${status_type.denominator} 日`}</div>
            </div>

            {mealData && (mealData.visibility === 1 || isExistMealItem) ? (
              <div className={props.classes.summaryWrap}>
                {mealData.choices &&
                  mealData.choices.map((item) => {
                    const choiceInputData =
                      meal.choices.length > 0 &&
                      meal.choices.find(
                        (choiceData) => choiceData.id === item.id
                      );
                    if (
                      !choiceInputData ||
                      (mealData.visibility === 0 &&
                        choiceInputData.count === 0) ||
                      (item.hidden === 1 && choiceInputData.count === 0)
                    ) {
                      return null;
                    }
                    return (
                      <div
                        className={props.classes.summaryContent}
                        key={choiceInputData.id}
                      >{`${item.name}：${choiceInputData.count} 回`}</div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className={props.classes.CreateAndUpdateDateWrap}>
            <CreateAndUpdateDate
              createdAt={props.supportsRecord.created_at}
              updatedAt={props.supportsRecord.updated_at}
            />
          </div>
          <UserDetailRecordSupportTable
            supportsRecord={props.supportsRecord}
            staffOptions={props.staffOptions}
            isEditing={props.recordUserDetail.isEditing}
            targetDate={props.recordUserDetail.targetDate}
            customRecords={props.customRecords}
            year={props.year}
            month={props.month}
            newCustomRecords={newCustomRecords || null}
            recordUserDetail={props.recordUserDetail}
            uifId={props.uifId}
            staff={props.staff}
          />
        </Paper>
      ) : (
        <NoRecord message="利用実績がありません。利用実績を入力後、ご利用ください。" />
      )}
    </>
  );
};

export default withStyles(styles)(UserDetailRecord);
