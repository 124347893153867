import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import LineBreak from "@components/atoms/LineBreak";
import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";
import { OptionInterface } from "@components/atoms/DropDown";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { SupportRecordUserState as SupportRecordUserStateGH } from "@stores/domain/mgr/GroupHome/supportRecordUser/types";
import { SupportRecordUserState as SupportRecordUserStateSN } from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateGH } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateSN } from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/types";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";

import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  CUSTOM_RECORD_DEFAULT_CHOICE_UNIT,
  FacilityType
} from "@constants/variables";

// utils
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { dateTodayInFormat } from "@utils/date";

const TABLE_HEAD_HEIGHT = 25;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: 24,
      letterSpacing: 1.2,
      textAlign: "center",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginTop: 16,
      marginBottom: 24
    },
    summaryTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 6
    },
    summary: {
      marginBottom: 16
    },
    summaryContent: {
      fontSize: 10,
      marginRight: 10,
      color: "rgba(0, 0, 0, 0.87)"
    },
    summaryInfo: { fontSize: 0 },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 90
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: "3px 2px"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  facilityUsers: GetFacilityUsersResponse["data"];
};

type OwnProps = {
  displayColumns: string[];
  date: string;
  supportsRecord:
    | DailyOperationsAndSupportsStateGH["support"]
    | DailyOperationsAndSupportsStateSN["support"];
  summary:
    | DailyOperationsAndSupportsStateGH["summary"]
    | DailyOperationsAndSupportsStateSN["summary"];
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  customRecords: CustomRecordsState;
  staffCustomRecordID: number;
  unitName: string;
  statusTypeList: OptionInterface[];
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

type SummaryContentsProps = {
  counts:
    | DailyOperationsAndSupportsStateGH["summary"][0]["counts"]
    | DailyOperationsAndSupportsStateSN["summary"][0]["counts"];
  classes: Record<string, string>;
  title: string;
} & WithStyles<typeof styles>;

const getSupportContents = (
  customRecords: CustomRecordsState,
  displayColumns: string[],
  status_type: number,
  support_record_input:
    | SupportRecordUserStateGH["support"][0]["support_record_input"]
    | SupportRecordUserStateSN["support"][0]["support_record_input"],
  classes: Record<string, string>,
  statusTypeList: OptionInterface[]
): JSX.Element[] => {
  const supportContents: JSX.Element[] = [];

  if (status_type) {
    const statusType = getLabelFromOptions(`${status_type}`, statusTypeList);

    const statusTypeContent = (
      <p key={supportContents.length} style={{ margin: 0 }}>
        {`[サービス提供の状況] ${statusType}`}
      </p>
    );

    supportContents.push(statusTypeContent);
  }

  [...customRecords].forEach((record) => {
    switch (record.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const textContent = support_record_input.find(
          (item) => item.custom_record_item_id === record.id
        );

        const textInputData =
          textContent && textContent.input_data ? textContent.input_data : null;

        // 「職員考察」項目
        if (
          record.default_item === 8 &&
          displayColumns.includes("staff_comment") &&
          textInputData
        ) {
          supportContents.push(
            <p key={supportContents.length} className={classes.contents}>
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }

        if (record.default_item !== 8 && textInputData) {
          supportContents.push(
            <p key={supportContents.length} className={classes.contents}>
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItemsId = support_record_input
          .filter(
            (item) =>
              item.custom_record_item_id === record.id && item.checked === 1
          )
          .map((filteredItem) => filteredItem.choiced_item_id);

        const checkedChoiceItems = record.choices
          .filter((item) => checkedItemsId.includes(item.id))
          .map((filteredItem) => filteredItem.name);

        const checkedName = checkedChoiceItems
          ? checkedChoiceItems.join("、")
          : "";

        if (checkedItemsId.length !== 0) {
          supportContents.push(
            <p
              key={supportContents.length}
              className={classes.contents}
              style={{ color: "#000" }}
            >
              {`[${record.name}] ${checkedName}`}
            </p>
          );
        }
        break;
      }
      // 複数テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
        const inputDataItems = support_record_input.filter(
          (item) => item.custom_record_item_id === record.id && item.input_data
        );

        const multiTextContents: JSX.Element[] = [];

        // 小項目の途中で改行されないようspanタグを使用
        [1, 2, 3, 4, 5].forEach((defaultChoiceNum) => {
          const choiced = record.choices.find(
            (recordItem) => recordItem.default_choice === defaultChoiceNum
          );
          const choicedInputData =
            choiced &&
            inputDataItems.find(
              (inputDataItem) => inputDataItem.choiced_item_id === choiced.id
            );

          if (choiced && choicedInputData) {
            multiTextContents.push(
              <span key={choiced.id} style={{ display: "inline-block" }}>
                {choiced.name} : {choicedInputData.input_data}
                {CUSTOM_RECORD_DEFAULT_CHOICE_UNIT[`${defaultChoiceNum}`]}&emsp;
              </span>
            );
          }
        });

        // 血圧の最低と最高を並び替え
        const sortedMultiTextContents = multiTextContents.slice();
        const index2 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最低）"
        );
        const index3 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最高）"
        );

        if (index2 !== -1 && index3 !== -1) {
          const lowerChoice = sortedMultiTextContents[index2];
          sortedMultiTextContents[index2] = sortedMultiTextContents[index3];
          sortedMultiTextContents[index3] = lowerChoice;
        }

        if (inputDataItems.length !== 0) {
          supportContents.push(
            <p key={supportContents.length} className={classes.contents}>
              {`[${record.name}]`} <span>{sortedMultiTextContents}</span>
            </p>
          );
        }
        break;
      }
      default:
        break;
    }
  });

  return supportContents;
};

const SummaryContents = ({
  counts,
  classes,
  title
}: SummaryContentsProps): JSX.Element => {
  const isMealItem = counts.meal.choices.some((choice) => choice.count !== 0);
  return (
    <div className={classes.summary}>
      {title && <div className={classes.summaryTitle}>{title}</div>}
      <div className={classes.summaryInfo}>
        <span
          className={classes.summaryContent}
        >{`宿泊：${counts.status_type.stay}人`}</span>
        <span
          className={classes.summaryContent}
        >{`入院：${counts.status_type.hospitalization}人`}</span>
        <span
          className={classes.summaryContent}
        >{`外泊：${counts.status_type.stay_away}人`}</span>
        <span className={classes.summaryContent}>
          {`/ ${counts.status_type.denominator}人`}
        </span>
      </div>
      {isMealItem && (
        <div className={classes.summaryInfo}>
          {counts.meal.choices
            .filter((item) => item.count > 0)
            .map((mealItem) => {
              return (
                <span
                  className={classes.summaryContent}
                  key={mealItem.id}
                >{`${mealItem.name}：${mealItem.count}人`}</span>
              );
            })}
        </div>
      )}
    </div>
  );
};

const DailyPrintSupportCore = (props: Props): JSX.Element | null => {
  const {
    classes,
    displayColumns,
    summary,
    supportsRecord,
    tableRowStartEndIndexInSheet,
    customRecords,
    staffCustomRecordID,
    date,
    facilityUsers,
    unitName,
    statusTypeList
  } = props;

  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;
  if (!summary || !summary.length) {
    return null;
  }

  return (
    <div className={classes.page}>
      <div>
        <header>
          <h1 className={classes.title}>
            {displayColumns.includes("support_record")
              ? "支援記録"
              : "サービス提供記録"}
          </h1>
        </header>
        <div>
          <div className={classes.subTitle}>
            <span>{dateTodayInFormat(date, true)}</span>
          </div>
          <SummaryContents
            counts={summary[0].counts}
            classes={classes}
            title={unitName ? "事業所全体" : ""}
          />
          {summary.length > 1 && unitName && (
            <SummaryContents
              counts={summary[1].counts}
              classes={classes}
              title={unitName}
            />
          )}
        </div>
        {supportsRecord.length > 0 && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label msize">利用者</td>
                  <td className="label">支援内容</td>
                  <td className="label ssize">記録者</td>
                  <td className="label sssize">捺印</td>
                </tr>
                {supportsRecord.map(
                  (
                    {
                      user_in_facility_id,
                      support_records_id,
                      status_type,
                      support_record_input
                    },
                    index: number
                  ) => {
                    if (index < startIndex || index > endIndex)
                      return undefined;
                    // 利用者
                    const user = facilityUsers.find(
                      (item) => item.uif_id === user_in_facility_id
                    );
                    const userName = user ? user.displayName : "";
                    // 記録者
                    const staffRecord = support_record_input
                      .filter(
                        (item) =>
                          item.custom_record_item_id === staffCustomRecordID &&
                          item.checked
                      )
                      .map((item) => item.choiced_staff_name_snapshot);

                    const staffName = staffRecord ? staffRecord.join("、") : "";

                    const supportContents = getSupportContents(
                      customRecords,
                      displayColumns,
                      status_type,
                      support_record_input,
                      classes,
                      statusTypeList
                    );

                    if (!supportContents.length && !staffName) {
                      return null;
                    }

                    return (
                      <tr key={support_records_id}>
                        <td className="label topCenterAlign">{userName}</td>
                        <td className="topAlign">{supportContents}</td>
                        <td className="topCenterAlign">{staffName}</td>
                        <td className="label" />
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityUsers =
    state.user.facility_type === FacilityType.GROUP_HOME
      ? state.GroupHome.userInFacility.users
      : state.SHISETSUNYUSHO.userInFacility.users;
  return {
    facilityUsers
  };
};

export const DailyPrintSupport = connect(mapStateToProps)(
  withStyles(styles)(DailyPrintSupportCore)
);
