import { getInOutResultsDaily } from "./getInOutResultsDaily";
import { getInOutResultsMonthly } from "./getInOutResultsMonthly";
import { postInOutResults } from "./postInOutResults";
import { postInOutResultsCopy } from "./postInOutResultsCopy";
import { postInOutResultsCopyCarePlan } from "./postInOutResultsCopyCarePlan";
import { postInOutResultsCopyServiceDelivery } from "./postInOutResultsCopyServiceDelivery";
import { deleteInOutResults } from "./deleteInOutResults";

export const inOutResults = {
  getInOutResultsDaily,
  getInOutResultsMonthly,
  postInOutResults,
  postInOutResultsCopy,
  postInOutResultsCopyCarePlan,
  postInOutResultsCopyServiceDelivery,
  deleteInOutResults
};
