import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { BasicInfoForm } from "@components/organisms/mgr/KEIKAKUSODAN/record/BasicInfoForm";

// utils
import * as URL from "@constants/url";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import { UserState } from "@stores/domain/user/type";
import { getUrlParams } from "@utils/url";
import {
  PARAMETER_TYPE_KEIKAKUSODAN,
  PARAMETER_TYPE_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  supportPlanBasicInfoId: string;
  copyId: string;
}>;

type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  userState: UserState;
};

type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchOne: (uifId: string) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 利用者ごと > 申請者の現状（基本情報）
 */
const BasicInfoCore = (props: Props): JSX.Element => {
  const { uifId, supportPlanBasicInfoId, copyId } = props.match.params;
  const { classes, history } = props;
  const { search } = props.location;

  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "申請者の現状（基本情報）一覧",
      path: `/record/support_plan/${uifId}/basic_info`
    }
  ];

  const [consultationType, setIsConsultationType] = React.useState<number>(1);

  // クエリパラメータ取得
  React.useEffect(() => {
    const queryParameter: {
      type?: string;
    } = getUrlParams(search);
    const consultationTypeQuery = queryParameter.type;
    if (consultationTypeQuery) {
      if (consultationTypeQuery === PARAMETER_TYPE_KEIKAKUSODAN) {
        setIsConsultationType(TYPE_CONSULTATION_KEIKAKUSODAN);
      } else if (consultationTypeQuery === PARAMETER_TYPE_SHOGAIJISODAN) {
        setIsConsultationType(TYPE_CONSULTATION_SHOGAIJISODAN);
      } else {
        history.push(`/record/support_plan/${uifId}/basic_info`);
      }
    }
  }, [search]);

  // 現在ページのタイトル名
  const pageName = "申請者の現状（基本情報）";

  React.useEffect(() => {
    props.fetchFacility();
    props.fetchOne(uifId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <BasicInfoForm
          uifId={uifId}
          supportPlanBasicInfoId={supportPlanBasicInfoId}
          copyId={copyId}
          history={props.history}
          consultationType={consultationType}
          facilityType={props.facility.facilityType}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.KEIKAKUSODAN.facility,
    user: state.KEIKAKUSODAN.userInFacility.user,
    userState: state.user as UserState
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchFacility: dispatches.KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchOne: (uifId: string): void => {
      dispatches.KEIKAKUSODAN.userInFacilityDispatcher(dispatch).fetchOne(
        uifId
      );
    }
  };
};

export const BasicInfo = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicInfoCore));
