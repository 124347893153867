import React from "react";
import { SupportRecordUserState } from "@stores/domain/supportRecordUser/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import RecordSupportTableRow from "@components/organisms/mgr/SHUROTEICHAKU/record/RecordSupportTableRow";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import { RecordUserDetailValues } from "@initialize/mgr/SHUROTEICHAKU/record/userDetail/initialValues";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "16px 32px 32px"
    },
    recordSummary: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginBottom: 32
    },
    usageSituation: {
      display: "flex",
      "& > div": {
        marginRight: 8
      }
    }
  });

type OwnProps = {
  supportsRecord: SupportRecordUserState;
  staffOptions: FieldItem[];
  formikProps: FormikProps<RecordUserDetailValues>;
  isEditing: boolean;
  serviceType?: string;
  initialValues: RecordUserDetailValues;
};
type Props = OwnProps & WithStyles<typeof styles>;

const UserDetailRecordSupportTableCore = (props: Props): JSX.Element | null => {
  if (!props.supportsRecord || props.supportsRecord.support.length === 0) {
    return null;
  }

  return (
    <Paper className={props.classes.root} elevation={0}>
      <div>
        <CreateAndUpdateDate
          createdAt={props.supportsRecord.created_at}
          updatedAt={props.supportsRecord.updated_at}
        />
      </div>
      {props.supportsRecord.support.map((supportRecord, index) => {
        const formikFieldNamePrefix = `support[${index}]`;
        return (
          <RecordSupportTableRow
            key={supportRecord.usage_results.id}
            editType="all"
            displayType="date"
            support={supportRecord}
            staffOptions={props.staffOptions}
            formikProps={props.formikProps}
            formikFieldNamePrefix={formikFieldNamePrefix}
            isEditing={props.isEditing}
            initialValues={props.initialValues.support[index]}
            index={index}
          />
        );
      })}
    </Paper>
  );
};

export const UserDetailRecordSupportTable = withStyles(styles)(
  UserDetailRecordSupportTableCore
);
