import * as React from "react";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import OfficeField from "@components/v202104/organisms/mgr/KEIKAKUSODAN/facility/items/OfficeField";
import { FacilityValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/facility/initialValues";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FUNCTION_ENHANCED_SYSTEM_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import MuiTextField from "@components/molecules/MuiTextField";

const styles = (): StyleRules =>
  createStyles({
    facilityLabel: {
      marginTop: 0,
      fontSize: 14
    },
    functionBlock: {
      display: "flex",
      flexFlow: "column"
    },
    ML16: {
      marginLeft: 16
    }
  });

const functionEnhancedSystemRadioItems = FUNCTION_ENHANCED_SYSTEM_LIST;

type OwnProps = {
  isFetchDone: boolean;
  facility: FacilityState;
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const BasicFieldsCore = (props: Props): JSX.Element => {
  const [shouldFirstSetup, setShouldFirstSetup] = React.useState<boolean>(true);

  const isKeikaku = props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  React.useEffect(() => {
    if (!shouldFirstSetup || !props.isFetchDone) return;

    setShouldFirstSetup(false);
  }, []);

  const getServiceType = (): string => {
    if (props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN) {
      return "計画相談支援";
    }
    if (props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN) {
      return "障害児相談支援";
    }
    if (
      props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
    ) {
      return "計画相談支援/障害児相談支援";
    }
    return "";
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="基本情報" />
      </div>
      <FormikTextField
        name="basic.corporationName"
        label="法人名"
        placeholder="株式会社ノウビー"
        maxLength={255}
        size="superLong"
      />
      <FormGroup row>
        <MuiTextField
          label="サービス種類"
          value={getServiceType()}
          disabled
          disabledStyle
        />
      </FormGroup>
      {(isKeikaku || isKeikakuShogaiji) && (
        <>
          <p className={props.classes.facilityLabel}>計画相談支援</p>
          <div className={props.classes.ML16}>
            <OfficeField type="keikaku" />
            <FormikTextField
              name="facilityInfoKeikaku.representativeName"
              label="代表職員氏名"
              placeholder="山田太郎"
              maxLength={20}
              size="superLong"
            />
            <FormikTextField
              name="facilityInfoKeikaku.tel"
              type="tel"
              label="電話番号"
              maxLength={12}
              placeholder="0000000000"
              helperText="ハイフンなしで入力"
            />
          </div>
        </>
      )}
      {(isShogaiji || isKeikakuShogaiji) && (
        <>
          <p className={props.classes.facilityLabel}>障害児相談支援</p>
          <div className={props.classes.ML16}>
            <OfficeField type="shogaiji" />
            <FormikTextField
              name="facilityInfoShogaiji.representativeName"
              label="代表職員氏名"
              placeholder="山田太郎"
              maxLength={20}
              size="superLong"
            />
            <FormikTextField
              name="facilityInfoShogaiji.tel"
              type="tel"
              label="電話番号"
              maxLength={12}
              placeholder="0000000000"
              helperText="ハイフンなしで入力"
            />
          </div>
        </>
      )}
      {(isKeikaku || isShogaiji) && (
        <FormikCheckbox
          name="basic.integratedManagementHandicappedChildFlg"
          label="指定特定相談支援事業所と指定障害児相談支援事業所を一体的に運営"
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="keikakusodanIntegratedManagementHandicappedChild" />
              }
            />
          }
        />
      )}
      <FormikPostalCode
        name="basic.postalCode"
        label="郵便番号"
        placeholder="000-0000"
        maxLength={8}
        startAdornmentLabel="〒"
      />
      {(isKeikaku || isKeikakuShogaiji) && (
        <FormikAddress
          prefectureIdName="basic.prefectureId"
          cityIdName="basic.cityId"
          formikProps={props.formikProps}
          showRegionType={false}
          showRegionType202104
        />
      )}
      {isShogaiji && (
        <FormikAddress
          prefectureIdName="basic.prefectureId"
          cityIdName="basic.cityId"
          formikProps={props.formikProps}
          showRegionType={false}
          showRegionChildrenDisabilityType202104
        />
      )}
      <FormikTextField
        name="basic.restAddress"
        label="市区町村以降の住所"
        size="superLong"
      />
      <div className={props.classes.functionBlock}>
        {(isKeikaku || isKeikakuShogaiji) && (
          <FormikRadioButtons
            name="facilityInfoKeikaku.functionEnhancedSystem"
            label="計画相談支援機能強化型体制"
            options={functionEnhancedSystemRadioItems}
          />
        )}
        {(isShogaiji || isKeikakuShogaiji) && (
          <FormikRadioButtons
            name="facilityInfoShogaiji.functionEnhancedSystem"
            label="障害児相談支援機能強化型体制"
            options={functionEnhancedSystemRadioItems}
            style={{ marginBottom: 0 }}
          />
        )}
      </div>
    </FormPaper>
  );
};

export const BasicFields = withStyles(styles)(BasicFieldsCore);
