/**
 * 指定日付にエラーアイコンを表示する
 */

import React from "react";
import {
  withDateSelection,
  ReactInfiniteCalendarProps
} from "react-infinite-calendar";
import DefaultCalendar from "./DefaultCalendar";
import withHighlightedDates from "./withHighlightedDates";

type Props = ReactInfiniteCalendarProps & { errorDates: string[] }; // errorDates => ["YYYY-MM-DD", ...]

export const HighlightErrorsCalendar = (props: Props): JSX.Element => {
  return (
    <DefaultCalendar
      Component={withDateSelection(withHighlightedDates(props.errorDates))}
      {...props}
    />
  );
};
