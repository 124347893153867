import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel, { InputLabelProps } from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FieldWrapper, { FieldSizeName } from "@components/atoms/FieldWrapper";
import { FieldItem } from "@interfaces/ui/form";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const styles = (): StyleRules =>
  createStyles({
    root: {
      lineHeight: "20px"
    },
    label: {
      color: "#37474f"
    },
    outlined: {
      "_::-webkit-full-page-media, _:future, :root &": {
        minHeight: "auto",
        height: 19,
        paddingTop: 18
      }
    },
    focus: {
      "&:focus": {
        backgroundColor: "rgba(0, 0, 0, 0)"
      }
    }
  });

const StyleInputLabel = withStyles({
  root: {
    "&$focused": {
      color: "#37474F"
    }
  },
  focused: {}
})(InputLabel);

const StyleOutlinedInput = withStyles({
  root: {
    "&$focused": {
      "& $notchedOutline": {
        borderColor: "#0277bd!important",
        borderWidth: "2px"
      }
    },
    "&$error": {
      "& $notchedOutline": {
        borderColor: "#f44336!important",
        borderWidth: "1px"
      }
    },
    "&$disabled": {
      "& $notchedOutline": {
        borderColor: "rgba(0, 0, 0, 0.23)!important"
      }
    }
  },
  notchedOutline: {
    borderColor: "#546e7a!important",
    "& > legend": {
      transition: "none"
    }
  },
  focused: {},
  error: {},
  disabled: {}
})(OutlinedInput);

type OwnProps = {
  name: string;
  label?: string;
  options?: FieldItem[];
  helperText?: string;
  emptyText?: string;
  shrink?: boolean;
  size?: FieldSizeName;
  FormLabelClasses?: InputLabelProps["FormLabelClasses"];
  isSelectablePlaceholder?: boolean; // true：編集モード時にplaceholderを選択できるようにする
};

export type MuiSelectProps = OwnProps & SelectProps;
type Props = MuiSelectProps & WithStyles<typeof styles>;

const MuiSelectCore = ({
  classes,
  label,
  options,
  helperText,
  emptyText,
  shrink,
  id,
  error,
  fullWidth,
  size,
  style,
  FormLabelClasses,
  placeholder,
  isSelectablePlaceholder,
  ...props
}: Props): JSX.Element => {
  const [labelWidth, setLabelWidth] = React.useState(0);
  const InputLabelRef = React.useRef() as React.MutableRefObject<
    HTMLSpanElement
  >;

  // 枠線の切れてる部分の長さ設定
  React.useEffect(() => {
    if (InputLabelRef.current) {
      setLabelWidth(InputLabelRef.current.offsetWidth);
    }
  }, [InputLabelRef.current]);
  // optionsの値が何もない
  const isItemEmpty = !options || options.length === 0;
  let text = helperText;
  if (isItemEmpty && emptyText) {
    text = emptyText;
  }
  return (
    <FieldWrapper
      size={size}
      style={{ marginBottom: 24, marginRight: 0, ...style }}
    >
      <FormControl
        variant="outlined"
        required={props.required}
        error={error}
        fullWidth={fullWidth}
        disabled={isItemEmpty}
      >
        {label && (
          <StyleInputLabel
            htmlFor={id || props.name}
            shrink={shrink}
            style={{
              visibility: !shrink && !!props.value ? "hidden" : "visible"
            }}
            classes={FormLabelClasses}
          >
            <span ref={InputLabelRef}>{label}</span>
          </StyleInputLabel>
        )}
        <Select
          id={id || props.name}
          displayEmpty={isSelectablePlaceholder || false}
          {...props}
          classes={{ outlined: classes.outlined, select: classes.focus }}
          input={
            <StyleOutlinedInput
              labelWidth={labelWidth}
              name={props.name}
              id={id || props.name}
              notched
            />
          }
          IconComponent={props.disabled ? (): JSX.Element => <></> : undefined}
        >
          {placeholder && isSelectablePlaceholder && (
            <MenuItem value="">{placeholder}</MenuItem>
          )}
          {options &&
            options.map((option) => (
              <MenuItem
                key={`${props.name}-${option.label}-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {text && (
          <FormHelperText classes={{ root: classes.root }}>
            {text}
          </FormHelperText>
        )}
      </FormControl>
    </FieldWrapper>
  );
};

MuiSelectCore.defaultProps = {
  fullWidth: true,
  shrink: true
};

export const MuiSelect = withStyles(styles)(MuiSelectCore);
