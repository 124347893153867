import {
  SupportState,
  OperationState
} from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import { OperationsValues } from "@initialize/mgr/Cseg/record/operations/initialValues";
import { format } from "date-fns";
import { ShotMultipleSelectValue } from "@interfaces/ui/form";

export type SupportRecordFormValues = Array<
  SupportState[number] & {
    displayStaffIds: ShotMultipleSelectValue;
  }
>;

export type OperationRecordFormValues = Omit<
  OperationsValues["operations"][0],
  "target_date"
>;

export const initialSupportsValues = (
  values: SupportState,
  targetDate: string
): SupportRecordFormValues => {
  return values.map((supportRecord) => {
    const displayStaffIds = supportRecord.staffs
      ? supportRecord.staffs.map((v) => {
          return {
            category_id: 0,
            id: v.id,
            label: v.snapshot_name || v.name,
            is_delete: null
          };
        })
      : [];
    return {
      ...supportRecord,
      target_date: format(targetDate, "YYYY-MM-DD"),
      support_content: supportRecord.support_content || "",
      staff_comment: supportRecord.staff_comment || "",
      displayStaffIds
    };
  });
};

export const initialOperationValue = (
  operation: OperationState
): OperationRecordFormValues => {
  return {
    operation_records_id: operation ? operation.operation_records_id : null,
    operation_in_the_morning: operation
      ? operation.operation_in_the_morning || ""
      : "",
    operation_in_the_afternoon: operation
      ? operation.operation_in_the_afternoon || ""
      : "",
    other_comment: operation ? operation.other_comment || "" : "",
    staffs: {
      beforeValues: operation ? operation.staffs : [],
      itemIdList: operation
        ? operation.staffs.map((staff) => {
            return {
              category_id: 0,
              id: staff.id,
              label: staff.snapshot_name,
              is_delete: null
            };
          })
        : []
    }
  };
};
