import * as types from "@stores/domain/mgr/IAB/usersSummary/types";
import * as actions from "@stores/domain/mgr/IAB/usersSummary/actions";

const initialState: types.IABRecordUsersSummaryState = {
  users: [],
  searchConditionsIds: []
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.IABRecordUsersSummaryState => {
  switch (action.type) {
    case types.SET_USERS:
      return { ...state, users: action.payload };
    case types.SET_SEARCH_CONDITIONS:
      return { ...state, searchConditionsIds: action.payload };
    default:
      return state;
  }
};

export default reducer;
