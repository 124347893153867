import * as React from "react";
import classNames from "classnames";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import {
  CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME
} from "@constants/variables";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import {
  getIndexes,
  getName,
  getInput
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";
import { normalizeDisplayCustomRecord } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordNormalize";
import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    wrapper: {
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: "32px",
      marginTop: "16px"
    },
    categoryType: {
      color: "#37474f",
      padding: "0 0 5px 0",
      borderBottom: "1px solid #37474f"
    },
    itemInputs: {
      width: "100%",
      "&.-tel": {
        width: "240px"
      },
      "&.-staff": {
        width: "240px",
        marginTop: "32px"
      }
    },
    noItem: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      margin: "8px 6px 24px 17px"
    }
  });

type OwnProps = {
  categoryType: number;
  formValues: SupportLedger;
  isEditing: boolean;
  customRecords: CustomRecordsWithCategoryState;
  supportLedgerState: SupportLedgerState["supportLedger"];
  staffs: FieldItem[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerFacilityCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const sortedCustomRecords = normalizeDisplayCustomRecord(
    props.customRecords,
    props.formValues,
    props.categoryType
  );

  // 全て非表示になっている場合は枠ごと非表示
  if (
    !sortedCustomRecords.length ||
    !sortedCustomRecords.some((v) => v.custom_record_items.length)
  ) {
    return <></>;
  }

  const renderBody = (
    category: CustomRecordsWithCategoryState[number]
  ): JSX.Element[] => {
    if (!category.custom_record_items.length) {
      return [
        <div className={classes.noItem}>
          このカテゴリー内の項目は全て非表示に設定されています。地域定着支援台帳の設定画面を確認してください。
        </div>
      ];
    }

    return category.custom_record_items.map((item) => {
      const indexes = getIndexes(props.formValues, item);
      const input = getInput(props.formValues, indexes);
      let maxLength = 2500;
      let wrapperClass = "";
      switch (item.default_item) {
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdStaff:
          if (!input.staff) {
            return <React.Fragment key={`item-${item.id}`} />;
          }
          return (
            <div
              key={`item-${item.id}`}
              className={classNames(classes.itemInputs, "-staff")}
            >
              <RecordSelect
                label="台帳作成事業所担当者"
                name={`${getName(indexes, "staff")}.id`}
                value={input.staff.name}
                defaultValue="-"
                placeholder="選択してください"
                options={props.staffs}
                emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                isEditable={props.isEditing}
                isSelectablePlaceholder
              />
            </div>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdTel:
          wrapperClass = "-tel";
          maxLength = 12;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdOffice:
          maxLength = 255;
          break;
        default:
      }
      return (
        <div
          className={classNames(classes.itemInputs, wrapperClass)}
          key={`item-${item.id}`}
        >
          <RecordTextField
            key={`field-${item.id}`}
            name={getName(indexes, "input_data")}
            label={item.name}
            labelType="default"
            defaultValue=""
            placeholder=""
            value={input.input_data}
            isEditable={props.isEditing}
            style={{ marginTop: 32, marginBottom: 0, width: "100%" }}
            maxLength={maxLength}
          />
        </div>
      );
    });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.categoryType}>
        {CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME[props.categoryType]}
      </div>
      {sortedCustomRecords.map((category) => (
        <div key={`category-${category.id}`}>{renderBody(category)}</div>
      ))}
    </div>
  );
};

/**
 * 事業所情報部分を表示するコンポーネント
 * デザインが違うため、表示条件(領域)が SupportLedgerSheetForm と異なるため別で作成
 * 内容は基本的には相違が出ない様にしている。
 */
export const SupportLedgerFacility = withStyles(styles)(
  SupportLedgerFacilityCore
);
