import * as React from "react";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import { CSSProperties } from "react";
import spacing from "@material-ui/core/styles/spacing";

const styles = (): StyleRules =>
  createStyles({
    wrap: {
      display: "flex"
    },
    labelWrap: {
      alignItems: "center"
    },
    tooltip: {
      marginLeft: spacing.unit
    },
    formWrap: {
      display: "flex",
      alignItems: "center"
    },
    highEmphasis: {
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  label: string | JSX.Element;
  tooltip?: React.ReactElement;
  chipsLabel?: React.ReactElement;
  control?: React.ReactElement;
  highEmphasis?: boolean;
  labelStyle?: CSSProperties;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  formControlLabelStyle?: string;
  formControlLabelBoxStyle?: string;
};
type Props = OwnProps & WithStyles<typeof styles>;

const FormLabel = (props: Props): JSX.Element => {
  const Label =
    props.tooltip || props.chipsLabel ? (
      <div
        className={classNames(
          props.classes.wrap,
          props.chipsLabel && props.classes.labelWrap
        )}
      >
        <span>{props.label}</span>
        {!props.control && (
          <span className={props.classes.tooltip}>{props.tooltip}</span>
        )}
        {props.chipsLabel}
      </div>
    ) : (
      <span
        className={props.highEmphasis ? props.classes.highEmphasis : undefined}
        style={props.labelStyle}
      >
        {props.label}
      </span>
    );
  return props.control ? (
    <div className={props.classes.formWrap}>
      <FormControlLabel
        control={props.control}
        label={Label}
        classes={{
          root: props.formControlLabelBoxStyle,
          label: props.formControlLabelStyle
        }}
        labelPlacement={props.labelPlacement}
      />
      {props.tooltip}
    </div>
  ) : (
    <Typography component="label">{Label}</Typography>
  );
};

export default withStyles(styles)(FormLabel);
