import { InitialDataValues } from "@initialize/v202104/mgr/JUDOHOMONKAIGO/carePlan/initialValues";
import validator, { validateSwitcher } from "@validator";
import {
  InitialErrors,
  CarePlanDetailsFields
} from "@interfaces/v202104/mgr/JUDOHOMONKAIGO/Users/initial";
import {
  JUDOHOMONKAIGO_STATUS_LIST,
  JUDOHOMONKAIGO_MEMBER_LIST
} from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { checkTimeRange } from "@validator/rules/checkTimeRange";
import convertHHMMToMinutes from "@utils/date/convertHHMMToMinutes";
import checkTime from "@validator/rules/checkTime";
import { checkTimeFutureStraddlingTheDay } from "@validator/rules/checkTimeFutureStraddlingTheDay";

type InitialDataErrors = InitialErrors;

const submitCarePlanDetailsValidation = (
  data: InitialDataErrors["initial"]["carePlanDetails1"]
): boolean => {
  return (
    data === undefined ||
    data.filter((row) => {
      return row !== undefined;
    }).length === 0
  );
};

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.status === undefined &&
      validationResult.initial.practitioner1 === undefined &&
      validationResult.initial.practitioner1InTime === undefined &&
      validationResult.initial.practitioner1OutTime === undefined &&
      validationResult.initial.practitioner1Memo === undefined &&
      validationResult.initial.practitioner2 === undefined &&
      validationResult.initial.practitioner2InTime === undefined &&
      validationResult.initial.practitioner2OutTime === undefined &&
      validationResult.initial.practitioner2Memo === undefined &&
      validationResult.initial.secondPersonFlgError === undefined &&
      validationResult.initial.accompanySupportInTime === undefined &&
      validationResult.initial.accompanySupportOutTime === undefined &&
      submitCarePlanDetailsValidation(
        validationResult.initial.carePlanDetails1
      ) &&
      submitCarePlanDetailsValidation(validationResult.initial.carePlanDetails2)
    );
  }
  return false;
};

/**
 * 重複チェック
 */
const validateDuplicateTime = (
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string
): string | undefined => {
  if (
    !(
      startTime1 &&
      endTime1 &&
      startTime2 &&
      endTime2 &&
      !checkTime(startTime1) &&
      !checkTime(endTime1) &&
      !checkTime(startTime2) &&
      !checkTime(endTime2)
    )
  ) {
    return undefined;
  }
  // 日跨ぎしている場合、時間に+24する
  const inTimes2 = startTime2.split(":");
  const outTimes1 = endTime1.split(":");
  const outTimes2 = endTime2.split(":");
  const inTime1 = startTime1;
  const inTime2 =
    startTime2Class === "1"
      ? `${Number(inTimes2[0]) + 24}:${inTimes2[1]}`
      : startTime2;
  const outTime1 =
    endTime1Class === "1"
      ? `${Number(outTimes1[0]) + 24}:${outTimes1[1]}`
      : endTime1;
  const outTime2 =
    endTime2Class === "1"
      ? `${Number(outTimes2[0]) + 24}:${outTimes2[1]}`
      : endTime2;

  const rangFlg1 = checkTimeRange(inTime1, "0", outTime1, "0", inTime2, "0");
  const rangFlg2 = checkTimeRange(inTime1, "0", outTime1, "0", outTime2, "0");
  const rangFlg3 = checkTimeRange(inTime2, "0", outTime2, "0", inTime1, "0");
  const equalFlg1 =
    convertHHMMToMinutes(inTime1) === convertHHMMToMinutes(outTime2);
  const equalFlg2 =
    convertHHMMToMinutes(inTime2) === convertHHMMToMinutes(outTime1);

  if ((!!rangFlg1 && !!rangFlg2 && !!rangFlg3) || equalFlg1 || equalFlg2) {
    return "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。";
  }

  return undefined;
};

/**
 * 終了時間の基本チェック
 */
const endTimeValidation = (
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  secondPersonFlg = false,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  let endTimeError;
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  const startTimeClass = secondPersonFlg ? startTime2Class : "0";
  const endTime = secondPersonFlg ? endTime2 : endTime1;
  const endTimeClass = secondPersonFlg ? endTime2Class : endTime1Class;
  endTimeError = validator(endTime, "required", "checkTime");
  if (!endTimeError) {
    endTimeError = checkTimeFutureStraddlingTheDay(
      startTime,
      startTimeClass,
      endTime,
      endTimeClass,
      option
    );
  }

  if (
    !endTimeError &&
    numberOfParticipants === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value &&
    !licenseSameFlg
  ) {
    endTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      endTime1Class,
      startTime2,
      startTime2Class,
      endTime2,
      endTime2Class
    );
  }

  return endTimeError;
};

/**
 * 開始時間の基本チェック
 */
const startTimeValidation = (
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  secondPersonFlg = false
): string | undefined => {
  let startTimeError;
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  startTimeError = validator(startTime, "required", "checkTime");

  if (
    !startTimeError &&
    numberOfParticipants === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value &&
    !licenseSameFlg
  ) {
    startTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      endTime1Class,
      startTime2,
      startTime2Class,
      endTime2,
      endTime2Class
    );
  }

  return startTimeError;
};

const detailTimeValidation = (
  targetTime: string,
  targetTimeClass: string,
  details: CarePlanDetailsFields[],
  idx: number,
  startFlg: boolean
): string | undefined => {
  const result = details
    .filter((row, i) => {
      return i !== idx && row.inTime && row.outTime;
    })
    .map((row) => {
      const flg = startFlg
        ? row.outTime === targetTime
        : row.inTime === targetTime;
      return flg
        ? "範囲内"
        : checkTimeRange(
            row.inTime,
            row.inTimeClass,
            row.outTime,
            row.outTimeClass,
            targetTime,
            targetTimeClass
          );
    })
    .filter((row) => {
      return row === undefined;
    });

  return result && result.length > 0
    ? "移動/空き時間は同一時間帯で重複しないように入力してください"
    : undefined;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      status: validator(
        values.initial.status === JUDOHOMONKAIGO_STATUS_LIST.NONE.value
          ? ""
          : values.initial.status,
        "required"
      )
    }
  };
};

const inoutResultsDetailsValidation = (
  baseInTime: string,
  baseInTimeClass: string,
  baseOutTime: string,
  baseOutTimeClass: string,
  details: CarePlanDetailsFields[]
): InitialErrors["initial"]["carePlanDetails1"] => {
  return details.map((row, idx) => {
    let inTimeError = validator(row.inTime, "required", "checkTime", {
      type: "checkTimeRange",
      startTime: baseInTime,
      startTimeClass: baseInTimeClass,
      endTime: baseOutTime,
      endTimeClass: baseOutTimeClass,
      targetTime: row.inTime,
      targetTimeClass: row.inTimeClass,
      equalityOperatorFlag: row.actionClass === "1",
      option: "移動/空き時間"
    });
    if (!inTimeError) {
      inTimeError = detailTimeValidation(
        row.inTime,
        row.inTimeClass,
        details,
        idx,
        true
      );
    }

    let outTimeError = validator(
      row.outTime,
      "required",
      "checkTime",
      {
        type: "checkTimeFutureStraddlingTheDay",
        startTime: row.inTime,
        startTimeClass: row.inTimeClass,
        endTime: row.outTime,
        endTimeClass: row.outTimeClass
      },
      {
        type: "checkTimeRange",
        startTime: baseInTime,
        startTimeClass: baseInTimeClass,
        endTime: baseOutTime,
        endTimeClass: baseOutTimeClass,
        targetTime: row.outTime,
        targetTimeClass: row.outTimeClass,
        equalityOperatorFlag: row.actionClass === "1",
        option: "移動/空き時間"
      }
    );
    if (!outTimeError) {
      outTimeError = detailTimeValidation(
        row.outTime,
        row.outTimeClass,
        details,
        idx,
        false
      );
    }

    return inTimeError || outTimeError
      ? {
          inTime: inTimeError,
          outTime: outTimeError
        }
      : undefined;
  });
};

const practitioner1Validation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      practitioner1InTime: startTimeValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner1OutTimeClass,
        values.initial.practitioner2InTime,
        values.initial.practitioner2InTimeClass,
        values.initial.practitioner2OutTime,
        values.initial.practitioner2OutTimeClass,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg
      ),
      practitioner1OutTime: endTimeValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner1OutTimeClass,
        values.initial.practitioner2InTime,
        values.initial.practitioner2InTimeClass,
        values.initial.practitioner2OutTime,
        values.initial.practitioner2OutTimeClass,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg
      ),
      carePlanDetails1: inoutResultsDetailsValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1InTimeClass,
        values.initial.practitioner1OutTime,
        values.initial.practitioner1OutTimeClass,
        values.initial.carePlanDetails1
      ),
      practitioner1Memo: validator(values.initial.practitioner1Memo, {
        type: "checkCharacterLength",
        length: 50
      })
    }
  };
};

const practitioner2Validation = (values: InitialDataValues): InitialErrors => {
  if (
    values.initial.numberOfParticipants === JUDOHOMONKAIGO_MEMBER_LIST.ONE.value
  ) {
    return { initial: {} };
  }
  return {
    initial: {
      practitioner2InTime: validateSwitcher(
        !values.initial.licenseSameFlg,
        startTimeValidation(
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner1OutTimeClass,
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          true
        )
      ),
      practitioner2OutTime: validateSwitcher(
        !values.initial.licenseSameFlg,
        endTimeValidation(
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner1OutTimeClass,
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          true
        )
      ),
      carePlanDetails2: validateSwitcher(
        !values.initial.licenseSameFlg,
        inoutResultsDetailsValidation(
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          values.initial.carePlanDetails2
        )
      ),
      practitioner2Memo: validator(values.initial.practitioner2Memo, {
        type: "checkCharacterLength",
        length: 50
      })
    }
  };
};

const accompanySupportValidation = (
  values: InitialDataValues
): InitialErrors => {
  if (
    values.initial.numberOfParticipants ===
      JUDOHOMONKAIGO_MEMBER_LIST.TWO.value ||
    !values.initial.accompanySupportFlg ||
    !values.initial.secondPersonFlg
  ) {
    return { initial: {} };
  }

  return {
    initial: {
      accompanySupportInTime: validator(
        values.initial.accompanySupportInTime,
        "required",
        "checkTime",
        {
          type: "checkTimeRange",
          startTime: values.initial.practitioner1InTime,
          startTimeClass: values.initial.practitioner1InTimeClass,
          endTime: values.initial.practitioner1OutTime,
          endTimeClass: values.initial.practitioner1OutTimeClass,
          targetTime: values.initial.accompanySupportInTime,
          targetTimeClass: values.initial.accompanySupportInTimeClass,
          equalityOperatorFlag: true,
          option: "同行支援開始時間、同行支援終了時間"
        }
      ),
      accompanySupportOutTime: validator(
        values.initial.accompanySupportOutTime,
        "required",
        "checkTime",
        {
          type: "checkTimeFutureStraddlingTheDay",
          startTime: values.initial.accompanySupportInTime,
          startTimeClass: values.initial.accompanySupportInTimeClass,
          endTime: values.initial.accompanySupportOutTime,
          endTimeClass: values.initial.accompanySupportOutTimeClass,
          option: {
            firstLabel: "同行支援終了時間",
            secondLabel: "同行支援開始時間"
          }
        },
        {
          type: "checkTimeRange",
          startTime: values.initial.practitioner1InTime,
          startTimeClass: values.initial.practitioner1InTimeClass,
          endTime: values.initial.practitioner1OutTime,
          endTimeClass: values.initial.practitioner1OutTimeClass,
          targetTime: values.initial.accompanySupportOutTime,
          targetTimeClass: values.initial.accompanySupportOutTimeClass,
          equalityOperatorFlag: true,
          option: "同行支援開始時間、同行支援終了時間"
        }
      )
    }
  };
};

const secondPersonFlgValidation = (
  values: InitialDataValues
): InitialErrors => {
  if (
    values.initial.numberOfParticipants === JUDOHOMONKAIGO_MEMBER_LIST.TWO.value
  ) {
    return { initial: {} };
  }

  let errorFlg;
  if (values.initial.accompanySupportFlg && !values.initial.secondPersonFlg) {
    errorFlg =
      "他事業所とともにサービスを提供するをONにしないと、サービス予定は設定できません";
  }

  return {
    initial: { secondPersonFlgError: errorFlg }
  };
};

export const validation = (values: InitialDataValues): InitialDataErrors => {
  const initialErrors = initialValidation(values);
  const practitioner1Errors = practitioner1Validation(values);
  const practitioner2Errors = practitioner2Validation(values);
  const accompanySupportErrors = accompanySupportValidation(values);
  const secondPersonFlgError = secondPersonFlgValidation(values);

  return {
    initial: {
      ...initialErrors.initial,
      ...practitioner1Errors.initial,
      ...practitioner2Errors.initial,
      ...accompanySupportErrors.initial,
      ...secondPersonFlgError.initial
    }
  };
};
