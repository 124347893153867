import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  basic_info: {
    basic_info_id: number | null;
    creation_date: string;
    author: number | null;
    support_progress: string | null;
    date_birth: string | null;
    age: number | null;
    postal_code: string | null;
    city_id: number | null;
    address: string | null;
    residence: number | null;
    residence_other: string | null;
    tel: string | null;
    fax: string | null;
    disability_name: string | null;
    classify_disability_support: number | null;
    gender: string | null;
    family_structure: string | null;
    social_relationship: string | null;
    life_history: string | null;
    medical_situation: string | null;
    self_complaint: string | null;
    family_complaint: string | null;
    type_consultation?: number | null;
    public_support: {
      basic_info_details_id: number | null;
      name: string | null;
      offer_name: string | null;
      support_contents: string | null;
      frequency: string | null;
      memo: string | null;
    };
    other_support: {
      basic_info_details_id: number | null;
      name: string | null;
      offer_name: string | null;
      support_contents: string | null;
      frequency: string | null;
      memo: string | null;
    };
    schedule: {
      basic_info_schedules_id: number | null;
      day_of_week: number[];
      start_time: string | null;
      next_day_flg: number | null;
      end_time: string | null;
      content: string | null;
      is_delete: number;
    }[];
    main_activity: string | null;
    non_weekly_service: string | null;
  };
};

/**
 * 申請者の現状 (基本情報) のデータ更新
 * @param uifId 事業所所属ユーザーのID
 */
export const postBasicInfo = async (
  uifId: string,
  param: RequestParam
): Promise<AxiosResponse<RequestParam>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/basic_info/`;
  return request.post(url, param);
};
