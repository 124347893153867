import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { ATTENDANCE_BOX_SHADOW } from "@constants/styles";

const styles = (): StyleRules =>
  createStyles({
    button: {
      width: "100%",
      minWidth: 180,
      maxWidth: 295,
      height: 56,
      fontSize: 22,
      boxShadow: ATTENDANCE_BOX_SHADOW
    },
    before: {
      backgroundColor: "#fff",
      color: "#333",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    primary: {
      backgroundColor: "#50aab4",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#50aab4"
      }
    },
    secondary: {
      backgroundColor: "#7167b9",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#7167b9"
      }
    },
    third: {
      backgroundColor: "#d06774",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#d06774"
      }
    },
    after: {
      backgroundColor: "#f5cd00",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#f5cd00"
      }
    },
    label: {
      display: "block",
      textAlign: "center",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden"
    }
  });

type OwnProps = {
  name: string;
  id: number;
  type: "before" | "working" | "after";
  status: number | undefined;
  kana: string;
  handleClick: (id: number, kana: string) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AttendanceButtonCore = (props: Props): JSX.Element => {
  const onClick = (): void => {
    props.handleClick(props.id, props.kana);
  };

  const checkType = (): string => {
    if (props.type !== "working") {
      return props.type;
    }

    switch (props.status) {
      case 2:
        return "primary";
      case 3:
        return "secondary";
      case 4:
        return "third";
      case 8:
        return "third";
      case 9:
        return "secondary";
      default:
        return "primary";
    }
  };

  const type = React.useMemo(() => checkType(), [props.type, props.status]);

  return (
    <Button
      variant="contained"
      onClick={onClick}
      color="secondary"
      className={props.classes.button}
      classes={{
        root: props.classes[type],
        label: props.classes.label
      }}
    >
      {props.name}
    </Button>
  );
};

export const AttendanceButton = withStyles(styles)(AttendanceButtonCore);
