import * as React from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FieldWrapper from "@components/atoms/FieldWrapper";
import FormLabel from "@components/molecules/FormLabel";

type OwnProps = {
  label: string | JSX.Element;
  tooltip?: React.ReactElement;
  checkboxStyle?: React.CSSProperties;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  formControlLabelStyle?: string;
  formControlLabelBoxStyle?: string;
  onChangeHook?: () => void;
};

export type MuiCheckboxProps = OwnProps & CheckboxProps;

const MuiCheckbox = ({
  label,
  tooltip,
  checkboxStyle,
  labelPlacement,
  formControlLabelStyle,
  formControlLabelBoxStyle,
  style,
  onChangeHook,
  ...props
}: MuiCheckboxProps): JSX.Element => (
  <FieldWrapper
    type="checkbox"
    size="auto"
    style={style}
    onChangeHook={onChangeHook}
  >
    <FormLabel
      control={<Checkbox {...props} style={checkboxStyle} />}
      label={label}
      tooltip={tooltip}
      labelPlacement={labelPlacement}
      formControlLabelStyle={formControlLabelStyle}
      formControlLabelBoxStyle={formControlLabelBoxStyle}
    />
  </FieldWrapper>
);

MuiCheckbox.defaultProps = {
  color: "secondary"
};

export default MuiCheckbox;
