import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { normalizePostSupportRecordUserFormToApi } from "./normalizer";
import { SupportRecordFormValues } from "@initialize/mgr/Cseg/record/support/initialValues";
import { csegSupportApi } from "@api/requests/supports/Cseg";

export const fetchSupportRecordUser = (dispatch: Dispatch) => async (
  uifId: string,
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportRecordUserStarted());
  await csegSupportApi
    .getSupportRecordUser(uifId, year, month)
    .then((res) => {
      dispatch(actions.fetchSupportRecordUserSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportRecordUserFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postSupportRecordUser = (dispatch: Dispatch) => async (
  uidId: string,
  editingRecordDate: string,
  formValues: SupportRecordFormValues[],
  initialValues: SupportRecordFormValues[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportRecordUserStarted());

  const normalizeParam = normalizePostSupportRecordUserFormToApi(
    editingRecordDate,
    formValues,
    initialValues
  );
  await csegSupportApi
    .postSupportRecordUser(normalizeParam, uidId)
    .then(() => {
      dispatch(actions.postSupportRecordUserSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postSupportRecordUserFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.data.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchSupportRecordUser: ReturnType<typeof fetchSupportRecordUser>;
  postSupportRecordUser: ReturnType<typeof postSupportRecordUser>;
};

export const CsegSupportRecordUserDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchSupportRecordUser: fetchSupportRecordUser(dispatch),
  postSupportRecordUser: postSupportRecordUser(dispatch)
});
