import React, {
  ChangeEvent,
  Dispatch as reactDispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { UnitsState } from "@stores/domain/units/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { RequestParam } from "@api/requests/units/postUseOperationRecord";
import { UserState } from "@stores/domain/user/type";

// ui
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import MuiSwitch from "@components/molecules/MuiSwitch";
import { CustomRecordsTable } from "@components/organisms/record/setting/CustomRecordsTable";
import { CustomRecordsDialog } from "@components/organisms/record/setting/dialolg/CustomRecordsDialog";
import { SettingFormButtons } from "@components/organisms/record/setting/SettingFormButtons";

// formik
import { Form, Formik, FormikActions } from "formik";
import {
  CustomRecordsTableBodyValues,
  initialValues,
  tableBodyInitialValues
} from "@initialize/record/customRecord/initialValues";

// others
import {
  CUSTOM_RECORD_TARGET_TYPE,
  FacilityType,
  OPERATION_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";
import deepEqual from "fast-deep-equal";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    },
    divider: {
      marginTop: spacing.unit * 4
    },
    unitDivider: {
      marginTop: 22
    },
    settingTopSection: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: spacing.unit * 2
    },
    pureSettingTopSection: {
      marginTop: spacing.unit * 3,
      marginBottom: spacing.unit,
      textAlign: "right"
    },
    ButtonsField: {
      marginTop: spacing.unit
    },
    changeOrderButton: {
      marginLeft: spacing.unit
    },
    commonCustomItemTableWrap: {
      marginBottom: spacing.unit * 6
    }
  });

type OwnProps = {
  customRecords: CustomRecordsState;
  setSortingSettingType: reactDispatch<SetStateAction<number | null>>;
  openHideConfirmModal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openHiddenCustomRecordsList: (type: number) => void;
  sortingSettingType: number | null;
  fetchCustomRecords: () => Promise<void>;
};

type StateProps = {
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  needsStopHistory: boolean;
  userState: UserState;
};

type DispatchProps = {
  postUseCommonOperationRecord: (
    setting: RequestParam["setting"]
  ) => Promise<void>;
  postUseUnitsOperationRecord: (
    setting: RequestParam["setting"]
  ) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsTableBodyValues
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const OperationSettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    optionalCustomInfo,
    customRecords,
    fetchCustomRecords,
    postUseCommonOperationRecord,
    postUseUnitsOperationRecord,
    sortingSettingType,
    setSortingSettingType,
    openHideConfirmModal,
    openHiddenCustomRecordsList,
    postCustomRecordsOrder,
    needsStopHistory,
    stopHistory,
    userState
  } = props;

  // state
  const [formValues, setFormValues] = useState(
    initialValues(userState.facility_type)
  );
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [customRecordModalItemId, setCustomRecordModalItemId] = useState(0);

  // 選択肢削除時に画面を更新するための処理
  useEffect(() => {
    const activeRecord = customRecords.find(
      (record) => record.id === customRecordModalItemId
    );

    setFormValues(initialValues(userState.facility_type, activeRecord));
  }, [customRecords]);

  // 項目の追加
  const openAddCustomRecordModal = (
    e: React.MouseEvent<HTMLInputElement>
  ): void => {
    // setting_typeの設定
    const { value } = e.currentTarget;
    setFormValues(
      initialValues(userState.facility_type, undefined, null, +value)
    );
    openCustomRecordModal(true);
  };

  // 項目の編集
  const openEditCustomRecordModal = (
    params: CustomRecordsTableBodyValues[number]
  ): void => {
    setFormValues(initialValues(userState.facility_type, params));
    setCustomRecordModalItemId(params.id);
    openCustomRecordModal(true);
  };

  // 項目の追加、編集モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    openCustomRecordModal(false);
  };

  // 並べ替え用関数
  const sortRecords = (records: CustomRecordsState): CustomRecordsState => {
    // 全項目をorderの昇順で並べ替え
    const sortedArray = records.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

    // 欠勤・休暇者の項目を1番目に配置する
    const absenteeItemIndex = sortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee
    );
    const absenteeItem =
      absenteeItemIndex !== -1
        ? sortedArray.splice(absenteeItemIndex, 1)
        : sortedArray.splice(0, 1);

    const absenteeSortedArray = [...absenteeItem, ...sortedArray];

    // 出勤者の項目を1番目に配置する（欠勤・休暇者は2番目になる）
    const attendantItemIndex = absenteeSortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant
    );
    const attendantItem =
      attendantItemIndex !== -1
        ? absenteeSortedArray.splice(attendantItemIndex, 1)
        : absenteeSortedArray.splice(0, 1);

    return [...attendantItem, ...absenteeSortedArray];
  };

  // 事業所全体用の記録項目データ
  const OperationCommonCustomRecords = customRecords.length
    ? customRecords.filter(
        (record) =>
          record.setting_type === CUSTOM_RECORD_TARGET_TYPE.operation_common
      )
    : [];

  // 事業所全体用の記録項目データ（表示用並べ替え済み）
  const visibleOperationCommonCustomRecords = OperationCommonCustomRecords.length
    ? sortRecords(
        OperationCommonCustomRecords.filter(
          (record) =>
            record.visibility === 1 && record.setting_page_visibility === 1
        )
      )
    : [];

  // 事業所全体用非表示リスト用データ
  const nonVisibleOperationCommonCustomRecords = OperationCommonCustomRecords.length
    ? OperationCommonCustomRecords.filter((record) => record.visibility === 0)
    : [];

  // state
  const [commonTableValues, setCommonTableValues] = React.useState(
    tableBodyInitialValues()
  );

  React.useEffect(() => {
    setCommonTableValues(
      tableBodyInitialValues(visibleOperationCommonCustomRecords)
    );
  }, [customRecords]);

  // ユニットごと用の記録項目データ
  const OperationUnitsCustomRecords = customRecords.length
    ? customRecords.filter(
        (record) =>
          record.setting_type === CUSTOM_RECORD_TARGET_TYPE.operation_unit
      )
    : [];

  // ユニットごと用の記録項目データ（表示用並べ替え済み）
  const visibleOperationUnitsCustomRecords = OperationUnitsCustomRecords.length
    ? sortRecords(
        OperationUnitsCustomRecords.filter(
          (record) =>
            record.visibility === 1 && record.setting_page_visibility === 1
        )
      )
    : [];

  // ユニットごと用非表示リスト用データ
  const nonVisibleOperationUnitsCustomRecords = OperationUnitsCustomRecords.length
    ? OperationUnitsCustomRecords.filter((record) => record.visibility === 0)
    : [];

  // 「事業所全体の日誌を記録する」Switcher用関数
  const toggleCommonOperationRecordSwitcher = async (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): Promise<void> => {
    const setting = checked ? 1 : 0;
    await postUseCommonOperationRecord(setting);
  };

  // 「ユニットごとに日誌を記録する」Switcher用関数
  const toggleUnitsOperationRecordSwitcher = async (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): Promise<void> => {
    const setting = checked ? 1 : 0;
    await postUseUnitsOperationRecord(setting);
  };

  // 「事業所全体の日誌を記録する」Switcherチェック状況
  const commonSwitcherChecked = Array.isArray(optionalCustomInfo)
    ? false
    : !!optionalCustomInfo.operation_record_flg.use_common_operation_record;

  // 「ユニットごとに日誌を記録する」Switcherチェック状況
  const unitSwitcherChecked = Array.isArray(optionalCustomInfo)
    ? false
    : !!optionalCustomInfo.operation_record_flg.use_units_operation_record;

  // state
  const [unitsTableValues, setUnitsTableValues] = React.useState(
    tableBodyInitialValues()
  );

  React.useEffect(() => {
    setUnitsTableValues(
      tableBodyInitialValues(visibleOperationUnitsCustomRecords)
    );
  }, [customRecords]);

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordsTableBodyValues
  ): void => {
    const tableValues =
      nextValues[0].setting_type === CUSTOM_RECORD_TARGET_TYPE.operation_common
        ? commonTableValues
        : unitsTableValues;

    const hasChange = !deepEqual(nextValues, tableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordsTableBodyValues): void => {
    if (!needsStopHistory) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordsTableBodyValues,
    actions: FormikActions<CustomRecordsTableBodyValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postCustomRecordsOrder(values)
      .then(() => {
        fetchCustomRecords();
        setSortingSettingType(null);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  // 種別によるユニット表記の振り分け
  let unitLabel: string;
  switch (userState.facility_type) {
    case FacilityType.GROUP_HOME:
      unitLabel = "ユニット";
      break;
    case FacilityType.SHISETSUNYUSHO:
      unitLabel = "グループ";
      break;
    default:
      unitLabel = "";
      break;
  }

  // ユニット設定がない場合の表示
  if (Array.isArray(optionalCustomInfo)) {
    return (
      <>
        <div
          className={
            !sortingSettingType ||
            sortingSettingType === CUSTOM_RECORD_TARGET_TYPE.operation_common
              ? classes.editable
              : classes.unEditable
          }
        >
          <FormPaper>
            <SectionTitle label="業務日誌の項目設定" />
            <Formik
              initialValues={commonTableValues}
              validate={validate}
              onSubmit={onSubmit}
            >
              {(formikProps): JSX.Element => (
                <>
                  <div className={classes.pureSettingTopSection}>
                    <SettingFormButtons
                      settingType={CUSTOM_RECORD_TARGET_TYPE.operation_common}
                      sortingSettingType={sortingSettingType}
                      setSortingSettingType={setSortingSettingType}
                      openAddCustomRecordModal={openAddCustomRecordModal}
                      formikProps={formikProps}
                      formValues={commonTableValues}
                      stopHistory={stopHistory}
                    />
                  </div>
                  <CustomRecordsTable
                    hiddenCustomRecords={nonVisibleOperationCommonCustomRecords}
                    openItemEditModal={openEditCustomRecordModal}
                    openHideConfirmModal={openHideConfirmModal}
                    openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                    settingType={CUSTOM_RECORD_TARGET_TYPE.operation_common}
                    isSorting={
                      sortingSettingType ===
                      CUSTOM_RECORD_TARGET_TYPE.operation_common
                    }
                    formikProps={formikProps}
                    formValues={commonTableValues}
                  />
                </>
              )}
            </Formik>
          </FormPaper>
        </div>
        {isOpenCustomRecordModal && (
          <CustomRecordsDialog
            isModalOpen={isOpenCustomRecordModal}
            closeModal={closeCustomRecordModal}
            formValues={formValues}
            fetchCustomRecords={fetchCustomRecords}
          />
        )}
      </>
    );
  }

  return (
    <>
      <FormPaper>
        <SectionTitle label="業務日誌の項目設定" />
        <Typography
          component="p"
          variant="caption"
          className={classes.description}
        >
          事業所単位、{unitLabel}単位それぞれで管理が可能です。
          <br />
          設定がオンの日誌のみが記録画面に表示されます。
        </Typography>
        <Divider className={classes.divider} />
        <div
          className={
            !sortingSettingType ||
            sortingSettingType === CUSTOM_RECORD_TARGET_TYPE.operation_common
              ? classes.editable
              : classes.unEditable
          }
        >
          <Formik
            initialValues={commonTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <Form>
                <div className={classes.settingTopSection}>
                  <MuiSwitch
                    label="事業所全体の日誌を記録する"
                    labelStyle={{ fontSize: "16px" }}
                    style={{ marginBottom: 16 }}
                    onChange={toggleCommonOperationRecordSwitcher}
                    value="common"
                    checked={commonSwitcherChecked}
                    disabled={
                      sortingSettingType ===
                      CUSTOM_RECORD_TARGET_TYPE.operation_common
                    }
                  />
                  {commonSwitcherChecked && (
                    <div className={classes.ButtonsField}>
                      <SettingFormButtons
                        settingType={CUSTOM_RECORD_TARGET_TYPE.operation_common}
                        sortingSettingType={sortingSettingType}
                        setSortingSettingType={setSortingSettingType}
                        openAddCustomRecordModal={openAddCustomRecordModal}
                        formikProps={formikProps}
                        formValues={commonTableValues}
                        stopHistory={stopHistory}
                      />
                    </div>
                  )}
                </div>
                {commonSwitcherChecked && (
                  <div className={classes.commonCustomItemTableWrap}>
                    <CustomRecordsTable
                      hiddenCustomRecords={
                        nonVisibleOperationCommonCustomRecords
                      }
                      openItemEditModal={openEditCustomRecordModal}
                      openHideConfirmModal={openHideConfirmModal}
                      openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                      settingType={CUSTOM_RECORD_TARGET_TYPE.operation_common}
                      isSorting={
                        sortingSettingType ===
                        CUSTOM_RECORD_TARGET_TYPE.operation_common
                      }
                      formikProps={formikProps}
                      formValues={commonTableValues}
                    />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <Divider className={classes.unitDivider} />
        <div
          className={
            !sortingSettingType ||
            sortingSettingType === CUSTOM_RECORD_TARGET_TYPE.operation_unit
              ? classes.editable
              : classes.unEditable
          }
        >
          <Formik
            initialValues={unitsTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                <div className={classes.settingTopSection}>
                  <MuiSwitch
                    label={`${unitLabel}ごとに日誌を記録する`}
                    labelStyle={{ fontSize: "16px" }}
                    style={
                      unitSwitcherChecked
                        ? { marginBottom: 16 }
                        : { marginBottom: -12 }
                    }
                    onChange={toggleUnitsOperationRecordSwitcher}
                    value="units"
                    checked={unitSwitcherChecked}
                    disabled={
                      sortingSettingType ===
                      CUSTOM_RECORD_TARGET_TYPE.operation_unit
                    }
                  />
                  {unitSwitcherChecked && (
                    <div className={classes.ButtonsField}>
                      <SettingFormButtons
                        settingType={CUSTOM_RECORD_TARGET_TYPE.operation_unit}
                        sortingSettingType={sortingSettingType}
                        setSortingSettingType={setSortingSettingType}
                        openAddCustomRecordModal={openAddCustomRecordModal}
                        formikProps={formikProps}
                        formValues={unitsTableValues}
                        stopHistory={stopHistory}
                      />
                    </div>
                  )}
                </div>
                {unitSwitcherChecked && (
                  <CustomRecordsTable
                    hiddenCustomRecords={nonVisibleOperationUnitsCustomRecords}
                    openItemEditModal={openEditCustomRecordModal}
                    openHideConfirmModal={openHideConfirmModal}
                    openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                    settingType={CUSTOM_RECORD_TARGET_TYPE.operation_unit}
                    isSorting={
                      sortingSettingType ===
                      CUSTOM_RECORD_TARGET_TYPE.operation_unit
                    }
                    formikProps={formikProps}
                    formValues={unitsTableValues}
                  />
                )}
              </>
            )}
          </Formik>
        </div>
      </FormPaper>
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  optionalCustomInfo: state.units.optionalCustomInfo,
  needsStopHistory: state.ui.needsStopHistory,
  userState: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { unitsDispatcher, customRecords, uiDispatch } = dispatches;
  const unitsDispatches = unitsDispatcher(dispatch);
  const customRecordsDispatches = customRecords(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    postUseCommonOperationRecord: (setting): Promise<void> => {
      return unitsDispatches.postUseCommonOperationRecord(setting);
    },
    postUseUnitsOperationRecord: (setting): Promise<void> => {
      return unitsDispatches.postUseUnitsOperationRecord(setting);
    },
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordsTableBodyValues
    ): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsOrder(
        visibleCustomRecords
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const OperationSettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(OperationSettingFormCore)
);
