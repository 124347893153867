import { GetFacilityMunicipalitiesResponse } from "@api/requests/facility/getFacilityMunicipalities";
import { GetFacilityMunicipalitiesTargetIdResponse } from "@api/requests/facility/getFacilityMunicipalitiesTargetId";

/**
 * ActionNames
 */
export const FETCH_STARTED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_FAILED";

export const FETCH_ONE_STARTED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/FETCH_ONE_FAILED";

export const POST_MUNICIPALITY_STARTED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/POST_MUNICIPALITY_STARTED";
export const POST_MUNICIPALITY_SUCCESS =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/POST_MUNICIPALITY_SUCCESS";
export const POST_MUNICIPALITY_FAILED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/POST_MUNICIPALITY_FAILED";

export const FETCH_MUNICIPALITY_STARTED =
  "IDOSHIEN/MUNICIPALITY/FETCH_MUNICIPALITY_STARTED";
export const FETCH_MUNICIPALITY_SUCCESS =
  "IDOSHIEN/MUNICIPALITY/FETCH_MUNICIPALITY_SUCCESS";
export const FETCH_MUNICIPALITY_FAILED =
  "IDOSHIEN/MUNICIPALITY/FETCH_MUNICIPALITY_FAILED";

export const FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_STARTED =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_SEARCH_STARTED";
export const FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_SUCCESS =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_SEARCH_SUCCESS";
export const FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_FAILED =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_SEARCH_FAILED";
export const FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_STARTED =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_PAGINATION_STARTED";
export const FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_SUCCESS =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_PAGINATION_SUCCESS";
export const FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_FAILED =
  "IDOSHIEN/MUNICIPALITY/FETCH_SERVICE_CODES_PAGINATION_FAILED";

export const DELETE_MUNICIPALITY_STARTED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/DELETE_MUNICIPALITY_STARTED";
export const DELETE_MUNICIPALITY_SUCCESS =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/DELETE_MUNICIPALITY_SUCCESS";
export const DELETE_MUNICIPALITY_FAILED =
  "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/DELETE_MUNICIPALITY_FAILED";

export const CLEAR = "KODOENGO/USER_IN_FACILITY/CLEAR";

export const CLEAR_ONE = "IDOSHIEN/MUNICIPALITIES_IN_FACILITY/CLEAR_ONE";

export type MunicipalitiesInFacilityState = {
  municipalities: GetFacilityMunicipalitiesResponse["data"]["municipalities"];
  municipality: GetFacilityMunicipalitiesTargetIdResponse["data"]["municipality"];
  serviceCodes: ServiceCodesState;
};

export type ServiceCodesState = {
  count: number;
  page: number;
  serviceCodes: {
    id: number;
    name: string;
    serviceCode: string;
    unitPrice: string;
  }[];
};
