import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { FacilityState } from "@stores/v202104/domain/mgr/GroupHome/facility/types";
import { Report } from "@stores/v202104/domain/mgr/GroupHome/report/type";
import {
  USAGE_PERFORMANCE_STATUS_TYPE_FULL,
  USAGE_PERFORMANCE_HOSPITALIZATION_SUPPORT_TYPE,
  USAGE_PERFORMANCE_GET_HOME_SUPPORT_TYPE,
  USAGE_PERFORMANCE_DAYTIME_SUPPORT_TYPE,
  USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104,
  LIFE_SUPPORT_FLG,
  SPUTUM_GUIDANCE_FLG
} from "@constants/variables";
import {
  USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE_202104,
  NIGHT_SUPPORT_TARGET_USERS,
  NIGHT_SUPPORT_TARGET_USERS_2,
  HOME_CARE_FLG
} from "@constants/mgr/GroupHome/variables";

const styles = (): StyleRules =>
  createStyles({
    veryShortCell: {
      width: 60,
      minWidth: 60,
      boxSizing: "content-box"
    },
    shortCell: {
      width: 64,
      minWidth: 64,
      boxSizing: "content-box"
    },
    semiMiddleCell: {
      width: 72,
      minWidth: 72,
      boxSizing: "content-box"
    },
    middleCell: {
      width: 128,
      minWidth: 128,
      boxSizing: "content-box"
    },
    longCell: {
      width: 226,
      minWidth: 226,
      boxSizing: "content-box"
    },
    nightSupportCell: {
      width: 144,
      minWidth: 144,
      boxSizing: "content-box"
    },
    nightSupportWrap: {
      display: "flex",
      justifyContent: "space-around"
    },
    nightSupportBlock: {
      width: "100%",
      wordBreak: "break-all",
      "&:first-child": {
        marginRight: 16
      }
    },
    lastCell: {
      width: 122,
      minWidth: 122,
      boxSizing: "content-box",
      wordBreak: "break-all"
    }
  });

interface OwnProps {
  params: Report;
  facility: FacilityState;
  idx: number;
  hasNightSupportType: boolean;
}

type Props = OwnProps & WithStyles<typeof styles>;

const UsagePerformanceReportCell = (props: Props): JSX.Element => {
  const { params, idx, hasNightSupportType, classes } = props;
  const shortCellClassName = hasNightSupportType
    ? classes.veryShortCell
    : classes.shortCell;

  // 夜間支援の値に応じて夜間支援対象者数のOptionを変更する
  const nightSupportTargetUsersOptions =
    params.nightSupportType === "3"
      ? NIGHT_SUPPORT_TARGET_USERS_2
      : NIGHT_SUPPORT_TARGET_USERS;

  return (
    <>
      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-status-type`}
        cellClass={hasNightSupportType ? classes.middleCell : classes.longCell}
      >
        {getLabelFromOptions(
          params.statusType,
          USAGE_PERFORMANCE_STATUS_TYPE_FULL
        )}
      </TableCellWrap>

      {/* 夜間支援 / 対象者数 */}
      <TableCellWrap
        key={`${idx}-night-support-type`}
        hidden={!hasNightSupportType}
        cellClass={classes.nightSupportCell}
      >
        <div className={classes.nightSupportWrap}>
          <div className={classes.nightSupportBlock}>
            {getLabelFromOptions(
              params.nightSupportType,
              USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE_202104
            )}
          </div>
          <div className={classes.nightSupportBlock}>
            {getLabelFromOptions(
              params.nightSupportTargetUsers,
              nightSupportTargetUsersOptions
            )}
          </div>
        </div>
      </TableCellWrap>

      {/* 入院時支援 */}
      <TableCellWrap
        key={`${idx}-hospitalization-support-type`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(
          params.hospitalizationSupportType,
          USAGE_PERFORMANCE_HOSPITALIZATION_SUPPORT_TYPE
        )}
      </TableCellWrap>

      {/* 帰宅時支援 */}
      <TableCellWrap
        key={`${idx}-get-home-support-type`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(
          params.getHomeSupportType,
          USAGE_PERFORMANCE_GET_HOME_SUPPORT_TYPE
        )}
      </TableCellWrap>

      {/* 日中支援 */}
      <TableCellWrap
        key={`${idx}-daytime-support-type`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(
          params.daytimeSupportType,
          USAGE_PERFORMANCE_DAYTIME_SUPPORT_TYPE
        )}
      </TableCellWrap>

      {/* 医療連携 */}
      <TableCellWrap
        key={`${idx}-medical-support-type`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(
          params.medicalSupportType,
          USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104
        )}
      </TableCellWrap>

      {/* 喀痰吸引等に係る指導 */}
      <TableCellWrap
        key={`${idx}-sputum-guidance-flg`}
        cellClass={classes.semiMiddleCell}
      >
        {getLabelFromOptions(params.sputumGuidanceFlg, SPUTUM_GUIDANCE_FLG)}
      </TableCellWrap>

      {/* 自立支援 */}
      <TableCellWrap
        key={`${idx}-life-support-flg`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(params.lifeSupportFlg, LIFE_SUPPORT_FLG)}
      </TableCellWrap>

      {/* 居宅介護 */}
      <TableCellWrap
        key={`${idx}-home-care-flg`}
        cellClass={shortCellClassName}
      >
        {getLabelFromOptions(params.homeCareFlg, HOME_CARE_FLG)}
      </TableCellWrap>

      {/* 備考 */}
      <TableCellWrap key={`${idx}-remarks`} cellClass={classes.lastCell}>
        {params.remarks || "-"}
      </TableCellWrap>
    </>
  );
};

export default withStyles(styles)(UsagePerformanceReportCell);
