import * as React from "react";

import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";

import { ErrorsState } from "@stores/domain/errors/types";
import RecordBatchPrinting from "@components/organisms/mgr/common/record/RecordBatchPrinting";
import { RecordEachUser } from "@components/organisms/mgr/common/record/recordEachUser/RecordEachUser";
import { RecordEachUserButtonsType } from "@constants/variables";
import { BASE_PUBLIC_URL } from "@config";

type StateProps = {
  users: UsersInFacilityState["users"];
  errorState: ErrorsState["records"]["data"];
};
type DispatchProps = {
  fetchUsers: () => Promise<void>;
  fetchErrors: () => Promise<void>;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

/**
 * 記録/利用者ごとで表示させるボタンのオブジェクト
 * @param uifId url作成用のufidを返す
 */
const buttonList: React.ComponentProps<typeof RecordEachUser>["buttonList"] = (
  uifId: number
): RecordEachUserButtonsType => {
  return [
    {
      name: "個別支援計画",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support_plan`
    },
    { name: "支援記録", url: `${BASE_PUBLIC_URL}#/record/${uifId}/support` },
    {
      name: "支援レポート",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support_report`,
      isLastButton: true
    }
  ];
};

/**
 * 利用者サマリ
 */
const RecordUsersSummaryCore = (props: Props): JSX.Element => {
  const { fetchUsers, fetchErrors, users, errorState, history } = props;

  // 一括印刷のデフォルト項目上書き指定
  const options = [
    { label: "記録を選択", value: "" },
    { label: "支援記録", value: "supportB" },
    { label: "支援レポート", value: "supportReport" }
  ];

  return (
    <AdminTemplate pageName="利用者ごと">
      {/* 記録の一括印刷 */}
      <RecordBatchPrinting history={history} options={options} />
      {/* 利用ごとの記録 */}
      <RecordEachUser
        users={users}
        errorState={errorState}
        buttonList={buttonList}
        fetchUsers={fetchUsers}
        fetchErrors={fetchErrors}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  users: state.SHUROTEICHAKU.userInFacility.users,
  errorState: state.errors.records.data
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHUROTEICHAKU, errorsDispatcher } = dispatches;
  const userInFacilityDispatcher = SHUROTEICHAKU.userInFacilityDispatcher(
    dispatch
  );
  const errorsDispatches = errorsDispatcher(dispatch);
  return {
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch(),
    fetchErrors: (): Promise<void> => errorsDispatches.records()
  };
};

export const RecordUsersSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordUsersSummaryCore);
