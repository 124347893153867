import { InitialState } from "@stores/domain/mgr/KEIKAKUSODAN/initial/types";
import { dateToSelectDateValue } from "@utils/date";
import { InitialValues } from "@interfaces/mgr/KEIKAKUSODAN/initial/initialData";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

export type InitialDataValues = InitialValues;

export const initialValues = (state?: InitialState): InitialDataValues => {
  const facility = state && state.facility ? state.facility : {};

  return {
    initialData: {
      facility: {
        first_time_bill_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(facility.first_time_bill_date, "")
          )
        ),
        total_number_of_users_1_month_before: undefinedToReturnValue(
          facility.total_number_of_users_1_month_before,
          ""
        ),
        total_number_of_users_2_month_before: undefinedToReturnValue(
          facility.total_number_of_users_2_month_before,
          ""
        ),
        total_number_of_users_3_month_before: undefinedToReturnValue(
          facility.total_number_of_users_3_month_before,
          ""
        ),
        total_number_of_users_4_month_before: undefinedToReturnValue(
          facility.total_number_of_users_4_month_before,
          ""
        ),
        total_number_of_users_5_month_before: undefinedToReturnValue(
          facility.total_number_of_users_5_month_before,
          ""
        ),
        total_number_of_users_6_month_before: undefinedToReturnValue(
          facility.total_number_of_users_6_month_before,
          ""
        ),
        total_number_of_staffs_1_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_1_month_before,
          ""
        ),
        total_number_of_staffs_2_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_2_month_before,
          ""
        ),
        total_number_of_staffs_3_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_3_month_before,
          ""
        ),
        total_number_of_staffs_4_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_4_month_before,
          ""
        ),
        total_number_of_staffs_5_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_5_month_before,
          ""
        ),
        total_number_of_staffs_6_month_before: undefinedToReturnValue(
          facility.total_number_of_staffs_6_month_before,
          ""
        )
      }
    }
  };
};
