import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FormikProps } from "formik";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE } from "@constants/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    changeOrderButton: {
      marginLeft: spacing.unit
    }
  });

type OwnProps = {
  isCategorized?: boolean;
  orderDisable: boolean;
  categoryType: number;
  sortingCategoryType: number | null;
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  openAddModal: (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null
  ) => void;
  formikProps: FormikProps<CustomRecordsWithCategoryState>;
  formValues: CustomRecordsWithCategoryState;
  stopHistory: (flag: boolean) => void;
  editedId: number;
  setSortingItemType: reactDispatch<SetStateAction<number | null>>;
  sortingItemType: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SettingFormButtonsCore = ({
  classes,
  isCategorized,
  orderDisable,
  categoryType,
  sortingCategoryType,
  setSortingCategoryType,
  openAddModal,
  formikProps,
  formValues,
  stopHistory,
  editedId,
  setSortingItemType,
  sortingItemType
}: Props): JSX.Element => {
  // カテゴライズしない項目の時はカテゴリーが1つしかないので配列の先頭を使う
  const customRecordCategoryId =
    !isCategorized && formValues.length > 0 ? formValues[0].id : null;
  // カテゴライズしない項目の時に項目並び替え条件を満たすか(custom_record_itemsが2つ以上存在するか)
  const isSortableItem = customRecordCategoryId
    ? formValues[0].custom_record_items.length <= 1
    : false;

  // 並べ替えモードに移行
  const onClickSortButton = (e: React.MouseEvent<HTMLInputElement>): void => {
    if (isCategorized) {
      const { value } = e.currentTarget;
      setSortingCategoryType(+value);
    } else {
      setSortingItemType(customRecordCategoryId);
    }
  };

  const onClickCancelButton = (): void => {
    formikProps.setValues(formValues);
    setSortingCategoryType(null);
    setSortingItemType(null);
    stopHistory(false);
  };

  const typeLabel = isCategorized ? "カテゴリー" : "項目";
  const type = isCategorized ? "category" : "item";
  const minWidth = isCategorized ? 180 : 120;

  let result: JSX.Element = <></>;

  if (
    categoryType !==
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.office_information
  ) {
    result =
      sortingCategoryType === categoryType ||
      (customRecordCategoryId && customRecordCategoryId === sortingItemType) ? (
        <>
          <KnowbeButton kind="outline" onClick={onClickCancelButton}>
            キャンセル
          </KnowbeButton>
          <FormikSubmitButton
            className={classes.changeOrderButton}
            buttonName="確定する"
            formikProps={formikProps}
          />
        </>
      ) : (
        <>
          <div
            className={
              editedId || !!sortingItemType
                ? classes.unEditable
                : classes.editable
            }
          >
            <KnowbeButton
              value={categoryType}
              onClick={(): void =>
                openAddModal(type, categoryType, customRecordCategoryId)
              }
              minWidth={minWidth}
            >
              {typeLabel}の追加
            </KnowbeButton>
            <KnowbeButton
              className={classes.changeOrderButton}
              value={categoryType}
              onClick={onClickSortButton}
              minWidth={minWidth}
              disabled={
                (isCategorized && orderDisable) ||
                isSortableItem ||
                (formValues.length > 0 &&
                  // 基本情報と障害情報（項目のみ表示）以外でカテゴリー並べ替え不可のもの
                  formValues[0].category_type !== 2 &&
                  formValues[0].category_type !== 3 &&
                  formValues[0].allow_change_order === 0)
              }
            >
              {typeLabel}の並べ替え
            </KnowbeButton>
          </div>
        </>
      );
  }

  return result;
};

export const SettingFormButtons = withStyles(styles)(SettingFormButtonsCore);
