import React, { useEffect } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import Paper from "@material-ui/core/Paper";
import AuthTemplate from "@components/templates/AuthTemplate";
import { AccountMail } from "@components/organisms/account/AccountMail";
import { AccountAdminPassword } from "@components/organisms/account/AccountAdminPassword";
import { AccountTimeCardPassword } from "@components/organisms/account/AccountTimeCardPassword";
import { AccountMobilePassword } from "@components/organisms/account/AccountMobilePassword";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { UserState } from "@stores/domain/user/type";
import { AppState } from "@stores/type";
import {
  USER_ROLE,
  FacilityTypeMobile,
  FacilityTypeNoTimeCard
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    header: {
      margin: 0,
      padding: "24px 32px 8px",
      borderBottom: "1px solid #cfd8dc",
      color: "#37474f",
      fontSize: 24,
      fontWeight: "normal",
      lineHeight: 32 / 24
    },
    footer: {
      padding: "24px 32px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    wrapper: {
      paddingBottom: 32
    },
    section: {
      padding: 32
    },
    title: {
      margin: 0,
      paddingBottom: 6,
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      color: "#37474f",
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 26 / 20
    },
    caution: {
      margin: "8px 0 32px 0",
      color: "#666",
      fontSize: 16,
      lineHeight: 1.75
    }
  });

type StateProps = {
  user: UserState;
};

type DispatchProps = {
  getTargetFacilityUser: () => void;
};

type Props = StateProps &
  DispatchProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

/**
 * アカウント情報の管理
 */
const AccountComponent = (props: Props): JSX.Element => {
  const { getTargetFacilityUser, classes, history, user } = props;

  useEffect(() => {
    getTargetFacilityUser();
  }, []);

  return (
    <AuthTemplate>
      <Paper>
        <h1 className={classes.header}>アカウント情報</h1>
        <div className={classes.wrapper}>
          <section className={classes.section}>
            <h2 className={classes.title}>メールアドレス</h2>
            <AccountMail />
          </section>
          <section className={classes.section}>
            <h2 className={classes.title}>管理画面</h2>
            <AccountAdminPassword />
          </section>
          {!FacilityTypeNoTimeCard.includes(props.user.facility_type) && (
            <section className={classes.section}>
              <h2 className={classes.title}>タイムカード画面</h2>
              {user.role === USER_ROLE.mgrgroupadmin && (
                <p className={classes.caution}>
                  ※現在選択中の事業所の情報を表示しています
                </p>
              )}
              <AccountTimeCardPassword />
            </section>
          )}
          {FacilityTypeMobile.includes(props.user.facility_type) &&
            user.featureGroup.group_operation_support === 1 && (
              <section className={classes.section}>
                <h2 className={classes.title}>モバイル画面</h2>
                {user.role === USER_ROLE.mgrgroupadmin && (
                  <p className={classes.caution}>
                    ※現在選択中の事業所の情報を表示しています
                  </p>
                )}
                <AccountMobilePassword history={props.history} />
              </section>
            )}
        </div>
        <div className={classes.footer}>
          <KnowbeButton kind="outline" onClick={history.goBack} minWidth={160}>
            管理画面に戻る
          </KnowbeButton>
        </div>
      </Paper>
      <NavigationTransitionPrompt />
    </AuthTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { accountDispatch } = dispatches;
  const accountDispatches = accountDispatch(dispatch);
  return {
    getTargetFacilityUser: (): Promise<void> =>
      accountDispatches.getTargetFacilityUser()
  };
};

export const Account = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccountComponent)
);
