import {
  SelectValue,
  SelectDateValue,
  RadioButtonValue,
  FieldItem
} from "@interfaces/ui/form";
import { dateToSelectDateValue, dateToSelectMonthValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  AssistanceReportState,
  ConsultationRecord
} from "@stores/domain/mgr/CHIIKIIKO/assistanceReport/types";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";

export type AssistanceReportValues = {
  id: number | null;
  createdAt: string;
  updatedAt: string;
  guardianName: string; // 保護者氏名
  guardianRelation: string; // 本人との続柄
  dateOfMonitoring: SelectDateValue; // モニタリング実施日
  creationDate: SelectDateValue; // 計画作成日
  author: SelectValue; // 作成者
  classifyDisabilitySupport: RadioButtonValue; // 障害支援区分
  classifyHandicappedFlg: number | null;
  disabledWelfareServiceRecipientNumber: string; // 障害福祉サービス受給者証番号
  regularPlaceRecipientNumber: string; // 通所受給者証番号
  consultationSupportRecipientNumber: string; // 地域相談支援受給者証番号
  attainmentGoal: string; // サービス等利用計画の到達目標
  wholeSituation: string; // 全体の状況
  staffComment: string; // 職員コメント
  details: {
    id: number | null;
    support_goal: string; // 利用者の期待や不安
    criteria_for_cooperation: string; // 協力（支援）の目安
    service_provision_status: string; // サービス提供状況（事業者からの聞き取り）
    personal_satisfaction: string; // 本人の感想・満足度
    achievement_of_expectations: string; // 期待への達成度・不安の解消度
    future_issues: string; // 今後の課題・解決方法
    other_considerations: string; // その他留意事項
    is_delete: number;
  }[];
};

// 地域移行支援報告書詳細初期値
export const initialDetails: AssistanceReportValues["details"][0] = {
  id: null,
  support_goal: "",
  criteria_for_cooperation: "",
  service_provision_status: "",
  personal_satisfaction: "",
  achievement_of_expectations: "",
  future_issues: "",
  other_considerations: "",
  is_delete: 0
};

// 計画の詳細
const detailsValues = (
  value: AssistanceReportState["assistanceReport"]["details"]
): AssistanceReportValues["details"] => {
  return value.map((detail) => ({
    id: detail.id,
    support_goal: detail.support_goal,
    criteria_for_cooperation: detail.criteria_for_cooperation,
    service_provision_status: detail.service_provision_status,
    personal_satisfaction: detail.personal_satisfaction,
    achievement_of_expectations: detail.achievement_of_expectations,
    future_issues: detail.future_issues,
    other_considerations: detail.other_considerations,
    is_delete: 0
  }));
};

// 新規作成用初期値
export const initialValuesNew = (
  user: UsersInFacilityState["user"]
): AssistanceReportValues => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
  const userInFacilityCHIIKIIKO = user ? user.user_in_facility_chiikiiko : null;
  const classifyHandicappedFlg =
    user.user_in_facility.classify_handicapped_flg === "1" ? 1 : 0;
  // 新規作成
  return {
    id: null,
    createdAt: "",
    updatedAt: "",
    guardianName:
      classifyHandicappedFlg === 1
        ? undefinedToReturnValue(user.user_in_facility.guardian_name)
        : "",
    guardianRelation:
      classifyHandicappedFlg === 1
        ? undefinedToReturnValue(user.user_in_facility.guardian_relation)
        : "",
    dateOfMonitoring: dateToSelectDateValue(currentDate),
    creationDate: dateToSelectDateValue(""),
    author: "",
    classifyDisabilitySupport:
      userInFacilityCHIIKIIKO && userInFacilityCHIIKIIKO.disability_class
        ? String(userInFacilityCHIIKIIKO.disability_class)
        : DISABILITY_CLASS_LIST[0].value,
    classifyHandicappedFlg,
    disabledWelfareServiceRecipientNumber:
      user && user.user_in_facility.recipient_number
        ? user.user_in_facility.recipient_number
        : "",
    regularPlaceRecipientNumber: "",
    consultationSupportRecipientNumber: "",
    attainmentGoal: "",
    wholeSituation: "",
    staffComment: "",
    details: [initialDetails]
  };
};

// 編集用初期値
export const initialAssistanceReportValues = (
  values: AssistanceReportState["assistanceReport"],
  user: UsersInFacilityState["user"],
  staffOptions: FieldItem[],
  isNew?: boolean
): AssistanceReportValues => {
  if (!values || isNew) {
    const newValues = initialValuesNew(user);
    return newValues;
  }

  const authorId = getStaffWithSnap(values.author, staffOptions);

  return {
    id: values.id,
    createdAt: undefinedToReturnValue(values.created_at),
    updatedAt: undefinedToReturnValue(values.updated_at),
    guardianName: undefinedToReturnValue(values.guardian_name),
    guardianRelation: undefinedToReturnValue(values.guardian_relation),
    dateOfMonitoring: dateToSelectDateValue(values.date_of_monitoring),
    creationDate: dateToSelectDateValue(
      undefinedToReturnValue(
        values.creation_date === "0000-00-00" ? null : values.creation_date
      )
    ),
    author: authorId || "",
    classifyDisabilitySupport: `${values.classify_disability_support}`,
    classifyHandicappedFlg: values.classify_handicapped_flg,
    disabledWelfareServiceRecipientNumber: undefinedToReturnValue(
      values.disabled_welfare_service_recipient_number
    ),
    regularPlaceRecipientNumber: undefinedToReturnValue(
      values.regular_place_recipient_number
    ),
    consultationSupportRecipientNumber: undefinedToReturnValue(
      values.consultation_support_recipient_number
    ),
    attainmentGoal: undefinedToReturnValue(values.attainment_goal),
    wholeSituation: undefinedToReturnValue(values.whole_situation),
    staffComment: undefinedToReturnValue(values.staff_comment),
    details: detailsValues(values.details)
  };
};

// 読み込み元に値があれば使用する
const createLoadedValue = (
  copy:
    | ConsultationRecord["supportPlanConsultationDetails"][0]
    | null
    | undefined,
  value: AssistanceReportValues["details"][0] | undefined,
  key: string
): string => {
  if (copy) {
    if (key === "criteria_for_cooperation") {
      if (!!copy.start_ym && !!copy.end_ym) {
        const startYm = dateToSelectMonthValue(copy.start_ym);
        const endYm = dateToSelectMonthValue(copy.end_ym);
        return `${startYm.year}年${startYm.month}月〜${endYm.year}年${endYm.month}月`;
      }
      return "";
    }
    return copy[key] || "";
  }
  if (value) {
    return value[key] || "";
  }
  return "";
};

// 地域移行支援報告書詳細読み込み
const loadDetails = (
  values: AssistanceReportValues["details"],
  copyDetailsValues: ConsultationRecord["supportPlanConsultationDetails"]
): AssistanceReportValues["details"] => {
  // 最終的に生成される配列の要素数
  const loadedLength = Math.max(values.length, copyDetailsValues.length);

  // 値が入っている読み込み先の優先順位のみ抽出
  const copyValues = copyDetailsValues.map((copy) => {
    // id以外空値か
    const isEmptyValue = Object.values(copy)
      .slice(1)
      .every((item) => !item);
    return isEmptyValue ? null : copy;
  });

  const arr = Array.from(new Array(loadedLength)).map((_, i) => {
    const value = values[i];
    const copy = copyValues[i];
    return {
      ...value,
      support_goal: createLoadedValue(copy, value, "support_goal"),
      criteria_for_cooperation: createLoadedValue(
        copy,
        value,
        "criteria_for_cooperation"
      ),
      is_delete: 0
    };
  });
  return arr;
};

// 計画の詳細読み込み
export const loadValuesDetails = (
  values: AssistanceReportValues,
  copyValues: ConsultationRecord
): AssistanceReportValues => {
  return {
    ...values,
    attainmentGoal: undefinedToReturnValue(copyValues.attainmentGoal),
    details: loadDetails(
      values.details,
      copyValues.supportPlanConsultationDetails
    )
  };
};

// 計画詳細上書き確認
export const isDetailUpdate = (values: AssistanceReportValues): boolean => {
  let result = false;
  const haveSupportGoal = values.details.every((x) => x.support_goal !== "");
  const haveCriteriaForCooperation = values.details.every(
    (x) => x.criteria_for_cooperation !== ""
  );
  if (
    values.attainmentGoal !== "" ||
    values.wholeSituation !== "" ||
    haveSupportGoal ||
    haveCriteriaForCooperation
  ) {
    result = true;
  }

  const filteredDetailsValues = values.details.filter(
    (value) => !value.is_delete
  );

  // 優先順位が初期値と同じ又は値がないか(idを除き)
  const isPriorityUpdate = !(
    filteredDetailsValues.length === 0 ||
    (filteredDetailsValues.length === 1 &&
      isEqual(
        omit(filteredDetailsValues[0], ["id"]),

        {
          ...omit(initialDetails, ["id"])
        }
      ))
  );
  if (isPriorityUpdate) {
    result = true;
  }

  return result;
};
