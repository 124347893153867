import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.MeetingRecordState = {
  meeting_records_id: 0,
  creation_date: "",
  creation_staff: null,
  meeting_date: "",
  meeting_start_time: "",
  meeting_end_time: "",
  meeting_place: "",
  section: 1,
  attendees: [],
  consideration_item: "",
  consideration_contents: "",
  consideration_results: "",
  others: "",
  type_consultation: 0,
  created_at: "",
  updated_at: ""
};

export const CsegMeetingRecord = (
  state = initialState,
  action: actions.ActionTypes
): types.MeetingRecordState => {
  switch (action.type) {
    case types.FETCH_CSEG_MEETING_RECORD_STARTED:
      return { ...state };
    case types.FETCH_CSEG_MEETING_RECORD_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_CSEG_MEETING_RECORD_FAILED:
      return { ...state };
    case types.POST_CSEG_MEETING_RECORD_STARTED:
      return { ...state };
    case types.POST_CSEG_MEETING_RECORD_SUCCESS:
      return { ...state };
    case types.POST_CSEG_MEETING_RECORD_FAILED:
      return { ...state };
    case types.DELETE_CSEG_MEETING_RECORD_STARTED:
      return { ...state };
    case types.DELETE_CSEG_MEETING_RECORD_SUCCESS:
      return { ...state };
    case types.DELETE_CSEG_MEETING_RECORD_FAILED:
      return { ...state };
    case types.UNSET_CSEG_MEETING_RECORD:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
