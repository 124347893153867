import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { FormikProps } from "formik";
import { FacilityValues as FacilityValuesKYOTAKUKAIGO } from "@initialize/mgr/KYOTAKUKAIGO/facility/initialValues";
import { UnitsValues as UnitsValuesKYOTAKUKAIGO } from "@interfaces/mgr/KYOTAKUKAIGO/facility/units";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 700,
      marginBottom: 18
    },
    officeInfoTableCell: {
      padding: "9px 5px",
      "&:first-child": {
        padding: "9px 5px 9px 16px"
      },
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        padding: "9px 16px 9px 5px"
      },
      "& > div": {
        marginBottom: 1
      }
    },
    officeInfoTextInput: {
      width: 526
    },
    officeInfoCheckbox: {
      width: 68
    },
    center: {
      textAlign: "center"
    },
    officeInfoItem: {
      "& > div": {
        marginLeft: 19,
        width: 20
      }
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValuesKYOTAKUKAIGO>;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const BuildingManagementTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const {
      units
    }: {
      units: UnitsValuesKYOTAKUKAIGO["units"];
    } = props.formikProps.values;

    const headData = [
      {
        label: "建物名",
        className: `${classes.officeInfoTableCell} ${classes.officeInfoTextInput}`
      },
      {
        label: (
          <span>
            事業所と
            <br />
            同一敷地内
          </span>
        ),
        className: `${classes.officeInfoTableCell} ${classes.officeInfoCheckbox}`
      },
      {
        label: "削除",
        className: `${classes.officeInfoTableCell} ${classes.officeInfoCheckbox} ${classes.center}`
      }
    ];

    const bodyData = (i: number): KnowbeTableCellParam[] => [
      {
        label: (
          <FormikTextField
            name={`units[${i}].unit_name`}
            placeholder="建物名"
            disabled={units[i].is_deleted}
            className={classes.officeInfoTextInput}
          />
        ),
        className: `${classes.officeInfoTableCell} ${classes.officeInfoTextInput}`
      },
      {
        label: (
          <FormikCheckbox
            name={`units[${i}].same_building_subtraction_flg`}
            label=""
            disabled={units[i].is_deleted}
          />
        ),
        className: `${classes.officeInfoTableCell} ${classes.officeInfoCheckbox} ${classes.officeInfoItem}`
      },
      {
        label: (
          <FormikCheckbox
            name={`units[${i}].is_deleted`}
            label=""
            disabled={units[i].results_use_flg}
          />
        ),
        className: `${classes.officeInfoTableCell} ${classes.officeInfoCheckbox} ${classes.officeInfoItem}`
      }
    ];

    const bodyValues = (units as UnitsValuesKYOTAKUKAIGO["units"][0][]).map(
      (unit, i) => {
        return bodyData(i);
      }
    );

    return (
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead
          uniqueKey="居住する建物"
          height={40}
          items={headData}
          backgroundColor="secondary"
        />
        <KnowbeTableBody
          uniqueKey="居住する建物情報"
          itemsContainer={bodyValues}
        />
      </KnowbeTable>
    );
  }
);
