import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    scrollPaper: {
      marginTop: 32,
      alignItems: "flex-start"
    },
    paperScrollPaper: {
      margin: "0 16px",
      maxHeight: "calc(100% - 80px)",
      width: "calc(100% - 32px)",
      maxWidth: "calc(100% - 32px)"
    }
  });

type OwnProps = {
  isOpn: boolean;
  isShowTitle?: boolean;
  isShowFooter?: boolean;
  title?: string;
  footerContent?: React.ReactNode;
  contentStyle?: React.CSSProperties;
  onCloseDialog: () => void;
  children: React.ReactNode;
};

type Props = OwnProps & WithStyles<typeof styles>;
const KnowbeDialog = (props: Props): JSX.Element => {
  const {
    classes,
    isOpn,
    isShowTitle = true,
    isShowFooter = true,
    title,
    children,
    footerContent,
    contentStyle,
    onCloseDialog
  } = props;

  return (
    <Dialog
      classes={{
        scrollPaper: classes.scrollPaper,
        paperScrollPaper: classes.paperScrollPaper
      }}
      open={isOpn}
      onClose={onCloseDialog}
      disableBackdropClick
    >
      <DialogContent style={contentStyle}>
        {isShowTitle && <DialogTitle>{title}</DialogTitle>}
        <div>{children}</div>
      </DialogContent>
      {isShowFooter && (
        <DialogActions style={{ margin: 0 }}>{footerContent}</DialogActions>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(KnowbeDialog);
