import { OptionInterface } from "@components/atoms/DropDown";
import { DEFAULT_SELECT_VALUE } from "@utils/dataNormalizer/index";

/**
 * オプションのvalueからlabelを取得する
 */
export function getLabelFromOptions(
  value: string,
  options: OptionInterface[]
): string {
  if (value === "" || value === DEFAULT_SELECT_VALUE) return options[0].label;
  const res = options.find((item: OptionInterface) => item.value === value);
  return res ? res.label : options[0].label;
}
