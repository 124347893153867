import * as React from "react";
import { FieldArray } from "formik";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { InitialState } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/initial/types";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";

const styles = (): StyleRules =>
  createStyles({
    fields: {
      display: "flex",
      alignItems: "center"
    },
    nameField: {
      width: 87,
      height: 24,
      marginRight: 87,
      marginBottom: 20
    }
  });

type OwnProps = {
  isFetchDone: boolean;
  stateInitialDataValues: InitialState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  shouldFirstSetup: boolean;
  isFetchDone: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

class SocialLifeSupportFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldFirstSetup: true,
      isFetchDone: false
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): object | null {
    if (!prevState.shouldFirstSetup || !nextProps.isFetchDone) {
      return null;
    }

    return {
      shouldFirstSetup: false,
      isFetchDone: nextProps.isFetchDone
    };
  }

  private existsUser = (initialState: InitialState): boolean => {
    const SocialLifeSupportUserCount = initialState.users.reduce(
      (prev, next) => {
        const uifJIRITSUKUNRENSEIKATSU = next.users_in_facility_jiritsukunren_seikatsu
          ? next.users_in_facility_jiritsukunren_seikatsu
          : {};
        return uifJIRITSUKUNRENSEIKATSU.social_life_support_flg === 1
          ? prev + 1
          : prev;
      },
      0
    );
    return initialState.users.length > 0 && SocialLifeSupportUserCount > 0;
  };

  public render(): JSX.Element {
    return (
      <FormPaper>
        <div style={{ marginBottom: 18 }}>
          <SectionTitle label="社会生活支援開始日" />
        </div>

        {this.existsUser(this.props.stateInitialDataValues) &&
        this.state.isFetchDone ? (
          <FormGroup>
            <FieldArray name="initialData.users">
              {(): (JSX.Element | null)[] =>
                this.props.stateInitialDataValues.users.map((user, index) => {
                  const uifJIRITSUKUNRENSEIKATSU = user.users_in_facility_jiritsukunren_seikatsu
                    ? user.users_in_facility_jiritsukunren_seikatsu
                    : {};
                  if (uifJIRITSUKUNRENSEIKATSU.social_life_support_flg === 1) {
                    return (
                      <div
                        className={this.props.classes.fields}
                        key={user.id || index}
                      >
                        <div className={this.props.classes.nameField}>
                          {user.name_sei} {user.name_mei}
                        </div>
                        <FormikSelectDateNotSelectedDefault
                          name={`initialData.users[${index}].users_in_facility_jiritsukunren_seikatsu.social_life_support_start_date`}
                          label=""
                          setFormikFieldValue={this.props.setFormikFieldValue}
                        />
                      </div>
                    );
                  }
                  return null;
                })
              }
            </FieldArray>
          </FormGroup>
        ) : (
          <p>
            社会生活支援の対象となる利用者が登録されている場合に入力できます。
          </p>
        )}
      </FormPaper>
    );
  }
}

export default withStyles(styles)(SocialLifeSupportFields);
