import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { SupportPlanState } from "@stores/domain/mgr/SHUROTEICHAKU/supportPlan/types";
import {
  RecordSupportPlanValues,
  supplementSupportPlanGoal,
  supplementSupportPlanProgram
} from "@initialize/mgr/SHUROTEICHAKU/record/supportPlan/initialValues";
import get from "lodash-es/get";
import { dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

/**
 * 個別支援計画新規・コピーフォーム初期値定義
 * supportPlanが渡される状況はコピー状態
 */
const initialValues = (values?: {
  user: UsersInFacilityState["user"];
  supportPlan?: SupportPlanState["privateSupportPlan"];
}): RecordSupportPlanValues => {
  // 計画作成日・実施日
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
  const creationDate = currentDate;
  const minutesDate = currentDate;

  return {
    creation_date: dateToSelectDateValue(creationDate),
    previous_creation_date: dateToSelectDateValue(""),
    support_begin_date: dateToSelectDateValue(""),
    support_end_date: dateToSelectDateValue(""),
    details: "",
    user_request_text: "",
    income_status: "",
    user_issue: "",
    physical_condition: "",
    risk_factor: "",
    after_summary: "",
    current_status: "",
    long_term_goal: "",
    support_plan_goal:
      values && values.supportPlan
        ? supplementSupportPlanGoal(values.supportPlan.support_plan_goal, true)
        : supplementSupportPlanGoal([]),
    support_plan_program: supplementSupportPlanProgram([]),
    pickup: "0",
    remarks: "",
    staff_comment: "",
    author: "",
    revaluation_date: dateToSelectDateValue(""),
    evaluation_status: "0",
    minutes_date: dateToSelectDateValue(minutesDate),
    evaluation_date: dateToSelectDateValue(""),
    participant: [],
    minutes: "",
    evaluation_minutes: "",
    status_type: "0",
    evaluation_minutes_status: "0",
    authorizer: "",
    evaluation_authorizer: "",
    support_plan_shuroteichaku: {
      assessment_date: dateToSelectDateValue(
        get(values, "support_plan_shuroteichaku.assessment_date") || ""
      ),
      company_name: get(values, "user.companies.name") || "",
      company_industry: get(values, "user.companies.industry") || "",
      company_address: get(values, "user.companies.address") || "",
      company_tel: get(values, "user.company_persons.0.tel") || "",
      responsible_person: get(values, "user.company_persons.0.name") || "",
      equipment_environment:
        get(
          values,
          "supportPlan.support_plan_shuroteichaku.equipment_environment"
        ) || "",
      human_environment:
        get(
          values,
          "supportPlan.support_plan_shuroteichaku.human_environment"
        ) || "",
      working_status: `${
        get(values, "supportPlan.support_plan_shuroteichaku.working_status") ||
        0
      }`,
      wage: get(values, "supportPlan.support_plan_shuroteichaku.wage") || "",
      working_time:
        get(values, "supportPlan.support_plan_shuroteichaku.working_time") ||
        "",
      rest_time:
        get(values, "supportPlan.support_plan_shuroteichaku.rest_time") || "",
      holiday:
        get(values, "supportPlan.support_plan_shuroteichaku.holiday") || "",
      business_description:
        get(
          values,
          "supportPlan.support_plan_shuroteichaku.business_description"
        ) || "",
      working_start_date: values
        ? dateToSelectDateValue(
            get(values.user, "companies.working_start_date") || ""
          )
        : dateToSelectDateValue(""),
      takeover:
        get(values, "supportPlan.support_plan_shuroteichaku.takeover") || "",
      disability_class: `${
        get(values, "user.user_in_facility_shuroteichaku.disability_class") || 0
      }`,
      disability_certificate_body: `${
        get(
          values,
          "user.user_in_facility_shuroteichaku.disability_certificate_body"
        ) || 0
      }`,
      disability_certificate_spirit: `${
        get(
          values,
          "user.user_in_facility_shuroteichaku.disability_certificate_spirit"
        ) || 0
      }`,
      disability_certificate_rehabilitation: `${
        get(
          values,
          "user.user_in_facility_shuroteichaku.disability_certificate_rehabilitation"
        ) || 0
      }`,
      disability_certificate_rehabilitation_other:
        get(
          values,
          "user.user_in_facility_shuroteichaku.disability_certificate_rehabilitation_other"
        ) || "",
      classify_incurable_flg: numberToBoolean(
        get(
          values,
          "user.user_in_facility_shuroteichaku.classify_incurable_flg"
        ),
        false
      ),
      support_group:
        get(values, "supportPlan.support_plan_shuroteichaku.support_group") ||
        "",
      physical_condition:
        get(
          values,
          "supportPlan.support_plan_shuroteichaku.physical_condition"
        ) || "",
      support_organization:
        get(
          values,
          "supportPlan.support_plan_shuroteichaku.support_organization"
        ) || ""
    }
  };
};

export default initialValues;
