import * as types from "./types";

// TODO: APIの共通の型として定義したい
type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchOperationsAndSupportsStarted = () =>
  ({ type: types.FETCH_OPERATIONS_AND_SUPPORTS_STARTED } as const);
export const fetchOperationsAndSupportsSuccess = (
  res: types.OperationsAndSupportsState
) =>
  ({
    type: types.FETCH_OPERATIONS_AND_SUPPORTS_SUCCESS,
    payload: res
  } as const);
export const fetchOperationsAndSupportsFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_OPERATIONS_AND_SUPPORTS_FAILED, error: err } as const);
export const postSupportsStarted = () =>
  ({ type: types.POST_SUPPORTS_STARTED } as const);
export const postSupportsSuccess = () =>
  ({ type: types.POST_SUPPORTS_SUCCESS } as const);
export const postSupportsFailed = (err: ErrorResponse) =>
  ({ type: types.POST_SUPPORTS_FAILED, error: err } as const);
export const postOperationStarted = () =>
  ({ type: types.POST_OPERATION_STARTED } as const);
export const postOperationSuccess = () =>
  ({ type: types.POST_OPERATION_SUCCESS } as const);
export const postOperationFailed = (err: ErrorResponse) =>
  ({ type: types.POST_OPERATION_FAILED, error: err } as const);
export const fetchOperationStarted = () =>
  ({ type: types.FETCH_OPERATION_STARTED } as const);
export const fetchOperationSuccess = (res: types.OperationsAndSupportsState) =>
  ({
    type: types.FETCH_OPERATION_SUCCESS,
    payload: res
  } as const);
export const fetchOperationFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_OPERATION_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchOperationsAndSupportsStarted>
  | ReturnType<typeof fetchOperationsAndSupportsSuccess>
  | ReturnType<typeof fetchOperationsAndSupportsFailed>
  | ReturnType<typeof postSupportsStarted>
  | ReturnType<typeof postSupportsSuccess>
  | ReturnType<typeof postSupportsFailed>
  | ReturnType<typeof postOperationStarted>
  | ReturnType<typeof postOperationSuccess>
  | ReturnType<typeof postOperationFailed>
  | ReturnType<typeof fetchOperationStarted>
  | ReturnType<typeof fetchOperationSuccess>
  | ReturnType<typeof fetchOperationFailed>;
