import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  KYOTAKUKAIGO_ACTION_CLASS_LIST,
  PRACTITIONER_SNAPSHOT_OPTION_VALUE,
  MIXUTER_INPUT_TYPE,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import {
  CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE,
  CHECKBOX_AND_TEXT_INPUT_TYPE,
  DOKOENGO_DISPLAY_STATUS_LIST_RECORD
} from "@constants/mgr/DOKOENGO/variables";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  DAY_SELECT_TYPE,
  FacilityType,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  ServiceDeliveryState,
  PractitionerType
} from "@stores/domain/serviceDelivery/types";
import { checkValidDate } from "@utils/date/checkValidDate";
import * as format from "date-fns/format";
import { CheckBoxValue, FieldItem, SelectDateValue } from "@interfaces/ui/form";
import { dateToSelectDateValue } from "@utils/date";
import { JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { KODOENGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/KODOENGO/variables";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { isNull } from "lodash-es";
import { IDOSHIEN_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/IDOSHIEN/variables";

type initialValuesParam = {
  customRecords: CustomRecordsWithCategoryState;
  targetDate: string;
  staffOptions: FieldItem[];
  facilityType: FacilityType;
  userId?: number;
  state?: ServiceDeliveryState["detailsRecord"];
};

type ServiceDeliveryRecordPractitionersValues = {
  serviceDeliveryRecordPractitionersId: number | null;
  startTimeDate: string;
  startTime: string;
  endTimeDate: string;
  endTime: string;
  numberOfTime: string;
  numberOfRides: string;
  numberOfServiceHours: string;
  numberOfServiceMinutes: string;
  calculatedMovingHours: string;
  accompanySupportInTimeDate: string;
  accompanySupportInTime: string;
  accompanySupportOutTimeDate: string;
  accompanySupportOutTime: string;
  calculatedAccompanySupportHours: string;
  calculatedAccompanySupportMovingHours: string;
  practitioner: string | null;
  practitioner2: string | null; // 移動支援のみ
  practitioner3: string | null; // 移動支援のみ
  practitioner4: string | null; // 移動支援のみ
  practitioner5: string | null; // 移動支援のみ
  practitionerSnapshot: PractitionerType | null;
  practitionerSnapshot2: PractitionerType | null;
  practitionerSnapshot3: PractitionerType | null;
  practitionerSnapshot4: PractitionerType | null;
  practitionerSnapshot5: PractitionerType | null;
  isSnapshot: boolean;
  isSnapshot2: boolean; // 移動支援のみ
  isSnapshot3: boolean; // 移動支援のみ
  isSnapshot4: boolean; // 移動支援のみ
  isSnapshot5: boolean; // 移動支援のみ
  practitionerName: string | null; // 行動のみ
  practitionerName2: string | null; // 移動支援のみ
  practitionerName3: string | null; // 移動支援のみ
  practitionerName4: string | null; // 移動支援のみ
  practitionerName5: string | null; // 移動支援のみ
  practitionerNum: number;
  displayFlg: boolean;
  serviceDeliveryRecordPractitionerDetails: DetailsInitialValues;
  custom_record: customRecordValues;
  // 同行のみ
  serviceDeliveryRecordPractitionerDestinations: DestinationValues;
  // 同行のみ
  serviceDeliveryRecordPractitionerMoneyManagements: MoneyManagementsValues;
};

export type DestinationValues = {
  service_delivery_record_practitioner_destinations_id: number | null;
  when_time: string;
  place: string;
  support: string;
  isDelete: number;
}[];

export type MoneyManagementsValues = {
  traveling_expenses: ExpensesValues;
  other_expenses: ExpensesValues;
};

export type ExpensesValues = {
  service_delivery_record_practitioner_money_managements_id: number | null;
  transportation: number;
  free_text: string;
  amount_money: string;
  isDelete: number;
}[];

export type ServiceDeliveryDetailValues = {
  serviceDeliveryRecordsId: number | null;
  inoutResultsId: number | null;
  supportProcedureFormsId: number | null; // 行動のみ設定
  createdAt: number | null;
  updatedAt: number | null;
  usersInFacilityId: number;
  targetDate: SelectDateValue;
  status: string;
  statusText: string | null; // 移動支援のみ
  emergencySupportFlg: CheckBoxValue;
  sputumImplementationFlg: CheckBoxValue;
  emergencyGuideSupportFlg: CheckBoxValue; // 重度のみ設定
  bodyRestrictedStillFlg: CheckBoxValue;
  accompanySupportFlg: CheckBoxValue; // 重度のみ設定
  accompanySupportDisplayFlg: boolean; // 重度のみ設定
  numberOfPractitioner: string;
  numberOfSupporters: string; // 移動支援のみ設定
  supportRatioPerPractitioner: string | null; // 移動支援のみ設定
  physicalCareFlg: CheckBoxValue | null; // 移動支援のみ設定
  licenseSameFlg: CheckBoxValue | null; // 移動支援のみ設定
  serviceDeliveryRecordPractitioners1: ServiceDeliveryRecordPractitionersValues;
  serviceDeliveryRecordPractitioners2: ServiceDeliveryRecordPractitionersValues;
  municipality: {
    calculationTimeFlg: boolean;
    roundUpMinute: number | null;
  } | null;
};

export type DetailsInitialValues = {
  serviceDeliveryRecordPractitionerDetailsId: number | null;
  actionClass: number;
  startTimeDate: string;
  startTime: string;
  endTimeDate: string;
  endTime: string;
  isDelete: number;
}[];

export type customRecordValues = {
  input_type_text: {
    [custom_record_id: number]: {
      custom_records_category_id: number;
      custom_record_input_id: number | null;
      custom_record_item_id: number;
      input_data: string;
      choiced_item_id: number;
      default_item: number;
      category_type: number;
    };
  };
  input_type_checkbox: {
    [custom_record_id: number]: {
      [custom_record_item_choices_id: number]: {
        custom_records_category_id: number;
        custom_record_input_id: number | null;
        custom_record_item_id: number;
        choiced_item_id: number;
        checked: CheckBoxValue;
        category_type: number;
      };
    };
  };
  input_type_multi_text: {
    [custom_record_id: number]: {
      [custom_record_item_choices_id: number]: {
        custom_records_category_id: number;
        custom_record_input_id: number | null;
        custom_record_item_id: number;
        choiced_item_id: number;
        input_data: string;
        default_item: number;
        default_choice: number;
        category_type: number;
      };
    };
  };
  input_type_mixture: {
    [custom_record_id: number]: {
      [custom_record_item_choices_id: number]: {
        custom_record_input_id: number | null;
        custom_record_item_id: number;
        choiced_item_id: number;
        timeDate: string;
        input_data: string;
        checked: CheckBoxValue;
        default_item: number;
        default_choice: number;
        default_choice_input_type: number;
        category_type: number;
      };
    };
  };
  input_type_checkbox_and_text: {
    [custom_record_id: number]: {
      [custom_record_item_choices_id: number]: {
        custom_records_category_id: number;
        custom_record_input_id: number | null;
        custom_record_item_id: number;
        choiced_item_id: number;
        input_data: string;
        checked: CheckBoxValue;
        default_item: number;
        default_choice: number;
        default_choice_input_type: number;
        category_type: number;
      };
    };
  };
};

// 当日・翌日判定
const createDateValues = (
  baseTime: string | null,
  targetDate: string
): string => {
  if (!baseTime) {
    return DAY_SELECT_TYPE.today;
  }
  if (targetDate !== format(baseTime, "YYYY-MM-DD")) {
    return DAY_SELECT_TYPE.nextDay;
  }
  return DAY_SELECT_TYPE.today;
};

// 初期値が整数のものはカンマ少数第一位まで表示する
const fillWith0FirstDecimal = (value: string | null): string => {
  const valueState = value || "";
  return Number(valueState) && !valueState.includes(".")
    ? `${valueState}.0`
    : valueState;
};

// 移動支援の車両移送の場合は00:00:00になっているので数字に変換する
const convertTimeToNumber = (value: string | null): string => {
  return value === "00:00:00" || isNull(value)
    ? ""
    : `${Number(value.split(":")[0])}.${Number(value.split(":")[1]) / 6}`;
};

export const practitionerDetailsInitialValue: DetailsInitialValues[0] = {
  serviceDeliveryRecordPractitionerDetailsId: null,
  actionClass: Number(KYOTAKUKAIGO_ACTION_CLASS_LIST.DRIVE.value),
  startTimeDate: DAY_SELECT_TYPE.today,
  startTime: "",
  endTimeDate: DAY_SELECT_TYPE.today,
  endTime: "",
  isDelete: 0
};

export const practitionerDestinationInitialValue: DestinationValues[0] = {
  service_delivery_record_practitioner_destinations_id: null,
  when_time: "",
  place: "",
  support: "",
  isDelete: 0
};

export const practitionerExpensesInitialValue: ExpensesValues[0] = {
  service_delivery_record_practitioner_money_managements_id: null,
  transportation: 0,
  free_text: "",
  amount_money: "",
  isDelete: 0
};

// カスタムレコード
const createCustomRecordsValues = (
  customRecords: CustomRecordsWithCategoryState,
  targetDateValue: string,
  supportRecord?: ServiceDeliveryState["detailsRecord"]["serviceDeliveryRecordPractitioners"][0]["serviceDeliveryRecords"]
): customRecordValues => {
  const obj1 = {};
  const obj2 = {};
  const obj3 = {};
  const obj4 = {};
  const obj5 = {};
  if (customRecords.length > 0) {
    customRecords.forEach((categoryRecord) => {
      categoryRecord.custom_record_items.forEach((record) => {
        const supportRecordInputCategory = supportRecord
          ? supportRecord.find(
              (item) => item.customRecords_category_id === categoryRecord.id
            )
          : null;

        const supportRecordInput = supportRecordInputCategory
          ? supportRecordInputCategory.input.filter(
              (item) => item.custom_record_item_id === record.id
            )
          : null;
        switch (record.input_type) {
          // テキスト形式
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text:
            Object.assign(obj1, {
              [record.id]: {
                custom_records_category_id: record.custom_records_category_id,
                custom_record_input_id:
                  supportRecordInput && supportRecordInput.length > 0
                    ? supportRecordInput[0].id
                    : null,
                custom_record_item_id: record.id,
                input_data:
                  supportRecordInput && supportRecordInput.length > 0
                    ? supportRecordInput[0].input_data
                    : "",
                default_item: record.default_item,
                category_type: categoryRecord.category_type
              }
            });
            break;
          // チェックボックス形式
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
            const checkBoxObj = {};
            if (record.custom_record_item_choices) {
              record.custom_record_item_choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;
                Object.assign(checkBoxObj, {
                  [choice.id]: {
                    custom_records_category_id:
                      record.custom_records_category_id,
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    checked: supportRecordInputChoiced
                      ? numberToBoolean(supportRecordInputChoiced.checked)
                      : false,
                    category_type: categoryRecord.category_type
                  }
                });
              });
            }
            Object.assign(obj2, { [record.id]: { ...checkBoxObj } });
            break;
          }
          // 複数テキスト形式
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text:
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.money: {
            const multiTextObj = {};
            if (record.custom_record_item_choices) {
              record.custom_record_item_choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;
                Object.assign(multiTextObj, {
                  [choice.id]: {
                    custom_records_category_id:
                      record.custom_records_category_id,
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    input_data: supportRecordInputChoiced
                      ? supportRecordInputChoiced.input_data
                      : "",
                    default_item: record.default_item,
                    default_choice: choice.default_choice,
                    category_type: categoryRecord.category_type
                  }
                });
              });
            }
            Object.assign(obj3, { [record.id]: { ...multiTextObj } });
            break;
          }
          // 選択式（入力項目）
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.mixture: {
            const multiTextObj = {} as customRecordValues["input_type_mixture"][];
            if (record.custom_record_item_choices) {
              record.custom_record_item_choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;

                let inputData = "";
                let timeDateValue = DAY_SELECT_TYPE.today;

                if (
                  supportRecordInputChoiced &&
                  supportRecordInputChoiced.input_data
                ) {
                  if (
                    choice.default_choice ===
                    MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
                  ) {
                    inputData = supportRecordInputChoiced.input_data;
                  } else {
                    inputData = checkValidDate(
                      supportRecordInputChoiced.input_data
                    )
                      ? format(supportRecordInputChoiced.input_data, "HH:mm")
                      : "";
                  }
                  timeDateValue = createDateValues(
                    supportRecordInputChoiced.input_data,
                    targetDateValue
                  );
                }

                Object.assign(multiTextObj, {
                  [choice.id]: {
                    custom_records_category_id:
                      record.custom_records_category_id,
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    timeDate:
                      choice.default_choice_input_type ===
                        MIXUTER_INPUT_TYPE.text &&
                      choice.default_choice !==
                        MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
                        ? timeDateValue
                        : "",
                    input_data:
                      supportRecordInputChoiced &&
                      choice.default_choice_input_type ===
                        MIXUTER_INPUT_TYPE.text
                        ? inputData
                        : "",
                    checked:
                      supportRecordInputChoiced &&
                      choice.default_choice_input_type ===
                        MIXUTER_INPUT_TYPE.checkbox
                        ? numberToBoolean(supportRecordInputChoiced.checked)
                        : false,
                    default_item: record.default_item,
                    default_choice: choice.default_choice,
                    default_choice_input_type: choice.default_choice_input_type,
                    category_type: categoryRecord.category_type
                  }
                });
              });
            }
            Object.assign(obj4, { [record.id]: { ...multiTextObj } });
            break;
          }
          // チェックボックス（カスタム可能）＋テキスト（１つだけ）（入力項目）
          case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text: {
            const checkboxAndTextObj = {} as customRecordValues["input_type_checkbox_and_text"][];
            if (record.custom_record_item_choices) {
              record.custom_record_item_choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;

                let inputData = "";
                if (
                  supportRecordInputChoiced &&
                  supportRecordInputChoiced.input_data
                ) {
                  if (
                    choice.default_choice ===
                    CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE.other
                  ) {
                    inputData = supportRecordInputChoiced.input_data;
                  }
                }

                Object.assign(checkboxAndTextObj, {
                  [choice.id]: {
                    custom_records_category_id:
                      record.custom_records_category_id,
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    input_data:
                      supportRecordInputChoiced &&
                      choice.default_choice_input_type ===
                        CHECKBOX_AND_TEXT_INPUT_TYPE.text
                        ? inputData
                        : "",
                    checked:
                      supportRecordInputChoiced &&
                      choice.default_choice_input_type ===
                        CHECKBOX_AND_TEXT_INPUT_TYPE.checkbox
                        ? numberToBoolean(supportRecordInputChoiced.checked)
                        : false,
                    default_item: record.default_item,
                    default_choice: choice.default_choice,
                    default_choice_input_type: choice.default_choice_input_type,
                    category_type: categoryRecord.category_type
                  }
                });
              });
            }
            Object.assign(obj5, { [record.id]: { ...checkboxAndTextObj } });
            break;
          }
          default:
            break;
        }
      });
    });
  }
  return {
    input_type_text: obj1,
    input_type_checkbox: obj2,
    input_type_multi_text: obj3,
    input_type_mixture: obj4,
    input_type_checkbox_and_text: obj5
  };
};

const createDestinationValues = (
  facilityType: FacilityType,
  destinations: ServiceDeliveryState["detailsRecord"]["serviceDeliveryRecordPractitioners"][number]["serviceDeliveryRecordPractitionerDestinations"]
): DestinationValues => {
  if (!destinations || !destinations.length) {
    return SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(facilityType)
      ? [practitionerDestinationInitialValue]
      : [];
  }
  return destinations.map((d) => {
    return {
      ...d,
      when_time: d.when_time ? format(d.when_time, "HH:mm") : "",
      place: d.place || "",
      support: d.support || "",
      isDelete: 0
    };
  });
};

const createMoneyManagementsValue = (
  facilityType: FacilityType,
  managements: ServiceDeliveryState["detailsRecord"]["serviceDeliveryRecordPractitioners"][number]["serviceDeliveryRecordPractitionerMoneyManagements"]
): MoneyManagementsValues => {
  if (SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(facilityType)) {
    const initial = [practitionerExpensesInitialValue];
    if (!managements) {
      return {
        traveling_expenses: initial,
        other_expenses: initial
      };
    }
    const traveling_expenses = managements.traveling_expenses.map((e) => {
      return {
        ...e,
        free_text: e.free_text ? `${e.free_text}` : "",
        amount_money: e.amount_money ? `${e.amount_money}` : "",
        transportation: e.transportation || 0,
        isDelete: 0
      };
    });
    const other_expenses = managements.other_expenses.map((e) => {
      return {
        ...e,
        free_text: e.free_text ? `${e.free_text}` : "",
        amount_money: e.amount_money ? `${e.amount_money}` : "",
        isDelete: 0
      };
    });
    return {
      traveling_expenses: traveling_expenses.length
        ? traveling_expenses
        : initial,
      other_expenses: other_expenses.length ? other_expenses : initial
    };
  }
  return {
    traveling_expenses: [],
    other_expenses: []
  };
};

const createDetailsValues = (
  details: ServiceDeliveryState["detailsRecord"]["serviceDeliveryRecordPractitioners"][0]["serviceDeliveryRecordsPractitionerDetails"],
  targetDate: string
): DetailsInitialValues => {
  return details.map((item) => {
    const startTimeValue = item.startTime
      ? format(item.startTime, "HH:mm")
      : "";
    const endTimeValue = item.endTime ? format(item.endTime, "HH:mm") : "";

    const startTimeDateValue = targetDate
      ? createDateValues(item.startTime, targetDate)
      : DAY_SELECT_TYPE.today;

    const endTimeDateValue = targetDate
      ? createDateValues(item.endTime, targetDate)
      : DAY_SELECT_TYPE.today;

    return {
      serviceDeliveryRecordPractitionerDetailsId:
        item.serviceDeliveryRecordPractitionerDetailsId,
      actionClass: item.actionClass,
      startTimeDate: startTimeDateValue,
      startTime: startTimeValue,
      endTimeDate: endTimeDateValue,
      endTime: endTimeValue,
      isDelete: item.isDelete
    };
  });
};

const getPractitionerSnapshot = (
  practitionerState: PractitionerType | null,
  staffOptions: FieldItem[]
): string | null => {
  if (!practitionerState) return null;
  const isDelete = staffOptions.every(
    (option) => option.value !== String(practitionerState.id)
  );
  // 提供者スナップショット対応
  const practitionerValues =
    practitionerState.snapshot_name &&
    practitionerState.snapshot_name !== practitionerState.name &&
    !isDelete
      ? PRACTITIONER_SNAPSHOT_OPTION_VALUE
      : String(practitionerState.id);

  return practitionerValues;
};

const createServiceDeliveryRecordPractitioners = (
  customRecords: CustomRecordsWithCategoryState,
  arrayIndex: number,
  staffOptions: FieldItem[],
  targetDateValue: string,
  facilityType: FacilityType,
  status: string,
  state?: ServiceDeliveryState["detailsRecord"]
): ServiceDeliveryRecordPractitionersValues => {
  // 編集時
  if (state && state.serviceDeliveryRecordPractitioners.length > arrayIndex) {
    // 提供者
    const practitionerState =
      state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner;
    const practitionerState2 =
      state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner2;
    const practitionerState3 =
      state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner3;
    const practitionerState4 =
      state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner4;
    const practitionerState5 =
      state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner5;

    const startTimeDateValue = createDateValues(
      state.serviceDeliveryRecordPractitioners[arrayIndex].startTime,
      state.targetDate
    );
    const endTimeDateValue = createDateValues(
      state.serviceDeliveryRecordPractitioners[arrayIndex].endTime,
      state.targetDate
    );

    // 重度訪問専用処理
    const accompanySupportInTimeDateValue =
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? createDateValues(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .accompanySupportInTime,
            state.targetDate
          )
        : "";
    const accompanySupportOutTimeValue =
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? createDateValues(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .accompanySupportOutTime,
            state.targetDate
          )
        : "";

    const { numberOfTime } = state.serviceDeliveryRecordPractitioners[0];
    const numberOfServiceHours = isNull(numberOfTime)
      ? ""
      : numberOfTime.split(":")[0];
    const numberOfServiceMinutes = isNull(numberOfTime)
      ? ""
      : numberOfTime.split(":")[1];

    return {
      serviceDeliveryRecordPractitionersId:
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .serviceDeliveryRecordPractitionersId,
      startTimeDate: startTimeDateValue,
      startTime: state.serviceDeliveryRecordPractitioners[arrayIndex].startTime
        ? format(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .startTime as string, // 三項演算子でtruthyな値であることは確認済み
            "HH:mm"
          )
        : "",
      endTimeDate: endTimeDateValue,
      endTime: state.serviceDeliveryRecordPractitioners[arrayIndex].endTime
        ? format(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .endTime as string, // 三項演算子でtruthyな値であることは確認済み
            "HH:mm"
          )
        : "",
      numberOfTime:
        status === IDOSHIEN_DISPLAY_STATUS_LIST_DETAIL[3].value &&
        facilityType === FacilityType.IDOSHIEN
          ? convertTimeToNumber(
              state.serviceDeliveryRecordPractitioners[arrayIndex].numberOfTime
            )
          : fillWith0FirstDecimal(
              state.serviceDeliveryRecordPractitioners[arrayIndex].numberOfTime
            ),
      numberOfRides:
        state.serviceDeliveryRecordPractitioners[arrayIndex].numberOfRides !==
        null
          ? String(
              state.serviceDeliveryRecordPractitioners[arrayIndex].numberOfRides
            )
          : "",
      numberOfServiceHours,
      numberOfServiceMinutes,
      calculatedMovingHours: fillWith0FirstDecimal(
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .calculatedMovingHours
      ),
      accompanySupportInTimeDate: accompanySupportInTimeDateValue,
      accompanySupportInTime: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].accompanySupportInTime
        ? format(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .accompanySupportInTime as string, // 三項演算子でtruthyな値であることは確認済み
            "HH:mm"
          )
        : "",
      accompanySupportOutTimeDate: accompanySupportOutTimeValue,
      accompanySupportOutTime: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].accompanySupportOutTime
        ? format(
            state.serviceDeliveryRecordPractitioners[arrayIndex]
              .accompanySupportOutTime as string, // 三項演算子でtruthyな値であることは確認済み
            "HH:mm"
          )
        : "",
      calculatedAccompanySupportHours: fillWith0FirstDecimal(
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .calculatedAccompanySupportHours
      ),

      calculatedAccompanySupportMovingHours: fillWith0FirstDecimal(
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .calculatedAccompanySupportMovingHours
      ),

      practitioner: getPractitionerSnapshot(practitionerState, staffOptions),
      practitioner2: getPractitionerSnapshot(practitionerState2, staffOptions),
      practitioner3: getPractitionerSnapshot(practitionerState3, staffOptions),
      practitioner4: getPractitionerSnapshot(practitionerState4, staffOptions),
      practitioner5: getPractitionerSnapshot(practitionerState5, staffOptions),
      practitionerSnapshot: state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner
        ? state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner
        : null,
      practitionerSnapshot2: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].practitioner2
        ? state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner2
        : null,
      practitionerSnapshot3: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].practitioner3
        ? state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner3
        : null,
      practitionerSnapshot4: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].practitioner4
        ? state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner4
        : null,
      practitionerSnapshot5: state.serviceDeliveryRecordPractitioners[
        arrayIndex
      ].practitioner5
        ? state.serviceDeliveryRecordPractitioners[arrayIndex].practitioner5
        : null,
      isSnapshot: !!state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner,
      isSnapshot2: !!state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner2,
      isSnapshot3: !!state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner3,
      isSnapshot4: !!state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner4,
      isSnapshot5: !!state.serviceDeliveryRecordPractitioners[arrayIndex]
        .practitioner5,
      practitionerName:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerName,
      practitionerName2:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerName2,
      practitionerName3:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerName3,
      practitionerName4:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerName4,
      practitionerName5:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerName5,
      practitionerNum:
        state.serviceDeliveryRecordPractitioners[arrayIndex].practitionerNum,
      displayFlg:
        state.serviceDeliveryRecordPractitioners[arrayIndex].displayFlg,
      serviceDeliveryRecordPractitionerDetails: createDetailsValues(
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .serviceDeliveryRecordsPractitionerDetails,
        targetDateValue
      ),
      custom_record: createCustomRecordsValues(
        customRecords,
        state.targetDate,
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .serviceDeliveryRecords
          ? state.serviceDeliveryRecordPractitioners[arrayIndex]
              .serviceDeliveryRecords
          : undefined
      ),
      serviceDeliveryRecordPractitionerDestinations: createDestinationValues(
        facilityType,
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .serviceDeliveryRecordPractitionerDestinations
      ),
      serviceDeliveryRecordPractitionerMoneyManagements: createMoneyManagementsValue(
        facilityType,
        state.serviceDeliveryRecordPractitioners[arrayIndex]
          .serviceDeliveryRecordPractitionerMoneyManagements
      )
    };
  }
  // 新規作成時
  return {
    serviceDeliveryRecordPractitionersId: null,
    startTimeDate: DAY_SELECT_TYPE.today,
    startTime: "",
    endTimeDate: DAY_SELECT_TYPE.today,
    endTime: "",
    numberOfTime: "",
    numberOfRides: "",
    numberOfServiceHours: "",
    numberOfServiceMinutes: "",
    calculatedMovingHours: "",
    accompanySupportInTimeDate: DAY_SELECT_TYPE.today,
    accompanySupportInTime: "",
    accompanySupportOutTimeDate: DAY_SELECT_TYPE.today,
    accompanySupportOutTime: "",
    calculatedAccompanySupportHours: "",
    calculatedAccompanySupportMovingHours: "",
    practitioner: "",
    practitioner2: "",
    practitioner3: "",
    practitioner4: "",
    practitioner5: "",
    practitionerSnapshot: null,
    practitionerSnapshot2: null,
    practitionerSnapshot3: null,
    practitionerSnapshot4: null,
    practitionerSnapshot5: null,
    isSnapshot: false,
    isSnapshot2: false,
    isSnapshot3: false,
    isSnapshot4: false,
    isSnapshot5: false,
    practitionerName: "",
    practitionerName2: "",
    practitionerName3: "",
    practitionerName4: "",
    practitionerName5: "",
    practitionerNum: arrayIndex + 1,
    displayFlg: false,
    serviceDeliveryRecordPractitionerDetails: [],
    custom_record: createCustomRecordsValues(customRecords, targetDateValue),
    serviceDeliveryRecordPractitionerDestinations: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
      facilityType
    )
      ? [practitionerDestinationInitialValue]
      : [],
    serviceDeliveryRecordPractitionerMoneyManagements: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
      facilityType
    )
      ? {
          traveling_expenses: [practitionerExpensesInitialValue],
          other_expenses: [practitionerExpensesInitialValue]
        }
      : {
          traveling_expenses: [],
          other_expenses: []
        }
  };
};

export const initialValues = ({
  customRecords,
  targetDate,
  staffOptions,
  facilityType,
  userId,
  state
}: initialValuesParam): ServiceDeliveryDetailValues => {
  // 月ごと新規の場合利用者が存在する
  const userIdValue = userId || 0;
  let targetDateValue = dateToLocalisedString(targetDate, "YYYY-MM-DD");
  // 月ごと新規の場合
  if (userId) {
    const today = new Date();
    targetDateValue =
      dateToLocalisedString(today, "YYYYMM") === targetDate
        ? dateToLocalisedString(today, "YYYY-MM-DD")
        : targetDate;
  }

  // 「入院（長期）」→「重度訪問介護（入院）」に変換（重度のみ）
  let statusValues = "1";
  if (state) {
    if (
      facilityType === FacilityType.JUDOHOMONKAIGO &&
      state.status === JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD[3].value
    ) {
      statusValues = JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD[2].value;
    } else {
      statusValues = state.status;
    }
  }
  // 同行援護/行動援護はstatus固定
  if (facilityType === FacilityType.DOKOENGO) {
    statusValues = DOKOENGO_DISPLAY_STATUS_LIST_RECORD[1].value;
  } else if (facilityType === FacilityType.KODOENGO) {
    statusValues = KODOENGO_DISPLAY_STATUS_LIST_RECORD[1].value;
  }

  // 職員1人あたりの支援比率を算出
  const supportRatioPerPractitioner =
    state && state.numberOfSupporters && state.numberOfPractitioner
      ? `${
          Math.round(
            (state.numberOfSupporters / state.numberOfPractitioner) * 100
          ) / 100
        }`
      : "";

  return {
    // date: { year: "2022", month: "9", day: "" },
    serviceDeliveryRecordsId: state ? state.serviceDeliveryRecordsId : null,
    inoutResultsId: state ? state.inoutResultsId : null,
    supportProcedureFormsId: state ? state.supportProcedureFormsId : null,
    createdAt: state ? state.createdAt : null,
    updatedAt: state ? state.updatedAt : null,
    usersInFacilityId: state ? state.usersInFacilityId : userIdValue,
    targetDate: state
      ? dateToSelectDateValue(state.targetDate)
      : dateToSelectDateValue(targetDateValue),
    status: statusValues,
    statusText: state ? state.statusText : "", // 移動支援
    emergencySupportFlg: state ? state.emergencySupportFlg : false,
    sputumImplementationFlg: state ? state.sputumImplementationFlg : false,
    bodyRestrictedStillFlg: state ? state.bodyRestrictedStillFlg : false,
    emergencyGuideSupportFlg: state ? state.emergencyGuideSupportFlg : false, // 重度のみ
    numberOfPractitioner: state ? state.numberOfPractitioner.toString() : "1",
    numberOfSupporters: state
      ? undefinedToReturnValue(state.numberOfSupporters)
      : "", // 移動支援
    supportRatioPerPractitioner, // 移動支援
    physicalCareFlg: state ? state.physicalCareFlg : false, // 移動支援
    licenseSameFlg: state ? state.licenseSameFlg : false, // 移動支援
    accompanySupportFlg: state ? state.accompanySupportFlg : false, // 重度のみ
    accompanySupportDisplayFlg: state
      ? state.accompanySupportDisplayFlg
      : false, // 重度のみ
    serviceDeliveryRecordPractitioners1: createServiceDeliveryRecordPractitioners(
      customRecords,
      0,
      staffOptions,
      targetDateValue,
      facilityType,
      statusValues,
      state
    ),
    serviceDeliveryRecordPractitioners2: createServiceDeliveryRecordPractitioners(
      customRecords,
      1,
      staffOptions,
      targetDateValue,
      facilityType,
      statusValues,
      state
    ),
    municipality: {
      calculationTimeFlg:
        state &&
        state.municipality &&
        !isNull(state.municipality.calculationTimeFlg)
          ? state.municipality.calculationTimeFlg
          : false,
      roundUpMinute:
        state && state.municipality ? state.municipality.roundUpMinute : null
    }
  };
};
