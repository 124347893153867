import * as React from "react";
import classNames, * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";

const styles = (): StyleRules =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      height: "100%",
      "& tbody td:not(:first-of-type)": {
        overflowWrap: "anywhere"
      },
      "& td": {
        verticalAlign: "initial",
        borderRight: "1px solid",
        borderTop: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        "&.padding4*6": {
          padding: "4px 6px"
        },
        "&.headLabelWrapper": {
          padding: "0px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle",
          height: "57px",
          textAlign: "center"
        },
        "&.headLabelWrapperPlanChange": {
          padding: "0px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle",
          border: "0px",
          height: "57px",
          textAlign: "center"
        },
        "& p": {
          margin: "0px",
          fontSize: "10px",
          transform: " scale(0.8)",
          lineHeight: 1.4,
          letterSpacing: "0.4px",
          color: "#212121",
          whiteSpace: "nowrap"
        },
        "&.index": {
          textAlign: "right",
          backgroundColor: "#f5f5f5"
        },
        "&.w29": {
          width: "29px"
        },
        "&.w48": {
          width: "48px"
        },
        "&.w55": {
          width: "55px"
        },
        "&.w128": {
          width: "128px"
        },
        "&.W134": {
          width: "134px"
        },
        "&.w77": {
          width: "77px"
        },
        "&.w146": {
          width: "146px"
        },
        "&.w142": {
          width: "142px"
        },
        "&.h22": {
          height: "22px"
        },
        "&.h33": {
          height: "33px"
        }
      },
      "& tr": {
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    headLabelWrapperPlanChangeTitle: {
      verticalAlign: "initial",
      paddingTop: "2px",
      borderTop: "0px",
      borderRight: "0px",
      borderBottom: "1px solid",
      fontSize: 10,
      letterSpacing: 0.6,
      color: "rgba(0, 0, 0, 0.84)",
      height: "20px"
    },
    headLabelWrapperPlanChangeItem: {
      padding: "4px 0 5px 0",
      borderRight: "1px solid",
      backgroundColor: "#f5f5f5",
      verticalAlign: "middle",
      height: "35px",
      width: "48px",
      textAlign: "center"
    },
    headWrapperPlan: {
      display: "grid",
      gridTemplateColumns: "48px 48px 48px"
    },
    headP: {
      marginTop: "0px",
      fontSize: "10px",
      transform: " scale(0.8)",
      lineHeight: 1.4,
      letterSpacing: "0.4px",
      color: "#212121",
      whiteSpace: "nowrap"
    },
    changePlan: {
      margin: "0px -6px",
      fontSize: "10px",
      transform: " scale(0.8)",
      lineHeight: 1.4,
      letterSpacing: "0.4px",
      textAlign: "center",
      color: "#212121",
      whiteSpace: "nowrap"
    },
    bodyWrapper: {
      padding: "0px"
    },
    bodyWrapperPlan: {
      display: "grid",
      gridTemplateColumns: "48px 48px 48px",
      height: "100%"
    },
    bodyWrapperChangePlan: {
      textAlign: "center",
      borderTop: "0px",
      borderRight: "1px solid"
    },
    p4x6: {
      padding: "4px 6px 4px 6px"
    }
  });

type OwnProps = {
  details: {
    record: GetMonitoringReport["data"]["details"][0];
    number: number;
  }[];
} & WithStyles<typeof styles>;
type Props = OwnProps;

const PriorityPrintContentCore = (props: Props): JSX.Element => {
  const { classes, details } = props;
  return (
    <table className={ClassNames(classes.table, classes.MT16)}>
      <thead>
        <tr>
          <td className="headLabelWrapper w29" rowSpan={2}>
            <p>
              優先
              <br />
              順位
            </p>
          </td>

          <td className="headLabelWrapper w128">
            <p>支援目標</p>
          </td>
          <td className="headLabelWrapper w55">
            <p>達成時期</p>
          </td>
          <td className="headLabelWrapper W134">
            <p>
              サービス提供状況
              <br />
              （事業者からの聞き取り）
            </p>
          </td>
          <td className="headLabelWrapper W134">
            <p>本人の感想・満足度</p>
          </td>
          <td className="headLabelWrapper W134">
            <p>
              支援目標の 達成度
              <br />
              （ニーズの 充足度）
            </p>
          </td>
          <td className="headLabelWrapper W134">
            <p>今後の課題・解決方法</p>
          </td>
          <td className="headLabelWrapperPlanChange w146">
            <div>
              <div className={classes.headLabelWrapperPlanChangeTitle}>
                <p className={classes.headP}>計画変更の必要性</p>
              </div>
            </div>
            <div className={classes.headWrapperPlan}>
              <div className={classes.headLabelWrapperPlanChangeItem}>
                <div className={classes.changePlan}>
                  サービス
                  <br />
                  種類の変更
                </div>
              </div>
              <div className={classes.headLabelWrapperPlanChangeItem}>
                <p className={classes.changePlan}>
                  サービス
                  <br />
                  量の変更
                </p>
              </div>
              <div
                className={classes.headLabelWrapperPlanChangeItem}
                style={{ borderRight: "0px" }}
              >
                <p className={classes.changePlan}>
                  週間計画の
                  <br />
                  変更
                </p>
              </div>
            </div>
          </td>
          <td
            className="headLabelWrapper w142"
            style={{ borderLeft: "1px solid" }}
          >
            <p>その他留意事項</p>
          </td>
        </tr>
      </thead>
      <tbody>
        {details.map(({ record, number }) => {
          return (
            <tr key={`priority_content_${number}`}>
              <td className="index">
                <p>{number}</p>
              </td>
              <td className={classes.p4x6}>{record.support_goal}</td>
              <td className={classes.p4x6}>{record.achievement_time}</td>
              <td className={classes.p4x6}>
                {record.service_provision_status}
              </td>
              <td className={classes.p4x6}>{record.personal_satisfaction}</td>
              <td className={classes.p4x6}>
                {record.achievement_support_goals}
              </td>
              <td className={classes.p4x6}>{record.future_issues}</td>
              <td className={classes.bodyWrapper}>
                <div className={classes.bodyWrapperPlan}>
                  <div
                    className={classNames(
                      classes.bodyWrapperChangePlan,
                      classes.p4x6
                    )}
                  >
                    {record.change_plan_service_type === null && <>&nbsp;</>}
                    {record.change_plan_service_type === 0 && "無し"}
                    {record.change_plan_service_type === 1 && "有り"}
                  </div>
                  <div
                    className={classNames(
                      classes.bodyWrapperChangePlan,
                      classes.p4x6
                    )}
                  >
                    {record.change_plan_service_volume === null && <>&nbsp;</>}
                    {record.change_plan_service_volume === 0 && "無し"}
                    {record.change_plan_service_volume === 1 && "有り"}
                  </div>
                  <div
                    className={classNames(
                      classes.bodyWrapperChangePlan,
                      classes.p4x6
                    )}
                    style={{ borderRight: "0px" }}
                  >
                    {record.change_plan_weekly === null && <>&nbsp;</>}
                    {record.change_plan_weekly === 0 && "無し"}
                    {record.change_plan_weekly === 1 && "有り"}
                  </div>
                </div>
              </td>
              <td
                className={classNames(
                  classes.otherConsiderations,
                  classes.p4x6
                )}
              >
                {record.other_considerations}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const PriorityPrintContent = withStyles(styles)(
  PriorityPrintContentCore
);
