import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { inOutConsultationResults } from "@api/requests/inOutConsultationResults/";
import { dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeCHIIKIIKOUserReportDataFromAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/mgr/CHIIKIIKO/report/initial";

/**
 * 利用実績一覧(月ごと)のデータ取得及びstore格納
 */
const fetchCHIIKIIKOUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCHIIKIIKOUserStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchCHIIKIIKOUser(
          normalizeCHIIKIIKOUserReportDataFromAPI(response.data, date)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchCHIIKIIKOUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postCHIIKIIKOReport = (dispatch: Dispatch) => async (
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCHIIKIIKOReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(before, after);
  await inOutConsultationResults
    .postInOutConsultationResults(requestParam)
    .then(() => {
      const actionType = actions.postCHIIKIIKOReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postCHIIKIIKOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchCHIIKIIKOUsers: (uifId: number, date: Date) => Promise<void>;
  postCHIIKIIKOReport: (
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues
  ) => Promise<void>;
};

export const CHIIKIIKOReportDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchCHIIKIIKOUsers: fetchCHIIKIIKOUsers(dispatch),
  postCHIIKIIKOReport: postCHIIKIIKOReport(dispatch)
});
