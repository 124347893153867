import { PostSupportRecordUserParams } from "@api/requests/supports/Cseg/postSupportRecordUser";
import { PostOperationsParams } from "@api/requests/operations/Cseg/postOperations";
import {
  SupportRecordFormValues,
  OperationRecordFormValues
} from "@initialize/mgr/Cseg/record/dailyRecord/initialValues";
import omit from "lodash-es/omit";
import _isEqual from "lodash-es/isEqual";
import { normalizeMultiSelectStaffs } from "@utils/domain/staffs/normalizeMultiSelectStaffs";

export const normalizePostSupportFormToApi = (
  uidId: number,
  targetDate: string,
  formValues: SupportRecordFormValues,
  initialValues: SupportRecordFormValues
): PostSupportRecordUserParams => {
  const editingRecord = formValues.find(
    (v) => v.users_in_facility_id === uidId
  );

  if (!editingRecord) {
    return { support: [] };
  }

  const { displayStaffIds, staffs, ...rest } = editingRecord;
  const param = omit(rest, ["support_records_id"]);

  // 同じstaffのnameとsnapshot_nameを一緒に選択した場合、snapshot_nameをリクエストから排除
  const staffsParam = normalizeMultiSelectStaffs(displayStaffIds, staffs || []);
  const postData: PostSupportRecordUserParams["support"][number] = {
    ...param,
    id: rest.support_records_id,
    target_date: targetDate,
    staffs: staffsParam
  };

  const beforeRecord = initialValues.find(
    (v) => v.users_in_facility_id === uidId
  );

  if (beforeRecord) {
    if (editingRecord.support_content === beforeRecord.support_content) {
      delete postData.support_content;
    }
    if (editingRecord.staff_comment === beforeRecord.staff_comment) {
      delete postData.staff_comment;
    }
    if (_isEqual(editingRecord.displayStaffIds, beforeRecord.displayStaffIds)) {
      delete postData.staffs;
    }
  }

  const res = [postData];

  return { support: res };
};

export const normalizePostOperationFormToApi = (
  formValues: OperationRecordFormValues,
  initialValues: OperationRecordFormValues
): PostOperationsParams => {
  const operation: PostOperationsParams["operation"] = {
    operation_records_id: formValues.operation_records_id || null,
    operation_in_the_morning: formValues.operation_in_the_morning,
    operation_in_the_afternoon: formValues.operation_in_the_afternoon,
    other_comment: formValues.other_comment,
    staffs: normalizeMultiSelectStaffs(
      formValues.staffs.itemIdList,
      formValues.staffs.beforeValues
    )
  };

  // 変更していないkeyは削除
  if (
    formValues.operation_in_the_morning ===
    initialValues.operation_in_the_morning
  ) {
    delete operation.operation_in_the_morning;
  }
  if (
    formValues.operation_in_the_afternoon ===
    initialValues.operation_in_the_afternoon
  ) {
    delete operation.operation_in_the_afternoon;
  }
  if (formValues.other_comment === initialValues.other_comment) {
    delete operation.other_comment;
  }
  if (_isEqual(formValues.staffs, initialValues.staffs)) {
    delete operation.staffs;
  }

  return { operation };
};
