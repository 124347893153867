import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: ""
    },
    facilityUnits: [],
    staffsInFacility: [],
    displayFacilityUnits: [],
    displayStaffsInFacility: [],
    displayList: [],
    planCount: 0
  },
  reportMonthly: {
    summary: {
      physicalCareCount: 0,
      outpatientCareWithPhysicalCareCount: 0,
      houseworkAssistanceCount: 0,
      outpatientCareCount: 0,
      gettingOnAndOffCount: 0
    },
    facilityUnits: [],
    staffsInFacility: [],
    displayFacilityUnits: [],
    displayStaffsInFacility: [],
    displayList: [],
    planCount: 0
  }
};

export const KYOTAKUKAIGOReport = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_KYOTAKUKAIGO_DAILY_STARTED:
      return { ...state };
    case types.FETCH_KYOTAKUKAIGO_DAILY:
      return {
        ...state,
        reportDaily: action.payload
      };
    case types.FETCH_KYOTAKUKAIGO_DAILY_FAILED:
      return { ...state };
    case types.FETCH_KYOTAKUKAIGO_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_KYOTAKUKAIGO_MONTHLY:
      return {
        ...state,
        reportMonthly: action.payload
      };
    case types.FETCH_KYOTAKUKAIGO_MONTHLY_FAILED:
      return {
        ...state
      };
    default:
      return {
        ...state
      };
  }
};
