import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import {
  COLLECTION_OF_UTILITY_FEE,
  FOOD_BREAKFAST,
  FOOD_LUNCH,
  FOOD_SUPPER,
  STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT_JISSEKI,
  STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT_JISSEKI,
  STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT,
  StatusType,
  HospitalizationOvernightstay,
  BulkRegistrationItemList,
  RegionalTransition,
  CollectionOfUtilityFee,
  FoodBreakfast,
  FoodLunch,
  FoodSupper,
  UsagePerformanceStoreParamKeyToSelectBoxName,
  UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName,
  USAGE_PERFORMANCE_SHISETSUNYUSHO_ITEM_LIST,
  USAGE_PERFORMANCE_ITEM_LIST,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY,
  HospitalizationDays,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS,
  STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS,
  STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL,
  STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import { SERVICE_END_STATUS } from "@constants/variables";
import {
  ReportState,
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType,
  ReportType,
  REPEAT_DAILY,
  REPEAT_USERS
} from "@stores/domain/mgr/SHISETSUNYUSHO/report/types";
import EditIcon from "@material-ui/icons/Edit";
import { AppState } from "@stores/type";
import DropDown from "@components/atoms/DropDown";
import TextField from "@components/atoms/TextField";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import validator, { ErrorMessage } from "@validator";
import {
  getDefaultHospitalizationDays,
  getUsersHospitalizationDaysPrev
} from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";
import { createOptionsHospitalizationOvernightStayList } from "@utils/domain/mgr/SHISETSUNYUSHO/report/createOptionsHospitalizationOvernightStay";

const styles = (): StyleRules =>
  createStyles({
    dropDownStyle: {
      margin: 0,
      width: "80%"
    },
    superLongCell: {
      width: "15%",
      minWidth: 256,
      boxSizing: "content-box",
      padding: "12px 0px 8px 16px",
      verticalAlign: "top"
    },
    longCell: {
      width: "15%",
      minWidth: 158,
      boxSizing: "content-box",
      padding: "12px 0px 8px 16px",
      verticalAlign: "top"
    },
    middleCell: {
      width: "7%",
      minWidth: 72,
      boxSizing: "content-box",
      padding: "12px 0px 8px 16px",
      verticalAlign: "top"
    },
    editCell: {
      width: "5%",
      // ここは、元のデザインとはサイズが違うが、細かい調整を行った数値を設定している。
      minWidth: 40,
      boxSizing: "content-box",
      padding: "18px 0px 14px 0px",
      verticalAlign: "top"
    },
    readonlyTextFieldCell: {
      width: "80%",
      lineHeight: "1.1875em"
    },
    hidden: {
      display: "none"
    },
    minWidth: {
      minWidth: 64
    },
    statusWidth: {
      width: 256
    }
  });

type OwnProps = {
  usagePerformance: UsagePerformanceType;
  usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType;
  idx: number;
  keyValue: string;
  openModal: (
    usagePerformance: UsagePerformanceType,
    usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType
  ) => void;
  isDisabledFood: boolean;
  reportType: ReportType;
};

type StateProps = {
  reportState: ReportState;
};

type DispatchProps = {
  updateSHISETSUNYUSHOUsagePerformanceItemsDaily: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => void;
  updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  updateSHISETSUNYUSHOUsagePerformanceItemsUsers: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => void;
  updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  resetInvalid: () => void;
  updateInvalidTrue: () => void;
};

type Props = OwnProps & WithStyles<typeof styles> & DispatchProps & StateProps;

class UsagePerformanceReportCellEdit extends React.Component<Props> {
  private onChangeHospitalizationDays = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const { reportType } = this.props;
    if (reportType === REPEAT_DAILY) {
      await this.updateStoreData(
        "hospitalizationDays",
        event.target.value,
        this.props.updateSHISETSUNYUSHOUsagePerformanceItemsDaily,
        this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
      );
    } else if (reportType === REPEAT_USERS) {
      await this.updateStoreData(
        "hospitalizationDays",
        event.target.value,
        this.props.updateSHISETSUNYUSHOUsagePerformanceItemsUsers,
        this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers
      );
    }
    this.props.resetInvalid();
  };

  /**
   * 編集モーダルの表示イベント
   */
  private openModal = (): void => {
    this.props.openModal(
      this.props.usagePerformance,
      this.props.usagePerformanceSHISETSUNYUSHO
    );
  };

  /**
   * サービス状況の値を変更した際の入院・外泊時加算入院時支援特別加算項目の値の初期化
   */
  private resetHospitalizationOvernightStay = (
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"]
  ): Promise<void> => {
    const stateUsagePerformance =
      updateUsagePerformanceSHISETSUNYUSHODispatcher ===
      this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
        ? this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].hospitalizationOvernightStay
        : this.props.reportState.reportUsers.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].hospitalizationOvernightStay;
    if (`${stateUsagePerformance}` !== HospitalizationOvernightstay.NONE) {
      return updateUsagePerformanceSHISETSUNYUSHODispatcher(
        BulkRegistrationItemList.HOSPITALIZATION_OVERNIGHTSTAY,
        HospitalizationOvernightstay.NONE,
        this.props.keyValue,
        this.props.reportState
      );
    }
    return new Promise((resolve) => {
      resolve();
    });
  };

  /**
   * サービス状況の値を「入院（最終日）」「外泊（中日）」「入院(最終日)→外泊(初日)」「入院(最終日)→共同生活住居に戻る→外泊(初日)」に変更した際に入院・外泊時加算入院時支援特別加算項目の値を変更する
   */
  private changeHospitalizationOvernightStay = async (
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"],
    isOnlySpecial: boolean
  ): Promise<void> => {
    const stateUsagePerformance =
      updateUsagePerformanceSHISETSUNYUSHODispatcher ===
      this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
        ? this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].hospitalizationOvernightStay
        : this.props.reportState.reportUsers.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].hospitalizationOvernightStay;
    if (isOnlySpecial) {
      if (
        `${stateUsagePerformance}` === HospitalizationOvernightstay.ONE ||
        `${stateUsagePerformance}` === HospitalizationOvernightstay.TWO
      ) {
        await updateUsagePerformanceSHISETSUNYUSHODispatcher(
          BulkRegistrationItemList.HOSPITALIZATION_OVERNIGHTSTAY,
          HospitalizationOvernightstay.NONE,
          this.props.keyValue,
          this.props.reportState
        );
      }
    } else if (
      `${stateUsagePerformance}` === HospitalizationOvernightstay.SPECIAL
    ) {
      await updateUsagePerformanceSHISETSUNYUSHODispatcher(
        BulkRegistrationItemList.HOSPITALIZATION_OVERNIGHTSTAY,
        HospitalizationOvernightstay.NONE,
        this.props.keyValue,
        this.props.reportState
      );
    }
  };

  /**
   * サービス状況の値を変更した際の入院・外泊時加算入院時支援特別加算項目の値の更新
   */
  private updateHospitalizationDays = async (
    statusType: string,
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"]
  ): Promise<void> => {
    let hospitalizationDaysUpdated = null;

    // 日ごと
    if (
      updateUsagePerformanceSHISETSUNYUSHODispatcher ===
      this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
    ) {
      hospitalizationDaysUpdated =
        // 非活性または初日「0」となるステータスを選択した時
        STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS.includes(statusType) ||
        STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS.includes(statusType)
          ? getDefaultHospitalizationDays(statusType, null)
          : getDefaultHospitalizationDays(
              statusType,
              this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
                .before[`${this.props.keyValue}`].hospitalizationDaysPrev
            );
      // 月ごと
    } else {
      hospitalizationDaysUpdated =
        // 非活性または初日「0」となるステータスを選択した時
        STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS.includes(statusType) ||
        STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS.includes(statusType)
          ? getDefaultHospitalizationDays(statusType, null)
          : getDefaultHospitalizationDays(
              statusType,
              getUsersHospitalizationDaysPrev(
                this.props.keyValue,
                this.props.reportState.reportUsers
                  .usagePerformanceSHISETSUNYUSHO
              )
            );
    }
    await updateUsagePerformanceSHISETSUNYUSHODispatcher(
      BulkRegistrationItemList.HOSPITALIZATION_DAYS,
      hospitalizationDaysUpdated,
      this.props.keyValue,
      this.props.reportState
    );
  };

  /**
   * サービス状況によってステータス「入院（初日・中日・最終日）」「外泊（初日・中日・最終日）」が選択されている場合、地域移行加算をリセット
   */
  private resetCollectionOfUtilityFeeAndFoodStatUs = async (
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"]
  ): Promise<void> => {
    const stateUsagePerformance =
      updateUsagePerformanceSHISETSUNYUSHODispatcher ===
      this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
        ? this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].regionalTransition
        : this.props.reportState.reportUsers.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`].regionalTransition;
    if (`${stateUsagePerformance}` !== RegionalTransition.NONE) {
      await updateUsagePerformanceSHISETSUNYUSHODispatcher(
        BulkRegistrationItemList.REGIONAL_TRANSITION,
        RegionalTransition.NONE,
        this.props.keyValue,
        this.props.reportState
      );
    }
  };

  /**
   * 栄養マネジメントと経口移行加算をステータスに沿った値に変更する
   */
  private resetNutritionManagementFlg = async (
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"],
    statusType: string
  ): Promise<void> => {
    const afterData =
      updateUsagePerformanceSHISETSUNYUSHODispatcher ===
      this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
        ? this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`]
        : this.props.reportState.reportUsers.usagePerformanceSHISETSUNYUSHO
            .after[`${this.props.keyValue}`];
    if (afterData && afterData.nutritionManagement) {
      if (
        STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(statusType)
      ) {
        // 栄養マネジメントが非機能になる場合はfalse(0)をセット
        await updateUsagePerformanceSHISETSUNYUSHODispatcher(
          BulkRegistrationItemList.NUTRITION_MANAGEMENT,
          0,
          this.props.keyValue,
          this.props.reportState
        );
        await updateUsagePerformanceSHISETSUNYUSHODispatcher(
          BulkRegistrationItemList.ORAL_TRANSITION,
          0,
          this.props.keyValue,
          this.props.reportState
        );
      } else {
        // 栄養マネジメントが活性化する場合には元の値をセット
        // ここは、非活性→活性化となった場合に最初の状態に戻したいため
        const beforeData =
          updateUsagePerformanceSHISETSUNYUSHODispatcher ===
          this.props
            .updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
            ? this.props.reportState.reportDaily.usagePerformanceSHISETSUNYUSHO
                .before[`${this.props.keyValue}`]
            : this.props.reportState.reportUsers.usagePerformanceSHISETSUNYUSHO
                .before[`${this.props.keyValue}`];
        await updateUsagePerformanceSHISETSUNYUSHODispatcher(
          BulkRegistrationItemList.NUTRITION_MANAGEMENT,
          beforeData.nutritionManagement,
          this.props.keyValue,
          this.props.reportState
        );
        await updateUsagePerformanceSHISETSUNYUSHODispatcher(
          BulkRegistrationItemList.ORAL_TRANSITION,
          beforeData.oralTransition,
          this.props.keyValue,
          this.props.reportState
        );
      }
    }
  };

  /**
   * 「入院・外泊日数」のバリデーション
   */
  private isErrorHospitalizationDays = (
    value: string | number | null
  ): ErrorMessage => {
    if (value === null) {
      return undefined;
    }
    const errorMessage = validator(value.toString(), "naturalNumber");
    if (errorMessage && !this.props.reportState.invalid) {
      this.props.updateInvalidTrue();
    }
    return errorMessage;
  };

  /**
   * 値の変更イベント
   */
  private changeSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const targetName = event.target.name as BulkRegistrationItemList;
    const targetValue = event.target.value;
    const { reportType } = this.props;

    if (reportType === REPEAT_DAILY) {
      await this.updateStoreData(
        targetName,
        targetValue,
        this.props.updateSHISETSUNYUSHOUsagePerformanceItemsDaily,
        this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily
      );
    } else if (reportType === REPEAT_USERS) {
      await this.updateStoreData(
        targetName,
        targetValue,
        this.props.updateSHISETSUNYUSHOUsagePerformanceItemsUsers,
        this.props.updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers
      );
    }
    this.props.resetInvalid();
  };

  /**
   * store反映
   */
  private updateStoreData = async (
    targetName: BulkRegistrationItemList,
    targetValue: string,
    updateUsagePerformanceDispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceItemsUsers"],
    updateUsagePerformanceSHISETSUNYUSHODispatcher:
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily"]
      | Props["updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers"]
  ): Promise<void> => {
    if (USAGE_PERFORMANCE_ITEM_LIST.includes(targetName)) {
      // 変更が無ければ何もしない
      if (this.notUpdateUsagePerformance(targetName, targetValue)) {
        return;
      }
      updateUsagePerformanceDispatcher(
        targetName,
        targetValue,
        this.props.keyValue,
        this.props.reportState
      );
      // サービス状況によって入院・外泊時加算入院時支援特別加算項目の値を初期化させる
      if (
        targetName === BulkRegistrationItemList.STATUS_TYPE &&
        STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY.includes(
          targetValue
        )
      ) {
        await this.resetHospitalizationOvernightStay(
          updateUsagePerformanceSHISETSUNYUSHODispatcher
        );
      }
      if (
        targetName === BulkRegistrationItemList.STATUS_TYPE &&
        STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL.includes(
          targetValue
        )
      ) {
        await this.changeHospitalizationOvernightStay(
          updateUsagePerformanceSHISETSUNYUSHODispatcher,
          true
        );
      }
      if (
        targetName === BulkRegistrationItemList.STATUS_TYPE &&
        targetValue === StatusType.OVERNIGHT
      ) {
        await this.changeHospitalizationOvernightStay(
          updateUsagePerformanceSHISETSUNYUSHODispatcher,
          false
        );
      }
      // サービス状況によって「入院・外泊日数」項目の値を更新する
      if (targetName === BulkRegistrationItemList.STATUS_TYPE) {
        await this.updateHospitalizationDays(
          targetValue,
          updateUsagePerformanceSHISETSUNYUSHODispatcher
        );
      }
      // サービス状況によってステータス「入院（初日・中日・最終日）」「外泊（初日・中日・最終日）」が選択されている場合、地域移行加算をリセット
      if (
        targetName === BulkRegistrationItemList.STATUS_TYPE &&
        STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT.includes(targetValue)
      ) {
        await this.resetCollectionOfUtilityFeeAndFoodStatUs(
          updateUsagePerformanceSHISETSUNYUSHODispatcher
        );
      }
      // サービス提供の状況によって栄養マネジメント加算と経口移行加算の値をデフォルト値に戻す
      if (targetName === BulkRegistrationItemList.STATUS_TYPE) {
        this.resetNutritionManagementFlg(
          updateUsagePerformanceSHISETSUNYUSHODispatcher,
          targetValue
        );
      }
    } else if (
      USAGE_PERFORMANCE_SHISETSUNYUSHO_ITEM_LIST.includes(targetName)
    ) {
      // 変更がなければ何もしない
      if (
        this.notUpdateUsagePerformanceSHISETSUNYUSHO(targetName, targetValue)
      ) {
        return;
      }
      await updateUsagePerformanceSHISETSUNYUSHODispatcher(
        targetName,
        targetValue,
        this.props.keyValue,
        this.props.reportState
      );
    }
  };

  /**
   * usagePerformanceの値更新チェック
   */
  private notUpdateUsagePerformance = (
    name: string,
    value: string
  ): boolean => {
    return (
      value ===
      `${
        this.props.usagePerformance[
          UsagePerformanceStoreParamKeyToSelectBoxName[name].storeKey
        ]
      }`
    );
  };

  /**
   * usagePerformanceSHISETSUNYUSHOの値更新チェック
   * usagePerformanceSHISETSUNYUSHOが存在しない場合false
   */
  private notUpdateUsagePerformanceSHISETSUNYUSHO = (
    name: string,
    value: string
  ): boolean => {
    return this.props.usagePerformanceSHISETSUNYUSHO
      ? value ===
          `${
            this.props.usagePerformanceSHISETSUNYUSHO[
              UsagePerformanceSHISETSUNYUSHOStoreParamKeyToSelectBoxName[name]
                .storeKey
            ]
          }`
      : false;
  };

  public render(): JSX.Element {
    const {
      idx,
      usagePerformance,
      usagePerformanceSHISETSUNYUSHO,
      classes,
      isDisabledFood
    } = this.props;

    // サービス提供状況が[-]かどうかの判定
    const isStatusNone = `${usagePerformance.statusType}` === StatusType.NONE;

    const isStatusDisabledHospitalization = STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY.some(
      (item) => item === `${usagePerformance.statusType}`
    );

    // ユーザーの状態に応じでサービス提供の状況の選択肢を切り替える
    const statusTypeList =
      usagePerformance.isServiceEnd === SERVICE_END_STATUS.SERVICE_USE ||
      usagePerformance.isServiceEnd === SERVICE_END_STATUS.SERVICE_END_DAY
        ? STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT_JISSEKI
        : STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT_JISSEKI;

    // 地域移行加算選択されたかどうか確認
    const isRegionalTransitionChecked =
      `${usagePerformanceSHISETSUNYUSHO.regionalTransition}` === "1";

    // 特定のサービス提供の状況で「入院・外泊日数」項目をdisableにする
    const isStatusDisabledHospitalizationDays = STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS.some(
      (value) => {
        return `${usagePerformance.statusType}` === value;
      }
    );

    // 特定のサービス提供の状況で「入院・外泊日数」項目を読み取り専用にする
    const isStatusReadOnlyHospitalizationDays = STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS.some(
      (value) => {
        return `${usagePerformance.statusType}` === value;
      }
    );

    // サービス提供の状況が地域移行加算(退所後)かどうかの判定
    const isStatusTypeRegionalTransitionChecked =
      `${usagePerformance.statusType}` ===
      StatusType.REGIONAL_MIGRATION_SUPPORT;

    return (
      <>
        {/* サービス提供の状況 */}
        <TableCellWrap
          key={`${idx}-status-type`}
          cellClass={classes.superLongCell}
        >
          <DropDown
            id={`${idx}-status-type-drop-down`}
            key={`${idx}-status-type-drop-down`}
            options={statusTypeList}
            onChange={this.changeSelect}
            value={`${usagePerformance.statusType}`}
            helperText=""
            InputProps={{ name: BulkRegistrationItemList.STATUS_TYPE }}
            classes={{ dropDown: classes.dropDownStyle }}
            styles={classes.statusWidth}
          />
        </TableCellWrap>

        {/* 入院・外泊時加算入院時支援特別加算 */}
        <TableCellWrap
          key={`${idx}-hospitalization-overnight-stay`}
          cellClass={classes.longCell}
        >
          <DropDown
            id={`${idx}-hospitalization-overnight-stay-drop-down`}
            key={`${idx}-hospitalization-overnight-stay-drop-down`}
            options={createOptionsHospitalizationOvernightStayList(
              `${usagePerformance.statusType}`
            )}
            onChange={this.changeSelect}
            value={
              usagePerformanceSHISETSUNYUSHO
                ? `${usagePerformanceSHISETSUNYUSHO.hospitalizationOvernightStay}`
                : HospitalizationOvernightstay.NONE
            }
            helperText=""
            InputProps={{
              name: BulkRegistrationItemList.HOSPITALIZATION_OVERNIGHTSTAY
            }}
            classes={{ dropDown: classes.dropDownStyle }}
            isDisabled={
              isStatusDisabledHospitalization || isRegionalTransitionChecked
            }
          />
        </TableCellWrap>

        {/* 入院・外泊日数 */}
        <TableCellWrap
          key={`${idx}-hospitalization-days`}
          cellClass={classes.middleCell}
        >
          {isStatusReadOnlyHospitalizationDays ? (
            <div className={classes.readonlyTextFieldCell}>
              <ReadonlyTextField
                value={` ${HospitalizationDays.ZERO}`}
                defaultValue=""
              />
            </div>
          ) : (
            <TextField
              id={`${idx}-hospitalization-days-text-field`}
              value={
                usagePerformanceSHISETSUNYUSHO.hospitalizationDays !== null
                  ? usagePerformanceSHISETSUNYUSHO.hospitalizationDays
                  : "-"
              }
              size="textFieldSuperSmall"
              type="text"
              maxLength={3}
              onChange={this.onChangeHospitalizationDays}
              isDisabled={isStatusNone || isStatusDisabledHospitalizationDays}
              inputStyle={{ margin: 0, width: "80%" }}
              helperText={this.isErrorHospitalizationDays(
                usagePerformanceSHISETSUNYUSHO.hospitalizationDays
              )}
              isError={
                !!this.isErrorHospitalizationDays(
                  usagePerformanceSHISETSUNYUSHO.hospitalizationDays
                )
              }
            />
          )}
        </TableCellWrap>

        {/* 光熱水費 */}
        <TableCellWrap
          key={`${idx}-collection-of-utility-fee`}
          cellClass={classes.middleCell}
        >
          <DropDown
            id={`${idx}-collection-of-utility-fee-drop-down`}
            key={`${idx}-collection-of-utility-fee-drop-down`}
            options={COLLECTION_OF_UTILITY_FEE}
            onChange={this.changeSelect}
            value={
              usagePerformanceSHISETSUNYUSHO
                ? `${usagePerformanceSHISETSUNYUSHO.collectionOfUtilityFee}`
                : CollectionOfUtilityFee.NONE
            }
            helperText=""
            InputProps={{
              name: BulkRegistrationItemList.COLLECTION_OF_UTILITY_FEE
            }}
            classes={{ dropDown: classes.dropDownStyle }}
            isDisabled={
              isStatusNone ||
              isRegionalTransitionChecked ||
              isStatusTypeRegionalTransitionChecked
            }
            styles={classes.minWidth}
          />
        </TableCellWrap>

        {/* 食事提供(朝) */}
        <TableCellWrap
          key={`${idx}-food-breakfast`}
          cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
        >
          <DropDown
            id={`${idx}-food-breakfast-drop-down`}
            key={`${idx}-food-breakfast-drop-down`}
            options={FOOD_BREAKFAST}
            onChange={this.changeSelect}
            value={
              usagePerformanceSHISETSUNYUSHO
                ? `${usagePerformanceSHISETSUNYUSHO.foodBreakfast}`
                : FoodBreakfast.NONE
            }
            helperText=""
            InputProps={{ name: BulkRegistrationItemList.FOOD_BREAKFAST }}
            classes={{ dropDown: classes.dropDownStyle }}
            isDisabled={
              isStatusNone ||
              isRegionalTransitionChecked ||
              isStatusTypeRegionalTransitionChecked
            }
            styles={classes.minWidth}
          />
        </TableCellWrap>

        {/* 食事提供(昼) */}
        <TableCellWrap
          key={`${idx}-food-lunch`}
          cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
        >
          <DropDown
            id={`${idx}-food-lunch-drop-down`}
            key={`${idx}-food-lunch-drop-down`}
            options={FOOD_LUNCH}
            onChange={this.changeSelect}
            value={
              usagePerformanceSHISETSUNYUSHO
                ? `${usagePerformanceSHISETSUNYUSHO.foodLunch}`
                : FoodLunch.NONE
            }
            helperText=""
            InputProps={{
              name: BulkRegistrationItemList.FOOD_LUNCH
            }}
            classes={{ dropDown: classes.dropDownStyle }}
            isDisabled={
              isStatusNone ||
              isRegionalTransitionChecked ||
              isStatusTypeRegionalTransitionChecked
            }
            styles={classes.minWidth}
          />
        </TableCellWrap>

        {/* 食事提供(夜) */}
        <TableCellWrap
          key={`${idx}-food-supper`}
          cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
        >
          <DropDown
            id={`${idx}-food-supper-drop-down`}
            key={`${idx}-food-supper-drop-down`}
            options={FOOD_SUPPER}
            onChange={this.changeSelect}
            value={
              usagePerformanceSHISETSUNYUSHO
                ? `${usagePerformanceSHISETSUNYUSHO.foodSupper}`
                : FoodSupper.NONE
            }
            helperText=""
            InputProps={{ name: BulkRegistrationItemList.FOOD_SUPPER }}
            classes={{ dropDown: classes.dropDownStyle }}
            isDisabled={
              isStatusNone ||
              isRegionalTransitionChecked ||
              isStatusTypeRegionalTransitionChecked
            }
            styles={classes.minWidth}
          />
        </TableCellWrap>

        {/* 編集ボタン */}
        <TableCellWrap
          key={`${idx}-edit-icon`}
          cellClass={classes.editCell}
          align="right"
        >
          <EditIcon
            style={{
              width: 18,
              height: 18,
              color: "#0277bd",
              cursor: "pointer"
            }}
            onClick={this.openModal}
          />
        </TableCellWrap>
      </>
    );
  }
}
const mapStateToProps = (state: AppState): StateProps => ({
  reportState: state.SHISETSUNYUSHO.report
});

type Dispatcher = {
  updateSHISETSUNYUSHOUsagePerformanceItemsDaily: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  updateSHISETSUNYUSHOUsagePerformanceItemsUsers: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers: (
    name: string,
    value: string | number | null,
    keyValue: string,
    reportState: ReportState
  ) => Promise<void>;
  resetInvalid: () => Promise<void>;
  updateInvalidTrue: () => Promise<void>;
};

const mapDispatchToProps = (dispatch: Dispatch): Dispatcher => {
  const { SHISETSUNYUSHO } = dispatches;

  return {
    updateSHISETSUNYUSHOUsagePerformanceItemsDaily: (
      name: string,
      value: string | number | null,
      keyValue: string,
      reportState: ReportState
    ): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(
        dispatch
      ).updateSHISETSUNYUSHOUsagePerformanceItemsDaily(
        name,
        value,
        keyValue,
        reportState
      ),
    updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily: (
      name: string,
      value: string | number | null,
      keyValue: string,
      reportState: ReportState
    ): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(
        dispatch
      ).updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsDaily(
        name,
        value,
        keyValue,
        reportState
      ),
    updateSHISETSUNYUSHOUsagePerformanceItemsUsers: (
      name: string,
      value: string | number | null,
      keyValue: string,
      reportState: ReportState
    ): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(
        dispatch
      ).updateSHISETSUNYUSHOUsagePerformanceItemsUsers(
        name,
        value,
        keyValue,
        reportState
      ),
    updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers: (
      name: string,
      value: string | number | null,
      keyValue: string,
      reportState: ReportState
    ): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(
        dispatch
      ).updateSHISETSUNYUSHOUsagePerformanceSHISETSUNYUSHOItemsUsers(
        name,
        value,
        keyValue,
        reportState
      ),
    resetInvalid: (): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(dispatch).resetInvalid(),
    updateInvalidTrue: (): Promise<void> =>
      SHISETSUNYUSHO.reportDispatcher(dispatch).updateInvalidTrue()
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsagePerformanceReportCellEdit));
