import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_HORIZONTAL_PAGE_HEIGHT,
  PRINT_HORIZONTAL_PAGE_WIDTH,
  PRINT_PAGE_PADDING
} from "@/constants/styles";
import { DISABILITY_CLASS_LIST, INCOME_KIND_LIST } from "@constants/variables";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import format from "date-fns/format";
import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";
import { PriorityPrintContent } from "@components/organisms/mgr/KEIKAKUSODAN/record/monitoringReport/PriorityPrintContent";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";
import { findOfficeName } from "@utils/domain/mgr/KEIKAKUSODAN/facility";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "nowrap"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)",
      whiteSpace: "nowrap"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 2,
      minHeight: 20
    },
    signatureWrapper: {
      display: "flex",
      width: "292px",
      height: "38px",
      border: "solid 2px #212121",
      marginLeft: "auto"
    },
    signature: {
      width: "100px",
      borderRight: "solid 1px #212121",
      fontSize: "10px",
      letterSpacing: "0.5px",
      textAlign: "center",
      lineHeight: "34px"
    },
    page: {
      minHeight: PRINT_HORIZONTAL_PAGE_HEIGHT,
      width: PRINT_HORIZONTAL_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING + 3}px 10px ${PRINT_PAGE_PADDING}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      "& tr:not(:first-of-type)": {
        overflowWrap: "anywhere"
      },
      "& td": {
        verticalAlign: "initial",
        padding: "3px 7px",
        borderRight: "1px solid",
        borderTop: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 20,
        "&.label": {
          fontSize: "10px",
          textAlign: "center",
          lineHeight: 1.4,
          letterSpacing: "0.5px",
          color: "#212121",
          width: "118px",
          backgroundColor: "#f5f5f5",
          whiteSpace: "nowrap"
        },
        "&.termLabel": {
          fontSize: "10px",
          lineHeight: 1.4,
          letterSpacing: "0.5px",
          color: "#212121",
          width: "85px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle"
        },
        "&.blankLabel": {
          borderTop: "none",
          width: "33px",
          backgroundColor: "#f5f5f5"
        }
      }
    },
    W62: {
      width: "62px"
    },
    W136: {
      width: "136px"
    },
    W288: {
      width: "288px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT23: {
      marginTop: "23px"
    },
    MT24: {
      marginTop: "24px"
    },
    ML16: {
      marginLeft: "16px"
    },
    otherTable: {
      border: "solid 2px #212121",
      display: "grid",
      gridTemplateColumns: "80px 1fr"
    },
    staffCommentWrap: {
      minWidth: "80px",
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      textAlign: "center",
      verticalAlign: "middle",
      borderRight: "solid 1px #212121"
    },
    staffComment: {
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      overflowWrap: "anywhere"
    }
  });

type OwnProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  monitoringReport: GetMonitoringReport["data"];
  isStaffComment: boolean;
  title: string;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const MonitoringReportPrintContentCore = (props: Props): JSX.Element => {
  const {
    classes,
    facility,
    user,
    title,
    monitoringReport,
    isStaffComment
  } = props;
  const userInFacility = user.user_in_facility;

  if (userInFacility.name_sei === undefined) {
    return <></>;
  }

  const isShogaijiSodan =
    monitoringReport.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN;

  // 利用者負担上限額
  const incomeKind = INCOME_KIND_LIST.find(
    (item) => item.value === monitoringReport.income_kind
  );

  const officeName = findOfficeName(
    facility,
    monitoringReport.type_consultation
  );

  const baseListItems = {
    userName: {
      label: "利用者氏名（フリガナ）",
      className: ClassNames(classes.W288),
      data: `${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`
    },
    classify_disability_support: {
      label: "障害支援区分",
      className: ClassNames(classes.W136, classes.ML16),
      data:
        DISABILITY_CLASS_LIST[
          monitoringReport.classify_disability_support
            ? Number(monitoringReport.classify_disability_support)
            : 0
        ].label
    },
    officeName: {
      label: "相談支援事業者名",
      className: ClassNames(classes.W288, !isShogaijiSodan && classes.ML16),
      data: officeName
    },
    author: {
      label: "計画作成担当者",
      className: ClassNames(classes.W288, classes.ML16),
      data: getSnapOrRealName(monitoringReport.author || 0, "")
    },
    guardian_name: {
      label: "保護者氏名",
      className: ClassNames(classes.W288, classes.ML16),
      data: monitoringReport.guardian_name
    },
    guardian_relation: {
      label: "本人との続柄",
      className: ClassNames(classes.W136, classes.ML16),
      data: monitoringReport.guardian_relation
    },
    disabled_welfare_service_recipient_number: {
      label: "障害福祉サービス受給者証番号",
      className: ClassNames(classes.W136),
      data: monitoringReport.disabled_welfare_service_recipient_number
    },
    incomeKind: {
      label: "利用者負担上限額",
      className: ClassNames(classes.W136, classes.ML16),
      data: incomeKind ? incomeKind.label : ""
    },
    consultation_support_recipient_number: {
      label: "地域相談支援受給者番号",
      className: ClassNames(classes.W136, classes.ML16),
      data: monitoringReport.consultation_support_recipient_number
    },
    regular_place_recipient_number: {
      label: "通所受給者証番号",
      className: ClassNames(classes.W136, classes.ML16),
      data: monitoringReport.regular_place_recipient_number
    },
    creation_date: {
      label: "計画作成日",
      className: ClassNames(classes.W136),
      data:
        monitoringReport.creation_date &&
        `${format(monitoringReport.creation_date, "YYYY年M月D日")}`
    },
    date_of_monitoring: {
      label: "モニタリング実施日",
      className: ClassNames(classes.W136, classes.ML16),
      data: `${format(monitoringReport.date_of_monitoring, "YYYY年M月D日")}`
    }
  };

  // 基本情報項目
  const baseList1 = [
    baseListItems.userName,
    baseListItems.classify_disability_support,
    isShogaijiSodan ? baseListItems.guardian_name : baseListItems.officeName,
    isShogaijiSodan ? baseListItems.guardian_relation : baseListItems.author
  ];
  const baseList2 = isShogaijiSodan
    ? [baseListItems.officeName, baseListItems.author]
    : [
        baseListItems.disabled_welfare_service_recipient_number,
        baseListItems.incomeKind,
        baseListItems.consultation_support_recipient_number,
        baseListItems.regular_place_recipient_number
      ];
  const baseList3 = isShogaijiSodan
    ? [
        baseListItems.disabled_welfare_service_recipient_number,
        baseListItems.incomeKind,
        baseListItems.consultation_support_recipient_number,
        baseListItems.regular_place_recipient_number
      ]
    : [baseListItems.creation_date, baseListItems.date_of_monitoring];

  const baseList4 = [
    baseListItems.creation_date,
    baseListItems.date_of_monitoring
  ];

  // 値がある優先順位のみ取り出す
  const filteredDetails = monitoringReport.details
    .map((detail, index) => ({ record: detail, number: index + 1 }))
    .filter((detail) => {
      return !Object.entries(detail.record).every(([key, value]) => {
        if (key === "id") {
          return true;
        }
        return !value;
      });
    });

  return (
    <div className={classes.page}>
      {/* タイトル */}
      <div className={classes.pageTitle}>
        <h2 className={classes.pageTitleTextB}>{title}</h2>
      </div>
      {/* 基本情報 */}
      <div className={ClassNames(classes.flex, classes.MT24)}>
        {baseList1.map((m, index) => {
          const uniqueKey = `uniqueBaseList1Key-${index}`;
          return (
            <div className={m.className && m.className} key={uniqueKey}>
              <div className={classes.label}>{m.label}</div>
              <div className={classes.con}>{m.data}</div>
            </div>
          );
        })}
      </div>
      <div className={ClassNames(classes.flex, classes.MT16)}>
        {baseList2.map((m, index) => {
          const uniqueKey = `uniqueBaseList2Key-${index}`;
          return (
            <div className={m.className && m.className} key={uniqueKey}>
              <div className={classes.label}>{m.label}</div>
              <div className={classes.con}>{m.data}</div>
            </div>
          );
        })}
      </div>
      <div className={ClassNames(classes.flex, classes.MT16)}>
        {baseList3.map((m, index) => {
          const uniqueKey = `uniqueBaseList3Key-${index}`;
          return (
            <div className={m.className && m.className} key={uniqueKey}>
              <div className={classes.label}>{m.label}</div>
              <div className={classes.con}>{m.data}</div>
            </div>
          );
        })}
        {!isShogaijiSodan && (
          <div className={classes.signatureWrapper}>
            <div className={classes.signature}>利用者同意署名欄</div>
            <></>
          </div>
        )}
      </div>
      {isShogaijiSodan && (
        <div className={ClassNames(classes.flex, classes.MT16)}>
          {baseList4.map((m, index) => {
            const uniqueKey = `uniqueBaseList3Key-${index}`;
            return (
              <div className={m.className && m.className} key={uniqueKey}>
                <div className={classes.label}>{m.label}</div>
                <div className={classes.con}>{m.data}</div>
              </div>
            );
          })}
          <div className={classes.signatureWrapper}>
            <div className={classes.signature}>利用者同意署名欄</div>
          </div>
          <div className={ClassNames(classes.ML16, classes.signatureWrapper)}>
            <div className={classes.signature}>保護者署名欄</div>
          </div>
        </div>
      )}
      {/* 計画の詳細 */}
      <table className={ClassNames(classes.table, classes.MT23)}>
        <tbody>
          <tr>
            <td className="label">総合的な援助の方針</td>
            <td className="label">全体の状況</td>
          </tr>
          <tr>
            <td>{monitoringReport.support_policy}</td>
            <td>{monitoringReport.whole_situation}</td>
          </tr>
        </tbody>
      </table>

      {/* 優先順位 */}
      {filteredDetails.length > 0 && (
        <div className={classes.MT16}>
          <PriorityPrintContent details={filteredDetails} />
        </div>
      )}
      {isStaffComment && (
        <div
          className={ClassNames(classes.flex, classes.MT16, classes.otherTable)}
        >
          <div className={classes.staffCommentWrap}>職員コメント</div>
          <div className={classes.staffComment}>
            {monitoringReport.staff_comment}
          </div>
        </div>
      )}
    </div>
  );
};

export const MonitoringReportPrintContent = withStyles(styles)(
  MonitoringReportPrintContentCore
);
