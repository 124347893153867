import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * ログインユーザーが当該事業所に切り替えが可能かどうか、承認状態を確認する
 */
export const getFacilitySwitchApproval = async (
  facility_id: number
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/user/approval/${facility_id}`;
  return request.get(url);
};

export default getFacilitySwitchApproval;
