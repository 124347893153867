import React, {
  Dispatch as reactDispatch,
  SetStateAction,
  useState
} from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import Checkbox from "@components/atoms/Checkbox";
import icon_ndlist_disabled from "@images/icon_ndlist_disabled.svg";
import icon_ndlist from "@images/icon_ndlist.svg";
import * as classNames from "classnames";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { FormikProps } from "formik";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { tableBodyInitialValues } from "@initialize/record/customRecordWithCategory/initialValues";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
  FacilityType,
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    margin: "0",
    "&:before": {
      display: "none"
    }
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    display: "contents",
    padding: "0"
  },
  content: {
    margin: "0",
    border: 0,
    boxShadow: "none",
    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    padding: "0"
  }
})(MuiExpansionPanelDetails);

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    tableCategory: {
      minWidth: 1030
    },
    tableItem: {
      minWidth: 1030,
      marginBottom: 24
    },
    tableItemLast: {
      minWidth: 1030
    },
    tableHeaderColor: {
      backgroundColor: "#90a4ae"
    },
    tableHeaderColorDisable: {
      backgroundColor: "#E4E8EA"
    },
    tableBodyCategory: {
      borderBottom: "1px solid white"
    },
    tableBodyItem: {
      borderBottom: "1px solid #e5e5e5"
    },
    tableHeaderCell: {
      padding: "0 0 0 16px",
      "&:last-child": {
        textAlign: "center",
        paddingRight: "12px"
      }
    },
    tableHeaderItemNameCell: {
      width: 356
    },
    tableHeaderInputTypeCell: {
      paddingLeft: spacing.unit * 3
    },
    tableHeaderChangeVisibilityCell: {
      width: "68px"
    },
    "@keyframes flash": {
      "0%": { backgroundColor: "#ebebeb" },
      "100%": { backgroundColor: "#fff" }
    },
    tableMovedRow: {
      animation: "flash 0.5s linear"
    },
    tableBodyCell: {
      padding: "8px 0 8px 8px",
      verticalAlign: "middle",
      "&:last-child": {
        padding: "9px 0 8px"
      }
    },
    tableBodyItemNameCell: {
      width: 356,
      paddingLeft: "16px"
    },
    tableBodyInputTypeCell: {
      display: "flex",
      height: spacing.unit * 8
    },
    tableBodyEditWrapper: {
      display: "flex",
      width: 240,
      margin: "auto 0"
    },
    tableBodyUnEditWrapper: {
      display: "flex",
      width: 100,
      fontSize: "12px",
      justifyContent: "center",
      alignItems: "center",
      color: "rgba(0, 0, 0, 0.38)"
    },
    tableBodyAddAndOrderCell: {
      width: 252,
      margin: "auto 0",
      marginLeft: "auto",
      marginRight: 0
    },
    changeItemOrderButton: {
      marginLeft: "8px",
      padding: "6px 0"
    },
    sortItemButton: {
      marginLeft: "8px",
      minHeight: 32
    },
    tableBodyEditDisabledCell: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.38)",
      lineHeight: "28px",
      paddingLeft: 26,
      margin: "auto 0 auto 24px"
    },
    tableBodyChangeVisibilityCell: {
      width: 80,
      textAlign: "right",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.38)"
    },
    tableBodyDisabledText: {
      lineHeight: 1.33,
      textAlign: "center",
      marginRight: "12px"
    },
    editButton: {
      minHeight: 32,
      height: spacing.unit * 4,
      margin: "auto 0",
      backgroundColor: "#fff"
    },
    editSaveButton: {
      marginLeft: 9,
      minHeight: 32,
      height: spacing.unit * 4,
      margin: "auto 0"
    },
    openHiddenListButton: {
      display: "flex",
      margin: "16px 0 0 auto"
    },
    ndlistIcon: {
      marginRight: 6
    },
    tooltipWrap: {
      display: "flex",
      alignItems: "center"
    },
    tooltip: {
      margin: "7px 0 0 16px"
    },
    arrowIconWrap: {
      height: "47px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginRight: "22px"
    },
    arrowIconButton: {
      width: "36px",
      minWidth: "36px",
      height: "20px",
      minHeight: "20px",
      backgroundColor: "#fff"
    },
    downwardIcon: {
      "&:first-child": {
        marginTop: "27px"
      }
    },
    arrowIcon: {
      fontSize: "22px"
    },
    unChangeOrderWrapper: {
      display: "flex",
      fontSize: "12px",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.38)"
    },
    categoryCell: {
      backgroundColor: "#eceff1"
    },
    borderBottomNone: {
      "&:first-child": {
        borderBottom: "none"
      }
    },
    tableHead: {
      borderBottom: "1px solid #fff"
    },
    invisibleAllCategory: {
      display: "flex",
      alignItems: "center",
      height: 64,
      borderBottom: "1px solid #e5e5e5"
    },
    invisibleAllCategoryText: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      marginLeft: 16
    }
  });

type OwnProps = {
  isCategorized: boolean;
  openAddModal: (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number,
    custom_record_item_choices?: {
      id: number;
      custom_record_item_id: number;
      name: string;
      default_choice: number | null;
      hidden: number;
      default_choice_input_type: number | null;
    }[]
  ) => void;
  changeEditMode: (
    id: number,
    type: "" | "category" | "item",
    record: CustomRecordsWithCategoryState[number]
  ) => void;
  openHideConfirmModal: (id: number, type: "" | "category" | "item") => void;
  openHiddenCustomRecordsList: () => void;
  settingType: number;
  isSortingCategory: boolean;
  editedId: number;
  editedType: "" | "category" | "item";
  formikProps: FormikProps<CustomRecordsWithCategoryState>;
  formValues: CustomRecordsWithCategoryState;
  endEdit: () => void;
  sortingItemType: number | null;
  setSortingItemType: reactDispatch<SetStateAction<number | null>>;
  invisibleCustomRecordExist: boolean;
  stopHistory: (flag: boolean) => void;
  facilityType: FacilityType;
  setEvaluationPeriodDisabled: (disabled: boolean) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CustomRecordsTableCore = (props: Props): JSX.Element => {
  const {
    isCategorized,
    classes,
    openAddModal,
    changeEditMode,
    openHideConfirmModal,
    openHiddenCustomRecordsList,
    settingType,
    isSortingCategory,
    editedId,
    editedType,
    formikProps,
    formValues,
    endEdit,
    sortingItemType,
    setSortingItemType,
    invisibleCustomRecordExist,
    stopHistory,
    facilityType,
    setEvaluationPeriodDisabled
  } = props;

  const { values } = formikProps;

  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  React.useEffect(() => {
    formikProps.setValues(formValues);
  }, [formValues]);

  const [customRecordItemChoices, setCustomRecordItemChoices] = useState<
    {
      id: number;
      custom_record_item_id: number;
      name: string;
      default_choice: number | null;
      hidden: number;
      default_choice_input_type: number | null;
    }[]
  >([]);
  // 項目追加の際に渡すcustom_record_item_choicesを、そのカテゴリの最初の値を代表としてとっておく
  React.useEffect(() => {
    if (values.length > 0) {
      if (values[0].custom_record_items.length > 0) {
        setCustomRecordItemChoices(
          values[0].custom_record_items[0].custom_record_item_choices
        );
      }
    }
  }, [values]);

  // テーブルヘッダー用のデータ
  const headerData: KnowbeTableCellParam[] = [
    {
      label: isCategorized ? "カテゴリー / 項目名" : "項目名",
      className: classNames(
        editedId || (sortingItemType && isCategorized)
          ? classes.tableHeaderColorDisable
          : classes.tableHeaderColor,
        classes.tableHeaderCell,
        classes.tableHeaderItemNameCell
      )
    },
    {
      label: "",
      className: classNames(
        editedId || (sortingItemType && isCategorized)
          ? classes.tableHeaderColorDisable
          : classes.tableHeaderColor,
        classes.tableHeaderCell,
        classes.tableHeaderInputTypeCell
      )
    },
    {
      label: isSortingCategory ? "並び順" : "表示",
      className: classNames(
        editedId || (sortingItemType && isCategorized)
          ? classes.tableHeaderColorDisable
          : classes.tableHeaderColor,
        classes.tableHeaderCell,
        classes.tableHeaderChangeVisibilityCell
      )
    }
  ];

  const openHiddenCustomRecordsListModal = (): void => {
    openHiddenCustomRecordsList();
  };

  // state（並べ替えボタンが押された項目にアニメーションCSS用のclassを付与する用途）
  const [movedRowId, setMovedRow] = React.useState(0);

  React.useEffect(() => {
    // 0.5s後にデフォルトに戻す（同位置のボタンが押されたときにもアニメーションが起こるようにする）
    if (movedRowId !== 0) {
      setTimeout(setMovedRow, 500, 0);
    }
  }, [movedRowId]);

  // 並べ替え可能最初の項目
  const firstCategory = (():
    | CustomRecordsWithCategoryState[number]
    | undefined => {
    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
      case FacilityType.CHIIKIIKO:
      case FacilityType.CHIIKITEICHAKU:
        return values.filter((v) => v.order !== null).find((v) => v.visibility);
      default:
        return values.find((record) => record.visibility);
    }
  })();

  // 並べ替え可能最後の項目
  const lastCategory = (():
    | CustomRecordsWithCategoryState[number]
    | undefined => {
    const reversedValues = [...values].reverse();
    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
      case FacilityType.CHIIKIIKO:
      case FacilityType.CHIIKITEICHAKU:
        return reversedValues
          .filter((v) => v.order !== null)
          .find((v) => v.visibility);
      default:
        return reversedValues.find((record) => record.visibility);
    }
  })();

  // 項目名変更時の処理
  const handleChangeEditField = () => (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    // 保存ボタン状態管理
    const { value } = e.target;
    setSubmitButtonDisabled(value === "");
  };

  // 編集キャンセルボタン押下
  const onSubmitEditCancel = (): void => {
    setSubmitButtonDisabled(false);
    endEdit();
    stopHistory(false);
    setEvaluationPeriodDisabled(false);
  };

  // 並び替えキャンセルボタン押下
  const onSubmitSortCancel = (): void => {
    setSortingItemType(null);
    stopHistory(false);
    setEvaluationPeriodDisabled(false);
  };

  // 記録項目データ（テーブル表示用）
  const tableBody = values.map((record, index, self) => {
    const onClickSortingButton = (
      e: React.MouseEvent<HTMLInputElement>
    ): void => {
      const { value } = e.currentTarget;
      const orderedArray = [...self];

      if (value === "up") {
        // 配列の並び順を入れ替える
        orderedArray.splice(index - 1, 2, self[index], self[index - 1]);
      }
      if (value === "down") {
        // 配列の並び順を入れ替える
        orderedArray.splice(index, 2, self[index + 1], self[index]);
      }

      formikProps.setValues(tableBodyInitialValues(orderedArray));
      setMovedRow(record.id);
    };

    let visibilityOrOrderDom;
    if (isSortingCategory) {
      if (
        (facilityType === FacilityType.KEIKAKUSODAN ||
          facilityType === FacilityType.CHIIKIIKO ||
          facilityType === FacilityType.CHIIKITEICHAKU) &&
        record.allow_change_order === INT_FALSE_FROM_API
      ) {
        visibilityOrOrderDom = (
          <div className={classes.unChangeOrderWrapper}>
            並び替え
            <br />
            不可
          </div>
        );
      } else {
        visibilityOrOrderDom = (
          <div className={classes.arrowIconWrap}>
            {!!firstCategory && firstCategory.id !== record.id && (
              <KnowbeButton
                className={classes.arrowIconButton}
                kind="outline"
                value="up"
                onClick={onClickSortingButton}
              >
                <ArrowUpwardIcon className={classes.arrowIcon} />
              </KnowbeButton>
            )}
            {!!lastCategory && lastCategory.id !== record.id && (
              <KnowbeButton
                className={classNames(
                  classes.downwardIcon,
                  classes.arrowIconButton
                )}
                kind="outline"
                value="down"
                onClick={onClickSortingButton}
              >
                <ArrowDownwardIcon className={classes.arrowIcon} />
              </KnowbeButton>
            )}
          </div>
        );
      }
    } else {
      visibilityOrOrderDom = (
        <Checkbox
          label=""
          value={`${record.id}`}
          checked
          labelStyle={{
            width: 56,
            height: 46,
            marginRight: 12,
            justifyContent: "center"
          }}
          disabled={
            (editedId === record.id && editedType === "category") ||
            sortingItemType === record.id
          }
          onChange={(): void => {
            openHideConfirmModal(record.id, "category");
          }}
        />
      );
    }

    // カテゴリー行の書き出し
    const changeCategoryEditMode = (): void => {
      changeEditMode(record.id, "category", record);
      setEvaluationPeriodDisabled(true);
    };
    const makeCategoryEditLabel = (): React.ReactElement => {
      if (isSortingCategory) {
        return <></>;
      }
      if (!editedId || !(editedId === record.id && editedType === "category")) {
        return (
          <>
            {(facilityType === FacilityType.KEIKAKUSODAN ||
              facilityType === FacilityType.CHIIKIIKO ||
              facilityType === FacilityType.CHIIKITEICHAKU) &&
            record.allow_edit_name === INT_FALSE_FROM_API ? (
              <div className={classes.tableBodyUnEditWrapper}>編集不可</div>
            ) : (
              <div className={classes.tableBodyEditWrapper}>
                <KnowbeButton
                  minWidth={100}
                  kind="outline"
                  onClick={changeCategoryEditMode}
                  className={classes.editButton}
                  disabled={sortingItemType === record.id}
                >
                  編集
                </KnowbeButton>
              </div>
            )}
            <div className={classes.tableBodyAddAndOrderCell}>
              {sortingItemType && sortingItemType === record.id ? (
                <>
                  <KnowbeButton
                    value={settingType}
                    minWidth={120}
                    kind="outline"
                    onClick={onSubmitSortCancel}
                    className={classes.editButton}
                  >
                    キャンセル
                  </KnowbeButton>
                  <FormikSubmitButton
                    className={classes.sortItemButton}
                    minWidth={120}
                    buttonName="確定する"
                    formikProps={formikProps}
                  />
                </>
              ) : (
                <>
                  {(facilityType === FacilityType.KEIKAKUSODAN ||
                    facilityType === FacilityType.CHIIKIIKO ||
                    facilityType === FacilityType.CHIIKITEICHAKU) &&
                  record.allow_add_items === INT_FALSE_FROM_API ? (
                    <></>
                  ) : (
                    <KnowbeButton
                      value={settingType}
                      minWidth={120}
                      style={{ minHeight: 32 }}
                      onClick={(): void =>
                        openAddModal(
                          "item",
                          record.category_type,
                          record.id,
                          customRecordItemChoices
                        )
                      }
                    >
                      項目の追加
                    </KnowbeButton>
                  )}
                  {(facilityType === FacilityType.KEIKAKUSODAN ||
                    facilityType === FacilityType.CHIIKIIKO ||
                    facilityType === FacilityType.CHIIKITEICHAKU) &&
                  record.change_items_order_button_display_flg ===
                    INT_FALSE_FROM_API ? (
                    <></>
                  ) : (
                    <KnowbeButton
                      className={classes.changeItemOrderButton}
                      value={settingType}
                      minWidth={120}
                      style={{ minHeight: 32 }}
                      onClick={(): void => {
                        setSortingItemType(record.id);
                        setEvaluationPeriodDisabled(true);
                      }}
                      disabled={record.custom_record_items.length <= 1}
                    >
                      項目の並べ替え
                    </KnowbeButton>
                  )}
                </>
              )}
            </div>
          </>
        );
      }
      return (
        <>
          <div className={classes.tableBodyEditWrapper}>
            <KnowbeButton
              minWidth={100}
              kind="outline"
              onClick={onSubmitEditCancel}
              className={classes.editButton}
            >
              キャンセル
            </KnowbeButton>
            <FormikSubmitButton
              minWidth={100}
              buttonName="保存する"
              formikProps={formikProps}
              className={classes.editSaveButton}
              disabled={isSubmitButtonDisabled}
            />
          </div>
          <div className={classes.tableBodyAddAndOrderCell}>
            <KnowbeButton
              value={settingType}
              minWidth={120}
              style={{ minHeight: 32 }}
              disabled
            >
              項目の追加
            </KnowbeButton>
            <KnowbeButton
              className={classes.changeItemOrderButton}
              value={settingType}
              minWidth={120}
              style={{ minHeight: 32 }}
              disabled
            >
              項目の並べ替え
            </KnowbeButton>
          </div>
        </>
      );
    };
    const categoryBody = !isCategorized
      ? []
      : [
          [
            {
              label: (
                <>
                  {editedId &&
                  editedId === record.id &&
                  editedType === "category" ? (
                    <div className={classes.tooltipWrap}>
                      <FormikTextField
                        maxLength={20}
                        name={`[${index}].name`}
                        size="largeMedium"
                        style={{ marginBottom: -1, width: 320 }}
                        onChangeHook={handleChangeEditField()}
                      />
                    </div>
                  ) : (
                    record.name
                  )}
                </>
              ),
              className: classNames(
                (editedId &&
                  !(editedId === record.id && editedType === "category")) ||
                  (sortingItemType && sortingItemType !== record.id)
                  ? classes.unEditable
                  : classes.Editable,
                classes.categoryCell,
                classes.tableBodyCell,
                classes.tableBodyItemNameCell,
                movedRowId === record.id && classes.tableMovedRow
              )
            },
            {
              label: makeCategoryEditLabel(),
              className: classNames(
                (editedId &&
                  !(editedId === record.id && editedType === "category")) ||
                  (sortingItemType && sortingItemType !== record.id)
                  ? classes.unEditable
                  : classes.Editable,
                classes.categoryCell,
                classes.tableBodyCell,
                classes.tableBodyInputTypeCell,
                movedRowId === record.id && classes.tableMovedRow
              )
            },
            {
              label: visibilityOrOrderDom,
              className: classNames(
                (editedId &&
                  !(editedId === record.id && editedType === "category")) ||
                  (sortingItemType && sortingItemType !== record.id)
                  ? classes.unEditable
                  : classes.Editable,
                classes.categoryCell,
                classes.tableBodyCell,
                classes.tableBodyChangeVisibilityCell,
                movedRowId === record.id && classes.tableMovedRow
              )
            }
          ]
        ];

    // 項目行の書き出し
    const firstItem = (():
      | CustomRecordsWithCategoryState[number]["custom_record_items"][number]
      | undefined => {
      switch (facilityType) {
        case FacilityType.KEIKAKUSODAN:
        case FacilityType.CHIIKIIKO:
        case FacilityType.CHIIKITEICHAKU:
          return record.custom_record_items
            .filter((r) => r.order !== null)
            .find((r) => r.visibility);
        default:
          return record.custom_record_items.find((r) => r.visibility);
      }
    })();

    const lastItem = (():
      | CustomRecordsWithCategoryState[number]["custom_record_items"][number]
      | undefined => {
      const reversedValues = [...record.custom_record_items].reverse();
      switch (facilityType) {
        case FacilityType.KEIKAKUSODAN:
        case FacilityType.CHIIKIIKO:
        case FacilityType.CHIIKITEICHAKU:
          return reversedValues
            .filter((r) => r.order !== null)
            .find((r) => r.visibility);
        default:
          return reversedValues.find((r) => r.visibility);
      }
    })();

    const itemBody = record.custom_record_items.map((item, idx, selfItem) => {
      const onClickItemSortingButton = (
        e: React.MouseEvent<HTMLInputElement>
      ): void => {
        const { value } = e.currentTarget;
        const orderedItem = [...selfItem];
        if (value === "up") {
          // 配列の並び順を入れ替える
          orderedItem.splice(idx - 1, 2, selfItem[idx], selfItem[idx - 1]);
        }
        if (value === "down") {
          // 配列の並び順を入れ替える
          orderedItem.splice(idx, 2, selfItem[idx + 1], selfItem[idx]);
        }
        const orderedRecord = self.map((s) => {
          return s.id === item.custom_records_category_id
            ? { ...s, custom_record_items: orderedItem }
            : s;
        });
        formikProps.setValues(tableBodyInitialValues(orderedRecord));
        setMovedRow(item.id);
      };
      const changeItemEditMode = (): void => {
        changeEditMode(item.id, "item", record);
        setEvaluationPeriodDisabled(true);
      };
      let itemVisibilityOrOrderDom: JSX.Element;
      if (sortingItemType === item.custom_records_category_id) {
        if (
          (facilityType === FacilityType.KEIKAKUSODAN ||
            facilityType === FacilityType.CHIIKIIKO ||
            facilityType === FacilityType.CHIIKITEICHAKU) &&
          item.allow_change_order === INT_FALSE_FROM_API
        ) {
          itemVisibilityOrOrderDom = (
            <div className={classes.unChangeOrderWrapper}>
              並び替え
              <br />
              不可
            </div>
          );
        } else {
          itemVisibilityOrOrderDom = (
            <div className={classes.arrowIconWrap}>
              {!!firstItem && firstItem.id !== item.id && (
                <KnowbeButton
                  className={classes.arrowIconButton}
                  kind="outline"
                  value="up"
                  onClick={onClickItemSortingButton}
                >
                  <ArrowUpwardIcon className={classes.arrowIcon} />
                </KnowbeButton>
              )}
              {!!lastItem && lastItem.id !== item.id && (
                <KnowbeButton
                  className={classNames(
                    classes.downwardIcon,
                    classes.arrowIconButton
                  )}
                  kind="outline"
                  value="down"
                  onClick={onClickItemSortingButton}
                >
                  <ArrowDownwardIcon className={classes.arrowIcon} />
                </KnowbeButton>
              )}
            </div>
          );
        }
      } else {
        itemVisibilityOrOrderDom =
          (facilityType === FacilityType.A ||
            facilityType === FacilityType.B ||
            facilityType === FacilityType.IKOU ||
            ((facilityType === FacilityType.KEIKAKUSODAN ||
              facilityType === FacilityType.CHIIKIIKO ||
              facilityType === FacilityType.CHIIKITEICHAKU) &&
              item.allow_change_visibility === INT_FALSE_FROM_API)) &&
          item.input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.others ? (
            <></>
          ) : (
            <Checkbox
              label=""
              value={`${item.id}`}
              checked
              labelStyle={{
                width: 56,
                height: 46,
                marginRight: 12,
                justifyContent: "center"
              }}
              disabled={editedId === item.id && editedType === "item"}
              onChange={(): void => {
                openHideConfirmModal(item.id, "item");
              }}
            />
          );
      }
      const makeItemEditLabel = (): React.ReactElement => {
        if (
          isSortingCategory ||
          (sortingItemType && sortingItemType === record.id)
        ) {
          return <></>;
        }
        if (!editedId || !(editedId === item.id && editedType === "item")) {
          return item.allow_edit_name === 1 ? (
            <>
              <div className={classes.tableBodyEditWrapper}>
                <KnowbeButton
                  minWidth={100}
                  kind="outline"
                  onClick={changeItemEditMode}
                  className={classes.editButton}
                >
                  編集
                </KnowbeButton>
              </div>
            </>
          ) : (
            <div className={classes.tableBodyUnEditWrapper}>編集不可</div>
          );
        }
        return (
          <div className={classes.tableBodyEditWrapper}>
            <KnowbeButton
              minWidth={100}
              kind="outline"
              onClick={onSubmitEditCancel}
              className={classes.editButton}
            >
              キャンセル
            </KnowbeButton>
            <FormikSubmitButton
              minWidth={100}
              buttonName="保存する"
              formikProps={formikProps}
              className={classes.editSaveButton}
              disabled={isSubmitButtonDisabled}
            />
          </div>
        );
      };
      return [
        {
          label: (
            <>
              {editedId && editedId === item.id && editedType === "item" ? (
                <div className={classes.tooltipWrap}>
                  <FormikTextField
                    maxLength={20}
                    name={`[${index}].custom_record_items[${idx}].name`}
                    size="largeMedium"
                    style={{ marginBottom: -1, width: 320 }}
                    onChangeHook={handleChangeEditField()}
                  />
                </div>
              ) : (
                item.name
              )}
            </>
          ),
          className: classNames(
            (editedId && !(editedId === item.id && editedType === "item")) ||
              (sortingItemType && sortingItemType !== record.id)
              ? classes.unEditable
              : classes.Editable,
            classes.tableBodyCell,
            classes.tableBodyItemNameCell,
            movedRowId === item.id && classes.tableMovedRow
          )
        },
        {
          label: makeItemEditLabel(),
          className: classNames(
            (editedId && !(editedId === item.id && editedType === "item")) ||
              (sortingItemType && sortingItemType !== record.id)
              ? classes.unEditable
              : classes.Editable,
            classes.tableBodyCell,
            classes.tableBodyInputTypeCell,
            movedRowId === item.id && classes.tableMovedRow
          )
        },
        {
          label: itemVisibilityOrOrderDom,
          className: classNames(
            (editedId && !(editedId === item.id && editedType === "item")) ||
              (sortingItemType && sortingItemType !== record.id)
              ? classes.unEditable
              : classes.Editable,
            classes.tableBodyCell,
            classes.tableBodyChangeVisibilityCell,
            movedRowId === item.id && classes.tableMovedRow
          )
        }
      ];
    });
    return (
      <ExpansionPanel expanded={!isSortingCategory} key={`panel_${record.id}`}>
        {isCategorized && (
          <ExpansionPanelSummary key={`panelSummary_${record.id}`}>
            <KnowbeTable
              className={classes.tableCategory}
              key={`tableCategory_${record.id}`}
            >
              <KnowbeTableBody
                className={classes.tableBodyCategory}
                key={`customRecordsTableCategory_${record.id}`}
                uniqueKey="customRecordsTableCategory"
                itemsContainer={categoryBody}
                stripe={false}
                rowClassName={classes.borderBottomNone}
              />
            </KnowbeTable>
          </ExpansionPanelSummary>
        )}
        <ExpansionPanelDetails key={`panelDetails_${record.id}`}>
          <KnowbeTable
            className={
              !!lastCategory && lastCategory.id !== record.id
                ? classes.tableItem
                : classes.tableItemLast
            }
            key={`tableItem_${record.id}`}
          >
            <KnowbeTableBody
              className={classes.tableBodyItem}
              key={`customRecordsTableItem_${record.id}`}
              uniqueKey="customRecordsTableItem"
              itemsContainer={itemBody}
              stripe={false}
            />
          </KnowbeTable>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    );
  });

  const hiddenListButtonDisabled = !invisibleCustomRecordExist;
  const hiddenListUnEditable = isSortingCategory || sortingItemType || editedId;
  const isInvisibleAllRecord = ((): boolean => {
    if (formValues.length === 0) {
      return true;
    }
    const target = isCategorized
      ? formValues.map((v) => v.visibility)
      : formValues[0].custom_record_items.map((v) => v.visibility);
    return !target.some((visibility) => visibility === INT_TRUE_FROM_API);
  })();

  return (
    <>
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead
          uniqueKey="customRecordsTable"
          height={48}
          items={headerData}
          headerClassName={classes.tableHead}
        />
      </KnowbeTable>
      {tableBody}
      {isInvisibleAllRecord && (
        <div className={classes.invisibleAllCategory}>
          <p className={classes.invisibleAllCategoryText}>
            {`全ての${
              isCategorized ? "カテゴリー / " : ""
            }項目が非表示に設定されています。`}
          </p>
        </div>
      )}
      <KnowbeButton
        className={classNames(
          classes.openHiddenListButton,
          hiddenListUnEditable ? classes.unEditable : classes.editable
        )}
        onClick={openHiddenCustomRecordsListModal}
        kind="text"
        disabled={hiddenListButtonDisabled}
      >
        {hiddenListButtonDisabled ? (
          <img
            src={icon_ndlist_disabled}
            className={classes.ndlistIcon}
            alt=""
          />
        ) : (
          <img src={icon_ndlist} className={classes.ndlistIcon} alt="" />
        )}
        非表示項目リスト
      </KnowbeButton>
    </>
  );
};

export const CustomRecordsTable = withStyles(styles)(CustomRecordsTableCore);
