import { InitialDataValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";

export const REPORT_DAILY = "reportDaily";
export const REPORT_MONTHLY = "reportMonthly";

/**
 * ActionNames
 */
export const FETCH_KEIKAKUSODAN_DAILY_STARTED =
  "KEIKAKUSODAN/REPORT_202104/FETCH_DAILY_STARTED";
export const FETCH_KEIKAKUSODAN_DAILY =
  "KEIKAKUSODAN/REPORT_202104/FETCH_DAILY";
export const FETCH_KEIKAKUSODAN_DAILY_FAILED =
  "KEIKAKUSODAN/REPORT_202104/FETCH_FAILED";
export const FETCH_KEIKAKUSODAN_MONTHLY_STARTED =
  "KEIKAKUSODAN/REPORT_202104/FETCH_MONTHLY_STARTED";
export const FETCH_KEIKAKUSODAN_MONTHLY =
  "KEIKAKUSODAN/REPORT_202104/FETCH_MONTHLY";
export const FETCH_KEIKAKUSODAN_MONTHLY_FAILED =
  "KEIKAKUSODAN/REPORT_202104/FETCH_MONTHLY_FAILED";
export const POST_KEIKAKUSODAN_REPORT_STARTED =
  "KEIKAKUSODAN/REPORT_202104/POST_STARTED";
export const POST_KEIKAKUSODAN_REPORT_DAILY =
  "KEIKAKUSODAN/REPORT_202104/POST_REPORT_DAILY";
export const POST_KEIKAKUSODAN_REPORT_MONTHLY =
  "KEIKAKUSODAN/REPORT_202104/POST_REPORT_MONTHLY";
export const POST_KEIKAKUSODAN_REPORT_FAILED =
  "KEIKAKUSODAN/REPORT_202104/POST_REPORT_FAILED";
export const POST_KEIKAKUSODAN_FACILITY_MONTHLY_STARTED =
  "KEIKAKUSODAN/REPORT_202104/POST_FACILITY_MONTHLY_STARTED";
export const POST_KEIKAKUSODAN_FACILITY_MONTHLY =
  "KEIKAKUSODAN/REPORT_202104/POST_FACILITY_MONTHLY";
export const POST_KEIKAKUSODAN_FACILITY_MONTHLY_FAILED =
  "KEIKAKUSODAN/REPORT_202104/POST_FACILITY_MONTHLY_FAILED";

export type KEIKAKUSODANReportTypeInterface = {
  type: "reportDaily" | "reportMonthly";
};

export type InoutConsultationResultsState = {
  id: number | null;
  usersInFacilityId: number;
  targetDate: string;
  isHoliday: boolean;
  nameSei: string;
  nameMei: string;
  inoutResultDailyId: number | null;
  typeConsultation: number;
  status: number | null;
  firstAdditionFlg: boolean;
  firstAdditionSpan: number;
  intensiveSupport1AdditionFlg: boolean;
  intensiveSupport2AdditionFlg: boolean;
  intensiveSupport3AdditionFlg: boolean;
  inhomeNursingCareSupportOfficeRelation1Flg: boolean;
  inhomeNursingCareSupportOfficeRelation2Flg: boolean;
  inhomeNursingCareSupportOfficeRelation3Flg: boolean;
  inhomeNursingCareSupportOfficeRelation4Flg: boolean;
  inhomeNursingCareSupportOfficeRelation5Flg: boolean;
  inhomeNursingCareSupportOfficeRelation6Flg: boolean;
  dischargeAdditionFlg: boolean;
  sameInhomeNursingCareSupportFlg: boolean;
  samePreventiveCareSupportFlg: boolean;
  hospitalizationRelationAddition: number;
  medicalDaycareEducationRelationFlg: boolean;
  servicePersonnelMeetingHeldFlg: boolean;
  serviceProvisionMonitoringFlg: boolean;
  lifeSupportPromotionFlg: boolean;
  systemPromotionFlg: boolean;
  childcareTransitionSupport1Flg: boolean;
  childcareTransitionSupport2Flg: boolean;
  childcareTransitionSupport3Flg: boolean;
  memo: string | null;
};

export type ReportDailySummary = {
  serviceUseCount: number | null;
  continuousServiceUseCount: number | null;
  nextMonthUse2LimitCount: number | null;
  monitoringCount: number | null;
};

export type ReportMonthlySummary = {
  inhomeNursingCareSupportOfficeRelation1Count: number;
  inhomeNursingCareSupportOfficeRelation2Count: number;
  inhomeNursingCareSupportOfficeRelation3Count: number;
  inhomeNursingCareSupportOfficeRelation4Count: number;
  inhomeNursingCareSupportOfficeRelation5Count: number;
  inhomeNursingCareSupportOfficeRelation6Count: number;
  inhomeNursingCareSupportOfficeRelationLimit: number;
  lifeSupportPromotionCount: number;
  dischargeAdditionCount: number;
  childcareTransitionSupport1Count: number;
  childcareTransitionSupport2Count: number;
  childcareTransitionSupport3Count: number;
};

export type ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: string | null;
      numberOfEmployees: number | null;
      numberOfHandicappedChild: number | null;
      numberOfKeikakusodan: number | null;
      trainingByChiefFlg: boolean;
      trainingByChiefShogaijiFlg: boolean;
    };
    inoutConsultationResults: InoutConsultationResultsState[];
    summary: ReportDailySummary;
  };
  reportMonthly: {
    summary: ReportMonthlySummary;
    inoutConsultationResults: InoutConsultationResultsState[];
  };
};

export type FacilitiesKEIKAKUSODANMonthlyType = {
  checked: boolean;
  data: InitialDataValues;
  selectedDate: Date;
  numberOfEmployees: string;
  numberOfHandicappedChild: string;
  numberOfKeikakusodan: string;
  changeTrainingByChiefFlg: boolean;
  trainingByChiefShogaijiFlg: boolean;
  facility: FacilityState;
};
