import { GetOperations } from "@api/requests/operations/Cseg/getOperations";

/**
 * ActionNames
 */
export const FETCH_OPERATIONS_STARTED = "OPERATIONS/FETCH_OPERATIONS_STARTED";
export const FETCH_OPERATIONS_SUCCESS = "OPERATIONS/FETCH_OPERATIONS_SUCCESS";
export const FETCH_OPERATIONS_FAILED = "OPERATIONS/FETCH_OPERATIONS_FAILED";
export const UNSET_OPERATIONS = "OPERATIONS/UNSET_OPERATIONS";

/**
 * State
 */
export type OperationsState = GetOperations["data"];
