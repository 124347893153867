import { RadioItemInterface } from "@components/atoms/RadioButtons";

/**
 * 定数列挙型enumをもとにラジオボタン用のオブジェクトを生成する
 */
export function generateRadioItems(types: {
  [s: number]: string;
}): RadioItemInterface[] {
  const keys = Object.keys(types).filter((k) => typeof types[k] === "number");
  return keys.map((key) => {
    return {
      label: key,
      value: types[key].toString()
    };
  });
}
