import * as React from "react";
import { useEffect } from "react";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import MuiSelect from "@components/molecules/MuiSelect";
import {
  dateInHyphenYYYYMMDDFormat,
  dateToSelectDateValue,
  getWarekiList,
  dateInYYYYFormat
} from "@utils/date";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

type DateValue = {
  year: string;
  month: string;
};

type Props = {
  label: string;
  onChangeSelect: (value: DateValue) => void;
  addYearTo?: number;
  overrideYearFrom?: number;
  overrideYearTo?: number;
  defaultDate?: DateValue;
  isReset?: boolean;
  setIsReset?: React.Dispatch<React.SetStateAction<boolean>>;
};

const NOT_SELECTED_ERROR_TEXT = "選択してください";

export const DateSelectYearMonth = (props: Props): JSX.Element => {
  const { isReset, setIsReset, defaultDate } = props;
  const currentYear = +dateInYYYYFormat(new Date());
  const initialDate =
    props.defaultDate ||
    React.useMemo(() => {
      const date = dateInHyphenYYYYMMDDFormat(new Date());
      return dateToSelectDateValue(date);
    }, []);
  const [targetMonth, setTargetMonth] = React.useState<{
    year: string;
    month: string;
  }>({ year: initialDate.year, month: initialDate.month });
  const defaultSelect = {
    label: NOT_SELECTED_ERROR_TEXT,
    value: DEFAULT_SELECT_VALUE
  };
  const yearFrom = currentYear - 86;
  const from = props.overrideYearFrom || yearFrom;
  const to = props.overrideYearTo || currentYear + (props.addYearTo || 0);
  const yearOptions = [defaultSelect, ...getWarekiList(from, to)];
  const monthOptions = createOneToNumberOptions(12, "月");

  useEffect(() => {
    if (!isReset || !setIsReset || !defaultDate) return;
    setTargetMonth({ year: defaultDate.year, month: defaultDate.month });
    setIsReset(false);
  }, [isReset, setIsReset, defaultDate]);

  const [hasErrorYear, setHasErrorYear] = React.useState<boolean>(true);
  const [hasErrorMonth, setHasErrorMonth] = React.useState<boolean>(true);
  useEffect(() => {
    const noYear = targetMonth.year === DEFAULT_SELECT_VALUE;
    const noMonth = !targetMonth.month;
    setHasErrorYear(noYear && !noMonth);
    setHasErrorMonth(!noYear && noMonth);
  }, [targetMonth]);

  return (
    <div
      style={{
        display: "flex",
        marginBottom: 0
      }}
    >
      <MuiSelect
        name="targetYear"
        label={props.label}
        size="medium"
        value={targetMonth.year}
        options={yearOptions}
        onChange={(e): void => {
          setTargetMonth((prev) => ({
            ...prev,
            year: e.target.value
          }));
          props.onChangeSelect({
            year: e.target.value,
            month: targetMonth.month
          });
        }}
        style={{ width: "240px", minWidth: "240px", marginBottom: 0 }}
        helperText={hasErrorYear ? NOT_SELECTED_ERROR_TEXT : ""}
        error={hasErrorYear}
      />
      <MuiSelect
        name="targetMonth"
        label="月"
        shrink={false}
        size="superSmall"
        value={targetMonth.month}
        options={monthOptions}
        onChange={(e): void => {
          setTargetMonth((prev) => ({
            ...prev,
            month: e.target.value
          }));
          props.onChangeSelect({
            year: targetMonth.year,
            month: e.target.value
          });
        }}
        style={{ marginBottom: 0, width: "144px" }}
        helperText={hasErrorMonth ? NOT_SELECTED_ERROR_TEXT : ""}
        error={hasErrorMonth}
      />
    </div>
  );
};
