import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import getSupportsRecord from "@api/requests/supports/TANKINYUSHO/getSupportRecordUser";

export const fetchSupportRecordUser = (dispatch: Dispatch) => async (
  uifId: string,
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportRecordUserStarted());
  await getSupportsRecord(uifId, year, month)
    .then((res) => {
      dispatch(actions.fetchSupportRecordUserSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportRecordUserFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchSupportRecordUser: (
    uifId: string,
    year: string,
    month: string
  ) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchSupportRecordUser: fetchSupportRecordUser(dispatch)
});
