/**
 * ActionNames
 */
export const FETCH_KODOENGO_CARE_PLAN_STARTED =
  "KODOENGO/FETCH_CARE_PLAN_STARTED";
export const FETCH_KODOENGO_CARE_PLAN = "KODOENGO/FETCH_CARE_PLAN";
export const FETCH_KODOENGO_CARE_PLAN_FAILED =
  "KODOENGO/FETCH_CARE_PLAN_FAILED";

export const POST_KODOENGO_CARE_PLAN_STARTED =
  "KODOENGO/POST_CARE_PLAN_STARTED";
export const POST_KODOENGO_CARE_PLAN = "KODOENGO/POST_CARE_PLAN";
export const POST_KODOENGO_CARE_PLAN_FAILED = "KODOENGO/POST_CARE_PLAN_FAILED";

export const DELETE_KODOENGO_CARE_PLAN_STARTED =
  "KODOENGO/DELETE_CARE_PLAN_STARTED";
export const DELETE_KODOENGO_CARE_PLAN = "KODOENGO/DELETE_CARE_PLAN";
export const DELETE_KODOENGO_CARE_PLAN_FAILED =
  "KODOENGO/DELETE_CARE_PLAN_FAILED";

export type CarePlanDetailState = {
  id: number | null;
  actionClass: number | null;
  inTime: string | null;
  outTime: string | null;
};

export type CarePlanState = {
  id: number;
  usersInFacilityId: number;
  dayOfWeek: number | null;
  nameSei: string;
  nameMei: string;
  numberOfParticipants: number | null;
  practitioner1InTime: string;
  practitioner1OutTime: string;
  practitioner1CalculatedHours: number | null;
  practitioner1Memo: string | null;
  carePlanDetailState1: CarePlanDetailState[];
  practitioner2InTime: string;
  practitioner2OutTime: string;
  practitioner2Memo: string | null;
  carePlanDetailState2: CarePlanDetailState[];
  timeDuplicationJudgmentFlg: boolean;
  wholeCalculatedHours: number | null;
  duplicateCalculatedHours: number | null;
  licenseSameFlg: boolean;
  secondPersonFlg: boolean;
  sputumImplementationFlg: boolean;
};

export type DisplayListState = {
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  dayOfWeek: number;
  dataList: DataListState[];
};

export type DisplayState = {
  dispatchNo: number | null;
  inTime: string | null;
  outTime: string | null;
  calculatedHours: number | null;
  numberOfParticipants: number | null;
};

export type DataListState = {
  no: number | null;
  id: number | null;
  plan: DisplayState;
  planDetail: CarePlanState;
};

export type CarePlanStaffState = {
  displayList: DisplayListState[];
  carePlanCount: number;
};

export type DeleteCarePlanState = {
  uifId: string;
  users_in_facility_care_plan_id: number;
};
