import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.AssessmentState = {
  assessment: {
    created_at: null,
    updated_at: null,
    author: null,
    assessment: {
      assessment_records_id: null,
      target_date: null,
      assessment_records: []
    }
  },
  assessmentList: []
};

export const assessment = (
  state = initialState,
  action: actions.ActionTypes
): types.AssessmentState => {
  switch (action.type) {
    case types.FETCH_ASSESSMENT_STARTED:
      return { ...state };
    case types.FETCH_ASSESSMENT_SUCCESS:
      return { ...state, assessment: action.payload };
    case types.FETCH_ASSESSMENT_FAILED:
      return { ...state };
    case types.FETCH_ASSESSMENT_LIST_STARTED:
      return { ...state };
    case types.FETCH_ASSESSMENT_LIST_SUCCESS:
      return { ...state, assessmentList: action.payload };
    case types.FETCH_ASSESSMENT_LIST_FAILED:
      return { ...state };
    case types.POST_ASSESSMENT_STARTED:
      return { ...state };
    case types.POST_ASSESSMENT_SUCCESS:
      return { ...state };
    case types.POST_ASSESSMENT_FAILED:
      return { ...state };
    case types.UNSET_ASSESSMENT:
      return { ...state, ...initialState };
    case types.DELETE_ASSESSMENT_STARTED:
      return { ...state };
    case types.DELETE_ASSESSMENT_SUCCESS:
      return { ...state };
    case types.DELETE_ASSESSMENT_FAILED:
      return { ...state };
    default:
      return state;
  }
};
