import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PrintRecordParams = {
  targetDate?: string;
  excludedUserIds?: string;
  from?: string;
  to?: string;
  userIds?: string;
  targetId?: string;
};

export type GetSupportProcedurePrint = {
  data: {
    service_delivery_records_id: number;
    users_in_facility_id: number;
    name_sei: string;
    name_mei: string;
    target_date: string;
    status: number;
    number_of_practitioner: number;
    author: {
      id: number;
      name: string;
      role: string;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
    };
    support_procedure_form_practitioners: {
      support_procedure_form_practitioners_id: number;
      start_time: string;
      end_time: string;
      practitioner: {
        facility_id: number;
        id: number;
        license: number;
        license_name: string;
        name: string;
        role: string;
        snapshot_license_name: string;
        snapshot_name: string;
      };
      practitioner_num: number;
      facility_name: string;
    }[];
    support_procedure_form_other_practitioners: {
      support_procedure_form_other_practitioners_id: number;
      start_time: string;
      end_time: string;
      practitioner_name: number;
      facility_name: string;
    }[];
    support_procedure_form_details: {
      support_procedure_form_details_id: number;
      start_time: string;
      end_time: string;
      activity: string;
      service_procedure: string;
      check: number;
      appearance: string;
    }[];
    contact_information_plan: string;
    inquiry_items_plan: string;
    contact_information_record: string;
    inquiry_items_record: string;
  }[];
};

type responsePrintType = {
  data: GetSupportProcedurePrint["data"];
};

/**
 * サービス提供記録印刷用の情報または
 * 支援手順書兼記録用紙印刷用の情報を取得する
 *
 * @param targetDate 日ごとの対象日
 * @param excludedUserIds 日ごとの除外する利用者ID
 * @param from 月ごとの場合対象開始日
 * @param to 月ごとの場合対象終了日
 * @param userIds 月ごとの対象とする利用者ID
 * @param targetId 支援手順書兼記録用紙ID
 */

export const getSupportProcedurePrint = async (
  params: PrintRecordParams
): Promise<AxiosResponse<responsePrintType>> => {
  const { targetDate, excludedUserIds, from, to, userIds, targetId } = params;
  let target = "";
  let query = `?target_id=${targetId}`;

  // サービス提供記録日ごと
  if (targetDate) {
    target = "daily";
    query = `?target_date=${targetDate}`;
    if (excludedUserIds) {
      query += `&excluded_user_ids=${excludedUserIds}`;
    }
    // サービス提供記録月ごと
  } else if (from && to) {
    target = "monthly";
    query = `?from=${from}&to=${to}`;
    if (userIds) {
      query += `&user_ids=${userIds}`;
    }
    // 支援手順書兼記録用紙
  } else if (targetId) {
    target = "id";
    query = `?target_id=${targetId}`;
  }
  const url = `${VERSION_URL_201910}/support_procedure/${target}/print`;
  return request.get<responsePrintType>(url + query);
};
