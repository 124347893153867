import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportPlanUifIdCarePlanNew = {
  target_date: string;
  postal_code: string | null;
  city_id: number | null;
  prefecture_name: string | null;
  address: string | null;
  tel: string | null;
  classify_disability_support: number;
  date_pay_begin: string | null;
  date_pay_end: string | null;
  date_begin_in_service: string | null;
  pay_months_agreed_physical_care?: number | null; // 居宅介護のみ
  pay_months_agreed_outpatient_care_with_physical_care?: number | null; // 居宅介護のみ
  pay_months_agreed_housework_assistance?: number | null; // 居宅介護のみ
  pay_months_agreed_outpatient_care?: number | null; // 居宅介護のみ
  pay_months_agreed_getting_on_and_off?: number | null; // 居宅介護のみ
  housework_assistance_base_type?: number; // 居宅介護のみ
  housework_assistance_base_type_reason?: number | null; // 居宅介護のみ
  housework_assistance_base_type_comment?: string | null; // 居宅介護のみ
  usage_other_facility: string | null;
  user_request: string | null;
  user_issue: string | null;
  goals_direction_assistance: string | null;
  remarks: string | null;
  staff_comment: string | null;
  author: number | null;
  support_care_plan_schedules: {
    status: number;
    status_text?: string; // 重度訪問介護のみ
    support_care_plan_basic_info: {
      day_of_week: number[];
      start_time: string | null;
      end_time: string | null;
      end_time_next_day_flg: number;
      number_of_time: string | null;
      number_of_rides: number | null; // 居宅介護のみ
      number_of_participants: number | null;
      practitioner1: number | null;
      practitioner2?: number | null;
    }[];
    support_care_plan_details: {
      time_required: string | null;
      service_details: string | null;
      point: string | null;
      to_do: string | null;
    }[];
  }[];
  support_care_plans_judohomonkaigo?: {
    // 重度訪問介護のみ
    pay_months_agreed_severe_home_visit_care: number | null;
    pay_months_agreed_guide_severe_home_visit_care: number | null;
  };
  support_care_plans_dokoengo?: {
    // 同行援護のみ
    pay_months_agreed_accompanying_support: number | null;
  };
  support_care_plans_kodoengo?: {
    // 行動援護のみ
    pay_months_agreed_accompanying_support: number | null;
  };
};

/**
 * 対象ユーザーの介護計画を保存する。
 * @param uifId ユーザーID
 * @param params 更新データ
 */

export const postSupportPlanUifIdCarePlanNew = async (
  uifId: string,
  params: PostSupportPlanUifIdCarePlanNew
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/care_plan/new`;
  return request.post(url, params);
};
