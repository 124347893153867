import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";

import { FacilityType } from "@constants/variables";
import AdminTemplate from "@components/templates/AdminTemplate";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { OperationRecord } from "@components/organisms/mgr/Cseg/record/operationAndSupports/OperationRecord";
import { dateInYYYYMMDDFormat } from "@utils/date";
import { OperationsAndSupportsState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import { RECORD_DAILY } from "@constants/url";
import { DailySupportsRecord } from "@components/organisms/mgr/Cseg/record/operationAndSupports/DailySupportsRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { DailyPrintModal } from "@components/organisms/mgr/Cseg/record/operationAndSupports/DailyPrintModal";
import { parseDate } from "@utils/date/parseDate";
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";

const styles = (): StyleRules =>
  createStyles({
    sticky: {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px 16px",
      zIndex: 1000,
      position: "sticky",
      top: 0,
      background: "#eeeeee"
    },
    createAndUpdateDate: {
      padding: "16px 16px 0"
    },
    printButton: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    },
    operationRecordWrapper: {
      margin: "16px"
    },
    disabled: {
      margin: "16px",
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    }
  });

type UserInFacility =
  | UsersInFacilityStateKEIKAKUSODAN
  | UsersInFacilityStateCHIIKITEICHAKU
  | UsersInFacilityStateCHIIKIIKO;

type OwnProps = RouteComponentProps<{ yyyymmdd?: string }> &
  WithStyles<typeof styles>;

type StateProps = {
  facilityType: FacilityType;
  users: UserInFacility["users"];
  records: OperationsAndSupportsState;
};

type DispatchProps = {
  fetchUsers: (facilityType: FacilityType) => void;
  fetchOperationsAndSupports: (
    yyyymmdd: string,
    page?: number,
    keyword?: string,
    isRecord?: number
  ) => void;
  fetchStaffs: () => Promise<void>;
  fetchKEIKAKUSODANDaily: (date: Date) => void;
  fetchKEIKAKUSODANFacility: () => void;
};

type MergeProps = {};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

export type EditType = "operation" | "support" | "";

const DailyRecordCore = (props: Props): JSX.Element => {
  const { classes, facilityType } = props;
  const { yyyymmdd } = props.match.params;
  const [isOpenPrintModal, setOpenDetailModal] = React.useState(false);

  const defaultDate = parseDate(yyyymmdd) || new Date();
  const calendarMinDate = new Date(2021, 3, 1);
  const calendarMaxDate = new Date();

  const [isEditing, setIsEditing] = React.useState(false);
  const [editType, setEditType] = React.useState<EditType>("");

  const onChangeCalendar = React.useCallback((selectedDate: Date) => {
    const dateString = dateInYYYYMMDDFormat(selectedDate);
    props.history.replace(`${RECORD_DAILY}/${dateString}`);
  }, []);

  const onClickPrintButton = React.useCallback(() => {
    setOpenDetailModal(true);
  }, []);

  const closePrintModal = React.useCallback(() => {
    setOpenDetailModal(false);
  }, []);

  React.useEffect(() => {
    if (props.facilityType === FacilityType.KEIKAKUSODAN) {
      props.fetchKEIKAKUSODANDaily(defaultDate);
      props.fetchKEIKAKUSODANFacility();
    }
  }, []);

  React.useEffect(() => {
    const dateParam = yyyymmdd || dateInYYYYMMDDFormat();
    props.fetchOperationsAndSupports(dateParam, 1);
    props.fetchUsers(facilityType);
    props.fetchStaffs();
  }, [yyyymmdd]);

  return (
    <AdminTemplate pageName="日々の記録">
      <div className={classes.sticky}>
        <UnEditableWrapper unEditable={isEditing}>
          <DateSelectButtonsDaily
            defaultDate={defaultDate}
            min={calendarMinDate}
            max={calendarMaxDate}
            onClickSubmit={onChangeCalendar}
          />
        </UnEditableWrapper>
        <div className={classes.printButton}>
          <KnowbeButton
            disabled={isEditing || !props.records.created_at}
            onClick={onClickPrintButton}
          >
            印刷
          </KnowbeButton>
        </div>
      </div>
      <div className={classes.createAndUpdateDate}>
        <CreateAndUpdateDate
          createdAt={props.records.created_at}
          updatedAt={props.records.updated_at}
        />
      </div>
      <div
        className={
          isEditing && editType === "support"
            ? classes.disabled
            : classes.operationRecordWrapper
        }
      >
        <OperationRecord
          operation={props.records.operation_records}
          isEditing={isEditing && editType === "operation"}
          setIsEditing={setIsEditing}
          setEditType={setEditType}
          yyyymmdd={yyyymmdd || dateInYYYYMMDDFormat()}
        />
      </div>
      <div
        className={
          isEditing && editType === "operation"
            ? classes.disabled
            : classes.operationRecordWrapper
        }
      >
        <DailySupportsRecord
          supportCount={props.records.support_count}
          support={props.records.support_records}
          users={props.users}
          yyyymmdd={yyyymmdd || dateInYYYYMMDDFormat()}
          fetchOperationsAndSupports={props.fetchOperationsAndSupports}
          isEditing={isEditing}
          editType={editType}
          setIsEditing={setIsEditing}
          setEditType={setEditType}
        />
      </div>
      {isOpenPrintModal && (
        <DailyPrintModal
          isModalOpen={isOpenPrintModal}
          onClose={closePrintModal}
          yyyymmdd={yyyymmdd || dateInYYYYMMDDFormat()}
          history={props.history}
        />
      )}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    users: state[facilityType].userInFacility.users,
    records: state.Cseg.csegOperationsAndSupports
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, staffDispatcher, KEIKAKUSODAN } = dispatches;

  return {
    fetchUsers: (facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetch();
    },
    fetchOperationsAndSupports: (
      yyyymmdd: string,
      page?: number,
      keyword?: string,
      isRecord?: number
    ): void => {
      Cseg.operationsAndSupportsDispatcher(dispatch).fetchOperationsAndSupports(
        yyyymmdd,
        page,
        keyword,
        isRecord
      );
    },
    fetchStaffs: staffDispatcher(dispatch).fetch,
    fetchKEIKAKUSODANDaily: (date: Date): void => {
      KEIKAKUSODAN.reportDispatcher(dispatch).fetchKEIKAKUSODANDaily(date);
    },
    fetchKEIKAKUSODANFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

export const DailyRecord = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(DailyRecordCore)
);
