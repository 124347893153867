import * as React from "react";

import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import { AttendanceHeader } from "@components/organisms/attendance/AttendanceHeader";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      minHeight: "100vh",
      paddingBottom: spacing.unit * 5,
      fontFamily:
        "'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, Osaka, 'MS PGothic', arial, helvetica, sans-serif"
    },
    children: {
      maxWidth: "90%",
      marginLeft: 32
    }
  });

type OwnProps = {
  userName: string;
  handleLogout: () => void;
  children: React.ReactNode;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AttendanceTemplateCore = (props: Props): JSX.Element => (
  <div className={props.classes.root}>
    <AttendanceHeader
      userName={props.userName}
      handleLogout={props.handleLogout}
    />
    <div className={props.classes.children}>{props.children}</div>
  </div>
);

export const AttendanceTemplate = withStyles(styles)(AttendanceTemplateCore);
