import castNumber from "@utils/dataNormalizer/castNumber";

/**
 * 所要時間の計算
 * ※返却は分単位
 */
export const calculateForRequiredTime = (
  inTimeData: string | null,
  inTimeClass: string,
  outTimeData: string | null,
  outTimeClass: string
): number => {
  if (inTimeData === null || outTimeData === null) {
    return 0;
  }

  const inTimes = inTimeData.split(":");
  const outTimes = outTimeData.split(":");
  if (inTimes.length !== 2 || outTimes.length !== 2) {
    return 0;
  }

  // 日跨ぎしている場合、時間に+24する
  const inTimeHour =
    inTimeClass === "1"
      ? (castNumber(inTimes[0]) + 24) * 60
      : castNumber(inTimes[0]) * 60;
  const outTimeHour =
    outTimeClass === "1"
      ? (castNumber(outTimes[0]) + 24) * 60
      : castNumber(outTimes[0]) * 60;
  const inTimeMinutes = castNumber(inTimes[1]);
  const outTimeMinutes = castNumber(outTimes[1]);

  return outTimeHour + outTimeMinutes - (inTimeHour + inTimeMinutes);
};
