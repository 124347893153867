import React from "react";
import { RouteComponentProps } from "react-router-dom";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { AssessmentPrint } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentPrint";
import * as URL from "@constants/url";

/**
 * アセスメントシートのプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    assessmentId: string;
  }>;

const AssessmentRecordPrintCore = (props: Props): JSX.Element => {
  const query = props.location.search;

  const { uifId, assessmentId } = props.match.params;

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "アセスメント",
      path: `/record/support_plan/${uifId}/assessment`
    }
  ];
  const RenderPrint = (): JSX.Element => {
    return (
      <AssessmentPrint
        uifId={uifId}
        assessmentId={assessmentId}
        query={query}
      />
    );
  };

  return (
    <AdminTemplate pageName="アセスメントシート" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const AssessmentRecordPrint = connect(mapStateToProps)(
  AssessmentRecordPrintCore
);
