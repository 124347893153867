import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export const OutlineUploadIcon = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon height={24} viewBox="0 -960 960 960" width={24} {...props}>
      <path d="M440-320v-326L336-542l-56-58 200-200 200 200-56 58-104-104v326h-80ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z" />
    </SvgIcon>
  );
};
