import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    field1: {
      marginTop: 24
    },
    field2: {
      marginTop: 30
    },
    field3: {
      marginTop: 48,
      width: 245
    }
  });

type OwnProps = {
  isEditing: boolean;
  author?: {
    value: string;
    options: FieldItem[];
  };
  values: {
    remarks: string;
    staff_comment: string;
  };
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * その他
 */
const OtherFieldsCore = (props: Props): JSX.Element => {
  return (
    <>
      <GrayMiddleHeading text="その他" />
      <div className={props.classes.field1}>
        <RecordTextField
          name="remarks"
          value={props.values.remarks}
          defaultValue="-"
          label="特記事項"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>
      <div className={props.classes.field2}>
        <RecordTextField
          name="staff_comment"
          value={props.values.staff_comment}
          defaultValue="-"
          label="職員コメント"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>
      {props.author && (
        <div className={props.classes.field3}>
          <RecordSelect
            name="author"
            value={props.author.value}
            defaultValue="-"
            label="計画作成者"
            placeholder="選択してください"
            options={props.author.options}
            isEditable={props.isEditing}
            isSelectablePlaceholder
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          />
        </div>
      )}
    </>
  );
};

export const OtherFields = withStyles(styles)(OtherFieldsCore);
