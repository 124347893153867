import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportPlanUifIdSupportPlanSheets = {
  data: {
    id: number;
    creation_date: string;
    author_name: string | null;
  }[];
};

/**
 * 対象ユーザーの支援計画シート一覧を取得する。
 * @param uifId ユーザーID
 */
export const getSupportPlanUifIdSupportPlanSheets = async (
  uifId: string
): Promise<AxiosResponse<GetSupportPlanUifIdSupportPlanSheets>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_plan_sheets`;
  return request.get<GetSupportPlanUifIdSupportPlanSheets>(url);
};
