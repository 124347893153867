import React from "react";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import {
  createStyles,
  WithStyles,
  StyleRules,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import Table from "@components/molecules/Table";
import TableHead, { HeaderProps } from "@components/molecules/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { UsageResultListCell } from "@components/v202104/organisms/mgr/SHUROTEICHAKU/report/cell/UsageResultListCell";
import { UsageResultsState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/report/types";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import { FormikProps } from "formik";
import { InitialDataValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/report/initialData";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row: {
      height: 56,
      verticalAlign: "top",
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    disableCell: {
      display: "none"
    },
    sticky: {
      zIndex: 10,
      position: "sticky"
    },
    cell: {
      boxSizing: "border-box",
      paddingLeft: 8,
      height: 56
    },
    dateCell: {
      width: 142,
      minWidth: 142,
      maxWidth: 142,
      paddingLeft: 16
    },
    shortCell: {
      width: 64,
      minWidth: 64,
      maxWidth: 64
    },
    cooperationPromotionFlgCell: {
      width: 92,
      minWidth: 92,
      maxWidth: 92
    },
    longCell: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      "&:last-child": {
        paddingRight: 16
      }
    }
  });

type StateProps = {
  usageResultList: UsageResultsState;
};

type OwnProps = {
  isEditing: boolean;
  headerHeight: number;
  formikProps: FormikProps<InitialDataValues>;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const reportMonthlyHeader = (
  classes: Record<
    | "cell"
    | "dateCell"
    | "shortCell"
    | "cooperationPromotionFlgCell"
    | "longCell",
    string
  >
): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    items: [
      {
        align: "left",
        label: "日付",
        className: `${classes.cell} ${classes.dateCell}`
      },
      {
        align: "left",
        label: "支援実施",
        className: `${classes.cell} ${classes.shortCell}`
      },
      {
        align: "left",
        label: (
          <span>
            特別地域
            <br />
            加算
          </span>
        ),
        className: `${classes.cell} ${classes.shortCell}`
      },
      {
        align: "left",
        label: (
          <span>
            定着支援連携
            <br />
            促進加算
          </span>
        ),
        className: `${classes.cell} ${classes.cooperationPromotionFlgCell}`
      },
      {
        align: "left",
        label: "支援実施方法・場所",
        className: `${classes.cell} ${classes.longCell}`
      },
      {
        align: "left",
        label: "支援開始 / 終了時間",
        className: `${classes.cell} ${classes.longCell}`
      },
      {
        align: "left",
        label: "備考（実績記録票に表示）",
        className: `${classes.cell} ${classes.longCell}`
      }
    ]
  };
};

const UsageResultListTableCore = (props: Props): JSX.Element => {
  const childrenList = props.usageResultList.usageResults.map(
    (usageResult, index) => {
      return (
        <TableRow
          key={`table-row-${usageResult.targetDate}`}
          className={props.classes.row}
        >
          <UsageResultListCell
            params={usageResult}
            formikProps={props.formikProps}
            idx={index}
            isEditing={props.isEditing}
          />
        </TableRow>
      );
    }
  );
  const tableHead = reportMonthlyHeader(props.classes);
  const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

  return (
    <>
      <div className={props.classes.sticky} style={{ top: `${topHeight}px` }}>
        <Table key="monthly-report-table">
          <TableHead
            role={tableHead.role}
            ariaChecked={tableHead.ariaChecked}
            tabIndex={tableHead.tabIndex}
            key={tableHead.key}
            selected={tableHead.selected}
            items={tableHead.items}
            rowStyle={tableHead.rowStyle}
            headerStyle={{ position: "sticky", top: props.headerHeight }}
          />
        </Table>
      </div>
      <Table key="monthly-report-table">
        <TableBody>{childrenList.length > 0 && childrenList}</TableBody>
      </Table>
      {childrenList.length === 0 && (
        <div className={props.classes.noData}>
          利用者が登録されていません。
          <br />
          利用者情報画面で登録後、ご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    usageResultList: state.v202104.SHUROTEICHAKU.report
  };
};

export const UsageResultListTable = connect(mapStateToProps)(
  withStyles(styles)(UsageResultListTableCore)
);
