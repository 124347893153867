import { GetServiceDeliveryDaily } from "@api/requests/serviceDelivery/getServiceDeliveryDaily";
import { GetServiceDeliveryMonthly } from "@api/requests/serviceDelivery/getServiceDeliveryMonthly";
import { CheckBoxValue } from "@interfaces/ui/form";
import { NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/normalizer";
import { NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/normalizer";
import { NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/normalizer";
import { NormalizedGetFacilityUserTargetIdResponse as NormalizedGetFacilityUserTargetIdResponseKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/normalizer";

/**
 * ActionNames
 */
export const FETCH_DAILY_RECORD_STARTED =
  "SERVICE_DELIVERY/FETCH_DAILY_RECORD_STARTED";
export const FETCH_DAILY_RECORD_SUCCESS =
  "SERVICE_DELIVERY/FETCH_DAILY_RECORD_SUCCESS";
export const FETCH_DAILY_RECORD_FAILED =
  "SERVICE_DELIVERY/FETCH_DAILY_RECORD_FAILED";
export const FETCH_MONTHLY_RECORD_STARTED =
  "SERVICE_DELIVERY/FETCH_MONTHLY_RECORD_STARTED";
export const FETCH_MONTHLY_RECORD_SUCCESS =
  "SERVICE_DELIVERY/FETCH_MONTHLY_RECORD_SUCCESS";
export const FETCH_MONTHLY_RECORD_FAILED =
  "SERVICE_DELIVERY/FETCH_MONTHLY_RECORD_FAILED";
export const FETCH_DETAILS_RECORD_STARTED =
  "SERVICE_DELIVERY/FETCH_DETAILS_RECORD_STARTED";
export const FETCH_DETAILS_RECORD_SUCCESS =
  "SERVICE_DELIVERY/FETCH_DETAILS_RECORD_SUCCESS";
export const FETCH_DETAILS_RECORD_FAILED =
  "SERVICE_DELIVERY/FETCH_DETAILS_RECORD_FAILED";
export const POST_RECORD_DETAIL_STARTED =
  "SERVICE_DELIVERY/POST_RECORD_DETAIL_STARTED";
export const POST_RECORD_DETAIL_SUCCESS =
  "SERVICE_DELIVERY/POST_RECORD_DETAIL_SUCCESS";
export const POST_RECORD_DETAIL_FAILED =
  "SERVICE_DELIVERY/POST_RECORD_DETAIL_FAILED";
export const CLEAR_RECORD_DETAIL_STATE =
  "SERVICE_DELIVERY/CLEAR_RECORD_DETAIL_STATE";
export const CLEAR_RECORD_PRINT_STATE =
  "SERVICE_DELIVERY/CLEAR_RECORD_PRINT_STATE";
export const FETCH_PRINT_RECORD_STARTED =
  "SERVICE_DELIVERY/FETCH_PRINT_RECORD_STARTED";
export const FETCH_PRINT_RECORD_SUCCESS =
  "SERVICE_DELIVERY/FETCH_PRINT_RECORD_SUCCESS";
export const FETCH_PRINT_RECORD_FAILED =
  "SERVICE_DELIVERY/FETCH_PRINT_RECORD_FAILED";
export const DELETE_STARTED = "SERVICE_DELIVERY/DELETE_STARTED";
export const DELETE_SUCCESS = "SERVICE_DELIVERY/DELETE_SUCCESS";
export const DELETE_FAILED = "SERVICE_DELIVERY/DELETE_FAILED";
export const FETCH_USER_STARTED = "SERVICE_DELIVERY/FETCH_USER_STARTED";
export const FETCH_USER_SUCCESS = "SERVICE_DELIVERY/FETCH_USER_SUCCESS";
export const FETCH_USER_FAILED = "SERVICE_DELIVERY/FETCH_USER_FAILED";

export const SERVICE_DELIVERY_DAILY = "servicedeliveryDaily";
export const SERVICE_DELIVERY_MONTHLY = "servicedeliveryMonthly";

export type ServiceDeliveryTypeInterface = {
  type: "servicedeliveryDaily" | "servicedeliveryMonthly";
};

// サービス利用者情報
export type ServiceDeliveryUserType =
  | NormalizedGetFacilityUserTargetIdResponseKYOTAKUKAIGO
  | NormalizedGetFacilityUserTargetIdResponseJUDOHOMONKAIGO
  | NormalizedGetFacilityUserTargetIdResponseDOKOENGO
  | NormalizedGetFacilityUserTargetIdResponseKODOENGO;

export type PractitionerType = {
  id: number;
  name: string;
  role: string;
  license: number;
  license_name: string | null;
  facility_id: number;
  snapshot_name: string;
  snapshot_license_name: string | null;
};

export type ServiceDeliveryRecordsInput = {
  id: number;
  custom_record_item_id: number;
  input_data?: string;
  choiced_item_id?: number;
  checked?: number;
};
/**
 * State
 */
export type ServiceDeliveryState = {
  dailyRecord: GetServiceDeliveryDaily["data"];
  monthlyRecord: GetServiceDeliveryMonthly["data"];
  detailsRecord: {
    serviceDeliveryRecordsId: number | null;
    inoutResultsId: number | null;
    supportProcedureFormsId: number | null; // 行動のみ設定
    createdAt: number | null;
    updatedAt: number | null;
    usersInFacilityId: number;
    nameSei: string;
    nameMei: string;
    targetDate: string;
    status: string;
    statusText: string | null; // 移動支援のみ
    emergencySupportFlg: CheckBoxValue;
    emergencyGuideSupportFlg: CheckBoxValue; // 重度のみ設定
    sputumImplementationFlg: CheckBoxValue;
    bodyRestrictedStillFlg: CheckBoxValue;
    accompanySupportFlg: CheckBoxValue; // 重度のみ設定
    accompanySupportDisplayFlg: boolean; // 重度のみ設定
    numberOfPractitioner: number;
    numberOfSupporters: number | null; // 移動支援のみ
    supportRatioPerPractitioner: number | null; // 移動支援のみ
    physicalCareFlg: CheckBoxValue; // 移動支援のみ
    licenseSameFlg: CheckBoxValue; // 移動支援のみ
    serviceDeliveryRecordPractitioners: {
      serviceDeliveryRecordPractitionersId: number | null;
      startTime: string | null;
      endTime: string | null;
      numberOfTime: string | null;
      numberOfRides: number; // 居宅のみ設定
      calculatedMovingHours: string | null; // 重度のみ設定
      accompanySupportInTime: string | null; // 重度のみ設定
      accompanySupportOutTime: string | null; // 重度のみ設定
      calculatedAccompanySupportHours: string | null; // 重度のみ設定
      calculatedAccompanySupportMovingHours: string | null; // 重度のみ設定
      practitioner: PractitionerType | null;
      practitioner2: PractitionerType | null;
      practitioner3: PractitionerType | null;
      practitioner4: PractitionerType | null;
      practitioner5: PractitionerType | null;
      practitionerName: string | null; // 行動のみ設定
      practitionerName2: string | null; // 移動支援のみ
      practitionerName3: string | null; // 移動支援のみ
      practitionerName4: string | null; // 移動支援のみ
      practitionerName5: string | null; // 移動支援のみ
      practitionerNum: number;
      displayFlg: boolean;
      serviceDeliveryRecordsPractitionerDetails: {
        serviceDeliveryRecordPractitionerDetailsId: number | null;
        actionClass: number;
        startTime: string | null;
        endTime: string | null;
        isDelete: number;
      }[];
      serviceDeliveryRecords:
        | {
            customRecords_category_id: number;
            input: ServiceDeliveryRecordsInput[];
          }[]
        | null;
      // 同行援護のみ
      serviceDeliveryRecordPractitionerDestinations?: {
        service_delivery_record_practitioner_destinations_id: number;
        when_time: string;
        place: string;
        support: string;
      }[];
      // 同行援護のみ
      serviceDeliveryRecordPractitionerMoneyManagements?: {
        traveling_expenses: {
          service_delivery_record_practitioner_money_managements_id: number;
          transportation: number;
          free_text: string;
          amount_money: string;
        }[];
        other_expenses: {
          service_delivery_record_practitioner_money_managements_id: number;
          transportation: number;
          free_text: string;
          amount_money: string;
        }[];
      };
    }[];
    municipality: {
      calculationTimeFlg: boolean | null;
      roundUpMinute: number | null;
    };
  };
  print: string[];
  user: ServiceDeliveryUserType;
};
