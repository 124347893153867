import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import TableCell, { Padding } from "@material-ui/core/TableCell";

const styles = (): StyleRules =>
  createStyles({
    cellStyle: {
      padding: "0 8px",
      borderBottom: "none"
    },
    hidden: {
      display: "none"
    }
  });

type OwnProps = {
  children?: React.ReactNode;
  key: string;
  hidden?: boolean;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  cellClass?: string;
  colSpan?: number;
  rowSpan?: number;
  padding?: Padding;
};

type Props = OwnProps & WithStyles<typeof styles>;

const TableCellWrap = (props: Props): JSX.Element => {
  return (
    <TableCell
      key={props.key}
      align={props.align || "left"}
      className={ClassNames(
        props.classes.cellStyle,
        props.hidden ? props.classes.hidden : "",
        props.cellClass
      )}
      colSpan={props.colSpan}
      rowSpan={props.rowSpan}
      padding={props.padding}
    >
      {props.children}
    </TableCell>
  );
};

export default withStyles(styles)(TableCellWrap);
