import * as React from "react";
import { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import {
  StaffTreatmentSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  SpecificBetterSupporterConditionTypes2024,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  DISABLED_RADIO_ITEMS_VALUE,
  DEFAULT_SELECT_DATE_VALUE
} from "@constants/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/SHISETSUNYUSHO/facility/initialValues";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import SeeHeareTeam202404Fields from "@components/organisms/mgr/common/facility/items/SeeHeareTeamFields";
import {
  SERIOUS_DISABILITY_LIST_202404,
  NIGHTTIME_PLACEMENT_LIST,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_LIST,
  ORAL_HYGIENE_MANAGEMENT_SYSTEM_LIST
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikTextField from "@components/molecules/FormikTextField";

const styles = (): StyleRules =>
  createStyles({
    section: {
      marginBottom: 32
    }
  });

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);

// なしと福祉・介護職員等処遇改善（Ⅰ）-（Ⅴ）の選択肢
const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);
// なしと、福祉・介護職員等処遇改善（Ⅴ）1 - 14の選択肢
const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AdditionalItemFields = (props: Props): JSX.Element => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = useState(false);

  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );

  const isSelectedConditionType2024 =
    props.formikProps.values.additionalItem
      .specificBetterSupporterCondition202404 ===
    `${SELECTED_CONDITION_TYPE_2024_V}`;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (!isSelectedConditionType2024) {
      props.formikProps.setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
  }, [staffTreatmentSystemType]);

  // 夜勤職員配置・夜間看護体制加算：デフォルト値に戻す
  const onChangeNighttimePlacement = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (e.target.value !== "2") {
      props.formikProps.setFieldValue(
        "additionalItem.nighttimeNurseCount",
        "1"
      );
    }
  };

  // 高次脳機能障害者支援体制加算：デフォルト値に戻す
  const onChangeHigherBrainDysfunction = (): void => {
    const { setFieldValue } = props.formikProps;
    if (
      getIn(
        props.formikProps.values,
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg"
      )
    ) {
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        false
      );
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFrom",
        DEFAULT_SELECT_DATE_VALUE
      );
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportTo",
        DEFAULT_SELECT_DATE_VALUE
      );
    } else {
      setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        true
      );
    }
  };

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);
    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "additionalItem.regionalTransitionSupportFlg":
          props.formikProps.setFieldValue(
            "additionalItem.decreaseUsersCount",
            ""
          );
          props.formikProps.setFieldValue(
            "additionalItem.regionalTransitionSupportFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.regionalTransitionSupportTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "additionalItem.improvingInfectionControl1Flg":
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl1From",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl1To",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "additionalItem.improvingInfectionControl2Flg":
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl2From",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl2To",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };

  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        <FormikRadioButtons
          name="additionalItem.nighttimePlacement"
          label="夜勤職員配置・夜間看護体制加算"
          options={NIGHTTIME_PLACEMENT_LIST}
          onChangeHook={onChangeNighttimePlacement}
          style={{
            marginBottom:
              props.formikProps.values.additionalItem.nighttimePlacement === "2"
                ? 8
                : 32
          }}
        />
        {props.formikProps.values.additionalItem.nighttimePlacement === "2" && (
          <FormikTextField
            name="additionalItem.nighttimeNurseCount"
            label="看護職員配置人数"
            placeholder="1"
            endAdornmentLabel="人"
            style={{ marginLeft: 36 }}
          />
        )}
        {/* 視覚・聴覚言語障害者支援体制加算 */}
        <SeeHeareTeam202404Fields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="seeHearTeam202404Tips" />}
            />
          }
        />
        <FormikSwitch
          name="additionalItem.higherBrainDysfunctionPersonsSupportFlg"
          label="高次脳機能障害者支援体制加算"
          onChange={onChangeHigherBrainDysfunction}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikSwitch
          name="additionalItem.improvingInfectionControl1Flg"
          label="障害者支援施設等感染対策向上加算（Ⅰ）"
          onChange={onChangeSwitch}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl1From"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl1To"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikSwitch
          name="additionalItem.improvingInfectionControl2Flg"
          label="障害者支援施設等感染対策向上加算（Ⅱ）"
          onChange={onChangeSwitch}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl2From"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl2To"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikSwitch
          name="additionalItem.regionalTransitionSupportFlg"
          label="地域移行支援体制加算"
          onChange={onChangeSwitch}
        >
          <div className={props.classes.innerItem}>
            <FormikTextField
              name="additionalItem.decreaseUsersCount"
              label="利用定員の減少数"
              placeholder="0"
              endAdornmentLabel="人"
              style={{ marginBottom: 16 }}
            />
            <FormikSelectDateNotSelectedDefault
              name="additionalItem.regionalTransitionSupportFrom"
              label="開始年月日"
              addYearTo={1}
              overrideYearFrom={2024}
              setFormikFieldValue={props.formikProps.setFieldValue}
              monthOptionsStartFrom={4}
              style={{ marginBottom: 16 }}
            />
            <FormikSelectDateNotSelectedDefault
              name="additionalItem.regionalTransitionSupportTo"
              label="終了年月日（任意）"
              addYearTo={5}
              overrideYearFrom={2024}
              setFormikFieldValue={props.formikProps.setFieldValue}
            />
          </div>
        </FormikSwitch>
        <FormikCheckbox
          name="additionalItem.regionalLifeTransition"
          label="地域生活移行個別支援特別加算（Ⅰ）"
        />
        <FormGroup style={{ marginBottom: 28 }}>
          <FormikRadioButtons
            name="additionalItem.specificBetterSupporterCondition202404"
            style={{ marginBottom: 0 }}
            label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
            options={SpecificBetterSupporterConditionType2024RadioItems}
            onClick={onChangeSpecificBetterSupporterConditionType2024}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                }
              />
            }
          />
          {isSelectedConditionType2024 && (
            <FormikRadioButtons
              style={{ marginLeft: 48, marginTop: -2, marginBottom: 0 }}
              name="additionalItem.specificBetterSupporterCondition202404Sub"
              label=""
              options={SpecificBetterSupporterConditionTypeSub2024RadioItems}
            />
          )}
        </FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={STAFF_TREATMENT_SPECIFIC_SYSTEM_LIST}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.seriousDisability"
          label="重度障害者支援加算"
          options={SERIOUS_DISABILITY_LIST_202404}
        />
        <FormikRadioButtons
          name="additionalItem.oralHygieneManagementSystemFlg"
          label="口腔衛生管理体制加算"
          options={ORAL_HYGIENE_MANAGEMENT_SYSTEM_LIST}
          style={{ marginBottom: 0 }}
        />
      </FormGroup>
    </FormPaper>
  );
};

export default withStyles(styles)(AdditionalItemFields);
