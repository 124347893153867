import { BasicErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/basic";
import { ServiceUseErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/serviceUse";
import { RecipientCertificateErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/users/recipientCertificate";
import { UsersValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/users/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import castString from "@utils/dataNormalizer/castString";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

type FacilityErrors = BasicErrors &
  ServiceUseErrors &
  RecipientCertificateErrors;

const basicValidation = (
  values: UsersValues,
  facilityType: number | null
): BasicErrors => {
  return {
    basic: {
      nameSei: validator(values.basic.nameSei, "required"),
      nameMei: validator(values.basic.nameMei, "required"),
      nameSeiKana: validator(values.basic.nameSeiKana, "required", "kana"),
      nameMeiKana: validator(values.basic.nameMeiKana, "required", "kana"),
      recipientNumberKEIKAKUSODAN: validateSwitcher(
        facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
        validator(
          values.basic.recipientNumberKEIKAKUSODAN,
          {
            type: "required",
            shouldValidate: !values.basic.noneRecipientNumberFlagKEIKAKUSODAN
          },
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      recipientNumberSHOGAIJISODAN: validateSwitcher(
        facilityType === FACILITY_TYPE_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
        validator(
          values.basic.recipientNumberSHOGAIJISODAN,
          {
            type: "required",
            shouldValidate: !values.basic.noneRecipientNumberFlagSHOGAIJISODAN
          },
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      dateOfBirth: dateValidator(
        notSelectedToEmpty(values.basic.dateOfBirth),
        "required"
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "naturalNumber"),
      email: validator(values.basic.email, "email"),
      guardianNameSei: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianNameSei, "required")
      ),
      guardianNameMei: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianNameMei, "required")
      ),
      guardianNameSeiKana: values.basic.classifyHandicappedFlag
        ? validator(values.basic.guardianNameSeiKana, "required", "kana")
        : validator(values.basic.guardianNameSeiKana, "kana"),
      guardianNameMeiKana: values.basic.classifyHandicappedFlag
        ? validator(values.basic.guardianNameMeiKana, "required", "kana")
        : validator(values.basic.guardianNameMeiKana, "kana"),
      guardianRelation: validateSwitcher(
        values.basic.classifyHandicappedFlag,
        validator(values.basic.guardianRelation, "required")
      ),
      guardianTel: validator(values.basic.guardianTel, "naturalNumber"),
      familySerialNumber: validateSwitcher(
        facilityType === FACILITY_TYPE_SHOGAIJISODAN ||
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
        validator(values.basic.familySerialNumber, "naturalNumber")
      )
    }
  };
};

const serviceUseValidation = (
  values: UsersValues,
  facilityType: number | null
): ServiceUseErrors => {
  return {
    serviceUse: values.serviceUse.map((v) => {
      if (
        facilityType !== FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        facilityType !== v.targetType
      ) {
        return undefined;
      }
      const noneRecipientNumberFlag =
        v.targetType === FACILITY_TYPE_KEIKAKUSODAN
          ? values.basic.noneRecipientNumberFlagKEIKAKUSODAN
          : values.basic.noneRecipientNumberFlagSHOGAIJISODAN;
      return {
        inServiceStartDate:
          facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
          noneRecipientNumberFlag
            ? {
                year:
                  v.inServiceStartDate.year === "9999"
                    ? undefined
                    : "受給者証未発行の場合、9999を設定してください",
                month:
                  v.inServiceStartDate.month === "12"
                    ? undefined
                    : "受給者証未発行の場合、12を設定してください",
                day:
                  v.inServiceStartDate.day === "1"
                    ? undefined
                    : "受給者証未発行の場合、1を設定してください"
              }
            : dateValidator(
                notSelectedToEmpty(v.inServiceStartDate),
                "required"
              ),
        inServiceEndDate: dateValidator(
          notSelectedToEmpty(v.inServiceEndDate),
          {
            type: "future",
            startDate: v.inServiceStartDate,
            options: {
              startLabel: "開始日",
              endLabel: "終了日"
            }
          }
        ),
        payStartDate: validateSwitcher(
          !noneRecipientNumberFlag,
          dateValidator(notSelectedToEmpty(v.payStartDate), "required")
        ),
        payEndDate: noneRecipientNumberFlag
          ? dateValidator(notSelectedToEmpty(v.payEndDate), {
              type: "future",
              startDate: v.payStartDate,
              options: {
                startLabel: "支給決定開始日",
                endLabel: "支給決定終了日"
              }
            })
          : dateValidator(notSelectedToEmpty(v.payEndDate), "required", {
              type: "future",
              startDate: v.payStartDate,
              options: {
                startLabel: "支給決定開始日",
                endLabel: "支給決定終了日"
              }
            }),
        subsidizedPercent: validateSwitcher(
          v.subsidizedFlag && v.subsidizedUnit === "1",
          validator(v.subsidizedPercent, "required", "naturalNumberNonZero", {
            type: "upperLimit",
            upperLimit: 100
          })
        ),
        subsidizedYen: validateSwitcher(
          v.subsidizedFlag && v.subsidizedUnit === "2",
          validator(v.subsidizedYen, "required", "naturalNumberNonZero")
        ),
        subsidizedCityId: validateSwitcher(
          v.subsidizedFlag,
          validator(v.subsidizedCityId, {
            type: "selectRequired",
            value: DEFAULT_SELECT_VALUE
          })
        ),
        upperLimitFacilityNumber: validateSwitcher(
          v.upperLimitFacilityFlag,
          validator(v.upperLimitFacilityNumber, "required", "naturalNumber", {
            type: "checkDigits",
            digits: 10
          })
        ),
        upperLimitFacilityNumber2: validateSwitcher(
          v.upperLimitFacilityFlag,
          validator(v.upperLimitFacilityNumber2, "naturalNumber", {
            type: "checkDigits",
            digits: 10
          })
        ),
        upperLimitFacilityNumber3: validateSwitcher(
          v.upperLimitFacilityFlag,
          validator(v.upperLimitFacilityNumber3, "naturalNumber", {
            type: "checkDigits",
            digits: 10
          })
        ),
        upperLimitFacilityNumber4: validateSwitcher(
          v.upperLimitFacilityFlag,
          validator(v.upperLimitFacilityNumber4, "naturalNumber", {
            type: "checkDigits",
            digits: 10
          })
        ),
        upperLimitFacilityName: validateSwitcher(
          v.upperLimitFacilityFlag,
          validator(
            castString(v.upperLimitFacilityName),
            {
              type: "checkCharacterLength",
              length: 2500
            },
            "required"
          )
        ),
        upperLimitFacilityName2: validateSwitcher(
          v.upperLimitFacilityFlag && !!v.upperLimitFacilityNumber2,
          validator(
            castString(v.upperLimitFacilityName2),
            {
              type: "checkCharacterLength",
              length: 2500
            },
            "required"
          )
        ),
        upperLimitFacilityName3: validateSwitcher(
          v.upperLimitFacilityFlag && !!v.upperLimitFacilityNumber3,
          validator(
            castString(v.upperLimitFacilityName3),
            {
              type: "checkCharacterLength",
              length: 2500
            },
            "required"
          )
        ),
        upperLimitFacilityName4: validateSwitcher(
          v.upperLimitFacilityFlag && !!v.upperLimitFacilityNumber4,
          validator(
            castString(v.upperLimitFacilityName4),
            {
              type: "checkCharacterLength",
              length: 2500
            },
            "required"
          )
        ),
        upperLimitTotalYen: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitTotalYen, "naturalNumberNonZero")
        ),
        upperLimitTotalYen2: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitTotalYen2, "naturalNumberNonZero")
        ),
        upperLimitTotalYen3: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitTotalYen3, "naturalNumberNonZero")
        ),
        upperLimitTotalYen4: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitTotalYen4, "naturalNumberNonZero")
        ),
        upperLimitUserLoadYen: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitUserLoadYen, "naturalNumberNonZero")
        ),
        upperLimitUserLoadYen2: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitUserLoadYen2, "naturalNumberNonZero")
        ),
        upperLimitUserLoadYen3: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitUserLoadYen3, "naturalNumberNonZero")
        ),
        upperLimitUserLoadYen4: validateSwitcher(
          v.upperLimitFacilityFlag && v.upperLimitControlledBy === "1",
          validator(v.upperLimitUserLoadYen4, "naturalNumberNonZero")
        ),
        uplimitUserLoadAfterAdjustmentYen: validateSwitcher(
          v.upperLimitFacilityFlag &&
            v.upperLimitControlledBy === "1" &&
            v.resultOfManagement === "3",
          validator(
            v.uplimitUserLoadAfterAdjustmentYen,
            "required",
            "naturalNumberNonZero"
          )
        ),
        uplimitUserLoadAfterAdjustmentYen2: validateSwitcher(
          v.upperLimitFacilityFlag &&
            v.upperLimitControlledBy === "1" &&
            v.resultOfManagement === "3" &&
            !!v.upperLimitFacilityNumber2,
          validator(
            v.uplimitUserLoadAfterAdjustmentYen2,
            "required",
            "naturalNumberNonZero"
          )
        ),
        uplimitUserLoadAfterAdjustmentYen3: validateSwitcher(
          v.upperLimitFacilityFlag &&
            v.upperLimitControlledBy === "1" &&
            v.resultOfManagement === "3" &&
            !!v.upperLimitFacilityNumber3,
          validator(
            v.uplimitUserLoadAfterAdjustmentYen3,
            "required",
            "naturalNumberNonZero"
          )
        ),
        uplimitUserLoadAfterAdjustmentYen4: validateSwitcher(
          v.upperLimitFacilityFlag &&
            v.upperLimitControlledBy === "1" &&
            v.resultOfManagement === "3" &&
            !!v.upperLimitFacilityNumber4,
          validator(
            v.uplimitUserLoadAfterAdjustmentYen4,
            "required",
            "naturalNumberNonZero"
          )
        ),
        specialAreaEndDate: validateSwitcher(
          v.specialAreaFlag,
          dateValidator(notSelectedToEmpty(v.specialAreaEndDate), {
            type: "future",
            startDate: v.specialAreaStartDate,
            options: {
              startLabel: "適用開始日",
              endLabel: "適用終了日"
            }
          })
        ),
        disabledWelfareServiceTo: validateSwitcher(
          v.disabledWelfareServiceFlag,
          dateValidator(
            notSelectedToEmpty({
              ...v.disabledWelfareServiceTo,
              day: "1"
            }),
            {
              type: "future",
              startDate: {
                ...v.disabledWelfareServiceFrom,
                day: "1"
              },
              options: {
                startLabel: "開始年月",
                endLabel: "終了年月"
              }
            }
          )
        ),
        handicapppedChildConsultationSupportTo: validateSwitcher(
          v.handicapppedChildConsultationSupportFlag,
          dateValidator(
            notSelectedToEmpty({
              ...v.handicapppedChildConsultationSupportTo,
              day: "1"
            }),
            {
              type: "future",
              startDate: {
                ...v.handicapppedChildConsultationSupportFrom,
                day: "1"
              },
              options: {
                startLabel: "開始年月",
                endLabel: "終了年月"
              }
            }
          )
        ),
        dateBeforeCreatePlan: validateSwitcher(
          v.beforeCreatePlanFlag,
          dateValidator(notSelectedToEmpty(v.dateBeforeCreatePlan), "required")
        ),
        dateBeforeGoneMonitoring: validateSwitcher(
          v.beforeGoneMonitoringFlag,
          dateValidator(
            notSelectedToEmpty(v.dateBeforeGoneMonitoring),
            "required"
          )
        )
      };
    })
  };
};

const recipientCertificateValidation = (
  values: UsersValues,
  facilityType: number | null
): RecipientCertificateErrors => {
  return {
    recipientCertificate: values.recipientCertificate.map((v) => {
      if (
        facilityType !== FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        facilityType !== v.targetType
      ) {
        return undefined;
      }
      return {
        userChargeLimitStartDate: validateSwitcher(
          v.userChargeLimitFlag,
          dateValidator(
            notSelectedToEmpty(v.userChargeLimitStartDate),
            "required"
          )
        ),
        userChargeLimitEndDate: validateSwitcher(
          v.userChargeLimitFlag,
          dateValidator(
            notSelectedToEmpty(v.userChargeLimitEndDate),
            "required",
            {
              type: "future",
              startDate: v.userChargeLimitStartDate,
              options: {
                startLabel: "適用開始日",
                endLabel: "適用終了日"
              }
            }
          )
        ),
        careSupportAuthStartDate: validateSwitcher(
          v.careSupportAuthFlag,
          dateValidator(
            notSelectedToEmpty(v.careSupportAuthStartDate),
            "required"
          )
        ),
        careSupportAuthEndDate: validateSwitcher(
          v.careSupportAuthFlag,
          dateValidator(
            notSelectedToEmpty(v.careSupportAuthEndDate),
            "required",
            {
              type: "future",
              startDate: v.careSupportAuthStartDate,
              options: {
                startLabel: "認定開始日",
                endLabel: "認定終了日"
              }
            }
          )
        ),
        careSupportPaymentStartDate: validateSwitcher(
          v.careSupportPaymentFlag,
          dateValidator(
            notSelectedToEmpty(v.careSupportPaymentStartDate),
            "required"
          )
        ),
        careSupportPaymentEndDate: validateSwitcher(
          v.careSupportPaymentFlag,
          dateValidator(
            notSelectedToEmpty(v.careSupportPaymentEndDate),
            "required",
            {
              type: "future",
              startDate: v.careSupportPaymentStartDate,
              options: {
                startLabel: "支給決定開始日",
                endLabel: "支給決定終了日"
              }
            }
          )
        ),

        planSupportPaymentStartDate: validateSwitcher(
          v.planSupportPaymentFlag,
          dateValidator(
            notSelectedToEmpty(v.planSupportPaymentStartDate),
            "required"
          )
        ),
        planSupportPaymentEndDate: validateSwitcher(
          v.planSupportPaymentFlag,
          dateValidator(
            notSelectedToEmpty(v.planSupportPaymentEndDate),
            "required",
            {
              type: "future",
              startDate: v.planSupportPaymentStartDate,
              options: {
                startLabel: "支給決定開始日",
                endLabel: "支給決定終了日"
              }
            }
          )
        ),
        planSupportMonitorStartDate: validateSwitcher(
          v.planSupportMonitorFlag,
          dateValidator(
            notSelectedToEmpty(v.planSupportMonitorStartDate),
            "required"
          )
        ),
        planSupportMonitorEndDate: validateSwitcher(
          v.planSupportMonitorFlag,
          dateValidator(
            notSelectedToEmpty(v.planSupportMonitorEndDate),
            "required",
            {
              type: "future",
              startDate: v.planSupportMonitorStartDate,
              options: {
                startLabel: "モニタリング開始日",
                endLabel: "モニタリング終了日"
              }
            }
          )
        )
      };
    })
  };
};

export const validation = (
  values: UsersValues,
  facilityType: number | null
): FacilityErrors => {
  const basicErrors = basicValidation(values, facilityType);
  const serviceUseErrors = serviceUseValidation(values, facilityType);
  const recipientCertificateErrors = recipientCertificateValidation(
    values,
    facilityType
  );
  return { ...basicErrors, ...serviceUseErrors, ...recipientCertificateErrors };
};
