import * as React from "react";
import { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import {
  StaffTreatmentSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DEFAULT_RADIO_VALUE,
  SpecificBetterSupporterConditionTypes2024,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import {
  SpecificOfficeType,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST
} from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/JUDOHOMONKAIGO/facility/initialValues";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";

// なしと福祉・介護職員等処遇改善（Ⅰ）-（Ⅴ）の選択肢
const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionTypes2024
);
// なしと、福祉・介護職員等処遇改善（Ⅴ）1 - 14の選択肢
const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionSubTypes2024
);

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);

const specificOfficeTypeRadioItems = generateRadioItems(SpecificOfficeType);

type Props = {
  formikProps: FormikProps<FacilityValues>;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = useState(false);
  // 福祉・介護職員等ベースアップ等支援加算のdisabled-state
  const [
    disableBetterSupporterConditionBaseUpFlg,
    setDisableBetterSupporterConditionBaseUpFlg
  ] = useState(false);

  const [
    staffTreatmentSpecificSystemTypeRadioItems,
    setStaffTreatmentSpecificSystemTypeRadioItems
  ] = useState(STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST);

  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );
  const specificFacilitiesAddition: string = getIn(
    props.formikProps.values,
    "additionalItem.specificFacilitiesAddition"
  );

  useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.formikProps.setFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.formikProps.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  const isSelectedConditionType2024 =
    props.formikProps.values.additionalItem
      .specificBetterSupporterCondition202404 ===
    `${SELECTED_CONDITION_TYPE_2024_V}`;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (!isSelectedConditionType2024) {
      props.formikProps.setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
    setDisableBetterSupporterConditionBaseUpFlg(disabled);
    const specificDisabled = specificFacilitiesAddition === "0";
    const list = [...staffTreatmentSpecificSystemTypeRadioItems];
    list[1].disabled = specificDisabled;
    setStaffTreatmentSpecificSystemTypeRadioItems(list);
  }, [staffTreatmentSystemType, specificFacilitiesAddition]);

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        <FormikRadioButtons
          name="additionalItem.specificFacilitiesAddition"
          label="特定事業所加算"
          options={specificOfficeTypeRadioItems}
        />
        <FormGroup style={{ marginBottom: 28 }}>
          <FormikRadioButtons
            name="additionalItem.specificBetterSupporterCondition202404"
            style={{ marginBottom: 0 }}
            label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
            options={SpecificBetterSupporterConditionType2024RadioItems}
            onClick={onChangeSpecificBetterSupporterConditionType2024}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                }
              />
            }
          />
          {isSelectedConditionType2024 && (
            <FormikRadioButtons
              style={{ marginLeft: 48, marginTop: -2, marginBottom: 0 }}
              name="additionalItem.specificBetterSupporterCondition202404Sub"
              label=""
              options={SpecificBetterSupporterConditionTypeSub2024RadioItems}
            />
          )}
        </FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
      </FormGroup>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableBetterSupporterConditionBaseUpFlg}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.lifeSupportHubInDistrictFlg"
          label="地域生活支援拠点等"
        />
      </FormGroup>
    </FormPaper>
  );
};
