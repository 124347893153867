import * as React from "react";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FieldWrapper from "@components/atoms/FieldWrapper";
import FormLabel from "@components/molecules/FormLabel";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { CSSProperties } from "react";

type OwnProps = {
  label: string | JSX.Element;
  highEmphasis?: boolean;
  onChangeHook?: () => void;
  labelStyle?: CSSProperties;
};

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "0 10px 0 12px",
      position: "absolute",
      width: 42,
      height: 42
    },
    formControlLabelStyle: {
      marginLeft: 42,
      marginRight: -16 // MuiFormControlLabel-root{margin-right: 16px}の打ち消し
    }
  });

export type MuiCheckboxProps = OwnProps &
  CheckboxProps &
  WithStyles<typeof styles>;

const MuiCheckboxCore = ({
  label,
  style,
  onChangeHook,
  highEmphasis,
  color = "secondary",
  classes,
  labelStyle,
  ...props
}: MuiCheckboxProps): JSX.Element => (
  <FieldWrapper
    type="checkbox"
    size="auto"
    style={{ margin: "0 0 18px", ...style }}
    onChangeHook={onChangeHook}
  >
    <FormLabel
      control={
        <Checkbox classes={{ root: classes.root }} color={color} {...props} />
      }
      label={label}
      labelStyle={labelStyle}
      highEmphasis={highEmphasis}
      formControlLabelStyle={classes.formControlLabelStyle}
    />
  </FieldWrapper>
);

export const MuiCheckbox = withStyles(styles)(MuiCheckboxCore);
