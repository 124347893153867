import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import AuthTemplate from "@components/templates/AuthTemplate";
import LoginForm from "@components/organisms/auth/LoginForm";
import { LoginCaution } from "@components/organisms/auth/LoginCaution";
import { recommendationCheck } from "@utils/recommendation";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      "@media only screen and (max-width: 639px)": {
        paddingBottom: 64
      }
    },
    recommendationForm: {
      order: 1
    },
    notRecommendationForm: {
      order: 2,
      marginTop: 16
    },
    recommendationCaution: {
      order: 2,
      marginTop: 16
    },
    notRecommendationCaution: {
      order: 1
    }
  });
type Props = WithStyles<typeof styles> & RouteComponentProps;

const LoginCore = (props: Props): JSX.Element => {
  const { history, classes } = props;

  // 機器ブラウザ情報取得
  const res = recommendationCheck();

  return (
    <AuthTemplate>
      <div className={classes.wrapper}>
        <div
          className={
            res.recommendation
              ? classes.recommendationForm
              : classes.notRecommendationForm
          }
        >
          <LoginForm history={history} />
        </div>
        <div
          className={
            res.recommendation
              ? classes.recommendationCaution
              : classes.notRecommendationCaution
          }
        >
          <LoginCaution res={res} />
        </div>
      </div>
    </AuthTemplate>
  );
};

export const Login = withStyles(styles)(LoginCore);
