import { SelectDateValue } from "@interfaces/ui/form";

/**
 * year の "" を "NOT_SELECTED" に変換
 * @param value
 */
const emptyToNotSelected = (value: SelectDateValue): SelectDateValue => {
  return {
    ...value,
    year: value.year === "" ? "NOT_SELECTED" : value.year
  };
};

export default emptyToNotSelected;
