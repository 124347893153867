import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import unitsApi from "@api/requests/units";

export const fetchOptionalCustomInfo = (
  dispatch: Dispatch
) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchOptionalCustomInfoStarted());
  await unitsApi
    .getOptionalCustomInfo()
    .then((res) => dispatch(actions.fetchOptionalCustomInfoSuccess(res.data)))
    .catch((e) => {
      dispatch(actions.fetchOptionalCustomInfoFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postUseCommonOperationRecord = (dispatch: Dispatch) => async (
  setting: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postUseCommonOperationRecordStarted());
  await unitsApi
    .postUseOperationRecord({ target: "common", setting })
    .then(() => {
      dispatch(actions.postUseCommonOperationRecordSuccess(setting));
    })
    .catch((e) => {
      dispatch(
        actions.postUseCommonOperationRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postUseUnitsOperationRecord = (dispatch: Dispatch) => async (
  setting: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postUseUnitsOperationRecordStarted());
  await unitsApi
    .postUseOperationRecord({ target: "units", setting })
    .then(() => {
      dispatch(actions.postUseUnitsOperationRecordSuccess(setting));
    })
    .catch((e) => {
      dispatch(
        actions.postUseUnitsOperationRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type UnitsDispatcher = {
  fetchOptionalCustomInfo: () => Promise<void>;
  postUseCommonOperationRecord: (setting: number) => Promise<void>;
  postUseUnitsOperationRecord: (setting: number) => Promise<void>;
};

export default (dispatch: Dispatch): UnitsDispatcher => ({
  fetchOptionalCustomInfo: fetchOptionalCustomInfo(dispatch),
  postUseCommonOperationRecord: postUseCommonOperationRecord(dispatch),
  postUseUnitsOperationRecord: postUseUnitsOperationRecord(dispatch)
});
