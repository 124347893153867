import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  createStyles,
  WithStyles,
  withStyles,
  Theme,
  StyleRules
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import SectionTitle from "@components/atoms/SectionTitle";
import Table from "@components/molecules/Table";
import { HistoryResultData, InvoiceState } from "@stores/domain/invoice/type";
import TableHead, { HeaderProps } from "@components/molecules/TableHead";
import { dateToLocalisedString } from "@utils/date";
import { FacilityType } from "@constants/variables";
import { FACILITY_TYPE_LIST } from "@constants/mgr/KEIKAKUSODAN/variables";

const FILE_TARGET_JISSEKI = "jisseki";
const FILE_TARGET_UPLIMIT = "uplimit";
const kindOfFileTarget = {
  jisseki: "サービス提供実績記録票",
  seikyu_meisai: "請求書・明細書",
  uplimit: "上限額管理事業所明細書"
};

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    tableContainer: {
      padding: `30px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: spacing.unit * 2,
      boxShadow: "none"
    },
    tableCell: {
      fontSize: 14,
      padding: "14px 0 14px 16px",
      "&:last-child": {
        paddingTop: 0,
        paddingRight: 16,
        paddingBottom: 4
      }
    },
    tableRow: {
      "&:nth-child(2n)": {
        background: "#f5f5f5"
      },
      "&>td": {
        borderBottom: "none"
      }
    },
    cellStyle: {
      padding: spacing.unit * 2,
      borderBottom: "none"
    },
    tab: {
      height: 32
    },
    historyButton: {
      minWidth: "auto",
      minHeight: 32,
      width: 80,
      margin: `6px ${spacing.unit}px 2px 0`,
      "&>span": {
        lineHeight: 1
      }
    },
    receiptHistoryButton: {
      width: 112,
      minHeight: 32,
      marginTop: 6,
      marginBottom: 2,
      "&>span": {
        lineHeight: 1
      }
    },
    noData: {
      clear: "both",
      marginTop: 80,
      marginBottom: 52,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 14
    }
  });

type CellParam = {
  role?: string;
  align: "left" | "center" | "right";
  className?: string;
  label: string | React.ReactElement;
};
type RowParam = {
  cells: CellParam[];
};

type OwnProps = {
  facilityType: FacilityType;
  invoice: InvoiceState;
  history: RouteComponentProps["history"];
  handleClick: (title: string[], path: string, dataKey: string) => () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadHistory = (props: Props): JSX.Element => {
  const { classes } = props;

  const createHeaderItems = (facilityType: FacilityType): CellParam[] => {
    const commonItems = {
      targetDate: {
        className: classes.cellStyle,
        align: "left" as const,
        label: "対象月"
      },
      fileTarget: {
        className: classes.cellStyle,
        align: "left" as const,
        label: "ファイル種別"
      },
      createdAt: {
        className: classes.cellStyle,
        align: "left" as const,
        label: "日時"
      },
      buttons: {
        className: classes.cellStyle,
        align: "left" as const,
        label: "利用者帳票印刷"
      }
    };

    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
        return [
          commonItems.targetDate,
          {
            className: classes.cellStyle,
            align: "left",
            label: "サービス種類"
          },
          commonItems.fileTarget,
          commonItems.createdAt,
          commonItems.buttons
        ];
      default:
        return [
          commonItems.targetDate,
          commonItems.fileTarget,
          commonItems.createdAt,
          commonItems.buttons
        ];
    }
  };

  const createButtons = (
    facilityType: FacilityType,
    fileTarget: string,
    dataKey: string
  ): JSX.Element | null => {
    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
        if (fileTarget === FILE_TARGET_UPLIMIT) {
          return null;
        }
        break;
      default:
        if (
          fileTarget === FILE_TARGET_JISSEKI ||
          fileTarget === FILE_TARGET_UPLIMIT
        ) {
          return null;
        }
    }

    return (
      <>
        <KnowbeButton
          kind="outlineWhite"
          className={classes.historyButton}
          onClick={props.handleClick(["請求日"], "seikyu", dataKey)}
        >
          請求書
        </KnowbeButton>
        <KnowbeButton
          kind="outlineWhite"
          onClick={props.handleClick(["発行日"], "receipt", dataKey)}
          className={classes.historyButton}
        >
          領収書
        </KnowbeButton>
        <KnowbeButton
          kind="outlineWhite"
          onClick={props.handleClick(
            ["受領日", "通知日"],
            "agency_receipt",
            dataKey
          )}
          className={classes.receiptHistoryButton}
        >
          代理受領書
        </KnowbeButton>
      </>
    );
  };

  const createCells = (
    facilityType: FacilityType,
    historyResultData: HistoryResultData
  ): CellParam[] => {
    const commonCells = {
      targetDate: {
        className: classes.cellStyle,
        align: "left" as const,
        label: dateToLocalisedString(
          `${historyResultData.target_date}01`,
          "YYYY年M月分"
        )
      },
      fileTarget: {
        className: classes.cellStyle,
        align: "left" as const,
        label: kindOfFileTarget[historyResultData.file_target]
      },
      createdAt: {
        className: classes.cellStyle,
        align: "left" as const,
        label: dateToLocalisedString(
          historyResultData.created_at,
          "YYYY年M月D日 H時m分"
        )
      },
      buttons: {
        className: classes.cellStyle,
        align: "left" as const,
        label: createButtons(
          facilityType,
          historyResultData.file_target,
          historyResultData.dataKey
        )
      }
    };

    switch (facilityType) {
      case FacilityType.KEIKAKUSODAN:
        return [
          commonCells.targetDate,
          {
            className: classes.cellStyle,
            align: "left",
            label:
              historyResultData.type_consultation !== undefined
                ? FACILITY_TYPE_LIST[historyResultData.type_consultation]
                : ""
          },
          commonCells.fileTarget,
          commonCells.createdAt,
          commonCells.buttons
        ];

      default:
        return [
          commonCells.targetDate,
          commonCells.fileTarget,
          commonCells.createdAt,
          commonCells.buttons
        ];
    }
  };

  const header: HeaderProps = {
    tabIndex: -1,
    key: 0,
    selected: false,
    items: createHeaderItems(props.facilityType)
  };

  const rows: RowParam[] = props.invoice.history.map((h) => {
    return { cells: createCells(props.facilityType, h) };
  });

  return (
    <Paper elevation={0} className={classes.tableContainer}>
      <SectionTitle label="請求書類CSVファイルのダウンロード履歴" />
      <Table key="daily-report-table">
        <TableHead
          role={header.role}
          ariaChecked={header.ariaChecked}
          tabIndex={header.tabIndex}
          key={header.key}
          selected={header.selected}
          items={header.items}
          rowStyle={header.rowStyle}
        />
        <TableBody tabIndex={0} key={`"${header.key}-body"`}>
          {rows.map((row, idx) => (
            <TableRow
              // rowはindex以外に割り当て可能なkeyがない
              // eslint-disable-next-line react/no-array-index-key
              key={`${header.key}-row-${idx}`}
              className={classes.tableRow}
            >
              {row.cells.map((cell, i) => (
                <TableCell
                  // cellはindex以外に割り当て可能なkeyがない
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${header.key}-row-${idx}-cell-${i}`}
                  align={cell.align}
                  className={classes.tableCell}
                >
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {rows.length === 0 && (
        <div className={classes.noData}>
          ダウンロード履歴がありません。
          <br />
          ダウンロード履歴は給付費請求必要書類をダウンロードした後に表示されます。
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(DownloadHistory);
