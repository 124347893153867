import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

// utils
import * as URL from "@constants/url";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import { UserState } from "@stores/domain/user/type";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/CHIIKIIKO/planMonitoringMeeting/types";
import { PlanMonitoringMeetingRecordList } from "@components/organisms/mgr/CHIIKIIKO/record/planMonitoringMeeting/PlanMonitoringMeetingRecordList";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
}>;

type StateProps = {
  user: UsersInFacilityState["user"];
  userState: UserState;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
};

type DispatchProps = {
  fetchPlanMonitoringMeeting: (uifId: string) => void;
  fetchOne: (uifId: string) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 利用者ごと > 計画・報告書・会議記録 > 一覧
 */
const PlanMonitoringMeetingListCore = (props: Props): JSX.Element => {
  const { uifId } = props.match.params;
  const { classes } = props;

  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: URL.RECORD_USERS_SUMMARY }
  ];

  // 現在ページのタイトル名
  const pageName = "計画・報告書・会議記録";

  React.useEffect(() => {
    props.fetchPlanMonitoringMeeting(uifId);
    props.fetchOne(uifId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <PlanMonitoringMeetingRecordList
          pageName={pageName}
          userName={props.userName}
          uifId={uifId}
          planMonitoringMeeting={props.planMonitoringMeeting}
          history={props.history}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.CHIIKIIKO.userInFacility.user,
    userState: state.user as UserState,
    planMonitoringMeeting:
      state.CHIIKIIKO.planMonitoringMeeting.planMonitoringMeeting
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO } = dispatches;
  const planMonitoringMeetingDispatcher = CHIIKIIKO.planMonitoringMeetingDispatcher(
    dispatch
  );
  return {
    fetchPlanMonitoringMeeting: (uifId: string): void => {
      planMonitoringMeetingDispatcher.fetchPlanMonitoringMeeting(uifId);
    },
    fetchOne: (uifId: string): void => {
      dispatches.CHIIKIIKO.userInFacilityDispatcher(dispatch).fetchOne(uifId);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const PlanMonitoringMeetingList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(PlanMonitoringMeetingListCore));
