import * as React from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, Prompt } from "react-router-dom";
import dispatches from "@stores/dispatches";
import NavigationTransitionDialog from "@components/molecules/dialog/NavigationTransitionDialog";
import { NavigationTransitionDialog as MobileNavigationTransitionDialog } from "@componentsMobile/molecules/dialog/NavigationTransitionDialog";
import { AppState } from "@stores/type";
import * as H from "history";

type StateProps = {
  needsStopHistory: boolean;
  isMobile: boolean;
};

type DispatchProps = {
  allowChangesToHistory: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

type Location = {
  pathname: string;
  state: H.LocationState;
};

type State = {
  openModal: boolean;
  isTransferring: boolean;
  nextLocation: string;
  locationState: H.LocationState;
};

class NavigationTransitionPrompt extends React.Component<Props, State> {
  private trigger: symbol;

  constructor(props: Props) {
    super(props);
    this.state = {
      openModal: false,
      isTransferring: !props.needsStopHistory,
      nextLocation: "",
      locationState: null
    };
    this.trigger = Symbol.for(`PreventNavigationTransitionPrompt${Date.now()}`);
  }

  public componentDidMount(): void {
    window[this.trigger] = this.show;
  }

  public componentDidUpdate(): void {
    if (this.state.isTransferring && this.state.nextLocation !== "") {
      const currentLocation = this.props.history.location.pathname;
      if (this.state.nextLocation !== currentLocation) {
        // リロードしてからブラウザバックをされると遷移はしないがurlが書き換わるので一旦現在ページに書き換え
        // 上記の状態は複数回ブラウザバックも可能なので、これだと正確な履歴に戻すことができない
        this.props.history.replace(currentLocation);
      }
      this.setIsTransferring(); // 無限にhistory.pushが実行されるのを防ぐ
      if (this.state.locationState) {
        this.props.history.push({
          pathname: this.state.nextLocation,
          state: this.state.locationState
        });
      } else {
        this.props.history.push(this.state.nextLocation);
      }
    }
  }

  public componentWillUnmount(): void {
    delete window[this.trigger];
  }

  private setIsTransferring = (): void => {
    this.setState({ isTransferring: false });
  };

  private show = (
    allowTransitionCallback: (isTransferring: boolean) => void
  ): void => {
    this.setState({ openModal: true, isTransferring: false }, () =>
      allowTransitionCallback(false)
    );
  };

  private handleCancel = (): void => {
    this.setState({ openModal: false });
  };

  private handleConfirm = (): void => {
    this.props.allowChangesToHistory();
    this.setState({ openModal: false, isTransferring: true });
  };

  private handleTransition = (location: Location): string | true => {
    this.setState({
      nextLocation: location.pathname,
      locationState: location.state || null
    });
    if (!this.props.needsStopHistory) {
      return true;
    }
    return Symbol.keyFor(this.trigger) || true;
  };

  public render(): JSX.Element {
    return (
      <>
        <Prompt
          when={this.props.needsStopHistory}
          message={this.handleTransition}
        />
        {!this.props.isMobile ? (
          <NavigationTransitionDialog
            isOpen={this.state.openModal}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
          />
        ) : (
          <MobileNavigationTransitionDialog
            isOpen={this.state.openModal}
            onConfirm={this.handleConfirm}
            onCancel={this.handleCancel}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  isMobile: state.user.isMobile
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  return {
    allowChangesToHistory: (): Promise<void> => uiDispatches.stopHistory(false)
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavigationTransitionPrompt)
);
