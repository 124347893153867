import * as React from "react";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  ServiceDeliveryRecordsInput,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
// ui
import { ServiceDeliveryDetailItems } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailItems";
import { ServiceDeliveryDetailExpenses } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailExpenses";
import { ServiceDeliveryDetailItems as ServiceDeliveryDetailItemsMobile } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryDetailItems";
import { InsertPhraseModal } from "@components/organisms/mgr/common/record/InsertPhraseModal";
import { InsertPhraseModal as InsertPhraseModalMobile } from "@componentsMobile/organisms/mgr/common/record/InsertPhraseModal";
import { ServiceDeliveryDetailExpenses as ServiceDeliveryDetailExpensesMobile } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryDetailExpenses";
// formik
import { FormikProps } from "formik";
import {
  ExpensesValues,
  ServiceDeliveryDetailValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import {
  CUSTOM_RECORD_VITAL_DEFAULT_CHOICE,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO,
  FacilityType,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO,
  SERVICE_HEAD_FIXED_ITEM,
  SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE,
  SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE
} from "@constants/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import Divider from "@material-ui/core/Divider";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import get from "lodash-es/get";
import {
  isCost,
  isMoneyAndDeposit,
  isVital
} from "@utils/domain/serviceDelivery/extractDefaultItems";

const styles = (): StyleRules =>
  createStyles({
    divider: {
      backgroundColor: "#cfd8dc",
      margin: "24px 0px 0px 0px"
    }
  });

type Props = {
  isMobile: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  customRecords: CustomRecordsWithCategoryState;
  categoryType: number;
  practitionerNum: number;
  facilityType: FacilityType;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  isEdit?: boolean;
} & WithStyles<typeof styles>;

export type SortedCustomRecords = {
  customRecord: CustomRecordsWithCategoryState[0]["custom_record_items"][0];
  checkedItems?: ServiceDeliveryRecordsInput[] | null;
}[];

const ServiceDeliveryDetailContainerCore = (props: Props): JSX.Element => {
  const [isInsertPhraseModal, setInsertPhraseModal] = React.useState(false);
  const [insertPhraseId, setInsertPhraseId] = React.useState(0);
  const [insertPhraseFieldName, setInsertPhraseFieldName] = React.useState("");
  const { customRecords, practitionerNum, categoryType } = props;
  const { values: formikValues } = props.formikProps;
  const serviceDeliveryRecords =
    props.detailRecords &&
    props.detailRecords.serviceDeliveryRecordPractitioners.length >=
      practitionerNum
      ? props.detailRecords.serviceDeliveryRecordPractitioners[
          practitionerNum - 1
        ].serviceDeliveryRecords
      : null;

  const customRecordsCategory = customRecords.find(
    (item) => item.category_type === categoryType
  );
  const practitionerValues =
    practitionerNum === 1
      ? formikValues.serviceDeliveryRecordPractitioners1
      : formikValues.serviceDeliveryRecordPractitioners2;

  const customRecordsItems = customRecordsCategory
    ? customRecordsCategory.custom_record_items
    : [];

  // 定型分モーダル表示
  const openInsertPhraseModal = (
    fieldName: string,
    customRecord: CustomRecordsWithCategoryState[0]["custom_record_items"][0]
  ) => (e: React.MouseEvent): void => {
    e.preventDefault();
    setInsertPhraseModal(true);
    setInsertPhraseFieldName(fieldName);
    setInsertPhraseId(customRecord.id);
  };

  // 定型分モーダル閉じる
  const closeInsertPhraseModal = (): void => {
    setInsertPhraseModal(false);
    setInsertPhraseFieldName("");
    setInsertPhraseId(0);
  };

  /**
   * カーソル外したとき、全角の数字及びピリオドを半角に変換する(重度の身体介護の場合は数字のみ)
   * 数値が1以上でかつ先頭が0のとき、最初の0以外の数字が表示されるまで先頭から0を省略する
   * ドットなしの形式の場合、ドットを自動で付ける（「体温」「体重」項目のみ）
   */
  const handleBlurDecimal = (
    defaultChoice: number | null,
    categoryTypeParam: number
  ) => (event: React.FormEvent<HTMLInputElement>): string | void => {
    // TODO: 今後種別やdefault_itemによる分岐が必要
    if (
      props.facilityType === FacilityType.JUDOHOMONKAIGO &&
      categoryTypeParam ===
        CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO.body_care
    ) {
      return event.currentTarget.value.replace(/[０-９．]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    }
    const halfWidthValue = event.currentTarget.value
      .replace(/[０-９．]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      )
      .replace(/^(0+)([0-9]+)/g, "$2");

    if (
      defaultChoice &&
      [CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.body_temperature].includes(
        defaultChoice
      )
    ) {
      if (parseFloat(halfWidthValue) > 0 && !halfWidthValue.includes(".")) {
        return `${halfWidthValue}.0`;
      }
    }
    return halfWidthValue;
  };

  /**
   * 「体温」「体重」項目のみ整数の場合は8文字以上入力できないように制限する(少数の場合は10文字まで可)
   */
  const handleChangeNumber = (defaultChoice: number | null) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string => {
    const currentTarget = event.target.value;
    // TODO: 今後種別やdefault_itemによる分岐が必要
    if (
      defaultChoice &&
      [CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.body_temperature].includes(
        defaultChoice
      ) &&
      !/[.．]/.test(currentTarget) &&
      currentTarget.length > 8
    ) {
      return currentTarget.slice(0, 8);
    }
    if (currentTarget.length > 10) {
      return currentTarget.slice(0, 10);
    }
    return currentTarget;
  };

  const formatMoney = (value: string): string => {
    const money = value
      .replace(/，|,/g, "")
      .replace(/[０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );

    const moneyValue = Number(money);
    if (Number.isNaN(moneyValue) || !money) {
      return value.replace(/[０-９，]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    }
    return moneyValue.toLocaleString();
  };

  /**
   * カーソルを外したとき、全角の数字を半角に変換し，入力された値が数値の時のみ3桁ずつで「,」を補完する。
   */
  const handleBlurMoney = (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    return formatMoney(event.currentTarget.value);
  };

  const multiTextUnitVital = ["度", "mmHg", "mmHg"];

  const multiTextUnitBodyCare = {
    number: "回",
    amount: "cc"
  };
  /**
   * 複数テキスト入力の単位
   */
  const createUnit = (
    choice: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["custom_record_item_choices"][0],
    defaultItem: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["default_item"],
    facilityTypeValues: FacilityType,
    categoryTypeParam: number
  ): string => {
    if (isVital(defaultItem, facilityTypeValues)) {
      return choice.default_choice
        ? multiTextUnitVital[choice.default_choice - 1]
        : "";
    }
    if (
      facilityTypeValues === FacilityType.JUDOHOMONKAIGO &&
      categoryTypeParam ===
        CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO.body_care
    ) {
      // (水分補給（量）)
      if (
        defaultItem ===
        SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.hydration
      ) {
        return multiTextUnitBodyCare.amount;
      }
      // (排泄管理（排尿）)
      if (
        defaultItem ===
        SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.urination_management
      ) {
        return choice.default_choice === 1
          ? multiTextUnitBodyCare.number
          : multiTextUnitBodyCare.amount;
      }
      // (排泄管理（排便）)
      if (
        defaultItem ===
        SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.excretion_management
      ) {
        return choice.default_choice === 1 ? multiTextUnitBodyCare.number : "";
      }
    }
    return "";
  };

  // カスタムレコードを表示する必要な項目のみ抽出、order順にソート・
  const detailCustomItems: SortedCustomRecords = [];
  customRecordsItems.forEach((customRecord) => {
    const categoryInputData =
      serviceDeliveryRecords &&
      serviceDeliveryRecords.find(
        (item) =>
          item.customRecords_category_id ===
          customRecord.custom_records_category_id
      );

    switch (customRecord.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const inputData = categoryInputData
          ? categoryInputData.input.find(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                item.input_data
            )
          : null;
        // visibility === 0かつ各項目に入力がない場合はスキップ
        if (customRecord.visibility === 1 || inputData) {
          detailCustomItems.push({ customRecord });
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItems = categoryInputData
          ? categoryInputData.input.filter(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                item.checked === 1
            )
          : null;
        // visibility === 0かつ各項目に一つもチェックされていない場合はスキップ
        if (
          customRecord.visibility === 1 ||
          (checkedItems && checkedItems.length > 0)
        ) {
          detailCustomItems.push({ customRecord, checkedItems });
        }
        break;
      }
      // 複数テキスト入力
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text:
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.money: {
        const inputDataItems = categoryInputData
          ? categoryInputData.input.filter(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                item.input_data
            )
          : null;

        // visibility === 0かつ各項目に入力がない場合はスキップ
        if (
          customRecord.visibility === 1 ||
          (inputDataItems && inputDataItems.length > 0)
        ) {
          detailCustomItems.push({ customRecord });
        }
        break;
      }
      // 選択式（入力項目）
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.mixture: {
        const checkedItemsMixture = categoryInputData
          ? categoryInputData.input.filter(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                item.checked === 1
            )
          : null;

        if (
          customRecord.visibility === 1 ||
          (checkedItemsMixture && checkedItemsMixture.length > 0)
        ) {
          detailCustomItems.push({ customRecord });
        }
        // visibility === 0かつ各項目に入力がない場合はスキップ
        break;
      }
      // チェックボックス（カスタム可）＋テキスト
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text: {
        const checkedItems = categoryInputData
          ? categoryInputData.input.filter(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                item.checked === 1
            )
          : null;
        const existCheckboxAndText = categoryInputData
          ? categoryInputData.input.filter(
              (item) =>
                item.custom_record_item_id === customRecord.id &&
                (item.checked === 1 || item.input_data)
            )
          : null;
        if (
          customRecord.visibility === 1 ||
          (existCheckboxAndText && existCheckboxAndText.length > 0)
        ) {
          detailCustomItems.push({ customRecord, checkedItems });
        }
        // visibility === 0かつ各項目に入力がない場合はスキップ
        break;
      }
      default:
        break;
    }
  });

  // 並び順が先頭固定のdefault_item
  const getHeadFixedItems = (): number[] => {
    if (SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(props.facilityType)) {
      return SERVICE_HEAD_FIXED_ITEM;
    }
    return [];
  };
  const headFixedItems = getHeadFixedItems();

  const sortedCustomRecordsItems = detailCustomItems.sort((a, b) => {
    // 固定項目を先頭に持ってくる
    const aIndex = headFixedItems.findIndex(
      (i) => i === a.customRecord.default_item
    );
    const bIndex = headFixedItems.findIndex(
      (i) => i === b.customRecord.default_item
    );
    if (aIndex >= 0 && bIndex >= 0) return aIndex - bIndex;
    if (aIndex >= 0) return -1;
    if (bIndex >= 0) return 1;
    // 固定項目以外のソート
    if (!a.customRecord.order && !b.customRecord.order) return 0;
    if (!a.customRecord.order) return 1;
    if (!b.customRecord.order) return -1;
    return a.customRecord.order - b.customRecord.order;
  });

  const changeTotal = (targetTotal: number, target: string): void => {
    // 金銭管理のカスタム項目ID取得
    const expenseRecord = sortedCustomRecordsItems.find((item) => {
      return isCost(item.customRecord.default_item, props.facilityType);
    });
    const expenseItemId = expenseRecord ? expenseRecord.customRecord.id : null; // 金銭管理>費用負担のdefaultItemID
    const getChoiceId = (defaultChoice: number): number | null => {
      if (!expenseRecord) return null;
      const choice = expenseRecord.customRecord.custom_record_item_choices.find(
        (c) => c.default_choice === defaultChoice
      );
      return choice ? choice.id : null;
    };

    const travelingTotalId = getChoiceId(
      SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.traveling_expenses
    );
    const otherTotalId = getChoiceId(
      SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.other_expense
    );
    const totalId = getChoiceId(
      SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.total
    );

    const expenseRecordKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.custom_record.input_type_multi_text`;
    if (expenseItemId && travelingTotalId && otherTotalId && totalId) {
      // 編集したtargetの合計を金銭管理>費用負担>targetに反映、また交通費＋その他費用を金銭管理>費用負担>合計に反映
      const targetTotalId =
        target === "traveling_expenses" ? travelingTotalId : otherTotalId;
      const anotherTotalId =
        target !== "traveling_expenses" ? travelingTotalId : otherTotalId;
      const anotherTotal = get(
        formikValues,
        `${expenseRecordKey}[${expenseItemId}][${anotherTotalId}].input_data`
      );
      if (!Number.isNaN(targetTotal)) {
        props.setFormikFieldValue(
          `${expenseRecordKey}[${expenseItemId}][${targetTotalId}].input_data`,
          formatMoney(`${targetTotal}`)
        );
        if (!Number.isNaN(+anotherTotal.replace(/,/g, ""))) {
          props.setFormikFieldValue(
            `${expenseRecordKey}[${expenseItemId}][${totalId}].input_data`,
            formatMoney(`${+targetTotal + +anotherTotal.replace(/,/g, "")}`)
          );
        }
      }
    }
  };

  // 金銭管理の交通費（その他費用）を編集した際、合計金額を計算して対象のカスタム項目に反映する
  const changeExpenses = (
    target: string, // 交通費(traveling_expenses) or その他費用(other_expenses)
    i: number,
    event?: React.FormEvent<HTMLInputElement>
  ): void => {
    const expensesKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.serviceDeliveryRecordPractitionerMoneyManagements.${target}`;
    const expenses: ExpensesValues = get(props.formikProps.values, expensesKey);
    // 合計値の算出
    let total = expenses.reduce((sum, e, index) => {
      // formikの値が更新前のタイミングなのでここで自身は足さずに後で足す
      if (!e.amount_money || i === index || e.isDelete) return sum;
      return sum + +e.amount_money.replace(/,/g, "");
    }, 0);
    // 自身の値をeventから持ってきて足す
    if (event) {
      const eventValue = event.currentTarget.value
        ? +event.currentTarget.value.replace(/,/g, "")
        : 0;
      total += eventValue;
    }
    changeTotal(total, target);
  };

  /**
   * カーソルを外したとき、全角の数字を半角に変換し，入力された値が数値の時のみ3桁ずつで「,」を補完する。
   * 交通費、その他費用の合計値を合計に反映する
   */
  const handleBlurCost = (
    defaultItem: number | null,
    defaultChoice: number | null
  ) => (event: React.FormEvent<HTMLInputElement>): string | void => {
    const targetValue = +event.currentTarget.value.replace(/,/g, "");
    if (isCost(defaultItem, props.facilityType)) {
      switch (defaultChoice) {
        case SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.traveling_expenses:
          changeTotal(targetValue, "traveling_expenses");
          break;
        case SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE.other_expense:
          changeTotal(targetValue, "other_expenses");
          break;
        default:
          break;
      }
    }
    return formatMoney(event.currentTarget.value);
  };

  /**
   * カーソルを外したとき、全角の数字を半角に変換し，入力された値が数値の時のみ3桁ずつで「,」を補完する。
   * 交通費、その他費用の合計値を合計に反映する
   */
  const handleBlurMoneyAndDeposit = (
    defaultItem: number | null,
    defaultChoice: number | null
  ) => (event: React.FormEvent<HTMLInputElement>): string | void => {
    if (isMoneyAndDeposit(defaultItem, props.facilityType)) {
      if (
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit ||
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
      ) {
        const eventValue = +event.currentTarget.value.replace(/,/g, "");
        // 所持金・預かり金のカスタム項目ID取得
        const record = sortedCustomRecordsItems.find((item) => {
          return isMoneyAndDeposit(
            item.customRecord.default_item,
            props.facilityType
          );
        });
        const itemId = record ? record.customRecord.id : null; // 金銭管理>所持金・預かり金のdefaultItemID
        const getChoiceId = (targetChoice: number): number | null => {
          if (!record) return null;
          const choice = record.customRecord.custom_record_item_choices.find(
            (c) => c.default_choice === targetChoice
          );
          return choice ? choice.id : null;
        };

        const moneyAndDepositId = getChoiceId(
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit
        );
        const usedMoneyId = getChoiceId(
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
        );
        const balanceId = getChoiceId(
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.balance
        );

        const expenseRecordKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.custom_record.input_type_multi_text`;
        if (itemId && moneyAndDepositId && usedMoneyId && balanceId) {
          let moneyAndDepositValue = get(
            formikValues,
            `${expenseRecordKey}[${itemId}][${moneyAndDepositId}].input_data`
          ).replace(/,/g, "");
          let usedMoneyValue = get(
            formikValues,
            `${expenseRecordKey}[${itemId}][${usedMoneyId}].input_data`
          ).replace(/,/g, "");
          // blurイベント直後の値はeventからしか取れないので上書きする
          if (
            defaultChoice ===
            SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit
          ) {
            moneyAndDepositValue = eventValue;
          }
          if (
            defaultChoice ===
            SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
          ) {
            usedMoneyValue = eventValue;
          }
          if (!Number.isNaN(+moneyAndDepositValue)) {
            props.setFormikFieldValue(
              `${expenseRecordKey}[${itemId}][${moneyAndDepositId}].input_data`,
              formatMoney(`${moneyAndDepositValue}`)
            );
          }
          if (!Number.isNaN(+usedMoneyValue)) {
            props.setFormikFieldValue(
              `${expenseRecordKey}[${itemId}][${usedMoneyId}].input_data`,
              formatMoney(`${usedMoneyValue}`)
            );
          }
          if (!Number.isNaN(+moneyAndDepositValue - +usedMoneyValue)) {
            props.setFormikFieldValue(
              `${expenseRecordKey}[${itemId}][${balanceId}].input_data`,
              formatMoney(`${+moneyAndDepositValue - +usedMoneyValue}`)
            );
          }
        }
      }
    }
    return formatMoney(event.currentTarget.value);
  };

  const handleBlurExpenses = (target: string, i: number) => (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    changeExpenses(target, i, event);
    return handleBlurMoney(event);
  };

  const inputTypeMoneyBlurHook = (
    defaultItem: number | null,
    defaultChoice: number | null
  ): ((event: React.FormEvent<HTMLInputElement>) => string | void) => {
    if (isCost(defaultItem, props.facilityType)) {
      return handleBlurCost(defaultItem, defaultChoice);
    }
    if (isMoneyAndDeposit(defaultItem, props.facilityType)) {
      return handleBlurMoneyAndDeposit(defaultItem, defaultChoice);
    }
    return handleBlurMoney;
  };

  return (
    <div>
      {props.isMobile ? (
        <>
          {SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
            props.facilityType
          ) &&
            categoryType ===
              CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.money_management && (
              <>
                <ServiceDeliveryDetailExpensesMobile
                  isEdit={!!props.isEdit}
                  formikProps={props.formikProps}
                  practitionerValues={practitionerValues}
                  practitionerNum={practitionerNum}
                  facilityType={props.facilityType}
                  handleBlurMoney={handleBlurMoney}
                  detailRecords={props.detailRecords}
                  target="traveling_expenses"
                  changeExpenses={changeExpenses}
                  handleBlurExpenses={handleBlurExpenses}
                />
                <ServiceDeliveryDetailExpensesMobile
                  isEdit={!!props.isEdit}
                  formikProps={props.formikProps}
                  practitionerValues={practitionerValues}
                  practitionerNum={practitionerNum}
                  facilityType={props.facilityType}
                  handleBlurMoney={handleBlurMoney}
                  detailRecords={props.detailRecords}
                  target="other_expenses"
                  changeExpenses={changeExpenses}
                  handleBlurExpenses={handleBlurExpenses}
                />
              </>
            )}
          <ServiceDeliveryDetailItemsMobile
            isEdit={!!props.isEdit}
            formikProps={props.formikProps}
            setFormikFieldValue={props.setFormikFieldValue}
            sortedCustomRecordsItems={sortedCustomRecordsItems}
            practitionerValues={practitionerValues}
            categoryType={categoryType}
            practitionerNum={practitionerNum}
            facilityType={props.facilityType}
            openInsertPhraseModal={openInsertPhraseModal}
            createUnit={createUnit}
            handleBlurDecimal={handleBlurDecimal}
            handleChangeNumber={handleChangeNumber}
            detailRecords={props.detailRecords}
            inputTypeMoneyBlurHook={inputTypeMoneyBlurHook}
          />
          {customRecordsCategory && (
            <InsertPhraseModalMobile
              isModalOpen={isInsertPhraseModal}
              onClose={closeInsertPhraseModal}
              formikProps={props.formikProps}
              customRecordsWithCategory={
                customRecordsCategory.custom_record_items
              }
              insertPhraseFieldName={insertPhraseFieldName}
              insertPhraseId={insertPhraseId}
            />
          )}
        </>
      ) : (
        <>
          {SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
            props.facilityType
          ) &&
            categoryType ===
              CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.money_management && (
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ marginRight: 32, width: "50%" }}>
                    <ServiceDeliveryDetailExpenses
                      formikProps={props.formikProps}
                      practitionerValues={practitionerValues}
                      practitionerNum={practitionerNum}
                      facilityType={props.facilityType}
                      handleBlurMoney={handleBlurMoney}
                      detailRecords={props.detailRecords}
                      target="traveling_expenses"
                      changeExpenses={changeExpenses}
                      handleBlurExpenses={handleBlurExpenses}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <ServiceDeliveryDetailExpenses
                      formikProps={props.formikProps}
                      practitionerValues={practitionerValues}
                      practitionerNum={practitionerNum}
                      facilityType={props.facilityType}
                      handleBlurMoney={handleBlurMoney}
                      detailRecords={props.detailRecords}
                      target="other_expenses"
                      changeExpenses={changeExpenses}
                      handleBlurExpenses={handleBlurExpenses}
                    />
                  </div>
                </div>
                <Divider variant="middle" className={props.classes.divider} />
              </>
            )}
          <ServiceDeliveryDetailItems
            formikProps={props.formikProps}
            setFormikFieldValue={props.setFormikFieldValue}
            sortedCustomRecordsItems={sortedCustomRecordsItems}
            practitionerValues={practitionerValues}
            categoryType={categoryType}
            practitionerNum={practitionerNum}
            facilityType={props.facilityType}
            openInsertPhraseModal={openInsertPhraseModal}
            createUnit={createUnit}
            handleBlurDecimal={handleBlurDecimal}
            handleChangeNumber={handleChangeNumber}
            handleBlurMoney={handleBlurMoney}
            detailRecords={props.detailRecords}
            handleBlurCost={handleBlurCost}
            handleBlurMoneyAndDeposit={handleBlurMoneyAndDeposit}
          />
          {customRecordsCategory && (
            <InsertPhraseModal
              isModalOpen={isInsertPhraseModal}
              onClose={closeInsertPhraseModal}
              formikProps={props.formikProps}
              customRecordsWithCategory={
                customRecordsCategory.custom_record_items
              }
              insertPhraseFieldName={insertPhraseFieldName}
              insertPhraseId={insertPhraseId}
            />
          )}
        </>
      )}
    </div>
  );
};
export const ServiceDeliveryDetailContainer = withStyles(styles)(
  ServiceDeliveryDetailContainerCore
);
