// react
import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
// ui
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
// components
import AdminTemplate from "@components/templates/AdminTemplate";
import { AssociatedFacilityListItem } from "@components/organisms/customer-support/AssociatedFacilityList";
// constants
import { HEADER_HEIGHT } from "@constants/styles";
// stores
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { AssociatedFacilityListState } from "@stores/domain/facilities/types";
import { UserState } from "@stores/domain/user/type";

const styles = (): StyleRules =>
  createStyles({
    root: {
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      backgroundColor: "#f5f5f5",
      overflowY: "scroll"
    },
    wrapper: {
      height: "calc(90vh)",
      margin: "21px 80px 10px 37px",
      overflow: "scroll"
    },
    list: {
      backgroundColor: "#fff",
      boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
      border: "solid 1px #dcdcdc",
      paddingTop: 0,
      paddingBottom: 0
    },
    listItemRoot: {
      width: "auto",
      boxSizing: "content-box"
    },
    divider: {
      margin: "0px 32px"
    }
  });

/**
 * 施設切替
 */

type DispatchProps = {
  handleLogout: () => void;
  fetchInitialData: () => void;
  updateAssociatedFacility: (value: {
    facility_id: UserState["facility_id"];
  }) => void;
};

type StateProps = {
  facilities: AssociatedFacilityListState["data"];
};

type Props = DispatchProps &
  StateProps &
  RouteComponentProps &
  WithStyles<typeof styles>;

const SwitchAssociatedFacilityPageCore = (props: Props): JSX.Element => {
  const {
    fetchInitialData,
    updateAssociatedFacility,
    classes,
    facilities
  } = props;
  useEffect(() => {
    fetchInitialData();
  }, []);

  const handleClick = (facility: { id: number }): void => {
    updateAssociatedFacility({ facility_id: facility.id });
  };

  return (
    <AdminTemplate pageName="施設切替">
      <div className={classes.wrapper}>
        <List className={classes.list}>
          {facilities.map((facility, index) => {
            const uniqueKey = `key-${index}`;
            return (
              <React.Fragment key={uniqueKey}>
                {index !== 0 ? <Divider className={classes.divider} /> : null}
                <AssociatedFacilityListItem
                  facility={facility}
                  handleClick={handleClick}
                />
              </React.Fragment>
            );
          })}
        </List>
      </div>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilities: state.facilities.data
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch, FacilityDispatcher, userDispatch } = dispatches;

  return {
    handleLogout: authDispatch(dispatch).logout,
    fetchInitialData: FacilityDispatcher(dispatch).AssociatedFacilityList,
    updateAssociatedFacility: userDispatch(dispatch).updateAssociatedFacility
  };
};

export const SwitchAssociatedFacilityPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SwitchAssociatedFacilityPageCore));
