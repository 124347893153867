import * as React from "react";

import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import knowbemgr_logoPng from "@images/logo_fix_knowbe_logo-wh.png"; // 仮

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      backgroundColor: "#37474F"
    },
    logo: {
      marginLeft: spacing.unit * 4,
      paddingTop: spacing.unit,
      paddingBottom: spacing.unit,
      width: spacing.unit * 11
    }
  });

type Props = WithStyles<typeof styles>;

const contactHeader = (props: Props): JSX.Element => {
  return (
    <AppBar position="static" color="inherit" className={props.classes.root}>
      <img
        src={knowbemgr_logoPng}
        alt="https://knowbe.jp/"
        className={props.classes.logo}
      />
    </AppBar>
  );
};

export default withStyles(styles)(contactHeader);
