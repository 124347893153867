import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import { FormikProps } from "formik";
import checkTime from "@validator/rules/checkTime";
import FormikTime from "@components/molecules/FormikTime";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikMultipleSelect from "@components/molecules/FormikMultipleSelect";

import { FieldItem } from "@interfaces/ui/form";
import { StaffData } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/types";
import {
  Status,
  RecordCarePlan,
  SupportCarePlanBasicInfo
} from "@interfaces/record/carePlan/carePlan";
import { SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM } from "@constants/mgr/KYOTAKUKAIGO/variables";
import {
  FacilityType,
  CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK,
  CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER,
  CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER_ITEM,
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG,
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM
} from "@constants/variables";
import differenceInMinutes from "date-fns/difference_in_minutes";

const styles = (): StyleRules =>
  createStyles({
    item: {
      padding: "16px",
      display: "flex",
      borderBottom: "1px solid #e0e0e0"
    },
    checkBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      "&>div": {
        margin: 0
      },
      "&>div>div": {
        margin: 0,
        padding: 0,
        "&>label": {
          margin: 0,
          "&>span": {
            padding: 0
          }
        }
      }
    },
    dayOfWeek: {
      "&>div>div": {
        display: "block",
        whiteSpace: "nowrap"
      }
    },
    dayOfWeekDisabled: {
      pointerEvents: "none",
      "&>div>div::before": {
        borderBottom: "1px dotted rgba(0, 0, 0, 0.87)"
      },
      "&>div>svg": {
        display: "none"
      }
    },
    nextDayFlgWrap: {
      display: "flex"
    },
    nextDayFlg: {
      "& div": {
        textOverflow: "unset"
      }
    },
    MT16: {
      marginTop: "16px"
    },
    ML24: {
      marginLeft: "24px"
    },
    ML16: {
      marginLeft: "16px"
    },
    ML8: {
      marginLeft: "8px"
    },
    W236: {
      width: "236px",
      "&>div": {
        width: "100%"
      }
    },
    W72: {
      maxWidth: "72px",
      "&>div": {
        width: "100%"
      }
    },
    W106: {
      width: "106px",
      "&>div": {
        width: "100%"
      }
    },
    W56: {
      width: "56px",
      "&>div": {
        width: "100%"
      }
    },
    W200: {
      width: "200px",
      "&>div": {
        width: "100%"
      }
    }
  });

type OwnProps = {
  item: SupportCarePlanBasicInfo;
  status: Status;
  isRides: boolean;
  unitKey: string;
  nameNumber: number;
  staffItems: StaffData[];
  formikProps: FormikProps<RecordCarePlan>;
  facilityType: FacilityType;
  schedulesLength: number;
};
type Props = OwnProps & WithStyles<typeof styles>;

const BasicInfoItemCore = (props: Props): JSX.Element => {
  const {
    classes,
    item,
    status,
    isRides,
    unitKey,
    nameNumber,
    staffItems,
    formikProps,
    facilityType,
    schedulesLength
  } = props;

  // サービス内容が未選択の時disabled
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);
  const setDisabled = (): void => {
    if (status === SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.SELECT.value) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };
  React.useEffect(() => {
    if (facilityType === FacilityType.KYOTAKUKAIGO) {
      setDisabled();
    } else if (
      facilityType === FacilityType.JUDOHOMONKAIGO ||
      facilityType === FacilityType.DOKOENGO ||
      facilityType === FacilityType.KODOENGO
    ) {
      setIsDisabled(false);
    }
  }, [status, facilityType]);

  // 削除を選択した時disabled
  const [isDelete, setIsDelete] = React.useState<boolean>(true);
  const { is_delete } = item;
  React.useEffect(() => {
    setIsDelete(is_delete);
  }, [is_delete]);

  // 人数によって提供担当者（資格）の個数を切り替え
  const [isParticipants2, setIsParticipants2] = React.useState(false);
  const { number_of_participants } = item;
  React.useEffect(() => {
    if (
      number_of_participants ===
      CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER_ITEM.TWO.value
    ) {
      setIsParticipants2(true);
    } else {
      setIsParticipants2(false);
    }
  }, [number_of_participants]);

  // 開始時間終了時間入力したら時間数自動計算
  const calculationTime = (
    start: string,
    end: string,
    nextDayFlg: string
  ): void => {
    if (!!checkTime(start) || !!checkTime(end) || start === "" || end === "") {
      formikProps.values.support_care_plan_schedules[
        schedulesLength
      ].support_care_plan_basic_info[nameNumber].number_of_time = "";
      return;
    }

    const baseDay = "2020-01-01";
    const nextDay = "2020-01-02";
    const endDay =
      nextDayFlg ===
      CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM.NEXT_DAY.value
        ? nextDay
        : baseDay;

    const totalMinutes = differenceInMinutes(
      `${endDay} ${end}`,
      `${baseDay} ${start}`
    );
    let time =
      totalMinutes % 15 === 0
        ? totalMinutes
        : totalMinutes - (totalMinutes % 15) + 15;
    time = time >= 0 ? time : 0;
    const res = time % 60 === 0 ? `${time / 60}.0時間` : `${time / 60}時間`;

    formikProps.values.support_care_plan_schedules[
      schedulesLength
    ].support_care_plan_basic_info[nameNumber].number_of_time = res;
  };
  // start_time・end_timeを変える度にend_time_next_day_flgのバリデーション実行させる
  const startValidation = (): void => {
    formikProps.setFieldTouched(
      `${unitKey}[${nameNumber}].end_time_next_day_flg`,
      true
    );
    formikProps.setFieldTouched(`${unitKey}[${nameNumber}].start_time`, true);
    formikProps.setFieldTouched(`${unitKey}[${nameNumber}].end_time`, true);
  };
  const onChangeStartTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string
  ): string | void => {
    startValidation();
    calculationTime(
      autoCorrectValue,
      item.end_time,
      item.end_time_next_day_flg
    );
  };
  const onChangeEndTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string
  ): string | void => {
    startValidation();
    calculationTime(
      item.start_time,
      autoCorrectValue,
      item.end_time_next_day_flg
    );
  };
  const onChangeNextDayFlg = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    calculationTime(item.start_time, item.end_time, event.target.value);
  };

  const ridesCheck = React.useCallback(() => {
    return !isRides ? (
      <FormikTextField
        label=""
        name={`${unitKey}[${nameNumber}].number_of_time`}
        maxLength={10}
        style={{ marginBottom: 0 }}
        disabled={isDelete}
      />
    ) : (
      <FormikTextField
        label=""
        name={`${unitKey}[${nameNumber}].number_of_rides`}
        style={{ marginBottom: 0 }}
        endAdornmentLabel="回"
        disabled={isDelete}
      />
    );
  }, [isRides, isDelete]);

  // 提供担当者（資格）1のOptions
  const staffOptions = (): FieldItem[] => {
    return staffItems.map((i) => {
      const licenseName =
        i.staffLicenseName === ""
          ? `${i.staffName}`
          : `${i.staffName}（${i.staffLicenseName}）`;
      return {
        label: licenseName,
        value: i.staffItemId
      };
    });
  };

  return (
    <div className={classes.item}>
      <div className={classes.W236}>
        {isDisabled ? (
          <ReadonlyTextField value="" defaultValue="" />
        ) : (
          <FormikMultipleSelect
            label=""
            name={`${unitKey}[${nameNumber}].day_of_week`}
            options={[
              {
                categoryName: "",
                items: CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK
              }
            ]}
            placeholder="選択してください"
            className={ClassNames(
              classes.dayOfWeek,
              isDelete && classes.dayOfWeekDisabled
            )}
            style={{ marginBottom: 0 }}
            isNotShot
          />
        )}
      </div>

      <div className={ClassNames(classes.nextDayFlgWrap, classes.ML16)}>
        <div className={classes.W56}>
          <ReadonlyTextField value={isDisabled ? "" : "当日"} defaultValue="" />
        </div>
        <div className={ClassNames(classes.W72, classes.ML8)}>
          {isDisabled ? (
            <ReadonlyTextField value="" defaultValue="" />
          ) : (
            <FormikTime
              label=""
              name={`${unitKey}[${nameNumber}].start_time`}
              placeholder="00:00"
              maxLength={5}
              style={{ marginBottom: 0 }}
              disabled={isDelete}
              onChangeHookTime={onChangeStartTime}
            />
          )}
        </div>
      </div>

      <div className={ClassNames(classes.nextDayFlgWrap, classes.ML16)}>
        <div className={ClassNames(classes.W56, classes.nextDayFlg)}>
          {isDisabled ? (
            <ReadonlyTextField value="" defaultValue="" />
          ) : (
            <FormikSelect
              label=""
              name={`${unitKey}[${nameNumber}].end_time_next_day_flg`}
              options={CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG}
              style={{ marginBottom: 0, marginTop: "-16px" }}
              disabled={isDelete}
              onChangeHook={onChangeNextDayFlg}
            />
          )}
        </div>
        <div className={ClassNames(classes.W72, classes.ML8)}>
          {isDisabled ? (
            <ReadonlyTextField value="" defaultValue="" />
          ) : (
            <FormikTime
              label=""
              name={`${unitKey}[${nameNumber}].end_time`}
              placeholder="00:00"
              maxLength={5}
              style={{ marginBottom: 0 }}
              disabled={isDelete}
              onChangeHookTime={onChangeEndTime}
            />
          )}
        </div>
      </div>

      <div className={ClassNames(classes.W106, classes.ML16)}>
        {isDisabled ? (
          <ReadonlyTextField value="" defaultValue="" />
        ) : (
          ridesCheck()
        )}
      </div>

      <div className={ClassNames(classes.W56, classes.ML16)}>
        {isDisabled ? (
          <ReadonlyTextField value="" defaultValue="" />
        ) : (
          <FormikSelect
            label=""
            name={`${unitKey}[${nameNumber}].number_of_participants`}
            options={CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER}
            style={{ marginBottom: 0, marginTop: "-16px" }}
            disabled={isDelete}
          />
        )}
      </div>

      <div>
        <div className={ClassNames(classes.W200, classes.ML16)}>
          {isDisabled ? (
            <ReadonlyTextField value="" defaultValue="" />
          ) : (
            <FormikSelect
              label=""
              name={`${unitKey}[${nameNumber}].practitioner1`}
              options={staffOptions()}
              placeholder="選択してください"
              isSelectablePlaceholder
              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
              style={{ marginBottom: 0, marginTop: "-16px" }}
              disabled={isDelete || staffOptions().length === 0}
            />
          )}
        </div>
        {isParticipants2 && (
          <div className={ClassNames(classes.W200, classes.ML16, classes.MT16)}>
            {isDisabled ? (
              <ReadonlyTextField value="" defaultValue="" />
            ) : (
              <FormikSelect
                label=""
                name={`${unitKey}[${nameNumber}].practitioner2`}
                options={staffOptions()}
                placeholder="選択してください"
                isSelectablePlaceholder
                emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                style={{ marginBottom: 0, marginTop: "-16px" }}
                disabled={isDelete || staffOptions().length === 0}
              />
            )}
          </div>
        )}
      </div>

      <div className={ClassNames(classes.checkBox, classes.ML24)}>
        {!isDisabled && (
          <FormikCheckbox
            name={`${unitKey}[${nameNumber}].is_delete`}
            label=""
          />
        )}
      </div>
    </div>
  );
};

export const BasicInfoItem = withStyles(styles)(BasicInfoItemCore);
