import {
  CarePlanStaffState,
  CarePlanState,
  CarePlanDetailState,
  DisplayListState,
  DataListState,
  DeleteCarePlanState
} from "@stores/domain/mgr/KODOENGO/carePlan/types";
import {
  GetCarePlanResponse,
  CarePlanItem,
  CarePlanDetailsItem,
  CarePlanList
} from "@api/requests/facility/getCarePlan";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/mgr/KODOENGO/Users/initial";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  DEFAULT_CHECKBOX_VALUE
} from "@constants/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import { FacilityState } from "@stores/domain/mgr/KODOENGO/facility/types";
import { cloneDeep } from "lodash-es";
import {
  convertTimeHHMM,
  dateToLocalisedString,
  parseDateString
} from "@utils/date";

type InitBaseInfo = {
  uifId: number;
  sei: string;
  mei: string;
};

// 規定値
const SPECIFIED_DATE = "1900/01/01";

const nullDataByParticipants = (
  participants: number,
  license_same_flg: number | null | undefined
): string[] => {
  switch (participants) {
    case 1:
      return [
        "license_same_flg",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_memo",
        "users_in_facility_care_plan_details2",
        "whole_calculated_hours",
        "duplicate_calculated_hours"
      ];
    case 2:
      if (license_same_flg) {
        return [
          "second_person_flg",
          "practitioner2_in_time",
          "practitioner2_out_time",
          "practitioner2_memo",
          "users_in_facility_care_plan_details2",
          "practitioner1_calculated_hours"
        ];
      }
      return ["second_person_flg", "practitioner1_calculated_hours"];
    default:
      return [];
  }
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  practitioner1_in_time: {
    requestKey: "practitioner1_in_time",
    cooperationKeys: [
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "users_in_facility_care_plan_details1"
    ]
  },
  practitioner1_out_time: {
    requestKey: "practitioner1_out_time",
    cooperationKeys: [
      "practitioner1_in_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "users_in_facility_care_plan_details1"
    ]
  },
  practitioner2_in_time: {
    requestKey: "practitioner2_in_time",
    cooperationKeys: [
      "practitioner2_out_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "users_in_facility_care_plan_details2"
    ]
  },
  practitioner2_out_time: {
    requestKey: "practitioner2_out_time",
    cooperationKeys: [
      "practitioner2_in_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "users_in_facility_care_plan_details2"
    ]
  },
  practitioner1_calculated_hours: {
    requestKey: "practitioner1_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  whole_calculated_hours: {
    requestKey: "whole_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  duplicate_calculated_hours: {
    requestKey: "duplicate_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  time_duplication_judgment_flg: {
    requestKey: "time_duplication_judgment_flg",
    cooperationKeys: [
      "practitioner1_calculated_hours",
      "whole_calculated_hours",
      "duplicate_calculated_hours"
    ]
  }
};

/**
 * yyyy-mm-dd HH:MM:SSの作成
 */
const createTime = (date?: string, time?: string): string => {
  let returnDate = "";
  if (date && time) {
    returnDate =
      dateToLocalisedString(date, "YYYY-MM-DD").concat(" ") +
      time.concat(":00");
  }
  return returnDate;
};

const normalizeCarePlanDetail = (
  list: CarePlanDetailsItem[]
): CarePlanDetailState[] => {
  return list
    ? list.map((record) => {
        return {
          id: record.id,
          actionClass: record.action_class,
          inTime: convertTimeHHMM(parseDateString(record.in_time)),
          outTime: convertTimeHHMM(parseDateString(record.out_time))
        };
      })
    : [];
};

const nullDataByFacility = (facility: FacilityState): string[] => {
  const target = [];
  // 「特定事業所加算＝（Ⅰ）」を選択しているときは、「喀痰吸引等実施」は非活性
  if (facility.specificFacilitiesAddition === "1") {
    target.push("sputum_implementation_flg");
  }

  return target;
};

/**
 * 明細の差分有無確認
 */
const normalizeDiffDetailParamToPostAPI = (
  before: CarePlanDetailsItem[] | undefined,
  after: CarePlanDetailsItem[] | undefined
): boolean => {
  if (before === undefined || after === undefined) {
    return false;
  }
  if (before.length === 0 && after.length === 0) {
    return false;
  }

  return true;
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

export const normalizeDetailData = (
  recordNo: number | null,
  carePlanItemList: CarePlanItem[],
  baseInfo: InitBaseInfo
): CarePlanState => {
  const targetRecord = carePlanItemList.filter((row) => {
    return row.id === recordNo && row.users_in_facility_id === baseInfo.uifId;
  });

  const record = recordNo && targetRecord.length === 1 ? targetRecord[0] : null;
  return {
    id: record ? record.id : 0,
    usersInFacilityId: record ? record.users_in_facility_id : baseInfo.uifId,
    dayOfWeek: record ? record.day_of_week : null,
    nameSei: record ? record.name_sei : baseInfo.sei,
    nameMei: record ? record.name_mei : baseInfo.mei,
    numberOfParticipants: record ? record.number_of_participants : null,
    practitioner1InTime:
      record && record.practitioner1_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_in_time))
        : "",
    practitioner1OutTime:
      record && record.practitioner1_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_out_time))
        : "",
    practitioner1CalculatedHours: record
      ? record.practitioner1_calculated_hours
      : null,
    practitioner1Memo: record ? record.practitioner1_memo : "",
    carePlanDetailState1: record
      ? normalizeCarePlanDetail(record.users_in_facility_care_plan_details1)
      : [],
    practitioner2InTime:
      record && record.practitioner2_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_in_time))
        : "",
    practitioner2OutTime:
      record && record.practitioner2_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_out_time))
        : "",
    practitioner2Memo: record ? record.practitioner2_memo : "",
    carePlanDetailState2: record
      ? normalizeCarePlanDetail(record.users_in_facility_care_plan_details2)
      : [],
    timeDuplicationJudgmentFlg: record
      ? record.time_duplication_judgment_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    wholeCalculatedHours: record ? record.whole_calculated_hours : null,
    duplicateCalculatedHours: record ? record.duplicate_calculated_hours : null,
    licenseSameFlg: record
      ? record.license_same_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    secondPersonFlg: record
      ? record.second_person_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    sputumImplementationFlg: record
      ? record.sputum_implementation_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE
  };
};

export const initialDataList = (
  record: CarePlanList | null,
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  baseInfo: InitBaseInfo
): DataListState => {
  return {
    no: record ? record.no : null,
    id: record ? record.id : null,
    plan: {
      dispatchNo: record && record.plan ? record.plan.dispatch_no : null,
      inTime: record && record.plan ? record.plan.in_time : null,
      outTime: record && record.plan ? record.plan.out_time : null,
      calculatedHours:
        record && record.plan ? record.plan.calculated_hours : null,
      numberOfParticipants:
        record && record.plan ? record.plan.number_of_participants : null
    },
    planDetail: normalizeDetailData(
      record ? record.id : null,
      carePlanList,
      baseInfo
    )
  };
};

export const normalizeDataListFromAPI = (
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  displayList: CarePlanList[],
  baseInfo: InitBaseInfo
): DataListState[] => {
  return displayList
    ? displayList.map((record) => {
        return initialDataList(record, carePlanList, baseInfo);
      })
    : [initialDataList(null, carePlanList, baseInfo)];
};

/**
 * サービス予定データ一覧の整形
 */
export const normalizeDisplayListFromAPI = (
  carePlanList: GetCarePlanResponse["data"]["users_in_facility_care_plan"],
  displayList: GetCarePlanResponse["data"]["display_list"]
): DisplayListState[] => {
  return displayList.map((record) => {
    const baseInfo: InitBaseInfo = {
      uifId: record.users_in_facility_id,
      sei: record.name_sei,
      mei: record.name_mei
    };

    return {
      usersInFacilityId: record.users_in_facility_id,
      nameSei: record.name_sei,
      nameMei: record.name_mei,
      dayOfWeek: record.day_of_week,
      dataList: normalizeDataListFromAPI(
        carePlanList,
        record.data_list,
        baseInfo
      )
    };
  });
};

/**
 * サービス予定情報
 */
export const normalizeCarePlanDataFromAPI = (
  result: GetCarePlanResponse
): CarePlanStaffState => {
  const normalizeReportList: CarePlanStaffState = {
    displayList: normalizeDisplayListFromAPI(
      result.data.users_in_facility_care_plan,
      result.data.display_list
    ),
    carePlanCount: result.data.users_in_facility_care_plan
      ? result.data.users_in_facility_care_plan.length
      : 0
  };

  return normalizeReportList;
};

const normalizeCarePlanDetailToAPI = (
  detailList: CarePlanDetailsFields[]
): CarePlanDetailsItem[] => {
  if (!detailList || detailList.length === 0) {
    return [];
  }

  const result = detailList.map((record) => {
    return {
      id: record.id,
      action_class:
        record.inTime && record.outTime ? castNumber(record.actionClass) : -1,
      in_time: createTime(SPECIFIED_DATE, record.inTime),
      out_time: createTime(SPECIFIED_DATE, record.outTime)
    };
  });

  return result.filter((record) => {
    return record.action_class !== -1;
  });
};

/**
 * サービス予定登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    uif_id: formValue.initial.usersInFacilityId,
    params: {
      users_in_facility_care_plan_id: formValue.initial.id,
      day_of_week: formValue.initial.dayOfWeek,
      number_of_participants: castNumber(
        formValue.initial.numberOfParticipants
      ),
      practitioner1_in_time: createTime(
        SPECIFIED_DATE,
        formValue.initial.practitioner1InTime
      ),
      practitioner1_out_time: createTime(
        SPECIFIED_DATE,
        formValue.initial.practitioner1OutTime
      ),
      practitioner1_calculated_hours:
        formValue.initial.numberOfParticipants === "1"
          ? castNumber(formValue.initial.practitioner1CalculatedHours)
          : null,
      practitioner1_memo: formValue.initial.practitioner1Memo,
      users_in_facility_care_plan_details1: normalizeCarePlanDetailToAPI(
        formValue.initial.carePlanDetails1
      ),
      practitioner2_in_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(SPECIFIED_DATE, formValue.initial.practitioner2InTime)
          : null,
      practitioner2_out_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(SPECIFIED_DATE, formValue.initial.practitioner2OutTime)
          : null,
      practitioner2_memo:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? formValue.initial.practitioner2Memo
          : null,
      users_in_facility_care_plan_details2:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? normalizeCarePlanDetailToAPI(formValue.initial.carePlanDetails2)
          : [],
      time_duplication_judgment_flg:
        formValue.initial.numberOfParticipants === "2"
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
      whole_calculated_hours:
        formValue.initial.numberOfParticipants === "2"
          ? castNumber(formValue.initial.wholeCalculatedHours)
          : null,
      duplicate_calculated_hours:
        formValue.initial.numberOfParticipants === "2"
          ? castNumber(formValue.initial.duplicateCalculatedHours)
          : null,
      license_same_flg: formValue.initial.licenseSameFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      second_person_flg: formValue.initial.secondPersonFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      sputum_implementation_flg: formValue.initial.sputumImplementationFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  };

  if (formValue.initial.numberOfParticipants === "1") {
    result.params.license_same_flg = null;
  }
  if (formValue.initial.numberOfParticipants === "2") {
    result.params.second_person_flg = null;
  }

  return result;
};

export const normalizeDiffParamToPostAPI = (
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI,
  facility: FacilityState
): RequestParamToPostAPI => {
  const nullTargetList = nullDataByParticipants(
    after.params.number_of_participants
      ? after.params.number_of_participants
      : 1,
    after.params.license_same_flg
  ).concat(nullDataByFacility(facility));

  const result: RequestParamToPostAPI = {
    uif_id: after.uif_id,
    params: {
      users_in_facility_care_plan_id:
        after.params.users_in_facility_care_plan_id,
      day_of_week: after.params.day_of_week,
      time_duplication_judgment_flg: after.params.time_duplication_judgment_flg
    }
  };

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    const nullFlg = nullTargetList.includes(key);
    const detailFlg =
      key === "users_in_facility_care_plan_details1" ||
      key === "users_in_facility_care_plan_details2";
    const nullValue = detailFlg ? [] : null;
    const diffFlg = !detailFlg
      ? before.params[key] !== after.params[key]
      : normalizeDiffDetailParamToPostAPI(
          before.params[key],
          after.params[key]
        );
    if (after.params.users_in_facility_care_plan_id === null) {
      // 初回の場合、null対象はnull、それ以外は入力値をセット
      result.params[key] = nullFlg ? nullValue : after.params[key];
    } else if (diffFlg) {
      result.params[key] = after.params[key];
    }
  });

  const diffDate = addRelationValue(result, after);

  let nullTarget = nullDataByFacility(facility);

  if (diffDate.params.number_of_participants) {
    nullTarget = nullTarget.concat(
      nullDataByParticipants(
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg
      )
    );
  }

  nullTarget.forEach((key) => {
    const detailFlg =
      key === "users_in_facility_care_plan_details1" ||
      key === "users_in_facility_care_plan_details2";
    const nullValue = detailFlg ? [] : null;
    diffDate.params[key] = nullValue;
  });

  return diffDate;
};

/**
 * サービス予定削除
 */
export const normalizeRequestParamToDeleteAPI = (
  target: DeleteCarePlanState
): RequestParamToDeleteAPI => {
  return {
    data: {
      target: [
        {
          users_in_facility_care_plan_id: target.users_in_facility_care_plan_id
        }
      ]
    }
  };
};
