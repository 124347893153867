import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 指定した自治体情報を削除する
 * @param id 自治体ID
 * doc: https://rhc-bd.atlassian.net/wiki/spaces/KNOW/pages/2694053889/DELETE+host+version+mgr+municipalities+municipality+id
 */
export const deleteFacilityMunicipality = async (
  id: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/municipalities/${id}`;
  return request.delete(url);
};

export default deleteFacilityMunicipality;
