import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    }
  });

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
  openPrintModal: () => void;
  disabledPrint: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryDailyHeaderCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.flex}>
      <div>
        <DateSelectButtonsDaily
          defaultDate={props.selectedDate}
          min={props.minDate}
          max={props.maxDate}
          onClickSubmit={props.onChangeDate}
        />
      </div>
      <div>
        <KnowbeButton
          onClick={props.openPrintModal}
          disabled={props.disabledPrint}
        >
          印刷
        </KnowbeButton>
      </div>
    </div>
  );
};

export const ServiceDeliveryDailyHeader = withStyles(styles)(
  ServiceDeliveryDailyHeaderCore
);
