import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import oneLetterWeekdaysJapanese from "@utils/date/oneLetterWeekdaysJapanese";

/**
 *  利用状況の詳細モーダルのヘッダー用の日付フォーマッター
 *  YYYY年M月D日 (d)
 */
export const dateTodayForAttendanceHeaderForDetailDaily = (
  date: Date = new Date(),
  withDayOfWeek = true
): string =>
  dateToLocalisedString(date, "YYYY年M月D日") +
  (withDayOfWeek
    ? ` (${oneLetterWeekdaysJapanese[+dateToLocalisedString(date, "d")]})`
    : "");
