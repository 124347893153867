import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { ReportResultPostAPI } from "@stores/v201910/domain/mgr/GroupHome/report/type";

export const postSetUsagePerformanceDaily = async (
  values: ReportResultPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/usage_performance/daily/set`;
  return request.post(url, values);
};

export default postSetUsagePerformanceDaily;
