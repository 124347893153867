import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { InOutReportDailyHeader } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportDailyHeader";
import { InOutReportDailyPaperHeader } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportDailyPaperHeader";
import { InOutReportTable } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { dateInYYYYMMDDFormat } from "@utils/date";
import {
  InitialDataValues,
  initialValues
} from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import { InOutReportDialog } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportDialog";
import { InOutReportSetupDialog } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportSetupDialog";
import {
  REPORT_DAILY,
  ReportState,
  InoutConsultationResultsState,
  FacilitiesKEIKAKUSODANMonthlyType
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  INVOICE_PATHS,
  REPORT_FILTER_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { ResponseError } from "@stores/ui/type";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { FacilityType } from "@constants/variables";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: NOTICE_HEADER_HEIGHT,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      minHeight: 56,
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8,
      width: "100%"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  facility: FacilityState;
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
};

type DispatchProps = {
  fetchDaily: (date: Date) => Promise<void>;
  fetchFacility: () => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  responseErrorClear(): void;
  postFacilitiesKEIKAKUSODANMonthly: (
    args: FacilitiesKEIKAKUSODANMonthlyType
  ) => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績（日ごと）
 */
const InOutReportDailyCore = (props: Props): JSX.Element => {
  const { classes, inoutErrors, reportState, user } = props;

  const [selectedDate, setSelectedDate] = React.useState<Date>(
    props.initialDate
  );
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);
  const [data, setData] = React.useState<InitialDataValues>(initialValues());
  const [isOpenDetailModal, setIsOpenDetailModal] = React.useState<boolean>(
    false
  );
  const [isOpenSetupModal, setIsOpenSetupModal] = React.useState<boolean>(
    false
  );
  const [filterFlg, setFilterFlg] = React.useState<boolean>(false);
  const [filterValue, setFilterValue] = React.useState<string>("1");

  React.useEffect(() => {
    Promise.all([
      props.fetchDaily(selectedDate),
      props.fetchFacility(),
      props.fetchInoutError(selectedDate)
    ]);
  }, []);

  React.useEffect(() => {
    const top = document.getElementById("reportDailyHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, [document.getElementById("reportDailyHeader"), inoutErrors]);

  const onChangeDate = (date: Date): void => {
    const transitionDone = checkReportVersion(
      date,
      FacilityType.KEIKAKUSODAN,
      props.currentPageVersion,
      INVOICE_PATHS.reportDaily,
      props.history
    );

    if (!transitionDone) {
      setSelectedDate(date);
      props.fetchDaily(date);
      props.fetchInoutError(date);
    }
  };

  const onChangeFilterSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setFilterValue(e.target.value);
    setFilterFlg(e.target.value === REPORT_FILTER_LIST[1].value);
  };

  const onClickErrorDialog = (): void => {
    props.openErrorsDialog();
  };

  const openModal = (param: InoutConsultationResultsState): void => {
    setData(initialValues(param));
    props.fetchUserInFacility(param.usersInFacilityId.toString());
    setIsOpenDetailModal(true);
  };

  const openCreateModal = (uifName: string, uifId: number): void => {
    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues();
    init.initial.targetDate = dateInYYYYMMDDFormat(selectedDate);
    init.initial.name = uifName;
    init.initial.usersInFacilityId = uifId.toString();

    setData(init);
    props.fetchUserInFacility(uifId.toString());

    setIsOpenDetailModal(true);
  };

  const onCancel = (): void => {
    setIsOpenDetailModal(false);
  };

  const onSubmit = (): void => {
    props.fetchDaily(selectedDate);
    props.fetchInoutError(selectedDate);
  };

  const openSetupModal = (report: ReportState): void => {
    setData(initialValues(null, report));
    setIsOpenSetupModal(true);
  };

  const onSetupCancel = (): void => {
    setIsOpenSetupModal(false);
  };

  /**
   * RectangleBoxが受け付けるconfigを生成する
   */
  const genRectangleBoxConfig = (
    title1: string,
    title2: string,
    num: number | null | undefined,
    isNextMonthUse2LimitCount = false,
    unit = "人",
    denom?: string
  ): {
    title1: string;
    title2: string;
    denom: string | undefined;
    num: number | null | undefined;
    unit: string;
  } => {
    const number = isNextMonthUse2LimitCount && num === null ? null : num;

    return { title1, title2, denom, num: number, unit };
  };

  const createRectangleList = (
    report: ReportState
  ): {
    title1: string;
    title2: string;
    denom: string | undefined;
    num: number | null | undefined;
    unit: string;
  }[] => {
    const month = selectedDate.getMonth() + 1;
    const nextMonth = !(month === 12) ? month + 1 : 1;
    const rectangleList = [
      genRectangleBoxConfig(
        `${month}月のサービス利用`,
        "支援人数",
        report.reportDaily.summary.serviceUseCount
      ),
      genRectangleBoxConfig(
        `${month}月の継続サービス利用`,
        "支援人数",
        report.reportDaily.summary.continuousServiceUseCount
      ),
      genRectangleBoxConfig(
        `${nextMonth}月の利用支援Ⅱ(障害児支援利用援助費Ⅱ)発生まで`,
        "支援人数",
        report.reportDaily.summary.nextMonthUse2LimitCount,
        true
      ),
      genRectangleBoxConfig(
        `${month}月の継続サービス提供(継続障害児支援利用援助)時`,
        "モニタリング人数",
        report.reportDaily.summary.monitoringCount,
        false,
        "人",
        report.reportDaily.inoutResultDaily.numberOfEmployees
          ? (
              report.reportDaily.inoutResultDaily.numberOfEmployees * 39
            ).toString()
          : "-"
      )
    ];
    return rectangleList;
  };

  return (
    <>
      <div id="reportDailyHeader" className={classes.headerWrapper}>
        {inoutErrors.hasError && (
          <InvoiceErrorBar
            message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
            onClick={onClickErrorDialog}
          />
        )}
        <div className={classes.headerInfoContainer}>
          <InOutReportDailyHeader
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate}
            filterValue={filterValue}
            onChangeDate={onChangeDate}
            onChangeFilterSelect={onChangeFilterSelect}
          />
        </div>
      </div>
      <Paper elevation={0} className={classes.tableContainer}>
        <InOutReportDailyPaperHeader
          rectangleConfigList={createRectangleList(reportState)}
          openModal={openSetupModal}
          selectedDate={selectedDate}
          facility={props.facility}
          report={reportState}
          featureGroup={user.featureGroup}
        />
        <InOutReportTable
          headerHeight={headerHeight}
          openModal={openModal}
          openCreateModal={openCreateModal}
          type={REPORT_DAILY}
          filterFlg={filterFlg}
          usersInFacilityState={props.usersInFacilityState}
        />
      </Paper>
      <InOutReportDialog
        open={isOpenDetailModal}
        usersInFacilityState={props.usersInFacilityState}
        data={data}
        selectedDate={selectedDate}
        onCancel={onCancel}
        onSubmit={onSubmit}
        type={REPORT_DAILY}
      />
      <InOutReportSetupDialog
        open={isOpenSetupModal}
        data={data}
        selectedDate={selectedDate}
        onCancel={onSetupCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.v202104.KEIKAKUSODAN.report,
    usersInFacilityState: state.v202104.KEIKAKUSODAN.userInFacility,
    facility: state.v202104.KEIKAKUSODAN.facility,
    inoutErrors: state.errors.inout,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = v202104.KEIKAKUSODAN.reportDispatcher(dispatch);
  const uifDispatcher = v202104.KEIKAKUSODAN.userInFacilityDispatcher(dispatch);
  return {
    fetchFacility: v202104.KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchDaily: (date: Date): Promise<void> =>
      reportDispatcher.fetchKEIKAKUSODANDaily(date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    openErrorsDialog: (): {
      readonly type: "UI/SHOW_ERRORS_DIALOG";
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear,
    postFacilitiesKEIKAKUSODANMonthly: ({
      checked,
      data,
      selectedDate,
      numberOfEmployees,
      numberOfHandicappedChild,
      numberOfKeikakusodan,
      changeTrainingByChiefFlg,
      trainingByChiefShogaijiFlg,
      facility
    }): Promise<void> =>
      reportDispatcher.postKEIKAKUSODANFacilityMonthly({
        checked,
        data,
        selectedDate,
        numberOfEmployees,
        numberOfHandicappedChild,
        numberOfKeikakusodan,
        changeTrainingByChiefFlg,
        trainingByChiefShogaijiFlg,
        facility
      })
  };
};

export const InOutReportDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDailyCore));
