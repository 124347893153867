import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import { ReportDaily } from "@components/pages/mgr/KEIKAKUSODAN/report/Daily";
import { ReportDaily202104 } from "@components/v202104/pages/mgr/KEIKAKUSODAN/report/Daily";
import { ReportMonthly } from "@components/pages/mgr/KEIKAKUSODAN/report/Monthly";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/KEIKAKUSODAN/report/Monthly";

// 業務日誌
import { Operations } from "@components/pages/mgr/Cseg/record/operation/Operations";
import { OperationsRecordPrint } from "@components/pages/record/print/OperationsPrint";

// 利用者ごと
import { UsersSummary } from "@components/pages/mgr/KEIKAKUSODAN/record/UsersSummary";
import { RecordUsersSummarySupportPrint } from "@components/pages/mgr/Cseg/print/RecordUsersSummarySupportPrint";

// 支援記録
import { UserDetail } from "@components/pages/mgr/Cseg/record/UserDetail";
import { SupportRecordPrint } from "@components/pages/record/print/SupportPrint";

// アセスメント
import { AssessmentList } from "@components/pages/mgr/Cseg/record/assessment/AssessmentList";
import { Assessment } from "@components/pages/mgr/Cseg/record/assessment/Assessment";

// 記録機能
import { DailyRecord } from "@components/pages/mgr/Cseg/record/operationAndSupports/DailyRecord";
import { DailyRecordCsegRecords } from "@components/pages/record/print/DailyPrintCsegRecords";

// 基本情報
import { BasicInfoList } from "@components/pages/mgr/KEIKAKUSODAN/record/BasicInfoList";
import { BasicInfo } from "@components/pages/mgr/KEIKAKUSODAN/record/BasicInfo";
import { BasicInfoRecordPrint } from "@components/pages/mgr/KEIKAKUSODAN/record/BasicInfoRecordPrint";
import { BasicInfoOfLifeRecordPrint } from "@components/pages/mgr/KEIKAKUSODAN/record/BasicInfoOfLifeRecordPrint";

// 計画・モニタリング・会議記録
import { PlanMonitoringMeetingList } from "@components/pages/mgr/KEIKAKUSODAN/record/PlanMonitoringMeeting/PlanMonitoringMeetingList";

// モニタリング報告書
import { MonitoringReport } from "@components/pages/mgr/KEIKAKUSODAN/record/monitoringReport/MonitoringReport";
import { MonitoringReportNew } from "@components/pages/mgr/KEIKAKUSODAN/record/monitoringReport/MonitoringReportNew";
import { MonitoringReportPrint } from "@components/pages/mgr/KEIKAKUSODAN/record/monitoringReport/MonitoringReportPrint";

// サービス利用計画
import { Consultation } from "@components/pages/mgr/KEIKAKUSODAN/record/consultation/Consultation";
import { ConsultationNew } from "@components/pages/mgr/KEIKAKUSODAN/record/consultation/ConsultationNew";
import { ConsultationPrint } from "@components/pages/mgr/KEIKAKUSODAN/record/consultation/ConsultationPrint";

// 担当者会議記録
import { StaffMeetingRecord } from "@components/pages/mgr/Cseg/record/staffMeetingRecord/StaffMeetingRecord";
import { StaffMeetingRecordNew } from "@components/pages/mgr/Cseg/record/staffMeetingRecord/StaffMeetingRecordNew";
import { StaffMeetingRecordPrint } from "@components/pages/mgr/Cseg/record/staffMeetingRecord/StaffMeetingRecordPrint";

// 利用者情報
import { UsersList } from "@components/pages/mgr/KEIKAKUSODAN/Users/UsersList";
import { CreateUser } from "@components/pages/mgr/KEIKAKUSODAN/Users/CreateUser";
import { EditUser } from "@components/pages/mgr/KEIKAKUSODAN/Users/EditUser";
import { EditUser202104 } from "@components/v202104/pages/mgr/KEIKAKUSODAN/users/EditUser";

// サービス担当者に対する照会（依頼）内容
import { Inquiry } from "@components/pages/mgr/KEIKAKUSODAN/record/Inquiry";
import { InquiryPrint } from "@components/pages/mgr/KEIKAKUSODAN/record/InquiryPrint";

// 事業所情報
import { Facility } from "@components/pages/mgr/KEIKAKUSODAN/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/KEIKAKUSODAN/Facility";

// 職員情報
import Staffs from "@components/pages/staffs/Staffs";

// 初期設定情報
import { InitialData } from "@components/pages/mgr/KEIKAKUSODAN/initialData";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/mgr/KEIKAKUSODAN/assessment/setting/AssessmentSetting";
import { AssessmentRecordPrint } from "@components/pages/mgr/Cseg/record/print/AssessmentPrint";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 計画相談でログインした時だけ有効になるルーティング
 */
export const KEIKAKUSODANRoutes = ({ user }: Props): JSX.Element => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />
    {/* 業務日誌 */}
    <AdminRoute exact path={URL.RECORD_OPERATIONS} component={Operations} />
    <AdminRoute
      exact
      path={`${URL.RECORD_OPERATIONS}/:year/:month`}
      component={Operations}
    />
    <AdminRoute
      exact
      path="/record/print/operations/:year/:month"
      component={OperationsRecordPrint}
    />
    {/* 利用者ごと */}
    <AdminRoute
      exact
      path={URL.RECORD_USERS_SUMMARY}
      component={UsersSummary}
    />
    <AdminRoute
      exact
      path="/record/print/users_summary_support/:year/:month"
      component={RecordUsersSummarySupportPrint}
    />
    {/* サービス利用計画 */}
    {/* 新規作成 */}
    <AdminRoute
      exact
      path="/record/consultation/new/:uifId/:targetFlg"
      component={ConsultationNew}
    />
    {/* 閲覧・編集 */}
    <AdminRoute
      exact
      path="/record/consultation/:uifId/:consultationId/:targetFlg"
      component={Consultation}
    />
    {/* 印刷 */}
    <AdminRoute
      exact
      path="/record/consultation/print/:uifId/:consultationId/:targetFlg"
      component={ConsultationPrint}
    />
    <AdminRoute exact path="/record/users_summary" component={UsersSummary} />
    {/* サービス担当者会議記録 */}
    {/* 新規作成 */}
    <AdminRoute
      exact
      path="/record/meeting_record/new/:uifId"
      component={StaffMeetingRecordNew}
    />
    {/* 閲覧・編集 */}
    <AdminRoute
      exact
      path="/record/meeting_record/:uifId/:meetingRecordId"
      component={StaffMeetingRecord}
    />
    {/* 印刷 */}
    <AdminRoute
      exact
      path="/record/meeting_record/print/:uifId/:meetingRecordId"
      component={StaffMeetingRecordPrint}
    />
    {/* サービス担当者に対する照会（依頼）内容 */}
    {/* 新規作成・閲覧・編集 */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/inquiry/:inquiryId"
      component={Inquiry}
    />
    {/* 印刷プレビュー */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/inquiry/:inquiryId/print`}
      component={InquiryPrint}
    />
    {/* 支援記録 */}
    <AdminRoute
      exact
      path="/record/:uifId/support/:yyyy?/:mm?"
      component={UserDetail}
    />
    {/* 印刷・支援記録 */}
    <AdminRoute
      exact
      path="/record/print/:uifId/support/:year/:month"
      component={SupportRecordPrint}
    />
    {/* アセスメントシート */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />

    {/* 記録機能 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_DAILY}/:yyyymmdd?`}
      component={DailyRecord}
    />
    <AdminRoute
      key="DailyRecordPrint"
      exact
      path="/record/print/daily/:yyyymmdd"
      component={DailyRecordCsegRecords}
    />
    {/* 基本情報 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info`}
      component={BasicInfoList}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info/:supportPlanBasicInfoId`}
      component={BasicInfo}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info/new`}
      component={BasicInfo}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info/:copyId/copy`}
      component={BasicInfo}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info/:supportPlanBasicInfoId/user/print`}
      component={BasicInfoRecordPrint}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/basic_info/:supportPlanBasicInfoId/life/print`}
      component={BasicInfoOfLifeRecordPrint}
    />
    {/* 計画・モニタリング・会議記録 */}
    <AdminRoute
      exact
      path="/record/:uifId/plan_monitoring_meeting"
      component={PlanMonitoringMeetingList}
    />
    {/* モニタリング報告書 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/monitoring_report/new`}
      component={MonitoringReportNew}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/monitoring_report/:monitoringReportId`}
      component={MonitoringReport}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/print/:uifId/monitoring_report/:monitoringReportId`}
      component={MonitoringReportPrint}
    />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    {/* 職員情報 */}
    <AdminRoute path={URL.STAFFS} component={Staffs} />
    {/* 初期設定情報 */}
    <AdminRoute path={URL.INITIAL} component={InitialData} />
    {/* アセスメントの設定 */}
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);
