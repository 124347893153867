import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikTextField from "@components/molecules/FormikTextField";
import { InitialDataValues } from "@initialize/mgr/KEIKAKUSODAN/report/initialValues";
import dispatches from "@stores/dispatches";
import { toEffectiveObject } from "@utils/object";
import {
  setupValidation,
  submitSetupValidation
} from "@initialize/mgr/KEIKAKUSODAN/report/validation";
import { InOutReportDialogSubmit } from "@components/organisms/mgr/KEIKAKUSODAN/report/dialog/InOutReportDialogSubmit";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { AppState } from "@stores/type";
import { ResponseError } from "@stores/ui/type";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  INOUT_RESULTS_ERROR_DUPLICATED,
  TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST,
  TRAINING_BY_CHIEF_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { FacilitiesKEIKAKUSODANMonthlyType } from "@stores/domain/mgr/KEIKAKUSODAN/report/types";
import FormikSelect from "@components/molecules/FormikSelect";

const styles = (): StyleRules =>
  createStyles({
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: 0,
      marginTop: 0,
      "& > :first-child": {
        paddingBottom: 8
      }
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between" as "space-between"
    },
    modal: {
      width: "80%",
      maxWidth: 616
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 32,
      paddingBottom: 24,
      paddingLeft: 32
    },
    modalContentsTitle: {
      paddingBottom: 6,
      color: "#37474f"
    },
    modalContentsbody: {
      color: "#666666"
    },
    modalHeaderArea: {
      height: "15%",
      padding: "12px 32px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: 20,
      backgroundColor: "#f5f5f5"
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    infoIcon: {
      color: "#0277bd",
      width: 18,
      height: 18,
      marginRight: 8,
      marginBottom: -3
    },
    categoryGroup: {
      paddingLeft: 32
    },
    textColor: {
      color: "#f44336"
    },
    numberOfEmployeesTooltip: {
      position: "absolute",
      left: 139,
      top: 179
    },
    numberOfHandicappedChildTooltip: {
      position: "absolute",
      left: 188,
      top: 259
    },
    existNumberOfEmployeesErrorNumberOfHandicappedChildTooltip: {
      position: "absolute",
      left: 188,
      top: 280
    },
    trainingChiefFlg: {
      width: "156px",
      height: "24px",
      margin: "0 382px 8px 0",
      fontFamily: "HiraginoSans-W4",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.71,
      letterSpacing: "0.1px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    wideLabel: {
      width: 240
    }
  });

type DispatchProps = {
  postFacilitiesKEIKAKUSODANMonthly: (
    args: FacilitiesKEIKAKUSODANMonthlyType
  ) => Promise<void>;
  responseErrorClear(): void;
};

type StateProps = {
  facilityState: FacilityState;
  responseError: ResponseError;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  data: InitialDataValues;
  selectedDate: Date;
  onCancel: () => void;
  onSubmit: () => void;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

const InOutReportSetupDialogCore = (props: Props): JSX.Element => {
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);
  const [
    existNumberOfEmployeesError,
    setExistnumberOfEmployeesError
  ] = React.useState<boolean>(false);
  const [
    isClickNumberOfEmployees,
    setIsClickNumberOfEmployees
  ] = React.useState<boolean>(false);

  const validate = (values: InitialDataValues): void | object => {
    const validationResult = setupValidation(values, props.facilityState);
    setIsSubmitDisabled(submitSetupValidation(validationResult));
    setExistnumberOfEmployeesError(
      validationResult.initial.numberOfEmployees !== undefined &&
        isClickNumberOfEmployees
    );

    const errorCheckResult = toEffectiveObject(validationResult);

    // エラーが存在するかは、toEffectiveObjectで確認
    // エラーが存在する場合は、validationの結果をそのまま返却
    // →FieldArrayを利用する場合、エラーは配列として保持する必要があるため
    // →また、エラー配列と表示データの配列はindexを一致させる必要がある
    // エラーが存在しない場合は、undefinedを返却（errorObjectがundefined）
    return errorCheckResult ? validationResult : errorCheckResult;
  };

  const onCancel = (): void => {
    setIsSubmitDisabled(true);
    setExistnumberOfEmployeesError(false);
    setIsClickNumberOfEmployees(false);
    props.onCancel();
  };

  const onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postFacilitiesKEIKAKUSODANMonthly({
        data: values,
        selectedDate: props.selectedDate,
        facility: props.facilityState
      })
      .then(() => {
        const error = { ...props.responseError };
        const data = error ? (error.data as { error: string }) : null;
        if (
          error.status === INOUT_RESULTS_ERROR_DUPLICATED.STATUS &&
          data &&
          data.error === INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
        ) {
          props.responseErrorClear();
        } else {
          onCancel();
          props.onSubmit();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const onClickNumberOfEmployees = (): void => {
    setIsClickNumberOfEmployees(true);
  };

  const numberOfEmployeesErrorTooltip = (
    <span
      className={
        existNumberOfEmployeesError
          ? props.classes
              .existNumberOfEmployeesErrorNumberOfHandicappedChildTooltip
          : props.classes.numberOfHandicappedChildTooltip
      }
    >
      <HelpToolTip
        title={<HelpTipMessages name="keikakusodanNumberOfEmployees" />}
      />
    </span>
  );

  const { classes } = props;
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.open}
        classes={{ paper: classes.modal }}
      >
        <Formik
          initialValues={props.data}
          validate={validate}
          onSubmit={onSubmit}
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.formArea}>
              <DialogTitle
                id={props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={props.classes.modalHeader}>
                  {`${props.selectedDate.getFullYear()}年${
                    props.selectedDate.getMonth() + 1
                  }月の設定項目`}
                </div>
              </DialogTitle>
              <DialogContent className={classes.modalContents}>
                <div className={classes.modalContentsTitle}>
                  <InfoOutlinedIcon className={classes.infoIcon} />
                  設定項目について
                </div>
                <div className={classes.modalContentsbody}>
                  請求を行うには、請求額算出に必要となる以下のデータを登録する必要があります。必ず
                  <span className={props.classes.textColor}>
                    月の請求作業の実施前に登録を完了
                  </span>
                  させてください。
                </div>
              </DialogContent>
              <FormGroup className={props.classes.categoryGroup}>
                {/* 全相談支援種類共通 */}
                <FormikTextField
                  name="initial.numberOfEmployees"
                  label="相談支援員の員数"
                  placeholder="0"
                  endAdornmentLabel="人"
                  required
                  onClick={onClickNumberOfEmployees}
                />
                <span className={props.classes.numberOfEmployeesTooltip}>
                  <HelpToolTip
                    title={
                      <HelpTipMessages name="keikakusodanNumberOfEmployees" />
                    }
                  />
                </span>
                {/* 計画相談支援 */}
                {props.facilityState.facilityType ===
                  FACILITY_TYPE_KEIKAKUSODAN &&
                  !props.facilityState
                    .integratedManagementHandicappedChildFlg && (
                    <FormikSelect
                      name="initial.trainingByChief202404"
                      label="主任相談支援専門員配置加算"
                      options={TRAINING_BY_CHIEF_TYPE_LIST}
                      FormLabelClasses={{
                        root: props.classes.wideLabel
                      }}
                    />
                  )}

                {/* 障害児相談支援 */}
                {props.facilityState.facilityType ===
                  FACILITY_TYPE_SHOGAIJISODAN &&
                  !props.facilityState
                    .integratedManagementHandicappedChildFlg && (
                    <FormikSelect
                      name="initial.trainingByChiefShogaiji202404"
                      label="主任相談支援専門員配置加算"
                      options={TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST}
                      FormLabelClasses={{
                        root: props.classes.wideLabel
                      }}
                    />
                  )}

                {/* 計画相談支援+障害児相談支援 */}
                {props.facilityState.facilityType ===
                  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN && (
                  <>
                    <span className={props.classes.trainingChiefFlg}>
                      計画相談支援
                    </span>
                    <FormikSelect
                      name="initial.trainingByChief202404"
                      label="主任相談支援専門員配置加算"
                      options={TRAINING_BY_CHIEF_TYPE_LIST}
                      FormLabelClasses={{
                        root: props.classes.wideLabel
                      }}
                    />
                    <span className={props.classes.trainingChiefFlg}>
                      障害児相談支援
                    </span>
                    <FormikSelect
                      name="initial.trainingByChiefShogaiji202404"
                      label="主任相談支援専門員配置加算"
                      options={TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST}
                      FormLabelClasses={{
                        root: props.classes.wideLabel
                      }}
                    />
                  </>
                )}

                {/* 計画相談支援
                指定特定相談支援事業所と指定障害児相談支援事業所を一体的に運営 */}
                {props.facilityState.facilityType ===
                  FACILITY_TYPE_KEIKAKUSODAN &&
                  props.facilityState
                    .integratedManagementHandicappedChildFlg && (
                    <>
                      <FormikTextField
                        name="initial.numberOfHandicappedChild"
                        label="障害児相談支援対象者の数"
                        placeholder="0"
                        endAdornmentLabel="人"
                        required
                      />
                      {numberOfEmployeesErrorTooltip}
                      <FormikSelect
                        name="initial.trainingByChief202404"
                        label="主任相談支援専門員配置加算"
                        options={TRAINING_BY_CHIEF_TYPE_LIST}
                        FormLabelClasses={{
                          root: props.classes.wideLabel
                        }}
                      />
                    </>
                  )}

                {/* 障害児相談支援
                指定特定相談支援事業所と指定障害児相談支援事業所を一体的に運営 */}
                {props.facilityState.facilityType ===
                  FACILITY_TYPE_SHOGAIJISODAN &&
                  props.facilityState
                    .integratedManagementHandicappedChildFlg && (
                    <>
                      <FormikTextField
                        name="initial.numberOfKeikakusodan"
                        label="計画相談支援対象者の数"
                        placeholder="0"
                        endAdornmentLabel="人"
                        required
                      />
                      {numberOfEmployeesErrorTooltip}
                      <FormikSelect
                        name="initial.trainingByChiefShogaiji202404"
                        label="主任相談支援専門員配置加算"
                        options={TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST}
                        FormLabelClasses={{
                          root: props.classes.wideLabel
                        }}
                      />
                    </>
                  )}
              </FormGroup>

              <DialogActions className={classes.action}>
                <InOutReportDialogSubmit
                  formikPropsValues={formikProps}
                  onCancel={onCancel}
                  disabled={isSubmitDisabled}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.KEIKAKUSODAN.facility,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN, uiDispatch } = dispatches;
  const reportDispatcher = KEIKAKUSODAN.reportDispatcher(dispatch);
  return {
    postFacilitiesKEIKAKUSODANMonthly: ({
      data,
      selectedDate,
      facility
    }): Promise<void> =>
      reportDispatcher.postKEIKAKUSODANFacilityMonthly({
        data,
        selectedDate,
        facility
      }),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportSetupDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportSetupDialogCore));
