import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import { SupportContentPrint } from "@components/organisms/mgr/Cseg/record/SupportContentPrint";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { UsersSummaryState } from "@stores/domain/mgr/Cseg/usersSummary/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { FacilityType } from "@constants/variables";

// utils
import { getUrlParams } from "@/utils/url";
import dateToObject from "@utils/date/dateToObject";
import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";

import { FACILITY_TYPE_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      fontSize: "18px",
      marginBottom: "16px",
      fontWeight: "normal",
      letterSpacing: 0.9,
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      marginBottom: "16px"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "4px 5px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.W54": {
          width: 54
        },
        "&.W84": {
          width: 84
        },
        "&.W86": {
          width: 86
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: "4px 5px",
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid"
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

const TABLE_HEAD_HEIGHT = 25;

type StateProps = {
  facilityType: FacilityType;
  usersSummary: UsersSummaryState["users"];
};
type OwnProps = {
  year: string;
  month: string;
  query: string;
};

type DispatchProps = {
  fetchUsersSummary: (
    year: string,
    month: string,
    excludedUserIds?: string
  ) => void;
};

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  displayComment: string[];
  year: string;
  month: string;
  facilityType: FacilityType;
  user: UsersSummaryState["users"][0];
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  classes: Record<string, string>;
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

export const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    facilityType,
    displayComment,
    year,
    month,
    user,
    tableRowStartEndIndexInSheet
  } = props;

  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;

  const monthValue = month.replace(/^0+/, "");

  return (
    <div className={classes.page}>
      <div>
        <header>
          <div className={classes.title}>支援記録</div>
        </header>
        <div className={classes.subTitle}>
          <span>
            {`${year}年${monthValue}月分${"　"}${user.name}`}
            {user.type_consultation === FACILITY_TYPE_SHOGAIJISODAN &&
              "　(障害児)"}
          </span>
        </div>
        {user.support.length > 0 && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label W84">日付</td>
                  <td className="label">支援内容</td>
                  <td className="label W86">記録者</td>
                  <td className="label W54">確認印</td>
                </tr>
                {user.support.map((record, index) => {
                  const {
                    support_records_id,
                    support_content,
                    staff_comment,
                    staffs,
                    target_date
                  } = record.support_record;
                  if (index < startIndex || index > endIndex) return null;
                  // 支援記録の入力がないものは表示しない
                  if (
                    !support_content &&
                    (!staff_comment ||
                      !displayComment.includes("staff_comment")) &&
                    (!staffs || staffs.length === 0)
                  )
                    return null;
                  // 日付
                  const targetDate = dateToObject(new Date(target_date));

                  // サービス内容
                  const status = record.inout_result
                    ? record.inout_result.status
                    : 0;

                  // 記録者
                  const staffName = staffs
                    ? staffs.map((item) => item.staff_name).join("、")
                    : "";

                  if (!target_date) {
                    return null;
                  }

                  return (
                    <tr key={`tr-${targetDate.month}_${targetDate.day}`}>
                      <td className="label topCenterAlign">
                        {`${targetDate.month}/${targetDate.day}（${targetDate.day_of_week}）`}
                      </td>
                      <td className="topAlign">
                        <SupportContentPrint
                          displayComment={displayComment}
                          status={status}
                          id={support_records_id}
                          facilityType={facilityType}
                          supportContent={support_content}
                          staffComment={staff_comment}
                          typeConsultation={user.type_consultation}
                        />
                      </td>
                      <td className="topCenterAlign">{staffName}</td>
                      <td className="label" />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const UsersSummarySupportPrintCore = (props: Props): JSX.Element | null => {
  const { classes, year, month, usersSummary, query, facilityType } = props;

  const [displayColumns, setDisplayColumns] = React.useState([] as string[]);
  const [renderFlg, setRenderFlg] = React.useState(false);

  React.useEffect(() => {
    const queryParameters: {
      display_columns?: string;
      excluded_user_ids?: string;
    } = getUrlParams(query);
    if (queryParameters.display_columns) {
      setDisplayColumns(queryParameters.display_columns.split(","));
    }
    let excludedUserIds = "";
    if (queryParameters.excluded_user_ids) {
      excludedUserIds = queryParameters.excluded_user_ids;
    }
    props.fetchUsersSummary(year, month, excludedUserIds);
    setRenderFlg(true);
  }, []);

  if (!renderFlg) {
    return null;
  }

  return (
    <>
      {usersSummary.map((user) => {
        if (user === undefined || !user.support) {
          return null;
        }
        const tableRowStartEndIndexInSheet = {
          startIndex: 0,
          endIndex: user.support.length
        };
        return (
          <Sheet
            classes={classes}
            displayComment={displayColumns}
            year={year}
            month={month}
            user={user}
            facilityType={facilityType}
            tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
            key={user.uif_id}
          />
        );
      })}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;

  return {
    facilityType,
    usersSummary: state.Cseg.usersSummary.users
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchUsersSummary: (
      year: string,
      month: string,
      excludedUserIds?: string
    ): Promise<void> =>
      dispatches.Cseg.usersSummaryDispatcher(dispatch).fetchUsersSummary(
        year,
        month,
        excludedUserIds
      )
  };
};

export const UsersSummarySupportPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsersSummarySupportPrintCore));
