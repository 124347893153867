import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import { InitialDataValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { addMonths } from "date-fns";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = createStyles({
  contents: {
    display: "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "flex-end" as "flex-end",
    marginBottom: 16
  },
  rectangleListContents: {
    display: "flex",
    justifyContent: "flex-start" as "flex-start",
    "& > :last-child": {
      marginRight: 0
    }
  },
  // RectangleBox用のを上書き
  wrapperBase: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 450,
    height: 66,
    backgroundColor: "#eceff1",
    borderRadius: 4,
    lineHeight: 1,
    padding: "10px 12px",
    marginRight: 10
  },
  wrapperSecondBase: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 237,
    height: 66,
    backgroundColor: "#eceff1",
    borderRadius: 4,
    lineHeight: 1,
    padding: "10px 12px",
    marginRight: 10
  },
  title: {
    fontSize: 13,
    color: "#37474F"
  },
  content: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    color: "#666"
  },
  num: {
    fontSize: 22,
    flex: 1,
    textAlign: "center"
  },
  unit: {
    fontSize: 14,
    marginRight: 10
  },
  label: {
    fontSize: 14
  },
  wrapperSmall: {
    width: 109,
    padding: "10px 8px"
  },
  wrapperLarge: {
    width: 215,
    padding: "10px 8px"
  }
});

type OwnProps = {
  rectangleConfigList: {
    title: string;
    num: number;
    denom?: string;
    unit: string;
  }[];
  multiRectangleConfigList?: {
    title: string;
    variableList: { label: string; value: number }[];
    denom?: string;
    unit: string;
  };
  secondMultiRectangleConfigList?: {
    title: string;
    variableList: { label: string; value: number }[];
    denom?: string;
    unit: string;
  };
  selectedDate: Date;
  data: InitialDataValues;
  facility: FacilityState;
  usersInFacilityState: UsersInFacilityState;
};
type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportMonthlyPaperHeaderCore = (props: Props): JSX.Element => {
  const keikakuSodanFacility = findFacilityKeikakuSodan(props.facility);
  const shogaijiSodanFacility = findFacilityShogaijisodan(props.facility);

  const isKeikaku = (): boolean => {
    return (
      props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
      (props.facility.facilityType ===
        FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        !!props.usersInFacilityState.user.user_in_facility_keikakusodan &&
        props.usersInFacilityState.user.user_in_facility_keikakusodan
          .type_daily_consultation === TYPE_CONSULTATION_KEIKAKUSODAN)
    );
  };

  const isShogaiji = (): boolean => {
    return (
      props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN ||
      (props.facility.facilityType ===
        FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        !!props.usersInFacilityState.user.user_in_facility_keikakusodan &&
        props.usersInFacilityState.user.user_in_facility_keikakusodan
          .type_daily_consultation === TYPE_CONSULTATION_SHOGAIJISODAN)
    );
  };

  const inService = (type: "keikaku" | "shogaiji"): boolean => {
    const uifKeikakuShogaiji =
      type === "keikaku"
        ? findUserInFacilityKeikakusodan(props.usersInFacilityState.user)
        : findUserInFacilityShogaijisodan(props.usersInFacilityState.user);
    // 障害福祉サービス等利用期間のフラグがOFF
    const disabledWelfareServiceUseFlg = uifKeikakuShogaiji
      ? uifKeikakuShogaiji.disabled_welfare_service_use_flg
      : 0;
    if (disabledWelfareServiceUseFlg === 0) {
      return false;
    }

    // 障害福祉サービス等利用期間の開始日
    const dateStartService =
      uifKeikakuShogaiji && uifKeikakuShogaiji.disabled_welfare_service_use_from
        ? convertBlankSeparatorFormatToDate(
            `${uifKeikakuShogaiji.disabled_welfare_service_use_from}01`
          )
        : null;
    // 障害福祉サービス等利用期間の終了日から6ヶ月
    const dateEndService =
      uifKeikakuShogaiji && uifKeikakuShogaiji.disabled_welfare_service_use_to
        ? addMonths(
            convertBlankSeparatorFormatToDate(
              `${uifKeikakuShogaiji.disabled_welfare_service_use_to}01`
            ),
            7
          )
        : null;

    return (
      (dateStartService &&
        dateEndService &&
        dateStartService <= props.selectedDate &&
        props.selectedDate < dateEndService) ||
      (dateEndService &&
        dateStartService === null &&
        props.selectedDate < dateEndService) ||
      (dateStartService &&
        dateEndService === null &&
        dateStartService <= props.selectedDate) ||
      (dateStartService === null && dateEndService === null)
    );
  };

  return (
    <div>
      <div className={props.classes.contents}>
        {props.rectangleConfigList && props.rectangleConfigList.length > 0 && (
          <div className={props.classes.rectangleListContents}>
            {props.rectangleConfigList.map((rectangleConfig, index) => {
              const uniqueKey = `${rectangleConfig.title}-${index}`;
              if (
                rectangleConfig.title === "地域生活支援拠点等相談強化加算" &&
                (!keikakuSodanFacility ||
                  !keikakuSodanFacility.lifeSupportHubInDistrictFlg) &&
                (!shogaijiSodanFacility ||
                  !shogaijiSodanFacility.lifeSupportHubInDistrictFlg)
              ) {
                return null;
              }
              return (
                <div
                  className={`${props.classes.wrapperBase}
                    ${
                      index === 0
                        ? props.classes.wrapperSmall
                        : props.classes.wrapperLarge
                    }`}
                  key={uniqueKey}
                >
                  <div className={props.classes.title}>
                    {rectangleConfig.title}
                  </div>
                  <div className={props.classes.content}>
                    <div className={props.classes.num}>
                      {rectangleConfig.num}
                    </div>
                    <span className={props.classes.unit}>
                      {rectangleConfig.denom && `/${rectangleConfig.denom}`}
                      {rectangleConfig.unit}
                    </span>
                  </div>
                </div>
              );
            })}
            {props.multiRectangleConfigList &&
              isKeikaku() &&
              inService("keikaku") && (
                <div className={props.classes.wrapperBase}>
                  <div className={props.classes.title}>
                    {props.multiRectangleConfigList.title}
                  </div>
                  <div className={props.classes.content}>
                    {props.multiRectangleConfigList.variableList.map(
                      (variable) => {
                        if (props.multiRectangleConfigList) {
                          return (
                            <React.Fragment
                              key={`multiRectangleConfigList-${variable.label}`}
                            >
                              <span className={props.classes.label}>
                                {variable.label}
                              </span>
                              <div className={props.classes.num}>
                                {variable.value}
                              </div>
                              <span className={props.classes.unit}>
                                {props.multiRectangleConfigList.denom &&
                                  `/${props.multiRectangleConfigList.denom}`}
                                {props.multiRectangleConfigList.unit}
                              </span>
                            </React.Fragment>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </div>
              )}
            {props.secondMultiRectangleConfigList &&
              isShogaiji() &&
              inService("shogaiji") && (
                <div className={props.classes.wrapperSecondBase}>
                  <div className={props.classes.title}>
                    {props.secondMultiRectangleConfigList.title}
                  </div>
                  <div className={props.classes.content}>
                    {props.secondMultiRectangleConfigList.variableList.map(
                      (variable) => {
                        if (props.secondMultiRectangleConfigList) {
                          return (
                            <React.Fragment
                              key={`secondMultiRectangleConfigList-${variable.label}`}
                            >
                              <span className={props.classes.label}>
                                {variable.label}
                              </span>
                              <div className={props.classes.num}>
                                {variable.value}
                              </div>
                              <span className={props.classes.unit}>
                                {props.secondMultiRectangleConfigList.denom &&
                                  `/${props.secondMultiRectangleConfigList.denom}`}
                                {props.secondMultiRectangleConfigList.unit}
                              </span>
                            </React.Fragment>
                          );
                        }
                        return null;
                      }
                    )}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export const InOutReportMonthlyPaperHeader = withStyles(styles)(
  InOutReportMonthlyPaperHeaderCore
);
