import { Success, Failure } from "typescript-fsa";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import get from "lodash-es/get";
import * as action from "@stores/domain/account/action";
import {
  GetTargetFacilityUserResult,
  UpdatePasswordFailed,
  AccountState,
  ActivateResult,
  GetURLValidityResult,
  NewMobileAccountFailed
} from "@stores/domain/account/type";

const initialState: AccountState = {
  errors: undefined,
  errorType: "",
  admin: {
    email: "",
    role: ""
  },
  user: {
    email: "",
    role: ""
  },
  mobile: {
    email: "",
    role: ""
  },
  activateRes: []
};

const getURLValidResultStarted = (state: AccountState): AccountState => state;
const getURLValidResultDone = (
  state: AccountState,
  payload: Success<{}, GetURLValidityResult>
): AccountState => ({
  ...state,
  status: payload.result.status
});
const getURLValidResultFailed = (state: AccountState): AccountState => state;

const getTargetFacilityUserStarted = (state: AccountState): AccountState =>
  state;
const getTargetFacilityUserDone = (
  state: AccountState,
  payload: Success<{}, GetTargetFacilityUserResult>
): AccountState => ({
  ...state,
  admin: payload.result.find((user) => user.role === "mgradmin") || {
    email: "",
    role: ""
  },
  user: payload.result.find((user) => user.role === "mgruser") || {
    email: "",
    role: ""
  },
  mobile: payload.result.find((user) => user.role === "mgrmobile") || {
    email: "",
    role: ""
  }
});
const getTargetFacilityUserFailed = (state: AccountState): AccountState =>
  state;

const updatePasswordStarted = (state: AccountState): AccountState => ({
  ...state,
  errorType: ""
});
const updatePasswordDone = (state: AccountState): AccountState => state;
const updatePasswordFailed = (
  state: AccountState,
  payload: Failure<{ formName: string }, UpdatePasswordFailed>
): AccountState => ({
  ...state,
  errors: { ...state.errors, [payload.params.formName]: payload.error },
  errorType: get(payload, "error[0].error[0].message") || "error"
});

const postNewMobileUserStarted = (state: AccountState): AccountState => ({
  ...state,
  errorType: ""
});
const postNewMobileUserSuccess = (state: AccountState): AccountState => state;
const postNewMobileUserFailed = (
  state: AccountState,
  payload: Failure<{}, NewMobileAccountFailed>
): AccountState => ({
  ...state,
  errors: { ...state.errors, mobile: payload.error },
  errorType:
    get(payload, "error.response.validation.email[0]") ||
    get(payload, "error.response.validation.password[0]") ||
    "error"
});

const activateFailed = (
  state: AccountState,
  data: ActivateResult
): AccountState => {
  return {
    ...state,
    activateRes: data
  };
};

const refreshActivateErrorMessage = (state: AccountState): AccountState => {
  return {
    ...state,
    activateRes: []
  };
};

const refreshActivateErrorMessageMgrAdminPassword = (
  state: AccountState
): AccountState => {
  const res = state.activateRes.filter((target) => {
    return !(target.role === "mgradmin" && target.error.target === "password");
  });
  return {
    ...state,
    activateRes: res
  };
};

const refreshActivateErrorMessageMgrUserAccountId = (
  state: AccountState
): AccountState => {
  const res = state.activateRes.filter((target) => {
    return !(target.role === "mgruser" && target.error.target === "email");
  });
  return {
    ...state,
    activateRes: res
  };
};

const refreshActivateErrorMessageMgrUserPassword = (
  state: AccountState
): AccountState => {
  const res = state.activateRes.filter((target) => {
    return !(target.role === "mgruser" && target.error.target === "password");
  });
  return {
    ...state,
    activateRes: res
  };
};

export default reducerWithInitialState(initialState)
  .case(action.getURLValidity.started, getURLValidResultStarted)
  .case(action.getURLValidity.done, getURLValidResultDone)
  .case(action.getURLValidity.failed, getURLValidResultFailed)
  .case(action.getTargetFacilityUser.started, getTargetFacilityUserStarted)
  .case(action.getTargetFacilityUser.done, getTargetFacilityUserDone)
  .case(action.getTargetFacilityUser.failed, getTargetFacilityUserFailed)
  .case(action.updatePassword.started, updatePasswordStarted)
  .case(action.updatePassword.done, updatePasswordDone)
  .case(action.updatePassword.failed, updatePasswordFailed)
  .case(action.postNewMobileUser.started, postNewMobileUserStarted)
  .case(action.postNewMobileUser.done, postNewMobileUserSuccess)
  .case(action.postNewMobileUser.failed, postNewMobileUserFailed)
  .case(action.activateFailed, activateFailed)
  .case(action.refreshActivateErrorMessage, refreshActivateErrorMessage)
  .case(
    action.refreshActivateErrorMessageMgrAdminPassword,
    refreshActivateErrorMessageMgrAdminPassword
  )
  .case(
    action.refreshActivateErrorMessageMgrUserAccountId,
    refreshActivateErrorMessageMgrUserAccountId
  )
  .case(
    action.refreshActivateErrorMessageMgrUserPassword,
    refreshActivateErrorMessageMgrUserPassword
  );
