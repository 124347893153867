export const TIMECARD_ERROR_MESSAGE =
  "タイムカードアカウントは半角英数8文字以上である必要があります";
export const MOBILE_ERROR_MESSAGE =
  "モバイル画面アカウントは半角英数記号8文字以上で設定してください";

/**
 * アカウントIDチェック(半角英数記号8文字以上)
 * @param value
 * @param accountType
 */
export const accountId = (
  value: string,
  accountType: "timeCard" | "mobile"
): string | undefined => {
  if (value !== "" && !/^[ -~]{8,100}$/.test(value)) {
    if (accountType === "timeCard") {
      return TIMECARD_ERROR_MESSAGE;
    }
    if (accountType === "mobile") {
      return MOBILE_ERROR_MESSAGE;
    }
  }
  return undefined;
};
