import * as React from "react";
import {
  createStyles,
  StyleRules,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { AppState } from "@stores/type";
import { connect } from "react-redux";
// ui
import FormGroup from "@material-ui/core/FormGroup";
import Paper from "@material-ui/core/Paper";
import { ServiceDeliveryBasicFieldDateSelect } from "@components/organisms/record/serviceDelivery/ServiceDeliveryBasicFieldDateSelect";
import { ServiceDeliveryBasicFieldUserSelect } from "@components/organisms/record/serviceDelivery/ServiceDeliveryBasicFieldUserSelect";
// formik
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { FormikProps, setNestedObjectValues } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import {
  ServiceDeliveryDetailErrors,
  validation
} from "@initialize/record/serviceDelivery/validation";
// utils
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { toEffectiveObject } from "@utils/object";
import merge from "lodash-es/merge";
import { createStatusOptions } from "@utils/domain/serviceDelivery/createStatusOptions";
import { FacilityType, MEMBER_LIST_SERVICE_DETAIL } from "@constants/variables";
// hooks
import { useAccompanyDisplayFlg } from "@hooks/record/serviceDelivery/useAccompanyDisplayFlg";
import FormikTextField from "@components/molecules/FormikTextField";
import {
  IDOSHIEN_GROUP_MEMBER_SELECT_LIST,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import { ServiceDeliveryOnlyTimeContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryOnlyTimeContainer";
import castNumber from "@utils/dataNormalizer/castNumber";
import MuiTextField from "@components/molecules/MuiTextField";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    paper: {
      margin: spacing.unit * 2,
      padding: "32px 32px 0px 32px"
    },
    alignEnd: {
      alignItems: "flex-end"
    },
    PB32: {
      paddingBottom: "32px"
    },
    supportRatioPerPractitionerStartAdornmentLabel: {
      "& p": {
        fontSize: "16px"
      }
    }
  });

type StateProps = {
  serviceDeliveryUser: ServiceDeliveryState["user"];
};

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  setNumberOfPractitioner: (num: number) => void;
  isInfoOpen: boolean;
  setIsInfoOpen: (bool: boolean) => void;
  facilityType: FacilityType;
  isEdit: boolean;
  targetDate: string;
  isDaily: boolean;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
} & StateProps &
  WithStyles<typeof styles>;

const ServiceDeliveryBasicFieldCore = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  // 種別ごとのサービス内容選択肢指定
  const statusOptions = createStatusOptions(props.facilityType);

  const onChangeHookStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (
      e.target.value !== statusOptions[0].value &&
      formikValues.usersInFacilityId !== 0
    ) {
      props.setIsInfoOpen(true);
    } else {
      props.setIsInfoOpen(false);
    }

    if (props.facilityType === FacilityType.IDOSHIEN) {
      // 移動支援では提供人数がサービスごとに異なるため、毎回リセットする
      props.setFormikFieldValue(
        "numberOfPractitioner",
        IDOSHIEN_MEMBER_LIST.ONE.value
      );
      // 移動支援ではグループ支援の支援人数の値が隠れてしまうため、リセットする
      props.setFormikFieldValue("numberOfSupporters", "");
    }
  };

  const onChangeHookNumber = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    props.setNumberOfPractitioner(Number(e.target.value));
    // 提供人数を「1」にしたとき，サービス提供記録２のエラーのある項目を空にする
    const validationResult = validation(
      props.formikProps.values,
      props.facilityType
    );
    const error = toEffectiveObject(
      validationResult
    ) as ServiceDeliveryDetailErrors;
    if (
      e.target.value === MEMBER_LIST_SERVICE_DETAIL[0].value &&
      error &&
      error.serviceDeliveryRecordPractitioners2
    ) {
      // エラーの項目を空にする
      const errorToBlackItem = setNestedObjectValues<
        ServiceDeliveryDetailErrors
      >(error, "");
      const mergedServiceDeliveryRecordPractitioners2 = merge(
        {},
        props.formikProps.values.serviceDeliveryRecordPractitioners2,
        errorToBlackItem.serviceDeliveryRecordPractitioners2
      );
      props.formikProps.setValues({
        ...props.formikProps.values,
        serviceDeliveryRecordPractitioners2: mergedServiceDeliveryRecordPractitioners2
      });
    }
  };

  const CheckboxLabel = ({ label }: { label: string }): JSX.Element => (
    <span
      style={{
        fontSize: 16,
        color: "rgba(0, 0, 0, 0.87)"
      }}
    >
      {label}
    </span>
  );

  // 同行支援の表示判定(重度のみ)
  const accompanySupportDisplayFlg =
    props.facilityType === FacilityType.JUDOHOMONKAIGO
      ? useAccompanyDisplayFlg(
          !props.isEdit,
          props.formikProps.values,
          props.serviceDeliveryUser
        )
      : false;

  const statusSelect = (
    <FormikSelect
      name="status"
      label="サービス内容"
      options={statusOptions}
      onChangeHook={onChangeHookStatus}
      style={{ width: 256 }}
    />
  );

  const checkBoxes = (
    <>
      {props.isInfoOpen && (
        <>
          <FormikCheckbox
            name="emergencySupportFlg"
            label={<CheckboxLabel label="緊急時対応" />}
            style={{ marginLeft: 20, marginRight: 20 }}
          />
          <FormikCheckbox
            name="sputumImplementationFlg"
            label={<CheckboxLabel label="喀痰吸引等実施" />}
            style={{ marginRight: 20 }}
          />
          {props.facilityType === FacilityType.JUDOHOMONKAIGO && (
            <FormikCheckbox
              name="emergencyGuideSupportFlg"
              label={<CheckboxLabel label="移動介護緊急時支援" />}
              style={{ marginRight: 20 }}
            />
          )}
          {new Date(2023, 3, 1) <=
            new Date(fixDateAndTimeFormat(props.targetDate)) && (
            <FormikCheckbox
              name="bodyRestrictedStillFlg"
              label={<CheckboxLabel label="身体拘束廃止未実施" />}
            />
          )}
        </>
      )}
    </>
  );

  const numberOfPractitionerSelect = (
    <>
      <FormikSelect
        name="numberOfPractitioner"
        label="提供人数"
        options={MEMBER_LIST_SERVICE_DETAIL}
        style={{ width: 156, paddingBottom: 32, marginBottom: 0 }}
        onChangeHook={onChangeHookNumber}
      />
    </>
  );

  const { numberOfPractitioner, numberOfSupporters } = props.formikProps.values;

  // 「職員1人あたりの支援比率」をセットする（移動支援のグループ支援）
  React.useEffect(() => {
    const praNum = castNumber(numberOfPractitioner);
    const supNum = castNumber(numberOfSupporters);
    if (!praNum || !supNum) return;
    const result = Math.round((supNum / praNum) * 100) / 100;
    props.formikProps.setFieldValue("supportRatioPerPractitioner", `${result}`);
  }, [numberOfPractitioner, numberOfSupporters]);

  // 移動支援の支援人数の項目で全角数字->半角数字にする
  const formatMoney = (value: string): string => {
    const money = value
      .replace(/，|,/g, "")
      .replace(/[０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );

    const moneyValue = Number(money);
    if (Number.isNaN(moneyValue) || !money) {
      return value.replace(/[０-９，]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    }
    return moneyValue.toLocaleString();
  };

  const handleBlurSupporters = (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    return formatMoney(event.currentTarget.value);
  };

  const getForm = (): JSX.Element => {
    const {
      status,
      serviceDeliveryRecordPractitioners1,
      licenseSameFlg
    } = props.formikProps.values;
    const isNotGroupOrVehicleTransportStatus = ![
      IDOSHIEN_STATUS_LIST.GROUP.value,
      IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    ].includes(status);
    const hasNumberOfSupporters = !!numberOfSupporters;
    const isFiniteNumberOfSupporters = Number.isFinite(
      Number(numberOfSupporters)
    );

    switch (props.facilityType) {
      case FacilityType.DOKOENGO:
        return (
          <>
            <FormGroup row style={{ display: "flex", alignItems: "flex-end" }}>
              {numberOfPractitionerSelect}
              {checkBoxes}
            </FormGroup>
          </>
        );
      case FacilityType.KODOENGO:
        return (
          <>
            <FormGroup row style={{ display: "flex", alignItems: "flex-end" }}>
              {numberOfPractitionerSelect}
              {checkBoxes}
            </FormGroup>
          </>
        );
      case FacilityType.IDOSHIEN:
        return (
          <>
            <FormGroup row className={props.classes.alignEnd}>
              {statusSelect}
              {props.isInfoOpen && (
                <>
                  {status === IDOSHIEN_STATUS_LIST.OTHER.value && (
                    <FormikTextField
                      name="statusText"
                      maxLength={20}
                      style={{ width: "336px" }}
                    />
                  )}
                  <FormikCheckbox name="physicalCareFlg" label="身体介護" />
                </>
              )}
            </FormGroup>
            {props.isInfoOpen && (
              <>
                <FormGroup row>
                  <FormikSelect
                    name="numberOfPractitioner"
                    label="提供人数"
                    options={
                      status === IDOSHIEN_STATUS_LIST.GROUP.value
                        ? IDOSHIEN_GROUP_MEMBER_SELECT_LIST
                        : MEMBER_LIST_SERVICE_DETAIL
                    }
                    style={{ width: 156, paddingBottom: 32, marginBottom: 0 }}
                    onChangeHook={onChangeHookNumber}
                  />
                  {isNotGroupOrVehicleTransportStatus && (
                    <FormikCheckbox
                      name="licenseSameFlg"
                      label="2人が同時にサービスを提供する"
                      disabled={
                        numberOfPractitioner === IDOSHIEN_MEMBER_LIST.ONE.value
                      }
                      style={{ marginTop: "10px" }}
                    />
                  )}
                  {status === IDOSHIEN_STATUS_LIST.GROUP.value && (
                    <>
                      <FormikTextField
                        name="numberOfSupporters"
                        label="支援人数"
                        endAdornmentLabel="人"
                        maxLength={3}
                        style={{ width: "156px" }}
                        onBlurHook={handleBlurSupporters}
                      />
                      <MuiTextField
                        value={
                          hasNumberOfSupporters && isFiniteNumberOfSupporters
                            ? `1:${props.formikProps.values.supportRatioPerPractitioner}`
                            : ""
                        }
                        label="職員1人あたりの支援比率"
                        className={
                          props.classes
                            .supportRatioPerPractitionerStartAdornmentLabel
                        }
                        maxLength={20}
                        InputLabelProps={{
                          style: { whiteSpace: "nowrap" },
                          shrink: true
                        }}
                        style={{ width: "156px" }}
                        disabled
                        disabledStyle
                      />
                    </>
                  )}
                </FormGroup>
                {(status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ||
                  (isNotGroupOrVehicleTransportStatus && licenseSameFlg)) && (
                  <div className={props.classes.PB32}>
                    <ServiceDeliveryOnlyTimeContainer
                      formikProps={props.formikProps}
                      setFormikFieldValue={props.formikProps.setFieldValue}
                      facilityType={props.facilityType}
                      serviceDeliveryRecordPractitioners1={
                        serviceDeliveryRecordPractitioners1
                      }
                      isEdit={props.isEdit}
                    />
                  </div>
                )}
              </>
            )}
          </>
        );
      default:
        return (
          <>
            <FormGroup row style={{ display: "flex", alignItems: "flex-end" }}>
              {statusSelect}
              {checkBoxes}
            </FormGroup>
            {props.isInfoOpen && (
              <FormGroup
                row
                style={{ display: "flex", alignItems: "flex-end" }}
              >
                {numberOfPractitionerSelect}
                {accompanySupportDisplayFlg && (
                  <FormikCheckbox
                    name="accompanySupportFlg"
                    label={<CheckboxLabel label="同行支援" />}
                    style={{ marginRight: 20 }}
                  />
                )}
              </FormGroup>
            )}
          </>
        );
    }
  };

  return (
    <Paper elevation={0} className={props.classes.paper}>
      {props.isDaily ? (
        <ServiceDeliveryBasicFieldUserSelect
          formikProps={props.formikProps}
          setIsInfoOpen={props.setIsInfoOpen}
          isEdit={props.isEdit}
          statusOptions={statusOptions}
          facilityType={props.facilityType}
          detailRecords={props.detailRecords}
        />
      ) : (
        <ServiceDeliveryBasicFieldDateSelect
          targetDate={props.targetDate}
          isEdit={props.isEdit}
        />
      )}
      {getForm()}
    </Paper>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  serviceDeliveryUser: state.serviceDelivery.user
});

export const ServiceDeliveryBasicField = connect(
  mapStateToProps,
  {}
)(withStyles(styles)(ServiceDeliveryBasicFieldCore));
