import { GetPaginatedDailySupports } from "@api/requests/supports/SHISETSUNYUSHO/getPaginatedDailySupports";

/**
 * ActionNames
 */
export const FETCH_PAGINATED_DAILY_SUPPORTS_STARTED =
  "SUPPORTS/FETCH_PAGINATED_DAILY_SUPPORTS_STARTED";
export const FETCH_PAGINATED_DAILY_SUPPORTS_SUCCESS =
  "SUPPORTS/FETCH_PAGINATED_DAILY_SUPPORTS_SUCCESS";
export const FETCH_PAGINATED_DAILY_SUPPORTS_FAILED =
  "SUPPORTS/FETCH_PAGINATED_DAILY_SUPPORTS_FAILED";

/**
 * State
 */
export type PaginatedDailySupportsState = GetPaginatedDailySupports["data"];
