import * as React from "react";
// store
import { AppState } from "@stores/type";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";
import { connect } from "react-redux";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
// ui
import { OptionInterface } from "@components/atoms/DropDown";
import FormGroup from "@material-ui/core/FormGroup";
import { FormPaper } from "@componentsMobile/atoms/FormPaper";
import { ServiceDeliveryOnlyTimeContainer } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryOnlyTimeContainer";
// formik
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import { FormikProps, setNestedObjectValues } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import {
  validation,
  ServiceDeliveryDetailErrors
} from "@initialize/record/serviceDelivery/validation";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import {
  IDOSHIEN_GROUP_MEMBER_SELECT_LIST,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import { FacilityType, MEMBER_LIST_SERVICE_DETAIL } from "@constants/variables";
// utils
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { toEffectiveObject } from "@utils/object";
import merge from "lodash-es/merge";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { createStatusOptions } from "@utils/domain/serviceDelivery/createStatusOptions";
import castNumber from "@utils/dataNormalizer/castNumber";
// hooks
import { useAccompanyDisplayFlg } from "@hooks/record/serviceDelivery/useAccompanyDisplayFlg";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

type StateProps = {
  serviceDeliveryUser: ServiceDeliveryState["user"];
  facilityType: FacilityType;
  facilityUsers: GetFacilityUsersListResponse["data"];
};

type DispatchProps = {
  fetchUser: (id: string, facilityType: FacilityType) => void;
};

type OwnProps = {
  isNew: boolean;
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  setNumberOfPractitioner: (num: number) => void;
  isInfoOpen: boolean;
  setIsInfoOpen: (bool: boolean) => void;
  targetDate: string;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
};

type Props = OwnProps &
  StateProps &
  DispatchProps & {
    userListOption: OptionInterface[];
  };

const ServiceDeliveryBasicFieldCore = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  const {
    serviceDeliveryRecordsId,
    inoutResultsId,
    status,
    usersInFacilityId,
    numberOfPractitioner,
    numberOfSupporters,
    serviceDeliveryRecordPractitioners1,
    licenseSameFlg
  } = formikValues;
  // 種別ごとのサービス内容選択肢指定
  const statusOptions = createStatusOptions(props.facilityType);

  React.useEffect(() => {
    if (!numberOfPractitioner || !numberOfSupporters) {
      props.formikProps.setFieldValue("supportRatioPerPractitioner", "");
    } else {
      const praNum = castNumber(numberOfPractitioner);
      const supNum = castNumber(numberOfSupporters);
      const result = Math.round((supNum / praNum) * 100) / 100;
      props.formikProps.setFieldValue("supportRatioPerPractitioner", result);
    }
  }, [numberOfPractitioner, numberOfSupporters]);

  const onChangeHookUser = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    if (
      props.facilityType === FacilityType.JUDOHOMONKAIGO &&
      Number(e.target.value) !== 0
    ) {
      await props.fetchUser(e.target.value, props.facilityType);
    }
    if (
      status !== KYOTAKUKAIGO_STATUS_LIST.NONE.value &&
      Number(e.target.value) !== 0
    ) {
      props.setIsInfoOpen(true);
    } else {
      props.setIsInfoOpen(false);
    }
  };

  const onChangeHookStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (
      e.target.value !== KYOTAKUKAIGO_STATUS_LIST.NONE.value &&
      usersInFacilityId !== 0
    ) {
      props.setIsInfoOpen(true);
    } else {
      props.setIsInfoOpen(false);
    }
    // 移動支援では提供人数がサービスごとに異なるため、毎回リセットする
    if (props.facilityType === FacilityType.IDOSHIEN) {
      props.setFormikFieldValue(
        "numberOfPractitioner",
        IDOSHIEN_MEMBER_LIST.ONE.value
      );
      props.setNumberOfPractitioner(Number(IDOSHIEN_MEMBER_LIST.ONE.value));
    }
  };

  const onChangeHookNumber = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    props.setNumberOfPractitioner(Number(e.target.value));
    // 提供人数を「1」にしたとき，サービス提供記録２のエラーのある項目を空にする
    const validationResult = validation(
      props.formikProps.values,
      props.facilityType
    );
    const error = toEffectiveObject(
      validationResult
    ) as ServiceDeliveryDetailErrors;
    if (
      e.target.value === MEMBER_LIST_SERVICE_DETAIL[0].value &&
      error &&
      error.serviceDeliveryRecordPractitioners2
    ) {
      // エラーの項目を空にする
      const errorToBlackItem = setNestedObjectValues<
        ServiceDeliveryDetailErrors
      >(error, "");
      const mergedServiceDeliveryRecordPractitioners2 = merge(
        {},
        props.formikProps.values.serviceDeliveryRecordPractitioners2,
        errorToBlackItem.serviceDeliveryRecordPractitioners2
      );
      props.formikProps.setValues({
        ...props.formikProps.values,
        serviceDeliveryRecordPractitioners2: mergedServiceDeliveryRecordPractitioners2
      });
    }
  };

  // 同行支援の表示判定(重度のみ)
  const accompanySupportDisplayFlg =
    props.facilityType === FacilityType.JUDOHOMONKAIGO
      ? useAccompanyDisplayFlg(
          props.isNew,
          props.formikProps.values,
          props.serviceDeliveryUser
        )
      : false;

  // 移動支援の支援人数の項目で全角数字->半角数字にする
  const formatNumberOfSupporters = (value: string): string => {
    const numberOfSupportersValue = value
      .replace(/，|,/g, "")
      .replace(/[０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );

    const numberOfSupportersNum = Number(numberOfSupportersValue);
    if (Number.isNaN(numberOfSupportersNum) || !numberOfSupportersValue) {
      return value.replace(/[０-９，]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    }
    return numberOfSupportersNum.toLocaleString();
  };

  const handleBlurSupporters = (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    return formatNumberOfSupporters(event.currentTarget.value);
  };

  return (
    <FormPaper>
      {!serviceDeliveryRecordsId && !inoutResultsId && (
        <FormikSelect
          size="fullSize"
          isMobile
          readOnly={!props.isEdit}
          name="usersInFacilityId"
          label="利用者名"
          options={props.userListOption}
          onChangeHook={onChangeHookUser}
        />
      )}
      <FormGroup>
        {statusOptions.length > 0 && (
          <FormikSelect
            size="fullSize"
            isMobile
            readOnly={!props.isEdit}
            name="status"
            label="サービス内容"
            options={statusOptions}
            onChangeHook={onChangeHookStatus}
          />
        )}
        {props.facilityType === FacilityType.IDOSHIEN &&
          status === IDOSHIEN_STATUS_LIST.OTHER.value && (
            <FormikTextField
              isMobile
              readOnly={!props.isEdit}
              name="statusText"
              size="fullSize"
              maxLength={20}
            />
          )}
        {props.isInfoOpen && (
          <>
            {props.facilityType === FacilityType.IDOSHIEN ? (
              <FormikCheckbox
                isMobile
                readOnly={!props.isEdit}
                highEmphasis
                name="physicalCareFlg"
                label="身体介護"
                labelStyle={{ fontSize: 16 }}
              />
            ) : (
              <>
                <FormikCheckbox
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  name="emergencySupportFlg"
                  label="緊急時対応"
                  labelStyle={{ fontSize: 16 }}
                />
                <FormikCheckbox
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  name="sputumImplementationFlg"
                  label="喀痰吸引等実施"
                  labelStyle={{ fontSize: 16 }}
                />
                {props.facilityType === FacilityType.JUDOHOMONKAIGO && (
                  <FormikCheckbox
                    isMobile
                    name="emergencyGuideSupportFlg"
                    readOnly={!props.isEdit}
                    highEmphasis
                    label="移動介護緊急時支援"
                    labelStyle={{ fontSize: 16 }}
                  />
                )}
                {new Date(2023, 3, 1) <=
                  new Date(fixDateAndTimeFormat(props.targetDate)) && (
                  <FormikCheckbox
                    isMobile
                    readOnly={!props.isEdit}
                    highEmphasis
                    name="bodyRestrictedStillFlg"
                    label="身体拘束廃止未実施"
                    labelStyle={{ fontSize: 16 }}
                  />
                )}
              </>
            )}
            {props.facilityType === FacilityType.IDOSHIEN &&
            status === IDOSHIEN_STATUS_LIST.GROUP.value ? (
              <>
                <FormGroup row>
                  <FormikSelect
                    isMobile
                    size="mobileHalf"
                    readOnly={!props.isEdit}
                    name="numberOfPractitioner"
                    label="提供人数"
                    options={IDOSHIEN_GROUP_MEMBER_SELECT_LIST}
                    onChangeHook={onChangeHookNumber}
                  />
                  <FormikTextField
                    isMobile
                    size="mobileHalf"
                    readOnly={!props.isEdit}
                    name="numberOfSupporters"
                    label="支援人数"
                    endAdornmentLabel="人"
                    maxLength={3}
                    style={{ marginLeft: 16 }}
                    onBlurHook={handleBlurSupporters}
                  />
                </FormGroup>
                <ReadonlyTextField
                  value={
                    formikValues.supportRatioPerPractitioner
                      ? `1:${formikValues.supportRatioPerPractitioner}`
                      : "-"
                  }
                  label="職員1人あたりの支援比率"
                  defaultValue="-"
                  size="mobileHalf"
                  style={{ whiteSpace: "nowrap" }}
                />
              </>
            ) : (
              <FormikSelect
                isMobile
                size="mobileHalf"
                readOnly={!props.isEdit}
                name="numberOfPractitioner"
                label="提供人数"
                options={MEMBER_LIST_SERVICE_DETAIL}
                onChangeHook={onChangeHookNumber}
                style={{ marginBottom: 0, marginTop: 6 }}
              />
            )}
            {accompanySupportDisplayFlg && (
              <FormikCheckbox
                name="accompanySupportFlg"
                isMobile
                readOnly={!props.isEdit}
                highEmphasis
                label="同行支援"
                style={{ margin: "27px 0px 4px" }}
              />
            )}
            {props.facilityType === FacilityType.IDOSHIEN &&
              status !== IDOSHIEN_STATUS_LIST.GROUP.value &&
              status !== IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value && (
                <FormikCheckbox
                  name="licenseSameFlg"
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  disabled={numberOfPractitioner === "1"}
                  label="2人が同時にサービスを提供する"
                  labelStyle={{
                    color: `${
                      numberOfPractitioner === "1" ? "rgba(0, 0, 0, 0.26)" : ""
                    }`
                  }}
                  style={{ margin: "27px 0px 4px" }}
                />
              )}
            {props.facilityType === FacilityType.IDOSHIEN &&
              (status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ||
                licenseSameFlg) && (
                <ServiceDeliveryOnlyTimeContainer
                  isEdit={props.isEdit}
                  formikProps={props.formikProps}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  facilityType={props.facilityType}
                  practitionerValues={serviceDeliveryRecordPractitioners1}
                />
              )}
          </>
        )}
      </FormGroup>
    </FormPaper>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    serviceDeliveryUser: state.serviceDelivery.user,
    facilityUsers: state[facilityType].userInFacility.usersList
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery } = dispatches;
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchUser: (id: string, facilityType: FacilityType): Promise<void> =>
      serviceDeliveryDispatches.fetchUser(id, facilityType)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): Props => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const defaultOption = {
    label: "選択してください",
    value: 0
  };
  const userListOption = stateProps.facilityUsers.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });

  userListOption.unshift(defaultOption);
  return {
    userListOption,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ServiceDeliveryBasicFieldDaily = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(ServiceDeliveryBasicFieldCore);
