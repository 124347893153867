import React from "react";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { OperationsState } from "@stores/domain/mgr/TANKINYUSHO/operations/types";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { Formik, Form, FormikActions } from "formik";
import initialValues, {
  RecordOperationsValues
} from "@initialize/mgr/TANKINYUSHO/record/operations/initialValues";
import validation from "@initialize/mgr/TANKINYUSHO/record/operations/validation";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { OperationRecord } from "@components/organisms/mgr/TANKINYUSHO/record/OperationRecord";
import { OfferServiceSummary } from "@components/organisms/mgr/TANKINYUSHO/record/OfferServiceSummary";
import { MealSummary } from "@components/organisms/mgr/common/record/MealSummary";
import { toEffectiveObject } from "@utils/object";
import deepEqual from "fast-deep-equal";
import { FieldItem } from "@interfaces/ui/form";

import { AppState } from "@stores/type";
import { connect } from "react-redux";

const styles = (): StyleRules =>
  createStyles({
    operationRecordWrapper: {
      margin: "0 16px 16px"
    },
    summary: {
      marginBottom: 24,
      "& > div": {
        marginBottom: 8
      }
    }
  });

type OwnProps = {
  year: string;
  month: string;
  currentDate: Date;
  settingType: number;
  isEditing: boolean;
  editDate: string;
  customRecords: CustomRecordsState;
  mealData?: CustomRecordsState[number];
  operations: OperationsState;
  users: UsersInFacilityState["users"];
  staffOptions: FieldItem[];
  needsStopHistory: boolean;
  postCustomOperation: (
    params: RecordOperationsValues,
    initialValues: RecordOperationsValues,
    targetDate: string
  ) => Promise<void>;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  holidays: string[];
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const OperationRecordListCore = (props: Props): JSX.Element => {
  const [formValues, setFormValues] = React.useState(
    initialValues(props.operations, props.customRecords)
  );

  React.useEffect(() => {
    setFormValues(initialValues(props.operations, props.customRecords));
  }, [props.customRecords, props.operations]);

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditDate(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);

  const confirmDiscardFormChanges = (
    nextValues: RecordOperationsValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: RecordOperationsValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const onSubmit = async (
    values: RecordOperationsValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postCustomOperation(values, formValues, props.editDate);
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  return (
    <>
      <Formik
        initialValues={formValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => {
          const onClickEditCancel = (): void => {
            formikProps.resetForm();
            props.unsetEditDate();
            props.stopHistory(false);
          };
          return (
            <Form>
              {formikProps.values.record.length ===
                props.operations.operation.length &&
                props.operations.operation.map((operation, index) => {
                  const date = operation.target_date;
                  const dateNumber = parseInt(date.replace(/-/g, ""), 10);
                  const isHoliday = props.holidays.includes(date);
                  // 現在の編集対象
                  const isEditTarget = props.editDate === date;
                  // 編集中だが編集対象でない
                  const isEditNotTarget =
                    props.isEditing && props.editDate !== date;
                  // 利用状況サマリ（食事）
                  const mealItem = operation
                    ? operation.counts.meal
                    : undefined;
                  const isExistMealItem = mealItem
                    ? mealItem.choices.some((choice) => choice.count !== 0)
                    : false;
                  // 指定日付を編集状態に
                  const onClickEdit = (): void => {
                    props.setEditDate(date);
                  };
                  return (
                    <div
                      className={props.classes.operationRecordWrapper}
                      key={`${dateNumber}-wrapper`}
                    >
                      <OperationRecord
                        index={index}
                        handleClickEdit={onClickEdit}
                        handleClickCancel={onClickEditCancel}
                        formikProps={formikProps}
                        formikFieldNamePrefix={`record[${index}]`}
                        isEditing={isEditTarget}
                        isEditNotTarget={isEditNotTarget}
                        settingType={props.settingType}
                        customRecords={props.customRecords}
                        operation={operation}
                        staffOptions={props.staffOptions}
                        date={date}
                        isHoliday={isHoliday}
                      >
                        <div className={props.classes.summary}>
                          <OfferServiceSummary
                            status_type={
                              operation
                                ? operation.counts.status_type
                                : undefined
                            }
                          />
                          {props.mealData &&
                            (props.mealData.visibility === 1 ||
                              isExistMealItem) && (
                              <MealSummary
                                mealData={props.mealData}
                                mealItem={mealItem}
                                date={date}
                              />
                            )}
                        </div>
                      </OperationRecord>
                    </div>
                  );
                })}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  holidays: state.holiday
});

export const OperationRecordList = withStyles(styles)(
  connect(mapStateToProps)(OperationRecordListCore)
);
