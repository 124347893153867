import trimString from "@utils/dataNormalizer/trimString";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 *  現在日時点、もしくは指定日付時点の満年齢を算出する
 */
const getAge = (
  dateOfBirth: string | undefined | null,
  baseDate?: string | undefined | null
): number | null => {
  const birthDate = trimString(dateOfBirth);
  if (!birthDate) return null;
  const birthYear = dateToLocalisedString(birthDate, "YYYY");
  const birthMonth = dateToLocalisedString(birthDate, "MM");
  const birthDay = dateToLocalisedString(birthDate, "DD");

  const base = baseDate || new Date();
  const baseYear = dateToLocalisedString(base, "YYYY");
  const baseMonth = dateToLocalisedString(base, "MM");
  const baseDay = dateToLocalisedString(base, "DD");

  // 引き算
  return Math.floor(
    (Number(baseYear + baseMonth + baseDay) -
      Number(birthYear + birthMonth + birthDay)) /
      10000
  );
};

export default getAge;
