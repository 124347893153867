import * as types from "./types";
import { GetSupportPlanUifIdCarePlan } from "@api/requests/carePlan/getSupportPlanUifIdCarePlan";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { CityState } from "@stores/domain/city/type";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};
// fetchSupportPlanUifIdCarePlan
export const fetchSupportPlanUifIdCarePlanStarted = () =>
  ({ type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_STARTED } as const);
export const fetchSupportPlanUifIdCarePlanSuccess = (
  res: GetSupportPlanUifIdCarePlan["data"]
) =>
  ({ type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_SUCCESS, payload: res } as const);
export const fetchSupportPlanUifIdCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_FAILED, error: err } as const);

// fetchSupportPlanUifIdCarePlanCarePlanId
export const fetchSupportPlanUifIdCarePlanCarePlanIdStarted = () =>
  ({ type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED } as const);
export const fetchSupportPlanUifIdCarePlanCarePlanIdSuccess = (
  res: GetSupportPlanUifIdCarePlanCarePlanId["data"]
) =>
  ({
    type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS,
    payload: res
  } as const);
export const fetchSupportPlanUifIdCarePlanCarePlanIdFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED,
    error: err
  } as const);

// update
export const updateSchedulesStarted = () =>
  ({ type: types.UPDATE_SCHEDULES_STARTED } as const);
export const updateSchedulesSuccess = (
  res: GetSupportPlanUifIdCarePlanCarePlanId["data"]
) =>
  ({
    type: types.UPDATE_SCHEDULES_SUCCESS,
    payload: res
  } as const);
export const updateSchedulesFailed = (err: ErrorResponse) =>
  ({
    type: types.UPDATE_SCHEDULES_FAILED,
    error: err
  } as const);

// Edit
export const setEdit = () => ({ type: types.SET_EDIT } as const);
export const unsetEdit = () => ({ type: types.UNSET_EDIT } as const);

// fetchCityFailed
export const fetchCityStarted = () =>
  ({ type: types.FETCH_CITY_STARTED } as const);
export const fetchCitySuccess = (res: CityState[] | null) =>
  ({ type: types.FETCH_CITY_SUCCESS, payload: res } as const);
export const fetchCityFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_CITY_FAILED, error: err } as const);

// postSupportPlanUifIdCarePlanCareNew
export const postSupportPlanUifIdCarePlanNewStarted = () =>
  ({ type: types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_STARTED } as const);
export const postSupportPlanUifIdCarePlanNewSuccess = () =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_SUCCESS
  } as const);
export const postSupportPlanUifIdCarePlanNewFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_FAILED,
    error: err
  } as const);

// postSupportPlanUifIdCarePlanCarePlanId
export const postSupportPlanUifIdCarePlanCarePlanIdStarted = () =>
  ({ type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED } as const);
export const postSupportPlanUifIdCarePlanCarePlanIdSuccess = () =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS
  } as const);
export const postSupportPlanUifIdCarePlanCarePlanIdFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED,
    error: err
  } as const);

// deleteSupportPlanUifIdCarePlanCarePlanId
export const deleteSupportPlanUifIdCarePlanCarePlanIdStarted = () =>
  ({ type: types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED } as const);
export const deleteSupportPlanUifIdCarePlanCarePlanIdSuccess = (
  carePlanId: string
) =>
  ({
    type: types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS,
    meta: { carePlanId }
  } as const);
export const deleteSupportPlanUifIdCarePlanCarePlanIdFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED,
    error: err
  } as const);

// postSupportPlanUifIdCarePlanCarePlanIdDetails
export const postSupportPlanUifIdCarePlanCarePlanIdDetailsStarted = () =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_STARTED
  } as const);
export const postSupportPlanUifIdCarePlanCarePlanIdDetailsSuccess = () =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_SUCCESS
  } as const);
export const postSupportPlanUifIdCarePlanCarePlanIdDetailsFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_FAILED,
    error: err
  } as const);

// deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId
export const deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdStarted = () =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_STARTED
  } as const);
export const deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdSuccess = () =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_SUCCESS
  } as const);
export const deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdFailed = (
  err: ErrorResponse
) =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanStarted>
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanSuccess>
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanFailed>
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanCarePlanIdStarted>
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanCarePlanIdSuccess>
  | ReturnType<typeof fetchSupportPlanUifIdCarePlanCarePlanIdFailed>
  | ReturnType<typeof updateSchedulesStarted>
  | ReturnType<typeof updateSchedulesSuccess>
  | ReturnType<typeof updateSchedulesFailed>
  | ReturnType<typeof postSupportPlanUifIdCarePlanNewStarted>
  | ReturnType<typeof postSupportPlanUifIdCarePlanNewSuccess>
  | ReturnType<typeof postSupportPlanUifIdCarePlanNewFailed>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdStarted>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdSuccess>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdFailed>
  | ReturnType<typeof deleteSupportPlanUifIdCarePlanCarePlanIdStarted>
  | ReturnType<typeof deleteSupportPlanUifIdCarePlanCarePlanIdSuccess>
  | ReturnType<typeof deleteSupportPlanUifIdCarePlanCarePlanIdFailed>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdDetailsStarted>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdDetailsSuccess>
  | ReturnType<typeof postSupportPlanUifIdCarePlanCarePlanIdDetailsFailed>
  | ReturnType<
      typeof deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdStarted
    >
  | ReturnType<
      typeof deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdSuccess
    >
  | ReturnType<
      typeof deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleIdFailed
    >
  | ReturnType<typeof setEdit>
  | ReturnType<typeof unsetEdit>
  | ReturnType<typeof fetchCityStarted>
  | ReturnType<typeof fetchCitySuccess>
  | ReturnType<typeof fetchCityFailed>;
