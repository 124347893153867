import { getHolidayResponse } from "@api/requests/holiday/getHoliday";

/**
 * ActionNames
 */
export const FETCH_HOLIDAY_STARTED = "SUPPORTS/FETCH_HOLIDAY_STARTED";
export const FETCH_HOLIDAY_SUCCESS = "SUPPORTS/FETCH_HOLIDAY_SUCCESS";
export const FETCH_HOLIDAY_FAILED = "SUPPORTS/FETCH_HOLIDAY_FAILED";

/**
 * State
 */
export type HolidayState = getHolidayResponse["data"];
