import * as types from "./types";
import * as actions from "./actions";

const initialState: types.CustomRecordsState = [];

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.CustomRecordsState => {
  switch (action.type) {
    case types.FETCH_CUSTOM_RECORDS_STARTED:
      return state;
    case types.FETCH_CUSTOM_RECORDS_SUCCESS:
      return action.payload;
    case types.FETCH_CUSTOM_RECORDS_FAILED:
      return state;
    case types.FETCH_SUPPORT_CUSTOM_RECORDS_STARTED:
      return state;
    case types.FETCH_SUPPORT_CUSTOM_RECORDS_SUCCESS:
      return [...state, ...action.payload];
    case types.FETCH_SUPPORT_CUSTOM_RECORDS_FAILED:
    case types.POST_CUSTOM_RECORDS_STARTED:
      return state;
    case types.POST_CUSTOM_RECORDS_SUCCESS:
      return state;
    case types.POST_CUSTOM_RECORDS_FAILED:
      return state;
    case types.POST_CUSTOM_RECORDS_ORDER_STARTED:
      return state;
    case types.POST_CUSTOM_RECORDS_ORDER_SUCCESS:
      return state;
    case types.POST_CUSTOM_RECORDS_ORDER_FAILED:
      return state;
    case types.POST_HIDDEN_CHOICE_STARTED:
      return state;
    case types.POST_HIDDEN_CHOICE_SUCCESS:
      return state;
    case types.POST_HIDDEN_CHOICE_FAILED:
      return state;
    case types.HIDE_CUSTOM_RECORD_STARTED:
      return state;
    case types.HIDE_CUSTOM_RECORD_SUCCESS:
      return state;
    case types.HIDE_CUSTOM_RECORD_FAILED:
      return state;
    case types.SHOW_CUSTOM_RECORD_STARTED:
      return state;
    case types.SHOW_CUSTOM_RECORD_SUCCESS:
      return state;
    case types.SHOW_CUSTOM_RECORD_FAILED:
      return state;
    default:
      return state;
  }
};

export default reducer;
