import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { UsagePerformanceTableHeader } from "@components/v202104/organisms/mgr/GroupHome/report/UsagePerformanceTableHeader";
import UsagePerformanceReportDailyCell from "@components/v202104/organisms/mgr/GroupHome/report/cells/UsagePerformanceReportDailyCell";
import {
  REPEAT_DAILY,
  ReportState
} from "@stores/v202104/domain/mgr/GroupHome/report/type";
import { FacilityState } from "@stores/v202104/domain/mgr/GroupHome/facility/types";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    table: {
      minWidth: 1030
    },
    row: {
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    }
  });

interface StateProps {
  report: ReportState;
  facility: FacilityState;
}

interface DispatchProps {
  setStatusType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setNightSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setNightSupportTargetUsersType: (value: string, key: number) => void;
  setHospitalizationSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setGetHomeSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setDaytimeSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setMedicalSupportType: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setSputumGuidanceFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setLifeSupportFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setHomeCareFlg: (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => void;
  setRemarks: (remarks: string, key: number) => void;
}

interface OwnProps {
  isEditing: boolean;
  headerHeight: number;
  onChangeDisabled: (errorMessage: string, key: number) => void;
}

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

const UsagePerformanceDailyTable = (props: Props): JSX.Element => {
  const rows = props.isEditing
    ? props.report.reportDaily.after
    : props.report.reportDaily.before;
  const TableList = rows.map((report, index) => {
    const uniqueKey = `table-row-${index}`;
    return (
      <TableRow key={uniqueKey} className={props.classes.row}>
        <UsagePerformanceReportDailyCell
          params={report}
          facility={props.facility}
          key={`${uniqueKey}-cell`}
          idx={index}
          isEditing={props.isEditing}
          hasNightSupportType={props.facility.nightSupportFlag}
          setStatusType={props.setStatusType}
          setNightSupportType={props.setNightSupportType}
          setNightSupportTargetUsersType={props.setNightSupportTargetUsersType}
          setHospitalizationSupportType={props.setHospitalizationSupportType}
          setGetHomeSupportType={props.setGetHomeSupportType}
          setDaytimeSupportType={props.setDaytimeSupportType}
          setMedicalSupportType={props.setMedicalSupportType}
          setSputumGuidanceFlg={props.setSputumGuidanceFlg}
          setLifeSupportFlg={props.setLifeSupportFlg}
          setHomeCareFlg={props.setHomeCareFlg}
          setRemarks={props.setRemarks}
          onChangeDisabled={props.onChangeDisabled}
        />
      </TableRow>
    );
  });

  return (
    <>
      <UsagePerformanceTableHeader
        firstLabel="利用者名"
        hasNightSupportType={props.facility.nightSupportFlag}
        headerHeight={props.headerHeight}
      />
      <Table key="monthly-report-table" className={props.classes.table}>
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者が登録されていません。
          <br />
          利用者情報画面で登録後、ご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    report: state.v202104.GroupHome.report as ReportState,
    facility: state.v202104.GroupHome.facility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const reportDispatches = v202104.GroupHome.reportDispatcher(dispatch);

  return {
    setStatusType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setStatusType(event.target.value, key, REPEAT_DAILY),
    setNightSupportType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setNightSupportType(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setNightSupportTargetUsersType: (value: string, key: number): void =>
      reportDispatches.setNightSupportTargetUsersType(value, key, REPEAT_DAILY),
    setHospitalizationSupportType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setHospitalizationSupportType(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setGetHomeSupportType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setGetHomeSupportType(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setDaytimeSupportType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setDaytimeSupportType(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setMedicalSupportType: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setMedicalSupportType(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setSputumGuidanceFlg: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setSputumGuidanceFlg(
        event.target.value,
        key,
        REPEAT_DAILY
      ),
    setLifeSupportFlg: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setLifeSupportFlg(event.target.value, key, REPEAT_DAILY),
    setHomeCareFlg: (
      event: React.ChangeEvent<HTMLInputElement>,
      key: number
    ): void =>
      reportDispatches.setHomeCareFlg(event.target.value, key, REPEAT_DAILY),
    setRemarks: (remarks: string, key: number): void =>
      reportDispatches.setRemarks(remarks, key, REPEAT_DAILY)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsagePerformanceDailyTable));
