export const FETCH_LATEST_INOUT_ERRORS_STARTED =
  "PAGES/REPORT_201910/DAILY/FETCH_LATEST_INOUT_ERRORS_STARTED";
export const FETCH_LATEST_INOUT_ERRORS_SUCCESS =
  "PAGES/REPORT_201910/DAILY/FETCH_LATEST_INOUT_ERRORS_SUCCESS";
export const FETCH_LATEST_INOUT_ERRORS_FAILED =
  "PAGES/REPORT_201910/DAILY/FETCH_LATEST_INOUT_ERRORS_FAILED";

export interface ReportDailyState {
  errorsDateList: string[];
}
