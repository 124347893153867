import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    section: {
      marginBottom: 32
    }
  });

type OwnProps = {
  targetType:
    | typeof FACILITY_TYPE_KEIKAKUSODAN
    | typeof FACILITY_TYPE_SHOGAIJISODAN;
  formikFieldNamePrefix: string;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const RecipientCertificateFieldsCore = (props: Props): JSX.Element => {
  const START_ADD_YEAR_TO = 1;
  const END_ADD_YEAR_TO = 5;

  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle
          label={`受給者証の詳細（${FACILITY_TYPE_LIST[props.targetType]}）`}
        />
      </div>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.userChargeLimitFlag`}
        label="負担上限月額の適用期間"
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.userChargeLimitStartDate`}
          label="適用開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.userChargeLimitEndDate`}
          label="適用終了日"
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.careSupportAuthFlag`}
        label="障害支援区分の認定有効期間"
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.careSupportAuthStartDate`}
          label="認定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.careSupportAuthEndDate`}
          label="認定終了日"
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
      {props.targetType === FACILITY_TYPE_KEIKAKUSODAN && (
        <FormikSwitch
          name={`${props.formikFieldNamePrefix}.careSupportPaymentFlag`}
          label="介護給付費の支給決定期間"
        >
          <FormikSelectDateNotSelectedDefault
            name={`${props.formikFieldNamePrefix}.careSupportPaymentStartDate`}
            label="支給決定開始日"
            style={{ marginBottom: 12 }}
            addYearTo={START_ADD_YEAR_TO}
            setFormikFieldValue={props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name={`${props.formikFieldNamePrefix}.careSupportPaymentEndDate`}
            label="支給決定終了日"
            addYearTo={END_ADD_YEAR_TO}
            setFormikFieldValue={props.setFormikFieldValue}
          />
        </FormikSwitch>
      )}
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.planSupportPaymentFlag`}
        label={
          props.targetType === FACILITY_TYPE_KEIKAKUSODAN
            ? "訓練給付費の支給決定期間"
            : "障害児通所給付費の支給決定期間"
        }
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.planSupportPaymentStartDate`}
          label="支給決定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.planSupportPaymentEndDate`}
          label="支給決定終了日"
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.planSupportMonitorFlag`}
        label={
          props.targetType === FACILITY_TYPE_KEIKAKUSODAN
            ? "計画相談支援給付費のモニタリング期間"
            : "障害児相談支援給付費のモニタリング期間"
        }
        style={{ marginBottom: 0 }}
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.planSupportMonitorStartDate`}
          label="モニタリング開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.planSupportMonitorEndDate`}
          label="モニタリング終了日"
          style={{ marginBottom: 0 }}
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
    </FormPaper>
  );
};

export const RecipientCertificateFields = withStyles(styles)(
  RecipientCertificateFieldsCore
);
