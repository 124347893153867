import { UsersInFacilityState } from "@stores/domain/mgr/IAB/userInFacility/types";

export const SET_USERS = "IAB/RECORD/USERS_SUMMARY/SET_USERS";
export const SET_SEARCH_CONDITIONS =
  "IAB/RECORD/USERS_SUMMARY/SET_SEARCH_CONDITIONS";

export type IABRecordUsersSummaryState = {
  users: UsersInFacilityState["users"];
  searchConditionsIds: number[];
};
