import ValidationErrors from "@interfaces/ui/validationErrors";
import validator from "@validator";
import { OperationsValues } from "@initialize/mgr/Cseg/record/operations/initialValues";

export const validation = (
  values: OperationsValues
): ValidationErrors<OperationsValues> => {
  return {
    operations: values.operations.map((item) => ({
      operation_in_the_morning: validator(item.operation_in_the_morning || "", {
        type: "checkCharacterLength",
        length: 2500
      }),
      operation_in_the_afternoon: validator(
        item.operation_in_the_afternoon || "",
        {
          type: "checkCharacterLength",
          length: 2500
        }
      ),
      other_comment: validator(item.other_comment || "", {
        type: "checkCharacterLength",
        length: 2500
      })
    }))
  };
};
