import { GetOperationsAndSupports } from "@api/requests/operations/Cseg/getOperationsAndSupports";

/**
 * ActionNames
 */
export const FETCH_OPERATIONS_AND_SUPPORTS_STARTED =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATIONS_STARTED";
export const FETCH_OPERATIONS_AND_SUPPORTS_SUCCESS =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATIONS_SUCCESS";
export const FETCH_OPERATIONS_AND_SUPPORTS_FAILED =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATIONS_FAILED";
export const POST_SUPPORTS_STARTED =
  "OPERATIONS_AND_SUPPORTS/POST_SUPPORTS_STARTED";
export const POST_SUPPORTS_SUCCESS =
  "OPERATIONS_AND_SUPPORTS/POST_SUPPORTS_SUCCESS";
export const POST_SUPPORTS_FAILED =
  "OPERATIONS_AND_SUPPORTS/POST_SUPPORTS_FAILED";
export const POST_OPERATION_STARTED =
  "OPERATIONS_AND_SUPPORTS/POST_OPERATION_STARTED";
export const POST_OPERATION_SUCCESS =
  "OPERATIONS_AND_SUPPORTS/POST_OPERATION_SUCCESS";
export const POST_OPERATION_FAILED =
  "OPERATIONS_AND_SUPPORTS/POST_OPERATION_FAILED";
export const FETCH_OPERATION_STARTED =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATION_STARTED";
export const FETCH_OPERATION_SUCCESS =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATION_SUCCESS";
export const FETCH_OPERATION_FAILED =
  "OPERATIONS_AND_SUPPORTS/FETCH_OPERATION_FAILED";

/**
 * State
 */
export type OperationsAndSupportsState = GetOperationsAndSupports["data"];
export type OperationState = OperationsAndSupportsState["operation_records"];
export type SupportState = OperationsAndSupportsState["support_records"];
