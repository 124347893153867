import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

/**
 * 自治体情報（municipalities）を取得するAPI
 * doc: https://rhc-bd.atlassian.net/wiki/spaces/KNOW/pages/2686025819/GET+host+version+mgr+facility+municipalities+municipality+id
 */
export type GetFacilityMunicipalitiesTargetIdResponse = BaseResponse<{
  municipality: {
    id: number;
    name: string;
    csv_file_name?: string | null;
    csv_file_uploaded_date?: string | null;
    csv_file_updated_date?: string | null;
    calculation_time_flg: number;
    round_up_minute: number | null;
    time_division_flg: number;
    billing_unit: number;
    unit_price_per_unit: number | null;
  };
}>;

/**
 * 指定した自治体情報を取得する。
 * @param id 自治体ID
 */
export const getFacilityMunicipalitiesTargetId = async (
  id: string
): Promise<AxiosResponse<GetFacilityMunicipalitiesTargetIdResponse>> => {
  const url = `${VERSION_URL_201910}/facility/municipalities/${id}`;
  return request.get<GetFacilityMunicipalitiesTargetIdResponse>(url);
};

export default getFacilityMunicipalitiesTargetId;
