import * as React from "react";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
};

const InOutReportDailyHeader = (props: OwnProps): JSX.Element => {
  return (
    <DateSelectButtonsDaily
      defaultDate={props.selectedDate}
      min={props.minDate}
      max={props.maxDate}
      onClickSubmit={props.onChangeDate}
    />
  );
};

export default InOutReportDailyHeader;
