import * as React from "react";

import { convertWareki } from "@utils/date";

import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/domain/invoice/type";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  JUDOHOMONKAIGO_JISSEKI_STATUS_LIST,
  JUDOHOMONKAIGO_DISPATCH_NO_LIST
} from "@constants/mgr/JUDOHOMONKAIGO/variables";

const PRINT_PAGE_MAX_HEIGHT = 900;
const PRINT_END_PAGE_MAX_HEIGHT = 850;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.thin": {
        border: "1px solid"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        height: 20,
        wordBreak: "break-all",
        overflow: "hidden",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.miniLabel": {
          width: 40,
          textAlign: "center",
          "& > div": {
            width: 40,
            transform: "scale(0.8) translate(0px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.miniLabelStatus": {
          width: 48,
          textAlign: "center",
          "& > div": {
            width: 48,
            transform: "scale(0.8) translate(0px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.miniLabelPer": {
          maxWidth: 30,
          textAlign: "center",
          "& > div": {
            width: 30,
            transform: "scale(0.8) translate(-2px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.ssmall": {
          height: 20
        },
        "&.timesmall": {
          height: 40
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssssize": {
          width: 22
        },
        "&.timesize": {
          width: 30
        },
        "&.ssssize": {
          width: 34
        },
        "&.sssize": {
          width: 40
        },
        "&.daysize": {
          width: 20
        },
        "&.guidecaresize": {
          width: 32
        },
        "&.headersize": {
          width: 62
        },
        "&.officeName": {
          width: 66
        },
        "&.recipientnamesize": {
          width: 167
        },
        "&.remarkssize": {
          width: 70
        },
        "&.ssize": {
          width: 80
        },
        "&.memosize": {
          width: 75
        },
        "&.msize": {
          width: 112
        },
        "&.bsize": {
          fontSize: 8,
          width: 30
        },
        "&.lsize": {
          width: 150
        },
        "&.llsize": {
          width: 200
        },
        "&.vertical": {
          height: 50,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderBottomBold": {
          borderBottom: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDoubleTop": {
          borderTopStyle: "double",
          borderTopWidth: "4px"
        },
        "&.borderDashed": {
          borderBottom: "1px dashed"
        }
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    empty: {
      width: "120%",
      transform: "matrix(-1.1, 0.6, 0.5, 0.8, -1.7, -0.3)",
      borderTop: "solid black 1px"
    },
    empty2: {
      width: "125%",
      transform: "matrix(-0.9, 0.9, 0.5, 1, -2, 0.2)",
      borderTop: "solid black 1px"
    },
    empty3: {
      width: "110%",
      transform: "matrix(-7, 4, 0.7, 0.7, -1, 0.3)",
      borderTop: "solid black 1px"
    },
    empty4: {
      width: "156%",
      transform: "matrix(5, -2.3, 0, -1, -4, -2.7)",
      borderTop: "solid black 1px"
    },
    empty5: {
      width: "87%",
      transform: "matrix(-2, 0.5, 1, 0.7, 3, 1)",
      borderTop: "solid black 1px"
    },
    empty6: {
      width: "120%",
      transform: "matrix(-1.1, 0.9, 0.5, 0.8, -1.7, 0.3)",
      borderTop: "solid black 1px"
    },
    empty7: {
      width: "120%",
      transform: "matrix(-1, 0.6, 0.5, 0.8, -1.7, -0.3)",
      borderTop: "solid black 1px"
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 重度訪問介護の実績記録票
 */
const InoutRecordTableCore = (props: Props): JSX.Element => {
  const { key, invoiceData } = props;
  const { date, facility, users } = invoiceData;
  const { classes } = props;
  let pageSum = 1;
  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;
        // デフォルトの高さ定義
        const defaultHeight = 37;
        const emptyHeight = 23;
        const indexArray: number[] = [0];
        const data = user.inout_results_judohomonkaigo
          ? user.inout_results_judohomonkaigo.reduce((sum, record, i) => {
              const len = record.memo ? record.memo.length : 0;
              const numberOfLines = Math.ceil(len / 7);
              const isLastData =
                user.inout_results_judohomonkaigo &&
                user.inout_results_judohomonkaigo.length - 1 === i;
              let result = defaultHeight;
              // サービス内容が「居宅」または「入院」かつ、備考が1行以下の場合は備考を考慮せずに高さを設定する(空行と同じ高さ)
              if (
                (record.status.toString() === "2" ||
                  record.status.toString() === "3") &&
                numberOfLines <= 1
              ) {
                result = emptyHeight;
              }
              // 備考が2行より多い場合、備考の文字数から高さを導出
              if (numberOfLines > 2) {
                result = defaultHeight + (numberOfLines - 2) * 18.5;
              }
              // 該当行が次のページかどうかを判定
              if (
                sum + result > PRINT_PAGE_MAX_HEIGHT ||
                (isLastData && sum + result > PRINT_END_PAGE_MAX_HEIGHT)
              ) {
                indexArray.push(i);
              } else {
                result = sum + result;
              }
              return result;
            }, 0)
          : 0;

        // 空行の行数を導出
        const marginLength = Math.floor(
          (PRINT_END_PAGE_MAX_HEIGHT - data) / emptyHeight
        );
        // ページ数
        pageSum = indexArray.length;
        const inoutResultsJudohomonkaigoSize = user.inout_results_judohomonkaigo
          ? user.inout_results_judohomonkaigo.length
          : 0;

        return (
          <React.Fragment key={keyIndex}>
            {indexArray.map((firstNumberOfPage, pageIndex) => {
              return (
                <section
                  key={`InoutRecordTable-${keyIndex}`}
                  className={classes.page}
                >
                  <div className={`${classes.flexContainer} center relative`}>
                    <div className={classes.date}>
                      <span>{`${convertWareki(date.year, date.month).era}${
                        convertWareki(date.year, date.month).year
                      }年${date.month}月分`}</span>
                    </div>
                    <span>重度訪問介護サービス提供実績記録票</span>
                  </div>
                  <div className={classes.flexContainer}>
                    <table className={`${classes.table} fullWidth`}>
                      <tbody>
                        <tr>
                          <td className="label headersize">
                            受給者証
                            <br />
                            番号
                          </td>
                          <td className="label ssize">{user.recipientNo}</td>
                          <td className="label msize">支給決定障害者等氏名</td>
                          <td className="label recipientnamesize">
                            {user.recipientName}
                          </td>
                          <td className="label" colSpan={2}>
                            事業所番号
                          </td>
                          <td className="label ssize">{facility.officeNo}</td>
                        </tr>
                        <tr>
                          <td className="label">契約支給量</td>
                          <td colSpan={3} className="ssize">
                            {user.payment}
                          </td>
                          <td className="label officeName">
                            事業者及び
                            <br />
                            その事業所
                          </td>
                          <td colSpan={2} className="label">
                            {facility.officeName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.flexContainer}>
                    {pageSum === pageIndex + 1 ? (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsJudohomonkaigoIndex={firstNumberOfPage}
                        inoutResultsJudohomonkaigoSize={
                          inoutResultsJudohomonkaigoSize - firstNumberOfPage
                        }
                        marginLength={marginLength < 0 ? 0 : marginLength}
                        isEndRow
                      />
                    ) : (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsJudohomonkaigoIndex={firstNumberOfPage}
                        inoutResultsJudohomonkaigoSize={
                          indexArray[pageIndex + 1] - firstNumberOfPage
                        }
                        marginLength={0}
                        isEndRow={false}
                      />
                    )}
                  </div>
                  <div className={`${classes.flexContainer} end`}>
                    <table className={`${classes.table} thin`}>
                      <tbody>
                        <tr>
                          <td className="label">{pageSum}</td>
                          <td className="label">枚中</td>
                          <td className="label">{pageIndex + 1}</td>
                          <td className="label">枚</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
  inoutResultsJudohomonkaigoIndex: number;
  inoutResultsJudohomonkaigoSize: number;
  marginLength: number;
  isEndRow: boolean;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const {
    user,
    classes,
    inoutResultsJudohomonkaigoIndex,
    inoutResultsJudohomonkaigoSize,
    isEndRow,
    marginLength
  } = props;
  const inoutResultsJudohomonkaigo = user.inout_results_judohomonkaigo
    ? user.inout_results_judohomonkaigo
    : [];
  const emptyList = new Array<undefined>(marginLength).fill(undefined);
  const records = [
    ...inoutResultsJudohomonkaigo.slice(
      inoutResultsJudohomonkaigoIndex,
      inoutResultsJudohomonkaigoIndex + inoutResultsJudohomonkaigoSize
    ),
    ...emptyList
  ];

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={3} className="label vertical">
            <span>日付</span>
          </td>
          <td rowSpan={3} className="label vertical">
            <span>曜日</span>
          </td>
          <td rowSpan={3} className="label vertical borderBold" />
          <td rowSpan={3} className="miniLabelStatus borderBold">
            <div>
              サービス
              <br />
              提供の
              <br />
              状況
            </div>
          </td>
          <td colSpan={4} className="label borderBold">
            重度訪問介護計画
          </td>
          <td colSpan={2} className="label">
            サービス
            <br />
            提供時間
          </td>
          <td colSpan={2} className="label borderBold">
            算定
            <br />
            時間数
          </td>
          <td rowSpan={3} className="label borderBold">
            派<br />遣<br />人<br />数
          </td>
          <td rowSpan={3} className="label">
            同<br />行<br />支<br />援
          </td>
          <td rowSpan={3} className="label">
            初<br />回<br />加<br />算
          </td>
          <td rowSpan={3} className="label bsize">
            緊急
            <br />
            時<br />
            対応
            <br />
            加算
          </td>
          <td rowSpan={3} className="label">
            行動
            <br />
            障害
            <br />
            支援
            <br />
            連携
            <br />
            加算
          </td>
          <td rowSpan={3} className="label borderBold">
            移動
            <br />
            介護
            <br />
            緊急
            <br />
            時
            <br />
            支援
            <br />
            加算
          </td>
          <td rowSpan={3} className="label borderBold">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={3} className="label">
            備考
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="label ">
            開始
            <br />
            時間
          </td>
          <td rowSpan={2} className="label ">
            終了
            <br />
            時間
          </td>
          <td colSpan={2} className="label borderBold">
            計画
            <br />
            時間数
          </td>
          <td rowSpan={2} className="label">
            開始
            <br />
            時間
          </td>
          <td rowSpan={2} className="label ">
            終了
            <br />
            時間
          </td>
          <td rowSpan={2} className="label">
            時間
          </td>
          <td rowSpan={2} className="label borderBold">
            移動
          </td>
        </tr>
        <tr>
          <td className="label timesmall">時間</td>
          <td className="label timesmall borderBold">移動</td>
        </tr>
        {records.map((inout_results_judohomonkaigo, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";
          const statusValue =
            inout_results_judohomonkaigo && inout_results_judohomonkaigo.status
              ? inout_results_judohomonkaigo.status.toString()
              : "";
          const serialNumberValue =
            inout_results_judohomonkaigo &&
            inout_results_judohomonkaigo.serialNumber
              ? inout_results_judohomonkaigo.serialNumber.toString()
              : "";
          const getStatus = (status: string): JSX.Element => {
            switch (status) {
              case "0":
                return <td className="label borderBold ssmall" />;
              case "4":
                return (
                  <td className="label borderBold ssmall">
                    入院
                    <br />
                    (長期)
                  </td>
                );
              default:
                return (
                  <td className="label borderBold ssmall">
                    {getLabelFromOptions(
                      statusValue,
                      JUDOHOMONKAIGO_JISSEKI_STATUS_LIST
                    )}
                  </td>
                );
            }
          };
          return (
            <tr
              key={`Records-${inoutResultsJudohomonkaigoIndex}-${keyIndex}`}
              className={rowClass}
            >
              <td className="label ssmall daysize">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.day
                  : ""}
              </td>
              <td className="label ssmall daysize">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.dayOfWeek
                  : ""}
              </td>
              <td className="label borderBold ssmall daysize">
                {inout_results_judohomonkaigo && serialNumberValue.length
                  ? getLabelFromOptions(
                      serialNumberValue,
                      JUDOHOMONKAIGO_DISPATCH_NO_LIST
                    )
                  : ""}
              </td>
              {getStatus(statusValue)}
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.planInTime
                  : ""}
              </td>
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.planOutTime
                  : ""}
              </td>
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.planHours
                  : ""}
              </td>
              <td className="label ssssize borderBold ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.planGuideHours > 0 &&
                inout_results_judohomonkaigo.planGuideHours !== 0.5
                  ? inout_results_judohomonkaigo.planGuideHours
                  : ""}
              </td>
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.inTime
                  : ""}
              </td>
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.outTime
                  : ""}
              </td>
              <td className="label ssssize ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.calculatedHours
                  : ""}
              </td>
              <td className="label ssssize borderBold ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.calculatedGuideHours > 0 &&
                inout_results_judohomonkaigo.calculatedGuideHours !== 0.5
                  ? inout_results_judohomonkaigo.calculatedGuideHours
                  : ""}
              </td>
              <td className="label daysize borderBold ssmall">
                {inout_results_judohomonkaigo
                  ? inout_results_judohomonkaigo.dispatchedPeople
                  : ""}
              </td>
              <td className="label daysize ssmall">
                {inout_results_judohomonkaigo &&
                  ((inout_results_judohomonkaigo.isIntensiveVisitCareSupport &&
                    "2") ||
                    (inout_results_judohomonkaigo.isAccompanySupport && "1") ||
                    "")}
              </td>
              <td className="label sssssize ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.isFirstAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label sssssize ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.isEmergencySupport
                  ? "1"
                  : ""}
              </td>
              <td className="label guidecaresize ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.isBehaviorDisorderAdditionFlg
                  ? "1"
                  : ""}
              </td>
              <td className="label guidecaresize borderBold ssmall">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.isGuideCareAdditionFlg
                  ? "1"
                  : ""}
              </td>
              <td className="label miniLabel borderBold ssmall" />
              <td className="ssmall memosize">
                {inout_results_judohomonkaigo &&
                inout_results_judohomonkaigo.memo
                  ? inout_results_judohomonkaigo.memo
                  : ""}
              </td>
            </tr>
          );
        })}
        {isEndRow && (
          <>
            <tr className="borderDoubleTop">
              <td colSpan={6} className="label">
                移動介護分
              </td>
              <td className="label">
                <div className={classes.empty}> </div>
              </td>
              <td className="label borderBold">
                {" "}
                {`${user.cntPlanGuideHours}`}{" "}
              </td>
              <td className="label">
                <div className={classes.empty3}> </div>
              </td>
              <td className="label">
                <div className={classes.empty3}> </div>
              </td>
              <td className="label">
                <div className={classes.empty}> </div>
              </td>
              <td className="label borderBold">
                {" "}
                {`${user.cntCalculatedGuideHours}`}{" "}
              </td>
              <td className="label borderBold">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                <div className={classes.empty6}> </div>
              </td>
              <td className="label">
                <div className={classes.empty7}> </div>
              </td>
              <td className="label">
                <div className={classes.empty7}> </div>
              </td>
              <td className="label borderBold">
                <div className={classes.empty}> </div>
              </td>
              <td className="label borderBold">
                <div className={classes.empty4}> </div>
              </td>
              <td className="label">
                <div className={classes.empty5}> </div>
              </td>
            </tr>

            <tr>
              <td colSpan={6} className="label">
                合計
              </td>
              <td className="label"> {`${user.cntPlanHours}`} </td>
              <td className="label borderBold">
                <div className={classes.empty}> </div>
              </td>
              <td className="label">
                <div className={classes.empty3}> </div>
              </td>
              <td className="label">
                <div className={classes.empty3}> </div>
              </td>
              <td className="label"> {`${user.cntCalculatedHours}`} </td>
              <td className="label borderBold">
                <div className={classes.empty}> </div>
              </td>
              <td className="label borderBold">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">
                <div className={classes.empty2}> </div>
              </td>
              <td className="label">{`${user.cntFirstAddition}回`}</td>
              <td className="label">{`${user.cntEmergencySupport}回`}</td>
              <td className="label">
                {`${user.cntBehaviorDisorderAddition}回`}
              </td>
              <td className="label borderBold">
                {`${user.cntGuideCareAddition}回`}
              </td>
              <td className="label borderBold">
                <div className={classes.empty4}> </div>
              </td>
              <td className="label">
                <div className={classes.empty5}> </div>
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export const JUDOHOMONKAIGOJisseki = withStyles(styles)(InoutRecordTableCore);
