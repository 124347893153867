import * as React from "react";

import {
  GetFacilityUsersResponse,
  GetFacilityUsersResponseElement
} from "@api/requests/facility/getFacilityUsers";
import { ErrorsState } from "@stores/domain/errors/types";
import SectionTitle from "@components/atoms/SectionTitle";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { PatternListType, patternListFormat } from "@constants/variables";
import { SearchUsersForm } from "./SearchUsersForm";
import { useCallback, useEffect, useState } from "react";
import { RecordUserRow } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserRow";
import { RecordUserEmptyMessage } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserEmptyMessage";
import { filterUsersByKana } from "@utils/domain/record/filterUsersByKana";
import { filterActiveUsers } from "@utils/domain/record/filterActiveUsers";
import uniq from "lodash-es/uniq";

const styles = (): StyleRules =>
  createStyles({
    paperContainer: {
      padding: "30px 32px 32px 32px",
      margin: 16
    },
    ul: {
      listStyle: "none",
      padding: 0,
      marginTop: 8,
      marginBottom: 0
    }
  });

type OwnProps = {
  users: GetFacilityUsersResponse["data"];
  errorState?: ErrorsState["records"]["data"];
  buttonList: React.ComponentProps<typeof RecordUserRow>["buttonList"];
  fetchUsers: () => Promise<void>;
  fetchErrors?: () => Promise<void>;
};

type Props = OwnProps & WithStyles<typeof styles>;

// フックの返却型定義
type UseRecordEachUserReturnType = {
  currentUsers: GetFacilityUsersResponse["data"];
  targetPattern: PatternListType[];
  setTargetPattern: React.Dispatch<React.SetStateAction<PatternListType[]>>;
  expiredVisibility: boolean;
  setExpiredVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearchUsers: (e: React.MouseEvent<HTMLElement>) => void;
  handleClear: () => void;
  isZeroMatch: boolean;
};

/**
 *
 * @param users ユーザー
 * @param optionalCustomInfo ユニット情報
 * @returns
 */
const useRecordEachUser = (
  users: GetFacilityUsersResponse["data"],
  fetchUsers: () => Promise<void>,
  fetchErrors?: () => Promise<void>
): UseRecordEachUserReturnType => {
  const [currentUsers, setCurrentUsers] = useState<
    GetFacilityUsersResponseElement[]
  >([]);
  const [targetPattern, setTargetPattern] = useState<PatternListType[]>(
    patternListFormat
  );
  const [expiredVisibility, setExpiredVisibility] = useState<boolean>(true);
  const [isZeroMatch, setIsZeroMatch] = useState<boolean>(false);
  const [searchConditionsIds, setSearchConditionsIds] = useState<number[]>([]);

  useEffect(() => {
    fetchUsers();
    if (fetchErrors) fetchErrors();
  }, []);

  /**
   * userがfetchされたらセットする
   */
  useEffect(() => {
    if (!users.length) return;
    let filteredUsers = users;
    // 退所者の非表示による絞り込み
    filteredUsers = filterActiveUsers(filteredUsers, true);
    setCurrentUsers(filteredUsers);
    setIsZeroMatch(filteredUsers.length === 0);
  }, [users]);

  /**
   * ふりがな絞り込み処理
   * @param usersResElm ユーザー
   * return 絞り込み後のユーザーのID配列
   */
  const extractClickablePatternIds = useCallback(
    (usersResElm: GetFacilityUsersResponseElement[]) => {
      const arr: number[] = [];
      [...usersResElm].forEach((item) => {
        const existingPattern = patternListFormat.find((pattern) => {
          const checkKanaReg = new RegExp(`^${pattern.targetKana}.*$`);
          return checkKanaReg.test(item.kanaName);
        });
        if (existingPattern) {
          arr.push(existingPattern.id);
        }
      });
      return uniq(arr);
    },
    [users, patternListFormat, uniq]
  );

  /**
   * ふりがなの選択状態を変更する
   */
  useEffect(() => {
    const patternList = patternListFormat.map((item) => {
      let { disabled, checked } = item;
      const clickablePatternID = extractClickablePatternIds(users);
      if (!clickablePatternID.includes(item.id)) {
        disabled = true;
      } else if (searchConditionsIds.includes(item.id)) {
        checked = true;
      }
      return {
        ...item,
        disabled,
        checked
      };
    });
    setTargetPattern(patternList);
  }, [
    extractClickablePatternIds,
    users,
    patternListFormat,
    searchConditionsIds
  ]);

  // 絞り込みボタン押下時の処理
  const handleSearchUsers = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
    const checkedPatterns = targetPattern.filter((item) => item.checked);
    const checkedPatternsIds = checkedPatterns.map((item) => item.id);
    setSearchConditionsIds(checkedPatternsIds);

    // フリガナでの絞り込み
    let filteredUsers = filterUsersByKana<GetFacilityUsersResponseElement>(
      users,
      checkedPatterns
    );

    // 退所者の非表示による絞り込み
    filteredUsers = filterActiveUsers(filteredUsers, expiredVisibility);

    setCurrentUsers(filteredUsers);
    setIsZeroMatch(filteredUsers.length === 0);
  };

  // 絞り込みリセット用の関数
  const handleClear = (): void => {
    const resetPattern = targetPattern.map((p) => ({
      ...p,
      checked: false
    }));
    setTargetPattern(resetPattern);
    setExpiredVisibility(true);
  };

  return {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    isZeroMatch
  };
};

/**
 * 絞り込みFormと、利用者情報一覧をまとめる
 */
const RecordEachUserCore = (props: Props): JSX.Element => {
  const {
    classes,
    users,
    errorState,
    buttonList,
    fetchUsers,
    fetchErrors
  } = props;

  const {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    isZeroMatch
  } = useRecordEachUser(users, fetchUsers, fetchErrors);

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <SectionTitle label="利用者ごとの記録" isTitleNoMargin />
      <SearchUsersForm
        targetPattern={targetPattern}
        setTargetPattern={setTargetPattern}
        expiredVisibility={expiredVisibility}
        setExpiredVisibility={setExpiredVisibility}
        handleSearchUsers={handleSearchUsers}
        handleClear={handleClear}
      />
      <ul className={classes.ul}>
        {/* 絞り込み結果なし */}
        {(isZeroMatch || !users.length) && <RecordUserEmptyMessage />}
        {!isZeroMatch &&
          currentUsers.map((user) => (
            <RecordUserRow
              key={`row-${user.uif_id}`}
              errorState={errorState}
              buttonList={buttonList}
              user={user}
            />
          ))}
      </ul>
    </Paper>
  );
};

export const RecordEachUser = withStyles(styles)(RecordEachUserCore);
