import { InitialValues } from "@interfaces/mgr/SHISETSUNYUSHO/report/initial";
import {
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType
} from "@stores/domain/mgr/SHISETSUNYUSHO/report/types";
import { INT_TRUE_FROM_API } from "@constants/variables";
import {
  StatusType,
  HospitalizationOvernightstay
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import {
  getDefaultHospitalizationDays,
  getDefaultHospitalizationDaysDialogFields
} from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import { dateToSelectDateValue } from "@utils/date";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

export type InitialDataValues = InitialValues;

// デフォルト値設定
export const initialValues = (
  state?: UsagePerformanceType,
  stateSHISETSUNYUSHO?: UsagePerformanceSHISETSUNYUSHOType,
  inputHospitalizationDaysPrev?: number | null
): InitialDataValues => {
  // 当日の入院・外泊日数
  let hospitalizationDays: number | null = null;
  if (stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.hospitalizationDays) {
    const hospitalNumber = Number(stateSHISETSUNYUSHO.hospitalizationDays);
    if (Number.isNaN(hospitalNumber)) {
      // NaNの場合、初期値としてプリセット値を設定する
      if (state && stateSHISETSUNYUSHO) {
        if (inputHospitalizationDaysPrev !== undefined) {
          hospitalizationDays = Number(
            getDefaultHospitalizationDaysDialogFields(
              String(state.statusType),
              inputHospitalizationDaysPrev
            )
          );
        } else {
          hospitalizationDays = Number(
            getDefaultHospitalizationDays(
              String(state.statusType),
              stateSHISETSUNYUSHO.hospitalizationDaysPrev
            )
          );
        }
      }
    } else {
      hospitalizationDays = hospitalNumber;
    }
  }
  // 前日の入院・外泊日数
  let hospitalizationDaysPrev: number | null = null;
  if (inputHospitalizationDaysPrev !== undefined) {
    hospitalizationDaysPrev = inputHospitalizationDaysPrev;
  } else if (
    stateSHISETSUNYUSHO &&
    stateSHISETSUNYUSHO.hospitalizationDaysPrev
  ) {
    const prevNumber = Number(stateSHISETSUNYUSHO.hospitalizationDaysPrev);
    hospitalizationDaysPrev = Number.isNaN(prevNumber) ? null : prevNumber;
  }
  return {
    initial: {
      name:
        state && state.nameSei && state.nameMei
          ? `${state.nameSei} ${state.nameMei}`
          : "",
      usersInFacilityId:
        state && state.usersInFacilityId ? state.usersInFacilityId : -1,
      targetDate: state && state.targetDate ? state.targetDate : "",
      statusType:
        state && state.statusType
          ? state.statusType.toString()
          : StatusType.NONE,
      hospitalizationOvernightStay:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.hospitalizationOvernightStay
          ? stateSHISETSUNYUSHO.hospitalizationOvernightStay.toString()
          : HospitalizationOvernightstay.NONE.toString(),
      hospitalizationDays,
      hospitalizationDaysPrev,
      regionalTransitionFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.regionalTransition
          ? stateSHISETSUNYUSHO.regionalTransition === INT_TRUE_FROM_API
          : false,
      collectionOfUtilityFeeFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.collectionOfUtilityFee
          ? stateSHISETSUNYUSHO.collectionOfUtilityFee === INT_TRUE_FROM_API
          : false,
      nutritionManagementFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.nutritionManagement
          ? stateSHISETSUNYUSHO.nutritionManagement === INT_TRUE_FROM_API
          : false,
      foodBreakfastFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodBreakfast
          ? stateSHISETSUNYUSHO.foodBreakfast === INT_TRUE_FROM_API
          : false,
      foodLunchFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodLunch
          ? stateSHISETSUNYUSHO.foodLunch === INT_TRUE_FROM_API
          : false,
      foodSupperFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodSupper
          ? stateSHISETSUNYUSHO.foodSupper === INT_TRUE_FROM_API
          : false,
      oralTransition:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.oralTransition
          ? stateSHISETSUNYUSHO.oralTransition === INT_TRUE_FROM_API
          : false,
      oralCareFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.oralCareFlg
          ? stateSHISETSUNYUSHO.oralCareFlg === INT_TRUE_FROM_API
          : false,
      medicalFoodsFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.medicalFoods
          ? stateSHISETSUNYUSHO.medicalFoods === INT_TRUE_FROM_API
          : false,
      intensiveSupportFlg:
        state && state.intensiveSupportFlg
          ? state.intensiveSupportFlg.toString()
          : "0",
      intensiveSupportStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(state && state.intensiveSupportStartDate)
        )
      ),
      hospitalVisitSupportFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.hospitalVisitSupportFlg
          ? stateSHISETSUNYUSHO.hospitalVisitSupportFlg === INT_TRUE_FROM_API
          : false,
      regionalTransitionPromotion2Flg:
        stateSHISETSUNYUSHO &&
        stateSHISETSUNYUSHO.regionalTransitionPromotion2Flg
          ? stateSHISETSUNYUSHO.regionalTransitionPromotion2Flg ===
            INT_TRUE_FROM_API
          : false,
      infectiousDiseasesFacilityFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.infectiousDiseasesFacilityFlg
          ? stateSHISETSUNYUSHO.infectiousDiseasesFacilityFlg ===
            INT_TRUE_FROM_API
          : false,
      severeDisabilitySupportType:
        state && state.severeDisabilitySupportType
          ? state.severeDisabilitySupportType.toString()
          : "0",
      remarks: state && state.remarks ? state.remarks : ""
    }
  };
};
