import { convertWareki } from "@utils/date/convertWareki";

/**
 * 元号が変わった年なら両方記載するように返す
 * @param yyyy 年
 */
export const getWareki = (yyyy: string | number): string => {
  const { year, era } = convertWareki(yyyy, 1);
  if (era === "大正" && year === 15) return "大正15/昭和1";
  if (era === "昭和" && year === 64) return "昭和64/平成1";
  if (era === "平成" && year === 31) return "平成31/令和1";
  return `${era}${year}`;
};
