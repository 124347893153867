import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostCustomSupportParams = {
  setting_type: number;
  support_record_id: number | null;
  status_type?: number; // GHの場合のみ出現
  custom_record: {
    custom_record_input_id: number | null;
    custom_record_item_id: number;
    input_data?: string;
    choiced_item_id?: number;
    choiced_staff_id?: number;
    choiced_staff_name_snapshot?: string;
    checked?: number;
  }[];
};

/**
 * 支援記録を更新をする
 * @param uifId 事業所所属ユーザーのID
 * @param yyyymmdd 日付
 * @param params 更新データ
 */
const postCustomSupport = async (
  uifId: string,
  yyyymmdd: string,
  params: PostCustomSupportParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_support/${uifId}/${yyyymmdd}`;
  return request.post(url, params);
};

export default postCustomSupport;
