import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { ReportType } from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/report/types";
import { ReportInterface } from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/report/interfaces/reportInterface";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import validation, {
  submitValidation
} from "@initialize/v201910/mgr/JIRITSUKUNRENSEIKATSU/report/validation";
import { InitialDataValues } from "@initialize/v201910/mgr/JIRITSUKUNRENSEIKATSU/report/initialValues";
import { InOutReportDialogSubmit } from "@components/v201910/organisms/mgr/JIRITSUKUNRENSEIKATSU/report/dialog/InOutReportDialogSubmit";
import InOutReportDialogFields from "@components/v201910/organisms/mgr/JIRITSUKUNRENSEIKATSU/report/dialog/InOutReportDialogFields";
import { FacilityState } from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/v201910/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/types";
import { Checkbox } from "@constants/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    action: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      // MuiDialog-paperの上書き
      margin: 0,
      paddingRight: 4,
      paddingBottom: 4
    },
    modalAction: {
      display: "flex"
    },
    button: {
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4,
      textTransform: "none",
      width: 110
    },
    submit: {
      marginLeft: spacing.unit,
      width: 110,
      marginRight: spacing.unit
    },
    modalHeader: {
      display: "flex"
    },
    modal: {
      maxHeight: "80%",
      width: 600,
      // MuiDialog-paperScrollPaperの上書き
      maxWidth: 600,
      margin: 0
    },
    modalArea: {
      height: "99%",
      width: "100%"
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 32,
      paddingBottom: 16,
      paddingLeft: 32
    },
    modalHeaderArea: {
      padding: "16px 32px 18px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: "20px",
      color: "#37474f",
      backgroundColor: "#f5f5f5"
    },
    headerName: {
      marginRight: 8,
      marginLeft: 32
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    UsageRecordDate: {
      textAlign: "right",
      flex: "auto",
      fontSize: 16
    }
  });

type DispatchProps = {
  putJIRITSUKUNRENSEIKATSUReport: (
    reportList: ReportInterface[],
    formValue: InitialDataValues,
    facilityState: FacilityState,
    usersInFacilityState: UsersInFacilityState,
    type: ReportType
  ) => Promise<void>;
};

type StateProps = {
  facilityState: FacilityState;
  userInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  reportList: ReportInterface[];
  data: InitialDataValues;
  selectedDate: Date;
  type: ReportType;
  onCancel: () => void;
  onSubmit: () => void;
  initialFlg: boolean;
};

type State = {
  isSubmitDisabled: boolean;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class InOutReportDialogCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitDisabled: true
    };
  }

  private validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values);
    this.setState({
      isSubmitDisabled: submitValidation(
        values.initial.status,
        validationResult
      )
    });
    const error = toEffectiveObject(validationResult);
    return error;
  };

  private onCancel = (): void => {
    this.setState({ isSubmitDisabled: true });
    this.props.onCancel();
  };

  private onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    this.onCancel();
    actions.setSubmitting(true);
    await this.props
      .putJIRITSUKUNRENSEIKATSUReport(
        this.props.reportList,
        values,
        this.props.facilityState,
        this.props.userInFacilityState,
        this.props.type
      )
      .then(() => {
        this.props.onSubmit();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={this.props.open}
        classes={{ paper: classes.modal }}
      >
        <Formik
          initialValues={this.props.data}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.formArea}>
              <DialogTitle
                id={this.props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={this.props.classes.modalHeader}>
                  <div>利用実績</div>
                  <div className={classes.UsageRecordDate}>
                    <span>
                      {this.props.selectedDate.getFullYear()}
                      <span>年</span>
                      {this.props.selectedDate.getMonth() + 1}
                      <span>月</span>
                      {this.props.selectedDate.getDate()}
                      <span>日</span>
                      <span>
                        {`（${
                          oneLetterWeekdaysJapanese[
                            this.props.selectedDate.getDay()
                          ]
                        }）`}
                      </span>
                    </span>
                  </div>
                </div>
                <div>{this.props.data.initial.name}</div>
              </DialogTitle>
              <DialogContent className={classes.modalContents}>
                <InOutReportDialogFields
                  formikPropsValues={formikProps}
                  isPickupAvailable={
                    this.props.facilityState.transferServiceFlag
                  }
                  isFoodAvailable={
                    this.props.facilityState.mealSaservedServiceFlag
                  }
                  isShortStayAvailable={
                    this.props.facilityState.shortStayType !== "0"
                  }
                  isSupportMentallyAvailable={
                    this.props.facilityState
                      .supportForMentallyIllDisChargeSystemType !== "0"
                  }
                  isSocialLifeAvailable={
                    this.props.userInFacilityState.user
                      .user_in_facility_jiritsukunren_seikatsu
                      ? `${this.props.userInFacilityState.user.user_in_facility_jiritsukunren_seikatsu.social_life_support_flg}` ===
                        Checkbox.ON
                      : false
                  }
                  isVisualImpairment={
                    this.props.userInFacilityState.user
                      .user_in_facility_jiritsukunren_seikatsu
                      ? `${this.props.userInFacilityState.user.user_in_facility_jiritsukunren_seikatsu.blindness_flg}` ===
                        Checkbox.ON
                      : false
                  }
                  setFormikFieldValue={formikProps.setFieldValue}
                />
              </DialogContent>
              <DialogActions className={classes.action}>
                <InOutReportDialogSubmit
                  formikPropsValues={formikProps}
                  onCancel={this.onCancel}
                  disabled={this.state.isSubmitDisabled}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.v201910.JIRITSUKUNRENSEIKATSU.facility,
    userInFacilityState: state.v201910.JIRITSUKUNRENSEIKATSU.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v201910 } = dispatches;
  const JIRITSUKUNRENDispatcher = v201910.JIRITSUKUNRENSEIKATSU.reportDispatcher(
    dispatch
  );
  return {
    putJIRITSUKUNRENSEIKATSUReport: (
      reportList: ReportInterface[],
      formValue: InitialDataValues,
      facilityState: FacilityState,
      usersInFacilityState: UsersInFacilityState,
      type: ReportType
    ): Promise<void> =>
      JIRITSUKUNRENDispatcher.putJIRITSUKUNRENSEIKATSUReport(
        reportList,
        formValue,
        facilityState,
        usersInFacilityState,
        type
      )
  };
};

export const InOutReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDialogCore));
