import { SupportReportValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { ErrorMessage, Rule } from "@validator";

type SupportReportSituationsErrorMessage = {
  content: ErrorMessage;
  result: ErrorMessage;
};

type SupportReportValuesErrors = ValidationErrors<SupportReportValues>;

const validation = (values: SupportReportValues): SupportReportValuesErrors => {
  const lengthRule = (length: number): Rule => ({
    type: "checkCharacterLength",
    length
  });
  const validateSituations = (
    situations: SupportReportValues["support_report"]["situations"]
  ): SupportReportSituationsErrorMessage[] => {
    return situations.map((sit) => {
      return {
        content: validator(sit.content, lengthRule(2500)),
        result: validator(sit.result, lengthRule(2500))
      };
    });
  };
  return {
    support_report: {
      company_name: validator(
        values.support_report.company_name,
        lengthRule(255)
      ),
      responsible_person: validator(
        values.support_report.responsible_person,
        lengthRule(255)
      ),
      goal_1: validator(values.support_report.goal_1, lengthRule(1500)),
      goal_2: validator(values.support_report.goal_2, lengthRule(1500)),
      goal_3: validator(values.support_report.goal_3, lengthRule(1500)),
      future_support_content: validator(
        values.support_report.future_support_content,
        lengthRule(1500)
      ),
      person_behavior: validator(
        values.support_report.person_behavior,
        lengthRule(1500)
      ),
      facility_behavior: validator(
        values.support_report.facility_behavior,
        lengthRule(1500)
      ),
      related_organizations_behavior: validator(
        values.support_report.related_organizations_behavior,
        lengthRule(1500)
      ),
      shared_content: validator(
        values.support_report.shared_content,
        lengthRule(1500)
      ),
      situations: validateSituations(values.support_report.situations)
    }
  };
};

export default validation;
