import React from "react";
import * as H from "history";
import { Formik, Form, FormikActions } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import isEqual from "lodash-es/isEqual";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { AssessmentSheetForm } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentSheetForm";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";

import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import * as assessmentActions from "@stores/domain/assessment/actions";
import { AssessmentState } from "@stores/domain/assessment/types";

import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE,
  CUSTOM_RECORD_TARGET_TYPE
} from "@constants/variables";
import * as URL from "@constants/url";

import {
  Assessment,
  initialValues
} from "@initialize/record/assessment/initialValues";
import {
  AssessmentMaxLengthConfig,
  validation
} from "@initialize/record/assessment/validation";

import { FieldItem } from "@interfaces/ui/form";

import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { findDefaultItem } from "@utils/domain/mgr/Cseg/assessment/variableFromFacility";
import { getUrlParams } from "@utils/url";
import {
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  PARAMETER_TYPE_KEIKAKUSODAN,
  PARAMETER_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    content: {
      background: "#fff",
      borderRadius: "4px"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    form: {
      background: "#fff",
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    author: {
      display: "flex",
      padding: "16px 32px 32px 32px"
    },
    authorName: {
      width: 240
    },
    authorRole: {
      width: 240,
      paddingLeft: "16px"
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    },
    deleteButton: {
      marginTop: 24,
      height: 0
    }
  });

type OwnProps = {
  userName: string;
  uifId: string;
  assessmentId: string;
  copyId: string;
  history: H.History;
  facilityType: FacilityType;
  query: string;
};

type StateProps = {
  needsStopHistory: boolean;
  customRecords: CustomRecordsWithCategoryState;
  assessment: AssessmentState["assessment"];
  staff: StaffState;
  facilitySubType: number | null;
};

type DispatchProps = {
  fetchStaffs: () => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  postAssessment: (
    uifId: string,
    initialAssessment: Assessment,
    assessment: Assessment,
    history: H.History,
    typeConsultation?: number
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  unsetAssessment: () => void;
  deleteAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  stopHistory: (flag: boolean) => Promise<void>;
  fetchKEIKAKUSODANFacility: () => void;
};

type MergeProps = {
  authorName: string;
  authorizerRole: string;
  staffOptions: FieldItem[];
  staffWithSnap: StaffState;
} & StateProps &
  DispatchProps;

type Props = OwnProps & MergeProps & WithStyles<typeof styles>;

const findItemIdFromDefaultItem = (
  defaultItem: number,
  customRecords: CustomRecordsWithCategoryState
): number | null => {
  let itemId: number | null = null;
  customRecords.forEach((category) => {
    if (itemId) return;
    const item = category.custom_record_items.find(
      (recordItem) => recordItem.default_item === defaultItem
    );
    if (item) {
      itemId = item.id;
    }
  });
  return itemId;
};

/**
 * 各種必要な情報から今回使用するべきtypeConsultationを返す
 * 不正 or 種別などで不要な場合はundefined
 */
const calculateQueryTypeConsultation = (
  facilityType: FacilityType,
  facilitySubType: number | null,
  query: string,
  assessment: AssessmentState["assessment"],
  isNew: boolean,
  isCopy: boolean
): number | undefined => {
  if (facilityType !== FacilityType.KEIKAKUSODAN) {
    return undefined;
  }

  // 編集の場合は対象帳票のtype consultationを返却
  if (!isNew && !isCopy) {
    return assessment.assessment.type_consultation;
  }

  // 新規(コピー)の場合で単体種別の契約をしている場合は契約している種別
  if (
    facilitySubType === FACILITY_TYPE_KEIKAKUSODAN ||
    facilitySubType === FACILITY_TYPE_SHOGAIJISODAN
  ) {
    return facilitySubType;
  }

  const { type }: { type?: undefined } = getUrlParams(query);
  if (type === PARAMETER_TYPE_KEIKAKUSODAN) {
    return FACILITY_TARGET_TYPE_KEIKAKUSODAN;
  }
  if (type === PARAMETER_TYPE_SHOGAIJISODAN) {
    return FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  }
  return undefined;
};

/**
 * アセスメント一覧
 *
 */
const AssessmentSheetCore = (props: Props): JSX.Element | null => {
  const {
    needsStopHistory,
    userName,
    uifId,
    assessmentId,
    copyId,
    history,
    customRecords,
    assessment,
    facilitySubType,
    staffWithSnap,
    authorName,
    staffOptions,
    fetchStaffs,
    fetchCustomRecords,
    fetchAssessment,
    postAssessment,
    unsetAssessment,
    deleteAssessment,
    fetchKEIKAKUSODANFacility,
    classes,
    facilityType,
    query
  } = props;
  const [isLoading, setLoading] = React.useState(true); // 初回fetch終わったらfalse
  const [isNew] = React.useState(assessmentId === "new"); // 新規作成
  const [isView] = React.useState(!isNew && !copyId); // 表示
  const [isCopy] = React.useState(!!copyId); // コピー中
  const [isEditing, setEditing] = React.useState(isNew || !isView || isCopy); // 編集ができる状態かどうか
  const [formValues, setFormValues] = React.useState(
    initialValues(assessment, customRecords, staffOptions, isCopy)
  );
  const [
    isOpenDeleteAssessmentModal,
    setOpenDeleteAssessmentModal
  ] = React.useState(false);
  const [typeConsultation, setTypeConsultation] = React.useState<number | null>(
    null
  );

  const handleTypeConsultation = (
    typeConsultationResult: number | undefined
  ): void => {
    if (facilityType !== FacilityType.KEIKAKUSODAN) {
      return;
    }

    // 新規・コピーの場合にのみクエリパラメーターでtypeを取得するが、不正な値の場合は一覧画面へ遷移
    if ((isNew || isCopy) && typeConsultationResult === undefined) {
      history.push(`/record/support_plan/${uifId}/assessment`);
    }

    if (typeConsultationResult) {
      setTypeConsultation(typeConsultationResult);
    }
  };

  //  初回fetch
  React.useEffect(() => {
    unsetAssessment();
    const typeConsultationResult = calculateQueryTypeConsultation(
      facilityType,
      facilitySubType,
      query,
      assessment,
      isNew,
      isCopy
    );
    setFormValues(
      initialValues(assessment, customRecords, staffOptions, isCopy, {
        facilityType,
        typeConsultation: typeConsultationResult
      })
    );
    const fetchFirstData = async (): Promise<void> => {
      if (isView || isCopy) {
        await fetchAssessment(uifId, assessmentId || copyId);
      }
      await fetchCustomRecords();
      await fetchStaffs();
    };
    fetchFirstData().then(() => {
      setLoading(false);
    });
    if (facilityType === FacilityType.KEIKAKUSODAN) {
      fetchKEIKAKUSODANFacility();
    }
  }, []);

  // fetch終了後
  React.useEffect(() => {
    const typeConsultationResult = calculateQueryTypeConsultation(
      facilityType,
      facilitySubType,
      query,
      assessment,
      isNew,
      isCopy
    );
    setFormValues(
      initialValues(assessment, customRecords, staffOptions, isCopy, {
        facilityType,
        typeConsultation: typeConsultationResult
      })
    );
    if (facilityType === FacilityType.KEIKAKUSODAN) {
      handleTypeConsultation(typeConsultationResult);
    }
  }, [assessment, customRecords]);

  const onSubmit = async (
    values: Assessment,
    actions: FormikActions<Assessment>
  ): Promise<void> => {
    actions.setSubmitting(true);
    const initial = isCopy
      ? initialValues(undefined, undefined, undefined, undefined, {
          facilityType,
          typeConsultation: typeConsultation || undefined
        })
      : initialValues(assessment, customRecords, staffOptions, undefined, {
          facilityType,
          typeConsultation: typeConsultation || undefined
        });
    await postAssessment(
      uifId,
      initial,
      values,
      history,
      // 編集時はtypeConsultation不要
      typeConsultation && !isView ? typeConsultation : undefined
    );
    if (isView) {
      await setFormValues(values);
      await fetchAssessment(uifId, assessmentId);
      setEditing(false);
    }
    actions.setSubmitting(false);
  };

  const getCategoryTypeList = (targetFacilityType: FacilityType): number[] => {
    switch (targetFacilityType) {
      case FacilityType.KEIKAKUSODAN:
        return [
          KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary,
          KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation,
          KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation,
          KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request,
          KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others
        ];
      case FacilityType.CHIIKIIKO:
        return [
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information,
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.summary,
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation,
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation,
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou,
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.others
        ];
      case FacilityType.CHIIKITEICHAKU:
        return [
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information,
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.summary,
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation,
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation,
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.request,
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.others
        ];
      default:
        return [];
    }
  };

  const assessmentCategoryTypeList = getCategoryTypeList(facilityType);

  const onClickPrint = (): void => {
    const url = `/record/support_plan/${uifId}/assessment/${assessmentId}/print`;
    history.push(url);
  };

  const onClickEdit = (): void => {
    setEditing(true);
  };

  const onClickDeleteAssessment = (): void => {
    setOpenDeleteAssessmentModal(true);
  };

  const onSubmitDeleteAssessment = async (): Promise<void> => {
    await deleteAssessment(uifId, assessmentId);
    props.stopHistory(false);
    history.push(`/record/support_plan/${uifId}/assessment`);
  };

  const onClickCancelDeleteAssessment = (): void => {
    setOpenDeleteAssessmentModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // formik handler
  const confirmDiscardFormChanges = (nextValues: Assessment): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  // バリデーション
  const validate = (values: Assessment): void | object => {
    const max200itemIds: number[] = [];
    const max2500itemIds: number[] = [];
    if (
      facilityType === FacilityType.CHIIKIIKO ||
      facilityType === FacilityType.CHIIKITEICHAKU
    ) {
      const keikakusodanOffice = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "keikakusodanOffice"),
        customRecords
      );
      if (keikakusodanOffice) {
        max200itemIds.push(keikakusodanOffice);
      }
      const keikakusodanSupporter = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "keikakusodanSupporter"),
        customRecords
      );
      if (keikakusodanSupporter) {
        max200itemIds.push(keikakusodanSupporter);
      }
      const opinionsOnChiikiikouCarer = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "opinionsOnChiikiikouCarer"),
        customRecords
      );
      if (opinionsOnChiikiikouCarer) {
        max2500itemIds.push(opinionsOnChiikiikouCarer);
      }
      const opinionsOnChiikiikouFamily = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "opinionsOnChiikiikouFamily"),
        customRecords
      );
      if (opinionsOnChiikiikouFamily) {
        max2500itemIds.push(opinionsOnChiikiikouFamily);
      }
      const opinionsOnChiikiikouOrganization = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "opinionsOnChiikiikouOrganization"),
        customRecords
      );
      if (opinionsOnChiikiikouOrganization) {
        max2500itemIds.push(opinionsOnChiikiikouOrganization);
      }
      const opinionsOnChiikiikouSelf = findItemIdFromDefaultItem(
        findDefaultItem(facilityType, "opinionsOnChiikiikouSelf"),
        customRecords
      );
      if (opinionsOnChiikiikouSelf) {
        max2500itemIds.push(opinionsOnChiikiikouSelf);
      }
    }

    let maxLengthConfig: AssessmentMaxLengthConfig | undefined;
    if (
      facilityType === FacilityType.KEIKAKUSODAN ||
      facilityType === FacilityType.CHIIKITEICHAKU
    ) {
      maxLengthConfig = {
        lifeHistoriesMaxLength: 600,
        disabilityHistoriesMaxLength: 600
      };
    }

    const validationResult = validation(values, {
      max200itemIds,
      max2500itemIds,
      maxLengthConfig
    });
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const onClickCancel = async (): Promise<void> => {
              await props.stopHistory(false).then(() => {
                if (isNew || isCopy) {
                  history.push(
                    `${URL.RECORD_SUPPORT_PLAN}/${uifId}/assessment`
                  );
                }
                if (isView && isEditing) {
                  formikProps.resetForm();
                  setEditing(false);
                }
              });
            };
            return (
              <Form>
                <PlanListHeader
                  pageName="アセスメントシート"
                  userName={userName}
                  uifId={uifId}
                  recordType={PLAN_LIST_TAB_PATH.ASSESSMENT}
                  isEditing={isEditing}
                  history={props.history}
                  button={
                    <div className={classes.titleBtn}>
                      {isEditing ? (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="outline"
                              onClick={onClickCancel}
                            >
                              キャンセル
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <FormikSubmitButton
                              key="submit-button"
                              buttonName="保存する"
                              formikProps={formikProps}
                              errorAction={submitError}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickPrint}>
                              印刷
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickEdit}>
                              編集
                            </KnowbeButton>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  facilityType={facilityType}
                />
                <div className={classes.form}>
                  {!isNew && !isCopy && (
                    <div className={classes.conCreateDate}>
                      <CreateAndUpdateDate
                        createdAt={formValues.created_at}
                        updatedAt={formValues.updated_at}
                      />
                    </div>
                  )}
                  <RecordSelectDate
                    name="target_date"
                    label="作成日"
                    required
                    value={formValues.target_date}
                    isEditable={isEditing}
                    addYearTo={1}
                    overrideYearFrom={1989}
                  />
                  {assessmentCategoryTypeList.map((categoryType) => (
                    <AssessmentSheetForm
                      key={categoryType}
                      categoryType={categoryType}
                      formValues={isEditing ? formikProps.values : formValues}
                      formikProps={formikProps}
                      isEditing={isEditing}
                      customRecords={customRecords}
                      facilityType={facilityType}
                      typeConsultation={typeConsultation}
                      facilitySubType={facilitySubType}
                    />
                  ))}
                </div>
                <div className={classes.author}>
                  <div className={classes.authorName}>
                    <RecordSelect
                      name="author"
                      label="作成者"
                      defaultValue="未設定"
                      placeholder="選択してください"
                      options={staffOptions}
                      emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                      value={authorName}
                      isEditable={isEditing}
                      isSelectablePlaceholder
                    />
                  </div>
                  <div className={classes.authorRole}>
                    <ReadonlyTextField
                      value={getStaffRole(
                        staffWithSnap,
                        isEditing
                          ? formikProps.values.author
                          : formValues.author
                      )}
                      label="役職"
                      defaultValue="-"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {isView && isEditing && (
          <DeleteButton
            text="アセスメントシートを削除する"
            onClick={onClickDeleteAssessment}
          />
        )}
      </div>
      <MessageDialog
        isOpen={isOpenDeleteAssessmentModal}
        title="アセスメントシートを削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDeleteAssessment}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteSubmitButton}
            onClick={onSubmitDeleteAssessment}
          >
            削除する
          </Button>
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  customRecords: state.customRecordsWithCategory.assesment,
  assessment: state.assessment.assessment,
  staff: state.staff,
  facilitySubType: state.KEIKAKUSODAN.facility.facilityType
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    staffDispatcher,
    assessmentDispatcher,
    customRecordsWithCategory,
    uiDispatch,
    KEIKAKUSODAN
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const assessmentDispatches = assessmentDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.assessment
      );
    },
    fetchAssessment: (uifId: string, assessmentId: string): Promise<void> => {
      return assessmentDispatches.fetchAssessment(uifId, assessmentId);
    },
    postAssessment: (
      uifId: string,
      initialAssessment: Assessment,
      assessment: Assessment,
      history: H.History,
      typeConsultation?: number
    ): Promise<void> => {
      return assessmentDispatches.postAssessment(
        uifId,
        initialAssessment,
        assessment,
        history,
        typeConsultation
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    unsetAssessment: (): void => {
      dispatch(assessmentActions.unsetAssessment());
    },
    deleteAssessment: (uifId: string, assessmentId: string): Promise<void> => {
      return assessmentDispatches.deleteAssessment(uifId, assessmentId);
    },
    stopHistory: uiDispatches.stopHistory,
    fetchKEIKAKUSODANFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, assessment } = stateProps;

  const author = assessment.author !== null ? assessment.author : 0;

  // 作成者名
  const authorName = getSnapOrRealName(author, "-");
  // 承認者役職
  const authorizerRole = getSnapOrRealRole(author, "-");

  // 記録者フィールドに渡すoptions
  let staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  // サービス提供者選択肢作成（スナップショット）
  if (assessment.author !== null) {
    staffOptions = createSnapshotOptions(staffOptions, assessment.author);
  }
  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptions.length > staff.staffItems.length) {
    const snapOption = staffOptions.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorName,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  return {
    authorName,
    authorizerRole,
    staffOptions,
    staffWithSnap,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const AssessmentSheet = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssessmentSheetCore)
);
