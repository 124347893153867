import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 指定のサービス提供記録を削除
 */
export const deleteServiceDelivery = async (
  serviceDeliveryRecordsId: number
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/service_delivery/details/${serviceDeliveryRecordsId}`;
  return request.delete(url);
};
