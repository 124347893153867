/**
 * 数値の桁数チェック
 * 小数を含む数値の桁数を制限するため
 * @param integerDigitsLimit 整数値部分の桁数制限
 * @param decimalDigitsLimit 小数部分の桁数制限
 * @param errorMessage エラーメッセージ
 */
export const numberOfDigits = (
  value: string,
  /** 整数部桁数上限 */
  integerDigitsLimit: number,
  decimalDigitsLimit: number,
  errorMessage?: string
): string | undefined => {
  const errMsg =
    errorMessage ||
    `整数は${integerDigitsLimit}桁以内、小数点以下は${decimalDigitsLimit}桁以内で入力してください`;
  const num = Number(value);
  if (Number.isNaN(num)) {
    return errMsg;
  }
  const [integer, decimal] = value.split(".");
  // 整数部分が指定桁数以上の場合
  if (integer.length > integerDigitsLimit) {
    return errMsg;
  }
  // 少数点以下の桁数制限(小数点以下は無い場合チェックしない)
  if (decimal && decimal.length > decimalDigitsLimit) {
    return errMsg;
  }
  return undefined;
};
