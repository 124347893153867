import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Formik, FormikProps } from "formik";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";

import { StaffData } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { SupportCarePlanSchedules } from "@interfaces/record/carePlan/carePlan";
import { FacilityType } from "@constants/variables";
import {
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS,
  SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { BasicInfo } from "@components/organisms/mgr/common/record/carePlan/Dialog/Edit/BasicInfo";
import { Details } from "@components/organisms/mgr/common/record/carePlan/Dialog/Edit/Details";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

const styles = (): StyleRules =>
  createStyles({
    status: {
      width: "254px"
    },
    dialog: {
      "&>div:nth-child(2)>div": {
        maxWidth: "1094px"
      }
    },
    dialogTitle: {
      padding: "16px 32px",
      background: "#f5f5f5",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    dialogCon: {
      padding: "32px 32px 50px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    MT48: {
      marginTop: "48px"
    },
    MT32: {
      marginTop: "32px"
    },
    header: {
      display: "flex",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      color: "#37474f",
      background: "#eceff1",
      padding: "14px 16px",
      marginTop: "8px"
    },
    week: {
      width: "236px"
    },
    start: {
      width: "calc(72px + 56px + 8px)",
      marginLeft: "16px"
    },
    end: {
      width: "calc(72px + 56px + 8px)",
      marginLeft: "16px"
    },
    times: {
      width: "106px",
      marginLeft: "16px"
    },
    number: {
      width: "56px",
      marginLeft: "16px"
    },
    charge: {
      width: "200px",
      marginLeft: "16px"
    },
    delete: {
      width: "25px",
      marginLeft: "24px"
    },
    time: {
      width: "72px"
    },
    service: {
      width: "144px",
      marginLeft: "16px"
    },
    point: {
      width: "406px",
      marginLeft: "16px"
    },
    toDo: {
      width: "280px",
      marginLeft: "16px"
    }
  });

type OwnProps = {
  validate: (values: SupportCarePlanSchedules) => void | object;
  isEditing: boolean;
  staffItems: StaffData[];
  submitError: () => void;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
  facilityType: FacilityType;
  needsStopHistory: boolean;
  editInitialValues: SupportCarePlanSchedules;
  serviceNumberEdit: number;
  isOpenModelOnEditing: boolean;
  onClickSaveButtonOnEditing: (data: SupportCarePlanSchedules[]) => void;
  onClickCancelButtonOnEditing: () => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanSchedulesDialogCore = (props: Props): JSX.Element => {
  const {
    classes,
    validate,
    isEditing,
    staffItems,
    submitError,
    facilityType,
    editInitialValues,
    serviceNumberEdit,
    isOpenModelOnEditing,
    onClickSaveButtonOnEditing,
    onClickCancelButtonOnEditing
  } = props;

  // サービス内容が通院等乗降介助・選択してくださいの時切り替え用
  const [isRides, setIsRides] = React.useState<boolean>(false);
  const [isDisabledSaveButton, setIsDisabledSaveButton] = React.useState<
    boolean
  >(true);
  const setRides = (status: SupportCarePlanSchedules["status"]): void => {
    if (
      status ===
      SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.GETTING_ON_AND_OFF_ASSISTANCE
        .value
    ) {
      setIsRides(true);
    } else {
      setIsRides(false);
    }
  };
  const setDisabledSaveButton = (
    status: SupportCarePlanSchedules["status"]
  ): void => {
    if (status === SUPPORT_CARE_PLAN_SCHEDULES_STATUS_ITEM.SELECT.value) {
      setIsDisabledSaveButton(true);
    } else {
      setIsDisabledSaveButton(false);
    }
  };
  const onStatusChange = (status: SupportCarePlanSchedules["status"]): void => {
    if (facilityType === FacilityType.KYOTAKUKAIGO) {
      setRides(status);
      setDisabledSaveButton(status);
    } else if (
      facilityType === FacilityType.JUDOHOMONKAIGO ||
      facilityType === FacilityType.DOKOENGO ||
      facilityType === FacilityType.KODOENGO
    ) {
      setIsRides(false);
      setIsDisabledSaveButton(false);
    } else {
      setIsDisabledSaveButton(false);
      setIsRides(false);
    }
  };

  // 編集
  const onSubmit = async (values: SupportCarePlanSchedules): Promise<void> => {
    onClickSaveButtonOnEditing([values]);
  };

  // 種別による分岐・サービス内容
  const schedulesStatus = (
    formikProps: FormikProps<SupportCarePlanSchedules>
  ): JSX.Element => {
    return (
      <RecordSelect
        name="status"
        value={getLabelFromOptions(
          formikProps.values.status,
          SUPPORT_CARE_PLAN_SCHEDULES_STATUS
        )}
        defaultValue="選択してください"
        label="サービス内容"
        placeholder="選択してください"
        options={SUPPORT_CARE_PLAN_SCHEDULES_STATUS}
        isEditable={isEditing}
      />
    );
  };
  const schedulesStatusText = (
    formikProps: FormikProps<SupportCarePlanSchedules>
  ): JSX.Element => {
    return (
      <RecordTextField
        name="status_text"
        label="サービス内容"
        labelType="default"
        value={formikProps.values.status_text}
        defaultValue=""
        placeholder=""
        maxLength={20}
        isEditable={isEditing}
        nonMultiline
      />
    );
  };
  const getSchedulesStatus = (
    formikProps: FormikProps<SupportCarePlanSchedules>
  ): JSX.Element => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return schedulesStatus(formikProps);
      case FacilityType.JUDOHOMONKAIGO:
      case FacilityType.DOKOENGO:
      case FacilityType.KODOENGO:
        return schedulesStatusText(formikProps);
      default:
        return schedulesStatus(formikProps);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={isOpenModelOnEditing}
    >
      <Formik
        initialValues={editInitialValues}
        onSubmit={onSubmit}
        validate={validate}
      >
        {(formikProps): JSX.Element => {
          onStatusChange(formikProps.values.status);
          return (
            <>
              <DialogTitle className={classes.dialogTitle}>
                サービス{circleNumbersList[serviceNumberEdit]}
                {"　"}
                サービス内容の詳細
              </DialogTitle>
              <DialogContent className={classes.dialogCon}>
                <div className={classes.status}>
                  {getSchedulesStatus(formikProps)}
                </div>
                {/* 基本情報 */}
                <Typography variant="subtitle2" className={classes.MT32}>
                  基本情報
                </Typography>
                <div className={classes.header}>
                  <span className={classes.week}>サービス提供日</span>
                  <span className={classes.start}>開始時間</span>
                  <span className={classes.end}>終了時間</span>
                  <span className={classes.times}>
                    {isRides ? "乗降回数" : "時間数"}
                  </span>
                  <span className={classes.number}>提供人数</span>
                  <span className={classes.charge}>提供担当者（資格）</span>
                  <span className={classes.delete}>
                    {isEditing ? "削除" : ""}
                  </span>
                </div>
                <BasicInfo
                  isRides={isRides}
                  isEditing={isEditing}
                  staffItems={staffItems}
                  formikProps={formikProps}
                  facilityType={facilityType}
                  isDisabledSaveButton={isDisabledSaveButton}
                />
                {/* 詳細内容 */}
                <Typography variant="subtitle2" className={classes.MT48}>
                  詳細内容
                </Typography>
                <div className={classes.header}>
                  <span className={classes.time}>所要時間</span>
                  <span className={classes.service}>サービスの詳細内容</span>
                  <span className={classes.point}>
                    手順・留意事項・観察ポイント
                  </span>
                  <span className={classes.toDo}>
                    本人・家族にやっていただくこと
                  </span>
                  <span className={classes.delete}>
                    {isEditing ? "削除" : ""}
                  </span>
                </div>
                <Details
                  isEditing={isEditing}
                  formikProps={formikProps.values}
                  facilityType={facilityType}
                  isDisabledSaveButton={isDisabledSaveButton}
                />
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <KnowbeButton
                  kind="outline"
                  onClick={onClickCancelButtonOnEditing}
                >
                  {isEditing ? "キャンセル" : "閉じる"}
                </KnowbeButton>
                {isEditing && (
                  <FormikSubmitButton
                    key="submit-button"
                    buttonName="保存する"
                    errorAction={submitError}
                    formikProps={formikProps}
                    disabled={isDisabledSaveButton}
                  />
                )}
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export const CarePlanSchedulesDialog = withStyles(styles)(
  CarePlanSchedulesDialogCore
);
