import { deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "./deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";
import { getSupportPlanUifIdSupportPlanSheets } from "./getSupportPlanUifIdSupportPlanSheets";
import { getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "./getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";
import { postSupportPlanUifIdSupportPlanSheets } from "./postSupportPlanUifIdSupportPlanSheets";

export const supportPlanSheetsApi = {
  getSupportPlanUifIdSupportPlanSheets,
  getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId,
  postSupportPlanUifIdSupportPlanSheets,
  deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId
};
