import { MunicipalityForm } from "@components/organisms/mgr/IDOSHIEN/Municipality/MunicipalityForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import AdminTemplate from "@components/templates/AdminTemplate";
import React from "react";

import { RouteComponentProps } from "react-router-dom";

type OwnProps = RouteComponentProps<{
  municipalityId: string;
}>;

type Props = OwnProps;

/**
 * 自治体情報編集画面
 */
const MunicipalityEditCore = (props: Props): JSX.Element => {
  const { municipalityId } = props.match.params;
  return (
    <AdminTemplate pageName="自治体情報">
      <MunicipalityForm
        history={props.history}
        municipalityId={municipalityId}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

export const MunicipalityEdit = MunicipalityEditCore;
