/**
 * ActionNames
 */
export const FETCH_STARTED = "GROUP_HOME/FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "GROUP_HOME/FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "GROUP_HOME/FACILITY/FETCH_FAILED";
export const POST_STARTED = "GROUP_HOME/FACILITY/POST_STARTED";
export const POST_SUCCESS = "GROUP_HOME/FACILITY/POST_SUCCESS";
export const POST_FAILED = "GROUP_HOME/FACILITY/POST_FAILED";

/**
 * State
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  groupHomeType: number;
  representativeName: string;
  capacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  multiFunctionOfficeFlag: boolean;
  operatingUnitFlag: boolean;

  // FacilitySubtractionItemSection
  lackOfLifeSupportMemberFlag: boolean;
  lackOfLifeSupportMemberStartDate: string;
  lackOfResponsiblePersonFlag: boolean;
  lackOfResponsiblePersonStartDate: string;
  establishedByLocalGovernmentsFlag: boolean;
  bodyRestrictedStillFlg: boolean;
  bodyRestrictedStillFrom: string;
  bodyRestrictedStillTo: string;
  abusePreventionMeasuresNotImplementedFlg: boolean;
  abusePreventionMeasuresNotImplementedFrom: string;
  abusePreventionMeasuresNotImplementedTo: string;
  workContinuationNotPlanningFlg: boolean;
  workContinuationNotPlanningFrom: string;
  workContinuationNotPlanningTo: string;
  informationDisclosureNotReportedFlg: boolean;
  informationDisclosureNotReportedFrom: string;
  informationDisclosureNotReportedTo: string;

  // FacilityAdditionItemSection
  staffPlacementType: string;
  welfareSpecialistPlacementType: string;
  welfareConditionStartDate: string;
  welfareConditionEndDate: string;
  specificBetterSupporterCondition202404: string | null;
  staffTreatmentSystemType: string;
  staffTreatmentSpecificSystemType: string;
  nightSupportFlag: boolean;
  nightSupportType: string;
  averageUsersLastYear: string;
  nightStaffAllocationSystemFlag: boolean;
  nursingStaffPlacementSystemFlag: string;
  commuterLifeSupportFlag: boolean;
  betterSupporterConditionBaseUpFlg: boolean;
  subtractionOfLargeScaleHousing: string;
  seeHearTeam202404: string;
  seeHearTeam202404From: string;
  seeHearTeam202404To: string;
  higherBrainDysfunctionPersonsSupportFlg: boolean;
  higherBrainDysfunctionPersonsSupportFrom: string;
  higherBrainDysfunctionPersonsSupportTo: string;
  personnelDistributionType: string;

  improvingInfectionControl1Flg: boolean;
  improvingInfectionControl1From: string;
  improvingInfectionControl1To: string;
  improvingInfectionControl2Flg: boolean;
  improvingInfectionControl2From: string;
  improvingInfectionControl2To: string;

  // units
  units: {
    id: number | null;
    unit_name: string;
    night_support_type: string;
    ave_users_last_fiscal_year: string;
    subtraction_of_large_scale_housing_type: string;
    is_deleted: boolean;
  }[];
};
