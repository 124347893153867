import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
// ui
import { FieldItem } from "@interfaces/ui/form";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import FormikTextField from "@components/molecules/FormikTextField";
import { UnitsFields } from "@componentsMobile/organisms/record/serviceDelivery/units/UnitsField";
import { ServiceDeliveryAccompanySupportField } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryAccompanySupportField";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";
// formik
import { FormikProps } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { DAY_SELECT_OPTIONS, FacilityType } from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      border: "1px solid #bdbdbd",
      padding: "24px 16px",
      borderRadius: "4px"
    },
    flex: {
      display: "flex",
      justifyContent: "space-between"
    }
  });

type Props = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  practitionerNum: number;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  facilityType: FacilityType;
  accompanyFieldDisplayFlg: boolean;
  fieldName: string;
  handleChangeDateSelect: (
    event: React.ChangeEvent<HTMLSelectElement>,
    isAccompanyField?: boolean
  ) => void;
  handleChangeInitialTime(
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string,
    isAccompanyField?: boolean
  ): void;
  onChangeActionClass(index: number, actionClass: number): void;
  onChangeDetailDateSelect(
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ): void;
  onChangeDetailRecord(
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ): void;
  deleteRecord(index: number): void;
  practitionerOptions: FieldItem[];
  changePractitioner: (
    e: React.ChangeEvent<HTMLSelectElement>,
    numberOfIsSnapshot?: string
  ) => void;
  actionClassLabel: string;
  actionClassOptions: {
    label: string;
    value: number;
  }[];
} & WithStyles<typeof styles>;

export const ServiceDeliveryTimeFieldCore = (props: Props): JSX.Element => {
  const {
    isEdit,
    classes,
    practitionerNum,
    practitionerValues,
    formikProps,
    accompanyFieldDisplayFlg,
    fieldName,
    facilityType,
    handleChangeDateSelect,
    handleChangeInitialTime,
    onChangeActionClass,
    onChangeDetailRecord,
    onChangeDetailDateSelect,
    deleteRecord,
    changePractitioner,
    practitionerOptions,
    actionClassLabel,
    actionClassOptions
  } = props;
  const { values: formikValues } = formikProps;
  const startTimeValue = DAY_SELECT_OPTIONS.find(
    (option) => option.value === practitionerValues.startTimeDate
  );

  const isGettingOnAndOff =
    facilityType === FacilityType.KYOTAKUKAIGO &&
    props.formikProps.values.status ===
      KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value;

  const isGroup =
    facilityType === FacilityType.IDOSHIEN &&
    formikValues.status === IDOSHIEN_STATUS_LIST.GROUP.value;
  const isVehicleTransport =
    facilityType === FacilityType.IDOSHIEN &&
    formikValues.status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value;

  return !isVehicleTransport && !formikValues.licenseSameFlg ? (
    <div className={classes.wrapper}>
      {isGroup ? (
        <>
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.practitioner`}
            label="サービス提供者1"
            options={practitionerOptions}
            isSelectablePlaceholder
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            onChangeHook={(e): void => changePractitioner(e, "")}
            size="fullSize"
          />
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.practitioner2`}
            label="サービス提供者2"
            options={practitionerOptions}
            isSelectablePlaceholder
            disabled={
              +formikValues.numberOfPractitioner < 2 ||
              practitionerOptions.length === 0
            }
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            onChangeHook={(e): void => changePractitioner(e, "2")}
            size="fullSize"
          />
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.practitioner3`}
            label="サービス提供者3"
            options={practitionerOptions}
            isSelectablePlaceholder
            disabled={
              +formikValues.numberOfPractitioner < 3 ||
              practitionerOptions.length === 0
            }
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            onChangeHook={(e): void => changePractitioner(e, "3")}
            size="fullSize"
          />
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.practitioner4`}
            label="サービス提供者4"
            options={practitionerOptions}
            isSelectablePlaceholder
            disabled={
              +formikValues.numberOfPractitioner < 4 ||
              practitionerOptions.length === 0
            }
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            onChangeHook={(e): void => changePractitioner(e, "4")}
            size="fullSize"
          />
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.practitioner5`}
            label="サービス提供者5"
            options={practitionerOptions}
            isSelectablePlaceholder
            disabled={
              +formikValues.numberOfPractitioner < 5 ||
              practitionerOptions.length === 0
            }
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            onChangeHook={(e): void => changePractitioner(e, "5")}
            size="fullSize"
          />
        </>
      ) : (
        <FormikSelect
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.practitioner`}
          label="サービス提供者"
          options={practitionerOptions}
          isSelectablePlaceholder
          placeholder="選択してください"
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          onChangeHook={(e): void => changePractitioner(e, "")}
          size="fullSize"
        />
      )}
      <div className={classes.flex}>
        {practitionerNum === 2 && facilityType === FacilityType.IDOSHIEN ? (
          <FormikSelect
            isMobile
            readOnly={!isEdit}
            name={`${fieldName}.startTimeDate`}
            label="開始時間"
            options={DAY_SELECT_OPTIONS}
            size="mobileHalf"
            noerrortext
            onChangeHook={(e): void => handleChangeDateSelect(e)}
          />
        ) : (
          <ReadonlyTextField
            label="開始時間"
            value={startTimeValue ? startTimeValue.label : "当日"}
            size="mobileHalf"
            defaultValue="当日"
          />
        )}
        <FormikTime
          name={`${fieldName}.startTime`}
          label=""
          placeholder="00:00"
          maxLength={5}
          onChangeHookTime={handleChangeInitialTime}
          size="mobileHalf"
          isMobile
          readOnly={!isEdit}
        />
      </div>
      <div className={classes.flex}>
        <FormikSelect
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.endTimeDate`}
          label="終了時間"
          options={DAY_SELECT_OPTIONS}
          size="mobileHalf"
          noerrortext
          onChangeHook={(e): void => handleChangeDateSelect(e)}
        />
        <FormikTime
          name={`${fieldName}.endTime`}
          placeholder="00:00"
          maxLength={5}
          onChangeHookTime={handleChangeInitialTime}
          size="mobileHalf"
          isMobile
          readOnly={!isEdit}
        />
      </div>
      {facilityType === FacilityType.KYOTAKUKAIGO &&
      formikProps.values.status ===
        KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value ? (
        <FormikTextField
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.numberOfRides`}
          label="乗降回数"
          endAdornmentLabel="回"
          size="mobileHalf"
          style={{ marginBottom: 0 }}
        />
      ) : (
        <ReadonlyTextField
          label={facilityType === FacilityType.IDOSHIEN ? "時間" : "時間数"}
          value={practitionerValues.numberOfTime || "-"}
          defaultValue=""
          endAdornment="時間"
          size="mobileHalf"
          style={{
            marginBottom: 0
          }}
        />
      )}
      {facilityType === FacilityType.JUDOHOMONKAIGO && (
        <ReadonlyTextField
          label="移動時間数"
          value={practitionerValues.calculatedMovingHours || "-"}
          defaultValue=""
          endAdornment="時間"
          size="mobileHalf"
          style={{ marginTop: 24 }}
        />
      )}
      {/* 同行支援時間フィールド */}
      {accompanyFieldDisplayFlg && (
        <ServiceDeliveryAccompanySupportField
          formikProps={props.formikProps}
          setFormikFieldValue={props.formikProps.setFieldValue}
          practitionerNum={props.practitionerNum}
          practitionerValues={props.practitionerValues}
          handleChangeDateSelect={handleChangeDateSelect}
          handleChangeInitialTime={handleChangeInitialTime}
          isEdit={isEdit}
        />
      )}
      {!isGettingOnAndOff && (
        <UnitsFields
          isEdit={isEdit}
          formikProps={props.formikProps}
          unitKey={`${fieldName}`}
          onDeleteRecord={deleteRecord}
          onChangeTime={onChangeDetailRecord}
          onChangeSelect={onChangeDetailDateSelect}
          onChangeActionClass={onChangeActionClass}
          setFormikFieldValue={props.formikProps.setFieldValue}
          isAddButton={
            practitionerValues.serviceDeliveryRecordPractitionerDetails.filter(
              (item) => !item.isDelete
            ).length < 10
          }
          list={practitionerValues.serviceDeliveryRecordPractitionerDetails}
          practitionerNum={practitionerNum}
          practitionerValues={practitionerValues}
          actionClassLabel={actionClassLabel}
          actionClassOptions={actionClassOptions}
          facilityType={facilityType}
        />
      )}
    </div>
  ) : (
    <FormikSelect
      isMobile
      readOnly={!isEdit}
      name={`${fieldName}.practitioner`}
      label="サービス提供者"
      options={practitionerOptions}
      isSelectablePlaceholder
      placeholder="選択してください"
      emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
      onChangeHook={(e): void => changePractitioner(e, "")}
      size="fullSize"
      style={{ marginBottom: 0 }}
    />
  );
};

export const ServiceDeliveryTimeField = withStyles(styles)(
  ServiceDeliveryTimeFieldCore
);
