/**
 * value が 1 の場合は true を返す
 * value が undefined または null の場合は defaultValue を返す
 *
 * @param value
 * @param defaultValue
 */
const numberToBoolean = (
  value: number | undefined | null,
  defaultValue?: boolean
): boolean => {
  if (defaultValue !== undefined) {
    if (value === undefined || value === null) {
      return defaultValue;
    }
  }
  return value === 1;
};

export default numberToBoolean;
