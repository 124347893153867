import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// ui
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { CustomRecordsTable } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/setting/CustomRecordsTable";
import { SettingFormButtons } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/setting/SettingFormButtons";

// formik
import { Formik, FormikActions } from "formik";
import { tableBodyInitialValues } from "@initialize/record/customRecordWithCategory/initialValues";

// others
import { CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME } from "@constants/variables";
import deepEqual from "fast-deep-equal";
import { HiddenItemsWithCategoryModal } from "@components/organisms/mgr/common/record/HiddenItemsWithCategoryModal";
import { UserState } from "@stores/domain/user/type";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    },
    pureSettingTopSection: {
      marginTop: spacing.unit * 3,
      marginBottom: spacing.unit,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "end"
    }
  });

type OwnProps = {
  isCategorized: boolean;
  categoryType: number;
  label: string;
  customRecords: CustomRecordsWithCategoryState;
  openHideConfirmModal: (id: number, type: "" | "category" | "item") => void;
  sortingCategoryType: number | null;
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  sortingItemType: number | null;
  setSortingItemType: reactDispatch<SetStateAction<number | null>>;
  fetchCustomRecords: () => Promise<void>;
  openAddModal: (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null
  ) => void;
  editedId: number;
  editedType: "" | "category" | "item";
  changeEditMode: (
    id: number,
    type: "" | "category" | "item",
    record: CustomRecordsWithCategoryState[number]
  ) => void;
  endEdit: () => void;
  submitEdit: (value: CustomRecordsWithCategoryState) => Promise<void>;
};

type StateProps = {
  user: UserState;
  needsStopHistory: boolean;
};

type DispatchProps = {
  postCustomRecordsCategoryOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState
  ) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState,
    customRecordCategoryId: number
  ) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const SupportLedgerSettingFormCore = (props: Props): JSX.Element => {
  const {
    isCategorized,
    categoryType,
    label,
    classes,
    customRecords,
    fetchCustomRecords,
    sortingCategoryType,
    setSortingCategoryType,
    sortingItemType,
    setSortingItemType,
    openHideConfirmModal,
    postCustomRecordsOrder,
    postCustomRecordsCategoryOrder,
    showCustomRecord,
    needsStopHistory,
    stopHistory,
    openAddModal,
    editedId,
    editedType,
    changeEditMode,
    endEdit,
    submitEdit
  } = props;

  // state
  const [commonTableValues, setCommonTableValues] = React.useState(
    tableBodyInitialValues()
  );
  const [
    isOpenHiddenCustomRecordList,
    setOpenHiddenCustomRecordList
  ] = React.useState(false);
  const [
    hiddenCustomRecordCategoryType,
    setHiddenCustomRecordCategoryType
  ] = React.useState(0);

  // 並べ替え用関数
  const sortRecords = (
    records: CustomRecordsWithCategoryState
  ): CustomRecordsWithCategoryState => {
    return records
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      })
      .map((r) => {
        const custom_record_items = r.custom_record_items.sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
        return { ...r, custom_record_items };
      });
  };

  const visibleCustomRecords = customRecords.length
    ? sortRecords(customRecords)
        .map((record) => {
          const custom_record_items = record.custom_record_items.filter(
            (i) => i.visibility
          );
          return { ...record, custom_record_items };
        })
        .filter((record) => record.visibility === 1)
    : [];

  // 非表示カテゴリーか非表示項目が1つでも存在するかどうか
  const invisibleCustomRecordExist: boolean = customRecords.length
    ? !!customRecords.filter((record) => {
        const invisible_item = record.custom_record_items.filter(
          (i) => i.visibility === 0
        );
        return record.visibility === 0 || invisible_item.length > 0;
      }).length
    : false;

  React.useEffect(() => {
    setCommonTableValues(tableBodyInitialValues(visibleCustomRecords));
  }, [customRecords]);

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordsWithCategoryState
  ): void => {
    const hasChange = !deepEqual(nextValues, commonTableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordsWithCategoryState): void => {
    if (!needsStopHistory) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordsWithCategoryState,
    actions: FormikActions<CustomRecordsWithCategoryState>
  ): Promise<void> => {
    await actions.setSubmitting(true);
    if (editedId) {
      await submitEdit(values);
      await fetchCustomRecords();
      await endEdit();
    } else if (sortingCategoryType) {
      await postCustomRecordsCategoryOrder(values);
      await fetchCustomRecords();
      await setSortingCategoryType(null);
    } else if (sortingItemType) {
      await postCustomRecordsOrder(values, sortingItemType);
      await fetchCustomRecords();
      await setSortingItemType(null);
    }
    actions.setSubmitting(false);
  };

  // 非表示項目リスト
  const openHiddenCustomRecordsList = (): void => {
    setHiddenCustomRecordCategoryType(categoryType);
    setOpenHiddenCustomRecordList(true);
  };

  const closeHiddenCustomRecordsList = async (): Promise<void> => {
    await setOpenHiddenCustomRecordList(false);
    setHiddenCustomRecordCategoryType(0);
  };

  const onClickVisible = async (
    visibleRecordIds: {
      item: number[];
      category: number[];
    },
    invisibleRecordIds: {
      item: number[];
      category: number[];
    }
  ): Promise<void> => {
    await showCustomRecord(visibleRecordIds, invisibleRecordIds);
    await fetchCustomRecords();
    await setOpenHiddenCustomRecordList(false);
  };

  const categoryName =
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME[categoryType];

  return (
    <>
      <div
        className={
          !sortingCategoryType || sortingCategoryType === categoryType
            ? classes.editable
            : classes.unEditable
        }
      >
        <FormPaper>
          <SectionTitle label={`【${categoryName}】の項目設定`} />
          <div className={classes.description}>{label}</div>
          <Formik
            initialValues={commonTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                <div className={classes.pureSettingTopSection}>
                  <SettingFormButtons
                    isCategorized={isCategorized}
                    orderDisable={visibleCustomRecords.length <= 1}
                    categoryType={categoryType}
                    sortingCategoryType={sortingCategoryType}
                    setSortingCategoryType={setSortingCategoryType}
                    openAddModal={openAddModal}
                    formikProps={formikProps}
                    formValues={commonTableValues}
                    stopHistory={stopHistory}
                    editedId={editedId}
                    setSortingItemType={setSortingItemType}
                    sortingItemType={sortingItemType}
                  />
                </div>
                <CustomRecordsTable
                  isCategorized={isCategorized}
                  openAddModal={openAddModal}
                  changeEditMode={changeEditMode}
                  openHideConfirmModal={openHideConfirmModal}
                  openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                  settingType={categoryType}
                  isSortingCategory={sortingCategoryType === categoryType}
                  editedId={editedId}
                  editedType={editedType}
                  formikProps={formikProps}
                  formValues={commonTableValues}
                  endEdit={endEdit}
                  sortingItemType={sortingItemType}
                  setSortingItemType={setSortingItemType}
                  invisibleCustomRecordExist={invisibleCustomRecordExist}
                  stopHistory={stopHistory}
                />
              </>
            )}
          </Formik>
        </FormPaper>
      </div>
      <HiddenItemsWithCategoryModal
        isModalOpen={isOpenHiddenCustomRecordList}
        onClose={closeHiddenCustomRecordsList}
        onVisible={onClickVisible}
        hiddenCustomRecordCategoryType={hiddenCustomRecordCategoryType}
        customRecords={props.customRecords}
        isCategorized={isCategorized}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory, uiDispatch } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );
  const uiDispatches = uiDispatch(dispatch);

  return {
    postCustomRecordsCategoryOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsCategoryOrder(
        visibleCustomRecords
      );
    },
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState,
      customRecordCategoryId: number
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsOrder(
        visibleCustomRecords,
        customRecordCategoryId
      );
    },
    showCustomRecord: (
      visibleRecordIds: {
        item: number[];
        category: number[];
      },
      invisibleRecordIds: {
        item: number[];
        category: number[];
      }
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.showCustomRecord(
        visibleRecordIds,
        invisibleRecordIds
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const SupportLedgerSettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportLedgerSettingFormCore)
);
