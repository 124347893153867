import * as React from "react";
import * as URL from "@constants/url";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { SupportProcedurePrint as SupportProcedurePrintOrganism } from "@components/organisms/record/print/SupportProcedurePrint";
import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";
import { PrintRecordParams } from "@api/requests/supportProcedure/getSupportProcedurePrint";
import { getUrlParams } from "@/utils/url";
import { dateInYYYYMMFormat } from "@utils/date";

type StateProps = {
  printState: SupportProcedureState["print"];
};

type DispatchProps = {
  fetchPrintRecord: (params: PrintRecordParams) => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

type PrintRecordQuery = {
  target_date?: string;
  excluded_user_ids?: string;
  from?: string;
  to?: string;
  user_ids?: string;
  target_id?: string;
  uifId?: string;
};

/**
 * 支援手順書兼記録用紙のプリント画面
 */
const SupportProcedurePrintCore = (props: Props): JSX.Element => {
  const { search } = props.location;
  const { uifId, ...rest } = getUrlParams(search) as PrintRecordQuery;
  const isSupportPlan = !!uifId; // 支援計画から遷移した場合のみuifIdが渡される

  // 印刷情報取得
  React.useEffect(() => {
    props.fetchPrintRecord({
      targetDate: rest.target_date,
      excludedUserIds: rest.excluded_user_ids,
      from: rest.from,
      to: rest.to,
      userIds: rest.user_ids,
      targetId: rest.target_id
    });
  }, []);

  // 支援手順書兼記録用紙から印刷プレビューに遷移した場合にパンくずを用意
  const pathList = isSupportPlan
    ? [
        { pathName: "支援計画", path: "/record/support_plan/users_summary" },
        {
          pathName: "支援手順書 兼 記録用紙一覧",
          path: `${
            URL.RECORD_SUPPORT_PLAN
          }/${uifId}/support_procedure/list/${dateInYYYYMMFormat(new Date())}`
        }
      ]
    : [];

  return (
    <AdminTemplate pageName="支援手順書 兼 記録用紙" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <SupportProcedurePrintOrganism records={props.printState} />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  printState: state.KODOENGO.supportProcedure.print
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  return {
    fetchPrintRecord: (params: PrintRecordParams): Promise<void> =>
      KODOENGO.supportProcedureDispatcher(dispatch).fetchPrintRecord(params)
  };
};

export const SupportProcedurePrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportProcedurePrintCore);
