import { GetPlanMonitoringMeetingResponse } from "@api/requests/planMonitoringMeeting/getPlanMonitoringMeeting";

/**
 * ActionNames
 */
export const FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_STARTED =
  "CHIIKIIKO/FETCH_PLAN_MONITORING_MEETING_STARTED";
export const FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_SUCCESS =
  "CHIIKIIKO/FETCH_PLAN_MONITORING_MEETING_SUCCESS";
export const FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_FAILED =
  "CHIIKIIKO/FETCH_PLAN_MONITORING_MEETING_FAILED";

/**
 * State
 */
export type PlanMonitoringMeetingState = {
  planMonitoringMeeting: GetPlanMonitoringMeetingResponse["data"];
};
