import { getCustomRecordsWithCategoryResponse } from "@api/requests/customRecords/getCustomRecordsWithCategory";

/**
 * ActionNames
 */
export const FETCH_CUSTOM_RECORDS_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_STARTED";
export const FETCH_CUSTOM_RECORDS_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_SUCCESS";
export const FETCH_CUSTOM_RECORDS_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_FAILED";

export const FETCH_CUSTOM_RECORDS_STARTED_SERVICE =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_STARTED_SERVICE";
export const FETCH_CUSTOM_RECORDS_SUCCESS_SERVICE =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_SUCCESS_SERVICE";
export const FETCH_CUSTOM_RECORDS_FAILED_SERVICE =
  "CUSTOM_RECORDS_WITH_CATEGORY/FETCH_FAILED_SERVICE";

export const POST_CUSTOM_RECORDS_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_STARTED";
export const POST_CUSTOM_RECORDS_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_SUCCESS";
export const POST_CUSTOM_RECORDS_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_FAILED";
export const POST_CUSTOM_RECORDS_CATEGORY_ORDER_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_STARTED";
export const POST_CUSTOM_RECORDS_CATEGORY_ORDER_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_SUCCESS";
export const POST_CUSTOM_RECORDS_CATEGORY_ORDER_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_FAILED";
export const POST_CUSTOM_RECORDS_ORDER_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_STARTED";
export const POST_CUSTOM_RECORDS_ORDER_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_SUCCESS";
export const POST_CUSTOM_RECORDS_ORDER_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_ORDER_FAILED";

export const HIDE_CUSTOM_RECORD_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/HIDE_STARTED";
export const HIDE_CUSTOM_RECORD_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/HIDE_SUCCESS";
export const HIDE_CUSTOM_RECORD_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/HIDE_FAILED";

export const SHOW_CUSTOM_RECORD_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/SHOW_STARTED";
export const SHOW_CUSTOM_RECORD_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/SHOW_SUCCESS";
export const SHOW_CUSTOM_RECORD_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/SHOW_FAILED";

export const CHANGE_VISIBILITY_CUSTOM_RECORD_STARTED =
  "CUSTOM_RECORD/CHANGE_VISIBILITY_STARTED";
export const CHANGE_VISIBILITY_CUSTOM_RECORD_SUCCESS =
  "CUSTOM_RECORD/CHANGE_VISIBILITY_SUCCESS";
export const CHANGE_VISIBILITY_CUSTOM_RECORD_FAILED =
  "CUSTOM_RECORD/CHANGE_VISIBILITY_FAILED";

export const POST_HIDDEN_CHOICE_STARTED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_HIDDEN_CHOICE_STARTED";
export const POST_HIDDEN_CHOICE_SUCCESS =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_HIDDEN_CHOICE_SUCCESS";
export const POST_HIDDEN_CHOICE_FAILED =
  "CUSTOM_RECORDS_WITH_CATEGORY/POST_HIDDEN_CHOICE_FAILED";
/**
 * State
 */
export type CustomRecordsWithCategoryState = getCustomRecordsWithCategoryResponse["data"];

export type CustomRecordsWithCategory = {
  assesment: CustomRecordsWithCategoryState;
  serviceDelivery: CustomRecordsWithCategoryState;
};
