import { CHIIKIIKOGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";
import { CHIIKIIKOPostConsultation as PostConsultation } from "@api/requests/consultation/postConsultation";

import * as types from "./types";
import { ConsultationValues } from "@initialize/mgr/CHIIKIIKO/record/consultation/initialValues";
import {
  selectDateValueToDatePaddingZero,
  selectDateValueToDatePaddingZeroWithoutDay
} from "@utils/date";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";

const removeNullParam = <T extends {}>(data: T, requiredKey?: string[]): T => {
  const targetParam = {} as T;

  // 必須キーを除くundefinedをのparamを除去
  Object.keys(data).forEach((param) => {
    if (
      (requiredKey && requiredKey.includes(param)) ||
      data[param] !== undefined
    ) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

// Get用のnormalizer
export const normalizeValueFromAPI = (
  res: GetConsultationResponse["data"]
): types.ConsultationRecordState => {
  return {
    id: res.id,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    targetFlg: res.target_flg,
    creationDate: res.creation_date,
    author: res.author,
    attainmentGoal: res.attainment_goal || "",
    longTermGoal: res.long_term_goal || "",
    shortTermGoal: res.short_term_goal || "",
    startYm: res.start_ym || "",
    endYm: res.end_ym || "",
    supportPlanConsultationDetails: res.support_plan_consultation_details || [],
    staffComment: res.staff_comment || ""
  };
};

// Post用の計画の詳細normalizer
export const normalizeSupportPlanConsultationDetails = (
  detailValues: ConsultationValues["supportPlanConsultationDetails"],
  initialDetailValues?: ConsultationValues["supportPlanConsultationDetails"]
): PostConsultation["support_plan_consultation_details"] => {
  return detailValues
    .filter(
      (value) =>
        !(!value.support_plan_consultation_details_id && value.is_delete)
    )
    .map((value) => {
      const initialvalue = initialDetailValues
        ? initialDetailValues.find(
            (initial) =>
              initial.support_plan_consultation_details_id ===
              value.support_plan_consultation_details_id
          )
        : undefined;

      // 存在する詳細データを編集する場合
      if (initialvalue) {
        const res = omitByNoChanges(value, initialvalue);
        const param = {
          support_plan_consultation_details_id:
            value.support_plan_consultation_details_id,
          support_goal:
            res.support_goal !== undefined ? value.support_goal : undefined,
          collaborator:
            res.collaborator !== undefined ? value.collaborator : undefined,
          contents_cooperation:
            res.contents_cooperation !== undefined
              ? value.contents_cooperation
              : undefined,
          considerations:
            res.considerations !== undefined ? value.considerations : undefined,
          start_ym: res.start_ym !== undefined ? value.start_ym : undefined,
          end_ym: res.end_ym !== undefined ? value.end_ym : undefined,
          users_do: res.users_do !== undefined ? value.users_do : undefined,
          is_delete: res.is_delete ? value.is_delete : undefined
        };
        return removeNullParam(param);
      }

      // 新規追加
      const param = {
        support_plan_consultation_details_id: null,
        support_goal: value.support_goal,
        collaborator: value.collaborator,
        contents_cooperation: value.contents_cooperation,
        considerations: value.considerations,
        start_ym: value.start_ym,
        end_ym: value.end_ym,
        users_do: value.users_do,
        is_delete: value.is_delete
      };
      return removeNullParam(param);
    });
};

// Post用のnormalizer
export const normalizeFormValuesToPostConsultation = (
  values: ConsultationValues,
  initialValues?: ConsultationValues
): PostConsultation => {
  let normalizedValue: PostConsultation;

  // 新規作成
  if (!initialValues) {
    normalizedValue = {
      support_plan_consultations_id: null,
      target_flg: values.targetFlg,
      creation_date:
        selectDateValueToDatePaddingZero(values.creationDate) || "",
      author: Number(values.author) || null,
      attainment_goal: values.attainmentGoal,
      long_term_goal: values.longTermGoal,
      short_term_goal: values.shortTermGoal,
      start_ym: selectDateValueToDatePaddingZeroWithoutDay(values.startYm),
      end_ym: selectDateValueToDatePaddingZeroWithoutDay(values.endYm),
      support_plan_consultation_details: normalizeSupportPlanConsultationDetails(
        values.supportPlanConsultationDetails
      ),
      staff_comment: values.staffComment
    };
  } else {
    // 編集
    const res = omitByNoChanges(values, initialValues);
    normalizedValue = {
      support_plan_consultations_id: values.id,
      target_flg: values.targetFlg,
      creation_date:
        selectDateValueToDatePaddingZero(values.creationDate) || "",
      author:
        res.author !== undefined ? Number(values.author) || null : undefined,
      attainment_goal:
        res.attainmentGoal !== undefined ? values.attainmentGoal : undefined,
      long_term_goal:
        res.longTermGoal !== undefined ? values.longTermGoal : undefined,
      short_term_goal:
        res.shortTermGoal !== undefined ? values.shortTermGoal : undefined,
      start_ym:
        selectDateValueToDatePaddingZeroWithoutDay(values.startYm) || null,
      end_ym: selectDateValueToDatePaddingZeroWithoutDay(values.endYm) || null,
      support_plan_consultation_details: normalizeSupportPlanConsultationDetails(
        values.supportPlanConsultationDetails,
        initialValues.supportPlanConsultationDetails
      ),
      staff_comment:
        res.staffComment !== undefined ? values.staffComment : undefined
    };
  }
  return removeNullParam(normalizedValue, [
    "support_plan_consultations_id",
    "target_flg",
    "creation_date"
  ]);
};
