import { GetBasicInfoResponse } from "@api/requests/basicInfo/getBasicInfo";
import { GetBasicInfoListResponse } from "@api/requests/basicInfo/getBasicInfoList";

/**
 * ActionNames
 */
export const FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_STARTED =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_LIST_STARTED";
export const FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_SUCCESS =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_LIST_SUCCESS";
export const FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_FAILED =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_LIST_FAILED";

export const FETCH_KEIKAKUSODAN_BASIC_INFO_STARTED =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_STARTED";
export const FETCH_KEIKAKUSODAN_BASIC_INFO_SUCCESS =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_SUCCESS";
export const FETCH_KEIKAKUSODAN_BASIC_INFO_FAILED =
  "KEIKAKUSODAN/FETCH_BASIC_INFO_FAILED";

export const POST_KEIKAKUSODAN_BASIC_INFO_STARTED =
  "KEIKAKUSODAN/POST_BASIC_INFO_STARTED";
export const POST_KEIKAKUSODAN_BASIC_INFO_SUCCESS =
  "KEIKAKUSODAN/POST_BASIC_INFO_SUCCESS";
export const POST_KEIKAKUSODAN_BASIC_INFO_FAILED =
  "KEIKAKUSODAN/POST_BASIC_INFO_FAILED";
export const UNSET_KEIKAKUSODAN_BASIC_INFO = "KEIKAKUSODAN/UNSET_BASIC_INFO";

export const DELETE_KEIKAKUSODAN_BASIC_INFO_STARTED =
  "KEIKAKUSODAN/DELETE_BASIC_INFO_STARTED";
export const DELETE_KEIKAKUSODAN_BASIC_INFO_SUCCESS =
  "KEIKAKUSODAN/DELETE_BASIC_INFO_SUCCESS";
export const DELETE_KEIKAKUSODAN_BASIC_INFO_FAILED =
  "KEIKAKUSODAN/DELETE_BASIC_INFO_FAILED";

/**
 * State
 */
export type BasicInfoState = {
  basicInfo: GetBasicInfoResponse["data"];
  basicInfoList: GetBasicInfoListResponse["data"];
};
