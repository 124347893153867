import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import { ReportMonthly } from "@components/pages/mgr/CHIIKITEICHAKU/report/Monthly";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/CHIIKITEICHAKU/report/Monthly";

// 日々の記録
import { DailyRecord } from "@components/pages/mgr/Cseg/record/operationAndSupports/DailyRecord";
import { DailyRecordCsegRecords } from "@components/pages/record/print/DailyPrintCsegRecords";

// 利用者ごと
import { UsersSummary } from "@components/pages/mgr/CHIIKITEICHAKU/record/UsersSummary";
import { RecordUsersSummarySupportPrint } from "@components/pages/mgr/Cseg/print/RecordUsersSummarySupportPrint";

// 支援記録
import { UserDetail } from "@components/pages/mgr/Cseg/record/UserDetail";
import { SupportRecordPrint } from "@components/pages/record/print/SupportPrint";

// 業務日誌
import { Operations } from "@components/pages/mgr/Cseg/record/operation/Operations";
import { OperationsRecordPrint } from "@components/pages/record/print/OperationsPrint";

// 利用者情報
import { UsersList } from "@components/pages/mgr/CHIIKITEICHAKU/Users/UsersList";
import { CreateUser } from "@components/pages/mgr/CHIIKITEICHAKU/Users/CreateUser";
import { EditUser } from "@components/pages/mgr/CHIIKITEICHAKU/Users/EditUser";
import { EditUser202104 } from "@components/v202104/pages/mgr/CHIIKITEICHAKU/users/EditUser";

// 事業所情報
import { Facility } from "@components/pages/mgr/CHIIKITEICHAKU/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/CHIIKITEICHAKU/Facility";

// アセスメントシート
import { Assessment } from "@components/pages/mgr/Cseg/record/assessment/Assessment";
import { AssessmentList } from "@components/pages/mgr/Cseg/record/assessment/AssessmentList";
import { AssessmentRecordPrint } from "@components/pages/mgr/Cseg/record/print/AssessmentPrint";

// 職員情報
import Staffs from "@components/pages/staffs/Staffs";

// 地域定着台帳
import { SupportLedgerList } from "@components/pages/mgr/CHIIKITEICHAKU/record/SupportLedgerList";
import { SupportLedger } from "@components/pages/mgr/CHIIKITEICHAKU/record/SupportLedger";
import { SupportLedgerRecordPrint } from "@components/pages/mgr/CHIIKITEICHAKU/record/print/SupportLedgerPrint";
import { SupportLedgerSetting } from "@components/pages/mgr/CHIIKITEICHAKU/record/SupportLedgerSetting";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/assessment/setting/AssessmentSetting";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 地域定着でログインした時だけ有効になるルーティング
 */
export const CHIIKITEICHAKURoutes = ({ user }: Props): JSX.Element => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />
    {/* 日々の記録 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_DAILY}/:yyyymmdd?`}
      component={DailyRecord}
    />
    <AdminRoute
      key="DailyRecordPrint"
      exact
      path="/record/print/daily/:yyyymmdd"
      component={DailyRecordCsegRecords}
    />
    {/* 利用者ごと */}
    <AdminRoute exact path="/record/users_summary" component={UsersSummary} />
    <AdminRoute
      exact
      path="/record/print/users_summary_support/:year/:month"
      component={RecordUsersSummarySupportPrint}
    />
    {/* 業務日誌 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_OPERATIONS}/:year?/:month?`}
      component={Operations}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_PRINT_OPERATIONS}/:year/:month`}
      component={OperationsRecordPrint}
    />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    {/* 職員情報 */}
    <AdminRoute path={URL.STAFFS} component={Staffs} />
    {/* 支援記録 */}
    <AdminRoute
      exact
      path="/record/:uifId/support/:yyyy?/:mm?"
      component={UserDetail}
    />
    {/* 印刷・支援記録 */}
    <AdminRoute
      exact
      path="/record/print/:uifId/support/:year/:month"
      component={SupportRecordPrint}
    />

    {/* アセスメントシート */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />

    {/* 地域定着台帳 */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/support_ledger"
      component={SupportLedgerList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/support_ledger/:supportLedgerId"
      component={SupportLedger}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/support_ledger/:copyId/copy"
      component={SupportLedger}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/support_ledger/:supportLedgerId/print"
      component={SupportLedgerRecordPrint}
    />
    {/* 地域定着台帳の設定 */}
    <AdminRoute
      exact
      path={URL.SETTING_SUPPORT_LEDGER}
      component={SupportLedgerSetting}
    />
    {/* アセスメントの設定 */}
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_USERS}
      />
    </Route>
  </Switch>
);
