import * as React from "react";
import * as H from "history";
// store
import { SupportsState } from "@stores/domain/supports/types";
// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import * as classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ExcludeUsersDialog from "@components/molecules/dialog/ExcludeUsersDialog";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { PrintFormNameServiceDelivery } from "@components/organisms/mgr/common/record/PrintFormNameServiceDelivery";
import { PrintFormTypeServiceDelivery } from "@components/organisms/mgr/common/record/PrintFormTypeServiceDelivery";
import MuiTextField from "@components/molecules/MuiTextField";
import { DateSelectFieldsDayOnly } from "@components/molecules/DateSelectFieldsDayOnly";
// variables
import {
  RECORD_MODAL_TYPE,
  SERVICE_DELIVERY_PRINT_TITLE_OPTIONS,
  SERVICE_DELIVERY_PRINT_TYPE_OPTIONS,
  FacilityType
} from "@constants/variables";
// utils
import { checkDatesRange } from "@utils/date/checkDatesRange";
import format from "date-fns/format";
import { futureDaySelectOnly } from "@validator/rules/futureDaySelectOnly";

const styles = (): StyleRules =>
  createStyles({
    dialogTitle: {
      padding: "16px 24px 20px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 64
    },
    dialogContent: {
      width: 600,
      height: "100%",
      minHeight: 148,
      padding: 0,
      "&::-webkit-scrollbar": { display: "none" }
    },
    dialogActions: {
      borderTop: "1px solid #cfd8dc",
      margin: 0,
      padding: 8
    },
    contentSection: {
      padding: "24px 32px",
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.38)"
      }
    },
    formNameWrap: {
      "& > div > div": {
        margin: 0,
        "& > label > span": {
          fontSize: 16
        }
      }
    },
    formNameLabel: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 6px"
    },
    note: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 16px"
    },
    preCheckbox: {
      marginBottom: 4
    },
    button: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 24
      }
    },
    cancel: {
      borderColor: "#ccc"
    },
    targetUsersWrap: {
      width: "100%",
      display: "flex",
      alignItems: "flex-end"
    },
    dateSelect: {
      marginTop: "18px"
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  onClose: () => void;
  modalType: number;
  targetUsers: SupportsState["report"]["users"];
  history: H.History;
  staffCommentLabel: string;
  targetDate: Date;
  targetUserId?: number;
  facilityType: FacilityType;
  getReportUsers: (reportType: string) => void;
  isSupportProcedureExist: boolean;
  isServiceDeliveryExist: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const currentDate = new Date();

/**
 * 指定ユーザーの記録を印刷する
 */
const UserSummaryModalCore = (props: Props): JSX.Element | null => {
  const { classes } = props;
  // RECORD_MODAL_TYPEごとの差分を定義
  const modalDiff = {
    [RECORD_MODAL_TYPE.serviceDeliveryDaily]: {
      title: "サービス提供記録（日ごと） 印刷",
      moveUrl: "/record/service_delivery/daily/print"
    },
    [RECORD_MODAL_TYPE.serviceDeliveryMonthly]: {
      title: "サービス提供記録（月ごと） 印刷",
      moveUrl: "/record/service_delivery/monthly/print"
    }
  };
  const modalData = modalDiff[props.modalType];

  // 定義と違うmodalTypeがきたら彈く
  if (!modalData) {
    return null;
  }

  // 月初日(対象開始日デフォルト値)
  const firstDayOfMonth = new Date(
    props.targetDate.getFullYear(),
    props.targetDate.getMonth(),
    1
  );

  // 対象終了日デフォルト値
  const isCurrentMonth =
    currentDate.getFullYear() === props.targetDate.getFullYear() &&
    currentDate.getMonth() === props.targetDate.getMonth();

  const endDayDefaultValue = isCurrentMonth
    ? currentDate
    : new Date(
        props.targetDate.getFullYear(),
        props.targetDate.getMonth() + 1,
        0
      );

  // state
  const [staffFlag, setStaffFlag] = React.useState(false);
  const [openExcludeUsers, setOpenExcludeUsers] = React.useState(false);
  const [formName, setFormName] = React.useState(
    SERVICE_DELIVERY_PRINT_TITLE_OPTIONS[0].value
  );
  const [startDateValues, setStartDateValues] = React.useState(
    format(firstDayOfMonth, "YYYY-MM-DD")
  );
  const [endDateValues, setEndDateValues] = React.useState(
    format(endDayDefaultValue, "YYYY-MM-DD")
  );
  const [isError, setIsError] = React.useState(false);
  const [errorText, setErrorText] = React.useState<undefined | string>(
    undefined
  );
  // 対象全利用者数
  const [allTargetUsers, setAllTargetUsers] = React.useState(props.targetUsers);
  // 対象でない利用者
  const [excludedUserIds, setExcludedUserIds] = React.useState([] as number[]);
  // 対象利用者
  const [currentUsers, setCurrentUsers] = React.useState(
    props.targetUsers.filter((user) => excludedUserIds.indexOf(+user.id) === -1)
  );
  // サービス提供記録のみ表示するコンポーネントのフラグ
  const [formDisabel, setFormDisabel] = React.useState(false);

  // 帳票種別
  const [printTypeOptions, setPrintTypeOptions] = React.useState(
    SERVICE_DELIVERY_PRINT_TYPE_OPTIONS
  );

  const [formType, setFormType] = React.useState(
    props.facilityType === FacilityType.KODOENGO &&
      props.isSupportProcedureExist === true
      ? SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value
      : SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[1].value
  );

  const userCountLabel = `${currentUsers.length}名／${allTargetUsers.length}名`;

  const isDisabled = currentUsers.length === 0 || isError;

  // 対象利用者更新（対象期間変更時・帳票種別変更時）
  React.useEffect(() => {
    if (
      props.modalType === RECORD_MODAL_TYPE.serviceDeliveryMonthly &&
      props.targetUserId
    ) {
      const filteredUsers = props.targetUsers.filter((user) => {
        if (
          formType === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value &&
          props.facilityType === FacilityType.KODOENGO
        ) {
          return (
            user.procedureFormDates &&
            checkDatesRange(
              user.procedureFormDates,
              startDateValues,
              endDateValues
            )
          );
        }
        return (
          user.serviceDeliveryDates &&
          checkDatesRange(
            user.serviceDeliveryDates,
            startDateValues,
            endDateValues
          )
        );
      });
      setAllTargetUsers(filteredUsers);
      const ids = filteredUsers
        .filter((user) => {
          return user.id !== props.targetUserId;
        })
        .map((item) => Number(item.id));
      setExcludedUserIds(ids);
      const targetUser = filteredUsers.find(
        (user) => user.id === props.targetUserId
      );
      setCurrentUsers(targetUser ? new Array(targetUser) : []);
    }
  }, [startDateValues, endDateValues, props.targetUsers]);

  // エラー時の対応
  React.useEffect(() => {
    if (isError) {
      setAllTargetUsers([]);
      setCurrentUsers([]);
    }
  }, [isError]);

  // 初期表示内容をセット
  React.useEffect(() => {
    if (props.isSupportProcedureExist && props.isServiceDeliveryExist) {
      setFormDisabel(false);
      setPrintTypeOptions(SERVICE_DELIVERY_PRINT_TYPE_OPTIONS);
    } else if (props.isSupportProcedureExist) {
      setFormDisabel(false);
      setPrintTypeOptions(
        SERVICE_DELIVERY_PRINT_TYPE_OPTIONS.filter(
          (item) => item.value === "support_procedure"
        )
      );
    } else if (props.isServiceDeliveryExist) {
      setFormDisabel(true);
      setPrintTypeOptions(
        SERVICE_DELIVERY_PRINT_TYPE_OPTIONS.filter(
          (item) => item.value === "service_delivery"
        )
      );
    }
  }, []);

  // 対象全利用者数を更新（帳票種別変更時）
  React.useEffect(() => {
    setAllTargetUsers(props.targetUsers);
  }, [props.targetUsers]);

  /**
   * 対象月の印刷プレビューに除外ユーザーと追加項目を設定して移動
   */
  const handleClickMoveToPreview = (): void => {
    // 印刷プレビュー先の設定(支援手順書・サービス提供記録)
    const pathname =
      formType === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value
        ? "/record/support_procedure/print"
        : `${modalData.moveUrl}`;
    const params = [];
    const columns = [];
    let targetDateParam: string;
    let fromTo: string;
    let excludedUserIdsParam: string;
    let userIdsParam: string;

    // サービス提供記録の場合
    if (formType === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[1].value) {
      columns.push(formName);
      if (staffFlag) {
        columns.push("staff_comment");
      }
      if (columns.length > 0) {
        params.push(`display_columns=${columns.join(",")}`);
      }
    }

    // 支援記録（日ごと）
    if (props.modalType === RECORD_MODAL_TYPE.serviceDeliveryDaily) {
      const targetDateYYYYMMDD = format(props.targetDate, "YYYYMMDD");
      targetDateParam = `target_date=${targetDateYYYYMMDD}`;
      params.push(targetDateParam);
      if (excludedUserIds.length > 0) {
        excludedUserIdsParam = `excluded_user_ids=${excludedUserIds.join(",")}`;
        params.push(excludedUserIdsParam);
      }
    }
    // 支援記録（月ごと）
    if (props.modalType === RECORD_MODAL_TYPE.serviceDeliveryMonthly) {
      const from = format(startDateValues, "YYYYMMDD");
      const to = format(endDateValues, "YYYYMMDD");
      fromTo = `from=${from}&to=${to}`;
      params.push(fromTo);
      if (currentUsers.length > 0) {
        const currentUserIds = currentUsers.map((user) => user.id);
        userIdsParam = `user_ids=${currentUserIds.join(",")}`;
        params.push(userIdsParam);
      }
    }

    const search = params.length > 0 ? params.join("&") : "";
    props.history.push({ pathname, search });
  };

  /**
   * 除外ユーザーのモーダル
   */
  const handleClickOpenSelectExcludeUserModal = (): void => {
    setOpenExcludeUsers(true);
  };
  const handleClickCloseSelectExcludeUserModal = (): void => {
    setOpenExcludeUsers(false);
  };

  /**
   * 職員考察チェックボックス
   */
  const handleChangeStaffFlag = (): void => {
    setStaffFlag(!staffFlag);
  };

  /**
   * ExcludeUsersDialogから除外ユーザーを貰って反映
   */
  const handleChangeExcludedUserIds = (idList: number[]): void => {
    setExcludedUserIds(idList);
    setCurrentUsers(
      allTargetUsers.filter((user) => idList.indexOf(+user.id) === -1)
    );
  };

  /**
   * 帳票名ラジオボタン
   */
  const handleChangeFormName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormName(e.target.value);
  };

  /**
   * 対象開始日セレクト変更時
   */
  const onChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setStartDateValues(e.target.value);
    const error = futureDaySelectOnly(e.target.value, endDateValues);
    setIsError(!!error);
    setErrorText(error);
  };

  /**
   * 対象終了日セレクト変更時
   */
  const onChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEndDateValues(e.target.value);
    const error = futureDaySelectOnly(startDateValues, e.target.value);
    setIsError(!!error);
    setErrorText(error);
  };

  const selectUsersComment =
    props.modalType === RECORD_MODAL_TYPE.serviceDeliveryDaily
      ? "印刷したい利用者を選択してください"
      : "他の利用者も一括で印刷したい場合は対象利用者を追加してください";

  /**
   * 帳票種別変更時
   */
  const handleChangeFormType = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setFormType(e.target.value);
    props.getReportUsers(e.target.value);
    if (e.target.value === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value) {
      setFormDisabel(false);
    } else {
      setFormDisabel(true);
    }
  };

  return (
    <>
      <Dialog open={props.isModalOpen} disableBackdropClick maxWidth={false}>
        <DialogTitle className={classes.dialogTitle}>
          {modalData.title}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {props.facilityType === FacilityType.KODOENGO && (
            <div className={classes.contentSection}>
              <p className={classes.formNameLabel}>
                印刷したい帳票を選択してください
              </p>
              <div className={classes.formNameWrap}>
                <PrintFormTypeServiceDelivery
                  value={formType}
                  changeFormType={handleChangeFormType}
                  printTypeOptions={printTypeOptions}
                />
              </div>
            </div>
          )}
          {props.modalType === RECORD_MODAL_TYPE.serviceDeliveryMonthly && (
            <div className={classes.contentSection}>
              <p className={classes.formNameLabel}>
                一括で印刷したい期間を設定してください
              </p>
              <div className={classes.dateSelect}>
                <DateSelectFieldsDayOnly
                  id="start"
                  label="対象開始日"
                  value={startDateValues}
                  onChange={onChangeStartDate}
                />
                <DateSelectFieldsDayOnly
                  id="end"
                  label="対象終了日"
                  value={endDateValues}
                  onChange={onChangeEndDate}
                  helperText={errorText}
                  isError={isError}
                />
              </div>
            </div>
          )}
          <div className={classes.contentSection}>
            <p className={classes.note}>{selectUsersComment}</p>
            <div className={classes.targetUsersWrap}>
              <MuiTextField
                label="対象利用者数"
                value={userCountLabel}
                InputLabelProps={{ shrink: true }}
                disabled
                disabledStyle
                size="small"
                style={{ marginBottom: 0 }}
              />
              <KnowbeButton
                className={classes.button}
                kind="outlineWhite"
                disabled={!allTargetUsers.length || isError}
                onClick={handleClickOpenSelectExcludeUserModal}
              >
                利用者選択
              </KnowbeButton>
            </div>
          </div>
          {/* 支援記録の印刷時に追加 */}
          {formDisabel ? (
            <>
              <div className={classes.contentSection}>
                <p className={classes.formNameLabel}>帳票名</p>
                <div className={classes.formNameWrap}>
                  <PrintFormNameServiceDelivery
                    value={formName}
                    changeFormName={handleChangeFormName}
                  />
                </div>
              </div>
              <div className={classes.contentSection}>
                <p className={classNames(classes.note, classes.preCheckbox)}>
                  以下の項目以外で、入力された全ての項目が印刷されます。
                  <br />
                  追加で印刷したい項目にチェックしてください。
                </p>
                <MuiCheckbox
                  id="staff_flg"
                  label={props.staffCommentLabel}
                  checked={staffFlag}
                  value="1"
                  onChange={handleChangeStaffFlag}
                  style={{ margin: "0 0 -2px" }}
                />
              </div>
            </>
          ) : null}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classNames(classes.button, classes.cancel)}
            variant="outlined"
            color="secondary"
            onClick={props.onClose}
          >
            キャンセル
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={handleClickMoveToPreview}
            disabled={isDisabled}
          >
            確定する
          </Button>
        </DialogActions>
      </Dialog>
      {/* 利用者選択 */}
      <ExcludeUsersDialog
        title="利用者の選択"
        open={openExcludeUsers}
        excludedUserIds={excludedUserIds}
        users={allTargetUsers}
        onSubmit={handleChangeExcludedUserIds}
        onClose={handleClickCloseSelectExcludeUserModal}
      />
    </>
  );
};

export const UserSummaryModal = withStyles(styles)(UserSummaryModalCore);
