import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import { SupportLedgerSettingForm } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/setting/SupportLedgerSettingForm";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { UserState } from "@stores/domain/user/type";
import { AppState } from "@stores/type";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE,
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";
import FormPaper from "@components/atoms/FormPaper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  CustomRecordsValues,
  initialValues
} from "@initialize/record/customRecordWithCategory/initialValues";
import { CustomRecordsDialog } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/setting/dialog/CustomRecordsDialog";

const styles = (): StyleRules =>
  createStyles({
    topSection: {
      marginTop: 32
    },
    infoIcon: {
      color: "#0277bd",
      width: 20,
      height: 20,
      marginRight: 6
    },
    caption: {
      color: "#37474f",
      display: "flex",
      alignItems: "center"
    },
    description: {
      lineHeight: 1.75,
      margin: "4px 26px",
      color: "#666666"
    }
  });

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  hideCustomRecord: (recordId: number, type: "item" | "category") => void;
  postCustomRecords: (formValue: CustomRecordsValues) => Promise<void>;
  postCustomRecordsCategory: (formValue: CustomRecordsValues) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
};

type StateProps = {
  user: UserState;
  customRecords: CustomRecordsWithCategoryState;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const SettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    customRecords,
    fetchCustomRecords,
    hideCustomRecord,
    postCustomRecords,
    postCustomRecordsCategory
  } = props;
  const [isOpenHideConfirm, setOpenHideConfirm] = React.useState(false);
  const [hideConfirmTargetId, setHideConfirmTargetId] = React.useState(0);
  const [formValues, setFormValues] = useState(initialValues());
  const [editedType, setEditedType] = useState<"" | "category" | "item">("");
  const [editedId, setEditedId] = useState(0);
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [sortingCategoryType, setSortingCategoryType] = useState<number | null>(
    null
  );
  const [sortingItemType, setSortingItemType] = useState<number | null>(null);

  let hideConfirmTargetName = "";
  if (hideConfirmTargetId > 0 && customRecords.length > 0) {
    if (editedType === "category") {
      const hideConfirmTarget = customRecords.find(
        (record) => record.id === hideConfirmTargetId
      );
      hideConfirmTargetName = hideConfirmTarget ? hideConfirmTarget.name : "";
    } else if (editedType === "item") {
      const hideConfirmTargetCategory = customRecords.find((record) =>
        record.custom_record_items.find((r) => r.id === hideConfirmTargetId)
      );
      const hideConfirmTargetItem = hideConfirmTargetCategory
        ? hideConfirmTargetCategory.custom_record_items.find(
            (r) => r.id === hideConfirmTargetId
          )
        : null;
      hideConfirmTargetName = hideConfirmTargetItem
        ? hideConfirmTargetItem.name
        : "";
    }
  }

  // 項目カテゴリー名の追加
  const openAddModal = (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null
  ): void => {
    const isInputTypeRadio = false;
    setFormValues(
      initialValues(
        category_type,
        custom_records_category_id,
        [],
        isInputTypeRadio
          ? SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio
          : SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text,
        CUSTOM_RECORD_TARGET_TYPE.support_ledger
      )
    );
    openCustomRecordModal(true);
    setEditedType(type);
  };

  // 項目カテゴリー名の編集
  const changeEditMode = (
    id: number,
    type: "" | "category" | "item",
    params: CustomRecordsWithCategoryState[number]
  ): void => {
    const isInputTypeRadio = false;
    setFormValues(
      initialValues(
        params.category_type,
        params.id,
        [],
        isInputTypeRadio
          ? SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio
          : SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text,
        CUSTOM_RECORD_TARGET_TYPE.support_ledger
      )
    );
    setEditedId(id);
    setEditedType(type);
  };

  // 項目カテゴリー名編集終了
  const endEdit = (): void => {
    setFormValues(initialValues());
    setEditedId(0);
    setEditedType("");
  };

  // カテゴリー名編集保存
  const submitCategoryEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((v) => v.id === editedId);
    const params = {
      id: editedId,
      name: category ? category.name : "",
      input_type: "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.support_ledger,
      custom_records_category_id: null,
      category_type: category ? category.category_type : 0
    };
    await postCustomRecordsCategory(params);
  };

  // 項目名編集保存
  const submitItemEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((value) =>
      value.custom_record_items.find((v) => v.id === editedId)
    );
    const item = category
      ? category.custom_record_items.find((v) => v.id === editedId)
      : null;
    const params = {
      id: editedId,
      name: item ? item.name : "",
      input_type: item ? `${item.input_type}` : "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.support_ledger,
      custom_records_category_id: item ? item.custom_records_category_id : 0,
      category_type: 0
    };
    await postCustomRecords(params);
  };

  // 項目カテゴリー名のうち編集中の保存関数を渡す
  const submitEdit =
    editedType === "category" ? submitCategoryEdit : submitItemEdit;

  // 項目の追加モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    setFormValues(initialValues());
    openCustomRecordModal(false);
    setEditedType("");
  };

  const categorizeCustomRecord = (
    categoryType: number
  ): CustomRecordsWithCategoryState => {
    return customRecords.filter((r) => r.category_type === categoryType);
  };

  const openHideConfirmModal = (
    id: number,
    type: "" | "category" | "item"
  ): void => {
    setOpenHideConfirm(true);
    setEditedType(type);
    setHideConfirmTargetId(id);
  };
  const closeHideConfirmModal = (): void => {
    setOpenHideConfirm(false);
  };

  const onClickHide = async (): Promise<void> => {
    setOpenHideConfirm(false);
    if (hideConfirmTargetId === 0) return;
    if (editedType === "") return;
    await hideCustomRecord(hideConfirmTargetId, editedType);
    await fetchCustomRecords();
  };

  useEffect(() => {
    fetchCustomRecords();
  }, []);

  const hideItemConfirmModalMessage = (
    <span>
      この項目は記録画面に表示されなくなり、「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
    </span>
  );
  const hideCategoryConfirmModalMessage = (
    <span>
      このカテゴリーに属する全ての項目が記録画面に表示されなくなり、
      <br />
      「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
      <br />
    </span>
  );
  const hideConfirmModalMessage =
    editedType === "category"
      ? hideCategoryConfirmModalMessage
      : hideItemConfirmModalMessage;
  const hideConfirmModalTitle =
    editedType === "category"
      ? `カテゴリー「${hideConfirmTargetName}」を非表示にしますか？`
      : `「${hideConfirmTargetName}」を非表示にしますか？`;

  const supportLedgerSettingInfo = [
    {
      isCategorized: false,
      categoryType:
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information
      )
    },
    {
      isCategorized: false,
      categoryType:
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information
      )
    },
    {
      isCategorized: true,
      categoryType: CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.user_condition,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.user_condition
      )
    },
    {
      isCategorized: true,
      categoryType: CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.family,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.family
      )
    },
    {
      isCategorized: true,
      categoryType:
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.related_organizations,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.related_organizations
      )
    },
    {
      isCategorized: true,
      categoryType: CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.notices,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.notices
      )
    },
    {
      isCategorized: false,
      categoryType:
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.office_information,
      label: "入力画面では文字入力形式となります。",
      customRecords: categorizeCustomRecord(
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.office_information
      )
    }
  ];

  return (
    <section className={classes.topSection}>
      <FormPaper>
        <div className={classes.caption}>
          <InfoOutlinedIcon className={classes.infoIcon} />
          この画面の設定について
        </div>
        <p className={classes.description}>
          ここで設定した内容は、作成済みのものも含めて全ての地域定着支援台帳に反映されます。
          <br />
          カテゴリー名や項目名を変更する際に、作成済みの台帳に反映させたくない場合は、該当箇所の「編集」は行わず、
          <br />
          「追加」で新しいカテゴリー/項目を作成の上、不要なカテゴリー/項目を「非表示」にしてください。
          <br />
          「非表示」にした場合でも、作成済みの台帳で内容が入力されている箇所は消えずに残ります。
        </p>
      </FormPaper>
      {supportLedgerSettingInfo.map((v) => (
        <SupportLedgerSettingForm
          key={`supportLedgerSettingForm-${v.categoryType}`}
          isCategorized={v.isCategorized}
          categoryType={v.categoryType}
          label={v.label}
          customRecords={v.customRecords}
          sortingCategoryType={sortingCategoryType}
          setSortingCategoryType={setSortingCategoryType}
          sortingItemType={sortingItemType}
          setSortingItemType={setSortingItemType}
          openHideConfirmModal={openHideConfirmModal}
          fetchCustomRecords={fetchCustomRecords}
          openAddModal={openAddModal}
          editedId={editedId}
          editedType={editedType}
          changeEditMode={changeEditMode}
          endEdit={endEdit}
          submitEdit={submitEdit}
        />
      ))}
      <MessageDialog
        isOpen={isOpenHideConfirm}
        title={hideConfirmModalTitle}
        message={hideConfirmModalMessage}
        closeButton={
          <KnowbeButton
            kind="text"
            style={{ margin: "0 8px 0 0" }}
            onClick={closeHideConfirmModal}
          >
            キャンセル
          </KnowbeButton>
        }
        actionButton={
          <KnowbeButton
            kind="textDelete"
            style={{ margin: 0 }}
            onClick={onClickHide}
          >
            非表示にする
          </KnowbeButton>
        }
      />
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          modalType={editedType}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user,
  customRecords: state.customRecordsWithCategory.assesment
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.support_ledger
      );
    },
    hideCustomRecord: (
      recordId: number,
      type: "item" | "category"
    ): Promise<void> => {
      return customRecordsDispatches.hideCustomRecord(recordId, type);
    },
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecords(formValue);
    },
    postCustomRecordsCategory: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsCategory(formValue);
    },
    showCustomRecord: (
      visibleRecordIds: {
        item: number[];
        category: number[];
      },
      invisibleRecordIds: {
        item: number[];
        category: number[];
      }
    ): Promise<void> => {
      return customRecordsDispatches.showCustomRecord(
        visibleRecordIds,
        invisibleRecordIds
      );
    }
  };
};

export const SettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingFormCore)
);
