/**
 * 自然数チェック（カンマは許容する）
 * @param value
 */
export const numberWithComma = (value: string): string | undefined => {
  let errorMessage;
  if (value !== "" && !/^-?([0-9]+,)*([0-9]+)$/.test(value)) {
    errorMessage = "数字を入力してください";
  }
  return errorMessage;
};
