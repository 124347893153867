import * as React from "react";
import { Field, FieldProps, getIn } from "formik";
import MuiCheckbox, {
  MuiCheckboxProps
} from "@components/molecules/MuiCheckbox";
import {
  MuiCheckbox as MuiCheckboxMobile,
  MuiCheckboxProps as MuiCheckboxPropsMobile
} from "@componentsMobile/molecules/MuiCheckbox";

type FormikCheckboxProps = {
  name: string;
  style?: React.CSSProperties;
  isMobile?: boolean;
  readOnly?: boolean;
  highEmphasis?: boolean;
  checkboxStyle?: React.CSSProperties;
  labelPlacement?: "end" | "start" | "top" | "bottom";
  formControlLabelStyle?: string;
};

type Props = FormikCheckboxProps & (MuiCheckboxProps | MuiCheckboxPropsMobile);

// モバイル用にhighEmphasisを受け付ける
// disabledと同時に指定することで"Checked Disabled High Emphasis"デザインを実装できる
const FormikCheckbox = ({
  isMobile,
  readOnly,
  highEmphasis,
  ...props
}: Props): JSX.Element => (
  <Field
    name={props.name}
    type="checkbox"
    render={({ field, form }: FieldProps): JSX.Element => {
      const checked = readOnly
        ? getIn(form.initialValues, props.name)
        : getIn(form.values, props.name);
      return isMobile ? (
        <MuiCheckboxMobile
          {...field}
          {...props}
          disabled={props.disabled || readOnly}
          checked={checked}
          highEmphasis={highEmphasis}
        />
      ) : (
        <MuiCheckbox {...field} {...props} checked={checked} />
      );
    }}
  />
);

export default FormikCheckbox;
