import { FacilityState } from "./types";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { PostFacilityParams } from "@api/requests/facility/postFacility";
import { FacilityValues } from "@initialize/mgr/SHUROTEICHAKU/facility/initialValues";
import {
  DEFAULT_RADIO_VALUE,
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  SELECTED_CONDITION_TYPE_2024_V,
  STRING_FALSE_FROM_API,
  STRING_TRUE_FROM_API,
  STRING_FALSE_FROM_API_1,
  STRING_TRUE_FROM_API_2
} from "@constants/variables";
import { selectDateValueToDate } from "@utils/date";
import castString from "@utils/dataNormalizer/castString";

// , formatTime
/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams
): FacilityState => {
  const { facility } = result;
  const facilitySHUROTEICHAKU = result.facility_shuroteichaku || {
    rate_get_job_202104: 0,
    workhardenes_result_flg: 0,
    workplace_adaptation_assistant_flg: 0
  };
  return {
    corporationName: facility.gov_business_owner,
    officeNumber: facility.gov_facility_number
      ? facility.gov_facility_number
      : "",
    officeName: facility.name ? facility.name : "",
    serviceType: facility.type_service,
    representativeName: facility.responsible_person
      ? facility.responsible_person
      : "",
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address,
    tel: facility.tel ? facility.tel : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : "",
    rateGetJob: facilitySHUROTEICHAKU.rate_get_job_202104
      ? facilitySHUROTEICHAKU.rate_get_job_202104.toString()
      : DEFAULT_RADIO_VALUE,
    lackOfLifeSupportMemberFlag:
      facility.lack_of_supporter_flg === STRING_TRUE_FROM_API,
    lackOfLifeSupportMemberStartDate:
      facility.date_start_lack_of_supporter !== null
        ? facility.date_start_lack_of_supporter
        : "",
    lackOfResponsiblePersonFlag:
      facility.lack_of_service_admin_flg === STRING_TRUE_FROM_API,
    lackOfResponsiblePersonStartDate:
      facility.date_start_lack_of_service_admin !== null
        ? facility.date_start_lack_of_service_admin
        : "",
    abusePreventionMeasuresNotImplementedFlg:
      facility.abuse_prevention_measures_not_implemented_flg ===
      INT_TRUE_FROM_API,
    abusePreventionMeasuresNotImplementedFrom: facility.abuse_prevention_measures_not_implemented_from
      ? facility.abuse_prevention_measures_not_implemented_from
      : "",
    abusePreventionMeasuresNotImplementedTo: facility.abuse_prevention_measures_not_implemented_to
      ? facility.abuse_prevention_measures_not_implemented_to
      : "",
    informationDisclosureNotReportedFlg:
      facility.information_disclosure_not_reported_flg === INT_TRUE_FROM_API,
    informationDisclosureNotReportedFrom: facility.information_disclosure_not_reported_from
      ? facility.information_disclosure_not_reported_from
      : "",
    informationDisclosureNotReportedTo: facility.information_disclosure_not_reported_to
      ? facility.information_disclosure_not_reported_to
      : "",
    workContinuationNotPlanningFlg:
      facility.work_continuation_not_planning_flg === INT_TRUE_FROM_API,
    workContinuationNotPlanningFrom: facility.work_continuation_not_planning_from
      ? facility.work_continuation_not_planning_from
      : "",
    workContinuationNotPlanningTo: facility.work_continuation_not_planning_to
      ? facility.work_continuation_not_planning_to
      : "",
    workHardenesResultFlag: facilitySHUROTEICHAKU.workhardenes_result_flg
      ? facilitySHUROTEICHAKU.workhardenes_result_flg === INT_TRUE_FROM_API
      : false,
    workPlaceAdaptationAssistantFlag: facilitySHUROTEICHAKU.workplace_adaptation_assistant_flg
      ? facilitySHUROTEICHAKU.workplace_adaptation_assistant_flg ===
        INT_TRUE_FROM_API
      : false,
    specificBetterSupporterCondition202404: facility.specific_better_supporter_condition_202404
      ? facility.specific_better_supporter_condition_202404
      : DEFAULT_RADIO_VALUE,
    capacity: "",
    multiFunctionOfficeFlag:
      facility.multiple_facility_flg === STRING_TRUE_FROM_API,
    masterSubordinateFlg:
      facility.master_subordinate_flg === STRING_TRUE_FROM_API,
    masterFlg: facility.master_flg ? facility.master_flg : "1",
    allCapacity: castString(facility.total_capacity),
    staffTreatmentSystemType: facility.better_supporter_condition
      ? facility.better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    specificBetterSupporterCondition:
      facility.specific_better_supporter_condition === STRING_TRUE_FROM_API_2,
    betterSupporterConditionBaseUpFlg:
      facility.better_supporter_condition_base_up_flg === STRING_TRUE_FROM_API
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result = {
    facility: { ...target.facility },
    facility_shuroteichaku: { ...target.facility_shuroteichaku }
  };
  if (result.facility.lack_of_service_admin_flg === STRING_FALSE_FROM_API) {
    result.facility.date_start_lack_of_service_admin = null;
  }
  if (result.facility.lack_of_supporter_flg === STRING_FALSE_FROM_API) {
    result.facility.date_start_lack_of_supporter = null;
  }
  if (result.facility.master_subordinate_flg === STRING_FALSE_FROM_API) {
    result.facility.master_flg = null;
  }
  if (result.facility.better_supporter_condition === DEFAULT_RADIO_VALUE) {
    result.facility.specific_better_supporter_condition = STRING_FALSE_FROM_API_1;
    result.facility.better_supporter_condition_base_up_flg = STRING_FALSE_FROM_API;
  }
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams
): NormalizedPostFacilityParams => normalizeApiParams(params);

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams
): PostFacilityParams => {
  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      capacity: after.facility.capacity,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      multiple_facility_flg: after.facility.multiple_facility_flg,
      users_vs_supporter_grade: after.facility.users_vs_supporter_grade,
      welfare_condition: after.facility.welfare_condition,
      welfare_condition_start_date: after.facility.welfare_condition_start_date,
      welfare_condition_end_date: after.facility.welfare_condition_end_date,
      better_supporter_condition: after.facility.better_supporter_condition,
      lack_of_supporter_flg: after.facility.lack_of_supporter_flg,
      date_start_lack_of_supporter: after.facility.date_start_lack_of_supporter,
      lack_of_service_admin_flg: after.facility.lack_of_service_admin_flg,
      date_start_lack_of_service_admin:
        after.facility.date_start_lack_of_service_admin,
      abuse_prevention_measures_not_implemented_flg: undefined,
      abuse_prevention_measures_not_implemented_from: undefined,
      abuse_prevention_measures_not_implemented_to: undefined,
      information_disclosure_not_reported_flg: undefined,
      information_disclosure_not_reported_from: undefined,
      information_disclosure_not_reported_to: undefined,
      work_continuation_not_planning_flg: undefined,
      work_continuation_not_planning_from: undefined,
      work_continuation_not_planning_to: undefined,
      origin_local_gov_flg: after.facility.origin_local_gov_flg,
      see_hear_team_flg: after.facility.see_hear_team_flg,

      master_subordinate_flg: undefined,
      // multiple_facility_flgを毎回送信しているため
      // 関係のあるmaster_flgとtotal_capacityも毎回送信する
      master_flg: after.facility.master_flg,
      total_capacity: after.facility.total_capacity,
      specific_better_supporter_condition_202404: undefined,
      specific_better_supporter_condition: undefined,
      better_supporter_condition_base_up_flg: undefined
    },
    facility_shuroteichaku: {
      rate_get_job_202104: undefined,
      workhardenes_result_flg: undefined,
      workplace_adaptation_assistant_flg: undefined
    }
  };

  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${before[key][param]}` !== `${after[key][param]}`) {
          target[key][param] = after[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });

  // 虐待防止措置未実施減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.abuse_prevention_measures_not_implemented_flg !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_from !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_to !== undefined
  ) {
    target.facility.abuse_prevention_measures_not_implemented_flg =
      after.facility.abuse_prevention_measures_not_implemented_flg;
    target.facility.abuse_prevention_measures_not_implemented_from =
      after.facility.abuse_prevention_measures_not_implemented_from;
    target.facility.abuse_prevention_measures_not_implemented_to =
      after.facility.abuse_prevention_measures_not_implemented_to;
  }
  // 業務継続計画未策定減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.work_continuation_not_planning_flg !== undefined ||
    target.facility.work_continuation_not_planning_from !== undefined ||
    target.facility.work_continuation_not_planning_to !== undefined
  ) {
    target.facility.work_continuation_not_planning_flg =
      after.facility.work_continuation_not_planning_flg;
    target.facility.work_continuation_not_planning_from =
      after.facility.work_continuation_not_planning_from;
    target.facility.work_continuation_not_planning_to =
      after.facility.work_continuation_not_planning_to;
  }
  // 情報公表未報告減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.information_disclosure_not_reported_flg !== undefined ||
    target.facility.information_disclosure_not_reported_from !== undefined ||
    target.facility.information_disclosure_not_reported_to !== undefined
  ) {
    target.facility.information_disclosure_not_reported_flg =
      after.facility.information_disclosure_not_reported_flg;
    target.facility.information_disclosure_not_reported_from =
      after.facility.information_disclosure_not_reported_from;
    target.facility.information_disclosure_not_reported_to =
      after.facility.information_disclosure_not_reported_to;
  }

  return target;
};

export const normalizeFormValue = (
  state: FacilityValues
): PostFacilityParams => {
  const postFacilityParams: PostFacilityParams = {
    facility: {
      gov_business_owner: state.basic.corporationName,
      gov_facility_number: state.basic.officeNumber,
      name: state.basic.officeName,
      type_service: state.basic.serviceType,
      responsible_person: state.basic.representativeName,
      capacity: null,
      multiple_facility_flg: state.basic.multiFunctionOfficeFlag
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_subordinate_flg: state.basic.masterSubordinateFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_flg: state.basic.masterFlg,
      total_capacity:
        state.basic.multiFunctionOfficeFlag && state.basic.allCapacity !== ""
          ? parseInt(state.basic.allCapacity, 10)
          : null,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      tel: state.basic.tel,
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: "",
      welfare_condition_end_date: "",
      better_supporter_condition: state.additionalItem.staffTreatmentSystemType,
      lack_of_supporter_flg: state.subtractionItem.lackOfLifeSupportMemberFlag
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      date_start_lack_of_supporter: state.subtractionItem
        .lackOfLifeSupportMemberFlag
        ? selectDateValueToDate(
            state.subtractionItem.lackOfLifeSupportMemberStartDate
          )
        : null,
      lack_of_service_admin_flg: state.subtractionItem
        .lackOfResponsiblePersonFlag
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      date_start_lack_of_service_admin: state.subtractionItem
        .lackOfResponsiblePersonFlag
        ? selectDateValueToDate(
            state.subtractionItem.lackOfResponsiblePersonStartDate
          )
        : null,
      abuse_prevention_measures_not_implemented_flg: state.subtractionItem
        .abusePreventionMeasuresNotImplementedFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      abuse_prevention_measures_not_implemented_from:
        state.subtractionItem.abusePreventionMeasuresNotImplementedFrom &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
            )
          : null,
      abuse_prevention_measures_not_implemented_to:
        state.subtractionItem.abusePreventionMeasuresNotImplementedTo &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedTo
            )
          : null,
      information_disclosure_not_reported_flg: state.subtractionItem
        .informationDisclosureNotReportedFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      information_disclosure_not_reported_from:
        state.subtractionItem.informationDisclosureNotReportedFrom &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedFrom
            )
          : null,
      information_disclosure_not_reported_to:
        state.subtractionItem.informationDisclosureNotReportedTo &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedTo
            )
          : null,
      work_continuation_not_planning_flg: state.subtractionItem
        .workContinuationNotPlanningFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      work_continuation_not_planning_from:
        state.subtractionItem.workContinuationNotPlanningFrom &&
        selectDateValueToDate(
          state.subtractionItem.workContinuationNotPlanningFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.workContinuationNotPlanningFrom
            )
          : null,
      work_continuation_not_planning_to:
        state.subtractionItem.workContinuationNotPlanningTo &&
        selectDateValueToDate(
          state.subtractionItem.workContinuationNotPlanningTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.workContinuationNotPlanningTo
            )
          : null,

      origin_local_gov_flg: "",
      see_hear_team_flg: "",
      specific_better_supporter_condition_202404:
        state.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        state.additionalItem.specificBetterSupporterCondition202404Sub
          ? state.additionalItem.specificBetterSupporterCondition202404Sub
          : state.additionalItem.specificBetterSupporterCondition202404,
      specific_better_supporter_condition: state.additionalItem
        .specificBetterSupporterCondition
        ? STRING_TRUE_FROM_API_2
        : STRING_FALSE_FROM_API_1,
      better_supporter_condition_base_up_flg: state.additionalItem
        .betterSupporterConditionBaseUpFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API
    },
    facility_shuroteichaku: {
      rate_get_job_202104:
        state.subtractionItem.rateGetJob !== ""
          ? parseInt(state.subtractionItem.rateGetJob, 10)
          : 0,
      workhardenes_result_flg: state.additionalItem.workHardenesResultFlag
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      workplace_adaptation_assistant_flg: state.additionalItem
        .workPlaceAdaptationAssistantFlag
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  };
  return removeUnnecessaryValue(postFacilityParams);
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues
): PostFacilityParams => {
  return removeNoChangeData(params, normalizeFormValue(response));
};
