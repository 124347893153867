import * as React from "react";
import { AdminTemplate202104 } from "@components/templates/AdminTemplate202104";
import FacilityForm from "@components/v202104/organisms/mgr/IAB/facility/FacilityForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { INVOICE_PATHS } from "@constants/mgr/IAB/variables";
import {
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_OPTIONS_123
} from "@constants/variables";

/**
 * 事業所情報
 */
export const Facility202104 = (): JSX.Element => (
  <AdminTemplate202104
    pageName="事業所情報"
    invoiceVersion={{
      currentVersion: INVOICE_VERSION_V202104,
      versionPaths: INVOICE_PATHS.facility,
      versionOptions: INVOICE_VERSION_OPTIONS_123
    }}
  >
    <FacilityForm />
    <NavigationTransitionPrompt />
  </AdminTemplate202104>
);
