import React from "react";
import { RouteComponentProps } from "react-router-dom";

import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import AdminTemplate from "@components/templates/AdminTemplate";
import { MunicipalityForm } from "@components/organisms/mgr/IDOSHIEN/Municipality/MunicipalityForm";

type OwnProps = RouteComponentProps<{}>;
type Props = OwnProps;

/**
 * 自治体情報新規作成画面
 */
const MunicipalityNewCore = (props: Props): JSX.Element => {
  return (
    <AdminTemplate pageName="自治体情報">
      <MunicipalityForm history={props.history} />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

export const MunicipalityNew = MunicipalityNewCore;
