import * as React from "react";

import { GetFacilityUsersResponseElement } from "@api/requests/facility/getFacilityUsers";
import { ErrorsState } from "@stores/domain/errors/types";
import ErrorIcon from "@material-ui/icons/Error";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import ClassNames from "classnames";
import {
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  KEIKAKU_MONITORING_KAIGI_KIROKU,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { RecordEachUserButtonsType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    ul: {
      listStyle: "none",
      padding: 0,
      marginTop: 8,
      marginBottom: 0
    },
    li: {
      display: "flex",
      "&:nth-child(2n)": {
        backgroundColor: "#f5f5f5"
      },
      "&:last-child": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
      }
    },
    userName: {
      margin: "21px auto 0 16px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    recordButton: {
      fontSize: 14,
      width: "auto",
      minWidth: "120px",
      height: 36,
      color: "#0277bd",
      backgroundColor: "#ffffff",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      margin: "14px 0 14px 8px"
    },
    buttonMargin: {
      marginRight: 16
    },
    alertPeriod: {
      color: "#f44336",
      fontSize: 12,
      position: "relative",
      left: "-10px"
    },
    keikakuMonitoringAlertPeriod: {
      position: "relative"
    },
    keikakuMonitoringAlertIcon: {
      width: 14,
      height: 14,
      color: "#ffc416",
      position: "absolute",
      zIndex: 1,
      top: -7,
      right: -4,
      transform: "translateY(14px)"
    },
    syougaijiLabel: {
      width: "47px",
      height: "20px",
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.25px",
      textAlign: "center",
      color: "#3daa8d",
      margin: "auto 6px auto 0px"
    },
    alertWrapper: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 5
    },
    alertIconUpper: {
      width: 15,
      height: 15,
      color: "#ff5656",
      position: "absolute",
      top: 7,
      left: -10
    }
  });

type OwnProps = {
  user: GetFacilityUsersResponseElement;
  errorState?: ErrorsState["records"]["data"];
  buttonList: (uifId: number) => RecordEachUserButtonsType;
  facilitySubType?: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const RecordUserRowCore = (props: Props): JSX.Element => {
  const { classes, user, errorState, facilitySubType, buttonList } = props;

  return (
    <li key={user.uif_id} className={classes.li}>
      <span className={classes.userName}>{user.displayName}</span>
      {facilitySubType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN &&
        user.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN && (
          <span className={classes.syougaijiLabel}>障害児</span>
        )}
      {buttonList(user.uif_id).map((b) => {
        if (b.name === "個別支援計画") {
          return (
            <div
              key={`button-${user.uif_id}-${b.name}`}
              className={classes.alertPeriod}
            >
              {errorState &&
                errorState.length > 0 &&
                errorState[0].errors.map((error) => {
                  if (error.relation.value === user.uif_id) {
                    return (
                      <span
                        className={classes.alertWrapper}
                        key={error.relation.key}
                      >
                        <ErrorIcon className={classes.alertIconUpper} />
                      </span>
                    );
                  }
                  return null;
                })}
              <Button className={classes.recordButton} href={b.url}>
                {b.name}
              </Button>
            </div>
          );
        }
        if (b.name === KEIKAKU_MONITORING_KAIGI_KIROKU) {
          return (
            <div
              key={`button-${user.uif_id}-${b.name}`}
              className={classes.keikakuMonitoringAlertPeriod}
            >
              {user.monitoring_not_create ? (
                <ErrorIcon
                  className={classes.keikakuMonitoringAlertIcon}
                  key={user.uif_id}
                />
              ) : null}
              <Button className={classes.recordButton} href={b.url}>
                {b.name}
              </Button>
            </div>
          );
        }
        return (
          <Button
            key={`button-${user.uif_id}-${b.name}`}
            className={ClassNames(classes.recordButton, {
              [classes.buttonMargin]: b.isLastButton
            })}
            href={b.url}
          >
            {b.name}
          </Button>
        );
      })}
    </li>
  );
};

export const RecordUserRow = withStyles(styles)(RecordUserRowCore);
