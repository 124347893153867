import { Dispatch } from "redux";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as dailyActions from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/actions";
import * as staffActions from "@stores/domain/staff/actions";
import getOperationsAndSupports from "@api/requests/operations/TANKINYUSHO/getOperationsAndSupports";
import staffApi from "@api/requests/staff";
import facilityApi from "@api/requests/facility";
import {
  getFacilityActions,
  getUserInFacilityActions
} from "@stores/domain/mgr/common/actions";
import { getNormalizeGetFacilityResult } from "@stores/domain/mgr/common/normalizer";
import { FacilityType } from "@constants/variables";
import { FacilityState as FacilityStateIAB } from "@stores/domain/mgr/IAB/facility/types";
import { FacilityState as FacilityStateSEIKATSUKAIGO } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/types";
import { FacilityState as FacilityStateSHUROTEICHAKU } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { FacilityState as FacilityStateJIRITSUKUNRENSEIKATSU } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { FacilityState as FacilityStateGroupHome } from "@stores/domain/mgr/GroupHome/facility/types";
import { FacilityState as FacilityStateSHISETSUNYUSHO } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import { FacilityState as FacilityStateTANKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/facility/types";
import * as customRecordsActions from "@stores/domain/customRecords/actions";
import getCustomRecordsAPI from "@api/requests/customRecords";
import {
  RecordOperationValue,
  RecordSupportsValues
} from "@initialize/mgr/TANKINYUSHO/record/dailyRecord/initialValues";
import {
  normalizeFormValuesToPostCustomOperationParams,
  normalizeFormValuesToPostCustomSupportParams
} from "@stores/pages/record/dailyTANKINYUSHO/normalizer";
import supportsApi from "@api/requests/supports";
import dailyOperationsAndSupportsDispatcher from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/dispatcher";
import * as actions from "@stores/pages/record/daily/actions";
import operationsTANKINYUSHOApi from "@api/requests/operations/TANKINYUSHO";

type FacilityState = FacilityStateIAB &
  FacilityStateSEIKATSUKAIGO &
  FacilityStateSHUROTEICHAKU &
  FacilityStateJIRITSUKUNRENSEIKATSU &
  FacilityStateGroupHome &
  FacilityStateTANKINYUSHO &
  FacilityStateSHISETSUNYUSHO;

/**
 * 日々の記録初期取得（短期入所）
 */
export const fetchInitialDailyRecord = (dispatch: Dispatch) => async (
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());

  const facility = await facilityApi.getFacility().catch((e) => {
    dispatch(responseErrorActions.setResponseError(e.response));
    dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    dispatch(loadingActions.loadDone());
  });
  if (facility === undefined) {
    return;
  }
  const typeService = facility.data.data.facility.type_service as FacilityType;
  const facilityActions = getFacilityActions(typeService);
  const userInFacilityActions = getUserInFacilityActions(typeService);
  dispatch(dailyActions.fetchDailyOperationsAndSupportsStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(userInFacilityActions.fetchStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());
  dispatch(facilityActions.fetchStarted());
  const getFacilityData = getNormalizeGetFacilityResult(typeService)(
    facility.data
  );
  dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));

  Promise.all([
    getOperationsAndSupports(yyyymmdd),
    staffApi.getStaff(),
    facilityApi.getFacilityUsers(),
    getCustomRecordsAPI.getCustomRecords()
  ])
    .then((results) => {
      dispatch(
        dailyActions.fetchDailyOperationsAndSupportsSuccess(
          results[0].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[1].data));
      dispatch(userInFacilityActions.fetchSuccess(results[2].data));
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[3].data.data)
      );
    })
    .catch((e) => {
      dispatch(
        dailyActions.fetchDailyOperationsAndSupportsFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(userInFacilityActions.fetchFailed({ error: e.response }));
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 日々の支援記録更新（短期入所）
 */
const postCustomSupport = (dispatch: Dispatch) => async (
  yyyymmdd: string,
  uifId: number,
  params: RecordSupportsValues,
  initialValues: RecordSupportsValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  const normalizeParams = normalizeFormValuesToPostCustomSupportParams(
    params,
    initialValues,
    uifId
  );
  await supportsApi
    .postCustomSupport(`${uifId}`, yyyymmdd, normalizeParams)
    .then(async () => {
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await dailyOperationsAndSupportsDispatcher(
        dispatch
      ).fetchDailyOperationsAndSupports(yyyymmdd);
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 日々の業務日誌更新（短期入所）
 */
const postCustomOperation = (dispatch: Dispatch) => async (
  params: RecordOperationValue,
  initialValues: RecordOperationValue,
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  const normalizedParams = normalizeFormValuesToPostCustomOperationParams(
    params,
    initialValues
  );
  await operationsTANKINYUSHOApi
    .postCustomOperation(normalizedParams, yyyymmdd)
    .then(async () => {
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await dailyOperationsAndSupportsDispatcher(
        dispatch
      ).fetchDailyOperationsAndSupports(yyyymmdd);
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchInitialDailyRecord: (yyyymmdd: string) => Promise<void>;
  postCustomSupport: (
    yyyymmdd: string,
    uifId: number,
    params: RecordSupportsValues,
    initialValues: RecordSupportsValues
  ) => Promise<void>;
  postCustomOperation: (
    params: RecordOperationValue,
    initialValues: RecordOperationValue,
    yyyymmdd: string
  ) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchInitialDailyRecord: fetchInitialDailyRecord(dispatch),
  postCustomSupport: postCustomSupport(dispatch),
  postCustomOperation: postCustomOperation(dispatch)
});
