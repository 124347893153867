import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.PlanMonitoringMeetingState = {
  planMonitoringMeeting: {
    yearly: []
  }
};

export const CHIIKIIKOPlanMonitoringMeeting = (
  state = initialState,
  action: actions.ActionTypes
): types.PlanMonitoringMeetingState => {
  switch (action.type) {
    case types.FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_STARTED:
      return { ...state };
    case types.FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_SUCCESS:
      return { ...state, planMonitoringMeeting: action.payload };
    case types.FETCH_CHIIKIIKO_PLAN_MONITORING_MEETING_FAILED:
      return { ...state };
    default:
      return state;
  }
};
