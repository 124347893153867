import * as types from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/types";
import * as actions from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/actions";
import {
  DEFAULT_CHECKBOX_VALUE,
  STRING_TRUE_FROM_API
} from "@constants/variables";

const initialState: types.FacilityState = {
  corporationName: "",
  officeNumber: "",
  officeName: "",
  serviceType: "",
  representativeName: "",
  capacity: "",
  postalCode: "",
  selectedPrefectureName: "",
  selectedCityCode: "",
  restAddress: "",
  tel: "",
  multiFunctionOfficeFlag: DEFAULT_CHECKBOX_VALUE,
  masterSubordinateFlg: DEFAULT_CHECKBOX_VALUE,
  masterFlg: "1",

  cityId: "",
  allCapacity: "",
  mealSaservedServiceFlag: DEFAULT_CHECKBOX_VALUE,
  transferServiceFlag: DEFAULT_CHECKBOX_VALUE,
  transferServiceType: STRING_TRUE_FROM_API,
  seriousSupporterFlg: DEFAULT_CHECKBOX_VALUE,

  staffTreatmentSystemType: "",
  staffTreatmentSpecificSystemType: "",
  commuterLifeSupportFlag: DEFAULT_CHECKBOX_VALUE,

  specificFacilitiesAddition: "",
  betterSupporterConditionBaseUpFlg: DEFAULT_CHECKBOX_VALUE,
  lifeSupportHubInDistrictFlg: DEFAULT_CHECKBOX_VALUE,
  buildingManagementFlg: DEFAULT_CHECKBOX_VALUE,
  units: []
};

export const KYOTAKUKAIGOFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.FacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_FAILED:
      return { ...state };
    case types.POST_STARTED:
      return { ...state };
    case types.POST_SUCCESS:
      return { ...state };
    case types.POST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
