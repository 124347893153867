import * as types from "./types";
import * as actions from "./actions";
import { Checkbox, INT_FALSE_FROM_API } from "@constants/variables";

// first or reset values
const userDefaultValues: types.UsersInFacilityState["user"] = {
  user_in_facility: {
    none_recipient_number_flg: Checkbox.OFF,
    gender: "1"
  },
  user_in_facility_chiikiiko: {
    users_in_facility_id: 0,
    disability_class: INT_FALSE_FROM_API,
    special_area_flg: INT_FALSE_FROM_API,
    date_begin_special_area: "",
    date_end_special_area: "",
    create_support_plan_flg: INT_FALSE_FROM_API,
    date_end_not_create_support_plan: ""
  }
};

const initialState: types.UsersInFacilityState = {
  users: [],
  user: userDefaultValues,
  doneUpsertUser: false,
  userValidation: {}
};

export const CHIIKIIKOUserInFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.UsersInFacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, users: action.payload.data };
    case types.FETCH_FAILED:
      return { ...state };
    case types.FETCH_ONE_STARTED:
      return { ...state };
    case types.FETCH_ONE_SUCCESS:
      return { ...state, user: action.payload };
    case types.FETCH_ONE_FAILED:
      return { ...state };
    case types.CREATE_STARTED:
      return { ...state };
    case types.CREATE_SUCCESS:
      return { ...state };
    case types.CREATE_FAILED:
      return { ...state };
    case types.UPDATE_STARTED:
      return { ...state };
    case types.UPDATE_SUCCESS:
      return { ...state };
    case types.UPDATE_FAILED:
      return { ...state };
    case types.DELETE_STARTED:
      return { ...state };
    case types.DELETE_SUCCESS:
      return { ...state };
    case types.DELETE_FAILED:
      return { ...state };
    case types.CLEAR:
      return { ...state, user: userDefaultValues };
    default:
      return state;
  }
};
