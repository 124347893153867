import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import dispatches from "@stores/dispatches";
import { serviceDeliveryApi } from "@api/requests/serviceDelivery";
import {
  normalizeFormValuesToPostRecordDetailParams,
  normalizeDetailsRecordFromAPI
} from "./normalizer";
import { ServiceDeliveryUserType } from "./types";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import * as H from "history";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import { PrintRecordParams } from "@api/requests/serviceDelivery/getServiceDeliveryPrint";
import { FacilityType } from "@constants/variables";
import facilityApi from "@api/requests/facility";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";
import { normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/normalizer";
import { normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/normalizer";
import { normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/normalizer";
import { normalizedGetFacilityUserTargetIdResponse as normalizedGetFacilityUserTargetIdResponseKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/normalizer";

/**
 * サービス提供記録（日ごと）取得
 */
export const fetchDailyRecord = (dispatch: Dispatch) => async (
  yyyymmdd: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchDailyRecordStarted());
  await serviceDeliveryApi
    .getServiceDeliveryDaily(yyyymmdd)
    .then((res) => {
      dispatch(actions.fetchDailyRecordSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchDailyRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービス提供記録（月ごと）取得
 */
export const fetchMonthlyRecord = (dispatch: Dispatch) => async (
  usersInFacilityId: number,
  yyyymm: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchMonthlyRecordStarted());
  await serviceDeliveryApi
    .getServiceDeliveryMonthly(usersInFacilityId, yyyymm)
    .then((res) => {
      dispatch(actions.fetchMonthlyRecordSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchMonthlyRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービス提供記録詳細情報取得
 */
export const fetchDetailsRecord = (dispatch: Dispatch) => async (
  serviceDeliveryRecordsId: number | null,
  inoutResultsId: number | null,
  supportProcedureFormsId: number | null
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchDetailsRecordStarted());
  await serviceDeliveryApi
    .getServiceDeliveryDetails(
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    )
    .then((res) => {
      const normalizedResponse = normalizeDetailsRecordFromAPI(res.data.data);
      dispatch(actions.fetchDetailsRecordSuccess(normalizedResponse));
    })
    .catch((e) => {
      dispatch(actions.fetchDetailsRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービス提供記録新規登録・更新
 */
export const postRecordDetail = (dispatch: Dispatch) => async (
  params: ServiceDeliveryDetailValues,
  initialValues: ServiceDeliveryDetailValues,
  history: H.History,
  path: string | null,
  facilityType: FacilityType,
  isMobile: boolean,
  supportProcedureFormsId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postRecordDetailStarted());
  const targetDateValues = selectDateValueToDatePaddingZero(params.targetDate);
  if (!targetDateValues) {
    throw new Error("targetDate is required.");
  }
  const normalizeParams = normalizeFormValuesToPostRecordDetailParams(
    params,
    initialValues,
    targetDateValues,
    facilityType,
    isMobile,
    supportProcedureFormsId
  );

  await serviceDeliveryApi
    .postServiceDeliveryDetails(normalizeParams)
    .then(async () => {
      dispatch(actions.postRecordDetailSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 成功したら元の一覧画面に戻す
      dispatches.uiDispatch(dispatch).stopHistory(false);
      if (path) {
        history.push(path);
      }
    })
    .catch((e) => {
      dispatch(
        actions.postRecordDetailFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービス提供記録削除
 */
export const deleteServiceDelivery = (dispatch: Dispatch) => async (
  serviceDeliveryRecordsId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteStarted());
  await serviceDeliveryApi
    .deleteServiceDelivery(serviceDeliveryRecordsId)
    .then(() => {
      dispatch(actions.deleteSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(actions.deleteFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービス提供記録印刷情報取得
 */
export const fetchPrintRecord = (dispatch: Dispatch) => async (
  params: PrintRecordParams
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchPrintRecordStarted());
  await serviceDeliveryApi
    .getServiceDeliveryPrint({
      target: params.target,
      targetDate: params.targetDate,
      excludedUserIds: params.excludedUserIds,
      from: params.from,
      to: params.to,
      userIds: params.userIds
    })
    .then((res) => {
      dispatch(actions.fetchPrintRecordSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchPrintRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 種別ごとの利用者情報normalize化
const normalizedUserResponse = (
  data: GetFacilityUserTargetIdResponse["data"],
  facilityType: FacilityType
): ServiceDeliveryUserType => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return normalizedGetFacilityUserTargetIdResponseKYOTAKUKAIGO(data);
    case FacilityType.JUDOHOMONKAIGO:
      return normalizedGetFacilityUserTargetIdResponseJUDOHOMONKAIGO(data);
    case FacilityType.DOKOENGO:
      return normalizedGetFacilityUserTargetIdResponseDOKOENGO(data);
    case FacilityType.KODOENGO:
      return normalizedGetFacilityUserTargetIdResponseKODOENGO(data);
    default:
      return normalizedGetFacilityUserTargetIdResponseKYOTAKUKAIGO(data);
  }
};

/**
 * サービス提供記録利用者情報取得
 */
export const fetchUser = (dispatch: Dispatch) => async (
  id: string,
  facilityType: FacilityType
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchUserStarted());
  await facilityApi
    .getFacilityUserTargetId(id)
    .then((res) => {
      const normalizedData = normalizedUserResponse(
        res.data.data,
        facilityType
      );
      dispatch(actions.fetchUserSuccess(normalizedData));
    })
    .catch((e) => {
      dispatch(actions.fetchUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchDailyRecord: (yyyymmdd: string) => Promise<void>;
  fetchMonthlyRecord: (
    usersInFacilityId: number,
    yyyymm: string
  ) => Promise<void>;
  fetchDetailsRecord: (
    serviceDeliveryRecordsId: number | null,
    inoutResultsId: number | null,
    supportProcedureFormsId: number | null
  ) => Promise<void>;
  postRecordDetail: ReturnType<typeof postRecordDetail>;
  deleteServiceDelivery: (serviceDeliveryRecordsId: number) => Promise<void>;
  fetchPrintRecord: (params: PrintRecordParams) => Promise<void>;
  fetchUser: (id: string, facilityType: FacilityType) => Promise<void>;
};

export const serviceDeliveryDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchDailyRecord: fetchDailyRecord(dispatch),
  fetchMonthlyRecord: fetchMonthlyRecord(dispatch),
  fetchDetailsRecord: fetchDetailsRecord(dispatch),
  postRecordDetail: postRecordDetail(dispatch),
  deleteServiceDelivery: deleteServiceDelivery(dispatch),
  fetchPrintRecord: fetchPrintRecord(dispatch),
  fetchUser: fetchUser(dispatch)
});
