import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { InOutReportMonthly } from "@components/organisms/mgr/DOKOENGO/report/InOutReportMonthly";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST,
  INVOICE_PATHS
} from "@constants/mgr/DOKOENGO/variables";
import * as URL from "@constants/url";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { FacilityType, INVOICE_VERSION_CURRENT } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

type Props = RouteComponentProps<{}, {}, { targetDate: Date }>;

/**
 * 利用実績
 */
export const ReportMonthly = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();

  // 報酬改定のバージョン変更が必要だったら変更後のページを表示
  const currentPageVersion = INVOICE_VERSION_CURRENT;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.DOKOENGO,
    currentPageVersion,
    INVOICE_PATHS.reportUsers,
    props.history
  );

  if (transitionDone) {
    return null;
  }

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.DAILY) {
      props.history.push(URL.REPORT_DAILY);
    }
  };
  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LiST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.USERS}
      />
      <InOutReportMonthly
        history={props.history}
        initialDate={currentDate}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate>
  );
};
