import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import { FormikProps } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { AppState } from "@stores/type";
import { SupportsState } from "@stores/domain/supports/types";
import dispatches from "@stores/dispatches";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import selectDateValueToDatePaddingZeroWithoutDay from "@utils/date/selectDateValueToDatePaddingZeroWithoutDay";
import { dateToSelectDateValue } from "@utils/date";

import { RecordSelectMonth } from "@components/organisms/mgr/common/record/RecordSelectMonth";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: "32px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      marginTop: 16
    },
    categoryLabel: {
      margin: 0,
      paddingBottom: "4px",
      borderBottom: "1px solid #37474f",
      fontSize: "16px"
    },
    itemLabel: {
      margin: "32px 0 0",
      padding: "5px 0 5px 8px",
      borderRadius: "2px",
      backgroundColor: "#f5f5f5",
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: 1.75
    },
    message: {
      margin: "32px 0",
      padding: "5px 0 5px 8px",
      color: "#37474f"
    },
    dateArea: {
      display: "flex",
      marginTop: "32px",
      gap: "16px"
    },
    description: {
      marginTop: "32px",
      fontSize: "16px",
      lineHeight: 1.75,
      color: "#37474f"
    },
    reportTable: {
      width: "100%"
    },
    reportTableHeaderCell: {
      paddingRight: "32px",
      "&.-date": {
        width: "170px",
        paddingLeft: "16px"
      },
      "&.-content": {
        minWidth: "516px"
      },
      "&.-reporter": {
        width: "169px"
      },
      "&.-check": {
        width: "124px"
      }
    },
    reportTableBodyCell: {
      paddingRight: "32px",
      verticalAlign: "middle",
      "&.-date": {
        width: "156px",
        paddingLeft: "16px"
      }
    },
    noItemMessage: {
      margin: "16px 0 16px 16px",
      fontSize: "16px"
    }
  });

type StateProps = {
  simpleRecords: SupportsState["simpleRecords"];
};
type DispatchProps = {
  fetchSupportSimpleReports: (
    uifId: string,
    fromMonth: string,
    toMonth: string
  ) => Promise<void>;
};

type OwnProps = {
  isEditing: boolean;
  uifId: string;
  formikProps: FormikProps<SupportLedger>;
  formValues: SupportLedger;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const SupportLedgerSupportProgressCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  // 初回のみAPIから取得した値に対応したチェック状態にする必要がある。
  // ２回目以降（変更後）はチェック状態は常にtrue
  const [isSetFirstTimeToField, setIsSetFirstTimeToField] = React.useState(
    true
  );
  const [isCompletedFetchReport, setIsCompletedFetchReport] = React.useState(
    false
  );
  const [isCompletedSetValues, setIsCompletedSetValues] = React.useState(false);

  React.useEffect(() => {
    if (
      props.formValues.support_ledger.is_ignore_print_support_ids &&
      isCompletedFetchReport
    ) {
      if (!props.isEditing || isSetFirstTimeToField) {
        setIsSetFirstTimeToField(false);
        const ignoreIds =
          props.formValues.support_ledger.is_ignore_print_support_ids;
        const recordIds = props.simpleRecords.support_record_consultations.reduce(
          (prev, record) => ({
            ...prev,
            [record.id]: !ignoreIds.includes(record.id)
          }),
          {}
        );
        props.formikProps.setFieldValue(
          "support_ledger.isPrintSupportIds",
          recordIds
        );
      } else {
        const recordIds = props.simpleRecords.support_record_consultations.reduce(
          (prev, record) => ({ ...prev, [record.id]: true }),
          {}
        );
        props.formikProps.setFieldValue(
          "support_ledger.isPrintSupportIds",
          recordIds
        );
      }
      setIsCompletedSetValues(true);
    }
  }, [
    props.formValues.support_ledger.is_ignore_print_support_ids,
    props.simpleRecords.support_record_consultations,
    isCompletedFetchReport
  ]);

  React.useEffect(() => {
    const fromDate = selectDateValueToDatePaddingZeroWithoutDay(
      props.formValues.support_ledger.date_begin_in_support
    );
    const toDate = selectDateValueToDatePaddingZeroWithoutDay(
      props.formValues.support_ledger.date_end_in_support
    );
    if (fromDate && toDate && !(fromDate > toDate)) {
      setIsCompletedSetValues(false);
      props
        .fetchSupportSimpleReports(props.uifId, fromDate, toDate)
        .then(() => {
          setIsCompletedFetchReport(true);
        });
    }
  }, [
    props.formValues.support_ledger.date_begin_in_support,
    props.formValues.support_ledger.date_end_in_support
  ]);

  const displaySupportContent = (
    supportContent: string | null,
    isEditing: boolean
  ): string | "" => {
    // 編集モードで冒頭20文字以降に入力がある場合は末に「…」を表示
    if (isEditing && supportContent && supportContent.length > 20) {
      return `${supportContent.substring(0, 20)}...`;
    }
    return supportContent || "";
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.categoryLabel}>支援経過</p>
      <p className={classes.itemLabel}>本台帳に係る支援期間</p>
      <div className={classes.dateArea}>
        <RecordSelectMonth
          label="支援開始年月"
          isEditable={props.isEditing}
          name="support_ledger.date_begin_in_support"
          value={props.formValues.support_ledger.date_begin_in_support}
        />
        <RecordSelectMonth
          label="支援終了年月"
          isEditable={props.isEditing}
          name="support_ledger.date_end_in_support"
          value={props.formValues.support_ledger.date_end_in_support}
          addYearTo={1}
        />
      </div>
      <p className={classes.itemLabel}>支援記録</p>
      <p className={classes.message}>
        帳票に表示がチェックされた項目が印刷されます。印刷しない支援記録はチェックを外してください。
      </p>
      <KnowbeTable className={classes.reportTable}>
        <KnowbeTableHead
          height={40}
          backgroundColor="secondary"
          uniqueKey="supportProgressHeader"
          items={[
            {
              label: "実施日",
              className: classNames(classes.reportTableHeaderCell, "-date")
            },
            {
              label: "支援内容",
              className: classNames(classes.reportTableHeaderCell, "-content")
            },
            {
              label: "記録者",
              className: classNames(classes.reportTableHeaderCell, "-reporter")
            },
            {
              label: "帳票に表示",
              className: classNames(classes.reportTableHeaderCell, "-check")
            }
          ]}
        />
        {isCompletedSetValues &&
        props.simpleRecords.support_record_consultations.length ? (
          <KnowbeTableBody
            uniqueKey="supportProgressBody"
            itemsContainer={props.simpleRecords.support_record_consultations.map(
              (record) => [
                {
                  label: `${dateToSelectDateValue(record.target_date).year}年${
                    dateToSelectDateValue(record.target_date).month
                  }月${dateToSelectDateValue(record.target_date).day}日`,
                  className: classNames(classes.reportTableBodyCell, "-date")
                },
                {
                  label: displaySupportContent(
                    record.support_content,
                    props.isEditing
                  ),
                  className: classes.reportTableBodyCell
                },
                {
                  label: record.staffs
                    ? record.staffs.map((s) => s.staff_name).join("、")
                    : "",
                  className: classes.reportTableBodyCell
                },
                {
                  label: (
                    <FormikCheckbox
                      name={`support_ledger.isPrintSupportIds[${record.id}]`}
                      label=""
                      style={{ margin: 0 }}
                      disabled={!props.isEditing}
                    />
                  ),
                  className: classes.reportTableBodyCell
                }
              ]
            )}
          />
        ) : null}
      </KnowbeTable>
      {isCompletedSetValues &&
      props.simpleRecords.support_record_consultations.length ? null : (
        <p className={classes.noItemMessage}>支援記録がありません。</p>
      )}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  simpleRecords: state.supports.simpleRecords
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportsDispatcher } = dispatches;
  const supportsDispatches = supportsDispatcher(dispatch);
  return {
    fetchSupportSimpleReports: (uifId, fromMonth, toMonth): Promise<void> => {
      return supportsDispatches.fetchSupportSimpleReports(
        uifId,
        fromMonth,
        toMonth
      );
    }
  };
};

/**
 * 地域定着台帳の支援経過エリア
 */
export const SupportLedgerSupportProgress = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportLedgerSupportProgressCore)
);
