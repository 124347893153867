import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";

export const FirstInvoiceDataFields = (): JSX.Element => {
  return (
    <FormPaper>
      <div style={{ marginBottom: 18 }}>
        <SectionTitle label="初回請求月の指定" />
      </div>
      <p style={{ color: "#37474f" }}>
        knowbeでの初回の請求作業の対象となる月を入力して下さい。
      </p>
      <div>
        <FormikSelectYearMonth
          name="initialData.facility.first_time_bill_date"
          label="初回請求年月"
          addYearTo={1}
          style={{ marginBottom: 4 }}
          required
        />
      </div>
    </FormPaper>
  );
};
