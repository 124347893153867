import { STRING_TRUE_FROM_API } from "@constants/variables";
import { MunicipalityValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { validateSwitcher, validateMarge } from "@validator";

export const validation = (
  values: MunicipalityValues
): ValidationErrors<MunicipalityValues> => {
  return {
    name: validator(values.name, "required"),
    roundUpMinute: validateSwitcher(
      values.calculationTimeFlag,
      validator(values.roundUpMinute, "required") ||
        validateMarge(
          values.roundUpMinute,
          [
            "naturalNumber",
            {
              type: "lowerLimit",
              lowerLimit: 1
            },
            {
              type: "upperLimit",
              upperLimit: 29
            }
          ],
          "1～29で入力してください"
        )
    ),
    unitPricePerUnit: validateSwitcher(
      values.billingUnit === STRING_TRUE_FROM_API,
      validator(
        values.unitPricePerUnit,
        "required",
        {
          type: "decimalPointWithMessage",
          message: "数字を入力してください"
        },
        {
          type: "numberOfDigits",
          integerDigitsLimit: 3,
          decimalDigitsLimit: 2
        }
      )
    )
  };
};
