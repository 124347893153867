import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikTextField from "@components/molecules/FormikTextField";
import { FacilityValues } from "@initialize/mgr/KEIKAKUSODAN/facility/initialValues";
import { FormikProps } from "formik";

type Props = {
  facility: FacilityState;
  formikProps: FormikProps<FacilityValues>;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  const isKeikaku = props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    props.facility.facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "facilityInfoKeikaku.lifeSupportHubInDistrictFlg":
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.lifeSupportFunctionPromotionFlg",
            false
          );
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        case "facilityInfoKeikaku.lifeSupportFunctionPromotionFlg":
          props.formikProps.setFieldValue(
            "facilityInfoKeikaku.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        case "facilityInfoShogaiji.lifeSupportHubInDistrictFlg":
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.lifeSupportFunctionPromotionFlg",
            false
          );
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        case "facilityInfoShogaiji.lifeSupportFunctionPromotionFlg":
          props.formikProps.setFieldValue(
            "facilityInfoShogaiji.lifeSupportFunctionPromotionTimes",
            ""
          );
          break;
        default:
      }
    }
  };
  return (
    <>
      {(isKeikaku || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="加算対象項目（計画相談支援）" />
          </div>
          <FormGroup>
            <FormikRadioButtons
              name="facilityInfoKeikaku.needMedicalCareHandicappedChildSupport202404"
              label="要医療児者支援体制"
              options={NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT_LIST}
            />
            <FormikCheckbox
              name="facilityInfoKeikaku.peerSupportFlg"
              label="ピアサポート体制"
            />
            <FormikSwitch
              name="facilityInfoKeikaku.lifeSupportHubInDistrictFlg"
              label="地域生活支援拠点等"
              onChange={onChangeSwitch}
              style={{ marginBottom: 0 }}
            >
              <FormikSwitch
                name="facilityInfoKeikaku.lifeSupportFunctionPromotionFlg"
                label="地域生活支援拠点等機能強化加算"
                onChange={onChangeSwitch}
              >
                <FormikTextField
                  name="facilityInfoKeikaku.lifeSupportFunctionPromotionTimes"
                  label="算定回数"
                  placeholder="1"
                  endAdornmentLabel="回"
                />
              </FormikSwitch>
            </FormikSwitch>
          </FormGroup>
        </FormPaper>
      )}
      {(isShogaiji || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="加算対象項目（障害児相談支援）" />
          </div>
          <FormGroup>
            <FormikRadioButtons
              name="facilityInfoShogaiji.needMedicalCareHandicappedChildSupport202404"
              label="要医療児者支援体制"
              options={NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT_LIST}
            />
            <FormikCheckbox
              name="facilityInfoShogaiji.peerSupportFlg"
              label="ピアサポート体制"
            />
            <FormikSwitch
              name="facilityInfoShogaiji.lifeSupportHubInDistrictFlg"
              label="地域生活支援拠点等"
              onChange={onChangeSwitch}
              style={{ marginBottom: 0 }}
            >
              <FormikSwitch
                name="facilityInfoShogaiji.lifeSupportFunctionPromotionFlg"
                label="地域生活支援拠点等機能強化加算"
                onChange={onChangeSwitch}
              >
                <FormikTextField
                  name="facilityInfoShogaiji.lifeSupportFunctionPromotionTimes"
                  label="算定回数"
                  placeholder="1"
                  endAdornmentLabel="回"
                />
              </FormikSwitch>
            </FormikSwitch>
          </FormGroup>
        </FormPaper>
      )}
    </>
  );
};
