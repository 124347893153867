import { PostMeetingRecord } from "@api/requests/meetingRecord/postMeetingRecord";
import { MeetingRecordValues } from "@initialize/mgr/Cseg/record/meetingRecord/initialValues";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEmpty from "lodash-es/isEmpty";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import castStringToNumber from "@utils/dataNormalizer/castStringToNumber";

const removeUndefinedParam = <T extends {}>(
  data: T,
  requiredKey?: string[]
): T => {
  const targetParam = {} as T;

  // 必須キーを除くundefinedのparamを除去
  Object.keys(data).forEach((param) => {
    if (
      (requiredKey && requiredKey.includes(param)) ||
      data[param] !== undefined
    ) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

const getTime = <T>(time?: T | null): string | null | undefined => {
  if (time !== undefined) {
    if (time) {
      return `${time}:00`;
    }
    return null;
  }
  return undefined;
};

export const normalizePostMeetingRecordDataToAPI = (
  param: MeetingRecordValues,
  initialValues?: MeetingRecordValues
): PostMeetingRecord => {
  let normalizedValue: PostMeetingRecord["meeting_record"];

  // 新規作成
  if (!initialValues) {
    normalizedValue = {
      meeting_records_id: null,
      creation_date:
        selectDateValueToDatePaddingZero(param.creation_date) || "",
      creation_staff_id: castStringToNumber(param.creation_staff) || null,
      meeting_date: selectDateValueToDatePaddingZero(param.meeting_date),
      meeting_start_time: param.meeting_start_time
        ? `${param.meeting_start_time}:00`
        : null,
      meeting_end_time: param.meeting_end_time
        ? `${param.meeting_end_time}:00`
        : null,
      meeting_place: isEmpty(param.meeting_place) ? null : param.meeting_place,
      section: castStringToNumber(param.section),
      attendees: param.attendees,
      consideration_item: isEmpty(param.consideration_item)
        ? null
        : param.consideration_item,
      consideration_contents: isEmpty(param.consideration_contents)
        ? null
        : param.consideration_contents,
      consideration_results: isEmpty(param.consideration_results)
        ? null
        : param.consideration_results,
      others: isEmpty(param.others) ? null : param.others,
      type_consultation: param.type_consultation
    };
  } else {
    // 編集
    const res = omitByNoChanges(param, initialValues);
    normalizedValue = {
      meeting_records_id: param.meeting_records_id,
      creation_date:
        selectDateValueToDatePaddingZero(param.creation_date) || "",
      creation_staff_id:
        res.creation_staff !== undefined
          ? castStringToNumber(param.creation_staff) || null
          : undefined,
      meeting_date:
        res.meeting_date !== undefined
          ? selectDateValueToDatePaddingZero(param.meeting_date)
          : undefined,
      meeting_start_time: getTime(res.meeting_start_time),
      meeting_end_time: getTime(res.meeting_end_time),
      meeting_place:
        res.meeting_place !== undefined ? param.meeting_place : undefined,
      section:
        res.section !== undefined
          ? castStringToNumber(param.section)
          : undefined,
      attendees: res.attendees !== undefined ? param.attendees : undefined,
      consideration_item:
        res.consideration_item !== undefined
          ? param.consideration_item
          : undefined,
      consideration_contents:
        res.consideration_contents !== undefined
          ? param.consideration_contents
          : undefined,
      consideration_results:
        res.consideration_results !== undefined
          ? param.consideration_results
          : undefined,
      others: res.others !== undefined ? param.others : undefined
    };
  }
  return {
    meeting_record: removeUndefinedParam(normalizedValue, [
      "meeting_records_id",
      "creation_date"
    ])
  };
};
