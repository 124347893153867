import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportRecordUserParams = {
  support_records: {
    id: number | null;
    usage_results_id: number | null;
    creation_date?: string | null;
    company_name?: string;
    todays_purpose?: string;
    life_current_issues?: string;
    life_support_content?: string;
    office_current_issues?: string;
    office_support_content?: string;
    usage_status_of_related_organizations?: string;
    notices?: string;
    staff_comment?: string;
    next_support_date?: string;
    next_support_method?: string;
    next_support_plans?: string;
    author?: number | null;
    author_name?: string | null;
  }[];
};

/**
 * 支援記録を更新をする
 * @param uifId 事業所所属ユーザーのID
 * @param params 更新データ
 */
const postSupportRecordUser = async (
  uifId: string,
  params: PostSupportRecordUserParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_records/users/${uifId}`;
  return request.post(url, params);
};

export default postSupportRecordUser;
