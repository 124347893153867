import {
  SupportRecordFormValues,
  OperationRecordFormValues
} from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator from "@validator";

export const supportValidation = (
  values: SupportRecordFormValues
): ValidationErrors<SupportRecordFormValues> => {
  return values.map((item) => {
    return {
      support_content: validator(item.support_content || "", {
        type: "checkCharacterLength",
        length: 2500
      }),
      staff_comment: validator(item.staff_comment || "", {
        type: "checkCharacterLength",
        length: 2500
      })
    };
  });
};

export const operationValidation = (
  values: OperationRecordFormValues
): ValidationErrors<OperationRecordFormValues> => {
  return {
    operation_in_the_morning: validator(values.operation_in_the_morning || "", {
      type: "checkCharacterLength",
      length: 2500
    }),
    operation_in_the_afternoon: validator(
      values.operation_in_the_afternoon || "",
      {
        type: "checkCharacterLength",
        length: 2500
      }
    ),
    other_comment: validator(values.other_comment || "", {
      type: "checkCharacterLength",
      length: 2500
    })
  };
};
