import React from "react";
import * as H from "history";
import { AssessmentState } from "@stores/domain/assessment/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Formik, Form, FormikActions } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import isEqual from "lodash-es/isEqual";

// components
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { AssessmentSheetForm } from "@components/organisms/mgr/IAB/record/AssessmentSheetForm";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import { AssessmentSheetPrintDialog } from "@components/organisms/mgr/IAB/record/AssessmentSheetPrintDialog";

// utils
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import * as assessmentActions from "@stores/domain/assessment/actions";
import {
  Assessment,
  initialValues
} from "@initialize/record/assessment/initialValues";
import { validation } from "@initialize/record/assessment/validation";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

import * as URL from "@constants/url";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  CUSTOM_RECORD_TARGET_TYPE,
  AB_ASSESSMENT_CATEGORY_TYPE,
  IKOU_ASSESSMENT_CATEGORY_TYPE
} from "@constants/variables";

import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    content: {
      background: "#fff",
      borderRadius: "4px"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    form: {
      background: "#fff",
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    author: {
      display: "flex",
      padding: "16px 32px 32px 32px"
    },
    authorName: {
      width: 240
    },
    authorRole: {
      width: 240,
      paddingLeft: "16px"
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    },
    deleteButton: {
      marginTop: 24,
      height: 0
    }
  });

type OwnProps = {
  userName: string;
  uifId: string;
  assessmentId: string;
  copyId: string;
  history: H.History;
  isNew: boolean;
  isView: boolean;
  isCopy: boolean;
  isEditing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  facilityType: FacilityType;
};

type StateProps = {
  needsStopHistory: boolean;
  customRecords: CustomRecordsWithCategoryState;
  assessment: AssessmentState["assessment"];
  staff: StaffState;
};

type DispatchProps = {
  fetchStaffs: () => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  postAssessment: (
    uifId: string,
    initialAssessment: Assessment,
    assessment: Assessment,
    history: H.History
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  unsetAssessment: () => void;
  deleteAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  stopHistory: (flag: boolean) => Promise<void>;
};

type MergeProps = {
  authorName: string;
  authorizerRole: string;
  staffOptions: FieldItem[];
  staffWithSnap: StaffState;
} & StateProps &
  DispatchProps;

type Props = OwnProps & MergeProps & WithStyles<typeof styles>;

/**
 * アセスメント一覧
 *
 */
const AssessmentSheetCore = (props: Props): JSX.Element | null => {
  const {
    needsStopHistory,
    userName,
    uifId,
    assessmentId,
    copyId,
    history,
    isNew,
    isView,
    isCopy,
    isEditing,
    setEditing,
    customRecords,
    assessment,
    staffWithSnap,
    authorName,
    staffOptions,
    fetchStaffs,
    fetchCustomRecords,
    fetchAssessment,
    postAssessment,
    unsetAssessment,
    deleteAssessment,
    classes,
    facilityType
  } = props;
  const [isLoading, setLoading] = React.useState(true); // 初回fetch終わったらfalse

  const [formValues, setFormValues] = React.useState(
    initialValues(assessment, customRecords, staffOptions, isCopy, {
      facilityType
    })
  );
  const [
    isOpenDeleteAssessmentModal,
    setOpenDeleteAssessmentModal
  ] = React.useState(false);

  const [
    isOpenPrintAssessmentModal,
    setIsOpenPrintAssessmentModal
  ] = React.useState(false);

  //  初回fetch
  React.useEffect(() => {
    unsetAssessment();
    setFormValues(
      initialValues(assessment, customRecords, staffOptions, isCopy, {
        facilityType
      })
    );
    const fetchFirstData = async (): Promise<void> => {
      if (isView || isCopy) {
        await fetchAssessment(uifId, assessmentId || copyId);
      }
      await fetchCustomRecords();
      await fetchStaffs();
    };
    fetchFirstData().then(() => {
      setLoading(false);
    });
  }, []);

  // fetch終了後
  React.useEffect(() => {
    setFormValues(
      initialValues(assessment, customRecords, staffOptions, isCopy, {
        facilityType
      })
    );
  }, [assessment, customRecords]);

  const onSubmit = async (
    values: Assessment,
    actions: FormikActions<Assessment>
  ): Promise<void> => {
    actions.setSubmitting(true);
    let initial: Assessment;
    if (isCopy) {
      initial = initialValues(undefined, undefined, undefined, undefined, {
        facilityType
      });
    } else if (isNew) {
      initial = initialValues(
        assessment,
        customRecords,
        staffOptions,
        undefined,
        { facilityType }
      );
      initial.assessment_records.forEach((r) => {
        r.input.forEach((v) => {
          if (v.work_histories) {
            v.work_histories.shift();
          }
        });
      });
    } else {
      initial = initialValues(
        assessment,
        customRecords,
        staffOptions,
        undefined,
        {
          facilityType
        }
      );
    }
    await postAssessment(uifId, initial, values, history);
    if (isView) {
      await setFormValues(values);
      await fetchAssessment(uifId, assessmentId);
      setEditing(false);
    }
    actions.setSubmitting(false);
  };

  const onClickPrint = (): void => {
    if (facilityType === FacilityType.IKOU) {
      setIsOpenPrintAssessmentModal(true);
    } else {
      const url = `/record/support_plan/${uifId}/assessment/${assessmentId}/print`;
      history.push(url);
    }
  };

  const onClickEdit = (): void => {
    setEditing(true);
  };

  const onClickDeleteAssessment = (): void => {
    setOpenDeleteAssessmentModal(true);
  };

  const onSubmitDeleteAssessment = async (): Promise<void> => {
    await deleteAssessment(uifId, assessmentId);
    props.stopHistory(false);
    props.showSnackbar({
      open: true,
      message: "削除が完了しました",
      variant: "success"
    });
    history.push(`/record/support_plan/${uifId}/assessment`);
  };

  const onClickCancelDeleteAssessment = (): void => {
    setOpenDeleteAssessmentModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // formik handler
  const confirmDiscardFormChanges = (nextValues: Assessment): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  // バリデーション
  const validate = (values: Assessment): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className={classes.content}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const onClickCancel = async (): Promise<void> => {
              await props.stopHistory(false).then(() => {
                if (isNew || isCopy) {
                  history.push(
                    `${URL.RECORD_SUPPORT_PLAN}/${uifId}/assessment`
                  );
                }
                if (isView && isEditing) {
                  formikProps.resetForm();
                  setEditing(false);
                }
              });
            };
            return (
              <Form>
                <PlanListHeader
                  pageName="アセスメントシート"
                  userName={userName}
                  uifId={uifId}
                  recordType={PLAN_LIST_TAB_PATH.ASSESSMENT}
                  isEditing={isEditing}
                  history={props.history}
                  button={
                    <div className={classes.titleBtn}>
                      {isEditing ? (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton
                              kind="outline"
                              onClick={onClickCancel}
                            >
                              キャンセル
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <FormikSubmitButton
                              key="submit-button"
                              buttonName="保存する"
                              formikProps={formikProps}
                              errorAction={submitError}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickPrint}>
                              印刷
                            </KnowbeButton>
                          </div>
                          <div className={classes.titleBtnItem}>
                            <KnowbeButton kind="default" onClick={onClickEdit}>
                              編集
                            </KnowbeButton>
                          </div>
                        </>
                      )}
                    </div>
                  }
                  facilityType={facilityType}
                />
                <div className={classes.form}>
                  {!isNew && !isCopy && (
                    <div className={classes.conCreateDate}>
                      <CreateAndUpdateDate
                        createdAt={formValues.created_at}
                        updatedAt={formValues.updated_at}
                      />
                    </div>
                  )}
                  <RecordSelectDate
                    name="target_date"
                    label="作成日"
                    required
                    value={formValues.target_date}
                    isEditable={isEditing}
                    addYearTo={1}
                    overrideYearFrom={1989}
                  />
                  <AssessmentSheetForm
                    categoryType={AB_ASSESSMENT_CATEGORY_TYPE.employment}
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <AssessmentSheetForm
                    categoryType={
                      facilityType === FacilityType.IKOU
                        ? IKOU_ASSESSMENT_CATEGORY_TYPE.support
                        : AB_ASSESSMENT_CATEGORY_TYPE.support
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <AssessmentSheetForm
                    categoryType={
                      facilityType === FacilityType.IKOU
                        ? IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
                        : AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                  <AssessmentSheetForm
                    categoryType={
                      facilityType === FacilityType.IKOU
                        ? IKOU_ASSESSMENT_CATEGORY_TYPE.others
                        : AB_ASSESSMENT_CATEGORY_TYPE.others
                    }
                    formValues={isEditing ? formikProps.values : formValues}
                    formikProps={formikProps}
                    isEditing={isEditing}
                    customRecords={customRecords}
                    facilityType={facilityType}
                  />
                </div>
                <div className={classes.author}>
                  <div className={classes.authorName}>
                    <RecordSelect
                      name="author"
                      label="作成者"
                      defaultValue="未設定"
                      placeholder="選択してください"
                      options={staffOptions}
                      emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                      value={authorName}
                      isEditable={isEditing}
                      isSelectablePlaceholder
                    />
                  </div>
                  <div className={classes.authorRole}>
                    <ReadonlyTextField
                      value={getStaffRole(
                        staffWithSnap,
                        isEditing
                          ? formikProps.values.author
                          : formValues.author
                      )}
                      label="役職"
                      defaultValue="-"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {isView && isEditing && (
          <DeleteButton
            text="アセスメントシートを削除する"
            onClick={onClickDeleteAssessment}
          />
        )}
      </div>
      <MessageDialog
        isOpen={isOpenDeleteAssessmentModal}
        title="アセスメントシートを削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDeleteAssessment}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteSubmitButton}
            onClick={onSubmitDeleteAssessment}
          >
            削除する
          </Button>
        }
      />
      <AssessmentSheetPrintDialog
        isOpen={isOpenPrintAssessmentModal}
        history={history}
        uifId={uifId}
        assessmentId={assessmentId}
        onClose={(): void => setIsOpenPrintAssessmentModal(false)}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  customRecords: state.customRecordsWithCategory.assesment,
  assessment: state.assessment.assessment,
  staff: state.staff
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    staffDispatcher,
    assessmentDispatcher,
    customRecordsWithCategory,
    uiDispatch
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const assessmentDispatches = assessmentDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.assessment
      );
    },
    fetchAssessment: (uifId: string, assessmentId: string): Promise<void> => {
      return assessmentDispatches.fetchAssessment(uifId, assessmentId);
    },
    postAssessment: (
      uifId: string,
      initialAssessment: Assessment,
      assessment: Assessment,
      history: H.History
    ): Promise<void> => {
      return assessmentDispatches.postAssessment(
        uifId,
        initialAssessment,
        assessment,
        history
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    unsetAssessment: (): void => {
      dispatch(assessmentActions.unsetAssessment());
    },
    deleteAssessment: (uifId: string, assessmentId: string): Promise<void> => {
      return assessmentDispatches.deleteAssessment(uifId, assessmentId);
    },
    stopHistory: uiDispatches.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, assessment } = stateProps;

  const author = assessment.author !== null ? assessment.author : 0;

  // 作成者名
  const authorName = getSnapOrRealName(author, "-");
  // 承認者役職
  const authorizerRole = getSnapOrRealRole(author, "-");

  // 記録者フィールドに渡すoptions
  let staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  // サービス提供者選択肢作成（スナップショット）
  if (assessment.author !== null) {
    staffOptions = createSnapshotOptions(staffOptions, assessment.author);
  }
  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptions.length > staff.staffItems.length) {
    const snapOption = staffOptions.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorName,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  return {
    authorName,
    authorizerRole,
    staffOptions,
    staffWithSnap,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const AssessmentSheet = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(AssessmentSheetCore)
);
