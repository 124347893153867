import { GetAssessmentListResponse } from "@api/requests/assessment/getAssessmentList";
import { GetAssessmentResponse } from "@api/requests/assessment/getAssessment";

/**
 * ActionNames
 */
export const FETCH_ASSESSMENT_STARTED = "ASSESSMENT/FETCH_ASSESSMENT_STARTED";
export const FETCH_ASSESSMENT_SUCCESS = "ASSESSMENT/FETCH_ASSESSMENT_SUCCESS";
export const FETCH_ASSESSMENT_FAILED = "ASSESSMENT/FETCH_ASSESSMENT_FAILED";

export const FETCH_ASSESSMENT_LIST_STARTED =
  "ASSESSMENT/FETCH_ASSESSMENT_LIST_STARTED";
export const FETCH_ASSESSMENT_LIST_SUCCESS =
  "ASSESSMENT/FETCH_ASSESSMENT_LIST_SUCCESS";
export const FETCH_ASSESSMENT_LIST_FAILED =
  "ASSESSMENT/FETCH_ASSESSMENT_LIST_FAILED";

export const POST_ASSESSMENT_STARTED = "ASSESSMENT/POST_ASSESSMENT_STARTED";
export const POST_ASSESSMENT_SUCCESS = "ASSESSMENT/POST_ASSESSMENT_SUCCESS";
export const POST_ASSESSMENT_FAILED = "ASSESSMENT/POST_ASSESSMENT_FAILED";
export const UNSET_ASSESSMENT = "ASSESSMENT/UNSET_ASSESSMENT";

export const DELETE_ASSESSMENT_STARTED = "ASSESSMENT/DELETE_ASSESSMENT_STARTED";
export const DELETE_ASSESSMENT_SUCCESS = "ASSESSMENT/DELETE_ASSESSMENT_SUCCESS";
export const DELETE_ASSESSMENT_FAILED = "ASSESSMENT/DELETE_ASSESSMENT_FAILED";
/**
 * State
 */
export type assessmentInput = GetAssessmentResponse["data"]["assessment"]["assessment_records"][number]["input"][number];
export type AssessmentState = {
  assessment: GetAssessmentResponse["data"];
  assessmentList: GetAssessmentListResponse["data"];
};
