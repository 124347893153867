import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import { dateToLocalisedString } from "@utils/date";
import { getLabelFromOptions } from "@/utils/dataNormalizer";

import {
  FacilityType,
  CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK
} from "@constants/variables";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";
import { SUPPORT_CARE_PLAN_SCHEDULES_STATUS } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

const styles = (): StyleRules =>
  createStyles({
    MT8: {
      marginTop: 8
    },
    MT16: {
      marginTop: 16
    },
    MT32: {
      marginTop: 32
    },
    title: {
      fontFamily: ["HiraginoSans-W6", "sans-serif"].join(","),
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      paddingBottom: 4,
      borderBottom: "1px solid #000",
      marginBottom: 16,
      whiteSpace: "initial"
    },
    info: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      "&>div:nth-child(n+2)": {
        marginTop: 8
      }
    },
    W95: {
      width: 95
    },
    W243: {
      width: 243
    },
    table: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      border: "solid 2px #212121"
    },
    tableCol: {
      display: "flex",
      "&+&": {
        borderTop: "solid 1px #212121"
      },
      "&>span": {
        display: "block",
        padding: 6
      },
      "&>span+span": {
        borderLeft: "solid 1px #212121"
      }
    },
    tableColBody: {
      "&>span": {
        minHeight: 36,
        wordBreak: "break-all"
      }
    },
    textAlignCenter: {
      textAlign: "center"
    }
  });
type OwnProps = {
  facilityType: FacilityType;
  supportCarePlanSchedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
};
type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanPrintContentDetailsCore = (props: Props): JSX.Element => {
  const { classes, facilityType, supportCarePlanSchedules } = props;

  // 基本情報
  const infoValue = (
    item: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"][0]
  ): JSX.Element => {
    return (
      <>
        {item.support_care_plan_basic_info.map((m, index) => {
          let week = "";
          let time = "";
          const numberOfTime =
            m.number_of_time === null ? "" : `（${m.number_of_time}）`;
          let practitioner1 = "";
          let practitioner2 = "";
          let name = "サービス提供担当者：";

          week = m.day_of_week
            .map((i) => {
              const target = CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK.find(
                (f) => f.value === i
              );
              return target && target.label;
            })
            .join("/");

          if (m.start_time !== null && m.end_time !== null) {
            time = `${dateToLocalisedString(
              m.start_time,
              "HH:mm"
            )}〜${dateToLocalisedString(m.end_time, "HH:mm")}${numberOfTime}`;
          } else if (m.start_time === null && m.end_time !== null) {
            time = `--:--〜${dateToLocalisedString(m.end_time, "HH:mm")}`;
          } else if (m.start_time !== null && m.end_time === null) {
            time = `${dateToLocalisedString(m.start_time, "HH:mm")}〜--:--`;
          }

          if (m.practitioner1 !== null) {
            if (
              m.practitioner1.snapshot_name !== null &&
              m.practitioner1.snapshot_license_name !== null
            ) {
              practitioner1 = `${m.practitioner1.snapshot_name}（${m.practitioner1.snapshot_license_name}）`;
            } else {
              practitioner1 = `${
                m.practitioner1.name !== null ? m.practitioner1.name : ""
              }${
                m.practitioner1.license_name !== null
                  ? `（${m.practitioner1.license_name}）`
                  : ""
              }`;
            }
          }
          if (m.practitioner2 !== null) {
            if (
              m.practitioner2.snapshot_name !== null &&
              m.practitioner2.snapshot_license_name !== null
            ) {
              practitioner2 = `${m.practitioner2.snapshot_name}（${m.practitioner2.snapshot_license_name}）`;
            } else {
              practitioner2 = `${
                m.practitioner2.name !== null ? m.practitioner2.name : ""
              }${
                m.practitioner2.license_name !== null
                  ? `（${m.practitioner2.license_name}）`
                  : ""
              }`;
            }
          }
          if (practitioner1 === "" && practitioner2 === "") {
            name = "";
          }

          name += [practitioner1, practitioner2].filter(Boolean).join("、");
          const weekTime = [week, time].filter(Boolean).join("　");
          const res = [weekTime, name].filter(Boolean).join("\n");
          const uniqueKey = `uniqueBasicInfoKey-${index}`;

          if (week === "" && time === "" && name === "") {
            return false;
          }
          return <div key={uniqueKey}>{res}</div>;
        })}
      </>
    );
  };
  // 詳細内容
  const detailsValue = (
    item: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"][0]
  ): JSX.Element => {
    return (
      <>
        <div className={classes.tableCol}>
          <span className={classes.W95}>所要時間</span>
          <span className={ClassNames(classes.W95, classes.textAlignCenter)}>
            サービス内容
          </span>
          <span className={ClassNames(classes.W243, classes.textAlignCenter)}>
            手順・留意事項・観察ポイント
          </span>
          <span className={ClassNames(classes.W243, classes.textAlignCenter)}>
            本人・家族にやっていただくこと
          </span>
        </div>
        {item.support_care_plan_details.map((detail, index) => {
          const uniqueKey = `uniqueDetailsKey-${index}`;
          return (
            <div
              className={ClassNames(classes.tableCol, classes.tableColBody)}
              key={uniqueKey}
            >
              <span className={classes.W95}>{detail.time_required}</span>
              <span className={classes.W95}>{detail.service_details}</span>
              <span className={classes.W243}>{detail.point}</span>
              <span className={classes.W243}>{detail.to_do}</span>
            </div>
          );
        })}
      </>
    );
  };

  const detailsTitle = (
    index: number,
    item: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"][0]
  ): string => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return `サービス${circleNumbersList[index]} ${getLabelFromOptions(
          `${item.status}`,
          SUPPORT_CARE_PLAN_SCHEDULES_STATUS
        )}`;
      case FacilityType.JUDOHOMONKAIGO:
      case FacilityType.DOKOENGO:
      case FacilityType.KODOENGO:
        return `サービス${circleNumbersList[index]} ${
          item.status_text !== undefined ? item.status_text : "-"
        }`;
      default:
        return `サービス${circleNumbersList[index]} ${
          item.status_text !== undefined ? item.status_text : "-"
        }`;
    }
  };
  return (
    <>
      {supportCarePlanSchedules.map((item, index) => {
        const uniqueKey = `uniqueSchedulesKey-${index}`;
        return (
          <div key={uniqueKey}>
            <h3 className={ClassNames(classes.title, classes.MT32)}>
              {detailsTitle(index, item)}
            </h3>
            <div className={classes.info}>{infoValue(item)}</div>
            <div className={ClassNames(classes.table, classes.MT8)}>
              {detailsValue(item)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const CarePlanPrintContentDetails = withStyles(styles)(
  CarePlanPrintContentDetailsCore
);
