import * as types from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { NormalizedGetFacilityUserTargetIdResponse } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/normalizer";
import { GetFacilityUsersResponse } from "@api/requests/v202104/facility/getFacilityUsers";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchStarted = () => ({ type: types.FETCH_STARTED } as const);
export const fetchSuccess = (res: GetFacilityUsersResponse) =>
  ({ type: types.FETCH_SUCCESS, payload: res } as const);
export const fetchFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_FAILED, error: err } as const);
export const fetchStartedTargetDay = () =>
  ({ type: types.FETCH_STARTED_TARGETDAY } as const);
export const fetchSuccessTargetDay = (res: GetFacilityUsersListResponse) =>
  ({ type: types.FETCH_SUCCESS_TARGETDAY, payload: res } as const);
export const fetchFailedTargetDay = (err: ErrorResponse) =>
  ({ type: types.FETCH_FAILED_TARGETDAY, error: err } as const);
export const fetchOneStarted = () =>
  ({ type: types.FETCH_ONE_STARTED } as const);
export const fetchOneSuccess = (
  res: NormalizedGetFacilityUserTargetIdResponse
) => ({ type: types.FETCH_ONE_SUCCESS, payload: res } as const);
export const fetchOneFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_ONE_FAILED, error: err } as const);
export const createStarted = () => ({ type: types.CREATE_STARTED } as const);
export const createSuccess = () => ({ type: types.CREATE_SUCCESS } as const);
export const createFailed = (err: ErrorResponse) =>
  ({ type: types.CREATE_FAILED, error: err } as const);
export const updateStarted = () => ({ type: types.UPDATE_STARTED } as const);
export const updateSuccess = () => ({ type: types.UPDATE_SUCCESS } as const);
export const updateFailed = (err: ErrorResponse) =>
  ({ type: types.UPDATE_FAILED, error: err } as const);
export const deleteStarted = () => ({ type: types.DELETE_STARTED } as const);
export const deleteSuccess = () => ({ type: types.DELETE_SUCCESS } as const);
export const deleteFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_FAILED, error: err } as const);
export const clear = () => ({ type: types.CLEAR } as const);

export type ActionTypes =
  | ReturnType<typeof fetchStarted>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof fetchFailed>
  | ReturnType<typeof fetchStartedTargetDay>
  | ReturnType<typeof fetchSuccessTargetDay>
  | ReturnType<typeof fetchFailedTargetDay>
  | ReturnType<typeof fetchOneStarted>
  | ReturnType<typeof fetchOneSuccess>
  | ReturnType<typeof fetchOneFailed>
  | ReturnType<typeof createStarted>
  | ReturnType<typeof createSuccess>
  | ReturnType<typeof createFailed>
  | ReturnType<typeof updateStarted>
  | ReturnType<typeof updateSuccess>
  | ReturnType<typeof updateFailed>
  | ReturnType<typeof deleteStarted>
  | ReturnType<typeof deleteSuccess>
  | ReturnType<typeof deleteFailed>
  | ReturnType<typeof clear>;
