import { GetPlanMonitoringMeetingResponse } from "@api/requests/planMonitoringMeeting/getPlanMonitoringMeeting";

/**
 * ActionNames
 */
export const FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_STARTED =
  "KEIKAKUSODAN/FETCH_PLAN_MONITORING_MEETING_STARTED";
export const FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_SUCCESS =
  "KEIKAKUSODAN/FETCH_PLAN_MONITORING_MEETING_SUCCESS";
export const FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_FAILED =
  "KEIKAKUSODAN/FETCH_PLAN_MONITORING_MEETING_FAILED";

/**
 * State
 */
export type PlanMonitoringMeetingState = {
  planMonitoringMeeting: GetPlanMonitoringMeetingResponse["data"];
};
