import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  FormLabel
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikTime from "@components/molecules/FormikTime";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v202104/mgr/DOKOENGO/report/initial";
import { UnitsFields } from "@components/v202104/organisms/mgr/DOKOENGO/report/dialog/UnitsFields";
import { DisplayStaffsInFacilityState } from "@stores/v202104/domain/mgr/DOKOENGO/report/types";
import { FacilityState } from "@stores/v202104/domain/mgr/DOKOENGO/facility/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/DOKOENGO/userInFacility/types";
import {
  DOKOENGO_BASE_LIST,
  DOKOENGO_PLAN_CORE_WORKER_LICENSE_LIST,
  DOKOENGO_MEMBER_LIST,
  DOKOENGO_MEMBER_SELECT_LIST,
  DOKOENGO_INPUT_CLASS_SELECT_LIST,
  DOKOENGO_INPUT_CLASS_LIST,
  STANDARD_TIME_VALUE,
  DOKOENGO_LICENSE_LIST,
  OUT_TIME_VALIDATION_LIST,
  TIME_CLASS_LIST,
  IN_TIME_VALIDATION_LIST
} from "@constants/mgr/DOKOENGO/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";
import { calculateHours } from "@utils/domain/mgr/calculateHours";
import { calculateOverlapTime } from "@utils/domain/mgr/calculateOverlapTime";
import { calculateTotalTime } from "@utils/domain/mgr/calculateTotalTime";
import { formatTime } from "@utils/date/formatTime";

import FormHelperText from "@material-ui/core/FormHelperText";
import { isWithinRange } from "@utils/domain/mgr/isWithinRange";

const styles = (): StyleRules =>
  createStyles({
    worker_license: {
      marginBottom: 0
    },
    categoryGroup: {
      paddingLeft: 32
    },
    categoryGroupBorder: {
      borderTop: "solid 1px #cfd8dc"
    },
    categoryLabel: {
      paddingTop: 24,
      paddingBottom: 24
    },
    checkboxHelperText: {
      marginTop: 0
    },
    calculatedHours: {
      "& > label": {
        width: 180
      }
    },
    disabledColorLabel: {
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      }
    },
    error: {
      "& > label": {
        color: "#f44336!important"
      }
    },
    disabledColor: {
      // disabledの色を上書き
      "& > label": {
        color: "rgba(0, 0, 0, 0.87)!important"
      },
      "& > div": {
        "& > input": {
          color: "rgba(0, 0, 0, 0.87)!important"
        },
        "& > div": {
          "& > p": {
            color: "rgba(0, 0, 0, 0.87)!important"
          }
        }
      }
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  facility: FacilityState;
  staffList: DisplayStaffsInFacilityState[];
  usersInFacility: UsersInFacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  staffList: FieldItem[];
  planCareWorkerLicenseList: FieldItem[];
  isDisableBodyRestrictedStillFlg: boolean;
  isPractitioner2: boolean;
  inputClass: string;
  practitioner1InTime: string;
  practitioner1InTimeClass: string;
  practitioner1OutTime: string;
  practitioner1OutTimeClass: string;
  practitioner1DetailTimeList: DetailTimeInfo[];
  isAddButton1: boolean;
  practitioner2InTime: string;
  practitioner2InTimeClass: string;
  practitioner2OutTime: string;
  practitioner2OutTimeClass: string;
  practitioner2DetailTimeList: DetailTimeInfo[];
  isAddButton2: boolean;
  standardValue: number;
  licenseSameFlg: boolean;
  license1: string;
  license2: string;
};

type DetailTimeInfo = {
  time: number;
  inTime: string;
  inTimeClass: string;
  outTime: string;
  outTimeClass: string;
};

type Props = StateProps & WithStyles<typeof styles>;

class InOutReportDialogFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { initial } = this.props.formikPropsValues.initialValues;
    this.state = {
      planCareWorkerLicenseList: DOKOENGO_PLAN_CORE_WORKER_LICENSE_LIST,
      staffList: this.props.staffList
        ? [DOKOENGO_BASE_LIST.NONE].concat(this.props.staffList)
        : [DOKOENGO_BASE_LIST.NONE],
      isDisableBodyRestrictedStillFlg:
        castNumber(initial.targetDate) < 20230401,
      isPractitioner2:
        initial.numberOfParticipants === DOKOENGO_MEMBER_LIST.TWO.value,
      inputClass: initial.inputClass,
      practitioner1InTime: initial.practitioner1InTime,
      practitioner1InTimeClass: initial.practitioner1InTimeClass,
      practitioner1OutTime: initial.practitioner1OutTime,
      practitioner1OutTimeClass: initial.practitioner1OutTimeClass,
      practitioner1DetailTimeList: initial.inoutResultsDetails1.map((row) => {
        return {
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton1: initial.inoutResultsDetails1.length < 10,
      practitioner2InTime: initial.practitioner2InTime,
      practitioner2InTimeClass: initial.practitioner2InTimeClass,
      practitioner2OutTime: initial.practitioner2OutTime,
      practitioner2OutTimeClass: initial.practitioner2OutTimeClass,
      practitioner2DetailTimeList: initial.inoutResultsDetails2.map((row) => {
        return {
          time: row.time,
          inTime: row.inTime,
          inTimeClass: row.inTimeClass,
          outTime: row.outTime,
          outTimeClass: row.outTimeClass
        };
      }),
      isAddButton2: initial.inoutResultsDetails2.length < 10,
      standardValue: STANDARD_TIME_VALUE,
      licenseSameFlg: initial.licenseSameFlg,
      license1: initial.practitioner1License,
      license2: initial.practitioner2License
    };
  }

  private getInitDetailTime = (): DetailTimeInfo => {
    return {
      time: 0,
      inTime: "",
      inTimeClass: "",
      outTime: "",
      outTimeClass: ""
    };
  };

  private onAddDetailRecord1 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner1DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner1DetailTimeList: list,
        isAddButton1: list.length < 10
      };
    });
  };

  private onAddDetailRecord2 = (): void => {
    this.setState((prevState) => {
      const list = prevState.practitioner2DetailTimeList.slice();
      list.push(this.getInitDetailTime());
      return {
        practitioner2DetailTimeList: list,
        isAddButton2: list.length < 10
      };
    });
  };

  private onDeleteDetailRecord1 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner1DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner1DetailTimeList: list, isAddButton1: true };
      },
      () => {
        this.setPersonalCalculatedHours1();
        this.setAllCalculatedHours();
      }
    );
  };

  private onDeleteDetailRecord2 = (index: number): void => {
    this.setState(
      (prevState) => {
        const list = prevState.practitioner2DetailTimeList.slice();
        list.splice(index, 1);
        return { practitioner2DetailTimeList: list, isAddButton2: true };
      },
      () => {
        this.setPersonalCalculatedHours2();
        this.setAllCalculatedHours();
      }
    );
  };

  private onChangeDetailRecord1 = (
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner1DetailTimeList: prevState.practitioner1DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours1(
          this.state.practitioner1InTime,
          this.state.practitioner1InTimeClass,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList
        );
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList
        );
      }
    );
  };

  private onChangeDetailRecord2 = (
    index: number,
    start: string,
    startClass: string,
    end: string,
    endClass: string
  ): void => {
    const startTime = formatTime(start);
    const endTime = formatTime(end);
    this.setState(
      (prevState) => ({
        practitioner2DetailTimeList: prevState.practitioner2DetailTimeList.map(
          (record, i) => {
            if (i !== index) {
              return record;
            }
            return {
              time: calculateForRequiredTime(
                startTime,
                startClass,
                endTime,
                endClass
              ),
              inTime: startTime,
              inTimeClass: startClass,
              outTime: endTime,
              outTimeClass: endClass
            };
          }
        )
      }),
      () => {
        this.setPersonalCalculatedHours2(
          this.state.practitioner2InTime,
          this.state.practitioner2InTimeClass,
          this.state.practitioner2OutTime,
          this.state.practitioner2OutTimeClass,
          this.state.practitioner2DetailTimeList
        );
        this.setAllCalculatedHours(
          this.state.practitioner1InTime,
          this.state.practitioner1OutTime,
          this.state.practitioner1OutTimeClass,
          this.state.practitioner1DetailTimeList,
          this.state.practitioner2InTime,
          this.state.practitioner2InTimeClass,
          this.state.practitioner2OutTime,
          this.state.practitioner2OutTimeClass,
          this.state.practitioner2DetailTimeList
        );
      }
    );
  };

  private setPersonalCalculatedHours1 = (
    inTime: string = this.state.practitioner1InTime,
    inTimeClass: string = this.state.practitioner1InTimeClass,
    outTime: string = this.state.practitioner1OutTime,
    outTimeClass: string = this.state.practitioner1OutTimeClass,
    list: DetailTimeInfo[] = this.state.practitioner1DetailTimeList,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    this.setPersonalCalculatedHours(
      "initial.practitioner1CalculatedHours",
      inTime,
      inTimeClass,
      outTime,
      outTimeClass,
      list,
      nextStandardValue
    );
  };

  private setPersonalCalculatedHours2 = (
    inTime: string = this.state.practitioner2InTime,
    inTimeClass: string = this.state.practitioner2InTimeClass,
    outTime: string = this.state.practitioner2OutTime,
    outTimeClass: string = this.state.practitioner2OutTimeClass,
    list: DetailTimeInfo[] = this.state.practitioner2DetailTimeList,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    this.setPersonalCalculatedHours(
      "initial.practitioner2CalculatedHours",
      inTime,
      inTimeClass,
      outTime,
      outTimeClass,
      list,
      nextStandardValue
    );
  };

  private setPersonalCalculatedHours = (
    target: string,
    inTime: string,
    inTimeClass: string,
    outTime: string,
    outTimeClass: string,
    otherTimeList: DetailTimeInfo[],
    nextStandardValue: number = this.state.standardValue
  ): void => {
    const baseTime = calculateForRequiredTime(
      inTime,
      inTimeClass,
      outTime,
      outTimeClass
    );
    const otherTime = otherTimeList.reduce((sum, value) => {
      return sum + value.time;
    }, 0);
    const result = calculateHours(
      baseTime,
      nextStandardValue,
      otherTime,
      STANDARD_TIME_VALUE
    );
    this.props.setFormikFieldValue(target, Number.isNaN(result) ? "" : result);
  };

  private handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    let start1 = this.state.practitioner1InTime;
    const start1Class = this.state.practitioner1InTimeClass;
    let end1 = this.state.practitioner1OutTime;
    let end1Class = this.state.practitioner1OutTimeClass;
    let start2 = this.state.practitioner2InTime;
    let start2Class = this.state.practitioner2InTimeClass;
    let end2 = this.state.practitioner2OutTime;
    let end2Class = this.state.practitioner2OutTimeClass;
    switch (event.target.name) {
      case "initial.practitioner1InTime":
        start1 = formatTime(event.target.value);
        this.setState({ practitioner1InTime: start1 });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner1OutTime":
        end1 = formatTime(event.target.value);
        this.setState({ practitioner1OutTime: end1 });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner1OutTimeClass":
        end1Class = event.target.value;
        this.setState({
          practitioner1OutTimeClass: end1Class
        });
        this.setPersonalCalculatedHours1(start1, start1Class, end1, end1Class);
        break;
      case "initial.practitioner2InTime":
        start2 = formatTime(event.target.value);
        this.setState({ practitioner2InTime: start2 });
        this.setPersonalCalculatedHours2(start2, start2Class, end2, end2Class);
        break;
      case "initial.practitioner2InTimeClass":
        start2Class = event.target.value;
        this.setState({
          practitioner2InTimeClass: start2Class
        });
        this.setPersonalCalculatedHours2(start2, start2Class, end2, end2Class);
        break;
      case "initial.practitioner2OutTime":
        end2 = formatTime(event.target.value);
        this.setState({ practitioner2OutTime: end2 });
        this.setPersonalCalculatedHours2(start2, start2Class, end2, end2Class);
        break;
      case "initial.practitioner2OutTimeClass":
        end2Class = event.target.value;
        this.setState({
          practitioner2OutTimeClass: end2Class
        });
        this.setPersonalCalculatedHours2(start2, start2Class, end2, end2Class);
        break;
      default:
    }

    // 合算算定（全体/重複）
    this.setAllCalculatedHours(
      start1,
      end1,
      end1Class,
      this.state.practitioner1DetailTimeList,
      start2,
      start2Class,
      end2,
      end2Class,
      this.state.practitioner2DetailTimeList
    );
  };

  private setAllCalculatedHours = (
    baseInTime1 = this.state.practitioner1InTime,
    baseOutTime1 = this.state.practitioner1OutTime,
    baseOutTime1Class = this.state.practitioner1OutTimeClass,
    baseDetail1 = this.state.practitioner1DetailTimeList,
    baseInTime2 = this.state.practitioner2InTime,
    baseInTime2Class = this.state.practitioner2InTimeClass,
    baseOutTime2 = this.state.practitioner2OutTime,
    baseOutTime2Class = this.state.practitioner2OutTimeClass,
    baseDetail2 = this.state.practitioner2DetailTimeList,
    sameFlg = this.state.licenseSameFlg,
    nextStandardValue: number = this.state.standardValue
  ): void => {
    const outTime1 = this.getTime(baseOutTime1, baseOutTime1Class);
    const detail1 = baseDetail1.map((record) => this.getDetailTime(record));

    // 同時実行ONの場合、提供者②は提供者①のコピーとする
    const inTime2 = !sameFlg
      ? this.getTime(baseInTime2, baseInTime2Class)
      : baseInTime1;
    const outTime2 = !sameFlg
      ? this.getTime(baseOutTime2, baseOutTime2Class)
      : outTime1;
    const detail2 = !sameFlg
      ? baseDetail2.map((record) => this.getDetailTime(record))
      : detail1.slice();

    // 提供時間の計算
    const baseData = this.calcAllCalculateTime([
      { inTime: baseInTime1, outTime: outTime1 },
      { inTime: inTime2, outTime: outTime2 }
    ]);
    // 運転/空き時間の計算
    // （・全体 = 全体の提供時間 - 明細1 - 明細3）
    // （・重複 = 重複の提供時間 - 明細2）
    // 1.提供時間を重複範囲とそれ以外に分割
    const data = calculateOverlapTime(
      { inTime: baseInTime1, outTime: outTime1 },
      [{ inTime: inTime2, outTime: outTime2 }]
    );

    // 2.運転空き時間を、重複範囲内か外かでグループ分け（重複の境を跨ぐ場合は、分割）
    const detail =
      data && data.length > 0
        ? this.calcDetailCalculateTime(data[0], detail1.concat(detail2))
        : {
            total: [{ inTime: "", outTime: "" }],
            duplicate: [{ inTime: "", outTime: "" }]
          };
    // 3.重複範囲外の運転空き時間を計算（→明細1)
    const subtractedTotalData = this.calcAllCalculateTime(
      detail.total.slice(1)
    );
    // 4.重複範囲内の運転空き時間（全体・重複）を計算（→明細2・明細3)
    const subtractedDuplicateData = this.calcAllCalculateTime(
      detail.duplicate.slice(1)
    );

    const wholeData = calculateHours(
      baseData.whole,
      nextStandardValue,
      subtractedTotalData.whole + subtractedDuplicateData.duplicate,
      STANDARD_TIME_VALUE
    );

    const duplicateData = sameFlg
      ? wholeData
      : calculateHours(
          baseData.duplicate,
          nextStandardValue,
          subtractedDuplicateData.whole,
          STANDARD_TIME_VALUE
        );

    // 算定時間（全体）のセット
    this.props.setFormikFieldValue(
      "initial.wholeCalculatedHours",
      Number.isNaN(wholeData) ? "" : wholeData
    );
    // 算定時間（重複）のセット
    this.props.setFormikFieldValue(
      "initial.duplicateCalculatedHours",
      Number.isNaN(duplicateData) ? "" : duplicateData
    );
  };

  private calcAllCalculateTime = (
    list: { inTime: string; outTime: string }[]
  ): { whole: number; duplicate: number } => {
    let totalList = list.slice();
    let target = totalList.shift();
    if (!target) {
      return { whole: 0, duplicate: 0 };
    }

    // [全体]の時間を算出
    let whole = 0;
    while (target) {
      const data = calculateTotalTime(target, totalList);
      whole += calculateForRequiredTime(
        data.result.inTime,
        "0",
        data.result.outTime,
        "0"
      );

      target = data.targetList.shift();
      totalList = data.targetList;
    }

    // [重複]の時間を算出
    // 1. 重複対象のデータ取得
    // 2. 1次元配列に変換
    // 3. 重複削除
    // 4. 算定用の時間計算
    let duplicate = 0;
    Array.from(
      new Map(
        list
          .map((data, idx) =>
            calculateOverlapTime(
              data,
              list.filter((record, i) => i !== idx)
            )
          )
          .reduce((pre, current) => pre.concat(current), [])
          .map((data) => [`${data.inTime}${data.outTime}`, data])
      ).values()
    ).forEach((record) => {
      duplicate += calculateForRequiredTime(
        record.inTime,
        "0",
        record.outTime,
        "0"
      );
    });

    return { whole, duplicate };
  };

  private calcDetailCalculateTime = (
    range: { inTime: string; outTime: string },
    list: { inTime: string; outTime: string }[]
  ): {
    total: { inTime: string; outTime: string }[];
    duplicate: { inTime: string; outTime: string }[];
  } => {
    return list.reduce(
      (prev, record) => {
        const start1 = Number(range.inTime.split(":").join(""));
        const start2 = Number(record.inTime.split(":").join(""));
        const end1 = Number(range.outTime.split(":").join(""));
        const end2 = Number(record.outTime.split(":").join(""));

        const start =
          isWithinRange(range.inTime, range.outTime, record.inTime) ||
          start1 === start2;
        const end =
          isWithinRange(range.inTime, range.outTime, record.outTime) ||
          end1 === end2;
        const outRangeFlg = !isWithinRange(
          record.inTime,
          record.outTime,
          range.inTime
        );

        // ケース1（重複範囲内）
        if (start && end) {
          return {
            total: prev.total,
            duplicate: prev.duplicate.concat(record)
          };
        }

        // ケース2（重複範囲Fromをまたぐ）
        if (!start && end) {
          return {
            total: prev.total.concat({
              inTime: record.inTime,
              outTime: range.inTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: range.inTime,
              outTime: record.outTime
            })
          };
        }

        // ケース3（重複範囲toをまたぐ）
        if (start && !end) {
          return {
            total: prev.total.concat({
              inTime: range.outTime,
              outTime: record.outTime
            }),
            duplicate: prev.duplicate.concat({
              inTime: record.inTime,
              outTime: range.outTime
            })
          };
        }

        // ケース4（重複範囲外）
        if (!start && !end && outRangeFlg) {
          return {
            total: prev.total.concat(record),
            duplicate: prev.duplicate
          };
        }

        // ケース5（重複範囲全体をまたぐ）
        return {
          total: prev.total.concat([
            { inTime: record.inTime, outTime: range.inTime },
            { inTime: range.outTime, outTime: record.outTime }
          ]),
          duplicate: prev.duplicate.concat(range)
        };
      },
      {
        total: [{ inTime: "", outTime: "" }],
        duplicate: [{ inTime: "", outTime: "" }]
      }
    );
  };

  private getTime = (time: string, timeClass: string): string => {
    // 日跨ぎしている場合、時間に+24する
    const times = time.split(":");

    return timeClass === "1" ? `${Number(times[0]) + 24}:${times[1]}` : time;
  };

  private getDetailTime = (detail: {
    inTime: string;
    inTimeClass: string;
    outTime: string;
    outTimeClass: string;
  }): { inTime: string; outTime: string } => {
    const inTime = this.getTime(detail.inTime, detail.inTimeClass);
    const outTime = this.getTime(detail.outTime, detail.outTimeClass);

    return { inTime, outTime };
  };

  private onChangeLicense1 = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({ license1: e.target.value });
    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours();
  };

  private onChangeLicense2 = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({ license2: e.target.value });
    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours();
  };

  private onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.setFormikFieldValue(e.target.name, e.target.checked);
  };

  private onChangeLicenseSameFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.onChangeCheckBox(e);
    this.setState({ licenseSameFlg: e.target.checked });

    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours(
      this.state.practitioner1InTime,
      this.state.practitioner1OutTime,
      this.state.practitioner1OutTimeClass,
      this.state.practitioner1DetailTimeList,
      this.state.practitioner2InTime,
      this.state.practitioner2InTimeClass,
      this.state.practitioner2OutTime,
      this.state.practitioner2OutTimeClass,
      this.state.practitioner2DetailTimeList,
      e.target.checked
    );
  };

  private handleChangeNumberOfParticipants = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      isPractitioner2: e.target.value === DOKOENGO_MEMBER_LIST.TWO.value
    });
    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours();
  };

  private onChangePractitioner = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const target = this.props.staffList.filter((record) => {
      return record.value === e.target.value;
    });
    const license =
      target.length > 0
        ? target[0].license
        : DOKOENGO_LICENSE_LIST.NEWCOMER.value;

    if (e.target.name === "initial.practitioner1") {
      this.props.setFormikFieldValue("initial.practitioner1License", license);
      this.setState({ license1: license });
    } else {
      this.props.setFormikFieldValue("initial.practitioner2License", license);
      this.setState({ license2: license });
    }
    this.setPersonalCalculatedHours1();
    this.setPersonalCalculatedHours2();
    this.setAllCalculatedHours();
  };

  public render(): JSX.Element {
    const onChangeTargetFlg = (
      e: React.ChangeEvent<HTMLInputElement>
    ): void => {
      this.setState({ inputClass: e.target.value });
    };

    return (
      <FormGroup>
        <FormGroup className={this.props.classes.categoryGroup}>
          {this.props.formikPropsValues.initialValues.initial.createFlg && (
            <FormikRadioButtons
              name="initial.inputClass"
              label=""
              options={DOKOENGO_INPUT_CLASS_SELECT_LIST}
              style={{ marginTop: 0, marginLeft: 0 }}
              onChangeHook={onChangeTargetFlg}
            />
          )}

          <FormikSelect
            name="initial.numberOfParticipants"
            label="提供人数"
            size="smallMedium"
            options={DOKOENGO_MEMBER_SELECT_LIST}
            onChangeHook={this.handleChangeNumberOfParticipants}
          />
          <FormGroup row>
            <FormikSelect
              name="initial.practitioner1"
              label="提供者 1人目"
              required
              size="smallMedium"
              options={this.state.staffList}
              className={this.props.classes.worker_license}
              onChangeHook={this.onChangePractitioner}
            />
            <FormikSelect
              name="initial.practitioner1License"
              label="資格"
              options={this.state.planCareWorkerLicenseList}
              size="smallMedium"
              className={this.props.classes.worker_license}
              onChangeHook={this.onChangeLicense1}
            />
          </FormGroup>
          <FormGroup row>
            <FormikSelect
              name="initial.practitioner2"
              label="提供者 2人目"
              required={this.state.isPractitioner2}
              size="smallMedium"
              options={this.state.staffList}
              className={this.props.classes.worker_license}
              disabled={!this.state.isPractitioner2}
              onChangeHook={this.onChangePractitioner}
            />
            <FormikSelect
              name="initial.practitioner2License"
              label="資格"
              options={this.state.planCareWorkerLicenseList}
              size="smallMedium"
              className={this.props.classes.worker_license}
              disabled={!this.state.isPractitioner2}
              onChangeHook={this.onChangeLicense2}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial
                  ? this.props.formikPropsValues.errors.initial
                      .practitioner2License !== undefined
                  : false
              }
            />
          </FormGroup>
          <FormikCheckbox
            name="initial.licenseSameFlg"
            label="同資格者が2人同時にサービスを提供する"
            style={{ marginBottom: 0 }}
            disabled={!this.state.isPractitioner2}
            onChange={this.onChangeLicenseSameFlg}
          />
          <FormikCheckbox
            name="initial.secondPersonFlg"
            label="他事業所とともにサービスを提供する"
            style={{ marginBottom: 0 }}
            disabled={this.state.isPractitioner2}
          />
        </FormGroup>

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            サービス提供時間
            {this.state.isPractitioner2 &&
              !this.state.licenseSameFlg &&
              " 1人目"}
          </FormLabel>
          <FormGroup row>
            <FormikTextField
              name="initial.practitioner1InTimeClass"
              label="開始時間"
              required
              style={{ width: 80 }}
              disabled
              disabledStyle
              className={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial
                  .practitioner1InTime &&
                IN_TIME_VALIDATION_LIST.includes(
                  this.props.formikPropsValues.errors.initial
                    .practitioner1InTime
                )
                  ? this.props.classes.error
                  : this.props.classes.disabledColorLabel
              }
            />
            <FormikTime
              name="initial.practitioner1InTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16, marginRight: 32 }}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1InTime
                  ? IN_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1InTime
                    )
                  : false
              }
            />
            <FormikSelect
              name="initial.practitioner1OutTimeClass"
              label="終了時間"
              required
              style={{ width: 80 }}
              options={TIME_CLASS_LIST}
              onChangeHook={this.handleChangeTime}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1OutTime
                    )
                  : false
              }
            />
            <FormikTime
              name="initial.practitioner1OutTime"
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={this.handleChangeTime}
              style={{ marginTop: 16 }}
              error={
                this.props.formikPropsValues.errors &&
                this.props.formikPropsValues.errors.initial &&
                this.props.formikPropsValues.errors.initial.practitioner1OutTime
                  ? OUT_TIME_VALIDATION_LIST.includes(
                      this.props.formikPropsValues.errors.initial
                        .practitioner1OutTime
                    )
                  : false
              }
            />
          </FormGroup>
          <FormGroup style={{ marginBottom: 20 }}>
            <UnitsFields
              formikProps={this.props.formikPropsValues}
              unitKey="inoutResultsDetails1"
              onAddRecord={this.onAddDetailRecord1}
              onDeleteRecord={this.onDeleteDetailRecord1}
              onChangeTime={this.onChangeDetailRecord1}
              isAddButton={this.state.isAddButton1}
              list={this.state.practitioner1DetailTimeList}
            />
          </FormGroup>
          <FormikTextField
            name="initial.practitioner1Memo"
            label="備考"
            size="quarterSuperLong"
          />
        </FormGroup>

        {this.state.isPractitioner2 && !this.state.licenseSameFlg && (
          <FormGroup
            className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
          >
            <FormLabel className={this.props.classes.categoryLabel}>
              サービス提供時間 2人目
            </FormLabel>
            {!this.state.licenseSameFlg && (
              <FormGroup row>
                <FormikSelect
                  name="initial.practitioner2InTimeClass"
                  label="開始時間"
                  required
                  style={{ width: 80 }}
                  options={TIME_CLASS_LIST}
                  onChangeHook={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2InTime
                      ? IN_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2InTime
                        )
                      : false
                  }
                />
                <FormikTime
                  name="initial.practitioner2InTime"
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  onChangeHookTime={this.handleChangeTime}
                  style={{ marginTop: 16, marginRight: 32 }}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2InTime
                      ? IN_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2InTime
                        )
                      : false
                  }
                />
                <FormikSelect
                  name="initial.practitioner2OutTimeClass"
                  label="終了時間"
                  required
                  style={{ width: 80 }}
                  options={TIME_CLASS_LIST}
                  onChangeHook={this.handleChangeTime}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2OutTime
                      ? OUT_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2OutTime
                        )
                      : false
                  }
                />
                <FormikTime
                  name="initial.practitioner2OutTime"
                  placeholder="00:00"
                  size="smallMedium"
                  maxLength={5}
                  onChangeHookTime={this.handleChangeTime}
                  style={{ marginTop: 16 }}
                  error={
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .practitioner2OutTime
                      ? OUT_TIME_VALIDATION_LIST.includes(
                          this.props.formikPropsValues.errors.initial
                            .practitioner2OutTime
                        )
                      : false
                  }
                />
              </FormGroup>
            )}
            {!this.state.licenseSameFlg && (
              <FormGroup style={{ marginBottom: 20 }}>
                <UnitsFields
                  formikProps={this.props.formikPropsValues}
                  unitKey="inoutResultsDetails2"
                  onAddRecord={this.onAddDetailRecord2}
                  onDeleteRecord={this.onDeleteDetailRecord2}
                  onChangeTime={this.onChangeDetailRecord2}
                  isAddButton={this.state.isAddButton2}
                  list={this.state.practitioner2DetailTimeList}
                />
              </FormGroup>
            )}
            {(!this.state.licenseSameFlg ||
              this.state.license1 !== this.state.license2) && (
              <FormikTextField
                name="initial.practitioner2Memo"
                label="備考"
                size="quarterSuperLong"
              />
            )}
          </FormGroup>
        )}

        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            算定時間
          </FormLabel>
          <FormGroup row>
            {(!this.state.isPractitioner2 ||
              this.state.license1 !== this.state.license2) && (
              <FormikTextField
                name="initial.practitioner1CalculatedHours"
                label={`算定時間${this.state.isPractitioner2 ? " 1人目" : ""}`}
                size="smallMedium"
                endAdornmentLabel="時間"
                disabled
                className={this.props.classes.disabledColor}
              />
            )}
            {this.state.isPractitioner2 &&
              this.state.license1 !== this.state.license2 && (
                <FormikTextField
                  name="initial.practitioner2CalculatedHours"
                  label="算定時間 2人目"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
              )}
          </FormGroup>
          <FormGroup row>
            {this.state.isPractitioner2 &&
              this.state.license1 === this.state.license2 && (
                <FormikTextField
                  name="initial.wholeCalculatedHours"
                  label="全体の算定時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                  className={this.props.classes.disabledColor}
                />
              )}
            {this.state.isPractitioner2 &&
              this.state.license1 === this.state.license2 && (
                <FormikTextField
                  className={`${this.props.classes.calculatedHours} ${this.props.classes.disabledColor}`}
                  name="initial.duplicateCalculatedHours"
                  label="2人が重複する算定時間"
                  size="smallMedium"
                  endAdornmentLabel="時間"
                  disabled
                />
              )}
          </FormGroup>
        </FormGroup>
        <FormGroup
          className={`${this.props.classes.categoryGroup} ${this.props.classes.categoryGroupBorder}`}
        >
          <FormLabel className={this.props.classes.categoryLabel}>
            その他
          </FormLabel>
          {this.state.inputClass === DOKOENGO_INPUT_CLASS_LIST.RESULT.value && (
            <FormGroup>
              <FormikCheckbox
                name="initial.emergencySupportFlg"
                label={
                  this.props.formikPropsValues.initialValues.initial
                    .inoutResultsPlanId
                    ? "緊急時対応加算"
                    : "緊急時対応加算*"
                }
                style={{ marginBottom: 0 }}
              />
              <FormHelperText
                className={this.props.classes.checkboxHelperText}
                style={{
                  marginTop: -2,
                  marginBottom: 8,
                  color:
                    this.props.formikPropsValues.errors &&
                    this.props.formikPropsValues.errors.initial &&
                    this.props.formikPropsValues.errors.initial
                      .emergencySupportFlgError !== undefined
                      ? "#ff5656"
                      : ""
                }}
              >
                {this.props.formikPropsValues.initialValues.initial
                  .inoutResultsPlanId
                  ? "同行援護計画が登録されています。緊急時対応加算は算定できません"
                  : "同行援護計画がない場合は緊急時対応加算は必須です"}
              </FormHelperText>
            </FormGroup>
          )}

          <FormikCheckbox
            name="initial.firstAdditionFlg"
            label="初回加算"
            style={{ marginBottom: 0 }}
          />
          {this.props.facility.specificFacilitiesAddition !== "1" && (
            <FormikCheckbox
              name="initial.sputumImplementationFlg"
              label="喀痰吸引等実施"
              style={{ marginBottom: 0 }}
            />
          )}
          {this.state.inputClass === DOKOENGO_INPUT_CLASS_LIST.RESULT.value && (
            <FormikCheckbox
              name="initial.bodyRestrictedStillFlg"
              label="身体拘束廃止未実施"
              disabled={this.state.isDisableBodyRestrictedStillFlg}
              style={{ marginBottom: 0 }}
            />
          )}
          {this.state.inputClass === DOKOENGO_INPUT_CLASS_LIST.RESULT.value &&
            this.state.isDisableBodyRestrictedStillFlg && (
              <FormHelperText className={this.props.classes.checkboxHelperText}>
                身体拘束廃止未実施は令和5年4月から適用が可能です。
              </FormHelperText>
            )}
        </FormGroup>
      </FormGroup>
    );
  }
}

export const InOutReportDialogFields = withStyles(styles)(
  InOutReportDialogFieldsCore
);
