import * as actions from "./actions";
import * as types from "./types";

const initialState: types.CarePlanState = {
  carePlan: [],
  supportCarePlan: {
    id: 0,
    created_at: null,
    updated_at: null,
    target_date: "",
    postal_code: null,
    city_id: null,
    prefecture_name: null,
    address: null,
    tel: null,
    classify_disability_support: 0,
    classify_handicapped_flg: 0,
    date_pay_begin: null,
    date_pay_end: null,
    date_begin_in_service: null,
    pay_months_agreed_physical_care: null,
    pay_months_agreed_outpatient_care_with_physical_care: null,
    pay_months_agreed_housework_assistance: null,
    pay_months_agreed_outpatient_care: null,
    pay_months_agreed_getting_on_and_off: null,
    housework_assistance_base_type: 0,
    housework_assistance_base_type_reason: null,
    housework_assistance_base_type_comment: null,
    usage_other_facility: null,
    user_request: null,
    user_issue: null,
    goals_direction_assistance: null,
    remarks: null,
    staff_comment: null,
    author: null,
    support_care_plan_schedules: []
  },
  supportCarePlanSchedules: [],
  isEditing: false,
  city: [
    {
      label: "",
      value: 0,
      cityCode: "",
      grade: 0,
      grade_202104: 0,
      grade_children_disability_202104: 0,
      grade_202404: 0,
      grade_children_disability_202404: 0
    }
  ]
};

export const carePlan = (
  state = initialState,
  action: actions.ActionTypes
): types.CarePlanState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_SUCCESS:
      return { ...state, carePlan: action.payload };
    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_FAILED:
      return { ...state };

    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS:
      return {
        ...state,
        supportCarePlan: action.payload,
        supportCarePlanSchedules: action.payload.support_care_plan_schedules
      };
    case types.FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED:
      return { ...state };

    case types.UPDATE_SCHEDULES_STARTED:
      return { ...state };
    case types.UPDATE_SCHEDULES_SUCCESS:
      return {
        ...state,
        supportCarePlanSchedules: action.payload.support_care_plan_schedules
      };
    case types.UPDATE_SCHEDULES_FAILED:
      return { ...state };

    case types.SET_EDIT:
      return { ...state, isEditing: true };
    case types.UNSET_EDIT:
      return { ...state, isEditing: false };

    case types.FETCH_CITY_STARTED:
      return { ...state };
    case types.FETCH_CITY_SUCCESS:
      return { ...state, city: action.payload };
    case types.FETCH_CITY_FAILED:
      return { ...state };

    case types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_STARTED:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_NEW_FAILED:
      return { ...state };

    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED:
      return { ...state };

    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED:
      return { ...state };
    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS: {
      const carePlanData = state.carePlan.filter(
        (i) => `${i.id}` !== action.meta.carePlanId
      );
      return { ...state, carePlan: carePlanData };
    }
    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED:
      return { ...state };

    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_STARTED:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_FAILED:
      return { ...state };

    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_STARTED:
      return { ...state };
    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_SUCCESS:
      return { ...state };
    case types.DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_FAILED:
      return { ...state };

    default:
      return state;
  }
};
