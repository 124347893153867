import { AxiosResponse } from "axios";
import request from "@api/index";
import { BaseResponse } from "@api/types";
import { VERSION_URL_201910 } from "@config";

export type GetSupportLedgerListResponse = BaseResponse<
  {
    id: number;
    creation_date: string;
    update_date: string;
    author_name: string;
  }[]
>;

/**
 * 地域定着支援台帳一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
const getSupportLedgerList = async (
  uifId: string
): Promise<AxiosResponse<GetSupportLedgerListResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_ledger`;
  return request.get<GetSupportLedgerListResponse>(url);
};

export default getSupportLedgerList;
