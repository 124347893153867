import * as types from "./types";
import * as actions from "./actions";

const initialState: types.ReportUsagePerformanceState = {
  summary: [],
  usage_performance: []
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportUsagePerformanceState => {
  switch (action.type) {
    case types.FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED:
      return { ...state };
    case types.FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED:
      return { ...state, ...action.error };
    case types.UNSET_SHISETSUNYUSHO_USAGE_PERFORMANCE_SUMMARY:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
