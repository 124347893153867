import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { KEIKAKUSODANReportTypeInterface } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { InitialDataValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import {
  validation,
  submitValidation
} from "@initialize/v202104/mgr/KEIKAKUSODAN/report/validation";
import { InOutReportDialogSubmit } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/dialog/InOutReportDialogSubmit";
import { InOutReportDialogFields } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/dialog/InOutReportDialogFields";
import { InOutReportFormPaper } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/dialog/InOutReportFormPaper";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { AppState } from "@stores/type";
import { InitialValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/report/initial";
import { ResponseError } from "@stores/ui/type";
import { INOUT_RESULTS_ERROR_DUPLICATED } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: 0,
      marginTop: 0,
      "& > :first-child": {
        paddingBottom: 8
      }
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between" as "space-between"
    },
    modalHeaderDate: {
      fontSize: 16
    },
    modal: {
      width: "80%",
      maxWidth: 616
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 0,
      paddingBottom: 20,
      paddingLeft: 0
    },
    modalHeaderArea: {
      height: "15%",
      padding: "12px 32px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: 20,
      backgroundColor: "#f5f5f5"
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    }
  });

type DispatchProps = {
  postKEIKAKUSODANReport: (
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KEIKAKUSODANReportTypeInterface["type"],
    facility: FacilityState,
    usersInFacility: UsersInFacilityState
  ) => Promise<void>;
  responseErrorClear(): void;
};

type StateProps = {
  facilityState: FacilityState;
  responseError: ResponseError;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  usersInFacilityState: UsersInFacilityState;
  data: InitialDataValues;
  selectedDate: Date;
  type: KEIKAKUSODANReportTypeInterface["type"];
  onCancel: () => void;
  onSubmit: () => void;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

const InOutReportDialogCore = (props: Props): JSX.Element => {
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);

  const validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values);
    setIsSubmitDisabled(submitValidation(validationResult));

    const errorCheckResult = toEffectiveObject(validationResult);

    // エラーが存在するかは、toEffectiveObjectで確認
    // エラーが存在する場合は、validationの結果をそのまま返却
    // →FieldArrayを利用する場合、エラーは配列として保持する必要があるため
    // →また、エラー配列と表示データの配列はindexを一致させる必要がある
    // エラーが存在しない場合は、undefinedを返却（errorObjectがundefined）
    return errorCheckResult ? validationResult : errorCheckResult;
  };

  const onCancel = (): void => {
    setIsSubmitDisabled(true);
    props.onCancel();
  };

  const onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postKEIKAKUSODANReport(
        props.data,
        values,
        props.type,
        props.facilityState,
        props.usersInFacilityState
      )
      .then(() => {
        const error = { ...props.responseError };
        const data = error ? (error.data as { error: string }) : null;
        if (
          error.status === INOUT_RESULTS_ERROR_DUPLICATED.STATUS &&
          data &&
          data.error === INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
        ) {
          props.responseErrorClear();
        } else {
          onCancel();
          props.onSubmit();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const { classes } = props;
  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.open}
        classes={{ paper: classes.modal }}
      >
        <Formik
          initialValues={props.data}
          validate={validate}
          onSubmit={onSubmit}
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.formArea}>
              <DialogTitle
                id={props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={props.classes.modalHeader}>
                  <div>利用実績</div>
                  <div>
                    <span className={props.classes.modalHeaderDate}>
                      {props.selectedDate.getFullYear()}
                      <span>年</span>
                      {props.selectedDate.getMonth() + 1}
                      <span>月</span>
                      {props.selectedDate.getDate()}
                      <span>日</span>
                      <span>
                        （
                        {oneLetterWeekdaysJapanese[props.selectedDate.getDay()]}
                        ）
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <span>{props.data.initial.name}</span>
                </div>
              </DialogTitle>
              <DialogContent className={classes.modalContents}>
                <InOutReportFormPaper>
                  <InOutReportDialogFields
                    formikPropsValues={formikProps}
                    setFormikFieldValue={formikProps.setFieldValue}
                    facility={props.facilityState}
                    usersInFacilityState={props.usersInFacilityState}
                  />
                </InOutReportFormPaper>
              </DialogContent>

              <DialogActions className={classes.action}>
                <InOutReportDialogSubmit
                  formikPropsValues={formikProps}
                  onCancel={onCancel}
                  disabled={isSubmitDisabled}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.v202104.KEIKAKUSODAN.facility,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104, uiDispatch } = dispatches;
  const reportDispatcher = v202104.KEIKAKUSODAN.reportDispatcher(dispatch);
  return {
    postKEIKAKUSODANReport: (
      beforeFormValue: InitialValues,
      afterFormValue: InitialValues,
      type: KEIKAKUSODANReportTypeInterface["type"],
      facility: FacilityState,
      usersInFacility: UsersInFacilityState
    ): Promise<void> =>
      reportDispatcher.postKEIKAKUSODANReport(
        beforeFormValue,
        afterFormValue,
        type,
        facility,
        usersInFacility
      ),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDialogCore));
