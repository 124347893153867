import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { OptionInterface } from "@components/atoms/DropDown";
import {
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_CURRENT
} from "@constants/variables";
import {
  FACILITY,
  FACILITY_201910,
  FACILITY_202104,
  USERS_ID,
  USERS_ID_201910,
  USERS_ID_202104,
  REPORT_DAILY,
  REPORT_DAILY_201910,
  REPORT_DAILY_202104,
  REPORT_USERS,
  REPORT_USERS_201910,
  REPORT_USERS_202104
} from "@constants/url";
// 常勤看護職員等配置加算
export enum FullTimeNursePlacementTypes {
  "なし" = 0,
  "常勤看護職員等配置（Ⅰ）", // 1
  "常勤看護職員等配置（Ⅱ）" // 2
}

// 常勤看護職員等配置加算
// 令和3年度報酬改定で追加
export enum NursingSupporterTypes {
  "なし" = 0,
  "常勤看護職員等配置（Ⅰ）", // 1
  "常勤看護職員等配置（Ⅱ）", // 2
  "常勤看護職員等配置（Ⅲ）" // 3
}

// 人員配置体制
export enum StaffPlacementTypes {
  "なし" = 0,
  "Ⅰ型（1.7：1）", // 1
  "Ⅱ型（2：1）", // 2
  "Ⅲ型（2.5：1）", // 3
  "Ⅳ型（3：1）", // 4
  "Ⅴ型（3.5：1）", // 5
  "Ⅵ型（4：1）", // 6
  "Ⅶ型（4.5：1）", // 7
  "Ⅷ型（5：1）", // 8
  "Ⅸ型（5.5：1）", // 9
  "Ⅹ型（6：1）" // 10
}

// 人員配置体制(r6 202404改訂分)
export enum StaffPlacementTypes202404 {
  "なし" = 0,
  "Ⅰ型（1.5：1）", // 1
  "Ⅱ型（1.7：1）", // 2
  "Ⅲ型（2：1）", // 3
  "Ⅳ型（2.5：1）" // 4
}

export enum OpenShortTime {
  NONE = "0",
  LESS_FOUR_HOURS = "1",
  BETWEEN_FOUR_TO_SIX = "2",
  MORE_SIX = "3"
}
export const OPENED_TIME_ITEMS: OptionInterface[] = [
  { label: "なし", value: OpenShortTime.NONE },
  { label: "開所時間４時間未満", value: OpenShortTime.LESS_FOUR_HOURS },
  {
    label: "開所時間４時間以上６時間未満",
    value: OpenShortTime.BETWEEN_FOUR_TO_SIX
  }
];

// 送迎サービス
export const SUPPLY_PICKUP_SERVICE_ITEMS = [
  { label: "送迎サービス（Ⅰ）", value: "1" },
  { label: "送迎サービス（Ⅱ）", value: "2" }
];

// 就労移行支援体制加算 前年度実績就労継続者
export const SUPPORT_IKOU_RESULT_ITEMS = [
  { label: "6ヶ月以上の就労継続者なし", value: "1" },
  { label: "6ヶ月以上の就労継続者あり", value: "2" }
];

// 福祉専門職員配置等加算
export enum WelfareSpecialistPlacementType {
  "なし" = 1, // 1
  "福祉専門職員配置加算 (Ⅰ)", // 2
  "福祉専門職員配置加算 (Ⅱ)", // 3
  "福祉専門職員配置加算 (Ⅲ)" // 4
}

export const WELFARE_SPECAILLIST_PLACEMENT_LIST = [
  { label: "なし", value: "1" },
  { label: "福祉専門職員配置加算 (Ⅰ)", value: "2" },
  { label: "福祉専門職員配置加算 (Ⅱ)", value: "3" },
  { label: "福祉専門職員配置加算 (Ⅲ)", value: "4" }
];

export const WELFARE_CONDITION_COMBINED_LIST = [
  { label: "福祉専門職員配置加算（Ⅰ）＋（Ⅲ）", value: "1" },
  { label: "福祉専門職員配置加算（Ⅱ）＋（Ⅲ）", value: "2" }
];

// 重度障害者支援
export const SEVERE_DISABILITY_SUPPORT_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "あり", value: "1" },
  { label: "あり（基礎研修修了者対応）", value: "2" }
];

// リハビリテーション加算
export const REHABILITATION_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "リハビリテーション加算（Ⅰ）", value: "1" },
  { label: "リハビリテーション加算（Ⅱ）", value: "2" }
];

export const SEIKATSUKAIGO_TYPE_LIST = [
  { label: "生活介護", value: "0" },
  { label: "共生型生活介護サービス（Ⅰ）", value: "1" },
  { label: "共生型生活介護サービス（Ⅱ）", value: "2" },
  { label: "基準該当生活介護サービス（Ⅰ）", value: "5" },
  { label: "基準該当生活介護サービス（Ⅱ）", value: "6" },
  { label: "経過的生活介護", value: "9" }
];

export const SEIKATSUKAIGOReportTabInfo = {
  DAILY: "0",
  MONTHLY: "1"
};

export const SEIKATSUKAIGO_REPORT_TABS_INFO = [
  { label: "日ごと", value: SEIKATSUKAIGOReportTabInfo.DAILY },
  { label: "月ごと", value: SEIKATSUKAIGOReportTabInfo.MONTHLY }
];

// 重度障害者支援加算
export const SERIOUS_DISABILITY_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "重度障害者支援加算（Ⅰ）", value: "1" },
  { label: "重度障害者支援加算（Ⅱ）または（Ⅲ）", value: "2" }
];
// 202104版
export const SERIOUS_DISABILITY_TYPE_LIST_V202104: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "重度障害者支援加算（Ⅰ）", value: "1" },
  { label: "重度障害者支援加算（Ⅱ）", value: "2" }
];

// 利用実績編集モーダル: 重度障害者支援加算
export const SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT: RadioItemInterface[] = [
  { label: "-", value: "0" },
  { label: "行動関連項目10点以上18点未満の利用者に提供", value: "1" },
  { label: "行動関連項目18点以上の利用者に提供", value: "2" }
];

// 施設区分：共生型生活介護サービス(1, 2),基準該当生活介護サービス(5, 6)
export const DISABLE_FACILITY_TYPE = [1, 2, 5, 6];
export const DISPLAY_NONE_STANDARD_FACILITY_TYPE = [5, 6];

// 施設区分：共生型生活介護サービス
export const ENABLE_FACILITY_TYPE = [1, 2];

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";

/** 生活介護の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V201910,
      url: FACILITY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_DAILY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_USERS_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V201910,
      url: USERS_ID_201910.replace(":id", userId)
    }
  ]
};
