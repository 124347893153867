import { format } from "date-fns";
import isEqual from "lodash-es/isEqual";

import { Assessment } from "@initialize/record/assessment/initialValues";
import {
  RequestParam,
  HistoryBase,
  FlowDayDetail
} from "@api/requests/assessment/postAssessment";

import { selectDateValueToDate } from "@utils/date";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";

type HistoryFormType =
  | NonNullable<
      Assessment["assessment_records"][number]["input"][number]["life_histories"]
    >[number]
  | NonNullable<
      Assessment["assessment_records"][number]["input"][number]["disability_histories"]
    >[number];
/** 生活歴、障害・疾病歴のnormalize関数 */
const normalizeHistory = (
  currentValue: HistoryFormType,
  beforeValue?: HistoryFormType | null
): HistoryBase => {
  const history: HistoryBase = {
    id: currentValue.id,
    yyyymm: currentValue.yyyymm,
    matter: currentValue.matter,
    is_delete: currentValue.isDelete
  };
  if (beforeValue) {
    if (history.yyyymm === beforeValue.yyyymm) delete history.yyyymm;
    if (history.matter === beforeValue.matter) delete history.matter;
  }
  return history;
};

/** 生活歴、障害・疾病歴のフィルター */
const filterHistory = (history: HistoryBase): boolean => {
  return (
    {}.hasOwnProperty.call(history, "yyyymm") ||
    {}.hasOwnProperty.call(history, "matter") ||
    history.is_delete === 1
  );
};

type FlowDayDetailFormType =
  | NonNullable<
      Assessment["assessment_records"][number]["input"][number]["flow_days"]
    >[number]["flow_day_details_user"][number]
  | NonNullable<
      Assessment["assessment_records"][number]["input"][number]["flow_days"]
    >[number]["flow_day_details_caregiver"][number];

/** １日の流れの本人、介護者のnormalize処理 */
const normalizeFlowDayDetail = (
  currentValue: FlowDayDetailFormType,
  beforeValue?: FlowDayDetailFormType | null
): FlowDayDetail => {
  const flowDetail: FlowDayDetail = {
    id: currentValue.id,
    start_time: currentValue.start_time
      ? `${currentValue.start_time}:00`
      : null,
    end_time: currentValue.end_time ? `${currentValue.end_time}:00` : null,
    matter: currentValue.matter,
    is_delete: currentValue.isDelete
  };
  if (beforeValue) {
    if (flowDetail.start_time === `${beforeValue.start_time}:00`)
      delete flowDetail.start_time;
    if (flowDetail.end_time === `${beforeValue.end_time}:00`)
      delete flowDetail.end_time;
    if (flowDetail.matter === beforeValue.matter) delete flowDetail.matter;
  }
  return flowDetail;
};

/** １日の流れ本人、介護者の変更のない値の除外 */
const filterFlowDayDetail = (value: FlowDayDetail): boolean => {
  return (
    {}.hasOwnProperty.call(value, "start_time") ||
    {}.hasOwnProperty.call(value, "end_time") ||
    {}.hasOwnProperty.call(value, "matter") ||
    value.is_delete === 1
  );
};

export const normalizePostAssessmentDataToAPI = (
  initialAssessment: Assessment,
  assessment: Assessment,
  typeConsultation?: number
): RequestParam => {
  const custom_record: RequestParam["custom_record"] = [];
  assessment.assessment_records.forEach((record) => {
    record.input.forEach((afterInput) => {
      const beforeRecord = initialAssessment.assessment_records.find(
        (a) =>
          a.custom_records_category_id === record.custom_records_category_id
      );
      const beforeInput = beforeRecord
        ? beforeRecord.input.find(
            (i) => i.custom_record_item_id === afterInput.custom_record_item_id
          )
        : null;
      if (!isEqual(afterInput, beforeInput)) {
        const reqInput: RequestParam["custom_record"][number] = {
          custom_records_category_id: record.custom_records_category_id,
          custom_record_input_id: afterInput.id,
          custom_record_item_id: afterInput.custom_record_item_id,
          input_data: afterInput.input_data
        };
        // input_type=5(radio)の場合
        if (
          "choiced_item_id" in afterInput &&
          afterInput.choiced_item_id !== undefined
        ) {
          if (afterInput.choiced_item_id === "") {
            reqInput.choiced_item_id = null;
            reqInput.checked = 0;
          } else {
            reqInput.choiced_item_id = +afterInput.choiced_item_id;
            reqInput.checked = 1;
          }
        }
        // input_type=2(checkbox)の場合
        if ("checked" in afterInput && afterInput.checked !== undefined) {
          reqInput.choiced_item_id = stringToNumber(afterInput.choiced_item_id);
          reqInput.checked = booleanToNumber0or1(afterInput.checked);
          reqInput.input_data = undefined;
        }
        // input_type=4(multi text)の場合
        if ("multiTexts" in afterInput && afterInput.multiTexts) {
          const multiTextValues: {
            choiceId: number | null;
            value: string;
            inputId: number | null;
          }[] = Object.keys(afterInput.multiTexts)
            .map((key) => {
              if (!afterInput.multiTexts)
                return { value: "", choiceId: null, inputId: null };
              const targetText = afterInput.multiTexts[key];
              return {
                choiceId: Number(key),
                value: targetText.value,
                inputId: targetText.id
              };
            })
            .filter((afterValue) => {
              // 変更のあった項目だけを抽出する
              if (
                !beforeInput ||
                !beforeInput.multiTexts ||
                !afterValue.choiceId
              ) {
                return true;
              }
              const beforeValue = beforeInput.multiTexts[afterValue.choiceId];
              return afterValue.value !== beforeValue.value;
            });
          const lastMultiText = multiTextValues.pop();
          // 最後の要素は共通pushで追加
          reqInput.input_data = lastMultiText ? lastMultiText.value : "";
          reqInput.choiced_item_id = lastMultiText
            ? lastMultiText.choiceId
            : null;
          reqInput.custom_record_input_id = lastMultiText
            ? lastMultiText.inputId
            : null;
          // 最後の要素以外はここでpush
          multiTextValues
            .filter((v) => v.choiceId !== null)
            .forEach((targetText) => {
              custom_record.push({
                ...reqInput,
                custom_record_input_id: targetText.inputId,
                input_data: targetText.value,
                choiced_item_id: targetText.choiceId
              });
            });
        }
        // input_type=9(カスタム入力形式外)の場合
        if (
          "work_histories" in afterInput &&
          afterInput.work_histories != null
        ) {
          reqInput.input_data = undefined;
          reqInput.work_histories = afterInput.work_histories.map((v) => {
            return {
              ...v,
              begin_date:
                v.begin_date.year !== "" && v.begin_date.month !== ""
                  ? format(
                      selectDateValueToDate({ ...v.begin_date, day: "1" }),
                      "YYYYMM"
                    )
                  : "",
              end_date:
                v.end_date.year !== "" && v.end_date.month !== ""
                  ? format(
                      selectDateValueToDate({ ...v.end_date, day: "1" }),
                      "YYYYMM"
                    )
                  : ""
            };
          });
        }
        // 評価期間の場合
        if ("evaluation_period" in afterInput && afterInput.evaluation_period) {
          // 評価期間は afterInput が一つに対して reqInput は開始と終了で二つ必要なので、evaluation_periodを分割する必要がある。
          const { start, end } = afterInput.evaluation_period;
          // startは先にレコードにpushする。
          const startRecord: RequestParam["custom_record"][number] = {
            custom_records_category_id: reqInput.custom_records_category_id,
            custom_record_input_id: start.id,
            custom_record_item_id: reqInput.custom_record_item_id,
            input_data: start.date.day
              ? format(selectDateValueToDate(start.date), "YYYY-MM-DD")
              : "",
            choiced_item_id: start.choiced_item_id
          };
          custom_record.push(startRecord);

          // 終了はreqInputでpushする
          reqInput.input_data = end.date.day
            ? format(selectDateValueToDate(end.date), "YYYY-MM-DD")
            : "";
          reqInput.custom_record_input_id = end.id;
          reqInput.choiced_item_id = end.choiced_item_id;
        }
        // 生活歴の場合
        if ("life_histories" in afterInput && afterInput.life_histories) {
          const lifeHistories = afterInput.life_histories
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["life_histories"]
              >[number]
            >((lifeHistory) => {
              const beforeHistory =
                beforeInput &&
                beforeInput.life_histories &&
                beforeInput.life_histories.find((v) => v.id === lifeHistory.id);
              return normalizeHistory(lifeHistory, beforeHistory);
            })
            .filter(filterHistory);
          reqInput.life_histories = lifeHistories;
        }
        // 障害・疾病歴の場合
        if (
          "disability_histories" in afterInput &&
          afterInput.disability_histories
        ) {
          const disabilityHistories = afterInput.disability_histories
            .map<
              NonNullable<
                RequestParam["custom_record"][number]["disability_histories"]
              >[number]
            >((disabilityHistory) => {
              const beforeHistory =
                beforeInput &&
                beforeInput.disability_histories &&
                beforeInput.disability_histories.find(
                  (v) => v.id === disabilityHistory.id
                );
              return normalizeHistory(disabilityHistory, beforeHistory);
            })
            .filter(filterHistory);
          reqInput.disability_histories = disabilityHistories;
        }
        // 生活状況 > １日の流れの場合
        if ("flow_days" in afterInput && afterInput.flow_days) {
          const flowDays = afterInput.flow_days.map<
            NonNullable<
              RequestParam["custom_record"][number]["flow_days"]
            >[number]
          >((flowDay) => {
            const beforeFlowDay =
              beforeInput &&
              beforeInput.flow_days &&
              beforeInput.flow_days.find((v) => v.id === flowDay.id);
            const data: NonNullable<
              RequestParam["custom_record"][number]["flow_days"]
            >[number] = {
              id: flowDay.id,
              title: flowDay.title,
              is_delete: flowDay.isDelete,
              flow_day_details_user: flowDay.flow_day_details_user
                .map<
                  NonNullable<
                    RequestParam["custom_record"][number]["flow_days"]
                  >[number]["flow_day_details_user"][number]
                >((flowDayDetailUser) => {
                  const beforeFlowDetail =
                    beforeFlowDay &&
                    beforeFlowDay.flow_day_details_user &&
                    beforeFlowDay.flow_day_details_user.find(
                      (v) => v.id === flowDayDetailUser.id
                    );
                  return normalizeFlowDayDetail(
                    flowDayDetailUser,
                    beforeFlowDetail
                  );
                })
                .filter(filterFlowDayDetail),
              flow_day_details_caregiver: flowDay.flow_day_details_caregiver
                .map<
                  NonNullable<
                    RequestParam["custom_record"][number]["flow_days"]
                  >[number]["flow_day_details_caregiver"][number]
                >((flowDayDetailCaregiver) => {
                  const beforeFlowDetail =
                    beforeFlowDay &&
                    beforeFlowDay.flow_day_details_caregiver &&
                    beforeFlowDay.flow_day_details_caregiver.find(
                      (v) => v.id === flowDayDetailCaregiver.id
                    );
                  return normalizeFlowDayDetail(
                    flowDayDetailCaregiver,
                    beforeFlowDetail
                  );
                })
                .filter(filterFlowDayDetail)
            };
            // データが存在する場合の未変更データ削除処理
            if (beforeFlowDay) {
              if (beforeFlowDay.title === data.title) delete data.title;
            }
            return data;
          });
          reqInput.flow_days = flowDays;
        }
        custom_record.push(reqInput);
      }
      return false;
    });
  });
  let request: RequestParam = {
    setting_type: 4,
    assessment_record_id: assessment.assessment_records_id,
    target_date: format(
      selectDateValueToDate(assessment.target_date),
      "YYYY-MM-DD"
    ),
    custom_record
  };
  if (String(assessment.author) !== String(initialAssessment.author)) {
    request = {
      ...request,
      author: assessment.author
    };
  }
  if (typeConsultation !== undefined) {
    request = {
      ...request,
      type_consultation: typeConsultation
    };
  }
  return request;
};
