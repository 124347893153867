import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { UserState } from "@stores/domain/user/type";
// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryFormMonthly } from "@components/organisms/record/serviceDelivery/ServiceDeliveryFormMonthly";
import { FacilityType } from "@constants/variables";

type StateProps = {
  serviceDeliveryUser: ServiceDeliveryState["user"];
  userState: UserState;
};

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  fetchStaffs: () => void;
  fetchUser: (id: string, facilityType: FacilityType) => void;
};

type OwnProps = RouteComponentProps<{
  uifId: string;
  date: string;
}>;

type Props = OwnProps &
  StateProps &
  DispatchProps & {
    staffOptions: FieldItem[];
  };

/**
 * サービス提供記録（新規作成）月ごと
 */
export const ServiceDeliveryNewMonthlyCore = (props: Props): JSX.Element => {
  const { uifId, date } = props.match.params;
  const user = props.serviceDeliveryUser.user_in_facility;

  const pageName =
    user.name_sei !== undefined && user.name_mei !== undefined
      ? `サービス提供記録${"　"}${user.name_sei} ${user.name_mei}`
      : "";

  // fetch
  React.useEffect(() => {
    Promise.all([
      props.fetchCustomRecords(),
      props.fetchStaffs(),
      props.fetchUser(uifId, props.userState.facility_type)
    ]);
  }, []);

  return (
    <AdminTemplate pageName={pageName}>
      <ServiceDeliveryFormMonthly
        targetDate={date}
        isEdit={false}
        history={props.history}
        user={user}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  serviceDeliveryUser: state.serviceDelivery.user,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    serviceDelivery,
    customRecordsWithCategory,
    staffDispatcher
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchUser: (id: string, facilityType: FacilityType): Promise<void> =>
      serviceDeliveryDispatches.fetchUser(id, facilityType)
  };
};

export const ServiceDeliveryNewMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryNewMonthlyCore);
