import { BasicErrors } from "@interfaces/mgr/SEIKATSUKAIGO/Users/basic";
import { ServiceUseErrors } from "@interfaces/mgr/SEIKATSUKAIGO/Users/serviceUse";
import { RecipientCertificateErrors } from "@interfaces/mgr/SEIKATSUKAIGO/Users/recipientCertificate";
import { UsersValues } from "@initialize/mgr/SEIKATSUKAIGO/users/initialValues";
import validator, {
  dateValidator,
  validateSwitcher,
  dateLimitValidator
} from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import castString from "@utils/dataNormalizer/castString";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type FacilityErrors = BasicErrors &
  ServiceUseErrors &
  RecipientCertificateErrors;

const basicValidation = (values: UsersValues): BasicErrors => {
  return {
    basic: {
      nameSei: validator(values.basic.nameSei, "required"),
      nameMei: validator(values.basic.nameMei, "required"),
      nameSeiKana: validator(values.basic.nameSeiKana, "required", "kana"),
      nameMeiKana: validator(values.basic.nameMeiKana, "required", "kana"),
      recipientNumber: validator(
        values.basic.recipientNumber,
        {
          type: "required",
          shouldValidate: !values.basic.noneRecipientNumberFlag
        },
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      dateOfBirth: dateValidator(
        notSelectedToEmpty(values.basic.dateOfBirth),
        "required"
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "naturalNumber"),
      email: validator(values.basic.email, "email"),
      guardianTel: validator(values.basic.guardianTel, "naturalNumber")
    }
  };
};

const serviceUseValidation = (values: UsersValues): ServiceUseErrors => {
  return {
    serviceUse: {
      inServiceStartDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.inServiceStartDate),
        "required"
      ),
      inServiceEndDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.inServiceEndDate),
        {
          type: "future",
          startDate: values.serviceUse.inServiceStartDate,
          options: {
            startLabel: "サービス提供開始日",
            endLabel: "サービス提供終了日"
          }
        }
      ),
      payStartDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.payStartDate),
        "required"
      ),
      payEndDate: dateValidator(
        notSelectedToEmpty(values.serviceUse.payEndDate),
        "required",
        {
          type: "future",
          startDate: values.serviceUse.payStartDate,
          options: {
            startLabel: "支給決定開始日",
            endLabel: "支給決定終了日"
          }
        }
      ),
      payDaysAgreed: validateSwitcher(
        values.serviceUse.agreedByContractFlg === "2",
        validator(
          values.serviceUse.payDaysAgreed,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 31
          }
        )
      ),
      businessNumberContract: validateSwitcher(
        values.serviceUse.agreedByContractFlg === "2",
        validator(
          values.serviceUse.businessNumberContract,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 2 }
        )
      ),
      subsidizedPercent: validateSwitcher(
        values.serviceUse.subsidizedFlag &&
          values.serviceUse.subsidizedUnit === "1",
        validator(
          values.serviceUse.subsidizedPercent,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 100
          }
        )
      ),
      subsidizedYen: validateSwitcher(
        values.serviceUse.subsidizedFlag &&
          values.serviceUse.subsidizedUnit === "2",
        validator(
          values.serviceUse.subsidizedYen,
          "required",
          "naturalNumberNonZero"
        )
      ),
      subsidizedCityId: validateSwitcher(
        values.serviceUse.subsidizedFlag,
        validator(values.serviceUse.subsidizedCityId, {
          type: "selectRequired",
          value: DEFAULT_SELECT_VALUE
        })
      ),
      upperLimitFacilityNumber: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag,
        validator(
          values.serviceUse.upperLimitFacilityNumber,
          "required",
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      upperLimitFacilityNumber2: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag,
        validator(
          values.serviceUse.upperLimitFacilityNumber2,
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      upperLimitFacilityNumber3: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag,
        validator(
          values.serviceUse.upperLimitFacilityNumber3,
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      upperLimitFacilityNumber4: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag,
        validator(
          values.serviceUse.upperLimitFacilityNumber4,
          "naturalNumber",
          { type: "checkDigits", digits: 10 }
        )
      ),
      upperLimitFacilityName: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag,
        validator(
          castString(values.serviceUse.upperLimitFacilityName),
          "required"
        )
      ),
      upperLimitFacilityName2: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          !!values.serviceUse.upperLimitFacilityNumber2,
        validator(
          castString(values.serviceUse.upperLimitFacilityName2),
          {
            type: "checkCharacterLength",
            length: 2500
          },
          "required"
        )
      ),
      upperLimitFacilityName3: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          !!values.serviceUse.upperLimitFacilityNumber3,
        validator(
          castString(values.serviceUse.upperLimitFacilityName3),
          {
            type: "checkCharacterLength",
            length: 2500
          },
          "required"
        )
      ),
      upperLimitFacilityName4: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          !!values.serviceUse.upperLimitFacilityNumber4,
        validator(
          castString(values.serviceUse.upperLimitFacilityName4),
          {
            type: "checkCharacterLength",
            length: 2500
          },
          "required"
        )
      ),
      upperLimitTotalYen: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(values.serviceUse.upperLimitTotalYen, "naturalNumberNonZero")
      ),
      upperLimitTotalYen2: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(values.serviceUse.upperLimitTotalYen2, "naturalNumberNonZero")
      ),
      upperLimitTotalYen3: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(values.serviceUse.upperLimitTotalYen3, "naturalNumberNonZero")
      ),
      upperLimitTotalYen4: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(values.serviceUse.upperLimitTotalYen4, "naturalNumberNonZero")
      ),
      upperLimitUserLoadYen: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(
          values.serviceUse.upperLimitUserLoadYen,
          "naturalNumberNonZero"
        )
      ),
      upperLimitUserLoadYen2: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(
          values.serviceUse.upperLimitUserLoadYen2,
          "naturalNumberNonZero"
        )
      ),
      upperLimitUserLoadYen3: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(
          values.serviceUse.upperLimitUserLoadYen3,
          "naturalNumberNonZero"
        )
      ),
      upperLimitUserLoadYen4: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1",
        validator(
          values.serviceUse.upperLimitUserLoadYen4,
          "naturalNumberNonZero"
        )
      ),
      uplimitUserLoadAfterAdjustmentYen: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1" &&
          values.serviceUse.resultOfManagement === "3",

        validator(
          values.serviceUse.uplimitUserLoadAfterAdjustmentYen,
          "required",
          "naturalNumberNonZero"
        )
      ),
      uplimitUserLoadAfterAdjustmentYen2: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1" &&
          values.serviceUse.resultOfManagement === "3" &&
          !!values.serviceUse.upperLimitFacilityNumber2,

        validator(
          values.serviceUse.uplimitUserLoadAfterAdjustmentYen2,
          "required",
          "naturalNumberNonZero"
        )
      ),
      uplimitUserLoadAfterAdjustmentYen3: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1" &&
          values.serviceUse.resultOfManagement === "3" &&
          !!values.serviceUse.upperLimitFacilityNumber3,

        validator(
          values.serviceUse.uplimitUserLoadAfterAdjustmentYen3,
          "required",
          "naturalNumberNonZero"
        )
      ),
      uplimitUserLoadAfterAdjustmentYen4: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.upperLimitControlledBy === "1" &&
          values.serviceUse.resultOfManagement === "3" &&
          !!values.serviceUse.upperLimitFacilityNumber4,

        validator(
          values.serviceUse.uplimitUserLoadAfterAdjustmentYen4,
          "required",
          "naturalNumberNonZero"
        )
      ),
      upperLimitYen: validateSwitcher(
        values.serviceUse.upperLimitFacilityFlag &&
          values.serviceUse.resultOfManagement === "3",
        validator(
          values.serviceUse.upperLimitYen,
          "required",
          "naturalNumberNonZero"
        )
      ),
      notCreateSupportPlanStartDate: validateSwitcher(
        values.serviceUse.createSupportPlanFlag,
        dateValidator(
          notSelectedToEmpty(values.serviceUse.notCreateSupportPlanStartDate),
          "required"
        )
      ),
      rehabilitationStartDate: validateSwitcher(
        values.serviceUse.rehabilitation === "1" ||
          values.serviceUse.rehabilitation === "2",
        dateValidator(
          notSelectedToEmpty(values.serviceUse.rehabilitationStartDate),
          "required"
        )
      ),
      // ４月スコープアウトのためコメントアウト
      // 実装時チケット https://gogopmpr.backlog.jp/view/KNOWBE_DEV-2072
      // intensiveSupportStartDate: validateSwitcher(
      //   values.serviceUse.intensiveSupportFlg,
      //   dateValidator(
      //     notSelectedToEmpty(values.serviceUse.intensiveSupportStartDate),
      //     "required"
      //   )
      // ),
      severeDisabilitySupportStartData: validateSwitcher(
        values.serviceUse.severeDisabilitySupport,
        dateValidator(
          notSelectedToEmpty(
            values.serviceUse.severeDisabilitySupportStartData
          ),
          "required"
        )
      ),
      midMonthChangeDisabilityClass: validateSwitcher(
        values.serviceUse.midMonthChangeFlg,
        validator(values.serviceUse.midMonthChangeDisabilityClass, {
          type: "optionNotMatch",
          value: values.serviceUse.disabilityClass
        })
      ),
      midMonthChangeDate: validateSwitcher(
        values.serviceUse.midMonthChangeFlg,
        dateValidator(values.serviceUse.midMonthChangeDate, "required")
      ),
      dayUseSpecialCaseStartDate: validateSwitcher(
        values.serviceUse.dayUseSpecialCaseFlag,
        dateValidator(
          notSelectedToEmpty({
            ...values.serviceUse.dayUseSpecialCaseStartDate,
            day: "1"
          }),
          "required"
        )
      ),
      dayUseSpecialCaseEndDate: validateSwitcher(
        values.serviceUse.dayUseSpecialCaseFlag,
        dateLimitValidator(
          notSelectedToEmpty({
            ...values.serviceUse.dayUseSpecialCaseStartDate,
            day: "1"
          }),
          notSelectedToEmpty({
            ...values.serviceUse.dayUseSpecialCaseEndDate,
            day: "1"
          }),
          12,
          3,
          "dayValueLimit",
          "required"
        )
      ),
      monCalculatedHours: validateSwitcher(
        values.serviceUse.monScheduledFlg,
        validator(
          values.serviceUse.monCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      tueCalculatedHours: validateSwitcher(
        values.serviceUse.tueScheduledFlg,
        validator(
          values.serviceUse.tueCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      wedCalculatedHours: validateSwitcher(
        values.serviceUse.wedScheduledFlg,
        validator(
          values.serviceUse.wedCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      thuCalculatedHours: validateSwitcher(
        values.serviceUse.thuScheduledFlg,
        validator(
          values.serviceUse.thuCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      friCalculatedHours: validateSwitcher(
        values.serviceUse.friScheduledFlg,
        validator(
          values.serviceUse.friCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      satCalculatedHours: validateSwitcher(
        values.serviceUse.satScheduledFlg,
        validator(
          values.serviceUse.satCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      ),
      sunCalculatedHours: validateSwitcher(
        values.serviceUse.sunScheduledFlg,
        validator(
          values.serviceUse.sunCalculatedHours,
          "required",
          "decimalPoint",
          "decimalPointByEveryHalfMinutes"
        )
      )
    }
  };
};

const recipientCertificateValidation = (
  values: UsersValues
): RecipientCertificateErrors => {
  return {
    recipientCertificate: {
      userChargeLimitStartDate: validateSwitcher(
        values.recipientCertificate.userChargeLimitFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.userChargeLimitStartDate
          ),
          "required"
        )
      ),
      userChargeLimitEndDate: validateSwitcher(
        values.recipientCertificate.userChargeLimitFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.userChargeLimitEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.userChargeLimitStartDate,
            options: {
              startLabel: "適用開始日",
              endLabel: "適用終了日"
            }
          }
        )
      ),
      foodServeAdditionStartDate: validateSwitcher(
        values.recipientCertificate.foodServeAdditionFlg,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.foodServeAdditionStartDate
          ),
          "required"
        )
      ),
      foodServeAdditionEndDate: validateSwitcher(
        values.recipientCertificate.foodServeAdditionFlg,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.foodServeAdditionEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.foodServeAdditionStartDate,
            options: {
              startLabel: "適用開始日",
              endLabel: "適用終了日"
            }
          }
        )
      ),
      careSupportAuthStartDate: validateSwitcher(
        values.recipientCertificate.careSupportAuthFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.careSupportAuthStartDate
          ),
          "required"
        )
      ),
      careSupportAuthEndDate: validateSwitcher(
        values.recipientCertificate.careSupportAuthFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.careSupportAuthEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.careSupportAuthStartDate,
            options: {
              startLabel: "認定開始日",
              endLabel: "認定終了日"
            }
          }
        )
      ),
      careSupportPaymentStartDate: validateSwitcher(
        values.recipientCertificate.careSupportPaymentFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.careSupportPaymentStartDate
          ),
          "required"
        )
      ),
      careSupportPaymentEndDate: validateSwitcher(
        values.recipientCertificate.careSupportPaymentFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.careSupportPaymentEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.careSupportPaymentStartDate,
            options: {
              startLabel: "支給決定開始日",
              endLabel: "支給決定終了日"
            }
          }
        )
      ),

      planSupportPaymentStartDate: validateSwitcher(
        values.recipientCertificate.planSupportPaymentFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.planSupportPaymentStartDate
          ),
          "required"
        )
      ),
      planSupportPaymentEndDate: validateSwitcher(
        values.recipientCertificate.planSupportPaymentFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.planSupportPaymentEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.planSupportPaymentStartDate,
            options: {
              startLabel: "支給開始日",
              endLabel: "支給終了日"
            }
          }
        )
      ),
      planSupportMonitorStartDate: validateSwitcher(
        values.recipientCertificate.planSupportMonitorFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.planSupportMonitorStartDate
          ),
          "required"
        )
      ),
      planSupportMonitorEndDate: validateSwitcher(
        values.recipientCertificate.planSupportMonitorFlag,
        dateValidator(
          notSelectedToEmpty(
            values.recipientCertificate.planSupportMonitorEndDate
          ),
          "required",
          {
            type: "future",
            startDate: values.recipientCertificate.planSupportMonitorStartDate,
            options: {
              startLabel: "モニタリング開始日",
              endLabel: "モニタリング終了日"
            }
          }
        )
      )
    }
  };
};

const validation = (values: UsersValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const serviceUseErrors = serviceUseValidation(values);
  const recipientCertificateErrors = recipientCertificateValidation(values);
  return { ...basicErrors, ...serviceUseErrors, ...recipientCertificateErrors };
};

export default validation;
