/**
 * 未来日判定（日付のみ比較）
 */
export const compareDateFuture = (
  baseDate: Date,
  targetDate: Date
): boolean => {
  const year1 = baseDate.getFullYear();
  const month1 = baseDate.getMonth() + 1;
  const day1 = baseDate.getDate();
  const year2 = targetDate.getFullYear();
  const month2 = targetDate.getMonth() + 1;
  const day2 = targetDate.getDate();

  if (year1 === year2) {
    if (month1 === month2) {
      return day2 > day1;
    }
    return month2 > month1;
  }
  return year2 > year1;
};
