import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.BasicInfoState = {
  basicInfo: {
    created_at: null,
    updated_at: null,
    basic_info: {
      basic_info_id: null,
      creation_date: "",
      author: null,
      support_progress: null,
      date_birth: null,
      age: null,
      postal_code: null,
      city_id: null,
      prefecture_name: null,
      address: null,
      residence: null,
      residence_other: null,
      tel: null,
      fax: null,
      disability_name: null,
      classify_disability_support: null,
      gender: null,
      family_structure: null,
      social_relationship: null,
      life_history: null,
      medical_situation: null,
      self_complaint: null,
      family_complaint: null,
      public_support: {
        basic_info_details_id: null,
        name: null,
        offer_name: null,
        support_contents: null,
        frequency: null,
        memo: null
      },
      other_support: {
        basic_info_details_id: null,
        name: null,
        offer_name: null,
        support_contents: null,
        frequency: null,
        memo: null
      },
      schedule: [],
      main_activity: null,
      non_weekly_service: null,
      type_consultation: 0
    }
  },
  basicInfoList: []
};

export const KEIKAKUSODANBasicInfo = (
  state = initialState,
  action: actions.ActionTypes
): types.BasicInfoState => {
  switch (action.type) {
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_STARTED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_SUCCESS:
      return { ...state, basicInfoList: action.payload };
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_FAILED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_STARTED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_SUCCESS:
      return { ...state, basicInfo: action.payload };
    case types.FETCH_KEIKAKUSODAN_BASIC_INFO_FAILED:
      return { ...state };
    case types.UNSET_KEIKAKUSODAN_BASIC_INFO:
      return { ...state, ...initialState };
    case types.DELETE_KEIKAKUSODAN_BASIC_INFO_STARTED:
      return { ...state };
    case types.DELETE_KEIKAKUSODAN_BASIC_INFO_SUCCESS:
      return { ...state };
    case types.DELETE_KEIKAKUSODAN_BASIC_INFO_FAILED:
      return { ...state };
    default:
      return state;
  }
};
