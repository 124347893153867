import React, { useState } from "react";
import * as H from "history";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AddToPhotosOutlined from "@material-ui/icons/AddToPhotosOutlined";

// utils
import { dateToLocalisedString } from "@/utils/date";
import { RecordHeader } from "./RecordHeader";
import { ConsultationSwitchDialog } from "@components/organisms/ConsultationSwitchDialog";
import {
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  PARAMETER_TYPE_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN,
  PARAMETER_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    list: {
      background: "#fff",
      borderRadius: "4px"
    },
    listCon: {
      marginTop: "32px",
      padding: "0 40px"
    },
    item: {
      borderTop: "1px solid #b2b2b2",
      "&:nth-child(n+2)": {
        marginTop: "24px"
      }
    },
    itemTitle: {
      borderBottom: "1px solid #d9d9d9",
      background: " rgba(245, 245, 245, 0.38)",
      padding: "10px 8px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    itemTitleText: {
      display: "flex",
      alignItems: "center",
      "&>p": {
        fontFamily: "HiraginoSans-W4",
        fontSize: "16px",
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.87)",
        margin: 0,
        "&:nth-child(n+2)": {
          marginLeft: "40px"
        }
      }
    },
    itemBtn: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 8px 16px"
    },
    itemBtnIcon: {
      color: "#0277bd"
    },
    itemBtnText: {
      display: "block",
      marginLeft: "4px",
      color: "#0277bd",
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "1.25px"
    },
    listMessage: {
      padding: "80px 32px",
      textAlign: "center",
      fontFamily: "HiraginoSans-W4",
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: " rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  basicInfoList: BasicInfoState["basicInfoList"];
  history: H.History;

  consultationType?: number;
};

type StateProps = {
  facility: FacilityState;
};

type DispatchProps = {
  fetchFacility: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

/**
 * 基本情報一覧
 *
 */
const BasicInfoRecordListCore = (props: Props): JSX.Element | null => {
  const { basicInfoList, classes, uifId, consultationType = 1 } = props;

  const [isModalOpen, toggleModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [transitionUrl, setIsTransitionUrl] = useState<string>("");
  const [defaultValue, setDefaultValue] = useState<string>("");

  const openModalNew = (): void => {
    toggleModal(true);
    setIsNew(true);
    setIsTransitionUrl(`#/record/support_plan/${uifId}/basic_info/new`);
    setDefaultValue(`${consultationType}`);
  };

  const openModalCopy = ({
    id,
    type_consultation
  }: BasicInfoState["basicInfoList"][number]): void => {
    toggleModal(true);
    setIsNew(false);
    setIsTransitionUrl(`#/record/support_plan/${uifId}/basic_info/${id}/copy`);
    setDefaultValue(`${type_consultation}`);
  };

  React.useEffect(() => {
    props.fetchFacility();
  }, []);

  const closeModal = (): void => {
    toggleModal(false);
  };

  const modalButton = (
    <KnowbeButton kind="default" onClick={openModalNew}>
      新規作成
    </KnowbeButton>
  );

  const createButton = (
    <KnowbeButton
      kind="default"
      href={`#/record/support_plan/${uifId}/basic_info/new/?type=${
        props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN
          ? PARAMETER_TYPE_SHOGAIJISODAN
          : PARAMETER_TYPE_KEIKAKUSODAN
      }`}
    >
      新規作成
    </KnowbeButton>
  );

  return (
    <>
      <ConsultationSwitchDialog
        isModalOpen={isModalOpen}
        onClose={closeModal}
        isNew={isNew}
        copyTitle="申請者の現状（基本情報）をコピー"
        transitionUrl={transitionUrl}
        defaultValue={defaultValue}
      />
      <div className={classes.list}>
        <RecordHeader
          pageName="申請者の現状（基本情報）一覧"
          userName={props.userName}
          uifId={props.uifId}
          recordType="basic_info"
          isEditing={false}
          history={props.history}
          button={
            props.facility.facilityType ===
            FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
              ? modalButton
              : createButton
          }
        />
        {basicInfoList.length !== 0 ? (
          <div className={classes.listCon}>
            {basicInfoList.map((item, index) => {
              const key = `key_${index}`;
              return (
                <div className={classes.item} key={key}>
                  <div className={classes.itemTitle}>
                    <div className={classes.itemTitleText}>
                      <p>
                        作成日：
                        {dateToLocalisedString(
                          item.target_date,
                          "YYYY年M月D日"
                        )}
                      </p>
                      <p>作成者：{item.author_name || "-"}</p>
                    </div>
                    <div className={classes.itemTitleBtn}>
                      <KnowbeButton
                        kind="outlineWhite"
                        minWidth={240}
                        href={`#/record/support_plan/${uifId}/basic_info/${item.id}`}
                      >
                        申請者の現状（基本情報）
                        {item.type_consultation ===
                          TYPE_CONSULTATION_SHOGAIJISODAN && "（障害児）"}
                      </KnowbeButton>
                    </div>
                  </div>
                  <div className={classes.itemBtn}>
                    {props.facility.facilityType ===
                    FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ? (
                      <KnowbeButton
                        kind="iconText"
                        onClick={(): void => openModalCopy(item)}
                      >
                        <AddToPhotosOutlined className={classes.itemBtnIcon} />
                        <span className={classes.itemBtnText}>
                          この申請者の現状（基本情報）をコピー
                        </span>
                      </KnowbeButton>
                    ) : (
                      <KnowbeButton
                        kind="iconText"
                        href={`#/record/support_plan/${uifId}/basic_info/${
                          item.id
                        }/copy/?type=${
                          props.facility.facilityType ===
                          FACILITY_TYPE_SHOGAIJISODAN
                            ? PARAMETER_TYPE_SHOGAIJISODAN
                            : PARAMETER_TYPE_KEIKAKUSODAN
                        }`}
                      >
                        <AddToPhotosOutlined className={classes.itemBtnIcon} />
                        <span className={classes.itemBtnText}>
                          この申請者の現状（基本情報）をコピー
                        </span>
                      </KnowbeButton>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.listMessage}>
            申請者の現状（基本情報）がありません。新規作成ボタンから作成してください。
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.KEIKAKUSODAN.facility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN } = dispatches;
  return {
    fetchFacility: KEIKAKUSODAN.facilityDispatcher(dispatch).fetch
  };
};

export const BasicInfoRecordList = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BasicInfoRecordListCore));
