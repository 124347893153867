import React, { SetStateAction, Dispatch as reactDispatch } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

// ui
import FormikTextField from "@components/molecules/FormikTextField";

const styles = (): StyleRules =>
  createStyles({
    modalItemContent: {
      padding: "0 32px"
    },
    radioLabel: {
      fontSize: "16px"
    }
  });

type OwnProps = {
  typeLabel: string;
  setSubmitButtonDisabled: reactDispatch<SetStateAction<boolean>>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CustomRecordsDialogContentCore = ({
  classes,
  typeLabel,
  setSubmitButtonDisabled
}: Props): JSX.Element => {
  // 項目名変更時の処理
  const handleChangeNameField = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    // 保存ボタン状態管理
    const { value } = e.target;
    setSubmitButtonDisabled(value === "");
  };

  return (
    <>
      <div className={classes.modalItemContent}>
        <FormikTextField
          name="name"
          label={`${typeLabel}名`}
          size="largeMedium"
          placeholder="入力してください"
          maxLength={20}
          onChangeHook={handleChangeNameField}
          style={{ marginBottom: "36px" }}
        />
      </div>
    </>
  );
};

export const CustomRecordsDialogContent = withStyles(styles)(
  CustomRecordsDialogContentCore
);
