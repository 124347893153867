import { GetUsersSummaryCustomRecords } from "@api/requests/supports/getUsersSummaryCustomRecords";

/**
 * ActionNames
 */
export const FETCH_USERS_SUMMARY_SHISETSUNYUSHO_STARTED =
  "SUPPORTS/FETCH_USERS_SUMMARY_SHISETSUNYUSHO_STARTED";
export const FETCH_USERS_SUMMARY_SHISETSUNYUSHO_SUCCESS =
  "SUPPORTS/FETCH_USERS_SUMMARY_SHISETSUNYUSHO_SUCCESS";
export const FETCH_USERS_SUMMARY_SHISETSUNYUSHO_FAILED =
  "SUPPORTS/FETCH_USERS_SUMMARY_SHISETSUNYUSHO_FAILED";
export const UNSET_USERS_SUMMARY_SHISETSUNYUSHO =
  "SUPPORTS/UNSET_USERS_SUMMARY_SHISETSUNYUSHO";

/**
 * State
 */
export type UsersSummaryState = GetUsersSummaryCustomRecords["data"];
