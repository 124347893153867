import * as types from "@stores/domain/mgr/GroupHome/supportPlan/types";
import { GetSupportPlanOnceResponse } from "@api/requests/supportPlan/GroupHome/getSupportPlanOnce";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchPrivateSupportPlanStarted = () =>
  ({ type: types.FETCH_PRIVATE_SUPPORT_PLAN_STARTED } as const);
export const fetchPrivateSupportPlanSuccess = (
  res: GetSupportPlanOnceResponse["data"]
) =>
  ({ type: types.FETCH_PRIVATE_SUPPORT_PLAN_SUCCESS, payload: res } as const);
export const fetchPrivateSupportPlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_PRIVATE_SUPPORT_PLAN_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchPrivateSupportPlanStarted>
  | ReturnType<typeof fetchPrivateSupportPlanSuccess>
  | ReturnType<typeof fetchPrivateSupportPlanFailed>;
