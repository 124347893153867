import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetAssessmentResponse = {
  data: {
    created_at: string | null;
    updated_at: string | null;
    author: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
    } | null;
    assessment: {
      assessment_records_id: number | null;
      target_date: string | null;
      type_consultation?: number;
      assessment_records: {
        custom_records_category_id: number;
        input: {
          id: number;
          custom_record_item_id: number;
          input_data: string;
          work_histories?: {
            id: number;
            begin_date: string;
            end_date: string;
            facility_name: string;
            details: string;
          }[];
          life_histories?: {
            id: number;
            yyyymm: string | null;
            matter: string | null;
          }[];
          disability_histories?: {
            id: number;
            yyyymm: string | null;
            matter: string | null;
          }[];
          flow_days?: FlowDay[];
          choiced_item_id?: number;
          checked?: number;
        }[];
      }[];
    };
  };
};

export type FlowDay = {
  id: number;
  title: string | null;
  flow_day_details_user: {
    id: number;
    start_time: string | null;
    end_time: string | null;
    matter: string | null;
  }[];
  flow_day_details_caregiver: {
    id: number;
    start_time: string | null;
    end_time: string | null;
    matter: string | null;
  }[];
};

/**
 * アセスメント一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const getAssessment = async (
  uifId: string,
  assessmentId: string
): Promise<AxiosResponse<GetAssessmentResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assessment/${assessmentId}`;
  return request.get<GetAssessmentResponse>(url);
};
