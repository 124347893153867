import { OptionInterface } from "@components/atoms/DropDown";
import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { UserRole } from "@stores/domain/user/type";

export const DEFAULT_RADIO_VALUE = "1";
export const DEFAULT_CHECKBOX_VALUE = false;
export const DEFAULT_SELECT_VALUE = "NOT_SELECTED";

// ON:1 OFF:0のとき
export const INT_FALSE_FROM_API = 0;
export const INT_TRUE_FROM_API = 1;
export const STRING_FALSE_FROM_API = "0";
export const STRING_TRUE_FROM_API = "1";
// ON:2 OFF:1のとき
export const STRING_FALSE_FROM_API_1 = "1";
export const STRING_TRUE_FROM_API_2 = "2";

export const DEFAULT_DROP_DOWN_OPTION: OptionInterface = {
  label: "選択してください",
  value: DEFAULT_SELECT_VALUE
};
export const DEFAULT_CITY_STATE = {
  label: DEFAULT_DROP_DOWN_OPTION.label,
  value: DEFAULT_SELECT_VALUE,
  cityCode: "",
  grade: 0,
  grade_202104: 0,
  grade_children_disability_202104: 0,
  grade_202404: 0,
  grade_children_disability_202404: 0
};
export const DEFAULT_SUBSIDIZED_UNIT = "1";

export enum FacilityType {
  A = "A",
  B = "B",
  IKOU = "I", // 移行
  GROUP_HOME = "G", //  グループホーム
  SEIKATSUKAIGO = "SEIKATSUKAIGO", // 生活介護
  SHUROTEICHAKU = "SHUROTEICHAKU", // 就労定着支援
  JIRITSUKUNRENSEIKATSU = "JIRITSUKUNREN_SEIKATSU", // 自立訓練（生活訓練）
  TANKINYUSHO = "TANKINYUSHO", // 短期入所
  SHISETSUNYUSHO = "SHISETSUNYUSHO", // 施設入所支援
  KYOTAKUKAIGO = "KYOTAKUKAIGO", // 居宅介護
  JUDOHOMONKAIGO = "JUDOHOMONKAIGO", // 重度訪問介護
  DOKOENGO = "DOKOENGO", // 同行援護
  KODOENGO = "KODOENGO", // 行動援護
  CHIIKIIKO = "CHIIKIIKO", // 地域移行
  CHIIKITEICHAKU = "CHIIKITEICHAKU", // 地域定着
  KEIKAKUSODAN = "KEIKAKUSODAN", // 計画相談
  IDOSHIEN = "IDOSHIEN" // 移動支援
}

// モバイル対応がある種別
export const FacilityTypeMobile = [
  FacilityType.KYOTAKUKAIGO, // 居宅介護
  FacilityType.JUDOHOMONKAIGO, // 重度訪問介護
  FacilityType.DOKOENGO, // 同行援護
  FacilityType.KODOENGO, // 行動援護
  FacilityType.IDOSHIEN // 移動支援
];

// タイムカード非表示種別
export const FacilityTypeNoTimeCard = [
  FacilityType.KYOTAKUKAIGO, // 居宅介護
  FacilityType.JUDOHOMONKAIGO, // 重度訪問介護
  FacilityType.DOKOENGO, // 同行援護
  FacilityType.KODOENGO, // 行動援護
  FacilityType.KEIKAKUSODAN, // 計画相談
  FacilityType.CHIIKITEICHAKU, // 地域定着
  FacilityType.CHIIKIIKO, // 地域移行
  FacilityType.IDOSHIEN // 移動支援
];

// 施設区分
export enum GroupHomeType {
  "介護サービス包括型" = 1,
  "外部サービス利用型", // 2
  "日中サービス支援型" // 3
}

// 職員配置
export enum StaffPlacementTypes {
  "Ⅰ型（4：1）" = 1,
  "Ⅱ型（5：1）", // 2
  "Ⅲ型（6：1）", // 3
  "Ⅳ型（10：1）", // 4
  "日中支援Ⅰ型（3：1）", // 5
  "日中支援Ⅱ型（4：1）", // 6
  "日中支援Ⅲ型（5：1）" // 7
}

// 福祉専門職員配置等加算
export enum WelfareSpecialistPlacementTypes {
  "なし" = 1, // 1
  "福祉専門職員配置等加算 (Ⅰ)", // 2
  "福祉専門職員配置等加算 (Ⅱ)", // 3
  "福祉専門職員配置等加算 (Ⅲ)" // 4
}

// 福祉・介護職員処遇改善加算
export enum StaffTreatmentSystemTypes {
  "なし" = 1,
  "処遇改善加算（Ⅰ）", // 2
  "処遇改善加算（Ⅱ）", // 3
  "処遇改善加算（Ⅲ）", // 4
  "処遇改善加算（Ⅳ）", // 5
  "処遇改善加算（Ⅴ）", // 6
  "特別加算" // 7
}

// 福祉・介護職員処遇改善加算（就労定着）
export enum StaffTreatmentSystemTypesSHUROTEICHAKU {
  "なし" = 1,
  "処遇改善加算（Ⅰ）", // 2
  "処遇改善加算（Ⅱ）", // 3
  "処遇改善加算（Ⅲ）" // 4
}

// 福祉・介護職員等処遇改善加算（Ⅴ）が選択された時の値
export const SELECTED_CONDITION_TYPE_2024_V = 99;

// 福祉・介護職員等処遇改善加算
export enum SpecificBetterSupporterConditionTypes2024 {
  "なし" = 1,
  "福祉・介護職員等処遇改善（Ⅰ）", // 2
  "福祉・介護職員等処遇改善（Ⅱ）", // 3
  "福祉・介護職員等処遇改善（Ⅲ）", // 4
  "福祉・介護職員等処遇改善（Ⅳ）", // 5
  "福祉・介護職員等処遇改善（Ⅴ）" = SELECTED_CONDITION_TYPE_2024_V // apiにこの項目のvalueがないため点灯用にフロントで値を持たせる
}

// 福祉・介護職員等処遇改善加算（Ⅴ）が選択された時の値
export const NO_SELECTED_CONDITION_TYPE_2024 = "1";
// 福祉・介護職員等処遇改善加算（Ⅴ）が選択された時の値
export const NO_SELECTED_CONDITION_TYPE_2024_SUB = "0";
// 福祉・介護職員等処遇改善加算(V)のラジオボタンのvalue
export enum SpecificBetterSupporterConditionSubTypes2024 {
  "福祉・介護職員等処遇改善加算（Ⅴ）(1)" = 6,
  "福祉・介護職員等処遇改善加算（Ⅴ）(2)", // 7
  "福祉・介護職員等処遇改善加算（Ⅴ）(3)", // 8
  "福祉・介護職員等処遇改善加算（Ⅴ）(4)", // 9
  "福祉・介護職員等処遇改善加算（Ⅴ）(5)", // 10
  "福祉・介護職員等処遇改善加算（Ⅴ）(6)", // 11
  "福祉・介護職員等処遇改善加算（Ⅴ）(7)", // 12
  "福祉・介護職員等処遇改善加算（Ⅴ）(8)", // 13
  "福祉・介護職員等処遇改善加算（Ⅴ）(9)", // 14
  "福祉・介護職員等処遇改善加算（Ⅴ）(10)", // 15
  "福祉・介護職員等処遇改善加算（Ⅴ）(11)", // 11
  "福祉・介護職員等処遇改善加算（Ⅴ）(12)", // 12
  "福祉・介護職員等処遇改善加算（Ⅴ）(13)", // 13
  "福祉・介護職員等処遇改善加算（Ⅴ）(14)" // 14
}

// IAB・自立・生介・施設入所・短期入所・就労定着で共通の福祉・介護職員等処遇改善加算の非活性項目
// 「SpecificBetterSupporterConditionTypes2024」「SpecificBetterSupporterConditionSubTypes2024」
// で生成されるラジオボタンで非活性にしたい項目のvalueの配列
export const DISABLED_RADIO_ITEMS_VALUE = ["3", "8", "9", "11", "14", "17"];

// 福祉・介護職員等処遇改善加算(V)のAPIのvalueの範囲
export const SpecificBetterSupporterConditionSubTypes2024Range = [
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19"
];

// 福祉・介護職員等特定処遇改善加算
export enum StaffTreatmentSpecificSystemTypes {
  "なし" = 1,
  "特定処遇改善加算（Ⅰ）",
  "特定処遇改善加算（Ⅱ）"
}

// 福祉・介護職員等特定処遇改善加算
export enum specificBetterSupporterConditionTypes {
  "なし" = 1,
  "特定処遇改善加算（Ⅰ）", // 2
  "特定処遇改善加算（Ⅱ）" // 3
}

// 視覚・聴覚障害者支援体制加算
export enum seeHearTeam202404Types {
  "なし" = 1,
  "視覚・聴覚言語障害者支援体制加算（Ⅰ）", // 2
  "視覚・聴覚言語障害者支援体制加算（Ⅱ）" // 3
}

export const SEE_HEAR_TEAM_202404_DEFAULT = "1";

export enum FacilityGroupHomeType {
  NURSINGCARE = 1,
  DAYTIME,
  EXTERNALUSE
}

export enum ServiceTypes {
  A = "45", // 就労継続支援A型
  B = "46", // 就労継続支援B型
  IKOU = "43", // 就労移行支援
  GROUP_HOME = "33", //  共同生活援助
  SEIKATSUKAIGO = "22", // 生活介護
  SHUROTEICHAKU = "47", // 就労定着支援
  JIRITSUKUNRENSEIKATSU = "42", // 自立訓練（生活訓練）
  TANKINYUSHO = "24", // 短期入所
  KYOTAKUKAIGO = "11", // 居宅介護
  JUDOHOMONKAIGO = "12", // 重度訪問介護
  DOKOENGO = "15", // 同行援護
  KODOENGO = "13", // 行動援護
  CHIIKIIKO = "53", // 地域移行
  CHIIKITEICHAKU = "54", // 地域定着
  KEIKAKUSODAN = "52" // 計画相談
}

// 都道府県
export const PREFECTURES_LIST: OptionInterface[] = [
  DEFAULT_DROP_DOWN_OPTION,
  { label: "北海道", value: "北海道" },
  { label: "青森県", value: "青森県" },
  { label: "岩手県", value: "岩手県" },
  { label: "宮城県", value: "宮城県" },
  { label: "秋田県", value: "秋田県" },
  { label: "山形県", value: "山形県" },
  { label: "福島県", value: "福島県" },
  { label: "茨城県", value: "茨城県" },
  { label: "栃木県", value: "栃木県" },
  { label: "群馬県", value: "群馬県" },
  { label: "埼玉県", value: "埼玉県" },
  { label: "千葉県", value: "千葉県" },
  { label: "東京都", value: "東京都" },
  { label: "神奈川県", value: "神奈川県" },
  { label: "新潟県", value: "新潟県" },
  { label: "富山県", value: "富山県" },
  { label: "石川県", value: "石川県" },
  { label: "福井県", value: "福井県" },
  { label: "山梨県", value: "山梨県" },
  { label: "長野県", value: "長野県" },
  { label: "岐阜県", value: "岐阜県" },
  { label: "静岡県", value: "静岡県" },
  { label: "愛知県", value: "愛知県" },
  { label: "三重県", value: "三重県" },
  { label: "滋賀県", value: "滋賀県" },
  { label: "京都府", value: "京都府" },
  { label: "大阪府", value: "大阪府" },
  { label: "兵庫県", value: "兵庫県" },
  { label: "奈良県", value: "奈良県" },
  { label: "和歌山県", value: "和歌山県" },
  { label: "鳥取県", value: "鳥取県" },
  { label: "島根県", value: "島根県" },
  { label: "岡山県", value: "岡山県" },
  { label: "広島県", value: "広島県" },
  { label: "山口県", value: "山口県" },
  { label: "徳島県", value: "徳島県" },
  { label: "香川県", value: "香川県" },
  { label: "愛媛県", value: "愛媛県" },
  { label: "高知県", value: "高知県" },
  { label: "福岡県", value: "福岡県" },
  { label: "佐賀県", value: "佐賀県" },
  { label: "長崎県", value: "長崎県" },
  { label: "熊本県", value: "熊本県" },
  { label: "大分県", value: "大分県" },
  { label: "宮崎県", value: "宮崎県" },
  { label: "鹿児島県", value: "鹿児島県" },
  { label: "沖縄県", value: "沖縄県" }
];

// 負担上限額
export const INCOME_KIND_LIST: RadioItemInterface[] = [
  { label: "0円", value: "1" },
  { label: "4,600円", value: "6" },
  { label: "9,300円", value: "3" },
  { label: "18,600円", value: "5" },
  { label: "37,200円", value: "4" }
];

// 所得区分
export const INCOME_KIND_TYPE_LIST: OptionInterface[] = [
  { label: "選択してください", value: "0" },
  { label: "生活保護", value: "1" },
  { label: "低所得", value: "2" }
];

export const INCOME_KIND_TYPE_LIST_NON_SELECT: OptionInterface[] = [
  { label: "生活保護", value: "1" },
  { label: "低所得", value: "2" }
];

// 助成金額の単位
export const SUBSIDIZED_UNIT_LIST: OptionInterface[] = [
  { label: "%", value: DEFAULT_SUBSIDIZED_UNIT },
  { label: "円", value: "2" }
];

export const UPLIMIT_CONT_ROLLED_BY_LIST: OptionInterface[] = [
  { label: "自事業所が上限管理", value: "1" },
  { label: "他事業所が上限管理", value: "2" }
];

export const RESULT_OF_MANAGEMENT_LIST: RadioItemInterface[] = [
  {
    label:
      "管理事業所で利用者負担額を充当したため、他事業所の利用者負担は発生しない",
    value: "1"
  },
  {
    label: "利用者負担額の合算額が、負担上限月額以下のため、調整事務は行わない",
    value: "2"
  },
  {
    label:
      "利用者負担額の合算額が、負担上限月額を超過するため、下記の通り調整した",
    value: "3"
  }
];

// 障害支援区分コードに対応する名前
// TODO: KNOWBE2-306対応後、21を区分1に変更
export const DISABILITY_CLASS_CODE_NAME_LIST = {
  "99": "区分なし",
  "21": "区分1",
  "22": "区分2",
  "23": "区分3",
  "24": "区分4",
  "25": "区分5",
  "26": "区分6"
};

// 障害区分
export const DISABILITY_CLASS_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "区分1", value: "1" },
  { label: "区分2", value: "2" },
  { label: "区分3", value: "3" },
  { label: "区分4", value: "4" },
  { label: "区分5", value: "5" },
  { label: "区分6", value: "6" }
];

export const REGIONAL_TRANSFER_FOR_STRONG_BEHAVIOR_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "1" },
  { label: "強度行動障害者地域移行特別加算", value: "2" },
  { label: "重度障害者支援加算", value: "3" }
];

// 令和3年度報酬改定で追加
export const REGIONAL_TRANSFER_FOR_STRONG_BEHAVIOR_TYPE_LIST_202104: RadioItemInterface[] = [
  { label: "なし", value: "1" },
  {
    label: "強度行動障害者地域移行特別加算 / 強度行動障害者体験利用加算",
    value: "2"
  },
  { label: "重度障害者支援加算(Ⅰ)", value: "3" },
  { label: "重度障害者支援加算(Ⅱ)", value: "4" }
];

export const MENTAL_DISORDER_SUPPORT_TYPE_LIST: RadioItemInterface[] = [
  { label: "なし", value: "1" },
  { label: "地域生活移行個別支援特別加算", value: "2" },
  { label: "精神障害者地域移行特別加算", value: "3" }
];

// 契約支給量
export const AGREED_BY_CONTRACT_LIST: RadioItemInterface[] = [
  { label: "原則日数（該当月の日数から8日を控除した日数）", value: "1" },
  { label: "契約支給量", value: "2" }
];

// 食事提供サービス
export const SUPPLY_FOOD_SERVICE: OptionInterface[] = [
  { label: "提供なし", value: "0" },
  { label: "提供あり", value: "1" }
];

// 同一敷地内送迎 0:なしの場合
export const PICKUP_PREMISES_CASE_0: OptionInterface[] = [
  { label: "-", value: "0" }
];

// 同一敷地内送迎 1:往の場合
export const PICKUP_PREMISES_CASE_1: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

// 同一敷地内送迎 2:復の場合
export const PICKUP_PREMISES_CASE_2: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "2" }
];

// 同一敷地内送迎 3:往復の場合
export const PICKUP_PREMISES_CASE_3: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "往のみ", value: "1" },
  { label: "復のみ", value: "2" },
  { label: "往復", value: "3" }
];

// Checkboxの値
export const enum Checkbox {
  OFF = "0",
  ON = "1"
}

// 延長支援サービス
export enum SupplyExtendedServiceList {
  "-" = "0",
  "延長（１時間未満）" = "1",
  "延長（１時間以上）" = "2"
}

export const SUPPLY_EXTENDED_SERVICE_LIST = [
  { label: "-", value: "0" },
  { label: "延長（１時間未満）", value: "1" },
  { label: "延長（１時間以上）", value: "2" }
];

export const SUPPLY_EXTENDED_SERVICE_LIST_202404 = [
  { label: "-", value: "0" },
  { label: "所要時間9時間以上10時間未満", value: "1" },
  { label: "所要時間10時間以上11時間未満", value: "2" },
  { label: "所要時間11時間以上12時間未満", value: "3" },
  { label: "所要時間12時間以上", value: "4" }
];

// 食事提供サービス
export enum SupplyFoodsServiceList {
  "-" = "0",
  "あり" = "1"
}

export const SUPPLY_FOOD_SERVICE_LIST = [
  { label: "提供なし", value: "0" },
  { label: "提供あり", value: "1" }
];

// 送迎サービス
export enum SupplyPickupServiceList {
  "-" = "0",
  "往" = "1",
  "復" = "2",
  "往復" = "3"
}

export const SUPPLY_PICKUP_SERVICE_LIST = [
  { label: "-", value: "0" },
  { label: "往", value: "1" },
  { label: "復", value: "2" },
  { label: "往復", value: "3" }
];
export const SUPPLY_PICKUP_SERVICE_LIST2 = [
  { label: "送迎なし", value: "0" },
  { label: "往", value: "1" },
  { label: "復", value: "2" },
  { label: "往復", value: "3" }
];

// 同一敷地内送迎サービス
export enum SupplyPickupPremisesServiceList {
  "-" = "",
  "同一敷地内ではない" = "0",
  "往路が同一敷地内" = "1",
  "復路が同一敷地内" = "2",
  "往復ともに同一敷地内" = "3"
}

export const SUPPLY_PICKUP_PREMISES_SERVICE_LIST0 = [
  { label: "-", value: "0" }
];
export const SUPPLY_PICKUP_PREMISES_SERVICE_LIST1 = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];
export const SUPPLY_PICKUP_PREMISES_SERVICE_LIST2 = [
  { label: "-", value: "0" },
  { label: "あり", value: "2" }
];
export const SUPPLY_PICKUP_PREMISES_SERVICE_LIST3 = [
  { label: "-", value: "0" },
  { label: "往のみ", value: "1" },
  { label: "復のみ", value: "2" },
  { label: "往復", value: "3" }
];

export const NURSING_SUPPORT_RADIO_ITEMS = [
  { label: "なし", value: "1" },
  { label: "看護職員配置体制加算", value: "2" },
  { label: "医療連携体制加算（ Ⅴ ）", value: "3" }
];

export const NURSING_SUPPORT_RADIO_ITEMS_202104 = [
  { label: "なし", value: "1" },
  { label: "看護職員配置体制加算", value: "2" },
  { label: "医療連携体制加算（ Ⅶ ）", value: "3" }
];

// 短期滞在
export const SHORT_STAY_LIST = [
  { label: "-", value: Checkbox.OFF },
  { label: "実施", value: Checkbox.ON }
];

export const ReportTabInfo = {
  DAILY: "0",
  MONTHLY: "1"
};

export const REPORT_TABS_INFO = [
  { label: "日ごと", value: ReportTabInfo.DAILY },
  { label: "月ごと", value: ReportTabInfo.MONTHLY }
];

export const USAGE_PERFORMANCE_STATUS_TYPE: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "宿泊", value: "1" },
  { label: "入院（初日）", value: "2" },
  { label: "入院（中日）", value: "3" },
  { label: "入院（最終日）", value: "4" },
  { label: "入院→外泊", value: "8" },
  { label: "入院→共同生活住居に戻る→外泊", value: "10" },
  { label: "外泊（初日）", value: "5" },
  { label: "外泊（中日）", value: "6" },
  { label: "外泊（最終日）", value: "7" },
  { label: "外泊→入院", value: "9" },
  { label: "外泊→共同生活住居に戻る→入院", value: "11" },
  { label: "体験利用", value: "12" }
];

export const USAGE_PERFORMANCE_STATUS_TYPE_END_IN_30_DAY: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "退去後加算", value: "91" }
];

export const USAGE_PERFORMANCE_STATUS_TYPE_FULL: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "宿泊", value: "1" },
  { label: "入院（初日）", value: "2" },
  { label: "入院（中日）", value: "3" },
  { label: "入院（最終日）", value: "4" },
  { label: "入院→外泊", value: "8" },
  { label: "入院→共同生活住居に戻る→外泊", value: "10" },
  { label: "外泊（初日）", value: "5" },
  { label: "外泊（中日）", value: "6" },
  { label: "外泊（最終日）", value: "7" },
  { label: "外泊→入院", value: "9" },
  { label: "外泊→共同生活住居に戻る→入院", value: "11" },
  { label: "体験利用", value: "12" },
  { label: "退去後加算", value: "91" }
];

export const USAGE_PERFORMANCE_HOSPITALIZATION_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "入院時支援特別加算", value: "2" },
  { label: "長期入院時支援特別加算", value: "3" }
];

export const USAGE_PERFORMANCE_GET_HOME_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "帰宅時支援加算", value: "2" },
  { label: "長期帰宅時支援加算", value: "3" }
];

export const USAGE_PERFORMANCE_DAYTIME_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "Ⅰ", value: "2" },
  { label: "Ⅱ", value: "3" }
];

export const USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "Ⅰ,Ⅲ", value: "2" },
  { label: "Ⅱ", value: "3" },
  { label: "Ⅳ", value: "4" }
];

// 令和3年度報酬改定で追加
export const USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE_202104: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅲ", value: "3" },
  { label: "Ⅳ", value: "4" },
  { label: "Ⅴ", value: "5" },
  { label: "Ⅵ", value: "6" }
];

/* 医療連携のIAB用（項目名が同じなのにGHと値が違う） */
export const IAB_USAGE_PERFORMANCE_MEDICAL_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "Ⅰ,Ⅲ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅳ", value: "3" }
];

export const SPUTUM_GUIDANCE_FLG: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

// 喀痰吸引等に係る指導実施のチェックが出来るようになる、医療連携体制加算の値
export const ENABLE_MEDICAL_COOPERATION_VALUES = ["1", "2", "3", "4", "6"];

export const LIFE_SUPPORT_FLG: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

export const LIFE_SUPPORT_FLG_202404: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "I", value: "1" },
  { label: "I+III", value: "2" },
  { label: "I+35単位", value: "3" },
  { label: "I+35単位+III", value: "4" },
  { label: "I+500単位", value: "5" },
  { label: "I+500単位+III", value: "6" },
  { label: "I+535単位", value: "7" },
  { label: "I+535単位+III", value: "8" },
  { label: "II（算定不可）", value: "9" },
  { label: "III", value: "10" }
];

export const FACILITY_TYPE_NAME_LIST: OptionInterface[] = [
  { label: "就労継続支援A型", value: FacilityType.A },
  { label: "就労継続支援B型", value: FacilityType.B },
  { label: "就労移行支援", value: FacilityType.IKOU },
  { label: "共同生活援助", value: FacilityType.GROUP_HOME },
  { label: "生活介護", value: FacilityType.SEIKATSUKAIGO },
  { label: "就労定着支援", value: FacilityType.SHUROTEICHAKU },
  { label: "自立訓練（生活訓練）", value: FacilityType.JIRITSUKUNRENSEIKATSU },
  { label: "短期入所", value: FacilityType.TANKINYUSHO },
  { label: "施設入所支援", value: FacilityType.SHISETSUNYUSHO },
  { label: "居宅介護", value: FacilityType.KYOTAKUKAIGO },
  { label: "重度訪問介護", value: FacilityType.JUDOHOMONKAIGO },
  { label: "同行援護", value: FacilityType.DOKOENGO },
  { label: "行動援護", value: FacilityType.KODOENGO },
  { label: "地域移行支援", value: FacilityType.CHIIKIIKO },
  { label: "地域定着支援", value: FacilityType.CHIIKITEICHAKU },
  { label: "計画相談支援", value: FacilityType.KEIKAKUSODAN },
  { label: "移動支援", value: FacilityType.IDOSHIEN }
];

export const GROUP_HOME_TYPE_LIST = [
  { label: "介護サービス包括型", value: "1" },
  { label: "日中サービス型", value: "2" },
  { label: "外部サービス利用型", value: "3" }
];

export const CONTACT_OVERVIEW_LIST = [
  { label: "選択してください", value: "" },
  { label: "操作に関するお問い合わせ", value: "操作に関するお問い合わせ" },
  { label: "動作不良・エラーについて", value: "動作不良・エラーについて" },
  { label: "取込送信システムについて", value: "取込送信システムについて" },
  { label: "その他", value: "その他" }
];

export const TRAIL_USAGE_KIND = [
  { label: "障害福祉サービスの体験利用支援加算(I)", value: "1" },
  { label: "障害福祉サービスの体験利用支援加算(Ⅱ)", value: "2" }
];

/**
 * notAttended : 未通所の場合true
 * notInout: trueの場合通所ボタン表示不可
 * isView: trueの場合前回の利用に表示
 */
export const SERVICE_STATUS = [
  { label: "-", value: 1, notAttended: true, notInOut: false, isView: false },
  {
    label: "通所",
    value: 2,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "施設外就労",
    value: 3,
    notAttended: false,
    notInOut: false,
    isView: false
  },
  {
    label: "施設外支援",
    value: 4,
    notAttended: false,
    notInOut: false,
    isView: false
  },
  {
    label: "欠席時対応",
    value: 5,
    notAttended: false,
    notInOut: true,
    isView: false
  },
  {
    label: "訪問",
    value: 6,
    notAttended: true,
    notInOut: true,
    isView: false
  },
  {
    label: "体験利用支援",
    value: 7,
    notAttended: true,
    notInOut: true,
    isView: true
  },
  {
    label: "移行準備支援Ⅰ",
    value: 8,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "移行準備支援Ⅱ",
    value: 9,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "欠席",
    value: 10,
    notAttended: true,
    notInOut: true,
    isView: false
  }
];

/**
 * 202104以降に利用するSERVICE_STATUS
 * 差分
 *  - 移行準備支援Ⅰ => 移行準備支援(I)
 *  - 移行準備支援Ⅱ => 旧 移行準備支援(II)
 */
export const SERVICE_STATUS_202104 = [
  { label: "-", value: 1, notAttended: true, notInOut: false, isView: false },
  {
    label: "通所",
    value: 2,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "施設外就労",
    value: 3,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "施設外支援",
    value: 4,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "欠席時対応",
    value: 5,
    notAttended: false,
    notInOut: true,
    isView: false
  },
  {
    label: "訪問",
    value: 6,
    notAttended: true,
    notInOut: true,
    isView: false
  },
  {
    label: "体験利用支援",
    value: 7,
    notAttended: true,
    notInOut: true,
    isView: true
  },
  {
    label: "移行準備支援（I）",
    value: 8,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "旧 移行準備支援（II）",
    value: 9,
    notAttended: false,
    notInOut: false,
    isView: true
  },
  {
    label: "欠席",
    value: 10,
    notAttended: true,
    notInOut: true,
    isView: false
  }
];

/**
 * サービス提供が終了しているか
 * 0 = サービス提供終了, 1 = サービス提供中, 2 = サービス提供終了後30日以内（GHは6ヶ月）
 */
export const SERVICE_END_STATUS = {
  SERVICE_END: 0,
  SERVICE_USE: 1,
  SERVICE_END_IN_30_DAY: 2,
  SERVICE_END_DAY: 3
};

export const VISIT_SUPPORT = [
  { label: "-", value: "0" },
  { label: "1時間未満", value: "1" },
  { label: "1時間以上", value: "2" }
];

export const TRIAL_USAGE_KIND = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const SUPPLY_PICKUP_SERVICE: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "往", value: "1" },
  { label: "復", value: "2" },
  { label: "往復", value: "3" }
];

export const SUPPLY_FOODS_SERVICE: OptionInterface[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

export const MEDICAL_COOPERATION = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅲ,Ⅳ", value: "4" }
];

// 令和3年度報酬改定で追加
export const MEDICAL_COOPERATION_202104 = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅲ", value: "3" },
  { label: "Ⅳ", value: "4" },
  { label: "Ⅴ", value: "5" },
  { label: "Ⅵ", value: "6" }
];

export const MEDICAL_COOPERATION_TEXT = [
  { label: "加算なし", value: "0" },
  { label: "医療連携体制加算（Ⅰ）", value: "1" },
  { label: "医療連携体制加算（Ⅱ）", value: "2" },
  { label: "医療連携体制加算（Ⅲ,Ⅳ）", value: "4" }
];

// 令和3年度報酬改定で追加
export const MEDICAL_COOPERATION_TEXT_202104 = [
  { label: "加算なし", value: "0" },
  { label: "医療連携体制加算（Ⅰ）", value: "1" },
  { label: "医療連携体制加算（Ⅱ）", value: "2" },
  { label: "医療連携体制加算（Ⅲ）", value: "3" },
  { label: "医療連携体制加算（Ⅳ）", value: "4" },
  { label: "医療連携体制加算（Ⅴ）", value: "5" },
  { label: "医療連携体制加算（Ⅵ）", value: "6" }
];

export const SEIKATSUKAIGO_IN_OUT_RECORDS_STATUS: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "通所", value: "2" },
  { label: "欠席時対応", value: "5" },
  { label: "訪問", value: "6" },
  { label: "体験利用", value: "7" },
  { label: "欠席（加算なし）", value: "10" }
];

export const SEIKATSUKAIGO_SUMMARY_SERVICE_STATUS = {
  USUAL_PLACE: { label: "通所", value: 2 },
  WHEN_ABSENT: { label: "欠席時対応", value: 5 },
  VISIT: { label: "訪問", value: 6 },
  TRIAL_USE_SUPPORT: { label: "体験利用支援", value: 7 },
  ABSENT: { label: "欠席（加算なし）", value: 10 }
};

// 請求書 請求 サービス区分 変換
export enum BillServiceTypes {
  A = "就労継続支援A型", // 45
  B = "就労継続支援B型", // 46
  IKOU = "就労移行支援", // 43
  GROUP_HOME = "共同生活援助", // 33
  SEIKATSUKAIGO = "生活介護", // 22
  SHUROTEICHAKU = "就労定着支援", // 47
  JIRITSUKUNRENSEIKATSU = "自立訓練(生活訓練)", // 42
  TANKINYUSHO = "短期入所", // 24
  SHISETSUNYUSHO = "施設入所支援", // 32
  KYOTAKUKAIGO = "居宅介護", // 11
  JUDOHOMONKAIGO = "重度訪問介護", // 12
  DOKOENGO = "同行援護", // 15
  KODOENGO = "行動援護", // 13
  CHIIKIIKO = "地域移行支援", // 53
  CHIIKITEICHAKU = "地域定着支援" // 54
}

// 介護給付費・訓練費請求書 介護給付費表示 区分
export const DISPLAY_INVOICE_SUMMARY_CITY_BILL_KAIGO_KYUHU = [
  BillServiceTypes.SEIKATSUKAIGO.toString(),
  BillServiceTypes.TANKINYUSHO.toString(),
  BillServiceTypes.SHISETSUNYUSHO.toString(),
  BillServiceTypes.KYOTAKUKAIGO.toString(),
  BillServiceTypes.JUDOHOMONKAIGO.toString(),
  BillServiceTypes.DOKOENGO.toString(),
  BillServiceTypes.KODOENGO.toString()
];

// 介護給付費・訓練費請求書 訓練給付費表示 区分
export const DISPLAY_INVOICE_SUMMARY_CITY_BILL_KUNREN_KYUHU = [
  BillServiceTypes.A.toString(),
  BillServiceTypes.B.toString(),
  BillServiceTypes.IKOU.toString(),
  BillServiceTypes.GROUP_HOME.toString(),
  BillServiceTypes.SHUROTEICHAKU.toString(),
  BillServiceTypes.JIRITSUKUNRENSEIKATSU.toString()
];

// 介護給付費・訓練費請求書 地域相談支援給付費表示 区分
export const DISPLAY_INVOICE_SUMMARY_CITY_BILL_CHIIKISODAN_KYUHU = [
  BillServiceTypes.CHIIKIIKO.toString(),
  BillServiceTypes.CHIIKITEICHAKU.toString()
];

// 福祉・介護職員等特定処遇改善加算の表示条件
export const ENABLE_SPECIFIC_BETTER_SUPORTER_CONDITION_TYPES = [
  `${WelfareSpecialistPlacementTypes["処遇改善加算（Ⅰ）"]}`,
  `${WelfareSpecialistPlacementTypes["処遇改善加算（Ⅱ）"]}`,
  `${WelfareSpecialistPlacementTypes["処遇改善加算（Ⅲ）"]}`
];

// 支援記録のinout.statusに応じた項目
export const SUPPORT_RECORD_KEY_LABEL = {
  1: [],
  2: [
    { key: "support_work_history", label: "作業" },
    { key: "user_status", label: "利用者状態" },
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  3: [
    { key: "workplace_company_id", label: "就労先企業" },
    { key: "support_work_history", label: "作業" },
    { key: "user_status", label: "利用者状態" },
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  4: [
    { key: "support_work_history", label: "作業" },
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  5: [
    { key: "correspondent_staff_id", label: "対応職員" },
    { key: "absence_reason", label: "欠席理由・支援内容" }
  ],
  6: [
    { key: "correspondent_staff_id", label: "対応職員" },
    { key: "support_content", label: "支援内容" }
  ],
  7: [
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  8: [
    { key: "support_work_history", label: "作業" },
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  9: [
    { key: "workplace_company_id", label: "就労先企業" },
    { key: "support_work_history", label: "作業" },
    { key: "user_status", label: "利用者状態" },
    { key: "staff_comment", label: "職員考察" },
    { key: "interview_flg", label: "面談" },
    { key: "other_comment", label: "その他" }
  ],
  10: [
    { key: "correspondent_staff_id", label: "対応職員" },
    { key: "absence_reason", label: "欠席理由・支援内容" }
  ]
};

// 初期設定ページが存在するfacilityTYpe
export const EXIST_INITIAL_PAGE = [
  FacilityType.SEIKATSUKAIGO,
  FacilityType.JIRITSUKUNRENSEIKATSU,
  FacilityType.IKOU,
  FacilityType.A,
  FacilityType.B,
  FacilityType.TANKINYUSHO,
  FacilityType.SHISETSUNYUSHO
];

// 福祉・介護職員等特定処遇改善加算のチェックが出来るようになる、福祉・介護職員処遇改善加算の値
export const ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES = [
  "2",
  "3",
  "4"
];

// 取得する記録内容を選択
export const RECORD_TYPE = {
  SUPPORT_PLAN: "support-plan",
  SUPPORT: "support",
  WORK: "work",
  INTERVIEW: "interview"
};

// 大規模住居等減算
export const SUBTRACTION_OF_LARGE_SCALE_HOUSING = [
  { label: "入居定員が8名以上", value: "1" },
  { label: "入居定員が21名以上", value: "2" },
  {
    label: "一体的な運営が行われている共同生活住居の入居定員の合計数が21名以上",
    value: "3"
  }
];

// 記録のモーダルタイプ
export const RECORD_MODAL_TYPE = {
  daily: 1, // 日々の記録
  interview: 2, // 面談記録
  support: 3, // 支援記録 一括印刷
  work: 4, // 作業記録
  supportPinUser: 5, // 支援記録 ユーザー詳細
  supportB: 6, // 支援記録 一括印刷(別表示パターン)
  supportReport: 7, // 支援レポート
  serviceDeliveryDaily: 8, // サービス提供記録（日ごと）
  serviceDeliveryMonthly: 9, // サービス提供記録（月ごと）
  assistance: 10 // 支援記録(相談系3種) 一括印刷
} as const;

// 記録のモーダルタイプに応じたAPIのtarget
export const RECORD_MODAL_TYPE_TARGET = {
  1: "support",
  2: "interview",
  3: "support",
  4: "work",
  5: "support",
  6: "support",
  7: "report",
  8: "serviceDelivery",
  9: "procedureForms",
  10: "assistance"
};

// 食事・送迎項目が不要なステータス
// "1": "-", "5": "欠席時対応", "6": "訪問", "7": "体験利用支援", "10": "欠席"
export const NOT_ACCEPT_FOOD_TRANSFER_IN_STATUSES = ["1", "5", "6", "7", "10"];

// 報酬改定による変更ラベルテキスト
export const REWARD_REVISION_LABEL = {
  CHANGE_TEXT: "令和3年4月 報酬改定で変更"
};

// 報酬改定の分岐点を判断する日付のdate object
export const REVISION_DATE_2021_04 = new Date(2021, 3, 1);

// スコア評価点
export const SCORE_LIST = {
  averageWorkDuration: [0, 80, 70, 55, 45, 40, 30, 20, 5],
  productionActivity: [0, 40, 25, 20, 5],
  diversifiedWorkStyle: [0, 15, 25, 35],
  improvementOfSupport: [0, 15, 25, 35],
  regionalCooperationActivity: [0, 10]
};

// スコア評価点
export const SCORE_LIST_202404 = {
  averageWorkDuration: [0, 90, 80, 65, 55, 40, 30, 20, 5],
  productionActivity: [0, 60, 50, 40, 20, -10, -20],
  diversifiedWorkStyle: [0, 0, 5, 15],
  improvementOfSupport: [0, 0, 5, 15],
  regionalCooperationActivity: [0, 10],
  managementImprovementPlan: [-50, 0],
  initiativesToImproveAbilities: [0, 10]
};

// 目標タイプ
export const SprintType = {
  LONG: 1, // 長期
  SHORT: 2, // 短期
  INDIVIDUAL: 3 // 個別目標 or 個別目標
} as const;

export const USER_ROLE: UserRole = {
  user: "user",
  mgruser: "mgruser",
  mgradmin: "mgradmin",
  mgrsupport: "mgrsupport",
  mgrgroupadmin: "mgrgroupadmin",
  mgrmobile: "mgrmobile"
};

// custom_recordの取得タイプ
export const CUSTOM_RECORD_TARGET_TYPE = {
  all: 0,
  operation_common: 1,
  operation_unit: 2,
  support: 3,
  assessment: 4,
  service_delivery: 5,
  support_ledger: 6
};

// custom_recordの取得タイプ
export const ASSESSMENT_CATEGORY_TYPE = {
  everyday_life: 1,
  psychosomatic_state: 2,
  fault_characteristics: 3,
  remarks: 4
};

// custom_recordの取得タイプ(A型、B型)
export const AB_ASSESSMENT_CATEGORY_TYPE = {
  employment: 1,
  support: 2,
  aptitude_skill: 3,
  others: 4
};

// custom_recordの取得タイプ(移行)
export const IKOU_ASSESSMENT_CATEGORY_TYPE = {
  employment: 1,
  evaluation_period: 2,
  support: 3,
  aptitude_skill: 4,
  others: 5
};

/** custom_recordの取得タイプ アセスメント (group home) */
export const GROUP_HOME_ASSESSMENT_CATEGORY_TYPE = {
  /** 日常生活 */
  everyday_life: 1,
  /** 社会生活 */
  social_life: 2,
  /** 心身の状態 */
  psychosomatic_state: 3,
  /** 障害特性 */
  aptitude_skill: 4,
  /** 特記事項 */
  remarks: 5
} as const;

/** custom_recordの取得タイプ アセスメント (生活介護) */
export const SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE = {
  /** 日常生活 */
  everyday_life: 1,
  /** 健康・医療 */
  health_medical_care: 2,
  /** 社会生活 */
  social_life: 3,
  /** 心身の状態 */
  psychosomatic_state: 4,
  /** 障害特性 */
  aptitude_skill: 5,
  /** 特記事項 */
  remarks: 6
} as const;

// custom_recordの取得タイプ(地域定着支援台帳)
export const CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE = {
  office_information: 1,
  basic_information: 2,
  fault_information: 3,
  user_condition: 4,
  family: 5,
  related_organizations: 6,
  notices: 7
} as const;

// custom_recordの取得タイプ(計画相談)
export const KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE = {
  summary: 1,
  living_situation: 2,
  situation: 3,
  request: 4,
  others: 5
};

// custom_recordの取得タイプ(地域移行)
export const CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE = {
  basic_information: 1,
  summary: 2,
  living_situation: 3,
  situation: 4,
  opinions_on_chiikiikou: 5,
  others: 6
} as const;

// custom_recordの取得タイプ(地域定着)
export const CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE = {
  basic_information: 1,
  summary: 2,
  living_situation: 3,
  situation: 4,
  request: 5,
  others: 6
};

// custom_record_with_categoryの取得タイプ(居宅介護)
export const CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO = {
  basic: 1,
  body_care: 2,
  hospital: 3,
  house_work: 4,
  special: 5
};

// custom_record_with_categoryの取得タイプ(重度訪問)
export const CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO = {
  basic: 1,
  body_care: 2,
  house_work: 3,
  outing_support: 4,
  communication_support: 5,
  special: 6
};

// custom_record_with_categoryの取得タイプ(同行援護・行動援護)
export const CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO = {
  money_management: 1,
  user_condition: 2,
  service: 3,
  special: 4,
  other: 5
};

export const CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT = 20;

// custom_recordの取得タイプ
export const ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "日常生活",
  2: "心身の状態",
  3: "障害特性",
  4: "特記事項"
};

// custom_recordの取得タイプ(A型、B型)
export const AB_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "就労",
  2: "支援",
  3: "適性・スキル",
  4: "その他"
};

// custom_recordの取得タイプ(移行)
export const IKOU_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "就労",
  2: "評価期間",
  3: "支援",
  4: "適性・スキル",
  5: "その他"
};

/** custom_recordの取得タイプ アセスメント (group home) */
export const GROUP_HOME_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "日常生活",
  2: "社会生活",
  3: "心身の状態",
  4: "障害特性",
  5: "特記事項"
};

/** custom_recordの取得タイプ アセスメント (group home) */
export const SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "日常生活",
  2: "健康・医療",
  3: "社会生活",
  4: "心身の状態",
  5: "障害特性",
  6: "特記事項"
};

// custom_recordの取得タイプ(地域定着支援台帳)
export const CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME = {
  1: "事業所情報",
  2: "基本情報",
  3: "障害情報",
  4: "本人の状態",
  5: "家族・親族",
  6: "関係機関",
  7: "特記事項"
};
// custom_recordの取得タイプ(計画相談)
export const KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "本人の概要",
  2: "生活状況",
  3: "本人の状況",
  4: "要望・希望する暮らし",
  5: "その他"
};

// custom_recordの取得タイプ(地域移行)
export const CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "基本情報",
  2: "本人の概要",
  3: "生活状況",
  4: "本人の状況",
  5: "地域移行に対する意思・意見",
  6: "その他"
} as const;

// custom_recordの取得タイプ(地域定着)
export const CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE_NAME = {
  1: "基本情報",
  2: "本人の概要",
  3: "生活状況",
  4: "本人の状況",
  5: "要望・希望する暮らし",
  6: "その他"
} as const;

/**
 * custom_recordのdefault_itemの対応表（移行）
 * 数が多いためロジックで使用する必要なものだけ定義
 */
export const IKOU_ASSESSMENT_DEFAULT_ITEM = {
  evaluationPeriod: 22
} as const;

/**
 * custom_recordのdefault_itemの対応表（計画相談）
 * 数が多いためロジックで使用する必要なものだけ定義
 */
export const KEIKAKUSODAN_ASSESSMENT_DEFAULT_ITEM = {
  /** 生活歴 */
  lifeHistory: 1,
  /** 障害・疾病歴 */
  disabilityHistory: 2,
  /** 生活状況の１日の流れ */
  flowDays: 8
} as const;

/**
 * custom_recordのdefault_itemの対応表（地域移行）
 * 数が多いためロジックで使用する必要なものだけ定義
 */
export const CHIIKIIKO_ASSESSMENT_DEFAULT_ITEM = {
  /** 計画相談事業所 */
  keikakusodanOffice: 1,
  /** 計画相談担当者 */
  keikakusodanSupporter: 2,
  /** 生活歴 */
  lifeHistory: 3,
  /** 障害・疾病歴 */
  disabilityHistory: 4,
  /** 生活歴 > １日の流れ */
  flowDays: 10,
  /** 地域移行に対する意思・意見 > 本人 */
  opinionsOnChiikiikouSelf: 52,
  /** 地域移行に対する意思・意見 > 家族 */
  opinionsOnChiikiikouFamily: 53,
  /** 地域移行に対する意思・意見 > 施設・病院職員 */
  opinionsOnChiikiikouCarer: 54,
  /** 地域移行に対する意思・意見 > その他関係者 */
  opinionsOnChiikiikouOrganization: 55
} as const;

/**
 * custom_recordのdefault_itemの対応表（地域移行）
 * 数が多いためロジックで使用する必要なものだけ定義
 */
export const CHIIKITEICHAKU_ASSESSMENT_DEFAULT_ITEM = {
  /** 計画相談事業所名 */
  keikakusodanOfficeName: 1,
  /** 計画相談担当者 */
  keikakusodanCarer: 2,
  /** 生活歴 */
  lifeHistory: 3,
  /** 障害・疾病歴 */
  disabilityHistory: 4,
  /** 生活歴 > １日の流れ */
  flowDays: 10
} as const;

/**
 * Cセグのアセスメント > 印刷プレビュー 内
 * 生活状況 > 1日の流れ のタイムテーブル作成時に使用する日付（本人側）
 */
export const CSEG_ASSESSMENT_PRINT_FLOW_USER_DATE = "2023-01-01" as const;

/**
 * Cセグのアセスメント > 印刷プレビュー 内
 * 生活状況 > 1日の流れ のタイムテーブル作成時に使用する日付（介護者側）
 */
export const CSEG_ASSESSMENT_PRINT_FLOW_CAREGIVER_DATE = "2023-01-02" as const;

// custom_recordのdefault_itemの対応表（業務日誌）
export const OPERATION_CUSTOM_RECORD_DEFAULT_ITEM = {
  attendant: 1,
  absentee: 2,
  operation_daytime: 3,
  operation_night: 4,
  other: 5,
  staff_name: 6
};

// custom_recordのdefault_itemの対応表（支援記録）
export const SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM = {
  vital_morning: 1,
  vital_night: 2,
  meal: 3,
  medicine: 4,
  support_daytime: 5,
  support_night: 6,
  other: 7,
  staff_comment: 8,
  staff_name: 9
};

/** 地域定着台帳のdefault_itemの対応表 */
export const CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM = {
  address: 1,
  tel: 2,
  email: 3,
  disabilityType: 4,
  disabilitySupportCategory: 5,
  disabilityHistories: 6,
  height: 7,
  weight: 8,
  /** 日常生活能力 */
  lifeAbility: 18,
  /** 生活保護 */
  welfare: 19,
  /** 障害年金 */
  disabilityPension: 20,
  /** 住居の状態 */
  housingStatus: 21,
  /** 周辺環境 */
  surroundingEnvironment: 22,
  /** 生活歴 */
  lifeHistories: 23,
  /** 家族・親族 */
  relativesInfo: 24,
  /** 福祉サービス */
  relatedWelfareService: 25,
  /** 医療機関 */
  relatedMedical: 26,
  /** ライフライン */
  relatedLifeLine: 27,
  /** 関係機関/その他 */
  relatedOthers: 28,
  /** 留意点 */
  pointsToRemember: 29,
  /** 台帳作成事業所名 */
  createdOffice: 30,
  /** 台帳作成事業所担当者 */
  createdStaff: 31,
  /** 台帳作成事業所連絡先 */
  createdTel: 32,
  /** 相談支援事業所名 */
  supportOffice: 33,
  /** 相談支援事業所担当者 */
  supportStaff: 34,
  /** 相談支援事業所連絡先 */
  supportTel: 35
} as const;

// custom_record「バイタル」のdefault_choiceの対応表
export const CUSTOM_RECORD_VITAL_DEFAULT_CHOICE = {
  body_temperature: 1,
  blood_pressure_min: 2,
  blood_pressure_max: 3,
  pulse: 4,
  weight: 5
};

// custom_recordの入力タイプ
export const SUPPORT_CUSTOM_RECORD_INPUT_TYPE = {
  text: 1,
  checkbox: 2,
  multi_select: 3,
  multi_text: 4,
  radio: 5,
  mixture: 6,
  money: 7,
  checkbox_and_text: 8,
  others: 9
};

// custom_record(setting_type=5)のdefault_itemの対応表
export const CUSTOM_RECORD_WITH_CATEGORY_DEFAULT_ITEM = {
  staff_comment: 33
};

export const USAGE_PERFORMANCE_STATUS_TYPE_COUNTS: OptionInterface[] = [
  { label: "宿泊", value: "stay" },
  { label: "入院", value: "hospitalization" },
  { label: "外泊", value: "stay_away" },
  { label: "合計", value: "denominator" }
];

// custom_record「複数テキスト」タイプの単位(バイタル)
export const CUSTOM_RECORD_DEFAULT_CHOICE_UNIT = {
  1: "度",
  2: "mmHg",
  3: "mmHg",
  4: "回",
  5: "kg"
};

// custom_record「複数テキスト」タイプの単位(バイタル)
export const CUSTOM_RECORD_DEFAULT_CHOICE_UNIT_BODY_CARE = {
  1: "回",
  2: "cc"
};

// custom_recordの取得タイプ
export const CUSTOM_RECORD_INPUT_TYPE = {
  1: "文字入力",
  2: "選択式（チェックボックス）",
  3: "選択式（セレクトボックス）",
  4: "文字入力",
  5: "選択式(ラジオボタン)",
  6: "文字入力",
  7: "文字入力",
  8: "選択式（チェックボックス）"
};

export const ENCIRCLED_NUMBER = {
  1: "①",
  2: "②",
  3: "③",
  4: "④",
  5: "⑤",
  6: "⑥",
  7: "⑦",
  8: "⑧",
  9: "⑨",
  10: "⑩"
};

// 居宅介護計画書・算定理由用
export const HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM = {
  NOTSELECT: { label: "選択してください", value: "0" },
  ALONE: { label: "利用者が一人暮らし", value: "1" },
  FAMILY: {
    label:
      "家族が障害、疾病等のため、利用者や家族等が家事を行うことが困難な場合",
    value: "2"
  },
  OTHERS: { label: "その他やむを得ない事情", value: "3" }
};
export const HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON = [
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.NOTSELECT,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.ALONE,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.FAMILY,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS
];

// 介護計画書・日跨ぎ用
export const CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM = {
  APPOINTED_DAY: { label: "当日", value: "0" },
  NEXT_DAY: { label: "翌日", value: "1" }
};

export const CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG = [
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM.APPOINTED_DAY,
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM.NEXT_DAY
];

// 介護計画書・サービス提供日用
export const CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK = [
  { label: "月", value: 1 },
  { label: "火", value: 2 },
  { label: "水", value: 3 },
  { label: "木", value: 4 },
  { label: "金", value: 5 },
  { label: "土", value: 6 },
  { label: "日", value: 7 }
];

// 介護計画書・提供人数用
export const CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER_ITEM = {
  ONE: { label: "1", value: "1" },
  TWO: { label: "2", value: "2" }
};
export const CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER = [
  CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER_ITEM.ONE,
  CARE_PLAN_SCHEDULES_DIALOG_PRACTITIONER_ITEM.TWO
];
export const DAY_SELECT_OPTIONS: OptionInterface[] = [
  { label: "当日", value: "1" },
  { label: "翌日", value: "2" }
];

export const DAY_SELECT_TYPE = {
  today: "1",
  nextDay: "2"
};

export const TIME_CLASS_LIST = [
  { label: "当日", value: "0" },
  { label: "翌日", value: "1" }
];

export const SERVICE_DELIVERY_PRINT_TITLE_OPTIONS = [
  { label: "サービス提供記録", value: "service_delivery" },
  { label: "サービス実施記録", value: "service_implementation" }
];

export const SERVICE_DELIVERY_PRINT_TYPE_OPTIONS = [
  { label: "支援手順書 兼 記録用紙", value: "support_procedure" },
  { label: "サービス提供記録", value: "service_delivery" }
];

export const CARE_PLAN_SCHEDULES_CLASSIFY_HANDICAPPED_FLG = {
  OFF: "0",
  ON: "1"
};

// アセスメント一覧/介護計画一覧/支援計画シート一覧PATHの末尾
export const PLAN_LIST_TAB_PATH = {
  ASSESSMENT: "assessment",
  CAREPLAN: "care_plan",
  SUPPORT_PLAN_SHEETS: "support_plan_sheets",
  SUPPORT_PROCEDURE: "support_procedure"
} as const;

export const SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE = "2018-01-0";

export const SUPPORT_CARE_PLAN_NAME = {
  KYOTAKUKAIGO: "居宅介護計画",
  JUDOHOMONKAIGO: "重度訪問介護計画",
  DOKOENGO: "同行援護計画",
  KODOENGO: "行動援護計画"
};
export const SUPPORT_CARE_PLAN_PAGE_NAME = {
  KYOTAKUKAIGO: "居宅介護計画書",
  JUDOHOMONKAIGO: "重度訪問介護計画書",
  DOKOENGO: "同行援護計画書",
  KODOENGO: "行動援護計画書"
};
export const SUPPORT_CARE_PLAN_PRINT_TEXT = {
  KYOTAKUKAIGO: "以下の方法で、居宅介護サービスを提供していきます。",
  JUDOHOMONKAIGO: "以下の方法で、重度訪問介護サービスを提供していきます。",
  DOKOENGO: "以下の方法で、同行援護サービスを提供していきます。",
  KODOENGO: "以下の方法で、行動援護サービスを提供していきます。"
};
export const SUPPORT_CARE_PLAN_SCHEDULES_STATUS_TEXT = {
  JUDOHOMONKAIGO: "重度訪問介護",
  DOKOENGO: "同行援護",
  KODOENGO: "行動援護"
};

export const DRIVE_ACTION_CLASS_SELECT_LIST_NUMBER = [
  { label: "運転", value: 1 },
  { label: "空き", value: 2 }
];

export const MEMBER_LIST_SERVICE_DETAIL = [
  { label: "1人", value: "1" },
  { label: "2人", value: "2" }
];

// サービス提供記録 - 対象種別用フラグ(同行援護・行動援護・移動支援)
export const SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO = [
  FacilityType.DOKOENGO,
  FacilityType.KODOENGO,
  FacilityType.IDOSHIEN
];

export const SERVICE_HEAD_FIXED_ITEM = [
  1, // 費用負担
  2 // 所持金・預かり金
];

export const SERVICE_CUSTOM_RECORD_COST_DEFAULT_CHOICE = {
  traveling_expenses: 1, // 交通費
  other_expense: 2, // その他費用
  total: 3, // 合計
  user_burden: 4 // うち利用者負担額
};

export const SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE = {
  money_and_deposit: 1, // 所持金・預かり金
  used_money: 2, // 使用額
  balance: 3 // 残金
};

export const SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT = [
  { label: "タクシー", value: "1" },
  { label: "バス", value: "2" },
  { label: "電車", value: "3" },
  { label: "車", value: "4" },
  { label: "その他", value: "5" }
];

export const SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT_VALUE = {
  null: 0,
  taxi: 1,
  bus: 2,
  train: 3,
  car: 4,
  other: 5
};

// 計画変更の必要性
export const PLAN_CHANGE: RadioItemInterface[] = [
  { label: "", value: "0" },
  { label: "", value: "1" }
];

export const DEFAULT_SELECT_DATE_VALUE = {
  year: DEFAULT_SELECT_VALUE,
  month: "",
  day: ""
};

export const NUMBER_OF_PRACTITIONER_STR = {
  one: "1",
  two: "2"
};

// 性別
export const GENDER_LIST: RadioItemInterface[] = [
  { label: "男性", value: "1" },
  { label: "女性", value: "2" }
];

export const DOWNLOAD_CSV_JISSEKI = "jisseki";
export const DOWNLOAD_CSV_MEISAI = "meisai";
export const DOWNLOAD_CSV_SEIKYU = "seikyu";

// バリデーションエラーステータス
export const VALIDATION_ERROR = 422;

// 報酬改定関連の定数
export const INVOICE_VERSION_V201910 = 1;
export const INVOICE_VERSION_V201910_END_DATE = new Date(
  2021,
  2,
  31,
  23,
  59,
  59,
  99
); // 2021-03-31
export const INVOICE_VERSION_V201910_LABEL = "〜 令和3年3月";

export const INVOICE_VERSION_V202104 = 2;
export const INVOICE_VERSION_V202104_END_DATE = new Date(
  2024,
  2,
  31,
  23,
  59,
  59,
  99
); // 2024-03-31
export const INVOICE_VERSION_V202104_LABEL =
  "令和3年4月・令和4年10月 報酬改定版";

export const INVOICE_VERSION_CURRENT = 3;
// export const INVOICE_VERSION_CURRENT_END_DATE = new Date("9999-03-31");
export const INVOICE_VERSION_CURRENT_LABEL = "令和6年4月 報酬改定版";

// バージョンの選択肢リスト 1,2,3
export const INVOICE_VERSION_OPTIONS_123 = [
  {
    value: INVOICE_VERSION_CURRENT,
    label: INVOICE_VERSION_CURRENT_LABEL
  },
  {
    value: INVOICE_VERSION_V202104,
    label: INVOICE_VERSION_V202104_LABEL
  },
  {
    value: INVOICE_VERSION_V201910,
    label: INVOICE_VERSION_V201910_LABEL
  }
];

// バージョンの選択肢リスト 2,3
export const INVOICE_VERSION_OPTIONS_23 = [
  {
    value: INVOICE_VERSION_CURRENT,
    label: INVOICE_VERSION_CURRENT_LABEL
  },
  {
    value: INVOICE_VERSION_V202104,
    label: INVOICE_VERSION_V202104_LABEL
  }
];

// 利用者ごと画面の利用者一覧に表示するボタン群
export type RecordEachUserButtonsType = {
  name: string;
  url: string;
  isLastButton?: boolean;
}[];

// 利用者ごと ユニット/グループ選択ボックスの初期選択肢
export const DEFAULT_UNIT_SELECT_BOX = {
  label: "すべてのユニット/グループ",
  value: "0"
};

// 一括印刷のデフォルト項目上書き指定
export const RECORD_BATCH_PRINTING_OPTIONS = [
  { label: "記録を選択", value: "" },
  { label: "支援記録", value: "supportB" }
];

export type InvoiceVersionUrl = {
  version: number;
  url: string;
};

// 絞り込みのパターンリスト
export type PatternListType = {
  id: number;
  targetKana: string;
  viewKana: string;
  alphabet: string;
  checked: boolean;
  disabled: boolean;
};

export const patternListFormat: PatternListType[] = [
  {
    id: 1,
    viewKana: "あ",
    alphabet: "a",
    targetKana: "[ァ-オ]|[ヴ]",
    checked: false,
    disabled: false
  },
  {
    id: 2,
    viewKana: "か",
    alphabet: "k",
    targetKana: "[カ-ゴ]|[ヵ-ヶ]",
    checked: false,
    disabled: false
  },
  {
    id: 3,
    viewKana: "さ",
    alphabet: "s",
    targetKana: "[サ-ゾ]",
    checked: false,
    disabled: false
  },
  {
    id: 4,
    viewKana: "た",
    alphabet: "t",
    targetKana: "[タ-ド]",
    checked: false,
    disabled: false
  },
  {
    id: 5,
    viewKana: "な",
    alphabet: "n",
    targetKana: "[ナ-ノ]",
    checked: false,
    disabled: false
  },
  {
    id: 6,
    viewKana: "は",
    alphabet: "h",
    targetKana: "[ハ-ポ]",
    checked: false,
    disabled: false
  },
  {
    id: 7,
    viewKana: "ま",
    alphabet: "m",
    targetKana: "[マ-モ]",
    checked: false,
    disabled: false
  },
  {
    id: 8,
    viewKana: "や",
    alphabet: "y",
    targetKana: "[ャ-ヨ]",
    checked: false,
    disabled: false
  },
  {
    id: 9,
    viewKana: "ら",
    alphabet: "r",
    targetKana: "[ラ-ロ]",
    checked: false,
    disabled: false
  },
  {
    id: 10,
    viewKana: "わ",
    alphabet: "w",
    targetKana: "[ヮ-ン]|[ヷ-ヺ]",
    checked: false,
    disabled: false
  }
];

// モニタリング実施状況：未実施
export const SUPPORT_PLAN_EVALUATION_STATUS_DEFAULT = "0";
