import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";
import * as queryString from "query-string";

export type ServiceCodeSearchParameters = {
  page?: number;
  content: number;
  physical_care: number;
  number_of_participants: number;
  whole_calculated_hours?: number | null;
  calculated_hours_daytime?: number;
  calculated_hours_nighttime?: number;
  calculated_hours_late_night?: number;
  calculated_hours_early_morning?: number;
  duplicate_calculated_hours?: number | null;
  duplication_calculated_hours_daytime?: number;
  duplication_calculated_hours_nighttime?: number;
  duplication_calculated_hours_late_night?: number;
  duplication_calculated_hours_early_morning?: number;
};

export type GetServiceCodeSearchResponse = BaseResponse<{
  count: number;
  page: number | null;
  service_codes: {
    service_code_id: number;
    content: number;
    name: string;
    service_code: string | null;
    unit_price: number | null;
  }[];
}>;

// 自治体情報に紐づくサービスコードを取得する
const getServiceCodeSearch = (
  municipalityId: string,
  params: ServiceCodeSearchParameters
): Promise<AxiosResponse<GetServiceCodeSearchResponse>> => {
  const url = `${VERSION_URL_201910}/service_code/${municipalityId}/search`;
  const query = `?${queryString.stringify(params)}`;
  return request.get<GetServiceCodeSearchResponse>(url + query);
};

export default getServiceCodeSearch;
