import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { dateToLocalisedString } from "@/utils/date";
import { SUPPORT_METHOD_ITEMS } from "@constants/mgr/SHUROTEICHAKU/variables";

import { SupportReportState } from "@stores/domain/supportReport/types";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 680,
      marginBottom: 53
    },
    headerCell: {
      fontSize: 16,
      padding: "8px 16px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        padding: "8px 16px "
      }
    },
    KnowbeTableCell1: {
      width: 176,
      paddingLeft: "16px",
      fontSize: 16,
      verticalAlign: "middle"
    },
    KnowbeTableCell2: {
      width: 370,
      paddingLeft: "16px",
      fontSize: 16,
      verticalAlign: "middle"
    },
    KnowbeTableCell3: {
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        paddingLeft: "16px",
        verticalAlign: "middle"
      }
    },
    secondaryInputLabel: {
      position: "relative"
    },
    helpMark: {
      position: "absolute",
      marginLeft: "5px"
    }
  });

type OwnProps = {
  usageResults: SupportReportState["support"]["usage_results"];
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

// 支援レポートで使用する「支援実績」欄
export const SupportMethodDisplay = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { usageResults, isEditing } = props;

    const headData = [
      {
        label: "支援実施日",
        className: props.classes.headerCell
      },
      {
        label: "支援方法・場所",
        className: props.classes.headerCell
      },
      {
        label: (
          <div className={props.classes.secondaryInputLabel}>
            帳票に表示
            <span className={props.classes.helpMark}>
              <HelpToolTip
                title={<HelpTipMessages name="supportMethotdFlag" />}
              />
            </span>
          </div>
        ),
        className: props.classes.headerCell
      }
    ];

    const bodyData = usageResults.map((item, i) => [
      {
        label: `${dateToLocalisedString(item.target_date, "YYYY年M月D日")}`,
        className: props.classes.KnowbeTableCell1
      },
      {
        label: `${
          item.support_method === 6
            ? item.support_method_other
            : SUPPORT_METHOD_ITEMS[item.support_method].label.replace(
                /\r?\n/g,
                ""
              )
        }`,
        className: props.classes.KnowbeTableCell2
      },
      {
        label: (
          <FormikCheckbox
            name={`usage_results[${i}].display_form_flg`}
            label=""
            style={{ margin: 0 }}
            disabled={!isEditing}
          />
        ),
        className: props.classes.KnowbeTableCell3
      }
    ]);
    return (
      <KnowbeTable className={props.classes.table}>
        <KnowbeTableHead
          uniqueKey="支援実績"
          height={40}
          items={headData}
          backgroundColor="secondary"
        />
        <KnowbeTableBody uniqueKey="支援実績" itemsContainer={bodyData} />
      </KnowbeTable>
    );
  }
);
