import { InitialDataValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import validator from "@validator";
import { InitialErrors } from "@interfaces/v202104/mgr/KEIKAKUSODAN/report/initial";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.memo === undefined &&
      validationResult.initial.hospitalizationRelationAddition === undefined
    );
  }
  return false;
};

const hospitalizationRelationAdditionValidate = (
  hospitalizationRelationAddition: string,
  intensiveSupport3AdditionFlg: boolean
): string | undefined => {
  if (
    hospitalizationRelationAddition ===
      HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[1].value &&
    intensiveSupport3AdditionFlg
  ) {
    return "集中支援加算の③が選択されている場合、入院時情報連携加算（Ⅰ）は選択しないでください";
  }
  return undefined;
};

export const validation = (values: InitialDataValues): InitialDataErrors => {
  return {
    initial: {
      hospitalizationRelationAddition: hospitalizationRelationAdditionValidate(
        values.initial.hospitalizationRelationAddition,
        values.initial.intensiveSupport3AdditionFlg
      ),
      memo: validator(values.initial.memo, {
        type: "checkCharacterLength",
        length: 50
      })
    }
  };
};

export const submitSetupValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.numberOfEmployees === undefined &&
      validationResult.initial.numberOfHandicappedChild === undefined &&
      validationResult.initial.numberOfKeikakusodan === undefined
    );
  }
  return false;
};

export const setupValidation = (
  values: InitialDataValues,
  facilityState: FacilityState
): InitialDataErrors => {
  return {
    initial: {
      numberOfEmployees: validator(
        values.initial.numberOfEmployees,
        "required",
        "naturalNumber",
        {
          type: "upperLimit",
          upperLimit: 99
        }
      ),
      numberOfHandicappedChild:
        facilityState.facilityType === FACILITY_TYPE_KEIKAKUSODAN &&
        facilityState.integratedManagementHandicappedChildFlg
          ? validator(
              values.initial.numberOfHandicappedChild,
              "required",
              "naturalNumber",
              {
                type: "upperLimit",
                upperLimit: 9999
              }
            )
          : undefined,
      numberOfKeikakusodan:
        facilityState.facilityType === FACILITY_TYPE_SHOGAIJISODAN &&
        facilityState.integratedManagementHandicappedChildFlg
          ? validator(
              values.initial.numberOfKeikakusodan,
              "required",
              "naturalNumber",
              {
                type: "upperLimit",
                upperLimit: 9999
              }
            )
          : undefined
    }
  };
};
