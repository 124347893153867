import React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { SettingForm } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/setting/SettingForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

export const SupportLedgerSetting = (): JSX.Element => (
  <AdminTemplate pageName="地域定着支援台帳の設定">
    <SettingForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
