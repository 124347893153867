import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostServiceDeliveryDetails = {
  service_delivery_records: {
    service_delivery_records_id: number | null;
    inout_results_id: number | null;
    support_procedure_forms_id: number | null; // 行動のみ設定
    users_in_facility_id: number;
    target_date: string;
    status?: number;
    status_text?: string; // 移動支援のみ
    emergency_support_flg?: number;
    sputum_implementation_flg?: number;
    body_restricted_still_flg?: number;
    emergency_guide_support_flg?: number; // 重度のみ設定
    accompany_support_flg?: number; // 重度のみ設定
    number_of_practitioner?: number;
    number_of_supporters?: number; // 移動支援のみ
    physical_care_flg?: number; // 移動支援のみ
    license_same_flg?: number; // 移動支援のみ
    service_delivery_record_practitioners: {
      service_delivery_record_practitioners_id: number | null;
      start_time?: string | null;
      end_time?: string | null;
      number_of_time?: string;
      number_of_rides?: number | null;
      calculated_moving_hours?: number; // 重度のみ設定
      accompany_support_in_time?: string | null; // 重度のみ設定
      accompany_support_out_time?: string | null; // 重度のみ設定
      calculated_accompany_support_hours?: number; // 重度のみ設定
      calculated_accompany_support_moving_hours?: number; // 重度のみ設定
      practitioner?: number | null;
      practitioner2?: number | null; // 移動支援のみ
      practitioner3?: number | null; // 移動支援のみ
      practitioner4?: number | null; // 移動支援のみ
      practitioner5?: number | null; // 移動支援のみ
      practitioner_name?: string; // 行動のみ設定
      practitioner2_name?: string; // 移動支援のみ
      practitioner3_name?: string; // 移動支援のみ
      practitioner4_name?: string; // 移動支援のみ
      practitioner5_name?: string; // 移動支援のみ
      practitioner_num?: number;
      display_flg?: number;
      service_delivery_record_practitioner_details?: {
        service_delivery_record_practitioner_details_id: number | null;
        action_class?: number;
        start_time?: string | null;
        end_time?: string | null;
        is_delete?: number;
      }[];
      custom_record: {
        custom_records_category_id: number;
        custom_record_input_id: number | null;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        checked?: number;
      }[];
      service_delivery_record_practitioner_destinations?: {
        service_delivery_record_practitioner_destinations_id: number | null;
        when_time?: string | null;
        place?: string | null;
        support?: string | null;
        is_delete?: number;
      }[];
      // 同行援護のみ
      service_delivery_record_practitioner_money_managements?: {
        traveling_expenses: {
          service_delivery_record_practitioner_money_managements_id:
            | number
            | null;
          transportation?: number | null;
          free_text?: string | null;
          amount_money?: string | null;
          is_delete?: number;
        }[];
        other_expenses: {
          service_delivery_record_practitioner_money_managements_id:
            | number
            | null;
          transportation?: number | null;
          free_text?: string | null;
          amount_money?: string | null;
          is_delete?: number;
        }[];
      };
    }[];
  };
};

/**
 * サービス提供記録詳細新規登録・更新する
 * @param params 登録・更新データ
 */
export const postServiceDeliveryDetails = async (
  params: PostServiceDeliveryDetails
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/service_delivery/details`;
  return request.post(url, params);
};
