import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { AttendanceButton } from "@components/atoms/buttons/AttendanceButton";
import { FormattedAttendanceList } from "@stores/domain/attendance/type";

const styles = (): StyleRules =>
  createStyles({
    contentWrapper: {
      display: "flex"
    },
    category: {
      fontSize: 20,
      color: "#616161",
      paddingTop: 36,
      marginRight: 16
    },
    attendanceList: {
      display: "flex",
      flexWrap: "wrap",
      width: "100%"
    },
    buttonWrapper: {
      marginLeft: 24,
      marginTop: 24,
      width: "15%",
      minWidth: 180,
      "@media only screen and (max-width: 875px)": {
        width: "40%"
      }
    },
    noUser: {
      textAlign: "center",
      color: "#616161",
      fontSize: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "calc(100vh - 100px)",
      paddingTop: 20
    }
  });

type OwnProps = {
  attendanceList?: FormattedAttendanceList;
  targetSelect: (id: number, kana: string) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AttendanceListCore = (props: Props): JSX.Element => {
  return (
    <div>
      {props.attendanceList && props.attendanceList.length ? (
        props.attendanceList.map((attendance) => (
          <div
            className={props.classes.contentWrapper}
            key={attendance.viewKana}
          >
            <div className={props.classes.category}>{attendance.viewKana}</div>
            <div className={props.classes.attendanceList}>
              {attendance.attendance.map((target) => (
                <div
                  className={props.classes.buttonWrapper}
                  key={target.uif_id}
                >
                  <AttendanceButton
                    id={target.uif_id}
                    type={target.attendanceStatus}
                    status={target.status}
                    name={target.displayName}
                    kana={attendance.viewKana}
                    handleClick={props.targetSelect}
                  />
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className={props.classes.noUser}>
          利用者が登録されていません。
          <br />
          管理画面の「利用者情報」で登録後、ご利用ください。
        </div>
      )}
    </div>
  );
};

export const AttendanceList = withStyles(styles)(AttendanceListCore);
