import { GetSupportProcedureListResponse } from "@api/requests/supportProcedure/getSupportProcedureList";
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { GetSupportProcedurePrint } from "@api/requests/supportProcedure/getSupportProcedurePrint";

export type SelectedSupportProcedureIds = {
  serviceDeliveryRecordsId: number | null;
  inoutResultsId: number | null;
  supportProcedureFormsId: number | null;
  targetDate?: string | null;
};

/**
 * ActionNames
 */
export const FETCH_SUPPORT_PROCEDURE_LIST_STARTED =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_LIST_STARTED";
export const FETCH_SUPPORT_PROCEDURE_LIST_SUCCESS =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_LIST_SUCCESS";
export const FETCH_SUPPORT_PROCEDURE_LIST_FAILED =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_LIST_FAILED";

export const DELETE_SUPPORT_PROCEDURE_LIST_STARTED =
  "SUPPORT_PROCEDURE/DELETE_SUPPORT_PROCEDURE_LIST_STARTED";
export const DELETE_SUPPORT_PROCEDURE_LIST_SUCCESS =
  "SUPPORT_PROCEDURE/DELETE_SUPPORT_PROCEDURE_LIST_SUCCESS";
export const DELETE_SUPPORT_PROCEDURE_LIST_FAILED =
  "SUPPORT_PROCEDURE/DELETE_SUPPORT_PROCEDURE_LIST_FAILED";

export const FETCH_MODAL_SUPPORT_PROCEDURE_LIST_STARTED =
  "SUPPORT_PROCEDURE/FETCH_MODAL_SUPPORT_PROCEDURE_LIST_STARTED";
export const FETCH_MODAL_SUPPORT_PROCEDURE_LIST_SUCCESS =
  "SUPPORT_PROCEDURE/FETCH_MODAL_SUPPORT_PROCEDURE_LIST_SUCCESS";
export const FETCH_MODAL_SUPPORT_PROCEDURE_LIST_FAILED =
  "SUPPORT_PROCEDURE/FETCH_MODAL_SUPPORT_PROCEDURE_LIST_FAILED";

export const FETCH_SUPPORT_PROCEDURE_DETAIL_STARTED =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_DETAIL_STARTED";
export const FETCH_SUPPORT_PROCEDURE_DETAIL_SUCCESS =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_DETAIL_SUCCESS";
export const FETCH_SUPPORT_PROCEDURE_DETAIL_FAILED =
  "SUPPORT_PROCEDURE/FETCH_SUPPORT_PROCEDURE_DETAIL_FAILED";

export const POST_SUPPORT_PROCEDURE_DETAIL_STARTED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_STARTED";
export const POST_SUPPORT_PROCEDURE_DETAIL_SUCCESS =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_SUCCESS";
export const POST_SUPPORT_PROCEDURE_DETAIL_FAILED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FAILED";

export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_STARTED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_STARTED";
export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_SUCCESS =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_SUCCESS";
export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_FAILED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_FAILED";

export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_STARTED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_STARTED";
export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_SUCCESS =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_SUCCESS";
export const POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_FAILED =
  "SUPPORT_PROCEDURE/POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_FAILED";

export const FETCH_PRINT_RECORD_STARTED =
  "SUPPORT_PROCEDURE/FETCH_PRINT_RECORD_STARTED";
export const FETCH_PRINT_RECORD_SUCCESS =
  "SUPPORT_PROCEDURE/FETCH_PRINT_RECORD_SUCCESS";
export const FETCH_PRINT_RECORD_FAILED =
  "SUPPORT_PROCEDURE/FETCH_PRINT_RECORD_FAILED";

export const CLEAR_SUPPORT_PROCEDURE_DETAIL_STATE =
  "CLEAR_SUPPORT_PROCEDURE_DETAIL_STATE";

export type SupportProcedureState = {
  supportProcedureList: GetSupportProcedureListResponse["data"];
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  print: GetSupportProcedurePrint["data"];
};
