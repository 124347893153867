import { Dispatch } from "redux";
import * as actions from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/actions";
import * as loadingActions from "@stores/loading/actions";
import {
  normalizeGetFacilityResult,
  normalizePostFacilityParams,
  normalizeFormValue,
  normalizePostDifferenceParams
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/normalizer";
import { FacilityValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/facility/initialValues";
import facilityApi from "@api/requests/v202104/facility";
import dispatches from "@stores/dispatches";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

export const fetch = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchStarted());
  await facilityApi
    .getFacility()
    .then((res) => {
      const normalizedData = normalizeGetFacilityResult(res.data);
      dispatch(actions.fetchSuccess(normalizedData));
    })
    .catch((e) => {
      dispatch(actions.fetchFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const post = (dispatch: Dispatch) => async (
  values: FacilityValues,
  facility: FacilityValues,
  facilityType: number | null
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postStarted());
  const isExistKeikaku =
    facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
    facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  const isExistShogaiji =
    facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN ||
    facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  const params = normalizeFormValue(values, isExistKeikaku, isExistShogaiji);
  const normalizedData = normalizePostDifferenceParams(
    params,
    facility,
    isExistKeikaku,
    isExistShogaiji
  );

  let officeName = "";
  if (facilityType === FACILITY_TYPE_KEIKAKUSODAN) {
    officeName = values.facilityInfoKeikaku.officeName;
  } else if (facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN) {
    officeName = values.facilityInfoShogaiji.officeName;
  } else if (facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) {
    officeName = `${values.facilityInfoKeikaku.officeName}/${values.facilityInfoShogaiji.officeName}`;
  }
  await facilityApi
    .postFacility(normalizedData)
    .then(async () => {
      dispatch(
        actions.postSuccess(normalizePostFacilityParams(params, facilityType))
      );
      dispatches
        .userDispatch(dispatch)
        .setFacilityValuesToUser(officeName, values.basic.corporationName);
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "保存が完了しました",
        variant: "success"
      });
    })
    .catch((e) => {
      dispatch(actions.postFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type FacilityDispatcher = {
  fetch: () => Promise<void>;
  post: (
    values: FacilityValues,
    facility: FacilityValues,
    facilityType: number | null
  ) => Promise<void>;
};

export const KEIKAKUSODANFacilityDispatcher = (
  dispatch: Dispatch
): FacilityDispatcher => ({
  fetch: fetch(dispatch),
  post: post(dispatch)
});
