/**
 * 自然数チェック（0含む）、空文字は判定しない、小数点第二位以降も許容
 * @param value
 */
export const decimalPointByEveryQuarterMinutesAfter30Minutes = (
  value: string,
  message = ""
): string | undefined => {
  let errorMessage;
  if (
    value !== "" &&
    (value === "0.25" || !/^([1-9]\d*|0)(\.(25|50?|75|00?))?$/.test(value))
  ) {
    errorMessage = `${message}最初の30分(0.5)以降は15分(0.25)刻みで入力してください`;
  }
  return errorMessage;
};
