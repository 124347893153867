import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { SortedCustomRecords } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailContainer";
// ui
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import Divider from "@material-ui/core/Divider";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
// formik
import { FormikProps, getIn } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  DAY_SELECT_OPTIONS,
  DAY_SELECT_TYPE,
  FacilityType,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO
} from "@constants/variables";
import {
  MIXUTER_INPUT_TYPE,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { EXCRETION_MAGAGEMENT_STATE } from "@constants/mgr/JUDOHOMONKAIGO/variables";
// utils
import {
  isCost,
  isExcretionManagement,
  isHydration,
  isMoneyAndDeposit,
  isStaffComment
} from "@utils/domain/serviceDelivery/extractDefaultItems";

const styles = (): StyleRules =>
  createStyles({
    multiTextWrap: {
      width: 114,
      marginRight: 20,
      "&:last-child": {
        marginRight: 0
      }
    },
    multiTextStateItemWrap: {
      marginTop: 20
    },
    multiTextWrapTime: {
      display: "flex",
      "&:last-child": {
        marginRight: 0
      }
    },
    multiTextWrapMoney: {
      width: 120,
      marginRight: 20,
      "&:last-child": {
        marginRight: 0
      }
    },
    sectionWrap: {
      marginTop: 20
    },
    sectionWrapText: {
      display: "flex",
      marginTop: 20,
      justifyContent: "space-around"
    },
    divider: {
      backgroundColor: "#cfd8dc",
      margin: "16px 0px 0px 0px"
    },
    dividerAfterCheckBoxes: {
      backgroundColor: "#cfd8dc",
      margin: "8px 0px 0px 0px"
    },
    listLabel: {
      width: 136,
      minWidth: 136,
      fontSize: 14,
      whiteSpace: "pre-line",
      color: "rgba(0, 0, 0, 0.6)"
    },
    moneyItemsLabel: {
      width: 180
    },
    radioIcon: {
      position: "relative",
      top: 3,
      color: "#607d8b",
      // atom/HelpToolTipの「marginRight: 16」を上書き
      marginRight: 0
    },
    itemListRow: {
      marginBottom: 0
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  sortedCustomRecordsItems: SortedCustomRecords;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  categoryType: number;
  practitionerNum: number;
  facilityType: FacilityType;
  openInsertPhraseModal: (
    fieldName: string,
    customRecord: CustomRecordsWithCategoryState[0]["custom_record_items"][0]
  ) => (e: React.MouseEvent) => void;
  createUnit: (
    choice: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["custom_record_item_choices"][0],
    defaultItem: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["default_item"],
    facilityTypeValues: FacilityType,
    categoryTypeParam: number
  ) => string;
  handleBlurDecimal: (
    defaultChoice: number | null,
    categoryTypeParam: number
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
  handleChangeNumber: (
    defaultChoice: number | null
  ) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => string;
  handleBlurMoney: (event: React.FormEvent<HTMLInputElement>) => string | void;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  handleBlurCost: (
    defaultItem: number | null,
    defaultChoice: number | null
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
  handleBlurMoneyAndDeposit: (
    defaultItem: number | null,
    defaultChoice: number | null
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
} & WithStyles<typeof styles>;

export const ServiceDeliveryDetailItemsCore = (props: Props): JSX.Element => {
  const {
    sortedCustomRecordsItems,
    practitionerValues,
    practitionerNum,
    classes,
    facilityType,
    openInsertPhraseModal,
    categoryType
  } = props;
  const { values: formikValues } = props.formikProps;

  const lastIndex =
    sortedCustomRecordsItems && sortedCustomRecordsItems.length - 1;

  return (
    <div>
      {sortedCustomRecordsItems && sortedCustomRecordsItems.length > 0 ? (
        sortedCustomRecordsItems.map((value, i) => {
          if (!value) {
            return null;
          }

          // uncontrolledエラー防止のため
          // https://reactjs.org/docs/forms.html#controlled-components
          if (
            Object.keys(practitionerValues.custom_record.input_type_checkbox)
              .length === 0
          ) {
            return null;
          }

          const { customRecord, checkedItems } = value;

          const fieldName = `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_text.${customRecord.id}.input_data`;

          switch (customRecord.input_type) {
            // テキスト形式
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
              //   // 定型文
              const fixedPhraseData = customRecord.custom_record_item_choices
                ? customRecord.custom_record_item_choices.filter(
                    (item) => item.hidden === 0
                  )
                : [];
              // visibility === 0かつ各項目に入力がない場合はスキップ
              return (
                <React.Fragment key={`${customRecord.id}_input_text`}>
                  <div className={classes.sectionWrapText}>
                    <div style={{ width: "100%", marginRight: 15 }}>
                      <RecordSupportTableField
                        type="text"
                        label={customRecord.name}
                        name={fieldName}
                        classes={{ listRow: classes.itemListRow }}
                        value="-"
                        defaultValue=""
                        placeholder=""
                        isEditing
                        tooltip={
                          // 重度も「職員考察」のdefault_itemは同じ番号
                          isStaffComment(
                            customRecord.default_item,
                            facilityType
                          ) ? (
                            <HelpToolTip
                              classes={{ icon: classes.radioIcon }}
                              placement="right-end"
                              title={
                                <HelpTipMessages
                                  name={
                                    facilityType === FacilityType.IDOSHIEN
                                      ? "staffComment"
                                      : "supportStaffComment"
                                  }
                                />
                              }
                            />
                          ) : null
                        }
                      />
                    </div>
                    {/* 定型文の挿入モーダル */}
                    {fixedPhraseData.length > 0 ? (
                      <div>
                        <KnowbeButton
                          style={{ minHeight: 30 }}
                          kind="outline"
                          onClick={openInsertPhraseModal(
                            fieldName,
                            customRecord
                          )}
                          minWidth={100}
                        >
                          定型文
                        </KnowbeButton>
                      </div>
                    ) : (
                      <div style={{ minWidth: 100 }} />
                    )}
                  </div>
                  {lastIndex !== i && (
                    <Divider variant="middle" className={classes.divider} />
                  )}
                </React.Fragment>
              );
            }
            // チェックボックス形式
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
              return (
                <div
                  key={`${customRecord.id}_input_checkbox`}
                  className={classes.sectionWrap}
                >
                  <div style={{ width: "90%" }}>
                    <RecordSupportTableField
                      type="custom"
                      label={customRecord.name}
                      classes={{ listRow: classes.itemListRow }}
                    >
                      <FormGroup row>
                        {customRecord.custom_record_item_choices &&
                          customRecord.custom_record_item_choices.map(
                            (choice) => {
                              const checked =
                                checkedItems &&
                                checkedItems.some(
                                  (item) => item.choiced_item_id === choice.id
                                );
                              if (
                                (choice.hidden === 1 && !checked) ||
                                (customRecord.visibility === 0 && !checked)
                              ) {
                                return null;
                              }

                              return (
                                <FormikCheckbox
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox.${customRecord.id}.${choice.id}.checked`}
                                  key={`$checkbox_choices_${customRecord.id}_${choice.id}`}
                                  label={
                                    <span
                                      style={{
                                        fontSize: 16,
                                        color: "rgba(0, 0, 0, 0.87)"
                                      }}
                                    >
                                      {choice.name}
                                    </span>
                                  }
                                  style={{ marginBottom: 0, marginRight: 24 }}
                                />
                              );
                            }
                          )}
                      </FormGroup>
                    </RecordSupportTableField>
                  </div>
                  {lastIndex !== i && (
                    <Divider
                      variant="middle"
                      className={classes.dividerAfterCheckBoxes}
                    />
                  )}
                </div>
              );
            }
            // 複数テキスト入力
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
              // 排泄管理（排便）（重度のみ）
              const isExcretionItem = isExcretionManagement(
                customRecord.default_item,
                facilityType
              );
              // 水分補給（量）（重度のみ）
              const isHydrationItem = isHydration(
                customRecord.default_item,
                facilityType
              );

              return (
                <div
                  key={`${customRecord.id}_input_multi_text`}
                  className={classes.sectionWrap}
                >
                  <div style={{ width: "90%" }}>
                    <RecordSupportTableField
                      type="custom"
                      label={customRecord.name}
                      classes={{ listRow: classes.itemListRow }}
                    >
                      <FormGroup row>
                        <div
                          style={{
                            width: "100%",
                            display: isExcretionItem ? "block" : "flex"
                          }}
                        >
                          {customRecord.custom_record_item_choices &&
                            customRecord.custom_record_item_choices.map(
                              (choice) => {
                                const unit = props.createUnit(
                                  choice,
                                  customRecord.default_item,
                                  facilityType,
                                  categoryType
                                );
                                const isStateItem =
                                  isExcretionItem &&
                                  choice.default_choice ===
                                    EXCRETION_MAGAGEMENT_STATE;
                                return (
                                  <div
                                    className={
                                      // 「状態」項目のみ幅を広げる
                                      isStateItem
                                        ? classes.multiTextStateItemWrap
                                        : classes.multiTextWrap
                                    }
                                    key={`${customRecord.id}_multi_text_${choice.id}`}
                                  >
                                    <RecordTextField
                                      name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_multi_text.${customRecord.id}.${choice.id}.input_data`}
                                      value="-"
                                      defaultValue=""
                                      label={isHydrationItem ? "" : choice.name}
                                      placeholder=""
                                      isEditable
                                      labelType="default"
                                      nonMultiline={!isStateItem}
                                      endAdornmentLabel={unit}
                                      onBlurHook={
                                        isStateItem
                                          ? undefined
                                          : props.handleBlurDecimal(
                                              choice.default_choice,
                                              categoryType
                                            )
                                      }
                                      onChangeHook={
                                        facilityType ===
                                          FacilityType.JUDOHOMONKAIGO &&
                                        categoryType ===
                                          CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO.body_care
                                          ? undefined
                                          : props.handleChangeNumber(
                                              choice.default_choice
                                            )
                                      }
                                      maxLength={isStateItem ? undefined : 10}
                                    />
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </FormGroup>
                    </RecordSupportTableField>
                  </div>
                  {lastIndex !== i && (
                    <Divider variant="middle" className={classes.divider} />
                  )}
                </div>
              );
            }
            // 選択式（入力項目）
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.mixture: {
              // 身体介護・院内介助
              const checkData = customRecord.custom_record_item_choices.find(
                (item) =>
                  item.default_choice === 1 &&
                  item.default_choice_input_type === 2
              );
              // 開始時間
              const startTimeData = customRecord.custom_record_item_choices.find(
                (item) =>
                  item.default_choice ===
                    MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.start &&
                  item.default_choice_input_type === MIXUTER_INPUT_TYPE.text
              );

              // 終了時間
              const endTimeData = customRecord.custom_record_item_choices.find(
                (item) =>
                  item.default_choice ===
                    MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.end &&
                  item.default_choice_input_type === MIXUTER_INPUT_TYPE.text
              );

              // 目的地・交通手段など
              const destinationData = customRecord.custom_record_item_choices.find(
                (item) =>
                  item.default_choice ===
                  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
              );

              if (!startTimeData || !endTimeData || !checkData) {
                return null;
              }

              const isShowTime = getIn(
                formikValues,
                `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${checkData.id}.checked`
              );

              // OFFにした時「開始時間」「終了時間」の値を空にする
              const onChangeMixtureCheckBox = (): void => {
                if (isShowTime) {
                  props.formikProps.setFieldValue(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.input_data`,
                    ""
                  );
                  props.formikProps.setFieldValue(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.timeDate`,
                    DAY_SELECT_TYPE.today
                  );
                  props.formikProps.setFieldValue(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`,
                    ""
                  );
                  props.formikProps.setFieldValue(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                    DAY_SELECT_TYPE.today
                  );
                  if (destinationData) {
                    props.formikProps.setFieldValue(
                      `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${destinationData.id}.input_data`,
                      ""
                    );
                  }
                }
              };

              const handleChangeDateSelect = (): void => {
                props.formikProps.setFieldTouched(
                  `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`,
                  true
                );
              };

              const handleChangeTime = (): void => {
                props.formikProps.setFieldTouched(
                  `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                  true
                );
              };

              // 提供人数「２」→「１」の時の対応（選択肢が””になるのを防ぐ）
              if (
                getIn(
                  formikValues,
                  `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`
                ) === ""
              ) {
                props.setFormikFieldValue(
                  `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                  DAY_SELECT_OPTIONS[0].value
                );
              }

              // visibility === 0かつ各項目に入力がない場合はスキップ
              return (
                <div
                  key={`${customRecord.id}_input_mixture`}
                  className={classes.sectionWrap}
                >
                  <RecordSupportTableField
                    type="custom"
                    label={customRecord.name}
                    classes={{ listRow: classes.itemListRow }}
                  >
                    <div style={{ width: "90%" }}>
                      {checkData && (
                        <FormikCheckbox
                          name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${checkData.id}.checked`}
                          key={`mixture_choices_${customRecord.id}_${checkData.id}`}
                          label={
                            <span
                              style={{
                                fontSize: 16,
                                color: "rgba(0, 0, 0, 0.87)"
                              }}
                            >
                              {checkData.name}
                            </span>
                          }
                          style={{ marginBottom: 0, marginRight: 24 }}
                          onChangeHook={onChangeMixtureCheckBox}
                        />
                      )}
                      {isShowTime && (
                        <>
                          <FormGroup row>
                            <div
                              style={{
                                width: "100%",
                                display: "flex"
                              }}
                            >
                              <div className={classes.multiTextWrapTime}>
                                <FormikSelect
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.timeDate`}
                                  label="開始時間"
                                  options={DAY_SELECT_OPTIONS}
                                  style={{ width: 80, marginBottom: 0 }}
                                  noerrortext
                                />
                                <FormikTime
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.input_data`}
                                  label=""
                                  placeholder="00:00"
                                  size="smallMedium"
                                  maxLength={5}
                                  style={{
                                    width: 156,
                                    marginTop: 16,
                                    marginBottom: 0
                                  }}
                                />
                              </div>

                              <div className={classes.multiTextWrapTime}>
                                <FormikSelect
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`}
                                  label="終了時間"
                                  options={DAY_SELECT_OPTIONS}
                                  style={{ width: 80, marginBottom: 0 }}
                                  noerrortext
                                  onChangeHook={handleChangeDateSelect}
                                />
                                <FormikTime
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`}
                                  label=""
                                  placeholder="00:00"
                                  size="smallMedium"
                                  maxLength={5}
                                  onChangeHookTime={handleChangeTime}
                                  style={{
                                    width: 156,
                                    marginTop: 16,
                                    marginBottom: 0
                                  }}
                                />
                              </div>
                            </div>
                          </FormGroup>
                          {/* 目的地・交通手段など */}
                          {destinationData && (
                            <div style={{ marginTop: 20 }}>
                              <RecordTextField
                                name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${destinationData.id}.input_data`}
                                value="-"
                                defaultValue=""
                                label={destinationData.name}
                                placeholder=""
                                isEditable
                                labelType="default"
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </RecordSupportTableField>
                  {lastIndex !== i && (
                    <Divider variant="middle" className={classes.divider} />
                  )}
                </div>
              );
            }
            // 文字入力(金額形式)
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.money: {
              return (
                <div
                  key={`${customRecord.id}_input_money`}
                  className={classes.sectionWrap}
                >
                  <div style={{ width: "90%" }}>
                    <RecordSupportTableField
                      type="custom"
                      label={customRecord.name}
                      classes={{ listRow: classes.itemListRow }}
                    >
                      <FormGroup row>
                        <div
                          style={{
                            width: "100%",
                            display: "flex"
                          }}
                        >
                          {customRecord.custom_record_item_choices &&
                            customRecord.custom_record_item_choices.map(
                              (choice) => {
                                const blurHook = (): ((
                                  event: React.FormEvent<HTMLInputElement>
                                ) => string | void) => {
                                  if (
                                    isCost(
                                      customRecord.default_item,
                                      facilityType
                                    )
                                  ) {
                                    return props.handleBlurCost(
                                      customRecord.default_item,
                                      choice.default_choice
                                    );
                                  }
                                  if (
                                    isMoneyAndDeposit(
                                      customRecord.default_item,
                                      facilityType
                                    )
                                  ) {
                                    return props.handleBlurMoneyAndDeposit(
                                      customRecord.default_item,
                                      choice.default_choice
                                    );
                                  }
                                  return props.handleBlurMoney;
                                };
                                return (
                                  <React.Fragment
                                    key={`${customRecord.id}_money_${choice.id}`}
                                  >
                                    {customRecord.default_item === 29 &&
                                      choice.default_choice === 3 && (
                                        <div
                                          style={{
                                            fontSize: "14px",
                                            color: "rgba(0, 0, 0, 0.6)",
                                            margin: "0 20px"
                                          }}
                                        >
                                          （内訳）
                                        </div>
                                      )}
                                    <div
                                      className={classes.multiTextWrapMoney}
                                      key={`${customRecord.id}_input_money_${choice.id}`}
                                    >
                                      <RecordTextField
                                        name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_multi_text.${customRecord.id}.${choice.id}.input_data`}
                                        value="-"
                                        defaultValue=""
                                        label={choice.name}
                                        placeholder=""
                                        isEditable
                                        labelType="default"
                                        nonMultiline
                                        endAdornmentLabel="円"
                                        onBlurHook={blurHook()}
                                        InputLabelProps={{
                                          shrink: true,
                                          FormLabelClasses: {
                                            root: classes.moneyItemsLabel
                                          }
                                        }}
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                              }
                            )}
                        </div>
                      </FormGroup>
                    </RecordSupportTableField>
                  </div>
                  {lastIndex !== i && (
                    <Divider variant="middle" className={classes.divider} />
                  )}
                </div>
              );
            }
            // チェックボックス（カスタム可）＋テキスト
            case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text: {
              const textChoice = customRecord.custom_record_item_choices.find(
                (c) =>
                  c.default_choice_input_type ===
                  SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text
              );
              const textField = textChoice ? (
                <div style={{ width: "100%", marginRight: 15 }}>
                  <RecordTextField
                    name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox_and_text.${customRecord.id}.${textChoice.id}.input_data`}
                    value="-"
                    defaultValue=""
                    label={textChoice.name}
                    placeholder=""
                    isEditable
                    labelType="default"
                  />
                </div>
              ) : null;
              return (
                <div
                  key={`${customRecord.id}_checkbox_and_text`}
                  className={classes.sectionWrap}
                >
                  <div style={{ width: "90%" }}>
                    <RecordSupportTableField
                      type="custom"
                      label={customRecord.name}
                      classes={{ listRow: classes.itemListRow }}
                    >
                      <FormGroup row>
                        {customRecord.custom_record_item_choices &&
                          customRecord.custom_record_item_choices
                            .filter(
                              (c) =>
                                c.default_choice_input_type ===
                                SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox
                            )
                            .map((choice) => {
                              const checked =
                                checkedItems &&
                                checkedItems.some(
                                  (item) => item.choiced_item_id === choice.id
                                );
                              if (
                                (choice.hidden === 1 && !checked) ||
                                (customRecord.visibility === 0 && !checked)
                              ) {
                                return null;
                              }

                              return (
                                <FormikCheckbox
                                  name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox_and_text.${customRecord.id}.${choice.id}.checked`}
                                  key={`$checkbox_and_text_choices_${customRecord.id}_${choice.id}`}
                                  label={
                                    <span
                                      style={{
                                        fontSize: 16,
                                        color: "rgba(0, 0, 0, 0.87)"
                                      }}
                                    >
                                      {choice.name}
                                    </span>
                                  }
                                  style={{ marginBottom: 0, marginRight: 24 }}
                                />
                              );
                            })}
                      </FormGroup>
                      <FormGroup row>{textField}</FormGroup>
                    </RecordSupportTableField>
                    {lastIndex !== i && (
                      <Divider variant="middle" className={classes.divider} />
                    )}
                  </div>
                </div>
              );
            }
            default:
              return null;
          }
        })
      ) : (
        <div
          style={{
            marginTop: "20px",
            fontSize: "12px",
            color: "rgba(0, 0, 0, 0.6)"
          }}
        >
          項目が全て非表示に設定されています。提供記録の設定画面を確認してください。
        </div>
      )}
    </div>
  );
};

export const ServiceDeliveryDetailItems = withStyles(styles)(
  ServiceDeliveryDetailItemsCore
);
