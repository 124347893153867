import { RecordUserDetailValues as SEIKATSUKAIGOFormValues } from "@initialize/mgr/SEIKATSUKAIGO/record/userDetail/initialValues";
import { RecordUserDetailValues as IABFormValues } from "@initialize/mgr/IAB/record/userDetail/initialValues";
import { RecordUserDetailValues as SHUROTEICHAKUFormValues } from "@initialize/mgr/SHUROTEICHAKU/record/userDetail/initialValues";
import { RecordUserDetailValues as GroupHomeFormValues } from "@initialize/mgr/GroupHome/record/userDetail/initialValues";
import { RecordUserDetailValues as SHISETSUNYUSHOFormValues } from "@initialize/mgr/SHISETSUNYUSHO/record/userDetail/initialValues";
import { RecordUserDetailValues as TANKINYUSHOFormValues } from "@initialize/mgr/TANKINYUSHO/record/userDetail/initialValues";
import { PostSupportsParams } from "@api/requests/supports/postSupports";
import { PostSupportRecordUserParams } from "@api/requests/supports/postSupportRecordUser";
import { PostCustomSupportParams } from "@api/requests/supports/postCustomSupport";
import { WorkState } from "@stores/domain/work/types";
import { StaffState } from "@stores/domain/staff/types";
import getStaffName from "@utils/domain/staffs/getStaffName";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import omit from "lodash-es/omit";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";

type RecordUserDetailValues = SEIKATSUKAIGOFormValues | IABFormValues;
type FormSupport = RecordUserDetailValues["support"][0];
type PostSupport = PostSupportsParams["support"][0];

/**
 * 差分だけピックしたフォームの値をPostSupportが受け付ける値に変換する
 */
const supportMapping = (
  value: Partial<FormSupport>,
  work: WorkState,
  staff: StaffState
): PostSupport => {
  const supportParams = {} as PostSupportsParams["support"][0];

  if (value.staff_id === "") {
    supportParams.staff_id = null;
    supportParams.staff_name = null;
  } else if (value.staff_id) {
    supportParams.staff_id = value.staff_id;
    supportParams.staff_name = getStaffName(staff, value.staff_id);
  }

  if (value.correspondent_staff_id === "") {
    supportParams.correspondent_staff_id = null;
    supportParams.correspondent_staff_name = null;
  } else if (value.correspondent_staff_id) {
    supportParams.correspondent_staff_id = value.correspondent_staff_id;
    supportParams.correspondent_staff_name = getStaffName(
      staff,
      value.correspondent_staff_id
    );
  }

  if (value.support_work_history) {
    const { beforeValues, itemIdList } = value.support_work_history;
    supportParams.support_work_history = itemIdList.map((item, index) => {
      const beforeValue = beforeValues[index];
      // is_deleteがnull以外＆同じ位置に同じitemIdがあれば変更なしとみなして前回値を渡す
      if (!item.is_delete && beforeValue && beforeValue.item_id === item.id) {
        beforeValue.is_delete = null;
        return beforeValue;
      }
      return {
        inout_record_id: null,
        category_id: item.category_id || 0,
        item_id: +item.id,
        item_name: item.label || "",
        is_delete: item.is_delete
      };
    });
  }

  // 面談なし
  if (value.interview_flg === "0") {
    supportParams.interview_start_time = null;
    supportParams.interview_end_time = null;
    supportParams.interview_comment = "";
  }

  // 時間の項目に空文字を送ると`00:00`扱いになるので面談フラグがあっても消しておく
  if (value.interview_start_time === "") {
    supportParams.interview_start_time = null;
  }
  if (value.interview_end_time === "") {
    supportParams.interview_end_time = null;
  }

  // 対応の不要なパラメーターを取り出す(上書きされるので型定義の解決をすればOK)
  const restValue = omit(value, ["support_work_history"]);
  // merge
  return { ...restValue, ...supportParams };
};

/**
 * PostSupportsに渡すparamsの変換
 */
export const normalizeFormValuesToPostSupportsParams = (
  values: RecordUserDetailValues,
  initialValues: RecordUserDetailValues,
  work: WorkState,
  staff: StaffState
): PostSupportsParams => {
  const params: PostSupportsParams = {
    support: []
  };

  // 変更のないプロパティを除外する
  values.support.forEach((v, i) => {
    const res = omitByNoChanges(v, initialValues.support[i]);
    // どれか一つでもis_deleteが1だった場合support_work_historyをPOST
    if (v.support_work_history.itemIdList.some((item) => item.is_delete)) {
      res.support_work_history = v.support_work_history;
    }
    if (res && Object.keys(res).length !== 0) {
      // 必要なデータの復元
      res.id = v.id;
      res.inout_record_id = v.inout_record_id;
      res.uif_id = v.uif_id;
      if (res.support_work_history) {
        res.support_work_history = v.support_work_history;
      }
      const res2 = supportMapping(res, work, staff);
      params.support.push(res2);
    }
  });

  return params;
};

/**
 * postUserDetailSupportRecordに渡すparamsの変換
 */
export const normalizeFormValuesToPostSupportRecordParams = (
  values: SHUROTEICHAKUFormValues,
  initialValues: SHUROTEICHAKUFormValues,
  staff: StaffState
): PostSupportRecordUserParams => {
  const params: PostSupportRecordUserParams = {
    support_records: []
  };

  // 変更のないプロパティを除外する
  values.support.forEach((v, i) => {
    const supportRecordUserParams = {} as PostSupportRecordUserParams["support_records"][0];
    const res = omitByNoChanges(v, initialValues.support[i]);
    supportRecordUserParams.id = v.id;
    supportRecordUserParams.usage_results_id = v.usage_results_id;
    if (res.author === "") {
      res.author = null;
      res.author_name = null;
    } else if (res.author) {
      res.author = Number(v.author) || 0;
      res.author_name = getStaffName(staff, v.author);
    }
    // 新規作成時(作成日と雇用事業主は「initialValues」と差がないため)
    if (!v.id) {
      const creationDate = selectDateValueToDatePaddingZero(v.creation_date);
      if (creationDate) {
        supportRecordUserParams.creation_date = creationDate;
      } else {
        delete res.creation_date;
      }
      if (v.company_name) {
        supportRecordUserParams.company_name = v.company_name;
      } else {
        delete res.company_name;
      }

      const res2 = { ...res, ...supportRecordUserParams };
      params.support_records.push(res2);
      // 編集時
    } else if (res && Object.keys(res).length !== 0) {
      if (res.creation_date) {
        res.creation_date = selectDateValueToDatePaddingZero(v.creation_date);
      }
      const res2 = { ...res, ...supportRecordUserParams };
      params.support_records.push(res2);
    }
  });

  return params;
};

const normalizeCustomRecords = (
  postCustomRecord: GroupHomeFormValues["record"][0]["custom_record"],
  initialDataCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]
): PostCustomSupportParams["custom_record"] => {
  const resCustom = [] as PostCustomSupportParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDatakeys = Object.keys(diffData);

  // 入力タイプ「テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_first")) {
    const diffDataFirst = omitByNoChanges(
      postCustomRecord.input_type_first,
      initialDataCustomRecord.input_type_first
    );
    const record = Object.values(
      diffDataFirst
    ) as PostCustomSupportParams["custom_record"];
    resCustom.push(...record);
  }

  // 入力タイプ「チェックボックス」
  if (diffDatakeys.some((item) => item === "input_type_second")) {
    const diffDataSecond = omitByNoChanges(
      postCustomRecord.input_type_second,
      initialDataCustomRecord.input_type_second
    );
    const records = Object.values(
      diffDataSecond
    ) as PostCustomSupportParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => {
        return {
          custom_record_input_id: item[key].custom_record_input_id,
          custom_record_item_id: item[key].custom_record_item_id,
          choiced_item_id: item[key].choiced_item_id,
          checked: castCheckBoxToNumber(item[key].checked)
        };
      });
      resCustom.push(...postRecord);
    });
  }

  // 入力タイプ「複数テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_fourth")) {
    const diffDataFourth = omitByNoChanges(
      postCustomRecord.input_type_fourth,
      initialDataCustomRecord.input_type_fourth
    );
    const records = Object.values(
      diffDataFourth
    ) as PostCustomSupportParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => item[key]);
      resCustom.push(...postRecord);
    });
  }
  return resCustom;
};

const normalizeCustomRecordInputTypeThird = (
  postCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]["input_type_third"],
  initialDataCustomRecord: GroupHomeFormValues["record"][0]["custom_record"]["input_type_third"]
): PostCustomSupportParams["custom_record"] => {
  const resCustom = [] as PostCustomSupportParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDatakeys = Object.keys(diffData);

  Object.entries(postCustomRecord).forEach(([key, value]) => {
    const isDeleteItem = value.itemIdList.some(
      (item) => item.is_delete !== null
    );

    const isDiffCustomRecord = diffDatakeys.some((item) => item === key);

    if (value && (isDiffCustomRecord || isDeleteItem)) {
      // 新規追加またはchecked「OFF」変更（「is_delete=1」の場合）
      const newRecord = [] as PostCustomSupportParams["custom_record"];
      value.itemIdList.forEach((item) => {
        const beforeValue = value.beforeValues.find(
          (beforeValueItem) => beforeValueItem.choiced_staff_id === item.id
        );

        const record = {
          custom_record_input_id: beforeValue
            ? beforeValue.custom_record_input_id
            : null,
          custom_record_item_id: Number(key),
          choiced_staff_id: beforeValue
            ? beforeValue.choiced_staff_id
            : Number(item.id),
          choiced_staff_name_snapshot: item.label,
          checked: item.is_delete === 1 ? 0 : 1
        };
        newRecord.push(record);
      });

      // checked「OFF」変更処理
      const unCheckRecord = value.beforeValues
        .filter((beforeValueItem) => {
          return !value.itemIdList.find(
            (itemIdListItem) =>
              beforeValueItem.choiced_staff_id === itemIdListItem.id
          );
        })
        .map((unCheckBeforeValue) => {
          return {
            custom_record_input_id: unCheckBeforeValue.custom_record_input_id,
            custom_record_item_id: Number(key),
            choiced_staff_id: unCheckBeforeValue.choiced_staff_id,
            choiced_staff_name_snapshot:
              unCheckBeforeValue.choiced_staff_name_snapshot,
            checked: 0
          };
        });

      resCustom.push(...newRecord, ...unCheckRecord);
    }
  });

  return resCustom;
};

/**
 * postCustomSupportに渡すparamsの変換（GH）
 */
export const normalizeFormValuesToPostCustomSupportParamsGROUPHOME = (
  values: GroupHomeFormValues,
  initialValues: GroupHomeFormValues,
  targetDate: string
): PostCustomSupportParams => {
  const params = {} as PostCustomSupportParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_date === targetDate
  );
  const postData = values.record.find(
    (item) => item.target_date === targetDate
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDatakeys = Object.keys(res);

    if (diffDatakeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = 3;
    params.support_record_id = postData.support_record_id;
    params.status_type = Number(postData.status_type);
  }

  return params;
};

/**
 * postCustomSupportに渡すparamsの変換（施設入所）
 */
export const normalizeFormValuesToPostCustomSupportParamsSHISETSUNYUSHO = (
  values: SHISETSUNYUSHOFormValues,
  initialValues: SHISETSUNYUSHOFormValues,
  targetDate: string
): PostCustomSupportParams => {
  const params = {} as PostCustomSupportParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_date === targetDate
  );
  const postData = values.record.find(
    (item) => item.target_date === targetDate
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDatakeys = Object.keys(res);

    if (diffDatakeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = 3;
    params.support_record_id = postData.support_record_id;
    params.status_type = Number(postData.status_type);
  }

  return params;
};

/**
 * postCustomSupportに渡すparamsの変換（短期入所）
 */
export const normalizeFormValuesToPostCustomSupportParamsTANKINYUSHO = (
  values: TANKINYUSHOFormValues,
  initialValues: TANKINYUSHOFormValues,
  targetDate: string
): PostCustomSupportParams => {
  const params = {} as PostCustomSupportParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_date === targetDate
  );
  const postData = values.record.find(
    (item) => item.target_date === targetDate
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDatakeys = Object.keys(res);

    if (diffDatakeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = 3;
    params.support_record_id = postData.support_record_id;
  }

  return params;
};
