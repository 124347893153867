import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
// ui
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import IconAdd from "@material-ui/icons/Add";
// formik
import { FormikProps, FieldArray } from "formik";
import {
  practitionerExpensesInitialValue,
  ServiceDeliveryDetailValues,
  ExpensesValues
} from "@initialize/record/serviceDelivery/initialValues";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
// variables
import {
  FacilityType,
  SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT,
  SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT_VALUE
} from "@constants/variables";
import get from "lodash-es/get";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      justifyContent: "space-between"
    },
    sectionWrap: {
      padding: "24px 16px",
      backgroundColor: "#fff",
      "&:not(:last-child)": {
        borderBottom: "1px solid #e0e0e0"
      }
    },
    contentWrap: {
      border: "1px solid #bdbdbd",
      padding: "24px 16px",
      borderRadius: "4px",
      marginTop: 16
    },
    content: {
      borderBottom: "1px solid #e0e0e0",
      "&:not(:last-child)": {
        marginBottom: 16
      }
    },
    title: {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)"
    },
    iconCell: {
      textAlign: "right",
      verticalAlign: "top",
      display: "inline-flex",
      justifyContent: "flex-start",
      alignContent: "flex-end",
      width: 110,
      marginRight: 16,
      marginBottom: 16
    },
    addButton: {
      marginTop: 16,
      marginBottom: 8
    }
  });

type Props = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  practitionerNum: number;
  facilityType: FacilityType;
  handleBlurMoney: (event: React.FormEvent<HTMLInputElement>) => string | void;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  target: string;
  handleBlurExpenses: (
    target: string,
    i: number
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
  changeExpenses: (
    target: string,
    i: number,
    event?: React.FormEvent<HTMLInputElement>
  ) => void;
} & WithStyles<typeof styles>;

const ServiceDeliveryDetailExpensesCore = (props: Props): JSX.Element => {
  const { values } = props.formikProps;
  const { classes } = props;
  const content =
    props.target === "other_expenses"
      ? { title: "その他費用", head: "費用内訳" }
      : { title: "交通費", head: "交通手段" };
  const expensesKey = `serviceDeliveryRecordPractitioners${props.practitionerNum}.serviceDeliveryRecordPractitionerMoneyManagements.${props.target}`;
  const expenses: ExpensesValues = get(values, expensesKey);
  const isAddButton = expenses.filter((item) => !item.isDelete).length < 50;
  const bodyData = (i: number, onDeleteRecord: () => void): JSX.Element => {
    return (
      <div className={classes.content} key={`wrapper-${i}-${expensesKey}`}>
        {props.target === "other_expenses" ? (
          <>
            <FormikTextField
              isMobile
              readOnly={!props.isEdit}
              size="fullSize"
              name={`${expensesKey}[${i}].free_text`}
              label="費用内訳"
              style={{ marginBottom: 16 }}
            />
            <FormikTextField
              isMobile
              readOnly={!props.isEdit}
              size="mobileHalf"
              name={`${expensesKey}[${i}].amount_money`}
              endAdornmentLabel="円"
              onBlurHook={props.handleBlurExpenses(props.target, i)}
              label="金額"
              style={{ marginBottom: 16 }}
            />
          </>
        ) : (
          <>
            <div className={classes.flex} key={`flex-${i}-${expensesKey}`}>
              <FormikSelect
                isMobile
                readOnly={!props.isEdit}
                size="mobileHalf"
                name={`${expensesKey}[${i}].transportation`}
                label="交通手段"
                options={SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT}
                placeholder="選択してください"
                isSelectablePlaceholder
                negativeMarginLabel
                noValueText="-"
                style={{ marginBottom: 16 }}
              />
              <FormikTextField
                isMobile
                readOnly={!props.isEdit}
                size="mobileHalf"
                name={`${expensesKey}[${i}].amount_money`}
                endAdornmentLabel="円"
                onBlurHook={props.handleBlurExpenses(props.target, i)}
                label="金額"
                style={{ marginBottom: 16 }}
              />
            </div>
            {+get(values, `${expensesKey}[${i}].transportation`) ===
              SERVICE_CUSTOM_RECORD_TRAVELING_EXPENSES_SELECT_VALUE.other && (
              <FormikTextField
                isMobile
                readOnly={!props.isEdit}
                size="fullSize"
                name={`${expensesKey}[${i}].free_text`}
                label="その他詳細"
                style={{ marginBottom: 16 }}
              />
            )}
          </>
        )}
        {props.isEdit && (
          <KnowbeButton
            kind="iconText"
            key={`delete-${i}`}
            className={classes.iconCell}
            onClick={onDeleteRecord}
          >
            <DeleteIcon
              style={{
                width: 24,
                height: 28,
                color: "#0277bd",
                cursor: "pointer",
                marginRight: 7
              }}
            />
            <div style={{ color: "#0277bd" }}>削除する</div>
          </KnowbeButton>
        )}
      </div>
    );
  };

  // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
  const getBodyValues = (
    onDeleteExpenses: (i: number) => void
  ): JSX.Element[] => {
    return expenses
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((item) => {
        const onDeleteRecord = (): void => {
          onDeleteExpenses(item.baseIndex);
          props.changeExpenses(props.target, item.baseIndex);
        };
        return bodyData(item.baseIndex, onDeleteRecord);
      });
  };

  const onDeleteExpenses = (index: number): void => {
    props.formikProps.setFieldValue(`${expensesKey}[${index}].isDelete`, 1);
  };

  return (
    <div className={classes.sectionWrap}>
      <FieldArray name={expensesKey}>
        {(arrayHelpers): JSX.Element => {
          const onClickAddExpenses = (): void => {
            arrayHelpers.push(practitionerExpensesInitialValue);
          };
          return (
            <div>
              <div className={classes.title}>{content.title}</div>
              <div className={classes.contentWrap}>
                {expenses.length && getBodyValues(onDeleteExpenses)}
                {props.isEdit && (
                  <KnowbeButton
                    kind="iconText"
                    className={classes.addButton}
                    onClick={onClickAddExpenses}
                    disabled={!isAddButton}
                  >
                    <IconAdd />
                    {content.title}を追加する
                  </KnowbeButton>
                )}
              </div>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const ServiceDeliveryDetailExpenses = withStyles(styles)(
  ServiceDeliveryDetailExpensesCore
);
