import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export const deleteSupportProcedureDetail = async (
  supportProcedureFormsId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_procedure/detail/${supportProcedureFormsId}`;
  return request.delete(url);
};
