import React from "react";

// mui
import MuiSelect from "@components/molecules/MuiSelect";
import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

// api
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

// utils
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";

// constants
import { DEFAULT_UNIT_SELECT_BOX } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    unit: {
      marginTop: 16,
      "& :last-child ul": {
        borderBottom: "1px solid #e5e5e5"
      }
    }
  });

type OwnProps = {
  unitGroup: number;
  setUnitGroup: React.Dispatch<React.SetStateAction<number>>;
  optionalCustomInfo?: GetOptionalCustomInfoResponse["data"];
};

type Props = OwnProps & WithStyles<typeof styles>;

const FieldUnitCore = (props: Props): JSX.Element | null => {
  const { classes, unitGroup, setUnitGroup, optionalCustomInfo } = props;

  const units =
    optionalCustomInfo && !Array.isArray(optionalCustomInfo)
      ? optionalCustomInfo.facility_units.filter((v) => v.id)
      : [];
  const unitsOptions = [
    DEFAULT_UNIT_SELECT_BOX,
    ...generateSelectFieldItems(units, "unit_name", "id")
  ];

  const onChangeUnit = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    setUnitGroup(+e.target.value);
  };

  return (
    <div className={classes.unit}>
      <MuiSelect
        name="unit_select"
        label="ユニット/グループ選択"
        value={unitGroup}
        options={unitsOptions}
        onChange={onChangeUnit}
        style={{ width: 520 }}
      />
    </div>
  );
};

export const FieldUnit = withStyles(styles)(FieldUnitCore);
