import {
  InputValue,
  SelectValue,
  SelectDateValue,
  SelectMonthValue,
  FieldItem
} from "@interfaces/ui/form";
import { dateToSelectDateValue, dateToSelectMonthValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import { ConsultationRecordState } from "@stores/domain/mgr/CHIIKIIKO/consultation/types";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";

export type ConsultationValues = {
  id: number | null;
  createdAt: string;
  updatedAt: string;
  targetFlg: number;
  creationDate: SelectDateValue; // 計画(案)作成日
  author: SelectValue; // 作成者
  attainmentGoal: InputValue; // サービス等利用計画の到達目標
  longTermGoal: InputValue; // 長期目標
  shortTermGoal: InputValue; // 短期目標
  startYm: SelectMonthValue; // 協力（支援）の開始年月
  endYm: SelectMonthValue; // 協力（支援）の終了年月
  supportPlanConsultationDetails: {
    support_plan_consultation_details_id: number | null;
    support_goal: InputValue; // 本人の期待や不安
    collaborator: InputValue; // そのために協力する人
    contents_cooperation: InputValue; // 協力する内容
    considerations: InputValue; // 留意事項
    start_ym: SelectValue; // 協力（支援）の目安開始年月
    end_ym: SelectValue; // 協力（支援）の目安終了年月
    users_do: InputValue; // 利用者自身がすること
    is_delete: number;
  }[];
  staffComment: string; // 職員コメント
};

// 協力（支援）の内容カード初期値
export const initialSupportPlanConsultationDetails: ConsultationValues["supportPlanConsultationDetails"][0] = {
  support_plan_consultation_details_id: null,
  support_goal: "",
  collaborator: "",
  contents_cooperation: "",
  considerations: "",
  start_ym: "",
  end_ym: "",
  users_do: "",
  is_delete: 0
};

// 計画の詳細
const supportPlanConsultationDetailsValues = (
  detailsValues: ConsultationRecordState["supportPlanConsultationDetails"]
): ConsultationValues["supportPlanConsultationDetails"] => {
  return detailsValues.map((detail) => ({
    support_plan_consultation_details_id:
      detail.support_plan_consultation_details_id,
    support_goal: detail.support_goal,
    collaborator: detail.collaborator,
    contents_cooperation: detail.contents_cooperation,
    considerations: detail.considerations,
    start_ym: detail.start_ym,
    end_ym: detail.end_ym,
    users_do: detail.users_do,
    is_delete: 0
  }));
};

// 新規作成用初期値
export const initialValuesNew = (targetFlg: number): ConsultationValues => {
  const dt = new Date();
  const currentDate = dateToLocalisedString(dt, "YYYY-MM-DD");
  dt.setMonth(dt.getMonth() + 5);
  const endDate = dateToLocalisedString(dt, "YYYY-MM-DD");

  // 新規作成
  return {
    id: null,
    createdAt: "",
    updatedAt: "",
    targetFlg,
    creationDate: dateToSelectDateValue(currentDate),
    author: "",
    attainmentGoal: "",
    longTermGoal: "",
    shortTermGoal: "",
    startYm: dateToSelectMonthValue(currentDate),
    endYm: dateToSelectMonthValue(endDate),
    supportPlanConsultationDetails: [initialSupportPlanConsultationDetails],
    staffComment: ""
  };
};

// 編集用初期値
export const initialValuesEdit = (
  values: ConsultationRecordState,
  staffOptions: FieldItem[]
): ConsultationValues => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
  const creationDate = values.creationDate || currentDate;
  const authorId = getStaffWithSnap(values.author, staffOptions);

  return {
    id: values.id,
    createdAt: values.createdAt,
    updatedAt: values.updatedAt,
    targetFlg: values.targetFlg,
    creationDate: dateToSelectDateValue(creationDate),
    author: authorId || "",
    attainmentGoal: values.attainmentGoal,
    longTermGoal: values.longTermGoal,
    shortTermGoal: values.shortTermGoal,
    startYm: dateToSelectMonthValue(values.startYm),
    endYm: dateToSelectMonthValue(values.endYm),
    supportPlanConsultationDetails: supportPlanConsultationDetailsValues(
      values.supportPlanConsultationDetails
    ),
    staffComment: values.staffComment
  };
};

// 読み込み元に値があれば使用する
const createLoadedValue = (
  copy:
    | ConsultationRecordState["supportPlanConsultationDetails"][0]
    | null
    | undefined,
  value: ConsultationValues["supportPlanConsultationDetails"][0] | undefined,
  key: string
): string => {
  if (copy) {
    return copy[key] || "";
  }
  if (value) {
    return value[key] || "";
  }
  return "";
};

// 協力（支援）の内容読み込み
const loadDetails = (
  detailsValues: ConsultationValues["supportPlanConsultationDetails"],
  copyDetailsValues: ConsultationRecordState["supportPlanConsultationDetails"]
): ConsultationValues["supportPlanConsultationDetails"] => {
  const filteredDetailsValues = detailsValues.filter(
    (value) => !value.is_delete
  );
  // 最終的に生成される配列の要素数
  const loadedLength = Math.max(
    filteredDetailsValues.length,
    copyDetailsValues.length
  );

  // 値が入っている読み込み先の優先順位のみ抽出
  const copyValues = copyDetailsValues.map((copy) => {
    // id以外空値か
    const isEmptyValue = Object.values(copy)
      .slice(1)
      .every((item) => !item);
    return isEmptyValue ? null : copy;
  });

  const arr = Array.from(new Array(loadedLength)).map((_, i) => {
    const value = filteredDetailsValues[i];
    const copy = copyValues[i];
    return {
      support_plan_consultation_details_id: value
        ? value.support_plan_consultation_details_id
        : null,
      support_goal: createLoadedValue(copy, value, "support_goal"),
      collaborator: createLoadedValue(copy, value, "collaborator"),
      contents_cooperation: createLoadedValue(
        copy,
        value,
        "contents_cooperation"
      ),
      considerations: createLoadedValue(copy, value, "considerations"),
      start_ym: value ? value.start_ym : "",
      end_ym: value ? value.end_ym : "",
      users_do: createLoadedValue(copy, value, "users_do"),
      is_delete: 0
    };
  });
  return arr;
};

// 計画の詳細読み込み
export const loadValuesDetails = (
  values: ConsultationValues,
  copyValues: ConsultationRecordState
): ConsultationValues => {
  return {
    id: values.id,
    createdAt: values.createdAt,
    updatedAt: values.updatedAt,
    targetFlg: values.targetFlg,
    creationDate: values.creationDate,
    author: values.author,
    attainmentGoal: copyValues.attainmentGoal,
    longTermGoal: copyValues.longTermGoal,
    shortTermGoal: copyValues.shortTermGoal,
    startYm: values.startYm,
    endYm: values.endYm,
    supportPlanConsultationDetails: loadDetails(
      values.supportPlanConsultationDetails,
      copyValues.supportPlanConsultationDetails
    ),
    staffComment: values.staffComment
  };
};

// 計画詳細上書き確認
export const isDetailUpdate = (values: ConsultationValues): boolean => {
  let result = false;
  if (
    values.attainmentGoal !== "" ||
    values.shortTermGoal !== "" ||
    values.longTermGoal !== ""
  ) {
    result = true;
  }

  const filteredDetailsValues = values.supportPlanConsultationDetails.filter(
    (value) => !value.is_delete
  );

  // 優先順位が初期値と同じ又は値がないか(idを除き)
  const isPriorityUpdate = !(
    filteredDetailsValues.length === 0 ||
    (filteredDetailsValues.length === 1 &&
      isEqual(
        omit(filteredDetailsValues[0], [
          "support_plan_consultation_details_id"
        ]),

        {
          ...omit(initialSupportPlanConsultationDetails, [
            "support_plan_consultation_details_id"
          ])
        }
      ))
  );
  if (isPriorityUpdate) {
    result = true;
  }

  return result;
};
