import { SupportRecordUserState } from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { ShotMultipleSelectValue, CheckBoxValue } from "@interfaces/ui/form";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type RecordUserDetailValues = {
  record: {
    target_date: string;
    setting_type: number;
    support_record_id: number | null;
    pickup: string;
    custom_record: {
      input_type_first: {
        [custom_record_id: number]: {
          custom_record_input_id: number | null;
          custom_record_item_id: number;
          input_data: string;
        };
      };
      input_type_second: {
        [custom_record_id: number]: {
          [custom_record_item_choices_id: number]: {
            custom_record_input_id: number | null;
            custom_record_item_id: number;
            choiced_item_id: number;
            checked: CheckBoxValue;
          };
        };
      };
      input_type_third: {
        [custom_record_id: number]: {
          // postする際に差異を判別するため使用
          readonly beforeValues: {
            custom_record_input_id: number | null;
            custom_record_item_id: number;
            choiced_staff_id: number;
            choiced_staff_name_snapshot: string;
            checked: CheckBoxValue;
          }[];
          // formで実際に変更するidList
          itemIdList: ShotMultipleSelectValue;
        };
      };
      input_type_fourth: {
        [custom_record_id: number]: {
          [custom_record_item_choices_id: number]: {
            custom_record_input_id: number | null;
            custom_record_item_id: number;
            choiced_item_id: number;
            input_data: string;
            default_item: number;
            default_choice: number;
          };
        };
      };
    };
  }[];
};

// カスタムレコード
const createCustomRecordsValues = (
  customRecords: CustomRecordsState,
  supportRecord: SupportRecordUserState["support"][number] | undefined
): RecordUserDetailValues["record"][number]["custom_record"] => {
  const obj1 = {};
  const obj2 = {};
  const obj3 = {};
  const obj4 = {};
  if (customRecords.length > 0) {
    customRecords
      .filter((item) => item.setting_type === 3)
      .forEach((record) => {
        const supportRecordInput = supportRecord
          ? supportRecord.support_record_input.filter(
              (item) => item.custom_record_item_id === record.id
            )
          : null;
        switch (record.input_type) {
          // テキスト形式
          case 1:
            Object.assign(obj1, {
              [record.id]: {
                custom_record_input_id:
                  supportRecordInput && supportRecordInput.length > 0
                    ? supportRecordInput[0].id
                    : null,
                custom_record_item_id: record.id,
                input_data:
                  supportRecordInput && supportRecordInput.length > 0
                    ? supportRecordInput[0].input_data
                    : ""
              }
            });
            break;
          // チェックボックス形式
          case 2: {
            const checkBoxObj = {};
            if (record.choices) {
              record.choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;
                Object.assign(checkBoxObj, {
                  [choice.id]: {
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    checked: supportRecordInputChoiced
                      ? numberToBoolean(supportRecordInputChoiced.checked)
                      : false
                  }
                });
              });
            }

            Object.assign(obj2, { [record.id]: { ...checkBoxObj } });
            break;
          }
          // セレクトボックス形式
          case 3: {
            const beforeValues = supportRecordInput
              ? supportRecordInput.map((support) => {
                  return {
                    custom_record_input_id: support.id,
                    custom_record_item_id: record.id,
                    choiced_staff_id: support.choiced_staff_id,
                    choiced_staff_name_snapshot:
                      support.choiced_staff_name_snapshot,
                    checked: numberToBoolean(support.checked)
                  };
                })
              : [];
            const itemIdList = supportRecordInput
              ? supportRecordInput
                  .filter((item) => item.checked === 1)
                  .map((support) => {
                    return {
                      category_id: support.id,
                      id: support.choiced_staff_id,
                      label: support.choiced_staff_name_snapshot,
                      is_delete: null
                    };
                  })
              : [];
            Object.assign(obj3, { [record.id]: { beforeValues, itemIdList } });
            break;
          }
          // 複数テキスト形式
          case 4: {
            const multiTextObj = {};
            if (record.choices) {
              record.choices.forEach((choice) => {
                const supportRecordInputChoiced = supportRecordInput
                  ? supportRecordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;
                Object.assign(multiTextObj, {
                  [choice.id]: {
                    custom_record_input_id: supportRecordInputChoiced
                      ? supportRecordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    input_data: supportRecordInputChoiced
                      ? supportRecordInputChoiced.input_data
                      : "",
                    default_item: record.default_item,
                    default_choice: choice.default_choice
                  }
                });
              });
            }
            Object.assign(obj4, { [record.id]: { ...multiTextObj } });
            break;
          }
          default:
            break;
        }
      });
  }
  return {
    input_type_first: obj1,
    input_type_second: obj2,
    input_type_third: obj3,
    input_type_fourth: obj4
  };
};

const initialValues = (
  values: SupportRecordUserState["support"],
  customRecords: CustomRecordsState
): RecordUserDetailValues => {
  return {
    record: values.map((value) => {
      return {
        target_date: value.target_date,
        setting_type: 3,
        support_record_id: value.support_records_id,
        pickup: String(value.pickup),
        custom_record: createCustomRecordsValues(customRecords, value)
      };
    })
  };
};

export default initialValues;
