import React, { SetStateAction, Dispatch as reactDispatch } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { AppState } from "@stores/type";
import { connect } from "react-redux";
// ui
import { CustomRecordsDialogChoicesContent } from "@components/organisms/record/settingWithCategory/dialolg/CustomRecordsDialogChoicesContent";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
// formik
import { FormikProps } from "formik";
import { CustomRecordsValuesForService } from "@initialize/record/customRecordWithCategory/initialValues";
// variables
import {
  CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    modalItemContent: {
      padding: "0 32px"
    },
    radioLabel: {
      fontSize: "16px"
    }
  });

type OwnProps = {
  formValues: CustomRecordsValuesForService;
  formikProps: FormikProps<CustomRecordsValuesForService>;
  setSubmitButtonDisabled: reactDispatch<SetStateAction<boolean>>;
};

type StateProps = {
  customRecords: CustomRecordsWithCategoryState;
};
type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const CustomRecordsDialogContentCore = ({
  classes,
  formValues,
  formikProps,
  setSubmitButtonDisabled,
  customRecords
}: Props): JSX.Element => {
  // 項目名が空、もしくは入力形式がチェックボックスで選択肢が1つもない場合に保存ボタンを非活性にする
  const IsSubmitButtonDisabled = (
    name: string,
    input_type: string,
    choicesArray: CustomRecordsValuesForService["custom_record_item_choices"]
  ): boolean => {
    return (
      name === "" ||
      (input_type === "2" &&
        choicesArray.filter(
          (choice) =>
            choice.name !== "" && !choice.delete && choice.hidden === 0
        ).length === 0)
    );
  };

  // 項目名変更時の処理
  const handleChangeNameField = (
    form: FormikProps<CustomRecordsValuesForService>
  ) => (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    // 保存ボタン状態管理
    const { value } = e.target;
    const {
      values: { input_type, custom_record_item_choices }
    } = form;
    setSubmitButtonDisabled(
      IsSubmitButtonDisabled(value, input_type, custom_record_item_choices)
    );
  };

  // 入力タイプ変更時文字切り捨て用関数
  const subtractString = (
    customRecordItemChoices: CustomRecordsValuesForService["custom_record_item_choices"],
    form: FormikProps<CustomRecordsValuesForService>
  ): void => {
    const invalidChoices = customRecordItemChoices.filter((choice) => {
      return choice.name.length > CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT;
    });
    if (invalidChoices.length > 0) {
      invalidChoices.forEach((choice) => {
        form.setFieldValue(
          `custom_record_item_choices[${choice.key}].name`,
          choice.name.substring(0, CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT)
        );
      });
    }
  };

  // 入力形式変更時の処理
  const handleChangeRadioButton = (
    form: FormikProps<CustomRecordsValuesForService>
  ) => (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    const { value } = e.target;
    form.setFieldValue("input_type", value);

    // 保存ボタン状態管理
    const {
      values: { name, custom_record_item_choices }
    } = form;

    subtractString(custom_record_item_choices, form);

    setSubmitButtonDisabled(
      IsSubmitButtonDisabled(name, value, custom_record_item_choices)
    );
  };

  const categoryData = customRecords.find(
    (record) => record.id === formValues.custom_records_category_id
  );
  const customRecord =
    formValues.id && categoryData
      ? categoryData.custom_record_items.find(
          (item) => item.id === formValues.id
        )
      : undefined;
  const allowEditName = customRecord ? customRecord.allow_edit_name : 1;

  const checkboxValue =
    +formValues.input_type ===
    +SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text
      ? "8"
      : "2";

  return (
    <>
      <div className={classes.modalItemContent}>
        <FormikTextField
          name="name"
          label="項目名"
          size="largeMedium"
          placeholder="入力してください"
          maxLength={20}
          disabled={!allowEditName}
          onChangeHook={handleChangeNameField(formikProps)}
          style={{ marginBottom: "36px" }}
        />
        <FormikRadioButtons
          name="input_type"
          label="入力形式"
          options={[
            { label: "文字入力", value: "1" },
            { label: "選択式（チェックボックス）", value: checkboxValue }
          ]}
          onChangeHook={handleChangeRadioButton(formikProps)}
          disabled={!!formValues.id}
          style={{ marginTop: "4px", marginBottom: 0 }}
          labelTextClass={classes.radioLabel}
        />
      </div>
      <CustomRecordsDialogChoicesContent
        formValues={formValues}
        formikProps={formikProps}
        setSubmitButtonDisabled={setSubmitButtonDisabled}
        IsSubmitButtonDisabled={IsSubmitButtonDisabled}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecordsWithCategory.serviceDelivery
});

export const CustomRecordsDialogContent = withStyles(styles)(
  connect(mapStateToProps)(CustomRecordsDialogContentCore)
);
