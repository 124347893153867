import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";

export const SET_USERS = "PAGES/RECORD/USERS_DAILY/SET_USERS";
export const SET_SEARCH_CONDITIONS =
  "PAGES/RECORD/USERS_DAILY/SET_SEARCH_CONDITIONS";
export const SET_CURRENT_PAGE = "PAGES/RECORD/USERS_DAILY/SET_CURRENT_PAGE";
export const SET_IS_RECORD = "PAGES/RECORD/USERS_DAILY/SET_IS_RECORD";
export const UNSET_SEARCH_CONDITIONS =
  "PAGES/RECORD/USERS_DAILY/UNSET_SEARCH_CONDITIONS";

export type RecordUsersDailyState = {
  users: UsersInFacilityState["users"];
  searchConditionsIds: number[];
  currentPage: number;
  isRecord: number;
};
