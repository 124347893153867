import * as React from "react";

import { dateToLocalisedString, convertWareki } from "@utils/date";

import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/domain/invoice/type";
import { isEmpty } from "lodash-es";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";

const RECORD_MAX_ROW = 29;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    diagonalLine: {
      position: "relative",
      "&::before": {
        content: "''",
        position: "absolute",
        backgroundColor: "black",
        top: "0",
        left: "0",
        width: "100%",
        // 下のボーダーが太いので、斜めになる様に調整
        height: "calc(20px - 2px)",
        clipPath:
          "polygon(0 calc(100% - 1px), calc(100% - 1px) 0, 100% 1px, 1px 100%)"
      }
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    marginTopNegative6px: {
      marginTop: "-6px"
    },
    container: {
      marginBottom: 12
    },
    borderBottomNone: {
      borderBottom: "none !important"
    },
    borderTop1px: {
      borderTop: "1px solid !important"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.thin": {
        border: "1px solid"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 20,
        wordBreak: "break-all",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.w20": {
          width: 20
        },
        "&.ssmall": {
          height: 25
        },
        "&.w30": {
          width: 30
        },
        "&.w32": {
          width: 32
        },
        "&.w33point7": {
          width: 33.7
        },
        "&.w38": {
          width: 38
        },
        "&.w40": {
          width: 40
        },
        "&.w41": {
          width: 41
        },
        "&.w42": {
          width: 42
        },
        "&.w46": {
          width: 46
        },
        "&.w50": {
          width: 50
        },
        "&.w64": {
          width: 64
        },
        "&.w66": {
          width: 66
        },
        "&.msize": {
          width: 80
        },
        "&.w94": {
          width: 94
        },
        "&.lsize": {
          width: 120
        },
        "&.nowrap": {
          whiteSpace: "nowrap"
        },
        "&.vertical": {
          height: 50,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.borderDashedBottom": {
          borderBottom: "1px dashed"
        },
        // フォントサイズ調整用クラス
        // フォントサイズのscaleを行うと、親クラスのサイズに合わせた幅調整が必要
        "&.fontSize6": {
          "& > div": {
            whiteSpace: "nowrap"
          },
          "&.w32": {
            maxWidth: 32,
            "& > div": {
              width: 54,
              transform: "scale(0.6) translate(-19px, 0px)"
            }
          }
        },
        "&.fontSize7": {
          "& > div": {
            whiteSpace: "nowrap"
          },
          "&.w40": {
            maxWidth: 40,
            "& > div": {
              width: 58,
              transform: "scale(0.7) translate(-12px, 0px)"
            }
          },
          "&.w50": {
            maxWidth: 50,
            "& > div": {
              width: 74,
              transform: "scale(0.7) translate(-18px, 0px)"
            }
          }
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDashed": {
          borderBottom: "1px dashed"
        }
      }
    },
    dateTable: {
      borderCollapse: "collapse",
      width: "100%",
      fontSize: 10,
      textAlign: "center",
      "& td": {
        borderRight: "1px solid",
        "&.borderBoldRight": {
          borderRight: "2px solid"
        },
        "&.w74": {
          width: 74
        }
      },
      // テーブル全体のボーダールール設定
      "& tr:first-child > td": {
        borderTop: "2px solid"
      },
      "& tr > td": {
        borderBottom: "2px solid"
      },
      "& tr > td:first-child": {
        borderLeft: "2px solid"
      },
      "& tr > td:last-child": {
        borderRight: "2px solid"
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 自立訓練(生活訓練)の実績記録票
 */
const InoutRecordTable = (props: Props): JSX.Element => {
  const { key, invoiceData } = props;
  const { date, facility, users } = invoiceData;
  const { classes } = props;

  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;
        return (
          <section
            key={`InoutRecordTable-${keyIndex}`}
            className={classes.page}
          >
            <div className={`${classes.flexContainer} center relative`}>
              <div className={classes.date}>
                <span>{`${convertWareki(date.year, date.month).era}${
                  convertWareki(date.year, date.month).year
                }年${date.month}月分`}</span>
              </div>
              <span>自立訓練(生活訓練)サービス提供実績記録票</span>
            </div>
            <div className={classes.flexContainer}>
              <table className={`${classes.table} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="label">
                      受給者証
                      <br />
                      <span>番号</span>
                    </td>
                    <td className="msize">{user.recipientNo}</td>
                    <td className="label">支給決定障害者氏名</td>
                    <td className="label lsize">{user.recipientName}</td>
                    <td className="label">事業所番号</td>
                    <td className="label msize">{facility.officeNo}</td>
                  </tr>
                  <tr>
                    <td className="label">契約支給量</td>
                    <td colSpan={2} className="label">
                      {user.payment}
                    </td>
                    <td className="label">
                      事業者及び
                      <br />
                      その事業所
                    </td>
                    <td colSpan={2} className="label">
                      {facility.officeName}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.flexContainer}>
              <Records
                classes={classes}
                user={user}
                facilityType={facility.kindService}
              />
            </div>
            <div
              className={`${classes.flexContainer} ${classes.marginTopNegative6px}`}
            >
              <table
                className={`${classes.table} ${classes.borderTop1px} fullWidth`}
              >
                <tbody>
                  <tr>
                    <td rowSpan={2} className="label borderBold w94">
                      合計
                    </td>
                    <td rowSpan={2} className="label borderDashed nowrap w38">
                      通所型
                    </td>
                    <td rowSpan={2} className="prise w30">
                      {`${user.cntCome}回`}
                    </td>
                    <td rowSpan={2} className="label borderDashed w41">
                      訪問型
                    </td>
                    <td className="label borderDashed borderDashedBottom w50 nowrap">
                      1時間未満
                    </td>
                    <td className="prise w46">{`${user.cntVisitLess}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w42"
                    >{`${user.cntPickup}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7"
                    >{`${user.cntShortStay}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7"
                    >{`${user.cntFood}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7"
                    >{`${user.cntSupportMedical}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7"
                    >{`${user.cntTrialUsage}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7"
                    >{`${user.cntEmergencyAcceptanceAddition}回`}</td>
                    <td
                      rowSpan={2}
                      className="prise w33point7 borderBold"
                    >{`${user.cntIntensiveSupport}回`}</td>
                    <td
                      rowSpan={2}
                      className={`prise ${classes.diagonalLine}`}
                    />
                  </tr>
                  <tr className="borderBold">
                    <td className="label borderDashed w50 nowrap">1時間以上</td>
                    <td className="prise w46">{`${user.cntVisitMore}回`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={classes.flexContainer}>
              <table className={`${classes.dateTable} fullWidth`}>
                <tbody>
                  <tr>
                    <td className="borderBoldRight w74">初期加算</td>
                    <td>利用開始日</td>
                    <td>
                      {isEmpty(user.dateBeginService)
                        ? ""
                        : dateToLocalisedString(
                            new Date(user.dateBeginService),
                            "YYYY年MM月DD日"
                          )}
                    </td>
                    <td className="borderBoldRight">30日目</td>
                    <td>
                      {isEmpty(user.dateAfter30Days)
                        ? ""
                        : dateToLocalisedString(
                            new Date(user.dateAfter30Days),
                            "YYYY年MM月DD日"
                          )}
                    </td>
                    <td>当月算定日数</td>
                    <td className="borderBoldRight">{`${user.cntDaysInitApply}日`}</td>
                  </tr>
                  <tr>
                    <td className="borderBoldRight w74">集中的支援加算</td>
                    <td>支援開始日</td>
                    <td>
                      {user.dateIntensiveSupportStart
                        ? dateToLocalisedString(
                            new Date(user.dateIntensiveSupportStart),
                            "YYYY年MM月DD日"
                          )
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={`${classes.flexContainer} end`}>
              <table className={`${classes.table} thin`}>
                <tbody>
                  <tr>
                    <td className="label">1</td>
                    <td className="label">枚中</td>
                    <td className="label">1</td>
                    <td className="label">枚</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        );
      })}
    </React.Fragment>
  );
};

type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const { user, classes } = props;
  const emptyRow =
    user.records.length >= RECORD_MAX_ROW
      ? 0
      : RECORD_MAX_ROW - user.records.length;
  // tslint:disable-next-line:prefer-array-literal
  const emptyList = new Array<undefined>(emptyRow).fill(undefined);
  const records = [...user.records, ...emptyList];

  return (
    <table className={`${classes.table} ${classes.borderBottomNone} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={3} className="label vertical w20">
            <span>日付</span>
          </td>
          <td rowSpan={3} className="label vertical borderBold w20">
            <span>曜日</span>
          </td>
          <td colSpan={12} className="label borderBold">
            サービス提供実績
          </td>
          <td rowSpan={3} className="label w46 borderBold">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={3} className="label msize">
            備考
          </td>
        </tr>
        <tr>
          <td rowSpan={2} className="label w50 fontSize7">
            <div>
              サービス提供
              <br />
              の状況
            </div>
          </td>
          <td rowSpan={2} className="label w64">
            <div>提供形態</div>
          </td>
          <td rowSpan={2} className="label w66">
            <div>開始時間</div>
          </td>
          <td rowSpan={2} className="label w66">
            <div>終了時間</div>
          </td>
          <td colSpan={2} className="label w40 fontSize7">
            <div>送迎加算</div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6">
            <div>
              短期滞在
              <br />
              加算
            </div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6">
            <div>
              食事提供
              <br />
              加算
            </div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6">
            <div>
              医療連携
              <br />
              体制加算
            </div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6">
            <div>
              体験利用
              <br />
              支援加算
            </div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6">
            <div>
              緊急時受
              <br />
              入加算
            </div>
          </td>
          <td rowSpan={2} className="label w32 fontSize6 borderBold">
            <div>
              集中的支
              <br />
              援加算
            </div>
          </td>
        </tr>
        <tr>
          <td className="label borderDashed">往</td>
          <td className="label">復</td>
        </tr>
        {records.map((record, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";

          let memoText = "";
          if (record) {
            memoText += record.memo;
            memoText +=
              record.memo && record.additions.isPeerSupport ? " " : "";
            memoText += record.additions.isPeerSupport ? "ピアサポート" : "";
          }

          return (
            <tr key={`Records-${keyIndex}`} className={rowClass}>
              <td className="label ssmall">{record ? record.day : ""}</td>
              <td className="label borderBold ssmall">
                {record ? record.dayOfWeek : ""}
              </td>
              <td className="label ssmall">
                {record ? record.serviceStatus : ""}
              </td>
              <td className="label ssmall">
                {record ? record.additions.provisionForm : ""}
              </td>
              <td className="label ssmall">{record ? record.startTime : ""}</td>
              <td className="label ssmall">{record ? record.endTime : ""}</td>
              <td className="label borderDashed w20">
                {record && (record.additions.isInbound ? "1" : "")}
              </td>
              <td className="label w20">
                {record && (record.additions.isOutbound ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record ? record.additions.shortStay : ""}
              </td>
              <td className="label ssmall">
                {record && (record.additions.isSuppliedMeal ? "1" : "")}
              </td>
              <td className="label ssmall">
                {record ? record.additions.supportMedical : ""}
              </td>
              <td className="label ssmall">
                {record ? record.additions.trialUsage : ""}
              </td>
              <td className="label ssmall">
                {record && record.additions.isEmergencyAcceptanceAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label borderBold ssmall">
                {record && record.additions.isIntensiveSupport ? "1" : ""}
              </td>
              <td className="borderBold ssmall" />
              <td className="ssmall">{memoText}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default withStyles(styles)(InoutRecordTable);
