import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps, getIn } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import SubsidizedFields from "@components/organisms/mgr/common/Users/items/SubsidizedFields";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { UpperLimitTable } from "@components/organisms/mgr/common/Users/items/UpperLimitTable";

import { FacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_TYPE_LIST,
  INCOME_KIND_LIST,
  UPLIMIT_CONT_ROLLED_BY_LIST,
  RESULT_OF_MANAGEMENT_LIST,
  SUPPLY_FOOD_SERVICE
} from "@constants/variables";
import { UsersValues } from "@initialize/mgr/SHISETSUNYUSHO/users/initialValues";
import { FieldItem, SelectDateValue } from "@interfaces/ui/form";
import { FormikSelectMonth } from "@components/molecules/FormikSelectMonth";
import * as validate from "@validator/rules";
import { SEVERE_DISABILITY_SUPPORT_DISABLE_VALUES } from "@constants/mgr/SHISETSUNYUSHO/variables";

const styles = (): StyleRules =>
  createStyles({
    groupDate: {
      marginLeft: 16
    },
    fieldWrapper: {
      position: "relative"
    },
    fieldWrapperFoods: {
      position: "relative",
      marginTop: 20
    },
    incomeKindType: {
      position: "absolute",
      top: 22,
      left: 140
    },
    checkOption: {
      color: "#666666",
      fontSize: "1.1rem",
      marginBottom: 28
    },
    check: {
      marginRight: 8,
      fontSize: 15
    },
    option: {
      marginLeft: 5,
      fontSize: 11
    },
    default: {
      marginLeft: 5
    },
    def: {
      marginBottom: -13
    },
    date: {
      marginBottom: 12
    },
    supplementaryBenefitYen: {
      marginTop: -25,
      marginLeft: 160
    },
    section: {
      marginBottom: 32
    },
    innerItem: {
      marginLeft: -20
    },
    fieldWrapperForm: {
      marginBottom: 16,
      "& > div": {
        marginBottom: 0
      }
    },
    oralForm: {
      marginTop: "13px",
      marginLeft: "18px"
    },
    oralTooltip: {
      position: "absolute",
      top: "50%",
      marginTop: "-10px",
      left: "90px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  isFetchDone?: boolean;
  facility: FacilityState;
  unitsOptions: FieldItem[];
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  groupOperationSupport: number;
};
type Props = OwnProps & WithStyles<typeof styles>;

type State = {
  shouldFirstSetup: boolean;
  showIncomeKindType: boolean;
  showOfficeInfoTable: boolean;
  showUpperLimitYen: boolean;
  tempBeforeEnabledOralMaintenanceIIFlag: boolean;
};

class ServiceUseFieldsCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      shouldFirstSetup: true,
      showIncomeKindType: true,
      showOfficeInfoTable: true,
      showUpperLimitYen: false,
      tempBeforeEnabledOralMaintenanceIIFlag: false
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Props,
    prevState: State
  ): State | null {
    if (!prevState.shouldFirstSetup || !nextProps.isFetchDone) {
      return null;
    }

    const { serviceUse } = nextProps.formikProps.values;
    return {
      shouldFirstSetup: false,
      showIncomeKindType: serviceUse.incomeKind === "1",
      showOfficeInfoTable: serviceUse.upperLimitControlledBy === "1",
      showUpperLimitYen: serviceUse.resultOfManagement === "3",
      /**
       * 経口維持加算(I)がfalseになったら経口維持加算(II)をfalse & disabledとする仕様がある。
       * 経口維持加算(I)がtrueに戻ったら（かつエラーが無い）経口維持加算(II)が元のstatus & enableとなる。
       * その際に、元のstatusを保持するための state
       * */
      tempBeforeEnabledOralMaintenanceIIFlag: false
    };
  }

  /**
   * 負担上限額が1の時、所得区分を表示
   */
  private onChangeIncomeKindHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showIncomeKindType: value === "1" });
  };

  /**
   * 上限管理事業所あり：ONOFF切り替え時に値をリセット
   */
  private onChangeUpperLimitFacilityFlag = (): void => {
    if (
      getIn(this.props.formikProps.values, "serviceUse.upperLimitFacilityFlag")
    ) {
      this.props.setFormikFieldValue(
        "serviceUse.upperLimitFacilityFlag",
        false
      );
      this.props.setFormikFieldValue("serviceUse.upperLimitControlledBy", "1");
      this.props.setFormikFieldValue("serviceUse.resultOfManagement", "1");
      this.props.setFormikFieldValue("serviceUse.upperLimitYen", "");
      this.setState({
        showUpperLimitYen: false,
        showOfficeInfoTable: true
      });
      this.resetUpperLimitFacilityYenForm();
    } else {
      this.props.setFormikFieldValue("serviceUse.upperLimitFacilityFlag", true);
    }
  };

  private resetUpperLimitFacilityYenForm = (): void => {
    for (let i = 0; i < 4; i += 1) {
      const dataNumber = i !== 0 ? String(i + 1) : "";
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityNumber${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitFacilityName${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitTotalYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.upperLimitUserLoadYen${dataNumber}`,
        ""
      );
      this.props.setFormikFieldValue(
        `serviceUse.uplimitUserLoadAfterAdjustmentYen${dataNumber}`,
        ""
      );
    }
  };

  /**
   * 管理事業所が1の時、総費用額と利用者負担額を表示
   */
  private onChangeUpperLimitControlledBy = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showOfficeInfoTable: value === "1" });
    this.resetUpperLimitFacilityYenForm();
  };

  /**
   * 管理結果が3の時、自事業所調整上限額を表示及び管理結果後利用者負担額の値のリセット
   */
  private onChangeResultOfManagement = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    this.setState({ showUpperLimitYen: value === "3" });
    if (value !== "3")
      ["", "2", "3", "4"].forEach((item) => {
        this.props.setFormikFieldValue(
          `serviceUse.uplimitUserLoadAfterAdjustmentYen${item}`,
          ""
        );
      });
  };

  /**
   * 経口維持加算（Ⅰ）の算定月にエラーがあるか判定する
   */
  private isOralMaintenanceIDateError = (
    startDateValue: SelectDateValue,
    endDateValue: SelectDateValue
  ): boolean => {
    let isError = false;
    const startDateRequired = validate.requiredDate(startDateValue);
    const endDateRequired = validate.requiredDate(endDateValue);
    const future = validate.future(endDateValue, startDateValue);
    isError =
      !!(startDateRequired.year || startDateRequired.month) ||
      !!(endDateRequired.year || endDateRequired.month) ||
      !!future.year;
    return isError;
  };

  /**
   * 経口維持加算（Ⅰ）が変更をして、条件によっては経口維持加算（Ⅱ）をONにする
   */
  private onChangeOralMaintenanceIFlg = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    const { formikProps } = this.props;
    if (checked) {
      if (this.state.tempBeforeEnabledOralMaintenanceIIFlag) {
        // エラーがない場合のみONにする
        const serviceUseValues = formikProps.values.serviceUse;
        const startDateValue = {
          ...serviceUseValues.oralMaintenanceIStartDate,
          day: "1"
        };
        const endDateValue = {
          ...serviceUseValues.oralMaintenanceIEndDate,
          day: "1"
        };
        const isError = this.isOralMaintenanceIDateError(
          startDateValue,
          endDateValue
        );
        if (!isError) {
          this.props.setFormikFieldValue(
            "serviceUse.oralMaintenanceIIFlg",
            true
          );
          this.setState({ tempBeforeEnabledOralMaintenanceIIFlag: false });
        }
      }
    } else if (this.props.formikProps.values.serviceUse.oralMaintenanceIIFlg) {
      this.props.setFormikFieldValue("serviceUse.oralMaintenanceIIFlg", false);
      this.setState({ tempBeforeEnabledOralMaintenanceIIFlag: true });
    }
    this.props.setFormikFieldValue("serviceUse.oralMaintenanceIFlg", checked);
  };

  /**
   * 経口維持加算（Ⅰ）年月の操作
   * エラーがある場合は経口維持加算（ⅠI）フラグをOFFにする
   * エラーが解消されたら経口維持加算（ⅠI）フラグをONにする
   */
  private onChangeOralMaintenanceIDate = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value, name } = e.target;
    const serviceUseValues = this.props.formikProps.values.serviceUse;
    const startDateValue = {
      year:
        name === "serviceUse.oralMaintenanceIStartDate.year"
          ? value
          : serviceUseValues.oralMaintenanceIStartDate.year,
      month:
        name === "serviceUse.oralMaintenanceIStartDate.month"
          ? value
          : serviceUseValues.oralMaintenanceIStartDate.month,
      day: "1"
    };
    const endDateValue = {
      year:
        name === "serviceUse.oralMaintenanceIEndDate.year"
          ? value
          : serviceUseValues.oralMaintenanceIEndDate.year,
      month:
        name === "serviceUse.oralMaintenanceIEndDate.month"
          ? value
          : serviceUseValues.oralMaintenanceIEndDate.month,
      day: "1"
    };
    const isError = this.isOralMaintenanceIDateError(
      startDateValue,
      endDateValue
    );

    // 経口維持加算（ⅠI）フラグの操作
    if (isError && serviceUseValues.oralMaintenanceIIFlg) {
      this.props.setFormikFieldValue("serviceUse.oralMaintenanceIIFlg", false);
      this.setState({ tempBeforeEnabledOralMaintenanceIIFlag: true });
    }
    if (
      this.state.tempBeforeEnabledOralMaintenanceIIFlag &&
      !isError &&
      !serviceUseValues.oralMaintenanceIIFlg
    ) {
      this.props.setFormikFieldValue("serviceUse.oralMaintenanceIIFlg", true);
      this.setState({ tempBeforeEnabledOralMaintenanceIIFlag: false });
    }
  };

  /**
   * 「重度障害者支援の個別支援対象者である」の操作
   * 「重度障害者支援の個別支援対象者である」が非活性の場合にSwitchをOFFにする
   */
  private onChangeDisabilityClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    if (SEVERE_DISABILITY_SUPPORT_DISABLE_VALUES.includes(value)) {
      this.props.setFormikFieldValue(
        "serviceUse.severeDisabilitySupport",
        false
      );
    }
  };

  public render(): JSX.Element {
    const START_ADD_YEAR_TO = 1;
    const END_ADD_YEAR_TO = 5;
    const { errors } = this.props.formikProps;
    // 経口維持加算（Ⅱ）非活性フラグ
    const isDisabledOralMaintenanceIIFlg =
      !this.props.formikProps.values.serviceUse.oralMaintenanceIFlg ||
      !!(errors.serviceUse && errors.serviceUse.oralMaintenanceIStartDate) ||
      !!(errors.serviceUse && errors.serviceUse.oralMaintenanceIEndDate);
    return (
      <FormPaper>
        <div className={this.props.classes.section}>
          <SectionTitle label="サービス利用詳細" />
        </div>
        {this.props.facility.operatingUnitFlag &&
          this.props.groupOperationSupport === 1 && (
            <FormikSelect
              name="serviceUse.facilityUnitId"
              label="所属グループ名"
              options={this.props.unitsOptions}
              isSelectablePlaceholder
              placeholder="選択してください"
              size="halfSuperLong"
            />
          )}
        <Typography className={this.props.classes.date}>
          サービス提供期間
        </Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceStartDate"
            label="サービス提供開始日"
            style={{ marginBottom: 12 }}
            addYearTo={START_ADD_YEAR_TO}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.inServiceEndDate"
            label="サービス提供終了日"
            addYearTo={END_ADD_YEAR_TO}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <Typography className={this.props.classes.date}>受給者証</Typography>
        <div className={this.props.classes.groupDate}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payStartDate"
            label="支給決定開始日 "
            style={{ marginBottom: 12 }}
            addYearTo={START_ADD_YEAR_TO}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.payEndDate"
            label="支給決定終了日"
            addYearTo={END_ADD_YEAR_TO}
            setFormikFieldValue={this.props.setFormikFieldValue}
          />
        </div>
        <FormGroup>
          <FormikRadioButtons
            name="serviceUse.disabilityClass"
            label="障害区分"
            options={DISABILITY_CLASS_LIST}
            onChangeHook={this.onChangeDisabilityClass}
          />
          <FormikSwitch
            name="serviceUse.severeDisabilitySupport"
            label="重度障害者支援の個別支援対象者である"
            disabled={SEVERE_DISABILITY_SUPPORT_DISABLE_VALUES.includes(
              this.props.formikProps.values.serviceUse.disabilityClass
            )}
          >
            <FormikSelectDateNotSelectedDefault
              name="serviceUse.severeDisabilitySupportStartData"
              label="加算算定開始日"
              addYearTo={START_ADD_YEAR_TO}
              setFormikFieldValue={this.props.setFormikFieldValue}
            />
          </FormikSwitch>
        </FormGroup>
        <div className={this.props.classes.fieldWrapper}>
          <FormikRadioButtons
            name="serviceUse.incomeKind"
            label="負担上限額"
            options={INCOME_KIND_LIST}
            onChangeHook={this.onChangeIncomeKindHook}
          />
          <div className={this.props.classes.incomeKindType}>
            {this.state.showIncomeKindType && (
              <FormikSelect
                name="serviceUse.incomeKindType"
                label="所得区分"
                options={INCOME_KIND_TYPE_LIST}
              />
            )}
          </div>
        </div>
        <SubsidizedFields formikProps={this.props.formikProps} />
        <FormikSwitch
          name="serviceUse.supplementaryBenefitFlg"
          label="補足給付"
        >
          <div className={this.props.classes.innerItem}>
            <FormikTextField
              name="serviceUse.supplementaryBenefitYen"
              label="補足給付金額"
              endAdornmentLabel="円"
              maxLength={6}
            />
          </div>
        </FormikSwitch>
        <FormikSwitch
          name="serviceUse.upperLimitFacilityFlag"
          label="上限管理事業所あり"
          onChange={this.onChangeUpperLimitFacilityFlag}
        >
          <div className={this.props.classes.innerItem}>
            <div className={this.props.classes.fieldWrapperForm}>
              <FormikSelect
                name="serviceUse.upperLimitControlledBy"
                label="管理事業所"
                options={UPLIMIT_CONT_ROLLED_BY_LIST}
                onChangeHook={this.onChangeUpperLimitControlledBy}
              />
            </div>
            {!this.state.showOfficeInfoTable && (
              <FormGroup row className={this.props.classes.fieldWrapperForm}>
                <FormikTextField
                  name="serviceUse.upperLimitFacilityNumber"
                  label="事業所番号"
                  maxLength={10}
                  placeholder="0000000000"
                />
                <FormikTextField
                  name="serviceUse.upperLimitFacilityName"
                  label="事業所名"
                />
              </FormGroup>
            )}
            <FormikRadioButtons
              name="serviceUse.resultOfManagement"
              label="管理結果"
              options={RESULT_OF_MANAGEMENT_LIST}
              onChangeHook={this.onChangeResultOfManagement}
              style={{ marginBottom: 0 }}
            />
            {this.state.showUpperLimitYen && (
              <FormikTextField
                name="serviceUse.upperLimitYen"
                label="自事業所　管理結果後利用者負担額"
                endAdornmentLabel="円"
                style={{
                  margin: this.state.showOfficeInfoTable
                    ? "16px 0px 16px 52px"
                    : "16px 0px 32px 52px"
                }}
                maxLength={11}
                InputLabelProps={{
                  style: {
                    width: "120%",
                    transform: "scale(0.75) translate(0, 1.5px)"
                  }
                }}
              />
            )}
            {this.state.showOfficeInfoTable && (
              <UpperLimitTable formikProps={this.props.formikProps} />
            )}
          </div>
        </FormikSwitch>
        <FormikSwitch
          name="serviceUse.createSupportPlanFlg"
          label="個別支援計画未作成"
        >
          <div className={this.props.classes.innerItem}>
            <FormikSelectDateNotSelectedDefault
              name="serviceUse.notCreateSupportPlanStartDate"
              label="未作成期間開始日"
              style={{ marginBottom: 0 }}
              addYearTo={START_ADD_YEAR_TO}
              setFormikFieldValue={this.props.setFormikFieldValue}
            />
          </div>
        </FormikSwitch>
        <div className={this.props.classes.fieldWrapperFoods}>
          <Typography className={this.props.classes.def}>
            食事提供サービス
            <span className={this.props.classes.default}>デフォルト</span>
            <span className={this.props.classes.option}>※</span>
          </Typography>
          <FormGroup row>
            <FormikSelect
              name="serviceUse.defFood"
              label=""
              options={SUPPLY_FOOD_SERVICE}
              disabled={!this.props.facility.availableFood}
              className={this.props.classes.groupDate}
              style={{ marginBottom: 16 }}
            />
            <FormikCheckbox
              name="serviceUse.foodBreakfastFlg"
              label="朝"
              style={{ marginBottom: 0, marginTop: 14 }}
              disabled={
                !this.props.facility.availableFood ||
                this.props.formikProps.values.serviceUse.defFood !== "1"
              }
            />
            <FormikCheckbox
              name="serviceUse.foodLunchFlg"
              label="昼"
              style={{ marginBottom: 0, marginTop: 14 }}
              disabled={
                !this.props.facility.availableFood ||
                this.props.formikProps.values.serviceUse.defFood !== "1"
              }
            />
            <FormikCheckbox
              name="serviceUse.foodSupperFlg"
              label="夜"
              style={{ marginBottom: 0, marginTop: 14 }}
              disabled={
                !this.props.facility.availableFood ||
                this.props.formikProps.values.serviceUse.defFood !== "1"
              }
            />
          </FormGroup>
        </div>
        <Typography className={this.props.classes.checkOption}>
          <span className={this.props.classes.check}>※</span>
          事業所設定の「実施オプションサービス」にチェックを入れると設定ができます。
        </Typography>
        <FormikCheckbox
          name="serviceUse.regionalLifeTransition2"
          label="地域生活移行個別支援特別加算（Ⅱ）"
          disabled={!this.props.facility.regionalLifeTransition}
        />
        <div>
          <div style={{ position: "relative" }}>
            <Typography>経口維持加算</Typography>
            <p className={this.props.classes.oralTooltip}>
              <HelpToolTip
                title={<HelpTipMessages name="shisetsunyushoOralMaintenance" />}
              />
            </p>
          </div>
          <FormGroup className={this.props.classes.oralForm}>
            <FormikSwitch
              name="serviceUse.oralMaintenanceIFlg"
              label="経口維持加算（Ⅰ）"
              onChange={this.onChangeOralMaintenanceIFlg}
            >
              <div className={this.props.classes.innerItem}>
                <FormikSelectMonth
                  name="serviceUse.oralMaintenanceIStartDate"
                  label="算定開始月"
                  addYearTo={5}
                  overrideYearFrom={1989}
                  onChangeHook={this.onChangeOralMaintenanceIDate}
                />
                <FormikSelectMonth
                  name="serviceUse.oralMaintenanceIEndDate"
                  label="算定終了月"
                  addYearTo={5}
                  style={{ marginBottom: 0 }}
                  overrideYearFrom={1989}
                  onChangeHook={this.onChangeOralMaintenanceIDate}
                />
              </div>
            </FormikSwitch>
            <FormikSwitch
              name="serviceUse.oralMaintenanceIIFlg"
              label="経口維持加算（Ⅱ）"
              disabled={isDisabledOralMaintenanceIIFlg}
            >
              <div className={this.props.classes.innerItem}>
                <FormikSelectMonth
                  name="serviceUse.oralMaintenanceIIStartDate"
                  label="算定開始月"
                  addYearTo={5}
                  overrideYearFrom={1989}
                />
                <FormikSelectMonth
                  name="serviceUse.oralMaintenanceIIEndDate"
                  label="算定終了月"
                  addYearTo={5}
                  style={{ marginBottom: 0 }}
                  overrideYearFrom={1989}
                />
              </div>
            </FormikSwitch>
          </FormGroup>
        </div>
      </FormPaper>
    );
  }
}

export const ServiceUseFields = withStyles(styles)(ServiceUseFieldsCore);
