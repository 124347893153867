import * as actions from "./actions";
import { Dispatch } from "redux";
import {
  normalizeValueFromAPI,
  normalizeFormValuesToPostConsultation
} from "./normalizer";
import { consultationApi } from "@api/requests/consultation";
import * as loadingActions from "@stores/loading/actions";
import { ConsultationValues } from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { KEIKAKUSODANGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";

const fetchConsultation = (dispatch: Dispatch) => async (
  uifId: string,
  consultationId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchConsultationStarted());
  await consultationApi
    .getConsultation<GetConsultationResponse>(uifId, consultationId)
    .then((res) => {
      const normalizedValue = normalizeValueFromAPI(res.data.data);
      dispatch(actions.fetchConsultationSuccess(normalizedValue));
    })
    .catch((e) => {
      dispatch(actions.fetchConsultationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 作成済み計画読み込み
const copyConsultation = (dispatch: Dispatch) => async (
  uifId: string,
  consultationId: string,
  copyType: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.copyConsultationStarted());
  await consultationApi
    .getConsultation<GetConsultationResponse>(uifId, consultationId)
    .then((res) => {
      const normalizedValue = normalizeValueFromAPI(res.data.data);
      dispatch(actions.copyConsultationSuccess(normalizedValue, copyType));
    })
    .catch((e) => {
      dispatch(actions.copyConsultationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postConsultation = (dispatch: Dispatch) => async (
  uifId: number,
  values: ConsultationValues,
  initialValues?: ConsultationValues,
  type?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postConsultationStarted());
  const normalizedParams = normalizeFormValuesToPostConsultation(
    values,
    initialValues,
    type
  );
  await consultationApi
    .postConsultation(String(uifId), normalizedParams)
    .then(() => {
      dispatch(actions.postConsultationSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.postConsultationFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const deleteConsultation = (dispatch: Dispatch) => async (
  uifId: string,
  id: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteConsultationStarted());
  await consultationApi
    .deleteConsultation(uifId, id)
    .then(() => {
      dispatch(actions.deleteConsultationSuccess());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.deleteConsultationFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchConsultation: ReturnType<typeof fetchConsultation>;
  copyConsultation: ReturnType<typeof copyConsultation>;
  postConsultation: ReturnType<typeof postConsultation>;
  deleteConsultation: ReturnType<typeof deleteConsultation>;
};

export const KEIKAKUSODANConsultationDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchConsultation: fetchConsultation(dispatch),
  copyConsultation: copyConsultation(dispatch),
  postConsultation: postConsultation(dispatch),
  deleteConsultation: deleteConsultation(dispatch)
});
