import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import FormikTextField from "@components/molecules/FormikTextField";
import { FormikProps, getIn } from "formik";
import { RESULT_OF_MANAGEMENT_LIST } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 920,
      marginTop: 16,
      marginBottom: 32
    },
    officeInfoTableCell: {
      padding: "12px 16px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        padding: "12px 16px"
      }
    },
    officeInfoTextInput: {
      width: 120
    },
    officeInfoNameInput: {
      width: 220
    },
    moneyInput: {
      width: 140
    }
  });

type Props = { formikProps: FormikProps<unknown> } & WithStyles<typeof styles>;

export const UpperLimitTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes, formikProps } = props;
    const size = "auto";
    const filedStyle = {
      marginRight: 0,
      marginBottom: 0
    };

    const resultManagement = getIn(
      formikProps.values,
      "serviceUse.resultOfManagement"
    );
    const headData = [
      {
        label: "事業所番号",
        className: classes.officeInfoTableCell
      },
      {
        label: "事業所名",
        className: classes.officeInfoTableCell
      },
      {
        label: "総費用額",
        className: classes.officeInfoTableCell
      },
      {
        label: "利用者負担額",
        className: classes.officeInfoTableCell
      },
      {
        label: "管理結果後利用者負担額",
        className: classes.officeInfoTableCell
      }
    ];

    const bodyData = Array.from(new Array(4)).map((item, idx) => {
      const dataNumber = idx ? String(idx + 1) : "";
      return [
        {
          label: (
            <FormikTextField
              name={`serviceUse.upperLimitFacilityNumber${dataNumber}`}
              maxLength={10}
              placeholder="0000000000"
              className={classes.officeInfoTextInput}
              size={size}
              style={filedStyle}
            />
          ),
          className: classes.officeInfoTableCell
        },
        {
          label: (
            <FormikTextField
              name={`serviceUse.upperLimitFacilityName${dataNumber}`}
              className={classes.officeInfoNameInput}
              size={size}
              style={filedStyle}
              maxLength={2500}
            />
          ),
          className: classes.officeInfoTableCell
        },
        {
          label: (
            <FormikTextField
              name={`serviceUse.upperLimitTotalYen${dataNumber}`}
              endAdornmentLabel="円"
              multiline
              className={classes.moneyInput}
              size={size}
              style={filedStyle}
            />
          ),
          className: classes.officeInfoTableCell
        },
        {
          label: (
            <FormikTextField
              name={`serviceUse.upperLimitUserLoadYen${dataNumber}`}
              endAdornmentLabel="円"
              maxLength={11}
              className={classes.moneyInput}
              size={size}
              style={filedStyle}
            />
          ),
          className: classes.officeInfoTableCell
        },
        {
          label: (
            <FormikTextField
              name={`serviceUse.uplimitUserLoadAfterAdjustmentYen${dataNumber}`}
              endAdornmentLabel="円"
              maxLength={11}
              className={classes.moneyInput}
              size={size}
              style={filedStyle}
              disabled={resultManagement !== RESULT_OF_MANAGEMENT_LIST[2].value}
            />
          ),
          className: classes.officeInfoTableCell
        }
      ];
    });
    return (
      <KnowbeTable className={classes.table} caption="他事業所情報">
        <KnowbeTableHead
          uniqueKey="他事業所情報"
          height={40}
          items={headData}
          backgroundColor="secondary"
        />
        <KnowbeTableBody uniqueKey="他事業所情報" itemsContainer={bodyData} />
      </KnowbeTable>
    );
  }
);
