import * as React from "react";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate201910";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import UsagePerformanceReportDailyCell from "@components/v201910/organisms/mgr/TANKINYUSHO/report/cells/UsagePerformanceReportDailyCell";
import {
  ReportState,
  UsagePerformanceType,
  UsagePerformanceTANKINYUSHOType
} from "@stores/v201910/domain/mgr/TANKINYUSHO/report/types";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import { StatusType } from "@constants/mgr/TANKINYUSHO/variables";
import UsagePerformanceTableDailyHeader from "@components/v201910/organisms/mgr/TANKINYUSHO/report/UsagePerformanceTableDailyHeader";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row: {
      height: 56,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    disableCell: {
      display: "none"
    },
    sticky: {
      zIndex: 10,
      position: "sticky"
    }
  });

type StateProps = {
  report: ReportState;
};

type OwnProps = {
  isEditing: boolean;
  isSorting: boolean;
  headerHeight: number;
  checkedIds: string[];
  onChecked: (checkedId: string, isChecked: boolean) => void;
  openModal: (
    usagePerformance: UsagePerformanceType,
    usagePerformanceTANKINYUSHO: UsagePerformanceTANKINYUSHOType
  ) => void;
  allCheck: (checkedValue: boolean) => void;
  isAllCheck: boolean;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

/**
 * ソート処理  サービス提供状況[提供] > サービス提供状況[-]
 * @param idList
 * @param usagePerformance
 */
const sortTable = (
  idList: string[],
  usagePerformance: ReportState["reportDaily"]["usagePerformance"]["after"]
): string[] => {
  return idList.sort((beforeId: string, afterId: string) => {
    // サービス提供状況[提供]は前へ
    if (
      `${usagePerformance[afterId].statusType}` === StatusType.IMPLEMENTATION
    ) {
      return 1;
    }
    // どちらもサービス提供状況[-]は何もしない
    if (
      `${usagePerformance[beforeId].statusType}` === StatusType.NONE &&
      `${usagePerformance[afterId].statusType}` === StatusType.NONE
    ) {
      return 0;
    }
    // 上記以外は後ろへ
    return -1;
  });
};

const UsagePerformanceDailyTable = (props: Props): JSX.Element => {
  const usagePerformance = props.isEditing
    ? props.report.reportDaily.usagePerformance.after
    : props.report.reportDaily.usagePerformance.before;
  const usagePerformanceTANKINYUSHO = props.isEditing
    ? props.report.reportDaily.usagePerformanceTANKINYUSHO.after
    : props.report.reportDaily.usagePerformanceTANKINYUSHO.before;
  const idList: string[] = Object.keys(usagePerformance);
  const sortIdList: string[] = props.isSorting
    ? sortTable(idList, usagePerformance)
    : idList;
  const TableList = sortIdList.map((keyValue: string, index) => {
    return (
      <TableRow key={`table-row-${keyValue}`} className={props.classes.row}>
        <UsagePerformanceReportDailyCell
          usagePerformance={usagePerformance[keyValue]}
          usagePerformanceTANKINYUSHO={usagePerformanceTANKINYUSHO[keyValue]}
          keyValue={keyValue}
          idx={index}
          isEditing={props.isEditing}
          onChecked={props.onChecked}
          openModal={props.openModal}
          checkedIds={props.checkedIds}
        />
      </TableRow>
    );
  });

  const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

  return (
    <>
      <div className={props.classes.sticky} style={{ top: `${topHeight}px` }}>
        <Table key="monthly-report-table">
          <UsagePerformanceTableDailyHeader
            isEditing={props.isEditing}
            allCheck={props.allCheck}
            isAllCheck={props.isAllCheck}
          />
        </Table>
      </div>
      <Table key="monthly-report-table">
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者が登録されていません。
          <br />
          利用者情報画面で登録後、ご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    report: state.v201910.TANKINYUSHO.report
  };
};

export default connect(mapStateToProps)(
  withStyles(styles)(UsagePerformanceDailyTable)
);
