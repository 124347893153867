import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";

const styles = (): StyleRules =>
  createStyles({
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteSubmitButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  openDeleteConfirm: boolean;
  onClickCancel: () => void;
  onClickDelete: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

// TODO: Cseg / Group / IABもこちらの共通ダイアログを使用するようにする
const DeleteAssessmentSettingDialogCore = (props: Props): JSX.Element => {
  return (
    <MessageDialog
      isOpen={props.openDeleteConfirm}
      title="アセスメントシートを削除します"
      message={
        <span>
          データが完全に削除され、復元できません。
          <br />
          よろしいですか？
        </span>
      }
      closeButton={
        <Button
          color="secondary"
          className={props.classes.deleteCancelButton}
          onClick={props.onClickCancel}
        >
          キャンセル
        </Button>
      }
      actionButton={
        <Button
          className={props.classes.deleteSubmitButton}
          onClick={props.onClickDelete}
        >
          削除する
        </Button>
      }
    />
  );
};

export const DeleteAssessmentSettingDialog = withStyles(styles)(
  DeleteAssessmentSettingDialogCore
);
