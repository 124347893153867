import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      width: 600,
      maxWidth: 600
    },
    title: {
      padding: "24px 32px 16px",
      "& > h6": {
        lineHeight: "24px"
      }
    },
    content: {
      padding: "0 32px 16px",
      color: "rgba(0, 0, 0, 0.6)"
    },
    actions: {
      margin: "0 32px 16px"
    },
    button: {
      margin: 0,
      width: 120
    }
  });

type Props = { open: boolean } & WithStyles<typeof styles>;

const ExpiredTokenModalCore = ({ open, classes }: Props): JSX.Element => {
  const onClick = (): void => {
    window.location.reload();
  };
  return (
    <Dialog classes={{ paper: classes.paper }} open={open}>
      <DialogTitle className={classes.title}>
        画面を再読み込みしてください
      </DialogTitle>
      <DialogContent className={classes.content}>
        別のアカウントに切り替わっている可能性があるため、
        <br />
        画面を再読み込みしてください。
      </DialogContent>
      <DialogActions className={classes.actions}>
        <KnowbeButton className={classes.button} onClick={onClick} kind="text">
          再読み込み
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const ExpiredTokenModal = React.memo(
  withStyles(styles)(ExpiredTokenModalCore),
  (prev, next) => prev.open === next.open
);
