import { reducerWithInitialState } from "typescript-fsa-reducers";

import * as action from "@stores/v201910/domain/invoice/action";
import {
  InvoiceData,
  InvoiceDataResult,
  InvoiceState
} from "@stores/v201910/domain/invoice/type";

const downloadJissekiJson = (
  state: InvoiceState,
  { result }: { result: InvoiceDataResult }
): InvoiceState => {
  return {
    ...state,
    invoiceData: {
      data: result.data
    }
  };
};

const initialState = {
  invoiceData: {
    data: [] as InvoiceData[]
  }
};

export default reducerWithInitialState(initialState).case(
  action.downloadJissekiJson.done,
  downloadJissekiJson
);
