/**
 *  時間フォーマッター（HH:MM）
 */
export const formatTime = (time: number | string): string => {
  if (!time) return "";

  let timeStr = typeof time === "number" ? time.toString() : time;
  const divider = ":";
  if (timeStr.includes(divider)) {
    timeStr = timeStr.replace(divider, "");
  }
  if (timeStr.length < 3) {
    return timeStr;
  }
  if (timeStr.length > 4) timeStr = timeStr.substr(0, 4);
  if (timeStr.length === 3) timeStr = `0${timeStr}`;
  timeStr = timeStr.slice(0, 2) + divider + timeStr.slice(2);
  return timeStr;
};
