import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Divider, Paper, Typography } from "@material-ui/core";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: "32px 32px 8px"
    },
    titleWithButtons: {
      display: "flex",
      justifyContent: "space-between"
    },
    buttons: {
      "& > button": {
        width: "120px",
        top: "-8px",
        marginLeft: "8px"
      }
    },
    divider: {
      margin: 0,
      backgroundColor: "rgba(0, 0, 0, 0.54)"
    }
  });

type OwnProps = {
  title: string;
  children: React.ReactNode;
  button?: JSX.Element;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SimplePaperCore = (props: Props): JSX.Element => {
  const { classes, title, button } = props;
  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.titleWithButtons}>
        <div className={classes.title}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        </div>
        {button && <div className={classes.buttons}>{button}</div>}
      </div>
      <Divider variant="middle" className={classes.divider} />
      {props.children}
    </Paper>
  );
};

export const SimplePaper = withStyles(styles)(SimplePaperCore);
