import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FormikProps } from "formik";
import { CustomRecordsTableBodyValues } from "@initialize/record/customRecord/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    changeOrderButton: {
      marginLeft: spacing.unit
    }
  });

type OwnProps = {
  settingType: number;
  sortingSettingType: number | null;
  setSortingSettingType: reactDispatch<SetStateAction<number | null>>;
  openAddCustomRecordModal: (e: React.MouseEvent<HTMLInputElement>) => void;
  formikProps: FormikProps<CustomRecordsTableBodyValues>;
  formValues: CustomRecordsTableBodyValues;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SettingFormButtonsCore = ({
  classes,
  settingType,
  sortingSettingType,
  setSortingSettingType,
  openAddCustomRecordModal,
  formikProps,
  formValues,
  stopHistory
}: Props): JSX.Element => {
  // 並べ替えモードに移行
  const onClickSortButton = (e: React.MouseEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    setSortingSettingType(+value);
  };

  const onClickCancelButton = (): void => {
    formikProps.setValues(formValues);
    setSortingSettingType(null);
    stopHistory(false);
  };

  return sortingSettingType !== settingType ? (
    <>
      <KnowbeButton value={settingType} onClick={openAddCustomRecordModal}>
        項目の追加
      </KnowbeButton>
      <KnowbeButton
        className={classes.changeOrderButton}
        value={settingType}
        onClick={onClickSortButton}
      >
        並べ替え
      </KnowbeButton>
    </>
  ) : (
    <>
      <KnowbeButton kind="outline" onClick={onClickCancelButton}>
        キャンセル
      </KnowbeButton>
      <FormikSubmitButton
        className={classes.changeOrderButton}
        buttonName="確定する"
        formikProps={formikProps}
      />
    </>
  );
};

export const SettingFormButtons = withStyles(styles)(SettingFormButtonsCore);
