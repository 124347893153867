import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 対象ユーザーの介護計画内にある計画予定表を削除する。
 * @param uifId ユーザーID
 * @param carePlanScheduleId 計画予定表ID
 */
export const deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId = async (
  uifId: string,
  carePlanScheduleId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/care_plan_schedule/${carePlanScheduleId}`;
  return request.delete(url);
};
