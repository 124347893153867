import React from "react";

import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { theme } from "@styles/theme";
import Chip from "@material-ui/core/Chip";
import ErrorIcon from "@material-ui/icons/Error";
import AddToPhotosOutlined from "@material-ui/icons/AddToPhotosOutlined";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { dateToLocalisedString } from "@/utils/date";
import { SupportPlanState } from "@stores/domain/supportPlan/types";
import { UserState } from "@stores/domain/user/type";
import { ErrorsState } from "@stores/domain/errors/types";
import { FacilityType } from "@constants/variables";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginBottom: 48,
      "&:last-child": {
        marginBottom: 0
      }
    },
    planTitle: {
      fontSize: 16,
      fontWeight: "normal",
      backgroundColor: "#f5f5f5",
      height: 34,
      padding: "5px 8px",
      margin: "0 0 16px"
    },
    planWrapper: {
      padding: "0 8px"
    },
    plan: {
      marginBottom: 32,
      "&:last-child": {
        marginBottom: 0
      }
    },
    planPeriod: {
      display: "flex",
      alignItems: "center",
      padding: 8,
      borderTop: "solid 1px #b5b5b5",
      backgroundColor: "rgba(245,245,245, 0.38)",
      color: "rgba(0,0,0,0.87)",
      "& span": {
        "&:nth-child(2)": {
          marginLeft: theme.spacing.unit * 2
        },
        "&:nth-child(3)": {
          marginLeft: theme.spacing.unit * 3
        }
      }
    },
    alertPeriod: {
      color: "#f44336",
      fontSize: 12,
      position: "relative",
      paddingLeft: 20
    },
    alertIconUpper: {
      width: 15,
      height: 15,
      color: "#ff5656",
      position: "absolute",
      top: 2,
      left: 0
    },
    planItem: {
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 8px",
      borderTop: "solid 1px #d9d9d9",
      borderBottom: "solid 1px #d9d9d9"
    },
    planUser: {
      color: "#37474f",
      "& :first-child": {
        marginBottom: 8
      }
    },
    chip: {
      marginLeft: 12,
      width: 71,
      backgroundColor: "#455a64",
      color: "#fff"
    },
    unapproved: {
      opacity: 0.12
    },
    copyButton: {
      marginTop: 8,
      textAlign: "right"
    },
    photosIcon: {
      marginRight: 4
    }
  });

type Props = OwnProps & WithStyles<typeof styles>;

interface OwnProps {
  planTitle?: string;
  targetPlans: SupportPlanState["supportPlan"];
  user: UserState;
  errors: ErrorsState["plan"]["data"];
  handleClickPlanDoc: (planId: number) => () => void;
  handleClickCopy: (planId: number) => () => void;
}

const PlanListCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const isVisibleCopy = [
    FacilityType.JIRITSUKUNRENSEIKATSU,
    FacilityType.SHISETSUNYUSHO
  ].includes(props.user.facility_type);
  return (
    <div className={classes.wrapper}>
      {props.planTitle && (
        <h2 className={classes.planTitle}>{props.planTitle}</h2>
      )}
      <div className={classes.planWrapper}>
        {props.targetPlans &&
          props.targetPlans.map((plan) => {
            const { author, authorizer } = plan;
            // 作成者名
            const authorValue = getSnapOrRealName(author, "-");
            // 承認者名
            const authorizerValue = getSnapOrRealName(authorizer, "-");
            // 支援開始日
            const supportBeginDate = plan.support_begin_date
              ? dateToLocalisedString(plan.support_begin_date, "YYYY年M月D日")
              : "";
            // 支援終了日
            const supportEndDate = plan.support_end_date
              ? dateToLocalisedString(plan.support_end_date, "YYYY年M月D日")
              : "";
            return (
              <div className={classes.plan} key={plan.id}>
                <div className={`${classes.planPeriod}`}>
                  <span>支援期間</span>
                  <span>{`${supportBeginDate} 〜 ${supportEndDate}`}</span>
                  {props.errors &&
                    props.errors.length > 0 &&
                    props.errors[0].errors.map((error) => {
                      if (!plan.archive && error.relation.value === plan.id) {
                        return (
                          <span
                            className={classes.alertPeriod}
                            key={error.relation.key}
                          >
                            <ErrorIcon className={classes.alertIconUpper} />
                            {error.content}
                          </span>
                        );
                      }
                      return null;
                    })}
                </div>
                <div className={classes.planItem}>
                  <div className={classes.planUser}>
                    <div>作成者 : {authorValue}</div>
                    <div>承認者 : {authorizerValue}</div>
                  </div>
                  <div>
                    <KnowbeButton
                      kind="outline"
                      minWidth={158}
                      onClick={props.handleClickPlanDoc(plan.id)}
                    >
                      個別支援計画書
                    </KnowbeButton>
                    <Chip
                      label={plan.status_type ? "承認済" : "未承認"}
                      className={
                        plan.status_type
                          ? `${classes.chip}`
                          : `${classes.chip} ${classes.unapproved}`
                      }
                    />
                  </div>
                </div>
                {isVisibleCopy && (
                  <div className={classes.copyButton}>
                    <KnowbeButton
                      kind="iconText"
                      onClick={props.handleClickCopy(plan.id)}
                    >
                      <AddToPhotosOutlined className={classes.photosIcon} />
                      この計画書をコピー
                    </KnowbeButton>
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const PlanList = withStyles(styles)(PlanListCore);
