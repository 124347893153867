import { StaffValues } from "./initialValues";
import validator, { validateSwitcher } from "@validator";
import ValidationErrors from "@interfaces/ui/validationErrors";
import { LICENSE_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";

type StaffFields = {
  staffs: {
    roleName: string;
    workName: string;
    delete: boolean;
  }[];
};

export const validation = (
  values: StaffValues
): ValidationErrors<StaffFields> => {
  return {
    staffs: values.staffs.map((staff) => {
      return {
        roleName: validateSwitcher(
          !staff.delete &&
            (!!staff.staffItemId ||
              !!staff.staffName ||
              staff.staffLicense !== LICENSE_LIST.SELECT.value ||
              !!staff.staffLicenseName),
          validator(staff.roleName, "required")
        ),
        staffName: validateSwitcher(
          !staff.delete &&
            (!!staff.roleName ||
              staff.staffLicense !== LICENSE_LIST.SELECT.value ||
              !!staff.staffLicenseName),

          validator(staff.staffName, "required")
        ),
        staffLicense: validateSwitcher(
          !staff.delete &&
            staff.staffLicense === LICENSE_LIST.SELECT.value &&
            (!!staff.staffName || !!staff.roleName || !!staff.staffLicenseName),
          validator(staff.staffLicense, {
            type: "selectRequired",
            value: LICENSE_LIST.SELECT.value
          })
        )
      };
    })
  };
};
