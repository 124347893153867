import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";
import { TARGET_PATH } from "@constants/mgr/KODOENGO/variables";

// 利用実績
import { ReportDaily } from "@components/pages/mgr/KODOENGO/report/Daily";
import { ReportMonthly } from "@components/pages/mgr/KODOENGO/report/Monthly";
import { ReportDaily202104 } from "@components/v202104/pages/mgr/KODOENGO/report/Daily";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/KODOENGO/report/Monthly";

// 利用者情報
import { UsersList } from "@components/pages/mgr/KODOENGO/Users/UsersList";
import { CreateUser } from "@components/pages/mgr/KODOENGO/Users/CreateUser";
import { EditUser } from "@components/pages/mgr/KODOENGO/Users/EditUser";
import { EditUserV202104 } from "@components/v202104/pages/mgr/KODOENGO/Users/EditUser";

// 事業所情報
import { Facility } from "@components/pages/mgr/KODOENGO/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/KODOENGO/Facility";

// 支援手順書兼記録用紙
import { SupportProcedureList } from "@components/pages/mgr/KODOENGO/record/SupportProcedureList";
import { SupportProcedureDetail } from "@components/pages/mgr/KODOENGO/record/SupportProcedureDetail";
import { SupportProcedureRecordForm } from "@components/pages/mgr/KODOENGO/record/SupportProcedureRecordForm";
import { SupportProcedurePrint } from "@components/pages/record/print/SupportProcedurePrint";

// アセスメントシート
import { AssessmentList } from "@components/pages/record/assessment/AssessmentList";
import { Assessment } from "@components/pages/record/assessment/Assessment";
import { AssessmentRecordPrint } from "@components/pages/record/print/AssessmentPrint";

// 職員情報
import { Staffs } from "@components/pages/mgr/KODOENGO/Staffs";

// 記録の項目設定
import { RecordSettingWithCategory } from "@components/pages/record/setting/RecordSettingWithCategory";

// 記録
import { SupportPlanUsersSummary } from "@components/pages/record/supportPlan/SupportPlanUsersSummary";

// 支援計画シート
import { SupportPlanSheetsList } from "@components/pages/record/supportPlanSheets/SupportPlanSheetsList";
import { SupportPlanSheetsEdit } from "@components/pages/record/supportPlanSheets/SupportPlanSheetsEdit";
import { SupportPlanSheetsCreate } from "@components/pages/record/supportPlanSheets/SupportPlanSheetsCreate";
import { SupportPlanSheetsPrint } from "@components/pages/record/supportPlanSheets/SupportPlanSheetsPrint";

// 行動援護計画
import { CarePlanList } from "@components/pages/record/carePlan/CarePlanList";
import { CarePlanEdit } from "@components/pages/record/carePlan/CarePlanEdit";
import { CarePlanCreate } from "@components/pages/record/carePlan/CarePlanCreate";
import { CarePlanPrint } from "@components/pages/record/carePlan/CarePlanPrint";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/assessment/setting/AssessmentSetting";

// 記録
import { ServiceDeliveryDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryDaily";
import { ServiceDeliveryMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryMonthly";
import { ServiceDeliveryNewDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewDaily";
import { ServiceDeliveryNewMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewMonthly";
import { ServiceDeliveryEditDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import { ServiceDeliveryEditMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditMonthly";
import { ServiceDeliveryPrint } from "@components/pages/record/print/ServiceDeliveryPrint";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 行動援護でログインした時だけ有効になるルーティング
 */
export const KODOENGORoutes = ({ user }: Props): JSX.Element => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    <AdminRoute
      path={URL.REPORT_MONTHLY_202104}
      component={ReportMonthly202104}
    />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUserV202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    {/* 記録 */}
    <AdminRoute
      exact
      path={URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY}
      component={SupportPlanUsersSummary}
    />
    {/* アセスメントシート */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />
    {/* 職員情報 */}
    <AdminRoute key="Staffs" path="/staffs" exact component={Staffs} />
    {/* 記録の項目設定 */}
    <AdminRoute
      key="RecordSetting"
      path={URL.RECORD_SETTING}
      exact
      component={RecordSettingWithCategory}
    />
    {/* 記録 */}
    <AdminRoute
      exact
      path={URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY}
      component={SupportPlanUsersSummary}
    />
    {/* 支援計画シート */}
    <AdminRoute
      exact
      path="/record/support_plan/:id/support_plan_sheets"
      component={SupportPlanSheetsList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/support_plan_sheets/new"
      component={SupportPlanSheetsCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/support_plan_sheets/:supportPlanSheetId/copy"
      component={SupportPlanSheetsCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/support_plan_sheets/:supportPlanSheetId"
      component={SupportPlanSheetsEdit}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/support_plan_sheets/:supportPlanSheetId/print"
      component={SupportPlanSheetsPrint}
    />
    {/* 行動援護計画 */}
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan"
      component={CarePlanList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/new"
      component={CarePlanCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId/copy"
      component={CarePlanCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId"
      component={CarePlanEdit}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId/print"
      component={CarePlanPrint}
    />
    {/* アセスメントの設定 */}
    <AdminRoute
      exact
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      component={AssessmentSetting}
    />

    {/* 支援手順書兼記録用紙 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/support_procedure/list/:date`}
      component={SupportProcedureList}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/support_procedure/${TARGET_PATH.new}`}
      component={SupportProcedureDetail}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/support_procedure/${TARGET_PATH.copy}`}
      component={SupportProcedureDetail}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/support_procedure/${TARGET_PATH.edit}`}
      component={SupportProcedureDetail}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SUPPORT_PLAN}/:uifId/support_procedure/${TARGET_PATH.setDate}`}
      component={SupportProcedureDetail}
    />

    <AdminRoute
      exact
      path="/record/service_delivery/daily/:uifId/support_procedure"
      component={SupportProcedureRecordForm}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/:uifId/support_procedure"
      component={SupportProcedureRecordForm}
    />

    <AdminRoute
      exact
      path="/record/support_procedure/print"
      component={SupportProcedurePrint}
    />
    {/* サービス提供記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/list/:date?"
      component={ServiceDeliveryDaily}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/list/:uifId/:date"
      component={ServiceDeliveryMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/new"
      component={ServiceDeliveryNewDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/:uifId/service_delivery/new"
      component={ServiceDeliveryNewMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:supportProcedureFormsId/:uifId"
      component={ServiceDeliveryEditDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:supportProcedureFormsId/:uifId/monthly"
      component={ServiceDeliveryEditMonthly}
    />
    {/* 印刷・サービス提供記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/print"
      component={ServiceDeliveryPrint}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/print"
      component={ServiceDeliveryPrint}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);
