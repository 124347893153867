import { PatternListType } from "@constants/variables";

export type TargetUser = {
  uif_id: number;
  kanaName: string;
};

/**
 * ユーザーの絞り込みを行う（フリガナによるフィルタリング）
 * @param usersValue ユーザーリスト
 * @param checkedPatterns ふりがなの選択パターン
 * @returns 絞り込んだユーザーリスト
 */
export const filterUsersByKana = <T extends TargetUser>(
  usersValue: T[],
  checkedPatterns: PatternListType[]
): T[] => {
  let filteredUsers = usersValue;

  // 氏名かな検索
  if (checkedPatterns.length) {
    const patternArr = checkedPatterns.map((item) => item.targetKana);
    const checkKanaReg = new RegExp(`^(${patternArr.join("|")}).*$`);
    filteredUsers = filteredUsers.filter((item) =>
      checkKanaReg.test(item.kanaName)
    );
  }

  return filteredUsers;
};
