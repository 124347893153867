import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
// store
import { AppState } from "@stores/type";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
// ui
import { OptionInterface } from "@components/atoms/DropDown";
import MuiTextField from "@components/molecules/MuiTextField";
// formik
import FormikSelect from "@components/molecules/FormikSelect";
import { FormikProps } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import {
  FacilityType,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { FieldItem } from "@interfaces/ui/form";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    paper: {
      margin: spacing.unit * 2,
      padding: "32px 32px 0px 32px"
    }
  });

type StateProps = {
  facilityUsersKYOTAKUKAIGO: GetFacilityUsersListResponse["data"];
  facilityUsersJUDOHOMONKAIGO: GetFacilityUsersListResponse["data"];
  facilityUsersDOKOENGO: GetFacilityUsersListResponse["data"];
  facilityUsersIDOSHIEN: GetFacilityUsersListResponse["data"];
};

type DispatchProps = {
  fetchUser: (id: string, facilityType: FacilityType) => void;
};

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setIsInfoOpen: (bool: boolean) => void;
  isEdit: boolean;
  statusOptions: FieldItem[];
  facilityType: FacilityType;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
};

type MergeProps = OwnProps &
  StateProps & {
    userListOption: OptionInterface[];
  } & DispatchProps;

type Props = MergeProps & WithStyles<typeof styles>;

const ServiceDeliveryBasicFieldUserSelectCore = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  const nameSei = props.detailRecords ? props.detailRecords.nameSei : "";
  const nameMei = props.detailRecords ? props.detailRecords.nameMei : "";

  const onChangeHookUser = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ): Promise<void> => {
    if (SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(props.facilityType))
      return;
    if (
      props.facilityType === FacilityType.JUDOHOMONKAIGO &&
      Number(e.target.value) !== 0
    ) {
      await props.fetchUser(e.target.value, props.facilityType);
    }
    if (
      formikValues.status !== props.statusOptions[0].value &&
      Number(e.target.value) !== 0
    ) {
      props.setIsInfoOpen(true);
    } else {
      props.setIsInfoOpen(false);
    }
  };

  return (
    <>
      {props.isEdit ? (
        <MuiTextField
          value={`${nameSei} ${nameMei}`}
          label="利用者名"
          disabled
          disabledStyle
          style={{ width: 256 }}
        />
      ) : (
        <FormikSelect
          name="usersInFacilityId"
          label="利用者名"
          options={props.userListOption}
          style={{ width: 328 }}
          onChangeHook={onChangeHookUser}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facilityUsersKYOTAKUKAIGO: state.KYOTAKUKAIGO.userInFacility.usersList,
  facilityUsersJUDOHOMONKAIGO: state.JUDOHOMONKAIGO.userInFacility.usersList,
  facilityUsersDOKOENGO: state.DOKOENGO.userInFacility.usersList,
  facilityUsersIDOSHIEN: state.IDOSHIEN.userInFacility.usersList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery } = dispatches;
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchUser: (id: string, facilityType: FacilityType): Promise<void> =>
      serviceDeliveryDispatches.fetchUser(id, facilityType)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchToProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const defaultOption = {
    label: "選択してください",
    value: 0
  };
  const createFacilityUsers = (): GetFacilityUsersListResponse["data"] => {
    switch (ownProps.facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return stateProps.facilityUsersKYOTAKUKAIGO;
      case FacilityType.JUDOHOMONKAIGO:
        return stateProps.facilityUsersJUDOHOMONKAIGO;
      case FacilityType.DOKOENGO:
        return stateProps.facilityUsersDOKOENGO;
      case FacilityType.IDOSHIEN:
        return stateProps.facilityUsersIDOSHIEN;
      default:
        return stateProps.facilityUsersKYOTAKUKAIGO;
    }
  };
  const facilityUsers = createFacilityUsers();
  const userListOption = facilityUsers.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });

  userListOption.unshift(defaultOption);
  return {
    userListOption,
    ...stateProps,
    ...dispatchToProps,
    ...ownProps
  };
};

export const ServiceDeliveryBasicFieldUserSelect = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryBasicFieldUserSelectCore));
