import * as facilityActionsSEIKATSUKAIGO from "@stores/domain/mgr/SEIKATSUKAIGO/facility/actions";
import * as facilityActionsJIRITSUKUNRENSEIKATSU from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/actions";
import * as facilityActionsIAB from "@stores/domain/mgr/IAB/facility/actions";
import * as facilityActionsSHUROTEICHAKU from "@stores/domain/mgr/SHUROTEICHAKU/facility/actions";
import * as facilityActionsGroupHome from "@stores/domain/mgr/GroupHome/facility/actions";
import * as facilityActionsSHISETSUNYUSHO from "@stores/domain/mgr/SHISETSUNYUSHO/facility/actions";
import * as facilityActionsTANKINYUSHO from "@stores/domain/mgr/TANKINYUSHO/facility/actions";
import * as userInFacilityActionsSEIKATSUKAIGO from "@stores/domain/mgr/SEIKATSUKAIGO/userInFacility/actions";
import * as userInFacilityActionsJIRITSUKUNRENSEIKATSU from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/actions";
import * as userInFacilityActionsIAB from "@stores/domain/mgr/IAB/userInFacility/actions";
import * as userInFacilityActionsSHUROTEICHAKU from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/actions";
import * as userInFacilityActionsGroupHome from "@stores/domain/mgr/GroupHome/userInFacility/actions";
import * as userInFacilityActionsSHISETSUNYUSHO from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/actions";
import * as userInFacilityActionsTANKINYUSHO from "@stores/domain/mgr/TANKINYUSHO/userInFacility/actions";
import * as reportActionsSEIKATSUKAIGO from "@stores/domain/mgr/SEIKATSUKAIGO/report/actions";
import * as reportActionsJIRITSUKUNRENSEIKATSU from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/report/actions";
import * as reportActionsIAB from "@stores/domain/mgr/IAB/report/actions";

import { FacilityType } from "@constants/variables";

export type FacilityActions =
  | typeof facilityActionsSEIKATSUKAIGO
  | typeof facilityActionsIAB
  | typeof facilityActionsSHUROTEICHAKU
  | typeof facilityActionsJIRITSUKUNRENSEIKATSU
  | typeof facilityActionsGroupHome
  | typeof facilityActionsTANKINYUSHO
  | typeof facilityActionsSHISETSUNYUSHO;

export type UserInFacilityActions =
  | typeof userInFacilityActionsSEIKATSUKAIGO
  | typeof userInFacilityActionsIAB
  | typeof userInFacilityActionsSHUROTEICHAKU
  | typeof userInFacilityActionsJIRITSUKUNRENSEIKATSU
  | typeof userInFacilityActionsGroupHome
  | typeof userInFacilityActionsTANKINYUSHO
  | typeof userInFacilityActionsSHISETSUNYUSHO;

/**
 * facilityActions取得
 * @param facilityType
 */
export const getFacilityActions = (
  facilityType: FacilityType
): FacilityActions => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return facilityActionsSEIKATSUKAIGO;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return facilityActionsJIRITSUKUNRENSEIKATSU;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return facilityActionsIAB;
    case FacilityType.SHUROTEICHAKU:
      return facilityActionsSHUROTEICHAKU;
    case FacilityType.GROUP_HOME:
      return facilityActionsGroupHome;
    case FacilityType.TANKINYUSHO:
      return facilityActionsTANKINYUSHO;
    case FacilityType.SHISETSUNYUSHO:
      return facilityActionsSHISETSUNYUSHO;
    default:
  }
  return facilityActionsSEIKATSUKAIGO;
};

export const getUserInFacilityActions = (
  facilityType: FacilityType
): UserInFacilityActions => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return userInFacilityActionsSEIKATSUKAIGO;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return userInFacilityActionsJIRITSUKUNRENSEIKATSU;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return userInFacilityActionsIAB;
    case FacilityType.SHUROTEICHAKU:
      return userInFacilityActionsSHUROTEICHAKU;
    case FacilityType.GROUP_HOME:
      return userInFacilityActionsGroupHome;
    case FacilityType.TANKINYUSHO:
      return userInFacilityActionsTANKINYUSHO;
    case FacilityType.SHISETSUNYUSHO:
      return userInFacilityActionsSHISETSUNYUSHO;
    default:
  }
  return userInFacilityActionsSEIKATSUKAIGO;
};

/**
 * reportActionsの取得
 * @param facilityType
 */
export const getReportActions = (
  facilityType: FacilityType
):
  | typeof reportActionsSEIKATSUKAIGO
  | typeof reportActionsIAB
  | typeof reportActionsJIRITSUKUNRENSEIKATSU => {
  switch (facilityType) {
    case FacilityType.SEIKATSUKAIGO:
      return reportActionsSEIKATSUKAIGO;
    case FacilityType.JIRITSUKUNRENSEIKATSU:
      return reportActionsJIRITSUKUNRENSEIKATSU;
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.IKOU:
      return reportActionsIAB;
    default:
  }
  return reportActionsSEIKATSUKAIGO;
};
