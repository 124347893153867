import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetMonitoringMonthResponse = BaseResponse<{
  monitoring_month: {
    id: number;
    year_month: string;
  }[];
}>;

/**
 * モニタリング実施月一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const getMonitoringMonth = async (
  uifId: string
): Promise<AxiosResponse<GetMonitoringMonthResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/monitoring_month`;
  return request.get<GetMonitoringMonthResponse>(url);
};
