import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { SupportReportState } from "@stores/domain/supportReport/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordSupportReportState } from "@stores/pages/record/supportReport/types";
import { SupportPlanState } from "@stores/domain/mgr/SHUROTEICHAKU/supportPlan/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import AdminTemplate from "@components/templates/AdminTemplate";
import UserInfoRecord from "@components/organisms/mgr/SHUROTEICHAKU/record/UserInfoRecord";
import { SupportReportRecord } from "@components/organisms/mgr/SHUROTEICHAKU/record/SupportReportRecord";
import { FieldItem } from "@interfaces/ui/form";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";

// utils
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      padding: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = WithStyles<typeof styles> &
  RouteComponentProps<{
    uifId: string;
    year?: string;
    month?: string;
  }>;
type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  supportPlanRecord: SupportPlanState["supportPlan"];
  supportReport: SupportReportState;
  staff: StaffState;
  recordSupportReport: RecordSupportReportState;
  userState: UserState;
};
type DispatchProps = {
  fetchSupportPlan: (uifId: string) => void;
  fetchSupportReport: (uifId: string, year: string, month: string) => void;
  fetchInitialUserDetail: (uifId: string, year: string, month: string) => void;
  fetchInitialSupportPlan: (uifId: string) => void;
};

type MergeProps = { userName: string; staffOptions: FieldItem[] } & OwnProps &
  StateProps &
  DispatchProps;

/**
 * 利用者ごと
 */
const SupportReportCore = (props: MergeProps): JSX.Element => {
  const { uifId, year, month } = props.match.params;

  const pageName = "支援レポート";

  // カレンダー周り
  const currentDate = new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = new Date(2049, 11, 31);
  const selectedDate =
    year && month ? new Date(+year, +month - 1, 1) : currentDate;
  const selectedYear = year || selectedDate.getFullYear().toString();
  const selectedMonth = month || (selectedDate.getMonth() + 1).toString();

  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" }
  ];

  // state
  const [calendar, setCalendar] = React.useState({
    year: selectedYear,
    month: selectedMonth,
    date: selectedDate
  });
  const isFirstRef = React.useRef(false);

  // fetch
  React.useEffect(() => {
    // 初期取得 or 再取得
    if (isFirstRef.current) {
      props.fetchSupportReport(uifId, calendar.year, calendar.month);
      props.fetchSupportPlan(uifId);
    } else {
      props.fetchInitialUserDetail(uifId, calendar.year, calendar.month);
      props.fetchInitialSupportPlan(uifId);
      isFirstRef.current = true;
    }
  }, [calendar]);

  // change url
  React.useEffect(() => {
    const locationState = {
      pathname: `/record/${uifId}/support_report/${calendar.year}/${calendar.month}`
    };
    if (props.match.params.year && props.match.params.month) {
      // TODO: ブラウザバックのバグが取れるまで履歴を持たなくする
      props.history.replace(locationState);
    } else {
      // urlにyear,monthがなければ現在日で付与
      props.history.replace(locationState);
    }
  }, [calendar]);

  // handler
  const onClickCalendar = (date: Date): void => {
    const clickYear = date.getFullYear().toString();
    const clickMonth = (date.getMonth() + 1).toString();
    setCalendar({
      date,
      year: clickYear,
      month: clickMonth
    });
  };

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper}>
        <UnEditableWrapper unEditable={props.recordSupportReport.isEditing}>
          <DateSelectButtonsMonthly
            selectedMonth={calendar.date}
            min={calendarMinDate}
            max={calendarMaxDate}
            onClickSubmit={onClickCalendar}
          />
        </UnEditableWrapper>
      </div>
      <div className={props.classes.wrapper}>
        <UserInfoRecord
          userName={props.userName}
          user={props.user}
          facility={props.facility}
          supportPlan={props.supportPlanRecord}
          isEditing={props.recordSupportReport.isEditing}
        />
      </div>
      <div className={props.classes.wrapper}>
        <SupportReportRecord
          pageName={pageName}
          userName={props.userName}
          uifId={uifId}
          year={calendar.year}
          month={calendar.month}
          supportReport={props.supportReport}
          staff={props.staff}
          staffOptions={props.staffOptions}
          recordSupportReport={props.recordSupportReport}
          history={props.history}
          user={props.user}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.SHUROTEICHAKU.facility,
  user: state.SHUROTEICHAKU.userInFacility.user,
  supportPlanRecord: state.SHUROTEICHAKU.supportPlan.supportPlan,
  supportReport: state.supportReport,
  staff: state.staff,
  recordSupportReport: state.pages.recordSupportReport,
  userState: state.user
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportPlanDispatcher, pages, supportReportDispatcher } = dispatches;
  const recordUserDetailDispatcher = pages.recordUserDetailDispatcher(dispatch);
  const supportPlanDispatches = supportPlanDispatcher(dispatch);
  const supportReportDispatches = supportReportDispatcher(dispatch);

  return {
    fetchSupportPlan: (uifId: string): void => {
      supportPlanDispatches.fetchSupportPlan(uifId);
    },
    fetchSupportReport: (uifId: string, year: string, month: string): void => {
      supportReportDispatches.fetchSupportReport(uifId, year, month);
    },
    fetchInitialUserDetail: (
      uifId: string,
      year: string,
      month: string
    ): void => {
      recordUserDetailDispatcher.fetchInitialSupportReport(uifId, year, month);
    },
    fetchInitialSupportPlan: (uifId: string): void => {
      recordUserDetailDispatcher.fetchInitialSupportPlan(uifId);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  return {
    userName,
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportReport = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(SupportReportCore));
