import isEmpty from "lodash-es/isEmpty";

/**
 * 数字以外の文字列を渡したときに返す値が castNumber とは異なる
 */
const castStringToNumber = (value: number | string): number => {
  if (typeof value === "number") {
    return value;
  }
  if (isEmpty(value) || Number.isNaN(Number(value))) {
    return 0;
  }
  return Number(value);
};

export default castStringToNumber;
