import React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import { SettingForm } from "@components/organisms/mgr/KEIKAKUSODAN/assessment/setting/SettingForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

export const AssessmentSetting = (): JSX.Element => (
  <AdminTemplate pageName="アセスメントの設定">
    <SettingForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);
