import * as actions from "./actions";
import * as types from "./types";

const initialState: types.InquiryState = {
  inquiry: {
    support_inquiries_id: 0,
    creation_date: "",
    creation_staff: null,
    why_not: "",
    inquiry_purpose: "",
    inquiry_destination: "",
    inquiry_date: "",
    inquiry_content: "",
    respondent_name: "",
    response_date: "",
    response_content: "",
    type_consultation: 0,
    created_at: "",
    updated_at: ""
  }
};

export const KEIKAKUSODANInquiry = (
  state = initialState,
  action: actions.ActionTypes
): types.InquiryState => {
  switch (action.type) {
    case types.FETCH_INQUIRY_STARTED:
      return { ...state };
    case types.FETCH_INQUIRY_SUCCESS:
      return { ...action.payload };
    case types.FETCH_INQUIRY_FAILED:
      return { ...state };
    case types.POST_INQUIRY_STARTED:
      return { ...state };
    case types.POST_INQUIRY_SUCCESS:
      return { ...state };
    case types.POST_INQUIRY_FAILED:
      return { ...state };
    case types.DELETE_INQUIRY_STARTED:
      return { ...state };
    case types.DELETE_INQUIRY_SUCCESS:
      return { ...state };
    case types.DELETE_INQUIRY_FAILED:
      return { ...state };
    case types.UNSET_INQUIRY:
      return { ...state, ...initialState };

    default:
      return state;
  }
};
