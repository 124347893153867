import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  COLLECTION_OF_UTILITY_FEE,
  FOOD_BREAKFAST,
  FOOD_LUNCH,
  FOOD_SUPPER,
  HospitalizationDays,
  HOSPITALIZATION_OVERNIGHTSTAY,
  STATUS_TYPE_JISSEKI
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import {
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType
} from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/types";

const styles = (): StyleRules =>
  createStyles({
    superLongCell: {
      width: "15%",
      minWidth: 256,
      boxSizing: "content-box",
      paddingRight: 0,
      paddingLeft: 16
    },
    longCell: {
      width: "15%",
      minWidth: 158,
      boxSizing: "content-box",
      paddingRight: 0,
      paddingLeft: 16
    },
    middleCell: {
      width: "7%",
      minWidth: 72,
      boxSizing: "content-box",
      paddingRight: 0,
      paddingLeft: 16
    },
    shortCell: {
      width: "5%",
      minWidth: 54,
      boxSizing: "content-box",
      paddingRight: 0,
      paddingLeft: 16
    },
    editCell: {
      width: "5%",
      // ここは、元のデザインとはサイズが違うが、細かい調整を行った数値を設定している。
      minWidth: 40,
      boxSizing: "content-box",
      paddingLeft: 0,
      paddingTop: 14,
      paddingBottom: 14
    },
    hidden: {
      display: "none"
    }
  });

type OwnProps = {
  usagePerformance: UsagePerformanceType;
  usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType;
  idx: number;
  openModal: (
    usagePerformance: UsagePerformanceType,
    usagePerformanceSHISETSUNYUSHO: UsagePerformanceSHISETSUNYUSHOType
  ) => void;
  isDisabledFood: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UsagePerformanceReportCell = (props: Props): JSX.Element => {
  const {
    usagePerformance,
    usagePerformanceSHISETSUNYUSHO,
    idx,
    classes,
    isDisabledFood
  } = props;

  return (
    <>
      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-status-type`}
        cellClass={classes.superLongCell}
      >
        {getLabelFromOptions(
          `${usagePerformance.statusType}`,
          STATUS_TYPE_JISSEKI
        )}
      </TableCellWrap>

      {/* 入院・外泊 */}
      <TableCellWrap
        key={`${idx}-hospitalization-overnight-stay`}
        cellClass={classes.longCell}
      >
        {usagePerformanceSHISETSUNYUSHO
          ? getLabelFromOptions(
              `${usagePerformanceSHISETSUNYUSHO.hospitalizationOvernightStay}`,
              HOSPITALIZATION_OVERNIGHTSTAY
            )
          : "-"}
      </TableCellWrap>

      {/* 入院・外泊日数 */}
      <TableCellWrap
        key={`${idx}-hospitalization-days`}
        cellClass={classes.middleCell}
      >
        {props.usagePerformanceSHISETSUNYUSHO.hospitalizationDays === null
          ? HospitalizationDays.NONE
          : props.usagePerformanceSHISETSUNYUSHO.hospitalizationDays}
      </TableCellWrap>

      {/* 光熱水費 */}
      <TableCellWrap
        key={`${idx}-collection-of-utility-fee`}
        cellClass={classes.middleCell}
      >
        {usagePerformanceSHISETSUNYUSHO
          ? getLabelFromOptions(
              `${usagePerformanceSHISETSUNYUSHO.collectionOfUtilityFee}`,
              COLLECTION_OF_UTILITY_FEE
            )
          : "-"}
      </TableCellWrap>

      {/* 食事提供(朝) */}
      <TableCellWrap
        key={`${idx}-food-breakfast`}
        cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
      >
        {usagePerformanceSHISETSUNYUSHO && !usagePerformance.initialFlg
          ? getLabelFromOptions(
              `${usagePerformanceSHISETSUNYUSHO.foodBreakfast}`,
              FOOD_BREAKFAST
            )
          : "-"}
      </TableCellWrap>

      {/* 食事提供(昼) */}
      <TableCellWrap
        key={`${idx}-food-lunch`}
        cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
      >
        {usagePerformanceSHISETSUNYUSHO && !usagePerformance.initialFlg
          ? getLabelFromOptions(
              `${usagePerformanceSHISETSUNYUSHO.foodLunch}`,
              FOOD_LUNCH
            )
          : "-"}
      </TableCellWrap>

      {/* 食事提供(夜) */}
      <TableCellWrap
        key={`${idx}-food-supper`}
        cellClass={isDisabledFood ? classes.hidden : classes.middleCell}
      >
        {usagePerformanceSHISETSUNYUSHO && !usagePerformance.initialFlg
          ? getLabelFromOptions(
              `${usagePerformanceSHISETSUNYUSHO.foodSupper}`,
              FOOD_SUPPER
            )
          : "-"}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-edit-icon`}
        cellClass={classes.editCell}
        align="right"
      />
    </>
  );
};

export default withStyles(styles)(UsagePerformanceReportCell);
