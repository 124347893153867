import {
  ReportState,
  NoConstantContactState
} from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";
import {
  InitialValues,
  InitialConstantValues
} from "@interfaces/mgr/CHIIKITEICHAKU/report/initial";
import { CHIIKITEICHAKU_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";

export type InitialDataValues = InitialValues;
export type InitialConstantDataValues = InitialConstantValues;

// デフォルト値設定
export const initialValues = (
  state?: ReportState,
  date = ""
): InitialDataValues => {
  const inOutResults: ReportState["inoutConsultationResults"] =
    state && state.inoutConsultationResults
      ? state.inoutConsultationResults
      : [];

  const initializedInOutResults = inOutResults.map((inOutResultState) => {
    return {
      id:
        inOutResultState && inOutResultState.inout_consultation_results_id
          ? inOutResultState.inout_consultation_results_id
          : null,
      inoutResultDailyId: inOutResultState
        ? inOutResultState.inoutResultDailyId
        : null,
      targetDate:
        inOutResultState && inOutResultState.targetDate
          ? inOutResultState.targetDate
          : date,
      status:
        inOutResultState && inOutResultState.status
          ? inOutResultState.status.toString()
          : CHIIKITEICHAKU_STATUS_LIST.NONE.value,
      housingSupportCooperationFlg:
        inOutResultState && inOutResultState.housingSupportCooperationFlg
          ? inOutResultState.housingSupportCooperationFlg
          : false,
      housingSupportPromotionFlg:
        inOutResultState && inOutResultState.housingSupportPromotionFlg
          ? inOutResultState.housingSupportPromotionFlg
          : false,
      dailySupportProvisionOfInfoFlg:
        inOutResultState && inOutResultState.dailySupportProvisionOfInfoFlg
          ? inOutResultState.dailySupportProvisionOfInfoFlg
          : false,
      noConstantContactSystemFlg:
        inOutResultState && inOutResultState.noConstantContactSystemFlg
          ? inOutResultState.noConstantContactSystemFlg
          : false,
      memo:
        inOutResultState && inOutResultState.memo ? inOutResultState.memo : ""
    };
  });
  return {
    initial: {
      inoutConsultationResults: initializedInOutResults
    }
  };
};

// デフォルト値設定
export const initiaConstantlValues = (
  state?: NoConstantContactState
): InitialConstantDataValues => {
  return {
    initial: {
      noConstantContactSystemFlg:
        state && state.inoutResultsMonthly.noConstantContactSystemFlg
          ? state.inoutResultsMonthly.noConstantContactSystemFlg
          : false
    }
  };
};
