import { Dispatch } from "redux";
import * as action from "@stores/domain/city/action";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import dispatches from "@stores/dispatches";

import { CityParams } from "./type";
import { DEFAULT_CITY_STATE } from "@constants/variables";

import cityApi from "@api/requests/city";

export const fetch = (dispatch: Dispatch) => async (
  params: CityParams
): Promise<void> => {
  dispatch(action.fetch.started());

  await cityApi
    .getCityList(params.prefectureName)
    .then((response) => {
      const { data } = response.data;
      // 都道府県が未選択の場合はdataがnullで返却される
      const resultData = Array.isArray(data) ? data : [];
      // todo: DEFAULT_CITY_STATE の型が CityState と異なるため any で回避…苦肉の策
      /* eslint-disable @typescript-eslint/no-explicit-any */
      resultData.unshift(DEFAULT_CITY_STATE as any);
      dispatch(action.fetch.done({ result: resultData }));
    })
    .catch((e) => {
      dispatch(action.fetch.failed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    });
};

const clearCity = (dispatch: Dispatch) => (): void => {
  dispatch(action.clearCity());
};

type Dispatcher = {
  fetch: (params: CityParams) => Promise<void>;
  clearCity: () => void;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    fetch: fetch(dispatch),
    clearCity: clearCity(dispatch)
  };
}
