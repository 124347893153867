import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { InOutReportMonthly } from "@components/organisms/mgr/KEIKAKUSODAN/report/InOutReportMonthly";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import * as URL from "@constants/url";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { INVOICE_VERSION_CURRENT } from "@constants/variables";

type Props = RouteComponentProps<{}, {}, { targetDate: Date }>;

/**
 * 利用実績
 */
export const ReportMonthly = (props: Props): JSX.Element => {
  const currentPageVersion = INVOICE_VERSION_CURRENT;
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.DAILY) {
      props.history.push(URL.REPORT_DAILY);
    }
  };
  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LIST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.USERS}
      />
      <InOutReportMonthly
        initialDate={currentDate}
        history={props.history}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate>
  );
};
