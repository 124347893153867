import * as React from "react";

import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  DownloadableMonth,
  DownloadableUser
} from "@stores/domain/invoice/type";

const styles = (): StyleRules =>
  createStyles({
    button: {
      margin: "16px 8px 0",
      boxShadow: "none",
      borderRadius: 4,
      width: 245,
      "&:first-of-type": {
        marginLeft: 0
      }
    },
    buttonSide: {
      display: "flex",
      flexWrap: "wrap"
    },
    buttonMargin: {
      marginLeft: 0
    },
    invoiceTitle: {
      fontSize: "20px",
      color: "#37474f",
      fontWeight: 500,
      margin: "32px 0 16px 0"
    },
    description: {
      fontSize: 16,
      color: "#37474f"
    }
  });

export type DownloadAction = {
  label: string;
  onClick: () => void;
  isDisabled: boolean;
};

type OwnProps = {
  month: string;
  months: DownloadableMonth[];
  downloadCsvActions: DownloadAction[];
  downloadPrintActions: DownloadAction[];
  excludedUserIds: number[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadInvoiceSection = (props: Props): JSX.Element => {
  const { classes, month = "", months = [] } = props;
  const extractUser = (
    monthProp: string,
    monthsProp: DownloadableMonth[]
  ): Pick<
    DownloadableUser,
    "targetForUserCostAmountList" | "targetForUpLimit" | "targetServiceDisplay"
  > => {
    const checkIncludeTargetUsers = monthsProp.filter((target) => {
      return monthProp === target.date;
    });
    const users: DownloadableUser[] = [];
    checkIncludeTargetUsers.forEach((entry) => {
      entry.results.forEach((fixed) => {
        users.push(...fixed.users);
      });
    });

    const checkUser = users.filter((target) => {
      return (
        !props.excludedUserIds.some((id) => id === target.id) &&
        !target.none_recipient_number_flg
      );
    });
    return {
      targetForUserCostAmountList: checkUser.some(
        (user) => user.targetForUserCostAmountList
      ),
      targetForUpLimit: checkUser.some((user) => user.targetForUpLimit),
      targetServiceDisplay: checkUser.some((user) => user.targetServiceDisplay)
    };
  };
  const includeUser = extractUser(month, months);
  return (
    <div>
      <Typography component="h2" variant="h2" className={classes.invoiceTitle}>
        国保連への請求書類ダウンロード
      </Typography>
      <div>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          各種請求書類はCSVファイルでダウンロードされます。
          <br />
          ダウンロードしたCSVファイルは「取り込み送信システム」にそのままアップロードしてください。
        </Typography>
        <div className={classes.buttonSide}>
          {props.downloadCsvActions.map((action) =>
            action.label === "請求書・明細書" ? (
              <Button
                key={`download_${action.label}`}
                className={`${
                  includeUser && includeUser.targetServiceDisplay
                    ? ""
                    : classes.buttonMargin
                } ${classes.button}`}
                color="secondary"
                onClick={action.onClick}
                variant="contained"
                disabled={action.isDisabled}
              >
                {action.label}
              </Button>
            ) : (
              <Button
                key={`download_${action.label}`}
                style={
                  (action.label === "サービス提供実績記録票" &&
                    includeUser &&
                    includeUser.targetServiceDisplay) ||
                  (action.label === "利用者負担上限額管理結果票" &&
                    includeUser &&
                    includeUser.targetForUpLimit)
                    ? { display: "block" }
                    : { display: "none" }
                }
                className={classes.button}
                color="secondary"
                onClick={action.onClick}
                variant="contained"
                disabled={action.isDisabled}
              >
                {action.label}
              </Button>
            )
          )}
        </div>
      </div>
      <div>
        <Typography
          component="h2"
          variant="h2"
          className={classes.invoiceTitle}
        >
          請求書類の印刷
        </Typography>
        <Typography
          component="p"
          variant="body2"
          className={classes.description}
        >
          サービス提供実績や請求の詳細内容を規定のフォーマットで印刷されます。
          <br />
          各種請求内容の確認や保管などにご利用ください。
        </Typography>
        <div className={classes.buttonSide}>
          {props.downloadPrintActions.map((action) =>
            action.label === "請求書・明細書" ? (
              <Button
                key={`print_${action.label}`}
                className={`${
                  includeUser && includeUser.targetServiceDisplay
                    ? ""
                    : classes.buttonMargin
                } ${classes.button}`}
                color="secondary"
                onClick={action.onClick}
                variant="contained"
                disabled={action.isDisabled}
              >
                {action.label}
              </Button>
            ) : (
              <Button
                key={`print_${action.label}`}
                style={
                  (action.label === "サービス提供実績記録票" &&
                    includeUser &&
                    includeUser.targetServiceDisplay) ||
                  (action.label === "利用者負担額一覧表" &&
                    includeUser &&
                    includeUser.targetForUserCostAmountList) ||
                  (action.label === "利用者負担上限額管理結果票" &&
                    includeUser &&
                    includeUser.targetForUpLimit)
                    ? { display: "block" }
                    : { display: "none" }
                }
                className={classes.button}
                color="secondary"
                onClick={action.onClick}
                variant="contained"
                disabled={action.isDisabled}
              >
                {action.label}
              </Button>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(DownloadInvoiceSection);
