import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import {
  DisplayStaffsInFacilityState,
  IDOSHIENReportTypeInterface,
  ServiceCodesFilter,
  InoutResultsState,
  MunicipalityData
} from "@stores/domain/mgr/IDOSHIEN/report/types";
import { InitialDataValues } from "@initialize/mgr/IDOSHIEN/report/initialValues";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import {
  validation,
  submitValidation
} from "@initialize/mgr/IDOSHIEN/report/validation";
import { InOutReportDialogSubmit } from "@components/organisms/mgr/IDOSHIEN/report/dialog/InOutReportDialogSubmit";
import { InOutReportDialogFields } from "@components/organisms/mgr/IDOSHIEN/report/dialog/InOutReportDialogFields";
import { InOutReportFormPaper } from "@components/organisms/mgr/IDOSHIEN/report/dialog/InOutReportFormPaper";
import { ServiceCodesDialog } from "@components/organisms/mgr/IDOSHIEN/report/dialog/ServiceCodesDialog";

import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { AppState } from "@stores/type";
import {
  InitialValues,
  ServiceCodesFields
} from "@interfaces/mgr/IDOSHIEN/report/initial";
import { ResponseError } from "@stores/ui/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { ServiceCodeSearchParameters } from "@api/requests/serviceCodes/getServiceCodeSearch";
import { ServiceCodePaginationParameters } from "@api/requests/serviceCodes/getServiceCodePagination";
import { ServiceCodesState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import castNumber from "@utils/dataNormalizer/castNumber";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import { UserState } from "@stores/domain/user/type";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: 0,
      marginTop: 0
    },
    submitArea: {
      "& > :first-child": {
        paddingBottom: 8
      }
    },
    modalAction: {
      display: "flex"
    },
    button: {
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4,
      textTransform: "none",
      width: 110
    },
    submit: {
      marginLeft: spacing.unit,
      width: 110,
      marginRight: spacing.unit
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between" as "space-between"
    },
    modalHeaderDate: {
      fontSize: 16
    },
    modal: {
      width: "80%",
      maxWidth: 616
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 0,
      paddingBottom: 20,
      paddingLeft: 0
    },
    modalHeaderArea: {
      height: "15%",
      padding: "12px 32px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: 20,
      backgroundColor: "#f5f5f5"
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    }
  });

type DispatchProps = {
  postIDOSHIENReport: (
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: IDOSHIENReportTypeInterface["type"],
    municipality: MunicipalityData,
    staffList: DisplayStaffsInFacilityState[]
  ) => Promise<void>;
  fetchDetailsRecord: (
    serviceDeliveryRecordsId: number | null,
    inoutResultsId: number | null,
    supportProcedureFormsId: number | null
  ) => Promise<void>;
  fetchServiceCodesSearch: (
    municipalityId: string,
    params: ServiceCodeSearchParameters
  ) => Promise<void>;
  fetchServiceCodesPagination: (
    municipalityId: string,
    params: ServiceCodePaginationParameters
  ) => Promise<void>;
  responseErrorClear(): void;
};

type StateProps = {
  user: UserState;
  responseError: ResponseError;
  serviceCodes: ServiceCodesState;
  detailRecords: ServiceDeliveryState["detailsRecord"];
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  apiData: InoutResultsState | null;
  staffList: DisplayStaffsInFacilityState[];
  usersInFacilityState: UsersInFacilityState;
  municipality: MunicipalityData;
  municipalityId: number | null;
  data: InitialDataValues;
  selectedDate: Date;
  type: IDOSHIENReportTypeInterface["type"];
  onCancel: () => void;
  onSubmit: () => void;
  modalTarget: string;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

const InOutReportDialogCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);
  const [
    isOpenServiceCodesDialog,
    setIsOpenServiceCodesDialog
  ] = React.useState<boolean>(false);
  // サービスコード登録モーダルのデータ
  const [serviceCodesData, setServiceCodesData] = React.useState<
    ServiceCodesState
  >({
    page: 1,
    count: 0,
    serviceCodes: []
  });
  const [dialogType, setDialogType] = React.useState<"search" | "pagination">(
    "search"
  );

  React.useEffect(() => {
    setServiceCodesData(props.serviceCodes);
  }, [props.serviceCodes]);

  const validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values, props.usersInFacilityState);
    setIsSubmitDisabled(submitValidation(validationResult));
    const errorCheckResult = toEffectiveObject(validationResult);

    // エラーが存在するかは、toEffectiveObjectで確認
    // エラーが存在する場合は、validationの結果をそのまま返却
    // →FieldArrayを利用する場合、エラーは配列として保持する必要があるため
    // →また、エラー配列と表示データの配列はindexを一致させる必要がある
    // エラーが存在しない場合は、undefinedを返却（errorObjectがundefined）
    return errorCheckResult ? validationResult : errorCheckResult;
  };

  const onCancel = (): void => {
    setIsSubmitDisabled(true);
    props.onCancel();
  };

  // サービス提供記録から読み込む
  const copyServiceDelivery = async (): Promise<void> => {
    await props.fetchDetailsRecord(
      props.data.initial.serviceDeliveryRecordsId,
      null,
      null
    );
  };

  const onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postIDOSHIENReport(
        props.data,
        values,
        props.type,
        props.municipality,
        props.staffList
      )
      .then(() => {
        onCancel();
        props.onSubmit();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  // 対象サービスコードを追加登録する
  const searchServiceCodes = (
    page = 1,
    data: InitialDataValues["initial"]
  ): void => {
    let calculatedHours = null;
    if (!props.municipality.timeDivisionFlg) {
      if (data.status === "4") {
        const roundUpMinute =
          props.municipality.calculationTimeFlg &&
          props.municipality.roundUpMinute
            ? props.municipality.roundUpMinute
            : 30;
        calculatedHours =
          castNumber(data.vehicleCalculatedHours) +
          (castNumber(data.vehicleCalculatedMinute) >= roundUpMinute ? 0.5 : 0);
      } else if (data.numberOfParticipants === "1" || data.status === "3") {
        calculatedHours = castNumber(data.practitioner1CalculatedHours);
      } else {
        calculatedHours = castNumber(data.wholeCalculatedHours);
      }
    }
    const params = {
      page,
      content: castNumber(data.status) - 1, // 選択欄のvalueとAPIのクエリでズレがあるので−１する
      physical_care: booleanToNumber0or1(data.physicalCareFlg) || 0,
      number_of_participants: castNumber(data.numberOfParticipants),
      whole_calculated_hours: calculatedHours,
      calculated_hours_daytime: castNumber(data.calculatedHoursDaytime),
      calculated_hours_nighttime: castNumber(data.calculatedHoursNighttime),
      calculated_hours_late_night: castNumber(data.calculatedHoursLateNight),
      calculated_hours_early_morning: castNumber(
        data.calculatedHoursEarlyMorning
      ),
      duplicate_calculated_hours: !props.municipality.timeDivisionFlg
        ? castNumber(data.duplicateCalculatedHours)
        : null,
      duplication_calculated_hours_daytime: castNumber(
        data.duplicateCalculatedHoursDaytime
      ),
      duplication_calculated_hours_nighttime: castNumber(
        data.duplicateCalculatedHoursNighttime
      ),
      duplication_calculated_hours_late_night: castNumber(
        data.duplicateCalculatedHoursLateNight
      ),
      duplication_calculated_hours_early_morning: castNumber(
        data.duplicateCalculatedHoursEarlyMorning
      )
    };
    props.fetchServiceCodesSearch(String(props.municipalityId), params);
  };

  // 条件から絞り込んで登録する
  const paginationServiceCodes = (
    page = 1,
    params: ServiceCodesFilter
  ): void => {
    props.fetchServiceCodesPagination(String(props.municipalityId), {
      page,
      content: params.content ? castNumber(params.content) : null,
      physical_care: params.physicalCare
        ? castNumber(params.physicalCare)
        : null,
      calculation_hours: params.calculationHours
        ? castNumber(params.calculationHours)
        : null,
      daytime_hours: params.daytimeHours
        ? castNumber(params.daytimeHours)
        : null,
      nighttime_hours: params.nighttimeHours
        ? castNumber(params.nighttimeHours)
        : null,
      late_night_hours: params.lateNightHours
        ? castNumber(params.lateNightHours)
        : null,
      early_morning_hours: params.earlyMorningHours
        ? castNumber(params.earlyMorningHours)
        : null
    });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.open}
      classes={{ paper: classes.modal }}
    >
      <Formik
        initialValues={props.data}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(formikProps): JSX.Element => {
          const formikValue = formikProps.values;
          const onCloseServiceCodesDialog = (): void => {
            setIsOpenServiceCodesDialog(false);
            setServiceCodesData({
              page: 1,
              count: 0,
              serviceCodes: []
            });
          };
          const onSubmitServiceCodesDialog = (
            data: ServiceCodesState["serviceCodes"]
          ): void => {
            const newData: ServiceCodesFields[] = formikValue.initial.serviceCodes.concat(
              data.map((x) => ({
                serviceCodeInoutResultId: null,
                serviceCodeMunicipalityId: x.id,
                name: x.name,
                serviceCode: x.serviceCode,
                unitPrice: castNumber(x.unitPrice),
                isDelete: false
              }))
            );
            formikProps.setFieldValue("initial.serviceCodes", newData);
            onCloseServiceCodesDialog();
          };
          const onOpenServiceCodesDialog = (
            type: "search" | "pagination"
          ): void => {
            if (type === "search") {
              searchServiceCodes(1, formikProps.values.initial);
            }
            setIsOpenServiceCodesDialog(true);
            setDialogType(type);
          };
          const onPageChangeServiceCodesDialog = (
            page: number,
            filter: ServiceCodesFilter
          ): void => {
            if (dialogType === "pagination") {
              paginationServiceCodes(page, filter);
            } else {
              searchServiceCodes(page, formikProps.values.initial);
            }
          };
          const onClickSearchServiceCodesDialog = (
            page: number,
            filter: ServiceCodesFilter
          ): void => {
            paginationServiceCodes(page, filter);
          };
          const onDeleteServiceCodes = (index: number): void => {
            const newData = formikValue.initial.serviceCodes.map((x, i) =>
              i === index
                ? {
                    ...x,
                    isDelete: true
                  }
                : x
            );
            formikProps.setFieldValue("initial.serviceCodes", newData);
          };
          return (
            <Form className={classes.formArea}>
              <DialogTitle
                id={props.labelId}
                classes={{ root: classes.modalHeaderArea }}
                disableTypography
              >
                <div className={props.classes.modalHeader}>
                  <div>{props.modalTarget}</div>
                  <div>
                    <span className={props.classes.modalHeaderDate}>
                      {props.selectedDate.getFullYear()}
                      <span>年</span>
                      {props.selectedDate.getMonth() + 1}
                      <span>月</span>
                      {props.selectedDate.getDate()}
                      <span>日</span>
                      <span>
                        （
                        {oneLetterWeekdaysJapanese[props.selectedDate.getDay()]}
                        ）
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <span>{props.data.initial.name}</span>
                </div>
              </DialogTitle>
              <DialogContent className={classes.modalContents}>
                <InOutReportFormPaper>
                  <InOutReportDialogFields
                    formikProps={formikProps}
                    staffList={props.staffList}
                    apiData={props.apiData}
                    usersInFacility={props.usersInFacilityState}
                    serviceDelivery={props.detailRecords}
                    municipality={props.municipality}
                    municipalityId={props.municipalityId}
                    copyServiceDelivery={copyServiceDelivery}
                    openServiceCodesDialog={onOpenServiceCodesDialog}
                    onDeleteServiceCodes={onDeleteServiceCodes}
                    groupOperationSupportFlg={
                      props.user.featureGroup.group_operation_support === 1
                    }
                  />
                  <ServiceCodesDialog
                    open={isOpenServiceCodesDialog}
                    title={
                      dialogType === "pagination"
                        ? "条件から絞り込んで登録する"
                        : "対象サービスコードを追加登録する"
                    }
                    page={serviceCodesData.page}
                    maxCount={serviceCodesData.count}
                    serviceCodes={serviceCodesData.serviceCodes}
                    timeDivisionFlg={props.municipality.timeDivisionFlg}
                    canSearch={dialogType === "pagination"}
                    onSubmit={onSubmitServiceCodesDialog}
                    onClose={onCloseServiceCodesDialog}
                    onPageChange={onPageChangeServiceCodesDialog}
                    onClickSearch={onClickSearchServiceCodesDialog}
                  />
                </InOutReportFormPaper>
              </DialogContent>

              <DialogActions className={classes.action}>
                <div className={classes.submitArea}>
                  <InOutReportDialogSubmit
                    formikPropsValues={formikProps}
                    onCancel={onCancel}
                    disabled={isSubmitDisabled}
                  />
                </div>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    detailRecords: state.serviceDelivery.detailsRecord,
    serviceCodes: state.IDOSHIEN.municipalitiesInFacility.serviceCodes,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN, serviceDelivery, uiDispatch } = dispatches;
  const reportDispatcher = IDOSHIEN.reportDispatcher(dispatch);
  const municipalityDispatcher = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    postIDOSHIENReport: (
      beforeFormValue: InitialValues,
      afterFormValue: InitialValues,
      type: IDOSHIENReportTypeInterface["type"],
      municipality: MunicipalityData,
      staffList: DisplayStaffsInFacilityState[]
    ): Promise<void> =>
      reportDispatcher.postIDOSHIENReport(
        beforeFormValue,
        afterFormValue,
        type,
        municipality,
        staffList
      ),
    fetchDetailsRecord: (
      serviceDeliveryRecordsId: number | null,
      inoutResultsId: number | null,
      supportProcedureFormsId: number | null
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchDetailsRecord(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      ),
    fetchServiceCodesSearch: (
      municipalityId: string,
      params: ServiceCodeSearchParameters
    ): Promise<void> =>
      municipalityDispatcher.fetchIDOSHIENServiceCodesSearch(
        municipalityId,
        params
      ),
    fetchServiceCodesPagination: (
      municipalityId: string,
      params: ServiceCodePaginationParameters
    ): Promise<void> =>
      municipalityDispatcher.fetchIDOSHIENServiceCodesPagination(
        municipalityId,
        params
      ),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDialogCore));
