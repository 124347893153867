import * as React from "react";
import { FormikProps } from "formik";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

/* eslint-disable @typescript-eslint/no-explicit-any */
type Props = {
  buttonName: string;
  formikProps: FormikProps<any>;
  errorAction?: () => void;
  className?: string;
  minWidth?: number;
  disabled?: boolean;
};

const FormikSubmitButton = (props: Props): JSX.Element => {
  const handleSubmit = async (): Promise<void> => {
    // touchedでないフィールドのエラーをsubmitCountを利用して表示するので、結果に関わらずsubmit（エラーならformikが実行を弾く）
    await props.formikProps.submitForm();
    const { errors } = props.formikProps;
    if (Object.keys(errors).length !== 0 && props.errorAction) {
      props.errorAction();
    }
  };

  return (
    <KnowbeButton
      variant="contained"
      color="secondary"
      disabled={props.formikProps.isSubmitting || props.disabled}
      onClick={handleSubmit}
      className={props.className}
      minWidth={props.minWidth}
    >
      {props.buttonName}
    </KnowbeButton>
  );
};

type WrapperProps<T> = Omit<
  React.ComponentProps<typeof FormikSubmitButton>,
  "formikProps"
> & { formikProps: FormikProps<T> };

export const FormikSubmitButtonWrapper = <T,>(
  props: WrapperProps<T>
): JSX.Element => {
  return <FormikSubmitButton {...props} />;
};

export default FormikSubmitButton;
