import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { inOutConsultationResults } from "@api/requests/inOutConsultationResults/";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeDailyReportDataFromAPI,
  normalizeUserReportDataFromAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToPostAPI,
  normalizeFacilitiesKeikakusodanMonthlyParams
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/mgr/KEIKAKUSODAN/report/initial";
import {
  KEIKAKUSODANReportTypeInterface,
  FacilitiesKEIKAKUSODANMonthlyType,
  ReportState,
  REPORT_DAILY
} from "@stores/domain/mgr/KEIKAKUSODAN/report/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST,
  TRAINING_BY_CHIEF_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 */
const fetchKEIKAKUSODANDaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKEIKAKUSODANDailyStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKEIKAKUSODANDaily(
          normalizeDailyReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKEIKAKUSODANDailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(月ごと)のデータ取得及びstore格納
 */
const fetchKEIKAKUSODANUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKEIKAKUSODANUserStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKEIKAKUSODANUser(
          normalizeUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKEIKAKUSODANUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postKEIKAKUSODANReport = (dispatch: Dispatch) => async (
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: KEIKAKUSODANReportTypeInterface["type"],
  facility: FacilityState,
  usersInFacility: UsersInFacilityState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKEIKAKUSODANReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(
    before,
    after,
    facility,
    usersInFacility
  );
  await inOutConsultationResults
    .postInOutConsultationResults(requestParam)
    .then(() => {
      const actionType =
        type === REPORT_DAILY
          ? actions.postKEIKAKUSODANReportDaily(requestParam)
          : actions.postKEIKAKUSODANReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postKEIKAKUSODANReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 施設の月ごと情報（facilities_keikakusodan_monthly）の登録/更新
 */
const postKEIKAKUSODANFacilityMonthly = (dispatch: Dispatch) => async ({
  data,
  selectedDate,
  facility
}: FacilitiesKEIKAKUSODANMonthlyType): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postFacilitiesKEIKAKUSODANMonthlyStarted());

  const requestParam = normalizeFacilitiesKeikakusodanMonthlyParams({
    data,
    selectedDate,
    facility
  });
  const {
    number_of_employees,
    number_of_handicapped_child,
    number_of_keikakusodan,
    training_by_chief_202404,
    training_by_chief_shogaiji_202404
  } = requestParam.params.facility;
  const stateParam: ReportState["reportDaily"]["inoutResultDaily"] = {
    targetDate: requestParam.yyyymm,
    numberOfEmployees: number_of_employees,
    numberOfHandicappedChild: number_of_handicapped_child,
    trainingByChief202404: training_by_chief_202404
      ? training_by_chief_202404.toString()
      : TRAINING_BY_CHIEF_TYPE_LIST[0].value,
    numberOfKeikakusodan: number_of_keikakusodan,
    trainingByChiefShogaiji202404: training_by_chief_shogaiji_202404
      ? training_by_chief_shogaiji_202404.toString()
      : TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST[0].value
  };
  await inOutConsultationResults
    .postFacilitiesMonthly(requestParam)
    .then(() => {
      dispatch(actions.postFacilitiesKEIKAKUSODANMonthly(stateParam));
    })
    .catch((e) => {
      dispatch(
        actions.postFacilitiesKEIKAKUSODANMonthlyFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchKEIKAKUSODANDaily: (date: Date) => Promise<void>;
  fetchKEIKAKUSODANUsers: (uifId: number, date: Date) => Promise<void>;
  postKEIKAKUSODANReport: (
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KEIKAKUSODANReportTypeInterface["type"],
    facility: FacilityState,
    usersInFacility: UsersInFacilityState
  ) => Promise<void>;
  postKEIKAKUSODANFacilityMonthly: (
    args: FacilitiesKEIKAKUSODANMonthlyType
  ) => Promise<void>;
};

export const KEIKAKUSODANReportDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchKEIKAKUSODANDaily: fetchKEIKAKUSODANDaily(dispatch),
  fetchKEIKAKUSODANUsers: fetchKEIKAKUSODANUsers(dispatch),
  postKEIKAKUSODANReport: postKEIKAKUSODANReport(dispatch),
  postKEIKAKUSODANFacilityMonthly: postKEIKAKUSODANFacilityMonthly(dispatch)
});
