/**
 * 基準の時間内か判定
 */
export const checkWithinRangeTime = (
  baseStartTime: Date,
  baseEndTime: Date,
  targetStartTime: Date,
  targetEndTime: Date,
  option: {
    baseLabel: string;
    targetLabel: string;
  },
  equivalent = false
): string | undefined => {
  const errorMessage = `${option.targetLabel}は${option.baseLabel}の範囲内で入力してください`;
  if (
    Number.isNaN(baseStartTime.getTime()) ||
    Number.isNaN(baseEndTime.getTime()) ||
    Number.isNaN(targetStartTime.getTime()) ||
    Number.isNaN(targetEndTime.getTime())
  ) {
    return undefined;
  }

  // どちらかが逆転している場合もエラーは出さない
  if (baseStartTime >= baseEndTime || targetStartTime >= targetEndTime) {
    return undefined;
  }

  if (equivalent) {
    if (baseStartTime > targetStartTime || baseEndTime < targetEndTime) {
      return errorMessage;
    }
  } else if (baseStartTime >= targetStartTime || baseEndTime <= targetEndTime) {
    return errorMessage;
  }

  return undefined;
};
