import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { InquiryState } from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { InquiryForm } from "@components/organisms/mgr/KEIKAKUSODAN/record/inquiry/InquiryForm";

// utils
import * as URL from "@constants/url";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import { UserState } from "@stores/domain/user/type";
import { getUrlParams } from "@utils/url";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  inquiryId: string;
}>;

type StateProps = {
  user: UsersInFacilityState["user"];
  userState: UserState;
  inquiry: InquiryState["inquiry"];
};

type DispatchProps = {
  fetchOne: (uifId: string) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 利用者ごと > サービス担当者に対する照会（依頼）内容
 */
const InquiryCore = (props: Props): JSX.Element => {
  const { uifId, inquiryId } = props.match.params;
  const { classes } = props;

  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "計画・モニタリング・会議記録",
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // 現在ページのタイトル名
  const pageName = "サービス担当者に対する照会（依頼）内容";

  React.useEffect(() => {
    props.fetchOne(uifId);
  }, []);

  const { search } = props.location;
  const queryParam: {
    type?: string;
  } = getUrlParams(search);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <InquiryForm
          userName={props.userName}
          uifId={uifId}
          inquiryId={inquiryId}
          history={props.history}
          queryParam={queryParam.type}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.KEIKAKUSODAN.userInFacility.user,
    userState: state.user as UserState,
    inquiry: state.KEIKAKUSODAN.inquiry.inquiry
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchOne: (uifId: string): void => {
      dispatches.KEIKAKUSODAN.userInFacilityDispatcher(dispatch).fetchOne(
        uifId
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const Inquiry = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(InquiryCore));
