import isEmpty from "lodash-es/isEmpty";
import { format } from "date-fns";
import {
  Practitioner,
  RecordCarePlan,
  SupportCarePlanDetails,
  SupportCarePlanSchedules,
  SupportCarePlanBasicInfo
} from "@interfaces/record/carePlan/carePlan";
import { PostSupportPlanUifIdCarePlanNew } from "@api/requests/carePlan/postSupportPlanUifIdCarePlanNew";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { PostSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/postSupportPlanUifIdCarePlanCarePlanId";
import { PostSupportPlanUifIdCarePlanCarePlanIdDetails } from "@api/requests/carePlan/postSupportPlanUifIdCarePlanCarePlanIdDetails";

import {
  selectDateValueToDate,
  selectDateValueToDatePaddingZero
} from "@utils/date";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import castStringToNumber from "@utils/dataNormalizer/castStringToNumber";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";
import { HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM } from "@constants/variables";

/**
 * timeがあればyyyy-mm-dd HH:mm:ss形式で返す
 */
const createDateString = (time: string): string => {
  return time !== "" ? `${time}:00` : "";
};

/**
 * 特定のフラグがOFFの場合、APIに送信する項目を削除する
 * @param target
 */
const resultFormatNew = (
  target: PostSupportPlanUifIdCarePlanNew
): PostSupportPlanUifIdCarePlanNew => {
  const result = target;
  if (result.housework_assistance_base_type === 0) {
    delete result.housework_assistance_base_type_reason;
    delete result.housework_assistance_base_type_comment;
  }
  if (
    result.housework_assistance_base_type_reason !==
    Number(HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value)
  ) {
    delete result.housework_assistance_base_type_comment;
  }

  result.support_care_plan_schedules.forEach((i) => {
    i.support_care_plan_basic_info.forEach((m) => {
      const value = m;
      if (value.number_of_participants === 1) {
        delete value.practitioner2;
      }
      if (value.practitioner1 === 0) {
        value.practitioner1 = null;
      }
      if (value.practitioner2 === undefined || m.practitioner2 === 0) {
        value.practitioner2 = null;
      }
      return value;
    });
  });

  return result;
};
const resultFormat = (
  target: PostSupportPlanUifIdCarePlanCarePlanId
): PostSupportPlanUifIdCarePlanCarePlanId => {
  const result = target;
  if (result.housework_assistance_base_type === 0) {
    delete result.housework_assistance_base_type_reason;
    delete result.housework_assistance_base_type_comment;
  }
  if (
    result.housework_assistance_base_type_reason !==
    Number(HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value)
  ) {
    delete result.housework_assistance_base_type_comment;
  }
  return result;
};

const infoItem = (
  m: SupportCarePlanBasicInfo
): PostSupportPlanUifIdCarePlanNew["support_care_plan_schedules"][0]["support_care_plan_basic_info"][0] => {
  return {
    day_of_week: m.day_of_week,
    start_time: isEmpty(m.start_time) ? null : createDateString(m.start_time),
    end_time: isEmpty(m.end_time) ? null : createDateString(m.end_time),
    end_time_next_day_flg: Number(m.end_time_next_day_flg),
    number_of_time: isEmpty(m.number_of_time) ? null : m.number_of_time,
    number_of_rides: isEmpty(m.number_of_rides)
      ? null
      : Number(m.number_of_rides),
    number_of_participants: Number(m.number_of_participants),
    practitioner1:
      typeof m.practitioner1 === "number" && m.practitioner1 !== 0
        ? m.practitioner1
        : null,
    practitioner2:
      typeof m.practitioner2 === "number" && m.practitioner2 !== 0
        ? m.practitioner2
        : null
  };
};
const detailsItem = (
  m: SupportCarePlanDetails
): PostSupportPlanUifIdCarePlanNew["support_care_plan_schedules"][0]["support_care_plan_details"][0] => {
  return {
    time_required: m.time_required,
    service_details: m.service_details,
    point: m.point,
    to_do: m.to_do
  };
};
const schedulesNew = (
  values: SupportCarePlanSchedules[]
): PostSupportPlanUifIdCarePlanNew["support_care_plan_schedules"] => {
  const result = values.map((value: SupportCarePlanSchedules) => {
    const statusValue = Number(value.status);
    const statusText = value.status_text;
    const infoValue: PostSupportPlanUifIdCarePlanNew["support_care_plan_schedules"][0]["support_care_plan_basic_info"] = [];
    const detailsValue: PostSupportPlanUifIdCarePlanNew["support_care_plan_schedules"][0]["support_care_plan_details"] = [];
    value.support_care_plan_basic_info.forEach(
      (m: SupportCarePlanBasicInfo) => {
        infoValue.push(infoItem(m));
      }
    );
    value.support_care_plan_details.forEach((m: SupportCarePlanDetails) => {
      detailsValue.push(detailsItem(m));
    });
    return {
      status: statusValue,
      status_text: statusText,
      support_care_plan_basic_info: infoValue,
      support_care_plan_details: detailsValue
    };
  });

  return result;
};

const practitioner = (item: Practitioner): null | number | undefined => {
  if (typeof item === "number") {
    if (item === 0) {
      return undefined;
    }
    return item;
  }
  return null;
};
const schedulesEdit = (
  values: SupportCarePlanSchedules[]
): PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"] => {
  const result = values.map((value: SupportCarePlanSchedules) => {
    const statusValue = Number(value.status);
    const statusText = value.status_text;
    const id = value.support_care_plan_schedule_id;
    const infoValue: PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"][0]["support_care_plan_basic_info"] = [];
    const detailsValue: PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"][0]["support_care_plan_details"] = [];
    value.support_care_plan_basic_info.forEach(
      (m: SupportCarePlanBasicInfo) => {
        if (m.support_care_plan_basic_info_id !== undefined || !m.is_delete) {
          const item = {
            support_care_plan_basic_info_id: m.support_care_plan_basic_info_id,
            day_of_week: m.day_of_week,
            start_time: isEmpty(m.start_time)
              ? null
              : createDateString(m.start_time),
            end_time: isEmpty(m.end_time) ? null : createDateString(m.end_time),
            end_time_next_day_flg: Number(m.end_time_next_day_flg),

            number_of_time: isEmpty(m.number_of_time) ? null : m.number_of_time,
            number_of_rides: isEmpty(m.number_of_rides)
              ? null
              : Number(m.number_of_rides),
            number_of_participants: Number(m.number_of_participants),
            practitioner1: practitioner(m.practitioner1),
            practitioner2: practitioner(m.practitioner2),
            is_delete: m.is_delete ? 1 : 0
          };
          infoValue.push(item);
        }
      }
    );

    value.support_care_plan_details.forEach((m: SupportCarePlanDetails) => {
      if (m.support_care_plan_detail_id !== undefined || !m.is_delete) {
        const item = {
          support_care_plan_detail_id: m.support_care_plan_detail_id,
          time_required: m.time_required,
          service_details: m.service_details,
          point: m.point,
          to_do: m.to_do,
          is_delete: m.is_delete ? 1 : 0
        };
        detailsValue.push(item);
      }
    });

    return id
      ? {
          support_care_plan_schedule_id: id,
          status: statusValue,
          status_text: statusText,
          support_care_plan_basic_info: infoValue,
          support_care_plan_details: detailsValue
        }
      : {
          status: statusValue,
          status_text: statusText,
          support_care_plan_basic_info: infoValue,
          support_care_plan_details: detailsValue
        };
  });

  return result;
};
const schedulesCreate = (
  values: SupportCarePlanSchedules[]
): PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"] => {
  const result = values.map((value: SupportCarePlanSchedules) => {
    const statusValue = Number(value.status);
    const statusText = value.status_text;
    const infoValue: PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"][0]["support_care_plan_basic_info"] = [];
    const detailsValue: PostSupportPlanUifIdCarePlanCarePlanIdDetails["support_care_plan_schedules"][0]["support_care_plan_details"] = [];
    value.support_care_plan_basic_info.forEach(
      (m: SupportCarePlanBasicInfo) => {
        const item = { ...infoItem(m), is_delete: m.is_delete ? 1 : 0 };
        infoValue.push(item);
      }
    );
    value.support_care_plan_details.forEach((m: SupportCarePlanDetails) => {
      detailsValue.push({ ...detailsItem(m), is_delete: m.is_delete ? 1 : 0 });
    });

    return {
      status: statusValue,
      status_text: statusText,
      support_care_plan_basic_info: infoValue,
      support_care_plan_details: detailsValue
    };
  });

  return result;
};

const removeNoChangeData = (
  beforeList: GetSupportPlanUifIdCarePlanCarePlanId["data"],
  afterList: PostSupportPlanUifIdCarePlanCarePlanId
): PostSupportPlanUifIdCarePlanCarePlanId => {
  let diffObject = {} as PostSupportPlanUifIdCarePlanCarePlanId;
  Object.keys(beforeList).forEach((key: string): void => {
    if (beforeList[key] !== afterList[key]) {
      diffObject[key] = afterList[key];
    }
  });
  Object.keys(diffObject).forEach((v) => {
    if (diffObject[v] === undefined) {
      delete diffObject[v];
    }
  });

  if (
    (beforeList.author &&
      beforeList.author.name === beforeList.author.snapshot_name &&
      beforeList.author.id === afterList.author) ||
    (afterList.author !== null && afterList.author === 0)
  ) {
    delete diffObject.author;
  } else {
    diffObject = { ...diffObject, author: afterList.author };
  }

  if (diffObject.support_care_plans_judohomonkaigo) {
    Object.keys(diffObject.support_care_plans_judohomonkaigo).forEach(
      (item) => {
        if (
          diffObject.support_care_plans_judohomonkaigo &&
          beforeList.support_care_plans_judohomonkaigo &&
          item !== "support_care_plans_judohomonkaigo_id" &&
          diffObject.support_care_plans_judohomonkaigo[item] ===
            beforeList.support_care_plans_judohomonkaigo[item]
        ) {
          delete diffObject.support_care_plans_judohomonkaigo[item];
        }
      }
    );
  }
  if (
    diffObject.support_care_plans_judohomonkaigo &&
    Object.keys(diffObject.support_care_plans_judohomonkaigo).length === 1
  ) {
    delete diffObject.support_care_plans_judohomonkaigo;
  }

  if (diffObject.support_care_plans_dokoengo) {
    Object.keys(diffObject.support_care_plans_dokoengo).forEach((item) => {
      if (
        diffObject.support_care_plans_dokoengo &&
        beforeList.support_care_plans_dokoengo &&
        item !== "support_care_plans_dokoengo_id" &&
        diffObject.support_care_plans_dokoengo[item] ===
          beforeList.support_care_plans_dokoengo[item]
      ) {
        delete diffObject.support_care_plans_dokoengo[item];
      }
    });
  }
  if (
    diffObject.support_care_plans_dokoengo &&
    Object.keys(diffObject.support_care_plans_dokoengo).length === 1
  ) {
    delete diffObject.support_care_plans_dokoengo;
  }

  if (diffObject.support_care_plans_kodoengo) {
    Object.keys(diffObject.support_care_plans_kodoengo).forEach((item) => {
      if (
        diffObject.support_care_plans_kodoengo &&
        beforeList.support_care_plans_kodoengo &&
        item !== "support_care_plans_kodoengo_id" &&
        diffObject.support_care_plans_kodoengo[item] ===
          beforeList.support_care_plans_kodoengo[item]
      ) {
        delete diffObject.support_care_plans_kodoengo[item];
      }
    });
  }
  if (
    diffObject.support_care_plans_kodoengo &&
    Object.keys(diffObject.support_care_plans_kodoengo).length === 1
  ) {
    delete diffObject.support_care_plans_kodoengo;
  }

  return diffObject;
};

/**
 * フォームの結果をAPIに送れる形式に整形
 * @param values
 */
export const normalizeFormValuesToPostSupportPlanUifIdCarePlanNew = (
  values: RecordCarePlan
): PostSupportPlanUifIdCarePlanNew => {
  const target: PostSupportPlanUifIdCarePlanNew = {
    target_date: format(
      selectDateValueToDate(values.target_date),
      "YYYY-MM-DD"
    ),
    postal_code: isEmpty(values.postal_code) ? null : values.postal_code,
    city_id:
      values.city_id !== "NOT_SELECTED" && values.city_id !== ""
        ? castStringToNumber(values.city_id)
        : null,
    prefecture_name:
      values.prefecture_name !== "NOT_SELECTED" && values.prefecture_name !== ""
        ? values.prefecture_name
        : null,
    address: isEmpty(values.address) ? null : values.address,
    tel: isEmpty(values.tel) ? null : values.tel,
    classify_disability_support: castStringToNumber(
      values.classify_disability_support
    ),
    date_pay_begin: selectDateValueToDatePaddingZero(values.date_pay_begin),
    date_pay_end: selectDateValueToDatePaddingZero(values.date_pay_end),
    date_begin_in_service: selectDateValueToDatePaddingZero(
      values.date_begin_in_service
    ),
    pay_months_agreed_physical_care: castStringToNumber(
      values.pay_months_agreed_physical_care
    ),
    pay_months_agreed_outpatient_care_with_physical_care: castStringToNumber(
      values.pay_months_agreed_outpatient_care_with_physical_care
    ),
    pay_months_agreed_housework_assistance: castStringToNumber(
      values.pay_months_agreed_housework_assistance
    ),
    pay_months_agreed_outpatient_care: castStringToNumber(
      values.pay_months_agreed_outpatient_care
    ),
    pay_months_agreed_getting_on_and_off: castStringToNumber(
      values.pay_months_agreed_getting_on_and_off
    ),
    housework_assistance_base_type: castCheckBoxToNumber(
      values.housework_assistance_base_type
    ),
    housework_assistance_base_type_reason: isEmpty(
      values.housework_assistance_base_type_reason
    )
      ? null
      : castStringToNumber(values.housework_assistance_base_type_reason),
    housework_assistance_base_type_comment: isEmpty(
      values.housework_assistance_base_type_comment
    )
      ? null
      : values.housework_assistance_base_type_comment,
    usage_other_facility: isEmpty(values.usage_other_facility)
      ? null
      : values.usage_other_facility,
    user_request: isEmpty(values.user_request) ? null : values.user_request,
    user_issue: isEmpty(values.user_issue) ? null : values.user_issue,
    goals_direction_assistance: isEmpty(values.goals_direction_assistance)
      ? null
      : values.goals_direction_assistance,
    remarks: isEmpty(values.remarks) ? null : values.remarks,

    staff_comment: isEmpty(values.staff_comment) ? null : values.staff_comment,
    support_care_plan_schedules: schedulesNew(
      values.support_care_plan_schedules
    ),
    author: Number(values.author) || null,
    support_care_plans_judohomonkaigo: {
      pay_months_agreed_severe_home_visit_care: castStringToNumber(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_severe_home_visit_care
      ),
      pay_months_agreed_guide_severe_home_visit_care: castStringToNumber(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_guide_severe_home_visit_care
      )
    },
    support_care_plans_dokoengo: {
      pay_months_agreed_accompanying_support: castStringToNumber(
        values.support_care_plans_dokoengo
          .pay_months_agreed_accompanying_support
      )
    },
    support_care_plans_kodoengo: {
      pay_months_agreed_accompanying_support: castStringToNumber(
        values.support_care_plans_kodoengo
          .pay_months_agreed_accompanying_support
      )
    }
  };

  return resultFormatNew(target);
};
export const normalizeFormValuesToPostSupportPlanUifIdCarePlanCarePlanId = (
  values: RecordCarePlan,
  gotValue: GetSupportPlanUifIdCarePlanCarePlanId["data"]
): PostSupportPlanUifIdCarePlanCarePlanId => {
  const target: PostSupportPlanUifIdCarePlanCarePlanId = {
    target_date: format(
      selectDateValueToDate(values.target_date),
      "YYYY-MM-DD"
    ),
    postal_code: isEmpty(values.postal_code) ? null : values.postal_code,
    city_id:
      values.city_id !== "NOT_SELECTED" && values.city_id !== ""
        ? castStringToNumber(values.city_id)
        : null,
    prefecture_name:
      values.prefecture_name !== "NOT_SELECTED" && values.prefecture_name !== ""
        ? values.prefecture_name
        : null,
    address: isEmpty(values.address) ? null : values.address,
    tel: isEmpty(values.tel) ? null : values.tel,
    classify_disability_support: castStringToNumber(
      values.classify_disability_support
    ),
    date_pay_begin: selectDateValueToDatePaddingZero(values.date_pay_begin),
    date_pay_end: selectDateValueToDatePaddingZero(values.date_pay_end),
    date_begin_in_service: selectDateValueToDatePaddingZero(
      values.date_begin_in_service
    ),
    pay_months_agreed_physical_care: castStringToNumber(
      values.pay_months_agreed_physical_care
    ),
    pay_months_agreed_outpatient_care_with_physical_care: castStringToNumber(
      values.pay_months_agreed_outpatient_care_with_physical_care
    ),
    pay_months_agreed_housework_assistance: castStringToNumber(
      values.pay_months_agreed_housework_assistance
    ),
    pay_months_agreed_outpatient_care: castStringToNumber(
      values.pay_months_agreed_outpatient_care
    ),
    pay_months_agreed_getting_on_and_off: castStringToNumber(
      values.pay_months_agreed_getting_on_and_off
    ),
    housework_assistance_base_type: castCheckBoxToNumber(
      values.housework_assistance_base_type
    ),
    housework_assistance_base_type_reason: isEmpty(
      values.housework_assistance_base_type_reason
    )
      ? null
      : stringToNumber(values.housework_assistance_base_type_reason),
    housework_assistance_base_type_comment: isEmpty(
      values.housework_assistance_base_type_comment
    )
      ? null
      : values.housework_assistance_base_type_comment,
    usage_other_facility: isEmpty(values.usage_other_facility)
      ? null
      : values.usage_other_facility,
    user_request: isEmpty(values.user_request) ? null : values.user_request,
    user_issue: isEmpty(values.user_issue) ? null : values.user_issue,
    goals_direction_assistance: isEmpty(values.goals_direction_assistance)
      ? null
      : values.goals_direction_assistance,
    remarks: isEmpty(values.remarks) ? null : values.remarks,
    staff_comment: isEmpty(values.staff_comment) ? null : values.staff_comment,
    author: values.author === "" ? null : Number(values.author),
    support_care_plans_judohomonkaigo: {
      support_care_plans_judohomonkaigo_id: Number(
        values.support_care_plans_judohomonkaigo
          .support_care_plans_judohomonkaigo_id
      ),
      pay_months_agreed_severe_home_visit_care: castStringToNumber(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_severe_home_visit_care
      ),
      pay_months_agreed_guide_severe_home_visit_care: castStringToNumber(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_guide_severe_home_visit_care
      )
    },
    support_care_plans_dokoengo: {
      support_care_plans_dokoengo_id: Number(
        values.support_care_plans_dokoengo.support_care_plans_dokoengo_id
      ),
      pay_months_agreed_accompanying_support: castStringToNumber(
        values.support_care_plans_dokoengo
          .pay_months_agreed_accompanying_support
      )
    },
    support_care_plans_kodoengo: {
      support_care_plans_kodoengo_id: Number(
        values.support_care_plans_kodoengo.support_care_plans_kodoengo_id
      ),
      pay_months_agreed_accompanying_support: castStringToNumber(
        values.support_care_plans_kodoengo
          .pay_months_agreed_accompanying_support
      )
    }
  };
  return removeNoChangeData(gotValue, resultFormat(target));
};
export const normalizeFormValuesTopostSupportPlanUifIdCarePlanCarePlanIdDetails = (
  values: SupportCarePlanSchedules[],
  isNew: boolean
): PostSupportPlanUifIdCarePlanCarePlanIdDetails => {
  const target = {
    support_care_plan_schedules: isNew
      ? schedulesCreate(values)
      : schedulesEdit(values)
  };

  return target;
};
