import * as types from "./types";
import { GetUsersSummaryCustomRecords } from "@api/requests/supports/getUsersSummaryCustomRecords";
import { ResponseErrorState } from "@stores/ui/responseError/types";

export const fetchUsersSummarySHISETSUNYUSHOStarted = () =>
  ({ type: types.FETCH_USERS_SUMMARY_SHISETSUNYUSHO_STARTED } as const);

export const fetchUsersSummarySHISETSUNYUSHOSuccess = (
  res: GetUsersSummaryCustomRecords["data"]
) =>
  ({
    type: types.FETCH_USERS_SUMMARY_SHISETSUNYUSHO_SUCCESS,
    payload: res
  } as const);

export const fetchUsersSummarySHISETSUNYUSHOFailed = (err: {
  error: { response: ResponseErrorState };
}) =>
  ({
    type: types.FETCH_USERS_SUMMARY_SHISETSUNYUSHO_FAILED,
    error: err
  } as const);

export const unsetUsersSummarySHISETSUNYUSHO = () =>
  ({ type: types.UNSET_USERS_SUMMARY_SHISETSUNYUSHO } as const);

export type ActionTypes =
  | ReturnType<typeof fetchUsersSummarySHISETSUNYUSHOStarted>
  | ReturnType<typeof fetchUsersSummarySHISETSUNYUSHOSuccess>
  | ReturnType<typeof fetchUsersSummarySHISETSUNYUSHOFailed>
  | ReturnType<typeof unsetUsersSummarySHISETSUNYUSHO>;
