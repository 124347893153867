import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";
import {
  InoutResultsItem,
  InoutResultsDisplayList,
  InoutResultsFacilityUnitsItem,
  InoutResultsStaffsInFacilityItem
} from "./getInOutResultsDaily";

/**
 * 利用実績一覧(ユーザごと)APIresponse
 */
export type GetInOutResultsUsersResponse = {
  data: {
    inout_results: InoutResultsItem[];
    facility_units: InoutResultsFacilityUnitsItem[];
    staffs_in_facility: InoutResultsStaffsInFacilityItem[];
    summary: {
      physicalCareCount: number;
      outpatientCareWithPhysicalCareCount: number;
      houseworkAssistanceCount: number;
      outpatientCareCount: number;
      gettingOnAndOffCount: number;
      guideHoursCount: number;
      accompanySupportCount: number;
      serviceHoursCount: number;
      pay_times_individual_support: number;
      pay_months_individual_support: number;
      pay_times_group_support: number;
      pay_months_group_support: number;
      pay_times_vehicle_transfer_support: number;
      pay_months_vehicle_transfer_support: number;
      pay_times_school_and_hospital_commuting_support: number;
      pay_months_school_and_hospital_commuting_support: number;
      pay_times_other_support: number;
      pay_months_other_support: number;
    };
    number_of_records: number;
    display_list: InoutResultsDisplayList[];
  };
};

/**
 * 利用実績一覧(ユーザごと)を取得する
 * @param ufid ユーザID
 * @param date YYYYMMDD
 */
export const getInOutResultsMonthly = async (
  ufid: number,
  date: string
): Promise<AxiosResponse<GetInOutResultsUsersResponse>> => {
  const url = `${VERSION_URL_202404}/inout_results/monthly/${ufid}/${date}`;
  return request.get<GetInOutResultsUsersResponse>(url);
};
