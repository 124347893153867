import * as types from "./types";
import { GetSupportPlanUifIdSupportPlanSheets } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheets";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};
// fetchSupportPlanUifIdSupportPlanSheets
export const fetchSupportPlanUifIdSupportPlanSheetsStarted = () =>
  ({ type: types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_STARTED } as const);
export const fetchSupportPlanUifIdSupportPlanSheetsSuccess = (
  res: GetSupportPlanUifIdSupportPlanSheets["data"]
) =>
  ({
    type: types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUCCESS,
    payload: res
  } as const);
export const fetchSupportPlanUifIdSupportPlanSheetsFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_FAILED,
    error: err
  } as const);

// fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId
export const fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted = () =>
  ({
    type:
      types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED
  } as const);
export const fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess = (
  res: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"]
) =>
  ({
    type:
      types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS,
    payload: res
  } as const);
export const fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed = (
  err: ErrorResponse
) =>
  ({
    type:
      types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED,
    error: err
  } as const);

// Edit
export const setEdit = () => ({ type: types.SET_EDIT } as const);
export const unsetEdit = () => ({ type: types.UNSET_EDIT } as const);

// postSupportPlanUifIdSupportPlanSheets
export const postSupportPlanUifIdSupportPlanSheetsStarted = () =>
  ({ type: types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_STARTED } as const);
export const postSupportPlanUifIdSupportPlanSheetsSuccess = () =>
  ({
    type: types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_SUCCESS
  } as const);
export const postSupportPlanUifIdSupportPlanSheetsFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_FAILED,
    error: err
  } as const);

// deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId
export const deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted = () =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED
  } as const);
export const deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess = (
  supportPlanSheetId: string
) =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS,
    supportPlanSheetId
  } as const);
export const deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed = (
  err: ErrorResponse
) =>
  ({
    type:
      types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportPlanUifIdSupportPlanSheetsStarted>
  | ReturnType<typeof fetchSupportPlanUifIdSupportPlanSheetsSuccess>
  | ReturnType<typeof fetchSupportPlanUifIdSupportPlanSheetsFailed>
  | ReturnType<
      typeof fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted
    >
  | ReturnType<
      typeof fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess
    >
  | ReturnType<
      typeof fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed
    >
  | ReturnType<typeof setEdit>
  | ReturnType<typeof unsetEdit>
  | ReturnType<typeof postSupportPlanUifIdSupportPlanSheetsStarted>
  | ReturnType<typeof postSupportPlanUifIdSupportPlanSheetsSuccess>
  | ReturnType<typeof postSupportPlanUifIdSupportPlanSheetsFailed>
  | ReturnType<
      typeof deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted
    >
  | ReturnType<
      typeof deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess
    >
  | ReturnType<
      typeof deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed
    >;
