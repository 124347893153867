import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import { FormikProps, FieldArray } from "formik";

import {
  Assessment,
  lifeHistoryInitialValue,
  disabilityHistoryInitialValue,
  flowDaysInitialValue
} from "@initialize/record/assessment/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  FacilityType,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE
} from "@constants/variables";
import {
  getCategoryName,
  checkItemIsLifeHistories,
  checkItemIsDisabilityHistories,
  checkItemIsFlowDays,
  checkCategoryIsSituation,
  checkItemIsChiikiikoOpinions
} from "@utils/domain/mgr/Cseg/assessment/variableFromFacility";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { AssessmentSheetFormHistory } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentSheetFormHIstory";
import { AssessmentSheetFormFlowDays } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentSheetFormFlowDays";
import { AssessmentSheetFormChiikiikoOpinions } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentSheetFormChiikiikoOpinions";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    categoryType: {
      color: "#37474f",
      padding: "48px 0 5px 0",
      borderBottom: "1px solid #37474f"
    },
    category: {
      width: "100%",
      height: 34,
      backgroundColor: "#f5f5f5",
      marginTop: 32,
      paddingLeft: 8,
      lineHeight: "34px"
    },
    // 複数テキスト表示
    multiTextArea: {
      marginTop: 32,
      width: "100%"
    },
    multiTextName: {
      fontSize: 14,
      lineHeight: "24px"
    },
    multiTextInputArea: {
      display: "flex",
      flexDirection: "column",
      marginTop: 8,
      marginLeft: 16,
      gap: "32px"
    },
    // 生活歴、障害・疾病歴表示
    historyCardList: {
      display: "flex",
      flexDirection: "column",
      marginTop: 32,
      gap: "8px"
    },
    // １日の流れ
    flowDaysLabel: {
      fontSize: "14px",
      marginBottom: 0,
      marginTop: 32
    },
    flowDaysArea: {
      display: "flex",
      flexDirection: "column",
      marginTop: 8,
      gap: "8px"
    },
    historyCardAddButton: {
      marginTop: 24
    },
    buttonIcon: {
      marginRight: 8
    },
    noItem: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      margin: "8px 6px 24px 17px"
    }
  });

type OwnProps = {
  categoryType: number;
  formValues: Assessment;
  facilityType: FacilityType;
  formikProps: FormikProps<Assessment>;
  isEditing: boolean;
  customRecords: CustomRecordsWithCategoryState;
  typeConsultation: number | null;
  facilitySubType: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

const NoItem = ({ classes }: WithStyles<typeof styles>): JSX.Element => (
  <div className={classes.noItem}>
    このカテゴリー内の項目は全て非表示に設定されています。アセスメントの設定画面を確認してください。
  </div>
);

const checkIsDisplayTitle = (
  targetCategoryType: number,
  targetFacilityType: FacilityType
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.CHIIKIIKO:
      return (
        targetCategoryType !==
        CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information
      );
    case FacilityType.CHIIKITEICHAKU:
      return (
        targetCategoryType !==
        CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information
      );
    default:
      return true;
  }
};

/** 見出し名を表示するカテゴリかチェック */
const checkIsNeedCategoryName = (
  targetCategoryType: number,
  targetFacilityType: FacilityType
): boolean => {
  switch (targetFacilityType) {
    case FacilityType.KEIKAKUSODAN:
      return (
        targetCategoryType !==
        KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation
      );
    case FacilityType.CHIIKIIKO:
      return (
        targetCategoryType !==
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation &&
        targetCategoryType !==
          CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information
      );
    case FacilityType.CHIIKITEICHAKU:
      return (
        targetCategoryType !==
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation &&
        targetCategoryType !==
          CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information
      );
    default:
      return true;
  }
};

const AssessmentSheetFormCore = (props: Props): JSX.Element => {
  const {
    categoryType,
    formValues,
    facilityType,
    formikProps,
    isEditing,
    customRecords,
    typeConsultation,
    facilitySubType,
    classes
  } = props;

  const titleName = getCategoryName(categoryType, facilityType);
  const historiesMaxLength = 20;
  const flowDaysMaxLength = 10;

  const isDisplayTitle = checkIsDisplayTitle(categoryType, facilityType);
  const isNeedCategoryName = checkIsNeedCategoryName(
    categoryType,
    facilityType
  );

  const getItemName = (
    idxs: { categoryIdx: number; inputIdx: number },
    name: string
  ): string => {
    const { categoryIdx, inputIdx } = idxs;
    return `assessment_records[${categoryIdx}]['input'][${inputIdx}]['${name}']`;
  };

  const getInput = (idxs: {
    categoryIdx: number;
    inputIdx: number;
  }): Assessment["assessment_records"][number]["input"][number] => {
    const { categoryIdx, inputIdx } = idxs;
    return formValues.assessment_records[categoryIdx].input[inputIdx];
  };

  const getIdx = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): { categoryIdx: number; inputIdx: number } => {
    const categoryIdx = formValues.assessment_records.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx === -1) {
      return { categoryIdx, inputIdx: -1 };
    }
    const inputIdx = formValues.assessment_records[categoryIdx].input.findIndex(
      (i) => i.custom_record_item_id === item.id
    );
    return { categoryIdx, inputIdx };
  };

  const getOptions = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): { label: string; value: string }[] => {
    return item.custom_record_item_choices.map((choice) => {
      return { label: choice.name, value: `${choice.id}` };
    });
  };

  const getKeikakuVisibility = (
    record: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): boolean => {
    if (record.custom_records_keikakusodan_visibility) {
      if (facilitySubType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) {
        if (typeConsultation === TYPE_CONSULTATION_KEIKAKUSODAN) {
          if (item && item.custom_records_keikakusodan_visibility) {
            return (
              numberToBoolean(
                record.custom_records_keikakusodan_visibility.plan_visibility
              ) &&
              numberToBoolean(
                item.custom_records_keikakusodan_visibility.plan_visibility
              )
            );
          }
          return numberToBoolean(
            record.custom_records_keikakusodan_visibility.plan_visibility
          );
        }
        if (typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
          if (item && item.custom_records_keikakusodan_visibility) {
            return (
              numberToBoolean(
                record.custom_records_keikakusodan_visibility.child_visibility
              ) &&
              numberToBoolean(
                item.custom_records_keikakusodan_visibility.child_visibility
              )
            );
          }
          return numberToBoolean(
            record.custom_records_keikakusodan_visibility.child_visibility
          );
        }
      } else if (facilitySubType === FACILITY_TYPE_KEIKAKUSODAN) {
        if (item && item.custom_records_keikakusodan_visibility) {
          return (
            numberToBoolean(
              record.custom_records_keikakusodan_visibility.plan_visibility
            ) &&
            numberToBoolean(
              item.custom_records_keikakusodan_visibility.plan_visibility
            )
          );
        }
        return numberToBoolean(
          record.custom_records_keikakusodan_visibility.plan_visibility
        );
      } else if (facilitySubType === FACILITY_TYPE_SHOGAIJISODAN) {
        if (item && item.custom_records_keikakusodan_visibility) {
          return (
            numberToBoolean(
              record.custom_records_keikakusodan_visibility.child_visibility
            ) &&
            numberToBoolean(
              item.custom_records_keikakusodan_visibility.child_visibility
            )
          );
        }
        return numberToBoolean(
          record.custom_records_keikakusodan_visibility.child_visibility
        );
      }
    }
    return false;
  };

  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .sort((a, b) => {
      // 並べ替え不可項目は先頭に移動させ、default category で並び替えを行う
      if (!a.allow_change_order && b.allow_change_order) return -1;
      if (!b.allow_change_order && a.allow_change_order) return 1;
      if (
        !a.allow_change_order &&
        !b.allow_change_order &&
        a.default_category &&
        b.default_category
      ) {
        return a.default_category < b.default_category ? -1 : 1;
      }
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    })
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          const input = getInput(getIdx(i));
          return (
            (facilityType === FacilityType.KEIKAKUSODAN
              ? getKeikakuVisibility(r, i)
              : r.visibility && i.visibility) ||
            input.input_data ||
            !!input.choiced_item_id ||
            (input.multiTexts &&
              Object.values(input.multiTexts).some((v) => v.value)) ||
            input.life_histories ||
            input.disability_histories ||
            input.flow_days
          );
        })
        .sort((a, b) => {
          // 並べ替え不可項目は先頭に移動させ、default item で並び替えを行う
          if (!a.allow_change_order && b.allow_change_order) return -1;
          if (!b.allow_change_order && a.allow_change_order) return 1;
          if (
            !a.allow_change_order &&
            !b.allow_change_order &&
            a.default_item &&
            b.default_item
          ) {
            return a.default_item < b.default_item ? -1 : 1;
          }
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter(
      (r) =>
        (facilityType === FacilityType.KEIKAKUSODAN
          ? getKeikakuVisibility(r)
          : r.visibility) || r.custom_record_items.length
    );

  const isMultiTextCategory = checkCategoryIsSituation(
    facilityType,
    categoryType
  );

  /** 通常テキスト */
  const itemsTextBody = (
    items: CustomRecordsWithCategoryState[number]["custom_record_items"],
    categoryId: number
  ): JSX.Element[] => {
    if (!items.length) {
      return [<NoItem classes={classes} key={`noItem-${categoryId}`} />];
    }

    return items.map((item) => {
      const idxs = getIdx(item);
      const input = getInput(idxs);

      const isLifeHistory = checkItemIsLifeHistories(
        facilityType,
        item.default_item
      );
      const isDisabilityHistory = checkItemIsDisabilityHistories(
        facilityType,
        item.default_item
      );
      const isFlowDays = checkItemIsFlowDays(facilityType, item.default_item);
      const isChiikiikoOpinions = checkItemIsChiikiikoOpinions(
        facilityType,
        item.default_item
      );

      // 生活歴
      if (isLifeHistory) {
        const lifeHistoryName = getItemName(idxs, "life_histories");
        let visibleLifeHistoryLength = 0;
        return (
          <FieldArray name={lifeHistoryName} key={`field-text-${item.id}`}>
            {(arrayHelpers): JSX.Element => {
              const handleClickAddLifeHistories = (): void => {
                arrayHelpers.push(lifeHistoryInitialValue());
              };
              const handleClickDeleteLifeHistories = (index: number): void => {
                if (input.life_histories) {
                  const lifeHistories = Array.from(input.life_histories);
                  // APIから取得した値の場合 isDeleteフラグで削除
                  if (lifeHistories[index].id) {
                    lifeHistories[index].isDelete = 1;
                  } else {
                    lifeHistories.splice(index, 1);
                  }
                  formikProps.setFieldValue(lifeHistoryName, lifeHistories);
                }
              };
              return (
                <>
                  <div className={classes.historyCardList}>
                    {input.life_histories &&
                      input.life_histories.map(
                        (lifeHistory, lifeHistoryIndex) => {
                          if (!lifeHistory.isDelete) {
                            visibleLifeHistoryLength += 1;
                            const key = `life-history-${lifeHistoryIndex}`;
                            return (
                              <AssessmentSheetFormHistory
                                key={key}
                                label={`生活歴${visibleLifeHistoryLength}`}
                                itemName={`${lifeHistoryName}[${lifeHistoryIndex}]`}
                                isEditing={isEditing}
                                inputYearMonth={lifeHistory.yyyymm}
                                inputMatter={lifeHistory.matter}
                                onClickDelete={(): void => {
                                  handleClickDeleteLifeHistories(
                                    lifeHistoryIndex
                                  );
                                }}
                              />
                            );
                          }
                          return null;
                        }
                      )}
                  </div>
                  {isEditing && (
                    <KnowbeButton
                      kind="iconText"
                      className={classes.historyCardAddButton}
                      onClick={handleClickAddLifeHistories}
                      disabled={visibleLifeHistoryLength >= historiesMaxLength}
                    >
                      <AddIcon
                        color={
                          visibleLifeHistoryLength >= historiesMaxLength
                            ? "disabled"
                            : "secondary"
                        }
                        className={classes.buttonIcon}
                      />
                      生活歴を追加する
                    </KnowbeButton>
                  )}
                </>
              );
            }}
          </FieldArray>
        );
      }

      // 障害・疾病歴
      if (isDisabilityHistory) {
        const disabilityHistoryName = getItemName(idxs, "disability_histories");
        let visibleDisabilityHistoryLength = 0;
        return (
          <FieldArray
            name={disabilityHistoryName}
            key={`field-text-${item.id}`}
          >
            {(arrayHelpers): JSX.Element => {
              const handleClickAddDisabilityHistories = (): void => {
                arrayHelpers.push(disabilityHistoryInitialValue());
              };
              const handleClickDeleteDisabilityHistories = (
                index: number
              ): void => {
                if (input.disability_histories) {
                  const disabilityHistories = Array.from(
                    input.disability_histories
                  );
                  // APIから取得した値の場合 isDeleteフラグで削除
                  if (disabilityHistories[index].id) {
                    disabilityHistories[index].isDelete = 1;
                  } else {
                    disabilityHistories.splice(index, 1);
                  }
                  formikProps.setFieldValue(
                    disabilityHistoryName,
                    disabilityHistories
                  );
                }
              };
              return (
                <>
                  <div className={classes.historyCardList}>
                    {input.disability_histories &&
                      input.disability_histories.map(
                        (disabilityHistory, disabilityHistoryIndex) => {
                          if (!disabilityHistory.isDelete) {
                            visibleDisabilityHistoryLength += 1;
                            const key = `disability-history-${disabilityHistoryIndex}`;
                            return (
                              <AssessmentSheetFormHistory
                                key={key}
                                label={`障害・疾病歴${visibleDisabilityHistoryLength}`}
                                itemName={`${disabilityHistoryName}[${disabilityHistoryIndex}]`}
                                isEditing={isEditing}
                                inputYearMonth={disabilityHistory.yyyymm}
                                inputMatter={disabilityHistory.matter}
                                onClickDelete={(): void => {
                                  handleClickDeleteDisabilityHistories(
                                    disabilityHistoryIndex
                                  );
                                }}
                              />
                            );
                          }
                          return null;
                        }
                      )}
                  </div>
                  {isEditing && (
                    <KnowbeButton
                      kind="iconText"
                      className={classes.historyCardAddButton}
                      onClick={handleClickAddDisabilityHistories}
                      disabled={
                        visibleDisabilityHistoryLength >= historiesMaxLength
                      }
                    >
                      <AddIcon
                        color={
                          visibleDisabilityHistoryLength >= historiesMaxLength
                            ? "disabled"
                            : "secondary"
                        }
                        className={classes.buttonIcon}
                      />
                      障害・疾病歴を追加する
                    </KnowbeButton>
                  )}
                </>
              );
            }}
          </FieldArray>
        );
      }

      // 1日の流れ
      if (isFlowDays) {
        const flowDaysName = getItemName(idxs, "flow_days");
        let visibleFlowDaysLength = 0;
        return (
          <div key={`field-text-${item.id}`}>
            <p className={classes.flowDaysLabel}>{item.name}</p>
            <FieldArray name={flowDaysName}>
              {(arrayHelpers): JSX.Element => {
                const handleClickAddFlowDays = (): void => {
                  arrayHelpers.push(flowDaysInitialValue());
                };
                const handleClickDeleteFlowDays = (index: number): void => {
                  if (!input.flow_days) return;
                  const flowDays = Array.from(input.flow_days);
                  if (flowDays[index].id) {
                    flowDays[index].isDelete = 1;
                  } else {
                    flowDays.splice(index, 1);
                  }
                  formikProps.setFieldValue(flowDaysName, flowDays);
                };
                return (
                  <div className={classes.flowDaysArea}>
                    {input.flow_days &&
                      input.flow_days.map((flowDay, flowDayIndex) => {
                        if (flowDay.isDelete) return null;
                        const key = `flow-day-${flowDayIndex}`;
                        visibleFlowDaysLength += 1;
                        return (
                          <AssessmentSheetFormFlowDays
                            key={key}
                            itemName={`${flowDaysName}[${flowDayIndex}]`}
                            isEditing={isEditing}
                            inputFlowDays={flowDay}
                            formikProps={formikProps}
                            onClickDeleteButton={(): void =>
                              handleClickDeleteFlowDays(flowDayIndex)
                            }
                          />
                        );
                      })}
                    {isEditing && (
                      <div>
                        <KnowbeButton
                          kind="iconText"
                          className={classes.historyCardAddButton}
                          onClick={handleClickAddFlowDays}
                          disabled={visibleFlowDaysLength >= flowDaysMaxLength}
                        >
                          <AddIcon
                            color={
                              visibleFlowDaysLength >= flowDaysMaxLength
                                ? "disabled"
                                : "secondary"
                            }
                            className={classes.buttonIcon}
                          />
                          1日の流れを追加する
                        </KnowbeButton>
                      </div>
                    )}
                  </div>
                );
              }}
            </FieldArray>
          </div>
        );
      }

      if (isChiikiikoOpinions) {
        const options = getOptions(item);

        return (
          <AssessmentSheetFormChiikiikoOpinions
            key={`field-text-${item.id}`}
            choiceName={getItemName(idxs, "choiced_item_id")}
            choiceValue={input.choiced_item_id || ""}
            options={options}
            textName={getItemName(idxs, "input_data")}
            textValue={input.input_data}
            formikProps={formikProps}
            isEditing={isEditing}
          />
        );
      }

      return (
        <RecordTextField
          key={`field-text-${item.id}`}
          label={item.name}
          name={getItemName(idxs, "input_data")}
          labelType="default"
          defaultValue=""
          placeholder=""
          value={input.input_data}
          isEditable={isEditing}
          style={{ marginTop: 32, marginBottom: 0, width: "100%" }}
        />
      );
    });
  };

  /** 複数テキスト表示 */
  const itemsMultiTextBody = (
    items: CustomRecordsWithCategoryState[number]["custom_record_items"],
    categoryId: number
  ): JSX.Element | JSX.Element[] => {
    if (!items.length) {
      return <NoItem classes={classes} key={`noItemMultiText-${categoryId}`} />;
    }

    return items.map((item) => {
      const idxs = getIdx(item);
      const options = getOptions(item);
      const input = getInput(idxs);

      return (
        <div className={classes.multiTextArea} key={`item-${item.id}`}>
          <div className={classes.multiTextName}>{item.name}</div>
          <div className={classes.multiTextInputArea}>
            {options.map((option, optionIndex) => {
              const key = `field-${item.id}-${optionIndex}`;
              return (
                <RecordTextField
                  key={key}
                  label={option.label}
                  name={`${getItemName(idxs, "multiTexts")}[${
                    option.value
                  }].value`}
                  labelType="default"
                  defaultValue=""
                  placeholder=""
                  value={
                    input.multiTexts ? input.multiTexts[option.value].value : ""
                  }
                  isEditable={isEditing}
                  style={{ marginBottom: 0, width: "100%" }}
                />
              );
            })}
          </div>
        </div>
      );
    });
  };

  if (!sortedCustomRecords.length) {
    return <></>;
  }

  return (
    <>
      {isDisplayTitle ? (
        <div className={classes.categoryType}>{titleName}</div>
      ) : null}
      {sortedCustomRecords.map((category) => (
        <div key={`categoryContainer-${category.id}`}>
          {isNeedCategoryName ? (
            <div className={classes.category}>{category.name}</div>
          ) : null}
          {/* 複数テキスト */}
          {isMultiTextCategory &&
            itemsMultiTextBody(category.custom_record_items, category.id)}
          {/* 通常のテキスト */}
          {!isMultiTextCategory &&
            itemsTextBody(category.custom_record_items, category.id)}
        </div>
      ))}
    </>
  );
};

export const AssessmentSheetForm = withStyles(styles)(AssessmentSheetFormCore);
