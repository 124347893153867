import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Formik, Form, FormikActions } from "formik";
import { oneLetterWeekdaysJapanese } from "@utils/date";
import { KODOENGOReportTypeInterface } from "@stores/v202104/domain/mgr/KODOENGO/report/types";
import { InitialDataValues } from "@initialize/v202104/mgr/KODOENGO/report/initialValues";
import { toEffectiveObject } from "@utils/object";
import dispatches from "@stores/dispatches";
import {
  validation,
  submitValidation
} from "@initialize/v202104/mgr/KODOENGO/report/validation";
import { InOutReportDialogSubmit } from "@components/v202104/organisms/mgr/KODOENGO/report/dialog/InOutReportDialogSubmit";
import { InOutReportDialogFields } from "@components/v202104/organisms/mgr/KODOENGO/report/dialog/InOutReportDialogFields";
import { InOutReportFormPaper } from "@components/v202104/organisms/mgr/KODOENGO/report/dialog/InOutReportFormPaper";
import { FacilityState } from "@stores/v202104/domain/mgr/KODOENGO/facility/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KODOENGO/userInFacility/types";
import { AppState } from "@stores/type";
import { InitialValues } from "@interfaces/v202104/mgr/KODOENGO/report/initial";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";
import { ResponseError } from "@stores/ui/type";
import { INOUT_RESULTS_ERROR_DUPLICATED } from "@constants/mgr/KODOENGO/variables";

const styles = (): StyleRules =>
  createStyles({
    action: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      marginBottom: 0,
      marginTop: 0,
      "& > :first-child": {
        paddingBottom: 8
      }
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between" as "space-between"
    },
    modalHeaderDate: {
      fontSize: 16
    },
    modal: {
      width: "80%",
      maxWidth: 616
    },
    modalContents: {
      paddingTop: 24,
      paddingRight: 0,
      paddingBottom: 20,
      paddingLeft: 0
    },
    modalHeaderArea: {
      height: "15%",
      padding: "12px 32px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: 20,
      backgroundColor: "#f5f5f5"
    },
    formArea: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    dialogActionButton: {
      width: 138,
      color: "#b00020"
    }
  });

type DispatchProps = {
  postKODOENGOReport: (
    facility: FacilityState,
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KODOENGOReportTypeInterface["type"]
  ) => Promise<void>;
  responseErrorClear(): void;
};

type StateProps = {
  facilityState: FacilityState;
  responseError: ResponseError;
};

type OwnProps = {
  labelId?: string;
  open: boolean;
  usersInFacilityState: UsersInFacilityState;
  data: InitialDataValues;
  selectedDate: Date;
  type: KODOENGOReportTypeInterface["type"];
  onCancel: () => void;
  onSubmit: () => void;
  modalTarget: string;
};

type State = {
  isSubmitDisabled: boolean;
  isOpenApiErrorDialog: boolean;
};

type Props = OwnProps & DispatchProps & StateProps & WithStyles<typeof styles>;

class InOutReportDialogCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitDisabled: true,
      isOpenApiErrorDialog: false
    };
  }

  private validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(
      values,
      this.props.usersInFacilityState
    );
    this.setState({
      isSubmitDisabled: submitValidation(validationResult)
    });
    const errorCheckResult = toEffectiveObject(validationResult);

    // エラーが存在するかは、toEffectiveObjectで確認
    // エラーが存在する場合は、validationの結果をそのまま返却
    // →FieldArrayを利用する場合、エラーは配列として保持する必要があるため
    // →また、エラー配列と表示データの配列はindexを一致させる必要がある
    // エラーが存在しない場合は、undefinedを返却（errorObjectがundefined）
    return errorCheckResult ? validationResult : errorCheckResult;
  };

  private onCloseDialog = (): void => {
    this.setState({ isOpenApiErrorDialog: false });
  };

  private onCancel = (): void => {
    this.setState({ isSubmitDisabled: true });
    this.props.onCancel();
  };

  private onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await this.props
      .postKODOENGOReport(
        this.props.facilityState,
        this.props.data,
        values,
        this.props.type
      )
      .then(() => {
        const error = { ...this.props.responseError };
        const data = error ? (error.data as { error: string }) : null;
        if (
          error.status === INOUT_RESULTS_ERROR_DUPLICATED.STATUS &&
          data &&
          data.error === INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
        ) {
          this.setState({ isOpenApiErrorDialog: true });
          this.props.responseErrorClear();
        } else {
          this.onCancel();
          this.props.onSubmit();
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={this.props.open}
          classes={{ paper: classes.modal }}
        >
          <Formik
            initialValues={this.props.data}
            validate={this.validate}
            onSubmit={this.onSubmit}
          >
            {(formikProps): JSX.Element => (
              <Form className={classes.formArea}>
                <DialogTitle
                  id={this.props.labelId}
                  classes={{ root: classes.modalHeaderArea }}
                  disableTypography
                >
                  <div className={this.props.classes.modalHeader}>
                    <div>{this.props.modalTarget}</div>
                    <div>
                      <span className={this.props.classes.modalHeaderDate}>
                        {this.props.selectedDate.getFullYear()}
                        <span>年</span>
                        {this.props.selectedDate.getMonth() + 1}
                        <span>月</span>
                        {this.props.selectedDate.getDate()}
                        <span>日</span>
                        <span>
                          （
                          {
                            oneLetterWeekdaysJapanese[
                              this.props.selectedDate.getDay()
                            ]
                          }
                          ）
                        </span>
                      </span>
                    </div>
                  </div>
                  <div>
                    <span>{this.props.data.initial.name}</span>
                  </div>
                </DialogTitle>
                <DialogContent className={classes.modalContents}>
                  <InOutReportFormPaper>
                    <InOutReportDialogFields
                      formikPropsValues={formikProps}
                      setFormikFieldValue={formikProps.setFieldValue}
                      facility={this.props.facilityState}
                      usersInFacility={this.props.usersInFacilityState}
                    />
                  </InOutReportFormPaper>
                </DialogContent>

                <DialogActions className={classes.action}>
                  <InOutReportDialogSubmit
                    formikPropsValues={formikProps}
                    onCancel={this.onCancel}
                    disabled={this.state.isSubmitDisabled}
                  />
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
        <MessageDialog
          isOpen={this.state.isOpenApiErrorDialog}
          title=""
          message="同一時間帯で3人以上での提供実績があります。サービス提供時間を修正してください。"
          closeButton={
            <Button
              onClick={this.onCloseDialog}
              className={classes.dialogActionButton}
            >
              OK
            </Button>
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facilityState: state.v202104.KODOENGO.facility,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104, uiDispatch } = dispatches;
  const reportDispatcher = v202104.KODOENGO.reportDispatcher(dispatch);
  return {
    postKODOENGOReport: (
      facility: FacilityState,
      beforeFormValue: InitialValues,
      afterFormValue: InitialValues,
      type: KODOENGOReportTypeInterface["type"]
    ): Promise<void> =>
      reportDispatcher.postKODOENGOReport(
        facility,
        beforeFormValue,
        afterFormValue,
        type
      ),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportDialog = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDialogCore));
