import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import format from "date-fns/format";
import { dateToLocalisedString } from "@utils/date";
import { MeetingRecordState } from "@stores/domain/mgr/Cseg/meetingRecord/types";
import { SECTION_LABEL } from "@constants/mgr/Cseg/variables";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "nowrap"
    },
    flexRow: {
      display: "flex",
      flexDirection: "column"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)",
      whiteSpace: "nowrap"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 2,
      minHeight: 20,
      wordBreak: "break-all"
    },
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4,
      display: "flex",
      "&>span": {
        display: "block",
        minWidth: 88,
        marginLeft: 4
      }
    },
    W216: {
      width: "216px"
    },
    W447: {
      width: "447px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT24: {
      marginTop: "24px"
    },
    ML8: {
      marginLeft: "8px"
    },
    ML16: {
      marginLeft: "16px"
    },
    table: {
      border: "solid 2px #212121"
    },
    headerCell: {
      display: "flex",
      width: "86px",
      minHeight: 24,
      padding: "4px 6px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      justifyContent: "left",
      borderRight: "solid 1px #212121"
    },
    cell: {
      width: "590px",
      padding: "4px 6px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      wordBreak: "break-all"
    },
    gridTable: {
      display: "grid",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid #000",
      textAlign: "left",
      width: "100%",
      gridTemplateColumns: "86px 296px 1fr"
    },
    gridCell: {
      display: "flex",
      padding: "4px 6px",
      borderRight: "1px solid #000",
      borderBottom: "1px solid #000",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      minHeight: 24,
      height: "100%",
      color: "#212121",
      wordBreak: "break-all",
      "&.lastRow": {
        borderBottom: "none"
      },
      "&.lastColumn": {
        borderRight: "none"
      }
    },
    BB: {
      borderBottom: "1px solid #000"
    }
  });

type OwnProps = {
  user:
    | KEIKAKUSODANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
  meetingRecord: MeetingRecordState;
  title: string;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const StaffMeetingRecordPrintContentCore = (props: Props): JSX.Element => {
  const { classes, user, title, meetingRecord } = props;
  const userInFacility = user.user_in_facility;

  if (userInFacility.name_sei === undefined) {
    return <></>;
  }

  // 作成者名
  const authorName = meetingRecord.creation_staff
    ? getSnapOrRealName(meetingRecord.creation_staff, "")
    : "";

  // 基本情報項目
  const baseList1 = [
    {
      label: "利用者氏名（フリガナ）",
      className: classes.W216,
      data: `${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`
    },
    {
      label: "相談支援専門員氏名",
      className: ClassNames(classes.W447, classes.ML16),
      data: authorName
    }
  ];
  const baseList2 = [
    {
      label: "開催日時",
      className: classes.W216,
      data: (
        <span>
          {meetingRecord.meeting_date
            ? format(meetingRecord.meeting_date, "YYYY年M月D日")
            : ""}
          {meetingRecord.meeting_start_time ||
          meetingRecord.meeting_end_time ? (
            <span className={classes.ML8}>
              {meetingRecord.meeting_start_time
                ? dateToLocalisedString(
                    meetingRecord.meeting_start_time,
                    "HH:mm"
                  )
                : ""}
              〜
              {meetingRecord.meeting_end_time
                ? dateToLocalisedString(meetingRecord.meeting_end_time, "HH:mm")
                : ""}
            </span>
          ) : (
            ""
          )}
        </span>
      )
    },
    {
      label: "開催場所",
      className: ClassNames(classes.W447, classes.ML16),
      data: meetingRecord.meeting_place ? meetingRecord.meeting_place : ""
    }
  ];

  const attendeesLength =
    (meetingRecord.attendees &&
      meetingRecord.attendees.filter((v) => v.affiliation || v.name).length) ||
    0;

  let lastConsiderationContent = "";
  if (meetingRecord.consideration_item)
    lastConsiderationContent = "consideration_item";
  if (meetingRecord.consideration_contents)
    lastConsiderationContent = "consideration_contents";
  if (meetingRecord.consideration_results)
    lastConsiderationContent = "consideration_results";
  if (meetingRecord.others) lastConsiderationContent = "others";

  return (
    <>
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>{title}</h2>
          <div>
            <div className={classes.pageTitleTool}>
              {"作成日: "}
              <span>{format(meetingRecord.creation_date, "YYYY年M月D日")}</span>
            </div>
          </div>
        </div>
        {/* 基本情報 */}
        <div className={ClassNames(classes.flex, classes.MT24)}>
          {baseList1.map((m, index) => {
            const uniqueKey = `uniqueBaseList1Key-${index}`;
            return (
              <div className={m.className && m.className} key={uniqueKey}>
                <div className={classes.label}>{m.label}</div>
                <div className={classes.con}>{m.data}</div>
              </div>
            );
          })}
        </div>
        <div className={ClassNames(classes.flex, classes.MT16)}>
          {baseList2.map((m, index) => {
            const uniqueKey = `uniqueBaseList2Key-${index}`;
            return (
              <div className={m.className && m.className} key={uniqueKey}>
                <div className={classes.label}>{m.label}</div>
                <div className={classes.con}>{m.data}</div>
              </div>
            );
          })}
        </div>
        <div className={ClassNames(classes.flex, classes.MT16, classes.table)}>
          <div className={classes.headerCell}>区分</div>
          <div className={classes.cell}>
            {SECTION_LABEL[meetingRecord.section]}
          </div>
        </div>
        {meetingRecord.attendees && attendeesLength > 0 && (
          <div className={ClassNames(classes.MT16, classes.gridTable)}>
            {meetingRecord.attendees
              .filter((v) => v.affiliation || v.name)
              .map((v, idx) => {
                return (
                  <>
                    {idx === 0 && (
                      <div
                        className={`${classes.gridCell} lastRow`}
                        style={{
                          gridRowStart: 1,
                          // 1行目はヘッダー
                          gridRowEnd: 2 + attendeesLength
                        }}
                      >
                        会議出席者
                      </div>
                    )}
                    {idx === 0 && (
                      <>
                        <div className={classes.gridCell}>所属（職種）</div>
                        <div className={`${classes.gridCell} lastColumn`}>
                          氏名
                        </div>
                      </>
                    )}
                    <div
                      className={`${classes.gridCell}${
                        idx + 1 === attendeesLength ? " lastRow" : ""
                      }
                      `}
                    >
                      {v.affiliation}
                    </div>
                    <div
                      className={`${classes.gridCell} lastColumn${
                        idx + 1 === attendeesLength ? " lastRow" : ""
                      }
                      `}
                    >
                      {v.name}
                    </div>
                  </>
                );
              })}
          </div>
        )}
        {lastConsiderationContent && (
          <div
            className={ClassNames(classes.flexRow, classes.MT16, classes.table)}
          >
            {meetingRecord.consideration_item && (
              <div className={classes.flex}>
                <div
                  className={ClassNames(classes.headerCell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_item"
                  })}
                >
                  検討した項目
                </div>
                <div
                  className={ClassNames(classes.cell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_item"
                  })}
                >
                  {meetingRecord.consideration_item}
                </div>
              </div>
            )}
            {meetingRecord.consideration_contents && (
              <div className={classes.flex}>
                <div
                  className={ClassNames(classes.headerCell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_contents"
                  })}
                >
                  検討した内容
                </div>
                <div
                  className={ClassNames(classes.cell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_contents"
                  })}
                >
                  {meetingRecord.consideration_contents}
                </div>
              </div>
            )}
            {meetingRecord.consideration_results && (
              <div className={classes.flex}>
                <div
                  className={ClassNames(classes.headerCell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_results"
                  })}
                >
                  検討した結果
                </div>
                <div
                  className={ClassNames(classes.cell, {
                    [classes.BB]:
                      lastConsiderationContent !== "consideration_results"
                  })}
                >
                  {meetingRecord.consideration_results}
                </div>
              </div>
            )}
            {meetingRecord.others && (
              <div className={classes.flex}>
                <div className={classes.headerCell}>その他</div>
                <div className={classes.cell}>{meetingRecord.others}</div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export const StaffMeetingRecordPrintContent = withStyles(styles)(
  StaffMeetingRecordPrintContentCore
);
