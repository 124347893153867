import * as URL from "@constants/url";
import OutlineReceiptIcon from "@images/icons/OutlineReceiptIcon";
import OutlineRestoreIcon from "@images/icons/OutlineRestoreIcon";
import OutlineSettingsIcon from "@images/icons/OutlineSettingsIcon";
import OpenInNew from "@material-ui/icons/OpenInNew";
import HelpOutLine from "@material-ui/icons/HelpOutline";
import { MenuItemList } from "@constants/menu";
import OutlineAssignmentIcon from "@images/icons/OutlineAssignmentIcon";

const serviceDeliveryPatterns = [
  `^${URL.RECORD_SERVICE_DELIVERY}`, // 一覧
  "^/record/[\\d]+/service_delivery", // 編集・新規登録
  "^/record/[\\d]+/[\\d]+/service_delivery" // 月ごと（新規登録）
];

export const MENU_ITEM_LIST_IDOSHIEN: MenuItemList = [
  {
    title: "利用実績",
    to: URL.REPORT,
    icon: OutlineRestoreIcon,
    selectedPattern: `(${URL.REPORT}).*$`
  },
  {
    title: "記録",
    to: "",
    icon: OutlineAssignmentIcon,
    children: [
      {
        title: "サービス提供記録",
        to: URL.RECORD_SERVICE_DELIVERY_DAILY,
        selectedPattern: serviceDeliveryPatterns.join("|")
      }
    ],
    checkPlanGroupOperationSupport: true
  },
  {
    title: "請求",
    to: URL.DOWNLOAD,
    icon: OutlineReceiptIcon,
    checkPlanGroupInvoice: true
  },
  {
    title: "設定",
    icon: OutlineSettingsIcon,
    to: "",
    children: [
      {
        title: "事業所情報",
        to: URL.FACILITY,
        key: "facility",
        selectedPattern: `^(${URL.FACILITY}).*$`
      },
      {
        title: "利用者情報",
        to: URL.USERS,
        key: "users",
        selectedPattern: `^(${URL.USERS}).*$`
      },
      {
        title: "職員情報",
        to: URL.STAFFS
      },
      {
        title: "自治体情報",
        to: URL.MUNICIPALITY
      },
      {
        title: "提供記録の設定",
        to: URL.RECORD_SETTING,
        checkPlanGroupOperationSupport: true
      }
    ]
  },
  {
    title: "ヘルプ",
    icon: HelpOutLine,
    to: "",
    children: [
      {
        title: "操作マニュアル",
        to: URL.MANUAL_URL,
        target: "_blank",
        rightIcon: OpenInNew
      },
      {
        title: "お問い合わせ",
        to: URL.CONTACT,
        target: "_blank",
        rightIcon: OpenInNew
      }
    ]
  }
];
