import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { FieldItem } from "@interfaces/ui/form";
import { CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM } from "@constants/variables";
import { RELATED_ORGANIZATION_TARGET_TYPE } from "@constants/mgr/CHIIKITEICHAKU/variables";

type Indexes = { categoryIdx: number; inputIdx: number };

/**
 * 地域定着台帳
 * Formikのvalues内からカスタムレコードのitemに対応する値のカテゴリindexと項目indexを取得。
 * 存在しない場合は -1 が帰る。（findIndexの仕様）
 * @param supportLedgerFormValues Formikのvalues全て
 * @param customRecordItem カスタムレコードの対象ID
 */
export const getIndexes = (
  supportLedgerFormValues: SupportLedger,
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
): Indexes => {
  const categoryIdx = supportLedgerFormValues.support_ledger.support_ledger_items.findIndex(
    (r) =>
      r.custom_records_category_id ===
      customRecordItem.custom_records_category_id
  );
  if (categoryIdx === -1) {
    return { categoryIdx, inputIdx: -1 };
  }
  const inputIdx = supportLedgerFormValues.support_ledger.support_ledger_items[
    categoryIdx
  ].input.findIndex((i) => i.custom_record_item_id === customRecordItem.id);
  return { categoryIdx, inputIdx };
};

/**
 * Formikで使用するname文字列を生成
 * @param idxs getIndexesで取得したValueのindex
 * @param name input内の設定したいname(ex: input_data, life_histories...)
 * @returns Formikに対応したname文字列
 */
export const getName = (
  idxs: Indexes,
  name: keyof SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]
): string => {
  const { categoryIdx, inputIdx } = idxs;
  return `support_ledger['support_ledger_items'][${categoryIdx}]['input'][${inputIdx}]['${name}']`;
};

/**
 * 障害種別、障害支援区分の選択項目の名称を取得
 * @param id Formikで取得したinput.id
 * @param customRecordItemChoice 対象となるcustom_record_item_choices
 * @returns Formikに対応したname文字列
 */
export const getCustomRecordItemChoiceName = (
  id: number,
  customRecordItemChoice: CustomRecordsWithCategoryState[number]["custom_record_items"][number]["custom_record_item_choices"]
): string => {
  const targetItem = customRecordItemChoice.find((item) => item.id === id);

  if (!targetItem) {
    return "";
  }
  return targetItem.name;
};

/**
 * indexesを元にFormikに設定されているvalueから値を取得
 * @param supportLedgerFormValues Formikのvalue
 * @param indexes getIndexesで取得した対象のindex
 * @returns inputの値
 */
export const getInput = (
  supportLedgerFormValues: SupportLedger,
  indexes: Indexes
): SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number] => {
  const { categoryIdx, inputIdx } = indexes;
  return supportLedgerFormValues.support_ledger.support_ledger_items[
    categoryIdx
  ].input[inputIdx];
};

/**
 * カスタムレコードのitemから選択肢情報をフォームで使用できる選択肢の形で返却
 * @param item 選択肢を抽出したいカスタムレコード
 * @returns 選択肢のFieldItem
 */
export const getOptions = (
  item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
): FieldItem[] => {
  return item.custom_record_item_choices.map((v) => {
    return { label: v.name, value: `${v.id}` };
  });
};

/**
 * ループ外でdefaultItemを元にAPIからデータを取得するための関数
 * 非表示状態の考慮はしない
 * データが存在しない場合はnullを返す
 * @param supportLedgerState store情報（APIデータ）
 * @param customRecordItems 対象カテゴリ内のitems（カテゴリ内で使用する前提）
 * @param defaultItem defaultItemのnumber
 */
export const getStateValueFromDefaultItem = (
  supportLedgerState: SupportLedgerState["supportLedger"],
  customRecordItems: CustomRecordsWithCategoryState[number]["custom_record_items"],
  defaultItem: number
):
  | SupportLedgerState["supportLedger"]["support_ledger"]["support_ledger_items"][number]["input"][number]
  | null => {
  const targetCustomRecordItem = customRecordItems.find(
    (item) => item.default_item === defaultItem
  );
  if (!targetCustomRecordItem) return null;
  const targetCategoryData = supportLedgerState.support_ledger.support_ledger_items.find(
    (category) =>
      category.custom_records_category_id ===
      targetCustomRecordItem.custom_records_category_id
  );
  if (!targetCategoryData) return null;
  const targetItemData = targetCategoryData.input.find(
    (item) => item.custom_record_item_id === targetCustomRecordItem.id
  );
  if (!targetItemData) return null;
  return targetItemData;
};

/**
 * defaultItem毎にtargetTypeが変わるため、defaultItemを元にtargetTypeの数値を返却
 * @param defaultItem customRecordのdefaultItem
 * @returns 関係機関のtargetType
 */
export const getRelatedOrganizationTargetType = (
  defaultItem: number | null
): typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE] => {
  switch (defaultItem) {
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedWelfareService:
      return RELATED_ORGANIZATION_TARGET_TYPE.welfareService;
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedMedical:
      return RELATED_ORGANIZATION_TARGET_TYPE.medicalInstitution;
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedLifeLine:
      return RELATED_ORGANIZATION_TARGET_TYPE.lifeline;
    case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedOthers:
      return RELATED_ORGANIZATION_TARGET_TYPE.other;
    default:
      return RELATED_ORGANIZATION_TARGET_TYPE.other;
  }
};
