import differenceInMinutes from "date-fns/difference_in_minutes";

/**
 *  2つの時刻の分単位の差分を計算する
 */
export const getDiff = (
  dateLeft: string | number | Date,
  dateRight: string | number | Date,
  type = "minute"
): number => {
  switch (type) {
    case "minute":
      return differenceInMinutes(dateLeft, dateRight);
    default:
      return differenceInMinutes(dateLeft, dateRight);
  }
};
