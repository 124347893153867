import React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import * as ClassNames from "classnames";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import AddIcon from "@material-ui/icons/Add";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps, FieldArray } from "formik";
import FormikTime from "@components/molecules/FormikTime";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import {
  attendeeInitialValue,
  MeetingRecordValues
} from "@initialize/mgr/Cseg/record/meetingRecord/initialValues";
import { SECTION_LIST } from "@constants/mgr/Cseg/variables";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      paddingTop: "16px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "0px",
      top: "0px"
    },
    headerCell: {
      fontSize: "12px",
      padding: "8px 16px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        paddingLeft: "16px "
      }
    },
    headerAffiliation: {
      width: 486
    },
    headerName: {
      flexGrow: 1
    },
    headerDelete: {
      width: 60
    },
    cell: {
      padding: "16px 0px 16px 16px",
      fontSize: 16,

      "&:last-child": {
        paddingLeft: "16px "
      }
    },
    bodyAffiliation: {
      width: 486
    },
    bodyName: {
      flexGrow: 1
    },
    bodyDelete: {
      width: 60
    },
    bodyDeleteIcon: {
      color: "#0277bd",
      cursor: "pointer",
      verticalAlign: "-webkit-baseline-middle",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    meetingDate: {
      display: "flex"
    },
    boldLabel: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: 1.71,
      color: "rgba(0, 0, 0, 0.87)"
    },
    addButton: {
      marginTop: 16
    },
    radioLabel: {
      fontSize: "16px"
    },
    W240: {
      width: "240px"
    },
    W72: {
      width: "72px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT32: {
      marginTop: "32px"
    },
    MR16: {
      marginRight: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<MeetingRecordValues>;
  authorValue: string;
  staffOptionsAddSnapShot: FieldItem[];
  isEditing: boolean;
  isNew?: boolean;
} & WithStyles<typeof styles>;

type Props = OwnProps;

const StaffMeetingRecordFieldsCore = (props: Props): JSX.Element => {
  const {
    formikProps,
    classes,
    staffOptionsAddSnapShot,
    isEditing,
    authorValue,
    isNew
  } = props;

  const { values } = formikProps;

  const headData = [
    {
      label: "所属（職種）",
      className: ClassNames(classes.headerCell, classes.headerAffiliation)
    },
    {
      label: "氏名",
      className: ClassNames(classes.headerCell, classes.headerName)
    },
    {
      label: isEditing ? "削除" : "",
      className: ClassNames(classes.headerCell, classes.headerDelete)
    }
  ];

  const AttendeesBody = (
    handleClickDeleteAttendees: (index: number) => void
  ): KnowbeTableCellParam[][] => {
    let attendeesBody: KnowbeTableCellParam[][] = [[]];
    if (values.attendees) {
      attendeesBody = values.attendees.map((v, idx) => {
        const onClickDelete = (): void => handleClickDeleteAttendees(idx);
        attendeesBody.push();
        return [
          {
            label: (
              <RecordTextField
                name={`attendees[${idx}].affiliation`}
                labelType="default"
                defaultValue=""
                placeholder=""
                value={v.affiliation}
                isEditable={isEditing}
                style={{ marginBottom: 0, width: "100%" }}
                maxLength={100}
              />
            ),
            className: ClassNames(classes.cell, classes.bodyAffiliation)
          },
          {
            label: (
              <RecordTextField
                name={`attendees[${idx}].name`}
                labelType="default"
                defaultValue=""
                placeholder=""
                value={v.name}
                isEditable={isEditing}
                style={{ marginBottom: 0, width: "100%" }}
                maxLength={100}
              />
            ),
            className: ClassNames(classes.cell, classes.bodyName)
          },
          {
            label: isEditing ? (
              <DeleteOutlinedIcon
                className={classes.bodyDeleteIcon}
                color="secondary"
                onClick={onClickDelete}
              />
            ) : (
              ""
            ),
            className: ClassNames(classes.cell, classes.bodyDelete)
          }
        ];
      });
    }
    return attendeesBody;
  };

  const isDisabledAddButton =
    values.attendees !== null && values.attendees.length >= 30;

  return (
    <div className={classes.wrapper}>
      {!isNew && (
        <div className={classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={values.created_at}
            updatedAt={values.updated_at}
          />
        </div>
      )}
      {/* 作成日 */}
      <RecordSelectDate
        name="creation_date"
        label="作成日"
        required
        value={values.creation_date}
        isEditable={isEditing}
        overrideYearFrom={1989}
      />
      {/* 相談支援専門員氏名 */}
      <div className={ClassNames(classes.MT32, classes.W240)}>
        <RecordSelect
          name="creation_staff"
          label="相談支援専門員氏名"
          defaultValue=""
          placeholder="選択してください"
          options={staffOptionsAddSnapShot}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          value={authorValue}
          isEditable={isEditing}
          isSelectablePlaceholder
        />
      </div>
      {/* 開催日 */}
      <div className={ClassNames(classes.MT32, classes.meetingDate)}>
        <RecordSelectDate
          name="meeting_date"
          label="開催日"
          value={values.meeting_date}
          isEditable={isEditing}
          overrideYearFrom={1989}
        />
        <div className={ClassNames(classes.W72, classes.MR16)}>
          {isEditing ? (
            <FormikTime
              name="meeting_start_time"
              label="開始時間"
              placeholder="00:00"
              size="fullSize"
              style={{ marginBottom: 0 }}
              maxLength={5}
            />
          ) : (
            <ReadonlyTextField
              defaultValue=""
              label="開始時間"
              value={values.meeting_start_time || ""}
            />
          )}
        </div>
        <div className={classes.W72}>
          {isEditing ? (
            <FormikTime
              name="meeting_end_time"
              label="終了時間"
              placeholder="00:00"
              size="fullSize"
              style={{ marginBottom: 0 }}
              maxLength={5}
            />
          ) : (
            <ReadonlyTextField
              defaultValue=""
              label="終了時間"
              value={values.meeting_end_time || ""}
            />
          )}
        </div>
      </div>
      {/* 開催場所 */}
      <div className={classes.MT32}>
        <RecordTextField
          name="meeting_place"
          label="開催場所"
          labelType="default"
          defaultValue=""
          placeholder=""
          value={values.meeting_place || ""}
          isEditable={isEditing}
          maxLength={100}
        />
      </div>
      {/* 区分 */}
      <div className={classes.MT32}>
        {isEditing ? (
          <FormikRadioButtons
            name="section"
            label="区分"
            style={{
              flexDirection: "column",
              margin: "8px 0 0 14px"
            }}
            options={SECTION_LIST}
            labelTextClass={classes.radioLabel}
          />
        ) : (
          <MuiRadioButtons
            name="section"
            label="区分"
            style={{
              flexDirection: "column",
              margin: "8px 0 0 14px"
            }}
            options={SECTION_LIST}
            labelTextClass={classes.radioLabel}
            value={`${values.section}`}
            disabled
          />
        )}
      </div>

      {/* 会議出席者 */}
      <div className={classes.MT32}>
        <p className={classes.boldLabel}>会議出席者</p>
        <FieldArray name="attendees">
          {(arrayHelpers): JSX.Element => {
            if (!values.attendees || values.attendees.length === 0) {
              arrayHelpers.push(attendeeInitialValue());
            }
            const handleClickAddAttendees = (): void => {
              arrayHelpers.push(attendeeInitialValue());
            };
            const handleClickDeleteAttendees = (index: number): void => {
              const attendees = Array.from(values.attendees || []);
              attendees.splice(index, 1);
              props.formikProps.setFieldValue("attendees", attendees);
            };
            return (
              <>
                <KnowbeTable className={classes.table}>
                  <KnowbeTableHead
                    uniqueKey="会議出席者ヘッダー"
                    height={40}
                    items={headData}
                    backgroundColor="secondary"
                  />
                  <KnowbeTableBody
                    uniqueKey="会議出席者ボディ"
                    itemsContainer={AttendeesBody(handleClickDeleteAttendees)}
                  />
                </KnowbeTable>
                {isEditing && (
                  <KnowbeButton
                    kind="iconText"
                    className={classes.addButton}
                    onClick={handleClickAddAttendees}
                    disabled={isDisabledAddButton}
                  >
                    <AddIcon
                      color={isDisabledAddButton ? "disabled" : "secondary"}
                    />
                    追加する
                  </KnowbeButton>
                )}
              </>
            );
          }}
        </FieldArray>
      </div>
      {/* 検討した項目 */}
      <div className={classes.MT32}>
        <RecordTextField
          name="consideration_item"
          label="検討した項目"
          labelType="default"
          defaultValue=""
          placeholder=""
          value={values.consideration_item || ""}
          isEditable={isEditing}
        />
      </div>
      {/* 検討した内容 */}
      <div className={classes.MT32}>
        <RecordTextField
          name="consideration_contents"
          label="検討した内容"
          labelType="default"
          defaultValue=""
          placeholder=""
          value={values.consideration_contents || ""}
          isEditable={isEditing}
        />
      </div>
      {/* 検討した結果 */}
      <div className={classes.MT32}>
        <RecordTextField
          name="consideration_results"
          label="検討した結果"
          labelType="default"
          defaultValue=""
          placeholder=""
          value={values.consideration_results || ""}
          isEditable={isEditing}
        />
      </div>
      {/* その他 */}
      <div className={classes.MT32}>
        <RecordTextField
          name="others"
          label="その他"
          labelType="default"
          defaultValue=""
          placeholder=""
          value={values.others || ""}
          isEditable={isEditing}
        />
      </div>
    </div>
  );
};

export const StaffMeetingRecordFields = withStyles(styles)(
  StaffMeetingRecordFieldsCore
);
