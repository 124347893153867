import React from "react";
import { RouteComponentProps } from "react-router-dom";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";

import * as URL from "@constants/url";
import { BasicInfoPrintContent } from "@components/organisms/mgr/KEIKAKUSODAN/record/BasicInfoPrintContent";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import { CityParams, CityState } from "@stores/domain/city/type";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

/**
 * 基本情報のプリント画面
 */

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  supportPlanBasicInfoId: string;
}>;
type StateProps = {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  cityListFacility: CityState[] | null;
  basicInfo: BasicInfoState["basicInfo"]["basic_info"];
};

type DispatchProps = {
  fetchFacility: () => void;
  fetchFacilityCity: (params: CityParams) => Promise<void>;
  fetchBasicInfo: (
    uifId: string,
    supportPlanBasicInfoId: string
  ) => Promise<void>;
  fetchOne: (uifId: string) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const BasicInfoRecordPrintCore = (props: Props): JSX.Element => {
  const { uifId, supportPlanBasicInfoId } = props.match.params;
  const { user, facility, cityListFacility, basicInfo } = props;

  // fetch
  React.useEffect(() => {
    props.fetchFacility();
    props.fetchBasicInfo(uifId, supportPlanBasicInfoId);
    props.fetchOne(uifId);
  }, []);

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "申請者の現状（基本情報）一覧",
      path: `/record/support_plan/${uifId}/basic_info`
    }
  ];

  // 事業所市区町村取得
  React.useEffect(() => {
    if (
      basicInfo.prefecture_name !== null &&
      basicInfo.prefecture_name !== "" &&
      basicInfo.prefecture_name !== DEFAULT_SELECT_VALUE
    ) {
      props.fetchFacilityCity({
        prefectureName: basicInfo.prefecture_name
      });
    }
  }, [basicInfo]);

  const [cityFacilityLabel, setFacilityCityLabel] = React.useState<string>("");
  React.useEffect(() => {
    if (cityListFacility !== null) {
      Object.keys(cityListFacility).forEach((key) => {
        if (cityListFacility[key].value === basicInfo.city_id) {
          setFacilityCityLabel(cityListFacility[key].label);
        }
      });
    }
  }, [cityListFacility, basicInfo]);

  return (
    <AdminTemplate pageName="申請者の現状（基本情報）" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <BasicInfoPrintContent
          user={user}
          facility={facility}
          cityLabel={cityFacilityLabel}
          basicInfo={basicInfo}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.KEIKAKUSODAN.userInFacility.user,
    facility: state.KEIKAKUSODAN.facility,
    cityListFacility: state.city,
    basicInfo: state.KEIKAKUSODAN.basicInfo.basicInfo.basic_info
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN, cityDispatch } = dispatches;
  const facilityDispatcher = KEIKAKUSODAN.facilityDispatcher(dispatch);
  const basicInfoDispatcher = KEIKAKUSODAN.basicInfoDispatcher(dispatch);
  const userInFacilityDispatcher = KEIKAKUSODAN.userInFacilityDispatcher(
    dispatch
  );

  return {
    fetchFacility: facilityDispatcher.fetch,
    fetchFacilityCity: (params: CityParams): Promise<void> =>
      cityDispatch(dispatch).fetch(params),
    fetchBasicInfo: (
      uifId: string,
      supportPlanBasicInfoId: string
    ): Promise<void> => {
      return basicInfoDispatcher.fetchBasicInfo(uifId, supportPlanBasicInfoId);
    },
    fetchOne: (uifId: string): void => {
      userInFacilityDispatcher.fetchOne(uifId);
    }
  };
};

export const BasicInfoRecordPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicInfoRecordPrintCore);
