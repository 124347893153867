import * as types from "./types";
import * as actions from "./actions";

const initialState: types.OperationsAndSupportsState = {
  created_at: null,
  updated_at: null,
  target_date: "",
  page: 0,
  support_count: 0,
  keyword: "",
  is_record: 0,
  operation_records: null,
  support_records: []
};

export const CsegOperationsAndSupports = (
  state = initialState,
  action: actions.ActionTypes
): types.OperationsAndSupportsState => {
  switch (action.type) {
    case types.FETCH_OPERATIONS_AND_SUPPORTS_STARTED:
      return { ...state };
    case types.FETCH_OPERATIONS_AND_SUPPORTS_SUCCESS:
      return { ...state, ...action.payload };
    case types.FETCH_OPERATIONS_AND_SUPPORTS_FAILED:
      return { ...state, ...action.error };
    case types.FETCH_OPERATION_STARTED:
      return { ...state };
    case types.FETCH_OPERATION_SUCCESS:
      return {
        ...state,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at,
        operation_records: action.payload.operation_records
      };
    case types.FETCH_OPERATION_FAILED:
      return { ...state, ...action.error };
    default:
      return state;
  }
};
