import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import format from "date-fns/format";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { FacilityState } from "@stores/domain/mgr/CHIIKIIKO/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { AssistanceReportState } from "@stores/domain/mgr/CHIIKIIKO/assistanceReport/types";
import { DISABILITY_CLASS_LIST } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "nowrap",
      gap: "16px"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)",
      whiteSpace: "nowrap"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 4,
      minHeight: 20
    },
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      overflowWrap: "anywhere",
      "& td": {
        verticalAlign: "top",
        borderRight: "1px solid",
        borderTop: "1px solid",
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        fontSize: "10px",
        "&.label": {
          backgroundColor: "#f5f5f5",
          textAlign: "center",
          verticalAlign: "middle",
          "&.textEnd": {
            verticalAlign: "top",
            textAlign: "end"
          },
          "&.padding": {
            padding: "4px 0px"
          },
          "& div": {
            "&.st": {
              transform: "scale(0.8)"
            }
          }
        },
        "&.tableValueL": {
          padding: "4px 8px"
        },
        "&.tableValueS": {
          padding: "4px 6px"
        },
        "&.w29": {
          width: "32px"
        },
        "&.w92": {
          width: "92px"
        },
        "&.w338": {
          width: "338px"
        }
      }
    },
    signature: {
      display: "flex",
      fontSize: 10,
      border: "solid 2px #212121",
      "& div": {
        "&.label": {
          width: "98px",
          borderRight: "1px solid",
          padding: "12px 8px"
        },
        "&.value": {
          width: "228px"
        }
      }
    },
    staffComment: {
      display: "grid",
      gridTemplateColumns: "120px 1fr",
      fontSize: 10,
      border: "solid 2px #212121",
      overflowWrap: "anywhere",
      "& div": {
        "&.label": {
          borderRight: "1px solid",
          padding: "6px 8px"
        },
        "&.value": {
          padding: "6px"
        }
      }
    },
    W62: {
      width: "62px"
    },
    W158: {
      width: "158px"
    },
    W254: {
      width: "254px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT24: {
      marginTop: "24px"
    }
  });

type OwnProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  assistanceReport: AssistanceReportState["assistanceReport"];
  isStaffComment: boolean;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const AssistanceReportPrintContentCore = (props: Props): JSX.Element => {
  const { classes, user, facility, assistanceReport, isStaffComment } = props;
  const userInFacility = user.user_in_facility;

  if (userInFacility.name_sei === undefined) {
    return <></>;
  }

  // 計画作成日
  const creationDate = assistanceReport.creation_date
    ? format(assistanceReport.creation_date, "YYYY年M月D日")
    : "";
  // モニタリング実施日
  const monitoringDate = assistanceReport.date_of_monitoring
    ? format(assistanceReport.date_of_monitoring, "YYYY年M月D日")
    : "";
  // 計画作成者
  const [author, setAuthor] = React.useState<string>("");
  React.useEffect(() => {
    if (assistanceReport.author === null) {
      setAuthor("");
    } else {
      const name = getSnapOrRealName(assistanceReport.author, "");
      setAuthor(name);
    }
  }, [assistanceReport.author]);

  const classifyDisabilitySupport = DISABILITY_CLASS_LIST.find(
    (x) => x.value === String(assistanceReport.classify_disability_support)
  );

  // 値がある優先順位のみ取り出す
  const filteredDetails = assistanceReport.details
    .map((detail, index) => ({ record: detail, number: index + 1 }))
    .filter((detail) => {
      return !Object.entries(detail.record).every(([key, value]) => {
        if (key === "support_plan_consultation_details_id") {
          return true;
        }
        return !value;
      });
    });

  return (
    <>
      {/* 計画書（1） */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>地域移行支援報告書</h2>
        </div>
        {/* 基本情報 */}
        <div className={classes.MT24}>
          <div className={classes.flex}>
            <div className={classes.W254}>
              <div className={classes.label}>利用者氏名（フリガナ）</div>
              <div
                className={classes.con}
              >{`${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`}</div>
            </div>

            <div className={classes.W62}>
              <div className={classes.label}>障害支援区分</div>
              <div className={classes.con}>
                {classifyDisabilitySupport
                  ? classifyDisabilitySupport.label
                  : ""}
              </div>
            </div>

            {(assistanceReport.guardian_name !== "" ||
              assistanceReport.guardian_relation !== "") && (
              <div className={classes.flex}>
                <div className={classes.W158}>
                  <div className={classes.label}>保護者指名</div>
                  <div className={classes.con}>
                    {assistanceReport.guardian_name}
                  </div>
                </div>

                <div className={classes.W62}>
                  <div className={classes.label}>本人との続柄</div>
                  <div className={classes.con}>
                    {assistanceReport.guardian_relation}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={ClassNames(classes.flex, classes.MT16)}>
            <div className={classes.W254}>
              <div className={classes.label}>相談支援事業所</div>
              <div className={classes.con}>{facility.officeName}</div>
            </div>

            <div className={classes.W158}>
              <div className={classes.label}>計画作成担当者</div>
              <div className={classes.con}>{author}</div>
            </div>
          </div>

          <div className={ClassNames(classes.flex, classes.MT16)}>
            <div className={classes.W158}>
              <div className={classes.label}>福祉サービス受給者番号</div>
              <div className={classes.con}>
                {assistanceReport.disabled_welfare_service_recipient_number}
              </div>
            </div>

            <div className={classes.W158}>
              <div className={classes.label}>地域相談支援受給者番号</div>
              <div className={classes.con}>
                {assistanceReport.consultation_support_recipient_number}
              </div>
            </div>

            <div className={classes.W158}>
              <div className={classes.label}>通所受給者証番号</div>
              <div className={classes.con}>
                {assistanceReport.regular_place_recipient_number}
              </div>
            </div>
          </div>

          <div className={ClassNames(classes.flex, classes.MT16)}>
            <div className={classes.W158}>
              <div className={classes.label}>計画作成日</div>
              <div className={classes.con}>{creationDate}</div>
            </div>

            <div className={classes.W158}>
              <div className={classes.label}>モニタリング実施日</div>
              <div className={classes.con}>{monitoringDate}</div>
            </div>

            <div className={classes.signature}>
              <div className="label">利用者同意署名欄</div>
              <div className="value" />
            </div>
          </div>
        </div>

        {/* テーブル1 */}
        <table className={ClassNames(classes.table, classes.MT16)}>
          <thead>
            <tr>
              <td className="label padding w338">
                <div>サービス等利用計画の到達目標</div>
              </td>
              <td className="label padding w338">
                <div>全体の状況</div>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="value tableValueL">
                <div>{assistanceReport.attainment_goal}</div>
              </td>
              <td className="value tableValueL">
                <div>{assistanceReport.whole_situation}</div>
              </td>
            </tr>
          </tbody>
        </table>

        {/* テーブル2 */}
        {filteredDetails.length > 0 && (
          <table className={ClassNames(classes.table, classes.MT16)}>
            <thead>
              <tr>
                <td className="label w29">
                  <div className="st">
                    優先
                    <br />
                    順位
                  </div>
                </td>
                <td className="label w92">
                  <div className="st">利用者の期待や不安</div>
                </td>
                <td className="label w92">
                  <div className="st">協力（支援）の目安</div>
                </td>
                <td className="label w92">
                  <div className="st">
                    サービス提供状況（事業者からの聞き取り）
                  </div>
                </td>
                <td className="label w92">
                  <div className="st">本人の感想・満足度</div>
                </td>
                <td className="label w92">
                  <div className="st">期待への達成度・不安の解消度</div>
                </td>
                <td className="label w92">
                  <div className="st">今後の課題・解決方法</div>
                </td>
                <td className="label w92">
                  <div className="st">その他留意事項</div>
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredDetails.map(({ record, number }) => {
                return (
                  <tr key={`assistance_report_details_${number}`}>
                    <td className="label textEnd tableValueS">
                      <div className="st">{number}</div>
                    </td>
                    <td className="tableValueS">{record.support_goal}</td>
                    <td className="tableValueS">
                      {record.criteria_for_cooperation}
                    </td>
                    <td className="tableValueS">
                      {record.service_provision_status}
                    </td>
                    <td className="tableValueS">
                      {record.personal_satisfaction}
                    </td>
                    <td className="tableValueS">
                      {record.achievement_of_expectations}
                    </td>
                    <td className="tableValueS">{record.future_issues}</td>
                    <td className="tableValueS">
                      {record.other_considerations}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/* コメント */}
        {isStaffComment && (
          <div className={ClassNames(classes.staffComment, classes.MT16)}>
            <div className="label">職員コメント</div>
            <div className="value">{assistanceReport.staff_comment}</div>
          </div>
        )}
      </div>
    </>
  );
};

export const AssistanceReportPrintContent = withStyles(styles)(
  AssistanceReportPrintContentCore
);
