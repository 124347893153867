import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// store
import {
  ServiceDeliveryState,
  SERVICE_DELIVERY_DAILY
} from "@stores/domain/serviceDelivery/types";
// ui
import { ServiceDeliveryTable } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryTable";

// utils
import { dateInYYYYMMDDFormat, compareDateFuture } from "@utils/date";
// variables
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    tableContainer: {
      padding: "16px 16px"
    }
  });

type OwnProps = {
  currentDate: Date;
  selectedDate: Date;
  facilityType: FacilityType;
  serviceDeliveryDailyState: ServiceDeliveryState["dailyRecord"];
} & RouteComponentProps;

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryDailyRecordCore = (props: Props): JSX.Element | null => {
  const { selectedDate, currentDate } = props;

  let NoPlanMessage = "";
  if (props.serviceDeliveryDailyState.service_delivery.length === 0) {
    // 未来日の場合
    if (compareDateFuture(currentDate, selectedDate)) {
      if (props.facilityType === FacilityType.KODOENGO) {
        NoPlanMessage =
          "計画または支援手順書 兼 記録用紙がありません。利用実績画面から計画を入力するか、支援計画画面から支援手順書兼記録用紙を作成後、ご利用ください。";
      } else {
        NoPlanMessage =
          "計画がありません。利用実績画面から入力後、ご利用ください。";
      }
      // 当日または過去日の場合
    } else if (props.facilityType === FacilityType.KODOENGO) {
      NoPlanMessage =
        "計画・支援手順書 兼 記録用紙・サービス提供記録がありません。利用実績画面にて計画を入力する、または支援計画画面にて支援手順書 兼 記録用紙を作成後にご利用いただくか、この画面の「計画外の記録の追加」ボタンからサービス提供記録を作成してください。";
    } else {
      NoPlanMessage =
        "計画または記録がありません。利用実績画面にて計画を入力後にご利用いただくか、「計画外の記録の追加」ボタンから記録を作成してください。";
    }
  }

  const changeEditMode = (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    _targetDate: string,
    supportProcedureFormsId: number
  ): void => {
    if (props.facilityType === FacilityType.KODOENGO) {
      props.history.push(
        `/record/${dateInYYYYMMDDFormat(
          selectedDate
        )}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${supportProcedureFormsId}`
      );
    } else {
      props.history.push(
        `/record/${dateInYYYYMMDDFormat(
          selectedDate
        )}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}`
      );
    }
  };

  const { classes } = props;
  return (
    <div className={classes.tableContainer}>
      {NoPlanMessage ? (
        <div>{NoPlanMessage}</div>
      ) : (
        <ServiceDeliveryTable
          records={props.serviceDeliveryDailyState}
          type={SERVICE_DELIVERY_DAILY}
          changeEditMode={changeEditMode}
          facilityType={props.facilityType}
          openDeleteModal={(): void => {
            /* 未実装 */
          }}
        />
      )}
    </div>
  );
};

export const ServiceDeliveryDailyRecord = withStyles(styles)(
  ServiceDeliveryDailyRecordCore
);
