import {
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
import { HOSPITAL_STATUS_VALUES } from "@constants/mgr/KYOTAKUKAIGO/variables";

// サービス内容による表示する詳細内容の出し分け
export const isDisplayService = (
  status: string,
  categoryType: number
): boolean => {
  const statusValue = Number(status);
  if (
    statusValue ===
    CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.body_care
  ) {
    return ![
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.hospital,
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.house_work
    ].includes(categoryType);
  }
  if (
    statusValue ===
    CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.house_work
  ) {
    return ![
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.body_care,
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.hospital
    ].includes(categoryType);
  }
  if (HOSPITAL_STATUS_VALUES.includes(status)) {
    return ![
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.body_care,
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.house_work
    ].includes(categoryType);
  }
  return true;
};

// 同行・行動援護のモバイルの「記録の入力」扱いのカスタムレコード。falseは「行き先・金銭管理」の入力扱いのカスタムレコード
export const isCustomRecordDOKOENGO = (categoryType: number): boolean => {
  return (
    categoryType !==
    CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.money_management
  );
};
