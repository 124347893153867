import * as React from "react";

import ClassNames from "classnames";

import {
  Table,
  Typography,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { TableProps } from "@material-ui/core/Table";

const primaryFontSize = 14;
const primaryLineHeight = 24 / primaryFontSize;
const primaryCaptionMargin = 16;

const styles = (): StyleRules =>
  createStyles({
    table: {
      borderBottom: "1px solid #E5E5E5"
    },
    tableWithCaption: {
      marginLeft: primaryCaptionMargin
    },
    captionWrapper: {
      marginLeft: -primaryCaptionMargin,
      marginBottom: 8,
      textAlign: "left"
    },
    caption: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight
    }
  });

type OwnProps = {
  children: React.ReactNode;
  caption?: string;
  className?: string;
};

type Props = OwnProps & TableProps & WithStyles<typeof styles>;

/**
 *  Knowbeの独自コンポーネント。KnowbeTableHeadやKnowbeTableBody(WithSubHeader)などと組み合わせて使う。
 */
export const KnowbeTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { children, caption, classes, className } = props;

    return (
      <Table
        className={ClassNames(
          classes.table,
          caption && classes.tableWithCaption,
          className
        )}
      >
        {caption && (
          <caption className={classes.captionWrapper}>
            <Typography className={classes.caption}>{caption}</Typography>
          </caption>
        )}
        {children}
      </Table>
    );
  }
);
