import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import { RecordSelectMonth } from "@components/organisms/mgr/common/record/RecordSelectMonth";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { FieldItem } from "@interfaces/ui/form";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { ConsultationRecordState } from "@stores/domain/mgr/CHIIKIIKO/consultation/types";
import { FormikProps, ArrayHelpers, FieldArray, getIn } from "formik";
import {
  ConsultationValues,
  initialSupportPlanConsultationDetails,
  loadValuesDetails,
  isDetailUpdate
} from "@initialize/mgr/CHIIKIIKO/record/consultation/initialValues";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/CHIIKIIKO/planMonitoringMeeting/types";
import {
  dateToLocalisedString,
  dateToSelectMonthValue,
  getWareki,
  selectDateValueToDate
} from "@utils/date";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import { StaffState } from "@stores/domain/staff/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    field: { marginTop: "32px" },
    goalField: { paddingLeft: "16px" },
    shortGoalField: { paddingLeft: "16px", marginBottom: "48px" },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "24px"
    },
    deleteButton: {
      marginTop: "32px",
      marginLeft: "-8px"
    },
    btnTxt: {
      marginLeft: "8px"
    },
    priorityCards: {
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd",
      marginBottom: "16px"
    },
    priorityCardsTitle: {
      marginBottom: "32px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    loadPlanButtonWrapper: { display: "flex", justifyContent: "flex-end" },
    loadPlanButton: {
      width: "240px"
    },
    author: {
      display: "flex",
      padding: "23px 0 32px"
    },
    authorName: {
      width: 240
    },
    authorRole: {
      width: 240,
      paddingLeft: "16px"
    },
    flex: {
      display: "flex"
    },
    border: {
      marginTop: "32px",
      border: "solid 1px #d8d8d8"
    },
    W240: {
      width: "240px"
    },
    W320: {
      width: "320px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MB16: {
      marginBottom: "16px"
    },
    MB32: {
      marginBottom: "32px"
    },
    ML32: {
      marginLeft: "32px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<ConsultationValues>;
  user: UsersInFacilityState["user"];
  authorValue: string;
  staff: StaffState;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  consultationCopy: ConsultationRecordState | null;
  isEditing: boolean;
  onClickLoad: (isSchedule?: boolean) => () => void;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  resetCopyConsultation: () => void;
  setIsDetailUpdate: reactDispatch<SetStateAction<boolean>>;
  isNew?: boolean;
} & WithStyles<typeof styles>;

type Props = OwnProps;

const ConsultationFieldsCore = (props: Props): JSX.Element => {
  const {
    formikProps,
    classes,
    staff,
    staffOptionsAddSnapShot,
    consultationCopy,
    isEditing,
    authorValue,
    onClickLoad,
    isNew
  } = props;

  const { values } = formikProps;

  const overrideYearTo = new Date().getFullYear() + 1;

  const startYm = getIn(values, "startYm");

  // 上書きフラグ
  React.useEffect(() => {
    props.setIsDetailUpdate(isDetailUpdate(values));
  }, [values]);

  // 計画読み込み
  React.useEffect(() => {
    if (consultationCopy) {
      formikProps.setValues(loadValuesDetails(values, consultationCopy));
      props.resetCopyConsultation();
    }
  }, [consultationCopy]);

  const isDisabledAddButton =
    values.supportPlanConsultationDetails.filter((detail) => !detail.is_delete)
      .length >= 10;

  // 協力（支援）の開始年月から協力（支援）の目安開始・終了年月の選択肢を作成する
  const supportPeriodList = React.useMemo((): FieldItem[] => {
    const dt = new Date(selectDateValueToDate({ ...startYm, day: "1" }));
    const options: FieldItem[] = [];
    for (let i = 1; i <= 6; i += 1) {
      options.push({
        label: `${dt.getFullYear()}年 (${getWareki(dt.getFullYear())}) ${
          dt.getMonth() + 1
        }月`,
        value: dateToLocalisedString(dt, "YYYYMM")
      });
      dt.setMonth(dt.getMonth() + 1);
    }
    return options;
  }, [startYm]);

  const onChangeStartYm = (
    event: React.ChangeEvent<HTMLSelectElement>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeValue: string
  ): void => {
    let currentStartYm = { year: "", month: "" };
    if (event.target.name === "startYm.year") {
      currentStartYm = { ...startYm, year: event.target.value };
    }
    if (event.target.name === "startYm.month") {
      currentStartYm = { ...startYm, month: event.target.value };
    }

    // startYmが変更されたらendYmが6ヶ月後になるように更新し、協力（支援）の内容の目安期間をリセットする
    const dt = new Date(selectDateValueToDate({ ...currentStartYm, day: "1" }));
    dt.setMonth(dt.getMonth() + 5);
    const endYm = dateToSelectMonthValue(dateToLocalisedString(dt, "YYYY-MM"));

    formikProps.setFieldValue("endYm", endYm);
    values.supportPlanConsultationDetails.forEach((_, idx) => {
      formikProps.setFieldValue(
        `supportPlanConsultationDetails[${idx}].start_ym`,
        ""
      );
      formikProps.setFieldValue(
        `supportPlanConsultationDetails[${idx}].end_ym`,
        ""
      );
    });
  };

  return (
    <div className={classes.wrapper}>
      {!isNew && (
        <div className={classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={values.createdAt}
            updatedAt={values.updatedAt}
          />
        </div>
      )}
      {/* 計画作成日 */}
      <div className={classes.MT16}>
        <RecordSelectDate
          name="creationDate"
          label="作成年月日"
          required
          value={values.creationDate}
          isEditable={isEditing}
          overrideYearFrom={1989}
          overrideYearTo={overrideYearTo}
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        計画の詳細
      </Typography>
      {isEditing && (
        <div className={classes.loadPlanButtonWrapper}>
          <KnowbeButton
            className={classes.loadPlanButton}
            kind="default"
            onClick={onClickLoad()}
            disabled={props.planMonitoringMeeting.yearly.length === 0}
          >
            作成済みの計画から読み込む
          </KnowbeButton>
        </div>
      )}
      {/* サービス等利用計画の到達目標 */}
      <div className={classes.field}>
        <RecordTextField
          name="attainmentGoal"
          label="サービス等利用計画の到達目標"
          labelType="default"
          defaultValue=""
          value={values.attainmentGoal}
          isEditable={isEditing}
          placeholder=""
        />
      </div>

      {/* 長期目標 */}
      <div className={classes.field}>
        <RecordTextField
          name="longTermGoal"
          label="長期目標（内容及び期間等）"
          labelType="default"
          defaultValue=""
          value={values.longTermGoal}
          isEditable={isEditing}
          placeholder=""
        />
      </div>

      {/* 短期目標 */}
      <div className={classes.field}>
        <RecordTextField
          name="shortTermGoal"
          label="短期目標（内容及び期間等）"
          labelType="default"
          defaultValue=""
          value={values.shortTermGoal}
          isEditable={isEditing}
          placeholder=""
        />
      </div>

      <div className={classes.border} />

      <div className={ClassNames(classes.field, classes.flex, classes.MB32)}>
        {/* 協力（支援）の開始年月 */}
        <div>
          <RecordSelectMonth
            name="startYm"
            label="協力（支援）の開始年月"
            value={values.startYm}
            isEditable={isEditing}
            required
            addYearTo={1}
            overrideYearFrom={1989}
            onChangeHook={onChangeStartYm}
          />
        </div>
        {/* 協力（支援）の終了年月 */}
        <div className={classes.ML32}>
          <RecordSelectMonth
            name="endYm"
            label="協力（支援）の終了年月"
            value={values.endYm}
            isEditable={false}
            addYearTo={1}
            overrideYearFrom={1989}
          />
        </div>
      </div>

      {/* 協力（支援）の内容 */}
      <FieldArray name="supportPlanConsultationDetails">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          return (
            <>
              {values.supportPlanConsultationDetails
                .map((detail, index) => {
                  return { detail, baseIndex: index };
                })
                .filter((item) => !item.detail.is_delete)
                .map((item) => {
                  const { detail, baseIndex } = item;
                  const value =
                    values.supportPlanConsultationDetails[baseIndex];

                  const startYmItem = supportPeriodList.find(
                    (x) => x.value === value.start_ym
                  );
                  const endYmItem = supportPeriodList.find(
                    (x) => x.value === value.end_ym
                  );
                  return (
                    <div
                      className={classes.priorityCards}
                      key={`details_${baseIndex}_${detail.support_plan_consultation_details_id}`}
                    >
                      {/* 本人の期待や不安 */}
                      <div>
                        <RecordTextField
                          name={`supportPlanConsultationDetails[${baseIndex}].support_goal`}
                          label="本人の期待や不安"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.supportPlanConsultationDetails[baseIndex]
                              .support_goal
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* そのために協力する人 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`supportPlanConsultationDetails[${baseIndex}].collaborator`}
                          label="そのために協力する人"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.supportPlanConsultationDetails[baseIndex]
                              .collaborator
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* 協力する内容 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`supportPlanConsultationDetails[${baseIndex}].contents_cooperation`}
                          label="協力する内容"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.supportPlanConsultationDetails[baseIndex]
                              .contents_cooperation
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      {/* 留意事項 */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`supportPlanConsultationDetails[${baseIndex}].considerations`}
                          label="留意事項"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.supportPlanConsultationDetails[baseIndex]
                              .considerations
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>

                      <div className={ClassNames(classes.field, classes.flex)}>
                        {/* 協力（支援）の目安開始年月 */}
                        <div className={classes.W320}>
                          {isEditing ? (
                            <RecordSelect
                              name={`supportPlanConsultationDetails[${baseIndex}].start_ym`}
                              label="協力（支援）の目安開始年月"
                              defaultValue=""
                              placeholder="選択してください"
                              options={supportPeriodList}
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .start_ym
                              }
                              isEditable={isEditing}
                              isSelectablePlaceholder
                            />
                          ) : (
                            <ReadonlyTextField
                              label="協力（支援）の目安開始年月"
                              defaultValue=""
                              value={startYmItem ? startYmItem.label : ""}
                            />
                          )}
                        </div>
                        {/* 協力（支援）の目安終了年月 */}
                        <div className={ClassNames(classes.ML32, classes.W320)}>
                          {isEditing ? (
                            <RecordSelect
                              name={`supportPlanConsultationDetails[${baseIndex}].end_ym`}
                              label="協力（支援）の目安終了年月"
                              defaultValue=""
                              placeholder="選択してください"
                              options={supportPeriodList}
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .end_ym
                              }
                              isEditable={isEditing}
                              isSelectablePlaceholder
                            />
                          ) : (
                            <ReadonlyTextField
                              label="協力（支援）の目安終了年月"
                              defaultValue=""
                              value={endYmItem ? endYmItem.label : ""}
                            />
                          )}
                        </div>
                      </div>
                      <div className={classes.field} />

                      {/* 利用者自身がすること */}
                      <div className={classes.field}>
                        <RecordTextField
                          name={`supportPlanConsultationDetails[${baseIndex}].users_do`}
                          label="利用者自身がすること"
                          labelType="default"
                          defaultValue=""
                          value={
                            values.supportPlanConsultationDetails[baseIndex]
                              .users_do
                          }
                          isEditable={isEditing}
                          placeholder=""
                        />
                      </div>
                      {isEditing && (
                        <div className={props.classes.deleteButton}>
                          <DeleteButton
                            text="削除する"
                            onClick={(): void => {
                              formikProps.setFieldValue(
                                `supportPlanConsultationDetails[${baseIndex}].is_delete`,
                                1
                              );
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              {isEditing && (
                <KnowbeButton
                  kind="iconText"
                  className={classes.MT16}
                  onClick={(): void =>
                    arrayHelpers.push(initialSupportPlanConsultationDetails)
                  }
                  disabled={isDisabledAddButton}
                >
                  <AddIcon />
                  <span className={classes.btnTxt}>
                    協力（支援）の内容を追加する
                  </span>
                </KnowbeButton>
              )}
            </>
          );
        }}
      </FieldArray>
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      {/* 職員コメント */}
      <div className={classes.field}>
        <RecordTextField
          name="staffComment"
          label="職員コメント"
          labelType="default"
          defaultValue=""
          value={values.staffComment}
          isEditable={isEditing}
          placeholder=""
        />
      </div>

      <div className={classes.author}>
        <div className={classes.authorName}>
          <RecordSelect
            name="author"
            label="作成者"
            defaultValue="未設定"
            placeholder="選択してください"
            options={staffOptionsAddSnapShot}
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            value={authorValue}
            isEditable={isEditing}
            isSelectablePlaceholder
          />
        </div>
        <div className={classes.authorRole}>
          <ReadonlyTextField
            value={getStaffRole(staff, formikProps.values.author)}
            label="役職"
            defaultValue="-"
          />
        </div>
      </div>
    </div>
  );
};

export const ConsultationFields = withStyles(styles)(ConsultationFieldsCore);
