import { GetUsagePerformanceParamsSummary } from "@api/requests/usagePerformance/getUsagePerformanceSummary";

/**
 * ActionNames
 */
export const FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED =
  "SHISETSUNYUSHO/FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED";
export const FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS =
  "SHISETSUNYUSHO/FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS";
export const FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED =
  "SHISETSUNYUSHO/FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED";
export const UNSET_SHISETSUNYUSHO_USAGE_PERFORMANCE_SUMMARY =
  "SHISETSUNYUSHO/UNSET_SHISETSUNYUSHO_USAGE_PERFORMANCE_SUMMARY";

/**
 * State
 */
export type ReportUsagePerformanceState = GetUsagePerformanceParamsSummary;
