import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { InOutReportDailyHeader } from "@components/organisms/mgr/KODOENGO/report/InOutReportDailyHeader";
import { InOutReportPaperHeader } from "@components/organisms/mgr/KODOENGO/report/InOutReportPaperHeader";
import { InOutReportTable } from "@components/organisms/mgr/KODOENGO/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { dateInYYYYMMDDFormat } from "@utils/date";
import {
  InitialDataValues,
  initialValues
} from "@initialize/mgr/KODOENGO/report/initialValues";
import { InOutReportDialog } from "@components/organisms/mgr/KODOENGO/report/InOutReportDialog";
import {
  REPEAT_DAILY,
  ReportState,
  InoutResultsState,
  DeleteReportState,
  CopyReportState,
  CopyReportErrorState
} from "@stores/domain/mgr/KODOENGO/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import {
  KODOENGO_INPUT_CLASS_LIST,
  REPORT_FILTER_LIST,
  PROCESS_TYPE,
  INOUT_RESULTS_COPY_ERROR_STATUS,
  INVOICE_PATHS
} from "@constants/mgr/KODOENGO/variables";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import Button from "@material-ui/core/Button";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { ResponseError } from "@stores/ui/type";
import { FacilityType } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      minHeight: 56,
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8,
      width: "100%"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    dialogActionButton: {
      width: 138,
      color: "#b00020"
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
};

type DispatchProps = {
  fetchDaily: (date: Date) => Promise<void>;
  fetchFacility: () => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  deleteInoutResults: (target: DeleteReportState) => Promise<void>;
  copyInoutResults: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  responseErrorClear(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  selectedDate: Date;
  headerHeight: number;
  data: InitialDataValues;
  isOpenDetailModal: boolean;
  modalTarget: string;
  filterFlg: boolean;
  filterValue: string;
  isOpenDeleteDialog: boolean;
  isOpenNonDeleteDialog: boolean;
  deleteTarget: DeleteReportState | null;
  isOpenCopyConfirmDialog: boolean;
  isOpenAlreadyResultDialog: boolean;
  copyReportListTarget: CopyReportState[] | null;
};

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績（日ごと）
 */
class InOutReportDailyCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDate: this.props.initialDate,
      headerHeight: 0,
      data: initialValues(),
      isOpenDetailModal: false,
      modalTarget: KODOENGO_INPUT_CLASS_LIST.CREATE.label,
      filterFlg: false,
      filterValue: "1",
      isOpenDeleteDialog: false,
      isOpenNonDeleteDialog: false,
      deleteTarget: null,
      isOpenCopyConfirmDialog: false,
      isOpenAlreadyResultDialog: false,
      copyReportListTarget: null
    };
  }

  public componentDidMount(): void {
    Promise.all([
      this.props.fetchDaily(this.state.selectedDate),
      this.props.fetchFacility(),
      this.props.fetchInoutError(this.state.selectedDate)
    ]);
  }

  public componentDidUpdate(): void {
    const top = document.getElementById("reportDailyHeader");
    if (top && top.clientHeight !== this.state.headerHeight) {
      const setClientHeight = (): void => {
        this.setState({
          headerHeight: top.clientHeight
        });
      };
      setClientHeight();
    }
  }

  private onChangeDate = (date: Date): void => {
    const transitionDone = checkReportVersion(
      date,
      FacilityType.KODOENGO,
      this.props.currentPageVersion,
      INVOICE_PATHS.reportDaily,
      this.props.history
    );

    if (!transitionDone) {
      this.setState({ selectedDate: date });
      this.props.fetchDaily(date);
      this.props.fetchInoutError(date);
    }
  };

  private onChangeFilterSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      filterValue: e.target.value,
      filterFlg: e.target.value === REPORT_FILTER_LIST.FILTER.value
    });
  };

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  private openModal = (param: InoutResultsState, inputClass: string): void => {
    this.setState({ data: initialValues(param) });
    if (inputClass === KODOENGO_INPUT_CLASS_LIST.PLAN.value) {
      this.setState({ modalTarget: KODOENGO_INPUT_CLASS_LIST.PLAN.label });
    } else {
      this.setState({
        modalTarget: KODOENGO_INPUT_CLASS_LIST.RESULT.label
      });
    }
    this.props.fetchUserInFacility(param.usersInFacilityId.toString());
    this.setState({ isOpenDetailModal: true });
  };

  private openCreateModal = (
    date: string,
    uifName: string,
    uifId: number
  ): void => {
    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues();
    init.initial.targetDate = dateInYYYYMMDDFormat(this.state.selectedDate);
    init.initial.name = uifName;
    init.initial.usersInFacilityId = uifId.toString();

    this.setState({
      data: init,
      modalTarget: KODOENGO_INPUT_CLASS_LIST.CREATE.label
    });
    this.props.fetchUserInFacility(uifId.toString());

    this.setState({ isOpenDetailModal: true });
  };

  private onCancel = (): void => {
    this.setState({ isOpenDetailModal: false });
  };

  private onSubmit = (): void => {
    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);
  };

  /**
   * 削除アイコンのクリックイベント（確認モーダルオープン）
   * ※計画/実績の判定は子のほうで判定するため、ここでは共通化している
   */
  private onClickDeleteIcon = (target: DeleteReportState): void => {
    this.setState({ deleteTarget: target, isOpenDeleteDialog: true });
  };

  /**
   * 削除できない場合のモーダル表示処理
   * ※実績が存在する場合、計画が削除できない旨のモーダル表示
   */
  private openNonDeleteDialog = (): void => {
    this.setState({ isOpenNonDeleteDialog: true });
  };

  private onDelete = async (): Promise<void> => {
    if (this.state.deleteTarget !== null) {
      await this.props.deleteInoutResults(this.state.deleteTarget);
      this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
    }

    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);
  };

  private onCancelDelete = (): void => {
    this.setState({
      deleteTarget: null,
      isOpenDeleteDialog: false,
      isOpenNonDeleteDialog: false
    });
  };

  /**
   * 一括コピーボタン押下アクション
   */
  private onClickCopyReportListIcon = async (): Promise<void> => {
    // 1.processType=2でAPIをコール
    // 2.実績存在エラーが帰ってきた場合、モーダル表示
    // 3.モーダルの「OKボタン」押下時、processType=3でAPIをコール
    const copyList = this.props.reportState.reportDaily.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, copyList);
    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyReportListTarget: copyList,
        isOpenCopyConfirmDialog: true
      });
    } else {
      this.props.fetchDaily(this.state.selectedDate);
      this.props.fetchInoutError(this.state.selectedDate);
    }
  };

  private onCopyReportList = async (): Promise<void> => {
    if (this.state.copyReportListTarget !== null) {
      await this.props.copyInoutResults(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyReportListTarget
      );

      this.props.fetchDaily(this.state.selectedDate);
      this.props.fetchInoutError(this.state.selectedDate);
    }

    this.setState({
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null
    });
  };

  private onCopyRecord = async (target: CopyReportState): Promise<void> => {
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, [target]);
    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({ isOpenAlreadyResultDialog: true });
    }
  };

  private onCloseCopyDialog = (): void => {
    this.setState({
      isOpenCopyConfirmDialog: false,
      isOpenAlreadyResultDialog: false
    });
  };

  public render(): JSX.Element {
    const { classes, inoutErrors } = this.props;
    const { selectedDate, headerHeight } = this.state;

    return (
      <>
        <div id="reportDailyHeader" className={classes.headerWrapper}>
          {inoutErrors.hasError && (
            <InvoiceErrorBar
              message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
              onClick={this.onClickErrorDialog}
            />
          )}
          <div className={classes.headerInfoContainer}>
            <InOutReportDailyHeader
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedDate}
              filterValue={this.state.filterValue}
              onChangeDate={this.onChangeDate}
              onChangeFilterSelect={this.onChangeFilterSelect}
            />
          </div>
        </div>
        <Paper elevation={0} className={classes.tableContainer}>
          <InOutReportPaperHeader
            rectangleConfigList={[]}
            onClickCopyReportListIcon={this.onClickCopyReportListIcon}
            type={REPEAT_DAILY}
            disabledCopy={this.props.reportState.reportDaily.planCount === 0}
          />
          <InOutReportTable
            headerHeight={headerHeight}
            openModal={this.openModal}
            openCreateModal={this.openCreateModal}
            onClickDeleteIcon={this.onClickDeleteIcon}
            openNonDeleteDialog={this.openNonDeleteDialog}
            onCopyRecord={this.onCopyRecord}
            type={REPEAT_DAILY}
            filterFlg={this.state.filterFlg}
          />
        </Paper>
        <InOutReportDialog
          open={this.state.isOpenDetailModal}
          usersInFacilityState={this.props.usersInFacilityState}
          data={this.state.data}
          selectedDate={selectedDate}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          type={REPEAT_DAILY}
          modalTarget={this.state.modalTarget}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDeleteDialog}
          onDelete={this.onDelete}
          onCancel={this.onCancelDelete}
          title=""
          message="実績を削除しますか?"
        />
        <MessageDialog
          isOpen={this.state.isOpenNonDeleteDialog}
          title=""
          message="実績があるため削除できません"
          closeButton={
            <Button
              onClick={this.onCancelDelete}
              className={classes.dialogActionButton}
            >
              OK
            </Button>
          }
        />
        <MessageDialog
          isOpen={this.state.isOpenAlreadyResultDialog}
          title=""
          message="実績がすでに登録されています"
          closeButton={
            <Button
              onClick={this.onCloseCopyDialog}
              className={classes.dialogActionButton}
            >
              OK
            </Button>
          }
        />
        <ConfirmDialog
          isOpen={this.state.isOpenCopyConfirmDialog}
          onDelete={this.onCopyReportList}
          onCancel={this.onCloseCopyDialog}
          submitLabel="コピーする"
          title=""
          message="当月にすでに実績が登録されているデータが存在します。登録されていない日のみ計画をコピーしますか？"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.KODOENGO.report,
    usersInFacilityState: state.KODOENGO.userInFacility,
    inoutErrors: state.errors.inout,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = KODOENGO.reportDispatcher(dispatch);
  const uifDispatcher = KODOENGO.userInFacilityDispatcher(dispatch);
  return {
    fetchFacility: KODOENGO.facilityDispatcher(dispatch).fetch,
    fetchDaily: (date: Date): Promise<void> =>
      reportDispatcher.fetchKODOENGODaily(date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    openErrorsDialog: (): {
      readonly type: "UI/SHOW_ERRORS_DIALOG";
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    deleteInoutResults: (target: DeleteReportState): Promise<void> =>
      reportDispatcher.deleteKODOENGOReport(target),
    copyInoutResults: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postKODOENGOReportCopy(processType, target),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDailyCore));
