import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import FieldWrapper, { FieldSizeName } from "@components/atoms/FieldWrapper";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    inputMultiline: {
      overflow: "hidden"
    },
    endAdornmentLabel: {
      whiteSpace: "nowrap"
    },
    color: {
      "&>label": {
        color: "#37474F!important"
      },
      "&>div>input": {
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&>div p": {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    visibilityIcon: {
      cursor: "pointer",
      color: "#546e7a",
      width: 24,
      height: 24,
      margin: "0 8px"
    },
    passwordStyle: {
      "& input[type='password']": {
        height: "auto"
      }
    }
  });

type OwnProps = {
  type?: "text" | "password" | "number" | "tel" | "email" | "date";
  minLength?: number;
  maxLength?: number;
  startAdornmentLabel?: string;
  endAdornmentLabel?: string;
  size?: FieldSizeName;
  multiline?: boolean;
  rows?: string;
  inputProps?: never;
  InputProps?: never;
  label?: string; // ReactNodeを渡すとバグがあるのでしばらくstring固定
  helperText?: string; // 理由は上と同じで発生したことはまだないが塞いでおく
  disabledStyle?: boolean;
  showVisibilityIcon?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles> & TextFieldProps;

// 提供する型はWithStylesは除外しておく
export type MuiTextFieldProps = OwnProps & TextFieldProps;

const MuiTextField = ({
  minLength,
  maxLength,
  startAdornmentLabel,
  endAdornmentLabel,
  size,
  multiline,
  rows,
  classes,
  style,
  disabledStyle,
  showVisibilityIcon,
  className,
  ...props
}: Props): JSX.Element => {
  const [passwordVisibility, isPasswordVisibility] = React.useState<boolean>(
    false
  );
  const onClickVisibilityBtn = (): void => {
    isPasswordVisibility(!passwordVisibility);
  };
  const visibilityValue = (): null | JSX.Element => {
    if (props.type !== "password" || !showVisibilityIcon) {
      return null;
    }
    return passwordVisibility ? (
      <VisibilityOutlinedIcon
        className={classes.visibilityIcon}
        onClick={onClickVisibilityBtn}
      />
    ) : (
      <VisibilityOffOutlinedIcon
        className={classes.visibilityIcon}
        onClick={onClickVisibilityBtn}
      />
    );
  };
  const typeValue = (): typeof props.type => {
    if (props.type !== "password" || !showVisibilityIcon) {
      return props.type;
    }
    return passwordVisibility ? "text" : props.type;
  };

  const startAdornment = startAdornmentLabel ? (
    <InputAdornment position="start">{startAdornmentLabel}</InputAdornment>
  ) : null;
  const endAdornment = endAdornmentLabel ? (
    <InputAdornment
      position="end"
      classes={{ root: classes.endAdornmentLabel }}
    >
      {endAdornmentLabel}
    </InputAdornment>
  ) : (
    visibilityValue()
  );

  return (
    <FieldWrapper size={size} style={style}>
      <TextField
        {...props}
        type={typeValue()}
        inputProps={{
          minLength,
          maxLength
        }}
        /* inputPropsとInputPropsは別のpropsだが誤認されている */
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          startAdornment,
          endAdornment,
          classes: { inputMultiline: classes.inputMultiline }
        }}
        multiline={multiline || false}
        rows={rows}
        className={ClassNames(
          disabledStyle ? classes.color : "",
          className,
          showVisibilityIcon ? classes.passwordStyle : ""
        )}
      />
    </FieldWrapper>
  );
};

MuiTextField.defaultProps = {
  fullWidth: true,
  InputLabelProps: {
    shrink: true
  }
};

export default withStyles(styles)(MuiTextField);
