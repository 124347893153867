import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  setting_type: number;
  assessment_record_id: number | null;
  target_date: string;
  author?: number | null;
  type_consultation?: number;
  custom_record: {
    custom_records_category_id: number;
    custom_record_input_id: number | null;
    custom_record_item_id: number;
    input_data?: string;
    choiced_item_id?: number | null;
    checked?: number;
    work_histories?: RequestWorkHistory[];
    life_histories?: HistoryBase[];
    disability_histories?: HistoryBase[];
    flow_days?: FlowDay[];
  }[];
};

type RequestWorkHistory = {
  id: number | null;
  begin_date: string;
  end_date: string;
  facility_name: string;
  details: string;
  is_delete: number;
};

export type HistoryBase = {
  id: number | null;
  yyyymm?: string | null;
  matter?: string | null;
  is_delete?: number | null;
};

type FlowDay = {
  id: number | null;
  title?: string | null;
  is_delete?: number | null;
  flow_day_details_user: FlowDayDetail[];
  flow_day_details_caregiver: FlowDayDetail[];
};

export type FlowDayDetail = {
  id: number | null;
  start_time?: string | null;
  end_time?: string | null;
  matter?: string | null;
  is_delete?: number;
};

/**
 * アセスメント一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const postAssessment = async (
  uifId: string,
  param: RequestParam
): Promise<AxiosResponse<RequestParam>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assessment/`;
  return request.post(url, param);
};
