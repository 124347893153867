import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Button, FormGroup, Theme } from "@material-ui/core";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import FormikTextField from "@components/molecules/FormikTextField";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";
import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon
} from "@material-ui/icons";

// store
import { connect } from "react-redux";
import {
  DAY_SELECT_OPTIONS,
  MEMBER_LIST_SERVICE_DETAIL
} from "@constants/variables";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import {
  otherPractitionersInitialValue,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { FieldItem } from "@interfaces/ui/form";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      padding: "24px 16px",
      marginTop: "16px",
      backgroundColor: "#fff"
    },
    title: {
      fontSize: 14,
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.6)"
    },
    fieldArea: {
      marginTop: 16,
      padding: "24px 16px",
      borderRadius: "4px",
      border: "solid 1px rgba(0, 0, 0, 0.12)"
    },
    otherInputArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start"
    },
    border: {
      color: "#e0e0e0",
      borderTop: "none",
      borderBottom: "1px solid",
      width: "100%",
      margin: 0
    },
    iconButton: {
      display: "inline-flex",
      gap: "8px",
      padding: "8px 16px",
      color: "#0277bd",
      marginTop: "16px"
    },
    iconButtonBase: {
      color: "#0277bd"
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%"
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT24: {
      marginTop: spacing.unit * 3
    }
  });

type OwnProps = {
  staffOptions: FieldItem[];
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  isEdit: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureRecordFormServiceDeliveryCore = (
  props: Props
): JSX.Element => {
  const { classes } = props;
  const { staffOptions, formikProps } = props;
  const {
    supportProcedureFormPractitioners1,
    supportProcedureFormPractitioners2,
    supportProcedureFormOtherPractitioners,
    numberOfPractitioner
  } = formikProps.values;

  const maxLength = 20;
  const [isMaxLength, setIsMaxLength] = React.useState(false);

  React.useEffect(() => {
    setIsMaxLength(supportProcedureFormOtherPractitioners.length >= maxLength);
  }, [supportProcedureFormOtherPractitioners]);

  // サービス提供者選択肢作成（スナップショット）
  const practitionerOptions1 = createSnapshotOptions(
    staffOptions,
    supportProcedureFormPractitioners1.practitionerSnapshot
  );

  const practitionerOptions2 = createSnapshotOptions(
    staffOptions,
    supportProcedureFormPractitioners2.practitionerSnapshot
  );

  return (
    <div className={classes.wrapper}>
      <h3 className={classes.title}>自事業者のサービス提供者</h3>
      <div className={classes.fieldArea}>
        <FormikSelect
          name="numberOfPractitioner"
          label="サービス提供人数（自事業所）"
          options={MEMBER_LIST_SERVICE_DETAIL}
          isMobile
          readOnly={!props.isEdit}
          size="fullSize"
        />
        <FormGroup row style={{ display: "flex", alignItems: "flex-start" }}>
          <FormikSelect
            name="supportProcedureFormPractitioners1.practitionerId"
            label="サービス提供者1"
            options={practitionerOptions1}
            isMobile
            readOnly={!props.isEdit}
            isSelectablePlaceholder
            placeholder="選択してください"
            size="fullSize"
          />
          <div className={classes.flex}>
            <FormikSelect
              name="supportProcedureFormPractitioners1.startTimeDate"
              label="開始時間"
              size="mobileHalf"
              isMobile
              readOnly
              options={DAY_SELECT_OPTIONS}
            />
            <FormikTime
              name="supportProcedureFormPractitioners1.startTime"
              maxLength={5}
              placeholder="00:00"
              size="mobileHalf"
              isMobile
              readOnly={!props.isEdit}
            />
          </div>

          <div className={classes.flex}>
            <FormikSelect
              name="supportProcedureFormPractitioners1.endTimeDate"
              label="終了時間"
              options={DAY_SELECT_OPTIONS}
              size="mobileHalf"
              isMobile
              readOnly={!props.isEdit}
              style={{ marginBottom: 0 }}
              noerrortext
            />
            <FormikTime
              name="supportProcedureFormPractitioners1.endTime"
              label=""
              maxLength={5}
              placeholder="00:00"
              isMobile
              style={{ marginBottom: 0 }}
              size="mobileHalf"
              readOnly={!props.isEdit}
            />
          </div>
        </FormGroup>

        {Number(numberOfPractitioner) === 2 && (
          <FormGroup>
            <hr className={`${classes.border} ${classes.MT24}`} />
            <FormikSelect
              name="supportProcedureFormPractitioners2.practitionerId"
              label="サービス提供者2"
              style={{ marginTop: "24px" }}
              options={practitionerOptions2}
              isSelectablePlaceholder
              readOnly={!props.isEdit}
              size="fullSize"
              placeholder="選択してください"
              isMobile
            />
            <div className={classes.flex}>
              <FormikSelect
                name="supportProcedureFormPractitioners2.startTimeDate"
                label="開始時間"
                options={DAY_SELECT_OPTIONS}
                size="mobileHalf"
                isMobile
                readOnly={!props.isEdit}
                noerrortext
              />
              <FormikTime
                name="supportProcedureFormPractitioners2.startTime"
                label=""
                placeholder="00:00"
                size="mobileHalf"
                maxLength={5}
                isMobile
                readOnly={!props.isEdit}
              />
            </div>
            <div className={classes.flex}>
              <FormikSelect
                name="supportProcedureFormPractitioners2.endTimeDate"
                label="終了時間"
                options={DAY_SELECT_OPTIONS}
                size="mobileHalf"
                isMobile
                readOnly={!props.isEdit}
                style={{ marginBottom: 0 }}
                noerrortext
              />
              <FormikTime
                name="supportProcedureFormPractitioners2.endTime"
                label=""
                placeholder="00:00"
                size="mobileHalf"
                style={{ marginBottom: 0 }}
                maxLength={5}
                isMobile
                readOnly={!props.isEdit}
              />
            </div>
          </FormGroup>
        )}
      </div>

      <h3 className={`${classes.title} ${classes.MT24}`}>
        他事業者のサービス提供者
      </h3>

      <div className={classes.fieldArea}>
        <FieldArray name="supportProcedureFormOtherPractitioners">
          {(arrayHelpers: ArrayHelpers): JSX.Element => {
            const onClickAdd = (): void => {
              arrayHelpers.push(otherPractitionersInitialValue);
            };
            const onClickDelete = (index: number): void => {
              // src/components/organisms/mgr/KODOENGO/record/ServiceDeliveryForm.tsx
              // arrayHelpers.remove(index) を使用していたが、バリデーションエラーがある際に落ちるためsetFieldValueで代替
              // formik issue: https://github.com/jaredpalmer/formik/issues/1158
              formikProps.setFieldValue(
                "supportProcedureFormOtherPractitioners",
                supportProcedureFormOtherPractitioners.filter(
                  (_, i) => i !== index
                )
              );
            };
            return (
              <>
                {supportProcedureFormOtherPractitioners.length !== 0 ? (
                  supportProcedureFormOtherPractitioners.map(
                    (otherPractitioner, i) => {
                      const index = `supportProcedureFormOtherPractitionersRow${i}`;
                      return (
                        <FormGroup
                          key={index}
                          className={`${classes.otherInputArea} ${
                            i !== 0 ? classes.MT16 : ""
                          }`}
                        >
                          {props.isEdit ? (
                            <FormikTextField
                              name={`supportProcedureFormOtherPractitioners[${i}].facilityName`}
                              label="事業所名"
                              size="fullSize"
                              placeholder=""
                            />
                          ) : (
                            <ReadonlyTextField
                              label="事業所名"
                              value={otherPractitioner.facilityName}
                              size="fullSize"
                              defaultValue=""
                            />
                          )}
                          {props.isEdit ? (
                            <FormikTextField
                              name={`supportProcedureFormOtherPractitioners[${i}].practitionerName`}
                              label="サービス提供者"
                              size="fullSize"
                              placeholder=""
                            />
                          ) : (
                            <ReadonlyTextField
                              label="サービス提供者"
                              value={otherPractitioner.practitionerName}
                              size="fullSize"
                              defaultValue=""
                            />
                          )}

                          <div className={classes.flex}>
                            <FormikSelect
                              name={`supportProcedureFormOtherPractitioners[${i}].startTimeDate`}
                              label="開始時間"
                              options={DAY_SELECT_OPTIONS}
                              size="mobileHalf"
                              isMobile
                              readOnly={!props.isEdit}
                              noerrortext
                            />
                            <FormikTime
                              name={`supportProcedureFormOtherPractitioners[${i}].startTime`}
                              label=""
                              placeholder="00:00"
                              size="mobileHalf"
                              maxLength={5}
                              isMobile
                              readOnly={!props.isEdit}
                            />
                          </div>
                          <div className={classes.flex}>
                            <FormikSelect
                              name={`supportProcedureFormOtherPractitioners[${i}].endTimeDate`}
                              label="終了時間"
                              options={DAY_SELECT_OPTIONS}
                              isMobile
                              size="mobileHalf"
                              readOnly={!props.isEdit}
                              style={{ marginBottom: 0 }}
                              noerrortext
                            />
                            <FormikTime
                              name={`supportProcedureFormOtherPractitioners[${i}].endTime`}
                              label=""
                              placeholder="00:00"
                              size="mobileHalf"
                              style={{ marginBottom: 0 }}
                              maxLength={5}
                              isMobile
                              readOnly={!props.isEdit}
                            />
                          </div>
                          {props.isEdit ? (
                            <Button
                              className={classes.iconButton}
                              onClick={(): void => onClickDelete(i)}
                            >
                              <DeleteIcon className={classes.iconButtonBase} />
                              削除する
                            </Button>
                          ) : (
                            <></>
                          )}
                          <hr className={`${classes.border} ${classes.MT16}`} />
                        </FormGroup>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
                {props.isEdit ? (
                  <Button
                    color="secondary"
                    className={classes.MT16}
                    onClick={onClickAdd}
                    disabled={isMaxLength}
                    style={{ padding: "6px 16px" }}
                  >
                    <AddIcon />
                    追加する
                  </Button>
                ) : (
                  <></>
                )}
              </>
            );
          }}
        </FieldArray>
      </div>
    </div>
  );
};

export const SupportProcedureRecordFormServiceDelivery = withStyles(styles)(
  connect(null)(SupportProcedureRecordFormServiceDeliveryCore)
);
