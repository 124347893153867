import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import _has from "lodash-es/has";
import Paper from "@material-ui/core/Paper";
import { dateToLocalisedString } from "@/utils/date";
import getAge from "@utils/date/getAge";
import SectionTitle from "@components/atoms/SectionTitle";
// 種別ごとのtype
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";

import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import {
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

type UsersInFacilityStateType =
  | UsersInFacilityStateKEIKAKUSODAN["user"]
  | UsersInFacilityStateCHIIKIIKO["user"]
  | UsersInFacilityStateCHIIKITEICHAKU["user"];

const checkIsUserKeikakusodan = (
  user: UsersInFacilityStateType
): user is UsersInFacilityStateKEIKAKUSODAN["user"] => {
  return _has(user, "user_in_facility_keikakusodan");
};

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "32px 32px 24px"
    },
    table: {
      marginTop: 8
    },
    labelCell: {
      fontSize: 14,
      color: "#37474f",
      padding: "8px 16px 8px 0",
      "&:first-child": {
        width: "150px"
      }
    },
    cell: {
      fontWeight: 500,
      padding: "0 48px 0 8px"
    }
  });

type OwnProps = {
  user: UsersInFacilityStateType;
};
type Props = OwnProps & WithStyles<typeof styles>;

const UserInfoRecordCore = (props: Props): JSX.Element => {
  // 障害種別
  const {
    classify_physical_flg,
    classify_intelligence_flg,
    classify_mind_flg,
    classify_growth_flg,
    classify_brain_flg,
    classify_handicapped_flg,
    classify_incurable_flg
  } = props.user.user_in_facility;
  const classifies: string[] = [];
  if (classify_physical_flg === "1") {
    classifies.push("身体障害");
  }
  if (classify_intelligence_flg === "1") {
    classifies.push("知的障害");
  }
  if (classify_mind_flg === "1") {
    classifies.push("精神障害");
  }
  if (classify_growth_flg === "1") {
    classifies.push("発達障害");
  }
  if (classify_brain_flg === "1") {
    classifies.push("高次脳機能障害");
  }
  if (classify_handicapped_flg === "1") {
    classifies.push("障害児");
  }
  if (classify_incurable_flg === "1") {
    classifies.push("難病等対象者");
  }

  // 利用者名
  const { name_sei = "", name_mei = "" } = props.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;

  // サービス提供開始日
  let dateBeginInService = "";
  if (checkIsUserKeikakusodan(props.user)) {
    // 計画相談と障害児相談判定
    const typeDailyConsultation =
      props.user.user_in_facility_keikakusodan &&
      props.user.user_in_facility_keikakusodan.type_daily_consultation;
    if (typeDailyConsultation === TYPE_CONSULTATION_KEIKAKUSODAN) {
      const keikakusodanFacility = findUserInFacilityKeikakusodan(props.user);
      if (keikakusodanFacility) {
        dateBeginInService = keikakusodanFacility.date_begin_in_service;
      }
    }
    if (typeDailyConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
      const shogaijiFacility = findUserInFacilityShogaijisodan(props.user);
      if (shogaijiFacility) {
        dateBeginInService = shogaijiFacility.date_begin_in_service;
      }
    }
  } else if (props.user.user_in_facility.date_begin_in_service) {
    dateBeginInService = props.user.user_in_facility.date_begin_in_service;
  }

  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <SectionTitle label={userName} />
        <table className={props.classes.table}>
          <tbody>
            <tr>
              <td className={props.classes.labelCell}>サービス提供開始日</td>
              <td className={props.classes.cell}>
                {dateBeginInService &&
                  dateToLocalisedString(dateBeginInService, "YYYY年M月D日")}
              </td>
              <td className={props.classes.labelCell}>性別</td>
              <td className={props.classes.cell}>
                {props.user.user_in_facility.gender &&
                props.user.user_in_facility.gender === "1"
                  ? "男性"
                  : "女性"}
              </td>
              <td className={props.classes.labelCell}>生年月日</td>
              <td className={props.classes.cell}>
                {props.user.user_in_facility.date_birth &&
                  `${dateToLocalisedString(
                    props.user.user_in_facility.date_birth,
                    "YYYY年M月D日"
                  )}（${getAge(props.user.user_in_facility.date_birth)}歳）`}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className={props.classes.labelCell}>障害種別</td>
              <td className={props.classes.cell}>
                {classifies.length > 0 ? classifies.join("、") : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
    </>
  );
};

export const UserInfoRecord = withStyles(styles)(UserInfoRecordCore);
