import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import LineBreak from "@components/atoms/LineBreak";
import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";
import { OptionInterface } from "@components/atoms/DropDown";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { SupportRecordUserState as GroupHomeSRUState } from "@stores/domain/mgr/GroupHome/supportRecordUser/types";
import { UsersInFacilityState as GroupHomeUIFState } from "@stores/domain/mgr/GroupHome/userInFacility/types";
import { SupportRecordUserState as SHISETSUNYUSHOSRUState } from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/types";
import { UsersInFacilityState as SHISETSUNYUSHOSUIFState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import dispatches from "@stores/dispatches";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  USAGE_PERFORMANCE_STATUS_TYPE_FULL,
  CUSTOM_RECORD_DEFAULT_CHOICE_UNIT
} from "@constants/variables";
import { STATUS_TYPE } from "@constants/mgr/SHISETSUNYUSHO/variables";

// utils
import { getUrlParams } from "@utils/url";
import dateToObject from "@utils/date/dateToObject";
import { getLabelFromOptions } from "@utils/dataNormalizer";

const TABLE_HEAD_HEIGHT = 25;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.84)"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      marginBottom: 8
    },
    userBasicInfo: {
      marginBottom: 16
    },
    recipientNumber: {
      fontSize: 10,
      color: "rgba(0, 0, 0, 0.87)",
      marginLeft: 8
    },
    summaryContent: {
      fontSize: 10,
      marginRight: 10,
      color: "rgba(0, 0, 0, 0.87)"
    },
    summaryInfo: { fontSize: 0 },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  user: UserState;
  supportsRecord: GroupHomeSRUState | SHISETSUNYUSHOSRUState;
  userInFacility: GroupHomeUIFState["user"] | SHISETSUNYUSHOSUIFState["user"];
  customRecords: CustomRecordsState;
};
type OwnProps = {
  uifId: string;
  year: string;
  month: string;
  query: string;
};

type DispatchProps = {
  fetchSupportRecordUser: (
    uifId: string,
    year: string,
    month: string,
    facility_type: FacilityType
  ) => void;
  fetchOne: (id: string, facility_type: FacilityType) => void;
  fetchCustomRecords: () => void;
};

type MergeProps = StateProps &
  OwnProps &
  DispatchProps & {
    statusTypeList: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  displayColumns: string[];
  userInFacility: StateProps["userInFacility"];
  year: string;
  month: string;
  supportsRecord: StateProps["supportsRecord"];
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  customRecords: CustomRecordsState;
  statusTypeList: OptionInterface[];
  staffCustomRecordID: number;
  classes: Record<string, string>;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

const getSupportContents = (
  customRecords: CustomRecordsState,
  displayColumns: string[],
  status_type: number,
  statusTypeList: OptionInterface[],
  support_record_input: SheetOwnProps["supportsRecord"]["support"][0]["support_record_input"],
  classes: Record<string, string>
): JSX.Element[] => {
  const supportContents: JSX.Element[] = [];

  if (status_type) {
    const statusType = getLabelFromOptions(`${status_type}`, statusTypeList);

    const statusTypeContent = (
      <p key={`status_type_${status_type}`} style={{ margin: 0 }}>
        {`[サービス提供の状況] ${statusType}`}
      </p>
    );

    supportContents.push(statusTypeContent);
  }

  [...customRecords].forEach((record) => {
    switch (record.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const textContent = support_record_input.find(
          (item) => item.custom_record_item_id === record.id
        );

        const textInputData =
          textContent && textContent.input_data ? textContent.input_data : null;

        // 「職員考察」項目
        if (
          record.default_item === 8 &&
          displayColumns.includes("staff_comment") &&
          textInputData
        ) {
          supportContents.push(
            <p
              key={`input_type_first_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }

        if (record.default_item !== 8 && textInputData) {
          supportContents.push(
            <p
              key={`input_type_first_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItemsId = support_record_input
          .filter(
            (item) =>
              item.custom_record_item_id === record.id && item.checked === 1
          )
          .map((filteredItem) => filteredItem.choiced_item_id);

        const checkedChoiceItems = record.choices
          .filter((item) => checkedItemsId.includes(item.id))
          .map((filteredItem) => filteredItem.name);

        const checkedName = checkedChoiceItems
          ? checkedChoiceItems.join("、")
          : "";

        if (checkedItemsId.length !== 0) {
          supportContents.push(
            <p
              key={`input_type_second_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}] ${checkedName}`}
            </p>
          );
        }
        break;
      }
      // 複数テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
        const inputDataItems = support_record_input.filter(
          (item) => item.custom_record_item_id === record.id && item.input_data
        );

        const multiTextContents: JSX.Element[] = [];

        // 小項目の途中で改行されないようspanタグを使用
        [1, 2, 3, 4, 5].forEach((defaultChoiceNum) => {
          const choiced = record.choices.find(
            (recordItem) => recordItem.default_choice === defaultChoiceNum
          );
          const choicedInputData =
            choiced &&
            inputDataItems.find(
              (inputDataItem) => inputDataItem.choiced_item_id === choiced.id
            );

          if (choiced && choicedInputData) {
            multiTextContents.push(
              <span key={choiced.id} style={{ display: "inline-block" }}>
                {choiced.name} : {choicedInputData.input_data}
                {CUSTOM_RECORD_DEFAULT_CHOICE_UNIT[`${defaultChoiceNum}`]}&emsp;
              </span>
            );
          }
        });

        // 血圧の最低と最高を並び替え
        const sortedMultiTextContents = multiTextContents.slice();
        const index2 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最低）"
        );
        const index3 = sortedMultiTextContents.findIndex(
          (multiTextContent) =>
            multiTextContent.props.children[0] === "血圧（最高）"
        );

        if (index2 !== -1 && index3 !== -1) {
          const lowerChoice = sortedMultiTextContents[index2];
          sortedMultiTextContents[index2] = sortedMultiTextContents[index3];
          sortedMultiTextContents[index3] = lowerChoice;
        }

        if (inputDataItems.length !== 0) {
          supportContents.push(
            <p
              key={`input_type_fourth_${record.id}`}
              className={classes.contents}
            >
              {`[${record.name}]`} <span>{sortedMultiTextContents}</span>
            </p>
          );
        }
        break;
      }
      default:
        break;
    }
  });

  return supportContents;
};

const SupportCustomRecordPrintCore = (props: Props): JSX.Element | null => {
  const [displayColumns, setDisplayColumns] = React.useState([] as string[]);
  const [renderFlg, setRenderFlg] = React.useState(false);

  const {
    uifId,
    year,
    month,
    query,
    classes,
    userInFacility,
    supportsRecord,
    customRecords,
    statusTypeList,
    user
  } = props;

  React.useEffect(() => {
    props.fetchOne(uifId, user.facility_type);
    props.fetchSupportRecordUser(uifId, year, month, user.facility_type);
    props.fetchCustomRecords();
    const queryParameters: { display_columns?: string } = getUrlParams(query);
    if (queryParameters.display_columns) {
      setDisplayColumns(queryParameters.display_columns.split(","));
    }
    setRenderFlg(true);
  }, []);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  const tableRowStartEndIndexInSheet = {
    startIndex: 0,
    endIndex: supportsRecord.support.length
  };

  // 「order」順、「記録者」項目は除外
  const sortCustomRecords = (
    array: CustomRecordsState
  ): {
    newCustomRecords: CustomRecordsState;
    staffCustomRecord: CustomRecordsState;
  } => {
    const orderArray = array
      .filter((item) => item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);

    return {
      newCustomRecords: orderArray,
      staffCustomRecord: recorderItem
    };
  };

  const orderedCustomRecords =
    customRecords.length > 0 && sortCustomRecords(customRecords);

  if (!orderedCustomRecords) {
    return null;
  }

  return (
    <Sheet
      classes={classes}
      displayColumns={displayColumns}
      userInFacility={userInFacility}
      year={year}
      month={month}
      supportsRecord={supportsRecord}
      tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
      customRecords={orderedCustomRecords.newCustomRecords}
      statusTypeList={statusTypeList}
      staffCustomRecordID={orderedCustomRecords.staffCustomRecord[0].id}
    />
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    userInFacility,
    displayColumns,
    year,
    month,
    supportsRecord,
    tableRowStartEndIndexInSheet,
    customRecords,
    statusTypeList,
    staffCustomRecordID
  } = props;

  const { counts } = supportsRecord;

  const isMealItem = counts.meal.choices.some((choice) => choice.count !== 0);

  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;

  return (
    <div className={classes.page}>
      <div>
        <header>
          <h1 className={classes.title}>
            {displayColumns.includes("support_record")
              ? "支援記録"
              : "サービス提供記録"}
          </h1>
        </header>
        <div className={classes.userBasicInfo}>
          <div className={classes.subTitle}>
            <span>
              {`${year}年${month}月分${"　"}${
                userInFacility.user_in_facility.name_sei
              } ${userInFacility.user_in_facility.name_mei}`}
            </span>
            <span className={classes.recipientNumber}>
              {`（受給者証番号：${userInFacility.user_in_facility.recipient_number}）`}
            </span>
          </div>
          <div className={classes.summaryInfo}>
            <span
              className={props.classes.summaryContent}
            >{`宿泊：${counts.status_type.stay}日`}</span>
            <span
              className={props.classes.summaryContent}
            >{`入院：${counts.status_type.hospitalization}日`}</span>
            <span
              className={props.classes.summaryContent}
            >{`外泊：${counts.status_type.stay_away}日`}</span>
          </div>
          {isMealItem && (
            <div className={classes.summaryInfo}>
              {counts.meal.choices
                .filter((item) => item.count > 0)
                .map((mealItem) => {
                  return (
                    <span
                      className={props.classes.summaryContent}
                      key={mealItem.id}
                    >{`${mealItem.name}：${mealItem.count}回`}</span>
                  );
                })}
            </div>
          )}
        </div>
        {supportsRecord.support.length > 0 && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label ssize">日付</td>
                  <td className="label">支援内容</td>
                  <td className="label ssize">記録者</td>
                  <td className="label sssize">捺印</td>
                </tr>
                {supportsRecord.support.map(
                  (
                    { target_date, status_type, support_record_input },
                    index
                  ) => {
                    if (index < startIndex || index > endIndex)
                      return undefined;
                    const targetDate = dateToObject(new Date(target_date));

                    const supportContents = getSupportContents(
                      customRecords,
                      displayColumns,
                      status_type,
                      statusTypeList,
                      support_record_input,
                      classes
                    );

                    // 記録者
                    const staffRecord = support_record_input
                      .filter(
                        (item) =>
                          item.custom_record_item_id === staffCustomRecordID &&
                          item.checked
                      )
                      .map((item) => item.choiced_staff_name_snapshot);

                    const staffName = staffRecord ? staffRecord.join("、") : "";

                    if (!supportContents.length && !staffName) {
                      return undefined;
                    }

                    return (
                      <tr key={`tr-${targetDate.month}_${targetDate.day}`}>
                        <td className="label topCenterAlign">
                          {`${targetDate.month}/${targetDate.day}(${targetDate.day_of_week})`}
                        </td>
                        <td className="topAlign">{supportContents}</td>
                        <td className="topCenterAlign">{staffName}</td>
                        <td className="label" />
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const getUserInFacility = (): StateProps["userInFacility"] => {
    switch (user.facility_type) {
      case FacilityType.GROUP_HOME:
        return state.GroupHome.userInFacility.user;
      case FacilityType.SHISETSUNYUSHO:
        return state.SHISETSUNYUSHO.userInFacility.user;
      default:
        return state.GroupHome.userInFacility.user;
    }
  };
  const getSupportsRecord = (): StateProps["supportsRecord"] => {
    switch (user.facility_type) {
      case FacilityType.GROUP_HOME:
        return state.GroupHome.supportRecordUser;
      case FacilityType.SHISETSUNYUSHO:
        return state.SHISETSUNYUSHO.supportRecordUser;
      default:
        return state.GroupHome.supportRecordUser;
    }
  };
  const { customRecords } = state;
  return {
    user,
    supportsRecord: getSupportsRecord(),
    userInFacility: getUserInFacility(),
    customRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecords } = dispatches;
  const getUserInFacilityDispatcher = (
    uifId: string,
    facility_type: FacilityType
  ): Promise<void> => {
    switch (facility_type) {
      case FacilityType.GROUP_HOME:
        return dispatches.GroupHome.userInFacilityDispatcher(dispatch).fetchOne(
          uifId
        );
      case FacilityType.SHISETSUNYUSHO:
        return dispatches.SHISETSUNYUSHO.userInFacilityDispatcher(
          dispatch
        ).fetchOne(uifId);
      default:
        return dispatches.GroupHome.userInFacilityDispatcher(dispatch).fetchOne(
          uifId
        );
    }
  };
  const getSupportRecordUserDispatch = (
    uifId: string,
    year: string,
    month: string,
    facility_type: FacilityType
  ): Promise<void> => {
    switch (facility_type) {
      case FacilityType.GROUP_HOME:
        return dispatches.GroupHome.supportRecordUserDispatcher(
          dispatch
        ).fetchSupportRecordUser(uifId, year, month);
      case FacilityType.SHISETSUNYUSHO:
        return dispatches.SHISETSUNYUSHO.supportRecordUserDispatcher(
          dispatch
        ).fetchSupportRecordUser(uifId, year, month);
      default:
        return dispatches.GroupHome.supportRecordUserDispatcher(
          dispatch
        ).fetchSupportRecordUser(uifId, year, month);
    }
  };
  const customRecordsDispatches = customRecords(dispatch);

  return {
    fetchSupportRecordUser: getSupportRecordUserDispatch,
    fetchOne: getUserInFacilityDispatcher,
    fetchCustomRecords: (): void => {
      customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.support
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const getStatusType = (facility_type: FacilityType): OptionInterface[] => {
    switch (facility_type) {
      case FacilityType.GROUP_HOME:
        return USAGE_PERFORMANCE_STATUS_TYPE_FULL;
      case FacilityType.SHISETSUNYUSHO:
        return STATUS_TYPE;
      default:
        return USAGE_PERFORMANCE_STATUS_TYPE_FULL;
    }
  };
  const statusTypeList = getStatusType(stateProps.user.facility_type);
  return {
    statusTypeList,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportCustomRecordPrint = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(SupportCustomRecordPrintCore));
