import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type getCustomRecordsResponse = {
  data: {
    id: number;
    default_item: number | null;
    name: string;
    input_type: number;
    setting_type: number;
    order: number | null;
    visibility: number;
    choices: {
      id: number;
      custom_record_item_id: number;
      name: string;
      default_choice: number | null;
      hidden: number;
    }[];
    allow_edit_name: number;
    allow_edit_choiced: number;
    allow_change_order: number;
    allow_change_visibility: number;
    setting_page_visibility: number;
  }[];
};

/**
 * カスタム項目を取得する
 */
export const getCustomRecords = async (
  target?: number
): Promise<AxiosResponse<getCustomRecordsResponse>> => {
  const url = `${VERSION_URL_201910}/custom_records`;
  const query = target ? `?target=${target}` : "";
  return request.get<getCustomRecordsResponse>(url + query);
};

export default getCustomRecords;
