import * as React from "react";

// ui
import * as classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {
  CUSTOM_RECORD_INPUT_TYPE,
  FacilityType,
  INT_FALSE_FROM_API
} from "@constants/variables";

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    margin: "0",
    "&:before": {
      display: "none"
    }
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    display: "contents",
    padding: "0"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    border: 0,
    boxShadow: "none",
    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0"
  }
})(MuiExpansionPanelDetails);

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      minHeight: 330,
      maxHeight: "80%"
    },
    dialogTitle: {
      padding: "16px 24px 20px 32px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 58
    },
    dialogContent: {
      width: 776,
      height: "100%",
      minHeight: 148,
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    dialogActions: {
      borderTop: "1px solid #cfd8dc",
      margin: 0,
      padding: 8
    },
    contentSection: {
      padding: "24px 32px 48px",
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.38)"
      }
    },
    formNameWrap: {
      "& > div > div": {
        margin: 0,
        "& > label > span": {
          fontSize: 16
        }
      }
    },
    formNameLabel: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 6px"
    },
    note: {
      fontSize: 12,
      marginBottom: 24,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 16px"
    },
    button: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 24
      }
    },
    cancel: {
      borderColor: "#ccc"
    },
    contentHeaderCategory: {
      display: "flex",
      color: "#fff",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "12px 12px 12px 16px",
      backgroundColor: "#90a4ae"
    },
    contentHeaderItem: {
      display: "flex",
      color: "#37474f",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "12px 12px 12px 16px",
      backgroundColor: "#eceff1"
    },
    contentHeaderName: {
      display: "flex",
      alignItems: "center",
      width: 604
    },
    contentHeaderCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 40
    },
    contentItem: {
      display: "flex",
      borderBottom: "solid 1px #e5e5e5",
      fontSize: 16,
      width: 712,
      height: 64,
      padding: "12px 0px 12px 16px"
    },
    contentCategory: {
      marginTop: 1,
      display: "flex",
      color: "#37474f",
      backgroundColor: "#eceff1",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "0px 16px"
    },
    contentItemName: {
      display: "flex",
      alignItems: "center",
      minWidth: 604
    },
    contentItemCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 42
    },
    contentItemNameForService: {
      display: "flex",
      alignItems: "center",
      width: 340
    },
    contentItemType: {
      display: "flex",
      alignItems: "center",
      width: 240,
      marginLeft: 24
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onVisible: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
  hiddenCustomRecordCategoryType: number;
  customRecords: CustomRecordsWithCategoryState;
  isCategorized?: boolean;
  isServiceDelivery?: boolean;
  facilityType?: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 記録の項目設定 > 非表示項目リスト
 */
const HiddenItemsWithCategoryModalCore = (props: Props): JSX.Element | null => {
  const { classes, isServiceDelivery } = props;
  const [checkIds, setCheckIds] = React.useState<{
    item: number[];
    category: number[];
  }>({ item: [], category: [] });

  // 非表示項目リスト（カテゴリー非表示、あるいはカテゴリー内の項目のうち1つ以上が非表示）
  const hiddenCustomRecords = props.customRecords
    .filter((r) => r.category_type === props.hiddenCustomRecordCategoryType)
    .map((r) => {
      if (r.visibility === 0) {
        return r;
      }
      const invisible_items = r.custom_record_items.filter(
        (i) => i.visibility === 0
      );
      return { ...r, custom_record_items: invisible_items };
    })
    .filter((r) => r.visibility === 0 || r.custom_record_items.length > 0);

  // visible=1の項目を割り出す
  const defaultSetItems: number[] = hiddenCustomRecords
    .map((r) =>
      r.custom_record_items
        .map((i) => {
          return i.visibility === 1 ? i.id : 0;
        })
        .filter((v) => v)
    )
    .reduce((newArray, e) => {
      return newArray.concat(e);
    }, []);

  React.useEffect(() => {
    setCheckIds({ item: defaultSetItems, category: [] });
  }, [props.isModalOpen]);

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "item" | "category"
  ): void => {
    const id = +event.target.value;
    let { item, category } = checkIds;
    if (event.target.checked) {
      if (type === "item") {
        item = item.includes(id) ? item : [...item, id];
      }
      if (type === "category") {
        category = category.includes(id) ? category : [...category, id];
      }
    } else {
      if (type === "item") {
        item = item.filter((i) => i !== id);
      }
      if (type === "category") {
        category = category.filter((c) => c !== id);
      }
    }
    setCheckIds({ item, category });
  };

  const handleOnClose = (): void => {
    props.onClose();
    setCheckIds({ item: [], category: [] });
  };

  // 非表示かつチェックの入っていないカテゴリーが持つチェック済み項目を排除
  const removeInvisibleCategoryItems = (): number[] => {
    let { item } = checkIds;
    if (props.isCategorized) {
      item = checkIds.item.filter((id) => {
        const category = props.customRecords.find((r) =>
          r.custom_record_items.find((i) => id === i.id)
        );
        return !(
          category &&
          category.visibility === 0 &&
          !checkIds.category.includes(category.id)
        );
      });

      if (
        !!props.facilityType &&
        (props.facilityType === FacilityType.KEIKAKUSODAN ||
          props.facilityType === FacilityType.CHIIKIIKO ||
          props.facilityType === FacilityType.CHIIKITEICHAKU)
      ) {
        // 表示非表示の切り替えが不可の項目を排除
        item = item.filter((id) =>
          props.customRecords.some((r) =>
            r.custom_record_items.some(
              (i) =>
                id === i.id && i.allow_change_visibility !== INT_FALSE_FROM_API
            )
          )
        );
      }
    }
    return item;
  };

  // 表示から非表示にした項目のリスト
  const invisibleItems = (): number[] => {
    return defaultSetItems.filter((i) => !checkIds.item.some((id) => id === i));
  };

  const handleOnVisible = async (): Promise<void> => {
    await props.onVisible(
      {
        category: checkIds.category,
        item: removeInvisibleCategoryItems()
      },
      {
        category: [],
        item: invisibleItems()
      }
    );
    props.onClose();
    setCheckIds({ item: [], category: [] });
  };

  if (hiddenCustomRecords.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={props.isModalOpen}
      disableBackdropClick
      maxWidth={false}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        非表示項目リスト
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentSection}>
          <p className={classes.note}>
            表示のチェックをオンにすると、再び記録画面に表示されるようになります。
          </p>
          <div
            className={
              props.isCategorized
                ? classes.contentHeaderCategory
                : classes.contentHeaderItem
            }
          >
            <div
              className={
                isServiceDelivery
                  ? classes.contentItemNameForService
                  : classes.contentHeaderName
              }
            >
              {props.isCategorized && "カテゴリー / "}項目名
            </div>
            {isServiceDelivery && (
              <div className={classes.contentItemType}>入力形式</div>
            )}
            <div className={classes.contentHeaderCheckBox}>表示</div>
          </div>
          {hiddenCustomRecords.map((record) => {
            const category = props.isCategorized
              ? [
                  <ExpansionPanelSummary key={`summary-${record.id}`}>
                    <div
                      key={`content-category-${record.id}`}
                      className={classes.contentCategory}
                    >
                      <div className={classes.contentItemName}>
                        {record.name}
                      </div>
                      <div className={classes.contentItemCheckBox}>
                        {record.visibility === 0 && (
                          <MuiCheckbox
                            id={`item-checkbox-${record.id}`}
                            label=""
                            checked={checkIds.category.includes(record.id)}
                            value={`${record.id}`}
                            onChange={(e): void => {
                              handleChangeCheckbox(e, "category");
                            }}
                            style={{ margin: "0 0 -2px" }}
                          />
                        )}
                      </div>
                    </div>
                  </ExpansionPanelSummary>
                ]
              : [];
            // カテゴリーが非表示の場合、カテゴリーにチェックされていないなら項目を表示しない
            const expandedFlg = !(
              props.isCategorized &&
              record.visibility === 0 &&
              !checkIds.category.includes(record.id)
            );

            const item = record.custom_record_items.map((i) => {
              return (
                <div
                  key={`content-item-${i.id}`}
                  className={classes.contentItem}
                >
                  <div
                    className={
                      isServiceDelivery
                        ? classes.contentItemNameForService
                        : classes.contentItemName
                    }
                  >
                    {i.name}
                  </div>
                  {isServiceDelivery && (
                    <div className={classes.contentItemType}>
                      {CUSTOM_RECORD_INPUT_TYPE[i.input_type]}
                    </div>
                  )}
                  {(props.facilityType === FacilityType.KEIKAKUSODAN ||
                    props.facilityType === FacilityType.CHIIKIIKO ||
                    props.facilityType === FacilityType.CHIIKITEICHAKU) &&
                  i.allow_change_visibility === INT_FALSE_FROM_API ? (
                    <></>
                  ) : (
                    <div className={classes.contentItemCheckBox}>
                      <MuiCheckbox
                        id={`item-checkbox-${record.id}`}
                        label=""
                        checked={checkIds.item.includes(i.id)}
                        value={`${i.id}`}
                        onChange={(e): void => {
                          handleChangeCheckbox(e, "item");
                        }}
                        style={{ margin: "0 0 -2px" }}
                      />
                    </div>
                  )}
                </div>
              );
            });
            return (
              <ExpansionPanel key={`panel-${record.id}`} expanded={expandedFlg}>
                {category}
                <ExpansionPanelDetails key={`detail-${record.id}`}>
                  {...item}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton
          className={classNames(classes.button, classes.cancel)}
          color="secondary"
          onClick={handleOnClose}
          kind="outline"
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleOnVisible}
          disabled={
            !checkIds.category.length && !removeInvisibleCategoryItems().length
          }
        >
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const HiddenItemsWithCategoryModal = withStyles(styles)(
  HiddenItemsWithCategoryModalCore
);
