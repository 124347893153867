import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";

import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";

import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";

import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    editArea: {
      display: "flex",
      flexDirection: "column",
      gap: "32px"
    },
    row: {
      display: "flex"
    },
    staffSelect: {
      width: 240
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    }
  });

type OwnProps = {
  isNew: boolean;
  isCopy: boolean;
  isEditing: boolean;
  supportLedgerFieldValues: SupportLedger;
  supportLedgerData: SupportLedgerState["supportLedger"];
  staffs: FieldItem[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerCreateAndUpdateCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const creationStaffList = React.useMemo<FieldItem[]>(() => {
    return createSnapshotOptions(
      props.staffs,
      props.supportLedgerData.support_ledger.creation_staff
    );
  }, [props.staffs, props.supportLedgerData.support_ledger.creation_staff]);

  const creationStaffValue = React.useMemo<string>(() => {
    if (
      !props.supportLedgerData.support_ledger.creation_staff ||
      props.isNew ||
      props.isCopy
    )
      return "";
    return props.supportLedgerData.support_ledger.creation_staff.snapshot_name;
  }, [props.supportLedgerData.support_ledger.creation_staff]);

  const updateStaffList = React.useMemo<FieldItem[]>(() => {
    return createSnapshotOptions(
      props.staffs,
      props.supportLedgerData.support_ledger.update_staff
    );
  }, [props.staffs, props.supportLedgerData.support_ledger.update_staff]);

  const updateStaffValue = React.useMemo<string>(() => {
    if (
      !props.supportLedgerData.support_ledger.update_staff ||
      props.isNew ||
      props.isCopy
    )
      return "";
    return props.supportLedgerData.support_ledger.update_staff.snapshot_name;
  }, [props.supportLedgerData.support_ledger.update_staff]);

  return (
    <div>
      <div className={classes.editArea}>
        <div className={classes.row}>
          <RecordSelectDate
            name="support_ledger.creation_date"
            label="初期作成日"
            required
            value={props.supportLedgerFieldValues.support_ledger.creation_date}
            isEditable={props.isEditing}
            overrideYearFrom={1989}
          />
          <div className={classes.staffSelect}>
            <RecordSelect
              name="support_ledger.creation_staff"
              label="作成者"
              defaultValue="未設定"
              placeholder="選択してください"
              options={creationStaffList}
              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
              value={creationStaffValue}
              isEditable={props.isEditing}
              isSelectablePlaceholder
            />
          </div>
        </div>

        <div className={classes.row}>
          <RecordSelectDate
            name="support_ledger.update_date"
            label="最終更新日"
            required
            value={props.supportLedgerFieldValues.support_ledger.update_date}
            isEditable={props.isEditing}
            overrideYearFrom={1989}
          />
          <div className={classes.staffSelect}>
            <RecordSelect
              name="support_ledger.update_staff"
              label="更新者"
              defaultValue="未設定"
              placeholder="選択してください"
              options={updateStaffList}
              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
              value={updateStaffValue}
              isEditable={props.isEditing}
              isSelectablePlaceholder
            />
          </div>
        </div>
      </div>

      {!props.isNew && !props.isCopy && (
        <div className={classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={props.supportLedgerFieldValues.created_at}
            updatedAt={props.supportLedgerFieldValues.updated_at}
          />
        </div>
      )}
    </div>
  );
};

export const SupportLedgerCreateAndUpdate = withStyles(styles)(
  SupportLedgerCreateAndUpdateCore
);
