import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiCheckbox from "@components/molecules/MuiCheckbox";

import {
  getModalStampIconKey,
  getModalStampIconCheck,
  setModalStampIconCheck
} from "@utils/localStorage";
import { STRING_TRUE_FROM_API } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      "&>div:nth-child(2)>div": {
        maxWidth: "600px",
        width: "100%"
      }
    },
    dialogTitle: {
      padding: "16px 32px",
      background: "#f5f5f5",
      borderBottom: "solid 1px #cfd8dc",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    dialogContent: {
      padding: "24px 32px 0px"
    },
    dialogConText: {
      margin: 0,
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: "#37474f"
    },
    checkboxLi: {
      padding: "30px 0px 15px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    }
  });

type OwnProps = {
  title: string;
  isOpenPrintModal: boolean;
  onClosePrintModal: () => void;
  onConfirmPrintModal: (paramsOption: string) => void;
  staffLabel?: string;
  iconLabel?: string;
};

type UserProps = {
  uifId?: string;
  /** 印鑑マークの local storage key として使用する。帳票ごとのユニークな key */
  stampIconUniqueKey?: string;
  showCheckBox?: boolean;
  accountId?: number;
};

type Props = UserProps & OwnProps & WithStyles<typeof styles>;

const CommonPrintModalWithStaffCommentCore = (props: Props): JSX.Element => {
  const {
    classes,
    title,
    isOpenPrintModal,
    onClosePrintModal,
    onConfirmPrintModal,
    staffLabel = "職員コメント",
    iconLabel = "印鑑マーク",
    showCheckBox = false
  } = props;

  const localStorageKey = getModalStampIconKey(
    props.stampIconUniqueKey,
    props.uifId,
    props.accountId
  );

  const getInitialIconFlag = (key: string): boolean => {
    const savedIconFlag = getModalStampIconCheck(key);
    return savedIconFlag !== null ? savedIconFlag : true;
  };

  const initialIconFlag = getInitialIconFlag(localStorageKey);

  const [isComment, setIsComment] = React.useState<boolean>(false);
  const [isIcon, setIsIcon] = React.useState<boolean>(initialIconFlag);

  const onChangeComment = (): void => {
    setIsComment(!isComment);
  };
  const onChangeIcon = (): void => {
    const newIconFlag = !isIcon;
    setIsIcon(newIconFlag);
    setModalStampIconCheck(localStorageKey, newIconFlag);
  };

  const onClickSubmit = (): void => {
    const paramsArray = [];
    if (isComment) {
      paramsArray.push("display_comment=staff_comment");
    }
    if (showCheckBox && isIcon) {
      paramsArray.push("display_icon=stamp_icon");
    }
    const params = paramsArray.length > 0 ? `?${paramsArray.join("&")}` : "";
    onConfirmPrintModal(params);
  };

  return (
    <Dialog className={classes.dialog} open={isOpenPrintModal}>
      <DialogTitle className={classes.dialogTitle}>
        {title}
        {"　"}印刷項目
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <p className={classes.dialogConText}>
          以下の項目以外で、入力された全ての項目が印刷されます。
          <br />
          追加で印刷したい項目にチェックしてください。
        </p>
        <div className={classes.checkboxLi}>
          <MuiCheckbox
            style={{ marginBottom: "5px" }}
            value={STRING_TRUE_FROM_API}
            id="comment_flg"
            label={staffLabel}
            checked={isComment}
            onChange={onChangeComment}
          />
          {showCheckBox && (
            <MuiCheckbox
              style={{ marginBottom: "5px" }}
              value={STRING_TRUE_FROM_API}
              id="icon_flg"
              label={iconLabel}
              checked={isIcon}
              onChange={onChangeIcon}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton kind="outline" onClick={onClosePrintModal}>
          キャンセル
        </KnowbeButton>
        <KnowbeButton kind="default" onClick={onClickSubmit}>
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const CommonPrintModalWithStaffComment = withStyles(styles)(
  CommonPrintModalWithStaffCommentCore
);
