import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import { SelectDateValue } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    secondaryWrapper: {
      backgroundColor: "#fafafa",
      borderRadius: "4px",
      border: "1px solid #bdbdbd",
      fontSize: 14,
      padding: 24
    },
    inputBundler: {
      "&:not(:last-child)": {
        paddingBottom: 12,
        marginBottom: 12,
        borderBottom: "1px solid #e0e0e0"
      },
      "&:nth-child(3)": {
        "& > div": {
          marginTop: 24
        }
      }
    },
    inputAlignment: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    secondaryInputLabel: {
      width: 150
    },
    textField: {
      flex: 1
    }
  });

type OwnProps = {
  index: number;
  isEditing: boolean;
  values: {
    support_plan_goal: {
      id?: number;
      number: number;
      sprint_goal: string;
      adopt_info: string;
      support_info: string;
      sprint_start_date: SelectDateValue;
      sprint_end_date: SelectDateValue;
      sprint_result: string;
      achievement: string;
      satisfaction: string;
      countermeasure: string;
    }[];
  };
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 目標達成に向けた支援方針・内容・期間・頻度
 */
const SupportPlanGoalEvaluationFieldsCore = (props: Props): JSX.Element => {
  const radioButtonStyle: React.CSSProperties = {
    flexDirection: "row",
    margin: 0
  };
  const value = props.values.support_plan_goal[props.index];

  return (
    <div className={props.classes.secondaryWrapper}>
      {props.index >= 2 && (
        <div className={props.classes.inputBundler}>
          <div className={props.classes.inputAlignment}>
            <div className={props.classes.secondaryInputLabel}>実施</div>
            <FormikRadioButtons
              name={`support_plan_goal[${props.index}]['sprint_result']`}
              label=""
              options={[
                { label: "実施", value: "1", disabled: !props.isEditing },
                { label: "一部実施", value: "2", disabled: !props.isEditing },
                { label: "未実施", value: "0", disabled: !props.isEditing }
              ]}
              style={radioButtonStyle}
            />
          </div>
        </div>
      )}
      <div className={props.classes.inputBundler}>
        <div className={props.classes.inputAlignment}>
          <div className={props.classes.secondaryInputLabel}>
            {props.index >= 2 ? "達成度" : "目標達成度"}
          </div>
          <FormikRadioButtons
            name={`support_plan_goal[${props.index}]['achievement']`}
            label=""
            options={[
              { label: "達成", value: "1", disabled: !props.isEditing },
              { label: "一部達成", value: "2", disabled: !props.isEditing },
              { label: "未達成", value: "0", disabled: !props.isEditing }
            ]}
            style={radioButtonStyle}
          />
        </div>
      </div>
      {props.index >= 2 && (
        <div className={props.classes.inputBundler}>
          <div className={props.classes.inputAlignment}>
            <div className={props.classes.secondaryInputLabel}>
              効果・満足度など
            </div>
            <div className={props.classes.textField}>
              <RecordTextField
                name={`support_plan_goal[${props.index}]['satisfaction']`}
                value={value.satisfaction}
                defaultValue="-"
                placeholder=""
                isEditable={props.isEditing}
              />
            </div>
          </div>
          <div className={props.classes.inputAlignment}>
            <div className={props.classes.secondaryInputLabel}>
              残課題と対策
            </div>
            <div className={props.classes.textField}>
              <RecordTextField
                name={`support_plan_goal[${props.index}]['countermeasure']`}
                value={value.countermeasure}
                defaultValue="-"
                placeholder=""
                isEditable={props.isEditing}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const SupportPlanGoalEvaluationFields = withStyles(styles)(
  SupportPlanGoalEvaluationFieldsCore
);
