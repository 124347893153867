import React, { useState, useRef, useEffect } from "react";

import { FormikProps } from "formik";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";

import ConfirmDialog from "@components/atoms/ConfirmDialog";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { OutlineUploadIcon } from "@images/icons/OutlineUploadIcon";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      backgroundColor: "#f5f5f5",
      padding: 16,
      display: "flex",
      justifyContent: "space-between"
    },
    fieldTitle: {
      display: "block",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#37474f"
    },
    serviceCodeMainText: {
      marginTop: 20,
      marginBottom: 0,
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: "28px"
    },
    serviceCodeDateArea: {
      marginTop: "7px"
    },
    serviceCodeDateText: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "#37474f",
      margin: 0
    },
    actionArea: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end"
    },
    actionButtonText: {
      marginLeft: 8,
      whiteSpace: "nowrap"
    }
  });

type OwnProps = {
  fileFieldName: string;
  /** APIで保存済みのCSVファイル名 */
  savedCsvFileName: string;
  /** APIで保存済みのCSVアップロード日 */
  savedCsvUploadedDate: string;
  setFieldValue: FormikProps<{}>["setFieldValue"];
};

type Props = OwnProps & WithStyles<typeof styles>;

const FileInformation = ({
  classes,
  ...props
}: {
  fileName: string;
  uploadDate: string;
} & WithStyles<typeof styles>): JSX.Element => {
  return (
    <>
      <p className={classes.serviceCodeMainText}>{props.fileName}</p>
      {props.uploadDate ? (
        <div className={classes.serviceCodeDateArea}>
          <p className={classes.serviceCodeDateText}>
            アップロード日時：{props.uploadDate}
          </p>
        </div>
      ) : null}
    </>
  );
};

const DATE_TIME_FORMAT = "YYYY年M月D日HH:mm";

/**
 * 自治体情報 > サービスコードアップロード部分のコンポーネント
 */
const MunicipalityServiceCodeCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(
    false
  );
  const [isOpenOverSizeDialog, setIsOpenOverSizeDialog] = useState<boolean>(
    false
  );
  const [tmpUploadFile, setTmpUploadFile] = useState<File | null>(null);

  /*
   * 表示項目はファイル選択時と更新画面を開いた時で制御が必要なため
   * useStateを使用して管理を行う
   */
  // ファイル名
  const [displayFileName, setDisplayFileName] = useState<string>("");
  // アップロード日時
  const [displayUploaded, setDisplayUploaded] = useState<string>("");

  const inputFileRef = useRef<HTMLInputElement>(null);
  /** ファイルをブラウザで保持しているか */
  const hasFilePool = displayFileName && !displayUploaded;

  // APIで保存済みのCSV情報を表示する処理
  useEffect(() => {
    if (props.savedCsvFileName) {
      setDisplayFileName(props.savedCsvFileName);
    }
    if (props.savedCsvUploadedDate) {
      setDisplayUploaded(
        dateToLocalisedString(props.savedCsvUploadedDate, DATE_TIME_FORMAT)
      );
    }
    return (): void => {
      setDisplayFileName("");
      setDisplayUploaded("");
    };
  }, [props.savedCsvFileName, props.savedCsvUploadedDate]);

  /** サービスコード未登録 */
  const isNotSetServiceCode = !displayFileName;

  // アップロードをクリックしたらファイルをクリックして開く
  const handleClickUpload = (): void => {
    if (inputFileRef.current) {
      // ここでフィールドの初期化をしないと、同じファイル名を再選択したときにinputのonChangeが動かない。
      inputFileRef.current.value = "";
      inputFileRef.current.click();
    }
  };

  const handleClickCancel = (): void => {
    if (props.savedCsvFileName) {
      setDisplayFileName(props.savedCsvFileName);
    } else {
      setDisplayFileName("");
    }
    if (props.savedCsvUploadedDate) {
      setDisplayUploaded(
        dateToLocalisedString(props.savedCsvUploadedDate, DATE_TIME_FORMAT)
      );
    } else {
      setDisplayUploaded("");
    }
    props.setFieldValue(props.fileFieldName, null);
  };

  const handleChangeServiceCodeFile: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    if (!e.currentTarget.files || !e.currentTarget.files.length) {
      // ファイルが選ばれていない
      return;
    }
    const file = e.currentTarget.files[0];
    if (!file) {
      return;
    }
    if (file.size > 1024 * 1024 * 1) {
      // 1MBを超えている
      setIsOpenOverSizeDialog(true);
      return;
    }
    setTmpUploadFile(file);
    setIsOpenConfirmDialog(true);
  };

  const handleClickDialogUpload = (): void => {
    if (tmpUploadFile) {
      setDisplayFileName(tmpUploadFile.name);
      setDisplayUploaded("");
      props.setFieldValue(props.fileFieldName, tmpUploadFile);
    }
    setTmpUploadFile(null);
    setIsOpenConfirmDialog(false);
  };

  const handelClickCancel = (): void => {
    setIsOpenConfirmDialog(false);
  };

  const handleClickOverSizeDialogClose = (): void => {
    setIsOpenOverSizeDialog(false);
  };
  return (
    <>
      <div className={classes.wrapper}>
        {/* 表示側 */}
        <div>
          <span className={classes.fieldTitle}>サービスコードデータ</span>
          {isNotSetServiceCode ? (
            <p className={classes.serviceCodeMainText}>
              サービスコードデータが登録されていません
            </p>
          ) : (
            <FileInformation
              classes={classes}
              fileName={displayFileName}
              uploadDate={displayUploaded}
            />
          )}
        </div>

        {/* ボタン側 */}
        <div className={classes.actionArea}>
          {hasFilePool ? (
            <KnowbeButton kind="iconText" onClick={handleClickCancel}>
              <CancelIcon />
              <span className={classes.actionButtonText}>取り消し</span>
            </KnowbeButton>
          ) : (
            <KnowbeButton kind="iconText" onClick={handleClickUpload}>
              <OutlineUploadIcon />
              <span className={classes.actionButtonText}>アップロード</span>
            </KnowbeButton>
          )}
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputFileRef}
            accept=".csv"
            onChange={handleChangeServiceCodeFile}
          />
        </div>
      </div>

      <ConfirmDialog
        isOpen={isOpenConfirmDialog}
        title="サービスコードをアップロードします"
        submitLabel="アップロード"
        // onDeleteとなっているが、submit側のボタン押下時の処理
        onDelete={handleClickDialogUpload}
        onCancel={handelClickCancel}
        message="この操作を実行し、保存をするとサービスコードデータが更新されます。よろしいですか？"
      />

      <MessageDialog
        isOpen={isOpenOverSizeDialog}
        title="ご確認ください"
        message="ファイルサイズは１MB以内でアップロードしてください。"
        closeButton={
          <KnowbeButton kind="text" onClick={handleClickOverSizeDialogClose}>
            閉じる
          </KnowbeButton>
        }
      />
    </>
  );
};

export const MunicipalityServiceCode = withStyles(styles)(
  MunicipalityServiceCodeCore
);
