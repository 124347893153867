/**
 * ActionNames
 */

export const FETCH_STARTED = "KEIKAKUSODAN/FACILITY_202104/FETCH_STARTED";
export const FETCH_SUCCESS = "KEIKAKUSODAN/FACILITY_202104/FETCH_SUCCESS";
export const FETCH_FAILED = "KEIKAKUSODAN/FACILITY_202104/FETCH_FAILED";
export const POST_STARTED = "KEIKAKUSODAN/FACILITY_202104/POST_STARTED";
export const POST_SUCCESS = "KEIKAKUSODAN/FACILITY_202104/POST_SUCCESS";
export const POST_FAILED = "KEIKAKUSODAN/FACILITY_202104/POST_FAILED";

/**
 * Basic
 *
 * コメントは、施設情報APIでの対応するパラメーター名
 */
export type FacilityState = {
  corporationName: string; // facility.gov_business_owner
  serviceType: string; // facility.type_service
  capacity: string; // facility.capacity
  postalCode: string; // facility.postal_code
  selectedPrefectureName: string; // facility.prefecture_name
  selectedCityCode: string; // facility.city_id
  restAddress: string; // facility.address
  cityId: string; // facility.city_id
  integratedManagementHandicappedChildFlg: boolean;
  /**
   * 施設区分
   * 1:計画相談
   * 2:障害児相談
   * 3:計画相談/障害児相談
   * null:通信前の初期値やPOSTデータなどでこのデータが存在しない場合なので、取得後のstore格納時は発生しない想定
   */
  facilityType: null | number;
  facilities_keikakusodan_shogaijisodan: {
    target_type: number;
    govFacilityNumber: string;
    name: string;
    tel: string;
    responsiblePerson: string;
    functionEnhancedSystem: string;
    behavioralDisorderSupportFlg: boolean;
    needMedicalCareHandicappedChildSupportFlg: boolean;
    mentalDisorderSupportFlg: boolean;
    peerSupportFlg: boolean;
    lifeSupportHubInDistrictFlg: boolean;
  }[];
};
