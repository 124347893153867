import * as React from "react";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import { SERVICE_DELIVERY_PRINT_TYPE_OPTIONS } from "@constants/variables";

type OwnProps = {
  value: string;
  changeFormType: (e: React.ChangeEvent<HTMLInputElement>) => void;
  printTypeOptions: typeof SERVICE_DELIVERY_PRINT_TYPE_OPTIONS;
};

type Props = OwnProps;

export const PrintFormTypeServiceDelivery = (props: Props): JSX.Element => {
  return (
    <MuiRadioButtons
      label=""
      options={props.printTypeOptions}
      value={props.value}
      onChange={(e): void =>
        props.changeFormType(e as React.ChangeEvent<HTMLInputElement>)
      }
    />
  );
};
