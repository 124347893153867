import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import { StyleRules } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    groupDate: {
      marginTop: 6,
      marginRight: 16
    },
    sectionTitle: {
      marginBottom: 18
    }
  });

type Props = WithStyles<typeof styles>;

const FirstInvoiceDataFields = (props: Props): JSX.Element => {
  return (
    <FormPaper>
      <div className={props.classes.sectionTitle}>
        <SectionTitle label="初回請求月の指定" />
      </div>
      <p>初回の請求作業の対象となる月を入力して下さい。</p>
      <div className={props.classes.groupDate}>
        <FormikSelectYearMonth
          name="initialData.facility.first_time_bill_date"
          label="初回請求年月"
          style={{ marginBottom: 12 }}
          addYearTo={1}
        />
      </div>
    </FormPaper>
  );
};

export default withStyles(styles)(FirstInvoiceDataFields);
