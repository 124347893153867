const castStringOrNull = (
  value: string | number | null | undefined
): string | null => {
  if (value === undefined || value === null) {
    return null;
  }
  return `${value}`;
};

export default castStringOrNull;
