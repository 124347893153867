import * as React from "react";
import ClassNames from "classnames";

import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";

import tableheader_tips from "@images/tableheader_tips.svg";

const styles = (): StyleRules =>
  createStyles({
    tooltip: {
      fontSize: 12
    },
    tooltipAreaLarge: {
      maxWidth: 400
    },
    popper: {
      opacity: 1
    },
    icon: {
      color: "#607d8b",
      marginRight: 16
    }
  });

type OwnProps = {
  title: string | JSX.Element;
  tableHeader?: boolean;
  size?: "inherit" | "default" | "large" | "small";
  messageAreaSize?: "large";
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
};

type Props = OwnProps & WithStyles<typeof styles>;

const helpToolTip = ({
  title,
  tableHeader,
  placement,
  size,
  classes,
  messageAreaSize
}: Props): JSX.Element => (
  <Tooltip
    classes={{
      tooltip: ClassNames(classes.tooltip, {
        [classes.tooltipAreaLarge]: messageAreaSize === "large"
      }),
      popper: classes.popper
    }}
    placement={placement || "right-start"}
    title={title}
  >
    {tableHeader ? (
      <img src={tableheader_tips} alt="" />
    ) : (
      <HelpIcon className={classes.icon} fontSize={size || "small"} />
    )}
  </Tooltip>
);

export default withStyles(styles)(helpToolTip);
