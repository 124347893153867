import * as React from "react";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikSwitch from "@components/molecules/FormikSwitch";
import { WorkingTimeItemsFields } from "@components/v202104/organisms/mgr/IAB/users/items/WorkingTimeItemsFields";
import { WorkBreakTimesFields } from "@components/v202104/organisms/mgr/IAB/users/items/WorkBreakTimesFields";
import { ChangeEverydayFields } from "@components/v202104/organisms/mgr/IAB/users/items/ChangeEverydayFields";

// formik
import { FormikProps } from "formik";
import { UsersValues } from "@initialize/v202104/mgr/IAB/users/initialValues";

// other
import { UNIT_ENGRAVE } from "@constants/mgr/IAB/variables";
import * as ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    workingTimeSection: { marginTop: 29 },
    info: {
      fontSize: 16,
      color: "#666",
      marginBottom: 32
    },
    infoIcon: {
      color: "#0277bd",
      width: 20,
      height: 20,
      marginRight: 6
    },
    caption: {
      color: "#37474f",
      display: "flex",
      alignItems: "center"
    },
    description: {
      margin: "8px 16px"
    },
    label: {
      fontSize: 14,
      display: "flex",
      alignItems: "center",
      marginBottom: 12,
      "& :first-child": {
        marginRight: 6
      }
    },
    section: {
      marginBottom: 32
    },
    indentation: {
      marginLeft: 16
    },
    workTimeItems: {
      marginLeft: -32
    },
    formikActionLabel: {
      "&+span": {
        fontSize: 16
      },
      "& label span": {
        fontSize: 16
      }
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
};
type Props = OwnProps & WithStyles<typeof styles>;

const WorkingTimeFieldsCore = ({
  classes,
  formikProps
}: Props): JSX.Element => {
  /**
   * 刻む時間が変更されたときに、時間設定項目にバリデーションをかける
   */
  const onChangeUnitEngrave = React.useCallback((): void => {
    // 作業時間
    formikProps.setFieldTouched("workingTime.startMin", true);
    formikProps.setFieldTouched("workingTime.endMin", true);

    // 休憩時間
    formikProps.values.workingTime.workBreakTimes.forEach((item, index) => {
      formikProps.setFieldTouched(
        `workingTime.workBreakTimes[${index}].startTimeHour`,
        true
      );
      formikProps.setFieldTouched(
        `workingTime.workBreakTimes[${index}].startTimeMinute`,
        true
      );
      formikProps.setFieldTouched(
        `workingTime.workBreakTimes[${index}].endTimeHour`,
        true
      );
      formikProps.setFieldTouched(
        `workingTime.workBreakTimes[${index}].endTimeMinute`,
        true
      );
    });

    // 曜日ごと
    formikProps.values.workingTime.workTimeItems.forEach((item, index) => {
      formikProps.setFieldTouched(
        `workingTime.workTimeItems[${index}].startTimeMinute`,
        true
      );
      formikProps.setFieldTouched(
        `workingTime.workTimeItems[${index}].endTimeMinute`,
        true
      );
    });
  }, [
    formikProps.values.workingTime.workBreakTimes,
    formikProps.values.workingTime.workTimeItems
  ]);

  return (
    <div className={classes.workingTimeSection}>
      <FormikCheckbox
        name="serviceUse.defRecordWork"
        label="作業時間を自動的に入力する"
        className={ClassNames(classes.formikActionLabel)}
        tooltip={
          <HelpToolTip title={<HelpTipMessages name="def_record_work" />} />
        }
      />
      <FormikSwitch
        name="workingTime.workTimeUseFlg"
        label="利用者ごとの作業時間を設定する"
        className={classes.formikActionLabel}
        style={{ marginBottom: 0 }}
      >
        <div className={classes.info}>
          <div className={classes.caption}>
            <InfoOutlinedIcon className={classes.infoIcon} />
            作業・休憩時間の設定について
          </div>
          <p className={classes.description}>
            ここで設定した作業時間と休憩時間を基準に、利用者の作業合計時間が算出されます。
            <br />
            例）作業時間が8時間で休憩の合計時間を1時間に設定した場合、作業合計時間は休憩の合計時間を差し引いた7時間となります。
          </p>
        </div>

        <section>
          <div className={classes.label}>
            <span>刻む単位</span>
            <HelpToolTip title={<HelpTipMessages name="unitEngraveFlag" />} />
          </div>
          <div className={classes.indentation}>
            <FormikSelect
              name="workingTime.unitEngrave"
              label=""
              options={UNIT_ENGRAVE}
              style={{ marginTop: -16 }}
              onChangeHook={onChangeUnitEngrave}
            />
          </div>
        </section>

        <section>
          <div className={classes.label}>作業時間</div>
          <div className={classes.indentation}>
            <WorkingTimeItemsFields formikProps={formikProps} />
          </div>
        </section>

        <section className={classes.section}>
          <div className={classes.label}>休憩時間</div>
          <div className={classes.indentation}>
            <WorkBreakTimesFields formikProps={formikProps} />
          </div>
        </section>

        <FormikSwitch
          name="workingTime.dayOfWeekFlag"
          label="曜日ごとに作業時間と休憩時間を設定する"
        >
          <div className={classes.workTimeItems}>
            <ChangeEverydayFields formikProps={formikProps} />
          </div>
        </FormikSwitch>
      </FormikSwitch>
    </div>
  );
};

export const WorkingTimeFields = withStyles(styles)(WorkingTimeFieldsCore);
