import { GetFacilityMunicipalitiesTargetIdResponse } from "@api/requests/facility/getFacilityMunicipalitiesTargetId";
import { MUNICIPALITY_BILLING_UNIT_PRICE } from "@constants/mgr/IDOSHIEN/variables";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type MunicipalityValues = {
  /** 自治体名 */
  name: string;
  /** 算定時間切り上げフラグ */
  calculationTimeFlag: boolean;
  /** 切り上げ時間（分） */
  roundUpMinute: string;
  /** 時間区分 */
  timeDivisionFlg: boolean;
  /** 請求単位 */
  billingUnit: string;
  /** 1単位あたりの単価 */
  unitPricePerUnit: string;
  /** サービスコードのCSVファイル */
  serviceCodeCsv: File | null;
};

const generateInitialValues = (): MunicipalityValues => ({
  name: "",
  calculationTimeFlag: false,
  roundUpMinute: "",
  timeDivisionFlg: false,
  billingUnit: MUNICIPALITY_BILLING_UNIT_PRICE,
  unitPricePerUnit: "",
  serviceCodeCsv: null
});

/** 新規の自治体情報Formikデータ作成 */
export const initialValuesNew = (): MunicipalityValues => {
  return generateInitialValues();
};

/** 編集の自治体情報Formikデータ作成 */
export const initialValuesEdit = (
  municipalityData: GetFacilityMunicipalitiesTargetIdResponse["data"]["municipality"]
): MunicipalityValues => {
  return {
    name: municipalityData.name,
    calculationTimeFlag: numberToBoolean(municipalityData.calculation_time_flg),
    roundUpMinute: String(municipalityData.round_up_minute || ""),
    timeDivisionFlg: numberToBoolean(municipalityData.time_division_flg),
    billingUnit: String(municipalityData.billing_unit),
    unitPricePerUnit: String(municipalityData.unit_price_per_unit || ""),
    serviceCodeCsv: null
  };
};
