import { BasicValues } from "@interfaces/mgr/SHUROTEICHAKU/facility/basic";
import { SubtractionItemValues } from "@interfaces/mgr/SHUROTEICHAKU/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/mgr/SHUROTEICHAKU/facility/additionItem";
import { FacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import { initializeSpecificBetterSupporterCondition202404V } from "@utils/domain/facility/initializeSpecificBetterSupporterCondition202404V";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues;

const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : ""
    },
    subtractionItem: {
      rateGetJob: state ? state.rateGetJob : "1",
      lackFlag: state
        ? state.lackOfLifeSupportMemberFlag || state.lackOfResponsiblePersonFlag
        : false,
      lackOfLifeSupportMemberFlag: state
        ? state.lackOfLifeSupportMemberFlag
        : false,
      lackOfLifeSupportMemberStartDate:
        state && state.lackOfLifeSupportMemberStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfLifeSupportMemberStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }, // APIの仕様上dayは1固定
      lackOfResponsiblePersonFlag: state
        ? state.lackOfResponsiblePersonFlag
        : false,
      lackOfResponsiblePersonStartDate:
        state && state.lackOfResponsiblePersonStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfResponsiblePersonStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }, // APIの仕様上dayは1固定
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      ),
      workContinuationNotPlanningFlg: state
        ? state.workContinuationNotPlanningFlg
        : false,
      workContinuationNotPlanningFrom: initializeSelectDate(
        state && state.workContinuationNotPlanningFrom
      ),
      workContinuationNotPlanningTo: initializeSelectDate(
        state && state.workContinuationNotPlanningTo
      )
    },
    additionalItem: {
      workHardenesResultFlag: state ? state.workHardenesResultFlag : false,
      workPlaceAdaptationAssistantFlag: state
        ? state.workPlaceAdaptationAssistantFlag
        : false,
      specificBetterSupporterCondition202404: state
        ? initializeSpecificBetterSupporterCondition202404V(
            state.specificBetterSupporterCondition202404
          )
        : NO_SELECTED_CONDITION_TYPE_2024,
      specificBetterSupporterCondition202404Sub:
        state && state.specificBetterSupporterCondition202404
          ? state.specificBetterSupporterCondition202404
          : NO_SELECTED_CONDITION_TYPE_2024_SUB,
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      specificBetterSupporterCondition: state
        ? state.specificBetterSupporterCondition
        : false,
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false
    }
  };
};

export default initialValues;
