import { Dispatch } from "redux";
import get from "lodash-es/get";
import * as action from "@stores/domain/account/action";
import dispatches from "@stores/dispatches";
import {
  UpdatePasswordParams,
  NewAccountParams,
  NewMobileAccountParams
} from "@stores/domain/account/type";
import * as H from "history";
import {
  ACCOUNT_ACTIVATE_COMPLETE,
  MOBILE_ACCOUNT_ACTIVATE_COMPLETE
} from "@constants/url";

import facilityApi from "@api/requests/facility";
import userApi from "@api/requests/user";
import authApi from "@api/requests/auth";

/**
 * Check parameter validity saga
 * This saga is using for before user activation
 */
const checkActivateValidity = (dispatch: Dispatch) => async (
  email: string,
  token: string
): Promise<void> => {
  dispatch(action.getURLValidity.started({}));
  const uiDispatch = dispatches.uiDispatch(dispatch);
  uiDispatch.loading(true);
  await authApi
    .getCheck(email, token)
    .then((res) => {
      uiDispatch.loading(false);
      dispatch(action.getURLValidity.done({ params: {}, result: res.data }));
    })
    .catch((e) => {
      uiDispatch.loading(false);
      dispatch(
        action.getURLValidity.failed({
          params: {},
          error: e.response
        })
      );
    });
};

/**
 * 更新対象のユーザーを取得する
 */
const getTargetFacilityUser = (dispatch: Dispatch) => async (): Promise<
  void
> => {
  dispatch(action.getTargetFacilityUser.started({}));
  const uiDispatch = dispatches.uiDispatch(dispatch);
  uiDispatch.loading(true);
  await facilityApi
    .getTargetFacilityUser()
    .then((res) => {
      uiDispatch.loading(false);
      dispatch(
        action.getTargetFacilityUser.done({ params: {}, result: res.data })
      );
    })
    .catch((e) => {
      uiDispatch.loading(false);
      dispatch(
        action.getTargetFacilityUser.failed({
          params: {},
          error: e.response
        })
      );
    });
};

/**
 * パスワードの更新を行う
 */
const updatePassword = (dispatch: Dispatch) => async (
  params: UpdatePasswordParams,
  formName: string
): Promise<void> => {
  dispatch(action.updatePassword.started({ formName }));
  const uiDispatch = dispatches.uiDispatch(dispatch);
  uiDispatch.loading(true);
  await userApi
    .postUpdateUserPassword(params)
    .then(() => {
      dispatch(
        action.updatePassword.done({ params: { formName }, result: {} })
      );
      // 管理アカウントのパスワード変更成功時に強制ログアウトさせる
      if (formName === "admin") {
        dispatches.authDispatch(dispatch).logout();
      }
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "パスワードの変更が完了しました",
        variant: "success"
      });
    })
    .catch((e) => {
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message:
          get(e.response, "data[0].error[0].message") ||
          "パスワードの変更に失敗しました",
        variant: "error"
      });
      dispatch(
        action.updatePassword.failed({
          params: { formName },
          error: e.response.data
        })
      );
    });
  uiDispatch.loading(false);
};

/**
 * モバイルアカウントの発行
 */
const activateMobileAccount = (dispatch: Dispatch) => async (
  params: NewMobileAccountParams,
  history: H.History
): Promise<void> => {
  dispatch(action.postNewMobileUser.started({}));
  const uiDispatch = dispatches.uiDispatch(dispatch);
  uiDispatch.loading(true);
  await userApi
    .postNewMobileUser(params)
    .then(() => {
      dispatch(action.postNewMobileUser.done({ params: {}, result: params }));
      dispatches.uiDispatch(dispatch).stopHistory(false);
      history.push(MOBILE_ACCOUNT_ACTIVATE_COMPLETE);
    })
    .catch((e) => {
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "アカウントの作成に失敗しました",
        variant: "error"
      });
      dispatch(
        action.postNewMobileUser.failed({
          params: {},
          error: e.response.data
        })
      );
    });
  uiDispatch.loading(false);
};

const activate = (dispatch: Dispatch) => async (
  params: NewAccountParams,
  token: string,
  history: H.History
): Promise<void> => {
  const uiDispatch = dispatches.uiDispatch(dispatch);
  uiDispatch.loading(true);
  await authApi
    .postActivate(params, token)
    .then(() => {
      uiDispatch.loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "アカウントの登録が完了しました",
        variant: "success"
      });
      dispatch(action.refreshActivateErrorMessage());
      history.push(ACCOUNT_ACTIVATE_COMPLETE);
    })
    .catch((e) => {
      uiDispatch.loading(false);
      dispatch(action.activateFailed(e.response.data));
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "アカウントの登録に失敗しました",
        variant: "error"
      });
    });
};

const refreshActivateErrorMessage = (dispatch: Dispatch) => (
  role: string,
  target: string
): void => {
  if (role === "mgradmin" && target === "password") {
    dispatch(action.refreshActivateErrorMessageMgrAdminPassword());
  } else if (role === "mgruser" && target === "accountId") {
    dispatch(action.refreshActivateErrorMessageMgrUserAccountId());
  } else if (role === "mgruser" && target === "password") {
    dispatch(action.refreshActivateErrorMessageMgrUserPassword());
  }
};

type Dispatcher = {
  getTargetFacilityUser: () => Promise<void>;
  updatePassword: (
    params: UpdatePasswordParams,
    formName: string
  ) => Promise<void>;
  activateMobileAccount: (
    params: NewMobileAccountParams,
    history: H.History
  ) => Promise<void>;
  activate: (
    params: NewAccountParams,
    token: string,
    history: H.History
  ) => Promise<void>;
  refreshActivateErrorMessage: (role: string, target: string) => void;
  checkActivateValidity: (email: string, token: string) => Promise<void>;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    getTargetFacilityUser: getTargetFacilityUser(dispatch),
    updatePassword: updatePassword(dispatch),
    activateMobileAccount: activateMobileAccount(dispatch),
    activate: activate(dispatch),
    refreshActivateErrorMessage: refreshActivateErrorMessage(dispatch),
    checkActivateValidity: checkActivateValidity(dispatch)
  };
}
