/**
 * ログイン管理画面のヘッダー
 */
import * as React from "react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (): StyleRules =>
  createStyles({
    grow: {
      flexGrow: 1
    },
    menuButton: {
      marginLeft: 2,
      marginRight: 2
    },
    header: {
      width: "100%"
    },
    link: {
      padding: "8px 0",
      textDecoration: "none",
      color: "#37474f",
      "&:hover": {
        backgroundColor: "#eeeeee"
      }
    },
    text: {
      color: "#37474f"
    },
    versionSelect: {
      marginRight: 16
    }
  });

type OwnProps = {
  toggleDrawer: () => void;
  pageName: string;
  pathList?: { pathName: string; path: string }[];
  version?: { current: "1" | "2"; changePath: string };
  invoiceVersion?: {
    /** 報酬改定の現在ページのバージョン */
    currentVersion: number;
    /** 報酬改定の選択肢 */
    versionOptions: {
      value: number;
      label: string;
    }[];
    /** 報酬改定の遷移先パス一覧 */
    versionPaths: {
      version: number;
      url: string;
    }[];
  };
};
type Props = OwnProps & WithStyles<typeof styles> & RouteComponentProps;

const Header = ({ pageName = "", ...props }: Props): JSX.Element => {
  const { classes } = props;

  /**
   * バージョンチェンジセレクトの設定
   */
  const onChangeVersion = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    if (!props.invoiceVersion) return;

    const targetVersion = Number(e.target.value);
    if (
      Number.isNaN(targetVersion) ||
      props.invoiceVersion.currentVersion === targetVersion
    ) {
      return;
    }
    const targetUrl = props.invoiceVersion.versionPaths.find(
      (v) => v.version === targetVersion
    );
    if (!targetUrl) {
      return;
    }
    props.history.push(targetUrl.url);
  };

  return (
    <AppBar position="static" color="inherit" className={classes.header}>
      <Toolbar disableGutters variant="dense">
        <IconButton
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={props.toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" color="inherit" className={classes.grow}>
          {props.pathList &&
            props.pathList.map((pathData) => (
              <React.Fragment key={pathData.pathName}>
                <Link className={classes.link} to={pathData.path}>
                  {pathData.pathName}
                </Link>
                <span className={classes.text}> ＞ </span>
              </React.Fragment>
            ))}
          <span className={classes.text}>{pageName}</span>
        </Typography>
        {props.version ? <p>新しい実装へ変更</p> : null}
        {props.invoiceVersion && (
          <Select
            className={classes.versionSelect}
            value={props.invoiceVersion.currentVersion}
            onChange={onChangeVersion}
          >
            {props.invoiceVersion.versionOptions.map((v) => (
              <MenuItem value={v.value} key={`${v.label}_${v.value}`}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(withRouter(Header));
