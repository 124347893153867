import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import operationsApi from "@api/requests/operations/Cseg";

export const fetchOperations = (dispatch: Dispatch) => async (
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchOperationsStarted());
  await operationsApi
    .getOperations(year, month)
    .then((res) => {
      dispatch(actions.fetchOperationsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchOperationsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchOperations: (year: string, month: string) => Promise<void>;
};

export const CsegOperationsDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchOperations: fetchOperations(dispatch)
});
