import * as React from "react";
// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import RecordBatchPrinting from "@components/organisms/mgr/common/record/RecordBatchPrinting";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UserState } from "@stores/domain/user/type";
// other
import { RouteComponentProps } from "react-router-dom";
import { RecordEachUserSubType } from "@components/organisms/mgr/KEIKAKUSODAN/record/RecordEachUserSubType";
import { RecordEachUserButtonsType } from "@constants/variables";
import { BASE_PUBLIC_URL } from "@config";
import { KEIKAKU_MONITORING_KAIGI_KIROKU } from "@constants/mgr/KEIKAKUSODAN/variables";

type StateProps = {
  users: UsersInFacilityState["users"];
  facilitySubType: number | null;
  userState: UserState;
};

type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchUsers: () => Promise<void>;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

// 利用者ごと 利用者一覧に表示されるボタンリスト
const buttonList: React.ComponentProps<
  typeof RecordEachUserSubType
>["buttonList"] = (uifId: number): RecordEachUserButtonsType => {
  return [
    {
      name: "アセスメント",
      url: `${BASE_PUBLIC_URL}#/record/support_plan/${uifId}/assessment`
    },
    {
      name: "申請者の現状（基本情報）",
      url: `${BASE_PUBLIC_URL}#/record/support_plan/${uifId}/basic_info`
    },
    {
      name: KEIKAKU_MONITORING_KAIGI_KIROKU,
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/plan_monitoring_meeting`
    },
    {
      name: "支援記録",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support`,
      isLastButton: true
    }
  ];
};

// 一括印刷のデフォルト項目上書き指定
const DEFAULT_SELECTED_OPTION_RECORD_BATCH_PRINTING: React.ComponentProps<
  typeof RecordBatchPrinting
>["defaultSelected"] = "assistance";

const options: React.ComponentProps<typeof RecordBatchPrinting>["options"] = [
  { label: "記録を選択", value: "" },
  { label: "支援記録", value: DEFAULT_SELECTED_OPTION_RECORD_BATCH_PRINTING }
];

/**
 * 利用者サマリ
 */
const RecordUsersSummaryCore = (props: Props): JSX.Element => {
  const {
    fetchFacility,
    fetchUsers,
    users,
    userState,
    history,
    facilitySubType
  } = props;

  return (
    <AdminTemplate pageName="利用者ごと">
      {/* 記録の一括印刷 */}
      <RecordBatchPrinting
        history={history}
        options={options}
        defaultSelected={DEFAULT_SELECTED_OPTION_RECORD_BATCH_PRINTING}
      />
      {/* 利用ごとの記録 */}
      <RecordEachUserSubType
        users={users}
        buttonList={buttonList}
        userState={userState}
        fetchUsers={fetchUsers}
        fetchFacility={fetchFacility}
        facilitySubType={facilitySubType}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  users: state.KEIKAKUSODAN.userInFacility.users,
  facilitySubType: state.KEIKAKUSODAN.facility.facilityType,
  userState: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN } = dispatches;
  const facilityDispatcher = KEIKAKUSODAN.facilityDispatcher(dispatch);
  const userInFacilityDispatcher = KEIKAKUSODAN.userInFacilityDispatcher(
    dispatch
  );
  return {
    fetchFacility: (): Promise<void> => facilityDispatcher.fetch(),
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch()
  };
};

export const UsersSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordUsersSummaryCore);
