import { GetUsersSummaryCustomRecords } from "@api/requests/supports/getUsersSummaryCustomRecords";

/**
 * ActionNames
 */
export const FETCH_USERS_SUMMARY_GROUP_HOME_STARTED =
  "SUPPORTS/FETCH_USERS_SUMMARY_GROUP_HOME_STARTED";
export const FETCH_USERS_SUMMARY_GROUP_HOME_SUCCESS =
  "SUPPORTS/FETCH_USERS_SUMMARY_GROUP_HOME_SUCCESS";
export const FETCH_USERS_SUMMARY_GROUP_HOME_FAILED =
  "SUPPORTS/FETCH_USERS_SUMMARY_GROUP_HOME_FAILED";
export const UNSET_USERS_SUMMARY_GROUP_HOME =
  "SUPPORTS/UNSET_USERS_SUMMARY_GROUP_HOME";

/**
 * State
 */
export type UsersSummaryState = GetUsersSummaryCustomRecords["data"];
