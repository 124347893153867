import * as React from "react";

import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FieldArray, FormikProps } from "formik";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import {
  generateHistoryInitialValue,
  SupportLedger,
  History
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: "32px"
    },
    label: {
      margin: "48px 0 0",
      fontSize: "14px",
      lineHeight: "1.7"
    },
    card: {
      margin: "4px 0 8px",
      border: "1px solid #bdbdbd",
      borderRadius: "4px",
      padding: "24px 32px"
    },
    cardLabel: {
      fontSize: "14px",
      lineHeight: 1.71,
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0
    }
  });

type OwnProps = {
  historyData: History[];
  name: string;
  label: string;
  isEditing: boolean;
  formikProps: FormikProps<SupportLedger>;
  isShowItemLabel?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerHistoryCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  return (
    <div className={classes.wrapper}>
      {props.isShowItemLabel ? (
        <p className={classes.label}>{props.label}</p>
      ) : null}
      <FieldArray name={props.name}>
        {(arrayHelper): JSX.Element => {
          let displayHistoryCount = 0;
          const handleClickAddHistory = (): void => {
            arrayHelper.push(generateHistoryInitialValue());
          };
          const handleClickDeleteHistory = (index: number): void => {
            const newHistories = Array.from(props.historyData);
            const targetHistory = newHistories[index];
            if (targetHistory.id) {
              newHistories[index].isDelete = true;
              newHistories[index].yyyymm = "";
              newHistories[index].matter = "";
            } else {
              newHistories.splice(index, 1);
            }
            props.formikProps.setFieldValue(props.name, newHistories);
          };
          return (
            <>
              {props.historyData.map((history, index) => {
                if (history.isDelete) {
                  return null;
                }
                displayHistoryCount += 1;
                const key = `${props.name}_${index}`;
                return (
                  <div key={key} className={classes.card}>
                    <p className={classes.cardLabel}>
                      {props.label}
                      {displayHistoryCount}
                    </p>
                    <RecordTextField
                      label="年月"
                      value={history.yyyymm || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].yyyymm`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "8px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="事項"
                      value={history.matter || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].matter`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    {props.isEditing ? (
                      <KnowbeButton
                        kind="iconText"
                        onClick={(): void => handleClickDeleteHistory(index)}
                        style={{ marginTop: "24px" }}
                      >
                        <DeleteOutlinedIcon />
                        削除する
                      </KnowbeButton>
                    ) : null}
                  </div>
                );
              })}
              {props.isEditing ? (
                <KnowbeButton
                  kind="iconText"
                  onClick={handleClickAddHistory}
                  style={{ marginTop: "24px" }}
                  disabled={displayHistoryCount >= 20}
                >
                  <AddIcon />
                  {props.label}を追加する
                </KnowbeButton>
              ) : null}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

/**
 * 地域定着台帳
 * 障害・病歴、生活歴のフォームコンポーネント
 */
export const SupportLedgerHistory = withStyles(styles)(
  SupportLedgerHistoryCore
);
