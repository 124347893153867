import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * サービス予定登録API用のリクエストパラメータ
 * doc: https://rhc-bd.atlassian.net/wiki/spaces/KNOW/pages/2692780703/POST+host+version+mgr+facility+municipality
 */
export type RequestParamToPostAPI = {
  json_data: {
    municipality_id: number | null;
    name?: string;
    calculation_time_flg?: number | null;
    round_up_minute?: number | null;
    time_division_flg?: number | null;
    billing_unit?: number | null;
    unit_price_per_unit?: number | null;
  };
  service_code_csv?: File;
};

/**
 * 自治体情報のPOST
 * @param data リクエスト情報
 */
export const postFacilityMunicipality = async (
  data: RequestParamToPostAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/facility/municipality`;
  const postData = new FormData();
  postData.append("json_data", JSON.stringify(data.json_data));
  if (data.service_code_csv) {
    postData.append("service_code_csv", data.service_code_csv);
  }
  return request.post(url, postData);
};
