import {
  HOSPITALIZATION_OVERNIGHTSTAY,
  HOSPITALIZATION_OVERNIGHTSTAY_ONLY_SPECIAL,
  StatusType,
  STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL,
  HOSPITALIZATION_OVERNIGHTSTAY_WITHOUT_SPECIAL,
  HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG,
  HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_ONLY_SPECIAL,
  HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_WITHOUT_SPECIAL
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import { OptionInterface } from "@components/atoms/DropDown";

// 入院・外泊時加算・入院時支援特別加算選択肢作成(実績一覧)
export const createOptionsHospitalizationOvernightStayList = (
  status: string
): OptionInterface[] => {
  if (
    STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL.includes(
      status
    )
  ) {
    return HOSPITALIZATION_OVERNIGHTSTAY_ONLY_SPECIAL;
  }
  if (status === StatusType.OVERNIGHT) {
    return HOSPITALIZATION_OVERNIGHTSTAY_WITHOUT_SPECIAL;
  }
  return HOSPITALIZATION_OVERNIGHTSTAY;
};

// 入院・外泊時加算・入院時支援特別加算選択肢作成(モーダル用)
export const createOptionsHospitalizationOvernightStayDialog = (
  status: string
): OptionInterface[] => {
  if (
    STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL.includes(
      status
    )
  ) {
    return HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_ONLY_SPECIAL;
  }
  if (status === StatusType.OVERNIGHT) {
    return HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG_WITHOUT_SPECIAL;
  }
  return HOSPITALIZATION_OVERNIGHTSTAY_BY_DIALOG;
};
