/**
 * 指定期間内チェック
 */
export const isWithinRange = (
  inTime: string,
  outTime: string,
  targetTime: string
): boolean => {
  const start = Number(inTime.split(":").join(""));
  const end = Number(outTime.split(":").join(""));
  const target = Number(targetTime.split(":").join(""));

  return start < target && target < end;
};
