import * as types from "./types";
import { GetWorkplaceCompanyResponse } from "@api/requests/offsiteWork/getWorkplaceCompany";
import { GetWorkplaceCompanyListResponse } from "@api/requests/offsiteWork/getWorkplaceCompanyList";
import { GetDownloadResponse } from "@api/requests/offsiteWork/getDownload";

export const fetchDownloadSuccess = (res: GetDownloadResponse["data"]) =>
  ({ type: types.FETCH_DOWNLOAD_SUCCESS, payload: res } as const);

export const fetchWorkplaceCompanyListStarted = () =>
  ({ type: types.FETCH_WORKPLACE_COMPANY_LIST_STARTED } as const);
export const fetchWorkplaceCompanyListSuccess = (
  res: GetWorkplaceCompanyListResponse["data"]["workplace_company"]
) =>
  ({ type: types.FETCH_WORKPLACE_COMPANY_LIST_SUCCESS, payload: res } as const);
export const fetchWorkplaceCompanyListFailed = () =>
  ({ type: types.FETCH_WORKPLACE_COMPANY_LIST_FAILED } as const);

export const fetchWorkplaceCompanyStarted = () =>
  ({ type: types.FETCH_WORKPLACE_COMPANY_STARTED } as const);
export const fetchWorkplaceCompanySuccess = (
  res: GetWorkplaceCompanyResponse["data"]
) => ({ type: types.FETCH_WORKPLACE_COMPANY_SUCCESS, payload: res } as const);
export const fetchWorkplaceCompanyFailed = () =>
  ({ type: types.FETCH_WORKPLACE_COMPANY_FAILED } as const);

export const clearWorkplaceCompany = () =>
  ({ type: types.CLEAR_WORKPLACE_COMPANY } as const);

export const postWorkplaceCompanyStarted = () =>
  ({ type: types.POST_WORKPLACE_COMPANY_STARTED } as const);

export const postWorkplaceCompanyFailed = () =>
  ({ type: types.POST_WORKPLACE_COMPANY_FAILED } as const);

export const deleteWorkplaceCompanyStarted = () =>
  ({ type: types.DELETE_WORKPLACE_COMPANY_STARTED } as const);
export const deleteWorkplaceCompanySuccess = () =>
  ({ type: types.DELETE_WORKPLACE_COMPANY_SUCCESS } as const);
export const deleteWorkplaceCompanyFailed = () =>
  ({ type: types.DELETE_WORKPLACE_COMPANY_FAILED } as const);

export const fetchDownloadablesStarted = () =>
  ({ type: types.FETCH_DOWNLOADABLE_STARTED } as const);
export const fetchDownloadablesSuccess = (
  res: types.InitialState["downloadables"]
) => ({ type: types.FETCH_DOWNLOADABLE_SUCCESS, payload: res } as const);
export const fetchDownloadablesFailed = () =>
  ({ type: types.FETCH_DOWNLOADABLE_STARTED } as const);

export type ActionTypes =
  | ReturnType<typeof fetchDownloadSuccess>
  | ReturnType<typeof fetchWorkplaceCompanyListStarted>
  | ReturnType<typeof fetchWorkplaceCompanyListSuccess>
  | ReturnType<typeof fetchWorkplaceCompanyListFailed>
  | ReturnType<typeof fetchWorkplaceCompanyStarted>
  | ReturnType<typeof fetchWorkplaceCompanySuccess>
  | ReturnType<typeof fetchWorkplaceCompanyFailed>
  | ReturnType<typeof deleteWorkplaceCompanyStarted>
  | ReturnType<typeof deleteWorkplaceCompanySuccess>
  | ReturnType<typeof deleteWorkplaceCompanyFailed>
  | ReturnType<typeof clearWorkplaceCompany>
  | ReturnType<typeof fetchDownloadablesStarted>
  | ReturnType<typeof fetchDownloadablesSuccess>
  | ReturnType<typeof fetchDownloadablesFailed>;
