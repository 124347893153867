import { StaffValues } from "./initialValues";
import validator, { validateSwitcher } from "@validator";
import ValidationErrors from "@interfaces/ui/validationErrors";

type StaffFields = {
  staffs: {
    roleName: string;
    workName: string;
    delete: boolean;
  }[];
};

export const validation = (
  values: StaffValues
): ValidationErrors<StaffFields> => {
  return {
    staffs: values.staffs.map((staff) => {
      return {
        roleName: validateSwitcher(
          !staff.delete &&
            (!!staff.staffItemId ||
              !!staff.staffName ||
              !!staff.staffLicenseName),
          validator(staff.roleName, "required")
        ),
        staffName: validateSwitcher(
          !staff.delete && (!!staff.roleName || !!staff.staffLicenseName),
          validator(staff.staffName, "required")
        )
      };
    })
  };
};
