import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { unsetOperations } from "@stores/domain/mgr/Cseg/operations/actions";
import { OperationsState } from "@stores/domain/mgr/Cseg/operations/types";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKITEICHAKUUsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { StaffState } from "@stores/domain/staff/types";
import * as recordMonthlyActions from "@stores/pages/record/monthly/actions";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { OperationRecordList } from "@components/organisms/mgr/Cseg/record/operation/OperationRecordList";
import AdminTemplate from "@components/templates/AdminTemplate";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { RECORD_OPERATIONS } from "@/constants/url";
import isAfter from "date-fns/is_after";
import { FieldItem } from "@interfaces/ui/form";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { OperationsValues } from "@initialize/mgr/Cseg/record/operations/initialValues";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "12px 16px 20px",
      background: "#eee"
    },
    headerSelect: {
      display: "flex",
      alignItems: "flex-end"
    },
    timestampWrapper: {
      margin: "0 16px 16px"
    }
  });

type StateProps = {
  operations: OperationsState;
  users:
    | KEIKAKUSODANUsersInFacilityState["users"]
    | CHIIKITEICHAKUUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
  staff: StaffState;
  needsStopHistory: boolean;
  isEditing: boolean;
  editDate: string;
};

type DispatchProps = {
  fetchOperations: (year: string, month: string) => Promise<void>;
  unsetOperations: () => void;
  fetchInitialOperations: (year: string, month: string) => Promise<void>;
  postOperation: (
    params: OperationsValues,
    initialValues: OperationsValues,
    targetDate: string
  ) => Promise<void>;
  fetchStaffs: () => Promise<void>;
  fetchHoliday: (date: string) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
};

type OwnProps = RouteComponentProps<{ year?: string; month?: string }> &
  WithStyles<typeof styles>;

type MergeProps = StateProps &
  DispatchProps &
  OwnProps & {
    staffOptions: FieldItem[];
  };

/**
 * 業務日誌
 */
const OperationsCore = ({
  classes,
  ...props
}: MergeProps): JSX.Element | null => {
  // カレンダー設定
  const { year, month } = props.match.params;
  const currentDate = new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = currentDate;
  const selectedDate =
    year && month ? new Date(+year, +month - 1, 1) : currentDate;
  const selectedYear = year || selectedDate.getFullYear().toString();
  const selectedMonth = month || (selectedDate.getMonth() + 1).toString();

  const [calendar, setCalendar] = React.useState({
    year: selectedYear,
    month: selectedMonth,
    date: selectedDate
  });

  const onClickCalendar = async (date: Date): Promise<void> => {
    const clickYear = date.getFullYear().toString();
    const clickMonth = (date.getMonth() + 1).toString();
    setCalendar({
      date,
      year: clickYear,
      month: clickMonth
    });
    await props.fetchHoliday(`${clickYear}${clickMonth.padStart(2, "0")}`);
    await props.fetchOperations(clickYear, clickMonth);
  };

  const onPrintPreview = (): void => {
    props.history.push(
      `/record/print/operations/${selectedYear}/${selectedMonth}/`
    );
  };

  // 初回取得完了の判定
  const isFirstRef = React.useRef(false);
  // 初回取得
  React.useEffect(() => {
    props.fetchInitialOperations(calendar.year, calendar.month).finally(() => {
      isFirstRef.current = true;
    });
    props.fetchStaffs();
  }, []);

  // カレンダーのURL反映
  React.useEffect(() => {
    props.history.replace(
      `${RECORD_OPERATIONS}/${calendar.year}/${calendar.month}`
    );
  }, [calendar]);

  // 直指定の未来日を弾く
  if (isAfter(selectedDate, calendarMaxDate)) {
    return null;
  }

  return (
    <AdminTemplate pageName="業務日誌">
      <div className={classes.stickyWrapper}>
        <div className={classes.headerSelect}>
          <UnEditableWrapper unEditable={props.isEditing}>
            <DateSelectButtonsMonthly
              selectedMonth={calendar.date}
              min={calendarMinDate}
              max={calendarMaxDate}
              onClickSubmit={onClickCalendar}
            />
          </UnEditableWrapper>
        </div>
        <KnowbeButton
          disabled={!props.operations.created_at || props.isEditing}
          onClick={onPrintPreview}
        >
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.timestampWrapper}>
        <CreateAndUpdateDate
          createdAt={props.operations.created_at}
          updatedAt={props.operations.updated_at}
        />
      </div>
      {isFirstRef.current && (
        <OperationRecordList
          year={calendar.year}
          month={calendar.month}
          currentDate={currentDate}
          {...props}
        />
      )}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    operations: state.Cseg.operations,
    users: state[facilityType].userInFacility.users,
    staff: state.staff,
    needsStopHistory: state.ui.needsStopHistory,
    isEditing: state.pages.recordMonthly.isEditing,
    editDate: state.pages.recordMonthly.targetDate
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, staffDispatcher, holiday, uiDispatch, pages } = dispatches;
  const operationsDispatches = Cseg.operationsDispatcher(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  const recordMonthlyDispatches = pages.recordMonthlyDispatcher(dispatch);
  const staffDispatches = staffDispatcher(dispatch);
  const holidayDispatcher = holiday(dispatch);

  return {
    fetchInitialOperations: (year: string, month: string): Promise<void> =>
      recordMonthlyDispatches.fetchCsegInitialOperations(year, month),

    fetchOperations: (year: string, month: string): Promise<void> =>
      operationsDispatches.fetchOperations(year, month),
    unsetOperations: (): void => {
      dispatch(unsetOperations());
    },
    postOperation: (params, initialValues, targetDate): Promise<void> =>
      recordMonthlyDispatches.postCsegOperation(
        params,
        initialValues,
        targetDate
      ),
    fetchStaffs: staffDispatches.fetch,
    fetchHoliday: (date: string): Promise<void> =>
      holidayDispatcher.fetchHoliday(date),
    stopHistory: (flag: boolean): void => {
      uiDispatches.stopHistory(flag);
    },
    setEditDate: (targetDate: string): void => {
      dispatch(recordMonthlyActions.setEdit(targetDate));
    },
    unsetEditDate: (): void => {
      dispatch(recordMonthlyActions.unsetEdit());
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );
  return {
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const Operations = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(OperationsCore)
);
