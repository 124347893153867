import React from "react";

import { FormikProps } from "formik";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { MunicipalityServiceCode } from "@components/organisms/mgr/IDOSHIEN/Municipality/MunicipalityServiceCode";
import { MunicipalityValues } from "@initialize/mgr/IDOSHIEN/Municipality/initialValues";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import {
  MUNICIPALITY_BILLING_UNIT_OPTIONS,
  MUNICIPALITY_BILLING_UNIT_UNIT
} from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    formLabel: {
      display: "block",
      marginBottom: 8,
      fontSize: "14px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    formNestGroup: {
      paddingLeft: 16
    },
    fieldMunicipalityName: {
      width: 356
    },
    fieldRoundUpMinute: {
      width: 185
    },
    fieldUnitPriceParUnit: {
      width: 229
    },
    MB16: {
      marginBottom: 16
    },
    MB20: {
      marginBottom: 20
    },
    MB32: {
      marginBottom: 32
    }
  });

type OwnProps = {
  formikProps: FormikProps<MunicipalityValues>;
  /** アカウントに請求関連の権限があるか */
  hasInvoiceAuthority: boolean;
  municipalityData: MunicipalitiesInFacilityState["municipality"];
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 自治体情報の編集フィールド
 */
const MunicipalityFieldsCore = (props: Props): JSX.Element => {
  const { values, setFieldValue } = props.formikProps;

  const isCheckedCalculationTimeFlag = values.calculationTimeFlag;
  const isSelectedBillingUnitToUnit =
    values.billingUnit === MUNICIPALITY_BILLING_UNIT_UNIT;

  return (
    <div>
      <FormikTextField
        label="自治体名称"
        required
        name="name"
        maxLength={50}
        className={props.classes.fieldMunicipalityName}
      />
      {props.hasInvoiceAuthority ? (
        <div className={props.classes.MB32}>
          <span className={props.classes.formLabel}>サービスコード</span>
          <MunicipalityServiceCode
            fileFieldName="serviceCodeCsv"
            setFieldValue={setFieldValue}
            savedCsvFileName={props.municipalityData.csv_file_name || ""}
            savedCsvUploadedDate={
              props.municipalityData.csv_file_uploaded_date || ""
            }
          />
        </div>
      ) : null}
      {/* チェックボックスに12pxのpaddingがあるため、ここで20pxの指定となる */}
      <div
        className={props.hasInvoiceAuthority ? props.classes.MB20 : undefined}
      >
        <span className={props.classes.formLabel}>算定時間</span>
        <div className={props.classes.formNestGroup}>
          <FormikSwitch
            label="算定時間に端数が生じた場合に処理を行う"
            name="calculationTimeFlag"
            style={{ marginBottom: 0 }}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="municipalityCalculationTips" />}
              />
            }
          />
          {isCheckedCalculationTimeFlag ? (
            <div className={props.classes.formNestGroup}>
              <FormikTextField
                placeholder="20"
                maxLength={2}
                name="roundUpMinute"
                endAdornmentLabel="分以上は切り上げる"
                className={props.classes.fieldRoundUpMinute}
                style={{ marginBottom: props.hasInvoiceAuthority ? 32 : 0 }}
              />
            </div>
          ) : null}
        </div>
      </div>
      {props.hasInvoiceAuthority ? (
        <>
          <div className={props.classes.formNestGroup}>
            <FormikCheckbox
              label="時間区分あり"
              name="timeDivisionFlg"
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="municipalityTimeDivisionTips" />
                  }
                />
              }
            />
          </div>
          <FormikRadioButtons
            label="請求単位"
            name="billingUnit"
            options={MUNICIPALITY_BILLING_UNIT_OPTIONS}
            className={props.classes.MB16}
          />
          {isSelectedBillingUnitToUnit ? (
            <div className={props.classes.formNestGroup}>
              <FormikTextField
                label="１単位あたりの単価"
                name="unitPricePerUnit"
                placeholder="10"
                endAdornmentLabel="円"
                maxLength={6}
                style={{ marginBottom: 0 }}
                className={props.classes.fieldUnitPriceParUnit}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

export const MunicipalityFields = withStyles(styles)(MunicipalityFieldsCore);
