import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    root: {
      width: "100%",
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd"
    },
    cardLabel: {
      margin: 0,
      fontSize: "14px",
      lineHeight: "1.7",
      color: "rgba(0, 0, 0, 0.87)"
    },
    deleteButton: {
      marginTop: "24px"
    },
    deleteIcon: {
      marginRight: 8
    }
  });

type OwnProps = {
  label: string;
  itemName: string;
  isEditing: boolean;
  inputYearMonth: string;
  inputMatter: string;
  onClickDelete: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentSheetFormHistoryCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  return (
    <div className={classes.root}>
      <p className={classes.cardLabel}>{props.label}</p>
      <RecordTextField
        label="年月"
        name={`${props.itemName}.yyyymm`}
        labelType="default"
        defaultValue=""
        placeholder=""
        value={props.inputYearMonth}
        isEditable={props.isEditing}
        style={{ marginTop: 8, marginBottom: 0, width: "100%" }}
      />
      <RecordTextField
        label="事項"
        name={`${props.itemName}.matter`}
        labelType="default"
        defaultValue=""
        placeholder=""
        value={props.inputMatter}
        isEditable={props.isEditing}
        style={{ marginTop: 32, marginBottom: 0, width: "100%" }}
      />
      {props.isEditing && (
        <KnowbeButton
          className={classes.deleteButton}
          onClick={props.onClickDelete}
          kind="iconText"
        >
          <DeleteOutlinedIcon
            color="secondary"
            className={classes.deleteIcon}
          />
          削除する
        </KnowbeButton>
      )}
    </div>
  );
};

export const AssessmentSheetFormHistory = withStyles(styles)(
  AssessmentSheetFormHistoryCore
);
