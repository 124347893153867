import React from "react";
import { Formik, Form, FormikActions } from "formik";
import {
  RecordOperationValue,
  initialOperationValue as initialValue
} from "@initialize/mgr/GroupHome/record/dailyRecord/initialValues";
import { operationValidation as validation } from "@initialize/mgr/GroupHome/record/dailyRecord/validation";
import { toEffectiveObject } from "@utils/object";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import * as recordDailyUserActions from "@stores/pages/record/daily/actions";
import { RecordDailyState } from "@stores/pages/record/daily/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { AppState } from "@stores/type";
import SectionTitleWithButton from "@components/molecules/SectionTitleWithButton";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import Paper from "@material-ui/core/Paper";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";
import { FieldItem } from "@interfaces/ui/form";
import deepEqual from "fast-deep-equal";
import { InsertPhraseModal } from "@components/organisms/mgr/common/record/InsertPhraseModal";
import { SnackbarParams } from "@stores/ui/type";

const styles = (): StyleRules =>
  createStyles({
    operationRecordWrapper: {
      padding: "16px 16px 0"
    },
    paper: {
      padding: "32px 32px 8px"
    },
    root: {
      opacity: 1
    },
    editRoot: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    cancel: {
      marginRight: "8px"
    },
    fieldList: {
      margin: "8px 0 0",
      padding: "0 116px 0 0"
    },
    inputField: {
      position: "relative"
    },
    fixedButton: {
      position: "absolute",
      top: 0,
      right: -116,
      minWidth: 100,
      minHeight: 30
    },
    checkboxField: {
      display: "flex",
      flexWrap: "wrap",
      margin: "-12px 0"
    },
    checkbox: {
      fontSize: 16,
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  title: string;
  staffOptions: FieldItem[];
  operations: DailyOperationsAndSupportsState["operation"];
  selectedUnitId: number;
  unitId: number;
  yyyymmdd: string;
};
type StateProps = {
  customRecords: CustomRecordsState;
  recordDaily: RecordDailyState;
  needsStopHistory: boolean;
};
type DispatchProps = {
  setEditing: (unitId: number) => void;
  unsetEditing: () => void;
  postCustomOperation: (
    param: RecordOperationValue,
    initialValue: RecordOperationValue,
    yyyymmdd: string,
    fetchOptions: { unitId: number }
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
};
type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const DailyOperationRecordCore = (props: Props): JSX.Element | null => {
  if (!props.customRecords.length) return null;

  // 1: 業務日誌/事業所全体 or ユニットなし
  // 2: 業務日誌/ユニットごと
  const setting_type = props.unitId === 0 ? 1 : 2;

  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === setting_type)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    // 位置が固定の項目
    // 並べ替え → 1: 出勤者, 2:欠勤・休暇者, last: 記録者
    const targetDefaultItems = [1, 2, 6];
    const targetItems = targetDefaultItems.map((defaultItem) => {
      const itemIndex = orderArray.findIndex(
        (item) => item.default_item === defaultItem
      );
      return orderArray.splice(itemIndex, 1)[0];
    });
    return [targetItems[0], targetItems[1], ...orderArray, targetItems[2]];
  };

  const newCustomRecords = sortCustomRecords(props.customRecords);

  // 使用する業務日誌情報を、props.operationsから抽出する。
  // 「事業所全体」と「ユニット」のいずれか
  const getTargetOperation = (
    operations: DailyOperationsAndSupportsState["operation"],
    isUnit: boolean | undefined
  ): DailyOperationsAndSupportsState["operation"][number] | null => {
    if (!(operations.length > 0)) return null;
    const separatedOperations: {
      common: DailyOperationsAndSupportsState["operation"];
      unit: DailyOperationsAndSupportsState["operation"];
    } = props.operations.reduce(
      (
        acc: {
          common: DailyOperationsAndSupportsState["operation"];
          unit: DailyOperationsAndSupportsState["operation"];
        },
        cur: DailyOperationsAndSupportsState["operation"][number]
      ) => {
        if (cur.facility_unit_id === 0) {
          return { ...acc, common: [...acc.common, cur] };
        }
        return { ...acc, unit: [...acc.unit, cur] };
      },
      { common: [], unit: [] }
    );
    return isUnit ? separatedOperations.unit[0] : separatedOperations.common[0];
  };

  let operation:
    | DailyOperationsAndSupportsState["operation"][number]
    | null = null;
  operation = getTargetOperation(props.operations, props.unitId !== 0);

  const isEditMode =
    (props.recordDaily.uifId === null && props.recordDaily.unitId === null) ||
    props.unitId === props.recordDaily.unitId;
  const isEditing =
    props.unitId === props.recordDaily.unitId && props.recordDaily.isEditing;

  const [isInsertPhraseModal, setInsertPhraseModal] = React.useState(false);
  const [insertPhraseId, setInsertPhraseId] = React.useState(0);
  const [insertPhraseFieldName, setInsertPhraseFieldName] = React.useState("");

  const [formValues, setFormValues] = React.useState(
    initialValue(operation, props.customRecords, props.unitId)
  );

  React.useEffect(() => {
    operation = getTargetOperation(props.operations, props.unitId !== 0);
    setFormValues(initialValue(operation, props.customRecords, props.unitId));
  }, [props.customRecords, props.operations, props.unitId]);

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditing(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);

  const closeInsertPhraseModal = (): void => {
    setInsertPhraseModal(false);
    setInsertPhraseFieldName("");
    setInsertPhraseId(0);
  };

  const confirmDiscardFormChanges = (
    nextValues: RecordOperationValue
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (value: RecordOperationValue): void | object => {
    const validationResult = validation(value);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(value);
    }
    return error;
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onSubmit = async (
    values: RecordOperationValue,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postCustomOperation(values, formValues, props.yyyymmdd, {
      unitId: props.selectedUnitId
    });
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const onClickEditCancel = (): void => {
          formikProps.resetForm();
          props.unsetEditing();
          props.stopHistory(false);
        };
        const onClickEdit = (): void => {
          props.setEditing(props.unitId);
        };
        return (
          <Form>
            <div className={props.classes.operationRecordWrapper}>
              <Paper elevation={0} className={props.classes.paper}>
                <div
                  className={
                    isEditMode ? props.classes.root : props.classes.editRoot
                  }
                >
                  <div />
                  <SectionTitleWithButton label={props.title}>
                    {!isEditing ? (
                      <KnowbeButton onClick={onClickEdit}>編集</KnowbeButton>
                    ) : (
                      <div>
                        <KnowbeButton
                          kind="outlineWhite"
                          onClick={onClickEditCancel}
                          className={props.classes.cancel}
                        >
                          キャンセル
                        </KnowbeButton>
                        <FormikSubmitButton
                          buttonName="保存する"
                          formikProps={formikProps}
                          errorAction={submitError}
                        />
                      </div>
                    )}
                  </SectionTitleWithButton>

                  <div className={props.classes.fieldList}>
                    {newCustomRecords.map((customRecord) => {
                      const fieldName = `record.custom_record.input_type_first.${customRecord.id}.input_data`;
                      const OpenInsertPhraseModal = (): void => {
                        setInsertPhraseModal(true);
                        setInsertPhraseFieldName(fieldName);
                        setInsertPhraseId(customRecord.id);
                      };
                      // RecordSupportTableFieldは初期状態がliなのでdivに変更
                      const tag = "div" as keyof JSX.IntrinsicElements;
                      // 共通で適応出来るprops
                      const commonProps = {
                        tag,
                        key: `${customRecord.id}`,
                        label: customRecord.name,
                        isExtendsMarginBottom: true
                      };
                      const textAndMultipleProps = {
                        defaultValue: "",
                        placeholder: "",
                        isEditing
                      };

                      // テキスト形式
                      if (customRecord.input_type === 1) {
                        const recordInput =
                          operation &&
                          operation.operation_record_input.find(
                            (item) =>
                              item.custom_record_item_id === customRecord.id &&
                              item.input_data
                          );
                        // 定型文
                        const fixedPhraseData = customRecord.choices
                          ? customRecord.choices.filter(
                              (item) => item.hidden === 0
                            )
                          : [];
                        // visibilityか入力データがあれば表示
                        const show =
                          customRecord.visibility === 1 || recordInput;
                        return (
                          show && (
                            <div
                              className={props.classes.inputField}
                              key={`input-${customRecord.id}`}
                            >
                              <RecordSupportTableField
                                type="text"
                                value={
                                  recordInput && recordInput.input_data
                                    ? recordInput.input_data
                                    : ""
                                }
                                name={fieldName}
                                {...textAndMultipleProps}
                                {...commonProps}
                              />
                              {isEditing && fixedPhraseData.length > 0 && (
                                <KnowbeButton
                                  kind="outline"
                                  className={props.classes.fixedButton}
                                  onClick={OpenInsertPhraseModal}
                                >
                                  定型文
                                </KnowbeButton>
                              )}
                            </div>
                          )
                        );
                      }
                      // チェックボックス形式
                      if (customRecord.input_type === 2) {
                        const recordInput = operation
                          ? operation.operation_record_input.filter(
                              (item) =>
                                item.custom_record_item_id ===
                                  customRecord.id && item.checked === 1
                            )
                          : [];
                        // visibilityかデータが1件でもある場合表示
                        const show =
                          customRecord.visibility === 1 ||
                          recordInput.length > 0;
                        return (
                          show && (
                            <RecordSupportTableField
                              type="custom"
                              {...commonProps}
                            >
                              <div className={props.classes.checkboxField}>
                                {customRecord.choices &&
                                  customRecord.choices.map((choice) => {
                                    // 非表示時の未チェックは除去
                                    const checked = recordInput.some(
                                      (item) =>
                                        item.choiced_item_id === choice.id
                                    );
                                    // undefined時に発生する「uncontrolledエラー」を回避
                                    const noUndefined =
                                      !!formikProps.values.record.custom_record
                                        .input_type_second[customRecord.id] &&
                                      customRecord.choices.length ===
                                        Object.keys(
                                          formikProps.values.record
                                            .custom_record.input_type_second[
                                            customRecord.id
                                          ]
                                        ).length;
                                    if (
                                      (choice.hidden === 1 && !checked) ||
                                      (customRecord.visibility === 0 &&
                                        !checked) ||
                                      !noUndefined
                                    ) {
                                      return null;
                                    }
                                    return (
                                      <FormikCheckbox
                                        name={`record.custom_record.input_type_second.${customRecord.id}.${choice.id}.checked`}
                                        key={`checkbox_${choice.id}`}
                                        label={
                                          <span
                                            className={props.classes.checkbox}
                                          >
                                            {choice.name}
                                          </span>
                                        }
                                        disabled={!isEditing}
                                        style={{ margin: 0 }}
                                      />
                                    );
                                  })}
                              </div>
                            </RecordSupportTableField>
                          )
                        );
                      }
                      // マルチプルセレクト形式
                      if (customRecord.input_type === 3) {
                        const recordInput = operation
                          ? operation.operation_record_input.filter(
                              (item) =>
                                item.custom_record_item_id ===
                                  customRecord.id && item.checked === 1
                            )
                          : [];
                        const choicedStaffName = recordInput.map((item) => ({
                          id: item.choiced_staff_id ? item.choiced_staff_id : 0,
                          name: item.choiced_staff_name_snapshot
                            ? item.choiced_staff_name_snapshot
                            : ""
                        }));
                        const mergedStaffOptions = generateMergedStaffOptions(
                          props.staffOptions,
                          choicedStaffName
                        );
                        // visibilityかデータが1件でもある場合表示（チェックボックスと同一）
                        const show =
                          customRecord.visibility === 1 ||
                          choicedStaffName.length > 0;
                        return (
                          show && (
                            <RecordSupportTableField
                              type="multiple"
                              value={
                                choicedStaffName
                                  ? choicedStaffName
                                      .map((item) => item.name)
                                      .join("、")
                                  : ""
                              }
                              name={`record.custom_record.input_type_third.${customRecord.id}.itemIdList`}
                              options={[
                                { categoryName: "", items: mergedStaffOptions }
                              ]}
                              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                              {...textAndMultipleProps}
                              {...commonProps}
                            />
                          )
                        );
                      }
                      return null;
                    })}
                  </div>
                  <InsertPhraseModal
                    isModalOpen={isInsertPhraseModal}
                    onClose={closeInsertPhraseModal}
                    formikProps={formikProps}
                    customRecords={props.customRecords}
                    insertPhraseFieldName={insertPhraseFieldName}
                    insertPhraseId={insertPhraseId}
                  />
                </div>
              </Paper>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords,
  recordDaily: state.pages.recordDaily,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, pages } = dispatches;
  const recordDailyDispatches = pages.recordDailyGroupHomeDispatcher(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    setEditing: (unitId: number): void => {
      dispatch(recordDailyUserActions.setEditWithUnitId(unitId));
    },
    unsetEditing: (): void => {
      dispatch(recordDailyUserActions.unsetEdit());
    },
    postCustomOperation: (
      param: RecordOperationValue,
      initialValues: RecordOperationValue,
      yyyymmdd: string,
      fetchOptions: { unitId: number }
    ): Promise<void> =>
      recordDailyDispatches.postCustomOperation(
        param,
        initialValues,
        yyyymmdd,
        fetchOptions
      ),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: (flag: boolean): void => {
      uiDispatches.stopHistory(flag);
    }
  };
};

export const DailyOperationRecord = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(DailyOperationRecordCore));
