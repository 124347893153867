import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { RATE_GET_JOB_ITEMS } from "@constants/mgr/SHUROTEICHAKU/variables";
import LackFields from "@components/v201910/organisms/mgr/common/facility/items/LackFields";
import {
  WithStyles,
  createStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";

import { FormikProps } from "formik";
import { REWARD_REVISION_LABEL } from "@constants/variables";
import { ChipsLabel } from "@components/presentational/atoms/ChipsLabel";
import { FacilityValues } from "@initialize/v201910/mgr/SHUROTEICHAKU/facility/initialValues";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

interface OwnProps {
  formikProps: FormikProps<FacilityValues>;
}

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields: React.FunctionComponent<Props> = (
  props: Props
) => (
  <FormPaper>
    <div style={{ marginBottom: 32 }}>
      <SectionTitle label="減算対象" />
    </div>
    <FormikRadioButtons
      name="subtractionItem.rateGetJob"
      label="就労定着率"
      options={RATE_GET_JOB_ITEMS}
      chipsLabel={<ChipsLabel text={REWARD_REVISION_LABEL.CHANGE_TEXT} />}
    />
    <LackFields formikProps={props.formikProps} name="subtractionItem" />
  </FormPaper>
);

export default withStyles(styles)(SubtractionItemFields);
