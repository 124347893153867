import { BasicErrors } from "@interfaces/mgr/SHISETSUNYUSHO/facility/basic";
import { SubtractionItemErrors } from "@interfaces/mgr/SHISETSUNYUSHO/facility/subtractionItem";
import { AdditionalItemErrors } from "@interfaces/mgr/SHISETSUNYUSHO/facility/additionItem";
import { FacilityValues } from "@initialize/mgr/SHISETSUNYUSHO/facility/initialValues";
import validator, { validateSwitcher, dateValidator } from "@validator";
import {
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  SELECTED_CONDITION_TYPE_2024_V,
  DEFAULT_SELECT_VALUE
} from "@constants/variables";
import {
  foodExpenses,
  utility,
  NIGHTTIME_PLACEMENT_LIST
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import { UnitsErrors } from "@interfaces/v201910/mgr/SHISETSUNYUSHO/facility/units";

import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type FacilityErrors = BasicErrors &
  SubtractionItemErrors &
  AdditionalItemErrors;

const basicValidation = (values: FacilityValues): BasicErrors => {
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      officeNumber: validator(
        values.basic.officeNumber,
        "required",
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      officeName: validator(values.basic.officeName, "required"),
      representativeName: validator(
        values.basic.representativeName,
        "required"
      ),
      capacity: validator(
        values.basic.capacity,
        "required",
        "naturalNumberNonZero",
        {
          type: "upperLimit",
          upperLimit: 999
        }
      ),
      allCapacity: validateSwitcher(
        values.basic.multiFunctionOfficeFlg,
        validator(
          values.basic.allCapacity,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 999
          }
        )
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "required", "naturalNumber"),
      foodExpensesBreakfast: validateSwitcher(
        values.basic.availableFood &&
          values.basic.foodExpenses === foodExpenses.PER_FOOD,
        validator(
          values.basic.foodExpensesBreakfast,
          "required",
          "naturalNumberNonZero"
        )
      ),
      foodExpensesLunch: validateSwitcher(
        values.basic.availableFood &&
          values.basic.foodExpenses === foodExpenses.PER_FOOD,
        validator(
          values.basic.foodExpensesLunch,
          "required",
          "naturalNumberNonZero"
        )
      ),
      foodExpensesSupper: validateSwitcher(
        values.basic.availableFood &&
          values.basic.foodExpenses === foodExpenses.PER_FOOD,
        validator(
          values.basic.foodExpensesSupper,
          "required",
          "naturalNumberNonZero"
        )
      ),
      foodExpensesDay: validateSwitcher(
        values.basic.availableFood &&
          values.basic.foodExpenses === foodExpenses.PER_DAY,
        validator(
          values.basic.foodExpensesDay,
          "required",
          "naturalNumberNonZero"
        )
      ),
      utilityCosts: validateSwitcher(
        values.basic.utility !== utility.NONE,
        validator(values.basic.utilityCosts, "required", "naturalNumberNonZero")
      )
    }
  };
};

const subtractionItemValidation = (
  values: FacilityValues
): SubtractionItemErrors => {
  // dateStartLackOfSupporterのフォーム値は「年月」であるが、
  // 入力チェックを行うために「年月日」であることが必要なので、
  // 日にちとして「1日」をdefaultで設定する
  const memberDate = {
    ...values.subtractionItem.dateStartLackOfSupporter,
    day: "1"
  };
  const selectDateRules: { type: "selectRequired"; value: string } = {
    type: "selectRequired",
    value: DEFAULT_SELECT_VALUE
  };
  return {
    subtractionItem: {
      dateStartLackOfSupporter: {
        year: validateSwitcher(
          values.subtractionItem.lackOfSupporterFlg,
          validator(memberDate.year, selectDateRules)
        ),
        // 日付の初期値が空文字のため、validationの初期値に合わせる
        month: validateSwitcher(
          values.subtractionItem.lackOfSupporterFlg,
          validator(
            memberDate.month === "" ? DEFAULT_SELECT_VALUE : memberDate.month,
            selectDateRules
          )
        ),
        day: validateSwitcher(
          values.subtractionItem.lackOfSupporterFlg,
          validator(memberDate.day, selectDateRules)
        )
      },
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      bodyRestrictedStillFrom: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillFrom),
          "required"
        )
      ),
      bodyRestrictedStillTo: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillTo),
          {
            type: "future",
            startDate: values.subtractionItem.bodyRestrictedStillFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      workContinuationNotPlanningFrom: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningFrom
          ),
          "required"
        )
      ),
      workContinuationNotPlanningTo: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningTo
          ),
          {
            type: "future",
            startDate: values.subtractionItem.workContinuationNotPlanningFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};

/**
 * 加算対象項目
 */
const additionItemValidation = (
  values: FacilityValues
): AdditionalItemErrors => {
  return {
    additionalItem: {
      nighttimeNurseCount: validateSwitcher(
        values.additionalItem.nighttimePlacement ===
          NIGHTTIME_PLACEMENT_LIST[2].value,
        validator(
          values.additionalItem.nighttimeNurseCount,
          "required",
          "naturalNumberOverOne",
          {
            type: "lowerLimitHalfSize",
            lowerLimitHalfSize: 1
          }
        )
      ),
      seeHearTeam202404From: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404From),
          "required"
        )
      ),
      seeHearTeam202404To: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404To),
          {
            type: "future",
            startDate: values.additionalItem.seeHearTeam202404From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      improvingInfectionControl1From: validateSwitcher(
        values.additionalItem.improvingInfectionControl1Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl1From
          ),
          "required"
        )
      ),
      improvingInfectionControl1To: validateSwitcher(
        values.additionalItem.improvingInfectionControl1Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl1To
          ),
          {
            type: "future",
            startDate: values.additionalItem.improvingInfectionControl1From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      improvingInfectionControl2From: validateSwitcher(
        values.additionalItem.improvingInfectionControl2Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl2From
          ),
          "required"
        )
      ),
      improvingInfectionControl2To: validateSwitcher(
        values.additionalItem.improvingInfectionControl2Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl2To
          ),
          {
            type: "future",
            startDate: values.additionalItem.improvingInfectionControl2From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      regionalTransitionSupportFrom: validateSwitcher(
        values.additionalItem.regionalTransitionSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.regionalTransitionSupportFrom
          ),
          "required"
        )
      ),
      regionalTransitionSupportTo: validateSwitcher(
        values.additionalItem.regionalTransitionSupportFlg,
        dateValidator(
          notSelectedToEmpty(values.additionalItem.regionalTransitionSupportTo),
          {
            type: "future",
            startDate: values.additionalItem.regionalTransitionSupportFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      decreaseUsersCount: validateSwitcher(
        values.additionalItem.regionalTransitionSupportFlg,
        validator(
          values.additionalItem.decreaseUsersCount,
          "required",
          "naturalNumberNonZero"
        )
      ),
      higherBrainDysfunctionPersonsSupportFrom: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportFrom
          ),
          "required"
        )
      ),
      higherBrainDysfunctionPersonsSupportTo: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportTo
          ),
          {
            type: "future",
            startDate:
              values.additionalItem.higherBrainDysfunctionPersonsSupportFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      specificBetterSupporterCondition202404:
        values.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        values.additionalItem.specificBetterSupporterCondition202404Sub ===
          `${NO_SELECTED_CONDITION_TYPE_2024_SUB}`
          ? "福祉・介護職員等処遇改善（Ⅴ）以下の項目を選択してください"
          : undefined
    }
  };
};

/**
 *  施設入所、グループ
 * 「利用者を2つ以上のグループに分けて運営している」が有効な時に実行する
 */
const unitsValidation = (values: FacilityValues): UnitsErrors => {
  const units = values.basic.operatingUnitFlag
    ? values.units.map((unit) => ({
        unit_name: validateSwitcher(
          !unit.is_deleted,
          validator(unit.unit_name, "required", {
            type: "checkCharacterLength",
            length: 30
          })
        )
      }))
    : [];
  return { units };
};

const validation = (values: FacilityValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const subtractionItemErrors = subtractionItemValidation(values);
  const additionalItemErrors = additionItemValidation(values);
  const unitsErrors = unitsValidation(values);
  return {
    ...basicErrors,
    ...subtractionItemErrors,
    ...additionalItemErrors,
    ...unitsErrors
  };
};

export default validation;
