import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
// ui
import MuiTextField from "@components/molecules/MuiTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
// formik
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import { DAY_SELECT_OPTIONS } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    selectLabel: {
      width: "200%"
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  practitionerNum: number;
  handleChangeDateSelect: (
    event: React.ChangeEvent<HTMLSelectElement>,
    isAccompanyField?: boolean
  ) => void;
  handleChangeInitialTime: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string,
    isAccompanyField?: boolean
  ) => void;
} & WithStyles<typeof styles>;

export const ServiceDeliveryAccompanySupportFieldCore = (
  props: Props
): JSX.Element => {
  const {
    classes,
    practitionerNum,
    practitionerValues,
    handleChangeDateSelect,
    handleChangeInitialTime
  } = props;
  const fieldName = `serviceDeliveryRecordPractitioners${practitionerNum}`;

  return (
    <FormGroup row style={{ display: "flex", alignItems: "flex-start" }}>
      <FormGroup row>
        <FormikSelect
          name={`${fieldName}.accompanySupportInTimeDate`}
          label="同行支援開始時間"
          options={DAY_SELECT_OPTIONS}
          style={{ width: 80 }}
          noerrortext
          onChangeHook={(e): void => handleChangeDateSelect(e, true)}
          FormLabelClasses={{ root: classes.selectLabel }}
        />

        <FormikTime
          name={`${fieldName}.accompanySupportInTime`}
          label=""
          placeholder="00:00"
          size="smallMedium"
          maxLength={5}
          onChangeHookTime={(e, before, auto): void =>
            handleChangeInitialTime(e, before, auto, true)
          }
          style={{ marginTop: 16, marginRight: 24 }}
        />
      </FormGroup>
      <FormGroup row>
        <FormikSelect
          name={`${fieldName}.accompanySupportOutTimeDate`}
          label="同行支援終了時間"
          options={DAY_SELECT_OPTIONS}
          style={{ width: 80 }}
          noerrortext
          onChangeHook={(e): void => handleChangeDateSelect(e, true)}
          FormLabelClasses={{ root: classes.selectLabel }}
        />
        <FormikTime
          name={`${fieldName}.accompanySupportOutTime`}
          placeholder="00:00"
          size="smallMedium"
          maxLength={5}
          onChangeHookTime={(e, before, auto): void =>
            handleChangeInitialTime(e, before, auto, true)
          }
          style={{ marginTop: 16, marginRight: 24 }}
        />
      </FormGroup>

      <MuiTextField
        value={practitionerValues.calculatedAccompanySupportHours}
        label="同行支援の時間数"
        size="smallMedium"
        disabled
        disabledStyle
      />

      <MuiTextField
        value={practitionerValues.calculatedAccompanySupportMovingHours}
        label="同行支援の移動時間数"
        size="smallMedium"
        disabled
        disabledStyle
        style={{ marginLeft: 8 }}
        endAdornmentLabel="時間"
      />
    </FormGroup>
  );
};

export const ServiceDeliveryAccompanySupportField = withStyles(styles)(
  ServiceDeliveryAccompanySupportFieldCore
);
