import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { AssessmentState } from "@stores/domain/assessment/types";

import { UsersInFacilityState as IABUsersInFacilityState } from "@stores/domain/mgr/IAB/userInFacility/types";

import dispatches from "@stores/dispatches";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  AB_ASSESSMENT_CATEGORY_TYPE,
  AB_ASSESSMENT_CATEGORY_TYPE_NAME,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  IKOU_ASSESSMENT_CATEGORY_TYPE,
  IKOU_ASSESSMENT_CATEGORY_TYPE_NAME,
  IKOU_ASSESSMENT_DEFAULT_ITEM
} from "@constants/variables";

// utils
import { dateToLocalisedString } from "@utils/date";
import getAge from "@utils/date/getAge";
import { getUrlParams } from "@/utils/url";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 14,
      marginBottom: 20
    },
    headerTitle: {
      fontSize: 18
    },
    headerCreate: {
      fontSize: 10
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start"
    },
    userInfoNameContainer: {
      width: 288,
      borderBottom: "1px solid #000"
    },
    userInfoBirthContainer: {
      width: 128,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoGenderContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoRecipientNumberContainer: {
      width: 288,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyAContainer: {
      width: 300,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyIBContainer: {
      width: 376,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyDisabilitySupportContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      fontSize: 10,
      color: "#212121",
      paddingBottom: 5
    },
    table: {
      display: "grid",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid #000",
      textAlign: "left",
      width: "100%",
      gridTemplateColumns: "73px 142px 1fr",
      "&.support": {
        gridTemplateColumns: "73px 128px repeat(4, 48px) 1fr"
      },
      "&.aptitudeSkill": {
        gridTemplateColumns: "73px 128px repeat(3, 48px) 1fr"
      }
    },
    cell: {
      display: "flex",
      alignItems: "center",
      padding: "4px 6px",
      borderRight: "1px solid #000",
      borderBottom: "1px solid #000",
      fontSize: 10,
      letterSpacing: "normal",
      minHeight: 24,
      height: "100%",
      color: "rgba(0, 0, 0, 0.84)",
      "&.label": {
        justifyContent: "center",
        borderBottom: "2px solid #000"
      },
      "&.text": {
        paddingLeft: 8,
        wordWrap: "break-word"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.lastRow": {
        borderBottom: "none"
      },
      "&.lastColumn": {
        borderRight: "none"
      },
      "&.small": {
        padding: 0
      },
      "&.borderDashed": {
        borderRight: "1px dotted #000"
      },
      // 就労歴セル
      "&.workHistoryCell": {
        padding: 0,
        flexDirection: "column"
      }
    },
    // 就業履歴関連のスタイル
    tableWorkRow: {
      display: "flex",
      width: "100%"
    },
    tableWorkIndex: {
      display: "flex",
      alignItems: "center",
      padding: "0.5px 2px",
      width: 20,
      minWidth: 20,
      height: "100%",
      borderRight: "1px solid #000",
      justifyContent: "center",
      "&:not(.lastRow)": {
        borderBottom: "1px solid #000"
      }
    },
    tableWorkData: {
      flexGrow: 1,
      display: "grid",
      gridTemplateColumns: "85px 1fr 85px 1fr",
      "&> div": {
        padding: "4px 6px",
        "&:not(.lastRow)": {
          borderBottom: "1px solid #000"
        },
        "&:not(.lastColumn)": {
          borderRight: "1px solid #000"
        }
      }
    },
    tableWork2col: {
      gridColumn: "2 / 5"
    },
    tableChoice2lineContainer: {
      maxHeight: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: "14px"
    },
    tableChoiceLine1: {
      whiteSpace: "nowrap",
      transform: "scale(0.8)"
    },
    tableChoiceLine2: {
      marginTop: -3,
      whiteSpace: "nowrap",
      transform: "scale(0.8)"
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    categoryName: {
      marginTop: 24,
      width: "100%",
      fontFamily: "HiraginoSans-W6",
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginBottom: 10,
      paddingBottom: 5,
      whiteSpace: "initial",
      "&.evaluationPeriod": {
        display: "flex",
        justifyContent: "space-between"
      }
    },
    evaluationPeriodDate: {
      color: "#212121",
      fontFamily: "HiraginoSans-W3"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  user: UserState;
  assessmentRecord: AssessmentState["assessment"];
  userInFacility: IABUsersInFacilityState["user"];
  customRecords: CustomRecordsWithCategoryState;
};

type OwnProps = {
  uifId: string;
  assessmentId: string;
  query?: string;
};

type DisplayParams = {
  display_works?: string;
  display_support_skills?: string;
  display_others?: string;
};

type DispatchProps = {
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  getUserDetailInFacilityData: (
    uifId: string,
    facility_type: FacilityType
  ) => void;
};

type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: StateProps["userInFacility"];
  assessmentRecord: AssessmentState["assessment"];
  customRecords: CustomRecordsWithCategoryState;
  classes: Record<string, string>;
  facilityType: FacilityType;
  displayParams: DisplayParams;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type ContentsProps = {
  customRecords: CustomRecordsWithCategoryState;
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"];
  categoryType: number;
  classes: Record<string, string>;
  facilityType: FacilityType;
};

const getEvaluationPeriod = (
  facilityType: FacilityType,
  categoryType: number,
  customRecords: CustomRecordsWithCategoryState,
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"]
): {
  startDate?: string;
  endDate?: string;
} => {
  const hasEvaluationPeriodCategory =
    facilityType === FacilityType.IKOU &&
    categoryType === IKOU_ASSESSMENT_CATEGORY_TYPE.support;

  if (!hasEvaluationPeriodCategory) {
    return {};
  }

  const evaluationPeriodCategoryType =
    IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period;
  const categoryIndex = customRecords.findIndex((v) => {
    return v.category_type === evaluationPeriodCategoryType;
  });

  const itemId = IKOU_ASSESSMENT_DEFAULT_ITEM.evaluationPeriod;
  const itemIndex =
    categoryIndex !== -1
      ? customRecords[categoryIndex].custom_record_items.findIndex(
          (v) => v.default_item === itemId
        )
      : -1;
  const [startChoiceId, endChoiceId] = customRecords[
    categoryIndex
  ].custom_record_items[itemIndex].custom_record_item_choices
    .sort((a, b) => {
      if (!a.default_choice || !b.default_choice) return 0;
      return a.default_choice - b.default_choice;
    })
    .map((v) => v.id);

  const categoryValueIndex = assessmentRecords.findIndex(
    (v) => v.custom_records_category_id === customRecords[categoryIndex].id
  );

  if (categoryValueIndex === -1) return {};

  const startEvaluationPeriodInput = assessmentRecords[
    categoryValueIndex
  ].input.find((v) => v.choiced_item_id === startChoiceId);

  const startDate =
    startEvaluationPeriodInput &&
    startEvaluationPeriodInput.input_data &&
    dateToLocalisedString(
      startEvaluationPeriodInput.input_data,
      "YYYY年M月D日"
    );

  const endEvaluationPeriodInput = assessmentRecords[
    categoryValueIndex
  ].input.find((v) => v.choiced_item_id === endChoiceId);

  const endDate =
    endEvaluationPeriodInput &&
    endEvaluationPeriodInput.input_data &&
    dateToLocalisedString(endEvaluationPeriodInput.input_data, "YYYY年M月D日");

  return {
    startDate,
    endDate
  };
};

const WorkHistoryCell = (props: {
  classes: Record<string, string>;
  workHistories: NonNullable<
    AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]["work_histories"]
  >;
  isLastItem: boolean;
}): JSX.Element => {
  const { classes, workHistories, isLastItem } = props;

  return (
    <div
      className={`${classes.cell} workHistoryCell lastColumn${
        isLastItem ? " lastRow" : ""
      }`}
    >
      {workHistories.map((workHistory, hIdx) => {
        const isLastRow = workHistories.length === hIdx + 1;
        return (
          <div
            className={classes.tableWorkRow}
            key={`workHistory-${workHistory.id}`}
          >
            <div
              className={`${classes.tableWorkIndex}${
                isLastRow ? " lastRow" : ""
              }`}
            >
              {hIdx + 1}
            </div>
            <div className={classes.tableWorkData}>
              <div>開始年月</div>
              <div>
                {workHistory.begin_date
                  ? dateToLocalisedString(workHistory.begin_date, "YYYY年M月")
                  : ""}
              </div>
              <div>終了年月</div>
              <div className="lastColumn">
                {workHistory.end_date
                  ? dateToLocalisedString(workHistory.end_date, "YYYY年M月")
                  : ""}
              </div>
              {/* 企業名 */}
              <div>企業名・施設名</div>
              <div className={`lastColumn ${classes.tableWork2col}`}>
                {workHistory.facility_name}
              </div>
              {/* 詳細 */}
              <div className={isLastRow ? "lastRow" : ""}>詳細</div>
              <div
                className={`lastColumn ${classes.tableWork2col}${
                  isLastRow ? " lastRow" : ""
                }`}
              >
                {workHistory.details}
              </div>
              {/* </div> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const Contents = (props: ContentsProps): JSX.Element => {
  const { customRecords, assessmentRecords, categoryType, classes } = props;

  const facilityCategoryType = {
    employment:
      props.facilityType === FacilityType.IKOU
        ? IKOU_ASSESSMENT_CATEGORY_TYPE.employment
        : AB_ASSESSMENT_CATEGORY_TYPE.employment,
    support:
      props.facilityType === FacilityType.IKOU
        ? IKOU_ASSESSMENT_CATEGORY_TYPE.support
        : AB_ASSESSMENT_CATEGORY_TYPE.support,
    aptitude_skill:
      props.facilityType === FacilityType.IKOU
        ? IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
        : AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
    others:
      props.facilityType === FacilityType.IKOU
        ? IKOU_ASSESSMENT_CATEGORY_TYPE.others
        : AB_ASSESSMENT_CATEGORY_TYPE.others
  };

  // 選択肢情報からテーブルヘッダーを作成
  let header: JSX.Element;
  const getHeader = (
    choices: { id: number; string: string[] }[]
  ): JSX.Element => {
    return (
      <>
        <div className={`${classes.cell} label`}>カテゴリー</div>
        <div className={`${classes.cell} label`}>項目</div>
        {choices.map((c, ci) => (
          <div
            key={`td-choice-${c.id}`}
            className={`${classes.cell} small label ${
              // 最後の一つは右ボーダは通常線
              ci !== choices.length - 1 ? "borderDashed" : ""
            }`}
          >
            <div className={`${classes.tableChoice2lineContainer}`}>
              {c.string.map((string, idx) =>
                idx === 0 ? (
                  <div key={`d1-${c.id}`} className={classes.tableChoiceLine1}>
                    {string}
                  </div>
                ) : (
                  <div key={`d2-${c.id}`} className={classes.tableChoiceLine2}>
                    {string}
                  </div>
                )
              )}
            </div>
          </div>
        ))}
        <div className={`${classes.cell} label`}>備考</div>
      </>
    );
  };

  // カテゴリータイプに対応する選択肢項目
  switch (categoryType) {
    case facilityCategoryType.support:
      header = getHeader([
        { id: 1, string: ["できる"] },
        { id: 2, string: ["少し", "支援が必要"] },
        { id: 3, string: ["支援が必要"] },
        { id: 4, string: ["できない"] }
      ]);
      break;
    case facilityCategoryType.aptitude_skill:
      header = getHeader([
        { id: 1, string: ["ある"] },
        { id: 2, string: ["少しある"] },
        { id: 3, string: ["ない"] }
      ]);
      break;
    default:
      header = <></>;
      break;
  }

  // 記録項目に対応する保存内容を取得
  const getInput = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number] => {
    const categoryIdx = assessmentRecords.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx !== -1) {
      const inputIdx = assessmentRecords[categoryIdx].input.findIndex(
        (i) => i.custom_record_item_id === item.id
      );
      if (inputIdx !== -1) {
        return assessmentRecords[categoryIdx].input[inputIdx];
      }
    }
    return {
      id: 0,
      custom_record_item_id: 0,
      input_data: ""
    };
  };

  // 入力がある記録項目のみ取得してソート
  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          if (i.name === "障害の情報開示" && (!i.visibility || !r.visibility)) {
            return false;
          }
          const input = getInput(i);
          const exist_work_histories =
            input.work_histories && input.work_histories.length > 0;
          const open_information = i.name === "障害の情報開示";
          return (
            input.input_data ||
            !!input.choiced_item_id ||
            exist_work_histories ||
            open_information
          );
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter((r) => {
      return r.custom_record_items.length > 0;
    })
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

  const getInputData = (
    inputType: number,
    input: AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number]
  ): string => {
    // 「障害の情報開示」の項目の場合
    if (inputType === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox) {
      return input.checked === 1 ? "希望する" : "希望しない";
    }
    return input.input_data;
  };

  const getTableRows = (): JSX.Element[][] => {
    /** このテーブルで描画された行数 */
    let displayRowCount = 0;
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // 項目ごと
      return category.custom_record_items.map((item, iIdx) => {
        const input = getInput(item);
        const lastItem =
          lastCategory && category.custom_record_items.length === iIdx + 1;
        displayRowCount += 1;
        return (
          <React.Fragment key={item.id}>
            {iIdx === 0 && (
              <div
                className={`${classes.cell} text${
                  lastCategory ? " lastRow" : ""
                }`}
                style={{
                  gridRowStart: displayRowCount,
                  gridRowEnd:
                    displayRowCount + category.custom_record_items.length
                }}
              >
                {category.name}
              </div>
            )}
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {item.name}
            </div>
            {input.work_histories ? (
              <WorkHistoryCell
                classes={classes}
                workHistories={input.work_histories}
                isLastItem={lastItem}
              />
            ) : (
              <div
                className={`${classes.cell} text lastColumn${
                  lastItem ? " lastRow" : ""
                }`}
              >
                {getInputData(item.input_type, input)}
              </div>
            )}
          </React.Fragment>
        );
      });
    });
  };

  const getTableRowsRadio = (): JSX.Element[][] => {
    /** このテーブルで描画された行数(初期値でヘッダー行) */
    let displayRowCount = 1;
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // 項目ごと
      return category.custom_record_items.map((customRecordItem, iIdx) => {
        const input = getInput(customRecordItem);
        const lastItem =
          lastCategory && category.custom_record_items.length === iIdx + 1;
        displayRowCount += 1;
        return (
          <React.Fragment key={customRecordItem.id}>
            {iIdx === 0 && (
              <div
                className={`${classes.cell} text${
                  lastCategory ? " lastRow" : ""
                }`}
                style={{
                  gridRowStart: displayRowCount,
                  gridRowEnd:
                    displayRowCount + category.custom_record_items.length
                }}
              >
                {category.name}
              </div>
            )}
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {customRecordItem.name}
            </div>
            {customRecordItem.custom_record_item_choices.map(
              (choice, choiceIndex) => {
                const lastDot =
                  customRecordItem.custom_record_item_choices.length !==
                  choiceIndex + 1;
                return (
                  <div
                    key={`choice-${choice.id}`}
                    className={`${classes.cell} center${
                      lastDot ? " borderDashed" : ""
                    }${lastItem ? " lastRow" : ""}`}
                  >
                    {input.choiced_item_id === choice.id && "●"}
                  </div>
                );
              }
            )}
            <div
              className={`${classes.cell} text lastColumn${
                lastItem ? " lastRow" : ""
              }`}
            >
              {input.input_data}
            </div>
          </React.Fragment>
        );
      });
    });
  };

  const evaluationValue = getEvaluationPeriod(
    props.facilityType,
    props.categoryType,
    props.customRecords,
    props.assessmentRecords
  );

  if (
    sortedCustomRecords.length === 0 &&
    !evaluationValue.startDate &&
    !evaluationValue.endDate
  ) {
    return <></>;
  }

  const addClasses = {
    [facilityCategoryType.employment]: "",
    [facilityCategoryType.support]: "support",
    [facilityCategoryType.aptitude_skill]: "aptitudeSkill",
    [facilityCategoryType.others]: ""
  };

  const CategoryHeader = ({
    evaluationPeriod
  }: {
    evaluationPeriod: ReturnType<typeof getEvaluationPeriod>;
  }): JSX.Element => {
    if (evaluationPeriod.startDate || evaluationPeriod.endDate) {
      return (
        <div className={`${classes.categoryName} evaluationPeriod`}>
          <span>{IKOU_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}</span>

          <span className={classes.evaluationPeriodDate}>
            評価期間 {evaluationPeriod.startDate} 〜 {evaluationPeriod.endDate}
          </span>
        </div>
      );
    }

    return (
      <div className={classes.categoryName}>
        {props.facilityType === FacilityType.IKOU
          ? IKOU_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]
          : AB_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}
      </div>
    );
  };

  return (
    <div>
      <CategoryHeader evaluationPeriod={evaluationValue} />
      {sortedCustomRecords.length !== 0 && (
        <div
          className={`${classes.table} ${addClasses[categoryType]} fullWidth`}
        >
          {categoryType === facilityCategoryType.employment ||
          categoryType === facilityCategoryType.others ? (
            getTableRows()
          ) : (
            <>
              {header}
              {getTableRowsRadio()}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const AssessmentPrintCore = (props: Props): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);
  const {
    uifId,
    assessmentId,
    classes,
    userInFacility,
    assessmentRecord,
    customRecords,
    user,
    getUserDetailInFacilityData,
    fetchAssessment,
    fetchCustomRecords,
    query
  } = props;

  React.useEffect(() => {
    getUserDetailInFacilityData(uifId, user.facility_type);
    fetchAssessment(uifId, assessmentId);
    fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  if (customRecords.length === 0 || !assessmentRecord.created_at) {
    return null;
  }

  const params: DisplayParams = query ? getUrlParams(query) : {};

  return (
    <Sheet
      classes={classes}
      userInFacility={userInFacility}
      assessmentRecord={assessmentRecord}
      customRecords={customRecords}
      facilityType={user.facility_type}
      displayParams={params}
    />
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, userInFacility, assessmentRecord, customRecords } = props;

  const userDetail = userInFacility.user_in_facility;

  const getAuthorString = (): string => {
    const { author } = assessmentRecord;
    if (!author) {
      return "-";
    }
    const authorName = author.snapshot_name || author.name;
    const authorRole = author.snapshot_role || author.role;
    return `${authorName}（${authorRole}）`;
  };

  const printCategory =
    props.facilityType === FacilityType.IKOU
      ? []
      : [
          AB_ASSESSMENT_CATEGORY_TYPE.employment,
          AB_ASSESSMENT_CATEGORY_TYPE.support,
          AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
          AB_ASSESSMENT_CATEGORY_TYPE.others
        ];

  if (props.facilityType === FacilityType.IKOU) {
    const {
      display_works,
      display_support_skills,
      display_others
    } = props.displayParams;
    if (display_works === "true") {
      printCategory.push(IKOU_ASSESSMENT_CATEGORY_TYPE.employment);
    }
    if (display_support_skills === "true") {
      printCategory.push(IKOU_ASSESSMENT_CATEGORY_TYPE.support);
      printCategory.push(IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill);
    }
    if (display_others === "true") {
      printCategory.push(IKOU_ASSESSMENT_CATEGORY_TYPE.others);
    }
  }

  // 障害種別
  const flags = {
    classify_physical_flg: "身体障害",
    classify_intelligence_flg: "知的障害",
    classify_mind_flg: "精神障害",
    classify_growth_flg: "発達障害",
    classify_brain_flg: "高次脳機能障害",
    classify_handicapped_flg: "障害児",
    classify_incurable_flg: "難病等対象者"
  };

  const classifies = Object.entries(flags)
    .filter(([flag]) => userDetail[flag] === "1")
    .map(([, value]) => value);

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>アセスメントシート</div>
        <div>
          <div className={classes.headerCreate}>
            作成日：
            {assessmentRecord.assessment.target_date
              ? dateToLocalisedString(
                  assessmentRecord.assessment.target_date,
                  "YYYY年M月D日"
                )
              : "-"}
          </div>
          <div className={classes.headerCreate}>
            作成者：{getAuthorString()}
          </div>
        </div>
      </div>
      <div>
        <div className={classes.userInfo}>
          <div className={classes.userInfoNameContainer}>
            <span className={classes.userInfoItem}>氏名（フリガナ）</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.name_sei} ${userDetail.name_mei}（${userDetail.name_sei_kana} ${userDetail.name_mei_kana}）`}</span>
            </div>
          </div>
          <div className={classes.userInfoBirthContainer}>
            <span className={classes.userInfoItem}>生年月日(年齢)</span>
            <div className={classes.userInfoValue}>
              <span>
                {userDetail.date_birth !== undefined
                  ? dateToLocalisedString(userDetail.date_birth, "YYYY年M月D日")
                  : ""}
                {`(${getAge(
                  userDetail.date_birth,
                  assessmentRecord.created_at
                )}歳)`}
              </span>
            </div>
          </div>
          <div className={classes.userInfoGenderContainer}>
            <span className={classes.userInfoItem}>性別</span>
            <div className={classes.userInfoValue}>
              <span>{userDetail.gender === "1" ? "男性" : "女性"}</span>
            </div>
          </div>
        </div>
        <div className={classes.userInfo}>
          <div className={classes.userInfoRecipientNumberContainer}>
            <span className={classes.userInfoItem}>受給者証番号</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.recipient_number}`}</span>
            </div>
          </div>
          <div
            className={
              props.facilityType === FacilityType.A
                ? classes.userInfoClassifyAContainer
                : classes.userInfoClassifyIBContainer
            }
          >
            <span className={classes.userInfoItem}>障害種別</span>
            <div className={classes.userInfoValue}>
              <span>{classifies.length > 0 ? classifies.join("、") : "-"}</span>
            </div>
          </div>
          {props.facilityType === FacilityType.A && (
            <div className={classes.userInfoClassifyDisabilitySupportContainer}>
              <span className={classes.userInfoItem}>障害支援区分</span>
              <div className={classes.userInfoValue}>
                <span>
                  {userDetail.classify_disability_support !== "0"
                    ? `区分${userDetail.classify_disability_support}`
                    : "なし"}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {printCategory.map((type) => (
        <Contents
          key={type}
          customRecords={customRecords}
          assessmentRecords={assessmentRecord.assessment.assessment_records}
          categoryType={type}
          classes={classes}
          facilityType={props.facilityType}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const assessmentRecord = state.assessment.assessment;
  const getUserInFacility = (): StateProps["userInFacility"] => {
    return state.IAB.userInFacility.user;
  };
  const customRecords = state.customRecordsWithCategory.assesment;
  return {
    user,
    assessmentRecord,
    userInFacility: getUserInFacility(),
    customRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { assessmentDispatcher, customRecordsWithCategory } = dispatches;

  const fetchAssessment = (
    uifId: string,
    assessmentId: string
  ): Promise<void> =>
    assessmentDispatcher(dispatch).fetchAssessment(uifId, assessmentId);
  const getUserDetailInFacilityData = (uifId: string): Promise<void> => {
    return dispatches.IAB.userInFacilityDispatcher(dispatch).fetchOne(uifId);
  };
  const fetchCustomRecords = (): Promise<void> =>
    customRecordsWithCategory(dispatch).fetchCustomRecords(
      CUSTOM_RECORD_TARGET_TYPE.assessment
    );

  return {
    fetchAssessment,
    fetchCustomRecords,
    getUserDetailInFacilityData
  };
};

export const AssessmentPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssessmentPrintCore));
