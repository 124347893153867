import React from "react";

// props
import * as H from "history";
import { SupportReportState } from "@stores/domain/supportReport/types";
import { AppState } from "@stores/type";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import * as recordSupportReportActions from "@stores/pages/record/supportReport/actions";

import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState } from "@stores/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { RecordSupportReportState } from "@stores/pages/record/supportReport/types";

// ui
import RecordHeaderAllEditType from "@components/organisms/mgr/common/record/RecordHeaderAllEditType";
import { SupportReportTable } from "@components/organisms/mgr/SHUROTEICHAKU/record/SupportReportTable";
import NoRecord from "@components/organisms/mgr/common/record/NoRecord";

// formik
import { Formik, Form, FormikActions } from "formik";
import initialValues, {
  SupportReportValues
} from "@initialize/mgr/SHUROTEICHAKU/record/supportReport/initialValues";
import isEqual from "lodash-es/isEqual";
import validation from "@initialize/mgr/SHUROTEICHAKU/record/supportReport/validation";
import { toEffectiveObject } from "@utils/object";

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  year: string;
  month: string;
  supportReport: SupportReportState;
  staff: StaffState;
  staffOptions: FieldItem[];
  recordSupportReport: RecordSupportReportState;
  history: H.History;
  user: UsersInFacilityState["user"];
};
type StateProps = {
  needsStopHistory: boolean;
};
type DispatchProps = {
  postSupportReport: (
    uifId: string,
    params: SupportReportValues,
    initialValue: SupportReportValues,
    year: string,
    month: string,
    staff: StaffState
  ) => void;

  setEditing: (inoutId?: number) => void;
  unsetEditing: () => void;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

/**
 * 利用者ごとの支援レポートの管理を扱う
 */
const SupportReportRecordCore = (props: Props): JSX.Element => {
  let noRecordMessage = "";
  if (props.supportReport.support.usage_results.length === 0) {
    noRecordMessage =
      "利用実績がありません。利用実績を入力後、ご利用ください。";
  }
  const hasRecord = noRecordMessage === "";
  const noCreatedAt = !props.supportReport.created_at;

  // state
  const [formValues, setFormValues] = React.useState(
    initialValues(props.supportReport.support, props.user)
  );
  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditing(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);
  React.useEffect(() => {
    setFormValues(initialValues(props.supportReport.support, props.user));
  }, [props.supportReport.support]);

  // handler
  const onClickEdit = (e: React.MouseEvent): void => {
    props.setEditing();
    e.preventDefault();
  };

  // formik handler
  const confirmDiscardFormChanges = (nextValues: SupportReportValues): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };
  const validate = (values: SupportReportValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };
  const onSubmit = async (
    values: SupportReportValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postSupportReport(
      props.uifId,
      values,
      formValues,
      props.year,
      props.month,
      props.staff
    );
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const onClickEditCancel = (): void => {
          formikProps.resetForm();
          props.unsetEditing();
          setFormValues(initialValues(props.supportReport.support, props.user));
          props.stopHistory(false);
        };
        return (
          <Form>
            <RecordHeaderAllEditType
              pageName={props.pageName}
              userName={props.userName}
              hasRecord={hasRecord}
              uifId={props.uifId}
              year={props.year}
              month={props.month}
              recordType="support_report"
              isEditing={props.recordSupportReport.isEditing}
              onClickEdit={onClickEdit}
              onClickEditCancel={onClickEditCancel}
              formikProps={formikProps}
              history={props.history}
              disablePrintButton={noCreatedAt}
              isExistSupportReport
            />

            {hasRecord ? (
              <>
                <SupportReportTable
                  SupportReportState={props.supportReport}
                  staffOptions={props.staffOptions}
                  formikProps={formikProps}
                  isEditing={props.recordSupportReport.isEditing}
                  initialValues={formValues}
                />
              </>
            ) : (
              <NoRecord message={noRecordMessage} />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { pages, uiDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const recordSupportReportDispatcher = pages.recordSupportReportDispatcher(
    dispatch
  );
  return {
    postSupportReport: (
      uifId: string,
      params: SupportReportValues,
      initialValue: SupportReportValues,
      year: string,
      month: string,
      staff: StaffState
    ): void => {
      recordSupportReportDispatcher.postSupportReport(
        uifId,
        params,
        initialValue,
        staff,
        { year, month }
      );
    },
    setEditing: (): recordSupportReportActions.ActionTypes =>
      dispatch(recordSupportReportActions.setEdit()),
    unsetEditing: (): recordSupportReportActions.ActionTypes =>
      dispatch(recordSupportReportActions.unsetEdit()),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const SupportReportRecord = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportReportRecordCore);
