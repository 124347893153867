import * as React from "react";
import * as URL from "@constants/url";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { StaffMeetingRecordPrintContent } from "@components/organisms/mgr/Cseg/record/staffMeetingRecord/StaffMeetingRecordPrintContent";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { FacilityType } from "@constants/variables";
import { UserState } from "@stores/domain/user/type";
import { MeetingRecordState } from "@stores/domain/mgr/Cseg/meetingRecord/types";

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  meetingRecordId: string;
}>;
type StateProps = {
  user: UserState;
  userInFacility:
    | KEIKAKUSODANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
  meetingRecord: MeetingRecordState;
};
type DispatchProps = {
  fetchOneUser: (facilityType: FacilityType, id: string) => void;
  fetchMeetingRecord: (uifId: string, meetingRecordId: string) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const StaffMeetingRecordPrintCore = (props: Props): JSX.Element => {
  const { uifId, meetingRecordId } = props.match.params;
  const { user, userInFacility, meetingRecord } = props;
  // ページ名
  const listName =
    user.facility_type === FacilityType.KEIKAKUSODAN
      ? "計画・モニタリング・会議記録"
      : "計画・報告書・会議記録";
  const pageName = "サービス担当者会議記録";
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(user.facility_type, uifId);
    props.fetchMeetingRecord(uifId, meetingRecordId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <StaffMeetingRecordPrintContent
          user={userInFacility}
          meetingRecord={meetingRecord}
          title={pageName}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const facilityType = user.facility_type;
  return {
    user,
    userInFacility: state[facilityType].userInFacility.user,
    meetingRecord: state.Cseg.meetingRecord
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg } = dispatches;
  return {
    fetchOneUser: (facilityType: FacilityType, id: string): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchMeetingRecord: (
      uifId: string,
      meetingRecordId: string
    ): Promise<void> =>
      Cseg.meetingRecordDispatcher(dispatch).fetchMeetingRecord(
        uifId,
        meetingRecordId
      )
  };
};

export const StaffMeetingRecordPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffMeetingRecordPrintCore);
