import * as React from "react";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import FieldWrapper, { FieldSizeName } from "@components/atoms/FieldWrapper";
import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    input: {
      height: 19,
      "&:-webkit-autofill": {
        boxShadow: "0 0 0 1000px white inset"
      }
    },
    inputMultiline: {
      overflow: "hidden"
    },
    endAdornmentLabel: {
      whiteSpace: "nowrap",
      "& > p": {
        color: "rgba(0, 0, 0, 0.87)!important",
        fontSize: 16
      }
    },
    labelRoot: {
      color: "#37474f!important"
    }
  });

type OwnProps = {
  type?: "text" | "password" | "number" | "tel" | "email" | "date";
  minLength?: number;
  maxLength?: number;
  startAdornmentLabel?: string;
  endAdornmentLabel?: string;
  size?: FieldSizeName;
  multiline?: boolean;
  rows?: string;
  inputProps?: never;
  InputProps?: never;
  label?: string; // ReactNodeを渡すとバグがあるのでしばらくstring固定
  helperText?: string; // 理由は上と同じで発生したことはまだないが塞いでおく
};

type Props = OwnProps & WithStyles<typeof styles> & TextFieldProps;

const MobileMuiTextFieldCore = ({
  minLength,
  maxLength,
  startAdornmentLabel,
  endAdornmentLabel,
  size,
  multiline,
  rows,
  classes,
  style,
  className,
  ...props
}: Props): JSX.Element => {
  const startAdornment = startAdornmentLabel ? (
    <InputAdornment position="start">{startAdornmentLabel}</InputAdornment>
  ) : null;
  const endAdornment = endAdornmentLabel ? (
    <InputAdornment
      position="end"
      classes={{ root: classes.endAdornmentLabel }}
    >
      {endAdornmentLabel}
    </InputAdornment>
  ) : null;

  return (
    <FieldWrapper
      size={size}
      style={{ marginBottom: 24, marginRight: 0, ...style }}
    >
      <TextField
        {...props}
        inputProps={{
          minLength,
          maxLength
        }}
        /* inputPropsとInputPropsは別のpropsだが誤認されている */
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          startAdornment,
          endAdornment,
          classes: {
            input: classes.input,
            inputMultiline: classes.inputMultiline
          }
        }}
        InputLabelProps={{
          shrink: true,
          classes: {
            root: classes.labelRoot
          }
        }}
        multiline={multiline || false}
        rows={rows}
        variant="outlined"
        placeholder={props.placeholder}
        className={`${className}`}
      />
    </FieldWrapper>
  );
};

MobileMuiTextFieldCore.defaultProps = {
  fullWidth: true,
  InputLabelProps: {
    shrink: true
  }
};

export const MobileMuiTextField = withStyles(styles)(MobileMuiTextFieldCore);
