import _isEqual from "lodash-es/isEqual";
import _isNil from "lodash-es/isNil";

import { selectDateValueToDatePaddingZero } from "@utils/date";
import { MonitoringReportValues } from "@initialize/mgr/KEIKAKUSODAN/record/monitoringReport/initialValues";
import { PostMonitoringReport } from "@api/requests/monitoringReport/postMonitoringReport";

/** モニタリング詳細のフィルタリング関数を生成する関数 */
const generateFunctionFilterDetail = (
  initialValues?: MonitoringReportValues
) => (detail: MonitoringReportValues["details"][number]): boolean => {
  const targetId = detail.id;
  const beforeDetail = initialValues
    ? initialValues.details.find((d) => d.id === targetId)
    : null;
  if (!beforeDetail) {
    // 同じ値がないが、削除対象のデータが残っていたら除外したいためis_deleteを見ている
    return !detail.is_delete;
  }
  // 全く同じ値のデータは除外する
  if (_isEqual(detail, beforeDetail)) {
    return false;
  }
  return true;
};

/** モニタリング詳細の重複したkeyを除外する関数を生成する関数 */
const generateFunctionNormalizeDetail = (
  initialValues?: MonitoringReportValues
): ((
  detail: MonitoringReportValues["details"][number]
) => Required<PostMonitoringReport>["details"][number]) => {
  const beforeDetails = initialValues ? initialValues.details : [];

  return (
    detail: MonitoringReportValues["details"][number]
  ): Required<PostMonitoringReport>["details"][number] => {
    const beforeDetail = beforeDetails.find((d) => d.id === detail.id);

    const postDetail: Required<PostMonitoringReport>["details"][number] = {
      id: detail.id || null,
      support_goal: detail.support_goal || null,
      achievement_time: detail.achievement_time || null,
      service_provision_status: detail.service_provision_status || null,
      personal_satisfaction: detail.personal_satisfaction || null,
      achievement_support_goals: detail.achievement_support_goals || null,
      future_issues: detail.future_issues || null,
      change_plan_service_type:
        detail.change_plan_service_type === "0" ||
        detail.change_plan_service_type === "1"
          ? Number(detail.change_plan_service_type)
          : null,
      change_plan_service_volume:
        detail.change_plan_service_volume === "0" ||
        detail.change_plan_service_volume === "1"
          ? Number(detail.change_plan_service_volume)
          : null,
      change_plan_weekly:
        detail.change_plan_weekly === "0" || detail.change_plan_weekly === "1"
          ? Number(detail.change_plan_weekly)
          : null,
      other_considerations: detail.other_considerations || null,
      is_delete: detail.is_delete
    };

    if (beforeDetail) {
      if (_isEqual(detail.support_goal, beforeDetail.support_goal)) {
        delete postDetail.support_goal;
      }
      if (_isEqual(detail.achievement_time, beforeDetail.achievement_time)) {
        delete postDetail.achievement_time;
      }
      if (
        _isEqual(
          detail.service_provision_status,
          beforeDetail.service_provision_status
        )
      ) {
        delete postDetail.service_provision_status;
      }
      if (
        _isEqual(
          detail.personal_satisfaction,
          beforeDetail.personal_satisfaction
        )
      ) {
        delete postDetail.personal_satisfaction;
      }
      if (
        _isEqual(
          detail.achievement_support_goals,
          beforeDetail.achievement_support_goals
        )
      ) {
        delete postDetail.achievement_support_goals;
      }
      if (_isEqual(detail.future_issues, beforeDetail.future_issues)) {
        delete postDetail.future_issues;
      }
      if (
        _isEqual(
          detail.change_plan_service_type,
          beforeDetail.change_plan_service_type
        )
      ) {
        delete postDetail.change_plan_service_type;
      }
      if (
        _isEqual(
          detail.change_plan_service_volume,
          beforeDetail.change_plan_service_volume
        )
      ) {
        delete postDetail.change_plan_service_volume;
      }
      if (
        _isEqual(detail.change_plan_weekly, beforeDetail.change_plan_weekly)
      ) {
        delete postDetail.change_plan_weekly;
      }
      if (
        _isEqual(detail.other_considerations, beforeDetail.other_considerations)
      ) {
        delete postDetail.other_considerations;
      }
    }

    return postDetail;
  };
};

export const normalizeFormValuesToPostMonitoringReport = (
  values: MonitoringReportValues,
  initialValues?: MonitoringReportValues
): PostMonitoringReport => {
  const filterDetail = generateFunctionFilterDetail(initialValues);
  const normalizeDetail = generateFunctionNormalizeDetail(initialValues);

  const postData: PostMonitoringReport = {
    id: initialValues ? values.id : null,
    date_of_monitoring:
      selectDateValueToDatePaddingZero(values.date_of_monitoring) || "",
    creation_date:
      selectDateValueToDatePaddingZero(values.creation_date) || null,
    author: Number(values.author) || null,
    classify_disability_support:
      Number(values.classify_disability_support) || 0,
    income_kind: values.income_kind || null,
    income_category: Number(values.income_category) || 0,
    disabled_welfare_service_recipient_number:
      values.disabled_welfare_service_recipient_number || null,
    consultation_support_recipient_number:
      values.consultation_support_recipient_number || null,
    regular_place_recipient_number:
      values.regular_place_recipient_number || null,
    support_policy: values.support_policy || null,
    whole_situation: values.whole_situation || null,
    staff_comment: values.staff_comment || null,
    details: values.details
      // .filter((detail) => !(!detail.id && detail.is_delete))
      .filter(filterDetail)
      .map(normalizeDetail)
  };

  // 新規の場合は相談種別（計画・障害児）の設定
  if (!initialValues) {
    postData.type_consultation = values.type_consultation;
  }

  // 障害児相談の場合、保護者関連情報を設定
  if (!_isNil(values.guardian_name) && !_isNil(values.guardian_relation)) {
    postData.guardian_name = values.guardian_name;
    postData.guardian_relation = values.guardian_relation;
  }

  // 編集の場合、未変更のものは除外
  if (initialValues) {
    if (_isEqual(values.date_of_monitoring, initialValues.date_of_monitoring)) {
      delete postData.date_of_monitoring;
    }
    if (_isEqual(values.creation_date, initialValues.creation_date)) {
      delete postData.creation_date;
    }
    if (_isEqual(values.author, initialValues.author)) {
      delete postData.author;
    }
    if (
      _isEqual(
        values.classify_disability_support,
        initialValues.classify_disability_support
      )
    ) {
      delete postData.classify_disability_support;
    }
    if (_isEqual(values.income_kind, initialValues.income_kind)) {
      delete postData.income_kind;
    }
    if (_isEqual(values.income_category, initialValues.income_category)) {
      delete postData.income_category;
    }
    if (
      _isEqual(
        values.disabled_welfare_service_recipient_number,
        initialValues.disabled_welfare_service_recipient_number
      )
    ) {
      delete postData.disabled_welfare_service_recipient_number;
    }
    if (
      _isEqual(
        values.consultation_support_recipient_number,
        initialValues.consultation_support_recipient_number
      )
    ) {
      delete postData.consultation_support_recipient_number;
    }
    if (
      _isEqual(
        values.regular_place_recipient_number,
        initialValues.regular_place_recipient_number
      )
    ) {
      delete postData.regular_place_recipient_number;
    }
    if (_isEqual(values.support_policy, initialValues.support_policy)) {
      delete postData.support_policy;
    }
    if (_isEqual(values.whole_situation, initialValues.whole_situation)) {
      delete postData.whole_situation;
    }
    if (_isEqual(values.staff_comment, initialValues.staff_comment)) {
      delete postData.staff_comment;
    }
    if (!postData.details || postData.details.length === 0) {
      delete postData.details;
    }
    if (_isEqual(values.guardian_name, initialValues.guardian_name)) {
      delete postData.guardian_name;
    }
    if (_isEqual(values.guardian_relation, initialValues.guardian_relation)) {
      delete postData.guardian_relation;
    }
  }

  return postData;
};
