import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import { ReportDailyState } from "@stores/pages/report/daily/types";
import Checkbox from "@components/atoms/Checkbox";
import { FacilityType } from "@constants/variables";
import { REWARD_TYPE_ITEMS } from "@constants/mgr/IAB/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    },
    dateSelect: {
      paddingBottom: 8
    }
  });

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedDate: Date;
  errorsDateList: ReportDailyState["errorsDateList"];
  shortTimeFlg: boolean;
  serviceType: string;
  rewardType: string;
  onChangeDate: (date: Date) => void;
  fetchLatestInoutErrors: () => Promise<void>;
  onChangeShortTimeSubtractionCheck: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportDailyHeader = (props: Props): JSX.Element => {
  return (
    <div className={props.classes.wrapper}>
      <div className={props.classes.dateSelect}>
        <DateSelectButtonsDaily
          defaultDate={props.selectedDate}
          min={props.minDate}
          max={props.maxDate}
          onClickSubmit={props.onChangeDate}
          highlightErrors={{
            errorsDateList: props.errorsDateList,
            fetchLatestInoutErrors: props.fetchLatestInoutErrors
          }}
        />
      </div>
      {props.serviceType === FacilityType.B &&
        props.rewardType === REWARD_TYPE_ITEMS[1].value && (
          <Checkbox
            label="短時間利用減算"
            value="physicalRestraintAbolitionCheck"
            checked={props.shortTimeFlg}
            onChange={props.onChangeShortTimeSubtractionCheck}
            inputStyle={{ marginRight: 8, padding: "8px" }}
          />
        )}
    </div>
  );
};

export default withStyles(styles)(InOutReportDailyHeader);
