import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SUPPORT_CUSTOM_RECORD_INPUT_TYPE } from "@constants/variables";

// 項目の新規追加・編集モーダル用
export type CustomRecordsValues = {
  id: number | null;
  name: string;
  input_type: string; //  MuiRadioButtonsのvalueがstring型固定のためstring型
  setting_type: number;
  custom_records_category_id?: number | null;
  custom_record_item_choices?: {
    key: number;
    id: number | null;
    custom_record_item_id: number | null;
    name: string;
    default_choice: number | null;
    default_choice_input_type: number | null;
    hidden: number;
    dirty: boolean;
    delete: boolean;
  }[];
  category_type: number;
};

// 項目の新規追加・編集モーダル用(サービス提供記録用)
export type CustomRecordsValuesForService = {
  id: number | null;
  name: string;
  input_type: string;
  setting_type: number;
  custom_records_category_id: number;
  custom_record_item_choices: {
    key: number;
    id: number | null;
    custom_record_item_id: number | null;
    name: string;
    default_choice: number | null;
    default_choice_input_type: number | null;
    hidden: number;
    dirty: boolean;
    delete: boolean;
  }[];
};

// 項目並べ替え用(サービス提供記録用)
export type CustomRecordItemValues = CustomRecordsWithCategoryState[0]["custom_record_items"];

export const initialValues = (
  category_type?: number,
  custom_records_category_id?: number | null,
  custom_record_item_choices?: {
    id: number;
    custom_record_item_id: number;
    name: string;
    default_choice: number | null;
    hidden: number;
    default_choice_input_type: number | null;
  }[],
  input_type?: number,
  setting_type?: number,
  id?: number | null,
  name?: string
): CustomRecordsValues => {
  const customRecordsItemChoices: CustomRecordsValues["custom_record_item_choices"] = custom_record_item_choices
    ? custom_record_item_choices.map((choice, index) => {
        return {
          ...choice,
          key: index,
          dirty: false,
          delete: false
        };
      })
    : [];
  return {
    id: id || null,
    name: name || "",
    input_type: `${input_type}`,
    setting_type: setting_type || 0,
    custom_records_category_id: custom_records_category_id || null,
    custom_record_item_choices: customRecordsItemChoices,
    category_type: category_type || 0
  };
};

export const initialValuesForService = (
  customRecordCategoryId: number,
  settingType: number,
  customRecordItem?: CustomRecordsWithCategoryState[0]["custom_record_items"][0],
  customRecordItemId?: number | null
): CustomRecordsValuesForService => {
  const customRecordsItemChoices: CustomRecordsValuesForService["custom_record_item_choices"] = customRecordItem
    ? customRecordItem.custom_record_item_choices
        .filter((choice) => {
          // チェックボックスとテキストが組み合わさった形式の場合、チェックボックスのみ編集可能
          if (
            customRecordItem.input_type ===
            SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text
          ) {
            return (
              choice.default_choice_input_type ===
              SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox
            );
          }
          return true;
        })
        .map((choice, index) => {
          return {
            key: index,
            id: choice.id,
            custom_record_item_id: choice.custom_record_item_id,
            name: choice.name,
            default_choice: choice.default_choice,
            default_choice_input_type: choice.default_choice_input_type,
            hidden: choice.hidden,
            dirty: false,
            delete: false
          };
        })
    : [];
  const choicesTotal = customRecordsItemChoices
    ? customRecordsItemChoices.filter((choice) => choice.hidden === 0).length
    : 0;
  const maxRowLength = 10;

  // 選択肢の表示合計数が上限より少ない場合に新規列追加
  if (choicesTotal < maxRowLength) {
    customRecordsItemChoices.push({
      key: customRecordsItemChoices ? customRecordsItemChoices.length : 0,
      id: null,
      custom_record_item_id: customRecordItem
        ? customRecordItem.id
        : customRecordItemId || null,
      name: "",
      default_choice: null,
      default_choice_input_type: null,
      hidden: 0,
      dirty: false,
      delete: false
    });
  }

  return {
    id: customRecordItem ? customRecordItem.id : null,
    name: customRecordItem ? customRecordItem.name : "",
    input_type: customRecordItem ? String(customRecordItem.input_type) : "1",
    setting_type: settingType,
    custom_records_category_id: customRecordCategoryId,
    custom_record_item_choices: customRecordsItemChoices
  };
};

export const tableBodyInitialValues = (
  state?: CustomRecordsWithCategoryState
): CustomRecordsWithCategoryState => {
  return state || [];
};

export const tableBodyInitialValuesForService = (
  state?: CustomRecordItemValues
): CustomRecordItemValues => {
  if (state) {
    return state.map((item) => {
      return {
        id: item.id,
        default_item: item.default_item,
        name: item.name,
        input_type: item.input_type,
        setting_type: item.setting_type,
        order: item.order,
        visibility: item.visibility,
        custom_records_category_id: item.custom_records_category_id,
        custom_record_item_choices: item.custom_record_item_choices,
        allow_edit_name: item.allow_edit_name,
        allow_edit_choiced: item.allow_edit_choiced,
        allow_change_order: item.allow_change_order,
        allow_change_visibility: item.allow_change_visibility,
        setting_page_visibility: item.setting_page_visibility
      };
    });
  }

  return [];
};
