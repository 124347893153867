import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import LineBreak from "@components/atoms/LineBreak";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  CUSTOM_RECORD_DEFAULT_CHOICE_UNIT
} from "@constants/variables";
import {
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  SUPPLY_PICKUP_SERVICE_LIST
} from "@constants/mgr/TANKINYUSHO/variables";

// utils
import { dateTodayInFormat } from "@utils/date";

import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";

const TABLE_HEAD_HEIGHT = 25;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: 24,
      letterSpacing: 1.2,
      textAlign: "center",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginTop: 16,
      marginBottom: 16
    },
    summary: {
      marginBottom: 16
    },
    summaryContent: {
      fontSize: 10,
      marginRight: 10,
      color: "rgba(0, 0, 0, 0.87)"
    },
    summaryInfo: { fontSize: 0 },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 90
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: "3px 2px"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      },
      "& .dateText": {
        "& + .dateText": {
          marginTop: "2px"
        }
      }
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  facilityUsers: GetFacilityUsersResponse["data"];
};

type OwnProps = {
  displayColumns: string[];
  date: string;
  supportsRecord: DailyOperationsAndSupportsState["support"];
  summary: DailyOperationsAndSupportsState["summary"];
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  customRecords: CustomRecordsState;
  staffCustomRecordID: number;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const getSupportContents = (
  customRecords: CustomRecordsState,
  displayColumns: string[],
  support_record_input: DailyOperationsAndSupportsState["support"][0]["support_record_input"],
  classes: Record<string, string>
): JSX.Element[] => {
  const supportContents: JSX.Element[] = [];

  [...customRecords].forEach((record) => {
    switch (record.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const textContent = support_record_input.find(
          (item) => item.custom_record_item_id === record.id
        );

        const textInputData =
          textContent && textContent.input_data ? textContent.input_data : null;

        // 「職員考察」項目
        if (
          record.default_item ===
            SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment &&
          displayColumns.includes("staff_comment") &&
          textInputData
        ) {
          supportContents.push(
            <p key={supportContents.length} className={classes.contents}>
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }

        if (
          record.default_item !==
            SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment &&
          textInputData
        ) {
          supportContents.push(
            <p key={supportContents.length} className={classes.contents}>
              {`[${record.name}]`} <LineBreak text={textInputData} />
            </p>
          );
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItemsId = support_record_input
          .filter(
            (item) =>
              item.custom_record_item_id === record.id && item.checked === 1
          )
          .map((filteredItem) => filteredItem.choiced_item_id);

        const checkedChoiceItems = record.choices
          .filter((item) => checkedItemsId.includes(item.id))
          .map((filteredItem) => filteredItem.name);

        const checkedName = checkedChoiceItems
          ? checkedChoiceItems.join("、")
          : "";

        if (checkedItemsId.length !== 0) {
          supportContents.push(
            <p
              key={supportContents.length}
              className={classes.contents}
              style={{ color: "#000" }}
            >
              {`[${record.name}] ${checkedName}`}
            </p>
          );
        }
        break;
      }
      // 複数テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
        const inputDataItems = support_record_input.filter(
          (item) => item.custom_record_item_id === record.id && item.input_data
        );

        // カスタム項目の小項目設定データを取得
        const getChoicedData = (
          defaultChoiceNum: number
        ): CustomRecordsState[number]["choices"][number] | undefined => {
          return record.choices.find(
            (recordItem) => recordItem.default_choice === defaultChoiceNum
          );
        };

        // 小項目入力データを取得
        const getChoicedInputData = (
          choicedData: CustomRecordsState[number]["choices"][number] | undefined
        ):
          | DailyOperationsAndSupportsState["support"][0]["support_record_input"][number]
          | undefined => {
          return (
            choicedData &&
            inputDataItems.find(
              (inputDataItem) =>
                inputDataItem.choiced_item_id === choicedData.id
            )
          );
        };

        // 項目が受入時間・終了時間の場合
        if (
          record.default_item ===
          SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.start_end_time
        ) {
          [1, 2].forEach((defaultChoiceNum) => {
            const choicedData = getChoicedData(defaultChoiceNum);
            const choicedInputData = getChoicedInputData(choicedData);

            if (
              choicedData &&
              choicedInputData &&
              choicedInputData.input_data
            ) {
              supportContents.push(
                <p
                  key={`input_type_forth_${choicedData.id}`}
                  className={classes.contents}
                >
                  {`[${choicedData.name}] `}
                  <LineBreak text={choicedInputData.input_data} />
                </p>
              );
            }
          });
        } else {
          // 項目が受入時間・終了時間以外の場合（バイタルの場合）
          const multiTextContents: JSX.Element[] = [];

          // 小項目の途中で改行されないようspanタグを使用
          [1, 2, 3, 4, 5].forEach((defaultChoiceNum) => {
            const choicedData = getChoicedData(defaultChoiceNum);
            const choicedInputData = getChoicedInputData(choicedData);

            if (choicedData && choicedInputData) {
              multiTextContents.push(
                <span key={choicedData.id} style={{ display: "inline-block" }}>
                  {choicedData.name} : {choicedInputData.input_data}
                  {CUSTOM_RECORD_DEFAULT_CHOICE_UNIT[`${defaultChoiceNum}`]}
                  &emsp;
                </span>
              );
            }
          });

          // 血圧の最低と最高を並び替え
          const sortedMultiTextContents = multiTextContents.slice();
          const index2 = sortedMultiTextContents.findIndex(
            (multiTextContent) =>
              multiTextContent.props.children[0] === "血圧（最低）"
          );
          const index3 = sortedMultiTextContents.findIndex(
            (multiTextContent) =>
              multiTextContent.props.children[0] === "血圧（最高）"
          );

          if (index2 !== -1 && index3 !== -1) {
            const lowerChoice = sortedMultiTextContents[index2];
            sortedMultiTextContents[index2] = sortedMultiTextContents[index3];
            sortedMultiTextContents[index3] = lowerChoice;
          }

          if (inputDataItems.length !== 0) {
            supportContents.push(
              <p key={supportContents.length} className={classes.contents}>
                {`[${record.name}]`} <span>{sortedMultiTextContents}</span>
              </p>
            );
          }
        }
        break;
      }
      default:
        break;
    }
  });

  return supportContents;
};

const DailyPrintSupportTANKINYUSHOCore = (props: Props): JSX.Element | null => {
  const {
    classes,
    displayColumns,
    summary,
    supportsRecord,
    tableRowStartEndIndexInSheet,
    customRecords,
    staffCustomRecordID,
    date,
    facilityUsers
  } = props;
  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;
  if (!summary || !summary.length) {
    return null;
  }

  const isMealItem = summary[0].counts.meal.choices.some(
    (choice) => choice.count !== 0
  );

  return (
    <div className={classes.page}>
      <div>
        <header>
          <h1 className={classes.title}>
            {displayColumns.includes("support_record")
              ? "支援記録"
              : "サービス提供記録"}
          </h1>
        </header>
        <div>
          <div className={classes.subTitle}>
            <span>{dateTodayInFormat(date, true)}</span>
          </div>
          <div className={classes.summary}>
            <div className={classes.summaryInfo}>
              <span
                className={classes.summaryContent}
              >{`サービス提供実施：${summary[0].counts.status_type.implement}人`}</span>
            </div>
            {isMealItem && (
              <div className={classes.summaryInfo}>
                {summary[0].counts.meal.choices
                  .filter((item) => item.count > 0)
                  .map((mealItem) => {
                    return (
                      <span
                        className={classes.summaryContent}
                        key={mealItem.id}
                      >{`${mealItem.name}：${mealItem.count}人`}</span>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
        {supportsRecord.length > 0 && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label msize">利用者</td>
                  <td className="label">支援内容</td>
                  <td className="label ssize">記録者</td>
                  <td className="label sssize">捺印</td>
                </tr>
                {supportsRecord.map(
                  (
                    {
                      user_in_facility_id,
                      support_record_input,
                      support_records_id,
                      pickup
                    },
                    index
                  ) => {
                    if (index < startIndex || index > endIndex)
                      return undefined;
                    // 利用者
                    const user = facilityUsers.find(
                      (item) => item.uif_id === user_in_facility_id
                    );
                    const userName = user ? user.displayName : "";
                    // 記録者
                    const staffRecord = support_record_input
                      .filter(
                        (item) =>
                          item.custom_record_item_id === staffCustomRecordID &&
                          item.checked
                      )
                      .map((item) => item.choiced_staff_name_snapshot);

                    const staffName = staffRecord ? staffRecord.join("、") : "";

                    const supportContents = getSupportContents(
                      customRecords,
                      displayColumns,
                      support_record_input,
                      classes
                    );

                    if (!supportContents.length && !staffName) {
                      return null;
                    }

                    return (
                      <tr key={support_records_id}>
                        <td className="label topCenterAlign">
                          <div className="dateText">{userName}</div>
                          <div className="dateText">
                            {!!pickup &&
                              `（送迎：${SUPPLY_PICKUP_SERVICE_LIST[pickup].label}）`}
                          </div>
                        </td>
                        <td className="topAlign">{supportContents}</td>
                        <td className="topCenterAlign">{staffName}</td>
                        <td className="label" />
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityUsers = state.TANKINYUSHO.userInFacility.users;
  return {
    facilityUsers
  };
};

export const DailyPrintSupportTANKINYUSHO = connect(mapStateToProps)(
  withStyles(styles)(DailyPrintSupportTANKINYUSHOCore)
);
