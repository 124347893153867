import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles,
  Theme
} from "@material-ui/core/styles";
// ui
import { FormikSelectDayOnly } from "@components/molecules/FormikSelectDayOnly";
import MuiTextField from "@components/molecules/MuiTextField";
import { dateToLocalisedString } from "@utils/date";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    paper: {
      margin: spacing.unit * 2,
      padding: "32px 32px 0px 32px"
    }
  });

type Props = {
  targetDate: string;
  isEdit: boolean;
} & WithStyles<typeof styles>;

const ServiceDeliveryBasicFieldDateSelectCore = (props: Props): JSX.Element => {
  const targetDateText = dateToLocalisedString(
    props.targetDate,
    "YYYY年M月D日"
  );

  return (
    <>
      {props.isEdit ? (
        <MuiTextField
          value={targetDateText}
          label="サービス提供日"
          disabled
          disabledStyle
          style={{ width: 256 }}
        />
      ) : (
        <FormikSelectDayOnly name="targetDate" label="サービス提供日" />
      )}
    </>
  );
};

export const ServiceDeliveryBasicFieldDateSelect = withStyles(styles)(
  ServiceDeliveryBasicFieldDateSelectCore
);
