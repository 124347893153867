import React, { useEffect, useState } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { connect } from "react-redux";

// ui
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { CustomRecordsDialogSubmit } from "@components/organisms/record/settingWithCategory/dialolg/CustomRecordsDialogSubmit";
import { CustomRecordsDialogContent } from "@components/organisms/record/settingWithCategory/dialolg/CutomRecordsDialogContent";

// formik
import { Formik, FormikActions } from "formik";
import { CustomRecordsValuesForService } from "@initialize/record/customRecordWithCategory/initialValues";

const styles = (): StyleRules =>
  createStyles({
    modal: {
      maxHeight: "80%",
      width: 776,
      maxWidth: 776
    },
    form: {
      display: "flex",
      overflowY: "auto",
      flexDirection: "column",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    modalHeaderArea: {
      padding: "16px 32px 18px",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      fontSize: "20px",
      color: "#37474f",
      lineHeight: "24px",
      backgroundColor: "#f5f5f5"
    },
    modalHeader: {
      display: "flex"
    },
    modalContents: {
      padding: "24px 0 48px 0",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    action: {
      backgroundColor: "#ffffff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      margin: 0,
      paddingRight: 4,
      paddingBottom: 4
    }
  });

type OwnProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  formValues: CustomRecordsValuesForService;
  fetchCustomRecords: () => Promise<void>;
};

type DispatchProps = {
  postCustomRecords: (
    formValue: CustomRecordsValuesForService
  ) => Promise<void>;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

const CustomRecordsDialogCore = (props: Props): JSX.Element => {
  const {
    classes,
    isModalOpen,
    closeModal,
    formValues,
    postCustomRecords,
    fetchCustomRecords
  } = props;

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  useEffect(() => {
    setSubmitButtonDisabled(formValues.name === "");
  }, []);

  const onSubmit = async (
    values: CustomRecordsValuesForService,
    actions: FormikActions<CustomRecordsValuesForService>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postCustomRecords(values)
      .then(() => {
        fetchCustomRecords();
      })
      .finally(() => {
        closeModal();
        actions.setSubmitting(false);
      });
  };

  return (
    <Dialog fullWidth open={isModalOpen} classes={{ paper: classes.modal }}>
      <Formik initialValues={formValues} onSubmit={onSubmit}>
        {(formikProps): JSX.Element => (
          <div className={classes.form}>
            <DialogTitle
              classes={{ root: classes.modalHeaderArea }}
              disableTypography
            >
              <div className={classes.modalHeader}>
                {formValues.id ? "項目の編集" : "新規項目の作成"}
              </div>
            </DialogTitle>
            <DialogContent className={classes.modalContents}>
              <CustomRecordsDialogContent
                formValues={formValues}
                formikProps={formikProps}
                setSubmitButtonDisabled={setSubmitButtonDisabled}
              />
            </DialogContent>
            <DialogActions className={classes.action}>
              <CustomRecordsDialogSubmit
                formikProps={formikProps}
                onCancel={closeModal}
                disabled={submitButtonDisabled}
              />
            </DialogActions>
          </div>
        )}
      </Formik>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );

  return {
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsForService(
        formValue
      );
    }
  };
};

export const CustomRecordsDialog = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CustomRecordsDialogCore));
