import { RequestParam } from "@api/requests/basicInfo/postBasicInfo";
import { BasicInfoValues } from "@initialize/mgr/KEIKAKUSODAN/record/basicInfo/initialValues";
import castStringToNumber from "@utils/dataNormalizer/castStringToNumber";
import { selectDateValueToDate } from "@utils/date";
import { format } from "date-fns";
import { isEmpty } from "lodash-es";

/**
 * timeがあればyyyy-mm-dd HH:mm:ss形式で返す
 */
const createDateString = (time: string): string => {
  return time !== "" ? `${time}:00` : "";
};

export const normalizePostBasicInfoDataToAPI = (
  basicInfo: BasicInfoValues,
  isCopy: boolean,
  isNew: boolean
): RequestParam => {
  const request: RequestParam = {
    basic_info: {
      basic_info_id: basicInfo.basic_info_id,
      creation_date: format(
        selectDateValueToDate(basicInfo.creation_date),
        "YYYY-MM-DD"
      ),
      author: Number(basicInfo.author) || null,
      support_progress: isEmpty(basicInfo.support_progress)
        ? null
        : basicInfo.support_progress,
      date_birth: isEmpty(selectDateValueToDate(basicInfo.date_birth))
        ? null
        : format(selectDateValueToDate(basicInfo.date_birth), "YYYY-MM-DD"),
      age: isEmpty(basicInfo.age) ? null : Number(basicInfo.age),
      postal_code: isEmpty(basicInfo.postal_code)
        ? null
        : basicInfo.postal_code,
      city_id:
        basicInfo.city_id !== "NOT_SELECTED" && basicInfo.city_id !== ""
          ? castStringToNumber(basicInfo.city_id)
          : null,
      address: isEmpty(basicInfo.address) ? null : basicInfo.address,
      residence: isEmpty(basicInfo.residence)
        ? null
        : Number(basicInfo.residence),
      residence_other: isEmpty(basicInfo.residence_other)
        ? null
        : basicInfo.residence_other,
      tel: isEmpty(basicInfo.tel) ? null : basicInfo.tel,
      fax: isEmpty(basicInfo.fax) ? null : basicInfo.fax,
      disability_name: isEmpty(basicInfo.disability_name)
        ? null
        : basicInfo.disability_name,
      classify_disability_support: isEmpty(
        basicInfo.classify_disability_support
      )
        ? null
        : Number(basicInfo.classify_disability_support),
      gender: isEmpty(basicInfo.gender) ? null : basicInfo.gender,
      family_structure: isEmpty(basicInfo.family_structure)
        ? null
        : basicInfo.family_structure,
      social_relationship: isEmpty(basicInfo.social_relationship)
        ? null
        : basicInfo.social_relationship,
      life_history: isEmpty(basicInfo.life_history)
        ? null
        : basicInfo.life_history,
      medical_situation: isEmpty(basicInfo.medical_situation)
        ? null
        : basicInfo.medical_situation,
      self_complaint: isEmpty(basicInfo.self_complaint)
        ? null
        : basicInfo.self_complaint,
      family_complaint: isEmpty(basicInfo.family_complaint)
        ? null
        : basicInfo.family_complaint,
      public_support: {
        basic_info_details_id:
          !isCopy && basicInfo.public_support.basic_info_details_id
            ? basicInfo.public_support.basic_info_details_id
            : null,
        name: isEmpty(basicInfo.public_support.name)
          ? null
          : basicInfo.public_support.name,
        offer_name: isEmpty(basicInfo.public_support.offer_name)
          ? null
          : basicInfo.public_support.offer_name,
        support_contents: isEmpty(basicInfo.public_support.support_contents)
          ? null
          : basicInfo.public_support.support_contents,
        frequency: isEmpty(basicInfo.public_support.frequency)
          ? null
          : basicInfo.public_support.frequency,
        memo: isEmpty(basicInfo.public_support.memo)
          ? null
          : basicInfo.public_support.memo
      },
      other_support: {
        basic_info_details_id:
          !isCopy && basicInfo.other_support.basic_info_details_id
            ? basicInfo.other_support.basic_info_details_id
            : null,
        name: isEmpty(basicInfo.other_support.name)
          ? null
          : basicInfo.other_support.name,
        offer_name: isEmpty(basicInfo.other_support.offer_name)
          ? null
          : basicInfo.other_support.offer_name,
        support_contents: isEmpty(basicInfo.other_support.support_contents)
          ? null
          : basicInfo.other_support.support_contents,
        frequency: isEmpty(basicInfo.other_support.frequency)
          ? null
          : basicInfo.other_support.frequency,
        memo: isEmpty(basicInfo.other_support.memo)
          ? null
          : basicInfo.other_support.memo
      },
      schedule: basicInfo.schedule.map((x) => ({
        basic_info_schedules_id: isCopy ? null : x.basic_info_schedules_id,
        day_of_week: x.day_of_week,
        start_time: isEmpty(x.start_time)
          ? null
          : createDateString(x.start_time),
        end_time: isEmpty(x.end_time) ? null : createDateString(x.end_time),
        next_day_flg: Number(x.next_day_flg),
        content: isEmpty(x.content) ? null : x.content,
        is_delete: x.is_delete ? 1 : 0
      })),
      main_activity: isEmpty(basicInfo.main_activity)
        ? null
        : basicInfo.main_activity,
      non_weekly_service: isEmpty(basicInfo.non_weekly_service)
        ? null
        : basicInfo.non_weekly_service,
      ...((isCopy || isNew) && {
        type_consultation: basicInfo.type_consultation
          ? basicInfo.type_consultation
          : null
      })
    }
  };
  return request;
};
