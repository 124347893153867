import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  custom_records_category_order: {
    id: number;
    name: string;
    order: number;
  }[];
};

/**
 * カスタム項目の並び順を変更する
 */
export const postCustomRecordsCategoryOrder = async (
  param: RequestParam
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_records/category/order`;
  return request.post(url, param);
};

export default postCustomRecordsCategoryOrder;
