import React from "react";

// UI
import {
  createStyles,
  TableBody,
  TableRow,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Table from "@components/molecules/Table";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { SupportProcedureTableRows } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureTableRows";

// store
import { connect } from "react-redux";
import {
  SelectedSupportProcedureIds,
  SupportProcedureState
} from "@stores/domain/mgr/KODOENGO/supportProcedure/types";

import { groupBy, orderBy } from "lodash-es";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableContainer: {
      padding: "32px 32px 8px"
    },
    tableRowBase: {
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    dateCell: {
      minWidth: 136,
      width: 136,
      paddingLeft: 16
    },
    createdCell: {
      minWidth: 52,
      width: 52
    },
    numberOfPractitionerCell: {
      minWidth: 56,
      width: 56
    },
    timeCell: {
      minWidth: 164,
      width: 164
    },
    titleCell: {
      minWidth: 462,
      width: 462
    },
    iconButtonCell: {
      minWidth: 48,
      width: 48,
      "&:last-child": {
        paddingRight: 0
      },
      textAlign: "center"
    },
    dotBorderTop: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left top",
      // 背景色を指定するセルの場合、ボーダーが消えるため個別で再設定
      "& > :first-child": {
        backgroundColor: "#ffffff",
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
        backgroundSize: "6px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left top"
      }
    },
    solidBorderBottom: {
      borderBottom: "1px solid #e0e0e0"
    },
    noRecord: {
      paddingTop: "80px",
      paddingBottom: "48px",
      textAlign: "center",
      lineHeight: "28px"
    }
  });

type OwnProps = {
  handleOnClickDelete: (target: string) => void;
  handleOnClickEdit: (ids: SelectedSupportProcedureIds) => void;
  handleOnClickCopy: (ids: SelectedSupportProcedureIds) => void;
  handleOnClickDateSetting: (
    ids: SelectedSupportProcedureIds,
    originId: string
  ) => void;
  records: SupportProcedureState["supportProcedureList"]["support_procedure_forms"];
};
type Props = OwnProps & WithStyles<typeof styles>;
const SupportProcedureTableCore = (props: Props): JSX.Element => {
  const {
    classes,
    handleOnClickEdit,
    handleOnClickCopy,
    handleOnClickDateSetting,
    handleOnClickDelete,
    records
  } = props;
  const headData = [
    { label: "日付", className: `${classes.dateCell}` },
    {
      label: (
        <>
          作成
          <br />
          済み
        </>
      ),
      className: `${classes.createdCell}`
    },
    { label: "人数", className: `${classes.numberOfPractitionerCell}` },
    { label: "時間", className: `${classes.timeCell}` },
    { label: "タイトル", className: `${classes.titleCell}` },
    { label: "削除", className: `${classes.iconButtonCell}` },
    { label: "コピー", className: `${classes.iconButtonCell}` },
    { label: "編集", className: `${classes.iconButtonCell}` }
  ];

  // 日付が設定されていないデータはテーブル上で1列として扱うためGroupByでまとめない
  const notNullRows = records.filter((item) => item.target_date !== null);
  const nullRows = records.filter((item) => item.target_date === null);
  const sortedRows = orderBy(notNullRows, "target_date", "asc");
  const groupedRows = groupBy(sortedRows, "target_date");
  const supportProcedureRows = Object.values(groupedRows).concat(
    nullRows.map((item) => [item])
  );

  return (
    <div className={classes.tableContainer}>
      <Table key="mui-table-head">
        <KnowbeTableHead
          uniqueKey="supportProcedureTableHead"
          height={48}
          items={headData}
        />
      </Table>
      {records.length > 0 ? (
        <Table key="mui-table">
          <TableBody key="mui-table-body" className={classes.solidBorderBottom}>
            {supportProcedureRows.map((rows, index) => {
              const rowSpan = rows.length;
              return rows.map((row, subIndex) => {
                const tableRowIndex = `${index}-${subIndex}`;
                return (
                  <TableRow
                    key={`mui-table-row-${tableRowIndex}`}
                    className={`${
                      index !== 0 && subIndex === 0 ? classes.dotBorderTop : ""
                    } ${classes.tableRowBase}`}
                  >
                    <SupportProcedureTableRows
                      key={`table-row-${tableRowIndex}`}
                      index={index}
                      subIndex={subIndex}
                      rowSpan={rowSpan}
                      rowData={row}
                      handleOnClickDelete={handleOnClickDelete}
                      handleOnClickEdit={handleOnClickEdit}
                      handleOnClickCopy={handleOnClickCopy}
                      handleOnClickDateSetting={handleOnClickDateSetting}
                    />
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      ) : (
        <div className={classes.noRecord}>
          計画または支援手順書がありません。
          <br />
          利用実績画面にて計画を入力後にご利用いただくか、
          <br />
          「新規作成」ボタンから支援手順書を作成してください。
        </div>
      )}
    </div>
  );
};

export const SupportProcedureTable = connect(null)(
  withStyles(styles)(SupportProcedureTableCore)
);
