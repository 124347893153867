import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Formik, FormikActions } from "formik";
import deepEqual from "fast-deep-equal";
import { format } from "date-fns";

// components
import AdminTemplate from "@components/templates/AdminTemplate";
import { SupportProcedureForm } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureForm";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import ConfirmDialog from "@components/atoms/ConfirmDialog";

// stores
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import dispatches from "@stores/dispatches";

// utils
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import {
  checkErrorIsValidation,
  createPostValidationError
} from "@utils/domain/mgr/KODOENGO/supportProcedureErrorValidation";
import { FieldItem } from "@interfaces/ui/form";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { validate } from "@initialize/mgr/KODOENGO/record/supportProcedure/validation";
import {
  initialFormValues,
  initialValuesEdit,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { getUrlParams } from "@utils/url";
import { toEffectiveObject } from "@utils/object";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { getDayOfTheWeek } from "@utils/date";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    actionArea: {
      margin: "0 16px 20px",
      display: "flex",
      justifyContent: "space-between"
    },
    updateArea: {
      margin: "0 16px 16px"
    },
    deleteArea: {
      margin: "32px 16px 0"
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  supportProcedureId: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UsersInFacilityState["user"];
  staff: StaffState;
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  needsStopHistory: boolean;
};

type DispatchProps = {
  fetchStaff: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchSupportProcedureDetail: (
    serviceDeliveryRecordsId?: string,
    inoutResultsId?: string,
    supportProcedureFormsId?: string
  ) => void;
  postSupportProcedureDetailForm: (
    formValues: SupportProcedureDetailFormValues,
    staff: StaffState,
    apiResponse: GetSupportProcedureDetailResponse["data"]
  ) => Promise<void>;
  deleteSupportProcedureRecord: (
    apiResponse: GetSupportProcedureDetailResponse["data"]
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type MergeProps = {
  authorName: string;
  authorizerRole: string;
  staffOptionsAddSnapShot: FieldItem[];
  staffWithSnap: StaffState;
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

type SupportProcedureDetailQuery = {
  serviceDeliveryRecordsId?: string;
  inoutResultsId?: string;
  supportProcedureFormsId?: string;
  originSupportProcedureFormsId?: string;
};

const SupportProcedureRecordCore = (props: Props): JSX.Element => {
  const { uifId } = props.match.params;
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = React.useState(false);

  const pageTitle = React.useMemo(() => {
    let str = "支援手順書 兼 記録用紙";
    // ユーザー名設定
    if (props.userName) {
      // eslint-disable-next-line no-irregular-whitespace
      str += `　${props.userName}`;
    }
    // 日時設定
    if (props.supportProcedureDetail.target_date) {
      const targetDate = new Date(
        fixDateAndTimeFormat(props.supportProcedureDetail.target_date)
      );
      // eslint-disable-next-line no-irregular-whitespace
      str += `　${format(targetDate, "YYYY年M月D日")}(${getDayOfTheWeek(
        targetDate
      )})`;
    }
    return str;
  }, [props.userName, props.supportProcedureDetail.target_date]);

  const [targetObject, setTargetObject] = React.useState<TargetObject[]>([]);

  const [formValues, setFormValues] = React.useState(initialFormValues);
  const rest = getUrlParams(
    props.location.search
  ) as SupportProcedureDetailQuery;
  React.useEffect(() => {
    props.fetchOneUser(uifId);
    props.fetchStaff();
  }, []);

  React.useEffect(() => {
    setFormValues(
      initialValuesEdit(
        props.supportProcedureDetail,
        props.staffOptionsAddSnapShot
      )
    );
    // targetObjectに分かれている値を設定する
    setTargetObject([
      {
        targetDate: props.supportProcedureDetail.target_date,
        serviceDeliveryRecordsId: null,
        inoutResultsId: null,
        supportProcedureFormsId: null
      }
    ]);
  }, [props.supportProcedureDetail]);

  const fetchSupportProcedureForms = (): void => {
    const {
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    } = rest;
    props.fetchSupportProcedureDetail(
      serviceDeliveryRecordsId,
      inoutResultsId,
      supportProcedureFormsId
    );
  };

  React.useEffect(() => {
    fetchSupportProcedureForms();
  }, [props.location.search]);

  const confirmDiscardFormChanges = (
    nextValues: SupportProcedureDetailFormValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  const validateForm = (
    value: SupportProcedureDetailFormValues
  ): void | object => {
    const validationResult = validate(value);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(value);
    }
    return error;
  };

  const returnListPage = (): void => {
    const isDaily = props.location.pathname.includes("daily");
    const datePath = props.supportProcedureDetail.target_date
      ? format(
          props.supportProcedureDetail.target_date,
          isDaily ? "YYYYMMDD" : "YYYYMM"
        )
      : "";
    const returnUrl = isDaily
      ? `/record/service_delivery/daily/list/${datePath}`
      : `/record/service_delivery/monthly/list/${uifId}/${datePath}`;
    props.history.push(returnUrl);
  };

  const onSubmit = async (
    values: SupportProcedureDetailFormValues,
    actions: FormikActions<SupportProcedureDetailFormValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postSupportProcedureDetailForm(
        values,
        props.staff,
        props.supportProcedureDetail
      )
      .then(() => {
        fetchSupportProcedureForms();
        props.stopHistory(false);
      })
      .catch((e) => {
        if (checkErrorIsValidation(e)) {
          const error = createPostValidationError(e);
          actions.setErrors(error);
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  const onClickReturnList = (): void => {
    returnListPage();
  };

  const onClickDeleteButton = (): void => {
    setIsOpenDeleteDialog(true);
  };

  const handleClickDeleteButtonInDialog = (): void => {
    if (!props.supportProcedureDetail.support_procedure_forms_id) {
      setIsOpenDeleteDialog(false);
      return;
    }
    props
      .deleteSupportProcedureRecord(props.supportProcedureDetail)
      .then(() => {
        props.stopHistory(false);
        setIsOpenDeleteDialog(false);
        returnListPage();
      });
  };

  return (
    <AdminTemplate pageName={pageTitle}>
      <div className={props.classes.wrapper}>
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validateForm}
          enableReinitialize
        >
          {(formikProps): JSX.Element => (
            <>
              <div className={props.classes.actionArea}>
                <KnowbeButton
                  kind="outline"
                  onClick={onClickReturnList}
                  minWidth={120}
                >
                  一覧に戻る
                </KnowbeButton>
                <FormikSubmitButton
                  key="submit-button"
                  buttonName="保存する"
                  minWidth={120}
                  formikProps={formikProps}
                />
              </div>
              <div className={props.classes.updateArea}>
                <CreateAndUpdateDate
                  createdAt={formikProps.values.createdAt}
                  updatedAt={formikProps.values.updatedAt}
                />
              </div>
              <SupportProcedureForm
                supportProcedureDetail={props.supportProcedureDetail}
                staffOptions={props.staffOptionsAddSnapShot}
                formikProps={formikProps}
                targetObject={targetObject}
                staff={props.staff}
                isRecord
              />
            </>
          )}
        </Formik>
        {props.supportProcedureDetail.procedure_status_flg === 2 && (
          <div className={props.classes.deleteArea}>
            <DeleteButton text="記録を削除する" onClick={onClickDeleteButton} />
          </div>
        )}
      </div>
      <ConfirmDialog
        title="該当する支援手順書を削除します"
        message={
          <>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </>
        }
        isOpen={isOpenDeleteDialog}
        onDelete={handleClickDeleteButtonInDialog}
        onCancel={(): void => setIsOpenDeleteDialog(false)}
        dialogWidth={600}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.KODOENGO.userInFacility.user,
  staff: state.staff,
  supportProcedureDetail:
    state.KODOENGO.supportProcedure.supportProcedureDetail,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  const uiDispatcher = dispatches.uiDispatch(dispatch);

  return {
    fetchStaff: KODOENGO.staffDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      KODOENGO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportProcedureDetail: (
      serviceDeliveryRecordsId?: string,
      inoutResultsId?: string,
      supportProcedureFormsId?: string
    ): void => {
      KODOENGO.supportProcedureDispatcher(dispatch).fetchSupportProcedureDetail(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      );
    },
    postSupportProcedureDetailForm: (
      formValues: SupportProcedureDetailFormValues,
      staff: StaffState,
      apiResponse: GetSupportProcedureDetailResponse["data"]
    ): Promise<void> => {
      return KODOENGO.supportProcedureDispatcher(
        dispatch
      ).postSupportProcedureDetailForm(formValues, staff, apiResponse);
    },
    deleteSupportProcedureRecord: (
      apiResponse: GetSupportProcedureDetailResponse["data"]
    ): Promise<void> => {
      return KODOENGO.supportProcedureDispatcher(
        dispatch
      ).postSupportProcedureDetailFormRecordDelete(apiResponse);
    },
    stopHistory: uiDispatcher.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, user } = stateProps;

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  // 作成者名
  const authorName = "";
  // 承認者役職
  const authorizerRole = "";

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  const staffOptionsAddSnapShot = staffOptions;

  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptionsAddSnapShot.length > staff.staffItems.length) {
    const snapOption = staffOptionsAddSnapShot.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorName,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  return {
    authorName,
    authorizerRole,
    staffOptionsAddSnapShot,
    staffWithSnap,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportProcedureRecordForm = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SupportProcedureRecordCore)
);
