import React, {
  useEffect,
  Dispatch as reactDispatch,
  SetStateAction
} from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { HiddenChoiceState } from "@stores/domain/customRecords/types";
// ui
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import FormikTextField from "@components/molecules/FormikTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
// formik
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import {
  initialValuesForService,
  CustomRecordsValuesForService
} from "@initialize/record/customRecordWithCategory/initialValues";
// variables
import {
  CUSTOM_RECORD_TARGET_TYPE,
  ENCIRCLED_NUMBER,
  CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    modalChoicesContents: {
      marginTop: "32px",
      padding: "24px 32px 0 32px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)"
    },
    modalChoicesContentsText: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      margin: "4px 0 0"
    },
    ChoiceWrap: {
      display: "flex",
      alignItems: "end"
    },
    choiceIndex: {
      color: "#666",
      fontSize: "14px",
      lineHeight: "32px",
      marginRight: "22px"
    },
    deleteIcon: {
      minWidth: 24,
      padding: 0,
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    disabledIcon: {
      color: "rgba(0, 0, 0, 0.12)",
      pointerEvents: "none"
    }
  });

type OwnProps = {
  formValues: CustomRecordsValuesForService;
  formikProps: FormikProps<CustomRecordsValuesForService>;
  setSubmitButtonDisabled: reactDispatch<SetStateAction<boolean>>;
  IsSubmitButtonDisabled: (
    name: string,
    input_type: string,
    choicesArray: CustomRecordsValuesForService["custom_record_item_choices"]
  ) => boolean;
};

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  postHiddenChoice: (params: HiddenChoiceState) => Promise<void>;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

const CustomRecordsDialogChoicesContentCore = ({
  classes,
  formValues,
  formikProps,
  setSubmitButtonDisabled,
  IsSubmitButtonDisabled,
  fetchCustomRecords,
  postHiddenChoice
}: Props): JSX.Element => {
  // 選択肢削除アイコンの状態制御
  const [isDeleteIconDisabled, setDeleteIconDisabled] = React.useState(false);

  // 選択肢に変更があるか確認
  const hasChangesInChoices = (
    nextValues: CustomRecordsValuesForService["custom_record_item_choices"]
  ): boolean => {
    return nextValues
      .filter((choice) => !choice.delete)
      .some((choice) => choice.dirty);
  };

  // 保存済みの選択肢の数が1つか確認
  const isChoicesLengthMin = (
    input_type: string,
    choices: CustomRecordsValuesForService["custom_record_item_choices"]
  ): boolean => {
    if (+input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox) {
      return (
        choices.filter(
          (choice) => choice.id && !choice.delete && choice.hidden === 0
        ).length === 1
      );
    }
    if (+input_type === SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text) {
      return (
        choices.filter(
          (choice) =>
            choice.id &&
            !choice.delete &&
            choice.hidden === 0 &&
            choice.default_choice_input_type ===
              SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox
        ).length === 1
      );
    }
    return false;
  };

  // 選択肢変更時の処理
  const handleChangeChoicesField = (
    arrayHelpers: ArrayHelpers,
    form: FormikProps<CustomRecordsValuesForService>,
    currentKey: number
  ) => (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): void => {
    const { value } = e.target;
    const {
      values: { name, input_type, custom_record_item_choices }
    } = form;

    const tmpChoices = custom_record_item_choices.map((choice, index) => {
      if (index === currentKey) {
        return {
          ...choice,
          name: value
        };
      }
      return choice;
    });

    // 変更管理
    tmpChoices[currentKey].dirty = true;
    if (tmpChoices[currentKey].id) {
      tmpChoices[currentKey].dirty =
        tmpChoices[currentKey].name !==
        formValues.custom_record_item_choices[currentKey].name;
    } else {
      tmpChoices[currentKey].dirty = tmpChoices[currentKey].name !== "";
    }
    if (
      tmpChoices[currentKey].dirty !==
      custom_record_item_choices[currentKey].dirty
    ) {
      form.setFieldValue(
        `custom_record_item_choices[${currentKey}].dirty`,
        tmpChoices[currentKey].dirty
      );
    }

    // 入力値がある、もしくは保存済みの選択値の合計行数
    const inputLength = tmpChoices.filter((choice) => {
      return (
        (!choice.delete && choice.name !== "" && choice.hidden === 0) ||
        (choice.id && choice.hidden === 0)
      );
    }).length;

    // 選択肢の上限数
    const maxRowLength = 10;

    // 最終行に値を入力時、選択肢が最大値未満の場合行追加
    if (
      currentKey === custom_record_item_choices.length - 1 &&
      tmpChoices[currentKey].name &&
      inputLength < maxRowLength
    ) {
      const newChoice = initialValuesForService(
        formValues.custom_records_category_id,
        CUSTOM_RECORD_TARGET_TYPE.service_delivery,
        undefined,
        formValues.id
      ).custom_record_item_choices[0];
      newChoice.key = tmpChoices[currentKey].key + 1;
      arrayHelpers.push(newChoice);
      tmpChoices.push(newChoice);
    } else if (
      // 最終行以外の未保存の値を削除時、最終行に値がある、かつ選択肢が最大値未満の場合行追加
      currentKey !== custom_record_item_choices.length - 1 &&
      tmpChoices[currentKey].name === "" &&
      !tmpChoices[currentKey].id &&
      tmpChoices[custom_record_item_choices.length - 1].name !== "" &&
      inputLength < maxRowLength + 1
    ) {
      const newChoice = initialValuesForService(
        formValues.custom_records_category_id,
        CUSTOM_RECORD_TARGET_TYPE.service_delivery,
        undefined,
        formValues.id
      ).custom_record_item_choices[0];
      newChoice.key = custom_record_item_choices.length;
      arrayHelpers.push(newChoice);
      tmpChoices.push(newChoice);
    }

    // 最終行以外の未保存選択肢の値を削除時に行削除
    if (
      !tmpChoices[currentKey].id &&
      tmpChoices[currentKey].name === "" &&
      currentKey !== custom_record_item_choices.length - 1
    ) {
      form.setFieldValue(
        `custom_record_item_choices[${currentKey}].delete`,
        true
      );
      tmpChoices[currentKey].delete = true;
    }

    // 保存ボタン状態管理
    // 項目名が空、もしくは入力形式がチェックボックスで選択肢が1つもない場合
    // もしくは、保存済み選択肢に空欄の項目がある場合に保存ボタンを非活性にする
    setSubmitButtonDisabled(
      IsSubmitButtonDisabled(name, input_type, tmpChoices) ||
        !!tmpChoices.filter((record) => !!record.id && !record.name).length
    );

    // 選択肢削除アイコン状態管理
    // 選択肢に変更がある場合、もしくは入力形式がチェックボックスで選択肢の数が1つ以下の場合に非活性にする
    setDeleteIconDisabled(
      hasChangesInChoices(tmpChoices) ||
        isChoicesLengthMin(input_type, tmpChoices)
    );
  };

  // 選択肢削除モーダルのstate
  const [isOpenDeleteConfirmModal, setOpenDeleteConfirmModal] = React.useState(
    false
  );
  const [deleteTargetChoiceId, setDeleteTargetChoiceId] = React.useState(0);

  // 選択肢削除モーダルを開く
  const handleClickDelete = (event: React.MouseEvent<SVGElement>): void => {
    setOpenDeleteConfirmModal(true);

    // 選択肢のidをセットしておく
    if (!event.currentTarget.dataset.id) return;
    setDeleteTargetChoiceId(+event.currentTarget.dataset.id);
  };

  // 選択肢削除モーダルを閉じる
  const closeDeleteConfirmModal = (): void => {
    setOpenDeleteConfirmModal(false);
  };

  // 選択肢削除
  const onClickDeleteButton = async (): Promise<void> => {
    if (!formikProps.values.id) return;
    await postHiddenChoice({
      id: deleteTargetChoiceId,
      custom_record_item_id: formikProps.values.id
    });

    await fetchCustomRecords();

    setOpenDeleteConfirmModal(false);
  };

  // 選択肢削除時にモーダル表示を更新する
  useEffect(() => {
    formikProps.setValues(formValues);
    const { input_type, custom_record_item_choices } = formValues;

    // 入力形式がチェックボックスで、選択肢の数が1つ以下の場合、選択肢削除アイコンを非活性にする
    setDeleteIconDisabled(
      isChoicesLengthMin(input_type, custom_record_item_choices)
    );
  }, [formValues]);

  const deleteConfirmModalTitle =
    formikProps.values.input_type === "1"
      ? "この定型文を削除しますか？"
      : "この選択肢を削除しますか？";

  const deleteConfirmModalMessage = (
    <span>
      削除した場合、データの復元はできません。
      <br />
      （記録画面で保存された内容は保持されます）
      <br />
      よろしいですか？
    </span>
  );

  const openDeleteConfirmCloseButton = (
    <KnowbeButton
      kind="text"
      style={{ margin: "0 8px 0 0" }}
      onClick={closeDeleteConfirmModal}
    >
      キャンセル
    </KnowbeButton>
  );
  const openDeleteConfirmSubmitButton = (
    <KnowbeButton
      kind="textDelete"
      style={{ margin: 0 }}
      onClick={onClickDeleteButton}
    >
      削除する
    </KnowbeButton>
  );

  return (
    <>
      {formikProps.values.input_type === "1" && (
        <div className={classes.modalChoicesContents}>
          <div>定型文の登録</div>
          <p className={classes.modalChoicesContentsText}>
            よく使う内容を登録しておくと、記録画面での入力が簡単になります。
          </p>
          <FieldArray
            name="custom_record_item_choices"
            validateOnChange={false}
          >
            {(arrayHelpers): JSX.Element => (
              <>
                {formikProps.values.custom_record_item_choices
                  .filter((choice) => !choice.delete && choice.hidden === 0)
                  .map((choice) => {
                    return (
                      <div
                        key={`${choice.id}_${choice.key}`}
                        className={classes.ChoiceWrap}
                      >
                        <FormikTextField
                          name={`custom_record_item_choices[${choice.key}].name`}
                          style={{
                            width: "672px",
                            marginTop: "24px",
                            marginBottom: 0
                          }}
                          placeholder="入力してください"
                          maxLength={300}
                          multiline
                          onChangeHook={handleChangeChoicesField(
                            arrayHelpers,
                            formikProps,
                            choice.key
                          )}
                        />
                        {!!choice.id && (
                          <DeleteOutlineIcon
                            data-id={
                              formValues.custom_record_item_choices[choice.key]
                                .id
                            }
                            onClick={handleClickDelete}
                            className={`${classes.deleteIcon} ${
                              isDeleteIconDisabled ? classes.disabledIcon : ""
                            }`}
                          />
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </FieldArray>
        </div>
      )}
      {(formikProps.values.input_type === "2" ||
        formikProps.values.input_type === "8") && (
        <div className={classes.modalChoicesContents}>
          <div>選択肢の登録</div>
          <p className={classes.modalChoicesContentsText}>
            一つ以上の選択肢を登録してください。
          </p>
          <FieldArray
            name="custom_record_item_choices"
            validateOnChange={false}
          >
            {(arrayHelpers): JSX.Element => (
              <>
                {formikProps.values.custom_record_item_choices
                  .filter((choice) => !choice.delete && choice.hidden === 0)
                  .map((choice, index) => {
                    return (
                      <div
                        key={`${choice.id}_${choice.key}`}
                        className={classes.ChoiceWrap}
                      >
                        <div className={classes.choiceIndex}>{`選択肢 ${
                          ENCIRCLED_NUMBER[index + 1]
                        }`}</div>
                        <FormikTextField
                          name={`custom_record_item_choices[${choice.key}].name`}
                          style={{
                            width: "360px",
                            marginTop: "24px",
                            marginBottom: 0
                          }}
                          placeholder="入力してください"
                          maxLength={CUSTOM_RECORD_MAXIMUM_CHARACTERS_SELECT}
                          onChangeHook={handleChangeChoicesField(
                            arrayHelpers,
                            formikProps,
                            choice.key
                          )}
                        />
                        {!!choice.id && (
                          <DeleteOutlineIcon
                            data-id={
                              formValues.custom_record_item_choices[choice.key]
                                .id
                            }
                            onClick={handleClickDelete}
                            className={`${classes.deleteIcon} ${
                              isDeleteIconDisabled ? classes.disabledIcon : ""
                            }`}
                          />
                        )}
                      </div>
                    );
                  })}
              </>
            )}
          </FieldArray>
        </div>
      )}
      <MessageDialog
        isOpen={isOpenDeleteConfirmModal}
        title={deleteConfirmModalTitle}
        message={deleteConfirmModalMessage}
        closeButton={openDeleteConfirmCloseButton}
        actionButton={openDeleteConfirmSubmitButton}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );

  return {
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsWithCategoryDispatches.fetchCustomRecordsService();
    },
    postHiddenChoice: (params: HiddenChoiceState): Promise<void> => {
      return customRecordsWithCategoryDispatches.postHiddenChoice(params);
    }
  };
};

export const CustomRecordsDialogChoicesContent = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(CustomRecordsDialogChoicesContentCore));
