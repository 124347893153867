import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InOutReportUserHeader } from "@components/organisms/mgr/CHIIKIIKO/report/InOutReportMonthlyHeader";
import { InOutReportTable } from "@components/organisms/mgr/CHIIKIIKO/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { InOutReportPaperHeader } from "@components/organisms/mgr/CHIIKIIKO/report/InOutReportPaperHeader";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  ReportState,
  ReportSummary,
  InoutConsultationResultsState
} from "@stores/domain/mgr/CHIIKIIKO/report/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { OptionInterface } from "@components/atoms/DropDown";
import { InOutReportDialog } from "@components/organisms/mgr/CHIIKIIKO/report/InOutReportDialog";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import {
  InitialDataValues,
  initialValues
} from "@initialize/mgr/CHIIKIIKO/report/initialValues";
import {
  INVOICE_PATHS,
  MONTHLY_INTENSIVE_SUPPORT_VALUE
} from "@constants/mgr/CHIIKIIKO/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { FacilityType } from "@constants/variables";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  reportState: ReportState;
  inoutErrors: ErrorsState["inout"];
};

type DispatchProps = {
  fetchFacility: () => void;
  fetchReportUser: (uifId: number, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績(月ごと）
 */
const InOutReportMonthlyCore = (props: Props): JSX.Element => {
  const [selectedMonth, setSelectedMonth] = React.useState<Date>(
    props.initialDate
  );
  const [selectedUser, setSelectedUser] = React.useState<OptionInterface>({
    label: "",
    value: ""
  });
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);
  const [isOpenDetailModal, setIsOpenDetailModal] = React.useState<boolean>(
    false
  );
  const [data, setData] = React.useState<InitialDataValues>(initialValues());
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    props.initialDate
  );

  React.useEffect(() => {
    Promise.all([props.fetchFacility(), props.fetchInoutError(selectedMonth)]);
  }, []);

  React.useEffect(() => {
    const top = document.getElementById("reportMonthlyHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, [document.getElementById("reportMonthlyHeader")]);

  const onChangeMonth = (date: Date, user: OptionInterface): void => {
    const transitionDone = checkReportVersion(
      date,
      FacilityType.CHIIKIIKO,
      props.currentPageVersion,
      INVOICE_PATHS.reportUsers,
      props.history
    );
    if (!transitionDone) {
      setSelectedMonth(date);
      setSelectedUser(user);
      props.fetchReportUser(+user.value, date);
      props.fetchInoutError(date);
    }
  };

  const onChangeUser = (user: OptionInterface): void => {
    setSelectedUser(user);
    props.fetchReportUser(+user.value, selectedMonth);
    props.fetchUserInFacility(user.value.toString());
    props.fetchInoutError(selectedMonth);
  };

  const onClickErrorDialog = (): void => {
    props.openErrorsDialog();
  };

  const openModal = (param: InoutConsultationResultsState): void => {
    setIsOpenDetailModal(true);
    setData(initialValues(param));
    setSelectedDate(convertBlankSeparatorFormatToDate(param.targetDate));
    props.fetchUserInFacility(param.usersInFacilityId.toString());
  };

  const onCancel = (): void => {
    setIsOpenDetailModal(false);
  };

  const onSubmit = (): void => {
    props.fetchReportUser(+selectedUser.value, selectedMonth);
    props.fetchInoutError(selectedMonth);
  };

  /**
   * RectangleBoxが受け付けるconfigを生成する
   */
  const genRectangleBoxConfig = (
    title: string,
    num: number | null | undefined,
    denom?: number,
    unit = "日"
  ): {
    title: string;
    denom: number | undefined;
    num: number;
    unit: string;
  } => {
    return { title, denom, num: num || 0, unit };
  };

  const createRectangleList = (
    summary: ReportSummary
  ): {
    title: string;
    denom: number | undefined;
    num: number;
    unit: string;
  }[] => {
    return [
      genRectangleBoxConfig(
        "集中支援加算",
        summary.intensiveSupportCount,
        MONTHLY_INTENSIVE_SUPPORT_VALUE
      )
    ];
  };

  const { classes, inoutErrors } = props;
  return (
    <>
      <div id="reportMonthlyHeader" className={classes.headerWrapper}>
        {inoutErrors.hasError && (
          <InvoiceErrorBar
            message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
            onClick={onClickErrorDialog}
          />
        )}
        <div className={classes.headerInfoContainer}>
          <InOutReportUserHeader
            minDate={minDate}
            maxDate={maxDate}
            selectedMonth={selectedMonth}
            selectedUserId={selectedUser.value}
            onChangeMonth={onChangeMonth}
            onChangeUser={onChangeUser}
          />
        </div>
      </div>
      <Paper elevation={0} className={classes.tableContainer}>
        <InOutReportPaperHeader
          rectangleConfigList={createRectangleList(
            props.reportState.reportMonthly.summary
          )}
        />
        <InOutReportTable
          headerHeight={headerHeight}
          openModal={openModal}
          filterFlg={false}
        />
      </Paper>
      <InOutReportDialog
        open={isOpenDetailModal}
        data={data}
        selectedDate={selectedDate}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    reportState: state.CHIIKIIKO.report,
    inoutErrors: state.errors.inout
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO, errorsDispatcher } = dispatches;
  const reportDispatcher = CHIIKIIKO.reportDispatcher(dispatch);
  const uifDispatcher = CHIIKIIKO.userInFacilityDispatcher(dispatch);
  return {
    fetchFacility: CHIIKIIKO.facilityDispatcher(dispatch).fetch,
    fetchReportUser: (uifId: number, date: Date): Promise<void> =>
      reportDispatcher.fetchCHIIKIIKOUsers(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog())
  };
};

export const InOutReportMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportMonthlyCore));
