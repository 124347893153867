import * as React from "react";
import * as URL from "@constants/url";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { FacilityType, PLAN_LIST_TAB_PATH } from "@constants/variables";
import { FacilityState as FacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/facility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { SupportPlanSheetsPrintContent } from "@components/organisms/mgr/common/record/SupportPlanSheetsPrintContent";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";

type OwnProps = {} & RouteComponentProps<{
  id: string;
  supportPlanSheetId: string;
}>;
type StateProps = {
  user: UsersInFacilityStateKODOENGO["user"];
  facility: FacilityStateKODOENGO;
  facilityType: FacilityType;
  supportPlanSheet: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"];
};
type DispatchProps = {
  fetchOneUser: (id: string, facilityType: FacilityType) => Promise<void>;
  fetchFacility: (facilityType: FacilityType) => Promise<void>;
  fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
    id: string,
    supportPlanSheetId: string
  ) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const SupportPlanSheetsPrintCore = (props: Props): JSX.Element => {
  const { id, supportPlanSheetId } = props.match.params;
  const { user, facility, facilityType, supportPlanSheet } = props;

  const pageName = "支援計画シート";
  const pathList = [
    {
      pathName: "支援計画",
      path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY
    },
    {
      pathName: `${pageName}一覧`,
      path: `${URL.RECORD_SUPPORT_PLAN}/${id}/${PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS}`
    }
  ];

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchFacility(facilityType);
    props.fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
      id,
      supportPlanSheetId
    );
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <SupportPlanSheetsPrintContent
          user={user}
          pageName={pageName}
          facility={facility}
          supportPlanSheet={supportPlanSheet}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    user: state[facilityType].userInFacility.user,
    facility: state[facilityType].facility,
    facilityType,
    supportPlanSheet:
      state.supportPlanSheets.supportPlanSheetsSupportPlanSheetId
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportPlanSheetsDispatcher } = dispatches;

  return {
    fetchOneUser: (id: string, facilityType: FacilityType): Promise<void> =>
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id),
    fetchFacility: (facilityType: FacilityType): Promise<void> =>
      dispatches[facilityType].facilityDispatcher(dispatch).fetch(),
    fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: supportPlanSheetsDispatcher(
      dispatch
    ).fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId
  };
};

export const SupportPlanSheetsPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportPlanSheetsPrintCore);
