import React from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as classNames from "classnames";
// ui
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import Checkbox from "@components/atoms/Checkbox";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import icon_ndlist_disabled from "@images/icon_ndlist_disabled.svg";
import icon_ndlist from "@images/icon_ndlist.svg";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
// variables
import { CUSTOM_RECORD_INPUT_TYPE, FacilityType } from "@constants/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO } from "@constants/mgr/DOKOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO } from "@constants/mgr/KODOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN } from "@constants/mgr/IDOSHIEN/variables";
// formik
import { FormikProps } from "formik";
import {
  CustomRecordItemValues,
  tableBodyInitialValuesForService
} from "@initialize/record/customRecordWithCategory/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    table: {
      minWidth: 1030
    },
    tableHeaderCell: {
      padding: "0 0 0 16px",
      "&:last-child": {
        textAlign: "center",
        paddingRight: "12px"
      }
    },
    tableHeaderItemNameCell: {
      width: 356
    },
    tableHeaderInputTypeCell: {
      paddingLeft: spacing.unit * 3
    },
    tableHeaderChangeVisibilityCell: {
      width: "68px"
    },
    "@keyframes flash": {
      "0%": { backgroundColor: "#ebebeb" },
      "100%": { backgroundColor: "#fff" }
    },
    tableMovedRow: {
      animation: "flash 0.5s linear"
    },
    tableBodyCell: {
      padding: "8px 0 8px 16px",
      verticalAlign: "middle",
      "&:last-child": {
        padding: "9px 0 8px"
      }
    },
    tableBodyItemNameCell: {
      width: 356
    },
    tableBodyInputTypeCell: {
      display: "flex",
      height: spacing.unit * 8,
      paddingLeft: spacing.unit * 3
    },
    tableBodyInputTypeNameCell: {
      width: 240,
      margin: "auto 0"
    },
    tableBodyEditDisabledCell: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.38)",
      lineHeight: "28px",
      paddingLeft: 26,
      margin: "auto 0 auto 24px"
    },
    tableBodyChangeVisibilityCell: {
      textAlign: "right",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.38)"
    },
    tableBodyDisabledText: {
      lineHeight: 1.33,
      textAlign: "center",
      marginRight: "12px"
    },
    editButton: {
      minHeight: 32,
      height: spacing.unit * 4,
      margin: "auto 0 auto 24px"
    },
    openHiddenListButton: {
      display: "flex",
      margin: "16px 0 0 auto"
    },
    ndlistIcon: {
      marginRight: 6
    },
    tooltipWrap: {
      display: "flex",
      alignItems: "center"
    },
    tooltip: {
      margin: "7px 0 0 16px"
    },
    arrowIconWrap: {
      height: "47px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      marginRight: "12px"
    },
    arrowIconButton: {
      width: "36px",
      minWidth: "36px",
      height: "20px",
      minHeight: "20px",
      backgroundColor: "#fff"
    },
    downwardIcon: {
      "&:first-child": {
        marginTop: "27px"
      }
    },
    arrowIcon: {
      fontSize: "22px"
    },
    invisibleAllCategory: {
      display: "flex",
      alignItems: "center",
      height: 64,
      borderBottom: "1px solid #e5e5e5"
    },
    invisibleAllCategoryText: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      marginLeft: 16
    }
  });

type OwnProps = {
  formikProps: FormikProps<CustomRecordItemValues>;
  hiddenCustomRecords: CustomRecordItemValues;
  openItemEditModal: (record: CustomRecordItemValues[0]) => void;
  openHideConfirmModal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  openHiddenCustomRecordsList: (type: number) => void;
  categoryType: number;
  isSorting: boolean;
  formValues: CustomRecordItemValues;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CustomRecordsTableCore = (props: Props): JSX.Element => {
  const {
    classes,
    hiddenCustomRecords,
    openItemEditModal,
    openHideConfirmModal,
    openHiddenCustomRecordsList,
    categoryType,
    isSorting,
    formikProps,
    formValues
  } = props;

  const { values } = formikProps;

  React.useEffect(() => {
    formikProps.setValues(formValues);
  }, [formValues]);

  // テーブルヘッダー用のデータ
  const headerData: KnowbeTableCellParam[] = [
    {
      label: "項目名",
      className: classNames(
        classes.tableHeaderCell,
        classes.tableHeaderItemNameCell
      )
    },
    {
      label: "入力形式",
      className: classNames(
        classes.tableHeaderCell,
        classes.tableHeaderInputTypeCell
      )
    },
    {
      label: isSorting ? "並び順" : "表示",
      className: classNames(
        classes.tableHeaderCell,
        classes.tableHeaderChangeVisibilityCell
      )
    }
  ];

  const openHiddenCustomRecordsListModal = (): void => {
    openHiddenCustomRecordsList(categoryType);
  };

  // state（並べ替えボタンが押された項目にアニメーションCSS用のclassを付与する用途）
  const [movedRowId, setMovedRow] = React.useState(0);

  React.useEffect(() => {
    // 0.5s後にデフォルトに戻す（同位置のボタンが押されたときにもアニメーションが起こるようにする）
    if (movedRowId !== 0) {
      setTimeout(setMovedRow, 500, 0);
    }
  }, [movedRowId]);

  // 並べ替え可能最初の項目
  const firstItem = values.find((record) => record.allow_change_order);

  // 並べ替え可能最後の項目
  const lastItem = [...values]
    .reverse()
    .find((record) => record.allow_change_order);

  const extractStaffCommentNum = (): number => {
    switch (props.facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.staff_comment;
      case FacilityType.JUDOHOMONKAIGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.staff_comment;
      case FacilityType.DOKOENGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.staff_comment;
      case FacilityType.KODOENGO:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.staff_comment;
      case FacilityType.IDOSHIEN:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.staff_comment;
      default:
        return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.staff_comment;
    }
  };

  const staffComment = extractStaffCommentNum();

  // 記録項目データ（テーブル表示用）
  const bodyData = values.map((record, index, self) => {
    const openEditModal = (): void => {
      openItemEditModal(record);
    };

    const onClickSortingButton = (
      e: React.MouseEvent<HTMLInputElement>
    ): void => {
      const { value } = e.currentTarget;
      const orderedArray = [...self];

      if (value === "up") {
        // 配列の並び順を入れ替える
        orderedArray.splice(index - 1, 2, self[index], self[index - 1]);
      }
      if (value === "down") {
        // 配列の並び順を入れ替える
        orderedArray.splice(index, 2, self[index + 1], self[index]);
      }

      formikProps.setValues(tableBodyInitialValuesForService(orderedArray));
      setMovedRow(record.id);
    };

    let visibilityOrOrderDom;

    if (!isSorting) {
      visibilityOrOrderDom = record.allow_change_visibility ? (
        <Checkbox
          label=""
          value={`${record.id}`}
          checked
          labelStyle={{
            width: 56,
            height: 46,
            marginRight: 12,
            justifyContent: "center"
          }}
          onChange={openHideConfirmModal}
        />
      ) : (
        <div className={classes.tableBodyDisabledText}>常に表示</div>
      );
    } else {
      visibilityOrOrderDom = record.allow_change_order ? (
        <div className={classes.arrowIconWrap}>
          {!!firstItem && firstItem.id !== record.id && (
            <KnowbeButton
              className={classes.arrowIconButton}
              kind="outline"
              value="up"
              onClick={onClickSortingButton}
            >
              <ArrowUpwardIcon className={classes.arrowIcon} />
            </KnowbeButton>
          )}
          {!!lastItem && lastItem.id !== record.id && (
            <KnowbeButton
              className={classNames(
                classes.downwardIcon,
                classes.arrowIconButton
              )}
              kind="outline"
              value="down"
              onClick={onClickSortingButton}
            >
              <ArrowDownwardIcon className={classes.arrowIcon} />
            </KnowbeButton>
          )}
        </div>
      ) : (
        <div className={classes.tableBodyDisabledText}>
          並べ替え
          <br />
          不可
        </div>
      );
    }

    return [
      {
        label: (
          <>
            {record.default_item === staffComment ? (
              <div className={classes.tooltipWrap}>
                <span>{record.name}</span>
                <span className={classes.tooltip}>
                  <HelpToolTip
                    title={<HelpTipMessages name="staffComment" />}
                  />
                </span>
              </div>
            ) : (
              record.name
            )}
          </>
        ),
        className: classNames(
          classes.tableBodyCell,
          classes.tableBodyItemNameCell,
          movedRowId === record.id && classes.tableMovedRow
        )
      },
      {
        label: (
          <>
            <div className={classes.tableBodyInputTypeNameCell}>
              {CUSTOM_RECORD_INPUT_TYPE[record.input_type]}
            </div>
            {!isSorting &&
              (record.allow_edit_name === 1 ||
              record.allow_edit_choiced === 1 ? (
                <KnowbeButton
                  minWidth={100}
                  kind="outline"
                  onClick={openEditModal}
                  className={classes.editButton}
                >
                  編集
                </KnowbeButton>
              ) : (
                <div className={classes.tableBodyEditDisabledCell}>
                  編集不可
                </div>
              ))}
          </>
        ),
        className: classNames(
          classes.tableBodyCell,
          classes.tableBodyInputTypeCell,
          movedRowId === record.id && classes.tableMovedRow
        )
      },
      {
        label: visibilityOrOrderDom,
        className: classNames(
          classes.tableBodyCell,
          classes.tableBodyChangeVisibilityCell,
          movedRowId === record.id && classes.tableMovedRow
        )
      }
    ];
  });

  const hiddenListButtonDisabled = !hiddenCustomRecords.length || isSorting;
  const invisibleAllItem = !formValues.some(
    (v) => v.visibility === 1 && v.setting_page_visibility === 1
  );

  return (
    <>
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead
          uniqueKey="customRecordsTable"
          height={48}
          items={headerData}
        />
        <KnowbeTableBody
          uniqueKey="customRecordsTable"
          itemsContainer={bodyData}
          stripe={false}
        />
      </KnowbeTable>
      {invisibleAllItem && (
        <div className={classes.invisibleAllCategory}>
          <p className={classes.invisibleAllCategoryText}>
            全ての項目が非表示に設定されています。
          </p>
        </div>
      )}
      <KnowbeButton
        className={classes.openHiddenListButton}
        onClick={openHiddenCustomRecordsListModal}
        kind="text"
        disabled={hiddenListButtonDisabled}
      >
        {hiddenListButtonDisabled ? (
          <img
            src={icon_ndlist_disabled}
            className={classes.ndlistIcon}
            alt=""
          />
        ) : (
          <img src={icon_ndlist} className={classes.ndlistIcon} alt="" />
        )}
        非表示項目リスト
      </KnowbeButton>
    </>
  );
};

export const CustomRecordsTable = withStyles(styles)(CustomRecordsTableCore);
