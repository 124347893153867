import { RecordSupportPlanValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, {
  dateValidator,
  validateSwitcher,
  ErrorMessage,
  Rule
} from "@validator";

type SupportPlanGoalErrorMessage = {
  sprint_review: ErrorMessage;
  sprint_user_review: ErrorMessage;
  sprint_staff_review: ErrorMessage;
};

const validation = (
  values: RecordSupportPlanValues
): ValidationErrors<RecordSupportPlanValues> => {
  const lengthRule = (length: number): Rule => ({
    type: "checkCharacterLength",
    length
  });
  const validateSupportPlanGoal = (
    goal: RecordSupportPlanValues["support_plan_goal"]
  ): SupportPlanGoalErrorMessage[] => {
    return goal.map((g) => {
      return {
        sprint_review: validator(
          g.sprint_review ? g.sprint_review : "",
          lengthRule(2500)
        ),
        sprint_user_review: validator(
          g.sprint_user_review ? g.sprint_user_review : "",
          lengthRule(2500)
        ),
        user_hope: validator(g.user_hope ? g.user_hope : "", lengthRule(2500)),
        sprint_staff_review: validator(
          g.sprint_staff_review ? g.sprint_staff_review : "",
          lengthRule(2500)
        )
      };
    });
  };
  return {
    creation_date: dateValidator(values.creation_date, "required"),
    support_begin_date: dateValidator(values.support_begin_date, "required"),
    support_end_date: dateValidator(values.support_end_date, "required", {
      type: "future",
      startDate: values.support_begin_date
    }),
    support_plan_goal: validateSupportPlanGoal(values.support_plan_goal),
    after_summary: validator(
      values.after_summary ? values.after_summary : "",
      lengthRule(2500)
    ),
    evaluation_minutes: validator(
      values.evaluation_minutes ? values.evaluation_minutes : "",
      lengthRule(2500)
    ),
    authorizer: validateSwitcher(
      values.status_type === "1",
      validator(values.authorizer, "required")
    )
  };
};

export default validation;
