import * as React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {
  STICKY_BOX_SHADOW,
  STICKY_NONE_BOX_SHADOW,
  DIVIDER_UNSET_BG,
  DIVIDER_BG_COLOR
} from "@constants/styles";
import * as URL from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    // 暫定処理 : KnowbeTabs 未選択ボタンの透過対応
    tabWrapper: {
      position: "sticky",
      backgroundColor: "#fff"
    },
    stickyWrapper: {
      position: "sticky",
      zIndex: 10
    },
    stickyCommonWrapper: {
      top: 73
    },
    stickySupportPlanWrapper: {
      top: 16
    },
    header: {
      backgroundColor: "#fff",
      padding: "32px 32px 16px",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between"
    },
    title: {
      lineHeight: 1,
      "& > span": {
        marginLeft: 16
      }
    },
    divider: {
      position: "relative",
      margin: "0 32px -2px"
    }
  });

type OwnProps = {
  uifId: string;
  recordType:
    | "assessment"
    | "basic_info"
    | "support"
    | "plan_monitoring_meeting";
  isEditing: boolean;
  history: H.History;
  pageName: string;
  userName: string;
  button: JSX.Element | JSX.Element[];
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * タブとタイトルヘッダーを表示する役割
 */
const RecordHeaderCore = (props: Props): JSX.Element => {
  const scrollYValue = 335;
  const classWrapper =
    props.recordType === "support"
      ? `${props.classes.stickyWrapper} ${props.classes.stickyCommonWrapper}`
      : `${props.classes.stickyWrapper} ${props.classes.stickySupportPlanWrapper}`;
  const [stickyFlg, setStickyFlg] = React.useState(false);

  React.useEffect(() => {
    let unmounted = false;
    const listenScrollEvent = (): void => {
      if (unmounted) return;
      if (window.scrollY > scrollYValue) {
        setStickyFlg(true);
      } else {
        setStickyFlg(false);
      }
    };

    window.addEventListener("scroll", listenScrollEvent);
    return (): void => {
      unmounted = true;
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const handleTabChange = (e: React.ChangeEvent<{}>, value: string): void => {
    if (value === "support" || value === "plan_monitoring_meeting") {
      props.history.push(`${URL.RECORD}/${props.uifId}/${value}`);
    } else {
      props.history.push(`${URL.RECORD_SUPPORT_PLAN}/${props.uifId}/${value}`);
    }
  };

  const recordInfo = [
    { label: "アセスメント", value: "assessment" },
    { label: "申請者の現状（基本情報）", value: "basic_info" },
    { label: "計画・モニタリング・会議記録", value: "plan_monitoring_meeting" },
    { label: "支援記録", value: "support" }
  ];

  return (
    <div
      className={classWrapper}
      style={
        stickyFlg
          ? { boxShadow: STICKY_BOX_SHADOW }
          : { boxShadow: STICKY_NONE_BOX_SHADOW }
      }
    >
      <div className={props.classes.tabWrapper}>
        <KnowbeTabs
          key="recordTabs"
          tabInfo={recordInfo}
          value={props.recordType}
          disabled={props.isEditing}
          onChange={handleTabChange}
        />
      </div>
      <div className={props.classes.header}>
        <Typography
          variant="h6"
          color="primary"
          className={props.classes.title}
        >
          {props.pageName}
          <span>{props.userName}</span>
        </Typography>
        <div>{props.button}</div>
      </div>
      <Divider
        className={props.classes.divider}
        style={
          stickyFlg
            ? { backgroundColor: DIVIDER_UNSET_BG }
            : { backgroundColor: DIVIDER_BG_COLOR }
        }
      />
    </div>
  );
};
export const RecordHeader = withStyles(styles)(RecordHeaderCore);
