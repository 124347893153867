import * as types from "./types";
import * as actions from "./actions";

const initialState: types.HolidayState = [];

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.HolidayState => {
  switch (action.type) {
    case types.FETCH_HOLIDAY_STARTED:
      return state;
    case types.FETCH_HOLIDAY_SUCCESS:
      return action.payload;
    case types.FETCH_HOLIDAY_FAILED:
      return state;
    default:
      return state;
  }
};

export default reducer;
