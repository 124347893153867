import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    root: {
      paddingTop: 0
    }
  });

type OwnProps = {
  children: React.ReactNode;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 請求書などの印刷用書類ページで利用する空のテンプレート
 */
const previewTemplate = (props: Props): JSX.Element => (
  <div className={props.classes.root}>{props.children}</div>
);

export default withStyles(styles)(previewTemplate);
