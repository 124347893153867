import { BasicErrors } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/facility/basic";
import { UnitsErrors } from "@interfaces/v202104/mgr/KYOTAKUKAIGO/facility/units";
import { FacilityValues } from "@initialize/v202104/mgr/KYOTAKUKAIGO/facility/initialValues";
import validator, { validateSwitcher } from "@validator";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

type FacilityErrors = BasicErrors;

const basicValidation = (values: FacilityValues): BasicErrors => {
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      officeNumber: validator(
        values.basic.officeNumber,
        "required",
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      officeName: validator(values.basic.officeName, "required"),
      representativeName: validator(
        values.basic.representativeName,
        "required"
      ),
      allCapacity: validateSwitcher(
        values.basic.multiFunctionOfficeFlag,
        validator(
          values.basic.allCapacity,
          "required",
          "naturalNumberNonZero",
          {
            type: "upperLimit",
            upperLimit: 999
          }
        )
      ),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "required", "naturalNumber")
    }
  };
};

const unitsValidation = (values: FacilityValues): UnitsErrors => {
  return {
    units: values.units
      ? values.units.map((record) => {
          return {
            unit_name: validateSwitcher(
              !record.is_deleted && values.basic.buildingManagementFlg,
              validator(record.unit_name, "required", {
                type: "checkCharacterLength",
                length: 30
              })
            )
          };
        })
      : []
  };
};

export const validation = (values: FacilityValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const unitsErrors = unitsValidation(values);
  return {
    ...basicErrors,
    ...unitsErrors
  };
};
