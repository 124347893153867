import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";
import { Checkbox } from "@constants/variables";

/**
 * ActionNames
 */
export const FETCH_STARTED = "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_FAILED";
export const FETCH_STARTED_TARGETDAY =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_STARTED_TARGETDAY";
export const FETCH_SUCCESS_TARGETDAY =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_SUCCESS_TARGETDAY";
export const FETCH_FAILED_TARGETDAY =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_FAILED_TARGETDAY";
export const FETCH_ONE_STARTED =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED =
  "KYOTAKUKAIGO/USER_IN_FACILITY/FETCH_ONE_FAILED";
export const CREATE_STARTED = "KYOTAKUKAIGO/USER_IN_FACILITY/CREATE_STARTED";
export const CREATE_SUCCESS = "KYOTAKUKAIGO/USER_IN_FACILITY/CREATE_SUCCESS";
export const CREATE_FAILED = "KYOTAKUKAIGO/USER_IN_FACILITY/CREATE_FAILED";
export const UPDATE_STARTED = "KYOTAKUKAIGO/USER_IN_FACILITY/UPDATE_STARTED";
export const UPDATE_SUCCESS = "KYOTAKUKAIGO/USER_IN_FACILITY/UPDATE_SUCCESS";
export const UPDATE_FAILED = "KYOTAKUKAIGO/USER_IN_FACILITY/UPDATE_FAILED";
export const DELETE_STARTED = "KYOTAKUKAIGO/USER_IN_FACILITY/DELETE_STARTED";
export const DELETE_SUCCESS = "KYOTAKUKAIGO/USER_IN_FACILITY/DELETE_SUCCESS";
export const DELETE_FAILED = "KYOTAKUKAIGO/USER_IN_FACILITY/DELETE_FAILED";
export const CLEAR = "KYOTAKUKAIGO/USER_IN_FACILITY/CLEAR";

export type UsersInFacilityState = {
  users: GetFacilityUsersResponse["data"];
  usersList: GetFacilityUsersListResponse["data"];
  user: {
    user_in_facility: {
      /* 基本情報 */

      // 施設利用者id
      id?: number;
      // 姓
      name_sei?: string;
      // 名
      name_mei?: string;
      // 姓(カナ)
      name_sei_kana?: string;
      // 名(カナ)
      name_mei_kana?: string;
      // 受給者証番号
      recipient_number?: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg?: Checkbox;
      // 性別
      gender?: string;
      // 施設id
      facility_id?: number;
      // 身体障害
      classify_physical_flg?: Checkbox;
      // 知的障害
      classify_intelligence_flg?: Checkbox;
      // 精神障害
      classify_mind_flg?: Checkbox;
      // 発達障害
      classify_growth_flg?: Checkbox;
      // 高次脳機能障害
      classify_brain_flg?: Checkbox;
      // 難病等対象者
      classify_incurable_flg?: Checkbox;
      // 障害児
      classify_handicapped_flg?: Checkbox;
      // 生年月日
      date_birth?: string;
      // 郵便番号
      postal_code?: string;
      // 都道府県
      prefecture_name?: string;
      // 市区町村id
      city_id?: number;
      // 住所
      address?: string;
      // 電話番号
      tel?: string;
      // メールアドレス
      email?: string;
      // 保護者姓
      guardian_name_sei?: string;
      // 保護者名
      guardian_name_mei?: string;
      // 保護者姓(カナ)
      guardian_name_sei_kana?: string;
      // 保護者名(カナ)
      guardian_name_mei_kana?: string;
      // 保護者、続柄
      guardian_relation?: string;
      // 保護者 電話番号
      guardian_tel?: string;
      // 備考
      memo?: string;

      /* サービス詳細 */

      // 入居日 yyyy-mm-dd
      date_begin_in_service?: string;
      // 退居日 yyyy-mm-dd
      date_end_in_service?: string;
      // 支給決定開始日
      date_pay_begin?: string;
      // 支給決定終了日
      date_pay_end?: string;
      // 障害支援区分
      classify_disability_support?: string;
      // 負担上限額
      income_kind?: string;
      // 所得区分
      income_kind_type?: string;
      // Checkbox?: 自治体助成金対応
      subsidized_flg?: Checkbox;
      // 金額?: %
      subsidized_percent?: string;
      // 金額 ¥
      subsidized_yen?: string;
      // Select?: 自治体助成金対応(単位 1?: %, 2?: ¥)
      subsidized_unit_flg?: string;
      // 市区町村番号
      subsidized_city_id?: number;
      // 上限額管理事業所有り
      uplimit_facility_flg?: Checkbox;
      // 上限管理事業所コード null, 1?: 上限額管理事業所あり 2?: 他事業所が上限を管理
      uplimit_controlled_by?: string;
      // サービス提供した事業者番号
      uplimit_facility_number?: string;
      // サービス提供した事業者番号2
      uplimit_facility_number2?: string;
      // サービス提供した事業者番号3
      uplimit_facility_number3?: string;
      // サービス提供した事業者番号4
      uplimit_facility_number4?: string;
      // 上限額管理事業所 事業所名
      uplimit_facility_name?: string;
      // 上限額管理事業所 事業所名2
      uplimit_facility_name2?: string;
      // 上限額管理事業所 事業所名3
      uplimit_facility_name3?: string;
      // 上限額管理事業所 事業所名4
      uplimit_facility_name4?: string;
      // 上限管理・総費用額(金額)
      uplimit_total_yen?: string;
      // 上限管理・総費用額(金額)2
      uplimit_total_yen2?: string;
      // 上限管理・総費用額(金額)3
      uplimit_total_yen3?: string;
      // 上限管理・総費用額(金額)4
      uplimit_total_yen4?: string;
      // 上限管理・利用者負担額(yen)
      uplimit_user_load_yen?: string;
      // 上限管理・利用者負担額(yen)2
      uplimit_user_load_yen2?: string;
      // 上限管理・利用者負担額(yen)3
      uplimit_user_load_yen3?: string;
      // 上限管理・利用者負担額(yen)4
      uplimit_user_load_yen4?: string;
      // 上限管理・管理結果後利用者負担額(yen)
      uplimit_user_load_after_adjustment_yen?: string;
      // 上限管理・管理結果後利用者負担額(yen)2
      uplimit_user_load_after_adjustment_yen2?: string;
      // 上限管理・管理結果後利用者負担額(yen)3
      uplimit_user_load_after_adjustment_yen3?: string;
      // 上限管理・管理結果後利用者負担額(yen)4
      uplimit_user_load_after_adjustment_yen4?: string;
      // 利用者負担上限額管理結果
      result_of_management?: string;
      // 自事業所調整上限額
      uplimit_yen?: string;
      // 個別支援計画作成済み
      create_support_plan_flg?: Checkbox;
      // 個別支援計画作成期間開始日
      date_start_not_create_support_plan?: string;
      // 同一法人の事業所から受け入れ(初期加算対象外)
      same_corporation_movement_flg?: Checkbox;
      // 契約支給量
      agreed_by_contract_flg?: string;
      // 食事提供サービス
      def_food?: string;
      // 送迎サービス
      def_pickup?: string;
      // 同一敷地内送迎
      pickup_premises?: string;
      // 日数
      pay_days_agreed?: string;
      // 事業所記入欄番号
      business_number_contract?: string;
      // 就労移行連携加算
      ikou_cooperation_flg?: number;
      // 就労移行連携加算実施日
      ikou_cooperation_date?: string | null;
      // 支援計画会議実施加算
      case_meeting_flg?: number;
      // 支援計画会議実施加算 算定開始日
      case_meeting_start_date?: string | null;
      // 利用日数に関わる特例あり
      day_use_special_case_flg?: Checkbox;
      // 利用日数に関わる特例あり開始日
      day_use_special_case_from?: string;
      // 利用日数に関わる特例あり終了日
      day_use_special_case_to?: string;

      /* 受給者証 */

      // 負担上限月額の適用期間 1: ON, 0: OFF
      user_charge_limit_flg?: Checkbox;
      // 負担上限月額の適用開始日 yyyy-mm-dd
      date_begin_user_charge_limit?: string;
      // 負担上限月額の適用終了日 yyyy-mm-dd
      date_end_user_charge_limit?: string;
      // 食事提供体制加算の適用 1: ON, 0: OFF
      food_serve_addition_flg?: Checkbox;
      // 食事提供体制加算の適用開始日 yyyy-mm-dd
      date_begin_food_serve_addition?: string;
      // 食事提供体制加算の適用終了日 yyyy-mm-dd
      date_end_food_serve_addition?: string;
      // 介護給付費の支給決定フラグ 1?: ON 0?: OFF
      care_support_payment_flg?: Checkbox;
      // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
      date_begin_care_support_payment?: string;
      // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
      date_end_care_support_payment?: string;
      // 障害支援区分の認定有効  1: ON, 0: OFF
      care_support_auth_flg?: Checkbox;
      // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
      date_begin_care_support_auth?: string;
      // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
      date_end_care_support_auth?: string;
      // 計画相談支援給付費の支給決定フラグ 1?: ON 0?: OFF
      plan_support_payment_flg?: Checkbox;
      // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
      date_begin_plan_support_payment?: string;
      // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
      date_end_plan_support_payment?: string;
      // 計画相談支援給付費のモニタリング期間 フラグ 1?: ON 0?: OFF
      plan_support_monitor_flg?: Checkbox;
      // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
      date_begin_plan_support_monitor?: string;
      // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
      date_end_plan_support_monitor?: string;

      // 通所予定日（月）
      mon_scheduled_flg?: Checkbox;
      // 通所予定日（火）
      tue_scheduled_flg?: Checkbox;
      // 通所予定日（水）
      wed_scheduled_flg?: Checkbox;
      // 通所予定日（木）
      thu_scheduled_flg?: Checkbox;
      // 通所予定日（金）
      fri_scheduled_flg?: Checkbox;
      // 通所予定日（土）
      sat_scheduled_flg?: Checkbox;
      // 通所予定日（日）
      sun_scheduled_flg?: Checkbox;
      // A型減免対象
      a_target_for_reduction_flg?: Checkbox;
      // 作業時間を管理する
      def_record_work?: Checkbox;
    };
    user_in_facility_kyotakukaigo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 居住する建物ID
      facility_unit_id: number | null;
      // 障害区分
      disability_class: number;
      // 所得区分
      income_kind: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 開始日
      date_begin_special_area: string | null;
      // 特別地域加算 終了日
      date_end_special_area: string | null;
      // 契約支給量_身体介護_月単位
      pay_months_agreed_physical_care: number;
      // 契約支給量_身体介護_回単位
      pay_times_agreed_physical_care: number;
      // 契約支給量_通院介助（伴う）_月単位
      pay_months_agreed_outpatient_care_with_physical_care: number;
      // 契約支給量_通院介助（伴う）_回単位
      pay_times_agreed_outpatient_care_with_physical_care: number;
      // 契約支給量_家事援助_月単位
      pay_months_agreed_housework_assistance: number;
      // 契約支給量_家事援助_回単位
      pay_times_agreed_housework_assistance: number;
      // 契約支給量_通院介助（伴ず）_月単位
      pay_months_agreed_outpatient_care: number;
      // 契約支給量_通院介助（伴ず）_回単位
      pay_times_agreed_outpatient_care: number;
      // 契約支給量_乗降介助_月単位
      pay_months_agreed_getting_on_and_off: number;
    }>;
  };
  doneUpsertUser: boolean;
  userValidation: Partial<{}>;
};
