import * as types from "./types";
import { GetOperations } from "@api/requests/operations/getCustomRecordOperations";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchOperationsStarted = () =>
  ({ type: types.FETCH_OPERATIONS_STARTED } as const);
export const fetchOperationsSuccess = (res: GetOperations["data"]) =>
  ({ type: types.FETCH_OPERATIONS_SUCCESS, payload: res } as const);
export const fetchOperationsFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_OPERATIONS_FAILED, error: err } as const);
export const unsetOperations = () =>
  ({ type: types.UNSET_OPERATIONS } as const);

export type ActionTypes =
  | ReturnType<typeof fetchOperationsStarted>
  | ReturnType<typeof fetchOperationsSuccess>
  | ReturnType<typeof fetchOperationsFailed>
  | ReturnType<typeof unsetOperations>;
