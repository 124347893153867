import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

const styles: StyleRules = createStyles({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: 118,
    height: 66,
    backgroundColor: "#eceff1",
    borderRadius: 4,
    lineHeight: 1,
    padding: "10px 12px",
    marginRight: 10
  },
  title: {
    fontSize: 13,
    color: "#37474F"
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    color: "#666"
  },
  item: {
    display: "flex",
    alignItems: "baseline"
  },
  num: {
    fontSize: 22,
    flex: 1,
    textAlign: "center"
  },
  hasError: {
    color: "#f44336"
  },
  unit: {
    fontSize: 14
  },
  ml8: {
    marginLeft: "8px"
  }
});

type OwnProps = {
  title: string;
  num: number;
  denom?: number;
  unit: string;
  key: string;
  // 2つ目が必要な場合に
  num2?: number;
  denom2?: number;
  unit2?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 主に累計などの表示に使うボックス
 * denom(分母)が渡された時、numがdenomを超えていたらエラーの色で表示する
 */
const RectangleBox = ({
  classes,
  title,
  num,
  denom,
  unit,
  key,
  num2,
  denom2,
  unit2
}: Props): JSX.Element => (
  <div className={classes.wrapper} key={key}>
    <div className={classes.title}>{title}</div>
    <div className={classes.content}>
      <div className={classes.item}>
        <div
          className={ClassNames(classes.num, {
            [classes.hasError]: denom && num > denom
          })}
        >
          {num}
        </div>
        <span className={classes.unit}>
          {denom && `/${denom}`}
          {unit}
        </span>
      </div>

      {/* ２つ目が必要な場合 */}
      {num2 !== undefined && (
        <div className={classes.item}>
          <div
            className={ClassNames(classes.ml8, classes.num, {
              [classes.hasError]: denom2 && num2 > denom2
            })}
          >
            {num2}
          </div>
          <span className={classes.unit}>
            {denom2 && `/${denom2}`}
            {unit2}
          </span>
        </div>
      )}
    </div>
  </div>
);

export default withStyles(styles)(RectangleBox);
