import * as React from "react";
import ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import format from "date-fns/format";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { InquiryState } from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { findOfficeName } from "@utils/domain/mgr/KEIKAKUSODAN/facility";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4,
      display: "flex",
      "&:nth-child(n+2)": {
        marginTop: 4
      },
      "&>span": {
        display: "block",
        minWidth: 88,
        marginLeft: 4
      }
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start"
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      height: 19,
      fontSize: 10,
      lineHeight: 1.4,
      color: "#212121",
      paddingBottom: 5,
      borderBottom: "solid 1px #000"
    },
    tableArea: {
      lineHeight: 1.4,
      fontSize: 10,
      color: "#212121"
    },
    why_not: {
      padding: "4px 6px",
      borderLeft: "solid 1px #212121",
      overflowWrap: "anywhere"
    },
    inquiryStatusGridContainer: {
      flexGrow: 1,
      display: "grid",
      width: "100%",
      border: "solid 2px #212121",
      gridTemplateColumns: "110px 1fr",
      "&> div": {
        padding: "4px 6px",
        "&:not(.lastRow)": {
          borderBottom: "1px solid #000"
        },
        "&:not(.lastColumn)": {
          borderRight: "1px solid #000"
        }
      }
    },
    tableItem: {
      display: "flex",
      justifyContent: "flex-start",
      overflowWrap: "anywhere"
    },
    inquiryStatuslastColumn: {
      borderRight: "solid 1px #212121"
    },
    whynotGridContainer: {
      flexGrow: 1,
      display: "grid",
      width: "100%",
      border: "solid 2px #212121",
      lineHeight: 1.4,
      fontSize: 10,
      color: "#212121",
      gridTemplateColumns: "195px 1fr",
      "&> div": {
        padding: "4px 6px"
      }
    },
    W448: {
      width: 448
    },
    W216: {
      width: 216
    },
    ML16: {
      marginLeft: 16
    },
    MT16: {
      marginTop: 16
    },
    MT24: {
      marginTop: 24
    }
  });

type InquiryTableProp = {
  value: {
    name_sei: string | undefined;
    name_mei: string | undefined;
    support_inquiries_id: number;
    creation_date: string;
    creation_staff: {
      id: number;
      name: string;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
      role: string;
      license: number;
      license_name: string;
    } | null;
    why_not: string;
    inquiry_purpose: string;
    inquiry_destination: string;
    inquiry_date: string;
    inquiry_content: string;
    respondent_name: string;
    response_date: string;
    response_content: string;
  };
  classes: Record<string, string>;
};

type OwnProps = {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  inquiry: InquiryState["inquiry"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

// 照会の状況
const InquiryStatus = (props: InquiryTableProp): JSX.Element => {
  const { classes, value } = props;

  if (Object.keys(value).every((x) => !x)) {
    return <div />;
  }

  return (
    <div>
      <div
        className={ClassNames(
          classes.tableArea,
          classes.MT16,
          classes.inquiryStatusGridContainer,
          classes.inquiryStatuslastColumn
        )}
      >
        <div className={ClassNames(classes.tableItem)}>
          <div>照会目的</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>{value.inquiry_purpose ? value.inquiry_purpose : ""}</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>照会（依頼）先</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>
            {value.inquiry_destination ? value.inquiry_destination : ""}
          </div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>照会（依頼）年月日</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>
            {value.inquiry_date
              ? format(value.inquiry_date, "YYYY年M月D日")
              : ""}
          </div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>照会（依頼）内容</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>{value.inquiry_content ? value.inquiry_content : ""}</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>回答者氏名</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>{value.respondent_name ? value.respondent_name : ""}</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>回答年月日</div>
        </div>
        <div className={ClassNames(classes.tableItem)}>
          <div>
            {value.response_date
              ? format(value.response_date, "YYYY年M月D日")
              : ""}
          </div>
        </div>
        <div className={ClassNames(classes.tableItem, "lastRow")}>
          <div>回答内容</div>
        </div>
        <div className={ClassNames(classes.tableItem, "lastRow")}>
          <div>{value.response_content ? value.response_content : ""}</div>
        </div>
      </div>
    </div>
  );
};

const InquiryFormPrintCore = (props: Props): JSX.Element => {
  const { classes, user, facility, inquiry } = props;

  // 計画作成日
  const targetDate = format(inquiry.creation_date, "YYYY年M月D日");

  // 作成者名
  const authorName = inquiry.creation_staff
    ? getSnapOrRealName(inquiry.creation_staff, "")
    : "";

  const userInFacility = user.user_in_facility;

  const officeName = findOfficeName(facility, inquiry.type_consultation);

  if (user.user_in_facility.name_sei === undefined) {
    return <></>;
  }

  return (
    <>
      {/* サービス担当者に対する照会（依頼）内容 */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>
            サービス担当者に対する照会（依頼）内容
          </h2>
          <div>
            <div className={classes.pageTitleTool}>
              作成日: <span>{targetDate}</span>
            </div>
          </div>
        </div>
        {/* 基本情報 */}
        <div>
          <div className={classes.userInfo}>
            <div className={ClassNames(classes.W216, classes.MT24)}>
              <span className={classes.userInfoItem}>
                サービス計画作成者（照会者）氏名
              </span>
              <div className={classes.userInfoValue}>
                <span>{authorName}</span>
              </div>
            </div>
            <div
              className={ClassNames(classes.W448, classes.ML16, classes.MT24)}
            >
              <span className={classes.userInfoItem}>相談支援事業者名</span>
              <div className={classes.userInfoValue}>
                <span>{officeName}</span>
              </div>
            </div>
          </div>
          <div className={ClassNames(classes.W216, classes.MT16)}>
            <span className={classes.userInfoItem}>利用者名 （フリガナ）</span>
            <div className={classes.userInfoValue}>
              <span>{`${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`}</span>
            </div>
          </div>
        </div>
        {!!inquiry.why_not && (
          <div
            className={ClassNames(classes.whynotGridContainer, classes.MT16)}
          >
            <div className={ClassNames(classes.table1colS, classes.tableItem)}>
              <div>
                サービス担当者会議を開催しない理由 ないし会議に出席できない理由
              </div>
            </div>
            <div className={ClassNames(classes.table2colE, classes.why_not)}>
              {inquiry.why_not}
            </div>
          </div>
        )}
        {/* 照会の状況 */}
        <InquiryStatus
          value={{
            ...inquiry,
            name_sei: userInFacility.name_sei,
            name_mei: userInFacility.name_mei
          }}
          classes={classes}
        />
      </div>
    </>
  );
};

export const InquiryFormPrint = withStyles(styles)(InquiryFormPrintCore);
