import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import RecordBatchPrinting from "@components/organisms/mgr/common/record/RecordBatchPrinting";
import { RecordEachUser } from "@components/organisms/mgr/common/record/recordEachUser/RecordEachUser";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { unsetUsersSummaryTANKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/usersSummary/actions";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { AppState } from "@stores/type";

import { RecordEachUserButtonsType } from "@constants/variables";
import { BASE_PUBLIC_URL } from "@config";

type StateProps = {
  users: UsersInFacilityState["users"];
};

type DispatchProps = {
  fetchUsers: () => Promise<void>;
  unsetUsersSummary: () => void;
};

type Props = StateProps & DispatchProps & RouteComponentProps;

/**
 * 記録/利用者ごとで表示させるボタンのオブジェクト
 * @param uifId url作成用のufidを返す
 */
const buttonList: React.ComponentProps<typeof RecordEachUser>["buttonList"] = (
  uifId: number
): RecordEachUserButtonsType => {
  return [
    {
      name: "支援記録",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support`,
      isLastButton: true
    }
  ];
};

/**
 * 利用者サマリ
 */
const RecordUsersSummaryCore = (props: Props): JSX.Element => {
  const { fetchUsers, users, history, unsetUsersSummary } = props;

  // 一括印刷のデフォルト項目上書き指定
  const options = [
    { label: "記録を選択", value: "" },
    { label: "支援記録", value: "supportB" }
  ];

  return (
    <AdminTemplate pageName="利用者ごと">
      {/* 記録の一括印刷 */}
      <RecordBatchPrinting
        history={history}
        defaultSelected="supportB"
        options={options}
        isUseCustomRecord
        unsetUsersSummary={unsetUsersSummary}
      />
      {/* 利用ごとの記録 */}
      <RecordEachUser
        users={users}
        buttonList={buttonList}
        fetchUsers={fetchUsers}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  users: state.TANKINYUSHO.userInFacility.users
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { TANKINYUSHO } = dispatches;
  const userInFacilityDispatcher = TANKINYUSHO.userInFacilityDispatcher(
    dispatch
  );
  return {
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch(),
    unsetUsersSummary: (): void => {
      dispatch(unsetUsersSummaryTANKINYUSHO());
    }
  };
};

export const RecordUsersSummary = connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordUsersSummaryCore);
