/**
 * number なら string に変換して null, undefined なら undefined を返す
 */
const castStringOrUndefined = (
  value: string | number | null | undefined
): string | undefined => {
  if (value === undefined || value === null) {
    return undefined;
  }
  return `${value}`;
};

export default castStringOrUndefined;
