import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportRecordUserResponse = {
  data: {
    support: {
      usage_results: {
        id: number;
        uif_id: number;
        target_date: string;
        support_method: number;
        support_method_other: string | null;
        support_start_time: string | null;
        support_end_time: string | null;
      };
      record: {
        id: number | null;
        usage_results_id: number;
        creation_date: string | null;
        company_name: string | null;
        todays_purpose: string | null;
        life_current_issues: string | null;
        life_support_content: string | null;
        office_current_issues: string | null;
        office_support_content: string | null;
        usage_status_of_related_organizations: string | null;
        notices: string | null;
        staff_comment: string | null;
        next_support_date: string | null;
        next_support_method: string | null;
        next_support_plans: string | null;
        author: {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        } | null;
      };
    }[];
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 利用者の支援記録のデータを取得する
 * @param uifId 事業所所属ユーザーのID
 * @param year 年
 * @param month 月(MM)
 */
const getSupportRecordUser = async (
  uifId: string,
  year: string,
  month: string
): Promise<AxiosResponse<GetSupportRecordUserResponse>> => {
  const url = `${VERSION_URL_201910}/support_records/users/${uifId}/${year}/${month}`;
  return request.get<GetSupportRecordUserResponse>(url);
};

export default getSupportRecordUser;
