import * as React from "react";
import { AdminTemplate201910 } from "@components/templates/AdminTemplate201910";
import FacilityForm from "@components/v201910/organisms/mgr/SHUROTEICHAKU/facility/FacilityForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import {
  INVOICE_VERSION_OPTIONS_123,
  INVOICE_VERSION_V201910
} from "@constants/variables";
import { INVOICE_PATHS } from "@constants/mgr/SHUROTEICHAKU/variables";

/**
 * 事業所情報
 */
const Facility = (): JSX.Element => (
  <AdminTemplate201910
    pageName="事業所情報"
    invoiceVersion={{
      currentVersion: INVOICE_VERSION_V201910,
      versionPaths: INVOICE_PATHS.facility,
      versionOptions: INVOICE_VERSION_OPTIONS_123
    }}
  >
    <FacilityForm />
    <NavigationTransitionPrompt />
  </AdminTemplate201910>
);

export default Facility;
