import * as React from "react";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import { CarePlanTable } from "@components/v202104/organisms/mgr/KODOENGO/Users/CarePlanTable";
import { UserState } from "@stores/domain/user/type";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import {
  InitialDataValues,
  initialValues
} from "@initialize/v202104/mgr/KODOENGO/carePlan/initialValues";
import { CarePlanDialog } from "@components/v202104/organisms/mgr/KODOENGO/Users/CarePlanDialog";
import {
  CarePlanStaffState,
  CarePlanState,
  DeleteCarePlanState
} from "@stores/v202104/domain/mgr/KODOENGO/carePlan/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KODOENGO/userInFacility/types";
import ConfirmDialog from "@components/atoms/ConfirmDialog";

type OwnProps = {
  uifId: number;
  nameSei: string;
  nameMei: string;
  hideAddButton: boolean;
};

type StateProps = {
  user: UserState;
  carePlanStaffState: CarePlanStaffState;
  usersInFacilityState: UsersInFacilityState;
};

type DispatchProps = {
  fetchCarePlan: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  deleteCarePlan: (target: DeleteCarePlanState) => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps;

type State = {
  data: InitialDataValues;
  isOpenDetailModal: boolean;
  isOpenDeleteDialog: boolean;
  deleteTarget: DeleteCarePlanState | null;
};

/**
 * サービス予定情報
 */
class CarePlanCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: initialValues(),
      isOpenDetailModal: false,
      isOpenDeleteDialog: false,
      deleteTarget: null
    };
  }

  private openModal = (param: CarePlanState): void => {
    this.setState({ data: initialValues(param), isOpenDetailModal: true });
  };

  private openCreateModal = (
    dayOfWeek: number,
    uifName: string,
    uifId: number
  ): void => {
    // 新規作成時は、氏名、曜日を親から取得してセットする必要がある
    const init = initialValues();
    init.initial.name = uifName;
    init.initial.usersInFacilityId = uifId.toString();
    init.initial.dayOfWeek = dayOfWeek;

    this.setState({ data: init, isOpenDetailModal: true });
  };

  private onCancel = (): void => {
    this.setState({ isOpenDetailModal: false });
  };

  private onSubmit = (): void => {
    this.props.fetchCarePlan(this.props.uifId.toString());
  };

  private onClickDeleteIcon = (target: DeleteCarePlanState): void => {
    this.setState({ deleteTarget: target, isOpenDeleteDialog: true });
  };

  private onDelete = async (): Promise<void> => {
    if (this.state.deleteTarget != null) {
      await this.props.deleteCarePlan(this.state.deleteTarget);
      this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
    }

    this.props.fetchCarePlan(this.props.uifId.toString());
  };

  private onCancelDelete = (): void => {
    this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
  };

  public render(): JSX.Element {
    const { uifId, nameSei, nameMei } = this.props;

    return (
      <>
        <Paper elevation={0}>
          <CarePlanTable
            uifId={uifId}
            nameSei={nameSei}
            nameMei={nameMei}
            openModal={this.openModal}
            openCreateModal={this.openCreateModal}
            onClickDeleteIcon={this.onClickDeleteIcon}
            hideAddButton={this.props.hideAddButton}
          />
        </Paper>
        <CarePlanDialog
          open={this.state.isOpenDetailModal}
          usersInFacilityState={this.props.usersInFacilityState}
          data={this.state.data}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDeleteDialog}
          onDelete={this.onDelete}
          onCancel={this.onCancelDelete}
          title=""
          message="サービス予定を削除しますか?"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    carePlanStaffState: state.v202104.KODOENGO.carePlan,
    usersInFacilityState: state.v202104.KODOENGO.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const carePlanDispatcher = v202104.KODOENGO.carePlanDispatcher(dispatch);
  return {
    fetchCarePlan: (uifId: string): Promise<void> =>
      carePlanDispatcher.fetchCarePlan(uifId),
    openErrorsDialog: (): {
      readonly type: "UI/SHOW_ERRORS_DIALOG";
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    deleteCarePlan: (target: DeleteCarePlanState): Promise<void> =>
      carePlanDispatcher.deleteKODOENGOCarePlan(target)
  };
};

export const CarePlan = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarePlanCore);
