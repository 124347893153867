import {
  ReportState,
  InoutResultsState,
  InoutResultsDetailsState,
  DisplayListState,
  DataListState,
  DeleteReportState,
  CopyReportState
} from "@stores/domain/mgr/KODOENGO/report/types";
import {
  GetInOutResultsDailyResponse,
  InoutResultsItem,
  InoutResultsDetailsItem,
  InoutResultsDataList
} from "@api/requests/inOutResults/getInOutResultsDaily";
import { GetInOutResultsUsersResponse } from "@api/requests/inOutResults/getInOutResultsMonthly";
import { RequestParamToPostAPI } from "@api/requests/inOutResults/postInOutResults";
import { RequestParamToDeleteAPI } from "@api/requests/inOutResults/deleteInOutResults";
import {
  InitialValues,
  InoutResultsDetailsFields
} from "@interfaces/mgr/KODOENGO/report/initial";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  DEFAULT_CHECKBOX_VALUE
} from "@constants/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import {
  convertTimeHHMM,
  dateToLocalisedString,
  parseDateString
} from "@utils/date";
import { RequestParamToPostCopyAPI } from "@api/requests/inOutResults/postInOutResultsCopy";
import { FacilityState } from "@stores/domain/mgr/KODOENGO/facility/types";
import { cloneDeep } from "lodash-es";

type InitBaseInfo = {
  uifId: number;
  sei: string;
  mei: string;
  date: string;
};

const nullDataByParticipants = (
  participants: number,
  license_same_flg: number | null | undefined
): string[] => {
  switch (participants) {
    case 1:
      return [
        "license_same_flg",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_memo",
        "inout_results_details2",
        "whole_calculated_hours",
        "duplicate_calculated_hours"
      ];
    case 2:
      if (license_same_flg) {
        return [
          "second_person_flg",
          "practitioner1_calculated_hours",
          "practitioner2_in_time",
          "practitioner2_out_time",
          "practitioner2_memo",
          "inout_results_details2"
        ];
      }
      return ["second_person_flg", "practitioner1_calculated_hours"];
    default:
      return [];
  }
};

const nullDataByInputClass = (inputClass: number): string[] => {
  switch (inputClass) {
    case 1:
      return ["emergency_support_flg", "behavior_disorder_coaching_flg"];
    default:
      return [];
  }
};

const nullDataByFacility = (facility: FacilityState): string[] => {
  const target = [];
  // 「特定事業所加算＝（Ⅰ）」を選択しているときは、「喀痰吸引等実施」は非活性
  if (facility.specificFacilitiesAddition === "1") {
    target.push("sputum_implementation_flg");
  }

  return target;
};

const normalizeInoutResultsDetail = (
  list: InoutResultsDetailsItem[]
): InoutResultsDetailsState[] => {
  return list && list.length > 0
    ? list.map((record) => {
        return {
          id: record.id,
          actionClass: record.action_class,
          inTime: convertTimeHHMM(parseDateString(record.in_time)),
          outTime: convertTimeHHMM(parseDateString(record.out_time))
        };
      })
    : [];
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  practitioner1_in_time: {
    requestKey: "practitioner1_in_time",
    cooperationKeys: [
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "inout_results_details1"
    ]
  },
  practitioner1_out_time: {
    requestKey: "practitioner1_out_time",
    cooperationKeys: [
      "practitioner1_in_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "inout_results_details1"
    ]
  },
  practitioner2_in_time: {
    requestKey: "practitioner2_in_time",
    cooperationKeys: [
      "practitioner2_out_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "inout_results_details2"
    ]
  },
  practitioner2_out_time: {
    requestKey: "practitioner2_out_time",
    cooperationKeys: [
      "practitioner2_in_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "inout_results_details2"
    ]
  },
  practitioner1_calculated_hours: {
    requestKey: "practitioner1_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  whole_calculated_hours: {
    requestKey: "whole_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  duplicate_calculated_hours: {
    requestKey: "duplicate_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  time_duplication_judgment_flg: {
    requestKey: "time_duplication_judgment_flg",
    cooperationKeys: [
      "practitioner1_calculated_hours",
      "whole_calculated_hours",
      "duplicate_calculated_hours"
    ]
  }
};

/**
 * yyyy-mm-dd HH:MM:SSの作成
 */
const createTime = (date?: string, time?: string): string => {
  let returnDate = "";
  if (date && time) {
    returnDate =
      dateToLocalisedString(date, "YYYY-MM-DD").concat(" ") +
      time.concat(":00");
  }

  return returnDate;
};

export const normalizeDetailData = (
  inputClass: number,
  inoutResultsId: number | null,
  inoutResultsList: InoutResultsItem[],
  baseInfo: InitBaseInfo,
  inoutResultsPlanId: number | null = null,
  resultFlg = false
): InoutResultsState => {
  const targetRecord = inoutResultsList
    ? inoutResultsList.filter((row) => {
        return (
          row.id === inoutResultsId &&
          row.users_in_facility_id === baseInfo.uifId &&
          row.input_class === inputClass
        );
      })
    : [];

  const record =
    inoutResultsId && targetRecord.length === 1 ? targetRecord[0] : null;
  return {
    id: record ? record.id : null,
    inoutResultDailyId:
      record && record.inout_result_daily_id
        ? record.inout_result_daily_id
        : null,
    usersInFacilityId: record ? record.users_in_facility_id : baseInfo.uifId,
    nameSei: record ? record.name_sei : baseInfo.sei,
    nameMei: record ? record.name_mei : baseInfo.mei,
    targetDate:
      record && record.target_date ? record.target_date : baseInfo.date,
    inputClass: record ? record.input_class : inputClass,
    inoutResultsPlanId: record
      ? record.inout_results_plan_id
      : inoutResultsPlanId,
    numberOfParticipants: record ? record.number_of_participants : null,
    practitioner1InTime:
      record && record.practitioner1_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_in_time))
        : "",
    practitioner1OutTime:
      record && record.practitioner1_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_out_time))
        : "",
    practitioner1CalculatedHours: record
      ? record.practitioner1_calculated_hours
      : null,
    practitioner1Memo: record ? record.practitioner1_memo : "",
    inoutResultsDetails1: record
      ? normalizeInoutResultsDetail(record.inout_results_details1)
      : [],
    practitioner2InTime:
      record && record.practitioner2_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_in_time))
        : "",
    practitioner2OutTime:
      record && record.practitioner2_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_out_time))
        : "",
    practitioner2Memo: record ? record.practitioner2_memo : "",
    inoutResultsDetails2: record
      ? normalizeInoutResultsDetail(record.inout_results_details2)
      : [],
    timeDuplicationJudgmentFlg: record
      ? record.time_duplication_judgment_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    wholeCalculatedHours: record ? record.whole_calculated_hours : null,
    duplicateCalculatedHours: record ? record.duplicate_calculated_hours : null,
    licenseSameFlg: record
      ? record.license_same_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    secondPersonFlg: record
      ? record.second_person_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    emergencySupportFlg: record
      ? record.emergency_support_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    firstAdditionFlg: record
      ? record.first_addition_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    sputumImplementationFlg: record
      ? record.sputum_implementation_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    behaviorDisorderCoachingFlg: record
      ? record.behavior_disorder_coaching_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    resultExistFlg: resultFlg
  };
};

export const initialDataList = (
  record: InoutResultsDataList | null,
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  baseInfo: InitBaseInfo
): DataListState => {
  const planId = record && record.plan ? record.plan.inout_results_id : null;
  const resultId =
    record && record.plan ? record.result.inout_results_id : null;

  // 計画に紐づく実績が存在するか（段ずれ表示を考慮して、inout_resultsを探して判定）
  const resultFlg =
    planId && inoutResults
      ? inoutResults.filter((row) => {
          return (
            row.inout_results_plan_id === planId &&
            row.users_in_facility_id === baseInfo.uifId &&
            row.input_class === 2
          );
        }).length > 0
      : false;

  return {
    no: record ? record.no : null,
    resultExistFlg: resultFlg,
    plan: {
      inoutResultsId: planId,
      dispatchNo: record && record.plan ? record.plan.dispatch_no : null,
      inTime: record && record.plan ? record.plan.in_time : null,
      outTime: record && record.plan ? record.plan.out_time : null,
      calculatedHours:
        record && record.plan ? record.plan.calculated_hours : null,
      numberOfParticipants:
        record && record.plan ? record.plan.number_of_participants : null
    },
    result: {
      inoutResultsId: resultId,
      dispatchNo: record && record.result ? record.result.dispatch_no : null,
      inTime: record && record.result ? record.result.in_time : null,
      outTime: record ? record.result.out_time : null,
      calculatedHours:
        record && record.result ? record.result.calculated_hours : null,
      numberOfParticipants:
        record && record.result ? record.result.number_of_participants : null,
      emergencySupportFlg:
        record && record.result ? record.result.emergency_support_flg : null
    },
    planDetail: normalizeDetailData(
      1,
      planId,
      inoutResults,
      baseInfo,
      null,
      resultFlg
    ),
    resultDetail: normalizeDetailData(
      2,
      resultId,
      inoutResults,
      baseInfo,
      planId
    )
  };
};

export const normalizeDataListFromAPI = (
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  displayList: InoutResultsDataList[],
  baseInfo: InitBaseInfo
): DataListState[] => {
  return displayList && displayList.length > 0
    ? displayList.map((record) => {
        return initialDataList(record, inoutResults, baseInfo);
      })
    : [initialDataList(null, inoutResults, baseInfo)];
};

/**
 * 実績データ一覧の整形
 */
export const normalizeDisplayListFromAPI = (
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  displayList: GetInOutResultsUsersResponse["data"]["display_list"],
  date = ""
): DisplayListState[] => {
  return displayList
    ? displayList.map((record) => {
        const targetDateState = record.target_date ? record.target_date : date;

        const baseInfo: InitBaseInfo = {
          uifId: record.users_in_facility_id,
          sei: record.name_sei,
          mei: record.name_mei,
          date: targetDateState
        };

        return {
          usersInFacilityId: record.users_in_facility_id,
          nameSei: record.name_sei,
          nameMei: record.name_mei,
          isHoliday: record.isHoliday ? record.isHoliday : false,
          targetDate: targetDateState,
          dataList: normalizeDataListFromAPI(
            inoutResults,
            record.data_list,
            baseInfo
          )
        };
      })
    : [];
};

export const normalizeDaily = (
  daily: GetInOutResultsDailyResponse["data"]["inout_result_daily"]
): ReportState["reportDaily"]["inoutResultDaily"] => {
  return {
    targetDate: daily.target_date
  };
};

export const normalizeSummary = (
  summary: GetInOutResultsUsersResponse["data"]["summary"]
): ReportState["reportMonthly"]["summary"] => {
  return {
    serviceHoursCount: summary.serviceHoursCount
  };
};

/**
 * 利用実績一覧(日ごと)
 */
export const normalizeKODOENGODailyReportDataFromAPI = (
  result: GetInOutResultsDailyResponse,
  date: string
): ReportState["reportDaily"] => {
  const normalizeReportList: ReportState["reportDaily"] = {
    inoutResultDaily: normalizeDaily(result.data.inout_result_daily),
    displayList: normalizeDisplayListFromAPI(
      result.data.inout_results,
      result.data.display_list,
      date
    ),
    planCount: result.data.inout_results
      ? result.data.inout_results.filter((record) => record.input_class === 1)
          .length
      : 0
  };

  return normalizeReportList;
};

/**
 * 利用実績一覧(ユーザごと)
 */
export const normalizeKODOENGOUserReportDataFromAPI = (
  result: GetInOutResultsUsersResponse
): ReportState["reportMonthly"] => {
  const normalizeReportList: ReportState["reportMonthly"] = {
    summary: normalizeSummary(result.data.summary),
    displayList: normalizeDisplayListFromAPI(
      result.data.inout_results,
      result.data.display_list
    ),
    planCount: result.data.inout_results
      ? result.data.inout_results.filter((record) => record.input_class === 1)
          .length
      : 0
  };

  return normalizeReportList;
};

const normalizeInoutResultsDetailToAPI = (
  detailList: InoutResultsDetailsFields[],
  date: string
): InoutResultsDetailsItem[] => {
  if (!detailList || detailList.length === 0) {
    return [];
  }

  const result = detailList.map((record) => {
    return {
      id: record.id,
      action_class:
        record.inTime && record.outTime ? castNumber(record.actionClass) : -1,
      in_time: createTime(date, record.inTime),
      out_time: createTime(date, record.outTime)
    };
  });

  return result.filter((record) => {
    return record.action_class !== -1;
  });
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

/**
 * 明細の差分有無確認
 */
const normalizeDiffDetailParamToPostAPI = (
  before: InoutResultsDetailsItem[] | undefined,
  after: InoutResultsDetailsItem[] | undefined
): boolean => {
  if (before === undefined || after === undefined) {
    return false;
  }
  if (before.length === 0 && after.length === 0) {
    return false;
  }

  return true;
};

/**
 * 利用実績登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    yyyymmdd: dateToLocalisedString(formValue.initial.targetDate, "YYYYMMDD"),
    uif_id: formValue.initial.usersInFacilityId,
    params: {
      inout_results_id: formValue.initial.id,
      inout_result_daily_id: formValue.initial.inoutResultDailyId,
      target_date: formValue.initial.targetDate,
      input_class: castNumber(formValue.initial.inputClass),
      inout_results_plan_id: formValue.initial.inoutResultsPlanId,
      number_of_participants: castNumber(
        formValue.initial.numberOfParticipants
      ),
      practitioner1_in_time: createTime(
        formValue.initial.targetDate,
        formValue.initial.practitioner1InTime
      ),
      practitioner1_out_time: createTime(
        formValue.initial.targetDate,
        formValue.initial.practitioner1OutTime
      ),
      practitioner1_calculated_hours:
        formValue.initial.numberOfParticipants === "1"
          ? castNumber(formValue.initial.practitioner1CalculatedHours)
          : null,
      practitioner1_memo: formValue.initial.practitioner1Memo,
      inout_results_details1: normalizeInoutResultsDetailToAPI(
        formValue.initial.inoutResultsDetails1,
        formValue.initial.targetDate
      ),
      practitioner2_in_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(
              formValue.initial.targetDate,
              formValue.initial.practitioner2InTime
            )
          : null,
      practitioner2_out_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(
              formValue.initial.targetDate,
              formValue.initial.practitioner2OutTime
            )
          : null,
      practitioner2_memo:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? formValue.initial.practitioner2Memo
          : null,
      inout_results_details2:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? normalizeInoutResultsDetailToAPI(
              formValue.initial.inoutResultsDetails2,
              formValue.initial.targetDate
            )
          : [],
      time_duplication_judgment_flg:
        formValue.initial.numberOfParticipants === "2"
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
      whole_calculated_hours:
        formValue.initial.numberOfParticipants === "2"
          ? castNumber(formValue.initial.wholeCalculatedHours)
          : null,
      duplicate_calculated_hours:
        formValue.initial.numberOfParticipants === "2"
          ? castNumber(formValue.initial.duplicateCalculatedHours)
          : null,
      license_same_flg: formValue.initial.licenseSameFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      second_person_flg: formValue.initial.secondPersonFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      emergency_support_flg: formValue.initial.emergencySupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      first_addition_flg: formValue.initial.firstAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      sputum_implementation_flg: formValue.initial.sputumImplementationFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      behavior_disorder_coaching_flg: formValue.initial
        .behaviorDisorderCoachingFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  };

  if (formValue.initial.numberOfParticipants === "1") {
    result.params.license_same_flg = null;
  }
  if (formValue.initial.numberOfParticipants === "2") {
    result.params.second_person_flg = null;
  }
  if (formValue.initial.inputClass === "1") {
    result.params.behavior_disorder_coaching_flg = null;
  }

  return result;
};

export const normalizeDiffParamToPostAPI = (
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI,
  facility: FacilityState
): RequestParamToPostAPI => {
  const nullTargetList = nullDataByParticipants(
    after.params.number_of_participants
      ? after.params.number_of_participants
      : 1,
    after.params.license_same_flg
  )
    .concat(
      nullDataByInputClass(
        after.params.input_class ? after.params.input_class : 1
      )
    )
    .concat(nullDataByFacility(facility));

  const result: RequestParamToPostAPI = {
    uif_id: after.uif_id,
    yyyymmdd: after.yyyymmdd,
    params: {
      inout_result_daily_id: after.params.inout_result_daily_id,
      inout_results_id: after.params.inout_results_id,
      inout_results_plan_id: after.params.inout_results_plan_id,
      target_date: after.params.target_date,
      input_class: after.params.input_class,
      time_duplication_judgment_flg: after.params.time_duplication_judgment_flg
    }
  };

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    const nullFlg = nullTargetList.includes(key);
    const detailFlg =
      key === "inout_results_details1" || key === "inout_results_details2";
    const nullValue = detailFlg ? [] : null;
    const diffFlg = !detailFlg
      ? before.params[key] !== after.params[key]
      : normalizeDiffDetailParamToPostAPI(
          before.params[key],
          after.params[key]
        );
    if (after.params.inout_results_id === null) {
      // 初回の場合、null対象はnull、それ以外は入力値をセット
      result.params[key] = nullFlg ? nullValue : after.params[key];
    } else if (diffFlg) {
      result.params[key] = after.params[key];
    }
  });

  const diffDate = addRelationValue(result, after);

  let nullTarget = nullDataByInputClass(
    after.params.input_class ? after.params.input_class : 1
  ).concat(nullDataByFacility(facility));

  if (diffDate.params.number_of_participants) {
    nullTarget = nullTarget.concat(
      nullDataByParticipants(
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg
      )
    );
  }

  nullTarget.forEach((key) => {
    const detailFlg =
      key === "inout_results_details1" || key === "inout_results_details2";
    const nullValue = detailFlg ? [] : null;
    diffDate.params[key] = nullValue;
  });

  return diffDate;
};

/**
 * 実績削除
 */
export const normalizeRequestParamToDeleteAPI = (
  target: DeleteReportState
): RequestParamToDeleteAPI => {
  return {
    data: {
      target: [
        {
          uif_id: target.uifId,
          yyyymmdd: target.targetDate,
          inout_results_id: target.inoutResultsId
        }
      ]
    }
  };
};

export const normalizeRequestParamToPostCopyAPI = (
  processType: number,
  target: CopyReportState[]
): RequestParamToPostCopyAPI => {
  return {
    process_type: processType,
    target: target.map((record) => {
      const data = {
        uif_id: record.uifId,
        yyyymmdd: record.targetDate
      };
      if (record.inoutResultsId) {
        const key = "inout_results_id";
        data[key] = record.inoutResultsId;
      }
      return data;
    })
  };
};

export const normalizeRequestParamToPostCopyCarePlanAPI = (
  processType: number,
  target: CopyReportState[]
): RequestParamToPostCopyAPI => {
  return {
    process_type: processType,
    target: target.map((record) => {
      return {
        uif_id: record.uifId,
        yyyymmdd: record.targetDate
      };
    })
  };
};
