import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate202104 } from "@components/templates/AdminTemplate202104";
import { InOutReportDaily } from "@components/v202104/organisms/mgr/DOKOENGO/report/InOutReportDaily";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  REPORT_TABS_INFO_PARAM,
  REPORT_TABS_INFO_LiST,
  INVOICE_PATHS
} from "@constants/mgr/DOKOENGO/variables";
import * as URL from "@constants/url";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { FacilityType, INVOICE_VERSION_V202104 } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

type Props = RouteComponentProps<{}, {}, { targetDate: Date }>;

/**
 * 利用実績
 */
export const ReportDaily202104 = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();
  // 報酬改定のバージョン変更が必要だったら変更後のページを表示
  const currentPageVersion = INVOICE_VERSION_V202104;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.DOKOENGO,
    currentPageVersion,
    INVOICE_PATHS.reportDaily,
    props.history
  );

  if (transitionDone) {
    return null;
  }
  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.USERS) {
      props.history.push(URL.REPORT_USERS);
    }
  };
  return (
    <AdminTemplate202104 pageName="利用実績" zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={REPORT_TABS_INFO_LiST}
        onChange={onChangeTag}
        value={REPORT_TABS_INFO_PARAM.DAILY}
      />
      <InOutReportDaily
        initialDate={currentDate}
        history={props.history}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate202104>
  );
};
