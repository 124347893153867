import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FormGroup } from "@material-ui/core";
import { FormikProps } from "formik";
import { FacilityType, SCORE_LIST_202404 } from "@constants/variables";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiTextField from "@components/molecules/MuiTextField";
import {
  AverageMonthlyWageType,
  AverageWorkDurationsType202404,
  DIVERSIFIED_WORK_STYLES_TYPE_202404,
  IMPROVEMENT_OF_SUPPORTS_TYPE_202404,
  INITIATIVES_TO_IMPROVE_ABILITIES_TYPE,
  MANAGEMENT_IMPROVEMENT_PLAN_TYPE,
  ProductionActivitiesType202404,
  RegionalCooperationActivitiesType,
  REWARD_TYPE_ITEMS
} from "@constants/mgr/IAB/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import { generateRadioItems } from "@utils/dataNormalizer";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { FacilityValues } from "@initialize/mgr/IAB/facility/initialValues";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    },
    section: {
      marginBottom: 32
    }
  });

const AverageWorkDurationRadioItems = generateRadioItems(
  AverageWorkDurationsType202404
);

const ProductionActivityRadioItems = generateRadioItems(
  ProductionActivitiesType202404
);

const RegionalCooperationActivityRadioItems = generateRadioItems(
  RegionalCooperationActivitiesType
);

const AverageMonthlyWageRadioItems = generateRadioItems(AverageMonthlyWageType);
type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const BasicRewardItemFields = (props: Props): JSX.Element => {
  const getTotalScore = (): number => {
    return (
      SCORE_LIST_202404.averageWorkDuration[
        castNumber(props.formikProps.values.basicRewardItem.averageWorkDuration)
      ] +
      SCORE_LIST_202404.productionActivity[
        castNumber(props.formikProps.values.basicRewardItem.productionActivity)
      ] +
      SCORE_LIST_202404.diversifiedWorkStyle[
        castNumber(
          props.formikProps.values.basicRewardItem.diversifiedWorkStyle
        )
      ] +
      SCORE_LIST_202404.improvementOfSupport[
        castNumber(
          props.formikProps.values.basicRewardItem.improvementOfSupport
        )
      ] +
      SCORE_LIST_202404.regionalCooperationActivity[
        castNumber(
          props.formikProps.values.basicRewardItem.regionalCooperationActivity
        )
      ] +
      SCORE_LIST_202404.managementImprovementPlan[
        castNumber(
          props.formikProps.values.basicRewardItem.managementImprovementPlan
        )
      ] +
      SCORE_LIST_202404.initiativesToImproveAbilities[
        castNumber(
          props.formikProps.values.basicRewardItem.initiativesToImproveAbilities
        )
      ]
    );
  };

  return (
    <FormPaper>
      {(props.formikProps.values.basic.serviceType === FacilityType.A ||
        props.formikProps.values.basic.serviceType === FacilityType.B) && (
        <div className={props.classes.section}>
          <SectionTitle label="基本報酬" />
        </div>
      )}
      {props.formikProps.values.basic.serviceType === FacilityType.A && (
        <FormGroup row={false}>
          <FormikRadioButtons
            name="basicRewardItem.averageWorkDuration"
            label="利用者の1日平均労働時間"
            options={AverageWorkDurationRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="averageWorkDuration" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.productionActivity"
            label="生産活動"
            options={ProductionActivityRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="productionActivity202404" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.diversifiedWorkStyle"
            label="多様な働き方"
            options={DIVERSIFIED_WORK_STYLES_TYPE_202404}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="diversifiedWorkStyle202404" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.improvementOfSupport"
            label="支援力向上"
            options={IMPROVEMENT_OF_SUPPORTS_TYPE_202404}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="improvementOfSupport202404" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.regionalCooperationActivity"
            label="地域連携活動"
            options={RegionalCooperationActivityRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="regionalCooperationActivity" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.managementImprovementPlan"
            label="経営改善計画"
            options={MANAGEMENT_IMPROVEMENT_PLAN_TYPE}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="managementImprovementPlan" />}
              />
            }
          />
          <FormikRadioButtons
            name="basicRewardItem.initiativesToImproveAbilities"
            label="利用者の知識・能力の向上"
            options={INITIATIVES_TO_IMPROVE_ABILITIES_TYPE}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="initiativesToImproveAbilities" />}
              />
            }
          />
          <MuiTextField
            value={getTotalScore()}
            label="基本報酬 スコア合計点"
            endAdornmentLabel="点/200点"
            disabled
            disabledStyle
          />
        </FormGroup>
      )}
      {props.formikProps.values.basic.serviceType === FacilityType.B && (
        <FormGroup row={false}>
          <FormikRadioButtons
            name="basicRewardItem.rewardType"
            label="報酬体系"
            options={REWARD_TYPE_ITEMS}
          />
          <FormikRadioButtons
            name="basicRewardItem.averageMonthlyWage"
            label="平均工賃の月額"
            disabled={
              props.formikProps.values.basicRewardItem.rewardType ===
              REWARD_TYPE_ITEMS[1].value
            }
            options={AverageMonthlyWageRadioItems}
          />
        </FormGroup>
      )}
    </FormPaper>
  );
};

export default withStyles(styles)(BasicRewardItemFields);
