import * as React from "react";
import * as H from "history";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import MuiSelect from "@components/molecules/MuiSelect";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { SupportProcedureModalTable } from "@components/organisms/mgr/KODOENGO/dialog/SupportProcedureModalTable";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";

import orderBy from "lodash-es/orderBy";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { SelectDateValue } from "@interfaces/ui/form";
import { isDateWithin } from "@utils/date/isDateWithin";
import {
  dateInHyphenYYYYMMDDFormat,
  dateToSelectDateValue,
  getWarekiList
} from "@utils/date";
import { SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE } from "@constants/mgr/KODOENGO/variables";

const styles = (): StyleRules =>
  createStyles({
    modal: {
      "&>div:nth-child(2)>div": {
        maxWidth: "820px",
        width: "100%"
      }
    },
    title: {
      padding: "16px 32px",
      background: "#f5f5f5",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    content: {
      paddingTop: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 24
    },
    actions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    targetDateWrapper: {
      display: "flex",
      paddingTop: 24,
      paddingLeft: 32
    },
    border: {
      borderBottom: "1px solid #cfd8dc"
    },
    checkboxWrapper: {
      paddingTop: 24,
      paddingLeft: 32
    },
    noRecord: {
      fontSize: "16px",
      paddingTop: "80px",
      paddingBottom: "49px",
      textAlign: "center",
      lineHeight: "28px"
    }
  });

type OwnProps = {
  isOpen: boolean;
  uifId: string;
  dateBeginInService: string;
  dateEndInService: string | undefined;
  history: H.History;
  onClose: () => void;
} & WithStyles<typeof styles>;

type StateProps = {
  supportProcedureList: SupportProcedureState["supportProcedureList"];
};

type DispatchProps = {
  fetchModalSupportProcedureList: (uifId: string, date: string) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

type CheckedIds = { id: string; statusFlg: string };

const SupportProcedurePrintModalCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const {
    isOpen,
    uifId,
    dateBeginInService,
    dateEndInService,
    onClose
  } = props;
  const { supportProcedureList } = props;
  const { fetchModalSupportProcedureList } = props;

  const today = dateInHyphenYYYYMMDDFormat(new Date());
  const { year, month } = dateToSelectDateValue(today);

  // 対象年月
  const [targetDate, setTargetDate] = React.useState<
    Pick<SelectDateValue, "year" | "month">
  >({ year, month });

  // 選択した手順書のIndexと手順書作成済みフラグ
  const [checkedIds, setCheckedIds] = React.useState<CheckedIds[]>([]);

  const [isNoRecord, setIsNoRecord] = React.useState(false);
  const [isCheckedAll, setIsCheckedAll] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);

  // 対象年月の選択肢（年)
  const yearOptions = React.useMemo(() => {
    return getWarekiList(1989, Number(year) + 10).map(({ label, value }) => ({
      label,
      value: `${value}`
    }));
  }, []);

  // 対象年月の選択肢（月)
  const monthOptions = React.useMemo(() => {
    return createOneToNumberOptions(12, "月", false);
  }, []);

  // API Response変更時処理
  const supportProcedureRows = React.useMemo(() => {
    setCheckedIds([]);
    setIsCheckedAll(false);

    // 印刷モーダルでは作成済みの手順書のみを一覧に表示する
    const supportProcedureForms = orderBy(
      supportProcedureList.support_procedure_forms,
      "target_date",
      "asc"
    ).filter(({ procedure_status_flg }) => procedure_status_flg !== 0);

    setIsNoRecord(supportProcedureForms.length === 0);

    return supportProcedureForms;
  }, [supportProcedureList]);

  // 日付が設定されている手順書のIndexと手順書作成済みフラグ
  const baseIds = React.useMemo((): CheckedIds[] => {
    return supportProcedureRows.reduce((acc, { target_date }, index) => {
      return target_date !== null
        ? [...acc, { id: `${index}`, statusFlg: "" } as CheckedIds] // 印刷モーダルでstatusFlgは使用しないので空文字
        : [...acc];
    }, [] as CheckedIds[]);
  }, [supportProcedureRows]);

  const errorMessage = React.useMemo(() => {
    return hasError ? SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE : "";
  }, [hasError]);

  // 対象年月の変更時処理
  React.useEffect(() => {
    const date = `${targetDate.year}${targetDate.month.padStart(2, "0")}`;
    fetchModalSupportProcedureList(uifId, date);
  }, [targetDate]);

  // 日付の設定された手順書のチェックボックス全選択した状態 or 何も選択していない状態と、「すべての日時を選択」の状態を連動させる
  React.useEffect(() => {
    if (baseIds.length !== 0) {
      const checkedIdsSet = new Set(checkedIds.map((v) => v.id));
      setIsCheckedAll(baseIds.every((v) => checkedIdsSet.has(v.id)));
    }
  }, [checkedIds]);

  // チェックボックス「すべての日時を選択」の変更時処理
  const handleCheckAllChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // 日時が設定されていない手順書のチェックボックスには影響を与えないようにする
    const noDateCheckedIds = checkedIds.filter(
      (v) => !baseIds.some((ids) => ids.id === v.id)
    );
    if (e.target.checked) {
      setCheckedIds([...baseIds, ...noDateCheckedIds]);
    } else {
      setCheckedIds([...noDateCheckedIds]);
    }
    setIsCheckedAll(!e.target.checked);
  };

  // 手順書ごとのチェックボックス単体の変更時処理
  const handleChangeCheckbox = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      if (checked) {
        setCheckedIds((prev) => [...prev, { id, statusFlg: "" }]); // 印刷モーダルでstatusFlgは使用しないので空文字
      } else {
        setCheckedIds((prev) => prev.filter((v) => v.id !== id));
      }
    },
    []
  );

  // 対象年月のバリデーション処理
  const validation = React.useCallback((): void => {
    const dateString = `${targetDate.year}-${targetDate.month.padStart(
      2,
      "0"
    )}-1`;
    const isError = !isDateWithin(
      new Date(dateString),
      dateBeginInService,
      dateEndInService
    );
    setHasError(isError);
  }, [targetDate]);

  // 「確定する」ボタン押下時の処理
  const onClickFix = (): void => {
    if (checkedIds.length === 0) return;
    const selectedRows = supportProcedureRows.filter((_, index) =>
      checkedIds.some(({ id }) => Number(id) === index)
    );
    const printTargetIds = selectedRows.map(
      (v) => v.support_procedure_forms_id
    );
    const search = `target_id=${printTargetIds.join(",")}&uifId=${uifId}`;
    props.history.push({ pathname: "/record/support_procedure/print", search });
  };

  return (
    <Dialog className={classes.modal} maxWidth="md" open={isOpen}>
      <DialogTitle className={classes.title}>支援手順書 印刷</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={`${classes.targetDateWrapper} ${classes.border}`}>
          <MuiSelect
            name="targetYear"
            label="対象年月"
            size="medium"
            value={targetDate.year}
            options={yearOptions}
            onChange={(e): void => {
              setTargetDate((prev) => ({
                ...prev,
                year: e.target.value
              }));
            }}
            onBlur={validation}
            error={hasError}
            helperText={errorMessage}
          />
          <MuiSelect
            name="targetMonth"
            label=""
            size="superSmall"
            value={targetDate.month}
            options={monthOptions}
            onChange={(e): void => {
              setTargetDate((prev) => ({
                ...prev,
                month: e.target.value
              }));
            }}
            onBlur={validation}
            error={hasError}
          />
        </div>
        {!isNoRecord ? (
          <>
            <div className={classes.checkboxWrapper}>
              <MuiCheckbox
                label="すべての日時を選択"
                checked={isCheckedAll}
                onChange={handleCheckAllChange}
                style={{ margin: 0 }}
                disabled={baseIds.length === 0}
              />
            </div>
            <SupportProcedureModalTable
              checkedIds={checkedIds}
              handleChangeCheckbox={handleChangeCheckbox}
              records={supportProcedureRows}
            />
          </>
        ) : (
          <div className={classes.noRecord}>
            作成済みの支援手順書がありません
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <KnowbeButton kind="outline" onClick={onClose}>
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          disabled={hasError || checkedIds.length === 0}
          onClick={onClickFix}
        >
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  supportProcedureList:
    state.KODOENGO.supportProcedureModal.supportProcedureList
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  return {
    fetchModalSupportProcedureList: (uifId: string, date: string): void => {
      KODOENGO.supportProcedureDispatcher(
        dispatch
      ).fetchModalSupportProcedureList(uifId, date);
    }
  };
};

export const SupportProcedurePrintModal = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportProcedurePrintModalCore)
);
