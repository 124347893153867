import { useState, useEffect } from "react";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { selectDateValueToDatePaddingZero } from "@utils/date";

export const useAccompanyDisplayFlg = (
  isNew: boolean,
  serviceDeliveryValues: ServiceDeliveryDetailValues,
  serviceDeliveryUser: ServiceDeliveryState["user"]
): boolean => {
  const [isDisplay, setIsDisplay] = useState(false);
  const { accompanySupportDisplayFlg, targetDate } = serviceDeliveryValues;

  useEffect(() => {
    if (!isNew && accompanySupportDisplayFlg) {
      setIsDisplay(true);
    }

    if (
      isNew &&
      "user_in_facility_judohomonkaigo" in serviceDeliveryUser &&
      serviceDeliveryUser.user_in_facility_judohomonkaigo
    ) {
      const userJUDO = serviceDeliveryUser.user_in_facility_judohomonkaigo;

      const targetDateValue = new Date(
        selectDateValueToDatePaddingZero(targetDate) || ""
      );
      const isWithin = !!(
        userJUDO.date_accompany_support_begin &&
        userJUDO.date_accompany_support_end &&
        targetDateValue >= new Date(userJUDO.date_accompany_support_begin) &&
        new Date(userJUDO.date_accompany_support_end) >= targetDateValue
      );
      if (isNew && userJUDO.approval_of_accompany_support_flg) {
        setIsDisplay(!!isWithin);
      }
    }
    return (): void => {
      setIsDisplay(false);
    };
  }, [accompanySupportDisplayFlg, targetDate, serviceDeliveryUser]);
  return isDisplay;
};
