import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";
import { ConsultationRecordState } from "../consultation/types";
import * as types from "./types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};
// 取得
export const fetchMonitoringReportStarted = () =>
  ({ type: types.FETCH_MONITORINGREPORT_STARTED } as const);
export const fetchMonitoringReportSuccess = (
  res: GetMonitoringReport["data"]
) =>
  ({
    type: types.FETCH_MONITORINGREPORT_SUCCESS,
    payload: res
  } as const);
export const fetchMonitoringReportFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_MONITORINGREPORT_FAILED, error: err } as const);
// 作成済み計画読み込み
export const copyConsultationStarted = () =>
  ({ type: types.COPY_CONSULTATION_STARTED } as const);
export const copyConsultationSuccess = (res: ConsultationRecordState) =>
  ({
    type: types.COPY_CONSULTATION_SUCCESS,
    payload: res
  } as const);
export const copyConsultationFailed = (err: ErrorResponse) =>
  ({ type: types.COPY_CONSULTATION_FAILED, error: err } as const);
export const resetCopyConsultation = () =>
  ({ type: types.RESET_COPY_CONSULTATION } as const);
// 更新
export const postMonitoringReportStarted = () =>
  ({ type: types.POST_MONITORINGREPORT_STARTED } as const);
export const postMonitoringReportSuccess = () =>
  ({
    type: types.POST_MONITORINGREPORT_SUCCESS
  } as const);
export const postMonitoringReportFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_MONITORINGREPORT_FAILED,
    error: err
  } as const);

// 削除
export const deleteMonitoringReportStarted = () =>
  ({ type: types.DELETE_MONITORINGREPORT_STARTED } as const);
export const deleteMonitoringReportSuccess = () =>
  ({
    type: types.DELETE_MONITORINGREPORT_SUCCESS
  } as const);
export const deleteMonitoringReportFailed = (err: ErrorResponse) =>
  ({
    type: types.DELETE_MONITORINGREPORT_FAILED,
    error: err
  } as const);
export const unsetMonitoringReport = () =>
  ({ type: types.UNSET_MONITORINGREPORT } as const);

export type ActionTypes =
  | ReturnType<typeof fetchMonitoringReportStarted>
  | ReturnType<typeof fetchMonitoringReportSuccess>
  | ReturnType<typeof fetchMonitoringReportFailed>
  | ReturnType<typeof copyConsultationStarted>
  | ReturnType<typeof copyConsultationSuccess>
  | ReturnType<typeof copyConsultationFailed>
  | ReturnType<typeof resetCopyConsultation>
  | ReturnType<typeof postMonitoringReportStarted>
  | ReturnType<typeof postMonitoringReportSuccess>
  | ReturnType<typeof postMonitoringReportFailed>
  | ReturnType<typeof deleteMonitoringReportStarted>
  | ReturnType<typeof deleteMonitoringReportSuccess>
  | ReturnType<typeof deleteMonitoringReportFailed>
  | ReturnType<typeof unsetMonitoringReport>;
