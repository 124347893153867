import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { OperationsState } from "@stores/domain/mgr/TANKINYUSHO/operations/types";
import dispatches from "@stores/dispatches";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  OPERATION_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";
import {
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM,
  CUSTOM_RECORD_TARGET_TYPE
} from "@constants/mgr/TANKINYUSHO/variables";

// utils
import dateToObject from "@utils/date/dateToObject";

import LineBreak from "@components/atoms/LineBreak";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontSize: 24,
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 24,
      marginTop: 16
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      marginBottom: 24,
      "&.fullWidth": {
        width: "100%"
      },
      "&.80width": {
        width: "80%"
      },
      "&.20width": {
        width: "20%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        wordBreak: "break-word",
        verticalAlign: "top",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.subLabel": {
          textAlign: "left",
          verticalAlign: "top"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 90
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 420
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top",
          paddingLeft: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  customRecords: CustomRecordsState;
  operations: OperationsState;
};

type DispatchProps = {
  fetchCustomRecords: (target: number) => Promise<void>;
  fetchOperations: (year: string, month: string) => Promise<void>;
};

type OwnProps = {
  year: string;
  month: string;
};

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

type OperationRecordProps = {
  operations: OperationsState;
  customRecords: CustomRecordsState;
  classes: Record<string, string>;
  recorderCustomRecord: CustomRecordsState;
  mealCustomRecord: CustomRecordsState[0];
};

const getOperationContents = (
  customRecords: CustomRecordsState,
  operation: OperationsState["operation"][0],
  mealCustomRecord: CustomRecordsState[0]
): JSX.Element[] => {
  const operationContents: JSX.Element[] = [];

  const { implement } = operation.counts.status_type;

  const isStatusType = Object.entries(operation.counts.status_type).some(
    ([key, value]) => key !== "denominator" && value
  );

  const isMealItem = operation.counts.meal.choices.some(
    (choice) => choice.count !== 0
  );

  // サマリ項目
  if (isStatusType) {
    operationContents.push(
      <React.Fragment key={`${operation.operation_records_id}_status_type`}>
        <td className="subLabel">サマリ</td>
        <td>サービス提供実施：{implement}人</td>
      </React.Fragment>
    );
  }

  // 食事項目
  const mealContent = mealCustomRecord.choices
    .map((item) => {
      const mealOperationData = operation.counts.meal.choices.find(
        (choicedData) => choicedData.id === item.id
      );
      if (
        !mealOperationData ||
        (item.hidden === 1 && mealOperationData.count === 0)
      ) {
        return null;
      }
      return `${item.name}：${mealOperationData.count}人`;
    })
    .filter((meal) => meal)
    .join("　");

  if (isMealItem) {
    operationContents.push(
      <React.Fragment key={`${operation.operation_records_id}_meal`}>
        <td className="subLabel">食事</td>
        <td>{mealContent}</td>
      </React.Fragment>
    );
  }
  [...customRecords].forEach((record) => {
    switch (record.input_type) {
      // テキスト形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
        const textContent = operation.operation_record_input.find(
          (item) => item.custom_record_item_id === record.id
        );

        const textInputData =
          textContent && textContent.input_data ? textContent.input_data : null;

        if (textInputData) {
          operationContents.push(
            <React.Fragment
              key={`${operation.operation_records_id}_text_${record.id}`}
            >
              <td className="subLabel">{record.name}</td>
              <td>
                <LineBreak text={textInputData} />
              </td>
            </React.Fragment>
          );
        }
        break;
      }
      // チェックボックス形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
        const checkedItemsId = operation.operation_record_input
          .filter(
            (item) =>
              item.custom_record_item_id === record.id && item.checked === 1
          )
          .map((filteredItem) => filteredItem.choiced_item_id);

        const checkedChoiceItems = record.choices
          .filter((item) => checkedItemsId.includes(item.id))
          .map((filteredItem) => filteredItem.name);

        const checkedName =
          checkedChoiceItems.length > 0 ? checkedChoiceItems.join("、") : "";

        if (checkedName) {
          operationContents.push(
            <React.Fragment
              key={`${operation.operation_records_id}_checkbox_${record.id}`}
            >
              <td className="subLabel">{record.name}</td>
              <td>{checkedName}</td>
            </React.Fragment>
          );
        }
        break;
      }
      // セレクトボックス（複数選択）形式
      case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_select: {
        const checkedItems = operation.operation_record_input
          .filter(
            (item) => item.custom_record_item_id === record.id && item.checked
          )
          .map((item) => item.choiced_staff_name_snapshot);
        const checkedItemsName = checkedItems.length
          ? checkedItems.join("、")
          : "";

        if (checkedItemsName) {
          operationContents.push(
            <React.Fragment
              key={`${operation.operation_records_id}_selectbox_${record.id}`}
            >
              <td className="subLabel">{record.name}</td>
              <td>{checkedItemsName}</td>
            </React.Fragment>
          );
        }
        break;
      }
      default:
        break;
    }
  });

  return operationContents;
};

const OperationRecord = ({
  classes,
  operations,
  customRecords,
  recorderCustomRecord,
  mealCustomRecord
}: OperationRecordProps): JSX.Element | null => {
  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td className="label ssize">日付</td>
          <td className="label msize">項目</td>
          <td className="label llsize">内容</td>
          <td className="label ssize">記録者</td>
          <td className="label sssize">捺印</td>
        </tr>
        {operations.operation.map((operationItem) => {
          const targetDate = dateToObject(new Date(operationItem.target_date));

          // 記録者
          const staffRecord = operationItem.operation_record_input
            .filter(
              (item) =>
                item.custom_record_item_id === recorderCustomRecord[0].id &&
                item.checked
            )
            .map((item) => item.choiced_staff_name_snapshot);
          const staffName = staffRecord.length ? staffRecord.join("、") : "";

          // 内容
          const contents = getOperationContents(
            customRecords,
            operationItem,
            mealCustomRecord
          );

          if (!contents || !contents.length) {
            return null;
          }

          return contents.map((content, index) => (
            <tr key={`${content.key}`}>
              {index === 0 && (
                <td
                  className="topAlign ssize"
                  rowSpan={contents.length}
                >{`${targetDate.month}/${targetDate.day}(${targetDate.day_of_week})`}</td>
              )}
              {content}
              {index === 0 && (
                <td className="topCenterAlign" rowSpan={contents.length}>
                  {staffName}
                </td>
              )}
              {index === 0 && (
                <td className="label" rowSpan={contents.length} />
              )}
            </tr>
          ));
        })}
      </tbody>
    </table>
  );
};

const OperationsPrintTANKINYUSHOCore = (props: Props): JSX.Element | null => {
  const { classes, operations, customRecords, year, month } = props;

  React.useEffect(() => {
    props.fetchCustomRecords(0);
    props.fetchOperations(year, month);
  }, []);

  // 業務日誌 並べ替え用関数
  const sortOperationCustomRecords = (
    records: CustomRecordsState
  ): CustomRecordsState => {
    // 全項目をorderの昇順で並べ替え
    const sortedArray = records.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

    // 欠勤・休暇者の項目を1番目に配置する
    const absenteeItemIndex = sortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee
    );
    const absenteeItem =
      absenteeItemIndex !== -1
        ? sortedArray.splice(absenteeItemIndex, 1)
        : sortedArray.splice(0, 1);

    const absenteeSortedArray = [...absenteeItem, ...sortedArray];

    // 出勤者の項目を1番目に配置する（欠勤・休暇者は2番目になる）
    const attendantItemIndex = absenteeSortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant
    );
    const attendantItem =
      attendantItemIndex !== -1
        ? absenteeSortedArray.splice(attendantItemIndex, 1)
        : absenteeSortedArray.splice(0, 1);

    return [...attendantItem, ...absenteeSortedArray];
  };

  // 記録項目データ事業所全体・ユニット別の指定
  const targetType = CUSTOM_RECORD_TARGET_TYPE.operation_common;

  // 記録項目データ
  const OperationCustomRecords = customRecords.length
    ? sortOperationCustomRecords(
        customRecords.filter((record) => record.setting_type === targetType)
      )
    : [];

  // 食事項目
  const mealCustomRecord = customRecords.find(
    (item) =>
      item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
      item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.meal
  );

  // 記録者項目
  const recorderItemIndex = OperationCustomRecords.findIndex(
    (item) =>
      item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
  );
  const recorderCustomRecord = OperationCustomRecords.splice(
    recorderItemIndex,
    1
  );

  if (
    !OperationCustomRecords ||
    !OperationCustomRecords.length ||
    !operations.operation.length ||
    !mealCustomRecord
  ) {
    return null;
  }

  return (
    <div className={classes.page}>
      <header>
        <h1 className={classes.title}>業務日誌</h1>
      </header>
      <div className={classes.subTitle}>
        <span>{`${year}年${month}月分`}</span>
      </div>
      <OperationRecord
        classes={classes}
        operations={operations}
        customRecords={OperationCustomRecords}
        recorderCustomRecord={recorderCustomRecord}
        mealCustomRecord={mealCustomRecord}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { customRecords } = state;
  const { operations } = state.TANKINYUSHO;
  return {
    customRecords,
    operations
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecords, TANKINYUSHO } = dispatches;
  const customRecordsDispatches = customRecords(dispatch);
  const operationsDispatches = TANKINYUSHO.operationsDispatcher(dispatch);

  return {
    fetchCustomRecords: (target: number): Promise<void> =>
      customRecordsDispatches.fetchCustomRecords(target),
    fetchOperations: (year: string, month: string): Promise<void> =>
      operationsDispatches.fetchOperations(year, month)
  };
};

export const OperationsPrintTANKINYUSHO = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OperationsPrintTANKINYUSHOCore));
