import * as React from "react";
import {
  createStyles,
  StyleRules,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { INPUT_LABEL_COLOR } from "@constants/styles";
import { textFieldStyle } from "@styles/base";
import classNames from "classnames";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    ...textFieldStyle,
    root: {
      display: "flex"
    },
    formControl: {
      marginTop: spacing.unit * 2,
      marginBottom: spacing.unit * 2
    },
    group: {
      marginTop: 12,
      marginRight: spacing.unit * 2,
      marginLeft: 26
    },
    label: {
      fontSize: 14,
      color: INPUT_LABEL_COLOR
    },
    radioButton: {
      padding: 4,
      marginRight: 8
    },
    itemWrapper: {
      display: "flex",
      alignItems: "center"
    },
    helpMark: {
      marginLeft: `${spacing.unit * 2}px`
    }
  });

export type RadioItemInterface = {
  label: string;
  value: string;
};

export type RadioItemDisabledInterface = {
  label: string;
  value: string;
  disabled: boolean;
};

type OwnProps = {
  name: string;
  label?: string | JSX.Element;
  radioItems?: RadioItemInterface[];
  value: string;
  radioGroupClass?: string;
  labelClass?: string;
  inputStyle?: object;
  helperItemTip?: object;
  onChange: (event: React.ChangeEvent<{}>, value: string) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const RadioButtons = ({
  label = "",
  value = "",
  ...props
}: Props): JSX.Element => {
  const {
    classes,
    name,
    helperItemTip,
    onChange,
    radioItems,
    radioGroupClass
  } = props;
  return (
    <FormControl component="div" className={classes.formControl}>
      <FormLabel component="label" className={classes.label}>
        {label}
      </FormLabel>
      <RadioGroup
        name={name}
        className={radioGroupClass || classes.group}
        value={value}
        onChange={onChange}
      >
        {radioItems &&
          radioItems.map((item, index) => {
            const key = `radio_${name}_${index}`;
            return (
              <FormControlLabel
                key={key}
                value={item.value}
                control={
                  <Radio className={classes.radioButton} color="secondary" />
                }
                label={
                  item.label ? (
                    <div
                      className={classNames(
                        classes.itemWrapper,
                        props.labelClass
                      )}
                    >
                      {item.label}
                      {helperItemTip && item.value in helperItemTip && (
                        <span className={classes.helpMark}>
                          <HelpToolTip
                            title={
                              <HelpTipMessages
                                name={helperItemTip[item.value]}
                              />
                            }
                          />
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )
                }
                labelPlacement="end"
              />
            );
          })}
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(RadioButtons);
