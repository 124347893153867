import getUser from "./getUser";
import getFacilitySwitchApproval from "./getFacilitySwitchApproval";
import { postNewMobileUser } from "./postNewMobileUser";
import postUpdateUserPassword from "./postUpdateUserPassword";
import postUpdateAssociatedFacility from "./postUpdateAssociatedFacility";

export default {
  getUser,
  getFacilitySwitchApproval,
  postNewMobileUser,
  postUpdateUserPassword,
  postUpdateAssociatedFacility
};
