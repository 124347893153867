import {
  RadioItemInterface,
  RadioItemDisabledInterface
} from "@components/atoms/RadioButtons";

import {
  FACILITY,
  FACILITY_202104,
  REPORT_DAILY,
  REPORT_DAILY_202104,
  REPORT_USERS,
  REPORT_USERS_202104,
  USERS_ID,
  USERS_ID_202104
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V202104
} from "@constants/variables";

// 要介護区分
export const NEED_NURSING_CARE_CLASS_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "要介護1", value: "1" },
  { label: "要介護2", value: "2" },
  { label: "要介護3", value: "3" },
  { label: "要介護4", value: "4" },
  { label: "要介護5", value: "5" }
];

// 要支援区分
export const SUPPORT_REQUIRED_CLASS_LIST: RadioItemInterface[] = [
  { label: "なし", value: "0" },
  { label: "要支援1", value: "1" },
  { label: "要支援2", value: "2" }
];

// 相談支援機能強化型体制
export const FUNCTION_ENHANCED_SYSTEM_LIST = [
  { label: "なし", value: "0" },
  { label: "相談支援機能強化型体制（Ⅰ）", value: "1" },
  { label: "相談支援機能強化型体制（Ⅱ）", value: "2" },
  { label: "相談支援機能強化型体制（Ⅲ）", value: "3" },
  { label: "相談支援機能強化型体制（Ⅳ）", value: "4" }
];

// 要医療児者支援体制（R6年度報酬改定で追加）
export const DEFAULT_NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT = "0";
export const NEED_MEDICAL_CARE_HANDICAPPED_CHILD_SUPPORT_LIST = [
  { label: "なし", value: "0" },
  { label: "要医療児者支援体制（Ⅰ）", value: "1" },
  { label: "要医療児者支援体制（Ⅱ）", value: "2" }
];

export const DEFAULT_FUNCTION_ENHANCED_SYSTEM = "0";

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LIST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

export const REPORT_FILTER_LIST = [
  { label: "すべての利用者", value: "1" },
  { label: "実績あり", value: "2" }
];

export const STATUS_LIST = [
  { label: "-", value: "0" },
  { label: "基本報酬なし", value: "1" },
  { label: "サービス利用支援", value: "2" },
  { label: "継続サービス利用支援", value: "3" }
];

export const STATUS_LIST_SHOGAIJISODAN = [
  { label: "-", value: "0" },
  { label: "基本報酬なし", value: "1" },
  { label: "障害児支援利用援助", value: "2" },
  { label: "継続障害児支援利用援助", value: "3" }
];

export const HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const MENTAL_DISORDER_SUPPORT_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const BEHAVIORAL_DISORDER_SUPPORT_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const HIGHER_BRAIN_DYSFUNCTION_SUPPORT_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const TRAINING_BY_CHIEF_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" }
];

export const FIRST_ADDITION_SPAN_LIST = [
  { label: "-", value: "0" },
  { label: "4ヶ月目", value: "1" },
  { label: "5ヶ月目", value: "2" },
  { label: "6ヶ月目以上", value: "3" }
];

export const DEFAULT_DISCHARGE_ADDITION_COUNT = 3;
export const DEFAULT_LIFE_SUPPORT_PROMOTION_COUNT = 4;

export const RESULT_OF_MANAGEMENT_LIST: RadioItemDisabledInterface[] = [
  {
    label:
      "管理事業所で利用者負担額を充当したため、他事業所の利用者負担は発生しない",
    value: "1",
    disabled: true
  },
  {
    label: "利用者負担額の合算額が、負担上限月額以下のため、調整事務は行わない",
    value: "2",
    disabled: false
  },
  {
    label:
      "利用者負担額の合算額が、負担上限月額を超過するため、下記の通り調整した",
    value: "3",
    disabled: false
  }
];

// モニタリング期間選択肢
export const MONITORING_PERIOD_LIST = [
  { label: "1ヶ月", value: "1" },
  { label: "2ヶ月", value: "2" },
  { label: "3ヶ月", value: "3" },
  { label: "4ヶ月", value: "4" },
  { label: "5ヶ月", value: "5" },
  { label: "6ヶ月", value: "6" },
  { label: "7ヶ月", value: "7" },
  { label: "8ヶ月", value: "8" },
  { label: "9ヶ月", value: "9" },
  { label: "10ヶ月", value: "10" },
  { label: "11ヶ月", value: "11" },
  { label: "12ヶ月", value: "12" }
];

// 利用計画タイプ
export const CONSULTATION_TARGET_FRG = {
  CONSULTATION_PLAN: 0, // サービス等利用計画案
  CONSULTATION: 1, // サービス等利用計画
  CONSULTATION_CONTINUATION: 2 // 継続サービス等利用計画
};

// 利用計画タイトル
export const CONSULTATION_TARGET_TITLE = {
  0: "サービス等利用計画案",
  1: "サービス等利用計画",
  2: "継続サービス等利用計画"
};

// 利用計画タイトル（障害児相談）
export const CONSULTATION_TARGET_TITLE_SHOGAIJISODAN = {
  0: "障害児支援利用計画案",
  1: "障害児支援利用計画",
  2: "継続障害児支援利用計画"
};

// 週間スケジュールの曜日
export const SCHEDULE_DAY_OF_WEEK = [
  { label: "月", value: 1 },
  { label: "火", value: 2 },
  { label: "水", value: 3 },
  { label: "木", value: 4 },
  { label: "金", value: 5 },
  { label: "土", value: 6 },
  { label: "日", value: 7 }
];

// 当日翌日フラグ
export const SCHEDULE_NEXT_DAY_FLG_ITEM = {
  APPOINTED_DAY: { label: "当日", value: "0" },
  NEXT_DAY: { label: "翌日", value: "1" }
};

export const SCHEDULE_NEXT_DAY_FLG = [
  SCHEDULE_NEXT_DAY_FLG_ITEM.APPOINTED_DAY,
  SCHEDULE_NEXT_DAY_FLG_ITEM.NEXT_DAY
];

// 性別
export const GENDER_LIST: RadioItemInterface[] = [
  { label: "男性", value: "1" },
  { label: "女性", value: "2" }
];

// 住まい
export const RESIDENCE_LIST: RadioItemInterface[] = [
  { label: "持家", value: "1" },
  { label: "借家", value: "2" },
  { label: "グループホーム/ケアホーム", value: "3" },
  { label: "入所施設", value: "4" },
  { label: "医療機関", value: "5" },
  { label: "その他", value: "6" }
];

export const TYPE_CONSULTATION_FLG = [
  { label: "計画相談支援", value: "1" },
  { label: "障害児相談支援", value: "2" }
];

export const USAGE_DRAFT_PLAN_NUM = 0;
export const USAGE_PLAN_NUM = 1;
export const USAGE_CONTINUATION_PLAN_NUM = 2;
export const MONITORING_REPORT_NUM = 100;
export const STAFF_MEETING_RECORD_NUM = 102;
export const INQUIRY_CONTENT_NUM = 103;

export const USAGE_DRAFT_PLAN = "利用計画案";
export const USAGE_PLAN = "利用計画";
export const USAGE_CONTINUATION_PLAN = "継続利用計画";
export const MONITORING_REPORT = "モニタリング報告書";
export const STAFF_MEETING_RECORD = "担当者会議記録";
export const INQUIRY_CONTENT = "照会内容";

export const USAGE_DRAFT_PLAN_RADIO_LABEL = "サービス等利用計画案";
export const USAGE_PLAN_RADIO_LABEL = "サービス等利用計画";
export const USAGE_CONTINUATION_PLAN_RADIO_LABEL = "継続サービス等利用計画";
export const MONITORING_REPORT_RADIO_LABEL = "モニタリング報告書";
export const STAFF_MEETING_RECORD_RADIO_LABEL = "サービス担当者会議記録";
export const INQUIRY_CONTENT_RADIO_LABEL = "担当者に対する照会内容";

export const SHOGAIJI_USAGE_DRAFT_PLAN_RADIO_LABEL = "障害児相談支援利用計画案";
export const SHOGAIJI_USAGE_PLAN_RADIO_LABEL = "障害児相談支援利用計画";
export const SHOGAIJI_USAGE_CONTINUATION_PLAN_RADIO_LABEL =
  "継続障害児相談支援利用計画";
export const SHOGAIJI_MONITORING_REPORT_RADIO_LABEL = "モニタリング報告書";
export const SHOGAIJI_STAFF_MEETING_RECORD_RADIO_LABEL =
  "サービス担当者会議記録";
export const SHOGAIJI_INQUIRY_CONTENT_RADIO_LABEL = "担当者に対する照会内容";

/**
 * 共通で使用する短縮された帳票名を管理
 * ※障害児の場合はsuffixに"(障害児)"をつけて使用
 * */
export const TARGET_FLG_TYPE = {
  [USAGE_DRAFT_PLAN_NUM]: USAGE_DRAFT_PLAN,
  [USAGE_PLAN_NUM]: USAGE_PLAN,
  [USAGE_CONTINUATION_PLAN_NUM]: USAGE_CONTINUATION_PLAN,
  [MONITORING_REPORT_NUM]: MONITORING_REPORT,
  [STAFF_MEETING_RECORD_NUM]: STAFF_MEETING_RECORD,
  [INQUIRY_CONTENT_NUM]: INQUIRY_CONTENT
};

/** 計画読み込みで使用する帳票名（計画相談） */
export const TARGET_FLG_TYPE_KEIKAKU = {
  [USAGE_DRAFT_PLAN_NUM]: USAGE_DRAFT_PLAN_RADIO_LABEL,
  [USAGE_PLAN_NUM]: USAGE_PLAN_RADIO_LABEL,
  [USAGE_CONTINUATION_PLAN_NUM]: USAGE_CONTINUATION_PLAN_RADIO_LABEL
};

/** 計画読み込みで使用する帳票名（障害児相談） */
export const TARGET_FLG_TYPE_SHOGAIJI = {
  [USAGE_DRAFT_PLAN_NUM]: "障害児支援利用計画案",
  [USAGE_PLAN_NUM]: "障害児支援利用計画",
  [USAGE_CONTINUATION_PLAN_NUM]: "継続障害児支援利用計画"
};

export const REGISTERED_YEAR_MONTH_VALIDATION =
  "登録済みです。別の年月を選択してください";

export const FACILITY_TYPE_KEIKAKUSODAN = 1; // "計画相談"
export const FACILITY_TYPE_SHOGAIJISODAN = 2; // "障害児相談"
export const FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN = 3; // "計画障害児相談"

export const TYPE_CONSULTATION_KEIKAKUSODAN = 1; // 相談種別: 計画相談
export const TYPE_CONSULTATION_SHOGAIJISODAN = 2; // 相談種別: 障害児相談

export const PARAMETER_TYPE_KEIKAKUSODAN = "keikaku"; // 相談種別: 計画相談(クエリパラメータの値)
export const PARAMETER_TYPE_SHOGAIJISODAN = "shogaiji"; // 相談種別: 障害児相談(クエリパラメータの値)

/** 事業所情報 対象種別 計画相談 */
export const FACILITY_TARGET_TYPE_KEIKAKUSODAN = 1;
/** 事業所情報 対象種別 障害児相談 */
export const FACILITY_TARGET_TYPE_SHOGAIJISODAN = 2;

/** 利用者詳細 利用者の相談種別 計画相談 */
export const UIFACILITY_TYPE_CONSULTATION_KEIKAKUSODAN = 1;
/** 利用者詳細 利用者の相談種別 障害児相談 */
export const UIFACILITY_TYPE_CONSULTATION_SHOGAIJISODAN = 2;

export const FACILITY_TYPE_LIST = {
  1: "計画相談支援",
  2: "障害児相談支援"
};

/** 利用者詳細 受給者証未発行がONの場合にAPIへ渡すサービス開始日のダミー値 */
export const DUMMY_DATE_BEGIN_IN_SERVICE = "9999-12-01";

/** 利用実績 編集モーダル サービス種類の選択肢 */
export const FACILITY_TYPE_SELECT_OPTIONS = [
  { label: "計画相談支援", value: "1" },
  { label: "障害児相談支援", value: "2" }
];

/** 請求ダウンロード ファイルタイプ  */
export const DOWNLOAD_FILE_TYPE = {
  jisseki: "請求書・明細書",
  costAmountList: "利用者負担額一覧表",
  upLimit: "利用者負担上限額管理結果票"
};

type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportUsers";

/** 計画相談の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportUsers: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_USERS_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};

export const KEIKAKU_MONITORING_KAIGI_KIROKU = "計画・モニタリング・会議記録";
