import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { BasicFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/BasicFields";
import { GoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/GoalFields";
import { SupportPlanGoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/SupportPlanGoalFields";
import { OtherFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/OtherFields";
import { dateToLocalisedString } from "@/utils/date";
import { dateToSelectDateValue } from "@utils/date";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { supplementSupportPlanGoal } from "@initialize/mgr/SHUROTEICHAKU/record/supportPlan/initialValues";
import { GetSupportPlanResponse } from "@api/requests/supportPlan/SHUROTEICHAKU/getSupportPlan";

const styles = (): StyleRules =>
  createStyles({
    dialogTitle: {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      minHeight: 53,
      maxWidth: 1070,
      padding: "11px 31px",
      "& > :first-child": {
        marginLeft: 8
      }
    },
    titleWrap: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    dialogContent: {
      width: 1070,
      padding: "0 32px"
    },
    section: {
      display: "flex",
      fontSize: 20,
      marginTop: 24,
      borderBottom: "solid 1px rgba(0, 0, 0, 0.54)",
      "& > span": {
        lineHeight: "45px"
      }
    },
    createData: {
      justifyContent: "space-between",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      marginLeft: "auto",
      marginBottom: 9
    },
    space: {
      marginLeft: 20
    },
    fieldsWrap: {
      padding: "24px 8px 32px"
    },
    divider: {
      marginTop: 48
    }
  });

type OwnProps = {
  isOpen: boolean;
  onClose: () => void;
  supportPlan: GetSupportPlanResponse["data"];
  userName: string;
  dateBirth: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PlannedTargetDialog = (props: Props): JSX.Element | null => {
  const filteredPlan = props.supportPlan.filter((plan) => {
    return !plan.archive;
  });
  const latestPlan =
    filteredPlan.length > 0 &&
    filteredPlan.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
  if (!latestPlan) {
    return null;
  }

  // 作成者名
  const { author, support_plan_shuroteichaku } = latestPlan;
  const authorValue = getSnapOrRealName(author, "-");
  // 各フィールドに必要な値
  const values = {
    remarks: latestPlan.remarks || "",
    staff_comment: latestPlan.staff_comment || "",
    support_plan_goal: supplementSupportPlanGoal(latestPlan.support_plan_goal),
    creation_date: latestPlan.creation_date || "",
    support_plan_shuroteichaku: {
      company_name: support_plan_shuroteichaku.company_name || "",
      company_industry: support_plan_shuroteichaku.company_industry || "",
      company_address: support_plan_shuroteichaku.company_address || "",
      company_tel: support_plan_shuroteichaku.company_tel || "",
      responsible_person: support_plan_shuroteichaku.responsible_person || "",
      equipment_environment:
        support_plan_shuroteichaku.equipment_environment || "",
      human_environment: support_plan_shuroteichaku.human_environment || "",
      working_status: `${support_plan_shuroteichaku.working_status || 0}`,
      wage: support_plan_shuroteichaku.wage || "",
      working_time: support_plan_shuroteichaku.working_time || "",
      rest_time: support_plan_shuroteichaku.rest_time || "",
      holiday: support_plan_shuroteichaku.holiday || "",
      business_description:
        support_plan_shuroteichaku.business_description || "",
      working_start_date: dateToSelectDateValue(
        support_plan_shuroteichaku.working_start_date || ""
      ),
      takeover: support_plan_shuroteichaku.takeover || "",
      disability_class: `${support_plan_shuroteichaku.disability_class || 0}`,
      disability_certificate_body: `${
        support_plan_shuroteichaku.disability_certificate_body || 0
      }`,
      disability_certificate_spirit: `${
        support_plan_shuroteichaku.disability_certificate_spirit || 0
      }`,
      disability_certificate_rehabilitation: `${
        support_plan_shuroteichaku.disability_certificate_rehabilitation || 0
      }`,
      disability_certificate_rehabilitation_other:
        support_plan_shuroteichaku.disability_certificate_rehabilitation_other ||
        "",
      classify_incurable_flg: numberToBoolean(
        support_plan_shuroteichaku.classify_incurable_flg,
        false
      ),
      support_group: support_plan_shuroteichaku.support_group || "",
      physical_condition: support_plan_shuroteichaku.physical_condition || "",
      support_organization:
        support_plan_shuroteichaku.support_organization || ""
    }
  };

  return (
    <Dialog open={props.isOpen} disableBackdropClick maxWidth={false}>
      <DialogTitle className={props.classes.dialogTitle}>
        <div className={props.classes.titleWrap}>
          <div>
            <span>{props.userName}</span>
            <span className={props.classes.space}>計画目標</span>
          </div>
          <KnowbeButton kind="outline" minWidth={120} onClick={props.onClose}>
            閉じる
          </KnowbeButton>
        </div>
      </DialogTitle>
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.section}>
          <span>支援期間</span>
          <span className={props.classes.space}>
            {latestPlan.support_begin_date
              ? dateToLocalisedString(
                  latestPlan.support_begin_date,
                  "YYYY年M月D日"
                )
              : null}
          </span>
          <span className={props.classes.space}>〜</span>
          <span className={props.classes.space}>
            {latestPlan.support_end_date
              ? dateToLocalisedString(
                  latestPlan.support_end_date,
                  "YYYY年M月D日"
                )
              : null}
          </span>
          <div className={props.classes.createData}>
            <div>
              <span>計画作成日：</span>
              <span>
                {latestPlan.creation_date
                  ? dateToLocalisedString(
                      latestPlan.creation_date,
                      "YYYY年 MM月 DD日"
                    )
                  : "-"}
              </span>
            </div>
            <div>
              <span>計画作成者：</span>
              <span>{authorValue}</span>
            </div>
          </div>
        </div>

        <div className={props.classes.fieldsWrap}>
          <BasicFields
            isEditing={false}
            dateBirth={props.dateBirth}
            values={values}
          />
          <div className={props.classes.divider} />
          <GoalFields
            isEditing={false}
            sprintType={1}
            values={values}
            isDateSplitOff
          />
          <div className={props.classes.divider} />
          <GoalFields
            isEditing={false}
            sprintType={2}
            values={values}
            isDateSplitOff
          />
          <div className={props.classes.divider} />
          <SupportPlanGoalFields isEditing={false} values={values} isPlanned />
          <div className={props.classes.divider} />
          <OtherFields isEditing={false} values={values} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(PlannedTargetDialog);
