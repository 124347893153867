import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FieldArray, FormikProps } from "formik";

import FormikTime from "@components/molecules/FormikTime";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

import {
  flowDayDetailInitialValue,
  Assessment,
  FlowDays
} from "@initialize/record/assessment/initialValues";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd"
    },
    tableTitle: {
      fontSize: "14px"
    },
    divider: {
      backgroundColor: "#bdbdbd"
    },
    userLabel: {
      marginTop: 24,
      marginBottom: 8,
      fontSize: "14px",
      lineHeight: "24px"
    },
    // １日の流れ入力ヘッダー
    headerArea: {
      display: "flex",
      alignItems: "center",
      width: "100%",
      padding: "12px 16px",
      height: 40,
      marginTop: 8,
      gap: "16px",
      backgroundColor: "#eceff1",
      color: "#37474f",
      fontSize: 12
    },
    headerTime: {
      width: 72
    },
    headerMatter: {
      flexGrow: 1
    },
    headerDelete: {
      width: 27
    },
    // １日の流れ入力ボディ
    bodyArea: {
      display: "flex",
      alignItems: "flex-start",
      width: "100%",
      padding: "12px 16px",
      marginTop: 8,
      gap: "16px",
      "&:nth-child(even)": {
        backgroundColor: "#f5f5f5"
      }
    },
    bodyTime: {
      width: 72
    },
    bodyMatter: {
      flexGrow: 1
    },
    bodyDelete: {
      width: 27
    },
    bodyDeleteIcon: {
      marginTop: 4,
      minWidth: 24,
      padding: 0,
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    emptyMessage: {
      margin: "16px 0 0 14px",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)"
    },
    // 追加するボタン
    addButton: {
      marginTop: 24
    },
    buttonIcon: {
      marginRight: 8
    },
    deleteButton: {
      marginTop: 24
    }
  });

type OwnProps = {
  itemName: string;
  isEditing: boolean;
  inputFlowDays: FlowDays;
  formikProps: FormikProps<Assessment>;
  onClickDeleteButton: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

type FlowDaysHeaderProps = {
  isEditing: boolean;
} & WithStyles<typeof styles>;

const FlowDaysHeader = ({
  classes,
  ...props
}: FlowDaysHeaderProps): JSX.Element => {
  return (
    <div className={classes.headerArea}>
      <div className={classes.headerTime}>開始時間</div>
      <div className={classes.headerTime}>終了時間</div>
      <div className={classes.headerMatter}>事項</div>
      <div className={classes.headerDelete}>
        {props.isEditing ? "削除" : ""}
      </div>
    </div>
  );
};

type FlowDaysBodyProps = {
  itemName: string;
  isEditing: boolean;
  valueMatter: string;
  valueStartTime: string;
  valueEndTime: string;
  onClickDeleteIcon: () => void;
} & WithStyles<typeof styles>;

const FlowDaysBody = ({
  classes,
  ...props
}: FlowDaysBodyProps): JSX.Element => {
  return (
    <div className={classes.bodyArea}>
      <div className={classes.bodyTime}>
        {props.isEditing ? (
          <FormikTime
            name={`${props.itemName}.start_time`}
            label=""
            placeholder="00:00"
            size="fullSize"
            style={{ marginBottom: 0 }}
            maxLength={5}
          />
        ) : (
          <ReadonlyTextField defaultValue="" value={props.valueStartTime} />
        )}
      </div>
      <div className={classes.bodyTime}>
        {props.isEditing ? (
          <FormikTime
            name={`${props.itemName}.end_time`}
            label=""
            placeholder="00:00"
            size="fullSize"
            style={{ marginBottom: 0 }}
            maxLength={5}
          />
        ) : (
          <ReadonlyTextField defaultValue="" value={props.valueEndTime} />
        )}
      </div>
      <div className={classes.bodyMatter}>
        <RecordTextField
          name={`${props.itemName}.matter`}
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={props.valueMatter}
          isEditable={props.isEditing}
          style={{ marginBottom: 0, width: "100%" }}
        />
      </div>
      <div className={classes.bodyDelete}>
        {props.isEditing ? (
          <DeleteOutlinedIcon
            className={classes.bodyDeleteIcon}
            color="secondary"
            onClick={props.onClickDeleteIcon}
          />
        ) : null}
      </div>
    </div>
  );
};

const AssessmentSheetFormFlowDaysCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const flowDayDetailsUserName = `${props.itemName}.flow_day_details_user`;
  const flowDayDetailsCaregiverName = `${props.itemName}.flow_day_details_caregiver`;
  const flowDaysDetailMaxLength = 50;
  let visibleFlowDayDetailsUserCount = 0;
  let visibleFlowDayDetailsCaregiverCount = 0;
  return (
    <div className={classes.root}>
      <RecordTextField
        label="タイトル"
        name={`${props.itemName}.title`}
        labelType="default"
        defaultValue=""
        placeholder=""
        value={props.inputFlowDays.title}
        isEditable={props.isEditing}
        style={{ marginBottom: 0, width: "100%" }}
      />

      <Divider className={classes.divider} style={{ marginTop: 32 }} />

      <div>
        <p className={classes.userLabel}>本人</p>
        <FlowDaysHeader classes={classes} isEditing={props.isEditing} />
        <FieldArray name={flowDayDetailsUserName}>
          {(arrayHelpers): JSX.Element => {
            const handleClickAddFlowDayDetailUser = (): void => {
              arrayHelpers.push(flowDayDetailInitialValue());
            };
            const handleClickDeleteFlowDayDetailUser = (
              index: number
            ): void => {
              const flowDayDetail = Array.from(
                props.inputFlowDays.flow_day_details_user
              );
              if (flowDayDetail[index].id) {
                flowDayDetail[index].isDelete = 1;
              } else {
                flowDayDetail.splice(index, 1);
              }
              props.formikProps.setFieldValue(
                flowDayDetailsUserName,
                flowDayDetail
              );
            };
            // 登録数0件の場合
            if (
              !props.isEditing &&
              props.inputFlowDays.flow_day_details_user.length === 0
            ) {
              return <p className={classes.emptyMessage}>登録されていません</p>;
            }
            return (
              <>
                {props.inputFlowDays.flow_day_details_user.map(
                  (flowDayDetail, flowDayDetailIndex) => {
                    if (flowDayDetail.isDelete) return null;
                    const key = `flow-detail-user-${flowDayDetailIndex}`;
                    visibleFlowDayDetailsUserCount += 1;
                    return (
                      <FlowDaysBody
                        key={key}
                        isEditing={props.isEditing}
                        itemName={`${flowDayDetailsUserName}[${flowDayDetailIndex}]`}
                        classes={classes}
                        valueMatter={flowDayDetail.matter}
                        valueStartTime={flowDayDetail.start_time}
                        valueEndTime={flowDayDetail.end_time}
                        onClickDeleteIcon={(): void =>
                          handleClickDeleteFlowDayDetailUser(flowDayDetailIndex)
                        }
                      />
                    );
                  }
                )}
                {props.isEditing && (
                  <KnowbeButton
                    kind="iconText"
                    className={classes.addButton}
                    onClick={handleClickAddFlowDayDetailUser}
                    disabled={
                      visibleFlowDayDetailsUserCount >= flowDaysDetailMaxLength
                    }
                  >
                    <AddIcon
                      color={
                        visibleFlowDayDetailsUserCount >=
                        flowDaysDetailMaxLength
                          ? "disabled"
                          : "secondary"
                      }
                      className={classes.buttonIcon}
                    />
                    追加する
                  </KnowbeButton>
                )}
              </>
            );
          }}
        </FieldArray>
      </div>

      <Divider className={classes.divider} style={{ marginTop: 24 }} />

      <div>
        <p className={classes.userLabel}>介護者</p>
        <FlowDaysHeader classes={classes} isEditing={props.isEditing} />
        <FieldArray name={flowDayDetailsCaregiverName}>
          {(arrayHelpers): JSX.Element => {
            const handleClickAddFlowDayDetailCaregiver = (): void => {
              arrayHelpers.push(flowDayDetailInitialValue());
            };
            const handleClickDeleteFlowDayDetailCaregiver = (
              index: number
            ): void => {
              const flowDayDetail = Array.from(
                props.inputFlowDays.flow_day_details_caregiver
              );
              if (flowDayDetail[index].id) {
                flowDayDetail[index].isDelete = 1;
              } else {
                flowDayDetail.splice(index, 1);
              }
              props.formikProps.setFieldValue(
                flowDayDetailsCaregiverName,
                flowDayDetail
              );
            };
            // 登録数0件の場合
            if (
              !props.isEditing &&
              props.inputFlowDays.flow_day_details_caregiver.length === 0
            ) {
              return <p className={classes.emptyMessage}>登録されていません</p>;
            }
            return (
              <>
                {props.inputFlowDays.flow_day_details_caregiver.map(
                  (flowDayDetail, flowDayDetailIndex) => {
                    if (flowDayDetail.isDelete) return null;
                    const key = `flow-detail=caregiver-${flowDayDetailIndex}`;
                    visibleFlowDayDetailsCaregiverCount += 1;
                    return (
                      <FlowDaysBody
                        key={key}
                        isEditing={props.isEditing}
                        itemName={`${flowDayDetailsCaregiverName}[${flowDayDetailIndex}]`}
                        classes={classes}
                        valueMatter={flowDayDetail.matter}
                        valueStartTime={flowDayDetail.start_time}
                        valueEndTime={flowDayDetail.end_time}
                        onClickDeleteIcon={(): void =>
                          handleClickDeleteFlowDayDetailCaregiver(
                            flowDayDetailIndex
                          )
                        }
                      />
                    );
                  }
                )}
                {props.isEditing && (
                  <KnowbeButton
                    kind="iconText"
                    className={classes.addButton}
                    onClick={handleClickAddFlowDayDetailCaregiver}
                    disabled={
                      visibleFlowDayDetailsCaregiverCount >=
                      flowDaysDetailMaxLength
                    }
                  >
                    <AddIcon
                      color={
                        visibleFlowDayDetailsCaregiverCount >=
                        flowDaysDetailMaxLength
                          ? "disabled"
                          : "secondary"
                      }
                    />
                    追加する
                  </KnowbeButton>
                )}
              </>
            );
          }}
        </FieldArray>
      </div>

      {props.isEditing && (
        <>
          <Divider className={classes.divider} style={{ marginTop: 24 }} />
          <KnowbeButton
            kind="iconText"
            className={classes.deleteButton}
            onClick={props.onClickDeleteButton}
          >
            <DeleteOutlinedIcon
              color="secondary"
              className={classes.buttonIcon}
            />
            削除する
          </KnowbeButton>
        </>
      )}
    </div>
  );
};

export const AssessmentSheetFormFlowDays = withStyles(styles)(
  AssessmentSheetFormFlowDaysCore
);
