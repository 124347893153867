import { SelectMonthValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * string (YYYY-MM) => SelectMonthValue
 */
export const dateToSelectMonthValue = (date: string): SelectMonthValue => {
  const year = date ? dateToLocalisedString(date, "YYYY") : "";
  const month = date ? dateToLocalisedString(date, "M") : "";
  return { year, month };
};
