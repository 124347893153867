import _isNil from "lodash-es/isNil";
import { FacilityState } from "./types";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { PostFacilityParams } from "@api/requests/facility/postFacility";
import { FacilityValues } from "@initialize/mgr/IDOSHIEN/facility/initialValues";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams
): FacilityState => {
  const { facility } = result;

  return {
    corporationName:
      facility.gov_business_owner !== null ? facility.gov_business_owner : "",
    officeNumber: !_isNil(facility.gov_facility_number)
      ? facility.gov_facility_number
      : "",
    officeName: !_isNil(facility.name) ? facility.name : "",
    serviceType: facility.type_service,
    representativeName: !_isNil(facility.responsible_person)
      ? facility.responsible_person
      : "",
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address !== null ? facility.address : "",
    tel: !_isNil(facility.tel) ? facility.tel : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : ""
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result = {
    facility: { ...target.facility }
  };

  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMap = {
  facility: {
    master_subordinate_flg: {
      key: "master_subordinate_flg",
      childKeys: ["master_flg"]
    }
  }
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param target
 * @param after
 */
const addParentValue = (
  target: PostFacilityParams,
  after: PostFacilityParams
): PostFacilityParams => {
  const result = target;
  Object.keys(parentParamsMap).forEach((facilityKey) => {
    Object.keys(parentParamsMap[facilityKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[facilityKey][paramKey].childKeys
        ? parentParamsMap[facilityKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return result[facilityKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        result[facilityKey][parentParamsMap[facilityKey][paramKey].key] =
          after[facilityKey][parentParamsMap[facilityKey][paramKey].key];
      }
    });
  });
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams
): NormalizedPostFacilityParams => normalizeApiParams(params);

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams
): PostFacilityParams => {
  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      capacity: null,
      multiple_facility_flg: "",
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: null,
      welfare_condition_end_date: null,
      better_supporter_condition: "",
      lack_of_supporter_flg: "",
      date_start_lack_of_supporter: null,
      lack_of_service_admin_flg: "",
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: "",
      see_hear_team_flg: null
    }
  };

  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${before[key][param]}` !== `${after[key][param]}`) {
          target[key][param] = after[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });

  return addParentValue(target, after);
};

export const normalizeFormValue = (
  state: FacilityValues
): PostFacilityParams => {
  return removeUnnecessaryValue({
    facility: {
      gov_business_owner: state.basic.corporationName,
      gov_facility_number: state.basic.officeNumber,
      name: state.basic.officeName,
      type_service: state.basic.serviceType,
      responsible_person: state.basic.representativeName,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      tel: state.basic.tel,
      capacity: null,
      multiple_facility_flg: "",
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: null,
      welfare_condition_end_date: null,
      better_supporter_condition: "",
      lack_of_supporter_flg: "",
      date_start_lack_of_supporter: null,
      lack_of_service_admin_flg: "",
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: "",
      see_hear_team_flg: null
    }
  });
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues
): PostFacilityParams => {
  return removeNoChangeData(params, normalizeFormValue(response));
};
