import * as React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import {
  createStyles,
  WithStyles,
  Button,
  withStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
// ui
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
// store
import { connect } from "react-redux";
// constants
import { FacilityType, STRING_TRUE_FROM_API } from "@constants/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  getModalStampIconKey,
  getModalStampIconCheck,
  setModalStampIconCheck
} from "@utils/localStorage";

const styles = (): StyleRules =>
  createStyles({
    dialogHeader: {
      marginBottom: 4,
      padding: "16px 24px 20px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 64
    },
    paper: {
      height: 540
    },
    dialogContent: {
      minWidth: 550,
      height: "100%",
      padding: 0
    },
    dialogSubTitle: {
      margin: 0,
      padding: "8px 24px 8px",
      color: "#778899",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: 1
    },
    dialogSubTitleForA: {
      fontSize: 16,
      lineHeight: 1.8,
      margin: "10px 0px 35px 5px",
      color: "#37474f"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      marginLeft: 0,
      marginRight: 0
    },
    li: {
      marginBottom: 8,
      color: "#333",
      listStyleType: "none",
      "&:before": {
        width: 8,
        height: 8,
        display: "inline-block",
        position: "relative",
        content: "''",
        borderRadius: "100%",
        top: -2,
        left: -8,
        background: "black"
      }
    },
    innerUl: {
      paddingTop: 7,
      paddingLeft: 17
    },
    innerLi: {
      "&:before": {
        border: "solid 1px #4a4a4a",
        background: "#fff"
      }
    },
    checkboxLi: {
      margin: "24px 0 0 30px",
      "& > div:nth-last-child(-n + 2) > div": { margin: "38px 0 0" },
      "& > div:last-child > div": { paddingBottom: "10px" }
    },
    checkbox: {
      margin: "-36px 0 0 0"
    },
    buttons: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 32
      }
    },
    space: {
      marginLeft: 20
    },
    lineSpace: {
      marginBottom: 20
    }
  });

type BaseProps = {
  isModalOpen: boolean;
  onClose: () => void;
  history: H.History;
  serviceType: FacilityType;
};

type SupportPinUserProps = {
  uifId: string;
  supportPlanId?: string;
  accountId: number;
};

type Props = SupportPinUserProps & BaseProps & WithStyles<typeof styles>;

/**
 * 個別支援計画の印刷モーダル
 * ※モニタリング無し
 */
const SupportPlanPrintModalCore = (props: Props): JSX.Element => {
  const localStorageKey = getModalStampIconKey(
    "support_plan",
    props.uifId,
    props.accountId
  );

  const getInitialIconFlag = (key: string): boolean => {
    const savedIconFlag = getModalStampIconCheck(key);
    return savedIconFlag !== null ? savedIconFlag : true;
  };

  const initialIconFlag = getInitialIconFlag(localStorageKey);

  const [infoFlag, setInfoFlag] = React.useState(false);
  const [commentFlag, setCommentFlag] = React.useState(false);
  const [minutesFlag, setMinutesFlag] = React.useState(false);
  const [iconFlag, setIconFlag] = React.useState(initialIconFlag);

  // event handler
  const onCloseModal = (): void => {
    props.onClose();
  };
  const changeInfoFlag = (): void => {
    setInfoFlag(!infoFlag);
  };
  const changeCommentFlag = (): void => {
    setCommentFlag(!commentFlag);
  };
  const changeMinutesFlag = (): void => {
    setMinutesFlag(!minutesFlag);
  };
  const changeIconFlag = (): void => {
    const newIconFlag = !iconFlag;
    setIconFlag(!iconFlag);
    setModalStampIconCheck(localStorageKey, newIconFlag);
  };

  const moveToPreview = (): void => {
    const params = [];
    if (infoFlag) {
      params.push("display_info=staff_support_info");
    }
    if (commentFlag) {
      params.push("display_comment=staff_comment");
    }
    if (minutesFlag) {
      params.push("display_minutes=staff_minutes");
    }
    if (iconFlag) {
      params.push("display_icon=stamp_icon");
    }
    const printOptions = params.length > 0 ? `?${params.join("&")}` : "";
    props.history.push(
      `/record/print/${props.uifId}/support_plan/${props.supportPlanId}${printOptions}`
    );
  };

  return (
    <div>
      <Dialog open={props.isModalOpen} disableBackdropClick>
        <DialogTitle className={props.classes.dialogHeader}>
          <span>個別支援計画書</span>
          <span className={props.classes.space}>印刷項目</span>
        </DialogTitle>
        <DialogContent className={props.classes.dialogContent}>
          {props.serviceType === FacilityType.A ||
          props.serviceType === FacilityType.B ||
          props.serviceType === FacilityType.IKOU ||
          props.serviceType === FacilityType.SHUROTEICHAKU ||
          props.serviceType === FacilityType.GROUP_HOME ||
          props.serviceType === FacilityType.SEIKATSUKAIGO ? (
            <div
              className={ClassNames(
                props.classes.dialogSubTitle,
                props.classes.dialogSubTitleForA
              )}
            >
              以下の項目以外で入力された項目が印刷されます。
              <br />
              追加で印刷したい項目にチェックしてください。
            </div>
          ) : (
            <>
              <ul>
                <li className={props.classes.li}>本人・家族の意向</li>
                <li
                  className={ClassNames(
                    props.classes.li,
                    props.classes.lineSpace
                  )}
                >
                  本人の現状
                </li>
                <li className={props.classes.li}>長期目標</li>
                <li className={props.classes.li}>短期目標</li>
                <li className={props.classes.li}>本人の取組内容</li>
                <li
                  className={ClassNames(
                    props.classes.li,
                    props.classes.lineSpace
                  )}
                >
                  備考
                </li>
              </ul>
              <div className={props.classes.dialogSubTitle}>
                追加で印刷したい項目にチェックしてください。
              </div>
            </>
          )}
          <div className={props.classes.checkboxLi}>
            <div className={props.classes.checkbox}>
              <MuiCheckbox
                id="info_flg"
                label="職員の支援内容"
                checked={infoFlag}
                value={STRING_TRUE_FROM_API}
                onChange={changeInfoFlag}
              />
            </div>
            <div className={props.classes.checkbox}>
              <MuiCheckbox
                id="comment_flg"
                label="職員コメント"
                checked={commentFlag}
                value={STRING_TRUE_FROM_API}
                onChange={changeCommentFlag}
              />
            </div>
            <div className={props.classes.checkbox}>
              <MuiCheckbox
                id="minutes_flg"
                label="個別支援会議 議事録"
                checked={minutesFlag}
                value={STRING_TRUE_FROM_API}
                onChange={changeMinutesFlag}
              />
            </div>
            <div className={props.classes.checkbox}>
              <MuiCheckbox
                id="icon_flg"
                label="印鑑マーク"
                checked={iconFlag}
                value={STRING_TRUE_FROM_API}
                onChange={changeIconFlag}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={props.classes.dialogFooter}>
          <KnowbeButton onClick={onCloseModal} kind="outlineWhite">
            キャンセル
          </KnowbeButton>
          <Button
            className={props.classes.buttons}
            variant="contained"
            color="secondary"
            onClick={moveToPreview}
          >
            確定する
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const SupportPlanPrintModal = connect(null)(
  withStyles(styles)(SupportPlanPrintModalCore)
);
