import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { ServiceTypes } from "@constants/variables";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";

import InvoiceSummaryCity from "@components/organisms/download/print/InvoiceSummaryCity";
import InvoiceSummaryTotal from "@components/organisms/download/print/InvoiceSummaryTotal";
import InvoiceSummaryDetailGroupHome from "@components/organisms/download/print/InvoiceSummaryDetailGroupHome";
import { InvoiceSummaryDetailMultiple } from "@components/organisms/download/print/InvoiceSummaryDetailMultiple";
import { InvoiceSummaryDetailRegionalConsultation } from "@components/organisms/download/print/InvoiceSummaryDetailRegionalConsultation";
import { InvoiceSummaryCityPlanningConsultation } from "@components/organisms/download/print/InvoiceSummaryCityPlanningConsultation";
import { InvoiceSummaryDetailPlanningConsultation } from "@components/organisms/download/print/InvoiceSummaryDetailPlanningConsultation";

type OwnProps = {
  year: string;
  month: string;
  idList: string;
  typeConsultation?: string;
};
type DispatchProps = {
  handleLogout: () => void;
  fetchUser: () => void;
  fetchSeikyu: (
    year: string,
    month: string,
    excludeUserIds: number[],
    typeConsultation?: string
  ) => Promise<void>;
};

type Props = OwnProps & DispatchProps & AppState;

/**
 * 請求書・明細書のプリント画面
 */
class SeikyuPreview extends React.Component<Props> {
  public componentWillMount(): void {
    const { year, month, idList, typeConsultation } = this.props;
    const excludedUserIds = idList
      ? (idList.split(",").map((str) => parseInt(str, 10)) as number[])
      : ([] as number[]);

    this.props.fetchSeikyu(year, month, excludedUserIds, typeConsultation);
  }

  public render(): JSX.Element | string {
    const {
      summaryByCity,
      summaryByMonth
    } = this.props.invoice.invoiceSeikyuData;

    if (!summaryByMonth || !summaryByMonth) {
      return "";
    }
    return (
      <PreviewTemplate>
        <InvoiceSummaryTotal data={summaryByMonth} />
        {summaryByCity.map((city) => {
          const { users } = city.detail;
          const userDetail = users.map((user) => {
            switch (user.serviceTypes[0].s01) {
              case ServiceTypes.GROUP_HOME:
                return (
                  <InvoiceSummaryDetailGroupHome
                    data={city.detail}
                    key={user.m05}
                    user={user}
                  />
                );
              case ServiceTypes.CHIIKIIKO:
              case ServiceTypes.CHIIKITEICHAKU:
                return (
                  <InvoiceSummaryDetailRegionalConsultation
                    data={city.detail}
                    key={user.m05}
                    user={user}
                  />
                );
              case ServiceTypes.KEIKAKUSODAN:
                return (
                  <InvoiceSummaryDetailPlanningConsultation
                    data={city.detail}
                    key={user.m05}
                    user={user}
                    typeConsultation={this.props.typeConsultation}
                  />
                );
              default:
                return (
                  <InvoiceSummaryDetailMultiple
                    data={city.detail}
                    key={user.m05}
                    user={user}
                  />
                );
            }
          });
          switch (users[0].serviceTypes[0].s01) {
            case ServiceTypes.KEIKAKUSODAN:
              return (
                <React.Fragment key={city.demand}>
                  <InvoiceSummaryCityPlanningConsultation
                    data={city}
                    typeConsultation={this.props.typeConsultation}
                  />
                  {userDetail}
                </React.Fragment>
              );
            default:
              return (
                <React.Fragment key={city.demand}>
                  <InvoiceSummaryCity data={city} />
                  {userDetail}
                </React.Fragment>
              );
          }
        })}
      </PreviewTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch, userDispatch, invoiceDispatch } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);
  return {
    handleLogout: authDispatch(dispatch).logout,
    fetchUser: userDispatch(dispatch).me,
    fetchSeikyu: (
      year: string,
      month: string,
      excludeUserIds: number[],
      typeConsultation
    ): Promise<void> =>
      invoiceDispatched.downloadSeikyuJson(
        year,
        month,
        excludeUserIds,
        typeConsultation
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SeikyuPreview);
