import * as types from "./types";
import { GetPlanMonitoringMeetingResponse } from "@api/requests/planMonitoringMeeting/getPlanMonitoringMeeting";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchPlanMonitoringMeetingStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_STARTED } as const);
export const fetchPlanMonitoringMeetingSuccess = (
  res: GetPlanMonitoringMeetingResponse["data"]
) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_SUCCESS,
    payload: res
  } as const);
export const fetchPlanMonitoringMeetingFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_PLAN_MONITORING_MEETING_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchPlanMonitoringMeetingStarted>
  | ReturnType<typeof fetchPlanMonitoringMeetingSuccess>
  | ReturnType<typeof fetchPlanMonitoringMeetingFailed>;
