import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      margin: "32px 16px",
      maxWidth: "328px"
    },
    title: {
      fontSize: 20,
      lineHeight: 1.6,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "16px 24px"
    },
    content: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 24px",
      margin: "8px 0"
    },
    actions: {
      margin: "16px 24px",
      justifyContent: "center"
    },
    button: {
      height: "40px"
    }
  });

type Props = { open: boolean } & WithStyles<typeof styles>;

const ExpiredTokenModalCore = ({ open, classes }: Props): JSX.Element => {
  const onClick = (): void => {
    window.location.reload();
  };
  return (
    <Dialog classes={{ paper: classes.paper }} open={open}>
      <DialogTitle className={classes.title}>
        画面を再読み込みしてください
      </DialogTitle>
      <DialogContent className={classes.content}>
        別のアカウントに切り替わっている可能性があるため、画面を再読み込みしてください。
      </DialogContent>
      <DialogActions className={classes.actions}>
        <KnowbeButton className={classes.button} onClick={onClick} kind="text">
          再読み込み
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const ExpiredTokenModal = React.memo(
  withStyles(styles)(ExpiredTokenModalCore),
  (prev, next) => prev.open === next.open
);
