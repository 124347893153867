import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: 32
    },
    label: {
      margin: 0,
      fontSize: 12,
      lineHeight: 20 / 12,
      letterSpacing: 0.4,
      color: "#37474f"
    },
    currentEmail: {
      margin: "8px 0 0",
      fontSize: 16,
      lineHeight: 1,
      letterSpacing: 0.5,
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 600
    }
  });

type OwnProps = {
  label: string;
  account: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CurrentAccountNameCore = ({
  label,
  account,
  classes
}: Props): JSX.Element => (
  <div className={classes.wrapper}>
    <p className={classes.label}>{label}</p>
    <p className={classes.currentEmail}>{account}</p>
  </div>
);

export const CurrentAccountName = withStyles(styles)(CurrentAccountNameCore);
