import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as operationsActions from "@stores/domain/operations/actions";
import * as operationsGroupHomeActions from "@stores/domain/mgr/GroupHome/operations/actions";
import * as userInFacilityGroupHomeActions from "@stores/domain/mgr/GroupHome/userInFacility/actions";
import * as operationsSHISETSUNYUSHOActions from "@stores/domain/mgr/SHISETSUNYUSHO/operations/actions";
import * as operationsCsegActions from "@stores/domain/mgr/Cseg/operations/actions";
import * as userInFacilitySHISETSUNYUSHOActions from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/actions";
import * as userInFacilityTANKINYUSHOActions from "@stores/domain/mgr/TANKINYUSHO/userInFacility/actions";
import * as userInFacilityKEIKAKUSODANActions from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/actions";
import * as unitsActions from "@stores/domain/units/actions";
import * as customRecordsActions from "@stores/domain/customRecords/actions";
import * as workActions from "@stores/domain/work/actions";
import * as staffActions from "@stores/domain/staff/actions";
import * as holidayActions from "@stores/domain/holiday/actions";
import operationsDispatcher from "@stores/domain/operations/dispatcher";
import operationsGroupHomeDispatcher from "@stores/domain/mgr/GroupHome/operations/dispatcher";
import operationsTANKINYUSHODispatcher from "@stores/domain/mgr/TANKINYUSHO/operations/dispatcher";
import operationsSHISETSUNYUSHODispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/operations/dispatcher";
import { CsegOperationsDispatcher } from "@stores/domain/mgr/Cseg/operations/dispatcher";
import operationsApi from "@api/requests/operations";
import operationsTANKINYUSHOApi from "@api/requests/operations/TANKINYUSHO";
import facilityApi from "@api/requests/facility";
import unitsApi from "@api/requests/units";
import workApi from "@api/requests/work";
import staffApi from "@api/requests/staff";
import customRecordsApi from "@api/requests/customRecords";
import CsegOperationsApi from "@api/requests/operations/Cseg";
import { getHoliday } from "@api/requests/holiday/getHoliday";
import { RecordMonthlyValues as SEIKATSUKAIGOFormValues } from "@initialize/mgr/SEIKATSUKAIGO/record/monthly/initialValues";
import { RecordMonthlyValues as IABFormValues } from "@initialize/mgr/IAB/record/monthly/initialValues";
import { RecordOperationsValues } from "@initialize/mgr/GroupHome/record/operations/initialValues";
import { RecordOperationsValues as TANKINYUSHORecordOperationsValues } from "@initialize/mgr/TANKINYUSHO/record/operations/initialValues";
import { RecordOperationsValues as SHISETSUNYUSHORecordOperationsValues } from "@initialize/mgr/SHISETSUNYUSHO/record/operations/initialValues";
import normalizeFormValuesToPostOperationsParams, {
  normalizeFormValuesToPostCsegOperationParams,
  normalizeFormValuesToPostCustomOperationParams,
  normalizeFormValuesToPostCustomOperationParamsTANKINYUSHO
} from "./normalizer";
import { WorkState } from "@stores/domain/work/types";
import { StaffState } from "@stores/domain/staff/types";
import { CUSTOM_RECORD_TARGET_TYPE } from "@constants/variables";
import { OperationsValues } from "@initialize/mgr/Cseg/record/operations/initialValues";

type RecordMonthlyValues = SEIKATSUKAIGOFormValues | IABFormValues;

/**
 * 業務日誌初回用、MonthlyRecord + work + staff全取得
 */
export const fetchInitialMonthlyRecord = (dispatch: Dispatch) => async (
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());

  dispatch(operationsActions.fetchMonthlyRecordStarted());
  dispatch(workActions.fetchStarted());
  dispatch(staffActions.fetchStarted());
  Promise.all([
    operationsApi.getOperations(year, month),
    workApi.getWork(),
    staffApi.getStaff()
  ])
    .then((results) => {
      dispatch(
        operationsActions.fetchMonthlyRecordSuccess(results[0].data.data)
      );
      dispatch(workActions.fetchSuccess(results[1].data));
      dispatch(staffActions.fetchSuccess(results[2].data));
    })
    .catch((e) => {
      dispatch(
        operationsActions.fetchMonthlyRecordFailed({ error: e.response })
      );
      dispatch(workActions.fetchFailed({ error: e.response }));
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌更新
 */
export const postMonthlyRecord = (dispatch: Dispatch) => async (
  year: string,
  month: string,
  params: RecordMonthlyValues,
  initialValues: RecordMonthlyValues,
  state: {
    work: WorkState;
    staff: StaffState;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(operationsActions.postMonthlyRecordStarted());
  const normalizedParams = normalizeFormValuesToPostOperationsParams(
    params,
    initialValues,
    state.work,
    state.staff
  );

  await operationsApi
    .postOperations(normalizedParams)
    .then(async () => {
      dispatch(operationsActions.postMonthlyRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await operationsDispatcher(dispatch).fetchMonthlyRecord(year, month);
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        operationsActions.postMonthlyRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌初回用（GH）
 */
const fetchInitialOperations = (dispatch: Dispatch) => async (
  year: string,
  month: string,
  unitId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(unitsActions.fetchOptionalCustomInfoStarted());

  // 項目設定の状態を先に取得する
  const optionalCustomInfo = await unitsApi
    .getOptionalCustomInfo()
    .then((res) => {
      dispatch(unitsActions.fetchOptionalCustomInfoSuccess(res.data));
      return res.data.data;
    })
    .catch((e) => {
      dispatch(
        unitsActions.fetchOptionalCustomInfoFailed({ error: e.response })
      );
    });

  // エラーを吐いた場合中断
  if (!optionalCustomInfo) {
    dispatch(loadingActions.loadDone());
    return;
  }

  let targetUnitId = unitId;
  // unitIdの初期値をユニットの1番上にする必要があるか判断する
  if (!Array.isArray(optionalCustomInfo)) {
    const {
      use_common_operation_record,
      use_units_operation_record
    } = optionalCustomInfo.operation_record_flg;
    if (
      unitId === 0 &&
      use_common_operation_record === 0 &&
      use_units_operation_record === 1 &&
      optionalCustomInfo.facility_units.length > 0
    ) {
      targetUnitId = optionalCustomInfo.facility_units[0].id;
    }
  }
  // customRecordの取得タイプをunitIdで判断
  const settingType =
    targetUnitId === 0
      ? CUSTOM_RECORD_TARGET_TYPE.operation_common
      : CUSTOM_RECORD_TARGET_TYPE.operation_unit;

  // 残りの必要APIの取得開始
  dispatch(userInFacilityGroupHomeActions.fetchStarted());
  dispatch(operationsGroupHomeActions.fetchOperationsStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());
  dispatch(customRecordsActions.fetchSupportCustomRecordsStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(holidayActions.fetchHolidayStarted());

  Promise.all([
    facilityApi.getFacilityUsers(),
    operationsApi.getCustomRecordOperations(year, month, targetUnitId),
    customRecordsApi.getCustomRecords(settingType),
    customRecordsApi.getCustomRecords(CUSTOM_RECORD_TARGET_TYPE.support),
    staffApi.getStaff(),
    getHoliday(`${year}${month.padStart(2, "0")}`)
  ])
    .then((results) => {
      dispatch(userInFacilityGroupHomeActions.fetchSuccess(results[0].data));
      dispatch(
        operationsGroupHomeActions.fetchOperationsSuccess(results[1].data.data)
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[2].data.data)
      );
      dispatch(
        customRecordsActions.fetchSupportCustomRecordsSuccess(
          results[3].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[4].data));
      dispatch(holidayActions.fetchHolidaySuccess(results[5].data.data));
    })
    .catch((e) => {
      dispatch(
        userInFacilityGroupHomeActions.fetchFailed({ error: e.response })
      );
      dispatch(
        operationsGroupHomeActions.fetchOperationsFailed({ error: e.response })
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(
        customRecordsActions.fetchSupportCustomRecordsFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(holidayActions.fetchHolidayFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌更新 (GH)
 */
const postCustomOperation = (dispatch: Dispatch) => async (
  params: RecordOperationsValues,
  initialValues: RecordOperationsValues,
  target_date: string,
  unitId: number
): Promise<void> => {
  const normalizedParams = normalizeFormValuesToPostCustomOperationParams(
    params,
    initialValues,
    target_date
  );
  const yyyymmdd = target_date.replace(/-/g, "");
  await operationsApi
    .postCustomOperation(normalizedParams, yyyymmdd)
    .then(async () => {
      dispatch(operationsActions.postMonthlyRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      const year = yyyymmdd.slice(0, 4);
      const month = `${+yyyymmdd.slice(4, 6)}`;
      await operationsGroupHomeDispatcher(dispatch).fetchOperations(
        year,
        month,
        unitId
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        operationsActions.postMonthlyRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌初回用（TANKINYUSHO）
 */
const fetchTANKINYUSHOInitialOperations = (
  dispatch: Dispatch
) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(userInFacilityTANKINYUSHOActions.fetchStarted());
  dispatch(staffActions.fetchStarted());

  Promise.all([facilityApi.getFacilityUsers(), staffApi.getStaff()])
    .then((results) => {
      dispatch(userInFacilityTANKINYUSHOActions.fetchSuccess(results[0].data));
      dispatch(staffActions.fetchSuccess(results[1].data));
    })
    .catch((e) => {
      dispatch(
        userInFacilityTANKINYUSHOActions.fetchFailed({ error: e.response })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌更新 (TANKINYUSHO)
 */
const postTANKINYUSHOCustomOperation = (dispatch: Dispatch) => async (
  params: TANKINYUSHORecordOperationsValues,
  initialValues: TANKINYUSHORecordOperationsValues,
  target_date: string
): Promise<void> => {
  const normalizedParams = normalizeFormValuesToPostCustomOperationParamsTANKINYUSHO(
    params,
    initialValues,
    target_date
  );
  const yyyymmdd = target_date.replace(/-/g, "");
  await operationsTANKINYUSHOApi
    .postCustomOperation(normalizedParams, yyyymmdd)
    .then(async () => {
      dispatch(operationsActions.postMonthlyRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      const year = yyyymmdd.slice(0, 4);
      const month = `${+yyyymmdd.slice(4, 6)}`;
      await operationsTANKINYUSHODispatcher(dispatch).fetchOperations(
        year,
        month
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        operationsActions.postMonthlyRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌初回用（施設入所）
 */
const fetchSHISETSUNYUSHOInitialOperations = (dispatch: Dispatch) => async (
  year: string,
  month: string,
  unitId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(unitsActions.fetchOptionalCustomInfoStarted());

  // 項目設定の状態を先に取得する
  const optionalCustomInfo = await unitsApi
    .getOptionalCustomInfo()
    .then((res) => {
      dispatch(unitsActions.fetchOptionalCustomInfoSuccess(res.data));
      return res.data.data;
    })
    .catch((e) => {
      dispatch(
        unitsActions.fetchOptionalCustomInfoFailed({ error: e.response })
      );
    });

  // エラーを吐いた場合中断
  if (!optionalCustomInfo) {
    dispatch(loadingActions.loadDone());
    return;
  }

  let targetUnitId = unitId;
  // unitIdの初期値をユニットの1番上にする必要があるか判断する
  if (!Array.isArray(optionalCustomInfo)) {
    const {
      use_common_operation_record,
      use_units_operation_record
    } = optionalCustomInfo.operation_record_flg;
    if (
      unitId === 0 &&
      use_common_operation_record === 0 &&
      use_units_operation_record === 1 &&
      optionalCustomInfo.facility_units.length > 0
    ) {
      targetUnitId = optionalCustomInfo.facility_units[0].id;
    }
  }
  // customRecordの取得タイプをunitIdで判断
  const settingType =
    targetUnitId === 0
      ? CUSTOM_RECORD_TARGET_TYPE.operation_common
      : CUSTOM_RECORD_TARGET_TYPE.operation_unit;

  // 残りの必要APIの取得開始
  dispatch(userInFacilitySHISETSUNYUSHOActions.fetchStarted());
  dispatch(operationsSHISETSUNYUSHOActions.fetchOperationsStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());
  dispatch(customRecordsActions.fetchSupportCustomRecordsStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(holidayActions.fetchHolidayStarted());

  Promise.all([
    facilityApi.getFacilityUsers(),
    operationsApi.getCustomRecordOperations(year, month, targetUnitId),
    customRecordsApi.getCustomRecords(settingType),
    customRecordsApi.getCustomRecords(CUSTOM_RECORD_TARGET_TYPE.support),
    staffApi.getStaff(),
    getHoliday(`${year}${month.padStart(2, "0")}`)
  ])
    .then((results) => {
      dispatch(
        userInFacilitySHISETSUNYUSHOActions.fetchSuccess(results[0].data)
      );
      dispatch(
        operationsSHISETSUNYUSHOActions.fetchOperationsSuccess(
          results[1].data.data
        )
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[2].data.data)
      );
      dispatch(
        customRecordsActions.fetchSupportCustomRecordsSuccess(
          results[3].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[4].data));
      dispatch(holidayActions.fetchHolidaySuccess(results[5].data.data));
    })
    .catch((e) => {
      dispatch(
        userInFacilitySHISETSUNYUSHOActions.fetchFailed({ error: e.response })
      );
      dispatch(
        operationsSHISETSUNYUSHOActions.fetchOperationsFailed({
          error: e.response
        })
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(
        customRecordsActions.fetchSupportCustomRecordsFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(holidayActions.fetchHolidayFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌更新 (施設入所)
 */
const postSHISETSUNYUSHOCustomOperation = (dispatch: Dispatch) => async (
  params: RecordOperationsValues,
  initialValues: RecordOperationsValues,
  target_date: string,
  unitId: number
): Promise<void> => {
  const normalizedParams = normalizeFormValuesToPostCustomOperationParams(
    params,
    initialValues,
    target_date
  );
  const yyyymmdd = target_date.replace(/-/g, "");
  await operationsApi
    .postCustomOperation(normalizedParams, yyyymmdd)
    .then(async () => {
      dispatch(operationsActions.postMonthlyRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      const year = yyyymmdd.slice(0, 4);
      const month = `${+yyyymmdd.slice(4, 6)}`;
      await operationsSHISETSUNYUSHODispatcher(dispatch).fetchOperations(
        year,
        month,
        unitId
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        operationsActions.postMonthlyRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌初回用（Cseg-計画相談）
 */
const fetchCsegInitialOperations = (dispatch: Dispatch) => async (
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());

  // 残りの必要APIの取得開始
  dispatch(userInFacilityKEIKAKUSODANActions.fetchStarted());
  dispatch(operationsCsegActions.fetchOperationsStarted());
  dispatch(holidayActions.fetchHolidayStarted());

  Promise.all([
    facilityApi.getFacilityUsers(),
    CsegOperationsApi.getOperations(year, month),
    getHoliday(`${year}${month.padStart(2, "0")}`)
  ])
    .then((results) => {
      dispatch(userInFacilityKEIKAKUSODANActions.fetchSuccess(results[0].data));
      dispatch(
        operationsCsegActions.fetchOperationsSuccess(results[1].data.data)
      );
      dispatch(holidayActions.fetchHolidaySuccess(results[2].data.data));
    })
    .catch((e) => {
      dispatch(
        userInFacilityKEIKAKUSODANActions.fetchFailed({ error: e.response })
      );
      dispatch(
        operationsCsegActions.fetchOperationsFailed({
          error: e.response
        })
      );
      dispatch(holidayActions.fetchHolidayFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 業務日誌更新 (Cseg計画相談)
 */
const postCsegOperation = (dispatch: Dispatch) => async (
  params: OperationsValues,
  initialValues: OperationsValues,
  target_date: string
): Promise<void> => {
  const normalizedParams = normalizeFormValuesToPostCsegOperationParams(
    params,
    initialValues,
    target_date
  );
  const yyyymmdd = target_date.replace(/-/g, "");
  await CsegOperationsApi.postOperations(normalizedParams, yyyymmdd)
    .then(async () => {
      dispatch(operationsActions.postMonthlyRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      const year = yyyymmdd.slice(0, 4);
      const month = `${+yyyymmdd.slice(4, 6)}`;
      await CsegOperationsDispatcher(dispatch).fetchOperations(year, month);
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        operationsActions.postMonthlyRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchInitialMonthlyRecord: (year: string, month: string) => Promise<void>;
  postMonthlyRecord: (
    year: string,
    month: string,
    params: RecordMonthlyValues,
    initialValues: RecordMonthlyValues,
    state: {
      work: WorkState;
      staff: StaffState;
    }
  ) => Promise<void>;
  fetchInitialOperations: (
    year: string,
    month: string,
    unitId: number
  ) => Promise<void>;
  postCustomOperation: (
    params: RecordOperationsValues,
    initialValues: RecordOperationsValues,
    target_date: string,
    unitId: number
  ) => Promise<void>;
  fetchTANKINYUSHOInitialOperations: () => Promise<void>;
  postTANKINYUSHOCustomOperation: (
    params: TANKINYUSHORecordOperationsValues,
    initialValues: TANKINYUSHORecordOperationsValues,
    target_date: string
  ) => Promise<void>;
  fetchSHISETSUNYUSHOInitialOperations: (
    year: string,
    month: string,
    unitId: number
  ) => Promise<void>;
  postSHISETSUNYUSHOCustomOperation: (
    params: SHISETSUNYUSHORecordOperationsValues,
    initialValues: SHISETSUNYUSHORecordOperationsValues,
    target_date: string,
    unitId: number
  ) => Promise<void>;
  fetchCsegInitialOperations: (year: string, month: string) => Promise<void>;
  postCsegOperation: ReturnType<typeof postCsegOperation>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchInitialMonthlyRecord: fetchInitialMonthlyRecord(dispatch),
  postMonthlyRecord: postMonthlyRecord(dispatch),
  fetchInitialOperations: fetchInitialOperations(dispatch),
  postCustomOperation: postCustomOperation(dispatch),
  fetchTANKINYUSHOInitialOperations: fetchTANKINYUSHOInitialOperations(
    dispatch
  ),
  postTANKINYUSHOCustomOperation: postTANKINYUSHOCustomOperation(dispatch),
  fetchSHISETSUNYUSHOInitialOperations: fetchSHISETSUNYUSHOInitialOperations(
    dispatch
  ),
  postSHISETSUNYUSHOCustomOperation: postSHISETSUNYUSHOCustomOperation(
    dispatch
  ),
  fetchCsegInitialOperations: fetchCsegInitialOperations(dispatch),
  postCsegOperation: postCsegOperation(dispatch)
});
