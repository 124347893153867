import { RecordOperationsValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { ErrorMessage } from "@validator";

type RecordOperationsErrors = {
  record: ValidationErrors<RecordOperationsValues["record"]>;
};
type CustomRecordFirstErrorMessage = {
  [custom_record_id: string]: { input_data: ErrorMessage };
};

const validateCustomRecordFirst = (
  customRecords: RecordOperationsValues["record"][0]["custom_record"]["input_type_first"]
): CustomRecordFirstErrorMessage => {
  const obj = {};
  Object.keys(customRecords).forEach((item) => {
    Object.assign(obj, {
      [item]: {
        input_data: validator(customRecords[`${item}`].input_data, {
          type: "checkCharacterLength",
          length: 2500
        })
      }
    });
  });
  return obj;
};

const validation = (values: RecordOperationsValues): RecordOperationsErrors => {
  return {
    record: values.record
      ? values.record.map((item) => ({
          custom_record: {
            input_type_first: validateCustomRecordFirst(
              item.custom_record.input_type_first
            )
          }
        }))
      : []
  };
};

export default validation;
