import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FieldWrapper, { FieldSizeName } from "@components/atoms/FieldWrapper";
import InputAdornment from "@material-ui/core/InputAdornment";

const styles = (): StyleRules =>
  createStyles({
    adornmentLabel: {
      "& > p": {
        color: "rgba(0, 0, 0, 0.87)",
        width: "max-content",
        fontSize: 16
      }
    },
    label: {
      color: "#37474f!important"
    },
    input: {
      color: "rgba(0, 0, 0, 0.87)!important",
      "-webkit-text-fill-color": "rgba(0, 0, 0, 0.87)",
      backgroundColor: "rgba(0, 0, 0, 0.02)",
      borderRadius: "4px"
    },
    inputBase: {
      height: 19
    },
    notchedOutline: {
      border: "none"
    },
    focused: {},
    disabled: {},
    error: {}
  });

type OwnProps = {
  value: string;
  defaultValue: string;
  size?: FieldSizeName;
  label?: string;
  multiline?: boolean;
  rows?: number;
  rowsMax?: number;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  style?: React.CSSProperties;
  InputLabelStyle?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * テキストフィールド（表示用）
 */
const ReadonlyTextFieldCore = (props: Props): JSX.Element => {
  const endAdornment = props.endAdornment ? (
    <InputAdornment
      position="end"
      classes={{ root: props.classes.adornmentLabel }}
    >
      {props.endAdornment}
    </InputAdornment>
  ) : null;

  const startAdornment = props.startAdornment ? (
    <InputAdornment
      position="start"
      classes={{ root: props.classes.adornmentLabel }}
    >
      {props.startAdornment}
    </InputAdornment>
  ) : null;

  return (
    <FieldWrapper
      size={props.size}
      style={{ marginBottom: 24, marginRight: 0, ...props.style }}
    >
      <TextField
        fullWidth
        InputProps={{
          startAdornment,
          endAdornment,
          classes: {
            root: props.classes.input,
            input: props.classes.inputBase,
            notchedOutline: props.classes.notchedOutline
          }
        }}
        InputLabelProps={{
          shrink: true,
          FormLabelClasses: {
            root: props.InputLabelStyle || props.classes.label
          }
        }}
        multiline={props.multiline || false}
        variant="outlined"
        label={props.label}
        value={props.value || props.defaultValue}
        disabled
        rows={props.rows}
        rowsMax={props.rowsMax}
      />
    </FieldWrapper>
  );
};

export const ReadonlyTextField = withStyles(styles)(ReadonlyTextFieldCore);
