import { GetSupportLedgerListResponse } from "@api/requests/supportLedger/getSupportLedgerList";
import { GetSupportLedgerResponse } from "@api/requests/supportLedger/getSupportLedger";

/**
 * ActionNames
 */
export const FETCH_SUPPORT_LEDGER_STARTED =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_STARTED";
export const FETCH_SUPPORT_LEDGER_SUCCESS =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_SUCCESS";
export const FETCH_SUPPORT_LEDGER_FAILED =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_FAILED";

export const FETCH_SUPPORT_LEDGER_LIST_STARTED =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_LIST_STARTED";
export const FETCH_SUPPORT_LEDGER_LIST_SUCCESS =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_LIST_SUCCESS";
export const FETCH_SUPPORT_LEDGER_LIST_FAILED =
  "SUPPORT_LEDGER/FETCH_SUPPORT_LEDGER_LIST_FAILED";

export const POST_SUPPORT_LEDGER_STARTED =
  "SUPPORT_LEDGER/POST_SUPPORT_LEDGER_STARTED";
export const POST_SUPPORT_LEDGER_SUCCESS =
  "SUPPORT_LEDGER/POST_SUPPORT_LEDGER_SUCCESS";
export const POST_SUPPORT_LEDGER_FAILED =
  "SUPPORT_LEDGER/POST_SUPPORT_LEDGER_FAILED";
export const UNSET_SUPPORT_LEDGER = "SUPPORT_LEDGER/UNSET_SUPPORT_LEDGER";

export const DELETE_SUPPORT_LEDGER_STARTED =
  "SUPPORT_LEDGER/DELETE_SUPPORT_LEDGER_STARTED";
export const DELETE_SUPPORT_LEDGER_SUCCESS =
  "SUPPORT_LEDGER/DELETE_SUPPORT_LEDGER_SUCCESS";
export const DELETE_SUPPORT_LEDGER_FAILED =
  "SUPPORT_LEDGER/DELETE_SUPPORT_LEDGER_FAILED";

/**
 * State
 */
export type SupportLedgerState = {
  supportLedger: GetSupportLedgerResponse["data"];
  supportLedgerList: GetSupportLedgerListResponse["data"];
};
