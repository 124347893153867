import * as React from "react";
import * as H from "history";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { RecordHeader } from "@components/organisms/mgr/KEIKAKUSODAN/record/RecordHeader";
import { Formik, Form, FormikActions, FormikProps } from "formik";
import { ConsultationFields } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/ConsultationFields";
import { CommonPrintModalWithStaffComment } from "@components/organisms/mgr/common/record/CommonPrintModalWithStaffComment";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  ConsultationState,
  COPY_TYPE,
  RESET_COPY_CONSULTATION
} from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FieldItem } from "@interfaces/ui/form";
import {
  initialValuesNew,
  initialValuesEdit,
  ConsultationValues
} from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";
import { validation } from "@initialize/mgr/KEIKAKUSODAN/record/consultation/validation";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";
import { resetCopyConsultation } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/actions";
import isEqual from "lodash-es/isEqual";
import { LoadPlanModal } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/LoadPlanModal";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      margin: "32px auto"
    },
    con: {
      background: "#fff",
      padding: "16px 32px 32px"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteButton: {
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  user: UsersInFacilityState["user"];
  userInFacilityId: number;
  history: H.History;
  pageName: string;
  consultationTargetFlg: number;
  isNew?: boolean;
  consultationId?: string;
  facilityType: number | null;
  type?: number;
} & WithStyles<typeof styles>;

type StateProps = {
  consultation: ConsultationState;
  staff: StaffState;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  fetchConsultation: (uifId: string, consultationId: string) => Promise<void>;
  copyConsultation: (
    uifId: string,
    consultationId: string,
    copyType: string
  ) => Promise<void>;
  postConsultation: (
    uifId: number,
    values: ConsultationValues,
    initialValues?: ConsultationValues,
    type?: number
  ) => Promise<void>;
  resetCopyConsultation: () => void;
  deleteConsultation: (uifId: string, id: string) => void;
};

type MergeProps = {
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
} & OwnProps &
  StateProps &
  DispatchProps;

type Props = MergeProps;

const ConsultationFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    consultation,
    staffOptions,
    staffOptionsAddSnapShot,
    authorValue,
    fetchConsultation,
    user,
    userInFacilityId,
    history,
    facilityType,
    isNew,
    consultationTargetFlg,
    planMonitoringMeeting,
    type
  } = props;

  const { consultationRecord, copyType, consultationCopy } = consultation;

  const [formValues, setFormValues] = React.useState(
    isNew
      ? initialValuesNew(user, consultationTargetFlg, props.type)
      : initialValuesEdit(consultationRecord, staffOptions)
  );
  const [isEditing, setIsEditing] = React.useState(!!isNew);
  // 計画の詳細読み込みモーダル
  const [isOpenLoadModal, setIsOpenLoadModal] = React.useState<boolean>(false);
  // 計画の詳細上書きフラグ
  const [isDetailUpdate, setIsDetailUpdate] = React.useState<boolean>(false);
  // 週間計画表読み込みモーダル
  const [isOpenScheduleModal, setIsOpenScheduleModal] = React.useState<boolean>(
    false
  );
  // 週間計画表上書きフラグ
  const [isScheduleUpdate, setIsScheduleUpdate] = React.useState<boolean>(
    false
  );
  // 削除確認モーダル
  const [isOpenDeleteModal, setOpenDeleteModal] = React.useState(false);

  React.useEffect(() => {
    if (isNew) {
      setFormValues(initialValuesNew(user, consultationTargetFlg, props.type));
    } else {
      setFormValues(initialValuesEdit(consultationRecord, staffOptions));
    }
  }, [consultation]);

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const confirmDiscardFormChanges = (nextValues: ConsultationValues): void => {
    const hasChange = !isEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  // バリデーション
  const validate = (values: ConsultationValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (isEditing) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  // 編集
  const onClickEdit = (): void => {
    setIsEditing(true);
  };

  // キャンセル
  const onClickCancel = (
    formikProps: FormikProps<ConsultationValues>
  ) => (): void => {
    formikProps.resetForm();
    props.stopHistory(false);
    if (isNew) {
      history.goBack();
    } else {
      setIsEditing(false);
    }
  };

  // 読み込みボタン
  const onClickLoad = (isSchedule?: boolean) => (): void => {
    if (isSchedule) {
      setIsOpenScheduleModal(true);
    } else {
      setIsOpenLoadModal(true);
    }
  };

  // 読み込み確定ボタン
  const onSubmitLoad = async (
    id: string,
    isSchedule?: boolean
  ): Promise<void> => {
    await props.copyConsultation(
      String(userInFacilityId),
      id,
      isSchedule ? COPY_TYPE.schedule : COPY_TYPE.details
    );
    if (isSchedule) {
      setIsOpenScheduleModal(false);
    } else {
      setIsOpenLoadModal(false);
    }
  };

  // 読み込みキャンセルボタン
  const onCloseLoad = (isSchedule?: boolean) => (): void => {
    if (isSchedule) {
      setIsOpenScheduleModal(false);
    } else {
      setIsOpenLoadModal(false);
    }
  };

  // 印刷モーダル
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState<boolean>(
    false
  );
  const onOpenPrintModal = (): void => {
    setIsOpenPrintModal(true);
  };
  const onClosePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };
  const onConfirmPrintModal = (paramsOption: string): void => {
    props.history.push(
      `/record/consultation/print/${userInFacilityId}/${consultationRecord.id}/${consultationTargetFlg}${paramsOption}`
    );
  };

  // 削除確認モーダル機能
  const onClickDelete = (): void => {
    setOpenDeleteModal(true);
  };

  const onSubmitDelete = async (): Promise<void> => {
    await props.deleteConsultation(
      String(userInFacilityId),
      String(consultationRecord.id)
    );
    props.stopHistory(false);
    history.push(`/record/${String(userInFacilityId)}/plan_monitoring_meeting`);
  };

  const onClickCancelDelete = (): void => {
    setOpenDeleteModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 更新
  const onSubmit = async (
    values: ConsultationValues,
    actions: FormikActions<ConsultationValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (isNew) {
      await props.postConsultation(userInFacilityId, values, undefined, type);
      actions.setSubmitting(false);
      props.stopHistory(false);
      history.push(
        `/record/${String(userInFacilityId)}/plan_monitoring_meeting`
      );
    } else {
      await props.postConsultation(userInFacilityId, values, formValues);
      actions.setSubmitting(false);
      props.stopHistory(false);
      await fetchConsultation(
        String(userInFacilityId),
        String(consultationRecord.id)
      );
      setIsEditing(false);
    }
  };

  const EditButton = (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onOpenPrintModal}>
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickEdit}>
          編集
        </KnowbeButton>
      </div>
    </div>
  );

  const SubmitButton = (
    formikProps: FormikProps<ConsultationValues>
  ): JSX.Element => (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="outline" onClick={onClickCancel(formikProps)}>
          キャンセル
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <FormikSubmitButton
          buttonName="保存する"
          formikProps={formikProps}
          errorAction={submitError}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.wrapper}>
        {/* フォーム */}
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const FormButton = isEditing
              ? SubmitButton(formikProps)
              : EditButton;
            return (
              <>
                <RecordHeader
                  uifId={`${userInFacilityId}`}
                  recordType="plan_monitoring_meeting"
                  isEditing={isEditing}
                  history={history}
                  pageName={props.pageName}
                  userName={userName}
                  button={FormButton}
                />
                <div className={classes.con}>
                  <Form>
                    <ConsultationFields
                      formikProps={formikProps}
                      user={user}
                      authorValue={authorValue}
                      staffOptions={staffOptions}
                      staffOptionsAddSnapShot={staffOptionsAddSnapShot}
                      copyType={copyType}
                      consultationCopy={consultationCopy}
                      isEditing={isNew || isEditing}
                      consultationTargetFlg={consultationTargetFlg}
                      onClickLoad={onClickLoad}
                      planMonitoringMeeting={planMonitoringMeeting}
                      resetCopyConsultation={props.resetCopyConsultation}
                      setIsDetailUpdate={setIsDetailUpdate}
                      setIsScheduleUpdate={setIsScheduleUpdate}
                      isNew={isNew}
                    />
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {!isNew && isEditing && (
          <DeleteButton
            text={`${props.pageName}を削除する`}
            onClick={onClickDelete}
          />
        )}
      </div>

      {/* 計画の詳細読み込みモーダル */}
      <LoadPlanModal
        isOpen={isOpenLoadModal}
        title="計画の詳細の読み込み"
        onClickSubmit={onSubmitLoad}
        onClose={onCloseLoad()}
        planMonitoringMeeting={planMonitoringMeeting}
        isUpdate={isDetailUpdate}
        targetFlagList={[0, 1]}
        facilityType={facilityType}
      />

      {/* 週間計画表読み込みモーダル */}
      <LoadPlanModal
        isOpen={isOpenScheduleModal}
        title="週間計画表の読み込み"
        onClickSubmit={onSubmitLoad}
        onClose={onCloseLoad(true)}
        planMonitoringMeeting={planMonitoringMeeting}
        isUpdate={isScheduleUpdate}
        facilityType={facilityType}
        isSchedule
      />

      {/* 削除確認モーダル */}
      <MessageDialog
        isOpen={isOpenDeleteModal}
        title={`${props.pageName}を削除します`}
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDelete}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteButton}
            onClick={onSubmitDelete}
          >
            削除する
          </Button>
        }
      />

      {/* 印刷モーダル */}
      <CommonPrintModalWithStaffComment
        title={props.pageName}
        isOpenPrintModal={isOpenPrintModal}
        onClosePrintModal={onClosePrintModal}
        onConfirmPrintModal={onConfirmPrintModal}
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    consultation: state.KEIKAKUSODAN.consultation,
    staff: state.staff,
    planMonitoringMeeting:
      state.KEIKAKUSODAN.planMonitoringMeeting.planMonitoringMeeting
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, KEIKAKUSODAN } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    fetchConsultation: (uifId: string, consultationId: string): Promise<void> =>
      KEIKAKUSODAN.consultationDispatcher(dispatch).fetchConsultation(
        uifId,
        consultationId
      ),
    copyConsultation: (
      uifId: string,
      consultationId: string,
      copyType: string
    ): Promise<void> =>
      KEIKAKUSODAN.consultationDispatcher(dispatch).copyConsultation(
        uifId,
        consultationId,
        copyType
      ),
    postConsultation: (
      userInFacilityId: number,
      values: ConsultationValues,
      initialValues?: ConsultationValues,
      type?: number
    ): Promise<void> =>
      KEIKAKUSODAN.consultationDispatcher(dispatch).postConsultation(
        userInFacilityId,
        values,
        initialValues,
        type
      ),
    resetCopyConsultation: (): {
      type: typeof RESET_COPY_CONSULTATION;
    } => dispatch(resetCopyConsultation()),
    deleteConsultation: (uifId: string, id: string): void => {
      KEIKAKUSODAN.consultationDispatcher(dispatch).deleteConsultation(
        uifId,
        id
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, consultation } = stateProps;

  const author =
    consultation.consultationRecord.author !== null
      ? consultation.consultationRecord.author
      : 0;
  // 作成者名
  const authorValue =
    Object.keys(consultation.consultationRecord).length === 0
      ? ""
      : getSnapOrRealName(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // 作成者
  if (consultation.consultationRecord.author !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      consultation.consultationRecord.author
    );
  }

  return {
    authorValue,
    staffOptions,
    staffOptionsAddSnapShot,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const ConsultationForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConsultationFormCore)
);
