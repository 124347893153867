import * as React from "react";
import { connect } from "react-redux";

import { FacilityType } from "@constants/variables";
import { AppState } from "@stores/type";
import { InvoiceState } from "@stores/v201910/domain/invoice/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import InoutRecordTable from "@components/v201910/organisms/download/print/InoutRecordTable";
import UsagePerformanceTable from "@components/v201910/organisms/download/print/UsagePerformanceTable";
import SEIKATSUKAIGOJisseki from "@components/v201910/organisms/mgr/SEIKATSUKAIGO/download/preview/Jisseki";
import SHUROTEICHAKUJisseki from "@components/v201910/organisms/mgr/SHUROTEICHAKU/download/preview/Jisseki";
import JIRITSUKUNRENSEIKATSUJisseki from "@components/v201910/organisms/mgr/JIRITSUKUNRENSEIKATSU/download/preview/Jisseki";
import TANKINYUSHOJisseki from "@components/v201910/organisms/mgr/TANKINYUSHO/download/preview/Jisseki";
import SHISETSUNYUSHOJisseki from "@components/v201910/organisms/mgr/SHISETSUNYUSHO/download/preview/Jisseki";

type StateProps = {
  invoice: InvoiceState;
};

type Props = StateProps;
/**
 * 実績記録票のプリント画面
 */
const JissekiPrintPreview = (props: Props): JSX.Element | null => {
  if (
    props.invoice.invoiceData &&
    props.invoice.invoiceData.data &&
    props.invoice.invoiceData.data.length === 0
  ) {
    return null;
  }
  return (
    <PreviewTemplate>
      {props.invoice.invoiceData.data.map((invoiceData, key) => {
        const listKey = key;
        switch (invoiceData.facility.kindService) {
          case FacilityType.IKOU:
          case FacilityType.A:
          case FacilityType.B:
            return <InoutRecordTable key={listKey} invoiceData={invoiceData} />;
          case FacilityType.GROUP_HOME:
            return (
              <UsagePerformanceTable key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.SEIKATSUKAIGO:
            return (
              <SEIKATSUKAIGOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.JIRITSUKUNRENSEIKATSU:
            return (
              <JIRITSUKUNRENSEIKATSUJisseki
                key={listKey}
                invoiceData={invoiceData}
              />
            );
          case FacilityType.SHUROTEICHAKU:
            return (
              <SHUROTEICHAKUJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.TANKINYUSHO:
            return (
              <TANKINYUSHOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.SHISETSUNYUSHO:
            return (
              <SHISETSUNYUSHOJisseki key={listKey} invoiceData={invoiceData} />
            );
          default:
            return null;
        }
      })}
    </PreviewTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  invoice: state.v201910.invoice
});

export default connect(mapStateToProps)(JissekiPrintPreview);
