import React from "react";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { OperationsState } from "@stores/domain/mgr/SHISETSUNYUSHO/operations/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FormikProps } from "formik";
import { RecordOperationsValues } from "@initialize/mgr/SHISETSUNYUSHO/record/operations/initialValues";
import Paper from "@material-ui/core/Paper";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import { FieldItem } from "@interfaces/ui/form";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";
import { InsertPhraseModal } from "@components/organisms/mgr/common/record/InsertPhraseModal";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { connect } from "react-redux";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: "32px 32px 8px"
    },
    container: {
      marginBottom: 16
    },
    fieldList: {
      margin: "8px 0 0",
      padding: "0 116px 0 0"
    },
    titleWithButton: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      "& > :first-child": {
        width: "100%"
      }
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      minHeight: 32,
      marginBottom: 1
    },
    buttons: {
      position: "absolute",
      right: 0,
      top: "-8px"
    },
    cancel: {
      marginRight: 8
    },
    inputField: {
      position: "relative"
    },
    fixedButton: {
      position: "absolute",
      top: 0,
      right: -116,
      minWidth: 100,
      minHeight: 30
    },
    checkboxField: {
      display: "flex",
      flexWrap: "wrap",
      margin: "-12px 0"
    },
    checkbox: {
      fontSize: 16,
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  handleClickEdit: () => void;
  handleClickCancel: () => void;
  formikProps: FormikProps<RecordOperationsValues>;
  formikFieldNamePrefix: string;
  isEditing: boolean;
  isEditNotTarget: boolean;
  date: string;
  isHoliday: boolean;
  settingType: number;
  customRecords: CustomRecordsState;
  staffOptions: FieldItem[];
  operation?: OperationsState["operation"][0];
  children?: React.ReactNode;
  index?: number;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

const OperationRecordCore = (props: Props): JSX.Element | null => {
  // 正常に描画されるように値が入っていない場合はreturnする
  if (
    !props.customRecords.length ||
    (props.formikProps.values.record.length > 0 &&
      props.formikProps.values.record[0].custom_record &&
      Object.keys(
        props.formikProps.values.record[0].custom_record.input_type_first
      ).length === 0)
  ) {
    return null;
  }

  const [isInsertPhraseModal, setInsertPhraseModal] = React.useState(false);
  const [insertPhraseId, setInsertPhraseId] = React.useState(0);
  const [insertPhraseFieldName, setInsertPhraseFieldName] = React.useState("");

  const closeInsertPhraseModal = (): void => {
    setInsertPhraseModal(false);
    setInsertPhraseFieldName("");
    setInsertPhraseId(0);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  return (
    <Paper elevation={0} className={props.classes.paper}>
      <UnEditableWrapper
        unEditable={props.isEditNotTarget}
        key={`${props.index}-unedit`}
      >
        <div className={props.classes.titleWithButton}>
          <div className={props.classes.container}>
            <Typography
              gutterBottom
              variant="h6"
              color="primary"
              className={props.classes.flex}
            >
              <div>
                <CustomDateLabel
                  date={props.date}
                  dateFormat="MMMDo（dd）"
                  holiday={props.isHoliday}
                />
              </div>
            </Typography>
            <Divider variant="middle" className={props.classes.divider} />
          </div>
          <div className={props.classes.buttons}>
            {props.isEditing ? (
              <>
                <KnowbeButton
                  kind="outline"
                  onClick={props.handleClickCancel}
                  className={props.classes.cancel}
                >
                  キャンセル
                </KnowbeButton>
                <FormikSubmitButton
                  buttonName="保存する"
                  formikProps={props.formikProps}
                  errorAction={submitError}
                />
              </>
            ) : (
              <KnowbeButton onClick={props.handleClickEdit}>編集</KnowbeButton>
            )}
          </div>
        </div>
        {/* 業務日誌向け: 利用状況サマリ差し込みエリア */}
        {props.children}
        <div className={props.classes.fieldList}>
          {props.customRecords
            .filter((item) => item.setting_type === props.settingType)
            .map((customRecord) => {
              // RecordSupportTableFieldは初期状態がliなのでdivに変更
              const tag = "div" as keyof JSX.IntrinsicElements;
              // 共通で適応出来るprops
              const commonProps = {
                tag,
                key: `${props.index}-${customRecord.id}`,
                label: customRecord.name,
                isExtendsMarginBottom: true
              };
              const textAndMultipleProps = {
                defaultValue: "-",
                placeholder: "",
                isEditing: props.isEditing
              };

              // テキスト形式
              if (customRecord.input_type === 1) {
                const recordInput =
                  props.operation &&
                  props.operation.operation_record_input.find(
                    (item) =>
                      item.custom_record_item_id === customRecord.id &&
                      item.input_data
                  );
                // 定型文
                const fixedPhraseData = customRecord.choices
                  ? customRecord.choices.filter((item) => item.hidden === 0)
                  : [];
                // visibilityか入力データがあれば表示
                const show = customRecord.visibility === 1 || recordInput;
                const fieldName = `${props.formikFieldNamePrefix}.custom_record.input_type_first.${customRecord.id}.input_data`;
                const OpenInsertPhraseModal = (): void => {
                  setInsertPhraseModal(true);
                  setInsertPhraseFieldName(fieldName);
                  setInsertPhraseId(customRecord.id);
                };
                return (
                  show && (
                    <div
                      className={props.classes.inputField}
                      key={`input-${props.index}-${customRecord.id}`}
                    >
                      <RecordSupportTableField
                        type="text"
                        value={
                          recordInput && recordInput.input_data
                            ? recordInput.input_data
                            : ""
                        }
                        name={fieldName}
                        {...textAndMultipleProps}
                        {...commonProps}
                      />
                      {props.isEditing && fixedPhraseData.length > 0 && (
                        <KnowbeButton
                          kind="outline"
                          className={props.classes.fixedButton}
                          onClick={OpenInsertPhraseModal}
                        >
                          定型文
                        </KnowbeButton>
                      )}
                    </div>
                  )
                );
              }
              // チェックボックス形式
              if (customRecord.input_type === 2) {
                const recordInput = props.operation
                  ? props.operation.operation_record_input.filter(
                      (item) =>
                        item.custom_record_item_id === customRecord.id &&
                        item.checked === 1
                    )
                  : [];
                // visibilityかデータが1件でもある場合表示
                const show =
                  customRecord.visibility === 1 || recordInput.length > 0;
                return (
                  show && (
                    <RecordSupportTableField type="custom" {...commonProps}>
                      <div className={props.classes.checkboxField}>
                        {customRecord.choices &&
                          customRecord.choices.map((choice) => {
                            // 非表示時の未チェックは除去
                            const checked = recordInput.some(
                              (item) => item.choiced_item_id === choice.id
                            );
                            if (
                              (choice.hidden === 1 && !checked) ||
                              (customRecord.visibility === 0 && !checked)
                            ) {
                              return null;
                            }
                            return (
                              <FormikCheckbox
                                name={`${props.formikFieldNamePrefix}.custom_record.input_type_second.${customRecord.id}.${choice.id}.checked`}
                                key={`checkbox_${choice.id}`}
                                label={
                                  <span className={props.classes.checkbox}>
                                    {choice.name}
                                  </span>
                                }
                                disabled={!props.isEditing}
                                style={{ margin: 0 }}
                              />
                            );
                          })}
                      </div>
                    </RecordSupportTableField>
                  )
                );
              }
              // マルチプルセレクト形式
              if (customRecord.input_type === 3) {
                const recordInput = props.operation
                  ? props.operation.operation_record_input.filter(
                      (item) =>
                        item.custom_record_item_id === customRecord.id &&
                        item.checked === 1
                    )
                  : [];
                const choicedStaffName = recordInput.map((item) => ({
                  id: item.choiced_staff_id ? item.choiced_staff_id : 0,
                  name: item.choiced_staff_name_snapshot
                    ? item.choiced_staff_name_snapshot
                    : ""
                }));
                const mergedStaffOptions = generateMergedStaffOptions(
                  props.staffOptions,
                  choicedStaffName
                );
                // visibilityかデータが1件でもある場合表示（チェックボックスと同一）
                const show =
                  customRecord.visibility === 1 || choicedStaffName.length > 0;
                return (
                  show && (
                    <RecordSupportTableField
                      type="multiple"
                      value={
                        choicedStaffName
                          ? choicedStaffName.map((item) => item.name).join("、")
                          : ""
                      }
                      name={`${props.formikFieldNamePrefix}.custom_record.input_type_third.${customRecord.id}.itemIdList`}
                      options={[
                        { categoryName: "", items: mergedStaffOptions }
                      ]}
                      emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                      {...textAndMultipleProps}
                      {...commonProps}
                    />
                  )
                );
              }
              return null;
            })}
        </div>
        <InsertPhraseModal
          isModalOpen={isInsertPhraseModal}
          onClose={closeInsertPhraseModal}
          formikProps={props.formikProps}
          customRecords={props.customRecords}
          insertPhraseFieldName={insertPhraseFieldName}
          insertPhraseId={insertPhraseId}
        />
      </UnEditableWrapper>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params)
  };
};

export const OperationRecord = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OperationRecordCore));
