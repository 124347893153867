import {
  FacilityType,
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V201910_END_DATE,
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_V202104_END_DATE
} from "@constants/variables";

export const findVersionFromDate = (
  date: Date,
  facilityType: FacilityType
): number => {
  switch (facilityType) {
    case FacilityType.IKOU:
    case FacilityType.A:
    case FacilityType.B:
    case FacilityType.GROUP_HOME:
    case FacilityType.SEIKATSUKAIGO:
    case FacilityType.SHUROTEICHAKU:
    case FacilityType.JIRITSUKUNRENSEIKATSU:
    case FacilityType.TANKINYUSHO:
    case FacilityType.SHISETSUNYUSHO:
      if (date <= INVOICE_VERSION_V201910_END_DATE) {
        return INVOICE_VERSION_V201910;
      }
      if (date <= INVOICE_VERSION_V202104_END_DATE) {
        return INVOICE_VERSION_V202104;
      }
      return INVOICE_VERSION_CURRENT;
    case FacilityType.KYOTAKUKAIGO:
    case FacilityType.JUDOHOMONKAIGO:
    case FacilityType.DOKOENGO:
    case FacilityType.KODOENGO:
    case FacilityType.KEIKAKUSODAN:
    case FacilityType.CHIIKIIKO:
    case FacilityType.CHIIKITEICHAKU:
      if (date <= INVOICE_VERSION_V202104_END_DATE) {
        return INVOICE_VERSION_V202104;
      }
      return INVOICE_VERSION_CURRENT;
    default:
      return INVOICE_VERSION_CURRENT;
  }
};
