import { InitialDataValues } from "./initialValues";
import validator, { validateSwitcher } from "@validator";
import {
  InitialErrors,
  InoutResultsDetailsFields
} from "@interfaces/v202104/mgr/KYOTAKUKAIGO/report/initial";
import {
  KYOTAKUKAIGO_STATUS_LIST,
  KYOTAKUKAIGO_MEMBER_LIST,
  KYOTAKUKAIGO_INPUT_CLASS_LIST,
  SERVICE_TIME_ZONE_LIST,
  KYOTAKUKAIGO_BASE_LIST,
  KYOTAKUKAIGO_LICENSE_LIST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { checkTimeRange } from "@validator/rules/checkTimeRange";
import { checkTimeRangeLimit } from "@validator/rules/checkTimeRangeLimit";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import format from "date-fns/format";
import convertHHMMToMinutes from "@utils/date/convertHHMMToMinutes";
import checkTime from "@validator/rules/checkTime";
import { SelectValue } from "@interfaces/ui/form";

type InitialDataErrors = InitialErrors;

const submitInoutResultsDetailsValidation = (
  data: InitialDataErrors["initial"]["inoutResultsDetails1"]
): boolean => {
  return (
    data === undefined ||
    data.filter((row) => {
      return row !== undefined;
    }).length === 0
  );
};

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.inputClass === undefined &&
      validationResult.initial.status === undefined &&
      validationResult.initial.numberOfBoardingAndAlighting === undefined &&
      validationResult.initial.practitioner1 === undefined &&
      validationResult.initial.practitioner1InTime === undefined &&
      validationResult.initial.practitioner1OutTime === undefined &&
      validationResult.initial.practitioner1Memo === undefined &&
      validationResult.initial.practitioner2 === undefined &&
      validationResult.initial.practitioner2InTime === undefined &&
      validationResult.initial.practitioner2OutTime === undefined &&
      validationResult.initial.practitioner2Memo === undefined &&
      validationResult.initial.practitioner2License === undefined &&
      validationResult.initial.emergencySupportFlgError === undefined &&
      submitInoutResultsDetailsValidation(
        validationResult.initial.inoutResultsDetails1
      ) &&
      submitInoutResultsDetailsValidation(
        validationResult.initial.inoutResultsDetails2
      )
    );
  }
  return false;
};

/**
 * 重複チェック
 */
const validateDuplicateTime = (
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string,
  inputClass: SelectValue
): string | undefined => {
  if (
    !(
      startTime1 &&
      endTime1 &&
      startTime2 &&
      endTime2 &&
      !checkTime(startTime1) &&
      !checkTime(endTime1) &&
      !checkTime(startTime2) &&
      !checkTime(endTime2)
    )
  ) {
    return undefined;
  }
  // 日跨ぎしている場合、時間に+24する
  const inTimes2 = startTime2.split(":");
  const outTimes1 = endTime1.split(":");
  const outTimes2 = endTime2.split(":");
  const inTime1 = startTime1;
  const inTime2 =
    startTime2Class === "1"
      ? `${Number(inTimes2[0]) + 24}:${inTimes2[1]}`
      : startTime2;
  const outTime1 =
    endTime1Class === "1"
      ? `${Number(outTimes1[0]) + 24}:${outTimes1[1]}`
      : endTime1;
  const outTime2 =
    endTime2Class === "1"
      ? `${Number(outTimes2[0]) + 24}:${outTimes2[1]}`
      : endTime2;

  const rangFlg1 = checkTimeRange(inTime1, "0", outTime1, "0", inTime2, "0");
  const rangFlg2 = checkTimeRange(inTime1, "0", outTime1, "0", outTime2, "0");
  const rangFlg3 = checkTimeRange(inTime2, "0", outTime2, "0", inTime1, "0");
  const equalFlg1 =
    convertHHMMToMinutes(inTime1) === convertHHMMToMinutes(outTime2);
  const equalFlg2 =
    convertHHMMToMinutes(inTime2) === convertHHMMToMinutes(outTime1);

  if ((!!rangFlg1 && !!rangFlg2 && !!rangFlg3) || equalFlg1 || equalFlg2) {
    const modalName =
      inputClass === KYOTAKUKAIGO_INPUT_CLASS_LIST.RESULT.value
        ? "サービス提供実績"
        : "居宅介護計画";
    return `1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の${modalName}として入力してください。`;
  }

  return undefined;
};

/**
 * サービス提供が契約終了日以内かのチェック
 */
const dateInServiceValidation = (
  date: string,
  startTime: string,
  endTime: string,
  endTimeClass: string,
  license: string,
  status: string,
  usersInFacility: UsersInFacilityState
): string | undefined => {
  const targetEndDate = convertBlankSeparatorFormatToDate(date);
  // 日跨ぎ対応
  if (endTimeClass === "1") {
    const start = startTime.split(":");
    const end = endTime.split(":");
    const inTime = convertBlankSeparatorFormatToDate(date);
    inTime.setHours(Number(start[0]));
    inTime.setMinutes(Number(start[1]));
    const outTime = convertBlankSeparatorFormatToDate(date);
    outTime.setDate(outTime.getDate() + 1);
    outTime.setHours(Number(end[0]));
    outTime.setMinutes(Number(end[1]));

    const firstUnit =
      license === KYOTAKUKAIGO_LICENSE_LIST.SEVERE.value &&
      [
        KYOTAKUKAIGO_STATUS_LIST.PHYSICAL.value,
        KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL
      ].indexOf(status) >= 0
        ? 60
        : 30;
    const unit = status === KYOTAKUKAIGO_STATUS_LIST.HOUSE_WORK.value ? 15 : 30;

    const tmpTime = new Date(inTime);
    tmpTime.setMinutes(tmpTime.getMinutes() + firstUnit);
    while (tmpTime.getHours() !== 0 && tmpTime.getTime() < outTime.getTime()) {
      tmpTime.setMinutes(tmpTime.getMinutes() + unit);
    }

    // 日跨ぎの場合（開始が0時以降の場合）
    if (tmpTime.getTime() < outTime.getTime()) {
      targetEndDate.setDate(targetEndDate.getDate() + 1);
    }
  }

  const targetEnd = Number(format(targetEndDate, "YYYYMMDD"));
  const serviceEndDate = usersInFacility.user.user_in_facility.date_pay_end
    ? Number(
        format(usersInFacility.user.user_in_facility.date_pay_end, "YYYYMMDD")
      )
    : null;
  if (serviceEndDate && serviceEndDate < targetEnd) {
    return "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください";
  }

  return undefined;
};

/**
 * 時間帯バリデーション
 * ※開始時間/終了時間が同じ時間帯であるか
 */
const timeZoneValidation = (
  startTime: string,
  startTimeClass: string,
  endTime: string,
  endTimeClass: string
): string | undefined => {
  let resultFLg = false;
  SERVICE_TIME_ZONE_LIST.forEach((item) => {
    const start = checkTimeRange(
      item.start,
      item.startClass,
      item.end,
      item.endClass,
      startTime,
      startTimeClass
    );
    const end = checkTimeRange(
      item.start,
      item.startClass,
      item.end,
      item.endClass,
      endTime,
      endTimeClass
    );
    if (start === undefined && end === undefined) {
      resultFLg = true;
    }
  });

  return resultFLg
    ? undefined
    : "複数の乗降回数を含む実績が、早朝/日中/夜間/深夜の時間帯をまたいで登録されています。時間帯による加算を算定したい場合は、実績を分けて登録してください";
};

/**
 * 終了時間の基本チェック
 */
const endTimeValidation = (
  date: string,
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string,
  usersInFacility: UsersInFacilityState,
  status: string,
  license: string,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  inputClass: SelectValue,
  secondPersonFlg = false,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  let endTimeError;
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  const startTimeClass = secondPersonFlg ? startTime2Class : "0";
  const endTime = secondPersonFlg ? endTime2 : endTime1;
  const endTimeClass = secondPersonFlg ? endTime2Class : endTime1Class;
  endTimeError = validator(endTime, "required", "checkTime", {
    type: "checkTimeFutureStraddlingTheDay",
    startTime,
    startTimeClass,
    endTime,
    endTimeClass,
    option
  });
  if (!endTimeError) {
    endTimeError = dateInServiceValidation(
      date,
      startTime,
      endTime,
      endTimeClass,
      license,
      status,
      usersInFacility
    );
  }
  // 乗降の場合のみチェックを実施
  // 乗降の場合の時間入力は実施者①のみなのでそれ以外は対象外
  if (
    !endTimeError &&
    status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
  ) {
    endTimeError = timeZoneValidation(
      startTime1,
      startTimeClass,
      endTime1,
      endTimeClass
    );
  }

  if (
    !endTimeError &&
    numberOfParticipants === KYOTAKUKAIGO_MEMBER_LIST.TWO.value &&
    !licenseSameFlg &&
    status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
  ) {
    endTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      endTime1Class,
      startTime2,
      startTime2Class,
      endTime2,
      endTime2Class,
      inputClass
    );
  }

  return endTimeError;
};

/**
 * 開始時間の基本チェック
 */
const startTimeValidation = (
  startTime1: string,
  endTime1: string,
  endTime1Class: string,
  startTime2: string,
  startTime2Class: string,
  endTime2: string,
  endTime2Class: string,
  status: string,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  inputClass: SelectValue,
  secondPersonFlg = false
): string | undefined => {
  let startTimeError;
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  startTimeError = validator(startTime, "required", "checkTime");

  if (
    !startTimeError &&
    numberOfParticipants === KYOTAKUKAIGO_MEMBER_LIST.TWO.value &&
    !licenseSameFlg &&
    status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
  ) {
    startTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      endTime1Class,
      startTime2,
      startTime2Class,
      endTime2,
      endTime2Class,
      inputClass
    );
  }

  return startTimeError;
};

const detailTimeValidation = (
  targetTime: string,
  targetTimeClass: string,
  details: InoutResultsDetailsFields[],
  idx: number,
  startFlg: boolean
): string | undefined => {
  const result = details
    .filter((row, i) => {
      return i !== idx && row.inTime && row.outTime;
    })
    .map((row) => {
      const flg = startFlg
        ? row.outTime === targetTime
        : row.inTime === targetTime;
      return flg
        ? "範囲内"
        : checkTimeRange(
            row.inTime,
            row.inTimeClass,
            row.outTime,
            row.outTimeClass,
            targetTime,
            targetTimeClass
          );
    })
    .filter((row) => {
      return row === undefined;
    });

  return result && result.length > 0
    ? "空き/運転時間は同一時間帯で重複しないように入力してください"
    : undefined;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      inputClass: validator(values.initial.inputClass, "required"),
      status: validator(
        values.initial.status === KYOTAKUKAIGO_STATUS_LIST.NONE.value
          ? ""
          : values.initial.status,
        "required"
      ),
      numberOfBoardingAndAlighting: validateSwitcher(
        values.initial.status ===
          KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value &&
          !values.initial.emergencySupportFlg,
        validator(
          values.initial.numberOfBoardingAndAlighting,
          "required",
          "naturalNumber",
          { type: "upperLimit", upperLimit: 9 }
        )
      )
    }
  };
};

const inoutResultsDetailsValidation = (
  baseInTime: string,
  baseInTimeClass: string,
  baseOutTime: string,
  baseOutTimeClass: string,
  details: InoutResultsDetailsFields[]
): InitialErrors["initial"]["inoutResultsDetails1"] => {
  return details.map((row, idx) => {
    let inTimeError = validator(row.inTime, "required", "checkTime", {
      type: "checkTimeRange",
      startTime: baseInTime,
      startTimeClass: baseInTimeClass,
      endTime: baseOutTime,
      endTimeClass: baseOutTimeClass,
      targetTime: row.inTime,
      targetTimeClass: row.inTimeClass,
      equalityOperatorFlag: false,
      option: "空き/運転時間"
    });
    if (!inTimeError) {
      inTimeError = detailTimeValidation(
        row.inTime,
        row.inTimeClass,
        details,
        idx,
        true
      );
    }

    let outTimeError = validator(
      row.outTime,
      "required",
      "checkTime",
      {
        type: "checkTimeFutureStraddlingTheDay",
        startTime: row.inTime,
        startTimeClass: row.inTimeClass,
        endTime: row.outTime,
        endTimeClass: row.outTimeClass
      },
      {
        type: "checkTimeRange",
        startTime: baseInTime,
        startTimeClass: baseInTimeClass,
        endTime: baseOutTime,
        endTimeClass: baseOutTimeClass,
        targetTime: row.outTime,
        targetTimeClass: row.outTimeClass,
        equalityOperatorFlag: false,
        option: "空き/運転時間"
      }
    );
    if (!outTimeError) {
      outTimeError = detailTimeValidation(
        row.outTime,
        row.outTimeClass,
        details,
        idx,
        false
      );
    }
    if (!outTimeError) {
      outTimeError = checkTimeRangeLimit(
        row.inTime,
        row.inTimeClass,
        row.outTime,
        row.outTimeClass,
        2,
        "空き/運転時間"
      );
    }

    return inTimeError || outTimeError
      ? {
          inTime: inTimeError,
          outTime: outTimeError
        }
      : undefined;
  });
};

const practitioner1Validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialErrors => {
  const statusGettingOnAndOff =
    values.initial.status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value;

  return {
    initial: {
      practitioner1InTime: startTimeValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner1OutTimeClass,
        values.initial.practitioner2InTime,
        values.initial.practitioner2InTimeClass,
        values.initial.practitioner2OutTime,
        values.initial.practitioner2OutTimeClass,
        values.initial.status,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg,
        values.initial.inputClass
      ),
      practitioner1OutTime: endTimeValidation(
        values.initial.targetDate,
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner1OutTimeClass,
        values.initial.practitioner2InTime,
        values.initial.practitioner2InTimeClass,
        values.initial.practitioner2OutTime,
        values.initial.practitioner2OutTimeClass,
        usersInFacility,
        values.initial.status,
        values.initial.practitioner1License,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg,
        values.initial.inputClass
      ),
      inoutResultsDetails1: validateSwitcher(
        statusGettingOnAndOff,
        inoutResultsDetailsValidation(
          values.initial.practitioner1InTime,
          values.initial.practitioner1InTimeClass,
          values.initial.practitioner1OutTime,
          values.initial.practitioner1OutTimeClass,
          values.initial.inoutResultsDetails1
        )
      ),
      practitioner1Memo: validator(values.initial.practitioner1Memo, {
        type: "checkCharacterLength",
        length: 50
      }),
      practitioner1: validator(values.initial.practitioner1, {
        type: "selectRequired",
        value: KYOTAKUKAIGO_BASE_LIST.NONE.value
      })
    }
  };
};

const practitioner2Validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialErrors => {
  if (
    values.initial.numberOfParticipants === KYOTAKUKAIGO_MEMBER_LIST.ONE.value
  ) {
    return { initial: {} };
  }
  const statusGettingOnAndOff =
    values.initial.status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value;

  return {
    initial: {
      practitioner2InTime: validateSwitcher(
        statusGettingOnAndOff && !values.initial.licenseSameFlg,
        startTimeValidation(
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner1OutTimeClass,
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          values.initial.status,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          values.initial.inputClass,
          true
        )
      ),
      practitioner2OutTime: validateSwitcher(
        statusGettingOnAndOff && !values.initial.licenseSameFlg,
        endTimeValidation(
          values.initial.targetDate,
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner1OutTimeClass,
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          usersInFacility,
          values.initial.status,
          values.initial.practitioner2License,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          values.initial.inputClass,
          true
        )
      ),
      inoutResultsDetails2: validateSwitcher(
        statusGettingOnAndOff && !values.initial.licenseSameFlg,
        inoutResultsDetailsValidation(
          values.initial.practitioner2InTime,
          values.initial.practitioner2InTimeClass,
          values.initial.practitioner2OutTime,
          values.initial.practitioner2OutTimeClass,
          values.initial.inoutResultsDetails2
        )
      ),
      practitioner2Memo: validator(values.initial.practitioner2Memo, {
        type: "checkCharacterLength",
        length: 50
      }),
      practitioner2: validator(values.initial.practitioner2, {
        type: "selectRequired",
        value: KYOTAKUKAIGO_BASE_LIST.NONE.value
      }),
      practitioner2License:
        values.initial.licenseSameFlg &&
        values.initial.practitioner1License !==
          values.initial.practitioner2License
          ? "①と②の資格が異なります"
          : undefined
    }
  };
};

/**
 * 緊急時対応のバリデーション
 *
 * 汎用的なチェックではないため、独自対応
 */
const emergencySupportFlgValidation = (
  values: InitialDataValues
): InitialErrors => {
  let errorFlg;
  if (
    values.initial.inputClass === KYOTAKUKAIGO_INPUT_CLASS_LIST.RESULT.value
  ) {
    if (
      values.initial.emergencySupportFlg &&
      values.initial.inoutResultsPlanId
    ) {
      errorFlg = "緊急時対応の場合、計画は設定できません";
    } else if (
      !values.initial.emergencySupportFlg &&
      !values.initial.inoutResultsPlanId &&
      [
        KYOTAKUKAIGO_STATUS_LIST.PHYSICAL.value,
        KYOTAKUKAIGO_STATUS_LIST.OUTPATIENT_WITH_PHYSICAL.value
      ].includes(values.initial.status)
    ) {
      errorFlg = "緊急時対応以外は計画なしで実績は登録できません";
    }
  }

  return {
    initial: { emergencySupportFlgError: errorFlg }
  };
};

export const validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialDataErrors => {
  const initialErrors = initialValidation(values);
  const practitioner1Errors = practitioner1Validation(values, usersInFacility);
  const practitioner2Errors = practitioner2Validation(values, usersInFacility);
  const emergencySupportFlgError = emergencySupportFlgValidation(values);

  return {
    initial: {
      ...initialErrors.initial,
      ...practitioner1Errors.initial,
      ...practitioner2Errors.initial,
      ...emergencySupportFlgError.initial
    }
  };
};
