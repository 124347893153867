import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { Theme, Typography } from "@material-ui/core";
import FormPaper from "@components/atoms/FormPaper";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import HelpToolTip from "@components/atoms/HelpToolTip";
import FormikTextField from "@components/molecules/FormikTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import { SupportProcedureFormTable } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureFormTable";
import { ServiceDeliveryForm } from "@components/organisms/mgr/KODOENGO/record/ServiceDeliveryForm";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";

// utils
import { FieldItem } from "@interfaces/ui/form";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import { StaffState } from "@stores/domain/staff/types";
import { TARGET_FLG, TARGET_PATH } from "@constants/mgr/KODOENGO/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    titleTimeStampWrapper: {
      display: "flex",
      justifyContent: "space-between"
    },
    titleWrapper: {
      position: "relative"
    },
    tooltip: {
      zIndex: 1,
      position: "absolute",
      marginTop: "-5px",
      marginLeft: "58px"
    },
    sectionTitle: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    recordFormWrapper: {
      background: "#f5f5f5"
    },
    authorArea: {
      marginTop: spacing.unit * 4,
      display: "flex",
      gap: "16px"
    },
    author: {
      width: "240px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT8: {
      marginTop: spacing.unit
    }
  });

type OwnProps = {
  staffOptions: FieldItem[];
  staff: StaffState;
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  targetObject: TargetObject[];
  isRecord?: boolean;
  target?: keyof typeof TARGET_FLG;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureFormCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const {
    staffOptions,
    formikProps,
    targetObject,
    target,
    supportProcedureDetail,
    isRecord = false
  } = props;
  const { values: formikValues } = formikProps;

  const authorOptions = createSnapshotOptions(
    staffOptions,
    supportProcedureDetail.author
  );

  const author = React.useMemo<{
    name?: string;
    role?: string;
  }>(() => {
    // snapshot選択時
    if (formikValues.authorId === 0) {
      if (!supportProcedureDetail || !supportProcedureDetail.author) return {};
      return {
        name: supportProcedureDetail.author.snapshot_name,
        role:
          supportProcedureDetail.author.snapshot_role ||
          supportProcedureDetail.author.role
      };
    }
    if (!formikValues.authorId) return {};
    const selectedStaff = props.staff.staffItems.find(
      (staffItem) => staffItem.staffItemId === Number(formikValues.authorId)
    );
    if (!selectedStaff) {
      if (!supportProcedureDetail.author) return {};
      // スタッフを選択しているが削除されている
      return {
        name: supportProcedureDetail.author.snapshot_name,
        role:
          supportProcedureDetail.author.snapshot_role ||
          supportProcedureDetail.author.role
      };
    }
    return {
      name: selectedStaff.staffName,
      role: selectedStaff.roleName
    };
  }, [formikValues.authorId, props.staff]);

  return (
    <Form>
      <FormPaper>
        <div className={classes.titleTimeStampWrapper}>
          {/* タイトル */}
          {isRecord ? (
            <></>
          ) : (
            <div className={classes.titleWrapper}>
              <span className={classes.tooltip}>
                <HelpToolTip
                  title={<HelpTipMessages name="supportProcedureTitle" />}
                />
              </span>
              <FormikTextField
                name="title"
                label="タイトル"
                placeholder=""
                maxLength={20}
                size="largeMedium"
                error={formikProps.errors && !!formikProps.errors.title}
              />
            </div>
          )}
          {(target === TARGET_PATH.edit || target === TARGET_PATH.setDate) && (
            <CreateAndUpdateDate
              createdAt={formikValues.createdAt}
              updatedAt={formikValues.updatedAt}
            />
          )}
        </div>
        {/* サービス提供記録 */}
        {/* モーダルから単数選択で日付が設定されている場合 or モーダルで日付が設定されている場合は表示 */}
        {targetObject.length === 1 && !!targetObject[0].targetDate && (
          <ServiceDeliveryForm
            staffOptions={staffOptions}
            formikProps={formikProps}
          />
        )}
        {/* 手順詳細と記録 */}
        <Typography
          variant="subtitle1"
          className={`${classes.sectionTitle} ${classes.MT48}`}
        >
          手順詳細と記録
        </Typography>
        <SupportProcedureFormTable
          formikProps={formikProps}
          isRecord={isRecord}
        />
        {/* その他 */}
        <Typography
          variant="subtitle1"
          className={`${classes.sectionTitle} ${classes.MT48}`}
        >
          その他
        </Typography>
        <div style={{ marginTop: "29px" }}>
          {isRecord ? (
            <ReadonlyTextField
              label="連絡事項（手順書）"
              value={formikValues.contactInformationPlan}
              multiline
              defaultValue=""
            />
          ) : (
            <FormikTextField
              name="contactInformationPlan"
              label="連絡事項（手順書）"
              size="fullSize"
              multiline
            />
          )}
        </div>
        <div className={classes.recordFormWrapper}>
          <FormikTextField
            name="contactInformationRecord"
            label="連絡事項（記録）"
            size="fullSize"
            style={{ marginTop: "29px", padding: "16px" }}
            error={
              formikProps.errors &&
              !!formikProps.errors.contactInformationRecord
            }
            multiline
          />
        </div>
        <div style={{ marginTop: "29px" }}>
          {isRecord ? (
            <ReadonlyTextField
              label="問い合わせ事項（手順書）"
              value={formikValues.inquiryItemsPlan}
              multiline
              defaultValue=""
            />
          ) : (
            <FormikTextField
              name="inquiryItemsPlan"
              label="問い合わせ事項（手順書）"
              size="fullSize"
              multiline
              error={
                formikProps.errors && !!formikProps.errors.inquiryItemsPlan
              }
            />
          )}
        </div>
        <div className={classes.recordFormWrapper}>
          <FormikTextField
            name="inquiryItemsRecord"
            label="問い合わせ事項（記録）"
            size="fullSize"
            style={{ marginTop: "29px", padding: "16px" }}
            error={
              formikProps.errors && !!formikProps.errors.inquiryItemsRecord
            }
            multiline
          />
        </div>
        <div className={classes.authorArea}>
          <div className={classes.author}>
            {isRecord ? (
              <ReadonlyTextField
                label="作成者"
                value={author.name || ""}
                defaultValue=""
              />
            ) : (
              <RecordSelect
                name="authorId"
                label="作成者"
                defaultValue="未設定"
                placeholder="選択してください"
                options={authorOptions}
                emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                value=""
                isEditable
                isSelectablePlaceholder
              />
            )}
          </div>
          {author.role ? (
            <div className={classes.author}>
              <ReadonlyTextField
                label="役職"
                value={author.role}
                defaultValue=""
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </FormPaper>
    </Form>
  );
};

export const SupportProcedureForm = withStyles(styles)(
  SupportProcedureFormCore
);
