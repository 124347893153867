import { GetUsersSummaryTANKINYUSHO } from "@api/requests/supports/TANKINYUSHO/getUsersSummaryTANKINYUSHO";

/**
 * ActionNames
 */
export const FETCH_USERS_SUMMARY_TANKINYUSHO_STARTED =
  "SUPPORTS/FETCH_USERS_SUMMARY_TANKINYUSHO_STARTED";
export const FETCH_USERS_SUMMARY_TANKINYUSHO_SUCCESS =
  "SUPPORTS/FETCH_USERS_SUMMARY_TANKINYUSHO_SUCCESS";
export const FETCH_USERS_SUMMARY_TANKINYUSHO_FAILED =
  "SUPPORTS/FETCH_USERS_SUMMARY_TANKINYUSHO_FAILED";
export const UNSET_USERS_SUMMARY_TANKINYUSHO =
  "SUPPORTS/UNSET_USERS_SUMMARY_TANKINYUSHO";

/**
 * State
 */
export type UsersSummaryState = GetUsersSummaryTANKINYUSHO["data"];
