import React, { useState, useEffect } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules,
  Theme
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// store
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import {
  ServiceDeliveryState,
  SERVICE_DELIVERY_MONTHLY
} from "@stores/domain/serviceDelivery/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SupportsState } from "@stores/domain/supports/types";
import { UserState } from "@stores/domain/user/type";
// ui
import Paper from "@material-ui/core/Paper";
import { ServiceDeliveryMonthlyHeader } from "@components/organisms/record/serviceDelivery/ServiceDeliveryMonthlyHeader";
import { ServiceDeliveryTable } from "@components/organisms/record/serviceDelivery/ServiceDeliveryTable";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { UserSummaryModal } from "@components/organisms/record/serviceDelivery/UserSummaryModal";
import { ServiceDeliveryTable as ServiceDeliveryTableKodoengo } from "@components/organisms/mgr/KODOENGO/record/ServiceDeliveryTable";
import { ServiceDeliveryTable as ServiceDeliveryTableIdoshien } from "@components/organisms/mgr/IDOSHIEN/record/ServiceDeliveryTable";
// variables
import {
  RECORD_MODAL_TYPE,
  RECORD_MODAL_TYPE_TARGET,
  FacilityType,
  SERVICE_DELIVERY_PRINT_TYPE_OPTIONS
} from "@constants/variables";
import {
  NO_RECORD_MESSAGE_SERVICE_DELIVERY,
  NO_USER_MESSAGE_SERVICE_DELIVERY
} from "@constants/mgr/KYOTAKUKAIGO/variables";
// utils
import {
  dateInYYYYMMFormat,
  compareDateFuture,
  dateToLocalisedString
} from "@utils/date";
import { OptionInterface } from "@components/atoms/DropDown";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import format from "date-fns/format";
import { getStaffCommentLabelWithCategory } from "@utils/domain/customRecords/getStaffCommentLabelWithCategory";
import * as URL from "@constants/url";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8,
      width: "100%"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: `24px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    createButtonWrapper: {
      display: "flex",
      justifyContent: "flex-end"
    },
    createButton: {
      marginBottom: "8px",
      width: "180px"
    },
    noPlanAndNoRecordFuture: {
      height: "124px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    dialogContent: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 32px 16px"
    }
  });

type OwnProps = {
  selectedDate: Date;
  history: RouteComponentProps["history"];
  userId: number;
};

type StateProps = {
  serviceDeliveryMonthlyState: ServiceDeliveryState["monthlyRecord"];
  userInFacility: UsersInFacilityState;
  customRecords: CustomRecordsWithCategoryState;
  supportsUsers: SupportsState["report"]["users"];
  userState: UserState;
};

type DispatchProps = {
  fetchMonthlyRecord: (
    usersInFacilityId: number,
    yyyymm: string
  ) => Promise<void>;
  fetchFacilityUserList: (date: Date) => void;
  deleteServiceDelivery: (serviceDeliveryRecordsId: number) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchReportUsers: (target: string, month: string) => void;
};

type MergeProps = OwnProps &
  StateProps &
  DispatchProps & {
    userListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

const currentDate = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDate.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

const ServiceDeliveryMonthlyRecordCore = (props: Props): JSX.Element | null => {
  const { selectedDate, userId } = props;
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState(false);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState<number>(0);

  const isDisabledPrint = !props.serviceDeliveryMonthlyState.service_delivery.some(
    (item) => item.recorded_flg === 1 || !!item.procedure_status_flg
  );

  const getUrl = (params: { id?: number; date?: Date }): string => {
    const userIdParam = params.id || userId;
    const urlDate = params.date || selectedDate;
    const yyyymm = dateToLocalisedString(urlDate, "YYYYMM");
    return `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/${userIdParam}/${yyyymm}`;
  };

  // 未来日かつ計画・実績がない場合
  const isNoPlanAndNoRecordFuture =
    props.serviceDeliveryMonthlyState.service_delivery.length === 0 &&
    compareDateFuture(currentDate, selectedDate);

  // 利用者がいない場合
  const isNoUser = props.userListOption.length === 0;

  const noRecordMessage = (): string => {
    if (isNoUser) {
      return NO_USER_MESSAGE_SERVICE_DELIVERY;
    }
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      return "計画または支援手順書兼記録用紙がありません。利用実績画面から計画を入力するか、支援手順書 兼 記録用紙画面から支援手順書 兼 記録用紙を作成後、ご利用ください。";
    }
    return NO_RECORD_MESSAGE_SERVICE_DELIVERY;
  };

  // 初期表示時および日付変更に伴う利用者の変更
  const changeUserWithDate = (users: OptionInterface[]): void => {
    if (users.findIndex((item) => +item.value === userId) === -1) {
      props.history.replace(getUrl({ id: +users[0].value }));
    }
  };

  // 削除モーダル操作
  const openDeleteModal = (serviceDeliveryRecordsId: number): void => {
    setDeleteTargetId(serviceDeliveryRecordsId);
    setIsOpenDeleteDialog(true);
  };

  const handleCancel = (): void => {
    setIsOpenDeleteDialog(false);
  };

  const onDelete = async (): Promise<void> => {
    await props.deleteServiceDelivery(deleteTargetId);
    await props.fetchMonthlyRecord(+userId, dateInYYYYMMFormat(selectedDate));
    setIsOpenDeleteDialog(false);
  };

  useEffect(() => {
    props.fetchFacilityUserList(selectedDate);
    const top = document.getElementById("serviceDeliveryRecordHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, []);

  // 情報取得
  useEffect(() => {
    if (selectedDate && userId) {
      props.fetchMonthlyRecord(userId, dateInYYYYMMFormat(selectedDate));
    }
  }, [userId]);

  // 初期表示・日付変更時の処理
  useEffect(() => {
    if (props.userListOption && props.userListOption[0]) {
      changeUserWithDate(props.userListOption);
    }
  }, [props.userListOption]);

  const onChangeDate = async (date: Date): Promise<void> => {
    props.history.replace(getUrl({ date }));
  };

  const onChangeUser = (user: OptionInterface): void => {
    props.history.replace(getUrl({ id: +user.value }));
  };

  // 支援手順書兼記録用紙が存在しているか
  const isSupportProcedureExist = props.serviceDeliveryMonthlyState.service_delivery.some(
    (item) =>
      item.procedure_status_flg !== 0 && item.procedure_status_flg !== undefined
  );

  // サービス提供が記録されているか
  const isServiceDeliveryExist = props.serviceDeliveryMonthlyState.service_delivery.some(
    (item) => item.service_delivery_records_id !== null
  );

  const openPrintModal = async (): Promise<void> => {
    if (
      props.userState.facility_type === FacilityType.KODOENGO &&
      isSupportProcedureExist
    ) {
      await props.fetchReportUsers(
        RECORD_MODAL_TYPE_TARGET[9],
        dateInYYYYMMFormat(selectedDate)
      );
    } else {
      await props.fetchReportUsers(
        RECORD_MODAL_TYPE_TARGET[8],
        dateInYYYYMMFormat(selectedDate)
      );
    }
    await props.fetchCustomRecords();
    setIsOpenPrintModal(true);
  };

  const closePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };

  // 職員考察がカスタムされている場合にラベル名を取得
  const staffCommentLabel = getStaffCommentLabelWithCategory({
    customRecords: props.customRecords,
    facilityType: props.userState.facility_type
  });

  // 計画外の記録作成画面
  const changeCreateMode = (): void => {
    const targatYYYYMM = dateInYYYYMMFormat(selectedDate);
    const targetUserId = String(userId);
    if (targatYYYYMM && targetUserId) {
      props.history.push(
        `/record/${targatYYYYMM}/${targetUserId}/service_delivery/new`
      );
    }
  };

  const getReportUsers = (reportType: string): void => {
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      if (reportType === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value) {
        props.fetchReportUsers(
          RECORD_MODAL_TYPE_TARGET[9],
          dateInYYYYMMFormat(selectedDate)
        );
      } else {
        props.fetchReportUsers(
          RECORD_MODAL_TYPE_TARGET[8],
          dateInYYYYMMFormat(selectedDate)
        );
      }
    }
  };

  const changeEditMode = (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    uifId: number,
    supportProcedureFormsId?: number
  ): void => {
    const paramDate = format(targetDate, "YYYYMMDD");
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${supportProcedureFormsId}/${uifId}/monthly`
      );
    } else {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${uifId}/monthly`
      );
    }
  };

  const { classes } = props;

  const dialogMessage = (
    <span>
      データが完全に削除され、復元できません。
      <br />
      よろしいですか？
    </span>
  );

  const facilityType = props.userState.facility_type;

  return (
    <>
      <div id="serviceDeliveryRecordHeader" className={classes.headerWrapper}>
        <div className={classes.headerInfoContainer}>
          <ServiceDeliveryMonthlyHeader
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
            openPrintModal={openPrintModal}
            disabledPrint={isDisabledPrint}
            selectedUserId={userId}
            onChangeUser={onChangeUser}
            changeUserWithDate={changeUserWithDate}
            userListOption={props.userListOption}
          />
        </div>
      </div>

      <Paper elevation={0} className={classes.tableContainer}>
        {isNoPlanAndNoRecordFuture || isNoUser ? (
          <div className={classes.noPlanAndNoRecordFuture}>
            {noRecordMessage()}
          </div>
        ) : (
          <>
            <div className={classes.createButtonWrapper}>
              <KnowbeButton
                className={classes.createButton}
                onClick={changeCreateMode}
              >
                計画外の記録の追加
              </KnowbeButton>
            </div>
            {facilityType === FacilityType.KODOENGO && (
              <ServiceDeliveryTableKodoengo
                records={props.serviceDeliveryMonthlyState}
                type={SERVICE_DELIVERY_MONTHLY}
                headerHeight={headerHeight}
                changeEditMode={changeEditMode}
                facilityType={props.userState.facility_type}
              />
            )}
            {facilityType === FacilityType.IDOSHIEN && (
              <ServiceDeliveryTableIdoshien
                records={props.serviceDeliveryMonthlyState}
                type={SERVICE_DELIVERY_MONTHLY}
                headerHeight={headerHeight}
                changeEditMode={changeEditMode}
                openDeleteModal={openDeleteModal}
              />
            )}
            {![FacilityType.KODOENGO, FacilityType.IDOSHIEN].includes(
              facilityType
            ) && (
              <ServiceDeliveryTable
                records={props.serviceDeliveryMonthlyState}
                type={SERVICE_DELIVERY_MONTHLY}
                headerHeight={headerHeight}
                changeEditMode={changeEditMode}
                openDeleteModal={openDeleteModal}
                facilityType={props.userState.facility_type}
              />
            )}
          </>
        )}
      </Paper>
      {isOpenPrintModal && (
        <UserSummaryModal
          isModalOpen={isOpenPrintModal}
          onClose={closePrintModal}
          modalType={RECORD_MODAL_TYPE.serviceDeliveryMonthly}
          targetUsers={props.supportsUsers}
          history={props.history}
          staffCommentLabel={staffCommentLabel}
          targetDate={selectedDate}
          targetUserId={userId}
          facilityType={props.userState.facility_type}
          getReportUsers={getReportUsers}
          isSupportProcedureExist={isSupportProcedureExist}
          isServiceDeliveryExist={isServiceDeliveryExist}
        />
      )}
      {isOpenDeleteDialog && (
        <MessageDialog
          classes={{ content: props.classes.dialogContent }}
          isOpen={isOpenDeleteDialog}
          title="このサービス提供記録を削除します"
          message={dialogMessage}
          closeButton={
            <KnowbeButton kind="text" onClick={handleCancel}>
              キャンセル
            </KnowbeButton>
          }
          actionButton={
            <KnowbeButton kind="textDelete" onClick={onDelete}>
              削除する
            </KnowbeButton>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    serviceDeliveryMonthlyState: state.serviceDelivery.monthlyRecord,
    userInFacility: state.KYOTAKUKAIGO.userInFacility,
    customRecords: state.customRecordsWithCategory.serviceDelivery,
    supportsUsers: state.supports.report.users,
    userState: state.user as UserState
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    serviceDelivery,
    customRecordsWithCategory,
    supportsDispatcher
  } = dispatches;
  const { KYOTAKUKAIGO } = dispatches;
  const userInFacilityDispatcher = KYOTAKUKAIGO.userInFacilityDispatcher(
    dispatch
  );
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const supportsDispatches = supportsDispatcher(dispatch);
  return {
    fetchMonthlyRecord: (
      usersInFacilityId: number,
      yyyymm: string
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchMonthlyRecord(usersInFacilityId, yyyymm),
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date),
    deleteServiceDelivery: (serviceDeliveryRecordsId: number): Promise<void> =>
      serviceDeliveryDispatches.deleteServiceDelivery(serviceDeliveryRecordsId),
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchReportUsers: (target: string, month: string): void => {
      supportsDispatches.fetchReportUsers(target, month);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const userListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    userListOption,
    ...stateProps,
    ...ownProps,
    ...dispatchProps
  };
};

export const ServiceDeliveryMonthlyRecord = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(ServiceDeliveryMonthlyRecordCore));
