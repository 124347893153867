import React from "react";
import * as H from "history";
import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import { FacilityType } from "@constants/variables";
import { CHIIKITEICHAKU_PLAN_LIST_TAB_PATH } from "@constants/mgr/CHIIKITEICHAKU/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AddToPhotosOutlined from "@material-ui/icons/AddToPhotosOutlined";
import { SupportLedgerListHeader } from "@components/organisms/mgr/CHIIKITEICHAKU/record/SupportLedgerListHeader";
// utils
import { dateToLocalisedString } from "@/utils/date";

const styles = (): StyleRules =>
  createStyles({
    list: {
      background: "#fff",
      borderRadius: "4px"
    },
    listCon: {
      marginTop: "32px",
      padding: "0 40px"
    },
    item: {
      borderTop: "1px solid #b2b2b2",
      "&:nth-child(n+2)": {
        marginTop: "24px"
      }
    },
    itemTitle: {
      borderBottom: "1px solid #d9d9d9",
      background: " rgba(245, 245, 245, 0.38)",
      padding: "10px 8px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    itemTitleText: {
      display: "flex",
      alignItems: "center",
      "&>p": {
        fontFamily: "HiraginoSans-W4",
        fontSize: "16px",
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.87)",
        margin: 0,
        "&:nth-child(n+2)": {
          marginLeft: "40px"
        }
      }
    },
    itemBtn: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 8px 16px"
    },
    itemBtnIcon: {
      color: "#0277bd"
    },
    itemBtnText: {
      display: "block",
      marginLeft: "4px",
      color: "#0277bd",
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "1.25px"
    },
    listMessage: {
      padding: "80px 32px",
      textAlign: "center",
      fontFamily: "HiraginoSans-W4",
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: " rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  supportLedgerList: SupportLedgerState["supportLedgerList"];
  history: H.History;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 地域定着支援台帳一覧
 */
const SupportLedgerRecordListCore = (props: Props): JSX.Element | null => {
  const { supportLedgerList, classes, uifId } = props;
  // 作成日で降順ソート
  supportLedgerList.sort(
    (a, b) =>
      new Date(b.creation_date).getTime() - new Date(a.creation_date).getTime()
  );

  const createButton = (
    <KnowbeButton
      kind="default"
      href={`#/record/support_plan/${uifId}/support_ledger/new`}
    >
      新規作成
    </KnowbeButton>
  );

  return (
    <>
      <div className={classes.list}>
        <SupportLedgerListHeader
          pageName="地域定着支援台帳一覧"
          userName={props.userName}
          uifId={props.uifId}
          recordType={CHIIKITEICHAKU_PLAN_LIST_TAB_PATH.SUPPORT_LEDGER}
          isEditing={false}
          history={props.history}
          button={createButton}
        />
        {supportLedgerList.length === 0 ? (
          <div className={classes.listMessage}>
            地域定着支援台帳がありません。新規作成ボタンから作成してください。
          </div>
        ) : (
          <div className={classes.listCon}>
            {supportLedgerList.map((item, index) => {
              const key = `key_${index}`;
              return (
                <div className={classes.item} key={key}>
                  <div className={classes.itemTitle}>
                    <div className={classes.itemTitleText}>
                      <p>
                        作成日：
                        {dateToLocalisedString(
                          item.creation_date,
                          "YYYY年M月D日"
                        )}
                      </p>
                      <p>
                        更新日：
                        {dateToLocalisedString(
                          item.update_date,
                          "YYYY年M月D日"
                        )}
                      </p>
                      <p>作成者：{item.author_name || "-"}</p>
                    </div>
                    <div className={classes.itemTitleBtn}>
                      <KnowbeButton
                        kind="outlineWhite"
                        minWidth={158}
                        href={`#/record/support_plan/${uifId}/support_ledger/${item.id}`}
                      >
                        地域定着支援台帳
                      </KnowbeButton>
                    </div>
                  </div>
                  <div className={classes.itemBtn}>
                    <KnowbeButton
                      kind="iconText"
                      href={`#/record/support_plan/${uifId}/support_ledger/${item.id}/copy`}
                    >
                      <AddToPhotosOutlined className={classes.itemBtnIcon} />
                      <span className={classes.itemBtnText}>
                        この地域定着支援台帳をコピー
                      </span>
                    </KnowbeButton>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export const SupportLedgerRecordList = withStyles(styles)(
  SupportLedgerRecordListCore
);
