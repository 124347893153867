import { FacilityState } from "./types";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { PostFacilityParams } from "@api/requests/facility/postFacility";
import { FacilityValues } from "@initialize/mgr/KODOENGO/facility/initialValues";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  STRING_FALSE_FROM_API,
  STRING_TRUE_FROM_API,
  DEFAULT_RADIO_VALUE,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  SELECTED_CONDITION_TYPE_2024_V
} from "@constants/variables";
import { STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE } from "@constants/mgr/KODOENGO/variables";
import castString from "@utils/dataNormalizer/castString";
import { isNil } from "lodash-es";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import { selectDateValueToDate } from "@utils/date";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams
): FacilityState => {
  const { facility } = result;
  const facilityKODOENGO = result.facility_kodoengo || {
    specific_facilities_addition: 0,
    life_support_hub_in_district_flg: 0
  };

  return {
    corporationName:
      facility.gov_business_owner !== null ? facility.gov_business_owner : "",
    officeNumber: !isNil(facility.gov_facility_number)
      ? facility.gov_facility_number
      : "",
    officeName: !isNil(facility.name) ? facility.name : "",
    serviceType: facility.type_service,
    representativeName: !isNil(facility.responsible_person)
      ? facility.responsible_person
      : "",
    capacity: facility.capacity !== null ? facility.capacity.toString() : "",
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address !== null ? facility.address : "",
    tel: !isNil(facility.tel) ? facility.tel : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : "",
    multiFunctionOfficeFlag:
      facility.multiple_facility_flg === STRING_TRUE_FROM_API,
    masterSubordinateFlg:
      facility.master_subordinate_flg === STRING_TRUE_FROM_API,
    masterFlg: facility.master_flg ? facility.master_flg : "1",
    allCapacity: castString(facility.total_capacity),
    specificBetterSupporterCondition202404: facility.specific_better_supporter_condition_202404
      ? facility.specific_better_supporter_condition_202404
      : DEFAULT_RADIO_VALUE,
    staffTreatmentSystemType: facility.better_supporter_condition
      ? facility.better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    staffTreatmentSpecificSystemType: facility.specific_better_supporter_condition
      ? facility.specific_better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    specificFacilitiesAddition: facilityKODOENGO.specific_facilities_addition
      ? facilityKODOENGO.specific_facilities_addition.toString()
      : STRING_FALSE_FROM_API,
    betterSupporterConditionBaseUpFlg:
      facility.better_supporter_condition_base_up_flg === STRING_TRUE_FROM_API,
    lifeSupportHubInDistrictFlg:
      facilityKODOENGO.life_support_hub_in_district_flg === INT_TRUE_FROM_API,
    bodyRestrictedStillFlg:
      facility.body_restricted_still_flg === INT_TRUE_FROM_API,
    bodyRestrictedStillFrom: facility.body_restricted_still_from
      ? facility.body_restricted_still_from
      : "",
    bodyRestrictedStillTo: facility.body_restricted_still_to
      ? facility.body_restricted_still_to
      : "",
    abusePreventionMeasuresNotImplementedFlg:
      facility.abuse_prevention_measures_not_implemented_flg ===
      INT_TRUE_FROM_API,
    abusePreventionMeasuresNotImplementedFrom: facility.abuse_prevention_measures_not_implemented_from
      ? facility.abuse_prevention_measures_not_implemented_from
      : "",
    abusePreventionMeasuresNotImplementedTo: facility.abuse_prevention_measures_not_implemented_to
      ? facility.abuse_prevention_measures_not_implemented_to
      : "",
    informationDisclosureNotReportedFlg:
      facility.information_disclosure_not_reported_flg === INT_TRUE_FROM_API,
    informationDisclosureNotReportedFrom: facility.information_disclosure_not_reported_from
      ? facility.information_disclosure_not_reported_from
      : "",
    informationDisclosureNotReportedTo: facility.information_disclosure_not_reported_to
      ? facility.information_disclosure_not_reported_to
      : ""
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result = {
    facility: { ...target.facility },
    facility_kodoengo: target.facility_kodoengo
      ? { ...target.facility_kodoengo }
      : {}
  };
  if (
    !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      result.facility.better_supporter_condition
    )
  ) {
    result.facility.specific_better_supporter_condition = DEFAULT_RADIO_VALUE;
    result.facility.better_supporter_condition_base_up_flg = STRING_FALSE_FROM_API;
  } else if (
    result.facility_kodoengo.specific_facilities_addition === 0 &&
    result.facility.specific_better_supporter_condition ===
      STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1.value
  ) {
    result.facility.specific_better_supporter_condition = DEFAULT_RADIO_VALUE;
  }
  if (result.facility.master_subordinate_flg === STRING_FALSE_FROM_API) {
    result.facility.master_flg = null;
  }

  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMap = {
  facility: {
    master_subordinate_flg: {
      key: "master_subordinate_flg",
      childKeys: ["master_flg"]
    },
    better_supporter_condition: {
      key: "better_supporter_condition",
      childKeys: [
        "specific_better_supporter_condition",
        "better_supporter_condition_base_up_flg"
      ]
    }
  }
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 */
const addParentValue = (
  target: PostFacilityParams,
  after: PostFacilityParams
): PostFacilityParams => {
  const result = target;
  Object.keys(parentParamsMap).forEach((facilityKey) => {
    Object.keys(parentParamsMap[facilityKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[facilityKey][paramKey].childKeys
        ? parentParamsMap[facilityKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return result[facilityKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        result[facilityKey][parentParamsMap[facilityKey][paramKey].key] =
          after[facilityKey][parentParamsMap[facilityKey][paramKey].key];
      }
    });
  });
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams
): NormalizedPostFacilityParams => normalizeApiParams(params);

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams
): PostFacilityParams => {
  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      capacity: after.facility.capacity,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      multiple_facility_flg: after.facility.multiple_facility_flg,
      users_vs_supporter_grade: after.facility.users_vs_supporter_grade,
      welfare_condition: after.facility.welfare_condition,
      welfare_condition_start_date: after.facility.welfare_condition_start_date,
      welfare_condition_end_date: after.facility.welfare_condition_end_date,
      better_supporter_condition: after.facility.better_supporter_condition,
      lack_of_supporter_flg: after.facility.lack_of_supporter_flg,
      date_start_lack_of_supporter: after.facility.date_start_lack_of_supporter,
      lack_of_service_admin_flg: after.facility.lack_of_service_admin_flg,
      date_start_lack_of_service_admin:
        after.facility.date_start_lack_of_service_admin,
      origin_local_gov_flg: after.facility.origin_local_gov_flg,
      see_hear_team_flg: after.facility.see_hear_team_flg,
      master_subordinate_flg: undefined,
      // multiple_facility_flgを毎回送信しているため
      // 関係のあるmaster_flgとtotal_capacityも毎回送信する
      master_flg: after.facility.master_flg,
      total_capacity: after.facility.total_capacity,
      specific_better_supporter_condition_202404: undefined,
      specific_better_supporter_condition: undefined,
      better_supporter_condition_base_up_flg: undefined,
      body_restricted_still_flg: undefined,
      body_restricted_still_from: undefined,
      body_restricted_still_to: undefined,
      abuse_prevention_measures_not_implemented_flg: undefined,
      abuse_prevention_measures_not_implemented_from: undefined,
      abuse_prevention_measures_not_implemented_to: undefined,
      information_disclosure_not_reported_flg: undefined,
      information_disclosure_not_reported_from: undefined,
      information_disclosure_not_reported_to: undefined
    },
    facility_kodoengo: {
      specific_facilities_addition: undefined,
      life_support_hub_in_district_flg: undefined
    }
  };

  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${before[key][param]}` !== `${after[key][param]}`) {
          target[key][param] = after[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });

  // 身体拘束廃止未実施減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.body_restricted_still_flg !== undefined ||
    target.facility.body_restricted_still_from !== undefined ||
    target.facility.body_restricted_still_to !== undefined
  ) {
    target.facility.body_restricted_still_flg =
      after.facility.body_restricted_still_flg;
    target.facility.body_restricted_still_from =
      after.facility.body_restricted_still_from;
    target.facility.body_restricted_still_to =
      after.facility.body_restricted_still_to;
  }

  // 虐待防止措置未実施減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.abuse_prevention_measures_not_implemented_flg !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_from !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_to !== undefined
  ) {
    target.facility.abuse_prevention_measures_not_implemented_flg =
      after.facility.abuse_prevention_measures_not_implemented_flg;
    target.facility.abuse_prevention_measures_not_implemented_from =
      after.facility.abuse_prevention_measures_not_implemented_from;
    target.facility.abuse_prevention_measures_not_implemented_to =
      after.facility.abuse_prevention_measures_not_implemented_to;
  }

  // 情報公表未報告減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.information_disclosure_not_reported_flg !== undefined ||
    target.facility.information_disclosure_not_reported_from !== undefined ||
    target.facility.information_disclosure_not_reported_to !== undefined
  ) {
    target.facility.information_disclosure_not_reported_flg =
      after.facility.information_disclosure_not_reported_flg;
    target.facility.information_disclosure_not_reported_from =
      after.facility.information_disclosure_not_reported_from;
    target.facility.information_disclosure_not_reported_to =
      after.facility.information_disclosure_not_reported_to;
  }

  return addParentValue(target, after);
};

export const normalizeFormValue = (
  state: FacilityValues
): PostFacilityParams => {
  return removeUnnecessaryValue({
    facility: {
      gov_business_owner: state.basic.corporationName,
      gov_facility_number: state.basic.officeNumber,
      name: state.basic.officeName,
      type_service: state.basic.serviceType,
      responsible_person: state.basic.representativeName,
      capacity:
        state.basic.capacity !== "" ? parseInt(state.basic.capacity, 10) : null,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      tel: state.basic.tel,
      multiple_facility_flg: state.basic.multiFunctionOfficeFlag
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_subordinate_flg: state.basic.masterSubordinateFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_flg: state.basic.masterFlg,
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: "",
      welfare_condition_end_date: "",
      better_supporter_condition: state.additionalItem.staffTreatmentSystemType,
      lack_of_supporter_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_supporter: null,
      lack_of_service_admin_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: STRING_FALSE_FROM_API,
      see_hear_team_flg: STRING_FALSE_FROM_API,
      total_capacity:
        state.basic.multiFunctionOfficeFlag && state.basic.allCapacity !== ""
          ? parseInt(state.basic.allCapacity, 10)
          : null,
      specific_better_supporter_condition_202404:
        state.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        state.additionalItem.specificBetterSupporterCondition202404Sub
          ? state.additionalItem.specificBetterSupporterCondition202404Sub
          : state.additionalItem.specificBetterSupporterCondition202404,
      specific_better_supporter_condition:
        state.additionalItem.staffTreatmentSpecificSystemType,
      better_supporter_condition_base_up_flg: state.additionalItem
        .betterSupporterConditionBaseUpFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      body_restricted_still_flg: booleanToNumber0or1(
        state.subtractionItem.bodyRestrictedStillFlg
      ),
      body_restricted_still_from:
        state.subtractionItem.bodyRestrictedStillFrom &&
        selectDateValueToDate(state.subtractionItem.bodyRestrictedStillFrom) !==
          ""
          ? selectDateValueToDate(state.subtractionItem.bodyRestrictedStillFrom)
          : null,
      body_restricted_still_to:
        state.subtractionItem.bodyRestrictedStillTo &&
        selectDateValueToDate(state.subtractionItem.bodyRestrictedStillTo) !==
          ""
          ? selectDateValueToDate(state.subtractionItem.bodyRestrictedStillTo)
          : null,
      abuse_prevention_measures_not_implemented_flg: booleanToNumber0or1(
        state.subtractionItem.abusePreventionMeasuresNotImplementedFlg
      ),
      abuse_prevention_measures_not_implemented_from:
        state.subtractionItem.abusePreventionMeasuresNotImplementedFrom &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
            )
          : null,
      abuse_prevention_measures_not_implemented_to:
        state.subtractionItem.abusePreventionMeasuresNotImplementedTo &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedTo
            )
          : null,
      information_disclosure_not_reported_flg: booleanToNumber0or1(
        state.subtractionItem.informationDisclosureNotReportedFlg
      ),
      information_disclosure_not_reported_from:
        state.subtractionItem.informationDisclosureNotReportedFrom &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedFrom
            )
          : null,
      information_disclosure_not_reported_to:
        state.subtractionItem.informationDisclosureNotReportedTo &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedTo
            )
          : null
    },
    facility_kodoengo: {
      specific_facilities_addition: state.additionalItem
        .specificFacilitiesAddition
        ? parseInt(state.additionalItem.specificFacilitiesAddition, 10)
        : INT_FALSE_FROM_API,
      life_support_hub_in_district_flg: state.additionalItem
        .lifeSupportHubInDistrictFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  });
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues
): PostFacilityParams => {
  return removeNoChangeData(params, normalizeFormValue(response));
};
