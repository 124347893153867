import * as React from "react";
import * as URL from "@constants/url";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AddToPhotosOutlined from "@material-ui/icons/AddToPhotosOutlined";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as DOKOENGOUsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { CarePlanState } from "@stores/domain/carePlan/types";
import dispatches from "@stores/dispatches";

import { RouteComponentProps } from "react-router-dom";
import { dateToLocalisedString } from "@/utils/date";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  SUPPORT_CARE_PLAN_NAME,
  SUPPORT_CARE_PLAN_PAGE_NAME
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    list: {
      marginTop: "32px",
      background: "#fff",
      borderRadius: "4px"
    },
    listCon: {
      marginTop: "32px",
      padding: "0 40px"
    },
    item: {
      borderTop: "1px solid #b2b2b2",
      "&:nth-child(n+2)": {
        marginTop: "24px"
      }
    },
    itemTitle: {
      borderBottom: "1px solid #d9d9d9",
      background: " rgba(245, 245, 245, 0.38)",
      padding: "10px 8px 10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    itemTitleText: {
      display: "flex",
      alignItems: "center",
      "&>p": {
        fontFamily: "HiraginoSans-W4",
        fontSize: "16px",
        lineHeight: 1.5,
        letterSpacing: "0.15px",
        color: "rgba(0, 0, 0, 0.87)",
        margin: 0,
        "&:nth-child(n+2)": {
          marginLeft: "40px"
        }
      }
    },
    itemBtn: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 8px 16px"
    },
    itemBtnIcon: {
      color: "#0277bd"
    },
    itemBtnText: {
      display: "block",
      marginLeft: "4px",
      color: "#0277bd",
      fontSize: "14px",
      lineHeight: "1.14",
      letterSpacing: "1.25px"
    },
    listMessage: {
      padding: "80px 32px",
      textAlign: "center",
      fontFamily: "HiraginoSans-W4",
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: " rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = WithStyles<typeof styles> & RouteComponentProps<{ id: string }>;
type StateProps = {
  user:
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"]
    | DOKOENGOUsersInFacilityState["user"]
    | KODOENGOUsersInFacilityState["user"];
  carePlan: CarePlanState["carePlan"];
  facilityType: FacilityType;
};
type DispatchProps = {
  fetchOneUser: (id: string, facilityType: FacilityType) => void;
  fetchSupportPlanUifIdCarePlan: (uifId: string) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const CarePlanListCore = (props: Props): JSX.Element => {
  const { user, carePlan, facilityType } = props;
  const { id } = props.match.params;

  // ページ名
  const listName = SUPPORT_CARE_PLAN_NAME[facilityType];
  const pageName = SUPPORT_CARE_PLAN_PAGE_NAME[facilityType];
  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "支援計画", path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY }
  ];
  // ユーザー情報
  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;
  const userId = `${user.user_in_facility.id}`;

  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchSupportPlanUifIdCarePlan(id);
  }, []);

  const onClickButton = (): void => {
    const url = `${URL.RECORD_SUPPORT_PLAN}/${userId}/${PLAN_LIST_TAB_PATH.CAREPLAN}/new`;
    props.history.push(url);
  };

  const createButton = (
    <KnowbeButton kind="default" onClick={onClickButton}>
      新規作成
    </KnowbeButton>
  );

  if (user.user_in_facility.id === undefined) {
    return <></>;
  }

  return (
    <AdminTemplate pageName={listName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={props.classes.wrapper}>
        <CarePlanUserInfo user={user} />
        <div className={props.classes.list}>
          <PlanListHeader
            pageName={`${listName}一覧`}
            userName={userName}
            uifId={id}
            recordType={PLAN_LIST_TAB_PATH.CAREPLAN}
            isEditing={false}
            history={props.history}
            button={createButton}
            facilityType={facilityType}
          />
          {carePlan.length !== 0 ? (
            <div className={props.classes.listCon}>
              {carePlan.map((item) => {
                const key = `key_${item.id}`;
                return (
                  <div className={props.classes.item} key={key}>
                    <div className={props.classes.itemTitle}>
                      <div className={props.classes.itemTitleText}>
                        <p>
                          作成日：
                          {dateToLocalisedString(
                            item.target_date,
                            "YYYY年M月D日"
                          )}
                        </p>
                        <p>
                          作成者：
                          {item.author_name === null ? "-" : item.author_name}
                        </p>
                      </div>
                      <div className={props.classes.itemTitleBtn}>
                        <KnowbeButton
                          kind="outlineWhite"
                          minWidth={158}
                          href={`#/record/support_plan/${userId}/care_plan/${item.id}`}
                        >
                          {pageName}
                        </KnowbeButton>
                      </div>
                    </div>
                    <div className={props.classes.itemBtn}>
                      <KnowbeButton
                        kind="iconText"
                        href={`#/record/support_plan/${userId}/care_plan/${item.id}/copy`}
                      >
                        <AddToPhotosOutlined
                          className={props.classes.itemBtnIcon}
                        />
                        <span className={props.classes.itemBtnText}>
                          この計画書をコピー
                        </span>
                      </KnowbeButton>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={props.classes.listMessage}>
              {pageName}がありません。新規作成ボタンから作成してください。
            </div>
          )}
        </div>
      </div>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    user: state[facilityType].userInFacility.user,
    carePlan: state.carePlan.carePlan,
    facilityType
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { carePlanDispatcher } = dispatches;

  return {
    fetchOneUser: (id: string, facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportPlanUifIdCarePlan: carePlanDispatcher(dispatch)
      .fetchSupportPlanUifIdCarePlan
  };
};

export const CarePlanList = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CarePlanListCore)
);
