import { InitialDataValues } from "./initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { InitialErrors } from "@interfaces/v202104/mgr/CHIIKIIKO/report/initial";
import { CHIIKIIKO_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import { addMonths, addYears } from "date-fns";
import selectDateValueToDatePaddingZeroWithoutDay from "@utils/date/selectDateValueToDatePaddingZeroWithoutDay";
import ValidationErrors from "@interfaces/ui/validationErrors";
import { SelectDateValue } from "@interfaces/ui/form";
import format from "date-fns/format";
import { selectDateValueToDate, parseDateString } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import { isEmpty } from "lodash-es";

type InitialDataErrors = InitialErrors;
type DateErrorMessage = ValidationErrors<SelectDateValue> | undefined;

const submitDateValidation = (
  data: InitialDataErrors["initial"]["dateBeginHospital"]
): boolean => {
  return (
    data === undefined ||
    (data.year === undefined &&
      data.month === undefined &&
      data.day === undefined)
  );
};

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.memo === undefined &&
      validationResult.initial.shortTimeForPsychiatricHospitalFlg ===
        undefined &&
      submitDateValidation(validationResult.initial.dateBeginHospital) &&
      submitDateValidation(validationResult.initial.dateEndHospital)
    );
  }
  return false;
};

const monthValidation = (
  values: InitialDataValues,
  error: DateErrorMessage
): InitialErrors["initial"]["dateEndHospital"] => {
  // 今月
  const thisMonth = dateToLocalisedString(
    new Date(format(values.initial.targetDate, "YYYY-MM")),
    "YYYYMM"
  );

  // 翌月
  const nextMonth = dateToLocalisedString(
    addMonths(new Date(format(values.initial.targetDate, "YYYY-MM-DD")), 1),
    "YYYYMM"
  );

  const dateEndHospital = selectDateValueToDatePaddingZeroWithoutDay(
    values.initial.dateEndHospital
  );

  let monthErrorMessage;
  if (dateEndHospital !== thisMonth && dateEndHospital !== nextMonth) {
    monthErrorMessage = "当月または翌月を設定してください";
  }
  return {
    year: error && error.year,
    month: monthErrorMessage,
    day: error && error.day
  };
};

const dateEndHospitalValidation = (
  values: InitialDataValues
): InitialErrors["initial"]["dateEndHospital"] => {
  let error;
  if (values.initial.dischargeMonthAdditionFlg) {
    error = dateValidator(
      notSelectedToEmpty(values.initial.dateEndHospital),
      "required"
    );

    if (error && !error.year && !error.day) {
      error = dateValidator(
        notSelectedToEmpty(values.initial.dateEndHospital),
        {
          type: "futureDay",
          startDate: values.initial.dateBeginHospital,
          options: {
            startLabel: "入院・入所開始日",
            endLabel: "退院・退所日"
          }
        }
      );
    }
    if (error && !error.month) {
      error = monthValidation(values, error);
    }
  }
  return error || undefined;
};

const dischargeMonthAdditionValidation = (
  values: InitialDataValues
): InitialErrors => {
  return {
    initial: {
      dateBeginHospital: validateSwitcher(
        values.initial.dischargeMonthAdditionFlg,
        dateValidator(
          notSelectedToEmpty(values.initial.dateBeginHospital),
          "required"
        )
      ),
      dateEndHospital: dateEndHospitalValidation(values)
    }
  };
};

const memoValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      memo:
        values.initial.status === CHIIKIIKO_STATUS_LIST.THREE.value
          ? validator(
              values.initial.memo,
              {
                type: "checkCharacterLength",
                length: 39
              },
              "required"
            )
          : validator(values.initial.memo, {
              type: "checkCharacterLength",
              length: 39
            })
    }
  };
};

const shortTimeForPsychiatricHospitalFlgValidation = (
  values: InitialDataValues
): InitialErrors => {
  let errorFlg;
  if (values.initial.shortTimeForPsychiatricHospitalFlg) {
    const dateBeginString = selectDateValueToDate(
      values.initial.dateBeginHospital
    );
    let dateBegin;
    if (!isEmpty(dateBeginString)) {
      dateBegin = parseDateString(dateBeginString);
    }

    const dateEndString = selectDateValueToDate(values.initial.dateEndHospital);
    let dateEnd;
    if (!isEmpty(dateEndString)) {
      dateEnd = parseDateString(dateEndString);
    }

    let outOfRange = true;
    if (dateBegin && dateEnd) {
      const threeMonthsFromBegin = addMonths(dateBegin, 3);
      const oneYearFromBegin = addYears(dateBegin, 1);
      outOfRange =
        dateEnd < threeMonthsFromBegin || oneYearFromBegin <= dateEnd;
    }

    if (outOfRange) {
      errorFlg =
        "入院・入所開始日と退院・退所日の期間が3月以上1年未満になるように設定してください。";
    }
  }

  return {
    initial: { shortTimeForPsychiatricHospitalFlg: errorFlg }
  };
};

export const validation = (values: InitialDataValues): InitialDataErrors => {
  const dischargeMonthAdditionFlgError = dischargeMonthAdditionValidation(
    values
  );
  const shortTimeForPsychiatricHospitalFlgError = shortTimeForPsychiatricHospitalFlgValidation(
    values
  );
  const memoErrors = memoValidation(values);

  return {
    initial: {
      ...dischargeMonthAdditionFlgError.initial,
      ...shortTimeForPsychiatricHospitalFlgError.initial,
      ...memoErrors.initial
    }
  };
};
