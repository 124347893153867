import * as types from "./types";
import { GetMonitoringMonthResponse } from "@api/requests/monitoringMonth/getMonitoringMonth";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchMonitoringMonthStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_STARTED } as const);
export const fetchMonitoringMonthSuccess = (
  res: GetMonitoringMonthResponse["data"]
) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS,
    payload: res
  } as const);
export const fetchMonitoringMonthFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_FAILED,
    error: err
  } as const);
export const postMonitoringMonthStarted = () =>
  ({ type: types.POST_KEIKAKUSODAN_MONITORING_MONTH_STARTED } as const);
export const postMonitoringMonthSuccess = () =>
  ({
    type: types.POST_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS
  } as const);
export const postMonitoringMonthFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_KEIKAKUSODAN_MONITORING_MONTH_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchMonitoringMonthStarted>
  | ReturnType<typeof fetchMonitoringMonthSuccess>
  | ReturnType<typeof fetchMonitoringMonthFailed>
  | ReturnType<typeof postMonitoringMonthStarted>
  | ReturnType<typeof postMonitoringMonthSuccess>
  | ReturnType<typeof postMonitoringMonthFailed>;
