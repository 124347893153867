import * as types from "./types";

export const setEdit = (inoutId?: number) =>
  ({ type: types.SET_EDIT, payload: inoutId || null } as const);
export const unsetEdit = () => ({ type: types.UNSET_EDIT } as const);
export const setCalendarDate = (date: Date) =>
  ({ type: types.SET_CALENDAR_DATE, payload: date } as const);
export const unsetCalendarDate = () =>
  ({ type: types.UNSET_CALENDAR_DATE } as const);
export const setEditWithTargetDate = (targetDate: string) =>
  ({ type: types.SET_EDIT_WITH_TARGET_DATE, payload: targetDate } as const);
export const unsetEditWithTargetDate = () =>
  ({ type: types.UNSET_EDIT_WITH_TARGET_DATE } as const);

export type ActionTypes =
  | ReturnType<typeof setEdit>
  | ReturnType<typeof unsetEdit>
  | ReturnType<typeof setCalendarDate>
  | ReturnType<typeof unsetCalendarDate>
  | ReturnType<typeof setEditWithTargetDate>
  | ReturnType<typeof unsetEditWithTargetDate>;
