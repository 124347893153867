import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// ui
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import Typography from "@material-ui/core/Typography";
import { InsertPhraseModal } from "@components/organisms/mgr/common/record/InsertPhraseModal";

import FormikCheckbox from "@components/molecules/FormikCheckbox";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { FieldItem } from "@interfaces/ui/form";

// store
import { SupportRecordUserState } from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// formik
import { FormikProps } from "formik";
import { RecordUserDetailValues } from "@initialize/mgr/TANKINYUSHO/record/userDetail/initialValues";

// utils
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";

// variables
import {
  SUPPLY_PICKUP_SERVICE_LIST,
  CUSTOM_RECORD_VITAL_DEFAULT_CHOICE
} from "@constants/variables";
import { SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM } from "@constants/mgr/TANKINYUSHO/variables";
import { SnackbarParams } from "@stores/ui/type";

const styles = (): StyleRules =>
  createStyles({
    root: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 1
    },
    editRoot: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    sectionTitle: {
      display: "flex",
      justifyContent: "space-between"
    },
    contentFlexWrap: {
      display: "flex",
      padding: "20px 0 0 0"
    },
    contentWrap: {
      padding: "0 120px 0 0",
      width: "100%"
    },
    sectionWrap: {
      position: "relative",
      "& + $sectionWrap": {
        marginTop: 30
      }
    },
    button: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    },
    fixedPhraseButton: {
      position: "absolute",
      right: -120,
      bottom: 0,
      "& > button": {
        width: 100,
        minHeight: 30
      }
    },
    multiTextWrap: {
      minWidth: 100,
      width: "20%",
      "& + $multiTextWrap": {
        marginLeft: 20
      }
    },
    multiTimeWrap: {
      "& + $multiTimeWrap": {
        marginTop: 30
      }
    },
    multiTimeText: {
      minWidth: "120px",
      width: "20%",
      paddingRight: "20px"
    },
    serviceBox: {
      minWidth: 116,
      fontSize: 12
    },
    serviceBoxLabel: {
      color: "rgba(0, 0, 0, 0.87)"
    },
    serviceBoxText: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    radioIcon: {
      position: "relative",
      top: 3
    }
  });

type OwnProps = WithStyles<typeof styles> & {
  supportRecords: SupportRecordUserState["support"][number];
  staffOptions: FieldItem[];
  formikFieldNamePrefix: string;
  isEditing: boolean;
  isEditMode: boolean;
  hiddenLabel?: boolean;
  customRecords: CustomRecordsState;
  onClickEdit: (e: React.MouseEvent, date: string) => void;
  onClickEditCancel: () => void;
  isHoliday: boolean;
  formikProps: FormikProps<RecordUserDetailValues>;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps;

/**
 * 支援記録
 */
const RecordSupportTableRowCore = (props: Props): JSX.Element => {
  const [isInsertPhraseModal, setInsertPhraseModal] = React.useState(false);
  const [insertPhraseId, setInsertPhraseId] = React.useState(0);
  const [insertPhraseFieldName, setInsertPhraseFieldName] = React.useState("");

  const openInsertPhraseModal = (
    fieldName: string,
    customRecord: CustomRecordsState[0]
  ) => (e: React.MouseEvent): void => {
    e.preventDefault();
    setInsertPhraseModal(true);
    setInsertPhraseFieldName(fieldName);
    setInsertPhraseId(customRecord.id);
  };

  const closeInsertPhraseModal = (): void => {
    setInsertPhraseModal(false);
    setInsertPhraseFieldName("");
    setInsertPhraseId(0);
  };

  const { supportRecords } = props;

  const multiTextUnit = ["度", "mmHg", "mmHg", "回", "kg"];

  /**
   * 受入時間/終了時間でコロンなしで4桁の数字を入力時、コロンを自動で付ける
   */
  const handleChangeTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    const currentValue = event.currentTarget.value;
    return /^\d{4}$/.test(currentValue)
      ? `${currentValue.slice(0, 2)}:${currentValue.slice(2)}`
      : undefined;
  };

  /**
   * カーソル外したとき、全角の数字及びピリオドを半角に変換する
   * 数値が1以上でかつ先頭が0のとき、最初の0以外の数字が表示されるまで先頭から0を省略する
   * ドットなしの形式の場合、ドットを自動で付ける（「体温」「体重」項目のみ）
   */
  const handleBlurDecimal = (defaultChoice: number | null) => (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    const halfWidthValue = event.currentTarget.value
      .replace(/[０-９．]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      )
      .replace(/^(0+)([0-9]+)/g, "$2");

    if (
      defaultChoice === CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.body_temperature ||
      defaultChoice === CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.weight
    ) {
      if (parseFloat(halfWidthValue) > 0 && !halfWidthValue.includes(".")) {
        return `${halfWidthValue}.0`;
      }
    }
    return halfWidthValue;
  };

  /**
   * 「体温」「体重」項目のみ整数の場合は8文字以上入力できないように制限する(少数の場合は10文字まで可)
   */
  const handleChangeNumber = (defaultChoice: number | null) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string => {
    const currentTarget = event.target.value;

    if (
      (defaultChoice === CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.body_temperature ||
        defaultChoice === CUSTOM_RECORD_VITAL_DEFAULT_CHOICE.weight) &&
      !/[.．]/.test(currentTarget) &&
      currentTarget.length > 8
    ) {
      return currentTarget.slice(0, 8);
    }
    if (currentTarget.length > 10) {
      return currentTarget.slice(0, 10);
    }
    return currentTarget;
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  return (
    <div
      className={props.isEditMode ? props.classes.root : props.classes.editRoot}
    >
      <div className={props.classes.sectionTitle}>
        <Typography gutterBottom variant="subtitle1" color="primary">
          <CustomDateLabel
            date={supportRecords.target_date}
            dateFormat="Do（dd）"
            holiday={props.isHoliday}
          />
        </Typography>
        {!props.isEditing ? (
          <KnowbeButton
            key="edit-start-button"
            style={{ width: 120, marginLeft: 8, height: 36 }}
            onClick={(e): void =>
              props.onClickEdit(e, supportRecords.target_date)
            }
          >
            編集
          </KnowbeButton>
        ) : (
          <div className={props.classes.button}>
            <KnowbeButton kind="outlineWhite" onClick={props.onClickEditCancel}>
              キャンセル
            </KnowbeButton>
            <FormikSubmitButton
              buttonName="保存する"
              formikProps={props.formikProps}
              errorAction={submitError}
            />
          </div>
        )}
      </div>
      <div className={props.classes.contentFlexWrap}>
        <div className={props.classes.serviceBox}>
          {supportRecords.pickup !== 0 && (
            <>
              <span className={props.classes.serviceBoxLabel}>送迎</span>
              <br />
              <span className={props.classes.serviceBoxText}>
                {SUPPLY_PICKUP_SERVICE_LIST[supportRecords.pickup].label}
              </span>
            </>
          )}
        </div>
        <div className={props.classes.contentWrap}>
          {props.customRecords.length > 0 &&
            props.customRecords.map((customRecord) => {
              const fieldName = `${props.formikFieldNamePrefix}.custom_record.input_type_first.${customRecord.id}.input_data`;
              switch (customRecord.input_type) {
                // テキスト形式
                case 1: {
                  const inputData =
                    supportRecords &&
                    supportRecords.support_record_input.find(
                      (item) =>
                        item.custom_record_item_id === customRecord.id &&
                        item.input_data
                    );

                  // 定型文
                  const fixedPhraseData = customRecord.choices
                    ? customRecord.choices.filter((item) => item.hidden === 0)
                    : [];
                  // visibility === 0かつ各項目に入力がない場合はスキップ
                  return customRecord.visibility === 1 || inputData ? (
                    <div
                      key={`${supportRecords.target_date}_${customRecord.id}`}
                      className={props.classes.sectionWrap}
                    >
                      <RecordSupportTableField
                        type="text"
                        label={customRecord.name}
                        name={fieldName}
                        value={
                          inputData && inputData.input_data
                            ? inputData.input_data
                            : "-"
                        }
                        defaultValue=""
                        placeholder=""
                        isEditing={props.isEditing}
                        tooltip={
                          customRecord.default_item ===
                          SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_comment ? (
                            <HelpToolTip
                              classes={{ icon: props.classes.radioIcon }}
                              placement="right-end"
                              title={
                                <HelpTipMessages name="supportStaffComment" />
                              }
                            />
                          ) : null
                        }
                      />
                      {props.isEditing && fixedPhraseData.length > 0 && (
                        <div className={props.classes.fixedPhraseButton}>
                          <KnowbeButton
                            kind="outline"
                            onClick={openInsertPhraseModal(
                              fieldName,
                              customRecord
                            )}
                            minWidth={100}
                          >
                            定型文
                          </KnowbeButton>
                        </div>
                      )}
                    </div>
                  ) : null;
                }
                // チェックボックス形式
                case 2: {
                  const checkedItems =
                    supportRecords &&
                    supportRecords.support_record_input.filter(
                      (item) =>
                        item.custom_record_item_id === customRecord.id &&
                        item.checked === 1
                    );
                  // visibility === 0かつ各項目に一つもチェックされていない場合はスキップ
                  return customRecord.visibility === 1 ||
                    (checkedItems && checkedItems.length > 0) ? (
                    <div
                      key={`${supportRecords.target_date}_${customRecord.id}`}
                      className={props.classes.sectionWrap}
                    >
                      <RecordSupportTableField
                        type="custom"
                        label={customRecord.name}
                      >
                        <FormGroup row>
                          {customRecord.choices &&
                            customRecord.choices.map((choice) => {
                              const checked =
                                checkedItems &&
                                checkedItems.some(
                                  (item) => item.choiced_item_id === choice.id
                                );
                              if (
                                (choice.hidden === 1 && !checked) ||
                                (customRecord.visibility === 0 && !checked)
                              ) {
                                return null;
                              }

                              // チェックボックスが正常に描画されるように値が入っていない場合はreturnする
                              if (
                                props.formikProps.values.record.length > 0 &&
                                props.formikProps.values.record[0]
                                  .custom_record &&
                                Object.keys(
                                  props.formikProps.values.record[0]
                                    .custom_record.input_type_first
                                ).length === 0
                              ) {
                                return null;
                              }

                              return (
                                <FormikCheckbox
                                  name={`${props.formikFieldNamePrefix}.custom_record.input_type_second.${customRecord.id}.${choice.id}.checked`}
                                  key={`${supportRecords.target_date}_choices_${choice.id}`}
                                  label={
                                    <span
                                      style={{
                                        fontSize: 16,
                                        color: "rgba(0, 0, 0, 0.87)"
                                      }}
                                    >
                                      {choice.name}
                                    </span>
                                  }
                                  style={{ marginBottom: 0, marginRight: 24 }}
                                  disabled={!props.isEditing}
                                />
                              );
                            })}
                        </FormGroup>
                      </RecordSupportTableField>
                    </div>
                  ) : null;
                }
                case 3: {
                  const choicedStaffName =
                    supportRecords &&
                    supportRecords.support_record_input
                      .filter(
                        (item) =>
                          item.custom_record_item_id === customRecord.id &&
                          item.checked === 1
                      )
                      .map((item) => {
                        return {
                          id: item.choiced_staff_id ? item.choiced_staff_id : 0,
                          name: item.choiced_staff_name_snapshot
                            ? item.choiced_staff_name_snapshot
                            : ""
                        };
                      });

                  // 職員情報 更新・削除 履歴を加味した参加者名 options
                  const mergedStaffOptions = generateMergedStaffOptions(
                    props.staffOptions,
                    choicedStaffName || []
                  );
                  // visibility === 0かつ一つもチェックがない場合はスキップ
                  return customRecord.visibility === 1 ||
                    (choicedStaffName && choicedStaffName.length > 0) ? (
                    <div
                      key={`${supportRecords.target_date}_${customRecord.id}`}
                      className={props.classes.sectionWrap}
                    >
                      <RecordSupportTableField
                        type="multiple"
                        label={customRecord.name}
                        name={`${props.formikFieldNamePrefix}.custom_record.input_type_third.${customRecord.id}.itemIdList`}
                        value={
                          choicedStaffName
                            ? choicedStaffName
                                .map((item) => item.name)
                                .join("、")
                            : "-"
                        }
                        defaultValue="-"
                        placeholder="選択してください"
                        isEditing={props.isEditing}
                        options={[
                          { categoryName: "", items: mergedStaffOptions }
                        ]}
                        emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                      />
                    </div>
                  ) : null;
                }
                case 4: {
                  const inputDataItems =
                    supportRecords &&
                    supportRecords.support_record_input.filter(
                      (item) =>
                        item.custom_record_item_id === customRecord.id &&
                        item.input_data
                    );
                  // 項目が受入時間・終了時間の場合
                  if (
                    customRecord.default_item ===
                    SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.start_end_time
                  ) {
                    // visibility === 0かつ各項目に入力がない場合はスキップ
                    return customRecord.visibility === 1 ||
                      (inputDataItems && inputDataItems.length > 0) ? (
                      <div
                        key={`${supportRecords.target_date}_${customRecord.id}`}
                        className={props.classes.sectionWrap}
                      >
                        {customRecord.choices &&
                          customRecord.choices.map((choice) => {
                            const supportChoiceItem =
                              inputDataItems &&
                              inputDataItems.find(
                                (item) => item.choiced_item_id === choice.id
                              );
                            return (
                              <div
                                className={props.classes.multiTimeWrap}
                                key={`${supportRecords.target_date}_${customRecord.id}_multi_text_${choice.id}`}
                              >
                                <RecordSupportTableField
                                  type="custom"
                                  label={choice.name}
                                >
                                  <div className={props.classes.multiTimeText}>
                                    <RecordTextField
                                      name={`${props.formikFieldNamePrefix}.custom_record.input_type_fourth.${customRecord.id}.${choice.id}.input_data`}
                                      value={
                                        supportChoiceItem
                                          ? `${supportChoiceItem.input_data}`
                                          : "-"
                                      }
                                      defaultValue=""
                                      placeholder=""
                                      isEditable={props.isEditing}
                                      labelType="default"
                                      nonMultiline
                                      maxLength={5}
                                      onChangeHook={handleChangeTime}
                                    />
                                  </div>
                                </RecordSupportTableField>
                              </div>
                            );
                          })}
                      </div>
                    ) : null;
                  }
                  // 血圧の最低と最高を並び替え
                  const sortedChoices = customRecord.choices.slice();
                  const index2 = sortedChoices.findIndex(
                    (choice) => choice.default_choice === 2
                  );
                  const index3 = sortedChoices.findIndex(
                    (choice) => choice.default_choice === 3
                  );

                  if (index2 !== -1 && index3 !== -1) {
                    const lowerChoice = sortedChoices[index2];
                    sortedChoices[index2] = sortedChoices[index3];
                    sortedChoices[index3] = lowerChoice;
                  }

                  // 項目が受入時間・終了時間以外の場合（バイタルの場合）
                  // visibility === 0かつ各項目に入力がない場合はスキップ
                  return customRecord.visibility === 1 ||
                    (inputDataItems && inputDataItems.length > 0) ? (
                    <div
                      key={`${supportRecords.target_date}_${customRecord.id}`}
                      className={props.classes.sectionWrap}
                    >
                      <RecordSupportTableField
                        type="custom"
                        label={customRecord.name}
                        multiTextLabel
                      >
                        <FormGroup row>
                          <div
                            style={{
                              width: "100%",
                              display: "flex"
                            }}
                          >
                            {sortedChoices &&
                              sortedChoices.map((choice) => {
                                const supportChoiceItem =
                                  inputDataItems &&
                                  inputDataItems.find(
                                    (item) => item.choiced_item_id === choice.id
                                  );
                                const unit = choice.default_choice
                                  ? multiTextUnit[choice.default_choice - 1]
                                  : "";
                                return (
                                  <div
                                    className={props.classes.multiTextWrap}
                                    key={`${supportRecords.target_date}_${customRecord.id}_multi_text_${choice.id}`}
                                  >
                                    <RecordTextField
                                      name={`${props.formikFieldNamePrefix}.custom_record.input_type_fourth.${customRecord.id}.${choice.id}.input_data`}
                                      value={
                                        supportChoiceItem
                                          ? `${supportChoiceItem.input_data}`
                                          : "-"
                                      }
                                      defaultValue=""
                                      label={choice.name}
                                      placeholder=""
                                      isEditable={props.isEditing}
                                      labelType="default"
                                      nonMultiline
                                      endAdornmentLabel={unit}
                                      onBlurHook={handleBlurDecimal(
                                        choice.default_choice
                                      )}
                                      onChangeHook={handleChangeNumber(
                                        choice.default_choice
                                      )}
                                      maxLength={10}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </FormGroup>
                      </RecordSupportTableField>
                    </div>
                  ) : null;
                }
                default:
                  return null;
              }
            })}
        </div>
      </div>
      <InsertPhraseModal
        isModalOpen={isInsertPhraseModal}
        onClose={closeInsertPhraseModal}
        formikProps={props.formikProps}
        customRecords={props.customRecords}
        insertPhraseFieldName={insertPhraseFieldName}
        insertPhraseId={insertPhraseId}
      />
    </div>
  );
};

export const RecordSupportTableRow = withStyles(styles)(
  RecordSupportTableRowCore
);
