import {
  CarePlanState,
  CarePlanDetailState
} from "@stores/v202104/domain/mgr/KODOENGO/carePlan/types";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/v202104/mgr/KODOENGO/Users/initial";
import {
  KODOENGO_MEMBER_LIST,
  KODOENGO_ACTION_CLASS_LIST
} from "@constants/mgr/KODOENGO/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";

export type InitialDataValues = InitialValues;

export const carePlanDetailsInitialValue = {
  id: null,
  actionClass: KODOENGO_ACTION_CLASS_LIST.NONE.value,
  inTime: "",
  outTime: "",
  time: 0
};

const carePlanDetailsFormatValue = (
  detail: CarePlanDetailState[]
): CarePlanDetailsFields[] => {
  return detail.map((record) => {
    return {
      id: record.id,
      actionClass: record.actionClass
        ? record.actionClass.toString()
        : KODOENGO_ACTION_CLASS_LIST.NONE.value,
      inTime: record.inTime ? record.inTime : "",
      outTime: record.outTime ? record.outTime : "",
      time: calculateForRequiredTime(record.inTime, "0", record.outTime, "0")
    };
  });
};

// デフォルト値設定
export const initialValues = (
  state: CarePlanState | null = null
): InitialDataValues => {
  const details1 =
    state && state.carePlanDetailState1.length
      ? carePlanDetailsFormatValue(state.carePlanDetailState1)
      : [];

  const details2 =
    state && state.carePlanDetailState2.length
      ? carePlanDetailsFormatValue(state.carePlanDetailState2)
      : [];

  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      id: state ? state.id : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      dayOfWeek: state && state.dayOfWeek ? state.dayOfWeek : 0,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      numberOfParticipants:
        state && state.numberOfParticipants
          ? state.numberOfParticipants.toString()
          : KODOENGO_MEMBER_LIST.ONE.value,
      practitioner1InTime:
        state && state.practitioner1InTime ? state.practitioner1InTime : "",
      practitioner1OutTime:
        state && state.practitioner1OutTime ? state.practitioner1OutTime : "",
      practitioner1CalculatedHours:
        state && state.practitioner1CalculatedHours !== null
          ? state.practitioner1CalculatedHours.toString()
          : "0",
      practitioner1Memo:
        state && state.practitioner1Memo ? state.practitioner1Memo : "",
      carePlanDetails1: details1,
      practitioner2InTime:
        state && state.practitioner2InTime ? state.practitioner2InTime : "",
      practitioner2OutTime:
        state && state.practitioner2OutTime ? state.practitioner2OutTime : "",
      practitioner2Memo:
        state && state.practitioner2Memo ? state.practitioner2Memo : "",
      timeDuplicationJudgmentFlg:
        state && state.timeDuplicationJudgmentFlg
          ? state.timeDuplicationJudgmentFlg
          : false,
      wholeCalculatedHours:
        state && state.wholeCalculatedHours !== null
          ? state.wholeCalculatedHours.toString()
          : "",
      duplicateCalculatedHours:
        state && state.duplicateCalculatedHours !== null
          ? state.duplicateCalculatedHours.toString()
          : "",
      carePlanDetails2: details2,
      licenseSameFlg:
        state && state.licenseSameFlg ? state.licenseSameFlg : false,
      secondPersonFlg:
        state && state.secondPersonFlg ? state.secondPersonFlg : false,
      sputumImplementationFlg:
        state && state.sputumImplementationFlg
          ? state.sputumImplementationFlg
          : false
    }
  };
};
