import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { AssessmentState } from "@stores/domain/assessment/types";

import { UsersInFacilityState } from "@stores/domain/mgr/GroupHome/userInFacility/types";

import dispatches from "@stores/dispatches";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  GROUP_HOME_ASSESSMENT_CATEGORY_TYPE,
  GROUP_HOME_ASSESSMENT_CATEGORY_TYPE_NAME
} from "@constants/variables";

// utils
import { dateToLocalisedString } from "@utils/date";
import getAge from "@utils/date/getAge";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 10,
      marginBottom: 20
    },
    headerTitle: {
      fontSize: 18
    },
    headerCreate: {
      fontSize: 10,
      textAlign: "right"
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start"
    },
    userInfoSencond: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: 10
    },
    userInfoNameContainer: {
      width: 216,
      borderBottom: "1px solid #000"
    },
    userInfoBirthContainer: {
      width: 136,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoGenderContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoRecipientNumberContainer: {
      width: 216,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyContainer: {
      width: 368,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoClassifyDisabilitySupportContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      fontSize: 10,
      color: "#212121",
      paddingBottom: 5
    },
    table: {
      display: "grid",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid #000",
      textAlign: "left",
      width: "100%",
      gridTemplateColumns: "73px 142px 1fr",
      "&.everyday_life": {
        gridTemplateColumns: "73px 142px repeat(5, 26px) 1fr"
      },
      "&.social_life": {
        gridTemplateColumns: "73px 142px repeat(3, 48px) 1fr"
      },
      "&.psychosomatic_state": {
        gridTemplateColumns: "73px 142px repeat(3, 26px) 1fr"
      },
      "&.aptitude_skill": {
        gridTemplateColumns: "73px 142px repeat(2, 26px) 1fr"
      },
      "&.remarks": {
        gridTemplateColumns: "128px 1fr"
      }
    },
    cell: {
      display: "flex",
      padding: "4px 6px",
      borderRight: "1px solid #000",
      borderBottom: "1px solid #000",
      fontSize: 10,
      letterSpacing: "normal",
      minHeight: 24,
      height: "100%",
      color: "rgba(0, 0, 0, 0.84)",
      breakInside: "avoid",
      "&.label": {
        justifyContent: "center",
        borderBottom: "2px solid #000"
      },
      "&.text": {
        paddingLeft: 8,
        wordBreak: "break-word"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.lastRow": {
        borderBottom: "none"
      },
      "&.lastColumn": {
        borderRight: "none"
      },
      "&.small": {
        padding: 0
      },
      "&.borderGray": {
        borderRight: "1px solid rgba(0, 0, 0, 0.3)"
      },
      "&.borderBottomNone": {
        borderBottom: "none"
      }
    },
    cellItem: {
      display: "flex",
      alignItems: "center",
      padding: "4px 6px",
      borderRight: "1px solid #000",
      borderBottom: "1px solid #000",
      fontSize: 10,
      letterSpacing: "normal",
      minHeight: 24,
      height: "100%",
      color: "rgba(0, 0, 0, 0.84)",
      breakInside: "avoid",
      "&.label": {
        justifyContent: "center",
        borderBottom: "2px solid #000"
      },
      "&.text": {
        paddingLeft: 8,
        wordBreak: "break-word"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.lastRow": {
        borderBottom: "none"
      },
      "&.lastColumn": {
        borderRight: "none"
      },
      "&.small": {
        padding: 0
      },
      "&.borderGray": {
        borderRight: "1px solid rgba(0, 0, 0, 0.3)"
      }
    },
    tableChoice2lineContainer: {
      maxHeight: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: "14px"
    },
    tableChoiceLine1: {
      whiteSpace: "nowrap",
      transform: "scale(0.8)"
    },
    tableChoiceLine2: {
      marginTop: -3,
      whiteSpace: "nowrap",
      transform: "scale(0.8)"
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    categoryName: {
      marginTop: 36,
      width: "100%",
      fontFamily: "HiraginoSans-W6",
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginBottom: 16,
      paddingBottom: 5,
      whiteSpace: "initial"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  user: UserState;
  assessmentRecord: AssessmentState["assessment"];
  userInFacility: UsersInFacilityState["user"];
  customRecords: CustomRecordsWithCategoryState;
};

type OwnProps = {
  uifId: string;
  assessmentId: string;
  query?: string;
};

type DispatchProps = {
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  getUserDetailInFacilityData: (
    uifId: string,
    facility_type: FacilityType
  ) => void;
};

type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: StateProps["userInFacility"];
  assessmentRecord: AssessmentState["assessment"];
  customRecords: CustomRecordsWithCategoryState;
  classes: Record<string, string>;
  facilityType: FacilityType;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type ContentsProps = {
  customRecords: CustomRecordsWithCategoryState;
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"];
  categoryType: number;
  classes: Record<string, string>;
  facilityType: FacilityType;
};

const Contents = (props: ContentsProps): JSX.Element => {
  const { customRecords, assessmentRecords, categoryType, classes } = props;

  const facilityCategoryType = {
    everyday_life: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.everyday_life,
    social_life: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.social_life,
    psychosomatic_state:
      GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
    aptitude_skill: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
    remarks: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks
  };

  // 選択肢情報からテーブルヘッダーを作成
  let header: JSX.Element;
  const getHeader = (
    choices: { id: number; string: string[] }[]
  ): JSX.Element => {
    return (
      <>
        <div className={`${classes.cell} label`}>カテゴリー</div>
        <div className={`${classes.cell} label`}>項目</div>
        {choices.map((c, ci) => (
          <div
            key={`td-choice-${c.id}`}
            className={`${classes.cellItem} small label ${
              // 最後の一つは右ボーダは通常線
              ci !== choices.length - 1 ? "borderGray" : ""
            }`}
          >
            <div className={`${classes.tableChoice2lineContainer}`}>
              {c.string.map((string, idx) =>
                idx === 0 ? (
                  <div key={`d1-${c.id}`} className={classes.tableChoiceLine1}>
                    {string}
                  </div>
                ) : (
                  <div key={`d2-${c.id}`} className={classes.tableChoiceLine2}>
                    {string}
                  </div>
                )
              )}
            </div>
          </div>
        ))}
        <div className={`${classes.cell} label lastColumn`}>備考</div>
      </>
    );
  };

  switch (categoryType) {
    case facilityCategoryType.everyday_life:
      header = getHeader([
        { id: 1, string: ["自立"] },
        { id: 2, string: ["ほぼ", "自立"] },
        { id: 3, string: ["一部", "介助"] },
        { id: 4, string: ["多くを", "介助"] },
        { id: 5, string: ["全て", "介助"] }
      ]);
      break;
    case facilityCategoryType.social_life:
      header = getHeader([
        { id: 1, string: ["できる"] },
        { id: 2, string: ["ときどき", "問題がある"] },
        { id: 3, string: ["できない"] }
      ]);
      break;
    case facilityCategoryType.psychosomatic_state:
      header = getHeader([
        { id: 1, string: ["普通"] },
        { id: 2, string: ["やや", "困難"] },
        { id: 3, string: ["困難"] }
      ]);
      break;
    case facilityCategoryType.aptitude_skill:
      header = getHeader([
        { id: 1, string: ["あり"] },
        { id: 2, string: ["なし"] }
      ]);
      break;
    default:
      header = <></>;
      break;
  }

  // 記録項目に対応する保存内容を取得
  const getInput = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number] => {
    const categoryIdx = assessmentRecords.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx !== -1) {
      const inputIdx = assessmentRecords[categoryIdx].input.findIndex(
        (i) => i.custom_record_item_id === item.id
      );
      if (inputIdx !== -1) {
        return assessmentRecords[categoryIdx].input[inputIdx];
      }
    }
    return {
      id: 0,
      custom_record_item_id: 0,
      input_data: ""
    };
  };

  // 入力がある記録項目のみ取得してソート
  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          const input = getInput(i);
          return input.input_data || !!input.choiced_item_id;
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter((r) => {
      return r.custom_record_items.length > 0;
    })
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

  const getTableRows = (): JSX.Element[][] => {
    /** このテーブルで描画された行数 */
    let displayRowCount = 0;
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // 項目ごと
      return category.custom_record_items.map((item, iIdx) => {
        const input = getInput(item);
        const lastItem =
          lastCategory && category.custom_record_items.length === iIdx + 1;
        displayRowCount += 1;
        return (
          <React.Fragment key={item.id}>
            {categoryType !== GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks &&
              iIdx === 0 && (
                <div
                  className={`${classes.cell} text${
                    lastCategory ? " lastRow" : ""
                  }`}
                  style={{
                    gridRowStart: displayRowCount,
                    gridRowEnd:
                      displayRowCount + category.custom_record_items.length
                  }}
                >
                  {category.name}
                </div>
              )}
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {item.name}
            </div>
            <div
              className={`${classes.cell} text lastColumn${
                lastItem ? " lastRow" : ""
              }`}
            >
              {input.input_data}
            </div>
          </React.Fragment>
        );
      });
    });
  };

  const getTableRowsRadio = (): JSX.Element[][] => {
    /** このテーブルで描画された行数(初期値でヘッダー行) */
    // カテゴリごと
    return sortedCustomRecords.map((category, categoryIndex) => {
      const lastCategory = sortedCustomRecords.length === categoryIndex + 1;
      // 項目ごと
      return category.custom_record_items.map((customRecordItem, iIdx) => {
        const input = getInput(customRecordItem);
        const lastItem =
          lastCategory && category.custom_record_items.length === iIdx + 1;
        return (
          <React.Fragment key={customRecordItem.id}>
            <div
              className={`${classes.cell} text ${
                // 項目の最後以外のborderBottomはNoneにする
                // ただし、最後のカテゴリーの場合はすべてNoneにする（一番外の枠線と干渉するため）
                lastCategory ||
                !(category.custom_record_items.length === iIdx + 1)
                  ? "borderBottomNone"
                  : ""
              }`}
            >
              {iIdx === 0 ? category.name : ""}
            </div>
            <div
              className={`${classes.cell} text${lastItem ? " lastRow" : ""}`}
            >
              {customRecordItem.name}
            </div>
            {customRecordItem.custom_record_item_choices.map(
              (choice, choiceIndex) => {
                const lastDot =
                  customRecordItem.custom_record_item_choices.length !==
                  choiceIndex + 1;
                return (
                  <div
                    key={`choice-${choice.id}`}
                    className={`${classes.cell} center${
                      lastDot ? " borderGray" : ""
                    }${lastItem ? " lastRow" : ""}`}
                  >
                    {input.choiced_item_id === choice.id && "●"}
                  </div>
                );
              }
            )}
            <div
              className={`${classes.cell} text lastColumn${
                lastItem ? " lastRow" : ""
              }`}
            >
              {input.input_data}
            </div>
          </React.Fragment>
        );
      });
    });
  };

  if (sortedCustomRecords.length === 0) {
    return <></>;
  }

  const addClasses = {
    [facilityCategoryType.everyday_life]: "everyday_life",
    [facilityCategoryType.social_life]: "social_life",
    [facilityCategoryType.psychosomatic_state]: "psychosomatic_state",
    [facilityCategoryType.aptitude_skill]: "aptitude_skill",
    [facilityCategoryType.remarks]: "remarks"
  };

  const CategoryHeader = (): JSX.Element => {
    return (
      <div className={classes.categoryName}>
        {GROUP_HOME_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}
      </div>
    );
  };

  return (
    <div>
      <CategoryHeader />
      {sortedCustomRecords.length !== 0 && (
        <div
          className={`${classes.table} ${addClasses[categoryType]} fullWidth`}
        >
          {categoryType === facilityCategoryType.remarks ? (
            getTableRows()
          ) : (
            <>
              {header}
              {getTableRowsRadio()}
            </>
          )}
        </div>
      )}
    </div>
  );
};

const AssessmentPrintCore = (props: Props): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);
  const {
    uifId,
    assessmentId,
    classes,
    userInFacility,
    assessmentRecord,
    customRecords,
    user,
    getUserDetailInFacilityData,
    fetchAssessment,
    fetchCustomRecords
  } = props;

  React.useEffect(() => {
    getUserDetailInFacilityData(uifId, user.facility_type);
    fetchAssessment(uifId, assessmentId);
    fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  if (customRecords.length === 0 || !assessmentRecord.created_at) {
    return null;
  }

  return (
    <Sheet
      classes={classes}
      userInFacility={userInFacility}
      assessmentRecord={assessmentRecord}
      customRecords={customRecords}
      facilityType={user.facility_type}
    />
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, userInFacility, assessmentRecord, customRecords } = props;

  const userDetail = userInFacility.user_in_facility;
  const uifGroupHome = userInFacility.user_in_facility_group_home;

  const getAuthorString = (): string => {
    const { author } = assessmentRecord;
    if (!author) {
      return "-";
    }
    const authorName = author.snapshot_name || author.name;
    const authorRole = author.snapshot_role || author.role;
    return `${authorName}（${authorRole}）`;
  };

  const printCategory = [
    GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.everyday_life,
    GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.social_life,
    GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
    GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
    GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks
  ];

  // 障害種別
  const flags = {
    classify_physical_flg: "身体障害",
    classify_intelligence_flg: "知的障害",
    classify_mind_flg: "精神障害",
    classify_growth_flg: "発達障害",
    classify_brain_flg: "高次脳機能障害",
    classify_handicapped_flg: "障害児",
    classify_incurable_flg: "難病等対象者"
  };

  const classifies = Object.entries(flags)
    .filter(([flag]) => userDetail[flag] === "1")
    .map(([, value]) => value);

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>アセスメントシート</div>
        <div>
          <div className={classes.headerCreate}>
            作成日：
            {assessmentRecord.assessment.target_date
              ? dateToLocalisedString(
                  assessmentRecord.assessment.target_date,
                  "YYYY年M月D日"
                )
              : "-"}
          </div>
          <div className={classes.headerCreate}>
            作成者：{getAuthorString()}
          </div>
        </div>
      </div>
      <div>
        <div className={classes.userInfo}>
          <div className={classes.userInfoNameContainer}>
            <span className={classes.userInfoItem}>氏名（フリガナ）</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.name_sei} ${userDetail.name_mei}（${userDetail.name_sei_kana} ${userDetail.name_mei_kana}）`}</span>
            </div>
          </div>
          <div className={classes.userInfoBirthContainer}>
            <span className={classes.userInfoItem}>生年月日(年齢)</span>
            <div className={classes.userInfoValue}>
              <span>
                {userDetail.date_birth !== undefined
                  ? dateToLocalisedString(userDetail.date_birth, "YYYY年M月D日")
                  : ""}
                {`(${getAge(
                  userDetail.date_birth,
                  assessmentRecord.created_at
                )}歳)`}
              </span>
            </div>
          </div>
          <div className={classes.userInfoGenderContainer}>
            <span className={classes.userInfoItem}>性別</span>
            <div className={classes.userInfoValue}>
              <span>{userDetail.gender === "1" ? "男性" : "女性"}</span>
            </div>
          </div>
        </div>
        <div className={classes.userInfoSencond}>
          <div className={classes.userInfoRecipientNumberContainer}>
            <span className={classes.userInfoItem}>受給者証番号</span>
            <div className={classes.userInfoValue}>
              <span>{`${userDetail.recipient_number}`}</span>
            </div>
          </div>
          <div className={classes.userInfoClassifyContainer}>
            <span className={classes.userInfoItem}>障害種別</span>
            <div className={classes.userInfoValue}>
              <span>{classifies.length > 0 ? classifies.join("、") : "-"}</span>
            </div>
          </div>
          <div className={classes.userInfoClassifyDisabilitySupportContainer}>
            <span className={classes.userInfoItem}>障害支援区分</span>
            <div className={classes.userInfoValue}>
              <span>
                {uifGroupHome && uifGroupHome.disability_class !== "0"
                  ? `区分${uifGroupHome.disability_class}`
                  : "なし"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {printCategory.map((type) => (
        <Contents
          key={type}
          customRecords={customRecords}
          assessmentRecords={assessmentRecord.assessment.assessment_records}
          categoryType={type}
          classes={classes}
          facilityType={props.facilityType}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const assessmentRecord = state.assessment.assessment;
  const getUserInFacility = (): StateProps["userInFacility"] => {
    return state.GroupHome.userInFacility.user;
  };
  const customRecords = state.customRecordsWithCategory.assesment;
  return {
    user,
    assessmentRecord,
    userInFacility: getUserInFacility(),
    customRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { assessmentDispatcher, customRecordsWithCategory } = dispatches;

  const fetchAssessment = (
    uifId: string,
    assessmentId: string
  ): Promise<void> =>
    assessmentDispatcher(dispatch).fetchAssessment(uifId, assessmentId);
  const getUserDetailInFacilityData = (uifId: string): Promise<void> => {
    return dispatches.GroupHome.userInFacilityDispatcher(dispatch).fetchOne(
      uifId
    );
  };
  const fetchCustomRecords = (): Promise<void> =>
    customRecordsWithCategory(dispatch).fetchCustomRecords(
      CUSTOM_RECORD_TARGET_TYPE.assessment
    );

  return {
    fetchAssessment,
    fetchCustomRecords,
    getUserDetailInFacilityData
  };
};

export const AssessmentPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssessmentPrintCore));
