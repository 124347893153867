import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";

import Table, { CellParam as HeadCellParam } from "@components/molecules/Table";
import TableHead from "@components/molecules/TableHead";
import deepEqual from "fast-deep-equal";
import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

import {
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    root: {
      overflowX: "auto"
    },
    title: {
      padding: "14px 32px"
    },
    content: {
      width: 540,
      marginTop: spacing.unit * 2,
      padding: "0px",
      // スクロールバーのデザイン対応のため（Googole Chromeのみの適用）
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    allCheck: {
      margin: "0px 32px 8px 32px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    unitCheck: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    allCheckLabel: {
      paddingLeft: 16
    },
    unitCheckLabel: {
      paddingLeft: 16
    },
    table: {
      width: "100%",
      minWidth: 540
    },
    checkbox: {
      padding: 0,
      height: 24
    },
    tableContent: {
      backgroundColor: palette.common.white
    },
    row: {
      "&:nth-of-type(even)": {
        backgroundColor: "#eceff1"
      }
    },
    unitRow: {
      "&:nth-child(2n+1)": {
        backgroundColor: "#f5f5f5"
      }
    },
    cellStyle: {
      padding: spacing.unit,
      borderBottom: "none"
    },
    checkboxCellStyle: {
      width: 50,
      padding: "0px 0px 0px 32px",
      border: "none"
    },
    unitCheckboxCellStyle: {
      width: 24,
      padding: "0px 0px 0px 61px",
      border: "none"
    },
    unitCellStyle: {
      padding: "0px 32px",
      backgroundColor: "#eceff1",
      borderTop: "1px solid #ffffff",
      borderBottom: "none",
      "&:last-child": {
        paddingRight: 32
      }
    },
    cellNumber: {
      width: 139,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      border: "none",
      fontSize: "16px",
      boxSizing: "content-box",
      "&.-4col": {
        padding: "0 0 0 30px"
      }
    },
    cellUsers: {
      width: "auto",
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      borderRight: "solid 1px",
      borderColor: "rgba(0, 0, 0, 0.12)",
      fontSize: "16px",
      borderBottom: "none",
      "&.-4col": {
        padding: "0 0 0 30px"
      }
    },
    cellServiceType: {
      width: 126,
      height: 24,
      fontSize: "16px",
      boxSizing: "content-box",
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      border: "none"
    },
    border: {
      padding: "8px 0px",
      color: "rgba(0, 0, 0, 0.12)",
      borderTop: "solid 2px",
      margin: "0px"
    },
    labelNumber: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: 139,
      boxSizing: "content-box",
      "&.-4col": {
        width: "auto",
        padding: "0 16px 0 20px"
      }
    },
    labelUsers: {
      fontSize: 14,
      padding: "0 0 0 16px",
      marginLight: 16,
      textAlign: "left"
    },
    labelServiceType: {
      fontSize: 14,
      width: "142px",
      padding: "0 0 0 16px"
    },
    labelCheckbox: {
      fontSize: 14,
      width: 56,
      paddingLeft: 32,
      "&.-4col": {
        paddingRight: 3
      }
    },
    unitLabelCheckbox: {
      fontSize: 14,
      width: 86
    },
    scroll: {
      overflowY: "auto",
      overflowX: "hidden",
      height: 441,
      // スクロールバーのデザイン対応のため（Googole Chromeのみの適用）
      "&::-webkit-scrollbar": {
        display: "none",
        width: 12
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    cancelButton: {
      width: 125,
      border: "solid 1px #cccccc"
    },
    submitButton: {
      width: 125,
      marginRight: 32,
      boxShadow: "none",
      "&:active": {
        boxShadow: "none"
      }
    },
    fraction: {
      fontSize: "12px"
    },
    fractionLabel: {
      margin: "0 2px"
    },
    facility: {
      "& > tr:nth-of-type(even)": {
        backgroundColor: "rgba(0, 0, 0, 0)"
      }
    },
    facilityName: {
      backgroundColor: "#eceff1",
      padding: "4px 40px"
    }
  });

type OwnProps = {
  labelId?: string;
  shouldDisabledNoUser?: boolean; // 全ユーザー除去を許可するか
  title: string;
  open: boolean;
  excludedUserIds: number[];
  users: {
    id: string | number;
    recipientNumber: string;
    name: string;
    type_consultation?: number;
  }[];
  onSubmit: (excludedUserIds: number[]) => void;
  onClose: () => void;
  optionalCustomInfo?: GetOptionalCustomInfoResponse["data"];
};

type Props = OwnProps & WithStyles<typeof styles>;

type State = {
  initialExcludedUserIds: number[]; // 最後に確定された値の保持
  excludedUserIds: number[]; // 現在のチェックボックスの状態
};

const col4ClassName = "-4col";

class ExcludeUsersDialog extends React.Component<Props, State> {
  static get defaultProps(): object {
    return { shouldDisabledNoUser: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      initialExcludedUserIds: props.excludedUserIds,
      excludedUserIds: props.excludedUserIds
    };
  }

  /**
   * propsのidsと初期値のidsが一致しない => 親がidsを変更したらリセット
   */
  public componentDidUpdate(): void {
    const { excludedUserIds } = this.props;
    const { initialExcludedUserIds } = this.state;
    const hasChangedOwnIds = !deepEqual(
      excludedUserIds,
      initialExcludedUserIds
    );
    if (hasChangedOwnIds) {
      this.resetExcludedUserIds(excludedUserIds);
    }
  }

  private resetExcludedUserIds = (excludedUserIds: number[]): void => {
    this.setState({
      initialExcludedUserIds: excludedUserIds,
      excludedUserIds
    });
  };

  private dialogHeaderData = (): HeadCellParam[] => {
    const { optionalCustomInfo, users, classes } = this.props;
    const hasTypeConsultation = users.some((u) => !!u.type_consultation);

    const classLabelCheckbox =
      optionalCustomInfo === undefined ||
      Array.isArray(optionalCustomInfo) ||
      optionalCustomInfo.facility_units.length === 0
        ? classes.labelCheckbox
        : classes.unitLabelCheckbox;

    if (hasTypeConsultation) {
      return [
        {
          className: classNames(classLabelCheckbox, col4ClassName),
          align: "left",
          label: ""
        },
        {
          className: classes.labelServiceType,
          align: "left",
          label: "サービス種類"
        },
        {
          className: classNames(classes.labelNumber, col4ClassName),
          align: "left",
          label: "受給者番号"
        },
        {
          className: classNames(classes.labelUsers, col4ClassName),
          align: "left",
          label: "利用者名"
        }
      ];
    }
    return [
      {
        className: classLabelCheckbox,
        align: "left",
        label: ""
      },
      {
        className: classes.labelNumber,
        align: "left",
        label: "受給者番号"
      },
      {
        className: classes.labelUsers,
        align: "left",
        label: "利用者名"
      }
    ];
  };

  private isShogaijiSoudan = (userServiceType?: number): boolean =>
    userServiceType === TYPE_CONSULTATION_SHOGAIJISODAN;

  private dialogContentData = (): JSX.Element[] => {
    const { users, classes, optionalCustomInfo } = this.props;
    if (!users) return [<TableBody key="invoice-excluded-user-table-body" />];
    const { checkbox } = classes;
    const { excludedUserIds } = this.state;

    // ユニットごとでない時の処理
    if (
      optionalCustomInfo === undefined ||
      Array.isArray(optionalCustomInfo) ||
      optionalCustomInfo.facility_units.length === 0
    ) {
      const hasTypeConsultation = users.some((u) => !!u.type_consultation);
      if (hasTypeConsultation) {
        // 利用者の配列を計画相談支援優先で並べ替え
        users.sort((userA, userB) => {
          if (
            userA.type_consultation === TYPE_CONSULTATION_KEIKAKUSODAN &&
            userB.type_consultation !== TYPE_CONSULTATION_KEIKAKUSODAN
          ) {
            return -1;
          }
          if (
            userB.type_consultation === TYPE_CONSULTATION_KEIKAKUSODAN &&
            userA.type_consultation !== TYPE_CONSULTATION_KEIKAKUSODAN
          ) {
            return 1;
          }
          return 0;
        });
      }

      return [
        <TableBody key="invoice-excluded-user-tableBody">
          {users.map((user) => {
            const isChecked = !excludedUserIds.includes(+user.id);
            return (
              <TableRow
                className={classes.row}
                role="checkbox"
                aria-checked
                tabIndex={-1}
                key={`invoice-excluded-user-${user.id}`}
              >
                <TableCell
                  key={`${user.id}-invoice-excluded-user-id}`}
                  align="left"
                  className={classes.checkboxCellStyle}
                >
                  <Checkbox
                    checked={isChecked}
                    onChange={this.onChangeExcludedUser}
                    value={`${user.id}`}
                    className={checkbox}
                  />
                </TableCell>
                {hasTypeConsultation ? (
                  <TableCell
                    key={`${user.id}-invoice-excluded-user-serviceType}`}
                    align="left"
                    className={classes.cellServiceType}
                  >
                    {this.isShogaijiSoudan(user.type_consultation)
                      ? "障害児相談支援"
                      : "計画相談支援"}
                  </TableCell>
                ) : null}
                <TableCell
                  key={`${user.id}-invoice-excluded-user-recipientNumber}`}
                  align="left"
                  className={classNames(classes.cellNumber, {
                    [col4ClassName]: hasTypeConsultation
                  })}
                >
                  {user.recipientNumber}
                </TableCell>
                <TableCell
                  key={`${user.id}-invoice-excluded-user-name}`}
                  align="left"
                  className={classNames(classes.cellUsers, {
                    [col4ClassName]: hasTypeConsultation
                  })}
                >
                  {user.name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      ];
    }
    // ユニットごとの時の処理
    const rows: JSX.Element[] = [];
    optionalCustomInfo.facility_units
      .filter((unit) => unit.id !== null)
      .forEach((unit) => {
        const unitRows: JSX.Element[] = [];
        const unitUserCount = users.filter(
          (user) =>
            typeof user.id === "number" && unit.user_ids.includes(user.id)
        ).length;
        const excludedUnitUserCount = unit.user_ids.filter((id) =>
          excludedUserIds.includes(id)
        ).length;
        unitRows.push(
          <TableRow
            className={classes.row}
            role="checkbox"
            aria-checked
            tabIndex={-1}
            key={`invoice-excluded-unit-${unit.id}`}
          >
            <TableCell
              colSpan={3}
              key={`${unit.id}-invoice-excluded-unit-id`}
              align="left"
              className={classes.unitCellStyle}
            >
              <div className={classes.unitCheck}>
                <div>
                  <Checkbox
                    checked={excludedUnitUserCount === 0 && unitUserCount !== 0}
                    disabled={unitUserCount === 0}
                    indeterminate={
                      excludedUnitUserCount > 0 &&
                      excludedUnitUserCount < unitUserCount
                    }
                    value={`${unit.id}`}
                    onChange={this.onChangeUnitExcludedUser}
                    className={classes.checkbox}
                  />
                  <span className={classes.unitCheckLabel}>
                    {unit.unit_name}
                  </span>
                </div>
                <span className={classes.fraction}>
                  <span className={classes.fractionLabel}>
                    {unitUserCount - excludedUnitUserCount}
                  </span>
                  <span className={classes.fractionLabel}>/</span>
                  <span className={classes.fractionLabel}>{unitUserCount}</span>
                  <span>名</span>
                </span>
              </div>
            </TableCell>
          </TableRow>
        );
        users
          .filter(
            (user) =>
              typeof user.id === "number" && unit.user_ids.includes(user.id)
          )
          .forEach((user) => {
            const isUserChecked = !excludedUserIds.includes(+user.id);
            unitRows.push(
              <TableRow
                className={classes.unitRow}
                role="checkbox"
                aria-checked
                tabIndex={-1}
                key={`invoice-excluded-user-${user.id}`}
              >
                <TableCell
                  key={`${user.id}-invoice-excluded-user-id}`}
                  align="left"
                  className={classes.unitCheckboxCellStyle}
                >
                  <Checkbox
                    checked={isUserChecked}
                    onChange={this.onChangeExcludedUser}
                    value={`${user.id}`}
                    className={checkbox}
                  />
                </TableCell>
                <TableCell
                  key={`${user.id}-invoice-excluded-user-recipientNumber}`}
                  align="left"
                  className={classes.cellNumber}
                >
                  {user.recipientNumber}
                </TableCell>
                <TableCell
                  key={`${user.id}-invoice-excluded-user-name}`}
                  align="left"
                  className={classes.cellUsers}
                >
                  {user.name}
                </TableCell>
              </TableRow>
            );
          });
        rows.push(
          <TableBody key={`${unit.id}-invoice-excluded-user-tableBody}`}>
            {unitRows}
          </TableBody>
        );
      });
    return rows;
  };

  /**
   * 初期値に反映して親に渡したら終了
   */
  private handleOnSubmit = (): void => {
    const { onSubmit, onClose } = this.props;
    const { excludedUserIds } = this.state;
    this.setState({ initialExcludedUserIds: excludedUserIds });
    onSubmit(excludedUserIds);
    onClose();
  };

  /**
   * 初期値に戻してから終了
   */
  private handleOnClose = (): void => {
    const { onClose } = this.props;
    const { initialExcludedUserIds } = this.state;
    this.setState({ excludedUserIds: initialExcludedUserIds });
    onClose();
  };

  private onChangeExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { excludedUserIds } = this.state;

    const id = +event.target.value;
    const tmpExcludedIds = excludedUserIds.filter((x) => x !== id);
    // idの除外後のlengthが変わっていなければ追加
    const newExcludedUserIds =
      tmpExcludedIds.length === excludedUserIds.length
        ? [...excludedUserIds, id]
        : tmpExcludedIds;
    this.setState({ excludedUserIds: newExcludedUserIds });
  };

  private onChangeAllExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { users } = this.props;
    let excludedUserIds: number[] = [];
    if (!event.target.checked) {
      excludedUserIds = users.map((user) => +user.id);
    }
    this.setState({ excludedUserIds });
  };

  private onChangeUnitExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { users, optionalCustomInfo } = this.props;
    if (
      optionalCustomInfo !== undefined &&
      !Array.isArray(optionalCustomInfo) &&
      optionalCustomInfo.facility_units.length !== 0
    ) {
      const { excludedUserIds } = this.state;
      const unit = optionalCustomInfo.facility_units.find(
        (u) => u.id === +event.target.value
      );
      const unitUserIds =
        unit === undefined
          ? []
          : users
              .filter((user) => unit.user_ids.includes(+user.id))
              .map((user) => {
                return +user.id;
              });
      const newExcludedUserIds: number[] = event.target.checked
        ? excludedUserIds.filter((id) => !unitUserIds.includes(id))
        : [...excludedUserIds, ...unitUserIds].filter((id, i, self) => {
            // 除外済みユーザーとユニット内ユーザーを足して重複削除
            return self.indexOf(id) === i;
          });
      this.setState({ excludedUserIds: newExcludedUserIds });
    }
  };

  public render(): JSX.Element {
    const {
      classes,
      users,
      open,
      labelId,
      title,
      shouldDisabledNoUser
    } = this.props;
    const { excludedUserIds } = this.state;
    const facilityTypes = Object.keys(users);
    const rows = this.dialogContentData();
    const excludedUserCount = excludedUserIds.length;
    const userCount = users.length;
    const disableSubmit =
      shouldDisabledNoUser && excludedUserCount === userCount;

    return (
      <Dialog
        open={open}
        onClose={this.handleOnClose}
        className={classes.root}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id={labelId} className={classes.title}>
          {title}
        </DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
          <div className={classes.allCheck}>
            <div>
              <Checkbox
                checked={excludedUserCount === 0}
                indeterminate={
                  excludedUserCount > 0 && excludedUserCount < userCount
                }
                value={facilityTypes[0]}
                onChange={this.onChangeAllExcludedUser}
                className={classes.checkbox}
              />
              <span className={classes.allCheckLabel}>すべて選択</span>
            </div>
            <span className={classes.fraction}>
              <span className={classes.fractionLabel}>
                {userCount - excludedUserCount}
              </span>
              <span className={classes.fractionLabel}>/</span>
              <span className={classes.fractionLabel}>{userCount}</span>
              <span>名</span>
            </span>
          </div>
          <div>
            <Table
              className={classes.table}
              key="invoice-excluded-users-table-head"
            >
              <TableHead
                role="checkbox"
                ariaChecked
                tabIndex={-1}
                key={1}
                selected
                items={this.dialogHeaderData()}
                rowStyle={{ height: 32 }}
              />
            </Table>
          </div>
          <div className={classes.scroll}>
            <Table key="invoice-excluded-users-table" className={classes.table}>
              {rows.map((row) => row)}
            </Table>
          </div>
        </DialogContent>
        <DialogActions className={classes.border}>
          <div>
            <Button
              className={classes.cancelButton}
              onClick={this.handleOnClose}
              autoFocus={false}
              color="secondary"
              variant="text"
            >
              キャンセル
            </Button>
          </div>
          <Button
            disabled={disableSubmit}
            className={classes.submitButton}
            onClick={this.handleOnSubmit}
            autoFocus
            color="secondary"
            variant="contained"
          >
            保存する
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(ExcludeUsersDialog);
