import * as types from "./types";
import * as actions from "./actions";
import { INT_FALSE_FROM_API } from "@constants/variables";

const initialState: types.MunicipalitiesInFacilityState = {
  municipalities: [],
  municipality: {
    id: 0,
    name: "",
    csv_file_name: null,
    csv_file_uploaded_date: null,
    csv_file_updated_date: null,
    calculation_time_flg: INT_FALSE_FROM_API,
    round_up_minute: 0,
    time_division_flg: INT_FALSE_FROM_API,
    billing_unit: INT_FALSE_FROM_API,
    unit_price_per_unit: 0
  },
  serviceCodes: {
    count: 0,
    page: 1,
    serviceCodes: []
  }
};

export const IDOSHIENMunicipalitiesInFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.MunicipalitiesInFacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, municipalities: action.payload.data.municipalities };
    case types.FETCH_FAILED:
      return { ...state };
    case types.FETCH_ONE_STARTED:
      return { ...state };
    case types.FETCH_ONE_SUCCESS:
      return { ...state, municipality: action.payload.data.municipality };
    case types.FETCH_ONE_FAILED:
      return { ...state };
    case types.CLEAR_ONE:
      return { ...state, municipality: initialState.municipality };
    case types.POST_MUNICIPALITY_STARTED:
    case types.POST_MUNICIPALITY_SUCCESS:
    case types.POST_MUNICIPALITY_FAILED:
      return { ...state };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_STARTED:
      return { ...state };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_SUCCESS:
      return { ...state, serviceCodes: action.payload };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_SEARCH_FAILED:
      return { ...state };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_STARTED:
      return { ...state };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_SUCCESS:
      return { ...state, serviceCodes: action.payload };
    case types.FETCH_IDOSHIEN_SERVICE_CODES_PAGINATION_FAILED:
      return { ...state };
    default:
      return state;
  }
};
