import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LinkButton from "@components/atoms/LinkButton";
import MuiTextField from "@components/molecules/MuiTextField";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import LoginErrorMessage from "@components/organisms/auth/LoginErrorMessage";
import {
  setRemainedUser,
  getRemainedUser,
  clearRemainedUser
} from "@utils/localStorage";
import {
  LoginParams,
  Lockout,
  UnLock,
  ChangePasswordUser
} from "@stores/auth/type";
import { AppState } from "@stores/type";
import classNames from "classnames";
import { getTerminalFromUA, TERMINAL } from "@utils/terminal";
import { INTRODUCTORY_CLAUSE, PRIVACY_POLICY } from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    header: {
      margin: 0,
      borderBottom: "1px solid #cfd8dc",
      fontSize: 24,
      color: "#37474f",
      fontWeight: "normal",
      "@media only screen and (min-width: 640px)": {
        padding: "24px 32px 9px"
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px 9px",
        lineHeight: 1.31
      }
    },
    form: {
      "@media only screen and (min-width: 640px)": {
        padding: "64px 0 80px"
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px"
      }
    },
    field: {
      "@media only screen and (min-width: 640px)": {
        width: 384,
        margin: "auto"
      },
      "& > div": {
        marginRight: 0,
        marginBottom: 0,
        "&:not(:last-child)": {
          "@media only screen and (min-width: 640px)": {
            marginBottom: 32
          },
          "@media only screen and (max-width: 639px)": {
            marginBottom: 24
          }
        },
        "&:last-child": {
          marginBottom: 20
        }
      }
    },
    button: {
      display: "block",
      letterSpacing: "1.25px",
      "@media only screen and (min-width: 640px)": {
        width: 288,
        margin: "0 auto 8px"
      },
      "@media only screen and (max-width: 639px)": {
        width: "100%",
        marginTop: 32
      }
    },
    link: {
      "@media only screen and (max-width: 639px)": {
        marginTop: 16,
        padding: "10px"
      }
    },
    termsWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: 32,
      flexDirection: "row"
    },
    terms: {
      marginLeft: 16
    }
  });

type OwnProps = {
  history: H.History;
};
type StateProps = {
  hasError: boolean;
  lockout: Lockout | null;
  unlock: UnLock | null;
  wishChangePasswordUsers: ChangePasswordUser[];
};
type DispatchProps = {
  login: (params: LoginParams) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  isStayLogin: boolean;
  username: string;
  password: string;
};

class LoginForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStayLogin: false,
      username: "",
      password: ""
    };
  }

  public componentDidMount(): void {
    const remainedUser = getRemainedUser();
    if (!remainedUser) {
      return;
    }
    this.setState({ isStayLogin: true, username: remainedUser });
  }

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    if (this.state.isStayLogin) {
      setRemainedUser(this.state.username);
    } else {
      clearRemainedUser();
    }
    this.handleLogin();
    e.preventDefault();
  };

  public handleUsername = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ username: e.currentTarget.value });
  };

  public handlePassword = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({ password: e.currentTarget.value });
    e.preventDefault();
  };

  public handleIsStayLogin = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState((prevState) => {
      return { isStayLogin: !prevState.isStayLogin };
    });
    e.stopPropagation();
  };

  private handleLogin = async (): Promise<void> => {
    await this.props.login({
      email: this.state.username,
      password: this.state.password
    });
    // wishChangePasswordUsersがあったら強制変更に飛ばす
    if (this.props.wishChangePasswordUsers.length > 0) {
      this.props.history.push("/password/resetreminder");
    }
  };

  private enterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.keyCode === 13) {
      this.handleLogin();
      e.preventDefault();
    }
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    const terminalFromUA = getTerminalFromUA();
    return (
      <Paper onKeyDown={this.enterKeyDown}>
        <h1 className={classes.header}>業務支援にログイン</h1>
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <div className={classes.field}>
            <MuiTextField
              id="username"
              name="username"
              label="アカウント名"
              placeholder="アカウント名"
              helperText="メールアドレスまたはID"
              size="auto"
              autoCorrect="off"
              autoCapitalize="off"
              value={this.state.username}
              onChange={this.handleUsername}
            />
            <MuiTextField
              type="password"
              id="password"
              name="password"
              label="パスワード"
              placeholder="パスワード"
              helperText=""
              size="auto"
              value={this.state.password}
              onChange={this.handlePassword}
              showVisibilityIcon
            />
            <MuiCheckbox
              label="次回からアカウント名の入力を省略する"
              checked={this.state.isStayLogin}
              value="1"
              onChange={this.handleIsStayLogin}
            />
          </div>
          {this.props.hasError && (
            <LoginErrorMessage
              lockout={this.props.lockout}
              unlock={this.props.unlock}
            />
          )}
          {terminalFromUA === TERMINAL.IPHONE ||
          terminalFromUA === TERMINAL.ANDROID ? (
            <>
              <LinkButton
                to={PRIVACY_POLICY}
                className={classes.link}
                target="_blank"
              >
                プライバシーポリシー
              </LinkButton>
              <LinkButton
                to={INTRODUCTORY_CLAUSE}
                className={classes.link}
                target="_blank"
              >
                導入約款
              </LinkButton>
            </>
          ) : (
            <div className={classes.termsWrapper}>
              <LinkButton
                to={PRIVACY_POLICY}
                className={classes.link}
                target="_blank"
              >
                プライバシーポリシー
              </LinkButton>
              <LinkButton
                to={INTRODUCTORY_CLAUSE}
                className={classNames(classes.link, classes.terms)}
                target="_blank"
              >
                導入約款
              </LinkButton>
            </div>
          )}
          <Button
            className={classes.button}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={!this.state.username || !this.state.password}
          >
            上記に同意してログイン
          </Button>
          <LinkButton to="/password/reminder" className={classes.link}>
            パスワードを忘れた場合
          </LinkButton>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  hasError: state.auth.hasError,
  lockout: state.auth.lockout,
  unlock: state.auth.unlock,
  wishChangePasswordUsers: state.auth.wishChangePasswordUsers
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch } = dispatches;
  const authDispatches = authDispatch(dispatch);
  return {
    login: (params: LoginParams): Promise<void> => {
      return authDispatches.login(params);
    }
  };
};

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(LoginForm)
);
