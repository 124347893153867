import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetOperations = {
  data: {
    operation: {
      operation_records_id: number;
      target_date: string; // yyyy-mm-dd
      counts: {
        status_type: {
          stay: number;
          hospitalization: number;
          stay_away: number;
          denominator: number;
        };
        meal: {
          custom_record_item_id: number;
          choices: {
            id: number;
            name: string;
            count: number;
          }[];
        };
      };
      operation_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked: number; // 0,1
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 指定月の業務記録を取得する
 * @param year 年
 * @param month 月
 * @param facility_unit_id ユニットID 0は事業所全体
 */
const getOperations = async (
  year: string,
  month: string,
  facility_unit_id = 0
): Promise<AxiosResponse<GetOperations>> => {
  const url = `${VERSION_URL_201910}/operations/${year}/${month}?facility_unit_id=${facility_unit_id}`;
  return request.get<GetOperations>(url);
};

export default getOperations;
