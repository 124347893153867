import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";

export const AdditionalItemFields = (): JSX.Element => {
  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.peerSupportFlg"
          label="ピアサポート体制"
        />
        <FormikCheckbox
          name="additionalItem.lifeSupportHubInDistrictFlg"
          label="地域生活支援拠点等"
          style={{ marginBottom: 0 }}
        />
      </FormGroup>
    </FormPaper>
  );
};
