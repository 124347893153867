import * as actions from "./actions";
import * as types from "./types";

const initialState: types.MonitoringReportState = {
  monitoringReportRecord: {
    id: 0,
    created_at: "",
    updated_at: "",
    guardian_name: null,
    guardian_relation: null,
    date_of_monitoring: "",
    creation_date: null,
    author: null,
    classify_disability_support: null,
    income_kind: null,
    income_category: null,
    disabled_welfare_service_recipient_number: null,
    consultation_support_recipient_number: null,
    regular_place_recipient_number: null,
    support_policy: null,
    whole_situation: null,
    staff_comment: null,
    type_consultation: 0,
    details: []
  },
  consultationCopy: null
};

export const KEIKAKUSODANMonitoringReport = (
  state = initialState,
  action: actions.ActionTypes
): types.MonitoringReportState => {
  switch (action.type) {
    case types.FETCH_MONITORINGREPORT_STARTED:
      return { ...state };
    case types.FETCH_MONITORINGREPORT_SUCCESS:
      return { ...state, monitoringReportRecord: action.payload };
    case types.FETCH_MONITORINGREPORT_FAILED:
      return { ...state };
    case types.COPY_CONSULTATION_STARTED:
      return { ...state };
    case types.COPY_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultationCopy: action.payload
      };
    case types.COPY_CONSULTATION_FAILED:
      return { ...state };
    case types.RESET_COPY_CONSULTATION:
      return {
        ...state,
        consultationCopy: null
      };
    case types.POST_MONITORINGREPORT_STARTED:
      return { ...state };
    case types.POST_MONITORINGREPORT_SUCCESS:
      return { ...state };
    case types.POST_MONITORINGREPORT_FAILED:
      return { ...state };
    case types.DELETE_MONITORINGREPORT_STARTED:
      return { ...state };
    case types.DELETE_MONITORINGREPORT_SUCCESS:
      return { ...state };
    case types.DELETE_MONITORINGREPORT_FAILED:
      return { ...state };
    case types.UNSET_MONITORINGREPORT:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
