/**
 * ActionNames
 */
// 取得
export const FETCH_CONSULTATION_STARTED =
  "CONSULTATION/FETCH_CONSULTATION_STARTED";
export const FETCH_CONSULTATION_SUCCESS =
  "CONSULTATION/FETCH_CONSULTATION_SUCCESS";
export const FETCH_CONSULTATION_FAILED =
  "CONSULTATION/FETCH_CONSULTATION_FAILED";
// 作成済み計画読み込み
export const COPY_CONSULTATION_STARTED =
  "CONSULTATION/COPY_CONSULTATION_STARTED";
export const COPY_CONSULTATION_SUCCESS =
  "CONSULTATION/COPY_CONSULTATION_SUCCESS";
export const COPY_CONSULTATION_FAILED = "CONSULTATION/COPY_CONSULTATION_FAILED";
// 作成済み計画初期化
export const RESET_COPY_CONSULTATION = "CONSULTATION/RESET_COPY_CONSULTATION";
// 更新
export const POST_CONSULTATION_STARTED =
  "CONSULTATION/POST_CONSULTATION_STARTED";
export const POST_CONSULTATION_SUCCESS =
  "CONSULTATION/UPDATE_CONSULTATION_SUCCESS";
export const POST_CONSULTATION_FAILED = "CONSULTATION/POST_CONSULTATION_FAILED";
// 削除
export const DELETE_CONSULTATION_STARTED =
  "CONSULTATION/DELETE_CONSULTATION_STARTED";
export const DELETE_CONSULTATION_SUCCESS =
  "CONSULTATION/DELETE_CONSULTATION_SUCCESS";
export const DELETE_CONSULTATION_FAILED =
  "CONSULTATION/DELETE_CONSULTATION_FAILED";
export const UNSET_CONSULTATION = "CONSULTATION/UNSET_CONSULTATION";

export type ConsultationRecordState = {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetFlg: number;
  creationDate: string;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  monitoringPeriod: string;
  monitoringStartYm: string;
  planStartYm: string;
  classifyDisabilitySupport: number;
  incomeKind: string | null;
  disabledWelfareServiceRecipientNumber: string;
  regularPlaceRecipientNumber: string;
  consultationSupportRecipientNumber: string;
  desiredLife: string;
  supportPolicy: string;
  longTermGoal: string;
  shortTermGoal: string;
  supportPlanConsultationDetails: {
    support_plan_consultation_details_id: number;
    needs: string;
    support_goal: string;
    achievement_time: string;
    welfare_service_type: string;
    provider_name: string | null;
    role_in_problem_solving: string;
    evaluation_time: string;
    other_considerations: string;
  }[];
  supportPlanConsultationSchedule: {
    support_plan_consultation_schedule_id: number;
    day_of_week: number[];
    start_time: string;
    next_day_flg: number;
    end_time: string;
    content: string;
  }[];
  mainActivities: string;
  nonWeeklyService: string;
  overallLife: string;
  staffComment: string;
  type_consultation?: number;
};

export type ConsultationState = {
  consultationRecord: ConsultationRecordState;
  copyType: string;
  consultationCopy: ConsultationRecordState | null;
};

export const COPY_TYPE = {
  details: "details",
  schedule: "schedule"
};
