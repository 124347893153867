import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export interface GetSupportPlanResponse {
  data: {
    id: number;
    support_begin_date: string | null;
    support_end_date: string | null;
    status_type: number;
    evaluation_status: number;
    user_request_type: number;
    user_request_text: string | null;
    current_status: string | null;
    long_term_goal: string | null;
    other_comment: string | null;
    staff_comment: string | null;
    minutes: string | null;
    minutes_date: string | null;
    evaluation_authorizer:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        }
      | number;
    participant:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
        }[]
      | [];
    remarks: string | null;
    author:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        }
      | number;
    authorizer:
      | {
          id: number;
          name: string;
          role: string;
          facility_id: number;
          snapshot_name: string;
          snapshot_role: string;
        }
      | number;
    creation_date: string | null;
    support_plan_goal: {
      id: number;
      sprint_type: number; // モニタリングの場合 目標種別 (1:長期,2:短期,3:その他)
      number: number;
      sprint_goal: string | null;
      adopt_info: string | null;
      support_info: string | null;
      user_comment: string | null;
      improvement_plan: string | null;
      sprint_result: number | null; // モニタリングの場合 実施
      sprint_review: string | null; // モニタリングの場合 評価
      sprint_user_evaluation: number | null; // モニタリングの場合 本人の評価
      sprint_user_review: string | null; // モニタリングの場合 本人振り返り
      sprint_staff_evaluation: number | null; // モニタリングの場合 職員からの評価
      sprint_staff_review: string | null; // モニタリングの場合 職員振り返り
    }[];
    archive: boolean;
  }[];
}

/**
 * 個別支援計画のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
const getSupportPlan = async (
  uifId: string
): Promise<AxiosResponse<GetSupportPlanResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}`;
  return request.get<GetSupportPlanResponse>(url);
};

export default getSupportPlan;
