import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  StyleRules,
  Theme,
  withStyles
} from "@material-ui/core/styles";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      margin: spacing.unit * 2,
      padding: spacing.unit * 4
    }
  });

const StyledPaper = withStyles(styles)(Paper);

type OwnProps = {
  children: React.ReactNode;
};

type Props = OwnProps;

const FormPaper = ({ children }: Props): JSX.Element => (
  <StyledPaper component="section" elevation={0}>
    {children}
  </StyledPaper>
);

export default FormPaper;
