import * as types from "./types";
import * as actions from "./actions";

const initialState: types.SupportRecordUserState = {
  date_end_in_service: "",
  counts: {
    status_type: {
      stay: 0,
      hospitalization: 0,
      stay_away: 0,
      denominator: 0
    },
    meal: {
      custom_record_item_id: 0,
      choices: []
    }
  },
  support: [],
  created_at: null,
  updated_at: null
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportRecordUserState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_RECORD_USER_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_RECORD_USER_SUCCESS:
      return { ...action.payload };
    case types.FETCH_SUPPORT_RECORD_USER_FAILED:
      return { ...state };
    case types.UNSET_SUPPORT_RECORD_USER:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
