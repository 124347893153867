import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSwitch from "@components/molecules/FormikSwitch";
import Typography from "@material-ui/core/Typography";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/report/initial";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";
import {
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST,
  FIRST_ADDITION_SPAN_LIST,
  FACILITY_TYPE_SELECT_OPTIONS,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FieldItem } from "@interfaces/ui/form";
import { Checkbox } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    categoryGroup: {
      paddingLeft: 32
    },
    firstAdditionSpanLabel: {
      width: 336
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  usersInFacilityState: UsersInFacilityState;
};

type Props = StateProps & WithStyles<typeof styles>;

const InOutReportDialogFieldsCore = (props: Props): JSX.Element => {
  const { initial } = props.formikPropsValues.initialValues;

  const [typeConsultation, setTypeConsultation] = React.useState<number>(
    initial.typeConsultation
  );
  const [status, setStatus] = React.useState<string>(initial.status);
  const [statusOptions, setStatusOptions] = React.useState<FieldItem[]>(
    typeConsultation === FACILITY_TYPE_KEIKAKUSODAN
      ? STATUS_LIST
      : STATUS_LIST_SHOGAIJISODAN
  );

  const [dischargeAdditionFlg, setDischargeAdditionFlg] = React.useState<
    boolean
  >(initial.dischargeAdditionFlg);
  const [
    medicalDaycareEducationRelationFlg,
    setMedicalDaycareEducationRelationFlg
  ] = React.useState<boolean>(initial.medicalDaycareEducationRelationFlg);
  const [firstAdditionFlg, setFirstAdditionFlg] = React.useState<boolean>(
    initial.firstAdditionFlg
  );
  const [
    inhomeNursingCareSupportOfficeRelation1Flg,
    setInhomeNursingCareSupportOfficeRelation1Flg
  ] = React.useState<boolean>(
    initial.inhomeNursingCareSupportOfficeRelation1Flg
  );
  const [
    inhomeNursingCareSupportOfficeRelation3Flg,
    setInhomeNursingCareSupportOfficeRelation3Flg
  ] = React.useState<boolean>(
    initial.inhomeNursingCareSupportOfficeRelation3Flg
  );
  const [
    inhomeNursingCareSupportOfficeRelation4Flg,
    setInhomeNursingCareSupportOfficeRelation4Flg
  ] = React.useState<boolean>(
    initial.inhomeNursingCareSupportOfficeRelation4Flg
  );
  const [
    inhomeNursingCareSupportOfficeRelation6Flg,
    setInhomeNursingCareSupportOfficeRelation6Flg
  ] = React.useState<boolean>(
    initial.inhomeNursingCareSupportOfficeRelation6Flg
  );
  const [
    childcareTransitionSupport1Flg,
    setChildcareTransitionSupport1Flg
  ] = React.useState<boolean>(initial.childcareTransitionSupport1Flg);
  const [
    childcareTransitionSupport2Flg,
    setChildcareTransitionSupport2Flg
  ] = React.useState<boolean>(initial.childcareTransitionSupport2Flg);
  const [
    childcareTransitionSupport3Flg,
    setChildcareTransitionSupport3Flg
  ] = React.useState<boolean>(initial.childcareTransitionSupport2Flg);
  const [
    intensiveSupport3AdditionFlg,
    setIntensiveSupport3AdditionFlg
  ] = React.useState<boolean>(initial.intensiveSupport3AdditionFlg);
  const [
    hospitalizationRelationAddition,
    setHospitalizationRelationAddition
  ] = React.useState<string>(initial.hospitalizationRelationAddition);
  const [
    disabledFirstAdditionFlg,
    setDisabledFirstAdditionFlg
  ] = React.useState<boolean>(
    status !== "2" || dischargeAdditionFlg || medicalDaycareEducationRelationFlg
  );
  const [
    disabledDischargeAdditionFlg,
    setDisabledDischargeAdditionFlg
  ] = React.useState<boolean>(
    status !== "2" ||
      firstAdditionFlg ||
      inhomeNursingCareSupportOfficeRelation3Flg ||
      inhomeNursingCareSupportOfficeRelation6Flg ||
      intensiveSupport3AdditionFlg
  );
  const [
    disabledIntensiveSupport3AdditionFlg,
    setDisabledIntensiveSupport3AdditionFlg
  ] = React.useState<boolean>(
    status !== "1" ||
      dischargeAdditionFlg ||
      hospitalizationRelationAddition === "1"
  );
  const [
    disabledInhomeNursingCareSupportOfficeRelation3And6Flg,
    setDisabledInhomeNursingCareSupportOfficeRelation3And6Flg
  ] = React.useState<boolean>(
    status !== "1" ||
      firstAdditionFlg ||
      hospitalizationRelationAddition !== "0" ||
      dischargeAdditionFlg
  );

  const targetFacility = React.useMemo(() => {
    const { facility } = props;
    if (facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) {
      return typeConsultation === FACILITY_TYPE_KEIKAKUSODAN
        ? findFacilityKeikakuSodan(facility)
        : findFacilityShogaijisodan(facility);
    }
    return facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN
      ? findFacilityKeikakuSodan(facility)
      : findFacilityShogaijisodan(facility);
  }, [props.facility, typeConsultation]);

  React.useEffect(() => {
    setDisabledFirstAdditionFlg(
      status !== "2" ||
        dischargeAdditionFlg ||
        medicalDaycareEducationRelationFlg
    );
  }, [status, dischargeAdditionFlg, medicalDaycareEducationRelationFlg]);

  React.useEffect(() => {
    setDisabledDischargeAdditionFlg(
      status !== "2" ||
        firstAdditionFlg ||
        intensiveSupport3AdditionFlg ||
        (typeConsultation === FACILITY_TYPE_KEIKAKUSODAN &&
          (inhomeNursingCareSupportOfficeRelation3Flg ||
            inhomeNursingCareSupportOfficeRelation6Flg)) ||
        (typeConsultation === FACILITY_TYPE_SHOGAIJISODAN &&
          (childcareTransitionSupport1Flg ||
            childcareTransitionSupport2Flg ||
            childcareTransitionSupport3Flg))
    );
  }, [
    typeConsultation,
    status,
    firstAdditionFlg,
    inhomeNursingCareSupportOfficeRelation3Flg,
    inhomeNursingCareSupportOfficeRelation6Flg,
    childcareTransitionSupport1Flg,
    childcareTransitionSupport2Flg,
    childcareTransitionSupport3Flg,
    disabledInhomeNursingCareSupportOfficeRelation3And6Flg,
    intensiveSupport3AdditionFlg,
    disabledIntensiveSupport3AdditionFlg
  ]);

  React.useEffect(() => {
    setDisabledIntensiveSupport3AdditionFlg(
      status !== "1" ||
        dischargeAdditionFlg ||
        hospitalizationRelationAddition === "1"
    );
  }, [
    status,
    dischargeAdditionFlg,
    disabledDischargeAdditionFlg,
    hospitalizationRelationAddition
  ]);

  React.useEffect(() => {
    setDisabledInhomeNursingCareSupportOfficeRelation3And6Flg(
      status !== "1" ||
        firstAdditionFlg ||
        hospitalizationRelationAddition !== "0" ||
        dischargeAdditionFlg
    );
  }, [
    status,
    firstAdditionFlg,
    disabledFirstAdditionFlg,
    hospitalizationRelationAddition,
    dischargeAdditionFlg,
    disabledDischargeAdditionFlg
  ]);

  const handleChangeTypeConsultation = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setTypeConsultation(Number(e.target.value));
    setStatusOptions(
      Number(e.target.value) === FACILITY_TYPE_KEIKAKUSODAN
        ? STATUS_LIST
        : STATUS_LIST_SHOGAIJISODAN
    );
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setStatus(e.target.value);
  };

  const handleHospitalizationRelationAddition = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setHospitalizationRelationAddition(e.target.value);
  };

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>): void => {
    props.setFormikFieldValue(e.target.name, e.target.checked);
  };

  const onChangeDischargeAdditionFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setDischargeAdditionFlg(e.target.checked);
  };

  const onChangeMedicalDaycareEducationRelationFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setMedicalDaycareEducationRelationFlg(e.target.checked);
  };

  const onChangeFirstAdditionFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setFirstAdditionFlg(e.target.checked);
    // 初回加算をOFFにした時、期間をリセットする
    if (!e.target.checked) {
      props.setFormikFieldValue("initial.firstAdditionSpan", "0");
    }
  };

  const onChangeInhomeNursingCareSupportOfficeRelation1Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setInhomeNursingCareSupportOfficeRelation1Flg(e.target.checked);
  };

  const onChangeInhomeNursingCareSupportOfficeRelation3Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setInhomeNursingCareSupportOfficeRelation3Flg(e.target.checked);
  };

  const onChangeInhomeNursingCareSupportOfficeRelation4Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setInhomeNursingCareSupportOfficeRelation4Flg(e.target.checked);
  };

  const onChangeInhomeNursingCareSupportOfficeRelation6Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setInhomeNursingCareSupportOfficeRelation6Flg(e.target.checked);
  };

  const onChangeIntensiveSupport3AdditionFlg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setIntensiveSupport3AdditionFlg(e.target.checked);
  };

  const onChangeChildcareTransitionSupport1Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setChildcareTransitionSupport1Flg(e.target.checked);
  };

  const onChangeChildcareTransitionSupport2Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setChildcareTransitionSupport2Flg(e.target.checked);
  };

  const onChangeChildcareTransitionSupport3Flg = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    onChangeCheckBox(e);
    setChildcareTransitionSupport3Flg(e.target.checked);
  };

  return (
    <FormGroup>
      <FormGroup className={props.classes.categoryGroup}>
        <FormikSelect
          name="initial.typeConsultation"
          label="サービス種類"
          style={{ width: 200 }}
          options={FACILITY_TYPE_SELECT_OPTIONS}
          onChangeHook={handleChangeTypeConsultation}
        />
        <FormikSelect
          name="initial.status"
          label="サービス内容"
          style={{ width: 200 }}
          options={statusOptions}
          onChangeHook={handleChangeStatus}
        />
        {typeConsultation === FACILITY_TYPE_KEIKAKUSODAN &&
          props.usersInFacilityState.user.user_in_facility
            .classify_handicapped_flg === Checkbox.OFF &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan
            .need_nursing_care_class !== 0 &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan
            .support_required_class === 0 && (
            <FormikCheckbox
              name="initial.sameInhomeNursingCareSupportFlg"
              label="居宅介護支援費重複減算"
              style={{ marginBottom: 0 }}
              disabled={status === "0"}
            />
          )}
        {typeConsultation === FACILITY_TYPE_KEIKAKUSODAN &&
          props.usersInFacilityState.user.user_in_facility
            .classify_handicapped_flg === Checkbox.OFF &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan
            .support_required_class !== 0 &&
          props.usersInFacilityState.user.user_in_facility_keikakusodan
            .need_nursing_care_class === 0 && (
            <FormikCheckbox
              name="initial.samePreventiveCareSupportFlg"
              label="介護予防支援費重複減算"
              style={{ marginBottom: 0 }}
              disabled={status === "0"}
            />
          )}
      </FormGroup>

      <FormGroup className={props.classes.categoryGroup}>
        {(typeConsultation === FACILITY_TYPE_KEIKAKUSODAN ||
          props.usersInFacilityState.user.user_in_facility
            .classify_handicapped_flg === Checkbox.ON) && (
          <>
            <FormikSwitch
              name="initial.firstAdditionFlg"
              label="初回加算"
              disabled={disabledFirstAdditionFlg}
              onChange={onChangeFirstAdditionFlg}
            >
              <FormikSelect
                name="initial.firstAdditionSpan"
                label="サービス等利用計画案を交付した日までの期間"
                options={FIRST_ADDITION_SPAN_LIST}
                style={{ marginBottom: 12, width: 262 }}
                disabled={
                  disabledFirstAdditionFlg &&
                  !(
                    status === "2" &&
                    targetFacility &&
                    targetFacility.lifeSupportHubInDistrictFlg &&
                    props.usersInFacilityState.user.user_in_facility
                      .classify_handicapped_flg === Checkbox.ON
                  )
                }
                FormLabelClasses={{
                  root: props.classes.firstAdditionSpanLabel
                }}
              />
            </FormikSwitch>
            <FormikCheckbox
              name="initial.dischargeAdditionFlg"
              label="退院・退所加算"
              style={{ marginBottom: 12 }}
              disabled={disabledDischargeAdditionFlg}
              onChange={onChangeDischargeAdditionFlg}
            />
            <FormikCheckbox
              name="initial.medicalDaycareEducationRelationFlg"
              label="医療・保育・教育機関等連携加算"
              style={{ marginBottom: 12 }}
              disabled={status !== "2" || firstAdditionFlg}
              onChange={onChangeMedicalDaycareEducationRelationFlg}
            />
            <FormikCheckbox
              name="initial.servicePersonnelMeetingHeldFlg"
              label="サービス担当者会議実施加算"
              style={{ marginBottom: 12 }}
              disabled={status !== "3"}
            />
            <FormikCheckbox
              name="initial.serviceProvisionMonitoringFlg"
              label="サービス提供時モニタリング加算"
              style={{ marginBottom: 12 }}
              disabled={status === "0"}
            />
            <FormikSelect
              name="initial.hospitalizationRelationAddition"
              label="入院時情報連携加算"
              style={{ width: 200 }}
              options={HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST}
              disabled={
                status === "0" ||
                (typeConsultation === FACILITY_TYPE_KEIKAKUSODAN &&
                  (inhomeNursingCareSupportOfficeRelation1Flg ||
                    inhomeNursingCareSupportOfficeRelation3Flg ||
                    inhomeNursingCareSupportOfficeRelation4Flg ||
                    inhomeNursingCareSupportOfficeRelation6Flg)) ||
                (typeConsultation === FACILITY_TYPE_SHOGAIJISODAN &&
                  (childcareTransitionSupport1Flg ||
                    childcareTransitionSupport3Flg))
              }
              onChangeHook={handleHospitalizationRelationAddition}
            />
            {targetFacility && targetFacility.lifeSupportHubInDistrictFlg && (
              <FormikCheckbox
                name="initial.lifeSupportPromotionFlg"
                label="地域生活支援拠点等相談強化加算"
                style={{ marginBottom: 12 }}
                disabled={status === "0"}
              />
            )}
            {targetFacility && targetFacility.lifeSupportHubInDistrictFlg && (
              <FormikCheckbox
                name="initial.systemPromotionFlg"
                label="地域体制強化共同支援加算"
                style={{ marginBottom: 12 }}
                disabled={status === "0"}
              />
            )}
            <Typography>集中支援加算</Typography>
            <FormikCheckbox
              name="initial.intensiveSupport1AdditionFlg"
              label="①月2回以上面接"
              style={{ marginTop: 0, marginBottom: 0 }}
              disabled={status !== "1"}
            />
            <FormikCheckbox
              name="initial.intensiveSupport2AdditionFlg"
              label="②会議を開催"
              style={{ marginBottom: 0 }}
              disabled={status !== "1"}
            />
            <FormikCheckbox
              name="initial.intensiveSupport3AdditionFlg"
              label="③会議に参加"
              style={{ marginBottom: 12 }}
              disabled={disabledIntensiveSupport3AdditionFlg}
              onChange={onChangeIntensiveSupport3AdditionFlg}
            />
          </>
        )}
        {typeConsultation === FACILITY_TYPE_KEIKAKUSODAN && (
          <>
            <Typography>居宅介護支援事業所等連携加算</Typography>
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation1Flg"
              label="①居宅介護支援等の利用、就職等に関し情報提供"
              style={{ marginTop: 0, marginBottom: 0 }}
              disabled={
                status === "0" ||
                (status === "1" &&
                  (firstAdditionFlg || hospitalizationRelationAddition !== "0"))
              }
              onChange={onChangeInhomeNursingCareSupportOfficeRelation1Flg}
            />
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation2Flg"
              label="②居宅介護支援等の利用、就職等に関し月2回以上面接"
              style={{ marginBottom: 0 }}
              disabled={status !== "1" || firstAdditionFlg}
            />
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation3Flg"
              label="③居宅介護支援等の利用、就職等に関し会議に参加"
              style={{ marginBottom: 0 }}
              disabled={disabledInhomeNursingCareSupportOfficeRelation3And6Flg}
              onChange={onChangeInhomeNursingCareSupportOfficeRelation3Flg}
            />
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation4Flg"
              label="④就職等に関し情報提供"
              style={{ marginBottom: 0 }}
              disabled={
                status === "0" ||
                (status === "1" &&
                  (firstAdditionFlg || hospitalizationRelationAddition !== "0"))
              }
              onChange={onChangeInhomeNursingCareSupportOfficeRelation4Flg}
            />
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation5Flg"
              label="⑤就職等に関し月2回以上面接"
              style={{ marginBottom: 0 }}
              disabled={status !== "1" || firstAdditionFlg}
            />
            <FormikCheckbox
              name="initial.inhomeNursingCareSupportOfficeRelation6Flg"
              label="⑥就職等に関し会議に参加"
              style={{ marginBottom: 12 }}
              disabled={disabledInhomeNursingCareSupportOfficeRelation3And6Flg}
              onChange={onChangeInhomeNursingCareSupportOfficeRelation6Flg}
            />
          </>
        )}
        {typeConsultation !== FACILITY_TYPE_KEIKAKUSODAN &&
          props.usersInFacilityState.user.user_in_facility
            .classify_handicapped_flg === Checkbox.ON && (
            <>
              <Typography>保育・教育等移行支援加算</Typography>
              <FormikCheckbox
                name="initial.childcareTransitionSupport1Flg"
                label="①文書による情報提供"
                style={{ marginTop: 0, marginBottom: 0 }}
                disabled={
                  status === "0" ||
                  (status === "1" &&
                    (dischargeAdditionFlg ||
                      hospitalizationRelationAddition !== "0"))
                }
                onChange={onChangeChildcareTransitionSupport1Flg}
              />
              <FormikCheckbox
                name="initial.childcareTransitionSupport2Flg"
                label="②利用者の居宅に訪問し、月2回以上面談"
                style={{ marginBottom: 0 }}
                disabled={status !== "1" || dischargeAdditionFlg}
                onChange={onChangeChildcareTransitionSupport2Flg}
              />
              <FormikCheckbox
                name="initial.childcareTransitionSupport3Flg"
                label="③利用者の支援の検討等に関し会議に参加"
                style={{ marginBottom: 0 }}
                disabled={
                  status !== "1" ||
                  dischargeAdditionFlg ||
                  hospitalizationRelationAddition !== "0"
                }
                onChange={onChangeChildcareTransitionSupport3Flg}
              />
            </>
          )}
      </FormGroup>

      {(typeConsultation === FACILITY_TYPE_KEIKAKUSODAN ||
        props.usersInFacilityState.user.user_in_facility
          .classify_handicapped_flg === Checkbox.ON) && (
        <FormGroup className={props.classes.categoryGroup}>
          <FormikTextField
            name="initial.memo"
            label="備考"
            size="quarterSuperLong"
          />
        </FormGroup>
      )}
    </FormGroup>
  );
};

export const InOutReportDialogFields = withStyles(styles)(
  InOutReportDialogFieldsCore
);
