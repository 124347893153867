import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import dispatches from "@stores/dispatches";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { DownloadFileType, UplimitCreateType } from "@stores/ui/download/type";
import { DownloadableUser } from "@stores/domain/invoice/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import InvoiceExcludedUserDialog from "@components/organisms/mgr/InvoiceExcludedUserDialog";
import InvoiceTargetDateDialog from "@components/organisms/mgr/InvoiceSelectDateDialog";
import DownloadTargetSelect from "@components/organisms/download/DownloadTargetSelect";
import DownloadHistory from "@components/organisms/download/DownloadHistory";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { FacilityType, EXIST_INITIAL_PAGE } from "@constants/variables";
import isEmpty from "lodash-es/isEmpty";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import UplimitCreateTypeSelectDialog from "@components/organisms/mgr/UplimitCreateTypeSelectDialog";

type DispatchProps = {
  downloadable: () => void;
  fetchHistory: () => void;
  getMultiFunctionalFacility: () => void;
  downloadJissekiCsv: (
    year: string,
    month: string,
    excluded_user_ids: number[]
  ) => Promise<void>;
  downloadSeikyuCsv: (
    year: string,
    month: string,
    excluded_user_ids: number[],
    type_consultation?: 1 | 2
  ) => Promise<void>;
  downloadUplimitCsv: (
    year: string,
    month: string,
    excluded_user_ids: number[],
    user_id_to_uplimit_type: Map<number, UplimitCreateType>,
    type_consultation?: 1 | 2
  ) => Promise<void>;
  errorInvoice: (
    year: string,
    month: string,
    excluded_user_ids: number[]
  ) => void;
  appDownloadDispatchTargetYearMonth: (yearMonth: string) => void;
  appDownloadIsDisableDownloadButton: (isDisabled: boolean) => void;
  appDownloadIsDisableExcludedUsersButton: (isDisabled: boolean) => void;
  appDownloadReadyFileType: (fileType: DownloadFileType) => void;
  appDownloadIsOpenUserModal: (open: boolean) => void;
  appDownloadExcludedUserIds: (userIds: number[]) => void;
  appDownloadTmpExcludedUserIds: (userIds: number[]) => void;
  appDownloadClearExcludedUserIds: () => void;
  appDownloadReadyTypeConsultation: (typeConsultation?: 1 | 2) => void;
  appDownloadIsOpenUplimitCreateTypeSelectModal: (open: boolean) => void;
  appDownloadUpdateUserIdToUplimitType: (
    userIdToUplimitType: Map<number, UplimitCreateType>
  ) => void;
  openErrorsDialog: () => void;
  closeErrorsDialog: () => void;
  fetchSEIKATSUKAGIOInitial: () => Promise<void>;
  fetchJIRITSUKUNRENInitial: () => Promise<void>;
  fetchIABInitial: () => Promise<void>;
  fetchTANKINYUSHOInital: () => Promise<void>;
  fetchSHISETSUNYUSHOInitial: () => Promise<void>;
  fetchKEIKAKUSODANFacility: () => Promise<void>;
};

type State = {
  actionButtonType: "csv" | "print" | null;
  actionButtonTypeConsultation: 1 | 2 | undefined;
  targetYear: string;
  targetMonth: string;
  downloadReadyFileType: DownloadFileType;
  readonly isOpenSelectDateModal: boolean;
  readonly selectedDownloadPath: string;
  readonly selectedDataKey: string;
  readonly selectedDateTitle: string[];
  readonly isDownload: boolean;
  // 初期設定が設定されているかどうか、初期設定がない種別はfalse
  readonly isNotFinishedInitialData: boolean;
};

type DownloadCsvAction = {
  isDisabled: boolean;
  label: string;
  onClick: () => void;
};

type Props = DispatchProps & RouteComponentProps & AppState;

const initialState: State = {
  actionButtonType: null,
  actionButtonTypeConsultation: undefined,
  targetYear: "",
  targetMonth: "",
  downloadReadyFileType: DownloadFileType.NONE,
  isOpenSelectDateModal: false,
  selectedDownloadPath: "",
  selectedDataKey: "",
  selectedDateTitle: [],
  isDownload: false,
  isNotFinishedInitialData: false
};

const downloadFileLabelKeikakusodan = {
  jisseki: "請求書・明細書(計画相談支援)",
  costAmountList: "利用者負担額一覧表(計画相談支援)",
  upLimit: "利用者負担上限額管理結果票(計画相談支援)"
};

const downloadFileLabelShogaijisodan = {
  jisseki: "請求書・明細書(障害児相談支援)",
  costAmountList: "利用者負担額一覧表(障害児相談支援)",
  upLimit: "利用者負担上限額管理結果票(障害児相談支援)"
};

/**
 * 請求画面
 */
class Download extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public async componentDidMount(): Promise<void> {
    this.props.getMultiFunctionalFacility();
    await this.props.downloadable();
    this.props.fetchHistory();

    if (this.props.user.facility_type === FacilityType.KEIKAKUSODAN) {
      this.props.fetchKEIKAKUSODANFacility();
    }

    // 選択済みの対象請求月が編集で消えることがありえるのでボタンの非活性を行う
    if (
      this.props.appDownload.targetYearMonth &&
      !this.hasTargetUser(this.props.appDownload.targetYearMonth)
    ) {
      this.props.appDownloadIsDisableDownloadButton(true);
      this.props.appDownloadIsDisableExcludedUsersButton(true);
    }
    if (
      this.props.user.facility_type &&
      EXIST_INITIAL_PAGE.includes(this.props.user.facility_type)
    ) {
      this.setIsNotFinishedInitialData(this.props.user.facility_type);
    }
  }

  public componentDidUpdate(nextProps: Props): void {
    this.preCheckDownload(nextProps);
  }

  private onTargetDateDialogOpen = (
    title: string[],
    path: string,
    dataKey: string
  ) => (): void => {
    this.setState({
      isOpenSelectDateModal: true,
      selectedDownloadPath: path,
      selectedDataKey: dataKey,
      selectedDateTitle: title
    });
  };

  private onViewPreview = (targetDate: string, noticeDate: string): void => {
    const noticeDateQuery = noticeDate && `&notice_date=${noticeDate}`;
    this.props.history.push(
      `/download/print/user/${this.state.selectedDownloadPath}/${this.state.selectedDataKey}?target_date=${targetDate}${noticeDateQuery}`
    );
  };

  private onTargetDateDialogCancel = (): void => {
    this.setState({
      isOpenSelectDateModal: false
    });
  };

  // 利用者選択を保存した時
  private onUserModalSubmitClose = (): void => {
    this.props.appDownloadExcludedUserIds(
      this.props.appDownload.tmpExcludedUserIds
    );
    this.props.appDownloadIsOpenUserModal(false);
  };

  // 利用者選択をキャンセルした時
  private onUserModalClose = (): void => {
    this.props.appDownloadIsOpenUserModal(false);
  };

  /**
   * 請求対象月の変更
   */
  private onChangeMonthSelect = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.props.appDownloadDispatchTargetYearMonth(event.target.value);
    if (
      event.target.value !== undefined &&
      event.target.value.length > 0 &&
      this.hasTargetUser(event.target.value)
    ) {
      this.props.appDownloadIsDisableDownloadButton(false);
      this.props.appDownloadIsDisableExcludedUsersButton(false);
    } else {
      this.props.appDownloadIsDisableDownloadButton(true);
      this.props.appDownloadIsDisableExcludedUsersButton(true);
    }
    this.props.appDownloadClearExcludedUserIds();
  };

  /**
   * 対象外のユーザー選択モーダルを開く
   */
  private onClickButton = (): void => {
    this.props.appDownloadTmpExcludedUserIds(
      this.props.appDownload.excludedUserIds
    );
    this.props.appDownloadIsOpenUserModal(true);
  };

  /**
   * 対象外のユーザーの全て選択
   */
  private onChangeAllExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const monthUsers = this.targetMonthUser();
    const serviceType = event.target.value as FacilityType;
    const users = monthUsers[serviceType];
    const userIds = users.map((user) => user.id);

    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter(
          (m) => !userIds.includes(m)
        )
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userIds
      );
      const res = Array.from(new Set(userIdsData)); // unique
      this.props.appDownloadTmpExcludedUserIds(res);
    }
  };

  /**
   * 対象外のユーザーを全て選択(多機能)
   */
  private onChangeAllExcludedUserMultiple = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const monthUsers = this.targetMonthUser();
    let userIds: number[] = [];
    Object.keys(monthUsers).forEach((key) => {
      const users: DownloadableUser[] = monthUsers[key];
      userIds = userIds.concat(users.map((user) => user.id));
    });
    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter(
          (m) => !userIds.includes(m)
        )
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userIds
      );
      const res = Array.from(new Set(userIdsData)); // unique
      this.props.appDownloadTmpExcludedUserIds(res);
    }
  };

  /**
   * 対象外のユーザーのチェックボックスを押した時のボタン
   */
  private onChangeExcludedUser = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    // string -> number
    const userId: number = +event.target.value;

    if (event.target.checked) {
      this.props.appDownloadTmpExcludedUserIds(
        this.props.appDownload.tmpExcludedUserIds.filter((m) => m !== userId)
      );
    } else {
      const userIdsData = this.props.appDownload.tmpExcludedUserIds.concat(
        userId
      );
      const userIds = Array.from(new Set(userIdsData)); // unique
      this.props.appDownloadTmpExcludedUserIds(userIds);
    }
  };

  /**
   * 作成区分コード選択モーダルを閉じる
   */
  private onCloseUplimitCreateTypeSelectModal = (): void => {
    this.props.appDownloadIsOpenUplimitCreateTypeSelectModal(false);
    this.props.appDownloadUpdateUserIdToUplimitType(new Map());
  };

  /**
   * 利用者に紐づく作成区分コードを変更する
   * @param userId
   * @param uplimitCreateType
   */
  private onChangeUplimitCreateType = (
    userId: number,
    uplimitCreateType: UplimitCreateType
  ): void => {
    const { userIdToUplimitCreateType } = this.props.appDownload;
    userIdToUplimitCreateType.set(userId, uplimitCreateType);

    this.props.appDownloadUpdateUserIdToUplimitType(
      new Map(userIdToUplimitCreateType)
    );
  };

  private onSubmitUplimitDownload(): void {
    const { targetYear, targetMonth } = this.props.appDownload;
    if (!targetYear || !targetMonth) {
      return;
    }
    this.props.errorInvoice(
      targetYear,
      targetMonth,
      this.props.appDownload.excludedUserIds
    );
    this.setState({ isDownload: true });
    this.props.appDownloadIsOpenUplimitCreateTypeSelectModal(false);
    this.props.appDownloadReadyFileType(DownloadFileType.UPLIMIT_CSV);
    this.props.appDownloadReadyTypeConsultation();
  }

  private handleClickErrorsDialog = (): void => {
    this.checkDownload(
      this.state.targetYear,
      this.state.targetMonth,
      this.state.downloadReadyFileType,
      this.state.actionButtonTypeConsultation
    );
    this.props.closeErrorsDialog();
  };

  /**
   * 指定の月に対象利用者が1人以上存在するかを返す
   */
  private hasTargetUser = (selectMonth: string): boolean => {
    const currentMonthData = this.props.invoice.downloadable.months.find(
      (month) => month.date === selectMonth
    );
    if (!currentMonthData || currentMonthData.results.length === 0) {
      return false;
    }

    // 受給者証未発行/見学中の利用者を除外
    const users = currentMonthData.results.map((result) =>
      result.users.filter((user) => user.none_recipient_number_flg === 0)
    );
    const userCounts = users.reduce((acc, val) => acc.concat(val), []).length;
    return userCounts > 0;
  };

  private checkDownload = (
    year: string,
    month: string,
    fileType: DownloadFileType,
    typeConsultation?: 1 | 2
  ): void => {
    const downloadReadyFileType = fileType;
    const targetYear = year;
    const targetMonth = month;
    switch (downloadReadyFileType) {
      case DownloadFileType.JISSEKI_CSV:
        if (!this.state.isDownload) break;
        this.setState({ isDownload: false });
        this.props
          .downloadJissekiCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds
          )
          .then(() => {
            this.props.fetchHistory();
          });
        break;
      case DownloadFileType.SEIKYU_CSV:
        if (!this.state.isDownload) break;
        this.setState({ isDownload: false });
        this.props
          .downloadSeikyuCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds,
            typeConsultation
          )
          .then(() => {
            this.props.fetchHistory();
          });
        break;
      case DownloadFileType.UPLIMIT_CSV:
        if (!this.state.isDownload) break;
        this.setState({ isDownload: false });
        this.props
          .downloadUplimitCsv(
            targetYear,
            targetMonth,
            this.props.appDownload.excludedUserIds,
            this.props.appDownload.userIdToUplimitCreateType,
            typeConsultation
          )
          .then(() => {
            this.props.fetchHistory();
          })
          .finally(() => {
            this.props.appDownloadUpdateUserIdToUplimitType(new Map());
          });
        this.props.appDownloadIsOpenUplimitCreateTypeSelectModal(false);
        break;
      case DownloadFileType.JISSEKI_PRINT:
        this.props.history.push(
          `/download/print/jisseki/${targetYear}/${targetMonth}`
        );
        break;
      case DownloadFileType.SEIKYU_PRINT:
        this.props.history.push(
          typeConsultation
            ? `/download/print/seikyu/${targetYear}/${targetMonth}?typeConsultation=${typeConsultation}`
            : `/download/print/seikyu/${targetYear}/${targetMonth}`
        );
        break;
      case DownloadFileType.USER_COST_AMOUNT_LIST_PRINT:
        this.props.history.push(
          typeConsultation
            ? `/download/print/user_cost_amount_list/${targetYear}/${targetMonth}?typeConsultation=${typeConsultation}`
            : `/download/print/user_cost_amount_list/${targetYear}/${targetMonth}`
        );
        break;
      case DownloadFileType.UPLIMIT_PRINT:
        this.props.history.push(
          typeConsultation
            ? `/download/print/uplimit/${targetYear}/${targetMonth}?typeConsultation=${typeConsultation}`
            : `/download/print/uplimit/${targetYear}/${targetMonth}`
        );
        break;
      default:
    }
  };

  /**
   * 初期設定にて初回請求日を設定しているかどうか
   * 種別ごとに参照するデータが違うためswitchで種別判定を行う。
   */
  private setIsNotFinishedInitialData = (facilityType: FacilityType): void => {
    switch (facilityType) {
      case FacilityType.SEIKATSUKAIGO:
        this.props.fetchSEIKATSUKAGIOInitial().then(() => {
          this.setState({
            isNotFinishedInitialData: isEmpty(
              this.props.SEIKATSUKAIGO.initial.facility.first_time_bill_date
            )
          });
        });
        break;
      case FacilityType.JIRITSUKUNRENSEIKATSU:
        this.props.fetchJIRITSUKUNRENInitial().then(() => {
          this.setState({
            isNotFinishedInitialData: isEmpty(
              this.props.JIRITSUKUNRENSEIKATSU.initial.facility
                .first_time_bill_date
            )
          });
        });
        break;

      case FacilityType.IKOU:
      case FacilityType.A:
      case FacilityType.B:
        this.props.fetchIABInitial().then(() => {
          this.setState({
            isNotFinishedInitialData: isEmpty(
              this.props.IAB.initial.facility.first_time_bill_date
            )
          });
        });
        break;
      case FacilityType.TANKINYUSHO:
        this.props.fetchTANKINYUSHOInital().then(() => {
          this.setState({
            isNotFinishedInitialData: isEmpty(
              this.props.TANKINYUSHO.initial.facility.first_time_bill_date
            )
          });
        });
        break;
      case FacilityType.SHISETSUNYUSHO:
        this.props.fetchSHISETSUNYUSHOInitial().then(() => {
          this.setState({
            isNotFinishedInitialData: isEmpty(
              this.props.SHISETSUNYUSHO.initial.facility.first_time_bill_date
            )
          });
        });
        break;
      default:
        this.setState({
          isNotFinishedInitialData: false
        });
    }
  };

  /**
   * エラーを確認して大丈夫ならば、ファイルをダウンロードや、印刷ページに遷移する
   * @param nextProps
   */
  private preCheckDownload(nextProps: Props): void {
    const { downloadReadyFileType } = nextProps.appDownload;
    if (
      downloadReadyFileType !== DownloadFileType.NONE &&
      !nextProps.errors.invoice.loading
    ) {
      // ユーザー任意で実行することがあるためキープしておく
      this.setState({
        targetYear: nextProps.appDownload.targetYear,
        targetMonth: nextProps.appDownload.targetMonth,
        downloadReadyFileType: nextProps.appDownload.downloadReadyFileType,
        actionButtonTypeConsultation: nextProps.appDownload.typeConsultation
      });

      if (nextProps.errors.invoice.hasError) {
        // エラーが混じっている場合実行出来なくする
        const hasTypeError = nextProps.errors.invoice.data.some((data) =>
          data.errors.some((error) => error.type === "error")
        );
        // CSVかPrintで実行できるアクションを切り替える
        switch (downloadReadyFileType) {
          case DownloadFileType.JISSEKI_CSV:
          case DownloadFileType.SEIKYU_CSV:
          case DownloadFileType.UPLIMIT_CSV:
            this.setState({ actionButtonType: !hasTypeError ? "csv" : null });
            break;
          case DownloadFileType.JISSEKI_PRINT:
          case DownloadFileType.SEIKYU_PRINT:
          case DownloadFileType.USER_COST_AMOUNT_LIST_PRINT:
          case DownloadFileType.UPLIMIT_PRINT:
            this.setState({ actionButtonType: !hasTypeError ? "print" : null });
            break;
          default:
            this.setState({ actionButtonType: null });
        }
        this.props.openErrorsDialog();
      } else {
        this.checkDownload(
          nextProps.appDownload.targetYear,
          nextProps.appDownload.targetMonth,
          nextProps.appDownload.downloadReadyFileType,
          nextProps.appDownload.typeConsultation
        );
      }
      this.props.appDownloadReadyFileType(DownloadFileType.NONE);
    }
  }

  /**
   * 特定の月の、対象施設名を事業所種別ごとに取得
   * {[key in FacilityType]: }
   */
  private targetMonthFacilityName(): { [key in FacilityType]: string } {
    const { months } = this.props.invoice.downloadable;
    const { targetYearMonth } = this.props.appDownload;
    const monthes = months.filter((m) => m.date === targetYearMonth);
    const newState = {};
    let index = 0;

    const res = monthes.reduce((acc, month) => {
      const data = month.results.reduce((prev, result) => {
        const { name } = result.facility;
        index += 1;
        newState[index] = name;
        return {
          ...acc,
          ...newState
        };
      }, {});
      return {
        ...acc,
        ...data
      };
    }, {} as { [key in FacilityType]: string });
    return res;
  }

  /**
   * 特定の月の、対象ユーザーを事業所種別ごとに取得
   * {[key in FacilityType]: user}
   */
  private targetMonthUser(): { [key in FacilityType]: DownloadableUser[] } {
    const { months } = this.props.invoice.downloadable;
    const { targetYearMonth } = this.props.appDownload;
    const monthes = months.filter((m) => m.date === targetYearMonth);
    const newState = {};
    let index = 0;
    const res = monthes.reduce((acc, month) => {
      const data = month.results.reduce((prev, result) => {
        index += 1;
        newState[index] = result.users;
        return {
          ...acc,
          ...newState
        };
      }, {});
      return {
        ...acc,
        ...data
      };
    }, {} as { [key in FacilityType]: DownloadableUser[] });
    return res;
  }

  private uplimitCsvDownloadClickHandler(typeConsultation?: 1 | 2): void {
    const { targetYear, targetMonth } = this.props.appDownload;
    if (!targetYear || !targetMonth) {
      return;
    }
    this.props.appDownloadIsOpenUplimitCreateTypeSelectModal(true);
    this.props.appDownloadReadyTypeConsultation(typeConsultation);
  }

  private downloadCsvAction(): DownloadCsvAction[] {
    const isDisabled =
      this.props.appDownload.isDisableDownloadButton ||
      this.props.errors.invoice.loading ||
      this.state.isNotFinishedInitialData;

    const commonClickHandler = (
      fileType: DownloadFileType,
      typeConsultation?: 1 | 2
    ): void => {
      const { targetYear, targetMonth } = this.props.appDownload;
      if (!!targetYear && !!targetMonth) {
        this.props.errorInvoice(
          targetYear,
          targetMonth,
          this.props.appDownload.excludedUserIds
        );
        this.setState({ isDownload: true });
        this.props.appDownloadReadyFileType(fileType);
        this.props.appDownloadReadyTypeConsultation(typeConsultation);
      }
    };

    const createAction = (
      fileType: DownloadFileType,
      label: string,
      typeConsultation?: 1 | 2,
      clickHandler?: () => void
    ): DownloadCsvAction => {
      return {
        isDisabled,
        label,
        onClick:
          clickHandler != null
            ? clickHandler
            : (): void => commonClickHandler(fileType, typeConsultation)
      };
    };

    switch (this.props.user.facility_type) {
      case FacilityType.KEIKAKUSODAN:
        switch (this.props.KEIKAKUSODAN.facility.facilityType) {
          case FACILITY_TYPE_KEIKAKUSODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_CSV,
                downloadFileLabelKeikakusodan.jisseki,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_CSV,
                downloadFileLabelKeikakusodan.upLimit,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN,
                () => this.uplimitCsvDownloadClickHandler()
              )
            ];
          case FACILITY_TYPE_SHOGAIJISODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_CSV,
                downloadFileLabelShogaijisodan.jisseki,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_CSV,
                downloadFileLabelShogaijisodan.upLimit,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN,
                () => this.uplimitCsvDownloadClickHandler()
              )
            ];
          case FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_CSV,
                downloadFileLabelKeikakusodan.jisseki,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.SEIKYU_CSV,
                downloadFileLabelShogaijisodan.jisseki,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_CSV,
                downloadFileLabelKeikakusodan.upLimit,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN,
                () => this.uplimitCsvDownloadClickHandler()
              ),
              createAction(
                DownloadFileType.UPLIMIT_CSV,
                downloadFileLabelShogaijisodan.upLimit,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN,
                () => this.uplimitCsvDownloadClickHandler()
              )
            ];
          default:
            return [];
        }

      default:
        return [
          createAction(DownloadFileType.JISSEKI_CSV, "サービス提供実績記録票"),
          createAction(DownloadFileType.SEIKYU_CSV, "請求書・明細書"),
          {
            ...createAction(
              DownloadFileType.UPLIMIT_CSV,
              "利用者負担上限額管理結果票",
              undefined,
              () => this.uplimitCsvDownloadClickHandler()
            )
          }
        ];
    }
  }

  private downloadPrintAction(): DownloadCsvAction[] {
    const isDisabled =
      this.props.appDownload.isDisableDownloadButton ||
      this.props.errors.invoice.loading ||
      this.state.isNotFinishedInitialData;

    const commonClickHandler = (
      fileType: DownloadFileType,
      typeConsultation?: 1 | 2
    ): void => {
      const { targetYear, targetMonth } = this.props.appDownload;
      if (!!targetYear && !!targetMonth) {
        this.props.errorInvoice(
          targetYear,
          targetMonth,
          this.props.appDownload.excludedUserIds
        );
        this.props.appDownloadReadyFileType(fileType);
        this.props.appDownloadReadyTypeConsultation(typeConsultation);
      }
    };

    const createAction = (
      fileType: DownloadFileType,
      label: string,
      typeConsultation?: 1 | 2
    ): DownloadCsvAction => {
      return {
        isDisabled,
        label,
        onClick: (): void => commonClickHandler(fileType, typeConsultation)
      };
    };

    switch (this.props.user.facility_type) {
      case FacilityType.KEIKAKUSODAN:
        switch (this.props.KEIKAKUSODAN.facility.facilityType) {
          case FACILITY_TYPE_KEIKAKUSODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_PRINT,
                downloadFileLabelKeikakusodan.jisseki,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.USER_COST_AMOUNT_LIST_PRINT,
                downloadFileLabelKeikakusodan.costAmountList,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_PRINT,
                downloadFileLabelKeikakusodan.upLimit,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              )
            ];
          case FACILITY_TYPE_SHOGAIJISODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_PRINT,
                downloadFileLabelShogaijisodan.jisseki,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.USER_COST_AMOUNT_LIST_PRINT,
                downloadFileLabelShogaijisodan.costAmountList,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_PRINT,
                downloadFileLabelShogaijisodan.upLimit,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              )
            ];
          case FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN:
            return [
              createAction(
                DownloadFileType.SEIKYU_PRINT,
                downloadFileLabelKeikakusodan.jisseki,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.SEIKYU_PRINT,
                downloadFileLabelShogaijisodan.jisseki,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.USER_COST_AMOUNT_LIST_PRINT,
                downloadFileLabelKeikakusodan.costAmountList,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.USER_COST_AMOUNT_LIST_PRINT,
                downloadFileLabelShogaijisodan.costAmountList,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_PRINT,
                downloadFileLabelKeikakusodan.upLimit,
                FACILITY_TARGET_TYPE_KEIKAKUSODAN
              ),
              createAction(
                DownloadFileType.UPLIMIT_PRINT,
                downloadFileLabelShogaijisodan.upLimit,
                FACILITY_TARGET_TYPE_SHOGAIJISODAN
              )
            ];
          default:
            return [];
        }

      default:
        return [
          createAction(
            DownloadFileType.JISSEKI_PRINT,
            "サービス提供実績記録票"
          ),
          createAction(DownloadFileType.SEIKYU_PRINT, "請求書・明細書"),
          createAction(
            DownloadFileType.USER_COST_AMOUNT_LIST_PRINT,
            "利用者負担額一覧表"
          ),
          createAction(
            DownloadFileType.UPLIMIT_PRINT,
            "利用者負担上限額管理結果票"
          )
        ];
    }
  }

  public render(): JSX.Element {
    return (
      <AdminTemplate pageName="請求">
        <DownloadTargetSelect
          selected={false}
          months={this.props.invoice.downloadable.months}
          isDisabledSelect={false}
          isDisabledButton={this.props.appDownload.isDisableExcludedUsersButton}
          onChangeSelect={this.onChangeMonthSelect}
          onClickButton={this.onClickButton}
          excludedUserIds={this.props.appDownload.excludedUserIds}
          value={this.props.appDownload.targetYearMonth}
          downloadCsvActions={this.downloadCsvAction()}
          downloadPrintActions={this.downloadPrintAction()}
          facility={this.props.invoice.facilityMultiFunctional.facility}
          multiFacilities={
            this.props.invoice.facilityMultiFunctional.multipleFacilities
          }
          facilityType={this.props.user.facility_type}
          facilityTypeKEIKAKUSODAN={
            this.props.KEIKAKUSODAN.facility.facilityType
          }
          isNotFinishedInitialData={this.state.isNotFinishedInitialData}
        />
        <DownloadHistory
          facilityType={this.props.user.facility_type}
          invoice={this.props.invoice}
          history={this.props.history}
          handleClick={this.onTargetDateDialogOpen}
        />
        <InvoiceTargetDateDialog
          title={this.state.selectedDateTitle}
          isDialogOpen={this.state.isOpenSelectDateModal}
          isError
          onViewPreview={this.onViewPreview}
          onCancel={this.onTargetDateDialogCancel}
        />
        <InvoiceExcludedUserDialog
          open={this.props.appDownload.isOpenUserModal}
          onClose={this.onUserModalClose}
          onSubmit={this.onUserModalSubmitClose}
          labelId="excluded-dialog-title"
          users={this.targetMonthUser()}
          facilityNames={this.targetMonthFacilityName()}
          describeId="excluded-dialog-description"
          excludedUserIds={this.props.appDownload.tmpExcludedUserIds}
          onChangeExcludedUser={this.onChangeExcludedUser}
          onChangeAllExcludedUser={this.onChangeAllExcludedUser}
          onChangeAllExcludedUserMultiple={this.onChangeAllExcludedUserMultiple}
          isMultipleFacility={this.props.user.isMultipleFacility}
          isMasterSubordinate={this.props.user.isMasterSubordinate}
          facilityType={this.props.user.facility_type}
        />
        <UplimitCreateTypeSelectDialog
          open={this.props.appDownload.isOpenUplimitCreateTypeSelectModal}
          onClose={this.onCloseUplimitCreateTypeSelectModal}
          onSubmit={(): void => this.onSubmitUplimitDownload()}
          labelId="uplimit-type-select-dialog-title"
          users={this.targetMonthUser()}
          facilityNames={this.targetMonthFacilityName()}
          describeId="uplimit-create-type-select-dialog-description"
          isMultipleFacility={this.props.user.isMultipleFacility}
          isMasterSubordinate={this.props.user.isMasterSubordinate}
          facilityType={this.props.user.facility_type}
          excludedUserIds={this.props.appDownload.tmpExcludedUserIds}
          userIdToUplimitCreateType={
            this.props.appDownload.userIdToUplimitCreateType
          }
          onChangeUplimitCreateType={this.onChangeUplimitCreateType}
        />
        <ErrorsDialog
          errorsKey="invoice"
          actionButton={(():
            | {
                text: string;
                clickHandler: () => void;
              }
            | undefined => {
            if (this.state.actionButtonType === "csv") {
              return {
                text: "そのままダウンロード",
                clickHandler: this.handleClickErrorsDialog
              };
            }
            if (this.state.actionButtonType === "print") {
              return {
                text: "そのまま表示",
                clickHandler: this.handleClickErrorsDialog
              };
            }
            return undefined;
          })()}
        />
      </AdminTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    invoiceDispatch,
    errorsDispatcher,
    appDownloadDispatch,
    SEIKATSUKAIGO,
    JIRITSUKUNRENSEIKATSU,
    IAB,
    TANKINYUSHO,
    SHISETSUNYUSHO,
    KEIKAKUSODAN
  } = dispatches;

  const invoiceDispatched = invoiceDispatch(dispatch);
  const appDownloadDispatched = appDownloadDispatch(dispatch);

  return {
    downloadable: invoiceDispatched.downloadable,
    getMultiFunctionalFacility: invoiceDispatched.getMultiFunctionalFacility,
    fetchHistory: invoiceDispatched.history,
    downloadJissekiCsv: invoiceDispatched.downloadJissekiCsv,
    downloadSeikyuCsv: invoiceDispatched.downloadSeikyuCsv,
    downloadUplimitCsv: invoiceDispatched.downloadUplimitCsv,
    errorInvoice: errorsDispatcher(dispatch).invoice,
    appDownloadDispatchTargetYearMonth: appDownloadDispatched.targetYearMonth,
    appDownloadIsDisableDownloadButton:
      appDownloadDispatched.isDisableDownloadButton,
    appDownloadIsDisableExcludedUsersButton:
      appDownloadDispatched.isDisableExcludedUsersButton,
    appDownloadReadyFileType: appDownloadDispatched.downloadReadyFileType,
    appDownloadReadyTypeConsultation:
      appDownloadDispatched.downloadReadyTypeConsultation,
    appDownloadIsOpenUserModal: appDownloadDispatched.isOpenUserModal,
    // 対象外ユーザー選択
    appDownloadExcludedUserIds: appDownloadDispatched.excludedUserIds,
    appDownloadTmpExcludedUserIds: appDownloadDispatched.tmpExcludedUserIds,
    appDownloadClearExcludedUserIds: appDownloadDispatched.clearExcludedUserIds,
    appDownloadIsOpenUplimitCreateTypeSelectModal:
      appDownloadDispatched.isOpenUplimitCreateTypeSelectModal,
    appDownloadUpdateUserIdToUplimitType:
      appDownloadDispatched.updateUserIdToUplimitCreateType,
    openErrorsDialog: (): { readonly type: "UI/SHOW_ERRORS_DIALOG" } =>
      dispatch(errorsDialogActions.showErrorsDialog()),
    closeErrorsDialog: (): { readonly type: "UI/HIDE_ERRORS_DIALOG" } =>
      dispatch(errorsDialogActions.hideErrorsDialog()),
    fetchSEIKATSUKAGIOInitial: SEIKATSUKAIGO.initialDataDispatcher(dispatch)
      .fetch,
    fetchJIRITSUKUNRENInitial: JIRITSUKUNRENSEIKATSU.initialDataDispatcher(
      dispatch
    ).fetch,
    fetchIABInitial: IAB.initialDataDispatcher(dispatch).fetch,
    fetchTANKINYUSHOInital: TANKINYUSHO.initialDataDispatcher(dispatch).fetch,
    fetchSHISETSUNYUSHOInitial: SHISETSUNYUSHO.initialDataDispatcher(dispatch)
      .fetch,
    fetchKEIKAKUSODANFacility: KEIKAKUSODAN.facilityDispatcher(dispatch).fetch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Download);
