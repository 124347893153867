import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import * as H from "history";
import { SupportRecordUserState } from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/types";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { RecordUserDetailState } from "@stores/pages/record/userDetail/types";

// ui
import RecordHeader from "@components/organisms/mgr/SHISETSUNYUSHO/record/RecordHeader";
import { UserDetailRecordSupportTable } from "@components/organisms/mgr/SHISETSUNYUSHO/record/UserDetailRecordSupportTable";
import Paper from "@material-ui/core/Paper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";

import NoRecord from "@components/organisms/mgr/common/record/NoRecord";

// utils
import { parseDateString, dateToLocalisedString } from "@utils/date";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "16px 32px 0px"
    },
    recordSummary: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginBottom: 32
    },
    usageSituation: {
      display: "flex",
      "& > div": {
        marginRight: 8
      }
    },
    summaryWrap: {
      borderRadius: 4,
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "8px",
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    CreateAndUpdateDateWrap: {
      marginTop: 20,
      marginBottom: 20
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  year: string;
  month: string;
  currentDate: Date;
  supportsRecord: SupportRecordUserState;
  staff: StaffState;
  staffOptions: FieldItem[];
  recordUserDetail: RecordUserDetailState;
  history: H.History;
  user: UsersInFacilityState["user"];
  customRecords: CustomRecordsState;
};
type StateProps = {
  customRecords: CustomRecordsState;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

/**
 * 利用者ごとの支援記録の管理を扱う
 */
const Detail = (props: Props): JSX.Element => {
  // state
  const [supportLastDay, setSupportLastDay] = React.useState<number>(0);

  const dateLastDay = dateToLocalisedString(
    props.supportsRecord.date_end_in_service,
    "YYYY年M月"
  );

  const noRecordMessage = supportLastDay
    ? ""
    : `${dateLastDay}にサービスの提供を終了しています。`;

  const { status_type, meal } = props.supportsRecord.counts;

  // 支援記録表示最終日
  const createSupportLastDay = (): number => {
    let supportRecordLastDay;
    // 月初日
    const FirstDayOfMonth = new Date(
      Number(props.year),
      Number(props.month) - 1,
      1
    );
    // 月末日
    const LastDayOfMonth = new Date(Number(props.year), Number(props.month), 0);
    // 退去日
    let dateEndInService = parseDateString(
      props.supportsRecord.date_end_in_service
    );
    dateEndInService = dateEndInService
      ? new Date(dateEndInService.setDate(dateEndInService.getDate() + 29))
      : dateEndInService;
    // 退去日がnullかどうか
    // 退去日が存在する場合は退去日が月初日以前か
    // 上記の条件で条件分けし、退去日・選択日・月末日で一番早い日を設定する
    if (!props.supportsRecord.date_end_in_service) {
      supportRecordLastDay =
        LastDayOfMonth > props.currentDate ? props.currentDate : LastDayOfMonth;
      // 退去日が月初日以前
    } else if (FirstDayOfMonth > dateEndInService) {
      supportRecordLastDay = null;
    } else {
      const lastDay = Math.min(
        LastDayOfMonth.getTime(),
        props.currentDate.getTime(),
        dateEndInService.getTime()
      );
      supportRecordLastDay = new Date(lastDay);
    }
    return supportRecordLastDay ? supportRecordLastDay.getDate() : 0;
  };

  React.useEffect(() => {
    setSupportLastDay(createSupportLastDay());
  }, [props.year, props.month, props.supportsRecord.date_end_in_service]);

  const isExistMealItem = meal.choices.some((choice) => choice.count !== 0);
  // 「食事」項目
  const mealData =
    props.customRecords.length > 0 &&
    props.customRecords.find((record) => {
      return record.setting_type === 3 && record.default_item === 3;
    });

  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);
    return [...orderArray, ...recorderItem];
  };

  const newCustomRecords =
    props.customRecords.length > 0 && sortCustomRecords(props.customRecords);

  return (
    <>
      <RecordHeader
        pageName={props.pageName}
        userName={props.userName}
        uifId={props.uifId}
        recordType="support"
        isEditing={props.recordUserDetail.isEditing}
        history={props.history}
        showTitle={!!supportLastDay}
      />
      {supportLastDay ? (
        <Paper className={props.classes.root} elevation={0}>
          <div>
            <div className={props.classes.summaryWrap}>
              <div
                className={props.classes.summaryContent}
              >{`宿泊：${status_type.stay} 日`}</div>
              <div
                className={props.classes.summaryContent}
              >{`入院：${status_type.hospitalization} 日`}</div>
              <div
                className={props.classes.summaryContent}
              >{`外泊：${status_type.stay_away} 日`}</div>
              <div
                className={props.classes.summaryContent}
              >{`/ ${status_type.denominator} 日`}</div>
            </div>

            {mealData && (mealData.visibility === 1 || isExistMealItem) ? (
              <div className={props.classes.summaryWrap}>
                {mealData.choices &&
                  mealData.choices.map((item) => {
                    const choiceInputData =
                      meal.choices.length > 0 &&
                      meal.choices.find(
                        (choiceData) => choiceData.id === item.id
                      );
                    if (
                      !choiceInputData ||
                      (mealData.visibility === 0 &&
                        choiceInputData.count === 0) ||
                      (item.hidden === 1 && choiceInputData.count === 0)
                    ) {
                      return null;
                    }
                    return (
                      <div
                        className={props.classes.summaryContent}
                        key={choiceInputData.id}
                      >{`${item.name}：${choiceInputData.count} 回`}</div>
                    );
                  })}
              </div>
            ) : null}
          </div>
          <div className={props.classes.CreateAndUpdateDateWrap}>
            <CreateAndUpdateDate
              createdAt={props.supportsRecord.created_at}
              updatedAt={props.supportsRecord.updated_at}
            />
          </div>
          <UserDetailRecordSupportTable
            supportsRecord={props.supportsRecord}
            staffOptions={props.staffOptions}
            isEditing={props.recordUserDetail.isEditing}
            targetDate={props.recordUserDetail.targetDate}
            customRecords={props.customRecords}
            currentDate={props.currentDate}
            year={props.year}
            month={props.month}
            supportLastDay={supportLastDay || 0}
            newCustomRecords={newCustomRecords || null}
            recordUserDetail={props.recordUserDetail}
            uifId={props.uifId}
            staff={props.staff}
          />
        </Paper>
      ) : (
        <NoRecord message={noRecordMessage} />
      )}
    </>
  );
};

export const UserDetailRecord = withStyles(styles)(Detail);
