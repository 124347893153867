import castNumber from "@utils/dataNormalizer/castNumber";

/**
 * 所要時間の計算
 * ※返却は分単位
 * 時間が逆転していたらnullを返す
 */
export const calculateForRequiredTimeNoReverse = (
  inTimeData: string | null,
  OutTimeData: string | null
): number | null => {
  if (inTimeData === null || OutTimeData === null) {
    return 0;
  }

  const inTimes = inTimeData.split(":");
  const outTimes = OutTimeData.split(":");
  if (inTimes.length !== 2 || outTimes.length !== 2) {
    return 0;
  }

  const inTimeHour = castNumber(inTimes[0]) * 60;
  const outTimeHour = castNumber(outTimes[0]) * 60;
  const inTimeMinutes = castNumber(inTimes[1]);
  const outTimeMinutes = castNumber(outTimes[1]);

  if (inTimeHour > outTimeHour) {
    return null;
  }

  return outTimeHour + outTimeMinutes - (inTimeHour + inTimeMinutes);
};
