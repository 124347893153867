/**
 * ログイン管理画面のヘッダー
 */
import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import { Dispatch as reactDispatch, SetStateAction } from "react";
import KnowbeMgrLogo from "@components/atoms/KnowbeMgrLogo";
import * as classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    menuButton: {
      display: "flex",
      margin: 0,
      padding: 0,
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0)"
      }
    },
    menuBg: {
      width: 64,
      height: 64,
      left: 16
    },
    appBar: {
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)"
    },
    toolBar: {
      paddingLeft: 16,
      paddingRight: 16,
      display: "flex",
      justifyContent: "space-between"
    },
    menu: {
      display: "flex"
    },
    text: {
      fontSize: 14,
      marginLeft: 8,
      lineHeight: "64px"
    },
    menuIcon: {
      fontSize: 24
    }
  });

type OwnProps = {
  move?: string | undefined;
  toggleDrawer: reactDispatch<SetStateAction<boolean>>;
};
type Props = OwnProps & WithStyles<typeof styles> & RouteComponentProps;

const HeaderCore = (props: Props): JSX.Element => {
  const { classes, move } = props;
  const onClick = (): void => {
    props.toggleDrawer(true);
  };

  return (
    <>
      <AppBar position="fixed" className={classNames(classes.appBar, move)}>
        <Toolbar disableGutters className={classes.toolBar}>
          <KnowbeMgrLogo />
          <div className={classes.menu}>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={onClick}
              TouchRippleProps={{
                classes: { root: classes.menuBg }
              }}
            >
              <MenuIcon className={classes.menuIcon} />
              <div className={classes.text}>メニュー</div>
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export const Header = withStyles(styles)(withRouter(HeaderCore));
