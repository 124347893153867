import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

/**
 * 実績削除APIのリクエストパラメータ
 */
export type RequestParamToDeleteAPI = {
  data: {
    target: Record[];
  };
};

type Record = {
  uif_id: number;
  yyyymmdd: string;
  inout_results_id: number | null;
};

/**
 * 利用実績の削除
 * @param data リクエスト情報
 */
export const deleteInOutResults = async (
  data: RequestParamToDeleteAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/inout_results/`;
  return request.deleteAll(url, data);
};
