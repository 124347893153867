import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/v202104/inOutResults/postInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopy";
import { RequestParamToPostCopyCarePlanAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopyCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/v202104/inOutResults/deleteInOutResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchDOKOENGODailyStarted = () =>
  ({ type: types.FETCH_DOKOENGO_DAILY_STARTED } as const);
export const fetchDOKOENGODaily = (res: types.ReportState["reportDaily"]) =>
  ({ type: types.FETCH_DOKOENGO_DAILY, payload: res } as const);
export const fetchDOKOENGODailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_DOKOENGO_DAILY_FAILED, error: err } as const);
export const fetchDOKOENGOUserStarted = () =>
  ({ type: types.FETCH_DOKOENGO_MONTHLY_STARTED } as const);
export const fetchDOKOENGOUser = (res: types.ReportState["reportMonthly"]) =>
  ({ type: types.FETCH_DOKOENGO_MONTHLY, payload: res } as const);
export const fetchDOKOENGOUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_DOKOENGO_MONTHLY_FAILED, error: err } as const);

export const postDOKOENGOReportStarted = () =>
  ({ type: types.POST_DOKOENGO_REPORT_STARTED } as const);
export const postDOKOENGOReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_DOKOENGO_REPORT_DAILY, payload: req } as const);
export const postDOKOENGOReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_DOKOENGO_REPORT_MONTHLY, payload: req } as const);
export const postDOKOENGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_DOKOENGO_REPORT_FAILED, error: err } as const);

export const postDOKOENGOReportCopyStarted = () =>
  ({ type: types.POST_DOKOENGO_REPORT_COPY_STARTED } as const);
export const postDOKOENGOReportCopy = (req: RequestParamToPostCopyAPI) =>
  ({ type: types.POST_DOKOENGO_REPORT_COPY, payload: req } as const);
export const postDOKOENGOReportCopyFailed = (err: ErrorResponse) =>
  ({ type: types.POST_DOKOENGO_REPORT_COPY_FAILED, error: err } as const);

export const postDOKOENGOReportCopyCarePlanStarted = () =>
  ({ type: types.POST_DOKOENGO_REPORT_COPY_CARE_PLAN_STARTED } as const);
export const postDOKOENGOReportCopyCarePlan = (
  req: RequestParamToPostCopyCarePlanAPI
) =>
  ({
    type: types.POST_DOKOENGO_REPORT_COPY_CARE_PLAN,
    payload: req
  } as const);
export const postDOKOENGOReportCopyCarePlanFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_DOKOENGO_REPORT_COPY_CARE_PLAN_FAILED,
    error: err
  } as const);

export const deleteDOKOENGOReportStarted = () =>
  ({ type: types.DELETE_DOKOENGO_REPORT_STARTED } as const);
export const deleteDOKOENGOReport = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_DOKOENGO_REPORT, payload: req } as const);
export const deleteDOKOENGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_DOKOENGO_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchDOKOENGODailyStarted>
  | ReturnType<typeof fetchDOKOENGODaily>
  | ReturnType<typeof fetchDOKOENGODailyFailed>
  | ReturnType<typeof fetchDOKOENGOUserStarted>
  | ReturnType<typeof fetchDOKOENGOUser>
  | ReturnType<typeof fetchDOKOENGOUserFailed>
  | ReturnType<typeof postDOKOENGOReportStarted>
  | ReturnType<typeof postDOKOENGOReportDaily>
  | ReturnType<typeof postDOKOENGOReportUser>
  | ReturnType<typeof postDOKOENGOReportFailed>
  | ReturnType<typeof postDOKOENGOReportCopyStarted>
  | ReturnType<typeof postDOKOENGOReportCopy>
  | ReturnType<typeof postDOKOENGOReportCopyFailed>
  | ReturnType<typeof postDOKOENGOReportCopyCarePlanStarted>
  | ReturnType<typeof postDOKOENGOReportCopyCarePlan>
  | ReturnType<typeof postDOKOENGOReportCopyCarePlanFailed>
  | ReturnType<typeof deleteDOKOENGOReportStarted>
  | ReturnType<typeof deleteDOKOENGOReport>
  | ReturnType<typeof deleteDOKOENGOReportFailed>;
