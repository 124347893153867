/**
 * ActionNames
 */
// 取得
export const FETCH_CONSULTATION_STARTED =
  "CONSULTATION/FETCH_CONSULTATION_STARTED";
export const FETCH_CONSULTATION_SUCCESS =
  "CONSULTATION/FETCH_CONSULTATION_SUCCESS";
export const FETCH_CONSULTATION_FAILED =
  "CONSULTATION/FETCH_CONSULTATION_FAILED";
// 作成済み計画読み込み
export const COPY_CONSULTATION_STARTED =
  "CONSULTATION/COPY_CONSULTATION_STARTED";
export const COPY_CONSULTATION_SUCCESS =
  "CONSULTATION/COPY_CONSULTATION_SUCCESS";
export const COPY_CONSULTATION_FAILED = "CONSULTATION/COPY_CONSULTATION_FAILED";
// 作成済み計画初期化
export const RESET_COPY_CONSULTATION = "CONSULTATION/RESET_COPY_CONSULTATION";
// 更新
export const POST_CONSULTATION_STARTED =
  "CONSULTATION/POST_CONSULTATION_STARTED";
export const POST_CONSULTATION_SUCCESS =
  "CONSULTATION/UPDATE_CONSULTATION_SUCCESS";
export const POST_CONSULTATION_FAILED = "CONSULTATION/POST_CONSULTATION_FAILED";
// 削除
export const DELETE_CONSULTATION_STARTED =
  "CONSULTATION/DELETE_CONSULTATION_STARTED";
export const DELETE_CONSULTATION_SUCCESS =
  "CONSULTATION/DELETE_CONSULTATION_SUCCESS";
export const DELETE_CONSULTATION_FAILED =
  "CONSULTATION/DELETE_CONSULTATION_FAILED";

export type ConsultationRecordState = {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetFlg: number;
  creationDate: string;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  attainmentGoal: string;
  longTermGoal: string;
  shortTermGoal: string;
  startYm: string;
  endYm: string;
  supportPlanConsultationDetails: {
    support_plan_consultation_details_id: number | null;
    support_goal: string;
    collaborator: string;
    contents_cooperation: string;
    considerations: string;
    start_ym: string;
    end_ym: string;
    users_do: string;
  }[];
  staffComment: string;
};

export type ConsultationState = {
  consultationRecord: ConsultationRecordState;
  consultationCopy: ConsultationRecordState | null;
};
