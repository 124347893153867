import { format } from "date-fns";
import { selectDateValueToDate } from "@utils/date";
import { PostSupportPlanUifIdSupportPlanSheets } from "@api/requests/supportPlanSheets/postSupportPlanUifIdSupportPlanSheets";
import {
  SupportPlanSheetDetails,
  SupportPlanSheets
} from "@interfaces/record/supportPlanSheets/supportPlanSheets";

/**
 * フォームの結果をAPIに送れる形式に整形
 * @param values
 */
export const normalizeFormValuesToPostSupportPlanUifIdSupportPlanSheets = (
  values: SupportPlanSheets
): PostSupportPlanUifIdSupportPlanSheets => {
  const target: PostSupportPlanUifIdSupportPlanSheets = {
    support_plan_sheet_id: values.support_plan_sheet_id,
    creation_date: format(
      selectDateValueToDate(values.creation_date),
      "YYYY-MM-DD"
    ),
    author: Number(values.author) || null,
    intake: values.intake,
    assessment_biological: values.assessment_biological,
    assessment_psychological: values.assessment_psychological,
    assessment_social: values.assessment_social,
    support_plan_sheet_details: values.support_plan_sheet_details.map(
      (i: SupportPlanSheetDetails) => {
        return {
          support_plan_sheet_detail_id: i.support_plan_sheet_detail_id,
          support_issue: i.support_issue,
          response_policy: i.response_policy,
          is_delete: i.is_delete ? 1 : 0
        };
      }
    )
  };

  return target;
};
