import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
// ui
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
// formik
import { FormikProps } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import { DAY_SELECT_OPTIONS } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      justifyContent: "space-between"
    },
    longLabel: {
      color: "#37474f!important",
      width: "120%"
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  practitionerNum: number;
  handleChangeDateSelect: (
    event: React.ChangeEvent<HTMLSelectElement>,
    isAccompanyField?: boolean
  ) => void;
  handleChangeInitialTime: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    beforeValue: string,
    autoCorrectValue: string,
    isAccompanyField?: boolean
  ) => void;
  isEdit: boolean;
} & WithStyles<typeof styles>;

export const ServiceDeliveryAccompanySupportFieldCore = (
  props: Props
): JSX.Element => {
  const {
    classes,
    practitionerNum,
    practitionerValues,
    handleChangeDateSelect,
    handleChangeInitialTime,
    isEdit
  } = props;
  const fieldName = `serviceDeliveryRecordPractitioners${practitionerNum}`;
  return (
    <>
      <div className={classes.flex}>
        <FormikSelect
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.accompanySupportInTimeDate`}
          label="同行支援開始時間"
          options={DAY_SELECT_OPTIONS}
          size="mobileHalf"
          noerrortext
          onChangeHook={(e): void => handleChangeDateSelect(e, true)}
        />

        <FormikTime
          name={`${fieldName}.accompanySupportInTime`}
          label=""
          placeholder="00:00"
          maxLength={5}
          onChangeHookTime={(e, before, auto): void =>
            handleChangeInitialTime(e, before, auto, true)
          }
          size="mobileHalf"
          isMobile
          readOnly={!isEdit}
        />
      </div>
      <div className={classes.flex}>
        <FormikSelect
          isMobile
          readOnly={!isEdit}
          name={`${fieldName}.accompanySupportOutTimeDate`}
          label="同行支援終了時間"
          options={DAY_SELECT_OPTIONS}
          size="mobileHalf"
          noerrortext
          onChangeHook={(e): void => handleChangeDateSelect(e, true)}
        />
        <FormikTime
          name={`${fieldName}.accompanySupportOutTime`}
          placeholder="00:00"
          maxLength={5}
          onChangeHookTime={(e, before, auto): void =>
            handleChangeInitialTime(e, before, auto, true)
          }
          size="mobileHalf"
          isMobile
          readOnly={!isEdit}
        />
      </div>

      <ReadonlyTextField
        value={practitionerValues.calculatedAccompanySupportHours || "-"}
        label="同行支援の時間数"
        defaultValue=""
        endAdornment="時間"
        size="mobileHalf"
        style={{
          marginBottom: 0
        }}
      />

      <ReadonlyTextField
        InputLabelStyle={classes.longLabel}
        value={practitionerValues.calculatedAccompanySupportMovingHours || "-"}
        label="同行支援の移動時間数"
        defaultValue=""
        endAdornment="時間"
        size="mobileHalf"
        style={{ marginTop: 24 }}
      />
    </>
  );
};

export const ServiceDeliveryAccompanySupportField = withStyles(styles)(
  ServiceDeliveryAccompanySupportFieldCore
);
