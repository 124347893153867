import * as React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/JIRITSUKUNRENSEIKATSU/userInFacility/types";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { FacilityType } from "@constants/variables";
import { INVOICE_PATHS } from "@constants/mgr/JIRITSUKUNRENSEIKATSU/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    floatLeft: {
      float: "left"
    },
    floatRight: {
      float: "right"
    },
    dateButtonsContainer: {
      paddingTop: spacing.unit
    },
    usersDropDown: {
      marginTop: 0,
      marginLeft: spacing.unit * 2
    },
    buttonWidth: {
      width: 120
    },
    buttonContainer: {
      paddingRight: 0,
      paddingLeft: spacing.unit / 2
    },
    headerInfoContainer: {
      minHeight: 56,
      marginTop: 16,
      marginLeft: 16,
      width: "100%"
    }
  });

interface StateProps {
  userInFacility: UsersInFacilityState;
}

interface DispatchProps {
  fetchFacilityUserList: (date: Date) => Promise<void>;
  fetchOneUser: (id: string) => Promise<void>;
}

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedMonth: Date;
  selectedUserId: string | number;
  history: H.History;
  currentPageVersion: number;
  onChangeMonth: (date: Date, user: OptionInterface) => void;
  onChangeUser: (user: OptionInterface) => void;
};

type MergeProps = OwnProps &
  DispatchProps & {
    facilityListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）- 月変更 & 利用者変更（通常時）
 */
class InOutReportUserHeader extends React.Component<Props> {
  public async componentDidMount(): Promise<void> {
    if (!this.props.selectedUserId) {
      await this.props.fetchFacilityUserList(this.props.selectedMonth);
      if (this.props.facilityListOption && this.props.facilityListOption[0]) {
        this.props
          .fetchOneUser(`${this.props.facilityListOption[0].value}`)
          .then(() => {
            this.props.onChangeUser(this.props.facilityListOption[0]);
          });
      }
    }
  }

  /**
   * 月変更
   */
  private onChangeMonth = (date: Date): void => {
    // 報酬改定のバージョン変更が必要だったら変更後のページを表示
    const transitionDone = checkReportVersion(
      date,
      FacilityType.JIRITSUKUNRENSEIKATSU,
      this.props.currentPageVersion,
      INVOICE_PATHS.reportUsers,
      this.props.history
    );

    if (!transitionDone) {
      this.props.fetchFacilityUserList(date).then(() => {
        const selectedUserOption = this.props.facilityListOption.filter(
          (userOption) => userOption.value === this.props.selectedUserId
        );
        let selectedUser;
        if (selectedUserOption.length > 0) {
          [selectedUser] = selectedUserOption;
        } else if (this.props.facilityListOption.length > 0) {
          [selectedUser] = this.props.facilityListOption;
        } else {
          selectedUser = { label: "", value: 0 };
        }
        this.props.onChangeMonth(date, selectedUser);
      });
    }
  };

  /**
   * 利用者の変更
   */
  private onChangeUser = async (
    event: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const selectedUserOption = this.props.facilityListOption.filter(
      (userOption) => userOption.value === event.target.value
    );
    if (selectedUserOption.length > 0) {
      this.props.fetchOneUser(`${selectedUserOption[0].value}`);
      this.props.onChangeUser(selectedUserOption[0]);
    }
  };

  public render(): JSX.Element {
    const {
      classes,
      minDate,
      maxDate,
      selectedMonth,
      selectedUserId,
      facilityListOption
    } = this.props;
    return (
      <div className={classes.headerInfoContainer}>
        <div
          className={ClassNames(
            classes.floatLeft,
            classes.dateButtonsContainer
          )}
        >
          <DateSelectButtonsMonthly
            selectedMonth={selectedMonth}
            min={minDate}
            max={maxDate}
            onClickSubmit={this.onChangeMonth}
          />
        </div>
        <div className={classes.floatLeft}>
          <DropDown
            id="users-in-facility-list"
            label="利用者"
            isError={false}
            options={facilityListOption}
            value={`${selectedUserId}`}
            styles={classes.usersDropDown}
            onChange={this.onChangeUser}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userInFacility: state.v202104.JIRITSUKUNRENSEIKATSU.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const userInFacilityDispatcher = v202104.JIRITSUKUNRENSEIKATSU.userInFacilityDispatcher(
    dispatch
  );
  return {
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date),
    fetchOneUser: v202104.JIRITSUKUNRENSEIKATSU.userInFacilityDispatcher(
      dispatch
    ).fetchOne
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const facilityListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    facilityListOption,
    ...ownProps,
    ...dispatchProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(InOutReportUserHeader));
