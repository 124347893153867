import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetFacilityMunicipalitiesResponse = {
  data: {
    municipalities: {
      id: number;
      name: string;
    }[];
  };
};

/**
 * 自治体情報を取得する。
 */
export const getFacilityMunicipalities = async (): Promise<
  AxiosResponse<GetFacilityMunicipalitiesResponse>
> => {
  const url = `${VERSION_URL_201910}/facility/municipalities`;
  return request.get<GetFacilityMunicipalitiesResponse>(url);
};

export default getFacilityMunicipalities;
