import * as React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import ContentHeaderSubmit from "@components/molecules/ContentHeaderSubmit";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/GroupHome/userInFacility/types";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { FacilityType } from "@constants/variables";
import { INVOICE_PATHS } from "@constants/mgr/GroupHome/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    floatLeft: {
      float: "left"
    },
    floatRight: {
      float: "right"
    },
    dateButtonsContainer: {
      paddingTop: spacing.unit
    },
    usersDropDown: {
      marginTop: "0px !important",
      marginLeft: spacing.unit * 2
    },
    buttonWidth: {
      width: 120
    },
    buttonContainer: {
      paddingRight: 0,
      paddingLeft: spacing.unit / 2
    }
  });

type StateProps = {
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchFacilityUserList: (date: Date) => void;
};

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedMonth: Date;
  isSubmitDisabled: boolean;
  selectedUserId: string | number;
  history: H.History;
  currentPageVersion: number;
  onChangeMonth: (date: Date) => void;
  onChangeUser: (user: OptionInterface) => void;
  onChangeEditMode: () => void;
  setIsFacilityListOption: (isFacilityListOption: boolean) => void;
};

type MergeProps = OwnProps &
  DispatchProps & {
    facilityListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）- 月変更 & 利用者変更（通常時）
 */
class UsagePerformanceMonthlyHeader extends React.Component<Props> {
  public async componentDidMount(): Promise<void> {
    if (!this.props.selectedUserId) {
      await this.props.fetchFacilityUserList(this.props.selectedMonth);
      if (this.props.facilityListOption && this.props.facilityListOption[0]) {
        this.props.onChangeUser(this.props.facilityListOption[0]);
      }
    }
  }

  public componentDidUpdate(): void {
    if (
      this.props.facilityListOption &&
      this.props.facilityListOption[0] &&
      this.props.selectedUserId &&
      !this.props.facilityListOption.some(
        (target) => target.value === this.props.selectedUserId
      )
    ) {
      this.props.onChangeUser(this.props.facilityListOption[0]);
    }
  }

  /**
   * 月変更
   */
  private onChangeMonth = (date: Date): void => {
    // 報酬改定のバージョン変更が必要だったら変更後のページを表示
    const transitionDone = checkReportVersion(
      date,
      FacilityType.GROUP_HOME,
      this.props.currentPageVersion,
      INVOICE_PATHS.reportMonthly,
      this.props.history
    );

    if (!transitionDone) {
      this.props.fetchFacilityUserList(date);
      this.props.onChangeMonth(date);
    }
  };

  /**
   * 利用者の変更
   */
  private onChangeUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const facility = this.props.facilityListOption.filter(
      (x) => x.value === event.target.value
    );
    if (facility.length > 0) {
      this.props.onChangeUser(facility[0]);
    }
  };

  public render(): JSX.Element {
    const {
      classes,
      minDate,
      maxDate,
      selectedMonth,
      selectedUserId,
      facilityListOption,
      onChangeEditMode,
      setIsFacilityListOption
    } = this.props;
    setIsFacilityListOption(facilityListOption.length !== 0);
    return (
      <>
        <div
          className={ClassNames(
            classes.floatLeft,
            classes.dateButtonsContainer
          )}
        >
          <DateSelectButtonsMonthly
            selectedMonth={selectedMonth}
            min={minDate}
            max={maxDate}
            onClickSubmit={this.onChangeMonth}
          />
        </div>
        <div className={classes.floatLeft}>
          <DropDown
            id="users-in-facility-list"
            label="利用者"
            isError={false}
            options={facilityListOption}
            value={`${selectedUserId}`}
            styles={classes.usersDropDown}
            onChange={this.onChangeUser}
          />
        </div>
        <div className={classes.floatRight}>
          <ContentHeaderSubmit
            buttonName="編集"
            handleSubmit={onChangeEditMode}
            submitStyle={classes.buttonWidth}
            toolbarStyle={classes.buttonContainer}
            isSubmitDisabled={this.props.isSubmitDisabled}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userInFacility: state.GroupHome.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { GroupHome } = dispatches;
  const userInFacilityDispatcher = GroupHome.userInFacilityDispatcher(dispatch);
  return {
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const facilityListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    facilityListOption,
    ...ownProps,
    ...dispatchProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(UsagePerformanceMonthlyHeader));
