export const ERROR_MESSAGE =
  "パスワードはメールアドレス、管理画面のアカウント名、モバイル画面のアカウント名とは異なるものを設定してください";

/**
 * パスワード重複チェック（メールアドレスと管理画面のアカウント名は同一）
 * @param value
 * @param adminId
 * @param mobileId
 */
export const passwordDuplication = (
  value: string,
  adminId: string,
  mobileId: string
): string | undefined => {
  if (value !== "" && (value === adminId || value === mobileId)) {
    return ERROR_MESSAGE;
  }
  return undefined;
};
