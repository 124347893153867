import React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import {
  REGIONAL_COOPERATION_SUPPORT,
  SUPPORT_METHOD
} from "@constants/mgr/SHUROTEICHAKU/variables";
import { FormikProps } from "formik";
import { InitialDataValues } from "@interfaces/mgr/SHUROTEICHAKU/report/initialData";
import MuiSelect from "@components/molecules/MuiSelect";

const styles = (): StyleRules =>
  createStyles({
    cell: {
      boxSizing: "border-box",
      padding: "12px 8px",
      height: 56
    },
    shortCell: {
      width: 64,
      minWidth: 64,
      maxWidth: 64
    },
    regionalCooperationSupportCell: {
      width: 92,
      minWidth: 92,
      maxWidth: 92
    },
    longCell: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      "&:last-child": {
        paddingRight: 16
      }
    },
    root: {
      fontSize: 16
    },
    timeWrap: {
      display: "flex"
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialDataValues>;
  idx: number;
  isRegionalCooperationSupportFlg: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UsageResultEditCellCore = (props: Props): JSX.Element => {
  const { formikProps, idx, classes } = props;
  const fieldName = `ReportData.usageResults[${idx}]`;
  const checkBoxStyle = { position: "absolute" as const, margin: "-8px 0 0" };
  const {
    statusType,
    supportMethod,
    supportMethodOther,
    remarks
  } = formikProps.values.ReportData.usageResults[idx];

  /**
   * 支援実施方法・場所が選択された時、備考に対応するテキストを入れる
   * その他から変更された時テキストリセットも行う
   */
  const onChangeSupportMethod = (
    e: React.ChangeEvent<HTMLSelectElement>,
    beforeValue: string
  ): void => {
    if (beforeValue === e.target.value) return;

    let memo = remarks;
    // 前回値が"選択してください"でなければ備考のラベル値を除去
    if (beforeValue !== SUPPORT_METHOD[0].value) {
      const beforeSupport = SUPPORT_METHOD[+beforeValue];
      const replaceTarget =
        beforeValue === SUPPORT_METHOD[6].value
          ? supportMethodOther
          : beforeSupport.label;
      memo = memo.replace(replaceTarget, "");
    }
    // 選択値が”選択してください”と"その他"以外なら備考末尾にテキスト追加
    if (
      e.target.value !== SUPPORT_METHOD[0].value &&
      e.target.value !== SUPPORT_METHOD[6].value
    ) {
      const nextSupport = SUPPORT_METHOD[+e.target.value];
      memo += nextSupport.label;
    }
    if (memo !== remarks) {
      formikProps.setFieldValue(`${fieldName}.remarks`, memo);
    }

    // その他から他のステータスに変更された時、その他のテキストを初期化
    if (beforeValue === SUPPORT_METHOD[6].value) {
      formikProps.setFieldValue(`${fieldName}.supportMethodOther`, "");
    }
  };

  // その他のテキスト変更確定時備考に反映
  const onBlurSupportMethodOther = (
    e: React.FormEvent<HTMLInputElement>,
    beforeValue: string
  ): void => {
    const memo = `${remarks.replace(beforeValue, "")}${e.currentTarget.value}`;
    formikProps.setFieldValue(`${fieldName}.remarks`, memo);
  };

  return (
    <>
      {/* 支援実施 */}
      <TableCellWrap
        key={`${idx}-status-type`}
        cellClass={`${classes.cell} ${classes.shortCell}`}
      >
        <FormikCheckbox
          name={`${fieldName}.statusType`}
          label=""
          style={checkBoxStyle}
        />
      </TableCellWrap>

      {/* 特別地域加算 */}
      <TableCellWrap
        key={`${idx}-usage-performance`}
        cellClass={`${classes.cell} ${classes.shortCell}`}
      >
        <FormikCheckbox
          name={`${fieldName}.specialAreaFlg`}
          label=""
          focusVisibleClassName={classes.root}
          style={checkBoxStyle}
        />
      </TableCellWrap>

      {/* 地域連携会議実施加算 */}
      <TableCellWrap
        key={`${idx}-regional-cooperation-support-flg`}
        cellClass={`${classes.cell} ${classes.regionalCooperationSupportCell}`}
      >
        {props.isRegionalCooperationSupportFlg ? (
          <FormikSelect
            name={`${fieldName}.regionalCooperationSupport`}
            label=""
            style={{ margin: "-16px 0 0", width: "87px" }}
            options={REGIONAL_COOPERATION_SUPPORT}
          />
        ) : (
          <MuiSelect
            label=""
            name=""
            value="0"
            disabledStyle
            disabled
            style={{ margin: "-16px 0 0", width: "87px" }}
            options={REGIONAL_COOPERATION_SUPPORT}
          />
        )}
      </TableCellWrap>

      {/* 支援実施方法・場所 */}
      <TableCellWrap
        key={`${idx}-supportMethod`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        <FormikSelect
          name={`${fieldName}.supportMethod`}
          label=""
          options={SUPPORT_METHOD}
          size="fullSize"
          style={{ margin: "-16px 0 0" }}
          disabled={!statusType}
          onChangeHook={onChangeSupportMethod}
        />
        {supportMethod === SUPPORT_METHOD[6].value && (
          <FormikTextField
            name={`${fieldName}.supportMethodOther`}
            size="fullSize"
            placeholder="-"
            maxLength={30}
            style={{ margin: "8px 0 0" }}
            disabled={!statusType}
            onBlurHook={onBlurSupportMethodOther}
          />
        )}
      </TableCellWrap>

      {/* 支援期間 */}
      <TableCellWrap
        key={`${idx}-supportTime`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        <div className={classes.timeWrap}>
          <FormikTime
            name={`${fieldName}.supportStartTime`}
            placeholder={statusType ? "00:00" : ""}
            maxLength={5}
            style={{ margin: "0px 16px 0px 0px" }}
            disabled={!statusType}
          />
          <FormikTime
            name={`${fieldName}.supportEndTime`}
            placeholder={statusType ? "00:00" : ""}
            maxLength={5}
            style={{ margin: 0 }}
            disabled={!statusType}
          />
        </div>
      </TableCellWrap>

      {/* 備考 */}
      <TableCellWrap
        key={`${idx}-remarks`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        <FormikTextField
          name={`${fieldName}.remarks`}
          label=""
          required={false}
          placeholder="-"
          size="fullSize"
          style={{ marginBottom: 0 }}
        />
      </TableCellWrap>
    </>
  );
};

export const UsageResultEditCell = withStyles(styles)(UsageResultEditCellCore);
