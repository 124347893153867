import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportRecordUserResponse = {
  data: {
    date_end_in_service: string;
    counts: {
      status_type: {
        stay: number;
        hospitalization: number;
        stay_away: number;
        denominator: number;
      };
      meal: {
        custom_record_item_id: number;
        choices: {
          id: number;
          name: string;
          count: number;
        }[];
      };
    };
    support: {
      support_records_id: number;
      target_date: string;
      status_type: number;
      support_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 利用者の支援記録のデータを取得する
 * @param uifId 事業所所属ユーザーのID
 * @param year 年
 * @param month 月(MM)
 */
const getSupportRecordUser = async (
  uifId: string,
  year: string,
  month: string
): Promise<AxiosResponse<GetSupportRecordUserResponse>> => {
  const url = `${VERSION_URL_201910}/supports/users/${uifId}/records/${year}/${month}`;
  return request.get<GetSupportRecordUserResponse>(url);
};

export default getSupportRecordUser;
