import * as React from "react";
import LineBreak from "@components/atoms/LineBreak";
import {
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { CHIIKIIKO_DISPLAY_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";
import { CHIIKITEICHAKU_DISPLAY_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { FacilityType } from "@constants/variables";

type Props = {
  displayComment: string[];
  facilityType: FacilityType;
  status: number;
  id: number | null;
  supportContent: string | null;
  staffComment: string | null;
  typeConsultation?: number;
};

const isShogaijiUser = (typeConsultation?: number): boolean =>
  typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN;

const getStatusLabel = (
  facilityType: FacilityType,
  status: number,
  isShogaiji: boolean
): string => {
  switch (facilityType) {
    case FacilityType.KEIKAKUSODAN: {
      const list = isShogaiji ? STATUS_LIST_SHOGAIJISODAN : STATUS_LIST;
      const s = list.find((v) => v.value === `${status}`);
      return s ? s.label : "-";
    }
    case FacilityType.CHIIKIIKO: {
      const s = CHIIKIIKO_DISPLAY_STATUS_LIST.find(
        (v) => v.value === `${status}`
      );
      return s ? s.label : "-";
    }
    case FacilityType.CHIIKITEICHAKU: {
      const s = CHIIKITEICHAKU_DISPLAY_STATUS_LIST.find(
        (v) => v.value === `${status}`
      );
      return s ? s.label : "-";
    }
    default:
      return "-";
  }
};

export const SupportContentPrint = (props: Props): JSX.Element => {
  const {
    displayComment,
    id,
    status,
    supportContent,
    staffComment,
    facilityType,
    typeConsultation
  } = props;
  const supportContents: JSX.Element[] = [];

  if (status) {
    const isShogaiji = isShogaijiUser(typeConsultation);

    const statusLabel = getStatusLabel(facilityType, status, isShogaiji);
    const statusTypeContent = (
      <p key={`status_type_${id}`} style={{ margin: 0 }}>
        {`[サービス内容] ${statusLabel}`}
      </p>
    );
    supportContents.push(statusTypeContent);
  }

  if (supportContent) {
    const support = (
      <p key={`supportContent_${id}`} style={{ margin: 0 }}>
        [支援内容] <LineBreak text={supportContent} />
      </p>
    );
    supportContents.push(support);
  }

  if (displayComment.includes("staff_comment") && staffComment) {
    const staff = (
      <p key={`staffComment_${id}`} style={{ margin: 0 }}>
        [職員考察] <LineBreak text={staffComment || ""} />
      </p>
    );
    supportContents.push(staff);
  }

  return <>{supportContents}</>;
};
