import * as React from "react";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import {
  TableMultiHead,
  HeaderProps
} from "@components/molecules/TableMultiHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { InOutReportTableCells } from "@components/v202104/organisms/mgr/CHIIKIIKO/report/InOutReportTableCells";
import { AppState } from "@stores/type";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import {
  ReportState,
  InoutConsultationResultsState
} from "@stores/v202104/domain/mgr/CHIIKIIKO/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/CHIIKIIKO/userInFacility/types";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableLayout: {
      width: "100%",
      minWidth: 1030
    },
    rowUser: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    superShortIconCell: {
      minWidth: 32,
      width: 32,
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 4
      },
      paddingLeft: 4,
      paddingRight: 4,
      textAlign: "center"
    },
    superShortCell: {
      minWidth: 16,
      width: 16,
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 4
      },
      padding: 4
    },
    shortMiddleCell: {
      minWidth: 41,
      width: 41,
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 4
      },
      padding: 4
    },
    middleCell: {
      minWidth: 72,
      width: 72,
      boxSizing: "content-box",
      lineHeight: "28px",
      padding: 4,
      paddingLeft: 16
    },
    user: {
      paddingTop: 4,
      paddingBottom: 4
    },
    dataHeaderColor: {
      backgroundColor: "#9badb6"
    },
    headerFontSize: {
      fontSize: 14
    },
    date: {
      paddingLeft: 16
    }
  });

type StateProps = {
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  headerHeight: number;
  openModal: (param: InoutConsultationResultsState) => void;
  filterFlg: boolean;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const reportMonthlyHeader = (classes: Props["classes"]): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    rowStyle: [
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 32
      },
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 56
      }
    ],
    items: [
      [
        {
          align: "left",
          label: "日付",
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.date}`
        },
        {
          align: "left",
          label: "支援内容",
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "体験利用",
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "体験宿泊",
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "初回",
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              居住支援
              <br />
              連携体制
            </>
          ),
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              地域居住支援
              <br />
              体制強化推進
            </>
          ),
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              退院・
              <br />
              退所月
            </>
          ),
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "",
          className: `${classes.superShortIconCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        }
      ]
    ]
  };
};

const InOutReportTableCore = (props: Props): JSX.Element => {
  const { reportState } = props;
  const tableHead = reportMonthlyHeader(props.classes);
  const rows = reportState.reportMonthly.inoutConsultationResults;

  const TableList = rows
    .filter((row) => {
      return !props.filterFlg || !(row.id === null);
    })
    .map((row, index) => {
      return (
        <TableRow
          key={`table-row-${row.usersInFacilityId}-${row.targetDate}`}
          className={props.classes.rowUser}
        >
          <>
            <TableCellWrap
              key={`${row.usersInFacilityId}-${row.targetDate}-target-date`}
              cellClass={props.classes.middleCell}
              rowSpan={1}
            >
              <CustomDateLabel
                date={row.targetDate ? row.targetDate : ""}
                dateFormat="Do（dd）"
                holiday={row.isHoliday}
              />
            </TableCellWrap>
            <InOutReportTableCells
              params={row}
              idx={`${index}`}
              openModal={props.openModal}
              classOption={props.classes.user}
              usersInFacilityState={props.usersInFacilityState}
            />
          </>
        </TableRow>
      );
    });

  const topHeight = NOTICE_HEADER_HEIGHT + props.headerHeight;

  return (
    <>
      <Table key="monthly-report-table" className={props.classes.tableLayout}>
        <TableMultiHead
          role={tableHead.role}
          ariaChecked={tableHead.ariaChecked}
          tabIndex={tableHead.tabIndex}
          key={tableHead.key}
          selected={tableHead.selected}
          items={tableHead.items}
          rowStyle={tableHead.rowStyle}
          headerStyle={{
            zIndex: 10,
            position: "sticky",
            top: `${topHeight}px`
          }}
        />
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者の情報が登録されていません。
          <br />
          利用者情報ページで利用者を登録した後にご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    reportState: state.v202104.CHIIKIIKO.report,
    usersInFacilityState: state.v202104.CHIIKIIKO.userInFacility
  };
};

export const InOutReportTable = connect(mapStateToProps)(
  withStyles(styles)(InOutReportTableCore)
);
