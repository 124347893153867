import { FacilityType } from "@constants/variables";
import { KYOTAKUKAIGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { DOKOENGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/DOKOENGO/variables";
import { KODOENGO_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/KODOENGO/variables";
import { IDOSHIEN_DISPLAY_STATUS_LIST_RECORD } from "@constants/mgr/IDOSHIEN/variables";
import { getLabelFromOptions } from "@utils/dataNormalizer";

export const getStatusLabel = (
  status: number,
  facilityType: FacilityType,
  isMobile?: boolean
): string => {
  const getList = (): { label: string; value: string }[] => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return KYOTAKUKAIGO_DISPLAY_STATUS_LIST_RECORD;
      case FacilityType.JUDOHOMONKAIGO:
        return JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD;
      case FacilityType.DOKOENGO:
        return DOKOENGO_DISPLAY_STATUS_LIST_RECORD;
      case FacilityType.KODOENGO:
        return KODOENGO_DISPLAY_STATUS_LIST_RECORD;
      case FacilityType.IDOSHIEN:
        return IDOSHIEN_DISPLAY_STATUS_LIST_RECORD;
      default:
        return JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_RECORD;
    }
  };
  let statusLabel = getLabelFromOptions(status.toString(), getList());

  // サービス内容改行用処理
  if (facilityType === FacilityType.KYOTAKUKAIGO) {
    const lineChangeIdx = statusLabel.indexOf("（", 0);
    if (lineChangeIdx !== -1) {
      statusLabel = `${statusLabel.slice(
        0,
        lineChangeIdx
      )}\n${statusLabel.slice(lineChangeIdx, statusLabel.length)}`;
    }
  }
  return isMobile && facilityType === FacilityType.KYOTAKUKAIGO
    ? statusLabel.replace("（", "(").replace("）", ")")
    : statusLabel;
};
