import { RecordSupportPlanValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportPlan/initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { validateSwitcher, Rule } from "@validator";

const validation = (
  values: RecordSupportPlanValues
): ValidationErrors<RecordSupportPlanValues> => {
  const lengthRule = (length: number): Rule => ({
    type: "checkCharacterLength",
    length
  });
  return {
    support_plan_goal: values.support_plan_goal.map((g) => ({
      satisfaction: validator(g.satisfaction, lengthRule(2500)),
      countermeasure: validator(g.countermeasure, lengthRule(2500))
    })),
    after_summary: validator(values.after_summary, lengthRule(2500)),
    evaluation_minutes: validator(values.evaluation_minutes, lengthRule(2500)),
    evaluation_authorizer: validateSwitcher(
      values.evaluation_status === "1",
      validator(values.evaluation_authorizer, "required")
    )
  };
};

export default validation;
