import { KYOTAKUKAIGO_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import { DOKOENGO_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/DOKOENGO/variables";
import { KODOENGO_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/KODOENGO/variables";
import { IDOSHIEN_DISPLAY_STATUS_LIST_DETAIL } from "@constants/mgr/IDOSHIEN/variables";

import { FacilityType } from "@constants/variables";
import { FieldItem } from "@interfaces/ui/form";

// 種別ごとのサービス内容選択肢指定
export const createStatusOptions = (
  facilityType: FacilityType
): FieldItem[] => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return KYOTAKUKAIGO_DISPLAY_STATUS_LIST_DETAIL;
    case FacilityType.JUDOHOMONKAIGO:
      return JUDOHOMONKAIGO_DISPLAY_STATUS_LIST_DETAIL;
    case FacilityType.DOKOENGO:
      return DOKOENGO_DISPLAY_STATUS_LIST_DETAIL;
    case FacilityType.KODOENGO:
      return KODOENGO_DISPLAY_STATUS_LIST_DETAIL;
    case FacilityType.IDOSHIEN:
      return IDOSHIEN_DISPLAY_STATUS_LIST_DETAIL;
    default:
      return KYOTAKUKAIGO_DISPLAY_STATUS_LIST_DETAIL;
  }
};
