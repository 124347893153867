import { KEIKAKUSODANGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";
import { KEIKAKUSODANPostConsultation as PostConsultation } from "@api/requests/consultation/postConsultation";

import * as types from "./types";
import castString from "@utils/dataNormalizer/castString";
import { ConsultationValues } from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";
import {
  selectDateValueToDatePaddingZero,
  selectDateValueToDatePaddingZeroWithoutDay
} from "@utils/date";
import { CONSULTATION_TARGET_FRG } from "@constants/mgr/KEIKAKUSODAN/variables";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";

const removeNullParam = <T extends {}>(data: T, requiredKey?: string[]): T => {
  const targetParam = {} as T;

  // 必須キーを除くundefinedをのparamを除去
  Object.keys(data).forEach((param) => {
    if (
      (requiredKey && requiredKey.includes(param)) ||
      data[param] !== undefined
    ) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

// Get用のnormalizer
export const normalizeValueFromAPI = (
  res: GetConsultationResponse["data"]
): types.ConsultationRecordState => {
  return {
    id: res.id,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    targetFlg: res.target_flg,
    creationDate: res.creation_date,
    author: res.author,
    monitoringPeriod: castString(res.monitoring_period),
    monitoringStartYm: res.monitoring_start_ym || "",
    planStartYm: res.plan_start_ym,
    classifyDisabilitySupport: res.classify_disability_support,
    incomeKind: res.income_kind,
    disabledWelfareServiceRecipientNumber:
      res.disabled_welfare_service_recipient_number || "",
    regularPlaceRecipientNumber: res.regular_place_recipient_number || "",
    consultationSupportRecipientNumber:
      res.consultation_support_recipient_number || "",
    desiredLife: res.desired_life || "",
    supportPolicy: res.support_policy || "",
    longTermGoal: res.long_term_goal || "",
    shortTermGoal: res.short_term_goal || "",
    supportPlanConsultationDetails: res.support_plan_consultation_details || [],
    supportPlanConsultationSchedule: res.support_plan_consultation_schedule,
    mainActivities: res.main_activities || "",
    nonWeeklyService: res.non_weekly_service || "",
    overallLife: res.overall_life || "",
    staffComment: res.staff_comment || "",
    type_consultation: res.type_consultation
  };
};

// Post用の計画の詳細normalizer
export const normalizeSupportPlanConsultationDetails = (
  detailValues: ConsultationValues["supportPlanConsultationDetails"],
  targetFag: number,
  initialDetailValues?: ConsultationValues["supportPlanConsultationDetails"]
): PostConsultation["support_plan_consultation_details"] => {
  return detailValues
    .filter(
      (value) =>
        !(!value.support_plan_consultation_details_id && value.is_delete)
    )
    .map((value) => {
      const initialvalue = initialDetailValues
        ? initialDetailValues.find(
            (initial) =>
              initial.support_plan_consultation_details_id ===
              value.support_plan_consultation_details_id
          )
        : undefined;

      // 存在する詳細データを編集する場合
      if (initialvalue) {
        const res = omitByNoChanges(value, initialvalue);
        const param = {
          support_plan_consultation_details_id:
            value.support_plan_consultation_details_id,
          needs: res.needs !== undefined ? value.needs : undefined,
          support_goal:
            res.support_goal !== undefined ? value.support_goal : undefined,
          achievement_time:
            res.achievement_time !== undefined
              ? value.achievement_time
              : undefined,
          welfare_service_type:
            res.welfare_service_type !== undefined
              ? value.welfare_service_type
              : undefined,
          provider_name:
            targetFag === CONSULTATION_TARGET_FRG.CONSULTATION &&
            res.provider_name !== undefined
              ? value.provider_name
              : undefined,
          role_in_problem_solving:
            res.role_in_problem_solving !== undefined
              ? value.role_in_problem_solving
              : undefined,
          evaluation_time:
            res.evaluation_time !== undefined
              ? value.evaluation_time
              : undefined,
          other_considerations:
            res.other_considerations !== undefined
              ? value.other_considerations
              : undefined,
          is_delete: res.is_delete ? value.is_delete : undefined
        };
        return removeNullParam(param);
      }

      // 新規追加
      const param = {
        support_plan_consultation_details_id: null,
        needs: value.needs,
        support_goal: value.support_goal,
        achievement_time: value.achievement_time,
        welfare_service_type: value.welfare_service_type,
        provider_name:
          targetFag === CONSULTATION_TARGET_FRG.CONSULTATION
            ? value.provider_name
            : undefined,
        role_in_problem_solving: value.role_in_problem_solving,
        evaluation_time: value.evaluation_time,
        other_considerations: value.other_considerations,
        is_delete: value.is_delete
      };
      return removeNullParam(param);
    });
};

const formatTime = (time: string | null): string => {
  return time ? `${time}:00` : "";
};

// Post用の週間計画表normalizer
export const normalizeSupportPlanConsultationSchedule = (
  scheduleValues: ConsultationValues["supportPlanConsultationSchedule"],
  initialScheduleValues?: ConsultationValues["supportPlanConsultationSchedule"]
): PostConsultation["support_plan_consultation_schedule"] => {
  return scheduleValues
    .filter(
      (value) =>
        !(!value.support_plan_consultation_schedule_id && value.is_delete)
    )
    .map((value) => {
      const initialvalue = initialScheduleValues
        ? initialScheduleValues.find(
            (initial) =>
              initial.support_plan_consultation_schedule_id ===
              value.support_plan_consultation_schedule_id
          )
        : undefined;

      // 存在する詳細データを編集する場合
      if (initialvalue) {
        const res = omitByNoChanges(value, initialvalue);

        const param = {
          support_plan_consultation_schedule_id:
            value.support_plan_consultation_schedule_id,
          day_of_week:
            res.day_of_week !== undefined ? value.day_of_week : undefined,
          start_time:
            res.start_time !== undefined
              ? formatTime(value.start_time)
              : undefined,
          next_day_flg:
            res.end_time === undefined && res.next_day_flg === undefined // end_timeが送られてくるときは必須
              ? undefined
              : Number(value.next_day_flg),
          end_time:
            res.end_time !== undefined ? formatTime(value.end_time) : undefined,
          content: res.content !== undefined ? value.content : undefined,
          is_delete: res.is_delete !== undefined ? value.is_delete : undefined
        };
        return removeNullParam(param);
      }

      // 新規追加
      return {
        support_plan_consultation_schedule_id: null,
        day_of_week: value.day_of_week,
        start_time: value.start_time ? `${value.start_time}:00` : null,
        next_day_flg: Number(value.next_day_flg),
        end_time: value.end_time ? `${value.end_time}:00` : null,
        content: value.content,
        is_delete: value.is_delete
      };
    });
};

// Post用のnormalizer
export const normalizeFormValuesToPostConsultation = (
  values: ConsultationValues,
  initialValues?: ConsultationValues,
  type?: number
): PostConsultation => {
  let normalizedValue: PostConsultation;

  // 新規作成
  if (!initialValues) {
    normalizedValue = {
      support_plan_consultations_id: null,
      target_flg: values.targetFlg,
      creation_date:
        selectDateValueToDatePaddingZero(values.creationDate) || "",
      author: Number(values.author) || null,

      monitoring_period:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
          ? Number(values.monitoringPeriod) || null
          : undefined,
      monitoring_start_ym:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
          ? selectDateValueToDatePaddingZeroWithoutDay(
              values.monitoringStartYm
            ) || null
          : undefined,
      plan_start_ym:
        selectDateValueToDatePaddingZeroWithoutDay(values.planStartYm) || null,

      classify_disability_support: Number(values.classifyDisabilitySupport),
      income_kind:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_PLAN
          ? values.incomeKind
          : undefined,

      disabled_welfare_service_recipient_number:
        values.disabledWelfareServiceRecipientNumber,
      regular_place_recipient_number: values.regularPlaceRecipientNumber,
      consultation_support_recipient_number:
        values.consultationSupportRecipientNumber,

      desired_life: values.desiredLife,
      support_policy: values.supportPolicy,
      long_term_goal: values.longTermGoal,
      short_term_goal: values.shortTermGoal,

      support_plan_consultation_details:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
          ? normalizeSupportPlanConsultationDetails(
              values.supportPlanConsultationDetails,
              values.targetFlg
            )
          : undefined,

      support_plan_consultation_schedule: normalizeSupportPlanConsultationSchedule(
        values.supportPlanConsultationSchedule
      ),
      main_activities: values.mainActivities,
      non_weekly_service: values.nonWeeklyService,
      overall_life: values.overallLife,
      staff_comment: values.staffComment,
      type_consultation: type
    };
  } else {
    // 編集
    const res = omitByNoChanges(values, initialValues);
    normalizedValue = {
      support_plan_consultations_id: values.id,
      target_flg: values.targetFlg,
      creation_date:
        selectDateValueToDatePaddingZero(values.creationDate) || "",
      author:
        res.author !== undefined ? Number(values.author) || null : undefined,
      monitoring_period:
        values.targetFlg !==
          CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION &&
        res.monitoringPeriod !== undefined
          ? Number(values.monitoringPeriod) || null
          : undefined,
      monitoring_start_ym:
        values.targetFlg !==
          CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION &&
        res.monitoringStartYm !== undefined
          ? selectDateValueToDatePaddingZeroWithoutDay(
              values.monitoringStartYm
            ) || null
          : undefined,
      plan_start_ym:
        res.planStartYm !== undefined
          ? selectDateValueToDatePaddingZeroWithoutDay(values.planStartYm) ||
            null
          : undefined,
      classify_disability_support:
        res.classifyDisabilitySupport !== undefined
          ? Number(values.classifyDisabilitySupport)
          : undefined,
      income_kind:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_PLAN ||
        res.incomeKind !== undefined
          ? values.incomeKind
          : undefined,
      disabled_welfare_service_recipient_number:
        res.disabledWelfareServiceRecipientNumber !== undefined
          ? values.disabledWelfareServiceRecipientNumber
          : undefined,
      regular_place_recipient_number:
        res.regularPlaceRecipientNumber !== undefined
          ? values.regularPlaceRecipientNumber
          : undefined,
      consultation_support_recipient_number:
        res.consultationSupportRecipientNumber !== undefined
          ? values.consultationSupportRecipientNumber
          : undefined,
      desired_life:
        res.desiredLife !== undefined ? values.desiredLife : undefined,
      support_policy:
        res.supportPolicy !== undefined ? values.supportPolicy : undefined,
      long_term_goal:
        res.longTermGoal !== undefined ? values.longTermGoal : undefined,
      short_term_goal:
        res.shortTermGoal !== undefined ? values.shortTermGoal : undefined,
      support_plan_consultation_details:
        values.targetFlg !== CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
          ? normalizeSupportPlanConsultationDetails(
              values.supportPlanConsultationDetails,
              values.targetFlg,
              initialValues.supportPlanConsultationDetails
            )
          : undefined,
      support_plan_consultation_schedule: normalizeSupportPlanConsultationSchedule(
        values.supportPlanConsultationSchedule,
        initialValues.supportPlanConsultationSchedule
      ),
      main_activities:
        res.mainActivities !== undefined ? values.mainActivities : undefined,
      non_weekly_service:
        res.nonWeeklyService !== undefined
          ? values.nonWeeklyService
          : undefined,
      overall_life:
        res.overallLife !== undefined ? values.overallLife : undefined,
      staff_comment:
        res.staffComment !== undefined ? values.staffComment : undefined,
      type_consultation: values.type_consultation
        ? values.type_consultation
        : undefined
    };
  }
  return removeNullParam(normalizedValue, [
    "support_plan_consultations_id",
    "target_flg",
    "creation_date"
  ]);
};
