import _has from "lodash-es/has";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { GetFacilityResponse } from "@api/requests/v202104/facility/getFacility";
import { PostFacilityParams } from "@api/requests/v202104/facility/postFacility";
import { FacilityValues } from "@initialize/v202104/mgr/KEIKAKUSODAN/facility/initialValues";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  STRING_FALSE_FROM_API
} from "@constants/variables";
import {
  DEFAULT_FUNCTION_ENHANCED_SYSTEM,
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

/** GetとPOSTどちらのデータか判定を行う */
const isGetResponse = (
  data: GetFacilityResponse["data"] | PostFacilityParams
): data is GetFacilityResponse["data"] => {
  return _has(data.facility_keikakusodan, "facility_type");
};

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams,
  facilityType?: number | null
): FacilityState => {
  const { facility, facility_keikakusodan } = result;
  const defaultValues: Exclude<
    GetFacilityResponse["data"]["facility_keikakusodan"],
    undefined
  > = {
    facility_type: FACILITY_TYPE_KEIKAKUSODAN, // 施設区分: 計画相談単体
    integrated_management_handicapped_child_flg: 0, // 施設（計画相談）：指定障害児相談支援事業所と一体的に運営フラグ
    facilities_keikakusodan_shogaijisodan: []
  };
  const facilityKEIKAKUSODAN = Object.assign(
    defaultValues,
    facility_keikakusodan
  );
  const facilities_keikakusodan_shogaijisodan = facilityKEIKAKUSODAN.facilities_keikakusodan_shogaijisodan.map<
    FacilityState["facilities_keikakusodan_shogaijisodan"][number]
  >((oneFacility) => ({
    target_type: oneFacility.target_type,
    govFacilityNumber: oneFacility.gov_facility_number,
    name: oneFacility.name,
    tel: oneFacility.tel,
    responsiblePerson: oneFacility.responsible_person,
    functionEnhancedSystem: oneFacility.function_enhanced_system
      ? oneFacility.function_enhanced_system.toString()
      : DEFAULT_FUNCTION_ENHANCED_SYSTEM,
    behavioralDisorderSupportFlg:
      oneFacility.behavioral_disorder_support_flg === INT_TRUE_FROM_API,
    needMedicalCareHandicappedChildSupportFlg:
      oneFacility.need_medical_care_handicapped_child_support_flg ===
      INT_TRUE_FROM_API,
    mentalDisorderSupportFlg:
      oneFacility.mental_disorder_support_flg === INT_TRUE_FROM_API,
    peerSupportFlg: oneFacility.peer_support_flg === INT_TRUE_FROM_API,
    lifeSupportHubInDistrictFlg:
      oneFacility.life_support_hub_in_district_flg === INT_TRUE_FROM_API
  }));

  return {
    corporationName:
      facility.gov_business_owner !== null ? facility.gov_business_owner : "",
    serviceType: facility.type_service,
    capacity: facility.capacity !== null ? facility.capacity.toString() : "",
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address !== null ? facility.address : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : "",
    integratedManagementHandicappedChildFlg:
      facilityKEIKAKUSODAN.integrated_management_handicapped_child_flg ===
      INT_TRUE_FROM_API,
    facilityType:
      isGetResponse(result) && result.facility_keikakusodan
        ? result.facility_keikakusodan.facility_type
        : facilityType || null,
    facilities_keikakusodan_shogaijisodan
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result: PostFacilityParams = {
    facility: { ...target.facility },
    facility_keikakusodan: target.facility_keikakusodan
      ? { ...target.facility_keikakusodan }
      : {}
  };

  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMap = {
  facility: {
    master_subordinate_flg: {
      key: "master_subordinate_flg",
      childKeys: ["master_flg"]
    }
  }
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param target
 * @param after
 */
const addParentValue = (
  target: PostFacilityParams,
  after: PostFacilityParams
): PostFacilityParams => {
  const result = target;
  Object.keys(parentParamsMap).forEach((facilityKey) => {
    Object.keys(parentParamsMap[facilityKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[facilityKey][paramKey].childKeys
        ? parentParamsMap[facilityKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return result[facilityKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        result[facilityKey][parentParamsMap[facilityKey][paramKey].key] =
          after[facilityKey][parentParamsMap[facilityKey][paramKey].key];
      }
    });
  });
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams,
  facilityType: number | null
): NormalizedPostFacilityParams => normalizeApiParams(params, facilityType);

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams,
  isExistKeikaku: boolean,
  isExistShogaiji: boolean
): PostFacilityParams => {
  const facilitiesKeikakusodanShogaijisodan = [];

  if (
    after.facility_keikakusodan &&
    after.facility_keikakusodan.facilities_keikakusodan_shogaijisodan
  ) {
    if (isExistKeikaku) {
      const afterFacilityKeikaku = after.facility_keikakusodan.facilities_keikakusodan_shogaijisodan.find(
        (v) => v.target_type === FACILITY_TARGET_TYPE_KEIKAKUSODAN
      );
      if (afterFacilityKeikaku) {
        facilitiesKeikakusodanShogaijisodan.push({
          target_type: FACILITY_TARGET_TYPE_KEIKAKUSODAN,
          gov_facility_number: afterFacilityKeikaku.gov_facility_number,
          name: afterFacilityKeikaku.name,
          tel: afterFacilityKeikaku.tel,
          responsible_person: afterFacilityKeikaku.responsible_person,
          function_enhanced_system: undefined,
          behavioral_disorder_support_flg: undefined,
          need_medical_care_handicapped_child_support_flg: undefined,
          mental_disorder_support_flg: undefined,
          peer_support_flg: undefined,
          life_support_hub_in_district_flg: undefined
        });
      }
    }
    if (isExistShogaiji) {
      const afterFacilityShogaiji = after.facility_keikakusodan.facilities_keikakusodan_shogaijisodan.find(
        (v) => v.target_type === FACILITY_TARGET_TYPE_SHOGAIJISODAN
      );
      if (afterFacilityShogaiji) {
        facilitiesKeikakusodanShogaijisodan.push({
          target_type: FACILITY_TARGET_TYPE_SHOGAIJISODAN,
          gov_facility_number: afterFacilityShogaiji.gov_facility_number,
          name: afterFacilityShogaiji.name,
          tel: afterFacilityShogaiji.tel,
          responsible_person: afterFacilityShogaiji.responsible_person,
          function_enhanced_system: undefined,
          behavioral_disorder_support_flg: undefined,
          need_medical_care_handicapped_child_support_flg: undefined,
          mental_disorder_support_flg: undefined,
          peer_support_flg: undefined,
          life_support_hub_in_district_flg: undefined
        });
      }
    }
  }

  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      capacity: after.facility.capacity,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      multiple_facility_flg: after.facility.multiple_facility_flg,
      users_vs_supporter_grade: after.facility.users_vs_supporter_grade,
      welfare_condition: after.facility.welfare_condition,
      welfare_condition_start_date: after.facility.welfare_condition_start_date,
      welfare_condition_end_date: after.facility.welfare_condition_end_date,
      better_supporter_condition: after.facility.better_supporter_condition,
      lack_of_supporter_flg: after.facility.lack_of_supporter_flg,
      date_start_lack_of_supporter: after.facility.date_start_lack_of_supporter,
      lack_of_service_admin_flg: after.facility.lack_of_service_admin_flg,
      date_start_lack_of_service_admin:
        after.facility.date_start_lack_of_service_admin,
      origin_local_gov_flg: after.facility.origin_local_gov_flg,
      see_hear_team_flg: after.facility.see_hear_team_flg
    },
    facility_keikakusodan: {
      integrated_management_handicapped_child_flg: undefined,
      facilities_keikakusodan_shogaijisodan: facilitiesKeikakusodanShogaijisodan
    }
  };

  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (
        target[key][param] !== undefined &&
        param !== "facilities_keikakusodan_shogaijisodan"
      ) {
        return;
      }

      if (
        param === "facilities_keikakusodan_shogaijisodan" &&
        target.facility_keikakusodan &&
        target.facility_keikakusodan.facilities_keikakusodan_shogaijisodan
      ) {
        target.facility_keikakusodan.facilities_keikakusodan_shogaijisodan.forEach(
          (facility, i) => {
            Object.keys(facility).forEach((flag) => {
              if (target[key][param][i][flag] !== undefined) {
                return;
              }
              if (
                `${before[key][param][i][flag]}` ===
                `${after[key][param][i][flag]}`
              ) {
                target[key][param][i][flag] = undefined;
              } else {
                target[key][param][i][flag] = after[key][param][i][flag];
              }
            });
          }
        );
      } else if (`${before[key][param]}` === `${after[key][param]}`) {
        target[key][param] = undefined;
      } else {
        target[key][param] = after[key][param];
      }
    });
  });

  return addParentValue(target, after);
};

export const normalizeFormValue = (
  state: FacilityValues,
  isExistKeikaku: boolean,
  isExistShogaiji: boolean
): PostFacilityParams => {
  const facilitiesKeikakusodanShogaijisodan = [];
  if (isExistKeikaku) {
    facilitiesKeikakusodanShogaijisodan.push({
      target_type: FACILITY_TARGET_TYPE_KEIKAKUSODAN,
      gov_facility_number: state.facilityInfoKeikaku.officeNumber,
      name: state.facilityInfoKeikaku.officeName,
      tel: state.facilityInfoKeikaku.tel,
      responsible_person: state.facilityInfoKeikaku.representativeName,
      function_enhanced_system: state.facilityInfoKeikaku.functionEnhancedSystem
        ? parseInt(state.facilityInfoKeikaku.functionEnhancedSystem, 10)
        : parseInt(DEFAULT_FUNCTION_ENHANCED_SYSTEM, 10),
      behavioral_disorder_support_flg: state.facilityInfoKeikaku
        .behavioralDisorderSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      need_medical_care_handicapped_child_support_flg: state.facilityInfoKeikaku
        .needMedicalCareHandicappedChildSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      mental_disorder_support_flg: state.facilityInfoKeikaku
        .mentalDisorderSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      peer_support_flg: state.facilityInfoKeikaku.peerSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      life_support_hub_in_district_flg: state.facilityInfoKeikaku
        .lifeSupportHubInDistrictFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    });
  }
  if (isExistShogaiji) {
    facilitiesKeikakusodanShogaijisodan.push({
      target_type: FACILITY_TARGET_TYPE_SHOGAIJISODAN,
      gov_facility_number: state.facilityInfoShogaiji.officeNumber,
      name: state.facilityInfoShogaiji.officeName,
      tel: state.facilityInfoShogaiji.tel,
      responsible_person: state.facilityInfoShogaiji.representativeName,
      function_enhanced_system: state.facilityInfoShogaiji
        .functionEnhancedSystem
        ? parseInt(state.facilityInfoShogaiji.functionEnhancedSystem, 10)
        : parseInt(DEFAULT_FUNCTION_ENHANCED_SYSTEM, 10),
      behavioral_disorder_support_flg: state.facilityInfoShogaiji
        .behavioralDisorderSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      need_medical_care_handicapped_child_support_flg: state
        .facilityInfoShogaiji.needMedicalCareHandicappedChildSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      mental_disorder_support_flg: state.facilityInfoShogaiji
        .mentalDisorderSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      peer_support_flg: state.facilityInfoShogaiji.peerSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      life_support_hub_in_district_flg: state.facilityInfoShogaiji
        .lifeSupportHubInDistrictFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    });
  }

  return removeUnnecessaryValue({
    facility: {
      gov_business_owner: state.basic.corporationName,
      type_service: state.basic.serviceType,
      capacity:
        state.basic.capacity !== "" ? parseInt(state.basic.capacity, 10) : null,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: null,
      welfare_condition_end_date: null,
      better_supporter_condition: "",
      lack_of_supporter_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_supporter: null,
      lack_of_service_admin_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: STRING_FALSE_FROM_API,
      see_hear_team_flg: STRING_FALSE_FROM_API
    },
    facility_keikakusodan: {
      facilities_keikakusodan_shogaijisodan: facilitiesKeikakusodanShogaijisodan,
      integrated_management_handicapped_child_flg: state.basic
        .integratedManagementHandicappedChildFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  });
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues,
  isExistKeikaku: boolean,
  isExistShogaiji: boolean
): PostFacilityParams => {
  return removeNoChangeData(
    params,
    normalizeFormValue(response, isExistKeikaku, isExistShogaiji),
    isExistKeikaku,
    isExistShogaiji
  );
};
