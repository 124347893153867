import * as React from "react";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  StyleRules,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import Table from "@components/molecules/Table";
import {
  TableMultiHead,
  HeaderProps
} from "@components/molecules/TableMultiHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import { InOutReportListCell } from "@components/organisms/mgr/CHIIKITEICHAKU/report/cell/InOutReportListCell";
import { AppState } from "@stores/type";
import { ReportState } from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/CHIIKITEICHAKU/report/initial";
import format from "date-fns/format";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    tableLayout: {
      width: "100%",
      minWidth: 1030
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    superShortCell: {
      minWidth: 16,
      width: 16,
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 4
      },
      padding: 4
    },
    shortMiddleCell: {
      minWidth: 41,
      width: "15%",
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 2
      },
      padding: 4
    },
    dataHeaderColor: {
      backgroundColor: "#9badb6"
    },
    headerFontSize: {
      fontSize: 13
    },
    date: {
      paddingLeft: 16
    },
    longCell: {
      width: 41,
      minWidth: 41,
      maxWidth: 41,
      wordBreak: "break-all",
      "&:last-child": {
        paddingRight: 16
      }
    }
  });

type StateProps = {
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  isEditing: boolean;
  headerHeight: number;
  filterFlg: boolean;
  formikProps: FormikProps<InitialValues>;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const reportMonthlyHeader = (classes: Props["classes"]): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    rowStyle: [
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 32
      },
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 56
      }
    ],
    items: [
      [
        {
          align: "left",
          label: "日付",
          className: `${classes.shortMiddleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.date}`
        },
        {
          align: "left",
          label: "サービス提供の状況",
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              居住支援連携
              <br />
              体制加算
            </>
          ),
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              地域居住支援
              <br />
              体制強化推進加算
            </>
          ),
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: (
            <>
              日常生活支援
              <br />
              情報提供加算
            </>
          ),
          className: `${classes.superShortCell} ${classes.dataHeaderColor} ${classes.headerFontSize}`
        },
        {
          align: "left",
          label: "備考（実績記録表に表示）",
          className: `${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.longCell}`
        }
      ]
    ]
  };
};

const InOutReportTableCore = (props: Props): JSX.Element => {
  const payStartDate =
    props.usersInFacilityState &&
    props.usersInFacilityState.user.user_in_facility.date_begin_in_service
      ? props.usersInFacilityState.user.user_in_facility.date_begin_in_service
      : "";
  const payEndDate =
    props.usersInFacilityState &&
    props.usersInFacilityState.user.user_in_facility.date_end_in_service
      ? props.usersInFacilityState.user.user_in_facility.date_end_in_service
      : "";
  const startDate = Number(format(payStartDate, "YYYYMMDD"));
  const endDate = payEndDate ? Number(format(payEndDate, "YYYYMMDD")) : "";

  const tableList = props.reportState.inoutConsultationResults.map(
    (inOutResult, index) => {
      const isServicePeriod = !(
        Number(format(inOutResult.targetDate, "YYYYMMDD")) < startDate ||
        (endDate &&
          Number(format(inOutResult.targetDate, "YYYYMMDD")) > endDate)
      );
      return (
        <>
          {isServicePeriod && (
            <TableRow
              key={`table-row-${inOutResult.targetDate}`}
              className={props.classes.row}
            >
              <InOutReportListCell
                params={inOutResult}
                formikProps={props.formikProps}
                idx={`${index}`}
                isEditing={props.isEditing}
              />
            </TableRow>
          )}
        </>
      );
    }
  );
  const tableHead = reportMonthlyHeader(props.classes);
  return (
    <>
      <Table key="monthly-report-table" className={props.classes.tableLayout}>
        <TableMultiHead
          role={tableHead.role}
          ariaChecked={tableHead.ariaChecked}
          tabIndex={tableHead.tabIndex}
          key={tableHead.key}
          selected={tableHead.selected}
          items={tableHead.items}
          rowStyle={tableHead.rowStyle}
          headerStyle={{
            zIndex: 10,
            position: "sticky",
            top: `${props.headerHeight}px`
          }}
        />
        <TableBody>{tableList.length > 0 && tableList}</TableBody>
      </Table>
      {tableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者の情報が登録されていません。
          <br />
          利用者情報ページで利用者を登録した後にご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    reportState: state.CHIIKITEICHAKU.report,
    usersInFacilityState: state.CHIIKITEICHAKU.userInFacility
  };
};

export const InOutReportTable = connect(mapStateToProps)(
  withStyles(styles)(InOutReportTableCore)
);
