import { CityState } from "@stores/domain/city/type";

/**
 * 選択した市区町村のcityGradeを取得する（令和6年4月報酬改定版）
 */
export function getSelectedCityGrade202404(
  cityList: CityState[],
  selectedCityId: number | string
): string {
  let result = "";
  const id = Number(selectedCityId);
  Object.keys(cityList).forEach((key) => {
    if (cityList[key].value === id) {
      result = cityList[key].grade_202404
        ? String(cityList[key].grade_202404)
        : "";
    }
  });

  return result;
}
