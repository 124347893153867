import React, { useEffect } from "react";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
// ui
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between"
    },
    selectWrap: {
      display: "flex",
      alignItems: "end",
      marginTop: "8px"
    },
    usersDropDown: {
      marginTop: "0px !important",
      marginBottom: "0px !important",
      marginLeft: spacing.unit * 2
    }
  });

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedDate: Date;
  onChangeDate: (date: Date) => void;
  openPrintModal: () => void;
  disabledPrint: boolean;
  selectedUserId: string | number;
  onChangeUser: (user: OptionInterface) => void;
  changeUserWithDate: (users: OptionInterface[]) => void;
  userListOption: OptionInterface[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryMonthlyHeaderCore = (props: Props): JSX.Element => {
  const { classes } = props;

  // 画面表示時の処理（デフォルトの利用者を選択状態にする）
  useEffect(() => {
    if (
      props.userListOption &&
      props.userListOption[0] &&
      !props.selectedUserId
    ) {
      props.changeUserWithDate(props.userListOption);
    }
  }, [props.userListOption]);

  /**
   * 利用者の変更
   */
  const onChangeUserList = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const user = props.userListOption.find(
      (x) => x.value === event.target.value
    );
    if (user) {
      props.onChangeUser(user);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.selectWrap}>
          <DateSelectButtonsMonthly
            selectedMonth={props.selectedDate}
            min={props.minDate}
            max={props.maxDate}
            onClickSubmit={props.onChangeDate}
          />
          {props.userListOption.length > 0 && (
            <DropDown
              id="users-in-facility-list"
              label="利用者選択"
              isError={false}
              options={props.userListOption}
              value={`${props.selectedUserId}`}
              styles={classes.usersDropDown}
              onChange={onChangeUserList}
            />
          )}
        </div>
        <div>
          <KnowbeButton
            onClick={props.openPrintModal}
            disabled={props.disabledPrint}
          >
            印刷
          </KnowbeButton>
        </div>
      </div>
    </>
  );
};

export const ServiceDeliveryMonthlyHeader = withStyles(styles)(
  ServiceDeliveryMonthlyHeaderCore
);
