import { FacilityState } from "./types";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { PostFacilityParams } from "@api/requests/facility/postFacility";
import { FacilityValues } from "@initialize/mgr/SHISETSUNYUSHO/facility/initialValues";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  STRING_FALSE_FROM_API,
  STRING_TRUE_FROM_API,
  DEFAULT_RADIO_VALUE,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  SELECTED_CONDITION_TYPE_2024_V
} from "@constants/variables";
import { selectDateValueToDate } from "@utils/date";
import castNumber from "@utils/dataNormalizer/castNumber";
import castString from "@utils/dataNormalizer/castString";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

// , formatTime
/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

// パラメータの親子関係マッピング表
const parentParamsMap = {
  facility: {
    master_subordinate_flg: {
      key: "master_subordinate_flg",
      childKeys: ["master_flg"]
    },
    better_supporter_condition: {
      key: "better_supporter_condition",
      childKeys: ["specific_better_supporter_condition"]
    },
    specific_better_supporter_condition: {
      key: "specific_better_supporter_condition",
      childKeys: [
        "better_supporter_condition",
        "better_supporter_condition_base_up_flg"
      ]
    }
  },
  facility_shisetsunyusho: {
    food_expenses: {
      key: "food_expenses",
      childKeys: [
        "food_expenses_breakfast",
        "food_expenses_lunch",
        "food_expenses_supper",
        "food_expenses_day"
      ]
    },
    food_expenses_breakfast: {
      key: "food_expenses_breakfast",
      childKeys: ["food_expenses"]
    },
    food_expenses_lunch: {
      key: "food_expenses_lunch",
      childKeys: ["food_expenses"]
    },
    food_expenses_supper: {
      key: "food_expenses_supper",
      childKeys: ["food_expenses"]
    },
    food_expenses_day: {
      key: "food_expenses_day",
      childKeys: ["food_expenses"]
    },
    utility: {
      key: "utility",
      childKeys: ["utility_costs"]
    },
    utility_costs: {
      key: "utility_costs",
      childKeys: ["utility"]
    }
  }
};

const undefinedToReturnValueToNumber = (
  value: number | undefined,
  returnValue: number
): number => {
  return Number(undefinedToReturnValue(value, returnValue));
};

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams
): FacilityState => {
  const { facility } = result;
  const facilitySHISETSUNYUSHO = result.facility_shisetsunyusho || {
    food_expenses: 0,
    food_expenses_breakfast: 0,
    food_expenses_lunch: 0,
    food_expenses_supper: 0,
    food_expenses_day: 0,
    utility: 0,
    utility_costs: 0,
    nutritionist_placement: 0,
    nighttime_placement: 0,
    nighttime_nurse_count: 0,
    serious_disability: 0,
    regional_life_transition: 0,
    oral_hygiene_management_system_flg: 0
  };
  const units = result.units
    ? result.units.map((unit) => ({
        id: unit.id,
        unit_name: `${unit.unit_name || ""}`,
        night_support_type: `${unit.night_support_type_202104 || 1}`,
        ave_users_last_fiscal_year: `${unit.ave_users_last_fiscal_year}`,
        is_deleted: unit.is_deleted === INT_TRUE_FROM_API
      }))
    : [];
  return {
    units,
    corporationName: facility.gov_business_owner,
    officeNumber: facility.gov_facility_number
      ? facility.gov_facility_number
      : "",
    officeName: facility.name ? facility.name : "",
    serviceType: facility.type_service,
    representativeName: facility.responsible_person
      ? facility.responsible_person
      : "",
    capacity: facility.capacity !== null ? facility.capacity.toString() : "",
    multiFunctionOfficeFlg:
      facility.multiple_facility_flg === STRING_TRUE_FROM_API,
    operatingUnitFlag: facility.operating_unit_flg === INT_TRUE_FROM_API,
    masterSubordinateFlg:
      facility.master_subordinate_flg === STRING_TRUE_FROM_API,
    isMasterRadioValue: facility.master_flg ? facility.master_flg : "1",
    allCapacity: castString(facility.total_capacity),
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address,
    tel: facility.tel ? facility.tel : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : "",
    availableFood: facility.available_food === STRING_TRUE_FROM_API,
    foodExpenses: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.food_expenses,
      0
    ),
    foodExpensesBreakfast: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.food_expenses_breakfast,
      0
    ),
    foodExpensesLunch: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.food_expenses_lunch,
      0
    ),
    foodExpensesSupper: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.food_expenses_supper,
      0
    ),
    foodExpensesDay: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.food_expenses_day,
      0
    ),
    utility: undefinedToReturnValueToNumber(facilitySHISETSUNYUSHO.utility, 0),
    utilityCosts: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.utility_costs,
      0
    ),
    originLocalGovFlg: facility.origin_local_gov_flg === STRING_TRUE_FROM_API,
    lackOfSupporterFlg: facility.lack_of_supporter_flg === STRING_TRUE_FROM_API,
    dateStartLackOfSupporter:
      facility.date_start_lack_of_supporter !== null
        ? facility.date_start_lack_of_supporter
        : "",
    abusePreventionMeasuresNotImplementedFlg:
      facility.abuse_prevention_measures_not_implemented_flg ===
      INT_TRUE_FROM_API,
    abusePreventionMeasuresNotImplementedFrom: facility.abuse_prevention_measures_not_implemented_from
      ? facility.abuse_prevention_measures_not_implemented_from
      : "",
    abusePreventionMeasuresNotImplementedTo: facility.abuse_prevention_measures_not_implemented_to
      ? facility.abuse_prevention_measures_not_implemented_to
      : "",
    regionalTransitionSupportFlg:
      facilitySHISETSUNYUSHO.regional_transition_support_flg ===
      INT_TRUE_FROM_API,
    regionalTransitionSupportFrom: facilitySHISETSUNYUSHO.regional_transition_support_from
      ? facilitySHISETSUNYUSHO.regional_transition_support_from
      : "",
    regionalTransitionSupportTo: facilitySHISETSUNYUSHO.regional_transition_support_to
      ? facilitySHISETSUNYUSHO.regional_transition_support_to
      : "",
    decreaseUsersCount:
      facilitySHISETSUNYUSHO.decrease_users_count !== null
        ? undefinedToReturnValue(facilitySHISETSUNYUSHO.decrease_users_count)
        : "",
    nutritionistPlacement: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.nutritionist_placement,
      1
    ),
    nighttimePlacement: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.nighttime_placement,
      0
    ),
    nighttimeNurseCount:
      facilitySHISETSUNYUSHO.nighttime_nurse_count !== 0
        ? castString(facilitySHISETSUNYUSHO.nighttime_nurse_count)
        : "",
    seeHearTeam202404: facility.see_hear_team_202404
      ? facility.see_hear_team_202404.toString()
      : DEFAULT_RADIO_VALUE,
    seeHearTeam202404From: facility.see_hear_team_202404_from
      ? facility.see_hear_team_202404_from
      : "",
    seeHearTeam202404To: facility.see_hear_team_202404_to
      ? facility.see_hear_team_202404_to
      : "",
    regionalLifeTransition:
      facilitySHISETSUNYUSHO.regional_life_transition === INT_TRUE_FROM_API,
    specificBetterSupporterCondition202404: facility.specific_better_supporter_condition_202404
      ? facility.specific_better_supporter_condition_202404
      : DEFAULT_RADIO_VALUE,
    staffTreatmentSystemType: facility.better_supporter_condition
      ? facility.better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    staffTreatmentSpecificSystemType: facility.specific_better_supporter_condition
      ? facility.specific_better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    seriousDisability: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.serious_disability,
      0
    ),
    betterSupporterConditionBaseUpFlg:
      facility.better_supporter_condition_base_up_flg === STRING_TRUE_FROM_API,
    oralHygieneManagementSystemFlg: undefinedToReturnValueToNumber(
      facilitySHISETSUNYUSHO.oral_hygiene_management_system_flg,
      0
    ),
    higherBrainDysfunctionPersonsSupportFlg:
      facility.higher_brain_dysfunction_persons_support_flg ===
      INT_TRUE_FROM_API,
    higherBrainDysfunctionPersonsSupportFrom: facility.higher_brain_dysfunction_persons_support_from
      ? facility.higher_brain_dysfunction_persons_support_from
      : "",
    higherBrainDysfunctionPersonsSupportTo: facility.higher_brain_dysfunction_persons_support_to
      ? facility.higher_brain_dysfunction_persons_support_to
      : "",
    bodyRestrictedStillFlg:
      facility.body_restricted_still_flg === INT_TRUE_FROM_API,
    bodyRestrictedStillFrom: facility.body_restricted_still_from
      ? facility.body_restricted_still_from
      : "",
    bodyRestrictedStillTo: facility.body_restricted_still_to
      ? facility.body_restricted_still_to
      : "",
    workContinuationNotPlanningFlg:
      facility.work_continuation_not_planning_flg === INT_TRUE_FROM_API,
    workContinuationNotPlanningFrom: facility.work_continuation_not_planning_from
      ? facility.work_continuation_not_planning_from
      : "",
    workContinuationNotPlanningTo: facility.work_continuation_not_planning_to
      ? facility.work_continuation_not_planning_to
      : "",
    informationDisclosureNotReportedFlg:
      facility.information_disclosure_not_reported_flg === INT_TRUE_FROM_API,
    informationDisclosureNotReportedFrom: facility.information_disclosure_not_reported_from
      ? facility.information_disclosure_not_reported_from
      : "",
    informationDisclosureNotReportedTo: facility.information_disclosure_not_reported_to
      ? facility.information_disclosure_not_reported_to
      : "",
    improvingInfectionControl1Flg:
      facility.improving_infection_control1_flg === INT_TRUE_FROM_API,
    improvingInfectionControl1From: facility.improving_infection_control1_from
      ? facility.improving_infection_control1_from
      : "",
    improvingInfectionControl1To: facility.improving_infection_control1_to
      ? facility.improving_infection_control1_to
      : "",
    improvingInfectionControl2Flg:
      facility.improving_infection_control2_flg === INT_TRUE_FROM_API,
    improvingInfectionControl2From: facility.improving_infection_control2_from
      ? facility.improving_infection_control2_from
      : "",
    improvingInfectionControl2To: facility.improving_infection_control2_to
      ? facility.improving_infection_control2_to
      : ""
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result = {
    facility: { ...target.facility },
    facility_shisetsunyusho: target.facility_shisetsunyusho
      ? { ...target.facility_shisetsunyusho }
      : undefined,
    units: target.units
  };
  if (result.facility_shisetsunyusho) {
    if (result.facility.available_food === STRING_FALSE_FROM_API) {
      result.facility_shisetsunyusho.food_expenses = 0;
      result.facility_shisetsunyusho.food_expenses_breakfast = 0;
      result.facility_shisetsunyusho.food_expenses_lunch = 0;
      result.facility_shisetsunyusho.food_expenses_supper = 0;
      result.facility_shisetsunyusho.food_expenses_day = 0;
    }
    if (result.facility_shisetsunyusho.food_expenses === 0) {
      result.facility_shisetsunyusho.food_expenses_breakfast = 0;
      result.facility_shisetsunyusho.food_expenses_lunch = 0;
      result.facility_shisetsunyusho.food_expenses_supper = 0;
      result.facility_shisetsunyusho.food_expenses_day = 0;
    }
    if (result.facility_shisetsunyusho.food_expenses === 1) {
      result.facility_shisetsunyusho.food_expenses_day = 0;
    }
    if (result.facility_shisetsunyusho.food_expenses === 2) {
      result.facility_shisetsunyusho.food_expenses_breakfast = 0;
      result.facility_shisetsunyusho.food_expenses_lunch = 0;
      result.facility_shisetsunyusho.food_expenses_supper = 0;
    }
    if (result.facility_shisetsunyusho.utility === 0) {
      result.facility_shisetsunyusho.utility_costs = 0;
    }
    if (result.facility_shisetsunyusho.nighttime_placement !== 2) {
      result.facility_shisetsunyusho.nighttime_nurse_count = 0;
    }
    if (
      result.facility_shisetsunyusho.regional_transition_support_flg ===
      INT_FALSE_FROM_API
    ) {
      result.facility_shisetsunyusho.regional_transition_support_from = null;
      result.facility_shisetsunyusho.regional_transition_support_to = null;
      result.facility_shisetsunyusho.decrease_users_count = 0;
    }
  }
  if (result.facility.master_subordinate_flg === STRING_FALSE_FROM_API) {
    result.facility.master_flg = null;
  }
  if (result.facility.lack_of_supporter_flg === STRING_FALSE_FROM_API) {
    result.facility.date_start_lack_of_supporter = null;
  }
  if (
    !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      result.facility.better_supporter_condition
    )
  ) {
    result.facility.specific_better_supporter_condition = DEFAULT_RADIO_VALUE;
    result.facility.better_supporter_condition_base_up_flg = STRING_FALSE_FROM_API;
  }
  if (
    result.facility.higher_brain_dysfunction_persons_support_flg ===
    INT_FALSE_FROM_API
  ) {
    result.facility.higher_brain_dysfunction_persons_support_from = null;
    result.facility.higher_brain_dysfunction_persons_support_to = null;
  }
  if (result.facility.body_restricted_still_flg === INT_FALSE_FROM_API) {
    result.facility.body_restricted_still_from = null;
    result.facility.body_restricted_still_to = null;
  }
  if (
    result.facility.work_continuation_not_planning_flg === INT_FALSE_FROM_API
  ) {
    result.facility.work_continuation_not_planning_from = null;
    result.facility.work_continuation_not_planning_to = null;
  }
  if (
    result.facility.information_disclosure_not_reported_flg ===
    INT_FALSE_FROM_API
  ) {
    result.facility.information_disclosure_not_reported_from = null;
    result.facility.information_disclosure_not_reported_to = null;
  }
  // operating_unit_flgがなければ送らない
  if (!target.facility.operating_unit_flg) {
    delete result.units;
  }
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams
): NormalizedPostFacilityParams => normalizeApiParams(params);

/**
 * パラメータ内のundefinedはパラメータから除外する
 * @param data
 */
const removeNullParam = (data: PostFacilityParams): PostFacilityParams => {
  const targetParm = {
    facility: {},
    facility_shisetsunyusho: {},
    units: {}
  };
  // 引数のデータからfacilityとfacility_shisetsunyushoを取得
  Object.keys(data).forEach((key) => {
    if (key === "units") {
      if (data.units !== undefined) {
        targetParm.units = data.units;
      }
    } else {
      // facilityとfacility_shisetsunyushoからparamを取得
      Object.keys(data[key]).forEach((param) => {
        if (data[key][param] !== undefined) {
          targetParm[key][param] = data[key][param];
        }
      });
    }
  });

  return targetParm as PostFacilityParams;
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param target
 * @param after
 */
const addParentValue = (
  target: PostFacilityParams,
  after: PostFacilityParams
): PostFacilityParams => {
  const tempTarget = { ...target };
  Object.keys(parentParamsMap).forEach((facilityKey) => {
    Object.keys(parentParamsMap[facilityKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[facilityKey][paramKey].childKeys
        ? parentParamsMap[facilityKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return target[facilityKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        tempTarget[facilityKey][parentParamsMap[facilityKey][paramKey].key] =
          after[facilityKey][parentParamsMap[facilityKey][paramKey].key];
      }
    });
  });

  // 特殊な親子関係
  if (tempTarget.facility_shisetsunyusho && after.facility_shisetsunyusho) {
    if (tempTarget.facility_shisetsunyusho.food_expenses !== undefined) {
      tempTarget.facility.available_food = after.facility.available_food;
    }
    if (tempTarget.facility.available_food !== undefined) {
      tempTarget.facility_shisetsunyusho.food_expenses =
        after.facility_shisetsunyusho.food_expenses;
      tempTarget.facility_shisetsunyusho.food_expenses_breakfast =
        after.facility_shisetsunyusho.food_expenses_breakfast;
      tempTarget.facility_shisetsunyusho.food_expenses_lunch =
        after.facility_shisetsunyusho.food_expenses_lunch;
      tempTarget.facility_shisetsunyusho.food_expenses_supper =
        after.facility_shisetsunyusho.food_expenses_supper;
      tempTarget.facility_shisetsunyusho.food_expenses_day =
        after.facility_shisetsunyusho.food_expenses_day;
    }
  }
  return removeNullParam(tempTarget);
};

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams
): PostFacilityParams => {
  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      capacity: after.facility.capacity,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      multiple_facility_flg: after.facility.multiple_facility_flg,
      operating_unit_flg: after.facility.operating_unit_flg,
      master_subordinate_flg: undefined,
      // multiple_facility_flgを毎回送信しているため
      // 関係のあるmaster_flgとtotal_capacityも毎回送信する
      master_flg: after.facility.master_flg,
      total_capacity: after.facility.total_capacity,
      users_vs_supporter_grade: after.facility.users_vs_supporter_grade,
      welfare_condition: after.facility.welfare_condition,
      welfare_condition_start_date: after.facility.welfare_condition_start_date,
      welfare_condition_end_date: after.facility.welfare_condition_end_date,
      better_supporter_condition: after.facility.better_supporter_condition,
      lack_of_supporter_flg: after.facility.lack_of_supporter_flg,
      date_start_lack_of_supporter: after.facility.date_start_lack_of_supporter,
      abuse_prevention_measures_not_implemented_flg: undefined,
      abuse_prevention_measures_not_implemented_from: undefined,
      abuse_prevention_measures_not_implemented_to: undefined,
      lack_of_service_admin_flg: after.facility.lack_of_service_admin_flg,
      date_start_lack_of_service_admin:
        after.facility.date_start_lack_of_service_admin,
      origin_local_gov_flg: after.facility.origin_local_gov_flg,
      see_hear_team_202404: undefined,
      see_hear_team_202404_from: undefined,
      see_hear_team_202404_to: undefined,
      better_supporter_condition_base_up_flg:
        after.facility.better_supporter_condition_base_up_flg,

      available_food: undefined,
      specific_better_supporter_condition: undefined,
      specific_better_supporter_condition_202404: undefined,
      higher_brain_dysfunction_persons_support_flg: undefined,
      higher_brain_dysfunction_persons_support_from: undefined,
      higher_brain_dysfunction_persons_support_to: undefined,
      body_restricted_still_flg: undefined,
      body_restricted_still_from: undefined,
      body_restricted_still_to: undefined,
      work_continuation_not_planning_flg: undefined,
      work_continuation_not_planning_from: undefined,
      work_continuation_not_planning_to: undefined,
      information_disclosure_not_reported_flg: undefined,
      information_disclosure_not_reported_from: undefined,
      information_disclosure_not_reported_to: undefined,
      improving_infection_control1_flg: undefined,
      improving_infection_control1_from: undefined,
      improving_infection_control1_to: undefined,
      improving_infection_control2_flg: undefined,
      improving_infection_control2_from: undefined,
      improving_infection_control2_to: undefined
    },
    facility_shisetsunyusho: {
      food_expenses: undefined,
      food_expenses_breakfast: undefined,
      food_expenses_lunch: undefined,
      food_expenses_supper: undefined,
      food_expenses_day: undefined,
      utility: undefined,
      utility_costs: undefined,
      nutritionist_placement: undefined,
      nighttime_placement: undefined,
      nighttime_nurse_count: undefined,
      serious_disability: undefined,
      regional_life_transition: undefined,
      oral_hygiene_management_system_flg: undefined,
      regional_transition_support_flg: undefined,
      regional_transition_support_from: undefined,
      regional_transition_support_to: undefined,
      decrease_users_count: undefined
    },
    units: after.units
  };

  Object.keys(target).forEach((key) => {
    if (key !== "units") {
      Object.keys(target[key]).forEach((param) => {
        if (target[key][param] === undefined) {
          target[key][param] =
            before[key][param] !== after[key][param]
              ? after[key][param]
              : undefined;
        }
      });
    }
  });

  // 高次脳機能障害支援体制加算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.higher_brain_dysfunction_persons_support_flg ||
    target.facility.higher_brain_dysfunction_persons_support_from ||
    target.facility.higher_brain_dysfunction_persons_support_to
  ) {
    target.facility.higher_brain_dysfunction_persons_support_flg =
      after.facility.higher_brain_dysfunction_persons_support_flg;
    target.facility.higher_brain_dysfunction_persons_support_from =
      after.facility.higher_brain_dysfunction_persons_support_from;
    target.facility.higher_brain_dysfunction_persons_support_to =
      after.facility.higher_brain_dysfunction_persons_support_to;
  }
  if (target.facility_shisetsunyusho && after.facility_shisetsunyusho) {
    // 地域移行支援体制加算のフラグ、利用定員の減少数、開始終了年月日はセットで送信する
    if (
      target.facility_shisetsunyusho.regional_transition_support_flg !==
        undefined ||
      target.facility_shisetsunyusho.regional_transition_support_from !==
        undefined ||
      target.facility_shisetsunyusho.regional_transition_support_to !==
        undefined ||
      target.facility_shisetsunyusho.decrease_users_count !== undefined
    ) {
      target.facility_shisetsunyusho.regional_transition_support_flg =
        after.facility_shisetsunyusho.regional_transition_support_flg;
      target.facility_shisetsunyusho.regional_transition_support_from =
        after.facility_shisetsunyusho.regional_transition_support_from;
      target.facility_shisetsunyusho.regional_transition_support_to =
        after.facility_shisetsunyusho.regional_transition_support_to;
      target.facility_shisetsunyusho.decrease_users_count =
        after.facility_shisetsunyusho.decrease_users_count;
    }

    // 夜間看護体制加算のフラグ、夜間看護職員の配置人数はセットで送信する
    if (
      target.facility_shisetsunyusho.nighttime_placement !== undefined ||
      target.facility_shisetsunyusho.nighttime_nurse_count !== undefined
    ) {
      target.facility_shisetsunyusho.nighttime_placement =
        after.facility_shisetsunyusho.nighttime_placement;
      target.facility_shisetsunyusho.nighttime_nurse_count =
        after.facility_shisetsunyusho.nighttime_nurse_count;
    }
  }

  // 情報公表未報告減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.information_disclosure_not_reported_flg !== undefined ||
    target.facility.information_disclosure_not_reported_from !== undefined ||
    target.facility.information_disclosure_not_reported_to !== undefined
  ) {
    target.facility.information_disclosure_not_reported_flg =
      after.facility.information_disclosure_not_reported_flg;
    target.facility.information_disclosure_not_reported_from =
      after.facility.information_disclosure_not_reported_from;
    target.facility.information_disclosure_not_reported_to =
      after.facility.information_disclosure_not_reported_to;
  }
  // 虐待防止措置未実施減算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.abuse_prevention_measures_not_implemented_flg !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_from !==
      undefined ||
    target.facility.abuse_prevention_measures_not_implemented_to !== undefined
  ) {
    target.facility.abuse_prevention_measures_not_implemented_flg =
      after.facility.abuse_prevention_measures_not_implemented_flg;
    target.facility.abuse_prevention_measures_not_implemented_from =
      after.facility.abuse_prevention_measures_not_implemented_from;
    target.facility.abuse_prevention_measures_not_implemented_to =
      after.facility.abuse_prevention_measures_not_implemented_to;
  }
  if (
    target.facility.body_restricted_still_flg !== undefined ||
    target.facility.body_restricted_still_from !== undefined ||
    target.facility.body_restricted_still_to !== undefined
  ) {
    target.facility.body_restricted_still_flg =
      after.facility.body_restricted_still_flg;
    target.facility.body_restricted_still_from =
      after.facility.body_restricted_still_from;
    target.facility.body_restricted_still_to =
      after.facility.body_restricted_still_to;
  }
  if (
    target.facility.work_continuation_not_planning_flg !== undefined ||
    target.facility.work_continuation_not_planning_from !== undefined ||
    target.facility.work_continuation_not_planning_to !== undefined
  ) {
    target.facility.work_continuation_not_planning_flg =
      after.facility.work_continuation_not_planning_flg;
    target.facility.work_continuation_not_planning_from =
      after.facility.work_continuation_not_planning_from;
    target.facility.work_continuation_not_planning_to =
      after.facility.work_continuation_not_planning_to;
  }

  // 視覚・聴覚障害者支援体制加算のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.see_hear_team_202404 !== undefined ||
    target.facility.see_hear_team_202404_from !== undefined ||
    target.facility.see_hear_team_202404_to !== undefined
  ) {
    target.facility.see_hear_team_202404 = after.facility.see_hear_team_202404;
    target.facility.see_hear_team_202404_from =
      after.facility.see_hear_team_202404_from;
    target.facility.see_hear_team_202404_to =
      after.facility.see_hear_team_202404_to;
  }

  // 障害者支援施設等感染対策向上加算（Ⅰ）のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.improving_infection_control1_flg !== undefined ||
    target.facility.improving_infection_control1_from !== undefined ||
    target.facility.improving_infection_control1_to !== undefined
  ) {
    target.facility.improving_infection_control1_flg =
      after.facility.improving_infection_control1_flg;
    target.facility.improving_infection_control1_from =
      after.facility.improving_infection_control1_from;
    target.facility.improving_infection_control1_to =
      after.facility.improving_infection_control1_to;
  }

  // 障害者支援施設等感染対策向上加算（Ⅱ）のフラグ、開始終了年月日はセットで送信する
  if (
    target.facility.improving_infection_control2_flg !== undefined ||
    target.facility.improving_infection_control2_from !== undefined ||
    target.facility.improving_infection_control2_to !== undefined
  ) {
    target.facility.improving_infection_control2_flg =
      after.facility.improving_infection_control2_flg;
    target.facility.improving_infection_control2_from =
      after.facility.improving_infection_control2_from;
    target.facility.improving_infection_control2_to =
      after.facility.improving_infection_control2_to;
  }

  return addParentValue(target, after);
};

export const normalizeFormValue = (
  state: FacilityValues
): PostFacilityParams => {
  // 新規(id:null)で削除フラグが立っているものは弾く
  const filteredUnits = state.units.filter(
    (unit) => !(unit.id === null && unit.is_deleted)
  );
  const units = filteredUnits.map((unit) => ({
    id: unit.id,
    unit_name: unit.unit_name,
    // 施設入所では未使用なので固定値
    night_support_type_202104: 1,
    // 施設入所では未使用なので固定値
    ave_users_last_fiscal_year: 0,
    is_deleted: unit.is_deleted ? INT_TRUE_FROM_API : INT_FALSE_FROM_API
  }));
  return removeUnnecessaryValue({
    units,
    facility: {
      gov_business_owner: state.basic.corporationName,
      gov_facility_number: state.basic.officeNumber,
      name: state.basic.officeName,
      type_service: state.basic.serviceType,
      responsible_person: state.basic.representativeName,
      capacity:
        state.basic.capacity !== "" ? parseInt(state.basic.capacity, 10) : null,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      tel: state.basic.tel,
      multiple_facility_flg: state.basic.multiFunctionOfficeFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      operating_unit_flg: state.basic.operatingUnitFlag
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      master_subordinate_flg: state.basic.masterSubordinateFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_flg: state.basic.isMasterRadioValue,
      total_capacity:
        state.basic.multiFunctionOfficeFlg && state.basic.allCapacity !== ""
          ? parseInt(state.basic.allCapacity, 10)
          : null,
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: "",
      welfare_condition_end_date: "",
      better_supporter_condition: state.additionalItem.staffTreatmentSystemType,
      lack_of_supporter_flg: state.subtractionItem.lackOfSupporterFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      date_start_lack_of_supporter: state.subtractionItem
        .dateStartLackOfSupporter
        ? selectDateValueToDate(state.subtractionItem.dateStartLackOfSupporter)
        : null,
      abuse_prevention_measures_not_implemented_flg: state.subtractionItem
        .abusePreventionMeasuresNotImplementedFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      abuse_prevention_measures_not_implemented_from:
        state.subtractionItem.abusePreventionMeasuresNotImplementedFrom &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedFrom
            )
          : null,
      abuse_prevention_measures_not_implemented_to:
        state.subtractionItem.abusePreventionMeasuresNotImplementedTo &&
        selectDateValueToDate(
          state.subtractionItem.abusePreventionMeasuresNotImplementedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.abusePreventionMeasuresNotImplementedTo
            )
          : null,
      lack_of_service_admin_flg: "",
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: state.subtractionItem.originLocalGovFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      body_restricted_still_flg: state.subtractionItem.bodyRestrictedStillFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      body_restricted_still_from:
        state.subtractionItem.bodyRestrictedStillFrom &&
        selectDateValueToDate(state.subtractionItem.bodyRestrictedStillFrom)
          ? selectDateValueToDate(state.subtractionItem.bodyRestrictedStillFrom)
          : null,
      body_restricted_still_to:
        state.subtractionItem.bodyRestrictedStillTo &&
        selectDateValueToDate(state.subtractionItem.bodyRestrictedStillTo)
          ? selectDateValueToDate(state.subtractionItem.bodyRestrictedStillTo)
          : null,
      work_continuation_not_planning_flg: state.subtractionItem
        .workContinuationNotPlanningFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      work_continuation_not_planning_from:
        state.subtractionItem.workContinuationNotPlanningFrom &&
        selectDateValueToDate(
          state.subtractionItem.workContinuationNotPlanningFrom
        )
          ? selectDateValueToDate(
              state.subtractionItem.workContinuationNotPlanningFrom
            )
          : null,
      work_continuation_not_planning_to:
        state.subtractionItem.workContinuationNotPlanningTo &&
        selectDateValueToDate(
          state.subtractionItem.workContinuationNotPlanningTo
        )
          ? selectDateValueToDate(
              state.subtractionItem.workContinuationNotPlanningTo
            )
          : null,
      see_hear_team_202404: state.additionalItem.seeHearTeam202404
        ? parseInt(state.additionalItem.seeHearTeam202404, 10)
        : 1,
      see_hear_team_202404_from:
        state.additionalItem.seeHearTeam202404From &&
        selectDateValueToDate(state.additionalItem.seeHearTeam202404From) !== ""
          ? selectDateValueToDate(state.additionalItem.seeHearTeam202404From)
          : null,
      see_hear_team_202404_to:
        state.additionalItem.seeHearTeam202404To &&
        selectDateValueToDate(state.additionalItem.seeHearTeam202404To) !== ""
          ? selectDateValueToDate(state.additionalItem.seeHearTeam202404To)
          : null,
      available_food: state.basic.availableFood
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      better_supporter_condition_base_up_flg: state.additionalItem
        .betterSupporterConditionBaseUpFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      specific_better_supporter_condition:
        state.additionalItem.staffTreatmentSpecificSystemType,
      specific_better_supporter_condition_202404:
        state.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        state.additionalItem.specificBetterSupporterCondition202404Sub
          ? state.additionalItem.specificBetterSupporterCondition202404Sub
          : state.additionalItem.specificBetterSupporterCondition202404,
      higher_brain_dysfunction_persons_support_flg: state.additionalItem
        .higherBrainDysfunctionPersonsSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      higher_brain_dysfunction_persons_support_from:
        state.additionalItem.higherBrainDysfunctionPersonsSupportFrom &&
        selectDateValueToDate(
          state.additionalItem.higherBrainDysfunctionPersonsSupportFrom
        )
          ? selectDateValueToDate(
              state.additionalItem.higherBrainDysfunctionPersonsSupportFrom
            )
          : null,
      higher_brain_dysfunction_persons_support_to:
        state.additionalItem.higherBrainDysfunctionPersonsSupportTo &&
        selectDateValueToDate(
          state.additionalItem.higherBrainDysfunctionPersonsSupportTo
        )
          ? selectDateValueToDate(
              state.additionalItem.higherBrainDysfunctionPersonsSupportTo
            )
          : null,
      information_disclosure_not_reported_flg: state.subtractionItem
        .informationDisclosureNotReportedFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      information_disclosure_not_reported_from:
        state.subtractionItem.informationDisclosureNotReportedFrom &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedFrom
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedFrom
            )
          : null,
      information_disclosure_not_reported_to:
        state.subtractionItem.informationDisclosureNotReportedTo &&
        selectDateValueToDate(
          state.subtractionItem.informationDisclosureNotReportedTo
        ) !== ""
          ? selectDateValueToDate(
              state.subtractionItem.informationDisclosureNotReportedTo
            )
          : null,
      improving_infection_control1_flg: state.additionalItem
        .improvingInfectionControl1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      improving_infection_control1_from:
        state.additionalItem.improvingInfectionControl1From &&
        selectDateValueToDate(
          state.additionalItem.improvingInfectionControl1From
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.improvingInfectionControl1From
            )
          : null,
      improving_infection_control1_to:
        state.additionalItem.improvingInfectionControl1To &&
        selectDateValueToDate(
          state.additionalItem.improvingInfectionControl1To
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.improvingInfectionControl1To
            )
          : null,
      improving_infection_control2_flg: state.additionalItem
        .improvingInfectionControl2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      improving_infection_control2_from:
        state.additionalItem.improvingInfectionControl2From &&
        selectDateValueToDate(
          state.additionalItem.improvingInfectionControl2From
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.improvingInfectionControl2From
            )
          : null,
      improving_infection_control2_to:
        state.additionalItem.improvingInfectionControl2To &&
        selectDateValueToDate(
          state.additionalItem.improvingInfectionControl2To
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.improvingInfectionControl2To
            )
          : null
    },
    facility_shisetsunyusho: {
      food_expenses: castNumber(state.basic.foodExpenses),
      food_expenses_breakfast: castNumber(state.basic.foodExpensesBreakfast),
      food_expenses_lunch: castNumber(state.basic.foodExpensesLunch),
      food_expenses_supper: castNumber(state.basic.foodExpensesSupper),
      food_expenses_day: castNumber(state.basic.foodExpensesDay),
      utility: castNumber(state.basic.utility),
      utility_costs: castNumber(state.basic.utilityCosts),
      nutritionist_placement: castNumber(
        state.subtractionItem.nutritionistPlacement
      ),
      nighttime_placement: castNumber(state.additionalItem.nighttimePlacement),
      nighttime_nurse_count:
        state.additionalItem.nighttimeNurseCount !== ""
          ? castNumber(state.additionalItem.nighttimeNurseCount)
          : 0,
      serious_disability: castNumber(state.additionalItem.seriousDisability),
      regional_life_transition: state.additionalItem.regionalLifeTransition
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      oral_hygiene_management_system_flg: castNumber(
        state.additionalItem.oralHygieneManagementSystemFlg
      ),
      regional_transition_support_flg: state.additionalItem
        .regionalTransitionSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      regional_transition_support_from:
        state.additionalItem.regionalTransitionSupportFrom &&
        selectDateValueToDate(
          state.additionalItem.regionalTransitionSupportFrom
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.regionalTransitionSupportFrom
            )
          : null,
      regional_transition_support_to:
        state.additionalItem.regionalTransitionSupportTo &&
        selectDateValueToDate(
          state.additionalItem.regionalTransitionSupportTo
        ) !== ""
          ? selectDateValueToDate(
              state.additionalItem.regionalTransitionSupportTo
            )
          : null,
      decrease_users_count:
        state.additionalItem.decreaseUsersCount !== ""
          ? castNumber(state.additionalItem.decreaseUsersCount)
          : 0
    }
  });
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues
): PostFacilityParams => {
  return removeNoChangeData(params, normalizeFormValue(response));
};
