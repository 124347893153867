import { ConsultationValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { dateValidator, validateSwitcher } from "@validator";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";

type ConsultationErrors = ValidationErrors<ConsultationValues>;

// 削除済みでなければバリデーションをかける
const isDeleteValidateSwitcher = (
  detail: ConsultationValues["supportPlanConsultationDetails"][0],
  value: string
): Exclude<
  Exclude<ConsultationErrors["supportPlanConsultationDetails"], undefined>[0],
  undefined
>["is_delete"] => {
  return validateSwitcher(
    !detail.is_delete,
    validator(value, {
      type: "checkCharacterLength",
      length: 2500
    })
  );
};

// 計画の詳細バリデーション
export const supportPlanConsultationDetailsValidation = (
  detailValues: ConsultationValues["supportPlanConsultationDetails"]
): ConsultationErrors["supportPlanConsultationDetails"] => {
  return detailValues.map((detail) => {
    return {
      support_goal: isDeleteValidateSwitcher(detail, detail.support_goal),
      collaborator: isDeleteValidateSwitcher(detail, detail.collaborator),
      contents_cooperation: isDeleteValidateSwitcher(
        detail,
        detail.contents_cooperation
      ),
      considerations: isDeleteValidateSwitcher(detail, detail.considerations),
      end_ym: validateSwitcher(
        !detail.is_delete,
        validator(detail.end_ym, {
          type: "futureMonth",
          startTime: new Date(fixDateAndTimeFormat(detail.start_ym)),
          endTime: new Date(fixDateAndTimeFormat(detail.end_ym)),
          options: {
            startLabel: "目安開始年月",
            endLabel: "目安終了年月"
          }
        })
      ),
      users_do: isDeleteValidateSwitcher(detail, detail.users_do)
    };
  });
};

export const validation = (values: ConsultationValues): ConsultationErrors => {
  return {
    creationDate: dateValidator(values.creationDate, "required"),
    attainmentGoal: validator(values.attainmentGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    longTermGoal: validator(values.longTermGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    shortTermGoal: validator(values.shortTermGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    startYm: dateValidator({ ...values.startYm, day: "1" }, "required"),
    supportPlanConsultationDetails: supportPlanConsultationDetailsValidation(
      values.supportPlanConsultationDetails
    ),
    staffComment: validator(values.staffComment, {
      type: "checkCharacterLength",
      length: 2500
    })
  };
};
