import { CityState } from "@stores/domain/city/type";
import { GetSupportPlanUifIdCarePlan } from "@api/requests/carePlan/getSupportPlanUifIdCarePlan";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

/**
 * ActionNames
 */
export const FETCH_SUPPORT_PLAN_CARE_PLAN_STARTED =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_STARTED";
export const FETCH_SUPPORT_PLAN_CARE_PLAN_SUCCESS =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_SUCCESS";
export const FETCH_SUPPORT_PLAN_CARE_PLAN_FAILED =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_FAILED";

export const FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED";
export const FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS";
export const FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED =
  "CAREPLAN/FETCH_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED";

export const UPDATE_SCHEDULES_STARTED = "CAREPLAN/UPDATE_SCHEDULES_STARTED";
export const UPDATE_SCHEDULES_SUCCESS = "CAREPLAN/UPDATE_SCHEDULES_SUCCESS";
export const UPDATE_SCHEDULES_FAILED = "CAREPLAN/UPDATE_SCHEDULES_FAILED";

export const SET_EDIT = "CAREPLAN/SET_EDIT";
export const UNSET_EDIT = "CAREPLAN/UNSET_EDIT";

export const FETCH_CITY_STARTED = "CAREPLAN/FETCH_CITY_STARTED";
export const FETCH_CITY_SUCCESS = "CAREPLAN/FETCH_CITY_SUCCESS";
export const FETCH_CITY_FAILED = "CAREPLAN/FETCH_CITY_FAILED";

export const POST_SUPPORT_PLAN_CARE_PLAN_NEW_STARTED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_NEW_STARTED";
export const POST_SUPPORT_PLAN_CARE_PLAN_NEW_SUCCESS =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_NEW_SUCCESS";
export const POST_SUPPORT_PLAN_CARE_PLAN_NEW_FAILED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_NEW_FAILED";

export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED";
export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS";
export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED";

export const DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_STARTED";
export const DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_SUCCESS";
export const DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_FAILED";

export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_STARTED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_STARTED";
export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_SUCCESS =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_SUCCESS";
export const POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_FAILED =
  "CAREPLAN/POST_SUPPORT_PLAN_CARE_PLAN_CARE_PLAN_ID_DETAILS_FAILED";

export const DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_STARTED =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_STARTED";
export const DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_SUCCESS =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_SUCCESS";
export const DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_FAILED =
  "CAREPLAN/DELETE_SUPPORT_PLAN_CARE_PLAN_SCHEDULE_CARE_PLAN_SCHEDULE_ID_FAILED";

export type CarePlanState = {
  carePlan: GetSupportPlanUifIdCarePlan["data"];
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
  supportCarePlanSchedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  isEditing: boolean;
  city: CityState[] | null;
};
