import { InitialState } from "@stores/domain/mgr/TANKINYUSHO/initial/types";
import { dateToSelectDateValue } from "@utils/date";
import { InitialDataValues } from "@interfaces/mgr/TANKINYUSHO/initial/initialData";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

const initialValues = (state?: InitialState): InitialDataValues => {
  const facility = state && state.facility ? state.facility : {};
  const users = state && state.users ? state.users : [{}];
  const initializedUsers = users.map((user) => {
    const uifTANKINYUSHO = user.users_in_facility_tankinyusho
      ? user.users_in_facility_tankinyusho
      : {};
    return {
      users_in_facility_tankinyusho: {
        short_term_usage_addition_start_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(
              uifTANKINYUSHO.short_term_usage_addition_start_date,
              ""
            )
          )
        ),
        short_term_usage_addition_count: undefinedToReturnValue(
          uifTANKINYUSHO.short_term_usage_addition_count,
          "0"
        )
      }
    };
  });

  return {
    initialData: {
      facility: {
        first_time_bill_date: emptyToNotSelected(
          dateToSelectDateValue(
            undefinedToReturnValue(facility.first_time_bill_date, "")
          )
        ),
        total_number_of_users_1_month_before: undefinedToReturnValue(
          facility.total_number_of_users_1_month_before,
          ""
        ),
        total_number_of_users_2_month_before: undefinedToReturnValue(
          facility.total_number_of_users_2_month_before,
          ""
        ),
        total_number_of_users_3_month_before: undefinedToReturnValue(
          facility.total_number_of_users_3_month_before,
          ""
        )
      },
      users: initializedUsers
    }
  };
};

export default initialValues;
