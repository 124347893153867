/**
 * ActionNames
 */
export const FETCH_ASSISTANCE_REPORT_STARTED =
  "CHIIKIIKO/FETCH_ASSISTANCE_REPORT_STARTED";
export const FETCH_ASSISTANCE_REPORT_SUCCESS =
  "CHIIKIIKO/FETCH_ASSISTANCE_REPORT_SUCCESS";
export const FETCH_ASSISTANCE_REPORT_FAILED =
  "CHIIKIIKO/FETCH_ASSISTANCE_REPORT_FAILED";
// 作成済み計画読み込み
export const COPY_CONSULTATION_STARTED =
  "CONSULTATION/COPY_CONSULTATION_STARTED";
export const COPY_CONSULTATION_SUCCESS =
  "CONSULTATION/COPY_CONSULTATION_SUCCESS";
export const COPY_CONSULTATION_FAILED = "CONSULTATION/COPY_CONSULTATION_FAILED";
// 作成済み計画初期化
export const RESET_COPY_CONSULTATION = "CONSULTATION/RESET_COPY_CONSULTATION";
// 更新
export const POST_ASSISTANCE_REPORT_STARTED =
  "CHIIKIIKO/POST_ASSISTANCE_REPORT_STARTED";
export const POST_ASSISTANCE_REPORT_SUCCESS =
  "CHIIKIIKO/UPDATE_ASSISTANCE_REPORT_SUCCESS";
export const POST_ASSISTANCE_REPORT_FAILED =
  "CHIIKIIKO/POST_ASSISTANCE_REPORT_FAILED";
// 削除
export const DELETE_ASSISTANCE_REPORT_STARTED =
  "CHIIKIIKO/DELETE_ASSISTANCE_REPORT_STARTED";
export const DELETE_ASSISTANCE_REPORT_SUCCESS =
  "CHIIKIIKO/DELETE_ASSISTANCE_REPORT_SUCCESS";
export const DELETE_ASSISTANCE_REPORT_FAILED =
  "CHIIKIIKO/DELETE_ASSISTANCE_REPORT_FAILED";

export type AssistanceReportRecord = {
  id: number | null;
  created_at: string;
  updated_at: string;
  guardian_name: string;
  guardian_relation: string;
  date_of_monitoring: string;
  creation_date: string | null;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
  } | null;
  classify_disability_support: number;
  classify_handicapped_flg: number;
  disabled_welfare_service_recipient_number: string;
  consultation_support_recipient_number: string;
  regular_place_recipient_number: string;
  attainment_goal: string;
  whole_situation: string;
  staff_comment: string;
  details: {
    id: number | null;
    support_goal: string;
    criteria_for_cooperation: string;
    service_provision_status: string;
    personal_satisfaction: string;
    achievement_of_expectations: string;
    future_issues: string;
    other_considerations: string;
  }[];
};

export type ConsultationRecord = {
  id: number;
  createdAt: string;
  updatedAt: string;
  targetFlg: number;
  creationDate: string;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  attainmentGoal: string;
  longTermGoal: string;
  shortTermGoal: string;
  startYm: string;
  endYm: string;
  supportPlanConsultationDetails: {
    support_plan_consultation_details_id: number | null;
    support_goal: string;
    collaborator: string;
    contents_cooperation: string;
    considerations: string;
    start_ym: string;
    end_ym: string;
    users_do: string;
  }[];
  staffComment: string;
};

/**
 * State
 */
export type AssistanceReportState = {
  assistanceReport: AssistanceReportRecord;
  consultationCopy: ConsultationRecord | null;
};
