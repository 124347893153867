import {
  InoutResultsState,
  InoutResultsDetailsState
} from "@stores/domain/mgr/DOKOENGO/report/types";
import {
  InitialValues,
  InoutResultsDetailsFields
} from "@interfaces/mgr/DOKOENGO/report/initial";
import {
  DOKOENGO_INPUT_CLASS_LIST,
  DOKOENGO_MEMBER_LIST,
  DOKOENGO_BASE_LIST,
  DOKOENGO_ACTION_CLASS_LIST,
  DOKOENGO_LICENSE_LIST
} from "@constants/mgr/DOKOENGO/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";

export type InitialDataValues = InitialValues;

export const inoutResultsDetailsInitialValue = {
  id: null,
  actionClass: DOKOENGO_ACTION_CLASS_LIST.DRIVE.value,
  inTime: "",
  inTimeClass: "0",
  outTime: "",
  outTimeClass: "0",
  time: 0
};

const inoutResultsDetailsFormatValue = (
  detail: InoutResultsDetailsState[]
): InoutResultsDetailsFields[] => {
  return detail.map((record) => {
    return {
      id: record.id,
      actionClass: record.actionClass
        ? record.actionClass.toString()
        : DOKOENGO_ACTION_CLASS_LIST.DRIVE.value,
      inTimeClass: record.inTimeClass ? record.inTimeClass : "0",
      inTime: record.inTime ? record.inTime : "",
      outTimeClass: record.outTimeClass ? record.outTimeClass : "0",
      outTime: record.outTime ? record.outTime : "",
      time: calculateForRequiredTime(
        record.inTime,
        record.inTimeClass,
        record.outTime,
        record.outTimeClass
      )
    };
  });
};

// デフォルト値設定
export const initialValues = (
  state: InoutResultsState | null = null,
  date = ""
): InitialDataValues => {
  const details1 =
    state && state.inoutResultsDetails1
      ? inoutResultsDetailsFormatValue(state.inoutResultsDetails1)
      : [];

  const details2 =
    state && state.inoutResultsDetails2
      ? inoutResultsDetailsFormatValue(state.inoutResultsDetails2)
      : [];

  const targetDateState = state && state.targetDate ? state.targetDate : date;
  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      id: state && state.id ? state.id : null,
      inoutResultDailyId: state ? state.inoutResultDailyId : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      targetDate: targetDateState,
      inputClass:
        state && state.inputClass
          ? state.inputClass.toString()
          : DOKOENGO_INPUT_CLASS_LIST.PLAN.value,
      inoutResultsPlanId:
        state && state.inoutResultsPlanId ? state.inoutResultsPlanId : null,
      numberOfParticipants:
        state && state.numberOfParticipants
          ? state.numberOfParticipants.toString()
          : DOKOENGO_MEMBER_LIST.ONE.value,
      practitioner1:
        state && state.practitioner1
          ? state.practitioner1.toString()
          : DOKOENGO_BASE_LIST.NONE.value,
      practitioner1License:
        state && state.practitioner1License
          ? state.practitioner1License.toString()
          : DOKOENGO_LICENSE_LIST.NEWCOMER.value,
      practitioner1InTimeClass: "当日",
      practitioner1InTime:
        state && state.practitioner1InTime ? state.practitioner1InTime : "",
      practitioner1OutTimeClass:
        state && state.practitioner1OutTimeClass
          ? state.practitioner1OutTimeClass
          : "0",
      practitioner1OutTime:
        state && state.practitioner1OutTime ? state.practitioner1OutTime : "",
      practitioner1CalculatedHours:
        state && state.practitioner1CalculatedHours !== null
          ? state.practitioner1CalculatedHours.toString()
          : "0",
      practitioner1Memo:
        state && state.practitioner1Memo ? state.practitioner1Memo : "",
      inoutResultsDetails1: details1,
      practitioner2:
        state && state.practitioner2
          ? state.practitioner2.toString()
          : DOKOENGO_BASE_LIST.NONE.value,
      practitioner2License:
        state && state.practitioner2License
          ? state.practitioner2License.toString()
          : DOKOENGO_LICENSE_LIST.NEWCOMER.value,
      practitioner2InTimeClass:
        state && state.practitioner2InTimeClass
          ? state.practitioner2InTimeClass
          : "0",
      practitioner2InTime:
        state && state.practitioner2InTime ? state.practitioner2InTime : "",
      practitioner2OutTimeClass:
        state && state.practitioner2OutTimeClass
          ? state.practitioner2OutTimeClass
          : "0",
      practitioner2OutTime:
        state && state.practitioner2OutTime ? state.practitioner2OutTime : "",
      practitioner2CalculatedHours:
        state && state.practitioner2CalculatedHours !== null
          ? state.practitioner2CalculatedHours.toString()
          : "",
      practitioner2Memo:
        state && state.practitioner2Memo ? state.practitioner2Memo : "",
      inoutResultsDetails2: details2,
      timeDuplicationJudgmentFlg:
        state && state.timeDuplicationJudgmentFlg
          ? state.timeDuplicationJudgmentFlg
          : false,
      wholeCalculatedHours:
        state && state.wholeCalculatedHours !== null
          ? state.wholeCalculatedHours.toString()
          : "",
      duplicateCalculatedHours:
        state && state.duplicateCalculatedHours !== null
          ? state.duplicateCalculatedHours.toString()
          : "",
      licenseSameFlg:
        state && state.licenseSameFlg ? state.licenseSameFlg : false,
      secondPersonFlg:
        state && state.secondPersonFlg ? state.secondPersonFlg : false,
      emergencySupportFlg:
        state && state.emergencySupportFlg ? state.emergencySupportFlg : false,
      emergencySupportFlgError: "",
      sputumImplementationFlg:
        state && state.sputumImplementationFlg
          ? state.sputumImplementationFlg
          : false,
      firstAdditionFlg:
        state && state.firstAdditionFlg ? state.firstAdditionFlg : false,
      createFlg: !state,
      resultExistFlg:
        state && state.resultExistFlg ? state.resultExistFlg : false
    }
  };
};
