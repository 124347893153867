import { BasicValues } from "@interfaces/mgr/GroupHome/facility/basic";
import { SubtractionItemValues } from "@interfaces/mgr/GroupHome/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/mgr/GroupHome/facility/additionItem";
import { UnitsValues } from "@interfaces/mgr/GroupHome/facility/units";
import { FacilityState } from "@stores/domain/mgr/GroupHome/facility/types";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import get from "lodash-es/get";
import { initializeSpecificBetterSupporterCondition202404V } from "@utils/domain/facility/initializeSpecificBetterSupporterCondition202404V";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues &
  UnitsValues;

// ユニットの初期値
export const unitInitialValue = {
  id: null,
  unit_name: "",
  night_support_type: "1",
  ave_users_last_fiscal_year: "0",
  subtraction_of_large_scale_housing_type: "0",
  is_deleted: false
};

const initialValues = (state?: FacilityState): FacilityValues => {
  // ユニットは複数 ≒ 必ず2件以上
  const unitsValue =
    state && state.units.length > 0
      ? state.units
      : [unitInitialValue, unitInitialValue];

  return {
    basic: {
      corporationName: get(state, "corporationName") || "",
      officeNumber: get(state, "officeNumber") || "",
      officeName: get(state, "officeName") || "",
      serviceType: get(state, "serviceType") || "",
      groupHomeType: `${get(state, "groupHomeType") || ""}`,
      representativeName: get(state, "representativeName") || "",
      capacity: get(state, "capacity") || "",
      multiFunctionOfficeFlag: get(state, "multiFunctionOfficeFlag") || false,
      postalCode: get(state, "postalCode") || "",
      prefectureId: get(state, "selectedPrefectureName") || "",
      cityId: get(state, "selectedCityCode") || "",
      restAddress: get(state, "restAddress") || "",
      tel: get(state, "tel") || "",
      operatingUnitFlag: get(state, "operatingUnitFlag") || false
    },
    subtractionItem: {
      establishedByLocalGovernmentsFlag: state
        ? state.establishedByLocalGovernmentsFlag
        : false,
      lackFlag: state
        ? state.lackOfLifeSupportMemberFlag || state.lackOfResponsiblePersonFlag
        : false,
      lackOfLifeSupportMemberFlag: state
        ? state.lackOfLifeSupportMemberFlag
        : false,
      lackOfLifeSupportMemberStartDate:
        state && state.lackOfLifeSupportMemberStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfLifeSupportMemberStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }, // APIの仕様上dayは1固定
      lackOfResponsiblePersonFlag: state
        ? state.lackOfResponsiblePersonFlag
        : false,
      lackOfResponsiblePersonStartDate:
        state && state.lackOfResponsiblePersonStartDate
          ? dateToSelectDateValueYYYYM(state.lackOfResponsiblePersonStartDate)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }, // APIの仕様上dayは1固定
      subtractionOfLargeScaleHousingFlag: !!(
        state && state.subtractionOfLargeScaleHousing !== "0"
      ),
      subtractionOfLargeScaleHousing:
        state && state.subtractionOfLargeScaleHousing !== "0"
          ? state.subtractionOfLargeScaleHousing
          : "1",
      bodyRestrictedStillFlg: state ? state.bodyRestrictedStillFlg : false,
      bodyRestrictedStillFrom: initializeSelectDate(
        state && state.bodyRestrictedStillFrom
      ),
      bodyRestrictedStillTo: initializeSelectDate(
        state && state.bodyRestrictedStillTo
      ),
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      workContinuationNotPlanningFlg: state
        ? state.workContinuationNotPlanningFlg
        : false,
      workContinuationNotPlanningFrom: initializeSelectDate(
        state && state.workContinuationNotPlanningFrom
      ),
      workContinuationNotPlanningTo: initializeSelectDate(
        state && state.workContinuationNotPlanningTo
      ),
      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      )
    },
    additionalItem: {
      staffPlacementType: state ? state.staffPlacementType : "",
      welfareSpecialistPlacementType: state
        ? state.welfareSpecialistPlacementType
        : "",
      welfareConditionStartDate: initializeSelectDate(
        state && state.welfareConditionStartDate
      ),
      welfareConditionEndDate: initializeSelectDate(
        state && state.welfareConditionEndDate
      ),
      specificBetterSupporterCondition202404: state
        ? initializeSpecificBetterSupporterCondition202404V(
            state.specificBetterSupporterCondition202404
          )
        : NO_SELECTED_CONDITION_TYPE_2024,
      specificBetterSupporterCondition202404Sub:
        state && state.specificBetterSupporterCondition202404
          ? state.specificBetterSupporterCondition202404
          : NO_SELECTED_CONDITION_TYPE_2024_SUB,
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "",
      nursingStaffPlacementSystemFlag: state
        ? state.nursingStaffPlacementSystemFlag
        : "",
      nightStaffAllocationSystemFlag: state
        ? state.nightStaffAllocationSystemFlag
        : false,
      commuterLifeSupportFlag: state ? state.commuterLifeSupportFlag : false,
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false,
      nightSupportFlag: get(state, "nightSupportFlag") || false,
      nightSupportType: get(state, "nightSupportType") || "",
      averageUsersLastYear: get(state, "averageUsersLastYear") || "0",
      seeHearTeam202404: state ? state.seeHearTeam202404 : "1",
      seeHearTeam202404From: initializeSelectDate(
        state && state.seeHearTeam202404From
      ),
      seeHearTeam202404To: initializeSelectDate(
        state && state.seeHearTeam202404To
      ),
      higherBrainDysfunctionPersonsSupportFlg: state
        ? state.higherBrainDysfunctionPersonsSupportFlg
        : false,
      higherBrainDysfunctionPersonsSupportFrom: initializeSelectDate(
        state && state.higherBrainDysfunctionPersonsSupportFrom
      ),
      higherBrainDysfunctionPersonsSupportTo: initializeSelectDate(
        state && state.higherBrainDysfunctionPersonsSupportTo
      ),
      personnelDistributionType: state ? state.personnelDistributionType : "",
      improvingInfectionControl1Flg: state
        ? state.improvingInfectionControl1Flg
        : false,
      improvingInfectionControl1From: initializeSelectDate(
        state && state.improvingInfectionControl1From
      ),
      improvingInfectionControl1To: initializeSelectDate(
        state && state.improvingInfectionControl1To
      ),
      improvingInfectionControl2Flg: state
        ? state.improvingInfectionControl2Flg
        : false,
      improvingInfectionControl2From: initializeSelectDate(
        state && state.improvingInfectionControl2From
      ),
      improvingInfectionControl2To: initializeSelectDate(
        state && state.improvingInfectionControl2To
      )
    },
    units: unitsValue
  };
};

export default initialValues;
