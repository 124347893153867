import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Button, FormGroup, Theme } from "@material-ui/core";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import FormikTextField from "@components/molecules/FormikTextField";
import {
  Add as AddIcon,
  DeleteOutline as DeleteIcon
} from "@material-ui/icons";
import MuiTextField from "@components/molecules/MuiTextField";

// store
import { connect } from "react-redux";
import {
  DAY_SELECT_OPTIONS,
  MEMBER_LIST_SERVICE_DETAIL
} from "@constants/variables";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import {
  otherPractitionersInitialValue,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { FieldItem } from "@interfaces/ui/form";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      border: "1px solid #bdbdbd",
      padding: "30px 30px",
      borderRadius: "4px",
      marginBottom: "48px",
      backgroundColor: "#fafafa"
    },
    border: {
      color: "#e0e0e0",
      borderBottom: "1px solid"
    },
    iconButton: {
      width: 44,
      minWidth: 44,
      textAlign: "center",
      padding: 0,
      paddingTop: 24
    },
    iconButtonBase: {
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    },
    MB32: {
      marginBottom: spacing.unit * 4
    }
  });

type OwnProps = {
  staffOptions: FieldItem[];
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryFormCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { staffOptions, formikProps } = props;
  const {
    supportProcedureFormPractitioners1,
    supportProcedureFormPractitioners2,
    supportProcedureFormOtherPractitioners,
    numberOfPractitioner
  } = formikProps.values;

  const maxLength = 20;
  const [isMaxLength, setIsMaxLength] = React.useState(false);
  const [
    selectedNumberOfPractitioner,
    setSelectedNumberOfPractitioner
  ] = React.useState(1);
  React.useEffect(() => {
    setSelectedNumberOfPractitioner(+numberOfPractitioner);
  }, [numberOfPractitioner]);

  React.useEffect(() => {
    setIsMaxLength(supportProcedureFormOtherPractitioners.length >= maxLength);
  }, [supportProcedureFormOtherPractitioners]);

  // サービス提供者選択肢作成（スナップショット）
  const practitionerOptions1 = createSnapshotOptions(
    staffOptions,
    supportProcedureFormPractitioners1.practitionerSnapshot
  );

  const practitionerOptions2 = createSnapshotOptions(
    staffOptions,
    supportProcedureFormPractitioners2.practitionerSnapshot
  );

  // サービス提供人数（自事業所）変更時処理
  const onChangeNumberOfPractitioner = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setSelectedNumberOfPractitioner(Number(e.target.value));
  };

  return (
    <div className={classes.wrapper}>
      <FormikSelect
        name="numberOfPractitioner"
        label="サービス提供人数（自事業所）"
        options={MEMBER_LIST_SERVICE_DETAIL}
        onChangeHook={onChangeNumberOfPractitioner}
      />
      <FormGroup row style={{ display: "flex", alignItems: "flex-start" }}>
        <FormikSelect
          name="supportProcedureFormPractitioners1.practitionerId"
          label="サービス提供者1"
          options={practitionerOptions1}
          isSelectablePlaceholder
          placeholder="選択してください"
        />
        <MuiTextField
          value={DAY_SELECT_OPTIONS[0].label}
          label="開始時間"
          disabled
          disabledStyle
          style={{ width: 80 }}
        />
        <FormikTime
          name="supportProcedureFormPractitioners1.startTime"
          label=""
          placeholder="00:00"
          size="smallMedium"
          style={{ marginTop: 16, marginRight: 24 }}
          error={
            formikProps.errors &&
            formikProps.errors.supportProcedureFormPractitioners1 &&
            !!formikProps.errors.supportProcedureFormPractitioners1.startTime
          }
        />
        <FormikSelect
          name="supportProcedureFormPractitioners1.endTimeDate"
          label="終了時間"
          options={DAY_SELECT_OPTIONS}
          style={{ width: 80 }}
          error={
            formikProps.errors &&
            formikProps.errors.supportProcedureFormPractitioners1 &&
            !!formikProps.errors.supportProcedureFormPractitioners1.endTimeDate
          }
          noerrortext
        />
        <FormikTime
          name="supportProcedureFormPractitioners1.endTime"
          label=""
          placeholder="00:00"
          size="smallMedium"
          style={{ marginTop: 16, marginRight: 24 }}
          error={
            formikProps.errors &&
            formikProps.errors.supportProcedureFormPractitioners1 &&
            !!formikProps.errors.supportProcedureFormPractitioners1.endTime
          }
        />
      </FormGroup>
      {selectedNumberOfPractitioner === 2 && (
        <FormGroup row style={{ display: "flex", alignItems: "flex-start" }}>
          <FormikSelect
            name="supportProcedureFormPractitioners2.practitionerId"
            label="サービス提供者2"
            options={practitionerOptions2}
            isSelectablePlaceholder
            placeholder="選択してください"
          />
          <FormikSelect
            name="supportProcedureFormPractitioners2.startTimeDate"
            label="開始時間"
            options={DAY_SELECT_OPTIONS}
            style={{ width: 80 }}
            error={
              formikProps.errors &&
              formikProps.errors.supportProcedureFormPractitioners2 &&
              !!formikProps.errors.supportProcedureFormPractitioners2
                .startTimeDate
            }
            noerrortext
          />
          <FormikTime
            name="supportProcedureFormPractitioners2.startTime"
            label=""
            placeholder="00:00"
            size="smallMedium"
            style={{ marginTop: 16, marginRight: 24 }}
            error={
              formikProps.errors &&
              formikProps.errors.supportProcedureFormPractitioners2 &&
              !!formikProps.errors.supportProcedureFormPractitioners2.startTime
            }
          />
          <FormikSelect
            name="supportProcedureFormPractitioners2.endTimeDate"
            label="終了時間"
            options={DAY_SELECT_OPTIONS}
            style={{ width: 80 }}
            error={
              formikProps.errors &&
              formikProps.errors.supportProcedureFormPractitioners2 &&
              !!formikProps.errors.supportProcedureFormPractitioners2
                .endTimeDate
            }
            noerrortext
          />
          <FormikTime
            name="supportProcedureFormPractitioners2.endTime"
            label=""
            placeholder="00:00"
            size="smallMedium"
            style={{ marginTop: 16, marginRight: 24 }}
            error={
              formikProps.errors &&
              formikProps.errors.supportProcedureFormPractitioners2 &&
              !!formikProps.errors.supportProcedureFormPractitioners2.endTime
            }
          />
        </FormGroup>
      )}
      <div className={`${classes.border} ${classes.MB32}`} />
      <FieldArray name="supportProcedureFormOtherPractitioners">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          const onClickAdd = (): void => {
            arrayHelpers.push(otherPractitionersInitialValue);
          };
          const onClickDelete = (index: number): void => {
            // arrayHelpers.remove(index) を使用していたが、バリデーションエラーがある際に落ちるためsetFieldValueで代替
            // formik issue: https://github.com/jaredpalmer/formik/issues/1158
            formikProps.setFieldValue(
              "supportProcedureFormOtherPractitioners",
              supportProcedureFormOtherPractitioners.filter(
                (_, i) => i !== index
              )
            );
          };
          return (
            <>
              {supportProcedureFormOtherPractitioners.length !== 0 ? (
                supportProcedureFormOtherPractitioners.map((_, i) => {
                  // Typescriptの型判定のための処理
                  let isOtherPractitionersError = false;
                  let isPractitionerNameError = false;
                  let isStartTimeDateError = false;
                  let isStartTimeError = false;
                  let isEndTimeDate = false;
                  let isEndTime = false;
                  if (
                    formikProps.errors &&
                    formikProps.errors.supportProcedureFormOtherPractitioners
                  ) {
                    // そのまま書くと型エラーとなるため変数に入れて使用する
                    const otherPractitioner =
                      formikProps.errors.supportProcedureFormOtherPractitioners[
                        i
                      ];
                    if (otherPractitioner) {
                      isOtherPractitionersError = !!otherPractitioner.facilityName;
                      isPractitionerNameError = !!otherPractitioner.practitionerName;
                      isStartTimeDateError = !!otherPractitioner.startTimeDate;
                      isStartTimeError = !!otherPractitioner.startTime;
                      isEndTimeDate = !!otherPractitioner.endTimeDate;
                      isEndTime = !!otherPractitioner.endTime;
                    }
                  }
                  const index = `supportProcedureFormOtherPractitionersRow${i}`;
                  return (
                    <FormGroup
                      row
                      key={index}
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <FormikTextField
                        name={`supportProcedureFormOtherPractitioners[${i}].facilityName`}
                        label="事業所名"
                        placeholder=""
                        style={{ maxWidth: "240px" }}
                        error={isOtherPractitionersError}
                        multiline
                      />
                      <FormikTextField
                        name={`supportProcedureFormOtherPractitioners[${i}].practitionerName`}
                        label="サービス提供者"
                        placeholder=""
                        style={{ maxWidth: "240px" }}
                        error={isPractitionerNameError}
                        multiline
                      />
                      <FormikSelect
                        name={`supportProcedureFormOtherPractitioners[${i}].startTimeDate`}
                        label="開始時間"
                        options={DAY_SELECT_OPTIONS}
                        style={{ maxWidth: "80px" }}
                        error={isStartTimeDateError}
                      />
                      <FormikTime
                        name={`supportProcedureFormOtherPractitioners[${i}].startTime`}
                        label=""
                        placeholder="00:00"
                        size="smallMedium"
                        style={{
                          marginTop: 16,
                          marginRight: 24,
                          maxWidth: "80px"
                        }}
                        error={isStartTimeError}
                      />
                      <FormikSelect
                        name={`supportProcedureFormOtherPractitioners[${i}].endTimeDate`}
                        label="終了時間"
                        options={DAY_SELECT_OPTIONS}
                        style={{ maxWidth: "80px" }}
                        error={isEndTimeDate}
                      />
                      <FormikTime
                        name={`supportProcedureFormOtherPractitioners[${i}].endTime`}
                        label=""
                        placeholder="00:00"
                        size="smallMedium"
                        style={{
                          marginTop: 16,
                          marginRight: 24,
                          maxWidth: "80px"
                        }}
                        error={isEndTime}
                      />
                      <div className={classes.iconButton}>
                        <DeleteIcon
                          className={classes.iconButtonBase}
                          onClick={(): void => onClickDelete(i)}
                        />
                      </div>
                    </FormGroup>
                  );
                })
              ) : (
                <></>
              )}
              <Button
                color="secondary"
                onClick={onClickAdd}
                disabled={isMaxLength}
              >
                <AddIcon />
                他事業所のサービス提供者を追加する
              </Button>
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const ServiceDeliveryForm = withStyles(styles)(
  connect(null)(ServiceDeliveryFormCore)
);
