import * as React from "react";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import OfficeField from "@components/organisms/mgr/common/facility/items/OfficeField";
import ServiceTypeField from "@components/organisms/mgr/common/facility/items/ServiceTypeField";
import { FacilityValues } from "@initialize/mgr/CHIIKIIKO/facility/initialValues";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";

type Props = {
  isFetchDone: boolean;
  serviceType: string;
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

export const BasicFields = (props: Props): JSX.Element => {
  const [shouldFirstSetup, setShouldFirstSetup] = React.useState<boolean>(true);
  const [masterSubordinateFlg, setMasterSubordinateFlg] = React.useState<
    boolean
  >(props.formikProps.values.basic.masterSubordinateFlg);

  React.useEffect(() => {
    if (!shouldFirstSetup || !props.isFetchDone) return;

    const { basic } = props.formikProps.values;
    setShouldFirstSetup(false);
    setMasterSubordinateFlg(basic.masterSubordinateFlg);
  }, []);

  /**
   * 主従たる事業所の値が変更されたら、checkboxの値を更新
   */
  const onChangeMasterSubordinateFlg = (): void => {
    // 主従たる事業所のcheckboxがONからOFFになるときラジオボタンを初期化
    if (
      masterSubordinateFlg &&
      props.formikProps.values.basic.masterFlg === "0"
    ) {
      props.setFormikFieldValue("basic.masterFlg", "1");
    }

    props.setFormikFieldValue(
      "basic.masterSubordinateFlg",
      !masterSubordinateFlg
    );
    setMasterSubordinateFlg(!masterSubordinateFlg);
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="基本情報" />
      </div>
      <FormikTextField
        name="basic.corporationName"
        label="法人名"
        placeholder="株式会社ノウビー"
        maxLength={255}
        size="superLong"
      />
      <OfficeField />
      <FormGroup row>
        <ServiceTypeField serviceType={props.serviceType} />
      </FormGroup>
      <FormikTextField
        name="basic.representativeName"
        label="代表職員氏名"
        placeholder="山田太郎"
        maxLength={20}
        size="superLong"
      />
      <FormGroup>
        <FormikSwitch
          name="basic.masterSubordinateFlg"
          label="主従たる事業所（一体的な運営を行なっている）"
          onChange={onChangeMasterSubordinateFlg}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="satelliteTypeFlagSEIKATSUKAIGO" />}
            />
          }
        />
        {props.formikProps.values.basic.masterSubordinateFlg && (
          <FormikRadioButtons
            name="basic.masterFlg"
            label=""
            options={[
              { label: "主たる事業所", value: "1", disabled: false },
              {
                label: "従たる事業所",
                value: "0",
                disabled: props.formikProps.values.basic.multiFunctionOfficeFlag
              }
            ]}
            style={{ marginTop: -20, marginLeft: 32, display: "inline" }}
          />
        )}
      </FormGroup>
      <FormGroup>
        <FormikSwitch
          name="basic.multiFunctionOfficeFlag"
          label="多機能型事業所"
          disabled={
            props.formikProps.values.basic.masterSubordinateFlg &&
            props.formikProps.values.basic.masterFlg === "0"
          }
        />
        {props.formikProps.values.basic.multiFunctionOfficeFlag && (
          <FormikTextField
            name="basic.allCapacity"
            label="総利用定員数"
            placeholder="0"
            endAdornmentLabel="人"
            style={{ marginTop: -20, marginLeft: 32 }}
          />
        )}
      </FormGroup>
      <FormikPostalCode
        name="basic.postalCode"
        label="郵便番号"
        placeholder="000-0000"
        maxLength={8}
        startAdornmentLabel="〒"
      />
      <FormikAddress
        prefectureIdName="basic.prefectureId"
        cityIdName="basic.cityId"
        formikProps={props.formikProps}
        showRegionType={false}
        showRegionType202404
      />
      <FormikTextField
        name="basic.restAddress"
        label="市区町村以降の住所"
        size="superLong"
      />
      <FormikTextField
        name="basic.tel"
        type="tel"
        label="電話番号"
        maxLength={12}
        placeholder="0000000000"
        helperText="ハイフンなしで入力"
        style={{ marginBottom: 0 }}
      />
    </FormPaper>
  );
};
