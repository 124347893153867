/**
 * ActionNames
 */
export const FETCH_DOKOENGO_CARE_PLAN_STARTED =
  "DOKOENGO/202104/FETCH_CARE_PLAN_STARTED";
export const FETCH_DOKOENGO_CARE_PLAN = "DOKOENGO/202104/FETCH_CARE_PLAN";
export const FETCH_DOKOENGO_CARE_PLAN_FAILED =
  "DOKOENGO/202104/FETCH_CARE_PLAN_FAILED";

export const POST_DOKOENGO_CARE_PLAN_STARTED =
  "DOKOENGO/202104/POST_CARE_PLAN_STARTED";
export const POST_DOKOENGO_CARE_PLAN = "DOKOENGO/202104/POST_CARE_PLAN";
export const POST_DOKOENGO_CARE_PLAN_FAILED =
  "DOKOENGO/202104/POST_CARE_PLAN_FAILED";

export const DELETE_DOKOENGO_CARE_PLAN_STARTED =
  "DOKOENGO/202104/DELETE_CARE_PLAN_STARTED";
export const DELETE_DOKOENGO_CARE_PLAN = "DOKOENGO/202104/DELETE_CARE_PLAN";
export const DELETE_DOKOENGO_CARE_PLAN_FAILED =
  "DOKOENGO/202104/DELETE_CARE_PLAN_FAILED";

export type CarePlanDetailState = {
  id: number | null;
  actionClass: number | null;
  inTimeClass: string;
  inTime: string | null;
  outTimeClass: string;
  outTime: string | null;
};

export type CarePlanState = {
  id: number;
  usersInFacilityId: number;
  dayOfWeek: number | null;
  nameSei: string;
  nameMei: string;
  numberOfParticipants: number | null;
  practitioner1: number | null;
  practitioner1License: number | null;
  practitioner1InTime: string;
  practitioner1OutTimeClass: string;
  practitioner1OutTime: string;
  practitioner1CalculatedHours: number | null;
  practitioner1Memo: string | null;
  carePlanDetailState1: CarePlanDetailState[];
  practitioner2: number | null;
  practitioner2License: number | null;
  practitioner2InTimeClass: string;
  practitioner2InTime: string;
  practitioner2OutTimeClass: string;
  practitioner2OutTime: string;
  practitioner2CalculatedHours: number | null;
  practitioner2Memo: string | null;
  carePlanDetailState2: CarePlanDetailState[];
  timeDuplicationJudgmentFlg: boolean;
  wholeCalculatedHours: number | null;
  duplicateCalculatedHours: number | null;
  licenseSameFlg: boolean;
  secondPersonFlg: boolean;
  sputumImplementationFlg: boolean;
};

export type DisplayListState = {
  usersInFacilityId: number;
  nameSei: string;
  nameMei: string;
  dayOfWeek: number;
  dataList: DataListState[];
};

export type DisplayState = {
  dispatchNo: number | null;
  status: number | null;
  inTime: string | null;
  outTime: string | null;
  calculatedHours: number | null;
  numberOfParticipants: number | null;
};

export type DataListState = {
  no: number | null;
  id: number | null;
  plan: DisplayState;
  planDetail: CarePlanState;
};

export type StaffsInFacilityState = {
  id: number;
  name: string;
  role: string | null;
  license: number;
};

export type DisplayStaffsInFacilityState = {
  label: string;
  value: string;
  license: string;
};

export type CarePlanStaffState = {
  staffsInFacility: StaffsInFacilityState[];
  displayStaffsInFacility: DisplayStaffsInFacilityState[];
  displayList: DisplayListState[];
  carePlanCount: number;
};

export type DeleteCarePlanState = {
  uifId: string;
  users_in_facility_care_plan_id: number;
};
