import { InquiryFields } from "@interfaces/mgr/KEIKAKUSODAN/record/inquiry";
import { FieldItem } from "@interfaces/ui/form";
import { InquiryState } from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/types";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { dateToObject, dateToSelectDateValue } from "@utils/date";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";
import { get } from "lodash-es";

export type InquiryValues = InquiryFields;

const inquiryInitialValue = (typeConsultation?: number): InquiryValues => {
  return {
    support_inquiries_id: null,
    creation_date: dateToObject(new Date()),
    creation_staff: "",
    why_not: "",
    inquiry_purpose: "",
    inquiry_destination: "",
    inquiry_date: dateToObject(new Date()),
    inquiry_content: "",
    respondent_name: "",
    response_date: dateToObject(new Date()),
    response_content: "",
    type_consultation: typeConsultation,
    created_at: null,
    updated_at: null
  };
};

export const initialValues = (
  state: InquiryState["inquiry"],
  staffOptions: FieldItem[],
  option?: {
    isNew?: boolean;
    typeConsultation?: number;
  }
): InquiryValues => {
  if (!state || (option && option.isNew)) {
    const values = inquiryInitialValue(option && option.typeConsultation);
    return values;
  }

  const inquiry = state || {};

  const creationStaffId = getStaffWithSnap(state.creation_staff, staffOptions);
  const inquiryDate = get(inquiry, "inquiry_date");
  const responseDate = get(inquiry, "response_date");

  return {
    support_inquiries_id: get(inquiry, "support_inquiries_id"),
    creation_date: dateToSelectDateValue(
      undefinedToReturnValue(get(inquiry, "creation_date"))
    ),
    creation_staff: creationStaffId || "",
    why_not: undefinedToReturnValue(get(inquiry, "why_not")),
    inquiry_purpose: undefinedToReturnValue(get(inquiry, "inquiry_purpose")),
    inquiry_destination: undefinedToReturnValue(
      get(inquiry, "inquiry_destination")
    ),
    inquiry_date: dateToSelectDateValue(
      undefinedToReturnValue(inquiryDate === "0000-00-00" ? null : inquiryDate)
    ),
    inquiry_content: undefinedToReturnValue(get(inquiry, "inquiry_content")),
    respondent_name: undefinedToReturnValue(get(inquiry, "respondent_name")),
    response_date: dateToSelectDateValue(
      undefinedToReturnValue(
        responseDate === "0000-00-00" ? null : responseDate
      )
    ),
    response_content: undefinedToReturnValue(get(inquiry, "response_content")),
    type_consultation: get(inquiry, "type_consultation"),
    created_at: undefinedToReturnValue(get(state, "created_at")),
    updated_at: undefinedToReturnValue(get(state, "updated_at"))
  };
};
