import { Dispatch } from "redux";
import * as action from "./action";
import dispatches from "@stores/dispatches";
import * as loadingActions from "@stores/loading/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import { PostUpdateAssociatedFacilityParams } from "@api/requests/user/postUpdateAssociatedFacility";
import userApi from "@api/requests/user";
import { getFacilityId, setFacilityId } from "@utils/localStorage";
import { BASE_PUBLIC_URL } from "@config";
import * as H from "history";
import {
  REPORT,
  REPORT_201910,
  REPORT_DAILY,
  REPORT_DAILY_201910
} from "@constants/url";

export const me = (dispatch: Dispatch) => async (): Promise<void> => {
  const uiDispatches = dispatches.uiDispatch(dispatch);
  dispatch(action.me.started());
  uiDispatches.loading(true);
  await userApi
    .getUser()
    .then((res) => {
      dispatch(action.me.done({ result: res.data.data }));
      if (!getFacilityId()) {
        setFacilityId(res.data.data.origin_facility_id);
      }

      uiDispatches.loading(false);
    })
    .catch((e) => {
      dispatch(action.me.failed({ error: e.response }));
      uiDispatches.responseError(e.response);
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
      uiDispatches.loading(false);
    });
};

/**
 * サイドメニューの法人名と事業所名を更新する
 * @param dispatch
 */
const setFacilityValuesToUser = (dispatch: Dispatch) => (
  facilityName: string,
  businessOwner: string,
  multiFunctionOfficeFlag = false,
  masterSubordinateFlg = false
): void => {
  dispatch(
    action.setFacilityValuesToUser({
      facilityName,
      businessOwner,
      multiFunctionOfficeFlag,
      masterSubordinateFlg
    })
  );
};

/**
 * ログインユーザー所属事業所情報更新
 */
export const updateAssociatedFacility = (dispatch: Dispatch) => async (
  value: PostUpdateAssociatedFacilityParams["facility_id"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(action.updateAssociatedFacility());
  await userApi
    .postUpdateAssociatedFacility(value)
    .then(() => {
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatches.userDispatch(dispatch).me();
    })
    .catch((e) => {
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー", // TODO fix
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 事業所切り替え
 */
export const switchFacility = (dispatch: Dispatch) => async (
  facility_id: number,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  await userApi
    .getFacilitySwitchApproval(facility_id)
    .then(() => {
      // 認証が成功したら、選択事業所のfacility_idをLocal Storageに保存する
      setFacilityId(facility_id);
      // トップ画面（利用実績画面）に遷移する。
      // UserAPIを含む全てのAPIを初期呼び出ししたいため、リロードをかける
      // トップ画面で事業所切り替えを行った場合は履歴を残さない（ブラウザバック時に同画面に遷移しない）
      if (
        history.location.pathname === REPORT_DAILY ||
        history.location.pathname === REPORT_DAILY_201910 ||
        history.location.pathname === REPORT ||
        history.location.pathname === REPORT_201910
      ) {
        window.location.replace(BASE_PUBLIC_URL);
        return;
      }

      window.location.href = BASE_PUBLIC_URL;
    })
    .catch((e) => {
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  me: () => Promise<void>;
  switchFacility: (facility_id: number, history: H.History) => Promise<void>;
  setFacilityValuesToUser: (
    facilityName: string,
    businessOwner: string,
    multiFunctionOfficeFlag?: boolean,
    masterSubordinateFlg?: boolean
  ) => void;
  updateAssociatedFacility: (
    value: PostUpdateAssociatedFacilityParams["facility_id"]
  ) => Promise<void>;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    me: me(dispatch),
    switchFacility: switchFacility(dispatch),
    setFacilityValuesToUser: setFacilityValuesToUser(dispatch),
    updateAssociatedFacility: updateAssociatedFacility(dispatch)
  };
}
