import * as React from "react";
import { withStyles } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import DateSelectButtonsDaily from "@components/molecules/DateSelectButtonsDaily";
import MuiSelect from "@components/molecules/MuiSelect";
import { REPORT_FILTER_LIST } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = createStyles({
  flex: {
    display: "flex",
    width: "100%",
    alignItems: "end"
  },
  innerFlex: {
    marginLeft: 10
  }
});

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedDate: Date;
  filterValue: string;
  onChangeDate: (date: Date) => void;
  onChangeFilterSelect: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportDailyHeaderCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <div className={classes.flex}>
      <div>
        <DateSelectButtonsDaily
          defaultDate={props.selectedDate}
          min={props.minDate}
          max={props.maxDate}
          onClickSubmit={props.onChangeDate}
        />
      </div>
      <div className={classes.innerFlex}>
        <MuiSelect
          label="利用者選択"
          name=""
          value={props.filterValue}
          options={REPORT_FILTER_LIST}
          onChange={props.onChangeFilterSelect}
          style={{ width: 205, marginBottom: 0 }}
        />
      </div>
    </div>
  );
};

export const InOutReportDailyHeader = withStyles(styles)(
  InOutReportDailyHeaderCore
);
