import * as React from "react";
import AdminTemplate from "@components/templates/AdminTemplate";
import FacilityForm from "@components/organisms/mgr/SEIKATSUKAIGO/facility/FacilityForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { INVOICE_PATHS } from "@constants/mgr/SEIKATSUKAIGO/variables";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_OPTIONS_123
} from "@constants/variables";

/**
 * 事業所情報
 */
const Facility = (): JSX.Element => (
  <AdminTemplate
    pageName="事業所情報"
    invoiceVersion={{
      currentVersion: INVOICE_VERSION_CURRENT,
      versionPaths: INVOICE_PATHS.facility,
      versionOptions: INVOICE_VERSION_OPTIONS_123
    }}
  >
    <FacilityForm />
    <NavigationTransitionPrompt />
  </AdminTemplate>
);

export default Facility;
