import * as React from "react";
import { DestinationFields } from "@components/organisms/record/serviceDelivery/destination/DestinationField";
import { DestinationFields as DestinationFieldsMobile } from "@componentsMobile/organisms/record/serviceDelivery/destination/DestinationField";

// formik
import { FormikProps } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import { FacilityType } from "@constants/variables";

type Props = {
  isMobile: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerNum: number;
  facilityType: FacilityType;
  isEdit?: boolean;
};

export const ServiceDeliveryDestinationContainer = (
  props: Props
): JSX.Element => {
  const { practitionerNum } = props;
  const { values: formikValues } = props.formikProps;

  const destination =
    practitionerNum === 1
      ? formikValues.serviceDeliveryRecordPractitioners1
          .serviceDeliveryRecordPractitionerDestinations
      : formikValues.serviceDeliveryRecordPractitioners2
          .serviceDeliveryRecordPractitionerDestinations;

  const isAddButton = destination
    ? destination.filter((d) => !d.isDelete).length < 20
    : false;

  return (
    <div>
      {props.isMobile ? (
        <DestinationFieldsMobile
          isEdit={props.isEdit || false}
          formikProps={props.formikProps}
          isAddButton={isAddButton}
          practitionerNum={practitionerNum}
          destination={destination}
        />
      ) : (
        <DestinationFields
          formikProps={props.formikProps}
          isAddButton={isAddButton}
          practitionerNum={practitionerNum}
          destination={destination}
        />
      )}
    </div>
  );
};
