import getSupportLedgerList from "./getSupportLedgerList";
import getSupportLedger from "./getSupportLedger";
import postSupportLedger from "./postSupportLedger";
import deleteSupportLedger from "./deleteSupportLedger";

export const supportLedgerApi = {
  getSupportLedgerList,
  getSupportLedger,
  postSupportLedger,
  deleteSupportLedger
};
