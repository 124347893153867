import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import { SelectDateValue } from "@interfaces/ui/form";

/**
 * "NOT_SELECTED"がrequiredバリデーションにかからないため""に変換
 */
const notSelectedToEmpty = (value: SelectDateValue): SelectDateValue => ({
  ...value,
  year: value.year === DEFAULT_SELECT_VALUE ? "" : value.year
});

export default notSelectedToEmpty;
