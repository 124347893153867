import React, { useRef, useEffect } from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Table, { CellParam as HeadCellParam } from "@components/molecules/Table";
import TableHead from "@components/molecules/TableHead";
import { ServiceCodesState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import { Pagination } from "@components/molecules/Pagination";
import MuiSelect from "@components/molecules/MuiSelect";
import {
  CALCULATION_HOURS_LIST,
  DAYTIME_HOURS_LIST,
  EARLY_MORNING_HOURS_LIST,
  IDOSHIEN_DISPLAY_STATUS_LIST_SEARCH,
  LATE_NIGHT_HOURS_LIST,
  NIGHTTIME_HOURS_LIST,
  PHYSICAL_CARE_LIST,
  SERVICE_CODE_DIALOG_MAX_COUNT
} from "@constants/mgr/IDOSHIEN/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ServiceCodesFilter } from "@stores/domain/mgr/IDOSHIEN/report/types";

const styles = (): StyleRules =>
  createStyles({
    root: {
      overflowX: "auto"
    },
    title: {
      padding: "14px 32px",
      color: "#cfd8dc",
      borderBottom: "solid 1px"
    },
    content: {
      height: "100%",
      padding: "0px",
      overflowY: "hidden"
    },
    contentInner: {
      // 228.5はモーダルの最大サイズで実機調整を行なった数値
      maxHeight: "calc(100vh - 228.5px)",
      overflowY: "scroll"
    },
    search: {
      display: "flex",
      justifyContent: "space-between",
      margin: "16px 32px"
    },
    searchButtonWrapper: {
      display: "flex",
      alignItems: "end",
      "& > *": {
        width: 120,
        minHeight: 36
      }
    },
    clearButton: {
      display: "flex",
      padding: "3px 8px",
      marginRight: 8
    },
    table: {
      width: "100%",
      minWidth: 696
    },
    table2: {
      width: "100%",
      minWidth: 696,
      position: "sticky",
      top: 0,
      zIndex: 1
    },
    tableBody: {
      overflowX: "hidden"
    },
    checkbox: {
      padding: 0,
      height: 24
    },
    row: {
      "&:nth-of-type(even)": {
        backgroundColor: "#eceff1"
      }
    },
    checkboxCellStyle: {
      width: 50,
      padding: "0px 0px 0px 32px",
      border: "none"
    },
    cellNumber: {
      maxWidth: 120,
      width: 120,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      border: "none",
      fontSize: "16px",
      wordWrap: "break-word"
    },
    cellName: {
      width: 360,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      fontSize: "16px",
      borderBottom: "none"
    },
    cellPrice: {
      width: 90,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      fontSize: "16px",
      borderBottom: "none"
    },
    border: {
      padding: "8px 0px",
      color: "#cfd8dc",
      borderTop: "solid 1px",
      margin: "0px"
    },
    labelNumber: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: 104,
      boxSizing: "content-box"
    },
    labelName: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: 344,
      marginLight: 16,
      textAlign: "left"
    },
    labelPrice: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: 104,
      textAlign: "left"
    },
    labelCheckbox: {
      fontSize: 14,
      width: 56,
      paddingLeft: 32
    },
    cancelButton: {
      width: 125,
      border: "solid 1px #cccccc"
    },
    submitButton: {
      width: 125,
      marginRight: 32,
      boxShadow: "none",
      "&:active": {
        boxShadow: "none"
      }
    },
    paging: {
      height: 56,
      margin: "0px 32px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    fraction: {
      fontSize: "12px",
      color: "rgba(0, 0, 1, 0.6)"
    },
    fractionLabel: {
      margin: "0 2px"
    },
    flex: {
      display: "flex"
    }
  });

type OwnProps = {
  labelId?: string;
  title: string;
  open: boolean;
  maxCount: number;
  page: number;
  serviceCodes: ServiceCodesState["serviceCodes"];
  timeDivisionFlg: boolean;
  canSearch: boolean;
  onSubmit: (data: ServiceCodesState["serviceCodes"]) => void;
  onClose: () => void;
  onPageChange: (page: number, filter: ServiceCodesFilter) => void;
  onClickSearch: (page: number, filter: ServiceCodesFilter) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceCodesDialogCore = (props: Props): JSX.Element => {
  const {
    classes,
    open,
    maxCount,
    page,
    serviceCodes,
    labelId,
    title,
    timeDivisionFlg,
    canSearch,
    onSubmit,
    onClose,
    onPageChange,
    onClickSearch
  } = props;

  // 現在のチェックボックスの状態
  const [excludedData, setExcludedData] = React.useState<
    ServiceCodesState["serviceCodes"]
  >([]);
  const initValue = {
    content: "6",
    physicalCare: "",
    calculationHours: "",
    daytimeHours: "",
    nighttimeHours: "",
    lateNightHours: "",
    earlyMorningHours: ""
  };
  const [searchValue, setSearchValue] = React.useState<ServiceCodesFilter>(
    initValue
  );

  const dialogContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (dialogContentRef.current) {
      dialogContentRef.current.scrollTo(0, 0);
    }
  }, [serviceCodes]);

  const dialogHeaderData = (): HeadCellParam[] => {
    return [
      {
        className: classes.labelCheckbox,
        align: "left",
        label: ""
      },
      {
        className: classes.labelNumber,
        align: "left",
        label: "コード"
      },
      {
        className: classes.labelName,
        align: "left",
        label: "サービス名称"
      },
      {
        className: classes.labelPrice,
        align: "right",
        label: "単位/単価"
      }
    ];
  };

  const onChangeExcludedData = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const id = +event.target.value;
    const tmpExcludedData = excludedData.filter((x) => x.id !== id);
    // idの除外後のlengthが変わっていなければ追加
    const newExcludedData =
      tmpExcludedData.length === excludedData.length
        ? excludedData.concat(serviceCodes.filter((x) => x.id === id))
        : tmpExcludedData;
    setExcludedData(newExcludedData);
  };

  const dialogContentData = (): JSX.Element[] => {
    if (!serviceCodes) return [<TableBody key="service-codes-tableBody" />];
    const excludedDataIds = excludedData.map((x) => x.id);
    const rowData = excludedData.concat(
      serviceCodes.filter((x) => !excludedDataIds.includes(x.id))
    );
    return [
      <TableBody key="service-codes-tableBody">
        {rowData.map((data) => {
          const isChecked = excludedDataIds.includes(data.id);
          return (
            <TableRow
              className={classes.row}
              role="checkbox"
              aria-checked
              tabIndex={-1}
              key={`service-codes-${data.id}`}
            >
              <TableCell
                key={`service-codes-${data.id}-id}`}
                align="left"
                className={classes.checkboxCellStyle}
              >
                <Checkbox
                  checked={isChecked}
                  onChange={onChangeExcludedData}
                  value={`${data.id}`}
                  className={classes.checkbox}
                />
              </TableCell>
              <TableCell
                key={`service-codes-${data.id}-serviceCode}`}
                align="left"
                className={classes.cellNumber}
              >
                {data.serviceCode}
              </TableCell>
              <TableCell
                key={`service-codes-${data.id}-name}`}
                align="left"
                className={classes.cellName}
              >
                {data.name}
              </TableCell>
              <TableCell
                key={`service-codes-${data.id}-unitPrice}`}
                align="left"
                className={classes.cellPrice}
              >
                {data.unitPrice}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    ];
  };

  const onChangeFilterSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    searchValue[e.target.name] = e.target.value;
    setSearchValue({
      ...searchValue
    });
  };

  const onClickClearFilter = (): void => {
    setSearchValue(initValue);
  };

  const onClickSearchButton = (): void => {
    onClickSearch(page, searchValue);
  };

  const handlePageChange = (params: { selected: number }): void => {
    onPageChange(params.selected + 1, searchValue);
  };

  /**
   * 初期値に反映して親に渡したら終了
   */
  const handleOnSubmit = (): void => {
    onSubmit(excludedData);
    setExcludedData([]);
    onClickClearFilter();
    onClose();
  };

  /**
   * 初期値に戻してから終了
   */
  const handleOnClose = (): void => {
    setExcludedData([]);
    onClickClearFilter();
    onClose();
  };

  const rows = dialogContentData();
  const count =
    serviceCodes.length < SERVICE_CODE_DIALOG_MAX_COUNT
      ? `${serviceCodes.length}件を表示中`
      : `${SERVICE_CODE_DIALOG_MAX_COUNT * (page - 1) + 1} ~ ${
          page * SERVICE_CODE_DIALOG_MAX_COUNT
        }件目を表示中`;

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      className={classes.root}
      maxWidth="lg"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id={labelId} className={classes.title}>
        {title}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.contentInner} ref={dialogContentRef}>
          {canSearch && (
            <div className={classes.search}>
              <div className={classes.searchFilter}>
                <div className={classes.flex}>
                  <MuiSelect
                    label="サービス内容"
                    name="content"
                    value={searchValue.content}
                    options={IDOSHIEN_DISPLAY_STATUS_LIST_SEARCH}
                    onChange={onChangeFilterSelect}
                    style={{ width: 162, marginBottom: 16 }}
                  />
                  <MuiSelect
                    label="身体介護"
                    name="physicalCare"
                    value={searchValue.physicalCare}
                    options={PHYSICAL_CARE_LIST}
                    onChange={onChangeFilterSelect}
                    style={{ width: 162, marginBottom: 16 }}
                  />
                </div>
                {timeDivisionFlg ? (
                  <div className={classes.flex}>
                    <MuiSelect
                      label="日中"
                      name="daytimeHours"
                      value={searchValue.daytimeHours}
                      options={DAYTIME_HOURS_LIST}
                      onChange={onChangeFilterSelect}
                      style={{ width: 80, marginBottom: 0 }}
                    />
                    <MuiSelect
                      label="夜間"
                      name="nighttimeHours"
                      value={searchValue.nighttimeHours}
                      options={NIGHTTIME_HOURS_LIST}
                      onChange={onChangeFilterSelect}
                      style={{ width: 80, marginBottom: 0 }}
                    />
                    <MuiSelect
                      label="深夜"
                      name="lateNightHours"
                      value={searchValue.lateNightHours}
                      options={LATE_NIGHT_HOURS_LIST}
                      onChange={onChangeFilterSelect}
                      style={{ width: 80, marginBottom: 0 }}
                    />
                    <MuiSelect
                      label="早朝"
                      name="earlyMorningHours"
                      value={searchValue.earlyMorningHours}
                      options={EARLY_MORNING_HOURS_LIST}
                      onChange={onChangeFilterSelect}
                      style={{ width: 80, marginBottom: 0 }}
                    />
                  </div>
                ) : (
                  <div className={classes.flex}>
                    <MuiSelect
                      label="算定時間"
                      name="calculationHours"
                      value={searchValue.calculationHours}
                      options={CALCULATION_HOURS_LIST}
                      onChange={onChangeFilterSelect}
                      style={{ width: 80, marginBottom: 0 }}
                    />
                  </div>
                )}
              </div>
              <div className={classes.searchButtonWrapper}>
                <KnowbeButton
                  className={classes.clearButton}
                  kind="iconText"
                  onClick={onClickClearFilter}
                >
                  <RefreshIcon style={{ marginRight: 3 }} />
                  リセット
                </KnowbeButton>
                <KnowbeButton kind="outlineWhite" onClick={onClickSearchButton}>
                  絞り込む
                </KnowbeButton>
              </div>
            </div>
          )}
          <Table className={classes.table2} key="service-codes-table-head">
            <TableHead
              role="checkbox"
              ariaChecked
              tabIndex={-1}
              key={1}
              selected
              items={dialogHeaderData()}
              rowStyle={{ height: 32 }}
            />
          </Table>
          <div
            className={classes.tableBody}
            style={{ minHeight: canSearch ? "300px" : "400px" }}
          >
            <Table key="service-codes-table" className={classes.table}>
              {rows.map((row) => row)}
            </Table>
          </div>
          <div className={classes.paging}>
            <span className={classes.fraction}>
              <span className={classes.fractionLabel}>{count}</span>
              <span className={classes.fractionLabel}>/</span>
              <span className={classes.fractionLabel}>{maxCount}</span>
              <span>件</span>
            </span>
            <Pagination
              page={page}
              dataLength={maxCount}
              hideCountDisplay
              handlePageChange={handlePageChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.border}>
        <div>
          <Button
            className={classes.cancelButton}
            onClick={handleOnClose}
            autoFocus={false}
            color="secondary"
            variant="text"
          >
            キャンセル
          </Button>
        </div>
        <Button
          disabled={excludedData.length <= 0}
          className={classes.submitButton}
          onClick={handleOnSubmit}
          autoFocus
          color="secondary"
          variant="contained"
        >
          追加する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const ServiceCodesDialog = withStyles(styles)(ServiceCodesDialogCore);
