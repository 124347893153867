import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// ui
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryTimeContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryTimeContainer";
import { ServiceDeliveryDetailContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailContainer";
import { MobileKnowbeButton } from "@componentsMobile/presentational/atoms/KnowbeButton";
import Dialog from "@material-ui/core/Dialog";

// formik
import { FormikProps, getIn } from "formik";
import {
  ServiceDeliveryDetailErrors,
  validation
} from "@initialize/record/serviceDelivery/validation";
import { toEffectiveObject } from "@utils/object";
import {
  FacilityType,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
// utils
import {
  isCustomRecordDOKOENGO,
  isDisplayService
} from "@utils/domain/serviceDelivery/isDisplayService";
import { ServiceDeliveryDestinationContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDestinationContainer";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      backgroundColor: "#fff",
      margin: 0,
      marginBottom: 16
    },
    timeField: {
      padding: "16px 16px 24px 16px"
    },
    title: {
      height: 52,
      fontWeight: "bold",
      padding: "14px 0 14px 16px",
      borderBottom: "1px solid #e0e0e0"
    },
    input: {
      padding: "0 16px",
      paddingBottom: 24
    },
    inputButton: {
      width: "100%"
    },
    header: {
      height: 32,
      fontWeight: "bold",
      padding: "4px 16px",
      backgroundColor: "#f5f5f5"
    },
    modal: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      zIndex: 1200,
      backgroundColor: "#fff",
      overflow: "auto"
    },
    modalTitle: {
      position: "sticky",
      top: 0,
      fontSize: 20,
      padding: "9px 16px",
      minHeight: 48,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.2)",
      zIndex: 1200
    },
    modalHeader: {
      height: 128,
      padding: 16
    },
    modalDate: {
      fontSize: 16,
      fontWeight: "bold",
      marginBottom: 4
    },
    modalUserName: {
      fontSize: 16,
      fontWeight: "bold"
    },
    modalPractitioner: {
      fontSize: 12,
      marginTop: 16
    },
    modalFooter: {
      position: "sticky",
      bottom: 0,
      borderTop: "1px solid #e0e0e0",
      display: "flex",
      marginTop: 8,
      padding: 16,
      minHeight: 72,
      backgroundColor: "#fff",
      boxShadow: "4px 2px 0 0 rgba(0, 0, 0, 0.2)",
      zIndex: 1200
    },
    leftButton: {
      padding: 0,
      width: "100%"
    },
    rightButton: {
      padding: 0,
      width: "100%",
      marginLeft: 16
    }
  });

type Props = {
  submitError: () => void;
  date: string;
  userName: string;
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  customRecords: CustomRecordsWithCategoryState;
  practitionerNum: number;
  numberOfPractitioner: number;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  staffOptions: FieldItem[];
  facilityType: FacilityType;
} & WithStyles<typeof styles>;

export const ServiceDeliveryDetailFieldCore = (props: Props): JSX.Element => {
  const { classes, practitionerNum, date, userName, submitError } = props;
  const { setFieldValue } = props.formikProps;
  const formikValues = props.isEdit
    ? props.formikProps.values
    : props.formikProps.initialValues;
  const key = `serviceDeliveryRecordPractitioners${practitionerNum}`;
  const practitionerValues = formikValues[key];

  const isGroup =
    props.facilityType === FacilityType.IDOSHIEN &&
    formikValues.status === IDOSHIEN_STATUS_LIST.GROUP.value;

  const [isOpenRecordModal, setIsOpenRecordModal] = React.useState<boolean>(
    false
  );
  const [
    isOpenDestinationAndMoneyManagementModal,
    setIsOpenDestinationAndMoneyManagementModal
  ] = React.useState<boolean>(false);
  const [tempSaveValues, setTempSaveValues] = React.useState(formikValues);

  // 記録の入力
  const openRecordModal = (): void => {
    setTempSaveValues(formikValues);
    setIsOpenRecordModal(true);
  };
  const cancelRecordModal = (): void => {
    // formikProps.valuesを破棄してinitialに戻す
    setFieldValue(`${key}.custom_record`, tempSaveValues[key].custom_record);
    setIsOpenRecordModal(false);
  };
  const saveRecordModal = (): void => {
    // 記録のみvalidationをかけてエラーならstopする
    const values = getIn(props.formikProps.values, `${key}.custom_record`);
    const validationResult = validation(
      {
        ...props.formikProps.initialValues,
        [key]: {
          ...props.formikProps.initialValues[key],
          custom_record: values
        }
      },
      props.facilityType
    );
    const error = toEffectiveObject(
      validationResult
    ) as ServiceDeliveryDetailErrors;
    if (error && error[key] && error[key].custom_record) {
      submitError();
    } else {
      setTempSaveValues(props.formikProps.values);
      setIsOpenRecordModal(false);
    }
  };

  // 行き先・金銭管理の入力
  const openDestinationAndMoneyManagementModal = (): void => {
    setTempSaveValues(formikValues);
    setIsOpenDestinationAndMoneyManagementModal(true);
  };
  const cancelDestinationAndMoneyManagementModal = (): void => {
    // formikProps.valuesを破棄してinitialに戻す
    setFieldValue(`${key}.custom_record`, tempSaveValues[key].custom_record);
    setFieldValue(
      `${key}.serviceDeliveryRecordPractitionerDestinations`,
      tempSaveValues[key].serviceDeliveryRecordPractitionerDestinations
    );
    setFieldValue(
      `${key}.serviceDeliveryRecordPractitionerMoneyManagements`,
      tempSaveValues[key].serviceDeliveryRecordPractitionerMoneyManagements
    );
    setIsOpenDestinationAndMoneyManagementModal(false);
  };
  const saveDestinationAndMoneyManagementModal = (): void => {
    // 金銭管理、行き先、custom_recordにvalidationをかけてエラーならstopする
    const custom_record = getIn(
      props.formikProps.values,
      `${key}.custom_record`
    );
    const serviceDeliveryRecordPractitionerDestinations = getIn(
      props.formikProps.values,
      `${key}.serviceDeliveryRecordPractitionerDestinations`
    );
    const serviceDeliveryRecordPractitionerMoneyManagements = getIn(
      props.formikProps.values,
      `${key}.serviceDeliveryRecordPractitionerMoneyManagements`
    );
    const validationResult = validation(
      {
        ...props.formikProps.initialValues,
        [key]: {
          ...props.formikProps.initialValues[key],
          custom_record,
          serviceDeliveryRecordPractitionerDestinations,
          serviceDeliveryRecordPractitionerMoneyManagements
        }
      },
      props.facilityType
    );
    const error = toEffectiveObject(
      validationResult
    ) as ServiceDeliveryDetailErrors;
    if (
      error &&
      error[key] &&
      (error[key].custom_record ||
        error[key].serviceDeliveryRecordPractitionerDestinations ||
        error[key].serviceDeliveryRecordPractitionerMoneyManagements)
    ) {
      submitError();
    } else {
      setTempSaveValues(props.formikProps.values);
      setIsOpenDestinationAndMoneyManagementModal(false);
    }
  };

  const titleNum = practitionerNum.toString();
  const detail = (
    <div className={classes.detail}>
      {props.customRecords
        .filter((record) => {
          if (props.facilityType === FacilityType.KYOTAKUKAIGO) {
            return isDisplayService(
              props.formikProps.values.status,
              record.category_type
            );
          }
          if (
            SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(props.facilityType)
          ) {
            return isCustomRecordDOKOENGO(record.category_type);
          }
          return true;
        })
        .sort((a, b) => a.category_type - b.category_type)
        .map((item) => {
          return (
            <div key={item.category_type} className={classes.section}>
              <div className={classes.header}>{item.name}</div>
              <ServiceDeliveryDetailContainer
                isMobile
                isEdit={props.isEdit}
                formikProps={props.formikProps}
                setFormikFieldValue={props.formikProps.setFieldValue}
                customRecords={props.customRecords}
                categoryType={item.category_type}
                practitionerNum={practitionerNum}
                facilityType={props.facilityType}
                detailRecords={props.detailRecords}
              />
            </div>
          );
        })}
    </div>
  );

  // 行き先・金銭管理
  const isDestinationAndMoneyManagement = SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
    props.facilityType
  );
  const destinationAndMoneyManagement = isDestinationAndMoneyManagement ? (
    <>
      <div className={classes.detail}>
        <div key="destinationTable" className={classes.section}>
          <div className={classes.header}>行き先</div>
          <ServiceDeliveryDestinationContainer
            isMobile
            isEdit={props.isEdit}
            formikProps={props.formikProps}
            setFormikFieldValue={props.formikProps.setFieldValue}
            practitionerNum={practitionerNum}
            facilityType={props.facilityType}
          />
        </div>
      </div>
      <div className={classes.detail}>
        {props.customRecords
          .filter((record) => {
            // 金銭管理のみ
            return !isCustomRecordDOKOENGO(record.category_type);
          })
          .sort((a, b) => a.category_type - b.category_type)
          .map((item) => {
            return (
              <div key={item.category_type} className={classes.section}>
                <div className={classes.header}>{item.name}</div>
                <ServiceDeliveryDetailContainer
                  isMobile
                  isEdit={props.isEdit}
                  formikProps={props.formikProps}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  customRecords={props.customRecords}
                  categoryType={item.category_type}
                  practitionerNum={practitionerNum}
                  facilityType={props.facilityType}
                  detailRecords={props.detailRecords}
                />
              </div>
            );
          })}
      </div>
    </>
  ) : null;

  const practitionerName = (): string => {
    const practitionerId = getIn(formikValues, `${key}`).practitioner;
    // 職員情報が編集されている時、提供者IDが0なのでsnapshotの値を使う
    if (practitionerId === "0") {
      return practitionerValues.practitionerSnapshot.snapshot_name;
    }
    // 通常選択
    const staff = props.staffOptions.find((s) => s.value === practitionerId);
    if (staff) {
      return staff.label;
    }
    // 選択中の提供者ID情報がstaffOptionsにないがスナップショットにあるならそちらを使う（職員情報を削除したケース）
    if (practitionerId && practitionerValues.practitionerSnapshot) {
      if (+practitionerId === +practitionerValues.practitionerSnapshot.id) {
        return practitionerValues.practitionerSnapshot.name;
      }
    }
    return "-";
  };
  const title =
    props.numberOfPractitioner === 1 || isGroup
      ? "サービス提供記録"
      : `サービス提供記録 ${titleNum}`;
  const destinationTitle =
    props.numberOfPractitioner === 1 || isGroup
      ? "行き先・金銭管理"
      : `行き先・金銭管理 ${titleNum}`;
  return (
    <div className={classes.paper}>
      <div className={classes.title}>{title}</div>
      <div className={classes.timeField}>
        <ServiceDeliveryTimeContainer
          formikProps={props.formikProps}
          setFormikFieldValue={props.formikProps.setFieldValue}
          practitionerNum={practitionerNum}
          practitionerValues={practitionerValues}
          staffOptions={props.staffOptions}
          facilityType={props.facilityType}
          isMobile
          isEdit={props.isEdit}
        />
      </div>
      {props.isEdit && (
        <>
          {destinationAndMoneyManagement && (
            <div className={classes.input}>
              <MobileKnowbeButton
                onClick={openDestinationAndMoneyManagementModal}
                className={classes.inputButton}
              >
                <span>
                  行き先・金銭管理
                  {props.numberOfPractitioner === 2 && !isGroup && titleNum}
                  を入力する
                </span>
              </MobileKnowbeButton>
            </div>
          )}
          <div className={classes.input}>
            <MobileKnowbeButton
              onClick={openRecordModal}
              className={classes.inputButton}
            >
              <span>
                記録
                {props.numberOfPractitioner === 2 && !isGroup && titleNum}
                を入力する
              </span>
            </MobileKnowbeButton>
          </div>
        </>
      )}
      {!props.isEdit ? (
        <>
          {destinationAndMoneyManagement && (
            <div>{destinationAndMoneyManagement}</div>
          )}
          <div>{detail}</div>
        </>
      ) : (
        <>
          {/* 行き先・金銭管理の入力モーダル */}
          {destinationAndMoneyManagement && (
            <Dialog
              open={isOpenDestinationAndMoneyManagementModal}
              fullScreen
              transitionDuration={0}
            >
              <div className={classes.modalTitle}>{destinationTitle}</div>
              <div className={classes.modalHeader}>
                <div className={classes.modalDate}>{date}</div>
                <div className={classes.modalUserName}>{userName}</div>
                <div className={classes.modalPractitioner}>
                  <span className={classes.marginRight}>
                    サービス提供者&emsp;{practitionerName()}
                  </span>
                </div>
              </div>
              {destinationAndMoneyManagement}
              <div className={classes.modalFooter}>
                <MobileKnowbeButton
                  className={props.classes.leftButton}
                  kind="outline"
                  onClick={cancelDestinationAndMoneyManagementModal}
                >
                  キャンセル
                </MobileKnowbeButton>
                <MobileKnowbeButton
                  className={props.classes.rightButton}
                  onClick={saveDestinationAndMoneyManagementModal}
                >
                  確定する
                </MobileKnowbeButton>
              </div>
            </Dialog>
          )}
          {/* 記録の入力モーダル */}
          <Dialog open={isOpenRecordModal} fullScreen transitionDuration={0}>
            <div className={classes.modalTitle}>{title}</div>
            <div className={classes.modalHeader}>
              <div className={classes.modalDate}>{date}</div>
              <div className={classes.modalUserName}>{userName}</div>
              <div className={classes.modalPractitioner}>
                <span className={classes.marginRight}>
                  サービス提供者&emsp;{practitionerName()}
                </span>
              </div>
            </div>
            {detail}
            <div className={classes.modalFooter}>
              <MobileKnowbeButton
                className={props.classes.leftButton}
                kind="outline"
                onClick={cancelRecordModal}
              >
                キャンセル
              </MobileKnowbeButton>
              <MobileKnowbeButton
                className={props.classes.rightButton}
                onClick={saveRecordModal}
              >
                確定する
              </MobileKnowbeButton>
            </div>
          </Dialog>
        </>
      )}
    </div>
  );
};

export const ServiceDeliveryDetailField = withStyles(styles)(
  ServiceDeliveryDetailFieldCore
);
