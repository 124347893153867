import { InitialDataValues } from "./initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { InitialErrors } from "@interfaces/mgr/SEIKATSUKAIGO/report/initial";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  status: string,
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    const hasNoErrorsInBasicItems =
      validationResult.initial.memo === undefined &&
      validationResult.initial.inTime === undefined &&
      validationResult.initial.outTime === undefined &&
      validationResult.initial.travelTime === undefined;

    // 集中的支援加算（トグル、開始年月日）のエラーチェック
    const hasNoErrorsInIntensiveSupport =
      /* intensiveSupportStartDateがundefinedならトグルOFF */
      validationResult.initial.intensiveSupportStartDate === undefined
        ? validationResult.initial.intensiveSupportStartDate === undefined
        : validationResult.initial.intensiveSupportStartDate.year ===
            undefined &&
          validationResult.initial.intensiveSupportStartDate.month ===
            undefined &&
          validationResult.initial.intensiveSupportStartDate.day === undefined;
    return !(hasNoErrorsInBasicItems && hasNoErrorsInIntensiveSupport);
  }
  return false;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      trialUsageKind: validator(values.initial.trialUsageKind, "required"),
      memo: validator(values.initial.memo, {
        type: "checkCharacterLength",
        length: 50
      }),
      intensiveSupportStartDate: validateSwitcher(
        values.initial.intensiveSupportFlg,
        dateValidator(
          notSelectedToEmpty(values.initial.intensiveSupportStartDate),
          "required"
        )
      )
    }
  };
};

const validationIncludedTime = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      trialUsageKind: validator(values.initial.trialUsageKind, "required"),
      inTime: validator(values.initial.inTime, "required", "checkTime"),
      outTime: validator(values.initial.outTime, "checkTime", {
        type: "checkTimeFuture",
        startTime: values.initial.inTime
      }),
      memo: validator(values.initial.memo, {
        type: "checkCharacterLength",
        length: 50
      }),
      intensiveSupportStartDate: validateSwitcher(
        values.initial.intensiveSupportFlg,
        dateValidator(
          notSelectedToEmpty(values.initial.intensiveSupportStartDate),
          "required"
        )
      ),
      calculatedHours: validator(
        values.initial.calculatedHours,
        "decimalPoint",
        "decimalPointByEveryHalfMinutes"
      )
    }
  };
};

const validation = (values: InitialDataValues): InitialDataErrors => {
  let initialErrors;

  // 通所、訪問の場合、時間チェック
  if (values.initial.status === "2" || values.initial.status === "6") {
    initialErrors = validationIncludedTime(values);
  } else {
    initialErrors = initialValidation(values);
  }

  return { ...initialErrors };
};

export default validation;
