import * as React from "react";

import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import SectionTitle from "@components/atoms/SectionTitle";

import DownloadTargetSelectPaper from "@components/organisms/download/DownloadTargetSelectPaper";
import DownloadInvoiceSection, {
  DownloadAction
} from "@components/organisms/download/DownloadInvoiceSection";
import MultiFunctional from "@components/atoms/MultiFunctional";
import {
  DownloadableMonth,
  MainFacility,
  MultipleFacilities
} from "@stores/domain/invoice/type";
import { FacilityType } from "@constants/variables";
import { DownloadInvoiceSection as KEIKAKUSODANDownloadInvoiceSection } from "@components/organisms/mgr/KEIKAKUSODAN/download/DownloadInvoiceSection";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      margin: spacing.unit * 2,
      padding: "30px 32px 32px"
    },
    divider: {
      marginTop: spacing.unit * 2
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    }
  });

type OwnProps = {
  selected: boolean;
  months: DownloadableMonth[];
  isDisabledSelect: boolean;
  isDisabledButton: boolean;
  value: string;
  facility: MainFacility;
  multiFacilities: MultipleFacilities[];
  facilityType: FacilityType;
  facilityTypeKEIKAKUSODAN: number | null; // 計画相談のみで使用する施設区分（※計画相談以外の場合はnull）

  downloadCsvActions: DownloadAction[];
  downloadPrintActions: DownloadAction[];

  excludedUserIds: number[];

  isNotFinishedInitialData: boolean;

  onChangeSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickButton: (event: React.MouseEvent<HTMLSelectElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const targetSelectSection = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <Paper className={classes.root} elevation={0}>
      <SectionTitle label="請求書類のダウンロード・印刷" />
      <Typography
        component="p"
        variant="caption"
        className={classes.description}
      >
        国保連への請求用書類ダウンロードや、請求内容の確認書類などの印刷ができます。
        <br />
        請求内容は利用実績や各設定をもとに作成されます。あらかじめ利用実績や事業所情報などの設定内容をご確認の上、請求業務を行ってください。
      </Typography>
      {(props.multiFacilities ? props.multiFacilities : []).length > 0 && (
        <MultiFunctional
          facility={props.facility}
          multiFacilities={props.multiFacilities}
        />
      )}
      <DownloadTargetSelectPaper
        months={props.months}
        selected={props.selected}
        isDisabledSelect={props.isDisabledSelect}
        isDisabledButton={props.isDisabledButton}
        value={props.value}
        excludedUserIdCount={props.excludedUserIds.length}
        onChangeSelect={props.onChangeSelect}
        onClickButton={props.onClickButton}
        isNotFinishedInitialData={props.isNotFinishedInitialData}
      />
      <Divider className={classes.divider} />
      {props.facilityType === FacilityType.KEIKAKUSODAN ? (
        <KEIKAKUSODANDownloadInvoiceSection
          facilityType={props.facilityTypeKEIKAKUSODAN}
          targetMonth={props.value}
          downloadableMonthList={props.months}
          downloadCsvActions={props.downloadCsvActions}
          downloadPrintActions={props.downloadPrintActions}
          excludedUserIds={props.excludedUserIds}
        />
      ) : (
        <DownloadInvoiceSection
          month={props.value}
          months={props.months}
          downloadCsvActions={props.downloadCsvActions}
          downloadPrintActions={props.downloadPrintActions}
          excludedUserIds={props.excludedUserIds}
        />
      )}
    </Paper>
  );
};

export default withStyles(styles)(targetSelectSection);
