export type BaseTimes = {
  startTime: Date;
  endTime: Date;
}[];
/**
 * 複数の時間で重なる時間がないか
 */
export const checkTimeConflict = (
  targetStartTime: Date,
  targetEndTime: Date,
  baseTimes: BaseTimes,
  label: string
): string | undefined => {
  let resultFlg = false;
  baseTimes.forEach((timeObj) => {
    if (
      (timeObj.startTime < targetStartTime &&
        targetStartTime < timeObj.endTime) ||
      (timeObj.startTime < targetEndTime && targetEndTime < timeObj.endTime)
    ) {
      resultFlg = true;
    }

    if (
      (targetStartTime < timeObj.startTime &&
        timeObj.startTime < targetEndTime) ||
      (targetStartTime < timeObj.endTime && timeObj.endTime < targetEndTime)
    ) {
      resultFlg = true;
    }

    if (
      targetStartTime.getTime() === timeObj.startTime.getTime() &&
      timeObj.endTime.getTime() === targetEndTime.getTime() &&
      targetEndTime.getTime() - targetStartTime.getTime() ===
        timeObj.endTime.getTime() - timeObj.startTime.getTime()
    ) {
      resultFlg = true;
    }
  });

  return resultFlg
    ? `${label}は同一時間帯で重複しないように入力してください`
    : undefined;
};
