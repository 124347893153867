import React from "react";
import { RouteComponentProps } from "react-router-dom";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";

import * as URL from "@constants/url";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { InquiryState } from "@stores/domain/mgr/KEIKAKUSODAN/inquiry/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { InquiryFormPrint } from "@components/organisms/mgr/KEIKAKUSODAN/record/inquiry/InquiryFormPrint";

/**
 * サービス担当者に対する照会（依頼）内容のプリント画面
 */

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  inquiryId: string;
}>;
type StateProps = {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  inquiry: InquiryState["inquiry"];
};

type DispatchProps = {
  fetchFacility: () => void;
  fetchInquiry: (uifId: string, inquiryId: string) => Promise<void>;
  fetchOne: (uifId: string) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const InquiryPrintCore = (props: Props): JSX.Element => {
  const { uifId, inquiryId } = props.match.params;
  const { user, facility } = props;

  // fetch
  React.useEffect(() => {
    props.fetchFacility();
    props.fetchInquiry(uifId, inquiryId);
    props.fetchOne(uifId);
  }, []);

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "計画・モニタリング・会議記録",
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // 現在ページのタイトル名
  const pageName = "サービス担当者に対する照会（依頼）内容";

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <InquiryFormPrint
          user={user}
          facility={facility}
          inquiry={props.inquiry}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.KEIKAKUSODAN.userInFacility.user,
    facility: state.KEIKAKUSODAN.facility,
    inquiry: state.KEIKAKUSODAN.inquiry.inquiry
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN } = dispatches;
  const facilityDispatcher = KEIKAKUSODAN.facilityDispatcher(dispatch);
  const inquiryDispatcher = KEIKAKUSODAN.inquiryDispatcher(dispatch);
  const userInFacilityDispatcher = KEIKAKUSODAN.userInFacilityDispatcher(
    dispatch
  );

  return {
    fetchFacility: facilityDispatcher.fetch,
    fetchInquiry: (uifId: string, inquiryId: string): Promise<void> => {
      return inquiryDispatcher.fetchInquiry(uifId, inquiryId);
    },
    fetchOne: (uifId: string): void => {
      userInFacilityDispatcher.fetchOne(uifId);
    }
  };
};

export const InquiryPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(InquiryPrintCore);
