import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UnitsState } from "@stores/domain/units/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateGH } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateTN } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  OPERATION_CUSTOM_RECORD_DEFAULT_ITEM,
  FacilityType
} from "@constants/variables";

// utils
import { dateTodayInFormat } from "@utils/date";

// ui
import LineBreak from "@components/atoms/LineBreak";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: 24,
      letterSpacing: 1.2,
      textAlign: "center",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 8
    },
    subTitleDateGroupHome: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 24,
      marginTop: 16
    },
    subTitleDateTANKINYUSHO: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 16,
      marginTop: 16
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      marginBottom: 24,
      "&.fullWidth": {
        width: "100%"
      },
      "&.80width": {
        width: "80%"
      },
      "&.20width": {
        width: "20%"
      },
      "& td": {
        padding: "0.5px 3px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        wordBreak: "break-all",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.subLabel": {
          textAlign: "left",
          verticalAlign: "top"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 90
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 420
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        },
        "&.topAlign": {
          verticalAlign: "top"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
};

type OwnProps = {
  facilityUnitId: string[];
  date: string;
  operation:
    | DailyOperationsAndSupportsStateGH["operation"]
    | DailyOperationsAndSupportsStateTN["operation"];
  sortOperationCustomRecords: (
    customRecords: CustomRecordsState
  ) => CustomRecordsState;
  customRecords: CustomRecordsState;
  unitName: string;
  serviceType: FacilityType;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

type OperationRecordProps = {
  unitId: string;
  operation:
    | DailyOperationsAndSupportsStateGH["operation"]
    | DailyOperationsAndSupportsStateTN["operation"];
  sortOperationCustomRecords: (
    customRecords: CustomRecordsState
  ) => CustomRecordsState;
  customRecords: CustomRecordsState;
  classes: Record<string, string>;
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  unitName: string;
};

const OperationRecord = ({
  classes,
  unitId,
  operation,
  sortOperationCustomRecords,
  customRecords,
  optionalCustomInfo,
  unitName
}: OperationRecordProps): JSX.Element | null => {
  // 記録項目データ事業所全体・ユニット別の指定
  const targetType =
    unitId === "0"
      ? CUSTOM_RECORD_TARGET_TYPE.operation_common
      : CUSTOM_RECORD_TARGET_TYPE.operation_unit;

  const recordTitle = unitId === "0" ? "事業所全体" : unitName;

  // 記録項目データ
  const OperationCustomRecords = customRecords.length
    ? sortOperationCustomRecords(
        customRecords.filter((record) => record.setting_type === targetType)
      )
    : [];

  // 記録者項目
  const recorderItemIndex = OperationCustomRecords.findIndex(
    (item) =>
      item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
  );
  const recorderItem = OperationCustomRecords.splice(recorderItemIndex, 1);

  const operationData = operation.find(
    (item) => item.facility_unit_id === +unitId
  );

  if (!operationData || !recorderItem.length) {
    return null;
  }

  // 記録者
  const staffRecord = operationData.operation_record_input
    .filter(
      (item) =>
        item.custom_record_item_id === recorderItem[0].id && item.checked
    )
    .map((item) => item.choiced_staff_name_snapshot);
  const staffName = staffRecord ? staffRecord.join("、") : "";

  const tableRow = OperationCustomRecords.filter((item) =>
    operationData.operation_record_input.some(
      (i) => i.custom_record_item_id === item.id && (i.input_data || i.checked)
    )
  );

  // 一番上に表示される項目を判別するため使用
  let firstContentFlag = 0;
  // 「記録者」「捺印」項目
  const firstElement = (): JSX.Element => {
    if (!firstContentFlag) {
      firstContentFlag += 1;
    }
    return (
      <>
        <td className="subLabel" rowSpan={tableRow.length}>
          {staffName}
        </td>
        <td rowSpan={tableRow.length} />
      </>
    );
  };

  return (
    <>
      {!Array.isArray(optionalCustomInfo) && (
        <div className={classes.subTitle}>{recordTitle}</div>
      )}
      <table
        className={`${classes.table} fullWidth`}
        style={{ marginBottom: 24 }}
      >
        <tbody>
          <tr>
            <td className="label msize">項目</td>
            <td className="label llsize">内容</td>
            <td className="label ssize">記録者</td>
            <td className="label sssize">捺印</td>
          </tr>

          {OperationCustomRecords.map((record) => {
            switch (record.input_type) {
              // テキスト形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
                const textContent = operationData.operation_record_input.find(
                  (item) => item.custom_record_item_id === record.id
                );

                const textInputData =
                  textContent && textContent.input_data
                    ? textContent.input_data
                    : null;

                if (textInputData) {
                  return (
                    <tr key={record.id}>
                      <td className="subLabel">{record.name}</td>
                      <td className="topAlign">
                        <LineBreak text={textInputData} />
                      </td>
                      {!firstContentFlag && firstElement()}
                    </tr>
                  );
                }
                return null;
              }
              // チェックボックス形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
                const checkedItemsId = operationData.operation_record_input
                  .filter(
                    (item) =>
                      item.custom_record_item_id === record.id &&
                      item.checked === 1
                  )
                  .map((filteredItem) => filteredItem.choiced_item_id);

                const checkedChoiceItems = record.choices
                  .filter((item) => checkedItemsId.includes(item.id))
                  .map((filteredItem) => filteredItem.name);

                const checkedName =
                  checkedChoiceItems.length > 0
                    ? checkedChoiceItems.join("、")
                    : "";

                if (checkedName) {
                  return (
                    <tr key={record.id}>
                      <td className="subLabel">{record.name}</td>
                      <td className="topAlign">{checkedName}</td>
                      {!firstContentFlag && firstElement()}
                    </tr>
                  );
                }
                return null;
              }
              // セレクトボックス（複数選択）形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_select: {
                const checkedItems = operationData.operation_record_input
                  .filter(
                    (item) =>
                      item.custom_record_item_id === record.id && item.checked
                  )
                  .map((item) => item.choiced_staff_name_snapshot);
                const checkedItemsName = checkedItems
                  ? checkedItems.join("、")
                  : "";

                if (checkedItemsName) {
                  return (
                    <tr key={record.id}>
                      <td className="subLabel">{record.name}</td>
                      <td className="topAlign">{checkedItemsName}</td>
                      {!firstContentFlag && firstElement()}
                    </tr>
                  );
                }
                return null;
              }
              default:
                return null;
            }
          })}
        </tbody>
      </table>
    </>
  );
};

const DailyPrintOperationCustomRecordsCore = (props: Props): JSX.Element => {
  const {
    classes,
    facilityUnitId,
    date,
    operation,
    sortOperationCustomRecords,
    customRecords,
    optionalCustomInfo,
    unitName,
    serviceType
  } = props;

  return (
    <div className={classes.page}>
      <header>
        <h1 className={classes.title}>業務日誌</h1>
      </header>
      <div
        className={
          serviceType === FacilityType.GROUP_HOME
            ? classes.subTitleDateGroupHome
            : classes.subTitleDateTANKINYUSHO
        }
      >
        <span>{dateTodayInFormat(date, true)}</span>
      </div>
      {facilityUnitId.map((unitId) => {
        return (
          <OperationRecord
            key={unitId}
            classes={classes}
            unitId={unitId}
            operation={operation}
            sortOperationCustomRecords={sortOperationCustomRecords}
            customRecords={customRecords}
            optionalCustomInfo={optionalCustomInfo}
            unitName={unitId !== "0" ? unitName : ""}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { optionalCustomInfo } = state.units;
  return {
    optionalCustomInfo
  };
};

export const DailyPrintOperationCustomRecords = connect(mapStateToProps)(
  withStyles(styles)(DailyPrintOperationCustomRecordsCore)
);
