import { InitialDataValues } from "./initialValues";
import validator, { validateSwitcher } from "@validator";
import {
  InitialErrors,
  InoutResultsDetailsFields
} from "@interfaces/mgr/KODOENGO/report/initial";
import {
  KODOENGO_INPUT_CLASS_LIST,
  KODOENGO_MEMBER_LIST
} from "@constants/mgr/KODOENGO/variables";
import checkTimeFuture from "@validator/rules/checkTimeFuture";
import { checkTimeRange } from "@validator/rules/checkTimeRange";
import convertHHMMToMinutes from "@utils/date/convertHHMMToMinutes";
import checkTime from "@validator/rules/checkTime";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import format from "date-fns/format";
import { SelectValue } from "@interfaces/ui/form";

type InitialDataErrors = InitialErrors;

const submitCarePlanDetailsValidation = (
  data: InitialDataErrors["initial"]["inoutResultsDetails1"]
): boolean => {
  return (
    data === undefined ||
    data.filter((row) => {
      return row !== undefined;
    }).length === 0
  );
};

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.practitioner1InTime === undefined &&
      validationResult.initial.practitioner1OutTime === undefined &&
      validationResult.initial.practitioner1Memo === undefined &&
      validationResult.initial.practitioner2InTime === undefined &&
      validationResult.initial.practitioner2OutTime === undefined &&
      validationResult.initial.practitioner2Memo === undefined &&
      validationResult.initial.emergencySupportFlgError === undefined &&
      submitCarePlanDetailsValidation(
        validationResult.initial.inoutResultsDetails1
      ) &&
      submitCarePlanDetailsValidation(
        validationResult.initial.inoutResultsDetails2
      )
    );
  }
  return false;
};

/**
 * サービス提供が契約終了日以内かのチェック
 */
const dateInServiceValidation = (
  date: string,
  usersInFacility: UsersInFacilityState
): string | undefined => {
  const targetEnd = Number(
    format(convertBlankSeparatorFormatToDate(date), "YYYYMMDD")
  );
  const serviceEndDate = usersInFacility.user.user_in_facility.date_pay_end
    ? Number(
        format(usersInFacility.user.user_in_facility.date_pay_end, "YYYYMMDD")
      )
    : null;

  if (serviceEndDate && serviceEndDate < targetEnd) {
    return "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください";
  }

  return undefined;
};

/**
 * 重複チェック
 */
const validateDuplicateTime = (
  startTime1: string,
  endTime1: string,
  startTime2: string,
  endTime2: string,
  inputClass: SelectValue
): string | undefined => {
  if (
    !(
      startTime1 &&
      endTime1 &&
      startTime2 &&
      endTime2 &&
      !checkTime(startTime1) &&
      !checkTime(endTime1) &&
      !checkTime(startTime2) &&
      !checkTime(endTime2)
    )
  ) {
    return undefined;
  }
  const rangFlg1 = checkTimeRange(
    startTime1,
    "0",
    endTime1,
    "0",
    startTime2,
    "0"
  );
  const rangFlg2 = checkTimeRange(
    startTime1,
    "0",
    endTime1,
    "0",
    endTime2,
    "0"
  );
  const rangFlg3 = checkTimeRange(
    startTime2,
    "0",
    endTime2,
    "0",
    startTime1,
    "0"
  );
  const equalFlg1 =
    convertHHMMToMinutes(startTime1) === convertHHMMToMinutes(endTime2);
  const equalFlg2 =
    convertHHMMToMinutes(startTime1) === convertHHMMToMinutes(endTime1);

  if ((!!rangFlg1 && !!rangFlg2 && !!rangFlg3) || equalFlg1 || equalFlg2) {
    const modalName =
      inputClass === KODOENGO_INPUT_CLASS_LIST.RESULT.value
        ? "サービス提供実績"
        : "行動援護計画";
    return `1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の${modalName}として入力してください。`;
  }

  return undefined;
};

/**
 * 終了時間の基本チェック
 */
const endTimeValidation = (
  date: string,
  startTime1: string,
  endTime1: string,
  startTime2: string,
  endTime2: string,
  usersInFacility: UsersInFacilityState,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  inputClass: SelectValue,
  secondPersonFlg = false,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  const endTime = secondPersonFlg ? endTime2 : endTime1;
  let endTimeError = validator(endTime, "required", "checkTime");
  if (!endTimeError) {
    endTimeError = checkTimeFuture(endTime, startTime, option);
  }
  if (!endTimeError) {
    endTimeError = dateInServiceValidation(date, usersInFacility);
  }
  if (!endTimeError && numberOfParticipants === "2" && !licenseSameFlg) {
    endTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      startTime2,
      endTime2,
      inputClass
    );
  }

  return endTimeError;
};

/**
 * 開始時間の基本チェック
 */
const startTimeValidation = (
  startTime1: string,
  endTime1: string,
  startTime2: string,
  endTime2: string,
  numberOfParticipants: string,
  licenseSameFlg: boolean,
  inputClass: SelectValue,
  secondPersonFlg = false
): string | undefined => {
  const startTime = secondPersonFlg ? startTime2 : startTime1;
  let startTimeError = validator(startTime, "required", "checkTime");
  if (!startTimeError && numberOfParticipants === "2" && !licenseSameFlg) {
    startTimeError = validateDuplicateTime(
      startTime1,
      endTime1,
      startTime2,
      endTime2,
      inputClass
    );
  }

  return startTimeError;
};

const detailTimeValidation = (
  targetTime: string,
  details: InoutResultsDetailsFields[],
  idx: number,
  startFlg: boolean
): string | undefined => {
  const result = details
    .filter((row, i) => {
      return i !== idx && row.inTime && row.outTime;
    })
    .map((row) => {
      const flg = startFlg
        ? row.outTime === targetTime
        : row.inTime === targetTime;
      return flg
        ? "範囲内"
        : checkTimeRange(row.inTime, "0", row.outTime, "0", targetTime, "0");
    })
    .filter((row) => {
      return row === undefined;
    });

  return result && result.length > 0
    ? "空き時間は同一時間帯で重複しないように入力してください"
    : undefined;
};

const inoutResultsDetailsValidation = (
  baseInTime: string,
  baseOutTime: string,
  details: InoutResultsDetailsFields[]
): InitialErrors["initial"]["inoutResultsDetails1"] => {
  return details.map((row, idx) => {
    let inTimeError = validator(row.inTime, "required", "checkTime", {
      type: "checkTimeRange",
      startTime: baseInTime,
      startTimeClass: "0",
      endTime: baseOutTime,
      endTimeClass: "0",
      targetTime: row.inTime,
      targetTimeClass: "0",
      equalityOperatorFlag: false,
      option: "空き時間"
    });
    if (!inTimeError) {
      inTimeError = detailTimeValidation(row.inTime, details, idx, true);
    }

    let outTimeError = validator(row.outTime, "required", "checkTime");
    if (!outTimeError) {
      outTimeError = checkTimeFuture(row.outTime, row.inTime);
    }
    if (!outTimeError) {
      outTimeError = checkTimeRange(
        baseInTime,
        "0",
        baseOutTime,
        "0",
        row.outTime,
        "0",
        false,
        "空き時間"
      );
    }
    if (!outTimeError) {
      outTimeError = detailTimeValidation(row.outTime, details, idx, false);
    }

    return inTimeError || outTimeError
      ? {
          inTime: inTimeError,
          outTime: outTimeError
        }
      : undefined;
  });
};

const practitioner1Validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialErrors => {
  return {
    initial: {
      practitioner1InTime: startTimeValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner2InTime,
        values.initial.practitioner2OutTime,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg,
        values.initial.inputClass
      ),
      practitioner1OutTime: endTimeValidation(
        values.initial.targetDate,
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.practitioner2InTime,
        values.initial.practitioner2OutTime,
        usersInFacility,
        values.initial.numberOfParticipants,
        values.initial.licenseSameFlg,
        values.initial.inputClass
      ),
      inoutResultsDetails1: inoutResultsDetailsValidation(
        values.initial.practitioner1InTime,
        values.initial.practitioner1OutTime,
        values.initial.inoutResultsDetails1
      ),
      practitioner1Memo: validator(values.initial.practitioner1Memo, {
        type: "checkCharacterLength",
        length: 50
      })
    }
  };
};

const practitioner2Validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialErrors => {
  if (values.initial.numberOfParticipants === KODOENGO_MEMBER_LIST.ONE.value) {
    return { initial: {} };
  }

  return {
    initial: {
      practitioner2InTime: validateSwitcher(
        !values.initial.licenseSameFlg,
        startTimeValidation(
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner2InTime,
          values.initial.practitioner2OutTime,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          values.initial.inputClass,
          true
        )
      ),
      practitioner2OutTime: validateSwitcher(
        !values.initial.licenseSameFlg,
        endTimeValidation(
          values.initial.targetDate,
          values.initial.practitioner1InTime,
          values.initial.practitioner1OutTime,
          values.initial.practitioner2InTime,
          values.initial.practitioner2OutTime,
          usersInFacility,
          values.initial.numberOfParticipants,
          values.initial.licenseSameFlg,
          values.initial.inputClass,
          true
        )
      ),
      inoutResultsDetails2: validateSwitcher(
        !values.initial.licenseSameFlg,
        inoutResultsDetailsValidation(
          values.initial.practitioner2InTime,
          values.initial.practitioner2OutTime,
          values.initial.inoutResultsDetails2
        )
      ),
      practitioner2Memo: validator(values.initial.practitioner2Memo, {
        type: "checkCharacterLength",
        length: 50
      })
    }
  };
};

/**
 * 緊急時対応のバリデーション
 *
 * 汎用的なチェックではないため、独自対応
 */
const emergencySupportFlgValidation = (
  values: InitialDataValues
): InitialErrors => {
  let errorFlg;
  if (values.initial.inputClass === KODOENGO_INPUT_CLASS_LIST.RESULT.value) {
    if (
      values.initial.emergencySupportFlg &&
      values.initial.inoutResultsPlanId
    ) {
      errorFlg = "緊急時対応の場合、計画は設定できません";
    } else if (
      !values.initial.emergencySupportFlg &&
      !values.initial.inoutResultsPlanId
    ) {
      errorFlg = "緊急時対応以外は計画なしで実績は登録できません";
    }
  }

  return {
    initial: { emergencySupportFlgError: errorFlg }
  };
};

export const validation = (
  values: InitialDataValues,
  usersInFacility: UsersInFacilityState
): InitialDataErrors => {
  const practitioner1Errors = practitioner1Validation(values, usersInFacility);
  const practitioner2Errors = practitioner2Validation(values, usersInFacility);
  const emergencySupportFlgError = emergencySupportFlgValidation(values);

  return {
    initial: {
      ...practitioner1Errors.initial,
      ...practitioner2Errors.initial,
      ...emergencySupportFlgError.initial
    }
  };
};
