import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 対象ユーザーの支援計画シートを削除する。
 * @param uifId ユーザーID
 * @param supportPlanSheetId supportPlanSheetId
 */
export const deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId = async (
  uifId: string,
  supportPlanSheetId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_plan_sheets/${supportPlanSheetId}`;
  return request.delete(url);
};
