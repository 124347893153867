import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { DownloadAction } from "@components/organisms/download/DownloadInvoiceSection";
import {
  DownloadableMonth,
  DownloadableUser
} from "@stores/domain/invoice/type";
import { Typography } from "@material-ui/core";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  DOWNLOAD_FILE_TYPE
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { DownloadButton } from "@components/organisms/mgr/KEIKAKUSODAN/download/DownloadButton";

type ExtractUserDetails = Pick<
  DownloadableUser,
  "targetForUserCostAmountList" | "targetForUpLimit"
>;

const styles = (): StyleRules =>
  createStyles({
    invoiceTitle: {
      fontSize: "20px",
      color: "#37474f",
      fontWeight: 500,
      margin: "32px 0 16px 0"
    },
    description: {
      fontSize: 16,
      color: "#37474f"
    },
    buttonContainer: {
      display: "flex",
      flexWrap: "wrap"
    }
  });

type OwnProps = {
  facilityType: number | null;
  targetMonth: string;
  downloadableMonthList: DownloadableMonth[];
  downloadCsvActions: DownloadAction[];
  downloadPrintActions: DownloadAction[];
  excludedUserIds: number[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadInvoiceSectionCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const filteredUsers = (
    downloadableMonth: DownloadableMonth[],
    targetMonth: string
  ): DownloadableUser[] => {
    const ret: DownloadableUser[] = [];
    downloadableMonth
      .filter((v) => v.date === targetMonth)
      .forEach(({ results }) => {
        results.forEach(({ users }) => {
          ret.push(...users);
        });
      });
    return ret;
  };

  const extractUserDetails = (
    users: DownloadableUser[],
    excludedUserIds: number[]
  ): ExtractUserDetails => {
    const targetUsers = users.filter((user) => {
      const isExcluded = excludedUserIds.some((id) => id === user.id);
      const hasRecipientNumber = !user.none_recipient_number_flg;
      return !isExcluded && hasRecipientNumber;
    });

    const targetForUserCostAmountList = targetUsers.some(
      (v) => v.targetForUserCostAmountList
    );
    const targetForUpLimit = targetUsers.some((v) => v.targetForUpLimit);

    return { targetForUserCostAmountList, targetForUpLimit };
  };

  const renderDownloadButtons = (
    actions: DownloadAction[],
    label: string,
    userDetails: ExtractUserDetails
  ): JSX.Element => {
    const filteredActions = actions.filter((v) => v.label.includes(label));
    return (
      <div className={classes.buttonContainer}>
        {filteredActions.map((action) => {
          const actionLabel = action.label;
          const isDisplay =
            actionLabel.includes(DOWNLOAD_FILE_TYPE.jisseki) ||
            (actionLabel.includes(DOWNLOAD_FILE_TYPE.costAmountList) &&
              userDetails.targetForUserCostAmountList) ||
            (actionLabel.includes(DOWNLOAD_FILE_TYPE.upLimit) &&
              userDetails.targetForUpLimit);

          return (
            <DownloadButton
              key={`download_${actionLabel}`}
              action={action}
              isDisplay={isDisplay}
            />
          );
        })}
      </div>
    );
  };

  const targetUsers = filteredUsers(
    props.downloadableMonthList,
    props.targetMonth
  );
  const userDetails = extractUserDetails(targetUsers, props.excludedUserIds);

  const isKeikakuFacility =
    props.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
    props.facilityType === FACILITY_TYPE_KEIKAKUSODAN;

  const isShogaijiFacility =
    props.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN ||
    props.facilityType === FACILITY_TYPE_SHOGAIJISODAN;

  return (
    <>
      <Typography component="h2" variant="h2" className={classes.invoiceTitle}>
        国保連への請求書類ダウンロード
      </Typography>
      <Typography component="p" variant="body2" className={classes.description}>
        各種請求書類はCSVファイルでダウンロードされます。
        <br />
        ダウンロードしたCSVファイルは「取り込み送信システム」にそのままアップロードしてください。
      </Typography>
      {isKeikakuFacility &&
        renderDownloadButtons(
          props.downloadCsvActions,
          "計画相談支援",
          userDetails
        )}
      {isShogaijiFacility &&
        renderDownloadButtons(
          props.downloadCsvActions,
          "障害児相談支援",
          userDetails
        )}
      <Typography component="h2" variant="h2" className={classes.invoiceTitle}>
        請求書類の印刷
      </Typography>
      <Typography component="p" variant="body2" className={classes.description}>
        サービス提供実績や請求の詳細内容を規定のフォーマットで印刷されます。
        <br />
        各種請求内容の確認や保管などにご利用ください。
      </Typography>
      {isKeikakuFacility &&
        renderDownloadButtons(
          props.downloadPrintActions,
          "計画相談支援",
          userDetails
        )}
      {isShogaijiFacility &&
        renderDownloadButtons(
          props.downloadPrintActions,
          "障害児相談支援",
          userDetails
        )}
    </>
  );
};

export const DownloadInvoiceSection = withStyles(styles)(
  DownloadInvoiceSectionCore
);
