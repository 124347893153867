import * as types from "./types";
import { GetPaginatedDailySupports } from "@api/requests/supports/SHISETSUNYUSHO/getPaginatedDailySupports";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchPaginatedDailySupportsStarted = () =>
  ({ type: types.FETCH_PAGINATED_DAILY_SUPPORTS_STARTED } as const);
export const fetchPaginatedDailySupportsSuccess = (
  res: GetPaginatedDailySupports["data"]
) =>
  ({
    type: types.FETCH_PAGINATED_DAILY_SUPPORTS_SUCCESS,
    payload: res
  } as const);
export const fetchPaginatedDailySupportsFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_PAGINATED_DAILY_SUPPORTS_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchPaginatedDailySupportsStarted>
  | ReturnType<typeof fetchPaginatedDailySupportsSuccess>
  | ReturnType<typeof fetchPaginatedDailySupportsFailed>;
