import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  reportMonthly: {
    inoutConsultationResults: [],
    summary: {
      intensiveSupportCount: 0
    }
  }
};

export const CHIIKIIKOReport = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_CHIIKIIKO_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_CHIIKIIKO_MONTHLY:
      return {
        ...state,
        reportMonthly: action.payload
      };
    case types.FETCH_CHIIKIIKO_MONTHLY_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};
