import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate201910 } from "@components/templates/AdminTemplate201910";
import InOutReportUser from "@components/v201910/organisms/mgr/JIRITSUKUNRENSEIKATSU/report/InOutReportUser";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import {
  INVOICE_PATHS,
  JIRITSUKUNRENSEIKATSUReportTabInfo,
  JIRITSUKUNRENSEIKATSU_REPORT_TABS_INFO
} from "@constants/mgr/JIRITSUKUNRENSEIKATSU/variables";
import * as URL from "@constants/url";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import { FacilityType, INVOICE_VERSION_V201910 } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{}, {}, { targetDate: Date }> &
  WithStyles<typeof styles>;

/**
 * 利用実績
 */
const Report: React.FunctionComponent<Props> = (props: Props) => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();
  // 報酬改定のバージョン変更が必要だったら変更後のページを表示
  const currentPageVersion = INVOICE_VERSION_V201910;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.JIRITSUKUNRENSEIKATSU,
    currentPageVersion,
    INVOICE_PATHS.reportUsers,
    props.history
  );

  if (transitionDone) {
    return null;
  }

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === JIRITSUKUNRENSEIKATSUReportTabInfo.DAILY) {
      props.history.push(URL.REPORT_DAILY);
    }
  };
  return (
    <AdminTemplate201910 pageName="利用実績" zIndex>
      <KnowbeTabs
        key="tab"
        tabInfo={JIRITSUKUNRENSEIKATSU_REPORT_TABS_INFO}
        onChange={onChangeTag}
        value={JIRITSUKUNRENSEIKATSUReportTabInfo.MONTHLY}
      />
      <InOutReportUser
        initialDate={currentDate}
        history={props.history}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
    </AdminTemplate201910>
  );
};

export default withStyles(styles)(Report);
