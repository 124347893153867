/**
 * hh:mm形式チェック
 * @param value テキスト
 */
const checkTime = (value: string): string | undefined => {
  let errorMessage;
  if (
    value !== "" &&
    !(
      /^[0-9][0-9]:[0-5][0-9]$/.test(value) ||
      /^[0-9][0-9]:[0-5][0-9]$/.test(value)
    )
  ) {
    errorMessage = "00:00の形式で入力してください";
  }

  return errorMessage;
};

/**
 * 未来時刻かどうかをチェック(24時間限定ではない)
 *
 * @param endTime   // hh:mm
 * @param startTime // hh:mm
 * @param option
 */
export const checkTimeFutureHHMM = (
  endTime: string,
  startTime: string,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  let errorMessage;
  if (!!checkTime(startTime) || !!checkTime(endTime)) {
    return undefined;
  }
  const start = startTime.replace(":", "");
  const end = endTime.replace(":", "");

  if (parseInt(end, 10) - parseInt(start, 10) <= 0) {
    errorMessage = `${option.firstLabel}は${option.secondLabel}より後の時間を入力してください`;
  }
  return errorMessage;
};
