import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import HelpIcon from "@material-ui/icons/Help";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { FACILITY_COMBI_STATUS_ITEMS } from "@constants/mgr/TANKINYUSHO/variables";
import { FormikProps, getIn } from "formik";
import { FacilityValues } from "@initialize/mgr/TANKINYUSHO/facility/initialValues";
import { StyleRules } from "@material-ui/core/styles";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    },
    section: {
      marginBottom: 32
    },
    innerItem: {
      marginLeft: -20
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => {
  // 利用定員が20人以上である単独型事業所のdisabled-state
  const [disableLargeScaleFlg, setDisableLargeScaleFlg] = React.useState(false);
  // 施設併用状況が特定の値の時、利用定員が20人以上である単独型事業所が選択できる
  const facilityCombiStatus: string = getIn(
    props.formikProps.values,
    "subtractionItem.facilityCombiStatus"
  );
  React.useEffect(() => {
    const disabled = facilityCombiStatus !== "0";
    setDisableLargeScaleFlg(disabled);
  }, [facilityCombiStatus]);
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      const init = { year: "NOT_SELECTED", month: "", day: "" };
      switch (event.target.name) {
        case "subtractionItem.bodyRestrictedStillFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillTo",
            init
          );
          break;
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            init
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            init
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            init
          );
          break;
        default:
      }
    }
  };
  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikRadioButtons
        name="subtractionItem.facilityCombiStatus"
        label="施設併用状況"
        options={FACILITY_COMBI_STATUS_ITEMS}
        style={{ marginBottom: 0 }}
      />
      <FormikCheckbox
        name="subtractionItem.largeScaleFlg"
        label="利用定員数が20人以上である単独型事業所"
        style={{ paddingLeft: 16, marginTop: -5 }}
        disabled={disableLargeScaleFlg}
      />
      <FormikSwitch
        name="subtractionItem.lackOfLifeSupportMemberFlag"
        label="従業員の欠員"
        tooltip={
          <HelpToolTip title={<HelpTipMessages name="lackOfSupporterFlag" />} />
        }
      >
        <div className={props.classes.innerItem}>
          <FormikSelectYearMonth
            name="subtractionItem.lackOfLifeSupportMemberStartDate"
            label="減算適応開始月"
          />
          <div className={props.classes.comment}>
            <p>
              減算適応月については該当項目の
              <HelpIcon className={props.classes.helpIcon} />
              を参照してください
            </p>
          </div>
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.bodyRestrictedStillFlg"
        label="身体拘束廃止未実施減"
        onChange={onChangeSwitch}
      >
        <div className={props.classes.innerItem}>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <div className={props.classes.innerItem}>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.workContinuationNotPlanningFlg"
        label="業務継続計画未策定減算"
        onChange={onChangeSwitch}
      >
        <div className={props.classes.innerItem}>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        style={{ marginBottom: 0 }}
        onChange={onChangeSwitch}
      >
        <div className={props.classes.innerItem}>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </div>
      </FormikSwitch>
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
