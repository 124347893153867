import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { generateRadioItems } from "@utils/dataNormalizer";
import { FormikProps, getIn } from "formik";
import { UsersValues } from "@initialize/mgr/JIRITSUKUNRENSEIKATSU/users/initialValues";
import {
  INDIVIDUAL_PLAN_TYPE_0,
  individualTrainingImplementationPlan202404Types
} from "@constants/mgr/JIRITSUKUNRENSEIKATSU/variables";

const individualTrainingImplementationPlan202404TypeRadioItems = generateRadioItems(
  individualTrainingImplementationPlan202404Types
);

const styles = (): StyleRules =>
  createStyles({
    dateWrap: {
      margin: "-20px 0 30px 32px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 個別計画訓練支援加算
 */
const IndividualTrainingImplementationPlanFields: React.FC<Props> = (props) => {
  const value = getIn(
    props.formikProps.values,
    "serviceUse.individualTrainingImplementationPlan20240401"
  );
  const { setFieldValue } = props.formikProps;

  // 変更時、開始・終了年月日は初期値に戻す
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.currentTarget.value === INDIVIDUAL_PLAN_TYPE_0) {
      const init = { year: "NOT_SELECTED", month: "", day: "" };
      setFieldValue(
        "serviceUse.individualTrainingImplementationPlan20240401From",
        init
      );
      setFieldValue(
        "serviceUse.individualTrainingImplementationPlan20240401To",
        init
      );
    }
  };

  return (
    <div>
      <FormikRadioButtons
        name="serviceUse.individualTrainingImplementationPlan20240401"
        label="個別計画訓練支援加算"
        options={individualTrainingImplementationPlan202404TypeRadioItems}
        onChangeHook={onChangeRadio}
      />
      {value !==
        individualTrainingImplementationPlan202404TypeRadioItems[0].value && (
        <div className={props.classes.dateWrap}>
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.individualTrainingImplementationPlan20240401From"
            label="開始年月日"
            setFormikFieldValue={props.formikProps.setFieldValue}
            addYearTo={1}
            overrideYearFrom={2024}
            style={{ marginBottom: 22 }}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="serviceUse.individualTrainingImplementationPlan20240401To"
            label="終了年月日（任意）"
            setFormikFieldValue={props.formikProps.setFieldValue}
            addYearTo={5}
            overrideYearFrom={2024}
            style={{ marginBottom: 0 }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(
  withStyles(styles)(IndividualTrainingImplementationPlanFields)
);
