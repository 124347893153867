import { SelectMonthValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * SelectMonthValue => string (YYYY-MM)
 * 月を0埋めする
 */
export const selectMonthValueToDatePaddingZero = (
  value: SelectMonthValue
): string | null => {
  return value.year && value.month
    ? dateToLocalisedString(`${value.year}-${value.month}`, "YYYYMM")
    : null;
};
