import React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Dialog from "@material-ui/core/Dialog";
import Divider from "@material-ui/core/Divider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import Table, { CellParam as HeadCellParam } from "@components/molecules/Table";
import TableHead from "@components/molecules/TableHead";

import { DownloadableUser } from "@stores/domain/invoice/type";
import { FacilityType } from "@constants/variables";

import RadioButtons from "@components/atoms/RadioButtons";
import { UplimitCreateType } from "@stores/ui/download/type";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      overflowX: "auto"
    },
    title: {
      padding: "14px 32px"
    },
    content: {
      width: 680,
      marginTop: spacing.unit * 2,
      padding: "0px"
    },
    table: {
      width: "100%",
      minWidth: 550
    },
    rowSubHeader: {
      height: 48,
      borderTop: "1px solid white"
    },
    rowSubHeaderCell: {
      backgroundColor: "#eceff1"
    },
    rowOdd: {
      backgroundColor: "#f6f7f8"
    },
    rowEven: {
      backgroundColor: "#ffffff"
    },
    cellNumber: {
      width: 139,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      border: "none",
      fontSize: "16px",
      boxSizing: "content-box"
    },
    cellUsers: {
      width: "200px",
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      borderColor: "rgba(0, 0, 0, 0.12)",
      fontSize: "16px",
      border: "none"
    },
    cellUplimitTypes: {
      width: "auto",
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      border: "none",
      fontSize: "16px"
    },
    border: {
      padding: "8px 0px",
      color: "rgba(0, 0, 0, 0.12)",
      borderTop: "solid 2px",
      margin: "0px"
    },
    labelNumber: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: 139,
      boxSizing: "content-box"
    },
    labelUsers: {
      fontSize: 14,
      padding: "0 0 0 16px",
      width: "auto",
      marginLight: 16,
      textAlign: "left"
    },
    scroll: {
      overflowY: "scroll",
      overflowX: "hidden",
      height: 530,
      // スクロールバーのデザイン対応のため（Googole Chromeのみの適用）
      "&::-webkit-scrollbar": {
        width: 12
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    cancelButton: {
      width: 125,
      border: "solid 1px #cccccc"
    },
    submitButton: {
      width: 125,
      marginRight: 32
    },
    fraction: {
      fontSize: "12px"
    },
    facilityName: {
      backgroundColor: "#eceff1"
    },
    facilityNameCellBody: {
      margin: "10px 0",
      display: "flex",
      justifyContent: "space-between"
    },
    radioLabelClass: {
      paddingRight: "14px"
    },
    radioGroupClass: {
      margin: "0px",
      display: "flex",
      flexDirection: "row"
    },
    radioButtonsClass: {
      margin: "0px"
    }
  });

interface Props extends WithStyles<typeof styles> {
  labelId?: string;
  open: boolean;
  describeId?: string;
  onSubmit: () => void;
  onClose: () => void;
  users: { [P in string]: DownloadableUser[] };
  facilityNames: { [P in string]: string };
  isMultipleFacility: boolean;
  isMasterSubordinate: boolean;
  excludedUserIds: number[];
  userIdToUplimitCreateType: Map<number, UplimitCreateType>;
  facilityType: FacilityType;
  onChangeUplimitCreateType: (
    userId: number,
    uplimitType: UplimitCreateType
  ) => void;
}

const dialogFacilityData = (
  facilityType: string,
  excludedUserIds: number[],
  props: Props
): JSX.Element[] => {
  const users = props.users[facilityType];
  const { classes } = props;
  if (!users) {
    return [];
  }
  const targetUsers = users.filter(
    (user) => !excludedUserIds.includes(user.id) && user.targetForUpLimit
  );
  const facilityName = props.facilityNames[facilityType];
  if (!facilityName || !targetUsers.length) return [];

  return [
    <TableRow
      className={classes.rowSubHeader}
      role="checkbox"
      aria-checked
      tabIndex={-1}
      key={`uplimit-create-type-facility-${facilityName}`}
    >
      <TableCell className={classes.rowSubHeaderCell} colSpan={4}>
        <div className={classes.facilityNameCellBody}>
          <div>{facilityName}</div>
        </div>
      </TableCell>
    </TableRow>
  ];
};

const dialogHeaderData = (props: Props): HeadCellParam[] => {
  return [
    {
      className: props.classes.labelNumber,
      align: "left",
      label: "受給者番号"
    },
    {
      className: props.classes.labelUsers,
      align: "left",
      label: "利用者名"
    },
    {
      className: "",
      align: "left",
      label: ""
    }
  ];
};

const dialogContentData = (
  facilityType: string,
  props: Props
): JSX.Element[] => {
  const users = props.users[facilityType];
  if (!users) return [];
  const {
    rowOdd,
    rowEven,
    cellNumber,
    cellUsers,
    cellUplimitTypes,
    radioGroupClass,
    radioLabelClass,
    radioButtonsClass
  } = props.classes;
  const { excludedUserIds } = props;
  const targetUsers = users.filter(
    (user) => !excludedUserIds.includes(user.id) && user.targetForUpLimit
  );
  targetUsers.sort((u1, u2) => {
    // 受給者番号の昇順
    if (u1.recipientNumber < u2.recipientNumber) {
      return -1;
    }
    if (u1.recipientNumber > u2.recipientNumber) {
      return 1;
    }
    return 0;
  });

  return targetUsers.map((user, i) => {
    const idx = user.id;
    return (
      <TableRow
        className={i % 2 === 0 ? rowEven : rowOdd}
        role="checkbox"
        aria-checked
        tabIndex={-1}
        key={`uplimit-create-type-user-${idx}`}
      >
        <TableCell
          key={`${idx}-uplimit-create-type-user-recipientNumber}`}
          align="left"
          className={cellNumber}
        >
          {user.recipientNumber}
        </TableCell>
        <TableCell
          key={`${idx}-uplimit-create-type-user-name}`}
          align="left"
          className={cellUsers}
        >
          {user.name}
        </TableCell>
        <TableCell
          key={`${idx}-uplimit-create-type`}
          className={cellUplimitTypes}
        >
          <RadioButtons
            classes={{ formControl: radioButtonsClass }}
            value={
              props.userIdToUplimitCreateType.get(user.id) ||
              UplimitCreateType.CREATE
            }
            name=""
            onChange={(_, value): void =>
              props.onChangeUplimitCreateType(
                user.id,
                value as UplimitCreateType
              )
            }
            labelClass={radioLabelClass}
            radioGroupClass={radioGroupClass}
            radioItems={[
              { label: "新規", value: UplimitCreateType.CREATE },
              { label: "修正", value: UplimitCreateType.MODIFY },
              { label: "取消", value: UplimitCreateType.CANCEL }
            ]}
          />
        </TableCell>
      </TableRow>
    );
  });
};

/**
 * 多機能型ではない場合の内容行作成
 */
const createSingleFacilityRows = (props: Props): JSX.Element[] => {
  const { excludedUserIds } = props;
  const facilityTypes = Object.keys(props.users);
  const facilityName = dialogFacilityData(
    facilityTypes[0],
    excludedUserIds,
    props
  );
  return facilityName.concat(dialogContentData(facilityTypes[0], props));
};

/**
 * 多機能型の内容行作成
 */
const createMultipleFacilityRows = (props: Props): JSX.Element[][] => {
  const facilityTypes = Object.keys(props.users);
  const { excludedUserIds } = props;
  return facilityTypes.map((facilityType) => {
    const facilityName = dialogFacilityData(
      facilityType,
      excludedUserIds,
      props
    );
    const userData = dialogContentData(facilityType, props);
    return facilityName.concat(userData);
  });
};

const UplimitCreateTypeSelectDialog = (props: Props): JSX.Element => {
  const { classes } = props;
  const rows =
    props.isMultipleFacility || props.isMasterSubordinate
      ? createMultipleFacilityRows(props)
      : createSingleFacilityRows(props);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="lg"
      className={classes.root}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id={props.labelId} className={classes.title}>
        作成区分コード選択
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div>
          <Table
            key="uplimit-create-type-users-table-head"
            className={classes.table}
          >
            <TableHead
              role="checkbox"
              ariaChecked
              tabIndex={-1}
              key={1}
              selected
              items={dialogHeaderData(props)}
              rowStyle={{ height: 32 }}
            />
          </Table>
        </div>
        <div className={classes.scroll}>
          <Table
            key="uplimit-create-type-users-table"
            className={classes.table}
          >
            <TableBody>{rows}</TableBody>
          </Table>
        </div>
      </DialogContent>
      <DialogActions className={classes.border}>
        <Button
          className={classes.cancelButton}
          onClick={props.onClose}
          autoFocus={false}
          color="secondary"
          variant="text"
        >
          キャンセル
        </Button>
        <Button
          className={classes.submitButton}
          onClick={props.onSubmit}
          autoFocus
          color="secondary"
          variant="contained"
        >
          保存する
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(UplimitCreateTypeSelectDialog);
