import React, { useState, useEffect } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules,
  Theme
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
// store
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import {
  ServiceDeliveryState,
  SERVICE_DELIVERY_DAILY
} from "@stores/domain/serviceDelivery/types";
import { UserState } from "@stores/domain/user/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SupportsState } from "@stores/domain/supports/types";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";
// ui
import Paper from "@material-ui/core/Paper";
import { ServiceDeliveryDailyHeader } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDailyHeader";
import { ServiceDeliveryTable } from "@components/organisms/record/serviceDelivery/ServiceDeliveryTable";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { UserSummaryModal } from "@components/organisms/record/serviceDelivery/UserSummaryModal";
import { ServiceDeliveryTable as ServiceDeliveryTableKodoengo } from "@components/organisms/mgr/KODOENGO/record/ServiceDeliveryTable";
import { ServiceDeliveryTable as ServiceDeliveryTableIdoshien } from "@components/organisms/mgr/IDOSHIEN/record/ServiceDeliveryTable";
// utils
import {
  dateInYYYYMMDDFormat,
  compareDateFuture,
  dateToLocalisedString
} from "@utils/date";
import format from "date-fns/format";
import {
  RECORD_MODAL_TYPE,
  RECORD_MODAL_TYPE_TARGET,
  FacilityType,
  SERVICE_DELIVERY_PRINT_TYPE_OPTIONS
} from "@constants/variables";
import { getStaffCommentLabelWithCategory } from "@utils/domain/customRecords/getStaffCommentLabelWithCategory";
import * as URL from "@constants/url";
import {
  NO_RECORD_MESSAGE_SERVICE_DELIVERY,
  NO_USER_MESSAGE_SERVICE_DELIVERY
} from "@constants/mgr/KYOTAKUKAIGO/variables";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8,
      width: "100%"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: `24px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    createButtonWrapper: {
      display: "flex",
      justifyContent: "flex-end"
    },
    createButton: {
      marginBottom: "8px",
      width: "180px"
    },
    noPlanAndNoRecordFuture: {
      height: "124px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    dialogContent: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 32px 16px"
    }
  });

type OwnProps = {
  selectedDate: Date;
  history: RouteComponentProps["history"];
};

type StateProps = {
  serviceDeliveryDailyState: ServiceDeliveryState["dailyRecord"];
  customRecords: CustomRecordsWithCategoryState;
  supportsUsers: SupportsState["report"]["users"];
  userState: UserState;
  facilityUsers: GetFacilityUsersListResponse["data"];
};

type DispatchProps = {
  fetchDailyRecord: (params: string) => Promise<void>;
  deleteServiceDelivery: (serviceDeliveryRecordsId: number) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchReportUsers: (target: string, month: string) => void;
  fetchFacilityUserList: (date: Date) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const currentDate = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDate.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

const ServiceDeliveryDailyRecordCore = (props: Props): JSX.Element | null => {
  const { selectedDate } = props;
  const [headerHeight, setHeaderHeight] = useState(0);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState<number>(0);
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState(false);
  const selectedDateYYYYMMDD = format(selectedDate, "YYYY-MM-DD");
  const selectedDateYYYYMM = format(selectedDate, "YYYYMM");

  const getUrl = (date?: Date): string => {
    const urlDate = date || selectedDate;
    const yyyymmdd = dateToLocalisedString(urlDate, "YYYYMMDD");
    return `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${yyyymmdd}`;
  };

  const isNoRecordFuture =
    props.serviceDeliveryDailyState.service_delivery.length === 0 &&
    compareDateFuture(currentDate, selectedDate);

  const isNoRecordNoUser =
    props.serviceDeliveryDailyState.service_delivery.length === 0 &&
    props.facilityUsers.length === 0;

  // 情報取得
  useEffect((): void => {
    props.fetchFacilityUserList(selectedDate);
    props.fetchDailyRecord(dateInYYYYMMDDFormat(selectedDate));
  }, [selectedDate]);

  useEffect((): void => {
    const top = document.getElementById("serviceDeliveryRecordHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, []);

  const onChangeDate = (date: Date): void => {
    props.history.replace(getUrl(date));
  };

  const getReportUsers = (reportType: string): void => {
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      if (reportType === SERVICE_DELIVERY_PRINT_TYPE_OPTIONS[0].value) {
        props.fetchReportUsers(RECORD_MODAL_TYPE_TARGET[9], selectedDateYYYYMM);
      } else {
        props.fetchReportUsers(RECORD_MODAL_TYPE_TARGET[8], selectedDateYYYYMM);
      }
    }
  };

  // 支援手順書兼記録用紙が存在しているか
  const isSupportProcedureExist = props.serviceDeliveryDailyState.service_delivery.some(
    (item) =>
      item.procedure_status_flg !== 0 && item.procedure_status_flg !== undefined
  );

  // サービス提供が記録されているか
  const isServiceDeliveryExist = props.serviceDeliveryDailyState.service_delivery.some(
    (item) => item.service_delivery_records_id !== null
  );

  const openPrintModal = async (): Promise<void> => {
    if (
      props.userState.facility_type === FacilityType.KODOENGO &&
      isSupportProcedureExist
    ) {
      await props.fetchReportUsers(
        RECORD_MODAL_TYPE_TARGET[9],
        selectedDateYYYYMM
      );
    } else {
      await props.fetchReportUsers(
        RECORD_MODAL_TYPE_TARGET[8],
        selectedDateYYYYMM
      );
    }
    await props.fetchCustomRecords();
    setIsOpenPrintModal(true);
  };

  const closePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };

  const changeCreateMode = (): void => {
    props.history.push(
      `/record/${dateInYYYYMMDDFormat(selectedDate)}/service_delivery/new`
    );
  };

  const changeEditMode = (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    uifId: number,
    supportProcedureFormsId?: number
  ): void => {
    const paramDate = format(targetDate, "YYYYMMDD");
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${supportProcedureFormsId}/${uifId}`
      );
    } else {
      props.history.push(
        `/record/${paramDate}/service_delivery/edit/${serviceDeliveryRecordsId}/${inoutResultsId}/${uifId}`
      );
    }
  };

  // 削除モーダル操作
  const openDeleteModal = (serviceDeliveryRecordsId: number): void => {
    setDeleteTargetId(serviceDeliveryRecordsId);
    setIsOpenDeleteDialog(true);
  };

  const handleCancel = (): void => {
    setIsOpenDeleteDialog(false);
  };

  const onDelete = async (): Promise<void> => {
    await props.deleteServiceDelivery(deleteTargetId);
    await props.fetchDailyRecord(dateInYYYYMMDDFormat(selectedDate));
    setIsOpenDeleteDialog(false);
  };

  // 職員考察がカスタムされている場合にラベル名を取得
  const staffCommentLabel = getStaffCommentLabelWithCategory({
    customRecords: props.customRecords,
    facilityType: props.userState.facility_type
  });

  // 対象日付に記録がある利用者の絞り込み
  const recordedUsers: SupportsState["report"]["users"] = [];
  props.supportsUsers.forEach((user) => {
    if (
      user.serviceDeliveryDates &&
      user.serviceDeliveryDates.some((date) => date === selectedDateYYYYMMDD)
    ) {
      recordedUsers.push(user);
    } else if (
      user.procedureFormDates &&
      user.procedureFormDates.some((date) => date === selectedDateYYYYMMDD)
    ) {
      recordedUsers.push(user);
    }
  });

  const isDisabledPrint = !props.serviceDeliveryDailyState.service_delivery.some(
    (item) => item.recorded_flg === 1 || !!item.procedure_status_flg
  );

  const { classes } = props;
  const dialogMessage = (
    <span>
      データが完全に削除され、復元できません。
      <br />
      よろしいですか？
    </span>
  );
  const noRecordMessage = (): string => {
    if (isNoRecordNoUser) {
      return NO_USER_MESSAGE_SERVICE_DELIVERY;
    }
    if (props.userState.facility_type === FacilityType.KODOENGO) {
      return "計画または支援手順書兼記録用紙がありません。利用実績画面から計画を入力するか、支援手順書 兼 記録用紙画面から支援手順書 兼 記録用紙を作成後、ご利用ください。";
    }
    return NO_RECORD_MESSAGE_SERVICE_DELIVERY;
  };

  let serviceDeliveryComponent;

  if (props.userState.facility_type === FacilityType.KODOENGO) {
    serviceDeliveryComponent = (
      <ServiceDeliveryTableKodoengo
        records={props.serviceDeliveryDailyState}
        type={SERVICE_DELIVERY_DAILY}
        headerHeight={headerHeight}
        changeEditMode={changeEditMode}
        facilityType={props.userState.facility_type}
      />
    );
  } else if (props.userState.facility_type === FacilityType.IDOSHIEN) {
    serviceDeliveryComponent = (
      <ServiceDeliveryTableIdoshien
        records={props.serviceDeliveryDailyState}
        type={SERVICE_DELIVERY_DAILY}
        headerHeight={headerHeight}
        changeEditMode={changeEditMode}
        openDeleteModal={openDeleteModal}
      />
    );
  } else {
    serviceDeliveryComponent = (
      <ServiceDeliveryTable
        records={props.serviceDeliveryDailyState}
        type={SERVICE_DELIVERY_DAILY}
        headerHeight={headerHeight}
        changeEditMode={changeEditMode}
        openDeleteModal={openDeleteModal}
        facilityType={props.userState.facility_type}
      />
    );
  }

  return (
    <>
      <div id="serviceDeliveryRecordHeader" className={classes.headerWrapper}>
        <div className={classes.headerInfoContainer}>
          <ServiceDeliveryDailyHeader
            minDate={minDate}
            maxDate={maxDate}
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
            openPrintModal={openPrintModal}
            disabledPrint={isDisabledPrint}
          />
        </div>
      </div>

      <Paper elevation={0} className={classes.tableContainer}>
        {isNoRecordNoUser || isNoRecordFuture ? (
          <div className={classes.noPlanAndNoRecordFuture}>
            {noRecordMessage()}
          </div>
        ) : (
          <>
            <div className={classes.createButtonWrapper}>
              <KnowbeButton
                className={classes.createButton}
                onClick={changeCreateMode}
              >
                計画外の記録の追加
              </KnowbeButton>
            </div>
            {serviceDeliveryComponent}
          </>
        )}
      </Paper>
      {isOpenPrintModal && (
        <UserSummaryModal
          isModalOpen={isOpenPrintModal}
          onClose={closePrintModal}
          modalType={RECORD_MODAL_TYPE.serviceDeliveryDaily}
          targetUsers={recordedUsers}
          history={props.history}
          staffCommentLabel={staffCommentLabel}
          targetDate={selectedDate}
          facilityType={props.userState.facility_type}
          getReportUsers={getReportUsers}
          isSupportProcedureExist={isSupportProcedureExist}
          isServiceDeliveryExist={isServiceDeliveryExist}
        />
      )}
      {isOpenDeleteDialog && (
        <MessageDialog
          classes={{ content: props.classes.dialogContent }}
          isOpen={isOpenDeleteDialog}
          title="このサービス提供記録を削除します"
          message={dialogMessage}
          closeButton={
            <KnowbeButton kind="text" onClick={handleCancel}>
              キャンセル
            </KnowbeButton>
          }
          actionButton={
            <KnowbeButton kind="textDelete" onClick={onDelete}>
              削除する
            </KnowbeButton>
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    serviceDeliveryDailyState: state.serviceDelivery.dailyRecord,
    customRecords: state.customRecordsWithCategory.serviceDelivery,
    supportsUsers: state.supports.report.users,
    userState: state.user as UserState,
    facilityUsers: state.KYOTAKUKAIGO.userInFacility.usersList
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    KYOTAKUKAIGO,
    serviceDelivery,
    customRecordsWithCategory,
    supportsDispatcher
  } = dispatches;
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const supportsDispatches = supportsDispatcher(dispatch);
  const userInFacilityDispatcher = KYOTAKUKAIGO.userInFacilityDispatcher(
    dispatch
  );
  return {
    fetchDailyRecord: (param: string): Promise<void> =>
      serviceDeliveryDispatches.fetchDailyRecord(param),
    deleteServiceDelivery: (serviceDeliveryRecordsId: number): Promise<void> =>
      serviceDeliveryDispatches.deleteServiceDelivery(serviceDeliveryRecordsId),
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchReportUsers: (target: string, month: string): void => {
      supportsDispatches.fetchReportUsers(target, month);
    },
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetchTargetDay(date)
  };
};

export const ServiceDeliveryDailyRecord = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceDeliveryDailyRecordCore));
