import React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordMultipleSelect from "@components/organisms/mgr/common/record/RecordMultipleSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordHeaderAllEditType from "@components/organisms/mgr/common/record/RecordHeaderAllEditType";
import { BasicFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/BasicFields";
import { GoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/GoalFields";
import { SupportPlanGoalFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/SupportPlanGoalFields";
import { OtherFields } from "@components/organisms/mgr/SHUROTEICHAKU/record/fields/OtherFields";
import { WarningAlert } from "@components/organisms/mgr/common/record/WarningAlert";
import { RecordSupportPlanValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportPlan/initialValues";
import { FormikProps, getIn } from "formik";
import { StaffState } from "@stores/domain/staff/types";
import { ErrorsState } from "@stores/domain/errors/types";
import { FieldItem } from "@interfaces/ui/form";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import { DISABILITY_CERTIFICATE_REHABILITATION } from "@constants/mgr/SHUROTEICHAKU/variables";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: 32
    },
    title: {
      margin: "-16px 0"
    },
    title2: {
      paddingBottom: 8,
      borderBottom: "1px solid #757575",
      marginBottom: 32
    },
    field: {
      marginBottom: 16
    },
    dateField: {
      marginBottom: 32
    },
    selectField: {
      marginTop: 32,
      width: 245
    },
    authorizer: {
      width: 245,
      margin: "32px 16px 0 0"
    },
    divider: {
      marginTop: 48
    },
    deleteButton: {
      marginTop: 24
    },
    paper2: {
      padding: "24px 32px 32px",
      marginTop: 16
    },
    participant: {
      flex: 1,
      marginBottom: 32
    },
    warningAlertWrap: {
      marginBottom: 32,
      marginTop: -24
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  supportPlanId?: string;
  creationDate: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  dateBirth: string;
  isEditing: boolean;
  staff: StaffState;
  staffOptions: FieldItem[];
  mergedStaffOptions: FieldItem[];
  authorValue: string;
  authorizerValue: string;
  authorizerRole: string;
  participantValue: string;
  errorsState?: ErrorsState["plan"]["data"];
  initialValues: RecordSupportPlanValues;
  history: H.History;
  onClickEdit: (e: React.MouseEvent) => void;
  onClickEditCancel: () => void;
  onClickDelete?: () => void;
  formikProps: FormikProps<RecordSupportPlanValues>;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 *
 */
const SupportPlanFieldsCore = (props: Props): JSX.Element => {
  const { errorsState, supportPlanId } = props;
  const relationId = Number(supportPlanId);
  const hasWarningData =
    errorsState &&
    errorsState.length > 0 &&
    errorsState[0].errors.findIndex(
      (e) => e.type === "warn" && e.relation.value === relationId
    ) > -1;

  const { values: formikValues } = props.formikProps;

  // state
  const [showAuthorizer, setShowAuthorizer] = React.useState(false);
  const [staffRole, setStaffRole] = React.useState("-");

  // 承認ステータス変更時
  const currentStatus = getIn(formikValues, "status_type");
  React.useEffect(() => {
    setShowAuthorizer(currentStatus === "1");
  }, [currentStatus]);

  // 承認者変更時
  const currentAuthorizer = getIn(formikValues, "authorizer");
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態の場合
      // 変更後の authorizer 値にマッチした役職名を staffRole に流し込む
      setStaffRole(getStaffRole(props.staff, currentAuthorizer) || "-");
    } else {
      // 閲覧状態の場合 ( 初期表示時 )
      // staffRole に snapshot_role を流し込む ( スナップがない場合は role 値が入る )
      setStaffRole(props.authorizerRole || "-");
    }
  }, [currentAuthorizer]);

  // 編集・更新完了時に
  // 「必須ではない」かつ「入力状態が不完全」
  // な日付項目をリセットさせるための施策
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態開始時
      // スナップ情報ではなく現行の値に表示を切り替えるため staffRole を更新させる
      setStaffRole(getStaffRole(props.staff, currentAuthorizer) || "-");
    } else {
      props.formikProps.resetForm();
      // 編集状態終了時 ( 編集「キャンセル」時 )
      // スナップ情報を復元させるため staffRole を更新させる
      // ( 編集キャンセル前に値を変更された状態が残るケースのフォロー施策 )
      setStaffRole(props.authorizerRole || "-");
    }
  }, [props.isEditing]);

  // 非活性になった等級を消す
  const handleChangeDisabilityCertificateRehabilitation = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (event.target.value === DISABILITY_CERTIFICATE_REHABILITATION[0].value) {
      props.formikProps.setFieldValue(
        "support_plan_shuroteichaku.disability_certificate_rehabilitation_other",
        ""
      );
    }
  };

  return (
    <div>
      <RecordHeaderAllEditType
        pageName={props.pageName}
        userName={props.userName}
        hasRecord // 新規及びリスト経由なので常にtrue
        uifId={props.uifId}
        supportPlanId={props.supportPlanId}
        year="2000" // TODO 不要
        month="12" // TODO 不要
        recordType="support_plan"
        isEditing={props.isEditing}
        onClickEdit={props.onClickEdit}
        onClickEditCancel={props.onClickEditCancel}
        formikProps={props.formikProps}
        history={props.history}
        isExistSupportReport
      />
      <Paper className={props.classes.paper} elevation={0}>
        {!props.isEditing && errorsState && hasWarningData && (
          <div className={props.classes.warningAlertWrap}>
            <WarningAlert
              supportPlanId={supportPlanId}
              errorsState={errorsState}
            />
          </div>
        )}
        {props.createdAt && (
          <div className={props.classes.title}>
            <CreateAndUpdateDate
              createdAt={props.createdAt}
              updatedAt={props.updatedAt}
            />
          </div>
        )}
        <div className={props.classes.dateField}>
          <RecordSelectDate
            name="creation_date"
            label="計画作成日"
            required
            value={props.initialValues.creation_date}
            isEditable={props.isEditing}
            addYearTo={1}
            overrideYearFrom={1989}
          />
        </div>
        <FormGroup row>
          <div className={props.classes.dateField}>
            <RecordSelectDate
              name="support_begin_date"
              label="支援開始日"
              required
              value={props.initialValues.support_begin_date}
              isEditable={props.isEditing}
              addYearTo={1}
              overrideYearFrom={1989}
            />
          </div>
          <div className={props.classes.dateField}>
            <RecordSelectDate
              name="support_end_date"
              label="支援終了日"
              required
              value={props.initialValues.support_end_date}
              isEditable={props.isEditing}
              addYearTo={10}
              overrideYearFrom={1989}
            />
          </div>
        </FormGroup>
        <BasicFields
          isEditing={props.isEditing}
          dateBirth={props.dateBirth}
          values={formikValues}
          onChangeDisabilityCertificateRehabilitation={
            handleChangeDisabilityCertificateRehabilitation
          }
        />
        <div className={props.classes.divider} />
        <GoalFields
          isEditing={props.isEditing}
          sprintType={1}
          values={props.initialValues}
        />
        <div className={props.classes.divider} />
        <GoalFields
          isEditing={props.isEditing}
          sprintType={2}
          values={props.initialValues}
        />
        <div className={props.classes.divider} />
        <SupportPlanGoalFields
          isEditing={props.isEditing}
          values={props.initialValues}
        />
        <div className={props.classes.divider} />
        <OtherFields
          isEditing={props.isEditing}
          author={{ value: props.authorValue, options: props.staffOptions }}
          values={props.initialValues}
        />
      </Paper>

      <Paper className={props.classes.paper2} elevation={0}>
        <div className={props.classes.title2}>
          <Typography variant="h6" color="primary">
            個別支援会議 議事録
          </Typography>
        </div>
        <FormGroup row>
          <div className={props.classes.dateField}>
            <RecordSelectDate
              name="minutes_date"
              label="実施日"
              value={props.initialValues.minutes_date}
              isEditable={props.isEditing}
              addYearTo={10}
              overrideYearFrom={1989}
            />
          </div>
          <div className={props.classes.participant}>
            <RecordMultipleSelect
              name="participant"
              value={props.participantValue}
              defaultValue="-"
              label="参加者"
              labelType="default"
              placeholder="選択してください"
              options={[{ categoryName: "", items: props.mergedStaffOptions }]}
              isEditable={props.isEditing}
              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            />
          </div>
        </FormGroup>
        <RecordTextField
          name="minutes"
          value={props.initialValues.minutes}
          defaultValue="-"
          label="議事録"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
        <FormGroup row>
          <div className={props.classes.authorizer}>
            <RecordSelect
              name="status_type"
              value={
                props.initialValues.status_type === "1" ? "承認済" : "未承認"
              }
              defaultValue=""
              label="承認ステータス"
              placeholder=""
              options={[
                { label: "未承認", value: "0" },
                { label: "承認済", value: "1" }
              ]}
              isEditable={props.isEditing}
            />
          </div>
          {showAuthorizer && (
            <>
              <div className={props.classes.authorizer}>
                <RecordSelect
                  name="authorizer"
                  value={props.authorizerValue}
                  defaultValue="未設定"
                  label="承認者"
                  placeholder="選択してください"
                  options={props.staffOptions}
                  isEditable={props.isEditing}
                  isSelectablePlaceholder
                  emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                />
              </div>
              <div className={props.classes.authorizer}>
                <ReadonlyTextField
                  value={staffRole}
                  defaultValue=""
                  label="役職"
                />
              </div>
            </>
          )}
        </FormGroup>
      </Paper>

      {props.isEditing && props.onClickDelete && (
        <div className={props.classes.deleteButton}>
          <DeleteButton
            text="個別支援計画書を削除する"
            onClick={props.onClickDelete}
          />
        </div>
      )}
    </div>
  );
};

export const SupportPlanFields = withStyles(styles)(SupportPlanFieldsCore);
