/**
 * ActionNames
 */
export const FETCH_STARTED = "SHUROTEICHAKU/FETCH_STARTED";
export const FETCH = "SHUROTEICHAKU/FETCH";
export const FETCH_FAILED = "SHUROTEICHAKU/FETCH_FAILED";
export const POST_STARTED = "SHUROTEICHAKU/POST_STARTED";
export const POST = "SHUROTEICHAKU/POST";
export const POST_FAILED = "SHUROTEICHAKU/POST_FAILED";
export const UNSET_REPORT = "SHUROTEICHAKU/UNSET_REPORT";

/**
 * State
 */
export type UsageResultsState = {
  // 支援レポート共有日
  shareSupportReportDate: string;
  usageResults: UsageResult[];
};

export type UsageResult = {
  // 利用者ID
  uifId: number | null;
  // 姓
  nameSei: string | null;
  // 名
  nameMei: string | null;
  // 対象年月日
  targetDate: string | null;
  // サービス提供の状況
  statusType: number | null;
  // 特別地域加算
  specialAreaFlg: number | null;
  // 地域連携会議実施加算
  regionalCooperationSupport: number | null;
  // 支援実施方法・場所
  supportMethod: number | null;
  // 支援実施方法・場所(その他)
  supportMethodOther: string | null;
  // 支援開始
  supportStartTime: string | null;
  // 支援終了
  supportEndTime: string | null;
  // 備考
  remarks: string | null;
  // 休日判定
  isHoliday: boolean | null;
};
