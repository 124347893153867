import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FieldItem } from "@interfaces/ui/form";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { StaffState } from "@stores/domain/staff/types";
import { UserState } from "@stores/domain/user/type";
import { SupportLedgerState } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { CityState, CityParams } from "@stores/domain/city/type";
import { SupportsState } from "@stores/domain/supports/types";
import dispatches from "@stores/dispatches";

import {
  getIndexes,
  getOptions,
  getInput,
  getCustomRecordItemChoiceName
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";
import { normalizeDisplayCustomRecordPrint } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordNormalize";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE,
  CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME
} from "@constants/variables";
import { RELATED_ORGANIZATION_TARGET_TYPE } from "@constants/mgr/CHIIKITEICHAKU/variables";

// utils
import { dateToLocalisedString, dateToSelectDateValue } from "@utils/date";
import getAge from "@utils/date/getAge";
import { getUrlParams } from "@/utils/url";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import {
  SupportLedger,
  initialValues
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { FacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/types";
import classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 14,
      marginBottom: 20
    },
    headerTitle: {
      fontSize: 18
    },
    headerCreate: {
      fontSize: 10
    },
    infoArea: {
      display: "flex",
      justifyContent: "flex-start",
      marginTop: "16px",
      gap: "16px",
      "&.-ledger-office": {
        marginTop: "48px"
      }
    },
    infoContainer: {
      borderBottom: "1px solid #000",
      "&.-size-m": {
        width: 216
      },
      "&.-size-s": {
        width: 128
      },
      "&.-size-ss": {
        width: 64
      }
    },
    infoLabel: {
      display: "block",
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    infoValue: {
      fontSize: 10,
      color: "#212121",
      paddingBottom: 5,
      overflowWrap: "anywhere"
    },
    tableTitle: {
      fontSize: 10,
      fontWeight: "bold",
      borderBottom: "1px solid #000",
      paddingTop: 36,
      paddingBottom: 5,
      display: "flex",
      justifyContent: "space-between"
    },
    table: {
      marginTop: "16px",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid #000",
      textAlign: "left",
      width: "100%",
      gridTemplateColumns: "73px 142px 1fr"
    },
    tableRow: {
      display: "flex",
      "&:not(:last-of-type)": {
        borderBottom: "1px solid #000"
      },
      "&.hasNotCategoryCell": {
        flexDirection: "column"
      }
    },
    tableContent: {
      flex: 1,
      // CSSハック これによって外部コンテンツの高さに合わせてくれる
      display: "grid"
    },
    tableCell: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      padding: "4px 6px",
      fontSize: 10,
      letterSpacing: "normal",
      minHeight: 24,
      color: "rgba(0, 0, 0, 0.84)",
      "&:last-of-type": {
        overflowWrap: "anywhere"
      },
      "&:not(:last-of-type)": {
        borderRight: "1px solid #000"
      },
      "&.content": {
        alignItems: "flex-start"
      },
      "&.w-20": {
        flex: "none",
        width: 20
      },
      "&.w-72": {
        flex: "none",
        width: 72
      },
      "&.w-86": {
        flex: "none",
        width: 86
      },
      "&.w-108": {
        flex: "none",
        width: 108
      },
      "&.w-128": {
        flex: "none",
        width: 128
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none"
      }
    }
  });

/**
 * interface
 */
type StateProps = {
  staff: StaffState;
  user: UserState;
  facility: FacilityState;
  cityList: CityState[];
  supportLedgerRecord: SupportLedgerState["supportLedger"];
  userInFacility: UsersInFacilityState["user"];
  customRecords: CustomRecordsWithCategoryState;
  simpleRecords: SupportsState["simpleRecords"];
};

type OwnProps = {
  uifId: string;
  supportLedgerId: string;
  query?: string;
};

type DisplayParams = {
  display_works?: string;
  display_support_skills?: string;
  display_others?: string;
};

type DispatchProps = {
  fetchStaffs: () => Promise<void>;
  fetchCity: (params: CityParams) => Promise<void>;
  fetchOne: (uifId: string, facilityType: FacilityType) => void;
  fetchSupportLedger: (uifId: string, supportLedgerId: string) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  fetchSupportSimpleReports: (
    uifId: string,
    fromMonth: string,
    toMonth: string
  ) => Promise<void>;
};

type MergeProps = {
  staffOptions: FieldItem[];
} & StateProps &
  DispatchProps;

type Props = StateProps &
  OwnProps &
  DispatchProps &
  MergeProps &
  WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: StateProps["userInFacility"];
  supportLedgerRecord: SupportLedgerState["supportLedger"];
  targetSupportLedger: SupportLedger;
  customRecords: CustomRecordsWithCategoryState;
  classes: Record<string, string>;
  facilityType: FacilityType;
  displayParams: DisplayParams;
  simpleRecords: SupportsState["simpleRecords"];
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type ContentsProps = {
  customRecords: CustomRecordsWithCategoryState;
  sectionTitle?: string;
  targetSupportLedger: SupportLedger;
  categoryType: number;
  classes: Record<string, string>;
};
type ContentSupportProgressProps = {
  targetSupportLedger: SupportLedger;
  simpleRecords: SupportsState["simpleRecords"];
  classes: Record<string, string>;
};

type ContentOfficeProps = {
  targetSupportLedger: SupportLedger;
  classes: Record<string, string>;
};

// 障害種別
const getDisabilityType = (
  checkedItems: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["checkedItems"],
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
): string => {
  if (!checkedItems) {
    return "";
  }

  const disabilityTypeList: (string | undefined)[] = getOptions(
    customRecordItem
  )
    .map((item) => {
      if (Object.keys(checkedItems).find((key) => key === item.value)) {
        return checkedItems[item.value].checked ? item.label : undefined;
      }
      return undefined;
    })
    .filter((disabilityType) => {
      return disabilityType !== undefined;
    });

  return disabilityTypeList.length !== 0 ? disabilityTypeList.join("、") : "";
};

// 障害支援区分
const getDisabilitySupportCategory = (
  inputItem: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number],
  customRecordItemChoices: CustomRecordsWithCategoryState[number]["custom_record_items"][number]["custom_record_item_choices"]
): string => {
  if (!inputItem.choiced_item_id) {
    return "";
  }

  return getCustomRecordItemChoiceName(
    Number(inputItem.choiced_item_id),
    customRecordItemChoices
  );
};

const TableRow = ({
  label,
  classes,
  size,
  hiddenLabel = false,
  children,
  isContent = false
}: React.PropsWithChildren<{
  label: string;
  classes: Record<string, string>;
  hiddenLabel?: boolean;
  size: "20" | "72" | "86" | "108" | "128";
  isContent?: boolean;
}>): JSX.Element => {
  return (
    <div
      className={classNames(classes.tableRow, {
        hasNotCategoryCell: hiddenLabel
      })}
    >
      {hiddenLabel ? null : (
        <div
          className={`${classNames(classes.tableCell, {
            "w-20": size === "20",
            "w-72": size === "72",
            "w-86": size === "86",
            "w-108": size === "108",
            "w-128": size === "128"
          })}${isContent ? " content" : ""}`}
        >
          {label}
        </div>
      )}
      {children}
    </div>
  );
};

const HistoryCell = ({
  histories,
  classes,
  type
}: {
  histories: NonNullable<
    | SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["life_histories"]
    | SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["disability_histories"]
  >;
  classes: Record<string, string>;
  type: "life" | "disability";
}): JSX.Element => {
  return (
    <div className={classes.tableContent}>
      {histories.map((history, hIdx) => (
        <TableRow size="20" classes={classes} label={`${hIdx + 1}`}>
          <div className={classes.tableContent}>
            <TableRow
              size={type === "life" ? "108" : "86"}
              classes={classes}
              label="年月"
              isContent
            >
              <div className={classes.tableCell}>{history.yyyymm}</div>
            </TableRow>
            <TableRow
              size={type === "life" ? "108" : "86"}
              classes={classes}
              label="事項"
              isContent
            >
              <div className={classes.tableCell}>{history.matter}</div>
            </TableRow>
          </div>
        </TableRow>
      ))}
    </div>
  );
};

const RelativesInfoCell = ({
  relativesInfo,
  classes
}: {
  relativesInfo: NonNullable<
    SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["relatives_info"]
  >;
  classes: Record<string, string>;
}): JSX.Element => {
  return (
    <>
      {relativesInfo.map((info, hIdx) => (
        <TableRow size="20" classes={classes} label={`${hIdx + 1}`}>
          <div className={classes.tableContent}>
            <TableRow size="108" classes={classes} label="族柄" isContent>
              <div className={`${classes.tableCell} content`}>
                {info.relationship}
              </div>
            </TableRow>
            <TableRow size="108" classes={classes} label="氏名" isContent>
              <div className={`${classes.tableCell} content`}>{info.name}</div>
            </TableRow>
            <TableRow size="108" classes={classes} label="年齢" isContent>
              <div className={`${classes.tableCell} content`}>{info.age}</div>
            </TableRow>
            <TableRow size="108" classes={classes} label="職業" isContent>
              <div className={`${classes.tableCell} content`}>
                {info.profession}
              </div>
            </TableRow>
            <TableRow size="108" classes={classes} label="住所" isContent>
              <div className={`${classes.tableCell} content`}>
                {info.address}
              </div>
            </TableRow>
            <TableRow size="108" classes={classes} label="連絡先" isContent>
              <div className={`${classes.tableCell} content`}>
                {info.contact_address}
              </div>
            </TableRow>
            <TableRow size="108" classes={classes} label="備考" isContent>
              <div className={`${classes.tableCell} content`}>
                {info.remarks}
              </div>
            </TableRow>
          </div>
        </TableRow>
      ))}
    </>
  );
};

const RelatedOrganizationsCell = ({
  relatedOrganizations,
  classes
}: {
  relatedOrganizations: NonNullable<
    SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number]["related_organization"]
  >;
  classes: Record<string, string>;
}): JSX.Element => {
  const firstLabel: {
    [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
  } = {
    0: "施設・会社名",
    1: "施設・医療機関名",
    2: "施設・会社名",
    3: "施設・会社名"
  };

  const secondLabel: {
    [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
  } = {
    0: "種別",
    1: "診察科・部門",
    2: "種類",
    3: "種別・種類"
  };

  return (
    <>
      {relatedOrganizations.map((organization, hIdx) => {
        return (
          <TableRow
            size="20"
            classes={classes}
            label={`${hIdx + 1}`}
            key={`relatedOrganizations-${organization.id}`}
          >
            <div className={classes.tableContent}>
              {/* 施設、会社名、医療機関名 */}
              <TableRow
                size="108"
                classes={classes}
                label={firstLabel[organization.target_type]}
                isContent
              >
                <div className={`${classes.tableCell} content`}>
                  {organization.facility_name}
                </div>
              </TableRow>
              {/* 種別、種類、診察科、部門 */}
              <TableRow
                size="108"
                classes={classes}
                label={secondLabel[organization.target_type]}
                isContent
              >
                <div className={`${classes.tableCell} content`}>
                  {organization.kinds}
                </div>
              </TableRow>
              <TableRow size="108" classes={classes} label="担当者" isContent>
                <div className={`${classes.tableCell} content`}>
                  {organization.manager}
                </div>
              </TableRow>
              <TableRow size="108" classes={classes} label="連絡先" isContent>
                <div className={`${classes.tableCell} content`}>
                  {organization.contact_address}
                </div>
              </TableRow>
              <TableRow size="108" classes={classes} label="備考" isContent>
                <div className={`${classes.tableCell} content`}>
                  {organization.remarks}
                </div>
              </TableRow>
            </div>
          </TableRow>
        );
      })}
    </>
  );
};

const cellSwitcher = (
  defaultItem: number | null,
  inputItem: SupportLedger["support_ledger"]["support_ledger_items"][number]["input"][number],
  defaultRecordCategoryType: number | null,
  customRecordItem: CustomRecordsWithCategoryState[number]["custom_record_items"][number],
  classes: Record<string, string>
): JSX.Element => {
  // カテゴリ→項目の順で制御
  switch (defaultRecordCategoryType) {
    case CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information:
    case CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information:
      switch (defaultItem) {
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityType:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
            >
              <div className={classes.tableCell}>
                {getDisabilityType(inputItem.checkedItems, customRecordItem)}
              </div>
            </TableRow>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilitySupportCategory:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
            >
              <div className={classes.tableCell}>
                {getDisabilitySupportCategory(
                  inputItem,
                  customRecordItem.custom_record_item_choices
                )}
              </div>
            </TableRow>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.disabilityHistories:
          if (!inputItem.disability_histories) return <></>;
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
            >
              <HistoryCell
                histories={inputItem.disability_histories}
                classes={classes}
                type="disability"
              />
            </TableRow>
          );
        default:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
              isContent
            >
              <div className={`${classes.tableCell} content`}>
                {inputItem.input_data}
              </div>
            </TableRow>
          );
      }
    default:
      switch (defaultItem) {
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.weight:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
            >
              <div
                className={classes.tableCell}
              >{`${inputItem.input_data}kg`}</div>
            </TableRow>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.height:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
            >
              <div
                className={classes.tableCell}
              >{`${inputItem.input_data}cm`}</div>
            </TableRow>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.lifeHistories:
          if (!inputItem.life_histories) return <></>;
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
              hiddenLabel
            >
              <HistoryCell
                histories={inputItem.life_histories}
                classes={classes}
                type="life"
              />
            </TableRow>
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relativesInfo:
          if (!inputItem.relatives_info) return <></>;
          return (
            <RelativesInfoCell
              relativesInfo={inputItem.relatives_info}
              classes={classes}
            />
          );
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedWelfareService:
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedMedical:
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedLifeLine:
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.relatedOthers:
          if (!inputItem.related_organization) return <></>;
          return (
            <RelatedOrganizationsCell
              relatedOrganizations={inputItem.related_organization}
              classes={classes}
            />
          );
        default:
          return (
            <TableRow
              label={customRecordItem.name}
              classes={classes}
              size="128"
              isContent
            >
              <div className={`${classes.tableCell} content`}>
                {inputItem.input_data}
              </div>
            </TableRow>
          );
      }
  }
};

const CategoryContent = (props: ContentsProps): JSX.Element => {
  const {
    customRecords,
    sectionTitle,
    targetSupportLedger,
    categoryType,
    classes
  } = props;

  // 入力がある記録項目のみ取得してソート
  const nomalizedCustomRecord = normalizeDisplayCustomRecordPrint(
    customRecords,
    targetSupportLedger,
    categoryType
  );

  return (
    <section className={classes.section}>
      {categoryType !==
        CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information && (
        <div className={classes.tableTitle}>
          <span>{sectionTitle}</span>
        </div>
      )}

      {nomalizedCustomRecord.length !== 0 && (
        <div className={classes.table}>
          {// カテゴリごと
          nomalizedCustomRecord.map((category) => {
            const isdHiddenCategoryLabel =
              category.category_type ===
                CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information ||
              category.category_type ===
                CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information;
            return (
              <TableRow
                label={category.name}
                classes={classes}
                size="72"
                hiddenLabel={isdHiddenCategoryLabel}
                key={category.id}
              >
                <div className={classes.tableContent}>
                  {category.custom_record_items.map((item) => {
                    const indexes = getIndexes(targetSupportLedger, item);
                    const input = getInput(targetSupportLedger, indexes);
                    return cellSwitcher(
                      item.default_item,
                      input,
                      category.default_category || null,
                      item,
                      classes
                    );
                  })}
                </div>
              </TableRow>
            );
          })}
        </div>
      )}
    </section>
  );
};

const ContentsProgressSupport = (
  props: ContentSupportProgressProps
): JSX.Element => {
  const { targetSupportLedger, simpleRecords, classes } = props;
  const printSimpleRecords = simpleRecords.support_record_consultations;
  // 印刷しない帳票はのぞく
  if (
    targetSupportLedger.support_ledger.is_ignore_print_support_ids.length > 0
  ) {
    targetSupportLedger.support_ledger.is_ignore_print_support_ids.forEach(
      (id) => {
        const idx = printSimpleRecords.findIndex(
          (record) => !!record && record.id === id
        );
        if (idx !== -1) {
          printSimpleRecords.splice(idx, 1);
        }
      }
    );
  }
  const dateBeginInSupport = `${targetSupportLedger.support_ledger.date_begin_in_support.year}年${targetSupportLedger.support_ledger.date_begin_in_support.month}月`;
  const dateEndInSupport = `${targetSupportLedger.support_ledger.date_end_in_support.year}年${targetSupportLedger.support_ledger.date_end_in_support.month}月`;

  return (
    <section className={classes.section}>
      <div className={classes.tableTitle}>
        <span>支援経過</span>
      </div>
      <div className={classes.table}>
        <TableRow label="本台帳に係る支援期間" classes={classes} size="72">
          <div className={classes.tableContent}>
            <TableRow classes={classes} label="支援開始年月" size="128">
              <div className={classes.tableCell}>{dateBeginInSupport}</div>
            </TableRow>
            <TableRow classes={classes} label="支援終了年月" size="128">
              <div className={classes.tableCell}>{dateEndInSupport}</div>
            </TableRow>
          </div>
        </TableRow>
        {printSimpleRecords.length ? (
          <TableRow label="支援記録" classes={classes} size="72">
            <div className={classes.tableContent}>
              {printSimpleRecords.map((record, hIdx) => {
                let staffNames = "";
                if (record.staffs) {
                  const staffs: string[] = [];
                  record.staffs.map((staff) => {
                    return staff.staff_name
                      ? staffs.push(staff.staff_name)
                      : null;
                  });
                  staffNames = staffs.join("、");
                }
                const targetDate = dateToSelectDateValue(record.target_date);
                return (
                  <TableRow
                    key={`record-${record.id}`}
                    label={`${hIdx + 1}`}
                    classes={classes}
                    size="20"
                  >
                    <div className={classes.tableContent}>
                      <TableRow label="実施日" size="108" classes={classes}>
                        <div
                          className={classes.tableCell}
                        >{`${targetDate.year}年${targetDate.month}月${targetDate.day}日`}</div>
                      </TableRow>
                      <TableRow
                        label="支援内容"
                        size="108"
                        classes={classes}
                        isContent
                      >
                        <div className={`${classes.tableCell} content`}>
                          {record.support_content}
                        </div>
                      </TableRow>
                      <TableRow label="記録者" size="108" classes={classes}>
                        <div className={classes.tableCell}>{staffNames}</div>
                      </TableRow>
                    </div>
                  </TableRow>
                );
              })}
            </div>
          </TableRow>
        ) : null}
      </div>
    </section>
  );
};

const InfoField = ({
  label,
  value,
  size = "m",
  classes
}: {
  label: string;
  value: string;
  size?: "ss" | "s" | "m";
  classes: Record<string, string>;
}): JSX.Element => {
  return (
    <div className={classNames(classes.infoContainer, `-size-${size}`)}>
      <span className={classes.infoLabel}>{label}</span>
      <div className={classes.infoValue}>
        <span>{value}</span>
      </div>
    </div>
  );
};

// 事業所情報
const ContentsOffice = (props: ContentOfficeProps): JSX.Element => {
  const { targetSupportLedger, classes } = props;

  const officeInfo = {
    createdOffice: "", // 台帳作成事業所名
    createdStaff: "", // 台帳作成事業所担当者
    createdTel: "", // 台帳作成事業所連絡先
    supportOffice: "", // 相談支援事業所名
    supportStaff: "", // 相談支援事業所担当者
    supportTel: "" // 相談支援事業所連絡先
  };
  if (
    !targetSupportLedger.support_ledger ||
    !targetSupportLedger.support_ledger.support_ledger_items
  ) {
    return <></>;
  }

  targetSupportLedger.support_ledger.support_ledger_items.map((item) => {
    return item.input.map((i) => {
      switch (i.default_item) {
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdOffice:
          officeInfo.createdOffice = i.input_data;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdStaff:
          officeInfo.createdStaff = i.staff && i.staff.name ? i.staff.name : "";
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.createdTel:
          officeInfo.createdTel = i.input_data;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.supportOffice:
          officeInfo.supportOffice = i.input_data;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.supportStaff:
          officeInfo.supportStaff = i.input_data;
          break;
        case CHIIKITEICHAKU_SUPPORT_LEDGER_DEFAULT_ITEM.supportTel:
          officeInfo.supportTel = i.input_data;
          break;
        default:
          break;
      }
      return officeInfo;
    });
  });

  return (
    <>
      <div className={classNames(classes.infoArea, "-ledger-office")}>
        <InfoField
          classes={classes}
          label="台帳作成事業所名"
          value={officeInfo.createdOffice}
        />
        <InfoField
          classes={classes}
          label="台帳作成事業所担当者"
          value={officeInfo.createdStaff}
        />
        <InfoField
          classes={classes}
          label="台帳作成事業所連絡先"
          value={officeInfo.createdTel}
        />
      </div>
      <div className={classes.infoArea}>
        <InfoField
          classes={classes}
          label="相談支援事業所名"
          value={officeInfo.supportOffice}
        />
        <InfoField
          classes={classes}
          label="相談支援事業所担当者"
          value={officeInfo.supportStaff}
        />
        <InfoField
          classes={classes}
          label="相談支援事業所連絡先"
          value={officeInfo.supportTel}
        />
      </div>
    </>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    userInFacility,
    supportLedgerRecord,
    customRecords,
    simpleRecords
  } = props;

  const userDetail = userInFacility.user_in_facility;

  const getCreationStaff = (): string => {
    const { creation_staff } = supportLedgerRecord.support_ledger;
    if (!creation_staff) {
      return "-";
    }
    const creationStaffName =
      creation_staff.snapshot_name || creation_staff.name;
    return creationStaffName;
  };

  const getUpdateStaff = (): string => {
    const { update_staff } = supportLedgerRecord.support_ledger;
    if (!update_staff) {
      return "-";
    }
    const getUpdateStaffName = update_staff.snapshot_name || update_staff.name;
    return getUpdateStaffName;
  };
  const printCategory = [
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.basic_information,
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.fault_information,
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.user_condition,
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.family,
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.related_organizations,
    CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE.notices
  ];

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>地域定着支援台帳</div>
        <div>
          <div className={classes.headerCreate}>
            作成日：
            {supportLedgerRecord.created_at
              ? dateToLocalisedString(
                  supportLedgerRecord.created_at,
                  "YYYY年M月D日　　"
                )
              : "-　　"}
            作成者：{getCreationStaff()}
          </div>
          <div className={classes.headerCreate}>
            更新日：
            {supportLedgerRecord.updated_at
              ? dateToLocalisedString(
                  supportLedgerRecord.updated_at,
                  "YYYY年M月D日　　"
                )
              : "-　　"}
            更新者：{getUpdateStaff()}
          </div>
        </div>
      </div>
      <div>
        <div className={classes.infoArea}>
          <InfoField
            classes={classes}
            label="氏名（フリガナ）"
            value={`${userDetail.name_sei} ${userDetail.name_mei}（${userDetail.name_sei_kana} ${userDetail.name_mei_kana}）`}
          />
          <InfoField
            classes={classes}
            label="生年月日(年齢)"
            size="s"
            value={`${
              userDetail.date_birth !== undefined
                ? dateToLocalisedString(userDetail.date_birth, "YYYY年M月D日")
                : ""
            }(${getAge(
              userDetail.date_birth,
              supportLedgerRecord.support_ledger.update_date
            )}歳)`}
          />
          <InfoField
            classes={classes}
            label="性別"
            size="ss"
            value={userDetail.gender === "1" ? "男性" : "女性"}
          />
        </div>
      </div>
      {printCategory.map((type) => (
        <CategoryContent
          key={type}
          sectionTitle={CHIIKITEICHAKU_SUPPORT_LEDGER_CATEGORY_TYPE_NAME[type]}
          customRecords={customRecords}
          targetSupportLedger={props.targetSupportLedger}
          categoryType={type}
          classes={classes}
        />
      ))}
      {/* 支援経過 */}
      <ContentsProgressSupport
        targetSupportLedger={props.targetSupportLedger}
        simpleRecords={simpleRecords}
        classes={classes}
      />
      {/* 事業所情報 */}
      <ContentsOffice
        targetSupportLedger={props.targetSupportLedger}
        classes={classes}
      />
    </div>
  );
};

const SupportLedgerPrintCore = (props: Props): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);
  const {
    uifId,
    supportLedgerId,
    classes,
    userInFacility,
    supportLedgerRecord,
    customRecords,
    user,
    cityList,
    staffOptions,
    facility,
    fetchSupportLedger,
    fetchCustomRecords,
    fetchSupportSimpleReports,
    simpleRecords,
    query
  } = props;

  React.useEffect(() => {
    props.fetchOne(uifId, user.facility_type);
    fetchSupportLedger(uifId, supportLedgerId);
    fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  React.useEffect(() => {
    if (
      supportLedgerRecord.support_ledger.date_begin_in_support &&
      supportLedgerRecord.support_ledger.date_end_in_support
    ) {
      fetchSupportSimpleReports(
        uifId,
        supportLedgerRecord.support_ledger.date_begin_in_support,
        supportLedgerRecord.support_ledger.date_end_in_support
      );
    }
  }, [supportLedgerRecord]);

  const targetSupportLedger: SupportLedger = initialValues({
    user: props.userInFacility,
    facility,
    cityList,
    staffOptions,
    supportLedgerState: supportLedgerRecord,
    customRecords
  });

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  if (customRecords.length === 0 || !supportLedgerRecord.created_at) {
    return null;
  }

  const params: DisplayParams = query ? getUrlParams(query) : {};

  return (
    <Sheet
      classes={classes}
      userInFacility={userInFacility}
      supportLedgerRecord={supportLedgerRecord}
      targetSupportLedger={targetSupportLedger}
      customRecords={customRecords}
      facilityType={user.facility_type}
      displayParams={params}
      simpleRecords={simpleRecords}
    />
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const supportLedgerRecord = state.CHIIKITEICHAKU.supportLedger.supportLedger;
  const getUserInFacility = (): StateProps["userInFacility"] => {
    return state.CHIIKITEICHAKU.userInFacility.user;
  };
  const customRecords = state.customRecordsWithCategory.assesment;
  return {
    user,
    supportLedgerRecord,
    userInFacility: getUserInFacility(),
    customRecords,
    staff: state.staff,
    cityList: state.city,
    facility: state.CHIIKITEICHAKU.facility,
    simpleRecords: state.supports.simpleRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    staffDispatcher,
    cityDispatch,
    CHIIKITEICHAKU,
    customRecordsWithCategory,
    supportsDispatcher
  } = dispatches;

  const cityDispatches = cityDispatch(dispatch);
  const staffDispatches = staffDispatcher(dispatch);
  const supportsDispatches = supportsDispatcher(dispatch);
  const fetchSupportLedger = (
    uifId: string,
    supportLedgerId: string
  ): Promise<void> =>
    CHIIKITEICHAKU.supportLedgerDispatcher(dispatch).fetchSupportLedger(
      uifId,
      supportLedgerId
    );
  const fetchCustomRecords = (): Promise<void> =>
    customRecordsWithCategory(dispatch).fetchCustomRecords(
      CUSTOM_RECORD_TARGET_TYPE.support_ledger
    );

  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    fetchSupportLedger,
    fetchCustomRecords,
    fetchOne: (uifId: string): void => {
      dispatches.CHIIKITEICHAKU.userInFacilityDispatcher(dispatch).fetchOne(
        uifId
      );
      dispatches.CHIIKITEICHAKU.facilityDispatcher(dispatch).fetch();
    },
    fetchSupportSimpleReports: (uifId, fromMonth, toMonth): Promise<void> => {
      return supportsDispatches.fetchSupportSimpleReports(
        uifId,
        fromMonth,
        toMonth
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff } = stateProps;

  // ベースとなる職員の選択肢
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  return {
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportLedgerPrint = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(SupportLedgerPrintCore));
