import { postInquiry } from "@api/requests/inquiry/postInquiry";
import { InquiryValues } from "@initialize/mgr/KEIKAKUSODAN/record/inquiry/initialValues";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import isEmpty from "lodash-es/isEmpty";
import castStringToNumber from "@utils/dataNormalizer/castStringToNumber";

export const normalizePostInquiryDataToAPI = (
  inquiryData: InquiryValues
): postInquiry => {
  const request: postInquiry = {
    support_inquiry: {
      support_inquiries_id: inquiryData.support_inquiries_id || null,
      creation_date:
        selectDateValueToDatePaddingZero(inquiryData.creation_date) || "",
      creation_staff_id: inquiryData.creation_staff
        ? castStringToNumber(inquiryData.creation_staff)
        : null,

      why_not: isEmpty(inquiryData.why_not) ? null : inquiryData.why_not,
      inquiry_purpose: isEmpty(inquiryData.inquiry_purpose)
        ? null
        : inquiryData.inquiry_purpose,
      inquiry_destination: isEmpty(inquiryData.inquiry_destination)
        ? null
        : inquiryData.inquiry_destination,
      inquiry_date:
        selectDateValueToDatePaddingZero(inquiryData.inquiry_date) || "",
      inquiry_content: isEmpty(inquiryData.inquiry_content)
        ? null
        : inquiryData.inquiry_content,
      respondent_name: isEmpty(inquiryData.respondent_name)
        ? null
        : inquiryData.respondent_name,
      response_date:
        selectDateValueToDatePaddingZero(inquiryData.response_date) || "",
      response_content: isEmpty(inquiryData.response_content)
        ? null
        : inquiryData.response_content,
      type_consultation: inquiryData.type_consultation || undefined
    }
  };
  return request;
};
