import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  CUSTOM_RECORD_WITH_CATEGORY_DEFAULT_ITEM,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO,
  FacilityType
} from "@constants/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO } from "@constants/mgr/DOKOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO } from "@constants/mgr/KODOENGO/variables";

/**
 * "職員考察"ラベルの取得
 * 記録の項目設定 > 支援記録の項目設定の職員考察は名称の変更が可能なため、customRecordsを渡されたら現在の名称を取得する
 */

type getStaffCommentParam = {
  customRecords: CustomRecordsWithCategoryState;
  facilityType: FacilityType;
};

const extractSpecialCategoryNum = (facilityType: FacilityType): number => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.special;
    case FacilityType.JUDOHOMONKAIGO:
      return CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO.special;
    case FacilityType.DOKOENGO:
      return CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.special;
    case FacilityType.KODOENGO:
      return CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_DOKOENGO_KODOENGO.special;
    default:
      return CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.special;
  }
};

const getStaffCommentNum = (facilityType: FacilityType): number => {
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return CUSTOM_RECORD_WITH_CATEGORY_DEFAULT_ITEM.staff_comment;
    case FacilityType.JUDOHOMONKAIGO:
      return CUSTOM_RECORD_WITH_CATEGORY_DEFAULT_ITEM.staff_comment;
    case FacilityType.DOKOENGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.staff_comment;
    case FacilityType.KODOENGO:
      return SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.staff_comment;
    default:
      return 0;
  }
};

export const getStaffCommentLabelWithCategory = ({
  customRecords,
  facilityType
}: getStaffCommentParam): string => {
  const specialCategory = extractSpecialCategoryNum(facilityType);
  const customRecordsSpecialCategory = customRecords.find(
    (category) => category.category_type === specialCategory
  );

  const staffCommentNum = getStaffCommentNum(facilityType);
  const staffComment = customRecordsSpecialCategory
    ? customRecordsSpecialCategory.custom_record_items.find(
        (item) =>
          item.default_item === staffCommentNum &&
          item.setting_type === CUSTOM_RECORD_TARGET_TYPE.service_delivery
      )
    : undefined;
  return staffComment ? staffComment.name : "職員考察";
};
