import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import { RouteComponentProps } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import ErrorIcon from "@material-ui/icons/Error";
import ContactPhone from "@material-ui/icons/ContactPhone";
import LinkButton from "@components/atoms/LinkButton";
import AuthTemplate from "@components/templates/AuthTemplate";
import { LOGIN } from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      margin: "0 auto",
      "@media only screen and (min-width: 640px)": {
        maxWidth: 640
      }
    },
    header: {
      margin: 0,
      borderBottom: "1px solid #cfd8dc",
      fontSize: 24,
      fontWeight: "normal",
      color: "#37474f",
      "@media only screen and (min-width: 640px)": {
        padding: "24px 32px 9px"
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px 10px"
      }
    },
    section: {
      "@media only screen and (min-width: 640px)": {
        padding: 32
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px 32px"
      }
    },
    subText: {
      fontSize: 16,
      color: "#666666",
      "@media only screen and (min-width: 640px)": {
        lineHeight: 1.75,
        letterSpacing: 0.5
      },
      "@media only screen and (max-width: 639px)": {
        lineHeight: 1.75,
        letterSpacing: "0.15px"
      }
    },
    supplementWrapper: {
      marginTop: 24,
      display: "flex"
    },
    alertIconUpper: {
      color: "#ff5656",
      position: "relative",
      top: 2,
      width: 17,
      height: 17,
      marginRight: 6
    },
    subTextSmall: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0.3,
      color: "rgba(0, 0, 0, 0.6)"
    },
    telArea: {
      marginTop: 16,
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      "@media only screen and (min-width: 640px)": {
        marginLeft: 24
      }
    },
    tel: {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      fontWeight: "normal",
      color: "#37474f",
      fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(",")
    },
    telIcon: {
      marginRight: 8,
      transform: "translateY(15%)",
      fontSize: 16
    },
    linkButton: {
      marginTop: 32
    }
  });

type Props = RouteComponentProps & WithStyles<typeof styles>;

/**
 * パスワード忘れた 完了
 */
class ReminderComplete extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    return (
      <AuthTemplate>
        <Paper className={this.props.classes.paper}>
          <h1 className={this.props.classes.header}>
            パスワード再設定メール 送信完了
          </h1>
          <section className={this.props.classes.section}>
            <div className={this.props.classes.subText}>
              パスワード再設定の為のメールが送信されました。
              <br />
              メールに記載の手順に従ってパスワードの再設定をおこなってください。
            </div>
            <div className={this.props.classes.supplementWrapper}>
              <ErrorIcon className={this.props.classes.alertIconUpper} />
              <div className={this.props.classes.subTextSmall}>
                しばらく待ってもパスワード再設定の為のメールが届かない場合は、
                <br />
                下記 電話サポートまでお問い合わせください。
              </div>
            </div>
            <div className={this.props.classes.telArea}>
              <div className={this.props.classes.tel}>
                <ContactPhone className={this.props.classes.telIcon} />
                080-0080-4593
              </div>
              受付時間：10:00〜17:00（平日&emsp;※土日祝・年末年始・弊社休日を除く）
            </div>
            <div className={this.props.classes.linkButton}>
              <LinkButton to={LOGIN}>ログイン画面に戻る</LinkButton>
            </div>
          </section>
        </Paper>
      </AuthTemplate>
    );
  }
}

export default withStyles(styles)(ReminderComplete);
