import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { FacilityState } from "@stores/domain/mgr/IDOSHIEN/facility/types";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import { UsersValues } from "@initialize/mgr/IDOSHIEN/users/initialValues";
import {
  START_ADD_YEAR_TO,
  END_ADD_YEAR_TO
} from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    fieldMargin: {
      margin: "6px 0 0 16px"
    },
    fieldWrapper: {
      position: "relative"
    },
    MB12: {
      marginBottom: 12
    },
    tipsServiceStart: {
      marginLeft: 120,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    },
    tipsPayStart: {
      marginLeft: 100,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    },
    PT16: {
      paddingTop: 16
    },
    MB32: {
      marginBottom: 32
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  facility: FacilityState;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceUseFieldsCore = (props: Props): JSX.Element => {
  return (
    <FormPaper>
      <div className={props.classes.MB32}>
        <SectionTitle label="サービス利用詳細" />
      </div>
      <Typography className={props.classes.MB12}>サービス提供期間</Typography>
      <div className={props.classes.fieldMargin}>
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.inServiceStartDate"
          label="サービス提供開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsServiceStart}>
              <HelpToolTip
                title={<HelpTipMessages name="inServiceStartDateOfVisit" />}
              />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.inServiceEndDate"
          label="サービス提供終了日"
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <Typography className={props.classes.MB12}>受給者証</Typography>
      <div className={props.classes.fieldMargin}>
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.payStartDate"
          label="支給決定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={START_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsPayStart}>
              <HelpToolTip title={<HelpTipMessages name="pay_start_date" />} />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.payEndDate"
          label="支給決定終了日"
          addYearTo={END_ADD_YEAR_TO}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <FormGroup>
        <div className={props.classes.fieldWrapper}>
          <FormikRadioButtons
            name="serviceUse.disabilityClass"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
            tooltip={
              <HelpToolTip title={<HelpTipMessages name="disabilityClass" />} />
            }
            disabled={props.formikProps.values.basic.classifyHandicappedFlag}
          />
        </div>
        <Typography className={props.classes.MB12}>契約支給量</Typography>
        <div className={props.classes.fieldMargin}>
          <FormGroup row>
            <FormikTextField
              name="serviceUse.payTimesIndividualSupport"
              label="個別支援"
              placeholder="0.5"
              endAdornmentLabel="時間/月"
            />
            <FormikTextField
              name="serviceUse.payMonthsIndividualSupport"
              placeholder="0"
              endAdornmentLabel="回/月"
              maxLength={3}
              className={props.classes.PT16}
            />
          </FormGroup>
          <FormGroup row>
            <FormikTextField
              name="serviceUse.payTimesGroupSupport"
              label="グループ支援"
              placeholder="0.5"
              endAdornmentLabel="時間/月"
            />
            <FormikTextField
              name="serviceUse.payMonthsGroupSupport"
              placeholder="0"
              endAdornmentLabel="回/月"
              maxLength={3}
              className={props.classes.PT16}
            />
          </FormGroup>
          <FormGroup row>
            <FormikTextField
              name="serviceUse.payTimesVehicleTransferSupport"
              label="車両移送支援"
              placeholder="0.5"
              endAdornmentLabel="時間/月"
            />
            <FormikTextField
              name="serviceUse.payMonthsVehicleTransferSupport"
              placeholder="0"
              endAdornmentLabel="回/月"
              maxLength={3}
              className={props.classes.PT16}
            />
          </FormGroup>
          <FormGroup row>
            <FormikTextField
              name="serviceUse.payTimesSchoolAndHospitalCommutingSupport"
              label="通学・通院支援"
              placeholder="0.5"
              endAdornmentLabel="時間/月"
            />
            <FormikTextField
              name="serviceUse.payMonthsSchoolAndHospitalCommutingSupport"
              placeholder="0"
              endAdornmentLabel="回/月"
              maxLength={3}
              className={props.classes.PT16}
            />
          </FormGroup>
          <FormGroup row>
            <FormikTextField
              name="serviceUse.payTimesOtherSupport"
              label="その他"
              placeholder="0.5"
              endAdornmentLabel="時間/月"
            />
            <FormikTextField
              name="serviceUse.payMonthsOtherSupport"
              placeholder="0"
              endAdornmentLabel="回/月"
              maxLength={3}
              className={props.classes.PT16}
            />
          </FormGroup>
        </div>
        <FormikTextField
          name="serviceUse.userChargeRate"
          label="利用者負担"
          placeholder="0"
          endAdornmentLabel="%"
          maxLength={3}
        />
        <FormikTextField
          name="serviceUse.monthlyUserChargeLimit"
          label="負担上限額"
          placeholder="0"
          endAdornmentLabel="円"
          maxLength={9}
        />
      </FormGroup>
    </FormPaper>
  );
};

export const ServiceUseFields = withStyles(styles)(ServiceUseFieldsCore);
