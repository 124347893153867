import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { FormikProps } from "formik";
import {
  InitialValues,
  CarePlanDetailsFields
} from "@interfaces/mgr/KODOENGO/Users/initial";
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import {
  KODOENGO_ACTION_CLASS_LIST,
  DETAIL_IN_TIME_VALIDATION_LIST,
  DETAIL_OUT_TIME_VALIDATION_LIST
} from "@constants/mgr/KODOENGO/variables";
import { FormGroup } from "@material-ui/core";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 551,
      marginBottom: 18
    },
    tableColumnTimeItem: {
      marginTop: 16
    },
    firstTableCell: {
      paddingLeft: 16
    },
    firstItemCell: {
      width: 91,
      marginLeft: 16,
      paddingTop: 20,
      display: "inline-flex",
      verticalAlign: "top"
    },
    iconCell: {
      paddingTop: 20,
      display: "inline-flex",
      verticalAlign: "top"
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  unitKey: string;
  onClickDelete: (index: number) => void;
  onChangeTime: (index: number, start: string, end: string) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const UnitsTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const units: CarePlanDetailsFields[] =
      props.formikProps.values.initial[props.unitKey];

    const headData = [
      {
        label: "空き",
        className: `${classes.officeInfoTableCell} ${classes.firstTableCell}`
      },
      {
        label: "開始時間*",
        className: classes.officeInfoTableCell
      },
      {
        label: "終了時間*",
        className: classes.officeInfoTableCell
      },
      {
        label: "削除",
        className: classes.officeInfoTableCell
      }
    ];

    const bodyData = (
      i: number,
      onClickDelete: () => void,
      onChangeInTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void,
      onChangeOutTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void
    ): KnowbeTableCellParam[] => [
      {
        label: (
          <div className={props.classes.firstItemCell}>
            {KODOENGO_ACTION_CLASS_LIST.NONE.label}
          </div>
        ),
        className: classes.officeInfoTableCell
      },
      {
        label: (
          <FormikTime
            name={`initial.${props.unitKey}[${i}].inTime`}
            label=""
            placeholder="00:00"
            size="superSmall"
            maxLength={5}
            className={props.classes.tableColumnTimeItem}
            style={{ marginBottom: 16, width: 156 }}
            onChangeHookTime={onChangeInTime}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].inTime
                ? DETAIL_IN_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].inTime
                  )
                : false
            }
          />
        ),
        className: classes.officeInfoTableCell
      },
      {
        label: (
          <FormikTime
            name={`initial.${props.unitKey}[${i}].outTime`}
            label=""
            placeholder="00:00"
            size="superSmall"
            maxLength={5}
            className={props.classes.tableColumnTimeItem}
            style={{ marginBottom: 16, width: 156 }}
            onChangeHookTime={onChangeOutTime}
            error={
              props.formikProps.errors &&
              props.formikProps.errors.initial &&
              props.formikProps.errors.initial[props.unitKey] &&
              props.formikProps.errors.initial[props.unitKey][i] &&
              props.formikProps.errors.initial[props.unitKey][i].outTime
                ? DETAIL_OUT_TIME_VALIDATION_LIST.includes(
                    props.formikProps.errors.initial[props.unitKey][i].outTime
                  )
                : false
            }
          />
        ),
        className: classes.officeInfoTableCell
      },
      {
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickDelete}
            />
          </FormGroup>
        ),
        className: classes.officeInfoTableCell
      }
    ];

    const bodyValues = units.map((unit: CarePlanDetailsFields, i) => {
      const onClickDelete = (): void => {
        props.onClickDelete(i);
      };
      const onChangeInTime = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(i, event.target.value, unit.outTime);
      };
      const onChangeOutTime = (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ): void => {
        props.onChangeTime(i, unit.inTime, event.target.value);
      };

      return bodyData(i, onClickDelete, onChangeInTime, onChangeOutTime);
    });

    return (
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead uniqueKey="明細" height={40} items={headData} />
        <KnowbeTableBody uniqueKey="明細情報" itemsContainer={bodyValues} />
      </KnowbeTable>
    );
  }
);
