import { GetMonitoringMonthResponse } from "@api/requests/monitoringMonth/getMonitoringMonth";

/**
 * ActionNames
 */
export const FETCH_KEIKAKUSODAN_MONITORING_MONTH_STARTED =
  "KEIKAKUSODAN/FETCH_MONITORING_MONTH_STARTED";
export const FETCH_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS =
  "KEIKAKUSODAN/FETCH_MONITORING_MONTH_SUCCESS";
export const FETCH_KEIKAKUSODAN_MONITORING_MONTH_FAILED =
  "KEIKAKUSODAN/FETCH_MONITORING_MONTH_FAILED";
export const POST_KEIKAKUSODAN_MONITORING_MONTH_STARTED =
  "KEIKAKUSODAN/POST_KEIKAKUSODAN_MONITORING_MONTH_STARTED";
export const POST_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS =
  "KEIKAKUSODAN/POST_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS";
export const POST_KEIKAKUSODAN_MONITORING_MONTH_FAILED =
  "KEIKAKUSODAN/POST_KEIKAKUSODAN_MONITORING_MONTH_FAILED";

/**
 * State
 */
export type MonitoringMonthState = {
  monitoringMonth: GetMonitoringMonthResponse["data"];
};
