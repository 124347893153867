import {
  InputValue,
  SelectValue,
  SelectDateValue,
  RadioButtonValue,
  SelectMonthValue,
  FieldItem
} from "@interfaces/ui/form";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { dateToSelectDateValue, dateToSelectMonthValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_LIST,
  SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE
} from "@constants/variables";
import { ConsultationRecordState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { getStaffWithSnap } from "@utils/domain/staffs/getStaffWithSnap";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";
import { PrintSchedulesItem } from "@interfaces/record/print/print";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

export type ConsultationValues = {
  id: number | null;
  createdAt: string;
  updatedAt: string;
  targetFlg: number;
  creationDate: SelectDateValue; // 計画(案)作成日
  author: SelectValue; // 作成者
  monitoringPeriod: InputValue; // モニタリング期間
  monitoringStartYm: SelectMonthValue; // モニタリング開始年月
  planStartYm: SelectMonthValue; // 計画開始年月
  classifyDisabilitySupport: RadioButtonValue; // 障害支援区分
  incomeKind: RadioButtonValue; // 利用者負担上限額
  disabledWelfareServiceRecipientNumber: InputValue; // 障害福祉サービス受給者証番号
  regularPlaceRecipientNumber: InputValue; // 通所受給者証番号
  consultationSupportRecipientNumber: InputValue; // 地域相談支援受給者証番号
  desiredLife: InputValue; // 利用者及びその家族の生活に対する意向（希望する生活）
  supportPolicy: InputValue; // 総合的な援助の方針
  longTermGoal: InputValue; // 長期目標
  shortTermGoal: InputValue; // 短期目標
  supportPlanConsultationDetails: {
    support_plan_consultation_details_id: number | null;
    needs: InputValue; // 解決すべき課題（本人のニーズ）
    support_goal: InputValue; // 支援目標
    achievement_time: InputValue; // 達成時期
    welfare_service_type: InputValue; // 福祉サービス等 種類・内容・量（頻度・時間）
    provider_name: InputValue; // 福祉サービス等 提供事業者名（担当者名・電話）
    role_in_problem_solving: InputValue; // 課題解決のための本人の役割
    evaluation_time: InputValue; // 評価時期
    other_considerations: InputValue; // その他留意事項
    is_delete: number;
  }[];
  supportPlanConsultationSchedule: {
    support_plan_consultation_schedule_id: number | null;
    day_of_week: number[]; // 曜日
    start_time: InputValue; // 開始時間
    next_day_flg: InputValue; // 終了時間（当日/翌日）
    end_time: InputValue; // 終了時間（テキスト）
    content: InputValue; // 内容
    is_delete: number;
  }[];
  mainActivities: string; // 主な日常生活上の活動
  nonWeeklyService: string; // 週単位以外のサービス
  overallLife: string; // サービス提供によって実現する生活の全体像
  staffComment: string; // 職員コメント
  type_consultation?: number; // 相談種別
};

// 優先順位カード初期値
export const initialSupportPlanConsultationDetails: ConsultationValues["supportPlanConsultationDetails"][0] = {
  support_plan_consultation_details_id: null,
  needs: "",
  support_goal: "",
  achievement_time: "",
  welfare_service_type: "",
  provider_name: "",
  role_in_problem_solving: "",
  evaluation_time: "",
  other_considerations: "",
  is_delete: 0
};

// 週間計画表初期値
export const initialSupportPlanConsultationSchedule: ConsultationValues["supportPlanConsultationSchedule"][0] = {
  support_plan_consultation_schedule_id: null,
  day_of_week: [],
  start_time: "",
  next_day_flg: "0",
  end_time: "",
  content: "",
  is_delete: 0
};

// 計画の詳細
const supportPlanConsultationDetailsValues = (
  detailsValues: ConsultationRecordState["supportPlanConsultationDetails"]
): ConsultationValues["supportPlanConsultationDetails"] => {
  return detailsValues.map((detail) => ({
    support_plan_consultation_details_id:
      detail.support_plan_consultation_details_id,
    needs: detail.needs,
    support_goal: detail.support_goal,
    achievement_time: detail.achievement_time,
    welfare_service_type: detail.welfare_service_type,
    provider_name: detail.provider_name || "",
    role_in_problem_solving: detail.role_in_problem_solving,
    evaluation_time: detail.evaluation_time,
    other_considerations: detail.other_considerations,
    is_delete: 0
  }));
};

// 週間計画表
const supportPlanConsultationScheduleValues = (
  scheduleValues: ConsultationRecordState["supportPlanConsultationSchedule"]
): ConsultationValues["supportPlanConsultationSchedule"] => {
  return scheduleValues.map((schedule) => ({
    support_plan_consultation_schedule_id:
      schedule.support_plan_consultation_schedule_id,
    day_of_week: schedule.day_of_week,
    start_time: schedule.start_time
      ? dateToLocalisedString(schedule.start_time, "HH:mm")
      : "",
    next_day_flg: undefinedToReturnValue(schedule.next_day_flg),
    end_time: schedule.end_time
      ? dateToLocalisedString(schedule.end_time, "HH:mm")
      : "",
    content: schedule.content,
    is_delete: 0
  }));
};

// 新規作成用初期値
export const initialValuesNew = (
  user: UsersInFacilityState["user"],
  targetFag: number,
  typeConsultation?: number
): ConsultationValues => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");

  // 計画相談or障害児で参照するパラメータが変わる値
  const targetConsultationUserInFacility =
    typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN
      ? findUserInFacilityShogaijisodan(user)
      : findUserInFacilityKeikakusodan(user);

  // 新規作成
  return {
    id: null,
    createdAt: "",
    updatedAt: "",
    targetFlg: targetFag,
    creationDate: dateToSelectDateValue(currentDate),
    author: "",
    monitoringPeriod: "",
    monitoringStartYm: targetConsultationUserInFacility
      ? dateToSelectDateValue(
          targetConsultationUserInFacility.date_begin_plan_support_monitor || ""
        )
      : dateToSelectMonthValue(""),
    planStartYm: dateToSelectMonthValue(""),
    classifyDisabilitySupport: targetConsultationUserInFacility
      ? targetConsultationUserInFacility.disability_class.toString()
      : DISABILITY_CLASS_LIST[0].value,
    incomeKind: targetConsultationUserInFacility
      ? targetConsultationUserInFacility.income_kind
      : INCOME_KIND_LIST[0].value,
    disabledWelfareServiceRecipientNumber:
      targetConsultationUserInFacility &&
      targetConsultationUserInFacility.recipient_number != null
        ? targetConsultationUserInFacility.recipient_number
        : "",
    regularPlaceRecipientNumber: "",
    consultationSupportRecipientNumber: "",
    desiredLife: "",
    supportPolicy: "",
    longTermGoal: "",
    shortTermGoal: "",
    supportPlanConsultationDetails: [initialSupportPlanConsultationDetails],
    supportPlanConsultationSchedule: [initialSupportPlanConsultationSchedule],
    mainActivities: "",
    nonWeeklyService: "",
    overallLife: "",
    staffComment: "",
    type_consultation: undefined
  };
};

// 編集用初期値
export const initialValuesEdit = (
  values: ConsultationRecordState,
  staffOptions: FieldItem[]
): ConsultationValues => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
  const creationDate = values.creationDate || currentDate;
  const authorId = getStaffWithSnap(values.author, staffOptions);

  return {
    id: values.id,
    createdAt: values.createdAt,
    updatedAt: values.updatedAt,
    targetFlg: values.targetFlg,
    creationDate: dateToSelectDateValue(creationDate),
    author: authorId || "",
    monitoringPeriod: values.monitoringPeriod,
    monitoringStartYm: dateToSelectMonthValue(values.monitoringStartYm),
    planStartYm: dateToSelectMonthValue(values.planStartYm),
    classifyDisabilitySupport: `${values.classifyDisabilitySupport}`,
    incomeKind: `${values.incomeKind}`,
    disabledWelfareServiceRecipientNumber:
      values.disabledWelfareServiceRecipientNumber,
    regularPlaceRecipientNumber: values.regularPlaceRecipientNumber,
    consultationSupportRecipientNumber:
      values.consultationSupportRecipientNumber,
    desiredLife: values.desiredLife,
    supportPolicy: values.supportPolicy,
    longTermGoal: values.longTermGoal,
    shortTermGoal: values.shortTermGoal,
    supportPlanConsultationDetails: supportPlanConsultationDetailsValues(
      values.supportPlanConsultationDetails
    ),
    supportPlanConsultationSchedule: supportPlanConsultationScheduleValues(
      values.supportPlanConsultationSchedule
    ),
    mainActivities: values.mainActivities,
    nonWeeklyService: values.nonWeeklyService,
    overallLife: values.overallLife,
    staffComment: values.staffComment
  };
};

// 読み込み元に値があれば使用する
const createLoadedValue = (
  copy:
    | ConsultationRecordState["supportPlanConsultationDetails"][0]
    | null
    | undefined,
  value: ConsultationValues["supportPlanConsultationDetails"][0] | undefined,
  key: string
): string => {
  if (copy) {
    return copy[key] || "";
  }
  if (value) {
    return value[key] || "";
  }
  return "";
};

// 優先順位読み込み
const loadDetails = (
  detailsValues: ConsultationValues["supportPlanConsultationDetails"],
  copyDetailsValues: ConsultationRecordState["supportPlanConsultationDetails"]
): ConsultationValues["supportPlanConsultationDetails"] => {
  const filteredDetailsValues = detailsValues.filter(
    (value) => !value.is_delete
  );
  // 最終的に生成される配列の要素数
  const loadedLength = Math.max(
    filteredDetailsValues.length,
    copyDetailsValues.length
  );

  // 値が入っている読み込み先の優先順位のみ抽出
  const copyValues = copyDetailsValues.map((copy) => {
    // id以外空値か
    const isEmptyValue = Object.values(copy)
      .slice(1)
      .every((item) => !item);
    return isEmptyValue ? null : copy;
  });

  const arr = Array.from(new Array(loadedLength)).map((_, i) => {
    const value = filteredDetailsValues[i];
    const copy = copyValues[i];
    return {
      support_plan_consultation_details_id: value
        ? value.support_plan_consultation_details_id
        : null,
      needs: createLoadedValue(copy, value, "needs"),
      support_goal: createLoadedValue(copy, value, "support_goal"),
      achievement_time: createLoadedValue(copy, value, "achievement_time"),
      welfare_service_type: createLoadedValue(
        copy,
        value,
        "welfare_service_type"
      ),
      provider_name: createLoadedValue(copy, value, "provider_name"),
      role_in_problem_solving: createLoadedValue(
        copy,
        value,
        "role_in_problem_solving"
      ),
      evaluation_time: createLoadedValue(copy, value, "evaluation_time"),
      other_considerations: createLoadedValue(
        copy,
        value,
        "other_considerations"
      ),
      is_delete: 0
    };
  });
  return arr;
};

// 計画の詳細読み込み
export const loadValuesDetails = (
  values: ConsultationValues,
  copyValues: ConsultationRecordState
): ConsultationValues => {
  return {
    id: values.id,
    createdAt: values.createdAt,
    updatedAt: values.updatedAt,
    targetFlg: values.targetFlg,
    creationDate: values.creationDate,
    author: values.author,
    monitoringPeriod: values.monitoringPeriod,
    monitoringStartYm: values.monitoringStartYm,
    planStartYm: values.planStartYm,
    classifyDisabilitySupport: values.classifyDisabilitySupport,
    incomeKind: values.incomeKind,
    disabledWelfareServiceRecipientNumber:
      values.disabledWelfareServiceRecipientNumber,
    regularPlaceRecipientNumber: values.regularPlaceRecipientNumber,
    consultationSupportRecipientNumber:
      values.consultationSupportRecipientNumber,
    desiredLife: copyValues.desiredLife,
    supportPolicy: copyValues.supportPolicy,
    longTermGoal: copyValues.longTermGoal,
    shortTermGoal: copyValues.shortTermGoal,
    supportPlanConsultationDetails: loadDetails(
      values.supportPlanConsultationDetails,
      copyValues.supportPlanConsultationDetails
    ),
    supportPlanConsultationSchedule: values.supportPlanConsultationSchedule,
    mainActivities: values.mainActivities,
    nonWeeklyService: values.nonWeeklyService,
    overallLife: values.overallLife,
    staffComment: values.staffComment,
    type_consultation: values.type_consultation
  };
};

// スケジュール読み込み
const loadSchedule = (
  scheduleValues: ConsultationValues["supportPlanConsultationSchedule"],
  copyScheduleValues: ConsultationRecordState["supportPlanConsultationSchedule"]
): ConsultationValues["supportPlanConsultationSchedule"] => {
  const filteredScheduleValues = scheduleValues.filter(
    (value) => !value.is_delete
  );
  // 最終的に生成される配列の要素数
  const loadedLength = Math.max(
    filteredScheduleValues.length,
    copyScheduleValues.length
  );

  const arr = Array.from(new Array(loadedLength)).map((_, i) => {
    const value = filteredScheduleValues[i];
    const copy = copyScheduleValues[i];
    if (copy) {
      return {
        support_plan_consultation_schedule_id: value
          ? value.support_plan_consultation_schedule_id
          : null,
        day_of_week: copy.day_of_week,
        start_time: copy.start_time
          ? dateToLocalisedString(copy.start_time, "HH:mm")
          : "",
        next_day_flg: undefinedToReturnValue(copy.next_day_flg),
        end_time: copy.end_time
          ? dateToLocalisedString(copy.end_time, "HH:mm")
          : "",
        content: copy.content,
        is_delete: 0
      };
    }
    // 編集中のスケジュールにないものは削除する
    return {
      support_plan_consultation_schedule_id:
        value.support_plan_consultation_schedule_id,
      day_of_week: [],
      start_time: "",
      next_day_flg: "",
      end_time: "",
      content: "",
      is_delete: 1
    };
  });
  return arr;
};

// 週間計画読み込み
export const loadValuesSchedule = (
  values: ConsultationValues,
  copyValues: ConsultationRecordState
): ConsultationValues => {
  return {
    id: values.id,
    createdAt: values.createdAt,
    updatedAt: values.updatedAt,
    targetFlg: values.targetFlg,
    creationDate: values.creationDate,
    author: values.author,
    monitoringPeriod: values.monitoringPeriod,
    monitoringStartYm: values.monitoringStartYm,
    planStartYm: values.planStartYm,
    classifyDisabilitySupport: values.classifyDisabilitySupport,
    incomeKind: values.incomeKind,
    disabledWelfareServiceRecipientNumber:
      values.disabledWelfareServiceRecipientNumber,
    regularPlaceRecipientNumber: values.regularPlaceRecipientNumber,
    consultationSupportRecipientNumber:
      values.consultationSupportRecipientNumber,
    desiredLife: values.desiredLife,
    supportPolicy: values.supportPolicy,
    longTermGoal: values.longTermGoal,
    shortTermGoal: values.shortTermGoal,
    supportPlanConsultationDetails: values.supportPlanConsultationDetails,
    supportPlanConsultationSchedule: loadSchedule(
      values.supportPlanConsultationSchedule,
      copyValues.supportPlanConsultationSchedule
    ),
    mainActivities: copyValues.mainActivities,
    nonWeeklyService: copyValues.nonWeeklyService,
    overallLife: copyValues.overallLife,
    staffComment: values.staffComment,
    type_consultation: values.type_consultation
  };
};

// 計画詳細上書き確認
export const isDetailUpdate = (values: ConsultationValues): boolean => {
  let result = false;
  if (
    values.desiredLife !== "" ||
    values.supportPolicy !== "" ||
    values.shortTermGoal !== "" ||
    values.longTermGoal !== ""
  ) {
    result = true;
  }

  const filteredDetailsValues = values.supportPlanConsultationDetails.filter(
    (value) => !value.is_delete
  );

  // 優先順位が初期値と同じ又は値がないか(idを除き)
  const isPriorityUpdate = !(
    filteredDetailsValues.length === 0 ||
    (filteredDetailsValues.length === 1 &&
      isEqual(
        omit(filteredDetailsValues[0], [
          "support_plan_consultation_details_id"
        ]),

        {
          ...omit(initialSupportPlanConsultationDetails, [
            "support_plan_consultation_details_id"
          ])
        }
      ))
  );
  if (isPriorityUpdate) {
    result = true;
  }

  return result;
};

// 週間計画表上書き確認
export const isScheduleUpdate = (values: ConsultationValues): boolean => {
  let result = false;
  if (
    values.overallLife !== "" ||
    values.nonWeeklyService !== "" ||
    values.mainActivities !== ""
  ) {
    result = true;
  }

  const filteredSchedukeValues = values.supportPlanConsultationSchedule.filter(
    (value) => !value.is_delete
  );

  // スケジュールが初期値と同じ又は値がないか(idを除き)
  const isPriorityUpdate = !(
    filteredSchedukeValues.length === 0 ||
    (filteredSchedukeValues.length === 1 &&
      isEqual(
        omit(filteredSchedukeValues[0], [
          "support_plan_consultation_schedule_id"
        ]),

        {
          ...omit(initialSupportPlanConsultationSchedule, [
            "support_plan_consultation_schedule_id"
          ])
        }
      ))
  );

  if (isPriorityUpdate) {
    result = true;
  }

  return result;
};

// 印刷プレビュー用
export const printSchedules = (
  defaultVale: ConsultationRecordState["supportPlanConsultationSchedule"]
): PrintSchedulesItem[] => {
  const baseDay = SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE;
  const res: PrintSchedulesItem[] = [];

  defaultVale.forEach((item) => {
    item.day_of_week.forEach((w) => {
      if (item.start_time && item.end_time) {
        const resItem = { title: "", start: "", end: "" };
        const start = item.start_time;
        const end = item.end_time;
        let dayStart;
        let dayEnd;

        if (item.next_day_flg === 1) {
          dayStart = baseDay + w;
          dayEnd = baseDay + Number(Number(w) + 1);
        } else {
          dayStart = baseDay + w;
          dayEnd = baseDay + w;
        }

        resItem.title = `${item.content}`;
        resItem.start = `${dayStart}T${start}`;
        resItem.end = `${dayEnd}T${end}`;

        res.push(resItem);
      }
    });
  });

  return res;
};
