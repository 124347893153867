import * as React from "react";
import * as URL from "@constants/url";
import { getUrlParams } from "@/utils/url";
import { RouteComponentProps } from "react-router-dom";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { MonitoringReportPrintContent } from "@components/organisms/mgr/KEIKAKUSODAN/record/monitoringReport/MonitoringReportPrintContent";
import { GetMonitoringReport } from "@api/requests/monitoringReport/getMonitoringReport";

type OwnProps = {} & RouteComponentProps<{
  uifId: string;
  monitoringReportId: string;
}>;
type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  monitoringReport: GetMonitoringReport["data"];
};
type DispatchProps = {
  fetchFacility: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchMonitoringReport: (
    uifId: string,
    monitoringReportId: string
  ) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const MonitoringReportPrintCore = (props: Props): JSX.Element => {
  const { search } = props.location;
  const { uifId, monitoringReportId } = props.match.params;
  const { facility, user, monitoringReport } = props;
  // ページ名
  const listName = "計画・モニタリング・会議記録";
  const pageName = "モニタリング報告書";
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // コメント取得
  const [isStaffComment, setIsStaffComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_comment?: string;
    } = getUrlParams(search);
    setIsStaffComment(queryParameter.display_comment === "staff_comment");
  }, [search]);

  // fetch
  React.useEffect(() => {
    props.fetchFacility();
    props.fetchOneUser(uifId);
    props.fetchMonitoringReport(uifId, monitoringReportId);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
        type="horizontal"
      >
        <MonitoringReportPrintContent
          facility={facility}
          user={user}
          monitoringReport={monitoringReport}
          isStaffComment={isStaffComment}
          title={pageName}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    facility: state.KEIKAKUSODAN.facility,
    user: state.KEIKAKUSODAN.userInFacility.user,
    monitoringReport: state.KEIKAKUSODAN.monitoringReport.monitoringReportRecord
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN } = dispatches;
  return {
    fetchFacility: KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      KEIKAKUSODAN.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchMonitoringReport: (
      uifId: string,
      monitoringReportId: string
    ): Promise<void> =>
      KEIKAKUSODAN.monitoringReportDispatcher(dispatch).fetchMonitoringReport(
        uifId,
        monitoringReportId
      )
  };
};

export const MonitoringReportPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(MonitoringReportPrintCore);
