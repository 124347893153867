/**
 * 指定した期間内かどうかのチェック
 */

import { SelectDateValue } from "@interfaces/ui/form";
import { selectDateValueToDate } from "@utils/date";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import future from "@validator/rules/future";

export const dateRangeWithInDateRange = (
  baseStartDate: SelectDateValue,
  baseEndDate: SelectDateValue,
  targetStartDate: SelectDateValue,
  targetEndDate: SelectDateValue,
  message: string,
  validateTarget: "START_DATE" | "END_DATE" = "START_DATE"
): {
  year: string | undefined;
  month: undefined;
  day: undefined;
} => {
  let errorMessage;

  if (
    targetStartDate.year &&
    targetStartDate.month &&
    targetStartDate.day &&
    // バリデーションの対象が終了年月日（任意）かつ未選択のとき判定しない
    !(
      validateTarget === "END_DATE" &&
      targetEndDate.year === DEFAULT_SELECT_VALUE
    )
  ) {
    const baseStart = new Date(
      selectDateValueToDate(baseStartDate).replace(/-/g, "/")
    );
    const targetStart = new Date(
      selectDateValueToDate(targetStartDate).replace(/-/g, "/")
    );

    // baseStartDateが不正な入力の場合はエラー
    if (!selectDateValueToDate(baseStartDate)) {
      errorMessage = message;
    }

    if (baseEndDate.year === DEFAULT_SELECT_VALUE) {
      // baseEndDateが未選択のとき、startのみで判定する
      if (targetStart.getTime() < baseStart.getTime()) {
        errorMessage = message;
      }

      // baseEndDateが未選択でtargetEndDateが選択されているとき
      if (
        targetEndDate.year !== DEFAULT_SELECT_VALUE &&
        targetEndDate.month &&
        targetEndDate.day
      ) {
        // 日付が逆転してる場合は判定しない
        if (future(targetEndDate, targetStartDate).year) {
          errorMessage = undefined;
        } else if (targetStart.getTime() < baseStart.getTime()) {
          // targetStartDateがbaseStartDateより過去の場合エラー
          errorMessage = message;
        }
      }
    } else if (targetEndDate.year === DEFAULT_SELECT_VALUE) {
      const baseEnd = new Date(
        selectDateValueToDate(baseEndDate).replace(/-/g, "/")
      );

      // baseEndDateが不正な入力の場合はエラー
      if (!selectDateValueToDate(baseEndDate)) {
        errorMessage = message;
      }

      // targetEndDateがないとき、targetStartDateがbaseStart~baseEndで判定する
      if (
        targetStart.getTime() < baseStart.getTime() ||
        targetStart.getTime() > baseEnd.getTime()
      ) {
        errorMessage = message;
      }
    } else {
      const targetEnd = new Date(
        selectDateValueToDate(targetEndDate).replace(/-/g, "/")
      );
      const baseEnd = new Date(
        selectDateValueToDate(baseEndDate).replace(/-/g, "/")
      );

      // baseEndDateが不正な入力の場合はエラー
      if (!selectDateValueToDate(baseEndDate)) {
        errorMessage = message;
      }

      if (
        targetStart.getTime() < baseStart.getTime() ||
        targetEnd.getTime() > baseEnd.getTime()
      ) {
        errorMessage = message;
      }

      // 日付が逆転してる場合は判定しない
      if (future(targetEndDate, targetStartDate).year) {
        errorMessage = undefined;
      }
    }
  }
  return {
    year: errorMessage,
    month: undefined,
    day: undefined
  };
};
