import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";

import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";

import { FormikProps } from "formik";
import Button from "@material-ui/core/Button";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { CarePlanSchedulesDialog } from "@components/organisms/mgr/common/record/carePlan/Dialog/Edit/CarePlanSchedulesDialog";
import { CarePlanSchedulesDialogCreate } from "@components/organisms/mgr/common/record/carePlan/Dialog/Create/CarePlanSchedulesDialogCreate";

import { StaffData } from "@stores/domain/mgr/KYOTAKUKAIGO/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import {
  schedulesValue,
  initialValuesSchedules,
  initialValuesSchedulesData
} from "@initialize/record/carePlan/initialValues";
import {
  RecordCarePlan,
  SupportCarePlanBasicInfo,
  SupportCarePlanSchedules
} from "@interfaces/record/carePlan/carePlan";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";
import { FacilityType } from "@constants/variables";
import { SUPPORT_CARE_PLAN_SCHEDULES_STATUS as SUPPORT_CARE_PLAN_SCHEDULES_STATUS_KYOTAKUKAIGO } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { schedulesValidation } from "@initialize/record/carePlan/validation";
import { toEffectiveObject } from "@utils/object";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    gray: {
      color: "rgba(0, 0, 0, 0.20)"
    },
    black: {
      color: "#000"
    },
    bule: {
      color: "rgba(6, 166, 233, 0.87)"
    },
    red: {
      color: "#ff5656"
    },
    bgGray: {
      background: "#f5f5f5"
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    },
    buttonAdd: {
      marginTop: "16px"
    },
    buttonAddCon: {
      alignItems: "center",
      "&>span>span": {
        lineHeight: 1,
        minHeight: "auto",
        display: "block",
        marginLeft: "13px"
      }
    },
    header: {
      display: "flex",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      background: "#eceff1",
      color: "#37474f",
      padding: "14px 16px",
      width: "1030px",
      "& span": {
        display: "block"
      }
    },
    headerCol: {
      display: "flex",
      padding: "0 16px",
      marginLeft: "24px"
    },
    schedulesItem: {
      padding: "0 16px",
      width: "1030px",
      minHeight: "57px",
      display: "flex",
      alignItems: "center",
      fontSize: "16px",
      lineHeight: 1.3,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: "1px dotted #757575",
      "&:last-child": {
        borderBottom: "1px solid #e0e0e0"
      }
    },
    schedulesItemCol: {
      marginLeft: "24px"
    },
    schedulesItemList: {
      display: "flex",
      alignItems: "center",
      padding: "14px 16px",
      borderTop: "1px dotted #757575",
      "&:first-child": {
        borderTop: "none"
      }
    },
    schedulesItemListWeek: {
      display: "flex",
      alignItems: "center",
      "&>span": {
        width: "24px",
        minHeight: "28px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
      },
      "&>span:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    index: {
      width: "96px",
      minWidth: "96px"
    },
    status: {
      width: "168px",
      minWidth: "168px",
      marginLeft: "16px",
      whiteSpace: "pre-line",
      wordBreak: "break-all"
    },
    week: {
      width: "calc(24px * 7 + 8px * 6)"
    },
    start: {
      width: "70px",
      marginLeft: "32px"
    },
    end: {
      width: "70px",
      marginLeft: "16px"
    },
    time: {
      width: "102px",
      marginLeft: "16px"
    },
    number: {
      width: "28px",
      marginLeft: "16px"
    },
    delete: {
      width: "27px",
      marginLeft: "22px",
      textAlign: "center",
      "&>svg": {
        verticalAlign: "middle"
      }
    },
    edit: {
      width: "27px",
      marginLeft: "20px",
      textAlign: "center",
      "&>svg": {
        verticalAlign: "middle"
      }
    },
    icon: {
      color: "#0277bd",
      cursor: "pointer"
    },
    noMessage: {
      padding: spacing.unit * 2,
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      letterSpacing: "0.4px",
      borderBottom: "solid  1px #e0e0e0",
      margin: 0,
      maxWidth: "1035px"
    }
  });
type OwnProps = {
  isNew: boolean;
  paramsId: string;
  isEditing: boolean;
  schedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  formikProps: FormikProps<RecordCarePlan>;
  withOutAddBtn?: boolean;
  paramsCarePlanId: string;
} & WithStyles<typeof styles>;
type StateProps = {
  staffItems: StaffData[];
  facilityType: FacilityType;
  needsStopHistory: boolean;
};
type DispatchProps = {
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
  updateSchedules: (id: string, carePlanId: string) => Promise<void>;
  fetchStaffs: (facilityType: FacilityType) => void;
  postSupportPlanUifIdCarePlanCarePlanIdDetails: (
    uifId: string,
    carePlanId: string,
    values: SupportCarePlanSchedules[],
    isNew: boolean
  ) => Promise<void>;
  deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId: (
    uifId: string,
    carePlanScheduleId: string
  ) => Promise<void>;
};
type MergeProps = {};
type Props = OwnProps & StateProps & DispatchProps & MergeProps;

const CarePlanSchedulesCore = (props: Props): JSX.Element => {
  const {
    classes,
    isNew,
    paramsId,
    isEditing,
    schedules,
    staffItems,
    formikProps,
    facilityType,
    withOutAddBtn,
    paramsCarePlanId,
    needsStopHistory,
    fetchStaffs,
    stopHistory,
    showSnackbar,
    updateSchedules,
    postSupportPlanUifIdCarePlanCarePlanIdDetails,
    deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId
  } = props;

  // グレー背景用
  let bgCount = 0;

  // サービス提供日用
  const week = [
    { label: "月", class: classes.black },
    { label: "火", class: classes.black },
    { label: "水", class: classes.black },
    { label: "木", class: classes.black },
    { label: "金", class: classes.black },
    { label: "土", class: classes.bule },
    { label: "日", class: classes.red }
  ];

  // データがない時用メッセージ
  const noMessage = !withOutAddBtn
    ? "サービスが登録されていません。「サービスを追加」ボタンから登録してください。"
    : "サービスが登録されていません";

  const ridesValue = (value: string): string => {
    return value !== "" ? `${value}回` : "-";
  };
  const numberOfTimeValue = (value: string): string => {
    return value !== "" ? value : "-";
  };

  // 追加上限
  const maxLength = 50;
  const [isMaxLength, setIsMaxLength] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (isNew) {
      setIsMaxLength(
        formikProps.values.support_care_plan_schedules.length >= maxLength
      );
    } else {
      setIsMaxLength(schedules.length >= maxLength);
    }
  }, [formikProps.values.support_care_plan_schedules.length, schedules.length]);

  // 更新されたたびに表示用
  const [schedulesData, setSchedulesData] = React.useState(
    formikProps.values.support_care_plan_schedules
  );
  React.useEffect(() => {
    if (isNew) {
      setSchedulesData(formikProps.values.support_care_plan_schedules);
    } else {
      setSchedulesData(initialValuesSchedulesData(schedules));
    }
  }, [formikProps.values.support_care_plan_schedules, schedules]);

  // 新規の時用データ足す・減らす・長さ取得
  const [schedulesLength, setSchedulesLength] = React.useState<number>(0);
  const [dataBefore, setDataBefore] = React.useState<SupportCarePlanSchedules>({
    support_care_plan_schedule_id: 0,
    status: "",
    status_text: "",
    support_care_plan_basic_info: [],
    support_care_plan_details: []
  });
  const addDataOnCreating = (): void => {
    formikProps.values.support_care_plan_schedules.push(
      schedulesValue(
        formikProps.values.support_care_plan_schedules.length,
        facilityType
      )
    );
    setSchedulesLength(
      formikProps.values.support_care_plan_schedules.length - 1
    );
  };
  const deleteDataOnCreating = (th: number): void => {
    formikProps.values.support_care_plan_schedules.splice(th, 1);
  };
  const resetDataOnCreating = (th: number): void => {
    formikProps.values.support_care_plan_schedules.splice(th, 1, dataBefore);
  };
  const deleteItemOnCreating = (th: number): void => {
    formikProps.values.support_care_plan_schedules[
      th
    ].support_care_plan_basic_info = formikProps.values.support_care_plan_schedules[
      th
    ].support_care_plan_basic_info.filter((f) => f.is_delete !== true);

    formikProps.values.support_care_plan_schedules[
      th
    ].support_care_plan_details = formikProps.values.support_care_plan_schedules[
      th
    ].support_care_plan_details.filter((f) => f.is_delete !== true);
  };

  // サービスNo.
  const [serviceNumberEdit, setServiceNumberEdit] = React.useState<number>(0);
  const [serviceNumberCreate, setServiceNumberCreate] = React.useState<number>(
    0
  );

  // 編集の時initializeする
  const [editInitialValues, setEditInitialValues] = React.useState<
    SupportCarePlanSchedules
  >(initialValuesSchedules(schedules, 0));

  // 編集の時モーダル開く・閉じる
  const [isOpenModelOnEditing, setIsOpenModelOnEditing] = React.useState<
    boolean
  >(false);
  const openModalOnEditing = (id: number): void => {
    setIsOpenModelOnEditing(true);
    setEditInitialValues(initialValuesSchedules(schedules, id));
  };
  const closeModalOnEditing = (): void => {
    setIsOpenModelOnEditing(false);
  };

  // 新規の時モーダル開く・閉じる
  const [isOpenModelOnCreating, setIsOpenModelOnCreating] = React.useState<
    boolean
  >(false);
  const [isEditModeOnCreating, setIsEditModeOnCreating] = React.useState<
    boolean
  >(false);
  const openModalOnCreating = (): void => {
    setIsOpenModelOnCreating(true);
  };
  const closeModalOnCreating = (): void => {
    setIsOpenModelOnCreating(false);
  };

  // バリデーション関連
  const validate = (values: SupportCarePlanSchedules): void | object => {
    const validationResult = schedulesValidation(values, facilityType);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      stopHistory(true);
    }
    return error;
  };
  const submitError = (): void => {
    showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // クリック処理
  const onClickAddButton = (): void => {
    setServiceNumberCreate(
      isNew
        ? formikProps.values.support_care_plan_schedules.length
        : schedules.length
    );
    openModalOnCreating();
    addDataOnCreating();
  };
  const onClickEditButton = (id: number): void => {
    if (isNew) {
      setDataBefore(formikProps.values.support_care_plan_schedules[id]);
      setServiceNumberCreate(id);
      openModalOnCreating();
      setIsEditModeOnCreating(true);
      setSchedulesLength(id);
    } else {
      setServiceNumberEdit(id);
      openModalOnEditing(id);
    }
  };
  const onClickCancelButtonOnCreating = (th: number): void => {
    if (isEditModeOnCreating) {
      resetDataOnCreating(th);
    } else {
      deleteDataOnCreating(th);
    }
    closeModalOnCreating();
    setIsEditModeOnCreating(false);
  };
  const onClickSaveButtonOnCreating = (th: number): void => {
    deleteItemOnCreating(th);
    if (!isNew) {
      const validateRes = validate(
        formikProps.values.support_care_plan_schedules[th]
      );
      if (validateRes) {
        submitError();
      } else {
        postSupportPlanUifIdCarePlanCarePlanIdDetails(
          paramsId,
          paramsCarePlanId,
          [formikProps.values.support_care_plan_schedules[th]],
          true
        ).then(() => updateSchedules(paramsId, paramsCarePlanId));
        closeModalOnCreating();
      }
    } else {
      closeModalOnCreating();
    }
  };
  const onClickCancelButtonOnEditing = (): void => {
    closeModalOnEditing();
  };
  const onClickSaveButtonOnEditing = (
    data: SupportCarePlanSchedules[]
  ): void => {
    postSupportPlanUifIdCarePlanCarePlanIdDetails(
      paramsId,
      paramsCarePlanId,
      data,
      false
    ).then(() => updateSchedules(paramsId, paramsCarePlanId));
    closeModalOnEditing();
  };

  // 削除モーダル
  const [isShowDeleteDialog, setIsShowDeleteDialog] = React.useState<boolean>(
    false
  );
  const [deleteIndex, setDeleteIndex] = React.useState<number>(0);
  const onOpenDeleteDialog = (th: number): void => {
    setIsShowDeleteDialog(true);
    setDeleteIndex(th);
  };
  const onCloseDeleteDialog = (): void => {
    setIsShowDeleteDialog(false);
  };
  const onScheduleDelete = (): void => {
    if (isNew) {
      deleteDataOnCreating(deleteIndex);
      setIsShowDeleteDialog(false);
    } else {
      deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId(
        paramsId,
        `${deleteIndex}`
      ).then(() => updateSchedules(paramsId, paramsCarePlanId));
      setIsShowDeleteDialog(false);
    }
  };

  React.useEffect(() => {
    fetchStaffs(facilityType);
  }, []);

  // 種別による分岐・サービス内容
  const getSchedulesStatus = (value: SupportCarePlanSchedules): string => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return getLabelFromOptions(
          `${value.status}`,
          SUPPORT_CARE_PLAN_SCHEDULES_STATUS_KYOTAKUKAIGO
        );
      case FacilityType.JUDOHOMONKAIGO:
      case FacilityType.DOKOENGO:
        return value.status_text !== "" ? value.status_text : "-";
      default:
        return value.status_text !== "" ? value.status_text : "-";
    }
  };

  // 種別による分岐・テーブルヘッダーラベル・時間数
  const getTableLabelTime = (): string => {
    const defaultLabel = "時間数";
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return "時間 / 乗降回数";
      case FacilityType.JUDOHOMONKAIGO:
      case FacilityType.DOKOENGO:
        return defaultLabel;
      default:
        return defaultLabel;
    }
  };

  const baseInfo = (value: SupportCarePlanSchedules): JSX.Element => {
    return (
      <>
        {value.support_care_plan_basic_info.map(
          (i: SupportCarePlanBasicInfo, infoIndex: number) => {
            const uniqueKeyInfo = `info_${infoIndex}`;
            bgCount += 1;

            return (
              <div
                key={uniqueKeyInfo}
                className={ClassNames(
                  classes.schedulesItemList,
                  bgCount % 2 === 0 ? classes.bgGray : ""
                )}
              >
                <div className={classes.schedulesItemListWeek}>
                  {week.map((w, idx) => {
                    const uniqueKeyWeek = `week_${idx}`;
                    return (
                      <span
                        key={uniqueKeyWeek}
                        className={
                          i.day_of_week &&
                          i.day_of_week.find((f) => f === idx + 1)
                            ? w.class
                            : classes.gray
                        }
                      >
                        {w.label}
                      </span>
                    );
                  })}
                </div>
                <span className={classes.start}>
                  {i.start_time === "" ? "-" : i.start_time}
                </span>
                <span className={classes.end}>
                  {i.end_time === "" ? "-" : i.end_time}
                </span>
                <span className={classes.time}>
                  {value.status === "6"
                    ? ridesValue(i.number_of_rides)
                    : numberOfTimeValue(i.number_of_time)}
                </span>
                <span className={classes.number}>
                  {i.number_of_participants === ""
                    ? "-"
                    : i.number_of_participants}
                </span>
              </div>
            );
          }
        )}
      </>
    );
  };
  const noBaseInfo = (): JSX.Element => {
    bgCount += 1;
    return (
      <div
        className={ClassNames(
          classes.schedulesItemList,
          bgCount % 2 === 0 ? classes.bgGray : ""
        )}
      >
        <div className={classes.schedulesItemListWeek}>
          {week.map((w, idx) => {
            const uniqueKeyWeek = `week_${idx}`;
            return (
              <span key={uniqueKeyWeek} className={classes.gray}>
                {w.label}
              </span>
            );
          })}
        </div>
        <span className={classes.start}>-</span>
        <span className={classes.end}>-</span>
        <span className={classes.time}>-</span>
        <span className={classes.number}>-</span>
      </div>
    );
  };

  return (
    <>
      {/* 計画予定表 */}
      <div className={classes.header}>
        <span className={classes.index}>サービスNo.</span>
        <span className={classes.status}>サービス内容</span>
        <div className={classes.headerCol}>
          <span className={classes.week}>サービス提供日</span>
          <span className={classes.start}>開始時間</span>
          <span className={classes.end}>終了時間</span>
          <span className={classes.time}>{getTableLabelTime()}</span>
          <span className={classes.number}>人数</span>
        </div>
        <span className={classes.delete}>{isEditing ? "削除" : ""}</span>
        <span className={classes.edit}>{isEditing ? "編集" : "詳細"}</span>
      </div>
      {schedulesData.length === 0 ? (
        <p className={classes.noMessage}>{noMessage}</p>
      ) : (
        schedulesData.map((value, index) => {
          const uniqueKey = `item_${index}`;
          return (
            <div key={uniqueKey} className={classes.schedulesItem}>
              <span className={classes.index}>
                サービス{circleNumbersList[index]}
              </span>
              <span className={classes.status}>
                {getSchedulesStatus(value)}
              </span>
              <div className={classes.schedulesItemCol}>
                {value.support_care_plan_basic_info.length === 0
                  ? noBaseInfo()
                  : baseInfo(value)}
              </div>
              <div className={classes.delete}>
                {isEditing && (
                  <DeleteOutlineIcon
                    className={classes.icon}
                    onClick={(): void =>
                      onOpenDeleteDialog(
                        isNew ? index : value.support_care_plan_schedule_id
                      )
                    }
                  />
                )}
              </div>
              <div className={classes.edit}>
                <EditIcon
                  className={classes.icon}
                  onClick={(): void => onClickEditButton(index)}
                />
              </div>
            </div>
          );
        })
      )}
      {/* 新規追加ボタン */}
      {!withOutAddBtn && (
        <div className={classes.buttonAdd}>
          <KnowbeButton
            className={classes.buttonAddCon}
            kind="iconText"
            onClick={onClickAddButton}
            disabled={isMaxLength}
          >
            <AddIcon />
            <span>サービスを追加</span>
          </KnowbeButton>
        </div>
      )}
      {/* 新規追加用モーダル */}
      <CarePlanSchedulesDialogCreate
        staffItems={staffItems}
        formikProps={formikProps}
        facilityType={facilityType}
        schedulesLength={schedulesLength}
        serviceNumberCreate={serviceNumberCreate}
        isOpenModelOnCreating={isOpenModelOnCreating}
        onClickSaveButtonOnCreating={onClickSaveButtonOnCreating}
        onClickCancelButtonOnCreating={onClickCancelButtonOnCreating}
      />
      {/* 編集・閲覧の時用モーダル */}
      <CarePlanSchedulesDialog
        validate={validate}
        isEditing={isEditing}
        staffItems={staffItems}
        submitError={submitError}
        stopHistory={stopHistory}
        showSnackbar={showSnackbar}
        facilityType={facilityType}
        needsStopHistory={needsStopHistory}
        editInitialValues={editInitialValues}
        serviceNumberEdit={serviceNumberEdit}
        isOpenModelOnEditing={isOpenModelOnEditing}
        onClickSaveButtonOnEditing={onClickSaveButtonOnEditing}
        onClickCancelButtonOnEditing={onClickCancelButtonOnEditing}
      />
      {/* サービス削除の時用モーダル */}
      <MessageDialog
        isOpen={isShowDeleteDialog}
        title="該当するサービスを削除します"
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            onClick={onCloseDeleteDialog}
            className={classes.deleteCancelButton}
            color="secondary"
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button className={classes.deleteButton} onClick={onScheduleDelete}>
            削除する
          </Button>
        }
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    staffItems: state[facilityType].staff.staffItems,
    needsStopHistory: state.ui.needsStopHistory,
    facilityType
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, carePlanDispatcher } = dispatches;

  return {
    stopHistory: uiDispatch(dispatch).stopHistory,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    updateSchedules: carePlanDispatcher(dispatch).updateSchedules,
    fetchStaffs: async (facilityType: FacilityType): Promise<void> => {
      await dispatches[facilityType].staffDispatcher(dispatch).fetch;
    },
    postSupportPlanUifIdCarePlanCarePlanIdDetails: async (
      uifId: string,
      carePlanId: string,
      values: SupportCarePlanSchedules[],
      isNew: boolean
    ): Promise<void> => {
      await carePlanDispatcher(
        dispatch
      ).postSupportPlanUifIdCarePlanCarePlanIdDetails(
        uifId,
        carePlanId,
        values,
        isNew
      );
    },
    deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId: async (
      uifId: string,
      carePlanScheduleId: string
    ): Promise<void> => {
      await carePlanDispatcher(
        dispatch
      ).deleteSupportPlanUifIdCarePlanScheduleCarePlanScheduleId(
        uifId,
        carePlanScheduleId
      );
    }
  };
};

export const CarePlanSchedules = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(CarePlanSchedulesCore)
);
