import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UsageResultList } from "@components/organisms/mgr/SHUROTEICHAKU/report/UsageResultList";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { INVOICE_PATHS } from "@constants/mgr/SHUROTEICHAKU/variables";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FacilityType, INVOICE_VERSION_CURRENT } from "@constants/variables";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";

const styles = (): StyleRules =>
  createStyles({
    indicator: {
      display: "none"
    }
  });

type Props = RouteComponentProps<{}, {}, { targetDate: Date }> &
  WithStyles<typeof styles>;

/**
 * 利用実績
 */
const ReportCore = (props: Props): JSX.Element | null => {
  const currentDate = props.location.state
    ? props.location.state.targetDate
    : new Date();
  // 報酬改定のバージョン変更が必要だったら変更後のページを表示
  const currentPageVersion = INVOICE_VERSION_CURRENT;
  const transitionDone = checkReportVersion(
    currentDate,
    FacilityType.SHUROTEICHAKU,
    currentPageVersion,
    INVOICE_PATHS.reportUsers,
    props.history
  );

  if (transitionDone) {
    return null;
  }
  return (
    <AdminTemplate pageName="利用実績" zIndex>
      <UsageResultList
        initialDate={currentDate}
        history={props.history}
        currentPageVersion={currentPageVersion}
        // propsをstateに入れた時の不具合予防にkeyを入れる
        key={currentDate.getTime()}
      />
      <ErrorsDialog errorsKey="inout" />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

export const Report = withStyles(styles)(ReportCore);
