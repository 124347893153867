import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import Uplimit from "@components/organisms/download/print/Uplimit";

type OwnProps = {
  year: string;
  month: string;
  idList: string;
  typeConsultation?: string;
};

type DispatchProps = {
  fetchUplimit: (
    year: string,
    month: string,
    excludeUserIds: number[],
    typeConsultation?: string
  ) => void;
};

type Props = OwnProps & DispatchProps & AppState;

/**
 * 利用者負担上限額管理結果票のプリント画面
 */
class UplimitPrint extends React.Component<Props> {
  public componentWillMount(): void {
    const { year, month, idList, typeConsultation } = this.props;
    const excludedUserIds: number[] = idList
      ? idList.split(",").map((str) => parseInt(str, 10))
      : [];

    this.props.fetchUplimit(year, month, excludedUserIds, typeConsultation);
  }

  public render(): JSX.Element {
    const { invoiceUplimitData } = this.props.invoice;
    return (
      <PreviewTemplate>
        <Uplimit uplimitResults={invoiceUplimitData} />
      </PreviewTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): AppState => {
  return { ...state };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { invoiceDispatch } = dispatches;
  const invoiceDispatched = invoiceDispatch(dispatch);

  return {
    fetchUplimit: (
      year: string,
      month: string,
      excludeUserIds: number[],
      typeConsultation?: string
    ): void => {
      invoiceDispatched.downloadUplimitJson(
        year,
        month,
        excludeUserIds,
        typeConsultation
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UplimitPrint);
