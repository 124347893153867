import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { assistanceReportApi } from "@api/requests/assistanceReport";
import { AssistanceReportValues } from "@initialize/mgr/CHIIKIIKO/record/assistanceReport/initialValues";
import {
  normalizeFormValuesToPostAssistanceReport,
  normalizeValueFromAPI,
  normalizeCopyValueFromAPI
} from "./normalizer";
import { consultationApi } from "@api/requests/consultation";
import { CHIIKIIKOGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";

export const fetchAssistanceReport = (dispatch: Dispatch) => async (
  uifId: string,
  assistanceReportsId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchAssistanceReportStarted());
  await assistanceReportApi
    .getAssistanceReport(uifId, assistanceReportsId)
    .then((res) => {
      const normalizedValue = normalizeValueFromAPI(res.data.data);
      dispatch(actions.fetchAssistanceReportSuccess(normalizedValue));
    })
    .catch((e) => {
      dispatch(actions.fetchAssistanceReportFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 作成済み計画読み込み
const copyConsultation = (dispatch: Dispatch) => async (
  uifId: string,
  consultationId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.copyConsultationStarted());
  await consultationApi
    .getConsultation<GetConsultationResponse>(uifId, consultationId)
    .then((res) => {
      const normalizedValue = normalizeCopyValueFromAPI(res.data.data);
      dispatch(actions.copyConsultationSuccess(normalizedValue));
    })
    .catch((e) => {
      dispatch(actions.copyConsultationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 地域移行支援報告書の作成・更新
 */
const postAssistanceReport = (dispatch: Dispatch) => async (
  uifId: number,
  values: AssistanceReportValues,
  initialValues?: AssistanceReportValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postAssistanceReportStarted());
  const normalizedParams = normalizeFormValuesToPostAssistanceReport(
    values,
    initialValues
  );
  await assistanceReportApi
    .postAssistanceReport(String(uifId), normalizedParams)
    .then(() => {
      dispatch(actions.postAssistanceReportSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.postAssistanceReportFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const deleteAssistanceReport = (dispatch: Dispatch) => async (
  uifId: string,
  id: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteAssistanceReportStarted());
  await assistanceReportApi
    .deleteAssistanceReport(uifId, id)
    .then(() => {
      dispatch(actions.deleteAssistanceReportSuccess());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.deleteAssistanceReportFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchAssistanceReport: (
    uifId: string,
    assistanceReportsId: string
  ) => Promise<void>;
  copyConsultation: ReturnType<typeof copyConsultation>;
  postAssistanceReport: ReturnType<typeof postAssistanceReport>;
  deleteAssistanceReport: ReturnType<typeof deleteAssistanceReport>;
};

export const CHIIKIIKOAssistanceReportDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchAssistanceReport: fetchAssistanceReport(dispatch),
  copyConsultation: copyConsultation(dispatch),
  postAssistanceReport: postAssistanceReport(dispatch),
  deleteAssistanceReport: deleteAssistanceReport(dispatch)
});
