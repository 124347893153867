import { Dispatch } from "redux";
import * as actions from "./actions";

/**
 *  施設外就労実施報告書の印刷対象の設定
 */
export const setOffsiteWorkMonth = (dispatch: Dispatch) => async (
  date: string
): Promise<{
  readonly date: string;
  readonly type: "PAGES/OFFSITE_WORK/OFFICE_WORK_MONTH/SET_DATE";
}> => dispatch(actions.setOffsiteWorkMonth(date));

type Dispatcher = {
  setOffsiteWorkMonth: (
    date: string
  ) => Promise<{
    readonly date: string;
    readonly type: "PAGES/OFFSITE_WORK/OFFICE_WORK_MONTH/SET_DATE";
  }>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  setOffsiteWorkMonth: setOffsiteWorkMonth(dispatch)
});
