import { GetOperationsAndSupports } from "@api/requests/operations/GroupHome/getOperationsAndSupports";
/**
 * ActionNames
 */
export const FETCH_DAILY_OPERATIONS_AND_SUPPORTS_STARTED =
  "SUPPORTS/GROUP_HOME/FETCH_DAILY_OPERATIONS_AND_SUPPORTS_STARTED";
export const FETCH_DAILY_OPERATIONS_AND_SUPPORTS_SUCCESS =
  "SUPPORTS/GROUP_HOME/FETCH_DAILY_OPERATIONS_AND_SUPPORTS_SUCCESS";
export const FETCH_DAILY_OPERATIONS_AND_SUPPORTS_FAILED =
  "SUPPORTS/GROUP_HOME/FETCH_DAILY_OPERATIONS_AND_SUPPORTS_FAILED";
export const UNSET_DAILY_OPERATIONS_AND_SUPPORTS =
  "SUPPORTS/GROUP_HOME/UNSET_DAILY_OPERATIONS_AND_SUPPORTS";

/**
 * State
 */
export type DailyOperationsAndSupportsState = GetOperationsAndSupports["data"];
