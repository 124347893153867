import {
  CustomRecordsValues,
  CustomRecordsValuesForService,
  CustomRecordItemValues
} from "@initialize/record/customRecordWithCategory/initialValues";
import { RequestParam as CustomRecordsRequestParam } from "@api/requests/customRecords/postCustomRecords";
import { RequestParam as CustomRecordsCategoryRequestParam } from "@api/requests/customRecords/postCustomRecordsCategory";
import { RequestParam as OrderRequestParam } from "@api/requests/customRecords/postCustomRecordsOrder";
import { RequestParam as CategoryOrderRequestParam } from "@api/requests/customRecords/postCustomRecordsCategoryOrder";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";

const removeUndefinedParam = <T extends {}>(
  data: T,
  requiredKey?: string[]
): T => {
  const targetParam = {} as T;

  // 必須キーを除くundefinedのparamを除去
  Object.keys(data).forEach((param) => {
    if (
      (requiredKey && requiredKey.includes(param)) ||
      data[param] !== undefined
    ) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

export const normalizeCustomRecordsDataToAPI = (
  state: CustomRecordsValues
): CustomRecordsRequestParam => {
  let params = {
    id: state.id,
    name: state.name,
    input_type: +state.input_type,
    setting_type: state.setting_type,
    custom_record_item_choices: state.custom_record_item_choices
      ? state.custom_record_item_choices.map((x) => ({
          id: null,
          custom_record_item_id: null,
          name: x.name
        }))
      : [],
    custom_records_category_id: state.custom_records_category_id
  };
  if (state && state.custom_records_category_id) {
    params = {
      ...params,
      custom_records_category_id: state.custom_records_category_id
    };
  }
  return params;
};

export const normalizeCustomRecordsDataToAPIForService = (
  state: CustomRecordsValuesForService
): CustomRecordsRequestParam => {
  // 空の選択肢、非表示の選択肢、編集中に新規追加→削除した選択肢情報は省く
  const customRecordsItemChoices = state.custom_record_item_choices
    .filter((choice) => !!choice.name && choice.hidden !== 1 && !choice.delete)
    .map((choice) => {
      return {
        id: choice.id,
        custom_record_item_id: choice.custom_record_item_id,
        name: choice.name
      };
    });
  return {
    id: state.id,
    name: state.name,
    input_type: +state.input_type,
    setting_type: state.setting_type,
    custom_records_category_id: state.custom_records_category_id,
    custom_record_item_choices: customRecordsItemChoices
  };
};

export const normalizeCustomRecordsCategoryDataToAPI = (
  state: CustomRecordsValues
): CustomRecordsCategoryRequestParam => {
  const category_type = state && state.category_type ? state.category_type : 0;
  return {
    id: state.id,
    setting_type: state.setting_type,
    category_type,
    name: state.name
  };
};

// カテゴリー並べ替え用データ整形（並び順固定項目は省く）
// 非表示項目も省く必要があるが、関数実行前に既に省かれている想定
export const normalizeCustomRecordsCategoryOrderDataToAPI = (
  visibleCustomRecords: CustomRecordsWithCategoryState
): CategoryOrderRequestParam => {
  const normalizedCustomRecordsTableBodyValues = visibleCustomRecords.map(
    (record, index) => {
      return {
        id: record.id,
        name: record.name,
        order: index + 1
      };
    }
  );

  return {
    custom_records_category_order: normalizedCustomRecordsTableBodyValues
  };
};

// 項目並べ替え用データ整形（並び順固定項目は省く）
// 非表示項目も省く必要があるが、関数実行前に既に省かれている想定
export const normalizeCustomRecordsOrderDataToAPI = (
  visibleCustomRecords: CustomRecordsWithCategoryState,
  customRecordCategoryId: number
): OrderRequestParam => {
  const target = visibleCustomRecords.find(
    (record) => record.id === customRecordCategoryId
  );
  const normalizedCustomRecordsTableBodyValues = target
    ? target.custom_record_items.map((item, index) => {
        return {
          id: item.id,
          setting_type: item.setting_type,
          default_item: item.default_item,
          name: item.name,
          order: index + 1,
          custom_records_category_id: item.custom_records_category_id
        };
      })
    : [];

  return {
    custom_record_items_order: normalizedCustomRecordsTableBodyValues
  };
};

// 項目並べ替え用データ整形(サービス提供記録用)
export const normalizeCustomRecordsOrderDataToAPIForService = (
  visibleCustomRecords: CustomRecordItemValues,
  customRecordCategoryId: number
): OrderRequestParam => {
  const normalizedCustomRecordsTableBodyValues = visibleCustomRecords
    .filter((item) => {
      return item.order !== null;
    })
    .map((item, index) => {
      return {
        id: item.id,
        setting_type: item.setting_type,
        default_item: item.default_item,
        name: item.name,
        order: index + 1,
        custom_records_category_id: customRecordCategoryId
      };
    });
  return {
    custom_record_items_order: normalizedCustomRecordsTableBodyValues
  };
};

export const normalizeHideCustomRecords = (
  recordId: number,
  type: "item" | "category"
): postCustomRecordsVisibilityParams => {
  if (type === "item") {
    return {
      custom_record_items: [
        {
          id: recordId,
          visibility: 0
        }
      ]
    };
  }
  return {
    custom_records_category: [
      {
        id: recordId,
        visibility: 0
      }
    ]
  };
};

export const normalizeShowCustomRecords = (
  visibleRecordIds: {
    item: number[];
    category: number[];
  },
  invisibleRecordIds: {
    item: number[];
    category: number[];
  }
): postCustomRecordsVisibilityParams => {
  const visibles = visibleRecordIds.item.map((i) => {
    return {
      id: i,
      visibility: 1
    };
  });
  const invisibles = invisibleRecordIds.item.map((i) => {
    return {
      id: i,
      visibility: 0
    };
  });
  const custom_record_items = [...visibles, ...invisibles];

  const visibles_category = visibleRecordIds.category.map((c) => {
    return {
      id: c,
      visibility: 1
    };
  });

  const invisibles_category = invisibleRecordIds.category.map((c) => {
    return {
      id: c,
      visibility: 0
    };
  });

  const custom_records_category = [
    ...visibles_category,
    ...invisibles_category
  ];

  let params: postCustomRecordsVisibilityParams = custom_record_items.length
    ? { custom_record_items }
    : {};
  params = custom_records_category.length
    ? { ...params, custom_records_category }
    : params;
  return params;
};

export const normalizeChangeVisibilityCustomRecord = (
  customRecordItems: postCustomRecordsVisibilityParams["custom_record_items"],
  customRecordsCategory: postCustomRecordsVisibilityParams["custom_records_category"]
): postCustomRecordsVisibilityParams => {
  // 必須キー以外のundefinedのparamを除去
  let custom_record_items: postCustomRecordsVisibilityParams["custom_record_items"];
  if (customRecordItems) {
    custom_record_items = customRecordItems.map((item) => {
      if (item.custom_records_keikakusodan_visibility) {
        return {
          id: item.id,
          visibility: item.visibility,
          custom_records_keikakusodan_visibility: removeUndefinedParam(
            item.custom_records_keikakusodan_visibility,
            ["id"]
          )
        };
      }
      return item;
    });
  }

  let custom_records_category: postCustomRecordsVisibilityParams["custom_records_category"];
  if (customRecordsCategory) {
    custom_records_category = customRecordsCategory.map((category) => {
      if (category.custom_records_keikakusodan_visibility) {
        return {
          id: category.id,
          visibility: category.visibility,
          custom_records_keikakusodan_visibility: removeUndefinedParam(
            category.custom_records_keikakusodan_visibility,
            ["id"]
          )
        };
      }
      return category;
    });
  }

  return removeUndefinedParam({
    custom_record_items: custom_record_items
      ? custom_record_items.map((v) => removeUndefinedParam(v, ["id"]))
      : undefined,
    custom_records_category: custom_records_category
      ? custom_records_category.map((v) => removeUndefinedParam(v, ["id"]))
      : undefined
  });
};
