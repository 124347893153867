import * as actions from "./actions";
import * as types from "./types";

const initialState: types.SupportPlanSheetsState = {
  supportPlanSheets: [],
  supportPlanSheetsSupportPlanSheetId: {
    id: 0,
    created_at: null,
    updated_at: null,
    author: null,
    creation_date: "",
    intake: null,
    assessment_biological: null,
    assessment_psychological: null,
    assessment_social: null,
    support_plan_sheet_details: []
  },
  isEditing: false
};

export const supportPlanSheets = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportPlanSheetsState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUCCESS:
      return { ...state, supportPlanSheets: action.payload };
    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_FAILED:
      return { ...state };

    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS:
      return { ...state, supportPlanSheetsSupportPlanSheetId: action.payload };
    case types.FETCH_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED:
      return { ...state };

    case types.SET_EDIT:
      return { ...state, isEditing: true };
    case types.UNSET_EDIT:
      return { ...state, isEditing: false };

    case types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_STARTED:
      return { ...state };
    case types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_NEW_FAILED:
      return { ...state };

    case types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_STARTED:
      return { ...state };
    case types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_SUCCESS: {
      const supportPlanSheet = state.supportPlanSheets.filter(
        (i) => `${i.id}` !== action.supportPlanSheetId
      );
      return { ...state, supportPlanSheets: supportPlanSheet };
    }
    case types.DELETE_SUPPORT_PLAN_SUPPORT_PLAN_SHEETS_SUPPORT_PLAN_SHEET_ID_FAILED:
      return { ...state };

    default:
      return state;
  }
};
