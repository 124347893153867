import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import { SERVICE_DELIVERY_PRINT_TITLE_OPTIONS } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      "& > div > div": {
        marginLeft: 0,
        marginBottom: 0
      }
    }
  });

type OwnProps = {
  value: string;
  changeFormName: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PrintFormNameServiceDeliveryCore = (props: Props): JSX.Element => {
  return (
    <MuiRadioButtons
      label=""
      options={SERVICE_DELIVERY_PRINT_TITLE_OPTIONS}
      value={props.value}
      onChange={(e): void =>
        props.changeFormName(e as React.ChangeEvent<HTMLInputElement>)
      }
    />
  );
};

export const PrintFormNameServiceDelivery = withStyles(styles)(
  PrintFormNameServiceDeliveryCore
);
