import * as types from "./types";
import * as actions from "./actions";
import { Checkbox } from "@constants/variables";

// first or reset values
const userDefaultValues: types.UsersInFacilityState["user"] = {
  user_in_facility: {
    none_recipient_number_flg: Checkbox.OFF,
    gender: "1"
  },
  user_in_facility_idoshien: {
    users_in_facility_id: 0,
    municipality_id: 0,
    disability_class: 0,
    pay_times_individual_support: 0,
    pay_months_individual_support: 0,
    pay_times_group_support: 0,
    pay_months_group_support: 0,
    pay_times_vehicle_transfer_support: 0,
    pay_months_vehicle_transfer_support: 0,
    pay_times_school_and_hospital_commuting_support: 0,
    pay_months_school_and_hospital_commuting_support: 0,
    pay_times_other_support: 0,
    pay_months_other_support: 0,
    user_charge_rate: 0,
    monthly_user_charge_limit: 0
  }
};

const initialState: types.UsersInFacilityState = {
  users: [],
  usersList: [],
  user: userDefaultValues,
  doneUpsertUser: false,
  userValidation: {}
};

export const IDOSHIENUserInFacility = (
  state = initialState,
  action: actions.ActionTypes
): types.UsersInFacilityState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS:
      return { ...state, users: action.payload.data };
    case types.FETCH_FAILED:
      return { ...state };
    case types.FETCH_TARGET_DAY_STARTED:
      return { ...state };
    case types.FETCH_TARGET_DAY_SUCCESS:
      return { ...state, usersList: action.payload.data };
    case types.FETCH_TARGET_DAY_FAILED:
      return { ...state };
    case types.FETCH_ONE_STARTED:
      return { ...state };
    case types.FETCH_ONE_SUCCESS:
      return { ...state, user: action.payload };
    case types.FETCH_ONE_FAILED:
      return { ...state };
    case types.CLEAR:
      return { ...state, user: userDefaultValues };
    default:
      return state;
  }
};
