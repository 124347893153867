import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InOutReportUserHeader } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportMonthlyHeader";
import { InOutReportTable } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import { InOutReportMonthlyPaperHeader } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportMonthlyPaperHeader";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  REPORT_MONTHLY,
  ReportState,
  ReportMonthlySummary,
  InoutConsultationResultsState
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { OptionInterface } from "@components/atoms/DropDown";
import { InOutReportDialog } from "@components/v202104/organisms/mgr/KEIKAKUSODAN/report/InOutReportDialog";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import {
  InitialDataValues,
  initialValues
} from "@initialize/v202104/mgr/KEIKAKUSODAN/report/initialValues";
import { ResponseError } from "@stores/ui/type";
import {
  DEFAULT_DISCHARGE_ADDITION_COUNT,
  DEFAULT_LIFE_SUPPORT_PROMOTION_COUNT
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: NOTICE_HEADER_HEIGHT,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  facility: FacilityState;
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
};

type DispatchProps = {
  fetchUser: () => Promise<void>;
  fetchFacility: () => void;
  fetchReportUser: (uifId: number, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  responseErrorClear(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績(月ごと）
 */
const InOutReportMonthlyCore = (props: Props): JSX.Element => {
  const { classes, inoutErrors } = props;

  const [selectedMonth, setSelectedMonth] = React.useState<Date>(
    props.initialDate
  );
  const [selectedUser, setSelectedUser] = React.useState<OptionInterface>({
    label: "",
    value: ""
  });
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);
  const [isOpenDetailModal, setIsOpenDetailModal] = React.useState<boolean>(
    false
  );
  const [data, setData] = React.useState<InitialDataValues>(initialValues());
  const [selectedDate, setSelectedDate] = React.useState<Date>(
    props.initialDate
  );

  React.useEffect(() => {
    Promise.all([props.fetchFacility(), props.fetchInoutError(selectedMonth)]);
  }, []);

  React.useEffect(() => {
    const top = document.getElementById("reportMonthlyHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, [document.getElementById("reportMonthlyHeader"), inoutErrors]);

  const onChangeMonth = (date: Date, user: OptionInterface): void => {
    setSelectedMonth(date);
    setSelectedUser(user);
    props.fetchUserInFacility(user.value.toString());
    props.fetchReportUser(+user.value, date);
    props.fetchInoutError(date);
  };

  const onChangeUser = (user: OptionInterface): void => {
    setSelectedUser(user);
    props.fetchReportUser(+user.value, selectedMonth);
    props.fetchUserInFacility(user.value.toString());
    props.fetchInoutError(selectedMonth);
  };

  const onClickErrorDialog = (): void => {
    props.openErrorsDialog();
  };

  const openModal = (param: InoutConsultationResultsState): void => {
    setIsOpenDetailModal(true);
    setData(initialValues(param));
    setSelectedDate(convertBlankSeparatorFormatToDate(param.targetDate));
    props.fetchUserInFacility(param.usersInFacilityId.toString());
  };

  const openCreateModal = (date: string): void => {
    setIsOpenDetailModal(true);

    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues();
    init.initial.targetDate = date;
    init.initial.name = selectedUser.label;
    init.initial.usersInFacilityId = selectedUser.value.toString();

    setData(init);
    setSelectedDate(convertBlankSeparatorFormatToDate(date));
    props.fetchUserInFacility(selectedUser.value.toString());
  };

  const onCancel = (): void => {
    setIsOpenDetailModal(false);
  };

  const onSubmit = (): void => {
    props.fetchReportUser(+selectedUser.value, selectedMonth);
    props.fetchInoutError(selectedMonth);
  };

  /**
   * RectangleBoxが受け付けるconfigを生成する
   */
  const genRectangleBoxConfig = (
    title: string,
    num: number | null | undefined,
    denom?: string,
    unit = "時間"
  ): {
    title: string;
    denom: string | undefined;
    num: number;
    unit: string;
  } => {
    return { title, denom, num: num || 0, unit };
  };

  const createRectangleList = (
    summary: ReportMonthlySummary
  ): {
    title: string;
    denom: string | undefined;
    num: number;
    unit: string;
  }[] => {
    return [
      genRectangleBoxConfig(
        "退院・退所加算",
        summary.dischargeAdditionCount,
        DEFAULT_DISCHARGE_ADDITION_COUNT.toString(),
        "回"
      ),
      genRectangleBoxConfig(
        "地域生活支援拠点等相談強化加算",
        summary.lifeSupportPromotionCount,
        DEFAULT_LIFE_SUPPORT_PROMOTION_COUNT.toString(),
        "回"
      )
    ];
  };

  const createMultiRectangle = (
    summary: ReportMonthlySummary
  ): {
    title: string;
    variableList: { label: string; value: number }[];
    denom: string | undefined;
    unit: string;
  } => {
    return {
      title: "居宅介護支援事業所等連携加算",
      variableList: [
        {
          label: "①",
          value: summary.inhomeNursingCareSupportOfficeRelation1Count
            ? summary.inhomeNursingCareSupportOfficeRelation1Count
            : 0
        },
        {
          label: "②",
          value: summary.inhomeNursingCareSupportOfficeRelation2Count
            ? summary.inhomeNursingCareSupportOfficeRelation2Count
            : 0
        },
        {
          label: "③",
          value: summary.inhomeNursingCareSupportOfficeRelation3Count
            ? summary.inhomeNursingCareSupportOfficeRelation3Count
            : 0
        },
        {
          label: "④",
          value: summary.inhomeNursingCareSupportOfficeRelation4Count
            ? summary.inhomeNursingCareSupportOfficeRelation4Count
            : 0
        },
        {
          label: "⑤",
          value: summary.inhomeNursingCareSupportOfficeRelation5Count
            ? summary.inhomeNursingCareSupportOfficeRelation5Count
            : 0
        },
        {
          label: "⑥",
          value: summary.inhomeNursingCareSupportOfficeRelation6Count
            ? summary.inhomeNursingCareSupportOfficeRelation6Count
            : 0
        }
      ],
      denom:
        summary && summary.inhomeNursingCareSupportOfficeRelationLimit
          ? summary.inhomeNursingCareSupportOfficeRelationLimit.toString()
          : "0",
      unit: "回"
    };
  };

  const createSecondMultiRectangle = (
    summary: ReportMonthlySummary
  ): {
    title: string;
    variableList: { label: string; value: number }[];
    denom: string | undefined;
    unit: string;
  } => {
    return {
      title: "保育・教育等移行支援加算",
      variableList: [
        {
          label: "①",
          value: summary.childcareTransitionSupport1Count
        },
        {
          label: "②",
          value: summary.childcareTransitionSupport2Count
        },
        {
          label: "③",
          value: summary.childcareTransitionSupport3Count
        }
      ],
      denom: "1",
      unit: "回"
    };
  };

  return (
    <>
      <div id="reportMonthlyHeader" className={classes.headerWrapper}>
        {inoutErrors.hasError && (
          <InvoiceErrorBar
            message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
            onClick={onClickErrorDialog}
          />
        )}
        <div className={classes.headerInfoContainer}>
          <InOutReportUserHeader
            minDate={minDate}
            maxDate={maxDate}
            selectedMonth={selectedMonth}
            selectedUserId={selectedUser.value}
            onChangeMonth={onChangeMonth}
            onChangeUser={onChangeUser}
            history={props.history}
            currentPageVersion={props.currentPageVersion}
          />
        </div>
      </div>
      <Paper elevation={0} className={classes.tableContainer}>
        <InOutReportMonthlyPaperHeader
          rectangleConfigList={createRectangleList(
            props.reportState.reportMonthly.summary
          )}
          multiRectangleConfigList={createMultiRectangle(
            props.reportState.reportMonthly.summary
          )}
          secondMultiRectangleConfigList={createSecondMultiRectangle(
            props.reportState.reportMonthly.summary
          )}
          selectedDate={selectedMonth}
          data={data}
          facility={props.facility}
          usersInFacilityState={props.usersInFacilityState}
        />
        <InOutReportTable
          headerHeight={headerHeight}
          openModal={openModal}
          openCreateModal={openCreateModal}
          type={REPORT_MONTHLY}
          filterFlg={false}
          usersInFacilityState={props.usersInFacilityState}
          facility={props.facility}
        />
      </Paper>
      <InOutReportDialog
        open={isOpenDetailModal}
        usersInFacilityState={props.usersInFacilityState}
        data={data}
        selectedDate={selectedDate}
        onCancel={onCancel}
        onSubmit={onSubmit}
        type={REPORT_MONTHLY}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.v202104.KEIKAKUSODAN.report,
    usersInFacilityState: state.v202104.KEIKAKUSODAN.userInFacility,
    facility: state.v202104.KEIKAKUSODAN.facility,
    inoutErrors: state.errors.inout,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, v202104, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = v202104.KEIKAKUSODAN.reportDispatcher(dispatch);
  const uifDispatcher = v202104.KEIKAKUSODAN.userInFacilityDispatcher(dispatch);
  return {
    fetchUser: userDispatch(dispatch).me,
    fetchFacility: v202104.KEIKAKUSODAN.facilityDispatcher(dispatch).fetch,
    fetchReportUser: (uifId: number, date: Date): Promise<void> =>
      reportDispatcher.fetchKEIKAKUSODANUsers(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportMonthlyCore));
