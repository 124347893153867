import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import { getUsersSummary } from "@api/requests/supports/Cseg/getUsersSummary";

const fetchUsersSummary = (dispatch: Dispatch) => async (
  year: string,
  month: string,
  excludedUserIds?: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchUsersSummaryStarted());
  await getUsersSummary(year, month, excludedUserIds)
    .then((res) => {
      dispatch(actions.fetchUsersSummarySuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchUsersSummaryFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchUsersSummary: ReturnType<typeof fetchUsersSummary>;
};

export const CsegUsersSummaryDispatcher = (dispatch: Dispatch): Dispatcher => ({
  fetchUsersSummary: fetchUsersSummary(dispatch)
});
