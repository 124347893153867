import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import * as queryString from "query-string";
import { isEmpty } from "lodash-es";

// store
import {
  ServiceDeliveryTypeInterface,
  SERVICE_DELIVERY_DAILY,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
// ui
import TableCellWrap from "@components/atoms/TableCellWrap";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { CheckCircle } from "@material-ui/icons/";
// utils
import * as format from "date-fns/format";
import { getStatusLabel } from "@/utils/domain/serviceDelivery/getStatusLabel";
import { defaultTimeLabel } from "@/utils/date/index";
// variables
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    recordedFlg: {
      height: "18px",
      width: "18px"
    },
    statusCell: {
      minWidth: 104,
      width: 104,
      boxSizing: "content-box",
      lineHeight: "24px",
      paddingLeft: 8
    },
    numberOfPractitionerCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    timeCell: {
      minWidth: 132,
      width: 132,
      boxSizing: "content-box",
      lineHeight: "24px",
      whiteSpace: "pre-line"
    },
    numberOfTimeCell: {
      minWidth: 50,
      width: 50,
      boxSizing: "content-box",
      lineHeight: "24px"
    },
    unPlannedFlgCell: {
      minWidth: 72,
      width: 72,
      boxSizing: "content-box",
      lineHeight: "24px",
      textAlign: "center"
    },
    unPlannedIcon: {
      height: "24px",
      width: "64px",
      backgroundColor: "#757575",
      borderRadius: "12px",
      color: "#fff",
      textAlign: "center",
      fontSize: "12px"
    },
    recordedButtonCell: {
      width: 160,
      minWidth: 160,
      boxSizing: "content-box",
      lineHeight: "24px",
      textAlign: "center",
      "&:last-child": {
        paddingRight: 16
      }
    },
    note: {
      width: "160px",
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)"
    },
    recordedButton: {
      position: "relative",
      width: 160,
      minHeight: 32
    },
    recordedIcon: {
      position: "absolute",
      color: "#2db45a",
      right: "-8px",
      bottom: 22,
      height: "16.8px",
      width: "16.8px"
    },
    baseCell: {
      fontSize: 16,
      paddingLeft: 0,
      paddingRight: 8
    }
  });

type OwnProps = {
  params: ServiceDeliveryState["dailyRecord"]["service_delivery"][0];
  idx: string;
  lastFlg: boolean;
  type: ServiceDeliveryTypeInterface["type"];
  changeEditMode: (
    serviceDeliveryRecordsId: number,
    inoutResultsId: number,
    targetDate: string,
    uifId: number,
    support_procedure_forms_id: number
  ) => void;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const ServiceDeliveryTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  const statusLabel = getStatusLabel(params.status, props.facilityType);
  const userId = `${params.users_in_facility_id}`;

  const changeEditMode = (): void => {
    props.changeEditMode(
      params.service_delivery_records_id || 0,
      params.inout_results_id || 0,
      params.target_date,
      params.users_in_facility_id,
      params.support_procedure_forms_id || 0
    );
  };

  const supportProcedureQueryObject: Record<string, number> = {};
  if (params.service_delivery_records_id) {
    supportProcedureQueryObject.serviceDeliveryRecordsId =
      params.service_delivery_records_id;
  }
  if (params.inout_results_id) {
    supportProcedureQueryObject.inoutResultsId = params.inout_results_id;
  }
  if (params.support_procedure_forms_id) {
    supportProcedureQueryObject.supportProcedureFormsId =
      params.support_procedure_forms_id;
  }
  const supportProcedureQuery = !isEmpty(supportProcedureQueryObject)
    ? `?${queryString.stringify(supportProcedureQueryObject)}`
    : "";

  // 「時間・時間数」表示パターン
  const startTime1 = params.start_time_1
    ? format(params.start_time_1, "HH:mm")
    : defaultTimeLabel;
  const endTime1 = params.end_time_1
    ? format(params.end_time_1, "HH:mm")
    : defaultTimeLabel;
  const startTime2 = params.start_time_2
    ? format(params.start_time_2, "HH:mm")
    : defaultTimeLabel;
  const endTime2 = params.end_time_2
    ? format(params.end_time_2, "HH:mm")
    : defaultTimeLabel;
  const timeDisplayPattern = ((): number => {
    if (params.number_of_practitioner === 1) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_2 &&
      !params.end_time_2
    ) {
      return 1;
    }
    if (
      params.number_of_practitioner === 2 &&
      !params.start_time_1 &&
      !params.end_time_1
    ) {
      return 2;
    }
    return 3;
  })();

  // 時間表示用処理
  const TimeCellCore = (): JSX.Element => {
    switch (timeDisplayPattern) {
      case 1:
        return <>{`${startTime1} 〜 ${endTime1}`}</>;
      case 2:
        return <>{`${startTime2} 〜 ${endTime2}`}</>;
      case 3:
        return (
          <>
            {`${startTime1} 〜 ${endTime1}`}
            <br />
            {`${startTime2} 〜 ${endTime2}`}
          </>
        );
      default:
        return <></>;
    }
  };

  // 時間数表示用処理
  const NumberOfTimeCellCore = (): JSX.Element => {
    switch (timeDisplayPattern) {
      case 1:
        return (
          <>
            {params.number_of_time_1 ? params.number_of_time_1.toFixed(1) : "-"}
          </>
        );
      case 2:
        return (
          <>
            {params.number_of_time_2 ? params.number_of_time_2.toFixed(1) : "-"}
          </>
        );
      case 3:
        return (
          <>
            {params.number_of_time_1 ? params.number_of_time_1.toFixed(1) : "-"}
            <br />
            {params.number_of_time_2 ? params.number_of_time_2.toFixed(1) : "-"}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {/* 余白 */}
      <TableCellWrap
        key={`${idx}-margin`}
        cellClass={`${classes.recordedFlg}`}
      />
      {/* サービス内容 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.statusCell} ${classes.baseCell}`}
      >
        {statusLabel}
      </TableCellWrap>

      {/* 人数 */}
      <TableCellWrap
        key={`${idx}-practitioner-number`}
        cellClass={`${classes.numberOfPractitionerCell} ${classes.baseCell}`}
      >
        {params.number_of_practitioner ? params.number_of_practitioner : "-"}
      </TableCellWrap>

      {/* 時間 */}
      <TableCellWrap
        key={`${idx}-time`}
        cellClass={`${classes.timeCell} ${classes.baseCell}`}
      >
        <TimeCellCore />
      </TableCellWrap>

      {/* 時間数 */}
      <TableCellWrap
        key={`${idx}-numberーofーtime`}
        cellClass={`${classes.numberOfTimeCell} ${classes.baseCell}`}
      >
        <NumberOfTimeCellCore />
      </TableCellWrap>

      {/* 計画外 */}
      <TableCellWrap
        key={`${idx}-unplanned-flg`}
        cellClass={`${classes.unPlannedFlgCell} ${classes.baseCell}`}
      >
        {params.unplanned_flg ? (
          <div className={classes.unPlannedIcon}>計画外</div>
        ) : null}
      </TableCellWrap>

      {/* 手順書 兼 記録用紙 */}
      <TableCellWrap
        key={`${idx}-procedure-button`}
        cellClass={`${classes.recordedButtonCell} ${classes.baseCell}`}
      >
        {params.procedure_status_flg !== 0 ? (
          <KnowbeButton
            className={classes.recordedButton}
            kind="outlineWhite"
            href={
              props.type === SERVICE_DELIVERY_DAILY
                ? `#/record/service_delivery/daily/${userId}/support_procedure${supportProcedureQuery}`
                : `#/record/service_delivery/monthly/${userId}/support_procedure${supportProcedureQuery}`
            }
          >
            {params.procedure_status_flg === 2 ? (
              <CheckCircle className={classes.recordedIcon} />
            ) : null}
            手順書 兼 記録用紙
          </KnowbeButton>
        ) : (
          <p className={classes.note}>（手順書未作成）</p>
        )}
      </TableCellWrap>

      {/* サービス提供記録ボタン */}
      <TableCellWrap
        key={`${idx}-service-delivery-button`}
        cellClass={`${classes.recordedButtonCell} ${classes.baseCell}`}
      >
        <KnowbeButton
          className={classes.recordedButton}
          kind="outlineWhite"
          onClick={changeEditMode}
        >
          {params.recorded_flg === 1 ? (
            <CheckCircle className={classes.recordedIcon} />
          ) : null}
          サービス提供記録
        </KnowbeButton>
      </TableCellWrap>
    </>
  );
};

export const ServiceDeliveryTableCells = withStyles(styles)(
  ServiceDeliveryTableCellsCore
);
