import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { basicInfoApi } from "@api/requests/basicInfo";
import { BasicInfoValues } from "@initialize/mgr/KEIKAKUSODAN/record/basicInfo/initialValues";
import * as H from "history";
import * as URL from "@constants/url";
import dispatches from "@stores/dispatches";
import { normalizePostBasicInfoDataToAPI } from "./normalizer";

export const fetchBasicInfoList = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchBasicInfoListStarted());
  await basicInfoApi
    .getBasicInfoList(uifId)
    .then((res) => {
      dispatch(actions.fetchBasicInfoListSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchBasicInfoListFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchBasicInfo = (dispatch: Dispatch) => async (
  uifId: string,
  supportPlanBasicInfoId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchBasicInfoStarted());
  await basicInfoApi
    .getBasicInfo(uifId, supportPlanBasicInfoId)
    .then((res) => {
      dispatch(actions.fetchBasicInfoSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchBasicInfoFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postBasicInfo = (dispatch: Dispatch) => async (
  uifId: string,
  basicInfo: BasicInfoValues,
  history: H.History,
  isCopy: boolean,
  isNew: boolean
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postBasicInfoStarted());
  const normalizeParam = normalizePostBasicInfoDataToAPI(
    basicInfo,
    isCopy,
    isNew
  );
  await basicInfoApi
    .postBasicInfo(uifId, normalizeParam)
    .then(() => {
      dispatch(actions.postBasicInfoSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 新規・コピーの場合（編集ではないとき）
      if (basicInfo.basic_info_id === null) {
        history.push(`${URL.RECORD_SUPPORT_PLAN}/${uifId}/basic_info`);
      }
    })
    .catch((e) => {
      dispatch(actions.postBasicInfoFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteBasicInfo = (dispatch: Dispatch) => async (
  uifId: string,
  supportPlanBasicInfoId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteBasicInfoStarted());
  await basicInfoApi
    .deleteBasicInfo(uifId, supportPlanBasicInfoId)
    .then(() => {
      dispatch(actions.deleteBasicInfoSuccess());
    })
    .catch((e) => {
      dispatch(actions.deleteBasicInfoFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchBasicInfoList: (uifId: string) => Promise<void>;
  fetchBasicInfo: (
    uifId: string,
    supportPlanBasicInfoId: string
  ) => Promise<void>;
  postBasicInfo: (
    uifId: string,
    basicInfo: BasicInfoValues,
    history: H.History,
    isCopy: boolean,
    isNew: boolean
  ) => Promise<void>;
  deleteBasicInfo: (
    uifId: string,
    supportPlanBasicInfoId: string
  ) => Promise<void>;
};

export const KEIKAKUSODANBasicInfoDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchBasicInfoList: fetchBasicInfoList(dispatch),
  fetchBasicInfo: fetchBasicInfo(dispatch),
  postBasicInfo: postBasicInfo(dispatch),
  deleteBasicInfo: deleteBasicInfo(dispatch)
});
