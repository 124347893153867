import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import * as H from "history";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import { AppState } from "@stores/type";
import { StaffState } from "@stores/domain/staff/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { connect } from "react-redux";
import { UsersInFacilityState } from "@stores/domain/mgr/GroupHome/userInFacility/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// ui
import { DailyUsersRecordSupportTable } from "@components/organisms/mgr/GroupHome/record/DailyUsersRecordSupportTable";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "32px 32px 0px"
    },
    recordSummary: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginBottom: 32
    },
    usageSituation: {
      display: "flex",
      "& > div": {
        marginRight: 8
      }
    },
    summaryWrap: {
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "5px",
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    CreateAndUpdateDateWrap: {
      marginTop: 20,
      marginBottom: 20
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    noUsers: {
      padding: "80px",
      textAlign: "center"
    }
  });

type OwnProps = {
  pageName: string;
  supportsRecord: DailyOperationsAndSupportsState["support"];
  staff: StaffState;
  staffOptions: FieldItem[];
  recordDaily: RecordDailyState;
  history: H.History;
  users: UsersInFacilityState["users"];
  unitId: number;
  yyyymmdd: string;
};
type StateProps = {
  customRecords: CustomRecordsState;
};
type Props = OwnProps & StateProps & WithStyles<typeof styles>;

/**
 * 日ごとの支援記録の管理を扱う
 */
const DailySupportsRecord = (props: Props): JSX.Element => {
  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === 3)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) => item.default_item === 9
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);
    return [...orderArray, ...recorderItem];
  };

  const newCustomRecords =
    props.customRecords.length > 0 && sortCustomRecords(props.customRecords);
  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <div className={props.classes.header}>
          <Typography
            variant="h6"
            color="primary"
            className={props.classes.title}
          >
            {props.pageName}
          </Typography>
        </div>
        <Divider variant="middle" className={props.classes.divider} />
        {props.users.length > 0 ? (
          <DailyUsersRecordSupportTable
            supportsRecord={props.supportsRecord}
            staffOptions={props.staffOptions}
            isEditing={props.recordDaily.isEditing}
            targetUifId={props.recordDaily.uifId}
            targetUnitId={props.recordDaily.unitId}
            customRecords={props.customRecords}
            newCustomRecords={newCustomRecords || null}
            users={props.users}
            unitId={props.unitId}
            yyyymmdd={props.yyyymmdd}
          />
        ) : (
          <div className={props.classes.noUsers}>
            このユニットには利用者が登録されていません。
          </div>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords
});

export default connect(mapStateToProps)(
  withStyles(styles)(DailySupportsRecord)
);
