/**
 * 渡されたobjectのvalueから{}とundefinedを除去する
 * (0は有効、nullを入れると壊れるが現在の想定ではnullが入るパターンはないので無視)
 */
export const toEffectiveObject = (obj: object): object | undefined => {
  const effectiveKey = Object.keys(obj).filter((key) => {
    return obj[key] !== undefined;
  });
  const newObject = {};
  effectiveKey.forEach((key) => {
    const res =
      typeof obj[key] === "object" ? toEffectiveObject(obj[key]) : obj[key];
    if (res !== undefined) {
      newObject[key] = res;
    }
  });
  if (Object.keys(newObject).length) {
    return newObject;
  }
  return undefined;
};
