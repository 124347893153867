import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import classNames from "classnames";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { PrintSchedulesItem } from "@interfaces/record/assessment/assessment";
import {
  CSEG_ASSESSMENT_PRINT_FLOW_CAREGIVER_DATE,
  CSEG_ASSESSMENT_PRINT_FLOW_USER_DATE
} from "@constants/variables";
import { FlowDay } from "@api/requests/assessment/getAssessment";

const styles = (): StyleRules =>
  createStyles({
    container: {
      marginTop: 16,
      breakInside: "avoid"
    },
    calendar: {
      display: "flex",
      width: "680px",
      justifyContent: "flex-start",
      overflow: "hidden",
      position: "relative",
      "&::before": {
        content: "'　'",
        display: "block",
        width: "100%",
        height: "100%",
        border: "2px solid #212121",
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 10
      },
      "& .fc-scrollgrid>thead": {
        "@media print": {
          display: "table-caption",
          transform: "translateY(1px) translateX(1px)"
        }
      },
      "& .fc-timegrid-body": {
        overflow: "hidden"
      },
      "& table.fc-scrollgrid": {
        pointerEvents: "none",
        borderColor: "#212121"
      },
      "& .fc-col-header-cell-cushion": {
        display: "block",
        padding: 0
      },
      "& th.fc-col-header-cell": {
        fontSize: 10,
        lineHeight: 1.4,
        letterSpacing: "0.5px",
        padding: "3px 0",
        color: " #212121",
        fontWeight: "normal"
      },
      "& .fc-theme-standard th , & .fc-theme-standard td": {
        borderColor: "#212121"
      },
      "& td.fc-timegrid-slot-lane": {
        height: 24,
        borderTopStyle: "dotted",
        borderTopColor: "#979797"
      },
      "& .fc-timegrid-slot-label": { border: "none" },
      "& .fc-timegrid-slot-label-frame": {
        position: "relative",
        height: "100%"
      },
      "& .fc-timegrid-slot-label-frame::before": {
        content: "' '",
        display: "block",
        position: "absolute",
        width: 4,
        height: 2,
        background: "#212121",
        top: -1,
        right: 0
      },
      "& .fc-timegrid-slot-label-cushion": {
        fontSize: 10,
        lineHeight: 1,
        letterSpacing: "0.5px",
        transform: "scale(0.8)",
        transformOrigin: "right",
        position: "absolute",
        top: 2,
        right: 9,
        padding: 0
      },
      "& .fc-timegrid-event": {
        borderWidth: "1px",
        "& .fc-event-main": {
          padding: "2px 1px"
        }
      },
      "& .fc-timegrid-col-events": {
        margin: "0 2px 0 2px"
      },
      "& .fc-event-title-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },
      "& .fc-event-title": {
        fontSize: 10,
        lineHeight: 1,
        fontWeight: "bold",
        transform: "scale(0.7)",
        transformOrigin: "left",
        width: "calc(100% / 0.7)",
        overflow: "hidden",
        textAlign: "center",
        wordBreak: "break-all",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical"
      },
      "& .line-clamp--1 .fc-event-title": {
        "-webkit-line-clamp": 1
      },
      "& .line-clamp--2 .fc-event-title": {
        "-webkit-line-clamp": 2
      },
      "& .line-clamp--3 .fc-event-title": {
        "-webkit-line-clamp": 3
      },
      "& .line-clamp--4 .fc-event-title": {
        "-webkit-line-clamp": 4
      },
      "& .fc-timegrid-event.fc-event-start": {
        borderBottom: "none",
        height: "calc(100% + 1px)"
      },
      "& .fc-timegrid-event.fc-event-end": {
        borderTop: "none"
      },
      "& .fc-timegrid-event.fc-event-start.fc-event-end": {
        border: "1px solid #212121",
        "&::after": {
          display: "none"
        }
      },
      "& colgroup col": {
        width: "42px!important"
      }
    },
    calendarText: {
      position: "absolute",
      top: 4,
      left: 10,
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "#212121"
    },
    flowTitle: {
      display: "flex",
      alignItems: "center",
      fontFamily: "HiraginoSans-W3",
      fontSize: 10,
      lineHeight: "14px"
    },
    MT8: {
      marginTop: 8
    }
  });

type OwnProps = {
  flowDay: FlowDay;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentPrintFlowDayCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  // タイムテーブルの時間軸用
  let minTime = "00:00:00";
  let maxTime = "02:00:00";
  let minTimeValue = 0;
  let maxTimeValue = 0;

  const printSchedulesData: PrintSchedulesItem[] = [];
  props.flowDay.flow_day_details_user.forEach((v) => {
    if (v.start_time && v.end_time) {
      printSchedulesData.push({
        title: v.matter || "",
        start: `${CSEG_ASSESSMENT_PRINT_FLOW_USER_DATE}T${v.start_time}`,
        end: `${CSEG_ASSESSMENT_PRINT_FLOW_USER_DATE}T${v.end_time}`
      });
    }
  });
  props.flowDay.flow_day_details_caregiver.forEach((v) => {
    if (v.start_time && v.end_time) {
      printSchedulesData.push({
        title: v.matter || "",
        start: `${CSEG_ASSESSMENT_PRINT_FLOW_CAREGIVER_DATE}T${v.start_time}`,
        end: `${CSEG_ASSESSMENT_PRINT_FLOW_CAREGIVER_DATE}T${v.end_time}`
      });
    }
  });

  if (printSchedulesData.length === 0) {
    minTimeValue = 0;
    maxTimeValue = 2;
  } else {
    const timeData: number[] = [];
    props.flowDay.flow_day_details_user.forEach((v) => {
      if (v.start_time) {
        timeData.push(Number(v.start_time.split(":")[0]));
      }
      if (v.end_time) {
        timeData.push(Number(v.end_time.split(":")[0]));
      }
    });
    props.flowDay.flow_day_details_caregiver.forEach((v) => {
      if (v.start_time) {
        timeData.push(Number(v.start_time.split(":")[0]));
      }
      if (v.end_time) {
        timeData.push(Number(v.end_time.split(":")[0]));
      }
    });
    minTimeValue = Math.min(...timeData) - 2;
    maxTimeValue = Math.max(...timeData) + 2;
  }

  if (minTimeValue !== Infinity && maxTimeValue !== Infinity) {
    if (minTimeValue <= 0) {
      minTime = "00:00:00";
    } else if (minTimeValue > 0 && minTimeValue < 10) {
      minTime = `0${minTimeValue}:00:00`;
    } else {
      minTime = `${minTimeValue}:00:00`;
    }

    if (maxTimeValue > 24) {
      maxTime = "24:00:00";
    } else if (maxTimeValue < 10) {
      maxTime = `0${maxTimeValue}:00:00`;
    } else {
      maxTime = `${maxTimeValue}:00:00`;
    }
  }

  return (
    <div className={classes.container}>
      <span className={classes.flowTitle}>{props.flowDay.title}</span>
      <div className={classNames(classes.calendar, classes.MT8)}>
        <div className={classes.calendarText}>時刻</div>
        <FullCalendar
          locale="ja"
          plugins={[timeGridPlugin]}
          contentHeight="auto"
          headerToolbar={false}
          slotDuration="01:00:00"
          slotLabelInterval="02:00"
          slotMinTime={minTime}
          slotMaxTime={maxTime}
          slotEventOverlap={false}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            omitZeroMinute: false,
            meridiem: false,
            hour12: false
          }}
          dayHeaderFormat={(date): string => {
            const days = ["本人", "介護者"];
            return days[date.date.marker.getDay()];
          }}
          initialView="timeGridFourDay"
          views={{
            timeGridFourDay: {
              type: "timeGrid",
              duration: { days: 2 }
            }
          }}
          displayEventTime={false}
          eventBackgroundColor="#f5f5f5"
          eventColor="#212121"
          eventTextColor="#212121"
          eventBorderColor="#212121"
          allDaySlot={false}
          initialDate={CSEG_ASSESSMENT_PRINT_FLOW_USER_DATE}
          navLinks={false}
          editable
          dayMaxEvents
          events={printSchedulesData}
          eventDidMount={(item): void => {
            // タイトルの３点リーダー
            const height = item.el.clientHeight;
            if (height < 24) {
              item.el.classList.add("line-clamp--1");
            } else if (height >= 24 && height < 34) {
              item.el.classList.add("line-clamp--2");
            } else if (height >= 34 && height < 74) {
              item.el.classList.add("line-clamp--3");
            } else {
              item.el.classList.add("line-clamp--4");
            }
          }}
        />
      </div>
    </div>
  );
};

export const AssessmentPrintFlowDay = withStyles(styles)(
  AssessmentPrintFlowDayCore
);
