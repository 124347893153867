import React, { useEffect } from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import * as queryString from "query-string";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { GetURLValidityResult } from "@stores/domain/account/type";
import { RouteComponentProps, Redirect } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import AuthTemplate from "@components/templates/AuthTemplate";
import { NewAccountForm } from "@components/organisms/account/NewAccountForm";

const styles = (): StyleRules =>
  createStyles({
    header: {
      margin: 0,
      padding: "24px 32px 9px",
      borderBottom: "1px solid #cfd8dc",
      fontSize: 24,
      fontWeight: "normal",
      color: "#37474f"
    },
    company: {
      padding: "32px 32px 0",
      display: "flex"
    },
    companyWrapper: {
      width: "50%"
    },
    section: {
      padding: "0 32px 32px"
    },
    title: {
      margin: "0 0 32px 0",
      paddingBottom: 7,
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      fontSize: 20,
      color: "#37474f",
      fontWeight: "bold"
    },
    categoryType: {
      fontSize: 12,
      color: "#37474f",
      margin: "0 0 4px"
    },
    categoryText: {
      fontSize: 16,
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0
    },
    expiredText: {
      fontSize: 16,
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "center"
    }
  });

type StateProps = {
  account: GetURLValidityResult;
};

type DispatchProps = {
  checkActivateValidity: (email: string, token: string) => void;
};

type Props = RouteComponentProps &
  DispatchProps &
  StateProps &
  WithStyles<typeof styles>;

/**
 * 新規ユーザ登録
 */
const NewUserCore = (props: Props): JSX.Element => {
  useEffect(() => {
    const queryParam = queryString.parse(props.location.search);
    const email = Array.isArray(queryParam.a) ? queryParam.a[0] : queryParam.a;
    const token = Array.isArray(queryParam.b) ? queryParam.b[0] : queryParam.b;
    props.checkActivateValidity(email, token);
  }, []);

  const queryParam = queryString.parse(props.location.search);
  // 新規登録時に必要なデータが存在しない場合はログイン画面にリダイレクト
  if (!queryParam.a || !queryParam.b || !queryParam.c || !queryParam.d) {
    return <Redirect to="/login" />;
  }
  const email = Array.isArray(queryParam.a) ? queryParam.a[0] : queryParam.a;
  const token = Array.isArray(queryParam.b) ? queryParam.b[0] : queryParam.b;

  let isTimeCardAccountCreate;

  if (queryParam.e === null) {
    isTimeCardAccountCreate = true; // 2019/12/24リリース以前に送信された新規登録メールには"e"が存在しないパターンがあった
  } else if (Array.isArray(queryParam.e)) {
    isTimeCardAccountCreate = queryParam.e[0] !== "";
  } else {
    isTimeCardAccountCreate = queryParam.e !== "";
  }

  return (
    <AuthTemplate>
      {props.account.status === 0 && (
        <Paper>
          <h1 className={props.classes.header}>新規ユーザ登録</h1>
          <section className={props.classes.company}>
            <div className={props.classes.companyWrapper}>
              <p className={props.classes.categoryType}>法人名</p>
              <p className={props.classes.categoryText}>{queryParam.c}</p>
            </div>
            <div className={props.classes.companyWrapper}>
              <p className={props.classes.categoryType}>事業所名</p>
              <p className={props.classes.categoryText}>{queryParam.d}</p>
            </div>
          </section>
          <section className={props.classes.section}>
            <NewAccountForm
              history={props.history}
              email={email}
              token={token}
              isTimeCardAccountCreate={isTimeCardAccountCreate}
            />
          </section>
        </Paper>
      )}
      {props.account.status && props.account.status !== 0 ? (
        <div className={props.classes.expiredText}>
          このリンクは有効ではありません
        </div>
      ) : (
        ""
      )}
    </AuthTemplate>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { accountDispatch } = dispatches;
  const accountDispatches = accountDispatch(dispatch);
  return {
    checkActivateValidity: (email: string, token: string): Promise<void> =>
      accountDispatches.checkActivateValidity(email, token)
  };
};

const mapStateToProps = (state: StateProps): StateProps => ({
  account: state.account
});

export const NewUser = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewUserCore));
