import { WithStyles, createStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import React from "react";

const styles = (): StyleRules =>
  createStyles({
    noUser: {
      marginTop: 24,
      padding: "80px 0 48px",
      textAlign: "center",
      lineHeight: "28px",
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

type Props = WithStyles<typeof styles>;

const RecordUserEmptyMessageCore = (props: Props): JSX.Element => {
  const { classes } = props;

  return (
    <div className={classes.noUser}>
      利用者が登録されていないか、条件に当てはまる利用者が見つかりませんでした。
      <br />
      利用者を登録するか、条件を変更して再度絞り込みをしてください。
    </div>
  );
};

export const RecordUserEmptyMessage = withStyles(styles)(
  RecordUserEmptyMessageCore
);
