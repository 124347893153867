/**
 * basic.operatingUnitFlag(基本情報 > ２つ以上のユニットを運用している)が有効な時表示
 * ユニット名の追加・削除を行う
 */

import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { FormikProps, FieldArray } from "formik";
import {
  FacilityValues as FacilityValuesGroupHome,
  unitInitialValue
} from "@initialize/v202104/mgr/GroupHome/facility/initialValues";
import { UnitsValues as UnitsValuesGroupHome } from "@interfaces/v202104/mgr/GroupHome/facility/units";
import { UnitsValues as UnitsValuesSHISETSUNYUSHO } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/facility/units";
import { FacilityValues as FacilityValuesSHISETSUNYUSHO } from "@initialize/v202104/mgr/SHISETSUNYUSHO/facility/initialValues";
import Button from "@material-ui/core/Button";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import IconAdd from "@material-ui/icons/Add";
import deepEqual from "fast-deep-equal";
import { StyleRules } from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginLeft: -16
    },
    label: {
      display: "flex",
      fontSize: 12,
      color: "#37474F",
      "& > :first-child": {
        width: 526,
        marginRight: 14
      }
    },
    fieldWrapper: {
      "& > :last-child": {
        marginBottom: -4
      }
    },
    field: {
      display: "flex",
      marginBottom: 10,
      "& > div": {
        margin: 0,
        "&:first-child": {
          width: 526,
          marginRight: 16
        }
      }
    }
  });

type OwnProps = {
  formikProps:
    | FormikProps<FacilityValuesGroupHome>
    | FormikProps<FacilityValuesSHISETSUNYUSHO>;
  unitLabel?: string;
  children?: React.ReactNode;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UnitsFields = (props: Props): JSX.Element => {
  const {
    units
  }: {
    units: UnitsValuesGroupHome["units"] | UnitsValuesSHISETSUNYUSHO["units"];
  } = props.formikProps.values;
  const unitLabel: string = props.unitLabel ? props.unitLabel : "ユニット";
  const isDeletedCount = units.filter((x) => x.is_deleted).length;
  // 削除は2件以下には出来ない
  const canDelete = units.length - isDeletedCount > 2;
  return (
    <FieldArray name="units">
      {(arrayHelpers): JSX.Element => {
        const onClickAddUnit = (): void => {
          arrayHelpers.push(unitInitialValue);
        };
        return (
          <div className={props.classes.wrapper}>
            {props.children}
            <div>
              <div className={props.classes.label}>
                <div>{unitLabel}名</div>
                <div>削除</div>
              </div>
              <div className={props.classes.fieldWrapper}>
                {(units as (
                  | UnitsValuesGroupHome["units"][0]
                  | UnitsValuesSHISETSUNYUSHO["units"][0]
                )[]).map((unit, i) => (
                  <div
                    className={props.classes.field}
                    key={`unit-${unit.id || `index-${i}`}`}
                  >
                    <FormikTextField
                      name={`units[${i}].unit_name`}
                      placeholder={`${unitLabel}名`}
                      disabled={unit.is_deleted}
                    />
                    <FormikCheckbox
                      name={`units[${i}].is_deleted`}
                      label=""
                      disabled={!unit.is_deleted && !canDelete}
                    />
                  </div>
                ))}
              </div>
              <Button color="secondary" onClick={onClickAddUnit}>
                <IconAdd />
                {unitLabel}を追加
              </Button>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};

export default React.memo(withStyles(styles)(UnitsFields), (prev, next) =>
  deepEqual(prev.formikProps.values.units, next.formikProps.values.units)
);
