import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { CHIIKIIKO_SERVICE_TYPE_LIST } from "@constants/mgr/CHIIKIIKO/variables";

const chiikiikoServiceTypeRadioItems = CHIIKIIKO_SERVICE_TYPE_LIST;

export const BasicRemuneration = (): JSX.Element => {
  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="基本報酬" />
      </div>
      <FormGroup>
        <FormikRadioButtons
          name="basicRemuneration.chiikiikoServiceType"
          label="地域移行支援サービス費"
          options={chiikiikoServiceTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="chiikiikoServiceType" />}
            />
          }
          style={{ marginBottom: 0 }}
        />
      </FormGroup>
    </FormPaper>
  );
};
