/**
 * パスワードの一致チェック
 * @param value
 * @param matchValue
 */
const passwordMatch = (
  value: string,
  matchValue: string
): string | undefined => {
  let errorMessage;
  if (value !== matchValue) {
    errorMessage = "パスワードが一致しません";
  }
  return errorMessage;
};

export default passwordMatch;
