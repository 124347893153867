import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { OperationsState as OperationsStateTANIKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/operations/types";
import { OperationsState as OperationsStateGroupHome } from "@stores/domain/mgr/GroupHome/operations/types";

const styles = (): StyleRules =>
  createStyles({
    summaryWrap: {
      borderRadius: 4,
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      display: "flex",
      flexWrap: "wrap",
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    }
  });

type OwnProps = {
  mealData: CustomRecordsState[number];
  mealItem?:
    | OperationsStateTANIKINYUSHO["operation"][number]["counts"]["meal"]
    | OperationsStateGroupHome["operation"][number]["counts"]["meal"];
  date: string;
};
type Props = OwnProps & WithStyles<typeof styles>;

const MealSummaryCore = (props: Props): JSX.Element => {
  return (
    <div className={props.classes.summaryWrap}>
      {props.mealData.choices.map((item) => {
        const choiceInputData =
          props.mealItem &&
          props.mealItem.choices.length > 0 &&
          props.mealItem.choices.find(
            (choiceData) => choiceData.id === item.id
          );

        // 記録のない日付の表示設定
        if (
          !choiceInputData &&
          !(props.mealData.visibility === 0 || item.hidden === 1)
        ) {
          return (
            <div
              className={props.classes.summaryContent}
              key={`${props.date}_${item.id}`}
            >{`${item.name}：0 人`}</div>
          );
        }

        // 記録のある日付の表示設定
        if (
          !choiceInputData ||
          (props.mealData.visibility === 0 && choiceInputData.count === 0) ||
          (item.hidden === 1 && choiceInputData.count === 0)
        ) {
          return null;
        }
        return (
          <div
            className={props.classes.summaryContent}
            key={choiceInputData.id}
          >{`${item.name}：${choiceInputData.count} 人`}</div>
        );
      })}
    </div>
  );
};

export const MealSummary = withStyles(styles)(MealSummaryCore);
