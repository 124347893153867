import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import ReportDaily from "@components/pages/mgr/SHISETSUNYUSHO/Report/Daily";
import ReportDaily201910 from "@components/v201910/pages/mgr/SHISETSUNYUSHO/report/Daily";
import { ReportDaily202104 } from "@components/v202104/pages/mgr/SHISETSUNYUSHO/report/Daily";
import ReportMonthly from "@components/pages/mgr/SHISETSUNYUSHO/Report/Monthly";
import ReportMonthly201910 from "@components/v201910/pages/mgr/SHISETSUNYUSHO/report/Monthly";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/SHISETSUNYUSHO/report/Monthly";

// 利用者情報
import UsersList from "@components/pages/mgr/SHISETSUNYUSHO/Users/UsersList";
import CreateUser from "@components/pages/mgr/SHISETSUNYUSHO/Users/CreateUser";
import CreateUser201910 from "@components/v201910/pages/mgr/SHISETSUNYUSHO/users/CreateUser";
import EditUser from "@components/pages/mgr/SHISETSUNYUSHO/Users/EditUser";
import EditUser201910 from "@components/v201910/pages/mgr/SHISETSUNYUSHO/users/EditUser";
import { EditUser202104 } from "@components/v202104/pages/mgr/SHISETSUNYUSHO/users/EditUser";

// 記録機能
import { Operations } from "@components/pages/mgr/SHISETSUNYUSHO/record/Operations";
import { OperationsRecordPrint } from "@components/pages/record/print/OperationsPrint";

// 事業所情報
import Facility201910 from "@components/v201910/pages/mgr/SHISETSUNYUSHO/Facility";
import Facility from "@components/pages/mgr/SHISETSUNYUSHO/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/SHISETSUNYUSHO/Facility";

// 初期設定情報
import InitialData from "@components/pages/mgr/SHISETSUNYUSHO/initialData";

// 記録の項目設定
import { RecordSetting } from "@components/pages/record/setting/RecordSetting";

// 記録機能
import { RecordUsersSummary } from "@components/pages/mgr/SHISETSUNYUSHO/record/UsersSummary";

// 印刷・記録機能
import { RecordUsersSummarySupportPrint } from "@components/pages/record/print/UsersSummarySupportPrintCustomRecord";
// 個別支援計画
import { SupportPlanList } from "@components/pages/mgr/SHISETSUNYUSHO/record/SupportPlanList";
import { SupportPlanNew } from "@components/pages/mgr/SHISETSUNYUSHO/record/SupportPlanNew";
import { SupportPlan } from "@components/pages/mgr/SHISETSUNYUSHO/record/SupportPlan";
import { RecordSupportPlanPrint } from "@components/pages/record/print/SupportPlanPrint";

// 職員情報
import Staffs from "@components/pages/staffs/Staffs";

// 記録機能
import { DailyRecord } from "@components/pages/mgr/SHISETSUNYUSHO/record/Daily";
import { UserDetail } from "@components/pages/mgr/SHISETSUNYUSHO/record/UserDetail";

// 印刷・記録機能
import { SupportRecordPrint } from "@components/pages/record/print/SupportPrint";
import { DailyRecordCustomRecords } from "@components/pages/record/print/DailyPrintCustomRecords";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 施設入所支援施設でログインした時だけ有効になるルーティング
 */
const SHISETSUNYUSHORoutes: React.FunctionComponent<Props> = ({ user }) => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_DAILY_201910} component={ReportDaily201910} />
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    <AdminRoute
      path={URL.REPORT_USERS_201910}
      component={ReportMonthly201910}
    />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute
      exact
      path={URL.USERS_NEW_201910}
      component={CreateUser201910}
    />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_201910} component={EditUser201910} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    {/* 記録機能 */}
    <AdminRoute exact path="/record/operations" component={Operations} />
    <AdminRoute
      exact
      path="/record/operations/:year/:month"
      component={Operations}
    />
    <AdminRoute
      exact
      path="/record/users_summary"
      component={RecordUsersSummary}
    />

    {/* 利用者ごと > 一括印刷・支援記録 */}
    <AdminRoute
      key="RecordUsersSummarySupportPrint"
      exact
      path="/record/print/users_summary_support/:year/:month"
      component={RecordUsersSummarySupportPrint}
    />
    {/* 個別支援計画 */}
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/new"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId/copy"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId"
      component={SupportPlan}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support_plan)/:year?/:month?"
      component={SupportPlanList}
    />
    <AdminRoute
      key="SupportPlanRecordPrint"
      exact
      path="/record/print/:uifId/support_plan/:supportPlanId"
      component={RecordSupportPlanPrint}
    />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY_201910} component={Facility201910} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    <AdminRoute path={URL.FACILITY} component={Facility} />
    {/* 記録の項目設定 */}
    <AdminRoute
      key="RecordSetting"
      path={URL.RECORD_SETTING}
      exact
      component={RecordSetting}
    />
    {/* 初期設定情報 */}
    <AdminRoute path={URL.INITIAL} component={InitialData} />
    {/* 職員情報 */}
    <AdminRoute key="Staffs" path="/staffs" exact component={Staffs} />
    {/* 印刷・業務日誌 */}
    <AdminRoute
      key="OperationsRecordPrint"
      exact
      path="/record/print/operations/:year/:month"
      component={OperationsRecordPrint}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support/:year?/:month?"
      component={UserDetail}
    />
    <AdminRoute
      key="SupportRecordPrint"
      exact
      path="/record/print/:uifId/support/:year/:month"
      component={SupportRecordPrint}
    />
    {/* 記録機能 */}
    <AdminRoute exact path="/record/daily/:yyyymmdd?" component={DailyRecord} />
    {/* 印刷・日々の記録 */}
    <AdminRoute
      key="DailyRecordPrint"
      exact
      path="/record/print/daily/:yyyymmdd"
      component={DailyRecordCustomRecords}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);

export default SHISETSUNYUSHORoutes;
