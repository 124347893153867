import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g id="Header_x2F_BG" display="none">
        <rect
          x="-94"
          y="-138"
          display="inline"
          fill="#F1F1F2"
          width="520"
          height="520"
        />
      </g>
      <g id="Bounding_Boxes">
        <g id="ui_x5F_spec_x5F_header_copy_3" />
        <path fill="none" d="M0,0h24v24H0V0z" />
      </g>
      <g id="Rounded" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_5" display="inline" />
        <path
          display="inline"
          d="M19,3h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14
      c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,3,12,3z M13,17H8
      c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h5c0.55,0,1,0.45,1,1v0C14,16.55,13.55,17,13,17z M16,13H8c-0.55,0-1-0.45-1-1v0
      c0-0.55,0.45-1,1-1h8c0.55,0,1,0.45,1,1v0C17,12.55,16.55,13,16,13z M16,9H8C7.45,9,7,8.55,7,8v0c0-0.55,0.45-1,1-1h8
      c0.55,0,1,0.45,1,1v0C17,8.55,16.55,9,16,9z"
        />
      </g>
      <g id="Sharp" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_4" display="inline" />
        <path
          display="inline"
          d="M21,3h-6.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H3v18h18V3z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1
      s-1-0.45-1-1S11.45,3,12,3z M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z"
        />
      </g>
      <g id="Outline">
        <g id="ui_x5F_spec_x5F_header" />
        <g>
          <rect x="7" y="15" width="7" height="2" />
          <rect x="7" y="11" width="10" height="2" />
          <rect x="7" y="7" width="10" height="2" />
          <path
            d="M19,3L19,3h-4.18C14.4,1.84,13.3,1,12,1c-1.3,0-2.4,0.84-2.82,2H5h0C4.86,3,4.73,3.01,4.6,3.04
        C4.21,3.12,3.86,3.32,3.59,3.59c-0.18,0.18-0.33,0.4-0.43,0.64C3.06,4.46,3,4.72,3,5v14c0,0.27,0.06,0.54,0.16,0.78
        c0.1,0.24,0.25,0.45,0.43,0.64c0.27,0.27,0.62,0.47,1.01,0.55C4.73,20.99,4.86,21,5,21h0h14h0c1.1,0,2-0.9,2-2V5
        C21,3.9,20.1,3,19,3z M12,2.75c0.41,0,0.75,0.34,0.75,0.75c0,0.41-0.34,0.75-0.75,0.75c-0.41,0-0.75-0.34-0.75-0.75
        C11.25,3.09,11.59,2.75,12,2.75z M19,19H5V5h14V19z"
          />
        </g>
      </g>
      <g id="Duotone" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_2" display="inline" />
        <g display="inline">
          <path
            opacity="0.3"
            d="M5,5v14h14V5H5z M14,17H7v-2h7V17z M17,13H7v-2h10V13z M17,9H7V7h10V9z"
          />
          <g>
            <rect x="7" y="15" width="7" height="2" />
            <rect x="7" y="11" width="10" height="2" />
            <rect x="7" y="7" width="10" height="2" />
            <path
              d="M19,3L19,3h-4.18C14.4,1.84,13.3,1,12,1c-1.3,0-2.4,0.84-2.82,2H5C4.86,3,4.73,3.01,4.6,3.04
          C4.21,3.12,3.86,3.32,3.59,3.59c-0.18,0.18-0.33,0.4-0.43,0.64S3,4.72,3,5v14c0,0.27,0.06,0.54,0.16,0.78
          c0.1,0.24,0.25,0.45,0.43,0.64c0.27,0.27,0.62,0.47,1.01,0.55C4.73,20.99,4.86,21,5,21h14h0c1.1,0,2-0.9,2-2V5
          C21,3.9,20.1,3,19,3z M12,2.75c0.41,0,0.75,0.34,0.75,0.75c0,0.41-0.34,0.75-0.75,0.75c-0.41,0-0.75-0.34-0.75-0.75
          C11.25,3.09,11.59,2.75,12,2.75z M19,19H5V5h14V19z"
            />
          </g>
        </g>
      </g>
      <g id="Fill" display="none">
        <g id="ui_x5F_spec_x5F_header_copy" display="inline" />
        <path
          display="inline"
          d="M19,3h-4.18C14.4,1.84,13.3,1,12,1S9.6,1.84,9.18,3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h14
      c1.1,0,2-0.9,2-2V5C21,3.9,20.1,3,19,3z M12,3c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S11.45,3,12,3z M14,17H7v-2h7V17z M17,13
      H7v-2h10V13z M17,9H7V7h10V9z"
        />
      </g>
      <g id="nyt_x5F_exporter_x5F_info" display="none">
        <g display="inline">
          <path
            d="M-2.29,7l0.83,1.25c0.22,0.32,0.4,0.62,0.59,0.95h0.04c0.19-0.35,0.38-0.65,0.58-0.96L0.55,7h1.14L-0.29,9.8l2.04,3h-1.2
        l-0.85-1.31c-0.23-0.34-0.42-0.66-0.62-1.01h-0.02c-0.19,0.35-0.4,0.66-0.61,1.01L-2.4,12.8h-1.16l2.06-2.96L-3.46,7H-2.29z"
          />
          <path
            d="M2.77,8.89c0-0.74-0.02-1.34-0.05-1.9h0.95l0.05,1h0.02c0.43-0.71,1.12-1.13,2.06-1.13c1.4,0,2.46,1.19,2.46,2.95
        c0,2.09-1.27,3.12-2.64,3.12c-0.77,0-1.44-0.34-1.79-0.91H3.82v3.15H2.77V8.89z M3.82,10.44c0,0.16,0.02,0.3,0.05,0.43
        c0.19,0.73,0.83,1.24,1.58,1.24c1.12,0,1.76-0.91,1.76-2.24C7.21,8.7,6.6,7.7,5.49,7.7c-0.72,0-1.39,0.52-1.6,1.31
        C3.85,9.14,3.82,9.3,3.82,9.44V10.44z"
          />
          <path
            d="M14.86,9.85c0,2.15-1.49,3.08-2.89,3.08c-1.57,0-2.78-1.15-2.78-2.99c0-1.94,1.27-3.08,2.88-3.08
        C13.73,6.86,14.86,8.07,14.86,9.85z M10.25,9.91c0,1.27,0.73,2.23,1.76,2.23c1.01,0,1.76-0.95,1.76-2.25
        c0-0.98-0.49-2.23-1.74-2.23C10.79,7.65,10.25,8.81,10.25,9.91z"
          />
          <path
            d="M16.19,8.81c0-0.68-0.01-1.27-0.05-1.81h0.92l0.04,1.14h0.05c0.26-0.78,0.9-1.27,1.61-1.27c0.12,0,0.2,0.01,0.3,0.04v1
        c-0.11-0.02-0.22-0.04-0.36-0.04c-0.74,0-1.27,0.56-1.42,1.36c-0.02,0.14-0.05,0.31-0.05,0.49v3.09h-1.04V8.81z"
          />
          <path
            d="M21.67,5.33V7h1.51v0.8h-1.51v3.13c0,0.72,0.2,1.13,0.79,1.13c0.28,0,0.48-0.04,0.61-0.07l0.05,0.79
        c-0.2,0.08-0.53,0.14-0.94,0.14c-0.49,0-0.89-0.16-1.14-0.44c-0.3-0.31-0.41-0.83-0.41-1.51V7.8h-0.9V7h0.9V5.6L21.67,5.33z"
          />
          <path d="M29.5,13.7v0.6h-6v-0.6H29.5z" />
          <path
            d="M1.49,24.49c0.02,1.43,0.94,2.01,1.99,2.01c0.76,0,1.21-0.13,1.61-0.3l0.18,0.76c-0.37,0.17-1.01,0.36-1.93,0.36
        c-1.79,0-2.85-1.18-2.85-2.93s1.03-3.13,2.72-3.13c1.9,0,2.4,1.67,2.4,2.73c0,0.22-0.02,0.38-0.04,0.49H1.49z M4.58,23.73
        c0.01-0.67-0.28-1.71-1.46-1.71c-1.07,0-1.54,0.98-1.62,1.71H4.58z"
          />
          <path
            d="M7.02,27.2v-5H6.21v-0.8h0.82v-0.28c0-0.82,0.18-1.56,0.67-2.03c0.4-0.38,0.92-0.54,1.42-0.54c0.37,0,0.7,0.08,0.9,0.17
        l-0.14,0.82c-0.16-0.07-0.37-0.13-0.67-0.13c-0.9,0-1.13,0.79-1.13,1.68v0.31h1.4v0.8h-1.4v5H7.02z"
          />
          <path
            d="M10.17,26.12c0.31,0.2,0.86,0.42,1.39,0.42c0.77,0,1.13-0.38,1.13-0.86c0-0.5-0.3-0.78-1.08-1.07
        c-1.04-0.37-1.54-0.95-1.54-1.64c0-0.94,0.76-1.7,2-1.7c0.59,0,1.1,0.17,1.43,0.36l-0.26,0.77c-0.23-0.14-0.65-0.34-1.19-0.34
        c-0.62,0-0.97,0.36-0.97,0.79c0,0.48,0.35,0.7,1.1,0.98c1.01,0.38,1.52,0.89,1.52,1.75c0,1.02-0.79,1.74-2.17,1.74
        c-0.64,0-1.22-0.16-1.63-0.4L10.17,26.12z"
          />
          <path d="M20.54,24.35l-5.56,2.85v-0.83l4.73-2.35V24l-4.73-2.35v-0.83l5.56,2.85V24.35z" />
        </g>
      </g>
    </SvgIcon>
  );
};
