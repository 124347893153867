import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import { Theme } from "@material-ui/core/styles/createMuiTheme";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";

const styles = ({ typography }: Theme): StyleRules =>
  createStyles({
    paper: {
      margin: "16px",
      maxWidth: "328px"
    },
    title: {
      fontSize: typography.h6.fontSize,
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: 1.6,
      padding: "16px 24px 24px"
    },
    content: {
      color: "rgba(0, 0, 0, 0.6)",
      padding: "0 24px 8px"
    },
    action: {
      margin: "16px 24px 0",
      justifyContent: "center"
    },
    button: {
      height: "40px"
    }
  });

type Props = WithStyles<typeof styles> & {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  classes?: ClassNameMap;
};

class NavigationTransitionDialogCore extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.props.isOpen}
        onClose={this.props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: this.props.classes.paper }}
      >
        <DialogTitle className={classes.title} disableTypography>
          変更内容が保存されていません
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText>
            データを保存する前にこのページから
            <br />
            移動すると変更内容は失われます。
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button
            onClick={this.props.onConfirm}
            className={classes.button}
            color="secondary"
          >
            保存せずに移動する
          </Button>
        </DialogActions>
        <DialogActions className={classes.action} style={{ marginBottom: 16 }}>
          <Button
            onClick={this.props.onCancel}
            className={classes.button}
            color="secondary"
            autoFocus
          >
            このページに留まる
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export const NavigationTransitionDialog = withStyles(styles)(
  NavigationTransitionDialogCore
);
