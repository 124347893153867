import * as types from "./types";
import { ResponseErrorState } from "@stores/ui/responseError/types";
import { GetServiceDeliveryDaily } from "@api/requests/serviceDelivery/getServiceDeliveryDaily";
import { GetServiceDeliveryMonthly } from "@api/requests/serviceDelivery/getServiceDeliveryMonthly";
import { ServiceDeliveryUserType } from "./types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchDailyRecordStarted = () =>
  ({ type: types.FETCH_DAILY_RECORD_STARTED } as const);
export const fetchDailyRecordSuccess = (res: GetServiceDeliveryDaily["data"]) =>
  ({ type: types.FETCH_DAILY_RECORD_SUCCESS, payload: res } as const);
export const fetchDailyRecordFailed = (err: { error: ResponseErrorState }) =>
  ({ type: types.FETCH_DAILY_RECORD_FAILED, error: err } as const);
export const fetchMonthlyRecordStarted = () =>
  ({ type: types.FETCH_MONTHLY_RECORD_STARTED } as const);
export const fetchMonthlyRecordSuccess = (
  res: GetServiceDeliveryMonthly["data"]
) => ({ type: types.FETCH_MONTHLY_RECORD_SUCCESS, payload: res } as const);
export const fetchMonthlyRecordFailed = (err: { error: ResponseErrorState }) =>
  ({ type: types.FETCH_MONTHLY_RECORD_FAILED, error: err } as const);
export const fetchDetailsRecordStarted = () =>
  ({ type: types.FETCH_DETAILS_RECORD_STARTED } as const);
export const fetchDetailsRecordSuccess = (
  res: types.ServiceDeliveryState["detailsRecord"]
) => ({ type: types.FETCH_DETAILS_RECORD_SUCCESS, payload: res } as const);
export const fetchDetailsRecordFailed = (err: { error: ResponseErrorState }) =>
  ({ type: types.FETCH_DETAILS_RECORD_FAILED, error: err } as const);
export const postRecordDetailStarted = () =>
  ({ type: types.POST_RECORD_DETAIL_STARTED } as const);
export const postRecordDetailSuccess = () =>
  ({ type: types.POST_RECORD_DETAIL_SUCCESS } as const);
export const postRecordDetailFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.POST_RECORD_DETAIL_FAILED, error: err } as const);
export const fetchPrintRecordStarted = () =>
  ({ type: types.FETCH_PRINT_RECORD_STARTED } as const);
export const fetchPrintRecordSuccess = (
  res: types.ServiceDeliveryState["print"]
) => ({ type: types.FETCH_PRINT_RECORD_SUCCESS, payload: res } as const);
export const fetchPrintRecordFailed = (err: { error: ResponseErrorState }) =>
  ({ type: types.FETCH_PRINT_RECORD_FAILED, error: err } as const);
export const deleteStarted = () => ({ type: types.DELETE_STARTED } as const);
export const deleteSuccess = () => ({ type: types.DELETE_SUCCESS } as const);
export const deleteFailed = (err: {
  error: { response: ResponseErrorState };
}) => ({ type: types.DELETE_FAILED, error: err } as const);
export const clearRecordDetailState = () =>
  ({ type: types.CLEAR_RECORD_DETAIL_STATE } as const);
export const clearRecordPrintState = () =>
  ({ type: types.CLEAR_RECORD_PRINT_STATE } as const);
export const fetchUserStarted = () =>
  ({ type: types.FETCH_USER_STARTED } as const);
export const fetchUserSuccess = (res: ServiceDeliveryUserType) =>
  ({ type: types.FETCH_USER_SUCCESS, payload: res } as const);
export const fetchUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_USER_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchDailyRecordStarted>
  | ReturnType<typeof fetchDailyRecordSuccess>
  | ReturnType<typeof fetchDailyRecordFailed>
  | ReturnType<typeof fetchMonthlyRecordStarted>
  | ReturnType<typeof fetchMonthlyRecordSuccess>
  | ReturnType<typeof fetchMonthlyRecordFailed>
  | ReturnType<typeof fetchDetailsRecordStarted>
  | ReturnType<typeof fetchDetailsRecordSuccess>
  | ReturnType<typeof fetchDetailsRecordFailed>
  | ReturnType<typeof postRecordDetailStarted>
  | ReturnType<typeof postRecordDetailSuccess>
  | ReturnType<typeof postRecordDetailFailed>
  | ReturnType<typeof deleteStarted>
  | ReturnType<typeof deleteSuccess>
  | ReturnType<typeof deleteFailed>
  | ReturnType<typeof clearRecordDetailState>
  | ReturnType<typeof clearRecordPrintState>
  | ReturnType<typeof fetchPrintRecordStarted>
  | ReturnType<typeof fetchPrintRecordSuccess>
  | ReturnType<typeof fetchPrintRecordFailed>
  | ReturnType<typeof fetchUserStarted>
  | ReturnType<typeof fetchUserSuccess>
  | ReturnType<typeof fetchUserFailed>;
