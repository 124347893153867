import * as types from "./types";
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";

export const setCurrentUsers = (users: UsersInFacilityState["users"]) =>
  ({ type: types.SET_USERS, payload: users } as const);
export const setSearchConditions = (searchConditionsIds: number[]) =>
  ({
    type: types.SET_SEARCH_CONDITIONS,
    payload: searchConditionsIds
  } as const);
export const setCurrentPage = (currentPage: number) =>
  ({
    type: types.SET_CURRENT_PAGE,
    payload: currentPage
  } as const);
export const setIsRecord = (isRecord: number) =>
  ({
    type: types.SET_IS_RECORD,
    payload: isRecord
  } as const);
export const unsetSearchConditions = () =>
  ({ type: types.UNSET_SEARCH_CONDITIONS } as const);

export type ActionTypes =
  | ReturnType<typeof setCurrentUsers>
  | ReturnType<typeof setSearchConditions>
  | ReturnType<typeof setCurrentPage>
  | ReturnType<typeof setIsRecord>
  | ReturnType<typeof unsetSearchConditions>;
