import * as React from "react";
import { StaffState } from "@stores/domain/staff/types";
import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { OperationState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import { SimplePaper } from "@components/organisms/mgr/common/record/SimplePaper";
import { EditType } from "@components/pages/mgr/Cseg/record/operationAndSupports/DailyRecord";
import { Formik, FormikActions, FormikProps } from "formik";
import {
  initialOperationValue,
  OperationRecordFormValues
} from "@initialize/mgr/Cseg/record/dailyRecord/initialValues";
import { operationValidation } from "@initialize/mgr/Cseg/record/dailyRecord/validation";
import { SnackbarParams } from "@stores/ui/type";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import deepEqual from "fast-deep-equal";
import { toEffectiveObject } from "@utils/object";
import { dateInYYYYMMDDFormat } from "@utils/date";

const styles = (): StyleRules =>
  createStyles({
    operationRecord: {
      marginTop: "25px"
    },
    buttons: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    }
  });

type StateProps = {
  staffs: StaffState;
};

type DispatchProps = {
  stopHistory: (flag: boolean) => void;
  postOperation: (
    targetDate: string,
    formValues: OperationRecordFormValues,
    initialValues: OperationRecordFormValues
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  fetchOperation: (yyyymmdd: string) => void;
};

type OwnProps = {
  operation: OperationState;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setEditType: React.Dispatch<React.SetStateAction<EditType>>;
  yyyymmdd: string;
};
type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const OperationRecordCore = (props: Props): JSX.Element => {
  const {
    classes,
    operation,
    isEditing,
    setIsEditing,
    setEditType,
    yyyymmdd,
    stopHistory,
    staffs,
    showSnackbar,
    postOperation,
    fetchOperation
  } = props;
  const [formValues, setFormValues] = React.useState(
    initialOperationValue(operation)
  );

  React.useEffect(() => {
    setFormValues(initialOperationValue(operation));
  }, [operation]);

  const sharedOperationProps = [
    {
      name: "operation_in_the_morning",
      label: "業務内容（午前）",
      value: (operation && operation.operation_in_the_morning) || ""
    },
    {
      name: "operation_in_the_afternoon",
      label: "業務内容（午後）",
      value: (operation && operation.operation_in_the_afternoon) || ""
    },
    {
      name: "other_comment",
      label: "その他",
      value: (operation && operation.other_comment) || ""
    }
  ];

  const onClickEdit = (): void => {
    setIsEditing(true);
    setEditType("operation");
  };

  const onClickCancel = (
    formikProps: FormikProps<OperationRecordFormValues>
  ): void => {
    setIsEditing(false);
    setEditType("");
    formikProps.setValues(formValues);
    stopHistory(false);
  };

  // 記録者
  const staffOptions = generateSelectFieldItems(
    staffs.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  const staffNames = operation
    ? operation.staffs.map((staff) => ({
        id: staff.id,
        name: staff.snapshot_name
      }))
    : [];

  const mergedStaffOptions = generateMergedStaffOptions(
    staffOptions,
    staffNames
  );

  const confirmDiscardFormChanges = (
    nextValues: OperationRecordFormValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    stopHistory(hasChange);
  };

  const validate = (values: OperationRecordFormValues): void | object => {
    const validationResult = operationValidation(values);
    const error = toEffectiveObject(validationResult);
    confirmDiscardFormChanges(values);
    return error;
  };

  const submitError = (): void => {
    showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onSubmit = async (
    values: OperationRecordFormValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postOperation(yyyymmdd, values, formValues)
      .then(() => {
        stopHistory(false);
        const dateParam = yyyymmdd || dateInYYYYMMDDFormat();
        fetchOperation(dateParam);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsEditing(false);
        setEditType("");
      });
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const operationRecordButtons = isEditing ? (
          <>
            <KnowbeButton
              kind="outlineWhite"
              onClick={(): void => onClickCancel(formikProps)}
            >
              キャンセル
            </KnowbeButton>
            <FormikSubmitButton
              buttonName="保存する"
              formikProps={formikProps}
              errorAction={submitError}
            />
          </>
        ) : (
          <KnowbeButton onClick={onClickEdit}>編集</KnowbeButton>
        );

        return (
          <SimplePaper title="業務日誌" button={operationRecordButtons}>
            <div className={classes.operationRecord}>
              {sharedOperationProps.map((fieldProps, index) => {
                const key = `RecordSupportTableField-${index}`;
                return (
                  <RecordSupportTableField
                    key={key}
                    type="text"
                    defaultValue=""
                    placeholder=""
                    isEditing={isEditing}
                    isExtendsMarginBottom
                    {...fieldProps}
                  />
                );
              })}
              <RecordSupportTableField
                type="multiple"
                name="staffs.itemIdList"
                label="記録者"
                defaultValue=""
                value={
                  staffNames
                    ? staffNames.map((staff) => staff.name).join("、")
                    : ""
                }
                options={[{ categoryName: "", items: mergedStaffOptions }]}
                placeholder="選択してください"
                emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                isEditing={isEditing}
                isExtendsMarginBottom
              />
            </div>
          </SimplePaper>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  staffs: state.staff
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, Cseg } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  return {
    stopHistory: uiDispatches.stopHistory,
    postOperation: (targetDate, formValues, initialValues): Promise<void> => {
      return Cseg.operationsAndSupportsDispatcher(dispatch).postOperation(
        targetDate,
        formValues,
        initialValues
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    fetchOperation: (yyyymmdd: string): void => {
      Cseg.operationsAndSupportsDispatcher(dispatch).fetchOperation(yyyymmdd);
    }
  };
};

export const OperationRecord = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OperationRecordCore));
