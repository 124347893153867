import * as types from "./types";
import { GetBasicInfoListResponse } from "@api/requests/basicInfo/getBasicInfoList";
import { GetBasicInfoResponse } from "@api/requests/basicInfo/getBasicInfo";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchBasicInfoListStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_STARTED } as const);
export const fetchBasicInfoListSuccess = (
  res: GetBasicInfoListResponse["data"]
) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_SUCCESS,
    payload: res
  } as const);
export const fetchBasicInfoListFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_LIST_FAILED,
    error: err
  } as const);

export const fetchBasicInfoStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_STARTED } as const);
export const fetchBasicInfoSuccess = (res: GetBasicInfoResponse["data"]) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_SUCCESS,
    payload: res
  } as const);
export const fetchBasicInfoFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_KEIKAKUSODAN_BASIC_INFO_FAILED,
    error: err
  } as const);

export const postBasicInfoStarted = () =>
  ({ type: types.POST_KEIKAKUSODAN_BASIC_INFO_STARTED } as const);
export const postBasicInfoSuccess = () =>
  ({ type: types.POST_KEIKAKUSODAN_BASIC_INFO_SUCCESS } as const);
export const postBasicInfoFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KEIKAKUSODAN_BASIC_INFO_FAILED, error: err } as const);
export const unsetBasicInfo = () =>
  ({ type: types.UNSET_KEIKAKUSODAN_BASIC_INFO } as const);

export const deleteBasicInfoStarted = () =>
  ({ type: types.DELETE_KEIKAKUSODAN_BASIC_INFO_STARTED } as const);
export const deleteBasicInfoSuccess = () =>
  ({ type: types.DELETE_KEIKAKUSODAN_BASIC_INFO_SUCCESS } as const);
export const deleteBasicInfoFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_KEIKAKUSODAN_BASIC_INFO_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchBasicInfoListStarted>
  | ReturnType<typeof fetchBasicInfoListSuccess>
  | ReturnType<typeof fetchBasicInfoListFailed>
  | ReturnType<typeof fetchBasicInfoStarted>
  | ReturnType<typeof fetchBasicInfoSuccess>
  | ReturnType<typeof fetchBasicInfoFailed>
  | ReturnType<typeof postBasicInfoStarted>
  | ReturnType<typeof postBasicInfoSuccess>
  | ReturnType<typeof postBasicInfoFailed>
  | ReturnType<typeof unsetBasicInfo>
  | ReturnType<typeof deleteBasicInfoStarted>
  | ReturnType<typeof deleteBasicInfoSuccess>
  | ReturnType<typeof deleteBasicInfoFailed>;
