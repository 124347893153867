/**
 * ActionNames
 */

export const FETCH_STARTED = "SHISETSUNYUSHO/FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "SHISETSUNYUSHO/FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "SHISETSUNYUSHO/FACILITY/FETCH_FAILED";
export const POST_STARTED = "SHISETSUNYUSHO/FACILITY/POST_STARTED";
export const POST_SUCCESS = "SHISETSUNYUSHO/FACILITY/POST_SUCCESS";
export const POST_FAILED = "SHISETSUNYUSHO/FACILITY/POST_FAILED";

/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  capacity: string;
  multiFunctionOfficeFlg: boolean;
  masterSubordinateFlg: boolean;
  isMasterRadioValue: string;
  allCapacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
  availableFood: boolean;
  foodExpenses: number;
  foodExpensesBreakfast: number;
  foodExpensesLunch: number;
  foodExpensesSupper: number;
  foodExpensesDay: number;
  utility: number;
  utilityCosts: number;
  operatingUnitFlag: boolean;

  // SubtractionItemSection
  originLocalGovFlg: boolean;
  nutritionistPlacement: number;
  lackOfSupporterFlg: boolean;
  dateStartLackOfSupporter: string;
  informationDisclosureNotReportedFlg: boolean;
  informationDisclosureNotReportedFrom: string;
  informationDisclosureNotReportedTo: string;
  abusePreventionMeasuresNotImplementedFlg: boolean;
  abusePreventionMeasuresNotImplementedFrom: string;
  abusePreventionMeasuresNotImplementedTo: string;
  bodyRestrictedStillFlg: boolean;
  bodyRestrictedStillFrom: string;
  bodyRestrictedStillTo: string;
  workContinuationNotPlanningFlg: boolean;
  workContinuationNotPlanningFrom: string;
  workContinuationNotPlanningTo: string;

  // AdditionItemSection
  nighttimePlacement: number;
  nighttimeNurseCount: string;
  seeHearTeam202404: string;
  seeHearTeam202404From: string;
  seeHearTeam202404To: string;
  regionalLifeTransition: boolean;
  staffTreatmentSystemType: string;
  staffTreatmentSpecificSystemType: string;
  seriousDisability: number;
  betterSupporterConditionBaseUpFlg: boolean;
  oralHygieneManagementSystemFlg: number;
  specificBetterSupporterCondition202404: string | null;
  higherBrainDysfunctionPersonsSupportFlg: boolean;
  higherBrainDysfunctionPersonsSupportFrom?: string;
  higherBrainDysfunctionPersonsSupportTo?: string;
  improvingInfectionControl1Flg: boolean;
  improvingInfectionControl1From: string;
  improvingInfectionControl1To: string;
  improvingInfectionControl2Flg: boolean;
  improvingInfectionControl2From: string;
  improvingInfectionControl2To: string;
  regionalTransitionSupportFlg: boolean;
  decreaseUsersCount: string;
  regionalTransitionSupportFrom: string;
  regionalTransitionSupportTo: string;

  // units
  units: {
    id: number | null;
    unit_name: string;
    night_support_type: string;
    ave_users_last_fiscal_year: string;
    is_deleted: boolean;
  }[];
};
