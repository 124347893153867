import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityState as IABFacilityState } from "@stores/domain/mgr/IAB/facility/types";
import { FacilityState as SEIKATSUKAIGOFacilityState } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/types";
import { FacilityState as JIRITSUKUNRENSEIKATSUFacilityState } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { OperationsState } from "@stores/domain/operations/types";
import dispatches from "@stores/dispatches";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import DailyPrint from "@components/organisms/record/print/DailyPrint";
import { FacilityType } from "@/constants/variables";

type StateProps = {
  user: UserState;
  facility:
    | IABFacilityState
    | SEIKATSUKAIGOFacilityState
    | JIRITSUKUNRENSEIKATSUFacilityState;
  dailyRecord: OperationsState["dailyRecord"];
};
type DispatchProps = {
  requestOperationSupportRecordData: (selectedDate: string) => void;
  getFacilityData: (facility_type: FacilityType) => void;
};
type Props = StateProps &
  DispatchProps &
  RouteComponentProps<{ yyyymmdd: string }>;

/**
 * 日々の記録のプリント画面
 */
const DailyRecordPrintCore = (props: Props): JSX.Element => {
  const targetDate = props.match.params.yyyymmdd;
  const query = props.location.search;
  const { user } = props;

  React.useEffect(() => {
    props.requestOperationSupportRecordData(targetDate);
    props.getFacilityData(user.facility_type);
  }, []);

  const facilityUserLabel = props.user.labels
    ? props.user.labels.facility_user
    : "利用者";

  return (
    <AdminTemplate pageName="日々の記録">
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <DailyPrint
          dailyRecord={props.dailyRecord}
          typeService={props.user.facility_type}
          facilityUserLabel={facilityUserLabel}
          selectedDate={targetDate}
          query={query}
          // 事業所情報_食事の状態
          isMeal={props.facility.mealSaservedServiceFlag}
          // 事業所情報_送迎の状態
          isTransfer={props.facility.transferServiceFlag}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const facility = (():
    | IABFacilityState
    | SEIKATSUKAIGOFacilityState
    | JIRITSUKUNRENSEIKATSUFacilityState => {
    switch (user.facility_type) {
      case FacilityType.SEIKATSUKAIGO:
        return state.SEIKATSUKAIGO.facility;
      case FacilityType.JIRITSUKUNRENSEIKATSU:
        return state.JIRITSUKUNRENSEIKATSU.facility;
      default:
        return state.IAB.facility;
    }
  })();
  return {
    user,
    facility,
    dailyRecord: state.operations.dailyRecord
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { operationsDispatcher } = dispatches;
  const operationsDispatches = operationsDispatcher(dispatch);

  const getFacilityData = (facility_type: FacilityType): Promise<void> => {
    switch (facility_type) {
      case FacilityType.SEIKATSUKAIGO:
        return dispatches.SEIKATSUKAIGO.facilityDispatcher(dispatch).fetch();
      case FacilityType.JIRITSUKUNRENSEIKATSU:
        return dispatches.JIRITSUKUNRENSEIKATSU.facilityDispatcher(
          dispatch
        ).fetch();
      default:
        return dispatches.IAB.facilityDispatcher(dispatch).fetch();
    }
  };
  return {
    requestOperationSupportRecordData: (selectedDate: string): Promise<void> =>
      operationsDispatches.fetchDailyRecord(selectedDate),
    getFacilityData
  };
};

export const DailyRecordPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyRecordPrintCore);
