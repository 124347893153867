import { FieldItem } from "@interfaces/ui/form";

export type Staff = {
  id: number;
  name: string;
  role?: string;
  license?: number;
  license_name?: string;
  facility_id: number;
  snapshot_name: string;
  snapshot_role?: string;
} | null;

/**
 * スナップショット名を含む初期値を取り出す
 * @param staff
 * @param staffOptions
 */
export const getStaffWithSnap = (
  staff: Staff,
  staffOptions: FieldItem[]
): string | null => {
  let authorId = null;
  if (staffOptions && staff) {
    const authorIsDelete = staffOptions.every(
      (option) => staff && option.value !== String(staff.id)
    );
    authorId =
      staff.name !== staff.snapshot_name && !authorIsDelete ? 0 : staff.id;
  }
  return authorId !== null ? `${authorId}` : null;
};
