import * as React from "react";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup, { RadioGroupProps } from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@components/molecules/FormLabel";
import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    errorMessage: {
      fontSize: 12,
      lineHeight: 1.6,
      letterSpacing: 0.6,
      color: "#f44336",
      marginBottom: 4
    }
  });

type OwnProps = {
  label: string;
  disabled?: boolean;
  options?: FieldItem[];
  tooltip?: React.ReactElement;
  chipsLabel?: React.ReactElement;
  labelTextClass?: string;
  formControlLabelClass?: React.CSSProperties;
  hasError?: boolean;
  errorMessage?: string;
};

export type MuiRadioButtonsProps = OwnProps & RadioGroupProps;

type RadioButtonsProps = MuiRadioButtonsProps & WithStyles<typeof styles>;

// ラジオボタン
const labelStyles = (): StyleRules =>
  createStyles({
    root: {
      padding: 4,
      margin: "0 8px"
    }
  });
const StyledRadio = withStyles(labelStyles)(Radio);

// ラジオボタンのラッパー
const radioGroupStyles = (): StyleRules =>
  createStyles({
    root: {
      margin: "12px 0 28px 16px"
    }
  });
const StyledRadioGroup = withStyles(radioGroupStyles)(RadioGroup);

const MuiCheckbox = ({
  label,
  options,
  tooltip,
  style,
  chipsLabel,
  labelTextClass,
  hasError,
  errorMessage,
  classes,
  formControlLabelClass,
  ...props
}: RadioButtonsProps): JSX.Element => {
  const labelTextClassName =
    labelTextClass && labelTextClass.length
      ? { label: labelTextClass }
      : undefined;
  const formControlLabelClassName = formControlLabelClass || {
    marginRight: "auto",
    paddingRight: "16px"
  };
  return (
    <FormControl>
      <FormLabel label={label} tooltip={tooltip} chipsLabel={chipsLabel} />
      {errorMessage && hasError && (
        <div className={classes.errorMessage}>{errorMessage}</div>
      )}
      <StyledRadioGroup {...props} style={style}>
        {options &&
          options.map((option, index) => {
            const labelKey = `${props.name}-${index}`;
            return (
              <FormControlLabel
                style={formControlLabelClassName}
                control={<StyledRadio />}
                key={labelKey}
                value={`${option.value}`}
                label={option.label}
                disabled={props.disabled || option.disabled}
                classes={labelTextClassName}
              />
            );
          })}
      </StyledRadioGroup>
    </FormControl>
  );
};

export default withStyles(styles)(MuiCheckbox);
