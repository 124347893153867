import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules,
  Theme
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import AuthTemplate from "@components/templates/AuthTemplate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { RouteComponentProps } from "react-router-dom";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    paper: {
      margin: "0 auto",
      maxWidth: 640,
      marginTop: 40,
      textAlign: "left"
    },
    header: {
      margin: 0,
      padding: "24px 32px 8px",
      borderBottom: "1px solid #cfd8dc",
      color: "#37474f",
      fontSize: 24,
      fontWeight: "normal",
      lineHeight: 32 / 24
    },
    wrapper: {
      paddingLeft: spacing.unit * 4,
      paddingRight: spacing.unit * 4,
      paddingBottom: 26
    },
    text: {
      fontSize: 16,
      lineHeight: 1.75,
      letterSpacing: 0.5,
      marginTop: 29,
      color: "rgba(0, 0, 0, 0.6)"
    },
    buttonWrapper: {
      paddingBottom: spacing.unit * 4
    },
    button: {
      display: "block",
      margin: "0 auto"
    }
  });

type Props = RouteComponentProps & WithStyles<typeof styles>;

/**
 * アカウント情報の管理
 */
const MobileCompleteCore = (props: Props): JSX.Element => {
  const { classes } = props;

  const onClickButton = (): void => {
    props.history.push("/");
  };

  return (
    <AuthTemplate>
      <Paper className={classes.paper}>
        <h1 className={classes.header}>モバイル画面アカウント作成 完了</h1>
        <div className={classes.wrapper}>
          <div className={classes.text}>
            モバイル画面のアカウント作成が完了しました。
            <br />
            モバイル端末で記録機能を利用する場合は、作成したアカウントでログインしてください。
          </div>
        </div>
        <div className={classes.buttonWrapper}>
          <KnowbeButton
            kind="text"
            onClick={onClickButton}
            className={classes.button}
          >
            管理画面に戻る
          </KnowbeButton>
        </div>
      </Paper>
    </AuthTemplate>
  );
};

export const MobileComplete = withStyles(styles)(MobileCompleteCore);
