import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * サービス担当者会議議事録の削除
 * @param uifId 事業所所属ユーザーのID
 * @param meetingRecordId 会議議事録のID
 */
export const deleteMeetingRecord = async (
  uifId: number,
  meetingRecordId: number
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/meeting_record/${meetingRecordId}`;
  return request.delete(url);
};
