import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { SupportPlanPrint } from "@components/organisms/record/print/SupportPlanPrint";
import { FacilityType } from "@constants/variables";
import SupportPlanAPrint from "@components/organisms/record/print/SupportPlanAPrint";
import { SupportPlanSHUROTEICHAKUPrint } from "@components/organisms/record/print/SupportPlanSHUROTEICHAKUPrint";
import { SupportPlanPrintHasMonitoring } from "@components/organisms/record/print/SupportPlanPrintHasMonitoring";
import { SupportPlanPrintGroupHome } from "@components/organisms/record/print/SupportPlanPrintGroupHome";

/**
 * 個別支援計画のプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    supportPlanId: string;
  }>;

const RecordSupportPlanPrintCore = (props: Props): JSX.Element => {
  const { uifId } = props.match.params;
  const { supportPlanId } = props.match.params;
  const query = props.location.search;
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // 種別間のパンくず名が統一されるまで分岐
  let beforePageName = "個別支援計画一覧";
  let currentPageName = "個別支援計画";
  if (
    props.userState.facility_type === FacilityType.JIRITSUKUNRENSEIKATSU ||
    props.userState.facility_type === FacilityType.SHUROTEICHAKU ||
    props.userState.facility_type === FacilityType.GROUP_HOME ||
    props.userState.facility_type === FacilityType.SEIKATSUKAIGO ||
    props.userState.facility_type === FacilityType.SHISETSUNYUSHO
  ) {
    beforePageName = "個別支援計画";
    currentPageName = "個別支援計画書";
  }
  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" },
    { pathName: beforePageName, path: `/record/${uifId}/support_plan` }
  ];

  const RenderPrint = (): JSX.Element => {
    switch (props.userState.facility_type) {
      case FacilityType.A:
        return (
          <SupportPlanAPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        );
      case FacilityType.SHUROTEICHAKU:
        return (
          <SupportPlanSHUROTEICHAKUPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        );
      case FacilityType.B:
      case FacilityType.IKOU:
        return (
          <SupportPlanPrintHasMonitoring
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        );
      case FacilityType.SEIKATSUKAIGO:
      case FacilityType.GROUP_HOME:
        return (
          <SupportPlanPrintGroupHome
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        );
      default:
        return (
          <SupportPlanPrint
            uifId={uifId}
            supportPlanId={supportPlanId}
            query={query}
          />
        );
    }
  };

  return (
    <AdminTemplate pageName={currentPageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const RecordSupportPlanPrint = connect(mapStateToProps)(
  RecordSupportPlanPrintCore
);
