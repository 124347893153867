import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";

import { StaffState } from "@stores/domain/staff/types";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelect from "@components/molecules/FormikSelect";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTextField from "@components/molecules/FormikTextField";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import MuiSwitch from "@components/molecules/MuiSwitch";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { RecordCarePlan } from "@interfaces/record/carePlan/carePlan";
import {
  FacilityType,
  DISABILITY_CLASS_LIST,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM,
  CARE_PLAN_SCHEDULES_CLASSIFY_HANDICAPPED_FLG
} from "@constants/variables";
import { CarePlanSchedules } from "@components/organisms/mgr/common/record/carePlan/CarePlanSchedules";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    con: {
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    flex: {
      display: "flex",
      justifyContent: "flex-start",
      "& > div+div": {
        marginLeft: 16
      },
      "& > div > div > div > div > p": {
        whiteSpace: "normal"
      },
      "& > div > div > div ": {
        whiteSpace: "nowrap"
      }
    },
    col: {
      display: "flex"
    },
    colItem: {
      width: "240px"
    },
    radio: {
      "&>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>label>span:first-child": {
        color: "#757575"
      },
      "&>div>div>label>span:last-child": {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    postal: {
      "&>div>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>div::before": {
        borderBottomStyle: "dotted"
      },
      "&>div>div>div p": {
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&>div>div>div>input": {
        "&::placeholder": {
          color: "rgba(0, 0, 0, 0.87)",
          opacity: 1
        }
      }
    },
    address: {
      display: "flex",
      "&>div": {
        width: "245px"
      },
      "&>div:nth-child(n+2)": {
        marginLeft: "16px"
      }
    },
    addressEdit: {
      "&>div>div": {
        width: "245px"
      }
    },
    tel: {
      width: "245px",
      "&>div": {
        width: "100%"
      }
    },
    switch: {
      pointerEvents: "none",
      "&>*": {
        cursor: "unset"
      }
    },
    switchOpacity: {
      opacity: 0.38
    },
    reason: {
      maxWidth: "588px"
    },
    cellStyle: {
      padding: spacing.unit * 2,
      borderBottom: "none"
    },
    noMessage: {
      padding: spacing.unit * 2,
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      letterSpacing: "0.4px",
      borderBottom: "solid  1px #e0e0e0"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT8: {
      marginTop: spacing.unit
    },
    ML16: {
      marginLeft: "16px"
    }
  });

type OwnProps = {
  initialValues: RecordCarePlan;
  isEditing: boolean;
  authorName: string;
  authorizerRole: string;
  staffOptionsAddSnapShot: FieldItem[];
  staffWithSnap: StaffState;
  cityLabelView: string;
  formikProps: FormikProps<RecordCarePlan>;
  commentFlagView: boolean;
  schedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  paramsId: string;
  paramsCarePlanId: string;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanFormCore = (props: Props): JSX.Element => {
  const {
    initialValues,
    isEditing,
    authorName,
    authorizerRole,
    staffOptionsAddSnapShot,
    staffWithSnap,
    cityLabelView,
    classes,
    formikProps,
    commentFlagView,
    schedules,
    paramsId,
    paramsCarePlanId,
    facilityType
  } = props;

  // スタイル
  const inputStyleWidth = "calc((100% - 16px*4)/5)";

  // 編集の時「内容」表示Flag
  const [isComment, setIsComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsComment(
      formikProps.values.housework_assistance_base_type_reason ===
        HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value
    );
  }, [formikProps]);

  // 役職
  const [roleValue, setRoleValue] = React.useState<string>(authorizerRole);
  React.useEffect(() => {
    if (
      formikProps.values.author !== null &&
      formikProps.values.author !== ""
    ) {
      const select = staffWithSnap.staffItems.find(
        (f) => f.staffItemId === Number(formikProps.values.author)
      );
      setRoleValue(select ? select.roleName : "");
    } else {
      setRoleValue("");
    }
  }, [formikProps]);

  // 障害児フラグ
  const [isHandicapped, setIsHandicapped] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsHandicapped(
      (facilityType !== FacilityType.KYOTAKUKAIGO &&
        facilityType !== FacilityType.KODOENGO) ||
        formikProps.values.classify_handicapped_flg ===
          CARE_PLAN_SCHEDULES_CLASSIFY_HANDICAPPED_FLG.OFF
    );
  }, [formikProps, facilityType]);

  // 契約支給量
  const payMonthsAgreedKYOTAKUKAIGO = [
    {
      name: "pay_months_agreed_physical_care",
      label: "身体介護",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value: initialValues.pay_months_agreed_physical_care
    },
    {
      name: "pay_months_agreed_outpatient_care_with_physical_care",
      label: "通院等介助（身体介護伴う）",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value: initialValues.pay_months_agreed_outpatient_care_with_physical_care
    },
    {
      name: "pay_months_agreed_housework_assistance",
      label: "家事援助",
      defaultValue: "-",
      placeholder: "0.75",
      endAdornmentLabel: "時間/月",
      value: initialValues.pay_months_agreed_housework_assistance
    },
    {
      name: "pay_months_agreed_outpatient_care",
      label: "通院等介助（身体介護伴わず）",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value: initialValues.pay_months_agreed_outpatient_care
    },
    {
      name: "pay_months_agreed_getting_on_and_off",
      label: "通院等乗降介助",
      defaultValue: "-",
      placeholder: "",
      maxLength: 3,
      endAdornmentLabel: "回/月",
      value: initialValues.pay_months_agreed_getting_on_and_off
    }
  ];
  const payMonthsAgreedJUDOHOMONKAIGO = [
    {
      name:
        "support_care_plans_judohomonkaigo.pay_months_agreed_severe_home_visit_care",
      label: "重度訪問介護（移動介護含む）",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value:
        initialValues.support_care_plans_judohomonkaigo
          .pay_months_agreed_severe_home_visit_care
    },
    {
      name:
        "support_care_plans_judohomonkaigo.pay_months_agreed_guide_severe_home_visit_care",
      label: "移動介護",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value:
        initialValues.support_care_plans_judohomonkaigo
          .pay_months_agreed_guide_severe_home_visit_care
    }
  ];
  const payMonthsAgreedDOKOENGO = [
    {
      name:
        "support_care_plans_dokoengo.pay_months_agreed_accompanying_support",
      label: "同行援護",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value:
        initialValues.support_care_plans_dokoengo
          .pay_months_agreed_accompanying_support
    }
  ];
  const payMonthsAgreedKODOENGO = [
    {
      name:
        "support_care_plans_kodoengo.pay_months_agreed_accompanying_support",
      label: "行動援護",
      defaultValue: "-",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月",
      value:
        initialValues.support_care_plans_kodoengo
          .pay_months_agreed_accompanying_support
    }
  ];
  const getPayMonthsAgreedList = (): {
    name: string;
    label: string;
    defaultValue: string;
    placeholder: string;
    maxLength?: number;
    endAdornmentLabel: string;
    value: string;
  }[] => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return payMonthsAgreedKYOTAKUKAIGO;
      case FacilityType.JUDOHOMONKAIGO:
        return payMonthsAgreedJUDOHOMONKAIGO;
      case FacilityType.DOKOENGO:
        return payMonthsAgreedDOKOENGO;
      case FacilityType.KODOENGO:
        return payMonthsAgreedKODOENGO;
      default:
        return payMonthsAgreedKYOTAKUKAIGO;
    }
  };

  return (
    <div className={classes.con}>
      <div className={classes.conCreateDate}>
        <CreateAndUpdateDate
          createdAt={initialValues.created_at}
          updatedAt={initialValues.updated_at}
        />
      </div>
      <RecordSelectDate
        name="target_date"
        label="計画作成日"
        required
        value={initialValues.target_date}
        isEditable={isEditing}
        addYearTo={1}
        overrideYearFrom={1989}
      />
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        本人情報
      </Typography>
      <Typography className={classes.MT24}>住所</Typography>
      <div className={ClassNames(classes.MT8, classes.ML16)}>
        {isEditing ? (
          <FormikPostalCode
            name="postal_code"
            label="郵便番号"
            maxLength={8}
            startAdornmentLabel="〒"
            style={{ maxWidth: "158px" }}
          />
        ) : (
          <div className={classes.postal}>
            <FormikTextField
              name="read_only_postal_code"
              label="郵便番号"
              placeholder="-"
              startAdornmentLabel="〒"
              style={{ maxWidth: "158px" }}
            />
          </div>
        )}
        {isEditing ? (
          <div className={classes.addressEdit}>
            <FormikAddress
              prefectureIdName="prefecture_name"
              cityIdName="city_id"
              formikProps={formikProps}
              showRegionType={false}
              showCityCode={false}
              prefectureName={initialValues.prefecture_name}
            />
          </div>
        ) : (
          <div className={classes.address}>
            <ReadonlyTextField
              label="都道府県"
              defaultValue="-"
              value={initialValues.prefecture_name}
            />
            <ReadonlyTextField
              label="市区町村"
              defaultValue="-"
              value={cityLabelView}
            />
          </div>
        )}

        <div className={!isEditing ? classes.MT32 : ""}>
          <RecordTextField
            name="address"
            label="市区町村以降の住所"
            labelType="default"
            defaultValue="-"
            placeholder=""
            value={initialValues.address}
            isEditable={isEditing}
            style={{ maxWidth: "766px" }}
            nonMultiline
          />
        </div>
      </div>
      <div className={ClassNames(classes.MT32, classes.tel)}>
        {isEditing ? (
          <FormikTextField
            name="tel"
            type="tel"
            label="電話番号"
            helperText="ハイフンなしで入力"
            maxLength={12}
          />
        ) : (
          <ReadonlyTextField
            label="電話番号"
            defaultValue="-"
            value={initialValues.tel}
          />
        )}
      </div>

      <div className={classes.MT32}>
        {isHandicapped && (
          <>
            {isEditing ? (
              <FormikRadioButtons
                name="classify_disability_support"
                label="障害支援区分"
                options={DISABILITY_CLASS_LIST}
              />
            ) : (
              <div className={classes.radio}>
                <MuiRadioButtons
                  name="read_only_classify_disability_support"
                  label="障害支援区分"
                  options={DISABILITY_CLASS_LIST}
                  value={initialValues.classify_disability_support}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Typography>受給者証</Typography>
      <div className={ClassNames(classes.MT8, classes.ML16)}>
        <RecordSelectDate
          name="date_pay_begin"
          label="支給決定開始日"
          value={initialValues.date_pay_begin}
          isEditable={isEditing}
          addYearTo={1}
          overrideYearFrom={1989}
        />
        <div className={classes.MT24}>
          <RecordSelectDate
            name="date_pay_end"
            label="支給決定終了日"
            value={initialValues.date_pay_end}
            isEditable={isEditing}
            addYearTo={5}
            overrideYearFrom={1989}
          />
        </div>
      </div>
      <div className={classes.MT32}>
        <RecordSelectDate
          name="date_begin_in_service"
          label="サービス提供開始日"
          value={initialValues.date_begin_in_service}
          isEditable={isEditing}
          addYearTo={1}
          overrideYearFrom={1989}
        />
      </div>

      <Typography className={classes.MT32}>契約支給量</Typography>
      <div className={ClassNames(classes.flex, classes.MT8, classes.ML16)}>
        {getPayMonthsAgreedList().map((item, index) => {
          const uniqueKey = `pay-months-agreed-${index}`;
          return (
            <RecordTextField
              {...item}
              key={uniqueKey}
              labelType="default"
              isEditable={isEditing}
              nonMultiline
              style={{ maxWidth: inputStyleWidth }}
            />
          );
        })}
      </div>
      {facilityType === FacilityType.KYOTAKUKAIGO && (
        <div className={classes.MT32}>
          {isEditing ? (
            <FormikSwitch
              name="housework_assistance_base_type"
              label="「家事援助中心型」算定の理由を記載する"
            >
              <FormikSelect
                name="housework_assistance_base_type_reason"
                label="算定理由"
                options={HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON}
                style={{ width: "588px" }}
              />
              {isComment && (
                <div className={classes.MT32}>
                  <FormikTextField
                    name="housework_assistance_base_type_comment"
                    label="内容"
                    size="fullSize"
                    multiline
                  />
                </div>
              )}
            </FormikSwitch>
          ) : (
            <MuiSwitch
              name="read_only_housework_assistance_base_type"
              label="「家事援助中心型」算定の理由を記載する"
              checked={initialValues.housework_assistance_base_type}
              className={classes.switchOpacity}
            >
              <div className={classes.reason}>
                <ReadonlyTextField
                  label="算定理由"
                  defaultValue="-"
                  value={
                    HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON[
                      initialValues.housework_assistance_base_type_reason
                    ].value === "0"
                      ? ""
                      : HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON[
                          initialValues.housework_assistance_base_type_reason
                        ].label
                  }
                />
              </div>
              {commentFlagView && (
                <div className={classes.MT32}>
                  <ReadonlyTextField
                    label="内容"
                    defaultValue="-"
                    value={initialValues.housework_assistance_base_type_comment}
                    multiline
                  />
                </div>
              )}
            </MuiSwitch>
          )}
        </div>
      )}
      <div className={classes.MT32}>
        <RecordTextField
          name="usage_other_facility"
          label="他事業所利用状況"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.usage_other_facility}
          isEditable={isEditing}
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        本人の希望と現状
      </Typography>
      <div className={classes.MT24}>
        <RecordTextField
          name="user_request"
          label="本人・家族の希望"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.user_request}
          isEditable={isEditing}
        />
      </div>
      <div className={classes.MT32}>
        <RecordTextField
          name="user_issue"
          label="本人の現状と課題"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.user_issue}
          isEditable={isEditing}
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        援助の目標・方向性
      </Typography>
      <div className={classes.MT24}>
        <RecordTextField
          name="goals_direction_assistance"
          label="援助の目標・方向性"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.goals_direction_assistance}
          isEditable={isEditing}
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        計画予定表
      </Typography>
      <div className={classes.MT32}>
        <CarePlanSchedules
          isNew={false}
          paramsId={paramsId}
          isEditing={isEditing}
          schedules={schedules}
          formikProps={formikProps}
          withOutAddBtn={!isEditing}
          paramsCarePlanId={paramsCarePlanId}
        />
      </div>
      <div className={classes.MT32}>
        <RecordTextField
          name="remarks"
          label="備考"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.remarks}
          isEditable={isEditing}
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      <div className={classes.MT24}>
        <RecordTextField
          name="staff_comment"
          label="職員コメント"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.staff_comment}
          isEditable={isEditing}
        />
      </div>
      <div className={ClassNames(classes.col, classes.MT32)}>
        <div className={classes.colItem}>
          <RecordSelect
            name="author"
            label="作成者"
            defaultValue="-"
            placeholder="選択してください"
            options={staffOptionsAddSnapShot}
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            value={authorName}
            isEditable={isEditing}
            isSelectablePlaceholder
          />
        </div>
        <div className={ClassNames(classes.colItem, classes.ML16)}>
          <ReadonlyTextField
            value={isEditing ? roleValue : authorizerRole}
            label="役職"
            defaultValue="-"
          />
        </div>
      </div>
    </div>
  );
};

export const CarePlanForm = withStyles(styles)(CarePlanFormCore);
