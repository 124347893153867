import { SelectDateValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * string (YYYY-MM-dd) => SelectDateValue
 */
export const dateToSelectDateValue = (date: string): SelectDateValue => {
  const year = date ? dateToLocalisedString(date, "YYYY") : "";
  const month = date ? dateToLocalisedString(date, "M") : "";
  const day = date ? dateToLocalisedString(date, "D") : "";
  return { year, month, day };
};
