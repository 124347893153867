import { reducerWithInitialState } from "typescript-fsa-reducers";

import * as action from "@stores/domain/report/action";
import {
  ReportState,
  Report,
  ReportTypeInterface,
  ReportPostDailyState
} from "@stores/domain/report/type";

const initialState: ReportState = {
  performanceDaily: {
    before: {
      targetDate: ""
    },
    after: {
      targetDate: ""
    }
  },
  reportDaily: {
    before: [],
    after: []
  },
  reportMonthly: {
    before: [],
    after: []
  }
};

const stateCreate = (
  state: ReportState,
  stateType: string,
  payload: {
    value: Report[
      | "nightSupportType"
      | "hospitalizationSupportType"
      | "getHomeSupportType"
      | "daytimeSupportType"
      | "medicalSupportType"
      | "lifeSupportFlg"
      | "homeCareFlg"
      | "peerSupportFlg"
      | "intensiveSupportFlg"
      | "intensiveSupportStartDate"
      | "infectiousDiseasesFacilityFlg"
      | "severeDisabilitySupportType"
      | "remarks"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  const reportState = state;
  reportState[payload.type].after[payload.key][stateType] = payload.value;
  if (stateType === "medicalSupportType") {
    reportState[payload.type].after[payload.key].sputumGuidanceFlg = "0";
  }
  return {
    ...reportState
  };
};

/**
 * statusTypeの変更時にremarks以外を初期化する
 * TODO: use constant
 */
const changeStatusTypeState = (
  state: ReportState,
  payload: {
    value: Report["statusType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  const reportState = state;
  reportState[payload.type].after[payload.key].statusType = payload.value;
  reportState[payload.type].after[payload.key].nightSupportType = "1";
  reportState[payload.type].after[payload.key].nightSupportTargetUsers = "0";
  reportState[payload.type].after[payload.key].hospitalizationSupportType = "1";
  reportState[payload.type].after[payload.key].getHomeSupportType = "1";
  reportState[payload.type].after[payload.key].daytimeSupportType = "1";
  reportState[payload.type].after[payload.key].medicalSupportType = "0";
  reportState[payload.type].after[payload.key].sputumGuidanceFlg = "0";
  reportState[payload.type].after[payload.key].lifeSupportFlg = "0";
  reportState[payload.type].after[payload.key].homeCareFlg = "0";
  reportState[payload.type].after[payload.key].peerSupportFlg = "0";
  reportState[payload.type].after[payload.key].retirementServiceFlg = "0";
  reportState[payload.type].after[payload.key].intensiveSupportFlg = "0";
  reportState[payload.type].after[payload.key].intensiveSupportStartDate = "";
  reportState[payload.type].after[payload.key].infectiousDiseasesFacilityFlg =
    "0";
  reportState[payload.type].after[payload.key].severeDisabilitySupportType =
    "0";
  return {
    ...reportState
  };
};

/** **********
 * api setter
 *********** */
const fetchDaily = (
  state: ReportState,
  { result }: { result: ReportState }
): ReportState => {
  return {
    ...state,
    performanceDaily: result.performanceDaily,
    reportDaily: result.reportDaily
  };
};

const fetchMonthly = (
  state: ReportState,
  { result }: { result: ReportState }
): ReportState => {
  return {
    ...state,
    reportMonthly: result.reportMonthly
  };
};

const postDaily = (
  state: ReportState,
  { result }: { result: ReportPostDailyState }
): ReportState => {
  const before: Report[] = state.reportDaily.after.map((report) => {
    return { ...report };
  });
  return {
    ...state,
    performanceDaily: {
      ...state.performanceDaily,
      before: { ...result.performanceDaily }
    },
    reportDaily: {
      ...state.reportDaily,
      before
    }
  };
};

const postMonthly = (state: ReportState): ReportState => {
  const before: Report[] = state.reportMonthly.after.map((report) => {
    return { ...report };
  });
  return {
    ...state,
    reportMonthly: {
      ...state.reportMonthly,
      before
    }
  };
};

/** **********
 * setter
 *********** */

const setBeforeToAfterDaily = (state: ReportState): ReportState => {
  const after: Report[] = state.reportDaily.before.map((report) => {
    return { ...report };
  });
  return {
    ...state,
    reportDaily: {
      ...state.reportDaily,
      after
    }
  };
};

const setBeforeToAfterMonthly = (state: ReportState): ReportState => {
  const after: Report[] = state.reportMonthly.before.map((report) => {
    return { ...report };
  });
  return {
    ...state,
    reportMonthly: {
      ...state.reportMonthly,
      after
    }
  };
};

const setStatusType = (
  state: ReportState,
  payload: {
    value: Report["statusType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return changeStatusTypeState(state, payload);
};
const setNightSupportType = (
  state: ReportState,
  payload: {
    value: Report["nightSupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "nightSupportType", payload);
};
const setNightSupportTargetUsersType = (
  state: ReportState,
  payload: {
    value: Report["nightSupportTargetUsers"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "nightSupportTargetUsers", payload);
};
const setHospitalizationSupportType = (
  state: ReportState,
  payload: {
    value: Report["hospitalizationSupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "hospitalizationSupportType", payload);
};
const setGetHomeSupportType = (
  state: ReportState,
  payload: {
    value: Report["getHomeSupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "getHomeSupportType", payload);
};
const setDaytimeSupportType = (
  state: ReportState,
  payload: {
    value: Report["daytimeSupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "daytimeSupportType", payload);
};
const setMedicalSupportType = (
  state: ReportState,
  payload: {
    value: Report["medicalSupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "medicalSupportType", payload);
};
const setSputumGuidanceFlg = (
  state: ReportState,
  payload: {
    value: Report["sputumGuidanceFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "sputumGuidanceFlg", payload);
};
const setLifeSupportFlg = (
  state: ReportState,
  payload: {
    value: Report["lifeSupportFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "lifeSupportFlg", payload);
};
const setHomeCareFlg = (
  state: ReportState,
  payload: {
    value: Report["homeCareFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "homeCareFlg", payload);
};
const setRetirementServiceFlg = (
  state: ReportState,
  payload: {
    value: Report["retirementServiceFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "retirementServiceFlg", payload);
};
const setPeerSupportFlg = (
  state: ReportState,
  payload: {
    value: Report["peerSupportFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "peerSupportFlg", payload);
};
const setAftLeavingPeerSupportFlg = (
  state: ReportState,
  payload: {
    value: Report["aftLeavingPeerSupportFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "aftLeavingPeerSupportFlg", payload);
};
const setMoreThan8hoursFlg = (
  state: ReportState,
  payload: {
    value: Report["moreThan8hoursFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "moreThan8hoursFlg", payload);
};
const setIntensiveSupportFlg = (
  state: ReportState,
  payload: {
    value: Report["intensiveSupportFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "intensiveSupportFlg", payload);
};
const setIntensiveSupportStartDate = (
  state: ReportState,
  payload: {
    value: Report["intensiveSupportStartDate"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "intensiveSupportStartDate", payload);
};
const setInfectiousDiseasesFacilityFlg = (
  state: ReportState,
  payload: {
    value: Report["infectiousDiseasesFacilityFlg"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "infectiousDiseasesFacilityFlg", payload);
};
const setSevereDisabilitySupportType = (
  state: ReportState,
  payload: {
    value: Report["severeDisabilitySupportType"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "severeDisabilitySupportType", payload);
};
const setRemarks = (
  state: ReportState,
  payload: {
    value: Report["remarks"];
    type: ReportTypeInterface["type"];
    key: number;
  }
): ReportState => {
  return stateCreate(state, "remarks", payload);
};

/**
 * 未退去かつサービス提供状況が未設定の「ユーザー」を対象に、サービス提供の状況と夜間支援を設定する
 * サービス提供の状況 => 1（宿泊）
 * 夜間支援 => 利用者情報で設定したユニットに紐付く夜間支援体制（複数ユニット運営時のみ）
 */
const setAllStatusTypeDaily = (
  state: ReportState,
  payload: Report["statusType"]
): ReportState => {
  const after = state.reportDaily.after.map((report) => {
    if (
      report.isServiceEnd === 1 &&
      (report.statusType === "" || report.statusType === "0")
    ) {
      const statusType = payload;
      const nightSupportType = report.defNightSupportType;
      // 夜間支援がセットされる時、合わせて夜間支援対象者数もセットする
      let nightSupportTargetUsers =
        !!report.defNightSupportType &&
        report.defNightSupportType !== "1" &&
        report.defNightSupportType !== "4"
          ? report.defNightSupportTargetUsers
          : "0";
      // 夜間支援Ⅱの時夜間支援対象者の値が存在するかをチェック
      if (
        report.defNightSupportType === "3" &&
        (nightSupportTargetUsers === "2" || nightSupportTargetUsers === "3")
      ) {
        nightSupportTargetUsers = "0";
      }
      return {
        ...report,
        statusType,
        nightSupportType,
        nightSupportTargetUsers
      };
    }
    return report;
  });
  return {
    ...state,
    reportDaily: {
      ...state.reportDaily,
      after
    }
  };
};

/**
 * 未退去かつサービス提供状況が未設定の「日付」を対象に、サービス提供の状況と夜間支援を設定する
 * サービス提供の状況 => 1（宿泊）
 * 夜間支援 => 事業所情報で設定した夜間支援体制加算
 */
const setAllStatusTypeMonthly = (
  state: ReportState,
  payload: Report["statusType"]
): ReportState => {
  const after = state.reportMonthly.after.map((report) => {
    if (
      report.isServiceEnd === 1 &&
      (report.statusType === "" || report.statusType === "0")
    ) {
      const statusType = payload;
      const nightSupportType = report.defNightSupportType;
      // 夜間支援がセットされる時、合わせて夜間支援対象者数もセットする
      let nightSupportTargetUsers =
        !!report.defNightSupportType &&
        report.defNightSupportType !== "1" &&
        report.defNightSupportType !== "4"
          ? report.defNightSupportTargetUsers
          : "0";
      // 夜間支援Ⅱの時夜間支援対象者の値が存在するかをチェック
      if (
        report.defNightSupportType === "3" &&
        (nightSupportTargetUsers === "2" || nightSupportTargetUsers === "3")
      ) {
        nightSupportTargetUsers = "0";
      }
      return {
        ...report,
        statusType,
        nightSupportType,
        nightSupportTargetUsers
      };
    }
    return report;
  });
  return {
    ...state,
    reportMonthly: {
      ...state.reportMonthly,
      after
    }
  };
};

export default reducerWithInitialState(initialState)
  .case(action.fetchDaily.done, fetchDaily)
  .case(action.fetchMonthly.done, fetchMonthly)
  .case(action.postDaily.done, postDaily)
  .case(action.postMonthly.done, postMonthly)
  .case(action.setBeforeToAfterDaily, setBeforeToAfterDaily)
  .case(action.setBeforeToAfterMonthly, setBeforeToAfterMonthly)
  .case(action.setStatusType, setStatusType)
  .case(action.setNightSupportType, setNightSupportType)
  .case(action.setNightSupportTargetUsersType, setNightSupportTargetUsersType)
  .case(action.setHospitalizationSupportType, setHospitalizationSupportType)
  .case(action.setGetHomeSupportType, setGetHomeSupportType)
  .case(action.setDaytimeSupportType, setDaytimeSupportType)
  .case(action.setMedicalSupportType, setMedicalSupportType)
  .case(action.setSputumGuidanceFlg, setSputumGuidanceFlg)
  .case(action.setLifeSupportFlg, setLifeSupportFlg)
  .case(action.setHomeCareFlg, setHomeCareFlg)
  .case(action.setPeerSupportFlg, setPeerSupportFlg)
  .case(action.setRetirementServiceFlg, setRetirementServiceFlg)
  .case(action.setAftLeavingPeerSupportFlg, setAftLeavingPeerSupportFlg)
  .case(action.setMoreThan8hoursFlg, setMoreThan8hoursFlg)
  .case(action.setIntensiveSupportFlg, setIntensiveSupportFlg)
  .case(action.setIntensiveSupportStartDate, setIntensiveSupportStartDate)
  .case(
    action.setInfectiousDiseasesFacilityFlg,
    setInfectiousDiseasesFacilityFlg
  )
  .case(action.setSevereDisabilitySupportType, setSevereDisabilitySupportType)
  .case(action.setRemarks, setRemarks)
  .case(action.setAllStatusTypeDaily, setAllStatusTypeDaily)
  .case(action.setAllStatusTypeMonthly, setAllStatusTypeMonthly);
