import * as React from "react";
import * as H from "history";
import { StyleRules } from "@material-ui/core/styles";
import {
  createStyles,
  WithStyles,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import { stringify } from "query-string";

import MuiCheckbox from "@components/molecules/MuiCheckbox";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    dialogHeader: {
      marginBottom: 4,
      padding: "16px 32px 18px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      lineHeight: 1.2,
      height: 58
    },
    dialogSubTitle: {
      margin: 0,
      padding: "24px 30px 0",
      marginBottom: 47,
      color: "#37474f",
      fontSize: 16,
      fontWeight: "normal",
      lineHeight: 1.75,
      letterSpacing: 0.5,
      height: 80
    },
    checkboxLi: {
      padding: "0 30px"
    },
    checkbox: {
      margin: "-36px 0 0 0"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      marginLeft: 0,
      marginRight: 0
    }
  });

const cancelButtonStyles: React.CSSProperties = {
  width: 120,
  height: 36,
  marginRight: 8
};

const submitButtonStyles: React.CSSProperties = {
  width: 120,
  height: 36,
  marginRight: 32
};

type OwnProps = {
  isOpen: boolean;
  history: H.History;
  uifId: string;
  assessmentId: string;
  onClose: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentSheetPrintDialogCore = (props: Props): JSX.Element => {
  const [worksFlag, setWorksFlag] = React.useState(false);
  const handleChangeWorksFlag = (): void => {
    setWorksFlag(!worksFlag);
  };

  const [supportSkillsFlag, setSupportSkillsFlag] = React.useState(false);
  const handleChangeSupportSkillsFlag = (): void => {
    setSupportSkillsFlag(!supportSkillsFlag);
  };

  const [othersFlag, setOthersFlag] = React.useState(false);
  const handleChangeOthersFlag = (): void => {
    setOthersFlag(!othersFlag);
  };

  const handleCloseDialog = (): void => {
    props.onClose();
  };

  const isDisabledConfirmButton =
    !worksFlag && !supportSkillsFlag && !othersFlag;

  const handleClickConfirm = (): void => {
    const query = {
      display_works: worksFlag || undefined,
      display_support_skills: supportSkillsFlag || undefined,
      display_others: othersFlag || undefined
    };
    const url = `/record/support_plan/${props.uifId}/assessment/${
      props.assessmentId
    }/print?${stringify(query)}`;
    props.history.push(url);
  };

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle className={props.classes.dialogHeader}>
        <span>アセスメントシート印刷項目</span>
      </DialogTitle>
      <DialogContent>
        <div className={props.classes.dialogSubTitle}>
          以下の項目でチェックされた項目が印刷されます。
          <br />
          印刷したい項目にチェックしてください。
        </div>
        <div className={props.classes.checkboxLi}>
          <div className={props.classes.checkbox}>
            <MuiCheckbox
              id="works_flag"
              label="就労に関する項目"
              checked={worksFlag}
              value="1"
              onChange={handleChangeWorksFlag}
            />
          </div>
          <div className={props.classes.checkbox}>
            <MuiCheckbox
              id="support_skills_flag"
              label="支援、適正・スキルに関する項目"
              checked={supportSkillsFlag}
              value="1"
              onChange={handleChangeSupportSkillsFlag}
            />
          </div>
          <div className={props.classes.checkbox}>
            <MuiCheckbox
              id="others_flag"
              label="その他の項目"
              checked={othersFlag}
              value="1"
              onChange={handleChangeOthersFlag}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions className={props.classes.dialogFooter}>
        <KnowbeButton
          style={cancelButtonStyles}
          kind="outlineWhite"
          onClick={handleCloseDialog}
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          disabled={isDisabledConfirmButton}
          style={submitButtonStyles}
          onClick={handleClickConfirm}
        >
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const AssessmentSheetPrintDialog = withStyles(styles)(
  AssessmentSheetPrintDialogCore
);
