import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";
import FormikTextField from "@components/molecules/FormikTextField";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

// utils
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";

// store
import { connect } from "react-redux";
import { FormikProps } from "formik";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { StaffState } from "@stores/domain/staff/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      padding: "24px 16px",
      backgroundColor: "#fff"
    },
    title: {
      fontSize: 14,
      fontWeight: "normal",
      color: "rgba(0, 0, 0, 0.6)"
    },
    inputArea: {
      padding: "24px 16px",
      borderRadius: "4px",
      border: "solid 1px rgba(0, 0, 0, 0.12)"
    },
    border: {
      color: "#e0e0e0",
      borderTop: "none",
      borderBottom: "1px solid",
      width: "100%",
      margin: 0
    },
    contentText: {
      fontSize: 14,
      color: "#000",
      whiteSpace: "pre-wrap"
    },
    author: {
      display: "flex"
    },
    authorTitle: {
      width: "50px"
    },
    MT4: {
      marginTop: spacing.unit * 0.5
    },
    MT12: {
      marginTop: spacing.unit * 1.5
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT24: {
      marginTop: spacing.unit * 3
    }
  });

type OwnProps = {
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  isEdit: boolean;
  staff: StaffState;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureRecordFormOtherCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { formikProps, supportProcedureDetail } = props;
  const {
    contactInformationPlan,
    contactInformationRecord,
    inquiryItemsPlan,
    inquiryItemsRecord,
    authorId
  } = formikProps.values;

  const author = React.useMemo<{
    name?: string;
    role?: string;
  }>(() => {
    // snapshot選択時
    if (authorId === 0) {
      if (!supportProcedureDetail || !supportProcedureDetail.author) return {};
      return {
        name: supportProcedureDetail.author.snapshot_name,
        role:
          supportProcedureDetail.author.snapshot_role ||
          supportProcedureDetail.author.role
      };
    }

    if (!authorId) return {};
    const selectedStaff = props.staff.staffItems.find(
      (staffItem) => staffItem.staffItemId === Number(authorId)
    );
    if (!selectedStaff) {
      if (!supportProcedureDetail.author) return {};
      // スタッフを選択しているが削除されている
      return {
        name: supportProcedureDetail.author.snapshot_name,
        role:
          supportProcedureDetail.author.snapshot_role ||
          supportProcedureDetail.author.role
      };
    }
    return {
      name: selectedStaff.staffName,
      role: selectedStaff.roleName
    };
  }, [authorId, props.staff]);

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.MT24}>連絡事項</Typography>
      <div className={`${classes.inputArea} ${classes.MT16}`}>
        <Typography className={classes.contentText}>
          {contactInformationPlan || "-"}
        </Typography>
        <hr className={`${classes.border} ${classes.MT24}`} />
        {props.isEdit ? (
          <FormikTextField
            name="contactInformationRecord"
            label="記録"
            style={{ marginTop: "24px", marginBottom: 0 }}
            size="fullSize"
            multiline
          />
        ) : (
          <ReadonlyTextField
            label="記録"
            value={contactInformationRecord}
            defaultValue=""
            style={{ marginTop: "24px", marginBottom: 0 }}
            size="fullSize"
            multiline
          />
        )}
      </div>

      <Typography className={classes.MT24}>問い合わせ事項</Typography>
      <div className={`${classes.inputArea} ${classes.MT16}`}>
        <Typography className={classes.contentText}>
          {inquiryItemsPlan || "-"}
        </Typography>
        <hr className={`${classes.border} ${classes.MT24}`} />
        {props.isEdit ? (
          <FormikTextField
            name="inquiryItemsRecord"
            label="記録"
            style={{ marginTop: "24px", marginBottom: 0 }}
            size="fullSize"
            multiline
          />
        ) : (
          <ReadonlyTextField
            label="記録"
            value={inquiryItemsRecord}
            size="fullSize"
            style={{ marginTop: "24px", marginBottom: 0 }}
            defaultValue=""
            multiline
          />
        )}
      </div>

      <div className={classes.MT24}>
        <div className={classes.author}>
          <Typography
            className={`${classes.authorTitle} ${classes.contentText}`}
          >
            作成者
          </Typography>
          <Typography className={classes.contentText}>
            {author.name || "-"}
          </Typography>
        </div>
        {author.role ? (
          <div className={`${classes.author} ${classes.MT4}`}>
            <Typography
              className={`${classes.authorTitle} ${classes.contentText}`}
            >
              役職
            </Typography>
            <Typography className={classes.contentText}>
              {author.role || ""}
            </Typography>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export const SupportProcedureRecordFormOther = withStyles(styles)(
  connect(null)(SupportProcedureRecordFormOtherCore)
);
