import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import {
  AlertDialogState,
  HIDE_ALERT_DIALOG
} from "@stores/ui/alertDialog/types";
import * as alertDialogActions from "@stores/ui/alertDialog/actions";
import Button from "@material-ui/core/Button";
import MessageDialog from "@components/molecules/dialog/MessageDialog";

type StateProps = {
  alertDialog: AlertDialogState;
};
type DispatchProps = {
  closeAlertDialog: () => void;
};
type Props = StateProps & DispatchProps;

/**
 *
 */
const AlertDialog = ({ alertDialog, closeAlertDialog }: Props): JSX.Element => (
  <MessageDialog
    isOpen={alertDialog.show}
    title={alertDialog.title}
    message={alertDialog.message}
    closeButton={
      <Button onClick={closeAlertDialog} color="secondary">
        閉じる
      </Button>
    }
  />
);

const mapStateToProps = (state: AppState): StateProps => ({
  alertDialog: state.ui.alertDialog
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  closeAlertDialog: (): {
    type: typeof HIDE_ALERT_DIALOG;
  } => dispatch(alertDialogActions.hideAlertDialog())
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertDialog);
