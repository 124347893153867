import {
  ReportState,
  InoutConsultationResultsState
} from "@stores/domain/mgr/CHIIKIIKO/report/types";
import { GetInOutConsultationResultsUsersResponse } from "@api/requests/inOutConsultationResults/getInOutConsultationResultsMonthly";
import { RequestParamToPostAPI } from "@api/requests/inOutConsultationResults/postInOutConsultationResults";
import { InitialValues } from "@interfaces/mgr/CHIIKIIKO/report/initial";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  DEFAULT_CHECKBOX_VALUE
} from "@constants/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import {
  dateToLocalisedString,
  selectDateValueToDatePaddingZero,
  dateInYYYYMMFormat
} from "@utils/date";
import { cloneDeep } from "lodash-es";
import isEmpty from "lodash-es/isEmpty";

/**
 * 退院退所月フラグがOFFの場合、関連項目を削除する
 */
const resultFormat = (target: RequestParamToPostAPI): RequestParamToPostAPI => {
  const result = {
    yyyymmdd: target.yyyymmdd,
    uif_id: target.uif_id,
    params: {
      ...target.params
    }
  };

  if (!result.params.discharge_month_addition_flg) {
    result.params.date_begin_hospital = null;
    result.params.date_end_hospital = null;
    result.params.short_time_for_psychiatric_hospital_flg = 0;
  }

  return result;
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  discharge_month_addition_flg: {
    requestKey: "discharge_month_addition_flg",
    cooperationKeys: [
      "date_begin_hospital",
      "date_end_hospital",
      "short_time_for_psychiatric_hospital_flg"
    ]
  },
  date_begin_hospital: {
    requestKey: "date_begin_hospital",
    cooperationKeys: [
      "date_end_hospital",
      "short_time_for_psychiatric_hospital_flg"
    ]
  },
  date_end_hospital: {
    requestKey: "date_end_hospital",
    cooperationKeys: [
      "date_begin_hospital",
      "short_time_for_psychiatric_hospital_flg"
    ]
  },
  short_time_for_psychiatric_hospital_flg: {
    requestKey: "short_time_for_psychiatric_hospital_flg",
    cooperationKeys: ["date_begin_hospital", "date_end_hospital"]
  },
  memo_status: {
    requestKey: "memo_status",
    cooperationKeys: ["status"]
  },
  memo_trial: {
    requestKey: "memo_trial",
    cooperationKeys: ["trial_stay_type"]
  }
};

/**
 * 実績データ一覧の整形
 */
export const normalizeListFromAPI = (
  inoutConsultationResults: GetInOutConsultationResultsUsersResponse["data"]["inout_consultation_results"],
  date = ""
): InoutConsultationResultsState[] => {
  return inoutConsultationResults
    ? inoutConsultationResults.map((record) => {
        const targetDateState = record.target_date ? record.target_date : date;
        return {
          id: record ? record.id : null,
          inoutResultDailyId:
            record && record.inout_result_daily_id
              ? record.inout_result_daily_id
              : null,
          usersInFacilityId: record.users_in_facility_id,
          targetDate: targetDateState,
          nameSei: record.name_sei,
          nameMei: record.name_mei,
          status: record ? record.status : null,
          trialUseType: record ? record.trial_use_type : null,
          trialStayType: record ? record.trial_stay_type : null,
          firstAdditionFlg: record
            ? record.first_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          housingSupportCooperationFlg: record
            ? record.housing_support_cooperation_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          housingSupportPromotionFlg: record
            ? record.housing_support_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          dischargeMonthAdditionFlg: record
            ? record.discharge_month_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          dateBeginHospital: record ? record.date_begin_hospital : null,
          dateEndHospital: record ? record.date_end_hospital : null,
          shortTimeForPsychiatricHospitalFlg: record
            ? record.short_time_for_psychiatric_hospital_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          memoStatus: record ? record.memo_status : null,
          memoTrial: record ? record.memo_trial : null,
          memo: record ? record.memo : null,
          isHoliday: record.isHoliday ? record.isHoliday : false
        };
      })
    : [];
};

export const normalizeSummary = (
  summary: GetInOutConsultationResultsUsersResponse["data"]["summary"]
): ReportState["reportMonthly"]["summary"] => {
  return {
    intensiveSupportCount: summary.intensiveSupportCount
  };
};

/**
 * 利用実績一覧(月ごと)
 */
export const normalizeCHIIKIIKOUserReportDataFromAPI = (
  result: GetInOutConsultationResultsUsersResponse,
  date: Date
): ReportState["reportMonthly"] => {
  const normalizeReportList: ReportState["reportMonthly"] = {
    inoutConsultationResults: normalizeListFromAPI(
      result.data.inout_consultation_results,
      dateInYYYYMMFormat(date)
    ),
    summary: normalizeSummary(result.data.summary)
  };

  return normalizeReportList;
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

/**
 * 利用実績登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues
): RequestParamToPostAPI => {
  const target: RequestParamToPostAPI = {
    yyyymmdd: dateToLocalisedString(formValue.initial.targetDate, "YYYYMMDD"),
    uif_id: formValue.initial.usersInFacilityId,
    params: {
      inout_consultation_results_id: formValue.initial.id,
      inout_result_daily_id: formValue.initial.inoutResultDailyId,
      target_date: formValue.initial.targetDate,
      status: castNumber(formValue.initial.status),
      trial_use_type: castNumber(formValue.initial.trialUseType),
      trial_stay_type: castNumber(formValue.initial.trialStayType),
      first_addition_flg: formValue.initial.firstAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      housing_support_cooperation_flg: formValue.initial
        .housingSupportCooperationFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      housing_support_promotion_flg: formValue.initial
        .housingSupportPromotionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      discharge_month_addition_flg: formValue.initial.dischargeMonthAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      date_begin_hospital: selectDateValueToDatePaddingZero(
        formValue.initial.dateBeginHospital
      ),
      date_end_hospital: selectDateValueToDatePaddingZero(
        formValue.initial.dateEndHospital
      ),
      short_time_for_psychiatric_hospital_flg: formValue.initial
        .shortTimeForPsychiatricHospitalFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      memo_status: isEmpty(formValue.initial.memoStatus)
        ? null
        : formValue.initial.memoStatus,
      memo_trial: isEmpty(formValue.initial.memoTrial)
        ? null
        : formValue.initial.memoTrial,
      memo: isEmpty(formValue.initial.memo) ? null : formValue.initial.memo
    }
  };
  return resultFormat(target);
};

export const normalizeDiffParamToPostAPI = (
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    uif_id: after.uif_id,
    yyyymmdd: after.yyyymmdd,
    params: {
      inout_result_daily_id: after.params.inout_result_daily_id,
      inout_consultation_results_id: after.params.inout_consultation_results_id,
      target_date: after.params.target_date
    }
  };

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    if (after.params.inout_consultation_results_id === null) {
      // 初回の場合は入力値をセット
      result.params[key] = after.params[key];
    } else if (before.params[key] !== after.params[key]) {
      result.params[key] = after.params[key];
    }
  });

  return addRelationValue(result, after);
};
