import React from "react";
import {
  OperationsValues,
  OperationValue
} from "@initialize/mgr/Cseg/record/operations/initialValues";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FormikProps } from "formik";
import Paper from "@material-ui/core/Paper";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { connect } from "react-redux";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import { FieldItem } from "@interfaces/ui/form";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: "32px 32px 8px"
    },
    container: {
      marginBottom: 16
    },
    fieldList: {
      margin: "8px 0 0"
    },
    titleWithButton: {
      display: "flex",
      alignItems: "center",
      position: "relative",
      "& > :first-child": {
        width: "100%"
      }
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      minHeight: 32,
      marginBottom: 1
    },
    buttons: {
      position: "absolute",
      right: 0,
      top: "-8px"
    },
    cancel: {
      marginRight: 8
    }
  });

type OwnProps = {
  handleClickEdit: () => void;
  handleClickCancel: () => void;
  formikProps: FormikProps<OperationsValues>;
  formikFieldNamePrefix: string;
  isEditing: boolean;
  isEditNotTarget: boolean;
  date: string;
  isHoliday: boolean;
  operation: OperationValue | undefined;
  staffOptions: FieldItem[];
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & DispatchProps & WithStyles<typeof styles>;

const OperationRecordCore = (props: Props): JSX.Element | null => {
  const { operation, isEditing } = props;

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 記録者
  const staffNames = operation
    ? operation.staffs.map((staff) => ({
        id: staff.id,
        name: staff.snapshot_name
      }))
    : [];

  const mergedStaffOptions = generateMergedStaffOptions(
    props.staffOptions,
    staffNames
  );

  return (
    <Paper elevation={0} className={props.classes.paper}>
      <UnEditableWrapper unEditable={props.isEditNotTarget}>
        <div className={props.classes.titleWithButton}>
          {/** 業務日誌日付 */}
          <div className={props.classes.container}>
            <Typography
              gutterBottom
              variant="h6"
              color="primary"
              className={props.classes.flex}
            >
              <div>
                <CustomDateLabel
                  date={props.date}
                  dateFormat="MMMDo（dd）"
                  holiday={props.isHoliday}
                />
              </div>
            </Typography>
            <Divider variant="middle" className={props.classes.divider} />
          </div>
          {/** 編集・キャンセル・保存ボタン */}
          <div className={props.classes.buttons}>
            {isEditing ? (
              <>
                <KnowbeButton
                  kind="outline"
                  onClick={props.handleClickCancel}
                  className={props.classes.cancel}
                >
                  キャンセル
                </KnowbeButton>
                <FormikSubmitButton
                  buttonName="保存する"
                  formikProps={props.formikProps}
                  errorAction={submitError}
                />
              </>
            ) : (
              <KnowbeButton onClick={props.handleClickEdit}>編集</KnowbeButton>
            )}
          </div>
        </div>
        {/** 業務日誌項目 */}
        <div className={props.classes.fieldList}>
          <RecordSupportTableField
            type="text"
            name={`${props.formikFieldNamePrefix}.operation_in_the_morning`}
            label="業務内容（午前）"
            value={operation ? operation.operation_in_the_morning : ""}
            defaultValue=""
            placeholder=""
            isEditing={isEditing}
            isExtendsMarginBottom
          />
          <RecordSupportTableField
            type="text"
            name={`${props.formikFieldNamePrefix}.operation_in_the_afternoon`}
            label="業務内容（午後）"
            value={operation ? operation.operation_in_the_afternoon : ""}
            defaultValue=""
            placeholder=""
            isEditing={isEditing}
            isExtendsMarginBottom
          />
          <RecordSupportTableField
            type="text"
            name={`${props.formikFieldNamePrefix}.other_comment`}
            label="その他	"
            value={operation ? operation.other_comment : ""}
            defaultValue=""
            placeholder=""
            isEditing={isEditing}
            isExtendsMarginBottom
          />
          <RecordSupportTableField
            type="multiple"
            label="記録者"
            value={
              staffNames ? staffNames.map((staff) => staff.name).join("、") : ""
            }
            name={`${props.formikFieldNamePrefix}.staffs.itemIdList`}
            options={[{ categoryName: "", items: mergedStaffOptions }]}
            defaultValue=""
            placeholder="選択してください"
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            isEditing={isEditing}
            isExtendsMarginBottom
          />
        </div>
      </UnEditableWrapper>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params)
  };
};

export const OperationRecord = connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OperationRecordCore));
