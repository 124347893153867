/**
 * 上限数値チェック
 * @param value 入力数値
 * @param maximumNum 上限数値
 */
export const checkMaximumNumber = (
  value: number,
  maximumNum: number
): string | undefined => {
  let errorMessage;
  if (value > maximumNum) {
    errorMessage = `${maximumNum}以下の数値を入力してください`;
  }
  return errorMessage;
};
