import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { AssessmentState } from "@stores/domain/assessment/types";
import { FacilityState as KEIKAKUSODANFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { FacilityState as CHIIKIIKOFacilityState } from "@stores/domain/mgr/CHIIKIIKO/facility/types";
import { UsersInFacilityState as KEIKAKUSOUDANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UsersInFacilityState as CHIIKITEICHAKUUsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import { AssessmentRecordList } from "@components/organisms/mgr/common/record/AssessmentRecordList";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

// utils
import * as URL from "@constants/url";
import { FacilityType } from "@constants/variables";
import { SupportPlanState } from "@stores/domain/supportPlan/types";
import { UserState } from "@stores/domain/user/type";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
}>;

type StateProps = {
  facility: KEIKAKUSODANFacilityState | CHIIKIIKOFacilityState;
  supportPlanRecord: SupportPlanState["supportPlan"];
  userState: UserState;
  facilityType: FacilityType;
  user:
    | KEIKAKUSOUDANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"]
    | CHIIKITEICHAKUUsersInFacilityState["user"];
  assessmentList: AssessmentState["assessmentList"];
};

type DispatchProps = {
  fetchSupportPlan: (uifId: string) => void;
  fetchAssessmentList: (uifId: string) => void;
  fetchOne: (uifId: string, facility_type: FacilityType) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 支援計画 > アセスメント一覧
 */
const AssessmentListCore = (props: Props): JSX.Element => {
  const { uifId } = props.match.params;
  const { classes, facilityType } = props;

  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: URL.RECORD_USERS_SUMMARY }
  ];

  // 現在ページのタイトル名
  const pageName = "アセスメント";

  React.useEffect(() => {
    props.fetchSupportPlan(uifId);
    props.fetchAssessmentList(uifId);
    props.fetchOne(uifId, facilityType);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <AssessmentRecordList
          pageName={pageName}
          userName={props.userName}
          uifId={uifId}
          assessmentList={props.assessmentList}
          history={props.history}
          facilityType={facilityType}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facility: state[facilityType].facility,
    supportPlanRecord: state.supportPlan.supportPlan,
    userState: state.user as UserState,
    facilityType,
    user: state[facilityType].userInFacility.user,
    assessmentList: state.assessment.assessmentList
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportPlanDispatcher, assessmentDispatcher } = dispatches;
  const supportPlanDispatches = supportPlanDispatcher(dispatch);
  const assessmentDispatches = assessmentDispatcher(dispatch);

  return {
    fetchSupportPlan: (uifId: string): void => {
      supportPlanDispatches.fetchSupportPlan(uifId);
    },
    fetchAssessmentList: (uifId: string): void => {
      assessmentDispatches.fetchAssessmentList(uifId);
    },
    fetchOne: (uifId: string, facilityType: FacilityType): void => {
      dispatches[facilityType]
        .userInFacilityDispatcher(dispatch)
        .fetchOne(uifId);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const AssessmentList = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(AssessmentListCore));
