import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as alertDialogActions from "@stores/ui/alertDialog/actions";
import { StaffValues } from "@initialize/mgr/DOKOENGO/staffs/initialValues";
import staffApi from "@api/requests/staff";
import dispatches from "@stores/dispatches";
import castNumber from "@utils/dataNormalizer/castNumber";

export const fetch = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchStarted());
  await staffApi
    .getStaff()
    .then((res) => dispatch(actions.fetchSuccess(res.data)))
    .catch((e) => {
      dispatch(actions.fetchFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const post = (dispatch: Dispatch) => async (
  values: StaffValues["staffs"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postStarted());
  const params = values.map((value) => ({
    id: value.staffItemId || "new",
    name: value.staffName,
    role: value.roleName,
    license: value.staffLicense ? castNumber(value.staffLicense) : 0,
    license_name: value.staffLicenseName ? value.staffLicenseName : null
  }));
  await staffApi
    .postStaffList(params)
    .then(() => {
      dispatch(actions.postSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "保存が完了しました",
        variant: "success"
      });
    })
    .catch((e) => {
      dispatch(actions.postFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteStaff = (dispatch: Dispatch) => async (
  id: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteStarted());
  await staffApi
    .deleteStaff(id)
    .then(() => {
      dispatch(actions.deleteSuccess());
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "削除が完了しました",
        variant: "success"
      });
    })
    .catch((e) => {
      dispatch(actions.deleteFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatch(
        alertDialogActions.showAlertDialog(
          "削除を完了できませんでした",
          "何らかの問題が発生した為、データの削除が完了できませんでした。時間を置いてから再度データの削除を行ってください。"
        )
      );
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type StaffDispatcher = {
  fetch: () => Promise<void>;
  post: (values: StaffValues["staffs"]) => Promise<void>;
  deleteStaff: (id: number) => Promise<void>;
};

export const DOKOENGOStaffDispatcher = (
  dispatch: Dispatch
): StaffDispatcher => ({
  fetch: fetch(dispatch),
  post: post(dispatch),
  deleteStaff: deleteStaff(dispatch)
});
