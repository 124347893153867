import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetBasicInfoResponse = {
  data: {
    created_at: string | null;
    updated_at: string | null;
    basic_info: {
      basic_info_id: number | null;
      creation_date: string;
      author: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string;
        facility_id: number;
        snapshot_name: string;
        snapshot_role: string;
      } | null;
      support_progress: string | null;
      date_birth: string | null;
      age: number | null;
      postal_code: string | null;
      city_id: number | null;
      prefecture_name: string | null;
      address: string | null;
      residence: number | null;
      residence_other: string | null;
      tel: string | null;
      fax: string | null;
      disability_name: string | null;
      classify_disability_support: number | null;
      gender: string | null;
      family_structure: string | null;
      social_relationship: string | null;
      life_history: string | null;
      medical_situation: string | null;
      self_complaint: string | null;
      family_complaint: string | null;
      public_support: {
        basic_info_details_id: number | null;
        name: string | null;
        offer_name: string | null;
        support_contents: string | null;
        frequency: string | null;
        memo: string | null;
      };
      other_support: {
        basic_info_details_id: number | null;
        name: string | null;
        offer_name: string | null;
        support_contents: string | null;
        frequency: string | null;
        memo: string | null;
      };
      schedule: {
        basic_info_schedules_id: number;
        day_of_week: number[];
        start_time: string;
        next_day_flg: number;
        end_time: string;
        content: string;
      }[];
      main_activity: string | null;
      non_weekly_service: string | null;
      type_consultation?: number; // 計画相談のみ
    };
  };
};

/**
 * 申請者の現状 (基本情報) のデータ取得
 * @param uifId 事業所所属ユーザーのID
 * @param supportPlanBasicInfoId 申請者の現状 (基本情報)のID
 */
export const getBasicInfo = async (
  uifId: string,
  supportPlanBasicInfoId: string
): Promise<AxiosResponse<GetBasicInfoResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/basic_info/${supportPlanBasicInfoId}`;
  return request.get<GetBasicInfoResponse>(url);
};
