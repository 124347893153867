import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import * as URL from "@constants/url";

// サービス提供
import { ServiceDeliveryDailyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryDaily";
import { ServiceDeliveryMonthlyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryMonthly";
import { ServiceDeliveryEditDailyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import { ServiceDeliveryEditMonthlyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryEditMonthly";
// 支援手順書兼記録用紙
import { SupportProcedureRecordFormMobile } from "@componentsMobile/pages/record/supportProcedure/SupportProcedureRecordFormMobile";

/**
 * 行動援護でログインした時だけ有効になるルーティング
 */
export const KODOENGORoutes = (): JSX.Element => (
  <Switch>
    {/* サービス提供記録 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SERVICE_DELIVERY_DAILY}/:date?`}
      component={ServiceDeliveryDailyMobile}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/:uifId/:date`}
      component={ServiceDeliveryMonthlyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:supportProcedureFormsId"
      component={ServiceDeliveryEditDailyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:supportProcedureFormsId/:uifId/monthly"
      component={ServiceDeliveryEditMonthlyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/new"
      component={ServiceDeliveryEditDailyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/:uifId/service_delivery/new"
      component={ServiceDeliveryEditMonthlyMobile}
    />
    {/* 支援手順書兼記録用紙 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/:uifId/support_procedure"
      component={SupportProcedureRecordFormMobile}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/:uifId/support_procedure"
      component={SupportProcedureRecordFormMobile}
    />

    {/* default path */}
    <Route path="/">
      <Redirect to={`${URL.RECORD_SERVICE_DELIVERY_DAILY}`} />
    </Route>
  </Switch>
);
