import { GetFacilityUsersResponse } from "@api/requests/v202104/facility/getFacilityUsers";
import { Checkbox } from "@constants/variables";

/**
 * ActionNames
 */
export const FETCH_STARTED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_STARTED";
export const FETCH_SUCCESS =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_SUCCESS";
export const FETCH_FAILED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_FAILED";
export const FETCH_ONE_STARTED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/FETCH_ONE_FAILED";
export const CREATE_STARTED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CREATE_STARTED";
export const CREATE_SUCCESS =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CREATE_SUCCESS";
export const CREATE_FAILED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CREATE_FAILED";
export const UPDATE_STARTED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/UPDATE_STARTED";
export const UPDATE_SUCCESS =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/UPDATE_SUCCESS";
export const UPDATE_FAILED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/UPDATE_FAILED";
export const CLEAR_STARTED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CLEAR_STARTED";
export const CLEAR_SUCCESS =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CLEAR_SUCCESS";
export const CLEAR_FAILED =
  "SEIKATSUKAIGO/USER_IN_FACILITY_202104/CLEAR_FAILED";

export interface UsersInFacilityState {
  users: GetFacilityUsersResponse["data"];
  user: {
    user_in_facility: {
      /* 基本情報 */

      // 施設利用者id
      id?: number;
      // 姓
      name_sei?: string;
      // 名
      name_mei?: string;
      // 姓(カナ)
      name_sei_kana?: string;
      // 名(カナ)
      name_mei_kana?: string;
      // 受給者証番号
      recipient_number?: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg?: Checkbox;
      // 性別
      gender?: string;
      // 施設id
      facility_id?: number;
      // 身体障害
      classify_physical_flg?: Checkbox;
      // 知的障害
      classify_intelligence_flg?: Checkbox;
      // 精神障害
      classify_mind_flg?: Checkbox;
      // 発達障害
      classify_growth_flg?: Checkbox;
      // 高次脳機能障害
      classify_brain_flg?: Checkbox;
      // 難病等対象者
      classify_incurable_flg?: Checkbox;
      // 障害児
      classify_handicapped_flg?: Checkbox;
      // 生年月日
      date_birth?: string;
      // 郵便番号
      postal_code?: string;
      // 都道府県
      prefecture_name?: string;
      // 市区町村id
      city_id?: number;
      // 住所
      address?: string;
      // 電話番号
      tel?: string;
      // メールアドレス
      email?: string;
      // 保護者名
      guardian_name?: string;
      // 保護者、続柄
      guardian_relation?: string;
      // 保護者 電話番号
      guardian_tel?: string;
      // 備考
      memo?: string;

      /* サービス詳細 */

      // 入居日 yyyy-mm-dd
      date_begin_in_service?: string;
      // 退居日 yyyy-mm-dd
      date_end_in_service?: string;
      // 支給決定開始日
      date_pay_begin?: string;
      // 支給決定終了日
      date_pay_end?: string;
      // 障害支援区分
      classify_disability_support?: string;
      // 負担上限額
      income_kind?: string;
      // 所得区分
      income_kind_type?: string;
      // Checkbox?: 自治体助成金対応
      subsidized_flg?: Checkbox;
      // 金額?: %
      subsidized_percent?: string;
      // 金額 ¥
      subsidized_yen?: string;
      // Select?: 自治体助成金対応(単位 1?: %, 2?: ¥)
      subsidized_unit_flg?: string;
      // 市区町村番号
      subsidized_city_id?: number;
      // 上限額管理事業所有り
      uplimit_facility_flg?: Checkbox;
      // 上限管理事業所コード null, 1?: 上限額管理事業所あり 2?: 他事業所が上限を管理
      uplimit_controlled_by?: string;
      // サービス提供した事業者番号
      uplimit_facility_number?: string;
      // サービス提供した事業者番号2
      uplimit_facility_number2?: string;
      // サービス提供した事業者番号3
      uplimit_facility_number3?: string;
      // サービス提供した事業者番号4
      uplimit_facility_number4?: string;
      // 上限額管理事業所 事業所名
      uplimit_facility_name?: string;
      // 上限額管理事業所 事業所名2
      uplimit_facility_name2?: string;
      // 上限額管理事業所 事業所名3
      uplimit_facility_name3?: string;
      // 上限額管理事業所 事業所名4
      uplimit_facility_name4?: string;
      // 上限管理・総費用額(金額)
      uplimit_total_yen?: string;
      // 上限管理・総費用額(金額)2
      uplimit_total_yen2?: string;
      // 上限管理・総費用額(金額)3
      uplimit_total_yen3?: string;
      // 上限管理・総費用額(金額)4
      uplimit_total_yen4?: string;
      // 上限管理・利用者負担額(yen)
      uplimit_user_load_yen?: string;
      // 上限管理・利用者負担額(yen)2
      uplimit_user_load_yen2?: string;
      // 上限管理・利用者負担額(yen)3
      uplimit_user_load_yen3?: string;
      // 上限管理・利用者負担額(yen)4
      uplimit_user_load_yen4?: string;
      // 上限管理・管理結果後利用者負担額(yen)
      uplimit_user_load_after_adjustment_yen?: string;
      // 上限管理・管理結果後利用者負担額(yen)2
      uplimit_user_load_after_adjustment_yen2?: string;
      // 上限管理・管理結果後利用者負担額(yen)3
      uplimit_user_load_after_adjustment_yen3?: string;
      // 上限管理・管理結果後利用者負担額(yen)4
      uplimit_user_load_after_adjustment_yen4?: string;
      // 利用者負担上限額管理結果
      result_of_management?: string;
      // 自事業所調整上限額
      uplimit_yen?: string;
      // 個別支援計画作成済み
      create_support_plan_flg?: Checkbox;
      // 個別支援計画作成期間開始日
      date_start_not_create_support_plan?: string;
      // 同一法人の事業所から受け入れ(初期加算対象外)
      same_corporation_movement_flg?: Checkbox;
      // 契約支給量
      agreed_by_contract_flg?: string;
      // 食事提供サービス
      def_food?: string;
      // 送迎サービス
      def_pickup?: string;
      // 同一敷地内送迎
      pickup_premises?: string;
      // 日数
      pay_days_agreed?: string;
      // 事業所記入欄番号
      business_number_contract?: string;
      // 就労移行連携加算
      ikou_cooperation_flg?: number;
      // 就労移行連携加算実施日
      ikou_cooperation_date?: string | null;
      // 支援計画会議実施加算
      case_meeting_flg?: number;
      // 支援計画会議実施加算 算定開始日
      case_meeting_start_date?: string | null;
      // 利用日数に関わる特例あり
      day_use_special_case_flg?: Checkbox;
      // 利用日数に関わる特例あり開始日
      day_use_special_case_from?: string;
      // 利用日数に関わる特例あり終了日
      day_use_special_case_to?: string;

      /* 受給者証 */

      // 負担上限月額の適用期間 1: ON, 0: OFF
      user_charge_limit_flg?: Checkbox;
      // 負担上限月額の適用開始日 yyyy-mm-dd
      date_begin_user_charge_limit?: string;
      // 負担上限月額の適用終了日 yyyy-mm-dd
      date_end_user_charge_limit?: string;
      // 食事提供体制加算の適用 1: ON, 0: OFF
      food_serve_addition_flg?: Checkbox;
      // 食事提供体制加算の適用開始日 yyyy-mm-dd
      date_begin_food_serve_addition?: string;
      // 食事提供体制加算の適用終了日 yyyy-mm-dd
      date_end_food_serve_addition?: string;
      // 介護給付費の支給決定フラグ 1?: ON 0?: OFF
      care_support_payment_flg?: Checkbox;
      // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
      date_begin_care_support_payment?: string;
      // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
      date_end_care_support_payment?: string;
      // 障害支援区分の認定有効  1: ON, 0: OFF
      care_support_auth_flg?: Checkbox;
      // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
      date_begin_care_support_auth?: string;
      // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
      date_end_care_support_auth?: string;
      // 計画相談支援給付費の支給決定フラグ 1?: ON 0?: OFF
      plan_support_payment_flg?: Checkbox;
      // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
      date_begin_plan_support_payment?: string;
      // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
      date_end_plan_support_payment?: string;
      // 計画相談支援給付費のモニタリング期間 フラグ 1?: ON 0?: OFF
      plan_support_monitor_flg?: Checkbox;
      // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
      date_begin_plan_support_monitor?: string;
      // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
      date_end_plan_support_monitor?: string;

      // 通所予定日（月）
      mon_scheduled_flg?: Checkbox;
      // 通所予定日（火）
      tue_scheduled_flg?: Checkbox;
      // 通所予定日（水）
      wed_scheduled_flg?: Checkbox;
      // 通所予定日（木）
      thu_scheduled_flg?: Checkbox;
      // 通所予定日（金）
      fri_scheduled_flg?: Checkbox;
      // 通所予定日（土）
      sat_scheduled_flg?: Checkbox;
      // 通所予定日（日）
      sun_scheduled_flg?: Checkbox;
      // A型減免対象
      a_target_for_reduction_flg?: Checkbox;
      // 作業時間を管理する
      def_record_work?: Checkbox;
    };
    user_in_facility_seikatsukaigo?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 所得区分
      income_kind: string;
      // 障害区分
      disability_class: string;
      // 重度障害者支援
      severe_disability_support: number;
      // 加算算定開始日
      severe_disability_support_start_date: string;
      // リハビリテーション加算
      rehabilitation: string;
      // リハビリテーション実施計画作成日
      rehabilitation_start_date: string;
      // 月途中変更フラグ
      mid_month_change_flg: number;
      // 月途中変更区分
      mid_month_change_disability_class: number | null;
      // 月途中変更日付
      mid_month_change_date: string | null;
    }>;
  };
  doneUpsertUser: boolean;
  userValidation: Partial<{
    // 受給者証番号
    recipient_number: string;
    // 姓
    name_sei: string;
    // 名
    name_mei: string;
    // 姓(カナ)
    name_sei_kana: string;
    // 名(カナ)
    name_mei_kana: string;
    // 性別
    gender: string;
    // サービス開始日 yyyy-mm-dd
    date_begin_in_service: string;
    //  サービス終了日 yyyy-mm-dd
    date_end_in_service: string;
    // 生年月日
    date_birth: string;
    // 住所
    address: string;
    // 郵便番号
    postal_code: string;
    // 年id
    city_id: string;
    // 電話番号
    tel: string;
    // メールアドレス
    email: string;
    // 所得区分(利用者上限月額) 1:生活保護(負担額 0円) 2: 低所得(負担額 0円) 3: 一般1(負担上限金額: 9,300円) 4: 一般2(負担上限額 37,200円) 5: 一般2(18,600円) 6: 4,600円
    income_kind: string;
    // 金額: %
    subsidized_percent: string;
    // 金額 ¥
    subsidized_yen: string;
    // 市区町村番号
    subsidized_city_id: string;
    // 支給決定開始日
    date_pay_begin: string;
    // 支給決定終了日
    date_pay_end: string;
    // 上限管理事業所コード null, 1: 上限額管理事業所あり 2: 他事業所が上限を管理
    uplimit_controlled_by: string;
    // サービス提供した事業者番号
    uplimit_facility_number: string;
    // サービス提供した事業者番号2
    uplimit_facility_number2: string;
    // サービス提供した事業者番号3
    uplimit_facility_number3: string;
    // サービス提供した事業者番号4
    uplimit_facility_number4: string;
    // 上限額管理事業所 事業所名
    uplimit_facility_name: string;
    // 上限額管理事業所 事業所名2
    uplimit_facility_name2: string;
    // 上限額管理事業所 事業所名3
    uplimit_facility_name3: string;
    // 上限額管理事業所 事業所名4
    uplimit_facility_name4: string;
    // 個別支援計画-未作成開始日 yyyy-mm-dd
    date_start_not_create_support_plan: string;
    // 上限管理・総費用額(金額)
    uplimit_total_yen: string;
    // 上限管理・総費用額(金額)2
    uplimit_total_yen2: string;
    // 上限管理・総費用額(金額)3
    uplimit_total_yen3: string;
    // 上限管理・総費用額(金額)4
    uplimit_total_yen4: string;
    // 上限管理・利用者負担額(yen)
    uplimit_user_load_yen: string;
    // 上限管理・利用者負担額(yen)2
    uplimit_user_load_yen2: string;
    // 上限管理・利用者負担額(yen)3
    uplimit_user_load_yen3: string;
    // 上限管理・利用者負担額(yen)4
    uplimit_user_load_yen4: string;
    // 負担上限月額の適用開始日 yyyy-mm-dd
    date_begin_user_charge_limit: string;
    // 負担上限月額の適用終了日 yyyy-mm-dd
    date_end_user_charge_limit: string;
    // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
    date_begin_care_support_auth: string;
    // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
    date_end_care_support_auth: string;
    // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
    date_begin_care_support_payment: string;
    // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
    date_end_care_support_payment: string;
    // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
    date_begin_plan_support_payment: string;
    // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
    date_end_plan_support_payment: string;
    // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
    date_begin_plan_support_monitor: string;
    // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
    date_end_plan_support_monitor: string;
    // 都道府県
    prefecture_name: string;

    // 通所予定日（月）
    mon_scheduled_flg: string;
    // 通所予定日（火）
    tue_scheduled_flg: string;
    // 通所予定日（水）
    wed_scheduled_flg: string;
    // 通所予定日（木）
    thu_scheduled_flg: string;
    // 通所予定日（金）
    fri_scheduled_flg: string;
    // 通所予定日（土）
    sat_scheduled_flg: string;
    // 通所予定日（日）
    sun_scheduled_flg: string;
  }>;
}
