import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Form, FormikProps } from "formik";
import { Typography } from "@material-ui/core";
import { SupportProcedureRecordFormDetail } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordFormDetail";
import { SupportProcedureRecordFormServiceDelivery } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordFormServiceDelivery";
import { SupportProcedureRecordFormOther } from "@componentsMobile/organisms/record/supportProcedure/SupportProcedureRecordFormOther";

// utils
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { FieldItem } from "@interfaces/ui/form";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import { StaffState } from "@stores/domain/staff/types";

const styles = (): StyleRules =>
  createStyles({
    sectionTitle: {
      background: "#f5f5f5",
      padding: "4px 16px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      fontWeight: "bold",
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

type OwnProps = {
  staffOptions: FieldItem[];
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  staff: StaffState;
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  targetObject: TargetObject[];
  isEdit: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureFormCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { staffOptions, formikProps, targetObject } = props;

  return (
    <Form>
      <div>
        {/* サービス提供記録 */}
        {/* モーダルから単数選択で日付が設定されている場合 or モーダルで日付が設定されている場合は表示 */}
        {targetObject.length === 1 && !!targetObject[0].targetDate && (
          <SupportProcedureRecordFormServiceDelivery
            staffOptions={staffOptions}
            formikProps={formikProps}
            isEdit={props.isEdit}
          />
        )}
        {/* 手順詳細と記録 */}
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          手順詳細と記録
        </Typography>
        <SupportProcedureRecordFormDetail
          formikProps={formikProps}
          isEdit={props.isEdit}
        />
        {/* その他 */}
        <Typography variant="subtitle1" className={classes.sectionTitle}>
          その他
        </Typography>
        <SupportProcedureRecordFormOther
          formikProps={formikProps}
          supportProcedureDetail={props.supportProcedureDetail}
          isEdit={props.isEdit}
          staff={props.staff}
        />
      </div>
    </Form>
  );
};

export const SupportProcedureForm = withStyles(styles)(
  SupportProcedureFormCore
);
