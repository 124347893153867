import * as React from "react";
import { connect } from "react-redux";

import { FacilityType } from "@constants/variables";
import { AppState } from "@stores/type";
import { InvoiceState } from "@stores/v202104/domain/invoice/type";

import PreviewTemplate from "@components/templates/PreviewTemplate";
import InoutRecordTable from "@components/v202104/organisms/download/print/InoutRecordTable";
import UsagePerformanceTable from "@components/v202104/organisms/download/print/UsagePerformanceTable";
import SEIKATSUKAIGOJisseki from "@components/v202104/organisms/mgr/SEIKATSUKAIGO/download/preview/Jisseki";
import SHUROTEICHAKUJisseki from "@components/v202104/organisms/mgr/SHUROTEICHAKU/download/preview/Jisseki";
import JIRITSUKUNRENSEIKATSUJisseki from "@components/v202104/organisms/mgr/JIRITSUKUNRENSEIKATSU/download/preview/Jisseki";
import TANKINYUSHOJisseki from "@components/v202104/organisms/mgr/TANKINYUSHO/download/preview/Jisseki";
import SHISETSUNYUSHOJisseki from "@components/v202104/organisms/mgr/SHISETSUNYUSHO/download/preview/Jisseki";
import { KYOTAKUKAIGOJisseki } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/download/preview/Jisseki";
import { JUDOHOMONKAIGOJisseki } from "@components/v202104/organisms/mgr/JUDOHOMONKAIGO/download/preview/Jisseki";
import { DOKOENGOJisseki } from "@components/v202104/organisms/mgr/DOKOENGO/download/preview/Jisseki";
import { KODOENGOJisseki } from "@components/v202104/organisms/mgr/KODOENGO/download/preview/Jisseki";
import { CHIIKIIKOJisseki } from "@components/v202104/organisms/mgr/CHIIKIIKO/download/preview/Jisseki";
import { CHIIKITEICHAKUJisseki } from "@components/v202104/organisms/mgr/CHIIKITEICHAKU/download/preview/Jisseki";

type StateProps = {
  invoice: InvoiceState;
};

type Props = StateProps;
/**
 * 実績記録票のプリント画面
 */
const JissekiPrintPreview202104Core = (props: Props): JSX.Element | null => {
  if (
    props.invoice.invoiceData &&
    props.invoice.invoiceData.data &&
    props.invoice.invoiceData.data.length === 0
  ) {
    return null;
  }
  if (props.invoice.invoiceData.data === null) return null;

  return (
    <PreviewTemplate>
      {props.invoice.invoiceData.data.map((invoiceData, key) => {
        const listKey = key;
        switch (invoiceData.facility.kindService) {
          case FacilityType.IKOU:
          case FacilityType.A:
          case FacilityType.B:
            return <InoutRecordTable key={listKey} invoiceData={invoiceData} />;
          case FacilityType.GROUP_HOME:
            return (
              <UsagePerformanceTable key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.SEIKATSUKAIGO:
            return (
              <SEIKATSUKAIGOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.JIRITSUKUNRENSEIKATSU:
            return (
              <JIRITSUKUNRENSEIKATSUJisseki
                key={listKey}
                invoiceData={invoiceData}
              />
            );
          case FacilityType.SHUROTEICHAKU:
            return (
              <SHUROTEICHAKUJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.TANKINYUSHO:
            return (
              <TANKINYUSHOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.SHISETSUNYUSHO:
            return (
              <SHISETSUNYUSHOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.KYOTAKUKAIGO:
            return (
              <KYOTAKUKAIGOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.JUDOHOMONKAIGO:
            return (
              <JUDOHOMONKAIGOJisseki key={listKey} invoiceData={invoiceData} />
            );
          case FacilityType.DOKOENGO:
            return <DOKOENGOJisseki key={listKey} invoiceData={invoiceData} />;
          case FacilityType.KODOENGO:
            return <KODOENGOJisseki key={listKey} invoiceData={invoiceData} />;
          case FacilityType.CHIIKIIKO:
            return <CHIIKIIKOJisseki key={listKey} invoiceData={invoiceData} />;
          case FacilityType.CHIIKITEICHAKU:
            return (
              <CHIIKITEICHAKUJisseki key={listKey} invoiceData={invoiceData} />
            );
          default:
            return null;
        }
      })}
    </PreviewTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  invoice: state.v202104.invoice
});

export const JissekiPrintPreview202104 = connect(mapStateToProps)(
  JissekiPrintPreview202104Core
);
