import * as React from "react";
import * as URL from "@constants/url";
import * as H from "history";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions, FormikProps } from "formik";
import { RouteComponentProps } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import Button from "@material-ui/core/Button";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as DOKOENGOUsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { CityState, CityParams } from "@stores/domain/city/type";
import { setEdit, unsetEdit } from "@stores/domain/carePlan/actions";

import AdminTemplate from "@components/templates/AdminTemplate";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { CarePlanForm } from "@components/organisms/mgr/common/record/carePlan/CarePlanForm";
import { CommonPrintModalWithStaffComment } from "@components/organisms/mgr/common/record/CommonPrintModalWithStaffComment";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { editValidation } from "@initialize/record/carePlan/validation";
import { FieldItem } from "@interfaces/ui/form";
import {
  BasicValues,
  RecordCarePlan
} from "@interfaces/record/carePlan/carePlan";
import { initialValues } from "@initialize/record/carePlan/initialValues";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  SUPPORT_CARE_PLAN_NAME,
  SUPPORT_CARE_PLAN_PAGE_NAME,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM
} from "@constants/variables";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import { UserState } from "@stores/domain/user/type";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    form: {
      marginTop: "32px"
    },
    con: {
      background: "#fff"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    deleteBtn: {
      marginTop: 10,
      marginLeft: 16,
      alignItems: "center",
      boxShadow: "none",
      color: "#0277BD",
      backgroundColor: "rgba(98, 2, 238, 0)",
      padding: "6px 6px 6px 0px",
      "&>span": {
        marginRight: "0px"
      },
      "&>span>span": {
        lineHeight: 1,
        minHeight: "auto",
        marginLeft: "8px",
        marginRight: "0px"
      }
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteButton: {
      width: 120,
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = RouteComponentProps<{ id: string; carePlanId: string }> &
  WithStyles<typeof styles>;

type StateProps = {
  needsStopHistory: boolean;
  user:
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"]
    | DOKOENGOUsersInFacilityState["user"]
    | KODOENGOUsersInFacilityState["user"];
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
  supportCarePlanSchedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  isEditing: boolean;
  staff: StaffState;
  cityListView: CityState[] | null;
  loading: boolean;
  facilityType: FacilityType;
  userState: UserState;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  setEditing: () => void;
  unsetEditing: () => void;
  fetchOneUser: (id: string, facilityType: FacilityType) => void;
  fetchSupportPlanUifIdCarePlanCarePlanId: (
    id: string,
    carePlanId: string
  ) => Promise<void>;
  fetchCity: (params: CityParams) => Promise<void>;
  fetchCityEdit: (params: CityParams) => Promise<void>;
  postSupportPlanUifIdCarePlanCarePlanId: (
    uifId: string,
    carePlanId: string,
    values: RecordCarePlan,
    history: H.History,
    gotValue: GetSupportPlanUifIdCarePlanCarePlanId["data"]
  ) => Promise<void>;
  deleteSupportPlanUifIdCarePlanCarePlanId: (
    carePlanId: string,
    uifId: string,
    history: H.History
  ) => void;
};

type MergeProps = {
  authorValue: string;
  authorizerRole: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  staffWithSnap: StaffState;
  cityLabelView: string;
  commentFlagView: boolean;
  userId: number | undefined;
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

const CarePlanEditCore = (props: Props): JSX.Element => {
  const {
    needsStopHistory,
    history,
    classes,
    user,
    loading,
    supportCarePlan,
    supportCarePlanSchedules,
    staffOptions,
    staffOptionsAddSnapShot,
    staffWithSnap,
    userId,
    userName,
    isEditing,
    authorValue,
    authorizerRole,
    cityLabelView,
    commentFlagView,
    facilityType
  } = props;
  const { id, carePlanId } = props.match.params;

  // ページ名
  const listName = SUPPORT_CARE_PLAN_NAME[facilityType];
  const pageName = SUPPORT_CARE_PLAN_PAGE_NAME[facilityType];
  const pathList = [
    {
      pathName: "支援計画",
      path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `${URL.RECORD_SUPPORT_PLAN}/${userId}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
    }
  ];

  // schedules
  const [schedules, setSchedules] = React.useState<
    GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"]
  >([]);
  React.useEffect(() => {
    setSchedules(supportCarePlanSchedules);
  }, [supportCarePlanSchedules, loading]);

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchSupportPlanUifIdCarePlanCarePlanId(id, carePlanId);
    props.unsetEditing();
  }, []);

  // fetchCity
  React.useEffect(() => {
    if (supportCarePlan.prefecture_name !== null) {
      props.fetchCity({ prefectureName: supportCarePlan.prefecture_name });
    }
  }, [supportCarePlan]);

  // fetchCityEdit
  React.useEffect(() => {
    if (supportCarePlan.prefecture_name !== null) {
      props.fetchCityEdit({
        prefectureName: supportCarePlan.prefecture_name
      });
    }
  }, [supportCarePlan]);

  // reInitialize
  const [formValues, setFormValues] = React.useState(
    initialValues(supportCarePlan, staffOptions)
  );

  React.useEffect(() => {
    setFormValues(initialValues(supportCarePlan, staffOptions));
  }, [supportCarePlan]);

  // バリデーション
  const validate = (values: BasicValues): void | object => {
    const validationResult = editValidation(values, facilityType);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory && isEditing) {
      props.stopHistory(true);
    }
    return error;
  };

  // 編集
  const onClickEdit = (e: React.MouseEvent): void => {
    props.setEditing();
    e.preventDefault();
  };

  // キャンセル
  const onClickCancel = (formikProps: FormikProps<RecordCarePlan>): void => {
    formikProps.resetForm();
    props.stopHistory(false);
    props.unsetEditing();
  };

  // 印刷モーダル
  const [isOpenPrintModal, setIsOpenPrintModal] = React.useState<boolean>(
    false
  );
  const onOpenPrintModal = (): void => {
    setIsOpenPrintModal(true);
  };
  const onClosePrintModal = (): void => {
    setIsOpenPrintModal(false);
  };
  const onConfirmPrintModal = (paramsOption: string): void => {
    // 途中・リンク先は印刷プレビューで実装される
    history.push(
      `/record/support_plan/${id}/care_plan/${carePlanId}/print${paramsOption}`
    );
  };

  // 削除モーダル
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState<boolean>(
    false
  );
  const onOpenDeleteModal = (): void => {
    setIsOpenDeleteModal(true);
  };
  const onCloseDeleteModal = (): void => {
    setIsOpenDeleteModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 編集
  const onSubmit = async (
    values: RecordCarePlan,
    actions: FormikActions<RecordCarePlan>
  ): Promise<void> => {
    actions.setSubmitting(true);
    props.postSupportPlanUifIdCarePlanCarePlanId(
      id,
      carePlanId,
      values,
      history,
      supportCarePlan
    );
    actions.setSubmitting(false);
    props.unsetEditing();
    props.stopHistory(false);
  };

  // 削除
  const onClickDelete = (): void => {
    props.deleteSupportPlanUifIdCarePlanCarePlanId(id, carePlanId, history);
  };

  if (user.user_in_facility.id === undefined) {
    return <></>;
  }

  const editButton = (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onOpenPrintModal}>
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickEdit}>
          編集
        </KnowbeButton>
      </div>
    </div>
  );

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <CarePlanUserInfo user={user} />
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => (
            <Form className={classes.form}>
              {/* タブヘッダー */}
              <PlanListHeader
                pageName={pageName}
                userName={userName}
                uifId={id}
                recordType={PLAN_LIST_TAB_PATH.CAREPLAN}
                isEditing={isEditing}
                history={history}
                button={
                  isEditing ? (
                    <div className={classes.titleBtn}>
                      <div className={classes.titleBtnItem}>
                        <KnowbeButton
                          kind="outline"
                          onClick={(): void => onClickCancel(formikProps)}
                        >
                          キャンセル
                        </KnowbeButton>
                      </div>
                      <div className={classes.titleBtnItem}>
                        <FormikSubmitButton
                          key="submit-button"
                          buttonName="保存する"
                          formikProps={formikProps}
                          errorAction={submitError}
                        />
                      </div>
                    </div>
                  ) : (
                    editButton
                  )
                }
                facilityType={facilityType}
              />

              {/* フォーム */}
              <div className={classes.con}>
                <CarePlanForm
                  initialValues={formValues}
                  isEditing={isEditing}
                  authorName={authorValue}
                  authorizerRole={authorizerRole}
                  staffOptionsAddSnapShot={staffOptionsAddSnapShot}
                  staffWithSnap={staffWithSnap}
                  cityLabelView={cityLabelView}
                  formikProps={formikProps}
                  commentFlagView={commentFlagView}
                  schedules={schedules}
                  paramsId={id}
                  paramsCarePlanId={carePlanId}
                  facilityType={facilityType}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <NavigationTransitionPrompt />

      {/* 削除ボタン */}
      {isEditing && (
        <KnowbeButton
          kind="iconText"
          className={classes.deleteBtn}
          onClick={onOpenDeleteModal}
        >
          <DeleteIcon fontSize="small" />
          <span className={classes.deleteBtnText}>{pageName}を削除する</span>
        </KnowbeButton>
      )}

      {/* 削除確認モーダル */}
      <MessageDialog
        isOpen={isOpenDeleteModal}
        title={`${pageName}を削除します`}
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onCloseDeleteModal}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteButton}
            onClick={onClickDelete}
          >
            削除する
          </Button>
        }
      />

      {/* 印刷モーダル */}
      <CommonPrintModalWithStaffComment
        title={SUPPORT_CARE_PLAN_NAME[facilityType]}
        isOpenPrintModal={isOpenPrintModal}
        onClosePrintModal={onClosePrintModal}
        onConfirmPrintModal={onConfirmPrintModal}
        uifId={id}
        stampIconUniqueKey="care_plan"
        showCheckBox
        accountId={props.userState.id}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    needsStopHistory: state.ui.needsStopHistory,
    user: state[facilityType].userInFacility.user,
    supportCarePlan: state.carePlan.supportCarePlan,
    supportCarePlanSchedules: state.carePlan.supportCarePlanSchedules,
    isEditing: state.carePlan.isEditing,
    staff: state.staff,
    cityListView: state.carePlan.city,
    loading: state.loading,
    facilityType,
    userState: state.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, cityDispatch, carePlanDispatcher } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    setEditing: (): void => {
      dispatch(setEdit());
    },
    unsetEditing: (): void => {
      dispatch(unsetEdit());
    },
    fetchOneUser: (id: string, facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportPlanUifIdCarePlanCarePlanId: carePlanDispatcher(dispatch)
      .fetchSupportPlanUifIdCarePlanCarePlanId,
    fetchCity: carePlanDispatcher(dispatch).fetchCity,
    fetchCityEdit: async (params: CityParams): Promise<void> => {
      await cityDispatch(dispatch).fetch({
        prefectureName: params.prefectureName
      });
    },
    postSupportPlanUifIdCarePlanCarePlanId: async (
      uifId: string,
      carePlanId: string,
      values: RecordCarePlan,
      history: H.History,
      gotValue: GetSupportPlanUifIdCarePlanCarePlanId["data"]
    ): Promise<void> => {
      await carePlanDispatcher(dispatch).postSupportPlanUifIdCarePlanCarePlanId(
        uifId,
        carePlanId,
        values,
        history,
        gotValue
      );
    },
    deleteSupportPlanUifIdCarePlanCarePlanId: (
      uifId: string,
      carePlanId: string,
      history: H.History
    ): void => {
      carePlanDispatcher(dispatch).deleteSupportPlanUifIdCarePlanCarePlanId(
        uifId,
        carePlanId,
        history
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, supportCarePlan, cityListView, user } = stateProps;

  // ユーザID
  const userId = user.user_in_facility.id;
  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const author = supportCarePlan.author !== null ? supportCarePlan.author : 0;
  // 作成者名
  const authorValue =
    Object.keys(supportCarePlan).length === 0
      ? ""
      : getSnapOrRealName(author, "");
  // 承認者役職
  const authorizerRole =
    Object.keys(supportCarePlan).length === 0
      ? ""
      : getSnapOrRealRole(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // サービス提供者選択肢作成（スナップショット）
  if (supportCarePlan.author !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      supportCarePlan.author
    );
  }

  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = { staffItems: staff.staffItems.concat() };
  if (staffOptionsAddSnapShot.length > staff.staffItems.length) {
    const snapOption = staffOptionsAddSnapShot.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorValue,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  const id = Number(supportCarePlan.city_id);
  let cityLabelViewData = "";
  if (cityListView !== null) {
    Object.keys(cityListView).forEach((key) => {
      if (cityListView[key].value === id) {
        cityLabelViewData = cityListView[key].label;
      }
    });
  }

  // 閲覧の時「内容」表示Flag
  const commentFlagView =
    supportCarePlan.housework_assistance_base_type_reason ===
    Number(HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value);

  return {
    authorValue,
    authorizerRole,
    staffOptions,
    staffOptionsAddSnapShot,
    staffWithSnap,
    cityLabelView: cityLabelViewData,
    commentFlagView,
    userId,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const CarePlanEdit = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CarePlanEditCore)
);
