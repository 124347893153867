// Page URL
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const ATTENDANCE = "/attendance";
export const USERS = "/users";
export const USERS_201910 = "/v201910/users";
export const USERS_202104 = "/v202104/users";
export const USERS_NEW = "/users/new";
export const USERS_NEW_201910 = "/v201910/users/new";
export const USERS_NEW_202104 = "/v202104/users/new";
export const USERS_ID = "/users/:id";
export const USERS_ID_201910 = "/v201910/users/:id";
export const USERS_ID_202104 = "/v202104/users/:id";
export const USER_SUMMARY = "/user-summary";
export const USER_SUMMARY_OPERATION = "/user-summary/operations";
export const USER_SUMMARY_SUPPORT_REPORTS = "user-summary-support-reports";
export const USER_SUMMARY_WORK_REPORTS = "user-summary-work-reports";
export const USER_SUMMARY_INTERVIEW_REPORTS = "user-summary-interview-reports";
export const RECORD = "/record";
export const RECORD_DAILY = "/record/daily";
export const RECORD_USERS_SUMMARY = "/record/users_summary";
export const RECORD_OPERATIONS = "/record/operations";
export const RECORD_PRINT = "/record/print";
export const RECORD_PRINT_DAILY = "/record/print/daily";
export const RECORD_PRINT_OPERATIONS = "/record/print/operations";
export const RECORD_PRINT_USERS_SUMMARY = "/record/print/users_summary";
export const RECORD_SERVICE_DELIVERY = "/record/service_delivery";
export const RECORD_SERVICE_DELIVERY_DAILY =
  "/record/service_delivery/daily/list";
export const RECORD_SERVICE_DELIVERY_MONTHLY =
  "/record/service_delivery/monthly/list";
export const RECORD_CARE_PLAN = "/record/care_plan";
export const RECORD_CARE_PLAN_LIST = "/record/care_plan/list";
export const RECORD_SUPPORT_PLAN = "/record/support_plan";
export const RECORD_SUPPORT_PLAN_USERS_SUMMARY =
  "/record/support_plan/users_summary";
export const FACILITY = "/facility";
export const FACILITY_201910 = "/v201910/facility";
export const FACILITY_202104 = "/v202104/facility";
export const REPORT = "/report";
export const REPORT_201910 = "/v201910/report";
export const REPORT_202104 = "/v202104/report";
export const REPORT_DAILY = "/report/daily";
export const REPORT_DAILY_201910 = "/v201910/report/daily";
export const REPORT_DAILY_202104 = "/v202104/report/daily";
export const REPORT_MONTHLY = "/report/monthly";
export const REPORT_MONTHLY_201910 = "/v201910/report/monthly";
export const REPORT_MONTHLY_202104 = "/v202104/report/monthly";
export const REPORT_USERS = "/report/users";
export const REPORT_USERS_201910 = "/v201910/report/users";
export const REPORT_USERS_202104 = "/v202104/report/users";
export const OPERATION_SUPPORT_RECORD = "/operation-support-record";
export const OPERATION_SUPPORT_RECORD_DAILY = "operation-support-record-daily";
export const INITIAL = "/initial";
export const DOWNLOAD = "/download";
export const WORK_RECORDS = "/work-records";
export const PRINT = "/print";
export const PREVIEW = "/preview";
export const USER_INVOICES = "user-invoices";
export const USER_RECEIPTS = "user-receipts";
export const USER_AGENCY_RECEIPTS = "user-agency-receipts";
export const UPPER_LIMIT = "upper-limit";
export const USER_COST_AMOUNT_LIST = "user-cost-amount-list";
export const OPERATION_MONTHLY_REPORTS = "operation-monthly-reports";
export const SUPPORT_MONTHLY_REPORTS = "support-monthly-reports";
export const WORK_MONTHLY_REPORTS = "work-monthly-reports";
export const INTERVIEW_MONTHLY_REPORTS = "interview-monthly-reports";
export const INOUT = "inout";
export const INVOICE = "invoice";
export const CONTACT_COMPLETE = "/contact/complete";
export const WORKS = "/works";
export const STAFFS = "/staffs";
export const RECORD_SETTING = "/record/setting";
export const SETTING_ASSESSMENT = "/setting/assessment";
export const OFFSITE_WORK = "/record/offsite-work";
export const REMINDER_COMPLETE = "/password/reminder/complete";
export const RESET_REMINDER = "/password/resetreminder";
export const RESET_REMINDER_COMPLETE = "/password/resetreminder/complete";
export const ACCOUNT_ACTIVATE_COMPLETE = "/account/user/new/complete";
export const MOBILE_ACCOUNT_ACTIVATE_COMPLETE = "/account/mobile/complete";
export const SETTING_SUPPORT_LEDGER = "/setting/support-ledger";
export const CONSULTATION = "/record/consultation";
export const MUNICIPALITY = "/municipality";

// カスタマーサポートアカウント用
export const CUSTOMER_SUPPORT = "/customer-support";
export const CUSTOMER_SUPPORT_SWITCH_FACILITY = `${CUSTOMER_SUPPORT}/facility`;

// Assets URL
export const PRIVACYPOLICY_URL =
  "https://mgr.knowbe.jp/static/media/privacy_policy.pdf";
export const TERMS_URL = "https://mgr.knowbe.jp/static/media/terms.pdf";
export const MANUAL_URL = "https://knowberecruit.force.com/customers";
export const CONTACT =
  "https://knowberecruit.force.com/customers/s/contactsupport";
// 導入約款URL
export const INTRODUCTORY_CLAUSE =
  "https://cdn.p.recruit.co.jp/terms/kwb-t-1003/index.html";
// プライバシーポリシーURL
export const PRIVACY_POLICY =
  "https://cdn.p.recruit.co.jp/terms/clu-t-1001/index.html";
