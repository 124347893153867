import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState as IABUsersInFacilityState } from "@stores/domain/mgr/IAB/userInFacility/types";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import { AssessmentSheet } from "@components/organisms/mgr/IAB/record/AssessmentSheet";
import UserInfoRecordA from "@components/organisms/mgr/A/record/UserInfoRecord";
import { UserInfoRecord as UserInfoRecordIB } from "@components/organisms/mgr/IAB/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

// utils
import * as URL from "@constants/url";
import { FacilityType } from "@constants/variables";
import { FacilityState } from "@stores/domain/mgr/IAB/facility/types";
import { SupportPlanAState } from "@stores/domain/mgr/A/supportPlan/types";
import { SupportPlanState } from "@stores/domain/supportPlan/types";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  assessmentId: string;
  copyId: string;
}>;

type StateProps = {
  facility: FacilityState;
  supportPlanRecord:
    | SupportPlanAState["supportPlan"]
    | SupportPlanState["supportPlan"];
  user:
    | IABUsersInFacilityState["user"]
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"];
  facilityType: FacilityType;
};

type DispatchProps = {
  fetchSupportPlan: (uifId: string) => void;
  fetchOne: (uifId: string, facilityType: FacilityType) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 支援計画 > アセスメント一覧
 */
const AssessmentCore = (props: Props): JSX.Element => {
  const { uifId, assessmentId, copyId } = props.match.params;
  const { classes, facilityType } = props;
  const [isNew] = React.useState(assessmentId === "new"); // 新規作成
  const [isView] = React.useState(!isNew && !copyId); // 表示
  const [isCopy] = React.useState(!!copyId); // コピー中
  const [isEditing, setEditing] = React.useState(isNew || !isView || isCopy); // 編集ができる状態かどうか

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "アセスメント",
      path: `/record/support_plan/${uifId}/assessment`
    }
  ];

  // 現在ページのタイトル名
  const pageName = "アセスメントシート";

  React.useEffect(() => {
    props.fetchSupportPlan(uifId);
    props.fetchOne(uifId, facilityType);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {facilityType === FacilityType.A ? (
          <UserInfoRecordA
            userName={props.userName}
            user={props.user}
            facility={props.facility}
            supportPlan={
              props.supportPlanRecord as SupportPlanAState["supportPlan"]
            }
            isEditing={isEditing}
          />
        ) : (
          <UserInfoRecordIB
            userName={props.userName}
            user={props.user}
            facility={props.facility}
            supportPlan={
              props.supportPlanRecord as SupportPlanState["supportPlan"]
            }
            isEditing={isEditing}
            facilityType={facilityType}
          />
        )}
      </div>
      <div className={classes.wrapper}>
        <AssessmentSheet
          userName={props.userName}
          assessmentId={assessmentId}
          copyId={copyId}
          uifId={uifId}
          history={props.history}
          isNew={isNew}
          isView={isView}
          isCopy={isCopy}
          isEditing={isEditing}
          setEditing={setEditing}
          facilityType={facilityType}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facility: state.IAB.facility,
    supportPlanRecord:
      facilityType === FacilityType.A
        ? state.A.supportPlan.supportPlan
        : state.supportPlan.supportPlan,
    facilityType,
    user: state.IAB.userInFacility.user
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { supportPlanDispatcher } = dispatches;
  const supportPlanDispatches = supportPlanDispatcher(dispatch);
  return {
    fetchSupportPlan: (uifId: string): void => {
      supportPlanDispatches.fetchSupportPlan(uifId);
    },
    fetchOne: (uifId: string): void => {
      dispatches.IAB.userInFacilityDispatcher(dispatch).fetchOne(uifId);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const Assessment = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(AssessmentCore));
