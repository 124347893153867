/**
 * 配列からboolean, undefined, nullを除外したものを返す
 */
const makeValidDataList = <T>(
  dataList: (boolean | undefined | null | T)[]
): T[] => {
  return dataList.filter(
    (v): v is T => v !== undefined && v !== null && typeof v !== "boolean"
  );
};

export default makeValidDataList;
