import * as React from "react";

import { convertWareki } from "@utils/date";

import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/domain/invoice/type";

import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { KODOENGO_DISPATCH_NO_LIST } from "@constants/mgr/KODOENGO/variables";

const PRINT_PAGE_MAX_HEIGHT = 950;
const PRINT_END_PAGE_MAX_HEIGHT = 850;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.thin": {
        border: "1px solid"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 20,
        wordBreak: "break-all",
        overflow: "hidden",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.miniLabel": {
          width: 45,
          textAlign: "center",
          "& > div": {
            width: 40,
            transform: "scale(0.8) translate(0px, 0px)",
            whiteSpace: "nowrap"
          }
        },
        "&.ssmall": {
          height: 20,
          whiteSpace: "nowrap"
        },
        "&.sssssize": {
          width: 22
        },
        "&.sssize": {
          width: 40,
          whiteSpace: "nowrap"
        },
        "&.ssize": {
          width: 80
        },
        "&.mssize": {
          width: 50
        },
        "&.mmsize": {
          width: 100,
          whiteSpace: "nowrap"
        },
        "&.msize": {
          width: 120
        },
        "&.llsize": {
          width: 200
        },
        "&.vertical": {
          height: 50,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDoubleTop": {
          borderTopStyle: "double",
          borderTopWidth: "4px"
        }
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    empty7: {
      width: "102%",
      transform: "matrix(-2.7, 2.35, 1, -1.5, -6, 5)",
      borderTop: "solid black 1px"
    },
    empty8: {
      width: "138%",
      transform: "matrix(-0.85, 3.85, 1, -1, -3.5, 0.3)",
      borderTop: "solid black 1px"
    },
    empty9: {
      width: "138%",
      transform: "matrix(-8.35, 3.25, 1, -1, -45.5, 4)",
      borderTop: "solid black 1px"
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 行動援護の実績記録票
 */
const InoutRecordTable = (props: Props): JSX.Element => {
  const { key, invoiceData, classes } = props;
  const { date, facility, users } = invoiceData;
  let pageSum = 1;

  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;

        // デフォルトの高さ定義
        const defaultHeight = 25;
        const emptyHeight = 23;
        const indexArray: number[] = [0];
        const data = user.inout_results_kodoengo
          ? user.inout_results_kodoengo.reduce((sum, record, i) => {
              const len = record.memo ? record.memo.length : 0;
              const numberOfLines = Math.ceil(len / 15);
              const isLastData =
                user.inout_results_kodoengo &&
                user.inout_results_kodoengo.length - 1 === i;
              let result = defaultHeight;
              // 備考が1行より多い場合、備考の文字数から高さを導出
              if (numberOfLines > 1) {
                result = defaultHeight + (numberOfLines - 1) * 23;
              }

              // 該当行が次のページかどうかを判定
              if (
                sum + result > PRINT_PAGE_MAX_HEIGHT ||
                (isLastData && sum + result > PRINT_END_PAGE_MAX_HEIGHT)
              ) {
                indexArray.push(i);
              } else {
                result = sum + result;
              }
              return result;
            }, 0)
          : 0;

        // 空行の行数を導出
        const marginLength = Math.floor(
          (PRINT_END_PAGE_MAX_HEIGHT - data) / emptyHeight
        );
        // ページ数
        pageSum = indexArray.length;
        const inoutResultsKodoengoSize = user.inout_results_kodoengo
          ? user.inout_results_kodoengo.length
          : 0;

        return (
          <React.Fragment key={keyIndex}>
            {indexArray.map((firstNumberOfPage, pageIndex) => {
              const keyPageIndex = pageIndex;
              return (
                <section
                  key={`InoutRecordTable-${keyIndex}-${keyPageIndex}`}
                  className={classes.page}
                >
                  <div className={`${classes.flexContainer} center relative`}>
                    <div className={classes.date}>
                      <span>{`${convertWareki(date.year, date.month).era}${
                        convertWareki(date.year, date.month).year
                      }年${date.month}月分`}</span>
                    </div>
                    <span>行動援護サービス提供実績記録票</span>
                  </div>
                  <div className={classes.flexContainer}>
                    <table className={`${classes.table} fullWidth`}>
                      <tbody>
                        <tr>
                          <td className="label ssize">
                            受給者証
                            <br />
                            番号
                          </td>
                          <td className="label ssize">{user.recipientNo}</td>
                          <td className="label msize">
                            支給決定障害者等氏名
                            <br />
                            (障害児氏名)
                          </td>
                          <td className="label llsize">
                            {user.guardianName
                              ? user.guardianName
                              : user.recipientName}
                            <br />
                            {user.guardianName ? `(${user.recipientName})` : ""}
                          </td>
                          <td className="label" colSpan={2}>
                            事業所番号
                          </td>
                          <td className="label ssize">{facility.officeNo}</td>
                        </tr>
                        <tr>
                          <td className="label">契約支給量</td>
                          <td colSpan={3} className="ssize">
                            {user.payment}
                          </td>
                          <td className="label ssize">
                            事業者及び
                            <br />
                            その事業所
                          </td>
                          <td colSpan={2} className="label">
                            {facility.officeName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.flexContainer}>
                    {pageSum === pageIndex + 1 ? (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsKodoengoIndex={firstNumberOfPage}
                        inoutResultsKodoengoSize={
                          inoutResultsKodoengoSize - firstNumberOfPage
                        }
                        marginLength={marginLength}
                        isEndRow
                      />
                    ) : (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsKodoengoIndex={firstNumberOfPage}
                        inoutResultsKodoengoSize={
                          indexArray[pageIndex + 1] - firstNumberOfPage
                        }
                        marginLength={0}
                        isEndRow={false}
                      />
                    )}
                  </div>
                  <div className={`${classes.flexContainer} end`}>
                    <table className={`${classes.table} thin`}>
                      <tbody>
                        <tr>
                          <td className="label">{pageSum}</td>
                          <td className="label">枚中</td>
                          <td className="label">{pageIndex + 1}</td>
                          <td className="label">枚</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              );
            })}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};
type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
  inoutResultsKodoengoIndex: number;
  inoutResultsKodoengoSize: number;
  marginLength: number;
  isEndRow: boolean;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const {
    user,
    classes,
    inoutResultsKodoengoIndex,
    inoutResultsKodoengoSize,
    isEndRow,
    marginLength
  } = props;
  const inoutResultsKodoengo = user.inout_results_kodoengo
    ? user.inout_results_kodoengo
    : [];
  const emptyList = new Array<undefined>(marginLength).fill(undefined);
  const records = [
    ...inoutResultsKodoengo.slice(
      inoutResultsKodoengoIndex,
      inoutResultsKodoengoIndex + inoutResultsKodoengoSize
    ),
    ...emptyList
  ];

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={2} className="label sssssize vertical">
            <span>日付</span>
          </td>
          <td rowSpan={2} className="label sssssize vertical">
            <span>曜日</span>
          </td>
          <td rowSpan={2} className="label sssssize vertical borderBold" />
          <td colSpan={3} className="label borderBold">
            行動援護計画
          </td>
          <td colSpan={2} className="label mmsize borderBold">
            サービス提供時間
          </td>
          <td rowSpan={2} className="label mssize borderBold">
            算定
            <br />
            時間
          </td>
          <td rowSpan={2} className="label sssssize">
            派<br />遣<br />人<br />数
          </td>
          <td rowSpan={2} className="label sssize">
            初回
            <br />
            加算
          </td>
          <td rowSpan={2} className="label sssize">
            緊急時
            <br />
            対応
            <br />
            加算
          </td>
          <td rowSpan={2} className="label sssize borderBold">
            行動障
            <br />
            害支援
            <br />
            指導連
            <br />
            携加算
          </td>
          <td rowSpan={2} className="label miniLabel borderBold">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={2} className="label llsize borderBold">
            備考
          </td>
        </tr>
        <tr>
          <td className="label ">
            開始
            <br />
            時間
          </td>
          <td className="label ">
            終了
            <br />
            時間
          </td>
          <td className="label sssize borderBold">
            計画
            <br />
            時間数
          </td>
          <td className="label ">
            開始
            <br />
            時間
          </td>
          <td className="label borderBold">
            終了
            <br />
            時間
          </td>
        </tr>
        {records.map((inout_results_kodoengo, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";
          const serialNumberValue =
            inout_results_kodoengo && inout_results_kodoengo.serialNumber
              ? inout_results_kodoengo.serialNumber.toString()
              : "";

          return (
            <tr
              key={`Records-${inoutResultsKodoengoIndex}-${keyIndex}`}
              className={rowClass}
            >
              <td className="label ssmall">
                {inout_results_kodoengo ? inout_results_kodoengo.day : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kodoengo ? inout_results_kodoengo.dayOfWeek : ""}
              </td>
              <td className="label borderBold ssmall">
                {inout_results_kodoengo && serialNumberValue.length
                  ? getLabelFromOptions(
                      serialNumberValue,
                      KODOENGO_DISPATCH_NO_LIST
                    )
                  : ""}
              </td>
              <td className="label sssize ssmall">
                {inout_results_kodoengo
                  ? inout_results_kodoengo.planInTime
                  : ""}
              </td>
              <td className="label sssize ssmall">
                {inout_results_kodoengo
                  ? inout_results_kodoengo.planOutTime
                  : ""}
              </td>
              <td className="label ssmall borderBold">
                {inout_results_kodoengo ? inout_results_kodoengo.planHours : ""}
              </td>
              <td className="label sssize ssmall">
                {inout_results_kodoengo ? inout_results_kodoengo.inTime : ""}
              </td>
              <td className="label sssize ssmall borderBold">
                {inout_results_kodoengo ? inout_results_kodoengo.outTime : ""}
              </td>
              <td className="label ssmall borderBold">
                {inout_results_kodoengo
                  ? inout_results_kodoengo.calculatedHours
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kodoengo
                  ? inout_results_kodoengo.dispatchedPeople
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kodoengo &&
                inout_results_kodoengo.isFirstAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_results_kodoengo &&
                inout_results_kodoengo.isEmergencySupport
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall borderBold">
                {inout_results_kodoengo &&
                inout_results_kodoengo.isBehaviorDisorderCoaching
                  ? "1"
                  : ""}
              </td>
              <td className="label borderBold ssmall" />
              <td className="borderBold">
                {inout_results_kodoengo && inout_results_kodoengo.memo
                  ? inout_results_kodoengo.memo
                  : ""}
              </td>
            </tr>
          );
        })}
        {isEndRow && (
          <>
            <tr className="borderDoubleTop">
              <td rowSpan={2} colSpan={5} className="label borderBold">
                合計
              </td>
              <td rowSpan={1} className="label sssize borderBold">
                計画
                <br />
                時間数計
              </td>
              <td rowSpan={2} colSpan={2} className="label borderBold">
                <div className={classes.empty7}> </div>
              </td>
              <td rowSpan={1} className="label sssize borderBold">
                算定
                <br />
                時間数計
              </td>
              <td rowSpan={2} className="label">
                <div className={classes.empty8}> </div>
              </td>
              <td rowSpan={1} className="label">
                初回
                <br />
                加算
              </td>
              <td rowSpan={1} className="label">
                緊急時
                <br />
                対応
                <br />
                加算
              </td>
              <td rowSpan={1} className="label borderBold">
                行動障
                <br />
                害支援
                <br />
                指導連
                <br />
                携加算
              </td>
              <td rowSpan={2} colSpan={3} className="label">
                <div className={classes.empty9}> </div>
              </td>
            </tr>
            <tr className="borderBoldTop">
              <td className="label borderBold">{`${user.cntPlanHours}`}</td>
              <td className="label borderBold">{`${user.cntCalculatedHours}`}</td>
              <td className="label">{`${user.cntFirstAddition}`}回</td>
              <td className="label">{`${user.cntEmergencySupport}`}回</td>
              <td className="label borderBold">
                {`${user.cntBehaviorDisorderCoaching}`}回
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export const KODOENGOJisseki = withStyles(styles)(InoutRecordTable);
