import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import LackFields from "@components/v202104/organisms/mgr/common/facility/items/LackFields";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FormGroup } from "@material-ui/core";
import { FormikProps } from "formik";
import { FacilityType, SCORE_LIST } from "@constants/variables";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiTextField from "@components/molecules/MuiTextField";
import {
  AverageMonthlyWageType,
  AverageWorkDurationsType,
  DIVERSIFIED_WORK_STYLES_TYPE,
  IMPROVEMENT_OF_SUPPORTS_TYPE,
  ProductionActivitiesType,
  RegionalCooperationActivitiesType,
  REWARD_TYPE_ITEMS
} from "@constants/mgr/IAB/variables";
import castNumber from "@utils/dataNormalizer/castNumber";
import { generateRadioItems } from "@utils/dataNormalizer";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { FacilityValues } from "@initialize/v202104/mgr/IAB/facility/initialValues";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    },
    section: {
      marginBottom: 32
    }
  });

const AverageWorkDurationRadioItems = generateRadioItems(
  AverageWorkDurationsType
);

const ProductionActivityRadioItems = generateRadioItems(
  ProductionActivitiesType
);

const RegionalCooperationActivityRadioItems = generateRadioItems(
  RegionalCooperationActivitiesType
);

const AverageMonthlyWageRadioItems = generateRadioItems(AverageMonthlyWageType);
type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const bottomItem = { marginBottom: 64 };
  const getTotalScore = (): number => {
    return (
      SCORE_LIST.averageWorkDuration[
        castNumber(props.formikProps.values.subtractionItem.averageWorkDuration)
      ] +
      SCORE_LIST.productionActivity[
        castNumber(props.formikProps.values.subtractionItem.productionActivity)
      ] +
      SCORE_LIST.diversifiedWorkStyle[
        castNumber(
          props.formikProps.values.subtractionItem.diversifiedWorkStyle
        )
      ] +
      SCORE_LIST.improvementOfSupport[
        castNumber(
          props.formikProps.values.subtractionItem.improvementOfSupport
        )
      ] +
      SCORE_LIST.regionalCooperationActivity[
        castNumber(
          props.formikProps.values.subtractionItem.regionalCooperationActivity
        )
      ]
    );
  };

  return (
    <FormPaper>
      {(props.formikProps.values.basic.serviceType === FacilityType.A ||
        props.formikProps.values.basic.serviceType === FacilityType.B) && (
        <div className={props.classes.section}>
          <SectionTitle label="基本報酬" />
        </div>
      )}
      {props.formikProps.values.basic.serviceType === FacilityType.A && (
        <FormGroup row={false}>
          <FormikRadioButtons
            name="subtractionItem.averageWorkDuration"
            label="利用者の1日平均労働時間"
            options={AverageWorkDurationRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="averageWorkDuration" />}
              />
            }
          />
          <FormikRadioButtons
            name="subtractionItem.productionActivity"
            label="生産活動"
            options={ProductionActivityRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="productionActivity" />}
              />
            }
          />
          <FormikRadioButtons
            name="subtractionItem.diversifiedWorkStyle"
            label="多様な働き方"
            options={DIVERSIFIED_WORK_STYLES_TYPE}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="diversifiedWorkStyle" />}
              />
            }
          />
          <FormikRadioButtons
            name="subtractionItem.improvementOfSupport"
            label="支援力向上"
            options={IMPROVEMENT_OF_SUPPORTS_TYPE}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="improvementOfSupport" />}
              />
            }
          />
          <FormikRadioButtons
            name="subtractionItem.regionalCooperationActivity"
            label="地域連携活動"
            options={RegionalCooperationActivityRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="regionalCooperationActivity" />}
              />
            }
          />
          <MuiTextField
            value={getTotalScore()}
            label="基本報酬 スコア合計点"
            endAdornmentLabel="点/200点"
            disabled
            disabledStyle
            style={bottomItem}
          />
        </FormGroup>
      )}
      {props.formikProps.values.basic.serviceType === FacilityType.B && (
        <FormGroup row={false}>
          <FormikRadioButtons
            name="subtractionItem.rewardType"
            label="報酬体系"
            options={REWARD_TYPE_ITEMS}
          />
          <FormikRadioButtons
            name="subtractionItem.averageMonthlyWage"
            label="平均工賃の月額"
            disabled={
              props.formikProps.values.subtractionItem.rewardType ===
              REWARD_TYPE_ITEMS[1].value
            }
            options={AverageMonthlyWageRadioItems}
            style={bottomItem}
          />
        </FormGroup>
      )}
      <div className={props.classes.section}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.establishedByLocalGovernmentsFlag"
        label="地方公共団体が設置"
      />
      <LackFields formikProps={props.formikProps} name="subtractionItem" />
      {props.formikProps.values.basic.serviceType === FacilityType.A && (
        <FormikCheckbox
          name="subtractionItem.evaluationContentsUndisclosed"
          label="自己評価未公表"
          style={{ marginBottom: 0 }}
        />
      )}
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
