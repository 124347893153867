/**
 * ActionNames
 */

export const FETCH_STARTED = "KODOENGO/FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "KODOENGO/FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "KODOENGO/FACILITY/FETCH_FAILED";
export const POST_STARTED = "KODOENGO/FACILITY/POST_STARTED";
export const POST_SUCCESS = "KODOENGO/FACILITY/POST_SUCCESS";
export const POST_FAILED = "KODOENGO/FACILITY/POST_FAILED";

/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  capacity: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
  multiFunctionOfficeFlag: boolean;
  masterSubordinateFlg: boolean;
  masterFlg: string;
  allCapacity: string;

  // FacilityAdditionItemSection
  specificBetterSupporterCondition202404: string | null;
  staffTreatmentSystemType: string;
  staffTreatmentSpecificSystemType: string;
  specificFacilitiesAddition: string;
  betterSupporterConditionBaseUpFlg: boolean;
  lifeSupportHubInDistrictFlg: boolean;

  // SubtractionItemSection
  bodyRestrictedStillFlg: boolean;
  bodyRestrictedStillFrom: string;
  bodyRestrictedStillTo: string;
  abusePreventionMeasuresNotImplementedFlg: boolean;
  abusePreventionMeasuresNotImplementedFrom: string;
  abusePreventionMeasuresNotImplementedTo: string;
  informationDisclosureNotReportedFlg: boolean;
  informationDisclosureNotReportedFrom: string;
  informationDisclosureNotReportedTo: string;
};
