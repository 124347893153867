/**
 * " HH:NN" 形式の正規表現結果を返す
 * @param t " HH:NN" 形式の文字列 ( 先頭にブランクが含まれない場合すべて null 扱いされる )
 */
const getTime = (t: string): RegExpExecArray | null => {
  return / [0-1][0-9]:[0-5][0-9]/.exec(t)
    ? / [0-1][0-9]:[0-5][0-9]/.exec(t)
    : / [2][0-3]:[0-5][0-9]/.exec(t);
};

export default getTime;
