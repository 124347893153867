import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";

// api
import { GetFacilityUsersResponseElement } from "@api/requests/facility/getFacilityUsers";

// stores
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UnitsState } from "@stores/domain/units/types";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import { unsetUsersSummarySHISETSUNYUSHO } from "@stores/domain/mgr/SHISETSUNYUSHO/usersSummary/actions";

// components
import AdminTemplate from "@components/templates/AdminTemplate";
import RecordBatchPrinting from "@components/organisms/mgr/common/record/RecordBatchPrinting";
import { RecordEachUserGroup } from "@components/organisms/mgr/common/record/recordEachUser/RecordEachUserGroup";

// constants
import {
  RECORD_BATCH_PRINTING_OPTIONS,
  RecordEachUserButtonsType
} from "@constants/variables";
import { BASE_PUBLIC_URL } from "@config";

type StateProps = {
  users: GetFacilityUsersResponseElement[];
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  errorState: ErrorsState["records"]["data"];
  userState: UserState;
};

type DispatchProps = {
  fetchUsers: () => Promise<void>;
  fetchErrors: () => Promise<void>;
  fetchOptionalCustomInfo: () => Promise<void>;
  unsetUsersSummary: () => void;
};
type MergeProps = StateProps & DispatchProps & RouteComponentProps;

type Props = MergeProps;

// 利用者ごと 利用者一覧に表示されるボタンリスト
const buttonList: React.ComponentProps<
  typeof RecordEachUserGroup
>["buttonList"] = (uifId: number): RecordEachUserButtonsType => {
  return [
    {
      name: "個別支援計画",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support_plan`
    },
    {
      name: "支援記録",
      url: `${BASE_PUBLIC_URL}#/record/${uifId}/support`,
      isLastButton: true
    }
  ];
};

/**
 * 利用者サマリ
 */
const RecordUsersSummaryCore = (props: Props): JSX.Element => {
  const {
    fetchOptionalCustomInfo,
    fetchUsers,
    fetchErrors,
    users,
    optionalCustomInfo,
    errorState,
    userState
  } = props;

  return (
    <AdminTemplate pageName="利用者ごと">
      {/* 記録の一括印刷 */}
      <RecordBatchPrinting
        history={props.history}
        options={RECORD_BATCH_PRINTING_OPTIONS}
        defaultSelected="supportB"
        optionalCustomInfo={props.optionalCustomInfo}
        isUseCustomRecord
        unsetUsersSummary={props.unsetUsersSummary}
      />
      {/* 利用ごとの記録 */}
      <RecordEachUserGroup
        users={users}
        errorState={errorState}
        buttonList={buttonList}
        optionalCustomInfo={optionalCustomInfo}
        userState={userState}
        fetchOptionalCustomInfo={fetchOptionalCustomInfo}
        fetchUsers={fetchUsers}
        fetchErrors={fetchErrors}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  users: state.SHISETSUNYUSHO.userInFacility.users,
  optionalCustomInfo: state.units.optionalCustomInfo,
  errorState: state.errors.records.data,
  userState: state.user
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { SHISETSUNYUSHO, errorsDispatcher, unitsDispatcher } = dispatches;
  const userInFacilityDispatcher = SHISETSUNYUSHO.userInFacilityDispatcher(
    dispatch
  );
  const errorsDispatches = errorsDispatcher(dispatch);
  const unitsDispatches = unitsDispatcher(dispatch);
  return {
    fetchUsers: (): Promise<void> => userInFacilityDispatcher.fetch(),
    fetchErrors: (): Promise<void> => errorsDispatches.records(),
    fetchOptionalCustomInfo: (): Promise<void> =>
      unitsDispatches.fetchOptionalCustomInfo(),
    unsetUsersSummary: (): void => {
      dispatch(unsetUsersSummarySHISETSUNYUSHO());
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: RouteComponentProps
): MergeProps => {
  return {
    ...dispatchProps,
    ...stateProps,
    ...ownProps
  };
};

export const RecordUsersSummary = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(RecordUsersSummaryCore);
