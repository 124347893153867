import * as types from "./types";
import { GetSupportProcedureListResponse } from "@api/requests/supportProcedure/getSupportProcedureList";
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { ResponseErrorState } from "@stores/ui/responseError/types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchSupportProcedureStarted = () =>
  ({ type: types.FETCH_SUPPORT_PROCEDURE_LIST_STARTED } as const);
export const fetchSupportProcedureSuccess = (
  res: GetSupportProcedureListResponse["data"]
) =>
  ({ type: types.FETCH_SUPPORT_PROCEDURE_LIST_SUCCESS, payload: res } as const);
export const fetchSupportProcedureFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_SUPPORT_PROCEDURE_LIST_FAILED, error: err } as const);

export const deleteSupportProcedureDetailStarted = () =>
  ({ type: types.DELETE_SUPPORT_PROCEDURE_LIST_STARTED } as const);
export const deleteSupportProcedureDetailSuccess = () =>
  ({ type: types.DELETE_SUPPORT_PROCEDURE_LIST_SUCCESS } as const);
export const deleteSupportProcedureDetailFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_SUPPORT_PROCEDURE_LIST_FAILED, error: err } as const);
export const fetchModalSupportProcedureStarted = () =>
  ({ type: types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_STARTED } as const);
export const fetchModalSupportProcedureSuccess = (
  res: GetSupportProcedureListResponse["data"]
) =>
  ({
    type: types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_SUCCESS,
    payload: res
  } as const);
export const fetchModalSupportProcedureFailed = (err: ErrorResponse) =>
  ({
    type: types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_FAILED,
    error: err
  } as const);

export const fetchSupportProcedureDetailStarted = () =>
  ({ type: types.FETCH_SUPPORT_PROCEDURE_DETAIL_STARTED } as const);
export const fetchSupportProcedureDetailSuccess = (
  res: GetSupportProcedureDetailResponse["data"]
) =>
  ({
    type: types.FETCH_SUPPORT_PROCEDURE_DETAIL_SUCCESS,
    payload: res
  } as const);
export const fetchSupportProcedureDetailFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_SUPPORT_PROCEDURE_DETAIL_FAILED, error: err } as const);

export const postSupportProcedureDetailStarted = () =>
  ({ type: types.POST_SUPPORT_PROCEDURE_DETAIL_STARTED } as const);
export const postSupportProcedureDetailSuccess = () =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_SUCCESS
  } as const);
export const postSupportProcedureDetailFailed = (err: ErrorResponse) =>
  ({ type: types.POST_SUPPORT_PROCEDURE_DETAIL_FAILED, error: err } as const);

export const postSupportProcedureDetailFormStarted = () =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_STARTED
  } as const);
export const postSupportProcedureDetailFormSuccess = () =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_SUCCESS
  } as const);
export const postSupportProcedureDetailFormFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_FAILED,
    payload: err
  } as const);

export const postSupportProcedureDetailFormRecordDeleteStarted = () =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_STARTED
  } as const);
export const postSupportProcedureDetailFormRecordDeleteSuccess = () =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_SUCCESS
  } as const);
export const postSupportProcedureDetailFormRecordDeleteFailed = (
  err: ErrorResponse
) =>
  ({
    type: types.POST_SUPPORT_PROCEDURE_DETAIL_FORM_RECORD_DELETE_FAILED,
    payload: err
  } as const);

export const fetchPrintRecordStarted = () =>
  ({ type: types.FETCH_PRINT_RECORD_STARTED } as const);
export const fetchPrintRecordSuccess = (
  res: types.SupportProcedureState["print"]
) => ({ type: types.FETCH_PRINT_RECORD_SUCCESS, payload: res } as const);
export const fetchPrintRecordFailed = (err: { error: ResponseErrorState }) =>
  ({ type: types.FETCH_PRINT_RECORD_FAILED, error: err } as const);

export const clearSupportProcedureDetailState = () =>
  ({ type: types.CLEAR_SUPPORT_PROCEDURE_DETAIL_STATE } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportProcedureStarted>
  | ReturnType<typeof fetchSupportProcedureSuccess>
  | ReturnType<typeof fetchSupportProcedureFailed>
  | ReturnType<typeof deleteSupportProcedureDetailStarted>
  | ReturnType<typeof deleteSupportProcedureDetailSuccess>
  | ReturnType<typeof deleteSupportProcedureDetailFailed>
  | ReturnType<typeof fetchModalSupportProcedureStarted>
  | ReturnType<typeof fetchModalSupportProcedureSuccess>
  | ReturnType<typeof fetchModalSupportProcedureFailed>
  | ReturnType<typeof fetchSupportProcedureDetailStarted>
  | ReturnType<typeof fetchSupportProcedureDetailSuccess>
  | ReturnType<typeof fetchSupportProcedureDetailFailed>
  | ReturnType<typeof postSupportProcedureDetailStarted>
  | ReturnType<typeof postSupportProcedureDetailSuccess>
  | ReturnType<typeof postSupportProcedureDetailFailed>
  | ReturnType<typeof fetchPrintRecordStarted>
  | ReturnType<typeof fetchPrintRecordSuccess>
  | ReturnType<typeof fetchPrintRecordFailed>
  | ReturnType<typeof clearSupportProcedureDetailState>;
