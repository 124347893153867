import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import UsageResultCell from "@components/v201910/organisms/mgr/SHUROTEICHAKU/report/cell/UsageResultCell";
import { UsageResult } from "@stores/v201910/domain/mgr/SHUROTEICHAKU/report/types";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import UsageResultEditCell from "@components/v201910/organisms/mgr/SHUROTEICHAKU/report/cell/UsageResultEditCell";

const styles = (): StyleRules =>
  createStyles({
    cell: {
      boxSizing: "content-box",
      paddingLeft: 16,
      height: 56
    },
    dateCell: {
      width: "25%",
      minWidth: 260
    }
  });

type OwnProps = {
  params: UsageResult;
  idx: number;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UsageResultListCell = (props: Props): JSX.Element => {
  const { params, idx, isEditing } = props;
  return (
    <>
      {/* 日付 */}
      <TableCellWrap
        key={`${idx}-target-date`}
        cellClass={`${props.classes.cell} ${props.classes.dateCell}`}
      >
        <CustomDateLabel
          date={params.targetDate ? params.targetDate : ""}
          dateFormat="Do（dd）"
          holiday={params.isHoliday ? params.isHoliday : false}
        />
      </TableCellWrap>
      {isEditing ? (
        <UsageResultEditCell idx={idx} />
      ) : (
        <UsageResultCell params={params} idx={idx} />
      )}
    </>
  );
};

export default withStyles(styles)(UsageResultListCell);
