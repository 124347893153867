import * as React from "react";
import { convertWareki, dateToObject, parseDateString } from "@utils/date";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import { InvoiceData, InvoiceUser } from "@stores/v202104/domain/invoice/type";
import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { FacilityType } from "@constants/variables";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { CHIIKIIKO_DISPLAY_SERVICE_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";

const PRINT_PAGE_MAX_HEIGHT = 950;
const PRINT_END_PAGE_MAX_HEIGHT = 830;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    flexContainer: {
      display: "flex",
      maxHeight: PRINT_PAGE_MAX_HEIGHT,
      justifyContent: "flex-start",
      marginBottom: 8,
      "&.end": {
        justifyContent: "flex-end"
      },
      "&.center": {
        justifyContent: "center"
      },
      "&.relative": {
        position: "relative"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 1px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        height: 20,
        wordBreak: "break-all",
        overflow: "hidden",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.ssmall": {
          height: 20,
          whiteSpace: "nowrap"
        },
        "&.header-size1": {
          width: 62
        },
        "&.header-size2": {
          width: 66
        },
        "&.header-size3": {
          width: 80
        },
        "&.header-size4": {
          width: 112
        },
        "&.header-size5": {
          width: 155
        },
        "&.item-size1": {
          width: 24
        },
        "&.item-size2": {
          width: 40
        },
        "&.item-size3": {
          width: 52
        },
        "&.item-size4": {
          width: 68
        },
        "&.item-size5": {
          width: 80
        },
        "&.item-size6": {
          width: 140
        },
        "&.border-size1": {
          width: 135
        },
        "&.border-size2": {
          width: 137
        },
        "&.vertical": {
          height: 74,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBoldTop": {
          borderTop: "2px solid"
        },
        "&.borderDoubleTop": {
          borderTopStyle: "double",
          borderTopWidth: "4px"
        }
      }
    },
    date: {
      position: "absolute",
      top: 0,
      left: 0
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    empty1: {
      width: "138%",
      transform: "matrix(-4.15, 1.85, 1, -1, -25.5, 1.5)",
      borderTop: "solid black 1px"
    },
    empty2: {
      width: "138%",
      transform: "matrix(-2.25, 3.85, 1, -1, -6.0, 0.3)",
      borderTop: "solid black 1px"
    },
    empty3: {
      width: "138%",
      transform: "matrix(-7.1, 1.97, 1, -1, -45, 0)",
      borderTop: "solid black 1px"
    }
  });

type Props = WithStyles<typeof styles> & {
  key: number;
  invoiceData: InvoiceData;
};

/**
 * 地域移行の実績記録票
 */
const InoutRecordTable = (props: Props): JSX.Element => {
  const { key, invoiceData, classes } = props;
  const { date, facility, users } = invoiceData;
  let pageSum = 1;

  return (
    <React.Fragment key={key}>
      {users.map((user, index) => {
        const keyIndex = index;

        // デフォルトの高さ定義
        const defaultHeight = 20;
        const indexArray: number[] = [0];
        const numberYear = parseInt(date.year, 10);
        const numberMonth = parseInt(date.month, 10);
        // 月末日
        const lastDayOfMonth =
          !Number.isNaN(numberYear) && !Number.isNaN(numberMonth)
            ? new Date(numberYear, numberMonth, 0).getDate()
            : null;
        const data = user.inout_consultation_results
          ? user.inout_consultation_results.reduce((sum, record, i) => {
              const len = record.memo ? record.memo.length : 0;
              const numberOfLines = Math.ceil(len / 20);
              const isLastData =
                user.inout_consultation_results &&
                user.inout_consultation_results.length - 1 === i;
              let result = defaultHeight;
              // 備考が1行より多い場合、備考の文字数から高さを導出
              if (numberOfLines > 1) {
                result = defaultHeight + (numberOfLines - 1) * 15;
              }

              // 該当行が次のページかどうかを判定
              if (
                sum + result > PRINT_PAGE_MAX_HEIGHT ||
                (isLastData && sum + result > PRINT_END_PAGE_MAX_HEIGHT)
              ) {
                indexArray.push(i);
              } else {
                result = sum + result;
              }
              return result;
            }, 0)
          : 0;

        // ページ数
        pageSum = indexArray.length;
        // 空行の行数
        let marginLength = 0;
        // データの個数＋空行が月の日数と一致かつ、PRINT_END_PAGE_MAX_HEIGHTを超えていないか
        if (user.inout_consultation_results && lastDayOfMonth) {
          const dataLength = user.inout_consultation_results.length;
          // 空行がある場合
          if (dataLength < lastDayOfMonth) {
            const calculatedEmptyLength = Math.floor(
              (PRINT_END_PAGE_MAX_HEIGHT - data) / defaultHeight
            );
            marginLength =
              calculatedEmptyLength > lastDayOfMonth - dataLength
                ? lastDayOfMonth - dataLength
                : calculatedEmptyLength;
          }
        }
        const inoutResultsChiikiikoSize = user.inout_consultation_results
          ? user.inout_consultation_results.length
          : 0;

        return (
          <>
            {indexArray.map((firstNumberOfPage, pageIndex) => {
              const keyPageIndex = pageIndex;
              const dateBeginHospital = user.dateBeginHospital
                ? dateToObject(parseDateString(user.dateBeginHospital))
                : undefined;
              const dateEndHospital = user.dateEndHospital
                ? dateToObject(parseDateString(user.dateEndHospital))
                : undefined;
              return (
                <section
                  key={`InoutRecordTable-${keyIndex}-${keyPageIndex}`}
                  className={classes.page}
                >
                  <div className={`${classes.flexContainer} center relative`}>
                    <div className={classes.date}>
                      <span>{`${convertWareki(date.year, date.month).era}${
                        convertWareki(date.year, date.month).year
                      }年${date.month}月分`}</span>
                    </div>
                    <span>地域移行支援提供実績記録票</span>
                  </div>
                  <div className={classes.flexContainer}>
                    <table className={`${classes.table} fullWidth`}>
                      <tbody>
                        <tr>
                          <td className="label header-size1">
                            受給者証
                            <br />
                            番号
                          </td>
                          <td className="label header-size3">
                            {user.recipientNo}
                          </td>
                          <td className="label header-size4">
                            支給決定障害者等氏名
                          </td>
                          <td className="label header-size5">
                            {user.recipientName}
                          </td>
                          <td className="label" colSpan={2}>
                            事業所番号
                          </td>
                          <td className="label header-size3">
                            {facility.officeNo}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={4} />
                          <td className="label header-size2">
                            事業者及び
                            <br />
                            その事業所
                          </td>
                          <td colSpan={2} className="label">
                            {facility.officeName}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={classes.flexContainer}>
                    {pageSum === pageIndex + 1 ? (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsChiikiikoIndex={firstNumberOfPage}
                        inoutResultsChiikiikoSize={
                          inoutResultsChiikiikoSize - firstNumberOfPage
                        }
                        marginLength={marginLength}
                        isEndRow
                      />
                    ) : (
                      <Records
                        classes={classes}
                        user={user}
                        facilityType={facility.kindService}
                        inoutResultsChiikiikoIndex={firstNumberOfPage}
                        inoutResultsChiikiikoSize={
                          indexArray[pageIndex + 1] - firstNumberOfPage
                        }
                        marginLength={0}
                        isEndRow={false}
                      />
                    )}
                  </div>
                  <div className={`${classes.flexContainer} end`}>
                    {pageSum === pageIndex + 1 && (
                      <table className={`${classes.table}`}>
                        <tbody>
                          <tr>
                            <td className="label border-size2">
                              退院・退所月加算
                            </td>
                            <td className="label border-size1">入院開始日</td>
                            <td className="label border-size1">
                              {dateBeginHospital ? (
                                <span>{`${
                                  convertWareki(
                                    dateBeginHospital.year,
                                    dateBeginHospital.month
                                  ).era
                                }${
                                  convertWareki(
                                    dateBeginHospital.year,
                                    dateBeginHospital.month
                                  ).year
                                }年${dateBeginHospital.month}月${
                                  dateBeginHospital.day
                                }日`}</span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="label border-size2">退院・退所日</td>
                            <td className="label border-size2">
                              {dateEndHospital ? (
                                <span>{`${
                                  convertWareki(
                                    dateEndHospital.year,
                                    dateEndHospital.month
                                  ).era
                                }${
                                  convertWareki(
                                    dateEndHospital.year,
                                    dateEndHospital.month
                                  ).year
                                }年${dateEndHospital.month}月${
                                  dateEndHospital.day
                                }日`}</span>
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </div>
                </section>
              );
            })}
          </>
        );
      })}
    </React.Fragment>
  );
};
type RecordsProps = WithStyles<typeof styles> & {
  user: InvoiceUser;
  facilityType: FacilityType;
  inoutResultsChiikiikoIndex: number;
  inoutResultsChiikiikoSize: number;
  marginLength: number;
  isEndRow: boolean;
};
/**
 * 内部レコード
 */
const Records = (props: RecordsProps): JSX.Element => {
  const {
    user,
    classes,
    inoutResultsChiikiikoIndex,
    inoutResultsChiikiikoSize,
    isEndRow,
    marginLength
  } = props;
  const inoutResultsChiikiiko = user.inout_consultation_results
    ? user.inout_consultation_results
    : [];
  const emptyList = new Array<undefined>(marginLength).fill(undefined);
  const records = [
    ...inoutResultsChiikiiko.slice(
      inoutResultsChiikiikoIndex,
      inoutResultsChiikiikoIndex + inoutResultsChiikiikoSize
    ),
    ...emptyList
  ];

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td rowSpan={2} className="label item-size1 vertical">
            <span>日付</span>
          </td>
          <td rowSpan={2} className="label item-size1 vertical borderBold">
            <span>曜日</span>
          </td>
          <td colSpan={4} className="label borderBold">
            支援実績
          </td>
          <td rowSpan={2} className="label item-size2 borderBold">
            利用者
            <br />
            確認欄
          </td>
          <td rowSpan={2} className="label borderBold">
            備考
          </td>
        </tr>
        <tr>
          <td className="label item-size5">算定日数</td>
          <td className="label item-size6">サービス提供の状況</td>
          <td className="label item-size3">
            初回
            <br />
            加算
          </td>
          <td className="label item-size4 borderBold">
            地域居住支援
            <br />
            体制強化推進
            <br />
            加算
          </td>
        </tr>
        {records.map((inout_consultation_results, index) => {
          const keyIndex = index;
          const rowClass = keyIndex === 0 ? "borderBoldTop" : "";
          const statusValue =
            inout_consultation_results &&
            inout_consultation_results.serviceStatus
              ? inout_consultation_results.serviceStatus.toString()
              : "0";

          return (
            <tr
              key={`Records-${inoutResultsChiikiikoIndex}-${keyIndex}`}
              className={rowClass}
            >
              <td className="label ssmall">
                {inout_consultation_results
                  ? inout_consultation_results.day
                  : ""}
              </td>
              <td className="label ssmall borderBold">
                {inout_consultation_results
                  ? inout_consultation_results.dayOfWeek
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_consultation_results &&
                inout_consultation_results.isApplyDays
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_consultation_results && statusValue !== "0"
                  ? getLabelFromOptions(
                      statusValue,
                      CHIIKIIKO_DISPLAY_SERVICE_STATUS_LIST
                    )
                  : ""}
              </td>
              <td className="label ssmall">
                {inout_consultation_results &&
                inout_consultation_results.isFirstAddition
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall borderBold">
                {inout_consultation_results &&
                inout_consultation_results.isHousingSupportPromotion
                  ? "1"
                  : ""}
              </td>
              <td className="label ssmall borderBold" />
              <td>
                {inout_consultation_results && inout_consultation_results.memo
                  ? inout_consultation_results.memo
                  : ""}
              </td>
            </tr>
          );
        })}
        {isEndRow && (
          <>
            <tr className="borderDoubleTop">
              <td colSpan={2} className="label borderBold" />
              <td className="label">算定日数</td>
              <td rowSpan={2} className="label">
                <div className={classes.empty1}> </div>
              </td>
              <td className="label">
                初回
                <br />
                加算
              </td>
              <td className="label borderBold">
                地域居住支援
                <br />
                体制強化推進
                <br />
                加算
              </td>
              <td rowSpan={2} className="label borderBold">
                <div className={classes.empty2}> </div>
              </td>
              <td rowSpan={2} className="label">
                <div className={classes.empty3}> </div>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="label borderBold">
                合計
              </td>
              <td className="label">{`${user.applyDays}`}日</td>
              <td className="label">{`${user.cntFirstAddition}`}回</td>
              <td className="label borderBold">
                {`${user.cntHousingSupportPromotion}`}回
              </td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
};

export const CHIIKIIKOJisseki = withStyles(styles)(InoutRecordTable);
