import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";

import initialValues from "@initialize/contact/initialValues";
import { ContactValues } from "@interfaces/contact/contact";
import validation from "@initialize/contact/validation";
import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import ContactFields from "@components/organisms/contact/ContactFields";
import * as H from "history";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      padding: spacing.unit * 4
    }
  });

type OwnProps = {
  facilityName: string;
  businessOwner: string;
  history: H.History;
};

type DispatchProps = {
  postContact: (params: ContactValues, history: H.History) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = DispatchProps & OwnProps & WithStyles<typeof styles>;

const ContactForm = (props: Props): JSX.Element => {
  const validate = (values: ContactValues): void | object => {
    const validationResult = validation(values);
    return toEffectiveObject(validationResult);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onSubmit = async (
    values: ContactValues,
    actions: FormikActions<ContactValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    props.postContact(values, props.history);
    actions.setSubmitting(false);
  };

  return (
    <div className={props.classes.wrapper}>
      <Formik
        initialValues={initialValues(props.facilityName, props.businessOwner)}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => (
          <Form>
            <ContactFields
              formikProps={formikProps}
              submitError={submitError}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, contactDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const contactDispatches = contactDispatch(dispatch);
  return {
    postContact: (params: ContactValues, history: H.History): Promise<void> =>
      contactDispatches.post(params, history),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params)
  };
};

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(ContactForm));
