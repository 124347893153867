import * as React from "react";
import _cloneDeep from "lodash-es/cloneDeep";

// ui
import * as classNames from "classnames";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { INT_FALSE_FROM_API } from "@constants/variables";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";

const ExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    margin: "0",
    "&:before": {
      display: "none"
    }
  }
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    display: "contents",
    padding: "0"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    margin: "0",
    border: 0,
    boxShadow: "none",
    "&$expanded": {
      margin: "0"
    }
  },
  expanded: {
    margin: "auto"
  }
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0"
  }
})(MuiExpansionPanelDetails);

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      minHeight: 330,
      maxHeight: "80%"
    },
    dialogTitle: {
      padding: "16px 24px 20px 32px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 58
    },
    dialogContent: {
      width: 776,
      height: "100%",
      minHeight: 148,
      padding: 0,
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "12px"
      },
      "&::-webkit-scrollbar-track": {
        background: "#fff",
        border: "none",
        borderRadius: 10,
        boxShadow: "inset 0 0 2px #777"
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#aaa",
        borderRadius: 10,
        boxShadow: "none"
      }
    },
    dialogActions: {
      borderTop: "1px solid #cfd8dc",
      margin: 0,
      padding: 8
    },
    contentSection: {
      padding: "24px 32px 48px",
      "&:not(:last-child)": {
        borderBottom: "1px solid rgba(0, 0, 0, 0.38)"
      }
    },
    note: {
      fontSize: 12,
      marginBottom: 24,
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 16px"
    },
    button: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 24
      }
    },
    cancel: {
      borderColor: "#ccc"
    },
    contentHeaderCategory: {
      display: "flex",
      color: "#fff",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "12px 12px 12px 16px",
      backgroundColor: "#90a4ae"
    },
    contentHeaderItem: {
      display: "flex",
      color: "#37474f",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "12px 12px 12px 16px",
      backgroundColor: "#eceff1"
    },
    contentHeaderName: {
      display: "flex",
      alignItems: "center",
      width: 604
    },
    contentHeaderCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 40
    },
    contentHeaderCheckBoxKeikaku: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: 80
    },
    contentHeaderCheckBoxShogaiji: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      width: 80
    },
    contentItem: {
      display: "flex",
      borderBottom: "solid 1px #e5e5e5",
      fontSize: 16,
      width: 712,
      height: 64,
      padding: "12px 0px 12px 16px"
    },
    contentCategory: {
      marginTop: 1,
      display: "flex",
      color: "#37474f",
      backgroundColor: "#eceff1",
      fontSize: 12,
      width: 712,
      height: 40,
      padding: "0px 16px"
    },
    contentForKeikakuShogaiji: {
      "&:last-child": {
        paddingRight: 0
      }
    },
    contentItemName: {
      display: "flex",
      alignItems: "center",
      minWidth: 604
    },
    contentItemCheckBox: {
      display: "flex",
      alignItems: "center",
      marginLeft: 42
    },
    contentItemCheckBoxKeikaku: {
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      textAlign: "center",
      width: 80
    },
    contentItemCheckBoxShogaiji: {
      display: "flex",
      alignItems: "center",
      justifyContent: "right",
      textAlign: "center",
      width: 80
    },
    contentItemNameForKeikakuShogaiji: {
      display: "flex",
      alignItems: "center",
      width: 520
    }
  });

type ChangeVisibilityParams = {
  id: number;
  custom_records_keikakusodan_visibility: {
    id: number;
    plan_visibility?: number;
    child_visibility?: number;
  };
}[];

type OwnProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onVisible: (
    customRecordItems?: postCustomRecordsVisibilityParams["custom_record_items"],
    customRecordsCategory?: postCustomRecordsVisibilityParams["custom_records_category"]
  ) => Promise<void>;
  hiddenCustomRecordCategoryType: number;
  customRecords: CustomRecordsWithCategoryState;
  facility: FacilityState;
  isCategorized?: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 記録の項目設定 > 非表示項目リスト
 */
const HiddenItemsWithCategoryModalCore = (props: Props): JSX.Element | null => {
  const { classes, facility } = props;
  const [checkParams, setCheckParams] = React.useState<{
    items: ChangeVisibilityParams;
    category: ChangeVisibilityParams;
  }>({ items: [], category: [] });

  const isKeikakuAndShogaiji =
    facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;

  const isKeikaku = facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;

  // 非表示項目リスト（カテゴリー非表示、あるいはカテゴリー内の項目のうち1つ以上が非表示）
  const getHiddenCustomRecords = (): CustomRecordsWithCategoryState => {
    return props.customRecords
      .filter((r) => r.category_type === props.hiddenCustomRecordCategoryType)
      .map((r) => {
        if (r.visibility === 0) {
          return r;
        }
        const invisible_items = r.custom_record_items.filter(
          (i) => i.visibility === 0
        );
        return { ...r, custom_record_items: invisible_items };
      })
      .filter((r) => r.visibility === 0 || r.custom_record_items.length > 0);
  };

  const hiddenCustomRecords = getHiddenCustomRecords();

  React.useEffect(() => {
    if (!props.customRecords.length) return;
    const effectHiddenCustomRecords = getHiddenCustomRecords();

    // visible=1の項目を割り出す
    const defaultSetItems: ChangeVisibilityParams = [];
    effectHiddenCustomRecords.forEach((r) => {
      r.custom_record_items.forEach((i) => {
        if (
          i.visibility === 1 &&
          !checkParams.items.some((v) => v.id === i.id) &&
          i.custom_records_keikakusodan_visibility
        ) {
          if (
            i.custom_records_keikakusodan_visibility.plan_visibility &&
            i.custom_records_keikakusodan_visibility.child_visibility
          ) {
            defaultSetItems.push({
              id: i.id,
              custom_records_keikakusodan_visibility: {
                id: i.custom_records_keikakusodan_visibility.id,
                plan_visibility: 1,
                child_visibility: 1
              }
            });
          } else if (
            i.custom_records_keikakusodan_visibility.plan_visibility &&
            !i.custom_records_keikakusodan_visibility.child_visibility
          ) {
            defaultSetItems.push({
              id: i.id,
              custom_records_keikakusodan_visibility: {
                id: i.custom_records_keikakusodan_visibility.id,
                plan_visibility: 1
              }
            });
          } else if (
            i.custom_records_keikakusodan_visibility.child_visibility &&
            !i.custom_records_keikakusodan_visibility.plan_visibility
          ) {
            defaultSetItems.push({
              id: i.id,
              custom_records_keikakusodan_visibility: {
                id: i.custom_records_keikakusodan_visibility.id,
                child_visibility: 1
              }
            });
          }
        }
      });
    });

    setCheckParams({ items: defaultSetItems, category: [] });
  }, [props.isModalOpen, props.customRecords]);

  const handleChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "item" | "category",
    target: "keikaku" | "shogaiji",
    record: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): void => {
    const { checked } = event.target;
    const { items, category } = checkParams;
    let newItems: ChangeVisibilityParams = _cloneDeep(items);
    let newCategory: ChangeVisibilityParams = [...category];
    const key = target === "keikaku" ? "plan_visibility" : "child_visibility";
    if (
      type === "item" &&
      item &&
      item.custom_records_keikakusodan_visibility
    ) {
      // 送信項目に無いなら追加、追加済みなら対象パラメータのみ追加or変更
      newItems = newItems.some((v) => v.id === item.id)
        ? newItems.map((v) => {
            if (v.id !== item.id) {
              return v;
            }
            return {
              ...v,
              custom_records_keikakusodan_visibility: {
                ...v.custom_records_keikakusodan_visibility,
                [key]: booleanToNumber0or1(checked)
              }
            };
          })
        : [
            ...newItems,
            {
              id: item.id,
              custom_records_keikakusodan_visibility: {
                id: item.custom_records_keikakusodan_visibility.id,
                [key]: booleanToNumber0or1(checked)
              }
            }
          ];
      if (
        isKeikakuAndShogaiji &&
        checked &&
        record.custom_records_keikakusodan_visibility
      ) {
        // (計画×障害児)カテゴリーのチェックボックスがOFFの場合に配下の項目いずれかをONにした場合は、カテゴリーのチェックボックスもONになる
        newCategory = newCategory.some((v) => v.id === record.id)
          ? newCategory.map((v) => {
              if (
                v.id !== record.id ||
                v.custom_records_keikakusodan_visibility[key] === 1
              ) {
                return v;
              }
              return {
                ...v,
                custom_records_keikakusodan_visibility: {
                  ...v.custom_records_keikakusodan_visibility,
                  [key]: 1
                }
              };
            })
          : [
              ...newCategory,
              {
                id: record.id,
                custom_records_keikakusodan_visibility: {
                  id: record.custom_records_keikakusodan_visibility.id,
                  [key]: 1
                }
              }
            ];
        // (計画×障害児)項目のみが非表示項目リストに表示されており、カテゴリーはON/OFF混在の場合、
        // 項目をONからOFF(初期状態)にした時に、初期状態がOFFのカテゴリーはONからOFF(更新無し)に戻す
      } else if (
        isKeikakuAndShogaiji &&
        !checked &&
        record.custom_records_keikakusodan_visibility &&
        record.custom_records_keikakusodan_visibility.plan_visibility !==
          record.custom_records_keikakusodan_visibility.child_visibility &&
        record.custom_records_keikakusodan_visibility[key] === 0
      ) {
        newCategory = newCategory.reduce((prev, current) => {
          const currentCategory = current;
          if (
            currentCategory.id === record.id &&
            currentCategory.custom_records_keikakusodan_visibility[key] === 1
          ) {
            delete currentCategory.custom_records_keikakusodan_visibility[key];
          }
          if (
            currentCategory.custom_records_keikakusodan_visibility
              .plan_visibility === undefined &&
            currentCategory.custom_records_keikakusodan_visibility
              .child_visibility === undefined
          ) {
            return prev;
          }
          if (prev !== undefined) {
            prev.push(currentCategory);
          }
          return prev;
        }, [] as ChangeVisibilityParams);
      }
    } else if (
      type === "category" &&
      record.custom_records_keikakusodan_visibility
    ) {
      // 送信項目に無いなら追加、追加済みなら対象パラメータのみ追加or変更
      newCategory = newCategory.some((v) => v.id === record.id)
        ? newCategory.map((v) => {
            if (v.id !== record.id) {
              return v;
            }
            return {
              ...v,
              custom_records_keikakusodan_visibility: {
                ...v.custom_records_keikakusodan_visibility,
                [key]: booleanToNumber0or1(checked)
              }
            };
          })
        : [
            ...newCategory,
            {
              id: record.id,
              custom_records_keikakusodan_visibility: {
                id: record.custom_records_keikakusodan_visibility.id,
                [key]: booleanToNumber0or1(checked)
              }
            }
          ];
      if (isKeikakuAndShogaiji) {
        // (計画×障害児)カテゴリーのON or OFF時に配下の項目もすべてON or OFF
        const targetCategory = hiddenCustomRecords.find(
          (v) => v.id === record.id
        );
        if (targetCategory) {
          targetCategory.custom_record_items.forEach((t) => {
            if (
              t.allow_change_visibility &&
              t.custom_records_keikakusodan_visibility
            ) {
              newItems = newItems.some((v) => t.id === v.id)
                ? newItems.map((v) => {
                    if (v.id !== t.id) {
                      return v;
                    }
                    return {
                      ...v,
                      custom_records_keikakusodan_visibility: {
                        ...v.custom_records_keikakusodan_visibility,
                        [key]: booleanToNumber0or1(checked)
                      }
                    };
                  })
                : [
                    ...newItems,
                    {
                      id: t.id,
                      custom_records_keikakusodan_visibility: {
                        id: t.custom_records_keikakusodan_visibility.id,
                        [key]: booleanToNumber0or1(checked)
                      }
                    }
                  ];
            }
          });
        }
      }
    }
    setCheckParams({ items: newItems, category: newCategory });
  };

  const handleOnClose = (): void => {
    props.onClose();
    setCheckParams({ items: [], category: [] });
  };

  const removeItems = (): ChangeVisibilityParams => {
    const { items } = checkParams;
    const { category } = checkParams;
    let newItems: ChangeVisibilityParams = [];
    newItems = _cloneDeep(items).reduce((prev, current) => {
      const item = current;

      // 非表示かつチェックの入っていないカテゴリーが持つチェック済み項目を排除
      const parentCategory = props.customRecords.find((r) =>
        r.custom_record_items.find((i) => current.id === i.id)
      );
      if (parentCategory) {
        const parentCategoryInParams = category.find(
          (v) => v.id === parentCategory.id
        );
        if (
          item.custom_records_keikakusodan_visibility.plan_visibility === 1 &&
          parentCategory.custom_records_keikakusodan_visibility &&
          parentCategory.custom_records_keikakusodan_visibility
            .plan_visibility === 0 &&
          (parentCategoryInParams === undefined ||
            parentCategoryInParams.custom_records_keikakusodan_visibility
              .plan_visibility === 0)
        ) {
          delete item.custom_records_keikakusodan_visibility.plan_visibility;
        }
        if (
          item.custom_records_keikakusodan_visibility.child_visibility === 1 &&
          parentCategory.custom_records_keikakusodan_visibility &&
          parentCategory.custom_records_keikakusodan_visibility
            .child_visibility === 0 &&
          (parentCategoryInParams === undefined ||
            parentCategoryInParams.custom_records_keikakusodan_visibility
              .child_visibility === 0)
        ) {
          delete item.custom_records_keikakusodan_visibility.child_visibility;
        }
        if (
          item.custom_records_keikakusodan_visibility.plan_visibility ===
            undefined &&
          item.custom_records_keikakusodan_visibility.child_visibility ===
            undefined
        ) {
          return prev;
        }
        if (prev !== undefined) {
          prev.push(item);
        }
      }
      return prev;
    }, [] as ChangeVisibilityParams);

    // 表示非表示の切り替えが不可の項目を排除
    newItems = newItems.filter((v) =>
      hiddenCustomRecords.some((r) =>
        r.custom_record_items.some(
          (i) =>
            v.id === i.id && i.allow_change_visibility !== INT_FALSE_FROM_API
        )
      )
    );

    // 初期データと同じ状態に戻っていたら除外
    newItems = newItems.reduce((prev, current) => {
      const item = current;
      hiddenCustomRecords.forEach((r) => {
        r.custom_record_items.forEach((v) => {
          if (
            v.id === item.id &&
            v.custom_records_keikakusodan_visibility &&
            v.custom_records_keikakusodan_visibility.plan_visibility ===
              item.custom_records_keikakusodan_visibility.plan_visibility
          ) {
            delete item.custom_records_keikakusodan_visibility.plan_visibility;
          }
          if (
            v.id === item.id &&
            v.custom_records_keikakusodan_visibility &&
            v.custom_records_keikakusodan_visibility.child_visibility ===
              item.custom_records_keikakusodan_visibility.child_visibility
          ) {
            delete item.custom_records_keikakusodan_visibility.child_visibility;
          }
        });
      });
      if (
        item.custom_records_keikakusodan_visibility.plan_visibility ===
          undefined &&
        item.custom_records_keikakusodan_visibility.child_visibility ===
          undefined
      ) {
        return prev;
      }
      return [...prev, item];
    }, [] as ChangeVisibilityParams);
    return newItems;
  };

  const removeCategory = (): ChangeVisibilityParams => {
    let { category } = checkParams;

    // 初期データと同じ状態に戻っていたら除外
    if (category.length > 0) {
      category = category.reduce((prev, current) => {
        const record = current;
        hiddenCustomRecords.forEach((v) => {
          if (
            v.id === record.id &&
            v.custom_records_keikakusodan_visibility &&
            v.custom_records_keikakusodan_visibility.plan_visibility ===
              record.custom_records_keikakusodan_visibility.plan_visibility
          ) {
            delete record.custom_records_keikakusodan_visibility
              .plan_visibility;
          }
          if (
            v.id === record.id &&
            v.custom_records_keikakusodan_visibility &&
            v.custom_records_keikakusodan_visibility.child_visibility ===
              record.custom_records_keikakusodan_visibility.child_visibility
          ) {
            delete record.custom_records_keikakusodan_visibility
              .child_visibility;
          }
          if (
            record.custom_records_keikakusodan_visibility.plan_visibility ===
              undefined &&
            record.custom_records_keikakusodan_visibility.child_visibility ===
              undefined
          ) {
            return;
          }
          if (prev !== undefined) {
            prev.push(record);
          }
        });
        return prev;
      }, [] as ChangeVisibilityParams);
    }
    return category;
  };

  if (hiddenCustomRecords.length === 0) {
    return null;
  }

  const handleOnVisible = async (): Promise<void> => {
    await props.onVisible(removeItems(), removeCategory());
    props.onClose();
    setCheckParams({ items: [], category: [] });
  };

  const makeItemRows = (
    record: CustomRecordsWithCategoryState[number]
  ): JSX.Element[] => {
    return record.custom_record_items.map((i) => {
      return (
        <div
          key={`content-item-${i.id}`}
          className={classNames(
            classes.contentItem,
            isKeikakuAndShogaiji && classes.contentForKeikakuShogaiji
          )}
        >
          <div
            className={
              isKeikakuAndShogaiji
                ? classes.contentItemNameForKeikakuShogaiji
                : classes.contentItemName
            }
          >
            {i.name}
          </div>
          {i.allow_change_visibility === INT_FALSE_FROM_API ? (
            <></>
          ) : (
            <>
              {isKeikakuAndShogaiji ? (
                <>
                  <div className={classes.contentItemCheckBoxKeikaku}>
                    <MuiCheckbox
                      id={`item-checkbox-${i.id}-${
                        i.custom_records_keikakusodan_visibility
                          ? i.custom_records_keikakusodan_visibility.id
                          : 0
                      }`}
                      label=""
                      checked={checkParams.items.some(
                        (v) =>
                          v.id === i.id &&
                          v.custom_records_keikakusodan_visibility
                            .plan_visibility
                      )}
                      onChange={(e): void => {
                        handleChangeCheckbox(e, "item", "keikaku", record, i);
                      }}
                      style={{ margin: "0 0 -2px" }}
                    />
                  </div>
                  <div className={classes.contentItemCheckBoxShogaiji}>
                    <MuiCheckbox
                      id={`item-checkbox-${i.id}-${
                        i.custom_records_keikakusodan_visibility
                          ? i.custom_records_keikakusodan_visibility.id
                          : 0
                      }`}
                      label=""
                      checked={checkParams.items.some(
                        (v) =>
                          v.id === i.id &&
                          v.custom_records_keikakusodan_visibility
                            .child_visibility
                      )}
                      onChange={(e): void => {
                        handleChangeCheckbox(e, "item", "shogaiji", record, i);
                      }}
                      style={{ margin: "0 0 -2px" }}
                    />
                  </div>
                </>
              ) : (
                <div className={classes.contentItemCheckBox}>
                  <MuiCheckbox
                    id={`item-checkbox-${i.id}`}
                    label=""
                    checked={checkParams.items.some(
                      (v) =>
                        v.id === i.id &&
                        (isKeikaku
                          ? v.custom_records_keikakusodan_visibility
                              .plan_visibility
                          : v.custom_records_keikakusodan_visibility
                              .child_visibility)
                    )}
                    onChange={(e): void => {
                      handleChangeCheckbox(
                        e,
                        "item",
                        isKeikaku ? "keikaku" : "shogaiji",
                        record,
                        i
                      );
                    }}
                    style={{ margin: "0 0 -2px" }}
                  />
                </div>
              )}
            </>
          )}
        </div>
      );
    });
  };

  const makeCategoryRow = (
    record: CustomRecordsWithCategoryState[number]
  ): JSX.Element | null => {
    return props.isCategorized ? (
      <ExpansionPanelSummary key={`summary-${record.id}`}>
        <div
          key={`content-category-${record.id}`}
          className={classNames(
            classes.contentCategory,
            isKeikakuAndShogaiji && classes.contentForKeikakuShogaiji
          )}
        >
          <div
            className={
              isKeikakuAndShogaiji
                ? classes.contentItemNameForKeikakuShogaiji
                : classes.contentItemName
            }
          >
            {record.name}
          </div>
          {isKeikakuAndShogaiji ? (
            <>
              <div className={classes.contentItemCheckBoxKeikaku}>
                {record.visibility === 0 && (
                  <MuiCheckbox
                    id={`category-checkbox-${record.id}-${
                      record.custom_records_keikakusodan_visibility
                        ? record.custom_records_keikakusodan_visibility.id
                        : 0
                    }`}
                    label=""
                    checked={checkParams.category.some(
                      (v) =>
                        v.id === record.id &&
                        v.custom_records_keikakusodan_visibility.plan_visibility
                    )}
                    onChange={(e): void => {
                      handleChangeCheckbox(e, "category", "keikaku", record);
                    }}
                    style={{ margin: "0 0 -2px" }}
                  />
                )}
              </div>
              <div className={classes.contentItemCheckBoxShogaiji}>
                {record.visibility === 0 && (
                  <MuiCheckbox
                    id={`category-checkbox-${record.id}-${
                      record.custom_records_keikakusodan_visibility
                        ? record.custom_records_keikakusodan_visibility.id
                        : 0
                    }`}
                    label=""
                    checked={checkParams.category.some(
                      (v) =>
                        v.id === record.id &&
                        v.custom_records_keikakusodan_visibility
                          .child_visibility
                    )}
                    onChange={(e): void => {
                      handleChangeCheckbox(e, "category", "shogaiji", record);
                    }}
                    style={{ margin: "0 0 -2px" }}
                  />
                )}
              </div>
            </>
          ) : (
            <div className={classes.contentItemCheckBox}>
              {record.visibility === 0 && (
                <MuiCheckbox
                  id={`category-checkbox-${record.id}`}
                  label=""
                  checked={checkParams.category.some(
                    (v) =>
                      v.id === record.id &&
                      (isKeikaku
                        ? v.custom_records_keikakusodan_visibility
                            .plan_visibility
                        : v.custom_records_keikakusodan_visibility
                            .child_visibility)
                  )}
                  onChange={(e): void => {
                    handleChangeCheckbox(
                      e,
                      "category",
                      isKeikaku ? "keikaku" : "shogaiji",
                      record
                    );
                  }}
                  style={{ margin: "0 0 -2px" }}
                />
              )}
            </div>
          )}
        </div>
      </ExpansionPanelSummary>
    ) : null;
  };

  // カテゴリーが非表示の場合、カテゴリーにチェックされていないなら項目を表示しない
  const isExpanded = (
    record: CustomRecordsWithCategoryState[number]
  ): boolean =>
    !(
      props.isCategorized &&
      record.visibility === 0 &&
      !checkParams.category.some(
        (v) =>
          v.id === record.id &&
          (v.custom_records_keikakusodan_visibility.plan_visibility === 1 ||
            v.custom_records_keikakusodan_visibility.child_visibility === 1)
      )
    );

  const getCategoryAndItemRows = (): JSX.Element[] => {
    return hiddenCustomRecords.map((record) => {
      return (
        <ExpansionPanel
          key={`panel-${record.id}`}
          expanded={isExpanded(record)}
        >
          {makeCategoryRow(record)}
          <ExpansionPanelDetails key={`detail-${record.id}`}>
            {makeItemRows(record)}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    });
  };

  return (
    <Dialog
      open={props.isModalOpen}
      disableBackdropClick
      maxWidth={false}
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle className={classes.dialogTitle}>
        非表示項目リスト
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.contentSection}>
          <p className={classes.note}>
            表示のチェックをオンにすると、再び記録画面に表示されるようになります。
          </p>
          <div
            className={
              props.isCategorized
                ? classes.contentHeaderCategory
                : classes.contentHeaderItem
            }
          >
            <div
              className={
                isKeikakuAndShogaiji
                  ? classes.contentItemNameForKeikakuShogaiji
                  : classes.contentHeaderName
              }
            >
              {props.isCategorized && "カテゴリー / "}項目名
            </div>
            {isKeikakuAndShogaiji ? (
              <>
                <div className={classes.contentHeaderCheckBoxKeikaku}>
                  計画相談
                  <br />
                  表示
                </div>
                <div className={classes.contentHeaderCheckBoxShogaiji}>
                  障害児相談
                  <br />
                  表示
                </div>
              </>
            ) : (
              <div className={classes.contentHeaderCheckBox}>表示</div>
            )}
          </div>
          {getCategoryAndItemRows()}
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton
          className={classNames(classes.button, classes.cancel)}
          color="secondary"
          onClick={handleOnClose}
          kind="outline"
        >
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          className={classes.button}
          variant="contained"
          color="secondary"
          onClick={handleOnVisible}
          disabled={removeCategory().length === 0 && removeItems().length === 0}
        >
          確定する
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const HiddenItemsWithCategoryModal = withStyles(styles)(
  HiddenItemsWithCategoryModalCore
);
