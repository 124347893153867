import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetServiceDeliveryDaily = {
  data: {
    service_delivery: {
      service_delivery_records_id: number | null;
      inout_results_id: number | null;
      support_procedure_forms_id: number | null; // 行動援護のみ
      users_in_facility_id: number;
      name_sei: string;
      name_mei: string;
      target_date: string;
      status: number;
      status_text: string | null; // 移動支援のみ
      start_time_1: string;
      end_time_1: string;
      number_of_time_1: number;
      number_of_rides_1?: number; // 居宅介護のみ
      calculated_moving_hours_1?: number; // 重度訪問のみ
      start_time_2: string;
      end_time_2: string;
      number_of_time_2: number;
      number_of_rides_2?: number; // 居宅介護のみ
      calculated_moving_hours_2?: number; // 重度訪問のみ
      number_of_practitioner: number;
      recorded_flg: number;
      unplanned_flg: number;
      procedure_status_flg?: number; // 行動援護のみ
    }[];
    number_of_plans: number;
    number_of_records: number;
    number_of_procedures?: number; // 行動援護のみ
  };
};

/**
 * 指定日のサービス提供記録を取得する
 * @param date YYYYMMDD
 */
export const getServiceDeliveryDaily = async (
  date: string
): Promise<AxiosResponse<GetServiceDeliveryDaily>> => {
  const url = `${VERSION_URL_201910}/service_delivery/daily/${date}`;
  return request.get<GetServiceDeliveryDaily>(url);
};
