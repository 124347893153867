import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import { GetFacilityUsersListResponse } from "@api/requests/facility/getFacilityUsersList";
import { Checkbox } from "@constants/variables";

/**
 * ActionNames
 */
export const FETCH_STARTED = "IDOSHIEN/USER_IN_FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "IDOSHIEN/USER_IN_FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "IDOSHIEN/USER_IN_FACILITY/FETCH_FAILED";
export const FETCH_TARGET_DAY_STARTED =
  "IDOSHIEN/USER_IN_FACILITY/FETCH_TARGET_DAY_STARTED";
export const FETCH_TARGET_DAY_SUCCESS =
  "IDOSHIEN/USER_IN_FACILITY/FETCH_TARGET_DAY_SUCCESS";
export const FETCH_TARGET_DAY_FAILED =
  "IDOSHIEN/USER_IN_FACILITY/FETCH_TARGET_DAY_FAILED";
export const FETCH_ONE_STARTED = "IDOSHIEN/USER_IN_FACILITY/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS = "IDOSHIEN/USER_IN_FACILITY/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED = "IDOSHIEN/USER_IN_FACILITY/FETCH_ONE_FAILED";
export const CREATE_STARTED = "IDOSHIEN/USER_IN_FACILITY/CREATE_STARTED";
export const CREATE_SUCCESS = "IDOSHIEN/USER_IN_FACILITY/CREATE_SUCCESS";
export const CREATE_FAILED = "IDOSHIEN/USER_IN_FACILITY/CREATE_FAILED";
export const UPDATE_STARTED = "IDOSHIEN/USER_IN_FACILITY/UPDATE_STARTED";
export const UPDATE_SUCCESS = "IDOSHIEN/USER_IN_FACILITY/UPDATE_SUCCESS";
export const UPDATE_FAILED = "IDOSHIEN/USER_IN_FACILITY/UPDATE_FAILED";
export const DELETE_STARTED = "IDOSHIEN/USER_IN_FACILITY/DELETE_STARTED";
export const DELETE_SUCCESS = "IDOSHIEN/USER_IN_FACILITY/DELETE_SUCCESS";
export const DELETE_FAILED = "IDOSHIEN/USER_IN_FACILITY/DELETE_FAILED";
export const CLEAR = "IDOSHIEN/USER_IN_FACILITY/CLEAR";

export type UsersInFacilityState = {
  users: GetFacilityUsersResponse["data"];
  usersList: GetFacilityUsersListResponse["data"];
  user: {
    user_in_facility: {
      /* 基本情報 */

      // 施設利用者id
      id?: number;
      // 姓
      name_sei?: string;
      // 名
      name_mei?: string;
      // 姓(カナ)
      name_sei_kana?: string;
      // 名(カナ)
      name_mei_kana?: string;
      // 受給者証番号
      recipient_number?: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg?: Checkbox;
      // 性別
      gender?: string;
      // 施設id
      facility_id?: number;
      // 身体障害
      classify_physical_flg?: Checkbox;
      // 知的障害
      classify_intelligence_flg?: Checkbox;
      // 精神障害
      classify_mind_flg?: Checkbox;
      // 発達障害
      classify_growth_flg?: Checkbox;
      // 高次脳機能障害
      classify_brain_flg?: Checkbox;
      // 難病等対象者
      classify_incurable_flg?: Checkbox;
      // 障害児
      classify_handicapped_flg?: Checkbox;
      // 生年月日
      date_birth?: string;
      // 郵便番号
      postal_code?: string;
      // 都道府県
      prefecture_name?: string;
      // 市区町村id
      city_id?: number;
      // 住所
      address?: string;
      // 電話番号
      tel?: string;
      // メールアドレス
      email?: string;
      // 保護者姓
      guardian_name_sei?: string;
      // 保護者名
      guardian_name_mei?: string;
      // 保護者姓(カナ)
      guardian_name_sei_kana?: string;
      // 保護者名(カナ)
      guardian_name_mei_kana?: string;
      // 保護者、続柄
      guardian_relation?: string;
      // 保護者 電話番号
      guardian_tel?: string;
      // 備考
      memo?: string;
      /* サービス詳細 */

      // サービス提供開始日 yyyy-mm-dd
      date_begin_in_service?: string;
      // サービス提供終了日 yyyy-mm-dd
      date_end_in_service?: string;
      // 支給決定開始日
      date_pay_begin?: string;
      // 支給決定終了日
      date_pay_end?: string;
      // 障害支援区分
      classify_disability_support?: string;
      care_support_payment_flg?: Checkbox;
    };
    user_in_facility_idoshien?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 自治体情報id
      municipality_id: number;
      // 障害区分
      disability_class: number;
      // 契約支給量：個別支援：時間/月
      pay_times_individual_support: number;
      // 契約支給量：個別支援：回/月
      pay_months_individual_support: number;
      // 契約支給量：グループ支援：時間/月
      pay_times_group_support: number;
      // 契約支給量：グループ支援：回/月
      pay_months_group_support: number;
      // 契約支給量：車両移送支援：時間/月
      pay_times_vehicle_transfer_support: number;
      // 契約支給量：車両移送支援：回/月
      pay_months_vehicle_transfer_support: number;
      // 契約支給量：通学・通院支援：時間/月
      pay_times_school_and_hospital_commuting_support: number;
      // 契約支給量：通学・通院支援：回/月
      pay_months_school_and_hospital_commuting_support: number;
      // 契約支給量：その他支援：時間/月
      pay_times_other_support: number;
      // 契約支給量：その他支援：回/月
      pay_months_other_support: number;
      // 利用者負担（%）
      user_charge_rate: number;
      // 負担上限月額
      monthly_user_charge_limit: number;
    }>;
  };
  doneUpsertUser: boolean;
  userValidation: Partial<{}>;
};
