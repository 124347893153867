import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Histories } from "@interfaces/v201910/mgr/IAB/report/workRecord";

const styles = (): StyleRules =>
  createStyles({
    table: {
      margin: "8px 0"
    },
    tableHeader: {
      backgroundColor: "#f5f5f5"
    },
    column: {
      fontSize: 14,
      padding: "16px 0 16px 16px"
    },
    tableHeaderColumn: {
      borderBottom: "none",
      backgroundColor: "#90a4ae",
      color: "#ffffff"
    },
    tableBody: {
      "& > tr >td": {
        borderBottom: "none"
      },
      "& > tr:nth-child(even)": {
        backgroundColor: "#f5f5f5"
      }
    }
  });

type OwnProps = {
  data: Histories;
};

type Props = OwnProps & WithStyles<typeof styles>;
const columns = [
  { text: "日時", index: "updatedDate" },
  { text: "項目", index: "columnName" },
  { text: "変更内容", index: "changeDetail" }
];

const WorkDataTable = (props: Props): JSX.Element => {
  if (!props.data) {
    return <></>;
  }
  return (
    <Table classes={{ root: props.classes.table }}>
      <TableHead classes={{ root: props.classes.tableHeader }}>
        <TableRow>
          {columns.map((col) => (
            <TableCell
              key={col.index}
              className={`${props.classes.tableHeaderColumn} ${props.classes.column}`}
            >
              {col.text}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody className={props.classes.tableBody}>
        {props.data &&
          props.data.map((item, index) => {
            const rowKey = `row-${index}`;
            return (
              <TableRow key={rowKey}>
                {columns.map((col) => {
                  const colKey = `${index}_${col.index}`;
                  return (
                    <TableCell key={colKey} className={props.classes.column}>
                      {item[col.index]}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default withStyles(styles)(WorkDataTable);
