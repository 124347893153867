import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import dispatches from "@stores/dispatches";
import {
  AttendanceList as AttendanceListType,
  FormattedAttendanceList
} from "@stores/domain/attendance/type";
import { AttendanceTemplate } from "@components/templates/AttendanceTemplate";
import { AttendanceList } from "@components/organisms/attendance/AttendanceList";
import { AttendanceModal } from "@components/organisms/attendance/AttendanceModal";
import { UserState } from "@stores/domain/user/type";
import { getUserCorrectWorkRecordTimes } from "@utils/domain/facility/getUserCorrectWorkRecordTimes";
import makeTotalBreakTimeMinutes from "@utils/domain/report/makeTotalBreakTimeMinutes";
import { dateToLocalisedString } from "@utils/date";
import { convertBreakTime } from "@stores/domain/mgr/IAB/report/normalizer";
import roundingMinutes from "@utils/dataNormalizer/roundingMinutes";

type DispatchProps = {
  handleLogout: () => void;
  fetchAttendanceList: () => Promise<void>;
  fetchAttendance: (id: number) => Promise<void>;
  inTime: (id: number, name: string, status: number) => Promise<void>;
  outTime: (
    id: number,
    name: string,
    status: number,
    date: Date,
    breakTime: string | undefined
  ) => Promise<void>;
};

const styles = createStyles({
  wrapper: {
    overflow: "hidden",
    position: "relative",
    backgroundColor: "#fafafa"
  }
});

type StateProps = {
  attendanceList: AttendanceListType;
  user: UserState;
};

type MergeProps = StateProps &
  DispatchProps & {
    formattedAttendanceList: FormattedAttendanceList;
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * タイムカード
 */
const AttendanceCore = (props: Props): JSX.Element => {
  const punchInButtonLabel = props.user.labels
    ? props.user.labels.punch_in
    : "通所する";
  const punchOutButtonLabel = props.user.labels
    ? props.user.labels.punch_out
    : "終了する";

  const [isModalOpen, toggleModal] = React.useState(false);
  const [modalTargetId, setModalTargetId] = React.useState<number | undefined>(
    undefined
  );

  React.useEffect(() => {
    props.fetchAttendanceList();
  }, []);

  const getModalTargetAttendance = ():
    | AttendanceListType[number]
    | undefined => {
    return props.attendanceList.find((res) => {
      return res.uif_id === modalTargetId;
    });
  };

  React.useEffect(() => {
    const targetAttendance = getModalTargetAttendance();
    if (targetAttendance && targetAttendance.facility) {
      toggleModal(true);
    }
  }, [props.attendanceList]);

  const targetSelect = async (id: number): Promise<void> => {
    if (isModalOpen) return;
    setModalTargetId(id);
    await props.fetchAttendance(id);
  };

  const modalClose = (): void => {
    toggleModal(false);
  };

  const onInTime = async (
    id: number,
    name: string,
    status: number
  ): Promise<void> => {
    await props.inTime(id, name, status);
    modalClose();
  };

  const targetAttendance = getModalTargetAttendance();
  const onOutTime = async (
    id: number,
    name: string,
    status: number
  ): Promise<void> => {
    const date = new Date();
    let breakTime;
    if (
      targetAttendance &&
      targetAttendance.user &&
      targetAttendance.facility &&
      !Array.isArray(targetAttendance.facility) &&
      targetAttendance.inTime &&
      targetAttendance.attendanceStatus === "working"
    ) {
      const workRecord = getUserCorrectWorkRecordTimes(
        targetAttendance.user,
        targetAttendance.facility,
        date
      );
      const isBusinessDay = !!(workRecord.start_time && workRecord.end_time);

      if (isBusinessDay) {
        // 利用者単位で作業時間周りのデータがあれば、それを優先
        const isUserWorkTime =
          targetAttendance.user.work_details &&
          "work_time_use_flg" in targetAttendance.user.work_details &&
          targetAttendance.user.work_details.work_time_use_flg;
        const unitEngrave =
          isUserWorkTime &&
          targetAttendance.user.work_details &&
          "work_truncate_minutes" in targetAttendance.user.work_details &&
          targetAttendance.user.work_details.work_truncate_minutes
            ? targetAttendance.user.work_details.work_truncate_minutes
            : targetAttendance.facility.unitEngrave;
        // 設定した基準開始時刻より前なら基準時刻で上書きする
        let startTime = roundingMinutes(
          targetAttendance.inTime,
          unitEngrave,
          "start"
        );
        const replaceStartTime = startTime.replace(/:/, "");
        const replaceWorkStartTime = workRecord.start_time.replace(/:/, "");
        if (replaceWorkStartTime > replaceStartTime) {
          startTime = workRecord.start_time;
        }
        // 設定した基準終了時刻より後なら基準時刻で上書きする
        let endTime = roundingMinutes(
          dateToLocalisedString(`${date.toLocaleString("ja")}`, "HH:mm"),
          unitEngrave,
          "end"
        );
        const replaceEndTime = endTime.replace(/:/, "");
        const replaceWorkEndTime = workRecord.end_time.replace(/:/, "");
        if (replaceWorkEndTime < replaceEndTime) {
          endTime = workRecord.end_time;
        }

        // 時刻に含まれる休憩時間を算出してセット
        const minutes = makeTotalBreakTimeMinutes(
          startTime,
          endTime,
          workRecord.break_times,
          unitEngrave.toString()
        );
        breakTime = convertBreakTime(minutes);
      }
    }

    await props.outTime(id, name, status, date, breakTime);
    modalClose();
  };

  return (
    <div className={props.classes.wrapper}>
      <AttendanceTemplate
        userName={props.user.name || ""}
        handleLogout={props.handleLogout}
      >
        <AttendanceList
          targetSelect={targetSelect}
          attendanceList={props.formattedAttendanceList}
        />
      </AttendanceTemplate>
      <AttendanceModal
        isOpen={isModalOpen}
        onClose={modalClose}
        onInTime={onInTime}
        onOutTime={onOutTime}
        punchInButtonLabel={punchInButtonLabel}
        punchOutButtonLabel={punchOutButtonLabel}
        targetAttendance={targetAttendance}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { authDispatch, attendanceDispatch } = dispatches;
  return {
    handleLogout: authDispatch(dispatch).logout,
    fetchAttendanceList: attendanceDispatch(dispatch).fetchAttendanceList,
    fetchAttendance: attendanceDispatch(dispatch).fetchAttendance,
    inTime: attendanceDispatch(dispatch).inTime,
    outTime: attendanceDispatch(dispatch).outTime
  };
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    attendanceList: state.attendance.attendanceList,
    user: state.user
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: WithStyles<typeof styles>
): MergeProps => {
  const listFormat = [
    { viewKana: "あ", targetKana: /^[ァ-オ].*$/ },
    { viewKana: "か", targetKana: /^[カ-ゴ].*$/ },
    { viewKana: "さ", targetKana: /^[サ-ゾ].*$/ },
    { viewKana: "た", targetKana: /^[タ-ド].*$/ },
    { viewKana: "な", targetKana: /^[ナ-ノ].*$/ },
    { viewKana: "は", targetKana: /^[ハ-ポ].*$/ },
    { viewKana: "ま", targetKana: /^[マ-モ].*$/ },
    { viewKana: "や", targetKana: /^[ャ-ヨ].*$/ },
    { viewKana: "ら", targetKana: /^[ラ-ロ].*$/ },
    { viewKana: "わ", targetKana: /^[ヮ-ヴ].*$/ }
  ];

  const formatAttendanceList = (
    list: AttendanceListType
  ): FormattedAttendanceList => {
    return listFormat
      .map((target) => {
        return {
          viewKana: target.viewKana,
          attendance: list.filter((res) => {
            return target.targetKana.test(res.kanaName);
          })
        };
      })
      .filter((target) => {
        return target.attendance.length;
      });
  };

  return {
    formattedAttendanceList: formatAttendanceList(stateProps.attendanceList),
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const Attendance = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(AttendanceCore)
);
