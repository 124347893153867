import { SupportPlanState } from "@stores/domain/supportPlan/types";
import { RecordSupportPlanValues } from "@initialize/record/supportPlan/initialValues";
import get from "lodash-es/get";
import { dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * support_plan_goalは入力していないnumberは返って来ないので、ない場合は初期値でセットする
 */
const supplementSupportPlanGoal = (
  supportPlanGoal: SupportPlanState["privateSupportPlan"]["support_plan_goal"]
): RecordSupportPlanValues["support_plan_goal"] => {
  return [1, 2, 3].map((num) => {
    const res = supportPlanGoal.find((s) => s.number === num);
    return res
      ? {
          number: res.number,
          sprint_goal: res.sprint_goal || "",
          adopt_info: res.adopt_info || "",
          support_info: res.support_info || ""
        }
      : {
          number: num,
          sprint_goal: "",
          adopt_info: "",
          support_info: ""
        };
  });
};

/**
 * 個別支援計画新規・コピーフォーム初期値定義
 * valuesが渡される状況はコピー状態、以下の一部値以外をセットする
 * コピー対象外: 作成日, 支援開始日, 支援終了日, 作成者, 個別支援計画議事録
 */
const initialValues = (
  values?: SupportPlanState["privateSupportPlan"]
): RecordSupportPlanValues => {
  const currentDate = dateToSelectDateValue(
    dateToLocalisedString(new Date(), "YYYY-MM-DD")
  );
  const supportDate = dateToSelectDateValue("");

  return {
    creation_date: currentDate,
    support_begin_date: supportDate,
    support_end_date: supportDate,
    user_request_text: get(values, "user_request_text") || "",
    current_status: get(values, "current_status") || "",
    long_term_goal: get(values, "long_term_goal") || "",
    support_plan_goal: supplementSupportPlanGoal(
      get(values, "support_plan_goal") || []
    ),
    remarks: get(values, "remarks") || "",
    staff_comment: get(values, "staff_comment") || "",
    author: "",
    minutes_date: currentDate,
    participant: [],
    minutes: "",
    status_type: "0",
    authorizer: ""
  };
};

export default initialValues;
