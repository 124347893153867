import * as action from "@stores/v201910/domain/invoice/action";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";

import invoiceApi from "@api/requests/v201910/invoice";

const downloadJissekiJson = (dispatch: Dispatch) => async (
  year: string,
  month: string,
  excluded_user_ids: number[]
): Promise<void> => {
  dispatch(action.downloadJissekiJson.started());
  dispatches.uiDispatch(dispatch).loading(true);
  const user_ids = excluded_user_ids.join(",");
  await invoiceApi
    .getInvoicesJissekiJson(year, month, user_ids)
    .then((res) => {
      const { data } = res;
      dispatch(action.downloadJissekiJson.done({ result: data }));
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(action.downloadJissekiJson.failed({ error: e.toString() }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
    });
};

type DispatchProps = {
  downloadJissekiJson: (
    year: string,
    month: string,
    excluded_user_ids: number[]
  ) => Promise<void>;
};

export default function (dispatch: Dispatch): DispatchProps {
  return {
    downloadJissekiJson: downloadJissekiJson(dispatch)
  };
}
