import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// ui
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { CustomRecordsTable } from "@components/organisms/assessment/setting/CustomRecordsTable";
import { SettingFormButtons } from "@components/organisms/assessment/setting/SettingFormButtons";

// formik
import { Formik, FormikActions } from "formik";
import { tableBodyInitialValues } from "@initialize/record/customRecordWithCategory/initialValues";

// others
import {
  ASSESSMENT_CATEGORY_TYPE_NAME,
  AB_ASSESSMENT_CATEGORY_TYPE_NAME,
  FacilityType,
  IKOU_ASSESSMENT_CATEGORY_TYPE_NAME,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE_NAME,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE_NAME,
  GROUP_HOME_ASSESSMENT_CATEGORY_TYPE_NAME,
  SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE_NAME
} from "@constants/variables";
import deepEqual from "fast-deep-equal";
import { HiddenItemsWithCategoryModal } from "@components/organisms/mgr/common/record/HiddenItemsWithCategoryModal";
import { UserState } from "@stores/domain/user/type";
import Checkbox from "@components/atoms/Checkbox";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import { orderBy } from "lodash-es";
import classNames from "classnames";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    },
    descriptionMarginBottom: {
      marginBottom: spacing.unit * 3
    },
    pureSettingTopSection: {
      marginTop: spacing.unit * 3,
      marginBottom: spacing.unit,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "end"
    },
    evaluationPeriod: {
      marginRight: "auto",
      "& label span": {
        fontSize: "16px"
      }
    }
  });

type OwnProps = {
  isCategorized: boolean;
  categoryType: number;
  label: string;
  customRecords: CustomRecordsWithCategoryState;
  openHideConfirmModal: (id: number, type: "" | "category" | "item") => void;
  sortingCategoryType: number | null;
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  sortingItemType: number | null;
  setSortingItemType: reactDispatch<SetStateAction<number | null>>;
  fetchCustomRecords: () => Promise<void>;
  openAddModal: (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null
  ) => void;
  editedId: number;
  editedType: "" | "category" | "item";
  changeEditMode: (
    id: number,
    type: "" | "category" | "item",
    record: CustomRecordsWithCategoryState[number]
  ) => void;
  endEdit: () => void;
  submitEdit: (value: CustomRecordsWithCategoryState) => Promise<void>;
  openEvaluationPeriodConfirmModal: (id: number) => void;
  evaluationPeriodDisabled: boolean;
  setEvaluationPeriodDisabled: (disabled: boolean) => void;
  evaluationPeriodRecord?: CustomRecordsWithCategoryState;
  isHiddenButton?: boolean;
};

type StateProps = {
  user: UserState;
  needsStopHistory: boolean;
};

type DispatchProps = {
  postCustomRecordsCategoryOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState
  ) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState,
    customRecordCategoryId: number
  ) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const AssessmentSettingFormCore = (props: Props): JSX.Element => {
  const {
    user,
    isCategorized,
    categoryType,
    label,
    classes,
    customRecords,
    isHiddenButton,
    fetchCustomRecords,
    sortingCategoryType,
    setSortingCategoryType,
    sortingItemType,
    setSortingItemType,
    openHideConfirmModal,
    postCustomRecordsOrder,
    postCustomRecordsCategoryOrder,
    showCustomRecord,
    needsStopHistory,
    stopHistory,
    openAddModal,
    editedId,
    editedType,
    changeEditMode,
    endEdit,
    submitEdit,
    openEvaluationPeriodConfirmModal,
    evaluationPeriodDisabled,
    setEvaluationPeriodDisabled,
    evaluationPeriodRecord
  } = props;

  // state
  const [commonTableValues, setCommonTableValues] = React.useState(
    tableBodyInitialValues()
  );
  const [
    isOpenHiddenCustomRecordList,
    setOpenHiddenCustomRecordList
  ] = React.useState(false);
  const [
    hiddenCustomRecordCategoryType,
    setHiddenCustomRecordCategoryType
  ] = React.useState(0);

  // 並べ替え用関数
  const sortRecords = (
    records: CustomRecordsWithCategoryState
  ): CustomRecordsWithCategoryState => {
    return records
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      })
      .map((r) => {
        const sortedCustomRecordItems = r.custom_record_items.sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
        return { ...r, custom_record_items: sortedCustomRecordItems };
      });
  };

  const visibleCustomRecords = customRecords.length
    ? sortRecords(customRecords)
        .map((record) => {
          const invisibleCustomRecordItems = record.custom_record_items.filter(
            (i) => i.visibility
          );
          return { ...record, custom_record_items: invisibleCustomRecordItems };
        })
        .filter((record) => record.visibility === 1)
    : [];

  // 並び替え不可(orderがnull）の項目を先に表示するためのソート処理
  let sortedCustomRecords = visibleCustomRecords;
  if (
    user.facility_type === FacilityType.KEIKAKUSODAN ||
    user.facility_type === FacilityType.CHIIKIIKO ||
    user.facility_type === FacilityType.CHIIKITEICHAKU
  ) {
    const sortedRecords = orderBy(visibleCustomRecords, [
      (v): boolean => v.order !== null,
      "order",
      "default_category"
    ]);

    sortedCustomRecords = sortedRecords.map((record) => {
      const sortedCustomRecordItems = orderBy(record.custom_record_items, [
        (v): boolean => v.order !== null,
        "order"
      ]);
      return { ...record, custom_record_items: sortedCustomRecordItems };
    });
  }

  // 非表示カテゴリーか非表示項目が1つでも存在するかどうか
  const invisibleCustomRecordExist: boolean = customRecords.length
    ? !!customRecords.filter((record) => {
        const invisibleCustomRecordItems = record.custom_record_items.filter(
          (i) => i.visibility === 0
        );
        return record.visibility === 0 || invisibleCustomRecordItems.length > 0;
      }).length
    : false;

  React.useEffect(() => {
    setCommonTableValues(tableBodyInitialValues(sortedCustomRecords));
  }, [customRecords]);

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordsWithCategoryState
  ): void => {
    const hasChange = !deepEqual(nextValues, commonTableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordsWithCategoryState): void => {
    if (!needsStopHistory) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordsWithCategoryState,
    actions: FormikActions<CustomRecordsWithCategoryState>
  ): Promise<void> => {
    await actions.setSubmitting(true);
    if (editedId) {
      await submitEdit(values);
      await fetchCustomRecords();
      await endEdit();
    } else if (sortingCategoryType) {
      if (
        user.facility_type === FacilityType.KEIKAKUSODAN ||
        user.facility_type === FacilityType.CHIIKIIKO ||
        user.facility_type === FacilityType.CHIIKITEICHAKU
      ) {
        // 並び替え不可のカテゴリーはリクエストから排除
        await postCustomRecordsCategoryOrder(
          values.filter((v) => v.order !== null)
        );
      } else {
        await postCustomRecordsCategoryOrder(values);
      }
      await fetchCustomRecords();
      await setSortingCategoryType(null);
    } else if (sortingItemType) {
      if (
        user.facility_type === FacilityType.KEIKAKUSODAN ||
        user.facility_type === FacilityType.CHIIKIIKO ||
        user.facility_type === FacilityType.CHIIKITEICHAKU
      ) {
        // 並び替え不可の項目はリクエストから排除
        const sortableValues = values.map((record) => {
          const sortableCustomRecordItems = record.custom_record_items.filter(
            (v) => v.order !== null
          );
          return { ...record, custom_record_items: sortableCustomRecordItems };
        });
        await postCustomRecordsOrder(sortableValues, sortingItemType);
      } else {
        await postCustomRecordsOrder(values, sortingItemType);
      }
      await fetchCustomRecords();
      await setSortingItemType(null);
    }
    actions.setSubmitting(false);
    setEvaluationPeriodDisabled(false);
  };

  // 非表示項目リスト
  const openHiddenCustomRecordsList = (): void => {
    setHiddenCustomRecordCategoryType(categoryType);
    setOpenHiddenCustomRecordList(true);
  };

  const closeHiddenCustomRecordsList = async (): Promise<void> => {
    await setOpenHiddenCustomRecordList(false);
    setHiddenCustomRecordCategoryType(0);
  };

  const onClickVisible = async (
    visibleRecordIds: {
      item: number[];
      category: number[];
    },
    invisibleRecordIds: {
      item: number[];
      category: number[];
    }
  ): Promise<void> => {
    await showCustomRecord(visibleRecordIds, invisibleRecordIds);
    await fetchCustomRecords();
    await setOpenHiddenCustomRecordList(false);
  };

  const categoryName = (): string => {
    switch (user.facility_type) {
      case FacilityType.A:
      case FacilityType.B:
        return AB_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.IKOU:
        return IKOU_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.KEIKAKUSODAN:
        return KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.CHIIKIIKO:
        return CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.CHIIKITEICHAKU:
        return CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.GROUP_HOME:
        return GROUP_HOME_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      case FacilityType.SEIKATSUKAIGO:
        return SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
      default:
        return ASSESSMENT_CATEGORY_TYPE_NAME[categoryType];
    }
  };

  const sortedCustomRecordsForHiddenItemsWithCategoryModal = (): CustomRecordsWithCategoryState => {
    switch (user.facility_type) {
      case FacilityType.KEIKAKUSODAN:
      case FacilityType.CHIIKIIKO:
      case FacilityType.CHIIKITEICHAKU:
        // デフォルトカテゴリー → カスタムカテゴリーの順に表示する
        // 削除機能が存在しないアセスメントの設定ではidの昇順にすることで表示条件を満たす
        return orderBy(props.customRecords, "id");
      default:
        return props.customRecords;
    }
  };

  return (
    <>
      <div
        className={
          !sortingCategoryType || sortingCategoryType === categoryType
            ? classes.editable
            : classes.unEditable
        }
      >
        <FormPaper>
          <SectionTitle label={`【${categoryName()}】の項目設定`} />
          <div
            className={classNames(classes.description, {
              [classes.descriptionMarginBottom]: isHiddenButton
            })}
          >
            {label}
          </div>
          <Formik
            initialValues={commonTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                {isHiddenButton ? null : (
                  <div className={classes.pureSettingTopSection}>
                    {evaluationPeriodRecord &&
                      evaluationPeriodRecord.length > 0 && (
                        <div className={classes.evaluationPeriod}>
                          <Checkbox
                            label="評価期間の入力項目を表示する"
                            value={`${evaluationPeriodRecord[0].id}`}
                            checked={numberToBoolean(
                              evaluationPeriodRecord[0].visibility
                            )}
                            labelStyle={{
                              width: "100%"
                            }}
                            inputStyle={{
                              height: "24px"
                            }}
                            disabled={evaluationPeriodDisabled}
                            onChange={(): void => {
                              openEvaluationPeriodConfirmModal(
                                evaluationPeriodRecord[0].id
                              );
                            }}
                          />
                        </div>
                      )}
                    <SettingFormButtons
                      isCategorized={isCategorized}
                      orderDisable={
                        sortedCustomRecords.filter((v) =>
                          user.facility_type === FacilityType.KEIKAKUSODAN ||
                          user.facility_type === FacilityType.CHIIKIIKO ||
                          user.facility_type === FacilityType.CHIIKITEICHAKU
                            ? v.allow_change_order
                            : true
                        ).length <= 1
                      }
                      categoryType={categoryType}
                      sortingCategoryType={sortingCategoryType}
                      setSortingCategoryType={setSortingCategoryType}
                      openAddModal={openAddModal}
                      formikProps={formikProps}
                      formValues={commonTableValues}
                      stopHistory={stopHistory}
                      editedId={editedId}
                      setSortingItemType={setSortingItemType}
                      sortingItemType={sortingItemType}
                      setEvaluationPeriodDisabled={setEvaluationPeriodDisabled}
                      facilityType={user.facility_type}
                    />
                  </div>
                )}
                <CustomRecordsTable
                  isCategorized={isCategorized}
                  openAddModal={openAddModal}
                  changeEditMode={changeEditMode}
                  openHideConfirmModal={openHideConfirmModal}
                  openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                  settingType={categoryType}
                  isSortingCategory={sortingCategoryType === categoryType}
                  editedId={editedId}
                  editedType={editedType}
                  formikProps={formikProps}
                  formValues={commonTableValues}
                  endEdit={endEdit}
                  sortingItemType={sortingItemType}
                  setSortingItemType={setSortingItemType}
                  invisibleCustomRecordExist={invisibleCustomRecordExist}
                  stopHistory={stopHistory}
                  facilityType={user.facility_type}
                  setEvaluationPeriodDisabled={setEvaluationPeriodDisabled}
                />
              </>
            )}
          </Formik>
        </FormPaper>
      </div>
      <HiddenItemsWithCategoryModal
        isModalOpen={isOpenHiddenCustomRecordList}
        onClose={closeHiddenCustomRecordsList}
        onVisible={onClickVisible}
        hiddenCustomRecordCategoryType={hiddenCustomRecordCategoryType}
        customRecords={sortedCustomRecordsForHiddenItemsWithCategoryModal()}
        isCategorized={isCategorized}
        facilityType={user.facility_type}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory, uiDispatch } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );
  const uiDispatches = uiDispatch(dispatch);

  return {
    postCustomRecordsCategoryOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsCategoryOrder(
        visibleCustomRecords
      );
    },
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState,
      customRecordCategoryId: number
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsOrder(
        visibleCustomRecords,
        customRecordCategoryId
      );
    },
    showCustomRecord: (
      visibleRecordIds: {
        item: number[];
        category: number[];
      },
      invisibleRecordIds: {
        item: number[];
        category: number[];
      }
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.showCustomRecord(
        visibleRecordIds,
        invisibleRecordIds
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const AssessmentSettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentSettingFormCore)
);
