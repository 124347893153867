import * as React from "react";
import * as URL from "@constants/url";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";
import { StaffMeetingRecordForm } from "@components/organisms/mgr/Cseg/record/staffMeetingRecord/StaffMeetingRecordForm";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  meetingRecordId: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UserState;
  userInFacility:
    | KEIKAKUSODANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
};

type DispatchProps = {
  fetchOneUser: (facilityType: FacilityType, id: string) => void;
  fetchMeetingRecord: (uifId: string, meetingRecordId: string) => Promise<void>;
  fetchStaffs: () => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps;

const StaffMeetingRecordCore = (props: Props): JSX.Element => {
  const { history, classes, user, userInFacility } = props;
  const { uifId, meetingRecordId } = props.match.params;
  const userInFacilityId = userInFacility.user_in_facility.id;

  // ページ名
  const listName =
    user.facility_type === FacilityType.KEIKAKUSODAN
      ? "計画・モニタリング・会議記録"
      : "計画・報告書・会議記録";
  const pageName = "サービス担当者会議記録";
  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(user.facility_type, uifId);
    props.fetchMeetingRecord(uifId, meetingRecordId);
    props.fetchStaffs();
  }, [uifId, user.facility_type]);

  if (!userInFacilityId) {
    return <></>;
  }

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <UserInfoRecord user={userInFacility} />
        {/* フォーム */}
        <div className={classes.con}>
          <StaffMeetingRecordForm
            user={userInFacility}
            userInFacilityId={userInFacilityId}
            history={history}
            pageName={pageName}
            facilityType={user.facility_type}
          />
        </div>
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const facilityType = user.facility_type;
  return {
    user,
    userInFacility: state[facilityType].userInFacility.user
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, staffDispatcher } = dispatches;
  return {
    fetchOneUser: (facilityType: FacilityType, id: string): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchMeetingRecord: (
      uifId: string,
      meetingRecordId: string
    ): Promise<void> =>
      Cseg.meetingRecordDispatcher(dispatch).fetchMeetingRecord(
        uifId,
        meetingRecordId
      ),
    fetchStaffs: staffDispatcher(dispatch).fetch
  };
};

export const StaffMeetingRecord = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(StaffMeetingRecordCore)
);
