import React, { useState } from "react";
import { AppState } from "@stores/type";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  Theme,
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import * as H from "history";
import validator from "@validator";

import dispatches from "@stores/dispatches";
import { NewAccountParams, AccountState } from "@stores/domain/account/type";
import PasswordField from "@components/molecules/PasswordField";
import MuiTextField from "@components/molecules/MuiTextField";
import LinkButton from "@components/atoms/LinkButton";
import { INTRODUCTORY_CLAUSE, PRIVACY_POLICY } from "@constants/url";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: 32
    },
    submitButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      letterSpacing: 1.25,
      marginTop: spacing.unit
    },
    submitButton: {
      width: 320,
      boxShadow: "none",
      textTransform: "none"
    },
    disableButton: {
      width: 320,
      fontSize: 14,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
      borderRadius: 4
    },
    title: {
      margin: "48px 0 32px 0",
      paddingBottom: 7,
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)",
      fontSize: 20,
      color: "#37474f",
      fontWeight: "normal"
    },
    termsWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: 48
    },
    terms: {
      marginLeft: 40
    }
  });

type DispatchProps = {
  activate: (
    params: NewAccountParams,
    token: string,
    history: H.History
  ) => void;
  refreshActivateErrorMessage: (role: string, target: string) => void;
};

type StateProps = {
  account: AccountState;
};

type OwnProps = {
  history: H.History;
  email: string;
  token: string;
  isTimeCardAccountCreate: boolean;
};

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

type ErrorMessages = {
  admin: {
    password: string;
  };
  user: {
    accountId: string;
    password: string;
  };
};

const NewAccountFormCore = (props: Props): JSX.Element => {
  const [adminPassword, setAdminPassword] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [accountId, setAccountId] = useState("");
  const [accountIdError, setAccountIdError] = useState("");

  const isSubmit = (): string => {
    if (props.isTimeCardAccountCreate) {
      return adminPassword && userPassword && accountId;
    }
    return adminPassword;
  };

  const getTargetErrorMessage = (
    role: string,
    target: string
  ): AccountState["activateRes"][number] | undefined => {
    return props.account.activateRes.find((res) => {
      return res.role === role && res.error.target === target;
    });
  };

  const getErrorMessage = (): ErrorMessages => {
    const adminPasswordErrorMessage = getTargetErrorMessage(
      "mgradmin",
      "password"
    );
    const userPasswordErrorMessage = getTargetErrorMessage(
      "mgruser",
      "password"
    );
    const userAccountIdErrorMessage = getTargetErrorMessage("mgruser", "email");

    return {
      admin: {
        password: adminPasswordErrorMessage
          ? adminPasswordErrorMessage.error.message
          : ""
      },
      user: {
        accountId: userAccountIdErrorMessage
          ? userAccountIdErrorMessage.error.message
          : accountIdError,
        password: userPasswordErrorMessage
          ? userPasswordErrorMessage.error.message
          : ""
      }
    };
  };

  const errorMessages = getErrorMessage();

  const handleSubmit = (): void => {
    props.activate(
      {
        email: props.email,
        password: adminPassword,
        mgruser_email: accountId.length ? accountId : null,
        mgruser_password: userPassword || null
      },
      props.token,
      props.history
    );
  };

  const handleChangeAccountId = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (
      props.account.activateRes.filter((target) => {
        return target.role === "mgruser";
      }).length
    ) {
      props.refreshActivateErrorMessage("mgruser", "accountId");
    }
    const { value } = event.currentTarget;
    const error = validator(value, {
      type: "accountId",
      accountType: "timeCard"
    });
    setAccountIdError(error || "");
    setAccountId(error ? "" : value);
  };

  const onAdminChange = (password: string): void => {
    props.refreshActivateErrorMessage("mgradmin", "password");
    setAdminPassword(password);
  };

  const onUserChange = (password: string): void => {
    props.refreshActivateErrorMessage("mgruser", "password");
    setUserPassword(password);
  };

  return (
    <div className={props.classes.wrapper}>
      <h2 className={props.classes.title}>
        管理画面アカウントのパスワード登録
      </h2>
      <PasswordField
        passwordName="管理画面パスワード"
        confirmHelperText="パスワードをもう一度入力"
        onChange={onAdminChange}
        errorMessage={errorMessages.admin.password}
      />
      {props.isTimeCardAccountCreate && (
        <>
          <h2 className={props.classes.title}>
            タイムカード画面アカウントの登録
          </h2>
          <MuiTextField
            label="タイムカード画面アカウント"
            required
            onChange={handleChangeAccountId}
            error={Boolean(errorMessages.user.accountId)}
            helperText={
              errorMessages.user.accountId ||
              "8文字以上　半角英字・数字・記号いずれも使用可"
            }
            maxLength={100}
            size="long"
          />
          <PasswordField
            passwordName="タイムカード画面パスワード"
            confirmHelperText="パスワードをもう一度入力"
            onChange={onUserChange}
            errorMessage={errorMessages.user.password}
          />
        </>
      )}

      <div className={props.classes.termsWrapper}>
        <LinkButton to={PRIVACY_POLICY} target="_blank">
          プライバシーポリシー
        </LinkButton>
        <LinkButton
          to={INTRODUCTORY_CLAUSE}
          target="_blank"
          className={props.classes.terms}
        >
          導入約款
        </LinkButton>
      </div>
      <div className={props.classes.submitButtonWrapper}>
        {isSubmit() ? (
          <Button
            variant="contained"
            onClick={handleSubmit}
            color="secondary"
            className={props.classes.submitButton}
          >
            上記の内容に同意し、登録する
          </Button>
        ) : (
          <div className={props.classes.disableButton}>
            上記の内容に同意し、登録する
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { accountDispatch } = dispatches;
  const accountDispatches = accountDispatch(dispatch);
  return {
    activate: (
      params: NewAccountParams,
      token: string,
      history: H.History
    ): Promise<void> => accountDispatches.activate(params, token, history),
    refreshActivateErrorMessage: (role: string, target: string): void =>
      accountDispatches.refreshActivateErrorMessage(role, target)
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  account: state.account
});

export const NewAccountForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(NewAccountFormCore));
