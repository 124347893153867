import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import EditUserForm from "@components/organisms/mgr/SHISETSUNYUSHO/Users/EditUserForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_OPTIONS_123
} from "@constants/variables";
import { INVOICE_PATHS_FUNCTIONS } from "@constants/mgr/SHISETSUNYUSHO/variables";

type Props = RouteComponentProps<{ id: string }>;

/**
 * 利用者情報 > 編集
 */
const EditUser = (props: Props): JSX.Element => {
  return (
    <AdminTemplate
      pageName="利用者情報"
      invoiceVersion={{
        currentVersion: INVOICE_VERSION_CURRENT,
        versionPaths: INVOICE_PATHS_FUNCTIONS.user(props.match.params.id),
        versionOptions: INVOICE_VERSION_OPTIONS_123
      }}
    >
      <EditUserForm {...props} />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

export default EditUser;
