/**
 * 同時刻かどうかをチェック
 *
 * @param startTime
 * @param endTime
 * @param option
 */
export const checkTimeEqual = (
  startTime: string,
  endTime: string,
  option = { firstLabel: "終了時間", secondLabel: "開始時間" }
): string | undefined => {
  let errorMessage;
  const startHour = parseInt(startTime.replace(":", ""), 10);
  const endHour = parseInt(endTime.replace(":", ""), 10);

  if (endHour === startHour) {
    errorMessage = `${option.firstLabel}は${option.secondLabel}より後の時間を入力してください`;
  }

  return errorMessage;
};
