import * as H from "history";
import * as URL from "@constants/url";
import { PLAN_LIST_TAB_PATH } from "@constants/variables";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { supportPlanSheetsApi } from "@api/requests/supportPlanSheets";
import { SupportPlanSheets } from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import staffApi from "@api/requests/staff";
import * as loadingActions from "@stores/loading/actions";
import * as staffActions from "@stores/domain/staff/actions";
import * as navigationTransitionActions from "@stores/ui/navigationTransition/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import { normalizeFormValuesToPostSupportPlanUifIdSupportPlanSheets } from "./normalizer";

const fetchSupportPlanUifIdSupportPlanSheets = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportPlanUifIdSupportPlanSheetsStarted());
  await supportPlanSheetsApi
    .getSupportPlanUifIdSupportPlanSheets(uifId)
    .then((res) => {
      dispatch(
        actions.fetchSupportPlanUifIdSupportPlanSheetsSuccess(res.data.data)
      );
    })
    .catch((e) => {
      dispatch(
        actions.fetchSupportPlanUifIdSupportPlanSheetsFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId = (
  dispatch: Dispatch
) => async (uifId: string, supportPlanSheetId: string): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(
    actions.fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted()
  );
  dispatch(staffActions.fetchStarted());

  Promise.all([
    staffApi.getStaff(),
    await supportPlanSheetsApi.getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
      uifId,
      supportPlanSheetId
    )
  ])
    .then((res) => {
      dispatch(staffActions.fetchSuccess(res[0].data));
      dispatch(
        actions.fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess(
          res[1].data.data
        )
      );
    })
    .catch((e) => {
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(
        actions.fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postSupportPlanUifIdSupportPlanSheets = (dispatch: Dispatch) => async (
  uifId: string,
  sheet: SupportPlanSheets,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportPlanUifIdSupportPlanSheetsStarted());
  const normalizedParams = normalizeFormValuesToPostSupportPlanUifIdSupportPlanSheets(
    sheet
  );
  await supportPlanSheetsApi
    .postSupportPlanUifIdSupportPlanSheets(uifId, normalizedParams)
    .then(() => {
      dispatch(actions.postSupportPlanUifIdSupportPlanSheetsSuccess());
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS}`
      );
    })
    .catch((e) => {
      dispatch(
        actions.postSupportPlanUifIdSupportPlanSheetsFailed({
          error: e.response
        })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId = (
  dispatch: Dispatch
) => async (
  uifId: string,
  supportPlanSheetId: string,
  history: H.History
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(
    actions.deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdStarted()
  );
  await supportPlanSheetsApi
    .deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
      uifId,
      supportPlanSheetId
    )
    .then(() => {
      dispatch(
        actions.deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdSuccess(
          supportPlanSheetId
        )
      );
      dispatch(navigationTransitionActions.cancelNavigationTransition());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
      history.push(
        `${URL.RECORD_SUPPORT_PLAN}/${uifId}/${PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS}`
      );
    })
    .catch((e) => {
      dispatch(
        actions.deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetIdFailed(
          {
            error: e.response
          }
        )
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchSupportPlanUifIdSupportPlanSheets: (uifId: string) => Promise<void>;
  fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
    uifId: string,
    supportPlanSheetId: string
  ) => Promise<void>;
  postSupportPlanUifIdSupportPlanSheets: (
    uifId: string,
    sheet: SupportPlanSheets,
    history: H.History
  ) => Promise<void>;
  deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
    uifId: string,
    supportPlanSheetId: string,
    history: H.History
  ) => Promise<void>;
};

export const supportPlanSheetsDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchSupportPlanUifIdSupportPlanSheets: fetchSupportPlanUifIdSupportPlanSheets(
    dispatch
  ),
  fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
    dispatch
  ),
  postSupportPlanUifIdSupportPlanSheets: postSupportPlanUifIdSupportPlanSheets(
    dispatch
  ),
  deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
    dispatch
  )
});
