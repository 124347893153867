import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormPaper from "@components/atoms/FormPaper";
import FormikTextField from "@components/molecules/FormikTextField";
import SectionTitle from "@components/atoms/SectionTitle";
import Typography from "@material-ui/core/Typography";

const styles = (): StyleRules =>
  createStyles({
    description: {
      marginBottom: 28,
      color: "#37474f"
    },
    labelWidth: {
      "& > label": {
        width: 360
      }
    },
    inputWidth: {
      "Text-Field-Elements-Border-Bottom-Bold": { width: 280 }
    }
  });

type Props = WithStyles<typeof styles>;

const PastUsageFieldsCore = (props: Props): JSX.Element => {
  return (
    <FormPaper>
      <div style={{ marginBottom: 18 }}>
        <SectionTitle label="過去6ヶ月間の実績" />
      </div>
      <p className={props.classes.description}>
        上記「初回請求月」の過去6ヶ月間の計画相談支援対象者等の数（指定障害児相談支援事業所と一体的に運営している場合は障害児を含む）および、相談支援員の員数を入力してください。
      </p>
      <FormGroup>
        <Typography style={{ paddingBottom: 8 }}>1ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_1_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ width: 280 }}
            name="initialData.facility.total_number_of_staffs_1_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
        <Typography style={{ paddingBottom: 8 }}>2ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_2_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ width: 280 }}
            name="initialData.facility.total_number_of_staffs_2_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
        <Typography style={{ paddingBottom: 8 }}>3ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_3_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ width: 280 }}
            name="initialData.facility.total_number_of_staffs_3_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
        <Typography style={{ paddingBottom: 8 }}>4ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_4_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ width: 280 }}
            name="initialData.facility.total_number_of_staffs_4_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
        <Typography style={{ paddingBottom: 8 }}>5ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_5_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ width: 280 }}
            name="initialData.facility.total_number_of_staffs_5_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
        <Typography style={{ paddingBottom: 8 }}>6ヶ月前</Typography>
        <FormGroup row>
          <FormikTextField
            style={{ marginBottom: 0, marginRight: 24, width: 280 }}
            className={props.classes.labelWidth}
            name="initialData.facility.total_number_of_users_6_month_before"
            label="計画相談支援/障害児相談支援の対象者等の数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
          <FormikTextField
            style={{ marginBottom: 0, marginRight: 24, width: 280 }}
            name="initialData.facility.total_number_of_staffs_6_month_before"
            label="相談支援員の員数"
            endAdornmentLabel="人"
            placeholder="0"
            required
          />
        </FormGroup>
      </FormGroup>
    </FormPaper>
  );
};

export const PastUsageFields = withStyles(styles)(PastUsageFieldsCore);
