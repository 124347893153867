import React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from "@material-ui/core";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";

// utils
import { RecordHeader } from "../RecordHeader";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/CHIIKIIKO/planMonitoringMeeting/types";
import { PlanMonitoringMeetingTable } from "./PlanMonitoringMeetingTable";
import Paper from "@material-ui/core/Paper";
import spacing from "@material-ui/core/styles/spacing";
import { CHIIKIIKO_CREATE_MODAL_OPTIONS } from "@constants/mgr/CHIIKIIKO/variables";

const styles = (): StyleRules =>
  createStyles({
    list: {
      background: "#fff",
      borderRadius: "4px"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`
    },
    createButtonWrapper: {
      display: "flex",
      justifyContent: "flex-end"
    },
    createButton: {
      marginBottom: 8,
      width: "120px"
    },
    dialog: {
      "&>div:nth-child(2)>div": {
        maxWidth: "600px",
        width: "100%"
      }
    },
    dialogTitle: {
      padding: "16px 32px",
      background: "#f5f5f5",
      borderBottom: "1px solid #cfd8dc",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    dialogConBtn: {
      marginTop: "24px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    submitBtn: {
      width: 206
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  history: H.History;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 計画・報告書・会議記録 一覧
 *
 */
const PlanMonitoringMeetingRecordListCore = (
  props: Props
): JSX.Element | null => {
  const { classes } = props;
  const [isOpenCreateModal, setIsOpenCreateModal] = React.useState<boolean>(
    false
  );
  const [targetFlg, setTargetFlg] = React.useState<string>("0");

  const onClickNew = (): void => {
    setIsOpenCreateModal(true);
  };

  const onClickCancel = (): void => {
    setIsOpenCreateModal(false);
  };

  const newUrl = React.useMemo(() => {
    switch (targetFlg) {
      case "0":
      case "1":
      case "2":
        return `#/record/consultation/new/${props.uifId}/${targetFlg}`;
      case "101":
        return `#/record/support_plan/${props.uifId}/assistance_report/new`;
      case "102":
        return `#/record/meeting_record/new/${props.uifId}`;
      default:
        return "#";
    }
  }, [targetFlg]);

  return (
    <>
      <div className={classes.list}>
        <RecordHeader
          pageName="計画・報告書・会議記録"
          userName={props.userName}
          uifId={props.uifId}
          recordType="plan_monitoring_meeting"
          isEditing={false}
          history={props.history}
        />
        <Paper elevation={0} className={classes.tableContainer}>
          <div className={classes.createButtonWrapper}>
            <KnowbeButton
              kind="default"
              className={classes.createButton}
              onClick={onClickNew}
            >
              新規作成
            </KnowbeButton>
          </div>
          <PlanMonitoringMeetingTable
            record={props.planMonitoringMeeting}
            uifId={props.uifId}
          />
        </Paper>
      </div>

      {/* 新規作成モーダル */}
      <Dialog className={classes.dialog} open={isOpenCreateModal}>
        <DialogTitle className={classes.dialogTitle}>新規作成</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <MuiRadioButtons
            label=""
            style={{ margin: "24px 0 24px 8px" }}
            options={CHIIKIIKO_CREATE_MODAL_OPTIONS}
            value={targetFlg}
            onChange={(_, value): void => setTargetFlg(value)}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <KnowbeButton kind="outline" onClick={onClickCancel}>
            キャンセル
          </KnowbeButton>
          <KnowbeButton
            kind="default"
            className={classes.submitBtn}
            href={newUrl}
          >
            確定して編集画面に進む
          </KnowbeButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const PlanMonitoringMeetingRecordList = withStyles(styles)(
  PlanMonitoringMeetingRecordListCore
);
