import { BasicValues } from "@interfaces/v202104/mgr/CHIIKIIKO/facility/basic";
import { BasicRemunerationValues } from "@interfaces/v202104/mgr/CHIIKIIKO/facility/basicRemuneration";
import { AdditionalItemValues } from "@interfaces/v202104/mgr/CHIIKIIKO/facility/additionItem";
import { FacilityState } from "@stores/v202104/domain/mgr/CHIIKIIKO/facility/types";
import { DEFAULT_CHIIKIIKO_SERVICE_TYPE } from "@constants/mgr/CHIIKIIKO/variables";

export type FacilityValues = BasicValues &
  BasicRemunerationValues &
  AdditionalItemValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : ""
    },
    basicRemuneration: {
      chiikiikoServiceType: state
        ? state.chiikiikoServiceType
        : DEFAULT_CHIIKIIKO_SERVICE_TYPE
    },
    additionalItem: {
      peerSupportFlg: state ? state.peerSupportFlg : false,
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false
    }
  };
};
