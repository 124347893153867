import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
// ui
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import { FormGroup } from "@material-ui/core";
import { FieldItem } from "@interfaces/ui/form";
// formik
import { FormikProps, getIn } from "formik";
import {
  ServiceDeliveryDetailValues,
  DetailsInitialValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import { DAY_SELECT_OPTIONS, FacilityType } from "@constants/variables";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 752,
      marginBottom: 18
    },
    tableCell: {
      padding: "5px 5px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        padding: "5px 5px"
      }
    },
    firstTableCell: {
      paddingLeft: 16
    },
    timeItemCell: {
      width: 280
    },
    iconCell: {
      paddingTop: 16,
      display: "inline-flex",
      verticalAlign: "top"
    },
    firstTableValue: {
      position: "relative",
      top: "20px",
      right: "-16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  unitKey: string;
  onClickDelete: (index: number, unit: DetailsInitialValues[0]) => void;
  onChangeTime: (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ) => void;
  onChangeSelect: (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ) => void;
  onChangeActionClass: (index: number, actionClass: number) => void;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  practitionerNum: number;
  actionClassLabel: string;
  actionClassOptions: FieldItem[];
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const UnitsTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const practitionerValues =
      props.practitionerNum === 1
        ? props.formikProps.values.serviceDeliveryRecordPractitioners1
        : props.formikProps.values.serviceDeliveryRecordPractitioners2;
    const { classes } = props;

    const units: DetailsInitialValues =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;

    const isIdoshien = props.facilityType === FacilityType.IDOSHIEN;
    const isVehicleTransportStatus =
      props.formikProps.values.status ===
      IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value;
    const headData = [
      {
        label:
          props.facilityType === FacilityType.KODOENGO ||
          (isIdoshien && isVehicleTransportStatus)
            ? "空き"
            : `${props.actionClassLabel}/空き`,
        className: `${classes.firstTableCell}`
      },
      {
        label: "開始時間",
        className: `${classes.timeItemCell}`
      },
      {
        label: "終了時間",
        className: `${classes.timeItemCell}`
      },
      {
        label: "削除"
      }
    ];

    const bodyData = (
      i: number,
      onClickDelete: () => void,
      onChangeInTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void,
      onChangeInTimeDate: (event: React.ChangeEvent<HTMLSelectElement>) => void,
      onChangeOutTime: (
        event: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >,
        beforeValue: string,
        autoCorrectValue: string
      ) => void,
      onChangeOutTimeDate: (
        event: React.ChangeEvent<HTMLSelectElement>
      ) => void,
      onChangeClass: (event: React.ChangeEvent<HTMLSelectElement>) => void
    ): KnowbeTableCellParam[] => [
      {
        label:
          props.facilityType === FacilityType.KODOENGO ||
          (isIdoshien && isVehicleTransportStatus) ? (
            <span className={props.classes.firstTableValue}>空き</span>
          ) : (
            <FormikSelect
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].actionClass`}
              label=""
              size="superSmall"
              options={props.actionClassOptions}
              style={{ marginLeft: 16, marginRight: 32, marginBottom: 16 }}
              onChangeHook={onChangeClass}
            />
          )
      },
      {
        label: (
          <FormGroup row>
            <FormikSelect
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTimeDate`}
              label=""
              options={DAY_SELECT_OPTIONS}
              style={{ width: 80, marginBottom: 16 }}
              onChangeHook={onChangeInTimeDate}
              noerrortext
              error={
                getIn(
                  props.formikProps.errors,
                  `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTimeDate`
                ) !== undefined
              }
            />
            <FormikTime
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTime`}
              label=""
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={onChangeInTime}
              style={{ marginTop: 16, marginRight: 24, marginBottom: 16 }}
              error={
                getIn(
                  props.formikProps.errors,
                  `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].startTime`
                ) !== undefined
              }
            />
          </FormGroup>
        )
      },
      {
        label: (
          <FormGroup row>
            <FormikSelect
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTimeDate`}
              label=""
              options={DAY_SELECT_OPTIONS}
              style={{ width: 80, marginBottom: 16 }}
              noerrortext
              onChangeHook={onChangeOutTimeDate}
              error={
                getIn(
                  props.formikProps.errors,
                  `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTimeDate`
                ) !== undefined
              }
            />
            <FormikTime
              name={`${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTime`}
              label=""
              placeholder="00:00"
              size="smallMedium"
              maxLength={5}
              onChangeHookTime={onChangeOutTime}
              style={{ marginTop: 16, marginRight: 24, marginBottom: 16 }}
              error={
                getIn(
                  props.formikProps.errors,
                  `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${i}].endTime`
                ) !== undefined
              }
            />
          </FormGroup>
        )
      },
      {
        // iconCell
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 28,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickDelete}
            />
          </FormGroup>
        )
      }
    ];

    const bodyValues = units
      // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((unit: { value: DetailsInitialValues[0]; baseIndex: number }) => {
        // 提供人数「２」→「１」の時の対応（選択肢が””になるのを防ぐ）
        if (!unit.value.startTimeDate) {
          props.setFormikFieldValue(
            `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${unit.baseIndex}].startTimeDate`,
            DAY_SELECT_OPTIONS[0].value
          );
        }
        if (!unit.value.endTimeDate) {
          props.setFormikFieldValue(
            `${props.unitKey}.serviceDeliveryRecordPractitionerDetails[${unit.baseIndex}].endTimeDate`,
            DAY_SELECT_OPTIONS[0].value
          );
        }

        const onClickDelete = (): void => {
          props.onClickDelete(unit.baseIndex, unit.value);
        };
        const onChangeInTime = (
          event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >,
          beforeValue: string,
          autoCorrectValue: string
        ): void => {
          props.onChangeTime(
            unit.baseIndex,
            autoCorrectValue,
            unit.value.endTime,
            true
          );
        };
        const onChangeInTimeDate = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeSelect(
            unit.baseIndex,
            event.target.value,
            unit.value.endTimeDate,
            true
          );
        };
        const onChangeOutTime = (
          event: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
          >,
          beforeValue: string,
          autoCorrectValue: string
        ): void => {
          props.onChangeTime(
            unit.baseIndex,
            unit.value.startTime,
            autoCorrectValue,
            false
          );
        };
        const onChangeOutTimeDate = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeSelect(
            unit.baseIndex,
            unit.value.startTimeDate,
            event.target.value,
            false
          );
        };
        const onChangeClass = (
          event: React.ChangeEvent<HTMLSelectElement>
        ): void => {
          props.onChangeActionClass(unit.baseIndex, Number(event.target.value));
        };

        return bodyData(
          unit.baseIndex,
          onClickDelete,
          onChangeInTime,
          onChangeInTimeDate,
          onChangeOutTime,
          onChangeOutTimeDate,
          onChangeClass
        );
      });

    return (
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead
          uniqueKey="明細"
          height={40}
          items={headData}
          backgroundColor="secondary"
        />
        <KnowbeTableBody uniqueKey="明細情報" itemsContainer={bodyValues} />
      </KnowbeTable>
    );
  }
);
