import * as React from "react";
import * as H from "history";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import Button from "@material-ui/core/Button";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { RecordHeader as KEIKAKUSODANRecordHeader } from "@components/organisms/mgr/KEIKAKUSODAN/record/RecordHeader";
import { RecordHeader as CHIIKIIKORecordHeader } from "@components/organisms/mgr/CHIIKIIKO/record/RecordHeader";
import { Formik, Form, FormikActions, FormikProps } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { FieldItem } from "@interfaces/ui/form";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { toEffectiveObject } from "@utils/object";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import isEqual from "lodash-es/isEqual";
import { StaffMeetingRecordFields } from "./StaffMeetingRecordFields";
import { MeetingRecordState } from "@stores/domain/mgr/Cseg/meetingRecord/types";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import {
  initialValues,
  MeetingRecordValues
} from "@initialize/mgr/Cseg/record/meetingRecord/initialValues";
import { validation } from "@initialize/mgr/Cseg/record/meetingRecord/validation";
import { FacilityType } from "@constants/variables";
import {
  TYPE_CONSULTATION_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      margin: "32px auto"
    },
    con: {
      background: "#fff",
      padding: "16px 32px 32px"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    deleteCancelButton: {
      width: 120,
      marginRight: 8
    },
    deleteButton: {
      color: "#b00020",
      margin: 0
    }
  });

type OwnProps = {
  user:
    | KEIKAKUSODANUsersInFacilityState["user"]
    | CHIIKIIKOUsersInFacilityState["user"];
  userInFacilityId: number;
  history: H.History;
  pageName: string;
  isNew?: boolean;
  facilityType: FacilityType;
  queryParam?: string;
} & WithStyles<typeof styles>;

type StateProps = {
  meetingRecord: MeetingRecordState;
  staff: StaffState;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  fetchMeetingRecord: (uifId: string, meetingRecordId: string) => Promise<void>;
  postMeetingRecord: (
    uifId: number,
    values: MeetingRecordValues,
    initial?: MeetingRecordValues
  ) => Promise<void>;
  deleteMeetingRecord: (uifId: number, id: number) => void;
};

type MergeProps = {
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
} & OwnProps &
  StateProps &
  DispatchProps;

type Props = MergeProps;

const StaffMeetingRecordFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    user,
    facilityType,
    meetingRecord,
    staffOptions,
    staffOptionsAddSnapShot,
    authorValue,
    fetchMeetingRecord,
    userInFacilityId,
    history,
    isNew,
    queryParam
  } = props;

  let typeConsultation: number | undefined;
  if (facilityType === FacilityType.KEIKAKUSODAN) {
    typeConsultation =
      queryParam === "shogaiji"
        ? TYPE_CONSULTATION_SHOGAIJISODAN
        : TYPE_CONSULTATION_KEIKAKUSODAN;
  }

  const [formValues, setFormValues] = React.useState(
    initialValues(meetingRecord, staffOptions, { isNew, typeConsultation })
  );
  const [isEditing, setIsEditing] = React.useState(!!isNew);
  // 削除確認モーダル
  const [isOpenDeleteModal, setOpenDeleteModal] = React.useState(false);

  React.useEffect(() => {
    setFormValues(
      initialValues(meetingRecord, staffOptions, { isNew, typeConsultation })
    );
  }, [meetingRecord]);

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const confirmDiscardFormChanges = (nextValues: MeetingRecordValues): void => {
    const hasChange = !isEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  // バリデーション
  const validate = (values: MeetingRecordValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (isEditing) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  // 編集
  const onClickEdit = (): void => {
    setIsEditing(true);
  };

  // キャンセル
  const onClickCancel = (
    formikProps: FormikProps<MeetingRecordValues>
  ) => (): void => {
    formikProps.resetForm();
    props.stopHistory(false);
    if (isNew) {
      history.goBack();
    } else {
      setIsEditing(false);
    }
  };

  // 削除確認モーダル機能
  const onClickDelete = (): void => {
    setOpenDeleteModal(true);
  };

  const onSubmitDelete = async (): Promise<void> => {
    await props.deleteMeetingRecord(
      userInFacilityId,
      meetingRecord.meeting_records_id
    );
    props.stopHistory(false);
    history.push(`/record/${userInFacilityId}/plan_monitoring_meeting`);
  };

  const onClickCancelDelete = (): void => {
    setOpenDeleteModal(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // 更新
  const onSubmit = async (
    values: MeetingRecordValues,
    actions: FormikActions<MeetingRecordValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (isNew) {
      await props.postMeetingRecord(userInFacilityId, values);
      actions.setSubmitting(false);
      props.stopHistory(false);

      history.push(`/record/${userInFacilityId}/plan_monitoring_meeting`);
    } else {
      await props.postMeetingRecord(userInFacilityId, values, formValues);
      actions.setSubmitting(false);
      props.stopHistory(false);
      await fetchMeetingRecord(
        String(userInFacilityId),
        String(values.meeting_records_id)
      );
      setIsEditing(false);
    }
  };

  const EditButton = (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton
          kind="default"
          href={`#/record/meeting_record/print/${userInFacilityId}/${meetingRecord.meeting_records_id}`}
        >
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickEdit}>
          編集
        </KnowbeButton>
      </div>
    </div>
  );

  const SubmitButton = (
    formikProps: FormikProps<MeetingRecordValues>
  ): JSX.Element => (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="outline" onClick={onClickCancel(formikProps)}>
          キャンセル
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <FormikSubmitButton
          buttonName="保存する"
          formikProps={formikProps}
          errorAction={submitError}
        />
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.wrapper}>
        {/* フォーム */}
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            const FormButton = isEditing
              ? SubmitButton(formikProps)
              : EditButton;
            return (
              <>
                {facilityType === FacilityType.KEIKAKUSODAN && (
                  <KEIKAKUSODANRecordHeader
                    uifId={`${userInFacilityId}`}
                    recordType="plan_monitoring_meeting"
                    isEditing={isEditing}
                    history={history}
                    pageName={props.pageName}
                    userName={userName}
                    button={FormButton}
                  />
                )}
                {facilityType === FacilityType.CHIIKIIKO && (
                  <CHIIKIIKORecordHeader
                    uifId={`${userInFacilityId}`}
                    recordType="plan_monitoring_meeting"
                    isEditing={isEditing}
                    history={history}
                    pageName={props.pageName}
                    userName={userName}
                    button={FormButton}
                  />
                )}
                <div className={classes.con}>
                  <Form>
                    <StaffMeetingRecordFields
                      formikProps={formikProps}
                      authorValue={authorValue}
                      staffOptionsAddSnapShot={staffOptionsAddSnapShot}
                      isEditing={isNew || isEditing}
                      isNew={isNew}
                    />
                  </Form>
                </div>
              </>
            );
          }}
        </Formik>
      </div>
      <div className={props.classes.deleteButton}>
        {!isNew && isEditing && (
          <DeleteButton
            text={`${props.pageName}を削除する`}
            onClick={onClickDelete}
          />
        )}
      </div>

      {/* 削除確認モーダル */}
      <MessageDialog
        isOpen={isOpenDeleteModal}
        title={`${props.pageName}を削除します`}
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onClickCancelDelete}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteButton}
            onClick={onSubmitDelete}
          >
            削除する
          </Button>
        }
      />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    meetingRecord: state.Cseg.meetingRecord,
    staff: state.staff
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, Cseg } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    fetchMeetingRecord: (
      uifId: string,
      meetingRecordId: string
    ): Promise<void> =>
      Cseg.meetingRecordDispatcher(dispatch).fetchMeetingRecord(
        uifId,
        meetingRecordId
      ),
    postMeetingRecord: (
      userInFacilityId: number,
      values: MeetingRecordValues,
      initial?: MeetingRecordValues
    ): Promise<void> =>
      Cseg.meetingRecordDispatcher(dispatch).postMeetingRecord(
        userInFacilityId,
        values,
        initial
      ),
    deleteMeetingRecord: (uifId: number, id: number): void => {
      Cseg.meetingRecordDispatcher(dispatch).deleteMeetingRecord(uifId, id);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, meetingRecord } = stateProps;

  const author =
    meetingRecord.creation_staff !== null ? meetingRecord.creation_staff : 0;

  // 作成者名
  const authorValue =
    Object.keys(meetingRecord).length === 0
      ? ""
      : getSnapOrRealName(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // 作成者
  if (meetingRecord.creation_staff !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      meetingRecord.creation_staff
    );
  }

  return {
    authorValue,
    staffOptions,
    staffOptionsAddSnapShot,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const StaffMeetingRecordForm = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(StaffMeetingRecordFormCore)
);
