import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import { RecordSelectMonth } from "@components/organisms/mgr/common/record/RecordSelectMonth";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import Typography from "@material-ui/core/Typography";
import { WeeklyScheduleItems } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/WeeklyScheduleItems";
import AddIcon from "@material-ui/icons/Add";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import { FieldItem } from "@interfaces/ui/form";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  ConsultationRecordState,
  COPY_TYPE
} from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import { FormikProps, ArrayHelpers, FieldArray } from "formik";
import {
  ConsultationValues,
  initialSupportPlanConsultationDetails,
  loadValuesDetails,
  loadValuesSchedule,
  isDetailUpdate,
  isScheduleUpdate
} from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";
import { DISABILITY_CLASS_LIST, INCOME_KIND_LIST } from "@constants/variables";
import {
  MONITORING_PERIOD_LIST,
  CONSULTATION_TARGET_FRG
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    field: { marginTop: "32px" },
    goalField: { paddingLeft: "16px" },
    shortGoalField: { paddingLeft: "16px", marginBottom: "48px" },
    radio: {
      "&>div>div": {
        pointerEvents: "none"
      },
      "&>div>div>label>span:first-child": {
        color: "#757575"
      },
      "&>div>div>label>span:last-child": {
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "24px"
    },
    deleteButton: {
      marginTop: "32px",
      marginLeft: "-8px"
    },
    btnTxt: {
      marginLeft: "8px"
    },
    priorityCards: {
      padding: "24px 32px",
      borderRadius: "4px",
      border: "solid 1px #bdbdbd",
      marginBottom: "8px"
    },
    priorityCardsTitle: {
      fontSize: "14px",
      marginBottom: "32px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    loadPlanButtonWrapper: { display: "flex", justifyContent: "flex-end" },
    loadPlanButton: {
      width: "240px"
    },
    W240: {
      width: "240px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MB16: {
      marginBottom: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<ConsultationValues>;
  user: UsersInFacilityState["user"];
  authorValue: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  copyType: string;
  consultationCopy: ConsultationRecordState | null;
  isEditing: boolean;
  consultationTargetFlg: number;
  onClickLoad: (isSchedule?: boolean) => () => void;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  resetCopyConsultation: () => void;
  setIsDetailUpdate: reactDispatch<SetStateAction<boolean>>;
  setIsScheduleUpdate: reactDispatch<SetStateAction<boolean>>;
  isNew?: boolean;
} & WithStyles<typeof styles>;

type Props = OwnProps;

const ConsultationFieldsCore = (props: Props): JSX.Element => {
  const {
    formikProps,
    classes,
    staffOptionsAddSnapShot,
    consultationCopy,
    copyType,
    isEditing,
    authorValue,
    consultationTargetFlg,
    onClickLoad,
    isNew
  } = props;

  const { values } = formikProps;

  // 上書きフラグ
  React.useEffect(() => {
    props.setIsDetailUpdate(isDetailUpdate(values));
    props.setIsScheduleUpdate(isScheduleUpdate(values));
  }, [values]);

  // 計画読み込み
  React.useEffect(() => {
    if (consultationCopy) {
      if (copyType === COPY_TYPE.details) {
        formikProps.setValues(loadValuesDetails(values, consultationCopy));
      } else if (copyType === COPY_TYPE.schedule) {
        formikProps.setValues(loadValuesSchedule(values, consultationCopy));
      }
      props.resetCopyConsultation();
    }
  }, [consultationCopy]);

  // 作成済みの計画が存在するか
  const consultationExists = props.planMonitoringMeeting.yearly.some(
    (yearly) => {
      return yearly.monthly.some((monthly) => {
        return (
          monthly.daily && // モニタリング設定月だけ設定されており記録がない場合はdaily以下は返ってこない
          monthly.daily.some((daily) => {
            return daily.reports.some((report) => {
              return (
                report.target_flg ===
                  CONSULTATION_TARGET_FRG.CONSULTATION_PLAN ||
                report.target_flg === CONSULTATION_TARGET_FRG.CONSULTATION ||
                report.target_flg ===
                  CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
              );
            });
          })
        );
      });
    }
  );

  const monitoringPeriodValue = MONITORING_PERIOD_LIST.find(
    (item) => item.value === values.monitoringPeriod
  );

  const creationDateLabel =
    consultationTargetFlg === CONSULTATION_TARGET_FRG.CONSULTATION_PLAN
      ? "計画案作成日"
      : "計画作成日";

  const isDisabledAddButton =
    values.supportPlanConsultationDetails.filter((detail) => !detail.is_delete)
      .length >= 10;

  return (
    <div className={classes.wrapper}>
      {!isNew && (
        <div className={classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={values.createdAt}
            updatedAt={values.updatedAt}
          />
        </div>
      )}
      {/* 計画作成日 */}
      <div className={classes.MT16}>
        <RecordSelectDate
          name="creationDate"
          label={creationDateLabel}
          required
          value={values.creationDate}
          isEditable={isEditing}
          overrideYearFrom={1989}
        />
      </div>
      {/* 計画作成者 */}
      <div className={ClassNames(classes.field, classes.W240)}>
        <RecordSelect
          name="author"
          label="計画作成者"
          defaultValue=""
          placeholder="選択してください"
          options={staffOptionsAddSnapShot}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          value={authorValue}
          isEditable={isEditing}
          isSelectablePlaceholder
        />
      </div>
      {/* モニタリング期間 */}
      {(consultationTargetFlg === CONSULTATION_TARGET_FRG.CONSULTATION_PLAN ||
        consultationTargetFlg === CONSULTATION_TARGET_FRG.CONSULTATION) && (
        <>
          <div className={ClassNames(classes.field, classes.W240)}>
            <RecordSelect
              name="monitoringPeriod"
              label="モニタリング期間"
              defaultValue=""
              placeholder="選択してください"
              options={MONITORING_PERIOD_LIST}
              value={monitoringPeriodValue ? monitoringPeriodValue.label : ""}
              isEditable={isEditing}
              isSelectablePlaceholder
            />
          </div>
          {/* モニタリング開始年月 */}
          <div className={classes.field}>
            <RecordSelectMonth
              name="monitoringStartYm"
              label="モニタリング開始年月"
              value={values.monitoringStartYm}
              isEditable={isEditing}
              addYearTo={1}
              overrideYearFrom={1989}
            />
          </div>
        </>
      )}

      {/* 計画開始年月 */}
      <div className={classes.field}>
        <RecordSelectMonth
          name="planStartYm"
          label="計画開始年月"
          value={values.planStartYm}
          isEditable={isEditing}
          addYearTo={1}
          overrideYearFrom={1989}
        />
      </div>
      {/* 障害支援区分 */}
      <div className={classes.field}>
        {isEditing ? (
          <FormikRadioButtons
            name="classifyDisabilitySupport"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
          />
        ) : (
          <div className={classes.radio}>
            <MuiRadioButtons
              name="read_only_classify_disability_support"
              label="障害支援区分"
              options={DISABILITY_CLASS_LIST}
              value={values.classifyDisabilitySupport}
            />
          </div>
        )}
      </div>
      {(consultationTargetFlg === CONSULTATION_TARGET_FRG.CONSULTATION ||
        consultationTargetFlg ===
          CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION) && (
        <>
          {/* 利用者負担上限額 */}
          {isEditing ? (
            <FormikRadioButtons
              name="incomeKind"
              label="利用者負担上限額分"
              options={INCOME_KIND_LIST}
            />
          ) : (
            <div className={classes.radio}>
              <MuiRadioButtons
                name="read_only_incomeKind"
                label="利用者負担上限額分"
                options={INCOME_KIND_LIST}
                value={values.incomeKind}
              />
            </div>
          )}
        </>
      )}
      {/* 障害福祉サービス受給者証番号 */}
      <div className={classes.W240}>
        <RecordTextField
          name="disabledWelfareServiceRecipientNumber"
          label="障害福祉サービス受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.disabledWelfareServiceRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      {/* 通所受給者証番号 */}
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="regularPlaceRecipientNumber"
          label="通所受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.regularPlaceRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      {/* 地域相談支援受給者証番号 */}
      <div className={ClassNames(classes.W240, classes.field)}>
        <RecordTextField
          name="consultationSupportRecipientNumber"
          label="地域相談支援受給者証番号"
          labelType="default"
          defaultValue=""
          placeholder="0000000000"
          value={values.consultationSupportRecipientNumber}
          isEditable={isEditing}
          nonMultiline
          maxLength={10}
        />
      </div>
      {(consultationTargetFlg === CONSULTATION_TARGET_FRG.CONSULTATION ||
        consultationTargetFlg ===
          CONSULTATION_TARGET_FRG.CONSULTATION_PLAN) && (
        <>
          <Typography
            variant="subtitle1"
            className={ClassNames(classes.titleBg, classes.MT48)}
          >
            計画の詳細
          </Typography>
          {isEditing && (
            <div className={classes.loadPlanButtonWrapper}>
              <KnowbeButton
                className={classes.loadPlanButton}
                kind="default"
                onClick={onClickLoad()}
                disabled={!consultationExists}
              >
                作成済みの計画から読み込む
              </KnowbeButton>
            </div>
          )}
          {/* 利用者及びその家族の生活に対する意向（希望する生活） */}
          <div className={classes.field}>
            <RecordTextField
              name="desiredLife"
              label="利用者及びその家族の生活に対する意向（希望する生活）"
              labelType="default"
              defaultValue=""
              value={values.desiredLife}
              isEditable={isEditing}
              placeholder=""
            />
          </div>
          {/* 総合的な援助の方針 */}
          <div className={classes.field}>
            <RecordTextField
              name="supportPolicy"
              label="総合的な援助の方針"
              labelType="default"
              defaultValue=""
              value={values.supportPolicy}
              isEditable={isEditing}
              placeholder=""
            />
          </div>
          {/* 長期目標 */}
          <div className={ClassNames(classes.field, classes.goalField)}>
            <RecordTextField
              name="longTermGoal"
              label="長期目標"
              labelType="default"
              defaultValue=""
              value={values.longTermGoal}
              isEditable={isEditing}
              placeholder=""
            />
          </div>
          {/* 短期目標 */}
          <div
            className={ClassNames(
              classes.field,
              classes.shortGoalField,
              classes.MB32
            )}
          >
            <RecordTextField
              name="shortTermGoal"
              label="短期目標"
              labelType="default"
              defaultValue=""
              value={values.shortTermGoal}
              isEditable={isEditing}
              placeholder=""
            />
          </div>
          {/* 優先順位 */}
          <FieldArray name="supportPlanConsultationDetails">
            {(arrayHelpers: ArrayHelpers): JSX.Element => {
              return (
                <>
                  {values.supportPlanConsultationDetails
                    .map((detail, index) => {
                      return {
                        detail,
                        baseIndex: index
                      };
                    })
                    .filter((item) => !item.detail.is_delete)
                    .map((item, index) => {
                      const { detail, baseIndex } = item;
                      return (
                        <div
                          className={classes.priorityCards}
                          key={`details_${baseIndex}_${detail.support_plan_consultation_details_id}`}
                        >
                          <div
                            className={classes.priorityCardsTitle}
                          >{`優先順位${index + 1}`}</div>
                          {/* 解決すべき課題（本人のニーズ) */}
                          <div>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].needs`}
                              label="解決すべき課題（本人のニーズ）"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .needs
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>

                          {/* 支援目標 */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].support_goal`}
                              label="支援目標"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .support_goal
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>

                          {/* 達成時期 */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].achievement_time`}
                              label="達成時期"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .achievement_time
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>

                          {/* 福祉サービス等 種類・内容・量（頻度・時間） */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].welfare_service_type`}
                              label="福祉サービス等 種類・内容・量（頻度・時間）"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .welfare_service_type
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>
                          {consultationTargetFlg ===
                            CONSULTATION_TARGET_FRG.CONSULTATION && (
                            <>
                              {/* 福祉サービス等 提供事業者名（担当者名・電話） */}
                              <div className={classes.field}>
                                <RecordTextField
                                  name={`supportPlanConsultationDetails[${baseIndex}].provider_name`}
                                  label="福祉サービス等 提供事業者名（担当者名・電話）"
                                  labelType="default"
                                  defaultValue=""
                                  value={
                                    values.supportPlanConsultationDetails[
                                      baseIndex
                                    ].provider_name
                                  }
                                  isEditable={isEditing}
                                  placeholder=""
                                />
                              </div>
                            </>
                          )}

                          {/* 課題解決のための本人の役割 */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].role_in_problem_solving`}
                              label="課題解決のための本人の役割"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .role_in_problem_solving
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>
                          {/* 評価時期 */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].evaluation_time`}
                              label="評価時期"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .evaluation_time
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>
                          {/* その他留意事項 */}
                          <div className={classes.field}>
                            <RecordTextField
                              name={`supportPlanConsultationDetails[${baseIndex}].other_considerations`}
                              label="その他留意事項"
                              labelType="default"
                              defaultValue=""
                              value={
                                values.supportPlanConsultationDetails[baseIndex]
                                  .other_considerations
                              }
                              isEditable={isEditing}
                              placeholder=""
                            />
                          </div>
                          {isEditing && (
                            <div className={props.classes.deleteButton}>
                              <DeleteButton
                                text="削除する"
                                onClick={(): void => {
                                  formikProps.setFieldValue(
                                    `supportPlanConsultationDetails[${baseIndex}].is_delete`,
                                    1
                                  );
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  {isEditing && (
                    <KnowbeButton
                      kind="iconText"
                      className={classes.MT16}
                      onClick={(): void =>
                        arrayHelpers.push(initialSupportPlanConsultationDetails)
                      }
                      disabled={isDisabledAddButton}
                    >
                      <AddIcon />
                      <span className={classes.btnTxt}>
                        課題・支援内容等を追加する
                      </span>
                    </KnowbeButton>
                  )}
                </>
              );
            }}
          </FieldArray>
        </>
      )}
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        週間計画表
      </Typography>
      {isEditing && (
        <div
          className={ClassNames(classes.loadPlanButtonWrapper, classes.MB16)}
        >
          <KnowbeButton
            className={classes.loadPlanButton}
            kind="default"
            onClick={onClickLoad(true)}
            disabled={!consultationExists}
          >
            作成済みの計画から読み込む
          </KnowbeButton>
        </div>
      )}
      <WeeklyScheduleItems
        formikName="supportPlanConsultationSchedule"
        formikProps={formikProps}
        scheduleValues={values.supportPlanConsultationSchedule}
        isEditing={isEditing}
      />
      {/* 主な日常生活上の活動 */}
      <div className={classes.field}>
        <RecordTextField
          name="mainActivities"
          label="主な日常生活上の活動"
          labelType="default"
          defaultValue=""
          value={values.mainActivities}
          isEditable={isEditing}
          placeholder=""
        />
      </div>

      {/* 週単位以外のサービス */}
      <div className={classes.field}>
        <RecordTextField
          name="nonWeeklyService"
          label="週単位以外のサービス"
          labelType="default"
          defaultValue=""
          value={values.nonWeeklyService}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      {/* サービス提供によって実現する生活の全体像 */}
      <div className={classes.field}>
        <RecordTextField
          name="overallLife"
          label="サービス提供によって実現する生活の全体像"
          labelType="default"
          defaultValue=""
          value={values.overallLife}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      {/* 職員コメント */}
      <div className={classes.field}>
        <RecordTextField
          name="staffComment"
          label="職員コメント"
          labelType="default"
          defaultValue=""
          value={values.staffComment}
          isEditable={isEditing}
          placeholder=""
        />
      </div>
    </div>
  );
};

export const ConsultationFields = withStyles(styles)(ConsultationFieldsCore);
