import {
  InitialDataValues,
  InitialDataErrors
} from "@interfaces/mgr/SHISETSUNYUSHO/initial/initialData";
import validator, { dateValidator } from "@validator";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

const validation = (values: InitialDataValues): InitialDataErrors => {
  return {
    initialData: {
      facility: {
        first_time_bill_date: dateValidator(
          // first_time_bill_dateのフォーム値は日にちの情報を持たない為
          // 1日をdefaultで設定する
          notSelectedToEmpty({
            ...values.initialData.facility.first_time_bill_date,
            day: "1"
          }),
          "required"
        ),
        total_number_of_users_1_month_before: validator(
          values.initialData.facility.total_number_of_users_1_month_before,
          "required",
          "naturalNumber"
        ),
        total_number_of_users_2_month_before: validator(
          values.initialData.facility.total_number_of_users_2_month_before,
          "required",
          "naturalNumber"
        ),
        total_number_of_users_3_month_before: validator(
          values.initialData.facility.total_number_of_users_3_month_before,
          "required",
          "naturalNumber"
        ),
        number_of_days_open_1_month_before: validator(
          values.initialData.facility.number_of_days_open_1_month_before,
          "required",
          "naturalNumber",
          {
            type: "upperLimit",
            upperLimit: 31
          }
        ),
        number_of_days_open_2_month_before: validator(
          values.initialData.facility.number_of_days_open_2_month_before,
          "required",
          "naturalNumber",
          {
            type: "upperLimit",
            upperLimit: 31
          }
        ),
        number_of_days_open_3_month_before: validator(
          values.initialData.facility.number_of_days_open_3_month_before,
          "required",
          "naturalNumber",
          {
            type: "upperLimit",
            upperLimit: 31
          }
        )
      }
    }
  };
};
export default validation;
