import { SCHEDULE_NEXT_DAY_FLG_ITEM } from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  BasicErrors,
  BasicInfoFields,
  Schedule,
  SchedulesErrors
} from "@interfaces/mgr/KEIKAKUSODAN/record/basicInfo";
import validator, { Rule, dateValidator } from "@validator";
import { checkCharacterLength } from "@validator/rules";
import { isBefore } from "date-fns";
import { isEmpty } from "lodash-es";

const lengthRule = (length: number): Rule => ({
  type: "checkCharacterLength",
  length
});

// 初期値
export const basicInfoSchedulesValue: Schedule = {
  basic_info_schedules_id: null,
  day_of_week: [],
  start_time: "",
  end_time: "",
  next_day_flg: "0",
  content: "",
  is_delete: false
};

const schedulesData = (values: Schedule[]): SchedulesErrors[] => {
  return values.map((i) => {
    const nextDayFlg =
      i.next_day_flg === SCHEDULE_NEXT_DAY_FLG_ITEM.NEXT_DAY.value;
    const endTimeValidation = nextDayFlg
      ? validator(i.end_time || "", "checkTime")
      : validator(i.end_time || "", "checkTime", {
          type: "checkTimeFuture",
          startTime: i.start_time,
          options: { firstLabel: "終了時間", secondLabel: "開始時間" }
        });
    const endTimeNextDayFlg = (): boolean => {
      if (isEmpty(i.end_time) || isEmpty(i.start_time)) {
        return false;
      }
      if (i.end_time === i.start_time) {
        return true;
      }
      return isBefore(
        new Date(
          nextDayFlg ? `2020-01-02 ${i.end_time}` : `2020-01-01 ${i.end_time}`
        ),
        new Date(`2020-01-01 ${i.start_time}`)
      );
    };
    const res = {
      start_time: validator(i.start_time || "", "checkTime"),
      end_time: endTimeValidation
    };
    return endTimeNextDayFlg() ? { ...res, end_time_next_day_flg: " " } : res;
  });
};

export const validation = (values: BasicInfoFields): BasicErrors => {
  return {
    creation_date: dateValidator(values.creation_date, "required"),
    support_progress: checkCharacterLength(values.support_progress, 2500),
    age: validator(
      values.age,
      {
        type: "decimalPointWithMessage",
        message: "半角数字を入力してください"
      },
      "decimalPointByEveryHalfMinutes"
    ),
    postal_code: validator(values.postal_code, "postalCode"),
    tel: validator(values.tel, "naturalNumber"),
    fax: validator(values.fax, "naturalNumber"),
    family_structure: validator(values.family_structure, lengthRule(2500)),
    social_relationship: validator(
      values.social_relationship,
      lengthRule(2500)
    ),
    life_history: validator(values.life_history, lengthRule(2500)),
    medical_situation: validator(values.medical_situation, lengthRule(2500)),
    self_complaint: validator(values.self_complaint, lengthRule(2500)),
    family_complaint: validator(values.family_complaint, lengthRule(2500)),
    public_support: {
      name: validator(values.public_support.name, lengthRule(2500)),
      offer_name: validator(values.public_support.offer_name, lengthRule(2500)),
      support_contents: validator(
        values.public_support.support_contents,
        lengthRule(2500)
      ),
      frequency: validator(values.public_support.frequency, lengthRule(2500)),
      memo: validator(values.public_support.memo, lengthRule(2500))
    },
    other_support: {
      name: validator(values.other_support.name, lengthRule(2500)),
      offer_name: validator(values.other_support.offer_name, lengthRule(2500)),
      support_contents: validator(
        values.other_support.support_contents,
        lengthRule(2500)
      ),
      frequency: validator(values.other_support.frequency, lengthRule(2500)),
      memo: validator(values.other_support.memo, lengthRule(2500))
    },
    schedule: schedulesData(values.schedule),
    main_activity: validator(values.main_activity, lengthRule(2500)),
    non_weekly_service: validator(values.non_weekly_service, lengthRule(2500))
  };
};
