import { GetOptionalCustomInfoResponse } from "@api/requests/units/getOptionalCustomInfo";

/**
 * ActionNames
 */
export const FETCH_OPTIONAL_CUSTOM_INFO_STARTED =
  "UNITS/FETCH_OPTIONAL_CUSTOM_INFO_STARTED";
export const FETCH_OPTIONAL_CUSTOM_INFO_SUCCESS =
  "UNITS/FETCH_OPTIONAL_CUSTOM_INFO_SUCCESS";
export const FETCH_OPTIONAL_CUSTOM_INFO_FAILED =
  "UNITS/FETCH_OPTIONAL_CUSTOM_INFO_FAILED";
export const POST_USE_COMMON_OPERATION_RECORD_STARTED =
  "UNITS/POST_USE_COMMON_OPERATION_RECORD_STARTED";
export const POST_USE_COMMON_OPERATION_RECORD_SUCCESS =
  "UNITS/POST_USE_COMMON_OPERATION_RECORD_SUCCESS";
export const POST_USE_COMMON_OPERATION_RECORD_FAILED =
  "UNITS/POST_USE_COMMON_OPERATION_RECORD_FAILED";
export const POST_USE_UNITS_OPERATION_RECORD_STARTED =
  "UNITS/POST_USE_UNITS_OPERATION_RECORD_STARTED";
export const POST_USE_UNITS_OPERATION_RECORD_SUCCESS =
  "UNITS/POST_USE_UNITS_OPERATION_RECORD_SUCCESS";
export const POST_USE_UNITS_OPERATION_RECORD_FAILED =
  "UNITS/POST_USE_UNITS_OPERATION_RECORD_FAILED";

/**
 * State
 */
export type UnitsState = {
  optionalCustomInfo: GetOptionalCustomInfoResponse["data"];
};
