import * as types from "./types";
import { GetSupportRecordUserResponse } from "@api/requests/supports/TANKINYUSHO/getSupportRecordUser";

export const fetchSupportRecordUserStarted = () =>
  ({ type: types.FETCH_SUPPORT_RECORD_USER_STARTED } as const);
export const fetchSupportRecordUserSuccess = (
  res: GetSupportRecordUserResponse["data"]
) => ({ type: types.FETCH_SUPPORT_RECORD_USER_SUCCESS, payload: res } as const);
export const fetchSupportRecordUserFailed = (err: unknown) =>
  ({ type: types.FETCH_SUPPORT_RECORD_USER_FAILED, error: err } as const);
export const postSupportRecordUserStarted = () =>
  ({ type: types.POST_SUPPORT_RECORD_USER_STARTED } as const);
export const postSupportRecordUserSuccess = () =>
  ({ type: types.POST_SUPPORT_RECORD_USER_SUCCESS } as const);
export const postSupportRecordUserFailed = (err: unknown) =>
  ({ type: types.POST_SUPPORT_RECORD_USER_FAILED, error: err } as const);
export const unsetSupportRecordUser = () =>
  ({ type: types.UNSET_SUPPORT_RECORD_USER } as const);

export type ActionTypes =
  | ReturnType<typeof fetchSupportRecordUserStarted>
  | ReturnType<typeof fetchSupportRecordUserSuccess>
  | ReturnType<typeof fetchSupportRecordUserFailed>
  | ReturnType<typeof postSupportRecordUserStarted>
  | ReturnType<typeof postSupportRecordUserSuccess>
  | ReturnType<typeof postSupportRecordUserFailed>
  | ReturnType<typeof unsetSupportRecordUser>;
