import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { PATTERN_LIST, PatternListType } from "@constants/mgr/Cseg/variables";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import uniq from "lodash-es/uniq";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      backgroundColor: "#fafafa",
      border: "solid 1px #bdbdbd",
      padding: "20px 16px 0"
    },
    title: {
      fontSize: 14,
      letterSpacing: 0.1,
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 10
    },
    kanaButton: {
      height: 30,
      borderRadius: 18,
      minWidth: 0,
      minHeight: 30,
      marginRight: 10,
      "& span": {
        fontSize: 12
      },
      "&:disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "#fff"
      }
    },
    checkedKanaButton: {
      backgroundColor: "#455a64",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#455a64"
      }
    },
    uncheckedKanaButton: {
      backgroundColor: "#fff",
      color: "#37474f",
      "&:hover": {
        backgroundColor: "#fff"
      }
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between"
    },
    checkBoxWrapper: {
      display: "flex",
      "& > :first-child": {
        margin: "0"
      },
      "& > :last-child": {
        margin: "0 0 0 8px"
      }
    },
    searchButtonWrapper: {
      display: "flex",
      alignItems: "center",
      "& > *": {
        width: 168,
        minHeight: 30
      }
    },
    clearButton: {
      display: "flex",
      padding: "3px 8px",
      marginRight: 8
    }
  });

type UserInFacility =
  | UsersInFacilityStateKEIKAKUSODAN
  | UsersInFacilityStateCHIIKITEICHAKU
  | UsersInFacilityStateCHIIKIIKO;

type OwnProps = {
  users: UserInFacility["users"];
  handleSearchUsers: (keyword: string | undefined, isRecord: number) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SearchUsersFormCore = (props: Props): JSX.Element => {
  const { classes, users, handleSearchUsers } = props;

  const checkBoxOptions = {
    isRecorded: "isRecorded",
    isNotRecorded: "isNotRecorded"
  } as const;

  const [searchPatterns, setSearchPatterns] = React.useState(PATTERN_LIST);
  const [isRecorded, setIsRecorded] = React.useState(false);
  const [isNotRecorded, setIsNotRecorded] = React.useState(false);

  const onClickKanaButton = React.useCallback(
    (option: PatternListType): void => {
      const currentPatterns = searchPatterns.filter((p) => p.id !== option.id);
      const updatePatterns = [
        ...currentPatterns,
        { ...option, checked: !option.checked }
      ].sort((a, b) => a.id - b.id);
      setSearchPatterns(updatePatterns);
    },
    [searchPatterns]
  );

  const onClickCheckbox = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name, checked } = event.target;
      switch (name) {
        case checkBoxOptions.isRecorded:
          setIsRecorded(checked);
          break;
        case checkBoxOptions.isNotRecorded:
          setIsNotRecorded(checked);
          break;
        default:
      }
    },
    []
  );

  const onClickClear = React.useCallback((): void => {
    const resetPatterns = searchPatterns.map((v) => {
      return { ...v, checked: false };
    });
    setSearchPatterns(resetPatterns);
    setIsRecorded(false);
    setIsNotRecorded(false);
  }, [searchPatterns]);

  const onClickSearch = (): void => {
    let isRecord = 0;
    if (isRecorded && !isNotRecorded) {
      isRecord = 2;
    } else if (!isRecorded && isNotRecorded) {
      isRecord = 1;
    }

    const checkedKeyword = searchPatterns
      .filter((v) => v.checked && !v.disabled)
      .map((v) => v.alphabet);

    const keyword =
      checkedKeyword.length !== 0 ? checkedKeyword.join(",") : undefined;

    handleSearchUsers(keyword, isRecord);
  };

  const findEnabledPatternIds = React.useCallback(
    (userState: UserInFacility["users"]): number[] => {
      return userState.reduce((acc, user) => {
        const enabledPattern = PATTERN_LIST.find((pattern) =>
          new RegExp(`^${pattern.targetKana}.*$`).test(user.kanaName)
        );
        return enabledPattern ? [...acc, enabledPattern.id] : [...acc];
      }, [] as number[]);
    },
    []
  );

  React.useEffect(() => {
    if (!!users && users.length !== 0) {
      const enabledPatternUniqIds = uniq(findEnabledPatternIds(users));
      const updatePatterns = PATTERN_LIST.map((pattern) => {
        const isEnableId = enabledPatternUniqIds.includes(pattern.id);
        return {
          ...pattern,
          disabled: !isEnableId
        };
      });
      setSearchPatterns(updatePatterns);
      setIsRecorded(false);
      setIsNotRecorded(false);
    }
  }, [users]);

  return (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.title}>表示の絞り込み（複数選択可）</div>
      {searchPatterns.map((option) => {
        return (
          <KnowbeButton
            key={option.id}
            variant="outlined"
            disabled={option.disabled}
            disableRipple
            className={`${classes.kanaButton} ${
              option.checked
                ? classes.checkedKanaButton
                : classes.uncheckedKanaButton
            }`}
            onClick={(): void => onClickKanaButton(option)}
          >
            {option.viewKana}
          </KnowbeButton>
        );
      })}
      <div className={classes.buttons}>
        <div className={classes.checkBoxWrapper}>
          <MuiCheckbox
            label="記録済"
            name={checkBoxOptions.isRecorded}
            checked={isRecorded}
            onChange={onClickCheckbox}
          />
          <MuiCheckbox
            label="未記録"
            name={checkBoxOptions.isNotRecorded}
            checked={isNotRecorded}
            onChange={onClickCheckbox}
          />
        </div>
        <div className={classes.searchButtonWrapper}>
          <KnowbeButton
            className={classes.clearButton}
            kind="iconText"
            onClick={onClickClear}
          >
            <RefreshIcon style={{ marginRight: 3 }} />
            条件をリセット
          </KnowbeButton>
          <KnowbeButton kind="outlineWhite" onClick={onClickSearch}>
            この条件で絞り込む
          </KnowbeButton>
        </div>
      </div>
    </Paper>
  );
};

export const SearchUsersForm = withStyles(styles)(SearchUsersFormCore);
