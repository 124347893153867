import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import { SupportRecordUserState } from "@stores/domain/supportRecordUser/types";
import { FieldItem } from "@interfaces/ui/form";
import * as format from "date-fns/format";
import * as jaLocale from "date-fns/locale/ja";
import { FormikProps } from "formik";
import { RecordUserDetailValues } from "@initialize/mgr/SHUROTEICHAKU/record/userDetail/initialValues";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import { SUPPORT_METHOD_ITEMS } from "@constants/mgr/SHUROTEICHAKU/variables";
import { selectDateValueToDate, dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

const styles = (): StyleRules =>
  createStyles({
    root: {
      paddingBottom: "12px"
    },
    cell: {
      padding: "20px 0px 10px 0",
      verticalAlign: "top",
      "&:last-child": {
        paddingLeft: 32,
        paddingRight: 0
      }
    },
    hideBottomBorder: {
      padding: "20px 0px",
      verticalAlign: "top",
      "&:last-child": {
        paddingLeft: 32
      },
      borderBottom: "none"
    },
    upper_left: {
      marginBottom: 16
    },
    serviceBox: {
      fontSize: 12,
      marginBottom: 8,
      whiteSpace: "pre-line",
      color: "rgba(0, 0, 0, 0.6)",
      width: 90
    },
    listWrapper: {
      display: "flex",
      flexDirection: "column",
      listStyle: "none",
      padding: 0,
      margin: 0
    },
    editIcon: {
      marginLeft: 20,
      cursor: "pointer"
    },
    cancelButton: {
      borderColor: "#ccc"
    },
    saveButton: {
      boxShadow: "none"
    },
    button: {
      width: 120,
      marginBottom: 32,
      "&:first-child": {
        marginRight: 8
      }
    },
    buttonCell: {
      textAlign: "right"
    },
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    sectionWrapper: {
      "& > :not(:last-child)": {
        marginBottom: 24
      }
    }
  });

type SupportRecord = Exclude<SupportRecordUserState["support"], null>[0];

type BaseProps = WithStyles<typeof styles> & {
  support: SupportRecord;
  staffOptions: FieldItem[];
  formikFieldNamePrefix: string;
  isEditing: boolean;
  hiddenLabel?: boolean;
  index: number;
  initialValues: RecordUserDetailValues["support"][0];
};
type AllEditPattern = BaseProps & {
  editType: "all";
  displayType: "name" | "date";
  formikProps: FormikProps<RecordUserDetailValues>;
};
type Props = AllEditPattern;

/**
 * 支援記録のテーブル
 */
const SupportRecordTableRow = (props: Props): JSX.Element => {
  const {
    initialValues,
    support: { usage_results }
  } = props;

  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");

  React.useEffect(() => {
    if (props.isEditing && !props.support.record.id) {
      props.formikProps.setFieldValue(
        `${props.formikFieldNamePrefix}["creation_date"]`,
        dateToSelectDateValue(currentDate)
      );
    }
  }, [props.isEditing]);

  const tableRowStyle =
    props.editType === "all" || props.isEditing
      ? props.classes.editable
      : props.classes.unEditable;

  const tableCellStyle = props.classes.cell;

  // 作成者名
  const author = props.support.record.author ? props.support.record.author : 0;
  const authorValue = getSnapOrRealName(author, "");

  // 支援の開始/終了時間表示のロジック
  let supportInTime = "--:--";
  if (usage_results.support_start_time) {
    supportInTime = convertHHMMSSToHHMM(usage_results.support_start_time);
  }
  let supportOutTime = "--:--";
  if (usage_results.support_end_time) {
    supportOutTime = convertHHMMSSToHHMM(usage_results.support_end_time);
  }
  const supportInOutTime = `${supportInTime} 〜 ${supportOutTime}`;
  return (
    <div className={props.classes.root}>
      <Table>
        <TableBody>
          <TableRow className={tableRowStyle}>
            <TableCell className={tableCellStyle} colSpan={1}>
              <div className={props.classes.upper_left}>
                {format(usage_results.target_date, "Do（dd）", {
                  locale: jaLocale
                })}
              </div>
              <div className={props.classes.serviceBox}>
                <div>
                  {usage_results.support_method === 6
                    ? usage_results.support_method_other
                    : SUPPORT_METHOD_ITEMS[usage_results.support_method].label}
                </div>
                <div>{supportInOutTime}</div>
              </div>
            </TableCell>
            <TableCell className={tableCellStyle} colSpan={9}>
              <ul className={props.classes.listWrapper}>
                <RecordSupportTableField
                  key="creation_date"
                  type="date"
                  label="作成日"
                  name={`${props.formikFieldNamePrefix}["creation_date"]`}
                  value={
                    initialValues
                      ? selectDateValueToDate(initialValues.creation_date)
                      : ""
                  }
                  isEditing={props.isEditing}
                  required={false}
                  defaultValue=""
                  placeholder=""
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="company_name"
                  type="text"
                  label={"雇用事業主\n（勤務先事業所）"}
                  name={`${props.formikFieldNamePrefix}["company_name"]`}
                  value={initialValues ? initialValues.company_name : ""}
                  defaultValue="-"
                  placeholder=""
                  isEditing={props.isEditing}
                  hiddenLabel={props.hiddenLabel}
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="todays_purpose"
                  type="text"
                  label="当日の目的"
                  name={`${props.formikFieldNamePrefix}["todays_purpose"]`}
                  value={initialValues ? initialValues.todays_purpose : ""}
                  defaultValue="-"
                  placeholder=""
                  isEditing={props.isEditing}
                  hiddenLabel={props.hiddenLabel}
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="life_style"
                  type="custom"
                  label={"生活習慣や\n日常生活の状況"}
                  isExtendsMarginBottom
                >
                  <div className={props.classes.sectionWrapper}>
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["life_current_issues"]`}
                      label="現状と課題"
                      value={
                        initialValues ? initialValues.life_current_issues : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["life_support_content"]`}
                      label="支援内容"
                      value={
                        initialValues ? initialValues.life_support_content : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                  </div>
                </RecordSupportTableField>

                <RecordSupportTableField
                  key="work_style"
                  type="custom"
                  label="職場での状況"
                  isExtendsMarginBottom
                >
                  <div className={props.classes.sectionWrapper}>
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["office_current_issues"]`}
                      label="現状と課題"
                      value={
                        initialValues ? initialValues.office_current_issues : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["office_support_content"]`}
                      label="支援内容"
                      value={
                        initialValues
                          ? initialValues.office_support_content
                          : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                  </div>
                </RecordSupportTableField>

                <RecordSupportTableField
                  key="usage_status_of_related_organizations"
                  type="text"
                  label="関係機関の利用状況"
                  name={`${props.formikFieldNamePrefix}["usage_status_of_related_organizations"]`}
                  value={
                    initialValues
                      ? initialValues.usage_status_of_related_organizations
                      : ""
                  }
                  defaultValue="-"
                  placeholder=""
                  isEditing={props.isEditing}
                  hiddenLabel={props.hiddenLabel}
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="notices"
                  type="text"
                  label="その他特記事項"
                  name={`${props.formikFieldNamePrefix}["notices"]`}
                  value={initialValues ? initialValues.notices : ""}
                  defaultValue="-"
                  placeholder=""
                  isEditing={props.isEditing}
                  hiddenLabel={props.hiddenLabel}
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="next_support_plans"
                  type="custom"
                  label="次回の支援予定"
                  isExtendsMarginBottom
                >
                  <div className={props.classes.sectionWrapper}>
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["next_support_date"]`}
                      label="時期"
                      value={
                        initialValues ? initialValues.next_support_date : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["next_support_method"]`}
                      label="場所・方法"
                      value={
                        initialValues ? initialValues.next_support_method : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                    <RecordTextField
                      name={`${props.formikFieldNamePrefix}["next_support_plans"]`}
                      label="目的"
                      value={
                        initialValues ? initialValues.next_support_plans : ""
                      }
                      defaultValue="-"
                      placeholder=""
                      isEditable={props.isEditing}
                      labelType="default" // ラベル表記方法
                    />
                  </div>
                </RecordSupportTableField>

                <RecordSupportTableField
                  key="staff_comment"
                  type="text"
                  label="職員考察"
                  name={`${props.formikFieldNamePrefix}["staff_comment"]`}
                  value={initialValues ? initialValues.staff_comment : ""}
                  defaultValue="-"
                  placeholder=""
                  isEditing={props.isEditing}
                  hiddenLabel={props.hiddenLabel}
                  isExtendsMarginBottom
                />

                <RecordSupportTableField
                  key="author"
                  type="select"
                  label="作成者"
                  name={`${props.formikFieldNamePrefix}["author"]`}
                  value={authorValue}
                  defaultValue="未設定"
                  placeholder="選択してください"
                  isEditing={props.isEditing}
                  options={props.staffOptions}
                  hiddenLabel={props.hiddenLabel}
                  isSelectablePlaceholder
                  emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                  isExtendsMarginBottom
                />
              </ul>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default withStyles(styles)(SupportRecordTableRow);
