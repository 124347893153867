import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import {
  PRINT_HORIZONTAL_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_HORIZONTAL_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import { BasicInfoPrintSchedules } from "./BasicInfoPrintSchedules";
import {
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_HORIZONTAL_PAGE_HEIGHT,
      width: PRINT_HORIZONTAL_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        minHeight: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "-webkit-print-color-adjust": "exact",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start",
      gap: "16px",
      marginTop: 20
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      minHeight: 19,
      fontSize: 10,
      lineHeight: 1.4,
      color: "#212121",
      paddingBottom: 5,
      borderBottom: "solid 1px #000"
    },
    W288: {
      width: 288
    },
    W136: {
      width: 136
    }
  });

type OwnProps = {
  user: UsersInFacilityState["user"];
  facility: FacilityState;
  basicInfo: BasicInfoState["basicInfo"]["basic_info"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

// 現在の生活（基本情報）印刷プレビュー
const BasicInfoOfLifePrintContentCore = (props: Props): JSX.Element => {
  const { classes, user, facility, basicInfo } = props;

  // 作成者名
  const authorName = basicInfo.author
    ? getSnapOrRealName(basicInfo.author, "")
    : "";

  const userInFacility = user.user_in_facility;

  let officeName = "";
  const facilityKS = findFacilityKeikakuSodan(facility);
  const facilitySS = findFacilityShogaijisodan(facility);
  if (
    facilityKS &&
    basicInfo.type_consultation === TYPE_CONSULTATION_KEIKAKUSODAN
  ) {
    officeName = facilityKS.name;
  } else if (
    facilitySS &&
    basicInfo.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN
  ) {
    officeName = facilitySS.name;
  }

  // 障害支援区分
  const classifyDisabilitySupport = (): string => {
    if (basicInfo.classify_disability_support === 0) {
      return "なし";
    }
    return DISABILITY_CLASS_LIST.filter(
      (f) => f.value === `${basicInfo.classify_disability_support}`
    ).length !== 0
      ? DISABILITY_CLASS_LIST.filter(
          (f) => f.value === `${basicInfo.classify_disability_support}`
        )[0].label
      : "";
  };

  if (user.user_in_facility.name_sei === undefined) {
    return <></>;
  }

  return (
    <div className={classes.page}>
      {/* タイトル */}
      <div className={classes.pageTitle}>
        <h2 className={classes.pageTitleTextB}>現在の生活（基本情報）</h2>
      </div>
      {/* 基本情報 */}
      <div>
        <div className={classes.userInfo}>
          <div className={classes.W288}>
            <span className={classes.userInfoItem}>利用者氏名（フリガナ）</span>
            <div className={classes.userInfoValue}>
              <span>{`${userInFacility.name_sei} ${userInFacility.name_mei}（${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`}</span>
            </div>
          </div>
          <div className={classes.W136}>
            <span className={classes.userInfoItem}>障害支援区分</span>
            <div className={classes.userInfoValue}>
              <span>{classifyDisabilitySupport()}</span>
            </div>
          </div>
          <div className={classes.W288}>
            <span className={classes.userInfoItem}>相談支援事業者名</span>
            <div className={classes.userInfoValue}>
              <span>{officeName}</span>
            </div>
          </div>
          <div className={classes.W288}>
            <span className={classes.userInfoItem}>計画作成担当者</span>
            <div className={classes.userInfoValue}>
              <span>{authorName}</span>
            </div>
          </div>
        </div>
      </div>
      {/* 計画予定書 */}
      <BasicInfoPrintSchedules basicInfo={basicInfo} />
    </div>
  );
};

export const BasicInfoOfLifePrintContent = withStyles(styles)(
  BasicInfoOfLifePrintContentCore
);
