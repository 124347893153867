import { RadioItemInterface } from "@components/atoms/RadioButtons";
import {
  FACILITY_202104,
  FACILITY,
  REPORT_DAILY_202104,
  REPORT_DAILY,
  REPORT_MONTHLY_202104,
  USERS_ID,
  USERS_ID_202104,
  REPORT_USERS
} from "@constants/url";
import {
  INVOICE_VERSION_CURRENT,
  INVOICE_VERSION_V202104,
  InvoiceVersionUrl
} from "@constants/variables";

// 特定事業所
export enum SpecificOfficeType {
  "なし" = 0,
  "特定事業所加算（Ⅰ）", // 1
  "特定事業所加算（Ⅱ）", // 2
  "特定事業所加算（Ⅲ）", // 3
  "特定事業所加算（Ⅳ）" // 4
}

// 福祉・介護職員等特定処遇改善加算
export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE = {
  NONE: { label: "なし", value: "1", disabled: false },
  LEVEL1: { label: "特定処遇改善加算（Ⅰ）", value: "2", disabled: false },
  LEVEL2: { label: "特定処遇改善加算（Ⅱ）", value: "3", disabled: false }
};

export const STANDARD_TIME_VALUE = 0.5;
export const MAX_STANDARD_TIME_VALUE = 7.5;
export const MAX_TIME_VALUE = 8;

export const KODOENGO_ACTION_CLASS_LIST = {
  NONE: { label: "空き", value: "1" }
};

export const KODOENGO_MEMBER_LIST = {
  ONE: { label: "1人", value: "1" },
  TWO: { label: "2人", value: "2" }
};

export const KODOENGO_MEMBER_SELECT_LIST = [
  KODOENGO_MEMBER_LIST.ONE,
  KODOENGO_MEMBER_LIST.TWO
];

export const KODOENGO_INPUT_CLASS_LIST = {
  PLAN: { label: "行動援護計画", value: "1" },
  RESULT: {
    label: "サービス提供実績",
    value: "2"
  },
  CREATE: {
    label: "行動援護計画/サービス提供実績",
    value: "0"
  }
};

export const KODOENGO_INPUT_CLASS_SELECT_LIST = [
  KODOENGO_INPUT_CLASS_LIST.PLAN,
  KODOENGO_INPUT_CLASS_LIST.RESULT
];

export const PROCESS_TYPE = {
  ALL: 1,
  ALL_ERROR: 2,
  NEW_ONLY: 3
};

export const STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE_LIST = [
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.NONE,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1,
  STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL2
];

export const INOUT_RESULTS_COPY_ERROR_STATUS = 17;

export const INOUT_RESULTS_ERROR_DUPLICATED = {
  STATUS: 417,
  MESSAGE: "results_time_duplicated"
};

export const KODOENGO_DAY_OF_WEEK_LIST = [
  { label: "月", value: "1" },
  { label: "火", value: "2" },
  { label: "水", value: "3" },
  { label: "木", value: "4" },
  { label: "金", value: "5" },
  { label: "土", value: "6" },
  { label: "日", value: "7" }
];

export const KODOENGO_DISPATCH_NO_LIST = [
  { label: "①", value: "1" },
  { label: "②", value: "2" }
];

export const REPORT_TABS_INFO_PARAM = {
  DAILY: "0",
  USERS: "1"
};

export const REPORT_TABS_INFO_LiST = [
  { label: "日ごと", value: REPORT_TABS_INFO_PARAM.DAILY },
  { label: "月ごと", value: REPORT_TABS_INFO_PARAM.USERS }
];

export const REPORT_FILTER_LIST = {
  NONE: { label: "すべての利用者", value: "1" },
  FILTER: { label: "計画または実績あり", value: "2" }
};

export const REPORT_FILTER_SELECT_LIST = [
  REPORT_FILTER_LIST.NONE,
  REPORT_FILTER_LIST.FILTER
];

export const IN_TIME_VALIDATION_LIST = [
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の行動援護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "サービス提供終了日を超えて実績が入力されています。利用実績、もしくは利用者情報を修正してください",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス予定として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別の行動援護計画として入力してください。",
  "1人目と2人目のサービス提供時間は重複する必要があります。重複しない場合は、別のサービス提供実績として入力してください。"
];

export const DETAIL_IN_TIME_VALIDATION_LIST = [
  "空き時間は提供時間の範囲内で入力してください",
  "空き時間は同一時間帯で重複しないように入力してください"
];

export const DETAIL_OUT_TIME_VALIDATION_LIST = [
  "終了時間は開始時間より後の時間を入力してください",
  "空き時間は提供時間の範囲内で入力してください",
  "空き時間は同一時間帯で重複しないように入力してください"
];

export const KODOENGO_PLAN_LIST_TAB_PATH = {
  ASSESSMENT: "assessment",
  CAREPLAN: "care_plan",
  SUPPORT_PLAN_SHEETS: "support_plan_sheets",
  SUPPORT_PROCEDURE: "support_procedure"
} as const;

export const SUPPORT_PROCEDURE_DATE_SETTING_MODAL_RADIO_BUTTONS: RadioItemInterface[] = [
  { label: "一覧から日時を選択して設定する", value: "1" },
  { label: "日時を設定する", value: "2" }
];

export const SUPPORT_PROCEDURE_MODAL_VALIDATION_MESSAGE =
  "サービス提供期間内の日付を選択してください";

// 操作対象のAPI値
export const TARGET_FLG = {
  new: 1, // 新規
  edit: 2, // 更新
  copy: 3, // コピー
  setDate: 4 // 日時設定
} as const;

// 操作対象のパス
export const TARGET_PATH = {
  new: "new",
  edit: "edit",
  copy: "copy",
  setDate: "setDate"
} as const;

// custom_recordのdefault_itemの対応表（サービス提供記録）
export const SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO = {
  cost: 1,
  deposit: 2,
  vital: 3,
  user_state: 4,
  service_overview: 5,
  other_service: 6,
  staff_comment: 7,
  exit_confirmation: 8,
  other: 9
};

export const KODOENGO_DISPLAY_STATUS_LIST_RECORD = [
  { label: "-", value: "1" },
  { label: "行動援護", value: "2" }
];

// 支援手順書兼記録用紙の手順詳細チェック
export const KODOENGO_SUPPORT_PROCEDURE_FORM_DETAILS_CHECK_PRINT = [
  { label: "-", value: 0 },
  { label: "◯", value: 1 },
  { label: "△", value: 2 },
  { label: "×", value: 3 }
];

// サービス内容(記録画面詳細用)
export const KODOENGO_DISPLAY_STATUS_LIST_DETAIL = [];

// 印刷プレビューで表示する自事業所と他事業所の最大個数分の丸囲み文字
export const CIRCLE_NUMBERS = [
  "①",
  "②",
  "③",
  "④",
  "⑤",
  "⑥",
  "⑦",
  "⑧",
  "⑨",
  "⑩",
  "⑪",
  "⑫",
  "⑬",
  "⑭",
  "⑮",
  "⑯",
  "⑰",
  "⑱",
  "⑲",
  "⑳",
  "㉑",
  "㉒"
];

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportMonthly";
/** 行動援護の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportMonthly: [
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_MONTHLY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_USERS
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    }
  ]
};
