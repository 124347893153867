import * as React from "react";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { StaffState } from "@stores/domain/staff/types";
import { AppState } from "@stores/type";
import { Form, Formik, FormikActions } from "formik";
import { connect } from "react-redux";
import { SupportState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import { DailySupportTableRow } from "@components/organisms/mgr/Cseg/record/operationAndSupports/DailySupportTableRow";
import { UsersInFacilityState as UsersInFacilityStateKEIKAKUSODAN } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKITEICHAKU } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateCHIIKIIKO } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import {
  initialSupportsValues,
  SupportRecordFormValues
} from "@initialize/mgr/Cseg/record/dailyRecord/initialValues";
import { supportValidation } from "@initialize/mgr/Cseg/record/dailyRecord/validation";
import { EditType } from "@components/pages/mgr/Cseg/record/operationAndSupports/DailyRecord";
import { toEffectiveObject } from "@utils/object";
import deepEqual from "fast-deep-equal";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import format from "date-fns/format";
import { SnackbarParams } from "@stores/ui/type";
import { FacilityType } from "@constants/variables";

type UserInFacility =
  | UsersInFacilityStateKEIKAKUSODAN
  | UsersInFacilityStateCHIIKITEICHAKU
  | UsersInFacilityStateCHIIKIIKO;

type OwnProps = {
  supportList: SupportState;
  users: UserInFacility["users"];
  targetDate: string;
  isEditing: boolean;
  editType: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  setEditType: React.Dispatch<React.SetStateAction<EditType>>;
  update: () => void;
};

type StateProps = {
  facilityType: FacilityType;
  facilitySubType: number | null;
  staffs: StaffState;
};

type DispatchProps = {
  stopHistory: (flag: boolean) => void;
  postSupport: (
    uifId: number,
    targetDate: string,
    params: SupportRecordFormValues,
    initialValues: SupportRecordFormValues
  ) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const DailySupportTableCore = (props: Props): JSX.Element => {
  const {
    supportList,
    users,
    targetDate,
    staffs,
    setIsEditing,
    setEditType,
    postSupport,
    stopHistory,
    update
  } = props;
  const [targetUifId, setTargetUifId] = React.useState(0);
  // formの初期値
  const formValues = initialSupportsValues(supportList, targetDate);

  const staffOptions = generateSelectFieldItems(
    staffs.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  const onClickEdit = (e: React.MouseEvent, uifId: number): void => {
    setTargetUifId(uifId);
    setIsEditing(true);
    setEditType("support");
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const confirmDiscardFormChanges = (
    nextValues: SupportRecordFormValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    stopHistory(hasChange);
  };

  const validate = (values: SupportRecordFormValues): void | object => {
    const validationResult = supportValidation(values);
    const error = toEffectiveObject(validationResult);
    confirmDiscardFormChanges(values);
    return error;
  };

  const onSubmit = async (
    values: SupportRecordFormValues,
    actions: FormikActions<SupportRecordFormValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postSupport(
      targetUifId,
      format(targetDate, "YYYY-MM-DD"),
      values,
      formValues
    )
      .then(() => {
        stopHistory(false);
        update();
      })
      .finally(() => {
        actions.setSubmitting(false);
        setIsEditing(false);
        setEditType("");
        setTargetUifId(0);
      });
  };

  return (
    <div>
      <Formik
        initialValues={formValues}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => {
          const onClickEditCancel = (): void => {
            setIsEditing(false);
            setEditType("");
            setTargetUifId(0);
            formikProps.setValues(formValues);
            stopHistory(false);
          };
          return (
            <Form>
              {supportList.map((record, index) => {
                const displayUser = users.find(
                  (v) => v.uif_id === record.users_in_facility_id
                );
                const displayName = displayUser ? displayUser.displayName : "";
                const key = `${displayName}-${index}`;
                return (
                  <DailySupportTableRow
                    key={key}
                    staffOptions={staffOptions}
                    formikNamePrefix={`${index}`}
                    supportRecord={record}
                    displayName={displayName}
                    formikProps={formikProps}
                    isEditing={props.isEditing}
                    isTarget={targetUifId === record.users_in_facility_id}
                    onClickEdit={onClickEdit}
                    onClickEditCancel={onClickEditCancel}
                    submitError={submitError}
                    facilityType={props.facilityType}
                    facilitySubType={props.facilitySubType}
                  />
                );
              })}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facilityType: state.user.facility_type,
  facilitySubType: state.KEIKAKUSODAN.facility.facilityType,
  staffs: state.staff
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, Cseg } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  return {
    stopHistory: uiDispatches.stopHistory,
    postSupport: (uifId, targetDate, params, initialValues): Promise<void> => {
      return Cseg.operationsAndSupportsDispatcher(dispatch).postSupport(
        uifId,
        targetDate,
        params,
        initialValues
      );
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params)
  };
};

export const DailySupportTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailySupportTableCore);
