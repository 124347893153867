import validator, { dateValidator, Rule } from "@validator";
import {
  CommonItem,
  BasicErrors,
  RecordCarePlan,
  SchedulesErrors,
  SupportCarePlanSchedules
} from "@interfaces/record/carePlan/carePlan";
import {
  FacilityType,
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM
} from "@constants/variables";
import { isBefore } from "date-fns";
import { isEmpty } from "lodash-es";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

const lengthRule = (length: number): Rule => ({
  type: "checkCharacterLength",
  length
});
const schedulesData = (
  values: SupportCarePlanSchedules,
  facilityType: FacilityType
): SchedulesErrors => {
  const infoValue = values.support_care_plan_basic_info.map((i) => {
    const nextDayFlg =
      i.end_time_next_day_flg ===
      CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG_ITEM.NEXT_DAY.value;
    const endTimeValidation = nextDayFlg
      ? validator(i.end_time || "", "checkTime")
      : validator(i.end_time || "", "checkTime", {
          type: "checkTimeFuture",
          startTime: i.start_time,
          options: { firstLabel: "終了時間", secondLabel: "開始時間" }
        });
    const endTimeNextDayFlg = (): boolean => {
      if (isEmpty(i.end_time) || isEmpty(i.start_time)) {
        return false;
      }
      if (i.end_time === i.start_time) {
        return true;
      }
      return isBefore(
        new Date(
          nextDayFlg ? `2020-01-02 ${i.end_time}` : `2020-01-01 ${i.end_time}`
        ),
        new Date(`2020-01-01 ${i.start_time}`)
      );
    };
    const res = {
      start_time: validator(i.start_time || "", "checkTime"),
      end_time: endTimeValidation,
      number_of_rides: validator(`${i.number_of_rides}`, "naturalNumber", {
        type: "upperLimit",
        upperLimit: 9
      })
    };
    return endTimeNextDayFlg() ? { ...res, end_time_next_day_flg: " " } : res;
  });
  const detailsValue = values.support_care_plan_details.map((i) => {
    return {
      point: validator(i.point, lengthRule(2500)),
      to_do: validator(i.to_do, lengthRule(2500))
    };
  });

  const defaultList = {
    support_care_plan_basic_info: infoValue,
    support_care_plan_details: detailsValue
  };
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      return defaultList;
    case FacilityType.JUDOHOMONKAIGO:
    case FacilityType.DOKOENGO:
    case FacilityType.KODOENGO:
      return {
        status_text: validator(values.status_text, "required"),
        ...defaultList
      };
    default:
      return defaultList;
  }
};
const conmmonData = (values: CommonItem): BasicErrors => {
  return {
    target_date: dateValidator(
      notSelectedToEmpty(values.target_date),
      "required"
    ),
    postal_code: validator(values.postal_code, "postalCode"),
    tel: validator(values.tel, "naturalNumber"),
    date_pay_end: dateValidator(values.date_pay_end, {
      type: "future",
      startDate: values.date_pay_begin
    }),
    pay_months_agreed_physical_care: validator(
      values.pay_months_agreed_physical_care,
      {
        type: "decimalPointWithMessage",
        message: "半角数字を入力してください"
      },
      "decimalPointByEveryHalfMinutes"
    ),
    pay_months_agreed_outpatient_care_with_physical_care: validator(
      values.pay_months_agreed_outpatient_care_with_physical_care,
      {
        type: "decimalPointWithMessage",
        message: "半角数字を入力してください"
      },
      "decimalPointByEveryHalfMinutes"
    ),
    pay_months_agreed_housework_assistance: validator(
      values.pay_months_agreed_housework_assistance,
      {
        type: "decimalPointWithMessage",
        message: "半角数字を入力してください"
      },
      "decimalPointByEveryQuarterMinutesAfter30Minutes"
    ),
    pay_months_agreed_outpatient_care: validator(
      values.pay_months_agreed_outpatient_care,
      {
        type: "decimalPointWithMessage",
        message: "半角数字を入力してください"
      },
      "decimalPointByEveryHalfMinutes"
    ),
    pay_months_agreed_getting_on_and_off: validator(
      values.pay_months_agreed_getting_on_and_off,
      "naturalNumber",
      { type: "upperLimit", upperLimit: 999 }
    ),
    housework_assistance_base_type_comment: validator(
      values.housework_assistance_base_type_comment,
      lengthRule(2500)
    ),
    usage_other_facility: validator(
      values.usage_other_facility,
      lengthRule(2500)
    ),
    user_request: validator(values.user_request, lengthRule(2500)),
    user_issue: validator(values.user_issue, lengthRule(2500)),
    goals_direction_assistance: validator(
      values.goals_direction_assistance,
      lengthRule(2500)
    ),
    remarks: validator(values.remarks, lengthRule(2500)),
    staff_comment: validator(values.staff_comment, lengthRule(2500)),
    support_care_plans_judohomonkaigo: {
      pay_months_agreed_severe_home_visit_care: validator(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_severe_home_visit_care,
        {
          type: "decimalPointWithMessage",
          message: "半角数字を入力してください"
        },
        "decimalPointByEveryHalfMinutes"
      ),
      pay_months_agreed_guide_severe_home_visit_care: validator(
        values.support_care_plans_judohomonkaigo
          .pay_months_agreed_guide_severe_home_visit_care,
        {
          type: "decimalPointWithMessage",
          message: "半角数字を入力してください"
        },
        "decimalPointByEveryHalfMinutes"
      )
    },
    support_care_plans_dokoengo: {
      pay_months_agreed_accompanying_support: validator(
        values.support_care_plans_dokoengo
          .pay_months_agreed_accompanying_support,
        {
          type: "decimalPointWithMessage",
          message: "半角数字を入力してください"
        },
        "decimalPointByEveryHalfMinutes"
      )
    },
    support_care_plans_kodoengo: {
      pay_months_agreed_accompanying_support: validator(
        values.support_care_plans_kodoengo
          .pay_months_agreed_accompanying_support,
        {
          type: "decimalPointWithMessage",
          message: "半角数字を入力してください"
        },
        "decimalPointByEveryHalfMinutes"
      )
    }
  };
};

export const newValidation = (
  values: RecordCarePlan,
  facilityType: FacilityType
): BasicErrors => {
  const schedulesValue = values.support_care_plan_schedules.map((m) => {
    return schedulesData(m, facilityType);
  });
  const conmmonValue = conmmonData(values);
  return {
    ...conmmonValue,
    support_care_plan_schedules: schedulesValue
  };
};
export const editValidation = (
  values: RecordCarePlan,
  facilityType: FacilityType
): BasicErrors => {
  const schedulesValue = values.support_care_plan_schedules.map((m) => {
    return schedulesData(m, facilityType);
  });
  const conmmonValue = conmmonData(values);
  return {
    ...conmmonValue,
    support_care_plan_schedules: schedulesValue
  };
};
export const schedulesValidation = (
  values: SupportCarePlanSchedules,
  facilityType: FacilityType
): SchedulesErrors => {
  return schedulesData(values, facilityType);
};
