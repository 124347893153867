import * as React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate201910";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { UsageResultsState } from "@stores/v201910/domain/mgr/SHUROTEICHAKU/report/types";
import { ErrorsState } from "@stores/domain/errors/types";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { OptionInterface } from "@components/atoms/DropDown";
import UsageResultListHeader from "@components/v201910/organisms/mgr/SHUROTEICHAKU/report/UsageResultListHeader";
import UsageResultListTable from "@components/v201910/organisms/mgr/SHUROTEICHAKU/report/UsageResultListTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import UsageResultListHeaderEdit from "@components/v201910/organisms/mgr/SHUROTEICHAKU/report/UsageResultListHeaderEdit";
import { Formik, Form, FormikActions } from "formik";
import { InitialDataValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/report/initialData";
import initialValues from "@initialize/v201910/mgr/SHUROTEICHAKU/report/initialValues";
import reportValidation from "@initialize/v201910/mgr/SHUROTEICHAKU/report/validation";
import { toEffectiveObject } from "@utils/object";
import { dateInYYYYMMFormat } from "@utils/date";
import isEqual from "lodash-es/isEqual";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    clear: {
      clear: "both"
    },
    headerWrapper: {
      position: "sticky",
      top: NOTICE_HEADER_HEIGHT,
      backgroundColor: palette.background.default,
      zIndex: 10
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 16
    },
    table: {
      tableLayout: "fixed"
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    button: {
      marginLeft: 10,
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4
    },
    allStatusType: {
      marginBottom: spacing.unit * 2
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  usageResultList: UsageResultsState;
  inoutErrors: ErrorsState["inout"];
  needsStopHistory: boolean;
};

type DispatchProps = {
  fetch: (uifId: string, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  openErrorsDialog: () => void;
  post: (
    formValue: InitialDataValues,
    reportValue: UsageResultsState["usageResults"],
    id: string,
    date: string
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  unsetReport: () => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  isEditing: boolean;
  selectedMonth: Date;
  selectedUser: OptionInterface;
  headerHeight: number;
};

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2001, 0, 1);

/**
 * 利用実績（月ごと）
 */
class UsageResultList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditing: false,
      selectedMonth: this.props.initialDate,
      selectedUser: { label: "", value: "" },
      headerHeight: 0
    };
  }

  public componentDidUpdate(): void {
    const top = document.getElementById("reportMonthlyHeader");
    if (top && top.clientHeight !== this.state.headerHeight) {
      const setClientHeight = (): void => {
        this.setState({
          headerHeight: top.clientHeight
        });
      };
      setClientHeight();
    }
  }

  private onChangeMonth = (date: Date, user: OptionInterface): void => {
    this.setState({ selectedMonth: date });
    this.setState({ selectedUser: user });
    if (user.value) {
      this.props.fetch(`${user.value}`, date);
    } else {
      this.props.unsetReport();
    }
    this.props.fetchInoutError(date);
  };

  private onChangeUser = (user: OptionInterface): void => {
    this.setState({ selectedUser: user });
    this.props.fetch(`${user.value}`, this.state.selectedMonth);
    this.props.fetchInoutError(this.state.selectedMonth);
  };

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  private onSubmit = async (
    values: InitialDataValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    this.props
      .post(
        values,
        this.props.usageResultList.usageResults,
        `${this.state.selectedUser.value}`,
        dateInYYYYMMFormat(this.state.selectedMonth)
      )
      .finally(() => {
        this.props.fetchInoutError(this.state.selectedMonth);
        this.onChangeEditMode();
        actions.resetForm(initialValues(this.props.usageResultList));
        actions.setSubmitting(false);
      });
  };

  /**
   * 編集状態変更
   * 離脱ダイアログ表示の停止を行う
   */
  private onChangeEditMode = (): void => {
    if (this.state.isEditing) {
      this.props.stopHistory(false);
    }
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  // バリデーション動作時に離脱モーダルのフラグを立てる。
  private validate = (values: InitialDataValues): void | object => {
    const validationResult = reportValidation(values);
    if (!this.props.needsStopHistory) {
      this.confirmDiscardFormChanges(values);
    }
    return toEffectiveObject(validationResult);
  };

  // 差分チェック差分があれば離脱モーダルフラグをONに変更する。
  private confirmDiscardFormChanges(nextValues: InitialDataValues): void {
    const hasChange = !isEqual(
      nextValues,
      initialValues(this.props.usageResultList)
    );
    if (hasChange) {
      this.props.stopHistory(true);
    }
  }

  public render(): JSX.Element {
    const { classes, usageResultList, inoutErrors } = this.props;
    const { isEditing, selectedMonth, selectedUser, headerHeight } = this.state;

    return (
      <>
        <Formik
          initialValues={initialValues(this.props.usageResultList)}
          onSubmit={this.onSubmit}
          validate={this.validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => (
            <Form>
              <div id="reportMonthlyHeader" className={classes.headerWrapper}>
                {inoutErrors.hasError && (
                  <InvoiceErrorBar
                    message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
                    onClick={this.onClickErrorDialog}
                  />
                )}

                <div className={classes.headerInfoContainer}>
                  {isEditing ? (
                    <UsageResultListHeaderEdit
                      selectedMonth={selectedMonth}
                      selectedUserName={selectedUser.label}
                      onChangeEditMode={this.onChangeEditMode}
                      formikProps={formikProps}
                      resetForm={formikProps.resetForm}
                    />
                  ) : (
                    <UsageResultListHeader
                      minDate={minDate}
                      maxDate={maxDate}
                      selectedMonth={selectedMonth}
                      isSubmitDisabled={!usageResultList.usageResults.length}
                      selectedUserId={selectedUser.value}
                      onChangeMonth={this.onChangeMonth}
                      onChangeUser={this.onChangeUser}
                      onChangeEditMode={this.onChangeEditMode}
                      resetForm={formikProps.resetForm}
                      usageResultList={initialValues(
                        this.props.usageResultList
                      )}
                      history={this.props.history}
                      currentPageVersion={this.props.currentPageVersion}
                    />
                  )}
                </div>
              </div>

              <Paper
                elevation={0}
                className={ClassNames(classes.clear, classes.tableContainer)}
              >
                <UsageResultListTable
                  isEditing={isEditing}
                  headerHeight={headerHeight}
                />
              </Paper>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  usageResultList: state.v201910.SHUROTEICHAKU.report,
  inoutErrors: state.errors.inout,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v201910, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatches = v201910.SHUROTEICHAKU.reportDispacher(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    fetch: (uifId: string, date: Date): Promise<void> =>
      reportDispatches.fetch(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    post: (
      formValue: InitialDataValues,
      reportValue: UsageResultsState["usageResults"],
      id: string,
      date: string
    ): Promise<void> => reportDispatches.post(formValue, reportValue, id, date),
    stopHistory: uiDispatches.stopHistory,
    unsetReport: (): void => reportDispatches.unsetReport()
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsageResultList));
