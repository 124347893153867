import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  Typography
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v201910/mgr/SHISETSUNYUSHO/report/initial";
import {
  ORAL_TRANSITION,
  ORAL_PRESERVATION,
  STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT,
  STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT,
  STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT,
  StatusType,
  OralPreservation,
  OralTransition,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY,
  HospitalizationOvernightstay,
  STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS,
  STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS,
  HospitalizationDays,
  STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER,
  STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import Paper from "@material-ui/core/Paper";
import { FacilityState } from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/facility/types";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import { getDefaultHospitalizationDaysDialogFields } from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";
import { createOptionsHospitalizationOvernightStayDialog } from "@utils/domain/mgr/SHISETSUNYUSHO/report/createOptionsHospitalizationOvernightStay";

const styles = (): StyleRules =>
  createStyles({
    checkbox: {
      paddingLeft: 12,
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 16,
      height: 22.8
    },
    typographyMargin: { marginBottom: 11 },
    disabled: {
      color: "rgba(0, 0, 0, 0.38)"
    },
    foodGroup: {
      marginBottom: 30,
      marginTop: 0,
      minHeight: 24
    },
    hospitalizationDaysReadOnly: {
      marginBottom: 48,
      width: 128,
      minHeight: 52
    }
  });

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  isSevereDisabilitySupport: boolean;
  facilityState: FacilityState;
  serviceStatus?: number;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

type State = {
  isStatusTypeNone: boolean;
  isStatusDisabledHospitalization: boolean;
  isStatusDisabledHospitalizationDays: boolean;
  isStatusReadOnlyHospitalizationDays: boolean;
  isStatusDisabledRegionalMigration: boolean;
  isStatusTypeRegionalMigrationSupport: boolean;
};

type Props = StateProps & WithStyles<typeof styles>;

class UsagePerformanceReportDialogFields extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isStatusTypeNone: true,
      isStatusDisabledHospitalization: false,
      isStatusDisabledHospitalizationDays: false,
      isStatusReadOnlyHospitalizationDays: false,
      isStatusDisabledRegionalMigration: false,
      isStatusTypeRegionalMigrationSupport: false
    };
  }

  public componentDidMount(): void {
    this.setIsCommuteByStatus(
      this.props.formikPropsValues.values.initial.statusType
    );
  }

  // サービス提供実施の値が変更されたら、他項目のdisableの変更を行う
  private handleChangeStatusHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setIsCommuteByStatus(e.target.value);
  };

  // サービス提供の状況の判定
  private setIsCommuteByStatus = (status: string): void => {
    // 【-】時に他項目をdisableにする
    const isStatusTypeNone = status === StatusType.NONE.toString();
    // 特定のサービス提供の状況で「入院・外泊時加算/入院時支援特別加算」項目をdisableにし、値を「-」にする
    const isStatusDisabledHospitalization = STATUS_TYPE_DISABLED_HOSPITALIZATION_OVER_NIGHT_STAY.some(
      (item) => item === status
    );
    // 特定のサービス提供の状況で「入院・外泊日数」項目をdisableにし、値を「-」にする
    const isStatusDisabledHospitalizationDays = STATUS_TYPE_DISABLED_HOSPITALIZATION_DAYS.some(
      (item) => item === status
    );
    // 特定のサービス提供の状況で「入院・外泊日数」項目を読み取り専用にし、値を「0」にする
    const isStatusReadOnlyHospitalizationDays = STATUS_TYPE_READONLY_HOSPITALIZATION_DAYS.some(
      (item) => item === status
    );
    // サービス提供状況が「入院（初日・中日・最終日）」「外泊（初日・中日・最終日）」かどうかの判定
    const isStatusDisabledRegionalMigration = STATUS_TYPE_DISABLED_REGIONAL_MIGRATION_SUPPORT.some(
      (value) => {
        return status === value.toString();
      }
    );

    // サービス提供の状況が地域移行加算(退所後)かどうかの判定
    const isStatusTypeRegionalMigrationSupport =
      status === StatusType.REGIONAL_MIGRATION_SUPPORT.toString();

    this.setState({
      isStatusTypeNone,
      isStatusDisabledHospitalization,
      isStatusDisabledHospitalizationDays,
      isStatusReadOnlyHospitalizationDays,
      isStatusDisabledRegionalMigration,
      isStatusTypeRegionalMigrationSupport
    });

    if (isStatusDisabledHospitalization) {
      this.props.setFormikFieldValue(
        "initial.hospitalizationOvernightStay",
        HospitalizationOvernightstay.NONE
      );
    }

    // 「入院・外泊日数」の更新
    this.props.setFormikFieldValue(
      "initial.hospitalizationDays",
      getDefaultHospitalizationDaysDialogFields(
        status,
        this.props.formikPropsValues.initialValues.initial
          .hospitalizationDaysPrev
      )
    );

    // サービス提供の状況選択肢制御 地域移行加算（退所後）リセット
    if (isStatusDisabledRegionalMigration) {
      this.props.setFormikFieldValue("initial.regionalTransitionFlg", false);
    }

    // サービス提供の状況によって栄養マネジメント加算と経口移行加算の値をデフォルト値に戻す
    if (STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(status)) {
      this.props.setFormikFieldValue("initial.nutritionManagementFlg", false);
      this.props.setFormikFieldValue(
        "initial.oralTransition",
        OralTransition.NONE.toString()
      );
    }

    // 入院（最終日）・入院(最終日)→外泊(初日)・入院(最終日)→共同生活住居に戻る→外泊(初日)選択時は「入院・外泊時加算/入院時支援特別加算」項目の値を変更
    const {
      hospitalizationOvernightStay
    } = this.props.formikPropsValues.values.initial;
    if (
      STATUS_TYPE_HOSPITALIZATION_OVER_NIGHT_STAY_OPTION_ONLY_SPECIAL.includes(
        status
      ) &&
      (hospitalizationOvernightStay === HospitalizationOvernightstay.ONE ||
        hospitalizationOvernightStay === HospitalizationOvernightstay.TWO)
    ) {
      this.props.setFormikFieldValue(
        "initial.hospitalizationOvernightStay",
        HospitalizationOvernightstay.NONE
      );
    }
    // 外泊（中日）選択時は「入院・外泊時加算/入院時支援特別加算」項目の値を変更
    if (
      status === StatusType.OVERNIGHT &&
      hospitalizationOvernightStay === HospitalizationOvernightstay.SPECIAL
    ) {
      this.props.setFormikFieldValue(
        "initial.hospitalizationOvernightStay",
        HospitalizationOvernightstay.NONE
      );
    }
  };

  // リアルタイムにバリデーションを行いたい為、onChangeごとにFormikの更新を行う
  private onChangeText = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ): string | void => {
    this.props.setFormikFieldValue(event.target.name, event.target.value);
  };

  // 地域移行加算ONのとき他の加算をリセットして選択出来ないようにする
  private onChangeCheck = (): void => {
    this.props.setFormikFieldValue(
      "initial.hospitalizationOvernightStay",
      false
    );
    this.props.setFormikFieldValue("initial.collectionOfUtilityFeeFlg", false);
    this.props.setFormikFieldValue("initial.nutritionManagementFlg", false);
    this.props.setFormikFieldValue("initial.foodBreakfastFlg", false);
    this.props.setFormikFieldValue("initial.foodLunchFlg", false);
    this.props.setFormikFieldValue("initial.foodSupperFlg", false);
    this.props.setFormikFieldValue(
      "initial.oralTransition",
      OralTransition.NONE.toString()
    );
    this.props.setFormikFieldValue("initial.oralPreservation1", false);
    this.props.setFormikFieldValue("initial.oralPreservation2", false);
    this.props.setFormikFieldValue("initial.oralCareFlg", false);
    this.props.setFormikFieldValue("initial.medicalFoodsFlg", false);
    this.props.setFormikFieldValue("initial.severeDisabilitySupportFlg", false);
    this.props.setFormikFieldValue("initial.remarks", "");
  };

  public render(): JSX.Element {
    const checkBoxStyle = { marginBottom: 22, marginTop: 0, minHeight: 24 };
    const selectBoxStyle = { marginBottom: 30, minHeight: 52, minWidth: 330 };
    const foodCheckBoxStyle = { marginBottom: 0, marginTop: 0 };
    const disabledWithRegionalTransitionFlg = this.props.formikPropsValues
      .values.initial.regionalTransitionFlg;
    const { values } = this.props.formikPropsValues;

    return (
      <Paper component="section" elevation={0}>
        <FormikSelect
          name="initial.statusType"
          label="サービス提供の状況"
          options={
            this.props.serviceStatus === 1 || this.props.serviceStatus === 3
              ? STATUS_TYPE_WITHOUT_REGIONAL_MIGRATION_SUPPORT
              : STATUS_TYPE_ONLY_REGIONAL_MIGRATION_SUPPORT
          }
          onChangeHook={this.handleChangeStatusHook}
          style={{ ...selectBoxStyle, marginBottom: 22, width: "536px" }}
          required
        />
        <FormikSelect
          name="initial.hospitalizationOvernightStay"
          label="入院・外泊時加算/入院時支援特別加算"
          size="smallMedium"
          disabled={
            this.state.isStatusDisabledHospitalization ||
            disabledWithRegionalTransitionFlg
          }
          options={createOptionsHospitalizationOvernightStayDialog(
            `${this.props.formikPropsValues.values.initial.statusType}`
          )}
          style={selectBoxStyle}
          FormLabelClasses={
            this.state.isStatusTypeNone
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        {this.state.isStatusReadOnlyHospitalizationDays ? (
          <div className={this.props.classes.hospitalizationDaysReadOnly}>
            <ReadonlyTextField
              label="入院・外泊日数"
              value={HospitalizationDays.ZERO}
              defaultValue={HospitalizationDays.ZERO}
            />
          </div>
        ) : (
          <FormikTextField
            name="initial.hospitalizationDays"
            label="入院・外泊日数"
            size="quarterSuperLong"
            style={{ marginBottom: 48, width: 128, minHeight: 52 }}
            error={
              this.props.formikPropsValues.errors &&
              this.props.formikPropsValues.errors.initial
                ? this.props.formikPropsValues.errors.initial
                    .hospitalizationDays !== undefined
                : false
            }
            onChangeHook={this.onChangeText}
            placeholder="-"
            disabled={this.state.isStatusDisabledHospitalizationDays}
            maxLength={3}
          />
        )}
        <FormikCheckbox
          name="initial.regionalTransitionFlg"
          label="地域移行加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            this.state.isStatusDisabledRegionalMigration ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
          onChangeHook={this.onChangeCheck}
        />
        <FormikCheckbox
          name="initial.collectionOfUtilityFeeFlg"
          label="光熱水費提供"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
        />
        <FormikCheckbox
          name="initial.nutritionManagementFlg"
          label="栄養マネジメント加算"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(
              values.initial.statusType
            )
          }
          style={checkBoxStyle}
        />
        {this.props.facilityState.availableFood && (
          <>
            <Typography
              classes={{
                root:
                  !this.state.isStatusTypeNone &&
                  !disabledWithRegionalTransitionFlg &&
                  !this.state.isStatusTypeRegionalMigrationSupport
                    ? this.props.classes.typographyMargin
                    : `${this.props.classes.typographyMargin} ${this.props.classes.disabled}`
              }}
            >
              食事提供
            </Typography>
            <FormGroup row className={this.props.classes.foodGroup}>
              <FormikCheckbox
                name="initial.foodBreakfastFlg"
                label="朝"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
              <FormikCheckbox
                name="initial.foodLunchFlg"
                label="昼"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
              <FormikCheckbox
                name="initial.foodSupperFlg"
                label="夜"
                classes={{ root: this.props.classes.checkbox }}
                disabled={
                  this.state.isStatusTypeNone ||
                  disabledWithRegionalTransitionFlg ||
                  this.state.isStatusTypeRegionalMigrationSupport
                }
                style={foodCheckBoxStyle}
              />
            </FormGroup>
          </>
        )}

        <FormikSelect
          name="initial.oralTransition"
          label="経口移行"
          size="smallMedium"
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            STATUS_TYPE_DISABLED_NUTRITION_AND_ORAL_TRANSFER.includes(
              values.initial.statusType
            )
          }
          options={ORAL_TRANSITION}
          style={selectBoxStyle}
          FormLabelClasses={
            this.state.isStatusTypeNone
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        <FormikSelect
          name="initial.oralPreservation"
          label="経口維持"
          size="smallMedium"
          disabled={
            this.state.isStatusTypeNone ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          options={ORAL_PRESERVATION}
          style={selectBoxStyle}
          FormLabelClasses={
            this.state.isStatusTypeNone
              ? { root: this.props.classes.disabled }
              : undefined
          }
        />
        <FormikCheckbox
          name="initial.medicalFoodsFlg"
          label="療養食"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            !(
              this.props.formikPropsValues.values.initial.oralPreservation ===
                OralPreservation.NONE.toString() &&
              this.props.formikPropsValues.values.initial.oralTransition ===
                OralTransition.NONE.toString()
            ) ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
        />
        <FormikCheckbox
          name="initial.severeDisabilitySupportFlg"
          label="研修修了者による重度障害者支援（個別支援）を実施"
          classes={{ root: this.props.classes.checkbox }}
          disabled={
            this.state.isStatusTypeNone ||
            this.props.facilityState.seriousDisability === 0 ||
            !this.props.isSevereDisabilitySupport ||
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
          style={checkBoxStyle}
        />
        <FormikTextField
          name="initial.remarks"
          label="備考"
          size="quarterSuperLong"
          style={{ marginBottom: 48, width: 536, minHeight: 52 }}
          error={
            this.props.formikPropsValues.errors &&
            this.props.formikPropsValues.errors.initial
              ? this.props.formikPropsValues.errors.initial.remarks !==
                undefined
              : false
          }
          onChangeHook={this.onChangeText}
          disabled={
            disabledWithRegionalTransitionFlg ||
            this.state.isStatusTypeRegionalMigrationSupport
          }
        />
      </Paper>
    );
  }
}
export default withStyles(styles)(UsagePerformanceReportDialogFields);
