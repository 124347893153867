import * as React from "react";
import * as H from "history";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import MuiCheckbox from "@components/molecules/MuiCheckbox";

const styles = (): StyleRules =>
  createStyles({
    dialogHeader: {
      marginBottom: 4,
      padding: "16px 24px 20px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 64
    },
    dialogContent: {
      minWidth: 550,
      height: "100%",
      padding: 0,
      "&::-webkit-scrollbar": { display: "none" }
    },
    dialogSubTitle: {
      margin: 0,
      padding: "20px 24px",
      color: "#37474f",
      lineHeight: 1.6,
      fontSize: "16px",
      fontWeight: "normal",
      whiteSpace: "pre-line"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      marginLeft: 0,
      marginRight: 0
    },
    checkboxLi: {
      margin: "24px 0 0 24px"
    },
    checkbox: {
      margin: "-32px 0 0 0"
    },
    buttons: {
      width: 125,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 32
      }
    },
    space: {
      marginLeft: 20
    }
  });

type Props = {
  isModalOpen: boolean;
  onClose: () => void;
  history: H.History;
  yyyymmdd: string;
} & WithStyles<typeof styles>;

const DailyPrintModalCore = (
  props: Props /* , state: State */
): JSX.Element => {
  const [workFlag, setWorkFlag] = React.useState(false);
  const [staffFlag, setStaffFlag] = React.useState(false);
  const dispFlgs = {
    title: "日々の記録",
    moveUrl: `/record/print/daily/${props.yyyymmdd}`
  };

  // event handler
  const onCloseModal = (): void => {
    props.onClose();
  };
  const changeWorkFlag = (): void => {
    setWorkFlag(!workFlag);
  };
  const changeStaffFlag = (): void => {
    setStaffFlag(!staffFlag);
  };

  const moveToPreview = (): void => {
    const params = [];
    if (workFlag) {
      params.push("operation_record");
    }
    if (staffFlag) {
      params.push("staff_comment");
    }
    const printOptions =
      params.length > 0 ? `?display_columns=${params.join(",")}` : "";

    props.history.push(`${dispFlgs.moveUrl}${printOptions}`);
  };
  return (
    <div>
      <Dialog open={props.isModalOpen} disableBackdropClick>
        <DialogTitle className={props.classes.dialogHeader}>
          <span>{dispFlgs.title}</span>
          <span className={props.classes.space}>印刷項目</span>
        </DialogTitle>
        <DialogContent className={props.classes.dialogContent}>
          <>
            <div className={props.classes.dialogSubTitle}>
              以下の項目以外で、入力された全ての項目が印刷されます。
              <br />
              追加で印刷したい項目にチェックしてください。
            </div>
            <div className={props.classes.checkboxLi}>
              <div className={props.classes.checkbox}>
                <MuiCheckbox
                  id="work_flg"
                  label="業務日誌"
                  checked={workFlag}
                  value="1"
                  onChange={changeWorkFlag}
                />
              </div>
              <div className={props.classes.checkbox}>
                <MuiCheckbox
                  id="staff_flg"
                  label="職員考察（支援記録）"
                  checked={staffFlag}
                  value="1"
                  onChange={changeStaffFlag}
                />
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions className={props.classes.dialogFooter}>
          <KnowbeButton
            className={props.classes.buttons}
            kind="outline"
            onClick={onCloseModal}
          >
            キャンセル
          </KnowbeButton>
          <KnowbeButton
            className={props.classes.buttons}
            variant="contained"
            color="secondary"
            onClick={moveToPreview}
          >
            確定する
          </KnowbeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const DailyPrintModal = withStyles(styles)(DailyPrintModalCore);
