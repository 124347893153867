export const TERMINAL = {
  MAC: "mac",
  WINDOWS: "windows",
  IPHONE: "iphone",
  IPAD: "ipad",
  ANDROID: "android",
  UNKNOWN: "unknown"
} as const;
export type TERMINAL = typeof TERMINAL[keyof typeof TERMINAL];

const userAgentCheck = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const getTerminalFromUA = (): TERMINAL => {
  const ua = userAgentCheck();
  if (
    ua.indexOf("ipad") !== -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  )
    return TERMINAL.IPAD;
  if (ua.indexOf("iphone") !== -1) return TERMINAL.IPHONE;
  if (ua.indexOf("android") !== -1) return TERMINAL.ANDROID;
  if (ua.indexOf("mac") !== -1) return TERMINAL.MAC;
  if (ua.indexOf("windows") !== -1) return TERMINAL.WINDOWS;

  return TERMINAL.UNKNOWN;
};

export const currentTerminalName = (): string => {
  const currentUA = getTerminalFromUA();
  switch (currentUA) {
    case TERMINAL.IPAD:
      return "iPad";
    case TERMINAL.IPHONE:
      return "iPhone";
    case TERMINAL.ANDROID:
      return "Android";
    case TERMINAL.MAC:
      return "Mac";
    case TERMINAL.WINDOWS:
      return "Windows";
    default:
      return "unknown";
  }
};
