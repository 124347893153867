import { RecordUserDetailValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { ErrorMessage } from "@validator";
import {
  CUSTOM_RECORD_TIME_DEFAULT_CHOICE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/mgr/TANKINYUSHO/variables";

type RecordUserDetailErrors = {
  record: ValidationErrors<RecordUserDetailValues["record"]>;
};

type CustomRecordFirstErrorMessage = {
  [custom_record_id: string]: { input_data: ErrorMessage };
};

type CustomRecordFourthErrorMessage = {
  [custom_record_id: string]: {
    [choiced_item_id: string]: { input_data: ErrorMessage };
  };
};

const validateCustomRecordFirst = (
  customRecords: RecordUserDetailValues["record"][0]["custom_record"]["input_type_first"]
): CustomRecordFirstErrorMessage => {
  const obj = {};
  Object.keys(customRecords).forEach((item) => {
    Object.assign(obj, {
      [item]: {
        input_data: validator(customRecords[`${item}`].input_data, {
          type: "checkCharacterLength",
          length: 2500
        })
      }
    });
  });
  return obj;
};

const validateCustomRecordFourth = (
  customRecords: RecordUserDetailValues["record"][0]["custom_record"]["input_type_fourth"]
): CustomRecordFourthErrorMessage => {
  const obj = {};
  Object.entries(customRecords).forEach(([customRecordKey, items]) => {
    const choicedObj = {};
    Object.entries(items).forEach(([choicedItemKey, item]) => {
      // 項目が「受入時間 / 終了時間」の場合
      if (
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.start_end_time
      ) {
        // itemから受入時間と終了時間を抽出
        const pick_item_by_default_choice = (
          default_choice: number
        ): string => {
          const record = Object.values(items).find(
            (el) => el.default_choice === default_choice
          );
          return record ? record.input_data : "";
        };
        const startTime = pick_item_by_default_choice(
          CUSTOM_RECORD_TIME_DEFAULT_CHOICE.start_time
        );
        const endTime = pick_item_by_default_choice(
          CUSTOM_RECORD_TIME_DEFAULT_CHOICE.end_time
        );

        // 選択肢が「受入時間」の場合
        if (
          item.default_choice === CUSTOM_RECORD_TIME_DEFAULT_CHOICE.start_time
        ) {
          Object.assign(choicedObj, {
            [choicedItemKey]: {
              input_data: validator(item.input_data, "checkTime")
            }
          });
        }

        // 選択肢が終了時間の場合
        if (
          item.default_choice === CUSTOM_RECORD_TIME_DEFAULT_CHOICE.end_time
        ) {
          Object.assign(choicedObj, {
            [choicedItemKey]: {
              input_data: validator(endTime, "checkTime", {
                type: "checkTimeFuture",
                startTime,
                options: { firstLabel: "終了時間", secondLabel: "受入時間" }
              })
            }
          });
        }
        // 項目が「受入時間 / 終了時間」以外の場合
      } else {
        Object.assign(choicedObj, {
          [choicedItemKey]: {
            input_data: validator(item.input_data, "decimalPoint")
          }
        });
      }
    });
    Object.assign(obj, { [customRecordKey]: choicedObj });
  });
  return obj;
};

const validation = (values: RecordUserDetailValues): RecordUserDetailErrors => {
  return {
    record: values.record
      ? values.record.map((item) => ({
          custom_record: {
            input_type_first: validateCustomRecordFirst(
              item.custom_record.input_type_first
            ),
            input_type_fourth: validateCustomRecordFourth(
              item.custom_record.input_type_fourth
            )
          }
        }))
      : []
  };
};

export default validation;
