import * as actions from "./actions";
import { Dispatch } from "redux";
import { normalizeFormValuesToPostMonitoringReport } from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { MonitoringReportValues } from "@initialize/mgr/KEIKAKUSODAN/record/monitoringReport/initialValues";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { monitoringReportApi } from "@api/requests/monitoringReport";
import { normalizeValueFromAPI } from "../consultation/normalizer";
import { consultationApi } from "@api/requests/consultation";
import { KEIKAKUSODANGetConsultationResponse as GetConsultationResponse } from "@api/requests/consultation/getConsultation";

const fetchMonitoringReport = (dispatch: Dispatch) => async (
  uifId: string,
  monitoringReportId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchMonitoringReportStarted());
  await monitoringReportApi
    .getMonitoringReport(uifId, monitoringReportId)
    .then((res) => {
      dispatch(actions.fetchMonitoringReportSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchMonitoringReportFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 作成済み計画読み込み
const copyConsultation = (dispatch: Dispatch) => async (
  uifId: string,
  consultationId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.copyConsultationStarted());
  await consultationApi
    .getConsultation<GetConsultationResponse>(uifId, consultationId)
    .then((res) => {
      const normalizedValue = normalizeValueFromAPI(res.data.data);
      dispatch(actions.copyConsultationSuccess(normalizedValue));
    })
    .catch((e) => {
      dispatch(actions.copyConsultationFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const postMonitoringReport = (dispatch: Dispatch) => async (
  uifId: string,
  values: MonitoringReportValues,
  initialValues?: MonitoringReportValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postMonitoringReportStarted());
  const normalizedParams = normalizeFormValuesToPostMonitoringReport(
    values,
    initialValues
  );
  await monitoringReportApi
    .postMonitoringReport(uifId, normalizedParams)
    .then(() => {
      dispatch(actions.postMonitoringReportSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.postMonitoringReportFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const deleteMonitoringReport = (dispatch: Dispatch) => async (
  uifId: string,
  id: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteMonitoringReportStarted());
  await monitoringReportApi
    .deleteMonitoringReport(uifId, id)
    .then(() => {
      dispatch(actions.deleteMonitoringReportSuccess());
      dispatch(snackbarActions.showSnackbar("削除が完了しました", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.deleteMonitoringReportFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchMonitoringReport: ReturnType<typeof fetchMonitoringReport>;
  copyConsultation: ReturnType<typeof copyConsultation>;
  postMonitoringReport: ReturnType<typeof postMonitoringReport>;
  deleteMonitoringReport: ReturnType<typeof deleteMonitoringReport>;
};

export const KEIKAKUSODANMonitoringReportDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchMonitoringReport: fetchMonitoringReport(dispatch),
  copyConsultation: copyConsultation(dispatch),
  postMonitoringReport: postMonitoringReport(dispatch),
  deleteMonitoringReport: deleteMonitoringReport(dispatch)
});
