import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  FacilityValues
} from "@initialize/v202104/mgr/CHIIKIIKO/facility/initialValues";
import { validation } from "@initialize/v202104/mgr/CHIIKIIKO/facility/validation";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import { BasicFields } from "@components/v202104/organisms/mgr/CHIIKIIKO/facility/BasicFields";
import { BasicRemuneration } from "@components/v202104/organisms/mgr/CHIIKIIKO/facility/BasicRemunerationFields";
import { AdditionalItemFields } from "@components/v202104/organisms/mgr/CHIIKIIKO/facility/AdditionalItemFields";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { FacilityState } from "@stores/v202104/domain/mgr/CHIIKIIKO/facility/types";
import { CityState, CityParams } from "@stores/domain/city/type";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import deepEqual from "fast-deep-equal";
import { NOTICE_HEADER_HEIGHT } from "@components/templates/AdminTemplate202104";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      height: spacing.unit * 8,
      top: NOTICE_HEADER_HEIGHT
    }
  });

type DispatchProps = {
  fetchFacility: () => Promise<void>;
  postFacility: (values: FacilityValues, facility: FacilityValues) => void;
  fetchCity: (params: CityParams) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  facility: FacilityState;
  cityList: CityState[];
  needsStopHistory: boolean;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const FacilityFormCore = (props: Props): JSX.Element => {
  const unmount = React.useRef<boolean>(false);
  const [initialValuesState, setInitialValuesState] = React.useState<
    FacilityValues
  >(initialValues());
  const [isFetchDone, setIsFetchDone] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async (): Promise<() => void> => {
      await props.fetchFacility();
      if (!unmount.current) {
        setInitialValuesState(initialValues(props.facility));
        setIsFetchDone(true);
      }
      return (): void => {
        unmount.current = true;
      };
    })();
  }, []);

  React.useEffect(() => {
    // 再取得時、formを更新
    if (isFetchDone) {
      setInitialValuesState(initialValues(props.facility));
    }
  }, [props.facility, isFetchDone]);

  React.useEffect(() => {
    if (props.facility.selectedPrefectureName !== "") {
      props.fetchCity({
        prefectureName: props.facility.selectedPrefectureName
      });
    }
  }, [props.facility.selectedPrefectureName]);

  const onSubmit = async (
    values: FacilityValues,
    actions: FormikActions<FacilityValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.postFacility(values, initialValues(props.facility));
    actions.setSubmitting(false);
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const confirmDiscardFormChanges = (nextValues: FacilityValues): void => {
    const hasChange = !deepEqual(nextValues, initialValuesState);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: FacilityValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  return (
    <Formik
      initialValues={initialValuesState}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <ContentHeader
            position="sticky"
            classes={{ wrapper: props.classes.wrapper }}
          >
            <ContentHeaderRight mediaOff>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                errorAction={submitError}
              />
            </ContentHeaderRight>
          </ContentHeader>
          {/* 基本情報 */}
          <BasicFields
            isFetchDone={isFetchDone}
            serviceType={props.facility.serviceType}
            formikProps={formikProps}
            setFormikFieldValue={formikProps.setFieldValue}
          />
          {/* 基本報酬 */}
          <BasicRemuneration />
          {/* 加算対象項目 */}
          <AdditionalItemFields />
        </Form>
      )}
    </Formik>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { cityDispatch, uiDispatch, v202104 } = dispatches;
  const facilityDispatcher = v202104.CHIIKIIKO.facilityDispatcher(dispatch);
  const cityDispatches = cityDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchFacility: facilityDispatcher.fetch,
    postFacility: (
      values: FacilityValues,
      facility: FacilityValues
    ): Promise<void> => facilityDispatcher.post(values, facility),
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.v202104.CHIIKIIKO.facility,
  cityList: state.city,
  needsStopHistory: state.ui.needsStopHistory
});

export const FacilityForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FacilityFormCore));
