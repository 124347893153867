import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";

// store
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// ui
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import { CustomRecordsTable } from "@components/organisms/mgr/KEIKAKUSODAN/assessment/setting/CustomRecordsTable";
import { SettingFormButtons } from "@components/organisms/mgr/KEIKAKUSODAN/assessment/setting/SettingFormButtons";

// formik
import { Formik, FormikActions } from "formik";
import { tableBodyInitialValues } from "@initialize/record/customRecordWithCategory/initialValues";

// others
import { KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME } from "@constants/variables";
import deepEqual from "fast-deep-equal";
import { HiddenItemsWithCategoryModal } from "@components/organisms/mgr/KEIKAKUSODAN/assessment/setting/dialog/HiddenItemsWithCategoryModal";
import { orderBy } from "lodash-es";
import classNames from "classnames";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    editable: {
      opacity: 1
    },
    unEditable: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    },
    descriptionMarginBottom: {
      marginBottom: spacing.unit * 3
    },
    pureSettingTopSection: {
      marginTop: spacing.unit * 3,
      marginBottom: spacing.unit,
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "end"
    }
  });

type OwnProps = {
  isCategorized: boolean;
  categoryType: number;
  label: string;
  facility: FacilityState;
  customRecords: CustomRecordsWithCategoryState;
  sortingCategoryType: number | null;
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  sortingItemType: number | null;
  setSortingItemType: reactDispatch<SetStateAction<number | null>>;
  fetchCustomRecords: () => Promise<void>;
  openAddModal: (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null
  ) => void;
  editedId: number;
  editedType: "" | "category" | "item";
  changeEditMode: (
    id: number,
    type: "" | "category" | "item",
    record: CustomRecordsWithCategoryState[number]
  ) => void;
  endEdit: () => void;
  submitEdit: (value: CustomRecordsWithCategoryState) => Promise<void>;
  isHiddenButton?: boolean;
  onClickHideCheckbox: (
    type: "category" | "item",
    target: "keikaku" | "shogaiji",
    record?: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ) => Promise<void>;
  onClickVisibilityCheckbox: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: "category" | "item",
    target: "keikaku" | "shogaiji",
    record: CustomRecordsWithCategoryState[number],
    item?: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ) => Promise<void>;
  changeVisibilityCustomRecord: (
    customRecordItems?: postCustomRecordsVisibilityParams["custom_record_items"],
    customRecordsCategory?: postCustomRecordsVisibilityParams["custom_records_category"]
  ) => Promise<void>;
};

type StateProps = {
  needsStopHistory: boolean;
};

type DispatchProps = {
  postCustomRecordsCategoryOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState
  ) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState,
    customRecordCategoryId: number
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const AssessmentSettingFormCore = (props: Props): JSX.Element => {
  const {
    isCategorized,
    categoryType,
    label,
    classes,
    facility,
    customRecords,
    isHiddenButton,
    fetchCustomRecords,
    sortingCategoryType,
    setSortingCategoryType,
    sortingItemType,
    setSortingItemType,
    postCustomRecordsOrder,
    postCustomRecordsCategoryOrder,
    needsStopHistory,
    stopHistory,
    openAddModal,
    editedId,
    editedType,
    changeEditMode,
    endEdit,
    submitEdit,
    onClickHideCheckbox,
    onClickVisibilityCheckbox,
    changeVisibilityCustomRecord
  } = props;

  // state
  const [commonTableValues, setCommonTableValues] = React.useState(
    tableBodyInitialValues()
  );
  const [
    isOpenHiddenCustomRecordList,
    setOpenHiddenCustomRecordList
  ] = React.useState(false);
  const [
    hiddenCustomRecordCategoryType,
    setHiddenCustomRecordCategoryType
  ] = React.useState(0);

  // 並べ替え用関数
  const sortRecords = (
    records: CustomRecordsWithCategoryState
  ): CustomRecordsWithCategoryState => {
    return records
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      })
      .map((r) => {
        const sortedCustomRecordItems = r.custom_record_items.sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
        return { ...r, custom_record_items: sortedCustomRecordItems };
      });
  };

  const visibleCustomRecords = customRecords.length
    ? sortRecords(customRecords)
        .map((record) => {
          const invisibleCustomRecordItems = record.custom_record_items.filter(
            (i) => i.visibility
          );
          return { ...record, custom_record_items: invisibleCustomRecordItems };
        })
        .filter((record) => record.visibility === 1)
    : [];

  // 並び替え不可(orderがnull）の項目を先に表示するためのソート処理
  let sortedCustomRecords = visibleCustomRecords;
  const sortedRecords = orderBy(visibleCustomRecords, [
    (v): boolean => v.order !== null,
    "order",
    "default_category"
  ]);

  sortedCustomRecords = sortedRecords.map((record) => {
    const sortedCustomRecordItems = orderBy(record.custom_record_items, [
      (v): boolean => v.order !== null,
      "order"
    ]);
    return { ...record, custom_record_items: sortedCustomRecordItems };
  });

  // 非表示カテゴリーか非表示項目が1つでも存在するかどうか
  const invisibleCustomRecordExist: boolean = customRecords.length
    ? !!customRecords.filter((record) => {
        const invisibleCustomRecordItems = record.custom_record_items.filter(
          (i) => i.visibility === 0
        );
        return record.visibility === 0 || invisibleCustomRecordItems.length > 0;
      }).length
    : false;

  React.useEffect(() => {
    setCommonTableValues(tableBodyInitialValues(sortedCustomRecords));
  }, [customRecords]);

  const confirmDiscardFromChanges = (
    nextValues: CustomRecordsWithCategoryState
  ): void => {
    const hasChange = !deepEqual(nextValues, commonTableValues);
    if (hasChange) {
      stopHistory(true);
    }
  };

  // 離脱確認モーダルを表示するかのみの確認
  const validate = (values: CustomRecordsWithCategoryState): void => {
    if (!needsStopHistory) {
      confirmDiscardFromChanges(values);
    }
  };

  const onSubmit = async (
    values: CustomRecordsWithCategoryState,
    actions: FormikActions<CustomRecordsWithCategoryState>
  ): Promise<void> => {
    await actions.setSubmitting(true);
    if (editedId) {
      await submitEdit(values);
      await fetchCustomRecords();
      await endEdit();
    } else if (sortingCategoryType) {
      // 並び替え不可のカテゴリーはリクエストから排除
      await postCustomRecordsCategoryOrder(
        values.filter((v) => v.order !== null)
      );
      await fetchCustomRecords();
      await setSortingCategoryType(null);
    } else if (sortingItemType) {
      // 並び替え不可の項目はリクエストから排除
      const sortableValues = values.map((record) => {
        const sortableCustomRecordItems = record.custom_record_items.filter(
          (v) => v.order !== null
        );
        return { ...record, custom_record_items: sortableCustomRecordItems };
      });
      await postCustomRecordsOrder(sortableValues, sortingItemType);
      await fetchCustomRecords();
      await setSortingItemType(null);
    }
    actions.setSubmitting(false);
  };

  // 非表示項目リスト
  const openHiddenCustomRecordsList = (): void => {
    setHiddenCustomRecordCategoryType(categoryType);
    setOpenHiddenCustomRecordList(true);
  };

  const closeHiddenCustomRecordsList = async (): Promise<void> => {
    await setOpenHiddenCustomRecordList(false);
    setHiddenCustomRecordCategoryType(0);
  };

  const onClickVisible = async (
    customRecordItems?: postCustomRecordsVisibilityParams["custom_record_items"],
    customRecordsCategory?: postCustomRecordsVisibilityParams["custom_records_category"]
  ): Promise<void> => {
    await changeVisibilityCustomRecord(
      customRecordItems,
      customRecordsCategory
    );
    await fetchCustomRecords();
    await setOpenHiddenCustomRecordList(false);
  };

  const sortedCustomRecordsForHiddenItemsWithCategoryModal = (): CustomRecordsWithCategoryState => {
    // デフォルトカテゴリー → カスタムカテゴリーの順に表示する
    // 削除機能が存在しないアセスメントの設定ではidの昇順にすることで表示条件を満たす
    return orderBy(customRecords, "id");
  };

  return (
    <>
      <div
        className={
          !sortingCategoryType || sortingCategoryType === categoryType
            ? classes.editable
            : classes.unEditable
        }
      >
        <FormPaper>
          <SectionTitle
            label={`【${KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}】の項目設定`}
          />
          <div
            className={classNames(classes.description, {
              [classes.descriptionMarginBottom]: isHiddenButton
            })}
          >
            {label}
          </div>
          <Formik
            initialValues={commonTableValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikProps): JSX.Element => (
              <>
                {isHiddenButton ? null : (
                  <div className={classes.pureSettingTopSection}>
                    <SettingFormButtons
                      isCategorized={isCategorized}
                      orderDisable={
                        sortedCustomRecords.filter((v) => v.allow_change_order)
                          .length <= 1
                      }
                      categoryType={categoryType}
                      sortingCategoryType={sortingCategoryType}
                      setSortingCategoryType={setSortingCategoryType}
                      openAddModal={openAddModal}
                      formikProps={formikProps}
                      formValues={commonTableValues}
                      stopHistory={stopHistory}
                      editedId={editedId}
                      setSortingItemType={setSortingItemType}
                      sortingItemType={sortingItemType}
                    />
                  </div>
                )}
                <CustomRecordsTable
                  isCategorized={isCategorized}
                  openAddModal={openAddModal}
                  changeEditMode={changeEditMode}
                  openHiddenCustomRecordsList={openHiddenCustomRecordsList}
                  facility={facility}
                  settingType={categoryType}
                  isSortingCategory={sortingCategoryType === categoryType}
                  editedId={editedId}
                  editedType={editedType}
                  formikProps={formikProps}
                  formValues={commonTableValues}
                  endEdit={endEdit}
                  sortingItemType={sortingItemType}
                  setSortingItemType={setSortingItemType}
                  invisibleCustomRecordExist={invisibleCustomRecordExist}
                  stopHistory={stopHistory}
                  onClickHideCheckbox={onClickHideCheckbox}
                  onClickVisibilityCheckbox={onClickVisibilityCheckbox}
                />
              </>
            )}
          </Formik>
        </FormPaper>
      </div>
      <HiddenItemsWithCategoryModal
        isModalOpen={isOpenHiddenCustomRecordList}
        onClose={closeHiddenCustomRecordsList}
        onVisible={onClickVisible}
        hiddenCustomRecordCategoryType={hiddenCustomRecordCategoryType}
        customRecords={sortedCustomRecordsForHiddenItemsWithCategoryModal()}
        facility={facility}
        isCategorized={isCategorized}
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory, uiDispatch } = dispatches;
  const customRecordsWithCategoryDispatches = customRecordsWithCategory(
    dispatch
  );
  const uiDispatches = uiDispatch(dispatch);

  return {
    postCustomRecordsCategoryOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsCategoryOrder(
        visibleCustomRecords
      );
    },
    postCustomRecordsOrder: (
      visibleCustomRecords: CustomRecordsWithCategoryState,
      customRecordCategoryId: number
    ): Promise<void> => {
      return customRecordsWithCategoryDispatches.postCustomRecordsOrder(
        visibleCustomRecords,
        customRecordCategoryId
      );
    },
    stopHistory: uiDispatches.stopHistory
  };
};

export const AssessmentSettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AssessmentSettingFormCore)
);
