import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import ContentHeaderSubmit from "@components/molecules/ContentHeaderSubmit";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import {
  HORIZON_PRINT_STYLE_ID,
  STICKY_BOX_SHADOW,
  STICKY_NONE_BOX_SHADOW
} from "@constants/styles";
import ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    root: {
      border: "none",
      height: 300
    },
    noPrint: {},
    wrap: {
      width: "100%",
      position: "relative",
      zIndex: 1
    },
    "@media print": {
      noPrint: {
        display: "none"
      }
    },
    header: {
      zIndex: 10,
      position: "sticky",
      top: 0
    },
    buttonWidth: {
      width: 120
    },
    cancelButton: {
      width: 120,
      height: 36,
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      backgroundColor: "rgba(98, 2, 238, 0)",
      color: "#0277bd"
    }
  });

type Props = {
  type?: "vertical" | "horizontal";
} & RouteComponentProps &
  WithStyles<typeof styles>;

const initialState = {
  height: 300,
  stickyFlg: false
};

type State = Readonly<typeof initialState>;

class PrintPreviewTemplate extends React.Component<Props, State> {
  public unmounted = false;

  public scrollYValue = 48;

  constructor(props: Props) {
    super(props);
    this.state = initialState;
  }

  public componentDidMount(): void {
    window.addEventListener("scroll", this.listenScrollEvent);

    // 印刷時に横向きに表示するstyle
    if (this.props.type === "horizontal") {
      const styleEl = document.createElement("style");
      styleEl.id = HORIZON_PRINT_STYLE_ID;
      styleEl.textContent = `
        @page { size: landscape; }
        body { -webkit-print-color-adjust: exact; }
      `;
      document.head.appendChild(styleEl);
    }
  }

  public componentWillUnmount(): void {
    this.unmounted = true;
    window.removeEventListener("scroll", this.listenScrollEvent);

    // 横向きstyleがある場合は削除する
    const styleEl = document.getElementById(HORIZON_PRINT_STYLE_ID);
    if (styleEl) styleEl.remove();
  }

  public listenScrollEvent = (): void => {
    if (this.unmounted) return;
    if (window.scrollY > this.scrollYValue) {
      this.setState({ stickyFlg: true });
    } else {
      this.setState({ stickyFlg: false });
    }
  };

  private handleSubmit = (): void => {
    window.print();
  };

  private handleCancel = (): void => {
    this.props.history.goBack();
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    return (
      <>
        <div
          className={ClassNames(
            this.props.classes.header,
            this.props.classes.noPrint
          )}
          style={
            this.state.stickyFlg
              ? { boxShadow: STICKY_BOX_SHADOW }
              : { boxShadow: STICKY_NONE_BOX_SHADOW }
          }
        >
          <ContentHeader>
            <ContentHeaderSubmit
              buttonName="印刷する"
              handleSubmit={this.handleSubmit}
              submitStyle={classes.buttonWidth}
              cancelButtonName="キャンセル"
              handleCancel={this.handleCancel}
              cancelStyle={classes.cancelButton}
              isPrintPage
            />
          </ContentHeader>
        </div>
        <div className={classes.wrap}>{this.props.children}</div>
      </>
    );
  }
}

export default withStyles(styles)(PrintPreviewTemplate);
