import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.AssistanceReportState = {
  assistanceReport: {
    id: null,
    created_at: "",
    updated_at: "",
    guardian_name: "",
    guardian_relation: "",
    date_of_monitoring: "",
    creation_date: "",
    author: null,
    classify_disability_support: 0,
    classify_handicapped_flg: 0,
    disabled_welfare_service_recipient_number: "",
    consultation_support_recipient_number: "",
    regular_place_recipient_number: "",
    attainment_goal: "",
    whole_situation: "",
    staff_comment: "",
    details: []
  },
  consultationCopy: null
};

export const CHIIKIIKOAssistanceReport = (
  state = initialState,
  action: actions.ActionTypes
): types.AssistanceReportState => {
  switch (action.type) {
    case types.FETCH_ASSISTANCE_REPORT_STARTED:
      return { ...state };
    case types.FETCH_ASSISTANCE_REPORT_SUCCESS:
      return { ...state, assistanceReport: action.payload };
    case types.FETCH_ASSISTANCE_REPORT_FAILED:
      return { ...state };
    case types.COPY_CONSULTATION_STARTED:
      return { ...state };
    case types.COPY_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultationCopy: action.payload.res
      };
    case types.COPY_CONSULTATION_FAILED:
      return { ...state };
    case types.RESET_COPY_CONSULTATION:
      return {
        ...state,
        consultationCopy: null
      };
    case types.POST_ASSISTANCE_REPORT_STARTED:
      return { ...state };
    case types.POST_ASSISTANCE_REPORT_SUCCESS:
      return { ...state };
    case types.POST_ASSISTANCE_REPORT_FAILED:
      return { ...state };
    case types.DELETE_ASSISTANCE_REPORT_STARTED:
      return { ...state };
    case types.DELETE_ASSISTANCE_REPORT_SUCCESS:
      return { ...state };
    case types.DELETE_ASSISTANCE_REPORT_FAILED:
      return { ...state };
    default:
      return state;
  }
};
