import * as React from "react";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Table from "@components/molecules/Table";
import {
  TableMultiHead,
  HeaderProps
} from "@components/molecules/TableMultiHead";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import { BASE_GREY_TEXT_COLOR } from "@/constants/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { InOutReportTableCells } from "@components/v202104/organisms/mgr/KODOENGO/report/InOutReportTableCells";
import { AppState } from "@stores/type";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import {
  ReportState,
  InoutResultsState,
  KODOENGOReportTypeInterface,
  REPEAT_DAILY,
  REPEAT_MONTHLY,
  DeleteReportState,
  CopyReportState
} from "@stores/v202104/domain/mgr/KODOENGO/report/types";
import AddIcon from "@material-ui/icons/Add";
import classNames from "classnames";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KODOENGO/userInFacility/types";
import format from "date-fns/format";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableLayout: {
      width: "100%",
      minWidth: 1030
    },
    tableFooter: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left top",
      height: 1
    },
    rowDaily: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    rowUser: {
      height: 50,
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    noData: {
      clear: "both",
      marginTop: 76,
      textAlign: "center",
      height: 104,
      color: BASE_GREY_TEXT_COLOR
    },
    superShortIconCell: {
      minWidth: 32,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 4,
      paddingRight: 4,
      textAlign: "center"
    },
    middleShortCell: {
      minWidth: 16,
      width: 16,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 8,
      paddingRight: 8
    },
    shortCell: {
      minWidth: 32,
      width: 32,
      boxSizing: "content-box",
      lineHeight: "20px",
      "&:last-child": {
        paddingRight: 4
      },
      paddingLeft: 4,
      paddingRight: 4,
      textAlign: "center"
    },
    middleCell: {
      minWidth: 72,
      width: 72,
      boxSizing: "content-box",
      lineHeight: "20px",
      paddingLeft: 4,
      paddingRight: 4
    },
    longCellFirst: {
      minWidth: 100,
      width: 100,
      boxSizing: "content-box",
      lineHeight: "28px",
      paddingTop: 10,
      paddingBottom: 10,
      paddingRight: 8,
      paddingLeft: 16
    },
    headerVerticalAlign: {
      verticalAlign: "bottom"
    },
    headerPaddingBottom: {
      paddingTop: 16,
      paddingBottom: 16
    },
    border: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left top",
      // 背景色を指定するセルの場合、個別で再設定
      "& > :first-child": {
        backgroundColor: "#ffffff",
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
        backgroundSize: "6px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left top"
      },
      "& > :last-child": {
        backgroundColor: "#ffffff",
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
        backgroundSize: "6px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left top"
      }
    },
    categoryHeader: {
      paddingTop: 12,
      borderBottom: 0
    },
    daily: {
      paddingTop: 4,
      paddingBottom: 4
    },
    user: {
      paddingTop: 4,
      paddingBottom: 4
    },
    dataHeaderColor: {
      backgroundColor: "#9badb6"
    },
    headerAddItem: {
      paddingBottom: 18,
      textAlign: "center"
    },
    iconDiv: {
      height: 26
    },
    headerFontSize: {
      fontSize: 14
    },
    headerLargeFontSize: {
      fontSize: 16
    },
    copyFontSize: {
      fontSize: 12,
      width: 64,
      minWidth: 64,
      paddingLeft: 0,
      paddingRight: 0
    },
    textAlign: {
      textAlign: "center"
    },
    editPaddingRight: {
      paddingRight: "16px !important" // MuiTableCell:last-child の上書き"
    }
  });

type StateProps = {
  reportState: ReportState;
};
type OwnProps = {
  headerHeight: number;
  openModal: (param: InoutResultsState, inputClass: string) => void;
  openCreateModal: (date: string, uifName: string, uifId: number) => void;
  onClickDeleteIcon: (target: DeleteReportState) => void;
  onCopyRecord: (target: CopyReportState) => void;
  openNonDeleteDialog: () => void;
  type: KODOENGOReportTypeInterface["type"];
  filterFlg: boolean;
  usersInFacilityState?: UsersInFacilityState;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const reportDailyHeader = (
  classes: Props["classes"],
  type: KODOENGOReportTypeInterface["type"]
): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    rowStyle: [
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 32
      },
      {
        borderBottom: 0,
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        height: 20
      }
    ],
    items: [
      [
        {
          align: "left",
          label: type === REPEAT_DAILY ? "利用者名" : "日付",
          className: `${classes.longCellFirst} ${classes.headerVerticalAlign} ${classes.headerLargeFontSize}`,
          rowSpan: 2
        },
        {
          align: "left",
          label: "行動援護計画",
          className: `${classes.categoryHeader} ${classes.dataHeaderColor} ${classes.headerFontSize}`,
          colSpan: 7
        },
        {
          align: "left",
          label: "コピー",
          className: `${classes.middleCell} ${classes.headerPaddingBottom} ${classes.headerVerticalAlign} ${classes.copyFontSize} ${classes.textAlign}`,
          rowSpan: 2
        },
        {
          align: "left",
          label: "サービス提供実績",
          className: `${classes.categoryHeader} ${classes.dataHeaderColor} ${classes.headerFontSize}`,
          colSpan: 8
        },
        {
          align: "left",
          label: <>追加</>,
          className: `${classes.shortCell} ${classes.headerVerticalAlign} ${classes.headerAddItem} ${classes.headerFontSize}`,
          rowSpan: 2
        }
      ],
      [
        {
          align: "left",
          label: "",
          className: `${classes.middleShortCell} ${classes.dataHeaderColor} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>開始時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>終了時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>計画時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: "人数",
          className: `${classes.shortCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>削除</>,
          className: `${classes.superShortIconCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>編集</>,
          className: `${classes.superShortIconCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.editPaddingRight} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: "",
          className: `${classes.middleShortCell} ${classes.dataHeaderColor} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>開始時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>終了時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>算定時間</>,
          className: `${classes.middleCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: "人数",
          className: ` ${classes.shortCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: "緊急",
          className: `${classes.shortCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>削除</>,
          className: `${classes.superShortIconCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.headerPaddingBottom}`
        },
        {
          align: "left",
          label: <>編集</>,
          className: `${classes.superShortIconCell} ${classes.dataHeaderColor} ${classes.headerFontSize} ${classes.editPaddingRight} ${classes.headerPaddingBottom}`
        }
      ]
    ]
  };
};

const InOutReportTableCore = (props: Props): JSX.Element => {
  const { reportState, type, usersInFacilityState } = props;
  const tableHead = reportDailyHeader(props.classes, props.type);
  const payStartDate =
    usersInFacilityState &&
    usersInFacilityState.user.user_in_facility.date_begin_in_service
      ? usersInFacilityState.user.user_in_facility.date_begin_in_service
      : "";
  const payEndDate =
    usersInFacilityState &&
    usersInFacilityState.user.user_in_facility.date_end_in_service
      ? usersInFacilityState.user.user_in_facility.date_end_in_service
      : "";
  const startDate = Number(format(payStartDate, "YYYYMMDD"));
  const endDate = payEndDate ? Number(format(payEndDate, "YYYYMMDD")) : "";

  const rows =
    type === REPEAT_DAILY
      ? reportState.reportDaily.displayList
      : reportState.reportMonthly.displayList;
  const TableList = rows
    .filter((row) => {
      return (
        !props.filterFlg ||
        !(
          row.dataList.length === 1 &&
          row.dataList[0].plan.inoutResultsId === null &&
          row.dataList[0].result.inoutResultsId === null
        )
      );
    })
    .map((row, index) => {
      const openCreateModal = (): void => {
        const name = `${row.nameSei} ${row.nameMei}`;
        props.openCreateModal(row.targetDate, name, row.usersInFacilityId);
      };

      return row.dataList.map((data, subIndex) => {
        const idx = `${index}${subIndex}`;
        const border =
          index !== 0 && subIndex === 0 ? props.classes.border : "";
        return (
          <TableRow
            key={`table-row-${idx}`}
            className={classNames(
              props.type === REPEAT_DAILY
                ? props.classes.rowDaily
                : props.classes.rowUser,
              border
            )}
          >
            <>
              {subIndex === 0 && props.type === REPEAT_DAILY ? (
                /* 利用者名 */
                <TableCellWrap
                  key={`${idx}-sei-mei`}
                  cellClass={props.classes.longCellFirst}
                  rowSpan={row.dataList.length}
                >
                  {row.nameSei}
                  <> </>
                  {row.nameMei}
                </TableCellWrap>
              ) : null}
              {subIndex === 0 && props.type === REPEAT_MONTHLY ? (
                /* 日付 */
                <TableCellWrap
                  key={`${idx}-target-date`}
                  cellClass={props.classes.longCellFirst}
                  rowSpan={row.dataList.length}
                >
                  <CustomDateLabel
                    date={row.targetDate ? row.targetDate : ""}
                    dateFormat="Do（dd）"
                    holiday={row.isHoliday}
                  />
                </TableCellWrap>
              ) : null}
              <InOutReportTableCells
                params={data}
                idx={`${index}${subIndex}`}
                openModal={props.openModal}
                onClickDeleteIcon={props.onClickDeleteIcon}
                onCopyRecord={props.onCopyRecord}
                openNonDeleteDialog={props.openNonDeleteDialog}
                classOption={
                  type === REPEAT_DAILY
                    ? props.classes.daily
                    : props.classes.user
                }
              />
              {/* 追加ボタン */}
              {subIndex === 0 ? (
                <TableCellWrap
                  key={`${idx}-add-icon`}
                  cellClass={`${props.classes.shortCell} ${props.classes.icon}`}
                  align="right"
                  rowSpan={row.dataList.length}
                >
                  {!(
                    Number(format(row.targetDate, "YYYYMMDD")) < startDate ||
                    (endDate &&
                      Number(format(row.targetDate, "YYYYMMDD")) > endDate)
                  ) && (
                    <div className={props.classes.iconDiv}>
                      <AddIcon
                        style={{
                          width: 24,
                          height: 24,
                          color: "#0277bd",
                          cursor: "pointer"
                        }}
                        onClick={openCreateModal}
                      />
                    </div>
                  )}
                </TableCellWrap>
              ) : null}
            </>
          </TableRow>
        );
      });
    });

  return (
    <>
      <Table key="monthly-report-table" className={props.classes.tableLayout}>
        <TableMultiHead
          role={tableHead.role}
          ariaChecked={tableHead.ariaChecked}
          tabIndex={tableHead.tabIndex}
          key={tableHead.key}
          selected={tableHead.selected}
          items={tableHead.items}
          rowStyle={tableHead.rowStyle}
          headerStyle={{
            zIndex: 10,
            position: "sticky",
            top: `${props.headerHeight}px`
          }}
        />
        <TableBody>{TableList.length > 0 && TableList}</TableBody>
        <TableFooter
          className={TableList.length > 0 ? props.classes.tableFooter : ""}
        >
          <TableRow>
            <TableCellWrap key="table-footer-cell" colSpan={20}>
              <></>
            </TableCellWrap>
          </TableRow>
        </TableFooter>
      </Table>
      {TableList.length === 0 && (
        <div className={props.classes.noData}>
          利用者の情報が登録されていません。
          <br />
          利用者情報ページで利用者を登録した後にご利用ください。
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    reportState: state.v202104.KODOENGO.report
  };
};

export const InOutReportTable = connect(mapStateToProps)(
  withStyles(styles)(InOutReportTableCore)
);
