import { SelectDateValue } from "@interfaces/ui/form";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 * SelectDateValue => string (YYYY-MM-dd)
 * 月と日付を0埋めする
 */
export const selectDateValueToDatePaddingZero = (
  value: SelectDateValue
): string | null => {
  return value.year && value.month && value.day
    ? dateToLocalisedString(
        `${value.year}-${value.month}-${value.day}`,
        "YYYY-MM-DD"
      )
    : null;
};
