import {
  INVOICE_VERSION_V201910,
  INVOICE_VERSION_V202104,
  INVOICE_VERSION_CURRENT
} from "@constants/variables";
import {
  FACILITY,
  FACILITY_201910,
  FACILITY_202104,
  REPORT_DAILY,
  REPORT_DAILY_201910,
  REPORT_DAILY_202104,
  REPORT_MONTHLY,
  REPORT_MONTHLY_201910,
  REPORT_MONTHLY_202104,
  USERS_ID,
  USERS_ID_201910,
  USERS_ID_202104
} from "@constants/url";
import { OptionInterface } from "@components/atoms/DropDown";
import { FieldItem } from "@interfaces/ui/form";

export const GroupHomeReportTabInfo = {
  DAILY: "0",
  MONTHLY: "1"
};

export const GroupHome_REPORT_TABS_INFO = [
  { label: "日ごと", value: GroupHomeReportTabInfo.DAILY },
  { label: "月ごと", value: GroupHomeReportTabInfo.MONTHLY }
];

/**
 * 夜間支援体制加算 - 利用実績
 */
export const USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "Ⅰ", value: "2" },
  { label: "Ⅱ", value: "3" },
  { label: "Ⅲ", value: "4" }
];

// 令和3年度報酬改定で追加
export const USAGE_PERFORMANCE_NIGHT_SUPPORT_TYPE_202104: OptionInterface[] = [
  { label: "-", value: "1" },
  { label: "Ⅰ", value: "2" },
  { label: "Ⅱ", value: "3" },
  { label: "Ⅲ", value: "4" },
  { label: "Ⅰ＋Ⅳ", value: "5" },
  { label: "Ⅰ＋Ⅴ", value: "6" },
  { label: "Ⅰ＋Ⅵ", value: "7" }
];

/**
 * 夜間支援対象者数
 */
const NIGHT_SUPPORT_TARGET_USERS_BASE = [
  { value: "5", label: "5人" },
  { value: "6", label: "6人" },
  { value: "7", label: "7人" },
  { value: "8", label: "8人" },
  { value: "9", label: "9人" },
  { value: "10", label: "10人" },
  { value: "11", label: "11人" },
  { value: "12", label: "12人" },
  { value: "13", label: "13人" },
  { value: "14", label: "14人" },
  { value: "15", label: "15人" },
  { value: "16", label: "16人" },
  { value: "17", label: "17人" },
  { value: "18", label: "18人" },
  { value: "19", label: "19人" },
  { value: "20", label: "20人" },
  { value: "21", label: "21人" },
  { value: "22", label: "22人" },
  { value: "23", label: "23人" },
  { value: "24", label: "24人" },
  { value: "25", label: "25人" },
  { value: "26", label: "26人" },
  { value: "27", label: "27人" },
  { value: "28", label: "28人" },
  { value: "29", label: "29人" },
  { value: "30", label: "30人" }
];

// 通常のケース
export const NIGHT_SUPPORT_TARGET_USERS: OptionInterface[] = [
  { value: "0", label: "-" },
  { value: "2", label: "2人以下" },
  { value: "3", label: "3人" },
  { value: "4", label: "4人" },
  ...NIGHT_SUPPORT_TARGET_USERS_BASE
];

// 夜間支援(Ⅱ)の時
export const NIGHT_SUPPORT_TARGET_USERS_2: OptionInterface[] = [
  { value: "0", label: "-" },
  { value: "4", label: "4人以下" },
  ...NIGHT_SUPPORT_TARGET_USERS_BASE
];

/**
 * 夜間支援体制加算 - 事業所情報
 */
export const NIGHT_SUPPORT_TYPE_OPTIONS: FieldItem[] = [
  {
    value: "2",
    label: "夜間支援等体制加算（Ⅰ）"
  },
  {
    value: "3",
    label: "夜間支援等体制加算（Ⅱ）"
  },
  {
    value: "4",
    label: "夜間支援等体制加算（Ⅲ）"
  }
];

// 令和3年度報酬改定で追加
export const NIGHT_SUPPORT_TYPE_OPTIONS_202104: FieldItem[] = [
  {
    value: "2",
    label: "夜間支援等体制加算（Ⅰ）"
  },
  {
    value: "3",
    label: "夜間支援等体制加算（Ⅱ）"
  },
  {
    value: "4",
    label: "夜間支援等体制加算（Ⅲ）"
  },
  {
    value: "5",
    label: "夜間支援等体制加算（Ⅰ）+（Ⅳ）"
  },
  {
    value: "6",
    label: "夜間支援等体制加算（Ⅰ）+（Ⅴ）"
  },
  {
    value: "7",
    label: "夜間支援等体制加算（Ⅰ）+（Ⅵ）"
  }
];

/**
 * 夜間支援体制加算
 * 未設定を許容するパターンで使う想定
 */
export const NIGHT_SUPPORT_TYPE_ALL_OPTIONS: FieldItem[] = [
  {
    value: "1",
    label: "なし"
  },
  ...NIGHT_SUPPORT_TYPE_OPTIONS
];

// 大規模住居等減算
export const SUBTRACTION_OF_LARGE_SCALE_HOUSING = [
  { label: "対象外", value: "0" },
  { label: "入居定員が8名以上", value: "1" },
  { label: "入居定員が21名以上", value: "2" },
  {
    label: "一体的な運営が行われている共同生活住居の入居定員の合計数が21名以上",
    value: "3"
  }
];

// 令和3年度報酬改定で追加
export const NIGHT_SUPPORT_TYPE_ALL_OPTIONS_202104: FieldItem[] = [
  {
    value: "1",
    label: "なし"
  },
  ...NIGHT_SUPPORT_TYPE_OPTIONS_202104
];

/**
 * 居宅介護
 */
export const HOME_CARE_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

/**
 * 退居後共同生活援助サービス費
 */
export const RETIREMENT_SERVICE_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

/**
 * ピアサポート実施のフラグ
 */
export const PEER_SUPPORT_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

/**
 * 退去後ピアサポート実施
 */
export const AFT_LEAVING_PEER_SUPPORT_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

// 令和6年度報酬改定で追加
/**
 * 8時間以上の利用
 */
export const MORE_THAN_8HOURS_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

/**
 * 集中的支援加算
 */
export const INTENSIVE_SUPPORT_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "Ⅰ", value: "1" },
  { label: "Ⅱ", value: "2" },
  { label: "Ⅰ+Ⅱ", value: "3" }
];

/**
 * 新興感染症等施設療養加算
 */
export const INFECTIOUS_DISEASES_FACILITY_FLG: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "あり", value: "1" }
];

/**
 * 重度障害者支援加算
 */
export const SEVERE_DISABILITY_SUPPORT_TYPE: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "行動関連項目10点以上18点未満の利用者に提供", value: "1" },
  { label: "行動関連項目18点以上の利用者に提供", value: "2" }
];

// 利用状況の詳細用ヘッダー
export const USAGE_PERFORMANCE_SUMMARY_LABEL = [
  { key: 0, text: "", status: [] },
  { key: 1, text: "宿泊", status: [1] },
  {
    key: 2,
    text: "入院",
    status: [2, 3, 4],
    subLabel: ["初日", "中日", "最終日"]
  },
  { key: 3, text: "入院→外泊", status: [8] },
  { key: 4, text: "入院→戻る→外泊", status: [10] },
  {
    key: 5,
    text: "外泊",
    status: [5, 6, 7],
    subLabel: ["初日", "中日", "最終日"]
  },
  { key: 6, text: "外泊→入院", status: [9] },
  { key: 7, text: "外泊→戻る→入院", status: [11] },
  { key: 8, text: "体験利用", status: [12] },
  { key: 9, text: "利用なし", status: [0] }
];

// 目標タイプ
export enum SprintType {
  LONG = 1, // 長期
  SHORT = 2, // 短期
  INDIVIDUAL = 3 // 個別目標
}

// 振り返り目標達成度合い
export const ACHIEVEMENT_STATE = ["未達成", "達成", "一部達成"];

// 振り返りステータス
export const DONE_STATE = ["未実施", "実施", "一部実施"];

// 振り返り目標達成度合い
export const PLAN_CHANGE_STATE = ["なし", "あり"];
type InvoiceVersionUrl = {
  version: number;
  url: string;
};

type INVOICE_PATHS_KEYS = "facility" | "reportDaily" | "reportMonthly";
/** 共同生活援助の報酬改定バージョン管理パスリスト */
export const INVOICE_PATHS: {
  [key in INVOICE_PATHS_KEYS]: InvoiceVersionUrl[];
} = {
  facility: [
    {
      version: INVOICE_VERSION_V201910,
      url: FACILITY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: FACILITY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: FACILITY
    }
  ],
  reportDaily: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_DAILY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_DAILY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_DAILY
    }
  ],
  reportMonthly: [
    {
      version: INVOICE_VERSION_V201910,
      url: REPORT_MONTHLY_201910
    },
    {
      version: INVOICE_VERSION_V202104,
      url: REPORT_MONTHLY_202104
    },
    {
      version: INVOICE_VERSION_CURRENT,
      url: REPORT_MONTHLY
    }
  ]
};

type INVOICE_PATHS_FUNCTIONS_KEYS = "user";
export const INVOICE_PATHS_FUNCTIONS: {
  [key in INVOICE_PATHS_FUNCTIONS_KEYS]: (
    ...params: string[]
  ) => InvoiceVersionUrl[];
} = {
  user: (userId) => [
    {
      version: INVOICE_VERSION_CURRENT,
      url: USERS_ID.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V202104,
      url: USERS_ID_202104.replace(":id", userId)
    },
    {
      version: INVOICE_VERSION_V201910,
      url: USERS_ID_201910.replace(":id", userId)
    }
  ]
};

// 人員配置体制加算
export enum PERSONNEL_DISTRIBUTION_TYPE {
  "なし" = 1,
  "人員配置体制加算（Ⅰ）および （Ⅲ）（加配12:1）",
  "人員配置体制加算（Ⅱ）および（Ⅳ）（加配30:1）"
}

// 個別支援計画・モニタリングの短期目標の数
export const SHORT_TERMS_NUM = 6;
