import React, { useEffect, useState } from "react";
import {
  StyleRules,
  createStyles,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

// ui
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@componentsMobile/templates/AdminTemplate";
import { ServiceDeliveryDailyRecord } from "@componentsMobile/organisms/record/serviceDelivery/ServiceDeliveryDailyRecord";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import {
  compareDateFuture,
  dateInYYYYMMDDFormat,
  dateToLocalisedString
} from "@utils/date";
import KnowbeTabs from "@components/presentational/molecules/KnowbeTabs";
import * as format from "date-fns/format";
import { FacilityType } from "@constants/variables";
import {
  REPORT_TABS_INFO_LiST,
  REPORT_TABS_INFO_PARAM
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import * as URL from "@constants/url";
import { MuiFloatingActionButton } from "@componentsMobile/molecules/MuiFloatingActionButton";
import { DateSelectButtonsDaily } from "@componentsMobile/molecules/DateSelectButtonsDaily";

const styles = (): StyleRules =>
  createStyles({
    tab: {
      position: "relative",
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)"
    },
    dailyHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: 72,
      backgroundColor: "#fff",
      zIndex: 1,
      boxShadow:
        "0 2px 0 0 rgba(0,0,0,.14),0 3px 0 -2px rgba(0,0,0,.2),0 1px 0 0 rgba(0,0,0,.12)"
    }
  });

type StateProps = {
  serviceDeliveryDailyState: ServiceDeliveryState["dailyRecord"];
  facilityType: FacilityType;
};

type OwnProps = RouteComponentProps<{
  date: string;
}> &
  WithStyles<typeof styles>;

type DispatchProps = {
  fetchDailyRecord: (params: string) => Promise<void>;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;
/**
 * サービス提供記録（日ごと）
 */

const currentDate = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDate.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);
const ServiceDeliveryDailyCore = (defaultProps: Props): JSX.Element => {
  const { classes, ...props } = defaultProps;
  const { date } = props.match.params;
  let initialDate = new Date();
  if (date) {
    const year = +date.slice(0, 4);
    const month = +date.slice(4, 6);
    const day = +date.slice(6, 8);
    initialDate = new Date(year, month - 1, day);
    if (maxDate < initialDate || minDate > initialDate) {
      initialDate = new Date();
    }
  }
  const [selectedDate, setSelectedDate] = useState(initialDate);

  const getUrl = (paramDate?: Date): string => {
    const urlDate = paramDate || selectedDate;
    const yyyymmdd = dateToLocalisedString(urlDate, "YYYYMMDD");
    return `${URL.RECORD_SERVICE_DELIVERY_DAILY}/${yyyymmdd}`;
  };

  useEffect(() => {
    if (maxDate < selectedDate || minDate > selectedDate) {
      setSelectedDate(new Date());
    }
  }, []);

  useEffect(() => {
    props.history.replace(getUrl(selectedDate));
    props.fetchDailyRecord(dateInYYYYMMDDFormat(selectedDate));
  }, [selectedDate]);

  const onChangeTag = (event: React.ChangeEvent<{}>, value: string): void => {
    if (value === REPORT_TABS_INFO_PARAM.USERS) {
      props.history.push(
        `${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/0/${format(
          new Date(),
          "YYYYMM"
        )}`
      );
    }
  };

  const onChangeDate = (paramDate: Date): void => {
    setSelectedDate(paramDate);
    props.history.replace(getUrl(paramDate));
  };

  const changeCreateMode = (): void => {
    props.history.push(
      `/record/${dateInYYYYMMDDFormat(selectedDate)}/service_delivery/new`
    );
  };

  const pageNameValue =
    props.facilityType === FacilityType.KODOENGO
      ? "支援記録"
      : "サービス提供記録";

  return (
    <>
      <AdminTemplate
        pageName={pageNameValue}
        nav={[
          {
            key: 1,
            sticky: true,
            top: 0,
            elm: (
              <>
                <div className={classes.tab}>
                  <KnowbeTabs
                    tabInfo={REPORT_TABS_INFO_LiST}
                    onChange={onChangeTag}
                    value={REPORT_TABS_INFO_PARAM.DAILY}
                  />
                </div>
                <div
                  id="serviceDeliveryRecordHeader"
                  className={classes.dailyHeader}
                >
                  <DateSelectButtonsDaily
                    defaultDate={selectedDate}
                    min={minDate}
                    max={maxDate}
                    onClickSubmit={onChangeDate}
                  />
                </div>
              </>
            )
          }
        ]}
        footerButton={
          // 計画のない未来日の場合、計画外の記録の追加はできない
          !(
            props.serviceDeliveryDailyState.service_delivery.length === 0 &&
            compareDateFuture(currentDate, selectedDate)
          ) ? (
            <MuiFloatingActionButton
              onClick={changeCreateMode}
              label="計画外の記録の追加"
            />
          ) : undefined
        }
      >
        <ServiceDeliveryDailyRecord
          currentDate={currentDate}
          selectedDate={selectedDate}
          // propsをstateに入れた時の不具合予防にkeyを入れる
          key={selectedDate.getTime()}
          {...props}
        />
      </AdminTemplate>
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { serviceDelivery } = dispatches;
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchDailyRecord: (param: string): Promise<void> =>
      serviceDeliveryDispatches.fetchDailyRecord(param)
  };
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    serviceDeliveryDailyState: state.serviceDelivery.dailyRecord,
    facilityType: state.user.facility_type
  };
};

export const ServiceDeliveryDailyMobile = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ServiceDeliveryDailyCore));
