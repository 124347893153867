import { InitialDataValues } from "./initialValues";
import validator from "@validator";
import { InitialErrors } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/report/initial";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(
      validationResult.initial.remarks === undefined ||
      validationResult.initial.hospitalizationDays === undefined
    );
  }
  return false;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      remarks: validator(values.initial.remarks, {
        type: "checkCharacterLength",
        length: 50
      }),
      hospitalizationDays:
        values.initial.hospitalizationDays !== null &&
        values.initial.hospitalizationDays.toString() !== "-"
          ? validator(
              values.initial.hospitalizationDays.toString(),
              "naturalNumber"
            )
          : undefined
    }
  };
};

const validation = (values: InitialDataValues): InitialDataErrors => {
  return { ...initialValidation(values) };
};

export default validation;
