import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps, getIn } from "formik";
import { UsersValues } from "@initialize/v202104/mgr/JUDOHOMONKAIGO/users/initialValues";
import { CarePlan } from "@components/v202104/organisms/mgr/JUDOHOMONKAIGO/Users/CarePlan";
import { dateTodayForApi } from "@utils/date/dateTodayForApi";
import format from "date-fns/format";

const styles = (): StyleRules =>
  createStyles({
    descriptionTitle: {
      color: "#37474f",
      fontSize: "1.0rem"
    },
    description: {
      color: "#37474f",
      marginBottom: "5",
      marginLeft: "8"
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  initialFlg: boolean;
  isFetchDone?: boolean;
  uifId: number;
  nameSei: string;
  nameMei: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanFieldsCore = (props: Props): JSX.Element => {
  const [hideAddButton, setHideAddButton] = React.useState(false);

  const startYear = getIn(
    props.formikProps.values,
    "serviceUse.inServiceStartDate.year"
  );
  const startMonth = getIn(
    props.formikProps.values,
    "serviceUse.inServiceStartDate.month"
  );
  const startDay = getIn(
    props.formikProps.values,
    "serviceUse.inServiceStartDate.day"
  );
  const endYear = getIn(
    props.formikProps.values,
    "serviceUse.inServiceEndDate.year"
  );
  const endMonth = getIn(
    props.formikProps.values,
    "serviceUse.inServiceEndDate.month"
  );
  const endDay = getIn(
    props.formikProps.values,
    "serviceUse.inServiceEndDate.day"
  );

  React.useEffect(() => {
    const currentDate = Number(dateTodayForApi());
    const startDate = Number(
      format(`${startYear}-${startMonth}-${startDay}`, "YYYYMMDD")
    );
    const endDate = Number(
      format(`${endYear}-${endMonth}-${endDay}`, "YYYYMMDD")
    );

    const startFlg = startYear && startMonth && startDay;
    const endFlg = endYear && endMonth && endDay;
    if (!startFlg && !endFlg) {
      setHideAddButton(true);
    } else if (startFlg && currentDate < startDate) {
      setHideAddButton(true);
    } else if (endFlg && currentDate > endDate) {
      setHideAddButton(true);
    } else {
      setHideAddButton(false);
    }
  }, [startYear, startMonth, startDay, endYear, endMonth, endDay]);

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="サービス予定の設定" />
      </div>
      {props.initialFlg ? (
        <>
          <FormGroup row style={{ marginBottom: 16 }}>
            <InfoOutlinedIcon
              style={{
                width: "22",
                height: "22",
                color: "#0277bd",
                marginTop: -4
              }}
            />
            <Typography
              className={props.classes.descriptionTitle}
              component="h2"
              variant="h4"
              style={{ marginLeft: 8 }}
            >
              サービス予定の設定について
            </Typography>
          </FormGroup>
          <Typography className={props.classes.description}>
            利用者情報を設定し保存をした後、サービス予定を設定することができます。
          </Typography>
        </>
      ) : (
        <>
          <CarePlan
            uifId={props.uifId}
            nameSei={props.nameSei}
            nameMei={props.nameMei}
            hideAddButton={hideAddButton}
          />
        </>
      )}
    </FormPaper>
  );
};

export const CarePlanFields = withStyles(styles)(CarePlanFieldsCore);
