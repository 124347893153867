import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import { ErrorsState } from "@stores/domain/errors/types";

const styles = (): StyleRules =>
  createStyles({
    warningAlertIconWrapper: {
      position: "relative",
      top: 3,
      left: -10
    },
    warningAlertIcon: {
      width: 18,
      height: 18,
      color: "#ffca28"
    },
    warningListItem: {
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: 0.5,
      textAlign: "center",
      color: "#37474f",
      position: "relative",
      margin: "0 auto",
      listStyle: "none"
    },
    warningBg: {
      backgroundColor: "#fff7df",
      margin: 0,
      padding: "16px 0"
    }
  });

type OwnProps = {
  supportPlanId?: string;
  errorsState: ErrorsState["plan"]["data"] | ErrorsState["goal"]["data"];
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 「エラー種別が警告」かつ「自身(supportPlanId)が等価」のエラーを表示する
 */
const WarningAlertCore = (props: Props): JSX.Element | null => {
  const { errorsState, supportPlanId } = props;
  if (!supportPlanId) return null;
  const relationId = Number(supportPlanId);

  return (
    <ul className={props.classes.warningBg}>
      {errorsState[0].errors.map((error, index) => {
        if (error.relation.value === relationId && error.type === "warn") {
          const key = `warning-row-${index}`;
          return (
            <li className={props.classes.warningListItem} key={key}>
              <span className={props.classes.warningAlertIconWrapper}>
                <WarningIcon className={props.classes.warningAlertIcon} />
              </span>
              <span>{error.content}</span>
            </li>
          );
        }
        return null;
      })}
    </ul>
  );
};

export const WarningAlert = withStyles(styles)(WarningAlertCore);
