import { SupportPlanState } from "@stores/domain/supportPlan/types";
import {
  InputValue,
  SelectValue,
  ShotMultipleSelectValue,
  SelectDateValue,
  RadioButtonValue
} from "@interfaces/ui/form";
import get from "lodash-es/get";
import { dateToSelectDateValue } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  SUPPORT_PLAN_EVALUATION_STATUS_DEFAULT,
  SprintType
} from "@constants/variables";
import { isNil } from "lodash-es";

export interface RecordSupportPlanValues {
  creation_date: SelectDateValue; // 計画作成日
  support_begin_date: SelectDateValue; // 支援開始日
  support_end_date: SelectDateValue; // 支援終了日
  user_request_text: InputValue; // 本人・家族の意向
  current_status: InputValue; // 本人の現状
  long_term_goal: InputValue; // 長期目標
  // 短期目標
  support_plan_goal: {
    id?: number; // 登録済みの場合
    sprint_type?: number; // モニタリングの場合 目標種別 (1:長期,2:短期,3:その他)
    number: number; // 短期目標番号
    sprint_goal: InputValue; // 目標
    adopt_info: InputValue; // 本人の取り組み内容
    support_info: InputValue; // 職員の支援内容
    sprint_result?: RadioButtonValue; // モニタリングの場合 実施
    sprint_review?: InputValue; // モニタリングの場合 評価
    sprint_user_evaluation?: RadioButtonValue; // モニタリングの場合 本人の評価
    sprint_user_review?: InputValue; // モニタリングの場合 本人振り返り
    sprint_staff_evaluation?: RadioButtonValue; // モニタリングの場合 職員からの評価
    sprint_staff_review?: InputValue; // モニタリングの場合 職員振り返り
    user_hope?: InputValue; // (GH)本人の希望
    necessity_plan_change?: RadioButtonValue; // (GH)計画変更の必要性
  }[];
  remarks: InputValue; // 備考欄
  staff_comment: InputValue; // 職員コメント
  author: SelectValue; // 作成者
  minutes_date: SelectDateValue; // 実施日
  participant: ShotMultipleSelectValue; // 参加者
  minutes: InputValue; // 会議議事録
  status_type: SelectValue; // 承認ステータス
  authorizer: SelectValue; // 承認者
  after_summary?: InputValue; // モニタリングの場合 実施後の変化（総括）
  revaluation_date?: SelectDateValue; // モニタリングの場合 再評価日
  evaluation_status?: SelectValue; // モニタリングの場合 モニタリング実施状況
  evaluation_date?: SelectDateValue; // モニタリングの場合 実施日
  evaluation_minutes?: InputValue; // モニタリングの場合 モニタリング議事録
  evaluation_authorizer?: SelectValue; // モニタリングの場合 評価者
}

export type RecordSupportPlanValuesHasMonitoring = Required<
  RecordSupportPlanValues
>;

/**
 * support_plan_goalは入力していないnumberは返って来ないので、ない場合は初期値でセットする
 */
const supplementSupportPlanGoal = (
  supportPlanGoal: SupportPlanState["privateSupportPlan"]["support_plan_goal"]
): RecordSupportPlanValues["support_plan_goal"] => {
  return [1, 2, 3].map((num) => {
    const res = supportPlanGoal.find((s) => s.number === num);
    return res
      ? {
          id: res.id,
          number: res.number,
          sprint_goal: res.sprint_goal || "",
          adopt_info: res.adopt_info || "",
          support_info: res.support_info || ""
        }
      : {
          number: num,
          sprint_goal: "",
          adopt_info: "",
          support_info: ""
        };
  });
};

const initialValues = (
  values?: SupportPlanState["privateSupportPlan"]
): RecordSupportPlanValues => {
  let creationDate = get(values, "creation_date") || "";
  let minutesDate = get(values, "minutes_date") || "";
  if (!values) {
    // 新規作成の場合は現在日付を入れる
    const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");
    creationDate = currentDate;
    minutesDate = currentDate;
  }

  // author,authorizerは入力ありなしで型が変わる
  const authorId =
    values && typeof values.author !== "number" ? `${values.author.id}` : "";
  const authorizerId =
    values && typeof values.authorizer !== "number"
      ? `${values.authorizer.id}`
      : "";
  // participantはfacility_id以外の情報もくるので整形しておく
  const participantIds = values
    ? values.participant.map((p) => {
        const { id, name } = p;
        return { category_id: 0, id, label: name, is_delete: null };
      })
    : [];

  return {
    creation_date: dateToSelectDateValue(creationDate),
    support_begin_date: dateToSelectDateValue(
      get(values, "support_begin_date") || ""
    ),
    support_end_date: dateToSelectDateValue(
      get(values, "support_end_date") || ""
    ),
    user_request_text: get(values, "user_request_text") || "",
    current_status: get(values, "current_status") || "",
    long_term_goal: get(values, "long_term_goal") || "",
    support_plan_goal: supplementSupportPlanGoal(
      get(values, "support_plan_goal") || []
    ),
    remarks: get(values, "remarks") || "",
    staff_comment: get(values, "staff_comment") || "",
    author: authorId,
    minutes_date: dateToSelectDateValue(minutesDate),
    participant: participantIds,
    minutes: get(values, "minutes") || "",
    status_type: `${get(values, "status_type") || 0}`,
    authorizer: authorizerId
  };
};

/** モニタリングありの場合の短期、長期目標レコードの作成 */
const supplementSupportPlanGoalHasMonitoring = (
  supportPlanGoal: SupportPlanState["privateSupportPlan"]["support_plan_goal"],
  isCopy = false,
  shortTermsNum: number
): RecordSupportPlanValues["support_plan_goal"] => {
  const longTermList = supportPlanGoal.filter(
    (v) => v.sprint_type === SprintType.LONG
  );

  // 長期目標初期値
  let longTerm: RecordSupportPlanValues["support_plan_goal"][number] = {
    sprint_type: SprintType.LONG,
    number: 1,
    sprint_goal: "",
    adopt_info: "",
    support_info: "",
    sprint_result: "",
    sprint_review: "",
    sprint_user_evaluation: "",
    sprint_user_review: "",
    sprint_staff_evaluation: "",
    sprint_staff_review: "",
    user_hope: "",
    necessity_plan_change: ""
  };
  if (longTermList.length > 0) {
    const [_longTerm] = longTermList;
    longTerm = {
      id: _longTerm.id && !isCopy ? _longTerm.id : undefined,
      sprint_type: SprintType.LONG,
      number: 1,
      sprint_goal: _longTerm.sprint_goal,
      adopt_info: _longTerm.adopt_info || "",
      support_info: _longTerm.support_info || "",
      // モニタリング 実施
      sprint_result:
        isCopy || isNil(_longTerm.sprint_result)
          ? ""
          : String(_longTerm.sprint_result),
      // モニタリング 振り返り
      sprint_review: isCopy ? "" : _longTerm.sprint_review || "",
      // モニタリング 本人の評価
      sprint_user_evaluation:
        isCopy || isNil(_longTerm.sprint_user_evaluation)
          ? ""
          : String(_longTerm.sprint_user_evaluation),
      // モニタリング 本人振り返り
      sprint_user_review: isCopy ? "" : _longTerm.sprint_user_review || "",
      // モニタリング 職員からの評価
      sprint_staff_evaluation:
        isCopy || isNil(_longTerm.sprint_staff_evaluation)
          ? ""
          : String(_longTerm.sprint_staff_evaluation),
      // モニタリング 職員振り返り
      sprint_staff_review: isCopy ? "" : _longTerm.sprint_staff_review || "",
      user_hope: isCopy ? "" : _longTerm.user_hope || "",
      necessity_plan_change:
        isCopy || isNil(_longTerm.necessity_plan_change)
          ? ""
          : String(_longTerm.necessity_plan_change)
    };
  }
  const shortTermList = supportPlanGoal.filter(
    (v) => v.sprint_type === SprintType.SHORT
  );
  const shortTerms = Array.from(new Array(shortTermsNum))
    .map((v, i) => i + 1)
    .map<RecordSupportPlanValues["support_plan_goal"][number]>((num) => {
      const term = shortTermList.find((t) => t.number === num);
      if (term) {
        return {
          id: term.id && !isCopy ? term.id : undefined,
          sprint_type: SprintType.SHORT,
          number: term.number,
          sprint_goal: term.sprint_goal,
          adopt_info: term.adopt_info || "",
          support_info: term.support_info || "",
          // モニタリング 実施
          sprint_result:
            isCopy || isNil(term.sprint_result)
              ? ""
              : String(term.sprint_result),
          // モニタリング 振り返り
          sprint_review: isCopy ? "" : term.sprint_review || "",
          // モニタリング 本人の評価
          sprint_user_evaluation:
            isCopy || isNil(term.sprint_user_evaluation)
              ? ""
              : String(term.sprint_user_evaluation),
          // モニタリング 本人振り返り
          sprint_user_review: isCopy ? "" : term.sprint_user_review || "",
          // モニタリング 職員からの評価
          sprint_staff_evaluation:
            isCopy || isNil(term.sprint_staff_evaluation)
              ? ""
              : String(term.sprint_staff_evaluation),
          // モニタリング 職員振り返り
          sprint_staff_review: isCopy ? "" : term.sprint_staff_review || "",
          user_hope: isCopy ? "" : term.user_hope || "",
          necessity_plan_change:
            isCopy || isNil(term.necessity_plan_change)
              ? ""
              : String(term.necessity_plan_change)
        };
      }
      // 短期目標初期値
      return {
        sprint_type: SprintType.SHORT,
        number: num,
        sprint_goal: "",
        adopt_info: "",
        support_info: "",
        sprint_result: "",
        sprint_review: "",
        sprint_user_evaluation: "",
        sprint_user_review: "",
        sprint_staff_evaluation: "",
        sprint_staff_review: "",
        user_hope: "",
        necessity_plan_change: ""
      };
    });
  return [longTerm, ...shortTerms];
};

/** 個別支援計画、モニタリングありの初期値生成処理 */
export const initialValuesHasMonitoring = (
  values?: SupportPlanState["privateSupportPlan"],
  isCopy = false,
  shortTermsNum?: number
): RecordSupportPlanValuesHasMonitoring => {
  const currentDate = dateToLocalisedString(new Date(), "YYYY-MM-DD");

  const creationDate = get(values, "creation_date") || currentDate;
  let minutesDate = get(values, "minutes_date") || "";
  if (!values) {
    // 新規作成の場合にのみ現在日付を入れる
    minutesDate = currentDate;
  }

  // author,authorizerは入力ありなしで型が変わる
  const authorId =
    values && typeof values.author !== "number" ? `${values.author.id}` : "";
  const authorizerId =
    values && typeof values.authorizer !== "number"
      ? `${values.authorizer.id}`
      : "";
  const evaluationAuthorizerId =
    values && typeof values.evaluation_authorizer !== "number"
      ? `${values.evaluation_authorizer.id}`
      : "";
  const participantIds = values
    ? values.participant.map((p) => {
        const { id, name } = p;
        return { category_id: 0, id, label: name, is_delete: null };
      })
    : [];

  return {
    creation_date: dateToSelectDateValue(isCopy ? currentDate : creationDate),
    support_begin_date: dateToSelectDateValue(
      isCopy ? "" : get(values, "support_begin_date") || ""
    ),
    support_end_date: dateToSelectDateValue(
      isCopy ? "" : get(values, "support_end_date") || ""
    ),
    // long_term_goalは使用しない
    long_term_goal: "",
    user_request_text: get(values, "user_request_text") || "",
    current_status: get(values, "current_status") || "",
    support_plan_goal: supplementSupportPlanGoalHasMonitoring(
      get(values, "support_plan_goal") || [],
      isCopy,
      shortTermsNum || 3
    ),
    remarks: get(values, "remarks") || "",
    staff_comment: get(values, "staff_comment") || "",
    author: isCopy ? "" : authorId,
    minutes_date: isCopy
      ? dateToSelectDateValue(dateToLocalisedString(new Date(), "YYYY-MM-DD"))
      : dateToSelectDateValue(minutesDate),
    participant: isCopy ? [] : participantIds,
    minutes: isCopy ? "" : get(values, "minutes") || "",
    status_type: isCopy ? "0" : `${get(values, "status_type") || 0}`,
    authorizer: isCopy ? "" : authorizerId,
    after_summary: isCopy ? "" : get(values, "after_summary") || "",
    revaluation_date: dateToSelectDateValue(
      isCopy ? "" : get(values, "revaluation_date") || ""
    ),
    evaluation_status: isCopy
      ? SUPPORT_PLAN_EVALUATION_STATUS_DEFAULT
      : `${
          get(values, "evaluation_status") ||
          SUPPORT_PLAN_EVALUATION_STATUS_DEFAULT
        }`,
    evaluation_date: dateToSelectDateValue(
      isCopy ? "" : get(values, "evaluation_date") || ""
    ),
    evaluation_minutes: isCopy ? "" : get(values, "evaluation_minutes") || "",
    evaluation_authorizer: isCopy ? "" : evaluationAuthorizerId
  };
};

export default initialValues;
