import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { AppState } from "@stores/type";

// store
import { connect } from "react-redux";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// ui
import Paper from "@material-ui/core/Paper";

// variables
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/mgr/TANKINYUSHO/variables";

const styles = (): StyleRules =>
  createStyles({
    body: {
      paddingBottom: "18px"
    },
    summaryWrap: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    summarySpacing: {
      borderRadius: 4,
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "8px"
    },
    button: {
      minWidth: 142,
      minHeight: 30
    }
  });

type OwnProps = {
  summaries: DailyOperationsAndSupportsState["summary"];
};
type StateProps = {
  customRecords: CustomRecordsState;
};
type Props = OwnProps & StateProps & WithStyles<typeof styles>;

/**
 * 日ごとのサマリーを扱う
 */
const DailyRecordSummary = (props: Props): JSX.Element => {
  const summary: DailyOperationsAndSupportsState["summary"][number] =
    props.summaries[0];

  // 「食事」項目
  const mealData =
    props.customRecords.length > 0 &&
    props.customRecords.find((record) => {
      return (
        record.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
        record.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.meal
      );
    });

  const initialMeal = (mealData && {
    choices: mealData.choices.map((choice) => {
      return { id: choice.id, name: choice.name, count: 0 };
    })
  }) || { choices: [] };
  const initialCounts = {
    status_type: {
      implement: 0
    },
    meal: initialMeal
  };
  const { status_type, meal } = summary ? summary.counts : initialCounts;

  const isExistMealItem = meal.choices.some((choice) => choice.count !== 0);

  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <div className={props.classes.body}>
          <div className={props.classes.summarySpacing}>
            <div className={props.classes.summaryWrap}>
              <div
                className={props.classes.summaryContent}
              >{`サービス提供実施：${status_type.implement} 人`}</div>
            </div>
          </div>

          {mealData && (mealData.visibility === 1 || isExistMealItem) ? (
            <div className={props.classes.summarySpacing}>
              <div className={props.classes.summaryWrap}>
                {mealData.choices &&
                  mealData.choices.map((item) => {
                    const choiceInputData =
                      meal.choices.length > 0 &&
                      meal.choices.find(
                        (choiceData) => choiceData.id === item.id
                      );
                    if (
                      !choiceInputData ||
                      (mealData.visibility === 0 &&
                        choiceInputData.count === 0) ||
                      (item.hidden === 1 && choiceInputData.count === 0)
                    ) {
                      return null;
                    }
                    return (
                      <div
                        className={props.classes.summaryContent}
                        key={choiceInputData.id}
                      >{`${item.name}：${choiceInputData.count} 人`}</div>
                    );
                  })}
              </div>
            </div>
          ) : null}
        </div>
      </Paper>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords
});

export default connect(mapStateToProps)(withStyles(styles)(DailyRecordSummary));
