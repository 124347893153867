import * as types from "@stores/domain/units/types";
import * as actions from "@stores/domain/units/actions";

const initialState: types.UnitsState = {
  optionalCustomInfo: {
    facility_units: [
      {
        id: 0,
        unit_name: "",
        user_ids: []
      }
    ],
    operation_record_flg: {
      use_common_operation_record: 0,
      use_units_operation_record: 0
    }
  }
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.UnitsState => {
  switch (action.type) {
    case types.FETCH_OPTIONAL_CUSTOM_INFO_STARTED:
      return { ...state };
    case types.FETCH_OPTIONAL_CUSTOM_INFO_SUCCESS:
      return { ...state, optionalCustomInfo: action.payload.data };
    case types.FETCH_OPTIONAL_CUSTOM_INFO_FAILED:
      return { ...state };
    case types.POST_USE_COMMON_OPERATION_RECORD_STARTED:
      return { ...state };
    case types.POST_USE_COMMON_OPERATION_RECORD_SUCCESS:
      if (Array.isArray(state.optionalCustomInfo)) {
        return { ...state };
      }
      return {
        ...state,
        optionalCustomInfo: {
          ...state.optionalCustomInfo,
          operation_record_flg: {
            ...state.optionalCustomInfo.operation_record_flg,
            use_common_operation_record: action.payload
          }
        }
      };
    case types.POST_USE_COMMON_OPERATION_RECORD_FAILED:
      return { ...state };
    case types.POST_USE_UNITS_OPERATION_RECORD_STARTED:
      return { ...state };
    case types.POST_USE_UNITS_OPERATION_RECORD_SUCCESS:
      if (Array.isArray(state.optionalCustomInfo)) {
        return { ...state };
      }
      return {
        ...state,
        optionalCustomInfo: {
          ...state.optionalCustomInfo,
          operation_record_flg: {
            ...state.optionalCustomInfo.operation_record_flg,
            use_units_operation_record: action.payload
          }
        }
      };
    case types.POST_USE_UNITS_OPERATION_RECORD_FAILED:
      return { ...state };
    default:
      return state;
  }
};

export default reducer;
