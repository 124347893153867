import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { UsersSummarySupportPrint } from "@components/organisms/mgr/Cseg/record/UsersSummarySupportPrint";

/**
 * 利用者ごと一括支援記録のプリント画面
 */

type StateProps = {
  userState: UserState;
};

type Props = StateProps &
  RouteComponentProps<{
    year: string;
    month: string;
  }>;

/**
 * 利用者ごと画面上の支援記録のプリント画面
 */
const RecordUsersSummarySupportPrintCore = (props: Props): JSX.Element => {
  const { year, month } = props.match.params;
  const query = props.location.search;

  const pageName = "支援記録";
  // `利用者`は設定で変更可能
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";
  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" }
  ];

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <UsersSummarySupportPrint year={year} month={month} query={query} />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user
});

export const RecordUsersSummarySupportPrint = connect(mapStateToProps)(
  RecordUsersSummarySupportPrintCore
);
