import React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ErrorIcon from "@material-ui/icons/Error";

const styles = (): StyleRules =>
  createStyles({
    wrap: {
      whiteSpace: "normal"
    },
    title: {
      fontWeight: 500,
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      margin: 0,
      "& + $title": {
        marginTop: "6px"
      }
    },
    cautionWrapper: {
      listStyle: "none",
      padding: 0,
      margin: "16px 0 32px",
      fontSize: "14px",
      lineHeight: 1.43,
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)"
    },
    cautionList: {
      display: "flex",
      "& + $cautionList": {
        marginTop: "16px"
      }
    },
    alertIconUpper: {
      width: 17,
      height: 17,
      color: "#ff5656",
      top: 2,
      left: -2,
      position: "relative",
      marginRight: 3
    }
  });

type Props = WithStyles<typeof styles>;

const PasswordCautionCore = (props: Props): JSX.Element => {
  const { classes } = props;

  return (
    <div className={classes.wrap}>
      <p className={classes.title}>【ご注意ください】</p>
      <p className={classes.title}>
        「変更を確定する」ボタンを押下すると、本アカウントでログインしている全ての端末が強制的にログアウトされます。再度ログインするためには、ここで設定した新しいパスワードが必要となります。
      </p>
      <ul className={classes.cautionWrapper}>
        <li className={classes.cautionList}>
          <ErrorIcon className={classes.alertIconUpper} />
          <div className={classes.subTextSmall}>
            強制ログアウトされると、入力作業中の画面の内容は保存できず、消去されます。本アカウントを使用する方には事前にパスワード変更の日時を周知するなど、業務に影響のないようご対応をお願いします。
          </div>
        </li>
        <li className={classes.cautionList}>
          <ErrorIcon className={classes.alertIconUpper} />
          <div className={classes.subTextSmall}>
            新しいパスワードは、今後も本アカウントを使用する方のみに伝達し、退職者等に伝わることのないようご注意ください。
          </div>
        </li>
      </ul>
    </div>
  );
};

export const PasswordCaution = withStyles(styles)(PasswordCautionCore);
