import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import UsersListTemplate from "@components/templates/UsersListTemplate";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import { Theme } from "@material-ui/core/styles/createMuiTheme";

import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import ErrorsDialog from "@components/organisms/ErrorsDialog";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    container: {
      padding: spacing.unit * 2
    },
    newButtonLink: {
      textDecoration: "none",
      marginLeft: "auto"
    },
    newButton: {
      boxShadow: "none",
      borderRadius: 4,
      width: 120
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between"
    },
    countText: {
      fontSize: 12
    },
    paperContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px`,
      margin: spacing.unit * 2
    }
  });

type DispatchProps = {
  fetchUser: () => void;
  fetchUsersInFacility: () => void;
  fetchUsersError: () => void;
};

type State = {
  user: UserState;
  facilityUsers: GetFacilityUsersResponse["data"];
};

type Props = WithStyles<typeof styles> & State & DispatchProps;

/**
 * 利用者情報
 */
class UsersList extends React.Component<Props> {
  public componentDidMount(): void {
    if (!this.props.user.name) this.props.fetchUser();
    this.props.fetchUsersInFacility();
    this.props.fetchUsersError();
  }

  public render(): JSX.Element {
    return (
      <AdminTemplate pageName="利用者情報">
        <UsersListTemplate
          user={this.props.user}
          facilityUsers={this.props.facilityUsers}
        />
        <ErrorsDialog errorsKey="users" />
      </AdminTemplate>
    );
  }
}

const mapStateToProps = (state: AppState): State => ({
  user: state.user,
  facilityUsers: state.TANKINYUSHO.userInFacility.users
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, TANKINYUSHO, errorsDispatcher } = dispatches;
  const userInFacilityDispatched = TANKINYUSHO.userInFacilityDispatcher(
    dispatch
  );

  return {
    fetchUser: userDispatch(dispatch).me,
    fetchUsersInFacility: userInFacilityDispatched.fetch,
    fetchUsersError: errorsDispatcher(dispatch).users
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsersList));
