import { InitialDataValues } from "@initialize/v202104/mgr/CHIIKITEICHAKU/report/initialValues";
import validator from "@validator";
import { InitialErrors } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/report/initial";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (
    validationResult !== undefined &&
    validationResult.initial.inoutConsultationResults !== undefined
  ) {
    const undefinedDataList = validationResult.initial.inoutConsultationResults.filter(
      (x) => x !== undefined && x.memo !== undefined
    );
    return undefinedDataList.length > 0;
  }
  return false;
};

const memoValidation = (values: InitialDataValues): InitialErrors => {
  const validation = values.initial.inoutConsultationResults.map((inout) => {
    return {
      memo: validator(inout.memo, {
        type: "checkCharacterLength",
        length: 50
      })
    };
  });
  return {
    initial: {
      inoutConsultationResults: validation
    }
  };
};

export const validation = (values: InitialDataValues): InitialDataErrors => {
  const memoErrors = memoValidation(values);

  return {
    initial: {
      ...memoErrors.initial
    }
  };
};
