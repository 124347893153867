import * as React from "react";
// store
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
// ui
import FormGroup from "@material-ui/core/FormGroup";
import { FormPaper } from "@componentsMobile/atoms/FormPaper";
import { FormikSelectDayOnly } from "@components/molecules/FormikSelectDayOnly";
// formik
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { FormikProps, setNestedObjectValues } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
import {
  validation,
  ServiceDeliveryDetailErrors
} from "@initialize/record/serviceDelivery/validation";
// variables
import { KYOTAKUKAIGO_STATUS_LIST } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { FacilityType, MEMBER_LIST_SERVICE_DETAIL } from "@constants/variables";
// utils
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import { toEffectiveObject } from "@utils/object";
import merge from "lodash-es/merge";
import { createStatusOptions } from "@utils/domain/serviceDelivery/createStatusOptions";
// hooks
import { useAccompanyDisplayFlg } from "@hooks/record/serviceDelivery/useAccompanyDisplayFlg";
import {
  IDOSHIEN_GROUP_MEMBER_SELECT_LIST,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import FormikTextField from "@components/molecules/FormikTextField";
import { ServiceDeliveryOnlyTimeContainer } from "./ServiceDeliveryOnlyTimeContainer";
import castNumber from "@utils/dataNormalizer/castNumber";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

type Props = {
  isNew: boolean;
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  setNumberOfPractitioner: (num: number) => void;
  isInfoOpen: boolean;
  setIsInfoOpen: (bool: boolean) => void;
  targetDate: string;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  facilityType: FacilityType;
  serviceDeliveryUser: ServiceDeliveryState["user"];
};

export const ServiceDeliveryBasicFieldMonthly = (props: Props): JSX.Element => {
  const { values: formikValues } = props.formikProps;
  // 種別ごとのサービス内容選択肢指定
  const statusOptions = createStatusOptions(props.facilityType);

  React.useEffect(() => {
    // グループ支援の場合、支援比率の計算
    if (formikValues.status !== IDOSHIEN_STATUS_LIST.GROUP.value) return;
    if (
      !formikValues.numberOfPractitioner ||
      !formikValues.numberOfSupporters
    ) {
      props.formikProps.setFieldValue("supportRatioPerPractitioner", "");
    } else {
      const praNum = castNumber(formikValues.numberOfPractitioner);
      const supNum = castNumber(formikValues.numberOfSupporters);
      const result = Math.round((supNum / praNum) * 100) / 100;
      props.formikProps.setFieldValue("supportRatioPerPractitioner", result);
    }
  }, [formikValues.numberOfPractitioner, formikValues.numberOfSupporters]);

  const onChangeHookStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    if (e.target.value !== KYOTAKUKAIGO_STATUS_LIST.NONE.value) {
      props.setIsInfoOpen(true);
    } else {
      props.setIsInfoOpen(false);
    }
    // 移動支援では提供人数がサービスごとに異なるため、毎回リセットする
    if (props.facilityType === FacilityType.IDOSHIEN) {
      props.setFormikFieldValue(
        "numberOfPractitioner",
        IDOSHIEN_MEMBER_LIST.ONE.value
      );
      props.setNumberOfPractitioner(Number(IDOSHIEN_MEMBER_LIST.ONE.value));
    }
  };

  const onChangeHookNumber = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    props.setNumberOfPractitioner(Number(e.target.value));
    // 提供人数を「1」にしたとき，サービス提供記録２のエラーのある項目を空にする
    const validationResult = validation(
      props.formikProps.values,
      props.facilityType
    );
    const error = toEffectiveObject(
      validationResult
    ) as ServiceDeliveryDetailErrors;
    if (
      e.target.value === MEMBER_LIST_SERVICE_DETAIL[0].value &&
      error &&
      error.serviceDeliveryRecordPractitioners2
    ) {
      // エラーの項目を空にする
      const errorToBlackItem = setNestedObjectValues<
        ServiceDeliveryDetailErrors
      >(error, "");
      const mergedServiceDeliveryRecordPractitioners2 = merge(
        {},
        props.formikProps.values.serviceDeliveryRecordPractitioners2,
        errorToBlackItem.serviceDeliveryRecordPractitioners2
      );
      props.formikProps.setValues({
        ...props.formikProps.values,
        serviceDeliveryRecordPractitioners2: mergedServiceDeliveryRecordPractitioners2
      });
    }
  };

  // 同行支援の表示判定(重度のみ)
  const accompanySupportDisplayFlg =
    props.facilityType === FacilityType.JUDOHOMONKAIGO
      ? useAccompanyDisplayFlg(
          props.isNew,
          props.formikProps.values,
          props.serviceDeliveryUser
        )
      : false;

  // 移動支援の支援人数の項目で全角数字->半角数字にする
  const formatNumberOfSupporters = (value: string): string => {
    const numberOfSupporters = value
      .replace(/，|,/g, "")
      .replace(/[０-９]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );

    const numberOfSupportersValue = Number(numberOfSupporters);
    if (Number.isNaN(numberOfSupportersValue) || !numberOfSupporters) {
      return value.replace(/[０-９，]/g, (s) =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    }
    return numberOfSupportersValue.toLocaleString();
  };

  const handleBlurSupporters = (
    event: React.FormEvent<HTMLInputElement>
  ): string | void => {
    return formatNumberOfSupporters(event.currentTarget.value);
  };

  return (
    <FormPaper>
      {!formikValues.serviceDeliveryRecordsId &&
        !formikValues.inoutResultsId && (
          <FormikSelectDayOnly
            isMobile
            name="targetDate"
            label="サービス提供日"
          />
        )}
      <FormGroup>
        {statusOptions.length > 0 && (
          <FormikSelect
            size="fullSize"
            isMobile
            readOnly={!props.isEdit}
            name="status"
            label="サービス内容"
            options={statusOptions}
            onChangeHook={onChangeHookStatus}
          />
        )}
        {props.facilityType === FacilityType.IDOSHIEN &&
          formikValues.status === IDOSHIEN_STATUS_LIST.OTHER.value && (
            <FormikTextField
              isMobile
              readOnly={!props.isEdit}
              name="statusText"
              size="fullSize"
              maxLength={20}
            />
          )}
        {props.isInfoOpen && (
          <>
            {props.facilityType === FacilityType.IDOSHIEN ? (
              <FormikCheckbox
                isMobile
                readOnly={!props.isEdit}
                highEmphasis
                name="physicalCareFlg"
                label="身体介護"
                labelStyle={{ fontSize: 16 }}
              />
            ) : (
              <>
                <FormikCheckbox
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  name="emergencySupportFlg"
                  label="緊急時対応"
                  labelStyle={{ fontSize: 16 }}
                />
                <FormikCheckbox
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  name="sputumImplementationFlg"
                  label="喀痰吸引等実施"
                  labelStyle={{ fontSize: 16 }}
                />
                {props.facilityType === FacilityType.JUDOHOMONKAIGO && (
                  <FormikCheckbox
                    isMobile
                    name="emergencyGuideSupportFlg"
                    readOnly={!props.isEdit}
                    highEmphasis
                    label="移動介護緊急時支援"
                    labelStyle={{ fontSize: 16 }}
                  />
                )}
                {new Date(2023, 3, 1) <=
                  new Date(fixDateAndTimeFormat(props.targetDate)) && (
                  <FormikCheckbox
                    isMobile
                    readOnly={!props.isEdit}
                    highEmphasis
                    name="bodyRestrictedStillFlg"
                    label="身体拘束廃止未実施"
                    labelStyle={{ fontSize: 16 }}
                  />
                )}
              </>
            )}
            {props.facilityType === FacilityType.IDOSHIEN &&
            formikValues.status === IDOSHIEN_STATUS_LIST.GROUP.value ? (
              <>
                <FormGroup row>
                  <FormikSelect
                    isMobile
                    size="mobileHalf"
                    readOnly={!props.isEdit}
                    name="numberOfPractitioner"
                    label="提供人数"
                    options={IDOSHIEN_GROUP_MEMBER_SELECT_LIST}
                    onChangeHook={onChangeHookNumber}
                  />
                  <FormikTextField
                    isMobile
                    size="mobileHalf"
                    readOnly={!props.isEdit}
                    name="numberOfSupporters"
                    label="支援人数"
                    endAdornmentLabel="人"
                    maxLength={3}
                    style={{ marginLeft: 16 }}
                    onBlurHook={handleBlurSupporters}
                  />
                </FormGroup>
                <ReadonlyTextField
                  value={
                    formikValues.supportRatioPerPractitioner
                      ? `1:${formikValues.supportRatioPerPractitioner}`
                      : "-"
                  }
                  defaultValue="-"
                  size="mobileHalf"
                  style={{ whiteSpace: "nowrap" }}
                />
              </>
            ) : (
              <FormikSelect
                isMobile
                size="mobileHalf"
                readOnly={!props.isEdit}
                name="numberOfPractitioner"
                label="提供人数"
                options={MEMBER_LIST_SERVICE_DETAIL}
                onChangeHook={onChangeHookNumber}
                style={{ marginBottom: 0, marginTop: 6 }}
              />
            )}
            {accompanySupportDisplayFlg && (
              <FormikCheckbox
                name="accompanySupportFlg"
                isMobile
                readOnly={!props.isEdit}
                highEmphasis
                label="同行支援"
                style={{ margin: "27px 0px 4px" }}
              />
            )}
            {props.facilityType === FacilityType.IDOSHIEN &&
              formikValues.status !== IDOSHIEN_STATUS_LIST.GROUP.value &&
              formikValues.status !==
                IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value && (
                <FormikCheckbox
                  name="licenseSameFlg"
                  isMobile
                  readOnly={!props.isEdit}
                  highEmphasis
                  disabled={formikValues.numberOfPractitioner === "1"}
                  label="2人が同時にサービスを提供する"
                  labelStyle={{
                    color: `${
                      formikValues.numberOfPractitioner === "1"
                        ? "rgba(0, 0, 0, 0.26)"
                        : ""
                    }`
                  }}
                  style={{ margin: "27px 0px 4px" }}
                />
              )}
            {props.facilityType === FacilityType.IDOSHIEN &&
              (formikValues.status ===
                IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value ||
                formikValues.licenseSameFlg) && (
                <ServiceDeliveryOnlyTimeContainer
                  isEdit={props.isEdit}
                  formikProps={props.formikProps}
                  setFormikFieldValue={props.formikProps.setFieldValue}
                  facilityType={props.facilityType}
                  practitionerValues={
                    formikValues.serviceDeliveryRecordPractitioners1
                  }
                />
              )}
          </>
        )}
      </FormGroup>
    </FormPaper>
  );
};
