import { BasicValues } from "@interfaces/mgr/KEIKAKUSODAN/record/basicInfo";
import { FieldItem } from "@interfaces/ui/form";
import { BasicInfoState } from "@stores/domain/mgr/KEIKAKUSODAN/basicInfo/types";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import {
  dateToLocalisedString,
  dateToObject,
  dateToSelectDateValue
} from "@utils/date";
import { get } from "lodash-es";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import getAge from "@utils/date/getAge";
import { basicInfoSchedulesValue } from "./validation";
import {
  findUserInFacilityKeikakusodan,
  findUserInFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/userInFacility";
import {
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

export type BasicInfoValues = BasicValues;

const findTargetUserInFacility = (
  facilityType: number | null,
  user: UsersInFacilityState["user"],
  constitutionType: number
):
  | ReturnType<typeof findUserInFacilityKeikakusodan>
  | ReturnType<typeof findUserInFacilityShogaijisodan> => {
  switch (facilityType) {
    case FACILITY_TYPE_KEIKAKUSODAN:
      return findUserInFacilityKeikakusodan(user);
    case FACILITY_TYPE_SHOGAIJISODAN:
      return findUserInFacilityShogaijisodan(user);
    case FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN:
      return constitutionType === FACILITY_TARGET_TYPE_KEIKAKUSODAN
        ? findUserInFacilityKeikakusodan(user)
        : findUserInFacilityShogaijisodan(user);
    default:
      return undefined;
  }
};

const basicInfoInitialValue = (
  facilityType: number | null,
  user?: UsersInFacilityState["user"],
  constitutionType?: number
): BasicInfoValues => {
  const userInFacility = user ? user.user_in_facility : {};

  const targetUserInFacility =
    user && constitutionType
      ? findTargetUserInFacility(facilityType, user, constitutionType)
      : undefined;

  const disabilityClass = targetUserInFacility
    ? `${targetUserInFacility.disability_class}`
    : "0";

  return {
    basic_info_id: null,
    created_at: null,
    updated_at: null,
    creation_date: dateToObject(new Date()),
    author: "0",
    support_progress: "",
    date_birth: dateToSelectDateValue(
      undefinedToReturnValue(userInFacility.date_birth)
    ),
    age: undefinedToReturnValue(getAge(userInFacility.date_birth)),
    postal_code: undefinedToReturnValue(userInFacility.postal_code),
    city_id: undefinedToReturnValue(userInFacility.city_id),
    prefecture_name: undefinedToReturnValue(userInFacility.prefecture_name),
    address: undefinedToReturnValue(userInFacility.address),
    residence: "1",
    residence_other: "",
    tel: undefinedToReturnValue(userInFacility.tel),
    fax: "",
    disability_name: "",
    classify_disability_support: disabilityClass,
    gender: undefinedToReturnValue(userInFacility.gender),
    family_structure: "",
    social_relationship: "",
    life_history: "",
    medical_situation: "",
    self_complaint: "",
    family_complaint: "",
    public_support: {
      basic_info_details_id: null,
      name: "",
      offer_name: "",
      support_contents: "",
      frequency: "",
      memo: ""
    },
    other_support: {
      basic_info_details_id: null,
      name: "",
      offer_name: "",
      support_contents: "",
      frequency: "",
      memo: ""
    },
    schedule: [basicInfoSchedulesValue],
    main_activity: "",
    non_weekly_service: "",
    type_consultation: constitutionType
  };
};

export const initialValues = (
  facilityType: number | null,
  state?: BasicInfoState["basicInfo"],
  user?: UsersInFacilityState["user"],
  staffOptions?: FieldItem[],
  isCopy?: boolean,
  isNew?: boolean,
  consultationType?: number
): BasicInfoValues => {
  if (!state || !user || isNew) {
    const values = basicInfoInitialValue(facilityType, user, consultationType);
    return values;
  }

  const basic_info = state ? state.basic_info : {};
  const userInFacility = user ? user.user_in_facility : {};
  const schedule = state ? state.basic_info.schedule : [];
  const author = get(basic_info, "author");
  const age = isCopy
    ? getAge(userInFacility.date_birth)
    : get(basic_info, "age");

  let authorId = null;
  if (staffOptions && basic_info && author) {
    const authorIsDelete = staffOptions.every(
      (option) => author && option.value !== String(author.id)
    );
    if (isCopy) {
      authorId = null;
    } else {
      authorId =
        author.name !== author.snapshot_name && !authorIsDelete ? 0 : author.id;
    }
  }

  return {
    basic_info_id: isCopy ? null : get(basic_info, "basic_info_id"),
    created_at: undefinedToReturnValue(get(state, "created_at")),
    updated_at: undefinedToReturnValue(get(state, "updated_at")),
    creation_date: isCopy
      ? dateToObject(new Date())
      : dateToSelectDateValue(
          undefinedToReturnValue(get(basic_info, "creation_date"))
        ),
    author: authorId !== null ? `${authorId}` : authorId,
    support_progress: undefinedToReturnValue(
      get(basic_info, "support_progress")
    ),
    date_birth: dateToSelectDateValue(
      undefinedToReturnValue(
        isCopy ? userInFacility.date_birth : get(basic_info, "date_birth")
      )
    ),
    age: undefinedToReturnValue(age),
    postal_code: undefinedToReturnValue(
      isCopy ? userInFacility.postal_code : get(basic_info, "postal_code")
    ),
    city_id: undefinedToReturnValue(
      isCopy ? userInFacility.city_id : get(basic_info, "city_id")
    ),
    prefecture_name: undefinedToReturnValue(
      isCopy
        ? userInFacility.prefecture_name
        : get(basic_info, "prefecture_name")
    ),
    address: undefinedToReturnValue(
      isCopy ? userInFacility.address : get(basic_info, "address")
    ),
    residence: undefinedToReturnValue(get(basic_info, "residence")),
    residence_other: undefinedToReturnValue(get(basic_info, "residence_other")),
    tel: undefinedToReturnValue(
      isCopy ? userInFacility.tel : get(basic_info, "tel")
    ),
    fax: undefinedToReturnValue(isCopy ? "" : get(basic_info, "fax")),
    disability_name: undefinedToReturnValue(
      isCopy ? "" : get(basic_info, "disability_name")
    ),
    classify_disability_support: undefinedToReturnValue(
      isCopy
        ? get(user, "user_in_facility_keikakusodan.disability_class")
        : get(basic_info, "classify_disability_support")
    ),
    gender: undefinedToReturnValue(
      isCopy ? userInFacility.gender : get(basic_info, "gender")
    ),
    family_structure: undefinedToReturnValue(
      get(basic_info, "family_structure")
    ),
    social_relationship: undefinedToReturnValue(
      get(basic_info, "social_relationship")
    ),
    life_history: undefinedToReturnValue(get(basic_info, "life_history")),
    medical_situation: undefinedToReturnValue(
      get(basic_info, "medical_situation")
    ),
    self_complaint: undefinedToReturnValue(get(basic_info, "self_complaint")),
    family_complaint: undefinedToReturnValue(
      get(basic_info, "family_complaint")
    ),
    public_support: {
      basic_info_details_id: basic_info
        ? get(basic_info, "public_support.basic_info_details_id")
        : null,
      name: undefinedToReturnValue(get(basic_info, "public_support.name")),
      offer_name: undefinedToReturnValue(
        get(basic_info, "public_support.offer_name")
      ),
      support_contents: undefinedToReturnValue(
        get(basic_info, "public_support.support_contents")
      ),
      frequency: undefinedToReturnValue(
        get(basic_info, "public_support.frequency")
      ),
      memo: undefinedToReturnValue(get(basic_info, "public_support.memo"))
    },
    other_support: {
      basic_info_details_id: basic_info
        ? get(basic_info, "other_support.basic_info_details_id")
        : null,
      name: undefinedToReturnValue(get(basic_info, "other_support.name")),
      offer_name: undefinedToReturnValue(
        get(basic_info, "other_support.offer_name")
      ),
      support_contents: undefinedToReturnValue(
        get(basic_info, "other_support.support_contents")
      ),
      frequency: undefinedToReturnValue(
        get(basic_info, "other_support.frequency")
      ),
      memo: undefinedToReturnValue(get(basic_info, "other_support.memo"))
    },
    schedule:
      schedule.length > 0
        ? schedule.map((v) => ({
            basic_info_schedules_id: v.basic_info_schedules_id,
            day_of_week: v.day_of_week,
            start_time: undefinedToReturnValue(
              get(v, "start_time")
                ? dateToLocalisedString(get(v, "start_time"), "HH:mm")
                : null
            ),
            next_day_flg: undefinedToReturnValue(get(v, "next_day_flg")),
            end_time: undefinedToReturnValue(
              get(v, "end_time")
                ? dateToLocalisedString(get(v, "end_time"), "HH:mm")
                : null
            ),
            content: undefinedToReturnValue(get(v, "content")),
            is_delete: false
          }))
        : [],
    main_activity: undefinedToReturnValue(get(basic_info, "main_activity")),
    non_weekly_service: undefinedToReturnValue(
      get(basic_info, "non_weekly_service")
    ),
    ...(isCopy && { type_consultation: consultationType })
  };
};
