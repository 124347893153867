import * as React from "react";
import * as URL from "@constants/url";

// UI
import {
  createStyles,
  StyleRules,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Formik, FormikActions } from "formik";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { SupportProcedureForm } from "@components/organisms/mgr/KODOENGO/record/SupportProcedureForm";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { UsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";

import { FacilityType, PLAN_LIST_TAB_PATH } from "@constants/variables";
import { dateInHyphenYYYYMMDDFormat } from "@utils/date/dateInAnyFormat";
import { dateToSelectDateValue } from "@utils/date/dateToSelectDateValue";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import {
  checkErrorIsValidation,
  createPostValidationError
} from "@utils/domain/mgr/KODOENGO/supportProcedureErrorValidation";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";
import { dateTodayForAttendanceHeaderForDetailDaily } from "@utils/date";
import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { TARGET_FLG, TARGET_PATH } from "@constants/mgr/KODOENGO/variables";
import { getUrlParams } from "@utils/url";
import {
  initialFormValues,
  initialValuesEdit,
  initialValuesNew,
  SupportProcedureDetailFormValues
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { validate } from "@initialize/mgr/KODOENGO/record/supportProcedure/validation";
import deepEqual from "fast-deep-equal";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import {
  TargetObject,
  useLocalStorage
} from "@hooks/record/supportProcedure/useLocalStorage";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import {
  ActionTypes,
  clearSupportProcedureDetailState
} from "@stores/domain/mgr/KODOENGO/supportProcedure/actions";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: palette.background.default,
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    planListWrapper: {
      marginTop: "32px",
      background: "#fff",
      borderRadius: "4px"
    },
    cancelButton: {
      marginRight: 8
    }
  });

type OwnProps = RouteComponentProps<{ uifId: string }> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UsersInFacilityState["user"];
  staff: StaffState;
  facilityType: FacilityType;
  supportProcedureDetail: GetSupportProcedureDetailResponse["data"];
  needsStopHistory: boolean;
};

type DispatchProps = {
  fetchStaff: () => Promise<void>;
  fetchOneUser: (id: string) => void;
  fetchSupportProcedureDetail: (
    serviceDeliveryRecordsId?: string,
    inoutResultsId?: string,
    supportProcedureFormsId?: string
  ) => void;
  postSupportProcedureDetail: (
    formValues: SupportProcedureDetailFormValues,
    staff: StaffState,
    apiResponse: GetSupportProcedureDetailResponse["data"],
    targetPath: keyof typeof TARGET_FLG,
    targetObject: TargetObject[],
    uifId: number,
    queryTargetDate: string | undefined,
    originSupportProcedureFormsId?: string
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
  clearSupportProcedureDetailState: () => void;
};

type MergeProps = {
  staffOptions: FieldItem[];
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

type SupportProcedureDetailQuery = {
  serviceDeliveryRecordsId?: string;
  inoutResultsId?: string;
  supportProcedureFormsId?: string;
  targetDate?: string;
  originSupportProcedureFormsId?: string;
};

const SupportProcedureDetailCore = (props: Props): JSX.Element => {
  const { classes, history } = props;
  const { user, facilityType, supportProcedureDetail } = props;
  const {
    fetchStaff,
    fetchOneUser,
    fetchSupportProcedureDetail,
    postSupportProcedureDetail
  } = props;
  const { userName, staffOptions, staff } = props;
  const { uifId } = props.match.params;
  const { pathname, search } = props.location;
  const {
    targetDate,
    originSupportProcedureFormsId,
    ...rest
  }: SupportProcedureDetailQuery = getUrlParams(search);

  const [target, setTarget] = React.useState<keyof typeof TARGET_FLG>(
    TARGET_PATH.new
  );
  const [formValues, setFormValues] = React.useState(initialFormValues);

  const { getTargetObject, resetTargetObject } = useLocalStorage();
  const targetObject = getTargetObject();

  const today = React.useMemo(() => {
    const date = dateInHyphenYYYYMMDDFormat(new Date());
    return dateToSelectDateValue(date);
  }, []);

  React.useEffect(() => {
    if (pathname.indexOf(TARGET_PATH.new) !== -1) {
      setTarget(TARGET_PATH.new);
    }
    if (pathname.indexOf(TARGET_PATH.copy) !== -1) {
      setTarget(TARGET_PATH.copy);
    }
    if (pathname.indexOf(TARGET_PATH.edit) !== -1) {
      setTarget(TARGET_PATH.edit);
    }
    if (pathname.indexOf(TARGET_PATH.setDate) !== -1) {
      setTarget(TARGET_PATH.setDate);
    }
  }, [pathname]);

  React.useEffect(() => {
    fetchOneUser(uifId);
    fetchStaff();

    switch (target) {
      case TARGET_PATH.new: {
        // 新規の場合は、一覧から単独で選択した場合のみAPIを叩く
        if (targetObject.length === 1) {
          const {
            serviceDeliveryRecordsId,
            inoutResultsId,
            supportProcedureFormsId
          } = targetObject[0];
          if (
            serviceDeliveryRecordsId ||
            inoutResultsId ||
            supportProcedureFormsId
          ) {
            fetchSupportProcedureDetail(
              serviceDeliveryRecordsId
                ? `${serviceDeliveryRecordsId}`
                : undefined,
              inoutResultsId ? `${inoutResultsId}` : undefined,
              supportProcedureFormsId ? `${supportProcedureFormsId}` : undefined
            );
          }
        }
        break;
      }
      case TARGET_PATH.copy:
      case TARGET_PATH.edit:
      case TARGET_PATH.setDate: {
        const {
          serviceDeliveryRecordsId,
          inoutResultsId,
          supportProcedureFormsId
        } = rest;
        fetchSupportProcedureDetail(
          serviceDeliveryRecordsId,
          inoutResultsId,
          supportProcedureFormsId
        );
        break;
      }
      default:
    }
  }, [search, target]);

  React.useEffect(() => {
    switch (target) {
      case TARGET_PATH.new: {
        // 新規の場合は、一覧から単独で選択した場合のみAPIを叩く
        if (targetObject.length === 1) {
          const {
            serviceDeliveryRecordsId,
            inoutResultsId,
            supportProcedureFormsId
          } = targetObject[0];
          if (
            serviceDeliveryRecordsId ||
            inoutResultsId ||
            supportProcedureFormsId
          ) {
            setFormValues(
              initialValuesNew(supportProcedureDetail, false, staffOptions)
            );
          }
        }
        break;
      }
      case TARGET_PATH.copy:
        setFormValues(
          initialValuesNew(supportProcedureDetail, true, staffOptions)
        );
        break;
      case TARGET_PATH.edit:
      case TARGET_PATH.setDate:
        setFormValues(initialValuesEdit(supportProcedureDetail, staffOptions));
        break;
      default:
        break;
    }
  }, [target, supportProcedureDetail]);

  React.useEffect(() => {
    return (): void => {
      // state初期化
      props.clearSupportProcedureDetailState();
    };
  }, []);

  const confirmDiscardFormChanges = (
    nextValues: SupportProcedureDetailFormValues
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    props.stopHistory(hasChange);
  };

  const validateForm = (
    value: SupportProcedureDetailFormValues
  ): void | object => {
    const validationResult = validate(value);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(value);
    }
    return error;
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onSubmit = async (
    values: SupportProcedureDetailFormValues,
    actions: FormikActions<SupportProcedureDetailFormValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postSupportProcedureDetail(
      values,
      staff,
      supportProcedureDetail,
      target,
      targetObject,
      Number(uifId),
      targetDate,
      originSupportProcedureFormsId
    )
      .then(() => {
        resetTargetObject();
        props.stopHistory(false);

        const yearMonth = targetDate
          ? targetDate.split("-").slice(0, 2).join("")
          : `${today.year}${today.month.padStart(2, "0")}`;

        props.history.push(
          `${URL.RECORD_SUPPORT_PLAN}/${uifId}/support_procedure/list/${yearMonth}`
        );
      })
      .catch((e) => {
        if (checkErrorIsValidation(e)) {
          const error = createPostValidationError(e);
          actions.setErrors(error);
        }
      })
      .finally(() => actions.setSubmitting(false));
  };

  const onClickCancel = (): void => {
    const yearMonth = targetDate
      ? targetDate.split("-").slice(0, 2).join("")
      : `${today.year}${today.month.padStart(2, "0")}`;

    props.history.push(
      `${URL.RECORD_SUPPORT_PLAN}/${uifId}/support_procedure/list/${yearMonth}`
    );
  };

  const yearMonth = targetDate
    ? targetDate.split("-").slice(0, 2).join("")
    : `${today.year}${today.month.padStart(2, "0")}`;

  const pageName = "支援手順書 兼 記録用紙";
  const pathList = [
    { pathName: "支援計画", path: `${URL.RECORD_SUPPORT_PLAN}/users_summary` },
    {
      pathName: `${pageName}一覧`,
      path: `${URL.RECORD_SUPPORT_PLAN}/${uifId}/support_procedure/list/${yearMonth}`
    }
  ];

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <CarePlanUserInfo user={user} />
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validateForm}
          enableReinitialize
        >
          {(formikProps): JSX.Element => (
            <div className={classes.planListWrapper}>
              {/* タブヘッダー */}
              <PlanListHeader
                pageName={pageName}
                userName={userName}
                displayDate={
                  targetDate &&
                  dateTodayForAttendanceHeaderForDetailDaily(
                    new Date(targetDate)
                  )
                }
                uifId={uifId}
                recordType={PLAN_LIST_TAB_PATH.SUPPORT_PROCEDURE}
                isEditing={false}
                history={history}
                facilityType={facilityType}
                button={
                  <div>
                    <KnowbeButton
                      kind="outline"
                      onClick={onClickCancel}
                      minWidth={120}
                      className={classes.cancelButton}
                    >
                      キャンセル
                    </KnowbeButton>
                    <FormikSubmitButton
                      buttonName="保存する"
                      minWidth={120}
                      formikProps={formikProps}
                      errorAction={submitError}
                    />
                  </div>
                }
              />
              {/* フォーム */}
              <SupportProcedureForm
                supportProcedureDetail={supportProcedureDetail}
                staffOptions={staffOptions}
                formikProps={formikProps}
                targetObject={targetObject}
                staff={props.staff}
                target={target}
              />
            </div>
          )}
        </Formik>
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.KODOENGO.userInFacility.user,
  staff: state.staff,
  facilityType: state.user.facility_type,
  supportProcedureDetail:
    state.KODOENGO.supportProcedure.supportProcedureDetail,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KODOENGO } = dispatches;
  const uiDispatcher = dispatches.uiDispatch(dispatch);

  return {
    fetchStaff: KODOENGO.staffDispatcher(dispatch).fetch,
    fetchOneUser: (id: string): void => {
      KODOENGO.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportProcedureDetail: (
      serviceDeliveryRecordsId?: string,
      inoutResultsId?: string,
      supportProcedureFormsId?: string
    ): void => {
      KODOENGO.supportProcedureDispatcher(dispatch).fetchSupportProcedureDetail(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      );
    },
    stopHistory: uiDispatcher.stopHistory,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatcher.snackbar(params),
    postSupportProcedureDetail: (
      formValues: SupportProcedureDetailFormValues,
      staff: StaffState,
      apiResponse: GetSupportProcedureDetailResponse["data"],
      targetPath: keyof typeof TARGET_FLG,
      targetObject: TargetObject[],
      uifId: number,
      queryTargetDate: string | undefined,
      originSupportProcedureFormsId?: string
    ): Promise<void> => {
      return KODOENGO.supportProcedureDispatcher(
        dispatch
      ).postSupportProcedureDetail(
        formValues,
        staff,
        apiResponse,
        targetPath,
        targetObject,
        uifId,
        queryTargetDate,
        originSupportProcedureFormsId
      );
    },
    clearSupportProcedureDetailState: (): ActionTypes =>
      dispatch(clearSupportProcedureDetailState())
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, user } = stateProps;

  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  return {
    staffOptions,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportProcedureDetail = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SupportProcedureDetailCore)
);
