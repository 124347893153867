import {
  InoutResultsState,
  InoutResultsDetailsState,
  ServiceCodesState,
  PractitionerStaffState
} from "@stores/domain/mgr/IDOSHIEN/report/types";
import {
  InitialValues,
  InoutResultsDetailsFields,
  ServiceCodesFields
} from "@interfaces/mgr/IDOSHIEN/report/initial";
import {
  IDOSHIEN_INPUT_CLASS_LIST,
  IDOSHIEN_STATUS_LIST,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_BASE_LIST,
  IDOSHIEN_ACTION_CLASS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import { calculateForRequiredTime } from "@utils/domain/mgr/calculateForRequiredTime";
import castNumber from "@utils/dataNormalizer/castNumber";

export type InitialDataValues = InitialValues;

export const inoutResultsDetailsInitialValue = {
  id: null,
  actionClass: IDOSHIEN_ACTION_CLASS_LIST.DRIVE.value,
  inTime: "",
  inTimeClass: "0",
  outTime: "",
  outTimeClass: "0",
  time: 0
};

const inoutResultsDetailsFormatValue = (
  detail: InoutResultsDetailsState[]
): InoutResultsDetailsFields[] => {
  return detail.map((record) => {
    return {
      id: record.id,
      actionClass: record.actionClass
        ? record.actionClass.toString()
        : IDOSHIEN_ACTION_CLASS_LIST.DRIVE.value,
      inTimeClass: record.inTimeClass ? record.inTimeClass : "0",
      inTime: record.inTime ? record.inTime : "",
      outTimeClass: record.outTimeClass ? record.outTimeClass : "0",
      outTime: record.outTime ? record.outTime : "",
      time: calculateForRequiredTime(
        record.inTime,
        record.inTimeClass,
        record.outTime,
        record.outTimeClass
      )
    };
  });
};

const serviceCodesFormatValue = (
  serviceCodes: ServiceCodesState[]
): ServiceCodesFields[] => {
  return serviceCodes.map((i) => {
    return {
      ...i,
      isDelete: false
    };
  });
};

/**
 * スナップショット対応したスタッフID取得
 * スナップショットの場合 0
 */
const getStaffIdWithSnapshot = (staff: PractitionerStaffState): string => {
  if (staff.name !== staff.snapshotName) {
    return "0";
  }
  return staff.id.toString();
};

// デフォルト値設定
export const initialValues = (
  state: InoutResultsState | null = null,
  date = ""
): InitialDataValues => {
  const details1 =
    state && state.inoutResultsDetails1
      ? inoutResultsDetailsFormatValue(state.inoutResultsDetails1)
      : [];

  const details2 =
    state && state.inoutResultsDetails2
      ? inoutResultsDetailsFormatValue(state.inoutResultsDetails2)
      : [];

  const serviceCodes =
    state && state.serviceCodes
      ? serviceCodesFormatValue(state.serviceCodes)
      : [];

  const targetDateState = state && state.targetDate ? state.targetDate : date;
  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      id: state && state.id ? state.id : null,
      inoutResultDailyId: state ? state.inoutResultDailyId : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      targetDate: targetDateState,
      inputClass:
        state && state.inputClass
          ? state.inputClass.toString()
          : IDOSHIEN_INPUT_CLASS_LIST.PLAN.value,
      inoutResultsPlanId:
        state && state.inoutResultsPlanId ? state.inoutResultsPlanId : null,
      inoutResultsResultId:
        state && state.inoutResultsResultId ? state.inoutResultsResultId : null,
      serviceDeliveryRecordsId: state ? state.serviceDeliveryRecordsId : null,
      status:
        state && state.status ? state.status : IDOSHIEN_STATUS_LIST.NONE.value,
      statusText: state && state.statusText ? state.statusText : "",
      numberOfParticipants:
        state && state.numberOfParticipants
          ? state.numberOfParticipants.toString()
          : IDOSHIEN_MEMBER_LIST.ONE.value,
      practitioner1:
        state && state.practitioner1Staff
          ? getStaffIdWithSnapshot(state.practitioner1Staff)
          : IDOSHIEN_BASE_LIST.NONE.value,
      practitioner1InTimeClass: "当日",
      practitioner1InTime:
        state && state.practitioner1InTime ? state.practitioner1InTime : "",
      practitioner1OutTimeClass:
        state && state.practitioner1OutTimeClass
          ? state.practitioner1OutTimeClass
          : "0",
      practitioner1OutTime:
        state && state.practitioner1OutTime ? state.practitioner1OutTime : "",
      practitioner1CalculatedHours:
        state && state.practitioner1CalculatedHours !== null
          ? state.practitioner1CalculatedHours.toString()
          : "0",
      practitioner1Memo:
        state && state.practitioner1Memo ? state.practitioner1Memo : "",
      inoutResultsDetails1: details1,
      practitioner2:
        state && state.practitioner2Staff
          ? getStaffIdWithSnapshot(state.practitioner2Staff)
          : IDOSHIEN_BASE_LIST.NONE.value,
      practitioner2InTimeClass: "当日",
      practitioner2InTime:
        state && state.practitioner2InTime ? state.practitioner2InTime : "",
      practitioner2OutTimeClass:
        state && state.practitioner2OutTimeClass
          ? state.practitioner2OutTimeClass
          : "0",
      practitioner2OutTime:
        state && state.practitioner2OutTime ? state.practitioner2OutTime : "",
      practitioner2Memo:
        state && state.practitioner2Memo ? state.practitioner2Memo : "",
      inoutResultsDetails2: details2,
      practitioner3:
        state && state.practitioner3Staff
          ? getStaffIdWithSnapshot(state.practitioner3Staff)
          : IDOSHIEN_BASE_LIST.NONE.value,
      practitioner4:
        state && state.practitioner4Staff
          ? getStaffIdWithSnapshot(state.practitioner4Staff)
          : IDOSHIEN_BASE_LIST.NONE.value,
      practitioner5:
        state && state.practitioner5Staff
          ? getStaffIdWithSnapshot(state.practitioner5Staff)
          : IDOSHIEN_BASE_LIST.NONE.value,
      timeDuplicationJudgmentFlg:
        state && state.timeDuplicationJudgmentFlg
          ? state.timeDuplicationJudgmentFlg
          : false,
      wholeCalculatedHours:
        state && state.wholeCalculatedHours !== null
          ? state.wholeCalculatedHours.toString()
          : "",
      calculatedHoursDaytime:
        state && state.calculatedHoursDaytime !== null
          ? state.calculatedHoursDaytime.toString()
          : "",
      calculatedHoursNighttime:
        state && state.calculatedHoursNighttime !== null
          ? state.calculatedHoursNighttime.toString()
          : "",
      calculatedHoursLateNight:
        state && state.calculatedHoursLateNight !== null
          ? state.calculatedHoursLateNight.toString()
          : "",
      calculatedHoursEarlyMorning:
        state && state.calculatedHoursEarlyMorning !== null
          ? state.calculatedHoursEarlyMorning.toString()
          : "",
      duplicateCalculatedHours:
        state && state.duplicateCalculatedHours !== null
          ? state.duplicateCalculatedHours.toString()
          : "",
      duplicateCalculatedHoursDaytime:
        state && state.duplicateCalculatedHoursDaytime !== null
          ? state.duplicateCalculatedHoursDaytime.toString()
          : "",
      duplicateCalculatedHoursNighttime:
        state && state.duplicateCalculatedHoursNighttime !== null
          ? state.duplicateCalculatedHoursNighttime.toString()
          : "",
      duplicateCalculatedHoursLateNight:
        state && state.duplicateCalculatedHoursLateNight !== null
          ? state.duplicateCalculatedHoursLateNight.toString()
          : "",
      duplicateCalculatedHoursEarlyMorning:
        state && state.duplicateCalculatedHoursEarlyMorning !== null
          ? state.duplicateCalculatedHoursEarlyMorning.toString()
          : "",
      vehicleCalculatedHours:
        state && state.vehicleCalculatedHours !== null
          ? `${Number(state.vehicleCalculatedHours.split(":")[0])}`
          : "",
      vehicleCalculatedMinute:
        state && state.vehicleCalculatedHours !== null
          ? `${Number(state.vehicleCalculatedHours.split(":")[1])}`
          : "",
      licenseSameFlg:
        state && state.licenseSameFlg ? state.licenseSameFlg : false,
      physicalCareFlg:
        state && state.physicalCareFlg ? state.physicalCareFlg : false,
      numberOfSupporters:
        state && state.numberOfSupporters
          ? state.numberOfSupporters.toString()
          : "",
      supportRatio:
        state && state.numberOfParticipants && state.numberOfSupporters
          ? `1:${
              Math.floor(
                (state.numberOfSupporters /
                  castNumber(state.numberOfParticipants)) *
                  100
              ) / 100
            }`
          : "-",
      serviceCodes,
      createFlg: !state,
      resultExistFlg:
        state && state.resultExistFlg ? state.resultExistFlg : false
    }
  };
};
