import { Dispatch } from "redux";
import * as actions from "./actions";
import facilityApi from "@api/requests/facility";
import { MunicipalityValues } from "@initialize/mgr/IDOSHIEN/Municipality/initialValues";
import * as loadingActions from "@stores/loading/actions";
import dispatches from "@stores/dispatches";
import {
  normalizeFormToPostData,
  normalizeIDOSHIENServiceCodePaginationAPI,
  normalizeIDOSHIENServiceCodeSearchAPI
} from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/normalizer";
import { ServiceCodePaginationParameters } from "@api/requests/serviceCodes/getServiceCodePagination";
import { ServiceCodeSearchParameters } from "@api/requests/serviceCodes/getServiceCodeSearch";
import { serviceCodes } from "@api/requests/serviceCodes";
import { VALIDATION_ERROR } from "@constants/variables";

const fetch = (dispatch: Dispatch) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchStarted());
  await facilityApi
    .getFacilityMunicipalities()
    .then((res) => {
      dispatch(actions.fetchSuccess(res.data));
    })
    .catch((e) => {
      dispatch(actions.fetchFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const fetchOne = (dispatch: Dispatch) => async (id: string): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchOneStarted());
  await facilityApi
    .getFacilityMunicipalitiesTargetId(id)
    .then((res) => {
      dispatch(actions.fetchOneSuccess(res.data));
    })
    .catch((e) => {
      dispatch(actions.fetchOneFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

const clearOne = (dispatch: Dispatch) => async (): Promise<void> => {
  await dispatch(actions.clearOne());
};

const clear = (dispatch: Dispatch) => (): void => {
  dispatch(actions.clear());
};

const postMunicipality = (dispatch: Dispatch) => async (
  values: MunicipalityValues,
  hasInvoiceAuthority: boolean,
  municipalityId: number | null,
  initialValues?: MunicipalityValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postMunicipalityStarted());
  const normalizeData = normalizeFormToPostData(
    values,
    hasInvoiceAuthority,
    municipalityId,
    initialValues
  );
  await facilityApi
    .postFacilityMunicipality(normalizeData)
    .then(() => {
      dispatch(actions.postMunicipalitySuccess());
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "保存が完了しました",
        variant: "success"
      });
    })
    .catch((e) => {
      dispatch(actions.postMunicipalityFailed());
      dispatches.uiDispatch(dispatch).responseError(e.response);
      if (e.response && e.response.status !== VALIDATION_ERROR) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
      throw e;
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * サービスコードのデータ取得及びstore格納
 * @param dispatch
 */
const fetchIDOSHIENServiceCodesSearch = (dispatch: Dispatch) => async (
  municipalityId: string,
  params: ServiceCodeSearchParameters
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchIDOSHIENServiceCodesSearchStarted());
  await serviceCodes
    .getServiceCodeSearch(municipalityId, params)
    .then((res) => {
      const normalizedData = normalizeIDOSHIENServiceCodeSearchAPI(
        res.data.data
      );
      dispatch(actions.fetchIDOSHIENServiceCodesSearchSuccess(normalizedData));
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(
        actions.fetchIDOSHIENServiceCodesSearchFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * サービスコードのデータ取得及びstore格納(条件から絞り込んで登録する用)
 * @param dispatch
 */
const fetchIDOSHIENServiceCodesPagination = (dispatch: Dispatch) => async (
  municipalityId: string,
  params: ServiceCodePaginationParameters
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchIDOSHIENServiceCodesPaginationStarted());
  await serviceCodes
    .getServiceCodePagination(municipalityId, params)
    .then((res) => {
      const normalizedData = normalizeIDOSHIENServiceCodePaginationAPI(
        res.data.data
      );
      dispatch(
        actions.fetchIDOSHIENServiceCodesPaginationSuccess(normalizedData)
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(
        actions.fetchIDOSHIENServiceCodesPaginationFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

const deleteMunicipality = (dispatch: Dispatch) => async (
  id: string
): Promise<boolean> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteMunicipalityStarted());
  const isSuccess = await facilityApi
    .deleteFacilityMunicipality(id)
    .then(() => {
      dispatch(actions.deleteMunicipalitySuccess());
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "削除が完了しました",
        variant: "success"
      });
      return true;
    })
    .catch((e) => {
      dispatch(actions.deleteMunicipalityFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
      return false;
    })
    .finally(() => dispatch(loadingActions.loadDone()));
  return isSuccess;
};

type Dispatcher = {
  fetch: () => Promise<void>;
  fetchOne: (id: string) => Promise<void>;
  clearOne: ReturnType<typeof clearOne>;
  clear: () => void;
  postMunicipality: ReturnType<typeof postMunicipality>;
  fetchIDOSHIENServiceCodesSearch: (
    municipalityId: string,
    params: ServiceCodeSearchParameters
  ) => Promise<void>;
  fetchIDOSHIENServiceCodesPagination: (
    municipalityId: string,
    params: ServiceCodePaginationParameters
  ) => Promise<void>;
  deleteMunicipality: (id: string) => Promise<boolean>;
};

export const IDOSHIENMunicipalitiesInFacilityDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetch: fetch(dispatch),
  fetchOne: fetchOne(dispatch),
  clearOne: clearOne(dispatch),
  clear: clear(dispatch),
  postMunicipality: postMunicipality(dispatch),
  fetchIDOSHIENServiceCodesSearch: fetchIDOSHIENServiceCodesSearch(dispatch),
  fetchIDOSHIENServiceCodesPagination: fetchIDOSHIENServiceCodesPagination(
    dispatch
  ),
  deleteMunicipality: deleteMunicipality(dispatch)
});
