import * as types from "./types";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};
// 取得
export const fetchConsultationStarted = () =>
  ({ type: types.FETCH_CONSULTATION_STARTED } as const);
export const fetchConsultationSuccess = (res: types.ConsultationRecordState) =>
  ({ type: types.FETCH_CONSULTATION_SUCCESS, payload: res } as const);
export const fetchConsultationFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_CONSULTATION_FAILED, error: err } as const);
// 作成済み計画読み込み
export const copyConsultationStarted = () =>
  ({ type: types.COPY_CONSULTATION_STARTED } as const);
export const copyConsultationSuccess = (res: types.ConsultationRecordState) =>
  ({
    type: types.COPY_CONSULTATION_SUCCESS,
    payload: { res }
  } as const);
export const copyConsultationFailed = (err: ErrorResponse) =>
  ({ type: types.COPY_CONSULTATION_FAILED, error: err } as const);
export const resetCopyConsultation = () =>
  ({ type: types.RESET_COPY_CONSULTATION } as const);
// 更新
export const postConsultationStarted = () =>
  ({ type: types.POST_CONSULTATION_STARTED } as const);
export const postConsultationSuccess = () =>
  ({
    type: types.POST_CONSULTATION_SUCCESS
  } as const);
export const postConsultationFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_CONSULTATION_FAILED,
    error: err
  } as const);

// 削除
export const deleteConsultationStarted = () =>
  ({ type: types.DELETE_CONSULTATION_STARTED } as const);
export const deleteConsultationSuccess = () =>
  ({
    type: types.DELETE_CONSULTATION_SUCCESS
  } as const);
export const deleteConsultationFailed = (err: ErrorResponse) =>
  ({
    type: types.DELETE_CONSULTATION_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchConsultationStarted>
  | ReturnType<typeof fetchConsultationSuccess>
  | ReturnType<typeof fetchConsultationFailed>
  | ReturnType<typeof copyConsultationStarted>
  | ReturnType<typeof copyConsultationSuccess>
  | ReturnType<typeof copyConsultationFailed>
  | ReturnType<typeof resetCopyConsultation>
  | ReturnType<typeof postConsultationStarted>
  | ReturnType<typeof postConsultationSuccess>
  | ReturnType<typeof postConsultationFailed>
  | ReturnType<typeof deleteConsultationStarted>
  | ReturnType<typeof deleteConsultationSuccess>
  | ReturnType<typeof deleteConsultationFailed>;
