import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * アセスメントシートの削除
 * @param uifId 事業所所属ユーザーのID
 * @param assessmentId アセスメントシートのID
 */
export const deleteAssessment = async (
  uifId: string,
  assessmentId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assessment/${assessmentId}`;
  return request.delete(url);
};
