import * as React from "react";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";

// ui
import RefreshIcon from "@material-ui/icons/Refresh";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    searchButtonsWrap: {
      display: "flex",
      alignItems: "center",
      position: "absolute",
      bottom: 10,
      right: 10
    },
    clearButton: {
      display: "flex",
      padding: "3px 8px",
      marginRight: 8,
      width: 168,
      minHeight: 30
    }
  });

type Props = {
  onClickSearch: (e: React.MouseEvent<HTMLElement>) => void;
  onClickReset: (e: React.MouseEvent<HTMLElement>) => void;
} & WithStyles<typeof styles>;

/**
 * 「リセット」「この条件で絞り込む」Action Button
 */
const SearchUsersFormActionCore = (props: Props): JSX.Element => {
  const { onClickSearch, onClickReset, classes } = props;
  return (
    <div className={classes.searchButtonsWrap}>
      <KnowbeButton
        className={classes.clearButton}
        onClick={onClickReset}
        kind="iconText"
      >
        <RefreshIcon style={{ marginRight: 3 }} />
        条件をリセット
      </KnowbeButton>
      <KnowbeButton
        kind="outlineWhite"
        onClick={onClickSearch}
        className={classes.searchButton}
      >
        この条件で絞り込む
      </KnowbeButton>
    </div>
  );
};
export const SearchUsersFormAction = withStyles(styles)(
  SearchUsersFormActionCore
);
