import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

/**
 * 介護計画（利用者情報）から実績計画へのコピーAPI用のリクエストパラメータ
 */
export type RequestParamToPostCopyCarePlanAPI = {
  process_type: number;
  target: {
    uif_id: number;
    yyyymmdd: string;
  }[];
};

/**
 * 介護計画（利用者情報）から実績計画へのコピー
 * @param data リクエスト情報
 */
export const postInOutResultsCopyCarePlan = async (
  data: RequestParamToPostCopyCarePlanAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202404}/inout_results/copy_care_plan`;
  return request.post(url, data);
};
