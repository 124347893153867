import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export interface GetUserSummarySupportReports {
  supportRecords: {
    counts: {
      numberOfAbsence: number;
      numberOfHavingInterview: number;
      numberOfServiceUsage: number;
      numberOfSupportIkou1: number;
      numberOfSupportOutOfFacility: number;
      supportDays: number;
      totalInterviewMinutes: number;
    };
    support: {
      inout: {
        id: number;
        uif_id: number;
        name: string;
        recipientNumber: string;
        target_date: string;
        status: number;
        in_time: string | null;
        out_time: string | null;
        pickup: string | null;
        food: string | null;
      };
      record: {
        id: number;
        inout_record_id: number;
        user_status: string | null;
        interview_flg: string;
        interview_start_time: string | null;
        interview_end_time: string | null;
        interview_comment: string | null;
        staff_comment: string | null;
        other_comment: string | null;
        absence_reason: string | null;
        support_content: string | null;
        correspondent_staff_id: number | null;
        correspondent_staff_name: string | null;
        staff_id: number | null;
        staff_name: string | null;
        workplace_company: {
          workplace_company_id: number;
          workplace_name: string;
          is_checked: boolean;
        }[];
      } | null;
      support_work_history:
        | {
            inout_record_id: number;
            category_id: number;
            item_id: number;
            item_name: string;
            is_delete: number | null;
          }[]
        | [];
    }[];
    work_summary: {
      category: {
        id: number;
        name: string;
      };
      item: {
        id: number;
        name: string;
      };
      count: number;
      records: string[]; // YYYY-MM形式
    }[];
    // 就労定着用支援記録
    supports?: {
      id: number;
      uif_id: number;
      name: string;
      target_date: string;
      status: number;
      support_start_time: string | null;
      support_end_time: string | null;
      support_method: number;
      support_method_other: string | null;
      company_name: string | null;
      todays_purpose: string | null;
      life_current_issues: string | null;
      life_support_content: string | null;
      office_current_issues: string | null;
      office_support_content: string | null;
      usage_status_of_related_organizations: string | null;
      notices: string | null;
      staff_comment: string | null;
      next_support_date: string | null;
      next_support_method: string | null;
      next_support_plans: string | null;
      author: number | null;
      author_name: string | null;
      creation_date: string | null;
    }[];
    // 就労定着用支援支援レポート
    report?: {
      usage_result: {
        id: number;
        target_date: string;
        status: number;
        support_method: number;
        support_method_other: string | null;
      }[];
      uif_id: number;
      name: string;
      goal_1: string | null;
      goal_2: string | null;
      goal_3: string | null;
      future_support_content: string | null;
      person_behavior: string | null;
      facility_behavior: string | null;
      related_organizations_behavior: string | null;
      shared_content: string | null;
      presentation_date: string | null;
      author: number | null;
      author_name: string | null;
      company_name: string | null;
      responsible_person: string | null;
      working_start_date: string | null;
      content1: string | null;
      result1: string | null;
      content2: string | null;
      result2: string | null;
      content3: string | null;
      result3: string | null;
      creation_date: string | null;
    }[];
    created_at: string | null;
    updated_at: string | null;
  }[];
}

/**
 * 指定された利用者分の支援記録のデータを取得する
 * @param year 年
 * @param month 月(MM)
 * @param excludedUserIds 指定された事業所所属ユーザーのID
 * @param type 「支援記録」「支援レポート」の区別用（就労定着）
 */
const getUserSummarySupportReports = async (
  year: string,
  month: string,
  excludedUserIds?: string,
  type?: string
): Promise<AxiosResponse<GetUserSummarySupportReports>> => {
  const url = `${VERSION_URL_201910}/supports/users/records/${year}/${month}`;
  let query = excludedUserIds ? `?excluded_user_ids=${excludedUserIds}` : "";
  if (type) {
    query += excludedUserIds ? `&type=${type}` : `?type=${type}`;
  }
  return request.get<GetUserSummarySupportReports>(url + query);
};

export default getUserSummarySupportReports;
