import { BasicValues } from "@interfaces/mgr/DOKOENGO/Users/basic";
import { ServiceUseValues } from "@interfaces/mgr/DOKOENGO/Users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/mgr/DOKOENGO/Users/recipientCertificate";
import { UsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { DEFAULT_SELECT_VALUE, Checkbox } from "@constants/variables";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues;

// FlgがCheckbox.ONの場合はtrueを返す
const determineTureOrFalse = (value: Checkbox | undefined): boolean => {
  if (value === Checkbox.ON) {
    return true;
  }
  return false;
};

export const initialValues = (
  state?: UsersInFacilityState["user"]
): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const DOKOENGO =
    state && state.user_in_facility_dokoengo
      ? state.user_in_facility_dokoengo
      : {};
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTureOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTureOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTureOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTureOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTureOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTureOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTureOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTureOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianNameSei: undefinedToReturnValue(userInFacility.guardian_name_sei),
      guardianNameMei: undefinedToReturnValue(userInFacility.guardian_name_mei),
      guardianNameSeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_sei_kana
      ),
      guardianNameMeiKana: undefinedToReturnValue(
        userInFacility.guardian_name_mei_kana
      ),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      disabilityClass: undefinedToReturnValue(DOKOENGO.disability_class, 0),
      specialAreaFlag: numberToBoolean(DOKOENGO.special_area_flg),
      specialAreaStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(DOKOENGO.date_begin_special_area)
        )
      ),
      specialAreaEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(DOKOENGO.date_end_special_area)
        )
      ),
      payMonthsAgreedAccompanyingSupport: undefinedToReturnValue(
        DOKOENGO.pay_months_agreed_accompanying_support
      ),
      classifyDeafblindFlg: numberToBoolean(DOKOENGO.classify_deafblind_flg),
      incomeKind: undefinedToReturnValue(userInFacility.income_kind, 1),
      incomeKindType: undefinedToReturnValue(DOKOENGO.income_kind, 0),
      subsidizedFlag: determineTureOrFalse(userInFacility.subsidized_flg),
      subsidizedPercent: undefinedToReturnValue(
        userInFacility.subsidized_percent
      ),
      subsidizedYen: undefinedToReturnValue(userInFacility.subsidized_yen),
      subsidizedUnit: undefinedToReturnValue(
        userInFacility.subsidized_unit_flg,
        1
      ),
      subsidizedCityId: userInFacility.subsidized_city_id
        ? `${userInFacility.subsidized_city_id}`
        : DEFAULT_SELECT_VALUE,
      upperLimitFacilityFlag: determineTureOrFalse(
        userInFacility.uplimit_facility_flg
      ),
      upperLimitControlledBy: undefinedToReturnValue(
        userInFacility.uplimit_controlled_by,
        1
      ),
      upperLimitFacilityNumber: undefinedToReturnValue(
        userInFacility.uplimit_facility_number
      ),
      upperLimitFacilityNumber2: undefinedToReturnValue(
        userInFacility.uplimit_facility_number2
      ),
      upperLimitFacilityNumber3: undefinedToReturnValue(
        userInFacility.uplimit_facility_number3
      ),
      upperLimitFacilityNumber4: undefinedToReturnValue(
        userInFacility.uplimit_facility_number4
      ),
      upperLimitFacilityName: undefinedToReturnValue(
        userInFacility.uplimit_facility_name
      ),
      upperLimitFacilityName2: undefinedToReturnValue(
        userInFacility.uplimit_facility_name2
      ),
      upperLimitFacilityName3: undefinedToReturnValue(
        userInFacility.uplimit_facility_name3
      ),
      upperLimitFacilityName4: undefinedToReturnValue(
        userInFacility.uplimit_facility_name4
      ),
      upperLimitTotalYen: undefinedToReturnValue(
        userInFacility.uplimit_total_yen
      ),
      upperLimitTotalYen2: undefinedToReturnValue(
        userInFacility.uplimit_total_yen2
      ),
      upperLimitTotalYen3: undefinedToReturnValue(
        userInFacility.uplimit_total_yen3
      ),
      upperLimitTotalYen4: undefinedToReturnValue(
        userInFacility.uplimit_total_yen4
      ),
      upperLimitUserLoadYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen
      ),
      upperLimitUserLoadYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen2
      ),
      upperLimitUserLoadYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen3
      ),
      upperLimitUserLoadYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      resultOfManagement: undefinedToReturnValue(
        userInFacility.result_of_management,
        1
      ),
      upperLimitYen: undefinedToReturnValue(userInFacility.uplimit_yen),
      businessNumberContract: undefinedToReturnValue(
        userInFacility.business_number_contract
      )
    },
    recipientCertificate: {
      userChargeLimitFlag: determineTureOrFalse(
        userInFacility.user_charge_limit_flg
      ),
      userChargeLimitStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_user_charge_limit)
        )
      ),
      userChargeLimitEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_user_charge_limit)
        )
      ),
      careSupportAuthFlag: determineTureOrFalse(
        userInFacility.care_support_auth_flg
      ),
      careSupportAuthStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_auth)
        )
      ),
      careSupportAuthEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_auth)
        )
      ),
      careSupportPaymentFlag: determineTureOrFalse(
        userInFacility.care_support_payment_flg
      ),
      careSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_payment)
        )
      ),
      careSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_payment)
        )
      ),
      planSupportPaymentFlag: determineTureOrFalse(
        userInFacility.plan_support_payment_flg
      ),
      planSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_payment)
        )
      ),
      planSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_payment)
        )
      ),
      planSupportMonitorFlag: determineTureOrFalse(
        userInFacility.plan_support_monitor_flg
      ),
      planSupportMonitorStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_monitor)
        )
      ),
      planSupportMonitorEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_monitor)
        )
      )
    }
  };
};
