import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import { dateToLocalisedString } from "@/utils/date";
import getAge from "@utils/date/getAge";
import { selectDateValueToDate } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";
import { DISABILITY_CLASS_LIST } from "@constants/variables";
import {
  WORKING_STRATUS,
  DISABILITY_CERTIFICATE_BODY,
  DISABILITY_CERTIFICATE_SPIRIT,
  DISABILITY_CERTIFICATE_REHABILITATION
} from "@constants/mgr/SHUROTEICHAKU/variables";

const styles = (): StyleRules =>
  createStyles({
    label: {
      color: "rgba(0, 0, 0, 0.87)",
      marginTop: 24,
      marginBottom: 12
    },
    disabilityLabel: {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "0.875rem",
      marginBottom: 8
    },
    block: {
      paddingLeft: 12,
      marginBottom: 48
    },
    block2: {
      paddingLeft: 12,
      marginBottom: 32
    },
    companyName: {
      width: 496,
      marginRight: 16,
      marginBottom: 30
    },
    shortField: {
      width: 240,
      marginBottom: 30
    },
    middleField: {
      width: 256,
      marginBottom: 32
    },
    wageField: {
      width: 240
    },
    rowShortField: {
      width: 240,
      marginRight: 16,
      marginTop: 30
    },
    selectField: {
      width: 245,
      marginBottom: 24
    },
    otherField: {
      width: 243,
      marginLeft: 16,
      marginBottom: 24
    },
    field: {
      marginBottom: 30
    },
    businessDescription: {
      marginBottom: 48
    },
    birthDay: {
      margin: "32px 0",
      "& > div": {
        width: 256,
        marginRight: 16
      }
    }
  });

type OwnProps = {
  isEditing: boolean;
  dateBirth: string;
  values: {
    // creation_dateはinitialValues経由とAPI-valueの2ケースある
    creation_date: SelectDateValue | string;
    support_plan_shuroteichaku: {
      company_name: string;
      company_industry: string;
      company_address: string;
      company_tel: string;
      responsible_person: string;
      equipment_environment: string;
      human_environment: string;
      working_status: string;
      wage: string;
      working_time: string;
      rest_time: string;
      holiday: string;
      business_description: string;
      working_start_date: SelectDateValue;
      takeover: string;
      disability_class: string;
      disability_certificate_body: string;
      disability_certificate_spirit: string;
      disability_certificate_rehabilitation: string;
      disability_certificate_rehabilitation_other: string;
      classify_incurable_flg: boolean;
      support_group: string;
      physical_condition: string;
      support_organization: string;
    };
  };
  onChangeDisabilityCertificateRehabilitation?: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 就職先情報・本人情報
 */
const BasicFieldsCore = (props: Props): JSX.Element => {
  const {
    working_status,
    disability_class,
    disability_certificate_body,
    disability_certificate_spirit,
    disability_certificate_rehabilitation,
    disability_certificate_rehabilitation_other,
    classify_incurable_flg
  } = props.values.support_plan_shuroteichaku;

  // 療育の交付ありの時に等級を編集可能にする
  const [editRehabilitationOther, setEditRehabilitationOther] = React.useState(
    disability_certificate_rehabilitation ===
      DISABILITY_CERTIFICATE_REHABILITATION[1].value
  );
  React.useEffect(() => {
    setEditRehabilitationOther(
      disability_certificate_rehabilitation ===
        DISABILITY_CERTIFICATE_REHABILITATION[1].value
    );
  }, [disability_certificate_rehabilitation]);

  // 定数のラベルを表示させる必要のある項目
  const workingStatus = WORKING_STRATUS.find((v) => v.value === working_status);
  const disabilityClass = DISABILITY_CLASS_LIST.find(
    (v) => v.value === disability_class
  );

  // 障害者手帳
  const disabilityList: string[] = [];
  if (disability_certificate_body !== DISABILITY_CERTIFICATE_BODY[0].value) {
    disabilityList.push(`身体 ${disability_certificate_body}級`);
  }
  if (
    disability_certificate_spirit !== DISABILITY_CERTIFICATE_SPIRIT[0].value
  ) {
    disabilityList.push(`精神 ${disability_certificate_spirit}級`);
  }
  if (
    disability_certificate_rehabilitation !==
    DISABILITY_CERTIFICATE_REHABILITATION[0].value
  ) {
    disabilityList.push(`療育 ${disability_certificate_rehabilitation_other}`);
  }
  let disabilityCertificateText = "";
  if (disabilityList.length > 0) {
    disabilityCertificateText = disabilityList.join("、");
  } else if (classify_incurable_flg) {
    disabilityCertificateText = "難病";
  }

  // formik経由ならstringに戻す
  const creationDate: string =
    typeof props.values.creation_date === "string"
      ? props.values.creation_date
      : selectDateValueToDate(props.values.creation_date);
  const age = props.dateBirth ? getAge(props.dateBirth, creationDate) : null;

  return (
    <>
      <GrayMiddleHeading text="就職先情報" />
      <div className={props.classes.label}>雇用事業主</div>
      <div className={props.classes.block}>
        <FormGroup row>
          <div className={props.classes.companyName}>
            <RecordTextField
              name="support_plan_shuroteichaku.company_name"
              value={props.values.support_plan_shuroteichaku.company_name}
              defaultValue="-"
              label="企業名"
              labelType="default"
              placeholder=""
              isEditable={props.isEditing}
            />
          </div>
          <div className={props.classes.shortField}>
            <RecordTextField
              name="support_plan_shuroteichaku.company_industry"
              value={props.values.support_plan_shuroteichaku.company_industry}
              defaultValue="-"
              label="業種"
              labelType="default"
              placeholder=""
              isEditable={props.isEditing}
            />
          </div>
        </FormGroup>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_plan_shuroteichaku.company_address"
            value={props.values.support_plan_shuroteichaku.company_address}
            defaultValue="-"
            label="所在地"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
        <div className={props.classes.shortField}>
          <RecordTextField
            name="support_plan_shuroteichaku.company_tel"
            value={props.values.support_plan_shuroteichaku.company_tel}
            defaultValue="-"
            label="連絡先"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
        <div className={props.classes.shortField}>
          <RecordTextField
            name="support_plan_shuroteichaku.responsible_person"
            value={props.values.support_plan_shuroteichaku.responsible_person}
            defaultValue="-"
            label="担当者"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
      </div>

      <div className={props.classes.label}>職場環境</div>
      <div className={props.classes.block}>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_plan_shuroteichaku.equipment_environment"
            value={
              props.values.support_plan_shuroteichaku.equipment_environment
            }
            defaultValue="-"
            label="物理的環境（休憩室有無・音や光 等）"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_plan_shuroteichaku.human_environment"
            value={props.values.support_plan_shuroteichaku.human_environment}
            defaultValue="-"
            label="人的環境（キーパーソン、上司、同僚の属性 等）"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
      </div>

      <div className={props.classes.label}>労働条件</div>
      <div className={props.classes.block}>
        {props.isEditing ? (
          <FormikRadioButtons
            name="support_plan_shuroteichaku.working_status"
            label="雇用形態"
            options={WORKING_STRATUS}
          />
        ) : (
          <div className={props.classes.shortField}>
            <ReadonlyTextField
              value={workingStatus ? workingStatus.label : ""}
              defaultValue="-"
              label="雇用形態"
            />
          </div>
        )}
        <div className={props.classes.wageField}>
          <RecordTextField
            name="support_plan_shuroteichaku.wage"
            value={props.values.support_plan_shuroteichaku.wage}
            defaultValue="-"
            label="契約上の賃金"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
        <FormGroup row>
          <div className={props.classes.rowShortField}>
            <RecordTextField
              name="support_plan_shuroteichaku.working_time"
              value={props.values.support_plan_shuroteichaku.working_time}
              defaultValue="-"
              label="1日の勤務時間"
              labelType="default"
              placeholder=""
              isEditable={props.isEditing}
            />
          </div>
          <div className={props.classes.rowShortField}>
            <RecordTextField
              name="support_plan_shuroteichaku.rest_time"
              value={props.values.support_plan_shuroteichaku.rest_time}
              defaultValue="-"
              label="休み時間"
              labelType="default"
              placeholder=""
              isEditable={props.isEditing}
            />
          </div>
          <div className={props.classes.rowShortField}>
            <RecordTextField
              name="support_plan_shuroteichaku.holiday"
              value={props.values.support_plan_shuroteichaku.holiday}
              defaultValue="-"
              label="休日"
              labelType="default"
              placeholder=""
              isEditable={props.isEditing}
            />
          </div>
        </FormGroup>
      </div>
      <div className={props.classes.businessDescription}>
        <RecordTextField
          name="support_plan_shuroteichaku.business_description"
          value={props.values.support_plan_shuroteichaku.business_description}
          defaultValue="-"
          label="業務内容"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>

      <div className={props.classes.label}>
        就職まで利用していた事業所からの引き継ぎ事項
      </div>
      <div className={props.classes.block}>
        <div className={props.classes.field}>
          <RecordSelectDate
            name="support_plan_shuroteichaku.working_start_date"
            label="就職日"
            value={props.values.support_plan_shuroteichaku.working_start_date}
            isEditable={props.isEditing}
            addYearTo={1}
            overrideYearFrom={1989}
          />
        </div>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_plan_shuroteichaku.takeover"
            value={props.values.support_plan_shuroteichaku.takeover}
            defaultValue="-"
            label="引き継ぎ事項"
            labelType="default"
            placeholder=""
            isEditable={props.isEditing}
          />
        </div>
      </div>

      <GrayMiddleHeading text="本人情報" />
      <FormGroup row className={props.classes.birthDay}>
        <ReadonlyTextField
          value={
            props.dateBirth
              ? dateToLocalisedString(props.dateBirth, "YYYY年M月D日")
              : ""
          }
          defaultValue="-"
          label="生年月日"
        />
        <ReadonlyTextField
          value={age && age > 0 ? `${age}才` : "-"}
          defaultValue="-"
          label="年齢"
        />
      </FormGroup>

      {props.isEditing ? (
        <>
          <FormikRadioButtons
            name="support_plan_shuroteichaku.disability_class"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
          />
          <div className={props.classes.disabilityLabel}>障害者手帳</div>
          <div className={props.classes.block2}>
            <div className={props.classes.selectField}>
              <RecordSelect
                name="support_plan_shuroteichaku.disability_certificate_body"
                value={
                  props.values.support_plan_shuroteichaku
                    .disability_certificate_body
                }
                defaultValue="-"
                label="身体"
                placeholder="選択してください"
                options={DISABILITY_CERTIFICATE_BODY}
                isEditable
              />
            </div>
            <div className={props.classes.selectField}>
              <RecordSelect
                name="support_plan_shuroteichaku.disability_certificate_spirit"
                value={
                  props.values.support_plan_shuroteichaku
                    .disability_certificate_spirit
                }
                defaultValue="-"
                label="精神"
                placeholder="選択してください"
                options={DISABILITY_CERTIFICATE_SPIRIT}
                isEditable
              />
            </div>
            <FormGroup row>
              <div className={props.classes.selectField}>
                <RecordSelect
                  name="support_plan_shuroteichaku.disability_certificate_rehabilitation"
                  value={
                    props.values.support_plan_shuroteichaku
                      .disability_certificate_rehabilitation
                  }
                  defaultValue="-"
                  label="療育"
                  placeholder="選択してください"
                  options={DISABILITY_CERTIFICATE_REHABILITATION}
                  onChangeHook={
                    props.onChangeDisabilityCertificateRehabilitation
                  }
                  isEditable
                />
              </div>
              <div className={props.classes.otherField}>
                <RecordTextField
                  name="support_plan_shuroteichaku.disability_certificate_rehabilitation_other"
                  value={
                    props.values.support_plan_shuroteichaku
                      .disability_certificate_rehabilitation_other
                  }
                  defaultValue="-"
                  label="等級"
                  labelType="default"
                  placeholder=""
                  isEditable={editRehabilitationOther}
                />
              </div>
            </FormGroup>
            <FormikCheckbox
              name="support_plan_shuroteichaku.classify_incurable_flg"
              label="難病（手帳なし）"
            />
          </div>
        </>
      ) : (
        <>
          <div className={props.classes.middleField}>
            <ReadonlyTextField
              value={disabilityClass ? disabilityClass.label : ""}
              defaultValue="-"
              label="障害支援区分"
            />
          </div>
          <div className={props.classes.middleField}>
            <ReadonlyTextField
              value={disabilityCertificateText}
              defaultValue="-"
              label="障害者手帳"
            />
          </div>
        </>
      )}
      <div className={props.classes.field}>
        <RecordTextField
          name="support_plan_shuroteichaku.support_group"
          value={props.values.support_plan_shuroteichaku.support_group}
          defaultValue="-"
          label="関わっている支援機関"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>
      <div className={props.classes.field}>
        <RecordTextField
          name="support_plan_shuroteichaku.physical_condition"
          value={props.values.support_plan_shuroteichaku.physical_condition}
          defaultValue="-"
          label="健康状態（診断名、服薬状況等）"
          labelType="default"
          placeholder=""
          isEditable={props.isEditing}
        />
      </div>

      <RecordTextField
        name="support_plan_shuroteichaku.support_organization"
        value={props.values.support_plan_shuroteichaku.support_organization}
        defaultValue="-"
        label="生活環境及び生活面のサポート体制（家族との同居の有無、家事の自立状況等）"
        labelType="default"
        placeholder=""
        isEditable={props.isEditing}
      />
    </>
  );
};

export const BasicFields = withStyles(styles)(BasicFieldsCore);
