/**
 * ActionNames
 */

export const FETCH_STARTED = "IDOSHIEN/FACILITY/FETCH_STARTED";
export const FETCH_SUCCESS = "IDOSHIEN/FACILITY/FETCH_SUCCESS";
export const FETCH_FAILED = "IDOSHIEN/FACILITY/FETCH_FAILED";
export const POST_STARTED = "IDOSHIEN/FACILITY/POST_STARTED";
export const POST_SUCCESS = "IDOSHIEN/FACILITY/POST_SUCCESS";
export const POST_FAILED = "IDOSHIEN/FACILITY/POST_FAILED";
/**
 * Basic
 */
export type FacilityState = {
  corporationName: string;
  officeNumber: string;
  officeName: string;
  serviceType: string;
  representativeName: string;
  postalCode: string;
  selectedPrefectureName: string;
  selectedCityCode: string;
  restAddress: string;
  tel: string;
  cityId: string;
};
