import * as actions from "./actions";
import * as types from "./types";

const initialState: types.SupportProcedureState = {
  supportProcedureList: {
    support_procedure_forms: [],
    number_of_plans: 0,
    number_of_records: 0,
    number_of_procedures: 0
  },
  supportProcedureDetail: {
    service_delivery_records_id: null,
    inout_results_id: null,
    support_procedure_forms_id: null,
    users_in_facility_id: 0,
    target_date: null,
    created_at: "",
    updated_at: "",
    title: "",
    number_of_practitioner: 1,
    author: null,
    support_procedure_form_practitioners: [],
    support_procedure_form_other_practitioners: [],
    support_procedure_form_details: [],
    contact_information_plan: "",
    inquiry_items_plan: "",
    contact_information_record: "",
    inquiry_items_record: "",
    procedure_status_flg: 0
  },
  print: []
};

export const KODOENGOSupportProcedure = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportProcedureState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_PROCEDURE_LIST_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PROCEDURE_LIST_SUCCESS:
      return { ...state, supportProcedureList: action.payload };
    case types.FETCH_SUPPORT_PROCEDURE_LIST_FAILED:
      return { ...state };
    case types.FETCH_SUPPORT_PROCEDURE_DETAIL_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_PROCEDURE_DETAIL_SUCCESS:
      return { ...state, supportProcedureDetail: action.payload };
    case types.FETCH_SUPPORT_PROCEDURE_DETAIL_FAILED:
      return { ...state };
    case types.POST_SUPPORT_PROCEDURE_DETAIL_STARTED:
      return { ...state };
    case types.POST_SUPPORT_PROCEDURE_DETAIL_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_PROCEDURE_DETAIL_FAILED:
    case types.DELETE_SUPPORT_PROCEDURE_LIST_STARTED:
      return { ...state };
    case types.DELETE_SUPPORT_PROCEDURE_LIST_SUCCESS:
      return { ...state };
    case types.DELETE_SUPPORT_PROCEDURE_LIST_FAILED:
      return { ...state };
    case types.FETCH_PRINT_RECORD_STARTED:
      return { ...state };
    case types.FETCH_PRINT_RECORD_SUCCESS:
      return { ...state, print: action.payload };
    case types.FETCH_PRINT_RECORD_FAILED:
      return { ...state };
    case types.CLEAR_SUPPORT_PROCEDURE_DETAIL_STATE:
      return {
        ...state,
        supportProcedureDetail: initialState.supportProcedureDetail
      };
    default:
      return state;
  }
};

export const KODOENGOSupportProcedureModal = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportProcedureState => {
  switch (action.type) {
    case types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_STARTED:
      return { ...state };
    case types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_SUCCESS:
      return { ...state, supportProcedureList: action.payload };
    case types.FETCH_MODAL_SUPPORT_PROCEDURE_LIST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
