import { StaffDataWithLicense } from "@stores/domain/mgr/common/staff/types";
import { StaffValues } from "@interfaces/mgr/common/staff/staffValues";

export const initialValues = (
  values: StaffDataWithLicense[] = []
): StaffValues => {
  const staffs: StaffValues["staffs"] = values.map((value, index) => ({
    key: index,
    staffItemId: value.staffItemId,
    staffName: value.staffName,
    roleName: value.roleName,
    staffLicenseName: value.staffLicenseName,
    dirty: false,
    delete: false
  }));
  staffs.push({
    key: values.length,
    staffItemId: null,
    staffName: "",
    roleName: "",
    staffLicenseName: "",
    dirty: false,
    delete: false
  });
  return { staffs };
};
