import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  KYOTAKUKAIGO_DISPLAY_STATUS_LIST,
  KYOTAKUKAIGO_INPUT_CLASS_LIST,
  KYOTAKUKAIGO_DISPATCH_NO_LIST
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {
  DataListState,
  InoutResultsState,
  DeleteReportState,
  CopyReportState
} from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/report/types";

const styles = (): StyleRules =>
  createStyles({
    superShortCell: {
      minWidth: 16,
      width: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 4
      }
    },
    superShortIconCell: {
      minWidth: 32,
      boxSizing: "content-box",
      lineHeight: "10px",
      "&:last-child": {
        paddingRight: 4
      },
      paddingLeft: 4,
      paddingRight: 4
    },
    middleShortCell: {
      minWidth: 16,
      width: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      paddingLeft: 8,
      paddingRight: 8
    },
    shortCell: {
      minWidth: 32,
      width: 32,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    shortMiddleCell: {
      minWidth: 41,
      width: 41,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    middleCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      lineHeight: "28px"
    },
    longCell: {
      minWidth: 90,
      boxSizing: "content-box",
      lineHeight: "28px"
    },
    icon: {
      textAlign: "center"
    },
    iconDiv: {
      height: 26
    },
    baseCell: {
      fontSize: 14,
      paddingLeft: 4,
      paddingRight: 4,
      lineHeight: "20px"
    }
  });

type OwnProps = {
  params: DataListState;
  idx: string;
  classOption: string;
  openModal: (param: InoutResultsState, inputClass: string) => void;
  onClickDeleteIcon: (target: DeleteReportState) => void;
  onCopyRecord: (target: CopyReportState) => void;
  openNonDeleteDialog: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;
  const openEditResultModal = (): void => {
    props.openModal(
      params.resultDetail,
      KYOTAKUKAIGO_INPUT_CLASS_LIST.RESULT.value
    );
  };
  const openEditPlanModal = (): void => {
    props.openModal(
      params.planDetail,
      KYOTAKUKAIGO_INPUT_CLASS_LIST.PLAN.value
    );
  };

  const onClickPlanDeleteIcon = (): void => {
    // 実績が存在する場合には、計画削除不可、
    if (params.resultExistFlg) {
      props.openNonDeleteDialog();
    } else {
      const target = {
        uifId: params.planDetail.usersInFacilityId,
        targetDate: params.planDetail.targetDate,
        inoutResultsId: params.planDetail.id,
        inputClass: 1
      };
      props.onClickDeleteIcon(target);
    }
  };

  const onClickResultDeleteIcon = (): void => {
    const target = {
      uifId: params.resultDetail.usersInFacilityId,
      targetDate: params.resultDetail.targetDate,
      inoutResultsId: params.resultDetail.id,
      inputClass: 2
    };
    props.onClickDeleteIcon(target);
  };

  const onCopyRecord = (): void => {
    const target = {
      uifId: params.planDetail.usersInFacilityId,
      targetDate: params.planDetail.targetDate,
      inoutResultsId: params.planDetail.id
    };
    props.onCopyRecord(target);
  };

  const planStatusValue = params.plan.status
    ? params.plan.status.toString()
    : "0";
  const resultStatusValue = params.result.status
    ? params.result.status.toString()
    : "0";

  return (
    <>
      {/* ヘルパー番号 */}
      <TableCellWrap
        key={`${idx}-plan-dispatchNo`}
        cellClass={`${classes.middleShortCell} ${props.classOption}`}
      >
        {params.plan.dispatchNo
          ? getLabelFromOptions(
              params.plan.dispatchNo.toString(),
              KYOTAKUKAIGO_DISPATCH_NO_LIST
            )
          : ""}
      </TableCellWrap>

      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-plan-status`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {getLabelFromOptions(planStatusValue, KYOTAKUKAIGO_DISPLAY_STATUS_LIST)}
      </TableCellWrap>

      {/* 開始時間 */}
      <TableCellWrap
        key={`${idx}-plan-in-time`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.plan.inTime ? params.plan.inTime : "-"}
      </TableCellWrap>

      {/* 終了時間 */}
      <TableCellWrap
        key={`${idx}-plan-out-time`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.plan.outTime ? params.plan.outTime : "-"}
      </TableCellWrap>

      {/* 算定時間 */}
      <TableCellWrap
        key={`${idx}-plan-calculated-hours`}
        cellClass={`${classes.shortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.plan.calculatedHours ? params.plan.calculatedHours : "-"}
      </TableCellWrap>

      {/* 乗降 */}
      <TableCellWrap
        key={`${idx}-plan-getting-on-and-off-times`}
        cellClass={`${classes.superShortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.plan.gettingOnAndOff ? params.plan.gettingOnAndOff : "-"}
      </TableCellWrap>

      {/* 人数 */}
      <TableCellWrap
        key={`${idx}-plan-participants-number`}
        cellClass={`${classes.superShortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.plan.numberOfParticipants
          ? params.plan.numberOfParticipants
          : "-"}
      </TableCellWrap>

      {/* 削除 */}
      <TableCellWrap
        key={`${idx}-plan-delete-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {params.plan.inoutResultsId ? (
          <div className={classes.iconDiv}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickPlanDeleteIcon}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-plan-edit-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {params.plan.inoutResultsId ? (
          <div className={classes.iconDiv}>
            <Edit
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={openEditPlanModal}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>

      {/* コピー */}
      <TableCellWrap
        key={`${idx}-copy-icon`}
        cellClass={`${classes.middleCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {params.plan.inoutResultsId && !params.resultExistFlg ? (
          <div className={classes.iconDiv}>
            <KeyboardArrowRight
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onCopyRecord}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>

      {/* ヘルパー番号 */}
      <TableCellWrap
        key={`${idx}-dispatchNo`}
        cellClass={`${classes.middleShortCell} ${props.classOption}`}
      >
        {params.result.dispatchNo
          ? getLabelFromOptions(
              params.result.dispatchNo.toString(),
              KYOTAKUKAIGO_DISPATCH_NO_LIST
            )
          : ""}
      </TableCellWrap>

      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {getLabelFromOptions(
          resultStatusValue,
          KYOTAKUKAIGO_DISPLAY_STATUS_LIST
        )}
      </TableCellWrap>

      {/* 開始時間 */}
      <TableCellWrap
        key={`${idx}-in-time`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.inTime ? params.result.inTime : "-"}
      </TableCellWrap>

      {/* 終了時間 */}
      <TableCellWrap
        key={`${idx}-out-time`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.outTime ? params.result.outTime : "-"}
      </TableCellWrap>

      {/* 算定時間 */}
      <TableCellWrap
        key={`${idx}-calculated-hours`}
        cellClass={`${classes.shortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.calculatedHours ? params.result.calculatedHours : "-"}
      </TableCellWrap>

      {/* 乗降 */}
      <TableCellWrap
        key={`${idx}-getting-on-and-off-Times`}
        cellClass={`${classes.superShortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.gettingOnAndOff ? params.result.gettingOnAndOff : "-"}
      </TableCellWrap>

      {/* 人数 */}
      <TableCellWrap
        key={`${idx}-participants-number`}
        cellClass={`${classes.superShortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.numberOfParticipants
          ? params.result.numberOfParticipants
          : "-"}
      </TableCellWrap>

      {/* 緊急 */}
      <TableCellWrap
        key={`${idx}-emergency-support-flg`}
        cellClass={`${classes.superShortCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.result.emergencySupportFlg === 1
          ? params.result.emergencySupportFlg
          : "-"}
      </TableCellWrap>

      {/* 削除 */}
      <TableCellWrap
        key={`${idx}-delete-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {params.result.inoutResultsId ? (
          <div className={classes.iconDiv}>
            <DeleteIcon
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onClickResultDeleteIcon}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-edit-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {(params.plan.inoutResultsId && !params.resultExistFlg) ||
        params.result.inoutResultsId ? (
          <div className={classes.iconDiv}>
            <Edit
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={openEditResultModal}
            />
          </div>
        ) : (
          ""
        )}
      </TableCellWrap>
    </>
  );
};

export const InOutReportTableCells = withStyles(styles)(
  InOutReportTableCellsCore
);
