import { BasicValues } from "@interfaces/mgr/JUDOHOMONKAIGO/facility/basic";
import { AdditionalItemValues } from "@interfaces/mgr/JUDOHOMONKAIGO/facility/additionItem";
import { FacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/types";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import { SubtractionItemValues } from "@interfaces/mgr/JUDOHOMONKAIGO/facility/subtractionItem";
import {
  NO_SELECTED_CONDITION_TYPE_2024,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import { initializeSpecificBetterSupporterCondition202404V } from "@utils/domain/facility/initializeSpecificBetterSupporterCondition202404V";

export type FacilityValues = BasicValues &
  AdditionalItemValues &
  SubtractionItemValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      masterFlg: state ? state.masterFlg : "1",
      multiFunctionOfficeFlag: state ? state.multiFunctionOfficeFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : ""
    },
    additionalItem: {
      specificBetterSupporterCondition202404: state
        ? initializeSpecificBetterSupporterCondition202404V(
            state.specificBetterSupporterCondition202404
          )
        : NO_SELECTED_CONDITION_TYPE_2024,
      specificBetterSupporterCondition202404Sub:
        state && state.specificBetterSupporterCondition202404
          ? state.specificBetterSupporterCondition202404
          : NO_SELECTED_CONDITION_TYPE_2024_SUB,
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      specificFacilitiesAddition: state
        ? state.specificFacilitiesAddition
        : "0",
      lifeSupportHubInDistrictFlg: state
        ? state.lifeSupportHubInDistrictFlg
        : false,
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false
    },
    subtractionItem: {
      bodyRestrictedStillFlg: state ? state.bodyRestrictedStillFlg : false,
      bodyRestrictedStillFrom: initializeSelectDate(
        state && state.bodyRestrictedStillFrom
      ),
      bodyRestrictedStillTo: initializeSelectDate(
        state && state.bodyRestrictedStillTo
      ),
      abusePreventionMeasuresNotImplementedFlg: state
        ? state.abusePreventionMeasuresNotImplementedFlg
        : false,
      abusePreventionMeasuresNotImplementedFrom: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedFrom
      ),
      abusePreventionMeasuresNotImplementedTo: initializeSelectDate(
        state && state.abusePreventionMeasuresNotImplementedTo
      ),
      informationDisclosureNotReportedFlg: state
        ? state.informationDisclosureNotReportedFlg
        : false,
      informationDisclosureNotReportedFrom: initializeSelectDate(
        state && state.informationDisclosureNotReportedFrom
      ),
      informationDisclosureNotReportedTo: initializeSelectDate(
        state && state.informationDisclosureNotReportedTo
      )
    }
  };
};
