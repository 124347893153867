import { UsageResultsState } from "@stores/v201910/domain/mgr/SHUROTEICHAKU/report/types";
import { InitialDataValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/report/initialData";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";

const initialValues = (state?: UsageResultsState): InitialDataValues => {
  const usageResults: UsageResultsState["usageResults"] =
    state && state.usageResults ? state.usageResults : [];
  const initializedUsageResults = usageResults.map((usageResult) => {
    return {
      // 対象年月日
      targetDate: usageResult.targetDate ? usageResult.targetDate : "",
      // サービス提供の状況
      statusType: determineTrueOrFalse(
        castNumberCheckboxValue(usageResult.statusType)
      ),
      // 特別地域加算
      specialAreaFlg: determineTrueOrFalse(
        castNumberCheckboxValue(usageResult.specialAreaFlg)
      ),
      // 備考
      remarks: usageResult.remarks ? usageResult.remarks : ""
    };
  });

  return { ReportData: initializedUsageResults };
};

export default initialValues;
