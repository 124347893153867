import { GetInitialResponse } from "@api/requests/initial/getInitial";

/**
 * ActionNames
 */
export const FETCH_STARTED = "KEIKAKUSODAN/INITIAL/FETCH_STARTED";
export const FETCH_SUCCESS = "KEIKAKUSODAN/INITIAL/FETCH_SUCCESS";
export const FETCH_FAILED = "KEIKAKUSODAN/INITIAL/FETCH_FAILED";
export const POST_STARTED = "KEIKAKUSODAN/INITIAL/POST_STARTED";
export const POST_SUCCESS = "KEIKAKUSODAN/INITIAL/POST_SUCCESS";
export const POST_FAILED = "KEIKAKUSODAN/INITIAL/POST_FAILED";

export type InitialState = {
  facility: GetInitialResponse["data"]["facility"];
};
