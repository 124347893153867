import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import AdminTemplate from "@components/templates/AdminTemplate";
import MunicipalitiesListTemplate from "@components/templates/MunicipalitiesListTemplate";
import dispatches from "@stores/dispatches";
import { GetFacilityMunicipalitiesResponse } from "@api/requests/facility/getFacilityMunicipalities";
import ErrorsDialog from "@components/organisms/ErrorsDialog";

type DispatchProps = {
  fetchMunicipalitiesInFacility: () => void;
  fetchMunicipalitiesError: () => void;
  clear: () => void;
};

type StateProps = {
  facilityMunicipalities: GetFacilityMunicipalitiesResponse["data"];
};

type Props = StateProps & DispatchProps;

/**
 * 自治体情報
 */
const MunicipalitiesList: React.FC<Props> = ({
  facilityMunicipalities,
  fetchMunicipalitiesInFacility,
  fetchMunicipalitiesError,
  clear
}) => {
  useEffect(() => {
    fetchMunicipalitiesInFacility();
    fetchMunicipalitiesError();
  }, [fetchMunicipalitiesInFacility, fetchMunicipalitiesError]);

  return (
    <AdminTemplate pageName="自治体情報">
      <MunicipalitiesListTemplate
        facilityMunicipalities={facilityMunicipalities}
        clearForm={clear}
      />
      <ErrorsDialog errorsKey="users" />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facilityMunicipalities: state.IDOSHIEN.municipalitiesInFacility
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN, errorsDispatcher } = dispatches;
  const municipalitiesInFacilityDispatched = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );

  return {
    fetchMunicipalitiesInFacility: municipalitiesInFacilityDispatched.fetch,
    clear: municipalitiesInFacilityDispatched.clear,
    fetchMunicipalitiesError: errorsDispatcher(dispatch).users
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MunicipalitiesList);
