import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/inOutConsultationResults/postInOutConsultationResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchKEIKAKUSODANDailyStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_DAILY_STARTED } as const);
export const fetchKEIKAKUSODANDaily = (res: types.ReportState["reportDaily"]) =>
  ({ type: types.FETCH_KEIKAKUSODAN_DAILY, payload: res } as const);
export const fetchKEIKAKUSODANDailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KEIKAKUSODAN_DAILY_FAILED, error: err } as const);
export const fetchKEIKAKUSODANUserStarted = () =>
  ({ type: types.FETCH_KEIKAKUSODAN_MONTHLY_STARTED } as const);
export const fetchKEIKAKUSODANUser = (
  res: types.ReportState["reportMonthly"]
) => ({ type: types.FETCH_KEIKAKUSODAN_MONTHLY, payload: res } as const);
export const fetchKEIKAKUSODANUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KEIKAKUSODAN_MONTHLY_FAILED, error: err } as const);

export const postKEIKAKUSODANReportStarted = () =>
  ({ type: types.POST_KEIKAKUSODAN_REPORT_STARTED } as const);
export const postKEIKAKUSODANReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KEIKAKUSODAN_REPORT_DAILY, payload: req } as const);
export const postKEIKAKUSODANReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KEIKAKUSODAN_REPORT_MONTHLY, payload: req } as const);
export const postKEIKAKUSODANReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KEIKAKUSODAN_REPORT_FAILED, error: err } as const);

export const postFacilitiesKEIKAKUSODANMonthlyStarted = () =>
  ({ type: types.POST_KEIKAKUSODAN_FACILITY_MONTHLY_STARTED } as const);
export const postFacilitiesKEIKAKUSODANMonthly = (
  req: types.ReportState["reportDaily"]["inoutResultDaily"]
) =>
  ({ type: types.POST_KEIKAKUSODAN_FACILITY_MONTHLY, payload: req } as const);
export const postFacilitiesKEIKAKUSODANMonthlyFailed = (err: {
  error: { response: ErrorResponse };
}) =>
  ({
    type: types.POST_KEIKAKUSODAN_FACILITY_MONTHLY_FAILED,
    error: err
  } as const);

export type ActionTypes =
  | ReturnType<typeof fetchKEIKAKUSODANDailyStarted>
  | ReturnType<typeof fetchKEIKAKUSODANDaily>
  | ReturnType<typeof fetchKEIKAKUSODANDailyFailed>
  | ReturnType<typeof fetchKEIKAKUSODANUserStarted>
  | ReturnType<typeof fetchKEIKAKUSODANUser>
  | ReturnType<typeof fetchKEIKAKUSODANUserFailed>
  | ReturnType<typeof postKEIKAKUSODANReportStarted>
  | ReturnType<typeof postKEIKAKUSODANReportDaily>
  | ReturnType<typeof postKEIKAKUSODANReportUser>
  | ReturnType<typeof postKEIKAKUSODANReportFailed>
  | ReturnType<typeof postFacilitiesKEIKAKUSODANMonthlyStarted>
  | ReturnType<typeof postFacilitiesKEIKAKUSODANMonthly>
  | ReturnType<typeof postFacilitiesKEIKAKUSODANMonthlyFailed>;
