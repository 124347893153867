import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { FormikProps, getIn } from "formik";
import { FacilityValues as GHValues } from "@initialize/mgr/GroupHome/facility/initialValues";
import { FacilityValues as IABValues } from "@initialize/mgr/IAB/facility/initialValues";
import { FacilityValues as SEIKATSUKAIGOValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";
import { FacilityValues as JIRITSUKUNRENSEIKATSUValues } from "@initialize/mgr/JIRITSUKUNRENSEIKATSU/facility/initialValues";

const styles = (): StyleRules =>
  createStyles({
    dateWrap: {
      margin: "-20px 0 30px 32px"
    },
    helperText: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)"
    }
  });

type OwnProps = {
  formikProps: FormikProps<
    GHValues | IABValues | SEIKATSUKAIGOValues | JIRITSUKUNRENSEIKATSUValues
  >;
  tooltip: JSX.Element;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 福祉専門職員配置加算
 */
const WelfareSpecialistPlacementTypeFields: React.FC<Props> = (props) => {
  const { setFieldValue } = props.formikProps;
  const initNotSELECTED = { year: "NOT_SELECTED", month: "", day: "" };

  // 福祉専門職員配置等加算
  const welfareSpecialistPlacementType1: string = getIn(
    props.formikProps.values,
    "additionalItem.welfareSpecialistPlacementType1"
  );
  const welfareSpecialistPlacementType2: string = getIn(
    props.formikProps.values,
    "additionalItem.welfareSpecialistPlacementType2"
  );
  const welfareSpecialistPlacementType3: string = getIn(
    props.formikProps.values,
    "additionalItem.welfareSpecialistPlacementType3"
  );
  // 福祉専門職員配置等加算 (Ⅰ)のdisabled-state
  const [
    disableWelfareSpecialistPlacementType1,
    setDisableWelfareSpecialistPlacementType1
  ] = React.useState(false);
  // 福祉専門職員配置等加算 (Ⅱ)のdisabled-state
  const [
    disableWelfareSpecialistPlacementType2,
    setDisableWelfareSpecialistPlacementType2
  ] = React.useState(false);
  React.useEffect(() => {
    if (welfareSpecialistPlacementType2) {
      props.formikProps.setFieldValue(
        "additionalItem.welfareSpecialistPlacementType1",
        false
      );
      setDisableWelfareSpecialistPlacementType1(true);
    } else {
      setDisableWelfareSpecialistPlacementType1(false);
    }
    // 変更時、開始・終了年月日は初期値に戻す
    if (
      !welfareSpecialistPlacementType1 &&
      !welfareSpecialistPlacementType2 &&
      !welfareSpecialistPlacementType3
    ) {
      setFieldValue(
        "additionalItem.welfareConditionStartDate",
        initNotSELECTED
      );
      setFieldValue("additionalItem.welfareConditionEndDate", initNotSELECTED);
    }
  }, [welfareSpecialistPlacementType2]);
  React.useEffect(() => {
    if (welfareSpecialistPlacementType1) {
      props.formikProps.setFieldValue(
        "additionalItem.welfareSpecialistPlacementType2",
        false
      );
      setDisableWelfareSpecialistPlacementType2(true);
    } else {
      setDisableWelfareSpecialistPlacementType2(false);
    }
    // 変更時、開始・終了年月日は初期値に戻す
    if (
      !welfareSpecialistPlacementType1 &&
      !welfareSpecialistPlacementType2 &&
      !welfareSpecialistPlacementType3
    ) {
      setFieldValue(
        "additionalItem.welfareConditionStartDate",
        initNotSELECTED
      );
      setFieldValue("additionalItem.welfareConditionEndDate", initNotSELECTED);
    }
  }, [welfareSpecialistPlacementType1]);
  React.useEffect(() => {
    // 変更時、開始・終了年月日は初期値に戻す
    if (
      !welfareSpecialistPlacementType1 &&
      !welfareSpecialistPlacementType2 &&
      !welfareSpecialistPlacementType3
    ) {
      setFieldValue(
        "additionalItem.welfareConditionStartDate",
        initNotSELECTED
      );
      setFieldValue("additionalItem.welfareConditionEndDate", initNotSELECTED);
    }
  }, [welfareSpecialistPlacementType3]);

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Typography style={{ margin: "0px 8px 0px 0px" }}>
          福祉専門職員配置等加算
        </Typography>
        <HelpToolTip
          title={<HelpTipMessages name="welfareSpecialistPlacementType" />}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: 260,
          margin: "16px 0 28px 16px"
        }}
      >
        <FormikCheckbox
          name="additionalItem.welfareSpecialistPlacementType1"
          label="福祉専門職員配置等加算 (Ⅰ)"
          style={{ marginBottom: 0 }}
          disabled={disableWelfareSpecialistPlacementType1}
        />
        <FormikCheckbox
          name="additionalItem.welfareSpecialistPlacementType2"
          label="福祉専門職員配置等加算 (Ⅱ)"
          style={{ marginBottom: 0 }}
          disabled={disableWelfareSpecialistPlacementType2}
        />
        <FormikCheckbox
          name="additionalItem.welfareSpecialistPlacementType3"
          label="福祉専門職員配置等加算 (Ⅲ)"
          style={{ marginBottom: 0 }}
        />
      </div>

      {(welfareSpecialistPlacementType1 ||
        welfareSpecialistPlacementType2 ||
        welfareSpecialistPlacementType3) && (
        <div className={props.classes.dateWrap}>
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.welfareConditionStartDate"
            label="開始年月日"
            setFormikFieldValue={setFieldValue}
            addYearTo={1}
            style={{ marginBottom: 22 }}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.welfareConditionEndDate"
            label="終了年月日"
            setFormikFieldValue={setFieldValue}
            addYearTo={5}
            style={{ marginBottom: 0 }}
          />
          <span className={props.classes.helperText}>
            開始年月日または終了年月日は、決まっている場合のみ入力してください
          </span>
        </div>
      )}
    </div>
  );
};

export default React.memo(
  withStyles(styles)(WelfareSpecialistPlacementTypeFields)
);
