import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import ReportDaily from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Report/Daily";
import ReportMonthly from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Report/Monthly";
import ReportDaily201910 from "@components/v201910/pages/mgr/JIRITSUKUNRENSEIKATSU/report/Daily";
import ReportMonthly201910 from "@components/v201910/pages/mgr/JIRITSUKUNRENSEIKATSU/report/Monthly";
import ReportDaily202104 from "@components/v202104/pages/mgr/JIRITSUKUNRENSEIKATSU/report/Daily";
import ReportMonthly202104 from "@components/v202104/pages/mgr/JIRITSUKUNRENSEIKATSU/report/Monthly";

// 利用者情報
import UsersList from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Users/UsersList";
import CreateUser from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Users/CreateUser";
import EditUser from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Users/EditUser";
import CreateUser201910 from "@components/v201910/pages/mgr/JIRITSUKUNRENSEIKATSU/users/CreateUser";
import EditUser201910 from "@components/v201910/pages/mgr/JIRITSUKUNRENSEIKATSU/users/EditUser";
import EditUser202104 from "@components/v202104/pages/mgr/JIRITSUKUNRENSEIKATSU/users/EditUser";

// 事業所情報
import Facility from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/Facility";
import Facility201910 from "@components/v201910/pages/mgr/JIRITSUKUNRENSEIKATSU/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/JIRITSUKUNRENSEIKATSU/Facility";

// 初期設定情報
import InitialData from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/initialData";

// 記録機能
import RecordRoutes from "@app/CommonRoutes/RecordRoutes";
import { DailyRecord } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/Daily";
import { OperationsRecord } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/Operations";
import { UserDetail } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/UserDetail";
import SupportPlan from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/SupportPlan";
import { SupportPlanNew } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/SupportPlanNew";
import { SupportPlanList } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/SupportPlanList";
import { RecordUsersSummary } from "@components/pages/mgr/JIRITSUKUNRENSEIKATSU/record/UsersSummary";

interface OwnProps {
  user: UserState;
}

type Props = OwnProps;

/**
 * 自立訓練（生活訓練）施設でログインした時だけ有効になるルーティング
 */
const JIRITSUKUNRENSEIKATSURoutes: React.FunctionComponent<Props> = ({
  user
}) => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />
    <AdminRoute path={URL.REPORT_DAILY_201910} component={ReportDaily201910} />
    <AdminRoute
      path={URL.REPORT_USERS_201910}
      component={ReportMonthly201910}
    />
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute
      exact
      path={URL.USERS_NEW_201910}
      component={CreateUser201910}
    />
    <AdminRoute exact path={URL.USERS_ID_201910} component={EditUser201910} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />
    <AdminRoute path={URL.FACILITY_201910} component={Facility201910} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    {/* 初期設定情報 */}
    <AdminRoute path={URL.INITIAL} component={InitialData} />
    {/* 記録機能 */}
    {RecordRoutes}
    <AdminRoute exact path="/record/daily/:yyyymmdd?" component={DailyRecord} />
    <AdminRoute exact path="/record/operations" component={OperationsRecord} />
    <AdminRoute
      exact
      path="/record/operations/:year/:month"
      component={OperationsRecord}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/new"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId/copy"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId"
      component={SupportPlan}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support|work|interview)/:year?/:month?"
      component={UserDetail}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support_plan)/:year?/:month?"
      component={SupportPlanList}
    />
    <AdminRoute
      exact
      path="/record/users_summary"
      component={RecordUsersSummary}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);

export default JIRITSUKUNRENSEIKATSURoutes;
