import { Checkbox } from "@constants/variables";

const castStringCheckboxValue = (
  value: string | null | undefined
): Checkbox => {
  if (!value) {
    return Checkbox.OFF;
  }
  return value === "1" ? Checkbox.ON : Checkbox.OFF;
};

export default castStringCheckboxValue;
