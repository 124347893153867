import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetMeetingRecordResponse = BaseResponse<{
  meeting_records_id: number;
  creation_date: string;
  creation_staff: CreationStaff | null;
  meeting_date: string | null;
  meeting_start_time: string | null;
  meeting_end_time: string | null;
  meeting_place: string | null;
  section: number;
  attendees: Attendee[] | null;
  consideration_item: string | null;
  consideration_contents: string | null;
  consideration_results: string | null;
  others: string | null;
  type_consultation?: number;
  created_at: string;
  updated_at: string;
}>;

export type CreationStaff = {
  id: number;
  name: string;
  facility_id: number;
  snapshot_name: string;
};

export type Attendee = {
  affiliation: string;
  name: string;
};

/**
 * サービス担当者会議議事録のデータ取得
 * @param uifId 事業所所属ユーザーのID
 * @param meetingRecordId 会議議事録のID
 */
export const getMeetingRecord = async (
  uifId: string,
  meetingRecordId: string
): Promise<AxiosResponse<GetMeetingRecordResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/meeting_record/${meetingRecordId}`;
  return request.get<GetMeetingRecordResponse>(url);
};
