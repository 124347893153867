import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { AssessmentState } from "@stores/domain/assessment/types";
import { UsersInFacilityState as KYOTAKUKAIGOUsersInFacilityState } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as JUDOHOMONKAIGOUsersInFacilityState } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as DOKOENGOUsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";

import dispatches from "@stores/dispatches";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  ASSESSMENT_CATEGORY_TYPE,
  ASSESSMENT_CATEGORY_TYPE_NAME
} from "@constants/variables";

// utils
import { dateToLocalisedString } from "@utils/date";
import getAge from "@utils/date/getAge";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 14,
      marginBottom: 20
    },
    headerTitle: {
      fontSize: 18
    },
    headerCreate: {
      fontSize: 10
    },
    userInfo: {
      display: "flex",
      justifyContent: "flex-start"
    },
    userInfoNameContainer: {
      width: 288,
      borderBottom: "1px solid #000"
    },
    userInfoBirthContainer: {
      width: 128,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoGenderContainer: {
      width: 64,
      marginLeft: 16,
      borderBottom: "1px solid #000"
    },
    userInfoItem: {
      lineHeight: 1.5,
      fontSize: 8,
      color: "#424242",
      marginBottom: 4
    },
    userInfoValue: {
      fontSize: 10,
      color: "#212121",
      paddingBottom: 5
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "0.5px 2px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 24,
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.text": {
          paddingLeft: 8,
          wordWrap: "break-word"
        },
        "&.center": {
          textAlign: "center"
        },
        "&.small": {
          padding: 0,
          transform: "scale(0.8)"
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dotted"
        },
        "&.borderNormalLeft": {
          borderLeft: "1px solid"
        },
        "&.topAlign": {
          verticalAlign: "top",
          padding: 3
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    tableCategory: {
      width: 73
    },
    tableItem: {
      width: 142
    },
    tableChoice: {
      width: 26
    },
    tableChoice2lineContainer: {
      maxHeight: 24,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      lineHeight: "14px"
    },
    tableChoiceLine1: {
      whiteSpace: "nowrap"
    },
    tableChoiceLine2: {
      marginTop: -3,
      whiteSpace: "nowrap"
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    categoryName: {
      marginTop: 24,
      width: "100%",
      fontFamily: "HiraginoSans-W6",
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginBottom: 10,
      paddingBottom: 5,
      whiteSpace: "initial"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  user: UserState;
  assessmentRecord: AssessmentState["assessment"];
  userInFacility:
    | KYOTAKUKAIGOUsersInFacilityState["user"]
    | JUDOHOMONKAIGOUsersInFacilityState["user"]
    | DOKOENGOUsersInFacilityState["user"]
    | KODOENGOUsersInFacilityState["user"];
  customRecords: CustomRecordsWithCategoryState;
};

interface OwnProps {
  uifId: string;
  assessmentId: string;
}
interface DispatchProps {
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  fetchCustomRecords: () => Promise<void>;
  getUserDetailInFacilityData: (
    uifId: string,
    facility_type: FacilityType
  ) => void;
}

type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  userInFacility: StateProps["userInFacility"];
  assessmentRecord: AssessmentState["assessment"];
  customRecords: CustomRecordsWithCategoryState;
  classes: Record<string, string>;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;
type contentsProps = {
  customRecords: CustomRecordsWithCategoryState;
  assessmentRecords: AssessmentState["assessment"]["assessment"]["assessment_records"];
  categoryType: number;
  classes: Record<string, string>;
};

const Contents = (props: contentsProps): JSX.Element => {
  const { customRecords, assessmentRecords, categoryType, classes } = props;

  // 選択肢情報からテーブルヘッダーを作成
  let header: JSX.Element;
  const getHeader = (
    choices: { id: number; string: string[] }[]
  ): JSX.Element => {
    return (
      <tr className="label borderBold">
        <td className={`${classes.tableCategory} label`}>カテゴリー</td>
        <td className={`${classes.tableItem} label`}>項目</td>
        {choices.map((c) => (
          <td
            key={`td-choice-${c.id}`}
            className={`${classes.tableChoice} small label borderDashed`}
          >
            <div className={classes.tableChoice2lineContainer}>
              {c.string.map((string, idx) =>
                idx === 0 ? (
                  <div key={`d1-${c.id}`} className={classes.tableChoiceLine1}>
                    {string}
                  </div>
                ) : (
                  <div key={`d2-${c.id}`} className={classes.tableChoiceLine2}>
                    {string}
                  </div>
                )
              )}
            </div>
          </td>
        ))}
        <td className={`${classes.tableRemarks} label borderNormalLeft`}>
          備考
        </td>
      </tr>
    );
  };

  // カテゴリータイプに対応する選択肢項目
  switch (categoryType) {
    case ASSESSMENT_CATEGORY_TYPE.everyday_life:
      header = getHeader([
        { id: 1, string: ["自立"] },
        { id: 2, string: ["ほぼ", "自立"] },
        { id: 3, string: ["一部", "介助"] },
        { id: 4, string: ["多くを", "介助"] },
        { id: 5, string: ["全て", "介助"] }
      ]);
      break;
    case ASSESSMENT_CATEGORY_TYPE.psychosomatic_state:
      header = getHeader([
        { id: 1, string: ["普通"] },
        { id: 2, string: ["やや", "困難"] },
        { id: 3, string: ["困難"] }
      ]);
      break;
    case ASSESSMENT_CATEGORY_TYPE.fault_characteristics:
      header = getHeader([
        { id: 1, string: ["あり"] },
        { id: 2, string: ["なし"] }
      ]);
      break;
    case ASSESSMENT_CATEGORY_TYPE.remarks:
    default:
      header = <></>;
      break;
  }

  // 記録項目に対応する保存内容を取得
  const getInput = (
    item: CustomRecordsWithCategoryState[number]["custom_record_items"][number]
  ): AssessmentState["assessment"]["assessment"]["assessment_records"][number]["input"][number] => {
    const categoryIdx = assessmentRecords.findIndex(
      (r) => r.custom_records_category_id === item.custom_records_category_id
    );
    if (categoryIdx !== -1) {
      const inputIdx = assessmentRecords[categoryIdx].input.findIndex(
        (i) => i.custom_record_item_id === item.id
      );
      if (inputIdx !== -1) {
        return assessmentRecords[categoryIdx].input[inputIdx];
      }
    }
    return {
      id: 0,
      custom_record_item_id: 0,
      input_data: ""
    };
  };

  // 入力がある記録項目のみ取得してソート
  const sortedCustomRecords = customRecords
    .filter((r) => r.category_type === categoryType)
    .map((r) => {
      const custom_record_items = r.custom_record_items
        .filter((i) => {
          const input = getInput(i);
          return input.input_data || !!input.choiced_item_id;
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...r, custom_record_items };
    })
    .filter((r) => {
      return r.custom_record_items.length > 0;
    })
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

  const getInputTable = (): JSX.Element[][] => {
    return sortedCustomRecords.map((r) => {
      return r.custom_record_items.map((i, iIdx) => {
        const input = getInput(i);
        return (
          <tr key={`tr-${i.id}`}>
            {/* カテゴリー列は特記事項にはない、かつ初回のみrowSpan */}
            {categoryType !== ASSESSMENT_CATEGORY_TYPE.remarks && iIdx === 0 && (
              <td rowSpan={r.custom_record_items.length} className="text">
                {r.name}
              </td>
            )}
            <td className={`${classes.tableItem} text`}>{i.name}</td>
            {i.custom_record_item_choices.map((c) => {
              return (
                <td key={`choice-${c.id}`} className="center borderDashed">
                  {input.choiced_item_id === c.id && "●"}
                </td>
              );
            })}
            <td className="text borderNormalLeft">{input.input_data}</td>
          </tr>
        );
      });
    });
  };

  if (sortedCustomRecords.length === 0) {
    return <></>;
  }
  return (
    <>
      <div className={classes.categoryName}>
        {ASSESSMENT_CATEGORY_TYPE_NAME[categoryType]}
      </div>
      <table className={`${classes.table} fullWidth`}>
        <tbody>
          {header}
          {getInputTable()}
        </tbody>
      </table>
    </>
  );
};

const AssessmentPrintCore = (props: Props): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);
  const {
    uifId,
    assessmentId,
    classes,
    userInFacility,
    assessmentRecord,
    customRecords,
    user,
    getUserDetailInFacilityData,
    fetchAssessment,
    fetchCustomRecords
  } = props;

  React.useEffect(() => {
    getUserDetailInFacilityData(uifId, user.facility_type);
    fetchAssessment(uifId, assessmentId);
    fetchCustomRecords();
    setRenderFlg(true);
  }, []);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }

  if (customRecords.length === 0 || !assessmentRecord.created_at) {
    return null;
  }
  return (
    <Sheet
      classes={classes}
      userInFacility={userInFacility}
      assessmentRecord={assessmentRecord}
      customRecords={customRecords}
    />
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, userInFacility, assessmentRecord, customRecords } = props;

  const userDetail = userInFacility.user_in_facility;

  const getAuthorString = (): string => {
    const { author } = assessmentRecord;
    if (!author) {
      return "-";
    }
    const authorName = author.snapshot_name || author.name;
    const authorRole = author.snapshot_role || author.role;
    return `${authorName}（${authorRole}）`;
  };
  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerTitle}>アセスメントシート</div>
        <div>
          <div className={classes.headerCreate}>
            作成日：
            {assessmentRecord.assessment.target_date
              ? dateToLocalisedString(
                  assessmentRecord.assessment.target_date,
                  "YYYY年M月D日"
                )
              : "-"}
          </div>
          <div className={classes.headerCreate}>
            作成者：{getAuthorString()}
          </div>
        </div>
      </div>
      <div className={classes.userInfo}>
        <div className={classes.userInfoNameContainer}>
          <span className={classes.userInfoItem}>氏名（フリガナ）</span>
          <div className={classes.userInfoValue}>
            <span>{`${userDetail.name_sei} ${userDetail.name_mei}（${userDetail.name_sei_kana} ${userDetail.name_mei_kana}）`}</span>
          </div>
        </div>
        <div className={classes.userInfoBirthContainer}>
          <span className={classes.userInfoItem}>生年月日(年齢)</span>
          <div className={classes.userInfoValue}>
            <span>
              {dateToLocalisedString(
                userDetail.date_birth !== undefined
                  ? userDetail.date_birth
                  : "",
                "YYYY年M月D日"
              )}
              {`(${getAge(
                userDetail.date_birth,
                assessmentRecord.created_at
              )}歳)`}
            </span>
          </div>
        </div>
        <div className={classes.userInfoGenderContainer}>
          <span className={classes.userInfoItem}>性別</span>
          <div className={classes.userInfoValue}>
            <span>{userDetail.gender === "1" ? "男性" : "女性"}</span>
          </div>
        </div>
      </div>
      {[
        ASSESSMENT_CATEGORY_TYPE.everyday_life,
        ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
        ASSESSMENT_CATEGORY_TYPE.fault_characteristics,
        ASSESSMENT_CATEGORY_TYPE.remarks
      ].map((type) => (
        <Contents
          key={type}
          customRecords={customRecords}
          assessmentRecords={assessmentRecord.assessment.assessment_records}
          categoryType={type}
          classes={classes}
        />
      ))}
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const user = state.user as UserState;
  const assessmentRecord = state.assessment.assessment;
  const getUserInFacility = (): StateProps["userInFacility"] => {
    switch (user.facility_type) {
      case FacilityType.KYOTAKUKAIGO:
        return state.KYOTAKUKAIGO.userInFacility.user;
      case FacilityType.JUDOHOMONKAIGO:
        return state.JUDOHOMONKAIGO.userInFacility.user;
      case FacilityType.DOKOENGO:
        return state.DOKOENGO.userInFacility.user;
      case FacilityType.KODOENGO:
        return state.KODOENGO.userInFacility.user;
      default:
        return state.KYOTAKUKAIGO.userInFacility.user;
    }
  };
  const customRecords = state.customRecordsWithCategory.assesment;
  return {
    user,
    assessmentRecord,
    userInFacility: getUserInFacility(),
    customRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { assessmentDispatcher, customRecordsWithCategory } = dispatches;

  const fetchAssessment = (
    uifId: string,
    assessmentId: string
  ): Promise<void> =>
    assessmentDispatcher(dispatch).fetchAssessment(uifId, assessmentId);

  const getUserDetailInFacilityData = (
    uifId: string,
    facility_type: FacilityType
  ): Promise<void> => {
    switch (facility_type) {
      case FacilityType.KYOTAKUKAIGO:
        return dispatches.KYOTAKUKAIGO.userInFacilityDispatcher(
          dispatch
        ).fetchOne(uifId);
      case FacilityType.JUDOHOMONKAIGO:
        return dispatches.JUDOHOMONKAIGO.userInFacilityDispatcher(
          dispatch
        ).fetchOne(uifId);
      case FacilityType.DOKOENGO:
        return dispatches.DOKOENGO.userInFacilityDispatcher(dispatch).fetchOne(
          uifId
        );
      case FacilityType.KODOENGO:
        return dispatches.KODOENGO.userInFacilityDispatcher(dispatch).fetchOne(
          uifId
        );
      default:
        return dispatches.KYOTAKUKAIGO.userInFacilityDispatcher(
          dispatch
        ).fetchOne(uifId);
    }
  };

  const fetchCustomRecords = (): Promise<void> =>
    customRecordsWithCategory(dispatch).fetchCustomRecords(
      CUSTOM_RECORD_TARGET_TYPE.assessment
    );

  return {
    fetchAssessment,
    fetchCustomRecords,
    getUserDetailInFacilityData
  };
};

export const AssessmentPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AssessmentPrintCore));
