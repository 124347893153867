import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ReportState = {
  reportDaily: {
    inoutResultDaily: {
      targetDate: ""
    },
    staffsInFacility: [],
    displayStaffsInFacility: [],
    displayList: [],
    planCount: 0
  },
  reportMonthly: {
    summary: {
      serviceHoursCount: 0
    },
    staffsInFacility: [],
    displayStaffsInFacility: [],
    displayList: [],
    planCount: 0
  }
};

export const DOKOENGOReport = (
  state = initialState,
  action: actions.ActionTypes
): types.ReportState => {
  switch (action.type) {
    case types.FETCH_DOKOENGO_DAILY_STARTED:
      return { ...state };
    case types.FETCH_DOKOENGO_DAILY:
      return {
        ...state,
        reportDaily: action.payload
      };
    case types.FETCH_DOKOENGO_DAILY_FAILED:
      return { ...state };
    case types.FETCH_DOKOENGO_MONTHLY_STARTED:
      return { ...state };
    case types.FETCH_DOKOENGO_MONTHLY:
      return {
        ...state,
        reportMonthly: action.payload
      };
    case types.FETCH_DOKOENGO_MONTHLY_FAILED:
      return { ...state };
    default:
      return { ...state };
  }
};
