import * as React from "react";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import { ServiceProvideStatusFields } from "@components/organisms/mgr/IAB/Users/items/ServiceProvideStatusFields";

import { FacilityState } from "@stores/domain/mgr/IAB/facility/types";

// formik
import { FormikProps } from "formik";
import { UsersValues } from "@initialize/mgr/IAB/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    serviceProvideStatusSection: {
      marginTop: 29,
      marginBottom: 56
    },
    scheduledDate: {
      marginTop: 24
    },
    groupWeeks: {
      margin: "16px 0 0 16px"
    },
    weekDay: {
      marginRight: 18
    },
    formikActionLabel: {
      "&+span": {
        fontSize: 16
      },
      "& label span": {
        fontSize: 16
      }
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
};
type StateProps = {
  facility: FacilityState;
};
type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const StatusFieldsCore = ({
  classes,
  formikProps,
  facility
}: Props): JSX.Element => {
  return (
    <div className={classes.serviceProvideStatusSection}>
      <Typography className={classes.scheduledDate}>通所予定日</Typography>
      <FormGroup row className={classes.groupWeeks}>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.monScheduledFlg"
            label="月"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.tueScheduledFlg"
            label="火"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.wedScheduledFlg"
            label="水"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.thuScheduledFlg"
            label="木"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.friScheduledFlg"
            label="金"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.satScheduledFlg"
            label="土"
            className={classes.formikActionLabel}
          />
        </div>
        <div className={classes.weekDay}>
          <FormikCheckbox
            name="serviceUse.sunScheduledFlg"
            label="日"
            className={classes.formikActionLabel}
          />
        </div>
      </FormGroup>
      <GrayMiddleHeading text="サービス提供状況の自動入力" />
      <ServiceProvideStatusFields
        formikProps={formikProps}
        facility={facility}
      />
    </div>
  );
};

export const StatusFields = withStyles(styles)(StatusFieldsCore);
