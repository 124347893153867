import { FacilityState } from "@stores/v202104/domain/mgr/KYOTAKUKAIGO/facility/types";
import { GetFacilityResponse } from "@api/requests/v202104/facility/getFacility";
import { PostFacilityParams } from "@api/requests/v202104/facility/postFacility";
import { FacilityValues } from "@initialize/v202104/mgr/KYOTAKUKAIGO/facility/initialValues";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  STRING_FALSE_FROM_API,
  STRING_TRUE_FROM_API,
  DEFAULT_RADIO_VALUE,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES
} from "@constants/variables";
import { STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE } from "@constants/mgr/KYOTAKUKAIGO/variables";
import castString from "@utils/dataNormalizer/castString";
import { isNil } from "lodash-es";

// , formatTime
/**
 * Normalized Type
 */
export type NormalizedGetFacilityResponse = FacilityState;
export type NormalizedPostFacilityParams = FacilityState;

/**
 * GetFacilityResponse === PostFacilityParams前提の共通化
 */
const normalizeApiParams = (
  result: GetFacilityResponse["data"] | PostFacilityParams
): FacilityState => {
  const { facility } = result;
  const facilityKYOTAKUKAIGO = result.facility_kyotakukaigo || {
    facility_type: 0,
    specific_facilities_addition: 0,
    life_support_hub_in_district_flg: 0
  };
  const units =
    result && result.units
      ? result.units.map((unit) => ({
          id: unit.id,
          unit_name: `${unit.unit_name || ""}`,
          same_building_subtraction_flg:
            unit.same_building_subtraction_flg === INT_TRUE_FROM_API,
          is_deleted: unit.is_deleted === INT_TRUE_FROM_API,
          results_use_flg: unit.results_use_flg === INT_TRUE_FROM_API
        }))
      : [];

  return {
    units,
    corporationName:
      facility.gov_business_owner !== null ? facility.gov_business_owner : "",
    officeNumber: !isNil(facility.gov_facility_number)
      ? facility.gov_facility_number
      : "",
    officeName: !isNil(facility.name) ? facility.name : "",
    serviceType: facility.type_service,
    representativeName: !isNil(facility.responsible_person)
      ? facility.responsible_person
      : "",
    capacity: facility.capacity !== null ? facility.capacity.toString() : "",
    postalCode: facility.postal_code !== null ? facility.postal_code : "",
    selectedPrefectureName: facility.prefecture_name
      ? facility.prefecture_name
      : "NOT_SELECTED",
    selectedCityCode:
      facility.city_id !== null ? facility.city_id.toString() : "NOT_SELECTED",
    restAddress: facility.address !== null ? facility.address : "",
    tel: !isNil(facility.tel) ? facility.tel : "",
    cityId: facility.city_id !== null ? facility.city_id.toString() : "",
    multiFunctionOfficeFlag:
      facility.multiple_facility_flg === STRING_TRUE_FROM_API,
    masterSubordinateFlg:
      facility.master_subordinate_flg === STRING_TRUE_FROM_API,
    masterFlg: facility.master_flg ? facility.master_flg : "1",
    allCapacity: castString(facility.total_capacity),
    mealSaservedServiceFlag: facility.available_food === STRING_TRUE_FROM_API,
    transferServiceFlag: facility.available_pickup === STRING_TRUE_FROM_API,
    transferServiceType: facility.available_pickup_kind
      ? facility.available_pickup_kind
      : "1",
    seriousSupporterFlg: false,
    staffTreatmentSystemType: facility.better_supporter_condition
      ? facility.better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    staffTreatmentSpecificSystemType: facility.specific_better_supporter_condition
      ? facility.specific_better_supporter_condition
      : DEFAULT_RADIO_VALUE,
    commuterLifeSupportFlag:
      facility.execute_kaizen_flg === STRING_TRUE_FROM_API,
    specificFacilitiesAddition: facilityKYOTAKUKAIGO.specific_facilities_addition
      ? facilityKYOTAKUKAIGO.specific_facilities_addition.toString()
      : STRING_FALSE_FROM_API,
    betterSupporterConditionBaseUpFlg:
      facility.better_supporter_condition_base_up_flg === STRING_TRUE_FROM_API,
    lifeSupportHubInDistrictFlg:
      facilityKYOTAKUKAIGO.life_support_hub_in_district_flg ===
      INT_TRUE_FROM_API,
    buildingManagementFlg: units.length > 0
  };
};

/**
 * フラグがOFFの時、関連する値を消す
 * 入力後にdisabledとなったテキストエリアのテキストを消す
 */
const removeUnnecessaryValue = (
  target: PostFacilityParams
): PostFacilityParams => {
  const result = {
    facility: { ...target.facility },
    facility_kyotakukaigo: target.facility_kyotakukaigo
      ? { ...target.facility_kyotakukaigo }
      : {},
    units: target.units
  };
  if (result.facility.lack_of_service_admin_flg === STRING_FALSE_FROM_API) {
    result.facility.date_start_lack_of_service_admin = null;
  }
  if (result.facility.lack_of_supporter_flg === STRING_FALSE_FROM_API) {
    result.facility.date_start_lack_of_supporter = null;
  }
  if (
    !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      result.facility.better_supporter_condition
    )
  ) {
    result.facility.specific_better_supporter_condition = DEFAULT_RADIO_VALUE;
    result.facility.better_supporter_condition_base_up_flg = STRING_FALSE_FROM_API;
  } else if (
    result.facility_kyotakukaigo.specific_facilities_addition === 0 &&
    result.facility.specific_better_supporter_condition ===
      STAFF_TREATMENT_SPECIFIC_SYSTEM_TYPE.LEVEL1.value
  ) {
    result.facility.specific_better_supporter_condition = DEFAULT_RADIO_VALUE;
  }
  if (result.facility.master_subordinate_flg === STRING_FALSE_FROM_API) {
    result.facility.master_flg = null;
  }

  return result;
};

// パラメータの親子関係マッピング表
const parentParamsMap = {
  facility: {
    master_subordinate_flg: {
      key: "master_subordinate_flg",
      childKeys: ["master_flg"]
    },
    ab_support_ikou_flg: {
      key: "ab_support_ikou_flg",
      childKeys: ["ab_support_ikou_result", "ab_support_ikou_result_number"]
    },
    ab_support_ikou_result: {
      key: "ab_support_ikou_result",
      childKeys: ["ab_support_ikou_result_number"]
    },
    available_pickup: {
      key: "available_pickup",
      childKeys: ["available_pickup_kind", "serious_supporter_flg"]
    },
    mon_active_flg: {
      key: "mon_active_flg",
      childKeys: ["mon_open", "mon_close"]
    },
    mon_open: {
      key: "mon_open",
      childKeys: ["mon_close"]
    },
    mon_close: {
      key: "mon_close",
      childKeys: ["mon_open"]
    },
    tue_active_flg: {
      key: "tue_active_flg",
      childKeys: ["tue_open", "tue_close"]
    },
    tue_open: {
      key: "tue_open",
      childKeys: ["tue_close"]
    },
    tue_close: {
      key: "tue_close",
      childKeys: ["tue_open"]
    },
    wed_active_flg: {
      key: "wed_active_flg",
      childKeys: ["wed_open", "wed_close"]
    },
    wed_open: {
      key: "wed_open",
      childKeys: ["wed_close"]
    },
    wed_close: {
      key: "wed_close",
      childKeys: ["wed_open"]
    },
    thu_active_flg: {
      key: "thu_active_flg",
      childKeys: ["thu_open", "thu_close"]
    },
    thu_open: {
      key: "thu_open",
      childKeys: ["thu_close"]
    },
    thu_close: {
      key: "thu_close",
      childKeys: ["thu_open"]
    },
    fri_active_flg: {
      key: "fri_active_flg",
      childKeys: ["fri_open", "fri_close"]
    },
    fri_open: {
      key: "fri_open",
      childKeys: ["fri_close"]
    },
    fri_close: {
      key: "fri_close",
      childKeys: ["fri_open"]
    },
    sat_active_flg: {
      key: "sat_active_flg",
      childKeys: ["sat_open", "sat_close"]
    },
    sat_open: {
      key: "sat_open",
      childKeys: ["sat_close"]
    },
    sat_close: {
      key: "sat_close",
      childKeys: ["sat_open"]
    },
    sun_active_flg: {
      key: "sun_active_flg",
      childKeys: ["sun_open", "sun_close"]
    },
    sun_open: {
      key: "sun_open",
      childKeys: ["sun_close"]
    },
    sun_close: {
      key: "sun_close",
      childKeys: ["sun_open"]
    },
    better_supporter_condition: {
      key: "better_supporter_condition",
      childKeys: [
        "specific_better_supporter_condition",
        "better_supporter_condition_base_up_flg"
      ]
    }
  }
};

/**
 * 子要素の差分判定 子要素に差分がある場合、親要素もパラメータに付与する
 * @param target
 * @param after
 */
const addParentValue = (
  target: PostFacilityParams,
  after: PostFacilityParams
): PostFacilityParams => {
  const result = target;
  Object.keys(parentParamsMap).forEach((facilityKey) => {
    Object.keys(parentParamsMap[facilityKey]).forEach((paramKey) => {
      const childKeys = parentParamsMap[facilityKey][paramKey].childKeys
        ? parentParamsMap[facilityKey][paramKey].childKeys
        : [];
      const isDiffChildren: boolean = childKeys.some((childKey: string) => {
        return result[facilityKey][childKey] !== undefined;
      });
      if (isDiffChildren) {
        result[facilityKey][parentParamsMap[facilityKey][paramKey].key] =
          after[facilityKey][parentParamsMap[facilityKey][paramKey].key];
      }
    });
  });
  return result;
};

export const normalizeGetFacilityResult = (
  response: GetFacilityResponse
): NormalizedGetFacilityResponse => normalizeApiParams(response.data);

export const normalizePostFacilityParams = (
  params: PostFacilityParams
): NormalizedPostFacilityParams => normalizeApiParams(params);

export const removeNoChangeData = (
  after: PostFacilityParams,
  before: PostFacilityParams
): PostFacilityParams => {
  const target: PostFacilityParams = {
    facility: {
      gov_business_owner: after.facility.gov_business_owner,
      gov_facility_number: after.facility.gov_facility_number,
      name: after.facility.name,
      type_service: after.facility.type_service,
      responsible_person: after.facility.responsible_person,
      capacity: after.facility.capacity,
      postal_code: after.facility.postal_code,
      prefecture_name: after.facility.prefecture_name,
      city_id: after.facility.city_id,
      address: after.facility.address,
      tel: after.facility.tel,
      multiple_facility_flg: after.facility.multiple_facility_flg,
      users_vs_supporter_grade: after.facility.users_vs_supporter_grade,
      welfare_condition: after.facility.welfare_condition,
      welfare_condition_start_date: after.facility.welfare_condition_start_date,
      welfare_condition_end_date: after.facility.welfare_condition_end_date,
      better_supporter_condition: after.facility.better_supporter_condition,
      lack_of_supporter_flg: after.facility.lack_of_supporter_flg,
      date_start_lack_of_supporter: after.facility.date_start_lack_of_supporter,
      lack_of_service_admin_flg: after.facility.lack_of_service_admin_flg,
      date_start_lack_of_service_admin:
        after.facility.date_start_lack_of_service_admin,
      origin_local_gov_flg: after.facility.origin_local_gov_flg,
      see_hear_team_flg: after.facility.see_hear_team_flg,
      master_subordinate_flg: undefined,
      // multiple_facility_flgを毎回送信しているため
      // 関係のあるmaster_flgとtotal_capacityも毎回送信する
      master_flg: after.facility.master_flg,
      total_capacity: after.facility.total_capacity,
      available_food: undefined,
      available_pickup: undefined,
      available_pickup_kind: undefined,
      mon_active_flg: undefined,
      tue_active_flg: undefined,
      wed_active_flg: undefined,
      thu_active_flg: undefined,
      fri_active_flg: undefined,
      sat_active_flg: undefined,
      sun_active_flg: undefined,
      mon_open: undefined,
      tue_open: undefined,
      wed_open: undefined,
      thu_open: undefined,
      fri_open: undefined,
      sat_open: undefined,
      sun_open: undefined,
      mon_close: undefined,
      tue_close: undefined,
      wed_close: undefined,
      thu_close: undefined,
      fri_close: undefined,
      sat_close: undefined,
      sun_close: undefined,
      execute_kaizen_flg: undefined,
      better_supporter_condition_base_up_flg: undefined,
      specific_better_supporter_condition: undefined,
      ab_support_ikou_flg: undefined,
      ab_support_ikou_result: undefined,
      ab_support_ikou_result_number: undefined
    },
    facility_kyotakukaigo: {
      specific_facilities_addition: undefined,
      life_support_hub_in_district_flg: undefined
    },
    units: after.units
  };

  Object.keys(target).forEach((key) => {
    Object.keys(target[key]).forEach((param) => {
      if (target[key][param] === undefined) {
        if (`${before[key][param]}` !== `${after[key][param]}`) {
          target[key][param] = after[key][param];
        } else {
          target[key][param] = undefined;
        }
      }
    });
  });

  return addParentValue(target, after);
};

export const normalizeFormValue = (
  state: FacilityValues
): PostFacilityParams => {
  // 新規(id:null)で削除フラグが立っているものと建物名が空のものは弾く
  const filteredUnits = state.units.filter(
    (unit) =>
      !(unit.id === null && unit.is_deleted) &&
      !(unit.id === null && !unit.unit_name)
  );

  const units = filteredUnits
    ? filteredUnits.map((unit) => ({
        id: unit.id,
        unit_name: unit.unit_name,
        same_building_subtraction_flg: unit.same_building_subtraction_flg
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
        // switchがOFFの場合は一括削除する
        is_deleted:
          !state.basic.buildingManagementFlg || unit.is_deleted
            ? INT_TRUE_FROM_API
            : INT_FALSE_FROM_API,
        results_use_flg: unit.results_use_flg
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
        // 居宅介護で利用していない項目は固定値を設定
        night_support_type_202104: 1,
        ave_users_last_fiscal_year: 0
      }))
    : [
        {
          id: 0,
          unit_name: "",
          is_deleted: INT_FALSE_FROM_API,
          night_support_type_202104: 1,
          ave_users_last_fiscal_year: 0
        }
      ];

  return removeUnnecessaryValue({
    facility: {
      gov_business_owner: state.basic.corporationName,
      gov_facility_number: state.basic.officeNumber,
      name: state.basic.officeName,
      type_service: state.basic.serviceType,
      responsible_person: state.basic.representativeName,
      capacity:
        state.basic.capacity !== "" ? parseInt(state.basic.capacity, 10) : null,
      postal_code: state.basic.postalCode,
      prefecture_name: state.basic.prefectureId,
      city_id: parseInt(state.basic.cityId, 10),
      address: state.basic.restAddress,
      tel: state.basic.tel,
      multiple_facility_flg: state.basic.multiFunctionOfficeFlag
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_subordinate_flg: state.basic.masterSubordinateFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API,
      master_flg: state.basic.masterFlg,
      users_vs_supporter_grade: "",
      welfare_condition: "",
      welfare_condition_start_date: "",
      welfare_condition_end_date: "",
      better_supporter_condition: state.additionalItem.staffTreatmentSystemType,
      lack_of_supporter_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_supporter: null,
      lack_of_service_admin_flg: STRING_FALSE_FROM_API,
      date_start_lack_of_service_admin: null,
      origin_local_gov_flg: STRING_FALSE_FROM_API,
      see_hear_team_flg: STRING_FALSE_FROM_API,

      total_capacity:
        state.basic.multiFunctionOfficeFlag && state.basic.allCapacity !== ""
          ? parseInt(state.basic.allCapacity, 10)
          : null,
      specific_better_supporter_condition:
        state.additionalItem.staffTreatmentSpecificSystemType,
      ab_support_ikou_flg: STRING_FALSE_FROM_API,
      ab_support_ikou_result: null,
      ab_support_ikou_result_number: null,
      better_supporter_condition_base_up_flg: state.additionalItem
        .betterSupporterConditionBaseUpFlg
        ? STRING_TRUE_FROM_API
        : STRING_FALSE_FROM_API
    },
    facility_kyotakukaigo: {
      specific_facilities_addition: state.additionalItem
        .specificFacilitiesAddition
        ? parseInt(state.additionalItem.specificFacilitiesAddition, 10)
        : INT_FALSE_FROM_API,
      life_support_hub_in_district_flg: state.additionalItem
        .lifeSupportHubInDistrictFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    },
    units
  });
};

export const normalizePostDifferenceParams = (
  params: PostFacilityParams,
  response: FacilityValues
): PostFacilityParams => {
  return removeNoChangeData(params, normalizeFormValue(response));
};
