import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import HelpIcon from "@material-ui/icons/Help";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import {
  FormGroup,
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core";
import { NUTRITIONIST_PLACEMENT_LIST } from "@constants/mgr/SHISETSUNYUSHO/variables";
import { FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/SHISETSUNYUSHO/facility/initialValues";
import { StyleRules } from "@material-ui/core/styles";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { DEFAULT_SELECT_DATE_VALUE } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    },
    section: {
      marginBottom: 32
    },
    innerItem: {
      marginLeft: -20
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const { formikProps } = props;
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.bodyRestrictedStillFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };
  return (
    <FormPaper>
      <div className={props.classes.section}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.originLocalGovFlg"
        label="地方公共団体が設置"
      />
      <FormikSwitch
        name="subtractionItem.lackOfSupporterFlg"
        label="夜勤職員の欠員"
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="lackOfNightShiftStaffFlag" />}
          />
        }
      >
        <div className={props.classes.innerItem}>
          <FormikSelectYearMonth
            name="subtractionItem.dateStartLackOfSupporter"
            label="減算適応月"
            style={{ marginBottom: 0 }}
          />
          <div className={props.classes.comment}>
            <p>
              減算適応月については該当項目の
              <HelpIcon className={props.classes.helpIcon} />
              を参照してください
            </p>
          </div>
        </div>
      </FormikSwitch>
      <FormikRadioButtons
        name="subtractionItem.nutritionistPlacement"
        label="栄養士配置"
        options={NUTRITIONIST_PLACEMENT_LIST}
      />

      <FormikSwitch
        name="subtractionItem.bodyRestrictedStillFlg"
        label="身体拘束未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>

      <FormikSwitch
        name="subtractionItem.workContinuationNotPlanningFlg"
        label="業務継続計画未策定減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        onChange={onChangeSwitch}
        style={{ marginBottom: 0 }}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
