import _sortBy from "lodash-es/sortBy";
import _isEqual from "lodash-es/isEqual";

import { PostSupportRecordUserParams } from "@api/requests/supports/Cseg/postSupportRecordUser";
import { SupportRecordFormValues } from "@initialize/mgr/Cseg/record/support/initialValues";
import { normalizeMultiSelectStaffs } from "@utils/domain/staffs/normalizeMultiSelectStaffs";

type PostSupport = PostSupportRecordUserParams["support"][number];

export const normalizePostSupportRecordUserFormToApi = (
  editingRecordDate: string,
  formValues: SupportRecordFormValues[],
  initialValues: SupportRecordFormValues[]
): PostSupportRecordUserParams => {
  const editingRecord = formValues.find(
    (v) => v.target_date === editingRecordDate
  );
  const initialRecord = initialValues.find(
    (v) => v.target_date === editingRecordDate
  );

  if (!editingRecord) {
    return { support: [] };
  }

  const { displayStaffIds, staffs, ...rest } = editingRecord;

  // staffsにnullが入るのは支援記録未作成の場合のみで、初回保存時は選択した値しか使用しないため空配列を渡す
  const staffsParam = normalizeMultiSelectStaffs(displayStaffIds, staffs || []);
  const supportData: PostSupport = { ...rest, staffs: staffsParam };

  // 変更していないデータはkeyを削除してPOSTすることで、更新させない（同時編集）
  if (initialRecord) {
    if (initialRecord.support_content === supportData.support_content)
      delete supportData.support_content;
    if (initialRecord.staff_comment === supportData.staff_comment)
      delete supportData.staff_comment;
    // staffs
    const beforeIds =
      initialRecord && initialRecord.staffs
        ? initialRecord.staffs.map((s) => s.id)
        : [];
    const afterIds = staffsParam ? staffsParam.map((s) => s.staff_id) : [];
    if (_isEqual(_sortBy(beforeIds), _sortBy(afterIds))) {
      delete supportData.staffs;
    }
  }

  const supportRecord = { support: [supportData] };

  return supportRecord;
};
