const storageKey = {
  token: "token",
  refreshToken: "refreshToken",
  remainedUserName: "remainedUserName",
  facilityId: "facility_id"
} as const;

export function getToken(): string | null {
  if (!window.localStorage) {
    return null;
  }
  return window.localStorage.getItem(storageKey.token);
}

export function setToken(token: string): void {
  return window.localStorage.setItem(storageKey.token, token);
}

export const userCacheClear = (): void => {
  window.localStorage.removeItem(storageKey.token);
  window.localStorage.removeItem(storageKey.refreshToken);
  window.localStorage.removeItem(storageKey.remainedUserName);
  window.localStorage.removeItem(storageKey.facilityId);
};

export function setRefreshToken(token: string): void {
  return window.localStorage.setItem(storageKey.refreshToken, token);
}

export function getRemainedUser(): string | null {
  return window.localStorage.getItem(storageKey.remainedUserName);
}

export function setRemainedUser(userName: string | null): void {
  if (!userName) {
    return;
  }
  window.localStorage.setItem(storageKey.remainedUserName, userName);
}

export function clearRemainedUser(): void {
  return window.localStorage.removeItem(storageKey.remainedUserName);
}

export function getFacilityId(): string | null {
  return window.localStorage.getItem(storageKey.facilityId);
}

export function setFacilityId(facility_id: number | null): void {
  if (!facility_id) {
    return;
  }
  window.localStorage.setItem(storageKey.facilityId, String(facility_id));
}

export function getModalStampIconKey(
  uniqKey: string | undefined,
  uifId: string | undefined,
  accountId: number | undefined
): string {
  return `${uniqKey}_print_stamp_icon_${uifId}_${accountId}`;
}

export function getModalStampIconCheck(key: string): boolean | null {
  const savedValue = window.localStorage.getItem(key);
  return savedValue === null ? null : savedValue === "true";
}

export function setModalStampIconCheck(key: string, value: boolean): void {
  return window.localStorage.setItem(key, value.toString());
}
