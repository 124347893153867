import { GetMonitoringMonthResponse } from "@api/requests/monitoringMonth/getMonitoringMonth";
import { InitialMonitoringMonthValues } from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";
import { SelectMonthValue } from "@interfaces/ui/form";
import { dateToSelectMonthValue } from "@utils/date/dateToSelectMonthValue";

export const initialMonitoringMonthValues = (
  value?: GetMonitoringMonthResponse["data"]
): InitialMonitoringMonthValues => {
  if (!value) {
    return {
      monitoring_month: []
    };
  }
  return {
    monitoring_month: value.monitoring_month.map((v) => {
      return {
        id: v.id,
        year_month: dateToSelectMonthValue(v.year_month),
        is_delete: false
      };
    })
  };
};

export const initialMonitoringMonthElement = (): {
  id: number | null;
  year_month: SelectMonthValue;
  is_delete: boolean;
} => {
  return {
    id: null,
    year_month: { year: "", month: "" },
    is_delete: false
  };
};
