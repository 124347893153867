import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { RouteComponentProps } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import AuthTemplate from "@components/templates/AuthTemplate";
import { ReminderForm } from "@components/organisms/auth/password/ReminderForm";
import ErrorIcon from "@material-ui/icons/Error";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapperAll: {
      "@media only screen and (max-width: 639px)": {
        paddingBottom: 64
      }
    },
    paper: {
      "@media only screen and (min-width: 640px)": {
        margin: "0 auto",
        maxWidth: 640,
        textAlign: "left"
      }
    },
    header: {
      margin: 0,
      borderBottom: "1px solid #cfd8dc",
      fontSize: 24,
      fontWeight: "normal",
      color: "#37474f",
      "@media only screen and (min-width: 640px)": {
        padding: "24px 32px 8px",
        lineHeight: 32 / 24
      },
      "@media only screen and (max-width: 639px)": {
        padding: "32px 16px 10px",
        lineHeight: 1.09
      }
    },
    wrapper: {
      "@media only screen and (min-width: 640px)": {
        padding: spacing.unit * 4
      },
      "@media only screen and (max-width: 639px)": {
        padding: `${spacing.unit * 4}px ${spacing.unit * 2}px`
      }
    },
    subText: {
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 16,
      "@media only screen and (min-width: 640px)": {
        lineHeight: 1.75,
        letterSpacing: 0.5
      },
      "@media only screen and (max-width: 639px)": {
        lineHeight: 1.5,
        letterSpacing: 0.15
      }
    },
    supplementWrapper: {
      display: "flex",
      marginTop: 24
    },
    alertIconUpper: {
      color: "#ff5656",
      position: "relative",
      top: 2,
      width: 17,
      height: 17,
      marginRight: 6
    },
    subTextSmall: {
      fontSize: 14,
      lineHeight: 1.43,
      letterSpacing: 0.25,
      color: "rgba(0, 0, 0, 0.6)"
    }
  });

type Props = RouteComponentProps & WithStyles<typeof styles>;

/**
 * パスワードリマインダ
 */
const ReminderCore = (props: Props): JSX.Element => {
  return (
    <AuthTemplate>
      <div className={props.classes.wrapperAll}>
        <Paper className={props.classes.paper}>
          <h1 className={props.classes.header}>パスワード再設定メールの送信</h1>
          <div className={props.classes.wrapper}>
            <div className={props.classes.subText}>
              パスワード再設定の為のメールを、登録のメールアドレスに送信します。
              <br />
              登録メールアドレスまたはIDを入力ください。
            </div>
            <div className={props.classes.supplementWrapper}>
              <ErrorIcon className={props.classes.alertIconUpper} />
              <div className={props.classes.subTextSmall}>
                タイムカード画面アカウントまたはモバイル画面アカウントのパスワードを忘れた場合は、管理画面アカウントでログインの上、アカウント情報ページからパスワードの変更をしてください。
              </div>
            </div>
            <ReminderForm history={props.history} />
          </div>
        </Paper>
      </div>
    </AuthTemplate>
  );
};

export const Reminder = withStyles(styles)(ReminderCore);
