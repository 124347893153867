import * as React from "react";
// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import FormikTime from "@components/molecules/FormikTime";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikMultipleSelect from "@components/molecules/FormikMultipleSelect";

import { FormikProps } from "formik";
import { DeleteOutline } from "@material-ui/icons";
import {
  BasicInfoFields,
  Schedule
} from "@interfaces/mgr/KEIKAKUSODAN/record/basicInfo";
import RecordTextField from "../../common/record/RecordTextField";
import { SECONDARY_BLUE_COLOR } from "@constants/styles";
import {
  SCHEDULE_DAY_OF_WEEK,
  SCHEDULE_NEXT_DAY_FLG
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    item: {
      padding: "16px",
      display: "flex"
    },
    dayOfWeek: {
      "&>div>div": {
        display: "block",
        whiteSpace: "nowrap"
      }
    },
    nextDayFlgWrap: {
      display: "flex"
    },
    nextDayFlg: {
      "& div": {
        textOverflow: "unset"
      }
    },
    deleteOutline: {
      color: SECONDARY_BLUE_COLOR
    },
    MT16: {
      marginTop: "16px"
    },
    ML24: {
      marginLeft: "24px"
    },
    ML16: {
      marginLeft: "16px"
    },
    ML8: {
      marginLeft: "8px"
    },
    W236: {
      width: "236px",
      "&>div": {
        width: "100%"
      }
    },
    W72: {
      maxWidth: "72px",
      "&>div": {
        width: "100%"
      }
    },
    W64: {
      width: "64px",
      "&>div": {
        width: "100%"
      }
    },
    W450: {
      width: "450px",
      "&>div": {
        width: "100%"
      }
    }
  });

type OwnProps = {
  item: Schedule;
  isEditing: boolean;
  nameNumber: number;
  formikProps: FormikProps<BasicInfoFields>;
  onDelete: () => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const BasicInfoScheduleItemCore = (props: Props): JSX.Element => {
  const { classes, item, isEditing, nameNumber, formikProps, onDelete } = props;

  // 曜日
  const [dayOfWeekValue, setDayOfWeekValue] = React.useState<string>("");
  React.useEffect(() => {
    const array: string[] = [];
    SCHEDULE_DAY_OF_WEEK.map((i) => {
      return item.day_of_week.map((m) => {
        if (m !== i.value) {
          return false;
        }
        return array.push(i.label);
      });
    });
    setDayOfWeekValue(array.join("、"));
  }, [item.day_of_week]);

  // start_time・end_timeを変える度にend_time_next_day_flgのバリデーション実行させる
  const startValidation = (): void => {
    formikProps.setFieldTouched(
      `basic_info_schedule[${nameNumber}].next_day_flg`,
      true
    );
    formikProps.setFieldTouched(
      `basic_info_schedule[${nameNumber}].start_time`,
      true
    );
    formikProps.setFieldTouched(
      `basic_info_schedule[${nameNumber}].end_time`,
      true
    );
  };
  const onChangeStartTime = (): string | void => {
    startValidation();
  };
  const onChangeEndTime = (): string | void => {
    startValidation();
  };

  return (
    <div className={classes.item}>
      <div className={classes.W236}>
        {isEditing ? (
          <FormikMultipleSelect
            name={`schedule[${nameNumber}].day_of_week`}
            label=""
            options={[
              {
                categoryName: "",
                items: SCHEDULE_DAY_OF_WEEK
              }
            ]}
            placeholder="選択してください"
            className={ClassNames(classes.dayOfWeek)}
            style={{ marginBottom: 0 }}
            isNotShot
          />
        ) : (
          <ReadonlyTextField value={dayOfWeekValue} defaultValue="" />
        )}
      </div>

      <div className={ClassNames(classes.nextDayFlgWrap, classes.ML16)}>
        <div className={ClassNames(classes.W72)}>
          {isEditing ? (
            <FormikTime
              name={`schedule[${nameNumber}].start_time`}
              label=""
              placeholder="00:00"
              maxLength={5}
              style={{ marginBottom: 0 }}
              onChangeHookTime={onChangeStartTime}
            />
          ) : (
            <ReadonlyTextField value={item.start_time} defaultValue="" />
          )}
        </div>
      </div>

      <div className={ClassNames(classes.nextDayFlgWrap, classes.ML16)}>
        <div className={ClassNames(classes.W64, classes.nextDayFlg)}>
          <RecordSelect
            name={`schedule[${nameNumber}].next_day_flg`}
            value={`${SCHEDULE_NEXT_DAY_FLG[item.next_day_flg].label}`}
            options={SCHEDULE_NEXT_DAY_FLG}
            defaultValue=""
            placeholder=""
            isEditable={isEditing}
          />
        </div>
        <div className={ClassNames(classes.W72, classes.ML16)}>
          {isEditing ? (
            <FormikTime
              name={`schedule[${nameNumber}].end_time`}
              label=""
              placeholder="00:00"
              maxLength={5}
              style={{ marginBottom: 0 }}
              onChangeHookTime={onChangeEndTime}
            />
          ) : (
            <ReadonlyTextField value={item.end_time} defaultValue="" />
          )}
        </div>

        <div className={ClassNames(classes.W450, classes.ML16)}>
          {isEditing ? (
            <RecordTextField
              name={`schedule[${nameNumber}].content`}
              value={item.content}
              defaultValue=""
              placeholder=""
              isEditable={isEditing}
              maxLength={20}
            />
          ) : (
            <ReadonlyTextField value={item.content} defaultValue="" />
          )}
        </div>
      </div>

      <div className={ClassNames(classes.ML24)}>
        {isEditing && (
          <DeleteOutline
            className={props.classes.deleteOutline}
            onClick={onDelete}
          />
        )}
      </div>
    </div>
  );
};

export const BasicInfoScheduleItem = withStyles(styles)(
  BasicInfoScheduleItemCore
);
