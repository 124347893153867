import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { ConsultationState } from "@stores/domain/mgr/CHIIKIIKO/consultation/types";
import { FacilityState } from "@stores/domain/mgr/CHIIKIIKO/facility/types";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import format from "date-fns/format";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import { dateToLocalisedString } from "@utils/date";
import fixDateAndTimeFormat from "@utils/dataNormalizer/fixDateAndTimeFormat";
import ArrowIcon from "@components/atoms/ArrowIcon";

const styles = (): StyleRules =>
  createStyles({
    flex: {
      display: "flex",
      flexWrap: "nowrap"
    },
    flexEnd: {
      justifyContent: "end"
    },
    label: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)",
      whiteSpace: "nowrap"
    },
    con: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 2,
      minHeight: 20
    },
    signatureWrapper: {
      display: "flex",
      width: "332px",
      height: "38px",
      marginLeft: "16px",
      border: "solid 2px #212121"
    },
    signature: {
      width: "100px",
      borderRight: "solid 1px #212121",
      fontSize: "10px",
      letterSpacing: "0.5px",
      textAlign: "center",
      lineHeight: "34px"
    },
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4,
      display: "flex",
      justifyContent: "flex-end",
      "&:nth-child(n+2)": {
        marginTop: 4
      },
      "&>span": {
        display: "block",
        marginLeft: 4
      }
    },
    info: {
      marginTop: "16px",
      fontSize: 10
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      overflowWrap: "anywhere",
      "& td": {
        verticalAlign: "top",
        borderRight: "1px solid",
        borderTop: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 20,
        "&.headLabelWrapper": {
          padding: "8px 6px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle",
          height: "47px",
          textAlign: "center"
        },
        "&.supportPeriodLabel": {
          padding: 0,
          height: "47px",
          width: "254px",
          "& > .label": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#f5f5f5",
            height: "24px",
            borderBottom: "1px solid"
          },
          "& > .ym": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& div": {
              padding: "4px 0",
              textAlign: "center",
              width: "43px",
              "&:not(:last-child)": {
                borderRight: "1px solid"
              }
            }
          }
        },
        "&.supportPeriodValue": {
          padding: 0,
          "& > .arrow": {
            display: "flex",
            height: "16px",
            "& > .col": {
              padding: "6px 4px",
              "&:not(:last-child)": {
                borderRight: "1px dashed"
              }
            }
          },
          "& > .userDo": {
            padding: "5px 4px",
            borderTop: "1px solid"
          }
        },
        "&.tableValue": {
          padding: "4px 8px"
        },
        "&.w104": {
          width: "104px"
        },
        "&.w106": {
          width: "106px"
        }
      }
    },
    goalTable: {
      border: "solid 2px #212121",
      overflowWrap: "anywhere",
      "& > div": {
        display: "grid",
        gridTemplateColumns: "120px 1fr"
      }
    },
    goalTableWrap: {
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      borderRight: "solid 1px #212121"
    },
    goalTableValue: {
      padding: "4px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px"
    },
    comment: {
      border: "solid 2px #212121",
      overflowWrap: "anywhere",
      display: "grid",
      gridTemplateColumns: "120px 1fr"
    },
    commentWrap: {
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      borderRight: "solid 1px #212121",
      padding: "6px 8px"
    },
    commentValue: {
      padding: "6px 8px",
      color: "#212121",
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px"
    },
    item: {
      "&+&": {
        marginLeft: 16
      }
    },
    itemLabel: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)"
    },
    itemCon: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 4
    },
    itemConRight: {
      textAlign: "right",
      height: "20px"
    },
    dateCon: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr"
    },
    W62: {
      width: "62px"
    },
    W104: {
      width: "104px"
    },
    W216: {
      width: "216px"
    },
    W254: {
      width: "254px"
    },
    MT16: {
      marginTop: "16px"
    },
    MT24: {
      marginTop: "24px"
    },
    ML16: {
      marginLeft: "16px"
    },
    colorLight: {
      color: "#9e9e9e"
    },
    paddingRight: {
      paddingRight: 16
    },
    borderBottom: {
      borderBottom: "solid 1px #000"
    }
  });

type OwnProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  consultation: ConsultationState["consultationRecord"];
  isStaffComment: boolean;
  title: string;
  isStampIcon: boolean;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const ConsultationPrintContentCore = (props: Props): JSX.Element => {
  const {
    classes,
    facility,
    user,
    title,
    consultation,
    isStaffComment,
    isStampIcon
  } = props;
  const userInFacility = user.user_in_facility;

  if (userInFacility.name_sei === undefined) {
    return <></>;
  }

  // 計画作成日
  const targetDate = format(consultation.creationDate, "YYYY年MM月DD日");
  // 計画作成者
  const [author, setAuthor] = React.useState<string>("-");
  React.useEffect(() => {
    if (consultation.author === null) {
      setAuthor("-");
    } else {
      const name = getSnapOrRealName(consultation.author, "");
      const role = getSnapOrRealRole(consultation.author, "");
      setAuthor(`${name}（${role}）`);
    }
  }, [consultation.author]);

  // 値がある優先順位のみ取り出す
  const filteredDetails = consultation.supportPlanConsultationDetails
    .map((detail, index) => ({ record: detail, number: index + 1 }))
    .filter((detail) => {
      return !Object.entries(detail.record).every(([key, value]) => {
        if (key === "support_plan_consultation_details_id") {
          return true;
        }
        return !value;
      });
    });

  // 協力（支援）の開始年月から協力（支援）の目安の項目を作成する
  const supportPeriodList = React.useMemo((): {
    label: string;
    value: string;
  }[] => {
    const dt = new Date(fixDateAndTimeFormat(consultation.startYm));
    const list = [];
    for (let i = 1; i <= 6; i += 1) {
      list.push({
        label: `${dt.getMonth() + 1}月`,
        value: dateToLocalisedString(dt, "YYYYMM")
      });
      dt.setMonth(dt.getMonth() + 1);
    }
    return list;
  }, [consultation.startYm]);

  return (
    <>
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>{title}</h2>
          <div>
            <div className={classes.pageTitleTool}>
              作成日: <span>{`${targetDate}`}</span>
            </div>
            <div className={classes.pageTitleTool}>
              計画作成者: <span>{`${author}`}</span>
            </div>
          </div>
        </div>
        {/* 作成日・作成者 */}
        <div />

        <div className={classes.info}>
          これから先、６か月の計画です。自分のペースで取り組みます。今の時点の計画です。毎月相談をして、必要な時は見直しをしましょう。
        </div>

        {/* 基本情報 */}
        <div className={ClassNames(classes.MT24)}>
          <div className={classes.W254}>
            <div className={classes.label}>利用者氏名（フリガナ）</div>
            <div
              className={classes.con}
            >{`${userInFacility.name_sei} ${userInFacility.name_mei} （${userInFacility.name_sei_kana} ${userInFacility.name_mei_kana}）`}</div>
          </div>
        </div>

        {/* 計画の目標 */}
        <div className={ClassNames(classes.MT16, classes.goalTable)}>
          <div className={ClassNames(classes.flex, classes.borderBottom)}>
            <div
              className={classes.goalTableWrap}
              style={{ padding: "6px 0 4px 8px" }}
            >
              サービス等利用計画の到達目標
            </div>
            <div className={classes.goalTableValue}>
              {consultation.attainmentGoal}
            </div>
          </div>
          <div className={ClassNames(classes.flex, classes.borderBottom)}>
            <div
              className={classes.goalTableWrap}
              style={{ padding: "4px 0 4px 8px" }}
            >
              長期目標
            </div>
            <div className={classes.goalTableValue}>
              {consultation.longTermGoal}
            </div>
          </div>
          <div className={classes.flex}>
            <div
              className={classes.goalTableWrap}
              style={{ padding: "4px 0 6px 8px" }}
            >
              短期目標
            </div>
            <div className={classes.goalTableValue}>
              {consultation.shortTermGoal}
            </div>
          </div>
        </div>

        {/* 計画の協力（支援）の内容 */}
        {filteredDetails.length > 0 && (
          <table className={ClassNames(classes.table, classes.MT16)}>
            <thead>
              <tr>
                <td className="headLabelWrapper w104">
                  <div>
                    本人の
                    <br />
                    期待や不安
                  </div>
                </td>
                <td className="headLabelWrapper w106">
                  <div>
                    そのために
                    <br />
                    協力する人
                  </div>
                </td>
                <td className="headLabelWrapper w104">
                  <div>
                    協力する
                    <br />
                    内容
                  </div>
                </td>
                <td className="headLabelWrapper w104">
                  <div>留意事項</div>
                </td>
                <td className="supportPeriodLabel">
                  <div className="label">協力（支援）の目安</div>
                  <div className="ym">
                    {supportPeriodList.map((x) => (
                      <div key={`supportPeriodLabel-ym-${x.value}`}>
                        {x.label}
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              {filteredDetails.map(({ record, number }) => {
                const startDate = new Date(
                  fixDateAndTimeFormat(record.start_ym)
                );
                const endDate = new Date(fixDateAndTimeFormat(record.end_ym));

                const col: string[] = [];
                supportPeriodList.forEach((x) => {
                  const target = new Date(fixDateAndTimeFormat(x.value));
                  if (endDate >= target && startDate <= target) {
                    if (!col.includes("arrow")) {
                      col.push("arrow");
                    }
                  } else {
                    col.push("");
                  }
                });

                const arrowCol = 6 - col.length + 1;
                // (矢印のカラム数 × 1カラムの幅) + 線 で 矢印のカラムの幅を出す
                const arrowColWidth = arrowCol * 42 + arrowCol * 1;
                // arrowColWidth - 左右のpadding で矢印の長さを出す
                const arrowWidth = arrowColWidth - 8;
                return (
                  <tr key={`support_plan_consultation_details_${number}`}>
                    <td className="tableValue">
                      <div>{record.support_goal}</div>
                    </td>
                    <td className="tableValue">{record.collaborator}</td>
                    <td className="tableValue">
                      {record.contents_cooperation}
                    </td>
                    <td className="tableValue">{record.considerations}</td>
                    <td className="supportPeriodValue">
                      <div className="arrow">
                        {col.map((x, i) => {
                          const key = `supportPeriodValue-${i}`;
                          return x === "arrow" ? (
                            <div
                              key={key}
                              className="col"
                              style={{ width: `${arrowColWidth}px` }}
                            >
                              <ArrowIcon height={8} width={arrowWidth} />
                            </div>
                          ) : (
                            <div
                              key={key}
                              className="col"
                              style={{ width: "43px" }}
                            />
                          );
                        })}
                      </div>
                      <div className="userDo">{record.users_do}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {/* その他 */}
        {isStaffComment && (
          <div className={ClassNames(classes.MT16, classes.comment)}>
            <div className={classes.commentWrap}>職員コメント</div>
            <div className={classes.commentValue}>
              {consultation.staffComment}
            </div>
          </div>
        )}

        {/* 計画のフッター */}
        <div className={classes.MT24}>
          <div className={ClassNames(classes.flex, classes.flexEnd)}>
            <div className={ClassNames(classes.item, classes.W216)}>
              <div className={classes.itemLabel}>指定一般相談支援事業所</div>
              <div
                className={ClassNames(classes.itemCon, classes.paddingRight)}
              >
                {facility.officeName}
              </div>
            </div>
          </div>
          <div className={ClassNames(classes.flex, classes.MT16)}>
            <div className={ClassNames(classes.item, classes.W216)}>
              <div className={classes.itemLabel}>同意日</div>
              <div
                className={ClassNames(
                  classes.dateCon,
                  classes.itemCon,
                  classes.itemConRight,
                  classes.paddingRight
                )}
              >
                <div>年</div>
                <div>月</div>
                <div>日</div>
              </div>
            </div>
            <div className={ClassNames(classes.item, classes.W216)}>
              <div className={classes.itemLabel}>利用者同意署名</div>
              <div
                className={ClassNames(
                  classes.itemCon,
                  classes.colorLight,
                  classes.itemConRight
                )}
              >
                {isStampIcon && <span> 印</span>}
              </div>
            </div>
            <div className={ClassNames(classes.item, classes.W216)}>
              <div className={classes.itemLabel}>
                相談支援専門員（地域移行推進員）
              </div>
              <div
                className={ClassNames(
                  classes.itemCon,
                  classes.colorLight,
                  classes.itemConRight
                )}
              >
                {isStampIcon && <span> 印</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const ConsultationPrintContent = withStyles(styles)(
  ConsultationPrintContentCore
);
