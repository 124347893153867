import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportRecordUserParams = {
  support: {
    id: number | null;
    target_date: string;
    staff_comment?: string | null;
    support_content?: string | null;
    staffs?:
      | {
          staff_id: number;
          staff_name: string;
        }[]
      | null;
  }[];
};

/**
 * 支援記録を更新する
 * @param params 更新データ
 * @param uifId 事業所所属ユーザーのID
 */
export const postSupportRecordUser = async (
  params: PostSupportRecordUserParams,
  uifId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/supports/users/${uifId}`;
  return request.post(url, params);
};
