import { SupportRecordUserState } from "@stores/domain/supportRecordUser/types";
import get from "lodash-es/get";
import { SelectDateValue, SelectValue } from "@interfaces/ui/form";
import { dateToSelectDateValue } from "@utils/date";

export type RecordUserDetailValues = {
  support: {
    id: number | null;
    usage_results_id: number;
    creation_date: SelectDateValue;
    company_name: string;
    todays_purpose: string;
    life_current_issues: string;
    life_support_content: string;
    office_current_issues: string;
    office_support_content: string;
    usage_status_of_related_organizations: string;
    notices: string;
    staff_comment: string;
    next_support_date: string;
    next_support_method: string;
    next_support_plans: string;
    author: SelectValue; // 作成者
  }[];
};

const initialValues = (
  values: SupportRecordUserState["support"],
  companyNameParam?: string
): RecordUserDetailValues => {
  return {
    support: values
      ? values.map((sup) => {
          let companyName = get(sup.record, "company_name") || "";
          if (!sup.record.id) {
            // 新規作成の場合は雇用事業主を入れる
            companyName = companyNameParam || "";
          }
          // author,authorizerは入力ありなしで型が変わる
          const authorId =
            sup && sup.record.author !== null ? `${sup.record.author.id}` : "";
          return {
            id: get(sup.record, "id") || null,
            usage_results_id: sup.record.usage_results_id,
            creation_date: dateToSelectDateValue(
              get(sup.record, "creation_date") || ""
            ),
            company_name: companyName,
            todays_purpose: get(sup.record, "todays_purpose") || "",
            life_current_issues: get(sup.record, "life_current_issues") || "",
            life_support_content: get(sup.record, "life_support_content") || "",
            office_current_issues:
              get(sup.record, "office_current_issues") || "",
            office_support_content:
              get(sup.record, "office_support_content") || "",
            usage_status_of_related_organizations:
              get(sup.record, "usage_status_of_related_organizations") || "",
            notices: get(sup.record, "notices") || "",
            staff_comment: get(sup.record, "staff_comment") || "",
            next_support_date: get(sup.record, "next_support_date") || "",
            next_support_method: get(sup.record, "next_support_method") || "",
            next_support_plans: get(sup.record, "next_support_plans") || "",
            author: authorId
          };
        })
      : []
  };
};

export default initialValues;
