import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { ReportUsagePerformanceState } from "@stores/domain/mgr/GroupHome/report/types";
import { dateTodayForAttendanceHeaderForDetailDaily } from "@utils/date";
import { UnitsState } from "@stores/domain/units/types";
import UsagePerformanceDetailHeader from "@components/organisms/mgr/common/report/detail/UsagePerformanceDetailHeader";
import UsagePerformanceDetailModalContents from "@components/organisms/mgr/common/report/detail/UsagePerformanceDetailModalContents";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { connect } from "react-redux";
import { FacilityType } from "@constants/variables";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME } from "@constants/mgr/GroupHome/variables";
import { USAGE_PERFORMANCE_SUMMARY_LABEL as USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO } from "@constants/mgr/SHISETSUNYUSHO/variables";

const styles = (): StyleRules =>
  createStyles({
    modalHeader: {
      display: "flex",
      justifyContent: "space-between" as "space-between"
    },
    modalHeaderLeft: {
      display: "flex",
      justifyContent: "space-between" as "space-between",
      fontSize: 20
    },
    closeButton: {
      width: 100,
      height: 36,
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      backgroundColor: "rgba(98, 2, 238, 0)",
      color: "#0277bd"
    },
    modal: {
      margin: "25px",
      // スクロールバーの非表示（Googole Chromeのみの適用）
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    width1070: {
      width: 1070
    },
    width1315: {
      width: 1315
    },
    modalArea: {
      height: 640
    },
    modalContents: {
      padding: 0,
      height: 488
    },
    tableHeaderContainer: {
      position: "sticky",
      padding: 0,
      top: 64
    },
    modalHeaderArea: {
      height: 64,
      padding: "15px 40px",
      top: 0,
      position: "sticky",
      backgroundColor: "#fff"
    },
    marginInfo: {
      marginRight: 32
    },
    marginDate: {
      marginRight: 8
    }
  });

type OwnProps = {
  summary: ReportUsagePerformanceState["summary"];
  usagePerformance: ReportUsagePerformanceState["usage_performance"];
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  units: UnitsState["optionalCustomInfo"];
};

type StateProps = {
  userState: UserState;
};

type Props = WithStyles<typeof styles> & OwnProps & StateProps;

type State = {
  height: number;
};

class UsagePerformanceReportModalCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { height: window.innerHeight };
  }

  private updateDimensions = (): void => {
    this.setState({ height: window.innerHeight });
  };

  componentDidMount = (): void => {
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = (): void => {
    window.removeEventListener("resize", this.updateDimensions);
  };

  private onClose = (): void => {
    this.props.onClose();
  };

  public render(): JSX.Element | null {
    const { summary, usagePerformance, date, units, classes } = this.props;
    let labels;
    let modalWidth;
    switch (this.props.userState.facility_type) {
      case FacilityType.GROUP_HOME:
        labels = USAGE_PERFORMANCE_SUMMARY_LABEL_GROUP_HOME;
        modalWidth = classes.width1070;
        break;
      case FacilityType.SHISETSUNYUSHO:
        labels = USAGE_PERFORMANCE_SUMMARY_LABEL_SHISETSUNYUSHO;
        modalWidth = classes.width1315;
        break;
      default:
        return null;
    }
    // スクロールバーの表示範囲を調整。
    // IABリリース前からの事象でスクロールバーは表示されているが画面を小さくするとスクロールバーの表示が見切れてしまう事象があり、
    // 画面の高さに変更があった時、固定(640px)ではなく動的に高さを変動させてスクロールバーが正しい範囲内で収まるように調整。
    // MuiDialogのcssではモーダルの高さをwindow高さ - 96pxで計算している
    const modalHeight = this.state.height - 96;
    // モーダルのmaxHeightを640pxに制限
    const modalAreaStyle = {
      height: `${modalHeight > 640 ? 640 : modalHeight}px`
    };
    // tableBody部の高さ計算
    // スクロール時の固定表示部(152px)：モーダルヘッダーの高さ(64px) + テーブルヘッダー(114px)
    const tempTableBodyHeight = modalHeight - 178;
    // tableBody部のmaxHeightは640-178=462pxに制限
    const modalContentsStyle = {
      height: tempTableBodyHeight > 462 ? 462 : tempTableBodyHeight
    };

    return (
      <Dialog
        disableBackdropClick
        open={this.props.isOpen}
        onClose={this.onClose}
        maxWidth="lg"
        aria-labelledby="max-width-dialog-title"
        classes={{ paper: `${classes.modal} ${modalWidth}` }}
      >
        <div className={classes.modalArea} style={modalAreaStyle}>
          <DialogTitle className={`${classes.modalHeaderArea} ${modalWidth}`}>
            <div>
              <div className={classes.modalHeader}>
                <div className={classes.modalHeaderLeft}>
                  <div className={classes.marginInfo}>利用状況の詳細</div>
                  <div className={classes.marginDate}>
                    {dateTodayForAttendanceHeaderForDetailDaily(date)}
                  </div>
                </div>
                <KnowbeButton
                  onClick={this.onClose}
                  className={classes.closeButton}
                  kind="outline"
                >
                  閉じる
                </KnowbeButton>
              </div>
            </div>
          </DialogTitle>
          <div className={classes.tableHeaderContainer}>
            <UsagePerformanceDetailHeader
              summary={summary}
              labels={labels}
              facilityType={this.props.userState.facility_type}
            />
          </div>
          <DialogContent
            className={`${classes.modalContents} ${modalWidth}`}
            style={modalContentsStyle}
          >
            <UsagePerformanceDetailModalContents
              usagePerformance={usagePerformance}
              units={units}
              labels={labels}
              facilityType={this.props.userState.facility_type}
            />
          </DialogContent>
        </div>
      </Dialog>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const UsagePerformanceReportModal = connect(mapStateToProps)(
  withStyles(styles)(UsagePerformanceReportModalCore)
);
