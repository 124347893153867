import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import FormikCheckbox from "@components/molecules/FormikCheckbox";

import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";

import {
  getIndexes,
  getName
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";

const styles = (): StyleRules =>
  createStyles({
    p: {
      margin: 0
    },
    wrapper: {
      marginTop: 32
    },
    label: {
      fontSize: "14px"
    },
    checkboxContainer: {
      display: "flex",
      flexWrap: "wrap",
      width: 480,
      marginTop: 6,
      marginBottom: 20,
      marginLeft: 16,
      "& > div": {
        width: 160,
        marginBottom: 0
      }
    }
  });

type OwnProps = {
  item: CustomRecordsWithCategoryState[number]["custom_record_items"][number];
  formValues: SupportLedger;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportLedgerCheckBoxCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const indexes = getIndexes(props.formValues, props.item);
  const baseName = getName(indexes, "checkedItems");

  return (
    <div className={classes.wrapper}>
      <p className={classes.label}>{`${props.item.name}（複数選択可）`}</p>
      <div className={classes.checkboxContainer}>
        {props.item.custom_record_item_choices.map((choice) => {
          return (
            <FormikCheckbox
              key={`check-${choice.id}`}
              name={`${baseName}[${choice.id}].checked`}
              label={choice.name}
              style={{ padding: 0 }}
              disabled={!props.isEditing}
            />
          );
        })}
      </div>
    </div>
  );
};

export const SupportLedgerCheckBox = withStyles(styles)(
  SupportLedgerCheckBoxCore
);
