import { InitialValues } from "@interfaces/v201910/mgr/SHISETSUNYUSHO/report/initial";
import {
  UsagePerformanceType,
  UsagePerformanceSHISETSUNYUSHOType
} from "@stores/v201910/domain/mgr/SHISETSUNYUSHO/report/types";
import { INT_TRUE_FROM_API } from "@constants/variables";
import {
  StatusType,
  HospitalizationOvernightstay,
  OralTransition,
  OralPreservation
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import {
  getDefaultHospitalizationDays,
  getDefaultHospitalizationDaysDialogFields
} from "@utils/domain/mgr/SHISETSUNYUSHO/usagePerformanceReportUtil";

export type InitialDataValues = InitialValues;

// デフォルト値設定
export const initialValues = (
  state?: UsagePerformanceType,
  stateSHISETSUNYUSHO?: UsagePerformanceSHISETSUNYUSHOType,
  inputHospitalizationDaysPrev?: number | null
): InitialDataValues => {
  // 当日の入院・外泊日数
  let hospitalizationDays: number | null = null;
  if (stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.hospitalizationDays) {
    const hospitalNumber = Number(stateSHISETSUNYUSHO.hospitalizationDays);
    if (Number.isNaN(hospitalNumber)) {
      // NaNの場合、初期値としてプリセット値を設定する
      if (state && stateSHISETSUNYUSHO) {
        if (inputHospitalizationDaysPrev !== undefined) {
          hospitalizationDays = Number(
            getDefaultHospitalizationDaysDialogFields(
              String(state.statusType),
              inputHospitalizationDaysPrev
            )
          );
        } else {
          hospitalizationDays = Number(
            getDefaultHospitalizationDays(
              String(state.statusType),
              stateSHISETSUNYUSHO.hospitalizationDaysPrev
            )
          );
        }
      }
    } else {
      hospitalizationDays = hospitalNumber;
    }
  }
  // 前日の入院・外泊日数
  let hospitalizationDaysPrev: number | null = null;
  if (inputHospitalizationDaysPrev !== undefined) {
    hospitalizationDaysPrev = inputHospitalizationDaysPrev;
  } else if (
    stateSHISETSUNYUSHO &&
    stateSHISETSUNYUSHO.hospitalizationDaysPrev
  ) {
    hospitalizationDaysPrev = Number(
      stateSHISETSUNYUSHO.hospitalizationDaysPrev
    );
  }
  return {
    initial: {
      name:
        state && state.nameSei && state.nameMei
          ? `${state.nameSei} ${state.nameMei}`
          : "",
      usersInFacilityId:
        state && state.usersInFacilityId ? state.usersInFacilityId : -1,
      targetDate: state && state.targetDate ? state.targetDate : "",
      statusType:
        state && state.statusType
          ? state.statusType.toString()
          : StatusType.NONE,
      hospitalizationDays,
      hospitalizationDaysPrev,
      hospitalizationOvernightStay:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.hospitalizationOvernightStay
          ? stateSHISETSUNYUSHO.hospitalizationOvernightStay.toString()
          : HospitalizationOvernightstay.NONE.toString(),
      regionalTransitionFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.regionalTransition
          ? stateSHISETSUNYUSHO.regionalTransition === INT_TRUE_FROM_API
          : false,
      collectionOfUtilityFeeFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.collectionOfUtilityFee
          ? stateSHISETSUNYUSHO.collectionOfUtilityFee === INT_TRUE_FROM_API
          : false,
      nutritionManagementFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.nutritionManagement
          ? stateSHISETSUNYUSHO.nutritionManagement === INT_TRUE_FROM_API
          : false,
      foodBreakfastFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodBreakfast
          ? stateSHISETSUNYUSHO.foodBreakfast === INT_TRUE_FROM_API
          : false,
      foodLunchFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodLunch
          ? stateSHISETSUNYUSHO.foodLunch === INT_TRUE_FROM_API
          : false,
      foodSupperFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.foodSupper
          ? stateSHISETSUNYUSHO.foodSupper === INT_TRUE_FROM_API
          : false,
      oralTransition:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.oralTransition
          ? stateSHISETSUNYUSHO.oralTransition.toString()
          : OralTransition.NONE.toString(),
      oralPreservation:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.oralPreservation
          ? stateSHISETSUNYUSHO.oralPreservation.toString()
          : OralPreservation.NONE.toString(),
      medicalFoodsFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.medicalFoods
          ? stateSHISETSUNYUSHO.medicalFoods === INT_TRUE_FROM_API
          : false,
      severeDisabilitySupportFlg:
        stateSHISETSUNYUSHO && stateSHISETSUNYUSHO.severeDisabilitySupport
          ? stateSHISETSUNYUSHO.severeDisabilitySupport === INT_TRUE_FROM_API
          : false,
      remarks: state && state.remarks ? state.remarks : ""
    }
  };
};
