import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  id: number | null;
  name: string;
  input_type: number;
  setting_type: number;
  custom_records_category_id?: number | null;
  custom_record_item_choices: {
    id: number | null;
    custom_record_item_id: number | null;
    name: string;
  }[];
};

/**
 * カスタム項目を追加、編集する
 */
export const postCustomRecords = async (
  param: RequestParam
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_records`;
  return request.post(url, param);
};

export default postCustomRecords;
