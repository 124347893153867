import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
// ui
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
// formik
import { FormikProps } from "formik";
import {
  ServiceDeliveryDetailValues,
  DestinationValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import FormikTextField from "@components/molecules/FormikTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    contentWrap: {
      border: "1px solid #bdbdbd",
      padding: "24px 16px",
      borderRadius: "4px",
      marginTop: 16
    },
    delete: {
      paddingLeft: 16,
      marginTop: 16
    }
  });

type OwnProps = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  unitKey: string;
  onDeleteRecord: (index: number) => void;
  isAddButton: boolean;
  practitionerNum: number;
  destination: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDestinations"];
};

type Props = OwnProps & WithStyles<typeof styles>;

export const DestinationTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const bodyData = (i: number, onDeleteRecord: () => void): JSX.Element => (
      <div
        className={classes.contentWrap}
        key={`destination-${i}-${props.practitionerNum}`}
      >
        <FormikTime
          isMobile
          readOnly={!props.isEdit}
          name={`${props.unitKey}[${i}].when_time`}
          label="時間"
          placeholder="00:00"
          size="mobileHalf"
          maxLength={5}
        />
        <FormikTextField
          isMobile
          readOnly={!props.isEdit}
          name={`${props.unitKey}[${i}].place`}
          label="場所"
          size="fullSize"
        />
        <FormikTextField
          isMobile
          multiline
          readOnly={!props.isEdit}
          name={`${props.unitKey}[${i}].support`}
          label="支援内容"
          size="fullSize"
          style={{ marginBottom: 0 }}
        />
        {props.isEdit && (
          <KnowbeButton
            key={`delete-${i}`}
            className={classes.delete}
            kind="iconText"
            onClick={onDeleteRecord}
          >
            <DeleteIcon
              style={{
                width: 24,
                height: 28,
                color: "#0277bd",
                cursor: "pointer",
                marginRight: 7
              }}
            />
            <div style={{ color: "#0277bd" }}>削除する</div>
          </KnowbeButton>
        )}
      </div>
    );
    const bodyValues = props.destination
      // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((d: { value: DestinationValues[0]; baseIndex: number }) => {
        const onDeleteRecord = (): void => {
          props.onDeleteRecord(d.baseIndex);
        };
        return bodyData(d.baseIndex, onDeleteRecord);
      });

    return <>{bodyValues}</>;
  }
);
