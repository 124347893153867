import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { UserState } from "@stores/domain/user/type";
// ui
import AdminTemplate from "@components/templates/AdminTemplate";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { FieldItem } from "@interfaces/ui/form";
import { ServiceDeliveryFormMonthly } from "@components/organisms/record/serviceDelivery/ServiceDeliveryFormMonthly";
import { FacilityType } from "@constants/variables";

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  fetchStaffs: () => void;
  fetchDetailsRecord: (
    serviceDeliveryRecordsId: number | null,
    inoutResultsId: number | null,
    supportProcedureFormsId: number | null
  ) => Promise<void>;
  fetchUser: (id: string, facilityType: FacilityType) => void;
};

type StateProps = {
  detailRecords: ServiceDeliveryState["detailsRecord"];
  serviceDeliveryUser: ServiceDeliveryState["user"];
  userState: UserState;
};

type OwnProps = RouteComponentProps<{
  date: string;
  serviceDeliveryRecordsId: string;
  inoutResultsId: string;
  uifId: string;
  supportProcedureFormsId: string;
}>;

type Props = OwnProps &
  StateProps &
  DispatchProps & {
    staffOptions: FieldItem[];
  };

/**
 * サービス提供記録（編集）
 */
const ServiceDeliveryEditMonthlyCore = (props: Props): JSX.Element => {
  const {
    date,
    serviceDeliveryRecordsId,
    inoutResultsId,
    uifId,
    supportProcedureFormsId
  } = props.match.params;
  const user = props.serviceDeliveryUser.user_in_facility;
  const pageName =
    user.name_sei !== undefined && user.name_mei !== undefined
      ? `サービス提供記録${"　"}${user.name_sei} ${user.name_mei}`
      : "";

  // fetch
  React.useEffect(() => {
    props.fetchUser(uifId, props.userState.facility_type);
    props.fetchCustomRecords();
    props.fetchStaffs();
    props.fetchDetailsRecord(
      Number(serviceDeliveryRecordsId),
      Number(inoutResultsId),
      Number(supportProcedureFormsId)
    );
  }, []);

  return (
    <AdminTemplate pageName={pageName}>
      <ServiceDeliveryFormMonthly
        targetDate={date}
        history={props.history}
        isEdit
        detailRecords={props.detailRecords}
        user={user}
        params={props.match.params}
      />
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  detailRecords: state.serviceDelivery.detailsRecord,
  serviceDeliveryUser: state.serviceDelivery.user,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    customRecordsWithCategory,
    serviceDelivery,
    staffDispatcher
  } = dispatches;
  const staffDispatches = staffDispatcher(dispatch);
  const customRecordsDispatches = customRecordsWithCategory(dispatch);
  const serviceDeliveryDispatches = serviceDelivery(dispatch);
  return {
    fetchStaffs: staffDispatches.fetch,
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    fetchDetailsRecord: (
      serviceDeliveryRecordsId: number | null,
      inoutResultsId: number | null,
      supportProcedureFormsId: number | null
    ): Promise<void> =>
      serviceDeliveryDispatches.fetchDetailsRecord(
        serviceDeliveryRecordsId,
        inoutResultsId,
        supportProcedureFormsId
      ),
    fetchUser: (id: string, facilityType: FacilityType): Promise<void> =>
      serviceDeliveryDispatches.fetchUser(id, facilityType)
  };
};

export const ServiceDeliveryEditMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceDeliveryEditMonthlyCore);
