import * as React from "react";
import {
  createStyles,
  withStyles,
  StyleRules,
  WithStyles
} from "@material-ui/core/styles";

// ui
import Button, { ButtonProps } from "@material-ui/core/Button";

// utils
import cloneDeep from "lodash-es/cloneDeep";

// type
import { PatternListType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    searchTextWrap: {
      fontSize: 14,
      letterSpacing: 0.1,
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 10,
      marginTop: 2
    },
    searchTextGroup: {
      marginBottom: 10
    }
  });

type StyledChipProps = ButtonProps & {
  checked: boolean;
};

type Props = {
  targetPattern: PatternListType[];
  setTargetPattern: React.Dispatch<React.SetStateAction<PatternListType[]>>;
} & WithStyles<typeof styles>;

const StyledChip = (props: StyledChipProps): JSX.Element => {
  const { checked, disabled } = props;
  const StyledButton = withStyles({
    root: {
      backgroundColor: checked ? "#455a64" : "#fff",
      color: checked ? "#fff" : "#37474f",
      opacity: disabled ? 0.5 : 1,
      height: 30,
      borderRadius: 18,
      minWidth: 0,
      minHeight: 30,
      marginRight: 10,
      "&:hover": {
        backgroundColor: checked ? "#455a64" : "#fff"
      },
      "& span": {
        fontSize: 12
      },
      "&:disabled": {
        color: "rgba(0, 0, 0, 0.26)",
        backgroundColor: "#fff"
      }
    }
  })(Button);
  return <StyledButton {...props} />;
};

const FieldKanaCore = (props: Props): JSX.Element => {
  const { targetPattern, setTargetPattern, classes } = props;
  const handleClick = (pattern: PatternListType, index: number) => (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    e.preventDefault();
    const newPatternList = cloneDeep(targetPattern);
    newPatternList[index].checked = !pattern.checked;
    setTargetPattern(newPatternList);
  };

  return (
    <>
      <div className={classes.searchTextWrap}>ふりがな（複数選択可）</div>
      <div className={classes.searchTextGroup}>
        {targetPattern.map((item, index) => {
          return (
            <StyledChip
              variant="outlined"
              onClick={handleClick(item, index)}
              checked={item.checked}
              key={item.id}
              disabled={item.disabled}
              disableRipple
            >
              {item.viewKana}
            </StyledChip>
          );
        })}
      </div>
    </>
  );
};

export const FieldKana = withStyles(styles)(FieldKanaCore);
