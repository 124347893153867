import React from "react";

// ui
import DailyRecordSupportTableRow from "@components/organisms/mgr/TANKINYUSHO/record/DailyRecordSupportTableRow";
import { FieldItem } from "@interfaces/ui/form";

// formik
import { Formik, Form, FormikActions } from "formik";
import {
  RecordSupportsValues,
  initialSupportsValues as initialValues
} from "@initialize/mgr/TANKINYUSHO/record/dailyRecord/initialValues";
import { supportValidation as validation } from "@initialize/mgr/TANKINYUSHO/record/dailyRecord/validation";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import * as recordDailyUserActions from "@stores/pages/record/daily/actions";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";

// utils
import { toEffectiveObject } from "@utils/object";
import isEqual from "lodash-es/isEqual";
import { SnackbarParams } from "@stores/ui/type";

type OwnProps = {
  supportsRecord: DailyOperationsAndSupportsState["support"];
  staffOptions: FieldItem[];
  isEditing: boolean;
  targetUifId: number | null;
  customRecords: CustomRecordsState;
  newCustomRecords: CustomRecordsState | null;
  users: UsersInFacilityState["users"];
  yyyymmdd: string;
};
type StateProps = {
  needsStopHistory: boolean;
  recordDaily: RecordDailyState;
};
type DispatchProps = {
  setEditing: (uifId: number) => void;
  unsetEditing: () => void;
  stopHistory: (flag: boolean) => void;
  showSnackbar: (params: SnackbarParams) => void;
  postCustomSupport: (
    yyyymmdd: string,
    uifId: number,
    params: RecordSupportsValues,
    initialValue: RecordSupportsValues
  ) => void;
};
type Props = OwnProps & StateProps & DispatchProps;

const DailyUsersRecordSupportTableCore = (props: Props): JSX.Element | null => {
  // state
  const [formValues, setFormValues] = React.useState(
    initialValues(props.supportsRecord, props.customRecords)
  );

  // mount & update
  React.useEffect(() => {
    return (): void => {
      props.unsetEditing(); // domをumMountするタイミングで編集状態を破棄する
    };
  }, []);
  React.useEffect(() => {
    setFormValues(initialValues(props.supportsRecord, props.customRecords));
  }, [props.customRecords, props.supportsRecord]);

  // handler
  const onClickEdit = (e: React.MouseEvent, uifId: number): void => {
    props.setEditing(uifId);
    e.preventDefault();
  };

  // formik handler
  const confirmDiscardFormChanges = (
    nextValues: RecordSupportsValues
  ): void => {
    const hasChange = !isEqual(nextValues, formValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };
  const validate = (values: RecordSupportsValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };
  const onSubmit = async (
    values: RecordSupportsValues,
    actions: FormikActions<{}>
  ): Promise<void> => {
    actions.setSubmitting(true);
    if (props.recordDaily.uifId) {
      await props.postCustomSupport(
        props.yyyymmdd,
        props.recordDaily.uifId,
        values,
        formValues
      );
    }
    actions.setSubmitting(false);
    props.stopHistory(false);
  };

  return (
    <Formik
      initialValues={formValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => {
        const onClickEditCancel = (): void => {
          formikProps.resetForm();
          props.unsetEditing();
          setFormValues(
            initialValues(props.supportsRecord, props.customRecords)
          );
          props.stopHistory(false);
        };

        return (
          <Form>
            {formikProps.values.record.length === props.supportsRecord.length &&
              props.supportsRecord.map((record, index) => {
                const uifId = record.user_in_facility_id;
                return (
                  <DailyRecordSupportTableRow
                    key={uifId}
                    supportRecord={record}
                    staffOptions={props.staffOptions}
                    formikProps={formikProps}
                    formikFieldNamePrefix={`record[${index}]`}
                    isEditing={uifId === props.targetUifId && props.isEditing}
                    isEditMode={
                      (props.recordDaily.uifId === null &&
                        props.recordDaily.unitId === null) ||
                      uifId === props.targetUifId
                    }
                    uifId={uifId}
                    customRecords={props.newCustomRecords || []}
                    onClickEdit={onClickEdit}
                    onClickEditCancel={onClickEditCancel}
                    users={props.users}
                    showSnackbar={props.showSnackbar}
                  />
                );
              })}
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  needsStopHistory: state.ui.needsStopHistory,
  recordDaily: state.pages.recordDaily
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, pages } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const recordDailyDispatcher = pages.recordDailyTANKINYUSHODispatcher(
    dispatch
  );
  return {
    postCustomSupport: (
      yyyymmdd: string,
      uifId: number,
      params: RecordSupportsValues,
      initialValue: RecordSupportsValues
    ): void => {
      recordDailyDispatcher.postCustomSupport(
        yyyymmdd,
        uifId,
        params,
        initialValue
      );
    },
    setEditing: (uifId: number): void => {
      dispatch(recordDailyUserActions.setEditWithUifId(uifId));
    },
    unsetEditing: (): void => {
      dispatch(recordDailyUserActions.unsetEdit());
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const DailyUsersRecordSupportTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(DailyUsersRecordSupportTableCore);
