import * as React from "react";
import * as H from "history";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import RadioButtons, {
  RadioItemInterface
} from "@components/atoms/RadioButtons";
import {
  USAGE_DRAFT_PLAN_RADIO_LABEL,
  INQUIRY_CONTENT_RADIO_LABEL,
  MONITORING_REPORT_RADIO_LABEL,
  STAFF_MEETING_RECORD_RADIO_LABEL,
  USAGE_CONTINUATION_PLAN_RADIO_LABEL,
  USAGE_PLAN_RADIO_LABEL,
  TYPE_CONSULTATION_FLG,
  SHOGAIJI_INQUIRY_CONTENT_RADIO_LABEL,
  SHOGAIJI_MONITORING_REPORT_RADIO_LABEL,
  SHOGAIJI_STAFF_MEETING_RECORD_RADIO_LABEL,
  SHOGAIJI_USAGE_CONTINUATION_PLAN_RADIO_LABEL,
  SHOGAIJI_USAGE_DRAFT_PLAN_RADIO_LABEL,
  SHOGAIJI_USAGE_PLAN_RADIO_LABEL,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  PARAMETER_TYPE_KEIKAKUSODAN,
  PARAMETER_TYPE_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import MuiSelect from "@components/molecules/MuiSelect";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      width: 600
    },
    dialogHeader: {
      padding: "16px 32px 18px",
      color: "#37474f",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      lineHeight: 1.2,
      height: 58
    },
    content: {
      paddingTop: 4
    },
    radioGroupClass: {
      margin: "0 20px"
    },
    labelClass: {
      fontSize: "16px"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      paddingRight: 32,
      marginLeft: 0,
      marginRight: 0
    },
    formControlLabelStyle: {
      lineHeight: 1.0,
      marginBottom: "6px"
    }
  });

type OwnProps = {
  isOpen: boolean;
  uifId: string;
  onCancel: () => void;
  history: H.History;
  facility: FacilityState;
  typeConsultation?: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PlanMonitoringMeetingCreateDialogCore = (props: Props): JSX.Element => {
  const [selectedValue, setSelectedValue] = React.useState("1");
  const [filterValue, setFilterValue] = React.useState<string>(
    `${props.typeConsultation || 1}`
  );

  const radioItems = [
    { label: USAGE_DRAFT_PLAN_RADIO_LABEL, value: "1" },
    { label: USAGE_PLAN_RADIO_LABEL, value: "2" },
    { label: USAGE_CONTINUATION_PLAN_RADIO_LABEL, value: "3" },
    { label: MONITORING_REPORT_RADIO_LABEL, value: "4" },
    { label: STAFF_MEETING_RECORD_RADIO_LABEL, value: "5" },
    { label: INQUIRY_CONTENT_RADIO_LABEL, value: "6" }
  ];
  const shogaijiRadioItems = [
    { label: SHOGAIJI_USAGE_DRAFT_PLAN_RADIO_LABEL, value: "1" },
    { label: SHOGAIJI_USAGE_PLAN_RADIO_LABEL, value: "2" },
    { label: SHOGAIJI_USAGE_CONTINUATION_PLAN_RADIO_LABEL, value: "3" },
    { label: SHOGAIJI_MONITORING_REPORT_RADIO_LABEL, value: "4" },
    { label: SHOGAIJI_STAFF_MEETING_RECORD_RADIO_LABEL, value: "5" },
    { label: SHOGAIJI_INQUIRY_CONTENT_RADIO_LABEL, value: "6" }
  ];

  const paths = {
    "1": `/record/consultation/new/${props.uifId}/0`,
    "2": `/record/consultation/new/${props.uifId}/1`,
    "3": `/record/consultation/new/${props.uifId}/2`,
    "4": `/record/support_plan/${props.uifId}/monitoring_report/new`,
    "5": `/record/meeting_record/new/${props.uifId}`,
    "6": `/record/support_plan/${props.uifId}/inquiry/new`
  };

  const onChange = (event: React.ChangeEvent<{}>, value: string): void => {
    setSelectedValue(value);
  };

  const onChangeFilterSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setFilterValue(e.target.value);
  };

  const onSubmit = async (): Promise<void> => {
    let newPath = paths[selectedValue];
    if (
      props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
    ) {
      newPath += `?type=${
        filterValue === "1"
          ? PARAMETER_TYPE_KEIKAKUSODAN
          : PARAMETER_TYPE_SHOGAIJISODAN
      }`;
    } else if (props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN) {
      newPath += `?type=${PARAMETER_TYPE_KEIKAKUSODAN}`;
    } else if (props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN) {
      newPath += `?type=${PARAMETER_TYPE_SHOGAIJISODAN}`;
    }
    props.history.push(newPath);
  };

  const renderRadioButtons = (
    items: RadioItemInterface[],
    value: string
  ): JSX.Element => (
    <RadioButtons
      name="plan-monitoring-meeting-dialog-radio-buttons"
      radioItems={items}
      value={value}
      radioGroupClass={props.classes.radioGroupClass}
      labelClass={props.classes.labelClass}
      onChange={onChange}
    />
  );

  return (
    <Dialog open={props.isOpen} classes={{ paper: props.classes.paper }}>
      <DialogTitle className={props.classes.dialogHeader}>新規作成</DialogTitle>
      <DialogContent>
        <div className={props.classes.content}>
          {props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN &&
            renderRadioButtons(radioItems, selectedValue)}

          {props.facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN &&
            renderRadioButtons(shogaijiRadioItems, selectedValue)}

          {props.facility.facilityType ===
            FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN && (
            <>
              <MuiSelect
                label="サービス種類"
                name=""
                size="medium"
                value={filterValue}
                options={TYPE_CONSULTATION_FLG}
                onChange={onChangeFilterSelect}
                style={{
                  width: 205,
                  height: 16,
                  marginTop: "24px",
                  fontSize: 12,
                  lineHeight: 1.33,
                  letterSpacing: "0.4px"
                }}
              />
              {filterValue === "1" &&
                renderRadioButtons(radioItems, selectedValue)}
              {filterValue === "2" &&
                renderRadioButtons(shogaijiRadioItems, selectedValue)}
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className={props.classes.dialogFooter}>
        <KnowbeButton kind="outline" onClick={props.onCancel}>
          キャンセル
        </KnowbeButton>
        <KnowbeButton kind="default" onClick={onSubmit}>
          確定して編集画面に進む
        </KnowbeButton>
      </DialogActions>
    </Dialog>
  );
};

export const PlanMonitoringMeetingCreateDialog = withStyles(styles)(
  PlanMonitoringMeetingCreateDialogCore
);
