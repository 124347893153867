import React, { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import {
  Theme,
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import * as H from "history";
import { ChangePassword, AuthState } from "@stores/auth/type";

import dispatches from "@stores/dispatches";
import { SnackbarParams } from "@stores/ui/type";
import { AppState } from "@stores/type";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PasswordField from "@components/molecules/PasswordField";
import { PasswordCaution } from "@components/organisms/account/PasswordCaution";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: 32
    },
    submitButtonWrapper: {
      display: "flex",
      justifyContent: "center",
      marginTop: spacing.unit * 3
    },
    disableButton: {
      width: 320,
      fontSize: 14,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.26)",
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
      borderRadius: 4
    },
    content: {
      paddingTop: spacing.unit * 2
    },
    action: {
      paddingTop: spacing.unit,
      borderTop: "1px solid rgba(0, 0, 0, 0.12)"
    }
  });

type StateProps = {
  auth: AuthState;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  postChangePassword: (params: ChangePassword) => void;
  refreshTemporary: () => void;
  fetchMe: () => Promise<void>;
  refreshPasswordChangeErrorMessage: (type: "mgradmin" | "mgruser") => void;
};

type OwnProps = {
  history: H.History;
};

type Props = DispatchProps & OwnProps & StateProps & WithStyles<typeof styles>;

const ResetForm = (props: Props): JSX.Element => {
  const [afterPassword, setAfterPassword] = useState<string>("");

  const getErrorMessage = (): string | undefined => {
    const adminError = props.auth.changePasswordRes.filter((target) => {
      return target.role === "mgradmin";
    });
    return adminError.length ? adminError[0].error.message : "";
  };

  const onClickLoginButton = (): void => {
    props.refreshTemporary();
    props.fetchMe();
  };

  const handleChangeAfterPassword = (value: string): void => {
    if (
      props.auth.changePasswordRes.filter((target) => {
        return target.role === "mgradmin";
      }).length
    ) {
      props.refreshPasswordChangeErrorMessage("mgradmin");
    }
    setAfterPassword(value);
  };

  const handleSubmit = (): void => {
    props.postChangePassword({
      email: props.auth.temporaryEmail,
      password: afterPassword,
      remember_token: props.auth.temporaryToken
    });
  };
  const viewError = getErrorMessage();
  return (
    <div className={props.classes.wrapper}>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={props.auth.isLoggedIn}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle disableTypography>パスワード変更完了</DialogTitle>
        <DialogContent className={props.classes.content}>
          パスワードの変更が完了しました。
          <br />
          次回ログインより新しいパスワードをお使いください。
        </DialogContent>
        <DialogActions className={props.classes.action}>
          <Button onClick={onClickLoginButton} color="secondary" autoFocus>
            管理者様用管理画面へ
          </Button>
        </DialogActions>
      </Dialog>
      <PasswordField
        passwordName="新しいパスワード"
        onChange={handleChangeAfterPassword}
        errorMessage={viewError}
      />
      <PasswordCaution />
      <div className={props.classes.submitButtonWrapper}>
        <KnowbeButton
          onClick={handleSubmit}
          disabled={!afterPassword}
          minWidth={320}
        >
          変更を確定する
        </KnowbeButton>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, authDispatch, userDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const authDispatches = authDispatch(dispatch);
  const userDispatches = userDispatch(dispatch);
  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    postChangePassword: (params: ChangePassword): void =>
      authDispatches.postChangePassword(params),
    refreshTemporary: (): void => authDispatches.refreshTemporary(),
    refreshPasswordChangeErrorMessage: (type: "mgradmin" | "mgruser"): void =>
      authDispatches.refreshPasswordChangeErrorMessage(type),
    fetchMe: userDispatches.me
  };
};

const mapStateToProps = (state: AppState): StateProps => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ResetForm));
