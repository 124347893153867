import { FacilityType } from "@constants/variables";

// 実績記録票

export interface InvoiceRecordAdditions {
  isOutbound: boolean;
  isInbound: boolean;
  isSuppliedMeal: boolean;
  supportTime: number | null;
  supportSystem: number | null;
  supportOutOfFacility: string | null;
  supportMedical: string | null;
  isTrainCommute: boolean;
  trialUsage: string | null;
  supportDiffTime: number;
  supportSerious: boolean;
  provisionForm: number | null;
  shortStay: number | null;
}
export interface Record {
  day: number;
  dayOfWeek: string;
  serviceStatus: string | null;
  startTime: string;
  endTime: string | null;
  memo: string | null;
  sameCorporationMovementFlg: string | null;
  additions: InvoiceRecordAdditions;
}

// UsagePerformanceStatusType
export enum UsagePerformanceStatusType {
  STAY = 1,
  HOSPITALIZATION_FIRSTDAY,
  HOSPITALIZATION_MIDDLEDAY,
  HOSPITALIZATION_LASTDAY,
  OVERNIGHTSTAY_FIRSTDAY,
  OVERNIGHTSTAY_MIDDLEDAY,
  OVERNIGHTSTAY_LASTDAY,
  OVERNIGHTSTAY_FROM_HOSPITALIZATION,
  HOSPITALIZATION_FROM_OVERNIGHTSTAY,
  HOSPITALIZATION_COMMUNITY_OVERNIGHTSTAY,
  OVERNIGHTSTAY_COMMUNITY_HOSPITALIZATION,
  EXPERIENCE,
  HOSPITALIZATION_TEMPORARY_STAY_OUTSIDE = 18
}

export interface InvoiceUserUsagePerformance {
  id: number;
  users_in_facility_id: number;
  target_date: string;
  status_type: UsagePerformanceStatusType;
  day: number;
  day_of_week: string;
  night_support_type: number;
  hospitalization_support_type: number;
  get_home_support_type: number;
  daytime_support_type: number;
  medical_support_type: number;
  life_support_flg: number;
  remarks: null;
  isApplyDays: boolean;
  isOutbound: boolean;
  isInbound: boolean;
  isSuppliedMeal: boolean;
  isEmergencyInitialAdmission: boolean;
  overCapacity: number;
  overHours: number;
  severeDisabilitySupport: number;
  nightSupportSevereDisabilitySupport: number;
  hospitalStay: number;
  isRegionalTransition: boolean;
  isExperienceStay: boolean;
  isBreakfast: boolean;
  isLunch: boolean;
  isSupper: boolean;
  isUtilityfee: boolean;
}
export interface InvoiceUserUsageResults {
  day: number;
  day_of_week: string;
  isApplyDays: boolean;
  isSpecialArea: boolean;
  remarks: string;
}

export interface InvoiceUser {
  uifId: number;
  recipientNo: string;
  recipientName: string;
  payment: string;
  dateBeginService: string;
  dateAfter30Days: string;
  cntDaysInitApply: number;
  cntPickup: number;
  cntVisit: number;
  cntFood: number;
  cntSupportSystem1: number;
  cntSupportOutOfFacility: number;
  cntSupportSystem1Total: number;
  cntSupportOutOfFacilityTotal: number;
  cntSupportMedical: number;
  cntTrainCommute: number;
  cntTrialUsage: number;
  cntShortStay: number;
  cntCome: number;
  cntVisitLess: number;
  cntVisitMore: number;
  records: Record[];
  usage_performances: InvoiceUserUsagePerformance[];
  usage_results: InvoiceUserUsageResults[];
  dateEndService?: string;
  lastLifeSupportTargetDate?: string;
  applyDays?: number;
  cntSpecialArea?: number;
  emergencyInitialAdmission?: number;
  overCapacity?: number;
  benefit: string;
  benefitAmount: number;
  cntFoodBreakfast: number;
  cntFoodLunch: number;
  cntFoodSupper: number;
  cntUtilityfee: number;
  costFood: string;
  costUtilityfee: string;
  costSum: string;
  unitBreakfast: string;
  unitLunch: string;
  unitSupper: string;
  unitFood: string;
  unitUtilityfeeDay: string;
  unitUtilityfeeMonth: string;
  hospitalStay: number;
  regionalTransition: number;
  experienceStay: number;
  regionalTransitionEndDate: string;
  regionalTransitionAfterDate: string;
  cntHospitalizationSupportType: number;
}

export interface Facility {
  officeNo: string | null;
  officeName: string | null;
  kindService: FacilityType;
}

export interface InvoiceDate {
  year: string;
  month: string;
}

export interface InvoiceData {
  date: InvoiceDate;
  facility: Facility;
  users: InvoiceUser[];
}

export interface InvoiceDataResult {
  data: InvoiceData[];
}

export interface InvoiceState {
  invoiceData: InvoiceDataResult;
}
