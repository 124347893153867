import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export interface GetReportUsersResponse {
  facility: {
    typeService: string;
  };
  users: {
    id: number | string;
    recipientNumber: string;
    name: string;
    targetServiceDisplay: boolean;
    targetForUpLimit: boolean;
    targetForUserCostAmountList: boolean;
    serviceDeliveryDates?: string[]; // 居宅介護・重度訪問介護・同行援護、行動援護で使用
    procedureFormDates?: string[]; // 行動援護で使用
    type_consultation?: number; // 計画相談のみ
  }[];
  response: {
    code: number;
    msg: string;
  };
}

// 年月に対応するユーザー一覧取得
const getReportUsers = async (
  target: string,
  month: string
): Promise<AxiosResponse<GetReportUsersResponse>> => {
  const url = `${VERSION_URL_201910}/report/${target}/${month}`;
  return request.get<GetReportUsersResponse>(url);
};

export default getReportUsers;
