/**
 * 時間が指定の範囲内か
 */

export const checkTimeRange = (
  startTime: string,
  startTimeClass: string,
  outTime: string,
  outTimeClass: string,
  targetTime: string,
  targetTimeClass: string,
  equalityOperatorFlag = true,
  option = "時間"
): string | undefined => {
  let errorMessage;
  // 日跨ぎしている場合、時間に+24する
  const start =
    startTimeClass === "1"
      ? parseInt(startTime.replace(":", ""), 10) + 2400
      : parseInt(startTime.replace(":", ""), 10);
  const end =
    outTimeClass === "1"
      ? parseInt(outTime.replace(":", ""), 10) + 2400
      : parseInt(outTime.replace(":", ""), 10);
  const target =
    targetTimeClass === "1"
      ? parseInt(targetTime.replace(":", ""), 10) + 2400
      : parseInt(targetTime.replace(":", ""), 10);

  // 等価演算の許容判定
  const flg = equalityOperatorFlag
    ? target < start || target > end
    : target <= start || target >= end;

  if (flg) {
    errorMessage = `${option}は提供時間の範囲内で入力してください`;
  }

  return errorMessage;
};
