import * as React from "react";
import * as URL from "@constants/url";
import { getUrlParams } from "@/utils/url";
import { RouteComponentProps } from "react-router-dom";

import { AppState } from "@stores/type";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";

import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import {
  FacilityType,
  PLAN_LIST_TAB_PATH,
  DEFAULT_SELECT_VALUE,
  SUPPORT_CARE_PLAN_NAME,
  SUPPORT_CARE_PLAN_PAGE_NAME
} from "@constants/variables";
import { FacilityState as FacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/types";
import { FacilityState as FacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/types";
import { FacilityState as FacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/facility/types";
import { FacilityState as FacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/facility/types";
import { UsersInFacilityState as UsersInFacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { CarePlanPrintContent } from "@components/organisms/mgr/common/record/carePlan/CarePlanPrintContent";
import { CityState, CityParams } from "@stores/domain/city/type";

type OwnProps = {} & RouteComponentProps<{
  id: string;
  carePlanId: string;
}>;
type StateProps = {
  user:
    | UsersInFacilityStateKYOTAKUKAIGO["user"]
    | UsersInFacilityStateJUDOHOMONKAIGO["user"]
    | UsersInFacilityStateDOKOENGO["user"]
    | UsersInFacilityStateKODOENGO["user"];
  facility:
    | FacilityStateKYOTAKUKAIGO
    | FacilityStateJUDOHOMONKAIGO
    | FacilityStateDOKOENGO
    | FacilityStateKODOENGO;
  facilityType: FacilityType;
  cityListCarePlan: CityState[] | null;
  cityListFacility: CityState[] | null;
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
  supportCarePlanSchedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
};
type DispatchProps = {
  fetchCarePlanCity: (params: CityParams) => Promise<void>;
  fetchFacilityCity: (params: CityParams) => Promise<void>;
  fetchOneUser: (id: string, facilityType: FacilityType) => Promise<void>;
  fetchFacility: (facilityType: FacilityType) => Promise<void>;
  fetchSupportPlanUifIdCarePlanCarePlanId: (
    id: string,
    carePlanId: string
  ) => Promise<void>;
};
type Props = OwnProps & StateProps & DispatchProps;

const CarePlanPrintCore = (props: Props): JSX.Element => {
  const { search } = props.location;
  const { id, carePlanId } = props.match.params;
  const {
    user,
    facility,
    facilityType,
    supportCarePlan,
    supportCarePlanSchedules,
    cityListCarePlan,
    cityListFacility
  } = props;

  const pageName = SUPPORT_CARE_PLAN_PAGE_NAME[facilityType];
  const pathList = [
    {
      pathName: "支援計画",
      path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY
    },
    {
      pathName: SUPPORT_CARE_PLAN_NAME[facilityType],
      path: `${URL.RECORD_SUPPORT_PLAN}/${id}/${PLAN_LIST_TAB_PATH.CAREPLAN}`
    }
  ];

  // コメント取得
  const [isStaffComment, setIsStaffComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_comment?: string;
    } = getUrlParams(search);
    setIsStaffComment(queryParameter.display_comment === "staff_comment");
  }, [search]);

  // 印鑑マーク取得
  const [isStampIcon, setIsStampIcon] = React.useState<boolean>(false);
  React.useEffect(() => {
    const queryParameter: {
      display_icon?: string;
    } = getUrlParams(search);
    setIsStampIcon(queryParameter.display_icon === "stamp_icon");
  }, [search]);

  // fetch
  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchFacility(facilityType);
    props.fetchSupportPlanUifIdCarePlanCarePlanId(id, carePlanId);
  }, []);

  // 計画書市区町村取得
  React.useEffect(() => {
    if (
      supportCarePlan.prefecture_name !== null &&
      supportCarePlan.prefecture_name !== "" &&
      supportCarePlan.prefecture_name !== DEFAULT_SELECT_VALUE
    ) {
      props.fetchCarePlanCity({
        prefectureName: supportCarePlan.prefecture_name
      });
    }
  }, [supportCarePlan]);

  const [cityLabel, setCityLabel] = React.useState<string>("");
  React.useEffect(() => {
    if (cityListCarePlan !== null && supportCarePlan.city_id !== null) {
      Object.keys(cityListCarePlan).forEach((key) => {
        if (cityListCarePlan[key].value === supportCarePlan.city_id) {
          setCityLabel(cityListCarePlan[key].label);
        }
      });
    }
  }, [cityListCarePlan]);

  // 事業所市区町村取得
  React.useEffect(() => {
    if (
      facility.selectedPrefectureName !== null &&
      facility.selectedPrefectureName !== "" &&
      facility.selectedPrefectureName !== DEFAULT_SELECT_VALUE
    ) {
      props.fetchFacilityCity({
        prefectureName: facility.selectedPrefectureName
      });
    }
  }, [facility]);

  const [cityFacilityLabel, setFacilityCityLabel] = React.useState<string>("");
  React.useEffect(() => {
    if (
      cityListFacility !== null &&
      facility.selectedCityCode !== DEFAULT_SELECT_VALUE
    ) {
      Object.keys(cityListFacility).forEach((key) => {
        if (`${cityListFacility[key].value}` === facility.selectedCityCode) {
          setFacilityCityLabel(cityListFacility[key].label);
        }
      });
    }
  }, [cityListFacility, facility]);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <CarePlanPrintContent
          user={user}
          facility={facility}
          cityLabel={cityLabel}
          facilityType={facilityType}
          cityFacilityLabel={cityFacilityLabel}
          isStaffComment={isStaffComment}
          supportCarePlan={supportCarePlan}
          supportCarePlanSchedules={supportCarePlanSchedules}
          isStampIcon={isStampIcon}
        />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    user: state[facilityType].userInFacility.user,
    facility: state[facilityType].facility,
    facilityType,
    cityListCarePlan: state.carePlan.city,
    cityListFacility: state.city,
    supportCarePlan: state.carePlan.supportCarePlan,
    supportCarePlanSchedules: state.carePlan.supportCarePlanSchedules
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { carePlanDispatcher, cityDispatch } = dispatches;

  return {
    fetchCarePlanCity: carePlanDispatcher(dispatch).fetchCity,
    fetchFacilityCity: (params: CityParams): Promise<void> =>
      cityDispatch(dispatch).fetch(params),
    fetchOneUser: (id: string, facilityType: FacilityType): Promise<void> =>
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id),
    fetchFacility: (facilityType: FacilityType): Promise<void> =>
      dispatches[facilityType].facilityDispatcher(dispatch).fetch(),
    fetchSupportPlanUifIdCarePlanCarePlanId: carePlanDispatcher(dispatch)
      .fetchSupportPlanUifIdCarePlanCarePlanId
  };
};

export const CarePlanPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(CarePlanPrintCore);
