import * as React from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";

const styles = (): StyleRules =>
  createStyles({
    loot: {
      color: "#fff",
      padding: "8px 26px 8px 0",
      textTransform: "none",
      fontSize: 16
    },
    menu: {
      top: 48
    }
  });

type OwnProps = {
  label: string;
  menuLabel: string;
  menuSelect: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SimpleMenuCore = (props: Props): JSX.Element => {
  const [isMenuOpen, toggleMenu] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ): void => {
    setAnchorEl(event.currentTarget);
    toggleMenu(true);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    toggleMenu(false);
  };

  const menuSelect = (): void => {
    props.menuSelect();
  };

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={props.classes.loot}
      >
        {props.label}
        <KeyboardArrowDown />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        className={props.classes.menu}
      >
        <MenuItem onClick={menuSelect}>{props.menuLabel}</MenuItem>
      </Menu>
    </div>
  );
};

export const SimpleMenu = withStyles(styles)(SimpleMenuCore);
