import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import * as ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";

import { StaffState } from "@stores/domain/staff/types";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikPostalCode from "@components/molecules/FormikPostalCode";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSwitch from "@components/molecules/FormikSwitch";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTextField from "@components/molecules/FormikTextField";
import { RecordCarePlan } from "@interfaces/record/carePlan/carePlan";
import {
  FacilityType,
  DISABILITY_CLASS_LIST,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON,
  HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM,
  CARE_PLAN_SCHEDULES_CLASSIFY_HANDICAPPED_FLG
} from "@constants/variables";
import { CarePlanSchedules } from "@components/organisms/mgr/common/record/carePlan/CarePlanSchedules";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    con: {
      padding: "32px"
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    flex: {
      display: "flex",
      justifyContent: "flex-start",
      "& > div": {
        width: "calc((100% - 16px*4)/5)"
      },
      "& > div > div > label": {
        whiteSpace: "nowrap"
      }
    },
    col: {
      display: "flex"
    },
    colItem: {
      width: "240px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT8: {
      marginTop: spacing.unit
    },
    ML16: {
      marginLeft: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<RecordCarePlan>;
  staff: StaffState;
  staffOptions: FieldItem[];
  schedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  paramsId: string;
  paramsCarePlanId: string;
  facilityType: FacilityType;
};

type Props = OwnProps & WithStyles<typeof styles>;

const CarePlanFormCreateCore = (props: Props): JSX.Element => {
  const {
    classes,
    formikProps,
    staff,
    staffOptions,
    schedules,
    paramsId,
    paramsCarePlanId,
    facilityType
  } = props;

  // 新規の時「内容」表示Flag
  const [isComment, setIsComment] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsComment(
      formikProps.values.housework_assistance_base_type_reason ===
        HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON_ITEM.OTHERS.value
    );
  }, [formikProps]);

  // 役職
  const [roleValue, setRoleValue] = React.useState<string>("");
  React.useEffect(() => {
    if (formikProps.values.author !== null) {
      const select = staff.staffItems.find(
        (f) => f.staffItemId === Number(formikProps.values.author)
      );
      setRoleValue(select ? select.roleName : "");
    }
  }, [formikProps]);

  // 障害児フラグ
  const [isHandicapped, setIsHandicapped] = React.useState<boolean>(false);
  React.useEffect(() => {
    setIsHandicapped(
      (facilityType !== FacilityType.KYOTAKUKAIGO &&
        facilityType !== FacilityType.KODOENGO) ||
        formikProps.values.classify_handicapped_flg ===
          CARE_PLAN_SCHEDULES_CLASSIFY_HANDICAPPED_FLG.OFF
    );
  }, [formikProps, facilityType]);

  // 契約支給量
  const payMonthsAgreedKYOTAKUKAIGO = [
    {
      name: "pay_months_agreed_physical_care",
      label: "身体介護",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    },
    {
      name: "pay_months_agreed_outpatient_care_with_physical_care",
      label: "通院等介助（身体介護伴う）",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    },
    {
      name: "pay_months_agreed_housework_assistance",
      label: "家事援助",
      placeholder: "0.75",
      endAdornmentLabel: "時間/月"
    },
    {
      name: "pay_months_agreed_outpatient_care",
      label: "通院等介助（身体介護伴わず）",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    },
    {
      name: "pay_months_agreed_getting_on_and_off",
      label: "通院等乗降介助",
      placeholder: "",
      endAdornmentLabel: "回/月",
      maxLength: 3
    }
  ];
  const payMonthsAgreedJUDOHOMONKAIGO = [
    {
      name:
        "support_care_plans_judohomonkaigo.pay_months_agreed_severe_home_visit_care",
      label: "重度訪問介護（移動介護含む）",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    },
    {
      name:
        "support_care_plans_judohomonkaigo.pay_months_agreed_guide_severe_home_visit_care",
      label: "移動介護",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    }
  ];
  const payMonthsAgreedDOKOENGO = [
    {
      name:
        "support_care_plans_dokoengo.pay_months_agreed_accompanying_support",
      label: "同行援護",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    }
  ];
  const payMonthsAgreedKODOENGO = [
    {
      name:
        "support_care_plans_kodoengo.pay_months_agreed_accompanying_support",
      label: "行動援護",
      placeholder: "0.5",
      endAdornmentLabel: "時間/月"
    }
  ];
  const getPayMonthsAgreedList = (): {
    name: string;
    label: string;
    placeholder: string;
    endAdornmentLabel: string;
    maxLength?: number;
  }[] => {
    switch (facilityType) {
      case FacilityType.KYOTAKUKAIGO:
        return payMonthsAgreedKYOTAKUKAIGO;
      case FacilityType.JUDOHOMONKAIGO:
        return payMonthsAgreedJUDOHOMONKAIGO;
      case FacilityType.DOKOENGO:
        return payMonthsAgreedDOKOENGO;
      case FacilityType.KODOENGO:
        return payMonthsAgreedKODOENGO;
      default:
        return payMonthsAgreedKYOTAKUKAIGO;
    }
  };

  return (
    <div className={classes.con}>
      <FormikSelectDateNotSelectedDefault
        name="target_date"
        label="計画作成日"
        required
        setFormikFieldValue={formikProps.setFieldValue}
        addYearTo={1}
        overrideYearFrom={1989}
      />
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        本人情報
      </Typography>
      <Typography className={classes.MT24}>住所</Typography>
      <div className={ClassNames(classes.MT8, classes.ML16)}>
        <FormikPostalCode
          name="postal_code"
          label="郵便番号"
          maxLength={8}
          startAdornmentLabel="〒"
          style={{ maxWidth: "158px" }}
        />
        <FormikAddress
          prefectureIdName="prefecture_name"
          cityIdName="city_id"
          formikProps={formikProps}
          showRegionType={false}
          showCityCode={false}
        />
        <FormikTextField
          name="address"
          label="市区町村以降の住所"
          size="superLong"
          style={{ maxWidth: "766px" }}
        />
      </div>
      <div className={classes.MT32}>
        <FormikTextField
          name="tel"
          type="tel"
          label="電話番号"
          helperText="ハイフンなしで入力"
          maxLength={12}
          style={{ maxWidth: "245px" }}
        />
      </div>
      {isHandicapped && (
        <div className={classes.MT32}>
          <FormikRadioButtons
            name="classify_disability_support"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
          />
        </div>
      )}

      <Typography>受給者証</Typography>
      <div className={ClassNames(classes.MT8, classes.ML16)}>
        <FormikSelectDateNotSelectedDefault
          name="date_pay_begin"
          label="支給決定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={1}
          overrideYearFrom={1989}
          setFormikFieldValue={formikProps.setFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name="date_pay_end"
          label="支給決定終了日"
          addYearTo={5}
          overrideYearFrom={1989}
          setFormikFieldValue={formikProps.setFieldValue}
        />
      </div>
      <div className={classes.MT32}>
        <FormikSelectDateNotSelectedDefault
          name="date_begin_in_service"
          label="サービス提供開始日"
          addYearTo={1}
          overrideYearFrom={1989}
          setFormikFieldValue={formikProps.setFieldValue}
        />
      </div>

      <Typography className={classes.MT32}>契約支給量</Typography>
      <div className={ClassNames(classes.flex, classes.MT8, classes.ML16)}>
        {getPayMonthsAgreedList().map((item, index) => {
          const uniqueKey = `pay-months-agreed-${index}`;
          return <FormikTextField key={uniqueKey} {...item} />;
        })}
      </div>
      {facilityType === FacilityType.KYOTAKUKAIGO && (
        <FormikSwitch
          name="housework_assistance_base_type"
          label="「家事援助中心型」算定の理由を記載する"
        >
          <FormikSelect
            name="housework_assistance_base_type_reason"
            label="算定理由"
            options={HOUSEWORK_ASSISTANCE_BASE_TYPE_REASON}
            style={{ width: "588px" }}
          />

          {isComment && (
            <div className={classes.MT32}>
              <FormikTextField
                name="housework_assistance_base_type_comment"
                label="内容"
                size="fullSize"
                multiline
              />
            </div>
          )}
        </FormikSwitch>
      )}
      <FormikTextField
        name="usage_other_facility"
        label="他事業所利用状況"
        size="fullSize"
        multiline
      />

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        本人の希望と現状
      </Typography>
      <div className={classes.MT24}>
        <FormikTextField
          name="user_request"
          label="本人・家族の希望"
          size="fullSize"
          multiline
        />
      </div>
      <div className={classes.MT32}>
        <FormikTextField
          name="user_issue"
          label="本人の現状と課題"
          size="fullSize"
          multiline
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        援助の目標・方向性
      </Typography>
      <div className={classes.MT24}>
        <FormikTextField
          name="goals_direction_assistance"
          label="援助の目標・方向性"
          size="fullSize"
          multiline
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        計画予定表
      </Typography>
      <div className={classes.MT32}>
        <CarePlanSchedules
          isNew
          paramsId={paramsId}
          isEditing
          schedules={schedules}
          formikProps={formikProps}
          paramsCarePlanId={paramsCarePlanId}
        />
      </div>
      <div className={classes.MT32}>
        <FormikTextField
          name="remarks"
          label="備考"
          size="fullSize"
          multiline
        />
      </div>

      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        その他
      </Typography>
      <div className={classes.MT24}>
        <FormikTextField
          name="staff_comment"
          label="職員コメント"
          size="fullSize"
          multiline
        />
      </div>
      <div className={ClassNames(classes.col, classes.MT32)}>
        <div className={classes.colItem}>
          <FormikSelect
            name="author"
            label="作成者"
            options={staffOptions}
            placeholder="選択してください"
            isSelectablePlaceholder
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          />
        </div>
        <div className={ClassNames(classes.colItem, classes.ML16)}>
          <ReadonlyTextField value={roleValue} label="役職" defaultValue="-" />
        </div>
      </div>
    </div>
  );
};

export const CarePlanFormCreate = withStyles(styles)(CarePlanFormCreateCore);
