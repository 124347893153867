import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { isEmpty } from "lodash-es";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { OperationsState } from "@stores/domain/mgr/Cseg/operations/types";
import dispatches from "@stores/dispatches";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";

// utils
import dateToObject from "@utils/date/dateToObject";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontSize: 18,
      fontWeight: "normal",
      letterSpacing: 1.2,
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 16,
      marginTop: 16
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      boxSizing: "content-box",
      marginBottom: 24,
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "4px 6px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        wordBreak: "break-word",
        verticalAlign: "top",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.subLabel": {
          textAlign: "left",
          verticalAlign: "top"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 72
        },
        "&.msize": {
          width: 88
        },
        "&.lsize": {
          width: 340
        },
        "&.topAlign": {
          verticalAlign: "top"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          padding: 3,
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid"
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  operations: OperationsState;
};

type DispatchProps = {
  fetchOperations: (year: string, month: string) => Promise<void>;
};

type OwnProps = {
  year: string;
  month: string;
};

type Props = StateProps & DispatchProps & OwnProps & WithStyles<typeof styles>;

type OperationRecordProps = {
  operations: OperationsState;
  classes: Record<string, string>;
};

const OperationRecord = ({
  classes,
  operations
}: OperationRecordProps): JSX.Element | null => {
  const displayContent = operations.operations
    .filter((operation) => {
      return (
        !isEmpty(operation.operation_in_the_morning) ||
        !isEmpty(operation.operation_in_the_afternoon) ||
        !isEmpty(operation.other_comment)
      );
    })
    .map<
      {
        rowCount: number;
        contents: { label: string; value: string }[];
      } & OperationsState["operations"][number]
    >((operation) => {
      let rowCount = 0;
      const contents: { label: string; value: string }[] = [];

      if (!isEmpty(operation.operation_in_the_morning)) {
        rowCount += 1;
        contents.push({
          label: "業務内容（午前）",
          value: operation.operation_in_the_morning
        });
      }
      if (!isEmpty(operation.operation_in_the_afternoon)) {
        rowCount += 1;
        contents.push({
          label: "業務内容（午後）",
          value: operation.operation_in_the_afternoon
        });
      }
      if (!isEmpty(operation.other_comment)) {
        rowCount += 1;
        contents.push({
          label: "その他",
          value: operation.other_comment
        });
      }
      return { contents, rowCount, ...operation };
    });

  return (
    <table className={`${classes.table} fullWidth`}>
      <tbody>
        <tr>
          <td className="label ssize">日付</td>
          <td className="label msize">項目</td>
          <td className="label lsize">内容</td>
          <td className="label ssize">記録者</td>
          <td className="label sssize">確認印</td>
        </tr>
        {displayContent.map((operationItem) => {
          const targetDate = dateToObject(new Date(operationItem.target_date));

          // 記録者
          const staffRecord = operationItem.staffs
            ? operationItem.staffs.map((item) => item.snapshot_name)
            : [];
          const staffName = staffRecord.length ? staffRecord.join("、") : "";

          return operationItem.contents.map(
            (content, index): JSX.Element => {
              const key = `operation_${operationItem.operation_records_id}-${index}`;
              return (
                <tr key={key}>
                  {index === 0 && (
                    <td
                      className="topAlign ssize"
                      rowSpan={operationItem.rowCount}
                    >{`${targetDate.month}/${targetDate.day} (${targetDate.day_of_week})`}</td>
                  )}
                  <td>{content.label}</td>
                  <td>{content.value}</td>
                  {index === 0 && (
                    <td
                      className="topCenterAlign"
                      rowSpan={operationItem.rowCount}
                    >
                      {staffName}
                    </td>
                  )}
                  {index === 0 && (
                    <td className="label" rowSpan={operationItem.rowCount} />
                  )}
                </tr>
              );
            }
          );
        })}
      </tbody>
    </table>
  );
};

const OperationsRecordPrintCore = (props: Props): JSX.Element | null => {
  const { classes, operations, year, month } = props;

  React.useEffect(() => {
    props.fetchOperations(year, month);
  }, []);

  if (!operations.operations.length) {
    return null;
  }

  return (
    <div className={classes.page}>
      <header>
        <h1 className={classes.title}>業務日誌</h1>
      </header>
      <div className={classes.subTitle}>
        <span>{`${year}年${month}月分`}</span>
      </div>
      <OperationRecord classes={classes} operations={operations} />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { operations } = state.Cseg;
  return {
    operations
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg } = dispatches;
  const operationsDispatches = Cseg.operationsDispatcher(dispatch);

  return {
    fetchOperations: (year: string, month: string): Promise<void> =>
      operationsDispatches.fetchOperations(year, month)
  };
};

export const CsegOperationsRecordPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OperationsRecordPrintCore));
