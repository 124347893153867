import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import oneLetterWeekdaysJapanese from "@utils/date/oneLetterWeekdaysJapanese";

/**
 *  日付フォーマッター
 *  YYYY年MM月DD日 (d)
 */
export const dateTodayInFormat = (
  date: string | Date = new Date(),
  withDayOfWeek = true
): string =>
  dateToLocalisedString(date, "YYYY年MM月DD日") +
  (withDayOfWeek
    ? ` (${oneLetterWeekdaysJapanese[+dateToLocalisedString(date, "d")]})`
    : "");
