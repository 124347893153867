import { isWithinRange } from "@utils/domain/mgr/isWithinRange";

/**
 * 全体時間の算出
 */
export const calculateTotalTime = (
  item: { inTime: string; outTime: string },
  list: { inTime: string; outTime: string }[]
): {
  result: { inTime: string; outTime: string };
  targetList: { inTime: string; outTime: string }[];
} => {
  let result = { ...item };
  let targetList: { inTime: string; outTime: string }[] = [];

  list.forEach((record) => {
    // 時間かぶっているかチェック
    const start1 = isWithinRange(item.inTime, item.outTime, record.inTime);
    const end1 = isWithinRange(item.inTime, item.outTime, record.outTime);
    const start2 = isWithinRange(record.inTime, record.outTime, item.inTime);
    const end2 = isWithinRange(record.inTime, record.outTime, item.outTime);
    const equal =
      item.inTime === record.inTime && item.outTime === record.outTime;

    if (end2) {
      result.outTime = record.outTime;
    }
    if (start2) {
      result.inTime = record.inTime;
    }

    // 重複があった場合、過去スキップしたデータをチェックしなおす
    if (start1 || end1 || start2 || end2) {
      const data = calculateTotalTime(result, targetList);
      result = data.result;
      targetList = data.targetList;
    } else if (!equal) {
      targetList.push(record);
    }
  });

  return { result, targetList };
};
