import * as React from "react";
import { createStyles, withStyles } from "@material-ui/core";
import { StyleRules, WithStyles } from "@material-ui/core/styles";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { WELFARE_SPECIALIST_PLACEMENT_TYPE_ITEMS } from "@constants/mgr/TANKINYUSHO/variables";
import { FormikProps, getIn } from "formik";
import { FacilityValues } from "@initialize/mgr/TANKINYUSHO/facility/initialValues";

const styles = (): StyleRules =>
  createStyles({
    dateWrap: {
      margin: "-20px 0 30px 32px"
    },
    helperText: {
      fontSize: 12,
      color: "rgba(0, 0, 0, 0.6)"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
  tooltip: JSX.Element;
};
type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 福祉専門職員配置加算 (短期入所)
 * 差分
 *   - facilityType:3以外は利用できない
 *   - 福祉専門職員配置等加算 (Ⅲ)がない
 *   - tooltip直定義
 */
const WelfareSpecialistPlacementTypeFields = (props: Props): JSX.Element => {
  const value = getIn(
    props.formikProps.values,
    "additionalItem.welfareSpecialistPlacementType"
  );
  const { setFieldValue } = props.formikProps;
  const { facilityType } = props.formikProps.values.basic;
  const disabled = facilityType !== "3";

  // 変更時、開始・終了年月日は初期値に戻す
  const onChangeRadio = React.useCallback(() => {
    const init = { year: "NOT_SELECTED", month: "", day: "" };
    setFieldValue("additionalItem.welfareConditionStartDate", init);
    setFieldValue("additionalItem.welfareConditionEndDate", init);
  }, []);

  return (
    <div>
      <FormikRadioButtons
        name="additionalItem.welfareSpecialistPlacementType"
        label="福祉専門職員配置等加算"
        options={WELFARE_SPECIALIST_PLACEMENT_TYPE_ITEMS}
        tooltip={props.tooltip}
        disabled={disabled}
        onChangeHook={onChangeRadio}
      />
      {value !== WELFARE_SPECIALIST_PLACEMENT_TYPE_ITEMS[0].value && !disabled && (
        <div className={props.classes.dateWrap}>
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.welfareConditionStartDate"
            label="開始年月日"
            setFormikFieldValue={setFieldValue}
            addYearTo={1}
            style={{ marginBottom: 22 }}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.welfareConditionEndDate"
            label="終了年月日"
            setFormikFieldValue={setFieldValue}
            addYearTo={5}
            style={{ marginBottom: 0 }}
          />
          <span className={props.classes.helperText}>
            開始年月日または終了年月日は、決まっている場合のみ入力してください
          </span>
        </div>
      )}
    </div>
  );
};

export default React.memo(
  withStyles(styles)(WelfareSpecialistPlacementTypeFields)
);
