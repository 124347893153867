import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import format from "date-fns/format";

export type GetFacilityUsersListResponse = {
  data: {
    uif_id: number;
    displayName: string;
    kanaName: string;
  }[];
};

/**
 * 対象年月日において、サービス提供期間内である施設利用者の一覧を取得する(日単位を考慮)。
 * @param date Date
 */
export const getFacilityUsersList = async (
  date: Date
): Promise<AxiosResponse<GetFacilityUsersListResponse>> => {
  const targetDate = format(date, "YYYYMMDD");
  const url = `${VERSION_URL_201910}/facility/users/list/${targetDate}`;
  return request.get<GetFacilityUsersListResponse>(url);
};

export default getFacilityUsersList;
