import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { uniq } from "lodash-es";
import { format } from "date-fns";

// api
import {
  GetFacilityUsersResponse,
  GetFacilityUsersResponseElement
} from "@api/requests/facility/getFacilityUsers";

// stores
import { UserState } from "@stores/domain/user/type";

// mui
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

// constants
import {
  DEFAULT_SELECT_VALUE,
  PatternListType,
  patternListFormat
} from "@constants/variables";
import { SelectMonthValue } from "@interfaces/ui/form";

// components
import { SearchUsersForm } from "@components/organisms/mgr/common/record/recordEachUser/SearchUsersForm";
import { RecordUserRow } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserRow";
import { RecordUserEmptyMessage } from "@components/organisms/mgr/common/record/recordEachUser/RecordUserEmptyMessage";
import SectionTitle from "@components/atoms/SectionTitle";

// utils
import { filterUsersByKana } from "@utils/domain/record/filterUsersByKana";
import { filterActiveUsers } from "@utils/domain/record/filterActiveUsers";

const styles = (): StyleRules =>
  createStyles({
    paperContainer: {
      padding: "30px 32px 32px 32px",
      margin: 16
    },
    ul: {
      listStyle: "none",
      padding: 0,
      marginTop: 8,
      marginBottom: 0
    }
  });

type OwnProps = {
  users: GetFacilityUsersResponse["data"];
  buttonList: React.ComponentProps<typeof RecordUserRow>["buttonList"];
  userState?: UserState;
  fetchUsers: () => Promise<void>;
  fetchFacility: () => Promise<void>;
  facilitySubType?: number | null;
};

type Props = OwnProps & WithStyles<typeof styles>;

// フックの返却型定義
type UseRecordEachUserSubTypeReturnType = {
  currentUsers: GetFacilityUsersResponse["data"];
  targetPattern: PatternListType[];
  setTargetPattern: React.Dispatch<React.SetStateAction<PatternListType[]>>;
  expiredVisibility: boolean;
  setExpiredVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  handleSearchUsers: (e: React.MouseEvent<HTMLElement>) => void;
  handleClear: () => void;
  isZeroMatch: boolean;
  handleChangeMonthSelect: (selectDate: SelectMonthValue) => void;
  isReset: boolean;
  setIsReset: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * @param users ユーザー
 * @param setCurrentUsers 表示するユーザー
 * @param fetchUsers usersをfetchする処理
 * @param fetchErrors errorsをfetchする処理
 * @returns
 */
const useRecordEachUserSubType = (
  users: GetFacilityUsersResponse["data"],
  fetchUsers: () => Promise<void>,
  fetchFacility: () => Promise<void>
): UseRecordEachUserSubTypeReturnType => {
  const [currentUsers, setCurrentUsers] = useState<
    GetFacilityUsersResponseElement[]
  >([]);
  const [targetPattern, setTargetPattern] = useState<PatternListType[]>(
    patternListFormat
  );
  const [expiredVisibility, setExpiredVisibility] = useState<boolean>(true);
  const [isZeroMatch, setIsZeroMatch] = useState<boolean>(false);
  const [searchConditionsIds, setSearchConditionsIds] = useState<number[]>([]);
  const [yearMonthMonitoring, setYearMonthMonitoring] = useState<
    SelectMonthValue | undefined
  >(undefined);
  const [isReset, setIsReset] = useState<boolean>(false);

  useEffect(() => {
    fetchUsers();
    fetchFacility();
  }, []);

  /**
   * userがfetchされたらセットする
   * 退所者の非表示：ONが初期値であるため、絞り込みも行う
   * 一度しか使用しない
   */
  useEffect(() => {
    if (!users.length) return;
    let filteredUsers = users;
    // 退所者の非表示による絞り込み
    filteredUsers = filterActiveUsers(filteredUsers, true);
    setCurrentUsers(filteredUsers);
    setIsZeroMatch(filteredUsers.length === 0);
  }, [users]);

  /**
   * ふりがな絞り込み処理
   * @param usersResElm ユーザー
   * return 絞り込み後のユーザーのID配列
   */
  const extractClickablePatternIds = useCallback(
    (usersResElm: GetFacilityUsersResponseElement[]) => {
      const arr: number[] = [];
      [...usersResElm].forEach((item) => {
        const existingPattern = patternListFormat.find((pattern) => {
          const checkKanaReg = new RegExp(`^${pattern.targetKana}.*$`);
          return checkKanaReg.test(item.kanaName);
        });
        if (existingPattern) {
          arr.push(existingPattern.id);
        }
      });
      return uniq(arr);
    },
    [users, patternListFormat, uniq]
  );

  /**
   * ふりがなの選択状態を変更する
   */
  useEffect(() => {
    const patternList = patternListFormat.map((item) => {
      let { disabled, checked } = item;
      const clickablePatternID = extractClickablePatternIds(users);
      if (!clickablePatternID.includes(item.id)) {
        disabled = true;
      } else if (searchConditionsIds.includes(item.id)) {
        checked = true;
      }
      return {
        ...item,
        disabled,
        checked
      };
    });
    setTargetPattern(patternList);
  }, [
    extractClickablePatternIds,
    users,
    patternListFormat,
    searchConditionsIds
  ]);

  /**
   * 年月変更時
   * @param selectDate 選択した年月
   */
  const handleChangeMonthSelect = (selectDate: SelectMonthValue): void => {
    setYearMonthMonitoring(selectDate);
  };

  /**
   * 絞り込みボタン押下時の処理
   * @param e イベント
   */
  const handleSearchUsers = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    // モニタリング実施年月の一方が選択済みで一方が未選択なら、絞り込みしない
    if (
      yearMonthMonitoring &&
      !(
        yearMonthMonitoring.year !== DEFAULT_SELECT_VALUE &&
        yearMonthMonitoring.month
      )
    ) {
      return;
    }

    const checkedPatterns = targetPattern.filter((item) => item.checked);
    const checkedPatternsIds = checkedPatterns.map((item) => item.id);
    setSearchConditionsIds(checkedPatternsIds);

    // フリガナでの絞り込み
    let filteredUsers = filterUsersByKana<GetFacilityUsersResponseElement>(
      users,
      checkedPatterns
    );

    // 退所者の非表示による絞り込み
    filteredUsers = filterActiveUsers(filteredUsers, expiredVisibility);

    // モニタリング実施年月による絞り込み
    if (yearMonthMonitoring) {
      const yearMonthFormatted = format(
        `${yearMonthMonitoring.year}-${yearMonthMonitoring.month}`,
        "YYYYMM"
      );
      filteredUsers = filteredUsers.filter((user) => {
        return (
          user.monitoring_month &&
          user.monitoring_month.includes(yearMonthFormatted)
        );
      });
    }

    setCurrentUsers(filteredUsers);
    setIsZeroMatch(filteredUsers.length === 0);
  };

  /**
   * 絞り込みリセット用の関数
   */
  const handleClear = (): void => {
    const resetPattern = targetPattern.map((p) => ({
      ...p,
      checked: false
    }));
    setTargetPattern(resetPattern);
    setExpiredVisibility(true);
    setYearMonthMonitoring(undefined);
    setIsReset(true);
  };

  return {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    isZeroMatch,
    handleChangeMonthSelect,
    isReset,
    setIsReset
  };
};

/**
 * 絞り込みFormと、利用者情報一覧をまとめる
 */
const RecordEachUserSubTypeCore = (props: Props): JSX.Element => {
  const {
    classes,
    users,
    buttonList,
    userState,
    fetchUsers,
    fetchFacility,
    facilitySubType
  } = props;

  const {
    currentUsers,
    targetPattern,
    setTargetPattern,
    expiredVisibility,
    setExpiredVisibility,
    handleSearchUsers,
    handleClear,
    isZeroMatch,
    handleChangeMonthSelect,
    isReset,
    setIsReset
  } = useRecordEachUserSubType(users, fetchUsers, fetchFacility);

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <SectionTitle label="利用者ごとの記録" isTitleNoMargin />
      <SearchUsersForm
        targetPattern={targetPattern}
        setTargetPattern={setTargetPattern}
        expiredVisibility={expiredVisibility}
        setExpiredVisibility={setExpiredVisibility}
        handleSearchUsers={handleSearchUsers}
        handleClear={handleClear}
        userState={userState}
        handleChangeMonthSelect={handleChangeMonthSelect}
        isReset={isReset}
        setIsReset={setIsReset}
      />

      {/* ゼロマッチ・利用者登録なしメッセージ */}
      {(isZeroMatch || !users.length) && <RecordUserEmptyMessage />}

      {!isZeroMatch && Boolean(currentUsers.length) && (
        <ul className={classes.ul}>
          {currentUsers.map((user) => (
            <RecordUserRow
              key={`row-${user.uif_id}`}
              buttonList={buttonList}
              user={user}
              facilitySubType={facilitySubType}
            />
          ))}
        </ul>
      )}
    </Paper>
  );
};

export const RecordEachUserSubType = withStyles(styles)(
  RecordEachUserSubTypeCore
);
