import { OperationsState } from "@stores/domain/mgr/GroupHome/operations/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { CheckBoxValue, ShotMultipleSelectValue } from "@interfaces/ui/form";
import { CUSTOM_RECORD_TARGET_TYPE } from "@constants/variables";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type RecordOperationsValues = {
  record: {
    target_date: string; // 検索用
    setting_type: number;
    facility_unit_id: number;
    operation_record_id: number | null;
    custom_record: {
      input_type_first: {
        [custom_record_id: number]: {
          custom_record_input_id: number | null;
          custom_record_item_id: number;
          input_data: string;
        };
      };
      input_type_second: {
        [custom_record_id: number]: {
          [custom_record_item_choices_id: number]: {
            custom_record_input_id: number | null;
            custom_record_item_id: number;
            choiced_item_id: number;
            checked: CheckBoxValue;
          };
        };
      };
      input_type_third: {
        [custom_record_id: number]: {
          // postする際に差異を判別するため使用
          readonly beforeValues: {
            custom_record_input_id: number | null;
            custom_record_item_id: number;
            choiced_staff_id: number;
            choiced_staff_name_snapshot: string;
            checked: CheckBoxValue;
          }[];
          // formで実際に変更するidList
          itemIdList: ShotMultipleSelectValue;
        };
      };
    };
  }[];
};

// カスタムレコード
const createCustomRecordsValues = (
  settingType: number,
  customRecords: CustomRecordsState,
  operation?: OperationsState["operation"][0]
): RecordOperationsValues["record"][0]["custom_record"] => {
  const obj1 = {};
  const obj2 = {};
  const obj3 = {};

  if (customRecords.length > 0) {
    customRecords
      .filter((item) => item.setting_type === settingType)
      .forEach((record) => {
        const recordInput =
          operation &&
          operation.operation_record_input.filter(
            (item) => item.custom_record_item_id === record.id
          );
        switch (record.input_type) {
          // テキスト形式
          case 1:
            Object.assign(obj1, {
              [record.id]: {
                custom_record_input_id:
                  recordInput && recordInput.length > 0
                    ? recordInput[0].id
                    : null,
                custom_record_item_id: record.id,
                input_data:
                  recordInput && recordInput.length > 0
                    ? recordInput[0].input_data
                    : ""
              }
            });
            break;
          // チェックボックス形式
          case 2: {
            const checkBoxObj = {};
            if (record.choices) {
              record.choices.forEach((choice) => {
                const recordInputChoiced = recordInput
                  ? recordInput.find(
                      (item) => item.choiced_item_id === choice.id
                    )
                  : null;
                Object.assign(checkBoxObj, {
                  [choice.id]: {
                    custom_record_input_id: recordInputChoiced
                      ? recordInputChoiced.id
                      : null,
                    custom_record_item_id: record.id,
                    choiced_item_id: choice.id,
                    checked: recordInputChoiced
                      ? numberToBoolean(recordInputChoiced.checked)
                      : false
                  }
                });
              });
            }

            Object.assign(obj2, { [record.id]: { ...checkBoxObj } });
            break;
          }
          // セレクトボックス形式
          case 3: {
            const beforeValues = recordInput
              ? recordInput.map((support) => {
                  return {
                    custom_record_input_id: support.id,
                    custom_record_item_id: record.id,
                    choiced_staff_id: support.choiced_staff_id,
                    choiced_staff_name_snapshot:
                      support.choiced_staff_name_snapshot,
                    checked: numberToBoolean(support.checked)
                  };
                })
              : [];
            const itemIdList = recordInput
              ? recordInput
                  .filter((item) => item.checked === 1)
                  .map((support) => {
                    return {
                      category_id: support.id,
                      id: support.choiced_staff_id,
                      label: support.choiced_staff_name_snapshot,
                      is_delete: null
                    };
                  })
              : [];
            Object.assign(obj3, { [record.id]: { beforeValues, itemIdList } });
            break;
          }
          default:
            break;
        }
      });
  }
  return {
    input_type_first: obj1,
    input_type_second: obj2,
    input_type_third: obj3
  };
};

const initialValues = (
  values: OperationsState,
  customRecords: CustomRecordsState,
  unitId: number,
  dateList: string[]
): RecordOperationsValues => {
  const setting_type =
    unitId === 0
      ? CUSTOM_RECORD_TARGET_TYPE.operation_common
      : CUSTOM_RECORD_TARGET_TYPE.operation_unit;

  return {
    record: dateList.map((date) => {
      const operation = values.operation.find(
        (value) => value.target_date === date
      );
      return {
        setting_type,
        target_date: date,
        facility_unit_id: unitId,
        operation_record_id: operation ? operation.operation_records_id : null,
        custom_record: createCustomRecordsValues(
          setting_type,
          customRecords,
          operation
        )
      };
    })
  };
};

export default initialValues;
