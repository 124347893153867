import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { FormikProps } from "formik";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { InitialValues } from "@interfaces/v202104/mgr/CHIIKIIKO/report/initial";
import {
  CHIIKIIKO_MODAL_STATUS_LIST,
  CHIIKIIKO_TRIAL_USE_TYPE_SELECT_LIST,
  CHIIKIIKO_TRIAL_STAY_TYPE_SELECT_LIST
} from "@constants/mgr/CHIIKIIKO/variables";
import { selectDateValueToDate, parseDateString } from "@utils/date";
import { isEmpty } from "lodash-es";
import { addMonths, addYears } from "date-fns";

type StateProps = {
  formikPropsValues: FormikProps<InitialValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  selectedDate: Date;
};

type Props = StateProps;

export const InOutReportDialogFields = (props: Props): JSX.Element => {
  const ref = React.useRef(true);

  const [
    disableShortTimeForPsychiatricHospitalFlg,
    setDisableShortTimeForPsychiatricHospitalFlg
  ] = React.useState(false);

  const [showMemoStatus, setShowMemoStatus] = React.useState(false);

  const [showMemoTrial, setShowMemoTrial] = React.useState(false);

  const [memoSize, setMemoSize] = React.useState(536);

  const [isMemoRequired, setIsMemoRequired] = React.useState(false);

  const [isCheck, setIsCheck] = React.useState(false);

  const {
    dateBeginHospital,
    dateEndHospital,
    status,
    trialUseType,
    trialStayType,
    shortTimeForPsychiatricHospitalFlg
  } = props.formikPropsValues.values.initial;

  // dateBeginHospitalまたはdateEndHospital変更時
  React.useEffect(() => {
    const dateBeginString = selectDateValueToDate(dateBeginHospital);
    let dateBegin;
    if (!isEmpty(dateBeginString)) {
      dateBegin = parseDateString(dateBeginString);
    }

    const dateEndString = selectDateValueToDate(dateEndHospital);
    let dateEnd;
    if (!isEmpty(dateEndString)) {
      dateEnd = parseDateString(dateEndString);
    }

    let disabled = true;
    if (dateBegin && dateEnd) {
      const threeMonthsFromBegin = addMonths(dateBegin, 3);
      const oneYearFromBegin = addYears(dateBegin, 1);
      disabled = dateEnd < threeMonthsFromBegin || oneYearFromBegin <= dateEnd;
    }
    setDisableShortTimeForPsychiatricHospitalFlg(disabled);
  }, [dateBeginHospital, dateEndHospital]);

  // status変更時
  React.useEffect(() => {
    const disabledMemoStatus = status !== "0" && status !== "3";
    const isRequired = status === "3";
    const disabledMemoTrial = trialStayType !== "0";
    let size = 536;
    if (disabledMemoStatus && disabledMemoTrial) {
      size = 536 - (92 + 4) * 2;
    } else if (disabledMemoStatus || disabledMemoTrial) {
      size = 536 - 92 - 4;
    }
    setShowMemoStatus(disabledMemoStatus);
    setIsMemoRequired(isRequired);
    setMemoSize(size);
    if (ref.current) return;

    if (disabledMemoStatus) {
      props.setFormikFieldValue(
        "initial.memoStatus",
        getLabelFromOptions(status, CHIIKIIKO_MODAL_STATUS_LIST)
      );
    } else {
      props.setFormikFieldValue("initial.memoStatus", "");
    }
  }, [status]);

  // trial_use_type,trial_stay_type変更時
  React.useEffect(() => {
    const disabledMemoTrial = trialUseType !== "0" && trialStayType !== "0";
    const disabledMemoStatus = status !== "0" && status !== "3";
    let size = 536;
    if (disabledMemoStatus && disabledMemoTrial) {
      size = 536 - (92 + 4) * 2;
    } else if (disabledMemoStatus || disabledMemoTrial) {
      size = 536 - 92 - 4;
    }
    setShowMemoTrial(disabledMemoTrial);
    setMemoSize(size);
    if (ref.current) {
      ref.current = false;
      return;
    }

    if (disabledMemoTrial) {
      props.setFormikFieldValue(
        "initial.memoTrial",
        getLabelFromOptions(
          trialStayType,
          CHIIKIIKO_TRIAL_STAY_TYPE_SELECT_LIST
        )
      );
    } else {
      props.setFormikFieldValue("initial.memoTrial", "");
    }
  }, [trialUseType, trialStayType]);

  // shortTimeForPsychiatricHospitalFlg変更時
  React.useEffect(() => {
    setIsCheck(shortTimeForPsychiatricHospitalFlg);
  }, [shortTimeForPsychiatricHospitalFlg]);

  return (
    <FormGroup>
      <FormGroup style={{ paddingLeft: 32 }}>
        <FormikSelect
          name="initial.status"
          label="支援内容"
          style={{ width: 256 }}
          options={CHIIKIIKO_MODAL_STATUS_LIST}
        />
        <FormikSelect
          name="initial.trialUseType"
          label="体験利用"
          size="smallMedium"
          options={CHIIKIIKO_TRIAL_USE_TYPE_SELECT_LIST}
        />
        <FormikSelect
          name="initial.trialStayType"
          label="体験宿泊"
          options={CHIIKIIKO_TRIAL_STAY_TYPE_SELECT_LIST}
          size="smallMedium"
        />
        <FormikCheckbox
          name="initial.firstAdditionFlg"
          label="初回加算"
          style={{ marginBottom: 0 }}
        />
        <FormikCheckbox
          name="initial.housingSupportCooperationFlg"
          label="居住支援連携体制加算"
          style={{ marginBottom: 0 }}
        />
        <FormikCheckbox
          name="initial.housingSupportPromotionFlg"
          label="地域居住支援体制強化推進加算"
          style={{ marginBottom: 12 }}
        />
        <FormikSwitch
          name="initial.dischargeMonthAdditionFlg"
          label="退院・退所月加算"
        >
          <FormikSelectDateNotSelectedDefault
            name="initial.dateBeginHospital"
            label="入院・入所日"
            style={{ marginBottom: 12 }}
            setFormikFieldValue={props.setFormikFieldValue}
            required
          />
          <FormikSelectDateNotSelectedDefault
            name="initial.dateEndHospital"
            label="退院・退所日"
            style={{ marginBottom: 15 }}
            setFormikFieldValue={props.setFormikFieldValue}
            required
            overrideYearTo={
              props.selectedDate.getMonth() + 1 === 12
                ? props.selectedDate.getFullYear() + 1
                : props.selectedDate.getFullYear()
            }
          />
          <FormikCheckbox
            name="initial.shortTimeForPsychiatricHospitalFlg"
            label="精神科病院に入院後3月以上1年未満に退院"
            disabled={disableShortTimeForPsychiatricHospitalFlg}
            style={{ marginBottom: 0 }}
          />
          {isCheck && disableShortTimeForPsychiatricHospitalFlg && (
            <FormHelperText
              style={{
                marginTop: -2,
                marginBottom: 8,
                color: "#ff5656"
              }}
            >
              入院・入所開始日と退院・退所日の期間が3月以上1年未満になるように設定してください。
            </FormHelperText>
          )}
        </FormikSwitch>
        <FormGroup row style={{ marginBottom: -20 }}>
          {showMemoStatus && (
            <FormikTextField
              name="initial.memoStatus"
              label="備考"
              style={{
                width: 92,
                marginTop: 16,
                marginRight: 4
              }}
              disabled
              disabledStyle
            />
          )}
          {showMemoTrial && (
            <FormikTextField
              name="initial.memoTrial"
              label={!showMemoStatus ? "備考" : ""}
              style={{
                width: 92,
                marginTop: showMemoStatus ? 32 : 16,
                marginRight: 4
              }}
              disabled
              disabledStyle
              required={isMemoRequired}
            />
          )}
          <FormikTextField
            name="initial.memo"
            label={!showMemoStatus && !showMemoTrial ? "備考" : ""}
            style={{
              width: memoSize,
              marginTop: showMemoStatus || showMemoTrial ? 32 : 16
            }}
            required={isMemoRequired}
          />
        </FormGroup>
      </FormGroup>
    </FormGroup>
  );
};
