import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportPlanUifIdCarePlan = {
  data: {
    id: number;
    target_date: string;
    author_name: string | null;
  }[];
  response: {
    code: number;
    msg: string;
  };
};

/**
 * 対象ユーザーの介護計画一覧を取得する。
 * @param uifId ユーザーID
 */
export const getSupportPlanUifIdCarePlan = async (
  uifId: string
): Promise<AxiosResponse<GetSupportPlanUifIdCarePlan>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/care_plan`;
  return request.get<GetSupportPlanUifIdCarePlan>(url);
};
