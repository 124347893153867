import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Link } from "react-router-dom";

import UserList, { UserParam } from "@components/organisms/mgr/UserList";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";

import { createStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { StyleRules, withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import Paper from "@material-ui/core/Paper";

import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { ErrorsState } from "@stores/domain/errors/types";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";
import SectionTitle from "@components/atoms/SectionTitle";

import { dateToLocalisedString, getDiff } from "@utils/date";
import MuiCheckbox from "@components/molecules/MuiCheckbox";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    container: {
      margin: spacing.unit * 2,
      marginTop: 12
    },
    newButtonLink: {
      textDecoration: "none"
    },
    newButton: {
      boxShadow: "none",
      borderRadius: 4,
      width: 120,
      letterSpacing: "2px"
    },
    toolBar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end"
    },
    countText: {
      fontSize: 12,
      letterSpacing: "2px"
    },
    paperContainer: {
      paddingTop: "30px",
      paddingBottom: "32px",
      paddingLeft: "32px",
      paddingRight: "32px",
      marginTop: 20
    },
    formControlLabelBoxStyle: {
      marginRight: 0
    }
  });

type DispatchProps = {
  openErrorsDialog: () => void;
};

type StateProps = {
  error: ErrorsState;
};

type OwnProps = {
  user?: UserState;
  facilityUsers: GetFacilityUsersResponse["data"];
  clearForm?: () => void;
};

type State = {
  expiredVisibility: boolean;
};

type Props = WithStyles<typeof styles> &
  StateProps &
  OwnProps &
  DispatchProps & {};

/**
 * 利用者情報
 */
class Users extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expiredVisibility: true
    };
  }

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  private clearForm = (): void => {
    if (this.props.clearForm !== undefined) {
      this.props.clearForm();
    }
  };

  public render(): JSX.Element {
    const { classes } = this.props;
    const visibleUsersInfo: UserParam[] = [];
    this.props.facilityUsers.forEach((user) => {
      /**
       * サービス提供終了日が現在日より過去か判定
       */
      const isExpired = (targetDateInUser: Date): boolean => {
        const targetDate = new Date(targetDateInUser);
        const endDate = new Date(targetDate.getTime());
        endDate.setDate(endDate.getDate() + 1);
        if (getDiff(new Date(), endDate) <= 0) {
          return false;
        }
        return true;
      };
      if (this.state.expiredVisibility && user.date_end_in_service) {
        const targetDate = new Date(user.date_end_in_service);
        if (isExpired(targetDate)) {
          return;
        }
      }
      /**
       * サービス提供日が現在日より過去の場合のみ、表示用サブテキストを返す
       */
      const getSubText = (): string => {
        if (!user.date_end_in_service) {
          return "";
        }
        const targetDate = new Date(user.date_end_in_service);
        if (!isExpired(targetDate)) {
          return "";
        }
        return `サービス提供終了日: ${dateToLocalisedString(
          targetDate,
          "YYYY年M月D日"
        )}`;
      };
      visibleUsersInfo.push({
        id: user.uif_id,
        text: user.displayName,
        subtext: getSubText()
      });
    });

    const onClickCheckbox = (
      event: React.ChangeEvent<HTMLInputElement>
    ): void => {
      const { checked } = event.target;
      this.setState({ expiredVisibility: checked });
    };

    return (
      <>
        {this.props.error.users.hasError ? (
          <InvoiceErrorBar
            message={`${this.props.error.users.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
            onClick={this.onClickErrorDialog}
          />
        ) : null}
        <div className={classes.container}>
          <div className={classes.toolBar}>
            <Link to="users/new" className={classes.newButtonLink}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.newButton}
                onClick={this.clearForm}
              >
                新規登録
              </Button>
            </Link>
            <MuiCheckbox
              label="退所者を非表示にする"
              name="expiredVisibility"
              checked={this.state.expiredVisibility}
              onChange={onClickCheckbox}
              formControlLabelBoxStyle={classes.formControlLabelBoxStyle}
              style={{ margin: "0" }}
            />
          </div>
          <Paper
            elevation={0}
            className={classes.paperContainer}
            style={{ marginTop: "0" }}
          >
            <SectionTitle
              label="利用者リスト"
              subLabel={`全${visibleUsersInfo.length}件`}
              subClassName={classes.countText}
              isTitleNoMargin
            />
            <UserList
              users={visibleUsersInfo}
              userError={this.props.error.users}
            />
          </Paper>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  error: state.errors
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    openErrorsDialog: (): {
      readonly type: "UI/SHOW_ERRORS_DIALOG";
    } => dispatch(errorsDialogActions.showErrorsDialog())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Users));
