/**
 * ２つの時間が重複しているかチェック
 */
export const checkRangeDateTime = (
  startTimeFirst: Date,
  endTimeFirst: Date,
  startTimeSecond: Date,
  endTimeSecond: Date,
  message: string,
  allowContinuing?: boolean // 連続時間を許可するか
): string | undefined => {
  let errorMessage;
  if (
    Number.isNaN(startTimeFirst.getTime()) ||
    Number.isNaN(endTimeFirst.getTime()) ||
    Number.isNaN(startTimeSecond.getTime()) ||
    Number.isNaN(endTimeSecond.getTime())
  ) {
    return errorMessage;
  }

  // どちらかが逆転している場合もエラーは出さない(逆転エラーは他のバリデーションで担保すべき)
  if (endTimeFirst <= startTimeFirst || endTimeSecond <= startTimeSecond) {
    return errorMessage;
  }

  if (
    allowContinuing
      ? endTimeFirst < startTimeSecond || endTimeSecond < startTimeFirst
      : endTimeFirst <= startTimeSecond || endTimeSecond <= startTimeFirst
  ) {
    errorMessage = `${message}は重複するように入力してください`;
  }

  return errorMessage;
};
