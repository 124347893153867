import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Theme, Typography } from "@material-ui/core";
import { FieldItem } from "@interfaces/ui/form";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTextField from "@components/molecules/FormikTextField";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

// store
import { connect } from "react-redux";
import { FieldArray, FormikProps } from "formik";
import { SupportProcedureDetailFormValues } from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      padding: "24px 16px",
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      gap: "16px"
    },
    inputArea: {
      padding: "24px 16px",
      borderRadius: "4px",
      border: "solid 1px rgba(0, 0, 0, 0.12)"
    },
    border: {
      color: "#e0e0e0",
      borderTop: "none",
      borderBottom: "1px solid",
      width: "100%",
      margin: 0
    },
    contentText: {
      fontSize: 14,
      color: "#000",
      whiteSpace: "pre-wrap"
    },
    MT4: {
      marginTop: spacing.unit * 0.5
    },
    MT12: {
      marginTop: spacing.unit * 1.5
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    MT24: {
      marginTop: spacing.unit * 3
    }
  });

type OwnProps = {
  formikProps: FormikProps<SupportProcedureDetailFormValues>;
  isEdit: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const checkOptions: FieldItem[] = [
  { label: "-", value: "0" },
  { label: "◯", value: "1" },
  { label: "△", value: "2" },
  { label: "×", value: "3" }
];

const SupportProcedureRecordFormDetailCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const { formikProps } = props;
  const { supportProcedureFormDetails } = formikProps.values;

  return (
    <div className={classes.wrapper}>
      <FieldArray name="supportProcedureFormDetails">
        {(): JSX.Element => {
          return (
            <>
              {supportProcedureFormDetails.map((_, i) => {
                const key = `supportProcedureFormDetailsTableRow${i}`;
                return (
                  <div className={classes.inputArea} key={key}>
                    {/* 開始時間 ~ 終了時間 */}
                    <Typography className={classes.contentText}>
                      {(supportProcedureFormDetails &&
                        supportProcedureFormDetails[i] &&
                        supportProcedureFormDetails[i].startTime) ||
                        "--:--"}
                      {" 〜 "}
                      {(supportProcedureFormDetails &&
                        supportProcedureFormDetails[i] &&
                        supportProcedureFormDetails[i].endTime) ||
                        "--:--"}
                    </Typography>
                    {/* 活動 */}
                    <Typography
                      className={`${classes.contentText} ${classes.MT4}`}
                    >
                      {(supportProcedureFormDetails &&
                        supportProcedureFormDetails[i] &&
                        supportProcedureFormDetails[i].activity) ||
                        "-"}
                    </Typography>
                    {/* サービス手順・留意事項 */}
                    <Typography
                      className={`${classes.contentText} ${classes.MT12}`}
                    >
                      {(supportProcedureFormDetails &&
                        supportProcedureFormDetails[i] &&
                        supportProcedureFormDetails[i].serviceProcedure) ||
                        "-"}
                    </Typography>

                    <hr className={`${classes.border} ${classes.MT24}`} />

                    <FormikSelect
                      name={`supportProcedureFormDetails[${i}].check`}
                      label="チェック"
                      options={checkOptions}
                      style={{ marginTop: "24px" }}
                      size="mobileHalf"
                      isMobile
                      readOnly={!props.isEdit}
                    />
                    {props.isEdit ? (
                      <FormikTextField
                        name={`supportProcedureFormDetails[${i}].appearance`}
                        label="様子"
                        style={{
                          marginBottom: 0
                        }}
                        size="fullSize"
                        multiline
                      />
                    ) : (
                      <ReadonlyTextField
                        label="様子"
                        defaultValue="-"
                        style={{
                          marginBottom: 0
                        }}
                        size="fullSize"
                        value={supportProcedureFormDetails[i].appearance}
                        multiline
                      />
                    )}
                  </div>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const SupportProcedureRecordFormDetail = withStyles(styles)(
  connect(null)(SupportProcedureRecordFormDetailCore)
);
