import * as React from "react";

// mui
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const styles = (): StyleRules =>
  createStyles({
    unitName: {
      backgroundColor: "#eceff1",
      padding: "8px 16px",
      fontSize: 16,
      "& + ul": {
        borderTop: "none"
      }
    },
    noUser: {
      color: "rgba(0, 0, 0, 0.38)"
    }
  });

type OwnProps = {
  unitLabel: string;
  userIdsNum: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 絞り込みFormと、利用者情報一覧をまとめる
 */
const RecordUserGroupCore = (props: Props): JSX.Element => {
  const { classes, unitLabel, userIdsNum } = props;
  return (
    <Typography color="primary" className={classes.unitName}>
      {unitLabel}
      {userIdsNum === 0 && (
        <span className={classes.noUser}>（利用者が登録されていません）</span>
      )}
    </Typography>
  );
};

export const RecordUserGroup = withStyles(styles)(RecordUserGroupCore);
