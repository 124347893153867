import * as React from "react";

import omit from "lodash-es/omit";

import TableBody, { TableBodyProps } from "@material-ui/core/TableBody";
import {
  KnowbeTableRow,
  BasicTableBodyProps,
  MutualProps,
  KnowbeTableCellParam
} from "@components/presentational/molecules/KnowbeTableRow";

type OwnProps = {
  itemsContainer: KnowbeTableCellParam[][];
};

type KnowbeTableBodyProps = OwnProps &
  BasicTableBodyProps &
  MutualProps &
  TableBodyProps;

/**
 * Knowbeの独自コンポーネント。stripeというpropで行の縞模様を制御したり、dashedBorderというpropで列の区切りに点線を入れたりすることができる。
 */
export const KnowbeTableBody = (props: KnowbeTableBodyProps): JSX.Element => {
  const {
    uniqueKey,
    itemsContainer,
    rowClassName,
    stripe = true,
    dashedBorder = false,
    hover = false,
    isSelected,
    onClickRow,
    // 以下はアクセシビリティ対応用props
    role,
    ariaChecked,
    tabIndex = -1
  } = props;

  const excludedProps = omit(props, [
    "uniqueKey",
    "itemsContainer",
    "rowClassName",
    "stripe",
    "dashedBorder",
    "hover",
    "isSelected",
    "onClickRow",
    "role",
    "ariaChecked",
    "tabIndex"
  ]);

  return (
    <TableBody {...excludedProps}>
      {itemsContainer.map((items, index) => {
        const keyItem = `${uniqueKey}-${index}`;
        return (
          <KnowbeTableRow
            key={keyItem}
            uniqueKey={keyItem}
            rowClassName={rowClassName}
            stripe={stripe}
            dashedBorder={dashedBorder}
            hover={hover}
            items={items}
            role={role}
            aria-checked={ariaChecked}
            tabIndex={tabIndex}
            isSelected={isSelected}
            onClickRow={onClickRow}
          />
        );
      })}
    </TableBody>
  );
};
