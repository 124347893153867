import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  UsersValues
} from "@initialize/mgr/CHIIKIIKO/users/initialValues";
import { validation } from "@initialize/mgr/CHIIKIIKO/users/validation";
import Button from "@material-ui/core/Button";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import { BasicFields } from "@components/organisms/mgr/CHIIKIIKO/Users/BasicFields";
import { ServiceUseFields } from "@components/organisms/mgr/CHIIKIIKO/Users/ServiceUseFields";
import { RecipientCertificateFields } from "@components/organisms/mgr/CHIIKIIKO/Users/RecipientCertificateFields";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { CityState, CityParams } from "@stores/domain/city/type";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { SECONDARY_BLUE_COLOR } from "@constants/styles";
import * as URL from "@constants/url";
import isEqual from "lodash-es/isEqual";
import * as H from "history";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      height: 60,
      top: 0,
      "& > div": {
        minHeight: 60
      },
      "&+section": {
        marginTop: 8
      }
    },
    deleteButton: {
      boxShadow: "none",
      color: SECONDARY_BLUE_COLOR,
      backgroundColor: "rgba(98, 2, 238, 0)",
      padding: "6px 6px 6px 0px",
      marginLeft: 16
    },
    deleteOutline: {
      color: SECONDARY_BLUE_COLOR
    }
  });

type OwnProps = RouteComponentProps<{ id: string }> & WithStyles<typeof styles>;

type DispatchProps = {
  updateUser: (
    values: UsersValues,
    history: H.History,
    params: UsersInFacilityState["user"]
  ) => void;
  fetchOne: (id: string) => void;
  fetchCity: (params: CityParams) => void;
  showSnackbar: (params: SnackbarParams) => void;
  deleteUser: (id: string, history: H.History) => void;
  fetchFacility: () => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  cityList: CityState[];
  userInFacility: UsersInFacilityState;
  needsStopHistory: boolean;
};

type MergeProps = DispatchProps & StateProps & OwnProps;

const EditUserFormCore = (props: MergeProps): JSX.Element => {
  const [userValues, setInitialValues] = React.useState<UsersValues>(
    initialValues()
  );
  const [isFetchDone, setIsFetchDone] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const unmount = React.useRef<boolean>(false);
  React.useEffect(() => {
    (async (): Promise<void> => {
      if (!unmount.current) {
        const { id } = props.match.params;
        await props.fetchFacility();
        await props.fetchOne(id);
        setIsFetchDone(true);
      }
      unmount.current = true;
    })();
  }, []);

  React.useEffect(() => {
    setInitialValues(initialValues(props.userInFacility.user));
  }, [props.userInFacility.user]);

  React.useEffect(() => {
    (async (): Promise<void> => {
      const prefectureName =
        props.userInFacility.user.user_in_facility.prefecture_name;
      if (prefectureName) {
        await props.fetchCity({ prefectureName });
      }
    })();
  }, [props.userInFacility.user.user_in_facility.prefecture_name]);

  const confirmDiscardFormChanges = (nextValues: UsersValues): void => {
    const hasChange = !isEqual(nextValues, initialValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: UsersValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onClear = (): void => {
    setOpenModal(true);
  };

  const handleCancel = (): void => {
    setOpenModal(false);
  };

  const onDelete = (): void => {
    const id = `${props.userInFacility.user.user_in_facility.id}`;
    props.deleteUser(id, props.history);
  };

  const onSubmit = async (
    values: UsersValues,
    actions: FormikActions<UsersValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.updateUser(values, props.history, props.userInFacility.user);
    actions.setSubmitting(false);
  };

  const onCancel = (): void => {
    props.history.push(URL.USERS);
  };

  const { name_sei, name_mei } = props.userInFacility.user.user_in_facility;

  return (
    <Formik
      initialValues={userValues}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <ContentHeader
            position="sticky"
            classes={{ wrapper: props.classes.wrapper }}
          >
            <ContentHeaderRight mediaOff>
              <KnowbeButton
                kind="outline"
                style={{ position: "absolute" }}
                onClick={onCancel}
              >
                一覧に戻る
              </KnowbeButton>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                errorAction={submitError}
              />
            </ContentHeaderRight>
          </ContentHeader>
          {/* 基本情報 */}
          <BasicFields
            formikProps={formikProps}
            setFormikFieldValue={formikProps.setFieldValue}
          />
          {/* サービス利用詳細 */}
          <ServiceUseFields
            formikProps={formikProps}
            isFetchDone={isFetchDone}
            setFormikFieldValue={formikProps.setFieldValue}
          />
          {/* 受給者証の詳細 */}
          <RecipientCertificateFields
            setFormikFieldValue={formikProps.setFieldValue}
          />
          <Button
            className={props.classes.deleteButton}
            variant="contained"
            onClick={onClear}
          >
            <DeleteOutline className={props.classes.deleteOutline} />
            削除する
          </Button>
          <ConfirmDialog
            isOpen={openModal}
            onDelete={onDelete}
            onCancel={handleCancel}
            title={`${name_sei}${name_mei}さんの利用者情報を削除しますか？`}
            message={`${name_sei}${name_mei}さんの利用者情報を削除します。削除すると過去に登録した「利用実績」などのすべてのデータが完全に削除され、復元できません。削除してよろしいですか？`}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  cityList: state.city,
  userInFacility: state.CHIIKIIKO.userInFacility,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO, cityDispatch, uiDispatch } = dispatches;
  const userInFacilityDispatcher = CHIIKIIKO.userInFacilityDispatcher(dispatch);
  const facilityDispatcher = CHIIKIIKO.facilityDispatcher(dispatch);
  const cityDispatches = cityDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    fetchFacility: facilityDispatcher.fetch,
    updateUser: userInFacilityDispatcher.update,
    fetchOne: userInFacilityDispatcher.fetchOne,
    deleteUser: userInFacilityDispatcher.deleteUser,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const EditUserForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditUserFormCore)
);
