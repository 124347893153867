import React from "react";
import * as H from "history";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  createStyles,
  WithStyles,
  StyleRules,
  Theme,
  withStyles
} from "@material-ui/core/styles";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import ContentHeaderSubmit from "@components/molecules/ContentHeaderSubmit";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { AppState } from "@stores/type";
import { UsageResultsState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/report/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { InitialDataValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/report/initialData";
import { checkReportVersion } from "@utils/domain/report/checkReportVersion";
import { FacilityType } from "@constants/variables";
import { INVOICE_PATHS } from "@constants/mgr/SHUROTEICHAKU/variables";
import { DummySelectDate } from "@components/molecules/DummySelectDate";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    floatLeft: {
      float: "left"
    },
    floatRight: {
      float: "right"
    },
    dateButtonsContainer: {
      paddingTop: spacing.unit
    },
    usersDropDown: {
      width: 184,
      marginTop: 0,
      marginLeft: spacing.unit * 2
    },
    buttonWidth: {
      width: 120
    },
    buttonContainer: {
      paddingRight: 0,
      paddingLeft: spacing.unit / 2
    },
    shareReport: {
      marginRight: 116
    },
    headerInfoContainer: {
      minHeight: 56,
      width: "100%",
      minWidth: 1072
    },
    underLine: {
      color: "rgb(38, 49, 55)"
    }
  });

type StateProps = {
  usageResultList: UsageResultsState;
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchFacilityUserList: (date: Date) => Promise<void>;
  fetchOneUser: (id: string) => Promise<void>;
};

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedMonth: Date;
  isSubmitDisabled: boolean;
  selectedUserId: string | number;
  history: H.History;
  currentPageVersion: number;
  onChangeMonth: (date: Date, user: OptionInterface) => void;
  onChangeUser: (user: OptionInterface) => void;
  onChangeEditMode: () => void;
  resetForm: (nextValues?: InitialDataValues) => void;
  usageResultList: InitialDataValues;
};

type MergeProps = OwnProps &
  DispatchProps & {
    facilityListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）- 月変更 & 利用者変更（通常時）
 */
const UsageResultListHeaderCore = (props: Props): JSX.Element => {
  const { classes } = props;
  const [init, setInit] = React.useState(true);

  React.useEffect((): void => {
    props.fetchFacilityUserList(props.selectedMonth).then((): void => {
      setInit(false);
    });
  }, []);

  React.useEffect((): void => {
    if (props.facilityListOption && props.facilityListOption[0]) {
      props.fetchOneUser(`${props.facilityListOption[0].value}`);
      props.onChangeUser(props.facilityListOption[0]);
    }
  }, [init]);

  const onChangeMonth = (date: Date): void => {
    // 報酬改定のバージョン変更が必要だったら変更後のページを表示
    const transitionDone = checkReportVersion(
      date,
      FacilityType.SHUROTEICHAKU,
      props.currentPageVersion,
      INVOICE_PATHS.reportUsers,
      props.history
    );

    if (!transitionDone) {
      props.fetchFacilityUserList(date).then(() => {
        const selectedUserOption = props.facilityListOption.filter(
          (userOption) => userOption.value === props.selectedUserId
        );
        let selectedUser;
        if (selectedUserOption.length > 0) {
          [selectedUser] = selectedUserOption;
        } else if (props.facilityListOption.length > 0) {
          [selectedUser] = props.facilityListOption;
        } else {
          selectedUser = { label: "", value: 0 };
        }
        props.onChangeMonth(date, selectedUser);
      });
    }
  };

  const onChangeUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const facility = props.facilityListOption.filter(
      (x) => x.value === event.target.value
    );
    if (facility.length) {
      props.fetchOneUser(`${facility[0].value}`);
      props.onChangeUser(facility[0]);
    }
  };

  const onChange = (): void => {
    props.resetForm(props.usageResultList);
    props.onChangeEditMode();
  };

  return (
    <>
      <div className={classes.headerInfoContainer}>
        <div
          className={ClassNames(
            classes.floatLeft,
            classes.dateButtonsContainer
          )}
        >
          <DateSelectButtonsMonthly
            selectedMonth={props.selectedMonth}
            min={props.minDate}
            max={props.maxDate}
            onClickSubmit={onChangeMonth}
            classes={{ underLine: classes.underLine }}
          />
        </div>
        <div className={classes.floatLeft}>
          <DropDown
            id="users-in-facility-list"
            label="利用者"
            isError={false}
            options={props.facilityListOption}
            value={`${props.selectedUserId}`}
            styles={classes.usersDropDown}
            onChange={onChangeUser}
          />
        </div>
        <div className={classes.floatRight}>
          <ContentHeaderSubmit
            buttonName="編集"
            handleSubmit={onChange}
            submitStyle={classes.buttonWidth}
            toolbarStyle={classes.buttonContainer}
            isSubmitDisabled={props.isSubmitDisabled}
          />
        </div>
        <div className={ClassNames(classes.floatRight, classes.shareReport)}>
          <DummySelectDate
            label="支援レポート共有日"
            value={props.usageResultList.ReportData.shareSupportReportDate}
            size="short"
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    usageResultList: state.v202104.SHUROTEICHAKU.report,
    userInFacility: state.v202104.SHUROTEICHAKU.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { v202104 } = dispatches;
  const userInFacilityDispatcher = v202104.SHUROTEICHAKU.userInFacilityDispatcher(
    dispatch
  );
  return {
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date),
    fetchOneUser: (id: string): Promise<void> =>
      userInFacilityDispatcher.fetchOne(id)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const facilityListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    facilityListOption,
    ...ownProps,
    ...dispatchProps
  };
};

export const UsageResultListHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(UsageResultListHeaderCore));
