import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

export type GetFacilityResponse = {
  data: {
    facility: {
      id: number;
      gov_business_owner: string;
      gov_facility_number: string;
      name: string;
      type_service: string;
      responsible_person: string;
      capacity: number | null;
      postal_code: string;
      prefecture_name: string;
      city_id: number;
      address: string;
      tel: string;
      multiple_facility_flg: string;
      users_vs_supporter_grade: string;
      welfare_condition: string;
      welfare_condition_start_date: string;
      welfare_condition_end_date: string;
      better_supporter_condition: string;
      specific_better_supporter_condition: string;
      lack_of_supporter_flg: string;
      date_start_lack_of_supporter: string | null;
      lack_of_service_admin_flg: string;
      date_start_lack_of_service_admin: string | null;
      origin_local_gov_flg: string;
      see_hear_team_flg: string | null;

      total_capacity?: number | null;
      available_food?: string;
      available_pickup?: string;
      available_pickup_kind?: string;
      mon_active_flg?: string;
      tue_active_flg?: string;
      wed_active_flg?: string;
      thu_active_flg?: string;
      fri_active_flg?: string;
      sat_active_flg?: string;
      sun_active_flg?: string;
      mon_open?: string;
      tue_open?: string;
      wed_open?: string;
      thu_open?: string;
      fri_open?: string;
      sat_open?: string;
      sun_open?: string;
      mon_close?: string;
      tue_close?: string;
      wed_close?: string;
      thu_close?: string;
      fri_close?: string;
      sat_close?: string;
      sun_close?: string;
      execute_kaizen_flg?: string;
      better_supporter_condition_base_up_flg?: string;
      ab_support_ikou_flg?: string;
      ab_support_ikou_result?: string;
      ab_support_ikou_result_number?: number;
      master_subordinate_flg?: string | null;
      master_flg?: string | null;
      a_execute_measures_for_load_reduction_flg: string;
      a_execute_measures_for_load_reduction_unit_flg: string | null;
      a_yen_of_load_reduction: number | null;
      a_percent_of_load_reduction: number | null;
      i_fix_rate_get_job: string | null;
      i_train_finished_flg: string;
      ab_support_serious: string;
      mental_disorder_leave_support: string;
      a_wage_up_date_start: string | null;
      a_wage_up_date_end: string | null;
      b_target_kouchin_teacher_flg?: number;
      b_target_kouchin_teacher_date_start: string | null;
      b_target_kouchin_teacher_date_end: string | null;
      work_truncate_minutes: number | null;
      work_start_time: string | null;
      work_end_time: string | null;
      operating_unit_flg?: number;
      a_average_work_duration_202404?: number;
      a_production_activity_202404?: number;
      a_diversified_work_style_202404?: number;
      a_improvement_of_support_202404?: number;
      a_regional_cooperation_activity?: number;
      b_average_monthly_wage_202104?: number | null;
      management_improvement_plan?: number;
      initiatives_to_improve_abilities?: number;
      b_reward_type?: number;
      a_evaluation_contents_undisclosed?: number;
      see_hear_team_202404?: number;
      see_hear_team_202404_from?: string | null;
      see_hear_team_202404_to?: string | null;
      higher_brain_dysfunction_persons_support_flg: number;
      higher_brain_dysfunction_persons_support_from: string | null;
      higher_brain_dysfunction_persons_support_to: string | null;
      work_continuation_not_planning_flg: number;
      work_continuation_not_planning_from: string | null;
      work_continuation_not_planning_to: string | null;
      body_restricted_still_flg: number;
      body_restricted_still_from: string | null;
      body_restricted_still_to: string | null;
      abuse_prevention_measures_not_implemented_flg: number;
      abuse_prevention_measures_not_implemented_from: string | null;
      abuse_prevention_measures_not_implemented_to: string | null;
      specific_better_supporter_condition_202404: string | null;
      information_disclosure_not_reported_flg: number;
      information_disclosure_not_reported_from: string | null;
      information_disclosure_not_reported_to: string | null;
      b_target_kouchin_reached_flg?: number;
      b_target_kouchin_reached_start_date?: string | null;
      b_target_kouchin_reached_end_date?: string | null;
      users_vs_supporter_grade_202404: string;

      improving_infection_control1_flg: number;
      improving_infection_control1_from: string | null;
      improving_infection_control1_to: string | null;
      improving_infection_control2_flg: number;
      improving_infection_control2_from: string | null;
      improving_infection_control2_to: string | null;
    };
    facility_group_home?: {
      facility_type: number;
      ave_users_last_fiscal_year: number;
      def_night_support_flg: number;
      night_shift_supporter_flg: number;
      nursing_supporter_flg: number;
      commuter_support_flg: number;
      subtraction_of_large_scale_housing: number;
      night_support_type_202104: number | null;
      personnel_distribution_type: number | null;
    };
    facility_seikatsukaigo?: {
      facility_type: number;
      satellite_type_etc_flg: number;
      doctor_placement_flg: number;
      open_short_time: number;
      nursing_supporter_202404: number;
      serious_disability: number;
      serious_supporter_flg: number;
      addition_placement_of_service_admin_flg: number;
      addition_placement_start_date: string | null;
      addition_placement_end_date: string | null;
      welfare_condition_combined: string | null;
    };
    facility_shuroteichaku?: {
      rate_get_job_202104: number;
      workhardenes_result_flg: number;
      workplace_adaptation_assistant_flg: number;
    };
    facility_tankinyusho?: {
      facility_type: number;
      medical_type: number;
      fulltime_nursing_staff: number;
      serious_disability_flg: number;
      facility_combi_status: number;
      large_scale_flg: number;
      medical_support_flg: number;
      dietician: number;
      life_support_hub_in_district_flg: number;
    };
    facility_jiritsukunren_seikatsu?: {
      facility_type: number;
      standard_overuse_flg: number;
      nursing_supporter_flg: number;
      short_stay: number;
      support_for_mentally_ill_discharge: number;
    };
    facility_shisetsunyusho?: {
      food_expenses: number;
      food_expenses_breakfast: number;
      food_expenses_lunch: number;
      food_expenses_supper: number;
      food_expenses_day: number;
      utility: number;
      utility_costs: number;
      nutritionist_placement: number;
      nighttime_placement: number;
      nighttime_nurse_count: number;
      serious_disability: number;
      regional_life_transition: number;
      oral_hygiene_management_system_flg: number;
      regional_transition_support_flg: number;
      regional_transition_support_from: string | null;
      regional_transition_support_to: string | null;
      decrease_users_count: number | null;
    };
    facility_kyotakukaigo?: {
      specific_facilities_addition: number;
      life_support_hub_in_district_flg: number;
    };
    facility_judohomonkaigo?: {
      specific_facilities_addition: number;
      life_support_hub_in_district_flg: number;
    };
    facility_dokoengo?: {
      specific_facilities_addition: number;
      life_support_hub_in_district_flg: number;
    };
    facility_kodoengo?: {
      specific_facilities_addition: number;
      life_support_hub_in_district_flg: number;
    };
    facility_chiikiiko?: {
      service_cost_type: number;
      peer_support_flg: number;
      life_support_hub_in_district_flg: number;
      life_support_function_promotion_flg: number;
      life_support_function_promotion_times: number | null;
    };
    facility_chiikiteichaku?: {
      peer_support_flg: number;
      life_support_hub_in_district_flg: number;
      life_support_function_promotion_flg: number;
      life_support_function_promotion_times: number | null;
    };
    facility_keikakusodan?: {
      facility_type: number; // 施設区分
      integrated_management_handicapped_child_flg: number; // 施設（計画相談）：指定障害児相談支援事業所と一体的に運営フラグ
      facilities_keikakusodan_shogaijisodan: {
        target_type: number; // 施設区分
        gov_facility_number: string; // 事業所番号
        name: string; // 事業所名
        tel: string; // 施設の電話番号
        responsible_person: string; // 代表者職員氏名
        function_enhanced_system: number; // 施設（計画相談）：相談支援機能強化型体制
        need_medical_care_handicapped_child_support_202404: number; // 施設（計画相談）：要医療障害児支援体制フラグ（R6年度報酬改定で追加）
        peer_support_flg: number; // 施設（計画相談）：ピアサポート体制フラグ
        life_support_hub_in_district_flg: number; // 施設（計画相談）：地域生活支援拠点等フラグ
        life_support_function_promotion_flg: number; // 地域生活支援拠点等機能強化加算
        life_support_function_promotion_times: number | null; // 地域生活支援拠点等機能強化加算（算定回数）
        information_disclosure_not_reported_flg?: number;
        information_disclosure_not_reported_from?: string | null;
        information_disclosure_not_reported_to?: string | null;
        abuse_prevention_measures_not_implemented_flg?: number; // 虐待防止措置未実施減算フラグ
        abuse_prevention_measures_not_implemented_from?: string | null; // 虐待防止措置未実施減算開始日
        abuse_prevention_measures_not_implemented_to?: string | null; // 虐待防止措置未実施減算終了日
        work_continuation_not_planning_flg?: number; // 業務継続計画未策定フラグ
        work_continuation_not_planning_from?: string | null; // 業務継続計画未策定開始日
        work_continuation_not_planning_to?: string | null; // 業務継続計画未策定終了日
      }[];
    };
    users: {
      id: number;
      recipient_number: string;
      name_sei: string;
      name_mei: string;
      def_record_work: string;
    }[];
    workBreakTimes: {
      id: number;
      facility_id: number;
      start_time: string;
      end_time: string;
    }[];
    workBreakTimeItems: {
      id: number;
      facility_id: number;
      work_time_item_id: number;
      work_break_time_id: number;
      start_time: string;
      end_time: string;
      applied: number;
    }[];
    workTimeItems: {
      id: number;
      facility_id: number;
      start_time: string | null;
      end_time: string | null;
      day_of_the_week: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
    }[];
    defaultStatusSettings: {
      id: number;
      status: number;
      start_time: string;
      end_time: string;
      def_food: string;
      def_pickup: string;
      pickup_premises: string;
      time_card_display_flg: number;
    }[];
    // GH,施設入所のとき
    units?: {
      id: number | null;
      unit_name: string;
      night_support_type_202104: number | null;
      ave_users_last_fiscal_year: number;
      subtraction_of_large_scale_housing_type?: number;
      is_deleted: number;
      same_building_subtraction_flg?: number;
      results_use_flg?: number;
    }[];
  };
};

/**
 * 事業所情報を取得する
 */
export const getFacility = async (): Promise<
  AxiosResponse<GetFacilityResponse>
> => {
  const url = `${VERSION_URL_202404}/facility`;
  return request.get<GetFacilityResponse>(url);
};

export default getFacility;
