import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202104 } from "@config";

export type PostFacilityParams = {
  facility: {
    gov_business_owner: string;
    gov_facility_number?: string;
    name?: string;
    type_service: string;
    responsible_person?: string;
    capacity: number | null;
    postal_code: string;
    prefecture_name: string;
    city_id: number;
    address: string;
    tel?: string;
    multiple_facility_flg?: string;
    users_vs_supporter_grade: string;
    welfare_condition: string;
    welfare_condition_start_date: string | null;
    welfare_condition_end_date: string | null;
    better_supporter_condition: string;
    lack_of_supporter_flg: string;
    date_start_lack_of_supporter: string | null;
    lack_of_service_admin_flg: string;
    date_start_lack_of_service_admin: string | null;
    origin_local_gov_flg: string;
    see_hear_team_flg: string | null;

    total_capacity?: number | null;
    available_food?: string;
    available_pickup?: string;
    available_pickup_kind?: string | null;
    mon_active_flg?: string;
    tue_active_flg?: string;
    wed_active_flg?: string;
    thu_active_flg?: string;
    fri_active_flg?: string;
    sat_active_flg?: string;
    sun_active_flg?: string;
    mon_open?: string | null;
    tue_open?: string | null;
    wed_open?: string | null;
    thu_open?: string | null;
    fri_open?: string | null;
    sat_open?: string | null;
    sun_open?: string | null;
    mon_close?: string | null;
    tue_close?: string | null;
    wed_close?: string | null;
    thu_close?: string | null;
    fri_close?: string | null;
    sat_close?: string | null;
    sun_close?: string | null;
    execute_kaizen_flg?: string;
    better_supporter_condition_base_up_flg?: string;
    specific_better_supporter_condition?: string;
    ab_support_ikou_flg?: string;
    ab_support_ikou_result?: string | null;
    ab_support_ikou_result_number?: number | null;
    master_subordinate_flg?: string | null;
    master_flg?: string | null;

    a_execute_measures_for_load_reduction_flg?: string;
    a_yen_of_load_reduction?: number | null;
    a_percent_of_load_reduction?: number | null;
    a_execute_measures_for_load_reduction_unit_flg?: string | null;
    i_fix_rate_get_job?: string | null;
    i_train_finished_flg?: string | null;
    ab_support_serious?: string;
    mental_disorder_leave_support?: string;
    a_wage_up_date_start?: string | null;
    a_wage_up_date_end?: string | null;
    b_target_kouchin_teacher_date_start?: string | null;
    b_target_kouchin_teacher_date_end?: string | null;
    work_truncate_minutes?: number | null;
    work_start_time?: string | null;
    work_end_time?: string | null;
    operating_unit_flg?: number;
    a_average_work_duration_202104?: number;
    a_production_activity?: number;
    a_diversified_work_style?: number;
    a_improvement_of_support?: number;
    a_regional_cooperation_activity?: number;
    b_average_monthly_wage_202104?: number | null;
    b_reward_type?: number;
    a_evaluation_contents_undisclosed?: number;
  };
  facility_group_home?: {
    facility_type: number;
    ave_users_last_fiscal_year: number;
    def_night_support_flg: number;
    night_shift_supporter_flg: number;
    nursing_supporter_flg: number;
    commuter_support_flg: number;
    subtraction_of_large_scale_housing: number;
    night_support_type_202104: number | null;
  };
  facility_seikatsukaigo?: Partial<{
    facility_type: number;
    satellite_type_etc_flg: number;
    doctor_placement_flg: number;
    open_short_time: number;
    nursing_supporter_202104: number;
    serious_disability: number;
    serious_supporter_flg: number;
    addition_placement_of_service_admin_flg: number;
    addition_placement_start_date: string | null;
    addition_placement_end_date: string | null;
  }>;
  facility_shuroteichaku?: Partial<{
    number_of_users: number;
    rate_get_job_202104: number;
    workhardenes_result_flg: number;
    workplace_adaptation_assistant_flg: number;
  }>;
  facility_tankinyusho?: Partial<{
    facility_type: number;
    medical_type: number;
    fulltime_nursing_staff: number;
    serious_disability_flg: number;
    facility_combi_status: number;
    large_scale_flg: number;
    medical_support_flg: number;
    dietician: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_jiritsukunren_seikatsu?: Partial<{
    facility_type: number;
    standard_overuse_flg: number;
    nursing_supporter_flg: number;
    short_stay: number;
    support_for_mentally_ill_discharge: number;
  }>;
  facility_shisetsunyusho?: Partial<{
    food_expenses: number;
    food_expenses_breakfast: number;
    food_expenses_lunch: number;
    food_expenses_supper: number;
    food_expenses_day: number;
    utility: number;
    utility_costs: number;
    nutritionist_placement: number;
    nighttime_placement: number;
    serious_disability: number;
    regional_life_transition: number;
    oral_hygiene_management_system_flg: number;
  }>;
  facility_kyotakukaigo?: Partial<{
    specific_facilities_addition: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_judohomonkaigo?: Partial<{
    specific_facilities_addition: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_dokoengo?: Partial<{
    specific_facilities_addition: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_kodoengo?: Partial<{
    specific_facilities_addition: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_chiikiiko?: Partial<{
    service_cost_type: number;
    peer_support_flg: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_chiikiteichaku?: Partial<{
    peer_support_flg: number;
    life_support_hub_in_district_flg: number;
  }>;
  facility_keikakusodan?: Partial<{
    integrated_management_handicapped_child_flg: number; // 施設（計画相談）：指定障害児相談支援事業所と一体的に運営フラグ
    facilities_keikakusodan_shogaijisodan: Partial<{
      target_type: number; // 施設区分
      gov_facility_number: string; // 事業所番号
      name: string; // 事業所名
      tel: string; // 施設の電話番号
      responsible_person: string; // 代表者職員氏名
      function_enhanced_system: number; // 施設（計画相談）：相談支援機能強化型体制
      behavioral_disorder_support_flg: number; // 施設（計画相談）：行動障害支援体制フラグ
      need_medical_care_handicapped_child_support_flg: number; // 施設（計画相談）：要医療障害児支援体制フラグ
      mental_disorder_support_flg: number; // 施設（計画相談）：精神障害者支援体制フラグ
      peer_support_flg: number; // 施設（計画相談）：ピアサポート体制フラグ
      life_support_hub_in_district_flg: number; // 施設（計画相談）：地域生活支援拠点等フラグ
    }>[];
  }>;
  users?: {
    id: number;
    recipient_number: string;
    name_sei: string;
    name_mei: string;
    def_record_work: string;
  }[];
  workBreakTimes?: {
    id: number | string;
    facility_id: number;
    start_time: string;
    end_time: string;
  }[];
  workBreakTimeItems?: {
    id: number | null;
    facility_id: number;
    work_time_item_id: number | string;
    work_break_time_id: number | string;
    start_time: string;
    end_time: string;
    applied: number;
  }[];
  workTimeItems?: {
    id: number | string;
    facility_id: number;
    start_time: string;
    end_time: string;
    day_of_the_week: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
  }[];
  default_status_settings?: {
    id: number;
    status: number;
    start_time: string;
    end_time: string;
    def_food: string;
    def_pickup: string;
    pickup_premises: string;
    time_card_display_flg: number;
  }[];
  // GH,施設入所のとき
  units?: {
    id: number | null;
    unit_name: string;
    night_support_type_202104: number | null;
    ave_users_last_fiscal_year: number;
    subtraction_of_large_scale_housing_type?: number;
    is_deleted: number;
    same_building_subtraction_flg?: number;
    results_use_flg?: number;
  }[];
};

/**
 * 事業所情報を更新する
 * @param values PostFacilityParams
 */
export const postFacility = async (
  values: PostFacilityParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202104}/facility`;
  return request.post(url, values);
};

export default postFacility;
