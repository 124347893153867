import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetUsersSummary = BaseResponse<{
  users: {
    uif_id: number;
    name: string;
    type_consultation?: number; // 計画相談のみ
    support: {
      support_record: {
        support_records_id: number;
        target_date: string;
        support_content: string | null;
        staff_comment: string | null;
        staffs:
          | {
              id: number;
              staff_name: string;
            }[]
          | null;
      };
      inout_result: {
        inout_results_id: number;
        status: number;
      } | null;
    }[];
  }[];
}>;

/**
 * 指定された利用者分の支援記録のデータを取得する
 * @param year 年
 * @param month 月(MM)
 * @param excludedUserIds 指定された事業所所属ユーザーのID
 */
export const getUsersSummary = async (
  year: string,
  month: string,
  excludedUserIds?: string
): Promise<AxiosResponse<GetUsersSummary>> => {
  const url = `${VERSION_URL_201910}/supports/users/records/${year}/${month}`;
  const query = excludedUserIds ? `?excluded_user_ids=${excludedUserIds}` : "";
  return request.get<GetUsersSummary>(url + query);
};
