import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// ui
import { FieldItem } from "@interfaces/ui/form";
import SectionTitle from "@components/atoms/SectionTitle";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import FormPaper from "@components/atoms/FormPaper";
import { ServiceDeliveryTimeContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryTimeContainer";
import { ServiceDeliveryDetailContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailContainer";
import { ServiceDeliveryDestinationContainer } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDestinationContainer";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
// formik
import { FormikProps } from "formik";
import {
  FacilityType,
  NUMBER_OF_PRACTITIONER_STR,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
// utils
import { isDisplayService } from "@utils/domain/serviceDelivery/isDisplayService";
import { IDOSHIEN_STATUS_LIST } from "@constants/mgr/IDOSHIEN/variables";

const styles = (): StyleRules =>
  createStyles({
    firstSection: {
      marginTop: "32px"
    },
    section: {
      marginTop: "48px"
    },
    hideIcon: {
      "&::before": {
        content: "''",
        display: "inline-block",
        borderTop: "1px solid #0277bd",
        borderLeft: "1px solid #0277bd",
        transform: "rotate(45deg)",
        width: 6,
        height: 6,
        marginRight: 10
      }
    },
    showIcon: {
      "&::before": {
        content: "''",
        display: "inline-block",
        borderBottom: "1px solid #0277bd",
        borderRight: "1px solid #0277bd",
        transform: "rotate(45deg)",
        width: 6,
        height: 6,
        marginRight: 10,
        marginBottom: 2
      }
    }
  });

type Props = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  customRecords: CustomRecordsWithCategoryState;
  practitionerNum: number;
  numberOfPractitioner: number;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  staffOptions: FieldItem[];
  facilityType: FacilityType;
} & WithStyles<typeof styles>;

export const ServiceDeliveryDetailFieldCore = (props: Props): JSX.Element => {
  const { classes, practitionerNum } = props;
  const { values: formikValues, setFieldValue } = props.formikProps;

  const practitionerValues =
    practitionerNum === 1
      ? formikValues.serviceDeliveryRecordPractitioners1
      : formikValues.serviceDeliveryRecordPractitioners2;

  const [showUserInfo, setShowUserInfo] = React.useState<boolean>(
    practitionerValues.displayFlg
  );

  const toggleUserInfo = (): void => {
    setFieldValue(
      `serviceDeliveryRecordPractitioners${practitionerNum}.displayFlg`,
      !showUserInfo
    );
    setShowUserInfo((isShowUserInfo) => !isShowUserInfo);
  };
  // エラー時の表示・非表示の切り替え対応
  React.useEffect(() => {
    setShowUserInfo(practitionerValues.displayFlg);
  }, [practitionerValues.displayFlg]);

  const titleNum = practitionerNum.toString();

  const isGroupStatus =
    props.formikProps.values.status === IDOSHIEN_STATUS_LIST.GROUP.value &&
    props.facilityType === FacilityType.IDOSHIEN;

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle
          label={
            formikValues.numberOfPractitioner ===
              NUMBER_OF_PRACTITIONER_STR.one || isGroupStatus
              ? "サービス提供記録"
              : `サービス提供記録 ${titleNum}`
          }
        />
      </div>
      <ServiceDeliveryTimeContainer
        formikProps={props.formikProps}
        setFormikFieldValue={props.formikProps.setFieldValue}
        practitionerNum={practitionerNum}
        practitionerValues={practitionerValues}
        staffOptions={props.staffOptions}
        facilityType={props.facilityType}
        isMobile={false}
      />
      <KnowbeButton kind="text" onClick={toggleUserInfo}>
        <span className={showUserInfo ? classes.hideIcon : classes.showIcon}>
          記録を
          <span>{showUserInfo ? "非表示にする" : "表示する"}</span>
        </span>
      </KnowbeButton>
      {showUserInfo ? (
        <div style={{ marginTop: -12 }}>
          {SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
            props.facilityType
          ) && (
            <div className={classes.section}>
              <GrayMiddleHeading text="行き先" />
              <ServiceDeliveryDestinationContainer
                isMobile={false}
                formikProps={props.formikProps}
                setFormikFieldValue={props.formikProps.setFieldValue}
                practitionerNum={practitionerNum}
                facilityType={props.facilityType}
              />
            </div>
          )}
          {props.customRecords
            .filter((record) => {
              if (props.facilityType === FacilityType.KYOTAKUKAIGO) {
                return isDisplayService(
                  props.formikProps.values.status,
                  record.category_type
                );
              }
              return true;
            })
            .sort((a, b) => a.category_type - b.category_type)
            .map((item) => {
              return (
                <div key={item.category_type} className={classes.section}>
                  <GrayMiddleHeading text={item.name} />
                  <ServiceDeliveryDetailContainer
                    isMobile={false}
                    formikProps={props.formikProps}
                    setFormikFieldValue={props.formikProps.setFieldValue}
                    customRecords={props.customRecords}
                    categoryType={item.category_type}
                    practitionerNum={practitionerNum}
                    facilityType={props.facilityType}
                    detailRecords={props.detailRecords}
                  />
                </div>
              );
            })}
        </div>
      ) : null}
    </FormPaper>
  );
};

export const ServiceDeliveryDetailField = withStyles(styles)(
  ServiceDeliveryDetailFieldCore
);
