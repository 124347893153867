import { BasicValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/users/basic";
import { ServiceUseValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/users/recipientCertificate";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { DEFAULT_SELECT_VALUE, Checkbox } from "@constants/variables";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues;

// FlgがCheckbox.ONの場合はtrueを返す
const determineTureOrFalse = (value: Checkbox | undefined): boolean => {
  if (value === Checkbox.ON) {
    return true;
  }
  return false;
};

export const initialValues = (
  state?: UsersInFacilityState["user"]
): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const CHIIKITEICHAKU =
    state && state.user_in_facility_chiikiteichaku
      ? state.user_in_facility_chiikiteichaku
      : {};
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTureOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTureOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTureOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTureOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTureOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTureOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTureOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTureOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianName: undefinedToReturnValue(userInFacility.guardian_name),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      disabilityClass: undefinedToReturnValue(
        CHIIKITEICHAKU.disability_class,
        0
      ),
      specialAreaFlag: numberToBoolean(CHIIKITEICHAKU.special_area_flg),
      specialAreaStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(CHIIKITEICHAKU.date_begin_special_area)
        )
      ),
      specialAreaEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(CHIIKITEICHAKU.date_end_special_area)
        )
      )
    },
    recipientCertificate: {
      careSupportAuthFlag: determineTureOrFalse(
        userInFacility.care_support_auth_flg
      ),
      careSupportAuthStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_auth)
        )
      ),
      careSupportAuthEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_auth)
        )
      ),
      careSupportPaymentFlag: determineTureOrFalse(
        userInFacility.care_support_payment_flg
      ),
      careSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_payment)
        )
      ),
      careSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_payment)
        )
      ),
      planSupportPaymentFlag: determineTureOrFalse(
        userInFacility.plan_support_payment_flg
      ),
      planSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_payment)
        )
      ),
      planSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_payment)
        )
      ),
      planSupportMonitorFlag: determineTureOrFalse(
        userInFacility.plan_support_monitor_flg
      ),
      planSupportMonitorStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_monitor)
        )
      ),
      planSupportMonitorEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_monitor)
        )
      )
    }
  };
};
