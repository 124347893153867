import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import { ReportDaily } from "@components/pages/mgr/JUDOHOMONKAIGO/report/Daily";
import { ReportDaily202104 } from "@components/v202104/pages/mgr/JUDOHOMONKAIGO/report/Daily";
import { ReportMonthly } from "@components/pages/mgr/JUDOHOMONKAIGO/report/Monthly";
import { ReportMonthly202104 } from "@components/v202104/pages/mgr/JUDOHOMONKAIGO/report/Monthly";

// 記録
import { ServiceDeliveryDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryDaily";
import { ServiceDeliveryMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryMonthly";
import { ServiceDeliveryNewDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewDaily";
import { ServiceDeliveryNewMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryNewMonthly";
import { ServiceDeliveryEditDaily } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import { ServiceDeliveryEditMonthly } from "@components/pages/record/serviceDelivery/ServiceDeliveryEditMonthly";
import { ServiceDeliveryPrint } from "@components/pages/record/print/ServiceDeliveryPrint";
import { SupportPlanUsersSummary } from "@components/pages/record/supportPlan/SupportPlanUsersSummary";
import { AssessmentList } from "@components/pages/record/assessment/AssessmentList";
import { Assessment } from "@components/pages/record/assessment/Assessment";
import { AssessmentRecordPrint } from "@components/pages/record/print/AssessmentPrint";

// 重度訪問介護計画
import { CarePlanList } from "@components/pages/record/carePlan/CarePlanList";
import { CarePlanEdit } from "@components/pages/record/carePlan/CarePlanEdit";
import { CarePlanCreate } from "@components/pages/record/carePlan/CarePlanCreate";
import { CarePlanPrint } from "@components/pages/record/carePlan/CarePlanPrint";

// 利用者情報
import { UsersList } from "@components/pages/mgr/JUDOHOMONKAIGO/Users/UsersList";
import { CreateUser } from "@components/pages/mgr/JUDOHOMONKAIGO/Users/CreateUser";
import { EditUser } from "@components/pages/mgr/JUDOHOMONKAIGO/Users/EditUser";
import { EditUserV202104 } from "@components/v202104/pages/mgr/JUDOHOMONKAIGO/Users/EditUser";

// 事業所情報
import { Facility } from "@components/pages/mgr/JUDOHOMONKAIGO/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/JUDOHOMONKAIGO/Facility";

// 職員情報
import { Staffs } from "@components/pages/mgr/JUDOHOMONKAIGO/Staffs";

// 記録の項目設定
import { RecordSettingWithCategory } from "@components/pages/record/setting/RecordSettingWithCategory";

// アセスメントの設定
import { AssessmentSetting } from "@components/pages/assessment/setting/AssessmentSetting";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 重度訪問介護施設でログインした時だけ有効になるルーティング
 */
export const JUDOHOMONKAIGORoutes = ({ user }: Props): JSX.Element => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT_DAILY} component={ReportDaily} />
    <AdminRoute path={URL.REPORT_DAILY_202104} component={ReportDaily202104} />
    <AdminRoute path={URL.REPORT_USERS} component={ReportMonthly} />
    <AdminRoute
      path={URL.REPORT_USERS_202104}
      component={ReportMonthly202104}
    />

    {/* 記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/list/:date?"
      component={ServiceDeliveryDaily}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/list/:uifId/:date"
      component={ServiceDeliveryMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/new"
      component={ServiceDeliveryNewDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/:uifId/service_delivery/new"
      component={ServiceDeliveryNewMonthly}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:uifId"
      component={ServiceDeliveryEditDaily}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:uifId/monthly"
      component={ServiceDeliveryEditMonthly}
    />
    {/* 印刷・サービス提供記録 */}
    <AdminRoute
      exact
      path="/record/service_delivery/daily/print"
      component={ServiceDeliveryPrint}
    />
    <AdminRoute
      exact
      path="/record/service_delivery/monthly/print"
      component={ServiceDeliveryPrint}
    />
    {/* 記録 */}
    <AdminRoute
      exact
      path={URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY}
      component={SupportPlanUsersSummary}
    />
    {/* アセスメントシート */}
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment"
      component={AssessmentList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:copyId/copy"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId"
      component={Assessment}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:uifId/assessment/:assessmentId/print"
      component={AssessmentRecordPrint}
    />
    {/* 重度訪問計画 */}
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan"
      component={CarePlanList}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/new"
      component={CarePlanCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId/copy"
      component={CarePlanCreate}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId"
      component={CarePlanEdit}
    />
    <AdminRoute
      exact
      path="/record/support_plan/:id/care_plan/:carePlanId/print"
      component={CarePlanPrint}
    />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUserV202104} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY} component={Facility} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    {/* 職員情報 */}
    <AdminRoute key="Staffs" path="/staffs" exact component={Staffs} />
    {/* 記録の項目設定 */}
    <AdminRoute
      key="RecordSetting"
      path={URL.RECORD_SETTING}
      exact
      component={RecordSettingWithCategory}
    />
    {/* アセスメントの設定 */}
    <AdminRoute
      key="AssessmentSetting"
      path={URL.SETTING_ASSESSMENT}
      exact
      component={AssessmentSetting}
    />
    {/* default path */}
    <Route path="/">
      <Redirect
        to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT_DAILY}
      />
    </Route>
  </Switch>
);
