import { InitialDataValues } from "./initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import { InitialErrors } from "@interfaces/mgr/TANKINYUSHO/report/initial";
import { INTENSIVE_SUPPORT_FLG } from "@constants/mgr/TANKINYUSHO/variables";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";

type InitialDataErrors = InitialErrors;

export const submitValidation = (
  validationResult?: InitialDataErrors
): boolean => {
  if (validationResult !== undefined) {
    return !(validationResult.initial.remarks === undefined);
  }
  return false;
};

const initialValidation = (values: InitialDataValues): InitialErrors => {
  return {
    initial: {
      remarks: validator(values.initial.remarks, {
        type: "checkCharacterLength",
        length: 50
      }),
      intensiveSupportStartDate: validateSwitcher(
        values.initial.intensiveSupportFlg !== INTENSIVE_SUPPORT_FLG[0].value &&
          values.initial.statusType,
        dateValidator(
          notSelectedToEmpty(values.initial.intensiveSupportStartDate),
          "required"
        )
      )
    }
  };
};

const validation = (values: InitialDataValues): InitialDataErrors => {
  return { ...initialValidation(values) };
};

export default validation;
