import {
  ReportState,
  InoutConsultationResultsState,
  FacilitiesKEIKAKUSODANMonthlyType
} from "@stores/domain/mgr/KEIKAKUSODAN/report/types";
import { GetInOutConsultationResultsDailyResponse } from "@api/requests/inOutConsultationResults/getInOutConsultationResultsDaily";
import { GetInOutConsultationResultsUsersResponse } from "@api/requests/inOutConsultationResults/getInOutConsultationResultsMonthly";
import { RequestParamToPostAPI } from "@api/requests/inOutConsultationResults/postInOutConsultationResults";
import { postFacilitiesMonthlyParams } from "@api/requests/inOutConsultationResults/postFacilityMonthly";
import { InitialValues } from "@interfaces/mgr/KEIKAKUSODAN/report/initial";
import {
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API,
  DEFAULT_CHECKBOX_VALUE,
  Checkbox
} from "@constants/variables";
import { dateToLocalisedString } from "@utils/date";
import castNumber from "@utils/dataNormalizer/castNumber";
import stringToNumber from "@utils/dataNormalizer/stringToNumber";
import { cloneDeep } from "lodash-es";
import { FacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST,
  NEED_NURSING_CARE_CLASS_LIST,
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  SUPPORT_REQUIRED_CLASS_LIST,
  TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST,
  TRAINING_BY_CHIEF_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";

type InitBaseInfo = {
  uifId: number;
  sei: string;
  mei: string;
  date: string;
};

// statusによる不要なデータのstoreKey一覧（計画相談）
const defaultDataByStatus = (statusValue: string): string[] => {
  switch (statusValue) {
    case STATUS_LIST[0].value:
      return [
        "same_inhome_nursing_care_support_flg",
        "same_preventive_care_support_flg",
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "medical_daycare_education_relation1_flg",
        "medical_daycare_education_relation2_flg",
        "medical_daycare_education_relation3_flg",
        "service_personnel_meeting_held_flg",
        "service_provision_monitoring_flg",
        "hospitalization_relation_addition",
        "life_support_promotion_flg",
        "system_promotion_flg",
        "intensive_support1_addition_flg",
        "intensive_support2_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "inhome_nursing_care_support_office_relation1_flg",
        "inhome_nursing_care_support_office_relation2_flg",
        "inhome_nursing_care_support_office_relation3_flg",
        "inhome_nursing_care_support_office_relation4_flg",
        "inhome_nursing_care_support_office_relation5_flg",
        "inhome_nursing_care_support_office_relation6_flg",
        "mental_disorder_support",
        "behavioral_disorder_support",
        "higher_brain_dysfunction_support",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_medical_daycare_education1_flg",
        "remote_location_medical_daycare_education2_flg",
        "remote_location_hospitalization_relation_flg",
        "remote_location_inhome_nursing2_flg",
        "remote_location_inhome_nursing5_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    case STATUS_LIST[1].value:
      return [
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "medical_daycare_education_relation1_flg",
        "medical_daycare_education_relation2_flg",
        "medical_daycare_education_relation3_flg",
        "service_personnel_meeting_held_flg",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_medical_daycare_education1_flg",
        "remote_location_medical_daycare_education2_flg"
      ];
    case STATUS_LIST[2].value:
      return [
        "service_personnel_meeting_held_flg",
        "intensive_support1_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "inhome_nursing_care_support_office_relation2_flg",
        "inhome_nursing_care_support_office_relation3_flg",
        "inhome_nursing_care_support_office_relation5_flg",
        "inhome_nursing_care_support_office_relation6_flg",
        "remote_location_inhome_nursing2_flg",
        "remote_location_inhome_nursing5_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    case STATUS_LIST[3].value:
      return [
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "intensive_support1_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "inhome_nursing_care_support_office_relation2_flg",
        "inhome_nursing_care_support_office_relation3_flg",
        "inhome_nursing_care_support_office_relation5_flg",
        "inhome_nursing_care_support_office_relation6_flg",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_inhome_nursing2_flg",
        "remote_location_inhome_nursing5_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    default:
      return [];
  }
};

// statusによる不要なデータのstoreKey一覧（障害児相談）
const defaultDataByStatusForShogaiji = (statusValue: string): string[] => {
  switch (statusValue) {
    case STATUS_LIST_SHOGAIJISODAN[0].value:
      return [
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "medical_daycare_education_relation1_flg",
        "medical_daycare_education_relation2_flg",
        "medical_daycare_education_relation3_flg",
        "service_personnel_meeting_held_flg",
        "service_provision_monitoring_flg",
        "hospitalization_relation_addition",
        "life_support_promotion_flg",
        "system_promotion_flg",
        "intensive_support1_addition_flg",
        "intensive_support2_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "childcare_transition_support1_flg",
        "childcare_transition_support2_flg",
        "childcare_transition_support3_flg",
        "mental_disorder_support",
        "behavioral_disorder_support",
        "higher_brain_dysfunction_support",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_medical_daycare_education1_flg",
        "remote_location_medical_daycare_education2_flg",
        "remote_location_hospitalization_relation_flg",
        "remote_location_childcare_transition_support2_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    case STATUS_LIST_SHOGAIJISODAN[1].value:
      return [
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "medical_daycare_education_relation1_flg",
        "medical_daycare_education_relation2_flg",
        "medical_daycare_education_relation3_flg",
        "service_personnel_meeting_held_flg",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_medical_daycare_education1_flg",
        "remote_location_medical_daycare_education2_flg"
      ];
    case STATUS_LIST_SHOGAIJISODAN[2].value:
      return [
        "service_personnel_meeting_held_flg",
        "intensive_support1_addition_flg",
        "intensive_support2_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "childcare_transition_support2_flg",
        "childcare_transition_support3_flg",
        "remote_location_childcare_transition_support2_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    case STATUS_LIST_SHOGAIJISODAN[3].value:
      return [
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "intensive_support1_addition_flg",
        "intensive_support2_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "childcare_transition_support2_flg",
        "childcare_transition_support3_flg",
        "remote_location_first_addition_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_childcare_transition_support2_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg"
      ];
    default:
      return [];
  }
};

const defaultDataByUserInFacility = (
  classifyHandicappedFlg?: Checkbox,
  needNursingCareClass?: number,
  supportRequiredClass?: number
): string[] => {
  const defaultList = [];
  const needNursingCare = needNursingCareClass
    ? needNursingCareClass.toString()
    : "0";
  const supportRequired = supportRequiredClass
    ? supportRequiredClass.toString()
    : "0";
  if (classifyHandicappedFlg === Checkbox.ON) {
    defaultList.push(
      "same_inhome_nursing_care_support_flg",
      "same_preventive_care_support_flg"
    );
  }
  if (needNursingCare === NEED_NURSING_CARE_CLASS_LIST[0].value) {
    defaultList.push("same_inhome_nursing_care_support_flg");
  }
  if (supportRequired === SUPPORT_REQUIRED_CLASS_LIST[0].value) {
    defaultList.push("same_preventive_care_support_flg");
  }
  return defaultList;
};

const defaultDataByFacility = (
  lifeSupportHubInDistrictFlg: boolean
): string[] => {
  if (!lifeSupportHubInDistrictFlg) {
    return ["life_support_promotion_flg"];
  }
  return [];
};

const defaultData = (
  after: RequestParamToPostAPI,
  statusValue: string
): string[] => {
  const defaultList = [];
  const isOnlyIntensiveSupport3AdditionEnabled =
    after.params.intensive_support1_addition_flg === INT_FALSE_FROM_API &&
    after.params.intensive_support2_addition_flg === INT_FALSE_FROM_API &&
    after.params.intensive_support3_addition_flg === INT_TRUE_FROM_API &&
    after.params.intensive_support4_addition_flg === INT_FALSE_FROM_API &&
    after.params.intensive_support5_addition_flg === INT_FALSE_FROM_API;

  // 種別共通の非活性項目判定処理
  if (statusValue === STATUS_LIST[2].value) {
    if (
      after.params.discharge_addition_flg === INT_TRUE_FROM_API ||
      after.params.medical_daycare_education_relation1_flg === INT_TRUE_FROM_API
    ) {
      defaultList.push(
        "first_addition_flg",
        "first_addition_span",
        "remote_location_first_addition_flg"
      );
    }

    if (
      after.params.first_addition_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support1_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support2_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support3_flg === INT_TRUE_FROM_API ||
      isOnlyIntensiveSupport3AdditionEnabled
    ) {
      defaultList.push(
        "discharge_addition_flg",
        "remote_location_discharge_addition_flg"
      );
    }

    if (after.params.first_addition_flg === INT_TRUE_FROM_API) {
      defaultList.push(
        "medical_daycare_education_relation1_flg",
        "remote_location_intensive_support1_flg"
      );
    }

    if (
      after.params.first_addition_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support1_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support2_flg === INT_TRUE_FROM_API ||
      after.params.childcare_transition_support3_flg === INT_TRUE_FROM_API ||
      isOnlyIntensiveSupport3AdditionEnabled
    ) {
      defaultList.push(
        "discharge_addition_flg",
        "remote_location_discharge_addition_flg"
      );
    }
  }

  // 種別ごとの非活性項目判定処理
  if (after.params.type_consultation === FACILITY_TYPE_KEIKAKUSODAN) {
    if (
      after.params.inhome_nursing_care_support_office_relation1_flg ===
        INT_TRUE_FROM_API ||
      after.params.inhome_nursing_care_support_office_relation3_flg ===
        INT_TRUE_FROM_API ||
      after.params.inhome_nursing_care_support_office_relation4_flg ===
        INT_TRUE_FROM_API ||
      after.params.inhome_nursing_care_support_office_relation6_flg ===
        INT_TRUE_FROM_API
    ) {
      defaultList.push(
        "hospitalization_relation_addition",
        "remote_location_hospitalization_relation_flg"
      );
    }

    // サービス内容ごとの非活性項目判定処理
    switch (statusValue) {
      case STATUS_LIST[1].value:
        if (
          after.params.discharge_addition_flg === INT_TRUE_FROM_API ||
          (after.params.hospitalization_relation_addition &&
            after.params.hospitalization_relation_addition.toString() ===
              HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[1].value)
        ) {
          defaultList.push("intensive_support3_addition_flg");
        }

        if (
          after.params.first_addition_flg === INT_TRUE_FROM_API ||
          (after.params.hospitalization_relation_addition &&
            after.params.hospitalization_relation_addition.toString() !==
              HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[0].value)
        ) {
          defaultList.push(
            "inhome_nursing_care_support_office_relation1_flg",
            "inhome_nursing_care_support_office_relation4_flg"
          );
        }

        if (after.params.first_addition_flg === INT_TRUE_FROM_API) {
          defaultList.push(
            "inhome_nursing_care_support_office_relation2_flg",
            "inhome_nursing_care_support_office_relation5_flg",
            "remote_location_inhome_nursing2_flg",
            "remote_location_inhome_nursing5_flg"
          );
        }

        if (
          after.params.first_addition_flg === INT_TRUE_FROM_API ||
          (after.params.hospitalization_relation_addition &&
            after.params.hospitalization_relation_addition.toString() !==
              HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[0].value) ||
          after.params.discharge_addition_flg === INT_TRUE_FROM_API
        ) {
          defaultList.push(
            "inhome_nursing_care_support_office_relation3_flg",
            "inhome_nursing_care_support_office_relation6_flg"
          );
        }
        break;

      default:
    }
  } else if (after.params.type_consultation === FACILITY_TYPE_SHOGAIJISODAN) {
    if (
      after.params.hospitalization_relation_addition &&
      after.params.hospitalization_relation_addition.toString() ===
        HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[1].value &&
      ((after.params.childcare_transition_support1_flg === INT_TRUE_FROM_API &&
        after.params.childcare_transition_support2_flg === INT_FALSE_FROM_API &&
        after.params.childcare_transition_support3_flg === INT_TRUE_FROM_API) ||
        isOnlyIntensiveSupport3AdditionEnabled)
    ) {
      // 入院時情報連携加算Ⅰの非活性条件
      // 「保育・教育等移行支援加算」が①③の場合 or 「集中支援加算」が③の場合
      defaultList.push(
        "hospitalization_relation_addition",
        "remote_location_hospitalization_relation_flg"
      );
    }

    if (
      after.params.hospitalization_relation_addition &&
      after.params.hospitalization_relation_addition.toString() ===
        HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[2].value &&
      after.params.childcare_transition_support1_flg === INT_TRUE_FROM_API &&
      after.params.childcare_transition_support2_flg === INT_FALSE_FROM_API &&
      after.params.childcare_transition_support3_flg === INT_TRUE_FROM_API
    ) {
      // 入院時情報連携加算Ⅱの非活性条件
      // 「保育・教育等移行支援加算」が①③の場合
      defaultList.push(
        "hospitalization_relation_addition",
        "remote_location_hospitalization_relation_flg"
      );
    }

    // サービス内容ごとの非活性項目判定処理
    switch (statusValue) {
      case STATUS_LIST_SHOGAIJISODAN[1].value:
        if (
          after.params.discharge_addition_flg === INT_TRUE_FROM_API ||
          (after.params.hospitalization_relation_addition &&
            after.params.hospitalization_relation_addition.toString() ===
              HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[1].value)
        ) {
          defaultList.push("intensive_support3_addition_flg");
        }

        if (
          after.params.discharge_addition_flg === INT_TRUE_FROM_API ||
          (after.params.hospitalization_relation_addition &&
            after.params.hospitalization_relation_addition.toString() !==
              HOSPITALIZATION_RELATION_ADDITION_TYPE_LIST[0].value)
        ) {
          defaultList.push(
            "childcare_transition_support1_flg",
            "childcare_transition_support3_flg"
          );
        }

        if (after.params.discharge_addition_flg === INT_TRUE_FROM_API) {
          defaultList.push(
            "childcare_transition_support2_flg",
            "remote_location_childcare_transition_support2_flg"
          );
        }
        break;
      default:
    }
  }

  return defaultList;
};

export const normalizeInoutConsultationResultsDaily = (
  inoutResults: GetInOutConsultationResultsDailyResponse["data"]["inout_consultation_results"],
  date = ""
): InoutConsultationResultsState[] => {
  return inoutResults
    ? inoutResults.map((record) => {
        const baseInfo: InitBaseInfo = {
          uifId: record.users_in_facility_id,
          sei: record.name_sei,
          mei: record.name_mei,
          date
        };

        return {
          id: record.id,
          usersInFacilityId: record.users_in_facility_id,
          targetDate: date,
          isHoliday: false,
          nameSei: record ? record.name_sei : baseInfo.sei,
          nameMei: record ? record.name_mei : baseInfo.mei,
          inoutResultDailyId: record.inout_result_daily_id
            ? record.inout_result_daily_id
            : null,
          typeConsultation: record.type_consultation,
          status: record.status,
          firstAdditionFlg: record
            ? record.first_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          firstAdditionSpan: record.first_addition_span,
          intensiveSupport1AdditionFlg: record
            ? record.intensive_support1_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport2AdditionFlg: record
            ? record.intensive_support2_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport3AdditionFlg: record
            ? record.intensive_support3_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport4AdditionFlg: record
            ? record.intensive_support4_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport5AdditionFlg: record
            ? record.intensive_support5_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation1Flg: record
            ? record.inhome_nursing_care_support_office_relation1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation2Flg: record
            ? record.inhome_nursing_care_support_office_relation2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation3Flg: record
            ? record.inhome_nursing_care_support_office_relation3_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation4Flg: record
            ? record.inhome_nursing_care_support_office_relation4_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation5Flg: record
            ? record.inhome_nursing_care_support_office_relation5_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation6Flg: record
            ? record.inhome_nursing_care_support_office_relation6_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          dischargeAdditionFlg: record
            ? record.discharge_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          sameInhomeNursingCareSupportFlg: record
            ? record.same_inhome_nursing_care_support_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          samePreventiveCareSupportFlg: record
            ? record.same_preventive_care_support_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          hospitalizationRelationAddition: record
            ? record.hospitalization_relation_addition
            : 0,
          medicalDaycareEducationRelation1Flg: record
            ? record.medical_daycare_education_relation1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          medicalDaycareEducationRelation2Flg: record
            ? record.medical_daycare_education_relation2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          medicalDaycareEducationRelation3Flg: record
            ? record.medical_daycare_education_relation3_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          servicePersonnelMeetingHeldFlg: record
            ? record.service_personnel_meeting_held_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          serviceProvisionMonitoringFlg: record
            ? record.service_provision_monitoring_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          lifeSupportPromotionFlg: record
            ? record.life_support_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          systemPromotionFlg: record
            ? record.system_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport1Flg: record
            ? record.childcare_transition_support1_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport2Flg: record
            ? record.childcare_transition_support2_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport3Flg: record
            ? record.childcare_transition_support3_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          mentalDisorderSupport: record ? record.mental_disorder_support : 0,
          behavioralDisorderSupport: record
            ? record.behavioral_disorder_support
            : 0,
          higherBrainDysfunctionSupport: record
            ? record.higher_brain_dysfunction_support
            : 0,
          remoteLocationFirstAdditionFlg: record
            ? record.remote_location_first_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationHospitalizationRelationFlg: record
            ? record.remote_location_hospitalization_relation_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationDischargeAdditionFlg: record
            ? record.remote_location_discharge_addition_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationInhomeNursing2Flg: record
            ? record.remote_location_inhome_nursing2_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationInhomeNursing5Flg: record
            ? record.remote_location_inhome_nursing5_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationChildcareTransitionSupport2Flg: record
            ? record.remote_location_childcare_transition_support2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationMedicalDaycareEducation1Flg: record
            ? record.remote_location_medical_daycare_education1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationMedicalDaycareEducation2Flg: record
            ? record.remote_location_medical_daycare_education2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationIntensiveSupport1Flg: record
            ? record.remote_location_intensive_support1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationIntensiveSupport4Flg: record
            ? record.remote_location_intensive_support4_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          memo: record ? record.memo : ""
        };
      })
    : [];
};

export const normalizeInoutConsultationResultsMonthly = (
  inoutResults: GetInOutConsultationResultsUsersResponse["data"]["inout_consultation_results"],
  date = ""
): InoutConsultationResultsState[] => {
  return inoutResults
    ? inoutResults.map((record) => {
        const targetDateState = record.target_date ? record.target_date : date;

        const baseInfo: InitBaseInfo = {
          uifId: record.users_in_facility_id,
          sei: record.name_sei,
          mei: record.name_mei,
          date: targetDateState
        };

        return {
          id: record.id,
          usersInFacilityId: record.users_in_facility_id,
          targetDate: targetDateState,
          isHoliday: record.isHoliday ? record.isHoliday : false,
          nameSei: record ? record.name_sei : baseInfo.sei,
          nameMei: record ? record.name_mei : baseInfo.mei,
          inoutResultDailyId: record.inout_result_daily_id
            ? record.inout_result_daily_id
            : null,
          typeConsultation: record.type_consultation,
          status: record.status,
          firstAdditionFlg: record
            ? record.first_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          firstAdditionSpan: record.first_addition_span
            ? record.first_addition_span
            : 0,
          intensiveSupport1AdditionFlg: record
            ? record.intensive_support1_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport2AdditionFlg: record
            ? record.intensive_support2_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport3AdditionFlg: record
            ? record.intensive_support3_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport4AdditionFlg: record
            ? record.intensive_support4_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          intensiveSupport5AdditionFlg: record
            ? record.intensive_support5_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation1Flg: record
            ? record.inhome_nursing_care_support_office_relation1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation2Flg: record
            ? record.inhome_nursing_care_support_office_relation2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation3Flg: record
            ? record.inhome_nursing_care_support_office_relation3_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation4Flg: record
            ? record.inhome_nursing_care_support_office_relation4_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation5Flg: record
            ? record.inhome_nursing_care_support_office_relation5_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          inhomeNursingCareSupportOfficeRelation6Flg: record
            ? record.inhome_nursing_care_support_office_relation6_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          dischargeAdditionFlg: record
            ? record.discharge_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          sameInhomeNursingCareSupportFlg: record
            ? record.same_inhome_nursing_care_support_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          samePreventiveCareSupportFlg: record
            ? record.same_preventive_care_support_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          hospitalizationRelationAddition: record.hospitalization_relation_addition
            ? record.hospitalization_relation_addition
            : 0,
          medicalDaycareEducationRelation1Flg: record
            ? record.medical_daycare_education_relation1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          medicalDaycareEducationRelation2Flg: record
            ? record.medical_daycare_education_relation2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          medicalDaycareEducationRelation3Flg: record
            ? record.medical_daycare_education_relation3_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          servicePersonnelMeetingHeldFlg: record
            ? record.service_personnel_meeting_held_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          serviceProvisionMonitoringFlg: record
            ? record.service_provision_monitoring_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          lifeSupportPromotionFlg: record
            ? record.life_support_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          systemPromotionFlg: record
            ? record.system_promotion_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport1Flg: record
            ? record.childcare_transition_support1_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport2Flg: record
            ? record.childcare_transition_support2_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          childcareTransitionSupport3Flg: record
            ? record.childcare_transition_support3_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          mentalDisorderSupport: record ? record.mental_disorder_support : 0,
          behavioralDisorderSupport: record
            ? record.behavioral_disorder_support
            : 0,
          higherBrainDysfunctionSupport: record
            ? record.higher_brain_dysfunction_support
            : 0,
          remoteLocationFirstAdditionFlg: record
            ? record.remote_location_first_addition_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationHospitalizationRelationFlg: record
            ? record.remote_location_hospitalization_relation_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationDischargeAdditionFlg: record
            ? record.remote_location_discharge_addition_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationInhomeNursing2Flg: record
            ? record.remote_location_inhome_nursing2_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationInhomeNursing5Flg: record
            ? record.remote_location_inhome_nursing5_flg === INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationChildcareTransitionSupport2Flg: record
            ? record.remote_location_childcare_transition_support2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationMedicalDaycareEducation1Flg: record
            ? record.remote_location_medical_daycare_education1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationMedicalDaycareEducation2Flg: record
            ? record.remote_location_medical_daycare_education2_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationIntensiveSupport1Flg: record
            ? record.remote_location_intensive_support1_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          remoteLocationIntensiveSupport4Flg: record
            ? record.remote_location_intensive_support4_flg ===
              INT_TRUE_FROM_API
            : DEFAULT_CHECKBOX_VALUE,
          memo: record ? record.memo : ""
        };
      })
    : [];
};

export const normalizeDaily = (
  daily: GetInOutConsultationResultsDailyResponse["data"]["inout_result_daily"]
): ReportState["reportDaily"]["inoutResultDaily"] => {
  return {
    targetDate: daily.target_date,
    numberOfEmployees: daily.number_of_employees,
    numberOfHandicappedChild: daily.number_of_handicapped_child,
    numberOfKeikakusodan: daily.number_of_keikakusodan,
    trainingByChief202404: daily.training_by_chief_202404
      ? daily.training_by_chief_202404.toString()
      : TRAINING_BY_CHIEF_TYPE_LIST[0].value,
    trainingByChiefShogaiji202404: daily.training_by_chief_shogaiji_202404
      ? daily.training_by_chief_shogaiji_202404.toString()
      : TRAINING_BY_CHIEF_SHOGAIJI_TYPE_LIST[0].value
  };
};

export const normalizeDailySummary = (
  summary: GetInOutConsultationResultsDailyResponse["data"]["summary"]
): ReportState["reportDaily"]["summary"] => {
  return {
    serviceUseCount: summary.serviceUseCount,
    continuousServiceUseCount: summary.continuousServiceUseCount,
    nextMonthUse2LimitCount: summary.nextMonthUse2LimitCount,
    monitoringCount: summary.monitoringCount
  };
};

export const normalizeMonthlySummary = (
  summary: GetInOutConsultationResultsUsersResponse["data"]["summary"]
): ReportState["reportMonthly"]["summary"] => {
  return {
    inhomeNursingCareSupportOfficeRelation1Count: summary.inhomeNursingCareSupportOfficeRelation1Count
      ? summary.inhomeNursingCareSupportOfficeRelation1Count
      : 0,
    inhomeNursingCareSupportOfficeRelation2Count: summary.inhomeNursingCareSupportOfficeRelation2Count
      ? summary.inhomeNursingCareSupportOfficeRelation2Count
      : 0,
    inhomeNursingCareSupportOfficeRelation3Count: summary.inhomeNursingCareSupportOfficeRelation3Count
      ? summary.inhomeNursingCareSupportOfficeRelation3Count
      : 0,
    inhomeNursingCareSupportOfficeRelation4Count: summary.inhomeNursingCareSupportOfficeRelation4Count
      ? summary.inhomeNursingCareSupportOfficeRelation4Count
      : 0,
    inhomeNursingCareSupportOfficeRelation5Count: summary.inhomeNursingCareSupportOfficeRelation5Count
      ? summary.inhomeNursingCareSupportOfficeRelation5Count
      : 0,
    inhomeNursingCareSupportOfficeRelation6Count: summary.inhomeNursingCareSupportOfficeRelation6Count
      ? summary.inhomeNursingCareSupportOfficeRelation6Count
      : 0,
    inhomeNursingCareSupportOfficeRelationLimit: summary.inhomeNursingCareSupportOfficeRelationLimit
      ? summary.inhomeNursingCareSupportOfficeRelationLimit
      : 0,
    lifeSupportPromotionCount: summary.lifeSupportPromotionCount
      ? summary.lifeSupportPromotionCount
      : 0,
    dischargeAdditionCount: summary.dischargeAdditionCount
      ? summary.dischargeAdditionCount
      : 0,
    childcareTransitionSupport1Count: summary.childcareTransitionSupport1Count
      ? summary.childcareTransitionSupport1Count
      : 0,
    childcareTransitionSupport2Count: summary.childcareTransitionSupport2Count
      ? summary.childcareTransitionSupport2Count
      : 0,
    childcareTransitionSupport3Count: summary.childcareTransitionSupport3Count
      ? summary.childcareTransitionSupport3Count
      : 0
  };
};

/**
 * 利用実績一覧(日ごと)
 */
export const normalizeDailyReportDataFromAPI = (
  result: GetInOutConsultationResultsDailyResponse
): ReportState["reportDaily"] => {
  const normalizeReportList: ReportState["reportDaily"] = {
    inoutResultDaily: normalizeDaily(result.data.inout_result_daily),
    inoutConsultationResults: normalizeInoutConsultationResultsDaily(
      result.data.inout_consultation_results,
      result.data.inout_result_daily.target_date
    ),
    summary: normalizeDailySummary(result.data.summary)
  };

  return normalizeReportList;
};

/**
 * 利用実績一覧(月ごと)
 */
export const normalizeUserReportDataFromAPI = (
  result: GetInOutConsultationResultsUsersResponse
): ReportState["reportMonthly"] => {
  const normalizeReportList: ReportState["reportMonthly"] = {
    summary: normalizeMonthlySummary(result.data.summary),
    inoutConsultationResults: normalizeInoutConsultationResultsMonthly(
      result.data.inout_consultation_results
    )
  };

  return normalizeReportList;
};

/**
 * 利用実績登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    yyyymmdd: dateToLocalisedString(formValue.initial.targetDate, "YYYYMMDD"),
    uif_id: formValue.initial.usersInFacilityId,
    params: {
      inout_consultation_results_id: formValue.initial.id,
      inout_result_daily_id: formValue.initial.inoutResultDailyId,
      target_date: formValue.initial.targetDate,
      type_consultation: formValue.initial.typeConsultation,
      status: castNumber(formValue.initial.status),
      same_inhome_nursing_care_support_flg: formValue.initial
        .sameInhomeNursingCareSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      same_preventive_care_support_flg: formValue.initial
        .samePreventiveCareSupportFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      first_addition_flg: formValue.initial.firstAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      first_addition_span: castNumber(formValue.initial.firstAdditionSpan),
      discharge_addition_flg: formValue.initial.dischargeAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      medical_daycare_education_relation1_flg: formValue.initial
        .medicalDaycareEducationRelation1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      medical_daycare_education_relation2_flg: formValue.initial
        .medicalDaycareEducationRelation2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      medical_daycare_education_relation3_flg: formValue.initial
        .medicalDaycareEducationRelation3Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      service_personnel_meeting_held_flg: formValue.initial
        .servicePersonnelMeetingHeldFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      service_provision_monitoring_flg: formValue.initial
        .serviceProvisionMonitoringFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      hospitalization_relation_addition: stringToNumber(
        formValue.initial.hospitalizationRelationAddition
      ),
      life_support_promotion_flg: formValue.initial.lifeSupportPromotionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      system_promotion_flg: formValue.initial.systemPromotionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      intensive_support1_addition_flg: formValue.initial
        .intensiveSupport1AdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      intensive_support2_addition_flg: formValue.initial
        .intensiveSupport2AdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      intensive_support3_addition_flg: formValue.initial
        .intensiveSupport3AdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      intensive_support4_addition_flg: formValue.initial
        .intensiveSupport4AdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      intensive_support5_addition_flg: formValue.initial
        .intensiveSupport5AdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      memo: formValue.initial.memo,
      inhome_nursing_care_support_office_relation1_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      inhome_nursing_care_support_office_relation2_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      inhome_nursing_care_support_office_relation3_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation3Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      inhome_nursing_care_support_office_relation4_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation4Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      inhome_nursing_care_support_office_relation5_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation5Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      inhome_nursing_care_support_office_relation6_flg: formValue.initial
        .inhomeNursingCareSupportOfficeRelation6Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      childcare_transition_support1_flg: formValue.initial
        .childcareTransitionSupport1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      childcare_transition_support2_flg: formValue.initial
        .childcareTransitionSupport2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      childcare_transition_support3_flg: formValue.initial
        .childcareTransitionSupport3Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      mental_disorder_support: stringToNumber(
        formValue.initial.mentalDisorderSupport
      ),
      behavioral_disorder_support: stringToNumber(
        formValue.initial.behavioralDisorderSupport
      ),
      higher_brain_dysfunction_support: stringToNumber(
        formValue.initial.higherBrainDysfunctionSupport
      ),
      remote_location_first_addition_flg: formValue.initial
        .remoteLocationFirstAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_hospitalization_relation_flg: formValue.initial
        .remoteLocationHospitalizationRelationFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_discharge_addition_flg: formValue.initial
        .remoteLocationDischargeAdditionFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_inhome_nursing2_flg: formValue.initial
        .remoteLocationInhomeNursing2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_inhome_nursing5_flg: formValue.initial
        .remoteLocationInhomeNursing5Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_childcare_transition_support2_flg: formValue.initial
        .remoteLocationChildcareTransitionSupport2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_medical_daycare_education1_flg: formValue.initial
        .remoteLocationMedicalDaycareEducation1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_medical_daycare_education2_flg: formValue.initial
        .remoteLocationMedicalDaycareEducation2Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_intensive_support1_flg: formValue.initial
        .remoteLocationIntensiveSupport1Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      remote_location_intensive_support4_flg: formValue.initial
        .remoteLocationIntensiveSupport4Flg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API
    }
  };

  return result;
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  first_addition_flg: {
    requestKey: "first_addition_flg",
    cooperationKeys: ["first_addition_span"]
  },
  first_addition_span: {
    requestKey: "first_addition_span",
    cooperationKeys: ["first_addition_flg"]
  },
  remote_location_first_addition_flg: {
    requestKey: "remote_location_first_addition_flg",
    cooperationKeys: ["first_addition_flg"]
  },
  remote_location_hospitalization_relation_flg: {
    requestKey: "remote_location_hospitalization_relation_flg",
    cooperationKeys: ["hospitalization_relation_addition"]
  },
  remote_location_discharge_addition_flg: {
    requestKey: "remote_location_discharge_addition_flg",
    cooperationKeys: ["discharge_addition_flg"]
  },
  remote_location_inhome_nursing2_flg: {
    requestKey: "remote_location_inhome_nursing2_flg",
    cooperationKeys: ["inhome_nursing_care_support_office_relation2_flg"]
  },
  remote_location_inhome_nursing5_flg: {
    requestKey: "remote_location_inhome_nursing5_flg",
    cooperationKeys: ["inhome_nursing_care_support_office_relation5_flg"]
  },
  remote_location_childcare_transition_support2_flg: {
    requestKey: "remote_location_childcare_transition_support2_flg",
    cooperationKeys: ["childcare_transition_support2_flg"]
  },
  remote_location_medical_daycare_education1_flg: {
    requestKey: "remote_location_medical_daycare_education1_flg",
    cooperationKeys: ["medical_daycare_education_relation1_flg"]
  },
  remote_location_medical_daycare_education2_flg: {
    requestKey: "remote_location_medical_daycare_education2_flg",
    cooperationKeys: ["medical_daycare_education_relation2_flg"]
  },
  remote_location_intensive_support1_flg: {
    requestKey: "remote_location_intensive_support1_flg",
    cooperationKeys: ["intensive_support1_addition_flg"]
  },
  remote_location_intensive_support4_flg: {
    requestKey: "remote_location_intensive_support4_flg",
    cooperationKeys: ["intensive_support4_addition_flg"]
  }
};

// 遠隔地訪問加算の親項目
const remoteLocationMap = {
  remote_location_first_addition_flg: "first_addition_flg",
  remote_location_hospitalization_relation_flg:
    "hospitalization_relation_addition",
  remote_location_discharge_addition_flg: "discharge_addition_flg",
  remote_location_inhome_nursing2_flg:
    "inhome_nursing_care_support_office_relation2_flg",
  remote_location_inhome_nursing5_flg:
    "inhome_nursing_care_support_office_relation5_flg",
  remote_location_childcare_transition_support2_flg:
    "childcare_transition_support2_flg",
  remote_location_medical_daycare_education1_flg:
    "medical_daycare_education_relation1_flg",
  remote_location_medical_daycare_education2_flg:
    "medical_daycare_education_relation2_flg",
  remote_location_intensive_support1_flg: "intensive_support1_addition_flg",
  remote_location_intensive_support4_flg: "intensive_support4_addition_flg"
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

export const normalizeDiffParamToPostAPI = (
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI,
  facility: FacilityState,
  usersInFacility: UsersInFacilityState
): RequestParamToPostAPI => {
  const isKeikakusodan =
    after.params.type_consultation === FACILITY_TYPE_KEIKAKUSODAN;
  const needNursingCareClass =
    usersInFacility.user.user_in_facility_keikakusodan &&
    usersInFacility.user.user_in_facility_keikakusodan.need_nursing_care_class;
  const supportRequiredClass =
    usersInFacility.user.user_in_facility_keikakusodan &&
    usersInFacility.user.user_in_facility_keikakusodan.support_required_class;
  const classifyHandicappedFlg =
    usersInFacility.user.user_in_facility.classify_handicapped_flg;
  const status = after.params.status ? after.params.status.toString() : "0";
  const facilityTypeTargetList = isKeikakusodan
    ? defaultDataByStatus(status)
    : defaultDataByStatusForShogaiji(status);
  const targetFacility = isKeikakusodan
    ? findFacilityKeikakuSodan(facility)
    : findFacilityShogaijisodan(facility);
  let defaultTargetList = facilityTypeTargetList
    .concat(
      defaultDataByUserInFacility(
        classifyHandicappedFlg,
        needNursingCareClass,
        supportRequiredClass
      )
    )
    .concat(
      defaultDataByFacility(
        !!targetFacility && targetFacility.lifeSupportHubInDistrictFlg
      )
    )
    .concat(defaultData(after, status));
  defaultTargetList = defaultTargetList.filter(
    (value, index) => defaultTargetList.indexOf(value) === index
  );

  const typeSpecificStatusList = isKeikakusodan
    ? STATUS_LIST
    : STATUS_LIST_SHOGAIJISODAN;
  if (
    status === typeSpecificStatusList[2].value &&
    !!targetFacility &&
    targetFacility.lifeSupportHubInDistrictFlg &&
    classifyHandicappedFlg === Checkbox.ON
  ) {
    // サービス内容が2番目（サービス利用支援 or 障害児支援利用援助)を選択しており、
    // 地域生活支援拠点等と障害児がONの場合に「サービス等利用計画案を交付した日までの期間」は対象から除外
    defaultTargetList = defaultTargetList.filter(
      (v) => v !== "first_addition_span"
    );
  }

  const result: RequestParamToPostAPI = {
    uif_id: after.uif_id,
    yyyymmdd: after.yyyymmdd,
    params: {
      inout_result_daily_id: after.params.inout_result_daily_id,
      inout_consultation_results_id: after.params.inout_consultation_results_id,
      target_date: after.params.target_date,
      type_consultation: after.params.type_consultation
    }
  };

  const isChangedTypeConsultation =
    before.params.type_consultation !== after.params.type_consultation;

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    const defaultFlg = defaultTargetList.includes(key);
    if (after.params.inout_consultation_results_id === null) {
      // 初回の場合、デフォルト対象はデフォルト値、それ以外は入力値をセット
      result.params[key] = defaultFlg ? INT_FALSE_FROM_API : after.params[key];
    } else if (
      isChangedTypeConsultation ||
      before.params[key] !== after.params[key]
    ) {
      result.params[key] = after.params[key];
    }
  });

  const diffData = addRelationValue(result, after);

  defaultTargetList.forEach((key) => {
    diffData.params[key] = INT_FALSE_FROM_API;
  });

  // 遠隔地訪問加算は親項目がOFFの時はパラメータをOFFにする
  Object.keys(remoteLocationMap).forEach((key) => {
    const parentKey = remoteLocationMap[key];
    if (diffData.params[parentKey] === INT_FALSE_FROM_API) {
      diffData.params[key] = INT_FALSE_FROM_API;
    }
  });

  const keysToExclude: string[] = [];
  if (isKeikakusodan) {
    keysToExclude.push(
      "childcare_transition_support1_flg",
      "childcare_transition_support2_flg",
      "childcare_transition_support3_flg",
      "remote_location_childcare_transition_support2_flg"
    );
  } else {
    keysToExclude.push(
      "same_inhome_nursing_care_support_flg",
      "same_preventive_care_support_flg",
      "inhome_nursing_care_support_office_relation1_flg",
      "inhome_nursing_care_support_office_relation2_flg",
      "inhome_nursing_care_support_office_relation3_flg",
      "inhome_nursing_care_support_office_relation4_flg",
      "inhome_nursing_care_support_office_relation5_flg",
      "inhome_nursing_care_support_office_relation6_flg",
      "remote_location_inhome_nursing2_flg",
      "remote_location_inhome_nursing5_flg"
    );

    if (classifyHandicappedFlg === Checkbox.OFF) {
      // サービス種類が障害児相談かつ障害児フラグがOFFの場合、入力項目はすべて非活性
      keysToExclude.push(
        "first_addition_flg",
        "first_addition_span",
        "discharge_addition_flg",
        "medical_daycare_education_relation1_flg",
        "medical_daycare_education_relation2_flg",
        "medical_daycare_education_relation3_flg",
        "service_personnel_meeting_held_flg",
        "service_provision_monitoring_flg",
        "hospitalization_relation_addition",
        "life_support_promotion_flg",
        "system_promotion_flg",
        "intensive_support1_addition_flg",
        "intensive_support2_addition_flg",
        "intensive_support3_addition_flg",
        "intensive_support4_addition_flg",
        "intensive_support5_addition_flg",
        "childcare_transition_support1_flg",
        "childcare_transition_support2_flg",
        "childcare_transition_support3_flg",
        "mental_disorder_support",
        "behavioral_disorder_support",
        "higher_brain_dysfunction_support",
        "remote_location_first_addition_flg",
        "remote_location_hospitalization_relation_flg",
        "remote_location_discharge_addition_flg",
        "remote_location_inhome_nursing2_flg",
        "remote_location_inhome_nursing5_flg",
        "remote_location_childcare_transition_support2_flg",
        "remote_location_medical_daycare_education1_flg",
        "remote_location_medical_daycare_education2_flg",
        "remote_location_intensive_support1_flg",
        "remote_location_intensive_support4_flg",
        "memo"
      );
    }
  }

  // 不要なパラメーターをリクエストから排除
  keysToExclude.forEach((key) => {
    delete diffData.params[key];
  });

  return diffData;
};

export const normalizeFacilitiesKeikakusodanMonthlyParams = ({
  data,
  selectedDate,
  facility
}: FacilitiesKEIKAKUSODANMonthlyType): postFacilitiesMonthlyParams => {
  const numberOfHandicappedChildData = facility.integratedManagementHandicappedChildFlg
    ? castNumber(data.initial.numberOfHandicappedChild)
    : null;
  const numberOfKeikakusodanData = facility.integratedManagementHandicappedChildFlg
    ? castNumber(data.initial.numberOfKeikakusodan)
    : null;

  return {
    yyyymm: dateToLocalisedString(selectedDate, "YYYYMM"),
    params: {
      facility: {
        number_of_employees: castNumber(data.initial.numberOfEmployees),
        number_of_handicapped_child: numberOfHandicappedChildData,
        training_by_chief_202404:
          facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN ||
          facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
            ? castNumber(data.initial.trainingByChief202404)
            : null,
        number_of_keikakusodan: numberOfKeikakusodanData,
        training_by_chief_shogaiji_202404:
          facility.facilityType === FACILITY_TYPE_SHOGAIJISODAN ||
          facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
            ? castNumber(data.initial.trainingByChiefShogaiji202404)
            : null
      }
    }
  };
};
