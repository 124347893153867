/**
 * value が undefined or null の場合は returnValue を返す
 * returnValue が undefined の場合は "" を返す
 *
 * @param value
 * @param returnValue
 */
const undefinedToReturnValue = (
  value: number | string | undefined | null,
  returnValue?: string | number
): string => {
  if (value === undefined || value === null) {
    if (returnValue !== undefined && returnValue !== null) {
      return `${returnValue}`;
    }
    return "";
  }
  return `${value}`;
};

export default undefinedToReturnValue;
