import { GetSupportPlanOnceResponse } from "@api/requests/supportPlan/GroupHome/getSupportPlanOnce";

/**
 * ActionNames
 */
export const FETCH_PRIVATE_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_STARTED";
export const FETCH_PRIVATE_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_SUCCESS";
export const FETCH_PRIVATE_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_FAILED";

/**
 * State
 */
export interface SupportPlanGroupHomeState {
  privateSupportPlan: GetSupportPlanOnceResponse["data"];
}
