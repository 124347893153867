import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import getPaginatedDailySupports from "@api/requests/supports/SHISETSUNYUSHO/getPaginatedDailySupports";

export const fetchPaginatedDailySupports = (dispatch: Dispatch) => async (
  yyyymmdd: string,
  unitId?: number,
  page?: number,
  keyword?: string,
  is_record?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchPaginatedDailySupportsStarted());
  await getPaginatedDailySupports(yyyymmdd, unitId, page, keyword, is_record)
    .then((res) => {
      dispatch(actions.fetchPaginatedDailySupportsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(
        actions.fetchPaginatedDailySupportsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchPaginatedDailySupports: (
    yyyymmdd: string,
    unitId?: number,
    page?: number,
    keyword?: string,
    is_record?: number
  ) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchPaginatedDailySupports: fetchPaginatedDailySupports(dispatch)
});
