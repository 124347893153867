import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
// store
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { ServiceDeliveryState } from "@stores/domain/serviceDelivery/types";
import { SortedCustomRecords } from "@components/organisms/record/serviceDelivery/ServiceDeliveryDetailContainer";
// ui
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikTime from "@components/molecules/FormikTime";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
// formik
import { FormikProps, getIn } from "formik";
import { ServiceDeliveryDetailValues } from "@initialize/record/serviceDelivery/initialValues";
// variables
import {
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  DAY_SELECT_OPTIONS,
  DAY_SELECT_TYPE,
  FacilityType,
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO
} from "@constants/variables";
import {
  MIXUTER_INPUT_TYPE,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { EXCRETION_MAGAGEMENT_STATE } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import FormikTextField from "@components/molecules/FormikTextField";
// utils
import {
  isExcretionManagement,
  isHydration,
  isVital
} from "@utils/domain/serviceDelivery/extractDefaultItems";

const styles = (): StyleRules =>
  createStyles({
    sectionWrap: {
      padding: "24px 16px",
      backgroundColor: "#fff",
      "&:not(:last-child)": {
        borderBottom: "1px solid #e0e0e0"
      }
    },
    label: {
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.6)"
    },
    fixedPhrase: {
      marginTop: 16
    },
    section: {
      marginTop: 16
    },
    checkboxSection: {
      marginLeft: -24,
      marginTop: 12,
      marginBottom: -18,
      display: "flex",
      flexWrap: "wrap"
    },
    moneySection: {
      marginTop: 16,
      marginBottom: -24,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between"
    },
    multiTextMargin: {
      "&:nth-child(odd)": {
        marginRight: 16
      }
    },
    flex: {
      display: "flex",
      justifyContent: "space-between"
    },
    multiTextWrap: {
      marginTop: 24,
      marginBottom: -24
    }
  });

type Props = {
  isEdit: boolean;
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
  sortedCustomRecordsItems: SortedCustomRecords;
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"];
  categoryType: number;
  practitionerNum: number;
  facilityType: FacilityType;
  openInsertPhraseModal: (
    fieldName: string,
    customRecord: CustomRecordsWithCategoryState[0]["custom_record_items"][0]
  ) => (e: React.MouseEvent) => void;
  createUnit: (
    choice: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["custom_record_item_choices"][0],
    defaultItem: CustomRecordsWithCategoryState[0]["custom_record_items"][0]["default_item"],
    facilityTypeValues: FacilityType,
    categoryTypeParam: number
  ) => string;
  handleBlurDecimal: (
    defaultChoice: number | null,
    categoryTypeParam: number
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
  handleChangeNumber: (
    defaultChoice: number | null
  ) => (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => string;
  detailRecords?: ServiceDeliveryState["detailsRecord"];
  inputTypeMoneyBlurHook: (
    defaultItem: number | null,
    defaultChoice: number | null
  ) => (event: React.FormEvent<HTMLInputElement>) => string | void;
} & WithStyles<typeof styles>;

export const ServiceDeliveryDetailItemsCore = (props: Props): JSX.Element => {
  const {
    sortedCustomRecordsItems,
    practitionerValues,
    practitionerNum,
    facilityType,
    categoryType
  } = props;
  const { values: formikValues } = props.formikProps;

  return (
    <>
      <div>
        {sortedCustomRecordsItems && sortedCustomRecordsItems.length > 0 ? (
          sortedCustomRecordsItems.map((value) => {
            if (!value) {
              return null;
            }

            // uncontrolledエラー防止のため
            // https://reactjs.org/docs/forms.html#controlled-components
            if (
              Object.keys(practitionerValues.custom_record.input_type_checkbox)
                .length === 0
            ) {
              return null;
            }

            const { customRecord, checkedItems } = value;

            const fieldName = `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_text.${customRecord.id}.input_data`;

            switch (customRecord.input_type) {
              // テキスト形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
                //   // 定型文
                const fixedPhraseData = customRecord.custom_record_item_choices
                  ? customRecord.custom_record_item_choices.filter(
                      (item) => item.hidden === 0
                    )
                  : [];
                // visibility === 0かつ各項目に入力がない場合はスキップ
                return (
                  <div
                    className={props.classes.sectionWrap}
                    key={`${customRecord.id}_input_text`}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.section}>
                      <FormikTextField
                        isMobile
                        readOnly={!props.isEdit}
                        name={fieldName}
                        size="fullSize"
                        style={{ marginBottom: 0 }}
                        multiline
                      />
                    </div>
                    {/* 定型文の挿入モーダル */}
                    {props.isEdit && fixedPhraseData.length > 0 && (
                      <div className={props.classes.fixedPhrase}>
                        <KnowbeButton
                          style={{ minHeight: 40, width: "100%" }}
                          kind="outline"
                          onClick={props.openInsertPhraseModal(
                            fieldName,
                            customRecord
                          )}
                        >
                          定型文
                        </KnowbeButton>
                      </div>
                    )}
                  </div>
                );
              }
              // チェックボックス形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
                return (
                  <div
                    key={`${customRecord.id}_input_checkbox`}
                    className={props.classes.sectionWrap}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.checkboxSection}>
                      {customRecord.custom_record_item_choices &&
                        customRecord.custom_record_item_choices.map(
                          (choice) => {
                            const checked =
                              checkedItems &&
                              checkedItems.some(
                                (item) => item.choiced_item_id === choice.id
                              );
                            if (
                              (choice.hidden === 1 && !checked) ||
                              (customRecord.visibility === 0 && !checked)
                            ) {
                              return null;
                            }
                            return (
                              <FormikCheckbox
                                isMobile
                                highEmphasis
                                readOnly={!props.isEdit}
                                name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox.${customRecord.id}.${choice.id}.checked`}
                                key={`$checkbox_choices_${customRecord.id}_${choice.id}`}
                                label={
                                  <span
                                    style={{
                                      fontSize: 16,
                                      color: "rgba(0, 0, 0, 0.87)"
                                    }}
                                  >
                                    {choice.name}
                                  </span>
                                }
                                style={{ marginLeft: 24 }}
                              />
                            );
                          }
                        )}
                    </div>
                  </div>
                );
              }
              // 複数テキスト入力
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
                // 排泄管理（排便）か判断（重度のみ）
                const isExcretion = isExcretionManagement(
                  customRecord.default_item,
                  facilityType
                );
                // 水分補給（量）（重度のみ）
                const isHydrationItem = isHydration(
                  customRecord.default_item,
                  facilityType
                );
                const isVitalItem = isVital(
                  customRecord.default_item,
                  facilityType
                );
                const choices =
                  customRecord.custom_record_item_choices &&
                  customRecord.custom_record_item_choices.map((choice) => {
                    const unit = props.createUnit(
                      choice,
                      customRecord.default_item,
                      facilityType,
                      categoryType
                    );
                    const isStateItem =
                      isExcretion &&
                      choice.default_choice === EXCRETION_MAGAGEMENT_STATE;
                    return (
                      <div
                        key={`${customRecord.id}_input_multi_text_${choice.id}`}
                        style={{
                          width: isStateItem ? "100%" : "calc(50% - 8px)"
                        }}
                        className={
                          isStateItem
                            ? undefined
                            : props.classes.multiTextMargin
                        }
                      >
                        <FormikTextField
                          isMobile
                          label={isHydrationItem ? "" : choice.name}
                          readOnly={!props.isEdit}
                          endAdornmentLabel={unit}
                          name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_multi_text.${customRecord.id}.${choice.id}.input_data`}
                          style={{
                            margin: 0,
                            width: "100%",
                            paddingBottom: 24
                          }}
                          onBlurHook={
                            isStateItem
                              ? undefined
                              : props.handleBlurDecimal(
                                  choice.default_choice,
                                  categoryType
                                )
                          }
                          onChangeHook={
                            facilityType === FacilityType.JUDOHOMONKAIGO &&
                            categoryType ===
                              CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_JUDO.body_care
                              ? undefined
                              : props.handleChangeNumber(choice.default_choice)
                          }
                          maxLength={isStateItem ? undefined : 10}
                          multiline={isStateItem}
                        />
                      </div>
                    );
                  });
                // バイタルだけ1行目が1項目となるので空箱作成
                if (isVitalItem) {
                  choices.splice(
                    1,
                    0,
                    <div
                      key={`${customRecord.id}_input_multi_text_empty`}
                      style={{ width: "calc(50% - 8px)" }}
                    />
                  );
                }
                return (
                  <div
                    key={`${customRecord.id}_input_multi_text`}
                    className={props.classes.sectionWrap}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.section}>
                      <div
                        className={props.classes.multiTextWrap}
                        key={`${customRecord.id}_multi_text}`}
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginBottom: -24
                        }}
                      >
                        {choices}
                      </div>
                    </div>
                  </div>
                );
              }
              // 選択式（入力項目）
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.mixture: {
                // 身体介護・院内介助
                const checkData = customRecord.custom_record_item_choices.find(
                  (item) =>
                    item.default_choice === 1 &&
                    item.default_choice_input_type === 2
                );

                // 開始時間
                const startTimeData = customRecord.custom_record_item_choices.find(
                  (item) =>
                    item.default_choice ===
                      MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.start &&
                    item.default_choice_input_type === MIXUTER_INPUT_TYPE.text
                );

                // 終了時間
                const endTimeData = customRecord.custom_record_item_choices.find(
                  (item) =>
                    item.default_choice ===
                      MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.end &&
                    item.default_choice_input_type === MIXUTER_INPUT_TYPE.text
                );

                // 目的地・交通手段など
                const destinationData = customRecord.custom_record_item_choices.find(
                  (item) =>
                    item.default_choice ===
                    MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
                );

                if (!startTimeData || !endTimeData || !checkData) {
                  return null;
                }

                const isShowTime = getIn(
                  formikValues,
                  `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${checkData.id}.checked`
                );

                // OFFにした時「開始時間」「終了時間」の値を空にする
                const onChangeMixtureCheckBox = (): void => {
                  if (isShowTime) {
                    props.formikProps.setFieldValue(
                      `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.input_data`,
                      ""
                    );
                    props.formikProps.setFieldValue(
                      `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.timeDate`,
                      DAY_SELECT_TYPE.today
                    );
                    props.formikProps.setFieldValue(
                      `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`,
                      ""
                    );
                    props.formikProps.setFieldValue(
                      `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                      DAY_SELECT_TYPE.today
                    );
                    if (destinationData) {
                      props.formikProps.setFieldValue(
                        `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${destinationData.id}.input_data`,
                        ""
                      );
                    }
                  }
                };

                const handleChangeDateSelect = (): void => {
                  props.formikProps.setFieldTouched(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`,
                    true
                  );
                };

                const handleChangeTime = (): void => {
                  props.formikProps.setFieldTouched(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                    true
                  );
                };

                // 提供人数「２」→「１」の時の対応（選択肢が””になるのを防ぐ）
                if (
                  getIn(
                    formikValues,
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`
                  ) === ""
                ) {
                  props.setFormikFieldValue(
                    `serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`,
                    DAY_SELECT_OPTIONS[0].value
                  );
                }

                // visibility === 0かつ各項目に入力がない場合はスキップ
                return (
                  <div
                    key={`${customRecord.id}_input_mixture`}
                    className={props.classes.sectionWrap}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.section}>
                      {checkData && (
                        <FormikCheckbox
                          isMobile
                          highEmphasis
                          readOnly={!props.isEdit}
                          name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${checkData.id}.checked`}
                          key={`mixture_choices_${customRecord.id}_${checkData.id}`}
                          label={
                            <span
                              style={{
                                fontSize: 16,
                                color: "rgba(0, 0, 0, 0.87)"
                              }}
                            >
                              {checkData.name}
                            </span>
                          }
                          style={{ marginBottom: 0, marginRight: 24 }}
                          onChangeHook={onChangeMixtureCheckBox}
                        />
                      )}
                      {isShowTime && (
                        <div className={props.classes.multiTextWrap}>
                          <div className={props.classes.flex}>
                            <FormikSelect
                              isMobile
                              readOnly={!props.isEdit}
                              name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.timeDate`}
                              label="開始時間"
                              options={DAY_SELECT_OPTIONS}
                              noerrortext
                              size="mobileHalf"
                            />
                            <FormikTime
                              isMobile
                              readOnly={!props.isEdit}
                              name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${startTimeData.id}.input_data`}
                              label=""
                              placeholder="00:00"
                              maxLength={5}
                              size="mobileHalf"
                            />
                          </div>
                          <div className={props.classes.flex}>
                            <FormikSelect
                              isMobile
                              readOnly={!props.isEdit}
                              name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.timeDate`}
                              label="終了時間"
                              options={DAY_SELECT_OPTIONS}
                              noerrortext
                              onChangeHook={handleChangeDateSelect}
                              size="mobileHalf"
                            />
                            <FormikTime
                              isMobile
                              readOnly={!props.isEdit}
                              name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${endTimeData.id}.input_data`}
                              label=""
                              placeholder="00:00"
                              maxLength={5}
                              onChangeHookTime={handleChangeTime}
                              size="mobileHalf"
                            />
                          </div>
                          {/* 目的地・交通手段など */}
                          {destinationData && (
                            <div>
                              <FormikTextField
                                isMobile
                                label={destinationData.name}
                                readOnly={!props.isEdit}
                                name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_mixture.${customRecord.id}.${destinationData.id}.input_data`}
                                size="fullSize"
                                multiline
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
              // 文字入力(金額形式)
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.money: {
                const section =
                  customRecord.custom_record_item_choices &&
                  customRecord.custom_record_item_choices.map((choice) => {
                    return (
                      <FormikTextField
                        isMobile
                        size="mobileHalf"
                        readOnly={!props.isEdit}
                        key={`${customRecord.id}_input_money_${choice.id}`}
                        name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_multi_text.${customRecord.id}.${choice.id}.input_data`}
                        label={choice.name}
                        endAdornmentLabel="円"
                        onBlurHook={props.inputTypeMoneyBlurHook(
                          customRecord.default_item,
                          choice.default_choice
                        )}
                      />
                    );
                  });
                if (customRecord.default_item === 29) {
                  section.splice(
                    2,
                    0,
                    <div
                      key={`${customRecord.id}_empty`}
                      style={{
                        fontSize: "14px",
                        color: "rgba(0, 0, 0, 0.6)",
                        width: "100%",
                        margin: "-8px 0 16px"
                      }}
                    >
                      （内訳）
                    </div>
                  );
                }
                return (
                  <div
                    key={`${customRecord.id}_input_money`}
                    className={props.classes.sectionWrap}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.moneySection}>{section}</div>
                  </div>
                );
              }
              // チェックボックス（カスタム可）＋テキスト形式
              case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text: {
                const textChoice = customRecord.custom_record_item_choices.find(
                  (c) =>
                    c.default_choice_input_type ===
                    SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text
                );
                const textField = textChoice ? (
                  <div style={{ width: "100%", marginTop: 16 }}>
                    <FormikTextField
                      style={{ marginBottom: 0 }}
                      isMobile
                      size="fullSize"
                      readOnly={!props.isEdit}
                      key={`${customRecord.id}_input_money_${textChoice.id}`}
                      name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox_and_text.${customRecord.id}.${textChoice.id}.input_data`}
                      label={textChoice.name}
                    />
                  </div>
                ) : null;
                return (
                  <div
                    key={`${customRecord.id}_input_checkbox_and_text`}
                    className={props.classes.sectionWrap}
                  >
                    <div className={props.classes.label}>
                      {customRecord.name}
                    </div>
                    <div className={props.classes.checkboxSection}>
                      {customRecord.custom_record_item_choices &&
                        customRecord.custom_record_item_choices
                          .filter(
                            (c) =>
                              c.default_choice_input_type ===
                              SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox
                          )
                          .map((choice) => {
                            const checked =
                              checkedItems &&
                              checkedItems.some(
                                (item) => item.choiced_item_id === choice.id
                              );
                            if (
                              (choice.hidden === 1 && !checked) ||
                              (customRecord.visibility === 0 && !checked)
                            ) {
                              return null;
                            }
                            return (
                              <FormikCheckbox
                                isMobile
                                highEmphasis
                                readOnly={!props.isEdit}
                                name={`serviceDeliveryRecordPractitioners${practitionerNum}.custom_record.input_type_checkbox_and_text.${customRecord.id}.${choice.id}.checked`}
                                key={`$checkbox_choices_${customRecord.id}_${choice.id}`}
                                label={
                                  <span
                                    style={{
                                      fontSize: 16,
                                      color: "rgba(0, 0, 0, 0.87)"
                                    }}
                                  >
                                    {choice.name}
                                  </span>
                                }
                                style={{ marginLeft: 24 }}
                              />
                            );
                          })}
                    </div>
                    {textField}
                  </div>
                );
              }
              default:
                return null;
            }
          })
        ) : (
          <div className={props.classes.sectionWrap}>
            <div className={props.classes.label}>
              項目が全て非表示に設定されています。提供記録の設定画面を確認してください。
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export const ServiceDeliveryDetailItems = withStyles(styles)(
  ServiceDeliveryDetailItemsCore
);
