import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { unsetOperations } from "@stores/domain/mgr/TANKINYUSHO/operations/actions";
import { OperationsState } from "@stores/domain/mgr/TANKINYUSHO/operations/types";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordOperationsValues } from "@initialize/mgr/TANKINYUSHO/record/operations/initialValues";
import * as recordMonthlyActions from "@stores/pages/record/monthly/actions";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import UnEditableWrapper from "@components/atoms/UnEditableWrapper";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { OperationRecordList } from "@components/organisms/mgr/TANKINYUSHO/record/OperationRecordList";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { RECORD_OPERATIONS } from "@/constants/url";
import { FieldItem } from "@interfaces/ui/form";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/mgr/TANKINYUSHO/variables";
import NoRecord from "@components/organisms/mgr/common/record/NoRecord";
import { OPERATION_CUSTOM_RECORD_DEFAULT_ITEM } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      position: "sticky",
      top: 0,
      zIndex: 1000,
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "12px 16px 24px",
      background: "#eee"
    },
    headerSelect: {
      display: "flex",
      alignItems: "flex-end"
    },
    timestampWrapper: {
      margin: "0 16px 16px"
    },
    noSetting: {
      padding: "80px 0",
      margin: "0 16px"
    },
    noSettingText: {
      textAlign: "center",
      fontSize: 16,
      lineHeight: 1.75
    },
    paperWrapper: {
      margin: "0 16px"
    },
    preNoRecode: {
      marginBottom: 8
    }
  });

type StateProps = {
  customRecords: CustomRecordsState;
  operations: OperationsState;
  users: UsersInFacilityState["users"];
  staff: StaffState;
  needsStopHistory: boolean;
  isEditing: boolean;
  editDate: string;
};

type DispatchProps = {
  fetchCustomRecords: (target: number) => Promise<void>;
  fetchSupportCustomRecords: () => Promise<void>;
  fetchOperations: (year: string, month: string) => Promise<void>;
  unsetOperations: () => void;
  fetchInitialOperations: () => Promise<void>;
  postCustomOperation: (
    params: RecordOperationsValues,
    initialValues: RecordOperationsValues,
    targetDate: string
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
  setEditDate: (date: string) => void;
  unsetEditDate: () => void;
  fetchHoliday: (date: string) => void;
};

type OwnProps = RouteComponentProps<{ year?: string; month?: string }> &
  WithStyles<typeof styles>;

type MergeProps = StateProps &
  DispatchProps &
  OwnProps & {
    staffOptions: FieldItem[];
    mealData: CustomRecordsState[number] | undefined;
  };

/**
 * 業務日誌
 */
const OperationsCore = ({
  classes,
  ...props
}: MergeProps): JSX.Element | null => {
  const settingType = CUSTOM_RECORD_TARGET_TYPE.operation_common;

  // カレンダー周り
  const { year, month } = props.match.params;
  const currentDate = new Date();
  const calendarMinDate = new Date(2000, 0, 1);
  const calendarMaxDate = new Date(2049, 11, 31);
  const selectedDate =
    year && month ? new Date(+year, +month - 1, 1) : currentDate;
  const selectedYear = year || selectedDate.getFullYear().toString();
  const selectedMonth = month || (selectedDate.getMonth() + 1).toString();

  const [calendar, setCalendar] = React.useState({
    year: selectedYear,
    month: selectedMonth,
    date: selectedDate
  });

  const onClickCalendar = (date: Date): void => {
    const clickYear = date.getFullYear().toString();
    const clickMonth = (date.getMonth() + 1).toString();
    setCalendar({
      date,
      year: clickYear,
      month: clickMonth
    });
  };

  const onPrintPreview = (): void => {
    // 印刷ページのstateリセット
    props.unsetOperations();
    props.history.push(
      `/record/print/operations/${selectedYear}/${selectedMonth}`
    );
  };

  // 業務日誌の項目設定取得,ユーザー取得,記録者取得 初回のみ
  React.useEffect(() => {
    props.fetchInitialOperations();
    const fetchCustomData = async (): Promise<void> => {
      await props.fetchCustomRecords(settingType);
      // 食事項目のデータを取得する
      await props.fetchSupportCustomRecords();
    };
    fetchCustomData();
  }, []);

  // 業務日誌取得 カレンダー変更ごと及び表示選択変更ごと
  React.useEffect(() => {
    props.fetchOperations(calendar.year, calendar.month);
    props.fetchHoliday(`${calendar.year}${calendar.month.padStart(2, "0")}`);
    props.history.push(
      `${RECORD_OPERATIONS}/${calendar.year}/${calendar.month}`
    );
  }, [calendar.year, calendar.month]);

  return (
    <AdminTemplate pageName="業務日誌">
      <div className={classes.stickyWrapper}>
        <div className={classes.headerSelect}>
          <UnEditableWrapper unEditable={props.isEditing}>
            <DateSelectButtonsMonthly
              selectedMonth={calendar.date}
              min={calendarMinDate}
              max={calendarMaxDate}
              onClickSubmit={onClickCalendar}
            />
          </UnEditableWrapper>
        </div>
        <KnowbeButton
          disabled={!props.operations.created_at || props.isEditing}
          onClick={onPrintPreview}
        >
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.timestampWrapper}>
        <CreateAndUpdateDate
          createdAt={props.operations.created_at}
          updatedAt={props.operations.updated_at}
        />
      </div>
      {props.operations.operation.length === 0 ? (
        <div className={classes.paperWrapper}>
          <NoRecord
            message="利用実績がありません。利用実績を入力後、ご利用ください。"
            targetRecordDate={currentDate}
          />
        </div>
      ) : (
        <OperationRecordList
          year={calendar.year}
          month={calendar.month}
          currentDate={currentDate}
          settingType={settingType}
          {...props}
        />
      )}
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords,
  operations: state.TANKINYUSHO.operations,
  users: state.TANKINYUSHO.userInFacility.users,
  staff: state.staff,
  needsStopHistory: state.ui.needsStopHistory,
  isEditing: state.pages.recordMonthly.isEditing,
  editDate: state.pages.recordMonthly.targetDate
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { TANKINYUSHO, customRecords, holiday, uiDispatch, pages } = dispatches;
  const operationsDispatches = TANKINYUSHO.operationsDispatcher(dispatch);
  const customRecordsDispatches = customRecords(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  const recordMonthlyDispatches = pages.recordMonthlyDispatcher(dispatch);
  const holidayDispatcher = holiday(dispatch);

  return {
    fetchInitialOperations: (): Promise<void> =>
      recordMonthlyDispatches.fetchTANKINYUSHOInitialOperations(),
    fetchCustomRecords: (target: number): Promise<void> =>
      customRecordsDispatches.fetchCustomRecords(target),
    fetchSupportCustomRecords: (): Promise<void> =>
      customRecordsDispatches.fetchSupportCustomRecords(),
    fetchOperations: (year: string, month: string): Promise<void> =>
      operationsDispatches.fetchOperations(year, month),
    unsetOperations: (): void => {
      dispatch(unsetOperations());
    },
    postCustomOperation: (
      params: RecordOperationsValues,
      initialValues: RecordOperationsValues,
      targetDate: string
    ): Promise<void> =>
      recordMonthlyDispatches.postTANKINYUSHOCustomOperation(
        params,
        initialValues,
        targetDate
      ),
    stopHistory: (flag: boolean): void => {
      uiDispatches.stopHistory(flag);
    },
    setEditDate: (targetDate: string): void => {
      dispatch(recordMonthlyActions.setEdit(targetDate));
    },
    unsetEditDate: (): void => {
      dispatch(recordMonthlyActions.unsetEdit());
    },
    fetchHoliday: (date: string): void => {
      holidayDispatcher.fetchHoliday(date);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const staffOptions = generateSelectFieldItems(
    stateProps.staff.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  const operationCustomRecords = stateProps.customRecords.filter(
    (record) => record.setting_type !== CUSTOM_RECORD_TARGET_TYPE.support
  );

  // 出勤者を1番, 欠勤・休暇者を2番, 記録者を最後にして他はorder順に
  const orderCustomRecords: CustomRecordsState = [];
  if (stateProps.customRecords.length) {
    const firstPosition = operationCustomRecords.filter(
      (v) =>
        v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant ||
        v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee
    );
    const lastPosition = operationCustomRecords.filter(
      (v) => v.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );
    const orderArray = operationCustomRecords
      .filter(
        (v) =>
          v.default_item !== 1 && v.default_item !== 2 && v.default_item !== 6
      )
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });
    orderCustomRecords.push(...firstPosition, ...orderArray, ...lastPosition);
  }

  const mealData =
    stateProps.customRecords.length > 0
      ? stateProps.customRecords.find(
          (record) =>
            record.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
            record.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.meal
        )
      : undefined;

  return {
    staffOptions,
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    customRecords: orderCustomRecords,
    mealData
  };
};

export const Operations = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(OperationsCore)
);
