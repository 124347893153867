import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";

export default (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g id="Bounding_Boxes">
        <g id="ui_x5F_spec_x5F_header_copy_3" display="none" />
        <path fill="none" d="M0,0h24v24H0V0z" />
      </g>
      <g id="Rounded" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_5" />
        <g display="inline">
          <path
            d="M13.25,3c-5.09-0.14-9.26,3.94-9.26,9H2.2c-0.45,0-0.67,0.54-0.35,0.85l2.79,2.8c0.2,0.2,0.51,0.2,0.71,0l2.79-2.8
        C8.46,12.54,8.23,12,7.79,12H5.99c0-3.9,3.18-7.05,7.1-7c3.72,0.05,6.85,3.18,6.9,6.9c0.05,3.91-3.1,7.1-7,7.1
        c-1.61,0-3.1-0.55-4.28-1.48c-0.4-0.31-0.96-0.28-1.32,0.08l0,0C6.97,18.03,7,18.73,7.47,19.1c1.52,1.19,3.44,1.9,5.52,1.9
        c5.05,0,9.14-4.17,9-9.26C21.86,7.05,17.94,3.13,13.25,3z"
          />
          <path
            d="M12.74,8L12.74,8c-0.41,0-0.75,0.34-0.75,0.75v3.68c0,0.35,0.19,0.68,0.49,0.86l3.12,1.85c0.36,0.21,0.82,0.09,1.03-0.26
        c0.21-0.36,0.09-0.82-0.26-1.03l-2.88-1.71v-3.4C13.49,8.34,13.16,8,12.74,8z"
          />
        </g>
      </g>
      <g id="Sharp" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_4" />
        <g display="inline">
          <path
            d="M13,3c-4.97,0-9,4.03-9,9H1l4,3.99L9,12H6c0-3.87,3.13-7,7-7s7,3.13,7,7s-3.13,7-7,7c-1.93,0-3.68-0.79-4.94-2.06
        l-1.42,1.42C8.27,19.99,10.51,21,13,21c4.97,0,9-4.03,9-9C22,7.03,17.97,3,13,3z"
          />
          <polygon points="12,8 12,13 16.25,15.52 17.02,14.24 13.5,12.15 13.5,8 		" />
        </g>
      </g>
      <g id="Outline">
        <g id="ui_x5F_spec_x5F_header" display="none" />
        <g>
          <path
            d="M13,3c-4.97,0-9,4.03-9,9H1l4,3.99L9,12H6c0-3.87,3.13-7,7-7s7,3.13,7,7s-3.13,7-7,7c-1.93,0-3.68-0.79-4.94-2.06
        l-1.42,1.42C8.27,19.99,10.51,21,13,21c4.97,0,9-4.03,9-9C22,7.03,17.97,3,13,3z"
          />
          <polygon points="12,8 12,13 16.25,15.52 17.02,14.24 13.5,12.15 13.5,8 		" />
        </g>
      </g>
      <g id="Duotone" display="none">
        <g id="ui_x5F_spec_x5F_header_copy_2" />
        <g display="inline">
          <path
            d="M13,3c-4.97,0-9,4.03-9,9H1l4,3.99L9,12H6c0-3.87,3.13-7,7-7s7,3.13,7,7s-3.13,7-7,7c-1.93,0-3.68-0.79-4.94-2.06
        l-1.42,1.42C8.27,19.99,10.51,21,13,21c4.97,0,9-4.03,9-9C22,7.03,17.97,3,13,3z"
          />
          <polygon points="12,8 12,13 16.25,15.52 17.02,14.24 13.5,12.15 13.5,8 		" />
        </g>
      </g>
      <g id="Material" display="none">
        <g id="ui_x5F_spec_x5F_header_copy" />
        <g display="inline">
          <path
            d="M13,3c-4.97,0-9,4.03-9,9H1l4,3.99L9,12H6c0-3.87,3.13-7,7-7s7,3.13,7,7s-3.13,7-7,7c-1.93,0-3.68-0.79-4.94-2.06
        l-1.42,1.42C8.27,19.99,10.51,21,13,21c4.97,0,9-4.03,9-9C22,7.03,17.97,3,13,3z"
          />
          <polygon points="12,8 12,13 16.25,15.52 17.02,14.24 13.5,12.15 13.5,8 		" />
        </g>
      </g>
    </SvgIcon>
  );
};
