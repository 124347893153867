import React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSelect from "@components/molecules/FormikSelect";
import { CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/v202104/mgr/CHIIKITEICHAKU/report/initial";

const styles = (): StyleRules =>
  createStyles({
    cell: {
      boxSizing: "border-box",
      padding: "16px 8px",
      height: 56
    },
    middleCell: {
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      "&:last-child": {
        paddingRight: 16
      }
    },
    longCell: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      "&:last-child": {
        paddingRight: 16
      }
    },
    baseCell: {
      paddingLeft: 4,
      paddingRight: 4,
      lineHeight: "20px"
    },
    root: {
      fontSize: 16
    }
  });

type OwnProps = {
  formikProps: FormikProps<InitialValues>;
  idx: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportEditCellCore = (props: Props): JSX.Element => {
  const { idx, classes } = props;
  const fieldName = `initial.inoutConsultationResults[${idx}]`;
  const checkBoxStyle = { position: "absolute" as const, margin: "-25px 0 0" };
  return (
    <>
      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        <FormikSelect
          name={`${fieldName}.status`}
          label=""
          style={{ width: 130 }}
          options={CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST}
        />
      </TableCellWrap>

      {/* 居住支援連携体制 */}
      <TableCellWrap
        key={`${idx}-housing-support-cooperation-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        <FormikCheckbox
          name={`${fieldName}.housingSupportCooperationFlg`}
          label=""
          focusVisibleClassName={classes.root}
          style={checkBoxStyle}
        />
      </TableCellWrap>

      {/* 地域居住支援体制強化推進 */}
      <TableCellWrap
        key={`${idx}-housing-support-promotion-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        <FormikCheckbox
          name={`${fieldName}.housingSupportPromotionFlg`}
          label=""
          focusVisibleClassName={classes.root}
          style={checkBoxStyle}
        />
      </TableCellWrap>

      {/* 日常生活支援情報提供 */}
      <TableCellWrap
        key={`${idx}-daily-support-provision-of-info-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        <FormikCheckbox
          name={`${fieldName}.dailySupportProvisionOfInfoFlg`}
          label=""
          focusVisibleClassName={classes.root}
          style={checkBoxStyle}
        />
      </TableCellWrap>

      {/* 備考 */}
      <TableCellWrap
        key={`${idx}-memo`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        <FormikTextField
          name={`${fieldName}.memo`}
          label=""
          required={false}
          placeholder="-"
          size="fullSize"
          style={{ marginBottom: 0 }}
        />
      </TableCellWrap>
    </>
  );
};

export const InOutReportEditCell = withStyles(styles)(InOutReportEditCellCore);
