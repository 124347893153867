/**
 * boolean => "0" or "1"
 */
const booleanToString0or1 = (
  value: boolean | undefined
): string | undefined => {
  return value !== undefined ? `${+value}` : value;
};

export default booleanToString0or1;
