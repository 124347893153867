import {
  InitialMonitoringMonthValues,
  InitialMonitoringMonthErrors
} from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";
import { dateValidator, validateSwitcher } from "@validator";

const monitoringMonthValidation = (
  values: InitialMonitoringMonthValues
): InitialMonitoringMonthErrors => {
  return {
    monitoring_month: values.monitoring_month.map((v, index, array) => {
      return {
        year_month: validateSwitcher(
          v.year_month.year !== "" || v.year_month.month !== "",
          dateValidator(
            { ...v.year_month, day: "1" },
            {
              type: "requiredDateAnyMessage",
              message: "年月どちらも入力してください"
            },
            { type: "registeredYearMonth", index, array }
          )
        )
      };
    })
  };
};

export const validation = (
  values: InitialMonitoringMonthValues
): InitialMonitoringMonthErrors => {
  const basicErrors = monitoringMonthValidation(values);
  return {
    ...basicErrors
  };
};
