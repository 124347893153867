import * as React from "react";
import * as URL from "@constants/url";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { ConsultationForm } from "@components/organisms/mgr/KEIKAKUSODAN/record/consultation/ConsultationForm";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import * as consultationActions from "@stores/domain/mgr/KEIKAKUSODAN/consultation/actions";
import { UsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import {
  CONSULTATION_TARGET_TITLE,
  CONSULTATION_TARGET_TITLE_SHOGAIJISODAN,
  PARAMETER_TYPE_KEIKAKUSODAN,
  PARAMETER_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { getUrlParams } from "@utils/url";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  targetFlg: string;
  type: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  user: UsersInFacilityState["user"];
  facilityType: number | null;
};

type DispatchProps = {
  unsetConsultation: () => void;
  fetchOneUser: (id: string) => void;
  fetchStaffs: () => Promise<void>;
  fetchPlanMonitoringMeeting: (uifId: string) => void;
  fetchFacility: () => void;
};

type Props = OwnProps & StateProps & DispatchProps;

const ConsultationNewCore = (props: Props): JSX.Element => {
  const { history, classes, user, facilityType } = props;
  const { uifId, targetFlg } = props.match.params;
  const consultationTargetFlg = +targetFlg;
  const userInFacilityId = user.user_in_facility.id;
  const { search } = props.location;

  React.useEffect(() => {
    props.fetchFacility();
  }, []);

  // ページ名
  const listName = "計画・モニタリング・会議記録";

  const getTypeConsultation = (): number => {
    if (facilityType === FACILITY_TYPE_KEIKAKUSODAN) {
      return 1;
    }
    if (facilityType === FACILITY_TYPE_SHOGAIJISODAN) {
      return 2;
    }
    return 0;
  };

  const [typeConsultation, setTypeConsultation] = React.useState(
    getTypeConsultation()
  );
  React.useEffect(() => {
    setTypeConsultation(getTypeConsultation());
  }, [facilityType]);

  let pageName = "";
  // 施設区分が計画相談（１）の場合
  if (typeConsultation === 1) {
    pageName = CONSULTATION_TARGET_TITLE[`${consultationTargetFlg}`];
    // 施設区分が障害児相談（２）の場合
  } else if (typeConsultation === 2) {
    pageName =
      CONSULTATION_TARGET_TITLE_SHOGAIJISODAN[`${consultationTargetFlg}`];
  }
  React.useEffect(() => {
    if (facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN) {
      const queryParameter: {
        type?: string;
      } = getUrlParams(search);
      if (queryParameter.type) {
        // 施設区分が計画・障害児相談（３）かつ相談種別が計画相談（１）の場合
        if (queryParameter.type === PARAMETER_TYPE_KEIKAKUSODAN) {
          setTypeConsultation(1);
          pageName = CONSULTATION_TARGET_TITLE[`${consultationTargetFlg}`];
          // 施設区分が計画・障害児相談（３）かつ相談種別が障害児相談（２）の場合
        } else if (queryParameter.type === PARAMETER_TYPE_SHOGAIJISODAN) {
          setTypeConsultation(2);
          pageName =
            CONSULTATION_TARGET_TITLE_SHOGAIJISODAN[`${consultationTargetFlg}`];
        } else {
          // typeが渡されていて上記以外の値の場合は一覧画面へ戻す
          history.push(`/record/support_plan/${uifId}/assessment`);
        }
      }
    }
  }, [search]);

  const pathList = [
    {
      pathName: "利用者ごと",
      path: URL.RECORD_USERS_SUMMARY
    },
    {
      pathName: listName,
      path: `/record/${uifId}/plan_monitoring_meeting`
    }
  ];

  // fetch
  React.useEffect(() => {
    props.unsetConsultation();
    props.fetchOneUser(uifId);
    props.fetchStaffs();
    props.fetchPlanMonitoringMeeting(uifId);
    props.fetchFacility();
  }, []);

  if (!userInFacilityId) {
    return <></>;
  }

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <UserInfoRecord user={user} />
        {/* フォーム */}
        <div className={classes.con}>
          <ConsultationForm
            user={user}
            userInFacilityId={userInFacilityId}
            history={history}
            pageName={pageName}
            consultationTargetFlg={consultationTargetFlg}
            facilityType={facilityType}
            type={typeConsultation}
            isNew
          />
        </div>
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.KEIKAKUSODAN.userInFacility.user,
    facilityType: state.KEIKAKUSODAN.facility.facilityType
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { KEIKAKUSODAN, staffDispatcher } = dispatches;
  const planMonitoringMeetingDispatcher = KEIKAKUSODAN.planMonitoringMeetingDispatcher(
    dispatch
  );
  return {
    unsetConsultation: (): void => {
      dispatch(consultationActions.unsetConsultation());
    },
    fetchOneUser: (id: string): void => {
      KEIKAKUSODAN.userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchStaffs: staffDispatcher(dispatch).fetch,
    fetchPlanMonitoringMeeting: (uifId: string): void => {
      planMonitoringMeetingDispatcher.fetchPlanMonitoringMeeting(uifId);
    },
    fetchFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

export const ConsultationNew = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(ConsultationNewCore)
);
