import * as React from "react";
import AdminRoute from "@app/Auth/AdminRoute";

import Download from "@components/pages/download/Download";
import DownloadIdoshien from "@components/pages/mgr/IDOSHIEN/download/Download";
import JissekiPrint from "@components/pages/download/print/JissekiPrint";
import SeikyuPrint from "@components/pages/download/print/SeikyuPrint";
import UserSeikyuPrint from "@components/pages/download/print/UserSeikyuPrint";
import UserReceiptPrint from "@components/pages/download/print/UserReceiptPrint";
import UserAgencyReceiptPrint from "@components/pages/download/print/UserAgencyReceiptPrint";
import CostAmountListPrint from "@components/pages/download/print/CostAmountListPrint";
import UplimitPrint from "@components/pages/download/print/UplimitPrint";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";

/**
 * 請求機能
 */
const DownloadRoutes = (user: UserState): JSX.Element[] => [
  <AdminRoute
    key="JissekiPrint"
    exact
    path="/download/print/jisseki/:year/:month"
    component={JissekiPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="SeikyuPrint"
    exact
    path="/download/print/seikyu/:year/:month"
    component={SeikyuPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="UserSeikyuPrint"
    exact
    path="/download/print/user/seikyu/:dataKey"
    component={UserSeikyuPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="UserReceiptPrint"
    exact
    path="/download/print/user/receipt/:dataKey"
    component={UserReceiptPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="UserAgencyReceiptPrint"
    exact
    path="/download/print/user/agency_receipt/:dataKey"
    component={UserAgencyReceiptPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="CostAmountListPrint"
    exact
    path="/download/print/user_cost_amount_list/:year/:month"
    component={CostAmountListPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="UplimitPrint"
    exact
    path="/download/print/uplimit/:year/:month"
    component={UplimitPrint}
    groupInvoiceCheck
  />,
  <AdminRoute
    key="Download"
    exact
    path="/download"
    component={
      user.facility_type === FacilityType.IDOSHIEN ? DownloadIdoshien : Download
    }
    groupInvoiceCheck
  />
];

export default DownloadRoutes;
