import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";

import { DISABILITY_CLASS_LIST } from "@constants/variables";
import { UsersValues } from "@initialize/v202104/mgr/CHIIKITEICHAKU/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    groupDate: {
      margin: "6px 0 0 16px"
    },
    fieldWrapper: {
      position: "relative"
    },
    date: {
      marginBottom: 12
    },
    tipsServiceStart: {
      marginLeft: 120,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    },
    tipsPayStart: {
      marginLeft: 100,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    }
  });

type OwnProps = {
  formikProps: FormikProps<UsersValues>;
  isFetchDone?: boolean;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const ServiceUseFieldsCore = (props: Props): JSX.Element => {
  const [shouldFirstSetup, setShouldFirstSetup] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (!shouldFirstSetup || !props.isFetchDone) return;
    setShouldFirstSetup(false);
  }, []);

  const startAddYearTo = 1;
  const endAddYearTo = 5;

  const tipsDisabilityClass = (
    <HelpToolTip title={<HelpTipMessages name="disabilityClass" />} />
  );

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="サービス利用詳細" />
      </div>
      <Typography className={props.classes.date}>サービス提供期間</Typography>
      <div className={props.classes.groupDate}>
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.inServiceStartDate"
          label="サービス提供開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsServiceStart}>
              <HelpToolTip
                title={<HelpTipMessages name="inServiceStartDateOfVisit" />}
              />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.inServiceEndDate"
          label="サービス提供終了日"
          addYearTo={endAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <Typography className={props.classes.date}>受給者証</Typography>
      <div className={props.classes.groupDate}>
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.payStartDate"
          label="支給決定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsPayStart}>
              <HelpToolTip title={<HelpTipMessages name="pay_start_date" />} />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.payEndDate"
          label="支給決定終了日"
          addYearTo={endAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <FormGroup>
        <div className={props.classes.fieldWrapper}>
          <FormikRadioButtons
            name="serviceUse.disabilityClass"
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
            tooltip={tipsDisabilityClass}
          />
        </div>
      </FormGroup>
      <FormikSwitch name="serviceUse.specialAreaFlag" label="特別地域加算">
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.specialAreaStartDate"
          label="適用開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name="serviceUse.specialAreaEndDate"
          label="適用終了日"
          style={{ marginBottom: 0 }}
          addYearTo={endAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
    </FormPaper>
  );
};

export const ServiceUseFields = withStyles(styles)(ServiceUseFieldsCore);
