import * as types from "./types";
import { GetUsersSummaryTANKINYUSHO } from "@api/requests/supports/TANKINYUSHO/getUsersSummaryTANKINYUSHO";

export const fetchUsersSummaryTANKINYUSHOStarted = () =>
  ({ type: types.FETCH_USERS_SUMMARY_TANKINYUSHO_STARTED } as const);
export const fetchUsersSummaryTANKINYUSHOSuccess = (
  res: GetUsersSummaryTANKINYUSHO["data"]
) =>
  ({
    type: types.FETCH_USERS_SUMMARY_TANKINYUSHO_SUCCESS,
    payload: res
  } as const);
export const fetchUsersSummaryTANKINYUSHOFailed = (err: unknown) =>
  ({
    type: types.FETCH_USERS_SUMMARY_TANKINYUSHO_FAILED,
    error: err
  } as const);
export const unsetUsersSummaryTANKINYUSHO = () =>
  ({ type: types.UNSET_USERS_SUMMARY_TANKINYUSHO } as const);

export type ActionTypes =
  | ReturnType<typeof fetchUsersSummaryTANKINYUSHOStarted>
  | ReturnType<typeof fetchUsersSummaryTANKINYUSHOSuccess>
  | ReturnType<typeof fetchUsersSummaryTANKINYUSHOFailed>
  | ReturnType<typeof unsetUsersSummaryTANKINYUSHO>;
