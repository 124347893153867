import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      "& > div > div": {
        marginLeft: 0,
        marginBottom: 0
      }
    }
  });

type OwnProps = {
  value: string;
  changeFormName: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const PrintFormName = (props: Props): JSX.Element => {
  return (
    <MuiRadioButtons
      label=""
      options={[
        { label: "支援記録", value: "support_record" },
        { label: "サービス提供記録", value: "service_record" }
      ]}
      value={props.value}
      onChange={(e): void =>
        props.changeFormName(e as React.ChangeEvent<HTMLInputElement>)
      }
    />
  );
};

export default withStyles(styles)(PrintFormName);
