import * as types from "./types";
import * as actions from "./actions";

const initialState: types.PaginatedDailySupportsState = {
  facility_unit_id: 0,
  page: 0,
  keyword: "",
  is_record: 0,
  support_count: 0,
  support: []
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.PaginatedDailySupportsState => {
  switch (action.type) {
    case types.FETCH_PAGINATED_DAILY_SUPPORTS_STARTED:
      return state;
    case types.FETCH_PAGINATED_DAILY_SUPPORTS_SUCCESS:
      return { ...action.payload };
    case types.FETCH_PAGINATED_DAILY_SUPPORTS_FAILED:
      return state;
    default:
      return state;
  }
};

export default reducer;
