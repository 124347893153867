import { GetOperations } from "@api/requests/operations/getCustomRecordOperations";

/**
 * ActionNames
 */
export const FETCH_OPERATIONS_STARTED =
  "GROUP_HOME/OPERATIONS/FETCH_OPERATIONS_STARTED";
export const FETCH_OPERATIONS_SUCCESS =
  "GROUP_HOME/OPERATIONS/FETCH_OPERATIONS_SUCCESS";
export const FETCH_OPERATIONS_FAILED =
  "GROUP_HOME/OPERATIONS/FETCH_OPERATIONS_FAILED";
export const UNSET_OPERATIONS = "GROUP_HOME/OPERATIONS/UNSET_OPERATIONS";

/**
 * State
 */
export type OperationsState = GetOperations["data"];
