import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import { FormikProps } from "formik";

import { Assessment } from "@initialize/record/assessment/initialValues";

import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import MuiRadioButtons from "@components/molecules/MuiRadioButtons";

import { FieldItem } from "@interfaces/ui/form";

const styles = (): StyleRules =>
  createStyles({
    radioRow: {
      marginTop: "32px",
      display: "flex",
      alignItems: "flex-end"
    },
    clearButton: {
      marginBottom: "32px"
    }
  });

type OwnProps = {
  choiceName: string;
  choiceValue: string;
  options: FieldItem[];
  textName: string;
  textValue: string;
  formikProps: FormikProps<Assessment>;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const AssessmentSheetFormChiikiikoOpinionsCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  /** 選択解除ボタン押下 */
  const handleClickClearChoose = (): void => {
    props.formikProps.setFieldValue(props.choiceName, "");
  };

  return (
    <div>
      <div className={classes.radioRow}>
        {props.isEditing ? (
          <FormikRadioButtons
            style={{
              flexDirection: "row",
              marginBottom: "32px",
              fontSize: "16px"
            }}
            name={props.choiceName}
            label="意思"
            options={props.options}
          />
        ) : (
          <MuiRadioButtons
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "8px 0 32px 16px",
              fontSize: "16px"
            }}
            label="意思"
            options={props.options}
            value={props.choiceValue || ""}
            disabled
          />
        )}
        {props.isEditing && (
          <KnowbeButton
            className={classes.clearButton}
            kind="outlineMini"
            onClick={handleClickClearChoose}
            disabled={props.choiceValue === ""}
          >
            選択解除
          </KnowbeButton>
        )}
      </div>
      <RecordTextField
        name={props.textName}
        labelType="default"
        label="理由・詳細"
        defaultValue=""
        value={props.textValue}
        placeholder=""
        isEditable={props.isEditing}
      />
    </div>
  );
};

export const AssessmentSheetFormChiikiikoOpinions = withStyles(styles)(
  AssessmentSheetFormChiikiikoOpinionsCore
);
