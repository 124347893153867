import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserSummaryState } from "@stores/domain/userSummary/types";
import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import ClassNames from "classnames";
import {
  SUPPORT_METHOD_ITEMS,
  RECORD_TYPE_SHUROTEICHAKU
} from "@constants/mgr/SHUROTEICHAKU/variables";

// utils
import { dateToLocalisedString } from "@/utils/date";
import { getUrlParams } from "@/utils/url";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 20
    },
    headerInfo: {
      width: 430
    },
    title: {
      margin: 0,
      marginTop: 14,
      fontSize: 18,
      fontWeight: "normal",
      letterSpacing: 1.2,
      color: "#212121"
    },
    userBasicInfo: {
      marginTop: 28
    },
    footer: {
      marginTop: 24
    },
    basicInfo: {
      letterSpacing: "0.1em",
      fontSize: 0,
      "&:last-child": {
        marginTop: 4
      }
    },
    basicInfoText: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      "&:first-child": {
        marginRight: 20
      },
      "&:nth-child(2)": {
        marginRight: 24
      }
    },
    tableContainer: {
      fontSize: 10,
      marginBottom: 20,
      whiteSpace: "pre-line"
    },
    tableTitle: {
      fontWeight: "bold",
      borderBottom: "1px solid #000",
      paddingBottom: 5,
      marginBottom: 8,
      display: "flex",
      color: "rgba 0 0 0, 0.87",
      justifyContent: "space-between"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      tableLayout: "fixed",
      wordWrap: "break-word",
      overflowWrap: "break-word",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      color: "#212121",
      "&.fullWidth": {
        width: "100%"
      },
      "& th": {
        fontWeight: "normal",
        borderRight: "1px solid",
        padding: "3px 8px",
        verticalAlign: "top"
      },
      "& td": {
        padding: "4px 12px",
        height: 24,

        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.small": {
          height: 100
        },
        "&.middle": {
          height: 200
        },
        "&.large": {
          height: 300
        },
        "&.sssize": {
          width: 40
        },
        "&.ssize": {
          width: 60
        },
        "&.msize": {
          width: 80
        },
        "&.lsize": {
          width: 100
        },
        "&.llsize": {
          width: 180
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderDashed": {
          borderRight: "1px dashed"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    primaryTableHead: {
      width: 120
    },
    headerDate: {
      marginTop: 19
    },
    headerDateText: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.1em",
      textAlign: "right"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * type
 */

type StateProps = {
  supportRecords: UserSummaryState["supportRecords"];
};
type OwnProps = {
  year: string;
  month: string;
  query: string;
  user: UserState;
};

type DispatchProps = {
  getUserSummarySupportRecord: (
    year: string,
    month: string,
    excludedUserIds: string,
    type: string
  ) => void;
};
type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  report: Exclude<
    UserSummaryState["supportRecords"][0]["report"],
    undefined
  >[0];
  year: string;
  month: string;
  user: UserState;
};
type SectionTableOwnProps = {
  sectionTitle?: string;
  sectionData: {
    itemTitle?: string;
    content: string | null;
    borderBottomBold?: boolean;
  }[];
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

type SectionTableProps = SectionTableOwnProps & WithStyles<typeof styles>;

const UsersSummaryReportSHUROTEICHAKUPrintCore = (
  props: Props
): JSX.Element => {
  React.useEffect(() => {
    const { year, month, query } = props;
    const queryParameters: {
      excluded_user_ids?: string;
    } = getUrlParams(query);
    let excludedUserIds = "";
    if (queryParameters.excluded_user_ids) {
      excludedUserIds = queryParameters.excluded_user_ids;
    }
    props.getUserSummarySupportRecord(
      year,
      month,
      excludedUserIds,
      RECORD_TYPE_SHUROTEICHAKU.REPORT
    );
  }, []);

  const { classes, supportRecords, user } = props;

  return (
    <>
      {supportRecords.map((supportRecord) => {
        if (!supportRecord.report) {
          return null;
        }
        return (
          <Sheet
            classes={classes}
            report={supportRecord.report[0]}
            year={props.year}
            month={props.month}
            user={user}
            key={`users_summary_report_print_${supportRecord.report[0].uif_id}`}
          />
        );
      })}
    </>
  );
};

const SectionTable = (props: SectionTableProps): JSX.Element => {
  const { classes, sectionTitle, sectionData } = props;

  return (
    <section>
      {sectionTitle && (
        <div className={classes.tableTitle}>
          <span>{sectionTitle}</span>
        </div>
      )}

      <table className={`${classes.table} fullWidth`}>
        <tbody>
          {sectionData.map((data, index) => {
            const keyNumber = index;
            return (
              <tr
                key={keyNumber}
                className={data.borderBottomBold ? "borderBold" : ""}
              >
                {sectionTitle !== "共有事項" && (
                  <th className={ClassNames(classes.primaryTableHead)}>
                    {data.itemTitle}
                  </th>
                )}
                <td>{data.content}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </section>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, report, year, month, user } = props;

  // 担当者
  const author = report.author_name ? report.author_name : "-";

  // 雇用事業主
  const companyName = report.company_name ? report.company_name : "-";

  // ご担当者
  const companyPerson = report.responsible_person
    ? report.responsible_person
    : "-";

  // 支援実績
  let supportData = report.usage_result.map((result) => {
    const supportDateTime = `${dateToLocalisedString(
      result.target_date,
      "M月D日"
    )}`;
    const supportMethod =
      result.support_method === 6
        ? result.support_method_other
        : SUPPORT_METHOD_ITEMS[result.support_method].label.replace(
            /\r?\n/g,
            ""
          );

    return {
      itemTitle: supportDateTime,
      content: result.support_method === 0 ? "" : supportMethod
    };
  });

  if (supportData.length === 0) {
    supportData = [
      {
        itemTitle: "",
        content: ""
      }
    ];
  }

  // 当月の主な支援目標
  const goalData = [
    {
      itemTitle: "①",
      content: report.goal_1
    },
    {
      itemTitle: "②",
      content: report.goal_2
    },
    {
      itemTitle: "③",
      content: report.goal_3
    }
  ];

  // 当月の主な支援目標
  const situationsData = [
    {
      itemTitle: "支援実施内容 ①",
      content: report.content1
    },
    {
      itemTitle: "支援結果",
      content: report.result1,
      borderBottomBold: true
    },
    {
      itemTitle: "支援実施内容 ②",
      content: report.content2
    },
    {
      itemTitle: "支援結果",
      content: report.result2,
      borderBottomBold: true
    },
    {
      itemTitle: "支援実施内容 ③",
      content: report.content3
    },
    {
      itemTitle: "支援結果",
      content: report.result3,
      borderBottomBold: true
    }
  ];

  // 今後の方向性
  const nextSupportData = [
    {
      itemTitle: "今後の支援内容",
      content: report.future_support_content,
      borderBottomBold: true
    },
    {
      itemTitle: "対象者の取組",
      content: report.person_behavior
    },
    {
      itemTitle: "事業主の取組",
      content: report.facility_behavior
    },
    {
      itemTitle: "関係機関等の取組",
      content: report.related_organizations_behavior
    }
  ];

  // 共有事項
  const sharedContentData = [
    {
      content: report.shared_content
    }
  ];

  return (
    <div className={classes.page}>
      <div className={classes.header}>
        <div className={classes.headerInfo}>
          <h1 className={classes.title}>就労定着支援 支援レポート</h1>
          <div className={classes.userBasicInfo}>
            <div className={classes.basicInfo}>
              <span className={classes.basicInfoText}>
                {`利用者名: ${report.name}`}
              </span>
            </div>
            <div className={classes.basicInfo}>
              <span className={classes.basicInfoText}>
                {`雇用事業主（勤務先） : ${companyName}`}
              </span>
              <span
                className={classes.basicInfoText}
              >{`ご担当者 : ${companyPerson}`}</span>
            </div>
          </div>
        </div>
        <div className={classes.headerDate}>
          <div className={classes.headerDateText}>
            {`作成日 : ${
              report.creation_date
                ? dateToLocalisedString(report.creation_date, "YYYY年M月D日")
                : "-"
            }`}
          </div>
          <div className={classes.headerDateText}>
            {`雇用開始日 : ${
              report.working_start_date
                ? dateToLocalisedString(
                    report.working_start_date,
                    "YYYY年M月D日"
                  )
                : "-"
            }`}
          </div>
        </div>
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle={`${year}年${month}月の支援実績`}
          sectionData={supportData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="当月の主な支援目標"
          sectionData={goalData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="当月の支援状況"
          sectionData={situationsData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="今後の方向性"
          sectionData={nextSupportData}
        />
      </div>

      <div className={classes.tableContainer}>
        <SectionTable
          classes={classes}
          sectionTitle="共有事項"
          sectionData={sharedContentData}
        />
      </div>

      <div className={classes.footer}>
        <div className={classes.basicInfo}>
          <span className={classes.basicInfoText}>
            {`事業所名: ${user.facility_name}`}
          </span>
          <span className={classes.basicInfoText}>{`担当者 : ${author}`}</span>
        </div>
        <div className={classes.basicInfo}>
          <span className={classes.basicInfoText}>{`対象者提示日 : ${
            report.presentation_date
              ? dateToLocalisedString(report.presentation_date, "YYYY年M月D日")
              : "-"
          }`}</span>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  return {
    supportRecords: state.userSummary.supportRecords
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const userSummaryDispatches = dispatches.userSummaryDispatcher(dispatch);

  return {
    getUserSummarySupportRecord: (
      year: string,
      month: string,
      excludedUserIds: string,
      type: string
    ): Promise<void> =>
      userSummaryDispatches.fetchUserSummarySupportReports(
        year,
        month,
        excludedUserIds,
        type
      )
  };
};

export const UsersSummaryReportSHUROTEICHAKUPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(UsersSummaryReportSHUROTEICHAKUPrintCore));
