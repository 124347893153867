import * as React from "react";
import { useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import WelfareSpecialistPlacementTypeFields from "@components/organisms/mgr/common/facility/items/WelfareSpecialistPlacementTypeFields";
import {
  StaffTreatmentSystemTypes,
  StaffTreatmentSpecificSystemTypes,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DEFAULT_RADIO_VALUE,
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";

import {
  shortStayTypes,
  supportForMentallyIllDisChargeSystemTypes
} from "@constants/mgr/JIRITSUKUNRENSEIKATSU/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import FormikTextField from "@components/molecules/FormikTextField";
import { getIn, FormikProps } from "formik";
import { FacilityValues } from "@initialize/mgr/JIRITSUKUNRENSEIKATSU/facility/initialValues";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import SeeHearTeam202404Fields from "@components/organisms/mgr/common/facility/items/SeeHeareTeamFields";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);
const shortStayTypeRadioItems = generateRadioItems(shortStayTypes);
const supportForMentallyIllDisChargeSystemTypeRadioItems = generateRadioItems(
  supportForMentallyIllDisChargeSystemTypes
);

const staffTreatmentSpecificSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSpecificSystemTypes
);

const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionTypes2024
);

const SpecificBetterSupporterConditionType2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionSubTypes2024
);

const SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

interface Props {
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
}
const AdditionalItemFields: React.FunctionComponent<Props> = (props) => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = useState(false);

  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );
  useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
  }, [staffTreatmentSystemType]);

  // 就労移行支援体制加算 Switch ON/OFF
  const employmentTransitionSupportFlag: string = getIn(
    props.formikProps.values,
    "additionalItem.employmentTransitionSupportFlag"
  );
  useEffect(() => {
    if (!employmentTransitionSupportFlag) {
      props.formikProps.setFieldValue(
        "additionalItem.numberOfContinuations",
        ""
      );
    }
  }, [employmentTransitionSupportFlag]);

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (
      ![`${SELECTED_CONDITION_TYPE_2024_V}`].includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      )
    ) {
      props.setFormikFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  // デフォルト値に戻す
  const onChangeHigherBrainDysfunction = (): void => {
    if (
      getIn(
        props.formikProps.values,
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg"
      )
    ) {
      props.setFormikFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        false
      );
      props.formikProps.setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFrom",
        ""
      );
      props.formikProps.setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportTo",
        ""
      );
    } else {
      props.setFormikFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        true
      );
    }
  };

  useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.formikProps.setFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.formikProps.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        {/* 福祉専門職員配置等加算 */}
        <WelfareSpecialistPlacementTypeFields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="welfareSpecialistPlacementType2" />}
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.nursingSupporterFlag"
          label="看護職員配置体制加算"
        />
        {/* 視覚・聴覚言語障害者支援体制加算 */}
        <SeeHearTeam202404Fields formikProps={props.formikProps} />

        <FormikSwitch
          name="additionalItem.higherBrainDysfunctionPersonsSupportFlg"
          label="高次脳機能障害者支援体制加算"
          onChange={onChangeHigherBrainDysfunction}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>

        <FormGroup style={{ marginBottom: 28 }}>
          <FormikRadioButtons
            name="additionalItem.specificBetterSupporterCondition202404"
            style={{ marginBottom: 0 }}
            label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
            options={
              props.formikProps.values.basic.executeKaizenFlg
                ? SpecificBetterSupporterConditionType2024RadioItemsDisabled
                : SpecificBetterSupporterConditionType2024RadioItems
            }
            onClick={onChangeSpecificBetterSupporterConditionType2024}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                }
              />
            }
          />
          {props.formikProps.values.additionalItem
            .specificBetterSupporterCondition202404 ===
            `${SELECTED_CONDITION_TYPE_2024_V}` && (
            <FormikRadioButtons
              style={{ marginLeft: 48, marginTop: -2 }}
              name="additionalItem.specificBetterSupporterCondition202404Sub"
              label=""
              options={
                props.formikProps.values.basic.executeKaizenFlg
                  ? SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled
                  : SpecificBetterSupporterConditionTypeSub2024RadioItems
              }
            />
          )}
        </FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikSwitch
          name="additionalItem.employmentTransitionSupportFlag"
          label="就労移行支援体制加算"
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="employmentTransitionSupportType" />}
            />
          }
        >
          <FormGroup row>
            <FormikTextField
              name="additionalItem.numberOfContinuations"
              label="前年度実績の6ヶ月以上就労継続者数"
              endAdornmentLabel="名"
              placeholder="0"
              maxLength={3}
              style={{ width: 280, marginBottom: 8 }}
            />
          </FormGroup>
        </FormikSwitch>
        <FormikRadioButtons
          name="additionalItem.shortStayType"
          label="短期滞在加算"
          options={shortStayTypeRadioItems}
        />
        <FormikRadioButtons
          name="additionalItem.supportForMentallyIllDisChargeSystemType"
          label="精神障害者退院支援体制加算"
          options={supportForMentallyIllDisChargeSystemTypeRadioItems}
        />
      </FormGroup>
    </FormPaper>
  );
};

export default AdditionalItemFields;
