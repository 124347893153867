import convertMinutesToTime from "@utils/date/convertMinutesToTime";

const formatMinutes = (minutes: number): string => {
  const time = convertMinutesToTime(minutes);

  // 1時間以上の場合
  if (Number(time.hour) > 0) {
    return `${time.hour}時間${time.minutes}分`;
  }

  return `${time.minutes}分`;
};

export default formatMinutes;
