import * as React from "react";
import * as H from "history";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { InOutReportMonthlyHeader } from "@components/organisms/mgr/CHIIKITEICHAKU/report/InOutReportMonthlyHeader";
import { InOutReportTable } from "@components/organisms/mgr/CHIIKITEICHAKU/report/InOutReportTable";
import { InOutReportHeaderEdit } from "@components/organisms/mgr/CHIIKITEICHAKU/report/InOutReportHeaderEdit";
import {
  InitialDataValues,
  initialValues
} from "@initialize/mgr/CHIIKITEICHAKU/report/initialValues";
import {
  validation,
  submitValidation
} from "@initialize/mgr/CHIIKITEICHAKU/report/validation";
import { toEffectiveObject } from "@utils/object";
import { InitialValues } from "@interfaces/mgr/CHIIKITEICHAKU/report/initial";
import { Formik, Form, FormikActions } from "formik";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import { ReportState } from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { OptionInterface } from "@components/atoms/DropDown";
import format from "date-fns/format";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    }
  });

type OwnProps = {
  initialDate: Date;
  history: H.History;
  currentPageVersion: number;
};

type StateProps = {
  inOutResultList: ReportState;
  usersInFacilityState: UsersInFacilityState;
  inoutErrors: ErrorsState["inout"];
};

type DispatchProps = {
  fetchFacility: () => void;
  fetchReportUser: (uifId: number, date: Date) => Promise<void>;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  openErrorsDialog: () => void;
  postCHIIKITEICHAKUReport: (
    uifId: number,
    date: Date,
    formValue: InitialValues,
    reportValue: ReportState
  ) => Promise<void>;
  postCHIIKITEICHAKUReportMonthly: (
    uifId: number,
    date: Date,
    formValue: boolean
  ) => Promise<void>;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績(月ごと）
 */
const InOutReportMonthlyCore = (props: Props): JSX.Element => {
  const { classes, inoutErrors, inOutResultList, usersInFacilityState } = props;
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [selectedMonth, setSelectedMonth] = React.useState<Date>(
    props.initialDate
  );
  const [selectedUser, setSelectedUser] = React.useState<OptionInterface>({
    label: "",
    value: ""
  });
  const [headerHeight, setHeaderHeight] = React.useState<number>(0);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);

  React.useEffect(() => {
    Promise.all([props.fetchFacility(), props.fetchInoutError(selectedMonth)]);
  }, []);

  React.useEffect((): void => {
    const top = document.getElementById("reportMonthlyHeader");
    if (top && top.clientHeight !== headerHeight) {
      setHeaderHeight(top.clientHeight);
    }
  }, [document.getElementById("reportMonthlyHeader"), inoutErrors]);

  const onSubmitMonthly = async (values: boolean): Promise<void> => {
    await props.postCHIIKITEICHAKUReportMonthly(
      +selectedUser.value,
      selectedMonth,
      values
    );
  };

  const onCheckBoxFlg = (checkFlag: boolean): void => {
    onSubmitMonthly(checkFlag);
  };

  const onDefaultCheck = (): boolean => {
    const noConstantContactList = inOutResultList.inoutConsultationResults.filter(
      (x) => x.noConstantContactSystemFlg === true
    );
    return noConstantContactList.length > 0;
  };

  const onChangeMonth = async (
    date: Date,
    user: OptionInterface
  ): Promise<void> => {
    setSelectedMonth(date);
    setSelectedUser(user);
    const ret = props.fetchReportUser(+user.value, date);
    if (user.value) {
      props.fetchUserInFacility(user.value.toString());
    }
    props.fetchInoutError(date);
    return ret;
  };

  const onChangeUser = async (user: OptionInterface): Promise<void> => {
    setSelectedUser(user);
    const ret = props.fetchReportUser(+user.value, selectedMonth);
    props.fetchUserInFacility(user.value.toString());
    props.fetchInoutError(selectedMonth);
    return ret;
  };

  const onClickErrorDialog = (): void => {
    props.openErrorsDialog();
  };

  const onChangeEditMode = (editFlag: boolean): void => {
    if (isEditing) props.stopHistory(false);
    setIsEditing(editFlag);
  };

  const onCancel = (): void => {
    setIsSubmitDisabled(true);
    onChangeEditMode(false);
  };

  const onSubmit = async (
    values: InitialValues,
    actions: FormikActions<InitialDataValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .postCHIIKITEICHAKUReport(
        +selectedUser.value,
        selectedMonth,
        values,
        props.inOutResultList
      )
      .finally(() => {
        props.fetchInoutError(selectedMonth);
        onChangeEditMode(false);
        props.fetchReportUser(+selectedUser.value, selectedMonth);
        actions.resetForm(initialValues(props.inOutResultList));
        actions.setSubmitting(false);
      });
  };

  const validate = (values: InitialDataValues): void | object => {
    const validationResult = validation(values);
    setIsSubmitDisabled(submitValidation(validationResult));
    const errorCheckResult = toEffectiveObject(validationResult);
    // エラーが存在するかは、toEffectiveObjectで確認
    // エラーが存在する場合は、validationの結果をそのまま返却
    // →FieldArrayを利用する場合、エラーは配列として保持する必要があるため
    // →また、エラー配列と表示データの配列はindexを一致させる必要がある
    // エラーが存在しない場合は、undefinedを返却（errorObjectがundefined）
    return errorCheckResult ? validationResult : errorCheckResult;
  };
  const payStartDate =
    usersInFacilityState &&
    usersInFacilityState.user.user_in_facility.date_begin_in_service
      ? usersInFacilityState.user.user_in_facility.date_begin_in_service
      : "";
  const payEndDate =
    usersInFacilityState &&
    usersInFacilityState.user.user_in_facility.date_end_in_service
      ? usersInFacilityState.user.user_in_facility.date_end_in_service
      : "";
  const startDate = Number(format(payStartDate, "YYYYMM"));
  const endDate = payEndDate ? Number(format(payEndDate, "YYYYMM")) : "";
  const serviceFlg = !(
    Number(format(selectedMonth, "YYYYMM")) < startDate ||
    (endDate && Number(format(selectedMonth, "YYYYMM")) > endDate)
  );
  return (
    <Formik
      initialValues={initialValues(inOutResultList)}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <div id="reportMonthlyHeader" className={classes.headerWrapper}>
            {inoutErrors.hasError && (
              <InvoiceErrorBar
                message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
                onClick={onClickErrorDialog}
              />
            )}
            <div className={classes.headerInfoContainer}>
              {isEditing ? (
                <InOutReportHeaderEdit
                  selectedMonth={selectedMonth}
                  selectedUserName={selectedUser.label}
                  onCancel={onCancel}
                  formikProps={formikProps}
                  resetForm={formikProps.resetForm}
                  disabled={isSubmitDisabled}
                />
              ) : (
                <InOutReportMonthlyHeader
                  minDate={minDate}
                  maxDate={maxDate}
                  selectedMonth={selectedMonth}
                  selectedUserId={selectedUser.value}
                  isSubmitDisabled={
                    !inOutResultList.inoutConsultationResults.length ||
                    !usersInFacilityState.user ||
                    !serviceFlg
                  }
                  onChangeMonth={onChangeMonth}
                  onChangeUser={onChangeUser}
                  onChangeEditMode={onChangeEditMode}
                  resetForm={formikProps.resetForm}
                  InOutResultList={initialValues(inOutResultList)}
                  onDefaultCheck={onDefaultCheck}
                  onCheckBoxFlg={onCheckBoxFlg}
                  history={props.history}
                  currentPageVersion={props.currentPageVersion}
                />
              )}
            </div>
          </div>

          <Paper elevation={0} className={classes.tableContainer}>
            <InOutReportTable
              isEditing={isEditing}
              headerHeight={headerHeight}
              filterFlg={false}
              formikProps={formikProps}
            />
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    inOutResultList: state.CHIIKITEICHAKU.report,
    usersInFacilityState: state.CHIIKITEICHAKU.userInFacility,
    inoutErrors: state.errors.inout
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKITEICHAKU, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = CHIIKITEICHAKU.reportDispatcher(dispatch);
  const uifDispatcher = CHIIKITEICHAKU.userInFacilityDispatcher(dispatch);
  const uiDispatches = uiDispatch(dispatch);

  return {
    fetchFacility: CHIIKITEICHAKU.facilityDispatcher(dispatch).fetch,
    fetchReportUser: (uifId: number, date: Date): Promise<void> =>
      reportDispatcher.fetchCHIIKITEICHAKUMonthly(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    postCHIIKITEICHAKUReport: (
      uifId: number,
      date: Date,
      formValue: InitialValues,
      reportValue: ReportState
    ): Promise<void> =>
      reportDispatcher.postCHIIKITEICHAKUReport(
        uifId,
        date,
        formValue,
        reportValue
      ),
    postCHIIKITEICHAKUReportMonthly: (
      uifId: number,
      date: Date,
      formValue: boolean
    ): Promise<void> =>
      reportDispatcher.postCHIIKITEICHAKUReportMonthly(uifId, date, formValue),
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    stopHistory: uiDispatches.stopHistory
  };
};

export const InOutReportMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportMonthlyCore));
