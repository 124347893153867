import { ShotMultipleSelectValue } from "@interfaces/ui/form";

type StaffList = ShotMultipleSelectValue;
export type BeforeStaffList = {
  id: number;
  name: string;
  facility_id: number;
  snapshot_name: string;
}[];

/**
 * 複数職員選択におけるノーマライズ
 * @param staffList
 * @param beforeStaffList
 */
export const normalizeMultiSelectStaffs = (
  staffList: StaffList,
  beforeStaffList: BeforeStaffList
):
  | {
      staff_id: number;
      staff_name: string;
    }[]
  | null => {
  const itemMap = new Map();
  staffList.forEach((item) => {
    // staffのnameが変更前と変更後の両方が選択された場合
    if (itemMap.has(item.id)) {
      const matchIdItem = beforeStaffList.find(
        (before) => before.id === item.id
      );
      // 変更後のnameにreset
      itemMap.set(item.id, {
        ...(matchIdItem && {
          ...item,
          id: matchIdItem.id,
          label: matchIdItem.name
        })
      });
    } else {
      itemMap.set(item.id, { ...item });
    }
  });

  const unipueItemList: ShotMultipleSelectValue = Array.from(itemMap.values());

  return unipueItemList
    ? unipueItemList
        // 削除されてないstaffのみ抽出
        .filter((item) => item.is_delete !== 1)
        .map((item) => ({
          staff_id:
            typeof item.id === "number" ? item.id : parseInt(item.id, 10),
          staff_name: item.label
        }))
    : null;
};
