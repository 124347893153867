/**
 * 選択肢の一致チェック
 * @param value
 * @param matchValue
 */
const optionNotMatch = (
  value: string,
  matchValue: string
): string | undefined => {
  let errorMessage;
  if (value === matchValue) {
    errorMessage =
      "障害支援区分は月途中の変更前後で異なるものを選択してください";
  }
  return errorMessage;
};

export default optionNotMatch;
