import { BasicValues } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/facility/basic";
import { SubtractionItemValues } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/facility/subtractionItem";
import { AdditionalItemValues } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/facility/additionItem";
import { FacilityState } from "@stores/v202104/domain/mgr/SHISETSUNYUSHO/facility/types";
import { UnitsValues } from "@interfaces/v202104/mgr/SHISETSUNYUSHO/facility/units";
import { dateToSelectDateValueYYYYM } from "@utils/date";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";

export type FacilityValues = BasicValues &
  SubtractionItemValues &
  AdditionalItemValues &
  UnitsValues;

// ユニットの初期値
export const unitInitialValue = {
  id: null,
  unit_name: "",
  night_support_type: "1",
  ave_users_last_fiscal_year: "0",
  is_deleted: false
};

const initialValues = (state?: FacilityState): FacilityValues => {
  // ユニットは複数 ≒ 必ず2件以上
  const unitsValue =
    state && state.units.length > 0
      ? state.units
      : [unitInitialValue, unitInitialValue];

  return {
    basic: {
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      serviceType: state ? state.serviceType : "",
      representativeName: state ? state.representativeName : "",
      capacity: state ? state.capacity : "",
      masterSubordinateFlg: state ? state.masterSubordinateFlg : false,
      isMasterRadioValue: state ? state.isMasterRadioValue : "1",
      multiFunctionOfficeFlg: state ? state.multiFunctionOfficeFlg : false,
      operatingUnitFlag: state ? state.operatingUnitFlag : false,
      allCapacity: state ? state.allCapacity : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : DEFAULT_SELECT_VALUE,
      cityId: state ? state.selectedCityCode : DEFAULT_SELECT_VALUE,
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      availableFood: state ? state.availableFood : false,
      foodExpenses: state ? `${state.foodExpenses}` : "0",
      foodExpensesBreakfast: state ? `${state.foodExpensesBreakfast}` : "",
      foodExpensesLunch: state ? `${state.foodExpensesLunch}` : "",
      foodExpensesSupper: state ? `${state.foodExpensesSupper}` : "",
      foodExpensesDay: state ? `${state.foodExpensesDay}` : "",
      utility: state ? `${state.utility}` : "0",
      utilityCosts: state ? `${state.utilityCosts}` : ""
    },
    subtractionItem: {
      originLocalGovFlg: state ? state.originLocalGovFlg : false,
      nutritionistPlacement: state ? `${state.nutritionistPlacement}` : "1",
      lackOfSupporterFlg: state ? state.lackOfSupporterFlg : false,
      dateStartLackOfSupporter:
        state && state.dateStartLackOfSupporter
          ? dateToSelectDateValueYYYYM(state.dateStartLackOfSupporter)
          : { year: DEFAULT_SELECT_VALUE, month: "", day: "1" }
    },
    additionalItem: {
      nighttimePlacement: state ? `${state.nighttimePlacement}` : "0",
      seeHearTeamFlg: state ? state.seeHearTeamFlg : false,
      regionalLifeTransition: state ? state.regionalLifeTransition : false,
      staffTreatmentSystemType: state ? state.staffTreatmentSystemType : "1",
      staffTreatmentSpecificSystemType: state
        ? state.staffTreatmentSpecificSystemType
        : "1",
      seriousDisability: state ? `${state.seriousDisability}` : "0",
      betterSupporterConditionBaseUpFlg: state
        ? state.betterSupporterConditionBaseUpFlg
        : false,
      oralHygieneManagementSystemFlg: state
        ? `${state.oralHygieneManagementSystemFlg}`
        : "0"
    },
    units: unitsValue
  };
};

export default initialValues;
