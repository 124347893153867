import { reducerWithInitialState } from "typescript-fsa-reducers";

import * as action from "@stores/domain/city/action";
import { CityState } from "@stores/domain/city/type";

const initCityState: CityState[] = [
  {
    label: "",
    value: 0,
    cityCode: "",
    grade: 0,
    grade_202104: 0,
    grade_children_disability_202104: 0,
    grade_202404: 0,
    grade_children_disability_202404: 0
  }
];

const clearCity = (): CityState[] => {
  return [
    {
      label: "選択してください",
      value: 0,
      cityCode: "",
      grade: 0,
      grade_202104: 0,
      grade_children_disability_202104: 0,
      grade_202404: 0,
      grade_children_disability_202404: 0
    }
  ];
};

export default reducerWithInitialState(initCityState)
  .case(action.fetch.done, (state, { result }) => {
    return {
      ...result
    };
  })
  .case(action.clearCity, clearCity);
