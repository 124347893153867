type convertWareki = {
  year: number | string;
  month: number | string;
  era: string;
  leastEra: string;
  warekiYear: string;
};

// 元号の名称及び西暦情報
const warekiFormat = [
  {
    era: "大正",
    leastEra: "T",
    startDate: new Date(1912, 6, 30),
    endDate: new Date(1926, 11, 24)
  },
  {
    era: "昭和",
    leastEra: "S",
    startDate: new Date(1926, 11, 25),
    endDate: new Date(1989, 0, 7)
  },
  {
    era: "平成",
    leastEra: "H",
    startDate: new Date(1989, 0, 8),
    endDate: new Date(2019, 3, 30)
  },
  {
    era: "令和",
    leastEra: "R",
    startDate: new Date(2019, 4, 1),
    endDate: new Date(2099, 11, 31)
  }
];

/**
 * 引数の年月が所属する元号情報を返却する（1927/1 ~ 2099/12まで）
 * @param yyyy 年
 * @param mm 月
 */
export const convertWareki = (
  yyyy: string | number,
  mm: string | number
): convertWareki => {
  const defaultValue = {
    year: "",
    month: "",
    era: "",
    leastEra: "",
    warekiYear: "年"
  };

  if (!yyyy || !mm) {
    return defaultValue;
  }

  const targetDate = new Date(Number(yyyy), Number(mm) - 1, 2);
  const resultDate = warekiFormat.filter((e) => {
    return e.startDate <= targetDate && e.endDate >= targetDate;
  });
  if (resultDate.length === 0) {
    return defaultValue;
  }

  const { era, leastEra, startDate } = resultDate[0];
  const year = targetDate.getFullYear() - startDate.getFullYear() + 1;
  const month = targetDate.getMonth() + 1;
  const warekiYear = `${era}${year}年`;
  return {
    year,
    month,
    era,
    leastEra,
    warekiYear
  };
};
