/**
 * 都道府県〜市区町村フィールド
 * 初回のfetchCityはここではなく、フォームの取得と合わせて行なっているのが前提
 * 他で使用する都道府県〜市区町村フィールドと市区町村フィールドにおいてリンクできるようになっている
 * リンク時の動作はOrganismで行うことが前提
 */

import * as React from "react";
import { connect } from "react-redux";
import { FormikProps, getIn } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import MuiTextField from "@components/molecules/MuiTextField";
import { CityState } from "@stores/domain/city/type";
import { FieldItem } from "@interfaces/ui/form";
import {
  getSelectedCityGrade,
  getSelectedCityCode
} from "@utils/dataNormalizer";
import { PREFECTURES_LIST, DEFAULT_CITY_STATE } from "@constants/variables";
import { AppState } from "@stores/type";

type OwnProps = {
  prefectureIdName: string;
  cityIdName: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  formikProps: FormikProps<any>; // 使用されるformikPropsがページごとに違うためany
  required?: boolean;
  disabledPrefectureId?: boolean;
  showRegionType?: boolean;
  onChangePrefecture: (
    linkKey: string,
    nextValue: string,
    beforeValue: string
  ) => void;
  billingLabel?: boolean;
};

type StateProps = {
  cityList: CityState[];
};

type MergeProps = OwnProps &
  StateProps & {
    cityOptions: FieldItem[];
    cityGrade: string;
    cityCode: string;
  };

type State = {
  showRegionType?: boolean;
};

type Props = MergeProps;

class FormikLinkAddress extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showRegionType:
        this.props.showRegionType !== undefined
          ? this.props.showRegionType
          : true
    };
  }

  /**
   * 都道府県更新時に市区町村一覧の初期化と再取得を行う
   */
  private handleChangePrefectureHook = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    beforeValue: string
  ): Promise<void> => {
    this.props.onChangePrefecture(
      this.props.cityIdName,
      e.target.value,
      beforeValue
    );
  };

  /**
   * cityGradeから地域区分の表示
   */
  private regionType = (): string => {
    return this.props.cityGrade ? `${this.props.cityGrade}級地` : "その他";
  };

  public render(): JSX.Element {
    return (
      <FormGroup row>
        <FormikSelect
          name={this.props.prefectureIdName}
          label={this.props.billingLabel ? "請求先の都道府県" : "都道府県"}
          options={PREFECTURES_LIST}
          disabled={this.props.disabledPrefectureId}
          onChangeHook={this.handleChangePrefectureHook}
        />
        <FormikSelect
          name={this.props.cityIdName}
          label={this.props.billingLabel ? "請求先の市区町村" : "市区町村"}
          options={this.props.cityOptions}
        />
        {this.state.showRegionType && (
          <MuiTextField
            value={this.regionType()}
            label="地域区分"
            disabled
            size="superSmall"
          />
        )}
        <MuiTextField
          value={this.props.cityCode}
          label={
            this.props.billingLabel ? "請求先の市区町村番号" : "市区町村番号"
          }
          disabled
          disabledStyle={this.props.cityCode !== ""}
          size={
            !this.props.billingLabel && this.state.showRegionType
              ? "superSmall"
              : "medium"
          }
        />
      </FormGroup>
    );
  }
}
const mapDispatchToProps = (): {} => ({});

const mapStateToProps = (state: AppState): StateProps => ({
  cityList: state.city
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: {},
  ownProps: OwnProps
): MergeProps => {
  const hasCityList =
    stateProps.cityList && Object.keys(stateProps.cityList).length !== 1;
  // convert: CityState[] => FieldItem[]
  const cityOptions = hasCityList
    ? Object.keys(stateProps.cityList).map((key) => {
        const { label, value } = stateProps.cityList[key];
        return { label, value };
      })
    : [DEFAULT_CITY_STATE];
  // 地域区分 & 市区町村番号
  const cityId = getIn(ownProps.formikProps.values, ownProps.cityIdName);
  const cityGrade =
    cityId && hasCityList
      ? getSelectedCityGrade(stateProps.cityList, cityId)
      : "";
  const cityCode =
    cityId && hasCityList
      ? getSelectedCityCode(stateProps.cityList, cityId)
      : "";

  return {
    cityOptions,
    cityGrade,
    cityCode,
    ...stateProps,
    ...ownProps
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FormikLinkAddress);
