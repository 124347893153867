import { UsageResult, UsageResultsState } from "./types";
import { GetUsageResultsMonthlyResponse } from "@api/requests/v202104/usageResults/getUsageResultsMonthly";
import { InitialDataValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/report/initialData";
import { PostUsageResultsParam } from "@api/requests/v202104/usageResults/postUsageResultsMonthly";
import deepEqual from "fast-deep-equal";
import { cloneDeep, isEmpty } from "lodash-es";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import castNumber from "@utils/dataNormalizer/castNumber";
import castString from "@utils/dataNormalizer/castString";
import castStringOrNull from "@utils/dataNormalizer/castStringOrNull";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { INT_TRUE_FROM_API } from "@constants/variables";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";

/**
 * timeがあればyyyy-mm-dd HH:mm:ss形式で返す
 */
const createDateString = (time: string): string => {
  return time !== "" ? `${time}:00` : "";
};

/**
 * APIのデータをstoreに変更
 * @param result APIのresponse
 */
export const normalizeUsageResultFromAPI = (
  result: GetUsageResultsMonthlyResponse["data"]
): UsageResultsState => {
  // 支援レポート共有日
  const shareSupportReportDate = result.share_support_report_date || "";

  const usageResults: UsageResultsState["usageResults"] = [];
  if (Array.isArray(result.usage_results)) {
    result.usage_results.forEach((usageResult) => {
      usageResults.push({
        uifId: castNumber(usageResult.users_in_facility_id),
        nameSei: castString(usageResult.name_sei),
        // 名
        nameMei: castString(usageResult.name_mei),
        // 対象年月日
        targetDate: castString(usageResult.target_date),
        // サービス提供の状況
        statusType: castNumber(usageResult.status_type),
        // 特別地域加算
        specialAreaFlg: castNumber(usageResult.special_area_flg),
        // 定着支援連携促進加算
        cooperationPromotionFlg: castNumber(
          usageResult.cooperation_promotion_flg
        ),
        // 支援実施方法・場所
        supportMethod: castNumber(usageResult.support_method),
        // 支援実施方法・場所(その他)
        supportMethodOther: castString(usageResult.support_method_other),
        // 支援開始
        supportStartTime: convertHHMMSSToHHMM(
          castString(usageResult.support_start_time)
        ),
        // 支援終了
        supportEndTime: convertHHMMSSToHHMM(
          castString(usageResult.support_end_time)
        ),
        // 備考
        remarks: castStringOrNull(usageResult.remarks),
        // 休日判定
        isHoliday: usageResult.isHoliday
      });
    });
  }

  return { shareSupportReportDate, usageResults };
};

/**
 * formデータをAPIのrequestに変換
 * @param formValues formのデータ
 * @param storeValues storeのデータ
 */
export const normalizeFormValueToAPI = (
  formValues: UsageResultsState,
  storeValues: UsageResultsState
): PostUsageResultsParam => {
  const shareSupportReportDate = formValues.shareSupportReportDate
    ? formValues.shareSupportReportDate
    : null;

  const usageResults: PostUsageResultsParam["usage_results"] = [];
  storeValues.usageResults.forEach((stateValue, index) => {
    const formValue = formValues.usageResults[index];

    if (formValue === undefined || deepEqual(stateValue, formValue)) {
      return;
    }

    const diffValue: PostUsageResultsParam["usage_results"][0] = {
      users_in_facility_id: stateValue.uifId ? `${stateValue.uifId}` : "",
      target_date: stateValue.targetDate || ""
    };

    if (
      formValue.statusType !== null &&
      stateValue.statusType !== formValue.statusType
    ) {
      diffValue.status_type = formValue.statusType;
    }
    if (
      formValue.specialAreaFlg !== null &&
      stateValue.specialAreaFlg !== formValue.specialAreaFlg
    ) {
      diffValue.special_area_flg = formValue.specialAreaFlg;
    }
    if (
      formValue.cooperationPromotionFlg !== null &&
      stateValue.cooperationPromotionFlg !== formValue.cooperationPromotionFlg
    ) {
      diffValue.cooperation_promotion_flg = formValue.cooperationPromotionFlg;
    }
    if (
      formValue.supportMethod !== null &&
      stateValue.supportMethod !== formValue.supportMethod
    ) {
      diffValue.support_method = formValue.supportMethod;
    }
    if (
      formValue.supportMethodOther !== null &&
      stateValue.supportMethodOther !== formValue.supportMethodOther
    ) {
      diffValue.support_method_other = formValue.supportMethodOther;
    }
    if (
      formValue.supportStartTime !== null &&
      stateValue.supportStartTime !== formValue.supportStartTime
    ) {
      diffValue.support_start_time =
        formValue.supportStartTime !== ""
          ? createDateString(formValue.supportStartTime)
          : null;
    }
    if (
      formValue.supportEndTime !== null &&
      stateValue.supportEndTime !== formValue.supportEndTime
    ) {
      diffValue.support_end_time =
        formValue.supportEndTime !== ""
          ? createDateString(formValue.supportEndTime)
          : null;
    }
    // KNOWBE2-2014: remarksを空文字にできない問題の解消のため、nullを""に変換
    if (stateValue.remarks !== formValue.remarks) {
      diffValue.remarks = formValue.remarks || "";
    }

    usageResults.push(diffValue);
  });

  return {
    share_support_report_date: shareSupportReportDate,
    usage_results: usageResults
  };
};

/**
 * formデータをstoreデータに変換
 * @param formValues formのデータ
 */
export const normalizeUsageResultFromForm = (
  formValues: InitialDataValues,
  storeValues: UsageResultsState,
  usersInFacilityState: UsersInFacilityState
): UsageResultsState => {
  const shareSupportReportDate =
    selectDateValueToDatePaddingZero(
      formValues.ReportData.shareSupportReportDate
    ) || "";

  const usageResults = storeValues.usageResults.map((storeValue, index) => {
    const userInFacilitySHUROTEICHAKU = usersInFacilityState.user
      .user_in_facility_shuroteichaku
      ? usersInFacilityState.user.user_in_facility_shuroteichaku
      : {};
    const convertReportValue: UsageResult = cloneDeep(storeValue);
    if (
      index < formValues.ReportData.usageResults.length &&
      storeValue.targetDate ===
        formValues.ReportData.usageResults[index].targetDate
    ) {
      convertReportValue.statusType =
        booleanToNumber0or1(
          formValues.ReportData.usageResults[index].statusType
        ) || 0;
      convertReportValue.specialAreaFlg =
        booleanToNumber0or1(
          formValues.ReportData.usageResults[index].specialAreaFlg
        ) || 0;
      convertReportValue.cooperationPromotionFlg =
        (userInFacilitySHUROTEICHAKU.cooperation_promotion_flg ===
          INT_TRUE_FROM_API &&
          booleanToNumber0or1(
            formValues.ReportData.usageResults[index].cooperationPromotionFlg
          )) ||
        0;
      convertReportValue.supportMethod =
        convertReportValue.statusType === 1
          ? Number(formValues.ReportData.usageResults[index].supportMethod)
          : 0;
      convertReportValue.supportMethodOther =
        convertReportValue.statusType === 1
          ? formValues.ReportData.usageResults[index].supportMethodOther
          : "";
      convertReportValue.supportStartTime =
        convertReportValue.statusType === 1
          ? formValues.ReportData.usageResults[index].supportStartTime
          : "";
      convertReportValue.supportEndTime =
        convertReportValue.statusType === 1
          ? formValues.ReportData.usageResults[index].supportEndTime
          : "";
      convertReportValue.remarks = isEmpty(
        formValues.ReportData.usageResults[index].remarks
      )
        ? null
        : formValues.ReportData.usageResults[index].remarks;
      // 祝日情報がundefの場合deepCopyされずに後続の処理で不適切な動きをするため
      convertReportValue.isHoliday = storeValue.isHoliday;
    }
    return convertReportValue;
  });

  return { shareSupportReportDate, usageResults };
};
