import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type KEIKAKUSODANPostConsultation = {
  support_plan_consultations_id: number | null;
  target_flg: number;
  creation_date: string;
  author?: number | null;
  monitoring_period?: number | null; // 0:案、1:計画のみ出現
  monitoring_start_ym?: string | null; // 0:案、1:計画のみ出現
  plan_start_ym?: string | null;
  classify_disability_support?: number;
  income_kind?: string; // 1:計画,2:継続のみ出現
  disabled_welfare_service_recipient_number?: string;
  regular_place_recipient_number?: string;
  consultation_support_recipient_number?: string;
  desired_life?: string;
  support_policy?: string;
  long_term_goal?: string;
  short_term_goal?: string;
  // 0:案、1:計画のみ出現
  support_plan_consultation_details?: {
    support_plan_consultation_details_id: number | null;
    needs?: string;
    support_goal?: string;
    achievement_time?: string;
    welfare_service_type?: string;
    provider_name?: string; // 1:計画のみ出現
    role_in_problem_solving?: string;
    evaluation_time?: string;
    other_considerations?: string;
    is_delete?: number;
  }[];
  support_plan_consultation_schedule: {
    support_plan_consultation_schedule_id: number | null;
    day_of_week?: number[];
    start_time?: string | null;
    next_day_flg?: number;
    end_time?: string | null;
    content?: string;
    is_delete?: number;
  }[];
  main_activities?: string;
  non_weekly_service?: string;
  overall_life?: string;
  staff_comment?: string;
  type_consultation?: number;
};

export type CHIIKIIKOPostConsultation = {
  support_plan_consultations_id: number | null;
  target_flg: number;
  creation_date: string;
  author?: number | null;
  attainment_goal?: string;
  long_term_goal?: string;
  short_term_goal?: string;
  start_ym?: string | null;
  end_ym?: string | null;
  support_plan_consultation_details?: {
    support_plan_consultation_details_id: number | null;
    support_goal?: string;
    collaborator?: string;
    contents_cooperation?: string;
    considerations?: string;
    start_ym?: string | null;
    end_ym?: string | null;
    users_do?: string;
    is_delete?: number;
  }[];
  staff_comment?: string;
};

/**
 * 指定ユーザーのサービス等利用計画、地域移行計画を保存する
 * @param uifId ユーザーID
 * @param params 更新データ
 */

export const postConsultation = async (
  uifId: string,
  params: KEIKAKUSODANPostConsultation | CHIIKIIKOPostConsultation
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/consultation`;
  return request.post(url, params);
};
