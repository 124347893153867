import { InvoiceVersionUrl } from "@constants/variables";

/** 対象バージョンのURLを返却 */
export const getVersionUrl = (
  targetVersion: number,
  invoiceVersionUrls: InvoiceVersionUrl[]
): string | undefined => {
  // バージョン確認
  const invoiceVersionUrl = invoiceVersionUrls.find(
    (v) => v.version === targetVersion
  );
  if (invoiceVersionUrl) {
    return invoiceVersionUrl.url;
  }
  return undefined;
};
