import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import { AssessmentSettingForm } from "@components/organisms/assessment/setting/AssessmentSettingForm";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { UserState } from "@stores/domain/user/type";
import { AppState } from "@stores/type";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  ASSESSMENT_CATEGORY_TYPE,
  AB_ASSESSMENT_CATEGORY_TYPE,
  IKOU_ASSESSMENT_CATEGORY_TYPE,
  CUSTOM_RECORD_TARGET_TYPE,
  FacilityType,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE,
  KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE,
  CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE,
  CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE,
  GROUP_HOME_ASSESSMENT_CATEGORY_TYPE,
  SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE
} from "@constants/variables";
import FormPaper from "@components/atoms/FormPaper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import {
  CustomRecordsValues,
  initialValues
} from "@initialize/record/customRecordWithCategory/initialValues";
import { CustomRecordsDialog } from "@components/organisms/assessment/setting/dialog/CustomRecordsDialog";

const styles = (): StyleRules =>
  createStyles({
    topSection: {
      marginTop: 32
    },
    infoIcon: {
      color: "#0277bd",
      width: 20,
      height: 20,
      marginRight: 6
    },
    caption: {
      color: "#37474f",
      display: "flex",
      alignItems: "center"
    },
    description: {
      lineHeight: 1.75,
      margin: "4px 26px",
      color: "#666666"
    }
  });

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  hideCustomRecord: (recordId: number, type: "item" | "category") => void;
  postCustomRecords: (formValue: CustomRecordsValues) => Promise<void>;
  postCustomRecordsCategory: (formValue: CustomRecordsValues) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
};

type StateProps = {
  user: UserState;
  customRecords: CustomRecordsWithCategoryState;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const SettingFormCore = (props: Props): JSX.Element => {
  const {
    classes,
    user,
    customRecords,
    fetchCustomRecords,
    hideCustomRecord,
    postCustomRecords,
    postCustomRecordsCategory,
    showCustomRecord
  } = props;
  const [isOpenHideConfirm, setOpenHideConfirm] = React.useState(false);
  const [
    isOpenEvaluationPeriodConfirm,
    setOpenEvaluationPeriodConfirm
  ] = React.useState(false);
  const [hideConfirmTargetId, setHideConfirmTargetId] = React.useState(0);
  const [
    evaluationPeriodConfirmTargetId,
    setEvaluationPeriodConfirmTargetId
  ] = React.useState(0);
  const [
    evaluationPeriodDisabled,
    setEvaluationPeriodDisabled
  ] = React.useState(false);
  const [formValues, setFormValues] = useState(initialValues());
  const [editedType, setEditedType] = useState<"" | "category" | "item">("");
  const [editedId, setEditedId] = useState(0);
  const [isOpenCustomRecordModal, openCustomRecordModal] = useState(false);
  const [sortingCategoryType, setSortingCategoryType] = useState<number | null>(
    null
  );
  const [sortingItemType, setSortingItemType] = useState<number | null>(null);

  let hideConfirmTargetName = "";
  if (hideConfirmTargetId > 0 && customRecords.length > 0) {
    if (editedType === "category") {
      const hideConfirmTarget = customRecords.find(
        (record) => record.id === hideConfirmTargetId
      );
      hideConfirmTargetName = hideConfirmTarget ? hideConfirmTarget.name : "";
    } else if (editedType === "item") {
      const hideConfirmTargetCategory = customRecords.find((record) =>
        record.custom_record_items.find((r) => r.id === hideConfirmTargetId)
      );
      const hideConfirmTargetItem = hideConfirmTargetCategory
        ? hideConfirmTargetCategory.custom_record_items.find(
            (r) => r.id === hideConfirmTargetId
          )
        : null;
      hideConfirmTargetName = hideConfirmTargetItem
        ? hideConfirmTargetItem.name
        : "";
    }
  }

  // 項目カテゴリー名の追加
  const openAddModal = (
    type: "" | "category" | "item",
    category_type: number,
    custom_records_category_id: number | null,
    custom_record_item_choices?: {
      id: number;
      custom_record_item_id: number;
      name: string;
      default_choice: number | null;
      hidden: number;
      default_choice_input_type: number | null;
    }[]
  ): void => {
    let isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text;
    let choices;
    switch (user.facility_type) {
      case FacilityType.A:
      case FacilityType.B:
        if (
          category_type === AB_ASSESSMENT_CATEGORY_TYPE.support ||
          category_type === AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
        ) {
          isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio;
        }
        break;
      case FacilityType.IKOU:
        if (
          category_type === IKOU_ASSESSMENT_CATEGORY_TYPE.support ||
          category_type === IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
        ) {
          isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio;
        }
        break;
      case FacilityType.CHIIKIIKO:
      case FacilityType.CHIIKITEICHAKU:
      case FacilityType.KEIKAKUSODAN:
        if (
          custom_record_item_choices &&
          custom_record_item_choices.length > 0 &&
          category_type !==
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou
        ) {
          isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text;
          choices = custom_record_item_choices;
        }
        break;
      case FacilityType.GROUP_HOME:
        if (category_type !== GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks) {
          isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio;
        }
        break;
      case FacilityType.SEIKATSUKAIGO:
        if (category_type !== SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.remarks) {
          isInputType = SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio;
        }
        break;
      default:
        isInputType =
          category_type !== ASSESSMENT_CATEGORY_TYPE.remarks
            ? SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio
            : SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text;
    }

    setFormValues(
      initialValues(
        category_type,
        custom_records_category_id,
        choices,
        isInputType,
        CUSTOM_RECORD_TARGET_TYPE.assessment
      )
    );
    openCustomRecordModal(true);
    setEditedType(type);
  };

  // 項目カテゴリー名の編集
  const changeEditMode = (
    id: number,
    type: "" | "category" | "item",
    params: CustomRecordsWithCategoryState[number]
  ): void => {
    let isInputTypeRadio = false;
    switch (user.facility_type) {
      case FacilityType.A:
      case FacilityType.B:
        isInputTypeRadio =
          params.category_type === AB_ASSESSMENT_CATEGORY_TYPE.support ||
          params.category_type === AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill;
        break;
      case FacilityType.IKOU:
        isInputTypeRadio =
          params.category_type === IKOU_ASSESSMENT_CATEGORY_TYPE.support ||
          params.category_type === IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill;
        break;
      default:
        isInputTypeRadio =
          params.category_type !== ASSESSMENT_CATEGORY_TYPE.remarks;
    }
    setFormValues(
      initialValues(
        params.category_type,
        params.id,
        [],
        isInputTypeRadio
          ? SUPPORT_CUSTOM_RECORD_INPUT_TYPE.radio
          : SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text,
        CUSTOM_RECORD_TARGET_TYPE.assessment
      )
    );
    setEditedId(id);
    setEditedType(type);
    setEvaluationPeriodDisabled(true);
  };

  // 項目カテゴリー名編集終了
  const endEdit = (): void => {
    setFormValues(initialValues());
    setEditedId(0);
    setEditedType("");
    setEvaluationPeriodDisabled(false);
  };

  // カテゴリー名編集保存
  const submitCategoryEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((v) => v.id === editedId);
    const params = {
      id: editedId,
      name: category ? category.name : "",
      input_type: "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.assessment,
      custom_records_category_id: null,
      category_type: category ? category.category_type : 0
    };
    await postCustomRecordsCategory(params);
    setEvaluationPeriodDisabled(false);
  };

  // 項目名編集保存
  const submitItemEdit = async (
    values: CustomRecordsWithCategoryState
  ): Promise<void> => {
    const category = values.find((value) =>
      value.custom_record_items.find((v) => v.id === editedId)
    );
    const item = category
      ? category.custom_record_items.find((v) => v.id === editedId)
      : null;
    const params = {
      id: editedId,
      name: item ? item.name : "",
      input_type: item ? `${item.input_type}` : "",
      setting_type: CUSTOM_RECORD_TARGET_TYPE.assessment,
      custom_records_category_id: item ? item.custom_records_category_id : 0,
      category_type: 0
    };
    await postCustomRecords(params);
    setEvaluationPeriodDisabled(false);
  };

  // 項目カテゴリー名のうち編集中の保存関数を渡す
  const submitEdit =
    editedType === "category" ? submitCategoryEdit : submitItemEdit;

  // 項目の追加モーダルを閉じる
  const closeCustomRecordModal = (): void => {
    setFormValues(initialValues());
    openCustomRecordModal(false);
    setEditedType("");
  };

  const categorizeCustomRecord = (
    categoryType: number
  ): CustomRecordsWithCategoryState => {
    return customRecords.filter((r) => r.category_type === categoryType);
  };

  const openHideConfirmModal = (
    id: number,
    type: "" | "category" | "item"
  ): void => {
    setOpenHideConfirm(true);
    setEditedType(type);
    setHideConfirmTargetId(id);
  };
  const closeHideConfirmModal = (): void => {
    setOpenHideConfirm(false);
  };

  const onClickHide = async (): Promise<void> => {
    setOpenHideConfirm(false);
    if (hideConfirmTargetId === 0) return;
    if (editedType === "") return;
    await hideCustomRecord(hideConfirmTargetId, editedType);
    await fetchCustomRecords();
  };

  const openEvaluationPeriodConfirmModal = (id: number): void => {
    setOpenEvaluationPeriodConfirm(true);
    setEvaluationPeriodConfirmTargetId(id);
  };
  const closeEvaluationPeriodConfirmModal = (): void => {
    setOpenEvaluationPeriodConfirm(false);
  };

  const onClickEvaluationPeriod = async (): Promise<void> => {
    setOpenEvaluationPeriodConfirm(false);
    if (evaluationPeriodConfirmTargetId === 0) return;

    const currentVisibility = categorizeCustomRecord(
      IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period
    )[0].visibility;

    await showCustomRecord(
      currentVisibility === 1
        ? { item: [], category: [] }
        : { item: [], category: [evaluationPeriodConfirmTargetId] },
      currentVisibility === 1
        ? { item: [], category: [evaluationPeriodConfirmTargetId] }
        : { item: [], category: [] }
    );
    await fetchCustomRecords();
  };

  useEffect(() => {
    fetchCustomRecords();
  }, []);

  const hideItemConfirmModalMessage = (
    <span>
      この項目は記録画面に表示されなくなり、「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
    </span>
  );
  const hideCategoryConfirmModalMessage = (
    <span>
      このカテゴリーに属する全ての項目が記録画面に表示されなくなり、
      <br />
      「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
      <br />
    </span>
  );
  const hideConfirmModalMessage =
    editedType === "category"
      ? hideCategoryConfirmModalMessage
      : hideItemConfirmModalMessage;
  const hideConfirmModalTitle =
    editedType === "category"
      ? `カテゴリー「${hideConfirmTargetName}」を非表示にしますか？`
      : `「${hideConfirmTargetName}」を非表示にしますか？`;

  let assessmentSettingInfo: {
    isCategorized: boolean;
    categoryType: number;
    label: string;
    customRecords: CustomRecordsWithCategoryState;
    evaluationPeriodRecord?: CustomRecordsWithCategoryState;
    isHiddenButton?: boolean;
  }[] = [];
  switch (user.facility_type) {
    case FacilityType.A:
    case FacilityType.B:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: AB_ASSESSMENT_CATEGORY_TYPE.employment,
          label: "入力画面では文字入力形式となります。（編集不可の項目を除く）",
          customRecords: categorizeCustomRecord(
            AB_ASSESSMENT_CATEGORY_TYPE.employment
          )
        },
        {
          isCategorized: true,
          categoryType: AB_ASSESSMENT_CATEGORY_TYPE.support,
          label:
            "入力画面では「できる」「少し支援が必要」「支援が必要」「できない」の4つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            AB_ASSESSMENT_CATEGORY_TYPE.support
          )
        },
        {
          isCategorized: true,
          categoryType: AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
          label:
            "入力画面では「ある」「少しある」「ない」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            AB_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
          )
        },
        {
          isCategorized: true,
          categoryType: AB_ASSESSMENT_CATEGORY_TYPE.others,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            AB_ASSESSMENT_CATEGORY_TYPE.others
          )
        }
      ];
      break;
    case FacilityType.IKOU:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: IKOU_ASSESSMENT_CATEGORY_TYPE.employment,
          label: "入力画面では文字入力形式となります。（編集不可の項目を除く）",
          customRecords: categorizeCustomRecord(
            IKOU_ASSESSMENT_CATEGORY_TYPE.employment
          )
        },
        {
          isCategorized: true,
          categoryType: IKOU_ASSESSMENT_CATEGORY_TYPE.support,
          label:
            "入力画面では「できる」「少し支援が必要」「支援が必要」「できない」の4つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            IKOU_ASSESSMENT_CATEGORY_TYPE.support
          ),
          evaluationPeriodRecord: categorizeCustomRecord(
            IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period
          )
        },
        {
          isCategorized: true,
          categoryType: IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
          label:
            "入力画面では「ある」「少しある」「ない」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            IKOU_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
          )
        },
        {
          isCategorized: true,
          categoryType: IKOU_ASSESSMENT_CATEGORY_TYPE.others,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            IKOU_ASSESSMENT_CATEGORY_TYPE.others
          )
        }
      ];
      break;
    case FacilityType.KEIKAKUSODAN:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.summary
          )
        },
        {
          isCategorized: false,
          categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation,
          label: "入力画面に文字入力形式の追加が可能です。",
          customRecords: categorizeCustomRecord(
            KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.living_situation
          )
        },
        {
          isCategorized: true,
          categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation,
          label:
            "入力画面では「現状」「本人・家族の希望や困りごと」「支援者の気付きや気になりごと」の3項目への文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.situation
          )
        },
        {
          isCategorized: true,
          categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.request
          )
        },
        {
          isCategorized: true,
          categoryType: KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            KEIKAKUSODAN_ASSESSMENT_CATEGORY_TYPE.others
          )
        }
      ];
      break;
    case FacilityType.CHIIKIIKO:
      assessmentSettingInfo = [
        {
          isCategorized: false,
          categoryType: CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.basic_information
          ),
          isHiddenButton: true
        },
        {
          isCategorized: true,
          categoryType: CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.summary,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.summary
          )
        },
        {
          isCategorized: false,
          categoryType: CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation,
          label: "入力画面に文字入力形式の追加が可能です。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.living_situation
          )
        },
        {
          isCategorized: true,
          categoryType: CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation,
          label:
            "入力画面では「現状」「本人・家族の希望や困りごと」「支援者の気付きや気になりごと」の3項目への文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.situation
          )
        },
        {
          isCategorized: true,
          categoryType:
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou,
          label: "入力画面に文字入力形式の追加が可能です。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.opinions_on_chiikiikou
          )
        },
        {
          isCategorized: true,
          categoryType: CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.others,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKIIKO_ASSESSMENT_CATEGORY_TYPE.others
          )
        }
      ];
      break;
    case FacilityType.CHIIKITEICHAKU:
      assessmentSettingInfo = [
        {
          isCategorized: false,
          categoryType:
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.basic_information
          ),
          isHiddenButton: true
        },
        {
          isCategorized: true,
          categoryType: CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.summary,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.summary
          )
        },
        {
          isCategorized: false,
          categoryType:
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation,
          label: "入力画面に文字入力形式の追加が可能です。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.living_situation
          )
        },
        {
          isCategorized: true,
          categoryType: CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation,
          label:
            "入力画面では「現状」「本人・家族の希望や困りごと」「支援者の気付きや気になりごと」の3項目への文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.situation
          )
        },
        {
          isCategorized: true,
          categoryType: CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.request,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.request
          )
        },
        {
          isCategorized: true,
          categoryType: CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.others,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            CHIIKITEICHAKU_ASSESSMENT_CATEGORY_TYPE.others
          )
        }
      ];
      break;
    case FacilityType.GROUP_HOME:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.everyday_life,
          label:
            "入力画面では「自立」「ほぼ自立」「一部介助」「多くを介助」「全て介助」の5つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.everyday_life
          )
        },
        {
          isCategorized: true,
          categoryType: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.social_life,
          label:
            "入力画面では「できる」「ときどき問題がある」「できない」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.social_life
          )
        },
        {
          isCategorized: true,
          categoryType: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
          label:
            "入力画面では「普通」「やや困難」「困難」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state
          )
        },
        {
          isCategorized: true,
          categoryType: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
          label: "入力画面では「あり」「なし」の２つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
          )
        },
        {
          isCategorized: false,
          categoryType: GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            GROUP_HOME_ASSESSMENT_CATEGORY_TYPE.remarks
          )
        }
      ];
      break;
    case FacilityType.SEIKATSUKAIGO:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.everyday_life,
          label:
            "入力画面では「自立」「ほぼ自立」「一部介助」「多くを介助」「全て介助」の5つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.everyday_life
          )
        },
        {
          isCategorized: true,
          categoryType:
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.health_medical_care,
          label:
            "入力画面では「できる」「ときどき問題がある」「できない」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.health_medical_care
          )
        },
        {
          isCategorized: true,
          categoryType: SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.social_life,
          label:
            "入力画面では「できる」「ときどき問題がある」「できない」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.social_life
          )
        },
        {
          isCategorized: true,
          categoryType:
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
          label:
            "入力画面では「普通」「やや困難」「困難」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.psychosomatic_state
          )
        },
        {
          isCategorized: true,
          categoryType: SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.aptitude_skill,
          label: "入力画面では「あり」「なし」の２つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.aptitude_skill
          )
        },
        {
          isCategorized: false,
          categoryType: SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.remarks,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            SEIKATSUKAIGO_ASSESSMENT_CATEGORY_TYPE.remarks
          )
        }
      ];
      break;
    default:
      assessmentSettingInfo = [
        {
          isCategorized: true,
          categoryType: ASSESSMENT_CATEGORY_TYPE.everyday_life,
          label:
            "入力画面では「自立」「ほぼ自立」「一部介助」「多くを介助」「全て介助」の5つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            ASSESSMENT_CATEGORY_TYPE.everyday_life
          )
        },
        {
          isCategorized: true,
          categoryType: ASSESSMENT_CATEGORY_TYPE.psychosomatic_state,
          label:
            "入力画面では「ふつう」「やや困難」「困難」の3つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            ASSESSMENT_CATEGORY_TYPE.psychosomatic_state
          )
        },
        {
          isCategorized: true,
          categoryType: ASSESSMENT_CATEGORY_TYPE.fault_characteristics,
          label: "入力画面では「あり」「なし」の２つからの選択形式となります。",
          customRecords: categorizeCustomRecord(
            ASSESSMENT_CATEGORY_TYPE.fault_characteristics
          )
        },
        {
          isCategorized: false,
          categoryType: ASSESSMENT_CATEGORY_TYPE.remarks,
          label: "入力画面では文字入力形式となります。",
          customRecords: categorizeCustomRecord(
            ASSESSMENT_CATEGORY_TYPE.remarks
          )
        }
      ];
  }

  return (
    <section className={classes.topSection}>
      <FormPaper>
        <div className={classes.caption}>
          <InfoOutlinedIcon className={classes.infoIcon} />
          この画面の設定について
        </div>
        <p className={classes.description}>
          ここで設定した内容は、作成済みのものも含めて全てのアセスメントシートに反映されます。
          <br />
          カテゴリー名や項目名を変更する際に、作成済みのシートに反映させたくない場合は、該当箇所の「編集」は行わず、
          <br />
          「追加」で新しいカテゴリー/項目を作成の上、不要なカテゴリー/項目を「非表示」にしてください。
          <br />
          「非表示」にした場合でも、作成済みのシートで内容が入力されている箇所は消えずに残ります。
        </p>
      </FormPaper>
      {assessmentSettingInfo.map((v) => (
        <AssessmentSettingForm
          key={`assessmentSettingForm-${v.categoryType}`}
          isCategorized={v.isCategorized}
          categoryType={v.categoryType}
          label={v.label}
          customRecords={v.customRecords}
          isHiddenButton={v.isHiddenButton}
          sortingCategoryType={sortingCategoryType}
          setSortingCategoryType={setSortingCategoryType}
          sortingItemType={sortingItemType}
          setSortingItemType={setSortingItemType}
          openHideConfirmModal={openHideConfirmModal}
          fetchCustomRecords={fetchCustomRecords}
          openAddModal={openAddModal}
          editedId={editedId}
          editedType={editedType}
          changeEditMode={changeEditMode}
          endEdit={endEdit}
          submitEdit={submitEdit}
          openEvaluationPeriodConfirmModal={openEvaluationPeriodConfirmModal}
          evaluationPeriodDisabled={evaluationPeriodDisabled}
          setEvaluationPeriodDisabled={setEvaluationPeriodDisabled}
          evaluationPeriodRecord={v.evaluationPeriodRecord}
        />
      ))}
      <MessageDialog
        isOpen={isOpenHideConfirm}
        title={hideConfirmModalTitle}
        message={hideConfirmModalMessage}
        closeButton={
          <KnowbeButton
            kind="text"
            style={{ margin: "0 8px 0 0" }}
            onClick={closeHideConfirmModal}
          >
            キャンセル
          </KnowbeButton>
        }
        actionButton={
          <KnowbeButton
            kind="textDelete"
            style={{ margin: 0 }}
            onClick={onClickHide}
          >
            非表示にする
          </KnowbeButton>
        }
      />
      {isOpenEvaluationPeriodConfirm && (
        <MessageDialog
          isOpen={isOpenEvaluationPeriodConfirm}
          title={
            categorizeCustomRecord(
              IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period
            )[0].visibility === 1
              ? "「評価期間」を非表示にしますか？"
              : "「評価期間」を表示しますか？"
          }
          message={
            categorizeCustomRecord(
              IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period
            )[0].visibility === 1 ? (
              <span>
                この項目は記録画面に表示されなくなります。
                <br />
                「評価期間の入力項目を表示する」にチェックを入れることで、再度表示することも可能です。
              </span>
            ) : (
              <span>
                この項目は記録画面に表示されます。
                <br />
                「評価期間の入力項目を表示する」のチェックを外すことで、再度非表示にすることも可能です。
              </span>
            )
          }
          closeButton={
            <KnowbeButton
              kind="text"
              style={{ margin: "0 8px 0 0" }}
              onClick={closeEvaluationPeriodConfirmModal}
            >
              キャンセル
            </KnowbeButton>
          }
          actionButton={
            categorizeCustomRecord(
              IKOU_ASSESSMENT_CATEGORY_TYPE.evaluation_period
            )[0].visibility === 1 ? (
              <KnowbeButton
                kind="textDelete"
                style={{ margin: 0 }}
                onClick={onClickEvaluationPeriod}
              >
                非表示にする
              </KnowbeButton>
            ) : (
              <KnowbeButton
                kind="textDelete"
                style={{ margin: 0 }}
                onClick={onClickEvaluationPeriod}
              >
                表示にする
              </KnowbeButton>
            )
          }
        />
      )}
      {isOpenCustomRecordModal && (
        <CustomRecordsDialog
          isModalOpen={isOpenCustomRecordModal}
          modalType={editedType}
          closeModal={closeCustomRecordModal}
          formValues={formValues}
          fetchCustomRecords={fetchCustomRecords}
        />
      )}
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user,
  customRecords: state.customRecordsWithCategory.assesment
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecords(
        CUSTOM_RECORD_TARGET_TYPE.assessment
      );
    },
    hideCustomRecord: (
      recordId: number,
      type: "item" | "category"
    ): Promise<void> => {
      return customRecordsDispatches.hideCustomRecord(recordId, type);
    },
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecords(formValue);
    },
    postCustomRecordsCategory: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecordsCategory(formValue);
    },
    showCustomRecord: (
      visibleRecordIds: {
        item: number[];
        category: number[];
      },
      invisibleRecordIds: {
        item: number[];
        category: number[];
      }
    ): Promise<void> => {
      return customRecordsDispatches.showCustomRecord(
        visibleRecordIds,
        invisibleRecordIds
      );
    }
  };
};

export const SettingForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingFormCore)
);
