import * as React from "react";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles, Theme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { InOutReportDailyHeader } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportDailyHeader";
import { InOutReportPaperHeader } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportPaperHeader";
import { InOutReportTable } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportTable";
import CopyConfirmDialog from "@components/organisms/mgr/IDOSHIEN/report/dialog/CopyConfirmDialog";
import { InOutReportDialog } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportDialog";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import {
  REPEAT_DAILY,
  ReportState,
  InoutResultsState,
  DeleteReportState,
  CopyReportState,
  CopyReportErrorState,
  MunicipalityData
} from "@stores/domain/mgr/IDOSHIEN/report/types";
import {
  REPORT_FILTER_LIST,
  PROCESS_TYPE,
  INOUT_RESULTS_COPY_ERROR_STATUS,
  IDOSHIEN_INPUT_CLASS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import { ResponseError } from "@stores/ui/type";
import { dateInYYYYMMDDFormat } from "@utils/date";
import {
  InitialDataValues,
  initialValues
} from "@initialize/mgr/IDOSHIEN/report/initialValues";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      minHeight: 56,
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8,
      width: "100%"
    },
    tableContainer: {
      padding: `24px ${spacing.unit * 4}px ${spacing.unit * 4}px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    }
  });

type OwnProps = {
  initialDate: Date;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  municipality: MunicipalitiesInFacilityState["municipality"];
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
};

type DispatchProps = {
  fetchDaily: (date: Date) => Promise<void>;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  fetchMunicipality: (municipalityId: string) => Promise<void>;
  clearMunicipality: () => Promise<void>;
  openErrorsDialog: () => void;
  deleteInoutResults: (target: DeleteReportState) => Promise<void>;
  copyInoutResults: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  responseErrorClear(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  selectedDate: Date;
  headerHeight: number;
  data: InitialDataValues;
  openedModalApiInoutResultData: InoutResultsState | null;
  isOpenDetailModal: boolean;
  modalTarget: string;
  municipality: MunicipalityData;
  municipalityId: number | null;
  filterFlg: boolean;
  filterValue: string;
  isOpenDeleteDialog: boolean;
  deleteTarget: DeleteReportState | null;
  isOpenCopyConfirmDialog: boolean;
  copyReportListTarget: CopyReportState[] | null;
};

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績（日ごと）
 */
class InOutReportDailyCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedDate: this.props.initialDate,
      headerHeight: 0,
      data: initialValues(),
      openedModalApiInoutResultData: null,
      isOpenDetailModal: false,
      modalTarget: IDOSHIEN_INPUT_CLASS_LIST.CREATE.label,
      municipality: {
        calculationTimeFlg: this.props.municipality.calculation_time_flg === 1,
        roundUpMinute:
          this.props.municipality.calculation_time_flg === 1
            ? this.props.municipality.round_up_minute
            : null,
        timeDivisionFlg: this.props.municipality.time_division_flg === 1
      },
      municipalityId: null,
      filterFlg: false,
      filterValue: "1",
      isOpenDeleteDialog: false,
      deleteTarget: null,
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null
    };
  }

  public componentDidMount(): void {
    Promise.all([
      this.props.fetchDaily(this.state.selectedDate),
      this.props.fetchInoutError(this.state.selectedDate)
    ]);
  }

  public componentDidUpdate(): void {
    const top = document.getElementById("reportDailyHeader");
    if (top && top.clientHeight !== this.state.headerHeight) {
      const setClientHeight = (): void => {
        this.setState({
          headerHeight: top.clientHeight
        });
      };
      setClientHeight();
    }
  }

  private onChangeDate = (date: Date): void => {
    this.setState({ selectedDate: date });
    this.props.fetchDaily(date);
    this.props.fetchInoutError(date);
  };

  private onChangeFilterSelect = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    this.setState({
      filterValue: e.target.value,
      filterFlg: e.target.value === REPORT_FILTER_LIST.FILTER.value
    });
  };

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  /**
   * 実績編集モーダルを開く
   */
  private openModal = async (
    param: InoutResultsState,
    inputClass: string,
    uifId: number
  ): Promise<void> => {
    // ユーザーに設定されている自治体情報を取得する
    await this.props.fetchUserInFacility(uifId.toString());
    const userInFacility = this.props.usersInFacilityState.user
      .user_in_facility_idoshien;

    // 編集モーダルを開くが既存データが無い場合には利用者の設定してる情報を使う
    if (param.id === null) {
      if (userInFacility && userInFacility.municipality_id) {
        await this.props.fetchMunicipality(
          String(userInFacility.municipality_id)
        );
      } else {
        await this.props.clearMunicipality();
      }
    }
    // 自治体情報を設定
    const municipality =
      param.id === null
        ? {
            calculationTimeFlg:
              this.props.municipality.calculation_time_flg === 1,
            roundUpMinute:
              this.props.municipality.calculation_time_flg === 1
                ? this.props.municipality.round_up_minute
                : null,
            timeDivisionFlg: this.props.municipality.time_division_flg === 1
          }
        : param.municipality;

    this.setState({
      data: initialValues(param),
      openedModalApiInoutResultData: param
    });
    if (inputClass === IDOSHIEN_INPUT_CLASS_LIST.PLAN.value) {
      this.setState({ modalTarget: IDOSHIEN_INPUT_CLASS_LIST.PLAN.label });
    } else {
      this.setState({
        modalTarget: IDOSHIEN_INPUT_CLASS_LIST.RESULT.label
      });
    }
    this.setState({
      isOpenDetailModal: true,
      municipality,
      municipalityId:
        userInFacility && userInFacility.municipality_id
          ? userInFacility.municipality_id
          : null
    });
  };

  /**
   * 実績（新規作成）モーダルを開く
   * @param date
   * @param uifName
   * @param uifId
   */
  private openCreateModal = async (
    date: string,
    uifName: string,
    uifId: number
  ): Promise<void> => {
    // ユーザーに設定されている自治体情報を取得する
    await this.props.fetchUserInFacility(uifId.toString());
    const userInFacility = this.props.usersInFacilityState.user
      .user_in_facility_idoshien;
    if (userInFacility && userInFacility.municipality_id) {
      await this.props.fetchMunicipality(
        String(userInFacility.municipality_id)
      );
    } else {
      await this.props.clearMunicipality();
    }
    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues();
    init.initial.targetDate = dateInYYYYMMDDFormat(this.state.selectedDate);
    init.initial.name = uifName;
    init.initial.usersInFacilityId = uifId.toString();

    this.setState({
      data: init,
      modalTarget: IDOSHIEN_INPUT_CLASS_LIST.CREATE.label,
      isOpenDetailModal: true,
      municipality: {
        calculationTimeFlg: this.props.municipality.calculation_time_flg === 1,
        roundUpMinute:
          this.props.municipality.calculation_time_flg === 1
            ? this.props.municipality.round_up_minute
            : null,
        timeDivisionFlg: this.props.municipality.time_division_flg === 1
      },
      municipalityId:
        userInFacility && userInFacility.municipality_id
          ? userInFacility.municipality_id
          : null
    });
  };

  /**
   * 実績編集モーダルを閉じる
   */
  private onCancel = (): void => {
    this.setState({ isOpenDetailModal: false });
  };

  /**
   * 実績編集モーダルの更新
   */
  private onSubmit = (): void => {
    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);
  };

  /**
   * 削除アイコンのクリックイベント（確認モーダルオープン）
   * ※計画/実績の判定は子のほうで判定するため、ここでは共通化している
   */
  private onClickDeleteIcon = (target: DeleteReportState): void => {
    this.setState({ deleteTarget: target, isOpenDeleteDialog: true });
  };

  /**
   * 削除処理の実行
   */
  private onDelete = async (): Promise<void> => {
    if (this.state.deleteTarget !== null) {
      await this.props.deleteInoutResults(this.state.deleteTarget);
      this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
    }

    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);
  };

  private onCancelDelete = (): void => {
    this.setState({
      deleteTarget: null,
      isOpenDeleteDialog: false
    });
  };

  /**
   * 一括コピーボタン押下アクション
   */
  private onClickCopyReportListIcon = async (): Promise<void> => {
    // 1.processType=2でAPIをコール
    // 2.実績存在エラーが帰ってきた場合、モーダル表示
    // 3.モーダルの「OKボタン」押下時、processType=3でAPIをコール
    const copyList = this.props.reportState.reportDaily.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, copyList);
    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyReportListTarget: copyList,
        isOpenCopyConfirmDialog: true
      });
    } else {
      this.props.fetchDaily(this.state.selectedDate);
      this.props.fetchInoutError(this.state.selectedDate);
    }
  };

  private onCopyReportList = async (): Promise<void> => {
    if (this.state.copyReportListTarget !== null) {
      await this.props.copyInoutResults(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyReportListTarget
      );

      this.props.fetchDaily(this.state.selectedDate);
      this.props.fetchInoutError(this.state.selectedDate);
    }

    this.setState({
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null
    });
  };

  private onCopyRecord = async (target: CopyReportState): Promise<void> => {
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, [target]);
    this.props.fetchDaily(this.state.selectedDate);
    this.props.fetchInoutError(this.state.selectedDate);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
    }
  };

  private onCloseCopyDialog = (): void => {
    this.setState({
      isOpenCopyConfirmDialog: false
    });
  };

  public render(): JSX.Element {
    const { classes, inoutErrors } = this.props;
    const { selectedDate, headerHeight } = this.state;

    return (
      <>
        <div id="reportDailyHeader" className={classes.headerWrapper}>
          {inoutErrors.hasError && (
            <InvoiceErrorBar
              message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
              onClick={this.onClickErrorDialog}
            />
          )}
          <div className={classes.headerInfoContainer}>
            <InOutReportDailyHeader
              minDate={minDate}
              maxDate={maxDate}
              selectedDate={selectedDate}
              filterValue={this.state.filterValue}
              onChangeDate={this.onChangeDate}
              onChangeFilterSelect={this.onChangeFilterSelect}
            />
          </div>
        </div>
        <Paper elevation={0} className={classes.tableContainer}>
          <InOutReportPaperHeader
            rectangleConfigList={[]}
            onClickCopyReportListIcon={this.onClickCopyReportListIcon}
            type={REPEAT_DAILY}
            disabledCopy={this.props.reportState.reportDaily.planCount === 0}
            groupOperationSupportFlg={
              this.props.user.featureGroup.group_operation_support === 1
            }
          />
          <InOutReportTable
            headerHeight={headerHeight}
            openModal={this.openModal}
            openCreateModal={this.openCreateModal}
            onClickDeleteIcon={this.onClickDeleteIcon}
            onCopyRecord={this.onCopyRecord}
            type={REPEAT_DAILY}
            filterFlg={this.state.filterFlg}
          />
        </Paper>
        <InOutReportDialog
          open={this.state.isOpenDetailModal}
          staffList={this.props.reportState.reportDaily.displayStaffsInFacility}
          usersInFacilityState={this.props.usersInFacilityState}
          municipality={this.state.municipality}
          municipalityId={this.state.municipalityId}
          data={this.state.data}
          apiData={this.state.openedModalApiInoutResultData}
          selectedDate={selectedDate}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          type={REPEAT_DAILY}
          modalTarget={this.state.modalTarget}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDeleteDialog}
          onDelete={this.onDelete}
          onCancel={this.onCancelDelete}
          dialogWidth={600}
          title={`該当の${
            this.state.deleteTarget && this.state.deleteTarget.inputClass === 1
              ? "移動支援計画"
              : "サービス提供実績"
          }を削除します`}
          message={
            <span>
              削除した場合、データの復元はできません。
              <br />
              よろしいですか？
            </span>
          }
        />
        <CopyConfirmDialog
          isOpen={this.state.isOpenCopyConfirmDialog}
          onCopy={this.onCopyReportList}
          onCancel={this.onCloseCopyDialog}
          title="移動支援計画からサービス提供実績へ一括コピー"
          message="当日にすでに実績が登録されているデータが存在します。登録されていない利用者のみ計画をコピーしますか？"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.IDOSHIEN.report,
    inoutErrors: state.errors.inout,
    usersInFacilityState: state.IDOSHIEN.userInFacility,
    municipality: state.IDOSHIEN.municipalitiesInFacility.municipality,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = IDOSHIEN.reportDispatcher(dispatch);
  const municipalityDispatcher = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const uifDispatcher = IDOSHIEN.userInFacilityDispatcher(dispatch);
  return {
    fetchDaily: (date: Date): Promise<void> =>
      reportDispatcher.fetchIDOSHIENDaily(date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    fetchMunicipality: (municipalityId: string): Promise<void> =>
      municipalityDispatcher.fetchOne(municipalityId),
    clearMunicipality: (): Promise<void> => municipalityDispatcher.clearOne(),
    openErrorsDialog: (): {
      readonly type: "UI/SHOW_ERRORS_DIALOG";
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    deleteInoutResults: (target: DeleteReportState): Promise<void> =>
      reportDispatcher.deleteIDOSHIENReport(target),
    copyInoutResults: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postIDOSHIENReportCopy(processType, target),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportDaily = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportDailyCore));
