import _isEqual from "lodash-es/isEqual";
import { RequestParamToPostAPI } from "@api/requests/facility/postFacilityMunicipality";
import { MunicipalityValues } from "@initialize/mgr/IDOSHIEN/Municipality/initialValues";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import castNumberOrNull from "@utils/dataNormalizer/castNumberOrNull";
import { MUNICIPALITY_BILLING_UNIT_UNIT } from "@constants/mgr/IDOSHIEN/variables";
import { GetServiceCodePaginationResponse } from "@api/requests/serviceCodes/getServiceCodePagination";
import { GetServiceCodeSearchResponse } from "@api/requests/serviceCodes/getServiceCodeSearch";
import { ServiceCodesState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";

/**
 * 自治体情報をフォーム値からpostデータへ変換
 */
export const normalizeFormToPostData = (
  values: MunicipalityValues,
  /** アカウントが請求権限を持っているか */
  hasInvoiceAuthority: boolean,
  municipalityId: number | null,
  initialValues?: MunicipalityValues
): RequestParamToPostAPI => {
  const requestData: RequestParamToPostAPI = {
    json_data: {
      municipality_id: municipalityId,
      name: values.name,
      calculation_time_flg: booleanToNumber0or1(values.calculationTimeFlag),
      round_up_minute: values.calculationTimeFlag
        ? castNumberOrNull(values.roundUpMinute)
        : null,
      time_division_flg: booleanToNumber0or1(values.timeDivisionFlg),
      billing_unit: castNumberOrNull(values.billingUnit),
      unit_price_per_unit:
        values.billingUnit === MUNICIPALITY_BILLING_UNIT_UNIT
          ? castNumberOrNull(values.unitPricePerUnit)
          : null
    }
  };

  // ファイルが存在する場合にはファイルを設定
  if (values.serviceCodeCsv !== null) {
    requestData.service_code_csv = values.serviceCodeCsv;
  }

  // 請求権限がない場合は、不要な項目を更新対象から除外する
  if (!hasInvoiceAuthority) {
    delete requestData.json_data.time_division_flg;
    delete requestData.json_data.billing_unit;
    delete requestData.json_data.unit_price_per_unit;
  }

  // 差分チェック
  if (initialValues) {
    if (_isEqual(values.name, initialValues.name)) {
      delete requestData.json_data.name;
    }
    if (
      _isEqual(values.calculationTimeFlag, initialValues.calculationTimeFlag)
    ) {
      delete requestData.json_data.calculation_time_flg;
    }
    if (
      _isEqual(values.calculationTimeFlag, initialValues.calculationTimeFlag) &&
      _isEqual(values.roundUpMinute, initialValues.roundUpMinute)
    ) {
      delete requestData.json_data.round_up_minute;
    }
    if (_isEqual(values.timeDivisionFlg, initialValues.timeDivisionFlg)) {
      delete requestData.json_data.time_division_flg;
    }
    if (_isEqual(values.billingUnit, initialValues.billingUnit)) {
      delete requestData.json_data.billing_unit;
    }
    if (
      _isEqual(values.billingUnit, initialValues.billingUnit) &&
      _isEqual(values.unitPricePerUnit, initialValues.unitPricePerUnit)
    ) {
      delete requestData.json_data.unit_price_per_unit;
    }
  }

  return requestData;
};

/**
 * サービスコード情報
 */
export const normalizeIDOSHIENServiceCodeSearchAPI = (
  data: GetServiceCodeSearchResponse["data"]
): ServiceCodesState => {
  return {
    count: data.count,
    page: data.page ? data.page : 1,
    serviceCodes: data.service_codes.map((x) => ({
      id: x.service_code_id,
      name: x.name,
      serviceCode: x.service_code ? x.service_code : "",
      unitPrice: x.unit_price !== null ? String(x.unit_price) : ""
    }))
  };
};

/**
 * サービスコード情報(条件から絞り込んで登録する用)
 */
export const normalizeIDOSHIENServiceCodePaginationAPI = (
  data: GetServiceCodePaginationResponse["data"]
): ServiceCodesState => {
  return {
    count: data.count,
    page: data.page,
    serviceCodes: data.service_codes.map((x) => ({
      id: x.service_code_id,
      name: x.name,
      serviceCode: x.service_code ? x.service_code : "",
      unitPrice: x.unit_price ? String(x.unit_price) : ""
    }))
  };
};
