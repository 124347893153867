import * as React from "react";

// material ui components
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      height: 64,
      backgroundColor: palette.background.default
    }
  });

type OwnProps = {
  children: React.ReactNode;
  position?: "fixed" | "absolute" | "sticky" | "static" | "relative";
};

type Props = WithStyles<typeof styles> & OwnProps;

const MgrContentHeader = ({
  classes,
  children,
  position = "relative"
}: Props): JSX.Element => {
  return (
    <AppBar
      position={position}
      color="inherit"
      className={classes.wrapper}
      elevation={0}
    >
      {children}
    </AppBar>
  );
};

export default withStyles(styles)(MgrContentHeader);
