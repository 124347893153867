import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import { Theme, WithStyles, withStyles, createStyles } from "@material-ui/core";
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  UsersValues
} from "@initialize/mgr/IDOSHIEN/users/initialValues";
import { validation } from "@initialize/mgr/IDOSHIEN/users/validation";
import Button from "@material-ui/core/Button";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import { BasicFields } from "@components/organisms/mgr/IDOSHIEN/Users/BasicFields";
import { ServiceUseFields } from "@components/organisms/mgr/IDOSHIEN/Users/ServiceUseFields";
import dispatches from "@stores/dispatches";
import { CityState, CityParams } from "@stores/domain/city/type";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import { FacilityState } from "@stores/domain/mgr/IDOSHIEN/facility/types";
import * as URL from "@constants/url";
import isEqual from "lodash-es/isEqual";
import { AppState } from "@stores/type";
import * as H from "history";
import { StyleRules } from "@material-ui/core/styles";
import { GetFacilityMunicipalitiesResponse } from "@api/requests/facility/getFacilityMunicipalities";
import { FieldItem } from "@interfaces/ui/form";
import { DEFAULT_DROP_DOWN_OPTION } from "@constants/variables";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    wrapper: {
      height: spacing.unit * 8,
      top: 0
    },
    cancelButton: {
      marginRight: spacing.unit,
      boxShadow: "none",
      color: "#0277bd",
      border: "solid 1px rgba(0, 0, 0, 0.12)",
      backgroundColor: "rgba(98, 2, 238, 0)"
    }
  });

type DispatchProps = {
  fetchFacility: () => void;
  createUser: (values: UsersValues, history: H.History) => void;
  fetchCity: (params: CityParams) => void;
  clearCity: () => void;
  fetchMunicipalitiesInFacility: () => void;
  clearMunicipalitiesInFacility: () => void;
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  facility: FacilityState;
  cityList: CityState[];
  needsStopHistory: boolean;
  facilityMunicipalities: GetFacilityMunicipalitiesResponse["data"]["municipalities"];
};

type OwnProps = RouteComponentProps & WithStyles<typeof styles>;

type MergeProps = DispatchProps & StateProps & OwnProps;

const CreateUserFormCore = (props: MergeProps): JSX.Element => {
  const [municipalityOption, setMunicipalityOption] = React.useState<
    FieldItem[]
  >([DEFAULT_DROP_DOWN_OPTION]);

  React.useEffect(() => {
    if (Object.keys(props.cityList).length > 1) {
      props.clearCity();
    }
    props.fetchFacility();
    if (Object.keys(props.facilityMunicipalities).length > 1) {
      props.clearMunicipalitiesInFacility();
    }
    props.fetchMunicipalitiesInFacility();
  }, []);

  // municipalityOptionsの作成
  React.useEffect(() => {
    const hasFacilityMunicipalitiesList =
      props.facilityMunicipalities &&
      Object.keys(props.facilityMunicipalities).length !== 1;
    if (hasFacilityMunicipalitiesList) {
      const municipalityOptions = Object.keys(props.facilityMunicipalities).map(
        (key) => {
          const { name, id } = props.facilityMunicipalities[key];
          return { label: name, value: id };
        }
      );
      municipalityOptions.unshift(DEFAULT_DROP_DOWN_OPTION);
      setMunicipalityOption(municipalityOptions);
    }
  }, [props.facilityMunicipalities]);

  const confirmDiscardFormChanges = (nextValues: UsersValues): void => {
    const hasChange = !isEqual(nextValues, initialValues());
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const validate = (values: UsersValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const onSubmit = async (
    values: UsersValues,
    actions: FormikActions<UsersValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props.createUser(values, props.history);
    actions.setSubmitting(false);
  };

  const onCancel = (): void => {
    props.history.push(URL.USERS);
  };

  return (
    <Formik
      initialValues={initialValues()}
      validate={validate}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formikProps): JSX.Element => (
        <Form>
          <ContentHeader
            position="sticky"
            classes={{ wrapper: props.classes.wrapper }}
          >
            <ContentHeaderRight>
              <Button
                className={props.classes.cancelButton}
                variant="contained"
                onClick={onCancel}
              >
                キャンセル
              </Button>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                errorAction={submitError}
              />
            </ContentHeaderRight>
          </ContentHeader>

          {/* 基本情報 */}
          <BasicFields
            municipalityOption={municipalityOption}
            formikProps={formikProps}
            setFormikFieldValue={formikProps.setFieldValue}
          />
          {/* サービス利用詳細 */}
          <ServiceUseFields
            formikProps={formikProps}
            facility={props.facility}
            setFormikFieldValue={formikProps.setFieldValue}
          />
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.IDOSHIEN.facility,
  cityList: state.city,
  needsStopHistory: state.ui.needsStopHistory,
  facilityMunicipalities: state.IDOSHIEN.municipalitiesInFacility.municipalities
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { IDOSHIEN, cityDispatch, uiDispatch } = dispatches;
  const userInFacilityDispatcher = IDOSHIEN.userInFacilityDispatcher(dispatch);
  const facilityDispatcher = IDOSHIEN.userInFacilityDispatcher(dispatch);
  const municipalitiesInFacilityDispatched = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const cityDispatches = cityDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  return {
    fetchFacility: facilityDispatcher.fetch,
    createUser: userInFacilityDispatcher.create,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    clearCity: cityDispatches.clearCity,
    fetchMunicipalitiesInFacility: municipalitiesInFacilityDispatched.fetch,
    clearMunicipalitiesInFacility: municipalitiesInFacilityDispatched.clear,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const CreateUserForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps, mergeProps)(CreateUserFormCore)
);
