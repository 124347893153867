import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/v202104/inOutResults/postInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopy";
import { RequestParamToPostCopyCarePlanAPI } from "@api/requests/v202104/inOutResults/postInOutResultsCopyCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/v202104/inOutResults/deleteInOutResults";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchKODOENGODailyStarted = () =>
  ({ type: types.FETCH_KODOENGO_DAILY_STARTED } as const);
export const fetchKODOENGODaily = (res: types.ReportState["reportDaily"]) =>
  ({ type: types.FETCH_KODOENGO_DAILY, payload: res } as const);
export const fetchKODOENGODailyFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KODOENGO_DAILY_FAILED, error: err } as const);
export const fetchKODOENGOUserStarted = () =>
  ({ type: types.FETCH_KODOENGO_MONTHLY_STARTED } as const);
export const fetchKODOENGOUser = (res: types.ReportState["reportMonthly"]) =>
  ({ type: types.FETCH_KODOENGO_MONTHLY, payload: res } as const);
export const fetchKODOENGOUserFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KODOENGO_MONTHLY_FAILED, error: err } as const);

export const postKODOENGOReportStarted = () =>
  ({ type: types.POST_KODOENGO_REPORT_STARTED } as const);
export const postKODOENGOReportDaily = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KODOENGO_REPORT_DAILY, payload: req } as const);
export const postKODOENGOReportUser = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KODOENGO_REPORT_MONTHLY, payload: req } as const);
export const postKODOENGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KODOENGO_REPORT_FAILED, error: err } as const);

export const postKODOENGOReportCopyStarted = () =>
  ({ type: types.POST_KODOENGO_REPORT_COPY_STARTED } as const);
export const postKODOENGOReportCopy = (req: RequestParamToPostCopyAPI) =>
  ({ type: types.POST_KODOENGO_REPORT_COPY, payload: req } as const);
export const postKODOENGOReportCopyFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KODOENGO_REPORT_COPY_FAILED, error: err } as const);

export const postKODOENGOReportCopyCarePlanStarted = () =>
  ({ type: types.POST_KODOENGO_REPORT_COPY_CARE_PLAN_STARTED } as const);
export const postKODOENGOReportCopyCarePlan = (
  req: RequestParamToPostCopyCarePlanAPI
) =>
  ({
    type: types.POST_KODOENGO_REPORT_COPY_CARE_PLAN,
    payload: req
  } as const);
export const postKODOENGOReportCopyCarePlanFailed = (err: ErrorResponse) =>
  ({
    type: types.POST_KODOENGO_REPORT_COPY_CARE_PLAN_FAILED,
    error: err
  } as const);

export const deleteKODOENGOReportStarted = () =>
  ({ type: types.DELETE_KODOENGO_REPORT_STARTED } as const);
export const deleteKODOENGOReport = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_KODOENGO_REPORT, payload: req } as const);
export const deleteKODOENGOReportFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_KODOENGO_REPORT_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchKODOENGODailyStarted>
  | ReturnType<typeof fetchKODOENGODaily>
  | ReturnType<typeof fetchKODOENGODailyFailed>
  | ReturnType<typeof fetchKODOENGOUserStarted>
  | ReturnType<typeof fetchKODOENGOUser>
  | ReturnType<typeof fetchKODOENGOUserFailed>
  | ReturnType<typeof postKODOENGOReportStarted>
  | ReturnType<typeof postKODOENGOReportDaily>
  | ReturnType<typeof postKODOENGOReportUser>
  | ReturnType<typeof postKODOENGOReportFailed>
  | ReturnType<typeof postKODOENGOReportCopyStarted>
  | ReturnType<typeof postKODOENGOReportCopy>
  | ReturnType<typeof postKODOENGOReportCopyFailed>
  | ReturnType<typeof postKODOENGOReportCopyCarePlanStarted>
  | ReturnType<typeof postKODOENGOReportCopyCarePlan>
  | ReturnType<typeof postKODOENGOReportCopyCarePlanFailed>
  | ReturnType<typeof deleteKODOENGOReportStarted>
  | ReturnType<typeof deleteKODOENGOReport>
  | ReturnType<typeof deleteKODOENGOReportFailed>;
