import * as React from "react";
import { connect } from "react-redux";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import Table from "@components/molecules/Table";
import TableHead, { HeaderProps } from "@components/molecules/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import AddIcon from "@material-ui/icons/Add";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { CarePlanTableCells } from "@components/v202104/organisms/mgr/JUDOHOMONKAIGO/Users/CarePlanTableCells";
import { AppState } from "@stores/type";
import { JUDOHOMONKAIGO_DAY_OF_WEEK_LIST } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import {
  CarePlanStaffState,
  CarePlanState,
  DisplayListState,
  DeleteCarePlanState
} from "@stores/v202104/domain/mgr/JUDOHOMONKAIGO/carePlan/types";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: 914
    },
    tableHeader: {
      color: "#000000"
    },
    tableBody: {
      "&:last-child": {
        borderBottom: "1px solid #000000"
      }
    },
    tableRow: {
      height: 56,
      "&:nth-of-type(even)": {
        backgroundColor: "#f5f5f5"
      }
    },
    border: {
      "& > :first-child": {
        backgroundColor: "#ffffff",
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
        backgroundSize: "6px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left top"
      },
      "& > :last-child": {
        backgroundColor: "#ffffff",
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
        backgroundSize: "6px 1px",
        backgroundRepeat: "repeat-x",
        backgroundPosition: "left top"
      },
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left top"
    },
    superShortNumberCell: {
      width: "2%",
      minWidth: 16,
      maxWidth: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    superShortCell: {
      width: "6.3%",
      minWidth: 16,
      maxWidth: 16,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    shortCell: {
      width: "10.5%",
      minWidth: 44,
      maxWidth: 44,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    middleCell: {
      width: "16.4%",
      minWidth: 150,
      maxWidth: 150,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    firstCellStyle: {
      textAlign: "center"
    },
    saturday: {
      color: "#06a6e9"
    },
    sunday: {
      color: "#ff5656"
    },
    tableCell: {
      paddingTop: 14,
      paddingBottom: 14
    },
    sticky: {
      zIndex: 10,
      position: "sticky"
    },
    icon: {
      paddingLeft: 15,
      textAlign: "center"
    },
    iconDiv: {
      height: 26
    }
  });

type StateProps = {
  carePlanStaffState: CarePlanStaffState;
};
type OwnProps = {
  uifId: number;
  nameSei: string;
  nameMei: string;
  hideAddButton: boolean;
  openModal: (param: CarePlanState) => void;
  openCreateModal: (dayOfWeek: number, uifName: string, uifId: number) => void;
  onClickDeleteIcon: (target: DeleteCarePlanState) => void;
};

type Props = StateProps & OwnProps & WithStyles<typeof styles>;

const carePlanHeader = (classes: Props["classes"]): HeaderProps => {
  return {
    tabIndex: -1,
    key: 0,
    selected: false,
    items: [
      {
        align: "center",
        label: "曜日",
        className: `${classes.superShortCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "",
        className: `${classes.superShortNumberCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "サービス提供の状況",
        className: `${classes.middleCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "開始時間",
        className: `${classes.shortCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "終了時間",
        className: `${classes.shortCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "時間",
        className: `${classes.shortCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "移動時間",
        className: `${classes.shortCell} ${classes.tableHeader}`
      },
      {
        align: "left",
        label: "人数",
        className: `${classes.shortCell} ${classes.tableHeader}`
      },
      {
        align: "center",
        label: "削除",
        className: `${classes.superShortCell} ${classes.tableHeader}`
      },
      {
        align: "center",
        label: "編集",
        className: `${classes.superShortCell} ${classes.tableHeader}`
      },
      {
        align: "center",
        label: "追加",
        className: `${classes.superShortCell} ${classes.tableHeader}`
      }
    ]
  };
};

const CarePlanTableCore = (props: Props): JSX.Element => {
  const { carePlanStaffState } = props;
  const tableHead = carePlanHeader(props.classes);

  const planEmpty = {
    dispatchNo: null,
    status: null,
    inTime: null,
    outTime: null,
    calculatedHours: null,
    calculatedMovingHours: null,
    numberOfParticipants: null
  };

  const detailEmpty = {
    id: 0,
    usersInFacilityId: 0,
    dayOfWeek: 0,
    nameSei: "",
    nameMei: "",
    status: "",
    numberOfParticipants: null,
    practitioner1: null,
    practitioner1License: null,
    practitioner1InTime: "",
    practitioner1OutTimeClass: "",
    practitioner1OutTime: "",
    practitioner1CalculatedHours: null,
    practitioner1CalculatedMovingHours: null,
    practitioner1Memo: null,
    carePlanDetailState1: [],
    practitioner2: null,
    practitioner2License: null,
    practitioner2InTimeClass: "",
    practitioner2InTime: "",
    practitioner2OutTimeClass: "",
    practitioner2OutTime: "",
    practitioner2CalculatedHours: null,
    practitioner2Memo: null,
    carePlanDetailState2: [],
    timeDuplicationJudgmentFlg: false,
    wholeCalculatedHours: null,
    duplicateCalculatedHours: null,
    licenseSameFlg: false,
    accompanySupportFlg: false,
    movingTimeDuplicationJudgmentFlg: false,
    wholeCalculatedMovingHours: null,
    duplicateCalculatedMovingHours: null,
    accompanySupportInTimeClass: "",
    accompanySupportInTime: "",
    accompanySupportOutTimeClass: "",
    accompanySupportOutTime: "",
    wholeCalculatedAccompanySupportHours: null,
    wholeCalculatedAccompanySupportMovingHours: null,
    secondPersonFlg: false,
    sputumImplementationFlg: false,
    movingHours: null
  };

  const dataListEmpty = [
    {
      no: 0,
      id: 0,
      plan: planEmpty,
      planDetail: detailEmpty
    }
  ];

  const rows: DisplayListState[] = [];

  for (let i = 1; i <= 7; i += 1) {
    const displayRows = carePlanStaffState.displayList.filter((row) => {
      return row.dayOfWeek === i;
    });
    if (displayRows.length) {
      displayRows.map((item) => {
        return rows.push(item);
      });
    } else {
      rows.push({
        usersInFacilityId: props.uifId,
        nameSei: props.nameSei,
        nameMei: props.nameMei,
        dayOfWeek: i,
        dataList: dataListEmpty
      });
    }
  }

  const TableList = rows
    .filter((row) => {
      return row.dayOfWeek !== -1;
    })
    .map((row, index) => {
      const openCreateModal = (): void => {
        const name = `${row.nameSei} ${row.nameMei}`;
        props.openCreateModal(row.dayOfWeek, name, row.usersInFacilityId);
      };
      return row.dataList.map((data, subIndex) => {
        const idx = `${index}${subIndex}`;
        const border =
          index !== 0 && subIndex === 0 ? props.classes.border : "";
        return (
          <TableRow
            key={`table-row-${idx}`}
            className={`${props.classes.tableRow} ${border}`}
          >
            <>
              {subIndex === 0
                ? index < 5 && (
                    <TableCellWrap
                      key={`${idx}-sei-mei`}
                      cellClass={`${props.classes.superShortCell} ${props.classes.firstCellStyle} `}
                      rowSpan={row.dataList.length}
                    >
                      {getLabelFromOptions(
                        row.dayOfWeek.toString(),
                        JUDOHOMONKAIGO_DAY_OF_WEEK_LIST
                      )}
                    </TableCellWrap>
                  )
                : null}
              {subIndex === 0
                ? index === 5 && (
                    <TableCellWrap
                      key={`${idx}-sei-mei`}
                      cellClass={`${props.classes.superShortCell} ${props.classes.firstCellStyle}  ${props.classes.saturday}`}
                      rowSpan={row.dataList.length}
                    >
                      {getLabelFromOptions(
                        row.dayOfWeek.toString(),
                        JUDOHOMONKAIGO_DAY_OF_WEEK_LIST
                      )}
                    </TableCellWrap>
                  )
                : null}
              {subIndex === 0
                ? index === 6 && (
                    <TableCellWrap
                      key={`${idx}-sei-mei`}
                      cellClass={`${props.classes.superShortCell} ${props.classes.firstCellStyle}  ${props.classes.sunday}`}
                      rowSpan={row.dataList.length}
                    >
                      {getLabelFromOptions(
                        row.dayOfWeek.toString(),
                        JUDOHOMONKAIGO_DAY_OF_WEEK_LIST
                      )}
                    </TableCellWrap>
                  )
                : null}
              <CarePlanTableCells
                dayOfWeek={row.dayOfWeek}
                params={data}
                idx={`${index}${subIndex}`}
                classOption={props.classes.tableCell}
                openModal={props.openModal}
                onClickDeleteIcon={props.onClickDeleteIcon}
              />
              {/* 削除 */}
              {subIndex === 0 ? (
                <>
                  {/* 追加ボタン */}
                  <TableCellWrap
                    key={`${idx}-add-icon`}
                    cellClass={`${props.classes.superShortCell} ${props.classes.icon} ${props.classes.tableCell}`}
                    align="center"
                    rowSpan={row.dataList.length}
                  >
                    {!props.hideAddButton && (
                      <div className={props.classes.iconDiv}>
                        <AddIcon
                          style={{
                            width: 24,
                            height: 24,
                            color: "#0277bd",
                            cursor: "pointer"
                          }}
                          onClick={openCreateModal}
                        />
                      </div>
                    )}
                  </TableCellWrap>
                </>
              ) : null}
            </>
          </TableRow>
        );
      });
    });
  return (
    <>
      <div className={props.classes.sticky}>
        <Table key="care-plan-table" className={props.classes.table}>
          <TableHead
            role={tableHead.role}
            ariaChecked={tableHead.ariaChecked}
            tabIndex={tableHead.tabIndex}
            key={tableHead.key}
            selected={tableHead.selected}
            items={tableHead.items}
            rowStyle={tableHead.rowStyle}
            headerStyle={{ backgroundColor: "#eceff1" }}
          />
        </Table>
      </div>
      <Table key="care-plan-table" className={props.classes.table}>
        <TableBody className={props.classes.tableBody}>
          {TableList.length > 0 && TableList}
        </TableBody>
      </Table>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    carePlanStaffState: state.v202104.JUDOHOMONKAIGO.carePlan
  };
};

export const CarePlanTable = connect(mapStateToProps)(
  withStyles(styles)(CarePlanTableCore)
);
