import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

// ui
import LineBreak from "@components/atoms/LineBreak";

// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH
} from "@/constants/styles";
import { dateTodayInFormat } from "@utils/date";
import { OperationsAndSupportsState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import {
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { CHIIKIIKO_MODAL_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";
import { FacilityType } from "@constants/variables";

const TABLE_HEAD_HEIGHT = 25;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: 18,
      letterSpacing: 1.2,
      textAlign: "left",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginTop: 16,
      marginBottom: 16
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "4px 5px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.ssize": {
          width: 54
        },
        "&.msize": {
          width: 84
        },
        "&.lsize": {
          width: 86
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.topAlign": {
          verticalAlign: "top"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    contents: {
      margin: 0,
      overflowWrap: "break-word",
      wordWrap: "break-word"
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  facilityType: FacilityType;
  facilitySubType: number | null;
  facilityUsers: GetFacilityUsersResponse["data"];
};

type OwnProps = {
  displayColumns: string[];
  date: string;
  supportRecords: OperationsAndSupportsState["support_records"];
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

type StatusList = {
  label: string;
  value: string;
}[];

const getStatusList = (
  facilityType: FacilityType,
  type_consultation: number
): StatusList => {
  if (facilityType === FacilityType.CHIIKITEICHAKU) {
    return CHIIKITEICHAKU_DISPLAY_SERVICE_STATUS_LIST;
  }
  if (facilityType === FacilityType.CHIIKIIKO) {
    return CHIIKIIKO_MODAL_STATUS_LIST;
  }
  if (
    facilityType === FacilityType.KEIKAKUSODAN &&
    type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN
  ) {
    return STATUS_LIST_SHOGAIJISODAN;
  }
  return STATUS_LIST;
};

const getSupportContents = (
  supportRecord: OperationsAndSupportsState["support_records"][number],
  isDisplayStaffComment: boolean,
  classes: Record<string, string>,
  statusList: StatusList
): JSX.Element[] => {
  const supportContents: JSX.Element[] = [];

  if (supportRecord.status) {
    const status = statusList.find(
      (v) => Number(v.value) === supportRecord.status
    );
    supportContents.push(
      <p
        key={`support-contents-status-${supportRecord.support_records_id}`}
        className={classes.contents}
      >
        {`[サービス内容] ${status ? status.label : STATUS_LIST[0].label}`}
      </p>
    );
  }
  if (supportRecord.support_content) {
    supportContents.push(
      <p
        key={`support-contents-content-${supportRecord.support_records_id}`}
        className={classes.contents}
      >
        [支援内容] <LineBreak text={supportRecord.support_content} />
      </p>
    );
  }
  if (isDisplayStaffComment && supportRecord.staff_comment) {
    supportContents.push(
      <p
        key={`support-contents-comment-${supportRecord.support_records_id}`}
        className={classes.contents}
      >
        [職員考察] <LineBreak text={supportRecord.staff_comment} />
      </p>
    );
  }

  return supportContents;
};

const DailyPrintSupportCsegCore = (props: Props): JSX.Element | null => {
  const {
    classes,
    displayColumns,
    supportRecords,
    date,
    facilityType,
    facilitySubType,
    facilityUsers
  } = props;
  const isDisplayStaffComment = displayColumns.includes("staff_comment");

  const isExistContents = supportRecords.some(
    (v) =>
      v.status ||
      v.support_content ||
      (isDisplayStaffComment && v.staff_comment)
  );

  return (
    <div className={classes.page}>
      <div>
        <header>
          <h1 className={classes.title}>支援記録</h1>
        </header>
        <div>
          <div className={classes.subTitle}>
            <span>{dateTodayInFormat(date, true)}</span>
          </div>
        </div>
        {supportRecords.length > 0 && isExistContents && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label lsize">利用者名</td>
                  <td className="label">支援内容</td>
                  <td className="label msize">記録者</td>
                  <td className="label ssize">確認印</td>
                </tr>
                {supportRecords.map((supportRecord) => {
                  // 利用者
                  const user = facilityUsers.find(
                    (item) => item.uif_id === supportRecord.users_in_facility_id
                  );
                  const userName = user ? user.displayName : "";

                  const isKeikakuAndSHogaijiFacility = (): boolean =>
                    facilitySubType ===
                    FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
                  const isShogaiji = (): boolean =>
                    supportRecord.type_consultation ===
                    TYPE_CONSULTATION_SHOGAIJISODAN;

                  const statusList = getStatusList(
                    facilityType,
                    supportRecord.type_consultation
                  );

                  const supportContents = getSupportContents(
                    supportRecord,
                    isDisplayStaffComment,
                    classes,
                    statusList
                  );

                  if (!supportContents.length) {
                    return null;
                  }

                  return (
                    <tr key={supportRecord.users_in_facility_id}>
                      <td className="label topCenterAlign">
                        {userName}
                        {isKeikakuAndSHogaijiFacility() && isShogaiji() && (
                          <>
                            <br />
                            （障害児）
                          </>
                        )}
                      </td>
                      <td className="topAlign">{supportContents}</td>
                      <td className="topCenterAlign">
                        {supportRecord.staffs
                          .map((v) => getSnapOrRealName(v, ""))
                          .join("、")}
                      </td>
                      <td className="label" />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    facilitySubType: state.KEIKAKUSODAN.facility.facilityType,
    facilityUsers: state[facilityType].userInFacility.users
  };
};

export const DailyPrintSupportCseg = connect(mapStateToProps)(
  withStyles(styles)(DailyPrintSupportCsegCore)
);
