import { removeHyphenFromPostalCode } from "@utils/dataNormalizer";

export const requiredMessage = "必須です";
export const numberMessage = "数値を入力してください";
export const notSelectedMessage = "選択してください";
export const notCountMessage = "桁で入力してください";
export const notMailMessage = "メール形式で入力してください";
export const notPostalCode = "郵便番号形式で入力してください";
export const notKanaMessage = "全角カタカナで入力してください";
export const notPast = "よりも前に設定してください";
export const notFuture = "よりも後に設定してください";

/* eslint-disable @typescript-eslint/no-explicit-any */
const isNull = (value: any): boolean => value === null;

type CombineValidateParams = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  validator: (data: any, rule: any) => boolean;
  errorMessage: string;
};

type DependencyFlgListParams = {
  flgName: string;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value: any;
  valueGroup?: string;
};

export type ValidationRuleParams = {
  rule: CombineValidateParams[];
  default?: string;
  valueGroup?: string;
  label?: string;
  dependencyDate?: {
    label: string;
    value: string;
  };
  dependencyValue?: {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    normalValue: any;
    /* eslint-disable @typescript-eslint/no-explicit-any */
    nowValue: any;
  };
  count?: number;
  dependencyFlgList?: DependencyFlgListParams;
};

export const combineValidator = (
  rule: ValidationRuleParams,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value: any
): string[] => {
  let validator: CombineValidateParams[] = [...rule.rule];
  validator = validator.map((item) => {
    return { ...item };
  });
  const invalidData = validator.filter((item) => {
    if (item.errorMessage === notCountMessage) {
      /* eslint-disable no-param-reassign */
      item.errorMessage = rule.count + notCountMessage;
    }
    if (item.errorMessage === notFuture) {
      if (rule.dependencyDate) {
        /* eslint-disable no-param-reassign */
        item.errorMessage = `${rule.label}は${rule.dependencyDate.label}${notFuture}`;
      }
    }
    if (item.errorMessage === notPast) {
      if (rule.dependencyDate) {
        /* eslint-disable no-param-reassign */
        item.errorMessage = `${rule.label}は${rule.dependencyDate.label}${notPast}`;
      }
    }
    return !item.validator(value, rule);
  });
  return invalidData.map((item) => item.errorMessage);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const requiredValidator = (value: any): boolean => {
  let isValid = true;
  if (
    value === undefined ||
    isNull(value) ||
    value === "" ||
    (typeof value === "number" && Number.isNaN(value))
  ) {
    isValid = false;
  }
  return isValid;
};

export const dependencyRequiredValidator = (
  /* eslint-disable @typescript-eslint/no-explicit-any */
  value: any,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  rule?: any
): boolean => {
  if (rule.dependencyValue.nowValue === rule.dependencyValue.normalValue) {
    return true;
  }
  let isValid = true;
  if (
    value === undefined ||
    isNull(value) ||
    value === "" ||
    (typeof value === "number" && Number.isNaN(value))
  ) {
    isValid = false;
  }
  return isValid;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const kanaValidator = (value: any): boolean => {
  const kanaList = /^[\u30A0-\u30FF]+$/;
  let isValid = true;
  if (!value.match(kanaList)) {
    isValid = false;
  }
  return isValid;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const numberValidator = (value: any): boolean => {
  const numbers = /^[0-9]+$/;
  let isValid = true;
  if (value === "") {
    return true;
  }
  if (
    (typeof value === "number" && Number.isNaN(value)) ||
    !value.match(numbers)
  ) {
    isValid = false;
  }
  return isValid;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const countValidator = (value: any, rule?: any): boolean => {
  if (value === "") {
    return true;
  }
  return value.length === rule.count;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const postalCodeValidator = (value: any): boolean => {
  const numbers = /^[0-9]+$/;
  let isValid = true;
  const removeHyphenValue = removeHyphenFromPostalCode(value);
  if (
    (typeof removeHyphenValue === "number" &&
      Number.isNaN(removeHyphenValue)) ||
    !removeHyphenValue.match(numbers)
  ) {
    isValid = false;
  }
  if (removeHyphenValue.length !== 7) {
    isValid = false;
  }
  return isValid;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const dropDownValidator = (value: any, rule?: any): boolean => {
  return value !== rule.default;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const mailValidator = (value: any): boolean => {
  const mail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  let isValid = true;
  // TODO check `isRequired`
  if (value !== "" && !value.match(mail)) {
    isValid = false;
  }
  return isValid;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const futureValidator = (value: any, rule?: any): boolean => {
  if (rule.dependencyDate.value === "" || value === "") {
    return true;
  }
  const valueDate = new Date(value);
  const ruleDate = new Date(rule.dependencyDate.value);
  return valueDate.getTime() > ruleDate.getTime();
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const pastValidator = (value: any, rule?: any): boolean => {
  if (rule.dependencyDate.value === "" || value === "") {
    return true;
  }
  const valueDate = new Date(value);
  const ruleDate = new Date(rule.dependencyDate.value);
  return valueDate.getTime() < ruleDate.getTime();
};

// テキストボックス必須チェック
export const requiredRules = {
  validator: requiredValidator,
  errorMessage: requiredMessage
};

// 他の項目が特定の値の場合必須チェック
export const dependencyRequiredRules = {
  validator: dependencyRequiredValidator,
  errorMessage: requiredMessage
};

// カタカナチェック
export const kanaRules = {
  validator: kanaValidator,
  errorMessage: notKanaMessage
};

// テキストボックス数字チェック
export const numberRules = {
  validator: numberValidator,
  errorMessage: numberMessage
};

// テキストボックス桁数チェック
export const countRules = {
  validator: countValidator,
  errorMessage: notCountMessage
};

// ドロップダウン必須チェック
export const requiredDropDownRules = {
  validator: dropDownValidator,
  errorMessage: notSelectedMessage
};

// テキストボックス郵便番号チェック
export const postalCodeRules = {
  validator: postalCodeValidator,
  errorMessage: notPostalCode
};

// テキストボックスメールアドレスチェック
export const mailRules = {
  validator: mailValidator,
  errorMessage: notMailMessage
};

// 日付未来日チェック
export const futureRules = {
  validator: futureValidator,
  errorMessage: notFuture
};

// 日付過去日チェック
export const pastRules = {
  validator: pastValidator,
  errorMessage: notPast
};

export const ruleList = {
  required: requiredRules,
  dependencyRequired: dependencyRequiredRules,
  kana: kanaRules,
  number: numberRules,
  count: countRules,
  requiredDropDown: requiredDropDownRules,
  postalCode: postalCodeRules,
  mail: mailRules,
  future: futureRules,
  past: pastRules
};

export const defaultSelectedValue = "default";
