import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormLabel from "@components/molecules/FormLabel";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import WelfareSpecialistPlacementTypeFields from "@components/organisms/mgr/common/facility/items/WelfareSpecialistPlacementTypeFields";
import SeeHearTeam202404Fields from "@components/organisms/mgr/common/facility/items/SeeHeareTeamFields";

import {
  StaffTreatmentSystemTypes,
  StaffTreatmentSpecificSystemTypes,
  FacilityType,
  DEFAULT_RADIO_VALUE,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  SpecificBetterSupporterConditionTypes2024,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  DISABLED_RADIO_ITEMS_VALUE
} from "@constants/variables";

import {
  StaffPlacementTypes,
  StaffPlacementTypesBAfter2404,
  PostEmploymentRetentionRateTypes,
  SevereSupportType,
  DischargeSupportFacilityTypes
} from "@constants/mgr/IAB/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { FormikProps, getIn } from "formik";
import { FacilityValues } from "@initialize/mgr/IAB/facility/initialValues";
import { generateRadioItemsWithDisabled } from "@utils/dataNormalizer/generateRadioItemsWithDisabled";

const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);

const severeSupportTypeRadioItems = generateRadioItems(SevereSupportType);

const staffTreatmentSpecificSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSpecificSystemTypes
);

const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionTypes2024
);

const SpecificBetterSupporterConditionType2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionSubTypes2024
);

const SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled = generateRadioItemsWithDisabled(
  SpecificBetterSupporterConditionSubTypes2024,
  DISABLED_RADIO_ITEMS_VALUE
);

const dischargeSupportFacilityTypeRadioItems = generateRadioItems(
  DischargeSupportFacilityTypes
);
const PostEmploymentRetentionRateTypesRadioItems = generateRadioItems(
  PostEmploymentRetentionRateTypes
);

type Props = {
  formikProps: FormikProps<FacilityValues>;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  // SwitchのON/OFF切り替え時の入力値のフォーマット化
  const onChangeEmploymentTransition = (): void => {
    if (
      getIn(
        props.formikProps.values,
        "additionalItem.employmentTransitionSupportFlag"
      )
    ) {
      props.setFormikFieldValue(
        "additionalItem.employmentTransitionSupportFlag",
        false
      );
      props.setFormikFieldValue("additionalItem.numberOfContinuations", "");
    } else {
      props.setFormikFieldValue(
        "additionalItem.employmentTransitionSupportFlag",
        true
      );
    }
  };

  React.useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType !== DEFAULT_RADIO_VALUE ||
        props.formikProps.values.additionalItem
          .staffTreatmentSpecificSystemType)
    ) {
      props.setFormikFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.setFormikFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (
      ![`${SELECTED_CONDITION_TYPE_2024_V}`].includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      )
    ) {
      props.setFormikFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        "0"
      );
    }
  };

  // OFFだった場合、デフォルト値に戻す
  const onChangeHigherBrainDysfunction = (): void => {
    const init = { year: "NOT_SELECTED", month: "", day: "" };
    if (
      getIn(
        props.formikProps.values,
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg"
      )
    ) {
      props.setFormikFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        false
      );
      props.formikProps.setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFrom",
        init
      );
      props.formikProps.setFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportTo",
        init
      );
    } else {
      props.setFormikFieldValue(
        "additionalItem.higherBrainDysfunctionPersonsSupportFlg",
        true
      );
    }
  };

  // OFFだった場合、目標工賃達成指導員配置加算と目標工賃達成加算をデフォルト値に戻す
  const onChangeTargetKouchinTeacher = (): void => {
    const init = { year: "NOT_SELECTED", month: "", day: "" };
    if (
      getIn(props.formikProps.values, "additionalItem.targetKouchinTeacherFlag")
    ) {
      props.setFormikFieldValue(
        "additionalItem.targetKouchinTeacherFlag",
        false
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetWageTeacherStartDate",
        init
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetWageTeacherEndDate",
        init
      );
      props.setFormikFieldValue(
        "additionalItem.targetKouchinReachedFlag",
        false
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetKouchinReachedStartDate",
        init
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetKouchinReachedEndDate",
        init
      );
    } else {
      props.setFormikFieldValue(
        "additionalItem.targetKouchinTeacherFlag",
        true
      );
    }
  };

  // OFFだった場合、目標工賃達成加算をデフォルト値に戻す
  const onChangeTargetKouchinReached = (): void => {
    const init = { year: "NOT_SELECTED", month: "", day: "" };
    if (
      getIn(props.formikProps.values, "additionalItem.targetKouchinReachedFlag")
    ) {
      props.setFormikFieldValue(
        "additionalItem.targetKouchinReachedFlag",
        false
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetKouchinReachedStartDate",
        init
      );
      props.formikProps.setFieldValue(
        "additionalItem.targetKouchinReachedEndDate",
        init
      );
    } else {
      props.setFormikFieldValue(
        "additionalItem.targetKouchinReachedFlag",
        true
      );
    }
  };

  const isFacilityTypeA =
    props.formikProps.values.basic.serviceType === FacilityType.A;
  const isFacilityTypeB =
    props.formikProps.values.basic.serviceType === FacilityType.B;
  /** 職員配置の選択肢
   *  A型B型の時に使用
   *  B型R6報酬改定以降は項目が切り替わる */
  const staffPlacementTypesRadioItems =
    isFacilityTypeA || isFacilityTypeB
      ? generateRadioItems(
          isFacilityTypeB ? StaffPlacementTypesBAfter2404 : StaffPlacementTypes
        )
      : [];

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        {props.formikProps.values.basic.serviceType === FacilityType.IKOU && (
          <FormikRadioButtons
            name="additionalItem.postEmploymentRetentionRateType"
            label="就職後6ヶ月以上の定着率"
            options={PostEmploymentRetentionRateTypesRadioItems}
          />
        )}
        {(props.formikProps.values.basic.serviceType === FacilityType.A ||
          props.formikProps.values.basic.serviceType === FacilityType.B) && (
          <FormikRadioButtons
            name="additionalItem.staffPlacementType"
            label="職員配置"
            options={staffPlacementTypesRadioItems}
          />
        )}
        {/* 福祉専門職員配置等加算 */}
        <WelfareSpecialistPlacementTypeFields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="welfareSpecialistPlacementType" />}
            />
          }
        />
        {/* 視覚・聴覚言語障害者支援体制加算 */}
        <SeeHearTeam202404Fields formikProps={props.formikProps} />
        <FormikSwitch
          name="additionalItem.higherBrainDysfunctionPersonsSupportFlg"
          label="高次脳機能障害者支援体制加算"
          onChange={onChangeHigherBrainDysfunction}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        {props.formikProps.values.basic.serviceType === FacilityType.IKOU && (
          <FormikCheckbox
            name="additionalItem.employmentSupportTrainingCompletionFlag"
            label="就労支援関係研修修了加算"
          />
        )}
        {(props.formikProps.values.basic.serviceType === FacilityType.A ||
          props.formikProps.values.basic.serviceType === FacilityType.B) && (
          <FormikSwitch
            name="additionalItem.employmentTransitionSupportFlag"
            label="就労移行支援体制加算"
            onChange={onChangeEmploymentTransition}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages
                    name={
                      props.formikProps.values.basic.serviceType ===
                      FacilityType.A
                        ? "employmentTransitionSupportTypeA"
                        : "employmentTransitionSupportTypeB"
                    }
                  />
                }
              />
            }
          >
            <FormGroup row>
              <FormikTextField
                name="additionalItem.numberOfContinuations"
                label="前年度実績の6ヶ月以上就労継続者数"
                endAdornmentLabel="名"
                placeholder="0"
                style={{ width: 280 }}
              />
            </FormGroup>
          </FormikSwitch>
        )}
        {(props.formikProps.values.basic.serviceType === FacilityType.A ||
          props.formikProps.values.basic.serviceType === FacilityType.B) && (
          <FormikRadioButtons
            name="additionalItem.severeSupportType"
            label="重度者支援体制加算"
            options={severeSupportTypeRadioItems}
            tooltip={
              <HelpToolTip
                title={<HelpTipMessages name="severeSupportTypeFlag" />}
              />
            }
          />
        )}
        {props.formikProps.values.basic.serviceType === FacilityType.A && (
          <div>
            <FormLabel label="賃金向上達成指導員配置加算" />
            <div style={{ marginLeft: 15, marginTop: 20 }}>
              <FormikSelectDateNotSelectedDefault
                name="additionalItem.wageUpStartDate"
                label="開始年月日"
                addYearTo={1}
                setFormikFieldValue={props.setFormikFieldValue}
              />
              <FormikSelectDateNotSelectedDefault
                name="additionalItem.wageUpEndDate"
                label="終了年月日"
                addYearTo={5}
                setFormikFieldValue={props.setFormikFieldValue}
              />
            </div>
          </div>
        )}
        {isFacilityTypeB && (
          <FormikSwitch
            name="additionalItem.targetKouchinTeacherFlag"
            label="目標工賃達成指導員配置加算"
            onChange={onChangeTargetKouchinTeacher}
          >
            <FormikSelectDateNotSelectedDefault
              name="additionalItem.targetWageTeacherStartDate"
              label="開始年月日"
              addYearTo={1}
              overrideYearFrom={1986}
              setFormikFieldValue={props.setFormikFieldValue}
            />
            <FormikSelectDateNotSelectedDefault
              name="additionalItem.targetWageTeacherEndDate"
              label="終了年月日（任意）"
              addYearTo={5}
              overrideYearFrom={1986}
              setFormikFieldValue={props.setFormikFieldValue}
            />
            <FormikSwitch
              name="additionalItem.targetKouchinReachedFlag"
              label="目標工賃達成加算"
              onChange={onChangeTargetKouchinReached}
            >
              <FormikSelectDateNotSelectedDefault
                name="additionalItem.targetKouchinReachedStartDate"
                label="開始年月日"
                addYearTo={1}
                overrideYearFrom={1986}
                setFormikFieldValue={props.setFormikFieldValue}
              />
              <FormikSelectDateNotSelectedDefault
                name="additionalItem.targetKouchinReachedEndDate"
                label="終了年月日（任意）"
                addYearTo={5}
                overrideYearFrom={1986}
                setFormikFieldValue={props.setFormikFieldValue}
              />
            </FormikSwitch>
          </FormikSwitch>
        )}
        {props.formikProps.values.basic.serviceType === FacilityType.IKOU && (
          <FormikRadioButtons
            name="additionalItem.dischargeSupportFacilityType"
            label="精神障害者退院支援施設加算"
            options={dischargeSupportFacilityTypeRadioItems}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="dischargeSupportFacilityTypeFlag" />
                }
              />
            }
          />
        )}
        <div style={{ marginBottom: 28 }}>
          <FormGroup>
            <FormikRadioButtons
              name="additionalItem.specificBetterSupporterCondition202404"
              style={{ marginBottom: 0 }}
              label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
              options={
                props.formikProps.values.basic.commuterLifeSupportFlag
                  ? SpecificBetterSupporterConditionType2024RadioItemsDisabled
                  : SpecificBetterSupporterConditionType2024RadioItems
              }
              onClick={onChangeSpecificBetterSupporterConditionType2024}
              tooltip={
                <HelpToolTip
                  title={
                    <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                  }
                />
              }
            />
            {props.formikProps.values.additionalItem
              .specificBetterSupporterCondition202404 === "99" && (
              <FormikRadioButtons
                style={{ marginLeft: 48, marginTop: -2 }}
                name="additionalItem.specificBetterSupporterCondition202404Sub"
                label=""
                options={
                  props.formikProps.values.basic.commuterLifeSupportFlag
                    ? SpecificBetterSupporterConditionTypeSub2024RadioItemsDisabled
                    : SpecificBetterSupporterConditionTypeSub2024RadioItems
                }
              />
            )}
          </FormGroup>
        </div>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={
            !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
              props.formikProps.values.additionalItem.staffTreatmentSystemType
            )
          }
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={
            !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
              props.formikProps.values.additionalItem.staffTreatmentSystemType
            )
          }
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="oldTreatmentImprovementAdditionTips" />
              }
            />
          }
        />
      </FormGroup>
    </FormPaper>
  );
};
