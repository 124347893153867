import { WorkRecordsState } from "@stores/domain/workRecords/types";
import { FacilityType } from "@constants/variables";
import {
  serviceDeliveryDetail,
  serviceDeliverySummary
} from "@utils/domain/workRecords/serviceDelivery";

const supplementText = [
  "※作業合計時間はすでに休憩合計時間が除かれている時間になります。"
];

/**
 * サービス提供状況サマリ
 * @param workRecordState
 * @param facilityType
 * @param targetDate
 * @param facilityUserName
 * @param selectedUserIds
 */
export const serviceDeliveryData = (
  workRecordState: WorkRecordsState,
  facilityType: FacilityType,
  targetDate: string,
  facilityUserName: string,
  selectedUserIds: number[]
): string[][] => {
  const result = [];
  result.push(["<利用者毎のサービス提供状況ごとのサマリ集計>"]);
  result.push(supplementText);
  result.push([]);
  result.push(
    ...serviceDeliverySummary(
      facilityType,
      targetDate,
      facilityUserName,
      workRecordState.data.summary,
      selectedUserIds
    )
  );
  result.push([]);
  result.push(["<利用者毎のサービス提供状況ごとの集計>"]);
  result.push(supplementText);
  result.push([]);

  result.push(
    ...serviceDeliveryDetail(
      facilityType,
      targetDate,
      facilityUserName,
      workRecordState.data.details,
      selectedUserIds
    )
  );

  return result;
};
