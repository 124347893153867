import * as React from "react";
import { FastField, FieldProps, FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormikSelect from "@components/molecules/FormikSelect";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import { dateInYYYYFormat, getWarekiList, getLastDay } from "@utils/date";
import { SelectDateValue } from "@interfaces/ui/form";

// FormikSelectDateではここで定義しているためここに配置
const defaultSelect = { label: "選択してください", value: "NOT_SELECTED" };

type Props = {
  name: string;
  label: string;
  required?: boolean;
  addYearTo?: number;
  overrideYearFrom?: number;
  overrideYearTo?: number;
  style?: React.CSSProperties;
  setFormikFieldValue: (
    fieldName: string,
    value: number | boolean | string
  ) => void;
  tooltip?: React.ReactElement;
};

type ClassProps = Props & {
  value: SelectDateValue;
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>; // 使用されるformikPropsがページごとに違うためany
};

type ClassState = {
  yearOptions: { label: string; value: string | number }[];
  monthOptions: { label: string; value: string }[];
  dayOptions: { label: string; value: string }[];
};

type DayOptions = {
  dayOptions: { label: string; value: string }[];
};

/**
 * FormikSelectDateNotSelectedDefaultの小サイズ版
 * TODO FormikSelectDateNotSelectedDefaultで幅を指定できるよう対応が必要
 * 幅以外はFormikSelectDateNotSelectedDefaultと差分を作らないよう注意
 */
class FormikSelectDateNotSelectedDefaultClass extends React.Component<
  ClassProps,
  ClassState
> {
  constructor(props: ClassProps) {
    super(props);
    this.state = {
      yearOptions: [],
      monthOptions: [],
      dayOptions: []
    };
  }

  public static getDerivedStateFromProps(
    nextProps: ClassProps,
    prevState: ClassState
  ): DayOptions | null {
    const year = +nextProps.value.year;
    const month = +nextProps.value.month;
    if (year && month && prevState.dayOptions.length <= 1) {
      const lastDay = year && month ? getLastDay(year, month) : 0;
      const dayOptions = createOneToNumberOptions(lastDay, "日");
      return { dayOptions };
    }
    return null;
  }

  public componentDidMount(): void {
    const currentYear = +dateInYYYYFormat(new Date());
    const yearFrom = currentYear - 86;
    const from = this.props.overrideYearFrom || yearFrom;
    const to =
      this.props.overrideYearTo || currentYear + (this.props.addYearTo || 0);
    const yearOptions = [defaultSelect, ...getWarekiList(from, to)];
    const monthOptions = createOneToNumberOptions(12, "月");
    const dayOptions = this.newDayOptions(
      +this.props.value.year,
      +this.props.value.month
    );
    this.setState({ yearOptions, monthOptions, dayOptions });
  }

  public shouldComponentUpdate(
    nextProps: ClassProps,
    nextState: ClassState
  ): boolean {
    return (
      nextProps.value.year !== this.props.value.year ||
      nextProps.value.month !== this.props.value.month ||
      nextProps.value.day !== this.props.value.day ||
      nextState.yearOptions.length !== this.state.yearOptions.length ||
      nextState.monthOptions.length !== this.state.monthOptions.length ||
      nextState.dayOptions.length !== this.state.dayOptions.length
    );
  }

  /**
   * * 年更新で日付けの初期化判定
   */
  private handleChangeYear = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const year = +e.target.value;
    this.resetDay(year, +this.props.value.month, +this.props.value.day);
    const dayOptions = this.newDayOptions(year, +this.props.value.month);
    this.setState({ dayOptions });
  };

  /**
   * 月更新で日付けの初期化判定
   */
  private handleChangeMonth = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const month = +e.target.value;
    this.resetDay(+this.props.value.year, month, +this.props.value.day);
    const dayOptions = this.newDayOptions(+this.props.value.year, month);
    this.setState({ dayOptions });
  };

  /**
   * 日リストは年月更新で毎回変更される
   */
  private newDayOptions = (
    year: number,
    month: number
  ): { label: string; value: string }[] => {
    const lastDay = year && month ? getLastDay(year, month) : 0;
    return createOneToNumberOptions(lastDay, "日");
  };

  /**
   * 年月更新時に最終日が変わる可能性があるため、lastDayからはみ出ていたらリセットを行う
   */
  private resetDay = (year: number, month: number, day: number): void => {
    // dayがない => year, monthもない
    if (!day) return;
    if (Number.isNaN(year) || month === 0) {
      this.props.setFormikFieldValue(`${this.props.name}.day`, "");
      return;
    }

    const lastDay = getLastDay(year, month);
    if (day > lastDay) {
      this.props.setFormikFieldValue(`${this.props.name}.day`, "");
    }
  };

  public render(): JSX.Element {
    const { label, name, required, style } = this.props;
    return (
      <FormGroup row style={style || { marginBottom: 32 }}>
        <FormikSelect
          name={`${name}.year`}
          label={label}
          required={required}
          options={this.state.yearOptions}
          onChangeHook={this.handleChangeYear}
          style={{ width: 184, marginBottom: 0 }}
          tooltip={this.props.tooltip}
        />
        <FormikSelect
          name={`${name}.month`}
          label="月"
          shrink={false}
          size="small"
          options={this.state.monthOptions}
          onChangeHook={this.handleChangeMonth}
          style={{ width: 72, marginBottom: 0 }}
        />
        <FormikSelect
          name={`${name}.day`}
          label="日"
          shrink={false}
          size="small"
          options={this.state.dayOptions}
          style={{ width: 72, marginBottom: 0 }}
        />
      </FormGroup>
    );
  }
}

const FormikSelectDateNotSelectedDefault = (props: Props): JSX.Element => (
  // tslint:disable:jsx-no-lambda
  // FieldProps<any> 使用されるformikPropsがページごとに違うためany
  <FastField
    name={props.name}
    /* eslint-disable @typescript-eslint/no-explicit-any */
    render={({ field, form }: FieldProps<any>): JSX.Element => (
      <FormikSelectDateNotSelectedDefaultClass
        value={field.value}
        form={form}
        {...props}
      />
    )}
  />
);

export default FormikSelectDateNotSelectedDefault;
