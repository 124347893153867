import { BasicValues } from "@interfaces/mgr/IDOSHIEN/facility/basic";
import { FacilityState } from "@stores/domain/mgr/IDOSHIEN/facility/types";

export type FacilityValues = BasicValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  return {
    basic: {
      serviceType: state ? state.serviceType : "",
      postalCode: state ? state.postalCode : "",
      prefectureId: state ? state.selectedPrefectureName : "",
      cityId: state ? state.selectedCityCode : "",
      restAddress: state ? state.restAddress : "",
      tel: state ? state.tel : "",
      corporationName: state ? state.corporationName : "",
      officeNumber: state ? state.officeNumber : "",
      officeName: state ? state.officeName : "",
      representativeName: state ? state.representativeName : ""
    }
  };
};
