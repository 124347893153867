import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// store
import { OperationsAndSupportsState } from "@stores/domain/mgr/Cseg/operationAndSupports/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";

// utils
import { dateTodayInFormat } from "@utils/date";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";

// ui
import LineBreak from "@components/atoms/LineBreak";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      margin: 0,
      fontWeight: "normal",
      fontSize: 18,
      letterSpacing: 1.2,
      textAlign: "left",
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 8
    },
    subTitleDate: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      letterSpacing: 0.6,
      marginBottom: 16,
      marginTop: 16
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      marginBottom: 24,
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "4px 5px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        wordBreak: "break-all",
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.subLabel": {
          textAlign: "left",
          verticalAlign: "top"
        },
        "&.ssize": {
          width: 54
        },
        "&.msize": {
          width: 84
        },
        "&.lsize": {
          width: 102
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.topAlign": {
          verticalAlign: "top"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */
type OwnProps = {
  date: string;
  operation: OperationsAndSupportsState["operation_records"];
};

type Props = OwnProps & WithStyles<typeof styles>;

type OperationRecordProps = {
  classes: Record<string, string>;
  operation: OperationsAndSupportsState["operation_records"];
};

const OperationRecord = ({
  classes,
  operation
}: OperationRecordProps): JSX.Element | null => {
  if (operation) {
    let rowCount = 0;
    if (operation.operation_in_the_morning) rowCount += 1;
    if (operation.operation_in_the_afternoon) rowCount += 1;
    if (operation.other_comment) rowCount += 1;

    // 一番上に表示される項目を判別するため使用
    let isFirstRow = true;
    // 「記録者」「捺印」項目
    const firstElement = (): JSX.Element => {
      if (isFirstRow) {
        isFirstRow = false;
      }
      return (
        <>
          <td className="subLabel" rowSpan={rowCount}>
            {operation.staffs.map((v) => getSnapOrRealName(v, "")).join("、")}
          </td>
          <td rowSpan={rowCount} />
        </>
      );
    };
    return (
      <>
        {rowCount > 0 && (
          <table
            className={`${classes.table} fullWidth`}
            style={{ marginBottom: 24 }}
          >
            <tbody>
              <tr>
                <td className="label lsize">項目</td>
                <td className="label">内容</td>
                <td className="label msize">記録者</td>
                <td className="label ssize">確認印</td>
              </tr>
              {!!operation.operation_in_the_morning && (
                <tr>
                  <td className="subLabel">業務内容（午前）</td>
                  <td className="topAlign">
                    <LineBreak text={operation.operation_in_the_morning} />
                  </td>
                  {isFirstRow && firstElement()}
                </tr>
              )}
              {!!operation.operation_in_the_afternoon && (
                <tr>
                  <td className="subLabel">業務内容（午後）</td>
                  <td className="topAlign">
                    <LineBreak text={operation.operation_in_the_afternoon} />
                  </td>
                  {isFirstRow && firstElement()}
                </tr>
              )}
              {!!operation.other_comment && (
                <tr>
                  <td className="subLabel">その他</td>
                  <td className="topAlign">
                    <LineBreak text={operation.other_comment} />
                  </td>
                  {isFirstRow && firstElement()}
                </tr>
              )}
            </tbody>
          </table>
        )}
      </>
    );
  }
  return null;
};

const DailyPrintOperationCsegRecordsCore = (props: Props): JSX.Element => {
  const { classes, date, operation } = props;

  return (
    <div className={classes.page}>
      <header>
        <h1 className={classes.title}>業務日誌</h1>
      </header>
      <div className={classes.subTitleDate}>
        <span>{dateTodayInFormat(date, true)}</span>
      </div>

      <OperationRecord classes={classes} operation={operation} />
    </div>
  );
};

export const DailyPrintOperationCsegRecords = withStyles(styles)(
  DailyPrintOperationCsegRecordsCore
);
