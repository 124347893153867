import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId = {
  data: {
    id: number;
    created_at: string | null;
    updated_at: string | null;
    author: {
      id: number;
      name: string;
      role: string;
      license: number;
      license_name: string;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
    } | null;
    creation_date: string;
    intake: string | null;
    assessment_biological: string | null;
    assessment_psychological: string | null;
    assessment_social: string | null;
    support_plan_sheet_details: {
      support_plan_sheet_detail_id: number;
      support_issue: string | null;
      response_policy: string | null;
    }[];
  };
};

/**
 * 対象ユーザーの支援計画シート一を取得する。
 * @param uifId ユーザーID
 * @param supportPlanSheetId supportPlanSheetId
 */

export const getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId = async (
  uifId: string,
  supportPlanSheetId: string
): Promise<
  AxiosResponse<GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId>
> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_plan_sheets/${supportPlanSheetId}`;
  return request.get<GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId>(
    url
  );
};
