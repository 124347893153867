import { Dispatch } from "redux";
import * as actions from "./actions";
import dispatches from "@stores/dispatches";
import { inOutResults } from "@api/requests/v202104/inOutResults/";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeKODOENGODailyReportDataFromAPI,
  normalizeKODOENGOUserReportDataFromAPI,
  normalizeRequestParamToPostAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToDeleteAPI,
  normalizeRequestParamToPostCopyAPI,
  normalizeRequestParamToPostCopyCarePlanAPI
} from "./normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/v202104/mgr/KODOENGO/report/initial";
import {
  KODOENGOReportTypeInterface,
  REPEAT_DAILY,
  DeleteReportState,
  CopyReportState
} from "@stores/v202104/domain/mgr/KODOENGO/report/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KODOENGO/facility/types";
import {
  INOUT_RESULTS_COPY_ERROR_STATUS,
  INOUT_RESULTS_ERROR_DUPLICATED
} from "@constants/mgr/KODOENGO/variables";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 */
const fetchKODOENGODaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKODOENGODailyStarted());
  await inOutResults
    .getInOutResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKODOENGODaily(
          normalizeKODOENGODailyReportDataFromAPI(
            response.data,
            dateInYYYYMMDDFormat(date)
          )
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKODOENGODailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(ユーザごと)のデータ取得及びstore格納
 */
const fetchKODOENGOUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKODOENGOUserStarted());
  await inOutResults
    .getInOutResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKODOENGOUser(
          normalizeKODOENGOUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKODOENGOUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postKODOENGOReport = (dispatch: Dispatch) => async (
  facility: FacilityState,
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: KODOENGOReportTypeInterface["type"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKODOENGOReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(before, after, facility);
  await inOutResults
    .postInOutResults(requestParam)
    .then(() => {
      const actionType =
        type === REPEAT_DAILY
          ? actions.postKODOENGOReportDaily(requestParam)
          : actions.postKODOENGOReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postKODOENGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // 3人同時エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.error !== INOUT_RESULTS_ERROR_DUPLICATED.MESSAGE
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用計画→実績へのコピー
 */
const postKODOENGOReportCopy = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKODOENGOReportCopyStarted());

  const requestParam = normalizeRequestParamToPostCopyAPI(processType, target);

  await inOutResults
    .postInOutResultsCopy(requestParam)
    .then(() => {
      dispatch(actions.postKODOENGOReportCopy(requestParam));
    })
    .catch((e) => {
      dispatch(actions.postKODOENGOReportCopyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で実績登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 介護計画→利用計画へのコピー
 */
const postKODOENGOReportCopyCarePlan = (dispatch: Dispatch) => async (
  processType: number,
  target: CopyReportState[]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKODOENGOReportCopyCarePlanStarted());

  const requestParam = normalizeRequestParamToPostCopyCarePlanAPI(
    processType,
    target
  );

  await inOutResults
    .postInOutResultsCopyCarePlan(requestParam)
    .then(() => {
      dispatch(actions.postKODOENGOReportCopyCarePlan(requestParam));
    })
    .catch((e) => {
      dispatch(
        actions.postKODOENGOReportCopyCarePlanFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      // process_typeが2で計画登録エラーの場合snackbarは表示しない
      if (
        !e.response ||
        e.response.status !== INOUT_RESULTS_ERROR_DUPLICATED.STATUS ||
        e.response.data.response.code !== INOUT_RESULTS_COPY_ERROR_STATUS
      ) {
        dispatches.uiDispatch(dispatch).snackbar({
          open: true,
          message: "通信エラー",
          variant: "error"
        });
      }
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績削除
 */
const deleteKODOENGOReport = (dispatch: Dispatch) => async (
  target: DeleteReportState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteKODOENGOReportStarted());

  const requestParam = normalizeRequestParamToDeleteAPI(target);

  await inOutResults
    .deleteInOutResults(requestParam)
    .then(() => {
      dispatch(actions.deleteKODOENGOReport(requestParam));
    })
    .catch((e) => {
      dispatch(actions.deleteKODOENGOReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchKODOENGODaily: (date: Date) => Promise<void>;
  fetchKODOENGOUsers: (uifId: number, date: Date) => Promise<void>;
  postKODOENGOReport: (
    facility: FacilityState,
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KODOENGOReportTypeInterface["type"]
  ) => Promise<void>;
  postKODOENGOReportCopy: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  postKODOENGOReportCopyCarePlan: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  deleteKODOENGOReport: (target: DeleteReportState) => Promise<void>;
};

export const KODOENGOReport202104Dispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchKODOENGODaily: fetchKODOENGODaily(dispatch),
  fetchKODOENGOUsers: fetchKODOENGOUsers(dispatch),
  postKODOENGOReport: postKODOENGOReport(dispatch),
  postKODOENGOReportCopy: postKODOENGOReportCopy(dispatch),
  postKODOENGOReportCopyCarePlan: postKODOENGOReportCopyCarePlan(dispatch),
  deleteKODOENGOReport: deleteKODOENGOReport(dispatch)
});
