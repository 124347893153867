import { BasicValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/users/basic";
import { ServiceUseValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/users/recipientCertificate";
import { CompaniesValues } from "@interfaces/v201910/mgr/SHUROTEICHAKU/users/companies";
import { UsersInFacilityState } from "@stores/v201910/domain/mgr/SHUROTEICHAKU/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { DEFAULT_SELECT_VALUE, Checkbox } from "@constants/variables";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues &
  CompaniesValues;

// 担当者のフラグがONになるパラメータ
const STAFF_KEYS = [
  "name",
  "position",
  "department",
  "relationship",
  "tel",
  "email"
];

const checkFlg = (staff: object): boolean => {
  /* eslint-disable no-restricted-syntax */
  for (const key of Object.keys(staff)) {
    // staff[key]に0が入る可能性があるため
    if (
      staff[key] !== null &&
      staff[key] !== undefined &&
      STAFF_KEYS.includes(key)
    ) {
      return true;
    }
  }
  return false;
};

const createCompanyPersons = (
  companyPersons: UsersInFacilityState["user"]["company_persons"]
): CompaniesValues["companies"]["companyPersons"] => {
  const companyPersonsValue: CompaniesValues["companies"]["companyPersons"] = [
    {
      flg: false,
      name: "",
      position: "",
      department: "",
      relationship: "",
      tel: "",
      email: ""
    },
    {
      flg: false,
      name: "",
      position: "",
      department: "",
      relationship: "",
      tel: "",
      email: ""
    }
  ];
  if (companyPersons) {
    companyPersons.forEach((staff, index) => {
      companyPersonsValue.splice(index, 1, {
        flg: checkFlg(staff),
        name: undefinedToReturnValue(staff.name),
        position: undefinedToReturnValue(staff.position),
        department: undefinedToReturnValue(staff.department),
        relationship: undefinedToReturnValue(staff.relationship),
        tel: undefinedToReturnValue(staff.tel),
        email: undefinedToReturnValue(staff.email)
      });
    });
  }

  return companyPersonsValue;
};

const initialValues = (state?: UsersInFacilityState["user"]): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const SHUROTEICHAKU =
    state && state.user_in_facility_shuroteichaku
      ? state.user_in_facility_shuroteichaku
      : {};
  const companies = state && state.companies ? state.companies : {};
  const companyPersons =
    state && state.company_persons ? state.company_persons : [];
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTrueOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTrueOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTrueOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTrueOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTrueOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTrueOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTrueOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTrueOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianName: undefinedToReturnValue(userInFacility.guardian_name),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      sameCorporationMovementFlg: determineTrueOrFalse(
        userInFacility.same_corporation_movement_flg
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      incomeKind: undefinedToReturnValue(userInFacility.income_kind, 1),
      incomeKindType: undefinedToReturnValue(SHUROTEICHAKU.income_kind, 0),
      subsidizedFlag: determineTrueOrFalse(userInFacility.subsidized_flg),
      subsidizedPercent: undefinedToReturnValue(
        userInFacility.subsidized_percent
      ),
      subsidizedYen: undefinedToReturnValue(userInFacility.subsidized_yen),
      subsidizedUnit: undefinedToReturnValue(
        userInFacility.subsidized_unit_flg,
        1
      ),
      subsidizedCityId: userInFacility.subsidized_city_id
        ? `${userInFacility.subsidized_city_id}`
        : DEFAULT_SELECT_VALUE,
      upperLimitFacilityFlag: determineTrueOrFalse(
        userInFacility.uplimit_facility_flg
      ),
      upperLimitControlledBy: undefinedToReturnValue(
        userInFacility.uplimit_controlled_by,
        1
      ),
      upperLimitFacilityNumber: undefinedToReturnValue(
        userInFacility.uplimit_facility_number
      ),
      upperLimitFacilityNumber2: undefinedToReturnValue(
        userInFacility.uplimit_facility_number2
      ),
      upperLimitFacilityNumber3: undefinedToReturnValue(
        userInFacility.uplimit_facility_number3
      ),
      upperLimitFacilityNumber4: undefinedToReturnValue(
        userInFacility.uplimit_facility_number4
      ),
      upperLimitFacilityName: undefinedToReturnValue(
        userInFacility.uplimit_facility_name
      ),
      upperLimitFacilityName2: undefinedToReturnValue(
        userInFacility.uplimit_facility_name2
      ),
      upperLimitFacilityName3: undefinedToReturnValue(
        userInFacility.uplimit_facility_name3
      ),
      upperLimitFacilityName4: undefinedToReturnValue(
        userInFacility.uplimit_facility_name4
      ),
      upperLimitTotalYen: undefinedToReturnValue(
        userInFacility.uplimit_total_yen
      ),
      upperLimitTotalYen2: undefinedToReturnValue(
        userInFacility.uplimit_total_yen2
      ),
      upperLimitTotalYen3: undefinedToReturnValue(
        userInFacility.uplimit_total_yen3
      ),
      upperLimitTotalYen4: undefinedToReturnValue(
        userInFacility.uplimit_total_yen4
      ),
      upperLimitUserLoadYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen
      ),
      upperLimitUserLoadYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen2
      ),
      upperLimitUserLoadYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen3
      ),
      upperLimitUserLoadYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      resultOfManagement: undefinedToReturnValue(
        userInFacility.result_of_management,
        1
      ),
      upperLimitYen: undefinedToReturnValue(userInFacility.uplimit_yen),
      createSupportPlanFlag:
        userInFacility.create_support_plan_flg === Checkbox.OFF || false,
      notCreateSupportPlanStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(
            userInFacility.date_start_not_create_support_plan
          )
        )
      )
    },
    recipientCertificate: {
      userChargeLimitFlag: determineTrueOrFalse(
        userInFacility.user_charge_limit_flg
      ),
      userChargeLimitStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_user_charge_limit)
        )
      ),
      userChargeLimitEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_user_charge_limit)
        )
      ),
      foodServeAdditionFlg: determineTrueOrFalse(
        userInFacility.food_serve_addition_flg
      ),
      foodServeAdditionStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_food_serve_addition)
        )
      ),
      foodServeAdditionEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_food_serve_addition)
        )
      ),
      careSupportAuthFlag: determineTrueOrFalse(
        userInFacility.care_support_auth_flg
      ),
      careSupportAuthStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_auth)
        )
      ),
      careSupportAuthEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_auth)
        )
      ),
      careSupportPaymentFlag: determineTrueOrFalse(
        userInFacility.care_support_payment_flg
      ),
      careSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_payment)
        )
      ),
      careSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_payment)
        )
      ),
      planSupportPaymentFlag: determineTrueOrFalse(
        userInFacility.plan_support_payment_flg
      ),
      planSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_payment)
        )
      ),
      planSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_payment)
        )
      ),
      planSupportMonitorFlag: determineTrueOrFalse(
        userInFacility.plan_support_monitor_flg
      ),
      planSupportMonitorStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_monitor)
        )
      ),
      planSupportMonitorEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_monitor)
        )
      )
    },
    companies: {
      name: undefinedToReturnValue(companies.name),
      overview: undefinedToReturnValue(companies.overview),
      address: undefinedToReturnValue(companies.address),
      workingStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(companies.working_start_date)
        )
      ),
      department: undefinedToReturnValue(companies.department),
      remarks: undefinedToReturnValue(companies.remarks),
      companyPersons: createCompanyPersons(companyPersons)
    }
  };
};

export default initialValues;
