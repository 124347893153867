import * as React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import MuiIconButton, { IconButtonProps } from "@material-ui/core/IconButton";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "7px",
      border: "solid 1px rgba(0, 0, 0, 0.23)"
    }
  });

type Props = IconButtonProps & WithStyles<typeof styles>;

const IconButtonCore = (props: Props): JSX.Element => {
  const buttonColor = props.color || "secondary";
  return (
    <MuiIconButton
      color={buttonColor}
      className={props.classes.root}
      onClick={props.onClick}
    >
      {props.children}
    </MuiIconButton>
  );
};

export const IconButton = withStyles(styles)(IconButtonCore);
