import * as React from "react";
import * as classNames from "classnames";
import {
  createStyles,
  StyleRules,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    default: {
      marginRight: spacing.unit * 2,
      marginBottom: spacing.unit * 4
    },
    checkbox: {
      marginTop: -12,
      marginBottom: 20
    },
    switch: {
      marginTop: -12,
      marginBottom: 20
    },
    superSmall: {
      width: 100
    },
    small: {
      width: 128
    },
    smallMedium: {
      width: 160
    },
    medium: {
      width: 240
    },
    largeMedium: {
      width: 360
    },
    long: {
      width: 400
    },
    quarterSuperLong: {
      width: 500
    },
    halfSuperLong: {
      width: 544
    },
    superLong: {
      width: 800
    },
    fullSize: {
      width: "100%"
    },
    auto: {
      width: "auto"
    },
    mobileHalf: {
      width: "calc(50% - 8px)"
    }
  });

type FieldType = "default" | "checkbox" | "switch";

export type FieldSizeName =
  | "superSmall"
  | "small"
  | "smallMedium"
  | "medium"
  | "largeMedium"
  | "long"
  | "quarterSuperLong"
  | "halfSuperLong"
  | "superLong"
  | "fullSize"
  | "auto"
  | "mobileHalf";

type OwnProps = {
  children?: React.ReactNode;
  type?: FieldType;
  size?: FieldSizeName;
  style?: React.CSSProperties;
  onChangeHook?: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const FieldWrapper = ({
  children,
  classes,
  style,
  type = "default",
  size = "medium",
  onChangeHook
}: Props): JSX.Element => (
  <div
    className={classNames(classes[type], classes[size])}
    style={style}
    onChange={onChangeHook}
  >
    {children}
  </div>
);

export default withStyles(styles)(FieldWrapper);
