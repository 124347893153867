import * as validate from "@validator/rules";
import ValidationErrors from "@interfaces/ui/validationErrors";
import { SelectDateValue, SelectMonthValue } from "@interfaces/ui/form";
import { BaseTimes } from "@validator/rules/checkTimeConflict";

type Required = "required"; // 必須チェック
type NaturalNumber = "naturalNumber"; // 自然数チェック
type PostalCode = "postalCode"; // 郵便番号チェック
type Email = "email"; // メールアドレスチェック
type Kana = "kana"; // 全角カナチェック
type Password = "password"; // パスワードチェック
type CheckTime = "checkTime"; // 時間フォーマットチェック
type NaturalNumberNonZero = "naturalNumberNonZero"; // 自然数チェック（0単体を含む）
type NaturalNumberOverOne = "naturalNumberOverOne";
type NaturalNumberWithComma = "naturalNumberWithComma"; // 自然数チェック（カンマは許容する）、
type NumberWithComma = "numberWithComma"; // 数チェック（マイナス、カンマは許容する）、
type Decimal = "decimal";
type DecimalPoint = "decimalPoint";
type ValidDate = "validDate";
type DayValueLimit = "dayValueLimit";
type DecimalPointByEveryHalfMinutes = "decimalPointByEveryHalfMinutes";
type DecimalPointByEveryQuarterMinutesAfter30Minutes = "decimalPointByEveryQuarterMinutesAfter30Minutes";

// 条件を満たした時だけRequiredを実行する
type ShouldRequired = {
  type: "required";
  shouldValidate: boolean;
};

type RequiredAnyMessage = {
  type: "requiredAnyMessage";
  message: string;
};

// セレクトボックス変更チェック
type SelectRequired = {
  type: "selectRequired";
  value: string;
};

// セレクトボックスの値がoption内に存在するかのチェック
type SelectReenter = {
  type: "selectReenter";
  options: { value: string }[];
};

// 指定桁数チェック
type CheckDigits = {
  type: "checkDigits";
  digits: number;
};

// 上限数値チェック
type CheckMaximumNumber = {
  type: "checkMaximumNumber";
  value: number;
  maximumNum: number;
};

// startDateよりも未来かチェック
type Future = {
  type: "future";
  startDate: SelectDateValue;
  options?: { startLabel: string; endLabel: string };
};

// startDateよりも未来かチェック(エラーメッセージを日に表示させる)
type FutureDay = {
  type: "futureDay";
  startDate: SelectDateValue;
  options?: { startLabel: string; endLabel: string };
};

// 上限チェック
type UpperLimit = {
  type: "upperLimit";
  upperLimit: number;
};

// 上限チェック(小数考慮版)
type UpperLimitDecimal = {
  type: "upperLimitDecimal";
  upperLimitDecimal: number;
};

// 下限チェック
type LowerLimit = {
  type: "lowerLimit";
  lowerLimit: number;
};

// 下限チェック(半角数字)
type LowerLimitHalfSize = {
  type: "lowerLimitHalfSize";
  lowerLimitHalfSize: number;
};

// 下限チェック(小数考慮版)
type LowerLimitDecimal = {
  type: "lowerLimitDecimal";
  lowerLimitDecimal: number;
};

// 選択肢一致チェック
type OptionNotMatch = {
  type: "optionNotMatch";
  value: string;
};

// パスワード一致チェック
type PasswordMatch = {
  type: "passwordMatch";
  value: string;
};

// 終了時間チェック
type CheckTimeFuture = {
  type: "checkTimeFuture";
  startTime: string;
  options?: { firstLabel: string; secondLabel: string };
};

// 指定文字数数チェック
type CheckCharacterLength = {
  type: "checkCharacterLength";
  length: number;
};

// 期間内かどうかチェック(月単位)
type WithinTargetMonth = {
  type: "withinTargetMonth";
  startDate: SelectDateValue;
  targetMonth: number;
  options?: { startLabel: string; endLabel: string };
};

type CheckTimeFutureStraddlingTheDay = {
  type: "checkTimeFutureStraddlingTheDay";
  startTime: string;
  startTimeClass: string;
  endTime: string;
  endTimeClass: string;
  option?: { firstLabel: string; secondLabel: string };
};

type CheckTimeRange = {
  type: "checkTimeRange";
  startTime: string;
  startTimeClass: string;
  endTime: string;
  endTimeClass: string;
  targetTime: string;
  targetTimeClass: string;
  equalityOperatorFlag: boolean;
  option?: string;
};

type checkTimeEqual = {
  type: "checkTimeEqual";
  startTime: string;
  endTime: string;
  option?: { firstLabel: string; secondLabel: string };
};

type CheckRangeDateTime = {
  type: "checkRangeDateTime";
  startTimeFirst: Date;
  endTimeFirst: Date;
  startTimeSecond: Date;
  endTimeSecond: Date;
  message: string;
  allowContinuing?: boolean;
};

// 終了時間チェック
type FutureTime = {
  type: "futureTime";
  startTime: Date;
  endTime: Date;
  options?: { startLabel: string; endLabel: string };
};

// 終了年月チェック
type FutureMonth = {
  type: "futureMonth";
  startTime: Date;
  endTime: Date;
  options?: { startLabel: string; endLabel: string };
};

// 時間帯バリデーション
type CheckTimeZoneCrossing = {
  type: "checkTimeZoneCrossing";
  startTime: Date;
  endTime: Date;
  targetDate: string;
  numberOfdays?: number;
};

// 範囲内時間バリデーション
type CheckWithinRangeTime = {
  type: "checkWithinRangeTime";
  baseStartTime: Date;
  baseEndTime: Date;
  targetStartTime: Date;
  targetEndTime: Date;
  option: {
    baseLabel: string;
    targetLabel: string;
  };
  equivalent?: boolean;
};

// 同一時間帯重複バリデーション
type CheckTimeConflict = {
  type: "checkTimeConflict";
  targetStartTime: Date;
  targetEndTime: Date;
  baseTimes: BaseTimes;
  label: string;
};

// 時間範囲バリデーション
type CheckTimeRangeLimitDateType = {
  type: "checkTimeRangeLimitDateType";
  startTime: Date;
  outTime: Date;
  limit: number;
  option?: string;
};

type AccountId = {
  type: "accountId";
  accountType: "timeCard" | "mobile";
};

type DecimalPointWithMessage = {
  type: "decimalPointWithMessage";
  message: string;
};

type PasswordDuplication = {
  type: "passwordDuplication";
  adminId: string;
  mobileId: string;
};

// startDateよりも未来（翌日以降）かチェック
type FutureNextDayAfter = {
  type: "futureNextDayAfter";
  startDate: SelectDateValue;
  options: { startLabel: string; endLabel: string };
};

// セレクトボックス形式日付の必須チェック（任意のエラーメッセージ）
type RequiredDateAnyMessage = {
  type: "requiredDateAnyMessage";
  message: string;
};

// 終了日が設定されている場合に開始日も設定されているかチェック
type WithStartDate = {
  type: "withStartDate";
  startDate: SelectDateValue;
  options: { startLabel: string };
};

// 対象日付の期間内かチェック
type dateRangeWithInDateRange = {
  type: "dateRangeWithInDateRange";
  baseStartDate: SelectDateValue;
  baseEndDate: SelectDateValue;
  targetStartDate: SelectDateValue;
  targetEndDate: SelectDateValue;
  message: string;
  validateTarget?: "START_DATE" | "END_DATE";
};

// 配列内の年月の重複チェック
type RegisteredYearMonth = {
  type: "registeredYearMonth";
  index: number;
  array: {
    year_month: SelectMonthValue;
  }[];
};

// 小数点込みの数値の桁数チェック
type NumberOfDigits = {
  type: "numberOfDigits";
  integerDigitsLimit: number;
  decimalDigitsLimit: number;
  errorMessage?: string;
};

export type Rule =
  | Required
  | RequiredAnyMessage
  | NaturalNumber
  | PostalCode
  | Email
  | Kana
  | ShouldRequired
  | SelectRequired
  | SelectReenter
  | CheckDigits
  | CheckMaximumNumber
  | OptionNotMatch
  | Password
  | PasswordDuplication
  | PasswordMatch
  | AccountId
  | CheckTimeFuture
  | CheckCharacterLength
  | CheckTime
  | UpperLimit
  | UpperLimitDecimal
  | LowerLimit
  | LowerLimitHalfSize
  | LowerLimitDecimal
  | NaturalNumberNonZero
  | NaturalNumberOverOne
  | NaturalNumberWithComma
  | NumberWithComma
  | Decimal
  | DecimalPoint
  | DecimalPointByEveryHalfMinutes
  | DecimalPointByEveryQuarterMinutesAfter30Minutes
  | CheckTimeFutureStraddlingTheDay
  | CheckTimeRange
  | checkTimeEqual
  | CheckRangeDateTime
  | FutureTime
  | FutureMonth
  | CheckTimeZoneCrossing
  | CheckWithinRangeTime
  | CheckTimeConflict
  | CheckTimeRangeLimitDateType
  | DecimalPointWithMessage
  | NumberOfDigits
  | undefined;
export type ErrorMessage = string | undefined;

type DateRule =
  | Required
  | Future
  | FutureDay
  | WithinTargetMonth
  | ValidDate
  | DayValueLimit
  | FutureNextDayAfter
  | RequiredDateAnyMessage
  | WithStartDate
  | dateRangeWithInDateRange
  | RegisteredYearMonth;
type DateErrorMessage = ValidationErrors<SelectDateValue> | undefined;

/**
 * 渡されたルールからvalueに対して行うバリデーションを決定する
 * @param value
 * @param rules
 */
const validator = (value: string, ...rules: Rule[]): ErrorMessage => {
  let errorMessage: string | undefined;
  rules.some((rule): boolean => {
    if (rule === "required") {
      errorMessage = validate.required(value);
    }
    if (rule === "naturalNumber") {
      errorMessage = validate.naturalNumber(value);
    }
    if (rule === "naturalNumberNonZero") {
      errorMessage = validate.naturalNumberNonZero(value);
    }
    if (rule === "naturalNumberOverOne") {
      errorMessage = validate.naturalNumberOverOne(value);
    }
    if (rule === "naturalNumberWithComma") {
      errorMessage = validate.naturalNumberWithComma(value);
    }
    if (rule === "numberWithComma") {
      errorMessage = validate.numberWithComma(value);
    }
    if (rule === "decimal") {
      errorMessage = validate.decimal(value);
    }
    if (rule === "decimalPoint") {
      errorMessage = validate.decimalPoint(value);
    }
    if (rule === "checkTime") {
      errorMessage = validate.checkTime(value);
    }
    if (rule === "decimalPointByEveryHalfMinutes") {
      errorMessage = validate.decimalPointByEveryHalfMinutes(value);
    }
    if (rule === "decimalPointByEveryQuarterMinutesAfter30Minutes") {
      errorMessage = validate.decimalPointByEveryQuarterMinutesAfter30Minutes(
        value
      );
    }
    if (rule === "postalCode") {
      errorMessage = validate.postalCode(value);
    }
    if (rule === "email") {
      errorMessage = validate.email(value);
    }
    if (rule === "kana") {
      errorMessage = validate.kana(value);
    }
    if (typeof rule === "object") {
      if (rule.type === "required" && rule.shouldValidate) {
        errorMessage = validate.required(value);
      }
      if (rule.type === "requiredAnyMessage") {
        errorMessage = validate.requiredAnyMessage(value, rule.message);
      }
      if (rule.type === "selectRequired") {
        errorMessage = validate.selectRequired(value, rule.value);
      }
      if (rule.type === "selectReenter") {
        errorMessage = validate.selectReenter(value, rule.options);
      }
      if (rule.type === "checkDigits") {
        errorMessage = validate.checkDigits(value, rule.digits);
      }
      if (rule.type === "checkMaximumNumber") {
        errorMessage = validate.checkMaximumNumber(rule.value, rule.maximumNum);
      }
      if (rule.type === "passwordMatch") {
        errorMessage = validate.passwordMatch(value, rule.value);
      }
      if (rule.type === "passwordDuplication") {
        errorMessage = validate.passwordDuplication(
          value,
          rule.adminId,
          rule.mobileId
        );
      }
      if (rule.type === "checkTimeFuture") {
        errorMessage = validate.checkTimeFuture(
          value,
          rule.startTime,
          rule.options
        );
      }
      if (rule.type === "upperLimit") {
        errorMessage = validate.upperLimit(value, rule.upperLimit);
      }
      if (rule.type === "upperLimitDecimal") {
        errorMessage = validate.upperLimit(value, rule.upperLimitDecimal, true);
      }
      if (rule.type === "lowerLimit") {
        errorMessage = validate.lowerLimit(value, rule.lowerLimit);
      }
      if (rule.type === "lowerLimitHalfSize") {
        errorMessage = validate.lowerLimitHalfSize(
          value,
          rule.lowerLimitHalfSize
        );
      }
      if (rule.type === "lowerLimitDecimal") {
        errorMessage = validate.lowerLimit(value, rule.lowerLimitDecimal, true);
      }
      if (rule.type === "checkCharacterLength") {
        errorMessage = validate.checkCharacterLength(value, rule.length);
      }
      if (rule.type === "optionNotMatch") {
        errorMessage = validate.optionNotMatch(value, rule.value);
      }
      if (rule.type === "checkTimeFutureStraddlingTheDay") {
        errorMessage = validate.checkTimeFutureStraddlingTheDay(
          rule.startTime,
          rule.startTimeClass,
          rule.endTime,
          rule.endTimeClass,
          rule.option
        );
      }
      if (rule.type === "checkTimeRange") {
        errorMessage = validate.checkTimeRange(
          rule.startTime,
          rule.startTimeClass,
          rule.endTime,
          rule.endTimeClass,
          rule.targetTime,
          rule.targetTimeClass,
          rule.equalityOperatorFlag,
          rule.option
        );
      }
      if (rule.type === "checkTimeEqual") {
        errorMessage = validate.checkTimeEqual(
          rule.startTime,
          rule.endTime,
          rule.option
        );
      }
      if (rule.type === "checkRangeDateTime") {
        errorMessage = validate.checkRangeDateTime(
          rule.startTimeFirst,
          rule.endTimeFirst,
          rule.startTimeSecond,
          rule.endTimeSecond,
          rule.message,
          rule.allowContinuing
        );
      }
      if (rule.type === "futureTime") {
        errorMessage = validate.futureTime(
          rule.startTime,
          rule.endTime,
          rule.options
        );
      }
      if (rule.type === "futureMonth") {
        errorMessage = validate.futureMonth(
          rule.startTime,
          rule.endTime,
          rule.options
        );
      }
      if (rule.type === "checkTimeZoneCrossing") {
        errorMessage = validate.checkTimeZoneCrossing(
          rule.startTime,
          rule.endTime,
          rule.targetDate,
          rule.numberOfdays
        );
      }
      if (rule.type === "checkWithinRangeTime") {
        errorMessage = validate.checkWithinRangeTime(
          rule.baseStartTime,
          rule.baseEndTime,
          rule.targetStartTime,
          rule.targetEndTime,
          rule.option,
          rule.equivalent
        );
      }
      if (rule.type === "checkTimeConflict") {
        errorMessage = validate.checkTimeConflict(
          rule.targetStartTime,
          rule.targetEndTime,
          rule.baseTimes,
          rule.label
        );
      }
      if (rule.type === "checkTimeRangeLimitDateType") {
        errorMessage = validate.checkTimeRangeLimitDateType(
          rule.startTime,
          rule.outTime,
          rule.limit,
          rule.option
        );
      }
      if (rule.type === "accountId") {
        errorMessage = validate.accountId(value, rule.accountType);
      }
      if (rule.type === "decimalPointWithMessage") {
        errorMessage = validate.decimalPoint(value, rule.message);
      }
      if (rule.type === "numberOfDigits") {
        errorMessage = validate.numberOfDigits(
          value,
          rule.integerDigitsLimit,
          rule.decimalDigitsLimit,
          rule.errorMessage
        );
      }
    }
    if (rule === "password") {
      errorMessage = validate.password(value);
    }

    // errorMessageが生成されていたら終了
    if (errorMessage) {
      return true;
    }
    return false;
  });
  return errorMessage;
};

/**
 * SelectDateValueを処理するためのバリデーション
 * @param value
 * @param rules
 */
export const dateValidator = (
  value: SelectDateValue,
  ...rules: DateRule[]
): DateErrorMessage => {
  let errorMessage:
    | {
        year: string | undefined;
        month: string | undefined;
        day: string | undefined;
      }
    | undefined;
  rules.some((rule): boolean => {
    if (rule === "required") {
      errorMessage = validate.requiredDate(value);
    }
    if (rule === "validDate") {
      errorMessage = validate.validDate(value);
    }
    if (typeof rule === "object") {
      if (rule.type === "requiredDateAnyMessage") {
        errorMessage = validate.requiredDateAnyMessage(value, rule.message);
      }
      if (rule.type === "future") {
        errorMessage = validate.future(value, rule.startDate, rule.options);
      }
      if (rule.type === "futureDay") {
        errorMessage = validate.futureDay(value, rule.startDate, rule.options);
      }
      if (rule.type === "withinTargetMonth") {
        errorMessage = validate.withinTargetMonth(
          rule.startDate,
          value,
          rule.targetMonth,
          rule.options
        );
      }
      if (rule.type === "futureNextDayAfter") {
        errorMessage = validate.futureNextDayAfter(
          value,
          rule.startDate,
          rule.options
        );
      }
      if (rule.type === "withStartDate") {
        errorMessage = validate.withStartDate(
          value,
          rule.startDate,
          rule.options
        );
      }
      if (rule.type === "dateRangeWithInDateRange") {
        errorMessage = validate.dateRangeWithInDateRange(
          rule.baseStartDate,
          rule.baseEndDate,
          rule.targetStartDate,
          rule.targetEndDate,
          rule.message,
          rule.validateTarget
        );
      }
      if (rule.type === "registeredYearMonth") {
        errorMessage = validate.registeredYearMonth(
          { year: value.year, month: value.month },
          rule.index,
          rule.array
        );
      }
    }

    if (
      errorMessage &&
      (errorMessage.year || errorMessage.month || errorMessage.day)
    ) {
      return true;
    }
    return false;
  });
  return errorMessage;
};

/**
 * 期間開始・終了を処理するためのバリデーション
 * @param startValue
 * @param endValue
 * @param max
 * @param min
 * @param rules
 */
export const dateLimitValidator = (
  startValue: SelectDateValue,
  endValue: SelectDateValue,
  max: number,
  min: number,
  ...rules: DateRule[]
): DateErrorMessage => {
  let errorMessage:
    | {
        year: string | undefined;
        month?: string | undefined;
        day?: string | undefined;
      }
    | undefined;
  rules.some((rule): boolean => {
    if (rule === "dayValueLimit") {
      errorMessage = validate.dayValueLimit(startValue, endValue, max, min);
    } else if (rule === "required") {
      errorMessage = validate.requiredDate(endValue);
    }

    if (
      errorMessage &&
      (errorMessage.year || errorMessage.month || errorMessage.day)
    ) {
      return true;
    }
    return false;
  });
  return errorMessage;
};

/**
 * validatorの実行を制御する
 * @param needsValidate trueならバリデーション結果を返す
 * @param validatorResult バリデーション結果
 */
export const validateSwitcher = <T>(
  needsValidate: boolean,
  validatorResult: T
): T | undefined => {
  return needsValidate ? validatorResult : undefined;
};

/**
 * validatorルールの実行を制御する
 * @param needsValidate trueならルールを返す
 * @param rule バリデーションルール
 */
export const validateRuleSwitcher = (
  needsValidate: boolean,
  rule: Rule
): Rule | undefined => {
  return needsValidate ? rule : undefined;
};

/**
 * バリデーションをグルーピングする
 * 複数の条件を複合させて、メッセージは一種類だけ表示する際に使用
 * 例： 下限1 ~ 上限29 の場合に naturalNumber,lowerLimit,upperLimit を組み合わせて「1～29で入力してください」を表示
 * @param value バリデート対象の値
 * @param rules validatorのルール配列
 * @param errorMessage 返すエラーメッセージ
 * @returns エラーの場合はエラーメッセージ、問題ない場合はundefined(validatorの仕様同様)
 */
export const validateMarge = (
  value: string,
  rules: Rule[],
  errorMessage: string
): ErrorMessage => {
  if (validator(value, ...rules)) {
    return errorMessage;
  }
  return undefined;
};

export default validator;
