import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetPaginatedDailySupports = {
  data: {
    facility_unit_id: number;
    page: number;
    keyword: string;
    support_count: number;
    is_record: number;
    support: {
      user_in_facility_id: number;
      support_records_id: number;
      status_type: number;
      support_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
  };
};

/**
 * 指定日の支援記録を取得する（ページネーションあり）
 * @param yyyymmdd 日付
 * @param facility_unit_id グループID 0は事業所全体
 * @param page 取得するページ数 0は全件取得（未指定時も0）
 * @param keyword 絞り込み条件: あかさたな (未指定時は全件)
 * @param is_record 絞り込み条件: 未記録/記録済 (未指定時は全件)
 */
const getPaginatedDailySupports = async (
  yyyymmdd: string,
  facility_unit_id = 0,
  page?: number,
  keyword?: string,
  is_record?: number
): Promise<AxiosResponse<GetPaginatedDailySupports>> => {
  const url = `${VERSION_URL_201910}/records/${yyyymmdd}/pagination`;
  const query = `?facility_unit_id=${facility_unit_id}${
    page ? `&page=${page}` : ""
  }${keyword ? `&keyword=${keyword}` : ""}${
    is_record ? `&is_record=${is_record}` : ""
  }`;

  return request.get<GetPaginatedDailySupports>(url + query);
};

export default getPaginatedDailySupports;
