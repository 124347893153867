import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import AddIcon from "@material-ui/icons/Add";
import * as ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";
import { FieldItem } from "@interfaces/ui/form";
import { SupportPlanSheets } from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import { supportPlanSheetDetailsValue } from "@initialize/record/supportPlanSheets/initialValues";
import FormikTextField from "@components/molecules/FormikTextField";
import { StaffState } from "@stores/domain/staff/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    con: {
      padding: "32px",
      position: "relative"
    },
    conCreateDate: {
      position: "absolute",
      right: "32px",
      top: "16px"
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    flex: {
      display: "flex",
      justifyContent: "flex-start",
      "& > div+div": {
        marginLeft: 16
      },
      "& > div > div > div > div > p": {
        whiteSpace: "normal"
      },
      "& > div > div > div ": {
        whiteSpace: "nowrap"
      }
    },
    col: {
      display: "flex"
    },
    colItem: {
      width: "240px"
    },
    header: {
      display: "flex",
      width: "1030px",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      background: "#eceff1",
      color: "#37474f",
      padding: "14px 16px",
      "& span": {
        display: "block"
      }
    },
    index: {
      width: "52px",
      minWidth: "52px"
    },
    issue: {
      width: "312px",
      minWidth: "312px"
    },
    policy: {
      width: "578px",
      minWidth: "578px",
      marginLeft: "16px"
    },
    body: {
      width: "1030px",
      padding: "0 16px",
      display: "flex",
      fontSize: "16px",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: "1px solid #e0e0e0"
    },
    indexBody: {
      width: "52px",
      minHeight: "43px",
      marginTop: "16px"
    },
    issueBody: {
      width: "312px",
      minWidth: "312px",
      margin: "10px 0 16px 0"
    },
    editIssueBody: {
      width: "312px",
      minWidth: "312px",
      margin: "10px 0 0 0"
    },
    policyBody: {
      width: "578px",
      minWidth: "578px",
      margin: "10px 0 16px 16px"
    },
    editPolicyBody: {
      width: "578px",
      minWidth: "578px",
      margin: "10px 0 0 16px"
    },
    deleteIcon: {
      width: "32px",
      minWidth: "32px",
      marginLeft: "10px"
    },
    noData: {
      margin: "20px 0 21px 16px",
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)"
    },
    tableBottom: {
      width: "1030px",
      borderBottom: "1px solid #e0e0e0"
    },
    btnTxt: {
      marginLeft: 12
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: "16px"
    },
    ML16: {
      marginLeft: "16px"
    }
  });

type OwnProps = {
  initialValues: SupportPlanSheets;
  isEditing: boolean;
  formikProps: FormikProps<SupportPlanSheets>;
  authorName: string;
  authorizerRole: string;
  staffWithSnap: StaffState;
  staffOptionsAddSnapShot: FieldItem[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportPlanSheetsFormCore = (props: Props): JSX.Element => {
  const {
    initialValues,
    isEditing,
    formikProps,
    authorName,
    authorizerRole,
    staffWithSnap,
    staffOptionsAddSnapShot,
    classes
  } = props;

  const [deletedCount, setDeletedCount] = React.useState(0);

  // 役職
  const [roleValue, setRoleValue] = React.useState<string>(authorizerRole);
  React.useEffect(() => {
    if (
      formikProps.values.author !== null &&
      formikProps.values.author !== ""
    ) {
      const select = staffWithSnap.staffItems.find(
        (f) => f.staffItemId === Number(formikProps.values.author)
      );
      setRoleValue(select ? select.roleName : "");
    } else {
      setRoleValue("");
    }
  }, [formikProps]);
  return (
    <div className={classes.con}>
      <div className={classes.conCreateDate}>
        <CreateAndUpdateDate
          createdAt={initialValues.created_at}
          updatedAt={initialValues.updated_at}
        />
      </div>
      <RecordSelectDate
        name="creation_date"
        label="作成日"
        required
        value={initialValues.creation_date}
        isEditable={isEditing}
        addYearTo={1}
        overrideYearFrom={1989}
      />
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        インテーク（情報の収集・整理）
      </Typography>
      <div className={classes.MT32}>
        <RecordTextField
          name="intake"
          label="情報（見たこと、聴いたこと、資料などから）"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.intake}
          isEditable={isEditing}
        />
      </div>

      <Typography
        variant="subtitle2"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        アセスメント（評価）とプランニング（支援計画）
      </Typography>
      <div className={classes.MT24}>
        <Typography>理解・解釈・仮説（わかったこと、推測したこと）</Typography>
      </div>
      <div className={classes.MT24}>
        <RecordTextField
          name="assessment_biological"
          label="生物的なこと（疾患や障害、気質など）"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.assessment_biological}
          isEditable={isEditing}
        />
      </div>
      <div className={classes.MT24}>
        <RecordTextField
          name="assessment_psychological"
          label="心理的なこと（不安、葛藤、希望、感情など）"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.assessment_psychological}
          isEditable={isEditing}
        />
      </div>
      <div className={classes.MT32}>
        <RecordTextField
          name="assessment_social"
          label="社会的なこと（家庭、施設・学校、地域資源など）"
          labelType="default"
          defaultValue="-"
          placeholder=""
          value={initialValues.assessment_social}
          isEditable={isEditing}
        />
      </div>
      <div className={classes.MT48}>
        <Typography>支援課題と対応・方針</Typography>
      </div>
      <div className={classes.MT16}>
        <div className={classes.header}>
          {isEditing ? (
            <>
              <span className={classes.index}>No.</span>
              <span className={classes.issue}>
                支援課題（支援の必要なこと）
              </span>
              <span className={classes.policy}>
                対応・方針（やろうと思うこと）
              </span>
              <span className={classes.deleteIcon}>削除</span>
            </>
          ) : (
            <>
              <span className={classes.index}>No.</span>
              <span className={classes.issue}>
                支援課題（支援の必要なこと）
              </span>
              <span className={classes.policy}>
                対応・方針（やろうと思うこと）
              </span>
            </>
          )}
        </div>
      </div>
      {!isEditing && initialValues.support_plan_sheet_details.length === 0 ? (
        <div className={classes.tableBottom}>
          <div className={classes.noData}>支援課題が登録されていません。</div>
        </div>
      ) : (
        <div />
      )}
      {!isEditing ? (
        initialValues.support_plan_sheet_details.map((value, index) => {
          return (
            <div className={classes.body}>
              <span className={classes.indexBody}>
                {circleNumbersList[index]}
              </span>
              <span className={classes.issueBody}>
                <RecordTextField
                  name="support_issue"
                  labelType="default"
                  defaultValue="-"
                  placeholder=""
                  value={value.support_issue}
                  isEditable={isEditing}
                />
              </span>
              <span className={classes.policyBody}>
                <RecordTextField
                  name="response_policy"
                  labelType="default"
                  defaultValue="-"
                  placeholder=""
                  value={value.response_policy}
                  isEditable={isEditing}
                  style={{
                    minHeight: "33px"
                  }}
                />
              </span>
              <span className={classes.deleteIcon} />
            </div>
          );
        })
      ) : (
        <FieldArray name="support_plan_sheet_details">
          {(arrayHelpers: ArrayHelpers): JSX.Element => {
            const supportPlanSheetDetails =
              formikProps.values.support_plan_sheet_details;

            let deleteFalseIndex = 0;
            return (
              <div>
                {supportPlanSheetDetails.map((item, index) => {
                  // 削除しないdetailのみindex
                  if (item.is_delete === false) deleteFalseIndex += 1;

                  return item.is_delete === false ? (
                    <div className={classes.body}>
                      <span className={classes.indexBody}>
                        {circleNumbersList[deleteFalseIndex - 1]}
                      </span>
                      <span className={classes.editIssueBody}>
                        <FormikTextField
                          name={`support_plan_sheet_details[${index}].support_issue`}
                          size="fullSize"
                          style={{
                            marginBottom: "16px"
                          }}
                        />
                      </span>
                      <span className={classes.editPolicyBody}>
                        <FormikTextField
                          name={`support_plan_sheet_details[${index}].response_policy`}
                          size="fullSize"
                          style={{
                            marginBottom: "16px"
                          }}
                          multiline
                        />
                      </span>
                      {supportPlanSheetDetails.length > 1 ? (
                        <KnowbeButton
                          kind="iconText"
                          className={classes.deleteIcon}
                          onClick={(): void => {
                            if (item.support_plan_sheet_detail_id !== null) {
                              supportPlanSheetDetails[index] = {
                                ...supportPlanSheetDetails[index],
                                is_delete: true
                              };
                              setDeletedCount(deletedCount + 1);
                            }
                            if (item.support_plan_sheet_detail_id === null)
                              arrayHelpers.remove(index);
                          }}
                        >
                          <DeleteIcon />
                        </KnowbeButton>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <div />
                  );
                })}
                <KnowbeButton
                  kind="iconText"
                  className={classes.MT16}
                  onClick={(): void =>
                    arrayHelpers.push(supportPlanSheetDetailsValue)
                  }
                  disabled={
                    formikProps.values.support_plan_sheet_details.length -
                      deletedCount >=
                    20
                  }
                >
                  <AddIcon />
                  <span className={classes.btnTxt}>
                    支援課題と対応・方針を追加
                  </span>
                </KnowbeButton>
              </div>
            );
          }}
        </FieldArray>
      )}
      <div className={ClassNames(classes.col, classes.MT48)}>
        <div className={classes.colItem}>
          <RecordSelect
            name="author"
            label="作成者"
            defaultValue="-"
            placeholder="選択してください"
            options={staffOptionsAddSnapShot}
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            value={authorName}
            isEditable={isEditing}
            isSelectablePlaceholder
          />
        </div>
        <div className={ClassNames(classes.colItem, classes.ML16)}>
          <ReadonlyTextField
            value={isEditing ? roleValue : authorizerRole}
            label="役職"
            defaultValue="-"
          />
        </div>
      </div>
    </div>
  );
};

export const SupportPlanSheetsForm = withStyles(styles)(
  SupportPlanSheetsFormCore
);
