import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostSupportPlanUifIdCarePlanCarePlanIdDetails = {
  support_care_plan_schedules: {
    support_care_plan_schedule_id?: number;
    status: number;
    support_care_plan_basic_info: {
      support_care_plan_basic_info_id?: number;
      day_of_week: number[];
      start_time: string | null;
      end_time: string | null;
      end_time_next_day_flg: number;
      number_of_time: string | null;
      number_of_rides: number | null;
      number_of_participants: number | null;
      practitioner1?: number | null;
      practitioner2?: number | null;
      is_delete: number;
    }[];
    support_care_plan_details: {
      support_care_plan_detail_id?: number;
      time_required: string | null;
      service_details: string | null;
      point: string | null;
      to_do: string | null;
      is_delete: number;
    }[];
  }[];
};

/**
 * 対象ユーザーの介護計画内にある計画予定表を更新する。
 * @param uifId ユーザーID
 * @param carePlanId carePlanID
 * @param params 更新データ
 */
export const postSupportPlanUifIdCarePlanCarePlanIdDetails = async (
  uifId: string,
  carePlanId: string,
  params: PostSupportPlanUifIdCarePlanCarePlanIdDetails
): Promise<AxiosResponse<PostSupportPlanUifIdCarePlanCarePlanIdDetails>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/care_plan/${carePlanId}/details`;
  return request.post(url, params);
};
