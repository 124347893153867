import { format } from "date-fns";
import deepEqual from "fast-deep-equal";

import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { RequestParamToPostSupportProcedureDetailAPI } from "@api/requests/supportProcedure/postSupportProcedureDetail";
import { RequestParamToPostSupportProcedureDetailFormAPI } from "@api/requests/supportProcedure/postSupportProcedureDetailForm";
import { TARGET_FLG, TARGET_PATH } from "@constants/mgr/KODOENGO/variables";
import { DAY_SELECT_TYPE, INT_TRUE_FROM_API } from "@constants/variables";
import { TargetObject } from "@hooks/record/supportProcedure/useLocalStorage";
import {
  SupportProcedureDetailFormValues,
  SupportProcedureFormPractitioners
} from "@initialize/mgr/KODOENGO/record/supportProcedure/initialValues";
import { StaffState } from "@stores/domain/staff/types";
import getStaffName from "@utils/domain/staffs/getStaffName";

/** 日時情報を組み合わせて、対象日付の文字列を生成する */
const convertTimeString = (
  targetDate: Date,
  formTime: string,
  formTimeDate: string
): string => {
  if (formTime === "") return "";
  const date = new Date(targetDate);
  const [hour, minutes] = formTime.split(":").map(Number);
  date.setHours(hour);
  date.setMinutes(minutes);

  if (formTimeDate === DAY_SELECT_TYPE.nextDay) {
    date.setDate(date.getDate() + 1);
  }

  return format(date, "YYYY-MM-DD HH:mm:ss");
};

/** APIの値とフォームの値などを受け取り、設定するべき事業所内サービス提供者の情報を生成する */
const convertPractitioner = (
  practitioner: SupportProcedureDetailFormValues["supportProcedureFormPractitioners1"],
  targetDate: Date,
  staff: StaffState,
  practitionerNum: number,
  isNew: boolean,
  apiPractitioner?: GetSupportProcedureDetailResponse["data"]["support_procedure_form_practitioners"][number]
): RequestParamToPostSupportProcedureDetailAPI["support_procedure_forms"]["support_procedure_form_practitioners"][number] => {
  const { startTime, endTime, practitionerId } = practitioner;
  const isSnapshot = practitionerId === "0";
  const postData: ReturnType<typeof convertPractitioner> = {
    support_procedure_form_practitioners_id:
      apiPractitioner && !isNew
        ? apiPractitioner.support_procedure_form_practitioners_id
        : null,
    start_time: startTime
      ? convertTimeString(targetDate, startTime, practitioner.startTimeDate)
      : null,
    end_time: endTime
      ? convertTimeString(targetDate, endTime, practitioner.endTimeDate)
      : null,
    practitioner: Number(practitioner.practitionerId) || null,
    practitioner_name: getStaffName(staff, practitioner.practitionerId),
    practitioner_num: practitionerNum
  };
  const isEqual =
    apiPractitioner &&
    apiPractitioner.practitioner &&
    apiPractitioner.practitioner.id === Number(practitioner.practitionerId);

  const isApiSnapshot =
    apiPractitioner &&
    apiPractitioner.practitioner &&
    apiPractitioner.practitioner.name !==
      apiPractitioner.practitioner.snapshot_name;

  if ((isSnapshot || (!isApiSnapshot && isEqual)) && !isNew) {
    delete postData.practitioner;
    delete postData.practitioner_name;
  }
  return postData;
};

const removeNoChangeData = <T>(beforeList: T[], afterList: T[]): T[] => {
  const resultList = afterList.filter((after, idx) => {
    return !deepEqual(after, beforeList[idx]);
  });
  return resultList;
};

export const normalizePostSupportProcedureDetailToAPI = (
  formValues: SupportProcedureDetailFormValues,
  staff: StaffState,
  apiResponse: GetSupportProcedureDetailResponse["data"],
  targetPath: keyof typeof TARGET_FLG,
  targetObjects: TargetObject[],
  uifId: number,
  queryTargetDate: string | undefined,
  originSupportProcedureFormsId?: string
): RequestParamToPostSupportProcedureDetailAPI => {
  const {
    service_delivery_records_id,
    inout_results_id,
    support_procedure_forms_id,
    support_procedure_form_practitioners,
    support_procedure_form_other_practitioners,
    support_procedure_form_details
  } = apiResponse;

  const {
    supportProcedureFormPractitioners1,
    supportProcedureFormPractitioners2,
    supportProcedureFormOtherPractitioners,
    supportProcedureFormDetails
  } = formValues;

  const isNew =
    targetPath === TARGET_PATH.new || targetPath === TARGET_PATH.copy;

  // 複数作成
  const isSingle = targetObjects.length === 1;
  const firstTargetObject = targetObjects[0];

  // サービス提供者は単日選択の場合のみ設定される
  const practitioners: RequestParamToPostSupportProcedureDetailAPI["support_procedure_forms"]["support_procedure_form_practitioners"] = [];
  if (isSingle && firstTargetObject.targetDate) {
    const targetDate = new Date(
      firstTargetObject.targetDate.replace(/-/g, "/")
    );
    const [
      apiPractitioner1,
      apiPractitioner2
    ] = support_procedure_form_practitioners.sort(
      (a, b) => a.practitioner_num - b.practitioner_num
    );
    practitioners.push(
      convertPractitioner(
        supportProcedureFormPractitioners1,
        targetDate,
        staff,
        1,
        isNew,
        apiPractitioner1
      )
    );

    if (formValues.numberOfPractitioner === "2") {
      practitioners.push(
        convertPractitioner(
          supportProcedureFormPractitioners2,
          targetDate,
          staff,
          2,
          isNew,
          apiPractitioner2
        )
      );
    }
  }

  // 他事業所サービス提供者も単日選択の場合のみ設定
  const otherPractitioners: RequestParamToPostSupportProcedureDetailAPI["support_procedure_forms"]["support_procedure_form_other_practitioners"] = [];
  if (isSingle && firstTargetObject.targetDate) {
    const targetDate = new Date(
      firstTargetObject.targetDate.replace(/-/g, "/")
    );

    switch (targetPath) {
      case TARGET_PATH.new:
      case TARGET_PATH.copy:
        supportProcedureFormOtherPractitioners.forEach((v) => {
          otherPractitioners.push({
            support_procedure_form_other_practitioners_id:
              v.supportProcedureFormOtherPractitionersId || null,
            start_time:
              convertTimeString(targetDate, v.startTime, v.startTimeDate) ||
              null,
            end_time:
              convertTimeString(targetDate, v.endTime, v.endTimeDate) || null,
            practitioner_name: v.practitionerName,
            facility_name: v.facilityName
          });
        });
        break;

      case TARGET_PATH.edit:
      case TARGET_PATH.setDate: {
        const otherPractitionerFormValues: typeof otherPractitioners = [];
        const otherPractitionerFormAddValues: typeof otherPractitioners = [];
        supportProcedureFormOtherPractitioners.forEach((v) => {
          const id = v.supportProcedureFormOtherPractitionersId;
          // Formの値を既存の値と新規追加した値で分ける
          if (id !== null) {
            otherPractitionerFormValues.push({
              support_procedure_form_other_practitioners_id: id,
              start_time:
                convertTimeString(targetDate, v.startTime, v.startTimeDate) ||
                null,
              end_time:
                convertTimeString(targetDate, v.endTime, v.endTimeDate) || null,
              practitioner_name: v.practitionerName,
              facility_name: v.facilityName
            });
          } else {
            otherPractitionerFormAddValues.push({
              support_procedure_form_other_practitioners_id: id,
              start_time:
                convertTimeString(targetDate, v.startTime, v.startTimeDate) ||
                null,
              end_time:
                convertTimeString(targetDate, v.endTime, v.endTimeDate) || null,
              practitioner_name: v.practitionerName,
              facility_name: v.facilityName
            });
          }
        });

        const baseData: typeof otherPractitioners = [];
        support_procedure_form_other_practitioners.forEach((v) =>
          baseData.push(v)
        );

        // 更新があった値のみをリクエストに追加
        const changedData = removeNoChangeData(
          baseData,
          otherPractitionerFormValues
        );
        changedData.forEach((v) => otherPractitioners.push(v));

        // 削除された値にフラグを立ててリクエストに追加
        support_procedure_form_other_practitioners.forEach((p) => {
          const isDelete = !otherPractitionerFormValues.some(
            (v) =>
              p.support_procedure_form_other_practitioners_id ===
              v.support_procedure_form_other_practitioners_id
          );
          if (isDelete) {
            otherPractitioners.push({ ...p, is_delete: INT_TRUE_FROM_API });
          }
        });

        // 新規追加した値をリクエストに追加
        otherPractitionerFormAddValues.forEach((v) =>
          otherPractitioners.push(v)
        );
        break;
      }
      default:
    }
  }

  const formDetails: RequestParamToPostSupportProcedureDetailAPI["support_procedure_forms"]["support_procedure_form_details"] = [];
  switch (targetPath) {
    case TARGET_PATH.new:
    case TARGET_PATH.copy:
      supportProcedureFormDetails.forEach((v) => {
        formDetails.push({
          support_procedure_form_details_id: null,
          start_time: v.startTime ? `${v.startTime}:00` : null,
          end_time: v.endTime ? `${v.endTime}:00` : null,
          activity: v.activity,
          service_procedure: v.serviceProcedure,
          check: v.check,
          appearance: v.appearance
        });
      });
      break;
    case TARGET_PATH.edit:
    case TARGET_PATH.setDate: {
      const formDetailsFormValues: typeof formDetails = [];
      const formDetailsFormAddValues: typeof formDetails = [];
      supportProcedureFormDetails.forEach((v) => {
        const id = v.supportProcedureFormDetailsId;
        if (id) {
          formDetailsFormValues.push({
            support_procedure_form_details_id: id,
            start_time: v.startTime ? `${v.startTime}:00` : null,
            end_time: v.endTime ? `${v.endTime}:00` : null,
            activity: v.activity,
            service_procedure: v.serviceProcedure,
            check: v.check,
            appearance: v.appearance
          });
        } else {
          formDetailsFormAddValues.push({
            support_procedure_form_details_id: id,
            start_time: v.startTime ? `${v.startTime}:00` : null,
            end_time: v.endTime ? `${v.endTime}:00` : null,
            activity: v.activity,
            service_procedure: v.serviceProcedure,
            check: v.check,
            appearance: v.appearance
          });
        }
      });

      const baseData: typeof formDetails = [];
      support_procedure_form_details.forEach((v) =>
        baseData.push({ ...v, check: v.check })
      );

      // 更新があった値のみをリクエストに追加
      const changedData = removeNoChangeData(baseData, formDetailsFormValues);
      changedData.forEach((v) => formDetails.push(v));

      // 削除された値にフラグを立ててリクエストに追加
      support_procedure_form_details.forEach((p) => {
        const isDelete = !formDetailsFormValues.some(
          (v) =>
            p.support_procedure_form_details_id ===
            v.support_procedure_form_details_id
        );
        if (isDelete) {
          formDetails.push({
            ...p,
            check: p.check,
            is_delete: INT_TRUE_FROM_API
          });
        }
      });

      // 新規追加した値をリクエストに追加
      formDetailsFormAddValues.forEach((v) => formDetails.push(v));
      break;
    }
    default:
  }

  const convertTargetObject: RequestParamToPostSupportProcedureDetailAPI["target_object"] = [];
  targetObjects.forEach((v) => {
    convertTargetObject.push({
      service_delivery_records_id: v.serviceDeliveryRecordsId,
      inout_results_id: v.inoutResultsId,
      support_procedure_forms_id: v.supportProcedureFormsId,
      target_date: v.targetDate
    });
  });

  const isAuthorSnapshot = Number(formValues.authorId) === 0;

  const postData: RequestParamToPostSupportProcedureDetailAPI = {
    support_procedure_forms: {
      service_delivery_records_id: isNew ? null : service_delivery_records_id,
      inout_results_id: isNew ? null : inout_results_id,
      support_procedure_forms_id: isNew ? null : support_procedure_forms_id,
      users_in_facility_id: uifId,
      target_date: queryTargetDate || null,
      title: formValues.title,
      number_of_practitioner: Number(formValues.numberOfPractitioner),
      author: formValues.authorId,
      author_name: getStaffName(staff, formValues.authorId),
      role: formValues.role,
      support_procedure_form_practitioners: practitioners,
      support_procedure_form_other_practitioners: otherPractitioners,
      support_procedure_form_details: formDetails,
      contact_information_plan: formValues.contactInformationPlan,
      inquiry_items_plan: formValues.inquiryItemsPlan,
      contact_information_record: formValues.contactInformationRecord,
      inquiry_items_record: formValues.inquiryItemsRecord,
      origin_support_procedure_forms_id: originSupportProcedureFormsId
        ? Number(originSupportProcedureFormsId)
        : undefined
    },
    target_flg: TARGET_FLG[targetPath],
    target_object: convertTargetObject
  };

  const isAuthorEqual =
    apiResponse.author && apiResponse.author.id === formValues.authorId;
  const isAuthorApiIsSnapshot =
    apiResponse.author &&
    apiResponse.author.name !== apiResponse.author.snapshot_name;

  // スナップショットもしくは変更がない場合は作成者を送信しない
  if (isAuthorSnapshot || (!isAuthorApiIsSnapshot && isAuthorEqual)) {
    delete postData.support_procedure_forms.author;
    delete postData.support_procedure_forms.author_name;
  }

  return postData;
};

/** 記録側 支援手順書 兼 記録用紙 のサービス提供者の変換 */
const convertFormPractitionerToPostData = (
  practitioner: SupportProcedureFormPractitioners,
  practitionerNum: number,
  targetDate: Date,
  apiPractitioner?: GetSupportProcedureDetailResponse["data"]["support_procedure_form_practitioners"][number]
): RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_practitioners"][number] => {
  const isSnapshot = Number(practitioner.practitionerId) === 0;
  const postData: ReturnType<typeof convertFormPractitionerToPostData> = {
    support_procedure_form_practitioners_id:
      practitioner.supportProcedureFormPractitionersId,
    start_time:
      convertTimeString(
        targetDate,
        practitioner.startTime,
        practitioner.startTimeDate
      ) || null,
    end_time:
      convertTimeString(
        targetDate,
        practitioner.endTime,
        practitioner.endTimeDate
      ) || null,
    practitioner: Number(practitioner.practitionerId) || null, // 未選択時(0の場合)は null を送信する
    practitioner_num: practitionerNum
  };
  const isEqual =
    apiPractitioner &&
    apiPractitioner.practitioner &&
    apiPractitioner.practitioner.id === Number(practitioner.practitionerId);

  const isApiSnapshot =
    apiPractitioner &&
    apiPractitioner.practitioner &&
    apiPractitioner.practitioner.name !==
      apiPractitioner.practitioner.snapshot_name;

  if (isSnapshot || (!isApiSnapshot && isEqual)) {
    delete postData.practitioner;
  }
  return postData;
};

export const normalizePostSupportProcedureDetailFormToApi = (
  formValues: SupportProcedureDetailFormValues,
  response: GetSupportProcedureDetailResponse["data"]
): RequestParamToPostSupportProcedureDetailFormAPI => {
  const supportProcedureFormPractitioners: RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_practitioners"] = [];

  const targetDate = new Date(response.target_date || "");

  const [
    apiPractitioner1,
    apiPractitioner2
  ] = response.support_procedure_form_practitioners.sort(
    (a, b) => a.practitioner_num - b.practitioner_num
  );

  supportProcedureFormPractitioners.push(
    convertFormPractitionerToPostData(
      formValues.supportProcedureFormPractitioners1,
      1,
      targetDate,
      apiPractitioner1
    )
  );

  if (Number(formValues.numberOfPractitioner) === 2) {
    supportProcedureFormPractitioners.push(
      convertFormPractitionerToPostData(
        formValues.supportProcedureFormPractitioners2,
        2,
        targetDate,
        apiPractitioner2
      )
    );
  }

  const supportProcedureFormOtherPractitioners = formValues.supportProcedureFormOtherPractitioners.map<
    RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_other_practitioners"][number]
  >((value) => ({
    support_procedure_form_other_practitioners_id:
      value.supportProcedureFormOtherPractitionersId,
    start_time:
      convertTimeString(targetDate, value.startTime, value.startTimeDate) ||
      null,
    end_time:
      convertTimeString(targetDate, value.endTime, value.endTimeDate) || null,
    practitioner_name: value.practitionerName,
    facility_name: value.facilityName
  }));

  response.support_procedure_form_other_practitioners.forEach(
    (otherPractitioner) => {
      const isDelete = !supportProcedureFormOtherPractitioners.some(
        (practitioner) =>
          practitioner.support_procedure_form_other_practitioners_id ===
          otherPractitioner.support_procedure_form_other_practitioners_id
      );
      if (isDelete) {
        supportProcedureFormOtherPractitioners.push({
          ...otherPractitioner,
          is_delete: INT_TRUE_FROM_API
        });
      }
    }
  );

  const supportProcedureFormDetails = formValues.supportProcedureFormDetails.map<
    RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_details"][number]
  >((value) => ({
    support_procedure_form_details_id: value.supportProcedureFormDetailsId,
    check: value.check,
    appearance: value.appearance
  }));

  return {
    support_procedure_forms: {
      users_in_facility_id: response.users_in_facility_id,
      number_of_practitioner: Number(formValues.numberOfPractitioner),
      support_procedure_form_practitioners: supportProcedureFormPractitioners,
      support_procedure_form_other_practitioners: supportProcedureFormOtherPractitioners,
      support_procedure_form_details: supportProcedureFormDetails,
      contact_information_record: formValues.contactInformationRecord,
      inquiry_items_record: formValues.inquiryItemsRecord
    }
  };
};

/** 支援記録 支援手順書 兼 記録用紙 記録側削除 */
export const normalizePostSupportProcedureDetailFormRecordDeleteToApi = (
  response: GetSupportProcedureDetailResponse["data"]
): RequestParamToPostSupportProcedureDetailFormAPI => {
  const supportProcedureFormPractitioners = response.support_procedure_form_practitioners.map<
    RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_practitioners"][number]
  >((v) => ({
    support_procedure_form_practitioners_id:
      v.support_procedure_form_practitioners_id,
    start_time: v.start_time,
    end_time: v.end_time,
    practitioner_num: v.practitioner_num
  }));

  const supportProcedureFormOtherPractitioners = response.support_procedure_form_other_practitioners.map<
    RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_other_practitioners"][number]
  >((v) => ({
    support_procedure_form_other_practitioners_id:
      v.support_procedure_form_other_practitioners_id,
    start_time: v.start_time,
    end_time: v.end_time,
    practitioner_name: v.practitioner_name,
    facility_name: v.facility_name
  }));

  const supportProcedureFormDetails = response.support_procedure_form_details.map<
    RequestParamToPostSupportProcedureDetailFormAPI["support_procedure_forms"]["support_procedure_form_details"][number]
  >((v) => ({
    support_procedure_form_details_id: v.support_procedure_form_details_id,
    check: 0,
    appearance: null
  }));

  return {
    support_procedure_forms: {
      users_in_facility_id: response.users_in_facility_id,
      number_of_practitioner: response.number_of_practitioner,
      support_procedure_form_practitioners: supportProcedureFormPractitioners,
      support_procedure_form_other_practitioners: supportProcedureFormOtherPractitioners,
      support_procedure_form_details: supportProcedureFormDetails,
      contact_information_record: null,
      inquiry_items_record: null,
      is_delete: 1
    }
  };
};
