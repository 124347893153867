import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import { CHIIKITEICHAKU_DISPLAY_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { InoutConsultationResultsState } from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";

const styles = (): StyleRules =>
  createStyles({
    cell: {
      boxSizing: "border-box",
      padding: "16px 8px",
      height: 56
    },
    middleCell: {
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      "&:last-child": {
        paddingRight: 16
      }
    },
    longCell: {
      width: 180,
      minWidth: 180,
      maxWidth: 180,
      wordBreak: "break-all",
      "&:last-child": {
        paddingRight: 16
      }
    },
    baseCell: {
      paddingLeft: 4,
      paddingRight: 4,
      lineHeight: "20px"
    }
  });

type OwnProps = {
  params: InoutConsultationResultsState;
  idx: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportCellCore = (props: Props): JSX.Element => {
  const { params, idx, classes } = props;

  return (
    <>
      {/* サービス提供の状況 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        {params.status
          ? getLabelFromOptions(
              params.status.toString(),
              CHIIKITEICHAKU_DISPLAY_STATUS_LIST
            )
          : "-"}
      </TableCellWrap>

      {/* 居住支援連携体制 */}
      <TableCellWrap
        key={`${idx}-housing-support-cooperation-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        {params.housingSupportCooperationFlg ? "対象" : "-"}
      </TableCellWrap>

      {/* 地域居住支援体制強化推進 */}
      <TableCellWrap
        key={`${idx}-housing-support-promotion-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        {params.housingSupportPromotionFlg ? "実施" : "-"}
      </TableCellWrap>

      {/* 日常生活支援情報提供 */}
      <TableCellWrap
        key={`${idx}-daily-support-provision-of-info-flg`}
        cellClass={`${classes.middleCell} ${classes.baseCell}`}
      >
        {params.dailySupportProvisionOfInfoFlg ? "実施" : "-"}
      </TableCellWrap>

      {/* 備考 */}
      <TableCellWrap
        key={`${idx}-memo`}
        cellClass={`${classes.cell} ${classes.longCell}`}
      >
        {params.memo || "-"}
      </TableCellWrap>
    </>
  );
};

export const InOutReportCell = withStyles(styles)(InOutReportCellCore);
