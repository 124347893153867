import * as types from "./types";
import * as actions from "./actions";

const initialState: types.RecordUsersDailyState = {
  users: [],
  searchConditionsIds: [],
  currentPage: 1,
  isRecord: 0
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.RecordUsersDailyState => {
  switch (action.type) {
    case types.SET_USERS:
      return { ...state, users: action.payload };
    case types.SET_SEARCH_CONDITIONS:
      return { ...state, searchConditionsIds: action.payload };
    case types.SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload };
    case types.SET_IS_RECORD:
      return { ...state, isRecord: action.payload };
    case types.UNSET_SEARCH_CONDITIONS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
