import * as React from "react";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

import { Theme } from "@material-ui/core/styles/createMuiTheme";

import blueGrey from "@material-ui/core/colors/blueGrey";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    header: {
      backgroundColor: blueGrey[300],
      color: palette.common.white
    },
    userList: {
      color: palette.primary.main,
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: "16px",
      paddingBottom: "16px"
    }
  });

type ListProps = {
  id: number;
  theme?: string;
  button?: boolean;
  children: React.ReactNode;
};

type Props = WithStyles<typeof styles> & ListProps;

const listItem = ({
  children,
  classes,
  theme = "userList",
  button = true
}: Props): JSX.Element => {
  return (
    <ListItem className={`${classes[theme]}`} button={button}>
      {children}
    </ListItem>
  );
};

export default withStyles(styles)(listItem);
