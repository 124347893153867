import {
  FacilityType,
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";
import { SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM as SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO } from "@constants/mgr/TANKINYUSHO/variables";

// 項目の新規追加・編集モーダル用
export type customRecordsValues = {
  id: number | null;
  default_item: number | null;
  name: string;
  input_type: string; //  MuiRadioButtonsのvalueがstring型固定のためstring型
  setting_type: number;
  custom_record_item_choices: {
    key: number;
    id: number | null;
    custom_record_item_id: number | null;
    name: string;
    default_choice: number | null;
    hidden: number;
    dirty: boolean;
    delete: boolean;
  }[];
};

export const initialValues = (
  serviceType: FacilityType,
  state?: CustomRecordsTableBodyValues[number],
  customRecordItemId?: number | null,
  settingType?: number
): customRecordsValues => {
  const customRecordsItemChoices: customRecordsValues["custom_record_item_choices"] = state
    ? state.choices.map((choice, index) => {
        return {
          key: index,
          id: choice.id,
          custom_record_item_id: choice.custom_record_item_id,
          name: choice.name,
          default_choice: choice.default_choice,
          hidden: choice.hidden,
          dirty: false,
          delete: false
        };
      })
    : [];
  let default_item;
  switch (serviceType) {
    case FacilityType.TANKINYUSHO:
      default_item = SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO;
      break;
    default:
      default_item = SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM;
      break;
  }
  const choicesTotal = state
    ? state.choices.filter((choice) => choice.hidden === 0).length
    : 0;
  const maxRowLength =
    state &&
    state.setting_type === CUSTOM_RECORD_TARGET_TYPE.support &&
    state.default_item === default_item.meal
      ? 6
      : 10;

  // 選択肢の表示合計数が上限より少ない場合に新規列追加
  if (choicesTotal < maxRowLength) {
    customRecordsItemChoices.push({
      key: state ? state.choices.length : 0,
      id: null,
      custom_record_item_id: state ? state.id : customRecordItemId || null,
      name: "",
      default_choice: null,
      hidden: 0,
      dirty: false,
      delete: false
    });
  }

  return {
    id: state ? state.id : null,
    default_item: state ? state.default_item : null,
    name: state ? state.name : "",
    input_type: state ? String(state.input_type) : "1",
    setting_type: state ? state.setting_type : settingType || 0,
    custom_record_item_choices: customRecordsItemChoices
  };
};

// テーブルボディ用（並べ替え用）
export type CustomRecordsTableBodyValues = {
  id: number;
  default_item: number | null;
  name: string;
  input_type: number;
  setting_type: number;
  order: number | null;
  choices: {
    id: number | null;
    custom_record_item_id: number | null;
    name: string;
    default_choice: number | null;
    hidden: number;
  }[];
  allow_edit_name: number;
  allow_edit_choiced: number;
  allow_change_order: number;
  allow_change_visibility: number;
}[];

export const tableBodyInitialValues = (
  state?: CustomRecordsTableBodyValues
): CustomRecordsTableBodyValues => {
  if (state) {
    return state.map((item) => {
      return {
        id: item.id,
        default_item: item.default_item,
        name: item.name,
        input_type: item.input_type,
        setting_type: item.setting_type,
        order: item.order,
        choices: item.choices,
        allow_edit_name: item.allow_edit_name,
        allow_edit_choiced: item.allow_edit_choiced,
        allow_change_order: item.allow_change_order,
        allow_change_visibility: item.allow_change_visibility
      };
    });
  }

  return [];
};
