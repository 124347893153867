import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

// utils
import * as URL from "@constants/url";
import { AssessmentSheet } from "@components/organisms/mgr/Cseg/record/assessment/AssessmentSheet";
import { FacilityType } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  assessmentId: string;
  copyId: string;
}>;

type StateProps = {
  user: KEIKAKUSODANUsersInFacilityState["user"];
  facilityType: FacilityType;
};

type DispatchProps = {
  fetchOne: (uifId: string, facilityType: FacilityType) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;

/**
 * 支援計画 > アセスメント一覧
 */
const AssessmentCore = (props: Props): JSX.Element => {
  const query = props.location.search;
  const { uifId, assessmentId, copyId } = props.match.params;
  const { classes, facilityType } = props;

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "アセスメント",
      path: `/record/support_plan/${uifId}/assessment`
    }
  ];

  // 現在ページのタイトル名
  const pageName = "アセスメントシート";

  React.useEffect(() => {
    props.fetchOne(uifId, facilityType);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <AssessmentSheet
          userName={props.userName}
          assessmentId={assessmentId}
          copyId={copyId}
          uifId={uifId}
          history={props.history}
          facilityType={facilityType}
          query={query}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    user: state[facilityType].userInFacility.user
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchOne: (uifId: string, facilityType): void => {
      dispatches[facilityType]
        .userInFacilityDispatcher(dispatch)
        .fetchOne(uifId);
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const Assessment = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(AssessmentCore));
