import { GetSupportPlanOnceResponse } from "@api/requests/supportPlan/SHUROTEICHAKU/getSupportPlanOnce";
import { GetSupportPlanResponse } from "@api/requests/supportPlan/SHUROTEICHAKU/getSupportPlan";

/**
 * ActionNames
 */
export const FETCH_PRIVATE_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_STARTED";
export const FETCH_PRIVATE_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_SUCCESS";
export const FETCH_PRIVATE_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/FETCH_PRIVATE_SUPPORT_PLAN_FAILED";
export const FETCH_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/FETCH_SUPPORT_PLAN_STARTED";
export const FETCH_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/FETCH_SUPPORT_PLAN_SUCCESS";
export const FETCH_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/FETCH_SUPPORT_PLAN_FAILED";

export const POST_NEW_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/POST_NEW_SUPPORT_PLAN_STARTED";
export const POST_NEW_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/POST_NEW_SUPPORT_PLAN_SUCCESS";
export const POST_NEW_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/POST_NEW_SUPPORT_PLAN_FAILED";

export const POST_UPDATE_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/POST_UPDATE_SUPPORT_PLAN_STARTED";
export const POST_UPDATE_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/POST_UPDATE_SUPPORT_PLAN_SUCCESS";
export const POST_UPDATE_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/POST_UPDATE_SUPPORT_PLAN_FAILED";

export const DELETE_SUPPORT_PLAN_STARTED =
  "SUPPORT_PLAN/DELETE_SUPPORT_PLAN_STARTED";
export const DELETE_SUPPORT_PLAN_SUCCESS =
  "SUPPORT_PLAN/DELETE_SUPPORT_PLAN_SUCCESS";
export const DELETE_SUPPORT_PLAN_FAILED =
  "SUPPORT_PLAN/DELETE_SUPPORT_PLAN_FAILED";

/**
 * State
 */
export type SupportPlanState = {
  privateSupportPlan: GetSupportPlanOnceResponse["data"];
  supportPlan: GetSupportPlanResponse["data"];
};
