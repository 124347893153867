import React from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import { FacilityType } from "@constants/variables";
import { getFacilityId, getToken } from "@utils/localStorage";
import AdminRoute from "@app/Auth/AdminRoute";
import UserRoute from "@app/Auth/UserRoute";
import SupportRoute from "@app/Auth/SupportRoute";
import { ExpiredTokenModal } from "@components/presentational/molecules/ExpiredTokenModal";
import { ExpiredTokenModal as MobileExpiredTokenModal } from "@componentsMobile/presentational/molecules/ExpiredTokenModal";

// 種別ごとのルーティング
import SEIKATSUKAIGORoutes from "@app/FacilityTypeRoutes/SEIKATSUKAIGORoutes";
import SHUROTEICHAKURoutes from "@app/FacilityTypeRoutes/SHUROTEICHAKURoutes";
import TANKINYUSHORoutes from "@app/FacilityTypeRoutes/TANKINYUSHORoutes";
import JIRITSUKUNRENSEIKATSURoutes from "@app/FacilityTypeRoutes/JIRITSUKUNRENSEIKATSURoutes";
import SHISETSUNYUSHORoutes from "@app/FacilityTypeRoutes/SHISETSUNYUSHORoutes";
import GroupHomeRoutes from "@app/FacilityTypeRoutes/GroupHomeRoutes";
import IABRoutes from "@app/FacilityTypeRoutes/IABRoutes";
import { KYOTAKUKAIGORoutes } from "@app/FacilityTypeRoutes/KYOTAKUKAIGORoutes";
import { JUDOHOMONKAIGORoutes } from "@app/FacilityTypeRoutes/JUDOHOMONKAIGORoutes";
import { DOKOENGORoutes } from "@app/FacilityTypeRoutes/DOKOENGORoutes";
import { KODOENGORoutes } from "@app/FacilityTypeRoutes/KODOENGORoutes";
import { IDOSHIENRoutes as IDOSHIENMobileRoutes } from "@app/FacilityTypeMobileRoutes/IDOSHIENRoutes";
import { KYOTAKUKAIGORoutes as KYOTAKUKAIGOMobileRoutes } from "@app/FacilityTypeMobileRoutes/KYOTAKUKAIGORoutes";
import { JUDOHOMONKAIGORoutes as JUDOHOMONKAIGOMobileRoutes } from "@app/FacilityTypeMobileRoutes/JUDOHOMONKAIGORoutes";
import { DOKOENGORoutes as DOKOENGOMobileRoutes } from "@app/FacilityTypeMobileRoutes/DOKOENGORoutes";
import { KODOENGORoutes as KODOENGOMobileRoutes } from "@app/FacilityTypeMobileRoutes/KODOENGORoutes";
import { CHIIKIIKORoutes } from "@app/FacilityTypeRoutes/CHIIKIIKORoutes";
import { CHIIKITEICHAKURoutes } from "@app/FacilityTypeRoutes/CHIIKITEICHAKURoutes";
import { KEIKAKUSODANRoutes } from "@app/FacilityTypeRoutes/KEIKAKUSODANRoutes";
import { IDOSHIENRoutes } from "@app/FacilityTypeRoutes/IDOSHIENRoutes";

// アカウント情報
import { Account } from "@components/pages/account/Account";
import { MobileComplete } from "@components/pages/account/MobileComplete";

// 勤怠管理
import { Attendance } from "@components/pages/attendance/Attendance";

// お問い合わせ
import Contact from "@components/pages/contact/Contact";
import ContactComplete from "@components/pages/contact/Complete";

// 請求機能
import DownloadRoutes from "@app/CommonRoutes/DownloadRoutes";

// 施設切替（サポート権限専用）
import { SwitchAssociatedFacilityPage } from "@components/pages/customer-support/SwitchAssociatedFacility";

type StateProps = {
  user: UserState;
};

type Props = StateProps;

const AuthRoutes = (props: Props): JSX.Element => {
  // 比較用にstorageとは別にtokenを保持
  const [token] = React.useState(getToken());
  const facilityId = getFacilityId();
  const [openExpiredTokenModal, setOpenExpiredTokenModal] = React.useState(
    false
  );
  // storageを共有する他のタブなどでtokenが変更された時
  React.useEffect(() => {
    const monitoringChangeToken = (): void => {
      const nextToken = getToken();
      if (!nextToken || nextToken !== token) {
        setOpenExpiredTokenModal(true);
        return;
      }
      const nextFacilityId = getFacilityId();
      if (nextFacilityId !== facilityId) {
        setOpenExpiredTokenModal(true);
      }
    };
    window.addEventListener("storage", monitoringChangeToken, false);
    return (): void => {
      window.removeEventListener("storage", monitoringChangeToken);
    };
  }, []);

  return (
    <>
      <Switch>
        {/* 請求機能 */}
        {DownloadRoutes(props.user)}
        {/* アカウント情報 */}
        <AdminRoute path="/account/update" component={Account} exact />
        {/* アカウント情報 */}
        <AdminRoute
          path="/account/mobile/complete"
          component={MobileComplete}
          exact
        />
        {/* タイムカード */}
        <UserRoute path="/attendance" exact component={Attendance} />
        {/* お問い合わせ */}
        <AdminRoute path="/contact/complete" component={ContactComplete} />
        <AdminRoute path="/contact" component={Contact} />
        {/* 施設切替 */}
        <SupportRoute
          exact
          path="/customer-support/facility"
          component={SwitchAssociatedFacilityPage}
        />
        {props.user.isMobile ? (
          <>
            {/* 種別ごとのルーティング(モバイル) */}
            {props.user.facility_type === FacilityType.IDOSHIEN && (
              <IDOSHIENMobileRoutes />
            )}
            {props.user.facility_type === FacilityType.KYOTAKUKAIGO && (
              <KYOTAKUKAIGOMobileRoutes />
            )}
            {props.user.facility_type === FacilityType.JUDOHOMONKAIGO && (
              <JUDOHOMONKAIGOMobileRoutes />
            )}
            {props.user.facility_type === FacilityType.DOKOENGO && (
              <DOKOENGOMobileRoutes />
            )}
            {props.user.facility_type === FacilityType.KODOENGO && (
              <KODOENGOMobileRoutes />
            )}
          </>
        ) : (
          <>
            {/* 種別ごとのルーティング(PC) */}
            {props.user.facility_type === FacilityType.IDOSHIEN && (
              <IDOSHIENRoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.KEIKAKUSODAN && (
              <KEIKAKUSODANRoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.CHIIKITEICHAKU && (
              <CHIIKITEICHAKURoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.CHIIKIIKO && (
              <CHIIKIIKORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.KODOENGO && (
              <KODOENGORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.DOKOENGO && (
              <DOKOENGORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.JUDOHOMONKAIGO && (
              <JUDOHOMONKAIGORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.KYOTAKUKAIGO && (
              <KYOTAKUKAIGORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.SHISETSUNYUSHO && (
              <SHISETSUNYUSHORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.TANKINYUSHO && (
              <TANKINYUSHORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.SHUROTEICHAKU && (
              <SHUROTEICHAKURoutes user={props.user} />
            )}
            {props.user.facility_type ===
              FacilityType.JIRITSUKUNRENSEIKATSU && (
              <JIRITSUKUNRENSEIKATSURoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.SEIKATSUKAIGO && (
              <SEIKATSUKAIGORoutes user={props.user} />
            )}
            {props.user.facility_type === FacilityType.GROUP_HOME && (
              <GroupHomeRoutes />
            )}
            {(props.user.facility_type === FacilityType.A ||
              props.user.facility_type === FacilityType.B ||
              props.user.facility_type === FacilityType.IKOU) && (
              <IABRoutes user={props.user} />
            )}
          </>
        )}
      </Switch>
      {props.user.isMobile ? (
        <MobileExpiredTokenModal open={openExpiredTokenModal} />
      ) : (
        <ExpiredTokenModal open={openExpiredTokenModal} />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user as UserState
});

export default connect(mapStateToProps)(AuthRoutes);
