import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * 対象ユーザーの介護計画を削除する。
 * @param uifId ユーザーID
 * @param carePlanId carePlanID
 */
export const deleteSupportPlanUifIdCarePlanCarePlanId = async (
  uifId: string,
  carePlanId: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/care_plan/${carePlanId}`;
  return request.delete(url);
};
