import React from "react";

// store
import { SupportReportState as SupportReportStateType } from "@stores/domain/supportReport/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import Paper from "@material-ui/core/Paper";
import { SupportMethodDisplay } from "@components/organisms/mgr/SHUROTEICHAKU/record/SupportMethodDisplay";

// formik
import { FieldItem } from "@interfaces/ui/form";
import { FormikProps } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";

// utils
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";
import get from "lodash-es/get";
import { dateToSelectDateValue } from "@utils/date";

import { SupportReportValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportReport/initialValues";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "16px 32px 32px"
    },
    sectionTop: {
      display: "flex",
      justifyContent: "space-between"
    },
    title2: {
      fontSize: 14,
      marginBottom: 12,
      color: "rgba(0, 0, 0, 0.87)"
    },
    section: {
      marginBottom: 32
    },
    groupSection: {
      "& > :first-child": {
        marginBottom: 24
      },
      marginBottom: 53
    },
    groupSubSection: {
      paddingLeft: 16,
      marginBottom: 12
    },
    supportSection: {
      "& > :first-child": {
        marginBottom: 24
      },
      marginBottom: 32
    },
    field: {
      marginBottom: 24
    },
    field2: {
      marginBottom: 32
    },
    dateField: {
      marginBottom: 32
    },
    dateFieldTop: {
      marginBottom: 32,
      marginTop: 16
    },
    selectField: {
      width: 245
    }
  });

type OwnProps = {
  SupportReportState: SupportReportStateType;
  staffOptions: FieldItem[];
  formikProps: FormikProps<SupportReportValues>;
  isEditing: boolean;
  serviceType?: string;
  initialValues: SupportReportValues;
};
type Props = OwnProps & WithStyles<typeof styles>;

const SupportReportTableCore = (props: Props): JSX.Element => {
  const { SupportReportState, formikProps, initialValues, isEditing } = props;
  // 作成者名
  const author = SupportReportState.support.report.author
    ? SupportReportState.support.report.author
    : 0;
  const authorValue = getSnapOrRealName(author, "");

  return (
    <Paper className={props.classes.root} elevation={0}>
      <div className={props.classes.sectionTop}>
        <div className={props.classes.dateFieldTop}>
          <RecordSelectDate
            name="support_report.creation_date"
            label="レポート作成日"
            value={dateToSelectDateValue(
              get(SupportReportState.support.report, "creation_date") || ""
            )}
            isEditable={isEditing}
            addYearTo={1}
            overrideYearFrom={1989}
          />
        </div>
        <div>
          <CreateAndUpdateDate
            createdAt={SupportReportState.created_at}
            updatedAt={SupportReportState.updated_at}
          />
        </div>
      </div>
      <div className={props.classes.dateField}>
        <RecordSelectDate
          name="support_report.working_start_date"
          label="雇用開始日"
          value={dateToSelectDateValue(
            get(SupportReportState.support.report, "working_start_date") || ""
          )}
          isEditable={isEditing}
          addYearTo={1}
          overrideYearFrom={1989}
          setFormikFieldValue={formikProps.setFieldValue}
        />
      </div>
      <div className={props.classes.section}>
        <FormGroup row>
          <RecordTextField
            name="support_report.company_name"
            value={get(SupportReportState.support.report, "company_name") || ""}
            defaultValue="-"
            label="雇用事業主（勤務先）"
            labelType="default"
            placeholder=""
            isEditable={isEditing}
            style={{ width: "418px", marginRight: 16 }}
          />
          <RecordTextField
            name="support_report.responsible_person"
            value={
              get(SupportReportState.support.report, "responsible_person") || ""
            }
            defaultValue="-"
            label="ご担当者"
            labelType="default"
            placeholder=""
            isEditable={isEditing}
            style={{ width: "300px" }}
          />
        </FormGroup>
      </div>

      {formikProps.values.usage_results.length > 0 && (
        <SupportMethodDisplay
          usageResults={SupportReportState.support.usage_results}
          isEditing={isEditing}
        />
      )}

      <div className={props.classes.groupSection}>
        <GrayMiddleHeading text="当月の主な支援目標" />
        <div className={props.classes.field}>
          <RecordTextField
            name="support_report.goal_1"
            value={get(SupportReportState.support.report, "goal_1") || ""}
            defaultValue="-"
            label="①"
            labelType="default"
            placeholder=""
            isEditable={isEditing}
          />
        </div>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_report.goal_2"
            value={get(SupportReportState.support.report, "goal_2") || ""}
            defaultValue="-"
            label="②"
            labelType="default"
            placeholder=""
            isEditable={isEditing}
          />
        </div>
        <div className={props.classes.field}>
          <RecordTextField
            name="support_report.goal_3"
            value={get(SupportReportState.support.report, "goal_3") || ""}
            defaultValue="-"
            label="③"
            labelType="default"
            placeholder=""
            isEditable={isEditing}
          />
        </div>
      </div>

      <div className={props.classes.groupSection}>
        <GrayMiddleHeading text="当月の支援状況" />
        {[0, 1, 2].map((num, index) => (
          <div key={`short-${num}`}>
            <div className={props.classes.title2}>
              <>支援 {circleNumbersList[index]}</>
            </div>
            <div className={props.classes.groupSubSection}>
              <div className={props.classes.field}>
                <RecordTextField
                  name={`support_report.situations[${num}]["content"]`}
                  value={initialValues.support_report.situations[num].content}
                  defaultValue="-"
                  label="実施内容"
                  labelType="default"
                  placeholder=""
                  isEditable={isEditing}
                />
              </div>
              <div className={props.classes.field2}>
                <RecordTextField
                  name={`support_report.situations[${num}]["result"]`}
                  value={initialValues.support_report.situations[num].result}
                  defaultValue="-"
                  label="支援結果"
                  labelType="default"
                  placeholder=""
                  isEditable={isEditing}
                />
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={props.classes.supportSection}>
        <GrayMiddleHeading text="支援の方向性" />
        <div className={props.classes.title2}>今後の支援内容</div>
        <div className={props.classes.field2}>
          <RecordTextField
            name="support_report.future_support_content"
            value={
              get(
                SupportReportState.support.report,
                "future_support_content"
              ) || ""
            }
            defaultValue="-"
            label=""
            labelType="default"
            placeholder=""
            isEditable={isEditing}
          />
        </div>
        <div className={props.classes.title2}>
          対象者・事業主・関係機関等の取組
        </div>
        <div className={props.classes.groupSubSection}>
          <div className={props.classes.field}>
            <RecordTextField
              name="support_report.person_behavior"
              value={
                get(SupportReportState.support.report, "person_behavior") || ""
              }
              defaultValue="-"
              label="対象者"
              labelType="default"
              placeholder=""
              isEditable={isEditing}
              style={{ color: "#37474f" }}
            />
          </div>
          <div className={props.classes.field2}>
            <RecordTextField
              name="support_report.facility_behavior"
              value={
                get(SupportReportState.support.report, "facility_behavior") ||
                ""
              }
              defaultValue="-"
              label="事業主"
              labelType="default"
              placeholder=""
              isEditable={isEditing}
            />
          </div>
          <div className={props.classes.field2}>
            <RecordTextField
              name="support_report.related_organizations_behavior"
              value={
                get(
                  SupportReportState.support.report,
                  "related_organizations_behavior"
                ) || ""
              }
              defaultValue="-"
              label="関係機関等"
              labelType="default"
              placeholder=""
              isEditable={isEditing}
            />
          </div>
        </div>
      </div>

      <div className={props.classes.groupSection}>
        <GrayMiddleHeading text="共有事項" />
        <div className={props.classes.field}>
          <RecordTextField
            name="support_report.shared_content"
            value={
              get(SupportReportState.support.report, "shared_content") || ""
            }
            defaultValue="-"
            label=""
            labelType="default"
            placeholder=""
            isEditable={isEditing}
          />
        </div>
      </div>

      <div className={props.classes.dateField}>
        <RecordSelectDate
          name="support_report.presentation_date"
          label="対象者提示日"
          value={dateToSelectDateValue(
            get(SupportReportState.support.report, "presentation_date") || ""
          )}
          isEditable={isEditing}
          addYearTo={1}
          overrideYearFrom={1989}
          setFormikFieldValue={formikProps.setFieldValue}
        />
      </div>

      <div className={props.classes.selectField}>
        <RecordSelect
          name="support_report.author"
          value={authorValue}
          defaultValue="未設定"
          label="作成者"
          placeholder="選択してください"
          options={props.staffOptions}
          isEditable={isEditing}
          isSelectablePlaceholder
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
        />
      </div>
    </Paper>
  );
};

export const SupportReportTable = withStyles(styles)(SupportReportTableCore);
