import getInOut from "./getInOut";
import getInOutUser from "./getInOutUser";
import getInOutSummary from "./getInOutSummary";
import getInOutUserSummary from "./getInOutUserSummary";
import putInOutRecords from "./putInOutRecords";
import postInOutAllRecord from "./postInOutAllRecord";

export default {
  getInOut,
  getInOutUser,
  getInOutSummary,
  getInOutUserSummary,
  putInOutRecords,
  postInOutAllRecord
};
