import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

const styles = (): StyleRules =>
  createStyles({
    wrap: {
      borderRadius: 4,
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      display: "flex",
      justifyContent: "space-between"
    },
    summaryWrap: {
      display: "flex",
      flexWrap: "wrap",
      fontSize: 16,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    }
  });

type OwnProps = {
  status_type?: {
    implement: number;
  };
};
type Props = OwnProps & WithStyles<typeof styles>;

const OfferServiceSummaryCore = (props: Props): JSX.Element => {
  const { implement } = props.status_type || {
    implement: 0
  };
  return (
    <div className={props.classes.wrap}>
      <div className={props.classes.summaryWrap}>
        <div
          className={props.classes.summaryContent}
        >{`サービス提供実施：${implement} 人`}</div>
      </div>
    </div>
  );
};

export const OfferServiceSummary = withStyles(styles)(OfferServiceSummaryCore);
