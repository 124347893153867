/**
 * basic.operatingUnitFlag(基本情報 > 建物管理する)が有効な時表示
 * ユニット名の追加・削除を行う
 */

import * as React from "react";
import { WithStyles, withStyles, createStyles } from "@material-ui/core";
import { FormikProps, FieldArray } from "formik";
import {
  FacilityValues as FacilityValuesKYOTAKUKAIGO,
  unitInitialValue
} from "@initialize/v202104/mgr/KYOTAKUKAIGO/facility/initialValues";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import deepEqual from "fast-deep-equal";
import { StyleRules } from "@material-ui/core/styles";
import { BuildingManagementTable } from "@components/v202104/organisms/mgr/KYOTAKUKAIGO/facility/items/BuildingManagementTable";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginLeft: -16
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValuesKYOTAKUKAIGO>;
  unitLabel?: string;
  children?: React.ReactNode;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UnitsFieldsCore = (props: Props): JSX.Element => {
  const unitLabel: string = props.unitLabel ? props.unitLabel : "ユニット";
  return (
    <FieldArray name="units">
      {(arrayHelpers): JSX.Element => {
        const onClickAddUnit = (): void => {
          arrayHelpers.push(unitInitialValue);
        };
        return (
          <div className={props.classes.wrapper}>
            <div>
              <BuildingManagementTable formikProps={props.formikProps} />
              <Button color="secondary" onClick={onClickAddUnit}>
                <IconAdd />
                {unitLabel}を追加
              </Button>
            </div>
          </div>
        );
      }}
    </FieldArray>
  );
};

export const UnitsFields = React.memo(
  withStyles(styles)(UnitsFieldsCore),
  (prev, next) =>
    deepEqual(prev.formikProps.values.units, next.formikProps.values.units)
);
