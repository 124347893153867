import request from "@api/index";
import { BaseResponse } from "@api/types";
import { VERSION_URL_201910 } from "@config";
import { AxiosResponse } from "axios";

type staffs = {
  id: number;
  name: string;
  facility_id: number;
  snapshot_name: string;
}[];

export type GetOperationsAndSupports = BaseResponse<{
  created_at: string | null;
  updated_at: string | null;
  target_date: string;
  page: number;
  support_count: number;
  keyword: string;
  is_record: number;
  operation_records: {
    operation_records_id: number;
    operation_in_the_morning: string | null;
    operation_in_the_afternoon: string | null;
    other_comment: string | null;
    staffs: staffs;
  } | null;
  support_records: {
    support_records_id: number | null;
    users_in_facility_id: number;
    status: number | null;
    support_content: string | null;
    staff_comment: string | null;
    staffs: staffs;
    type_consultation: number;
  }[];
}>;

/**
 * 指定日の支援記録を取得する（ページネーションあり）
 * @param yyyymmdd 日付
 * @param page 取得するページ数（未指定時は全件取得）
 * @param keyword 絞り込み条件: あかさたな (未指定時は全件取得)
 * @param isRecord 絞り込み条件: 未記録/記録済 (未指定時は全件取得)
 */
export const getOperationsAndSupports = async (
  yyyymmdd: string,
  page?: number,
  keyword?: string,
  isRecord?: number
): Promise<AxiosResponse<GetOperationsAndSupports>> => {
  const url = `${VERSION_URL_201910}/records/${yyyymmdd}/pagination`;
  let query = "";
  if (page) {
    query += `&page=${page}`;
  }

  if (keyword) {
    query += `&keyword=${keyword}`;
  }

  if (isRecord) {
    query += `&is_record=${isRecord}`;
  }

  query = query && `?${query.slice(1)}`;

  return request.get<GetOperationsAndSupports>(url + query);
};
