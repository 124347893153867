import React from "react";

// UI
import { StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import {
  Done as DoneIcon,
  DeleteOutline as DeleteIcon,
  Edit as EditIcon,
  LibraryAddOutlined as CopyIcon
} from "@material-ui/icons";
import TableCellWrap from "@components/atoms/TableCellWrap";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { TimeCell } from "@components/organisms/mgr/KODOENGO/record/TimeCell";

// store
import {
  SelectedSupportProcedureIds,
  SupportProcedureState
} from "@stores/domain/mgr/KODOENGO/supportProcedure/types";

const styles = (): StyleRules =>
  createStyles({
    dateCell: {
      minWidth: 136,
      width: 136,
      backgroundColor: "#fff",
      padding: 0,
      paddingLeft: 16
    },
    createdCell: {
      minWidth: 52,
      width: 52,
      boxSizing: "content-box",
      padding: 0
    },
    createdIcon: {
      color: "#2db45a",
      height: "18px",
      width: "18px"
    },
    numberOfPractitionerCell: {
      minWidth: 56,
      width: 56,
      boxSizing: "content-box",
      padding: 0
    },
    timeCell: {
      minWidth: 164,
      width: 164,
      boxSizing: "content-box",
      padding: 0
    },
    titleCell: {
      minWidth: 398,
      width: 398,
      boxSizing: "content-box",
      padding: 0
    },
    unPlannedIconCell: {
      minWidth: 64,
      width: 64,
      boxSizing: "content-box",
      padding: 0,
      textAlign: "center"
    },
    unPlannedIcon: {
      height: "24px",
      width: "64px",
      backgroundColor: "#757575",
      borderRadius: "12px",
      color: "#fff",
      textAlign: "center",
      fontSize: "12px"
    },
    iconButtonCell: {
      minWidth: 48,
      width: 48,
      boxSizing: "content-box",
      padding: 0,
      "&:last-child": {
        paddingRight: 0
      },
      textAlign: "center"
    },
    iconButtonBase: {
      color: "#0277bd",
      cursor: "pointer",
      "&:hover": {
        color: "rgb(1, 83, 132)",
        backgroundColor: "transparent"
      }
    }
  });

type OwnProps = {
  index: number;
  subIndex: number;
  rowSpan: number;
  handleOnClickDelete: (target: string) => void;
  rowData: SupportProcedureState["supportProcedureList"]["support_procedure_forms"][0];
  handleOnClickEdit: (ids: SelectedSupportProcedureIds) => void;
  handleOnClickCopy: (ids: SelectedSupportProcedureIds) => void;
  handleOnClickDateSetting: (
    ids: SelectedSupportProcedureIds,
    originId: string
  ) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureTableRowsCore = (props: Props): JSX.Element => {
  const {
    index,
    subIndex,
    rowSpan,
    rowData,
    handleOnClickDelete,
    handleOnClickEdit,
    handleOnClickCopy,
    handleOnClickDateSetting,
    classes
  } = props;

  const tableRowIndex = `${index}-${subIndex}`;
  const isCreated = rowData.support_procedure_forms_id !== null;

  const onClickCopy = (ids: SelectedSupportProcedureIds): void => {
    handleOnClickCopy(ids);
  };

  const onClickDeleteIcon = (): void => {
    handleOnClickDelete(`${rowData.support_procedure_forms_id}`);
  };

  const onClickEdit = (ids: SelectedSupportProcedureIds): void => {
    handleOnClickEdit(ids);
  };

  const onClickDateSetting = (
    ids: SelectedSupportProcedureIds,
    originId: number | null
  ): void => {
    // 日付未設定の手順書のみなので手順書が存在している前提
    if (originId) {
      handleOnClickDateSetting(ids, `${originId}`);
    }
  };

  return (
    <>
      {/* 日付(存在する場合) */}
      {rowData.target_date !== null && subIndex === 0 && (
        <TableCellWrap
          key={`${tableRowIndex}-date`}
          cellClass={classes.dateCell}
          rowSpan={rowSpan}
        >
          <CustomDateLabel
            holiday={rowData.is_holiday}
            date={rowData.target_date}
            dateFormat="Do（dd）"
          />
        </TableCellWrap>
      )}
      {/* 日付(存在しない場合) */}
      {rowData.target_date === null && (
        <TableCellWrap
          key={`${tableRowIndex}-set-date-button`}
          cellClass={classes.dateCell}
        >
          <KnowbeButton
            minWidth={88}
            kind="outline"
            onClick={(): void =>
              onClickDateSetting(
                {
                  serviceDeliveryRecordsId: rowData.service_delivery_records_id,
                  inoutResultsId: rowData.inout_results_id,
                  supportProcedureFormsId: rowData.support_procedure_forms_id,
                  targetDate: rowData.target_date
                },
                rowData.support_procedure_forms_id
              )
            }
          >
            日時設定
          </KnowbeButton>
        </TableCellWrap>
      )}
      {/* 作成済み */}
      <TableCellWrap
        key={`${tableRowIndex}-created-flg`}
        cellClass={`${classes.createdCell}`}
      >
        {rowData.procedure_status_flg !== 0 ? (
          <DoneIcon className={classes.createdIcon} />
        ) : null}
      </TableCellWrap>
      {/* 人数 */}
      <TableCellWrap
        key={`${tableRowIndex}-practitioner-number`}
        cellClass={`${classes.numberOfPractitionerCell}`}
      >
        {rowData.number_of_practitioner ? rowData.number_of_practitioner : "-"}
      </TableCellWrap>
      {/* 時間 */}
      <TableCellWrap
        key={`${tableRowIndex}-time`}
        cellClass={`${classes.timeCell}`}
      >
        <TimeCell rowData={rowData} />
      </TableCellWrap>
      {/* タイトル */}
      <TableCellWrap
        key={`${tableRowIndex}-title`}
        cellClass={`${classes.titleCell}`}
      >
        {rowData.title ? rowData.title : "-"}
      </TableCellWrap>
      {/* 計画外 */}
      <TableCellWrap
        key={`${tableRowIndex}-status`}
        cellClass={`${classes.unPlannedIconCell}`}
      >
        {rowData.unplanned_flg ? (
          <div className={classes.unPlannedIcon}>計画外</div>
        ) : null}
      </TableCellWrap>
      {/* 削除 */}
      <TableCellWrap
        key={`${tableRowIndex}-delete-button`}
        cellClass={`${classes.iconButtonCell}`}
      >
        {isCreated ? (
          <DeleteIcon
            className={classes.iconButtonBase}
            onClick={onClickDeleteIcon}
          />
        ) : null}
      </TableCellWrap>
      {/* コピー */}
      <TableCellWrap
        key={`${tableRowIndex}-copy-button`}
        cellClass={`${classes.iconButtonCell}`}
      >
        {isCreated && (
          <CopyIcon
            className={classes.iconButtonBase}
            onClick={(): void => {
              onClickCopy({
                serviceDeliveryRecordsId: rowData.service_delivery_records_id,
                inoutResultsId: rowData.inout_results_id,
                supportProcedureFormsId: rowData.support_procedure_forms_id
              });
            }}
          />
        )}
      </TableCellWrap>
      {/* 編集 */}
      <TableCellWrap
        key={`${tableRowIndex}-edit-button`}
        cellClass={`${classes.iconButtonCell}`}
      >
        {isCreated ? (
          <EditIcon
            className={classes.iconButtonBase}
            onClick={(): void =>
              onClickEdit({
                serviceDeliveryRecordsId: rowData.service_delivery_records_id,
                inoutResultsId: rowData.inout_results_id,
                supportProcedureFormsId: rowData.support_procedure_forms_id,
                targetDate: rowData.target_date
              })
            }
          />
        ) : null}
      </TableCellWrap>
    </>
  );
};

export const SupportProcedureTableRows = withStyles(styles)(
  SupportProcedureTableRowsCore
);
