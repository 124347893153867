import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  setting_type: number;
  support_ledgers_id: number | null;
  creation_date?: string | null;
  creation_staff?: number | null;
  update_date?: string | null;
  update_staff?: number | null;
  date_begin_in_support: string | null;
  date_end_in_support: string | null;
  is_ignore_print_support_ids?: number[];
  custom_record: {
    custom_records_category_id: number;
    custom_record_item_id: number;
    custom_record_input_id: number | null;
    input_data?: string;
    choiced_item_id?: number | null;
    checked?: number;
    choiced_staff_id?: number;
    choiced_staff_name_snapshot?: string;
    life_histories?: RequestHistory[];
    disability_histories?: RequestHistory[];
    relatives_info?: RequestRelativesInfo[];
    related_organization?: RequestRelatedOrganization[];
  }[];
};

export type RequestHistory = {
  id: number | null;
  yyyymm?: string | null;
  matter?: string | null;
  is_delete?: number | null;
};

export type RequestRelativesInfo = {
  id: number | null;
  relationship?: string | null;
  name?: string | null;
  age?: string | null;
  profession?: string | null;
  address?: string | null;
  contact_address?: string | null;
  remarks?: string | null;
  is_delete?: number | null;
};

export type RequestRelatedOrganization = {
  id: number | null;
  target_type?: number | null;
  facility_name?: string | null;
  kinds?: string | null;
  manager?: string | null;
  contact_address?: string | null;
  remarks?: string | null;
  is_delete?: number | null;
};

/**
 * 地域定着台帳のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
const postSupportLedger = async (
  uifId: string,
  param: RequestParam
): Promise<AxiosResponse<RequestParam>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/support_ledger`;
  return request.post(url, param);
};

export default postSupportLedger;
