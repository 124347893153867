import { filterExpiredUser } from "@utils/domain/record/filterExpiredUser";

export type TargetUser = {
  uif_id: number;
  date_end_in_service?: string | null;
};

/**
 * ユーザーの絞り込みを行う（退所者フィルタリング）
 * @param usersValue ユーザーリスト
 * @param expiredVisibilityFlg 退所者の非表示チェックフラグ
 * @returns 絞り込んだユーザーリスト
 */
export const filterActiveUsers = <T extends TargetUser>(
  usersValue: T[],
  expiredVisibilityFlg: boolean
): T[] => {
  let filteredUsers = usersValue;

  if (!expiredVisibilityFlg) return filteredUsers;

  // 退所者フィルタリング
  const expiredUserIds = filterExpiredUser<T>(filteredUsers);
  if (expiredUserIds) {
    filteredUsers = filteredUsers.filter(
      (item) => !expiredUserIds.includes(`${item.uif_id}`)
    );
  }

  return filteredUsers;
};
