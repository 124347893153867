import * as React from "react";

import { DownloadAction } from "@components/organisms/download/DownloadInvoiceSection";
import { DOWNLOAD_FILE_TYPE } from "@constants/mgr/KEIKAKUSODAN/variables";
import { Button } from "@material-ui/core";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import classNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    button: {
      margin: "16px 8px 0",
      boxShadow: "none",
      borderRadius: 4,
      width: 290,
      "&:first-of-type": {
        marginLeft: 0
      }
    },
    upLimitButton: {
      width: 350
    }
  });

type OwnProps = {
  action: DownloadAction;
  isDisplay: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadButtonCore = (props: Props): JSX.Element => {
  const { classes, action, isDisplay } = props;
  const { label, onClick, isDisabled } = action;

  return (
    <Button
      className={classNames(
        classes.button,
        label.includes(DOWNLOAD_FILE_TYPE.upLimit) && classes.upLimitButton
      )}
      color="secondary"
      onClick={onClick}
      variant="contained"
      disabled={isDisabled}
      style={{ display: isDisplay ? "block" : "none" }}
    >
      {label}
    </Button>
  );
};

export const DownloadButton = withStyles(styles)(DownloadButtonCore);
