import * as types from "./types";
import * as actions from "./actions";

const initialState: types.UsersSummaryState = {
  users: []
};

const reducer = (
  state = initialState,
  action: actions.ActionTypes
): types.UsersSummaryState => {
  switch (action.type) {
    case types.FETCH_USERS_SUMMARY_TANKINYUSHO_STARTED:
      return { ...state };
    case types.FETCH_USERS_SUMMARY_TANKINYUSHO_SUCCESS:
      return { ...action.payload };
    case types.FETCH_USERS_SUMMARY_TANKINYUSHO_FAILED:
      return { ...state };
    case types.UNSET_USERS_SUMMARY_TANKINYUSHO:
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export default reducer;
