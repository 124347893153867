import * as React from "react";
import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { FormikProps, getIn } from "formik";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikAddress from "@components/molecules/FormikAddress";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import MuiTextField from "@components/molecules/MuiTextField";
import { UpperLimitTable } from "@components/organisms/mgr/common/Users/items/UpperLimitTable";

import {
  DISABILITY_CLASS_LIST,
  INCOME_KIND_LIST,
  INCOME_KIND_TYPE_LIST,
  SUBSIDIZED_UNIT_LIST,
  UPLIMIT_CONT_ROLLED_BY_LIST
} from "@constants/variables";
import {
  NEED_NURSING_CARE_CLASS_LIST,
  SUPPORT_REQUIRED_CLASS_LIST,
  RESULT_OF_MANAGEMENT_LIST,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_LIST
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { UsersValues } from "@initialize/mgr/KEIKAKUSODAN/users/initialValues";

const styles = (): StyleRules =>
  createStyles({
    groupDate: {
      margin: "6px 0 0 16px"
    },
    fieldWrapper: {
      position: "relative"
    },
    incomeKindType: {
      position: "absolute",
      top: 22,
      left: 140
    },
    date: {
      marginBottom: 12
    },
    tipsServiceStart: {
      marginLeft: 120,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    },
    tipsPayStart: {
      marginLeft: 100,
      marginTop: -4,
      "&+div > div > div": {
        marginTop: -6
      }
    }
  });

type OwnProps = {
  targetType:
    | typeof FACILITY_TYPE_KEIKAKUSODAN
    | typeof FACILITY_TYPE_SHOGAIJISODAN;
  facilityType: number | null;
  formikProps: FormikProps<UsersValues>;
  formikFieldNamePrefix: string;
  isFetchDone?: boolean;
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void;
};
type Props = OwnProps & WithStyles<typeof styles>;

const ServiceUseFieldsCore = (props: Props): JSX.Element => {
  const [shouldFirstSetup, setShouldFirstSetup] = React.useState<boolean>(true);
  const [showIncomeKindType, setShowIncomeKindType] = React.useState<boolean>(
    true
  );
  const [showSubsidizedPercent, setShowSubsidizedPercent] = React.useState<
    boolean
  >(true);
  const [showOfficeInfoTable, setShowOfficeInfoTable] = React.useState<boolean>(
    true
  );
  const [showUpperLimitYen, setShowUpperLimitYen] = React.useState<boolean>(
    false
  );
  const [
    disabledNeedNursingCareClass,
    setDisabledNeedNursingCareClass
  ] = React.useState<boolean>(false);
  const [
    disabledSupportRequiredClass,
    setDisabledSupportRequiredClass
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!shouldFirstSetup || !props.isFetchDone) return;

    const { serviceUse } = props.formikProps.values;
    const target = serviceUse.find((v) => v.targetType === props.targetType);
    if (target) {
      setShouldFirstSetup(false);
      setShowIncomeKindType(target.incomeKind === "1");
      setShowSubsidizedPercent(target.subsidizedUnit === "1");
      setShowOfficeInfoTable(target.upperLimitControlledBy === "1");
      setShowUpperLimitYen(target.resultOfManagement === "3");
      setDisabledNeedNursingCareClass(target.supportRequiredClass !== "0");
      setDisabledSupportRequiredClass(target.needNursingCareClass !== "0");
    }
  }, [props]);

  /**
   * 負担上限額が0の時、所得区分を表示
   */
  const onChangeIncomeKindHook = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setShowIncomeKindType(value === "1");
  };

  /**
   * 自治体助成金対象: 助成金 <=> 金額：円
   */
  const onChangeSubsidizedUnitHook = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    setShowSubsidizedPercent(value === "1");
  };

  const resetUpperLimitFacilityYenForm = (): void => {
    for (let i = 0; i < 4; i += 1) {
      const dataNumber = i !== 0 ? String(i + 1) : "";
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitFacilityNumber${dataNumber}`,
        ""
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitFacilityName${dataNumber}`,
        ""
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitTotalYen${dataNumber}`,
        ""
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitUserLoadYen${dataNumber}`,
        ""
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.uplimitUserLoadAfterAdjustmentYen${dataNumber}`,
        ""
      );
    }
  };

  /**
   * 上限管理事業所あり：ONOFF切り替え時に値をリセット
   */
  const onChangeUpperLimitFacilityFlag = (): void => {
    if (
      getIn(
        props.formikProps.values,
        `${props.formikFieldNamePrefix}.upperLimitFacilityFlag`
      )
    ) {
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitFacilityFlag`,
        false
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitControlledBy`,
        "1"
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitYen`,
        ""
      );
      setShowUpperLimitYen(false);
      setShowOfficeInfoTable(true);
      resetUpperLimitFacilityYenForm();
    } else {
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.upperLimitFacilityFlag`,
        true
      );
      props.setFormikFieldValue(
        `${props.formikFieldNamePrefix}.resultOfManagement`,
        "2"
      );
    }
  };

  /**
   * 管理事業所が1の時、総費用額と利用者負担額を表示
   */
  const onChangeUpperLimitControlledBy = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const { value } = e.target;
    setShowOfficeInfoTable(value === "1");
    resetUpperLimitFacilityYenForm();
  };

  /**
   * 管理結果が3の時、自事業所調整上限額を表示
   */
  const onChangeResultOfManagement = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.target;
    setShowUpperLimitYen(value === "3");
    if (value !== "3")
      ["", "2", "3", "4"].forEach((item) => {
        props.setFormikFieldValue(
          `${props.formikFieldNamePrefix}.uplimitUserLoadAfterAdjustmentYen${item}`,
          ""
        );
      });
  };

  /**
   * 要支援区分が0以外の時、要介護区分を非活性
   */
  const onChangeSupportRequiredClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDisabledNeedNursingCareClass(e.target.value !== "0");
  };

  /**
   * 要介護区分が0以外の時、要支援区分を非活性
   */
  const onChangeNeedNursingCareClass = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setDisabledSupportRequiredClass(e.target.value !== "0");
  };

  const startAddYearTo = 1;
  const endAddYearToOne = 1;
  const endAddYearToFive = 5;

  const tipsDisabilityClass = (
    <HelpToolTip title={<HelpTipMessages name="disabilityClass" />} />
  );
  const tipsNeedNursingCareClass = (
    <HelpToolTip title={<HelpTipMessages name="needNursingCareClass" />} />
  );
  const tipsSupportRequiredClass = (
    <HelpToolTip title={<HelpTipMessages name="supportRequiredClass" />} />
  );

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle
          label={`サービス利用詳細（${FACILITY_TYPE_LIST[props.targetType]}）`}
        />
      </div>
      <Typography className={props.classes.date}>サービス提供期間</Typography>
      <div className={props.classes.groupDate}>
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.inServiceStartDate`}
          label="サービス提供開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          additionalYearOptions={[{ label: "9999年", value: "9999" }]}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsServiceStart}>
              <HelpToolTip
                title={<HelpTipMessages name="inServiceStartDateOfVisit" />}
              />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.inServiceEndDate`}
          label="サービス提供終了日"
          addYearTo={endAddYearToFive}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <Typography className={props.classes.date}>
        {props.targetType === FACILITY_TYPE_KEIKAKUSODAN
          ? "計画相談支援給付費の支給期間"
          : "障害児相談支援給付費の支給期間"}
      </Typography>
      <div className={props.classes.groupDate}>
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.payStartDate`}
          label="支給決定開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
          tooltip={
            <div className={props.classes.tipsPayStart}>
              <HelpToolTip title={<HelpTipMessages name="pay_start_date" />} />
            </div>
          }
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.payEndDate`}
          label="支給決定終了日"
          addYearTo={endAddYearToFive}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </div>
      <FormGroup>
        <div className={props.classes.fieldWrapper}>
          <FormikRadioButtons
            name={`${props.formikFieldNamePrefix}.disabilityClass`}
            label="障害支援区分"
            options={DISABILITY_CLASS_LIST}
            tooltip={tipsDisabilityClass}
            disabled={props.formikProps.values.basic.classifyHandicappedFlag}
          />
        </div>
        {props.targetType === FACILITY_TYPE_KEIKAKUSODAN && (
          <>
            <div className={props.classes.fieldWrapper}>
              <FormikRadioButtons
                name={`${props.formikFieldNamePrefix}.needNursingCareClass`}
                label="要介護状態区分"
                options={NEED_NURSING_CARE_CLASS_LIST}
                tooltip={tipsNeedNursingCareClass}
                onChangeHook={onChangeNeedNursingCareClass}
                disabled={
                  props.formikProps.values.basic.classifyHandicappedFlag ||
                  disabledNeedNursingCareClass
                }
              />
            </div>
            <div className={props.classes.fieldWrapper}>
              <FormikRadioButtons
                name={`${props.formikFieldNamePrefix}.supportRequiredClass`}
                label="要支援状態区分"
                options={SUPPORT_REQUIRED_CLASS_LIST}
                tooltip={tipsSupportRequiredClass}
                onChangeHook={onChangeSupportRequiredClass}
                disabled={
                  props.formikProps.values.basic.classifyHandicappedFlag ||
                  disabledSupportRequiredClass
                }
              />
            </div>
          </>
        )}
        <div className={props.classes.fieldWrapper}>
          <FormikRadioButtons
            name={`${props.formikFieldNamePrefix}.incomeKind`}
            label="負担上限額"
            options={INCOME_KIND_LIST}
            onChangeHook={onChangeIncomeKindHook}
          />
          <div className={props.classes.incomeKindType}>
            {showIncomeKindType && (
              <FormikSelect
                name={`${props.formikFieldNamePrefix}.incomeClassification`}
                label="所得区分"
                options={INCOME_KIND_TYPE_LIST}
              />
            )}
          </div>
        </div>
      </FormGroup>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.subsidizedFlag`}
        label="自治体助成金対象"
        tooltip={
          <HelpToolTip title={<HelpTipMessages name="subsidized_flg" />} />
        }
      >
        <FormGroup row>
          {showSubsidizedPercent && (
            <FormikTextField
              name={`${props.formikFieldNamePrefix}.subsidizedPercent`}
              label="助成金"
              maxLength={9}
              placeholder="0"
            />
          )}
          {!showSubsidizedPercent && (
            <FormikTextField
              name={`${props.formikFieldNamePrefix}.subsidizedYen`}
              label="金額：円"
              maxLength={11}
              placeholder="0"
            />
          )}
          <FormikSelect
            name={`${props.formikFieldNamePrefix}.subsidizedUnit`}
            label="単位"
            options={SUBSIDIZED_UNIT_LIST}
            onChangeHook={onChangeSubsidizedUnitHook}
          />
        </FormGroup>
        <FormikAddress
          prefectureIdName="basic.prefectureId"
          cityIdName={`${props.formikFieldNamePrefix}.subsidizedCityId`}
          formikProps={props.formikProps}
          disabledPrefectureId
          showRegionType={false}
        />
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.upperLimitFacilityFlag`}
        label="上限管理事業所あり"
        onChange={onChangeUpperLimitFacilityFlag}
      >
        <FormikSelect
          name={`${props.formikFieldNamePrefix}.upperLimitControlledBy`}
          label="管理事業所"
          options={UPLIMIT_CONT_ROLLED_BY_LIST}
          onChangeHook={onChangeUpperLimitControlledBy}
        />
        {!showOfficeInfoTable && (
          <FormGroup row>
            <FormikTextField
              name={`${props.formikFieldNamePrefix}.upperLimitFacilityNumber`}
              label="事業所番号"
              maxLength={10}
              placeholder="0000000000"
            />
            <FormikTextField
              name={`${props.formikFieldNamePrefix}.upperLimitFacilityName`}
              label="事業所名"
              maxLength={2500}
            />
          </FormGroup>
        )}
        <FormikRadioButtons
          name={`${props.formikFieldNamePrefix}.resultOfManagement`}
          label="管理結果"
          options={RESULT_OF_MANAGEMENT_LIST}
          onChangeHook={onChangeResultOfManagement}
          style={{ marginBottom: 0 }}
        />
        {showUpperLimitYen && (
          <MuiTextField
            name={`${props.formikFieldNamePrefix}.upperLimitYen`}
            label="自事業所　管理結果後利用者負担額"
            value="0"
            endAdornmentLabel="円"
            style={{
              margin: showOfficeInfoTable
                ? "16px 0px 16px 52px"
                : "16px 0px 32px 52px"
            }}
            maxLength={11}
            InputLabelProps={{
              style: {
                width: "120%",
                transform: "scale(0.75) translate(0, 1.5px)"
              }
            }}
            disabled
            disabledStyle
          />
        )}
        {showOfficeInfoTable && (
          <UpperLimitTable
            formikProps={props.formikProps}
            formikFieldNamePrefix={props.formikFieldNamePrefix}
          />
        )}
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.specialAreaFlag`}
        label="特別地域加算"
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.specialAreaStartDate`}
          label="適用開始日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.specialAreaEndDate`}
          label="適用終了日"
          style={{ marginBottom: 0 }}
          addYearTo={endAddYearToFive}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.disabledWelfareServiceFlag`}
        label="障害福祉サービス等の利用期間"
        tooltip={
          <HelpToolTip
            title={
              <HelpTipMessages name="keikakusodanDisabledWelfareService" />
            }
          />
        }
      >
        <FormikSelectYearMonth
          name={`${props.formikFieldNamePrefix}.disabledWelfareServiceFrom`}
          label="開始年月"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
        />
        <FormikSelectYearMonth
          name={`${props.formikFieldNamePrefix}.disabledWelfareServiceTo`}
          label="終了年月"
          style={{ marginBottom: 0 }}
          addYearTo={endAddYearToOne}
        />
      </FormikSwitch>
      {props.facilityType === FACILITY_TYPE_KEIKAKUSODAN && (
        <FormikSwitch
          name={`${props.formikFieldNamePrefix}.handicapppedChildConsultationSupportFlag`}
          label="障害児相談支援の利用期間"
          disabled={!props.formikProps.values.basic.classifyHandicappedFlag}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="keikakusodanHandicapppedChildConsultationSupport" />
              }
            />
          }
        >
          <FormikSelectYearMonth
            name={`${props.formikFieldNamePrefix}.handicapppedChildConsultationSupportFrom`}
            label="開始年月"
            style={{ marginBottom: 12 }}
            addYearTo={startAddYearTo}
          />
          <FormikSelectYearMonth
            name={`${props.formikFieldNamePrefix}.handicapppedChildConsultationSupportTo`}
            label="終了年月"
            style={{ marginBottom: 0 }}
            addYearTo={endAddYearToOne}
          />
        </FormikSwitch>
      )}
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.beforeCreatePlanFlag`}
        label="knowbe利用開始前の計画作成日"
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.dateBeforeCreatePlan`}
          label="作成日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
      <FormikSwitch
        name={`${props.formikFieldNamePrefix}.beforeGoneMonitoringFlag`}
        label="knowbe利用開始前のモニタリング実施日"
      >
        <FormikSelectDateNotSelectedDefault
          name={`${props.formikFieldNamePrefix}.dateBeforeGoneMonitoring`}
          label="実施日"
          style={{ marginBottom: 12 }}
          addYearTo={startAddYearTo}
          setFormikFieldValue={props.setFormikFieldValue}
        />
      </FormikSwitch>
    </FormPaper>
  );
};

export const ServiceUseFields = withStyles(styles)(ServiceUseFieldsCore);
