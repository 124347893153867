import { InoutConsultationResultsState } from "@stores/v202104/domain/mgr/CHIIKIIKO/report/types";
import { InitialValues } from "@interfaces/v202104/mgr/CHIIKIIKO/report/initial";
import {
  CHIIKIIKO_STATUS_LIST,
  CHIIKIIKO_TRIAL_USE_TYPE_LIST,
  CHIIKIIKO_TRIAL_STAY_TYPE_LIST
} from "@constants/mgr/CHIIKIIKO/variables";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import { dateToSelectDateValue } from "@utils/date";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";

export type InitialDataValues = InitialValues;

// デフォルト値設定
export const initialValues = (
  state?: InoutConsultationResultsState,
  date = ""
): InitialDataValues => {
  const targetDateState = state && state.targetDate ? state.targetDate : date;
  const nameSeiState = state && state.nameSei ? state.nameSei : "";
  const nameMeiState = state && state.nameMei ? state.nameMei : "";

  return {
    initial: {
      id: state && state.id ? state.id : null,
      inoutResultDailyId: state ? state.inoutResultDailyId : null,
      usersInFacilityId:
        state && state.usersInFacilityId
          ? state.usersInFacilityId.toString()
          : null,
      nameSei: nameSeiState,
      nameMei: nameMeiState,
      name: `${nameSeiState} ${nameMeiState}`,
      targetDate: targetDateState,
      status:
        state && state.status
          ? state.status.toString()
          : CHIIKIIKO_STATUS_LIST.NONE.value,
      trialUseType:
        state && state.trialUseType
          ? state.trialUseType.toString()
          : CHIIKIIKO_TRIAL_USE_TYPE_LIST.NONE.value,
      trialStayType:
        state && state.trialStayType
          ? state.trialStayType.toString()
          : CHIIKIIKO_TRIAL_STAY_TYPE_LIST.NONE.value,
      firstAdditionFlg:
        state && state.firstAdditionFlg ? state.firstAdditionFlg : false,
      housingSupportCooperationFlg:
        state && state.housingSupportCooperationFlg
          ? state.housingSupportCooperationFlg
          : false,
      housingSupportPromotionFlg:
        state && state.housingSupportPromotionFlg
          ? state.housingSupportPromotionFlg
          : false,
      dischargeMonthAdditionFlg:
        state && state.dischargeMonthAdditionFlg
          ? state.dischargeMonthAdditionFlg
          : false,
      dateBeginHospital:
        state && state.dateBeginHospital
          ? emptyToNotSelected(
              dateToSelectDateValue(
                undefinedToReturnValue(state.dateBeginHospital)
              )
            )
          : emptyToNotSelected(dateToSelectDateValue("")),
      dateEndHospital:
        state && state.dateEndHospital
          ? emptyToNotSelected(
              dateToSelectDateValue(
                undefinedToReturnValue(state.dateEndHospital)
              )
            )
          : emptyToNotSelected(dateToSelectDateValue("")),
      shortTimeForPsychiatricHospitalFlg:
        state && state.shortTimeForPsychiatricHospitalFlg
          ? state.shortTimeForPsychiatricHospitalFlg
          : false,
      memoStatus: state && state.memoStatus ? state.memoStatus : "",
      memoTrial: state && state.memoTrial ? state.memoTrial : "",
      memo: state && state.memo ? state.memo : ""
    }
  };
};
