import { ShotMultipleSelectValue } from "@interfaces/ui/form";
import { SupportRecordsState } from "@stores/domain/mgr/Cseg/support/types";
import { convertDateToYYYYHyphenMM } from "@utils/date/convertDateToYYYYHyphenMM";

export type SupportRecordFormValues = SupportRecordsState[number] & {
  displayStaffIds: ShotMultipleSelectValue;
};

const recordInitialValues: SupportRecordFormValues = {
  id: null,
  target_date: "",
  status: 0,
  type_consultation: undefined,
  support_content: "",
  staff_comment: "",
  staffs: null,
  displayStaffIds: []
};

export const initialValues = (
  supportRecordState: SupportRecordsState,
  startDate: Date,
  endDate: Date,
  targetDate: Date
): SupportRecordFormValues[] => {
  const dateRange = Array.from(
    { length: endDate.getDate() - startDate.getDate() + 1 },
    (_, index) => startDate.getDate() + index
  );
  const YYYYMM = convertDateToYYYYHyphenMM(targetDate);
  const res = dateRange.map((day) => {
    const dateString = `${YYYYMM}-${day.toString().padStart(2, "0")}`;
    const supportRecord = supportRecordState.find(
      (v) => v.target_date === dateString
    );
    const staffs = supportRecord ? supportRecord.staffs : null;
    const displayStaffIds = staffs
      ? staffs.map((v) => {
          return {
            category_id: 0,
            id: v.id,
            label: v.snapshot_name || v.name,
            is_delete: null
          };
        })
      : [];

    return supportRecord
      ? {
          ...supportRecord,
          support_content: supportRecord.support_content || "",
          staff_comment: supportRecord.staff_comment || "",
          displayStaffIds
        }
      : { ...recordInitialValues, target_date: dateString };
  });

  return res;
};
