import * as types from "./types";
import * as actions from "./actions";
import orderBy from "lodash-es/orderBy";

const initialState: types.StaffState = {
  staffItems: []
};

export const JUDOHOMONKAIGOStaff = (
  state = initialState,
  action: actions.ActionTypes
): types.StaffState => {
  switch (action.type) {
    case types.FETCH_STARTED:
      return { ...state };
    case types.FETCH_SUCCESS: {
      const staffItems: types.StaffData[] = [];
      action.payload.data.forEach((record) => {
        staffItems.push({
          staffItemId: record.id,
          roleName: record.role,
          staffName: record.name,
          staffLicenseName: record.license_name || ""
        });
      });
      return {
        ...state,
        staffItems: orderBy(staffItems, ["staffItemId"], ["asc"])
      };
    }
    case types.FETCH_FAILED:
      return { ...state };
    case types.POST_STARTED:
      return { ...state };
    case types.POST_SUCCESS:
      return { ...state };
    case types.POST_FAILED:
      return { ...state };
    case types.DELETE_STARTED:
      return { ...state };
    case types.DELETE_SUCCESS:
      return { ...state };
    case types.DELETE_FAILED:
      return { ...state };
    default:
      return state;
  }
};
