import { SEIKATSUKAIGOReport } from "@stores/domain/mgr/SEIKATSUKAIGO/report/types";
import { InitialValues } from "@interfaces/mgr/SEIKATSUKAIGO/report/initial";
import { Checkbox } from "@constants/variables";
import getTime from "@utils/date/getTime";
import initializeSelectDate from "@utils/domain/facility/initializeSelectDate";
import {
  SERIOUS_DISABILITY_TYPE_LIST,
  SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT
} from "@constants/mgr/SEIKATSUKAIGO/variables";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";

export type InitialDataValues = InitialValues;

// デフォルト値設定
export const initialValues = (
  state?: SEIKATSUKAIGOReport
): InitialDataValues => {
  let inTime;
  if (state && state.inTime) {
    inTime = getTime(state.inTime);
  }

  let outTime;
  if (state && state.outTime) {
    outTime = getTime(state.outTime);
  }

  return {
    initial: {
      name: state && state.name ? state.name : "",
      uifId: state && state.uif_id ? state.uif_id : -1,
      targetDate: state && state.target_date ? state.target_date : "",
      status: state && state.status ? state.status.toString() : "1",
      trialUsageKind:
        state && state.trialUsageKind ? state.trialUsageKind : "1",
      lifeSupportHubInDistrictFlg:
        state && state.lifeSupportHubInDistrictFlg
          ? state.lifeSupportHubInDistrictFlg === Checkbox.ON
          : false,
      inTime: inTime ? inTime.toString().trim() : "",
      outTime: outTime ? outTime.toString().trim() : "",
      extended: state && state.extended ? state.extended : "0",
      didGetFood: state && state.didGetFood ? state.didGetFood : "0",
      travelTime: state && state.travelTime ? state.travelTime : "0",
      pickupPremises:
        state && state.pickupPremises ? state.pickupPremises : "0",
      memo: state && state.memo ? state.memo : "",
      severeDisabilitySupportType:
        state && state.severeDisabilitySupportType
          ? state.severeDisabilitySupportType.toString()
          : SERIOUS_DISABILITY_TYPE_LIST_INOUT_REPORT[0].value,
      isSevereDisabilitySupport:
        state && state.is_severe_disability_support
          ? `${state.is_severe_disability_support}` !==
            SERIOUS_DISABILITY_TYPE_LIST[0].value
          : false,
      nutritionScreeningFlg:
        state && state.nutritionScreeningFlg
          ? `${state.nutritionScreeningFlg}` === Checkbox.ON
          : false,
      sputumImplementationFlg:
        state && state.sputumImplementationFlg
          ? `${state.sputumImplementationFlg}` === Checkbox.ON
          : false,
      bathSupportFlg:
        state && state.bathSupportFlg
          ? `${state.bathSupportFlg}` === Checkbox.ON
          : false,
      nutritionImprovementServiceFlg:
        state && state.nutritionImprovementServiceFlg
          ? `${state.nutritionImprovementServiceFlg}` === Checkbox.ON
          : false,
      extended_202404:
        state && state.extended_202404 ? state.extended_202404 : "0",
      emergencyAcceptanceAdditionFlg:
        state && state.emergencyAcceptanceAdditionFlg
          ? state.emergencyAcceptanceAdditionFlg === Checkbox.ON
          : false,
      intensiveSupportFlg: numberToBoolean(state && state.intensiveSupportFlg),
      intensiveSupportStartDate: initializeSelectDate(
        state && state.intensiveSupportStartDate
          ? state.intensiveSupportStartDate
          : undefined
      ),
      calculatedHours:
        state && state.calculatedHours != null
          ? state.calculatedHours.toString()
          : ""
    }
  };
};
