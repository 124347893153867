import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

/**
 * 利用実績一覧(日ごと)APIresponse
 */
export type GetInOutResultsDailyResponse = {
  data: {
    inout_result_daily: {
      target_date: string;
    };
    inout_results: InoutResultsItem[];
    facility_units: InoutResultsFacilityUnitsItem[];
    staffs_in_facility: InoutResultsStaffsInFacilityItem[];
    display_list: InoutResultsDisplayList[];
  };
};

export type InoutResultsItem = {
  id: number;
  inout_result_daily_id?: number;
  users_in_facility_id: number;
  name_sei: string;
  name_mei: string;
  target_date?: string;
  input_class: number;
  inout_results_plan_id: number;
  service_delivery_records_id: number;
  status: number;
  status_text: string;
  number_of_boarding_and_alighting: number;
  number_of_participants: number;
  practitioner1: number;
  practitioner1_staff: PractitionerStaffItem | null;
  practitioner1_license: number;
  practitioner1_in_time: string;
  practitioner1_out_time: string;
  practitioner1_calculated_hours: number;
  practitioner1_calculated_moving_hours: number;
  practitioner1_memo: string;
  inout_results_details1: InoutResultsDetailsItem[];
  practitioner2: number;
  practitioner2_staff: PractitionerStaffItem | null;
  practitioner2_license: number;
  practitioner2_in_time: string;
  practitioner2_out_time: string;
  practitioner2_calculated_hours: number;
  practitioner2_memo: string;
  inout_results_details2: InoutResultsDetailsItem[];
  practitioner3_staff: PractitionerStaffItem | null;
  practitioner4_staff: PractitionerStaffItem | null;
  practitioner5_staff: PractitionerStaffItem | null;
  time_duplication_judgment_flg: number;
  whole_calculated_hours: number;
  calculated_hours_daytime: number;
  calculated_hours_nighttime: number;
  calculated_hours_late_night: number;
  calculated_hours_early_morning: number;
  duplicate_calculated_hours: number;
  duplication_calculated_hours_daytime: number;
  duplication_calculated_hours_nighttime: number;
  duplication_calculated_hours_late_night: number;
  duplication_calculated_hours_early_morning: number;
  moving_time_duplication_judgment_flg: number | null;
  whole_calculated_moving_hours: number | null;
  duplicate_calculated_moving_hours: number | null;
  accompany_support_in_time: string | null;
  accompany_support_out_time: string | null;
  whole_calculated_accompany_support_hours: number | null;
  whole_calculated_accompany_support_moving_hours: number | null;
  vehicle_calculated_hours: string;
  license_same_flg: number | null;
  second_person_flg: number | null;
  accompany_support_flg: number | null;
  emergency_support_flg: number | null;
  first_addition_flg: number | null;
  sputum_implementation_flg: number;
  behavior_disorder_flg: number | null;
  emergency_guide_support_flg: number | null;
  welfare_cooperation_flg: number | null;
  behavior_disorder_coaching_flg: number | null;
  body_restricted_still_flg: number | null;
  facility_unit_id: number | null;
  physical_care_flg: number;
  number_of_supporters: number;
  municipality: MunicipalityItem;
  service_codes: ServiceCodesItem[];
  support_before_hospitalization_flg: number | null;
  intensive_visit_care_support_flg: number | null;
};

export type InoutResultsFacilityUnitsItem = {
  id: number;
  unit_name: string;
  same_building_subtraction_flg: number;
};

export type InoutResultsStaffsInFacilityItem = {
  id: number;
  name: string;
  role: string;
  license: number;
};

export type InoutResultsDetailsItem = {
  id: number | null;
  action_class: number;
  in_time: string;
  out_time: string;
};

export type InoutResultsDisplayList = {
  users_in_facility_id: number;
  add_icon_flg: boolean;
  name_sei: string;
  name_mei: string;
  target_date?: string | null;
  isHoliday?: boolean;
  data_list: InoutResultsDataList[];
};

export type InoutResultsDataList = {
  no: number;
  plan: {
    inout_results_id: number | null;
    dispatch_no: number | null;
    status: number;
    status_text: string;
    in_time: string | null;
    out_time: string | null;
    calculated_hours: number | null;
    calculated_moving_hours: number | null;
    getting_on_and_off: number | null;
    number_of_participants: number | null;
  };
  result: {
    inout_results_id: number | null;
    dispatch_no: number | null;
    status: number;
    status_text: string;
    in_time: string | null;
    out_time: string | null;
    calculated_hours: number | null;
    calculated_moving_hours: number | null;
    getting_on_and_off: number | null;
    number_of_participants: number | null;
    emergency_support_flg: number | null;
    service_code_flg: number | null;
    difference_flg: number | null;
  };
};

export type PractitionerStaffItem = {
  id: number;
  name: string;
  facility_id: number;
  snapshot_name: string;
};

export type MunicipalityItem = {
  calculation_time_flg: number;
  round_up_minute: number;
  time_division_flg: number;
};

export type ServiceCodesItem = {
  service_code_inout_result_id: number;
  service_code_municipality_id: number;
  name: string;
  service_code: string;
  unit_price: number;
};

/**
 * 利用実績一覧(日ごと)を取得する
 * @param date YYYYMMDD
 */
export const getInOutResultsDaily = async (
  date: string
): Promise<AxiosResponse<GetInOutResultsDailyResponse>> => {
  const url = `${VERSION_URL_202404}/inout_results/daily/${date}`;
  return request.get<GetInOutResultsDailyResponse>(url);
};
