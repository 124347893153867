import {
  ServiceDeliveryDetailValues,
  DetailsInitialValues,
  customRecordValues,
  DestinationValues,
  MoneyManagementsValues,
  ExpensesValues
} from "@initialize/record/serviceDelivery/initialValues";
import { PostServiceDeliveryDetails } from "@api/requests/serviceDelivery/postServiceDeliveryDetails";
import booleanToNumber0or1 from "@utils/dataNormalizer/booleanToNumber0or1";
import * as format from "date-fns/format";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";
import { checkStatus } from "@initialize/record/serviceDelivery/validation";
import {
  KYOTAKUKAIGO_STATUS_LIST,
  MIXUTER_INPUT_TYPE,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { GetServiceDeliveryDetails } from "@api/requests/serviceDelivery/getServiceDeliveryDetails";
import { ServiceDeliveryState } from "./types";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import {
  DAY_SELECT_TYPE,
  FacilityType,
  SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO
} from "@constants/variables";
import isEqual from "lodash-es/isEqual";
import {
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import castNumber from "@utils/dataNormalizer/castNumber";

type PostCustomRecordValues = PractitionersType["custom_record"];

const formatTime = (
  time: string,
  baseDate: string,
  dateOption?: string
): string | null => {
  if (time === "") {
    return null;
  }
  const dateArr = baseDate.split("-");
  const timeArr = time.split(":");
  const timeDate = new Date(
    Number(dateArr[0]),
    Number(dateArr[1]) - 1,
    Number(dateArr[2]),
    Number(timeArr[0]),
    Number(timeArr[1])
  );
  if (dateOption === DAY_SELECT_TYPE.nextDay) {
    timeDate.setDate(timeDate.getDate() + 1);
  }
  return format(timeDate, "YYYY-MM-DD HH:mm:ss");
};

type PractitionersType = Exclude<
  PostServiceDeliveryDetails["service_delivery_records"]["service_delivery_record_practitioners"],
  undefined
>[0];

export const normalizeDetailsRecordFromAPI = (
  res: GetServiceDeliveryDetails["data"]
): ServiceDeliveryState["detailsRecord"] => {
  const serviceDeliveryRecordPractitionersState = res.service_delivery_record_practitioners.map(
    (item) => {
      // 運転/空き詳細
      const serviceDeliveryRecordsPractitionerDetailsParam = item.service_delivery_record_practitioner_details
        ? item.service_delivery_record_practitioner_details.map(
            (detailsItem) => {
              return {
                serviceDeliveryRecordPractitionerDetailsId:
                  detailsItem.service_delivery_record_practitioner_details_id,
                actionClass: detailsItem.action_class,
                startTime: detailsItem.start_time,
                endTime: detailsItem.end_time,
                isDelete: 0
              };
            }
          )
        : [];
      // サービス提供記録詳細情報
      const serviceDeliveryRecordsCustomRecordsParam = item.service_delivery_records
        ? item.service_delivery_records.map((recordsItem) => {
            return {
              customRecords_category_id: recordsItem.custom_records_category_id,
              input: recordsItem.input.map((inputItem) => {
                return {
                  id: inputItem.id,
                  custom_record_item_id: inputItem.custom_record_item_id,
                  input_data: inputItem.input_data,
                  choiced_item_id: inputItem.choiced_item_id,
                  checked: inputItem.checked
                };
              })
            };
          })
        : null;
      return {
        serviceDeliveryRecordPractitionersId:
          item.service_delivery_record_practitioners_id,
        startTime: item.start_time,
        endTime: item.end_time,
        numberOfTime: item.number_of_time ? String(item.number_of_time) : null,
        numberOfRides: item.number_of_rides || 0,
        calculatedMovingHours: item.calculated_moving_hours
          ? String(item.calculated_moving_hours)
          : null,
        accompanySupportInTime: item.accompany_support_in_time || null,
        accompanySupportOutTime: item.accompany_support_out_time || null,
        calculatedAccompanySupportHours: item.calculated_accompany_support_hours
          ? item.calculated_accompany_support_hours.toFixed(1)
          : null,
        calculatedAccompanySupportMovingHours: item.calculated_accompany_support_moving_hours
          ? item.calculated_accompany_support_moving_hours.toFixed(1)
          : null,
        practitioner: item.practitioner,
        practitioner2: item.practitioner2 || null,
        practitioner3: item.practitioner3 || null,
        practitioner4: item.practitioner4 || null,
        practitioner5: item.practitioner5 || null,
        practitionerName: item.practitioner
          ? item.practitioner.snapshot_name
          : null,
        practitionerName2: item.practitioner2
          ? item.practitioner2.snapshot_name
          : null,
        practitionerName3: item.practitioner3
          ? item.practitioner3.snapshot_name
          : null,
        practitionerName4: item.practitioner4
          ? item.practitioner4.snapshot_name
          : null,
        practitionerName5: item.practitioner5
          ? item.practitioner5.snapshot_name
          : null,
        practitionerNum: item.practitioner_num,
        displayFlg: numberToBoolean(item.display_flg, false),
        serviceDeliveryRecordsPractitionerDetails: serviceDeliveryRecordsPractitionerDetailsParam,
        serviceDeliveryRecords: serviceDeliveryRecordsCustomRecordsParam,
        serviceDeliveryRecordPractitionerDestinations:
          item.service_delivery_record_practitioner_destinations || [],
        serviceDeliveryRecordPractitionerMoneyManagements: item.service_delivery_record_practitioner_money_managements || {
          traveling_expenses: [],
          other_expenses: []
        }
      };
    }
  );

  return {
    serviceDeliveryRecordsId: res.service_delivery_records_id,
    inoutResultsId: res.inout_results_id,
    supportProcedureFormsId: res.support_procedure_forms_id,
    createdAt: res.created_at,
    updatedAt: res.updated_at,
    usersInFacilityId: res.users_in_facility_id,
    nameSei: res.name_sei,
    nameMei: res.name_mei,
    targetDate: res.target_date,
    status: String(res.status),
    statusText: res.status_text ? String(res.status_text) : "",
    emergencySupportFlg: numberToBoolean(res.emergency_support_flg, false),
    sputumImplementationFlg: numberToBoolean(
      res.sputum_implementation_flg,
      false
    ),
    bodyRestrictedStillFlg: numberToBoolean(
      res.body_restricted_still_flg,
      false
    ),
    emergencyGuideSupportFlg: numberToBoolean(
      res.emergency_guide_support_flg,
      false
    ),
    numberOfPractitioner: res.number_of_practitioner,
    numberOfSupporters:
      res.number_of_supporters !== undefined ? res.number_of_supporters : null,
    supportRatioPerPractitioner:
      res.support_ratio_per_practitioner !== undefined
        ? res.support_ratio_per_practitioner
        : null,
    physicalCareFlg: numberToBoolean(res.physical_care_flg, false),
    licenseSameFlg: numberToBoolean(res.license_same_flg, false),
    accompanySupportFlg: numberToBoolean(res.accompany_support_flg, false),
    accompanySupportDisplayFlg: numberToBoolean(
      res.accompany_support_display_flg,
      false
    ),
    serviceDeliveryRecordPractitioners: serviceDeliveryRecordPractitionersState,
    municipality: {
      calculationTimeFlg: res.municipality
        ? numberToBoolean(res.municipality.calculation_time_flg, false)
        : false,
      roundUpMinute: res.municipality ? res.municipality.round_up_minute : null
    }
  };
};

const removeNullParam = <T extends {}>(data: T): T => {
  const targetParam = {} as T;

  // undefinedをのparamを除去
  Object.keys(data).forEach((param) => {
    if (data[param] !== undefined) {
      targetParam[param] = data[param];
    }
  });

  return targetParam;
};

// カスタムレコード用
const normalizeCustomRecords = (
  postCustomRecord: customRecordValues,
  initialDataCustomRecord: customRecordValues,
  status: string,
  targetDate: string,
  facilityType: FacilityType
): PostCustomRecordValues => {
  const resCustom = [] as PostCustomRecordValues;

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDatakeys = Object.keys(diffData);

  // 入力タイプ「テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_text")) {
    const diffDataText = omitByNoChanges(
      postCustomRecord.input_type_text,
      initialDataCustomRecord.input_type_text
    );
    const records = Object.values(
      diffDataText
    ) as customRecordValues["input_type_text"][number][];

    const postRecord = records
      // 「サービス内容」に合った見出しタイプの項目のみPOSTする
      .filter(
        (item) =>
          !!item && checkStatus(item.category_type, status, facilityType)
      )
      .map((item) => {
        return {
          custom_records_category_id: item.custom_records_category_id,
          custom_record_input_id: item.custom_record_input_id,
          custom_record_item_id: item.custom_record_item_id,
          input_data: item.input_data
        };
      });
    resCustom.push(...postRecord);
  }

  // 入力タイプ「チェックボックス」
  if (diffDatakeys.some((item) => item === "input_type_checkbox")) {
    const diffDataCheckBox = omitByNoChanges(
      postCustomRecord.input_type_checkbox,
      initialDataCustomRecord.input_type_checkbox
    );
    const records = Object.values(
      diffDataCheckBox
    ) as customRecordValues["input_type_checkbox"][number][];
    records.forEach((item) => {
      const postRecord = Object.keys(item)
        // 「サービス内容」に合った見出しタイプの項目のみPOSTする
        .filter((key) =>
          checkStatus(item[key].category_type, status, facilityType)
        )
        .map((key) => {
          return {
            custom_records_category_id: item[key].custom_records_category_id,
            custom_record_input_id: item[key].custom_record_input_id,
            custom_record_item_id: item[key].custom_record_item_id,
            choiced_item_id: item[key].choiced_item_id,
            checked: castCheckBoxToNumber(item[key].checked)
          };
        });
      resCustom.push(...postRecord);
    });
  }

  // 入力タイプ「複数テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_multi_text")) {
    const diffDataMultiText = omitByNoChanges(
      postCustomRecord.input_type_multi_text,
      initialDataCustomRecord.input_type_multi_text
    );
    const records = Object.values(
      diffDataMultiText
    ) as customRecordValues["input_type_multi_text"][number][];
    records.forEach((item) => {
      if (!item) {
        return;
      }
      const postRecord = Object.keys(item)
        // 「サービス内容」に合った見出しタイプの項目のみPOSTする
        .filter((key) =>
          checkStatus(item[key].category_type, status, facilityType)
        )
        .map((key) => {
          return {
            custom_records_category_id: item[key].custom_records_category_id,
            custom_record_input_id: item[key].custom_record_input_id,
            custom_record_item_id: item[key].custom_record_item_id,
            choiced_item_id: item[key].choiced_item_id,
            input_data: item[key].input_data
          };
        });
      resCustom.push(...postRecord);
    });
  }

  // 入力タイプ「選択式（入力項目）」
  if (diffDatakeys.some((item) => item === "input_type_mixture")) {
    const diffDataMixture = omitByNoChanges(
      postCustomRecord.input_type_mixture,
      initialDataCustomRecord.input_type_mixture
    );
    const records = Object.values(
      diffDataMixture
    ) as customRecordValues["input_type_mixture"][number][];
    records.forEach((item) => {
      if (!item) {
        return;
      }
      const postRecord = Object.keys(item)
        // 「サービス内容」に合った見出しタイプの項目のみPOSTする
        .filter((key) =>
          checkStatus(item[key].category_type, status, facilityType)
        )
        .map((key) => {
          if (
            item[key].default_choice_input_type === MIXUTER_INPUT_TYPE.checkbox
          ) {
            return {
              custom_records_category_id: item[key].custom_records_category_id,
              custom_record_input_id: item[key].custom_record_input_id,
              custom_record_item_id: item[key].custom_record_item_id,
              choiced_item_id: item[key].choiced_item_id,
              checked: castCheckBoxToNumber(item[key].checked)
            };
          }

          return {
            custom_records_category_id: item[key].custom_records_category_id,
            custom_record_input_id: item[key].custom_record_input_id,
            custom_record_item_id: item[key].custom_record_item_id,
            choiced_item_id: item[key].choiced_item_id,
            input_data:
              item[key].default_choice ===
              MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
                ? item[key].input_data
                : formatTime(
                    item[key].input_data,
                    targetDate,
                    item[key].timeDate
                  )
          };
        });
      resCustom.push(...postRecord);
    });
  }

  // 入力タイプ「チェックボックス（カスタム可）＋テキスト」」
  if (diffDatakeys.some((item) => item === "input_type_checkbox_and_text")) {
    const diffDataCheckboxAndText = omitByNoChanges(
      postCustomRecord.input_type_checkbox_and_text,
      initialDataCustomRecord.input_type_checkbox_and_text
    );
    const records = Object.values(
      diffDataCheckboxAndText
    ) as customRecordValues["input_type_checkbox_and_text"][number][];
    records.forEach((item) => {
      if (!item) {
        return;
      }
      const postRecord = Object.keys(item).map((key) => {
        if (
          item[key].default_choice_input_type === MIXUTER_INPUT_TYPE.checkbox
        ) {
          return {
            custom_records_category_id: item[key].custom_records_category_id,
            custom_record_input_id: item[key].custom_record_input_id,
            custom_record_item_id: item[key].custom_record_item_id,
            choiced_item_id: item[key].choiced_item_id,
            checked: castCheckBoxToNumber(item[key].checked)
          };
        }

        return {
          custom_records_category_id: item[key].custom_records_category_id,
          custom_record_input_id: item[key].custom_record_input_id,
          custom_record_item_id: item[key].custom_record_item_id,
          choiced_item_id: item[key].choiced_item_id,
          input_data:
            item[key].default_choice ===
            MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
              ? item[key].input_data
              : formatTime(item[key].input_data, targetDate, item[key].timeDate)
        };
      });
      resCustom.push(...postRecord);
    });
  }

  return resCustom;
};

// 行き先用
const practitionerDestinationParams = (
  destinations: DestinationValues,
  targetDate: string,
  initialDestinations?: DestinationValues
): PractitionersType["service_delivery_record_practitioner_destinations"] => {
  return destinations
    .filter(
      // 追加した行で「isDelete」が1または値が何もない値は送らない
      (d) =>
        !(
          d.service_delivery_record_practitioner_destinations_id === null &&
          (d.isDelete === 1 ||
            (d.place === "" && d.support === "" && d.when_time === ""))
        )
    )
    .map((d) => {
      const initialValues = initialDestinations
        ? initialDestinations.find(
            (value) =>
              value.service_delivery_record_practitioner_destinations_id ===
              d.service_delivery_record_practitioner_destinations_id
          )
        : undefined;
      // 存在する詳細データを編集する場合
      if (initialValues) {
        const res = omitByNoChanges(d, initialValues);
        const destinationParam = {
          service_delivery_record_practitioner_destinations_id:
            d.service_delivery_record_practitioner_destinations_id,
          when_time:
            !d.isDelete && res.when_time !== undefined
              ? formatTime(d.when_time, targetDate)
              : undefined,
          place:
            !d.isDelete && res.place !== undefined ? `${d.place}` : undefined,
          support:
            !d.isDelete && res.support !== undefined
              ? `${d.support}`
              : undefined,
          is_delete: res.isDelete ? d.isDelete : undefined
        };
        return removeNullParam(destinationParam);
      }
      // 新規追加
      return {
        service_delivery_record_practitioner_destinations_id: null,
        when_time: formatTime(d.when_time, targetDate),
        place: `${d.place}`,
        support: `${d.support}`,
        is_delete: d.isDelete
      };
    });
};

// 金銭管理用
const practitionerMoneyManagementsParams = (
  managements: MoneyManagementsValues,
  initialManagements?: MoneyManagementsValues
): PractitionersType["service_delivery_record_practitioner_money_managements"] => {
  const createExpenses = (
    expenses: ExpensesValues,
    initial: ExpensesValues
  ): {
    service_delivery_record_practitioner_money_managements_id: number | null;
    transportation?: number | null;
    free_text?: string | null;
    amount_money?: string | null;
    is_delete?: number;
  }[] => {
    return expenses
      .filter(
        // 追加した行で「isDelete」が1または値が何もない値は送らない
        (e) =>
          !(
            e.service_delivery_record_practitioner_money_managements_id ===
              null &&
            (e.isDelete === 1 ||
              (e.free_text === "" &&
                e.amount_money === "" &&
                e.transportation === 0))
          )
      )
      .map((e) => {
        const initialValues = initial
          ? initial.find(
              (value) =>
                value.service_delivery_record_practitioner_money_managements_id ===
                e.service_delivery_record_practitioner_money_managements_id
            )
          : undefined;
        // 存在する詳細データを編集する場合
        if (initialValues) {
          const res = omitByNoChanges(e, initialValues);
          const moneyManagementsParam = {
            service_delivery_record_practitioner_money_managements_id:
              e.service_delivery_record_practitioner_money_managements_id,
            transportation:
              !e.isDelete && res.transportation !== undefined
                ? e.transportation || null
                : undefined,
            free_text:
              !e.isDelete && res.free_text !== undefined
                ? `${e.free_text}`
                : undefined,
            amount_money:
              !e.isDelete && res.amount_money !== undefined
                ? `${e.amount_money}`
                : undefined,
            is_delete: res.isDelete ? e.isDelete : undefined
          };
          return removeNullParam(moneyManagementsParam);
        }
        // 新規追加
        return {
          service_delivery_record_practitioner_money_managements_id: null,
          transportation: e.transportation || null,
          free_text: `${e.free_text}`,
          amount_money: `${e.amount_money}`,
          is_delete: e.isDelete
        };
      });
  };
  const travelingExpenses = managements.traveling_expenses;
  const otherExpenses = managements.other_expenses;
  const initialTravelingExpenses = initialManagements
    ? initialManagements.traveling_expenses
    : [];
  const initialOtherExpenses = initialManagements
    ? initialManagements.other_expenses
    : [];
  return {
    traveling_expenses: createExpenses(
      travelingExpenses,
      initialTravelingExpenses
    ),
    other_expenses: createExpenses(otherExpenses, initialOtherExpenses)
  };
};

// 運転/空き時間用
const practitionerDetailsParams = (
  details: DetailsInitialValues,
  targetDate: string,
  initialDetails?: DetailsInitialValues
): PractitionersType["service_delivery_record_practitioner_details"] => {
  return (
    details
      // 追加した行で「isDelete」が1の値は送らない
      .filter(
        (item) =>
          !(!item.serviceDeliveryRecordPractitionerDetailsId && item.isDelete)
      )
      .map((item) => {
        const initialValues = initialDetails
          ? initialDetails.find(
              (value) =>
                value.serviceDeliveryRecordPractitionerDetailsId ===
                item.serviceDeliveryRecordPractitionerDetailsId
            )
          : undefined;

        // 存在する詳細データを編集する場合
        if (initialValues) {
          const res = omitByNoChanges(item, initialValues);
          const detailParam = {
            service_delivery_record_practitioner_details_id:
              item.serviceDeliveryRecordPractitionerDetailsId,
            action_class:
              !item.isDelete && res.actionClass
                ? Number(item.actionClass)
                : undefined,
            start_time:
              !item.isDelete &&
              (res.startTime !== undefined || res.startTimeDate !== undefined)
                ? formatTime(item.startTime, targetDate, item.startTimeDate)
                : undefined,
            end_time:
              !item.isDelete &&
              (res.endTime !== undefined || res.endTimeDate !== undefined)
                ? formatTime(item.endTime, targetDate, item.endTimeDate)
                : undefined,
            is_delete: res.isDelete ? item.isDelete : undefined
          };

          return removeNullParam(detailParam);
        }

        // 新規追加
        return {
          service_delivery_record_practitioner_details_id: null,
          action_class: Number(item.actionClass),
          start_time: formatTime(
            item.startTime,
            targetDate,
            item.startTimeDate
          ),
          end_time: formatTime(item.endTime, targetDate, item.endTimeDate),
          is_delete: item.isDelete
        };
      })
  );
};

// サービス提供者オブジェクト用
const createPractitionersParams = (
  params: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"],
  initialValuesPractitioners: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"],
  targetDate: string,
  status: string,
  facilityType: FacilityType,
  isMobile: boolean,
  licenseSameFlg: boolean,
  isFirstRecord: boolean
): PractitionersType => {
  // 新規・編集共通処理
  const numberOfRidesValues =
    params.numberOfRides === "" ? null : Number(params.numberOfRides);
  // 「開始時間・終了時間」はyyyy-mm-dd HH:ii:ssに変換
  const startTimeValue = formatTime(
    params.startTime,
    targetDate,
    params.startTimeDate
  );
  const endTimeValue = formatTime(
    params.endTime,
    targetDate,
    params.endTimeDate
  );
  // 「同行支援開始時間・終了時間」
  const accompanySupportInTimeValue = formatTime(
    params.accompanySupportInTime,
    targetDate,
    params.accompanySupportInTimeDate
  );
  const accompanySupportOutTimeValue = formatTime(
    params.accompanySupportOutTime,
    targetDate,
    params.accompanySupportOutTimeDate
  );

  const numberOfServiceHours = String(params.numberOfServiceHours).padStart(
    2,
    "0"
  );
  const numberOfServiceMinutes = String(params.numberOfServiceMinutes).padStart(
    2,
    "0"
  );

  const isIdoshien = facilityType === FacilityType.IDOSHIEN;

  const isIdoshienVehicleTransportStatus =
    isIdoshien && status === IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value;

  const idoshienVehicleTransportStatusNumberOfTime =
    params.numberOfServiceHours || params.numberOfServiceMinutes
      ? `${numberOfServiceHours || "00"}:${numberOfServiceMinutes || "00"}:00`
      : undefined;

  // 編集
  // ※利用実績のみの登録の場合など「service_delivery_record_practitioners」が1要素しか登録されていない場合は新規として登録する
  if (params.serviceDeliveryRecordPractitionersId) {
    // 初期値との差分データを抽出
    const res = omitByNoChanges(params, initialValuesPractitioners);

    // practitionerがnullの場合は初期化，undefinedの場合は更新しない
    let practitionerValues;
    let practitionerValues2;
    let practitionerValues3;
    let practitionerValues4;
    let practitionerValues5;
    if ("practitioner" in res) {
      practitionerValues = params.practitioner
        ? Number(params.practitioner)
        : null;
    }
    if ("practitioner2" in res) {
      practitionerValues2 = params.practitioner2
        ? Number(params.practitioner2)
        : null;
    }
    if ("practitioner3" in res) {
      practitionerValues3 = params.practitioner3
        ? Number(params.practitioner3)
        : null;
    }
    if ("practitioner4" in res) {
      practitionerValues4 = params.practitioner4
        ? Number(params.practitioner4)
        : null;
    }
    if ("practitioner5" in res) {
      practitionerValues5 = params.practitioner5
        ? Number(params.practitioner5)
        : null;
    }

    const numberOfTimeValue =
      res.numberOfTime !== undefined ? params.numberOfTime : undefined;

    const editParams: Exclude<
      PostServiceDeliveryDetails["service_delivery_records"]["service_delivery_record_practitioners"],
      undefined
    >[0] = {
      service_delivery_record_practitioners_id:
        params.serviceDeliveryRecordPractitionersId,
      start_time:
        res.startTime !== undefined || res.startTimeDate !== undefined
          ? startTimeValue
          : undefined,
      end_time:
        res.endTime !== undefined || res.endTimeDate !== undefined
          ? endTimeValue
          : undefined,
      number_of_time:
        isIdoshienVehicleTransportStatus && isFirstRecord
          ? idoshienVehicleTransportStatusNumberOfTime
          : numberOfTimeValue,
      number_of_rides:
        res.numberOfRides !== undefined &&
        facilityType === FacilityType.KYOTAKUKAIGO &&
        status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
          ? numberOfRidesValues
          : undefined,
      calculated_moving_hours:
        res.calculatedMovingHours !== undefined
          ? Number(params.calculatedMovingHours)
          : undefined,
      accompany_support_in_time:
        res.accompanySupportInTime !== undefined ||
        res.accompanySupportInTimeDate !== undefined
          ? accompanySupportInTimeValue
          : undefined,
      accompany_support_out_time:
        res.accompanySupportOutTime !== undefined ||
        res.accompanySupportOutTimeDate !== undefined
          ? accompanySupportOutTimeValue
          : undefined,
      calculated_accompany_support_hours:
        res.calculatedAccompanySupportHours !== undefined
          ? Number(params.calculatedAccompanySupportHours)
          : undefined,
      calculated_accompany_support_moving_hours:
        res.calculatedAccompanySupportMovingHours !== undefined
          ? Number(params.calculatedAccompanySupportMovingHours)
          : undefined,
      practitioner: params.isSnapshot ? undefined : practitionerValues,
      practitioner2: params.isSnapshot2 ? undefined : practitionerValues2,
      practitioner3: params.isSnapshot3 ? undefined : practitionerValues3,
      practitioner4: params.isSnapshot4 ? undefined : practitionerValues4,
      practitioner5: params.isSnapshot5 ? undefined : practitionerValues5,
      practitioner_name: params.isSnapshot
        ? undefined
        : params.practitionerName || undefined,
      practitioner2_name: params.isSnapshot2
        ? undefined
        : params.practitionerName2 || undefined,
      practitioner3_name: params.isSnapshot3
        ? undefined
        : params.practitionerName3 || undefined,
      practitioner4_name: params.isSnapshot4
        ? undefined
        : params.practitionerName4 || undefined,
      practitioner5_name: params.isSnapshot5
        ? undefined
        : params.practitionerName5 || undefined,
      practitioner_num: params.practitionerNum,
      display_flg: booleanToNumber0or1(params.displayFlg),
      service_delivery_record_practitioner_details:
        facilityType !== FacilityType.KYOTAKUKAIGO ||
        status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
          ? practitionerDetailsParams(
              params.serviceDeliveryRecordPractitionerDetails,
              targetDate,
              initialValuesPractitioners.serviceDeliveryRecordPractitionerDetails
            )
          : undefined,
      custom_record: normalizeCustomRecords(
        params.custom_record,
        initialValuesPractitioners.custom_record,
        status,
        targetDate,
        facilityType
      ),
      service_delivery_record_practitioner_destinations: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
        facilityType
      )
        ? practitionerDestinationParams(
            params.serviceDeliveryRecordPractitionerDestinations,
            targetDate,
            initialValuesPractitioners.serviceDeliveryRecordPractitionerDestinations
          )
        : undefined,
      service_delivery_record_practitioner_money_managements: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
        facilityType
      )
        ? practitionerMoneyManagementsParams(
            params.serviceDeliveryRecordPractitionerMoneyManagements,
            initialValuesPractitioners.serviceDeliveryRecordPractitionerMoneyManagements
          )
        : undefined
    };

    return removeNullParam(editParams);
  }

  // モバイル時記録が入力されていればPC画面では表示状態にする
  const mobileDisplayFlag = !!(
    isMobile &&
    !isEqual(params.custom_record, initialValuesPractitioners.custom_record)
  );

  const newParams: Exclude<
    PostServiceDeliveryDetails["service_delivery_records"]["service_delivery_record_practitioners"],
    undefined
  >[0] = {
    service_delivery_record_practitioners_id: null,
    start_time: startTimeValue,
    end_time: endTimeValue,
    number_of_time:
      isIdoshienVehicleTransportStatus && isFirstRecord
        ? idoshienVehicleTransportStatusNumberOfTime
        : params.numberOfTime,
    number_of_rides:
      facilityType === FacilityType.KYOTAKUKAIGO &&
      status === KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
        ? numberOfRidesValues
        : undefined,
    // 重度のみ
    calculated_moving_hours:
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? Number(params.calculatedMovingHours)
        : undefined,
    accompany_support_in_time:
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? accompanySupportInTimeValue
        : undefined,
    accompany_support_out_time:
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? accompanySupportOutTimeValue
        : undefined,
    calculated_accompany_support_hours:
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? Number(params.calculatedAccompanySupportHours)
        : undefined,
    calculated_accompany_support_moving_hours:
      facilityType === FacilityType.JUDOHOMONKAIGO
        ? Number(params.calculatedAccompanySupportMovingHours)
        : undefined,
    practitioner: Number(params.practitioner) || null,
    practitioner2: Number(params.practitioner2) || null,
    practitioner3: Number(params.practitioner3) || null,
    practitioner4: Number(params.practitioner4) || null,
    practitioner5: Number(params.practitioner5) || null,
    practitioner_name: params.practitionerName || undefined,
    practitioner2_name: params.practitionerName2 || undefined,
    practitioner3_name: params.practitionerName3 || undefined,
    practitioner4_name: params.practitionerName4 || undefined,
    practitioner5_name: params.practitionerName5 || undefined,
    practitioner_num: params.practitionerNum,
    display_flg: booleanToNumber0or1(mobileDisplayFlag || params.displayFlg),
    service_delivery_record_practitioner_details:
      facilityType !== FacilityType.KYOTAKUKAIGO ||
      status !== KYOTAKUKAIGO_STATUS_LIST.GETTING_ON_AND_OFF.value
        ? practitionerDetailsParams(
            params.serviceDeliveryRecordPractitionerDetails,
            targetDate
          )
        : undefined,
    custom_record: normalizeCustomRecords(
      params.custom_record,
      initialValuesPractitioners.custom_record,
      status,
      targetDate,
      facilityType
    ),
    service_delivery_record_practitioner_destinations: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
      facilityType
    )
      ? practitionerDestinationParams(
          params.serviceDeliveryRecordPractitionerDestinations,
          targetDate,
          initialValuesPractitioners.serviceDeliveryRecordPractitionerDestinations
        )
      : undefined,
    service_delivery_record_practitioner_money_managements: SERVICE_DELIVERY_TYPE_DOKOENGO_KODOENGO.includes(
      facilityType
    )
      ? practitionerMoneyManagementsParams(
          params.serviceDeliveryRecordPractitionerMoneyManagements,
          initialValuesPractitioners.serviceDeliveryRecordPractitionerMoneyManagements
        )
      : undefined
  };

  return removeNullParam(newParams);
};

export const normalizeFormValuesToPostRecordDetailParams = (
  values: ServiceDeliveryDetailValues,
  initialValues: ServiceDeliveryDetailValues,
  targetDateValues: string,
  facilityType: FacilityType,
  isMobile: boolean,
  supportProcedureFormsId: string
): PostServiceDeliveryDetails => {
  const res = omitByNoChanges(values, initialValues);

  const normalizedServiceDeliveryRecordPractitioners1 = createPractitionersParams(
    values.serviceDeliveryRecordPractitioners1,
    initialValues.serviceDeliveryRecordPractitioners1,
    targetDateValues,
    values.status,
    facilityType,
    isMobile,
    values.licenseSameFlg || false,
    true
  );

  const numberOfPractitionerNum = castNumber(values.numberOfPractitioner);
  const isMoreThan2Persons =
    Number(IDOSHIEN_MEMBER_LIST.TWO.value) <= numberOfPractitionerNum;
  const normalizedServiceDeliveryRecordPractitioners2 = createPractitionersParams(
    values.serviceDeliveryRecordPractitioners2,
    initialValues.serviceDeliveryRecordPractitioners2,
    targetDateValues,
    values.status,
    facilityType,
    isMobile,
    values.licenseSameFlg || false,
    false
  );

  const serviceDeliveryRecordPractitionersParams = isMoreThan2Persons
    ? [
        { ...normalizedServiceDeliveryRecordPractitioners1 },
        { ...normalizedServiceDeliveryRecordPractitioners2 }
      ]
    : [{ ...normalizedServiceDeliveryRecordPractitioners1 }];

  const isNotIdoshienGroupOrVehicleTransportStatus =
    facilityType === FacilityType.IDOSHIEN &&
    ![
      IDOSHIEN_STATUS_LIST.GROUP.value,
      IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
    ].includes(values.status);

  const isIdoshienGroupStatus =
    facilityType === FacilityType.IDOSHIEN &&
    values.status === IDOSHIEN_STATUS_LIST.GROUP.value;

  // 編集
  const params = values.serviceDeliveryRecordsId
    ? {
        service_delivery_records_id: values.serviceDeliveryRecordsId,
        inout_results_id: values.inoutResultsId,
        support_procedure_forms_id: Number(supportProcedureFormsId) || null,
        users_in_facility_id: values.usersInFacilityId,
        target_date: targetDateValues,
        status: res.status ? Number(res.status) : undefined,
        status_text: res.statusText ? String(res.statusText) : undefined,
        emergency_support_flg:
          res.emergencySupportFlg !== undefined
            ? booleanToNumber0or1(values.emergencySupportFlg)
            : undefined,
        sputum_implementation_flg:
          res.sputumImplementationFlg !== undefined
            ? booleanToNumber0or1(values.sputumImplementationFlg)
            : undefined,
        body_restricted_still_flg:
          res.bodyRestrictedStillFlg !== undefined
            ? booleanToNumber0or1(values.bodyRestrictedStillFlg)
            : undefined,
        emergency_guide_support_flg:
          res.emergencyGuideSupportFlg !== undefined
            ? booleanToNumber0or1(values.emergencyGuideSupportFlg)
            : undefined,
        accompany_support_flg:
          res.accompanySupportFlg !== undefined
            ? booleanToNumber0or1(values.accompanySupportFlg)
            : undefined,
        number_of_practitioner: Number(values.numberOfPractitioner),
        number_of_supporters:
          res.numberOfSupporters && isIdoshienGroupStatus
            ? Number(res.numberOfSupporters)
            : undefined,
        physical_care_flg:
          res.physicalCareFlg !== undefined
            ? booleanToNumber0or1(values.physicalCareFlg || false)
            : undefined,
        license_same_flg:
          res.licenseSameFlg !== undefined &&
          isNotIdoshienGroupOrVehicleTransportStatus
            ? booleanToNumber0or1(values.licenseSameFlg || false)
            : undefined,
        service_delivery_record_practitioners: serviceDeliveryRecordPractitionersParams
      }
    : {
        service_delivery_records_id: null,
        inout_results_id: values.inoutResultsId,
        support_procedure_forms_id: values.supportProcedureFormsId,
        users_in_facility_id: values.usersInFacilityId,
        target_date: targetDateValues,
        status: Number(values.status),
        status_text: values.statusText ? values.statusText : undefined,
        emergency_support_flg: booleanToNumber0or1(values.emergencySupportFlg),
        sputum_implementation_flg: booleanToNumber0or1(
          values.sputumImplementationFlg
        ),
        body_restricted_still_flg: booleanToNumber0or1(
          values.bodyRestrictedStillFlg
        ),
        emergency_guide_support_flg:
          facilityType === FacilityType.JUDOHOMONKAIGO
            ? booleanToNumber0or1(values.emergencyGuideSupportFlg)
            : undefined,
        accompany_support_flg:
          facilityType === FacilityType.JUDOHOMONKAIGO
            ? booleanToNumber0or1(values.accompanySupportFlg)
            : undefined,
        number_of_practitioner: Number(values.numberOfPractitioner),
        number_of_supporters:
          values.numberOfSupporters && isIdoshienGroupStatus
            ? Number(values.numberOfSupporters)
            : undefined,
        physical_care_flg: values.physicalCareFlg
          ? booleanToNumber0or1(values.physicalCareFlg)
          : undefined,
        license_same_flg:
          values.licenseSameFlg && isNotIdoshienGroupOrVehicleTransportStatus
            ? booleanToNumber0or1(values.licenseSameFlg)
            : undefined,
        service_delivery_record_practitioners: serviceDeliveryRecordPractitionersParams
      };

  // 初期値から更新がない値は削除（種別によるリクエストしないパラメータの削除もこちらで補完）
  const removedParam = removeNullParam(params);
  return { service_delivery_records: removedParam };
};
