import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { AdminTemplate201910 } from "@components/templates/AdminTemplate201910";
import EditUserForm from "@components/v201910/organisms/mgr/JIRITSUKUNRENSEIKATSU/users/EditUserForm";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import {
  INVOICE_VERSION_OPTIONS_123,
  INVOICE_VERSION_V201910
} from "@constants/variables";
import { INVOICE_PATHS_FUNCTIONS } from "@constants/mgr/JIRITSUKUNRENSEIKATSU/variables";

type Props = RouteComponentProps<{ id: string }>;

/**
 * 利用者情報 > 編集
 */
const EditUser = (props: Props): JSX.Element => {
  return (
    <AdminTemplate201910
      pageName="利用者情報"
      invoiceVersion={{
        currentVersion: INVOICE_VERSION_V201910,
        versionPaths: INVOICE_PATHS_FUNCTIONS.user(props.match.params.id),
        versionOptions: INVOICE_VERSION_OPTIONS_123
      }}
    >
      <EditUserForm {...props} />
      <NavigationTransitionPrompt />
    </AdminTemplate201910>
  );
};

export default EditUser;
