import * as React from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";

import MuiRadioButtons from "@components/molecules/MuiRadioButtons";
import { RadioItemInterface } from "@components/atoms/RadioButtons";
import { useEffect, useState } from "react";
import {
  PARAMETER_TYPE_KEIKAKUSODAN,
  PARAMETER_TYPE_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    dialogHeader: {
      marginBottom: 4,
      padding: "16px 24px 20px",
      color: "#333",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      height: 64
    },
    dialogContent: {
      minWidth: 576,
      height: "100%",
      padding: 0,
      "&::-webkit-scrollbar": { display: "none" }
    },
    dialogSubTitle: {
      fontFamily: "HiraginoSans-W3",
      fontSize: 12,
      margin: "24px 0 0 42px",
      lineHeight: 1.33,
      color: "rgba(0, 0, 0, 0.6)"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      marginLeft: 0,
      marginRight: 0
    },
    checkboxLi: {
      margin: "12px 0 0 24px"
    },
    buttonConfirm: {
      width: 206,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 32
      }
    },
    buttonCancel: {
      width: 120,
      height: 36,
      boxShadow: "none",
      "&:last-child": {
        marginRight: 32
      }
    },
    space: {
      marginLeft: 20
    },
    radioLabel: {
      fontSize: 16
    }
  });

type Props = {
  isModalOpen: boolean;
  copyTitle: string;
  isNew: boolean;
  transitionUrl: string;
  onClose: () => void;
  defaultValue: string;
} & WithStyles<typeof styles>;

const ConsultationSwitchDialogComponent = (props: Props): JSX.Element => {
  const [queryParameter, setQueryParameter] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>(
    props.defaultValue
  );

  useEffect(() => {
    if (props.isModalOpen) setSelectedOption(props.defaultValue);
  }, [props.defaultValue, props.isModalOpen]);

  useEffect(() => {
    if (selectedOption === `${TYPE_CONSULTATION_KEIKAKUSODAN}`) {
      setQueryParameter(PARAMETER_TYPE_KEIKAKUSODAN);
    } else if (selectedOption === `${TYPE_CONSULTATION_SHOGAIJISODAN}`) {
      setQueryParameter(PARAMETER_TYPE_SHOGAIJISODAN);
    }
  }, [selectedOption]);

  const onCloseModal = (): void => {
    props.onClose();
  };

  const options: RadioItemInterface[] = [
    { label: "計画相談支援", value: "1" },
    { label: "障害児相談支援", value: "2" }
  ];

  return (
    <div>
      <Dialog open={props.isModalOpen} disableBackdropClick>
        <DialogTitle className={props.classes.dialogHeader}>
          <span className={props.classes.space}>
            {props.isNew ? "新規作成" : props.copyTitle}
          </span>
        </DialogTitle>
        <DialogContent className={props.classes.dialogContent}>
          <>
            <div className={props.classes.dialogSubTitle}>
              どちらの種別として作成するか選択してください。
            </div>
            <div className={props.classes.checkboxLi}>
              <div className={props.classes.checkbox}>
                <MuiRadioButtons
                  labelTextClass={props.classes.radioLabel}
                  label=""
                  options={options}
                  value={selectedOption}
                  onChange={(_, value): void => setSelectedOption(value)}
                />
              </div>
            </div>
          </>
        </DialogContent>
        <DialogActions className={props.classes.dialogFooter}>
          <KnowbeButton
            className={props.classes.buttonCancel}
            kind="outline"
            onClick={onCloseModal}
          >
            キャンセル
          </KnowbeButton>
          <KnowbeButton
            className={props.classes.buttonConfirm}
            variant="contained"
            color="secondary"
            href={`${props.transitionUrl}?type=${queryParameter}`}
          >
            確定して編集画面に進む
          </KnowbeButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const ConsultationSwitchDialog = withStyles(styles)(
  ConsultationSwitchDialogComponent
);
