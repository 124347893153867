import { SupportReportValues } from "@initialize/mgr/SHUROTEICHAKU/record/supportReport/initialValues";
import { PostSupportReportParams } from "@api/requests/supportReport/postSupportReport";
import { StaffState } from "@stores/domain/staff/types";
import getStaffName from "@utils/domain/staffs/getStaffName";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import { selectDateValueToDatePaddingZero } from "@utils/date";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";
import deepEqual from "fast-deep-equal";

/**
 * 差分だけピックしたフォームの値をPostSupportReportが受け付ける値に変換する
 */
const supportReportMapping = (
  supportReportValue: Partial<SupportReportValues["support_report"]>,
  staff: StaffState
): PostSupportReportParams["support_report"] => {
  const supportReportParams = {
    ...supportReportValue
  } as PostSupportReportParams["support_report"];

  if (supportReportValue.author === "") {
    supportReportParams.author = null;
    supportReportParams.author_name = null;
  } else if (supportReportValue.author) {
    supportReportParams.author = Number(supportReportValue.author) || 0;
    supportReportParams.author_name = getStaffName(
      staff,
      supportReportValue.author
    );
  }

  if (supportReportValue.creation_date) {
    supportReportParams.creation_date = selectDateValueToDatePaddingZero(
      supportReportValue.creation_date
    );
  }

  if (supportReportValue.working_start_date) {
    supportReportParams.working_start_date = selectDateValueToDatePaddingZero(
      supportReportValue.working_start_date
    );
  }

  if (supportReportValue.presentation_date) {
    supportReportParams.presentation_date = selectDateValueToDatePaddingZero(
      supportReportValue.presentation_date
    );
  }

  if (supportReportValue.situations) {
    supportReportParams.situations = supportReportValue.situations.map(
      (value) => ({
        id: value.id || null,
        support_reports_id: value.support_reports_id || null,
        number: value.number,
        content: value.content || null,
        result: value.result || null
      })
    );
  }

  return supportReportParams;
};

const normalizeFormValuesToPostSupportReportNew = (
  value: SupportReportValues
): PostSupportReportParams => {
  return {
    usage_results: value.usage_results
      .filter((result) => !result.display_form_flg)
      .map((result) => ({
        id: result.id,
        uif_id: result.uif_id,
        display_form_flg: castCheckBoxToNumber(result.display_form_flg)
      })),
    support_report: {
      facility_id: value.support_report.facility_id,
      uif_id: value.support_report.uif_id,
      creation_date: selectDateValueToDatePaddingZero(
        value.support_report.creation_date
      ),
      working_start_date: selectDateValueToDatePaddingZero(
        value.support_report.working_start_date
      ),
      company_name: value.support_report.company_name || null,
      responsible_person: value.support_report.responsible_person || null,
      goal_1: value.support_report.goal_1 || null,
      goal_2: value.support_report.goal_2 || null,
      goal_3: value.support_report.goal_3 || null,
      future_support_content:
        value.support_report.future_support_content || null,
      person_behavior: value.support_report.person_behavior || null,
      facility_behavior: value.support_report.facility_behavior || null,
      related_organizations_behavior:
        value.support_report.related_organizations_behavior || null,
      shared_content: value.support_report.shared_content || null,
      presentation_date: selectDateValueToDatePaddingZero(
        value.support_report.presentation_date
      ),
      author: Number(value.support_report.author) || null,
      author_name: value.support_report.author_name || null,
      situations: value.support_report.situations
        .filter((situation) => situation.content || situation.result)
        .map((item) => ({
          id: null,
          support_reports_id: null,
          number: item.number,
          content: item.content,
          result: item.result
        }))
    }
  };
};

/**
 * postUserDetailSupportRecordに渡すparamsの変換
 */
export const normalizeFormValuesToPostSupportReportParams = (
  value: SupportReportValues,
  initialValues: SupportReportValues,
  staff: StaffState
): PostSupportReportParams => {
  let params = {} as PostSupportReportParams;

  // 新規作成時
  if (!value.support_report.id) {
    params = normalizeFormValuesToPostSupportReportNew(value);
  } else {
    // 変更のないプロパティを除外する
    const changedUSupportReport = omitByNoChanges(
      value.support_report,
      initialValues.support_report
    );

    const changedUsageResults = value.usage_results.filter(
      (result, i) =>
        result.display_form_flg !==
        initialValues.usage_results[i].display_form_flg
    );

    // 「support_report」
    if (
      changedUSupportReport &&
      Object.keys(changedUSupportReport).length !== 0
    ) {
      // 必要なデータの復元
      changedUSupportReport.id = value.support_report.id;
      changedUSupportReport.uif_id = value.support_report.uif_id;
      changedUSupportReport.facility_id = value.support_report.facility_id;

      const situations: SupportReportValues["support_report"]["situations"] = [];
      value.support_report.situations.forEach((newValue, index) => {
        const oldValue = initialValues.support_report.situations[index];

        if (oldValue === undefined || deepEqual(newValue, oldValue)) {
          return;
        }

        situations.push(newValue);
      });

      if (situations.length > 0) {
        changedUSupportReport.situations = situations;
      }

      params.support_report = supportReportMapping(
        changedUSupportReport,
        staff
      );
    }

    // 「usage_results」
    if (changedUsageResults.length > 0) {
      params.usage_results = changedUsageResults.map((v) => {
        return {
          id: v.id,
          uif_id: v.uif_id,
          display_form_flg: castCheckBoxToNumber(v.display_form_flg)
        };
      });
    }
  }
  return params;
};
