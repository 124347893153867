import * as React from "react";
import {
  withStyles,
  WithStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";

// ui
import { TableRowStartEndIndices } from "@components/organisms/download/print/previewHOC";
import { SupportContentPrint } from "@components/organisms/mgr/Cseg/record/SupportContentPrint";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { SupportRecordUserState } from "@stores/domain/mgr/Cseg/support/types";
import { UsersInFacilityState as KEIKAKUSODANUsersInFacilityState } from "@stores/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { UsersInFacilityState as CHIIKIIKOUsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import { UsersInFacilityState as CHIIKITEICHAKUUsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";

// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { FacilityType } from "@constants/variables";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

// utils
import { getUrlParams } from "@utils/url";
import dateToObject from "@utils/date/dateToObject";

const TABLE_HEAD_HEIGHT = 25;

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      }
    },
    title: {
      fontSize: "18px",
      marginBottom: "16px",
      fontWeight: "normal",
      letterSpacing: 0.9,
      color: "#212121"
    },
    subTitle: {
      fontSize: 12,
      lineHeight: 1.4,
      color: "#212121",
      marginBottom: "16px",
      display: "flex",
      columnGap: "1rem"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 8
    },
    table: {
      tableLayout: "fixed",
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        overflowWrap: "break-word",
        padding: "4px 5px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        "&.label": {
          textAlign: "center"
        },
        "&.W54": {
          width: 54
        },
        "&.W84": {
          width: 84
        },
        "&.W86": {
          width: 86
        },
        "&.topAlign": {
          verticalAlign: "top"
        },
        "&.topCenterAlign": {
          verticalAlign: "top",
          textAlign: "left",
          wordWrap: "break-word"
        }
      },
      "& tr": {
        borderBottom: "1px solid"
      }
    },
    "@media print": {
      page: {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

/**
 * interface
 */

type StateProps = {
  facilityType: FacilityType;
  userInFacility:
    | KEIKAKUSODANUsersInFacilityState["user"]["user_in_facility"]
    | CHIIKIIKOUsersInFacilityState["user"]["user_in_facility"]
    | CHIIKITEICHAKUUsersInFacilityState["user"]["user_in_facility"];
  supportRecord: SupportRecordUserState;
};
type OwnProps = {
  uifId: string;
  year: string;
  month: string;
  query: string;
};

type DispatchProps = {
  fetchSupportRecordUser: (uifId: string, year: string, month: string) => void;
  fetchOneUser: (id: string, facilityType: FacilityType) => void;
  fetchKEIKAKUSODANFacility: () => void;
};

type Props = StateProps & OwnProps & DispatchProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  displayComment: string[];
  userInFacility: StateProps["userInFacility"];
  year: string;
  month: string;
  facilityType: FacilityType;
  supportRecord: SupportRecordUserState;
  tableRowStartEndIndexInSheet: TableRowStartEndIndices;
  classes: Record<string, string>;
};

type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

const SupportCsegPrintCore = (props: Props): JSX.Element | null => {
  const [displayComment, setDisplayComment] = React.useState([] as string[]);
  const [renderFlg, setRenderFlg] = React.useState(false);

  const {
    uifId,
    year,
    month,
    query,
    classes,
    facilityType,
    userInFacility,
    supportRecord
  } = props;

  React.useEffect(() => {
    props.fetchOneUser(uifId, facilityType);
    props.fetchSupportRecordUser(uifId, year, month);
    const queryParameters: { display_comment?: string } = getUrlParams(query);
    if (queryParameters.display_comment) {
      setDisplayComment(queryParameters.display_comment.split(","));
    }
    setRenderFlg(true);
    if (props.facilityType === FacilityType.KEIKAKUSODAN) {
      props.fetchKEIKAKUSODANFacility();
    }
  }, []);

  if (!renderFlg || !userInFacility.name_mei) {
    return null;
  }

  const tableRowStartEndIndexInSheet = {
    startIndex: 0,
    endIndex: supportRecord.support_records.length
  };

  return (
    <Sheet
      classes={classes}
      displayComment={displayComment}
      facilityType={facilityType}
      userInFacility={userInFacility}
      year={year}
      month={month}
      supportRecord={supportRecord}
      tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
    />
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const {
    classes,
    facilityType,
    userInFacility,
    displayComment,
    year,
    month,
    supportRecord,
    tableRowStartEndIndexInSheet
  } = props;

  const { startIndex, endIndex } = tableRowStartEndIndexInSheet;
  const isShogaijiType =
    supportRecord.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN;

  return (
    <div className={classes.page}>
      <div>
        <header>
          <div className={classes.title}>支援記録</div>
        </header>
        <div className={classes.subTitle}>
          <span>{`${year}年${month}月分`}</span>
          <span>{`${userInFacility.name_sei} ${userInFacility.name_mei}`}</span>
          {isShogaijiType ? <span>（障害児）</span> : null}
        </div>
        {supportRecord.support_records.length > 0 && (
          <div className={classes.flexContainer}>
            <table className={`${classes.table} fullWidth`}>
              <tbody>
                <tr style={{ height: TABLE_HEAD_HEIGHT }}>
                  <td className="label W86">日付</td>
                  <td className="label">支援内容</td>
                  <td className="label W84">記録者</td>
                  <td className="label W54">確認印</td>
                </tr>
                {supportRecord.support_records.map((record, index) => {
                  if (index < startIndex || index > endIndex) return null;
                  // 支援記録の入力がないものは表示しない
                  if (
                    !record.support_content &&
                    (!record.staff_comment ||
                      !displayComment.includes("staff_comment")) &&
                    (!record.staffs || record.staffs.length === 0)
                  )
                    return null;

                  // 日付
                  if (!record.target_date) return null;
                  const targetDate = dateToObject(new Date(record.target_date));

                  // 記録者
                  const staffName = record.staffs
                    ? record.staffs
                        .map((item) => item.snapshot_name || item.name)
                        .join("、")
                    : "";

                  return (
                    <tr key={`tr-${targetDate.month}_${targetDate.day}`}>
                      <td className="label topCenterAlign">
                        {`${targetDate.month}/${targetDate.day}(${targetDate.day_of_week})`}
                      </td>
                      <td className="topAlign">
                        <SupportContentPrint
                          displayComment={displayComment}
                          status={record.status}
                          id={record.id}
                          facilityType={facilityType}
                          supportContent={record.support_content}
                          staffComment={record.staff_comment}
                          typeConsultation={supportRecord.type_consultation}
                        />
                      </td>
                      <td className="topCenterAlign">{staffName}</td>
                      <td className="label" />
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state: AppState): StateProps => {
  const { user, Cseg } = state;
  const facilityType = user.facility_type;

  return {
    facilityType,
    userInFacility: state[facilityType].userInFacility.user.user_in_facility,
    supportRecord: Cseg.supportRecordUser
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { Cseg, KEIKAKUSODAN } = dispatches;

  return {
    fetchOneUser: (id: string, facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportRecordUser: (
      uifId: string,
      year: string,
      month: string
    ): void => {
      Cseg.supportRecordUserDispatcher(dispatch).fetchSupportRecordUser(
        uifId,
        year,
        month
      );
    },
    fetchKEIKAKUSODANFacility: (): void => {
      KEIKAKUSODAN.facilityDispatcher(dispatch).fetch();
    }
  };
};

export const SupportCsegPrint = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(SupportCsegPrintCore));
