import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { ConsultationState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import { CONSULTATION_TARGET_FRG } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      width: "100%",
      "& td": {
        verticalAlign: "initial",
        padding: "4px 6px",
        borderRight: "1px solid",
        borderTop: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        "&:not(:first-of-type)": {
          overflowWrap: "anywhere"
        },
        "&.headLabelWrapper": {
          padding: "0px",
          backgroundColor: "#f5f5f5",
          verticalAlign: "middle",
          height: "33px",
          textAlign: "center"
        },
        "& p": {
          margin: "0px",
          fontSize: "10px",
          transform: " scale(0.8)",
          lineHeight: 1.4,
          letterSpacing: "0.4px",
          color: "#212121",
          backgroundColor: "#f5f5f5",
          whiteSpace: "nowrap"
        },

        "&.index": {
          textAlign: "right",
          backgroundColor: "#f5f5f5"
        },
        "&.w29": {
          width: "29px"
        },
        "&.w54": {
          width: "54px"
        },
        "&.w146": {
          width: "146px"
        },
        "&.w150": {
          width: "150px"
        },
        "&.w156": {
          width: "156px"
        },
        "&.w180": {
          width: "180px"
        },
        "&.w294": {
          width: "294px"
        },
        "&.h22": {
          height: "22px"
        }
      },
      "& tr": {
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    }
  });

type OwnProps = {
  targetFlg: number;
  details: {
    record: ConsultationState["consultationRecord"]["supportPlanConsultationDetails"][0];
    number: number;
  }[];
} & WithStyles<typeof styles>;
type Props = OwnProps;

const PriorityPrintContentCore = (props: Props): JSX.Element => {
  const { classes, targetFlg, details } = props;
  return targetFlg === CONSULTATION_TARGET_FRG.CONSULTATION ? (
    <table className={ClassNames(classes.table, classes.MT16)}>
      <thead>
        <tr>
          <td className="headLabelWrapper w29" rowSpan={2}>
            <p>
              優先
              <br />
              順位
            </p>
          </td>
          <td className="headLabelWrapper w150" rowSpan={2}>
            <p>
              解決すべき課題
              <br />
              （本人のニーズ）
            </p>
          </td>
          <td className="headLabelWrapper w156" rowSpan={2}>
            <p>支援目標</p>
          </td>
          <td className="headLabelWrapper w54" rowSpan={2}>
            <p>達成時期</p>
          </td>
          <td className="headLabelWrapper w294 h22" colSpan={2}>
            <p>福祉サービス等</p>
          </td>
          <td className="headLabelWrapper w150" rowSpan={2}>
            <p>
              課題解決のための
              <br />
              本人の役割
            </p>
          </td>
          <td className="headLabelWrapper w54" rowSpan={2}>
            <p>評価時期</p>
          </td>
          <td className="headLabelWrapper w150" rowSpan={2}>
            <p>その他留意事項</p>
          </td>
        </tr>
        <tr>
          <td className="headLabelWrapper w146 h22">
            <p>種類・内容・量（頻度・時間）</p>
          </td>
          <td className="headLabelWrapper w146 h22">
            <p>提供事業者名（担当者名・電話）</p>
          </td>
        </tr>
      </thead>
      <tbody>
        {details.map(({ record, number }) => {
          return (
            <tr key={`priority_content_${number}`}>
              <td className="index">
                <p>{number}</p>
              </td>
              <td>{record.needs}</td>
              <td>{record.support_goal}</td>
              <td>{record.achievement_time}</td>
              <td>{record.welfare_service_type}</td>
              <td>{record.provider_name}</td>
              <td>{record.role_in_problem_solving}</td>
              <td>{record.evaluation_time}</td>
              <td>{record.other_considerations}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  ) : (
    <table className={ClassNames(classes.table, classes.MT16)}>
      <thead>
        <tr>
          <td className="headLabelWrapper w29">
            <p>
              優先
              <br />
              順位
            </p>
          </td>
          <td className="headLabelWrapper w180">
            <p>
              解決すべき課題
              <br />
              （本人のニーズ）
            </p>
          </td>
          <td className="headLabelWrapper w180">
            <p>支援目標</p>
          </td>
          <td className="headLabelWrapper w54">
            <p>達成時期</p>
          </td>
          <td className="headLabelWrapper w180">
            <p>
              福祉サービス等
              <br />
              種類・内容・量（頻度・時間）
            </p>
          </td>
          <td className="headLabelWrapper w180">
            <p>
              課題解決のための
              <br />
              本人の役割
            </p>
          </td>
          <td className="headLabelWrapper w54">
            <p>評価時期</p>
          </td>
          <td className="headLabelWrapper w180">
            <p>その他留意事項</p>
          </td>
        </tr>
      </thead>
      <tbody>
        {details.map(({ record, number }) => {
          return (
            <tr key={`priority_content_${number}`}>
              <td className="index">
                <p>{number}</p>
              </td>
              <td>{record.needs}</td>
              <td>{record.support_goal}</td>
              <td>{record.achievement_time}</td>
              <td>{record.welfare_service_type}</td>
              <td>{record.role_in_problem_solving}</td>
              <td>{record.evaluation_time}</td>
              <td>{record.other_considerations}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const PriorityPrintContent = withStyles(styles)(
  PriorityPrintContentCore
);
