import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetOperationsAndSupports = {
  data: {
    summary: {
      facility_unit_id: number;
      counts: {
        status_type: {
          implement: number;
        };
        meal: {
          custom_record_item_id: number;
          choices: {
            id: number;
            name: string;
            count: number;
          }[];
        };
      };
    }[];
    operation: {
      facility_unit_id: number;
      operation_records_id: number;
      operation_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked?: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    support: {
      user_in_facility_id: number;
      support_records_id: number;
      pickup: number;
      support_record_input: {
        id: number;
        custom_record_item_id: number;
        input_data?: string | null;
        choiced_item_id?: number;
        choiced_staff_id?: number;
        checked?: number;
        choiced_staff_name_snapshot?: string;
      }[];
    }[];
    created_at: string | null;
    updated_at: string | null;
  };
};

/**
 * 指定日の業務記録と支援記録を取得する
 * @param yyyymmdd 日付
 * @param facility_unit_id ユニットID 0は事業所全体
 */
const getOperationsAndSupports = async (
  yyyymmdd: string,
  facility_unit_id = 0
): Promise<AxiosResponse<GetOperationsAndSupports>> => {
  const url = `${VERSION_URL_201910}/operations/${yyyymmdd}?facility_unit_id=${facility_unit_id}`;
  return request.get<GetOperationsAndSupports>(url);
};

export default getOperationsAndSupports;
