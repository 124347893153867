import { BasicErrors } from "@interfaces/mgr/GroupHome/facility/basic";
import { SubtractionItemErrors } from "@interfaces/mgr/GroupHome/facility/subtractionItem";
import { AdditionalItemErrors } from "@interfaces/mgr/GroupHome/facility/additionItem";
import { UnitsErrors } from "@interfaces/mgr/GroupHome/facility/units";
import { FacilityValues } from "@initialize/mgr/GroupHome/facility/initialValues";
import validator, { dateValidator, validateSwitcher } from "@validator";
import notSelectedToEmpty from "@utils/dataNormalizer/notSelectedToEmpty";
import {
  DEFAULT_SELECT_VALUE,
  NO_SELECTED_CONDITION_TYPE_2024_SUB,
  SELECTED_CONDITION_TYPE_2024_V
} from "@constants/variables";

type FacilityErrors = BasicErrors &
  SubtractionItemErrors &
  AdditionalItemErrors;

const basicValidation = (values: FacilityValues): BasicErrors => {
  return {
    basic: {
      corporationName: validator(values.basic.corporationName, "required"),
      officeNumber: validator(
        values.basic.officeNumber,
        "required",
        "naturalNumber",
        { type: "checkDigits", digits: 10 }
      ),
      officeName: validator(values.basic.officeName, "required"),
      representativeName: validator(
        values.basic.representativeName,
        "required"
      ),
      capacity: validator(values.basic.capacity, "required", "naturalNumber"),
      postalCode: validator(values.basic.postalCode, "required", "postalCode"),
      prefectureId: validator(values.basic.prefectureId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      cityId: validator(values.basic.cityId, {
        type: "selectRequired",
        value: DEFAULT_SELECT_VALUE
      }),
      restAddress: validator(values.basic.restAddress, "required"),
      tel: validator(values.basic.tel, "required", "naturalNumber")
    }
  };
};

const subtractionItemValidation = (
  values: FacilityValues
): SubtractionItemErrors => {
  const memberDate = values.subtractionItem.lackOfLifeSupportMemberStartDate;
  const personDate = values.subtractionItem.lackOfResponsiblePersonStartDate;
  const selectDateRules: { type: "selectRequired"; value: string } = {
    type: "selectRequired",
    value: DEFAULT_SELECT_VALUE
  };
  return {
    subtractionItem: {
      lackOfLifeSupportMemberStartDate: {
        year: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(memberDate.year, selectDateRules)
        ),
        // 日付の初期値が空文字のため、validationの初期値に合わせる
        month: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(
            memberDate.month === "" ? DEFAULT_SELECT_VALUE : memberDate.month,
            selectDateRules
          )
        ),
        day: validateSwitcher(
          values.subtractionItem.lackOfLifeSupportMemberFlag,
          validator(memberDate.day, selectDateRules)
        )
      },
      lackOfResponsiblePersonStartDate: {
        year: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(personDate.year, selectDateRules)
        ),
        // 日付の初期値が空文字のため、validationの初期値に合わせる
        month: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(
            personDate.month === "" ? DEFAULT_SELECT_VALUE : personDate.month,
            selectDateRules
          )
        ),
        day: validateSwitcher(
          values.subtractionItem.lackOfResponsiblePersonFlag,
          validator(personDate.day, selectDateRules)
        )
      },
      bodyRestrictedStillFrom: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillFrom),
          "required"
        )
      ),
      bodyRestrictedStillTo: validateSwitcher(
        values.subtractionItem.bodyRestrictedStillFlg,
        dateValidator(
          notSelectedToEmpty(values.subtractionItem.bodyRestrictedStillTo),
          {
            type: "future",
            startDate: values.subtractionItem.bodyRestrictedStillFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      abusePreventionMeasuresNotImplementedFrom: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedFrom
          ),
          "required"
        )
      ),
      abusePreventionMeasuresNotImplementedTo: validateSwitcher(
        values.subtractionItem.abusePreventionMeasuresNotImplementedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.abusePreventionMeasuresNotImplementedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.abusePreventionMeasuresNotImplementedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      workContinuationNotPlanningFrom: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningFrom
          ),
          "required"
        )
      ),
      workContinuationNotPlanningTo: validateSwitcher(
        values.subtractionItem.workContinuationNotPlanningFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.workContinuationNotPlanningTo
          ),
          {
            type: "future",
            startDate: values.subtractionItem.workContinuationNotPlanningFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      informationDisclosureNotReportedFrom: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedFrom
          ),
          "required"
        )
      ),
      informationDisclosureNotReportedTo: validateSwitcher(
        values.subtractionItem.informationDisclosureNotReportedFlg,
        dateValidator(
          notSelectedToEmpty(
            values.subtractionItem.informationDisclosureNotReportedTo
          ),
          {
            type: "future",
            startDate:
              values.subtractionItem.informationDisclosureNotReportedFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};

/**
 * 加算対象項目
 */
const additionItemValidation = (
  values: FacilityValues
): AdditionalItemErrors => {
  // ２つ以上のユニットを運営している」が有効でない時に、夜間支援体制加算を有効にしたらチェック
  const hasNightSupport =
    !values.basic.operatingUnitFlag && values.additionalItem.nightSupportFlag;
  return {
    additionalItem: {
      nightSupportType: validateSwitcher(
        hasNightSupport,
        validator(values.additionalItem.nightSupportType, "required")
      ),
      averageUsersLastYear: validateSwitcher(
        hasNightSupport,
        validator(
          values.additionalItem.averageUsersLastYear,
          "required",
          "naturalNumber"
        )
      ),
      welfareConditionEndDate: validateSwitcher(
        values.additionalItem.welfareSpecialistPlacementType !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.welfareConditionEndDate),
          {
            type: "future",
            startDate: values.additionalItem.welfareConditionStartDate,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      specificBetterSupporterCondition202404:
        values.additionalItem.specificBetterSupporterCondition202404 ===
          `${SELECTED_CONDITION_TYPE_2024_V}` &&
        values.additionalItem.specificBetterSupporterCondition202404Sub ===
          `${NO_SELECTED_CONDITION_TYPE_2024_SUB}`
          ? "福祉・介護職員等処遇改善（Ⅴ）以下の項目を選択してください"
          : undefined,
      seeHearTeam202404From: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404From),
          "required"
        )
      ),
      seeHearTeam202404To: validateSwitcher(
        values.additionalItem.seeHearTeam202404 !== "1",
        dateValidator(
          notSelectedToEmpty(values.additionalItem.seeHearTeam202404To),
          {
            type: "future",
            startDate: values.additionalItem.seeHearTeam202404From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      higherBrainDysfunctionPersonsSupportFrom: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportFrom
          ),
          "required"
        )
      ),
      higherBrainDysfunctionPersonsSupportTo: validateSwitcher(
        values.additionalItem.higherBrainDysfunctionPersonsSupportFlg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.higherBrainDysfunctionPersonsSupportTo
          ),
          {
            type: "future",
            startDate:
              values.additionalItem.higherBrainDysfunctionPersonsSupportFrom,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      improvingInfectionControl1From: validateSwitcher(
        values.additionalItem.improvingInfectionControl1Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl1From
          ),
          "required"
        )
      ),
      improvingInfectionControl1To: validateSwitcher(
        values.additionalItem.improvingInfectionControl1Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl1To
          ),
          {
            type: "future",
            startDate: values.additionalItem.improvingInfectionControl1From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      ),
      improvingInfectionControl2From: validateSwitcher(
        values.additionalItem.improvingInfectionControl2Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl2From
          ),
          "required"
        )
      ),
      improvingInfectionControl2To: validateSwitcher(
        values.additionalItem.improvingInfectionControl2Flg,
        dateValidator(
          notSelectedToEmpty(
            values.additionalItem.improvingInfectionControl2To
          ),
          {
            type: "future",
            startDate: values.additionalItem.improvingInfectionControl2From,
            options: {
              startLabel: "開始年月日",
              endLabel: "終了年月日"
            }
          }
        )
      )
    }
  };
};

/**
 *  GHユニット
 * 「２つ以上のユニットを運営している」が有効な時に実行する
 */
const unitsValidation = (values: FacilityValues): UnitsErrors => {
  const units = values.basic.operatingUnitFlag
    ? values.units.map((unit) => ({
        unit_name: validateSwitcher(
          !unit.is_deleted,
          validator(unit.unit_name, "required", {
            type: "checkCharacterLength",
            length: 30
          })
        ),
        night_support_type: validateSwitcher(
          !unit.is_deleted && values.additionalItem.nightSupportFlag,
          validator(unit.night_support_type, "required")
        ),
        ave_users_last_fiscal_year: validateSwitcher(
          !unit.is_deleted &&
            values.additionalItem.nightSupportFlag &&
            unit.night_support_type !== "1",
          validator(
            unit.ave_users_last_fiscal_year,
            "required",
            "naturalNumber"
          )
        )
      }))
    : [];
  return { units };
};

export const validation = (values: FacilityValues): FacilityErrors => {
  const basicErrors = basicValidation(values);
  const subtractionItemErrors = subtractionItemValidation(values);
  const additionalItemErrors = additionItemValidation(values);
  const unitsErrors = unitsValidation(values);
  return {
    ...basicErrors,
    ...subtractionItemErrors,
    ...additionalItemErrors,
    ...unitsErrors
  };
};
