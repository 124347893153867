import React, { useEffect, useState } from "react";

import { Formik, Form, FormikConfig } from "formik";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import * as H from "history";
import isEqual from "lodash-es/isEqual";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";

import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import { MunicipalityFields } from "@components/organisms/mgr/IDOSHIEN/Municipality/MunicipalityFields";
import ErrorsDialog from "@components/organisms/ErrorsDialog";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import {
  initialValuesEdit,
  initialValuesNew,
  MunicipalityValues
} from "@initialize/mgr/IDOSHIEN/Municipality/initialValues";
import { validation } from "@initialize/mgr/IDOSHIEN/Municipality/validation";
import { toEffectiveObject } from "@utils/object";
import numberToBoolean from "@utils/dataNormalizer/numberToBoolean";
import { MUNICIPALITY } from "@constants/url";
import dispatches from "@stores/dispatches";
import { UserState } from "@stores/domain/user/type";
import { AppState } from "@stores/type";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { GetFacilityMunicipalitiesTargetIdResponse } from "@api/requests/facility/getFacilityMunicipalitiesTargetId";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import DeleteButton from "@components/atoms/buttons/DeleteButton";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import { checkCsvError } from "@utils/domain/mgr/IDOSHIEN/municipality/errorResponse";
import * as URL from "@constants/url";
import { SnackbarParams } from "@stores/ui/type";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      height: "auto"
    },
    headerActionArea: {
      marginTop: 12,
      marginLeft: 16,
      marginRight: 16,
      display: "flex",
      justifyContent: "space-between"
    },
    paper: {
      marginTop: 20,
      marginLeft: 16,
      marginRight: 16,
      marginBottom: 16,
      padding: 32,
      backgroundColor: "#fff",
      borderRadius: "4px"
    },
    sectionTitle: {
      width: "100%",
      margin: 0,
      paddingBottom: "6px",
      fontSize: "20px",
      fontWeight: "normal",
      color: "#37474f",
      borderBottom: "1px solid rgba(0, 0, 0, 0.54)"
    },
    sectionDescription: {
      margin: 0,
      marginTop: 32,
      fontSize: "16px",
      color: "#37474f",
      lineHeight: 1.75
    },
    municipalityFieldsWrapper: {
      marginTop: 32
    },
    ML16: {
      marginLeft: 16
    }
  });

type OwnProps = {
  /** 自治体ID */
  municipalityId?: string;
  history: H.History;
};

type StateProps = {
  user: UserState;
  needsStopHistory: boolean;
  municipality: GetFacilityMunicipalitiesTargetIdResponse["data"]["municipality"];
};

type DispatchProps = {
  me: () => Promise<void>;
  stopHistory: (flag: boolean) => Promise<void>;
  postMunicipality: (
    value: MunicipalityValues,
    hasInvoiceAuthority: boolean,
    municipalityId: number | null,
    initialValues?: MunicipalityValues
  ) => Promise<void>;
  clearMunicipality: () => Promise<void>;
  fetchMunicipality: (id: string) => void;
  deleteMunicipality: (id: string) => Promise<boolean>;
  showErrorDialog: () => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type MunicipalityFormik = FormikConfig<MunicipalityValues>;

/**
 * 自治体情報の新規・編集画面のコンポーネント
 */
const MunicipalityFormCore = (props: Props): JSX.Element => {
  const isNew = props.municipalityId === undefined;
  const [fieldValues, setFieldValues] = useState<MunicipalityValues>();
  // アカウントの請求権限
  const [hasInvoiceAuthority, setHasInvoiceAuthority] = useState<boolean>(
    false
  );
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [dialogErrorMessages, setDialogErrorMessages] = useState<string[]>([]);

  useEffect(() => {
    if (isNew) {
      props.clearMunicipality();
      setFieldValues(initialValuesNew());
    } else {
      if (!props.municipalityId) return;
      props.fetchMunicipality(props.municipalityId);
    }
  }, [isNew, props.municipalityId]);

  useEffect(() => {
    if (!isNew) {
      setFieldValues(initialValuesEdit(props.municipality));
    }
  }, [isNew, props.municipality]);

  useEffect(() => {
    if (props.user) {
      const { featureGroup } = props.user;
      setHasInvoiceAuthority(numberToBoolean(featureGroup.group_invoice));
    }
  }, [props.user]);

  const handleClickDeleteButton = (): void => {
    setOpenDeleteDialog(true);
  };

  const handleCancelDeleteDialog = (): void => {
    setOpenDeleteDialog(false);
  };

  const handleDeleteMunicipality = async (): Promise<void> => {
    const isSuccess = await props.deleteMunicipality(
      undefinedToReturnValue(props.municipalityId)
    );
    if (isSuccess) {
      await props.stopHistory(false);
      props.history.push(URL.MUNICIPALITY);
    }
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  const handleClickCancel = (): void => {
    props.history.push(MUNICIPALITY);
  };

  const handleSubmit: MunicipalityFormik["onSubmit"] = async (
    values,
    { setSubmitting }
  ) => {
    const initialValues: MunicipalityValues | undefined = props.municipalityId
      ? fieldValues
      : undefined;
    const municipalityId = props.municipalityId
      ? Number(props.municipalityId)
      : null;
    try {
      await props.postMunicipality(
        values,
        hasInvoiceAuthority,
        municipalityId,
        initialValues
      );
      await props.stopHistory(false);
      props.history.push(MUNICIPALITY);
    } catch (error) {
      if (checkCsvError(error)) {
        if (error.response && error.response.data.response.validation.csv) {
          // CSV側のファイル要件エラー
          setDialogErrorMessages(error.response.data.response.validation.csv);
        }
        if (error.response && error.response.data.response.validation.file) {
          // ファイル入力内容エラー
          setDialogErrorMessages(error.response.data.response.validation.file);
        }
        props.showErrorDialog();
      }
    } finally {
      setSubmitting(false);
    }
  };

  // フォームの差分チェックを行い、離脱モーダルの設定を行う
  const confirmDiscardFormChanges = (nextValues: MunicipalityValues): void => {
    const hasChange = !isEqual(nextValues, fieldValues);
    if (hasChange) {
      props.stopHistory(true);
    }
  };

  const handleValidate = (values: MunicipalityValues): object | void => {
    const errorMessages = validation(values);
    const err = toEffectiveObject(errorMessages);
    if (!props.needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return err;
  };

  if (!fieldValues) {
    return <></>;
  }

  return (
    <>
      <Formik
        initialValues={fieldValues}
        onSubmit={handleSubmit}
        validate={handleValidate}
        enableReinitialize
      >
        {(formikProps): JSX.Element => (
          <Form>
            <ContentHeader
              position="sticky"
              classes={{ wrapper: props.classes.wrapper }}
            >
              <div className={props.classes.headerActionArea}>
                <KnowbeButton kind="outline" onClick={handleClickCancel}>
                  {isNew ? "キャンセル" : "一覧に戻る"}
                </KnowbeButton>
                <FormikSubmitButton
                  formikProps={formikProps}
                  buttonName="保存する"
                  errorAction={submitError}
                />
              </div>
            </ContentHeader>
            <div className={props.classes.paper}>
              <h2 className={props.classes.sectionTitle}>自治体情報</h2>
              <p className={props.classes.sectionDescription}>
                請求する自治体に合わせて算定時間の設定や、サービスコードの登録（記録プランの場合は除く）ができます。
                <br />
                自治体情報の設定を変更した場合でも、登録済みの利用実績とサービス提供記録は変更されません。変更後の設定で登録したい場合は、登録済みの利用実績とサービス提供記録を削除して新たに利用実績を登録してください。
              </p>
              <div className={props.classes.municipalityFieldsWrapper}>
                <MunicipalityFields
                  formikProps={formikProps}
                  hasInvoiceAuthority={hasInvoiceAuthority}
                  municipalityData={props.municipality}
                />
              </div>
            </div>
            {!isNew && (
              <>
                <div className={props.classes.ML16}>
                  <DeleteButton
                    text="削除する"
                    onClick={handleClickDeleteButton}
                  />
                </div>
                <ConfirmDialog
                  isOpen={openDeleteDialog}
                  onDelete={handleDeleteMunicipality}
                  onCancel={handleCancelDeleteDialog}
                  title="自治体情報を削除します"
                  message={
                    <>
                      この操作を実行するとデータが完全に削除され、復元できません。
                      <br />
                      よろしいですか？
                    </>
                  }
                />
              </>
            )}
          </Form>
        )}
      </Formik>
      <ErrorsDialog
        customErrors={dialogErrorMessages}
        customErrorCountSuffix="CSVファイルを修正して再アップロードしてください。"
      />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  user: state.user,
  needsStopHistory: state.ui.needsStopHistory,
  municipality: state.IDOSHIEN.municipalitiesInFacility.municipality
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, IDOSHIEN, userDispatch } = dispatches;
  const uiDispatches = uiDispatch(dispatch);
  const userDispatches = userDispatch(dispatch);
  const municipalityDispatchers = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const municipalitiesInFacilityDispatches = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const showErrorDialog = (): void => {
    dispatch(errorsDialogActions.showErrorsDialog());
  };
  return {
    me: userDispatches.me,
    stopHistory: uiDispatches.stopHistory,
    postMunicipality: municipalityDispatchers.postMunicipality,
    fetchMunicipality: municipalitiesInFacilityDispatches.fetchOne,
    clearMunicipality: municipalitiesInFacilityDispatches.clearOne,
    deleteMunicipality: municipalitiesInFacilityDispatches.deleteMunicipality,
    showErrorDialog,
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params)
  };
};

export const MunicipalityForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(MunicipalityFormCore)
);
