import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import format from "date-fns/format";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import {
  FacilityType,
  DEFAULT_SELECT_VALUE,
  SUPPORT_CARE_PLAN_PAGE_NAME,
  SUPPORT_CARE_PLAN_PRINT_TEXT
} from "@constants/variables";
import { FacilityState as FacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/facility/types";
import { FacilityState as FacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/facility/types";
import { FacilityState as FacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/facility/types";
import { FacilityState as FacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/facility/types";
import { UsersInFacilityState as UsersInFacilityStateKYOTAKUKAIGO } from "@stores/domain/mgr/KYOTAKUKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateJUDOHOMONKAIGO } from "@stores/domain/mgr/JUDOHOMONKAIGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateDOKOENGO } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { GetSupportPlanUifIdCarePlanCarePlanId } from "@api/requests/carePlan/getSupportPlanUifIdCarePlanCarePlanId";
import { CarePlanPrintSchedules } from "@components/organisms/mgr/common/record/carePlan/CarePlanPrintSchedules";
import { CarePlanPrintContentBase } from "@components/organisms/mgr/common/record/carePlan/CarePlanPrintContentBase";
import { CarePlanPrintContentDetails } from "@components/organisms/mgr/common/record/carePlan/CarePlanPrintContentDetails";

const styles = (): StyleRules =>
  createStyles({
    MT8: {
      marginTop: 8
    },
    MT13: {
      marginTop: 13
    },
    MT16: {
      marginTop: 16
    },
    MT32: {
      marginTop: 32
    },
    colorLight: {
      color: "#9e9e9e"
    },
    flex: {
      display: "flex"
    },
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleTextS: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "14px",
      fontWeight: "normal",
      letterSpacing: "0.7px",
      margin: 0
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4,
      display: "flex",
      "&:nth-child(n+2)": {
        marginTop: 4
      },
      "&>span": {
        display: "block",
        minWidth: 88,
        marginLeft: 4
      }
    },
    pageTitleCon: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      marginTop: 8,
      width: "100%"
    },
    title: {
      fontFamily: ["HiraginoSans-W6", "sans-serif"].join(","),
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      paddingBottom: 4,
      borderBottom: "1px solid #000",
      marginBottom: 0
    },
    item: {
      "&+&": {
        marginLeft: 16
      }
    },
    itemLabel: {
      fontSize: "10px",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      color: "#424242",
      transform: " scale(0.8) translateX(-12.5%)"
    },
    itemCon: {
      fontSize: "10px",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      paddingBottom: 5,
      borderBottom: "solid 1px #000",
      marginTop: 4,
      height: "20px"
    },
    itemConRight: {
      textAlign: "right"
    },
    address: {
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px"
    },
    comment: {
      border: "solid 2px #212121",
      display: "flex",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px"
    },
    commentTitle: {
      width: 114,
      padding: 6
    },
    commentCon: {
      width: "calc(100% - 114px)",
      padding: 6,
      borderLeft: "1px solid #000"
    },
    W155: {
      width: 155
    },
    W246: {
      width: 246
    },
    paddingRight: {
      paddingRight: 16
    }
  });

type OwnProps = {
  user:
    | UsersInFacilityStateKYOTAKUKAIGO["user"]
    | UsersInFacilityStateJUDOHOMONKAIGO["user"]
    | UsersInFacilityStateDOKOENGO["user"]
    | UsersInFacilityStateKODOENGO["user"];
  facility:
    | FacilityStateKYOTAKUKAIGO
    | FacilityStateJUDOHOMONKAIGO
    | FacilityStateDOKOENGO
    | FacilityStateKODOENGO;
  cityLabel: string;
  facilityType: FacilityType;
  cityFacilityLabel: string;
  isStaffComment: boolean;
  supportCarePlan: GetSupportPlanUifIdCarePlanCarePlanId["data"];
  supportCarePlanSchedules: GetSupportPlanUifIdCarePlanCarePlanId["data"]["support_care_plan_schedules"];
  isStampIcon: boolean;
} & WithStyles<typeof styles>;
type Props = OwnProps;

const CarePlanPrintContentCore = (props: Props): JSX.Element => {
  const {
    classes,
    user,
    facility,
    cityLabel,
    facilityType,
    cityFacilityLabel,
    isStaffComment,
    supportCarePlan,
    supportCarePlanSchedules,
    isStampIcon
  } = props;

  // 計画作成日
  const targetDate = format(supportCarePlan.target_date, "YYYY年MM月DD日");
  // 計画作成者
  const [author, setAuthor] = React.useState<string>("-");
  React.useEffect(() => {
    if (supportCarePlan.author === null) {
      setAuthor("-");
    } else {
      const name = getSnapOrRealName(supportCarePlan.author, "");
      const role = getSnapOrRealRole(supportCarePlan.author, "");
      setAuthor(`${name}（${role}）`);
    }
  }, [supportCarePlan.author]);

  const officeInfo = (): string => {
    const officeName = facility.officeName === null ? "" : facility.officeName;
    const postalCode =
      facility.postalCode === null || facility.postalCode === ""
        ? ""
        : `〒${facility.postalCode}`;
    const prefectureName =
      facility.selectedPrefectureName === null ||
      facility.selectedPrefectureName === DEFAULT_SELECT_VALUE
        ? ""
        : facility.selectedPrefectureName;
    const restAddress =
      facility.restAddress === null ? "" : facility.restAddress;
    const tel =
      facility.tel === null || facility.tel === ""
        ? ""
        : `tel：${facility.tel}`;

    const prefectureAndCityNameAndRestAddress = [
      prefectureName,
      cityFacilityLabel,
      restAddress
    ]
      .filter(Boolean)
      .join("");

    return [officeName, postalCode, prefectureAndCityNameAndRestAddress, tel]
      .filter(Boolean)
      .join(" ");
  };

  if (user.user_in_facility.name_sei === undefined) {
    return <></>;
  }

  return (
    <>
      {/* 計画書（1） */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextB}>
            {SUPPORT_CARE_PLAN_PAGE_NAME[facilityType]}（1）
          </h2>
          <div>
            <div className={classes.pageTitleTool}>
              計画作成日: <span>{`${targetDate}`}</span>
            </div>
            <div className={classes.pageTitleTool}>
              計画作成者: <span>{`${author}`}</span>
            </div>
          </div>
        </div>
        {/* 基本情報 */}
        <CarePlanPrintContentBase
          cityLabel={cityLabel}
          facilityType={facilityType}
          userInFacility={user.user_in_facility}
          supportCarePlan={supportCarePlan}
        />
        {/* 計画予定書 */}
        <CarePlanPrintSchedules
          facilityType={facilityType}
          supportCarePlan={supportCarePlan}
          supportCarePlanSchedules={supportCarePlanSchedules}
        />
        {/* その他のツール */}
        <div className={ClassNames(classes.flex, classes.MT32)}>
          <div className={ClassNames(classes.item, classes.W155)}>
            <div className={classes.itemLabel}>交付日</div>
            <div
              className={ClassNames(
                classes.itemCon,
                classes.itemConRight,
                classes.paddingRight
              )}
            >
              年{"　　　"}月{"　　　"}日
            </div>
          </div>
          <div className={ClassNames(classes.item, classes.W246)}>
            <div className={classes.itemLabel}>利用者同意署名</div>
            <div
              className={ClassNames(
                classes.itemCon,
                classes.colorLight,
                classes.itemConRight
              )}
            >
              {isStampIcon && <span> 印</span>}
            </div>
          </div>
          <div className={ClassNames(classes.item, classes.W246)}>
            <div className={classes.itemLabel}>
              代理人または立会人等（続柄）
            </div>
            <div
              className={ClassNames(
                classes.itemCon,
                classes.colorLight,
                classes.itemConRight
              )}
            >
              {isStampIcon && <span> 印</span>}
            </div>
          </div>
        </div>
        <p className={ClassNames(classes.address, classes.MT13)}>
          {officeInfo()}
        </p>
      </div>

      {/* 計画書（2） */}
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <h2 className={classes.pageTitleTextS}>
            {SUPPORT_CARE_PLAN_PAGE_NAME[facilityType]}（2）【サービス内容】
          </h2>
          <div className={classes.pageTitleTool}>
            <span>{`計画作成日: ${targetDate}`}</span>
          </div>
          <div className={classes.pageTitleCon}>
            {SUPPORT_CARE_PLAN_PRINT_TEXT[facilityType]}
          </div>
        </div>
        {/* サービス内容 */}
        <CarePlanPrintContentDetails
          facilityType={facilityType}
          supportCarePlanSchedules={supportCarePlanSchedules}
        />
        {/* コメント */}
        {isStaffComment && (
          <div className={ClassNames(classes.comment, classes.MT32)}>
            <span className={classes.commentTitle}>職員コメント</span>
            <span className={classes.commentCon}>
              {supportCarePlan.staff_comment}
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export const CarePlanPrintContent = withStyles(styles)(
  CarePlanPrintContentCore
);
