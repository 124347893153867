import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Select, { SelectProps } from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel, { InputLabelProps } from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FieldWrapper, { FieldSizeName } from "@components/atoms/FieldWrapper";
import { FieldItem } from "@interfaces/ui/form";
import ClassNames from "classnames";

const styles = (): StyleRules =>
  createStyles({
    root: {
      lineHeight: 1.2
    },
    color: {
      "&>label": {
        color: "#37474F!important"
      },
      "&>div": {
        color: "rgba(0, 0, 0, 0.87)!important"
      }
    },
    negativeMarginLabel: {
      marginTop: -16
    }
  });

type OwnProps = {
  name: string;
  label: string;
  options?: FieldItem[];
  helperText?: string;
  emptyText?: string;
  shrink?: boolean;
  size?: FieldSizeName;
  tooltip?: React.ReactElement;
  FormLabelClasses?: InputLabelProps["FormLabelClasses"];
  isSelectablePlaceholder?: boolean; // true：編集モード時にplaceholderを選択できるようにする
  disabledStyle?: boolean;
  negativeMarginLabel?: boolean;
};

export type MuiSelectProps = OwnProps & SelectProps;
type Props = MuiSelectProps & WithStyles<typeof styles>;

const MuiSelect = ({
  classes,
  label,
  options,
  helperText,
  emptyText,
  shrink,
  id,
  error,
  fullWidth,
  size,
  style,
  FormLabelClasses,
  placeholder,
  isSelectablePlaceholder,
  tooltip,
  disabledStyle,
  negativeMarginLabel,
  ...props
}: Props): JSX.Element => {
  // optionsの値が何もない
  const isItemEmpty = !options || options.length === 0;
  let text = helperText;
  if (isItemEmpty && emptyText) {
    text = emptyText;
  }

  return (
    <FieldWrapper size={size} style={style}>
      <FormControl
        required={props.required}
        error={error}
        fullWidth={fullWidth}
        disabled={isItemEmpty}
        className={ClassNames(
          disabledStyle && classes.color,
          negativeMarginLabel && classes.negativeMarginLabel
        )}
      >
        <InputLabel
          htmlFor={id || props.name}
          shrink={shrink}
          style={{
            visibility: !shrink && !!props.value ? "hidden" : "visible"
          }}
          classes={FormLabelClasses}
        >
          {label}
        </InputLabel>
        {tooltip}
        <Select
          id={id || props.name}
          displayEmpty={isSelectablePlaceholder || false}
          {...props}
        >
          {placeholder && isSelectablePlaceholder && (
            <MenuItem value="">{placeholder}</MenuItem>
          )}
          {options &&
            options.map((option) => (
              <MenuItem
                key={`${props.name}-${option.label}-${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
        </Select>
        {text && (
          <FormHelperText classes={{ root: classes.root }}>
            {text}
          </FormHelperText>
        )}
      </FormControl>
    </FieldWrapper>
  );
};

MuiSelect.defaultProps = {
  fullWidth: true,
  shrink: true
};

export default withStyles(styles)(MuiSelect);
