import ValidationErrors from "@interfaces/ui/validationErrors";
import validator from "@validator";
import { SupportRecordsState } from "@stores/domain/mgr/Cseg/support/types";

export const validation = (
  values: SupportRecordsState
): ValidationErrors<SupportRecordsState> => {
  return values.map((item) => {
    return {
      support_content: validator(item.support_content || "", {
        type: "checkCharacterLength",
        length: 2500
      }),
      staff_comment: validator(item.staff_comment || "", {
        type: "checkCharacterLength",
        length: 2500
      })
    };
  });
};
