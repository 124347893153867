/**
 * 年月が未来かどうか判定する(同一月は対象外)
 * @param value
 */
export const futureMonth = (
  startTime: Date,
  endTime: Date,
  options = { startLabel: "開始年月", endLabel: "終了年月" }
): string | undefined => {
  let errorMessage;

  // 日の差を無くす
  startTime.setDate(1);
  endTime.setDate(1);

  if (
    !Number.isNaN(startTime.getTime()) &&
    !Number.isNaN(endTime.getTime()) &&
    endTime.getTime() < startTime.getTime()
  ) {
    errorMessage = `${options.endLabel}は${options.startLabel}より後の年月を入力してください`;
  }

  return errorMessage;
};
