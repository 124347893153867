import * as types from "./types";
import { GetUsersSummary } from "@api/requests/supports/Cseg/getUsersSummary";
import { ResponseErrorState } from "@stores/ui/responseError/types";

export const fetchUsersSummaryStarted = () =>
  ({ type: types.FETCH_USERS_SUMMARY_CSEG_STARTED } as const);
export const fetchUsersSummarySuccess = (res: GetUsersSummary["data"]) =>
  ({
    type: types.FETCH_USERS_SUMMARY_CSEG_SUCCESS,
    payload: res
  } as const);
export const fetchUsersSummaryFailed = (err: {
  error: { response: ResponseErrorState };
}) =>
  ({
    type: types.FETCH_USERS_SUMMARY_CSEG_FAILED,
    error: err
  } as const);
export const unsetUsersSummary = () =>
  ({ type: types.UNSET_USERS_SUMMARY_CSEG } as const);

export type ActionTypes =
  | ReturnType<typeof fetchUsersSummaryStarted>
  | ReturnType<typeof fetchUsersSummarySuccess>
  | ReturnType<typeof fetchUsersSummaryFailed>
  | ReturnType<typeof unsetUsersSummary>;
