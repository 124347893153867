import * as React from "react";

// store
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";
import dispatches from "@stores/dispatches";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { UnitsState } from "@stores/domain/units/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateGH } from "@stores/domain/mgr/GroupHome/dailyOperationsAndSupports/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateTN } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { DailyOperationsAndSupportsState as DailyOperationsAndSupportsStateSN } from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/types";

// constants
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM as SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_COMMON,
  OPERATION_CUSTOM_RECORD_DEFAULT_ITEM,
  FacilityType,
  USAGE_PERFORMANCE_STATUS_TYPE_FULL
} from "@constants/variables";
import { SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM as SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO } from "@constants/mgr/TANKINYUSHO/variables";
import { STATUS_TYPE } from "@constants/mgr/SHISETSUNYUSHO/variables";

// ui
import { DailyPrintOperationCustomRecords } from "@components/organisms/record/print/DailyPrintOperationCustomRecords";
import { DailyPrintSupport } from "@components/organisms/record/print/DailyPrintSupport";
import { DailyPrintSupportTANKINYUSHO } from "@components/organisms/record/print/DailyPrintSupportTANKINYUSHO";

// utils
import { getUrlParams } from "@utils/url";

/**
 * interface
 */
type StateProps = {
  dailyOperationsAndSupportsGH: DailyOperationsAndSupportsStateGH;
  dailyOperationsAndSupportsTN: DailyOperationsAndSupportsStateTN;
  dailyOperationsAndSupportsSN: DailyOperationsAndSupportsStateSN;
  optionalCustomInfo: UnitsState["optionalCustomInfo"];
  customRecords: CustomRecordsState;
  userState: UserState;
};
type DispatchProps = {
  fetchDailyOperationsAndSupportsGH: (
    yyyymmdd: string,
    unitId: number
  ) => Promise<void>;
  fetchDailyOperationsAndSupportsTN: (yyyymmdd: string) => Promise<void>;
  fetchDailyOperationsAndSupportsSN: (
    yyyymmdd: string,
    unitId: number
  ) => Promise<void>;
  fetchCustomRecords: () => void;
  fetchOptionalCustomInfo: () => void;
  fetchUsersGH: () => Promise<void>;
  fetchUsersTN: () => Promise<void>;
  fetchUsersSN: () => Promise<void>;
};
type MergeProps = OwnProps &
  StateProps &
  DispatchProps & {
    dailyOperationsAndSupports:
      | DailyOperationsAndSupportsStateGH
      | DailyOperationsAndSupportsStateTN
      | DailyOperationsAndSupportsStateSN;
    fetchUsers: () => Promise<void>;
  };
type OwnProps = {
  selectedDate: string;
  query: string;
};

const DailyPrintCustomRecordsCore = (props: MergeProps): JSX.Element | null => {
  const [renderFlg, setRenderFlg] = React.useState(false);
  const {
    selectedDate,
    optionalCustomInfo,
    dailyOperationsAndSupports,
    customRecords,
    query,
    userState
  } = props;

  const createItem = ():
    | typeof SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_COMMON
    | typeof SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO => {
    switch (userState.facility_type) {
      case FacilityType.TANKINYUSHO:
        return SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_TANKINYUSHO;
      default:
        // GH, SN
        return SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM_COMMON;
    }
  };
  const SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM = createItem();

  const queryParameters: {
    display_columns?: string;
    facility_unit_id?: string;
    support_facility_unit_id?: string;
  } = getUrlParams(query);

  React.useEffect(() => {
    if (userState.facility_type === FacilityType.GROUP_HOME) {
      Promise.all([
        props.fetchDailyOperationsAndSupportsGH(
          selectedDate,
          queryParameters.support_facility_unit_id
            ? Number(queryParameters.support_facility_unit_id)
            : 0
        ),
        props.fetchCustomRecords(),
        props.fetchOptionalCustomInfo(),
        props.fetchUsers()
      ]).finally(() => {
        setRenderFlg(true);
      });
    } else if (userState.facility_type === FacilityType.TANKINYUSHO) {
      Promise.all([
        props.fetchDailyOperationsAndSupportsTN(selectedDate),
        props.fetchCustomRecords(),
        props.fetchUsers()
      ]).finally(() => {
        setRenderFlg(true);
      });
    } else if (userState.facility_type === FacilityType.SHISETSUNYUSHO) {
      Promise.all([
        props.fetchDailyOperationsAndSupportsSN(
          selectedDate,
          queryParameters.support_facility_unit_id
            ? Number(queryParameters.support_facility_unit_id)
            : 0
        ),
        props.fetchCustomRecords(),
        props.fetchOptionalCustomInfo(),
        props.fetchUsers()
      ]).finally(() => {
        setRenderFlg(true);
      });
    }
  }, []);

  if (!renderFlg || Object.keys(customRecords).length === 0) {
    return null;
  }
  // 表示中のユニット
  const unit =
    !Array.isArray(optionalCustomInfo) &&
    optionalCustomInfo.facility_units.find(
      (item) =>
        queryParameters.support_facility_unit_id &&
        item.id === +queryParameters.support_facility_unit_id
    );

  const unitName = unit ? unit.unit_name : "";

  const tableRowStartEndIndexInSheet = {
    startIndex: 0,
    endIndex: dailyOperationsAndSupports.support.length
  };

  // 業務日誌 並べ替え用関数
  const sortOperationCustomRecords = (
    records: CustomRecordsState
  ): CustomRecordsState => {
    // 全項目をorderの昇順で並べ替え
    const sortedArray = records.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    });

    // 欠勤・休暇者の項目を1番目に配置する
    const absenteeItemIndex = sortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.absentee
    );
    const absenteeItem =
      absenteeItemIndex !== -1
        ? sortedArray.splice(absenteeItemIndex, 1)
        : sortedArray.splice(0, 1);

    const absenteeSortedArray = [...absenteeItem, ...sortedArray];

    // 出勤者の項目を1番目に配置する（欠勤・休暇者は2番目になる）
    const attendantItemIndex = absenteeSortedArray.findIndex(
      (item) =>
        item.default_item === OPERATION_CUSTOM_RECORD_DEFAULT_ITEM.attendant
    );
    const attendantItem =
      attendantItemIndex !== -1
        ? absenteeSortedArray.splice(attendantItemIndex, 1)
        : absenteeSortedArray.splice(0, 1);

    return [...attendantItem, ...absenteeSortedArray];
  };

  // 支援記録 並べ替え用関数(「記録者」項目は除外)
  const sortSupportCustomRecords = (
    array: CustomRecordsState
  ): {
    newCustomRecords: CustomRecordsState;
    staffCustomRecord: CustomRecordsState;
  } => {
    const orderArray = array
      .filter((item) => item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);

    return {
      newCustomRecords: orderArray,
      staffCustomRecord: recorderItem
    };
  };

  const orderedSupportCustomRecords =
    customRecords.length > 0 && sortSupportCustomRecords(customRecords);

  if (!orderedSupportCustomRecords) {
    return null;
  }

  const DailyPrintSupportCore = (): JSX.Element | null => {
    switch (userState.facility_type) {
      case FacilityType.GROUP_HOME:
        return (
          <DailyPrintSupport
            displayColumns={
              queryParameters.display_columns
                ? queryParameters.display_columns.split(",")
                : []
            }
            date={selectedDate}
            summary={
              dailyOperationsAndSupports.summary as DailyOperationsAndSupportsStateGH["summary"]
            }
            supportsRecord={
              dailyOperationsAndSupports.support as DailyOperationsAndSupportsStateGH["support"]
            }
            tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
            customRecords={orderedSupportCustomRecords.newCustomRecords}
            staffCustomRecordID={
              orderedSupportCustomRecords.staffCustomRecord[0].id
            }
            unitName={unitName}
            statusTypeList={USAGE_PERFORMANCE_STATUS_TYPE_FULL}
          />
        );
      case FacilityType.TANKINYUSHO:
        return (
          <DailyPrintSupportTANKINYUSHO
            displayColumns={
              queryParameters.display_columns
                ? queryParameters.display_columns.split(",")
                : []
            }
            date={selectedDate}
            supportsRecord={
              dailyOperationsAndSupports.support as DailyOperationsAndSupportsStateTN["support"]
            }
            summary={
              dailyOperationsAndSupports.summary as DailyOperationsAndSupportsStateTN["summary"]
            }
            tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
            customRecords={orderedSupportCustomRecords.newCustomRecords}
            staffCustomRecordID={
              orderedSupportCustomRecords.staffCustomRecord[0].id
            }
          />
        );
      case FacilityType.SHISETSUNYUSHO:
        return (
          <DailyPrintSupport
            displayColumns={
              queryParameters.display_columns
                ? queryParameters.display_columns.split(",")
                : []
            }
            date={selectedDate}
            summary={
              dailyOperationsAndSupports.summary as DailyOperationsAndSupportsStateSN["summary"]
            }
            supportsRecord={
              dailyOperationsAndSupports.support as DailyOperationsAndSupportsStateSN["support"]
            }
            tableRowStartEndIndexInSheet={tableRowStartEndIndexInSheet}
            customRecords={orderedSupportCustomRecords.newCustomRecords}
            staffCustomRecordID={
              orderedSupportCustomRecords.staffCustomRecord[0].id
            }
            unitName={unitName}
            statusTypeList={STATUS_TYPE}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {queryParameters.facility_unit_id &&
        queryParameters.facility_unit_id.length > 0 && (
          <DailyPrintOperationCustomRecords
            date={selectedDate}
            operation={dailyOperationsAndSupports.operation}
            sortOperationCustomRecords={sortOperationCustomRecords}
            facilityUnitId={queryParameters.facility_unit_id.split(",")}
            customRecords={customRecords}
            unitName={unitName}
            serviceType={userState.facility_type}
          />
        )}
      {/* 支援記録プレビュー */}
      <DailyPrintSupportCore />
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const { optionalCustomInfo } = state.units;
  const dailyOperationsAndSupportsGH =
    state.GroupHome.dailyOperationsAndSupports;
  const dailyOperationsAndSupportsTN =
    state.TANKINYUSHO.dailyOperationsAndSupports;
  const dailyOperationsAndSupportsSN =
    state.SHISETSUNYUSHO.dailyOperationsAndSupports;
  const { customRecords } = state;
  const userState = state.user as UserState;

  return {
    optionalCustomInfo,
    dailyOperationsAndSupportsGH,
    dailyOperationsAndSupportsTN,
    dailyOperationsAndSupportsSN,
    customRecords,
    userState
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const {
    TANKINYUSHO,
    GroupHome,
    SHISETSUNYUSHO,
    customRecords,
    unitsDispatcher
  } = dispatches;
  const dailyOperationsAndSupportsDispatchesGH = GroupHome.dailyOperationsAndSupportsDispatcher(
    dispatch
  );
  const dailyOperationsAndSupportsDispatchesTN = TANKINYUSHO.dailyOperationsAndSupportsDispatcher(
    dispatch
  );
  const dailyOperationsAndSupportsDispatchesSN = SHISETSUNYUSHO.dailyOperationsAndSupportsDispatcher(
    dispatch
  );
  const userInFacilityDispatchesGH = GroupHome.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatchesTN = TANKINYUSHO.userInFacilityDispatcher(
    dispatch
  );
  const userInFacilityDispatchesSN = SHISETSUNYUSHO.userInFacilityDispatcher(
    dispatch
  );
  const customRecordsDispatches = customRecords(dispatch);
  const unitsDispatches = unitsDispatcher(dispatch);
  return {
    fetchDailyOperationsAndSupportsGH: (
      yyyymmdd: string,
      unitId: number
    ): Promise<void> =>
      dailyOperationsAndSupportsDispatchesGH.fetchDailyOperationsAndSupports(
        yyyymmdd,
        unitId
      ),
    fetchDailyOperationsAndSupportsTN: (yyyymmdd: string): Promise<void> =>
      dailyOperationsAndSupportsDispatchesTN.fetchDailyOperationsAndSupports(
        yyyymmdd
      ),
    fetchDailyOperationsAndSupportsSN: (
      yyyymmdd: string,
      unitId: number
    ): Promise<void> =>
      dailyOperationsAndSupportsDispatchesSN.fetchDailyOperationsAndSupports(
        yyyymmdd,
        unitId
      ),
    fetchCustomRecords: (): void => {
      customRecordsDispatches.fetchCustomRecords();
    },
    fetchOptionalCustomInfo: (): void => {
      unitsDispatches.fetchOptionalCustomInfo();
    },
    fetchUsersGH: (): Promise<void> => userInFacilityDispatchesGH.fetch(),
    fetchUsersTN: (): Promise<void> => userInFacilityDispatchesTN.fetch(),
    fetchUsersSN: (): Promise<void> => userInFacilityDispatchesSN.fetch()
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  let fetchUsers;
  let dailyOperationsAndSupports;
  switch (stateProps.userState.facility_type) {
    case FacilityType.TANKINYUSHO:
      fetchUsers = dispatchProps.fetchUsersTN;
      dailyOperationsAndSupports = stateProps.dailyOperationsAndSupportsTN;
      break;
    case FacilityType.SHISETSUNYUSHO:
      fetchUsers = dispatchProps.fetchUsersSN;
      dailyOperationsAndSupports = stateProps.dailyOperationsAndSupportsSN;
      break;
    default:
      // GH
      fetchUsers = dispatchProps.fetchUsersGH;
      dailyOperationsAndSupports = stateProps.dailyOperationsAndSupportsGH;
  }

  return {
    fetchUsers,
    dailyOperationsAndSupports,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const DailyPrintCustomRecords = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(DailyPrintCustomRecordsCore);
