import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetOptionalCustomInfoResponse = BaseResponse<{
  facility_units: {
    id: number;
    unit_name: string;
    user_ids: number[];
  }[];
  operation_record_flg: {
    use_common_operation_record: number;
    use_units_operation_record: number;
  };
}>;

/**
 * 事業所のユニット情報、所属ユーザー、業務日誌の利用フラグを返却する
 *   ユニット設定がない場合は空配列が返却される
 */
export const getOptionalCustomInfo = async (): Promise<
  AxiosResponse<GetOptionalCustomInfoResponse>
> => {
  const url = `${VERSION_URL_201910}/optional_custom_info`;
  return request.get<GetOptionalCustomInfoResponse>(url);
};

export default getOptionalCustomInfo;
