import * as React from "react";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from "@material-ui/core/styles";

const styles = createStyles({
  root: {
    borderRadius: 0
  }
});

const StyledPaper = withStyles(styles)(Paper);

type Props = React.Props<{}>;

export const CarePlanFormPaper = ({ children }: Props): JSX.Element => (
  <StyledPaper component="section" elevation={0}>
    {children}
  </StyledPaper>
);
