import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export const targetDateType = {
  daily: "daily",
  monthly: "monthly"
} as const;

export type TargetType = typeof targetDateType[keyof typeof targetDateType];

export type PrintRecordParams = {
  target: TargetType;
  targetDate?: string;
  excludedUserIds?: string;
  from?: string;
  to?: string;
  userIds?: string;
};

export type GetServiceDeliveryPrint = {
  data: {
    service_delivery_records_id: number;
    users_in_facility_id: number;
    name_sei: string;
    name_mei: string;
    target_date: string;
    start_time: string;
    end_time: string;
    status: number;
    status_text: string | null;
    number_of_supporters: number;
    support_ratio_per_practitioner: number;
    physical_care_flg: number;
    license_same_flg: number;
    number_of_practitioner: number;
    service_delivery_record_practitioners: {
      service_delivery_record_practitioners_id: number;
      start_time: string;
      end_time: string;
      number_of_time: number | string;
      practitioner: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner2: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner3: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner4: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner5: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner_num: number;
      display_flg: number | null;
      service_delivery_records?: {
        custom_records_category_id: number;
        input: {
          id: number;
          custom_record_item_id: number;
          input_data?: string;
          choiced_item_id?: number;
          checked?: number;
        }[];
      }[];
      // 同行援護・行動援護のみ
      service_delivery_record_practitioner_destinations?: {
        service_delivery_record_practitioner_destinations_id: number;
        when_time: string;
        place: string;
        support: string;
      }[];
      // 同行援護・行動援護のみ
      service_delivery_record_practitioner_money_managements?: {
        traveling_expenses: GetServiceDeliveryPrintExpenses[];
        other_expenses: GetServiceDeliveryPrintExpenses[];
      };
    }[];
  }[];
};

export type GetServiceDeliveryPrintExpenses = {
  service_delivery_record_practitioner_money_managements_id: number;
  transportation: number;
  free_text: string;
  amount_money: number;
};

type responseType = {
  data: string[];
};

/**
 * サービス提供記録印刷用の情報を取得する
 * @param target 日ごとか月ごと
 * @param targetDate 日ごとの場合対象日
 * @param excludedUserIds 除外する利用者ID
 * @param from 月ごとの場合対象開始日
 * @param to 月ごとの場合対象終了日
 * @param userIds 対象とする利用者ID
 */
export const getServiceDeliveryPrint = async (
  params: PrintRecordParams
): Promise<AxiosResponse<responseType>> => {
  const { target, targetDate, excludedUserIds, from, to, userIds } = params;
  const url = `${VERSION_URL_201910}/service_delivery/${target}/print`;
  let query = "";
  if (target === targetDateType.daily && targetDate) {
    query = `?target_date=${targetDate}`;
    if (excludedUserIds) {
      query += `&excluded_user_ids=${excludedUserIds}`;
    }
  }
  if (target === targetDateType.monthly && from && to) {
    query = `?from=${from}&to=${to}`;
    if (userIds) {
      query += `&user_ids=${userIds}`;
    }
  }
  return request.get<responseType>(url + query);
};
