import * as React from "react";
import * as H from "history";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import FormGroup from "@material-ui/core/FormGroup";
import GrayMiddleHeading from "@components/atoms/GrayMiddleHeading";
import MuiTextField from "@components/molecules/MuiTextField";
import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import RecordSelectDate from "@components/organisms/mgr/common/record/RecordSelectDate";
import RecordHeaderAllEditType from "@components/organisms/mgr/common/record/RecordHeaderAllEditType";
import { RecordSupportPlanValuesHasMonitoring } from "@initialize/record/supportPlan/initialValues";
import { FormikProps, getIn } from "formik";
import { StaffState } from "@stores/domain/staff/types";
import { FieldItem } from "@interfaces/ui/form";
import getStaffRole from "@utils/domain/staffs/getStaffRole";
import WarningIcon from "@material-ui/icons/Warning";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import { ErrorsState } from "@stores/domain/errors/types";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import { Divider } from "@material-ui/core";
import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      padding: "16px 32px 16px",
      marginBottom: 16
    },
    paper2: {
      padding: "24px 32px 16px"
    },
    title: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      paddingBottom: 32
    },
    title2: {
      paddingBottom: 32
    },
    section: {
      marginBottom: 48
    },
    longTermGoalSection: {
      "& > :first-child": {
        marginBottom: 24
      }
    },
    groupSection: {
      "& > :first-child": {
        marginBottom: 24
      },
      "&:not(:last-child)": {
        marginBottom: 48
      }
    },
    sectionField: {
      marginBottom: 32
    },
    field: {
      marginBottom: 32,
      "& textarea::-webkit-scrollbar": {
        display: "none"
      }
    },
    dateField: {
      marginBottom: 32
    },
    selectField: {
      marginTop: "32px",
      width: 245,
      "&:not(:last-child)": {
        marginBottom: "48px"
      }
    },
    authorizer: {
      width: 245,
      margin: "0px 16px 16px 0px"
    },
    divider: {
      marginTop: 4,
      backgroundColor: "#37474f"
    },
    table: {
      width: 560,
      marginBottom: 8
    },
    warningAlertIconWrapper: {
      position: "relative",
      top: 3,
      left: -10
    },
    warningAlertIcon: {
      width: 18,
      height: 18,
      color: "#ffca28"
    },
    warningListItem: {
      fontSize: 16,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.75,
      letterSpacing: 0.5,
      textAlign: "center",
      color: "#37474f",
      position: "relative",
      margin: "0 auto",
      listStyle: "none"
    },
    warningBg: {
      backgroundColor: "#fff7df",
      marginTop: 0,
      marginBottom: 32,
      padding: "16px 0"
    },
    secondaryWrapper: {
      backgroundColor: "#fafafa",
      borderRadius: "4px",
      border: "1px solid #bdbdbd",
      fontSize: 14,
      padding: "4px 24px",
      marginBottom: 48
    },
    inputBundler: {
      borderBottom: "1px solid #e0e0e0",
      paddingBottom: 20,
      "&:last-child": {
        borderBottom: "none"
      }
    },
    inputBundlerPlanChange: {
      borderBottom: "1px solid #e0e0e0",
      "&:last-child": {
        borderBottom: "none"
      }
    },
    inputAlignment: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    inputAlignmentUserhope: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginTop: 16
    },
    secondaryInputLabel: {
      display: "flex",
      alignItems: "center",
      width: 150,
      minWidth: 150
    },
    withTooltip: {
      marginRight: 5
    },
    secondaryInputWrapper: {
      width: "100%",
      "& textarea::-webkit-scrollbar": {
        display: "none"
      }
    },
    summaryInput: {
      display: "flex",
      alignItems: "center",
      padding: "20px 0"
    },
    clearButton: {
      margin: "12px 16px"
    }
  });

type OwnProps = {
  pageName: string;
  userName: string;
  uifId: string;
  supportPlanId?: string;
  creationDate: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  isEditing: boolean;
  staff: StaffState;
  staffOptions: FieldItem[];
  authorValue: string;
  evaluationAuthorizerValue: string;
  evaluationAuthorizerRole: string;
  history: H.History;
  errorsState: ErrorsState["goal"]["data"];
  onClickEdit: (e: React.MouseEvent) => void;
  onClickEditCancel: () => void;
  formikProps: FormikProps<RecordSupportPlanValuesHasMonitoring>;
};
type EvaluationPros = {
  dataIndex: number;
  isEditing: boolean;
  formikProps: FormikProps<RecordSupportPlanValuesHasMonitoring>;
} & WithStyles<typeof styles>;
type Props = OwnProps & WithStyles<typeof styles>;
const EvaluationForm = (props: EvaluationPros): JSX.Element => {
  const evaluationStateData = [
    { label: "実施", value: "1", disabled: !props.isEditing },
    { label: "一部実施", value: "2", disabled: !props.isEditing },
    { label: "未実施", value: "0", disabled: !props.isEditing }
  ];
  const goalAchievementData = [
    { label: "達成", value: "1", disabled: !props.isEditing },
    { label: "一部達成", value: "2", disabled: !props.isEditing },
    { label: "未達成", value: "0", disabled: !props.isEditing }
  ];
  const necessityPlanChange = [
    { label: "あり", value: "1", disabled: !props.isEditing },
    { label: "なし", value: "0", disabled: !props.isEditing }
  ];

  const { dataIndex } = props;

  const { values: formikValues, setFieldValue } = props.formikProps;
  const radioButtonStyle: React.CSSProperties = {
    flexDirection: "row",
    margin: "12px 0"
  };

  const clearButton = (name: string, disabled: boolean): JSX.Element => {
    return (
      <KnowbeButton
        className={props.classes.clearButton}
        kind="outlineMini"
        onClick={(): void => {
          setFieldValue(`support_plan_goal[${dataIndex}][${name}]`, "");
        }}
        disabled={disabled}
      >
        選択解除
      </KnowbeButton>
    );
  };

  return (
    <div className={props.classes.secondaryWrapper}>
      <div className={props.classes.inputBundler}>
        <div className={props.classes.inputAlignment}>
          <div className={props.classes.secondaryInputLabel}>実施</div>
          <div className={props.classes.secondaryInputWrapper}>
            <FormikRadioButtons
              name={`support_plan_goal[${dataIndex}]['sprint_result']`}
              label=""
              options={evaluationStateData}
              style={radioButtonStyle}
            />
            {props.isEditing &&
              clearButton(
                "sprint_result",
                !formikValues.support_plan_goal[dataIndex].sprint_result
              )}
          </div>
        </div>
        <div className={props.classes.inputAlignment}>
          <span className={props.classes.secondaryInputLabel}>振り返り</span>
          <div className={props.classes.secondaryInputWrapper}>
            <RecordTextField
              name={`support_plan_goal[${dataIndex}]['sprint_review']`}
              value={
                formikValues.support_plan_goal[dataIndex].sprint_review || ""
              }
              defaultValue=""
              label=""
              placeholder="入力してください"
              isEditable={props.isEditing}
            />
          </div>
        </div>
      </div>
      <div className={props.classes.inputBundler}>
        <div className={props.classes.inputAlignment}>
          <div className={props.classes.secondaryInputLabel}>
            <span className={props.classes.withTooltip}>本人の評価</span>
            <HelpToolTip title={<HelpTipMessages name="selfEvaluation" />} />
          </div>
          <div className={props.classes.secondaryInputWrapper}>
            <FormikRadioButtons
              name={`support_plan_goal[${dataIndex}]['sprint_user_evaluation']`}
              label=""
              options={goalAchievementData}
              style={radioButtonStyle}
            />
            {props.isEditing &&
              clearButton(
                "sprint_user_evaluation",
                !formikValues.support_plan_goal[dataIndex]
                  .sprint_user_evaluation
              )}
          </div>
        </div>
        <div className={props.classes.inputAlignment}>
          <span className={props.classes.secondaryInputLabel}>
            本人振り返り
          </span>
          <div className={props.classes.secondaryInputWrapper}>
            <RecordTextField
              name={`support_plan_goal[${dataIndex}]['sprint_user_review']`}
              value={
                formikValues.support_plan_goal[dataIndex].sprint_user_review ||
                ""
              }
              defaultValue=""
              label=""
              placeholder="入力してください"
              isEditable={props.isEditing}
            />
          </div>
        </div>
        <div className={props.classes.inputAlignmentUserhope}>
          <span className={props.classes.secondaryInputLabel}>本人の希望</span>
          <div className={props.classes.secondaryInputWrapper}>
            <RecordTextField
              name={`support_plan_goal[${dataIndex}]['user_hope']`}
              value={formikValues.support_plan_goal[dataIndex].user_hope || ""}
              defaultValue=""
              label=""
              placeholder="入力してください"
              isEditable={props.isEditing}
            />
          </div>
        </div>
      </div>
      <div className={props.classes.inputBundler}>
        <div className={props.classes.inputAlignment}>
          <div className={props.classes.secondaryInputLabel}>
            <span className={props.classes.withTooltip}>職員からの評価</span>
            <HelpToolTip title={<HelpTipMessages name="staffEvaluation" />} />
          </div>
          <div className={props.classes.secondaryInputWrapper}>
            <FormikRadioButtons
              name={`support_plan_goal[${dataIndex}]['sprint_staff_evaluation']`}
              label=""
              options={goalAchievementData}
              style={radioButtonStyle}
            />
            {props.isEditing &&
              clearButton(
                "sprint_staff_evaluation",
                !formikValues.support_plan_goal[dataIndex]
                  .sprint_staff_evaluation
              )}
          </div>
        </div>
        <div className={props.classes.inputAlignment}>
          <span className={props.classes.secondaryInputLabel}>
            職員振り返り
          </span>
          <div className={props.classes.secondaryInputWrapper}>
            <RecordTextField
              name={`support_plan_goal[${dataIndex}]['sprint_staff_review']`}
              value={
                formikValues.support_plan_goal[dataIndex].sprint_staff_review ||
                ""
              }
              defaultValue=""
              label=""
              placeholder="入力してください"
              isEditable={props.isEditing}
            />
          </div>
        </div>
      </div>
      <div className={props.classes.inputBundlerPlanChange}>
        <div className={props.classes.inputAlignment}>
          <div className={props.classes.secondaryInputLabel}>
            <span className={props.classes.withTooltip}>計画変更の必要性</span>
          </div>
          <div className={props.classes.secondaryInputWrapper}>
            <FormikRadioButtons
              name={`support_plan_goal[${dataIndex}]['necessity_plan_change']`}
              label=""
              options={necessityPlanChange}
              style={radioButtonStyle}
            />
            {props.isEditing &&
              clearButton(
                "necessity_plan_change",
                !formikValues.support_plan_goal[dataIndex].necessity_plan_change
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
/**
 * 評価振り返り閲覧編集
 */
const EvaluationFieldsCore: React.FC<Props> = (props: Props) => {
  const { values: formikValues } = props.formikProps;
  const currentEvaluationStatus = getIn(formikValues, "evaluation_status");
  const currentEvaluationAuthorizer = getIn(
    formikValues,
    "evaluation_authorizer"
  );
  // state
  const [showReviewer, setShowReviewer] = React.useState(false);
  const [evaluationStaffRole, setEvaluationStaffRole] = React.useState("-");
  // 評価ステータス変更時
  React.useEffect(() => {
    setShowReviewer(currentEvaluationStatus === "1");
  }, [currentEvaluationStatus]);
  // 評価者変更時
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態の場合
      // 変更後の evaluation_authorizer 値にマッチした役職名を evaluationStaffRole に流し込む
      setEvaluationStaffRole(
        getStaffRole(props.staff, currentEvaluationAuthorizer) || "-"
      );
    } else {
      // 閲覧状態の場合 ( 初期表示時 )
      // evaluationStaffRole に snapshot_role を流し込む ( スナップがない場合は role 値が入る )
      setEvaluationStaffRole(props.evaluationAuthorizerRole || "-");
    }
  }, [currentEvaluationAuthorizer]);
  // 編集・更新完了時に
  // 「必須ではない」かつ「入力状態が不完全」
  // な日付項目をリセットさせるための施策
  React.useEffect(() => {
    if (props.isEditing) {
      // 編集状態開始時
      // スナップ情報ではなく現行の値に表示を切り替えるため evaluationStaffRole を更新させる
      setEvaluationStaffRole(
        getStaffRole(props.staff, currentEvaluationAuthorizer) || "-"
      );
    } else {
      props.formikProps.resetForm();
      // 編集状態終了時 ( 編集「キャンセル」時 )
      // スナップ情報を復元させるため evaluationStaffRole を更新させる
      // ( 編集キャンセル前に値を変更された状態が残るケースのフォロー施策 )
      setEvaluationStaffRole(props.evaluationAuthorizerRole || "-");
    }
  }, [props.isEditing]);
  const { errorsState, supportPlanId } = props;
  const relationId = Number(supportPlanId);
  const errorTypes = { warn: "warn" };
  const hasError = errorsState && errorsState.length > 0;
  // エラー表示条件は「エラー種別が警告」「A型」「自身(supportPlanId)が等価」であること
  const hasWarningData =
    hasError &&
    errorsState[0].errors.findIndex(
      (e) => e.type === errorTypes.warn && e.relation.value === relationId
    ) > -1;
  const longTermIndex = formikValues.support_plan_goal.findIndex(
    (v) => v.sprint_type === 1
  );
  const shortTermIndexes = formikValues.support_plan_goal
    .map((supportPlan, index) => {
      return {
        ...supportPlan,
        baseIndex: index
      };
    })
    .filter((v) => v.sprint_type === 2)
    .sort((a, b) => a.number - b.number)
    .map((supportPlan) => {
      return supportPlan.baseIndex;
    });
  return (
    <div>
      <RecordHeaderAllEditType
        pageName={props.pageName}
        userName={props.userName}
        hasRecord // 新規及びリスト経由なので常にtrue
        uifId={props.uifId}
        supportPlanId={supportPlanId}
        recordType="support_plan"
        isEditing={props.isEditing}
        onClickEdit={props.onClickEdit}
        onClickEditCancel={props.onClickEditCancel}
        formikProps={props.formikProps}
        history={props.history}
        isEvaluation
      />
      <Paper className={props.classes.paper} elevation={0}>
        {!props.isEditing && hasWarningData && (
          <ul className={props.classes.warningBg}>
            {errorsState[0].errors.map((error, index) => {
              if (
                error.relation.value === relationId &&
                error.type === errorTypes.warn
              ) {
                const key = `warning-row-${index}`;
                return (
                  <li className={props.classes.warningListItem} key={key}>
                    <span className={props.classes.warningAlertIconWrapper}>
                      <WarningIcon className={props.classes.warningAlertIcon} />
                    </span>
                    <span>{error.content}</span>
                  </li>
                );
              }
              return null;
            })}
          </ul>
        )}
        <div className={props.classes.conCreateDate}>
          <CreateAndUpdateDate
            createdAt={props.createdAt}
            updatedAt={props.updatedAt}
          />
        </div>
        <div className={props.classes.section}>
          <div className={props.classes.dateField}>
            <RecordSelectDate
              name="creation_date"
              label="計画作成日"
              required
              value={formikValues.creation_date}
              isEditable={false}
              addYearTo={1}
              overrideYearFrom={1989}
            />
          </div>
          <FormGroup row>
            <div className={props.classes.dateField}>
              <RecordSelectDate
                name="support_begin_date"
                label="支援開始日"
                required
                value={formikValues.support_begin_date}
                isEditable={false}
                addYearTo={1}
                overrideYearFrom={1989}
              />
            </div>
            <div className={props.classes.dateField}>
              <RecordSelectDate
                name="support_end_date"
                label="支援終了日"
                required
                value={formikValues.support_end_date}
                isEditable={false}
                addYearTo={10}
                overrideYearFrom={1989}
              />
            </div>
          </FormGroup>
          <div className={props.classes.sectionField}>
            <RecordTextField
              name="user_request_text"
              value={formikValues.user_request_text}
              defaultValue=""
              label="本人・家族の意向"
              labelType="default"
              placeholder="入力してください"
              isEditable={false}
            />
          </div>
          <div className={props.classes.sectionField}>
            <RecordTextField
              name="current_status"
              value={formikValues.current_status}
              defaultValue=""
              label="本人の現状"
              labelType="default"
              placeholder="入力してください"
              isEditable={false}
            />
          </div>
        </div>
        <div className={props.classes.longTermGoalSection}>
          <GrayMiddleHeading text="長期目標" />
          <div className={props.classes.field}>
            <RecordTextField
              name={`support_plan_goal[${longTermIndex}]['sprint_goal']`}
              value={formikValues.support_plan_goal[longTermIndex].sprint_goal}
              defaultValue=""
              label=""
              labelType="default"
              placeholder="入力してください"
              isEditable={false}
            />
          </div>
          <EvaluationForm
            dataIndex={longTermIndex}
            isEditing={props.isEditing}
            formikProps={props.formikProps}
            classes={props.classes}
          />
        </div>
        {shortTermIndexes.map((num) => (
          <div key={`short-${num}`}>
            <div className={props.classes.longTermGoalSection}>
              <GrayMiddleHeading
                text={`短期目標 ${formikValues.support_plan_goal[num].number}`}
              />
              <div className={props.classes.field}>
                <RecordTextField
                  name={"support_plan_goal[num]['sprint_goal']"}
                  value={formikValues.support_plan_goal[num].sprint_goal}
                  defaultValue=""
                  label="目標"
                  labelType="default"
                  placeholder="入力してください"
                  isEditable={false}
                />
              </div>
              <div className={props.classes.field}>
                <RecordTextField
                  name={"support_plan_goal[num]['sprint_goal']"}
                  value={formikValues.support_plan_goal[num].adopt_info}
                  defaultValue=""
                  label="本人の取組内容"
                  labelType="default"
                  placeholder="入力してください"
                  isEditable={false}
                />
              </div>
              <div className={props.classes.field}>
                <RecordTextField
                  name={"support_plan_goal[num]['sprint_goal']"}
                  value={formikValues.support_plan_goal[num].support_info}
                  defaultValue=""
                  label="職員の支援内容"
                  labelType="default"
                  placeholder="入力してください"
                  isEditable={false}
                />
              </div>
            </div>
            <EvaluationForm
              dataIndex={num}
              isEditing={props.isEditing}
              formikProps={props.formikProps}
              classes={props.classes}
            />
          </div>
        ))}
        <div className={props.classes.groupSection}>
          <GrayMiddleHeading text="その他" />
          <div className={props.classes.field}>
            <RecordTextField
              name="remarks"
              value={formikValues.remarks}
              defaultValue=""
              label="備考"
              labelType="default"
              placeholder="入力してください"
              isEditable={false}
            />
          </div>
          <div className={props.classes.field}>
            <RecordTextField
              name="staff_comment"
              value={formikValues.staff_comment}
              defaultValue=""
              label="職員コメント"
              labelType="default"
              placeholder="入力してください"
              isEditable={false}
            />
          </div>
          <div className={props.classes.selectField}>
            <RecordSelect
              name="author"
              value={props.authorValue}
              defaultValue="未設定"
              label="作成者"
              placeholder="選択してください"
              options={props.staffOptions}
              isEditable={false}
              isSelectablePlaceholder
              emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
            />
          </div>
          <div className={props.classes.longTermGoalSection}>
            <GrayMiddleHeading text="実施後の変化(総括)" />
            <div className={props.classes.secondaryWrapper}>
              <div className={props.classes.summaryInput}>
                <RecordTextField
                  name="after_summary"
                  value={formikValues.after_summary}
                  defaultValue=""
                  label="総括"
                  labelType="default"
                  placeholder="入力してください"
                  isEditable={props.isEditing}
                />
              </div>
              <div className={props.classes.summaryInput}>
                <RecordSelectDate
                  name="revaluation_date"
                  label="再評価日"
                  value={formikValues.revaluation_date}
                  isEditable={props.isEditing}
                  addYearTo={10}
                  overrideYearFrom={1989}
                />
              </div>
            </div>
            <div>
              <div className={props.classes.selectField}>
                <RecordSelect
                  name="evaluation_status"
                  value={
                    formikValues.evaluation_status === "1" ? "実施済" : "未実施"
                  }
                  defaultValue=""
                  label="モニタリング実施状況"
                  placeholder="入力してください"
                  options={[
                    { label: "未実施", value: "0" },
                    { label: "実施済", value: "1" }
                  ]}
                  isEditable={props.isEditing}
                />
              </div>
              {showReviewer && (
                <FormGroup row>
                  <div className={props.classes.authorizer}>
                    <RecordSelect
                      name="evaluation_authorizer"
                      value={props.evaluationAuthorizerValue}
                      defaultValue="未設定"
                      label="評価者"
                      placeholder="選択してください"
                      options={props.staffOptions}
                      isEditable={props.isEditing}
                      isSelectablePlaceholder
                      emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
                    />
                  </div>
                  <div className={props.classes.authorizer}>
                    <MuiTextField
                      value={evaluationStaffRole}
                      label="役職"
                      disabled
                      style={{ marginBottom: 0 }}
                    />
                  </div>
                </FormGroup>
              )}
            </div>
          </div>
        </div>
      </Paper>
      <Paper className={props.classes.paper2} elevation={0}>
        <div className={props.classes.title2}>
          <Typography variant="h6" color="primary">
            モニタリング会議 議事録
          </Typography>
          <Divider className={props.classes.divider} />
        </div>
        <FormGroup row>
          <div className={props.classes.field}>
            <RecordSelectDate
              name="evaluation_date"
              label="実施日"
              value={formikValues.evaluation_date}
              isEditable={props.isEditing}
              addYearTo={10}
              overrideYearFrom={1989}
            />
          </div>
        </FormGroup>
        <div className={props.classes.field}>
          <RecordTextField
            name="evaluation_minutes"
            value={formikValues.evaluation_minutes}
            defaultValue=""
            label="会議議事録"
            labelType="default"
            placeholder="入力してください"
            isEditable={props.isEditing}
          />
        </div>
      </Paper>
    </div>
  );
};
export const EvaluationFields = withStyles(styles)(EvaluationFieldsCore);
