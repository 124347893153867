import { GetFacilityUsersResponse } from "@api/requests/v202104/facility/getFacilityUsers";
import { Checkbox } from "@constants/variables";

/**
 * ActionNames
 */
export const FETCH_STARTED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_STARTED";
export const FETCH_SUCCESS =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_SUCCESS";
export const FETCH_FAILED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_FAILED";
export const FETCH_ONE_STARTED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_ONE_STARTED";
export const FETCH_ONE_SUCCESS =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_ONE_SUCCESS";
export const FETCH_ONE_FAILED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/FETCH_ONE_FAILED";
export const CREATE_STARTED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/CREATE_STARTED";
export const CREATE_SUCCESS =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/CREATE_SUCCESS";
export const CREATE_FAILED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/CREATE_FAILED";
export const UPDATE_STARTED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/UPDATE_STARTED";
export const UPDATE_SUCCESS =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/UPDATE_SUCCESS";
export const UPDATE_FAILED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/UPDATE_FAILED";
export const DELETE_STARTED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/DELETE_STARTED";
export const DELETE_SUCCESS =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/DELETE_SUCCESS";
export const DELETE_FAILED =
  "CHIIKITEICHAKU/USER_IN_FACILITY_202104/DELETE_FAILED";
export const CLEAR = "CHIIKITEICHAKU/USER_IN_FACILITY_202104/CLEAR";

export type UsersInFacilityState = {
  users: GetFacilityUsersResponse["data"];
  user: {
    user_in_facility: Partial<{
      /* 基本情報 */
      // 施設利用者id
      id: number;
      // 姓
      name_sei: string;
      // 名
      name_mei: string;
      // 姓(カナ)
      name_sei_kana: string;
      // 名(カナ)
      name_mei_kana: string;
      // 受給者証番号
      recipient_number: string;
      // 受給者証未発行、または見学中の利用者である（請求対象外）
      none_recipient_number_flg: Checkbox;
      // 性別
      gender: string;
      // 施設id
      facility_id: number;
      // 身体障害
      classify_physical_flg: Checkbox;
      // 知的障害
      classify_intelligence_flg: Checkbox;
      // 精神障害
      classify_mind_flg: Checkbox;
      // 発達障害
      classify_growth_flg: Checkbox;
      // 高次脳機能障害
      classify_brain_flg: Checkbox;
      // 難病等対象者
      classify_incurable_flg: Checkbox;
      // 障害児
      classify_handicapped_flg: Checkbox;
      // 生年月日
      date_birth: string;
      // 郵便番号
      postal_code: string;
      // 都道府県
      prefecture_name: string;
      // 市区町村id
      city_id: number;
      // 住所
      address: string;
      // 電話番号
      tel: string;
      // メールアドレス
      email: string;
      // 保護者名
      guardian_name: string;
      // 保護者、続柄
      guardian_relation: string;
      // 保護者 電話番号
      guardian_tel: string;
      // 備考
      memo: string;

      /* サービス詳細 */
      // 入居日 yyyy-mm-dd
      date_begin_in_service: string;
      // 退居日 yyyy-mm-dd
      date_end_in_service: string;
      // 支給決定開始日
      date_pay_begin: string;
      // 支給決定終了日
      date_pay_end: string;

      /* 受給者証 */
      // 介護給付費の支給決定フラグ 1: ON 0: OFF
      care_support_payment_flg: Checkbox;
      // 介護給付費の支給決定期間 開始日 yyyy-mm-dd
      date_begin_care_support_payment: string;
      // 介護給付費の支給決定期間 終了日 yyyy-mm-dd
      date_end_care_support_payment: string;
      // 障害支援区分の認定有効  1: ON, 0: OFF
      care_support_auth_flg: Checkbox;
      // 障害支援区分の認定有効期間 開始日 yyyy-mm-dd
      date_begin_care_support_auth: string;
      // 障害支援区分の認定有効期間 終了日 yyyy-mm-dd
      date_end_care_support_auth: string;
      // 計画相談支援給付費の支給決定フラグ 1: ON 0: OFF
      plan_support_payment_flg: Checkbox;
      // 計画相談支援給付費の支給期間 開始日 yyyy-mm-dd
      date_begin_plan_support_payment: string;
      // 計画相談支援給付費の支給期間 終了日 yyyy-mm-dd
      date_end_plan_support_payment: string;
      // 計画相談支援給付費のモニタリング期間 フラグ 1: ON 0: OFF
      plan_support_monitor_flg: Checkbox;
      // 計画相談支援給付費のモニタリング期間 開始日 yyyy-mm-dd
      date_begin_plan_support_monitor: string;
      // 計画相談支援給付費のモニタリング期間 終了日 yyyy-mm-dd
      date_end_plan_support_monitor: string;
    }>;
    user_in_facility_chiikiteichaku?: Partial<{
      // 施設利用者id
      users_in_facility_id: number;
      // 障害区分
      disability_class: number;
      // 特別地域加算
      special_area_flg: number;
      // 特別地域加算 適用開始日
      date_begin_special_area: string | null;
      // 特別地域加算 適用終了日
      date_end_special_area: string | null;
    }>;
  };
  doneUpsertUser: boolean;
  userValidation: Partial<{}>;
};
