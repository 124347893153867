import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
// store
import { GetSupportProcedurePrint } from "@api/requests/supportProcedure/getSupportProcedurePrint";
import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";
// constants
import {
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH_RECORD
} from "@/constants/styles";
// utils
import { dateTodayInFormat } from "@utils/date";
import ClassNames from "classnames";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import { formatTime } from "@utils/date/formatTime";
import { defaultTimeLabel } from "@utils/date/index";
import { orderBy } from "lodash-es";

import {
  CIRCLE_NUMBERS,
  KODOENGO_DISPLAY_STATUS_LIST_RECORD,
  KODOENGO_SUPPORT_PROCEDURE_FORM_DETAILS_CHECK_PRINT
} from "@constants/mgr/KODOENGO/variables";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: "987px",
      width: PRINT_PAGE_WIDTH_RECORD,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    entryFieldLabel: {
      marginBottom: 12,
      color: "#424242"
    },
    entryFieldContentContainer: {
      fontFamily: "HiraginoSans-W6",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
      marginTop: 4,
      color: "#212121",
      fontSize: 10
    },
    entryFieldContainer: {
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginTop: 12
    },
    titleContainer: {
      display: "flex",
      width: "100%"
    },
    title: {
      margin: 0,
      fontSize: "18px",
      fontWeight: "normal",
      textAlign: "left",
      color: "#212121",
      width: "calc(100% - 242px)"
    },
    author: {
      width: "242px",
      fontSize: "10px",
      margin: "0 9 22 7",
      textAlign: "right"
    },
    userName: {
      width: 120,
      marginRight: 16,
      display: "inline-block"
    },
    serviceDate: {
      width: 162,
      marginRight: 16,
      display: "inline-block"
    },
    serviceName: {
      width: 118,
      marginRight: 16,
      display: "inline-block"
    },
    table: {
      borderCollapse: "collapse",
      border: "2px solid",
      textAlign: "left",
      "& td": {
        fontSize: "10px",
        borderRight: "1px solid",
        height: 22.5,
        color: "#212121",
        borderBottom: "1px solid",
        letterSpacing: "0.5px",
        fontFamily: "HiraginoSans-W3",
        wordBreak: "break-all",
        "&.practitionerTitle": {
          fontWeight: "bold",
          width: "58px",
          textAlign: "center",
          padding: "4px 6px 4px 8px",
          fontFamily: "HiraginoSans-W6"
        },
        "&.practitionerName": {
          width: "172px",
          padding: "4px 6px",
          lineHeight: 1.4
        },
        "&.practitionerTime": {
          padding: "4px 6px"
        },
        "&.practitionerFacility": {
          width: "auto",
          padding: "4px 57px 4px 6px"
        },
        "&.detailTimeLabel": {
          fontFamily: "HiraginoSans-W6",
          width: "58px",
          padding: "4px 6px 4px 8px",
          lineHeight: 1.4
        },
        "&.detailActivityLabel": {
          fontFamily: "HiraginoSans-W6",
          width: "74px",
          padding: "4px 6px 4px 4px",
          lineHeight: 1.4
        },
        "&.detailServiceLabel": {
          fontFamily: "HiraginoSans-W6",
          width: "244px",
          padding: "4px 6px",
          lineHeight: 1.4
        },
        "&.detailCheckLabel": {
          fontFamily: "HiraginoSans-W6",
          width: "36px",
          padding: "4px 0",
          letterSpacing: "0.4px"
        },
        "&.detailAppearanceLabel": {
          fontFamily: "HiraginoSans-W6",
          width: "auto",
          padding: "4px 6px",
          lineHeight: 1.4
        },
        "&.detailTime": {
          padding: "4px 6px 4px 8px"
        },
        "&.detailActivity": {
          padding: "4px 6px 4px 4px"
        },
        "&.detailService": {
          padding: "4px 5.5px"
        },
        "&.detailCheck": {
          padding: "4px 0",
          verticalAlign: "inherit"
        },
        "&.detailAppearance": {
          padding: "4px 6px"
        },
        "&.contactInformation": {
          padding: "4px 6px 4px 8px",
          width: "90px"
        },
        "&.contactInformationItem": {
          whiteSpace: "pre-wrap",
          padding: "4px 6px",
          width: "auto"
        },
        "&.inquiry": {
          padding: "4px 6px 4px 8px",
          width: "90px"
        },
        "&.inquiryItems": {
          whiteSpace: "pre-wrap",
          padding: "4px 6px",
          width: "auto"
        },
        "&.label": {
          textAlign: "center"
        },
        "&.textCenter": {
          textAlign: "center"
        },
        "&.verticalTop": {
          verticalAlign: "top"
        },
        "&.noBorder": { borderBottom: "none" },
        "&.topBorder": { borderTop: "1px solid" },
        "&.rightBorder": { borderRight: "2px solid" },
        "&.bgGrey": {
          backgroundColor: "#f5f5f5"
        }
      },
      "& tr": {
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    }
  });

type OwnProps = {
  records: SupportProcedureState["print"];
};

type Props = OwnProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  record: GetSupportProcedurePrint["data"][number];
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

const SupportProcedurePrintCore = (props: Props): JSX.Element | null => {
  const { classes, records } = props;
  return (
    <>
      {records.map(
        (record: GetSupportProcedurePrint["data"][number], index: number) => {
          if (record === undefined) {
            return null;
          }
          return (
            <Sheet
              classes={classes}
              record={record}
              key={`page-${index.toString()}`}
            />
          );
        }
      )}
    </>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, record } = props;
  const [firstPractitioner, secondPractitioner] = orderBy(
    record.support_procedure_form_practitioners,
    "practitioner_num",
    "asc"
  );

  // 「日時設定なし」の場合はサービス提供日はハイフン
  const serviceDate = record.target_date
    ? dateTodayInFormat(record.target_date, true)
    : "-";

  // 作成者なしの場合はハイフン
  const getAuthorString = (): string => {
    const { author } = record;
    if (!author) {
      return "-";
    }
    const authorName = author.snapshot_name || author.name;
    const authorRole = author.snapshot_role || author.role;
    return `${authorName}（${authorRole}）`;
  };

  const practitionerTimeConvert = (time: string): string => {
    if (!time) {
      return defaultTimeLabel;
    }
    const times = time.split(" ");
    return convertHHMMSSToHHMM(times[1]);
  };

  const detailsTimeConvert = (time: string): string => {
    if (!time) {
      return defaultTimeLabel;
    }
    return formatTime(time);
  };

  const detailsCheck = (check: number): string | null => {
    const checkValue = KODOENGO_SUPPORT_PROCEDURE_FORM_DETAILS_CHECK_PRINT.filter(
      (item) => check === item.value
    );
    if (checkValue.length > 0) {
      return checkValue[0].label;
    }
    return null;
  };

  // フォントサイズを8pxにするためのスタイル
  const font8px = {
    display: "block",
    transform: "scale(0.8)",
    margin: "0px -12px",
    width: "100%"
  };

  return (
    <div className={classes.page}>
      <header className={classes.titleContainer}>
        {/* 印刷タイトル */}
        <h1 className={classes.title}>支援手順書 兼 記録用紙</h1>
        {/* 作成者 */}
        <div className={classes.author}>
          <span>作成者：{getAuthorString()}</span>
        </div>
      </header>
      {/* 利用者情報 */}
      <div
        className={ClassNames(classes.entryFieldContainer, classes.userName)}
      >
        <span style={{ ...font8px }} className={classes.entryFieldLabel}>
          利用者名
        </span>
        <div className={classes.entryFieldContentContainer}>
          <span>{`${record.name_sei} ${record.name_mei}`}</span>
        </div>
      </div>
      <div
        className={ClassNames(classes.entryFieldContainer, classes.serviceDate)}
      >
        <span
          style={{ ...font8px, margin: "0px -16px" }}
          className={classes.entryFieldLabel}
        >
          サービス提供日
        </span>
        <div className={classes.entryFieldContentContainer}>
          <span>{serviceDate}</span>
        </div>
      </div>
      <div
        className={ClassNames(classes.entryFieldContainer, classes.serviceName)}
      >
        <span style={{ ...font8px }} className={classes.entryFieldLabel}>
          サービス名
        </span>
        <div className={classes.entryFieldContentContainer}>
          <span>
            {String(record.status) ===
            KODOENGO_DISPLAY_STATUS_LIST_RECORD[1].value
              ? KODOENGO_DISPLAY_STATUS_LIST_RECORD[1].label
              : ""}
          </span>
        </div>
      </div>

      {/* 提供者の詳細 */}
      <div style={{ marginTop: 16 }}>
        <table className={`${classes.table}`} style={{ width: "100%" }}>
          <tbody>
            {/* 自事業所の提供者1 */}
            {record.support_procedure_form_practitioners.length !== 0 && (
              <tr key="support_procedure_form_practitioners-1">
                <td className="practitionerTitle topBorder bgGrey">提供者①</td>
                <td className="practitionerName">
                  {firstPractitioner.practitioner &&
                  firstPractitioner.practitioner.name
                    ? firstPractitioner.practitioner.name
                    : ""}
                </td>
                <td className="practitionerTime">{`${practitionerTimeConvert(
                  firstPractitioner.start_time
                )}〜${practitionerTimeConvert(
                  firstPractitioner.end_time
                )}`}</td>
                <td className="practitionerFacility">
                  {firstPractitioner.facility_name}
                </td>
              </tr>
            )}
            {/* 自事業所の提供者2 */}
            {record.number_of_practitioner === 2 && (
              <tr key="support_procedure_form_practitioners-2">
                <td className="practitionerTitle topBorder bgGrey">提供者②</td>
                <td className="practitionerName">
                  {secondPractitioner.practitioner &&
                  secondPractitioner.practitioner.name
                    ? secondPractitioner.practitioner.name
                    : ""}
                </td>
                <td className="practitionerTime">{`${practitionerTimeConvert(
                  secondPractitioner.start_time
                )}〜${practitionerTimeConvert(
                  secondPractitioner.end_time
                )}`}</td>
                <td className="practitionerFacility">
                  {secondPractitioner.facility_name}
                </td>
              </tr>
            )}
            {/* その他事業所 */}
            {record.support_procedure_form_other_practitioners.map(
              (item, index) => (
                <tr
                  key={`support_procedure_form_other_practitioners-${item.support_procedure_form_other_practitioners_id}`}
                >
                  <td className="practitionerTitle topBorder bgGrey">
                    {`提供者${
                      CIRCLE_NUMBERS[index + record.number_of_practitioner]
                    }`}
                  </td>
                  <td className="practitionerName">{item.practitioner_name}</td>
                  <td className="practitionerTime">{`${practitionerTimeConvert(
                    item.start_time
                  )}〜${practitionerTimeConvert(item.end_time)}`}</td>
                  <td className="practitionerFacility">{item.facility_name}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>

      {/* 支援の詳細 */}
      <div style={{ marginTop: 16 }}>
        <table className={`${classes.table}`} style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td className="detailTimeLabel topBorder textCenter bgGrey">
                時間
              </td>
              <td className="detailActivityLabel textCenter bgGrey">活動</td>
              <td className="detailServiceLabel textCenter rightBorder bgGrey">
                サービス手順・留意時事項
              </td>
              <td className="detailCheckLabel textCenter bgGrey">
                <span
                  style={{
                    display: "block",
                    transform: "scale(0.8)",
                    letterSpacing: "0.4px",
                    width: "calc(100% + 8px)",
                    margin: "0px -3.8px"
                  }}
                >
                  チェック
                </span>
              </td>
              <td className="detailAppearanceLabel textCenter bgGrey">様子</td>
            </tr>
            {record.support_procedure_form_details.map((details, index) => (
              <tr key={`support_procedure_form_details-${index.toString()}`}>
                <td className="detailTime verticalTop ">
                  <span>
                    {detailsTimeConvert(details.start_time)}
                    <br />〜{detailsTimeConvert(details.end_time)}
                  </span>
                </td>
                <td className="detailActivity verticalTop">
                  {details.activity}
                </td>
                <td className="detailService rightBorder verticalTop ">
                  {details.service_procedure}
                </td>
                <td className="detailCheck textCenter">
                  {detailsCheck(details.check)}
                </td>
                <td className="detailAppearance verticalTop ">
                  {details.appearance}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* 連絡・問い合わせ事項 */}
      <div style={{ marginTop: 16 }}>
        <table className={`${classes.table}`} style={{ width: "100%" }}>
          <tbody>
            {record.contact_information_plan ? (
              <tr>
                <td className="contactInformation noBorder verticalTop">
                  連絡事項
                </td>
                <td className="contactInformationItem verticalTop">
                  {record.contact_information_plan}
                </td>
              </tr>
            ) : null}
            {record.contact_information_record ? (
              <tr>
                <td className="contactInformation noBorder verticalTop">
                  {record.contact_information_plan ? "" : "連絡事項"}
                </td>
                <td className="contactInformationItem verticalTop">
                  {record.contact_information_record}
                </td>
              </tr>
            ) : null}
            {record.inquiry_items_plan ? (
              <tr>
                <td className="inquiry noBorder topBorder verticalTop">
                  問い合わせ事項
                </td>
                <td className="inquiryItems verticalTop">
                  {record.inquiry_items_plan}
                </td>
              </tr>
            ) : null}
            {record.inquiry_items_record ? (
              <tr>
                <td
                  className={
                    record.inquiry_items_plan
                      ? "inquiry verticalTop "
                      : "inquiry topBorder verticalTop"
                  }
                >
                  {record.inquiry_items_plan ? "" : "問い合わせ事項"}
                </td>
                <td className="inquiryItems verticalTop">
                  {record.inquiry_items_record}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export const SupportProcedurePrint = withStyles(styles)(
  SupportProcedurePrintCore
);
