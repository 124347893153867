import * as React from "react";

import CustomDateLabel from "@components/atoms/CustomDateLabel";
import HelpToolTip from "@components/atoms/HelpToolTip";
import { FormikSubmitButtonWrapper } from "@components/molecules/FormikSubmitButton";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import RecordSupportTableField from "@components/organisms/mgr/common/record/RecordSupportTableField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { FieldItem } from "@interfaces/ui/form";
import { Typography } from "@material-ui/core";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { SupportRecordsState } from "@stores/domain/mgr/Cseg/support/types";
import generateMergedStaffOptions from "@utils/domain/staffs/generateMergedStaffOptions";
import { FormikProps } from "formik";

const styles = (): StyleRules =>
  createStyles({
    default: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 1
    },
    nonEditable: {
      paddingTop: "20px",
      paddingBottom: "25px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    title: {
      display: "flex",
      justifyContent: "space-between"
    },
    buttons: {
      "& > button": {
        width: 120,
        marginLeft: 8
      }
    },
    contentField: {
      padding: "20px 0px 0px 200px"
    },
    helpIcon: {
      position: "relative",
      top: 3,
      marginRight: 0
    }
  });

type OwnProps = {
  date: string;
  isHoliday: boolean;
  staffOptions: FieldItem[];
  formikNamePrefix: string;
  supportRecord: SupportRecordsState[number];
  formikProps: FormikProps<SupportRecordsState>;
  editTargetDate: string | undefined;
  statusList: { label: string; value: string }[];
  onClickEdit: (date: string) => void;
  onClickCancel: (formik: FormikProps<SupportRecordsState>) => void;
  submitError: () => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const RecordSupportTableRowCore = (props: Props): JSX.Element => {
  const {
    classes,
    date,
    isHoliday,
    staffOptions,
    formikNamePrefix,
    supportRecord,
    formikProps,
    editTargetDate,
    statusList,
    onClickEdit,
    onClickCancel,
    submitError
  } = props;

  const displayStaffs = supportRecord.staffs
    ? supportRecord.staffs.map((v) => v.snapshot_name).join("、")
    : "";

  const status = statusList.find((v) => v.value === `${supportRecord.status}`);
  const displayStatus = status ? status.label : statusList[0].label;

  const isEditing = editTargetDate === date;
  const isNotEditable = editTargetDate !== undefined && !isEditing;

  const choseStaffs = supportRecord.staffs
    ? supportRecord.staffs
        .filter((v) => !!v.snapshot_name)
        .map((v) => {
          return { id: Number(v.id), name: v.snapshot_name };
        })
    : [];

  const mergedStaffOptions = generateMergedStaffOptions(
    staffOptions,
    choseStaffs
  );

  return (
    <div className={isNotEditable ? classes.nonEditable : classes.default}>
      <div className={classes.title}>
        <Typography gutterBottom variant="subtitle1" color="primary">
          <CustomDateLabel
            date={date}
            dateFormat="Do（dd）"
            holiday={isHoliday}
          />
        </Typography>
        {isEditing ? (
          <div className={classes.buttons}>
            <KnowbeButton
              kind="outlineWhite"
              onClick={(): void => onClickCancel(formikProps)}
            >
              キャンセル
            </KnowbeButton>
            <FormikSubmitButtonWrapper
              formikProps={formikProps}
              buttonName="保存する"
              errorAction={submitError}
            />
          </div>
        ) : (
          <KnowbeButton onClick={(): void => onClickEdit(date)}>
            編集
          </KnowbeButton>
        )}
      </div>
      <Typography gutterBottom variant="subtitle2">
        {displayStatus}
      </Typography>
      <div className={classes.contentField}>
        <RecordSupportTableField
          type="text"
          label="支援内容"
          name={`${formikNamePrefix}.support_content`}
          value={supportRecord.support_content || ""}
          defaultValue=""
          placeholder=""
          isEditing={isEditing}
        />
        <RecordSupportTableField
          type="text"
          label="職員考察"
          name={`${formikNamePrefix}.staff_comment`}
          value={supportRecord.staff_comment || ""}
          defaultValue=""
          placeholder=""
          isEditing={isEditing}
          tooltip={
            <HelpToolTip
              classes={{ icon: classes.helpIcon }}
              placement="right-end"
              title={<HelpTipMessages name="csegStaffComment" />}
            />
          }
        />
        <RecordSupportTableField
          type="multiple"
          label="記録者"
          name={`${formikNamePrefix}.displayStaffIds`}
          value={displayStaffs}
          defaultValue=""
          placeholder="選択してください"
          isEditing={isEditing}
          options={[{ categoryName: "", items: mergedStaffOptions }]}
          emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
        />
      </div>
    </div>
  );
};

export const RecordSupportTableRow = withStyles(styles)(
  RecordSupportTableRowCore
);
