import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
// store
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { UserState } from "@stores/domain/user/type";

import { SupportPrint } from "@components/organisms/record/print/SupportPrint";
import { SupportSHUROTEICHAKUPrint } from "@components/organisms/record/print/SupportSHUROTEICHAKUPrint";
import { SupportCustomRecordPrint } from "@components/organisms/record/print/SupportCustomRecordPrint";
import { SupportTANKINYUSHOPrint } from "@components/organisms/record/print/SupportTANKINYUSHOPrint";
import { SupportCsegPrint } from "@components/organisms/mgr/Cseg/record/SupportCsegPrint";
import { FacilityType } from "@constants/variables";

type StateProps = {
  userState: UserState;
};
type Props = StateProps &
  RouteComponentProps<{
    uifId: string;
    year: string;
    month: string;
  }>;

/**
 * 支援記録のプリント画面
 */
const RecordSupportPrint = (props: Props): JSX.Element => {
  const { uifId, year, month } = props.match.params;
  const query = props.location.search;
  const facilityUserLabel = props.userState.labels
    ? props.userState.labels.facility_user
    : "利用者";

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: `${facilityUserLabel}ごと`, path: "/record/users_summary" }
  ];

  const RenderPrint = (): JSX.Element => {
    switch (props.userState.facility_type) {
      case FacilityType.GROUP_HOME:
      case FacilityType.SHISETSUNYUSHO:
        return (
          <SupportCustomRecordPrint
            uifId={uifId}
            year={year}
            month={month}
            query={query}
          />
        );
      case FacilityType.TANKINYUSHO:
        return (
          <SupportTANKINYUSHOPrint
            uifId={uifId}
            year={year}
            month={month}
            query={query}
          />
        );
      case FacilityType.SHUROTEICHAKU:
        return (
          <SupportSHUROTEICHAKUPrint
            uifId={uifId}
            year={year}
            month={month}
            query={query}
          />
        );
      case FacilityType.KEIKAKUSODAN:
      case FacilityType.CHIIKIIKO:
      case FacilityType.CHIIKITEICHAKU:
        return (
          <SupportCsegPrint
            uifId={uifId}
            year={year}
            month={month}
            query={query}
          />
        );
      default:
        return (
          <SupportPrint uifId={uifId} year={year} month={month} query={query} />
        );
    }
  };

  return (
    <AdminTemplate pageName="支援記録" pathList={pathList}>
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <RenderPrint />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  userState: state.user as UserState
});

export const SupportRecordPrint = connect(mapStateToProps)(RecordSupportPrint);
