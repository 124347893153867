import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { RectangleBox } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportRectangleBox";
import {
  IDOSHIENReportTypeInterface,
  REPEAT_MONTHLY
} from "@stores/domain/mgr/IDOSHIEN/report/types";

const styles = createStyles({
  contents: {
    display: "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "flex-end" as "flex-end",
    marginBottom: 16
  },
  actionContents: {
    display: "flex",
    justifyContent: "end" as "end",
    width: "100%"
  },
  rectangleListContents: {
    display: "flex",
    justifyContent: "flex-start" as "flex-start",
    flexWrap: "wrap",
    gap: "8px",
    "& > :last-child": {
      marginRight: 0
    }
  },
  linkButton: {
    height: 36,
    border: "solid 1px rgba(0, 0, 0, 0.12)",
    borderRadius: 5,
    color: "#37474f",
    marginLeft: 8
  },
  button: {
    color: "#0277bd",
    width: "100%",
    paddingBottom: 4
  },
  // RectangleBox用のを上書き
  wrapper: {
    width: "auto"
  },
  content: {
    justifyContent: "start"
  }
});

type OwnProps = {
  rectangleConfigList: Omit<
    React.ComponentProps<typeof RectangleBox>,
    "classes"
  >[];
  type: IDOSHIENReportTypeInterface["type"];
  onClickCopyReportListIcon: () => void;
  onClickCopyServiceDeliveryIcon?: () => void;
  disabledCopyServiceDelivery?: boolean;
  disabledCopy: boolean;
  groupOperationSupportFlg: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportPaperHeaderCore = ({
  classes,
  rectangleConfigList,
  type,
  onClickCopyReportListIcon,
  onClickCopyServiceDeliveryIcon,
  disabledCopyServiceDelivery,
  disabledCopy,
  groupOperationSupportFlg
}: Props): JSX.Element => (
  <div>
    <div className={classes.contents}>
      <div className={classes.actionContents}>
        {type === REPEAT_MONTHLY && groupOperationSupportFlg ? (
          <div className={classes.linkButton}>
            <Button
              className={classes.button}
              color="secondary"
              onClick={onClickCopyServiceDeliveryIcon}
              disabled={disabledCopyServiceDelivery}
            >
              サービス提供記録から実績へ一括コピー
            </Button>
          </div>
        ) : null}

        <div className={classes.linkButton}>
          <Button
            className={classes.button}
            color="secondary"
            onClick={onClickCopyReportListIcon}
            disabled={disabledCopy}
          >
            計画から実績へ一括コピー
          </Button>
        </div>
      </div>
    </div>
    {rectangleConfigList && rectangleConfigList.length > 0 && (
      <div className={classes.contents}>
        <div className={classes.rectangleListContents}>
          {rectangleConfigList.map((rectangleConfig, index) => {
            const uniqueKey = `${rectangleConfig.title}-${index}`;
            return (
              <RectangleBox
                classes={{
                  wrapper: classes.wrapper,
                  content: classes.content
                }}
                key={uniqueKey}
                {...rectangleConfig}
              />
            );
          })}
        </div>
      </div>
    )}
  </div>
);

export const InOutReportPaperHeader = withStyles(styles)(
  InOutReportPaperHeaderCore
);
