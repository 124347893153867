import { SERVICE_TIME_ZONE_LIST_SEPARATOR_TIME } from "@constants/mgr/KYOTAKUKAIGO/variables";

/**
 * SERVICE_TIME_ZONE_LISTをDate型に変換する
 * @param targetDate
 * @param numberOfdays // 何日分計算するか
 */
const createTimeZoneArr = (
  targetDate: string,
  numberOfdays = 2
): Date[] | undefined => {
  const baseDate = new Date(targetDate);
  if (Number.isNaN(baseDate.getTime())) {
    return undefined;
  }
  const baseDates = Array.from(new Array(numberOfdays)).map((_, i) => {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + i);
    return date;
  });

  const zoneTimes: Date[] = [];
  baseDates.forEach((date) => {
    return SERVICE_TIME_ZONE_LIST_SEPARATOR_TIME.forEach((time) => {
      const newDate = new Date(date);
      const timeArr = time.split(":");
      const hour = Number(timeArr[0]);
      const minute = Number(timeArr[1]);
      newDate.setHours(hour, minute);
      zoneTimes.push(newDate);
    });
  });

  return zoneTimes;
};

/**
 * 時間帯バリデーション
 * ※開始時間/終了時間が同じ時間帯であるか
 *
 * @param startTime
 * @param endTime
 * @returns
 */
export const checkTimeZoneCrossing = (
  startTime: Date,
  endTime: Date,
  targetDate: string,
  numberOfdays = 2 // targetDateから換算して当日と翌日計算
): string | undefined => {
  let errorflg = false;
  const timeZoneArr = createTimeZoneArr(targetDate, numberOfdays);
  if (
    timeZoneArr === undefined ||
    Number.isNaN(startTime.getTime()) ||
    Number.isNaN(endTime.getTime())
  ) {
    return undefined;
  }
  timeZoneArr.forEach((time) => {
    if (startTime < time && time < endTime) {
      errorflg = true;
    }
  });

  return errorflg
    ? "早朝/日中/夜間/深夜の時間帯をまたぐ場合は、実績を分けて登録してください"
    : undefined;
};
