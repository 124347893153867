import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_202404 } from "@config";

export type PostUpdateFacilityUserParams = {
  user_in_facility: {
    id?: number | null | undefined;
    name_sei?: string | null | undefined;
    name_mei?: string | null | undefined;
    name_sei_kana?: string | null | undefined;
    name_mei_kana?: string | null | undefined;
    recipient_number?: string | null | undefined;
    none_recipient_number_flg?: number | null | undefined;
    gender?: string | null | undefined;
    classify_physical_flg?: string | null | undefined;
    classify_intelligence_flg?: string | null | undefined;
    classify_mind_flg?: string | null | undefined;
    classify_growth_flg?: string | null | undefined;
    classify_brain_flg?: string | null | undefined;
    classify_incurable_flg?: string | null | undefined;
    classify_handicapped_flg?: string | null | undefined;
    date_birth?: string | null | undefined;
    postal_code?: string | null | undefined;
    prefecture_name?: string | null | undefined;
    city_id?: number | null | undefined;
    address?: string | null | undefined;
    tel?: string | null | undefined;
    email?: string | null | undefined;
    guardian_name_sei?: string | null | undefined;
    guardian_name_mei?: string | null | undefined;
    guardian_name_sei_kana?: string | null | undefined;
    guardian_name_mei_kana?: string | null | undefined;
    guardian_relation?: string | null | undefined;
    guardian_tel?: string | null | undefined;
    memo?: string | null | undefined;

    date_begin_in_service?: string | null | undefined;
    date_end_in_service?: string | null | undefined;
    date_pay_begin?: string | null | undefined;
    date_pay_end?: string | null | undefined;
    classify_disability_support?: number | null | undefined;
    income_kind?: string | null | undefined;
    subsidized_flg?: string | null | undefined;
    subsidized_percent?: number | null | undefined;
    subsidized_yen?: number | null | undefined;
    subsidized_unit_flg?: string | null | undefined;
    subsidized_city_id?: number | null | undefined;
    uplimit_facility_flg?: string | null | undefined;
    uplimit_controlled_by?: string | null | undefined;
    uplimit_facility_number?: string | null | undefined;
    uplimit_facility_number2?: string | null | undefined;
    uplimit_facility_number3?: string | null | undefined;
    uplimit_facility_number4?: string | null | undefined;
    uplimit_facility_name?: string | null | undefined;
    uplimit_facility_name2?: string | null | undefined;
    uplimit_facility_name3?: string | null | undefined;
    uplimit_facility_name4?: string | null | undefined;
    uplimit_total_yen?: number | null | undefined;
    uplimit_total_yen2?: number | null | undefined;
    uplimit_total_yen3?: number | null | undefined;
    uplimit_total_yen4?: number | null | undefined;
    uplimit_user_load_yen?: number | null | undefined;
    uplimit_user_load_yen2?: number | null | undefined;
    uplimit_user_load_yen3?: number | null | undefined;
    uplimit_user_load_yen4?: number | null | undefined;
    uplimit_user_load_after_adjustment_yen?: number | null;
    uplimit_user_load_after_adjustment_yen2?: number | null;
    uplimit_user_load_after_adjustment_yen3?: number | null;
    uplimit_user_load_after_adjustment_yen4?: number | null;
    result_of_management?: string | null | undefined;
    uplimit_yen?: number | null | undefined;
    create_support_plan_flg?: string | null | undefined;
    date_start_not_create_support_plan?: string | null | undefined;
    same_corporation_movement_flg?: number | null | undefined;
    average_use_period_exception_flg?: number | null | undefined;
    agreed_by_contract_flg?: string | null | undefined;
    def_food?: string | null | undefined;
    def_pickup?: string | null | undefined;
    pickup_premises?: string | null | undefined;
    pay_days_agreed?: string | null | undefined;
    business_number_contract?: string | null | undefined;
    ikou_cooperation_flg?: number;
    ikou_cooperation_date?: string | null;
    regional_collaboration_meeting_flg?: number;
    regional_collaboration_meeting_start_date?: string | null;
    intensive_support_flg?: number;
    intensive_support_start_date?: string | null;

    user_charge_limit_flg?: number | null | undefined;
    date_begin_user_charge_limit?: string | null | undefined;
    date_end_user_charge_limit?: string | null | undefined;
    food_serve_addition_flg?: number | null | undefined;
    date_begin_food_serve_addition?: string | null | undefined;
    date_end_food_serve_addition?: string | null | undefined;
    care_support_payment_flg?: number | null | undefined;
    date_begin_care_support_payment?: string | null | undefined;
    date_end_care_support_payment?: string | null | undefined;
    care_support_auth_flg?: number | null | undefined;
    date_begin_care_support_auth?: string | null | undefined;
    date_end_care_support_auth?: string | null | undefined;
    plan_support_payment_flg?: number | null | undefined;
    date_begin_plan_support_payment?: string | null | undefined;
    date_end_plan_support_payment?: string | null | undefined;
    plan_support_monitor_flg?: number | null | undefined;
    date_begin_plan_support_monitor?: string | null | undefined;
    date_end_plan_support_monitor?: string | null | undefined;
    facility_id?: number | null | undefined;

    mon_scheduled_flg?: string | null | undefined;
    tue_scheduled_flg?: string | null | undefined;
    wed_scheduled_flg?: string | null | undefined;
    thu_scheduled_flg?: string | null | undefined;
    fri_scheduled_flg?: string | null | undefined;
    sat_scheduled_flg?: string | null | undefined;
    sun_scheduled_flg?: string | null | undefined;
    day_use_special_case_flg?: number | null | undefined;
    day_use_special_case_from?: string | null | undefined;
    day_use_special_case_to?: string | null | undefined;
  };
  user_in_facility_group_home?: {
    users_in_facility_id?: number | null | undefined;
    disability_class?: number | null | undefined;
    regional_transfer_for_strong_behavior_type_202104?: number;
    mental_disorder_support_type: number | null | undefined;
    income_kind_type?: number | null | undefined;
    specified_persons_disabilities_benefits?: number | null | undefined;
    facility_unit_id?: number;
    medical_care_flg?: number;
    mid_month_change_flg?: number;
    mid_month_change_disability_class?: number | null;
    mid_month_change_date?: string | null;
    daytime_facility_number?: string | null;
    daytime_facility_name?: string | null;
    daytime_support_count?: number | null;
    regional_transfer_for_strong_behavior_type_202404?: number;
    severe_disability_support_flg?: number;
    severe_disability_support_start_date?: string | null;
    transition_support_housing_date?: string | null;
  };
  user_in_facility_seikatsukaigo?: Partial<{
    users_in_facility_id: number | null;
    disability_class: number | null;
    severe_disability_support: number | null;
    severe_disability_support_start_date: string | null;
    rehabilitation: number | null;
    rehabilitation_start_date: string | null;
    income_kind: number | null;
    mid_month_change_flg?: number;
    mid_month_change_disability_class?: number | null;
    mid_month_change_date?: string | null;
  }>;
  user_in_facility_shuroteichaku?: Partial<{
    users_in_facility_id: number | null;
    income_kind: number | null;
    regional_cooperation_support_flg: number;
    regional_cooperation_support_flg_start_date: string | null;
    disability_class: number | null;
    disability_certificate_body: number | null;
    disability_certificate_spirit: number | null;
    disability_certificate_rehabilitation: number | null;
    disability_certificate_rehabilitation_other: string | null;
    classify_incurable_flg: number;
    support_system_not_implemented_flg: number;
    support_system_not_implemented_start_date: string | null;
    support_system_not_implemented_end_date: string | null;
  }>;
  companies?: Partial<{
    id: number | null;
    users_in_facility_id: number | null;
    name: string | null;
    industry: string | null;
    overview: string | null;
    address: string | null;
    department: string | null;
    working_start_date: string | null;
    remarks: string | null;
  }>;
  company_persons?: Partial<{
    id: number | null;
    users_in_facility_companies_id: number | null;
    name: string | null;
    position: string | null;
    department: string | null;
    relationship: number | null;
    tel: string | null;
    email: string | null;
  }>[];
  user_in_facility_tankinyusho?: Partial<{
    users_in_facility_id: number;
    support_type: number;
    disability_class: number;
    disability_child_class: number;
    severely_disabled_flg: number | null;
    use_type: number;
    income_kind: number;
    medical_care_flg: number;
    special_severe_disability_support_202104: number;
    severe_disability_support: number;
    end_in_service_same_corporation_movement_flg: number;
  }>;
  user_in_facility_jiritsukunren_seikatsu?: Partial<{
    users_in_facility_id: number | null;
    disability_class: number | null;
    income_kind: number | null;
    blindness_flg: number | null;
    individual_training_implementation_plan_20240401: number;
    individual_training_implementation_plan_20240401_from: string | null;
    individual_training_implementation_plan_20240401_to: string | null;
    social_life_support_flg: number | null;
  }>;
  user_in_facility_shisetsunyusho?: Partial<{
    users_in_facility_id: number;
    supplementary_benefit_flg: number;
    supplementary_benefit_yen: number | null;
    disability_class: number;
    income_kind: number;
    severe_disability_support: number;
    severe_disability_support_start_date: string | null;
    regional_life_transition2: number;
    food_breakfast_flg: number | null;
    food_lunch_flg: number | null;
    food_supper_flg: number | null;
    oral_maintenance_i_flg: number;
    oral_maintenance_i_start_date: string | null;
    oral_maintenance_i_end_date: string | null;
    oral_maintenance_ii_flg: number;
    oral_maintenance_ii_start_date: string | null;
    oral_maintenance_ii_end_date: string | null;
  }>;

  user_in_judohomonkaigo?: Partial<{
    users_in_facility_id: number;
    disability_class: number;
    income_kind: number;
    severely_disabled_support_flg: number;
    special_area_flg: number;
    date_begin_special_area: string | null;
    date_end_special_area: string | null;
    pay_months_agreed_severe_home_visit_care: number;
    pay_months_agreed_guide_severe_home_visit_care: number | null;
    approval_of_accompany_support_flg: number;
    total_newly_appointed: number;
    total_accompany_support_hours: number;
    date_accompany_support_begin: string | null;
    date_accompany_support_end: string | null;
  }>;

  user_in_facility_kyotakukaigo?: Partial<{
    users_in_facility_id: number;
    disability_class: number;
    income_kind: number;
    facility_unit_id: number | null;
    basis_training_supporter_flg: number;
    special_area_flg: number;
    date_begin_special_area: string | null;
    date_end_special_area: string | null;
    pay_months_agreed_physical_care: number;
    pay_times_agreed_physical_care: number;
    pay_months_agreed_outpatient_care_with_physical_care: number;
    pay_times_agreed_outpatient_care_with_physical_care: number;
    pay_months_agreed_housework_assistance: number;
    pay_times_agreed_housework_assistance: number;
    pay_months_agreed_outpatient_care: number;
    pay_times_agreed_outpatient_care: number;
    pay_months_agreed_getting_on_and_off: number;
  }>;
  user_in_facility_dokoengo?: Partial<{
    users_in_facility_id: number;
    disability_class: number;
    income_kind: number;
    classify_deafblind_flg: number;
    special_area_flg: number;
    date_begin_special_area: string | null;
    date_end_special_area: string | null;
    pay_months_agreed_accompanying_support: number;
  }>;
  user_in_facility_kodoengo?: Partial<{
    users_in_facility_id: number;
    disability_class: number;
    income_kind: number;
    special_area_flg: number;
    date_begin_special_area: string | null;
    date_end_special_area: string | null;
    pay_months_agreed_accompanying_support: number;
  }>;
  user_in_facility_chiikiiko?: Partial<{
    users_in_facility_id: number;
    disability_class: number;
    special_area_flg: number;
    date_begin_special_area: string | null;
    date_end_special_area: string | null;
    create_support_plan_flg: number;
    date_end_not_create_support_plan: string | null;
  }>;
  user_in_facility_keikakusodan?: Partial<{
    need_nursing_care_class: number;
    support_required_class: number;
    handicapped_child_consultation_support_flg: number;
    handicapped_child_consultation_support_from: string | null;
    handicapped_child_consultation_support_to: string | null;
    family_serial_number: number | null;
    user_in_facility_keikakusodan_shogaijisodan: Partial<{
      target_type: number;
      recipient_number: string | null;
      none_recipient_number_flg: number;
      date_begin_in_service: string;
      date_end_in_service: string | null;
      date_pay_begin: string | null;
      date_pay_end: string | null;
      disability_class: number;
      income_kind: string | null;
      income_classification: number;
      subsidized_flg: string | null;
      subsidized_unit_flg: string | null;
      subsidized_percent: number | null;
      subsidized_yen: number | null;
      subsidized_city_id: number | null;
      uplimit_facility_flg: string | null;
      uplimit_controlled_by: string | null;
      uplimit_facility_number: string | null;
      uplimit_facility_name: string | null;
      uplimit_total_yen: number | null;
      uplimit_user_load_yen: number | null;
      uplimit_user_load_after_adjustment_yen: number | null;
      uplimit_facility_number2: string | null;
      uplimit_facility_name2: string | null;
      uplimit_total_yen2: number | null;
      uplimit_user_load_yen2: number | null;
      uplimit_user_load_after_adjustment_yen2: number | null;
      uplimit_facility_number3: string | null;
      uplimit_facility_name3: string | null;
      uplimit_total_yen3: number | null;
      uplimit_user_load_yen3: number | null;
      uplimit_user_load_after_adjustment_yen3: number | null;
      uplimit_facility_number4: string | null;
      uplimit_facility_name4: string | null;
      uplimit_total_yen4: number | null;
      uplimit_user_load_yen4: number | null;
      uplimit_user_load_after_adjustment_yen4: number | null;
      result_of_management: string | null;
      special_area_flg: number;
      date_begin_special_area: string | null;
      date_end_special_area: string | null;
      disabled_welfare_service_use_flg: number;
      disabled_welfare_service_use_from: string | null;
      disabled_welfare_service_use_to: string | null;
      before_create_plan_flg: number;
      date_before_create_plan: string | null;
      before_gone_monitoring_flg: number;
      date_before_gone_monitoring: string | null;
      user_charge_limit_flg: number;
      date_begin_user_charge_limit: string | null;
      date_end_user_charge_limit: string | null;
      care_support_auth_flg: number;
      date_begin_care_support_auth: string | null;
      date_end_care_support_auth: string | null;
      plan_support_payment_flg: number;
      date_begin_plan_support_payment: string | null;
      date_end_plan_support_payment: string | null;
      plan_support_monitor_flg: number;
      date_begin_plan_support_monitor: string | null;
      date_end_plan_support_monitor: string | null;
    }>[];
  }>;
  // IAB型のみ
  work_break_times?: {
    id: number | string;
    start_time: string;
    end_time: string;
  }[];
  work_time_items?: {
    id: number | string;
    start_time: string;
    end_time: string;
    day_of_the_week: "mon" | "tue" | "wed" | "thu" | "fri" | "sat" | "sun";
  }[];
  work_break_time_items?: {
    id?: number | null;
    users_in_facility_work_time_items_id: number | string;
    users_in_facility_work_break_times_id: number | string;
    applied: number;
  }[];
  work_details?: Partial<{
    id?: number;
    work_time_use_flg: number;
    work_time_use_flg_of_week: number;
    work_truncate_minutes: number | null;
    work_start_time: string;
    work_end_time: string;
  }>;
  default_status_settings?: {
    id: number | undefined;
    status: number;
    start_time: string | null;
    end_time: string | null;
    def_food: string;
    def_pickup: string;
    pickup_premises: number;
    time_card_display_flg: number;
  }[];
};

/**
 * 事業所情報に紐付く利用者情報を更新する
 * @param id 利用者ID
 * @param values 更新する利用者情報
 */
export const postUpdateFacilityUser = async (
  id: number | undefined,
  values: PostUpdateFacilityUserParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_202404}/facility/users/${id}`;
  return request.post(url, values);
};

export default postUpdateFacilityUser;
