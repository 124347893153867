import { BasicValues } from "@interfaces/mgr/IAB/Users/basic";
import { ServiceUseValues } from "@interfaces/mgr/IAB/Users/serviceUse";
import { RecipientCertificateValues } from "@interfaces/mgr/IAB/Users/recipientCertificate";
import { WorkingTimeValues } from "@interfaces/mgr/IAB/Users/workingtime";
import { ServiceProvideStatusValues } from "@interfaces/mgr/IAB/Users/serviceProvideStatus";
import { UsersInFacilityState } from "@stores/domain/mgr/IAB/userInFacility/types";
import { dateToSelectDateValue } from "@utils/date";
import { sortBy } from "lodash-es";
import {
  SERVICE_STATUS_202104,
  DEFAULT_SELECT_VALUE,
  Checkbox,
  DISABILITY_CLASS_LIST,
  INT_TRUE_FROM_API,
  PICKUP_PREMISES_CASE_0,
  PICKUP_PREMISES_CASE_1,
  PICKUP_PREMISES_CASE_2,
  PICKUP_PREMISES_CASE_3,
  FacilityType
} from "@constants/variables";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import undefinedToReturnValue from "@utils/dataNormalizer/undefinedToReturnValue";
import {
  dowMappingSchedule,
  DowType
} from "@utils/domain/facility/dowMappingAdministration";
import { FacilityState } from "@stores/domain/mgr/IAB/facility/types";
import { UserState } from "@stores/domain/user/type";
import { OptionInterface } from "@components/atoms/DropDown";

export type UsersValues = BasicValues &
  ServiceUseValues &
  RecipientCertificateValues &
  ServiceProvideStatusValues &
  WorkingTimeValues;

const supplementWorkingTimeValues = (
  facility: FacilityState,
  state?: UsersInFacilityState["user"]
): UsersValues["workingTime"] => {
  // 営業スケジュール(作業時間項目で営業日を確認するのに使用)
  const administration = {
    mondaySchedule: facility.mondaySchedule,
    tuesdaySchedule: facility.tuesdaySchedule,
    wednesdaySchedule: facility.wednesdaySchedule,
    thursdaySchedule: facility.thursdaySchedule,
    fridaySchedule: facility.fridaySchedule,
    saturdaySchedule: facility.saturdaySchedule,
    sundaySchedule: facility.sundaySchedule
  };

  // 作業時間項目
  const workingTime = {} as WorkingTimeValues["workingTime"];

  workingTime.workDetailsID =
    state && state.work_details && !Array.isArray(state.work_details)
      ? state.work_details.id
      : undefined;

  // 利用者ごとの作業時間を設定する
  const workTimeUseFlg =
    state &&
    state.work_details &&
    !Array.isArray(state.work_details) &&
    state.work_details.work_time_use_flg
      ? state.work_details.work_time_use_flg
      : 0;

  workingTime.workTimeUseFlg = determineTrueOrFalse(workTimeUseFlg);
  workingTime.administration = administration;

  let startHor: UsersValues["workingTime"]["startHor"] = "";
  let startMin: UsersValues["workingTime"]["startMin"] = "";
  let endHor: UsersValues["workingTime"]["endHor"] = "";
  let endMin: UsersValues["workingTime"]["endMin"] = "";
  let dayOfWeekFlag = false;
  let workBreakTimes: UsersValues["workingTime"]["workBreakTimes"] = [];
  let workTimeItems: UsersValues["workingTime"]["workTimeItems"] = [];

  // work_time_use_flgの初期値でデフォルト値（事業所情報の作業時間の値）を入力するか判断
  // stateから登録情報を入力
  if (workTimeUseFlg) {
    // 作業時間の設定
    const workStartTime =
      state &&
      state.work_details &&
      !Array.isArray(state.work_details) &&
      state.work_details.work_start_time
        ? state.work_details.work_start_time.split(":")
        : ["", ""];
    const workEndTime =
      state &&
      state.work_details &&
      !Array.isArray(state.work_details) &&
      state.work_details.work_end_time
        ? state.work_details.work_end_time.split(":")
        : ["", ""];
    [startHor, startMin] = workStartTime;
    [endHor, endMin] = workEndTime;

    // 休憩時間
    workBreakTimes =
      state && state.work_break_times
        ? state.work_break_times.map((workBreakTimeUser, index) => {
            const startTime = workBreakTimeUser.start_time.split(":");
            const endTime = workBreakTimeUser.end_time.split(":");
            return {
              index: index + 1,
              id: workBreakTimeUser.id,
              startTimeHour: startTime[0] || "",
              startTimeMinute: startTime[1] || "",
              endTimeHour: endTime[0] || "",
              endTimeMinute: endTime[1] || "",
              isDeleted: false
            };
          })
        : [];
    // 刻み時間
    workingTime.unitEngrave =
      state &&
      state.work_details &&
      !Array.isArray(state.work_details) &&
      state.work_details.work_truncate_minutes
        ? `${state.work_details.work_truncate_minutes}`
        : "0";

    // // 曜日ごとの作業時間
    if (state && state.work_time_items && state.work_time_items.length > 0) {
      state.work_time_items.forEach((workTimeItem) => {
        const startTime = workTimeItem.start_time
          ? workTimeItem.start_time.split(":")
          : ["", ""];
        const endTime = workTimeItem.end_time
          ? workTimeItem.end_time.split(":")
          : ["", ""];
        // この曜日の休憩時間設定 ※復元用に保持する
        const workBreakTimeMaster = state.work_break_time_items
          ? state.work_break_time_items.filter(
              (v) => v.users_in_facility_work_time_items_id === workTimeItem.id
            )
          : [];
        // 曜日ごとの休憩時間(applied === 1のid)
        const workBreakTimeItems = workBreakTimeMaster
          .filter((v) => v.applied)
          .map((v) => v.users_in_facility_work_break_times_id);

        // 曜日
        const { day_of_the_week } = workTimeItem;

        // 営業日
        const isBusinessDay =
          administration[dowMappingSchedule[day_of_the_week]];

        // 営業日の曜日ごとの設定が全て作業時間と休憩時間がマスターの設定と同じであれば、変更設定をしていると見なす
        // 休憩時間の一致は全ての作業時間を設定しているかと等しいので、lengthの比較で出来る
        if (
          !dayOfWeekFlag &&
          isBusinessDay &&
          (startHor !== startTime[0] ||
            startMin !== startTime[1] ||
            endHor !== endTime[0] ||
            endMin !== endTime[1] ||
            workBreakTimes.length !== workBreakTimeItems.length)
        ) {
          dayOfWeekFlag = true;
        }

        workTimeItems.push({
          day_of_the_week,
          workBreakTimeMaster,
          workBreakTimeItems,
          id: workTimeItem.id,
          startTimeHour: startTime[0],
          startTimeMinute: startTime[1],
          endTimeHour: endTime[0],
          endTimeMinute: endTime[1]
        });
      });
    } else {
      // 初回時などのworkTimeItemsがない時に初期値を生成する
      workTimeItems = Object.keys(dowMappingSchedule).map((dow, i) => {
        return {
          id: `new#${i}`,
          day_of_the_week: dow as DowType,
          startTimeHour: startHor,
          startTimeMinute: startMin,
          endTimeHour: endHor,
          endTimeMinute: endMin,
          workBreakTimeItems: workBreakTimes.map((v) => v.id)
        };
      });
    }
    workingTime.startHor = startHor;
    workingTime.startMin = startMin;
    workingTime.endHor = endHor;
    workingTime.endMin = endMin;
    workingTime.workBreakTimes = workBreakTimes;
    workingTime.workTimeItems = workTimeItems;
    workingTime.dayOfWeekFlag = dayOfWeekFlag;
    return workingTime;
  }
  // デフォルト値（事業所情報の作業時間の値）を入力
  // 作業時間の設定
  startHor = facility.startHor;
  startMin = facility.startMin;
  endHor = facility.endHor;
  endMin = facility.endMin;

  // 休憩時間
  workBreakTimes = facility.workBreakTimes.map((workBreakTime, index) => {
    const startTime = workBreakTime.start_time.split(":");
    const endTime = workBreakTime.end_time.split(":");
    return {
      index: index + 1,
      id: `#new${index}`,
      // 事業所情報の作業時間
      defaultId: workBreakTime.id,
      startTimeHour: startTime[0] || "",
      startTimeMinute: startTime[1] || "",
      endTimeHour: endTime[0] || "",
      endTimeMinute: endTime[1] || "",
      isDeleted: false
    };
  });
  // 刻み時間(デフォルト値は事業者情報の作業時間項目の値)
  workingTime.unitEngrave = facility.unitEngrave
    ? `${facility.unitEngrave}`
    : "0";

  // 曜日ごとの作業時間
  if (facility.workTimeItems && facility.workTimeItems.length > 0) {
    facility.workTimeItems.forEach((workTimeItem, index) => {
      const startTime = workTimeItem.start_time
        ? workTimeItem.start_time.split(":")
        : ["", ""];
      const endTime = workTimeItem.end_time
        ? workTimeItem.end_time.split(":")
        : ["", ""];
      // この曜日の休憩時間設定(IDの配列)
      const workBreakTimeIds = facility.workBreakTimeItems
        ? facility.workBreakTimeItems
            .filter((v) => v.work_time_item_id === workTimeItem.id && v.applied)
            .map((v) => v.work_break_time_id)
        : [];
      // 曜日ごとの休憩時間(applied === 1のid)
      const workBreakTimeItems = workBreakTimes
        .filter((v) => v.defaultId && workBreakTimeIds.includes(v.defaultId))
        .map((v) => v.id);

      // 曜日
      const { day_of_the_week } = workTimeItem;

      // 営業日
      const isBusinessDay = administration[dowMappingSchedule[day_of_the_week]];

      // 既にworkTimeItemsが登録されている場合を考慮
      const workTimeItemsUserInFacility =
        state &&
        state.work_time_items &&
        state.work_time_items.find(
          (userTimeItem) => userTimeItem.day_of_the_week === day_of_the_week
        );

      const workTimeItemsIdUserInFacility = workTimeItemsUserInFacility
        ? workTimeItemsUserInFacility.id
        : null;

      // 営業日の曜日ごとの設定が全て作業時間と休憩時間がマスターの設定と同じであれば、変更設定をしていると見なす
      // 休憩時間の一致は全ての作業時間を設定しているかと等しいので、lengthの比較で出来る
      if (
        !dayOfWeekFlag &&
        isBusinessDay &&
        (startHor !== startTime[0] ||
          startMin !== startTime[1] ||
          endHor !== endTime[0] ||
          endMin !== endTime[1] ||
          workBreakTimes.length !== workBreakTimeItems.length)
      ) {
        dayOfWeekFlag = true;
      }

      workTimeItems.push({
        day_of_the_week,
        workBreakTimeItems,
        id: workTimeItemsIdUserInFacility || `#new${index}`,
        startTimeHour: startTime[0],
        startTimeMinute: startTime[1],
        endTimeHour: endTime[0],
        endTimeMinute: endTime[1]
      });
    });
  } else {
    // 初回時などのworkTimeItemsがない時に初期値を生成する
    workTimeItems = Object.keys(dowMappingSchedule).map((dow, i) => {
      // 既にworkTimeItemsが登録されている場合を考慮
      const workTimeItemsUserInFacility =
        state &&
        state.work_time_items &&
        state.work_time_items.find(
          (userTimeItem) => userTimeItem.day_of_the_week === dow
        );
      const workTimeItemsIdUserInFacility = workTimeItemsUserInFacility
        ? workTimeItemsUserInFacility.id
        : null;

      return {
        id: workTimeItemsIdUserInFacility || `new#${i}`,
        day_of_the_week: dow as DowType,
        startTimeHour: startHor,
        startTimeMinute: startMin,
        endTimeHour: endHor,
        endTimeMinute: endMin,
        workBreakTimeItems: []
      };
    });
  }
  workingTime.startHor = startHor;
  workingTime.startMin = startMin;
  workingTime.endHor = endHor;
  workingTime.endMin = endMin;
  workingTime.workBreakTimes = workBreakTimes;
  workingTime.workTimeItems = workTimeItems;
  workingTime.dayOfWeekFlag = dayOfWeekFlag;
  return workingTime;
};

const supplementServiceProvideStatusValues = (
  user?: UserState,
  state?: UsersInFacilityState["user"]
): UsersValues["serviceProvideStatus"] => {
  // サービス提供状況項目
  const serviceProvideStatus = {} as ServiceProvideStatusValues["serviceProvideStatus"];
  const serviceProvideStatusItems: UsersValues["serviceProvideStatus"]["serviceProvideStatusItems"] = [];

  // 送迎の値に対応する同一敷地内送迎のリストを使用する
  const assignPickupPremiseList = (def_pickup: string): OptionInterface[] => {
    switch (def_pickup) {
      case "0":
        return PICKUP_PREMISES_CASE_0;
      case "1":
        return PICKUP_PREMISES_CASE_1;
      case "2":
        return PICKUP_PREMISES_CASE_2;
      case "3":
        return PICKUP_PREMISES_CASE_3;
      default:
        return PICKUP_PREMISES_CASE_0;
    }
  };

  let pickupPremiseList = assignPickupPremiseList(
    state && state.user_in_facility.def_pickup
      ? state.user_in_facility.def_pickup
      : "0"
  );

  const serviceProvideStatusItem = {
    id: undefined,
    status: 0,
    startTime: "",
    endTime: "",
    supplyFood:
      state && state.user_in_facility.def_food
        ? state.user_in_facility.def_food
        : "1",
    pickup:
      state && state.user_in_facility.def_pickup
        ? state.user_in_facility.def_pickup
        : "0",
    pickupPremise:
      state && state.user_in_facility.pickup_premises
        ? state.user_in_facility.pickup_premises
        : "0",
    pickupPremiseList,
    timeCardShow: false
  };
  if (
    state &&
    state.default_status_settings &&
    state.default_status_settings.length > 0
  ) {
    state.default_status_settings.forEach((item) => {
      pickupPremiseList = assignPickupPremiseList(item.def_pickup);
      serviceProvideStatusItems.push({
        id: item.id,
        status: item.status,
        startTime: item.start_time ? item.start_time.substring(0, 5) : "",
        endTime: item.end_time ? item.end_time.substring(0, 5) : "",
        supplyFood: undefinedToReturnValue(item.def_food, "1"),
        pickup: undefinedToReturnValue(item.def_pickup, "0"),
        pickupPremise: undefinedToReturnValue(
          item.pickup_premises.toString(),
          "0"
        ),
        pickupPremiseList,
        timeCardShow: determineTrueOrFalse(item.time_card_display_flg)
      });
    });
  }
  // 既存のitemsになければ追加する
  const fillItems = (
    currentItems: UsersValues["serviceProvideStatus"]["serviceProvideStatusItems"],
    serviceStatus: number,
    isTimeCardShow: boolean
  ): void => {
    if (!currentItems.find((item) => item.status === serviceStatus)) {
      const timeCardShow = isTimeCardShow ? { timeCardShow: true } : {};
      currentItems.push({
        ...serviceProvideStatusItem,
        ...timeCardShow,
        status: serviceStatus
      });
    }
  };
  // 通所
  fillItems(serviceProvideStatusItems, SERVICE_STATUS_202104[1].value, true);
  // その他
  if (user) {
    switch (user.facility_type) {
      case FacilityType.IKOU:
        // 7: 移行準備支援(I), 8: 旧 移行準備支援(II)
        [7, 8].forEach((statusIndex) => {
          fillItems(
            serviceProvideStatusItems,
            SERVICE_STATUS_202104[statusIndex].value,
            false
          );
        });
        break;
      case FacilityType.A:
      case FacilityType.B:
        // 2: 施設外就労, 3: 施設外支援
        [2, 3].forEach((statusIndex) => {
          fillItems(
            serviceProvideStatusItems,
            SERVICE_STATUS_202104[statusIndex].value,
            false
          );
        });
        break;
      default:
    }
  }
  sortBy(serviceProvideStatusItems, (item) => item.status);
  serviceProvideStatus.serviceProvideStatusItems = serviceProvideStatusItems;
  return serviceProvideStatus;
};

const initialValues = (
  facility: FacilityState,
  state?: UsersInFacilityState["user"],
  user?: UserState
): UsersValues => {
  const userInFacility = state ? state.user_in_facility : {};
  const incomeKind =
    userInFacility.income_kind !== "0" ? userInFacility.income_kind : undefined;
  const workingTime = supplementWorkingTimeValues(facility, state);
  const serviceProvideStatus = supplementServiceProvideStatusValues(
    user,
    state
  );
  return {
    basic: {
      nameSei: undefinedToReturnValue(userInFacility.name_sei),
      nameMei: undefinedToReturnValue(userInFacility.name_mei),
      nameSeiKana: undefinedToReturnValue(userInFacility.name_sei_kana),
      nameMeiKana: undefinedToReturnValue(userInFacility.name_mei_kana),
      recipientNumber: undefinedToReturnValue(userInFacility.recipient_number),
      noneRecipientNumberFlag: determineTrueOrFalse(
        userInFacility.none_recipient_number_flg
      ),
      gender: undefinedToReturnValue(userInFacility.gender, 1),
      classifyPhysicalFlag: determineTrueOrFalse(
        userInFacility.classify_physical_flg
      ),
      classifyIntelligenceFlag: determineTrueOrFalse(
        userInFacility.classify_intelligence_flg
      ),
      classifyMindFlag: determineTrueOrFalse(userInFacility.classify_mind_flg),
      classifyGrowthFlag: determineTrueOrFalse(
        userInFacility.classify_growth_flg
      ),
      classifyBrainFlag: determineTrueOrFalse(
        userInFacility.classify_brain_flg
      ),
      classifyIncurableFlag: determineTrueOrFalse(
        userInFacility.classify_incurable_flg
      ),
      classifyHandicappedFlag: determineTrueOrFalse(
        userInFacility.classify_handicapped_flg
      ),
      dateOfBirth: emptyToNotSelected(
        dateToSelectDateValue(undefinedToReturnValue(userInFacility.date_birth))
      ),
      postalCode: undefinedToReturnValue(userInFacility.postal_code),
      prefectureId: userInFacility.prefecture_name || DEFAULT_SELECT_VALUE,
      cityId: userInFacility.city_id
        ? `${userInFacility.city_id}`
        : DEFAULT_SELECT_VALUE,
      restAddress: undefinedToReturnValue(userInFacility.address),
      tel: undefinedToReturnValue(userInFacility.tel),
      email: undefinedToReturnValue(userInFacility.email),
      guardianName: undefinedToReturnValue(userInFacility.guardian_name),
      guardianRelation: undefinedToReturnValue(
        userInFacility.guardian_relation
      ),
      guardianTel: undefinedToReturnValue(userInFacility.guardian_tel),
      memo: undefinedToReturnValue(userInFacility.memo)
    },
    serviceUse: {
      inServiceStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_in_service)
        )
      ),
      inServiceEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_in_service)
        )
      ),
      sameCorporationMovementFlg: determineTrueOrFalse(
        userInFacility.same_corporation_movement_flg
      ),
      averageUsePeriodExceptionFlg: determineTrueOrFalse(
        userInFacility.average_use_period_exception_flg
      ),
      payStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_begin)
        )
      ),
      payEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_pay_end)
        )
      ),
      classifyDisabilitySupport:
        userInFacility.classify_disability_support ||
        DISABILITY_CLASS_LIST[0].value,
      agreedByContractFlg: undefinedToReturnValue(
        userInFacility.agreed_by_contract_flg,
        1
      ),
      incomeKind: undefinedToReturnValue(incomeKind, 1),
      incomeKindType: undefinedToReturnValue(
        userInFacility.income_kind_type,
        undefined
      ),
      subsidizedFlag: determineTrueOrFalse(userInFacility.subsidized_flg),
      subsidizedPercent: undefinedToReturnValue(
        userInFacility.subsidized_percent
      ),
      subsidizedYen: undefinedToReturnValue(userInFacility.subsidized_yen),
      subsidizedUnit: undefinedToReturnValue(
        userInFacility.subsidized_unit_flg,
        1
      ),
      subsidizedCityId: userInFacility.subsidized_city_id
        ? `${userInFacility.subsidized_city_id}`
        : DEFAULT_SELECT_VALUE,
      upperLimitFacilityFlag: determineTrueOrFalse(
        userInFacility.uplimit_facility_flg
      ),
      upperLimitControlledBy: undefinedToReturnValue(
        userInFacility.uplimit_controlled_by,
        1
      ),
      upperLimitFacilityNumber: undefinedToReturnValue(
        userInFacility.uplimit_facility_number
      ),
      upperLimitFacilityNumber2: undefinedToReturnValue(
        userInFacility.uplimit_facility_number2
      ),
      upperLimitFacilityNumber3: undefinedToReturnValue(
        userInFacility.uplimit_facility_number3
      ),
      upperLimitFacilityNumber4: undefinedToReturnValue(
        userInFacility.uplimit_facility_number4
      ),
      upperLimitFacilityName: undefinedToReturnValue(
        userInFacility.uplimit_facility_name
      ),
      upperLimitFacilityName2: undefinedToReturnValue(
        userInFacility.uplimit_facility_name2
      ),
      upperLimitFacilityName3: undefinedToReturnValue(
        userInFacility.uplimit_facility_name3
      ),
      upperLimitFacilityName4: undefinedToReturnValue(
        userInFacility.uplimit_facility_name4
      ),
      upperLimitTotalYen: undefinedToReturnValue(
        userInFacility.uplimit_total_yen
      ),
      upperLimitTotalYen2: undefinedToReturnValue(
        userInFacility.uplimit_total_yen2
      ),
      upperLimitTotalYen3: undefinedToReturnValue(
        userInFacility.uplimit_total_yen3
      ),
      upperLimitTotalYen4: undefinedToReturnValue(
        userInFacility.uplimit_total_yen4
      ),
      upperLimitUserLoadYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen
      ),
      upperLimitUserLoadYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen2
      ),
      upperLimitUserLoadYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen3
      ),
      upperLimitUserLoadYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_yen4
      ),
      uplimitUserLoadAfterAdjustmentYen: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen
      ),
      uplimitUserLoadAfterAdjustmentYen2: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen2
      ),
      uplimitUserLoadAfterAdjustmentYen3: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen3
      ),
      uplimitUserLoadAfterAdjustmentYen4: undefinedToReturnValue(
        userInFacility.uplimit_user_load_after_adjustment_yen4
      ),
      resultOfManagement: undefinedToReturnValue(
        userInFacility.result_of_management,
        1
      ),
      upperLimitYen: undefinedToReturnValue(userInFacility.uplimit_yen),
      createSupportPlanFlag:
        userInFacility.create_support_plan_flg === Checkbox.OFF || false,
      notCreateSupportPlanStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(
            userInFacility.date_start_not_create_support_plan
          )
        )
      ),
      defFood: undefinedToReturnValue(userInFacility.def_food, 1),
      defPickup: undefinedToReturnValue(userInFacility.def_pickup, 0),
      pickupPremises: undefinedToReturnValue(userInFacility.pickup_premises, 0),
      payDaysAgreed: undefinedToReturnValue(userInFacility.pay_days_agreed),
      businessNumberContract: undefinedToReturnValue(
        userInFacility.business_number_contract
      ),
      ikouCooperationFlg:
        userInFacility.ikou_cooperation_flg === INT_TRUE_FROM_API,
      ikouCooperationDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.ikou_cooperation_date)
        )
      ),
      regionalCollaborationMeetingFlg:
        userInFacility.regional_collaboration_meeting_flg === INT_TRUE_FROM_API,
      regionalCollaborationMeetingStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(
            userInFacility.regional_collaboration_meeting_start_date
          )
        )
      ),
      intensiveSupportFlg:
        userInFacility.intensive_support_flg === Checkbox.ON || false,
      intensiveSupportStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.intensive_support_start_date)
        )
      ),
      monScheduledFlg: determineTrueOrFalse(
        userInFacility.mon_scheduled_flg || Checkbox.ON
      ),
      tueScheduledFlg: determineTrueOrFalse(
        userInFacility.tue_scheduled_flg || Checkbox.ON
      ),
      wedScheduledFlg: determineTrueOrFalse(
        userInFacility.wed_scheduled_flg || Checkbox.ON
      ),
      thuScheduledFlg: determineTrueOrFalse(
        userInFacility.thu_scheduled_flg || Checkbox.ON
      ),
      friScheduledFlg: determineTrueOrFalse(
        userInFacility.fri_scheduled_flg || Checkbox.ON
      ),
      satScheduledFlg: determineTrueOrFalse(userInFacility.sat_scheduled_flg),
      sunScheduledFlg: determineTrueOrFalse(userInFacility.sun_scheduled_flg),
      aTargetForReductionFlg: determineTrueOrFalse(
        userInFacility.a_target_for_reduction_flg
      ),
      defRecordWork: determineTrueOrFalse(
        userInFacility.def_record_work || Checkbox.ON
      ),
      dayUseSpecialCaseFlag: determineTrueOrFalse(
        userInFacility.day_use_special_case_flg
      ),
      dayUseSpecialCaseStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.day_use_special_case_from)
        )
      ),
      dayUseSpecialCaseEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.day_use_special_case_to)
        )
      )
    },
    serviceProvideStatus,
    workingTime,
    recipientCertificate: {
      userChargeLimitFlag: determineTrueOrFalse(
        userInFacility.user_charge_limit_flg
      ),
      userChargeLimitStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_user_charge_limit)
        )
      ),
      userChargeLimitEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_user_charge_limit)
        )
      ),
      foodServeAdditionFlg: determineTrueOrFalse(
        userInFacility.food_serve_addition_flg
      ),
      foodServeAdditionStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_food_serve_addition)
        )
      ),
      foodServeAdditionEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_food_serve_addition)
        )
      ),
      careSupportAuthFlag: determineTrueOrFalse(
        userInFacility.care_support_auth_flg
      ),
      careSupportAuthStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_auth)
        )
      ),
      careSupportAuthEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_auth)
        )
      ),
      careSupportPaymentFlag: determineTrueOrFalse(
        userInFacility.care_support_payment_flg
      ),
      careSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_care_support_payment)
        )
      ),
      careSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_care_support_payment)
        )
      ),
      planSupportPaymentFlag: determineTrueOrFalse(
        userInFacility.plan_support_payment_flg
      ),
      planSupportPaymentStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_payment)
        )
      ),
      planSupportPaymentEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_payment)
        )
      ),
      planSupportMonitorFlag: determineTrueOrFalse(
        userInFacility.plan_support_monitor_flg
      ),
      planSupportMonitorStartDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_begin_plan_support_monitor)
        )
      ),
      planSupportMonitorEndDate: emptyToNotSelected(
        dateToSelectDateValue(
          undefinedToReturnValue(userInFacility.date_end_plan_support_monitor)
        )
      )
    }
  };
};

export default initialValues;
