import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";

// ui
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import AdminTemplate from "@components/templates/AdminTemplate";
import { SupportLedgerSheet } from "@components/organisms/mgr/CHIIKITEICHAKU/record/supportLedger/sheet/SupportLedgerSheet";
import { UserInfoRecord } from "@components/organisms/mgr/common/record/UserInfoRecord";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";

// utils
import * as URL from "@constants/url";
import { FacilityType } from "@constants/variables";
import { FacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/facility/types";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: 16
    },
    stickyWrapper: {
      paddingBottom: 16,
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    }
  });

type OwnProps = RouteComponentProps<{
  uifId: string;
  supportLedgerId: string;
  copyId: string;
}>;

type StateProps = {
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  facilityType: FacilityType;
};

type DispatchProps = {
  fetchOne: (uifId: string, facilityType: FacilityType) => void;
};

type MergeProps = StateProps &
  DispatchProps & {
    userName: string;
  };

type Props = MergeProps & OwnProps & WithStyles<typeof styles>;
/**
 * 利用者ごと > 地域定着支援台帳
 */
const SupportLedgerCore = (props: Props): JSX.Element => {
  const { uifId, supportLedgerId, copyId } = props.match.params;
  const { classes, facilityType } = props;
  const [isNew] = React.useState(supportLedgerId === "new"); // 新規作成
  const [isView] = React.useState(!isNew && !copyId); // 表示
  const [isCopy] = React.useState(!!copyId); // コピー中
  const [isEditing, setEditing] = React.useState(isNew || !isView || isCopy); // 編集ができる状態かどうか

  // ヘッダーのパンくず構成
  const pathList = [
    { pathName: "利用者ごと", path: URL.RECORD_USERS_SUMMARY },
    {
      pathName: "地域定着支援台帳",
      path: `/record/support_plan/${uifId}/support_ledger`
    }
  ];

  // 現在ページのタイトル名
  const pageName = "地域定着支援台帳";

  React.useEffect(() => {
    props.fetchOne(uifId, facilityType);
  }, []);

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={classes.stickyWrapper} />
      <div className={classes.wrapper}>
        <UserInfoRecord user={props.user} />
      </div>
      <div className={classes.wrapper}>
        <SupportLedgerSheet
          user={props.user}
          userName={props.userName}
          supportLedgerId={supportLedgerId}
          copyId={copyId}
          uifId={uifId}
          history={props.history}
          isNew={isNew}
          isView={isView}
          isCopy={isCopy}
          isEditing={isEditing}
          setEditing={setEditing}
          facilityType={facilityType}
          facility={props.facility}
        />
      </div>
      <NavigationTransitionPrompt />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facility: state.CHIIKITEICHAKU.facility,
    facilityType,
    user: state.CHIIKITEICHAKU.userInFacility.user
  };
};
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchOne: (uifId: string): void => {
      dispatches.CHIIKITEICHAKU.userInFacilityDispatcher(dispatch).fetchOne(
        uifId
      );
      dispatches.CHIIKITEICHAKU.facilityDispatcher(dispatch).fetch();
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 該当者の名前を取得する
  const { name_sei = "", name_mei = "" } = stateProps.user.user_in_facility;
  const userName = `${name_sei} ${name_mei}`;
  return {
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportLedger = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(SupportLedgerCore));
