import * as React from "react";
import { BASE_PUBLIC_URL } from "@config";

// ui
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import AdminTemplate from "@components/templates/AdminTemplate";

// store
import { connect } from "react-redux";
import dispatches from "@stores/dispatches";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import { GetFacilityUsersResponse } from "@api/requests/facility/getFacilityUsers";

import { FacilityType, SUPPORT_CARE_PLAN_NAME } from "@constants/variables";
import { dateInYYYYMMFormat } from "@utils/date";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      padding: "32px",
      backgroundColor: "#fff",
      width: "calc(100% - 16px * 2)",
      margin: "24px auto 0",
      borderRadius: "4px"
    },
    inner: {
      borderStyle: "solid",
      borderColor: "#e5e5e5",
      borderWidth: "1px 0"
    },
    message: {
      fontSize: "16px",
      lineHeight: 1.75,
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "center",
      padding: "48px 32px"
    },
    item: {
      padding: "14px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:nth-child(2n)": {
        background: "#f5f5f5"
      }
    },
    itemName: {
      margin: 0,
      lineHeight: 1,
      fontSize: "16px",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    itemList: {
      display: "flex",
      alignItems: "center"
    },
    ML14: {
      marginLeft: 14
    },
    ML16: {
      marginLeft: 16
    }
  });

type OwnProps = WithStyles<typeof styles>;
type StateProps = {
  facilityType: FacilityType;
  facilityUsers: GetFacilityUsersResponse["data"];
};
type DispatchProps = {
  fetchUsers: (facility_type: FacilityType) => Promise<void>;
};
type MergeProps = OwnProps & StateProps & DispatchProps;

const SupportPlanUsersSummaryCore = (props: MergeProps): JSX.Element => {
  const { facilityType, facilityUsers } = props;

  React.useEffect(() => {
    props.fetchUsers(facilityType);
  }, []);

  const getCarePlanKnowbeButtonProps = (
    item: GetFacilityUsersResponse["data"][0]
  ): { href: string; minWidth?: number } => {
    const defaultProps = {
      href: `${BASE_PUBLIC_URL}#/record/support_plan/${item.uif_id}/care_plan`
    };
    if (facilityType === FacilityType.JUDOHOMONKAIGO) {
      return { ...defaultProps, minWidth: 158 };
    }
    return defaultProps;
  };

  return (
    <AdminTemplate pageName="支援計画">
      <div className={props.classes.wrapper}>
        {facilityUsers.length > 0 ? (
          <div className={props.classes.inner}>
            {facilityUsers.map((item) => {
              const CarePlanKnowbeButtonProps = getCarePlanKnowbeButtonProps(
                item
              );
              return (
                <div className={props.classes.item} key={item.uif_id}>
                  <p className={props.classes.itemName}>{item.displayName}</p>
                  <div className={props.classes.itemList}>
                    <div>
                      <KnowbeButton
                        kind="outlineWhite"
                        href={`${BASE_PUBLIC_URL}#/record/support_plan/${item.uif_id}/assessment`}
                      >
                        アセスメント
                      </KnowbeButton>
                    </div>
                    <div
                      className={
                        facilityType === FacilityType.JUDOHOMONKAIGO
                          ? props.classes.ML16
                          : props.classes.ML14
                      }
                    >
                      <KnowbeButton
                        kind="outlineWhite"
                        {...CarePlanKnowbeButtonProps}
                      >
                        {SUPPORT_CARE_PLAN_NAME[facilityType]}
                      </KnowbeButton>
                    </div>
                    {facilityType === FacilityType.KODOENGO ? (
                      <>
                        <div className={props.classes.ML14}>
                          <KnowbeButton
                            kind="outlineWhite"
                            href={`${BASE_PUBLIC_URL}#/record/support_plan/${item.uif_id}/support_plan_sheets`}
                          >
                            支援計画シート
                          </KnowbeButton>
                        </div>
                        <div className={props.classes.ML14}>
                          <KnowbeButton
                            kind="outlineWhite"
                            href={`${BASE_PUBLIC_URL}#/record/support_plan/${
                              item.uif_id
                            }/support_procedure/list/${dateInYYYYMMFormat(
                              new Date()
                            )}`}
                          >
                            支援手順書 兼 記録用紙
                          </KnowbeButton>
                        </div>
                      </>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={props.classes.message}>
            利用者が登録されていません。利用者情報画面で登録後、ご利用ください。
          </div>
        )}
      </div>
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    facilityType,
    facilityUsers: state[facilityType].userInFacility.users
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    fetchUsers: (facility_type: FacilityType): Promise<void> =>
      dispatches[facility_type].userInFacilityDispatcher(dispatch).fetch()
  };
};

export const SupportPlanUsersSummary = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SupportPlanUsersSummaryCore)
);
