/**
 * 時間形式できた値を時と分で合算した値にして返す
 * 形式が条件を満たしていないと引数の値をそのまま返す
 * @param time hh:mm形式の値（hh:mm:ss形式も可）
 */
const convertHHMMToMinutes = (time: string): number | string => {
  if (!/^[0-9]{2}:[0-9]{2}/.test(time)) {
    return time;
  }
  const [hour, minute] = time.split(":");
  const resHour = parseInt(hour, 10);
  const resMinute = parseInt(minute, 10);
  return resHour * 60 + resMinute;
};

export default convertHHMMToMinutes;
