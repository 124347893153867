import { AxiosResponse } from "axios";
import request from "@api/index";
import { COMMON_VERSION_URL } from "@config";
import { NewMobileAccountParams } from "@stores/domain/account/type";

/**
 * モバイルアカウントの作成
 * @param data
 */
export const postNewMobileUser = async (
  data: NewMobileAccountParams
): Promise<AxiosResponse<unknown>> => {
  const url = `${COMMON_VERSION_URL}/mobile_user/new`;
  return request.post(url, data);
};
