export const MOCK_BASE_URL = "http://localhost:8103";

// swagger mock サーバーに向けるURL （使用しない場合は空配列）
export const MOCKED_URLS: {
  get: string[];
  post: string[];
  postAll: string[];
  put: string[];
  delete: string[];
} = {
  // sample: [`${VERSION_URL_202104}/facility/users`]
  get: [],
  post: [],
  postAll: [],
  put: [],
  delete: []
};
