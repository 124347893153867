/**
 * ActionNames
 */
export const FETCH_STARTED = "SHUROTEICHAKU/REPORT_201910/FETCH_STARTED";
export const FETCH = "SHUROTEICHAKU/REPORT_201910/FETCH";
export const FETCH_FAILED = "SHUROTEICHAKU/REPORT_201910/FETCH_FAILED";
export const POST_STARTED = "SHUROTEICHAKU/REPORT_201910/POST_STARTED";
export const POST = "SHUROTEICHAKU/REPORT_201910/POST";
export const POST_FAILED = "SHUROTEICHAKU/REPORT_201910/POST_FAILED";
export const UNSET_REPORT = "SHUROTEICHAKU/REPORT_201910/UNSET_REPORT";

/**
 * State
 */
export type UsageResultsState = {
  usageResults: UsageResult[];
};
export type UsageResult = {
  // 利用者ID
  uifId: number | null;
  // 姓
  nameSei: string | null;
  // 名
  nameMei: string | null;
  // 対象年月日
  targetDate: string | null;
  // サービス提供の状況
  statusType: number | null;
  // 特別地域加算
  specialAreaFlg: number | null;
  // 備考
  remarks: string | null;
  // 休日判定
  isHoliday: boolean | null;
};
