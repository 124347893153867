/**
 * 配列内の年月の重複チェック
 * 配列内の重複している要素の内、2つ目以降の要素の場合にエラーを返却する
 * @param date
 * @param array
 */

import { SelectMonthValue } from "@interfaces/ui/form";
import { selectDateValueToDate } from "@utils/date";

export const registeredYearMonth = (
  date: SelectMonthValue,
  index: number,
  array: {
    year_month: SelectMonthValue;
  }[]
): {
  year: string | undefined;
  month: string | undefined;
  day: undefined;
} => {
  let errorMessage;
  if (date.year && date.month) {
    const firstValueIndex = array.findIndex(
      (v) =>
        selectDateValueToDate({ ...v.year_month, day: "1" }) ===
        selectDateValueToDate({ ...date, day: "1" })
    );
    // 最初に見つかった値が、現在のindexと違う場合このindexは重複した月を入力しているが、indexが違うため２つ目以降の値となる
    if (firstValueIndex !== index) {
      errorMessage = "登録済みです。別の年月を選択してください";
    }
  }
  return {
    year: errorMessage,
    month: undefined,
    day: undefined
  };
};
