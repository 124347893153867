import * as React from "react";

import CreateAndUpdateDate from "@components/atoms/CreateAndUpdateDate";
import { UserDetailRecordSupportTable } from "@components/organisms/mgr/Cseg/record/UserDetailRecordSupportTable";
import { FacilityType } from "@constants/variables";
import { SupportRecordFormValues } from "@initialize/mgr/Cseg/record/support/initialValues";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { SupportRecordUserState } from "@stores/domain/mgr/Cseg/support/types";
import { isDateWithin } from "@utils/date/isDateWithin";
import { addDays, addMonths, endOfMonth, min, startOfMonth } from "date-fns";
import { SnackbarParams } from "@stores/ui/type";

const styles = (): StyleRules =>
  createStyles({
    pageWrapper: {
      backgroundColor: "#fff",
      padding: "12px 32px 20px"
    },
    outsidePeriod: {
      fontSize: "16px",
      paddingTop: "80px",
      paddingBottom: "80px",
      textAlign: "center",
      lineHeight: "28px",
      backgroundColor: "#fff",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      opacity: 1
    }
  });

type OwnProps = {
  uifId: string;
  facilityType: FacilityType;
  supportRecord: SupportRecordUserState;
  calendarSelectedDate: Date;
  dateBeginInService: string;
  setIsDisabledPrintButton: (isDisabled: boolean) => void;
  editTargetDate: string | undefined;
  setEditTargetDate: (v: string | undefined) => void;
  stopHistory: (flag: boolean) => void;
  postSupportRecordUser: (
    uifId: string,
    editingRecordDate: string,
    params: SupportRecordFormValues[],
    initialValues: SupportRecordFormValues[]
  ) => Promise<void>;
  fetchSupportRecordUser: (uifId: string, year: string, month: string) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const UserDetailRecordCore = (props: Props): JSX.Element => {
  const {
    classes,
    uifId,
    facilityType,
    supportRecord,
    calendarSelectedDate,
    dateBeginInService,
    setIsDisabledPrintButton,
    editTargetDate,
    setEditTargetDate,
    stopHistory,
    postSupportRecordUser,
    fetchSupportRecordUser,
    showSnackbar
  } = props;

  const determineFacilityTypeEndDate = React.useCallback(
    (facility: FacilityType): string | Date | undefined => {
      switch (facility) {
        case FacilityType.KEIKAKUSODAN: {
          const dateEndInService = supportRecord.date_end_in_service
            ? addMonths(supportRecord.date_end_in_service, 6)
            : undefined;
          return supportRecord.date_pay_end && dateEndInService
            ? min(supportRecord.date_pay_end, dateEndInService)
            : supportRecord.date_pay_end || dateEndInService;
        }
        case FacilityType.CHIIKIIKO:
          return supportRecord.date_end_in_service
            ? addDays(supportRecord.date_end_in_service, 30)
            : undefined;
        case FacilityType.CHIIKITEICHAKU:
          return supportRecord.date_end_in_service
            ? supportRecord.date_end_in_service
            : undefined;
        default:
          return undefined;
      }
    },
    [supportRecord]
  );

  const endDate = determineFacilityTypeEndDate(facilityType);
  const isInsidePeriod = isDateWithin(
    calendarSelectedDate,
    startOfMonth(dateBeginInService),
    endDate && endOfMonth(endDate)
  );

  React.useEffect(() => {
    const isDisabled = !(
      isInsidePeriod && supportRecord.support_records.length !== 0
    );
    setIsDisabledPrintButton(isDisabled);
  }, [supportRecord, isInsidePeriod]);

  return (
    <div className={classes.pageWrapper}>
      {isInsidePeriod ? (
        <>
          <CreateAndUpdateDate
            createdAt={supportRecord.created_at}
            updatedAt={supportRecord.updated_at}
          />
          <UserDetailRecordSupportTable
            uifId={uifId}
            supportRecord={supportRecord}
            calendarSelectedDate={calendarSelectedDate}
            dateBeginInService={dateBeginInService}
            editTargetDate={editTargetDate}
            dateEndInService={endDate}
            setEditTargetDate={setEditTargetDate}
            stopHistory={stopHistory}
            postSupportRecordUser={postSupportRecordUser}
            fetchSupportRecordUser={fetchSupportRecordUser}
            showSnackbar={showSnackbar}
          />
        </>
      ) : (
        <div className={classes.outsidePeriod}>サービス提供期間外です</div>
      )}
    </div>
  );
};

export const UserDetailRecord = withStyles(styles)(UserDetailRecordCore);
