import * as React from "react";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles,
  Theme
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import * as ClassNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { FieldItem } from "@interfaces/ui/form";
import { ArrayHelpers, FieldArray, FormikProps } from "formik";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";
import { StaffState } from "@stores/domain/staff/types";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import FormikSelect from "@components/molecules/FormikSelect";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikTextField from "@components/molecules/FormikTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { SupportPlanSheets } from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import { supportPlanSheetDetailsValue } from "@initialize/record/supportPlanSheets/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    con: {
      padding: "32px"
    },
    titleBg: {
      background: "#f5f5f5",
      padding: "5px 8px",
      fontSize: "16px",
      lineHeight: "1.75",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    flex: {
      display: "flex",
      justifyContent: "flex-start",
      "& > div": {
        width: "calc((100% - 16px*4)/5)"
      },
      "& > div > div > label": {
        whiteSpace: "nowrap"
      }
    },
    col: {
      display: "flex"
    },
    colItem: {
      width: "240px"
    },
    header: {
      display: "flex",
      width: "1030px",
      fontSize: "12px",
      lineHeight: 1,
      letterSpacing: "0.4px",
      background: "#eceff1",
      color: "#37474f",
      padding: "14px 16px",
      "& span": {
        display: "block"
      }
    },
    index: {
      width: "52px",
      minWidth: "52px"
    },
    issue: {
      width: "312px",
      minWidth: "312px"
    },
    policy: {
      width: "578px",
      minWidth: "578px",
      marginLeft: "16px"
    },
    delete: {
      width: "28px",
      height: "auto",
      minWidth: "28px",
      margin: "0 16px 0 16px"
    },
    body: {
      width: "1030px",
      padding: "0 16px",
      display: "flex",
      fontSize: "16px",
      letterSpacing: "0.5px",
      color: "rgba(0, 0, 0, 0.87)",
      borderBottom: "1px solid #e0e0e0"
    },
    indexBody: {
      width: "52px",
      minHeight: "52px",
      marginTop: "16px"
    },
    issueBody: {
      width: "312px",
      minWidth: "312px",
      marginTop: "16px"
    },
    policyBody: {
      width: "578px",
      minWidth: "578px",
      margin: "16px 0 0 16px"
    },
    deleteIcon: {
      minWidth: "32px",
      marginLeft: "10px"
    },
    btnTxt: {
      marginLeft: "8px"
    },
    MT48: {
      marginTop: spacing.unit * 6
    },
    MT32: {
      marginTop: spacing.unit * 4
    },
    MT24: {
      marginTop: spacing.unit * 3
    },
    MT16: {
      marginTop: spacing.unit * 2
    },
    ML16: {
      marginLeft: "16px"
    }
  });

type OwnProps = {
  formikProps: FormikProps<SupportPlanSheets>;
  staff: StaffState;
  staffOptions: FieldItem[];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportPlanSheetsFormCreateCore = (props: Props): JSX.Element => {
  const { classes, formikProps, staff, staffOptions } = props;

  // 役職
  const [roleValue, setRoleValue] = React.useState<string>("");
  React.useEffect(() => {
    if (formikProps.values.author !== null) {
      const select = staff.staffItems.find(
        (f) => f.staffItemId === Number(formikProps.values.author)
      );
      setRoleValue(select ? select.roleName : "");
    }
  }, [formikProps.values.author]);

  // 追加ボタン非活性
  const [isDisabledAddButton, setSsDisabledAddButton] = React.useState<boolean>(
    false
  );
  React.useEffect(() => {
    if (formikProps.values.support_plan_sheet_details.length === 20) {
      setSsDisabledAddButton(true);
    }
  }, [formikProps.values.support_plan_sheet_details]);

  return (
    <div className={classes.con}>
      <FormikSelectDateNotSelectedDefault
        name="creation_date"
        label="作成日"
        required
        setFormikFieldValue={formikProps.setFieldValue}
        addYearTo={1}
        overrideYearFrom={1989}
      />
      <Typography
        variant="subtitle1"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        インテーク（情報の収集・整理）
      </Typography>
      <div className={classes.MT32}>
        <FormikTextField
          name="intake"
          label="情報（見たこと、聴いたこと、資料などから）"
          size="fullSize"
        />
      </div>
      <Typography
        variant="subtitle2"
        className={ClassNames(classes.titleBg, classes.MT48)}
      >
        アセスメント（評価）とプランニング（支援計画）
      </Typography>
      <div className={classes.MT24}>
        <Typography>理解・解釈・仮説（わかったこと、推測したこと）</Typography>
      </div>
      <div className={classes.MT24}>
        <FormikTextField
          name="assessment_biological"
          label="生物的なこと（疾患や障害、気質など）"
          size="fullSize"
        />
      </div>
      <div className={classes.MT24}>
        <FormikTextField
          name="assessment_psychological"
          label="心理的なこと（不安、葛藤、希望、感情など）"
          size="fullSize"
        />
      </div>
      <div className={classes.MT24}>
        <FormikTextField
          name="assessment_social"
          label="社会的なこと（家庭、施設・学校、地域資源など）"
          size="fullSize"
        />
      </div>
      <div className={classes.MT48}>
        <Typography>支援課題と対応・方針</Typography>
      </div>
      <div className={classes.MT16}>
        <div className={classes.header}>
          <span className={classes.index}>No.</span>
          <span className={classes.issue}>支援課題（支援の必要なこと）</span>
          <span className={classes.policy}>対応・方針（やろうと思うこと）</span>
          <span className={classes.delete}>削除</span>
        </div>
      </div>
      <FieldArray name="support_plan_sheet_details">
        {(arrayHelpers: ArrayHelpers): JSX.Element => {
          const supportPlanSheetDetails =
            formikProps.values.support_plan_sheet_details;
          return (
            <div>
              {supportPlanSheetDetails.map((item, index) => {
                return (
                  <div className={classes.body}>
                    <span className={classes.indexBody}>
                      {circleNumbersList[index]}
                    </span>
                    <span className={classes.issueBody}>
                      <FormikTextField
                        name={`support_plan_sheet_details[${index}].support_issue`}
                        size="fullSize"
                        style={{
                          marginBottom: "16px"
                        }}
                      />
                    </span>
                    <span className={classes.policyBody}>
                      <FormikTextField
                        name={`support_plan_sheet_details[${index}].response_policy`}
                        size="fullSize"
                        style={{
                          marginBottom: "16px"
                        }}
                        multiline
                      />
                    </span>
                    {supportPlanSheetDetails.length > 1 ? (
                      <KnowbeButton
                        kind="iconText"
                        className={classes.deleteIcon}
                        onClick={(): void => arrayHelpers.remove(index)}
                      >
                        <DeleteIcon />
                      </KnowbeButton>
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
              <KnowbeButton
                kind="iconText"
                className={classes.MT16}
                onClick={(): void =>
                  arrayHelpers.push(supportPlanSheetDetailsValue)
                }
                disabled={isDisabledAddButton}
              >
                <AddIcon />
                <span className={classes.btnTxt}>
                  支援課題と対応・方針を追加
                </span>
              </KnowbeButton>
            </div>
          );
        }}
      </FieldArray>

      <div className={ClassNames(classes.col, classes.MT48)}>
        <div className={classes.colItem}>
          <FormikSelect
            name="author"
            label="作成者"
            options={staffOptions}
            placeholder="選択してください"
            isSelectablePlaceholder
            emptyText="職員の登録がありません。職員情報画面から職員を登録してください。"
          />
        </div>
        <div className={ClassNames(classes.colItem, classes.ML16)}>
          <ReadonlyTextField value={roleValue} label="役職" defaultValue="-" />
        </div>
      </div>
    </div>
  );
};

export const SupportPlanSheetsFormCreate = withStyles(styles)(
  SupportPlanSheetsFormCreateCore
);
