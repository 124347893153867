import React, { useEffect } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { UserState } from "@stores/domain/user/type";
// ui
import { ServiceDeliverySettingForm } from "@components/organisms/record/settingWithCategory/ServiceDeliverySettingForm";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { HiddenItemsWithCategoryModal } from "@components/organisms/mgr/common/record/HiddenItemsWithCategoryModal";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormPaper from "@components/atoms/FormPaper";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// formik
import { CustomRecordsValues } from "@initialize/record/customRecordWithCategory/initialValues";
// variables
import {
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO,
  FacilityType
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    topSection: {
      marginTop: 32
    },
    infoIcon: {
      color: "#0277bd",
      width: 20,
      height: 20,
      marginRight: 6
    },
    caption: {
      color: "#37474f",
      display: "flex",
      alignItems: "center"
    },
    description: {
      margin: "4px 26px",
      color: "#666666",
      lineHeight: 1.75
    }
  });

type DispatchProps = {
  fetchCustomRecords: () => Promise<void>;
  hideCustomRecord: (recordId: number, type: "item" | "category") => void;
  postCustomRecords: (formValue: CustomRecordsValues) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: { item: number[]; category: number[] },
    invisibleRecordIds: { item: number[]; category: number[] }
  ) => void;
};

type StateProps = {
  customRecords: CustomRecordsWithCategoryState;
  userState: UserState;
};

type Props = DispatchProps & StateProps & WithStyles<typeof styles>;

const SettingFormWithCategoryCore = (props: Props): JSX.Element => {
  const {
    classes,
    customRecords,
    fetchCustomRecords,
    hideCustomRecord,
    showCustomRecord
  } = props;
  const [isOpenHideConfirm, setOpenHideConfirm] = React.useState(false);
  const [
    isOpenHiddenCustomRecordList,
    setOpenHiddenCustomRecordList
  ] = React.useState(false);

  const [
    hiddenCustomRecordCategoryType,
    setHiddenCustomRecordCategoryType
  ] = React.useState(0);

  // 並べ替えモード用 state
  const [sortingCategoryType, setSortingCategoryType] = React.useState<
    null | number
  >(null);
  const [hideConfirmTargetId, setHideConfirmTargetId] = React.useState(0);

  let hideConfirmTargetName = "";
  if (hideConfirmTargetId > 0 && customRecords.length > 0) {
    const hideConfirmTargetCategory = customRecords.find((record) =>
      record.custom_record_items.find((r) => r.id === hideConfirmTargetId)
    );
    const hideConfirmTargetItem = hideConfirmTargetCategory
      ? hideConfirmTargetCategory.custom_record_items.find(
          (r) => r.id === hideConfirmTargetId
        )
      : null;
    hideConfirmTargetName = hideConfirmTargetItem
      ? hideConfirmTargetItem.name
      : "";
  }

  const openHideConfirmModal = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setHideConfirmTargetId(+event.target.value);
    setOpenHideConfirm(true);
  };
  const closeHideConfirmModal = (): void => {
    setOpenHideConfirm(false);
  };

  const onClickHide = async (): Promise<void> => {
    setOpenHideConfirm(false);
    if (hideConfirmTargetId === 0) return;
    await hideCustomRecord(hideConfirmTargetId, "item");
    fetchCustomRecords();
  };

  const openHiddenCustomRecordsList = (type: number): void => {
    setHiddenCustomRecordCategoryType(type);
    setOpenHiddenCustomRecordList(true);
  };
  const closeHiddenCustomRecordsList = (): void => {
    setOpenHiddenCustomRecordList(false);
  };
  const onClickVisible = async (
    visibleRecordIds: {
      item: number[];
      category: number[];
    },
    invisibleRecordIds: {
      item: number[];
      category: number[];
    }
  ): Promise<void> => {
    await showCustomRecord(visibleRecordIds, invisibleRecordIds);
    await setOpenHiddenCustomRecordList(false);
    await fetchCustomRecords();
  };

  useEffect(() => {
    fetchCustomRecords();
  }, []);

  const hideConfirmModalMessage = (
    <span>
      この項目は記録画面に表示されなくなり、「非表示項目リスト」に移動します。
      <br />
      「非表示項目リスト」にて、再度「表示」にすることも可能です。
    </span>
  );

  const description = (
    categoryType: number,
    facilityType: FacilityType
  ): string => {
    if (facilityType !== FacilityType.KYOTAKUKAIGO) {
      return "";
    }
    switch (categoryType) {
      case CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.basic:
        return "サービス内容にかかわらず全ての記録に表示される項目です。";
      case CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.body_care:
        return "サービス内容が「身体介護」の場合に記録に表示される項目です。";
      case CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.hospital:
        return "サービス内容が「通院等乗降介助（身体介護 伴う・伴わず）」「通院等乗降介助」の場合に記録に表示される項目です。";
      case CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.house_work:
        return "サービス内容が「家事援助」の場合に記録に表示される項目です。";
      case CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.special:
        return "サービス内容にかかわらず全ての記録に表示される項目です。";
      default:
        return "";
    }
  };

  return (
    <section className={classes.topSection}>
      <FormPaper>
        <div className={classes.caption}>
          <InfoOutlinedIcon className={classes.infoIcon} />
          この画面の設定について
        </div>
        <p className={classes.description}>
          ここで設定した内容は、作成済みのものも含めて全てのサービス提供記録に反映されます。
          <br />
          項目名を変更する際に、作成済みの記録に反映させたくない場合は、該当項目の「編集」は行わず、
          <br />
          「追加」で新しい項目を作成の上、不要な項目を「非表示」にしてください。
          <br />
          「非表示」にした場合でも、作成済みの記録で内容が入力されている箇所は消えずに残ります。
        </p>
      </FormPaper>
      {customRecords
        .sort((a, b) => a.category_type - b.category_type)
        .map((categoryData) => {
          return (
            <ServiceDeliverySettingForm
              key={categoryData.id}
              categoryData={categoryData}
              sortingCategoryType={sortingCategoryType}
              setSortingCategoryType={setSortingCategoryType}
              openHideConfirmModal={openHideConfirmModal}
              openHiddenCustomRecordsList={openHiddenCustomRecordsList}
              fetchCustomRecords={fetchCustomRecords}
              title={categoryData.name}
              description={description(
                categoryData.category_type,
                props.userState.facility_type
              )}
              facilityType={props.userState.facility_type}
            />
          );
        })}
      <MessageDialog
        isOpen={isOpenHideConfirm}
        title={`「${hideConfirmTargetName}」を非表示にしますか？`}
        message={hideConfirmModalMessage}
        closeButton={
          <KnowbeButton
            kind="text"
            style={{ margin: "0 8px 0 0" }}
            onClick={closeHideConfirmModal}
          >
            キャンセル
          </KnowbeButton>
        }
        actionButton={
          <KnowbeButton
            kind="textDelete"
            style={{ margin: 0 }}
            onClick={onClickHide}
          >
            非表示にする
          </KnowbeButton>
        }
      />
      <HiddenItemsWithCategoryModal
        isModalOpen={isOpenHiddenCustomRecordList}
        onClose={closeHiddenCustomRecordsList}
        onVisible={onClickVisible}
        hiddenCustomRecordCategoryType={hiddenCustomRecordCategoryType}
        customRecords={props.customRecords}
        isServiceDelivery
      />
    </section>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecordsWithCategory.serviceDelivery,
  userState: state.user as UserState
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { customRecordsWithCategory } = dispatches;
  const customRecordsDispatches = customRecordsWithCategory(dispatch);

  return {
    fetchCustomRecords: (): Promise<void> => {
      return customRecordsDispatches.fetchCustomRecordsService();
    },
    hideCustomRecord: (
      recordId: number,
      type: "item" | "category"
    ): Promise<void> => {
      return customRecordsDispatches.hideCustomRecord(recordId, type);
    },
    postCustomRecords: (formValue): Promise<void> => {
      return customRecordsDispatches.postCustomRecords(formValue);
    },
    showCustomRecord: (
      visibleRecordIds: {
        item: number[];
        category: number[];
      },
      invisibleRecordIds: {
        item: number[];
        category: number[];
      }
    ): Promise<void> => {
      return customRecordsDispatches.showCustomRecord(
        visibleRecordIds,
        invisibleRecordIds
      );
    }
  };
};

export const SettingFormWithCategory = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(SettingFormWithCategoryCore)
);
