import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";

type Props = {
  facility: FacilityState;
};

export const AdditionalItemFields = (props: Props): JSX.Element => {
  const isKeikaku = props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN;
  const isShogaiji =
    props.facility.facilityType === FACILITY_TARGET_TYPE_SHOGAIJISODAN;
  const isKeikakuShogaiji =
    props.facility.facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN;
  return (
    <>
      {(isKeikaku || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="加算対象項目（計画相談支援）" />
          </div>
          <FormGroup>
            <FormikCheckbox
              name="facilityInfoKeikaku.behavioralDisorderSupportFlg"
              label="行動障害支援体制"
            />
            <FormikCheckbox
              name="facilityInfoKeikaku.needMedicalCareHandicappedChildSupportFlg"
              label="要医療児者支援体制"
            />
            <FormikCheckbox
              name="facilityInfoKeikaku.mentalDisorderSupportFlg"
              label="精神障害者支援体制"
            />
            <FormikCheckbox
              name="facilityInfoKeikaku.peerSupportFlg"
              label="ピアサポート体制"
            />
            <FormikCheckbox
              name="facilityInfoKeikaku.lifeSupportHubInDistrictFlg"
              label="地域生活支援拠点等"
              style={{ marginBottom: 0 }}
            />
          </FormGroup>
        </FormPaper>
      )}
      {(isShogaiji || isKeikakuShogaiji) && (
        <FormPaper>
          <div style={{ marginBottom: 32 }}>
            <SectionTitle label="加算対象項目（障害児相談支援）" />
          </div>
          <FormGroup>
            <FormikCheckbox
              name="facilityInfoShogaiji.behavioralDisorderSupportFlg"
              label="行動障害支援体制"
            />
            <FormikCheckbox
              name="facilityInfoShogaiji.needMedicalCareHandicappedChildSupportFlg"
              label="要医療児者支援体制"
            />
            <FormikCheckbox
              name="facilityInfoShogaiji.mentalDisorderSupportFlg"
              label="精神障害者支援体制"
            />
            <FormikCheckbox
              name="facilityInfoShogaiji.peerSupportFlg"
              label="ピアサポート体制"
            />
            <FormikCheckbox
              name="facilityInfoShogaiji.lifeSupportHubInDistrictFlg"
              label="地域生活支援拠点等"
              style={{ marginBottom: 0 }}
            />
          </FormGroup>
        </FormPaper>
      )}
    </>
  );
};
