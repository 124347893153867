import { getServiceDeliveryDaily } from "./getServiceDeliveryDaily";
import { getServiceDeliveryMonthly } from "./getServiceDeliveryMonthly";
import { getServiceDeliveryDetails } from "./getServiceDeliveryDetails";
import { getServiceDeliveryPrint } from "./getServiceDeliveryPrint";
import { postServiceDeliveryDetails } from "./postServiceDeliveryDetails";
import { deleteServiceDelivery } from "./deleteServiceDelivery";

export const serviceDeliveryApi = {
  getServiceDeliveryDaily,
  getServiceDeliveryMonthly,
  getServiceDeliveryDetails,
  getServiceDeliveryPrint,
  postServiceDeliveryDetails,
  deleteServiceDelivery
};
