import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import { dayOfTheWeekLabels } from "@utils/date";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import { dowMappingSchedule, DowType } from "./dowMappingAdministration";
import { GetFacilityUserTargetIdResponse } from "@api/requests/facility/getFacilityUserTargetId";
import getCorrectWorkRecordTimes from "@utils/domain/facility/getCorrectWorkRecordTimes";

// FacilityStateの中にある、ここで必要なものだけ定義
export type PickFacilityState = {
  mondaySchedule: boolean;
  tuesdaySchedule: boolean;
  wednesdaySchedule: boolean;
  thursdaySchedule: boolean;
  fridaySchedule: boolean;
  saturdaySchedule: boolean;
  sundaySchedule: boolean;
  workBreakTimes: GetFacilityResponse["data"]["workBreakTimes"];
  workBreakTimeItems: GetFacilityResponse["data"]["workBreakTimeItems"];
  workTimeItems: GetFacilityResponse["data"]["workTimeItems"];
};
export type BreakTimes = {
  id: number;
  facility_id: number | undefined;
  start_time: string;
  end_time: string;
}[];

// FacilityStateの中にある、ここで必要なものだけ定義
export type PickUserState = {
  user_in_facility: {
    facility_id?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["facility_id"];
    mon_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["mon_scheduled_flg"];
    tue_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["tue_scheduled_flg"];
    wed_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["wed_scheduled_flg"];
    thu_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["thu_scheduled_flg"];
    fri_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["fri_scheduled_flg"];
    sat_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["sat_scheduled_flg"];
    sun_scheduled_flg?: GetFacilityUserTargetIdResponse["data"]["user_in_facility"]["sun_scheduled_flg"];
  };
  work_details?: GetFacilityUserTargetIdResponse["data"]["work_details"];
  work_break_times?: GetFacilityUserTargetIdResponse["data"]["work_break_times"];
  work_break_time_items?: GetFacilityUserTargetIdResponse["data"]["work_break_time_items"];
  work_time_items?: GetFacilityUserTargetIdResponse["data"]["work_time_items"];
};

/**
 * 営業日・曜日ごとの設定などを考慮して作業開始日・作業終了日・休憩の開始/終了リストを返却する
 */
export const getUserCorrectWorkRecordTimes = (
  userState: PickUserState,
  facility: PickFacilityState,
  targetDate: string | Date
): {
  start_time: string;
  end_time: string;
  break_times: BreakTimes;
} => {
  let start_time = "";
  let end_time = "";
  let break_times: BreakTimes = [];

  // ユーザーごとに作業時間の設定がない場合は、facilityで処理
  const isUserWorkTime =
    userState &&
    userState.work_details &&
    "work_time_use_flg" in userState.work_details &&
    userState.work_details.work_time_use_flg;
  // (ユーザー単位の)曜日ごとの作業時間設定を利用する
  const isWeekWorkTime =
    userState &&
    userState.work_details &&
    "work_time_use_flg_of_week" in userState.work_details &&
    userState.work_details.work_time_use_flg_of_week;
  if (!isUserWorkTime) {
    return getCorrectWorkRecordTimes(facility, targetDate);
  }

  if (targetDate) {
    const date =
      typeof targetDate === "string" ? new Date(targetDate) : targetDate;
    const weekLabel: DowType = dayOfTheWeekLabels[date.getDay()];
    const scheduleName = dowMappingSchedule[weekLabel];
    // 営業日かどうか
    if (facility[scheduleName]) {
      // 曜日ごとの設定の存在確認
      if (isWeekWorkTime && userState.work_time_items) {
        // 曜日の作業時間
        const workTimeItems = userState.work_time_items.find(
          (v) => v.day_of_the_week === weekLabel
        );
        // findによる存在チェックは要るがここまできた時点で必ず存在しているはず
        if (workTimeItems) {
          start_time = workTimeItems.start_time || "";
          end_time = workTimeItems.end_time || "";
          // 有効な休憩時間
          const workBreakTimeItems = (
            userState.work_break_time_items || []
          ).filter(
            (v) =>
              v.applied &&
              v.users_in_facility_work_time_items_id === workTimeItems.id
          );
          break_times = workBreakTimeItems.map((v) => ({
            id: v.id,
            facility_id: userState.user_in_facility.facility_id,
            start_time: convertHHMMSSToHHMM(v.start_time),
            end_time: convertHHMMSSToHHMM(v.end_time)
          }));
        }
      }
      // 曜日ごとで分けない場合の設定
      else if (
        userState &&
        userState.work_details &&
        "work_start_time" in userState.work_details &&
        "work_end_time" in userState.work_details &&
        userState.work_details.work_start_time !== null &&
        userState.work_details.work_end_time !== null
      ) {
        start_time = convertHHMMSSToHHMM(
          userState.work_details.work_start_time
        );
        end_time = convertHHMMSSToHHMM(userState.work_details.work_end_time);
        // 有効な休憩時間
        const workBreakTimeItems = (
          userState.work_break_time_items || []
        ).filter((v) => v.applied);
        break_times = workBreakTimeItems.map((v) => ({
          id: v.id,
          facility_id: userState.user_in_facility.facility_id,
          start_time: convertHHMMSSToHHMM(v.start_time),
          end_time: convertHHMMSSToHHMM(v.end_time)
        }));
      }
    }
  }

  return {
    break_times,
    start_time: convertHHMMSSToHHMM(start_time),
    end_time: convertHHMMSSToHHMM(end_time)
  };
};
