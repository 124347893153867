import { AxiosRequestConfig, AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { DownloadCsvTarget } from "@stores/domain/invoice/type";

/**
 * 請求のCSVを取得
 * @param year
 * @param month
 * @param user_ids
 */
export const getInvoicesCsv = async (
  year: string,
  month: string,
  user_ids: string,
  target: DownloadCsvTarget
): Promise<AxiosResponse<string>> => {
  const url = `${VERSION_URL_201910}/invoices/${target}/${year}/${month}?type=csv&excluded_user_ids=${user_ids}`;
  const requestParams: AxiosRequestConfig = {
    responseType: "blob"
  };
  return request.get<string>(url, requestParams);
};
