import React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";

// props
import * as H from "history";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/TANKINYUSHO/dailyOperationsAndSupports/types";
import { StaffState } from "@stores/domain/staff/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { connect } from "react-redux";
import { UsersInFacilityState } from "@stores/domain/mgr/TANKINYUSHO/userInFacility/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";

// ui
import { DailyUsersRecordSupportTable } from "@components/organisms/mgr/TANKINYUSHO/record/DailyUsersRecordSupportTable";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

// constants
import { SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM } from "@constants/mgr/TANKINYUSHO/variables";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "32px 32px 0px"
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    }
  });

type OwnProps = {
  pageName: string;
  supportsRecord: DailyOperationsAndSupportsState["support"];
  staff: StaffState;
  staffOptions: FieldItem[];
  recordDaily: RecordDailyState;
  history: H.History;
  users: UsersInFacilityState["users"];
  yyyymmdd: string;
  customRecords: CustomRecordsState;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 日ごとの支援記録の管理を扱う
 */
const DailySupportsRecord = (props: Props): JSX.Element => {
  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === 3)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);
    return [...orderArray, ...recorderItem];
  };

  const newCustomRecords =
    props.customRecords.length > 0 && sortCustomRecords(props.customRecords);
  return (
    <Paper className={props.classes.root} elevation={0}>
      <Typography variant="h6" color="primary">
        {props.pageName}
      </Typography>
      <Divider variant="middle" className={props.classes.divider} />
      <DailyUsersRecordSupportTable
        supportsRecord={props.supportsRecord}
        staffOptions={props.staffOptions}
        isEditing={props.recordDaily.isEditing}
        targetUifId={props.recordDaily.uifId}
        customRecords={props.customRecords}
        newCustomRecords={newCustomRecords || null}
        users={props.users}
        yyyymmdd={props.yyyymmdd}
      />
    </Paper>
  );
};

export default connect(null)(withStyles(styles)(DailySupportsRecord));
