import * as React from "react";
import { Field, FormikProps, FieldProps, getIn } from "formik";
import MuiSelect, { MuiSelectProps } from "@components/molecules/MuiSelect";
import {
  MuiSelect as MuiSelectMobile,
  MuiSelectProps as MuiSelectMobileProps
} from "@componentsMobile/molecules/MuiSelect";
import { DEFAULT_SELECT_VALUE } from "@constants/variables";
import { ReadonlyTextField } from "@componentsMobile/molecules/ReadonlyTextField";

type ChangeHookEvent = (
  event: React.ChangeEvent<HTMLSelectElement>,
  beforeValue: string
) => void;

type OwnProps = {
  name: string;
  onChangeHook?: ChangeHookEvent;
  tooltip?: React.ReactElement;
  emptyText?: string;
  disabledStyle?: boolean;
  isMobile?: boolean;
  noerrortext?: boolean;
  readOnly?: boolean; // 閲覧モード時にReadonlyTextFieldに切り替える用
  noValueText?: string;
  negativeMarginLabel?: boolean;
};

type NeverSelectProps = {
  value?: never;
  onChange?: never;
};

type MuiProps = MuiSelectProps | MuiSelectMobileProps;

type Props = OwnProps & NeverSelectProps & MuiProps;

const handleChange = (
  event: React.ChangeEvent<HTMLSelectElement>,
  beforeValue: string,
  /* eslint-disable @typescript-eslint/no-explicit-any */
  form: FormikProps<any>, // 使用されるformikPropsがページごとに違うためany
  onChangeHook?: ChangeHookEvent
): void => {
  if (onChangeHook) onChangeHook(event, beforeValue);
  form.setFieldValue(event.target.name, event.target.value);
  form.setFieldTouched(event.target.name, true);
};

const FormikSelect = ({
  error,
  helperText,
  onChangeHook,
  disabledStyle,
  isMobile,
  noerrortext,
  readOnly,
  noValueText,
  negativeMarginLabel,
  ...props
}: Props): JSX.Element => (
  // tslint:disable:jsx-no-lambda
  <Field
    name={props.name}
    render={({ field, form }: FieldProps): JSX.Element => {
      let unselectedValue = "";
      if (
        props.options &&
        props.options.length > 0 &&
        props.options[0].value === DEFAULT_SELECT_VALUE
      ) {
        unselectedValue = props.options[0].value;
      }
      const selectValue =
        props.options &&
        props.options.find((category) => {
          if (typeof field.value === "string") {
            return `${category.value}` === field.value;
          }
          return Number(category.value) === field.value;
        });
      const formValue = selectValue ? selectValue.value : unselectedValue;
      const formikError = getIn(form.errors, props.name);
      const formikTouch =
        getIn(form.touched, props.name) || form.submitCount !== 0;
      const hasError = error || !!(formikTouch && formikError);
      const errorText = noerrortext ? "" : formikError;
      const errorOrHelperText = hasError ? errorText : helperText;
      const label = props.label || "";
      if (isMobile) {
        if (!readOnly) {
          return (
            <MuiSelectMobile
              {...props}
              label={label}
              value={formValue}
              error={hasError}
              helperText={errorOrHelperText}
              onChange={(e): void => {
                return handleChange(e, field.value, form, onChangeHook);
              }}
              onBlur={field.onBlur}
            />
          );
        }
        let value = "";
        const initialValue = getIn(form.initialValues, props.name);
        if (props.options) {
          let selectOption = props.options.find(
            (o) => `${o.value}` === `${initialValue}`
          );
          if (props.placeholder) {
            // placeholderがあるなら初期値はないので空でいい
            value = selectOption ? `${selectOption.label}` : "";
          } else {
            // placeholderがないなら一番上を選択しているのと同等
            selectOption = selectOption || props.options[0];
            value = `${selectOption.label}`;
          }
        }
        return (
          <ReadonlyTextField
            size={props.size}
            style={props.style}
            label={props.label}
            value={value || noValueText || "-"}
            defaultValue=""
          />
        );
      }
      return (
        <MuiSelect
          {...props}
          label={label}
          value={formValue}
          error={hasError}
          helperText={errorOrHelperText}
          onChange={(e): void => {
            return handleChange(e, field.value, form, onChangeHook);
          }}
          onBlur={field.onBlur}
          tooltip={props.tooltip}
          disabledStyle={disabledStyle}
          negativeMarginLabel={negativeMarginLabel}
        />
      );
    }}
  />
);

export default FormikSelect;
