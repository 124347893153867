import {
  CustomRecordsTableBodyValues,
  customRecordsValues
} from "@initialize/record/customRecord/initialValues";
import { RequestParam as CustomRecordsRequestParam } from "@api/requests/customRecords/postCustomRecords";
import { RequestParam as OrderRequestParam } from "@api/requests/customRecords/postCustomRecordsOrder";

export const normalizeCustomRecordsDataToAPI = (
  state: customRecordsValues
): CustomRecordsRequestParam => {
  // 空の選択肢、非表示の選択肢、編集中に新規追加→削除した選択肢情報は省く
  const customRecordsItemChoices = state.custom_record_item_choices
    .filter((choice) => !!choice.name && choice.hidden !== 1 && !choice.delete)
    .map((choice) => {
      return {
        id: choice.id,
        custom_record_item_id: choice.custom_record_item_id,
        name: choice.name
      };
    });
  return {
    id: state ? state.id : null,
    name: state ? state.name : "",
    input_type: state ? +state.input_type : 1,
    setting_type: state ? state.setting_type : 0,
    custom_record_item_choices: customRecordsItemChoices
  };
};

// 並べ替え用データ整形（並び順固定項目は省く）
// 非表示項目も省く必要があるが、関数実行前に既に省かれている想定
export const normalizeCustomRecordsOrderDataToAPI = (
  visibleCustomRecords: CustomRecordsTableBodyValues
): OrderRequestParam => {
  const normalizedCustomRecordsTableBodyValues = visibleCustomRecords
    .filter((record) => record.allow_change_order === 1)
    .map((record, index) => {
      return {
        id: record.id,
        setting_type: record.setting_type,
        default_item: record.default_item,
        name: record.name,
        order: index + 1
      };
    });

  return {
    custom_record_items_order: normalizedCustomRecordsTableBodyValues
  };
};
