import * as React from "react";
import { SnackbarParams } from "@stores/ui/type";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { InitialMonitoringMonthValues } from "@interfaces/mgr/KEIKAKUSODAN/monitoringMonth/initial";
import { toEffectiveObject } from "@utils/object";
import { validation } from "@initialize/mgr/KEIKAKUSODAN/monitoringMonth/validation";
import { FieldArray, Form, Formik, FormikActions, getIn } from "formik";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { initialMonitoringMonthElement } from "@initialize/mgr/KEIKAKUSODAN/monitoringMonth/initialValues";
import { FormikSelectMonth } from "@components/molecules/FormikSelectMonth";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import { REGISTERED_YEAR_MONTH_VALIDATION } from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    paper: {
      width: 600
    },
    dialogHeader: {
      padding: "16px 32px 18px",
      color: "#37474f",
      fontSize: 20,
      backgroundColor: "#f5f5f5",
      borderBottom: "solid 1px",
      borderBottomColor: "#cfd8dc",
      lineHeight: 1.2,
      height: 58
    },
    dialogSubTitle: {
      margin: "24px 0 16px 0",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 12,
      fontWeight: "normal",
      lineHeight: 1.33,
      letterSpacing: 0.4,
      height: 32
    },
    content: {
      display: "flex"
    },
    deleteLabelEmpty: {
      height: "14px"
    },
    dialogFooter: {
      borderTop: "solid 1px",
      borderTopColor: "#cfd8dc",
      paddingTop: 8,
      paddingRight: 32,
      marginLeft: 0,
      marginRight: 0
    },
    formControlLabelStyle: {
      lineHeight: 1.0,
      marginBottom: "6px"
    }
  });

type OwnProps = {
  isOpen: boolean;
  data: InitialMonitoringMonthValues;
  onCancel: () => void;
  onSubmit: (values: InitialMonitoringMonthValues) => Promise<void>;
  showSnackbar: (params: SnackbarParams) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const MonitoringMonthDialogCore = (props: Props): JSX.Element => {
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);

  const validate = (values: InitialMonitoringMonthValues): void | object => {
    const validationResult = validation(values);
    const errorObj = toEffectiveObject(validationResult);
    // 年月が未選択の行のみの追加の場合、または「登録済みです。別の年月を選択してください」のエラーが含まれる場合は保存ボタンを非活性とする
    // 配列型になっているvalidationResultでチェックする
    if (
      JSON.stringify(
        values.monitoring_month
          .filter((v) => v.year_month.year !== "" || v.year_month.month !== "")
          .map((v) => {
            return {
              ...v,
              year_month: {
                year: `${v.year_month.year}`,
                month: v.year_month.month
              }
            };
          })
      ) === JSON.stringify(props.data.monitoring_month) ||
      (validationResult.monitoring_month &&
        validationResult.monitoring_month.some(
          (v) =>
            v &&
            v.year_month &&
            v.year_month.year === REGISTERED_YEAR_MONTH_VALIDATION
        ))
    ) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
    return errorObj;
  };

  const onCancel = (): void => {
    setIsSubmitDisabled(true);
    props.onCancel();
  };

  const onSubmit = async (
    values: InitialMonitoringMonthValues,
    actions: FormikActions<InitialMonitoringMonthValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await props
      .onSubmit(values)
      .then(() => {
        onCancel();
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  return (
    <Dialog open={props.isOpen} classes={{ paper: props.classes.paper }}>
      <Formik
        initialValues={props.data}
        validate={validate}
        onSubmit={onSubmit}
      >
        {(formikProps): JSX.Element => (
          <Form>
            <DialogTitle className={props.classes.dialogHeader}>
              モニタリング実施月の設定
            </DialogTitle>
            <DialogContent>
              <FieldArray name="monitoring_month">
                {(arrayHelpers): JSX.Element => {
                  const onClickAddMonitoringMonth = (): void => {
                    arrayHelpers.push(initialMonitoringMonthElement());
                  };
                  const addButtonDisabled =
                    formikProps.values.monitoring_month.length >= 24;
                  return (
                    <div>
                      <div className={props.classes.dialogSubTitle}>
                        この設定を行うと、モニタリング未実施のアラートの表示や
                        <br />
                        利用者一覧画面での絞り込みをすることができます。
                      </div>
                      <div>
                        {formikProps.values.monitoring_month.map((_, index) => {
                          const keyNum = index;
                          const yearError = getIn(
                            formikProps.errors,
                            `monitoring_month[${keyNum}].year_month.year`
                          );
                          return (
                            <div
                              key={`monitoringMonth-${keyNum}`}
                              className={props.classes.content}
                            >
                              <FormikSelectMonth
                                name={`monitoring_month[${keyNum}].year_month`}
                                label={index === 0 ? "モニタリング実施月" : ""}
                                addYearTo={3}
                                overrideYearFrom={1989}
                                style={{ marginTop: 16 }}
                                yearError={
                                  yearError === REGISTERED_YEAR_MONTH_VALIDATION
                                }
                                monthError={
                                  yearError === REGISTERED_YEAR_MONTH_VALIDATION
                                }
                              />
                              <FormikCheckbox
                                name={`monitoring_month[${keyNum}].is_delete`}
                                label={
                                  index === 0 ? (
                                    <span
                                      style={{
                                        fontSize: 12,
                                        color: "#37474f"
                                      }}
                                    >
                                      削除
                                    </span>
                                  ) : (
                                    <div
                                      className={props.classes.deleteLabelEmpty}
                                    />
                                  )
                                }
                                checkboxStyle={{ padding: "2px" }}
                                style={{ margin: "16px 0 0", padding: 0 }}
                                labelPlacement="top"
                                formControlLabelStyle={
                                  props.classes.formControlLabelStyle
                                }
                              />
                            </div>
                          );
                        })}
                        <KnowbeButton
                          style={{ marginTop: "16px" }}
                          onClick={onClickAddMonitoringMonth}
                          disabled={addButtonDisabled}
                          kind="iconText"
                        >
                          <AddIcon
                            color={addButtonDisabled ? "disabled" : "secondary"}
                          />
                          実施月を追加する
                        </KnowbeButton>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </DialogContent>
            <DialogActions className={props.classes.dialogFooter}>
              <KnowbeButton kind="outline" onClick={onCancel}>
                キャンセル
              </KnowbeButton>
              <FormikSubmitButton
                buttonName="保存する"
                formikProps={formikProps}
                disabled={isSubmitDisabled}
                errorAction={submitError}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export const MonitoringMonthDialog = withStyles(styles)(
  MonitoringMonthDialogCore
);
