import * as React from "react";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  withStyles,
  Theme,
  StyleRules
} from "@material-ui/core/styles";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      margin: spacing.unit * 2
    }
  });

const StyledPaper = withStyles(styles)(Paper);

type Props = React.Props<{}>;

const InOutReportFormPaper = ({ children }: Props): JSX.Element => (
  <StyledPaper component="section" elevation={0}>
    {children}
  </StyledPaper>
);

export default InOutReportFormPaper;
