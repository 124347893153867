import getOperations from "./getOperations";
import getCustomRecordOperations from "./getCustomRecordOperations";
import getOperationsAndSupports from "./getOperationsAndSupports";
import getOperationsDateList from "./getOperationsDateList";
import postOperations from "./postOperations";
import postOperationsAndSupports from "./postOperationsAndSupports";
import postCustomOperation from "./postCustomOperation";

export default {
  getOperations,
  getCustomRecordOperations,
  getOperationsAndSupports,
  getOperationsDateList,
  postOperations,
  postOperationsAndSupports,
  postCustomOperation
};
