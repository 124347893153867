import { format } from "date-fns";

import { GetSupportProcedureDetailResponse } from "@api/requests/supportProcedure/getSupportProcedureDetail";
import { DAY_SELECT_TYPE } from "@constants/variables";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import { FieldItem } from "@interfaces/ui/form";

type Practitioner = {
  id: number;
  name: string;
  role: string;
  license: number;
  license_name: string | null;
  facility_id: number;
  snapshot_name: string;
  snapshot_license_name: string | null;
} | null;

export type SupportProcedureFormPractitioners = {
  supportProcedureFormPractitionersId: number | null;
  startTimeDate: string;
  startTime: string;
  endTimeDate: string;
  endTime: string;
  practitionerId: string | null;
  practitionerSnapshot: Practitioner;
};

type SupportProcedureFormOtherPractitioners = {
  supportProcedureFormOtherPractitionersId: number | null;
  startTimeDate: string;
  startTime: string;
  endTimeDate: string;
  endTime: string;
  practitionerName: string;
  facilityName: string;
};

type SupportProcedureFormDetails = {
  supportProcedureFormDetailsId: number | null;
  startTime: string;
  endTime: string;
  activity: string;
  serviceProcedure: string;
  check: number;
  appearance: string;
};

export type SupportProcedureDetailFormValues = {
  title: string;
  numberOfPractitioner: string;
  supportProcedureFormPractitioners1: SupportProcedureFormPractitioners;
  supportProcedureFormPractitioners2: SupportProcedureFormPractitioners;
  supportProcedureFormOtherPractitioners: SupportProcedureFormOtherPractitioners[];
  supportProcedureFormDetails: SupportProcedureFormDetails[];
  contactInformationPlan: string;
  inquiryItemsPlan: string;
  contactInformationRecord: string;
  inquiryItemsRecord: string;
  authorId: number | null;
  role: string;
  updatedAt: string;
  createdAt: string;
};

const practitionersInitialValue: SupportProcedureFormPractitioners = {
  supportProcedureFormPractitionersId: null,
  startTimeDate: DAY_SELECT_TYPE.today,
  startTime: "",
  endTimeDate: DAY_SELECT_TYPE.today,
  endTime: "",
  practitionerId: "",
  practitionerSnapshot: null
};

// 支援手順書兼記録用紙の手順詳細管理オブジェクト追加時に使用する初期値
export const supportProcedureFormDetailInitialValue: SupportProcedureFormDetails = {
  supportProcedureFormDetailsId: null,
  startTime: "",
  endTime: "",
  activity: "",
  serviceProcedure: "",
  check: 0,
  appearance: ""
};

export const initialFormValues: SupportProcedureDetailFormValues = {
  title: "",
  numberOfPractitioner: "1",
  supportProcedureFormPractitioners1: practitionersInitialValue,
  supportProcedureFormPractitioners2: practitionersInitialValue,
  supportProcedureFormOtherPractitioners: [],
  supportProcedureFormDetails: [{ ...supportProcedureFormDetailInitialValue }],
  contactInformationPlan: "",
  inquiryItemsPlan: "",
  contactInformationRecord: "",
  inquiryItemsRecord: "",
  authorId: null,
  role: "",
  updatedAt: "",
  createdAt: ""
};

// 支援手順書兼記録用紙の他事業所提供者オブジェクト追加時に使用する初期値
export const otherPractitionersInitialValue: SupportProcedureFormOtherPractitioners = {
  supportProcedureFormOtherPractitionersId: null,
  startTimeDate: DAY_SELECT_TYPE.today,
  startTime: "",
  endTimeDate: DAY_SELECT_TYPE.today,
  endTime: "",
  practitionerName: "",
  facilityName: ""
};

// 当日・翌日判定
const createDateValues = (
  baseTime: string | null,
  targetDate: string | null
): string => {
  if (!baseTime) {
    return DAY_SELECT_TYPE.today;
  }
  if (!targetDate) {
    return DAY_SELECT_TYPE.today;
  }
  if (baseTime !== format(targetDate, "YYYY-MM-DD")) {
    return DAY_SELECT_TYPE.nextDay;
  }
  return DAY_SELECT_TYPE.today;
};

/**
 * サービス提供者がスナップショット対象の場合 true
 * 削除済みの場合falseを返却
 */
const isSnapshotPractitioner = (
  practitioner: GetSupportProcedureDetailResponse["data"]["support_procedure_form_practitioners"][number]["practitioner"],
  staffOptions: FieldItem[]
): boolean => {
  // サービス提供者がない場合はfalse
  if (!practitioner) return false;
  const isDeleted = staffOptions.every(
    (staff) => String(practitioner.id) !== staff.value
  );
  if (isDeleted) return false;

  const isChangedName = practitioner.name !== practitioner.snapshot_name;
  return isChangedName;
};

// 支援手順書兼記録用紙の提供者オブジェクトをFormで使用できる形に変換
const convertPractitioners = (
  practitioners: GetSupportProcedureDetailResponse["data"]["support_procedure_form_practitioners"][number],
  targetDate: string,
  staffOptions: FieldItem[],
  isRecorded?: boolean,
  isUnRecordedServiceDelivery?: boolean
): SupportProcedureFormPractitioners => {
  const {
    start_time,
    end_time,
    practitioner,
    support_procedure_form_practitioners_id
  } = practitioners;

  let practitionerId = "";
  if (!isUnRecordedServiceDelivery) {
    if (practitioner) {
      if (isSnapshotPractitioner(practitioner, staffOptions)) {
        practitionerId = "0";
      } else {
        practitionerId = String(practitioner.id);
      }
    }
  }

  return {
    supportProcedureFormPractitionersId:
      support_procedure_form_practitioners_id || null,
    startTime: start_time ? dateToLocalisedString(start_time, "HH:mm") : "",
    startTimeDate:
      isRecorded && start_time
        ? DAY_SELECT_TYPE.today
        : createDateValues(targetDate, start_time),
    endTime: end_time ? dateToLocalisedString(end_time, "HH:mm") : "",
    endTimeDate:
      isRecorded && end_time
        ? DAY_SELECT_TYPE.today
        : createDateValues(targetDate, end_time),
    practitionerId,
    practitionerSnapshot: practitioner
  };
};

const getAuthorId = (
  author: GetSupportProcedureDetailResponse["data"]["author"],
  staffOptions: FieldItem[]
): number | null => {
  if (!author) return null;
  const isDeleted = staffOptions.every((v) => v.value !== String(author.id));
  if (isDeleted) return author.id;
  const isSnapshot = author.name !== author.snapshot_name;
  if (isSnapshot) return 0;
  return author.id;
};

// 新規作成/コピー時に使用するFormの初期値
export const initialValuesNew = (
  supportProcedureState: GetSupportProcedureDetailResponse["data"],
  isCopy: boolean,
  staffOptions: FieldItem[]
): SupportProcedureDetailFormValues => {
  const {
    title,
    service_delivery_records_id,
    inout_results_id,
    number_of_practitioner,
    support_procedure_form_practitioners,
    support_procedure_forms_id,
    support_procedure_form_details,
    contact_information_plan,
    inquiry_items_plan,
    target_date
  } = supportProcedureState;

  const targetDate = target_date || format(new Date(), "YYYY-MM-DD");

  const isUnplanned = inout_results_id === null;
  const isUnRecordedServiceDelivery = service_delivery_records_id === null;
  const isRecorded = support_procedure_forms_id !== null;

  const practitionersLength = support_procedure_form_practitioners.length;
  const practitioners1 =
    practitionersLength !== 0
      ? convertPractitioners(
          support_procedure_form_practitioners[0],
          targetDate,
          staffOptions,
          isRecorded,
          isUnRecordedServiceDelivery
        )
      : { ...practitionersInitialValue };

  const practitioners2 =
    practitionersLength === 2
      ? convertPractitioners(
          support_procedure_form_practitioners[1],
          targetDate,
          staffOptions,
          isRecorded,
          isUnRecordedServiceDelivery
        )
      : { ...practitionersInitialValue };

  const supportProcedureFormDetails: SupportProcedureFormDetails[] = [];
  if (isCopy) {
    support_procedure_form_details.forEach((v) => {
      supportProcedureFormDetails.push({
        supportProcedureFormDetailsId: null,
        startTime: v.start_time ? convertHHMMSSToHHMM(v.start_time) : "",
        endTime: v.end_time ? convertHHMMSSToHHMM(v.end_time) : "",
        activity: v.activity,
        serviceProcedure: v.service_procedure,
        check: 0,
        appearance: ""
      });
    });
  } else {
    supportProcedureFormDetails.push({
      ...supportProcedureFormDetailInitialValue
    });
  }

  return {
    title: isCopy ? title : "",
    numberOfPractitioner:
      isUnplanned && isUnRecordedServiceDelivery
        ? "1"
        : `${number_of_practitioner}`,
    authorId: null,
    role: "",
    supportProcedureFormPractitioners1: practitioners1,
    supportProcedureFormPractitioners2: practitioners2,
    supportProcedureFormOtherPractitioners: [],
    supportProcedureFormDetails,
    contactInformationPlan: isCopy ? contact_information_plan || "" : "",
    inquiryItemsPlan: isCopy ? inquiry_items_plan || "" : "",
    contactInformationRecord: "",
    inquiryItemsRecord: "",
    updatedAt: "",
    createdAt: ""
  };
};

// 編集/日時設定に使用するFormの初期値
export const initialValuesEdit = (
  supportProcedureState: GetSupportProcedureDetailResponse["data"],
  staffOptions: FieldItem[]
): SupportProcedureDetailFormValues => {
  const {
    support_procedure_form_practitioners,
    support_procedure_form_other_practitioners,
    support_procedure_form_details,
    target_date
  } = supportProcedureState;

  const targetDate = target_date || format(new Date(), "YYYY-MM-DD");

  const practitionersLength = support_procedure_form_practitioners.length;
  const practitioners1 =
    practitionersLength > 0
      ? convertPractitioners(
          support_procedure_form_practitioners[0],
          targetDate,
          staffOptions
        )
      : { ...practitionersInitialValue };

  const practitioners2 =
    practitionersLength > 1
      ? convertPractitioners(
          support_procedure_form_practitioners[1],
          targetDate,
          staffOptions
        )
      : { ...practitionersInitialValue };

  const otherPractitioners: SupportProcedureFormOtherPractitioners[] = [];
  support_procedure_form_other_practitioners.forEach((v) => {
    otherPractitioners.push({
      supportProcedureFormOtherPractitionersId:
        v.support_procedure_form_other_practitioners_id,
      startTimeDate: v.start_time
        ? createDateValues(target_date, v.start_time)
        : DAY_SELECT_TYPE.today,
      startTime: v.start_time
        ? dateToLocalisedString(v.start_time, "HH:mm")
        : "",
      endTimeDate: v.end_time
        ? createDateValues(target_date, v.end_time)
        : DAY_SELECT_TYPE.today,
      endTime: v.end_time ? dateToLocalisedString(v.end_time, "HH:mm") : "",
      practitionerName: v.practitioner_name,
      facilityName: v.facility_name
    });
  });

  const formDetails: SupportProcedureFormDetails[] = [];
  support_procedure_form_details.forEach((v) => {
    formDetails.push({
      supportProcedureFormDetailsId: v.support_procedure_form_details_id,
      startTime: v.start_time ? convertHHMMSSToHHMM(v.start_time) : "",
      endTime: v.end_time ? convertHHMMSSToHHMM(v.end_time) : "",
      activity: v.activity,
      serviceProcedure: v.service_procedure,
      check: v.check,
      appearance: v.appearance
    });
  });

  const res: SupportProcedureDetailFormValues = {
    title: supportProcedureState.title,
    numberOfPractitioner: `${supportProcedureState.number_of_practitioner}`,
    supportProcedureFormPractitioners1: practitioners1,
    supportProcedureFormPractitioners2: practitioners2,
    supportProcedureFormOtherPractitioners: otherPractitioners,
    supportProcedureFormDetails: formDetails,
    contactInformationPlan:
      supportProcedureState.contact_information_plan || "",
    inquiryItemsPlan: supportProcedureState.inquiry_items_plan || "",
    contactInformationRecord:
      supportProcedureState.contact_information_record || "",
    inquiryItemsRecord: supportProcedureState.inquiry_items_record || "",
    authorId: getAuthorId(supportProcedureState.author, staffOptions),
    role:
      supportProcedureState.author !== null
        ? supportProcedureState.author.role
        : "",
    updatedAt: supportProcedureState.updated_at,
    createdAt: supportProcedureState.created_at
  };

  return res;
};
