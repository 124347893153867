import { MonitoringReportValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { dateValidator, validateSwitcher } from "@validator";

type MonitoringReportErrors = ValidationErrors<MonitoringReportValues>;

// 削除済みでなければバリデーションをかける
const isDeleteValidateSwitcher = (
  detail: MonitoringReportValues["details"][0],
  value: string
): Exclude<
  Exclude<MonitoringReportErrors["details"], undefined>[0],
  undefined
>["is_delete"] => {
  return validateSwitcher(
    !detail.is_delete,
    validator(value, {
      type: "checkCharacterLength",
      length: 2500
    })
  );
};

// 詳細のバリデーション
export const detailsValidation = (
  detailValues: MonitoringReportValues["details"]
): MonitoringReportErrors["details"] => {
  return detailValues.map((detail) => {
    return {
      support_goal: isDeleteValidateSwitcher(detail, detail.support_goal),
      achievement_time: isDeleteValidateSwitcher(
        detail,
        detail.achievement_time
      ),
      service_provision_status: isDeleteValidateSwitcher(
        detail,
        detail.service_provision_status
      ),
      personal_satisfaction: isDeleteValidateSwitcher(
        detail,
        detail.personal_satisfaction
      ),
      achievement_support_goals: isDeleteValidateSwitcher(
        detail,
        detail.achievement_support_goals
      ),
      future_issues: isDeleteValidateSwitcher(detail, detail.future_issues),
      other_considerations: isDeleteValidateSwitcher(
        detail,
        detail.other_considerations
      )
    };
  });
};

export const validation = (
  values: MonitoringReportValues
): MonitoringReportErrors => {
  return {
    date_of_monitoring: dateValidator(values.date_of_monitoring, "required"),
    disabled_welfare_service_recipient_number: validator(
      values.disabled_welfare_service_recipient_number,
      "naturalNumber"
    ),
    consultation_support_recipient_number: validator(
      values.consultation_support_recipient_number,
      "naturalNumber"
    ),
    regular_place_recipient_number: validator(
      values.regular_place_recipient_number,
      "naturalNumber"
    ),
    support_policy: validator(values.support_policy, {
      type: "checkCharacterLength",
      length: 2500
    }),
    whole_situation: validator(values.whole_situation, {
      type: "checkCharacterLength",
      length: 2500
    }),
    staff_comment: validator(values.staff_comment, {
      type: "checkCharacterLength",
      length: 2500
    }),
    details: detailsValidation(values.details)
  };
};
