import * as H from "history";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { ContactValues } from "@interfaces/contact/contact";
import { CONTACT_COMPLETE } from "@constants/url";

import contactApi from "@api/requests/contact";
import { ResponseErrorState } from "@stores/ui/responseError/types";

const success = (dispatch: Dispatch, history: H.History) => (): void => {
  dispatches.uiDispatch(dispatch).loading(false);
  dispatches.uiDispatch(dispatch).snackbar({
    open: true,
    message: "送信が完了しました",
    variant: "success"
  });
  history.push(CONTACT_COMPLETE);
};

const error = (dispatch: Dispatch) => (e: {
  response: ResponseErrorState;
}): void => {
  dispatches.uiDispatch(dispatch).responseError(e.response);
  dispatches.uiDispatch(dispatch).loading(false);
  dispatches.uiDispatch(dispatch).snackbar({
    open: true,
    message: "通信エラー",
    variant: "error"
  });
};

export const post = (dispatch: Dispatch) => async (
  params: ContactValues,
  history: H.History
): Promise<void> => {
  dispatches.uiDispatch(dispatch).loading(true);
  contactApi
    .postContact(params)
    .then(success(dispatch, history))
    .catch(error(dispatch));
};

type Dispatcher = {
  post: (params: ContactValues, history: H.History) => Promise<void>;
};

export default function (dispatch: Dispatch): Dispatcher {
  return {
    post: post(dispatch)
  };
}
