import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import { UserState } from "@stores/domain/user/type";
import * as URL from "@constants/url";

// 利用実績
import { Report } from "@components/pages/mgr/SHUROTEICHAKU/report";
import Report201910 from "@components/v201910/pages/mgr/SHUROTEICHAKU/report";
import { Report202104 } from "@components/v202104/pages/mgr/SHUROTEICHAKU/report";

// 利用者情報
import UsersList from "@components/pages/mgr/SHUROTEICHAKU/Users/UsersList";
import CreateUser from "@components/pages/mgr/SHUROTEICHAKU/Users/CreateUser";
import CreateUser201910 from "@components/v201910/pages/mgr/SHUROTEICHAKU/users/CreateUser";
import EditUser from "@components/pages/mgr/SHUROTEICHAKU/Users/EditUser";
import EditUser201910 from "@components/v201910/pages/mgr/SHUROTEICHAKU/users/EditUser";
import EditUser202104 from "@components/v202104/pages/mgr/SHUROTEICHAKU/users/EditUser";

// 事業所情報
import Facility from "@components/pages/mgr/SHUROTEICHAKU/Facility";
import Facility201910 from "@components/v201910/pages/mgr/SHUROTEICHAKU/Facility";
import { Facility202104 } from "@components/v202104/pages/mgr/SHUROTEICHAKU/Facility";

// 記録機能
import UserDetail from "@components/pages/mgr/SHUROTEICHAKU/record/UserDetail";
import { SupportPlan } from "@components/pages/mgr/SHUROTEICHAKU/record/SupportPlan";
import { SupportPlanNew } from "@components/pages/mgr/SHUROTEICHAKU/record/SupportPlanNew";
import { SupportPlanList } from "@components/pages/mgr/SHUROTEICHAKU/record/SupportPlanList";
import { Evaluation } from "@components/pages/mgr/SHUROTEICHAKU/record/Evaluation";
import { RecordUsersSummary } from "@components/pages/mgr/SHUROTEICHAKU/record/UsersSummary";
import { SupportReport } from "@components/pages/mgr/SHUROTEICHAKU/record/SupportReport";
import { RecordSupportPlanPrint } from "@components/pages/record/print/SupportPlanPrint";

// 職員情報
import Staffs from "@components/pages/staffs/Staffs";

// 印刷・記録機能
import { SupportRecordPrint } from "@components/pages/record/print/SupportPrint";
import { RecordUsersSummarySupportSHUROTEICHAKUPrint } from "@components/pages/mgr/SHUROTEICHAKU/record/UsersSummarySupportSHUROTEICHAKUPrint";
import { RecordUsersSummaryReportSHUROTEICHAKUPrint } from "@components/pages/mgr/SHUROTEICHAKU/record/UsersSummaryReportSHUROTEICHAKUPrint";

// 印刷・支援レポート
import { SupportReportRecordPrint } from "@components/pages/mgr/SHUROTEICHAKU/record/SupportReportRecordPrint";
import { EvaluationPrint } from "@components/pages/record/print/EvaluationPrint";

type OwnProps = {
  user: UserState;
};

type Props = OwnProps;

/**
 * 就労定着支援施設でログインした時だけ有効になるルーティング
 */
const SHUROTEICHAKURoutes: React.FunctionComponent<Props> = ({ user }) => (
  <Switch>
    {/* 利用実績 */}
    <AdminRoute path={URL.REPORT} component={Report} />
    <AdminRoute path={URL.REPORT_201910} component={Report201910} />
    <AdminRoute path={URL.REPORT_202104} component={Report202104} />
    {/* 利用者情報 */}
    <AdminRoute exact path={URL.USERS} component={UsersList} />
    <AdminRoute
      exact
      path={URL.USERS_NEW_201910}
      component={CreateUser201910}
    />
    <AdminRoute exact path={URL.USERS_NEW} component={CreateUser} />
    <AdminRoute exact path={URL.USERS_ID_201910} component={EditUser201910} />
    <AdminRoute exact path={URL.USERS_ID_202104} component={EditUser202104} />
    <AdminRoute exact path={URL.USERS_ID} component={EditUser} />
    {/* 事業所情報 */}
    <AdminRoute path={URL.FACILITY_201910} component={Facility201910} />
    <AdminRoute path={URL.FACILITY_202104} component={Facility202104} />
    <AdminRoute path={URL.FACILITY} component={Facility} />
    {/* 記録機能 */}
    <AdminRoute
      exact
      path="/record/:uifId/support/:year?/:month?"
      component={UserDetail}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_report/:year?/:month?"
      component={SupportReport}
    />
    <AdminRoute
      exact
      path="/record/users_summary"
      component={RecordUsersSummary}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/evaluation/:supportPlanId"
      component={Evaluation}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/new"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId/copy"
      component={SupportPlanNew}
    />
    <AdminRoute
      exact
      path="/record/:uifId/support_plan/:supportPlanId"
      component={SupportPlan}
    />
    <AdminRoute
      exact
      path="/record/:uifId/:recordType(support_plan)/:year?/:month?"
      component={SupportPlanList}
    />
    {/* 職員情報 */}
    <AdminRoute key="Staffs" path="/staffs" exact component={Staffs} />,
    {/* 印刷・支援記録 */}
    <AdminRoute
      key="SupportRecordPrint"
      exact
      path="/record/print/:uifId/support/:year/:month"
      component={SupportRecordPrint}
    />
    {/* 印刷・支援レポート */}
    <AdminRoute
      key="SupportReportRecordPrint"
      exact
      path="/record/print/:uifId/support_report/:year/:month"
      component={SupportReportRecordPrint}
    />
    {/* 利用者ごと > 一括印刷・支援記録 */}
    <AdminRoute
      key="RecordUsersSummarySupportPrint"
      exact
      path="/record/print/users_summary_support/:year/:month"
      component={RecordUsersSummarySupportSHUROTEICHAKUPrint}
    />
    {/* 利用者ごと > 一括印刷・支援レポート */}
    <AdminRoute
      key="RecordUsersSummaryReportPrint"
      exact
      path="/record/print/users_summary_support_report/:year/:month"
      component={RecordUsersSummaryReportSHUROTEICHAKUPrint}
    />
    {/* 印刷・個別支援計画 */}
    <AdminRoute
      key="SupportPlanRecordPrint"
      exact
      path="/record/print/:uifId/support_plan/:supportPlanId"
      component={RecordSupportPlanPrint}
    />
    {/* 印刷・評価振り返り */}
    <AdminRoute
      key="EvaluationPrint"
      exact
      path="/record/print/:uifId/support_plan/evaluation/:supportPlanId"
      component={EvaluationPrint}
    />
    {/* default path */}
    <Route path="/">
      <Redirect to={user.role === "mgruser" ? URL.ATTENDANCE : URL.REPORT} />
    </Route>
  </Switch>
);

export default SHUROTEICHAKURoutes;
