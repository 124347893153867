import * as types from "./types";
import { GetUsagePerformanceParamsSummary } from "@api/requests/usagePerformance/getUsagePerformanceSummary";
import { ResponseErrorState } from "@stores/ui/responseError/types";

export const fetchUsagePerformanceSummaryStarted = () =>
  ({ type: types.FETCH_USAGE_PERFORMANCE_SUMMARY_STARTED } as const);
export const fetchUsagePerformanceSummarySuccess = (
  res: GetUsagePerformanceParamsSummary
) =>
  ({
    type: types.FETCH_USAGE_PERFORMANCE_SUMMARY_SUCCESS,
    payload: res
  } as const);
export const fetchUsagePerformanceSummaryFailed = (err: {
  error: { response: ResponseErrorState };
}) =>
  ({ type: types.FETCH_USAGE_PERFORMANCE_SUMMARY_FAILED, error: err } as const);
export const unsetGroupHomeUsagePerformanceSummary = () =>
  ({ type: types.UNSET_GROUP_HOME_USAGE_PERFORMANCE_SUMMARY } as const);

export const unsetGroupHomeReport = () =>
  ({ type: types.UNSET_GroupHome_REPORT } as const);

export type ActionTypes =
  | ReturnType<typeof fetchUsagePerformanceSummaryStarted>
  | ReturnType<typeof fetchUsagePerformanceSummarySuccess>
  | ReturnType<typeof fetchUsagePerformanceSummaryFailed>
  | ReturnType<typeof unsetGroupHomeUsagePerformanceSummary>
  | ReturnType<typeof unsetGroupHomeReport>;
