// 整理中: 分離済みのロジック
import { compareDateFuture } from "@utils/date/compareDateFuture";
import { checkValidDate } from "@utils/date/checkValidDate";
import { convertTimeHHMM } from "@utils/date/convertTimeHHMM";
import { convertWareki } from "@utils/date/convertWareki";
import { convertYYYYMMToYYYY_MM } from "@utils/date/convertYYYYMMToYYYY_MM";
import { createTime } from "@utils/date/createTime";
import createWeekdaysJapaneseList from "@utils/date/createWeekdaysJapaneseList";
import { currentUnixTimestamp } from "@utils/date/currentUnixTimestamp";
import { dateTodayForAttendanceHeaderForDetailDaily } from "@utils/date/dateTodayForAttendanceHeaderForDetailDaily";
import { dateTodayForApi } from "@utils/date/dateTodayForApi";
import { dateTodayInFormat } from "@utils/date/dateTodayInFormat";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import dateToObject from "@utils/date/dateToObject";
import { dateToSelectDateValue } from "@utils/date/dateToSelectDateValue";
import { dateToSelectMonthValue } from "@utils/date/dateToSelectMonthValue";
import { dateToSelectDateValueYYYYM } from "@utils/date/dateToSelectDateValueYYYYM";
import { formatTime } from "@utils/date/formatTime";
import { getDayOfTheWeek } from "@utils/date/getDayOfTheWeek";
import { getDiff } from "@utils/date/getDiff";
import { getLastDay } from "@utils/date/getLastDay";
import { getWareki } from "@utils/date/getWareki";
import { getWarekiList } from "@utils/date/getWarekiList";
import { parseDateString } from "@utils/date/parseDateString";
import { selectDateValueToDate } from "@utils/date/selectDateValueToDate";
import { selectDateValueToDatePaddingZero } from "@utils/date/selectDateValueToDatePaddingZero";
import { selectMonthValueToDatePaddingZero } from "@utils/date/selectMonthValueToDatePaddingZero";
import selectDateValueToDatePaddingZeroWithoutDay from "@utils/date/selectDateValueToDatePaddingZeroWithoutDay";
import {
  dateInYYYYMMDDFormat,
  dateInHyphenYYYYMMDDFormat,
  dateInYYYYMMFormat,
  dateInYYYYFormat,
  dateInMMFormat,
  dateInMFormat
} from "@utils/date/dateInAnyFormat";
import oneLetterWeekdaysJapanese from "@utils/date/oneLetterWeekdaysJapanese";

// 整理中: 分離したロジックを`@utils/date`で参照出来るように再配布
export {
  checkValidDate,
  compareDateFuture,
  convertTimeHHMM,
  convertWareki,
  convertYYYYMMToYYYY_MM,
  createTime,
  createWeekdaysJapaneseList,
  currentUnixTimestamp,
  dateInHyphenYYYYMMDDFormat,
  dateInYYYYMMDDFormat,
  dateInYYYYMMFormat,
  dateInYYYYFormat,
  dateInMMFormat,
  dateInMFormat,
  dateTodayForAttendanceHeaderForDetailDaily,
  dateTodayForApi,
  dateTodayInFormat,
  dateToLocalisedString,
  dateToObject,
  dateToSelectDateValue,
  dateToSelectMonthValue,
  dateToSelectDateValueYYYYM,
  formatTime,
  getDayOfTheWeek,
  getDiff,
  getLastDay,
  getWareki,
  getWarekiList,
  parseDateString,
  selectDateValueToDate,
  selectDateValueToDatePaddingZero,
  selectMonthValueToDatePaddingZero,
  selectDateValueToDatePaddingZeroWithoutDay,
  oneLetterWeekdaysJapanese
};

export const dayOfTheWeekLabels = {
  0: "sun",
  1: "mon",
  2: "tue",
  3: "wed",
  4: "thu",
  5: "fri",
  6: "sat"
} as const;

export const defaultTimeLabel = "--:--";

const dateToday = new Date();

export const localisedDateToday = dateToLocalisedString(
  dateToday,
  "YYYY/MM/DD"
);
