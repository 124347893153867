import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.MonitoringMonthState = {
  monitoringMonth: {
    monitoring_month: []
  }
};

export const KEIKAKUSODANMonitoringMonth = (
  state = initialState,
  action: actions.ActionTypes
): types.MonitoringMonthState => {
  switch (action.type) {
    case types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_STARTED:
      return { ...state };
    case types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS:
      return { ...state, monitoringMonth: action.payload };
    case types.FETCH_KEIKAKUSODAN_MONITORING_MONTH_FAILED:
      return { ...state };
    case types.POST_KEIKAKUSODAN_MONITORING_MONTH_STARTED:
      return { ...state };
    case types.POST_KEIKAKUSODAN_MONITORING_MONTH_SUCCESS:
      return { ...state };
    case types.POST_KEIKAKUSODAN_MONITORING_MONTH_FAILED:
      return { ...state };
    default:
      return state;
  }
};
