/**
 * セレクトボックスの変更チェック
 * @param value
 */

import { SelectDateValue } from "@interfaces/ui/form";
import { addMonths, isAfter, isBefore, isEqual } from "date-fns";

const dayLimit = (
  startDate: SelectDateValue,
  endDate: SelectDateValue,
  max: number,
  min: number
): string | undefined => {
  let errorMessage;
  const startDateValue = new Date(`${startDate.year}/${startDate.month}`);
  const endDateValue = new Date(`${endDate.year}/${endDate.month}`);
  const maxInterval = addMonths(startDateValue, max);
  const minInterval = addMonths(startDateValue, min - 1);

  if (startDate.year && startDate.month && endDate.year && endDate.month) {
    if (isAfter(startDateValue, endDateValue)) {
      errorMessage =
        "対象期間（終了）は対象期間（開始）よりも後に設定してください";
    } else if (!isBefore(endDateValue, maxInterval)) {
      errorMessage = `対象期間は${max}ヶ月以内で設定してください`;
    } else if (
      !isAfter(endDateValue, minInterval) &&
      !isEqual(endDateValue, minInterval)
    ) {
      errorMessage = `対象期間は${min}ヶ月以上で設定してください`;
    }
  }

  return errorMessage;
};

const dayValueLimit = (
  startDate: SelectDateValue,
  endDate: SelectDateValue,
  max: number,
  min: number
): {
  year: string | undefined;
} => {
  return {
    year: dayLimit(startDate, endDate, max, min)
  };
};

export default dayValueLimit;
