import { GetSupportRecordUserResponse } from "@api/requests/supports/Cseg/getSupportRecordUser";

export const FETCH_SUPPORT_RECORD_USER_STARTED =
  "SUPPORTS/FETCH_SUPPORT_RECORD_USER_STARTED";
export const FETCH_SUPPORT_RECORD_USER_SUCCESS =
  "SUPPORTS/FETCH_SUPPORT_RECORD_USER_SUCCESS";
export const FETCH_SUPPORT_RECORD_USER_FAILED =
  "SUPPORTS/FETCH_SUPPORT_RECORD_USER_FAILED";

export const POST_SUPPORT_RECORD_USER_STARTED =
  "SUPPORTS/POST_SUPPORT_RECORD_USER_STARTED";
export const POST_SUPPORT_RECORD_USER_SUCCESS =
  "SUPPORTS/POST_SUPPORT_RECORD_USER_SUCCESS";
export const POST_SUPPORT_RECORD_USER_FAILED =
  "SUPPORTS/POST_SUPPORT_RECORD_USER_FAILED";

export type SupportRecordUserState = GetSupportRecordUserResponse["data"];
export type SupportRecordsState = SupportRecordUserState["support_records"];
