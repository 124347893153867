import * as React from "react";

import * as ClassNames from "classnames";
import omit from "lodash-es/omit";

import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import TableCell from "@material-ui/core/TableCell";
import TableHead, { TableHeadProps } from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import blueGrey from "@material-ui/core/colors/blueGrey";

import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";

import { SECONDARY_BACKGROUND_COLOR, DARK_BLUE } from "@constants/styles";

const primaryPaddingRight = 16;
const secondaryPaddingRight = 0;
const primaryFontSize = 14;
const secondaryFontSize = 12;
const primaryLineHeight = 20 / primaryFontSize;
const secondaryLineHeight = 16 / secondaryFontSize;

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    row32: {
      height: 32
    },
    cell32: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight,
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: secondaryPaddingRight
      }
    },
    row40: {
      height: 40
    },
    cell40: {
      fontSize: secondaryFontSize,
      lineHeight: secondaryLineHeight,
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: primaryPaddingRight
      }
    },
    row48: {
      height: 48
    },
    cell48: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight,
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: primaryPaddingRight
      }
    },
    row56: {
      height: 56
    },
    cell56: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight,
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: primaryPaddingRight
      }
    },
    row88: {
      height: 88
    },
    cell88: {
      fontSize: primaryFontSize,
      lineHeight: primaryLineHeight,
      // MuiTableCell-root last-childのpadding 24pxをクリアする。
      "&:last-child": {
        paddingRight: secondaryPaddingRight
      }
    },
    primaryHead: {
      backgroundColor: blueGrey[300]
    },
    primaryCell: {
      color: palette.common.white
    },
    secondaryHead: {
      backgroundColor: SECONDARY_BACKGROUND_COLOR
    },
    secondaryCell: {
      color: DARK_BLUE
    },
    cell: {
      borderBottom: "none"
    },
    stripeCell: {
      "&:nth-child(2n)": {
        backgroundColor: "#9badb6"
      }
    }
  });

export type KnowbeTableHeaderProps = {
  uniqueKey: string;
  items: KnowbeTableCellParam[];
  height: 32 | 40 | 48 | 56 | 88;
  backgroundColor?: "primary" | "secondary";
  headerClassName?: string;
  rowClassName?: string;
  stripe?: boolean;
  // 以下はアクセシビリティ対応
  role?: string;
  ariaChecked?: boolean;
  tabIndex?: number;
  selected?: boolean;
};

type Props = KnowbeTableHeaderProps &
  TableHeadProps &
  WithStyles<typeof styles>;

/**
 *  Knowbeの独自コンポーネント。heightやbackgroundColorをpropsに渡せばknowbeのTableHeadのパターンの基本的な部分(fontsizeや背景色)は再現できる。
 */
export const KnowbeTableHead = withStyles(styles)(
  (props: Props): JSX.Element => {
    const {
      classes,
      uniqueKey,
      items,
      height,
      backgroundColor = "primary",
      headerClassName,
      rowClassName,
      stripe,
      // 以下はアクセシビリティ対応用props
      role,
      ariaChecked,
      tabIndex = -1,
      selected
    } = props;

    const excludedProps = omit(props, [
      "classes",
      "uniqueKey",
      "items",
      "height",
      "backgroundColor",
      "headerClassName",
      "rowClassName",
      "stripe",
      "role",
      "ariaChecked",
      "tabIndex",
      "selected"
    ]);

    return (
      <TableHead
        className={ClassNames(
          classes[`${backgroundColor}Head`],
          headerClassName
        )}
        {...excludedProps}
      >
        <TableRow
          role={role}
          className={ClassNames(classes[`row${height}`], rowClassName)}
          tabIndex={tabIndex}
          aria-checked={ariaChecked}
          selected={selected}
        >
          {items.map((item, idx) => {
            const keyNum = idx;
            return (
              <TableCell
                key={`table-header-cell-${uniqueKey}-${keyNum}`}
                className={ClassNames(
                  classes.cell,
                  classes[`${backgroundColor}Cell`],
                  classes[`cell${height}`],
                  item.className,
                  stripe && classes.stripeCell
                )}
                align={item.align || "left"}
                padding="none"
              >
                {item.label}
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }
);
