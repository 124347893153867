import { BasicValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/basic";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { DEFAULT_FUNCTION_ENHANCED_SYSTEM } from "@constants/mgr/KEIKAKUSODAN/variables";
import {
  findFacilityKeikakuSodan,
  findFacilityShogaijisodan
} from "@utils/domain/mgr/KEIKAKUSODAN/facility";
import { FacilityInfoShogaijiValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/facilityInfoShogaijiValues";
import { FacilityInfoKeikakuValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/facility/facilityInfoKeikakuValues";

export type FacilityValues = BasicValues &
  FacilityInfoKeikakuValues &
  FacilityInfoShogaijiValues;

export const initialValues = (state?: FacilityState): FacilityValues => {
  if (state) {
    const facilityKS = findFacilityKeikakuSodan(state);
    const facilitySS = findFacilityShogaijisodan(state);

    return {
      basic: {
        corporationName: state.corporationName,
        serviceType: state.serviceType,
        capacity: state.capacity,
        integratedManagementHandicappedChildFlg:
          state.integratedManagementHandicappedChildFlg,
        postalCode: state.postalCode,
        prefectureId: state.selectedPrefectureName,
        cityId: state.selectedCityCode,
        restAddress: state.restAddress
      },
      facilityInfoKeikaku: {
        officeNumber:
          facilityKS && facilityKS.govFacilityNumber
            ? facilityKS.govFacilityNumber
            : "",
        officeName: facilityKS && facilityKS.name ? facilityKS.name : "",
        representativeName:
          facilityKS && facilityKS.responsiblePerson
            ? facilityKS.responsiblePerson
            : "",
        tel: facilityKS && facilityKS.tel ? facilityKS.tel : "",
        functionEnhancedSystem:
          facilityKS && facilityKS.functionEnhancedSystem
            ? facilityKS.functionEnhancedSystem
            : DEFAULT_FUNCTION_ENHANCED_SYSTEM,
        behavioralDisorderSupportFlg:
          !!facilityKS && facilityKS.behavioralDisorderSupportFlg,
        needMedicalCareHandicappedChildSupportFlg:
          !!facilityKS && facilityKS.needMedicalCareHandicappedChildSupportFlg,
        mentalDisorderSupportFlg:
          !!facilityKS && facilityKS.mentalDisorderSupportFlg,
        peerSupportFlg: !!facilityKS && facilityKS.peerSupportFlg,
        lifeSupportHubInDistrictFlg:
          !!facilityKS && facilityKS.lifeSupportHubInDistrictFlg
      },
      facilityInfoShogaiji: {
        officeNumber:
          facilitySS && facilitySS.govFacilityNumber
            ? facilitySS.govFacilityNumber
            : "",
        officeName: facilitySS && facilitySS.name ? facilitySS.name : "",
        representativeName:
          facilitySS && facilitySS.responsiblePerson
            ? facilitySS.responsiblePerson
            : "",
        tel: facilitySS && facilitySS.tel ? facilitySS.tel : "",
        functionEnhancedSystem:
          facilitySS && facilitySS.functionEnhancedSystem
            ? facilitySS.functionEnhancedSystem
            : DEFAULT_FUNCTION_ENHANCED_SYSTEM,
        behavioralDisorderSupportFlg:
          !!facilitySS && facilitySS.behavioralDisorderSupportFlg,
        needMedicalCareHandicappedChildSupportFlg:
          !!facilitySS && facilitySS.needMedicalCareHandicappedChildSupportFlg,
        mentalDisorderSupportFlg:
          !!facilitySS && facilitySS.mentalDisorderSupportFlg,
        peerSupportFlg: !!facilitySS && facilitySS.peerSupportFlg,
        lifeSupportHubInDistrictFlg:
          !!facilitySS && facilitySS.lifeSupportHubInDistrictFlg
      }
    };
  }
  return {
    basic: {
      corporationName: "",
      serviceType: "",
      capacity: "",
      integratedManagementHandicappedChildFlg: false,
      postalCode: "",
      prefectureId: "",
      cityId: "",
      restAddress: ""
    },
    facilityInfoKeikaku: {
      officeNumber: "",
      officeName: "",
      representativeName: "",
      tel: "",
      functionEnhancedSystem: DEFAULT_FUNCTION_ENHANCED_SYSTEM,
      behavioralDisorderSupportFlg: false,
      needMedicalCareHandicappedChildSupportFlg: false,
      mentalDisorderSupportFlg: false,
      peerSupportFlg: false,
      lifeSupportHubInDistrictFlg: false
    },
    facilityInfoShogaiji: {
      officeNumber: "",
      officeName: "",
      representativeName: "",
      tel: "",
      functionEnhancedSystem: DEFAULT_FUNCTION_ENHANCED_SYSTEM,
      behavioralDisorderSupportFlg: false,
      needMedicalCareHandicappedChildSupportFlg: false,
      mentalDisorderSupportFlg: false,
      peerSupportFlg: false,
      lifeSupportHubInDistrictFlg: false
    }
  };
};
