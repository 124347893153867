import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { assessmentApi } from "@api/requests/assessment";
import { Assessment } from "@initialize/record/assessment/initialValues";
import { normalizePostAssessmentDataToAPI } from "@stores/domain/assessment/normalizer";
import dispatches from "@stores/dispatches";
import * as H from "history";
import * as URL from "@constants/url";

export const fetchAssessment = (dispatch: Dispatch) => async (
  uifId: string,
  assessmentId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchAssessmentStarted());
  await assessmentApi
    .getAssessment(uifId, assessmentId)
    .then((res) => {
      dispatch(actions.fetchAssessmentSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchAssessmentFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchAssessmentList = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchAssessmentListStarted());
  await assessmentApi
    .getAssessmentList(uifId)
    .then((res) => {
      dispatch(actions.fetchAssessmentListSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchAssessmentListFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postAssessment = (dispatch: Dispatch) => async (
  uifId: string,
  initialAssessment: Assessment,
  assessment: Assessment,
  history: H.History,
  typeConsultation?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postAssessmentStarted());
  const normalizeParam = normalizePostAssessmentDataToAPI(
    initialAssessment,
    assessment,
    typeConsultation
  );
  await assessmentApi
    .postAssessment(uifId, normalizeParam)
    .then(() => {
      dispatch(actions.postAssessmentSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 新規・コピーの場合（編集ではないとき）
      if (assessment.assessment_records_id === null) {
        history.push(`${URL.RECORD_SUPPORT_PLAN}/${uifId}/assessment`);
      }
    })
    .catch((e) => {
      dispatch(actions.postAssessmentFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteAssessment = (dispatch: Dispatch) => async (
  uifId: string,
  assessmentId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteAssessmentStarted());
  await assessmentApi
    .deleteAssessment(uifId, assessmentId)
    .then(() => {
      dispatch(actions.deleteAssessmentSuccess());
    })
    .catch((e) => {
      dispatch(actions.deleteAssessmentFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchAssessment: (uifId: string, assessmentId: string) => Promise<void>;
  fetchAssessmentList: (uifId: string) => Promise<void>;
  postAssessment: (
    uifId: string,
    initialAssessment: Assessment,
    assessment: Assessment,
    history: H.History,
    typeConsultation?: number
  ) => Promise<void>;
  deleteAssessment: (uifId: string, assessmentId: string) => Promise<void>;
};

export const assessmentDispatcher = (dispatch: Dispatch): DispatchProps => ({
  fetchAssessment: fetchAssessment(dispatch),
  fetchAssessmentList: fetchAssessmentList(dispatch),
  postAssessment: postAssessment(dispatch),
  deleteAssessment: deleteAssessment(dispatch)
});
