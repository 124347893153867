import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetInquiryResponse = {
  data: {
    support_inquiries_id: number;
    creation_date: string;
    creation_staff: {
      id: number;
      name: string;
      facility_id: number;
      snapshot_name: string;
      snapshot_role: string;
      role: string;
      license: number;
      license_name: string;
    } | null;
    why_not: string;
    inquiry_purpose: string;
    inquiry_destination: string;
    inquiry_date: string;
    inquiry_content: string;
    respondent_name: string;
    response_date: string;
    response_content: string;
    type_consultation: number;
    created_at: string;
    updated_at: string;
  };
  response: {
    code: number;
    msg: string;
  };
};

/**
 * 指定ユーザーのサービス等利用計画、地域移行計画の情報を取得する
 * @param uifId ユーザーID
 * @param inquiryId 計画ID
 */
export const getInquiry = async (
  uifId: string,
  inquiryId: string
): Promise<AxiosResponse<GetInquiryResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/inquiry/${inquiryId}`;
  return request.get<GetInquiryResponse>(url);
};
