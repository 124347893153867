import * as React from "react";

import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SectionTitle from "@components/atoms/SectionTitle";

import DownloadTargetSelectPaper from "@components/organisms/mgr/IDOSHIEN/download/DownloadTargetSelectPaper";
import DownloadInvoiceSection, {
  DownloadAction
} from "@components/organisms/mgr/IDOSHIEN/download/DownloadInvoiceSection";
import { DownloadableMonth } from "@stores/domain/invoice/type";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      margin: spacing.unit * 2,
      padding: "30px 32px 32px"
    },
    description: {
      color: "#37474f",
      fontSize: "16px"
    }
  });

type OwnProps = {
  months: DownloadableMonth[];
  isDisabledButton: boolean;
  value: string;
  downloadCsvActions: DownloadAction[];
  excludedUserIds: number[];
  onChangeSelect: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickButton: (event: React.MouseEvent<HTMLSelectElement>) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

const DownloadTargetSelectCore = (props: Props): JSX.Element => {
  const { classes } = props;
  return (
    <Paper className={classes.root} elevation={0}>
      <SectionTitle label="集計データのダウンロード" />
      <Typography
        component="p"
        variant="caption"
        className={classes.description}
      >
        利用実績画面で入力した内容を集計したCSVファイルをダウンロードできます。
        <br />
        集計データは各種請求内容の確認や各自治体指定の帳票の作成などにご利用ください。
      </Typography>
      <DownloadTargetSelectPaper
        months={props.months}
        isDisabledButton={props.isDisabledButton}
        value={props.value}
        excludedUserIdCount={props.excludedUserIds.length}
        onChangeSelect={props.onChangeSelect}
        onClickButton={props.onClickButton}
      />
      <DownloadInvoiceSection downloadCsvActions={props.downloadCsvActions} />
    </Paper>
  );
};

export const DownloadTargetSelect = withStyles(styles)(
  DownloadTargetSelectCore
);
