import * as React from "react";

import FormPaper from "@components/atoms/FormPaper";
import FormGroup from "@material-ui/core/FormGroup";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import LackFields from "@components/organisms/mgr/common/facility/items/LackFields";

import { FormikProps, getIn } from "formik";

import {
  DEFAULT_SELECT_DATE_VALUE,
  SUBTRACTION_OF_LARGE_SCALE_HOUSING
} from "@constants/variables";
import { UnitsLargeScaleHousingFields } from "@components/organisms/mgr/GroupHome/facility/items/UnitsLargeScaleHousingFields";
import { FacilityValues } from "@initialize/mgr/GroupHome/facility/initialValues";

type Props = {
  formikProps: FormikProps<FacilityValues>;
};

export const SubtractionItemFields = ({ formikProps }: Props): JSX.Element => {
  // 複数ユニットに応じて大規模住居等減算のフィールドが変わる
  const { operatingUnitFlag } = formikProps.values.basic;
  const { units } = formikProps.values;

  const onChangeSubtractionOfLargeScaleHousingFlag = (): void => {
    const subtractionOfLargeScaleHousingFlag = getIn(
      formikProps.values,
      "subtractionItem.subtractionOfLargeScaleHousingFlag"
    );
    formikProps.setFieldValue(
      "subtractionItem.subtractionOfLargeScaleHousingFlag",
      !subtractionOfLargeScaleHousingFlag
    );
    if (operatingUnitFlag && !subtractionOfLargeScaleHousingFlag) {
      units.forEach((unit, index) => {
        formikProps.setFieldValue(
          `units[${index}].subtraction_of_large_scale_housing_type`,
          0
        );
      });
    }
  };
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "subtractionItem.bodyRestrictedStillFlg":
          formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.establishedByLocalGovernmentsFlag"
        label="地方公共団体が設置"
      />
      <LackFields formikProps={formikProps} name="subtractionItem" />
      <FormikSwitch
        name="subtractionItem.subtractionOfLargeScaleHousingFlag"
        label="大規模住居等減算"
        onChange={onChangeSubtractionOfLargeScaleHousingFlag}
      >
        {operatingUnitFlag ? (
          <UnitsLargeScaleHousingFields formikProps={formikProps} />
        ) : (
          <FormikRadioButtons
            name="subtractionItem.subtractionOfLargeScaleHousing"
            label=""
            options={SUBTRACTION_OF_LARGE_SCALE_HOUSING}
            style={{ marginTop: 0, marginLeft: 0 }}
          />
        )}
      </FormikSwitch>

      <FormikSwitch
        name="subtractionItem.bodyRestrictedStillFlg"
        label="身体拘束廃止未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>

      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>

      <FormikSwitch
        name="subtractionItem.workContinuationNotPlanningFlg"
        label="業務継続計画未策定減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>

      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};
