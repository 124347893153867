import { Dispatch } from "redux";
import * as actions from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/actions";
import dispatches from "@stores/dispatches";
import { inOutConsultationResults } from "@api/requests/v202104/inOutConsultationResults/";
import { dateInYYYYMMDDFormat, dateInYYYYMMFormat } from "@utils/date";
import {
  normalizeDailyReportDataFromAPI,
  normalizeUserReportDataFromAPI,
  normalizeDiffParamToPostAPI,
  normalizeRequestParamToPostAPI,
  normalizeFacilitiesKeikakusodanMonthlyParams
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/normalizer";
import * as loadingActions from "@stores/loading/actions";
import { InitialValues } from "@interfaces/v202104/mgr/KEIKAKUSODAN/report/initial";
import {
  KEIKAKUSODANReportTypeInterface,
  FacilitiesKEIKAKUSODANMonthlyType,
  ReportState,
  REPORT_DAILY
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/report/types";
import { FacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import { UsersInFacilityState } from "@stores/v202104/domain/mgr/KEIKAKUSODAN/userInFacility/types";
import { INT_TRUE_FROM_API } from "@constants/variables";

/**
 * 利用実績一覧(日ごと)データ取得及びstore格納
 */
const fetchKEIKAKUSODANDaily = (dispatch: Dispatch) => async (
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKEIKAKUSODANDailyStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsDaily(dateInYYYYMMDDFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKEIKAKUSODANDaily(
          normalizeDailyReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKEIKAKUSODANDailyFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 利用実績一覧(月ごと)のデータ取得及びstore格納
 */
const fetchKEIKAKUSODANUsers = (dispatch: Dispatch) => async (
  uifId: number,
  date: Date
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchKEIKAKUSODANUserStarted());
  await inOutConsultationResults
    .getInOutConsultationResultsMonthly(uifId, dateInYYYYMMFormat(date))
    .then((response) => {
      dispatch(
        actions.fetchKEIKAKUSODANUser(
          normalizeUserReportDataFromAPI(response.data)
        )
      );
      dispatches.uiDispatch(dispatch).loading(false);
    })
    .catch((e) => {
      dispatch(actions.fetchKEIKAKUSODANUserFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).loading(false);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 実績登録/更新
 */
const postKEIKAKUSODANReport = (dispatch: Dispatch) => async (
  beforeFormValue: InitialValues,
  afterFormValue: InitialValues,
  type: KEIKAKUSODANReportTypeInterface["type"],
  facility: FacilityState,
  usersInFacility: UsersInFacilityState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postKEIKAKUSODANReportStarted());

  const before = normalizeRequestParamToPostAPI(beforeFormValue);
  const after = normalizeRequestParamToPostAPI(afterFormValue);

  const requestParam = normalizeDiffParamToPostAPI(
    before,
    after,
    facility,
    usersInFacility
  );
  await inOutConsultationResults
    .postInOutConsultationResults(requestParam)
    .then(() => {
      const actionType =
        type === REPORT_DAILY
          ? actions.postKEIKAKUSODANReportDaily(requestParam)
          : actions.postKEIKAKUSODANReportUser(requestParam);
      dispatch(actionType);
    })
    .catch((e) => {
      dispatch(actions.postKEIKAKUSODANReportFailed({ error: e.response }));
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

/**
 * 施設の月ごと情報（facilities_keikakusodan_monthly）の登録/更新
 */
const postKEIKAKUSODANFacilityMonthly = (dispatch: Dispatch) => async ({
  checked,
  data,
  selectedDate,
  numberOfEmployees,
  numberOfHandicappedChild,
  numberOfKeikakusodan,
  changeTrainingByChiefFlg,
  trainingByChiefShogaijiFlg,
  facility
}: FacilitiesKEIKAKUSODANMonthlyType): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postFacilitiesKEIKAKUSODANMonthlyStarted());

  const requestParam = normalizeFacilitiesKeikakusodanMonthlyParams({
    checked,
    data,
    selectedDate,
    numberOfEmployees,
    numberOfHandicappedChild,
    numberOfKeikakusodan,
    changeTrainingByChiefFlg,
    trainingByChiefShogaijiFlg,
    facility
  });
  const {
    number_of_employees,
    number_of_handicapped_child,
    number_of_keikakusodan,
    training_by_chief_flg,
    training_by_chief_shogaiji_flg
  } = requestParam.params.facility;
  const stateParam: ReportState["reportDaily"]["inoutResultDaily"] = {
    targetDate: requestParam.yyyymm,
    numberOfEmployees: number_of_employees,
    numberOfHandicappedChild: number_of_handicapped_child,
    trainingByChiefFlg: training_by_chief_flg === INT_TRUE_FROM_API,
    numberOfKeikakusodan: number_of_keikakusodan,
    trainingByChiefShogaijiFlg:
      training_by_chief_shogaiji_flg === INT_TRUE_FROM_API
  };
  await inOutConsultationResults
    .postFacilitiesMonthly(requestParam)
    .then(() => {
      dispatch(actions.postFacilitiesKEIKAKUSODANMonthly(stateParam));
    })
    .catch((e) => {
      dispatch(
        actions.postFacilitiesKEIKAKUSODANMonthlyFailed({ error: e.response })
      );
      dispatches.uiDispatch(dispatch).responseError(e.response);
      dispatches.uiDispatch(dispatch).snackbar({
        open: true,
        message: "通信エラー",
        variant: "error"
      });
    })
    .finally(() => {
      dispatch(loadingActions.loadDone());
    });
};

type Dispatcher = {
  fetchKEIKAKUSODANDaily: (date: Date) => Promise<void>;
  fetchKEIKAKUSODANUsers: (uifId: number, date: Date) => Promise<void>;
  postKEIKAKUSODANReport: (
    beforeFormValue: InitialValues,
    afterFormValue: InitialValues,
    type: KEIKAKUSODANReportTypeInterface["type"],
    facility: FacilityState,
    usersInFacility: UsersInFacilityState
  ) => Promise<void>;
  postKEIKAKUSODANFacilityMonthly: (
    args: FacilitiesKEIKAKUSODANMonthlyType
  ) => Promise<void>;
};

export const KEIKAKUSODANReportDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchKEIKAKUSODANDaily: fetchKEIKAKUSODANDaily(dispatch),
  fetchKEIKAKUSODANUsers: fetchKEIKAKUSODANUsers(dispatch),
  postKEIKAKUSODANReport: postKEIKAKUSODANReport(dispatch),
  postKEIKAKUSODANFacilityMonthly: postKEIKAKUSODANFacilityMonthly(dispatch)
});
