import { currentBrowserName, getBrowserFromUA, BROWSER } from "@utils/browser";
import {
  currentTerminalName,
  getTerminalFromUA,
  TERMINAL
} from "@utils/terminal";

export const RECOMMENDATION_NAME = {
  IPHONE: "iPhone / Safari",
  ANDROID: "Android / Google Chrome",
  MAC: "Mac / Google Chrome",
  WINDOWS: "Windows / Google Chrome",
  IPAD: "iPad / Google Chrome"
};
export type RecommendationName = {
  recommendation: boolean;
  browserName: string;
  terminalName: string;
  recommendationName?: string;
};

const userAgentCheck = (): string => {
  return navigator.userAgent.toLowerCase();
};

export const recommendationCheck = (): RecommendationName => {
  const ua = userAgentCheck();
  const res: RecommendationName = {
    recommendation: false,
    browserName: currentBrowserName(),
    terminalName: currentTerminalName()
  };

  const mobile = (): void => {
    if (
      getTerminalFromUA() === TERMINAL.IPHONE &&
      getBrowserFromUA() === BROWSER.SAFARI
    ) {
      // iPhone
      res.recommendation = true;
    } else if (
      getTerminalFromUA() === TERMINAL.IPHONE &&
      getBrowserFromUA() !== BROWSER.SAFARI
    ) {
      res.recommendation = false;
      res.recommendationName = RECOMMENDATION_NAME.IPHONE;
    } else if (
      getTerminalFromUA() === TERMINAL.ANDROID &&
      getBrowserFromUA() === BROWSER.CHROME
    ) {
      // Android
      res.recommendation = true;
    } else if (
      getTerminalFromUA() === TERMINAL.ANDROID &&
      getBrowserFromUA() !== BROWSER.CHROME
    ) {
      res.recommendation = false;
      res.recommendationName = RECOMMENDATION_NAME.ANDROID;
    }
  };

  const tablePc = (): void => {
    if (getBrowserFromUA() === BROWSER.CHROME) {
      res.recommendation = true;
    } else {
      res.recommendation = false;
      if (getTerminalFromUA() === TERMINAL.MAC) {
        res.recommendationName = RECOMMENDATION_NAME.MAC;
      } else if (getTerminalFromUA() === TERMINAL.WINDOWS) {
        res.recommendationName = RECOMMENDATION_NAME.WINDOWS;
      } else if (getTerminalFromUA() === TERMINAL.IPAD) {
        res.recommendationName = RECOMMENDATION_NAME.IPAD;
      } else if (getTerminalFromUA() === TERMINAL.ANDROID) {
        res.recommendationName = RECOMMENDATION_NAME.ANDROID;
      }
    }
  };

  if (ua.match(/(iPhone|iPod|Android.*Mobile)/i)) {
    // スマホ
    mobile();
  } else {
    // PC・タブレット
    tablePc();
  }

  return res;
};
