import { AxiosError } from "axios";
import _has from "lodash-es/has";

import { MUNICIPALITY_POST_ERROR_CODE } from "@constants/mgr/IDOSHIEN/variables";

export type ApiCsvError = {
  data: null;
  response: {
    code: number;
    msg: string;
    validation: {
      file?: string[];
      csv?: string[];
    };
  };
};

/**
 * CSVのファイルエラーなのか型判定
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkCsvError = (error: any): error is AxiosError<ApiCsvError> => {
  if (!error.response) {
    return false;
  }
  if (!_has(error.response, "data")) {
    return false;
  }
  if (!_has(error.response.data, "response")) {
    return false;
  }
  if (
    error.response.data.response.code !== MUNICIPALITY_POST_ERROR_CODE.fileError
  ) {
    return false;
  }
  return true;
};
