import * as React from "react";
import { FacilityState } from "@stores/domain/mgr/GroupHome/facility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/GroupHome/userInFacility/types";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import { SectionTitleWithButtonFlex } from "@components/molecules/SectionTitleWithButtonFlex";
import { dateToLocalisedString } from "@/utils/date";
import getAge from "@utils/date/getAge";
import { GetSupportPlanResponse } from "@api/requests/supportPlan/getSupportPlan";
import { PlannedTargetDialogHasMonitoring } from "@components/organisms/mgr/common/record/PlannedTargetDialogHasMonitoring";
import { SHORT_TERMS_NUM } from "@constants/mgr/GroupHome/variables";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "32px 32px 24px"
    },
    appBar: {
      marginTop: 32,
      borderRadius: 3
    },
    button: {
      minWidth: 120
    },
    table: {
      marginTop: 8
    },
    labelCell: {
      fontSize: 14,
      color: "#37474f",
      padding: "8px 16px 8px 0",
      "&:first-child": {
        width: "150px"
      }
    },
    cell: {
      fontWeight: 500,
      padding: "0 48px 0 8px"
    },
    week: {
      display: "flex",
      color: "#fff",
      "& > span": {
        display: "block",
        fontWeight: "normal",
        lineHeight: 1,
        padding: 6,
        marginRight: 8,
        borderRadius: "50%"
      }
    },
    greenLabel: {
      backgroundColor: "#3daa8d"
    },
    grayLabel: {
      backgroundColor: "#bababa"
    },
    colorlessLabel: {
      color: "#333"
    }
  });

type OwnProps = {
  userName: string;
  facility: FacilityState;
  user: UsersInFacilityState["user"];
  supportPlan?: GetSupportPlanResponse["data"];
  isEditing: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const UserInfoRecordCore = (props: Props): JSX.Element => {
  // 障害種別
  const {
    classify_physical_flg,
    classify_intelligence_flg,
    classify_mind_flg,
    classify_growth_flg,
    classify_brain_flg,
    classify_handicapped_flg,
    classify_incurable_flg
  } = props.user.user_in_facility;
  const classifies: string[] = [];
  if (classify_physical_flg === "1") {
    classifies.push("身体障害");
  }
  if (classify_intelligence_flg === "1") {
    classifies.push("知的障害");
  }
  if (classify_mind_flg === "1") {
    classifies.push("精神障害");
  }
  if (classify_growth_flg === "1") {
    classifies.push("発達障害");
  }
  if (classify_brain_flg === "1") {
    classifies.push("高次脳機能障害");
  }
  if (classify_handicapped_flg === "1") {
    classifies.push("障害児");
  }
  if (classify_incurable_flg === "1") {
    classifies.push("難病等対象者");
  }

  // 計画目標モーダル
  const filteredPlan = (props.supportPlan || []).filter((plan) => {
    return !plan.archive;
  });
  const latestPlan =
    filteredPlan.length > 0 &&
    filteredPlan.reduce((prev, current) => {
      return prev.id > current.id ? prev : current;
    });
  const [isOpenDialog, setOpenDialog] = React.useState(false);
  const openModal = (): void => {
    setOpenDialog(true);
  };
  const closeDialog = (): void => {
    setOpenDialog(false);
  };

  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <SectionTitleWithButtonFlex label={props.userName}>
          <KnowbeButton
            kind="outline"
            onClick={openModal}
            disabled={!latestPlan || props.isEditing}
          >
            計画目標
          </KnowbeButton>
        </SectionTitleWithButtonFlex>
        <table className={props.classes.table}>
          <tbody>
            <tr>
              <td className={props.classes.labelCell}>サービス提供開始日</td>
              <td className={props.classes.cell}>
                {props.user.user_in_facility.date_begin_in_service &&
                  dateToLocalisedString(
                    props.user.user_in_facility.date_begin_in_service,
                    "YYYY年M月D日"
                  )}
              </td>
              <td className={props.classes.labelCell}>性別</td>
              <td className={props.classes.cell}>
                {props.user.user_in_facility.gender &&
                props.user.user_in_facility.gender === "1"
                  ? "男性"
                  : "女性"}
              </td>
              <td className={props.classes.labelCell}>生年月日</td>
              <td className={props.classes.cell}>
                {props.user.user_in_facility.date_birth &&
                  `${dateToLocalisedString(
                    props.user.user_in_facility.date_birth,
                    "YYYY年M月D日"
                  )}（${getAge(props.user.user_in_facility.date_birth)}歳）`}
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td className={props.classes.labelCell}>障害種別</td>
              <td className={props.classes.cell}>
                {classifies.length > 0 ? classifies.join("、") : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </Paper>
      {props.supportPlan && (
        <PlannedTargetDialogHasMonitoring
          isOpen={isOpenDialog}
          onClose={closeDialog}
          supportPlan={props.supportPlan}
          userName={props.userName}
          shortTermsNum={SHORT_TERMS_NUM}
        />
      )}
    </>
  );
};

export const UserInfoRecord = withStyles(styles)(UserInfoRecordCore);
