import * as React from "react";
import * as ClassNames from "classnames";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { createStyles, WithStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import DropDown, { OptionInterface } from "@components/atoms/DropDown";
import DateSelectButtonsMonthly from "@components/molecules/DateSelectButtonsMonthly";
import { generateDropDownOptions } from "@utils/dataNormalizer";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    floatLeft: {
      float: "left"
    },
    dateButtonsContainer: {
      paddingTop: spacing.unit
    },
    usersDropDown: {
      marginTop: "0px !important",
      marginLeft: spacing.unit * 2
    },
    headerInfoContainer: {
      minHeight: 56,
      marginTop: 16,
      marginLeft: 16,
      width: "100%"
    }
  });

type StateProps = {
  userInFacility: UsersInFacilityState;
};

type DispatchProps = {
  fetchFacilityUserList: (date: Date) => Promise<void>;
};

type OwnProps = {
  minDate: Date;
  maxDate: Date;
  selectedMonth: Date;
  selectedUserId: string | number;
  onChangeMonth: (date: Date, user: OptionInterface) => void;
  onChangeUser: (user: OptionInterface) => void;
};

type MergeProps = OwnProps &
  DispatchProps & {
    facilityListOption: OptionInterface[];
  };

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 利用実績（月ごと）- 月変更 & 利用者変更（通常時）
 */
const InOutReportUserHeaderCore = (props: Props): JSX.Element => {
  React.useEffect((): void => {
    if (!props.selectedUserId) {
      props.fetchFacilityUserList(props.selectedMonth);
    }
  }, [props.selectedUserId]);

  React.useEffect((): void => {
    if (
      !props.selectedUserId &&
      props.facilityListOption &&
      props.facilityListOption[0]
    ) {
      props.onChangeUser(props.facilityListOption[0]);
    }
  }, [props.facilityListOption]);

  const onChangeMonth = (date: Date): void => {
    props.fetchFacilityUserList(date).then(() => {
      const facility = props.facilityListOption.filter(
        (x) => x.value === props.selectedUserId
      );
      let selectedUser;
      if (facility.length > 0) {
        [selectedUser] = facility;
      } else if (props.facilityListOption.length > 0) {
        [selectedUser] = props.facilityListOption;
      } else {
        selectedUser = { label: "", value: props.selectedUserId };
      }
      props.onChangeMonth(date, selectedUser);
    });
  };

  const onChangeUser = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const facility = props.facilityListOption.filter(
      (x) => x.value === event.target.value
    );
    if (facility.length > 0) {
      props.onChangeUser(facility[0]);
    }
  };

  const {
    classes,
    minDate,
    maxDate,
    selectedMonth,
    selectedUserId,
    facilityListOption
  } = props;

  return (
    <>
      <div className={classes.headerInfoContainer}>
        <div
          className={ClassNames(
            classes.floatLeft,
            classes.dateButtonsContainer
          )}
        >
          <DateSelectButtonsMonthly
            selectedMonth={selectedMonth}
            min={minDate}
            max={maxDate}
            onClickSubmit={onChangeMonth}
          />
        </div>
        <div className={classes.floatLeft}>
          <DropDown
            id="users-in-facility-list"
            label="利用者"
            isError={false}
            options={facilityListOption}
            value={`${selectedUserId}`}
            styles={classes.usersDropDown}
            onChange={onChangeUser}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userInFacility: state.CHIIKIIKO.userInFacility
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { CHIIKIIKO } = dispatches;
  const userInFacilityDispatcher = CHIIKIIKO.userInFacilityDispatcher(dispatch);
  return {
    fetchFacilityUserList: (date: Date): Promise<void> =>
      userInFacilityDispatcher.fetch(date)
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  // 利用者一覧をドロップダウンで使用する形式に整形する
  const facilityListOption = stateProps.userInFacility.users.map((user) => {
    return generateDropDownOptions({
      label: user.displayName,
      value: user.uif_id
    });
  });
  return {
    facilityListOption,
    ...ownProps,
    ...dispatchProps
  };
};

export const InOutReportUserHeader = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(InOutReportUserHeaderCore));
