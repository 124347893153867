import * as actions from "./actions";
import * as types from "./types";

const initialState: types.ConsultationState = {
  consultationRecord: {
    id: 0,
    createdAt: "",
    updatedAt: "",
    targetFlg: 0,
    creationDate: "",
    author: null,
    attainmentGoal: "",
    longTermGoal: "",
    shortTermGoal: "",
    startYm: "",
    endYm: "",
    supportPlanConsultationDetails: [],
    staffComment: ""
  },
  consultationCopy: null
};

export const CHIIKIIKOConsultation = (
  state = initialState,
  action: actions.ActionTypes
): types.ConsultationState => {
  switch (action.type) {
    case types.FETCH_CONSULTATION_STARTED:
      return { ...state };
    case types.FETCH_CONSULTATION_SUCCESS:
      return { ...state, consultationRecord: action.payload };
    case types.FETCH_CONSULTATION_FAILED:
      return { ...state };
    case types.COPY_CONSULTATION_STARTED:
      return { ...state };
    case types.COPY_CONSULTATION_SUCCESS:
      return {
        ...state,
        consultationCopy: action.payload.res
      };
    case types.COPY_CONSULTATION_FAILED:
      return { ...state };
    case types.RESET_COPY_CONSULTATION:
      return {
        ...state,
        consultationCopy: null
      };
    case types.POST_CONSULTATION_STARTED:
      return { ...state };
    case types.POST_CONSULTATION_SUCCESS:
      return { ...state };
    case types.POST_CONSULTATION_FAILED:
      return { ...state };
    case types.DELETE_CONSULTATION_STARTED:
      return { ...state };
    case types.DELETE_CONSULTATION_SUCCESS:
      return { ...state };
    case types.DELETE_CONSULTATION_FAILED:
      return { ...state };
    default:
      return state;
  }
};
