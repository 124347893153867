import * as React from "react";
import { createStyles, withStyles, WithStyles } from "@material-ui/core";
import RectangleBox from "@components/atoms/RectangleBox";

const styles = createStyles({
  contents: {
    display: "flex",
    justifyContent: "space-between" as "space-between",
    alignItems: "flex-end" as "flex-end",
    marginBottom: 16
  },
  rectangleListContents: {
    display: "flex",
    justifyContent: "flex-start" as "flex-start",
    "& > :last-child": {
      marginRight: 0
    }
  },
  // RectangleBox用のを上書き
  wrapper: {
    width: 130,
    "& > div": {
      "& > div": {
        color: "#666"
      }
    }
  }
});

type OwnProps = {
  rectangleConfigList: {
    title: string;
    num: number;
    denom?: number;
    unit: string;
  }[];
};
type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportPaperHeaderCore = ({
  classes,
  rectangleConfigList
}: Props): JSX.Element => (
  <div>
    {rectangleConfigList && rectangleConfigList.length > 0 && (
      <div className={classes.contents}>
        <div className={classes.rectangleListContents}>
          {rectangleConfigList.map((rectangleConfig, index) => {
            const uniqueKey = `${rectangleConfig.title}-${index}`;
            return (
              <RectangleBox
                classes={{ wrapper: classes.wrapper }}
                key={uniqueKey}
                {...rectangleConfig}
              />
            );
          })}
        </div>
      </div>
    )}
  </div>
);

export const InOutReportPaperHeader = withStyles(styles)(
  InOutReportPaperHeaderCore
);
