import { GetMeetingRecordResponse } from "@api/requests/meetingRecord/getMeetingRecord";

/**
 * ActionNames
 */
export const FETCH_CSEG_MEETING_RECORD_STARTED =
  "CSEG/FETCH_MEETING_RECORD_STARTED";
export const FETCH_CSEG_MEETING_RECORD_SUCCESS =
  "CSEG/FETCH_MEETING_RECORD_SUCCESS";
export const FETCH_CSEG_MEETING_RECORD_FAILED =
  "CSEG/FETCH_MEETING_RECORD_FAILED";
export const POST_CSEG_MEETING_RECORD_STARTED =
  "CSEG/POST_CSEG_MEETING_RECORD_STARTED";
export const POST_CSEG_MEETING_RECORD_SUCCESS =
  "CSEG/POST_CSEG_MEETING_RECORD_SUCCESS";
export const POST_CSEG_MEETING_RECORD_FAILED =
  "CSEG/POST_CSEG_MEETING_RECORD_FAILED";
export const DELETE_CSEG_MEETING_RECORD_STARTED =
  "CSEG/POST_CSEG_MEETING_RECORD_STARTED";
export const DELETE_CSEG_MEETING_RECORD_SUCCESS =
  "CSEG/POST_CSEG_MEETING_RECORD_SUCCESS";
export const DELETE_CSEG_MEETING_RECORD_FAILED =
  "CSEG/POST_CSEG_MEETING_RECORD_FAILED";
export const UNSET_CSEG_MEETING_RECORD = "CSEG/UNSET_CSEG_MEETING_RECORD";

/**
 * State
 */
export type MeetingRecordState = GetMeetingRecordResponse["data"];
