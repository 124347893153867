import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
// ui
import LineBreak from "@components/atoms/LineBreak";
// store
import { GetServiceDeliveryPrint } from "@api/requests/serviceDelivery/getServiceDeliveryPrint";
import {
  ServiceDeliveryRecordsInput,
  ServiceDeliveryState
} from "@stores/domain/serviceDelivery/types";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
// constants
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH
} from "@/constants/styles";
import { CustomRecordItemValues } from "@initialize/record/customRecordWithCategory/initialValues";
import {
  CUSTOM_RECORD_DEFAULT_CHOICE_UNIT,
  FacilityType,
  SERVICE_DELIVERY_PRINT_TITLE_OPTIONS,
  SUPPORT_CUSTOM_RECORD_INPUT_TYPE
} from "@constants/variables";
import {
  MIXUTER_INPUT_TYPE,
  MIXUTER_INPUT_TYPE_DEFAULT_CHOICE
} from "@constants/mgr/KYOTAKUKAIGO/variables";
import { CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE } from "@constants/mgr/DOKOENGO/variables";
// utils
import * as format from "date-fns/format";
import pako from "pako";
import { dateTodayInFormat } from "@utils/date";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import ClassNames from "classnames";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import { ServiceDeliveryPrintMoneyManagementsTableBody } from "@components/organisms/record/print/ServiceDeliveryPrintMoneyManagementsTableBody";
import {
  IDOSHIEN_DISPLAY_STATUS_LIST,
  IDOSHIEN_STATUS_LIST,
  SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN
} from "@constants/mgr/IDOSHIEN/variables";
import convertHHMMToMinutes from "@utils/date/convertHHMMToMinutes";
import formatMinutes from "@utils/date/formatMinutes";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `24px ${PRINT_PAGE_PADDING / 2}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      "&:last-child": {
        margin: "0 auto"
      },
      position: "relative",
      "print-color-adjust": "exact"
    },
    confirmationColumn: {
      position: "absolute",
      top: "25px",
      right: `${PRINT_PAGE_PADDING / 2}px`
    },
    title: {
      margin: 0,
      fontSize: "18px",
      fontWeight: "normal",
      textAlign: "left",
      color: "#212121"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start"
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",

      "&.fullWidth": {
        width: "100%"
      },
      "& td": {
        padding: "5px 4px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: "normal",
        height: 20,
        color: "rgba(0, 0, 0, 0.84)",
        borderBottom: "1px solid",
        "&.ssize": {
          width: "14%"
        },
        "&.lsize": {
          width: "74%"
        },
        "&.titleLabel": {
          width: 71,
          height: 0,
          textAlign: "center",
          lineHeight: 1
        },
        "&.titleContents": {
          width: 71,
          height: 56
        },
        "&.vertical": {
          width: 18,
          letterSpacing: 0,
          height: 80,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.verticalTop": {
          verticalAlign: "top"
        },
        "&.noBorder": { borderBottom: "none" },
        "&.topBorder": { borderTop: "1px solid" },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.bgGrey": {
          backgroundColor: "#f5f5f5"
        }
      },
      "& tr": {
        "&.borderBold": {
          borderBottom: "2px solid"
        }
      }
    },
    entryFieldContainer: {
      fontSize: 10,
      borderBottom: "1px solid #000",
      marginTop: 16,
      "&.disabilityClass": {
        width: 145
      }
    },
    userName: {
      width: 120,
      marginRight: 16
    },
    serviceDate: {
      width: 280,
      marginRight: 16
    },
    entryFieldLabel: {
      fontSize: 8,
      marginBottom: 12,
      color: "#424242"
    },
    entryFieldContentContainer: {
      fontFamily: "HiraginoSans-W6",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: 5,
      marginTop: 4,
      color: "#212121",
      fontSize: 10
    },
    "@media print": {
      page: {
        width: "200mm",
        height: "auto",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });

type OwnProps = {
  records: ServiceDeliveryState["print"];
  customRecords: CustomRecordsWithCategoryState;
  displayColumns: string[];
};

type Props = OwnProps & WithStyles<typeof styles>;

type SheetOwnProps = {
  record: GetServiceDeliveryPrint["data"][0];
  customRecords: CustomRecordsWithCategoryState;
  title: string;
  isStaffComment: boolean;
};
type SheetProps = SheetOwnProps & WithStyles<typeof styles>;

const ServiceDeliveryPrintCore = (props: Props): JSX.Element | null => {
  const { classes, records, customRecords, displayColumns } = props;

  const title = displayColumns.includes(
    SERVICE_DELIVERY_PRINT_TITLE_OPTIONS[0].value
  )
    ? SERVICE_DELIVERY_PRINT_TITLE_OPTIONS[0].label
    : SERVICE_DELIVERY_PRINT_TITLE_OPTIONS[1].label;

  const isStaffCommentParam = displayColumns.includes("staff_comment");
  // 「order」順
  const sortedCustomRecord = customRecords
    .map((category) => {
      const sortedCustomRecordItems = category.custom_record_items.sort(
        (a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        }
      );
      return { ...category, custom_record_items: sortedCustomRecordItems };
    })
    .sort((a, b) => {
      if (!a.category_type && !b.category_type) return 0;
      if (!a.category_type) return 1;
      if (!b.category_type) return -1;
      return a.category_type - b.category_type;
    });

  // 文字列をUint8Arrayに変換
  function gunZip(str: string): GetServiceDeliveryPrint["data"][0] {
    const charList = atob(str).split("");
    const uintArray = [];
    for (let i = 0; i < charList.length; i += 1) {
      uintArray.push(charList[i].charCodeAt(0));
    }
    const uint = new Uint8Array(uintArray);
    return JSON.parse(pako.ungzip(uint, { to: "string" }));
  }

  return (
    <>
      {records.map((gzipRecord: string) => {
        const record: GetServiceDeliveryPrint["data"][0] = gunZip(gzipRecord);
        if (record === undefined) {
          return null;
        }
        return (
          <Sheet
            classes={classes}
            record={record}
            customRecords={sortedCustomRecord}
            title={title}
            isStaffComment={isStaffCommentParam}
            key={`page-${record.service_delivery_records_id}`}
          />
        );
      })}
    </>
  );
};

// サービス日時・サービス提供者の時間計算
const createTimeInfo = (
  startTime: string | null,
  endTime: string | null,
  numberOfTime?: number | string,
  status?: number
): string => {
  let serviceDateStartTime = "--:--";
  let serviceDateEndTime = "--:--";
  if (startTime) {
    const serviceDateStartTimeArray = startTime.split(" ");
    serviceDateStartTime = `${convertHHMMSSToHHMM(
      serviceDateStartTimeArray[1]
    )}`;
  }
  if (endTime) {
    const serviceDateEndTimeArray = endTime.split(" ");
    serviceDateEndTime = `${convertHHMMSSToHHMM(serviceDateEndTimeArray[1])}`;
  }
  const serviceDateTime =
    !startTime && !endTime
      ? ""
      : `${serviceDateStartTime}〜${serviceDateEndTime}`;
  if (!!numberOfTime && serviceDateTime && status) {
    if (typeof numberOfTime === "number") {
      return `${serviceDateTime}（${numberOfTime.toFixed(1)}時間）`;
    }
    const minutes = convertHHMMToMinutes(numberOfTime);
    return typeof minutes === "number"
      ? `${serviceDateTime}（${formatMinutes(minutes)}）`
      : serviceDateTime;
  }
  return serviceDateTime;
};

// サービス内容の詳細作成（内容）
const createContents = (
  customRecord: CustomRecordItemValues[0],
  inputData: ServiceDeliveryRecordsInput[]
): JSX.Element | null => {
  switch (customRecord.input_type) {
    // テキストタイプ
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text: {
      const textInputData = inputData[0].input_data || null;

      if (!textInputData) {
        return null;
      }

      return <LineBreak text={textInputData} />;
    }
    // チェックボックスタイプ
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox: {
      const checkboxDataIds = inputData
        .filter((item) => !!item.checked)
        .map((checkedItem) => checkedItem.choiced_item_id);
      const choicedCustomRecord = customRecord.custom_record_item_choices
        .filter((choice) => checkboxDataIds.includes(choice.id))
        .map((checkedItemchoiceItem) => checkedItemchoiceItem.name);
      const checkedName =
        choicedCustomRecord.length > 0 ? choicedCustomRecord.join("、") : "";

      return <LineBreak text={checkedName} />;
    }
    // 複数テキストタイプ（バイタル）
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.multi_text: {
      const multiTextContents: JSX.Element[] = [];

      [1, 2, 3].forEach((defaultChoiceNum) => {
        const choiced = customRecord.custom_record_item_choices.find(
          (choiceItem) => choiceItem.default_choice === defaultChoiceNum
        );
        const choicedInputData =
          choiced &&
          inputData.find(
            (inputDataItem) =>
              inputDataItem.input_data &&
              inputDataItem.choiced_item_id === choiced.id
          );

        if (choiced && choicedInputData) {
          // 単位
          const unit = CUSTOM_RECORD_DEFAULT_CHOICE_UNIT;

          multiTextContents.push(
            <span key={choiced.id} style={{ display: "inline-block" }}>
              {choiced.name} : {choicedInputData.input_data}
              {unit[`${defaultChoiceNum}`]}&emsp;
            </span>
          );
        }
      });

      return <span>{multiTextContents}</span>;
    }
    // 選択タイプ
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.mixture: {
      // チェックボックス形式
      const checkBoxItem = customRecord.custom_record_item_choices.find(
        (choiceItem) =>
          choiceItem.default_choice_input_type === MIXUTER_INPUT_TYPE.checkbox
      );

      const checkBoxItemName = checkBoxItem ? checkBoxItem.name : "";
      // 時間形式
      const startTimeItem = customRecord.custom_record_item_choices.find(
        (choiceItem) =>
          choiceItem.default_choice_input_type === MIXUTER_INPUT_TYPE.text &&
          choiceItem.default_choice === MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.start
      );
      const startItemInputData = inputData.find(
        (input) => startTimeItem && startTimeItem.id === input.choiced_item_id
      );
      const startItemContent =
        startItemInputData && startItemInputData.input_data
          ? format(startItemInputData.input_data, "HH:mm")
          : "--:--";
      const endTimeItem = customRecord.custom_record_item_choices.find(
        (choiceItem) =>
          choiceItem.default_choice_input_type === MIXUTER_INPUT_TYPE.text &&
          choiceItem.default_choice === MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.end
      );
      const endTimeItemInputData = inputData.find(
        (input) => endTimeItem && endTimeItem.id === input.choiced_item_id
      );
      const endTimeItemContent =
        endTimeItemInputData && endTimeItemInputData.input_data
          ? format(endTimeItemInputData.input_data, "HH:mm")
          : "--:--";

      const timeContent =
        (startItemInputData && startItemInputData.input_data) ||
        (endTimeItemInputData && endTimeItemInputData.input_data)
          ? `（${startItemContent} 〜 ${endTimeItemContent}）`
          : "";

      // テキスト形式
      const textItem = customRecord.custom_record_item_choices.find(
        (choiceItem) =>
          choiceItem.default_choice_input_type === MIXUTER_INPUT_TYPE.text &&
          choiceItem.default_choice ===
            MIXUTER_INPUT_TYPE_DEFAULT_CHOICE.destination
      );
      const textItemInputData = inputData.find(
        (input) => textItem && textItem.id === input.choiced_item_id
      );

      return (
        <>
          <span>{`${checkBoxItemName}${timeContent}`}</span>
          {textItemInputData && textItemInputData.input_data && textItem && (
            <div>
              {textItem.name} : {textItemInputData.input_data}
            </div>
          )}
        </>
      );
    }
    // 金額入力タイプ
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.money: {
      const multiTextContents: JSX.Element[] = [];
      const detailsContents: JSX.Element[] = [];
      [1, 2, 3].forEach((defaultChoiceNum) => {
        const choiced = customRecord.custom_record_item_choices.find(
          (choiceItem) => choiceItem.default_choice === defaultChoiceNum
        );
        const choicedInputData =
          choiced &&
          inputData.find(
            (inputDataItem) =>
              inputDataItem.input_data &&
              inputDataItem.choiced_item_id === choiced.id
          );

        if (choiced && choicedInputData) {
          multiTextContents.push(
            <span key={choiced.id} style={{ display: "inline-block" }}>
              {choiced.name} : {choicedInputData.input_data}円&emsp;
            </span>
          );
        }
      });

      const contents = (
        <div>
          <span>{multiTextContents}</span>
          {detailsContents.length > 0 && (
            <div>
              <span>（内訳{detailsContents}）</span>
            </div>
          )}
        </div>
      );

      return contents;
    }
    // チェックボックス（カスタム可）＋テキスト
    case SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox_and_text: {
      // チェックボックス形式
      const checkboxItem = customRecord.custom_record_item_choices.filter(
        (choiceItem) =>
          choiceItem.default_choice_input_type ===
          SUPPORT_CUSTOM_RECORD_INPUT_TYPE.checkbox
      );
      let checkboxNames = "";
      checkboxItem.forEach((i) => {
        checkboxNames += `${i.name} `;
      });

      // テキスト形式
      const textItem = customRecord.custom_record_item_choices.find(
        (choiceItem) =>
          choiceItem.default_choice_input_type ===
            SUPPORT_CUSTOM_RECORD_INPUT_TYPE.text &&
          choiceItem.default_choice ===
            CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE.other
      );
      const textItemInputData = inputData.find(
        (input) => textItem && textItem.id === input.choiced_item_id
      );

      return (
        <>
          {checkboxNames && <span>{`${checkboxNames}`}</span>}
          {textItemInputData && textItemInputData.input_data && textItem && (
            <div>
              {textItem.name} : {textItemInputData.input_data}
            </div>
          )}
        </>
      );
    }
    default:
      return null;
  }
};

// サービス内容の詳細テーブル作成
const createTableBody = (
  practitionerNumber: number,
  serviceDeliveryPrint: GetServiceDeliveryPrint["data"][0],
  classes: Record<string, string>,
  filteredCustomRecords: CustomRecordsWithCategoryState,
  serviceDeliveryRecordsPractitioner: GetServiceDeliveryPrint["data"][0]["service_delivery_record_practitioners"][0]["service_delivery_records"],
  isStaffComment: boolean
): JSX.Element => {
  return (
    <>
      <ServiceDeliveryPrintMoneyManagementsTableBody
        practitionerNumber={practitionerNumber}
        serviceDeliveryPrint={serviceDeliveryPrint}
        classes={classes}
        filteredCustomRecords={filteredCustomRecords}
        serviceDeliveryRecordsPractitioner={serviceDeliveryRecordsPractitioner}
        facilityType={FacilityType.IDOSHIEN}
      />
      <div style={{ marginTop: 16 }}>
        <table className={`${classes.table}`} style={{ width: "100%" }}>
          <tbody>
            {filteredCustomRecords.map((record) => {
              const keyNumber = record.category_type;
              const serviceRecordCategory =
                serviceDeliveryRecordsPractitioner || null;
              if (
                !serviceRecordCategory ||
                serviceRecordCategory.length === 0
              ) {
                return null;
              }
              // 適用するカテゴリータイプのservice_delivery_records抽出
              const recordCategory = serviceRecordCategory.find(
                (item) => item.custom_records_category_id === record.id
              );
              if (!recordCategory) {
                return null;
              }
              // 記録がある項目のみ抽出
              const recordedItems = record.custom_record_items.filter(
                (item) => {
                  // ServiceDeliveryPrintMoneyManagementsTableBodyで表示するためこちらでは非表示
                  if (
                    item.default_item ===
                      SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.cost ||
                    item.default_item ===
                      SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit
                  ) {
                    return false;
                  }
                  // 職員考察のチェックOFFの場合は職員考察は表示しない
                  if (
                    item.default_item ===
                      SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.staff_comment &&
                    !isStaffComment
                  ) {
                    return false;
                  }
                  return (
                    recordCategory.input.filter(
                      (inputItem) =>
                        inputItem.custom_record_item_id === item.id &&
                        (inputItem.input_data || inputItem.checked)
                    ).length > 0
                  );
                }
              );
              if (recordedItems.length === 0) {
                return null;
              }
              return recordedItems.map((item, index) => {
                const data = recordCategory.input.filter(
                  (recordItem) => recordItem.custom_record_item_id === item.id
                );
                const inputData = createContents(item, data);

                if (index === 0) {
                  return (
                    <tr key={`${keyNumber}-${item.id}`}>
                      <td className="noBorder topBorder ssize verticalTop bgGrey">
                        <span>{record.name}</span>
                      </td>
                      <td className="ssize verticalTop bgGrey">{item.name}</td>
                      <td className="lsize verticalTop">{inputData}</td>
                    </tr>
                  );
                }
                return (
                  <tr key={`${keyNumber}-${item.id}`}>
                    <td className="noBorder ssize verticalTop bgGrey" />
                    <td className="ssize verticalTop bgGrey">{item.name}</td>
                    <td className="lsize verticalTop">{inputData}</td>
                  </tr>
                );
              });
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

const Sheet = (props: SheetProps): JSX.Element => {
  const { classes, record, customRecords, title, isStaffComment } = props;

  // サービス日時
  const serviceDate = dateTodayInFormat(record.target_date, true);
  const serviceDateTime = createTimeInfo(record.start_time, record.end_time);
  const statusLabel = getLabelFromOptions(
    record.status.toString(),
    IDOSHIEN_DISPLAY_STATUS_LIST
  );

  const isGroup = record.status === Number(IDOSHIEN_STATUS_LIST.GROUP.value);
  const isVehicleTransport =
    record.status === Number(IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value);

  // サービス提供者１の氏名
  const practitioner1NameLabel =
    record.number_of_practitioner > 1 ? "サービス提供者 1" : "サービス提供者";

  const practitionerNames = Array.from(
    new Array(record.number_of_practitioner)
  ).map((_, i) => {
    if (isGroup) {
      return getSnapOrRealName(
        record.service_delivery_record_practitioners[0][
          `practitioner${i > 0 ? i + 1 : ""}`
        ] || 0,
        "-"
      );
    }
    return getSnapOrRealName(
      record.service_delivery_record_practitioners[i].practitioner || 0,
      "-"
    );
  });

  const practitionerServiceTimes = Array.from(
    new Array(record.number_of_practitioner)
  ).map((_, i) => {
    if (isGroup || record.license_same_flg || (isVehicleTransport && i === 1)) {
      return createTimeInfo(
        record.service_delivery_record_practitioners[0].start_time,
        record.service_delivery_record_practitioners[0].end_time,
        record.service_delivery_record_practitioners[0].number_of_time,
        record.status
      );
    }
    return createTimeInfo(
      record.service_delivery_record_practitioners[i].start_time,
      record.service_delivery_record_practitioners[i].end_time,
      record.service_delivery_record_practitioners[i].number_of_time,
      record.status
    );
  });

  const customRecordsIDs = customRecords.map((item) => item.id);

  // 登録されている記録があるか確認
  let isRecordedPractitionerFirst = false;
  const serviceDeliveryRecordsPractitionerFirst =
    record.service_delivery_record_practitioners[0].service_delivery_records ||
    undefined;
  const serviceDeliveryRecordDestinationsPractitionerFirst =
    record.service_delivery_record_practitioners[0]
      .service_delivery_record_practitioner_destinations || undefined;
  if (
    serviceDeliveryRecordsPractitionerFirst &&
    serviceDeliveryRecordsPractitionerFirst.length > 0
  ) {
    isRecordedPractitionerFirst = !!serviceDeliveryRecordsPractitionerFirst.filter(
      (firstRecord) => {
        const input = firstRecord.input.filter(
          (item) => item.checked || item.input_data
        );
        return (
          customRecordsIDs.includes(firstRecord.custom_records_category_id) &&
          input.length > 0
        );
      }
    ).length;
  } else if (
    serviceDeliveryRecordDestinationsPractitionerFirst &&
    serviceDeliveryRecordDestinationsPractitionerFirst.length > 0
  ) {
    isRecordedPractitionerFirst = !!serviceDeliveryRecordDestinationsPractitionerFirst.filter(
      (x) => x.service_delivery_record_practitioner_destinations_id !== null
    );
  }

  let isRecordedPractitionerSecond = false;
  const serviceDeliveryRecordsPractitionerSecond =
    record.number_of_practitioner === 2
      ? record.service_delivery_record_practitioners[1].service_delivery_records
      : undefined;
  const serviceDeliveryRecordDestinationsPractitionerSecond =
    record.number_of_practitioner === 2
      ? record.service_delivery_record_practitioners[1]
          .service_delivery_record_practitioner_destinations
      : undefined;
  if (
    serviceDeliveryRecordsPractitionerSecond &&
    serviceDeliveryRecordsPractitionerSecond.length > 0
  ) {
    isRecordedPractitionerSecond = !!serviceDeliveryRecordsPractitionerSecond.filter(
      (secondRecord) => {
        const input = secondRecord.input.filter(
          (item) => item.checked || item.input_data
        );
        return (
          customRecordsIDs.includes(secondRecord.custom_records_category_id) &&
          input.length > 0
        );
      }
    ).length;
  } else if (
    serviceDeliveryRecordDestinationsPractitionerSecond &&
    serviceDeliveryRecordDestinationsPractitionerSecond.length > 0
  ) {
    isRecordedPractitionerSecond = !!serviceDeliveryRecordDestinationsPractitionerSecond.filter(
      (x) => x.service_delivery_record_practitioner_destinations_id !== null
    );
  }

  // サービス提供者１のサービス内容記録
  const tableBodyFirst = isRecordedPractitionerFirst
    ? createTableBody(
        1,
        record,
        classes,
        customRecords,
        serviceDeliveryRecordsPractitionerFirst,
        isStaffComment
      )
    : null;

  // サービス提供者2のサービス内容記録
  const tableBodySecond =
    record.number_of_practitioner > 1 &&
    !isGroup &&
    isRecordedPractitionerSecond
      ? createTableBody(
          2,
          record,
          classes,
          customRecords,
          serviceDeliveryRecordsPractitionerSecond,
          isStaffComment
        )
      : null;

  return (
    <div
      className={classes.page}
      key={`page-${record.service_delivery_records_id}`}
    >
      {/* 印刷タイトル */}
      <header key={`sheet-header-${record.service_delivery_records_id}`}>
        <h1 className={classes.title}>{title}</h1>
      </header>
      {/* 確認欄 */}
      <div className={classes.confirmationColumn}>
        <table className={classes.table}>
          <tbody>
            <tr>
              <td rowSpan={3} className="vertical">
                <span>確認欄</span>
              </td>
              <td className="titleLabel">利用者</td>
              <td className="titleLabel">
                サービス提供
                <br />
                責任者
              </td>
            </tr>
            <tr>
              <td className="titleContents" />
              <td className="titleContents" />
            </tr>
          </tbody>
        </table>
      </div>
      {/* 利用者情報 */}
      <div className={classes.flexContainer}>
        <div
          className={ClassNames(classes.entryFieldContainer, classes.userName)}
        >
          <span className={classes.entryFieldLabel}>利用者名</span>
          <div className={classes.entryFieldContentContainer}>
            <span>{`${record.name_sei} ${record.name_mei}`}</span>
          </div>
        </div>
        <div
          className={ClassNames(
            classes.entryFieldContainer,
            classes.serviceDate
          )}
        >
          <span className={classes.entryFieldLabel}>サービス日時</span>
          <div className={classes.entryFieldContentContainer}>
            <span>{`${serviceDate} ${serviceDateTime}`}</span>
          </div>
        </div>
      </div>
      <div className={classes.flexContainer}>
        <div
          className={ClassNames(classes.entryFieldContainer, classes.userName)}
        >
          <span className={classes.entryFieldLabel}>支援内容</span>
          <div className={classes.entryFieldContentContainer}>
            <span>移動支援</span>
          </div>
        </div>
        <div
          className={ClassNames(
            classes.entryFieldContainer,
            classes.serviceDate
          )}
        >
          <span className={classes.entryFieldLabel}>サービス内容</span>
          <div className={classes.entryFieldContentContainer}>
            <span>{`${statusLabel}${
              record.status === Number(IDOSHIEN_STATUS_LIST.OTHER.value) &&
              record.status_text
                ? `（${record.status_text}）`
                : ""
            }${record.physical_care_flg ? "　身体介護あり" : ""}`}</span>
          </div>
        </div>
      </div>

      {/* サービス提供者情報 */}
      <div className={classes.flexContainer}>
        <div
          className={ClassNames(
            classes.entryFieldContainer,
            classes.serviceDate
          )}
        >
          <span className={classes.entryFieldLabel}>
            {practitioner1NameLabel}
          </span>
          <div className={classes.entryFieldContentContainer}>
            {practitionerNames.length > 0 ? (
              <span>{`${practitionerNames[0]} ${practitionerServiceTimes[0]}`}</span>
            ) : null}
          </div>
        </div>
        {record.number_of_practitioner > 1 &&
          (isGroup ||
            !isRecordedPractitionerFirst ||
            !isRecordedPractitionerSecond) && (
            <div
              className={ClassNames(
                classes.entryFieldContainer,
                classes.serviceDate
              )}
            >
              <span className={classes.entryFieldLabel}>サービス提供者 2</span>
              <div className={classes.entryFieldContentContainer}>
                <span>{`${practitionerNames[1]} ${practitionerServiceTimes[1]}`}</span>
              </div>
            </div>
          )}
      </div>
      <div className={classes.flexContainer}>
        {record.number_of_practitioner > 2 && isGroup && (
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.serviceDate
            )}
          >
            <span className={classes.entryFieldLabel}>サービス提供者 3</span>
            <div className={classes.entryFieldContentContainer}>
              <span>{`${practitionerNames[2]} ${practitionerServiceTimes[2]}`}</span>
            </div>
          </div>
        )}
        {record.number_of_practitioner > 3 && isGroup && (
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.serviceDate
            )}
          >
            <span className={classes.entryFieldLabel}>サービス提供者 4</span>
            <div className={classes.entryFieldContentContainer}>
              <span>{`${practitionerNames[3]} ${practitionerServiceTimes[3]}`}</span>
            </div>
          </div>
        )}
      </div>
      {record.number_of_practitioner > 4 && isGroup && (
        <div
          className={ClassNames(
            classes.entryFieldContainer,
            classes.serviceDate
          )}
        >
          <span className={classes.entryFieldLabel}>サービス提供者 5</span>
          <div className={classes.entryFieldContentContainer}>
            <span>{`${practitionerNames[4]} ${practitionerServiceTimes[4]}`}</span>
          </div>
        </div>
      )}
      {isGroup && (
        <div className={classes.flexContainer}>
          <div
            className={ClassNames(
              classes.entryFieldContainer,
              classes.userName
            )}
          >
            <span className={classes.entryFieldLabel}>提供人数</span>
            <div className={classes.entryFieldContentContainer}>
              <span>{`${record.number_of_practitioner}人`}</span>
            </div>
          </div>
          {record.number_of_supporters !== null && (
            <>
              <div
                className={ClassNames(
                  classes.entryFieldContainer,
                  classes.userName
                )}
              >
                <span className={classes.entryFieldLabel}>支援人数</span>
                <div className={classes.entryFieldContentContainer}>
                  <span>{`${record.number_of_supporters}人`}</span>
                </div>
              </div>
              <div
                className={ClassNames(
                  classes.entryFieldContainer,
                  classes.userName
                )}
              >
                <span className={classes.entryFieldLabel}>
                  職員1人あたりの支援比率
                </span>
                <div className={classes.entryFieldContentContainer}>
                  <span>{`1:${record.support_ratio_per_practitioner}`}</span>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {/* サービス内容の詳細 */}
      <div style={{ marginTop: 16 }}>
        {tableBodyFirst}
        {record.number_of_practitioner > 1 &&
          !isGroup &&
          isRecordedPractitionerFirst &&
          isRecordedPractitionerSecond && (
            <>
              <div
                className={ClassNames(
                  classes.entryFieldContainer,
                  classes.serviceDate
                )}
              >
                <span className={classes.entryFieldLabel}>
                  サービス提供者 2
                </span>
                <div className={classes.entryFieldContentContainer}>
                  <span>{`${practitionerNames[1]} ${practitionerServiceTimes[1]}`}</span>
                </div>
              </div>
            </>
          )}
        {tableBodySecond}
      </div>
    </div>
  );
};

export const ServiceDeliveryPrint = withStyles(styles)(
  ServiceDeliveryPrintCore
);
