import dateToLocalisedString from "@utils/date/dateToLocalisedString";

/**
 *  dateオブジェクトをフォーマットに沿った文字列に変換する
 */
export const dateInHyphenYYYYMMDDFormat = (date: Date): string =>
  dateToLocalisedString(date, "YYYY-MM-DD");
export const dateInYYYYMMDDFormat = (date = new Date()): string =>
  dateToLocalisedString(date, "YYYYMMDD");
export const dateInYYYYMMFormat = (date: Date): string =>
  dateToLocalisedString(date, "YYYYMM");
export const dateInYYYYFormat = (date: Date): string =>
  dateToLocalisedString(date, "YYYY");
export const dateInMMFormat = (date: Date): string =>
  dateToLocalisedString(date, "MM");
export const dateInMFormat = (date: Date): string =>
  dateToLocalisedString(date, "M");
