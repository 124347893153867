import React from "react";
import { RouteComponentProps } from "react-router-dom";
import AdminTemplate from "@components/templates/AdminTemplate";
import PrintPreviewTemplate from "@components/templates/PrintPreviewTemplate";
import { DailyPrintCsegRecords } from "@components/organisms/record/print/DailyPrintCsegRecords";

type Props = RouteComponentProps<{ yyyymmdd: string }>;

/**
 * 日々の記録（計画相談・地域移行・地域定着）のプリント画面
 */
const DailyPrintCsegRecordsCore = (props: Props): JSX.Element => {
  const targetDate = props.match.params.yyyymmdd;
  const query = props.location.search;

  return (
    <AdminTemplate pageName="日々の記録">
      <PrintPreviewTemplate
        history={props.history}
        location={props.location}
        match={props.match}
      >
        <DailyPrintCsegRecords selectedDate={targetDate} query={query} />
      </PrintPreviewTemplate>
    </AdminTemplate>
  );
};

export const DailyRecordCsegRecords = DailyPrintCsegRecordsCore;
