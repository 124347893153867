import * as React from "react";
import { connect } from "react-redux";
import {
  createStyles,
  WithStyles,
  Theme,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";

import { InOutReportUserHeader } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportMonthlyHeader";
import { InOutReportTable } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportTable";
import InvoiceErrorBar from "@components/organisms/mgr/InvoiceErrorBar";
import { ErrorsState } from "@stores/domain/errors/types";
import { UserState } from "@stores/domain/user/type";
import { InOutReportPaperHeader } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportPaperHeader";
import CopyConfirmDialog from "@components/organisms/mgr/IDOSHIEN/report/dialog/CopyConfirmDialog";
import { InOutReportDialog } from "@components/organisms/mgr/IDOSHIEN/report/InOutReportDialog";
import * as errorsDialogActions from "@stores/ui/errorsDialog/actions";
import { SHOW_ERRORS_DIALOG } from "@stores/ui/errorsDialog/types";
import {
  REPEAT_MONTHLY,
  ReportState,
  ReportSummary,
  InoutResultsState,
  DeleteReportState,
  CopyReportState,
  CopyReportErrorState,
  MunicipalityData
} from "@stores/domain/mgr/IDOSHIEN/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import { Dispatch } from "redux";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { OptionInterface } from "@components/atoms/DropDown";
import {
  PROCESS_TYPE,
  INOUT_RESULTS_COPY_ERROR_STATUS,
  IDOSHIEN_INPUT_CLASS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import { ResponseError } from "@stores/ui/type";
import {
  InitialDataValues,
  initialValues
} from "@initialize/mgr/IDOSHIEN/report/initialValues";
import convertBlankSeparatorFormatToDate from "@utils/date/convertBlankSeparatorFormatToDate";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";

const styles = ({ spacing, palette }: Theme): StyleRules =>
  createStyles({
    clear: {
      clear: "both"
    },
    headerWrapper: {
      position: "sticky",
      top: 0,
      backgroundColor: palette.background.default,
      zIndex: 1
    },
    headerInfoContainer: {
      width: "100%",
      paddingRight: 16,
      paddingLeft: 16,
      marginTop: 16,
      marginBottom: 8
    },
    tableContainer: {
      padding: `${spacing.unit * 2}px ${spacing.unit * 4}px ${
        spacing.unit * 4
      }px`,
      margin: `0px ${spacing.unit * 2}px ${spacing.unit * 2}px ${
        spacing.unit * 2
      }px`
    },
    button: {
      marginLeft: 10,
      border: "1px solid #cccccc",
      boxShadow: "none",
      borderRadius: 4
    },
    abolitionChip: {
      paddingLeft: 20,
      width: 160
    },
    flex: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between"
    },
    bodyRestraint: {
      paddingBottom: spacing.unit * 2
    }
  });

type OwnProps = {
  initialDate: Date;
};

type StateProps = {
  user: UserState;
  reportState: ReportState;
  usersInFacilityState: UsersInFacilityState;
  municipality: MunicipalitiesInFacilityState["municipality"];
  inoutErrors: ErrorsState["inout"];
  responseError: ResponseError;
};

type DispatchProps = {
  fetchUser: () => Promise<void>;
  fetchReportUser: (uifId: number, date: Date) => void;
  fetchInoutError: (date: Date) => void;
  fetchUserInFacility: (uifId: string) => Promise<void>;
  fetchMunicipality: (municipalityId: string) => Promise<void>;
  clearMunicipality: () => Promise<void>;
  openErrorsDialog: () => void;
  deleteInoutResults: (target: DeleteReportState) => Promise<void>;
  copyInoutResults: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  copyServiceDelivery: (
    processType: number,
    target: CopyReportState[]
  ) => Promise<void>;
  responseErrorClear(): void;
};

type Props = OwnProps & StateProps & DispatchProps & WithStyles<typeof styles>;

type State = {
  selectedMonth: Date;
  selectedUser: OptionInterface;
  headerHeight: number;
  isOpenDetailModal: boolean;
  data: InitialDataValues;
  openedModalApiInoutResultData: InoutResultsState | null;
  selectedDate: Date;
  modalTarget: string;
  municipality: MunicipalityData;
  municipalityId: number | null;
  isOpenDeleteDialog: boolean;
  deleteTarget: DeleteReportState | null;
  isOpenCopyConfirmDialog: boolean;
  copyReportListTarget: CopyReportState[] | null;
  isOpenCopyServiceDeliveryConfirmDialog: boolean;
  copyServiceDeliveryTarget: CopyReportState[] | null;
};

type RectangleBoxProps = React.ComponentProps<
  typeof InOutReportPaperHeader
>["rectangleConfigList"][number];

const currentDateForMonthly = new Date();
// 日付の最大値の設定 (30年後の12月31日)
const maxDate = new Date(currentDateForMonthly.getFullYear() + 30, 11, 31);
const minDate = new Date(2021, 3, 1);

/**
 * 利用実績(ユーザごと）
 */
class InOutReportMonthlyCore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedMonth: this.props.initialDate,
      selectedUser: { label: "", value: "" },
      headerHeight: 0,
      isOpenDetailModal: false,
      data: initialValues(null),
      openedModalApiInoutResultData: null,
      selectedDate: this.props.initialDate,
      modalTarget: IDOSHIEN_INPUT_CLASS_LIST.CREATE.label,
      municipality: {
        calculationTimeFlg: this.props.municipality.calculation_time_flg === 1,
        roundUpMinute:
          this.props.municipality.calculation_time_flg === 1
            ? this.props.municipality.round_up_minute
            : null,
        timeDivisionFlg: this.props.municipality.time_division_flg === 1
      },
      municipalityId: null,
      isOpenDeleteDialog: false,
      deleteTarget: null,
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null,
      isOpenCopyServiceDeliveryConfirmDialog: false,
      copyServiceDeliveryTarget: null
    };
  }

  public componentDidMount(): void {
    Promise.all([this.props.fetchInoutError(this.state.selectedMonth)]);
  }

  public componentDidUpdate(): void {
    const top = document.getElementById("reportDailyHeader");
    if (top && top.clientHeight !== this.state.headerHeight) {
      const setClientHeight = (): void => {
        this.setState({
          headerHeight: top.clientHeight
        });
      };
      setClientHeight();
    }
  }

  private onChangeMonth = (date: Date, user: OptionInterface): void => {
    this.setState({ selectedMonth: date, selectedUser: user });
    this.props.fetchReportUser(+user.value, date);
    this.props.fetchInoutError(date);
    if (user.value !== "") {
      this.props.fetchUserInFacility(user.value.toString());
    }
  };

  private onChangeUser = (user: OptionInterface): void => {
    this.setState({ selectedUser: user });
    this.props.fetchReportUser(+user.value, this.state.selectedMonth);
    this.props.fetchUserInFacility(user.value.toString());
    this.props.fetchInoutError(this.state.selectedMonth);
  };

  private onClickErrorDialog = (): void => {
    this.props.openErrorsDialog();
  };

  private openModal = async (
    param: InoutResultsState,
    inputClass: string
  ): Promise<void> => {
    await this.props.fetchUserInFacility(param.usersInFacilityId.toString());
    const userInFacility = this.props.usersInFacilityState.user
      .user_in_facility_idoshien;
    // 編集モーダルを開くが既存データが無い場合には利用者の設定してる情報を使う
    if (param.id === null) {
      if (userInFacility && userInFacility.municipality_id) {
        await this.props.fetchMunicipality(
          String(userInFacility.municipality_id)
        );
      } else {
        await this.props.clearMunicipality();
      }
    }
    // 自治体情報を設定
    const municipality =
      param.id === null
        ? {
            calculationTimeFlg:
              this.props.municipality.calculation_time_flg === 1,
            roundUpMinute:
              this.props.municipality.calculation_time_flg === 1
                ? this.props.municipality.round_up_minute
                : null,
            timeDivisionFlg: this.props.municipality.time_division_flg === 1
          }
        : param.municipality;

    this.setState({
      isOpenDetailModal: true,
      data: initialValues(param),
      openedModalApiInoutResultData: param,
      selectedDate: convertBlankSeparatorFormatToDate(param.targetDate),
      municipality
    });
    if (inputClass === IDOSHIEN_INPUT_CLASS_LIST.PLAN.value) {
      this.setState({ modalTarget: IDOSHIEN_INPUT_CLASS_LIST.PLAN.label });
    } else {
      this.setState({
        modalTarget: IDOSHIEN_INPUT_CLASS_LIST.RESULT.label,
        municipalityId:
          userInFacility && userInFacility.municipality_id
            ? userInFacility.municipality_id
            : null
      });
    }
  };

  private openCreateModal = async (date: string): Promise<void> => {
    await this.props.fetchUserInFacility(
      this.state.selectedUser.value.toString()
    );
    this.setState({ isOpenDetailModal: true });
    // ユーザーに設定されている自治体情報を取得する
    const userInFacility = this.props.usersInFacilityState.user
      .user_in_facility_idoshien;
    if (userInFacility && userInFacility.municipality_id) {
      await this.props.fetchMunicipality(
        String(userInFacility.municipality_id)
      );
    } else {
      await this.props.clearMunicipality();
    }

    // 新規作成時は、日付情報と氏名を親から取得してセットする必要がある
    const init = initialValues(null);
    init.initial.targetDate = date;
    init.initial.name = this.state.selectedUser.label;
    init.initial.usersInFacilityId = this.state.selectedUser.value.toString();

    this.setState({
      data: init,
      selectedDate: convertBlankSeparatorFormatToDate(date),
      modalTarget: IDOSHIEN_INPUT_CLASS_LIST.CREATE.label,
      municipality: {
        calculationTimeFlg: this.props.municipality.calculation_time_flg === 1,
        roundUpMinute:
          this.props.municipality.calculation_time_flg === 1
            ? this.props.municipality.round_up_minute
            : null,
        timeDivisionFlg: this.props.municipality.time_division_flg === 1
      },
      municipalityId:
        userInFacility && userInFacility.municipality_id
          ? userInFacility.municipality_id
          : null
    });
  };

  private onCancel = (): void => {
    this.setState({ isOpenDetailModal: false, data: initialValues(null) });
  };

  private onSubmit = (): void => {
    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);
  };

  private onClickDeleteIcon = (target: DeleteReportState): void => {
    this.setState({ deleteTarget: target, isOpenDeleteDialog: true });
  };

  private onDelete = async (): Promise<void> => {
    if (this.state.deleteTarget !== null) {
      await this.props.deleteInoutResults(this.state.deleteTarget);
      this.setState({ deleteTarget: null, isOpenDeleteDialog: false });
    }

    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);
  };

  private onCancelDelete = (): void => {
    this.setState({
      deleteTarget: null,
      isOpenDeleteDialog: false
    });
  };

  private onClickCopyServiceDeliveryIcon = async (): Promise<void> => {
    const copyList = this.props.reportState.reportMonthly.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyServiceDelivery(PROCESS_TYPE.ALL_ERROR, copyList);
    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyServiceDeliveryTarget: copyList,
        isOpenCopyServiceDeliveryConfirmDialog: true
      });
    } else {
      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
    }
  };

  private onCopyServiceDelivery = async (): Promise<void> => {
    if (this.state.copyServiceDeliveryTarget !== null) {
      await this.props.copyServiceDelivery(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyServiceDeliveryTarget
      );

      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
    }

    this.setState({
      isOpenCopyServiceDeliveryConfirmDialog: false,
      copyServiceDeliveryTarget: null
    });
  };

  private onClickCopyReportListIcon = async (): Promise<void> => {
    const copyList = this.props.reportState.reportMonthly.displayList.map(
      (record) => {
        return {
          uifId: record.usersInFacilityId,
          targetDate: record.targetDate
        };
      }
    );
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, copyList);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
      this.setState({
        copyReportListTarget: copyList,
        isOpenCopyConfirmDialog: true
      });
    } else {
      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
    }
  };

  private onCopyReportList = async (): Promise<void> => {
    if (this.state.copyReportListTarget !== null) {
      await this.props.copyInoutResults(
        PROCESS_TYPE.NEW_ONLY,
        this.state.copyReportListTarget
      );

      this.props.fetchReportUser(
        +this.state.selectedUser.value,
        this.state.selectedMonth
      );
      this.props.fetchInoutError(this.state.selectedMonth);
    }

    this.setState({
      isOpenCopyConfirmDialog: false,
      copyReportListTarget: null
    });
  };

  private onCopyRecord = async (target: CopyReportState): Promise<void> => {
    await this.props.copyInoutResults(PROCESS_TYPE.ALL_ERROR, [target]);
    this.props.fetchReportUser(
      +this.state.selectedUser.value,
      this.state.selectedMonth
    );
    this.props.fetchInoutError(this.state.selectedMonth);

    const error = { ...this.props.responseError };
    const data = error ? (error.data as CopyReportErrorState) : null;
    if (
      data &&
      "response" in data &&
      "code" in data.response &&
      data.response.code === INOUT_RESULTS_COPY_ERROR_STATUS
    ) {
      this.props.responseErrorClear();
    }
  };

  private onCloseCopyDialog = (): void => {
    this.setState({
      isOpenCopyConfirmDialog: false,
      isOpenCopyServiceDeliveryConfirmDialog: false
    });
  };

  /**
   * RectangleBoxが受け付けるconfigを生成する
   */
  private getRectangleBoxConfig = (
    title: string,
    // 時間
    times: number | null | undefined,
    // 回
    months: number | null | undefined,
    timesDenom?: number,
    monthsDenom?: number
  ): RectangleBoxProps | undefined => {
    const main = timesDenom
      ? {
          denom: timesDenom,
          num: times || 0,
          unit: "時間"
        }
      : undefined;

    const sub = monthsDenom
      ? {
          denom: monthsDenom,
          num: months || 0,
          unit: "回"
        }
      : undefined;

    if (main === undefined && sub === undefined) {
      return undefined;
    }

    return {
      title,
      main,
      sub
    };
  };

  private createRectangleList = (
    summary: ReportSummary,
    usersInFacilityState: UsersInFacilityState
  ): RectangleBoxProps[] => {
    const userInFacility = usersInFacilityState.user.user_in_facility_idoshien;
    const list: (RectangleBoxProps | undefined)[] = [
      this.getRectangleBoxConfig(
        "個別支援",
        summary.payTimesIndividualSupport,
        summary.payMonthsIndividualSupport,
        userInFacility && userInFacility.pay_times_individual_support
          ? userInFacility.pay_times_individual_support
          : undefined,
        userInFacility && userInFacility.pay_months_individual_support
          ? userInFacility.pay_months_individual_support
          : undefined
      ),
      this.getRectangleBoxConfig(
        "グループ支援",
        summary.payTimesGroupSupport,
        summary.payMonthsGroupSupport,
        userInFacility && userInFacility.pay_times_group_support
          ? userInFacility.pay_times_group_support
          : undefined,
        userInFacility && userInFacility.pay_months_group_support
          ? userInFacility.pay_months_group_support
          : undefined
      ),
      this.getRectangleBoxConfig(
        "車両移送支援",
        summary.payTimesVehicleTransferSupport,
        summary.payMonthsVehicleTransferSupport,
        userInFacility && userInFacility.pay_times_vehicle_transfer_support
          ? userInFacility.pay_times_vehicle_transfer_support
          : undefined,
        userInFacility && userInFacility.pay_months_vehicle_transfer_support
          ? userInFacility.pay_months_vehicle_transfer_support
          : undefined
      ),
      this.getRectangleBoxConfig(
        "通学・通所支援",
        summary.payTimesSchoolAndHospitalCommutingSupport,
        summary.payMonthsSchoolAndHospitalCommutingSupport,
        userInFacility &&
          userInFacility.pay_times_school_and_hospital_commuting_support
          ? userInFacility.pay_times_school_and_hospital_commuting_support
          : undefined,
        userInFacility &&
          userInFacility.pay_months_school_and_hospital_commuting_support
          ? userInFacility.pay_months_school_and_hospital_commuting_support
          : undefined
      ),
      this.getRectangleBoxConfig(
        "その他",
        summary.payTimesOtherSupport,
        summary.payMonthsOtherSupport,
        userInFacility && userInFacility.pay_times_other_support
          ? userInFacility.pay_times_other_support
          : undefined,
        userInFacility && userInFacility.pay_months_other_support
          ? userInFacility.pay_months_other_support
          : undefined
      )
    ];
    return list.filter((v): v is RectangleBoxProps => v !== undefined);
  };

  public render(): JSX.Element {
    const { classes, inoutErrors } = this.props;
    const { selectedMonth, selectedUser, headerHeight } = this.state;
    return (
      <>
        <div id="reportDailyHeader" className={classes.headerWrapper}>
          {inoutErrors.hasError && (
            <InvoiceErrorBar
              message={`${inoutErrors.errorCount} 件のエラーが起きています。内容を確認し、データを修正してください。`}
              onClick={this.onClickErrorDialog}
            />
          )}
          <div className={classes.headerInfoContainer}>
            <InOutReportUserHeader
              minDate={minDate}
              maxDate={maxDate}
              selectedMonth={selectedMonth}
              selectedUserId={selectedUser.value}
              onChangeMonth={this.onChangeMonth}
              onChangeUser={this.onChangeUser}
            />
          </div>
        </div>
        <Paper elevation={0} className={classes.tableContainer}>
          <InOutReportPaperHeader
            rectangleConfigList={this.createRectangleList(
              this.props.reportState.reportMonthly.summary,
              this.props.usersInFacilityState
            )}
            type={REPEAT_MONTHLY}
            onClickCopyReportListIcon={this.onClickCopyReportListIcon}
            onClickCopyServiceDeliveryIcon={this.onClickCopyServiceDeliveryIcon}
            disabledCopyServiceDelivery={
              this.props.reportState.reportMonthly.numberOfRecords === 0
            }
            disabledCopy={this.props.reportState.reportMonthly.planCount === 0}
            groupOperationSupportFlg={
              this.props.user.featureGroup.group_operation_support === 1
            }
          />
          <InOutReportTable
            headerHeight={headerHeight}
            openModal={this.openModal}
            openCreateModal={this.openCreateModal}
            onClickDeleteIcon={this.onClickDeleteIcon}
            onCopyRecord={this.onCopyRecord}
            type={REPEAT_MONTHLY}
            filterFlg={false}
          />
        </Paper>
        <InOutReportDialog
          open={this.state.isOpenDetailModal}
          staffList={
            this.props.reportState.reportMonthly.displayStaffsInFacility
          }
          usersInFacilityState={this.props.usersInFacilityState}
          municipality={this.state.municipality}
          municipalityId={this.state.municipalityId}
          data={this.state.data}
          apiData={this.state.openedModalApiInoutResultData}
          selectedDate={this.state.selectedDate}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          type={REPEAT_MONTHLY}
          modalTarget={this.state.modalTarget}
        />
        <ConfirmDialog
          isOpen={this.state.isOpenDeleteDialog}
          onDelete={this.onDelete}
          onCancel={this.onCancelDelete}
          dialogWidth={600}
          title={`該当の${
            this.state.deleteTarget && this.state.deleteTarget.inputClass === 1
              ? "移動支援計画"
              : "サービス提供実績"
          }を削除します`}
          message={
            <span>
              削除した場合、データの復元はできません。
              <br />
              よろしいですか？
            </span>
          }
        />
        <CopyConfirmDialog
          isOpen={this.state.isOpenCopyServiceDeliveryConfirmDialog}
          onCopy={this.onCopyServiceDelivery}
          onCancel={this.onCloseCopyDialog}
          title="サービス提供記録からサービス提供実績へ一括コピー"
          message="開始時間と終了時間が入力されていない、または当月にすでに実績が登録されているサービス提供記録はコピーされません。コピー可能なサービス提供記録を実績へコピーしますか？"
        />
        <CopyConfirmDialog
          isOpen={this.state.isOpenCopyConfirmDialog}
          onCopy={this.onCopyReportList}
          onCancel={this.onCloseCopyDialog}
          title="移動支援計画からサービス提供実績へ一括コピー"
          message="当月にすでに実績が登録されているデータが存在します。登録されていない日のみ計画をコピーしますか？"
        />
      </>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    user: state.user as UserState,
    reportState: state.IDOSHIEN.report,
    usersInFacilityState: state.IDOSHIEN.userInFacility,
    municipality: state.IDOSHIEN.municipalitiesInFacility.municipality,
    inoutErrors: state.errors.inout,
    responseError: state.ui.responseError
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { userDispatch, IDOSHIEN, errorsDispatcher, uiDispatch } = dispatches;
  const reportDispatcher = IDOSHIEN.reportDispatcher(dispatch);
  const municipalityDispatcher = IDOSHIEN.municipalitiesInFacilityDispatcher(
    dispatch
  );
  const uifDispatcher = IDOSHIEN.userInFacilityDispatcher(dispatch);
  return {
    fetchUser: userDispatch(dispatch).me,
    fetchReportUser: (uifId: number, date: Date): Promise<void> =>
      reportDispatcher.fetchIDOSHIENUsers(uifId, date),
    fetchInoutError: errorsDispatcher(dispatch).inout,
    fetchUserInFacility: (uifId: string): Promise<void> =>
      uifDispatcher.fetchOne(uifId),
    fetchMunicipality: (municipalityId: string): Promise<void> =>
      municipalityDispatcher.fetchOne(municipalityId),
    clearMunicipality: (): Promise<void> => municipalityDispatcher.clearOne(),
    openErrorsDialog: (): {
      type: typeof SHOW_ERRORS_DIALOG;
    } => dispatch(errorsDialogActions.showErrorsDialog()),
    deleteInoutResults: (target: DeleteReportState): Promise<void> =>
      reportDispatcher.deleteIDOSHIENReport(target),
    copyInoutResults: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postIDOSHIENReportCopy(processType, target),
    copyServiceDelivery: (
      processType: number,
      target: CopyReportState[]
    ): Promise<void> =>
      reportDispatcher.postIDOSHIENReportCopyServiceDelivery(
        processType,
        target
      ),
    responseErrorClear: uiDispatch(dispatch).responseErrorClear
  };
};

export const InOutReportMonthly = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(InOutReportMonthlyCore));
