import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import DialogActions from "@material-ui/core/DialogActions";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Table, { CellParam as HeadCellParam } from "@components/molecules/Table";
import TableHead from "@components/molecules/TableHead";
import { PlanMonitoringMeetingState } from "@stores/domain/mgr/KEIKAKUSODAN/planMonitoringMeeting/types";
import { SECONDARY_LINE_COLOR } from "@/constants/styles";
import MuiSelect from "@components/molecules/MuiSelect";
import { getWarekiList } from "@utils/date";
import dateToLocalisedString from "@utils/date/dateToLocalisedString";
import {
  DEFAULT_SELECT_VALUE,
  DEFAULT_DROP_DOWN_OPTION
} from "@constants/variables";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import {
  CONSULTATION_TARGET_FRG,
  FACILITY_TYPE_KEIKAKUSODAN,
  FACILITY_TYPE_SHOGAIJISODAN,
  FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN,
  TYPE_CONSULTATION_KEIKAKUSODAN,
  TYPE_CONSULTATION_SHOGAIJISODAN,
  TARGET_FLG_TYPE_KEIKAKU,
  TARGET_FLG_TYPE_SHOGAIJI
} from "@constants/mgr/KEIKAKUSODAN/variables";

const styles = (): StyleRules =>
  createStyles({
    dialog: {
      "&>div:nth-child(2)>div": {
        maxWidth: "600px",
        width: "100%"
      }
    },
    dialogTitle: {
      padding: "16px 32px",
      background: "#f5f5f5",
      "&>h6": {
        fontSize: "20px",
        lineHeight: 1,
        color: "#37474f",
        letterSpacing: " 0.25px"
      }
    },
    dialogContent: {
      padding: "24px 0px 50px"
    },
    dialogActions: {
      margin: 0,
      padding: "8px 32px",
      borderTop: "1px solid #cfd8dc"
    },
    selectWrapper: {
      paddingLeft: "32px"
    },
    noDateMessage: {
      paddingTop: "48px",
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      textAlign: "center",
      fontSize: "16px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    labelButton: {
      width: "50px"
    },
    labelDate: {
      fontSize: 14,
      paddingLeft: "16px",
      boxSizing: "content-box"
    },
    table: {
      width: "100%",
      minWidth: 540
    },
    row: {
      borderBottom: `1px dotted ${SECONDARY_LINE_COLOR}`,
      "&:nth-of-type(even)": {
        backgroundColor: "#eceff1"
      }
    },
    radioButtonCellStyle: {
      width: "50px",
      padding: "0px",
      textAlign: "right",
      backgroundColor: "#fff",
      borderBottom: "none"
    },
    dateCell: {
      width: 139,
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      borderBottom: "none",
      fontSize: "16px",
      boxSizing: "content-box",
      backgroundColor: "#fff"
    },
    cellUsers: {
      width: "auto",
      height: 24,
      color: "rgba(0, 0, 0, 0.87)",
      padding: "0 0 0 16px",
      fontSize: "16px",
      borderBottom: "none"
    }
  });

type OwnProps = {
  isOpen: boolean;
  title: string;
  onClickSubmit: (id: string, isSchedule?: boolean) => Promise<void>;
  onClose: () => void;
  planMonitoringMeeting: PlanMonitoringMeetingState["planMonitoringMeeting"];
  isUpdate: boolean;
  isSchedule?: boolean;
  // 読み込み対象とするflagのリスト
  targetFlagList?: number[];
  facilityType: number | null;
} & WithStyles<typeof styles>;
type Props = OwnProps;

type Reports = {
  id: number;
  targetFlag: number;
  date: string;
  isHoliday: number;
  typeConsultation: number;
}[];

const createTargetReports = (
  plan: PlanMonitoringMeetingState["planMonitoringMeeting"]["yearly"][0],
  targetFlagList: number[],
  facilityType?: number | null
): Reports => {
  const reports: Reports = [];
  plan.monthly.forEach((month) => {
    if (month.daily) {
      month.daily.forEach((day) => {
        day.reports.forEach((report) => {
          if (targetFlagList.includes(report.target_flg)) {
            // 施設が計画相談(1)の場合、相談種別が計画相談(1)のみを取り出す
            if (
              facilityType === FACILITY_TYPE_KEIKAKUSODAN &&
              report.type_consultation === TYPE_CONSULTATION_KEIKAKUSODAN
            ) {
              const dayReport = {
                id: report.id,
                targetFlag: report.target_flg,
                date: day.creation_date,
                isHoliday: day.is_holiday,
                typeConsultation: report.type_consultation
              };
              reports.push(dayReport);
              // 施設が障害児相談(2)の場合、相談種別が障害児相談(2)のみを取り出す
            } else if (
              facilityType === FACILITY_TYPE_SHOGAIJISODAN &&
              report.type_consultation === TYPE_CONSULTATION_SHOGAIJISODAN
            ) {
              const dayReport = {
                id: report.id,
                targetFlag: report.target_flg,
                date: day.creation_date,
                isHoliday: day.is_holiday,
                typeConsultation: report.type_consultation
              };
              reports.push(dayReport);
              // 施設が計画相談・障害児相談(3)の場合
            } else if (
              facilityType === FACILITY_TYPE_KEIKAKUSODAN_SHOGAIJISODAN
            ) {
              const dayReport = {
                id: report.id,
                targetFlag: report.target_flg,
                date: day.creation_date,
                isHoliday: day.is_holiday,
                // 計画相談の場合はreport.type_consultation が undefined のことはないので
                // 型エラー対応で TYPE_CONSULTATION_KEIKAKUSODAN を設定している
                typeConsultation:
                  report.type_consultation || TYPE_CONSULTATION_KEIKAKUSODAN
              };
              reports.push(dayReport);
            }
          }
        });
      });
    }
  });
  return reports.sort((a, b) => {
    return a.date < b.date ? 1 : -1;
  });
};

const LoadPlanModalCore = (props: Props): JSX.Element => {
  const {
    classes,
    isOpen,
    title,
    onClickSubmit,
    onClose,
    planMonitoringMeeting,
    isSchedule,
    targetFlagList = [
      CONSULTATION_TARGET_FRG.CONSULTATION_PLAN,
      CONSULTATION_TARGET_FRG.CONSULTATION,
      CONSULTATION_TARGET_FRG.CONSULTATION_CONTINUATION
    ],
    facilityType
  } = props;
  const currentDate = dateToLocalisedString(new Date(), "YYYY");
  const [targetYear, setTargetYear] = React.useState(DEFAULT_SELECT_VALUE);
  const [reports, setReports] = React.useState<Reports>([]);
  const [reportId, setReportId] = React.useState("");
  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false);

  // 値リセット
  React.useEffect(() => {
    if (!isOpen) {
      setTargetYear(DEFAULT_SELECT_VALUE);
      setReports([]);
      setReportId("");
    }
  }, [isOpen]);

  // 対象年変更時
  React.useEffect(() => {
    const targetYearlyReport = planMonitoringMeeting.yearly.find(
      (plan) => plan.year === Number(targetYear)
    );
    if (targetYearlyReport) {
      const targetReports = createTargetReports(
        targetYearlyReport,
        targetFlagList,
        facilityType
      );
      if (targetReports.length > 0) {
        setReports(targetReports);
        setReportId(String(targetReports[0].id));
      } else {
        setReports([]);
      }
    } else {
      setReports([]);
    }
  }, [targetYear]);

  // ラジオボタン押下
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setReportId(e.target.value);
  };

  // 読み込みボタン押下
  const onLoad = (): void => {
    if (props.isUpdate) {
      setIsOpenUpdateModal(true);
    } else {
      onClickSubmit(reportId, isSchedule);
    }
  };

  // 上書き確認モーダル確定ボタン押下
  const onClickUpdate = (): void => {
    onClickSubmit(reportId, isSchedule);
    setIsOpenUpdateModal(false);
  };

  // 上書き確認モーダルキャンセルボタン押下
  const onCloseDialog = (): void => {
    setIsOpenUpdateModal(false);
  };

  // 対象年の選択肢
  const yearOptions = [
    DEFAULT_DROP_DOWN_OPTION,
    ...getWarekiList(1989, Number(currentDate) + 1).map(({ label, value }) => ({
      label,
      value: `${value}`
    }))
  ];

  const headerData = (): HeadCellParam[] => {
    return [
      {
        className: classes.labelButton,
        align: "left",
        label: ""
      },
      {
        className: classes.labelDate,
        align: "left",
        label: "作成日"
      }
    ];
  };

  const BodyData = (): JSX.Element => {
    return (
      <TableBody>
        {reports.map((report) => {
          let cellTitle = "";
          if (report.typeConsultation === TYPE_CONSULTATION_SHOGAIJISODAN) {
            cellTitle = TARGET_FLG_TYPE_SHOGAIJI[report.targetFlag];
          } else {
            cellTitle = TARGET_FLG_TYPE_KEIKAKU[report.targetFlag];
          }
          return (
            <TableRow
              className={classes.row}
              role="checkbox"
              aria-checked
              tabIndex={-1}
              key={report.id}
            >
              <TableCell align="left" className={classes.radioButtonCellStyle}>
                <Radio
                  value={String(report.id)}
                  color="secondary"
                  checked={reportId === String(report.id)}
                  onChange={handleChange}
                  style={{ padding: "0px" }}
                />
              </TableCell>
              <TableCell align="left" className={classes.dateCell}>
                <CustomDateLabel
                  date={report.date}
                  dateFormat="M月D日 （dd）"
                  holiday={!!report.isHoliday}
                />
              </TableCell>
              <TableCell align="left" className={classes.cellUsers}>
                {cellTitle}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  const noDateMessage =
    targetYear === DEFAULT_SELECT_VALUE
      ? "読み込み元の対象年を選択してください"
      : "作成済みの計画がありません";

  return (
    <Dialog className={classes.dialog} open={isOpen}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.selectWrapper}>
          <MuiSelect
            name="targetYear"
            label="対象年"
            size="medium"
            value={targetYear}
            options={yearOptions}
            onChange={(e): void => {
              setTargetYear(e.target.value);
            }}
            style={{ marginBottom: "24px" }}
          />
        </div>
        {targetYear === DEFAULT_SELECT_VALUE || reports.length === 0 ? (
          <div className={classes.noDateMessage}>{noDateMessage}</div>
        ) : (
          <>
            <div>
              <Table
                className={classes.table}
                key="invoice-excluded-users-table-head"
              >
                <TableHead
                  role="checkbox"
                  ariaChecked
                  tabIndex={-1}
                  key={1}
                  selected
                  items={headerData()}
                  rowStyle={{ height: 32 }}
                />
              </Table>
            </div>
            <div className={classes.scroll}>
              <Table
                key="invoice-excluded-users-table"
                className={classes.table}
              >
                <BodyData />
              </Table>
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <KnowbeButton kind="outline" onClick={onClose}>
          キャンセル
        </KnowbeButton>
        <KnowbeButton
          kind="default"
          onClick={onLoad}
          disabled={reports.length === 0}
        >
          読み込む
        </KnowbeButton>
      </DialogActions>
      <ConfirmDialog
        isOpen={isOpenUpdateModal}
        onDelete={onClickUpdate}
        onCancel={onCloseDialog}
        title="入力済みの計画は上書きされます"
        message={
          <>
            上書きされる前のデータは復元できません。
            <br />
            よろしいですか？
          </>
        }
        submitLabel="確定する"
        dialogWidth={600}
      />
    </Dialog>
  );
};

export const LoadPlanModal = withStyles(styles)(LoadPlanModalCore);
