import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";
import { BaseResponse } from "@api/types";

export type GetMonitoringReport = BaseResponse<{
  id: number;
  created_at: string;
  updated_at: string;
  guardian_name: string | null;
  guardian_relation: string | null;
  date_of_monitoring: string;
  creation_date: string | null;
  author: {
    id: number;
    name: string;
    role: string;
    license: number;
    license_name: string;
    facility_id: number;
    snapshot_name: string;
    snapshot_role: string;
  } | null;
  classify_disability_support: number | null;
  income_kind: string | null;
  income_category: number | null;
  disabled_welfare_service_recipient_number: string | null;
  consultation_support_recipient_number: string | null;
  regular_place_recipient_number: string | null;
  support_policy: string | null;
  whole_situation: string | null;
  staff_comment: string | null;
  type_consultation: number;
  details: {
    id: number;
    support_goal: string | null;
    achievement_time: string | null;
    service_provision_status: string | null;
    personal_satisfaction: string | null;
    achievement_support_goals: string | null;
    future_issues: string | null;
    change_plan_service_type: number | null;
    change_plan_service_volume: number | null;
    change_plan_weekly: number | null;
    other_considerations: string | null;
  }[];
}>;

/**
 * モニタリング報告書 のデータ取得
 * @param uifId 事業所所属ユーザーのID
 * @param monitoringReportId モニタリング報告書 のID
 */
export const getMonitoringReport = async (
  uifId: string,
  monitoringReportId: string
): Promise<AxiosResponse<GetMonitoringReport>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/monitoring_report/${monitoringReportId}`;
  return request.get<GetMonitoringReport>(url);
};
