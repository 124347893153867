import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCarePlanStarted = () =>
  ({ type: types.FETCH_KYOTAKUKAIGO_CARE_PLAN_STARTED } as const);
export const fetchCarePlan = (res: types.CarePlanStaffState) =>
  ({ type: types.FETCH_KYOTAKUKAIGO_CARE_PLAN, payload: res } as const);
export const fetchCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_KYOTAKUKAIGO_CARE_PLAN_FAILED, error: err } as const);

export const postKYOTAKUKAIGOCarePlanStarted = () =>
  ({ type: types.POST_KYOTAKUKAIGO_CARE_PLAN_STARTED } as const);
export const postKYOTAKUKAIGOCarePlan = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_KYOTAKUKAIGO_CARE_PLAN, payload: req } as const);
export const postKYOTAKUKAIGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.POST_KYOTAKUKAIGO_CARE_PLAN_FAILED, error: err } as const);

export const deleteKYOTAKUKAIGOCarePlanStarted = () =>
  ({ type: types.DELETE_KYOTAKUKAIGO_CARE_PLAN_STARTED } as const);
export const deleteKYOTAKUKAIGOCarePlan = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_KYOTAKUKAIGO_CARE_PLAN, payload: req } as const);
export const deleteKYOTAKUKAIGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_KYOTAKUKAIGO_CARE_PLAN_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCarePlanStarted>
  | ReturnType<typeof fetchCarePlan>
  | ReturnType<typeof fetchCarePlanFailed>
  | ReturnType<typeof postKYOTAKUKAIGOCarePlanStarted>
  | ReturnType<typeof postKYOTAKUKAIGOCarePlan>
  | ReturnType<typeof postKYOTAKUKAIGOCarePlanFailed>
  | ReturnType<typeof deleteKYOTAKUKAIGOCarePlanStarted>
  | ReturnType<typeof deleteKYOTAKUKAIGOCarePlan>
  | ReturnType<typeof deleteKYOTAKUKAIGOCarePlanFailed>;
