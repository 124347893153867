import React from "react";

import {
  TableRow,
  Theme,
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { TimeCell } from "../record/TimeCell";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { Done as DoneIcon } from "@material-ui/icons";

import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";
import MuiCheckbox from "@components/molecules/MuiCheckbox";
import { CheckedIds } from "./SupportProcedureCreateModalContent";

const styles = ({ palette }: Theme): StyleRules =>
  createStyles({
    tableRowBase: {
      "&:nth-of-type(even)": {
        backgroundColor: palette.background.default
      }
    },
    baseCell: {
      fontSize: "16px",
      padding: 0,
      boxSizing: "content-box"
    },
    checkboxCell: {
      width: 40,
      paddingLeft: 32
    },
    dateCell: {
      minWidth: 120,
      width: 120
    },
    noDateLabel: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)"
    },
    createdCell: {
      width: 42,
      minWidth: 42,
      fontSize: "12px",
      textAlign: "center"
    },
    createdIcon: {
      color: "#2db45a",
      width: "18px",
      height: "18px"
    },
    numberOfPractitionerCell: {
      width: 56,
      minWidth: 56,
      textAlign: "center"
    },
    timeCell: {
      width: 164,
      minWidth: 164
    },
    titleCell: {
      width: 372,
      minWidth: 372,
      "&:last-child": {
        paddingRight: 0
      }
    },
    dotBorderTop: {
      backgroundImage:
        "linear-gradient(to right, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54) 3px,transparent 3px, transparent 6px)",
      backgroundSize: "6px 1px",
      backgroundRepeat: "repeat-x",
      backgroundPosition: "left top"
    }
  });

type OwnProps = {
  index: number;
  checkedIds: CheckedIds[];
  handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rowData: SupportProcedureState["supportProcedureList"]["support_procedure_forms"][0];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureModalTableRowCore = (props: Props): JSX.Element => {
  const { index, checkedIds, handleChangeCheckbox, rowData, classes } = props;

  return (
    <TableRow
      className={`${classes.tableRowBase} ${
        index !== 0 && classes.dotBorderTop
      }`}
    >
      <>
        {/* チェックボックス */}
        <TableCellWrap
          key={`checkbox-${index}`}
          cellClass={`${classes.baseCell} ${classes.checkboxCell}`}
        >
          <MuiCheckbox
            id={`${index}`}
            label=""
            value={`${rowData.procedure_status_flg}`}
            checked={checkedIds.some(({ id }) => id === `${index}`)}
            onChange={handleChangeCheckbox}
            // FormControlLabelのマージンを打ち消す => marginRight
            style={{ marginTop: 0, marginBottom: 0, marginRight: -16 }}
          />
        </TableCellWrap>
        {/* 日付 */}
        {rowData.target_date === null ? (
          <TableCellWrap
            key={`date-${index}`}
            cellClass={`${classes.baseCell} ${classes.dateCell}`}
          >
            <div className={classes.noDateLabel}>設定なし</div>
          </TableCellWrap>
        ) : (
          <TableCellWrap
            key={`date-${index}`}
            cellClass={`${classes.baseCell} ${classes.dateCell}`}
          >
            <CustomDateLabel
              holiday={rowData.is_holiday}
              date={rowData.target_date}
              dateFormat="Do（dd）"
            />
          </TableCellWrap>
        )}
        {/* 作成済み */}
        <TableCellWrap
          key={`created-flg-${index}`}
          cellClass={`${classes.baseCell} ${classes.createdCell}`}
        >
          {rowData.procedure_status_flg !== 0 && (
            <DoneIcon className={classes.createdIcon} />
          )}
        </TableCellWrap>
        {/* 人数 */}
        <TableCellWrap
          key={`practitioner-number-${index}`}
          cellClass={`${classes.baseCell} ${classes.numberOfPractitionerCell}`}
        >
          {rowData.number_of_practitioner
            ? rowData.number_of_practitioner
            : "-"}
        </TableCellWrap>
        {/* 時間 */}
        <TableCellWrap
          key={`time-${index}`}
          cellClass={`${classes.baseCell} ${classes.timeCell}`}
        >
          <TimeCell rowData={rowData} />
        </TableCellWrap>
        {/* タイトル */}
        <TableCellWrap
          key={`title-${index}`}
          cellClass={`${classes.baseCell} ${classes.titleCell}`}
        >
          {rowData.title ? rowData.title : "-"}
        </TableCellWrap>
      </>
    </TableRow>
  );
};

export const SupportProcedureModalTableRow = withStyles(styles)(
  SupportProcedureModalTableRowCore
);
