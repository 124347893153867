import {
  GetInOutResultsDailyResponse,
  InoutResultsDataList,
  InoutResultsDetailsItem,
  InoutResultsItem,
  InoutResultsStaffsInFacilityItem,
  PractitionerStaffItem,
  ServiceCodesItem
} from "@api/requests/inOutResults/getInOutResultsDaily";
import { GetInOutResultsUsersResponse } from "@api/requests/inOutResults/getInOutResultsMonthly";
import {
  DEFAULT_CHECKBOX_VALUE,
  INT_FALSE_FROM_API,
  INT_TRUE_FROM_API
} from "@constants/variables";
import {
  CopyReportState,
  DataListState,
  DeleteReportState,
  DisplayListState,
  DisplayStaffsInFacilityState,
  InoutResultsDetailsState,
  InoutResultsState,
  MunicipalityData,
  PractitionerStaffState,
  ReportState,
  ServiceCodesState
} from "@stores/domain/mgr/IDOSHIEN/report//types";
import {
  convertTimeHHMM,
  createTime,
  dateToLocalisedString,
  parseDateString
} from "@utils/date";
import { RequestParamToDeleteAPI } from "@api/requests/inOutResults/deleteInOutResults";
import { RequestParamToPostCopyAPI } from "@api/requests/inOutResults/postInOutResultsCopy";
import {
  InitialValues,
  InoutResultsDetailsFields
} from "@interfaces/mgr/IDOSHIEN/report/initial";
import { RequestParamToPostAPI } from "@api/requests/inOutResults/postInOutResults";
import castNumber from "@utils/dataNormalizer/castNumber";
import {
  IDOSHIEN_INPUT_CLASS_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import { cloneDeep } from "lodash-es";

type InitBaseInfo = {
  uifId: number;
  sei: string;
  mei: string;
  date: string;
};

/**
 * 当日/翌日の取得
 */
const getTimeClass = (date?: string, dateTime?: string): string => {
  let timeClass = "";
  if (date && dateTime) {
    timeClass =
      date === dateToLocalisedString(dateTime, "YYYYMMDD") ? "0" : "1";
  }

  return timeClass;
};

const normalizeInoutResultsDetail = (
  list: InoutResultsDetailsItem[],
  date: string
): InoutResultsDetailsState[] => {
  return list && list.length > 0
    ? list.map((record) => {
        return {
          id: record.id,
          actionClass: record.action_class,
          inTimeClass: getTimeClass(date, record.in_time),
          inTime: convertTimeHHMM(parseDateString(record.in_time)),
          outTimeClass: getTimeClass(date, record.out_time),
          outTime: convertTimeHHMM(parseDateString(record.out_time))
        };
      })
    : [];
};

const normalizePractitionerStaff = (
  data: PractitionerStaffItem | null
): PractitionerStaffState | null => {
  return data
    ? {
        id: data.id,
        name: data.name,
        facilityId: data.facility_id,
        snapshotName: data.snapshot_name
      }
    : null;
};

const normalizeServiceCodes = (
  list: ServiceCodesItem[]
): ServiceCodesState[] => {
  return list && list.length > 0
    ? list.map((record) => {
        return {
          serviceCodeInoutResultId: record.service_code_inout_result_id,
          serviceCodeMunicipalityId: record.service_code_municipality_id,
          name: record.name,
          serviceCode: record.service_code,
          unitPrice: record.unit_price
        };
      })
    : [];
};

export const normalizeDaily = (
  daily: GetInOutResultsDailyResponse["data"]["inout_result_daily"]
): ReportState["reportDaily"]["inoutResultDaily"] => {
  return {
    targetDate: daily.target_date
  };
};

export const normalizeDetailData = (
  inputClass: number,
  inoutResultsId: number | null,
  inoutResultsList: InoutResultsItem[],
  baseInfo: InitBaseInfo,
  inoutResultsPlanId: number | null = null,
  inoutResultsResultId: number | null = null,
  serviceDeliveryRecordsId: number | null = null,
  resultFlg = false
): InoutResultsState => {
  const targetRecord = inoutResultsList
    ? inoutResultsList.filter((row) => {
        return (
          row.id === inoutResultsId &&
          row.users_in_facility_id === baseInfo.uifId &&
          row.input_class === inputClass
        );
      })
    : [];

  const record =
    inoutResultsId && targetRecord.length === 1 ? targetRecord[0] : null;
  const date =
    record && record.target_date ? record.target_date : baseInfo.date;
  return {
    id: record ? record.id : null,
    inoutResultDailyId:
      record && record.inout_result_daily_id
        ? record.inout_result_daily_id
        : null,
    usersInFacilityId: record ? record.users_in_facility_id : baseInfo.uifId,
    nameSei: record ? record.name_sei : baseInfo.sei,
    nameMei: record ? record.name_mei : baseInfo.mei,
    targetDate: date,
    inputClass: record ? record.input_class : inputClass,
    inoutResultsPlanId: record
      ? record.inout_results_plan_id
      : inoutResultsPlanId,
    inoutResultsResultId,
    serviceDeliveryRecordsId: record
      ? record.service_delivery_records_id
      : serviceDeliveryRecordsId,
    status: record && record.status ? `${record.status}` : "",
    statusText: record && record.status_text ? `${record.status_text}` : "",
    numberOfParticipants: record ? record.number_of_participants : null,
    practitioner1: record ? record.practitioner1 : null,
    practitioner1Staff: record
      ? normalizePractitionerStaff(record.practitioner1_staff)
      : null,
    practitioner1InTime:
      record && record.practitioner1_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_in_time))
        : "",
    practitioner1OutTimeClass: record
      ? getTimeClass(date, record.practitioner1_out_time)
      : "",
    practitioner1OutTime:
      record && record.practitioner1_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner1_out_time))
        : "",
    practitioner1CalculatedHours: record
      ? record.practitioner1_calculated_hours
      : null,
    practitioner1Memo: record ? record.practitioner1_memo : "",
    inoutResultsDetails1: record
      ? normalizeInoutResultsDetail(record.inout_results_details1, date)
      : [],
    practitioner2: record ? record.practitioner2 : null,
    practitioner2Staff: record
      ? normalizePractitionerStaff(record.practitioner2_staff)
      : null,
    practitioner2InTimeClass: record
      ? getTimeClass(date, record.practitioner2_in_time)
      : "",
    practitioner2InTime:
      record && record.practitioner2_in_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_in_time))
        : "",
    practitioner2OutTimeClass: record
      ? getTimeClass(date, record.practitioner2_out_time)
      : "",
    practitioner2OutTime:
      record && record.practitioner2_out_time
        ? convertTimeHHMM(parseDateString(record.practitioner2_out_time))
        : "",
    practitioner2Memo: record ? record.practitioner2_memo : "",
    inoutResultsDetails2: record
      ? normalizeInoutResultsDetail(record.inout_results_details2, date)
      : [],
    practitioner3Staff: record
      ? normalizePractitionerStaff(record.practitioner3_staff)
      : null,
    practitioner4Staff: record
      ? normalizePractitionerStaff(record.practitioner4_staff)
      : null,
    practitioner5Staff: record
      ? normalizePractitionerStaff(record.practitioner5_staff)
      : null,
    timeDuplicationJudgmentFlg: record
      ? record.time_duplication_judgment_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    wholeCalculatedHours: record ? record.whole_calculated_hours : null,
    calculatedHoursDaytime: record ? record.calculated_hours_daytime : null,
    calculatedHoursNighttime: record ? record.calculated_hours_nighttime : null,
    calculatedHoursLateNight: record
      ? record.calculated_hours_late_night
      : null,
    calculatedHoursEarlyMorning: record
      ? record.calculated_hours_early_morning
      : null,
    duplicateCalculatedHours: record ? record.duplicate_calculated_hours : null,
    duplicateCalculatedHoursDaytime: record
      ? record.duplication_calculated_hours_daytime
      : null,
    duplicateCalculatedHoursNighttime: record
      ? record.duplication_calculated_hours_nighttime
      : null,
    duplicateCalculatedHoursLateNight: record
      ? record.duplication_calculated_hours_late_night
      : null,
    duplicateCalculatedHoursEarlyMorning: record
      ? record.duplication_calculated_hours_early_morning
      : null,
    vehicleCalculatedHours: record ? record.vehicle_calculated_hours : "",
    licenseSameFlg: record
      ? record.license_same_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    secondPersonFlg: record
      ? record.second_person_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    physicalCareFlg: record
      ? record.physical_care_flg === INT_TRUE_FROM_API
      : DEFAULT_CHECKBOX_VALUE,
    numberOfSupporters: record ? record.number_of_supporters : null,
    municipality: {
      calculationTimeFlg: record
        ? record.municipality.calculation_time_flg === INT_TRUE_FROM_API
        : DEFAULT_CHECKBOX_VALUE,
      roundUpMinute:
        record && record.municipality.calculation_time_flg === INT_TRUE_FROM_API
          ? record.municipality.round_up_minute
          : null,
      timeDivisionFlg: record
        ? record.municipality.time_division_flg === INT_TRUE_FROM_API
        : DEFAULT_CHECKBOX_VALUE
    },
    serviceCodes: record ? normalizeServiceCodes(record.service_codes) : [],
    resultExistFlg: resultFlg
  };
};

export const normalizeDisplayStaffsInFacilityStateFromAPI = (
  data: InoutResultsStaffsInFacilityItem[]
): DisplayStaffsInFacilityState[] => {
  return data && data.length > 0
    ? data.map((record) => {
        return {
          label: record.name,
          value: record.id.toString()
        };
      })
    : [];
};

export const initialDataList = (
  record: InoutResultsDataList | null,
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  baseInfo: InitBaseInfo
): DataListState => {
  const planId = record ? record.plan.inout_results_id : null;
  const resultId = record ? record.result.inout_results_id : null;

  const target = inoutResults
    ? inoutResults.find((x) => x.id === planId || x.id === resultId)
    : null;
  const serviceDeliveryRecordsId = target && target.service_delivery_records_id;

  // 計画に紐づく実績が存在するか（段ずれ表示を考慮して、inout_resultsを探して判定）
  const resultFlg =
    planId && inoutResults
      ? inoutResults.filter((row) => {
          return (
            row.inout_results_plan_id === planId &&
            row.users_in_facility_id === baseInfo.uifId &&
            row.input_class === 2
          );
        }).length > 0
      : false;

  return {
    no: record ? record.no : null,
    resultExistFlg: resultFlg,
    plan: {
      inoutResultsId: planId,
      dispatchNo: record && record.plan ? record.plan.dispatch_no : null,
      status: record && record.plan ? record.plan.status : null,
      statusText: record && record.plan ? record.plan.status_text : null,
      inTime: record && record.plan ? record.plan.in_time : null,
      outTime: record && record.plan ? record.plan.out_time : null,
      calculatedHours:
        record && record.plan ? record.plan.calculated_hours : null,
      gettingOnAndOff:
        record && record.plan ? record.plan.getting_on_and_off : null,
      numberOfParticipants:
        record && record.plan ? record.plan.number_of_participants : null
    },
    result: {
      inoutResultsId: resultId,
      dispatchNo: record && record.result ? record.result.dispatch_no : null,
      status: record && record.result ? record.result.status : null,
      statusText: record && record.result ? record.result.status_text : null,
      inTime: record && record.result ? record.result.in_time : null,
      outTime: record ? record.result.out_time : null,
      calculatedHours:
        record && record.result ? record.result.calculated_hours : null,
      gettingOnAndOff:
        record && record.result ? record.result.getting_on_and_off : null,
      numberOfParticipants:
        record && record.result ? record.result.number_of_participants : null,
      emergencySupportFlg:
        record && record.result ? record.result.emergency_support_flg : null,
      serviceCodeFlg:
        record && record.result ? record.result.service_code_flg : null,
      differenceFlg:
        record && record.result ? record.result.difference_flg : null
    },
    planDetail: normalizeDetailData(
      1,
      planId,
      inoutResults,
      baseInfo,
      null,
      resultId,
      serviceDeliveryRecordsId,
      resultFlg
    ),
    resultDetail: normalizeDetailData(
      2,
      resultId,
      inoutResults,
      baseInfo,
      planId,
      null,
      serviceDeliveryRecordsId
    )
  };
};

export const normalizeDataListFromAPI = (
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  displayList: InoutResultsDataList[],
  baseInfo: InitBaseInfo
): DataListState[] => {
  return displayList && displayList.length > 0
    ? displayList.map((record) => {
        return initialDataList(record, inoutResults, baseInfo);
      })
    : [initialDataList(null, inoutResults, baseInfo)];
};

/**
 * 実績データ一覧の整形
 */
export const normalizeDisplayListFromAPI = (
  inoutResults: GetInOutResultsUsersResponse["data"]["inout_results"],
  displayList: GetInOutResultsUsersResponse["data"]["display_list"],
  date = ""
): DisplayListState[] => {
  return displayList
    ? displayList.map((record) => {
        const targetDateState = record.target_date ? record.target_date : date;

        const baseInfo: InitBaseInfo = {
          uifId: record.users_in_facility_id,
          sei: record.name_sei,
          mei: record.name_mei,
          date: targetDateState
        };

        return {
          usersInFacilityId: record.users_in_facility_id,
          addIconFlg: record.add_icon_flg,
          nameSei: record.name_sei,
          nameMei: record.name_mei,
          isHoliday: record.isHoliday ? record.isHoliday : false,
          targetDate: targetDateState,
          dataList: normalizeDataListFromAPI(
            inoutResults,
            record.data_list,
            baseInfo
          )
        };
      })
    : [];
};

/**
 * 利用実績一覧(日ごと)
 */
export const normalizeIDOSHIENDailyReportDataFromAPI = (
  result: GetInOutResultsDailyResponse,
  date: string
): ReportState["reportDaily"] => {
  const normalizeReportList: ReportState["reportDaily"] = {
    inoutResultDaily: normalizeDaily(result.data.inout_result_daily),
    staffsInFacility: result.data.staffs_in_facility,
    displayStaffsInFacility: normalizeDisplayStaffsInFacilityStateFromAPI(
      result.data.staffs_in_facility
    ),
    displayList: normalizeDisplayListFromAPI(
      result.data.inout_results,
      result.data.display_list,
      date
    ),
    planCount: result.data.inout_results
      ? result.data.inout_results.filter((record) => record.input_class === 1)
          .length
      : 0
  };

  return normalizeReportList;
};

export const normalizeSummary = (
  summary: GetInOutResultsUsersResponse["data"]["summary"]
): ReportState["reportMonthly"]["summary"] => {
  return {
    payTimesIndividualSupport: summary.pay_times_individual_support,
    payMonthsIndividualSupport: summary.pay_months_individual_support,
    payTimesGroupSupport: summary.pay_times_group_support,
    payMonthsGroupSupport: summary.pay_months_group_support,
    payTimesVehicleTransferSupport: summary.pay_times_vehicle_transfer_support,
    payMonthsVehicleTransferSupport:
      summary.pay_months_vehicle_transfer_support,
    payTimesSchoolAndHospitalCommutingSupport:
      summary.pay_times_school_and_hospital_commuting_support,
    payMonthsSchoolAndHospitalCommutingSupport:
      summary.pay_months_school_and_hospital_commuting_support,
    payTimesOtherSupport: summary.pay_times_other_support,
    payMonthsOtherSupport: summary.pay_months_other_support
  };
};

/**
 * 利用実績一覧(ユーザごと)
 */
export const normalizeIDOSHIENUserReportDataFromAPI = (
  result: GetInOutResultsUsersResponse
): ReportState["reportMonthly"] => {
  const normalizeReportList: ReportState["reportMonthly"] = {
    summary: normalizeSummary(result.data.summary),
    staffsInFacility: result.data.staffs_in_facility,
    displayStaffsInFacility: normalizeDisplayStaffsInFacilityStateFromAPI(
      result.data.staffs_in_facility
    ),
    numberOfRecords: result.data.number_of_records,
    planCount: result.data.inout_results
      ? result.data.inout_results.filter((record) => record.input_class === 1)
          .length
      : 0,
    displayList: normalizeDisplayListFromAPI(
      result.data.inout_results,
      result.data.display_list
    )
  };

  return normalizeReportList;
};

// サービス内容による不要なデータのstoreKey一覧
const nullDataByStatus = (statusValue: string): string[] => {
  switch (statusValue) {
    case IDOSHIEN_STATUS_LIST.INDIVIDUAL.value:
    case IDOSHIEN_STATUS_LIST.COMMUTE.value:
      return [
        "status_text",
        "practitioner3_staff",
        "practitioner4_staff",
        "practitioner5_staff",
        "vehicle_calculated_hours",
        "number_of_supporters"
      ];
    case IDOSHIEN_STATUS_LIST.GROUP.value:
      return [
        "status_text",
        "license_same_flg",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_calculated_hours",
        "practitioner2_memo",
        "inout_results_details2",
        "vehicle_calculated_hours",
        "whole_calculated_hours",
        "duplicate_calculated_hours",
        "duplication_calculated_hours_daytime",
        "duplication_calculated_hours_nighttime",
        "duplication_calculated_hours_late_night",
        "duplication_calculated_hours_early_morning"
      ];
    case IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value:
      return [
        "status_text",
        "license_same_flg",
        "practitioner1_calculated_hours",
        "practitioner2_in_time",
        "practitioner2_out_time",
        "practitioner2_calculated_hours",
        "practitioner2_memo",
        "inout_results_details2",
        "practitioner3_staff",
        "practitioner4_staff",
        "practitioner5_staff",
        "number_of_supporters",
        "whole_calculated_hours",
        "calculated_hours_daytime",
        "calculated_hours_nighttime",
        "calculated_hours_late_night",
        "calculated_hours_early_morning",
        "duplicate_calculated_hours",
        "duplication_calculated_hours_daytime",
        "duplication_calculated_hours_nighttime",
        "duplication_calculated_hours_late_night",
        "duplication_calculated_hours_early_morning"
      ];
    case IDOSHIEN_STATUS_LIST.OTHER.value:
      return [
        "practitioner3_staff",
        "practitioner4_staff",
        "practitioner5_staff",
        "vehicle_calculated_hours",
        "number_of_supporters"
      ];
    default:
      return [];
  }
};

// 提供人数による不要なデータのstoreKey一覧
const nullDataByParticipants = (
  status: string,
  participants: number,
  license_same_flg: number | null | undefined
): string[] => {
  const practitioner1NullValues = [
    "license_same_flg",
    "practitioner2",
    "practitioner2_in_time",
    "practitioner2_out_time",
    "practitioner2_calculated_hours",
    "practitioner2_memo",
    "inout_results_details2",
    "whole_calculated_hours",
    "duplicate_calculated_hours",
    "duplication_calculated_hours_daytime",
    "duplication_calculated_hours_nighttime",
    "duplication_calculated_hours_late_night",
    "duplication_calculated_hours_early_morning"
  ];

  // グループ支援対応の場合
  if (status === IDOSHIEN_STATUS_LIST.GROUP.value) {
    if (participants === 1) {
      return practitioner1NullValues;
    }
    return [
      "license_same_flg",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "practitioner2_calculated_hours",
      "practitioner2_memo",
      "inout_results_details2",
      "whole_calculated_hours",
      "duplicate_calculated_hours",
      "duplication_calculated_hours_daytime",
      "duplication_calculated_hours_nighttime",
      "duplication_calculated_hours_late_night",
      "duplication_calculated_hours_early_morning"
    ];
  }
  // それ以外の場合
  switch (participants) {
    case 1:
      // 提供人数(number_of_supporters)はグループ支援の場合必要なので除外キーをここで追加してる
      return ["number_of_supporters", ...practitioner1NullValues];
    case 2:
      if (license_same_flg) {
        return [
          "practitioner2_in_time",
          "practitioner2_out_time",
          "practitioner2_calculated_hours",
          "practitioner2_memo",
          "inout_results_details2",
          "practitioner1_calculated_hours"
        ];
      }
      return [
        "practitioner2_calculated_hours",
        "practitioner1_calculated_hours"
      ];
    default:
      return [];
  }
};

const normalizeInoutResultsDetailToAPI = (
  detailList: InoutResultsDetailsFields[],
  date: string,
  status: string
): InoutResultsDetailsItem[] => {
  if (!detailList || detailList.length === 0) {
    return [];
  }

  const result = detailList.map((record) => {
    // 車両移送の場合は必ず「空き」
    const actionClass = status !== "4" ? record.actionClass : "2";
    return {
      id: record.id,
      action_class:
        record.inTime && record.outTime ? castNumber(actionClass) : -1,
      in_time: createTime(date, record.inTime, record.inTimeClass),
      out_time: createTime(date, record.outTime, record.outTimeClass)
    };
  });

  return result.filter((record) => {
    return record.action_class !== -1;
  });
};

// パラメータの関係マッピング表
const relationshipParamsMap = {
  practitioner1_in_time: {
    requestKey: "practitioner1_in_time",
    cooperationKeys: [
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "inout_results_details1"
    ]
  },
  practitioner1_out_time: {
    requestKey: "practitioner1_out_time",
    cooperationKeys: [
      "practitioner1_in_time",
      "practitioner2_in_time",
      "practitioner2_out_time",
      "inout_results_details1"
    ]
  },
  practitioner2_in_time: {
    requestKey: "practitioner2_in_time",
    cooperationKeys: [
      "practitioner2_out_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "inout_results_details2"
    ]
  },
  practitioner2_out_time: {
    requestKey: "practitioner2_out_time",
    cooperationKeys: [
      "practitioner2_in_time",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "inout_results_details2"
    ]
  },
  practitioner1_calculated_hours: {
    requestKey: "practitioner1_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  whole_calculated_hours: {
    requestKey: "whole_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  duplicate_calculated_hours: {
    requestKey: "duplicate_calculated_hours",
    cooperationKeys: [
      "time_duplication_judgment_flg",
      "practitioner1_in_time",
      "practitioner1_out_time",
      "practitioner2_in_time",
      "practitioner2_out_time"
    ]
  },
  time_duplication_judgment_flg: {
    requestKey: "time_duplication_judgment_flg",
    cooperationKeys: [
      "practitioner1_calculated_hours",
      "whole_calculated_hours",
      "duplicate_calculated_hours"
    ]
  }
};

/**
 * 要素の差分判定 要素に差分がある場合、連携要素もパラメータに付与する
 */
const addRelationValue = (
  target: RequestParamToPostAPI,
  after: RequestParamToPostAPI
): RequestParamToPostAPI => {
  const addedRelationParam = cloneDeep(target);
  Object.keys(relationshipParamsMap).forEach((key) => {
    const cooperationKeys = relationshipParamsMap[key].cooperationKeys
      ? relationshipParamsMap[key].cooperationKeys
      : [];
    const hasDiffCooperation: boolean = cooperationKeys.some(
      (cooperationKey: string) => {
        return addedRelationParam.params[cooperationKey] !== undefined;
      }
    );

    if (
      hasDiffCooperation &&
      addedRelationParam.params[relationshipParamsMap[key].requestKey] ===
        undefined
    ) {
      addedRelationParam.params[relationshipParamsMap[key].requestKey] =
        after.params[relationshipParamsMap[key].requestKey];
    }
  });
  return addedRelationParam;
};

/**
 * 明細の差分有無確認
 */
const normalizeDiffDetailParamToPostAPI = (
  before: InoutResultsDetailsItem[] | undefined,
  after: InoutResultsDetailsItem[] | undefined
): boolean => {
  if (before === undefined || after === undefined) {
    return false;
  }
  if (before.length === 0 && after.length === 0) {
    return false;
  }

  return true;
};

/**
 * スナップショットに対応したスタッフ名取得処理
 * 0を選択した時にはスナップショットを選択した判定となる
 * @param staffList スタッフの選択肢一覧
 * @param id スタッフID
 * @returns スタッフ名 or null(未選択) or undefined(スナップショット)
 */
const getStaffNameOrNullOrUndefined: (
  staffList: DisplayStaffsInFacilityState[],
  id: string
) => string | null | undefined = (staffList, id) => {
  if (id === "0") {
    return undefined;
  }
  if (id === "") {
    return null;
  }
  const staff = staffList.find((v) => v.value === id);
  if (staff) {
    return staff.label;
  }
  return null;
};

/**
 * 利用実績登録
 */
export const normalizeRequestParamToPostAPI = (
  formValue: InitialValues,
  targetDate: string,
  usersInFacilityId: string | null,
  municipality: MunicipalityData,
  staffList: DisplayStaffsInFacilityState[]
): RequestParamToPostAPI => {
  const result: RequestParamToPostAPI = {
    yyyymmdd: dateToLocalisedString(targetDate, "YYYYMMDD"),
    uif_id: usersInFacilityId,
    params: {
      inout_results_id: formValue.initial.id,
      inout_result_daily_id: formValue.initial.inoutResultDailyId,
      target_date: targetDate,
      input_class: castNumber(formValue.initial.inputClass),
      inout_results_plan_id: formValue.initial.inoutResultsPlanId,
      inout_results_result_id: formValue.initial.inoutResultsResultId,
      service_delivery_records_id: formValue.initial.serviceDeliveryRecordsId,
      status: castNumber(formValue.initial.status),
      status_text:
        formValue.initial.status === "6" ? formValue.initial.statusText : null,
      number_of_participants: castNumber(
        formValue.initial.numberOfParticipants
      ),
      practitioner1: formValue.initial.practitioner1
        ? castNumber(formValue.initial.practitioner1)
        : null,
      practitioner1_name: getStaffNameOrNullOrUndefined(
        staffList,
        formValue.initial.practitioner1
      ),
      practitioner1_in_time: createTime(
        targetDate,
        formValue.initial.practitioner1InTime,
        formValue.initial.practitioner1InTimeClass
      ),
      practitioner1_out_time: createTime(
        targetDate,
        formValue.initial.practitioner1OutTime,
        formValue.initial.practitioner1OutTimeClass
      ),
      practitioner1_calculated_hours:
        formValue.initial.status === IDOSHIEN_STATUS_LIST.GROUP.value ||
        formValue.initial.numberOfParticipants === "1"
          ? castNumber(formValue.initial.practitioner1CalculatedHours)
          : null,
      practitioner1_memo: formValue.initial.practitioner1Memo,
      inout_results_details1: normalizeInoutResultsDetailToAPI(
        formValue.initial.inoutResultsDetails1,
        targetDate,
        formValue.initial.status
      ),
      practitioner2:
        formValue.initial.numberOfParticipants >= "2" &&
        formValue.initial.practitioner2
          ? castNumber(formValue.initial.practitioner2)
          : null,
      practitioner2_name: getStaffNameOrNullOrUndefined(
        staffList,
        formValue.initial.practitioner2
      ),
      practitioner2_in_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(
              targetDate,
              formValue.initial.practitioner2InTime,
              formValue.initial.practitioner2InTimeClass
            )
          : null,
      practitioner2_out_time:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? createTime(
              targetDate,
              formValue.initial.practitioner2OutTime,
              formValue.initial.practitioner2OutTimeClass
            )
          : null,
      practitioner2_memo:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? formValue.initial.practitioner2Memo
          : null,
      inout_results_details2:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg
          ? normalizeInoutResultsDetailToAPI(
              formValue.initial.inoutResultsDetails2,
              targetDate,
              formValue.initial.status
            )
          : [],
      practitioner3:
        formValue.initial.numberOfParticipants >= "3" &&
        formValue.initial.status === "3" &&
        formValue.initial.practitioner3
          ? castNumber(formValue.initial.practitioner3)
          : null,
      practitioner3_name: getStaffNameOrNullOrUndefined(
        staffList,
        formValue.initial.practitioner3
      ),
      practitioner4:
        formValue.initial.numberOfParticipants >= "4" &&
        formValue.initial.status === "3" &&
        formValue.initial.practitioner4
          ? castNumber(formValue.initial.practitioner4)
          : null,
      practitioner4_name: getStaffNameOrNullOrUndefined(
        staffList,
        formValue.initial.practitioner4
      ),
      practitioner5:
        formValue.initial.numberOfParticipants === "5" &&
        formValue.initial.status === "3" &&
        formValue.initial.practitioner5
          ? castNumber(formValue.initial.practitioner5)
          : null,
      practitioner5_name: getStaffNameOrNullOrUndefined(
        staffList,
        formValue.initial.practitioner5
      ),
      time_duplication_judgment_flg:
        formValue.initial.numberOfParticipants === "2" &&
        !formValue.initial.licenseSameFlg &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4"
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
      whole_calculated_hours:
        formValue.initial.numberOfParticipants === "2" &&
        !municipality.timeDivisionFlg
          ? castNumber(formValue.initial.wholeCalculatedHours)
          : null,
      calculated_hours_daytime: municipality.timeDivisionFlg
        ? castNumber(formValue.initial.calculatedHoursDaytime)
        : null,
      calculated_hours_nighttime: municipality.timeDivisionFlg
        ? castNumber(formValue.initial.calculatedHoursNighttime)
        : null,
      calculated_hours_late_night: municipality.timeDivisionFlg
        ? castNumber(formValue.initial.calculatedHoursLateNight)
        : null,
      calculated_hours_early_morning: municipality.timeDivisionFlg
        ? castNumber(formValue.initial.calculatedHoursEarlyMorning)
        : null,
      duplicate_calculated_hours:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        !municipality.timeDivisionFlg
          ? castNumber(formValue.initial.duplicateCalculatedHours)
          : null,
      duplication_calculated_hours_daytime:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        municipality.timeDivisionFlg
          ? castNumber(formValue.initial.duplicateCalculatedHoursDaytime)
          : null,
      duplication_calculated_hours_nighttime:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        municipality.timeDivisionFlg
          ? castNumber(formValue.initial.duplicateCalculatedHoursNighttime)
          : null,
      duplication_calculated_hours_late_night:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        municipality.timeDivisionFlg
          ? castNumber(formValue.initial.duplicateCalculatedHoursLateNight)
          : null,
      duplication_calculated_hours_early_morning:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        municipality.timeDivisionFlg
          ? castNumber(formValue.initial.duplicateCalculatedHoursEarlyMorning)
          : null,
      vehicle_calculated_hours:
        formValue.initial.status === "4"
          ? `${String(formValue.initial.vehicleCalculatedHours).padStart(
              2,
              "0"
            )}:${String(formValue.initial.vehicleCalculatedMinute).padStart(
              2,
              "0"
            )}`
          : null,
      license_same_flg:
        formValue.initial.numberOfParticipants === "2" &&
        formValue.initial.status !== "3" &&
        formValue.initial.status !== "4" &&
        formValue.initial.licenseSameFlg
          ? INT_TRUE_FROM_API
          : INT_FALSE_FROM_API,
      physical_care_flg: formValue.initial.physicalCareFlg
        ? INT_TRUE_FROM_API
        : INT_FALSE_FROM_API,
      number_of_supporters:
        formValue.initial.status === "3"
          ? castNumber(formValue.initial.numberOfSupporters)
          : null,
      service_codes:
        formValue.initial.inputClass ===
          IDOSHIEN_INPUT_CLASS_LIST.RESULT.value && !formValue.initial.createFlg
          ? formValue.initial.serviceCodes
              .filter(
                (x) => !(x.serviceCodeInoutResultId === null && x.isDelete)
              )
              .map((x) => ({
                service_code_inout_result_id: x.serviceCodeInoutResultId,
                service_code_municipality_id: x.serviceCodeMunicipalityId,
                is_delete: x.isDelete ? 1 : 0
              }))
          : []
    }
  };

  return result;
};

export const normalizeDiffParamToPostAPI = (
  targetStatus: string,
  before: RequestParamToPostAPI,
  after: RequestParamToPostAPI,
  municipality: MunicipalityData
): RequestParamToPostAPI => {
  const nullTargetList = nullDataByStatus(
    after.params.status ? after.params.status.toString() : "1"
  )
    .concat(
      nullDataByParticipants(
        targetStatus,
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg
      )
    )
    .concat(
      municipality.timeDivisionFlg
        ? [
            "practitioner1_calculated_hours",
            "whole_calculated_hours",
            "duplicate_calculated_hours"
          ]
        : [
            "calculated_hours_daytime",
            "calculated_hours_nighttime",
            "calculated_hours_late_night",
            "calculated_hours_early_morning",
            "duplication_calculated_hours_daytime",
            "duplication_calculated_hours_nighttime",
            "duplication_calculated_hours_late_night",
            "duplication_calculated_hours_early_morning"
          ]
    );

  const result: RequestParamToPostAPI = {
    uif_id: before.uif_id,
    yyyymmdd: before.yyyymmdd,
    params: {
      inout_result_daily_id: after.params.inout_result_daily_id,
      inout_results_id: after.params.inout_results_id,
      inout_results_plan_id: after.params.inout_results_plan_id,
      target_date: after.params.target_date,
      input_class: after.params.input_class,
      status: after.params.status,
      time_duplication_judgment_flg: after.params.time_duplication_judgment_flg
    }
  };

  // 差分項目のセット
  Object.keys(before.params).forEach((key) => {
    const nullFlg = nullTargetList.includes(key);
    const detailFlg =
      key === "inout_results_details1" || key === "inout_results_details2";
    const nullValue = detailFlg ? [] : null;
    const diffFlg = !detailFlg
      ? before.params[key] !== after.params[key]
      : normalizeDiffDetailParamToPostAPI(
          before.params[key],
          after.params[key]
        );
    if (after.params.inout_results_id === null) {
      // 初回の場合、null対象はnull、それ以外は入力値をセット
      result.params[key] = nullFlg ? nullValue : after.params[key];
    } else if (diffFlg) {
      result.params[key] = after.params[key];
    }
  });

  const diffDate = addRelationValue(result, after);

  let nullTarget = nullDataByStatus(
    after.params.status ? after.params.status.toString() : "1"
  );

  if (diffDate.params.number_of_participants) {
    nullTarget = nullTarget.concat(
      nullDataByParticipants(
        targetStatus,
        after.params.number_of_participants
          ? after.params.number_of_participants
          : 1,
        after.params.license_same_flg
      )
    );
  }

  nullTarget.forEach((key) => {
    const detailFlg =
      key === "inout_results_details1" || key === "inout_results_details2";
    const nullValue = detailFlg ? [] : null;
    diffDate.params[key] = nullValue;
  });

  return diffDate;
};

export const normalizeRequestParamToPostCopyAPI = (
  processType: number,
  target: CopyReportState[]
): RequestParamToPostCopyAPI => {
  return {
    process_type: processType,
    target: target.map((record) => {
      const data = {
        uif_id: record.uifId,
        yyyymmdd: record.targetDate
      };
      if (record.inoutResultsId) {
        const key = "inout_results_id";
        data[key] = record.inoutResultsId;
      }
      return data;
    })
  };
};

/**
 * 実績削除
 */
export const normalizeRequestParamToDeleteAPI = (
  target: DeleteReportState
): RequestParamToDeleteAPI => {
  return {
    data: {
      target: [
        {
          uif_id: target.uifId,
          yyyymmdd: target.targetDate,
          inout_results_id: target.inoutResultsId
        }
      ]
    }
  };
};

export const normalizeRequestParamToPostCopyServiceDeliveryAPI = (
  processType: number,
  target: CopyReportState[]
): RequestParamToPostCopyAPI => {
  return {
    process_type: processType,
    target: target.map((record) => {
      return {
        uif_id: record.uifId,
        yyyymmdd: record.targetDate
      };
    })
  };
};
