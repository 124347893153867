import * as React from "react";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";

const styles = (): StyleRules =>
  createStyles({
    toolbar: {
      paddingRight: 16,
      paddingLeft: 16
    },
    flex: {
      flexGrow: 1
    }
  });

type OwnProps = {
  mediaOff?: boolean;
  children: React.ReactNode;
};
type Props = OwnProps & WithStyles<typeof styles>;

const ContentHeaderRight = (props: Props): JSX.Element => (
  <Toolbar
    disableGutters={false}
    className={props.mediaOff ? props.classes.toolbar : undefined}
  >
    <div className={props.classes.flex} />
    {props.children}
  </Toolbar>
);

export default withStyles(styles)(ContentHeaderRight);
