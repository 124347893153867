import * as React from "react";

import { createStyles, WithStyles } from "@material-ui/core";
import { StyleRules, withStyles } from "@material-ui/core/styles";

import MuiCheckbox from "@components/molecules/MuiCheckbox";

const styles = (): StyleRules =>
  createStyles({
    formControlLabelBoxStyle: {
      marginRight: 0
    }
  });

type OwnProps = {
  expiredVisibility: boolean;
  setExpiredVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

type Props = WithStyles<typeof styles> & OwnProps;

/**
 * 「退所者の非表示」Checkbox
 */
const FieldExpirationCore = (props: Props): JSX.Element => {
  const { classes, expiredVisibility, setExpiredVisibility } = props;

  const onClickCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { checked } = event.target;
    setExpiredVisibility(checked);
  };

  return (
    <MuiCheckbox
      label="退所者の非表示"
      name="expiredVisibility"
      checked={expiredVisibility}
      onChange={onClickCheckbox}
      formControlLabelBoxStyle={classes.formControlLabelBoxStyle}
      style={{ margin: "0" }}
    />
  );
};

export const FieldExpiration = withStyles(styles)(FieldExpirationCore);
