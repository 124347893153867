import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParam = {
  id: number;
  custom_record_item_id: number;
};

/**
 * カスタム項目の選択肢を削除する
 */
export const postHiddenChoice = async (
  param: RequestParam
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/custom_record/hidden_choice`;
  return request.post(url, param);
};

export default postHiddenChoice;
