import * as React from "react";
import { FormikProps, getIn } from "formik";
import FormGroup from "@material-ui/core/FormGroup";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import FormikTextField from "@components/molecules/FormikTextField";
import FormikSelect from "@components/molecules/FormikSelect";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikRadioButtons from "@components/molecules/FormikRadioButtons";
import UnitsNightSupportFields from "@components/organisms/mgr/GroupHome/facility/items/UnitsNightSupportFields";
import WelfareSpecialistPlacementTypeFields from "@components/organisms/mgr/common/facility/items/WelfareSpecialistPlacementTypeFields";
import { RadioItemInterface } from "@components/atoms/RadioButtons";
import {
  StaffPlacementTypes,
  StaffTreatmentSystemTypes,
  StaffTreatmentSpecificSystemTypes,
  NURSING_SUPPORT_RADIO_ITEMS_202104,
  GroupHomeType,
  ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES,
  DEFAULT_SELECT_DATE_VALUE,
  DEFAULT_RADIO_VALUE,
  SpecificBetterSupporterConditionTypes2024,
  SpecificBetterSupporterConditionSubTypes2024,
  SELECTED_CONDITION_TYPE_2024_V,
  NO_SELECTED_CONDITION_TYPE_2024_SUB
} from "@constants/variables";
import { generateRadioItems } from "@utils/dataNormalizer";
import { FacilityValues } from "@initialize/mgr/GroupHome/facility/initialValues";
import {
  NIGHT_SUPPORT_TYPE_OPTIONS_202104,
  PERSONNEL_DISTRIBUTION_TYPE
} from "@constants/mgr/GroupHome/variables";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import SeeHeareTeam202404Fields from "@components/organisms/mgr/common/facility/items/SeeHeareTeamFields";

// 置き場所が定まってないので一旦ここ
const filterStaffPlacementTypeItems = (
  groupHomeType: number,
  items: RadioItemInterface[]
): RadioItemInterface[] => {
  switch (groupHomeType) {
    case GroupHomeType.日中サービス支援型:
      return [items[0], items[1], items[2], items[3]];
    case GroupHomeType.外部サービス利用型:
      return [items[4], items[5], items[6]];
    default:
      // 介護サービス包括型
      return [items[0], items[1], items[2]];
  }
};

// なしと福祉・介護職員等処遇改善（Ⅰ）-（Ⅴ）の選択肢
const SpecificBetterSupporterConditionType2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionTypes2024
);
// なしと、福祉・介護職員等処遇改善（Ⅴ）1 - 14の選択肢
const SpecificBetterSupporterConditionTypeSub2024RadioItems = generateRadioItems(
  SpecificBetterSupporterConditionSubTypes2024
);

const staffPlacementTypeRadioItems = (
  groupHomeType: number
): RadioItemInterface[] => {
  const radioItems = generateRadioItems(StaffPlacementTypes);
  return filterStaffPlacementTypeItems(groupHomeType, radioItems);
};
const staffTreatmentSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSystemTypes
);
const staffTreatmentSpecificSystemTypeRadioItems = generateRadioItems(
  StaffTreatmentSpecificSystemTypes
);
const personnelDistributionTypeRadioItems = generateRadioItems(
  PERSONNEL_DISTRIBUTION_TYPE
);
interface Props {
  formikProps: FormikProps<FacilityValues>;
  groupHomeType: number;
}

const AdditionalItemFields: React.FunctionComponent<Props> = (props) => {
  // 福祉・介護職員等特定処遇改善加算のdisabled-state
  const [
    disableStaffTreatmentSpecificSystemType,
    setDisableStaffTreatmentSpecificSystemType
  ] = React.useState(false);
  // 福祉・介護職員処遇改善加算が特定の値の時、福祉・介護職員等特定処遇改善加算が選択できる
  const staffTreatmentSystemType: string = getIn(
    props.formikProps.values,
    "additionalItem.staffTreatmentSystemType"
  );

  const isSelectedConditionType2024 =
    props.formikProps.values.additionalItem
      .specificBetterSupporterCondition202404 ===
    `${SELECTED_CONDITION_TYPE_2024_V}`;

  const onChangeSpecificBetterSupporterConditionType2024 = (): void => {
    if (!isSelectedConditionType2024) {
      props.formikProps.setFieldValue(
        "additionalItem.specificBetterSupporterCondition202404Sub",
        NO_SELECTED_CONDITION_TYPE_2024_SUB
      );
    }
  };

  React.useEffect(() => {
    const disabled = !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
      staffTreatmentSystemType
    );
    setDisableStaffTreatmentSpecificSystemType(disabled);
  }, [staffTreatmentSystemType]);

  // 複数ユニットに応じて夜間支援体制加算のフィールドが変わる
  const { operatingUnitFlag } = props.formikProps.values.basic;

  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.currentTarget.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.currentTarget.name) {
        case "additionalItem.higherBrainDysfunctionPersonsSupportFlg":
          props.formikProps.setFieldValue(
            "additionalItem.higherBrainDysfunctionPersonsSupportFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.higherBrainDysfunctionPersonsSupportTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "additionalItem.improvingInfectionControl1Flg":
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl1From",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl1To",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "additionalItem.improvingInfectionControl2Flg":
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl2From",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "additionalItem.improvingInfectionControl2To",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
      }
    }
  };

  React.useEffect(() => {
    // 非活性条件の場合かつ福祉・介護職員等特定処遇改善加算と
    // 福祉・介護職員等ベースアップ等支援加算にデフォルト値以外の入力値がある場合は値をリセットする

    if (
      !ENABLE_SPECIFIC_BETTER_SUPPORTER_CONDITION_VALUES.includes(
        props.formikProps.values.additionalItem.staffTreatmentSystemType
      ) &&
      (props.formikProps.values.additionalItem
        .staffTreatmentSpecificSystemType ||
        props.formikProps.values.additionalItem
          .betterSupporterConditionBaseUpFlg)
    ) {
      props.formikProps.setFieldValue(
        "additionalItem.staffTreatmentSpecificSystemType",
        DEFAULT_RADIO_VALUE
      );

      props.formikProps.setFieldValue(
        "additionalItem.betterSupporterConditionBaseUpFlg",
        false
      );
    }
  }, [props.formikProps.values.additionalItem.staffTreatmentSystemType]);

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="加算対象項目" />
      </div>
      <FormGroup>
        {props.groupHomeType !== GroupHomeType.介護サービス包括型 && (
          <FormikRadioButtons
            name="additionalItem.staffPlacementType"
            label="職員配置"
            options={staffPlacementTypeRadioItems(props.groupHomeType)}
          />
        )}
        {/* 福祉専門職員配置等加算 */}
        <WelfareSpecialistPlacementTypeFields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="welfareSpecialistPlacementType" />}
            />
          }
        />
        <FormGroup style={{ marginBottom: 28 }}>
          <FormikRadioButtons
            name="additionalItem.specificBetterSupporterCondition202404"
            style={{ marginBottom: 0 }}
            label="福祉・介護職員等処遇改善加算（令和6年6月1日から）"
            options={SpecificBetterSupporterConditionType2024RadioItems}
            onClick={onChangeSpecificBetterSupporterConditionType2024}
            tooltip={
              <HelpToolTip
                title={
                  <HelpTipMessages name="specificBetterSupporterCondition202404Tips" />
                }
              />
            }
          />
          {isSelectedConditionType2024 && (
            <FormikRadioButtons
              style={{ marginLeft: 48, marginTop: -2, marginBottom: 0 }}
              name="additionalItem.specificBetterSupporterCondition202404Sub"
              label=""
              options={SpecificBetterSupporterConditionTypeSub2024RadioItems}
            />
          )}
        </FormGroup>
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSystemType"
          label="福祉・介護職員処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSystemTypeRadioItems}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.staffTreatmentSpecificSystemType"
          label="福祉・介護職員等特定処遇改善加算（令和6年5月31日まで）"
          options={staffTreatmentSpecificSystemTypeRadioItems}
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikCheckbox
          name="additionalItem.betterSupporterConditionBaseUpFlg"
          label="福祉・介護職員等ベースアップ等支援加算（令和6年5月31日まで）"
          disabled={disableStaffTreatmentSpecificSystemType}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="calculationPossibleUntil20240531Tips" />
              }
            />
          }
        />
        <FormikRadioButtons
          name="additionalItem.nursingStaffPlacementSystemFlag"
          label="看護職員・医療連携に関する加算"
          options={NURSING_SUPPORT_RADIO_ITEMS_202104}
          tooltip={
            <HelpToolTip
              title={
                <HelpTipMessages name="nursingStaffPlacementSystemFlag_202104" />
              }
            />
          }
        />
      </FormGroup>
      <FormikSwitch
        name="additionalItem.nightSupportFlag"
        label="夜間支援等体制加算"
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="nightSupportFlag_202104" />}
          />
        }
      >
        {operatingUnitFlag ? (
          <UnitsNightSupportFields formikProps={props.formikProps} />
        ) : (
          <FormGroup row>
            <FormikSelect
              label="夜間支援等体制加算"
              name="additionalItem.nightSupportType"
              placeholder="選択してください"
              isSelectablePlaceholder
              options={NIGHT_SUPPORT_TYPE_OPTIONS_202104}
              style={{ width: 270 }}
            />
            <FormikTextField
              name="additionalItem.averageUsersLastYear"
              label="前年度の平均実績"
              endAdornmentLabel="人"
            />
          </FormGroup>
        )}
      </FormikSwitch>
      <FormGroup>
        <FormikCheckbox
          name="additionalItem.commuterLifeSupportFlag"
          label="通勤者生活支援加算"
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="commuterLifeSupportFlag" />}
            />
          }
        />

        {/* 視覚・聴覚言語障害者支援体制加算 */}
        <SeeHeareTeam202404Fields
          formikProps={props.formikProps}
          tooltip={
            <HelpToolTip
              title={<HelpTipMessages name="seeHearTeam202404Tips" />}
            />
          }
        />
        <FormikSwitch
          name="additionalItem.higherBrainDysfunctionPersonsSupportFlg"
          label="高次脳機能障害者支援体制加算"
          onChange={onChangeSwitch}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.higherBrainDysfunctionPersonsSupportTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikSwitch
          name="additionalItem.improvingInfectionControl1Flg"
          label="障害者支援施設等感染対策向上加算（Ⅰ）"
          onChange={onChangeSwitch}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl1From"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl1To"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikSwitch
          name="additionalItem.improvingInfectionControl2Flg"
          label="障害者支援施設等感染対策向上加算（Ⅱ）"
          onChange={onChangeSwitch}
        >
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl2From"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
          <FormikSelectDateNotSelectedDefault
            name="additionalItem.improvingInfectionControl2To"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormikSwitch>
        <FormikRadioButtons
          name="additionalItem.personnelDistributionType"
          label="人員配置体制加算"
          options={personnelDistributionTypeRadioItems}
        />
      </FormGroup>
    </FormPaper>
  );
};

export default AdditionalItemFields;
