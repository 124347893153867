import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RouteComponentProps } from "react-router-dom";
import {
  WithStyles,
  withStyles,
  createStyles,
  StyleRules
} from "@material-ui/core/styles";
import { Formik, Form, FormikActions } from "formik";
import {
  initialValues,
  UsersValues
} from "@initialize/mgr/DOKOENGO/users/initialValues";
import { validation } from "@initialize/mgr/DOKOENGO/users/validation";
import Button from "@material-ui/core/Button";
import ContentHeaderRight from "@components/molecules/ContentHeaderRight";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import ContentHeader from "@components/organisms/mgr/ContentHeader";
import { BasicFields } from "@components/organisms/mgr/DOKOENGO/Users/BasicFields";
import { ServiceUseFields } from "@components/organisms/mgr/DOKOENGO/Users/ServiceUseFields";
import { CarePlanFields } from "@components/organisms/mgr/DOKOENGO/Users/CarePlanFields";
import { RecipientCertificateFields } from "@components/organisms/mgr/DOKOENGO/Users/RecipientCertificateFields";
import dispatches from "@stores/dispatches";
import { AppState } from "@stores/type";
import { UsersInFacilityState } from "@stores/domain/mgr/DOKOENGO/userInFacility/types";
import { CityState, CityParams } from "@stores/domain/city/type";
import { SnackbarParams } from "@stores/ui/type";
import { toEffectiveObject } from "@utils/object";
import ConfirmDialog from "@components/atoms/ConfirmDialog";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import { SECONDARY_BLUE_COLOR } from "@constants/styles";
import { FacilityState } from "@stores/domain/mgr/DOKOENGO/facility/types";
import * as URL from "@constants/url";
import isEqual from "lodash-es/isEqual";
import * as H from "history";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      height: 60,
      top: 0,
      "& > div": {
        minHeight: 60
      },
      "&+section": {
        marginTop: 8
      }
    },
    deleteButton: {
      boxShadow: "none",
      color: SECONDARY_BLUE_COLOR,
      backgroundColor: "rgba(98, 2, 238, 0)",
      padding: "6px 6px 6px 0px",
      marginLeft: 16
    },
    deleteOutline: {
      color: SECONDARY_BLUE_COLOR
    }
  });

type OwnProps = RouteComponentProps<{ id: string }> & WithStyles<typeof styles>;

type DispatchProps = {
  updateUser: (
    values: UsersValues,
    history: H.History,
    params: UsersInFacilityState["user"]
  ) => void;
  fetchOne: (id: string) => void;
  fetchCarePlan: (uifId: string) => Promise<void>;
  fetchCity: (params: CityParams) => void;
  showSnackbar: (params: SnackbarParams) => void;
  deleteUser: (id: string, history: H.History) => void;
  fetchFacility: () => void;
  stopHistory: (flag: boolean) => void;
};

type StateProps = {
  facility: FacilityState;
  cityList: CityState[];
  userInFacility: UsersInFacilityState;
  needsStopHistory: boolean;
};

type MergeProps = DispatchProps & StateProps & OwnProps;

type State = {
  initialValues: UsersValues;
  isFetchDone: boolean;
  openModal: boolean;
};

class EditUserFormCore extends React.Component<MergeProps, State> {
  constructor(props: MergeProps) {
    super(props);
    this.state = {
      initialValues: initialValues(),
      isFetchDone: false,
      openModal: false
    };
  }

  public async componentDidMount(): Promise<void> {
    const { id } = this.props.match.params;
    await this.props.fetchFacility();
    await this.props.fetchOne(id);
    if (this.props.userInFacility.user.user_in_facility.id) {
      await this.props.fetchCarePlan(
        this.props.userInFacility.user.user_in_facility.id.toString()
      );
    }
    this.setState({
      initialValues: initialValues(this.props.userInFacility.user)
    });
    const prefectureName = this.props.userInFacility.user.user_in_facility
      .prefecture_name;
    if (prefectureName) {
      await this.props.fetchCity({ prefectureName });
    }
    this.setState({ isFetchDone: true });
  }

  private validate = (values: UsersValues): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!this.props.needsStopHistory) {
      this.confirmDiscardFormChanges(values);
    }
    return error;
  };

  private submitError = (): void => {
    this.props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  private onClear = (): void => {
    this.setState({ openModal: true });
  };

  private handleCancel = (): void => {
    this.setState({ openModal: false });
  };

  private onDelete = (): void => {
    const id = `${this.props.userInFacility.user.user_in_facility.id}`;
    this.props.deleteUser(id, this.props.history);
  };

  private onSubmit = async (
    values: UsersValues,
    actions: FormikActions<UsersValues>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await this.props.updateUser(
      values,
      this.props.history,
      this.props.userInFacility.user
    );
    actions.setSubmitting(false);
  };

  private onCancel = (): void => {
    this.props.history.push(URL.USERS);
  };

  private confirmDiscardFormChanges(nextValues: UsersValues): void {
    const hasChange = !isEqual(nextValues, this.state.initialValues);
    if (hasChange) {
      this.props.stopHistory(true);
    }
  }

  public render(): JSX.Element {
    const { name_sei } = this.props.userInFacility.user.user_in_facility;
    const { name_mei } = this.props.userInFacility.user.user_in_facility;
    return (
      <Formik
        initialValues={this.state.initialValues}
        validate={this.validate}
        onSubmit={this.onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => (
          <Form>
            <ContentHeader
              position="sticky"
              classes={{ wrapper: this.props.classes.wrapper }}
            >
              <ContentHeaderRight mediaOff>
                <KnowbeButton
                  kind="outline"
                  style={{ position: "absolute" }}
                  onClick={this.onCancel}
                >
                  一覧に戻る
                </KnowbeButton>
                <FormikSubmitButton
                  buttonName="保存する"
                  formikProps={formikProps}
                  errorAction={this.submitError}
                />
              </ContentHeaderRight>
            </ContentHeader>
            {/* 基本情報 */}
            <BasicFields
              formikProps={formikProps}
              setFormikFieldValue={formikProps.setFieldValue}
            />
            {/* サービス利用詳細 */}
            <ServiceUseFields
              formikProps={formikProps}
              isFetchDone={this.state.isFetchDone}
              facility={this.props.facility}
              setFormikFieldValue={formikProps.setFieldValue}
            />
            {/* サービス予定 */}
            <CarePlanFields
              initialFlg={false}
              isFetchDone={this.state.isFetchDone}
              formikProps={formikProps}
              uifId={
                this.props.userInFacility.user.user_in_facility.id
                  ? this.props.userInFacility.user.user_in_facility.id
                  : 0
              }
              nameSei={
                this.props.userInFacility.user.user_in_facility.name_sei
                  ? this.props.userInFacility.user.user_in_facility.name_sei
                  : ""
              }
              nameMei={
                this.props.userInFacility.user.user_in_facility.name_mei
                  ? this.props.userInFacility.user.user_in_facility.name_mei
                  : ""
              }
            />
            {/* 受給者証の詳細 */}
            <RecipientCertificateFields
              setFormikFieldValue={formikProps.setFieldValue}
            />
            <Button
              className={this.props.classes.deleteButton}
              variant="contained"
              onClick={this.onClear}
            >
              <DeleteOutline className={this.props.classes.deleteOutline} />
              削除する
            </Button>
            <ConfirmDialog
              isOpen={this.state.openModal}
              onDelete={this.onDelete}
              onCancel={this.handleCancel}
              title={`${name_sei}${name_mei}さんの利用者情報を削除しますか？`}
              message={`${name_sei}${name_mei}さんの利用者情報を削除します。削除すると過去に登録した「利用実績」などのすべてのデータが完全に削除され、復元できません。削除してよろしいですか？`}
            />
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = (state: AppState): StateProps => ({
  facility: state.DOKOENGO.facility,
  cityList: state.city,
  userInFacility: state.DOKOENGO.userInFacility,
  needsStopHistory: state.ui.needsStopHistory
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { DOKOENGO, cityDispatch, uiDispatch } = dispatches;
  const userInFacilityDispatcher = DOKOENGO.userInFacilityDispatcher(dispatch);
  const facilityDispatcher = DOKOENGO.facilityDispatcher(dispatch);
  const cityDispatches = cityDispatch(dispatch);
  const uiDispatches = uiDispatch(dispatch);
  const carePlanDispatcher = DOKOENGO.carePlanDispatcher(dispatch);

  return {
    fetchFacility: facilityDispatcher.fetch,
    updateUser: userInFacilityDispatcher.update,
    fetchOne: userInFacilityDispatcher.fetchOne,
    deleteUser: userInFacilityDispatcher.deleteUser,
    fetchCity: async (params: CityParams): Promise<void> => {
      await cityDispatches.fetch({
        prefectureName: params.prefectureName
      });
    },
    fetchCarePlan: (uifId: string): Promise<void> =>
      carePlanDispatcher.fetchCarePlan(uifId),
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatches.snackbar(params),
    stopHistory: uiDispatches.stopHistory
  };
};

export const EditUserForm = withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditUserFormCore)
);
