import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export interface PostOperationsParams {
  operation: {
    operation_records_id: number | null;
    operation_in_the_morning?: string | null;
    operation_in_the_afternoon?: string | null;
    other_comment?: string | null;
    staffs?:
      | {
          staff_id: number;
          staff_name: string;
        }[]
      | null;
  } | null;
}

/**
 * 指定日の業務記録を同時に更新する
 * 更新しないデータにはnullを送る
 * @param params 更新データ
 * @param yyyymmdd 日付
 */
const postOperations = async (
  params: PostOperationsParams,
  yyyymmdd: string
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/operations/${yyyymmdd}`;
  return request.post(url, params);
};

export default postOperations;
