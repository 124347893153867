import * as React from "react";
import { createStyles, WithStyles } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { getLabelFromOptions } from "@/utils/dataNormalizer";
import {
  CHIIKIIKO_DISPLAY_STATUS_LIST,
  CHIIKIIKO_DISPLAY_TRIAL_USE_TYPE_LIST,
  CHIIKIIKO_DISPLAY_TRIAL_STAY_TYPE_LIST
} from "@constants/mgr/CHIIKIIKO/variables";
import Edit from "@material-ui/icons/Edit";
import { InoutConsultationResultsState } from "@stores/domain/mgr/CHIIKIIKO/report/types";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKIIKO/userInFacility/types";
import format from "date-fns/format";

const styles = (): StyleRules =>
  createStyles({
    superShortIconCell: {
      minWidth: 32,
      width: 32,
      boxSizing: "content-box",
      lineHeight: "10px",
      "&:last-child": {
        paddingRight: 4
      },
      paddingLeft: 4,
      paddingRight: 4
    },
    shortMiddleCell: {
      minWidth: 41,
      width: 41,
      boxSizing: "content-box",
      lineHeight: "28px",
      "&:last-child": {
        paddingRight: 16
      }
    },
    longCell: {
      minWidth: 88,
      width: 88,
      boxSizing: "content-box",
      lineHeight: "28px"
    },
    icon: {
      textAlign: "center"
    },
    baseCell: {
      paddingLeft: 4,
      paddingRight: 4,
      lineHeight: "20px"
    },
    iconDiv: {
      height: 26
    }
  });

type OwnProps = {
  params: InoutConsultationResultsState;
  idx: string;
  classOption: string;
  openModal: (param: InoutConsultationResultsState) => void;
  usersInFacilityState: UsersInFacilityState;
};

type Props = OwnProps & WithStyles<typeof styles>;

const InOutReportTableCellsCore = (props: Props): JSX.Element => {
  const { params, idx, classes, usersInFacilityState } = props;
  const openEditModal = (): void => {
    props.openModal(params);
  };

  const serviceStartDate = usersInFacilityState.user.user_in_facility
    .date_begin_in_service
    ? usersInFacilityState.user.user_in_facility.date_begin_in_service
    : "";
  const serviceEndDate = usersInFacilityState.user.user_in_facility
    .date_end_in_service
    ? usersInFacilityState.user.user_in_facility.date_end_in_service
    : "";
  const serviceEndAdd30days = new Date(serviceEndDate);
  serviceEndAdd30days.setDate(serviceEndAdd30days.getDate() + 30);
  const startDate = Number(format(serviceStartDate, "YYYYMMDD"));
  const endDate = serviceEndDate
    ? Number(format(serviceEndAdd30days, "YYYYMMDD"))
    : "";
  const targetDate = Number(format(params.targetDate, "YYYYMMDD"));

  return (
    <>
      {/* 支援内容 */}
      <TableCellWrap
        key={`${idx}-status`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.status
          ? getLabelFromOptions(
              params.status.toString(),
              CHIIKIIKO_DISPLAY_STATUS_LIST
            )
          : "-"}
      </TableCellWrap>

      {/* 体験利用 */}
      <TableCellWrap
        key={`${idx}-trial-use-type`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.trialUseType
          ? getLabelFromOptions(
              params.trialUseType.toString(),
              CHIIKIIKO_DISPLAY_TRIAL_USE_TYPE_LIST
            )
          : "-"}
      </TableCellWrap>

      {/* 体験宿泊 */}
      <TableCellWrap
        key={`${idx}-trial-stay-type`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.trialStayType
          ? getLabelFromOptions(
              params.trialStayType.toString(),
              CHIIKIIKO_DISPLAY_TRIAL_STAY_TYPE_LIST
            )
          : "-"}
      </TableCellWrap>

      {/* 初回 */}
      <TableCellWrap
        key={`${idx}-first-addition-flg`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.firstAdditionFlg ? "あり" : "-"}
      </TableCellWrap>

      {/* 居住支援連携体制 */}
      <TableCellWrap
        key={`${idx}-housing-support-cooperation-flg`}
        cellClass={`${classes.shortMiddleCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.housingSupportCooperationFlg ? "あり" : "-"}
      </TableCellWrap>

      {/* 地域居住支援体制強化推進 */}
      <TableCellWrap
        key={`${idx}-housing-support-promotion-flg`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.housingSupportPromotionFlg ? "あり" : "-"}
      </TableCellWrap>

      {/* 退院・退所月 */}
      <TableCellWrap
        key={`${idx}-discharge-month-addition-flg`}
        cellClass={`${classes.longCell} ${classes.baseCell} ${props.classOption}`}
      >
        {params.dischargeMonthAdditionFlg ? "あり" : "-"}
      </TableCellWrap>

      {/* 編集ボタン */}
      <TableCellWrap
        key={`${idx}-edit-icon`}
        cellClass={`${classes.superShortIconCell} ${classes.baseCell} ${classes.icon} ${props.classOption}`}
        align="right"
      >
        {!(targetDate < startDate || (endDate && targetDate > endDate)) && (
          <div className={props.classes.iconDiv}>
            <Edit
              style={{
                width: 24,
                height: 24,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={openEditModal}
            />
          </div>
        )}
      </TableCellWrap>
    </>
  );
};

export const InOutReportTableCells = withStyles(styles)(
  InOutReportTableCellsCore
);
