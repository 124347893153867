import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

/**
 * getSupportProcedureDetailのAPIResponse
 */
export type GetSupportProcedureDetailResponse = {
  data: {
    service_delivery_records_id: number | null;
    inout_results_id: number | null;
    support_procedure_forms_id: number | null;
    users_in_facility_id: number;
    target_date: string | null;
    created_at: string;
    updated_at: string;
    title: string;
    number_of_practitioner: number;
    author: {
      id: number;
      license: number;
      license_name: string;
      name: string;
      role: string;
      facility_id: number;
      snapshot_license_name: string | null;
      snapshot_name: string;
      snapshot_role: string;
    } | null;
    support_procedure_form_practitioners: {
      support_procedure_form_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner: {
        id: number;
        name: string;
        role: string;
        license: number;
        license_name: string | null;
        facility_id: number;
        snapshot_name: string;
        snapshot_license_name: string | null;
      } | null;
      practitioner_num: number;
    }[];
    support_procedure_form_other_practitioners: {
      support_procedure_form_other_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner_name: string;
      facility_name: string;
    }[];
    support_procedure_form_details: {
      support_procedure_form_details_id: number | null;
      start_time: string | null;
      end_time: string | null;
      activity: string;
      service_procedure: string;
      check: number;
      appearance: string;
    }[];
    contact_information_plan: string | null;
    inquiry_items_plan: string | null;
    contact_information_record: string | null;
    inquiry_items_record: string | null;
    procedure_status_flg: number;
  };
};

export type PostSupportProcedureDetailValidationErrorResponse = {
  data: null;
  response: {
    code: 17; // codeは17の場合がバリデーションエラーなので固定
    msg: string;
    validation: {
      start_time_1?: string[];
      end_time_1?: string[];
      start_time_2?: string[];
      end_time_2?: string[];
    };
  };
};

/**
 * 対象IDの支援手順書兼記録用紙を取得する
 */
export const getSupportProcedureDetail = async (
  serviceDeliveryRecordsId?: string,
  inoutResultsId?: string,
  supportProcedureFormsId?: string
): Promise<AxiosResponse<GetSupportProcedureDetailResponse>> => {
  let query = "";
  if (serviceDeliveryRecordsId) {
    query += `&service_delivery_records_id=${serviceDeliveryRecordsId}`;
  }

  if (inoutResultsId) {
    query += `&inout_results_id=${inoutResultsId}`;
  }

  if (supportProcedureFormsId) {
    query += `&support_procedure_forms_id=${supportProcedureFormsId}`;
  }

  // リクエストパラメータが1つでもある場合は先頭の&を?に置き換える
  query = query && `?${query.slice(1)}`;

  const url = `${VERSION_URL_201910}/support_procedure/detail`;
  return request.get<GetSupportProcedureDetailResponse>(`${url}${query}`);
};
