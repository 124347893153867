import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AdminRoute from "@app/Auth/AdminRoute";
import * as URL from "@constants/url";

// サービス提供
import { ServiceDeliveryDailyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryDaily";
import { ServiceDeliveryMonthlyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryMonthly";
import { ServiceDeliveryEditDailyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryEditDaily";
import { ServiceDeliveryEditMonthlyMobile } from "@componentsMobile/pages/record/serviceDelivery/ServiceDeliveryEditMonthly";

/**
 * 同行援護でログインした時だけ有効になるルーティング
 */
export const DOKOENGORoutes = (): JSX.Element => (
  <Switch>
    {/* サービス提供記録 */}
    <AdminRoute
      exact
      path={`${URL.RECORD_SERVICE_DELIVERY_DAILY}/:date?`}
      component={ServiceDeliveryDailyMobile}
    />
    <AdminRoute
      exact
      path={`${URL.RECORD_SERVICE_DELIVERY_MONTHLY}/:uifId/:date`}
      component={ServiceDeliveryMonthlyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId"
      component={ServiceDeliveryEditDailyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/edit/:serviceDeliveryRecordsId/:inoutResultsId/:uifId/monthly"
      component={ServiceDeliveryEditMonthlyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/service_delivery/new"
      component={ServiceDeliveryEditDailyMobile}
    />
    <AdminRoute
      exact
      path="/record/:date/:uifId/service_delivery/new"
      component={ServiceDeliveryEditMonthlyMobile}
    />
    {/* default path */}
    <Route path="/">
      <Redirect to={`${URL.RECORD_SERVICE_DELIVERY_DAILY}`} />
    </Route>
  </Switch>
);
