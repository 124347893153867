import * as types from "./types";
import * as actions from "./actions";

export const initialState: types.SupportLedgerState = {
  supportLedger: {
    created_at: null,
    updated_at: null,
    support_ledger: {
      creation_date: "",
      creation_staff: null,
      update_date: "",
      update_staff: null,
      support_ledgers_id: null,
      date_begin_in_support: null,
      date_end_in_support: null,
      is_ignore_print_support_ids: [],
      support_ledger_items: []
    }
  },
  supportLedgerList: []
};

export const CHIIKITEICHAKUSupportLedger = (
  state = initialState,
  action: actions.ActionTypes
): types.SupportLedgerState => {
  switch (action.type) {
    case types.FETCH_SUPPORT_LEDGER_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_LEDGER_SUCCESS:
      return { ...state, supportLedger: action.payload };
    case types.FETCH_SUPPORT_LEDGER_FAILED:
      return { ...state };
    case types.FETCH_SUPPORT_LEDGER_LIST_STARTED:
      return { ...state };
    case types.FETCH_SUPPORT_LEDGER_LIST_SUCCESS:
      return { ...state, supportLedgerList: action.payload };
    case types.FETCH_SUPPORT_LEDGER_LIST_FAILED:
      return { ...state };
    case types.POST_SUPPORT_LEDGER_STARTED:
      return { ...state };
    case types.POST_SUPPORT_LEDGER_SUCCESS:
      return { ...state };
    case types.POST_SUPPORT_LEDGER_FAILED:
      return { ...state };
    case types.UNSET_SUPPORT_LEDGER:
      return { ...state, ...initialState };
    case types.DELETE_SUPPORT_LEDGER_STARTED:
      return { ...state };
    case types.DELETE_SUPPORT_LEDGER_SUCCESS:
      return { ...state };
    case types.DELETE_SUPPORT_LEDGER_FAILED:
      return { ...state };
    default:
      return state;
  }
};
