import * as React from "react";
import * as ClassNames from "classnames";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import { ConsultationState } from "@stores/domain/mgr/KEIKAKUSODAN/consultation/types";
import { SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE } from "@constants/variables";
import { printSchedules } from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";

const styles = (): StyleRules =>
  createStyles({
    calendar: {
      display: "flex",
      justifyContent: "flex-start",
      overflow: "hidden",
      position: "relative",
      "&::before": {
        content: "'　'",
        display: "block",
        width: "100%",
        height: "100%",
        border: "2px solid #212121",
        top: 0,
        left: 0,
        position: "absolute",
        zIndex: 10
      },
      "& .fc": {
        width: "calc(100% - 210px)"
      },
      "& .fc-view-harness": {
        marginBottom: "-30px"
      },
      "& .fc-scrollgrid>thead": {
        "@media print": {
          height: "21px",
          display: "table-row-group"
        }
      },
      "& .fc-timegrid-body": {
        overflow: "hidden"
      },
      "& table.fc-scrollgrid": {
        pointerEvents: "none",
        borderColor: "#212121"
      },
      "& .fc-col-header-cell-cushion": {
        display: "block",
        padding: 0
      },
      "& th.fc-col-header-cell": {
        fontSize: 10,
        lineHeight: 1.4,
        letterSpacing: "0.5px",
        padding: "3px 0",
        color: " #212121",
        fontWeight: "normal"
      },
      "& .fc-theme-standard th , & .fc-theme-standard td": {
        borderColor: "#212121"
      },
      "& td.fc-timegrid-slot-lane": {
        height: 24,
        borderTopStyle: "dotted",
        borderTopColor: "#979797"
      },
      "& .fc-timegrid-slot-label": { border: "none" },
      "& .fc-timegrid-slot-label-frame": {
        position: "relative",
        height: "100%"
      },
      "& .fc-timegrid-slot-label-frame::before": {
        content: "' '",
        display: "block",
        position: "absolute",
        width: 4,
        height: 2,
        background: "#212121",
        top: -1,
        right: 0
      },
      "& .fc-timegrid-slot-label-cushion": {
        fontSize: 10,
        lineHeight: 1,
        letterSpacing: "0.5px",
        transform: "scale(0.8)",
        transformOrigin: "right",
        position: "absolute",
        top: 2,
        right: 9,
        padding: 0
      },
      "& .fc-timegrid-event": {
        borderWidth: "1px",
        "& .fc-event-main": {
          padding: "2px 1px"
        }
      },
      "& .fc-event-title-container": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      },
      "& .fc-event-title": {
        fontSize: 10,
        lineHeight: 1,
        fontWeight: "bold",
        transform: "scale(0.7)",
        transformOrigin: "left",
        width: "calc(100% / 0.7)",
        overflow: "hidden",
        textAlign: "center",
        wordBreak: "break-all",
        display: "-webkit-box",
        "-webkit-box-orient": "vertical"
      },
      "& .line-clamp--1 .fc-event-title": {
        "-webkit-line-clamp": 1
      },
      "& .line-clamp--2 .fc-event-title": {
        "-webkit-line-clamp": 2
      },
      "& .line-clamp--3 .fc-event-title": {
        "-webkit-line-clamp": 3
      },
      "& .line-clamp--4 .fc-event-title": {
        "-webkit-line-clamp": 4
      },
      "& .fc-timegrid-event.fc-event-start": {
        borderBottom: "none",
        height: "calc(100% + 1px)",
        position: "relative",
        "&::after": {
          display: "block",
          content: "' '",
          height: "1890px",
          background: "rgb(245, 245, 245)",
          width: "calc(100% + 2px)",
          transform: "translateX(-1px)",
          borderColor: "#212121",
          borderStyle: "solid",
          borderWidth: "0 1px 0 1px",
          boxShadow: "0px 1px 0px 1px #fff",
          "@media print": {
            height: "357px",
            borderColor: "#212121"
          }
        }
      },
      "& .fc-timegrid-event.fc-event-end": {
        borderTop: "none"
      },
      "& .fc-timegrid-event.fc-event-start.fc-event-end": {
        border: "1px solid #212121",
        "&::after": {
          display: "none"
        }
      },
      "& colgroup col": {
        width: "44px!important"
      }
    },
    calendarText: {
      position: "absolute",
      top: 4,
      left: 10,
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: "#212121"
    },
    comment: {
      width: "calc(100% - 835px)",
      border: "1px solid #212121",
      borderLeft: "none",
      fontSize: 10,
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      color: " #212121",
      fontWeight: "normal",
      marginLeft: "1px"
    },
    commentTitle: {
      padding: "3px 0",
      display: "block",
      textAlign: "center",
      borderBottom: "1px solid #212121",
      marginLeft: "-2px"
    },
    commentCon: {
      padding: 4,
      wordBreak: "break-word"
    },
    MT16: {
      marginTop: 16
    }
  });

type OwnProps = {
  consultation: ConsultationState["consultationRecord"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

const SchedulePrintContentCore = (props: Props): JSX.Element => {
  const { classes, consultation } = props;
  const scheduleData = consultation.supportPlanConsultationSchedule;

  // calendarの時間軸用
  const [minTime, setMinTime] = React.useState<string>("00:00:00");
  const [maxTime, setMaxTime] = React.useState<string>("24:00:00");
  // calendarの高さ用
  const [remarksWrapHeight, setRemarksWrapHeight] = React.useState<number>(0);
  const [calendarHeight, setcalendarHeight] = React.useState<number>(0);

  // calendar用データ整理
  const printSchedulesData = printSchedules(scheduleData);

  React.useEffect(() => {
    // 「主な日常生活上の活動」の高さ
    const mainActivities = document.getElementById("mainActivities");
    const nonWeeklyService = document.getElementById("nonWeeklyService");

    const remarksHeader = 23;
    if (mainActivities && nonWeeklyService) {
      setRemarksWrapHeight(
        Math.floor(
          mainActivities.clientHeight +
            nonWeeklyService.clientHeight +
            remarksHeader
        )
      );
    }

    const calendar = { border: 1, head: 20, item: 24 };
    let minTimeValue;
    let maxTimeValue;

    if (printSchedulesData.length === 0) {
      minTimeValue = 0;
      maxTimeValue =
        Math.ceil(remarksWrapHeight / calendar.item) > 24
          ? 24
          : Math.ceil(remarksWrapHeight / calendar.item);
    } else {
      const timeData: number[] = [];
      scheduleData.forEach((item) => {
        if (item.start_time && item.end_time) {
          timeData.push(Number(item.start_time.split(":")[0]));
          timeData.push(Number(item.end_time.split(":")[0]));
        }
        minTimeValue = Math.min(...timeData) - 2;
        maxTimeValue = Math.max(...timeData) + 2;
      });
    }

    if (
      !(minTimeValue === Infinity || minTimeValue === undefined) &&
      !(maxTimeValue === Infinity || maxTimeValue === undefined)
    ) {
      if (minTimeValue < 0) {
        setMinTime("00:00:00");
        minTimeValue = 0;
      } else if (minTimeValue > 0 && minTimeValue < 10) {
        setMinTime(`0${String(minTimeValue)}:00:00`);
      } else {
        setMinTime(`${String(minTimeValue)}:00:00`);
      }

      if (maxTimeValue > 24) {
        setMaxTime("24:00:00");
        maxTimeValue = 24;
      } else if (maxTimeValue < 10) {
        setMaxTime(`0${String(maxTimeValue)}:00:00`);
      } else {
        setMaxTime(`${String(maxTimeValue)}:00:00`);
      }
    }

    setcalendarHeight(
      Math.floor(
        calendar.head +
          ((maxTimeValue || 0) - (minTimeValue || 0)) *
            (calendar.item + calendar.border) +
          calendar.border * 2
      )
    );
  }, [printSchedulesData]);

  return (
    <div className={ClassNames(classes.calendar, classes.MT16)}>
      <div className={classes.calendarText}>時刻</div>
      <FullCalendar
        locale="ja"
        plugins={[timeGridPlugin]}
        height={remarksWrapHeight > calendarHeight ? undefined : calendarHeight}
        contentHeight={
          remarksWrapHeight > calendarHeight
            ? remarksWrapHeight
            : calendarHeight
        }
        headerToolbar={false}
        slotDuration="01:00:00"
        slotLabelInterval="02:00"
        slotMinTime={minTime}
        slotMaxTime={maxTime}
        slotEventOverlap={false}
        slotLabelFormat={{
          hour: "2-digit",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: false,
          hour12: false
        }}
        dayHeaderFormat={(date): string => {
          const days = ["日", "月", "火", "水", "木", "金", "土"];
          return days[date.date.marker.getDay()];
        }}
        displayEventTime={false}
        eventBackgroundColor="#f5f5f5"
        eventColor="#212121"
        eventTextColor="#212121"
        eventBorderColor="#212121"
        firstDay={1}
        allDaySlot={false}
        initialDate={`${SUPPORT_CARE_PLAN_PRINT_CALENDAR_DATE}1`}
        navLinks={false}
        editable
        dayMaxEvents
        events={printSchedulesData}
        eventDidMount={(item): void => {
          // 内容の３点リーダー
          const height = item.el.clientHeight;
          if (height < 24) {
            item.el.classList.add("line-clamp--1");
          } else if (height >= 24 && height < 34) {
            item.el.classList.add("line-clamp--2");
          } else if (height >= 34 && height < 74) {
            item.el.classList.add("line-clamp--3");
          } else {
            item.el.classList.add("line-clamp--4");
          }
        }}
      />
      <div className={classes.comment}>
        <span className={classes.commentTitle}>主な日常生活上の活動</span>
        <div
          className={classes.commentCon}
          style={{ borderBottom: "1px solid #212121", marginLeft: "-1px" }}
          id="mainActivities"
        >
          {consultation.mainActivities}
        </div>
        <div id="nonWeeklyService">
          <span className={classes.commentTitle}>週単位以外のサービス</span>
          <div className={classes.commentCon}>
            {consultation.nonWeeklyService}
          </div>
        </div>
      </div>
    </div>
  );
};

export const SchedulePrintContent = withStyles(styles)(
  SchedulePrintContentCore
);
