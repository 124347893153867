import { getAssessment } from "./getAssessment";
import { getAssessmentList } from "./getAssessmentList";
import { postAssessment } from "./postAssessment";
import { deleteAssessment } from "./deleteAssessment";

export const assessmentApi = {
  getAssessment,
  getAssessmentList,
  postAssessment,
  deleteAssessment
};
