/**
 * 算定時間の計算(移動支援)
 *
 * 算定時間は30分ごとに「0.5」として丸める
 * →自治体情報画面で端数処理設定を行っている場合は、30分に対して設定された時間を超えていた場合は切り上げる。超えていなかった場合は切下げる。
 * →端数処理設定を行っていない場合は、30分に満たない端数は切り捨てる（自治体情報を設定していない利用者も同様）
 */
export const calculateHours = (
  base: number,
  roundUpMinute: number | null,
  other = 0
): number => {
  const totalTime = base - other;
  const result = Math.floor(totalTime / 60);
  let decimalPart = totalTime % 60;

  if (result <= 0 && decimalPart <= 0) return 0;

  if (roundUpMinute) {
    if (decimalPart <= 0 || decimalPart < roundUpMinute) {
      decimalPart = 0;
    } else if (
      decimalPart >= roundUpMinute &&
      decimalPart < 30 + roundUpMinute
    ) {
      decimalPart = 30;
    } else if (decimalPart >= 30 + roundUpMinute) {
      decimalPart = 60;
    }
  } else if (decimalPart < 30) {
    decimalPart = 0;
  } else if (decimalPart >= 30 && decimalPart < 60) {
    decimalPart = 30;
  } else if (decimalPart === 60) {
    decimalPart = 60;
  }

  return result + decimalPart / 60;
};
