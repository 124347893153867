import * as React from "react";

import AddIcon from "@material-ui/icons/Add";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { FieldArray, FormikProps } from "formik";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

import {
  generateRelatedOrganizationInitialValues,
  SupportLedger,
  RelatedOrganization
} from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { RELATED_ORGANIZATION_TARGET_TYPE } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { getRelatedOrganizationTargetType } from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";

import RecordTextField from "@components/organisms/mgr/common/record/RecordTextField";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";

const styles = (): StyleRules =>
  createStyles({
    wrapper: {
      marginTop: "32px",
      paddingBottom: "16px"
    },
    card: {
      margin: "4px 0 8px",
      border: "1px solid #bdbdbd",
      borderRadius: "4px",
      padding: "24px 32px"
    },
    cardLabel: {
      fontSize: "14px",
      lineHeight: 1.71,
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0
    }
  });

type OwnProps = {
  relatedOrganizationData: RelatedOrganization[];
  name: string;
  label: string;
  defaultItem: number;
  formikProps: FormikProps<SupportLedger>;
  isEditing: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const firstLabel: {
  [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
} = {
  0: "施設・会社名",
  1: "施設・医療機関名",
  2: "施設・会社名",
  3: "施設・会社名"
};

const secondLabel: {
  [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
} = {
  0: "種別",
  1: "診察科・部門",
  2: "種類",
  3: "種別・種類"
};

const firstPlaceholder: {
  [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
} = {
  0: "〇〇事業所",
  1: "〇〇病院",
  2: "〇〇電力",
  3: ""
};

const secondPlaceholder: {
  [key in typeof RELATED_ORGANIZATION_TARGET_TYPE[keyof typeof RELATED_ORGANIZATION_TARGET_TYPE]]: string;
} = {
  0: "〇〇支援 等",
  1: "〇〇科 等",
  2: "電気・ガス 等",
  3: ""
};

const SupportLedgerRelatedOrganizationCore = ({
  classes,
  ...props
}: Props): JSX.Element => {
  const targetType = getRelatedOrganizationTargetType(props.defaultItem);
  return (
    <div className={classes.wrapper}>
      <FieldArray name={props.name}>
        {(arrayHelper): JSX.Element => {
          let displayDataCount = 0;
          const handleClickAddRelatedOrganization = (): void => {
            arrayHelper.push(
              generateRelatedOrganizationInitialValues(targetType)
            );
          };
          const handleClickDeleteRelatedOrganization = (
            index: number
          ): void => {
            const newData = Array.from(props.relatedOrganizationData);
            const targetData = newData[index];
            if (targetData.id) {
              newData[index].isDelete = true;
              newData[index].contact_address = "";
              newData[index].facility_name = "";
              newData[index].kinds = "";
              newData[index].manager = "";
              newData[index].remarks = "";
            } else {
              newData.splice(index, 1);
            }
            props.formikProps.setFieldValue(props.name, newData);
          };
          return (
            <>
              {props.relatedOrganizationData.map((data, index) => {
                if (data.isDelete) {
                  return null;
                }
                displayDataCount += 1;
                const key = `${props.name}_${index}`;
                return (
                  <div key={key} className={classes.card}>
                    <p className={classes.cardLabel}>
                      {props.label}
                      {displayDataCount}
                    </p>
                    <RecordTextField
                      label={firstLabel[data.target_type]}
                      value={data.facility_name || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].facility_name`}
                      isEditable={props.isEditing}
                      placeholder={firstPlaceholder[data.target_type]}
                      style={{ marginTop: "8px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label={secondLabel[data.target_type]}
                      value={data.kinds || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].kinds`}
                      isEditable={props.isEditing}
                      placeholder={secondPlaceholder[data.target_type]}
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="担当者"
                      value={data.manager || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].manager`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="連絡先"
                      value={data.contact_address || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].contact_address`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    <RecordTextField
                      label="備考"
                      value={data.remarks || ""}
                      labelType="default"
                      defaultValue=""
                      name={`${props.name}[${index}].remarks`}
                      isEditable={props.isEditing}
                      placeholder=""
                      style={{ marginTop: "32px", marginBottom: 0 }}
                    />
                    {props.isEditing ? (
                      <KnowbeButton
                        kind="iconText"
                        onClick={(): void =>
                          handleClickDeleteRelatedOrganization(index)
                        }
                        style={{ marginTop: "24px" }}
                      >
                        <DeleteOutlinedIcon />
                        削除する
                      </KnowbeButton>
                    ) : null}
                  </div>
                );
              })}
              {props.isEditing ? (
                <KnowbeButton
                  kind="iconText"
                  onClick={handleClickAddRelatedOrganization}
                  style={{ marginTop: "24px" }}
                  disabled={displayDataCount >= 20}
                >
                  <AddIcon />
                  {props.label}を追加する
                </KnowbeButton>
              ) : null}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const SupportLedgerRelatedOrganization = withStyles(styles)(
  SupportLedgerRelatedOrganizationCore
);
