import * as React from "react";
import FormikTextField, {
  FormikTextFieldProps
} from "@components/molecules/FormikTextField";

type Props = FormikTextFieldProps;

/**
 * 3桁目入力時(2 => 3)ハイフンを自動で付ける
 */
const handleChange = (
  event: React.ChangeEvent<
    HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
  >,
  beforeValue: string
): string => {
  const currentValue = event.currentTarget.value;
  if (beforeValue.length === 2 && currentValue.length === 3) {
    return `${currentValue}-`;
  }
  return currentValue;
};

/**
 * ハイフンなしの郵便番号形式の場合、ハイフンを自動で付ける
 */
const handleBlur = (event: React.FormEvent<HTMLInputElement>): string => {
  const currentValue = event.currentTarget.value;
  if (/^\d{7}$/.test(currentValue)) {
    return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
  }
  return currentValue;
};

const FormikPostalCode = ({ ...props }: Props): JSX.Element => (
  <FormikTextField
    {...props}
    onChangeHook={handleChange}
    onBlurHook={handleBlur}
  />
);

export default FormikPostalCode;
