import * as React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import ClassNames from "classnames";
import Button from "@material-ui/core/Button";
import IconAdd from "@material-ui/icons/Add";
import FormikTime from "@components/molecules/FormikTime";
import RecordSelect from "@components/organisms/mgr/common/record/RecordSelect";
import FormikTextField from "@components/molecules/FormikTextField";
import ReadonlyTextField from "@components/molecules/ReadonlyTextField";
import FormikMultipleSelect from "@components/molecules/FormikMultipleSelect";
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { FormikProps, FieldArray } from "formik";
import {
  ConsultationValues,
  initialSupportPlanConsultationSchedule
} from "@initialize/mgr/KEIKAKUSODAN/record/consultation/initialValues";
import {
  CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK,
  CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    headerCell: {
      fontSize: "12px",
      padding: "8px 16px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        paddingLeft: "16px "
      }
    },
    endTimeHeaderCell: {
      width: "152px"
    },
    cell: {
      padding: "16px 0px 16px 16px",
      fontSize: 16,

      "&:last-child": {
        paddingLeft: "16px "
      }
    },
    weekCell: {
      width: "218px"
    },
    startTimeCell: {
      width: "72px"
    },
    endTimeCell: {
      width: "152px",
      verticalAlign: "top"
    },
    endTimeWrap: {
      display: "flex"
    },
    nextDayFlg: {
      minWidth: "64px",
      maxWidth: "64px",
      marginRight: "16px"
    },
    contentCell: {
      width: "450px"
    },
    deleteCell: {
      width: "40px"
    },
    deleteIcon: {
      color: "#0277bd",
      cursor: "pointer",
      verticalAlign: "-webkit-baseline-middle"
    },
    dayOfWeek: {
      "&>div>div": {
        display: "block",
        whiteSpace: "nowrap"
      }
    },
    noDataMessage: {
      height: "48px",
      fontSize: "12px",
      lineHeight: "48px",
      color: "rgba(0, 0, 0, 0.6)",
      borderBottom: "1px solid #e0e0e0",
      padding: "0px 16px"
    },
    addBtn: {
      marginTop: "24px"
    }
  });

type ScheduleValuesType = ConsultationValues["supportPlanConsultationSchedule"];

type OwnProps = {
  formikName: string;
  formikProps: FormikProps<ConsultationValues>;
  scheduleValues: ScheduleValuesType;
  isEditing: boolean;
};
type Props = OwnProps & WithStyles<typeof styles>;

const WeeklyScheduleItemsCore = (props: Props): JSX.Element => {
  const { formikName, formikProps, scheduleValues, isEditing, classes } = props;

  const filteredScheduleValues = scheduleValues
    .map((item, i) => {
      return { value: item, baseIndex: i };
    })
    .filter((item) => !item.value.is_delete);

  const joinDayOfWeekLabel = (array: number[]): string => {
    return CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK.filter((option) =>
      array.includes(option.value)
    )
      .map((option) => option.label)
      .join("、");
  };

  const headData = [
    {
      label: "曜日",
      className: ClassNames(classes.headerCell, classes.weekCell)
    },
    {
      label: "開始時間",
      className: ClassNames(classes.headerCell, classes.startTimeCell)
    },
    {
      label: "終了時間",
      className: ClassNames(classes.headerCell, classes.endTimeHeaderCell)
    },
    {
      label: "内容",
      className: ClassNames(classes.headerCell, classes.contentCell)
    },
    {
      label: isEditing ? "削除" : "",
      className: ClassNames(classes.headerCell, classes.deleteCell)
    }
  ];

  const bodyValues = (): KnowbeTableCellParam[][] => {
    // 削除ボタン
    const onClickDelete = (index: number, baseIndex: number) => (): void => {
      formikProps.setFieldValue(`${formikName}[${baseIndex}].is_delete`, 1);
    };

    return filteredScheduleValues.map(
      (
        data: { value: OwnProps["scheduleValues"][0]; baseIndex: number },
        index
      ) => {
        const { value, baseIndex } = data;
        const daysLabel = joinDayOfWeekLabel(value.day_of_week);
        return [
          {
            label: isEditing ? (
              <FormikMultipleSelect
                name={`${formikName}[${baseIndex}].day_of_week`}
                label=""
                options={[
                  {
                    categoryName: "",
                    items: CARE_PLAN_SCHEDULES_DIALOG_DAY_OF_WEEK
                  }
                ]}
                placeholder="選択してください"
                style={{ marginBottom: 0, marginRight: 0, width: "100%" }}
                isNotShot
              />
            ) : (
              <ReadonlyTextField value={daysLabel} defaultValue="" />
            ),
            className: ClassNames(classes.cell, classes.weekCell)
          },
          {
            label: isEditing ? (
              <FormikTime
                name={`${formikName}[${baseIndex}].start_time`}
                label=""
                placeholder="00:00"
                maxLength={5}
                style={{ marginBottom: 0, marginRight: 0, width: "100%" }}
              />
            ) : (
              <ReadonlyTextField value={value.start_time} defaultValue="" />
            ),
            className: ClassNames(classes.cell, classes.startTimeCell)
          },
          {
            label: (
              <div className={classes.endTimeWrap}>
                <div className={classes.nextDayFlg}>
                  <RecordSelect
                    name={`${formikName}[${baseIndex}].next_day_flg`}
                    value={`${
                      CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG[
                        value.next_day_flg
                      ].label
                    }`}
                    options={CARE_PLAN_SCHEDULES_DIALOG_END_TIME_NEXT_DAY_FLG}
                    defaultValue=""
                    placeholder=""
                    isEditable={isEditing}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  {isEditing ? (
                    <FormikTime
                      name={`${formikName}[${baseIndex}].end_time`}
                      label=""
                      placeholder="00:00"
                      maxLength={5}
                      style={{
                        marginBottom: 0,
                        marginRight: 0,
                        width: "100%"
                      }}
                    />
                  ) : (
                    <ReadonlyTextField value={value.end_time} defaultValue="" />
                  )}
                </div>
              </div>
            ),
            className: ClassNames(classes.cell, classes.endTimeCell)
          },
          {
            label: isEditing ? (
              <FormikTextField
                name={`${formikName}[${baseIndex}].content`}
                label=""
                placeholder=""
                maxLength={20}
                size="medium"
                style={{
                  width: "auto",
                  marginBottom: "0px"
                }}
              />
            ) : (
              <ReadonlyTextField value={value.content} defaultValue="" />
            ),
            className: ClassNames(classes.cell, classes.contentCell)
          },
          {
            label: isEditing ? (
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={onClickDelete(index, baseIndex)}
              />
            ) : (
              <div
                style={{
                  width: 24
                }}
              />
            ),
            className: ClassNames(classes.cell, classes.deleteCell)
          }
        ];
      }
    );
  };

  const noDataMessage = isEditing
    ? "計画が登録されていません。「追加する」ボタンから登録してください。"
    : "計画が登録されていません";

  return (
    <div>
      <FieldArray name={formikName}>
        {(arrayHelpers): JSX.Element => {
          const onClickAddItem = (): void => {
            arrayHelpers.push(initialSupportPlanConsultationSchedule);
          };
          return (
            <>
              <KnowbeTable className={classes.table}>
                <KnowbeTableHead
                  uniqueKey="週間計画表ヘッダー"
                  height={40}
                  items={headData}
                  backgroundColor="secondary"
                />
                <KnowbeTableBody
                  uniqueKey="週間計画表ボディ"
                  itemsContainer={bodyValues()}
                />
              </KnowbeTable>
              {!filteredScheduleValues.length && (
                <div className={classes.noDataMessage}>{noDataMessage}</div>
              )}
              {isEditing && (
                <Button
                  className={classes.addBtn}
                  color="secondary"
                  onClick={onClickAddItem}
                  disabled={filteredScheduleValues.length >= 50}
                >
                  <IconAdd />
                  追加する
                </Button>
              )}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export const WeeklyScheduleItems = withStyles(styles)(WeeklyScheduleItemsCore);
