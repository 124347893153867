import React from "react";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";

// props
import * as H from "history";
import { DailyOperationsAndSupportsState } from "@stores/domain/mgr/SHISETSUNYUSHO/dailyOperationsAndSupports/types";
import { AppState } from "@stores/type";
import { StaffState } from "@stores/domain/staff/types";
import { RecordDailyState } from "@stores/pages/record/daily/types";
import { FieldItem } from "@interfaces/ui/form";

// store
import { connect } from "react-redux";
import { UsersInFacilityState } from "@stores/domain/mgr/SHISETSUNYUSHO/userInFacility/types";
import { CustomRecordsState } from "@stores/domain/customRecords/types";
import { Dispatch } from "redux";

// ui
import { DailyUsersRecordSupportTable } from "@components/organisms/mgr/SHISETSUNYUSHO/record/DailyUsersRecordSupportTable";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";
import { SearchUsersForm } from "@components/organisms/mgr/SHISETSUNYUSHO/record/SearchUsersForm";
import * as usersDailyActions from "@stores/pages/record/usersDaily/actions";
import uniq from "lodash-es/uniq";
import { Pagination } from "@components/molecules/Pagination";
import {
  patternListFormat,
  PatternListType
} from "@constants/mgr/SHISETSUNYUSHO/variables";
import { RecordUsersDailyState } from "@stores/pages/record/usersDaily/types";
import {
  CUSTOM_RECORD_TARGET_TYPE,
  SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM
} from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    root: {
      padding: "32px 32px 0px"
    },
    recordSummary: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "space-between",
      marginBottom: 32
    },
    usageSituation: {
      display: "flex",
      "& > div": {
        marginRight: 8
      }
    },
    summaryWrap: {
      backgroundColor: "#eceff1",
      padding: "5px 10px",
      marginBottom: "5px",
      display: "flex",
      flexWrap: "wrap",
      fontSize: 14,
      lineHeight: 2,
      letterSpacing: 0.25,
      color: "#37474f"
    },
    summaryContent: {
      marginRight: 20
    },
    CreateAndUpdateDateWrap: {
      marginTop: 20,
      marginBottom: 20
    },
    divider: {
      backgroundColor: "rgba(0, 0, 0, 0.54)",
      margin: 0
    },
    editSearch: {
      opacity: 0.5,
      zIndex: 1000,
      pointerEvents: "none"
    },
    noUsers: {
      padding: "80px",
      textAlign: "center"
    },
    searchUsersForm: {
      marginBottom: "48px"
    },
    resultsLabel: {
      fontSize: "12px",
      lineHeight: "16px",
      color: "rgba(0, 0, 0, 0.6)",
      letterSpacing: "0.4px",
      padding: "18px 0"
    },
    noResultsLabelBorder: {
      borderBottom: "1px solid #75757580"
    },
    noResultsBody: {
      fontSize: "16px",
      lineHeight: "28px",
      alignItems: "center",
      letterSpacing: "0.5px",
      wordWrap: "break-word",
      padding: "80px 0",
      textAlign: "center"
    },
    pagination: {
      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      paddingTop: "8px",
      paddingBottom: "12px"
    }
  });

type OwnProps = {
  pageName: string;
  supportsRecord: DailyOperationsAndSupportsState["support"];
  supportCount: number;
  staff: StaffState;
  staffOptions: FieldItem[];
  recordDaily: RecordDailyState;
  history: H.History;
  users: UsersInFacilityState["users"];
  unitId: number;
  yyyymmdd: string;
  recordUsersDaily: RecordUsersDailyState;
};

type StateProps = {
  customRecords: CustomRecordsState;
  searchedUsers: UsersInFacilityState["users"];
};

type DispatchProps = {
  setCurrentUsers: (users: UsersInFacilityState["users"]) => void;
  setSearchConditions: (searchConditionsIds: number[]) => void;
  setCurrentPage: (currentPage: number) => void;
  setIsRecord: (isRecord: number) => void;
};

type MergeProps = {
  patternList: PatternListType[];
  currentUsers: UsersInFacilityState["users"];
} & OwnProps &
  StateProps &
  DispatchProps;

type Props = MergeProps & WithStyles<typeof styles>;

/**
 * 日ごとの支援記録の管理を扱う
 */
const DailySupportsRecordCore = (props: Props): JSX.Element => {
  // 「order」順、「記録者」項目は最後に表示されるよう要素をソート
  const sortCustomRecords = (array: CustomRecordsState): CustomRecordsState => {
    const orderArray = array
      .filter((item) => item.setting_type === CUSTOM_RECORD_TARGET_TYPE.support)
      .sort((a, b) => {
        if (!a.order && !b.order) return 0;
        if (!a.order) return 1;
        if (!b.order) return -1;
        return a.order - b.order;
      });

    const recorderItemIndex = orderArray.findIndex(
      (item) =>
        item.default_item === SUPPORT_CUSTOM_RECORD_DEFAULT_ITEM.staff_name
    );

    const recorderItem = orderArray.splice(recorderItemIndex, 1);
    return [...orderArray, ...recorderItem];
  };

  const newCustomRecords =
    props.customRecords.length > 0 && sortCustomRecords(props.customRecords);

  const handleSearchUsers = (list: PatternListType[], isRecord: number) => (
    e: React.MouseEvent<HTMLElement>
  ): void => {
    e.preventDefault();
    const checkedPatterns = list.filter((item) => item.checked);
    const checkedPatternsIds = checkedPatterns.map((item) => item.id);
    props.setSearchConditions(checkedPatternsIds);
    if (checkedPatterns.length === 0) {
      props.setCurrentUsers(props.users);
    } else {
      const patternArr: string[] = [];
      checkedPatterns.forEach((item) => patternArr.push(item.targetKana));
      const checkKanaReg = new RegExp(`^(${patternArr.join("|")}).*$`);
      const newUsers = props.users.filter((item) =>
        checkKanaReg.test(item.kanaName)
      );
      props.setCurrentUsers(newUsers);
    }
    props.setCurrentPage(1);
    props.setIsRecord(isRecord);
  };

  // ページネーションのボタンクリック時の処理
  const handlePageChange = (data: { selected: number }): void => {
    props.setCurrentPage(data.selected + 1);
  };

  return (
    <>
      <Paper className={props.classes.root} elevation={0}>
        <div className={props.classes.header}>
          <Typography
            variant="h6"
            color="primary"
            className={props.classes.title}
          >
            {props.pageName}
          </Typography>
        </div>
        <Divider variant="middle" className={props.classes.divider} />
        {props.currentUsers.length > 0 ? (
          <>
            <div
              className={
                props.recordDaily.isEditing ? props.classes.editSearch : ""
              }
            >
              <SearchUsersForm
                patternList={props.patternList}
                isRecord={props.recordUsersDaily.isRecord}
                handleSearchUsers={handleSearchUsers}
                className={props.classes.searchUsersForm}
              />
              {props.supportCount > 20 && (
                <Pagination
                  dataLength={props.supportCount}
                  handlePageChange={handlePageChange}
                  page={props.recordUsersDaily.currentPage}
                />
              )}
            </div>
            {props.supportCount === 0 ? (
              <div>
                <div
                  className={`${props.classes.resultsLabel}
                    ${props.classes.noResultsLabelBorder}`}
                >
                  0人
                </div>
                <div className={props.classes.noResultsBody}>
                  条件に当てはまる利用者が見つかりませんでした。
                  <br />
                  条件を変更して再度絞り込みをしてください。
                </div>
              </div>
            ) : (
              <div>
                {props.supportCount <= 20 && (
                  <div className={props.classes.resultsLabel}>
                    {props.supportCount}人
                  </div>
                )}
                <DailyUsersRecordSupportTable
                  supportsRecord={props.supportsRecord.filter((record) =>
                    props.currentUsers.find(
                      (user) => user.uif_id === record.user_in_facility_id
                    )
                  )}
                  staffOptions={props.staffOptions}
                  isEditing={props.recordDaily.isEditing}
                  targetUifId={props.recordDaily.uifId}
                  targetUnitId={props.recordDaily.unitId}
                  customRecords={props.customRecords}
                  newCustomRecords={newCustomRecords || null}
                  users={props.currentUsers}
                  unitId={props.unitId}
                  yyyymmdd={props.yyyymmdd}
                  recordUsersDaily={props.recordUsersDaily}
                />
                {props.supportCount > 20 && (
                  <Pagination
                    className={props.classes.pagination}
                    dataLength={props.supportCount}
                    handlePageChange={handlePageChange}
                    page={props.recordUsersDaily.currentPage}
                    hideCountDisplay
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <div className={props.classes.noUsers}>
            このグループには利用者が登録されていません。
          </div>
        )}
      </Paper>
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  customRecords: state.customRecords,
  searchedUsers: state.pages.recordUsersDaily.users
});

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  /**
   * 絞り込みで「active」にする「かなのリスト」を生成
   * @param users 絞り込む前の全ユーザー
   */
  const extractClickablePatternID = (
    users: UsersInFacilityState["users"]
  ): number[] => {
    const arr: number[] = [];
    [...users].forEach((item) => {
      const existingPattern = patternListFormat.find((pattern) => {
        const checkKanaReg = new RegExp(`^${pattern.targetKana}.*$`);
        return checkKanaReg.test(item.kanaName);
      });
      if (existingPattern) {
        arr.push(existingPattern.id);
      }
    });
    return uniq(arr);
  };

  const currentUsers = stateProps.searchedUsers.length
    ? stateProps.searchedUsers
    : ownProps.users;

  const clickablePatternID = extractClickablePatternID(ownProps.users);
  const patternList =
    ownProps.users.length > 0
      ? patternListFormat.map((item) => {
          return {
            ...item,
            disabled: item.disabled || !clickablePatternID.includes(item.id),
            checked:
              item.checked ||
              (clickablePatternID.includes(item.id) &&
                ownProps.recordUsersDaily.searchConditionsIds.includes(item.id))
          };
        })
      : patternListFormat;
  return {
    patternList,
    currentUsers,
    ...dispatchProps,
    ...stateProps,
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setCurrentUsers: (users: UsersInFacilityState["users"]): void => {
      dispatch(usersDailyActions.setCurrentUsers(users));
    },
    setSearchConditions: (searchConditionsIds: number[]): void => {
      dispatch(usersDailyActions.setSearchConditions(searchConditionsIds));
    },
    setCurrentPage: (currentPage: number): void => {
      dispatch(usersDailyActions.setCurrentPage(currentPage));
    },
    setIsRecord: (isRecord: number): void => {
      dispatch(usersDailyActions.setIsRecord(isRecord));
    }
  };
};

export const DailySupportsRecord = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(withStyles(styles)(DailySupportsRecordCore));
