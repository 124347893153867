import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";

import * as ClassNames from "classnames";
import format from "date-fns/format";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import {
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@/constants/styles";
import { FacilityState as FacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/facility/types";
import { UsersInFacilityState as UsersInFacilityStateKODOENGO } from "@stores/domain/mgr/KODOENGO/userInFacility/types";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";
import circleNumbersList from "@constants/mgr/IAB/circleNumbersList";

const styles = (): StyleRules =>
  createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `10px ${PRINT_PAGE_PADDING / 2}px`,
      paddingBottom: 47,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
      whiteSpace: "pre-line",
      "&:last-child": {
        margin: "0 auto"
      },
      "@media print": {
        width: "172mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    },
    pageTitle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      flexWrap: "wrap",
      paddingTop: 14
    },
    pageTitleLeft: {
      display: "flex"
    },
    pageTitleTextB: {
      fontFamily: ["HiraginoSans-W3", "sans-serif"].join(","),
      fontSize: "18px",
      fontWeight: "normal",
      letterSpacing: "0.9px",
      margin: 0
    },
    pageTitleRight: {
      textAlign: "end"
    },
    pageTitleTool: {
      fontSize: "10px",
      letterSpacing: "0.5px",
      lineHeight: 1.4
    },
    userName: {
      width: "199px",
      marginLeft: "20px",
      borderBottom: "solid 1px #000"
    },
    userTitle: {
      height: "14px",
      fontSize: "8px !important",
      fontFamily: "HiraginoSans-W3",
      color: "#424242",
      lineHeight: 1.5,
      letterSpacing: "0.4px",
      margin: "5px 0 5px 0"
    },
    userText: {
      height: "14px",
      fontSize: "10px",
      fontFamily: "HiraginoSans-W6",
      color: "#212121",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      marginLeft: "10px"
    },
    table: {
      marginTop: "25px",
      border: "solid 2px #212121"
    },
    tableRow: {
      display: "flex",
      alignItems: "stretch"
    },
    tableCulGrid: {
      display: "grid",
      gridTemplateRows: "repeat(auto-fill))",
      gridAutoRows: "1fr"
    },
    tableHeadCell: {
      textAlign: "center",
      fontSize: "8px !important",
      fontFamily: "HiraginoSans-W6",
      color: "#212121",
      backgroundColor: "#eeeeee",
      lineHeight: 1.2,
      letterSpacing: "0.5px",
      padding: "4px 8px"
    },
    headerBorder: {
      borderBottom: "solid 1px #212121",
      "@media print": {
        borderBottom: "solid 1px #212121"
      }
    },
    tableCell: {
      padding: "8px",
      fontSize: "10px",
      fontFamily: "HiraginoSans-W3",
      color: "#212121",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      wordBreak: "break-all"
    },
    tableDetailCell: {
      padding: "8px",
      fontSize: "10px",
      fontFamily: "HiraginoSans-W3",
      color: "#212121",
      lineHeight: 1.4,
      letterSpacing: "0.5px",
      borderBottom: "dashed 1px",
      wordBreak: "break-all"
    },
    width135: {
      width: "135px"
    },
    width184: {
      width: "184px"
    },
    width319: {
      width: "319px"
    },
    borderTop: {
      borderTop: "solid 1px #212121"
    },
    borderBottom: {
      borderBottom: "solid 1px #212121"
    },
    borderLeft: {
      borderLeft: "solid 1px #212121"
    },
    borderBottom2: {
      borderBottom: "solid 2px #212121"
    },
    borderRight2: {
      borderRight: "solid 2px #212121"
    },
    minHeight34: {
      minHeight: "34px"
    },
    fontSize10: {
      fontSize: "10px"
    }
  });

type OwnProps = {
  user: UsersInFacilityStateKODOENGO["user"];
  pageName: string;
  facility: FacilityStateKODOENGO;
  supportPlanSheet: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"];
} & WithStyles<typeof styles>;
type Props = OwnProps;

const SupportPlanSheetsPrintContentCore = (props: Props): JSX.Element => {
  const { classes, user, pageName, supportPlanSheet } = props;

  // 作成日
  const targetDate = format(supportPlanSheet.creation_date, "YYYY年MM月DD日");
  // 作成者
  const [author, setAuthor] = React.useState<string>("-");
  React.useEffect(() => {
    if (supportPlanSheet.author === null) {
      setAuthor("-");
    } else {
      const name = getSnapOrRealName(supportPlanSheet.author, "");
      const role = getSnapOrRealRole(supportPlanSheet.author, "");
      setAuthor(`${name}（${role}）`);
    }
  }, [supportPlanSheet.author]);

  if (user.user_in_facility.name_sei === undefined) {
    return <></>;
  }

  const planSheetsDetails = supportPlanSheet.support_plan_sheet_details;

  return (
    <>
      <div className={classes.page}>
        {/* タイトル */}
        <div className={classes.pageTitle}>
          <div className={classes.pageTitleLeft}>
            <div className={classes.pageTitleTextB}>{pageName}</div>
            <div className={classes.userName}>
              <span className={classes.userTitle}>利用者名</span>
              <span className={classes.userText}>
                {user.user_in_facility.name_sei}{" "}
                {user.user_in_facility.name_mei}
              </span>
            </div>
          </div>
          <div className={classes.pageTitleRight}>
            <div className={classes.pageTitleTool}>
              作成日: <span>{`${targetDate}`}</span>
            </div>
            <div className={classes.pageTitleTool}>
              作成者: <span>{`${author}`}</span>
            </div>
          </div>
        </div>
        {/* 支援計画シート */}
        <div className={classes.table}>
          {/* 支援計画シート table header 1 */}
          <div className={classes.tableRow}>
            <div
              className={ClassNames(
                classes.tableHeadCell,
                classes.width184,
                classes.borderRight2,
                classes.headerBorder
              )}
            >
              <span className={classes.fontSize10}>インテーク</span>
              （情報の収集・整理）
            </div>
            <div
              className={ClassNames(
                classes.tableHeadCell,
                classes.width319,
                classes.borderRight2,
                classes.headerBorder
              )}
            >
              <span className={classes.fontSize10}>アセスメント</span>（評価）
            </div>
            <div
              className={ClassNames(
                classes.tableHeadCell,
                classes.width184,
                classes.headerBorder
              )}
            >
              <span className={classes.fontSize10}>プランニング</span>
              （支援計画）
            </div>
          </div>
          {/* 支援計画シート table header 2 */}
          <div className={ClassNames(classes.tableRow, classes.borderBottom2)}>
            <div
              className={ClassNames(
                classes.tableHeadCell,
                classes.width184,
                classes.borderRight2
              )}
            >
              <span className={classes.fontSize10}>情報</span>
              <br />
              （見たこと、聴いたこと、資料などから）
            </div>
            <div
              className={ClassNames(classes.tableHeadCell, classes.width184)}
            >
              <span className={classes.fontSize10}>理解・解釈・仮説</span>
              <br />
              （わかったこと、推測したこと）
            </div>
            <div
              className={ClassNames(
                classes.tableHeadCell,
                classes.borderRight2,
                classes.borderLeft
              )}
              style={{
                width: "134px"
              }}
            >
              <span className={classes.fontSize10}>支援課題</span>
              <br />
              （支援の必要なこと）
            </div>
            <div
              className={ClassNames(classes.tableHeadCell, classes.width184)}
            >
              <span className={classes.fontSize10}>対応・方針</span>
              <br />
              （やろうと思うこと）
            </div>
          </div>
          {/* 支援計画シート table body */}
          <div className={classes.tableRow}>
            {/* 情報 */}
            <div
              className={ClassNames(
                classes.tableCulGrid,
                classes.width184,
                classes.borderRight2
              )}
            >
              <div className={classes.tableCell}>{supportPlanSheet.intake}</div>
            </div>
            {/* 理解・解釈・仮説 */}
            <div className={classes.width184}>
              <div
                className={ClassNames(
                  classes.tableHeadCell,
                  classes.borderBottom,
                  classes.minHeight34
                )}
              >
                <span className={classes.fontSize10}>生物的なこと</span>
                <br />
                （疾患や障害、気質など）
              </div>
              <div
                className={ClassNames(classes.tableCell, classes.minHeight34)}
              >
                {supportPlanSheet.assessment_biological}
              </div>
              <div
                className={ClassNames(
                  classes.tableHeadCell,
                  classes.borderTop,
                  classes.borderBottom,
                  classes.minHeight34
                )}
              >
                <span className={classes.fontSize10}>心理的なこと</span>
                <br />
                （不安、葛藤、希望、感情など）
              </div>
              <div
                className={ClassNames(classes.tableCell, classes.minHeight34)}
              >
                {supportPlanSheet.assessment_psychological}
              </div>
              <div
                className={ClassNames(
                  classes.tableHeadCell,
                  classes.borderTop,
                  classes.borderBottom,
                  classes.minHeight34
                )}
              >
                <span className={classes.fontSize10}>社会的なこと</span>
                <br />
                （家庭、施設・学校、地域資源など）
              </div>
              <div
                className={ClassNames(
                  classes.tableCell,
                  classes.tableCulGrid,
                  classes.minHeight34
                )}
              >
                {supportPlanSheet.assessment_social}
              </div>
            </div>
            {/* 支援課題 */}
            <div className={ClassNames(classes.tableCulGrid, classes.width135)}>
              {planSheetsDetails.map((item, index) => {
                if (index === planSheetsDetails.length - 1) {
                  return (
                    <div
                      key={item.support_plan_sheet_detail_id}
                      className={ClassNames(
                        classes.tableCell,
                        classes.borderLeft,
                        classes.borderRight2
                      )}
                    >
                      {circleNumbersList[index] + item.support_issue}
                    </div>
                  );
                }
                return (
                  <div
                    key={item.support_plan_sheet_detail_id}
                    className={ClassNames(
                      classes.tableDetailCell,
                      classes.borderLeft,
                      classes.borderRight2
                    )}
                  >
                    {circleNumbersList[index] + item.support_issue}
                  </div>
                );
              })}
            </div>
            {/* 対応・方針 */}
            <div className={ClassNames(classes.tableCulGrid, classes.width184)}>
              {planSheetsDetails.map((item, index) => {
                if (index === planSheetsDetails.length - 1) {
                  return (
                    <div
                      key={item.support_plan_sheet_detail_id}
                      className={classes.tableCell}
                    >
                      {item.response_policy}
                    </div>
                  );
                }
                return (
                  <div
                    key={item.support_plan_sheet_detail_id}
                    className={classes.tableDetailCell}
                  >
                    {item.response_policy}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const SupportPlanSheetsPrintContent = withStyles(styles)(
  SupportPlanSheetsPrintContentCore
);
