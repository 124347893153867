import {
  CALCULATION_TIME_UPPER_LIMIT,
  DEFAULT_ROUND_UP_MINUTE,
  IDOSHIEN_MEMBER_LIST,
  IDOSHIEN_STATUS_LIST
} from "@constants/mgr/IDOSHIEN/variables";
import { STANDARD_TIME_VALUE } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { DAY_SELECT_TYPE, FacilityType } from "@constants/variables";
import {
  DetailsInitialValues,
  ServiceDeliveryDetailValues
} from "@initialize/record/serviceDelivery/initialValues";
import { MunicipalitiesInFacilityState } from "@stores/domain/mgr/IDOSHIEN/municipalitiesInFacility/types";
import { UsersInFacilityState } from "@stores/domain/mgr/IDOSHIEN/userInFacility/types";
import castNumber from "@utils/dataNormalizer/castNumber";
import { calculateForRequiredTimeNoReverse } from "@utils/domain/mgr/calculateForRequiredTimeNoReverse";
import { calculateHours } from "@utils/domain/mgr/calculateHours";
import { checkTime } from "@validator/rules";
import { checkTimeFutureHHMM } from "@validator/rules/checkTimeFutureHHMM";
import { FormikProps } from "formik";
import { useEffect } from "react";

/**
 * TODO: components/organisms/record/serviceDelivery/ServiceDeliveryTimeContainerなどでも使えるように共通化したい
 * @param fieldName
 * 例）serviceDeliveryRecordPractitioners1
 * @param detailsFieldName
 * 例）serviceDeliveryRecordPractitioners1.serviceDeliveryRecordPractitionerDetails
 * @param setFormikFieldValue
 * (fieldName: string, value: number | string | boolean) => void;
 * @param facilityType
 * 例）IDOSHIEN
 * @param practitionerValues
 * ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]
 * @param formikProps
 * FormikProps<ServiceDeliveryDetailValues>
 * @param fetchMunicipality
 * 自治体情報取得
 * @param fetchUserInFacility
 * ユーザー情報取得
 * @param municipality
 * 自治体データ
 * @param userInFacility
 * ユーザーデータ
 */
export const useServiceDeliveryTimeContainer = (
  fieldName: string,
  detailsFieldName: string,
  setFormikFieldValue: (
    fieldName: string,
    value: number | string | boolean
  ) => void,
  facilityType: FacilityType,
  practitionerValues: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"],
  formikProps: FormikProps<ServiceDeliveryDetailValues>,
  fetchMunicipality: (id: string) => void,
  fetchUserInFacility: (uifId: string) => void,
  municipality: MunicipalitiesInFacilityState,
  userInFacility: UsersInFacilityState
): {
  onChangeDateSelect: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeInitialTime: (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    _: string,
    autoCorrectValue: string
  ) => void;
  onChangeActionClass: (index: number, actionClass: number) => void;
  onChangeDetailDateSelect: (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ) => void;
  onChangeDetailRecord: (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ) => void;
  onDeleteRecord: (index: number) => void;
} => {
  const isEditable = !!formikProps.values.serviceDeliveryRecordsId;
  useEffect(() => {
    if (!formikProps.values.usersInFacilityId || isEditable) return;
    fetchUserInFacility(String(formikProps.values.usersInFacilityId));
  }, [formikProps.values.usersInFacilityId]);

  useEffect(() => {
    const municipalityId =
      userInFacility.user.user_in_facility_idoshien &&
      userInFacility.user.user_in_facility_idoshien.municipality_id;
    if (!municipalityId) return;
    fetchMunicipality(String(municipalityId));
  }, [userInFacility]);

  useEffect(() => {
    if (isEditable) return;
    const calculationTimeFlg = municipality.municipality.calculation_time_flg;
    const roundUpMinute =
      municipality.municipality.round_up_minute || DEFAULT_ROUND_UP_MINUTE;
    setFormikFieldValue("municipality.calculationTimeFlg", calculationTimeFlg);
    setFormikFieldValue("municipality.roundUpMinute", roundUpMinute);
  }, [municipality]);

  // 当日翌日を考慮し、HH:MM形式の時間を00:01~47:59で表示する
  const formatTimeTodayOrNextDay = (time: string, dateNum: string): string => {
    // 00:00の形式でないものはそのまま返す
    if (dateNum === DAY_SELECT_TYPE.today || checkTime(time) || !time) {
      return time;
    }
    const times = time.split(":");
    const hour = Number(times[0]) + 24;
    return `${hour.toString()}:${times[1]}`;
  };

  const getIdoshienTotalTime = (base: number, other: number): number => {
    const totalTime = base - other;
    if (
      facilityType !== FacilityType.IDOSHIEN ||
      !formikProps.values.municipality
    )
      return totalTime;
    const {
      calculationTimeFlg,
      roundUpMinute
    } = formikProps.values.municipality;
    const roundUpMinuteValue =
      calculationTimeFlg && roundUpMinute !== null
        ? roundUpMinute
        : DEFAULT_ROUND_UP_MINUTE;
    const timeOfOver = totalTime % CALCULATION_TIME_UPPER_LIMIT;
    if (timeOfOver < roundUpMinuteValue) {
      return totalTime - timeOfOver;
    }
    return totalTime;
  };

  // 「開始時間・終了時間・運転/空き時間」から時間数計算
  const setPersonalCalculatedHours = (
    inTimeDate: string,
    inTime: string,
    outTimeDate: string,
    outTime: string,
    otherTimeList: DetailsInitialValues
  ): void => {
    // 自動計算される項目のフィールド名
    const inTimeValue = formatTimeTodayOrNextDay(inTime, inTimeDate);
    const outTimeValue = formatTimeTodayOrNextDay(outTime, outTimeDate);
    const baseTime = calculateForRequiredTimeNoReverse(
      inTimeValue,
      outTimeValue
    );
    if (baseTime) {
      const otherTime = otherTimeList.reduce((sum, value) => {
        if (value.isDelete) {
          return sum;
        }
        // 開始時間または終了時間が存在しない場合は計算しない
        if (value.startTime.length === 0 || value.endTime.length === 0) {
          return sum;
        }
        const inTimeDetails = formatTimeTodayOrNextDay(
          value.startTime,
          value.startTimeDate
        );
        // 基準となる時間未満の場合は基準の時間に設定する
        const inTimeDetailsValues = checkTimeFutureHHMM(
          inTimeDetails,
          inTimeValue
        )
          ? inTimeValue
          : inTimeDetails;

        const outTimeDetails = formatTimeTodayOrNextDay(
          value.endTime,
          value.endTimeDate
        );
        // 基準となる時間を超える場合は基準の時間に設定する
        const outTimeDetailsValues = checkTimeFutureHHMM(
          outTimeDetails,
          outTimeValue
        )
          ? outTimeDetails
          : outTimeValue;
        const time = calculateForRequiredTimeNoReverse(
          inTimeDetailsValues,
          outTimeDetailsValues
        );
        return time ? sum + time : sum;
      }, 0);

      if (
        formikProps.values.status ===
        IDOSHIEN_STATUS_LIST.VEHICLE_TRANSPORT.value
      ) {
        // 車両移送支援の差分時間項目にセット
        const totalMinutes = castNumber(baseTime - otherTime);
        const hour = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        setFormikFieldValue(
          `${fieldName}.numberOfServiceHours`,
          hour.toString().padStart(2, "0")
        );
        setFormikFieldValue(
          `${fieldName}.numberOfServiceMinutes`,
          minutes.toString().padStart(2, "0")
        );
      } else {
        const result = calculateHours(
          baseTime,
          STANDARD_TIME_VALUE.HALF,
          otherTime,
          STANDARD_TIME_VALUE.HALF,
          undefined,
          undefined,
          (base, other): number => getIdoshienTotalTime(base, other)
        );
        const resultText =
          result > 0 && !String(result).includes(".")
            ? `${result}.0`
            : String(result);

        setFormikFieldValue(
          `${fieldName}.numberOfTime`,
          !result || Number.isNaN(result) ? "" : resultText
        );
      }
    } else {
      setFormikFieldValue(`${fieldName}.numberOfTime`, "");
      setFormikFieldValue(`${fieldName}.numberOfServiceHours`, "");
      setFormikFieldValue(`${fieldName}.numberOfServiceMinutes`, "");
    }
  };

  // 「当日・翌日」セレクトボックス変更時
  const onChangeDateSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const start = practitionerValues.startTime;
    const end = practitionerValues.endTime;
    const startTimeFieldName = "startTime";
    const endTimeFieldName = "endTime";
    const otherTime =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;
    formikProps.setFieldTouched(`${fieldName}.${startTimeFieldName}`, true);
    formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}`, true);
    if (event.target.name === `${fieldName}.${startTimeFieldName}Date`) {
      formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}Date`, true);
      setPersonalCalculatedHours(
        event.target.value,
        start,
        practitionerValues.endTimeDate,
        end,
        otherTime
      );
    } else {
      formikProps.setFieldTouched(`${fieldName}.startTimeDate`, true);
      setPersonalCalculatedHours(
        practitionerValues.startTimeDate,
        start,
        event.target.value,
        end,
        otherTime
      );
    }
  };

  // 「開始時間・終了時間」時間変更時
  const onChangeInitialTime = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    _: string,
    autoCorrectValue: string
  ): void => {
    const startDate = practitionerValues.startTimeDate;
    const endDate = practitionerValues.endTimeDate;
    const startTimeFieldName = "startTime";
    const endTimeFieldName = "endTime";

    const otherTime =
      practitionerValues.serviceDeliveryRecordPractitionerDetails;
    formikProps.setFieldTouched(`${fieldName}.${startTimeFieldName}Date`, true);
    formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}Date`, true);
    if (event.target.name === `${fieldName}.${startTimeFieldName}`) {
      formikProps.setFieldTouched(`${fieldName}.${endTimeFieldName}`, true);
      const end = practitionerValues.endTime;
      setPersonalCalculatedHours(
        startDate,
        autoCorrectValue,
        endDate,
        end,
        otherTime
      );
    } else {
      formikProps.setFieldTouched(`${fieldName}.startTime`, true);
      const start = practitionerValues.startTime;

      setPersonalCalculatedHours(
        startDate,
        start,
        endDate,
        autoCorrectValue,
        otherTime
      );
    }
  };

  // NOTE: UnitsFieldsコンポーネントで必須となっているので移動支援で使わないですが定義しておきます
  // 「運転・空き」「当日・翌日」セレクトボックス変更時(重度のみ)
  const onChangeActionClass = (index: number, actionClass: number): void => {
    if (facilityType === FacilityType.JUDOHOMONKAIGO) {
      const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
        (item, i) => {
          if (i === index) {
            return {
              serviceDeliveryRecordPractitionerDetailsId:
                item.serviceDeliveryRecordPractitionerDetailsId,
              actionClass,
              startTimeDate: item.startTimeDate,
              startTime: item.startTime,
              endTimeDate: item.endTimeDate,
              endTime: item.endTime,
              isDelete: item.isDelete
            };
          }
          return item;
        }
      );
      setPersonalCalculatedHours(
        practitionerValues.startTimeDate,
        practitionerValues.startTime,
        practitionerValues.endTimeDate,
        practitionerValues.endTime,
        otherTime
      );
    }
  };

  // 「運転・空き」「当日・翌日」セレクトボックス変更時
  const onChangeDetailDateSelect = (
    index: number,
    startDate: string,
    endDate: string,
    isStart: boolean
  ): void => {
    const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
      (item, i) => {
        if (i === index) {
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].startTime`,
            true
          );
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].endTime`,
            true
          );
          if (isStart) {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].endTimeDate`,
              true
            );
          } else {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].startTimeDate`,
              true
            );
          }
          return {
            serviceDeliveryRecordPractitionerDetailsId:
              item.serviceDeliveryRecordPractitionerDetailsId,
            actionClass: item.actionClass,
            startTimeDate: startDate,
            startTime: item.startTime,
            endTimeDate: endDate,
            endTime: item.endTime,
            isDelete: item.isDelete
          };
        }
        return item;
      }
    );
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      otherTime
    );
  };

  // 「運転・空き」時間変更時
  const onChangeDetailRecord = (
    index: number,
    start: string,
    end: string,
    isStart: boolean
  ): void => {
    const otherTime = practitionerValues.serviceDeliveryRecordPractitionerDetails.map(
      (item, i) => {
        if (i === index) {
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].startTimeDate`,
            true
          );
          formikProps.setFieldTouched(
            `${detailsFieldName}[${index}].endTimeDate`,
            true
          );
          if (isStart) {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].endTime`,
              true
            );
          } else {
            formikProps.setFieldTouched(
              `${detailsFieldName}[${index}].startTime`,
              true
            );
          }
          return {
            serviceDeliveryRecordPractitionerDetailsId:
              item.serviceDeliveryRecordPractitionerDetailsId,
            actionClass: item.actionClass,
            startTimeDate: item.startTimeDate,
            startTime: start,
            endTimeDate: item.endTimeDate,
            endTime: end,
            isDelete: item.isDelete
          };
        }
        return item;
      }
    );
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      otherTime
    );
  };

  // 「運転/空き時間」削除時
  const onDeleteRecord = (index: number): void => {
    const removeDetailsValues = [
      ...practitionerValues.serviceDeliveryRecordPractitionerDetails
    ];
    removeDetailsValues.splice(index, 1);
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      removeDetailsValues
    );
  };

  // サービス内容が変更されていれば時間差分を再計算する
  useEffect(() => {
    setPersonalCalculatedHours(
      practitionerValues.startTimeDate,
      practitionerValues.startTime,
      practitionerValues.endTimeDate,
      practitionerValues.endTime,
      practitionerValues.serviceDeliveryRecordPractitionerDetails
    );
  }, [formikProps.values.status, formikProps.values.numberOfPractitioner]);

  // 提供人数を一人に変更されていれば２人同時チェックを外す
  useEffect(() => {
    if (
      formikProps.values.numberOfPractitioner !== IDOSHIEN_MEMBER_LIST.ONE.value
    )
      return;
    setFormikFieldValue("licenseSameFlg", false);
  }, [formikProps.values.numberOfPractitioner]);

  return {
    onChangeDateSelect,
    onChangeInitialTime,
    onChangeActionClass,
    onChangeDetailDateSelect,
    onChangeDetailRecord,
    onDeleteRecord
  };
};
