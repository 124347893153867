import * as React from "react";

type OwnProps = {
  text: string;
  needBlankText?: boolean;
};

type Props = OwnProps;

const LineBreak = ({
  text = "",
  needBlankText = false
}: Props): JSX.Element => {
  const blankText = needBlankText ? "-" : null;
  const t = text
    ? text.split("\n").map((line, i) => {
        const key = `s_${i}`;
        return (
          <span key={key}>
            {line}
            <br />
          </span>
        );
      })
    : blankText;
  return <span>{t}</span>;
};

export default LineBreak;
