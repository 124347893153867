import * as types from "./types";
import { getHolidayResponse } from "@api/requests/holiday/getHoliday";

type ErrorResponse = {
  error: {
    data: {
      error: string;
      message: string;
    };
  };
};

export const fetchHolidayStarted = () =>
  ({ type: types.FETCH_HOLIDAY_STARTED } as const);
export const fetchHolidaySuccess = (res: getHolidayResponse["data"]) =>
  ({ type: types.FETCH_HOLIDAY_SUCCESS, payload: res } as const);
export const fetchHolidayFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_HOLIDAY_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchHolidayStarted>
  | ReturnType<typeof fetchHolidaySuccess>
  | ReturnType<typeof fetchHolidayFailed>;
