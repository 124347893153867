import React from "react";

import {
  Table,
  TableBody,
  WithStyles,
  createStyles,
  withStyles
} from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { SupportProcedureModalTableRow } from "./SupportProcedureModalTableRow";

import { connect } from "react-redux";
import { SupportProcedureState } from "@stores/domain/mgr/KODOENGO/supportProcedure/types";
import { CheckedIds } from "./SupportProcedureCreateModalContent";

const styles = (): StyleRules =>
  createStyles({
    baseCell: {
      fontSize: "14px",
      boxSizing: "content-box"
    },
    checkboxCell: {
      width: 66,
      minWidth: 66
    },
    dateCell: {
      width: 120,
      minWidth: 120
    },
    createdCell: {
      width: 42,
      minWidth: 42,
      fontSize: "12px",
      textAlign: "center"
    },
    numberOfPractitionerCell: {
      width: 56,
      minWidth: 56,
      textAlign: "center"
    },
    timeCell: {
      width: 164,
      minWidth: 164
    },
    titleCell: {
      width: 372,
      minWidth: 372,
      "&:last-child": {
        paddingRight: 0
      }
    },
    solidBorderBottom: {
      borderBottom: "1px solid #e0e0e0"
    }
  });

type OwnProps = {
  checkedIds: CheckedIds[];
  handleChangeCheckbox: (e: React.ChangeEvent<HTMLInputElement>) => void;
  records: SupportProcedureState["supportProcedureList"]["support_procedure_forms"];
};

type Props = OwnProps & WithStyles<typeof styles>;

const SupportProcedureModalTableCore = (props: Props): JSX.Element => {
  const { checkedIds, handleChangeCheckbox, records, classes } = props;
  const headData = [
    { label: "", className: `${classes.baseCell} ${classes.checkboxCell}` },
    { label: "日付", className: `${classes.baseCell} ${classes.dateCell}` },
    {
      label: (
        <>
          作成
          <br />
          済み
        </>
      ),
      className: `${classes.baseCell} ${classes.createdCell}`
    },
    {
      label: "人数",
      className: `${classes.baseCell} ${classes.numberOfPractitionerCell}`
    },
    { label: "時間", className: `${classes.baseCell} ${classes.timeCell}` },
    { label: "タイトル", className: `${classes.baseCell} ${classes.titleCell}` }
  ];

  return (
    <div>
      <Table>
        <KnowbeTableHead
          uniqueKey="supportProcedureTableHead"
          height={40}
          items={headData}
        />
      </Table>
      <Table key="mui-table">
        <TableBody key="mui-table-body" className={classes.solidBorderBottom}>
          {records.map((row, index) => {
            const tableRowIndex = `mui-table-row-${index}`;
            return (
              <SupportProcedureModalTableRow
                key={tableRowIndex}
                index={index}
                checkedIds={checkedIds}
                handleChangeCheckbox={handleChangeCheckbox}
                rowData={row}
              />
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export const SupportProcedureModalTable = connect(null)(
  withStyles(styles)(SupportProcedureModalTableCore)
);
