import {
  FacilityState,
  FETCH_FAILED,
  FETCH_STARTED,
  FETCH_SUCCESS,
  POST_FAILED,
  POST_STARTED,
  POST_SUCCESS
} from "./types";
import { ActionTypes } from "./actions";
import { DEFAULT_CHECKBOX_VALUE } from "@constants/variables";

const initialState: FacilityState = {
  corporationName: "",
  serviceType: "",
  capacity: "",
  postalCode: "",
  selectedPrefectureName: "",
  selectedCityCode: "",
  restAddress: "",
  cityId: "",
  integratedManagementHandicappedChildFlg: DEFAULT_CHECKBOX_VALUE,
  facilityType: null,
  facilities_keikakusodan_shogaijisodan: []
};

export const KEIKAKUSODANFacility = (
  state = initialState,
  action: ActionTypes
): FacilityState => {
  switch (action.type) {
    case FETCH_STARTED:
      return { ...state };
    case FETCH_SUCCESS:
      return { ...state, ...action.payload };
    case FETCH_FAILED:
      return { ...state };
    case POST_STARTED:
      return { ...state };
    case POST_SUCCESS:
      return { ...state, ...action.payload };
    case POST_FAILED:
      return { ...state };
    default:
      return state;
  }
};
