import * as React from "react";
import * as classNames from "classnames";
import { convertWareki } from "@utils/date";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import {
  SeikyuDetail,
  SeikyuDetailUser,
  SeikyuDetailUserServiceContent
} from "@stores/domain/invoice/type";
import {
  PRINT_PAGE_WIDTH,
  PRINT_PAGE_PADDING,
  PRINT_PAGE_HEIGHT,
  PRINT_PAGE_MARGIN_BOTTOM
} from "@constants/styles";
import { TYPE_CONSULTATION_SHOGAIJISODAN } from "@constants/mgr/KEIKAKUSODAN/variables";

const SERVICE_CONTENTS_MAX_ROW = 30;

const styles = (): StyleRules => {
  return createStyles({
    page: {
      minHeight: PRINT_PAGE_HEIGHT,
      width: PRINT_PAGE_WIDTH,
      margin: `0 auto ${PRINT_PAGE_MARGIN_BOTTOM}px`,
      padding: `20px ${PRINT_PAGE_PADDING}px`,
      backgroundColor: "#fff",
      boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "flex-start",
      marginBottom: 16,
      "&.space-between": {
        justifyContent: "space-between"
      },
      "&.align-end": {
        alignItems: "flex-end"
      }
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: 0,
      border: "2px solid",
      textAlign: "left",
      "&.fullWidth": {
        width: "100%"
      },
      "&.borderOpacity": {
        borderBottom: "none",
        borderLeft: "none",
        borderRight: "none"
      },
      "& td": {
        padding: "0.5px 7px",
        borderRight: "1px solid",
        fontSize: 10,
        letterSpacing: 0.6,
        color: "rgba(0, 0, 0, 0.84)",
        height: 15,
        "&.label": {
          textAlign: "center"
        },
        "&.prise": {
          textAlign: "right"
        },
        "&.msize": {
          width: 120
        },
        "&.lsize": {
          width: 150
        },
        "&.llsize": {
          width: 250
        },
        "&.vertical": {
          width: 18,
          letterSpacing: 0,
          "& > span": {
            writingMode: "vertical-rl"
          }
        },
        "&.borderBold": {
          borderRight: "2px solid"
        },
        "&.borderBottomBold": {
          borderBottom: "2px solid"
        },
        "&.borderOpacity": {
          borderTop: "none",
          borderBottom: "none",
          borderLeft: "none",
          borderRight: "none"
        }
      },
      "& tr": {
        borderBottom: "1px solid",
        "&.borderBold": {
          borderBottom: "2px solid"
        },
        "&.borderBoldSide": {
          borderLeft: "2px solid",
          borderRight: "2px solid"
        },
        "&.borderOpacity": {
          borderBottom: "none"
        }
      }
    },
    title: {
      margin: "0 0 30px 0",
      fontWeight: "normal",
      letterSpacing: 1.2,
      textAlign: "center",
      color: "rgba(0, 0, 0, 0.84)",
      fontSize: 13
    },
    "@media print": {
      page: {
        width: "172mm",
        height: "251mm",
        minHeight: 0,
        padding: 0,
        margin: "0 auto",
        boxShadow: "none",
        pageBreakAfter: "always",
        "&:last-child": {
          pageBreakAfter: "auto"
        }
      }
    }
  });
};

type OwnProps = {
  data: SeikyuDetail;
  user: SeikyuDetailUser;
  typeConsultation?: string;
};

type Props = OwnProps & WithStyles<typeof styles>;

/**
 * 請求明細書
 * 介護給付費・訓練等給付費等明細書 の印刷画面
 */
const InvoiceSummaryDetailPlanningConsultationCore = (
  props: Props
): JSX.Element => {
  const { data, classes, user } = props;
  const { date, facility } = data;
  // 各ページの最初のデータindexが入った配列
  const indexArray: number[] = [0];
  let dataIndex = 0;
  user.serviceContents.forEach((item, index) => {
    if (dataIndex <= SERVICE_CONTENTS_MAX_ROW - 1) {
      dataIndex += 1;
    } else {
      dataIndex = 0;
      indexArray.push(index);
      dataIndex += 1;
    }
  });

  const isInvoiceConsultationShogaiji =
    Number(props.typeConsultation) === TYPE_CONSULTATION_SHOGAIJISODAN;

  const invoiceTitle = isInvoiceConsultationShogaiji
    ? "障害児相談支援給付費明細書"
    : "計画相談支援給付費明細書";

  const grantDecisionHeaderLabel = isInvoiceConsultationShogaiji
    ? "給付決定保護者"
    : "支給決定障害者等";

  return (
    <>
      {indexArray.map((firstNumberOfPage, index) => {
        const uniqueKey = `table-row-${index}`;
        return (
          <React.Fragment key={uniqueKey}>
            <section className={classes.page}>
              <header>
                <h1 className={classes.title}>{invoiceTitle}</h1>
              </header>
              <div
                className={classNames(classes.flexContainer, "space-between")}
              >
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td className="label">市町村番号</td>
                      <td className="msize">{user.m01}</td>
                    </tr>
                  </tbody>
                </table>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>{convertWareki(date.m03, date.m04).era}</td>
                      <td>{convertWareki(date.m03, date.m04).year}</td>
                      <td>年</td>
                      <td>{date.m04}</td>
                      <td>月分</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                className={classNames(
                  classes.flexContainer,
                  "space-between",
                  "align-end"
                )}
              >
                <table className={`${classes.table} borderOpacity`}>
                  <tbody>
                    <tr className="borderBoldSide">
                      <td className="label">受給者証番号</td>
                      <td className="lsize">{user.m05}</td>
                    </tr>
                    <tr className="borderBold borderBoldSide">
                      <td className="label">
                        {grantDecisionHeaderLabel}
                        <br />
                        氏名
                      </td>
                      <td className="lsize">{user.m06}</td>
                    </tr>
                    <tr className="borderOpacity">
                      <td className="borderOpacity" />
                      <td className="msize borderOpacity" />
                    </tr>
                    <tr className="borderOpacity">
                      <td className="borderOpacity" />
                      <td className="msize borderOpacity" />
                    </tr>
                  </tbody>
                </table>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td
                        rowSpan={4}
                        className="label vertical"
                        style={{ height: 100, padding: 0 }}
                      >
                        <span>請求事業者</span>
                      </td>
                      <td colSpan={2} className="label">
                        指定事業所番号
                      </td>
                      <td colSpan={2}>{facility.m08}</td>
                    </tr>
                    <tr>
                      <td
                        rowSpan={2}
                        colSpan={2}
                        className="label borderBottomBold"
                      >
                        事業者及び
                        <br />
                        その事業所
                        <br />
                        の名称
                      </td>
                      <td colSpan={2} className="llsize" style={{ height: 50 }}>
                        {facility.m09}
                      </td>
                    </tr>
                    <tr className="borderBold">
                      <td className="label">地域区分</td>
                      <td>{facility.m10}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={classes.flexContainer}>
                <ServiceContents
                  classes={classes}
                  user={user}
                  firstNumberOfPage={firstNumberOfPage}
                  lastNumberOfPage={
                    index + 1 === indexArray.length
                      ? user.serviceContents.length - 1
                      : indexArray[index + 1] - 1
                  }
                />
              </div>
              <div
                className={classNames(
                  classes.flexContainer,
                  "space-between",
                  "align-end"
                )}
              >
                <table className={classes.table}>
                  <tbody />
                </table>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td>{indexArray.length}枚中</td>
                      <td>{index + 1}枚目</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </React.Fragment>
        );
      })}
    </>
  );
};

type ServiceContentsProps = WithStyles<typeof styles> & {
  user: SeikyuDetailUser;
  firstNumberOfPage: number;
  lastNumberOfPage: number;
};

/**
 * 給付費明細欄
 */
const ServiceContents = (props: ServiceContentsProps): JSX.Element => {
  const { user, classes, firstNumberOfPage, lastNumberOfPage } = props;
  const emptyList = [
    ...Array(
      lastNumberOfPage - firstNumberOfPage + 1 >= SERVICE_CONTENTS_MAX_ROW
        ? 0
        : SERVICE_CONTENTS_MAX_ROW - (lastNumberOfPage - firstNumberOfPage + 1)
    )
  ].fill(undefined);
  const records: SeikyuDetailUserServiceContent[] = [
    ...user.serviceContents.slice(firstNumberOfPage, lastNumberOfPage + 1),
    ...emptyList
  ];
  return (
    <>
      <table className={`${classes.table} fullWidth`}>
        <tbody>
          <tr className="borderBold">
            <td
              rowSpan={SERVICE_CONTENTS_MAX_ROW + 1}
              className="label vertical borderBold"
              style={{ height: 100, padding: 0 }}
            >
              <span>給付費明細欄</span>
            </td>
            <td className="label borderBold">サービス内容</td>
            <td className="label borderBold">サービスコード</td>
            <td className="label borderBold" style={{ width: 55 }}>
              単位数
            </td>
            <td className="label borderBold">回数</td>
            <td className="label borderBold" style={{ width: 125 }}>
              サービス単位数
            </td>
            <td className="label borderBold">摘要</td>
          </tr>
          {records.map((item, index) => {
            const uniqueKey = `table-row-${index}`;
            return (
              <tr key={uniqueKey}>
                <td className="borderBold" style={{ width: 300 }}>
                  {item ? item.c01 : ""}
                </td>
                <td className="msize borderBold">{item ? item.c02 : ""}</td>
                <td className="prise borderBold" style={{ width: 50 }}>
                  {item ? item.c03 : ""}
                </td>
                <td className="prise borderBold" style={{ width: 50 }}>
                  {item ? item.c04 : ""}
                </td>
                <td className="msize prise borderBold">
                  {item ? item.c05 : ""}
                </td>
                <td className="msize borderBold">{item ? item.c06 : ""}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export const InvoiceSummaryDetailPlanningConsultation = withStyles(styles)(
  InvoiceSummaryDetailPlanningConsultationCore
);
