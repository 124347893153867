import { UsageResultsState } from "@stores/v202104/domain/mgr/SHUROTEICHAKU/report/types";
import { InitialDataValues } from "@interfaces/v202104/mgr/SHUROTEICHAKU/report/initialData";
import castNumberCheckboxValue from "@utils/dataNormalizer/castNumberCheckboxValue";
import determineTrueOrFalse from "@utils/dataNormalizer/determineTrueOrFalse";
import emptyToNotSelected from "@utils/dataNormalizer/emptyToNotSelected";
import { dateToSelectDateValue } from "@utils/date";
import convertHHMMSSToHHMM from "@utils/date/convertHHMMSSToHHMM";

export const initialValues = (state?: UsageResultsState): InitialDataValues => {
  // 支援レポート共有日
  const shareSupportReportDate: UsageResultsState["shareSupportReportDate"] =
    state && state.shareSupportReportDate ? state.shareSupportReportDate : "";
  const initializedShareSupportReportDate = emptyToNotSelected(
    dateToSelectDateValue(shareSupportReportDate)
  );

  const usageResults: UsageResultsState["usageResults"] =
    state && state.usageResults ? state.usageResults : [];
  const initializedUsageResults = usageResults.map((usageResult) => {
    return {
      // 対象年月日
      targetDate: usageResult.targetDate ? usageResult.targetDate : "",
      // サービス提供の状況
      statusType: determineTrueOrFalse(
        castNumberCheckboxValue(usageResult.statusType)
      ),
      // 特別地域加算
      specialAreaFlg: determineTrueOrFalse(
        castNumberCheckboxValue(usageResult.specialAreaFlg)
      ),
      // 定着支援連携促進加算
      cooperationPromotionFlg: determineTrueOrFalse(
        castNumberCheckboxValue(usageResult.cooperationPromotionFlg)
      ),
      // 支援実施方法・場所
      supportMethod: usageResult.supportMethod
        ? `${usageResult.supportMethod}`
        : "0",
      // 支援実施方法・場所(その他)
      supportMethodOther: usageResult.supportMethodOther
        ? usageResult.supportMethodOther
        : "",
      // 支援開始
      supportStartTime: usageResult.supportStartTime
        ? convertHHMMSSToHHMM(usageResult.supportStartTime)
        : "",
      // 支援終了
      supportEndTime: usageResult.supportEndTime
        ? convertHHMMSSToHHMM(usageResult.supportEndTime)
        : "",
      // 備考
      remarks: usageResult.remarks ? usageResult.remarks : ""
    };
  });

  return {
    ReportData: {
      shareSupportReportDate: initializedShareSupportReportDate,
      usageResults: initializedUsageResults
    }
  };
};
