import * as types from "./types";
import * as actions from "./actions";
import { Checkbox } from "@constants/variables";

const initialState: types.ServiceDeliveryState = {
  dailyRecord: {
    service_delivery: [],
    number_of_plans: 0,
    number_of_records: 0,
    number_of_procedures: 0
  },
  monthlyRecord: {
    service_delivery: [],
    number_of_plans: 0,
    number_of_records: 0,
    number_of_procedures: 0
  },
  detailsRecord: {
    serviceDeliveryRecordsId: null,
    inoutResultsId: null,
    supportProcedureFormsId: null,
    createdAt: null,
    updatedAt: null,
    usersInFacilityId: 0,
    nameSei: "",
    nameMei: "",
    targetDate: "",
    status: "",
    statusText: "",
    emergencySupportFlg: false,
    emergencyGuideSupportFlg: false,
    sputumImplementationFlg: false,
    bodyRestrictedStillFlg: false,
    accompanySupportFlg: false,
    accompanySupportDisplayFlg: false,
    numberOfPractitioner: 1,
    numberOfSupporters: null,
    physicalCareFlg: false,
    serviceDeliveryRecordPractitioners: [],
    supportRatioPerPractitioner: null,
    licenseSameFlg: false,
    municipality: {
      calculationTimeFlg: null,
      roundUpMinute: null
    }
  },
  print: [],
  user: {
    user_in_facility: {
      none_recipient_number_flg: Checkbox.OFF,
      gender: "1",
      income_kind: "1",
      subsidized_flg: Checkbox.OFF,
      subsidized_unit_flg: "1",
      uplimit_facility_flg: Checkbox.OFF,
      result_of_management: "1",
      create_support_plan_flg: Checkbox.ON,
      uplimit_controlled_by: "1"
    }
  }
};

export const serviceDelivery = (
  state = initialState,
  action: actions.ActionTypes
): types.ServiceDeliveryState => {
  switch (action.type) {
    case types.FETCH_DAILY_RECORD_SUCCESS:
      return { ...state, dailyRecord: action.payload };
    case types.FETCH_MONTHLY_RECORD_SUCCESS:
      return { ...state, monthlyRecord: action.payload };
    case types.FETCH_DETAILS_RECORD_SUCCESS:
      return { ...state, detailsRecord: action.payload };
    case types.FETCH_PRINT_RECORD_SUCCESS:
      return { ...state, print: action.payload };
    case types.FETCH_DAILY_RECORD_FAILED:
    case types.FETCH_MONTHLY_RECORD_FAILED:
    case types.FETCH_DETAILS_RECORD_FAILED:
    case types.DELETE_FAILED:
      return { ...state, ...action.error };
    case types.DELETE_STARTED:
      return { ...state };
    case types.DELETE_SUCCESS:
      return { ...state };
    case types.CLEAR_RECORD_DETAIL_STATE:
      return { ...state, detailsRecord: initialState.detailsRecord };
    case types.CLEAR_RECORD_PRINT_STATE:
      return { ...state, print: initialState.print };
    case types.FETCH_USER_SUCCESS:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};
