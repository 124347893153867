import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { PlanMonitoringMeetingApi } from "@api/requests/planMonitoringMeeting";

export const fetchPlanMonitoringMeeting = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchPlanMonitoringMeetingStarted());
  await PlanMonitoringMeetingApi.getPlanMonitoringMeeting(uifId)
    .then((res) => {
      dispatch(actions.fetchPlanMonitoringMeetingSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchPlanMonitoringMeetingFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchPlanMonitoringMeeting: (uifId: string) => Promise<void>;
};

export const CHIIKIIKOPlanMonitoringMeetingDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchPlanMonitoringMeeting: fetchPlanMonitoringMeeting(dispatch)
});
