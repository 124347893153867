import * as React from "react";

import { RecordSupportTableRow } from "@components/organisms/mgr/Cseg/record/RecordSupportTableRow";
import { CHIIKIIKO_DISPLAY_STATUS_LIST } from "@constants/mgr/CHIIKIIKO/variables";
import {
  FACILITY_TARGET_TYPE_KEIKAKUSODAN,
  FACILITY_TARGET_TYPE_SHOGAIJISODAN,
  STATUS_LIST,
  STATUS_LIST_SHOGAIJISODAN
} from "@constants/mgr/KEIKAKUSODAN/variables";
import { CHIIKITEICHAKU_DISPLAY_STATUS_LIST } from "@constants/mgr/CHIIKITEICHAKU/variables";
import { FacilityType } from "@constants/variables";
import {
  initialValues,
  SupportRecordFormValues
} from "@initialize/mgr/Cseg/record/support/initialValues";
import { validation } from "@initialize/mgr/Cseg/record/support/validation";
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import {
  SupportRecordsState,
  SupportRecordUserState
} from "@stores/domain/mgr/Cseg/support/types";
import { StaffState } from "@stores/domain/staff/types";
import { AppState } from "@stores/type";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import { toEffectiveObject } from "@utils/object";
import { endOfMonth, max, min, startOfMonth } from "date-fns";
import deepEqual from "fast-deep-equal";
import { Form, Formik, FormikActions, FormikProps } from "formik";
import { connect } from "react-redux";
import { SnackbarParams } from "@stores/ui/type";

const styles = (): StyleRules =>
  createStyles({
    tableWrapper: {
      paddingTop: "32px"
    }
  });

type OwnProps = {
  uifId: string;
  calendarSelectedDate: Date;
  dateBeginInService: string;
  supportRecord: SupportRecordUserState;
  editTargetDate: string | undefined;
  dateEndInService: string | Date | undefined;
  setEditTargetDate: (v: string | undefined) => void;
  stopHistory: (flag: boolean) => void;
  postSupportRecordUser: (
    uifId: string,
    editingRecordDate: string,
    params: SupportRecordFormValues[],
    initialValues: SupportRecordFormValues[]
  ) => Promise<void>;
  fetchSupportRecordUser: (uifId: string, year: string, month: string) => void;
  showSnackbar: (params: SnackbarParams) => void;
};

type StateProps = {
  holiday: string[];
  staffs: StaffState;
  needsStopHistory: boolean;
  facilityType: FacilityType;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const UserDetailRecordSupportTableCore = (props: Props): JSX.Element => {
  const {
    classes,
    holiday,
    staffs,
    needsStopHistory,
    facilityType,
    uifId,
    supportRecord,
    calendarSelectedDate,
    dateBeginInService,
    editTargetDate,
    dateEndInService,
    setEditTargetDate,
    stopHistory,
    postSupportRecordUser,
    fetchSupportRecordUser,
    showSnackbar
  } = props;

  const staffOptions = generateSelectFieldItems(
    staffs.staffItems,
    "staffName",
    "staffItemId",
    false
  );

  const findStatusList = React.useCallback(
    (typeConsultation?: number): { label: string; value: string }[] => {
      switch (facilityType) {
        case FacilityType.KEIKAKUSODAN:
          switch (typeConsultation) {
            case FACILITY_TARGET_TYPE_KEIKAKUSODAN:
              return STATUS_LIST;
            case FACILITY_TARGET_TYPE_SHOGAIJISODAN:
              return STATUS_LIST_SHOGAIJISODAN;
            default:
              return STATUS_LIST;
          }
        case FacilityType.CHIIKIIKO:
          return CHIIKIIKO_DISPLAY_STATUS_LIST;
        case FacilityType.CHIIKITEICHAKU:
          return CHIIKITEICHAKU_DISPLAY_STATUS_LIST;
        default:
          return [];
      }
    },
    [facilityType]
  );

  const [formValues, setFormValues] = React.useState<SupportRecordFormValues[]>(
    []
  );

  React.useEffect(() => {
    const startDate = max(
      dateBeginInService,
      startOfMonth(calendarSelectedDate)
    );

    const endDateCandidates = [
      dateEndInService,
      endOfMonth(calendarSelectedDate),
      new Date()
    ].filter((v): v is Exclude<typeof v, undefined> => v !== undefined);

    const endDate = min(...endDateCandidates);

    setFormValues(
      initialValues(
        supportRecord.support_records,
        startDate,
        endDate,
        calendarSelectedDate
      )
    );
  }, [supportRecord, dateEndInService]);

  const confirmDiscardFormChanges = (
    nextValues: SupportRecordFormValues[]
  ): void => {
    const hasChange = !deepEqual(nextValues, formValues);
    stopHistory(hasChange);
  };

  const validateForm = (
    values: SupportRecordFormValues[]
  ): object | undefined => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory) {
      confirmDiscardFormChanges(values);
    }
    return error;
  };

  const onSubmit = async (
    values: SupportRecordFormValues[],
    actions: FormikActions<SupportRecordsState>
  ): Promise<void> => {
    actions.setSubmitting(true);
    await postSupportRecordUser(
      uifId,
      editTargetDate as string, // 編集中には日付が必ず入る想定
      values,
      formValues
    )
      .then(() => {
        stopHistory(false);
        const [year, month] = (editTargetDate as string).split("-");
        fetchSupportRecordUser(uifId, year, month);
      })
      .finally(() => {
        actions.setSubmitting(false);
        setEditTargetDate(undefined);
      });
  };

  const onClickEdit = React.useCallback(
    (date: string): void => {
      setEditTargetDate(date);
    },
    [supportRecord]
  );

  const onClickCancel = React.useCallback(
    (formik: FormikProps<SupportRecordsState>) => {
      setEditTargetDate(undefined);
      formik.resetForm();
      stopHistory(false);
    },
    []
  );

  const submitError = React.useCallback(() => {
    showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  }, []);

  return (
    <div className={classes.tableWrapper}>
      <Formik
        initialValues={formValues}
        validate={validateForm}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {(formikProps): JSX.Element => {
          return (
            <Form>
              {formValues.map((record, index) => {
                return (
                  <RecordSupportTableRow
                    key={record.target_date}
                    date={record.target_date}
                    isHoliday={holiday.includes(record.target_date)}
                    staffOptions={staffOptions}
                    formikNamePrefix={`[${index}]`}
                    supportRecord={record}
                    formikProps={formikProps}
                    editTargetDate={editTargetDate}
                    statusList={findStatusList(record.type_consultation)}
                    onClickEdit={onClickEdit}
                    onClickCancel={onClickCancel}
                    submitError={submitError}
                  />
                );
              })}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  holiday: state.holiday,
  staffs: state.staff,
  needsStopHistory: state.ui.needsStopHistory,
  facilityType: state.user.facility_type
});

export const UserDetailRecordSupportTable = withStyles(styles)(
  connect(mapStateToProps)(UserDetailRecordSupportTableCore)
);
