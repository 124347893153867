import castNumber from "@utils/dataNormalizer/castNumber";

type TimeFrame = {
  // 日中：8:00~18:00
  daytime: number;
  // 夜間：18:00~22:00
  nighttime: number;
  // 深夜：22:00~6:00
  lateNight: number;
  // 早朝：6:00~8:00
  earlyMorning: number;
};
const TIME_CLASS = {
  TODAY: "0",
  NEXT_DAY: "1"
};

// 空き時間に被っているかどうかを判定する
const checkTimeRange = (
  target: number,
  list: {
    inTime: string;
    inTimeClass: string;
    outTime: string;
    outTimeClass: string;
  }[]
): boolean => {
  const timeList = list.map((x) => {
    const inTimesH = castNumber(x.inTime.split(":")[0]);
    const inTimesM = castNumber(x.inTime.split(":")[1]);
    const outTimesH = castNumber(x.outTime.split(":")[0]);
    const outTimesM = castNumber(x.outTime.split(":")[1]);
    const inTime =
      (x.inTimeClass === TIME_CLASS.NEXT_DAY ? inTimesH + 24 : inTimesH) * 60 +
      inTimesM;
    const outTime =
      (x.outTimeClass === TIME_CLASS.NEXT_DAY ? outTimesH + 24 : outTimesH) *
        60 +
      outTimesM;
    return { inTime, outTime };
  });
  return timeList.length >= 1
    ? timeList.every((x) => !(target > x.inTime && target <= x.outTime))
    : true;
};

/**
 * 時間帯ごとの所要時間の計算
 * ※返却は分単位
 */
export const calculateTheRequiredTimeOfEachTimeframe = (
  inTimeData: string | null,
  outTimeData: string | null,
  outTimeClass: string,
  subtractTime: {
    inTime: string;
    inTimeClass: string;
    outTime: string;
    outTimeClass: string;
  }[]
): TimeFrame => {
  const result = {
    daytime: 0,
    nighttime: 0,
    lateNight: 0,
    earlyMorning: 0
  };

  if (
    inTimeData === null ||
    outTimeData === null ||
    inTimeData === "" ||
    outTimeData === ""
  )
    return result;

  // 0:00を基準に分に変換(1:30→90)。翌日フラグのものは＋24時間
  const inTimesH = castNumber(inTimeData.split(":")[0]);
  const inTimesM = castNumber(inTimeData.split(":")[1]);
  const outTimesH = castNumber(outTimeData.split(":")[0]);
  const outTimesM = castNumber(outTimeData.split(":")[1]);
  const inTime = inTimesH * 60 + inTimesM;
  const outTime =
    (outTimeClass === TIME_CLASS.NEXT_DAY ? outTimesH + 24 : outTimesH) * 60 +
    outTimesM;

  // 1分ごとに30分ずつのリストに分ける（空き時間はスキップ）
  // (例)0:00~1:00の場合
  // [
  //  [0,1,2 ・・・・・ 28,29], 30分
  //  [30,31,32 ・・・・・ 58,59], 30分
  // ]
  let count = 1;
  const totalList = [];
  let halfTimeList = [];
  for (let i = 1; inTime + i <= outTime; i += 1) {
    const target = inTime + i;
    if (checkTimeRange(target, subtractTime)) {
      halfTimeList.push(target);
      if (count === 30 || target === outTime) {
        totalList.push(halfTimeList);
        count = 0;
        halfTimeList = [];
      }
      count += 1;
    } else if (target === outTime) {
      totalList.push(halfTimeList);
      count = 0;
      halfTimeList = [];
    }
  }

  totalList.forEach((list) => {
    const item = {
      daytime: 0,
      nighttime: 0,
      lateNight: 0,
      earlyMorning: 0
    };
    // 30分区切りで時間帯の判定をする
    list.forEach((x) => {
      if (x <= 1440) {
        if (x >= 8 * 60 && x < 18 * 60) {
          item.daytime += 1;
        } else if (x >= 18 * 60 && x < 22 * 60) {
          item.nighttime += 1;
        } else if ((x >= 22 * 60 && x <= 24 * 60) || (x >= 0 && x < 6 * 60)) {
          item.lateNight += 1;
        } else if (x >= 6 * 60 && x < 8 * 60) {
          item.earlyMorning += 1;
        }
      }
      // 翌日の場合
      if (x > 1440) {
        if (x >= (8 + 24) * 60 && x < (18 + 24) * 60) {
          item.daytime += 1;
        } else if (x >= (18 + 24) * 60 && x < (22 + 24) * 60) {
          item.nighttime += 1;
        } else if (
          (x >= (22 + 24) * 60 && x < (24 + 24) * 60) ||
          (x >= 24 * 60 && x < (6 + 24) * 60)
        ) {
          item.lateNight += 1;
        } else if (x >= (6 + 24) * 60 && x < (8 + 24) * 60) {
          item.earlyMorning += 1;
        }
      }
    });
    // 時間またぎの時
    const keys = Object.keys(item).filter((x) => item[x] > 0);
    if (keys.length >= 2) {
      let sum = 0;
      keys.forEach((x) => {
        sum += item[x];
      });
      // 全て同じ時間だった場合
      if (keys.every((x) => item[x] === item[keys[0]])) {
        keys.forEach((x) => {
          item[x] = 0;
        });
        item[keys[0]] = sum;
      } else {
        // 一番値の大きい時間帯に入れる
        const maxNum = Object.values(item)
          .sort((a, b) => b - a)
          .shift();
        const key = keys.find((x) => item[x] === maxNum);
        keys.forEach((x) => {
          item[x] = 0;
        });
        if (key) item[key] = sum;
      }
    }
    keys.forEach((x) => {
      result[x] += item[x];
    });
  });

  return result;
};
