import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";

import {
  getIndexes,
  getInput
} from "@utils/domain/mgr/CHIIKITEICHAKU/supportLedger/customRecordGetter";

/**
 * カスタムレコードを元に表示する項目だけ抽出しソートする
 * 抽出条件は、台帳詳細画面で使用する値
 */
export const normalizeDisplayCustomRecord = (
  customRecords: CustomRecordsWithCategoryState,
  supportLedgerFormValues: SupportLedger,
  categoryType: number
): CustomRecordsWithCategoryState => {
  return customRecords
    .filter((category) => category.category_type === categoryType)
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    })
    .map((category) => {
      const { custom_record_items } = category;
      const customRecordItems = custom_record_items
        .filter((item) => {
          const input = getInput(
            supportLedgerFormValues,
            getIndexes(supportLedgerFormValues, item)
          );
          return (
            (category.visibility && item.visibility) ||
            input.input_data ||
            !!input.choiced_item_id ||
            input.checkedItems ||
            input.life_histories ||
            input.related_organization ||
            input.relatives_info ||
            input.disability_histories
          );
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...category, custom_record_items: customRecordItems };
    })
    .filter((r) => r.visibility || r.custom_record_items.length);
};

/**
 * 印刷プレビュー用
 * カスタムレコードを元に表示する項目だけ抽出しソートする
 * 抽出条件は、台帳詳細画面で使用する値
 */
export const normalizeDisplayCustomRecordPrint = (
  customRecords: CustomRecordsWithCategoryState,
  supportLedgerFormValues: SupportLedger,
  categoryType: number
): CustomRecordsWithCategoryState => {
  return customRecords
    .filter((category) => category.category_type === categoryType)
    .sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order - b.order;
    })
    .map((category) => {
      const { custom_record_items } = category;
      const customRecordItems = custom_record_items
        .filter((item) => {
          const input = getInput(
            supportLedgerFormValues,
            getIndexes(supportLedgerFormValues, item)
          );
          return (
            input.input_data ||
            !!input.choiced_item_id ||
            input.checkedItems ||
            input.life_histories ||
            input.related_organization ||
            input.relatives_info ||
            input.disability_histories
          );
        })
        .sort((a, b) => {
          if (!a.order && !b.order) return 0;
          if (!a.order) return 1;
          if (!b.order) return -1;
          return a.order - b.order;
        });
      return { ...category, custom_record_items: customRecordItems };
    })
    .filter((r) => r.visibility || r.custom_record_items.length);
};
