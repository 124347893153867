import * as action from "@stores/domain/user/action";

import { reducerWithInitialState } from "typescript-fsa-reducers";

import { UserResult, UserState } from "@stores/domain/user/type";
import { USER_ROLE } from "@constants/variables";

const doneMe = (
  state: UserState,
  { result }: { result: UserResult }
): UserState => ({
  ...state,
  ...result,
  done: true,
  isLoading: true,
  isMobile: result.role === USER_ROLE.mgrmobile
});

const startedMe = (state: UserState): UserState => ({
  ...state,
  done: false,
  isLoading: true
});

const setFacilityValuesToUser = (
  state: UserState,
  payload: {
    facilityName: UserState["facility_name"];
    businessOwner: UserState["business_owner"];
    multiFunctionOfficeFlag: UserState["isMultipleFacility"];
    masterSubordinateFlg: UserState["isMasterSubordinate"];
  }
): UserState => {
  return {
    ...state,
    facility_name: payload.facilityName,
    business_owner: payload.businessOwner,
    isMultipleFacility: payload.multiFunctionOfficeFlag,
    isMasterSubordinate: payload.masterSubordinateFlg
  };
};

const updateAssociatedFacility = (state: UserState): UserState => {
  return {
    ...state
  };
};

const initUserState = {} as UserState;

export default reducerWithInitialState(initUserState)
  .case(action.me.started, startedMe)
  .case(action.me.done, doneMe)
  .case(action.setFacilityValuesToUser, setFacilityValuesToUser)
  .case(action.updateAssociatedFacility, updateAssociatedFacility);
