import * as React from "react";
import * as classNames from "classnames";

import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import DropDown from "@components/atoms/DropDown";
import MuiTextField from "@components/molecules/MuiTextField";
import createOneToNumberOptions from "@utils/createOneToNumberOptions";
import {
  getLastDay,
  dateToLocalisedString,
  parseDateString,
  getWareki
} from "@utils/date";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      padding: `${spacing.unit}px ${spacing.unit * 2}px`,
      backgroundColor: "#fff",
      display: "flex"
    },
    dropDown: {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      width: 75
    },
    yearTextField: {
      width: 128,
      marginBottom: 0
    },
    monthTextField: {
      marginTop: 16,
      marginBottom: 0,
      width: 72
    }
  });

type OwnProps = {
  id: string;
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  isError?: boolean;
};

type OptionState = { label: string; value: string | number }[];

type Props = OwnProps & WithStyles<typeof styles>;

const DateSelectFieldsDayOnlyCore = (props: Props): JSX.Element => {
  const { classes, isError, helperText, id } = props;
  const [dayOptions, setDayOptions] = React.useState<OptionState>([]);
  const [day, setDay] = React.useState<string>("");

  // 年月日取得
  const getCurrentDate = (
    dateValue: string
  ): { year: string; month: string; day: string } => {
    const date = {
      year: "",
      month: "",
      day: ""
    };
    if (dateValue) {
      const dt = parseDateString(dateValue);
      date.year = `${dt.getFullYear()}`;
      date.month = `${dt.getMonth() + 1}`;
      date.day = `${dt.getDate()}`;
    }
    return date;
  };

  const { year } = getCurrentDate(props.value);
  const { month } = getCurrentDate(props.value);

  // 月末日取得
  const getLastDayOfMonth = (): number => {
    return year && month ? getLastDay(Number(year), Number(month)) : 0;
  };
  const lastDay = getLastDayOfMonth();

  React.useEffect(() => {
    const options = createOneToNumberOptions(lastDay, "日", false);
    setDay(getCurrentDate(props.value).day);
    setDayOptions(options);
  }, []);

  const validateAllCompleted = (
    e: React.ChangeEvent<HTMLInputElement>,
    dateStr: string
  ): void => {
    e.target.value = dateStr;
    e.currentTarget = e.target;
    props.onChange(e);
  };

  const onChangeDay = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const targetDay = e.target.value;
    setDay(targetDay);

    if (!year || !month || !targetDay) {
      validateAllCompleted(e, "");
      return;
    }

    const datestr = dateToLocalisedString(
      new Date(Number(year), Number(month) - 1, Number(targetDay)),
      "YYYY-MM-DD"
    );

    validateAllCompleted(e, datestr);
  };

  const yearValue = `${year}年 (${getWareki(year)})`;
  const monthValue = `${month}月`;

  return (
    <div className={classNames(classes.root)}>
      <MuiTextField
        id={`${id}-year`}
        value={yearValue}
        label={props.label}
        disabled
        disabledStyle
        style={{ width: 240, marginBottom: 0 }}
      />
      <MuiTextField
        id={`${id}-month`}
        value={monthValue}
        disabled
        disabledStyle
        style={{ marginTop: 16, marginBottom: 0, width: 72 }}
      />
      <DropDown
        id={`${id}-day`}
        label={day ? " " : "日"}
        options={dayOptions}
        styles={classes.dropDown}
        onChange={onChangeDay}
        value={`${day}`}
        isError={isError}
        helperText={helperText}
      />
    </div>
  );
};

export const DateSelectFieldsDayOnly = withStyles(styles)(
  DateSelectFieldsDayOnlyCore
);
