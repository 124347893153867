import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import customRecordsApi from "@api/requests/customRecords";
import {
  CustomRecordsValues,
  CustomRecordsValuesForService,
  CustomRecordItemValues
} from "@initialize/record/customRecordWithCategory/initialValues";
import {
  normalizeCustomRecordsCategoryDataToAPI,
  normalizeCustomRecordsDataToAPI,
  normalizeCustomRecordsDataToAPIForService,
  normalizeCustomRecordsOrderDataToAPI,
  normalizeCustomRecordsCategoryOrderDataToAPI,
  normalizeCustomRecordsOrderDataToAPIForService,
  normalizeHideCustomRecords,
  normalizeShowCustomRecords,
  normalizeChangeVisibilityCustomRecord
} from "@stores/domain/customRecordsWithCategory/normalizer";
import dispatches from "@stores/dispatches";
import { CustomRecordsWithCategoryState } from "@stores/domain/customRecordsWithCategory/types";
import { HiddenChoiceState } from "@stores/domain/customRecords/types";
import { CUSTOM_RECORD_TARGET_TYPE } from "@constants/variables";
import { postCustomRecordsVisibilityParams } from "@api/requests/customRecords/postCustomRecordsVisibility";

const isAssessmentValue = (
  param: CustomRecordsWithCategoryState | CustomRecordItemValues
): param is CustomRecordsWithCategoryState => {
  return !!("category_type" in param[0]);
};

export const fetchCustomRecords = (dispatch: Dispatch) => async (
  target?: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCustomRecordsStarted());
  await customRecordsApi
    .getCustomRecordsWithCategory(target)
    .then((res) => {
      dispatch(actions.fetchCustomRecordsSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// サービス提供記録用カスタムレコード取得
export const fetchCustomRecordsService = (
  dispatch: Dispatch
) => async (): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchCustomRecordsStartedService());
  await customRecordsApi
    .getCustomRecordsWithCategory(CUSTOM_RECORD_TARGET_TYPE.service_delivery)
    .then((res) => {
      dispatch(actions.fetchCustomRecordsSuccessService(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchCustomRecordsFailedService({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 項目追加、編集
export const postCustomRecords = (dispatch: Dispatch) => async (
  param: CustomRecordsValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsStarted());

  const normalizedParam = normalizeCustomRecordsDataToAPI(param);

  await customRecordsApi
    .postCustomRecords(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 項目追加、編集(サービス提供記録用)
export const postCustomRecordsForService = (dispatch: Dispatch) => async (
  param: CustomRecordsValuesForService
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsStarted());

  const normalizedParam = normalizeCustomRecordsDataToAPIForService(param);

  await customRecordsApi
    .postCustomRecords(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// カテゴリー追加、編集
export const postCustomRecordsCategory = (dispatch: Dispatch) => async (
  param: CustomRecordsValues
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsStarted());
  const normalizedParam = normalizeCustomRecordsCategoryDataToAPI(param);
  await customRecordsApi
    .postCustomRecordsCategory(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// カテゴリー並び順変更
export const postCustomRecordsCategoryOrder = (dispatch: Dispatch) => async (
  visibleCustomRecords: CustomRecordsWithCategoryState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsCategoryOrderStarted());
  const normalizedParam = normalizeCustomRecordsCategoryOrderDataToAPI(
    visibleCustomRecords
  );
  await customRecordsApi
    .postCustomRecordsCategoryOrder(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsCategoryOrderSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(
        actions.postCustomRecordsCategoryOrderFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 項目並び順変更
export const postCustomRecordsOrder = (dispatch: Dispatch) => async (
  visibleCustomRecords: CustomRecordsWithCategoryState | CustomRecordItemValues,
  customRecordCategoryId: number
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postCustomRecordsOrderStarted());
  const normalizedParam = isAssessmentValue(visibleCustomRecords)
    ? normalizeCustomRecordsOrderDataToAPI(
        visibleCustomRecords,
        customRecordCategoryId
      )
    : normalizeCustomRecordsOrderDataToAPIForService(
        visibleCustomRecords,
        customRecordCategoryId
      );

  await customRecordsApi
    .postCustomRecordsOrder(normalizedParam)
    .then(() => {
      dispatch(actions.postCustomRecordsOrderSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postCustomRecordsOrderFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const hideCustomRecord = (dispatch: Dispatch) => async (
  recordId: number,
  type: "item" | "category"
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.HideCustomRecordStarted());
  const normalizedParam = normalizeHideCustomRecords(recordId, type);
  await customRecordsApi
    .postCustomRecordsVisibility(normalizedParam)
    .then(() => {
      dispatch(actions.HideCustomRecordSuccess());
    })
    .catch((e) => {
      dispatch(actions.HideCustomRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const showCustomRecord = (dispatch: Dispatch) => async (
  visibleRecordIds: {
    item: number[];
    category: number[];
  },
  invisibleRecordIds: {
    item: number[];
    category: number[];
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.ShowCustomRecordStarted());

  const normalizedParam = normalizeShowCustomRecords(
    visibleRecordIds,
    invisibleRecordIds
  );
  await customRecordsApi
    .postCustomRecordsVisibility(normalizedParam)
    .then(() => {
      dispatch(actions.ShowCustomRecordSuccess());
    })
    .catch((e) => {
      dispatch(actions.ShowCustomRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const changeVisibilityCustomRecord = (dispatch: Dispatch) => async (
  customRecordItems: postCustomRecordsVisibilityParams["custom_record_items"],
  customRecordsCategory: postCustomRecordsVisibilityParams["custom_records_category"]
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.ChangeVisibilityCustomRecordStarted());
  const normalizedParam = normalizeChangeVisibilityCustomRecord(
    customRecordItems,
    customRecordsCategory
  );
  await customRecordsApi
    .postCustomRecordsVisibility(normalizedParam)
    .then(() => {
      dispatch(actions.ChangeVisibilityCustomRecordSuccess());
    })
    .catch((e) => {
      dispatch(
        actions.ChangeVisibilityCustomRecordFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

// 選択肢削除
export const postHiddenChoice = (dispatch: Dispatch) => async (
  param: HiddenChoiceState
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postHiddenChoiceStarted());
  await customRecordsApi
    .postHiddenChoice(param)
    .then(() => {
      dispatch(actions.postHiddenChoiceSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
    })
    .catch((e) => {
      dispatch(actions.postHiddenChoiceFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchCustomRecords: (target?: number) => Promise<void>;
  fetchCustomRecordsService: () => Promise<void>;
  postCustomRecords: (param: CustomRecordsValues) => Promise<void>;
  postCustomRecordsForService: (
    param: CustomRecordsValuesForService
  ) => Promise<void>;
  postCustomRecordsCategory: (param: CustomRecordsValues) => Promise<void>;
  postCustomRecordsOrder: (
    visibleCustomRecords:
      | CustomRecordsWithCategoryState
      | CustomRecordItemValues,
    customRecordCategoryId: number
  ) => Promise<void>;
  postCustomRecordsCategoryOrder: (
    visibleCustomRecords: CustomRecordsWithCategoryState
  ) => Promise<void>;
  hideCustomRecord: (
    recordId: number,
    type: "item" | "category"
  ) => Promise<void>;
  showCustomRecord: (
    visibleRecordIds: {
      item: number[];
      category: number[];
    },
    invisibleRecordIds: {
      item: number[];
      category: number[];
    }
  ) => Promise<void>;
  changeVisibilityCustomRecord: (
    customRecordItems: postCustomRecordsVisibilityParams["custom_record_items"],
    customRecordsCategory: postCustomRecordsVisibilityParams["custom_records_category"]
  ) => Promise<void>;
  postHiddenChoice: (param: HiddenChoiceState) => Promise<void>;
};

export const customRecordsWithCategoryDispatcher = (
  dispatch: Dispatch
): Dispatcher => ({
  fetchCustomRecords: fetchCustomRecords(dispatch),
  fetchCustomRecordsService: fetchCustomRecordsService(dispatch),
  postCustomRecords: postCustomRecords(dispatch),
  postCustomRecordsForService: postCustomRecordsForService(dispatch),
  postCustomRecordsCategory: postCustomRecordsCategory(dispatch),
  postCustomRecordsOrder: postCustomRecordsOrder(dispatch),
  postCustomRecordsCategoryOrder: postCustomRecordsCategoryOrder(dispatch),
  hideCustomRecord: hideCustomRecord(dispatch),
  showCustomRecord: showCustomRecord(dispatch),
  changeVisibilityCustomRecord: changeVisibilityCustomRecord(dispatch),
  postHiddenChoice: postHiddenChoice(dispatch)
});
