import * as types from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/types";
import {
  NormalizedGetFacilityResponse,
  NormalizedPostFacilityParams
} from "@stores/v202104/domain/mgr/KEIKAKUSODAN/facility/normalizer";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchStarted = () => ({ type: types.FETCH_STARTED } as const);
export const fetchSuccess = (res: NormalizedGetFacilityResponse) =>
  ({ type: types.FETCH_SUCCESS, payload: res } as const);
export const fetchFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_FAILED, error: err } as const);
export const postStarted = () => ({ type: types.POST_STARTED } as const);
export const postSuccess = (res: NormalizedPostFacilityParams) =>
  ({ type: types.POST_SUCCESS, payload: res } as const);
export const postFailed = (err: ErrorResponse) =>
  ({ type: types.POST_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchStarted>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof fetchFailed>
  | ReturnType<typeof postStarted>
  | ReturnType<typeof postSuccess>
  | ReturnType<typeof postFailed>;
