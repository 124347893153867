import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type GetAssessmentListResponse = {
  data: {
    id: number;
    target_date: string;
    author_name: string;
    type_consultation: number;
  }[];
};

/**
 * アセスメント一覧のデータ取得
 * @param uifId 事業所所属ユーザーのID
 */
export const getAssessmentList = async (
  uifId: string
): Promise<AxiosResponse<GetAssessmentListResponse>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assessment`;
  return request.get<GetAssessmentListResponse>(url);
};
