import {
  RecordSupportsValues,
  RecordOperationValue
} from "@initialize/mgr/TANKINYUSHO/record/dailyRecord/initialValues";
import omitByNoChanges from "@utils/dataNormalizer/omitByNoChanges";
import { PostCustomSupportParams } from "@api/requests/supports/postCustomSupport";
import castCheckBoxToNumber from "@utils/dataNormalizer/castCheckBoxToNumber";
import { PostCustomOperationParams } from "@api/requests/operations/TANKINYUSHO/postCustomOperation";

const normalizeSupportCustomRecords = (
  postCustomRecord: RecordSupportsValues["record"][0]["custom_record"],
  initialDataCustomRecord: RecordSupportsValues["record"][0]["custom_record"]
): PostCustomSupportParams["custom_record"] => {
  const resCustom = [] as PostCustomSupportParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDatakeys = Object.keys(diffData);

  // 入力タイプ「テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_first")) {
    const diffDataFirst = omitByNoChanges(
      postCustomRecord.input_type_first,
      initialDataCustomRecord.input_type_first
    );
    const record = Object.values(
      diffDataFirst
    ) as PostCustomSupportParams["custom_record"];
    resCustom.push(...record);
  }

  // 入力タイプ「チェックボックス」
  if (diffDatakeys.some((item) => item === "input_type_second")) {
    const diffDataSecond = omitByNoChanges(
      postCustomRecord.input_type_second,
      initialDataCustomRecord.input_type_second
    );
    const records = Object.values(
      diffDataSecond
    ) as PostCustomSupportParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => {
        return {
          custom_record_input_id: item[key].custom_record_input_id,
          custom_record_item_id: item[key].custom_record_item_id,
          choiced_item_id: item[key].choiced_item_id,
          checked: castCheckBoxToNumber(item[key].checked)
        };
      });
      resCustom.push(...postRecord);
    });
  }

  // 入力タイプ「複数テキスト入力」
  if (diffDatakeys.some((item) => item === "input_type_fourth")) {
    const diffDataFourth = omitByNoChanges(
      postCustomRecord.input_type_fourth,
      initialDataCustomRecord.input_type_fourth
    );
    const records = Object.values(
      diffDataFourth
    ) as PostCustomSupportParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => item[key]);
      resCustom.push(...postRecord);
    });
  }
  return resCustom;
};

const normalizeOperationCustomRecords = (
  postCustomRecord: RecordOperationValue["record"]["custom_record"],
  initialDataCustomRecord: RecordOperationValue["record"]["custom_record"]
): PostCustomOperationParams["custom_record"] => {
  const resCustom = [] as PostCustomOperationParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);
  const diffDataKeys = Object.keys(diffData);

  // 入力タイプ「テキスト入力」
  if (diffDataKeys.some((item) => item === "input_type_first")) {
    const diffDataFirst = omitByNoChanges(
      postCustomRecord.input_type_first,
      initialDataCustomRecord.input_type_first
    );
    const record = Object.values(
      diffDataFirst
    ) as PostCustomOperationParams["custom_record"];
    resCustom.push(...record);
  }

  // 入力タイプ「チェックボックス」
  if (diffDataKeys.some((item) => item === "input_type_second")) {
    const diffDataSecond = omitByNoChanges(
      postCustomRecord.input_type_second,
      initialDataCustomRecord.input_type_second
    );
    const records = Object.values(
      diffDataSecond
    ) as PostCustomOperationParams["custom_record"];
    records.forEach((item) => {
      const postRecord = Object.keys(item).map((key) => {
        return {
          custom_record_input_id: item[key].custom_record_input_id,
          custom_record_item_id: item[key].custom_record_item_id,
          choiced_item_id: item[key].choiced_item_id,
          checked: castCheckBoxToNumber(item[key].checked)
        };
      });
      resCustom.push(...postRecord);
    });
  }
  return resCustom;
};

const normalizeSupportCustomRecordInputTypeThird = (
  postCustomRecord: RecordSupportsValues["record"][0]["custom_record"]["input_type_third"],
  initialDataCustomRecord: RecordSupportsValues["record"][0]["custom_record"]["input_type_third"]
): PostCustomSupportParams["custom_record"] => {
  const resCustom = [] as PostCustomSupportParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDatakeys = Object.keys(diffData);

  Object.entries(postCustomRecord).forEach(([key, value]) => {
    const isDeleteItem = value.itemIdList.some(
      (item) => item.is_delete !== null
    );

    const isDiffCustomRecord = diffDatakeys.some((item) => item === key);

    if (value && (isDiffCustomRecord || isDeleteItem)) {
      // 新規追加またはchecked「OFF」変更（「is_delete=1」の場合）
      const newRecord = [] as PostCustomSupportParams["custom_record"];
      value.itemIdList.forEach((item) => {
        const beforeValue = value.beforeValues.find(
          (beforeValueItem) => beforeValueItem.choiced_staff_id === item.id
        );

        const record = {
          custom_record_input_id: beforeValue
            ? beforeValue.custom_record_input_id
            : null,
          custom_record_item_id: Number(key),
          choiced_staff_id: beforeValue
            ? beforeValue.choiced_staff_id
            : Number(item.id),
          choiced_staff_name_snapshot: item.label,
          checked: item.is_delete === 1 ? 0 : 1
        };
        newRecord.push(record);
      });

      // checked「OFF」変更処理
      const unCheckRecord = value.beforeValues
        .filter((beforeValueItem) => {
          return !value.itemIdList.find(
            (itemIdListItem) =>
              beforeValueItem.choiced_staff_id === itemIdListItem.id
          );
        })
        .map((unCheckBeforeValue) => {
          return {
            custom_record_input_id: unCheckBeforeValue.custom_record_input_id,
            custom_record_item_id: Number(key),
            choiced_staff_id: unCheckBeforeValue.choiced_staff_id,
            choiced_staff_name_snapshot:
              unCheckBeforeValue.choiced_staff_name_snapshot,
            checked: 0
          };
        });

      resCustom.push(...newRecord, ...unCheckRecord);
    }
  });

  return resCustom;
};

const normalizeOperationCustomRecordInputTypeThird = (
  postCustomRecord: RecordOperationValue["record"]["custom_record"]["input_type_third"],
  initialDataCustomRecord: RecordOperationValue["record"]["custom_record"]["input_type_third"]
): PostCustomOperationParams["custom_record"] => {
  const resCustom = [] as PostCustomOperationParams["custom_record"];

  const diffData = omitByNoChanges(postCustomRecord, initialDataCustomRecord);

  const diffDataKeys = Object.keys(diffData);

  Object.entries(postCustomRecord).forEach(([key, value]) => {
    const isDeleteItem = value.itemIdList.some(
      (item) => item.is_delete !== null
    );

    const isDiffCustomRecord = diffDataKeys.some((item) => item === key);

    if (value && (isDiffCustomRecord || isDeleteItem)) {
      // 新規追加またはchecked「OFF」変更（「is_delete=1」の場合）
      const newRecord = [] as PostCustomOperationParams["custom_record"];
      value.itemIdList.forEach((item) => {
        const beforeValue = value.beforeValues.find(
          (beforeValueItem) => beforeValueItem.choiced_staff_id === item.id
        );

        const record = {
          custom_record_input_id: beforeValue
            ? beforeValue.custom_record_input_id
            : null,
          custom_record_item_id: Number(key),
          choiced_staff_id: beforeValue
            ? beforeValue.choiced_staff_id
            : Number(item.id),
          choiced_staff_name_snapshot: item.label,
          checked: item.is_delete === 1 ? 0 : 1
        };
        newRecord.push(record);
      });

      // checked「OFF」変更処理
      const unCheckRecord = value.beforeValues
        .filter((beforeValueItem) => {
          return !value.itemIdList.find(
            (itemIdListItem) =>
              beforeValueItem.choiced_staff_id === itemIdListItem.id
          );
        })
        .map((unCheckBeforeValue) => {
          return {
            custom_record_input_id: unCheckBeforeValue.custom_record_input_id,
            custom_record_item_id: Number(key),
            choiced_staff_id: unCheckBeforeValue.choiced_staff_id,
            choiced_staff_name_snapshot:
              unCheckBeforeValue.choiced_staff_name_snapshot,
            checked: 0
          };
        });

      resCustom.push(...newRecord, ...unCheckRecord);
    }
  });

  return resCustom;
};

/**
 * postCustomSupportに渡すparamsの変換
 */
export const normalizeFormValuesToPostCustomSupportParams = (
  values: RecordSupportsValues,
  initialValues: RecordSupportsValues,
  targetUifId: number
): PostCustomSupportParams => {
  const params = {} as PostCustomSupportParams;
  // 変更対象データ抽出
  const postInitialData = initialValues.record.find(
    (item) => item.target_uifId === targetUifId
  );
  const postData = values.record.find(
    (item) => item.target_uifId === targetUifId
  );

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeSupportCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDatakeys = Object.keys(res);

    if (diffDatakeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeSupportCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = 3;
    params.support_record_id = postData.support_record_id;
  }

  return params;
};

/**
 * postCustomSupportに渡すparamsの変換
 */
export const normalizeFormValuesToPostCustomOperationParams = (
  value: RecordOperationValue,
  initialValue: RecordOperationValue
): PostCustomOperationParams => {
  const params = {} as PostCustomOperationParams;
  const postData = value.record;
  const postInitialData = initialValue.record;

  if (postData && postInitialData) {
    // 「記録者」項目は「is_delete」が変化した場合、データの差異が抽出できないのでここでデータ整形
    const normalizedCustomRecordThird = normalizeOperationCustomRecordInputTypeThird(
      postData.custom_record.input_type_third,
      postInitialData.custom_record.input_type_third
    );
    params.custom_record = normalizedCustomRecordThird;

    const res = omitByNoChanges(postData, postInitialData);

    // 差分抽出
    const diffDataKeys = Object.keys(res);

    if (diffDataKeys.some((item) => item === "custom_record")) {
      const normalizedCustomRecords = normalizeOperationCustomRecords(
        postData.custom_record,
        postInitialData.custom_record
      );
      params.custom_record.push(...normalizedCustomRecords);
    }
    // 必要なデータの復元
    params.setting_type = postData.setting_type;
    params.operation_record_id = postData.operation_record_id;
  }

  return params;
};
