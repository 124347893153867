import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type PostAssistanceReport = {
  id: number | null;
  guardian_name?: string | null;
  guardian_relation?: string | null;
  date_of_monitoring: string | null;
  creation_date?: string | null;
  author?: number | null;
  classify_disability_support?: number | null;
  disabled_welfare_service_recipient_number?: string | null;
  consultation_support_recipient_number?: string | null;
  regular_place_recipient_number?: string | null;
  attainment_goal?: string | null;
  whole_situation?: string | null;
  staff_comment?: string | null;
  details:
    | {
        id: number | null;
        support_goal?: string | null;
        criteria_for_cooperation?: string | null;
        service_provision_status?: string | null;
        personal_satisfaction?: string | null;
        achievement_of_expectations?: string | null;
        future_issues?: string | null;
        other_considerations?: string | null;
        is_delete: number;
      }[]
    | null;
};

/**
 * 対象ユーザーの地域移行支援報告書情報を保存する
 * @param uifId ユーザーID
 * @param params 更新データ
 */

export const postAssistanceReport = async (
  uifId: string,
  params: PostAssistanceReport
): Promise<AxiosResponse<void>> => {
  const url = `${VERSION_URL_201910}/support_plan/${uifId}/assistance_report`;
  return request.post(url, params);
};
