import * as action from "@stores/ui/download/action";
import { AppDownloadState, DownloadFileType } from "@stores/ui/download/type";
import { reducerWithInitialState } from "typescript-fsa-reducers";

const targetYearMonth = (
  state: AppDownloadState,
  payload: AppDownloadState["targetYearMonth"]
): AppDownloadState => {
  const months = payload.split("-");
  const { year, month } = {
    year: months[0],
    month: months[1]
  };
  return {
    ...state,
    targetYearMonth: payload,
    targetYear: year,
    targetMonth: month
  };
};

const isDisableDownloadButton = (
  state: AppDownloadState,
  payload: AppDownloadState["isDisableDownloadButton"]
): AppDownloadState => ({
  ...state,
  isDisableDownloadButton: payload
});

const isDisableExcludedUsersButton = (
  state: AppDownloadState,
  payload: AppDownloadState["isDisableExcludedUsersButton"]
): AppDownloadState => ({
  ...state,
  isDisableExcludedUsersButton: payload
});

const downloadReadyFileType = (
  state: AppDownloadState,
  payload: AppDownloadState["downloadReadyFileType"]
): AppDownloadState => ({
  ...state,
  downloadReadyFileType: payload
});

const isOpenUserModal = (
  state: AppDownloadState,
  payload: AppDownloadState["isOpenUserModal"]
): AppDownloadState => ({
  ...state,
  isOpenUserModal: payload
});

const excludedUserIds = (
  state: AppDownloadState,
  payload: AppDownloadState["excludedUserIds"]
): AppDownloadState => {
  return {
    ...state,
    excludedUserIds: payload
  };
};

const tmpExcludedUserIds = (
  state: AppDownloadState,
  payload: AppDownloadState["tmpExcludedUserIds"]
): AppDownloadState => {
  return {
    ...state,
    tmpExcludedUserIds: payload
  };
};

const clearExcludedUserIds = (state: AppDownloadState): AppDownloadState => {
  return {
    ...state,
    excludedUserIds: [],
    tmpExcludedUserIds: []
  };
};

const downloadReadyTypeConsultation = (
  state: AppDownloadState,
  payload: AppDownloadState["typeConsultation"]
): AppDownloadState => ({
  ...state,
  typeConsultation: payload
});

const isOpenUplimitCreateTypeSelectModal = (
  state: AppDownloadState,
  payload: AppDownloadState["isOpenUplimitCreateTypeSelectModal"]
): AppDownloadState => ({
  ...state,
  isOpenUplimitCreateTypeSelectModal: payload
});

const userIdToUplimitCreateType = (
  state: AppDownloadState,
  payload: AppDownloadState["userIdToUplimitCreateType"]
): AppDownloadState => ({
  ...state,
  userIdToUplimitCreateType: payload
});

const initialState: AppDownloadState = {
  targetYearMonth: "",
  targetYear: "",
  targetMonth: "",
  isDisableDownloadButton: true,
  isDisableExcludedUsersButton: true,
  downloadReadyFileType: DownloadFileType.NONE,
  isOpenUserModal: false,
  excludedUserIds: [] as number[],
  tmpExcludedUserIds: [] as number[],
  typeConsultation: undefined,
  isOpenUplimitCreateTypeSelectModal: false,
  userIdToUplimitCreateType: new Map()
};

export default reducerWithInitialState(initialState)
  .case(action.targetYearMonth, targetYearMonth)
  .case(action.isDisableDownloadButton, isDisableDownloadButton)
  .case(action.isDisableExcludedUsersButton, isDisableExcludedUsersButton)
  .case(action.downloadReadyFileType, downloadReadyFileType)
  .case(action.isOpenUserModal, isOpenUserModal)
  .case(action.excludedUserIds, excludedUserIds)
  .case(action.clearExcludedUserIds, clearExcludedUserIds)
  .case(action.tmpExcludedUserIds, tmpExcludedUserIds)
  .case(action.downloadReadyTypeConsultation, downloadReadyTypeConsultation)
  .case(
    action.isOpenUplimitCreateTypeSelectModal,
    isOpenUplimitCreateTypeSelectModal
  )
  .case(action.userIdToUplimitCreateType, userIdToUplimitCreateType);
