export type PatternListType = {
  id: number;
  targetKana: string;
  viewKana: string;
  alphabet: string;
  checked: boolean;
  disabled: boolean;
};

export const PATTERN_LIST: PatternListType[] = [
  {
    id: 1,
    viewKana: "あ",
    alphabet: "a",
    targetKana: "[ァ-オ]|[ヴ]",
    checked: false,
    disabled: false
  },
  {
    id: 2,
    viewKana: "か",
    alphabet: "k",
    targetKana: "[カ-ゴ]|[ヵ-ヶ]",
    checked: false,
    disabled: false
  },
  {
    id: 3,
    viewKana: "さ",
    alphabet: "s",
    targetKana: "[サ-ゾ]",
    checked: false,
    disabled: false
  },
  {
    id: 4,
    viewKana: "た",
    alphabet: "t",
    targetKana: "[タ-ド]",
    checked: false,
    disabled: false
  },
  {
    id: 5,
    viewKana: "な",
    alphabet: "n",
    targetKana: "[ナ-ノ]",
    checked: false,
    disabled: false
  },
  {
    id: 6,
    viewKana: "は",
    alphabet: "h",
    targetKana: "[ハ-ポ]",
    checked: false,
    disabled: false
  },
  {
    id: 7,
    viewKana: "ま",
    alphabet: "m",
    targetKana: "[マ-モ]",
    checked: false,
    disabled: false
  },
  {
    id: 8,
    viewKana: "や",
    alphabet: "y",
    targetKana: "[ャ-ヨ]",
    checked: false,
    disabled: false
  },
  {
    id: 9,
    viewKana: "ら",
    alphabet: "r",
    targetKana: "[ラ-ロ]",
    checked: false,
    disabled: false
  },
  {
    id: 10,
    viewKana: "わ",
    alphabet: "w",
    targetKana: "[ヮ-ン]|[ヷ-ヺ]",
    checked: false,
    disabled: false
  }
];

export const SECTION_LIST = [
  { label: "サービス利用支援", value: "1" },
  { label: "継続サービス利用支援", value: "2" }
];

export const SECTION_LABEL = {
  1: "サービス利用支援",
  2: "継続サービス利用支援"
};
