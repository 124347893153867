import * as React from "react";
import {
  createStyles,
  StyleRules,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

const styles = (): StyleRules =>
  createStyles({
    button: {
      padding: 2
    },
    icon: {
      marginRight: 8
    }
  });

interface OwnProps {
  text: string;
  onClick: () => void;
}
type Props = OwnProps & WithStyles<typeof styles>;

const DeleteButton = (props: Props): JSX.Element => (
  <Button
    color="secondary"
    className={props.classes.button}
    onClick={props.onClick}
  >
    <DeleteOutlinedIcon color="secondary" className={props.classes.icon} />
    {props.text}
  </Button>
);

export default withStyles(styles)(DeleteButton);
