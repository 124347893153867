import { Dispatch } from "redux";
import { AxiosResponse } from "axios";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import * as supportReportActions from "@stores/domain/supportReport/actions";
import * as supportsActions from "@stores/domain/supports/actions";
import * as supportRecordUserActions from "@stores/domain/supportRecordUser/actions";
import * as supportRecordUserGroupHomeActions from "@stores/domain/mgr/GroupHome/supportRecordUser/actions";
import * as supportRecordUserSHISETSUNYUSHOActions from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/actions";
import * as supportRecordUserTANKINYUSHOActions from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/actions";
import * as supportPlanActions from "@stores/domain/supportPlan/actions";
import * as workActions from "@stores/domain/work/actions";
import * as staffActions from "@stores/domain/staff/actions";
import * as errorsActions from "@stores/domain/errors/actions";
import * as customRecordsActions from "@stores/domain/customRecords/actions";
import supportsDispatcher from "@stores/domain/supports/dispatcher";
import supportRecordUserDispatcher from "@stores/domain/supportRecordUser/dispatcher";
import supportRecordUserGroupHomeDispatcher from "@stores/domain/mgr/GroupHome/supportRecordUser/dispatcher";
import supportRecordUserSHISETSUNYUSHODispatcher from "@stores/domain/mgr/SHISETSUNYUSHO/supportRecordUser/dispatcher";
import supportRecordUserTANKINYUSHODispatcher from "@stores/domain/mgr/TANKINYUSHO/supportRecordUser/dispatcher";
import {
  normalizeFormValuesToPostSupportsParams,
  normalizeFormValuesToPostSupportRecordParams,
  normalizeFormValuesToPostCustomSupportParamsGROUPHOME,
  normalizeFormValuesToPostCustomSupportParamsSHISETSUNYUSHO,
  normalizeFormValuesToPostCustomSupportParamsTANKINYUSHO
} from "./normalizer";
import supportsApi from "@api/requests/supports";
import supportReportApi from "@api/requests/supportReport";
import supportPlanApi from "@api/requests/supportPlan";
import workApi from "@api/requests/work";
import staffApi from "@api/requests/staff";
import facilityApi from "@api/requests/facility";
import { GetFacilityResponse } from "@api/requests/facility/getFacility";
import errorsApi from "@api/requests/errors";
import getCustomRecordsAPI from "@api/requests/customRecords";
import { RecordUserDetailValues as SEIKATSUKAIGOFormValues } from "@initialize/mgr/SEIKATSUKAIGO/record/userDetail/initialValues";
import { RecordUserDetailValues as IABFormValues } from "@initialize/mgr/IAB/record/userDetail/initialValues";
import { RecordUserDetailValues as SHUROTEICHAKUFormValues } from "@initialize/mgr/SHUROTEICHAKU/record/userDetail/initialValues";
import { RecordUserDetailValues as GroupHomeFormValues } from "@initialize/mgr/GroupHome/record/userDetail/initialValues";
import { RecordUserDetailValues as SHISETSUNYUSHOFormValues } from "@initialize/mgr/SHISETSUNYUSHO/record/userDetail/initialValues";
import { RecordUserDetailValues as TANKINYUSHOFormValues } from "@initialize/mgr/TANKINYUSHO/record/userDetail/initialValues";
import { WorkState } from "@stores/domain/work/types";
import { StaffState } from "@stores/domain/staff/types";
import aggregateErrorCount from "@utils/domain/errors/aggregateErrorCount";
import { FacilityType } from "@constants/variables";
import {
  getFacilityActions,
  getUserInFacilityActions,
  FacilityActions,
  UserInFacilityActions
} from "@stores/domain/mgr/common/actions";
import {
  getNormalizeGetFacilityResult,
  getNormalizedGetFacilityUserTargetIdResponse
} from "@stores/domain/mgr/common/normalizer";
import * as format from "date-fns/format";

import { FacilityState as FacilityStateIAB } from "@stores/domain/mgr/IAB/facility/types";
import { FacilityState as FacilityStateSEIKATSUKAIGO } from "@stores/domain/mgr/SEIKATSUKAIGO/facility/types";
import { FacilityState as FacilityStateSHUROTEICHAKU } from "@stores/domain/mgr/SHUROTEICHAKU/facility/types";
import { FacilityState as FacilityStateJIRITSUKUNRENSEIKATSU } from "@stores/domain/mgr/JIRITSUKUNRENSEIKATSU/facility/types";
import { FacilityState as FacilityStateGroupHome } from "@stores/domain/mgr/GroupHome/facility/types";
import { FacilityState as FacilityStateSHISETSUNYUSHO } from "@stores/domain/mgr/SHISETSUNYUSHO/facility/types";
import { FacilityState as FacilityStateTANKINYUSHO } from "@stores/domain/mgr/TANKINYUSHO/facility/types";

type RecordUserDetailValues = SEIKATSUKAIGOFormValues | IABFormValues;

type FacilityState = FacilityStateIAB &
  FacilityStateSEIKATSUKAIGO &
  FacilityStateSHUROTEICHAKU &
  FacilityStateJIRITSUKUNRENSEIKATSU &
  FacilityStateGroupHome &
  FacilityStateTANKINYUSHO &
  FacilityStateSHISETSUNYUSHO;

type ActionResponse = {
  typeService: FacilityType;
  facilityActions: FacilityActions;
  userInFacilityActions: UserInFacilityActions;
  facility: AxiosResponse<GetFacilityResponse>;
};

const fetchTypeService = async (
  dispatch: Dispatch,
  supportPlan?: boolean
): Promise<ActionResponse | void> => {
  dispatch(loadingActions.loadStarted());
  const facility = await facilityApi.getFacility().catch((e) => {
    if (supportPlan) {
      dispatch(errorsActions.fetchPlanFailed({ error: e.response }));
      dispatch(errorsActions.fetchGoalFailed({ error: e.response }));
    }
    dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    dispatch(responseErrorActions.setResponseError(e.response));
    dispatch(loadingActions.loadDone());
  });
  if (facility === undefined) {
    return undefined;
  }
  const typeService = facility.data.data.facility.type_service as FacilityType;
  const facilityActions = getFacilityActions(typeService);
  const userInFacilityActions = getUserInFacilityActions(typeService);
  return { typeService, facilityActions, userInFacilityActions, facility };
};

/**
 * 利用者ごと初期取得
 */
export const fetchInitialUserDetailRecord = (dispatch: Dispatch) => async (
  uifId: string,
  year: string,
  month: string,
  target: "interview" | "work" | undefined
): Promise<void> => {
  const result = await fetchTypeService(dispatch);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;
  dispatch(supportsActions.fetchSupportsRecordStarted());
  dispatch(workActions.fetchStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());

  Promise.all([
    supportsApi.getSupportsRecord(uifId, year, month, target),
    workApi.getWork(),
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId)
  ])
    .then((results) => {
      dispatch(
        supportsActions.fetchSupportsRecordSuccess(results[0].data.data)
      );
      dispatch(workActions.fetchSuccess(results[1].data));
      dispatch(staffActions.fetchSuccess(results[2].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[3].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
    })
    .catch((e) => {
      dispatch(
        supportsActions.fetchSupportsRecordFailed({ error: e.response })
      );
      dispatch(workActions.fetchFailed({ error: e.response }));
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと初期取得(就労定着)
 */
export const fetchInitialUserDetailSupportRecord = (
  dispatch: Dispatch
) => async (uifId: string, year: string, month: string): Promise<void> => {
  const result = await fetchTypeService(dispatch);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;

  dispatch(supportRecordUserActions.fetchSupportRecordUserStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());

  Promise.all([
    supportsApi.getSupportRecordUser(uifId, year, month),
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId)
  ])
    .then((results) => {
      dispatch(
        supportRecordUserActions.fetchSupportRecordUserSuccess(
          results[0].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[1].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[2].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
    })
    .catch((e) => {
      dispatch(
        supportRecordUserActions.fetchSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと初期取得(GH)
 */
export const fetchInitialUserDetailSupportRecordGroupHome = (
  dispatch: Dispatch
) => async (
  uifId: string,
  year: string,
  month: string,
  target?: number
): Promise<void> => {
  const result = await fetchTypeService(dispatch);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;

  dispatch(supportRecordUserGroupHomeActions.fetchSupportRecordUserStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());

  Promise.all([
    supportsApi.getSupportRecordUserGroupHome(uifId, year, month),
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId),
    getCustomRecordsAPI.getCustomRecords(target)
  ])
    .then((results) => {
      dispatch(
        supportRecordUserGroupHomeActions.fetchSupportRecordUserSuccess(
          results[0].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[1].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[2].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[3].data.data)
      );
    })
    .catch((e) => {
      dispatch(
        supportRecordUserGroupHomeActions.fetchSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと初期取得(施設入所)
 */
export const fetchInitialUserDetailSupportRecordSHISETSUNYUSHO = (
  dispatch: Dispatch
) => async (
  uifId: string,
  year: string,
  month: string,
  target?: number
): Promise<void> => {
  const result = await fetchTypeService(dispatch);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;

  dispatch(
    supportRecordUserSHISETSUNYUSHOActions.fetchSupportRecordUserStarted()
  );
  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());

  Promise.all([
    supportsApi.getSupportRecordUserSHISETSUNYUSHO(uifId, year, month),
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId),
    getCustomRecordsAPI.getCustomRecords(target)
  ])
    .then((results) => {
      dispatch(
        supportRecordUserSHISETSUNYUSHOActions.fetchSupportRecordUserSuccess(
          results[0].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[1].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[2].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[3].data.data)
      );
    })
    .catch((e) => {
      dispatch(
        supportRecordUserSHISETSUNYUSHOActions.fetchSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと初期取得(短期入所)
 */
export const fetchInitialUserDetailSupportRecordTANKINYUSHO = (
  dispatch: Dispatch
) => async (
  uifId: string,
  year: string,
  month: string,
  target?: number
): Promise<void> => {
  const result = await fetchTypeService(dispatch);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;

  dispatch(supportRecordUserTANKINYUSHOActions.fetchSupportRecordUserStarted());
  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());
  dispatch(customRecordsActions.fetchCustomRecordsStarted());

  Promise.all([
    supportsApi.getSupportRecordUserTANKINYUSHO(uifId, year, month),
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId),
    getCustomRecordsAPI.getCustomRecords(target)
  ])
    .then((results) => {
      dispatch(
        supportRecordUserTANKINYUSHOActions.fetchSupportRecordUserSuccess(
          results[0].data.data
        )
      );
      dispatch(staffActions.fetchSuccess(results[1].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[2].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
      dispatch(
        customRecordsActions.fetchCustomRecordsSuccess(results[3].data.data)
      );
    })
    .catch((e) => {
      dispatch(
        supportRecordUserTANKINYUSHOActions.fetchSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(
        customRecordsActions.fetchCustomRecordsFailed({ error: e.response })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと更新
 */
export const postUserDetailRecord = (dispatch: Dispatch) => async (
  uifId: string,
  params: RecordUserDetailValues,
  initialValues: RecordUserDetailValues,
  state: {
    work: WorkState;
    staff: StaffState;
  },
  fetchOptions: {
    year: string;
    month: string;
    target?: "work" | "interview";
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(supportsActions.postSupportsRecordStarted());
  const normalizeParams = normalizeFormValuesToPostSupportsParams(
    params,
    initialValues,
    state.work,
    state.staff
  );
  await supportsApi
    .postSupports(uifId, normalizeParams)
    .then(async () => {
      dispatch(supportsActions.postSupportsRecordSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportsDispatcher(dispatch).fetchSupportsRecord(
        uifId,
        fetchOptions.year,
        fetchOptions.month,
        fetchOptions.target
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(supportsActions.postSupportsRecordFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと更新（就労定着）
 */
export const postUserDetailSupportRecord = (dispatch: Dispatch) => async (
  uifId: string,
  params: SHUROTEICHAKUFormValues,
  initialValues: SHUROTEICHAKUFormValues,
  staff: StaffState,
  fetchOptions: {
    year: string;
    month: string;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(supportRecordUserActions.postSupportRecordUserStarted());
  const normalizeParams = normalizeFormValuesToPostSupportRecordParams(
    params,
    initialValues,
    staff
  );
  await supportsApi
    .postSupportRecordUser(uifId, normalizeParams)
    .then(async () => {
      dispatch(supportRecordUserActions.postSupportRecordUserSuccess());
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportRecordUserDispatcher(dispatch).fetchSupportRecordUser(
        uifId,
        fetchOptions.year,
        fetchOptions.month
      );
      dispatch(actions.unsetEdit());
    })
    .catch((e) => {
      dispatch(
        supportRecordUserActions.postSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと更新（GH）
 */
export const postCustomSupport = (dispatch: Dispatch) => async (
  uifId: string,
  params: GroupHomeFormValues,
  initialValues: GroupHomeFormValues,
  targetDate: string,
  fetchOptions: {
    year: string;
    month: string;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(supportRecordUserGroupHomeActions.postSupportRecordUserStarted());
  const normalizeParams = normalizeFormValuesToPostCustomSupportParamsGROUPHOME(
    params,
    initialValues,
    targetDate
  );
  await supportsApi
    .postCustomSupport(uifId, format(targetDate, "YYYYMMDD"), normalizeParams)
    .then(async () => {
      dispatch(
        supportRecordUserGroupHomeActions.postSupportRecordUserSuccess()
      );
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportRecordUserGroupHomeDispatcher(
        dispatch
      ).fetchSupportRecordUser(uifId, fetchOptions.year, fetchOptions.month);
      dispatch(actions.unsetEditWithTargetDate());
    })
    .catch((e) => {
      dispatch(
        supportRecordUserGroupHomeActions.postSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと更新（SHISETSUNYUSHO）
 */
export const postCustomSupportSHISETSUNYUSHO = (dispatch: Dispatch) => async (
  uifId: string,
  params: SHISETSUNYUSHOFormValues,
  initialValues: SHISETSUNYUSHOFormValues,
  targetDate: string,
  fetchOptions: {
    year: string;
    month: string;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(
    supportRecordUserSHISETSUNYUSHOActions.postSupportRecordUserStarted()
  );
  const normalizeParams = normalizeFormValuesToPostCustomSupportParamsSHISETSUNYUSHO(
    params,
    initialValues,
    targetDate
  );
  await supportsApi
    .postCustomSupport(uifId, format(targetDate, "YYYYMMDD"), normalizeParams)
    .then(async () => {
      dispatch(
        supportRecordUserSHISETSUNYUSHOActions.postSupportRecordUserSuccess()
      );
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportRecordUserSHISETSUNYUSHODispatcher(
        dispatch
      ).fetchSupportRecordUser(uifId, fetchOptions.year, fetchOptions.month);
      dispatch(actions.unsetEditWithTargetDate());
    })
    .catch((e) => {
      dispatch(
        supportRecordUserSHISETSUNYUSHOActions.postSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと更新（短期入所）
 */
export const postCustomSupportTANKINYUSHO = (dispatch: Dispatch) => async (
  uifId: string,
  params: TANKINYUSHOFormValues,
  initialValues: TANKINYUSHOFormValues,
  targetDate: string,
  fetchOptions: {
    year: string;
    month: string;
  }
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(supportRecordUserTANKINYUSHOActions.postSupportRecordUserStarted());
  const normalizeParams = normalizeFormValuesToPostCustomSupportParamsTANKINYUSHO(
    params,
    initialValues,
    targetDate
  );
  await supportsApi
    .postCustomSupport(uifId, format(targetDate, "YYYYMMDD"), normalizeParams)
    .then(async () => {
      dispatch(
        supportRecordUserTANKINYUSHOActions.postSupportRecordUserSuccess()
      );
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 再取得
      await supportRecordUserTANKINYUSHODispatcher(
        dispatch
      ).fetchSupportRecordUser(uifId, fetchOptions.year, fetchOptions.month);
      dispatch(actions.unsetEditWithTargetDate());
    })
    .catch((e) => {
      dispatch(
        supportRecordUserTANKINYUSHOActions.postSupportRecordUserFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと（個別支援計画）
 * @param dispatch
 */
export const fetchInitialSupportPlan = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(errorsActions.fetchPlanStarted());
  dispatch(errorsActions.fetchGoalStarted());

  const result = await fetchTypeService(dispatch, true);
  if (result === undefined) {
    return;
  }
  const {
    typeService,
    facilityActions,
    userInFacilityActions,
    facility
  } = result;

  const normalizedGetFacilityUserTargetIdResponse = getNormalizedGetFacilityUserTargetIdResponse(
    typeService
  );

  const getFacilityData = getNormalizeGetFacilityResult(typeService)(
    facility.data
  );

  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());
  dispatch(supportPlanActions.fetchSupportPlanStarted());

  Promise.all([
    supportPlanApi.getSupportPlan(uifId),
    facilityApi.getFacilityUserTargetId(uifId),
    errorsApi.getPlanErrors(uifId),
    errorsApi.getGoalErrors(uifId)
  ])
    .then((results) => {
      dispatch(
        supportPlanActions.fetchSupportPlanSuccess(results[0].data.data)
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = normalizedGetFacilityUserTargetIdResponse(
        results[1].data.data
      );
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
      const planErrorCount = aggregateErrorCount(results[2].data.data);
      const hasPlanError = planErrorCount > 0;
      dispatch(
        errorsActions.fetchPlanSuccess(results[2].data, {
          hasError: hasPlanError,
          errorCount: planErrorCount
        })
      );
      const goalErrorCount = aggregateErrorCount(results[3].data.data);
      const hasGoalError = goalErrorCount > 0;
      dispatch(
        errorsActions.fetchGoalSuccess(results[3].data, {
          hasError: hasGoalError,
          errorCount: goalErrorCount
        })
      );
    })
    .catch((e) => {
      dispatch(
        supportPlanActions.fetchSupportPlanFailed({ error: e.response })
      );
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(errorsActions.fetchPlanFailed({ error: e.response }));
      dispatch(errorsActions.fetchGoalFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

/**
 * 利用者ごと初期取得(支援レポート)
 */
export const fetchInitialSupportReport = (dispatch: Dispatch) => async (
  uifId: string,
  year: string,
  month: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());

  const facility = await facilityApi.getFacility().catch((e) => {
    dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    dispatch(responseErrorActions.setResponseError(e.response));
    dispatch(loadingActions.loadDone());
  });
  if (facility === undefined) {
    return;
  }
  const typeService = facility.data.data.facility.type_service as FacilityType;
  const facilityActions = getFacilityActions(typeService);
  const userInFacilityActions = getUserInFacilityActions(typeService);

  dispatch(staffActions.fetchStarted());
  dispatch(facilityActions.fetchStarted());
  dispatch(userInFacilityActions.fetchOneStarted());
  dispatch(supportReportActions.fetchSupportReportStarted());

  Promise.all([
    staffApi.getStaff(),
    facilityApi.getFacilityUserTargetId(uifId),
    supportReportApi.getSupportReportUser(uifId, year, month)
  ])
    .then((results) => {
      dispatch(staffActions.fetchSuccess(results[0].data));
      const getFacilityData = getNormalizeGetFacilityResult(typeService)(
        facility.data
      );
      dispatch(facilityActions.fetchSuccess(getFacilityData as FacilityState));
      const getFacilityUserData = getNormalizedGetFacilityUserTargetIdResponse(
        typeService
      )(results[1].data.data);
      dispatch(userInFacilityActions.fetchOneSuccess(getFacilityUserData));
      dispatch(
        supportReportActions.fetchSupportReportSuccess(results[2].data.data)
      );
    })
    .catch((e) => {
      dispatch(staffActions.fetchFailed({ error: e.response }));
      dispatch(facilityActions.fetchFailed({ error: e.response }));
      dispatch(
        userInFacilityActions.fetchOneFailed({
          error: e.response
        })
      );
      dispatch(
        supportReportActions.fetchSupportReportFailed({
          error: e.response
        })
      );
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type Dispatcher = {
  fetchInitialUserDetailRecord: (
    uifId: string,
    year: string,
    month: string,
    target: "interview" | "work" | undefined
  ) => Promise<void>;
  fetchInitialUserDetailSupportRecord: (
    uifId: string,
    year: string,
    month: string
  ) => Promise<void>;
  fetchInitialUserDetailSupportRecordGroupHome: (
    uifId: string,
    year: string,
    month: string,
    target?: number
  ) => Promise<void>;
  fetchInitialUserDetailSupportRecordSHISETSUNYUSHO: (
    uifId: string,
    year: string,
    month: string,
    targat?: number
  ) => Promise<void>;
  fetchInitialUserDetailSupportRecordTANKINYUSHO: (
    uifId: string,
    year: string,
    month: string,
    targat?: number
  ) => Promise<void>;
  fetchInitialSupportPlan: (uifId: string) => Promise<void>;
  postUserDetailRecord: (
    uifId: string,
    params: RecordUserDetailValues,
    initialValues: RecordUserDetailValues,
    state: {
      work: WorkState;
      staff: StaffState;
    },
    fetchOptions: {
      year: string;
      month: string;
      target?: "work" | "interview";
    }
  ) => Promise<void>;
  fetchInitialSupportReport: (
    uifId: string,
    year: string,
    month: string
  ) => Promise<void>;
  postUserDetailSupportRecord: (
    uifId: string,
    params: SHUROTEICHAKUFormValues,
    initialValues: SHUROTEICHAKUFormValues,
    staff: StaffState,
    fetchOptions: {
      year: string;
      month: string;
    }
  ) => Promise<void>;
  postCustomSupport: (
    uifId: string,
    params: GroupHomeFormValues,
    initialValues: GroupHomeFormValues,
    targetDate: string,
    fetchOptions: {
      year: string;
      month: string;
    }
  ) => Promise<void>;
  postCustomSupportSHISETSUNYUSHO: (
    uifId: string,
    params: SHISETSUNYUSHOFormValues,
    initialValues: SHISETSUNYUSHOFormValues,
    targetDate: string,
    fetchOptions: {
      year: string;
      month: string;
    }
  ) => Promise<void>;
  postCustomSupportTANKINYUSHO: (
    uifId: string,
    params: TANKINYUSHOFormValues,
    initialValues: TANKINYUSHOFormValues,
    targetDate: string,
    fetchOptions: {
      year: string;
      month: string;
    }
  ) => Promise<void>;
};

export default (dispatch: Dispatch): Dispatcher => ({
  fetchInitialUserDetailRecord: fetchInitialUserDetailRecord(dispatch),
  fetchInitialUserDetailSupportRecord: fetchInitialUserDetailSupportRecord(
    dispatch
  ),
  fetchInitialUserDetailSupportRecordGroupHome: fetchInitialUserDetailSupportRecordGroupHome(
    dispatch
  ),
  fetchInitialUserDetailSupportRecordSHISETSUNYUSHO: fetchInitialUserDetailSupportRecordSHISETSUNYUSHO(
    dispatch
  ),
  fetchInitialUserDetailSupportRecordTANKINYUSHO: fetchInitialUserDetailSupportRecordTANKINYUSHO(
    dispatch
  ),
  fetchInitialSupportPlan: fetchInitialSupportPlan(dispatch),
  fetchInitialSupportReport: fetchInitialSupportReport(dispatch),
  postUserDetailRecord: postUserDetailRecord(dispatch),
  postUserDetailSupportRecord: postUserDetailSupportRecord(dispatch),
  postCustomSupport: postCustomSupport(dispatch),
  postCustomSupportSHISETSUNYUSHO: postCustomSupportSHISETSUNYUSHO(dispatch),
  postCustomSupportTANKINYUSHO: postCustomSupportTANKINYUSHO(dispatch)
});
