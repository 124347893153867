import {
  CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO,
  FacilityType,
  SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE
} from "@constants/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO } from "@constants/mgr/JUDOHOMONKAIGO/variables";
import {
  CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE,
  SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO
} from "@constants/mgr/DOKOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO } from "@constants/mgr/KYOTAKUKAIGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO } from "@constants/mgr/KODOENGO/variables";
import { SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN } from "@constants/mgr/IDOSHIEN/variables";

export const isExcretionManagement = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  return !!(
    facilityType === FacilityType.JUDOHOMONKAIGO &&
    defaultItem ===
      SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.excretion_management
  );
};

export const isHydration = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  return !!(
    facilityType === FacilityType.JUDOHOMONKAIGO &&
    defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.hydration
  );
};

export const isVital = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  let list;
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      list = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO;
      break;
    case FacilityType.DOKOENGO:
      list = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO;
      break;
    case FacilityType.KODOENGO:
      list = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO;
      break;
    case FacilityType.IDOSHIEN:
      list = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN;
      break;
    default:
      return false;
  }
  return list.vital === defaultItem;
};

// 金銭管理、費用負担
export const isCost = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  if (facilityType === FacilityType.DOKOENGO) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.cost;
  }
  if (facilityType === FacilityType.KODOENGO) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.cost;
  }
  if (facilityType === FacilityType.IDOSHIEN) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.cost;
  }
  return false;
};

// 所持金・預かり金
export const isMoneyAndDeposit = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  if (facilityType === FacilityType.DOKOENGO) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.deposit;
  }
  if (facilityType === FacilityType.KODOENGO) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.deposit;
  }
  if (facilityType === FacilityType.IDOSHIEN) {
    return defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit;
  }
  return false;
};

// 通院等介助・通院等乗降介助
export const isHospital = (
  categoryType: number | null,
  facilityType: FacilityType
): boolean => {
  return (
    facilityType === FacilityType.KYOTAKUKAIGO &&
    categoryType ===
      CUSTOM_RECORD_WITH_CATEGORY_CATEGORY_TYPE_KYOTAKUKAIGO.hospital
  );
};

// naturalNumberWithCommaのバリデートが必要なカスタム項目
export const isValidateNaturalNumberWithCommaRecord = (
  defaultItem: number | null,
  facilityType: FacilityType,
  defaultChoice?: number
): boolean => {
  if (facilityType === FacilityType.DOKOENGO) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.cost) {
      return true;
    }
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.deposit) {
      if (
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit ||
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
      ) {
        return true;
      }
    }
  }
  if (facilityType === FacilityType.KODOENGO) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.cost) {
      return true;
    }
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.deposit) {
      if (
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit ||
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
      ) {
        return true;
      }
    }
  }
  if (facilityType === FacilityType.IDOSHIEN) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.cost) {
      return true;
    }
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit) {
      if (
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.money_and_deposit ||
        defaultChoice ===
          SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.used_money
      ) {
        return true;
      }
    }
  }
  return false;
};

// checkCharacterLengthが必要なカスタム項目
export const isStaffComment = (
  defaultItem: number | null,
  facilityType: FacilityType
): boolean => {
  let default_staff;
  switch (facilityType) {
    case FacilityType.KYOTAKUKAIGO:
      default_staff =
        SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KYOTAKUKAIGO.staff_comment;
      break;
    case FacilityType.JUDOHOMONKAIGO:
      default_staff =
        SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_JUDOHOMONKAIGO.staff_comment;
      break;
    case FacilityType.DOKOENGO:
      default_staff = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.staff_comment;
      break;
    case FacilityType.KODOENGO:
      default_staff = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.staff_comment;
      break;
    case FacilityType.IDOSHIEN:
      default_staff = SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.staff_comment;
      break;
    default:
      break;
  }
  return default_staff === defaultItem;
};

// numberWithCommaのバリデートが必要なカスタム項目
export const isValidateNumberWithCommaRecord = (
  defaultItem: number | null,
  facilityType: FacilityType,
  defaultChoice?: number
): boolean => {
  if (facilityType === FacilityType.DOKOENGO) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.deposit) {
      if (
        defaultChoice ===
        SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.balance
      ) {
        return true;
      }
    }
  }
  if (facilityType === FacilityType.KODOENGO) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_KODOENGO.deposit) {
      if (
        defaultChoice ===
        SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.balance
      ) {
        return true;
      }
    }
  }
  if (facilityType === FacilityType.IDOSHIEN) {
    if (defaultItem === SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_IDOSHIEN.deposit) {
      if (
        defaultChoice ===
        SERVICE_CUSTOM_RECORD_MONEY_AND_DEPOSIT_DEFAULT_CHOICE.balance
      ) {
        return true;
      }
    }
  }
  return false;
};

// checkCharacterLengthが必要なカスタム項目
export const isCheckCharacterLengthRecord = (
  defaultItem: number | null,
  defaultChoice: number | null,
  facilityType: FacilityType
): boolean => {
  return (
    facilityType === FacilityType.DOKOENGO &&
    defaultItem ===
      SERVICE_CUSTOM_RECORD_DEFAULT_ITEM_DOKOENGO.visual_information_aid &&
    defaultChoice === CHECKBOX_AND_TEXT_INPUT_TYPE_DEFAULT_CHOICE.other
  );
};
