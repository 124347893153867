import React, { useEffect, useState } from "react";
import {
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import MuiTextField from "@components/molecules/MuiTextField";
import FormButtons from "@components/organisms/account/FormButtons";
import validator from "@validator";
import {
  AccountState,
  NewMobileAccountParams
} from "@stores/domain/account/type";
import * as H from "history";
import LinkButton from "@components/atoms/LinkButton";
import classNames from "classnames";
import { INTRODUCTORY_CLAUSE, PRIVACY_POLICY } from "@constants/url";

const styles = (): StyleRules =>
  createStyles({
    linkWrap: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "20px"
    },
    link: {
      letterSpacing: "1.25px"
    },
    rightLink: {
      marginLeft: "32px"
    }
  });

type OwnProps = {
  adminId: string;
  errorType: string;
  errors: AccountState["errors"];
  needsStopHistory: boolean;
  mobileAccountLabel: string;
  mobileAccountPlaceholder: string;
  passwordLabel: string;
  passwordConfirmLabel: string;
  confirmButtonLabel: string;
  onClose: () => void;
  activateMobileAccount: (
    params: NewMobileAccountParams,
    history: H.History
  ) => void;
  history: H.History;
  stopHistory: (flag: boolean) => void;
};

type Props = OwnProps & WithStyles<typeof styles>;

export const CreateAccountFormCore = (props: Props): JSX.Element => {
  const [mobileAccountName, setMobileAccountName] = useState("");
  const [mobileAccountNameError, setMobileAccountNameError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [passwordConfirmError, setPasswordConfirmError] = useState("");
  const [
    serverMobileAccountNameError,
    setServerMobileAccountNameError
  ] = useState(false);

  // モバイルアカウント作成UIのみhelperTextにサーバ側エラーメッセージを表示する
  useEffect(() => {
    if (props.errors && "mobile" in props.errors) {
      setServerMobileAccountNameError(true);
    }
  }, [props.errors]);

  const confirmDiscardFormChanges = (): void => {
    if (!props.needsStopHistory) {
      props.stopHistory(true);
    }
  };

  const handleChangeMobileAccountName = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setMobileAccountName(value);
    confirmDiscardFormChanges();
  };

  const handleBlurMobileAccountName = (): void => {
    const error = validator(mobileAccountName, {
      type: "accountId",
      accountType: "mobile"
    });
    setMobileAccountNameError(error || "");
    // サーバ側エラーメッセージを非表示にする
    setServerMobileAccountNameError(false);
    confirmDiscardFormChanges();
  };

  const handleChangePassword = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setPassword(value);
    confirmDiscardFormChanges();
  };

  const handleBlurPassword = (): void => {
    const error =
      validator(password, "password") ||
      validator(password, {
        type: "passwordDuplication",
        adminId: props.adminId,
        mobileId: mobileAccountName
      });
    setPasswordError(error || "");
    confirmDiscardFormChanges();
  };

  const handleChangePasswordConfirm = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setPasswordConfirm(value);
  };

  const handleBlurPasswordConfirm = (): void => {
    const error = validator(passwordConfirm, {
      type: "passwordMatch",
      value: password
    });
    setPasswordConfirmError(error || "");
  };

  const handleSubmit = async (): Promise<void> => {
    await props.activateMobileAccount(
      {
        email: mobileAccountName,
        password
      },
      props.history
    );
  };

  return (
    <form>
      <MuiTextField
        label={props.mobileAccountLabel}
        placeholder={props.mobileAccountPlaceholder}
        type="email"
        required
        size="long"
        value={mobileAccountName}
        onChange={handleChangeMobileAccountName}
        onBlur={handleBlurMobileAccountName}
        error={
          serverMobileAccountNameError || mobileAccountNameError.length !== 0
        }
        helperText={
          (serverMobileAccountNameError && props.errorType) ||
          mobileAccountNameError ||
          "8文字以上　半角英字・数字・記号いずれも使用可"
        }
      />
      <MuiTextField
        label={props.passwordLabel}
        type="password"
        autoComplete="new-password"
        required
        value={password}
        onChange={handleChangePassword}
        onBlur={handleBlurPassword}
        error={passwordError.length !== 0}
        size="long"
        helperText={
          passwordError || "半角英字・数字・記号を組み合わせた8文字以上"
        }
        maxLength={100}
      />
      <MuiTextField
        label={props.passwordConfirmLabel}
        type="password"
        autoComplete="new-password"
        required
        size="long"
        value={passwordConfirm}
        onChange={handleChangePasswordConfirm}
        onBlur={handleBlurPasswordConfirm}
        error={passwordConfirmError.length !== 0}
        helperText={passwordConfirmError || "パスワードをもう一度入力"}
        maxLength={100}
      />
      <div className={props.classes.linkWrap}>
        <LinkButton
          to={PRIVACY_POLICY}
          target="_blank"
          className={props.classes.link}
        >
          プライバシーポリシー
        </LinkButton>
        <LinkButton
          to={INTRODUCTORY_CLAUSE}
          target="_blank"
          className={classNames(props.classes.rightLink, props.classes.link)}
        >
          導入約款
        </LinkButton>
      </div>
      <FormButtons
        confirmButtonLabel={props.confirmButtonLabel}
        flexCenter
        disabled={
          !mobileAccountName ||
          !!mobileAccountNameError ||
          !password ||
          !passwordConfirm ||
          !!passwordError ||
          !!passwordConfirmError
        }
        handleSubmit={handleSubmit}
        handleCancel={props.onClose}
      />
    </form>
  );
};

export const CreateAccountForm = withStyles(styles)(CreateAccountFormCore);
