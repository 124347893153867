import * as React from "react";
import { StyleRules } from "@material-ui/core/styles";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
// ui
import { KnowbeTable } from "@components/presentational/molecules/KnowbeTable";
import { KnowbeTableHead } from "@components/presentational/molecules/KnowbeTableHead";
import { KnowbeTableBody } from "@components/presentational/molecules/KnowbeTableBody";
import FormikTime from "@components/molecules/FormikTime";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { KnowbeTableCellParam } from "@components/presentational/molecules/KnowbeTableRow";
import { FormGroup } from "@material-ui/core";
// formik
import { FormikProps } from "formik";
import {
  ServiceDeliveryDetailValues,
  DestinationValues
} from "@initialize/record/serviceDelivery/initialValues";
// variables
import FormikTextField from "@components/molecules/FormikTextField";

const styles = (): StyleRules =>
  createStyles({
    table: {
      width: "100%",
      marginBottom: 18
    },
    tableCell: {
      padding: "5px 5px",
      // material ui のlast childのpadding0を上書き
      "&:last-child": {
        padding: "5px 5px"
      }
    },
    timeTableCell: {
      width: 108,
      paddingLeft: 16
    },
    placeItemCell: {
      width: 224,
      marginLeft: 16
    },
    deleteCell: {
      width: 64,
      paddingLeft: 22,
      "&:last-child": {
        paddingLeft: "22px"
      }
    },
    iconCell: {
      verticalAlign: "top",
      display: "inline-flex",
      width: 26,
      marginLeft: 22
    }
  });

type OwnProps = {
  formikProps: FormikProps<ServiceDeliveryDetailValues>;
  unitKey: string;
  onDeleteRecord: (index: number) => void;
  isAddButton: boolean;
  practitionerNum: number;
  destination: ServiceDeliveryDetailValues["serviceDeliveryRecordPractitioners1"]["serviceDeliveryRecordPractitionerDestinations"];
};

type Props = OwnProps & WithStyles<typeof styles>;

export const DestinationTable = withStyles(styles)(
  (props: Props): JSX.Element => {
    const { classes } = props;

    const headData = [
      {
        label: "時間",
        className: `${classes.timeTableCell}`
      },
      {
        label: "場所",
        className: `${classes.placeItemCell}`
      },
      {
        label: "支援内容",
        className: `${classes.supportItemCell}`
      },
      {
        label: "削除",
        className: `${classes.deleteCell}`
      }
    ];

    const bodyData = (
      i: number,
      onDeleteRecord: () => void
    ): KnowbeTableCellParam[] => [
      {
        label: (
          <FormikTime
            name={`${props.unitKey}[${i}].when_time`}
            label=""
            placeholder="00:00"
            style={{ width: 72, margin: "16px" }}
            maxLength={5}
          />
        )
      },
      {
        label: (
          <FormikTextField
            name={`${props.unitKey}[${i}].place`}
            size="smallMedium"
            style={{ width: 200, marginBottom: 0 }}
          />
        )
      },
      {
        label: (
          <FormikTextField
            name={`${props.unitKey}[${i}].support`}
            size="fullSize"
            style={{ marginBottom: 0 }}
          />
        )
      },
      {
        label: (
          <FormGroup className={classes.iconCell}>
            <DeleteIcon
              style={{
                width: 24,
                height: 28,
                color: "#0277bd",
                cursor: "pointer"
              }}
              onClick={onDeleteRecord}
            />
          </FormGroup>
        )
      }
    ];
    const bodyValues = props.destination
      // 「isDelete」を「1」にすると配列のindexがずれるので元のindexを持たせる
      .map((item, i) => {
        return { value: item, baseIndex: i };
      })
      .filter((item) => !item.value.isDelete)
      .map((d: { value: DestinationValues[0]; baseIndex: number }) => {
        const onDeleteRecord = (): void => {
          props.onDeleteRecord(d.baseIndex);
        };
        return bodyData(d.baseIndex, onDeleteRecord);
      });

    return (
      <KnowbeTable className={classes.table}>
        <KnowbeTableHead
          uniqueKey="行き先"
          height={40}
          items={headData}
          backgroundColor="secondary"
        />
        <KnowbeTableBody
          uniqueKey="行き先"
          itemsContainer={bodyValues}
          stripe={false}
        />
      </KnowbeTable>
    );
  }
);
