import { AxiosResponse } from "axios";
import request from "@api/index";
import { VERSION_URL_201910 } from "@config";

export type RequestParamToPostSupportProcedureDetailFormAPI = {
  support_procedure_forms: {
    users_in_facility_id: number;
    number_of_practitioner: number;
    support_procedure_form_practitioners: {
      support_procedure_form_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner?: number | null;
      practitioner_num: number;
    }[];
    support_procedure_form_other_practitioners: {
      support_procedure_form_other_practitioners_id: number | null;
      start_time: string | null;
      end_time: string | null;
      practitioner_name: string;
      facility_name: string;
      is_delete?: number;
    }[];
    support_procedure_form_details: {
      support_procedure_form_details_id: number | null;
      check: number | null;
      appearance: string | null;
    }[];
    contact_information_record: string | null;
    inquiry_items_record: string | null;
    is_delete?: number;
  };
};

/**
 * 対象IDの支援手順書兼記録用紙を保存する
 */
export const postSupportProcedureDetailForm = async (
  /** 支援手順書兼記録用紙のID */
  supportProcedureFormsId: string | number,
  data: RequestParamToPostSupportProcedureDetailFormAPI
): Promise<AxiosResponse<unknown>> => {
  const url = `${VERSION_URL_201910}/support_procedure/detail/${supportProcedureFormsId}`;
  return request.post(url, data);
};
