import { isAfter, isWithinRange } from "date-fns";

/**
 * 対象の日付が範囲内に含まれているかチェック
 * @param target 対象の日付
 * @param start 範囲の開始日
 * @param end 範囲の終了日
 * @return 対象の日付が範囲内であればtrue, 範囲外であればfalse
 */
export const isDateWithin = (
  target: Date,
  start: Date | string,
  end?: Date | string
): boolean => {
  if (isAfter(start, target)) return false;
  if (!end) return true;

  return isWithinRange(target, new Date(start), new Date(end));
};
