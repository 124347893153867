import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import FormikSelectYearMonth from "@components/molecules/FormikSelectYearMonth";
import HelpIcon from "@material-ui/icons/Help";
import HelpToolTip from "@components/atoms/HelpToolTip";
import HelpTipMessages from "@components/molecules/HelpTipMessages";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { FacilityValues } from "@initialize/mgr/JIRITSUKUNRENSEIKATSU/facility/initialValues";
import { FormikProps } from "formik";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
};

type Props = WithStyles<typeof styles> & OwnProps;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    props.formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      const init = { year: "NOT_SELECTED", month: "", day: "" };
      switch (event.target.name) {
        case "subtractionItem.bodyRestrictedStillFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillTo",
            init
          );
          break;
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            init
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            init
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            init
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            init
          );
          break;
        default:
      }
    }
  };
  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.establishedByLocalGovernmentsFlag"
        label="地方公共団体が設置"
      />
      <FormikCheckbox
        name="subtractionItem.standardOverUseFlag"
        label="標準利用期間超過（当月）"
      />
      <FormikSwitch
        name="subtractionItem.lackOfLifeSupportMemberFlag"
        label="生活支援員等欠員"
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="lackOfLifeSupportMemberFlag" />}
          />
        }
      >
        <FormikSelectYearMonth
          name="subtractionItem.lackOfLifeSupportMemberStartDate"
          label="減算適応開始月"
          style={{ marginBottom: 0 }}
        />
        <div className={props.classes.comment}>
          <p>
            減算適応月については該当項目の
            <HelpIcon className={props.classes.helpIcon} />
            を参照してください
          </p>
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.lackOfResponsiblePersonFlag"
        label="サービス管理責任者の欠員"
        tooltip={
          <HelpToolTip
            title={<HelpTipMessages name="lackOfResponsiblePersonFlag" />}
          />
        }
      >
        <FormikSelectYearMonth
          name="subtractionItem.lackOfResponsiblePersonStartDate"
          label="減算適応開始月"
        />
        <div className={props.classes.comment}>
          <p>
            減算適応月については該当項目の
            <HelpIcon className={props.classes.helpIcon} />
            を参照してください
          </p>
        </div>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.bodyRestrictedStillFlg"
        label="身体拘束廃止未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.bodyRestrictedStillTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
        label="虐待防止措置未実施減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.workContinuationNotPlanningFlg"
        label="業務継続計画未策定減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.workContinuationNotPlanningTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
      <FormikSwitch
        name="subtractionItem.informationDisclosureNotReportedFlg"
        label="情報公表未報告減算"
        onChange={onChangeSwitch}
      >
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedFrom"
            label="開始年月日"
            addYearTo={1}
            overrideYearFrom={2024}
            setFormikFieldValue={props.formikProps.setFieldValue}
            monthOptionsStartFrom={4}
          />
        </FormGroup>
        <FormGroup row>
          <FormikSelectDateNotSelectedDefault
            name="subtractionItem.informationDisclosureNotReportedTo"
            label="終了年月日（任意）"
            addYearTo={5}
            overrideYearFrom={2024}
            style={{ marginBottom: 0 }}
            setFormikFieldValue={props.formikProps.setFieldValue}
          />
        </FormGroup>
      </FormikSwitch>
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
