import { dateToLocalisedString, parseDateString } from "@utils/date";
import { addDays } from "date-fns";

/**
 * yyyy-mm-dd HH:MM:SSの作成
 * 翌日の場合、+1日
 */
export const createTime = (
  date?: string,
  time?: string,
  timeClass?: string
): string => {
  let returnDate = "";
  if (date && time) {
    returnDate =
      timeClass === "1"
        ? dateToLocalisedString(
            addDays(parseDateString(date), 1),
            "YYYY-MM-DD"
          ).concat(" ") + time.concat(":00")
        : dateToLocalisedString(date, "YYYY-MM-DD").concat(" ") +
          time.concat(":00");
  }

  return returnDate;
};
