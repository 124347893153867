import * as React from "react";
import * as URL from "@constants/url";
import * as H from "history";

// UI
import {
  createStyles,
  StyleRules,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { Form, Formik, FormikActions, FormikProps } from "formik";
import { RouteComponentProps } from "react-router-dom";

// store
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AppState } from "@stores/type";
import dispatches from "@stores/dispatches";
import { StaffData, StaffState } from "@stores/domain/staff/types";
import { SnackbarParams } from "@stores/ui/type";
import { UsersInFacilityState as KODOENGOUsersInFacilityState } from "@stores/domain/mgr/KODOENGO/userInFacility/types";

import AdminTemplate from "@components/templates/AdminTemplate";
import { PlanListHeader } from "@components/organisms/mgr/common/record/carePlan/PlanListHeader";
import { CarePlanUserInfo } from "@components/organisms/mgr/common/record/carePlan/CarePlanUserInfo";
import { SupportPlanSheetsForm } from "@components/organisms/mgr/common/record/SupportPlanSheetsForm";
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import NavigationTransitionPrompt from "@components/organisms/mgr/NavigationTransitionPrompt";
import { GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId } from "@api/requests/supportPlanSheets/getSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId";
import { FieldItem } from "@interfaces/ui/form";
import { initialValues } from "@initialize/record/supportPlanSheets/initialValues";
import { FacilityType, PLAN_LIST_TAB_PATH } from "@constants/variables";
import generateSelectFieldItems from "@utils/dataNormalizer/generateSelectFieldItems";
import getSnapOrRealName from "@utils/domain/mgr/getSnapOrRealName";
import getSnapOrRealRole from "@utils/domain/mgr/getSnapOrRealRole";
import { createSnapshotOptions } from "@utils/domain/mgr/createSnapshotOptions";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
import { SupportPlanSheets } from "@interfaces/record/supportPlanSheets/supportPlanSheets";
import { setEdit, unsetEdit } from "@stores/domain/supportPlanSheets/actions";
import { validation } from "@initialize/record/supportPlanSheets/validation";
import { toEffectiveObject } from "@utils/object";
import MessageDialog from "@components/molecules/dialog/MessageDialog";
import { Button } from "@material-ui/core";

const styles = (): StyleRules =>
  createStyles({
    stickyWrapper: {
      height: "16px",
      background: "#eee",
      position: "sticky",
      top: 0,
      zIndex: 10
    },
    wrapper: {
      margin: "16px auto 0",
      width: "calc(100% - 16px * 2)"
    },
    form: {
      marginTop: "32px"
    },
    con: {
      background: "#fff"
    },
    titleBtn: {
      display: "flex"
    },
    titleBtnItem: {
      "&:nth-child(n+2)": {
        marginLeft: "8px"
      }
    },
    deleteBtn: {
      marginTop: 20,
      marginLeft: 16,
      alignItems: "center",
      boxShadow: "none",
      color: "#0277BD",
      backgroundColor: "rgba(98, 2, 238, 0)",
      padding: "6px 6px 6px 0px",
      "&>span": {
        marginRight: "0px"
      },
      "&>span>span": {
        lineHeight: 1,
        minHeight: "auto",
        marginLeft: "8px",
        marginRight: "0px"
      }
    },
    btnTxt: {
      marginLeft: 12
    },
    deleteButton: {
      color: "#b00020"
    }
  });

type OwnProps = RouteComponentProps<{
  id: string;
  supportPlanSheetId: string;
}> &
  WithStyles<typeof styles>;

type StateProps = {
  needsStopHistory: boolean;
  user: KODOENGOUsersInFacilityState["user"];
  supportPlanSheets: GetSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId["data"];
  isEditing: boolean;
  staff: StaffState;
  loading: boolean;
  facilityType: FacilityType;
};

type DispatchProps = {
  showSnackbar: (params: SnackbarParams) => void;
  stopHistory: (flag: boolean) => void;
  setEditing: () => void;
  unsetEditing: () => void;
  fetchOneUser: (id: string, facilityType: FacilityType) => void;
  fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
    id: string,
    supportPlanSheetId: string
  ) => Promise<void>;
  postSupportPlanUifIdSupportPlanSheets: (
    uifId: string,
    sheet: SupportPlanSheets,
    history: H.History
  ) => Promise<void>;
  deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
    id: string,
    supportPlanSheetId: string,
    history: H.History
  ) => void;
};

type MergeProps = {
  authorValue: string;
  authorizerRole: string;
  staffOptions: FieldItem[];
  staffOptionsAddSnapShot: FieldItem[];
  staffWithSnap: StaffState;
  userId: number | undefined;
  userName: string;
};

type Props = OwnProps & StateProps & DispatchProps & MergeProps;

const SupportPlanSheetsEditCore = (props: Props): JSX.Element => {
  const {
    needsStopHistory,
    history,
    classes,
    user,
    supportPlanSheets,
    staffOptions,
    staffOptionsAddSnapShot,
    staffWithSnap,
    userId,
    userName,
    isEditing,
    authorValue,
    authorizerRole,
    facilityType
  } = props;
  const { id, supportPlanSheetId } = props.match.params;

  const pageName = "支援計画シート";
  // ヘッダーのパンくず
  const pathList = [
    {
      pathName: "支援計画",
      path: URL.RECORD_SUPPORT_PLAN_USERS_SUMMARY
    },
    {
      pathName: `${pageName}一覧`,
      path: `${URL.RECORD_SUPPORT_PLAN}/${userId}/${PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS}`
    }
  ];
  // fetch
  React.useEffect(() => {
    props.fetchOneUser(id, facilityType);
    props.fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
      id,
      supportPlanSheetId
    );
    props.unsetEditing();
  }, []);

  // 削除モーダル;
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState<boolean>(
    false
  );
  const onOpenDeleteModal = (): void => {
    setIsOpenDeleteModal(true);
  };
  const onCloseDeleteModal = (): void => {
    setIsOpenDeleteModal(false);
  };
  // 削除
  const onClickDelete = (): void => {
    props.deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
      id,
      supportPlanSheetId,
      history
    );
  };

  // reInitialize
  const [formValues, setFormValues] = React.useState(
    initialValues(supportPlanSheets, staffOptions)
  );

  React.useEffect(() => {
    setFormValues(initialValues(supportPlanSheets, staffOptions));
  }, [supportPlanSheets]);

  if (user.user_in_facility.id === undefined) {
    return <></>;
  }

  // 印刷
  const onClickPrint = (): void => {
    history.push(
      `/record/support_plan/${id}/support_plan_sheets/${supportPlanSheetId}/print`
    );
  };

  // 編集
  const onClickEdit = (e: React.MouseEvent): void => {
    props.setEditing();
    e.preventDefault();
  };

  const editButton = (
    <div className={classes.titleBtn}>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickPrint}>
          印刷
        </KnowbeButton>
      </div>
      <div className={classes.titleBtnItem}>
        <KnowbeButton kind="default" onClick={onClickEdit}>
          編集
        </KnowbeButton>
      </div>
    </div>
  );

  // キャンセル
  const onClickCancel = (formikProps: FormikProps<SupportPlanSheets>): void => {
    window.location.reload();
    formikProps.resetForm();
    props.stopHistory(false);
    props.unsetEditing();
  };

  // 保存
  const onSubmit = async (
    sheet: SupportPlanSheets,
    actions: FormikActions<SupportPlanSheets>
  ): Promise<void> => {
    actions.setSubmitting(true);
    props.postSupportPlanUifIdSupportPlanSheets(id, sheet, history);
    actions.setSubmitting(false);
    props.unsetEditing();
    props.stopHistory(false);
  };

  // 保存エラー
  const submitError = (): void => {
    props.showSnackbar({
      open: true,
      message: "入力内容に誤りがあります",
      variant: "warning"
    });
  };

  // バリデーション
  const validate = (values: SupportPlanSheets): void | object => {
    const validationResult = validation(values);
    const error = toEffectiveObject(validationResult);
    if (!needsStopHistory && isEditing) {
      props.stopHistory(true);
    }
    return error;
  };

  return (
    <AdminTemplate pageName={pageName} pathList={pathList}>
      <div className={props.classes.stickyWrapper} />
      <div className={classes.wrapper}>
        {/* ユーザー情報 */}
        <CarePlanUserInfo user={user} />
        <Formik
          initialValues={formValues}
          onSubmit={onSubmit}
          validate={validate}
          enableReinitialize
        >
          {(formikProps): JSX.Element => {
            return (
              <Form className={classes.form}>
                {/* タブヘッダー */}
                <PlanListHeader
                  pageName={pageName}
                  userName={userName}
                  uifId={id}
                  recordType={PLAN_LIST_TAB_PATH.SUPPORT_PLAN_SHEETS}
                  isEditing={isEditing}
                  history={history}
                  button={
                    isEditing ? (
                      <div className={classes.titleBtn}>
                        <div className={classes.titleBtnItem}>
                          <KnowbeButton
                            kind="outline"
                            onClick={(): void => onClickCancel(formikProps)}
                          >
                            キャンセル
                          </KnowbeButton>
                        </div>
                        <div className={classes.titleBtnItem}>
                          <FormikSubmitButton
                            key="submit-button"
                            buttonName="保存する"
                            formikProps={formikProps}
                            errorAction={submitError}
                          />
                        </div>
                      </div>
                    ) : (
                      editButton
                    )
                  }
                  facilityType={facilityType}
                />

                {/* フォーム */}
                <div className={classes.con}>
                  <SupportPlanSheetsForm
                    initialValues={formValues}
                    isEditing={isEditing}
                    formikProps={formikProps}
                    authorName={authorValue}
                    authorizerRole={authorizerRole}
                    staffOptionsAddSnapShot={staffOptionsAddSnapShot}
                    staffWithSnap={staffWithSnap}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <NavigationTransitionPrompt />

      {/* 削除ボタン */}
      {isEditing && (
        <KnowbeButton
          kind="iconText"
          className={classes.deleteBtn}
          onClick={onOpenDeleteModal}
        >
          <DeleteIcon fontSize="small" />
          <span className={classes.btnTxt}>支援計画シートを削除する</span>
        </KnowbeButton>
      )}

      {/* 削除確認モーダル */}
      <MessageDialog
        isOpen={isOpenDeleteModal}
        title={`${pageName}を削除します`}
        message={
          <span>
            データが完全に削除され、復元できません。
            <br />
            よろしいですか？
          </span>
        }
        closeButton={
          <Button
            color="secondary"
            className={props.classes.deleteCancelButton}
            onClick={onCloseDeleteModal}
          >
            キャンセル
          </Button>
        }
        actionButton={
          <Button
            className={props.classes.deleteButton}
            onClick={onClickDelete}
          >
            削除する
          </Button>
        }
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const facilityType = state.user.facility_type;
  return {
    needsStopHistory: state.ui.needsStopHistory,
    user: state[facilityType].userInFacility.user,
    supportPlanSheets:
      state.supportPlanSheets.supportPlanSheetsSupportPlanSheetId,
    isEditing: state.supportPlanSheets.isEditing,
    staff: state.staff,
    loading: state.loading,
    facilityType
  };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  const { uiDispatch, supportPlanSheetsDispatcher } = dispatches;

  return {
    showSnackbar: (params: SnackbarParams): void =>
      uiDispatch(dispatch).snackbar(params),
    stopHistory: uiDispatch(dispatch).stopHistory,
    setEditing: (): void => {
      dispatch(setEdit());
    },
    unsetEditing: (): void => {
      dispatch(unsetEdit());
    },
    fetchOneUser: (id: string, facilityType: FacilityType): void => {
      dispatches[facilityType].userInFacilityDispatcher(dispatch).fetchOne(id);
    },
    fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: supportPlanSheetsDispatcher(
      dispatch
    ).fetchSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId,
    postSupportPlanUifIdSupportPlanSheets: async (
      uifId: string,
      sheet: SupportPlanSheets,
      history: H.History
    ): Promise<void> => {
      await supportPlanSheetsDispatcher(
        dispatch
      ).postSupportPlanUifIdSupportPlanSheets(uifId, sheet, history);
    },
    deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId: (
      uifId: string,
      supportPlanSheetId: string,
      history: H.History
    ): void => {
      supportPlanSheetsDispatcher(
        dispatch
      ).deleteSupportPlanUifIdSupportPlanSheetsSupportPlanSheetId(
        uifId,
        supportPlanSheetId,
        history
      );
    }
  };
};

const mergeProps = (
  stateProps: StateProps,
  dispatchProps: DispatchProps,
  ownProps: OwnProps
): MergeProps => {
  const { staff, supportPlanSheets, user } = stateProps;

  // ユーザID
  const userId = user.user_in_facility.id;
  const userName = `${user.user_in_facility.name_sei} ${user.user_in_facility.name_mei}`;

  const author =
    supportPlanSheets.author !== null ? supportPlanSheets.author : 0;
  // 作成者名
  const authorValue =
    Object.keys(supportPlanSheets).length === 0
      ? ""
      : getSnapOrRealName(author, "");
  // 承認者役職
  const authorizerRole =
    Object.keys(supportPlanSheets).length === 0
      ? ""
      : getSnapOrRealRole(author, "");

  // 記録者フィールドに渡すoptions
  const staffOptions = generateSelectFieldItems(
    staff.staffItems,
    "staffName",
    "staffItemId"
  );

  let staffOptionsAddSnapShot = staffOptions;
  // サービス提供者選択肢作成（スナップショット）
  if (supportPlanSheets.author !== null) {
    staffOptionsAddSnapShot = createSnapshotOptions(
      staffOptions,
      supportPlanSheets.author
    );
  }

  // snapshotがあるなら、snapshotに追加されているidをstaffに追加する
  const staffWithSnap = {
    staffItems: staff.staffItems.concat()
  };
  if (staffOptionsAddSnapShot.length > staff.staffItems.length) {
    const snapOption = staffOptionsAddSnapShot.find(
      (o) => !staff.staffItems.find((i) => i.staffItemId === +o.value)
    );
    const snapItem: StaffData = {
      staffItemId: snapOption ? +snapOption.value : 0,
      staffName: authorValue,
      roleName: authorizerRole
    };
    staffWithSnap.staffItems.push(snapItem);
  }

  return {
    authorValue,
    authorizerRole,
    staffOptions,
    staffOptionsAddSnapShot,
    staffWithSnap,
    userId,
    userName,
    ...stateProps,
    ...dispatchProps,
    ...ownProps
  };
};

export const SupportPlanSheetsEdit = withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  )(SupportPlanSheetsEditCore)
);
