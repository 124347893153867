import { AssistanceReportValues } from "./initialValues";
import ValidationErrors from "@interfaces/ui/validationErrors";
import validator, { dateValidator, validateSwitcher } from "@validator";

type ConsultationErrors = ValidationErrors<AssistanceReportValues>;

// 削除済みでなければバリデーションをかける
const isDeleteValidateSwitcher = (
  detail: AssistanceReportValues["details"][0],
  value: string
): Exclude<
  Exclude<ConsultationErrors["details"], undefined>[0],
  undefined
>["is_delete"] => {
  return validateSwitcher(
    !detail.is_delete,
    validator(value, {
      type: "checkCharacterLength",
      length: 2500
    })
  );
};

// 詳細バリデーション
export const detailsValidation = (
  detailValues: AssistanceReportValues["details"]
): ConsultationErrors["details"] => {
  return detailValues.map((detail) => {
    return {
      support_goal: isDeleteValidateSwitcher(detail, detail.support_goal),
      criteria_for_cooperation: isDeleteValidateSwitcher(
        detail,
        detail.criteria_for_cooperation
      ),
      service_provision_status: isDeleteValidateSwitcher(
        detail,
        detail.service_provision_status
      ),
      personal_satisfaction: isDeleteValidateSwitcher(
        detail,
        detail.personal_satisfaction
      ),
      achievement_of_expectations: isDeleteValidateSwitcher(
        detail,
        detail.achievement_of_expectations
      ),
      future_issues: isDeleteValidateSwitcher(detail, detail.future_issues),
      other_considerations: isDeleteValidateSwitcher(
        detail,
        detail.other_considerations
      )
    };
  });
};

export const validation = (
  values: AssistanceReportValues
): ConsultationErrors => {
  return {
    dateOfMonitoring: dateValidator(values.dateOfMonitoring, "required"),
    disabledWelfareServiceRecipientNumber: validator(
      values.disabledWelfareServiceRecipientNumber,
      "naturalNumber"
    ),
    regularPlaceRecipientNumber: validator(
      values.regularPlaceRecipientNumber,
      "naturalNumber"
    ),
    consultationSupportRecipientNumber: validator(
      values.consultationSupportRecipientNumber,
      "naturalNumber"
    ),
    attainmentGoal: validator(values.attainmentGoal, {
      type: "checkCharacterLength",
      length: 2500
    }),
    wholeSituation: validator(values.wholeSituation, {
      type: "checkCharacterLength",
      length: 2500
    }),
    details: detailsValidation(values.details),
    staffComment: validator(values.staffComment, {
      type: "checkCharacterLength",
      length: 2500
    })
  };
};
