import { Dispatch } from "redux";
import * as actions from "./actions";
import * as loadingActions from "@stores/loading/actions";
import * as snackbarActions from "@stores/ui/snackbar/actions";
import * as responseErrorActions from "@stores/ui/responseError/actions";
import { supportLedgerApi } from "@api/requests/supportLedger";
import { SupportLedger } from "@initialize/mgr/CHIIKITEICHAKU/record/supportLedger/initialValues";
import { normalizePostSupportLedgerDataToAPI } from "@stores/domain/mgr/CHIIKITEICHAKU/record/supportLedger/nomalizer";
import dispatches from "@stores/dispatches";
import * as H from "history";
import * as URL from "@constants/url";
import { FieldItem } from "@interfaces/ui/form";

export const fetchSupportLedger = (dispatch: Dispatch) => async (
  uifId: string,
  supportLedgerId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportLedgerStarted());
  await supportLedgerApi
    .getSupportLedger(uifId, supportLedgerId)
    .then((res) => {
      dispatch(actions.fetchSupportLedgerSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportLedgerFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const fetchSupportLedgerList = (dispatch: Dispatch) => async (
  uifId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.fetchSupportLedgerListStarted());
  await supportLedgerApi
    .getSupportLedgerList(uifId)
    .then((res) => {
      dispatch(actions.fetchSupportLedgerListSuccess(res.data.data));
    })
    .catch((e) => {
      dispatch(actions.fetchSupportLedgerListFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const postSupportLedger = (dispatch: Dispatch) => async (
  uifId: string,
  initialSupportLedger: SupportLedger,
  supportLedger: SupportLedger,
  history: H.History,
  staffOptions: FieldItem[],
  isNew?: boolean,
  isCopy?: boolean
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.postSupportLedgerStarted());
  const normalizeParam = normalizePostSupportLedgerDataToAPI(
    initialSupportLedger,
    supportLedger,
    staffOptions,
    isNew,
    isCopy
  );
  await supportLedgerApi
    .postSupportLedger(uifId, normalizeParam)
    .then(() => {
      dispatch(actions.postSupportLedgerSuccess());
      dispatches.uiDispatch(dispatch).stopHistory(false);
      dispatch(snackbarActions.showSnackbar("内容を保存しました。", "success"));
      // 新規・コピーの場合（編集ではないとき）
      if (supportLedger.support_ledger.support_ledgers_id === null) {
        history.push(`${URL.RECORD_SUPPORT_PLAN}/${uifId}/support_ledger`);
      }
    })
    .catch((e) => {
      dispatch(actions.postSupportLedgerFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

export const deleteSupportLedger = (dispatch: Dispatch) => async (
  uifId: string,
  supportLedgerId: string
): Promise<void> => {
  dispatch(loadingActions.loadStarted());
  dispatch(actions.deleteSupportLedgerStarted());
  await supportLedgerApi
    .deleteSupportLedger(uifId, supportLedgerId)
    .then(() => {
      dispatch(actions.deleteSupportLedgerSuccess());
    })
    .catch((e) => {
      dispatch(actions.deleteSupportLedgerFailed({ error: e.response }));
      dispatch(responseErrorActions.setResponseError(e.response));
      dispatch(snackbarActions.showSnackbar("通信エラー", "error"));
    })
    .finally(() => dispatch(loadingActions.loadDone()));
};

type DispatchProps = {
  fetchSupportLedger: (uifId: string, supportLedgerId: string) => Promise<void>;
  fetchSupportLedgerList: (uifId: string) => Promise<void>;
  postSupportLedger: (
    uifId: string,
    initialSupportLedger: SupportLedger,
    supportLedger: SupportLedger,
    history: H.History,
    staffOptions: FieldItem[],
    isNew?: boolean,
    isCopy?: boolean
  ) => Promise<void>;
  deleteSupportLedger: (
    uifId: string,
    supportLedgerId: string
  ) => Promise<void>;
};

export const CHIIKITEICHAKUSupportLedgerDispatcher = (
  dispatch: Dispatch
): DispatchProps => ({
  fetchSupportLedger: fetchSupportLedger(dispatch),
  fetchSupportLedgerList: fetchSupportLedgerList(dispatch),
  postSupportLedger: postSupportLedger(dispatch),
  deleteSupportLedger: deleteSupportLedger(dispatch)
});
