import React from "react";
import {
  createStyles,
  WithStyles,
  StyleRules,
  withStyles
} from "@material-ui/core/styles";
import TableCellWrap from "@components/atoms/TableCellWrap";
import { InOutReportCell } from "@components/organisms/mgr/CHIIKITEICHAKU/report/cell/InOutReportCell";
import { InoutConsultationResultsState } from "@stores/domain/mgr/CHIIKITEICHAKU/report/types";
import CustomDateLabel from "@components/atoms/CustomDateLabel";
import { InOutReportEditCell } from "@components/organisms/mgr/CHIIKITEICHAKU/report/cell/InOutReportEditCell";
import { UsersInFacilityState } from "@stores/domain/mgr/CHIIKITEICHAKU/userInFacility/types";
import { connect } from "react-redux";
import { AppState } from "@stores/type";
import { FormikProps } from "formik";
import { InitialValues } from "@interfaces/mgr/CHIIKITEICHAKU/report/initial";

const styles = (): StyleRules =>
  createStyles({
    dateCell: {
      boxSizing: "border-box",
      width: 120,
      minWidth: 120,
      maxWidth: 120,
      padding: "16px 8px 16px 16px"
    }
  });

type StateProps = {
  userInFacilityState: UsersInFacilityState;
};

type OwnProps = {
  params: InoutConsultationResultsState;
  formikProps: FormikProps<InitialValues>;
  idx: string;
  isEditing: boolean;
};

type Props = OwnProps & StateProps & WithStyles<typeof styles>;

const InOutReportListCellCore = (props: Props): JSX.Element => {
  const { params, formikProps, idx, isEditing } = props;
  return (
    <>
      <TableCellWrap
        key={`${idx}-target-date`}
        cellClass={props.classes.dateCell}
      >
        <CustomDateLabel
          date={params.targetDate ? params.targetDate : ""}
          dateFormat="Do（dd）"
          holiday={params.isHoliday ? params.isHoliday : false}
        />
      </TableCellWrap>
      {isEditing ? (
        <InOutReportEditCell formikProps={formikProps} idx={idx} />
      ) : (
        <InOutReportCell params={params} idx={idx} />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  return {
    userInFacilityState: state.CHIIKITEICHAKU.userInFacility
  };
};

export const InOutReportListCell = connect(mapStateToProps)(
  withStyles(styles)(InOutReportListCellCore)
);
