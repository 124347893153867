import * as types from "./types";
import { RequestParamToPostAPI } from "@api/requests/facility/postCarePlan";
import { RequestParamToDeleteAPI } from "@api/requests/facility/deleteCarePlan";

type ErrorResponse = {
  error: {
    code: number;
    msg: string;
  };
};

export const fetchCarePlanStarted = () =>
  ({ type: types.FETCH_DOKOENGO_CARE_PLAN_STARTED } as const);
export const fetchCarePlan = (res: types.CarePlanStaffState) =>
  ({ type: types.FETCH_DOKOENGO_CARE_PLAN, payload: res } as const);
export const fetchCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.FETCH_DOKOENGO_CARE_PLAN_FAILED, error: err } as const);

export const postDOKOENGOCarePlanStarted = () =>
  ({ type: types.POST_DOKOENGO_CARE_PLAN_STARTED } as const);
export const postDOKOENGOCarePlan = (req: RequestParamToPostAPI) =>
  ({ type: types.POST_DOKOENGO_CARE_PLAN, payload: req } as const);
export const postDOKOENGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.POST_DOKOENGO_CARE_PLAN_FAILED, error: err } as const);

export const deleteDOKOENGOCarePlanStarted = () =>
  ({ type: types.DELETE_DOKOENGO_CARE_PLAN_STARTED } as const);
export const deleteDOKOENGOCarePlan = (req: RequestParamToDeleteAPI) =>
  ({ type: types.DELETE_DOKOENGO_CARE_PLAN, payload: req } as const);
export const deleteDOKOENGOCarePlanFailed = (err: ErrorResponse) =>
  ({ type: types.DELETE_DOKOENGO_CARE_PLAN_FAILED, error: err } as const);

export type ActionTypes =
  | ReturnType<typeof fetchCarePlanStarted>
  | ReturnType<typeof fetchCarePlan>
  | ReturnType<typeof fetchCarePlanFailed>
  | ReturnType<typeof postDOKOENGOCarePlanStarted>
  | ReturnType<typeof postDOKOENGOCarePlan>
  | ReturnType<typeof postDOKOENGOCarePlanFailed>
  | ReturnType<typeof deleteDOKOENGOCarePlanStarted>
  | ReturnType<typeof deleteDOKOENGOCarePlan>
  | ReturnType<typeof deleteDOKOENGOCarePlanFailed>;
