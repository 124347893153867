import * as React from "react";
import {
  Theme,
  createStyles,
  WithStyles,
  withStyles,
  StyleRules
} from "@material-ui/core/styles";
import RecruitLogo from "@components/atoms/RecruitLogo";
import { INTRODUCTORY_CLAUSE, PRIVACY_POLICY } from "@constants/url";

export const MIN_FOOTER_HEIGHT = "54px";
export const MAX_FOOTER_HEIGHT = "80px";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: "#f5f5f5",
      padding: "12px 16px 16px 16px",
      "@media (min-width: 443px)": {
        height: MIN_FOOTER_HEIGHT,
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end"
      },
      "@media (max-width: 442px)": {
        height: MAX_FOOTER_HEIGHT
      }
    },
    logo: {
      height: 24,
      "@media (min-width: 443px)": {
        marginRight: 40
      },
      "@media (max-width: 442px)": {
        "&>a": {
          display: "block",
          "&>img": {
            verticalAlign: "bottom"
          }
        }
      }
    },
    footerLink: {
      color: "#37474f",
      textDecoration: "none",
      "&:hover": {
        textDecoration: "underline"
      }
    },
    legalRoot: {
      fontSize: "12px",
      lineHeight: 1,
      listStyle: "none",
      display: "flex",
      padding: 0,
      margin: 0,
      "&>li+li": {
        marginLeft: spacing.unit * 2 + 1,
        position: "relative",
        "&:before": {
          display: "block",
          content: "' '",
          position: "absolute",
          left: -(spacing.unit + 1),
          top: "50%",
          transform: "translateY(-50%)",
          width: 1,
          height: "calc(8 / 12 * 100%)",
          background: "#37474f"
        }
      },
      "@media (max-width: 442px)": {
        marginTop: 16,
        marginBottom: 0
      }
    }
  });

type Props = WithStyles<typeof styles>;

const FooterCore = ({ classes }: Props): JSX.Element => (
  <footer className={classes.root}>
    <div className={classes.logo}>
      <a href="https://www.recruit.co.jp/" rel="noreferrer noopener">
        <RecruitLogo />
      </a>
    </div>
    <ul className={classes.legalRoot}>
      <li>
        <a
          href={PRIVACY_POLICY}
          className={classes.footerLink}
          rel="noreferrer noopener"
          target="_blank"
        >
          プライバシーポリシー
        </a>
      </li>
      <li>
        <a
          href={INTRODUCTORY_CLAUSE}
          className={classes.footerLink}
          rel="noreferrer noopener"
          target="_blank"
        >
          導入約款
        </a>
      </li>
    </ul>
  </footer>
);

export const Footer = withStyles(styles)(FooterCore);
