import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import LackFields from "@components/organisms/mgr/common/facility/items/LackFields";
import {
  WithStyles,
  withStyles,
  createStyles,
  FormGroup
} from "@material-ui/core";

import { FormikProps } from "formik";
import { StyleRules } from "@material-ui/core/styles";
import { FacilityValues } from "@initialize/mgr/SEIKATSUKAIGO/facility/initialValues";
import {
  DISABLE_FACILITY_TYPE,
  DISPLAY_NONE_STANDARD_FACILITY_TYPE
} from "@constants/mgr/SEIKATSUKAIGO/variables";
import FormikSwitch from "@components/molecules/FormikSwitch";
import FormikSelectDateNotSelectedDefault from "@components/molecules/FormikSelectDateNotSelectedDefault";
import { DEFAULT_SELECT_DATE_VALUE } from "@constants/variables";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
  facilityType: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => {
  const { formikProps } = props;
  const onChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    formikProps.setFieldValue(event.target.name, checked);

    // OFFだった場合、デフォルト値に戻す
    if (!checked) {
      switch (event.target.name) {
        case "subtractionItem.bodyRestrictedStillFlg":
          formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          formikProps.setFieldValue(
            "subtractionItem.bodyRestrictedStillTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.abusePreventionMeasuresNotImplementedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.abusePreventionMeasuresNotImplementedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.workContinuationNotPlanningFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.workContinuationNotPlanningTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        case "subtractionItem.informationDisclosureNotReportedFlg":
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedFrom",
            DEFAULT_SELECT_DATE_VALUE
          );
          props.formikProps.setFieldValue(
            "subtractionItem.informationDisclosureNotReportedTo",
            DEFAULT_SELECT_DATE_VALUE
          );
          break;
        default:
          break;
      }
    }
  };

  return (
    <FormPaper>
      <div style={{ marginBottom: 32 }}>
        <SectionTitle label="減算対象項目" />
      </div>
      <FormikCheckbox
        name="subtractionItem.establishedByLocalGovernmentsFlag"
        label="地方公共団体が設置"
      />
      <FormikCheckbox
        name="subtractionItem.openedOrShortTimeFlag"
        label="短時間(利用時間５時間未満の利用者が全利用者の50％以上)"
      />
      {!DISABLE_FACILITY_TYPE.includes(props.facilityType) ? (
        <>
          <LackFields formikProps={props.formikProps} name="subtractionItem" />
          <FormikCheckbox
            name="subtractionItem.doctorInstallationFlag"
            label="医師未配置"
            style={
              !DISPLAY_NONE_STANDARD_FACILITY_TYPE.includes(props.facilityType)
                ? {}
                : { marginBottom: 0 }
            }
          />
        </>
      ) : null}
      {!DISPLAY_NONE_STANDARD_FACILITY_TYPE.includes(props.facilityType) && (
        <>
          <FormikSwitch
            name="subtractionItem.bodyRestrictedStillFlg"
            label="身体拘束廃止未実施減算"
            onChange={onChangeSwitch}
          >
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.bodyRestrictedStillFrom"
                label="開始年月日"
                addYearTo={1}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
                monthOptionsStartFrom={4}
              />
            </FormGroup>
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.bodyRestrictedStillTo"
                label="終了年月日（任意）"
                addYearTo={5}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
              />
            </FormGroup>
          </FormikSwitch>
          <FormikSwitch
            name="subtractionItem.abusePreventionMeasuresNotImplementedFlg"
            label="虐待防止措置未実施減算"
            onChange={onChangeSwitch}
          >
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.abusePreventionMeasuresNotImplementedFrom"
                label="開始年月日"
                addYearTo={1}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
                monthOptionsStartFrom={4}
              />
            </FormGroup>
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.abusePreventionMeasuresNotImplementedTo"
                label="終了年月日（任意）"
                addYearTo={5}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
              />
            </FormGroup>
          </FormikSwitch>
          <FormikSwitch
            name="subtractionItem.workContinuationNotPlanningFlg"
            label="業務継続計画未策定減算"
            onChange={onChangeSwitch}
          >
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.workContinuationNotPlanningFrom"
                label="開始年月日"
                addYearTo={1}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
                monthOptionsStartFrom={4}
              />
            </FormGroup>
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.workContinuationNotPlanningTo"
                label="終了年月日（任意）"
                addYearTo={5}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
              />
            </FormGroup>
          </FormikSwitch>
          <FormikSwitch
            name="subtractionItem.informationDisclosureNotReportedFlg"
            label="情報公表未報告減算"
            onChange={onChangeSwitch}
            style={{ marginBottom: 0 }}
          >
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.informationDisclosureNotReportedFrom"
                label="開始年月日"
                addYearTo={1}
                overrideYearFrom={2024}
                setFormikFieldValue={props.formikProps.setFieldValue}
                monthOptionsStartFrom={4}
              />
            </FormGroup>
            <FormGroup row>
              <FormikSelectDateNotSelectedDefault
                name="subtractionItem.informationDisclosureNotReportedTo"
                label="終了年月日（任意）"
                addYearTo={5}
                overrideYearFrom={2024}
                style={{ marginBottom: 0 }}
                setFormikFieldValue={props.formikProps.setFieldValue}
              />
            </FormGroup>
          </FormikSwitch>
        </>
      )}
    </FormPaper>
  );
};

export default withStyles(styles)(SubtractionItemFields);
