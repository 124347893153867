import React, { Dispatch as reactDispatch, SetStateAction } from "react";
import {
  WithStyles,
  createStyles,
  StyleRules,
  withStyles,
  Theme
} from "@material-ui/core/styles";
// ui
import KnowbeButton from "@components/presentational/atoms/KnowbeButton";
import FormikSubmitButton from "@components/molecules/FormikSubmitButton";
// formik
import { FormikProps } from "formik";
import { CustomRecordItemValues } from "@initialize/record/customRecordWithCategory/initialValues";

const styles = ({ spacing }: Theme): StyleRules =>
  createStyles({
    changeOrderButton: {
      marginLeft: spacing.unit
    }
  });

type OwnProps = {
  categoryType: number;
  sortingCategoryType: number | null;
  setSortingCategoryType: reactDispatch<SetStateAction<number | null>>;
  openAddCustomRecordModal: (e: React.MouseEvent<HTMLInputElement>) => void;
  formikProps: FormikProps<CustomRecordItemValues>;
  formValues: CustomRecordItemValues;
  stopHistory: (flag: boolean) => void;
  isSortButtonDisabled: boolean;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SettingFormButtonsCore = ({
  classes,
  categoryType,
  sortingCategoryType,
  setSortingCategoryType,
  openAddCustomRecordModal,
  formikProps,
  formValues,
  stopHistory,
  isSortButtonDisabled
}: Props): JSX.Element => {
  // 並べ替えモードに移行
  const onClickSortButton = (e: React.MouseEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    setSortingCategoryType(+value);
  };

  const onClickCancelButton = (): void => {
    formikProps.setValues(formValues);
    setSortingCategoryType(null);
    stopHistory(false);
  };

  return sortingCategoryType !== categoryType ? (
    <>
      <KnowbeButton value={categoryType} onClick={openAddCustomRecordModal}>
        項目の追加
      </KnowbeButton>
      <KnowbeButton
        className={classes.changeOrderButton}
        value={categoryType}
        onClick={onClickSortButton}
        disabled={isSortButtonDisabled}
      >
        並べ替え
      </KnowbeButton>
    </>
  ) : (
    <>
      <KnowbeButton kind="outline" onClick={onClickCancelButton}>
        キャンセル
      </KnowbeButton>
      <FormikSubmitButton
        className={classes.changeOrderButton}
        buttonName="確定する"
        formikProps={formikProps}
      />
    </>
  );
};

export const SettingFormButtons = withStyles(styles)(SettingFormButtonsCore);
