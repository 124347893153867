import * as React from "react";
import FormPaper from "@components/atoms/FormPaper";
import SectionTitle from "@components/atoms/SectionTitle";
import FormikCheckbox from "@components/molecules/FormikCheckbox";
import LackFields from "@components/v201910/organisms/mgr/common/facility/items/LackFields";
import {
  createStyles,
  withStyles,
  WithStyles,
  StyleRules
} from "@material-ui/core/styles";
import { FacilityValues } from "@initialize/v201910/mgr/SEIKATSUKAIGO/facility/initialValues";
import { FormikProps } from "formik";
import { DISABLE_FACILITY_TYPE } from "@constants/mgr/SEIKATSUKAIGO/variables";

const styles = (): StyleRules =>
  createStyles({
    comment: {
      fontSize: 12
    },
    helpIcon: {
      fontSize: 14.4,
      color: "#607d8b",
      verticalAlign: "sub"
    }
  });

type OwnProps = {
  formikProps: FormikProps<FacilityValues>;
  facilityType: number;
};

type Props = OwnProps & WithStyles<typeof styles>;

const SubtractionItemFields = (props: Props): JSX.Element => (
  <FormPaper>
    <div style={{ marginBottom: 32 }}>
      <SectionTitle label="減算対象" />
    </div>
    <FormikCheckbox
      name="subtractionItem.establishedByLocalGovernmentsFlag"
      label="地方公共団体が設置"
    />
    <FormikCheckbox
      name="subtractionItem.openedOrShortTimeFlag"
      label="短時間(利用時間５時間未満の利用者が全利用者の50％以上)"
    />
    {!DISABLE_FACILITY_TYPE.includes(props.facilityType) ? (
      <>
        <LackFields formikProps={props.formikProps} name="subtractionItem" />
        <FormikCheckbox
          name="subtractionItem.doctorInstallationFlag"
          label="医師未配置"
          style={{ marginBottom: 0 }}
        />
      </>
    ) : null}
  </FormPaper>
);

export default withStyles(styles)(SubtractionItemFields);
