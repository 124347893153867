import getCustomRecords from "./getCustomRecords";
import postCustomRecords from "./postCustomRecords";
import postCustomRecordsCategory from "./postCustomRecordsCategory";
import postCustomRecordsCategoryOrder from "./postCustomRecordsCategoryOrder";
import postCustomRecordsOrder from "./postCustomRecordsOrder";
import postCustomRecordsVisibility from "./postCustomRecordsVisibility";
import postHiddenChoice from "@api/requests/customRecords/postHiddenChoice";
import { getCustomRecordsWithCategory } from "./getCustomRecordsWithCategory";

export default {
  getCustomRecords,
  getCustomRecordsWithCategory,
  postCustomRecords,
  postCustomRecordsCategory,
  postCustomRecordsCategoryOrder,
  postCustomRecordsOrder,
  postCustomRecordsVisibility,
  postHiddenChoice
};
